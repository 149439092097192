import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useEta } from '@dashboard-experience/utils';
import moment from 'moment';
import _ from 'lodash';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

/**
 * @name getCompleteResultText
 * @function
 * @memberOf Report
 * @description Determines the complete state message
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const getCompleteResultText = (timelineEvent: TimelineEvent) => {
  const {
    to: { result, status, internal_state },
  } = timelineEvent;

  if (status === 'canceled') return ' (Report canceled)';
  if (internal_state === 'complete') {
    const reportResult = result ? `"${result}"` : 'no';
    return ` (report completed with ${reportResult} result)`;
  }

  return '';
};

/**
 * @name getEventCategory
 * @function
 * @memberOf Report
 * @description Determines the human-friendly category of the event
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventCategory = (timelineEvent: TimelineEvent) => {
  let category: string;
  if (
    timelineEvent.event === 'update' &&
    timelineEvent.to &&
    timelineEvent.to.adjudication !== undefined &&
    // Adjudication updates along with package during an upgrade, and we want to
    // prefer the report upgrade event over the adjudication event
    timelineEvent.to.package === undefined
  ) {
    category = 'Adjudication:';
  } else if (
    timelineEvent.event === 'request' &&
    timelineEvent.source === 'invitation'
  ) {
    category = 'Invitation';
  } else {
    category = 'Report';
  }
  return category;
};

/**
 * @name formatInvitationTimelineAction
 * @function
 * @memberOf Report
 * @description Determines the human-friendly message of the invitation timeline event
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const formatInvitationTimelineAction = (timelineEvent: TimelineEvent) => {
  let invitationTimelineAction = 'sent to candidate';
  if (timelineEvent.extra_info) {
    const packageName = JSON.parse(timelineEvent.extra_info).package?.name;
    invitationTimelineAction = `sent for ${packageName}`;
  }

  return invitationTimelineAction;
};

/**
 * @name getEventAction
 * @function
 * @memberOf Report
 * @description Determines the human-friendly event action
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventAction = (timelineEvent: TimelineEvent) => {
  let action = '';
  if (timelineEvent.event === 'view') {
    action = 'viewed';
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.status')
  ) {
    action = `status updated to "${timelineEvent.to.status}"`;
    action += getCompleteResultText(timelineEvent);

    if (_.get(timelineEvent, 'to.package')) {
      action += ` (Package upgraded from ${_.get(
        timelineEvent,
        'from.package',
      )} to ${_.get(timelineEvent, 'to.package')})`;
    }
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.pending_context') === 'candidate'
  ) {
    action = `status updated to "pending: candidate action"`;
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.adjudication') === 'pre_adverse_action'
  ) {
    action = 'Pre-adverse action email requested';
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.adjudication') === 'post_adverse_action'
  ) {
    action = 'Post-adverse action email requested';
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.adjudication') === 'engaged'
  ) {
    action = 'Candidate engaged';
  } else if (
    timelineEvent.event === 'update' &&
    _.get(timelineEvent, 'to.adjudication') === null &&
    _.get(timelineEvent, 'from.adjudication') === 'pre_adverse_action'
  ) {
    action = 'Pre-adverse action reversed';
  } else if (timelineEvent.event === 'report_eta_prediction') {
    const extra_info = JSON.parse(timelineEvent.extra_info);
    const estimatedCompletionTime = extra_info.estimated_completion_time;
    const createdAt = extra_info.created_at;

    /* eslint-disable react-hooks/rules-of-hooks */
    // @ts-ignore
    const { isBlocked, etaDate } = useEta({
      createdAt,
      estimatedCompletionTime,
    });
    /* eslint-enable react-hooks/rules-of-hooks */

    if (isBlocked) {
      // Don't show anything
    } else {
      action = `ETA updated to ${etaDate}`;
    }
  } else if (timelineEvent.event === 'copy_sent') {
    const copySentTo = _.get(timelineEvent, 'to.copy_sent_to', 'candidate');
    action = `copy sent to ${copySentTo}`;
  } else if (timelineEvent.event === 'request') {
    switch (timelineEvent.source) {
      case 'form':
        action = 'created by apply link';
        break;
      case 'invitation':
        action = formatInvitationTimelineAction(timelineEvent);
        break;
      case 'manual_order':
        action = 'created by manual order';
        break;
      case 'web':
        action = 'ordered via dashboard';
        break;
      case 'recurrence':
        action = 'created automatically by subscription';
        break;
      case 'continuous_check':
        action = 'created automatically by continuous check';
        break;
      case 'api':
        action = 'requested by API';
        break;
      case 'clearinghouse':
        action = 'created by clearinghouse';
        break;
      default:
        // show nothing
        break;
    }
  }

  return action;
};

const Report: React.FC<{
  timelineEvent: TimelineEvent;
}> = ({ timelineEvent }) => {
  const eventCategory = getEventCategory(timelineEvent);
  const eventAction = getEventAction(timelineEvent);

  return eventCategory && eventAction ? (
    <M.TableRow>
      <TimelineCell>
        <EventName>{`${eventCategory} ${eventAction}`}</EventName>
      </TimelineCell>
      <TimelineCell>
        {timelineEvent.who !== 'system' && timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  ) : null;
};

export default Report;
