import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const { TableExpandRow } = M.DataTable;
const { uiNavy600, uiNavy50, uiNavy100, uiGrey200 } = colors;

const CategoryExpandRow = styled(TableExpandRow)`
  .mastodon-table-cell {
    padding-top: ${({ main }) =>
      main ? '1rem !important' : '1.5rem !important'};
    padding-left: 2rem !important;
  }
  .mastodon-table-cell,
  .cds--table-expand {
    font-weight: bold;
    background-color: ${({ sub }) =>
      sub ? `${uiNavy50} !important` : `${uiNavy100} !important`};
    border-bottom: ${({ sub }) =>
      sub
        ? `1px solid ${uiGrey200} !important`
        : `1px solid ${uiNavy100} !important`};
    color: ${uiNavy600} !important;
  }
  svg {
    fill: ${uiNavy600} !important;
  }
  .mastodon-icon {
    opacity: 0;
  }
  &:hover .mastodon-icon {
    opacity: 100%;
  }
  .bulk-edit-cell {
    border-bottom: 1px solid ${colors.uiNavy50} !important;
  }
  .subcategory {
    padding-top: 0.7rem !important;
    padding-bottom: 1.3rem !important;
  }
`;

export default CategoryExpandRow;
