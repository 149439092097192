import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BoldText = styled.h6`
  padding-top: 1rem;
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight} !important;
`;

const BulkActionText = ({ action, accountHierarchyEnabled }) => {
  return (
    <>
      <M.Container.Row>
        {action === 'Update' ? (
          <BoldText>
            When updating an individual&apos;s node or city / state, your CSV
            must contain:
          </BoldText>
        ) : (
          <BoldText id='continuous-check-bulk-action-csv-file-notes'>
            When uploading a CSV, your file must contain:
          </BoldText>
        )}
      </M.Container.Row>
      <M.Container.Row>
        <ul>
          <li>
            • A <strong>candidate_id</strong> column
          </li>
          {accountHierarchyEnabled && action === 'Enroll' && (
            <>
              <li>
                • A two-digit <strong>state</strong> code column (example: TX)
              </li>
              <li>
                • A <strong>custom_node_id</strong> column (also known as a
                Custom ID)
              </li>
            </>
          )}
          {action === 'Update' && (
            <li>
              • A <strong>custom_node_id</strong> or <strong>state</strong>{' '}
              column for the information being updated
            </li>
          )}
        </ul>
      </M.Container.Row>
      <M.Container.Row>
        <BoldText>Additional notes:</BoldText>
      </M.Container.Row>
      <M.Container.Row>
        <ul>
          <li>• First row must contain column names</li>
          <li>• Must contain only one Candidate ID per row</li>
          {accountHierarchyEnabled && action === 'Enroll' && (
            <li>
              • An optional <strong>city</strong> column may be included
              (example: Austin)
            </li>
          )}
          {action === 'Update' && (
            <li>
              • An optional <strong>city</strong> column may be included with{' '}
              <strong>state</strong> (example: Austin)
            </li>
          )}
          <li>• CSV may not exceed 500,000 rows</li>
          <li>• CSV may not contain any PII</li>
        </ul>
      </M.Container.Row>
    </>
  );
};

BulkActionText.propTypes = {
  action: PropTypes.string.isRequired,
  accountHierarchyEnabled: PropTypes.bool.isRequired,
};

export default BulkActionText;
