import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const defaultTransitionStyle = {
  transition: 'transform 1000ms ease-in-out',
  transform: 'translateX(100%)',
};

const transitionStyles: any = {
  entering: { transform: 'translateX(0%)' },
  entered: { transform: 'translateX(0%)' },
  exiting: { transform: 'translateX(100%)' },
  exited: { transform: 'translateX(100%)' },
};

const OuterContainer = styled(M.Container)`
  z-index: 999;
  position: absolute;
  width: 100%;
  padding: 0 !important;
  &&& {
    background-color: ${colors.uiNavy50} !important;
    margin-left: 10%;
    margin-bottom: 0px !important;
    padding-right: 10% !important;
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100vh !important;
    overflow-y: hidden;
    box-shadow: -800px 20px 20px 800px rgb(0 0 0 / 50%);

    & > div {
      height: 100%;
    }

    transition: box-shadow 'transform 1000ms ease-in-out';
    transform: box-shadow 'translateX(100%)';

    @media (max-width: 780px) {
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
      margin: 0%;
      height: 100%;
      padding: 0% !important;
    }
  }
`;

const InnerContainer = styled.div`
  height: 100vh !important;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 55;
`;

type Props = {
  isExpanded: boolean;
  children: any;
};

const Slideout: React.FC<Props> = ({ isExpanded, children }) => {
  return (
    <Transition in={isExpanded} timeout={1000} unmountOnExit>
      {(transitionState: string) => (
        <Overlay>
          <OuterContainer
            style={{
              ...defaultTransitionStyle,
              ...transitionStyles[transitionState],
            }}
          >
            <InnerContainer>{children}</InnerContainer>
          </OuterContainer>
        </Overlay>
      )}
    </Transition>
  );
};

export default Slideout;
