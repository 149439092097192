export enum Standard {
  Repeated = 'repeated',
  Quickstart = 'quickstart',
  Lookback = 'lookback-period',
}

const LOOKBACK_AND_PREMADE_OPTIONS = [Standard.Quickstart, Standard.Lookback];

export function isPremadeOrLookbackOption(option: Standard) {
  return LOOKBACK_AND_PREMADE_OPTIONS.includes(option);
}

export default Standard;
