import { AnyQueryKey, useQuery } from 'react-query';
import { AmsAPI } from 'utils/APIClient';

export const useFetchAccountUsers = ({
  accountId,
  params = {},
}: {
  accountId: string;
  params?: any;
}) => {
  const key: AnyQueryKey = ['account', accountId];
  const request = () =>
    AmsAPI.get(`/api/public/v1/accounts/${accountId}/users`, { params });

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};
