import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import LabelWithTooltip from './LabelWithTooltip';
import { DownloadLink } from './InvoicesStyledComponents';

type Props = {
  docId: string;
  fileType: string;
  filename: string;
  translation: Function;
  handleDownload: Function;
};

const InvoicesStructuredListRow: React.FC<Props> = ({
  docId,
  filename,
  fileType,
  translation,
  handleDownload,
}) => {
  const downloadClick = useCallback(e => handleDownload(e), []);

  return (
    <M.StructuredListRow
      data-testid='structured-row'
      key={`row-${fileType}`}
      className='borderless-list'
    >
      <LabelWithTooltip translation={translation} fileType={fileType} />
      <M.StructuredListCell
        data-testid='structured-cell'
        key={`row-${fileType}`}
        style={{ borderTop: 'none' }}
      >
        <DownloadLink
          href='#'
          download
          key={`${docId}-${fileType}`}
          onClick={downloadClick}
          data-id={docId}
          data-type={fileType}
        >
          {translation(`links.${fileType}`, { filename })}
        </DownloadLink>
      </M.StructuredListCell>
    </M.StructuredListRow>
  );
};

export default InvoicesStructuredListRow;
