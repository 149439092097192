import { SavedSearchesClient as client } from 'api';
import { GenericObject } from 'types';

const RESTRICTED_SAVE_KEYS = Object.freeze([
  'per_page',
  'page',
  'order_by',
  'sort',
]);

export const removeRestrictedKeys = (search: GenericObject) => {
  const redacted: GenericObject = {};
  Object.keys(search).forEach(key => {
    if (!RESTRICTED_SAVE_KEYS.includes(key)) redacted[key] = search[key];
  });
  return redacted;
};

export const list = (): Promise<{ [key: string]: any }> => {
  return client.get('');
};

export const create = (
  params: GenericObject,
): Promise<{ [key: string]: any }> => {
  return client.post('', removeRestrictedKeys(params));
};
export default list;
