import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from '../../common';
import Content from '../../Content';
import Sections from '../../sections';
import InfoPanel from './AmericansImage';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  > *:nth-child(2) {
    flex-grow: 1;
    flex-grow: 10;
  }
`;

const Container: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.intro}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { paragraphs, title } = translations;
  const pargraphsElem = (
    <Content paragraphs={paragraphs} section={Sections.intro} />
  );

  return (
    <Section divider data-testid='fair-chance-sections-intro'>
      <h3>{title}</h3>
      <StyledContainer>
        {pargraphsElem}
        <InfoPanel />
      </StyledContainer>
    </Section>
  );
};

export default Container;
