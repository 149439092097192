import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Description: React.FC<Props> = () => {
  const { t } = useTranslation();
  const text = t('assess:settings.description');

  return <p>{text}</p>;
};

export default Description;
