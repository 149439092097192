import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  InternationalCreditReportRecordBox,
  InternationalCreditReportRecordLine,
  InternationalCreditGrayCaption,
} from './InternationalCreditStyledComponents';

type Props = {
  record: { [x: string]: any };
  lastLine: boolean;
};

const Record: React.FC<Props> = ({ record, lastLine }) => {
  return (
    !!record && (
      <div>
        <InternationalCreditReportRecordBox>
          <InternationalCreditReportRecordLine>
            <InternationalCreditGrayCaption>
              {moment(record.date).format('MMM D, YYYY h:m A')}
              {' ('}
              {moment(record.date).fromNow()}
              {') '}
            </InternationalCreditGrayCaption>
            <br />
            <br />
            <M.Link
              href={record.pdf_url}
              target='_blank'
              rel='noopener noreferrer'
            >
              View {record.country_name} credit report
            </M.Link>
          </InternationalCreditReportRecordLine>
        </InternationalCreditReportRecordBox>
        {!lastLine && <hr />}
      </div>
    )
  );
};
export default Record;
