import React from 'react';
import { CsvExport, useCsvExport, ModalType } from 'components/CsvExport';
import {
  SubscriptionErrorsCSVExportFilters,
  useContinuousCheckCsv,
} from 'api/csvExport';

const Body = () => (
  <p>
    Choose a date range up to 90 days in length. Please contact Checkr if you
    need to download more than 90 days of information or view errors that
    occurred before 11/15/2022.
  </p>
);

const disableDatesBefore = '11/15/2022';

type Filters = Partial<SubscriptionErrorsCSVExportFilters>;

const SubscriptionErrorsExportCsv: React.FC<{}> = () => {
  const { screen, open, toggle, setScreen } = useCsvExport();
  const { call, result } = useContinuousCheckCsv(setScreen);

  const processing = result?.isLoading;
  const error = result?.isError;
  const errorMsg = result?.error;

  const filters: Filters = {
    export_type: 'subscription_error',
  };

  return (
    <CsvExport
      body={<Body />}
      error={error}
      errorMsg={errorMsg}
      filters={filters}
      open={open}
      post={call}
      processing={processing}
      screen={screen}
      toggle={toggle}
      type={ModalType.SubscriptionErrors}
      modalHeader='Download error history'
      dateRangeHeadline='Date range:'
      submitButtonName='Submit'
      dateRangeLimit={90}
      disableDatesBefore={disableDatesBefore}
      disableSubmitButtonUntilSetupDates
      defaultDateDiffStartFromDays={0}
    />
  );
};

export default SubscriptionErrorsExportCsv;
