import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, reject } from 'lodash';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { Button } from 'components/Button';
import { BackButton } from 'modules/assess/ui/common';
import { Container } from 'modules/assess/ui/page/Actions';
import {
  useDraft,
  useDraftDiff,
  useDraftPublish,
} from 'modules/assess/ui/ruleset/version/api';
import {
  Quickstart,
  LookbackPeriod,
} from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { isEmptyLookbackRule } from 'modules/assess/v2/ui/utils';
import { ID } from 'modules/id';
import SuccessDialog from '../dialog';

type Props = {
  disabled?: boolean;
  ruleset?: ID;
};

export const Actions: React.FC<Props> = ({ disabled, ruleset }) => {
  const history = useHistory();
  const track = useTrackEvent();
  const publish = useDraftPublish(ruleset, true);
  const draft = useDraft(ruleset);
  const diff = useDraftDiff(ruleset);

  const handleClick = useCallback(() => {
    publish.call();
  }, [publish]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const onClose = useCallback(() => {
    setShowSuccessDialog(false);
    history.push('/assess');
  }, [history, setShowSuccessDialog]);

  const trackRulesPublished = useCallback(() => {
    const rules = draft?.data?.rules;
    if (!rules) return;

    const { premade, lookback } = rules;
    const premadeRules = Object.values(premade);
    const lookbackRules = Object.values(lookback);

    // only track changes from active ruleset version
    if (!isEmpty(diff?.data?.rules?.premade)) {
      // Quickstart guidelines are tracked even they have been all deleted
      const guidelines = premadeRules.map(rule =>
        rule.name.replace(/^.*] /, ''),
      );

      track(Quickstart.Published, {
        [Quickstart.GuidelinesPublished]: guidelines.join(', '),
        [Quickstart.NumberPublished]: premadeRules.length,
      });
    }

    if (!isEmpty(diff?.data?.rules?.lookback)) {
      // Only non-empty LookbackPeriod rules are tracked
      const subcategories = reject(lookbackRules, isEmptyLookbackRule).map(
        rule => rule.charge_subcategory,
      );
      track(LookbackPeriod.Published, {
        [LookbackPeriod.SubcategoriesPublished]: subcategories.join(', '),
        [LookbackPeriod.NumberPublished]: subcategories.length,
      });
    }
  }, [diff, draft, track]);

  useEffect(() => {
    if (publish.result.status === 'success' && !showSuccessDialog) {
      trackRulesPublished();
      setShowSuccessDialog(true);
    }
  }, [publish, setShowSuccessDialog, showSuccessDialog, trackRulesPublished]);

  if (publish.result.status === 'loading') {
    return <M.LoadingSpinner />;
  }

  return (
    <Container
      justify='flex-end'
      data-testid='assess-v2-ruleset-version-review-and-apply-actions'
    >
      <SuccessDialog open={showSuccessDialog} onClose={onClose} />
      <BackButton navigateUp />
      <Button
        disabled={disabled}
        kind='primary'
        size='sm'
        onClick={handleClick}
        trans='verbs.publish'
        id='assess-v2-ui-guideline-review-and-apply-actions-container-publish-button'
        data-testid='assess-v2-ruleset-version-review-and-apply-publish'
      />
    </Container>
  );
};

export default Actions;
