import React from 'react';
import styled from 'styled-components';
import { Field as FieldModel } from 'modules/assess/models/rulesets/version-diff/common';
import { Field } from 'modules/assess/ui/ruleset/version/publish/changes/common/Field';

type Props = {
  position: FieldModel.Type;
};

const Container = styled.div`
  display: flex;
  align-self: center;
  span {
    font-size: 24px;
    font-weight: bold;
  }
`;

const PositionChange: React.FC<Props> = ({ position }) => {
  return (
    <Container>
      <Field field={position} includeName={false} />
    </Container>
  );
};

export default PositionChange;
