import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { find, isEqualWith, map, orderBy, filter } from 'lodash/fp';
import styled from 'styled-components';
import {
  ISelectedCandidate,
  TableHeader,
  TABLE_HEADER_KEYS,
  SORT_DIRECTIONS,
  SortState,
  TABLE_HEADER_VALUES,
} from './types';
import { getNextDirection, sortDirections, sortExcused } from './utils';

interface Props {
  id: number;
  selectedCandidates: ISelectedCandidate[];
  onSchedule: (selectedItems: ISelectedCandidate[]) => () => void;
  discloseAlternateSelectionModal: Function;
  setSelectedCandidateId: Function;
  isAlternateAvailable: boolean;
  setGroupedCandidates: Function;
}

const TABLE_KEYS: { [key: string]: string } = {
  FULL_NAME: 'fullName',
  TEST_TYPE: 'testType',
  STATUS: 'status',
  ACTIONS: 'actions',
};

const TABLE_HEADERS: TableHeader[] = [
  {
    key: TABLE_KEYS.FULL_NAME as TABLE_HEADER_KEYS,
    name: 'Employee name' as TABLE_HEADER_VALUES,
  },
  {
    key: TABLE_KEYS.TEST_TYPE as TABLE_HEADER_KEYS,
    name: 'Test type' as TABLE_HEADER_VALUES,
  },
  {
    key: TABLE_KEYS.STATUS as TABLE_HEADER_KEYS,
    name: 'Status' as TABLE_HEADER_VALUES,
  },
  {
    key: TABLE_KEYS.ACTIONS as TABLE_HEADER_KEYS,
    name: 'Actions' as TABLE_HEADER_VALUES,
  },
];

const SCREENING_TYPE_MAPPINGS: { [key: string]: string } = {
  drug: 'Drug',
  alcohol: 'Alcohol',
  drug_alcohol: 'Drug, Alcohol',
};

const SelectionTable: FC<Props> = ({
  id,
  selectedCandidates,
  onSchedule,
  setSelectedCandidateId,
  discloseAlternateSelectionModal,
  isAlternateAvailable,
  setGroupedCandidates,
}) => {
  const [sortingState, setSortingState] = useState<SortState>({
    sortHeaderKey: null,
    sortDirection: sortDirections.NONE,
  });
  const [selectedRows, setSelectedRows] = useState<Set<ISelectedCandidate>>(
    new Set(),
  );

  const isRowDisabled = useCallback(
    (rowId: string) => {
      const candidateRow = find(['id', Number(rowId)], selectedCandidates);
      const { reportId } = candidateRow || {};

      return Boolean(reportId || candidateRow?.excused);
    },
    [selectedCandidates],
  );

  const getCandidateLink = useCallback(
    (rowId: string) => {
      const candidate = find(['id', Number(rowId)], selectedCandidates);
      return candidate
        ? `${window.location.origin}/candidates/${candidate.candidateId}`
        : '';
    },
    [selectedCandidates],
  );

  const getCandidateStatus = useCallback(
    (rowId: string) => {
      const candidate = find(['id', Number(rowId)], selectedCandidates);

      if (candidate?.excused) return <p>{candidate.notes}</p>;

      return candidate ? candidate.status : '-';
    },
    [selectedCandidates],
  );

  const getScreeningPassUri: Function = useCallback(
    (rowId: string) => {
      const candidate = find(['id', Number(rowId)], selectedCandidates);
      return candidate ? candidate.screeningPassUri : '';
    },
    [selectedCandidates],
  );

  const getCandidateScreeningType = useCallback(
    (rowId: string) => {
      const candidate = find(['id', Number(rowId)], selectedCandidates);
      return candidate ? SCREENING_TYPE_MAPPINGS[candidate.screeningType] : '-';
    },
    [selectedCandidates],
  );

  const renderDownloadIcon = useCallback(
    () => <StyledIcon icon='DocumentDownload' />,
    [],
  );

  const renderAlternateIcon = useCallback(
    () => <StyledIcon icon='ArrowsHorizontal' />,
    [],
  );

  const onSelectAlternate = useCallback(
    (rowId: string) => {
      setSelectedCandidateId(rowId);
      discloseAlternateSelectionModal();
    },
    [discloseAlternateSelectionModal, setSelectedCandidateId],
  );

  const getAlternateTooltipMessage = useCallback(
    (rowId: string) =>
      isAlternateAvailable || isRowDisabled(rowId)
        ? 'Swap for alternate'
        : 'No available alternatives',
    [isAlternateAvailable, isRowDisabled],
  );

  const getSortKey = useCallback(
    (sortHeaderKey: TABLE_HEADER_KEYS, isExcused: boolean) => {
      switch (sortHeaderKey) {
        case TABLE_KEYS.STATUS:
          return isExcused ? 'notes' : 'status';
        case TABLE_KEYS.TEST_TYPE:
          return 'screeningType';
        default:
          return sortHeaderKey;
      }
    },
    [],
  );

  const customSortFunction = useCallback(
    (
      sortHeaderKey: TABLE_HEADER_KEYS | null,
      sortDirection: SORT_DIRECTIONS,
    ) => {
      return orderBy(
        getSortKey(sortHeaderKey!, true),
        (sortDirection as any)?.toLowerCase(),
        selectedCandidates,
      );
    },
    [getSortKey, selectedCandidates],
  );

  const compareCandidates = useCallback(
    (first: ISelectedCandidate, second: ISelectedCandidate) => {
      return isEqualWith(
        (candidateA, candidateB) => candidateA.id === candidateB.id,
        first,
        second,
      );
    },
    [],
  );

  const onSelectCandidate = useCallback(
    (selectedCandidate: ISelectedCandidate) => {
      const selectedCandidateList = Array.from(selectedRows);
      if (selectedRows?.has(selectedCandidate)) {
        setSelectedRows(
          new Set(
            selectedCandidateList.filter(
              (candidate: ISelectedCandidate) =>
                !compareCandidates(candidate, selectedCandidate),
            ),
          ),
        );
      } else {
        setSelectedRows(new Set([...selectedCandidateList, selectedCandidate]));
      }
    },
    [compareCandidates, selectedRows],
  );

  const tableRows = (
    <>
      {map((candidate: ISelectedCandidate) => {
        const candidateId = candidate.id.toString();
        const isDisabled = isRowDisabled(candidateId);
        const screeningPassUri = getScreeningPassUri(candidate.id);
        let candidateNameRow;
        if (candidate.candidateId) {
          candidateNameRow = (
            <M.Link inline href={getCandidateLink(candidateId)} target='_blank'>
              {candidate.fullName}
            </M.Link>
          );
        } else {
          candidateNameRow = candidate.fullName;
        }

        return (
          <M.TableRow
            data-testid={candidate.id}
            key={candidate.id}
            isSelected={isDisabled || selectedRows.has(candidate)}
          >
            <M.TableSelectRow
              id={`select-candidate-${candidateId}`}
              name={`select-candidate-${candidateId}`}
              ariaLabel='Select candidate'
              checked={!isDisabled && selectedRows.has(candidate)}
              // eslint-disable-next-line react/jsx-no-bind
              onSelect={() => onSelectCandidate(candidate)}
              disabled={isDisabled}
            />
            <M.TableCell key={candidate.id}>{candidateNameRow}</M.TableCell>
            <M.TableCell key={candidate.id}>
              {getCandidateScreeningType(candidateId)}
            </M.TableCell>
            <M.TableCell key={candidateId}>
              {getCandidateStatus(candidateId)}
            </M.TableCell>

            <StyledCell>
              {!isDisabled && (
                <M.Button
                  id='swap-for-alternate'
                  renderIcon={renderAlternateIcon}
                  kind='tertiary'
                  iconDescription={getAlternateTooltipMessage(candidateId)}
                  size='md'
                  className={!isAlternateAvailable ? 'disabled' : ''}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={
                    isAlternateAvailable
                      ? () => onSelectAlternate(candidateId)
                      : null
                  }
                  hasIconOnly
                />
              )}
              {screeningPassUri && (
                <M.Button
                  renderIcon={renderDownloadIcon}
                  kind='tertiary'
                  iconDescription='Download Screening Pass'
                  size='md'
                  href={screeningPassUri}
                  target='_blank'
                  hasIconOnly
                />
              )}
            </StyledCell>
          </M.TableRow>
        );
      }, selectedCandidates)}
    </>
  );

  const selectableCandidates = useMemo(
    () =>
      filter(
        (candidate: ISelectedCandidate) =>
          !isRowDisabled(candidate.id.toString()),
        selectedCandidates,
      ),
    [isRowDisabled, selectedCandidates],
  );

  const allRowsSelected = useMemo(
    () =>
      selectableCandidates.length > 0 &&
      selectableCandidates?.every((candidate: ISelectedCandidate) =>
        selectedRows?.has(candidate),
      ),
    [selectableCandidates, selectedRows],
  );

  const onSelectAll = useCallback(() => {
    if (!allRowsSelected) setSelectedRows(new Set(selectableCandidates));
    else setSelectedRows(new Set());
  }, [allRowsSelected, selectableCandidates]);

  const selectAllKey = `select-all${id}`;

  return (
    <>
      <TitleContainer>
        <Title>Randomly selected employees</Title>
        {selectedRows.size > 0 && (
          <M.Button
            kind='primary'
            onClick={onSchedule(Array.from(selectedRows))}
          >
            Order test
          </M.Button>
        )}
      </TitleContainer>

      <M.Table>
        <M.TableHead>
          <M.TableRow key='header'>
            <StyledTableSelectAll
              id={selectAllKey}
              name={selectAllKey}
              ariaLabel='Select all'
              checked={allRowsSelected}
              onSelect={onSelectAll}
              disabled={selectableCandidates.length === 0}
            />
            {map((header: TableHeader) => {
              return (
                <StyledTableHeader
                  key={header.key}
                  sortDirection={sortingState.sortDirection}
                  isSortHeader={header.key === sortingState.sortHeaderKey}
                  {...{
                    isSortable: header.key !== TABLE_HEADER_KEYS.ACTIONS,
                    onClick: (_e: SyntheticEvent) => {
                      const nextDirection = getNextDirection(
                        sortingState.sortDirection,
                      );
                      setSortingState({
                        sortHeaderKey: header.key,
                        sortDirection: nextDirection,
                      });
                      setGroupedCandidates(
                        sortExcused(
                          customSortFunction(header.key, nextDirection),
                        ),
                      );
                    },
                  }}
                >
                  {header.name}
                </StyledTableHeader>
              );
            }, TABLE_HEADERS)}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>{tableRows}</M.TableBody>
      </M.Table>
    </>
  );
};

export default SelectionTable;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledTableSelectAll = styled(M.TableSelectAll)`
  background-color: #e0e0e0 !important;

  label.cds--checkbox-label::before,
  label.cds--checkbox-label::after {
    background-color: white;
  }
`;

const StyledTableHeader = styled(M.TableHeader)`
  background-color: #e0e0e0 !important;
`;

const StyledCell = styled(M.TableCell)`
  padding: 0 !important;
`;

const StyledIcon = styled(M.Icon)`
  fill: ${colors.uiTextTertiaryLight} !important;
`;
