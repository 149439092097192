import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetAnalyticsDashboard } from 'api/analytics';
import CustomIframe from 'components/CustomIframe';

const DashboardName = 'fair_chance_dashboard_adverse_action_segmentation';

const LookerTile: React.FC = () => {
  const { url, isLoading } = useGetAnalyticsDashboard({
    lookerName: DashboardName,
  });

  if (isLoading) {
    return <M.LoadingSpinner />;
  }

  return (
    <CustomIframe
      src={url}
      id={DashboardName}
      className='looker-iframe'
      height='420px'
      width='100%'
    />
  );
};

export default LookerTile;
