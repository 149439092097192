import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/rulesets/version';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { StorePage, StyledComponents } from 'modules/assess/v2/ui/common';
import LessThanMisdemeanor from 'modules/assess/v2/ui/common/lookback-rules/less-than-misdemeanor';
import { Actions } from '../../common';
import CSVActions from './csv-actions';
import Header from './Header';
import Search from './Search';
import Table from './table';
import Title from './Title';

const StyledLessThanMisdemeanor = styled(LessThanMisdemeanor)`
  background-color: ${colors.uiGrey100};
  border-radius: 8px;
  border: 1px solid ${colors.uiGrey200};
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export type Props = {
  data?: Entity.Type;
  status?: string;
};

export const Container: React.FC<Props> = ({ data, status }) => {
  const [search, setSearch] = useState('');

  return (
    <StorePage
      data={data}
      id='assess-v2-ui-guideline-lookback-period-container'
      event={LookbackPeriod.Pageview}
      status={status}
    >
      <Actions option={Navigation.Lookback} />
      <Title />
      <StyledComponents.SlateContainer>
        <Header />
        <StyledLessThanMisdemeanor />
        <Search setSearch={setSearch} />
        <Table search={search} />
      </StyledComponents.SlateContainer>
      <CSVActions />
    </StorePage>
  );
};

export default Container;
