import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  CellDashboardLink,
  SpacedIcon,
  StyledPendingStatus,
} from './AdverseActionsStyledComponents';
import { AdverseAction } from './types';

type Props = {
  adverseAction: AdverseAction;
};

const StatusCell: React.FC<Props> = ({ adverseAction }) => {
  const pendingStatus = useCallback(() => {
    if (adverseAction.pause_data) {
      return 'Paused';
    }

    if (adverseAction.post_notice_scheduled_at) {
      return 'Scheduled';
    }

    return 'Pending...';
  }, [adverseAction]);

  return (
    <M.TableCell>
      <CellDashboardLink path={`/reports/${adverseAction.report_id}`}>
        {adverseAction.status === 'pending' && (
          <StyledPendingStatus>{pendingStatus()}</StyledPendingStatus>
        )}
        {adverseAction.status !== 'pending' && (
          <span>{capitalize(adverseAction.status)}</span>
        )}
        {adverseAction.delivery.state === 'error' && (
          <SpacedIcon icon='WarningFilled' fill={colors.uiOrange600} />
        )}
      </CellDashboardLink>
    </M.TableCell>
  );
};

export default StatusCell;
