import { AnyQueryKey, useQuery } from 'react-query';
import { useMemo } from 'react';
import { Exception } from 'types';
import { useFlag } from '@dashboard-experience/react-flagr';
import { VERIFICATIONS_EXCEPTIONS } from 'Constants';
import { getReportVerificationExceptions } from './actions';

export const useGetReportVerificationExceptions = (reportId: string) => {
  const request = () => getReportVerificationExceptions(reportId);
  const key: AnyQueryKey = ['reportVerificationExceptions', { reportId }];
  const flagrEnabled =
    useFlag(VERIFICATIONS_EXCEPTIONS)?.variantKey === 'on' || false;
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId && flagrEnabled,
  });
  const exceptions = useMemo(
    () => result?.data || {},
    [result?.data],
  ) as Exception[];
  return exceptions;
};

export default useGetReportVerificationExceptions;
