class CreditReportRecordUtils {
  private records: any;

  public constructor(record: any) {
    this.records = record;
  }

  public isDomestic(): boolean {
    return (
      this.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name === 'United States' ||
          creditRecord.country_name === 'US',
      ) !== undefined
    );
  }

  public isInternationalHasNonEmptyRecords(): boolean {
    return (
      this.records.find(
        (creditRecord: { country_name: string; pdf_url: string }) =>
          creditRecord.country_name && creditRecord.pdf_url,
      ) !== undefined
    );
  }

  public isEmpty(): boolean {
    return (
      this.records.find(
        (creditRecord: { country_name: string }) =>
          creditRecord.country_name !== 'United States',
      ) !== undefined
    );
  }

  public findDomesticRecords(): any {
    return this.records.filter(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        !creditRecord.pdf_url,
    );
  }

  public findInternationalRecords(): any {
    return this.records.find(
      (creditRecord: { country_name: string; pdf_url: string }) =>
        creditRecord.country_name && creditRecord.pdf_url,
    );
  }

  public findInternationalEmptyObject(): any {
    return this.records.find(
      (creditRecord: { country_name: string }) =>
        creditRecord.country_name !== 'United States',
    );
  }
}

export default CreditReportRecordUtils;
