import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import T from 'components/Table';
import { TaskType } from 'modules/case-management/types';
import { useTasks, useTaskSearchParams } from '../hooks';
import BatchAction from './BatchAction/Container';
import {
  generateTaskTableRows,
  taskTableColumns,
  useGenerateHeaders,
} from './helpers';

const StyledTable = styled(T)`
  && {
    margin-top: 2.5em;
    overflow-x: scroll;
  }
`;

const Table: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('case-management');
  const taskSearchParams = useTaskSearchParams();
  const { data, isLoading } = useTasks(taskSearchParams);
  const tasks = useMemo(() => data?.data ?? [], [data]);
  const pages = useMemo(() => data?.meta.pages ?? [], [data]);
  const [selections, setSelections] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setSelections(
      tasks.reduce((acc: Record<string, boolean>, task: TaskType) => {
        acc[task.id] = false;
        return acc;
      }, {}),
    );
  }, [tasks]);

  const columns = Object.values(taskTableColumns);
  const headers = useGenerateHeaders();

  const handleOnSelect = useCallback((taskId: string, selected: boolean) => {
    setSelections(prevSelections => ({
      ...prevSelections,
      [taskId]: selected,
    }));
  }, []);

  const clearSelections = useCallback(() => {
    setSelections(prevSelections => {
      const clearedSelections = { ...prevSelections };
      Object.keys(clearedSelections).forEach(key => {
        clearedSelections[key] = false;
      });
      return clearedSelections;
    });
  }, []);

  const rows = generateTaskTableRows({ isLoading, tasks });

  const handlePageClick = useCallback(
    (pageIndex: number) => {
      const searchParams = new URLSearchParams({
        ...taskSearchParams,
        page: String(pageIndex + 1),
      } as Record<string, string>);
      history.push({
        pathname: '/cm/tasks',
        search: searchParams.toString(),
      });
    },
    [history, taskSearchParams],
  );
  const selectedIndex = pages?.curr ? pages.curr - 1 : 0;

  const page = {
    count: pages?.count,
    index: selectedIndex,
    onClick: handlePageClick,
  };

  const empty = <p>{t('tasks.table.empty')}</p>;

  return (
    <>
      <BatchAction
        clearSelections={clearSelections}
        selections={selections}
        tasks={tasks}
      />
      <StyledTable
        data-testid='case-management-tasks-page-table'
        headers={headers}
        columns={columns}
        rows={rows}
        selections={selections}
        onSelect={handleOnSelect}
        page={page}
        empty={empty}
      />
    </>
  );
};

export default Table;
