import React, { useCallback, useEffect, useState } from 'react';
import { useNotifier } from 'hooks';
import { isEqual } from 'lodash';
import * as Notifications from 'state/notifications/entity';
import * as Entity from 'modules/assess/models/settings/account';
import { useTranslation } from 'react-i18next';
import PageLoading from 'components/PageLoading';
import RemoveEligibleRecordsDialogConfirmation from 'modules/assess/ui/settings/confirmation-dialog';
import UnsavedDialog from 'modules/assess/ui/settings/unsaved-dialog';
import { useFetch, useUpdate } from './api/hooks';
import Content from './Content';
import Header from './header';

type Props = {
  selected?: boolean;
  overrideTabSelection: () => void;
  resumeTabSelection: () => void;
};

export const Container: React.FC<Props> = ({
  selected,
  overrideTabSelection,
  resumeTabSelection,
}) => {
  const { t } = useTranslation('assess');
  const title = t('assess:settings.notifications.success.title');
  const message = t('assess:settings.notifications.success.message');

  const fetch = useFetch();
  const notifier = useNotifier();
  const update = useUpdate();

  const [settings, setSettings] = useState(fetch.data || Entity.initialize());
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showUnsaved, setShowUnsaved] = useState(false);
  const [
    showRemoveEligibleRecordsDialogConfirmation,
    setShowRemoveEligibleRecordsDialogConfirmation,
  ] = useState(false);

  const changed = !isEqual(fetch.data, settings);

  const reset = useCallback(() => {
    if (fetch.data && fetch.status === 'success') {
      setNeedsConfirmation(false);
      setSettings(fetch.data);
    }
  }, [fetch.data, fetch.status]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (fetch.status === 'success' && changed && !selected) {
      overrideTabSelection();
      setShowUnsaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed, overrideTabSelection, selected]);

  useEffect(() => {
    if (update.result.status === 'success') {
      const params = Notifications.success({ title, message });
      notifier(params);

      update.result.reset();
    }
  }, [update.result, notifier, title, message]);

  const handleSave = useCallback(async () => {
    if (needsConfirmation) {
      setShowRemoveEligibleRecordsDialogConfirmation(true);
    } else {
      await update.call(settings);
    }
  }, [
    needsConfirmation,
    setShowRemoveEligibleRecordsDialogConfirmation,
    update,
    settings,
  ]);

  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      handleSave();
    }
  }, [confirmed, handleSave, setConfirmed]);

  const handleConfirm = useCallback(() => {
    // 1) make modal disappear
    // 2) disable save button
    // 3) reset settings
    // 4) respect tab change
    reset();
    setShowUnsaved(false);
    resumeTabSelection();
  }, [reset, resumeTabSelection]);

  const handleConfirmRemoveEligibleRecordsOption = useCallback(() => {
    setNeedsConfirmation(false);
    setShowRemoveEligibleRecordsDialogConfirmation(false);
    setConfirmed(true);
  }, [setNeedsConfirmation, setShowRemoveEligibleRecordsDialogConfirmation]);

  const handleClose = useCallback(() => {
    // 1) make modal disappear
    setShowUnsaved(false);
  }, []);

  const closeRemoveEligibleRecordsDialogConfirmation = useCallback(() => {
    setShowRemoveEligibleRecordsDialogConfirmation(false);
  }, []);

  return (
    <div>
      <Header disabled={!changed} handleSave={handleSave} />
      <PageLoading enabled={update.result.status === 'loading'} />
      <Content
        locals={settings}
        remote={fetch.data}
        setNeedsConfirmation={setNeedsConfirmation}
        setSettings={setSettings}
      />
      <RemoveEligibleRecordsDialogConfirmation
        open={showRemoveEligibleRecordsDialogConfirmation}
        onClose={closeRemoveEligibleRecordsDialogConfirmation}
        onConfirm={handleConfirmRemoveEligibleRecordsOption}
      />
      <UnsavedDialog
        open={showUnsaved}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default Container;
