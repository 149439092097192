/* eslint-disable default-param-last */
import { Entity } from 'modules/entity';
import { tempid } from 'modules/id';
import * as Actions from '../actions';
import * as Cache from './cache';
import { assign } from '../reducers';
import { PositionPayload } from '../payloads/position';

export function create<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<Omit<E, 'id'>>,
): Cache.Type<E> {
  const { payload } = action;
  const entity = { ...payload, id: tempid() } as E;

  const ids = state.ids.concat(entity.id);
  const table = { ...state.table, [entity.id]: entity };

  return {
    ...state,
    ids,
    table,
  };
}

export function createAll<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<Array<Omit<E, 'id'>>>,
): Cache.Type<E> {
  const { payload } = action;
  const payloadWithIds = payload.map(p => {
    return { ...p, id: tempid() } as E;
  });

  const payloadIds = payloadWithIds.map(({ id }) => id);
  const payloadRecords = payloadWithIds.reduce((t, e) => {
    t[e.id] = e;
    return t;
  }, {} as Record<Entity['id'], E>);

  const ids = state.ids.concat(payloadIds);
  const table = { ...state.table, ...payloadRecords };

  return {
    ...state,
    ids,
    table,
  };
}

export function insert<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<E>,
): Cache.Type<E> {
  const { payload: entity } = action;

  const ids = state.ids.concat(entity.id);
  const table = { ...state.table, [entity.id]: entity };

  return {
    ...state,
    ids,
    table,
  };
}

export function load<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<Array<E> | Record<Entity['id'], E>>,
): Cache.Type<E> {
  const { payload } = action;
  const entities = Object.values(payload);

  const ids = entities.map(({ id }) => id);
  const table = entities.reduce((t, e) => {
    t[e.id] = e;
    return t;
  }, {} as Record<Entity['id'], E>);

  return {
    ...state,
    ids,
    table,
  };
}

export function position<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<PositionPayload>,
): Cache.Type<E> {
  const { payload } = action;

  const entityIndex = state.ids.indexOf(payload.id);
  let nextIndex = payload.position;

  if (payload.relative) {
    nextIndex += entityIndex;
  }

  if (nextIndex < 0 || nextIndex > state.ids.length - 1) {
    return {
      ...state,
    };
  }

  const ids = Object.values(state.ids);
  const element = ids[entityIndex];
  ids.splice(entityIndex, 1);
  ids.splice(nextIndex, 0, element);

  return {
    ...state,
    ids,
  };
}

export function remove<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<Entity>,
): Cache.Type<E> {
  const { payload: entity } = action;

  const ids = state.ids.filter(id => id !== entity.id);
  const table = { ...state.table };
  delete table[entity.id];

  return {
    ...state,
    ids,
    table,
  };
}

export function replace<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<E>,
): Cache.Type<E> {
  const { payload: entity } = action;

  const table = { ...state.table, [entity.id]: entity };

  return {
    ...state,
    table,
  };
}

export function update<E extends Entity>(
  state: Cache.Type<E> = Cache.initialize(),
  action: Actions.AnyWithPayload<E>,
  merger = assign.apply,
): Cache.Type<E> {
  const { payload } = action;
  const entity = merger(state.table[payload.id], payload);

  const table = { ...state.table, [entity.id]: entity };

  return {
    ...state,
    table,
  };
}
