import React, { useCallback } from 'react';
import * as Entity from 'modules/assess/models/rules/count';
import { tempid } from 'modules/id';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/count';
import { CancelButton } from 'modules/assess/ui/rules/common/rule/editor';

export type Props = {
  rule: Entity.Type;
};

const Cancel: React.FC<Props> = ({ rule }) => {
  const dispatch = useDispatch();

  const cancel = useCallback(() => {
    if (tempid.is(rule.id) && rule.initial) {
      dispatch(Actions.Remove.create(rule));
      return;
    }
    dispatch(Actions.Update.create({ ...rule, editing: false }));
  }, [dispatch, rule]);

  return <CancelButton onClick={cancel} />;
};

export default Cancel;
