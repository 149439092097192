import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import styled from 'styled-components';
import { useGetCredits } from 'api/billing';
import { useUser } from 'context/CurrentUser';

const StyledHeader = styled.header`
  margin: 0 0 1.5rem;

  .mastodon-modal-body {
    p,
    a {
      font-size: 1rem !important;
    }

    p {
      padding-right: 0;

      &:last-of-type {
        padding-bottom: 1.5rem;
      }
    }
  }
`;

const LearnMoreButton = styled(M.Button)`
  padding: 0 0.5rem !important;
  vertical-align: bottom;
`;

const Credit = styled.p`
  font-weight: bold;
  padding-bottom: 2px;
  margin-bottom: 0 !important;
  color: ${colors.uiTextPrimaryLight};
`;

const BillingHeader: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const closeModal = React.useCallback(() => setModalOpen(false), []);
  const openModal = React.useCallback(() => setModalOpen(true), []);
  const { account = {} } = useUser();
  const { data, isLoading } = useGetCredits(account?.id);
  const showCredit = data && +data.amount > 0;
  return (
    <StyledHeader>
      <h2>
        {t(`pageHeaders.billing.title`)}
        <LearnMoreButton kind='tertiary' size='sm' onClick={openModal}>
          <M.Icon icon='InformationFilled' />
          <span>{t(`pageHeaders.billing.learn_more_button`)}</span>
        </LearnMoreButton>
      </h2>
      {showCredit ? (
        <div>
          <Credit>
            {isLoading
              ? null
              : t(`pageHeaders.billing.available_credit`, {
                  credit: getDollarAmount(data?.amount.toString()),
                })}
          </Credit>
          <p>{t(`pageHeaders.billing.credit_appliance`)}</p>
        </div>
      ) : null}
      <M.ComposedModal onClose={closeModal} open={modalOpen}>
        <M.ModalHeader closeModal={closeModal}>
          <h2>{t(`pageHeaders.billing.title`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <p>
            {t(`pageHeaders.billing.modal.page_description_p1`)}{' '}
            <M.Link
              inline
              href='https://help.checkr.com/hc/en-us/articles/4429308795927-Payment-billing'
              target='_blank'
            >
              {t(`pageHeaders.billing.modal.learn_more_link`)}
            </M.Link>
          </p>
          <p>
            {t(`pageHeaders.billing.modal.page_description_p2`)}{' '}
            <M.Link inline href='https://www.stripe.com' target='_blank'>
              {t(`pageHeaders.billing.modal.stripe_link`)}
            </M.Link>
          </p>
        </M.ModalBody>
      </M.ComposedModal>
    </StyledHeader>
  );
};

export default BillingHeader;
