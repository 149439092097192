import React from 'react';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { useNamespace } from '../../router/context';
import View from './View';

export type Props = {
  disabled?: boolean;
};

export const CountRulesContainer: React.FC<Props> = ({ disabled }) => {
  const namespace = useNamespace();
  const state = useState();

  const rulesMap = state.rules?.count.table || {};
  const rulesList = Object.values(rulesMap);

  return (
    <div data-testid='assess-rules-count-container'>
      <View rules={rulesList} disabled={disabled} namespace={namespace} />
    </div>
  );
};

export default CountRulesContainer;
