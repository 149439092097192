import React from 'react';
import { SCREENING_TITLES } from 'utils';
import { ReportProps } from 'types';
import { StatusTypes } from '@dashboard-experience/utils';
import ReferenceVerification from './ReferenceVerification';

const PersonalReferenceVerification: React.FC<ReportProps> = ({
  report,
  // reportStatusType,
}) => {
  const { personal_reference_verifications: prvs } = report;
  // TODO - remove this and use the prop once migrated to Assess - forces legacy for now
  const reportStatusType = StatusTypes.Legacy;

  return prvs && prvs.length ? (
    <ReferenceVerification
      expandableTestId='screenings-personal-reference-verification-expandable'
      referenceVerifications={prvs}
      reportStatusType={reportStatusType}
      screeningTestId={SCREENING_TITLES.personal_reference_verifications.id}
      title='Personal Reference Verification'
    />
  ) : null;
};

export default PersonalReferenceVerification;
