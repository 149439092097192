import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  exam_reorder_modal_body,
  exam_reorder_modal_footer,
} from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEventOnMount,
  ReorderEvent,
  EventProperty,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import { getSelectedExams } from './screening-selection/utils';
import { useModalState } from '../../context';

import ModalBody from './body';

const body = `${exam_reorder_modal_body}.states.success`;

const Success = () => {
  const { t } = useTranslation();
  const onClose = useModalState('onClose');
  const { reorderableExams } = useModalState('formData');

  const exam_slugs = getSelectedExams(reorderableExams).map(exam => exam?.slug);

  useTrackEventOnMount(ReorderEvent.ModalReorderCompleted, {
    [EventProperty.ReorderedExams]: exam_slugs.join(', '),
    [EventProperty.NumberOfReorderedExams]: exam_slugs.length,
  });

  const navigation = {
    close: {
      name: t(`${exam_reorder_modal_footer}.close`),
      onClick: onClose,
    },
  };

  return (
    <>
      <ModalBody>
        <section>
          <h2>{t(`${body}.title`)}</h2>
          <p>{t(`${body}.description`)}</p>
        </section>
      </ModalBody>
      <M.ModalNavigationFooter close={navigation.close} />
    </>
  );
};

export default Success;
