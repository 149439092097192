/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useSelector } from 'react-redux';
import { getCurrentUserAccount } from 'reducers/CurrentUser.reducer';
import {
  Fact,
  FactRule,
  Operator,
  getRegexFactsByNamespace,
} from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import { FactsFilter } from 'modules/assess/models/FactsFilter';
import Kind from 'modules/assess/models/rules/common/kind';
import {
  DeleteButton,
  getFactsByNamespace,
  FactRuleOption,
  FactSelector,
  getFactConfig,
} from 'modules/assess/ui/rules/common/rule/editor/conditions';
import { Row } from 'modules/assess/ui/rules/common/rule';
import { Fact as MVRFact } from 'modules/assess/models/mvr/rules';
import ValueSelectorLegacy from './value-selector-legacy';
import RegexInput from './regex-input';

type Props = {
  index: number;
  condition: Partial<FactRule>;
  facts: Array<any>;
  kind: Kind;
  namespace: Namespace;
  deleteCondition: (index: number) => void;
  editCondition: (index: number, condition: Partial<FactRule>) => void;
};

const Condition: React.FC<Props> = ({
  index,
  condition,
  namespace,
  facts = getFactsByNamespace(namespace),
  kind,
  editCondition,
  deleteCondition,
}) => {
  const assess_ui_regex_condition_authorized = useSelector(
    getCurrentUserAccount,
  )?.assess_ui_regex_condition_authorized;

  const assess_context_date_override = useSelector(
    getCurrentUserAccount,
  )?.assess_context_date_override;

  const setFact = useCallback(
    (fact: Fact) => {
      const c = getFactConfig(namespace, fact);
      editCondition(index, { fact, operator: c?.operator });
    },
    [namespace, editCondition, index],
  );

  const setValue = useCallback(
    (value: FactRuleOption) => editCondition(index, { ...condition, ...value }),
    [condition, index, editCondition],
  );

  const useRegexInput =
    (assess_ui_regex_condition_authorized &&
      condition.operator === Operator.MRI) ||
    condition.fact === MVRFact.RESTRICTIONS;

  let selector = null;
  if (condition.fact) {
    if (useRegexInput && condition.operator) {
      selector = (
        <RegexInput
          operator={condition.operator}
          value={condition.value}
          setValue={setValue}
        />
      );
    } else {
      selector = (
        <M.GridCol lg={6} md={3} sm={1}>
          <ValueSelectorLegacy
            condition={condition}
            kind={kind}
            namespace={namespace}
            setValue={setValue}
          />
        </M.GridCol>
      );
    }
  }

  const regexFacts = getRegexFactsByNamespace(namespace);
  const filteredFacts = new FactsFilter(
    facts,
    regexFacts,
    kind,
    assess_context_date_override,
    assess_ui_regex_condition_authorized,
  ).getFilteredFacts();

  return (
    <Row data-testid='assess-rules-rule-editor-rule-condition'>
      <M.GridCol lg={6} md={3} sm={1}>
        <FactSelector
          fact={condition.fact}
          facts={filteredFacts}
          setFact={setFact}
        />
      </M.GridCol>
      {selector}
      <DeleteButton index={index} deleteCondition={deleteCondition} />
    </Row>
  );
};

export default Condition;
