import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getDocument,
  ReportStatuses,
  Report,
} from '@dashboard-experience/utils';
import { useLogPDFDownload } from 'api/documents';

const paPdfDocumentType = 'pa_child_abuse_result_certificate';

type Props = {
  report: Report;
};

const DownloadLink: React.FC<Props> = ({ report }) => {
  const paPdfDocument = getDocument(report, paPdfDocumentType);
  const { call: logDownload } = useLogPDFDownload({
    reportId: report.id,
    type: paPdfDocumentType,
  });
  const disabled = report.status === ReportStatuses.PENDING;

  if (!paPdfDocument) return null;

  const link = (
    <M.Link
      disabled={disabled}
      download
      href={paPdfDocument.download_uri}
      target='_blank'
      onClick={logDownload}
      id='pa-child-abuse-result-certificate'
    >
      Download PA State Registry Certificate
    </M.Link>
  );
  return disabled ? (
    <M.TooltipDefinition
      highlighted={false}
      align='top'
      definition='Unable to download until report is completed'
    >
      {link}
    </M.TooltipDefinition>
  ) : (
    link
  );
};

export default DownloadLink;
