import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import {
  DeletePaymentMethodParams,
  HELP_PAYMENT_BILLING_URL,
  PaymentMethod,
  useDeletePaymentMethod,
} from 'api/payment';
import { scrollToTop } from 'utils';
import BAVerification from './BAVerification';
import EditPaymentMenu from './EditPaymentMenu';
import {
  GridCol,
  StyledWarningInlineNotification,
  StyledLink,
} from './PaymentStyledComponents';
import ConfirmModal from '../../ConfirmModal';
import DialogModal from './DialogModal';
import CardDetails from './CardDetails';
import BankDetails from './BankDetails';

type Props = {
  paymentInfo: PaymentMethod;
  verified: boolean;
  paymentMethodRequired: boolean;
};

const PaymentDisplay: React.FC<Props> = ({
  paymentInfo,
  verified,
  paymentMethodRequired,
}) => {
  const { t } = useTranslation();
  const user = useUser();
  const [isVerified, setIsVerified] = useState(false);
  const [modalConfirmDeleteOpen, setModalConfirmDeleteOpen] = useState(false);
  const [dialogModalOpen, setDialogModalOpen] = useState(false);
  const { deletePaymentMethodCall }: any = useDeletePaymentMethod();

  const verifiedPayment: boolean = verified || isVerified;

  const hideConfirmDeleteModal = useCallback((): void => {
    setModalConfirmDeleteOpen(false);
  }, [setModalConfirmDeleteOpen]);

  const confirmDeletePaymentMethod = useCallback((): void => {
    const deleteParams: DeletePaymentMethodParams = {
      accountId: user.account.id,
      stripeId: paymentInfo.stripe_id,
    };

    deletePaymentMethodCall(deleteParams);
    setModalConfirmDeleteOpen(false);
  }, [
    deletePaymentMethodCall,
    setModalConfirmDeleteOpen,
    paymentInfo.stripe_id,
    user.account.id,
  ]);

  const showConfirmDeleteModal = useCallback((): void => {
    setModalConfirmDeleteOpen(true);
    scrollToTop();
  }, [setModalConfirmDeleteOpen]);

  const showDialogModal = useCallback((): void => {
    setDialogModalOpen(true);
    scrollToTop();
  }, [setDialogModalOpen]);

  const hideDialogModal = useCallback((): void => {
    setDialogModalOpen(false);
  }, [setDialogModalOpen]);

  const modalOpener =
    paymentInfo.is_default_payment && paymentMethodRequired
      ? showDialogModal
      : showConfirmDeleteModal;

  const modalHeader =
    paymentInfo.payment_type === 'card'
      ? t(`payment.cc_info.delete_modal_header`)
      : t(`payment.bank_information.delete_modal_header`);

  const modalMessage =
    paymentInfo.payment_type === 'card'
      ? t(`payment.cc_info.delete_modal_message`)
      : t(`payment.bank_information.delete_modal_message`);

  const modalToDisplay = useCallback((): any => {
    return paymentInfo.is_default_payment && paymentMethodRequired ? (
      <DialogModal
        header={`${t(`payment.delete_default.header`)}`}
        message={`${t(`payment.delete_default.message`)}`}
        buttonText={`${t(`payment.delete_default.button`)}`}
        hideModal={hideDialogModal}
        open={dialogModalOpen}
      />
    ) : (
      // @ts-ignore
      <ConfirmModal
        header={modalHeader}
        message={modalMessage}
        hideModal={hideConfirmDeleteModal}
        callback={confirmDeletePaymentMethod}
        open={modalConfirmDeleteOpen}
        confirmMessage={t('buttons.confirm')}
      />
    );
  }, [
    t,
    paymentMethodRequired,
    paymentInfo.is_default_payment,
    hideConfirmDeleteModal,
    confirmDeletePaymentMethod,
    modalConfirmDeleteOpen,
    hideDialogModal,
    dialogModalOpen,
    modalHeader,
    modalMessage,
  ]);

  const paymentDetails = useCallback((): any => {
    return paymentInfo.payment_type === 'card' ? (
      <CardDetails cardInfo={paymentInfo} />
    ) : (
      <BankDetails bankInfo={paymentInfo} />
    );
  }, [paymentInfo]);

  const showBAVerification = useCallback((): any => {
    return !paymentInfo.ach_verification_url && !verifiedPayment;
  }, [paymentInfo.ach_verification_url, verifiedPayment]);

  const unverifiedWarning = useCallback((): any => {
    return paymentInfo.ach_verification_url ? (
      <>
        <span>
          <M.Link
            className='stripeVerificationLink'
            href={paymentInfo.ach_verification_url}
            target='_blank'
          >
            {t(`payment.bank_account_verification.verify_with_stripe`)}
          </M.Link>
        </span>
        <br />
        <span>
          {`${t(
            `payment.bank_account_verification.notification_description_3`,
          )}`}
        </span>
      </>
    ) : (
      <>
        <span>
          {`${t(
            `payment.bank_account_verification.notification_description_1`,
          )}`}
        </span>
        <br />
        <span>
          {`${t(
            `payment.bank_account_verification.notification_description_2`,
          )}`}
          <StyledLink href={HELP_PAYMENT_BILLING_URL} target='_blank'>
            {t(`payment.bank_account_verification.learn_more_link`)}
          </StyledLink>
        </span>
      </>
    );
  }, [t, paymentInfo.ach_verification_url]);

  return (
    <>
      {modalToDisplay()}

      {!verifiedPayment && (
        <StyledWarningInlineNotification
          inline='classic'
          hideActionButton
          data-testid='ach-verification-banner'
          kind='warning'
          title={`${t(`payment.bank_account_verification.notification_title`)}`}
          subtitle={unverifiedWarning()}
        />
      )}

      <M.GridRow className='mt-16' data-testid='payment-info'>
        {paymentDetails()}
        <GridCol md={1} style={{ display: 'flex', justifyContent: 'end' }}>
          <EditPaymentMenu
            showDeleteModal={modalOpener}
            isDefault={paymentInfo.is_default_payment}
            testId='payment-overflow-menu'
            stripeId={paymentInfo.stripe_id}
            verified={verifiedPayment}
          />
        </GridCol>
      </M.GridRow>

      {showBAVerification() && (
        <BAVerification setIsVerified={setIsVerified} bankInfo={paymentInfo} />
      )}
    </>
  );
};

export default PaymentDisplay;
