import StyledComponent from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Status } from '../types';

const calcHeight = (status: Status) => {
  switch (status) {
    case 'Success':
      return '63px';
    case 'Failure':
      return '86px';
    default:
      return '52px';
  }
};

export const ItemWrapper = StyledComponent.div<{ status: Status }>`
  padding: 0px;
  gap: 16px;
  border: 0px 0px 1px 0px;
  border-bottom: 1px solid ${colors.uiGrey200};
  height: ${props => calcHeight(props.status)};
  display: flex;
  flex-direction: row;
`;

export const DescriptionContainer = StyledComponent.div`
  width: 50%;
  height: 36px;
  gap: 0px;
  position: relative;
`;

export const ButtonContainer = StyledComponent.div`
  width: 50%;
  padding: 0px 24px 0px 16px;
  display: flex;
  flex-direction: row-reverse;
`;

export const Button = StyledComponent(M.Button).attrs({
  kind: 'tertiary',
})`
  min-height: 36px;
  height: 36px;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
  opacity: 0px;
`;

export const Description = StyledComponent.span`
  height: 18px;
  top: 9px;
  left: 24px;
  gap: 0px;
  opacity: 0px;
  position: absolute;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: ${colors.uiTextPrimaryLight};
`;

export const Spinner = StyledComponent(M.LoadingSpinner).attrs({
  withOverlay: false,
  small: true,
})`
  height: 16px;
  width: 16px;
`;
export const SpinnerContainer = StyledComponent.div`
  padding: 10px 16px;
`;

export const ActionContainer = StyledComponent.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
