/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Active from './active';
import Draft from './draft';
import Instance from './instance';
import List from './list';

export type Params = {};

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { path: base } = match;

  return (
    <Switch>
      <Route path={`${base}/active`} children={<Active />} />
      <Route path={`${base}/draft`} children={<Draft />} />
      <Route path={`${base}/:version`} children={<Instance />} />
      <Route path={base} children={<List />} />
    </Switch>
  );
};

export default Router;
