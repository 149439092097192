/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const FormTitle = styled.p`
  margin-bottom: 2em !important;
`;

export const TestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TestNumberContainer = styled.div`
  display: flex;
  gap: 23px;
  width: 80%;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TestInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const List = styled.ul`
  font-size: 14px;
  color: ${colors.uiTextSecondaryLight};
  list-style: initial;
  padding: 0.5rem 1em;
  margin-left: 1em;

  &::marker {
    color: ${colors.uiTextSecondaryLight};
  }
`;
