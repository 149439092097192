import React from 'react';
import { ReportProps } from 'types';
import FederalDistrictSearch from './FederalDistrictSearch';

const FederalDistrictCivilSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { federal_district_civil_searches } = report;
  if (!federal_district_civil_searches?.length) return null;

  return (
    <FederalDistrictSearch
      report={report}
      reportStatusType={reportStatusType}
      federalType='federal-district-civil-searches'
    />
  );
};

export default FederalDistrictCivilSearch;
