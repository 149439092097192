import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { NoteType } from 'modules/case-management/types';
import DeleteNoteModal from './DeleteNoteModal';

type PropTypes = {
  currentUserNote: boolean;
  note: NoteType;
  onEdit: () => void;
};

const UpdateButton = styled(M.Button)`
  padding: 1px !important;
  margin-right: 0 !important;
  margin-left: 8px !important;
`;

const BtnContainer = styled.div`
  display: flex;
`;

const UpdateButtons = ({ currentUserNote, note, onEdit }: PropTypes) => {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [setDeleteModalOpen, deleteModalOpen]);

  const canUpdate = currentUserNote && !note.discarded_at;
  if (!canUpdate) return null;

  return (
    <BtnContainer data-testid='case-management-note-update-btns'>
      <UpdateButton
        data-testid='case-management-edit-note-btn'
        kind='tertiary'
        onClick={onEdit}
      >
        <M.Icon icon='Edit' />
      </UpdateButton>
      <UpdateButton
        data-testid='case-management-delete-note-btn'
        onClick={toggleDeleteModal}
        kind='tertiary'
      >
        <M.Icon icon='TrashCan' />
      </UpdateButton>
      {deleteModalOpen && (
        <DeleteNoteModal
          noteId={note.id}
          open={deleteModalOpen}
          toggleOpen={toggleDeleteModal}
        />
      )}
    </BtnContainer>
  );
};

export default UpdateButtons;
