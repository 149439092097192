const SettingsSchema = () => ({
  adverse_action_email: {
    disabled: false,
    index: 5,
    type: 'email',
    value: '',
  },
  billing_email: {
    disabled: false,
    index: 4,
    type: 'email',
    value: '',
  },
  name: {
    disabled: false,
    index: 0,
    type: 'text',
    value: '',
  },
  compliance_contact_email: {
    disabled: false,
    index: 7,
    type: 'email',
    value: '',
  },
  support_email: {
    disabled: false,
    index: 3,
    type: 'email',
    value: '',
  },
  support_phone: {
    disabled: false,
    index: 2,
    type: 'tel',
    value: '',
  },
  technical_contact_email: {
    disabled: false,
    index: 6,
    type: 'email',
    value: '',
  },
  uri_name: {
    disabled: true,
    index: 1,
    type: 'text',
    value: '',
  },
});

export default SettingsSchema;
