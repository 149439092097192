/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { API } from 'utils/APIClient';
import { debug } from 'utils';
import querystring from 'querystring';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { useMutation } from 'react-query';

export const FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST =
  'FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST';
export const FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS =
  'FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS';
export const FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE =
  'FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE';

export const FETCH_BULK_ACTION_REQUEST = 'FETCH_BULK_ACTION_REQUEST';
export const FETCH_BULK_ACTION_SUCCESS = 'FETCH_BULK_ACTION_SUCCESS';
export const FETCH_BULK_ACTION_FAILURE = 'FETCH_BULK_ACTION_FAILURE';

const DEFAULT_DOWNLOAD_ERROR = 'Download failed, please try again later.';

export const requestContinuousCheckCsvExport = params => {
  return API.post('/continuous_check/csv_exports', {
    export_type: params.filters.export_type,
    start_at: params.filters.created_after,
    end_at: params.filters.created_before,
  })
    .then(res => {
      if (params.filters.export_type === 'document_audit_log') {
        window.location.href = res.download_uri;
      }
    })
    .catch(error => debug(`Unable to get updated csv_export: ${error}`));
};

export const fetchContinuousCheckDriverRecordReports =
  (accountId, documentType, searchValues, params) => async dispatch => {
    dispatch({ type: FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST });
    try {
      const generalParams = querystring.encode(params);
      let queryParams = '';
      Object.keys(searchValues).forEach(key => {
        const param = `&${key}=${searchValues[key]}`;
        if (searchValues[key]) {
          queryParams += param;
        }
      });

      const res = await API.get(
        `/accounts/${accountId}/continuous_check/documents?document_type=${documentType}&${generalParams}${queryParams}`,
      );
      if (res) {
        return dispatch({
          type: FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS,
          driverRecordReports: res.documents,
          driverRecordReportsTotal: res.total,
          searchValues: {
            candidate_id: searchValues.candidate_id,
            created_at_from: searchValues.created_at_from,
            created_at_to: searchValues.created_at_to,
          },
          params: {
            page: params.page,
            per_page: params.per_page,
            order_by: params.order_by,
            sort: params.sort,
          },
        });
      }

      return dispatch({
        type: FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE,
      });
    } catch (e) {
      return dispatch({
        type: FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE,
      });
    }
  };

export const clickAndRedirectUri = uri => {
  const element = document.createElement('a');
  element.target = '_blank';
  element.href = uri;
  element.click();
  element.remove();
};

export const fetchContinuousServicesBulkActions =
  (accountId, paramsBulkAction) => async dispatch => {
    dispatch({ type: FETCH_BULK_ACTION_REQUEST });
    try {
      const paginationParams = querystring.encode(paramsBulkAction);
      const res = await API.get(
        `/continuous_check/csv_uploads?account_id=${accountId}&${paginationParams}`,
      );
      if (res) {
        return dispatch({
          type: FETCH_BULK_ACTION_SUCCESS,
          bulkActions: res.csv_uploads,
          bulkActionsTotal: res.total,
          paramsBulkAction: {
            page: paramsBulkAction.page,
            per_page: paramsBulkAction.per_page,
          },
        });
      }

      return dispatch({
        type: FETCH_BULK_ACTION_FAILURE,
      });
    } catch (e) {
      return dispatch({
        type: FETCH_BULK_ACTION_FAILURE,
      });
    }
  };

export const useDownloadContinuousCheckFile = () => {
  const dispatch = useDispatch();
  const request = uri => API.get(uri);

  const [call, result] = useMutation(request, {
    onError: () => {
      dispatch(toastError(DEFAULT_DOWNLOAD_ERROR));
    },
    onSuccess: data => {
      clickAndRedirectUri(data.uri);
    },
  });
  return {
    call,
    result,
  };
};
