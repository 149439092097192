import styled from 'styled-components';

const Title = styled.p`
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
  font-size: 18px !important;
  font-weight: bold;
`;

export default Title;
