import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, orderByKey } from '@dashboard-experience/utils';
import {
  InternationalLicenseReport,
  InternationalMotorVehicleReport,
  ReportProps,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { STATUS_ORDER } from 'Constants';
import formatCountry from './utils';
import InternationalMotorVehicleReportRecord from './InternationalMotorVehicleReportRecord';

const InternationalMotorVehicleReports: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_motor_vehicle_reports } = report;

  const screenings: false | InternationalMotorVehicleReport[] = useMemo(
    () =>
      !!international_motor_vehicle_reports &&
      (orderByKey(
        international_motor_vehicle_reports,
        'status',
        STATUS_ORDER,
      ) as InternationalMotorVehicleReport[]),
    [international_motor_vehicle_reports],
  );

  return screenings && screenings.length > 0 ? (
    <M.Screening
      id={SCREENING_TITLES.international_motor_vehicle_reports.id}
      data-testid='international-motor-vehicle-report'
      title='International Motor Vehicle Report'
      statusType={reportStatusType}
      screening={screenings}
      hideCompletedAt
    >
      {screenings.map((screening: any, idx: number) => {
        const country = formatCountry(screening.country);
        const status = getResultOrStatus(screening);
        const mvrRecords = screening.license_reports;
        const pdfUrl = screening.pdf_url;
        const key = `international-motor-vehicle-report-${screening.country}-${idx}`;
        return (
          <M.SubScreening
            key={key}
            title={country}
            status={status}
            statusType={reportStatusType}
            screening={screening}
          >
            {mvrRecords?.map(
              (record: InternationalLicenseReport, i: number) => {
                return (
                  <React.Fragment key={record.license_infos[0].license_number}>
                    <InternationalMotorVehicleReportRecord
                      candidate={report.candidate}
                      licenseReport={record}
                      pdfUrl={pdfUrl}
                    />
                    {i < mvrRecords.length - 1 && <M.Divider />}
                  </React.Fragment>
                );
              },
            )}
          </M.SubScreening>
        );
      })}
    </M.Screening>
  ) : null;
};

export default InternationalMotorVehicleReports;
