export enum ReorderEvent {
  ToastNotificationClicked = 'Checkr Dashboard Report View Health Screening Reorder Toast Notification Clicked',
  ModalScreeningSelectionViewed = 'Checkr Dashboard Report View Health Screening Exam Reorder Screening Selection Viewed',
  ModalAppointmentDeadlineViewed = 'Checkr Dashboard Report View Health Screening Exam Reorder Appointment Deadline Viewed',
  ModalClinicSelectionViewed = 'Checkr Dashboard Report View Health Screening Exam Reorder Clinic Selection Viewed',
  ModalReviewViewed = 'Checkr Dashboard Report View Health Screening Exam Reorder Review Viewed',
  ModalReorderCompleted = 'Checkr Dashboard Report View Health Screening Exam Reorder Completed',
  ErrorTriggered = 'Checkr Dashboard Report View Health Screening Exam Reorder Error Triggered',
  ErrorAction = 'Checkr Dashboard Report View Health Screening Exam Reorder Error Action',
}

export type Events = (typeof ReorderEvent)[keyof typeof ReorderEvent];

export enum EventProperty {
  ReorderedExams = 'Reordered Exams',
  NumberOfReorderedExams = 'Number of Reordered Exams',
  Error = 'Error',
  ErrorAction = 'Error Action',
}

export enum EventValue {
  ClinicError = 'Clinic Error',
  ServerError = 'Server Error',
  Cancel = 'Cancel',
  Resubmit = 'Resubmit',
  ChooseNewClinic = 'Choose New Clinic',
  Back = 'Back',
}
