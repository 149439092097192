import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import { fromUrlToTranslation } from 'modules/assess/v2/ui/utils';

type Props = {
  option: Option;
};

const StyledDiv = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${colors.uiTextPrimaryLight};
`;

const Title: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation('assess:v2');
  const translationOption = fromUrlToTranslation(option);
  const text = t(`lite.${translationOption}.header.title`);

  return (
    <StyledDiv data-testid='assess-v2-ui-lite-common-header-title'>
      {text}
    </StyledDiv>
  );
};

export default Title;
