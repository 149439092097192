import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useCandidate } from 'providers/Candidate';
import { useGetSubscriptions } from 'api/continuousChecks';
import * as SubscriptionType from 'modules/core-types/subscription';
import { isUndefined } from 'lodash';
import Subscription from './Subscription';
import SubscriptionCreate from './SubscriptionCreate';
import PostHireModuleContainer from '../PostHire/PostHireModuleContainer';

const SubscriptionContainer: React.FC = () => {
  const candidate = useCandidate();
  const candidateId = candidate.id;
  const result = useGetSubscriptions(candidateId);
  const { data: subscriptionsResponse, isLoading } = result;
  const [subscriptions, setSubscriptions] = useState(Array<any>([]));

  useEffect(() => {
    if (isUndefined(subscriptionsResponse)) return;
    setSubscriptions(
      subscriptionsResponse.data as Array<SubscriptionType.Type>,
    );
  }, [subscriptionsResponse]);

  const updateSubscription = useCallback(
    (data: SubscriptionType.Type, action: string) => {
      let subs = subscriptions;
      switch (action) {
        case 'delete':
          subs = subs?.filter((el: SubscriptionType.Type) => el.id !== data.id);
          setSubscriptions(subs);
          return;
        case 'add':
          subs = subscriptions.concat([data]);
          setSubscriptions(subs);
          return;
        case 'update':
          subs = subs?.filter((el: SubscriptionType.Type) => el.id !== data.id);
          subs = subscriptions.concat([data]);
          setSubscriptions(subs);
      }
    },
    [subscriptions],
  );

  return (
    <PostHireModuleContainer
      id='subscriptions'
      title={
        <>Subscriptions ({isLoading ? '...' : subscriptions?.length || 0})</>
      }
      tooltipText='Run recurring background checks at an interval of your choice (ex. every 3 months).'
    >
      <SubscriptionCreate
        candidate={candidate}
        updateSubscriptions={updateSubscription}
      />
      {!isLoading && subscriptions?.length > 0 && (
        <M.Grid condensed>
          {subscriptions.map((subscription: SubscriptionType.Type, i) => (
            <Subscription
              key={subscription?.id || i + 1}
              candidate={candidate}
              subscription={subscription}
              updateSubscriptions={updateSubscription}
            />
          ))}
        </M.Grid>
      )}
    </PostHireModuleContainer>
  );
};

export default SubscriptionContainer;
