import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { CASE_MANAGEMENT_TASK_UI_UPDATES_FLAG_KEY } from 'Flags';
import PageContainer from '../shared/PageContainer';
import SettingsBanner from './SettingsBanner';
import SettingsContainer from './SettingsContainer';

export const Settings: React.FC = () => {
  // TODO: Remove flag EADA-4514
  const cmUIUpdateEnabled =
    useFlag(CASE_MANAGEMENT_TASK_UI_UPDATES_FLAG_KEY).variantKey === 'on';

  return (
    <PageContainer headerText='Settings' testId='case-management-settings-page'>
      {cmUIUpdateEnabled ? <SettingsContainer /> : <SettingsBanner />}
    </PageContainer>
  );
};

export default Settings;
