import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Container = styled.div`
  // match the height of the modal
  height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .mastodon-binary-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  }
`;

type Props = {
  hideDelete: () => void;
  onFileDelete: () => void;
};

const Delete: React.FC<Props> = ({ hideDelete, onFileDelete }) => {
  return (
    <Container data-testid='delete-brand-logo-screen'>
      <div className='title-container'>
        <h2>Are you sure you want to delete this logo?</h2>
      </div>
      <M.BinaryButtons
        btnLeft={{ name: 'Yes', onClick: onFileDelete }}
        btnRight={{
          name: 'Cancel',
          onClick: hideDelete,
        }}
        size='sm'
      />
    </Container>
  );
};

export default Delete;
