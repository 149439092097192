import React from 'react';
import styled from 'styled-components';
import SecondaryActions from './secondary-actions';
import LifeCycleActions from './lifecycle-actions';

type Props = {
  isScrolled: boolean;
};

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ReportActionsV2: React.FC<Props> = () => {
  return (
    <Container data-testid='candidate-header-report-actions-v2'>
      <LifeCycleActions />
      <SecondaryActions />
    </Container>
  );
};

export default ReportActionsV2;
