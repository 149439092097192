import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StyledContainer, ToolBarContainer } from './UsersStyledComponents';

type Props = {
  multiSelectRoles: string[];
  getToolbarProps: () => any;
  filterByEmail: (str: any) => any;
  filterByRoles: (items: string[]) => any;
  isLoading: boolean;
};

const UsersToolBar: React.FC<Props> = ({
  getToolbarProps,
  filterByEmail,
  filterByRoles,
  multiSelectRoles,
  isLoading,
}) => {
  const emailFilter = useCallback(
    (e: any) => {
      filterByEmail(e.currentTarget && e.currentTarget.value);
    },
    [filterByEmail],
  );

  const roleFilter = useCallback(
    (obj: any) => filterByRoles(obj.selectedItems),
    [filterByRoles],
  );

  return (
    <ToolBarContainer>
      <StyledContainer>
        <M.GridRow>
          <M.GridCol sm={12} md={4} lg={6}>
            <M.TableToolbar {...getToolbarProps()}>
              <M.TableToolbarContent>
                <M.TableToolbarSearch
                  onChange={emailFilter}
                  persistent
                  placeholder='Search users'
                />
              </M.TableToolbarContent>
            </M.TableToolbar>
          </M.GridCol>
          <M.GridCol sm={12} md={4} lg={6}>
            <M.MultiSelect
              useTitleInItem={false}
              id='role-filter'
              label='Filter by role'
              onChange={roleFilter}
              items={multiSelectRoles}
              hideLabel
            />
          </M.GridCol>
          <M.GridCol sm={12} md={12} lg={4}>
            {isLoading && <M.LoadingInline description='Searching...' />}
          </M.GridCol>
        </M.GridRow>
      </StyledContainer>
    </ToolBarContainer>
  );
};

export default UsersToolBar;
