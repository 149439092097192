import { MouseEventHandler } from 'react';

export type BetaVisibilityFunction = (flagKey: string) => MouseEventHandler;

export enum BetaState {
  DISABLED = 'disabled',
  INITIAL = 'initial',
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
}

export type BetaStateValues = (typeof BetaState)[keyof typeof BetaState];

export type BetaStates = {
  [flag: string]: BetaStateValues;
};

export type BetaOnChangeFunction = (betaState: BetaState) => any;

export type BetaContextType = {
  betaStates: BetaStates;
  showBeta: BetaVisibilityFunction;
  hideBeta: BetaVisibilityFunction;
};
