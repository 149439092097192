import { mapZipcodeToState } from '@dashboard-experience/utils';
import { Geo, GenericObject, User } from 'types';

export const formatGeo = (geo: Geo, currentUser: User) => {
  const domesticGeo = !geo.country || geo.country === 'US';

  if (currentUser?.account?.segmentation_enabled && domesticGeo) {
    return [geo.state, geo.city].filter(x => x).join(' - ');
  }
  return geo.name || '';
};

export const formatLocation = (
  country: string,
  state: string,
  city: string,
) => {
  return [country, state, city].filter(x => x).join(' - ');
};

export const deriveLocation = (
  workLocations: GenericObject[],
  geos: Geo[] | null | undefined,
  zipcode: string | null | undefined,
  defaultComplianceState: string | null,
  currentUser: User,
): { locations: string[]; tooltip: string } => {
  if (workLocations?.length) {
    return {
      locations: workLocations.map(location => {
        const { country, state, city } = location;
        return formatLocation(country, state, city);
      }),
      tooltip: 'Derived from the Work Location',
    };
  }

  if (geos?.length) {
    const formattedGeos = geos
      .map((geo: Geo) => formatGeo(geo, currentUser))
      .filter(Boolean);

    if (formattedGeos.length)
      return {
        locations: formattedGeos,
        tooltip: 'Derived from Geo',
      };
  }

  if (zipcode) {
    const state_name = mapZipcodeToState(zipcode)?.state_name;
    if (state_name) {
      return {
        locations: [state_name],
        tooltip: 'Derived from resident postal code',
      };
    }
  }

  if (defaultComplianceState) {
    return {
      locations: [defaultComplianceState],
      tooltip: 'Derived from account default Employment Location',
    };
  }

  return {
    locations: ['California'],
    tooltip: 'No location information available, defaulted to California',
  };
};
