import React from 'react';
import { ReportProps } from 'types';
import { HealthProvider } from './health-context';
import OccHealthScreening from './OccHealthScreening';
import { shouldShowCanceledStatus } from '../utils';

const Container: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { occupational_health_screening: screening } = report;
  if (!screening) return null;

  const canceledStatusEnabled = shouldShowCanceledStatus(
    report.account,
    screening,
  );

  return (
    <HealthProvider reportStatusType={reportStatusType}>
      <OccHealthScreening
        screening={screening}
        reportStatusType={reportStatusType}
        canceledStatusEnabled={canceledStatusEnabled}
      />
    </HealthProvider>
  );
};

export default Container;
