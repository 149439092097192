import { Client as client } from 'api';
import * as User from 'modules/core-types/user';
import { GenericObject } from 'types';

// eslint-disable-next-line import/prefer-default-export
export const updatePreferences = (
  updatedPreferences: GenericObject,
  user: User.Type,
): Promise<GenericObject> => {
  return client.put(`/users/${user.id}/settings`, updatedPreferences);
};
