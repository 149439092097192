import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

type Props = {
  checked?: boolean;
  text: string;
};

const StyledContainer = styled.div`
  background: ${colors.uiNavy100};
  border: 1px solid ${colors.brandNavy2};
  border-radius: 4px;
  box-sizing: border-box;
  height: 48px;
  left: 31px;
  top: 81px;
  width: 322px;
`;

const StyledCheckbox = styled(M.Checkbox)`
  left: 20px;
  top: 8px;
  color: ${colors.brandNavy2};
`;

export const Option: React.FC<Props> = ({ checked, text }) => {
  return (
    <StyledContainer>
      <StyledCheckbox
        data-testid='assess-v2-ui-guideline-review-and-apply-assign-guidelines-option-checkbox'
        checked={checked}
        labelText={text}
      />
    </StyledContainer>
  );
};

export default Option;
