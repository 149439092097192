import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

export type Props = {
  disabled: boolean;
  onCancel?: (event: SyntheticEvent) => void;
  onCreate: () => void;
  update?: boolean;
};

export const Actions: React.FC<Props> = ({
  disabled,
  onCancel,
  onCreate,
  update,
}) => {
  const trans = update ? 'verbs.update' : 'verbs.create';
  return (
    <>
      <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
      <Button
        disabled={disabled}
        kind='primary'
        trans={trans}
        onClick={onCreate}
      />
    </>
  );
};

export default Actions;
