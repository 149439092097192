import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { DrugAlcoholClearinghouse as DrugAlcoholClearinghouseReport } from 'types/report';

const getListItems = (
  drugAlcoholClearinghouse: DrugAlcoholClearinghouseReport,
) => {
  const list = [];
  if (drugAlcoholClearinghouse.query_result)
    list.push({
      itemKey: 'Query result',
      itemValue: drugAlcoholClearinghouse.query_result,
    });

  if (drugAlcoholClearinghouse.query_details_document)
    list.push({
      itemKey: 'Query details',
      itemValue: (
        <a
          href={drugAlcoholClearinghouse.query_details_document.download_uri}
          target='_blank'
          rel='noreferrer'
        >
          {drugAlcoholClearinghouse.query_details_document.filename}
        </a>
      ),
    });

  return list;
};

const DrugAlcoholClearinghouse: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { drug_alcohol_clearinghouse } = report;
  if (!drug_alcohol_clearinghouse) return null;
  const items = getListItems(drug_alcohol_clearinghouse);
  return (
    <M.Screening
      title='Drug and alcohol clearinghouse'
      id={SCREENING_TITLES.drug_alcohol_clearinghouse.id}
      data-testid='screenings-drug-alcohol-clearinghouse-report'
      screening={drug_alcohol_clearinghouse}
      statusType={reportStatusType}
    >
      {drug_alcohol_clearinghouse.status === 'complete' && (
        <M.KeyValueList
          items={items}
          data-testid='drug-alcohol-clearinghouse-fields'
        />
      )}
    </M.Screening>
  );
};
export default DrugAlcoholClearinghouse;
