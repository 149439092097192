import React from 'react';

import Charge from './Charge';
import { Charge as ChargeType } from '../types';

type Props = {
  charges: ChargeType[];
};

const SeverityPeriod: React.FC<Props> = ({ charges }) => {
  return (
    <>
      {charges.map((charge: ChargeType) => (
        <Charge key={charge.name} charge={charge} />
      ))}
    </>
  );
};

export default SeverityPeriod;
