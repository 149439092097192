import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledSection = styled.div`
  margin-bottom: 1rem;
`;

export type Props = {
  type: string;
  changes: Array<string>;
};

const CategoryChangeSection: React.FC<Props> = ({ type, changes }) => {
  const { t } = useTranslation('assess');
  const baseKey =
    'mvr.category-sets.instance.versions.publish.changes.category';

  if (changes.length === 0 || !type) return null;

  const icons: any = {
    created: 'NewTab',
    edited: 'Edit',
    deleted: 'TrashCan',
  };

  return (
    <StyledSection
      data-testid={`assess-ccsv-category-changes-${type}`}
      key={type}
    >
      <h3>
        {t(`${baseKey}.${type}`)}{' '}
        <span>
          <M.Icon icon={icons[type]} />
        </span>
      </h3>
      <M.UnorderedList>
        {changes.map(change => (
          <M.ListItem key={change}>{change}</M.ListItem>
        ))}
      </M.UnorderedList>
    </StyledSection>
  );
};

export default CategoryChangeSection;
