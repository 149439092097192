import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import { List } from 'modules/assess/models/rulesets/growth-insights/fairness-and-compliance';
import Benefit from './Benefit';
import Box from './Box';

export type Props = {
  fairness_and_compliance: List;
};

export const NUM_DISPLAYED = 3;

export const FairnessAndCompliance: React.FC<Props> = ({
  fairness_and_compliance,
}) => {
  const { t } = useTranslation('assess');
  if (!fairness_and_compliance) {
    return null;
  }
  const sortedDisplayedFairnessAndCompliance = fairness_and_compliance
    .sort((a, b) => b.annual_revenue - a.annual_revenue)
    .slice(0, NUM_DISPLAYED);
  const title = t(
    'assess:rulesets.growth_insights.fairness_and_compliance.title',
  );

  return (
    <div data-testid='assess-rulesets-growth_insights-fc'>
      <h5>{title}</h5>
      <ul>
        {sortedDisplayedFairnessAndCompliance.map(item => {
          return (
            <M.Expandable
              title={<p>{item.name}</p>}
              initialExpanded
              key={item.code}
            >
              <Box>
                <Benefit item={item} />
              </Box>
            </M.Expandable>
          );
        })}
      </ul>
    </div>
  );
};

export default FairnessAndCompliance;
