import React, { useEffect, useState } from 'react';
import { toastSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import ReportModal from 'components/Report/ReportModal';
import { useRemoveSuppressions } from 'api/communicator/hooks';
import styled from 'styled-components';
import { Candidate } from 'types';

const EditIcon = styled(M.Icon)`
  position: relative;
  top: 0.2rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
`;

type SuppressionModalProps = {
  candidate: Candidate;
  showModal: boolean;
  allowEdit: boolean;
  onClose: () => void;
};

// Component to display for removing a Candidate from the Suppression list
const SuppressionModal: React.FC<SuppressionModalProps> = ({
  candidate,
  showModal,
  onClose,
  allowEdit,
}) => {
  const { removalCall, removalResult } = useRemoveSuppressions(candidate);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    // Check for a response from the server, for success/error of removing Candidate from the Suppression list
    if (removalResult.isSuccess || removalResult.isError) {
      if (removalResult.isSuccess) {
        dispatch(toastSuccess('Success:', t('email_suppression.success')));
        onClose();
      } else {
        // Displaying a ToastError message is left at the Hooks level, because that's our default platform-wide behavior
        setIsError(true);
      }

      // After acting upon the success/error server response, reset its state
      removalResult.reset();
    }
  }, [dispatch, onClose, removalResult, t]);

  // Error state, for the response from the API call
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <ReportModal open={showModal} onClose={onClose}>
      <M.ModalHeader closeModal={onClose}>
        <h2>{t('email_suppression.heading')}</h2>
      </M.ModalHeader>

      <M.ModalBody padding='0'>
        <h2>{t('email_suppression.title')}</h2>

        <p>{t('email_suppression.explanation')}</p>

        {/* Check for editing permissions before showing this paragraph; Many roles can manage Suppressions, but only a few can actually edit email addresses */}
        {allowEdit && (
          <Trans t={t} i18nKey='email_suppression.update_prompt'>
            <p>
              If you need to update the candidate email address, you can close
              this modal and click the Edit button (<EditIcon icon='Edit' />)
              next to their email address.
            </p>
          </Trans>
        )}

        <p>{t('email_suppression.call_to_action')}</p>
      </M.ModalBody>

      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{
            name: t('verbs.cancel'),
            onClick: onClose,
          }}
          btnRight={{
            name: t('email_suppression.remove_suppression_button'),
            onClick: removalCall,
            kind: 'danger',
          }}
        />

        {isError && (
          <div className='errorContainer'>
            <span>{t('data_deletion.error')}</span>
          </div>
        )}
      </M.ModalFooter>
    </ReportModal>
  );
};

export default SuppressionModal;
