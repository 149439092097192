import React from 'react';
import styled from 'styled-components';

import * as Entity from 'modules/assess/models/rules/entity';

const Container = styled.span`
  font-size: 18px !important;
  font-weight: bold;
`;

export const Name = ({ rule }: { rule: Entity.Type }) => {
  return (
    <Container data-testid='assess-rules-rule-list-header-name'>
      {rule.name}
    </Container>
  );
};

export default Name;
