import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Text = styled.span`
  color: ${colors.brandNavy2};
  cursor: pointer;
  font-size: 14px;
  margin-left: 1rem;
  :hover {
    text-decoration: underline;
  }
`;

const BulkEditLink: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const text = t(`standard.guidelines.lookback_period.table.bulk_edit.link`);

  return (
    <Text data-testid='assess-v2-ui-guideline-lookback-period-table-bulk-edit-link-text'>
      {text}
    </Text>
  );
};

export default BulkEditLink;
