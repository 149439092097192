import { Dispatch, createContext, useContext } from 'react';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  ManualBulkUploadData,
  BulkInviteCSV,
  Geo,
  Node,
  Program,
  Invite,
} from '../SharedItems';
import { BillingEntity } from '../../../../types/Billing';

export type CANDIDATE_TYPE = 'CANDIDATE';
export type MYSELF_TYPE = 'MYSELF';

export type BasePackage = {
  name?: string;
  id: string;
  screenings: any[];
  slug?: string;
  base_package_sku?: string;
  price?: number;
  source_version_id?: string;
  applicant_pay?: any | null;
  requires_fmcsa_compliance?: boolean;
  dl_history_verification_enabled?: boolean;
  county_coverage_for_all_state_searches?: boolean;
  skip_county_fees?: boolean;
  skip_mandatory_county_fees?: boolean;
  protected_invitation_flow?: boolean;
  criminal_self_disclosure?: boolean;
  skip_self_disclosure_validation?: boolean;
  skip_ssn_collection_on_candidate_create?: boolean;
  education_history_based_county_criminal_searches?: boolean;
  education_history_based_state_criminal_searches?: boolean;
  employment_history_based_county_criminal_searches?: boolean;
  employment_history_based_state_criminal_searches?: boolean;
  candidates_can_declare_no_ssn?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties_type?: any;
  minors_allowed?: boolean;
  assess_auto_engage_eligible_reports?: boolean;
  county_criminal_search_skip?: boolean;
  has_screenings_requiring_data?: boolean;
  continuous_check?: boolean;
  override_international_report_locales?: boolean;
  international_only?: boolean;
  county_criminal_search_scope?: string;
  apply_url?: string;
  use_alias_skus?: boolean;
  aliases_enabled?: AliasesEnabledType;
  [key: string]: any;
  is_default_package?: boolean;
};

// Adding to state? Update this and initialState in reducer
export type StateType = {
  addedScreeningTypes: any;
  oldBasePackageAddedScreeningTypes: ScreeningType[];
  additionalProperties: any;
  newPackageName: string;
  newPackageNameInvalid: Boolean;
  /** Base package used to build package in the review page */
  oldBasePackage: BasePackage;
  /** A package instance used to display information in the OBC / AddChecks flow */
  basePackage: BasePackage;
  /** AddChecks flow only. Package instance used to populate a report fo */
  packageOnReport: BasePackage;
  /**
   * The package selected and confirmed in the package selection step
   * This should be used whether we have to clean the informations in the latter steps or not
   * when users back to package selection step and move to the next steps again
   */
  selectedPackage: BasePackage;
  /** Selected Geo Type. This is either domestic or international */
  selectedGeo: string;
  location: any;
  inviteMethod: string;
  manualBulkUploadType: string;
  manualBulkUploadData: ManualBulkUploadData;
  providerType: CANDIDATE_TYPE | MYSELF_TYPE;
  /** Geo object which is selected by the user */
  geo: Geo;
  /** Node(hierarchy) object which is selected by the user */
  node: Node;
  program: Program;
  emails: string[];
  invites: Invite[];
  csv: BulkInviteCSV;
  error: string | null;
  continueButtonDisabled: boolean;
  saveForNextTime: boolean;
  internationalPriceLinkClicked: boolean;
  selectPackageAmplitudeData: any;
  paymentProfile: BillingEntity;
  addOnsPrice: string;
  workflow: string;
  aliasesEnabled: string;
  aliasPrice: number;
  pricesSnapshot: {
    basePackagePrice: number;
    addonPrices: any[];
    aliasPrice: number;
    subtotalPerCandidate: number;
  };
  recommendedPackageSelected: boolean;
  recommendedPackageType: string;
};

export type Action = {
  type: string;
  payload?: any;
};

export interface IContextProps {
  state: StateType;
  dispatch: Dispatch<Action>;
  addLoadAction: (name: string, customAttributes?: object | undefined) => void;
  addUnloadAction: (
    name: string,
    customAttributes?: object | undefined,
  ) => void;
  addDataDogError: (
    error: unknown,
    customAttributes: object | undefined,
    source: 'source' | 'custom' | 'network' | undefined,
  ) => void;
}

const OrderBackgroundCheckContext = createContext({} as IContextProps);

export const useOrderBackgroundCheckContext = () => {
  return useContext(OrderBackgroundCheckContext);
};

export default OrderBackgroundCheckContext;
