export enum Fact {
  AGE_AT_ISSUED_DATE = 'age_at_issued_date',
  AGE_NOW = 'age_now',
  CATEGORY = 'category',
  CLASSIFICATION_CODE = 'classification_code',
  COUNT = 'count',
  CURRENT = 'current',
  DESCRIPTION = 'description',
  EXPIRATION_EXTENDED = 'expiration_extended',
  LICENSE_CLASS = 'license_class',
  LICENSE_TYPE = 'license_type',
  POINTS = 'points',
  PRIVILEGE_TO_DRIVE = 'privilege_to_drive',
  RELATIVE_CONTEXT_DATE_IN_DAYS = 'relative_context_date_in_days',
  RELATIVE_CONVICTION_DATE_IN_DAYS = 'relative_conviction_date_in_days',
  RELATIVE_ISSUED_DATE_IN_DAYS = 'relative_issued_date_in_days',
  RELATIVE_END_DATE_IN_YEARS = 'relative_end_date_in_years',
  RELATIVE_EXPIRATION_DATE_IN_DAYS = 'relative_expiration_date_in_days',
  RELATIVE_HISTORY_IN_YEARS = 'relative_history_in_years',
  RELATIVE_START_DATE_IN_YEARS = 'relative_start_date_in_years',
  RESTRICTED_LICENSE_STATUS = 'restricted_license_status',
  RESTRICTIONS = 'restrictions',
  STATE = 'state',
  STATUS = 'status',
  TYPE = 'object_type',
  WORK_STATE = 'work_state',
}

export default Fact;
