import * as Entity from 'modules/assess/models/rulesets/context-dates';
import { Initializer } from 'state/utilities/state';
import * as Options from './options';

export type Type = {
  disabled?: boolean;
  dirty: boolean;
  options: Options.State.Type;
};

export const initialize: Initializer<Type> = () => ({
  dirty: false,
  options: Options.State.initialize(),
});

export const entity = (state: Type): Entity.List => {
  return Options.State.entries(state.options);
};
