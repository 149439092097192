/* eslint-disable react/no-children-prop */
import React from 'react';
import { Redirect } from 'react-router-dom';

type Props = {};

const Home: React.FC<Props> = () => {
  return <Redirect to='.' />;
};

export default Home;
