import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section, SpecialContainer } from '../common';
import Content from '../Content';
import Sections from '../sections';

const Container = styled(SpecialContainer)`
  > *:first-child {
    padding-top: 0.5rem;
  }

  > *:last-child {
    padding-top: 0.5rem;
  }
  > * {
    padding-right: 1rem;
    padding-left: 2rem;
  }
`;

const StateRequirements: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.state_requirements}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { paragraphs, title } = translations;
  const content = (
    <Content paragraphs={paragraphs} section={Sections.state_requirements} />
  );

  return (
    <Section data-testid='fair-chance-sections-state-requirements'>
      <Container type='special'>
        <h3>{title}</h3>
        {content}
      </Container>
    </Section>
  );
};

export default StateRequirements;
