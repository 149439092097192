import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  categoryName: string;
};

export const Empty: React.FC<Props> = ({ categoryName }) => {
  const { t } = useTranslation('assess');
  const text = (
    <Trans
      i18nKey='assess:mvr.category-sets.instance.assignments.empty'
      t={t}
      values={{ category_name: categoryName }}
    />
  );

  return <p>{text}</p>;
};

export default Empty;
