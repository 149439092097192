import React, { FC } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BetaState, useBetaState, useShowBeta } from 'providers/Beta';
import sparkle from './Sparkle.svg';

const Banner = styled.div`
  display: flex;
  padding: 16px 32px;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid ${colors.uiGrey200};
  background: linear-gradient(
    89deg,
    ${colors.uiGrey50} 0%,
    ${colors.uiAqua100} 100%
  );
  margin-bottom: 32px;
  a {
    cursor: pointer;
  }
  .bold {
    color: ${colors.uiTextPrimaryLight};
  }
`;

const Column = styled.div`
  display: flex;
  padding-right: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const Icon = () => <img src={sparkle} alt='beta' />;

const Description: FC<{ translationKey: string; learnMoreLink?: string }> = ({
  translationKey,
  learnMoreLink,
}) => {
  const { t } = useTranslation();
  return (
    <Column>
      <div className='p3 bold'>{t(`beta.${translationKey}.banner.title`)}</div>
      <div className='p3'>
        {t(`beta.${translationKey}.banner.subtitle`)}{' '}
        {learnMoreLink && (
          <M.Link
            href={learnMoreLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            {t(`beta.${translationKey}.banner.learn_more`)}
          </M.Link>
        )}
      </div>
    </Column>
  );
};

const Button: FC<{ flagKey: string; translationKey: string }> = ({
  flagKey,
  translationKey,
}) => {
  const { t } = useTranslation();
  const betaState = useBetaState(flagKey);
  const showBeta = useShowBeta(flagKey);
  const buttonKey =
    betaState === BetaState.OPT_OUT
      ? `beta.${translationKey}.banner.opted_out_button`
      : `beta.${translationKey}.banner.button`;
  return (
    <M.Button
      kind='secondary'
      onClick={showBeta}
      data-pendo-id='candidate-beta-switch-to-beta'
    >
      {t(buttonKey)}
    </M.Button>
  );
};

const BetaBanner: FC<{
  flagKey: string;
  translationKey: string;
  learnMoreLink?: string;
}> = ({ flagKey, translationKey, learnMoreLink }) => {
  const betaState = useBetaState(flagKey);

  if (betaState === BetaState.DISABLED) return null;

  return (
    <Banner>
      <Icon />
      <Description
        translationKey={translationKey}
        learnMoreLink={learnMoreLink}
      />
      <Button flagKey={flagKey} translationKey={translationKey} />
    </Banner>
  );
};

export default BetaBanner;
