import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { CategoryCodeChange } from 'modules/assess/models/mvr/category-code-set-versions/version-diff/category-code-change';

export type Props = {
  categoryCodes?: Array<CategoryCodeChange>;
};

const UNASSIGNED = 'Unassigned';

const CategoryCodeChanges: React.FC<Props> = ({ categoryCodes }) => {
  const { t } = useTranslation('assess');
  const baseKey = 'mvr.category-sets.instance.versions.publish.changes.code';
  const headers = [
    {
      header: t(`${baseKey}.name`),
      key: 'code',
    },
    {
      header: t(`${baseKey}.update`),
      key: 'update',
    },
  ];

  const rows = categoryCodes?.map(({ code, description, category }, index) => ({
    id: index.toString(),
    code: `${code}: ${description}`,
    update: `${category.old || UNASSIGNED} → ${category.new || UNASSIGNED}`,
  }));

  return (
    <div>
      <h2>{t(`${baseKey}.title`)}</h2>
      <Table
        data-testid='assess-ccsv-category-code-changes'
        headers={headers}
        rows={rows}
      />
    </div>
  );
};

export default CategoryCodeChanges;
