import React from 'react';
import { ItemVisibility } from 'modules/assess';
import * as Entity from 'modules/assess/models/rules/premade';
import { Namespace } from 'modules/assess/api';
import { Container } from '../common';
import Description from './Description';
import Instructions from './Instructions';
import Table from './table';

type Props = {
  disabled?: boolean;
  namespace?: Namespace;
  rules: Entity.List | Entity.Map;
  onRuleClicked: (rule: Entity.Type, isSelected: boolean) => void;
  onRuleUpdated: (rule: Entity.Type, itemVisibility?: ItemVisibility) => void;
};

export const View: React.FC<Props> = ({
  disabled,
  namespace,
  rules,
  onRuleClicked,
  onRuleUpdated,
}) => {
  if (!namespace) return null;

  return (
    <Container data-testid='assess-rules-standard-view'>
      <Description namespace={namespace} />
      <Instructions />
      <Table
        disabled={disabled}
        namespace={namespace}
        rules={rules}
        onRuleClicked={onRuleClicked}
        onRuleUpdated={onRuleUpdated}
      />
    </Container>
  );
};

export default View;
