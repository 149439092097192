import { useSearchState } from 'components/Search/search-context';
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { AdvancedFiltersText } from '../StyledComponents';
import { toggleAdvancedFilters } from '../helpers';

const AdvancedFiltersToggle = () => {
  const { showAdvancedFilters, setShowAdvancedFilters } = useSearchState();
  const toggleFilters = useCallback(
    () => toggleAdvancedFilters(showAdvancedFilters, setShowAdvancedFilters),
    [showAdvancedFilters, setShowAdvancedFilters],
  );

  return (
    <AdvancedFiltersText onClick={toggleFilters}>
      {showAdvancedFilters ? (
        <>
          <M.Icon icon='Subtract' />
          Hide advanced filters
        </>
      ) : (
        <>
          <M.Icon icon='Add' />
          Advanced filters
        </>
      )}
    </AdvancedFiltersText>
  );
};

export default AdvancedFiltersToggle;
