import { M } from '@dashboard-experience/mastodon';
import React, { FunctionComponent } from 'react';
import { BillingEntities } from 'types/Billing';
import {
  DefaultHeader,
  HeaderWithTooltip,
  NoHeader,
} from '../Account/Invoices';
import PaymentProfileTableCell from './PaymentProfileTableCell';
import PaymentProfileTableEditCell from './PaymentProfileTableEditCell';
import PaymentProfileBillableToggleCell from './PaymentProfileBillableToggleCell';

type PaymentProfilesTableProps = {
  isLoading: boolean;
  error: Error | null;
  submit: (params: any, isDisable: any) => void;
  tableHeaders: {
    key: string;
    header: string;
    name: string;
    className: string;
    tooltip: boolean;
  }[];
  records: BillingEntities;
  EditForm: FunctionComponent<any>;
  NodeBillingDisablePopup: FunctionComponent<any>;
};

const PaymentProfilesTable: React.FC<PaymentProfilesTableProps> = ({
  isLoading,
  submit,
  tableHeaders,
  records,
  EditForm,
  NodeBillingDisablePopup,
}) => {
  const tableCells = (cell: any, row: any, cellProps: any) => {
    const headerTitle = cell.info.header;
    const cellId = cell.id;
    if (headerTitle === 'billable') {
      return (
        <PaymentProfileBillableToggleCell
          key={cellId}
          profile={row}
          isLoading={isLoading}
          submit={submit}
          EditForm={EditForm}
          DisablePopup={NodeBillingDisablePopup}
        />
      );
    }
    if (headerTitle === 'editButton') {
      return (
        <PaymentProfileTableEditCell
          key={cellId}
          profile={row}
          isLoading={isLoading}
          submit={submit}
          EditForm={EditForm}
        />
      );
    }
    return <PaymentProfileTableCell key={cellId} {...cellProps} />;
  };

  return (
    <M.DataTable rows={records} headers={tableHeaders}>
      {({ rows, headers }: any) => (
        <M.TableContainer>
          <M.Table>
            <M.TableHead className='border-top'>
              <M.TableRow>
                {headers.map(
                  (header: { name: any; tooltip: any; className?: any }) => {
                    let headerValue;
                    if (header.name) {
                      if (header.tooltip) {
                        headerValue = (
                          <HeaderWithTooltip
                            key={header.name}
                            header={header}
                          />
                        );
                      } else {
                        headerValue = (
                          <DefaultHeader
                            key={header.name}
                            header={header}
                            className={header.className || ''}
                          />
                        );
                      }
                    } else {
                      return <NoHeader key={header.name} />;
                    }
                    return headerValue;
                  },
                )}
              </M.TableRow>
            </M.TableHead>
            <M.TableBody>
              {rows.map(
                (
                  rowMetaData: {
                    cells: { info: { header: any }; id: string }[];
                  },
                  i: number,
                ) => {
                  const row = records[i];
                  return (
                    <M.TableRow key={row.id + row.name}>
                      {rowMetaData.cells.map(
                        (cell: { info: { header: any }; id: string }) => {
                          const cellProps = {
                            header: cell.info.header,
                            row,
                            cell,
                          };
                          return tableCells(cell, row, cellProps);
                        },
                      )}
                    </M.TableRow>
                  );
                },
              )}
            </M.TableBody>
          </M.Table>
        </M.TableContainer>
      )}
    </M.DataTable>
  );
};

export default PaymentProfilesTable;
