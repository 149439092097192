/**
 * The vast majority of this script taken directly from
 *  https://usehooks.com/useScript/
 *
 * With minor tweaks as appropriate for Checkr needs
 */

import { GenericObject } from '@dashboard-experience/utils';
import { useEffect, useState } from 'react';

export enum ScriptStatuses {
  IDLE = 'idle',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
}

/**
 * A hook to dynamically attach a `<script>` tag with a given URL to the page.
 * It will transmit the loading status of the script, and it will also avoid creating duplicates of any given URL
 *
 * Taken from https://usehooks.com/useScript/
 * @param {string} url - The full URL of the script to load
 * @returns {ScriptStatuses} The loading status of the script (an enum containing IDLE, LOADING, READY, and ERROR)
 */
export const useScript = (
  url: string,
  additionalAttributes?: GenericObject,
): ScriptStatuses => {
  const [status, setStatus] = useState<ScriptStatuses>(
    url ? ScriptStatuses.LOADING : ScriptStatuses.IDLE,
  );

  useEffect(() => {
    // Reasons to exit early and not even bother trying to load a script":
    // Either URL is entirely empty, or if the URL begins with "undefined" (i.e. there was supposed to be an ENV var to declare a specific domain, but that var failed to load and became "undefined")
    if (!url || url.startsWith('undefined')) {
      setStatus(ScriptStatuses.IDLE);
      return undefined;
    }

    // See if there is any existing script with the same source within this document
    let script = document.querySelector(
      `script[src="${url}"]`,
    ) as HTMLScriptElement; // Typecasting required to access .src and .async properties

    // If there isn't a match for this src, then build out a new one and add it to the document
    if (!script) {
      script = document.createElement('script');
      script.src = url;
      script.async = true;

      // Add this property to help track loading state of the script, even across multiple separate instances of the hook
      script.setAttribute('data-status', 'loading');

      // If any additional attributes were manually passed into the script, inject them here
      if (additionalAttributes) {
        Object.keys(additionalAttributes).forEach(key => {
          script.setAttribute(key, additionalAttributes[key]);
        });
      }

      document.body.appendChild(script);

      // Add event listeners to update the `data-status` property as events occur:
      const setAttributeFromEvent = (event: Event) => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error',
        );
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    }
    // If there IS already an existing script tag with this source, then just tie into its `data-status` property to report through this instance of the hook
    else {
      setStatus(script.getAttribute('data-status') as ScriptStatuses);
    }

    // In addition to the event handlers for tracking data-status, we also want to add some to report status back at the React state level:
    const setStateFromEvent = (event: Event) => {
      setStatus(
        event.type === 'load' ? ScriptStatuses.READY : ScriptStatuses.ERROR,
      );
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    // Cleanup script to remove the event listeners as needed
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [additionalAttributes, url]);

  return status;
};

export default useScript;
