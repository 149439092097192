import { namespace } from './constants';
import data from './data/en.json';

type Resources = Record<string, Record<typeof namespace, object>>;

export const resources: Resources = {
  en: {
    [namespace]: data,
  },
};

export default resources;
