import styled from 'styled-components';
import { colors, fonts } from '@dashboard-experience/mastodon';
import { ManualOrdersReview } from '../Packages/OrderBackgroundCheck/Context';

export const InputGroup = styled.div`
  border-radius: 0.25rem;
  display: inline-block;
  margin: 1rem;
  width: calc(50% - 2rem);

  @media only screen and (max-width: 800px) {
    width: calc(100% - 2rem);
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.h3`
  margin-bottom: 1.5rem !important;

  &::after {
    display: inline-block;
    border-radius: 0.25rem;
    padding: 0.125rem 0.5rem;
    color: ${colors.uiTextPrimaryLight};
    font-weight: 400;
    font-size: 0.75rem;
    font-family: ${fonts.legacy};
    margin-left: 0.5rem;
    vertical-align: middle;
    line-height: 1rem;
  }

  &.${ManualOrdersReview.NOT_REQUESTED} {
    &::after {
      content: 'Pending setup';
      background: ${colors.uiYellow200};
    }
  }

  &.${ManualOrdersReview.REQUESTED} {
    &::after {
      content: 'Pending approval';
      background: ${colors.uiGrey200};
    }
  }
`;

export const InputGroupTopAligned = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 100%;

  @media only screen and (min-width: 1056px) and (max-width: 1322px) {
    width: calc(50% - 1.5rem);
  }

  @media only screen and (min-width: 1056px) {
    margin: 0.75rem;
  }

  @media only screen and (min-width: 1323px) {
    width: calc(33.3% - 1.5rem);
  }
`;

export const InputGroupWrapper = styled.div`
  @media only screen and (min-width: 1056px) {
    margin: 0 -0.75rem;
  }
`;

export const SubmitInputGroup = styled(InputGroup)`
  padding: 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SaveCancelGroup = styled.div`
  margin: 2rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (min-width: 1056px) {
    width: calc(100% - 2rem);
  }

  @media only screen and (max-width: 1055px) {
    .btn-wrap-primary {
      width: 100%;

      .mastodon-button {
        width: calc(50% - 0.75rem);
        justify-content: center;
      }
    }
  }
`;

export const LogoImg = styled.img`
  max-height: 160px;
  max-width: 100px;
  height: auto;
  width: auto;

  @media only screen and (min-width: 675px) {
    max-width: 160px;
  }
`;

export const FileInputGroup = styled(SubmitInputGroup)`
  background-color: ${colors.uiGrey200};
  margin-top: 0;
  align-items: flex-start;
  flex-direction: row;
`;

export const FileInputGroupSettings = styled.div`
  display: flex;
  margin: 0;
  align-items: flex-start;
  flex-direction: row;
`;

export const FileInputGroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FileInputGroupWrap = styled.div`
  margin-left: 1.5rem;

  @media only screen and (min-width: 1323px) {
    width: 45%;
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1rem;
`;

export const StyledSeparator = styled.hr`
  background-color: ${colors.uiGrey200};
  border: none !important;
  height: 1px;
  margin: 1.5rem 0;
`;

// TODO Remove after delivery the user preference improvements
// and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
export const InputGroupTopAlignedOld = styled(InputGroup)`
  vertical-align: top;
`;
