import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ContextContainer } from 'containers';
import { useReportsWithMultiMvr } from 'api/reports';

type RouterProps = {
  reportId: string;
};

type Props = RouteComponentProps<RouterProps>;

const ReportPage: React.FC<Props> = ({ match }) => {
  const {
    params: { reportId },
  } = match;
  const { report, isLoading, isError } = useReportsWithMultiMvr({ reportId });
  if (isLoading) return <M.LoadingInline />;
  if (isError || !report?.candidate?.id)
    return (
      <M.InlineNotification
        hideCloseButton
        kind='error'
        title='Error fetching report'
        subtitle='Please try again later'
      />
    );
  const { hash, search } = window.location;
  return (
    <Redirect
      to={`/candidates/${report.candidate.id}/reports/${reportId}${search}${hash}`}
    />
  );
};

export default ContextContainer(ReportPage);
