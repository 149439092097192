import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import * as Assess from 'modules/assess/ui';
import RulesetVersion from 'modules/assess/ui/ruleset/version';
import { useFetch } from 'modules/assess/ui/ruleset/version/api';
import ContextDates from './context-dates';
import Missing from '../../missing';

type Params = {
  version: string;
};

export const Instance: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { path: base, params, url } = match;
  const id = params.version;
  const query = useFetch(id);

  if (!id) {
    return <Missing />;
  }

  return (
    <Switch>
      <Route path={`${base}/context-dates`}>
        <Assess.Route.Breadcrumb
          location={url}
          title={`Version ${query?.data?.number}`}
        >
          <ContextDates id={id} disabled />
        </Assess.Route.Breadcrumb>
      </Route>
      <Route path={base}>
        <RulesetVersion data={query?.data} status={query?.status} />
      </Route>
    </Switch>
  );
};

export default Instance;
