export const TABLE_SORT_DIRECTION = {
  NONE: 'NONE',
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

/**
 * Table sort cycle.
 */
export const TABLE_SORT_CYCLE = {
  ID_STATES_FROM_ASCENDING: 'id-states-from-ascending',
  ID_STATES_FROM_DESCENDING: 'id-states-from-descending',
  NAME_STATES_FROM_ASCENDING: 'name-states-from-ascending',
  NAME_STATES_FROM_DESCENDING: 'name-states-from-descending',
  TIER_STATES_FROM_ASCENDING: 'tier-states-from-ascending',
  TIER_STATES_FROM_DESCENDING: 'tier-states-from-descending',
  PARENT_CUSTOM_ID_STATES_FROM_ASCENDING:
    'parent-custom-id-states-from-ascending',
  PARENT_CUSTOM_ID_STATES_FROM_DESCENDING:
    'parent-custom-id-states-from-descending',
};

/**
 * Mapping of table sort cycles to table sort states.
 */
export const TABLE_SORT_CYCLES = {
  [TABLE_SORT_CYCLE.ID_STATES_FROM_ASCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.ASCENDING,
    TABLE_SORT_DIRECTION.DESCENDING,
  ],
  [TABLE_SORT_CYCLE.ID_STATES_FROM_DESCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.DESCENDING,
    TABLE_SORT_DIRECTION.ASCENDING,
  ],
  [TABLE_SORT_CYCLE.NAME_STATES_FROM_ASCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.ASCENDING,
    TABLE_SORT_DIRECTION.DESCENDING,
  ],
  [TABLE_SORT_CYCLE.NAME_STATES_FROM_DESCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.DESCENDING,
    TABLE_SORT_DIRECTION.ASCENDING,
  ],
  [TABLE_SORT_CYCLE.TIER_STATES_FROM_ASCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.ASCENDING,
    TABLE_SORT_DIRECTION.DESCENDING,
  ],
  [TABLE_SORT_CYCLE.TIER_STATES_FROM_DESCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.DESCENDING,
    TABLE_SORT_DIRECTION.ASCENDING,
  ],
  [TABLE_SORT_CYCLE.PARENT_CUSTOM_ID_STATES_FROM_ASCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.ASCENDING,
    TABLE_SORT_DIRECTION.DESCENDING,
  ],
  [TABLE_SORT_CYCLE.PARENT_CUSTOM_ID_STATES_FROM_DESCENDING]: [
    TABLE_SORT_DIRECTION.NONE,
    TABLE_SORT_DIRECTION.DESCENDING,
    TABLE_SORT_DIRECTION.ASCENDING,
  ],
};
