import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Fact } from 'modules/assess';
import { Namespace } from 'modules/assess/api';

type Props = {
  fact?: Fact;
  namespace?: Namespace;
  selectedValue?: string[];
};

export const HelpText: React.FC<Props> = ({
  fact,
  namespace,
  selectedValue,
}) => {
  const { t } = useTranslation('');

  const getTranslation = (option: string) => {
    return t(`assess:rules.facts.${fact}.${namespace}.help_text.${option}`, {
      defaultValue: null,
    });
  };

  const text = t(`assess:rules.facts.${fact}.help_text`, {
    defaultValue: null,
  });

  const optionTranslateItems = selectedValue
    ?.map(value => getTranslation(value))
    .filter(value => value);
  const optionListItem = optionTranslateItems?.map(value => (
    <M.ListItem key={value}>{value}</M.ListItem>
  ));
  const optionList = <M.UnorderedList>{optionListItem}</M.UnorderedList>;

  return (
    <>
      <p>
        <em>{text}</em>
      </p>
      {optionList}
    </>
  );
};

export default HelpText;
