import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDraft } from 'modules/assess/ui/ruleset/version/api';
import { Guideline as Amplitude } from 'modules/assess/v2/models/amplitude';
import { ID } from 'modules/entity';
import { Page } from 'pages/Assess';
import { SavedNotification } from '../../common';
import Actions from './Actions';
import Content from './content';
import Info from './info';

type Props = {
  ruleset?: ID;
};

export const Container: React.FC<Props> = ({ ruleset }) => {
  const query = useDraft(ruleset);
  const version = query.data;
  const location = useLocation();
  const queryRuleParam = new URLSearchParams(location.search).get('saved');

  return (
    <Page.V2.Container
      data-testid='assess-v2-ui-standard-guideline-container'
      status={query.status}
      event={Amplitude.Pageview}
    >
      {queryRuleParam && <SavedNotification />}
      <Info />
      <Content rules={version?.rules} />
      <Actions />
    </Page.V2.Container>
  );
};

export default Container;
