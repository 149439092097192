import { localStorageFallback } from '@dashboard-experience/utils';

/**
 * @name setProcessingStatus
 * @function
 * @memberOf StorageUtils
 * @description Stores processing status to localStorage
 */
export const setProcessingStatus = (data: any) => {
  const resourceId = data.candidateId;
  const statusUpdates = JSON.parse(
    localStorageFallback.getItem('processingStatusData') || '{}',
  );
  statusUpdates[resourceId] = data;
  localStorageFallback.setItem(
    'processingStatusData',
    JSON.stringify(statusUpdates),
  );
};

export default setProcessingStatus;
