import { AnyQueryKey, useQuery } from 'react-query';
import * as API from 'modules/assess/api/category-code-set/instance';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { Namespace } from 'modules/assess/api';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useSearch = (params?: API.SearchParams) => {
  const account = useUserAccount();
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = [namespace, undefined, params];

  const request = () => {
    return API.search({ account_id: account?.id, ...params });
  };

  return useQuery(key, request, { refetchOnWindowFocus: false });
};
