/* eslint-disable react/jsx-no-bind */
import React from 'react';
import InternationalRightToWork from './VerificationsScreenings/InternationalRightToWork';
import InternationalEmploymentVerification from './VerificationsScreenings/InternationalEmploymentVerification';
import InternationalEducationVerification from './VerificationsScreenings/InternationalEducationVerification';
import InternationalIdentityDocumentValidation from './VerificationsScreenings/InternationalIdentityDocumentValidation';
import {
  AccordionItem,
  ActionPropsProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import certificate from '../../shared/svg/certificate.svg';

type VerificationsItemProps = {
  screeningTypesInSearch: string[];
  actionProps: ActionPropsProps;
};
const VerificationsItem = ({
  screeningTypesInSearch,
  actionProps,
}: VerificationsItemProps) => {
  return (
    <AccordionItem
      open={
        screeningTypesInSearch?.includes(
          'international_education_verification',
        ) ||
        screeningTypesInSearch?.includes(
          'international_employment_verification',
        ) ||
        screeningTypesInSearch?.includes('international_right_to_work') ||
        screeningTypesInSearch?.includes(
          'international_identity_document_validation',
        )
      }
      title={
        <CategoryTitle data-testid='international-verifications-title'>
          <Icon src={certificate} className='screening-icon' /> Verifications
        </CategoryTitle>
      }
    >
      <ul>
        <InternationalEmploymentVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'international_employment_verification',
          )}
          actionProps={actionProps}
        />
        <InternationalEducationVerification
          shouldOpen={screeningTypesInSearch?.includes(
            'international_education_verification',
          )}
          actionProps={actionProps}
        />
        <InternationalIdentityDocumentValidation
          shouldOpen={screeningTypesInSearch?.includes(
            'international_identity_document_validation',
          )}
          actionProps={actionProps}
        />
        <InternationalRightToWork
          shouldOpen={screeningTypesInSearch?.includes(
            'international_right_to_work',
          )}
          actionProps={actionProps}
        />
      </ul>
    </AccordionItem>
  );
};

export default VerificationsItem;
