import React, { useCallback } from 'react';
import { useGetAccountSettings, useGetLastToSContent } from 'api/i9';
import { M } from '@dashboard-experience/mastodon';
import { I9_ORDERING } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import { AccountSetup, FormI9, AccountSetupUpsell } from '../components/FormI9';

const FormI9Page = () => {
  const accountSettings = useGetAccountSettings();
  const { isLoading, isError } = accountSettings;
  const { data: tosData, isLoading: tosLoading } = useGetLastToSContent();
  const showRedesignedI9Landing = useFlag(I9_ORDERING)?.variantKey === 'on';

  const updateAccountSettings = useCallback(() => {
    accountSettings.refetch();
  }, [accountSettings]);

  if (isLoading || tosLoading) {
    return <M.LoadingInline description='Loading...' />;
  }

  const renderSetup = !isLoading && isError;

  return renderSetup ? (
    <>
      {showRedesignedI9Landing ? (
        <AccountSetupUpsell tosData={tosData} />
      ) : (
        <AccountSetup tosData={tosData} />
      )}
    </>
  ) : (
    <FormI9
      settingsData={accountSettings.data}
      changedDefaultWorksite={updateAccountSettings}
    />
  );
};

export default FormI9Page;
