import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type StyleProps = {
  size?: string;
  flex?: number;
  marginLeft?: string;
  centered?: boolean;
  error?: boolean;
  warning?: boolean;
};

export const FiltersContainer = styled.div`
  max-width: 775px;
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled.div`
  width: ${({ size }: StyleProps) => size || '20rem'};
`;

export const CheckboxContainer = styled.div`
  .cds--form-item.cds--checkbox-wrapper {
    margin-bottom: 0.5rem;
  }
  span {
    margin-left: 0.5rem;
  }
`;

export const FilterLabel = styled.p`
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

export const SegmentsFooterLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 0.75rem;
  width: 100%;
`;

export const SegmentsFooterRight = styled.div`
  white-space: nowrap;

  button {
    width: auto !important;
  }
`;

export const BodyContainer = styled.div`
  min-height: 300px;

  ${({ centered }: StyleProps) =>
    centered &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export const InfoPanel = styled.div`
  background: ${colors.uiGrey100};
  border-radius: 4px;
  border: 1px solid ${colors.uiGrey800};
  padding: 1rem 2rem;

  .icon {
    margin-right: 1rem;
    font-size: 1rem;
  }

  ${({ error }: StyleProps) =>
    error &&
    `
      background: ${colors.uiOrange200};
      color: ${colors.uiOrange800};
    `};

  ${({ warning }: StyleProps) =>
    warning &&
    `
      background: ${colors.uiYellow100};
      color: ${colors.uiYellow800};
    `};
`;
