import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const StyledComposedModal = styled(M.ComposedModal)`
  .cds--modal-content {
    .helper-text {
      color: ${colors.uiGrey700};
      padding-right: 0 !important;
      margin: 0 0 1rem !important;
    }

    .mastodon-date-filter {
      @media (max-width: 767px) {
        display: block !important;
      }

      .cds--form-item:first-child {
        margin-right: 1rem;
      }

      .cds--date-picker__icon--invalid {
        fill: var(--cds-support-error, ${colors.uiOrange600}) !important;
      }
    }
  }
`;

export const StyledChoiceCardGroup = styled(M.ChoiceCardGroup)`
  .cds--radio-button-group {
    display: block !important;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      display: flex !important;
      margin-top: 0.5rem;
      width: 100%;
    }

    .mastodon-choice-card {
      margin-bottom: 1rem !important;
      max-width: 100% !important;
      width: 100%;

      .cds--radio-button__appearance {
        margin-right: 0 !important;
      }

      .mastodon-choice-card-title {
        display: inline-flex;

        .cds--toggletip-label {
          color: ${colors.brandNavy4};
          font-weight: bold;
        }
      }
    }
  }
`;

export const DateContainer = styled.div`
  padding-top: 2rem;
`;

export const ModalBody = styled(M.ModalBody)`
  &.downloadCsvDisabled {
    color: ${colors.uiGrey700};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .success-message {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }

  .email {
    color: ${colors.uiNavy600};
  }

  svg {
    width: 60px;
    height: 60px;
    fill: ${colors.uiNavy600};
  }
`;
