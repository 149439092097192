import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props = {
  status: string;
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'positive':
      return colors.uiPink600;
    case 'review' || 'consider':
      return colors.uiYellow400;
    default:
      return colors.uiPink600;
  }
};

const StatusCircle = styled.span.attrs({
  'data-testid': 'status-circle',
})<Props>`
  height: 1rem;
  width: 1rem;
  background-color: ${({ status }) => getStatusColor(status)};
  border-radius: 50%;
  display: inline-block;
`;

export default StatusCircle;
