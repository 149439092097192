import React from 'react';
import { FieldComponentProps } from '../types';

const DriverLicenseNumber: React.FC<FieldComponentProps> = ({ candidate }) => {
  const { driver_license_number, driver_license_state } = candidate;
  return (
    <>
      {driver_license_number && driver_license_state && (
        <>
          {driver_license_number} {driver_license_state}
        </>
      )}
      {(!driver_license_number || !driver_license_state) && <>-</>}
    </>
  );
};

export default DriverLicenseNumber;
