/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

export const FETCH_CANDIDATES_LEGACY_REQUEST =
  'FETCH_CANDIDATES_LEGACY_REQUEST';
export const FETCH_CANDIDATES_LEGACY_SUCCESS =
  'FETCH_CANDIDATES_LEGACY_SUCCESS';
export const FETCH_CANDIDATES_LEGACY_FAILURE =
  'FETCH_CANDIDATES_LEGACY_FAILURE';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const FETCH_CANDIDATE_REQUEST = 'FETCH_CANDIDATE_REQUEST';
export const FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS';
export const FETCH_CANDIDATE_FAILURE = 'FETCH_CANDIDATE_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CURRENT_USER_ACCOUNT_UPDATE = 'CURRENT_USER_ACCOUNT_UPDATE';
export const NOTIFICATIONS_SETTINGS_UPDATE = 'CURRENT_USER_SETTINGS_UPDATE';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const FETCH_MONOLITH_USERS_FOR_ACCOUNT_REQUEST =
  'FETCH_MONOLITH_USERS_FOR_ACCOUNT_REQUEST';
export const FETCH_MONOLITH_USERS_FOR_ACCOUNT_SUCCESS =
  'FETCH_MONOLITH_USERS_FOR_ACCOUNT_SUCCESS';
export const FETCH_MONOLITH_USERS_FOR_ACCOUNT_FAILURE =
  'FETCH_MONOLITH_USERS_FOR_ACCOUNT_FAILURE';
export const FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST =
  'FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST';
export const FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS =
  'FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS';
export const FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE =
  'FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE';

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export const SET_CONTEXT_PARAM = 'SET_CONTEXT_PARAM';

export const UPDATE_ACCOUNT_SETTINGS_REQUEST =
  'UPDATE_ACCOUNT_SETTINGS_REQUEST';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS =
  'UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_FAILURE =
  'UPDATE_ACCOUNT_SETTINGS_FAILURE';

export const EDIT_USER_ROLES_REQUEST = 'EDIT_USER_ROLES_REQUEST';
export const EDIT_USER_ROLES_SUCCESS = 'EDIT_USER_ROLES_SUCCESS';
export const EDIT_USER_ROLES_FAILURE = 'EDIT_USER_ROLES_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_GEO_SUBSCRIPTIONS_REQUEST =
  'FETCH_GEO_SUBSCRIPTIONS_REQUEST';
export const FETCH_GEO_SUBSCRIPTIONS_SUCCESS =
  'FETCH_GEO_SUBSCRIPTIONS_SUCCESS';
export const FETCH_GEO_SUBSCRIPTIONS_FAILURE =
  'FETCH_GEO_SUBSCRIPTIONS_FAILURE';

export const UPDATE_GEO_SUBSCRIPTIONS_REQUEST =
  'UPDATE_GEO_SUBSCRIPTIONS_REQUEST';
export const UPDATE_GEO_SUBSCRIPTIONS_SUCCESS =
  'UPDATE_GEO_SUBSCRIPTIONS_SUCCESS';
export const UPDATE_GEO_SUBSCRIPTIONS_FAILURE =
  'UPDATE_GEO_SUBSCRIPTIONS_FAILURE';

export const UPDATE_SINGLE_GEO_SUBSCRIPTION = 'UPDATE_SINGLE_GEO_SUBSCRIPTION';

export const WINDOW_RESIZE = 'WINDOW_RESIZE';

export * from './IdpConnectionsActions';
export * from './IdpSamlConfigActions';
export * from './PaymentActions';
export * from './SegmentActions';
export * from './GlobalActions';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export {
  FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST,
  FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS,
  FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE,
  FETCH_CANDIDATES_REQUEST,
  FETCH_CANDIDATES_SUCCESS,
  FETCH_CANDIDATES_FAILURE,
  UPDATE_SEARCH_PARAMS,
  UPDATE_PAGE_PARAM,
  CLEAR_SEARCH_PARAMS,
  DELETE_SAVED_SEARCH,
  INITIALIZE_SEARCH,
} from './SearchActions';

export {
  FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST,
  FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS,
  FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE,
  FETCH_BULK_ACTION_REQUEST,
  FETCH_BULK_ACTION_SUCCESS,
  FETCH_BULK_ACTION_FAILURE,
} from './ContinuousServicesActions';
