import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Missing from 'modules/assess/ui/router/missing';
import { Query, useFetch } from 'modules/assess/ui/ruleset/api';
import Versions from './versions';

type Params = {
  ruleset: string;
};

const Instance: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { path: base, params } = match;
  const { ruleset } = params;
  const query = useFetch(ruleset);

  return (
    <Query.Provider value={query}>
      <Switch>
        <Route path={`${base}/versions`}>
          <Versions />
        </Route>
        <Route path={`${base}/:missing`}>
          <Missing />
        </Route>
        <Redirect to={`${base}/versions/draft`} />
      </Switch>
    </Query.Provider>
  );
};

export default Instance;
