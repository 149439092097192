import React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Label = styled.div`
  width: 8em;
`;

export const Field: React.FC<Props> = ({ label, children, ...props }) => {
  return (
    <Container {...props}>
      <Label>
        <strong>{label}:</strong>
      </Label>
      {children}
    </Container>
  );
};

export default Field;
