import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  formatDateIfDate,
  getResultOrStatus,
  GenericObject,
} from '@dashboard-experience/utils';

type BasicMVRKeyValueListProps = {
  licenseReport: GenericObject;
};
const BasicMVRKeyValueList: React.FC<BasicMVRKeyValueListProps> = ({
  licenseReport,
}) => {
  const MVRItems = [
    {
      itemKey: 'License status',
      itemValue:
        underscoreToHuman(getResultOrStatus(licenseReport))?.toUpperCase() ||
        '-',
    },
    {
      itemKey: 'License number',
      itemValue: licenseReport.number,
    },
    {
      itemKey: 'License type',
      itemValue: licenseReport.type || '-',
    },
    {
      itemKey: 'License class',
      itemValue: licenseReport.class || '-',
    },
    {
      itemKey: 'License expiration date',
      itemValue: formatDateIfDate(licenseReport.expiration_date) || '-',
    },
    {
      itemKey: 'License issued date',
      itemValue: formatDateIfDate(licenseReport.issued_date) || '-',
    },
    {
      itemKey: 'License first issued date',
      itemValue: formatDateIfDate(licenseReport.first_issued_date) || '-',
    },
    {
      itemKey: 'License DOB returned',
      itemValue: formatDateIfDate(licenseReport.dob) || '-',
    },
  ];

  return <M.KeyValueList items={MVRItems} role='mvrKeyValueList' />;
};

export default BasicMVRKeyValueList;
