/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  ChoiceCardWrap,
  StyledChoiceCardGroup,
} from '../../Packages/OrderBackgroundCheck/SharedItems';

export const HeaderContainer = styled.div`
  font-size: 1.05rem;
  font-weight: 700;
`;

export const LabelTextContainer = styled.div`
  text-wrap: nowrap;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 50%;
  flex-shrink: 0;

  @media (max-width: 671px) {
    flex-direction: column;
  }
`;

export const ZipcodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LocationPanelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 420px;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const LocationLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const SkeletonContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const LocationlLabelHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelAddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  gap: 10px;
`;

export const BusinessHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;

  p {
    margin-bottom: 0 !important;
  }
`;

export const LocationSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Header = styled.div`
  font-family: Helvetica Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
  color: #001730;
  margin-bottom: 32px;
`;

export const RadioButtonWidth = styled(M.ChoiceCard)`
  border: 1px solid
    ${props => (props.checked ? colors.brandNavy2 : colors.brandSlate3)};
`;

export const ChoiceCardGroup = styled(StyledChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;

export const ChoiceCardWrapped = styled(ChoiceCardWrap)`
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
