import React, { useCallback, useState, useRef } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { useClickOutside } from '@dashboard-experience/utils';
import { Years, ChangeEvent, KeyboardEvent } from './types';

type Props = {
  min: number;
  max: number;
  years: Years;
  setFocused: (focused: boolean) => void;
  setYears: (data: Years) => void;
};

type InputContainerProps = {
  invalid: boolean;
};

const InputContainer = styled.span<InputContainerProps>`
  // added an extra id to override mastodon focus behavior for invalid state
  #lookback-period-table-input {
    .cds--number input[type='number'] {
      padding-right: 0 !important;
      min-width: 0;
    }
    .cds--number__controls {
      display: none !important;
    }
    .cds--number__invalid {
      right: 1.15rem !important;
    }
    .cds--number {
      display: unset;
      #undefined-error-msg {
        display: none;
      }
    }
    .cds--number[data-invalid] input[type='number']:focus {
      ${({ invalid }) => {
        if (!invalid) {
          return '';
        }
        return `border-bottom: 1px solid ${colors.uiOrange600} !important;`;
      }}
    }
  }
`;

const Input: React.FC<Props> = ({ min, max, years, setFocused, setYears }) => {
  const [value, setValue] = useState(years);
  const invalid =
    value === undefined || value === null ? false : value > max || value < min;
  const ref = useRef<HTMLElement>(null!);

  const handleChange = useCallback(
    (e: ChangeEvent) => {
      setValue(e.target.value === '' ? null : Number(e.target.value));
    },
    [setValue],
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.charCode === 45 || e.charCode === 46) {
        e.preventDefault();
        return;
      }
      if (e.key === 'Enter' && !invalid) {
        setYears(value);
        setFocused(false);
      }
    },
    [invalid, setYears, setFocused, value],
  );

  useClickOutside({
    ref,
    handler: () => {
      if (!invalid && years !== value) {
        setYears(value);
      }
      setFocused(false);
    },
  });

  return (
    <InputContainer invalid={invalid}>
      <span id='lookback-period-table-input'>
        <M.NumberInput
          ref={ref}
          invalid={invalid}
          invalidText=''
          value={years ?? ''}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          allowEmpty
          hideLabel
          hideSteppers
          autoFocus
        />
      </span>
    </InputContainer>
  );
};

export default Input;
