import React from 'react';
import { AccountSettingsParams, useCreateAccountSettings } from 'api/i9';
import { IAccountSettings } from '../Models';

const defaultWorksiteName = 'Default Worksite';

const useSubmitAccountSettings = () => {
  const { accountSettingsCreatedCall, accountSettingsCreatedResult } =
    useCreateAccountSettings();
  const submit = React.useCallback(
    (accounsSettingsFormValues: Partial<IAccountSettings>) => {
      const { employer_name, default_worksite, terms_of_service_agreement } =
        accounsSettingsFormValues;
      const accountSettingsObject: AccountSettingsParams = {
        params: {
          employer_name: employer_name || '',
          terms_of_service_agreement: {
            version: terms_of_service_agreement?.version || 0,
          },
          default_worksite: {
            name: defaultWorksiteName,
            street_line1: default_worksite?.street_line1 || '',
            street_line2: default_worksite?.street_line2 || '',
            city: default_worksite?.city || '',
            state: default_worksite?.state || '',
            zip_code: default_worksite?.zip_code || '',
          },
        },
      };

      accountSettingsCreatedCall(accountSettingsObject);
    },
    [accountSettingsCreatedCall],
  );

  return { submit, accountSettingsCreatedResult };
};

export default useSubmitAccountSettings;
