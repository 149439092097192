import { Initializer } from 'state/utilities/state';
import * as Geo from './geo';
import * as Package from './package';

export type Type = {
  adverse_action_pause_on_candidate_story_submitted?: boolean;
  adverse_action_pause_on_candidate_story_submitted_value?: string;
  adverse_action_automation?: {
    enabled?: boolean;
    value?: {
      geos?: boolean | null;
      packages?: boolean | null;
    };
  };
  adverse_action_assessment_eligible?: boolean;
  assess_auto_adverse_action_escalated_reports?: boolean;
  assess_auto_adverse_action_escalated_reports_value?: string;
  assess_auto_engage_eligible_reports?: boolean;
  assess_auto_engage_eligible_reports_value?: string;
  assess_context_date_override?: boolean;
  assess_eligible_item_display?: string;
  assess_enabled?: boolean;
  assess_insights?: boolean;
  candidate_stories_request_on_adverse_action?: boolean;
  candidate_stories_request_on_adverse_action_value?: string;
  geos?: Array<Geo.Type>;
  packages?: Array<Package.Type>;
};

export enum AutoAdverseActionOptions {
  All = 'all',
  EnabledPackages = 'enabled_packages',
}

export enum AutoEngageOptions {
  All = 'all',
  EnabledPackages = 'enabled_packages',
}

export enum CandidateStoriesRequestOptions {
  All = 'all',
  AutoOnly = 'auto_only',
  ManualOnly = 'manual_only',
  EnabledPackages = 'enabled_packages',
}

export enum AdverseActionPauseOptions {
  All = 'all',
  AutoOnly = 'auto_only',
  ManualOnly = 'manual_only',
  EnabledPackages = 'enabled_packages',
}

export const initialize: Initializer<Type> = () => ({
  adverse_action_assessment_eligible: true,
  assess_context_date_override: false,
  assess_enabled: false,
  assess_eligible_item_display: 'visible',
  assess_insights: false,
});

export type List = Array<Type>;
export type Map = { [key: string]: Type };
