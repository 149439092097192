import React from 'react';
import { FieldComponentProps } from '../types';

const Category: React.FC<FieldComponentProps> = ({ adverseItem, testid }) => {
  const { category } = adverseItem;
  return (
    <span data-testid={testid} className='value-width'>
      {category}
    </span>
  );
};

export default Category;
