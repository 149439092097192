/* eslint-disable react/display-name */
import React, { useCallback, useContext } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  underscoreToHuman,
  useToggle,
} from '@dashboard-experience/utils';
import { Flag } from '@dashboard-experience/react-flagr';

import UIContext from 'context/UI';
import { usePackages, usePackageObj } from 'containers/Report';
import { UPGRADE_PACKAGES_FLAG_KEY } from 'Constants';
import { postMessageToDashboard, scrollToTop } from 'utils';

import { useUpgradeReport } from 'api/reports';
import { ConfirmModal } from 'components';
import styled from 'styled-components';
import { FieldComponentProps, OverflowItemProps, RefType } from '../types';

const OverflowMenu = styled(M.OverflowMenu)`
  &.cds--overflow-menu {
    height: unset;
    color: ${colors.uiNavy600};

    &.cds--btn--md {
      min-height: 1.4rem;
      padding: 0;
    }

    &.cds--overflow-menu--open {
      box-shadow: none;
      background-color: ${colors.uiGrey0};

      .cds--overflow-menu-options__btn:focus {
        outline: none;
      }
    }

    &:hover {
      background: unset;
      color: ${colors.uiNavy700};
    }
  }
`;

const PackageName = styled.span`
  overflow-wrap: anywhere;
`;

const PackageOverflowItem = React.forwardRef<RefType, OverflowItemProps>(
  (
    {
      packageItem,
      isIframe,
      reportId,
      className,
      isDelete,
      itemPosition,
      primaryItem,
      selectorPrimaryFocus,
      setPrimaryItem,
      contextId,
      ...props
    },
    ref,
  ) => {
    const packageSlug = packageItem.slug;
    const { upgradeReportCall } = useUpgradeReport(reportId, packageSlug);
    const { isToggled, toggle } = useToggle();
    const { isStandaloneIframe } = useContext(UIContext);

    const confirmUpgrade = useCallback(() => {
      upgradeReportCall();
      toggle();
    }, [upgradeReportCall, toggle]);

    /**
     * @name openConfirmUpgradeMessage
     * @function
     * @memberOf Package
     * @description Sends message back to angular to pop the Message. NOTE: Remove when we can use modal completely in Customer
     */
    const openConfirmUpgradeMessage = useCallback(() => {
      if (isIframe) {
        postMessageToDashboard({
          body: packageSlug,
          contextId,
          messageId: 'open_confirm_upgrade_message',
        });
      }
    }, [isIframe, packageSlug, contextId]);

    const itemClickHandler = useCallback(() => {
      if (!isIframe || isStandaloneIframe) {
        scrollToTop(); // Standalone iframe needs to scroll up in order to see the modal
        toggle();
      } else {
        openConfirmUpgradeMessage();
      }
    }, [isIframe, isStandaloneIframe, openConfirmUpgradeMessage, toggle]);

    return (
      <>
        {/* @ts-ignore */}
        <ConfirmModal
          open={isToggled}
          hideModal={toggle}
          callback={confirmUpgrade}
          header='Upgrade Package'
          message={`Are you sure you want to upgrade to ${underscoreToHuman(
            packageSlug,
          )}.`}
        />
        <M.OverflowMenuItem
          className={className}
          isDelete={isDelete}
          itemPosition={itemPosition}
          primaryItem={primaryItem}
          selectorPrimaryFocus={selectorPrimaryFocus}
          setPrimaryItem={setPrimaryItem}
          key={packageSlug}
          ref={ref}
          itemText={underscoreToHuman(packageSlug)}
          onClick={itemClickHandler}
        />
      </>
    );
  },
);

const RenderedIcon = () => (
  <div
    style={{
      padding: '0 1rem',
    }}
  >
    Upgrade
  </div>
);

const Package: React.FC<FieldComponentProps> = ({
  report,
  currentUser,
  isIframe,
  contextId,
  testid,
}) => {
  const reportPackage = report.package_name ?? report.package;
  const packages = usePackages() || [];
  const packageObj = usePackageObj();

  // if a report's package wasn't found, include all packages
  const upgradePackages = packages?.filter(
    upgradablePackage =>
      !packageObj || upgradablePackage.slug !== packageObj.slug,
  );

  return (
    <div data-testid={testid}>
      <PackageName>{reportPackage}</PackageName>
      <Flag flagKey={UPGRADE_PACKAGES_FLAG_KEY} variantKey='on'>
        {upgradePackages.length > 0
          ? hasPermission(currentUser, 'perform_upgrade') && (
              <OverflowMenu
                data-floating-menu-container
                id='manual-upgrade'
                style={{
                  display: 'inline',
                  width: 'auto',
                }}
                renderIcon={RenderedIcon}
              >
                {upgradePackages.map(packageItem => {
                  return (
                    <PackageOverflowItem
                      key={packageItem.slug}
                      packageItem={packageItem}
                      reportId={report.id}
                      isIframe={isIframe}
                      contextId={contextId}
                    />
                  );
                })}
              </OverflowMenu>
            )
          : null}
      </Flag>
    </div>
  );
};

export default Package;
