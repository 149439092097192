import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useResumeAdverseAction } from 'modules/adjudication/api';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import Modal from './modal';
import Guard from './guard';

const Button = styled(M.Button)`
  width: 100%;
  margin: 1rem 0 0 !important;
  max-width: none !important;
  justify-content: center;
`;

const Resume = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const { call } = useResumeAdverseAction();
  const [modalOpen, setOpenModal] = useState(false);

  const openModal = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Resume Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpenModal]);

  const hideModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideModal();
  }, [call, hideModal]);

  const action = t(`${namespace}:report.actions.resume.action`);
  return (
    <Guard>
      <Button
        data-testid='resume'
        kind='danger'
        onClick={openModal}
        disabled={report.archived}
      >
        {action}
      </Button>
      <Modal open={modalOpen} hideModal={hideModal} callback={handleConfirm} />
    </Guard>
  );
};

export default Resume;
