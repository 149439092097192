import React from 'react';
import { EducationDegree } from 'types';
import { KeyValueSection } from '../Sections';

type Props = {
  degree: EducationDegree;
  number: number;
  pending: boolean;
};

const hideIfPending = (pending: boolean, value?: any) =>
  pending ? 'Pending' : value || '-';

const VerifiedDegree: React.FC<Props> = ({ degree, number, pending }) => (
  <KeyValueSection
    title={`Verified Degree #${number}`}
    items={[
      {
        itemKey: 'Degree level',
        itemValue: hideIfPending(pending, degree.title),
      },
      {
        itemKey: 'Majors',
        itemValue: hideIfPending(pending, degree.majors),
      },
      {
        itemKey: 'Concentrations',
        itemValue: hideIfPending(pending, degree.major_concentrations),
      },
      {
        itemKey: 'Minors',
        itemValue: hideIfPending(pending, degree.minors),
      },
      {
        itemKey: 'Year Awarded',
        itemValue: hideIfPending(pending, degree.year_awarded),
      },
    ]}
  />
);

export default VerifiedDegree;
