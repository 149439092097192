import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BaseRuleChange } from 'modules/assess/models/rulesets/version-diff';
import { Field as FieldModel } from 'modules/assess/models/rulesets/version-diff/common';
import Field from 'modules/assess/ui/ruleset/version/publish/changes/common/Field';
import Conditions from './Conditions';
import { TypeFields, Type } from '../../../type';

type Props = {
  change: BaseRuleChange.Type;
  displayItemVisibility?: boolean;
  type: Type;
};

const ChangesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container: React.FC<Props> = ({
  change,
  displayItemVisibility,
  type,
}) => {
  const { t } = useTranslation('assess');
  const changes = TypeFields.get(type)?.filter(
    c => c !== 'item_visibility' || displayItemVisibility,
  );

  const content = changes?.map(c => {
    const name = t(
      `assess:ruleset.version.publish.changes.${type.toLowerCase()}.changes.${c}`,
    );
    const field = change[c] as FieldModel.Type;

    return (
      <Field
        isAssessmentDecision={c === 'decision'}
        key={name}
        name={name}
        field={field}
      />
    );
  });

  return (
    <ChangesContainer>
      {content}
      <Conditions conditions={change.conditions} />
    </ChangesContainer>
  );
};

export default Container;
