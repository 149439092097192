import {
  ADJUDICATION_VALUES,
  getResultOrStatus,
  hasPermission,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { useAdverseActionable, useGetReport } from 'api/reports';
import { useUser } from 'context/CurrentUser';
import { useAdverseActions } from 'modules/adjudication/api';

export const useAdjudicationChangeAllowed = () => {
  const currentUser = useUser();
  return hasPermission(currentUser, 'perform_adjudication');
};

export const useDisplayCancelAdverseAction = () => {
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();
  const { report } = useGetReport();
  const { adverseAction } = useAdverseActions(report?.id);
  return adjudicationChangeAllowed && adverseAction?.status === 'pending';
};

export const useAdverseActionAllowedBySelfDisclosure = () => {
  const { report } = useGetReport();

  return (
    report &&
    report.account?.adverse_action_self_disclosure &&
    report.self_disclosure_screening?.self_disclosures?.length > 0
  );
};

export const usePauseAdverseActionAllowed = () => {
  const { report } = useGetReport();
  const { id: reportId } = report;
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();
  const { adverseAction } = useAdverseActions(reportId);

  return (
    adverseAction?.aa_delivery_state === 'delivered' &&
    adverseAction?.post_notice_scheduled_at &&
    !adverseAction?.pause_data &&
    report?.account?.adverse_action_pause &&
    report?.adjudication === 'pre_adverse_action' &&
    adjudicationChangeAllowed
  );
};

export const usePostAdverseActionAllowed = () => {
  const { report } = useGetReport();
  const isPreAdverseAction = report?.adjudication === 'pre_adverse_action';
  const { id: reportId } = report;
  const { adverseAction } = useAdverseActions(reportId);
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();
  const hasBeenDelivered = adverseAction?.aa_delivery_state === 'delivered';

  if (!hasBeenDelivered) return false;
  if (!isPreAdverseAction) return false;
  if (adverseAction?.created_by) return false;
  if (!adjudicationChangeAllowed) return false;

  return true;
};

export const useDisplayDeferredAction = () => {
  const { report } = useGetReport();
  const isSuspended = report?.status === ReportStatuses.SUSPENDED;
  const isConsider = getResultOrStatus(report) === ReportStatuses.CONSIDER;
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();
  const { adverseActionable } = useAdverseActionable();

  const accountHasDeferredEnabled =
    report?.account?.adjudication_deferred_enabled;

  const adjudicationIsEligible =
    adjudicationChangeAllowed &&
    (report?.adjudication === null ||
      report?.adjudication === ADJUDICATION_VALUES.ENGAGED);

  return (
    !isSuspended &&
    accountHasDeferredEnabled &&
    (adverseActionable?.enabled || (adjudicationIsEligible && isConsider))
  );
};

export const useDisplayRequireAction = () => {
  const { report } = useGetReport();
  const { adjudication, account } = report;
  const isAdjudicationActionable = adjudication
    ? !['engaged', 'deferred', 'post_adverse_action'].includes(adjudication)
    : true;

  return (
    account?.collaboration_suite &&
    isAdjudicationActionable &&
    report?.report_tasks
  );
};

export const useAdverseActionAllowedOnSuspendedReport = () => {
  const { report } = useGetReport();
  return report.account?.adverse_action_report_suspended;
};
