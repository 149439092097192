import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes, getScreeningStatus } from '@dashboard-experience/utils';
import { usePackagesLoading } from 'containers/Report';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import styled from 'styled-components';
import Table from './Table';
import DrugHealthScreening from './DrugHealthScreening';
import { Screenings } from './types';

type ExamResult = {
  name: string;
  slug: string;
  status: string;
  analytes: any[];
};

const headers = [
  { key: 'name', header: 'Test' },
  { key: 'disposition', header: 'Result' },
];

const getHealthScreeningTestStatusOrAssessment = (
  status: string,
  reportStatusType: string,
  statusOrAssessment: string,
) => {
  if (status === 'consider' && reportStatusType === StatusTypes.Assess) {
    return statusOrAssessment;
  }
  return status;
};

const StatusItem = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const V2DrugScreening: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { drug_screening: screening } = report;
  const packagesLoading = usePackagesLoading();
  if (!screening) return null;

  const statusOrAssessment = getScreeningStatus(screening, reportStatusType);
  const title = screening.is_clinic_based
    ? 'Clinic Based Drug Screening'
    : 'On-site Drug Screening';

  return packagesLoading ? (
    <M.LoadingInline />
  ) : (
    <DrugHealthScreening
      screening={screening}
      title={title}
      disposition='Disposition'
      reportStatusType={reportStatusType}
      timelineStatuses={screening.events}
      timelineKey='type'
      screeningTestId={SCREENING_TITLES.drug_screening.id}
    >
      {screening.exam_results?.length > 0 &&
        screening.exam_results.map((exam: ExamResult) => {
          const { name, analytes, status, slug } = exam;

          return (
            <div key={slug}>
              <strong data-testid='screenings-drug-health-category-name'>
                {name}
              </strong>
              <StatusItem>
                <M.StatusBadge
                  status={getHealthScreeningTestStatusOrAssessment(
                    status,
                    reportStatusType,
                    statusOrAssessment,
                  )}
                  statusType={reportStatusType}
                />
              </StatusItem>
              <Table
                rows={analytes}
                headers={headers}
                type={Screenings.V2Drug}
                assessEnabled={reportStatusType === StatusTypes.Assess}
                statusOrAssessment={statusOrAssessment}
                canceledStatusEnabled={false}
                tableProps={{ style: { width: '100%' } }}
              />
            </div>
          );
        })}
    </DrugHealthScreening>
  );
};

export default V2DrugScreening;
