import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Header = styled.h4`
  padding-top: 1rem;
`;

export const Title: React.FC = () => {
  const { t } = useTranslation('assess');
  const text = t('assess:settings.reports.general-settings.title');

  return <Header>{text}</Header>;
};

export default Title;
