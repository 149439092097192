import React, { useEffect, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useToggle } from '@dashboard-experience/utils';
import SegmentsContainer from 'containers/SegmentsContainer';
import {
  SegmentsProvider,
  useDispatch as useSegmentsDispatch,
} from 'components/Segments/SegmentsContext';
import { scrollToTop } from 'utils';
import {
  SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
  SHOW_ASSIGN_SEGMENTS_SUCCESS,
} from 'api/segments';

const Button = styled(M.Button)`
  margin: 0.625rem 0 0.75rem 1rem !important;
`;

type PackageProps = {
  name: string;
  id: string;
};

type NodeCellProps = {
  data: PackageProps;
  accountId: string;
};

const AssignNodesCell: React.FC<NodeCellProps> = ({ data, accountId }) => {
  const name = data?.name;
  const { isToggled, toggle } = useToggle();

  const segmentsDispatch = useSegmentsDispatch();

  useEffect(() => {
    if (isToggled) scrollToTop();
  }, [isToggled]);

  const handleHideAssignSegmentsSuccess = useCallback(() => {
    toggle();

    segmentsDispatch({
      type: SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
      payload: {
        showAssignSegmentsConfirmationView: false,
      },
    });

    segmentsDispatch({
      type: SHOW_ASSIGN_SEGMENTS_SUCCESS,
      payload: {
        showAssignSegmentsSuccessView: false,
      },
    });
  }, [segmentsDispatch, toggle]);

  return (
    <>
      <Button kind='secondary' size='sm' onClick={toggle}>
        Assign Nodes
      </Button>
      {isToggled && (
        <M.ComposedModal
          className='home-packages-modal'
          open={isToggled}
          onClose={handleHideAssignSegmentsSuccess}
        >
          <M.ModalHeader closeModal={handleHideAssignSegmentsSuccess}>
            <h2>{`Package - ${name}`}</h2>
          </M.ModalHeader>
          <SegmentsProvider>
            <SegmentsContainer
              parentContext='packages'
              parentId={data.id}
              accountId={accountId}
              closeModal={toggle}
            />
          </SegmentsProvider>
        </M.ComposedModal>
      )}
    </>
  );
};

export default AssignNodesCell;
