import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FactRule, Operator, SingleFactValue } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import {
  FactRuleOption,
  getFactConfig,
  getFactOptions,
} from 'modules/assess/ui/rules/common/rule/editor/conditions';
import { Fact, RecordType } from 'modules/assess/models/criminal/rules';
import {
  Fact as MVRFact,
  RecordType as MVRRecordType,
} from 'modules/assess/models/mvr/rules';
import { Kind } from 'modules/assess/models/rules/common/kind';
import { useOptionValues } from '../context';
import ValueSelectorMultiple from './value-selector-multiple';
import ValueSelectorSingle from './value-selector-single';

type Props = {
  condition: Partial<FactRule>;
  kind: Kind;
  namespace: Namespace;
  setValue: (value: FactRuleOption) => void;
};

export const ValueSelectorLegacy: React.FC<Props> = ({
  condition,
  kind,
  namespace,
  setValue,
}) => {
  const optionValues = useOptionValues();
  const config = getFactConfig(namespace, condition.fact, optionValues);
  let options = getFactOptions(config);

  if (
    kind === Kind.COUNT &&
    (condition.fact === Fact.TYPE || condition.fact === MVRFact.TYPE)
  ) {
    options = options?.filter(
      f =>
        f.value === RecordType.CRIMINAL_CHARGE ||
        f.value === MVRRecordType.ACCIDENT ||
        f.value === MVRRecordType.SUSPENSION ||
        f.value === MVRRecordType.VIOLATION,
    );
  }

  const { t } = useTranslation('');

  if (condition.operator === Operator.EQ && config?.operator === Operator.IN) {
    condition.operator = Operator.IN;
    if (condition.value) {
      condition.value = [condition.value as any];
    }
  }

  const selectedOption =
    condition.operator && condition.value
      ? pick(condition as FactRuleOption, ['operator', 'value'])
      : undefined;

  if (selectedOption) {
    const { operator, value } = selectedOption;
    let selectedOptions: Array<FactRuleOption> = [];

    if (Array.isArray(value)) {
      selectedOptions = value.map((v: SingleFactValue) => ({
        operator,
        value: v,
      }));
    } else {
      selectedOptions.push(selectedOption);
    }

    const missing = selectedOptions.filter(
      item => !options?.find(option => isEqual(option, item)),
    );
    options?.unshift(...missing);
  }

  const getOptionLabel = useCallback(
    (option: FactRuleOption) => {
      const trans: any = t(`assess:rules.facts.${condition.fact}`, {
        returnObjects: true,
      });
      const operator = trans.operators?.[option.operator];
      const value = option.value.toString();
      const result = trans.values?.[value] ?? value;

      return operator ? `${operator} ${result}` : result;
    },
    [condition, t],
  );

  if (!options) {
    return null;
  }

  return condition.operator === Operator.IN ? (
    <ValueSelectorMultiple
      options={options}
      selectedOptions={selectedOption}
      getOptionLabel={getOptionLabel}
      onChange={setValue}
      fact={condition.fact}
    />
  ) : (
    <ValueSelectorSingle
      options={options}
      selectedOptions={selectedOption}
      getOptionLabel={getOptionLabel}
      onChange={setValue}
    />
  );
};

export default ValueSelectorLegacy;
