import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';
import styled from 'styled-components';

type Props = {
  disabled: boolean;
  onClose?: (event: SyntheticEvent) => void;
  onAdd?: (event: SyntheticEvent) => void;
};

const Container = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const Actions: React.FC<Props> = ({ disabled, onClose, onAdd }) => {
  return (
    <Container>
      <Button kind='secondary' trans='verbs.cancel' onClick={onClose} />
      <Button
        disabled={disabled}
        kind='primary'
        trans='buttons.save_excluded_locations'
        onClick={onAdd}
      />
    </Container>
  );
};

export default Actions;
