import { M } from '@dashboard-experience/mastodon';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { ID } from 'modules/id';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../api';
import Content from './content';

type Props = {
  instance: ID;
  tab?: 'container' | 'underline';
  version: ID;
} & ContainerProps;

type ContainerProps = React.HTMLAttributes<HTMLDivElement>;

export const order: Array<ItemType> = [
  ItemType.VIOLATION,
  ItemType.ACCIDENT,
  ItemType.SUSPENSION,
];

const Container: React.FC<Props> = ({
  instance,
  tab = 'container',
  version,
  ...extras
}) => {
  const query = useFetch(instance, version);
  const { t } = useTranslation('assess');

  const { data } = query;

  const tabs: {
    label: string;
    content: React.ReactNode;
  }[] = [];

  order.forEach(type => {
    const categories = data?.categories?.filter(
      c => !c.type || c.type === type,
    );
    const label = t(`assess:mvr.record-types.${type}`, { context: 'plural' });

    tabs.push({
      label,
      content: (
        <Content
          categories={categories}
          instance={instance}
          version={version}
          type={type}
        />
      ),
    });
  });

  return (
    <div data-testid='assess-mvr-category-set-version-mappings' {...extras}>
      {tabs.length > 0 && (
        <M.Tabs>
          <M.TabList contained>
            {tabs.map(({ label }) => (
              <M.Tab key={label}>{label}</M.Tab>
            ))}
          </M.TabList>
          <M.TabPanels>
            {tabs.map(({ label, content }) => (
              <M.TabPanel key={label}>{content}</M.TabPanel>
            ))}
          </M.TabPanels>
        </M.Tabs>
      )}
    </div>
  );
};

export default Container;
