import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Checkmark from './svg/Checkmark.svg';

export const Label = styled.div`
  display: flex;
  padding: 8px 16px;
  padding-left: 0px;
  align-items: center;
  height: 37px;
  gap: 8px;
  border-radius: 2px;
`;

const CheckmarkImg = styled.img`
  width: 16px;
  height: 16px;
`;

const Title = styled.span`
  color: ${colors.brandNavy4}
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const AddedLabel: React.FC<{}> = () => {
  return (
    <Label data-testid='added-label'>
      <CheckmarkImg src={Checkmark} alt='' /> <Title>Added</Title>
    </Label>
  );
};

export default AddedLabel;
