import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  formatDateIfDate,
} from '@dashboard-experience/utils';
import { GenericObject } from 'types';
import { MvrItem } from './MultiMotorVehicleReportComponents';

type MiscellaneousIncidentsProps = {
  licenseReportOrCharge: GenericObject;
};

const MiscellaneousIncidents: React.FC<MiscellaneousIncidentsProps> = ({
  licenseReportOrCharge,
}) => {
  return (
    <M.SubScreening
      title='Miscellaneous incidents'
      data-testid='mvr-miscellaneous'
    >
      {licenseReportOrCharge.miscellaneous_incidents.map(
        (misc: GenericObject, idx: number) => {
          const MiscellaneousIncidentsItems = Object.keys(misc)
            .filter(parameter => {
              return misc[parameter];
            })
            .map(parameter => {
              const itemKeyValue = underscoreToHuman(parameter) || null;
              const itemValue = misc[parameter]
                ? formatDateIfDate(misc[parameter])
                : null;
              return { itemKey: itemKeyValue, itemValue };
            });

          return (
            <M.Expandable
              role='miscellaneousItem'
              key={`${misc.description}-${idx + 1}`}
              title={
                <>
                  <MvrItem item={misc} />
                  <span style={{ float: 'right' }}>
                    {formatDateIfDate(misc.date)}
                  </span>
                </>
              }
            >
              <M.KeyValueList items={MiscellaneousIncidentsItems} />
            </M.Expandable>
          );
        },
      )}{' '}
    </M.SubScreening>
  );
};

export default MiscellaneousIncidents;
