import React from 'react';
import { useCreateWorksite, WorksiteParams } from 'api/i9';
import { IWorksite } from '../Models';

const useSubmitAddWorksite = (successCallback: () => void) => {
  const { worksiteCreatedCall } = useCreateWorksite(successCallback);
  const submit = React.useCallback(
    (worksiteFormValues: IWorksite) => {
      const worksiteObject: WorksiteParams = {
        params: {
          name: worksiteFormValues.worksiteName,
          street_line1: worksiteFormValues.street1,
          street_line2: worksiteFormValues.street2,
          city: worksiteFormValues.city,
          state: worksiteFormValues.state,
          zip_code: worksiteFormValues.zipCode,
          everify_status: 'not_determined',
        },
      };

      worksiteCreatedCall(worksiteObject);
    },
    [worksiteCreatedCall],
  );

  return submit;
};

export default useSubmitAddWorksite;
