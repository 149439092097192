/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SignInLink,
  ContactUsLink,
  DashboardLink,
  HiddenIconWrapper,
  IconWrapper,
} from './styles';

export const getFormValues = () => {
  const storedValue = sessionStorage.getItem('signup');
  let storedObject: Record<string, any> = {};
  if (storedValue) {
    storedObject = JSON.parse(storedValue);
  }
  return storedObject.createAccountSection || {};
};

const GOOGLE_CLEAR_CACHE_URL =
  'https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop';

export const getErrorMessage = (t: any, error: any) => {
  if (error?.source === 'payment') {
    return <span>{t('components.error.payment')}</span>;
  }

  if (error?.source === 'payment-intent') {
    return (
      <div>
        <span>{t('components.error.paymentIntent')}</span>{' '}
        <ContactUsLink>{t('components.error.contactUs')}</ContactUsLink>
      </div>
    );
  }

  if (error?.source === 'setup-intent-unexpected-state') {
    return (
      <div>
        <span>{t('components.error.refreshPage')}</span>{' '}
      </div>
    );
  }

  if (
    error?.source === 'queue-invites' &&
    error?.error?.response?.data?.errors?.includes(
      'Email is not a valid email address',
    )
  ) {
    return (
      <div>
        <span>
          <strong>{t('components.error.badCandidateEmail')}</strong>{' '}
          {t('components.error.editOrRemove')}{' '}
          <DashboardLink>{t('components.error.dashboard')}</DashboardLink>.
        </span>
      </div>
    );
  }

  if (error?.source === 'queue-invites') {
    return (
      <div>
        <span>
          <strong>{t('components.error.issueOrdering')}</strong>{' '}
          {t('components.error.pleaseTryAgain')}{' '}
          <ContactUsLink>{t('components.error.contactUs')}</ContactUsLink>{' '}
          {t('components.error.ifTroubleContinues')}
        </span>
      </div>
    );
  }
  let errorMessage;

  try {
    errorMessage = error.error.response.data.error;
  } catch (e) {
    return (
      <div>
        <span>
          {t('components.error.genericError')}{' '}
          <ContactUsLink>{t('components.error.contactUs')}</ContactUsLink>
        </span>
      </div>
    );
  }

  if (errorMessage.match(/email.*taken/i)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <span>
          <strong>
            {t('components.error.alreadyHaveAccount', {
              email: getFormValues().email,
            })}
          </strong>{' '}
          {t('components.error.signInAccount')}
        </span>
        <SignInLink>{t('components.error.signIn')}</SignInLink>
      </div>
    );
  }

  if (errorMessage.match(/contains invalid characters./i)) {
    const message = errorMessage.match(/Name/i)
      ? t('components.error.invalidCharacter')
      : error?.error?.response?.data?.error;

    return (
      <span>
        {message}
        <br />
        <br />
        {t('components.error.clearCacheMessage')}{' '}
        <a
          href={GOOGLE_CLEAR_CACHE_URL}
          target='_blank'
          rel='noopener noreferrer'
        >
          here.
        </a>
      </span>
    );
  }

  return <div>{errorMessage}</div>;
};

type Props = {
  error: any;
  modalRef?: any;
};

const Errors = ({ error, modalRef }: Props) => {
  const { t } = useTranslation('signup');
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    setShowError(true);
    if (error && modalRef?.current) {
      modalRef?.current?.scrollIntoView();
    }
  }, [error, modalRef]);

  const handleClose = useCallback(() => {
    setShowError(false);
  }, []);

  if (!showError || !error) {
    return null;
  }

  return (
    <Container>
      <div>{getErrorMessage(t, error)}</div>
      <HiddenIconWrapper>
        <M.Icon icon='Close' height='20' width='20' />
      </HiddenIconWrapper>
      <IconWrapper onClick={handleClose}>
        <M.Icon icon='Close' height='20' width='20' />
      </IconWrapper>
    </Container>
  );
};

export default Errors;
