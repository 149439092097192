import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';
import { FieldComponentProps } from '../../types';
import { deriveLocation } from './utils';

const StyledTooltipContainer = styled(M.TooltipDefinition)`
  vertical-align: middle;
  padding-left: 5px;
`;

const WorkLocations: React.FC<
  Pick<FieldComponentProps, 'report' | 'testid' | 'currentUser'>
> = ({ report, testid, currentUser }) => {
  const { work_locations, geos, candidate, account } = report;

  const { locations, tooltip } = deriveLocation(
    work_locations,
    geos,
    candidate?.zipcode,
    account?.default_compliance_state,
    currentUser,
  );

  return (
    <div data-testid={testid}>
      {locations.map((loc: string, idx: number) => (
        <div key={`${loc}-${idx + 1}`} data-testid='work-location-item'>
          {loc}
          {idx !== locations.length - 1 ? (
            ','
          ) : (
            <StyledTooltipContainer
              align='bottom'
              data-testid='work-locations-tooltip'
              highlighted={false}
              definition={tooltip}
            >
              <M.Icon icon='InformationFilled' />
            </StyledTooltipContainer>
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkLocations;
