import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { useDispatch } from 'react-redux';
import { ColoredParagraph, FlexContainer, SubHeading } from './style';
import { toastError, toastSuccess } from '../../actions';

const paragraphColor = colors.darkGray100;

const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid ${colors.brandSlate2};
`;

const InfoTableRowTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const InfoSpanBottom = styled.span`
  min-width: 10rem;
  margin: 1rem;
  color: ${colors.coolBlack70};
`;

const InfoSpanTop = styled.span`
  margin: 1rem;
  color: ${colors.coolBlack70};
`;

const CopyButton = styled(M.Button)`
  margin-right: 0.25rem;
`;

const StyledBlankDiv = styled.div`
  width: 100%;
  height: 1rem;
`;

interface ActivateEVerifyModalProps {
  hideModal: () => void;
  open: boolean;
}

const ActivateEVerifyModal = ({
  hideModal,
  open,
}: ActivateEVerifyModalProps) => {
  const dispatch = useDispatch();

  const currentUser = useUser();

  const confirmActivateEVerify = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const copyTextToClipboard = useCallback(() => {
    try {
      navigator.clipboard.writeText(currentUser.account.id).then(
        () => {
          dispatch(
            toastSuccess(
              'Copied successfully',
              "Checkr's account id has been copied successfully",
            ),
          );
        },
        err => {
          dispatch(
            toastError(
              'Copy error',
              "Checkr's account id can not be copied, please copy it manually",
            ),
          );
        },
      );
    } catch (err) {
      dispatch(
        toastError(
          'Copy error',
          "Checkr's account id can not be copied, please copy it manually",
        ),
      );
    }
  }, [currentUser.account.id, dispatch]);

  return (
    <M.ComposedModal onClose={hideModal} open={open} size='lg'>
      <M.ModalHeader closeModal={hideModal}>
        <h2 data-testid='e-verify-modal'>Activate E-Verify</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <FlexContainer gap='1rem' padding='0'>
          <FlexContainer gap='0.5rem' padding='0'>
            <SubHeading>How to get started</SubHeading>
            <ColoredParagraph color={paragraphColor}>
              To complete the questionnaire, copy your Checkr’s account ID and
              paste it into the “Customer Profile” fields.
            </ColoredParagraph>
          </FlexContainer>
          <FlexContainer gap='1rem' padding='0'>
            <InfoTable>
              <InfoTableRowTop>
                <InfoSpanBottom>Checkr&apos;s account ID</InfoSpanBottom>
                <InfoSpanTop data-testid='e-verify-account-id'>
                  {currentUser.account.id}
                </InfoSpanTop>
                <CopyButton
                  onClick={copyTextToClipboard}
                  kind='tertiary'
                  data-testid='e-verify-copy-btn'
                >
                  <M.Icon icon='Copy' />
                </CopyButton>
                &nbsp;
              </InfoTableRowTop>
            </InfoTable>
            <ColoredParagraph color={paragraphColor}>
              Then fill out the remaining sections and submit.
            </ColoredParagraph>
            <ColoredParagraph color={paragraphColor}>
              <strong>
                <a
                  href='https://wwwcdn.i9complete.com/imple/chkrev/qJhlJ0qRU4RoLMxF2.html'
                  target='_blank'
                  rel='noreferrer'
                >
                  Open E-Verify enrollment questionnaire
                </a>
              </strong>
            </ColoredParagraph>
            <ColoredParagraph color={paragraphColor}>
              Activation takes 3-5 business days. Make sure another team member
              hasn’t already submitted the questionnaire.
            </ColoredParagraph>
          </FlexContainer>
          <FlexContainer gap='0.5rem' padding='0'>
            <StyledBlankDiv />
            <SubHeading>Need help?</SubHeading>
            <ColoredParagraph color={paragraphColor}>
              Contact&nbsp;
              <a
                href='https://checkr.com/contact'
                target='_blank'
                rel='noreferrer'
              >
                Checkr customer support
              </a>
              &nbsp;or check our&nbsp;
              <a
                href='https://help.checkr.com/hc/en-us/articles/15483238166423-E-Verify'
                target='_blank'
                rel='noreferrer'
              >
                help center
              </a>
            </ColoredParagraph>
          </FlexContainer>
        </FlexContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='primary' onClick={confirmActivateEVerify} size='small'>
          Got it
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ActivateEVerifyModal;
