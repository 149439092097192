export const PACKAGES_SEGMENTS_QUERY_KEY = 'packages/segments';
export const PACKAGES_SEGMENTS_PARENT_QUERY_KEY = 'packages/segments/parent';
export const PACKAGES_SEGMENTS_SEARCH_QUERY_KEY = 'packages/segments/search';

export const SEGMENTS_PATH = 'segments';
export const APPLICATION_PATH = 'application_instances';
export const NODES_PATH = 'nodes';
export const CUSTOMER_PATH = 'customer';
export const ACCOUNTS_PATH = 'accounts';
export const AMS_API_PATCH_PATH = '/api/public/v1/users';

export const APP_INSTANCE_URL_QUERY = 'app_instance_customer';

export const DEFAULT_ERROR = 'Something went wrong, please try again later.';

export const GET_ASSIGNED_NODES = 'GET_ASSIGNED_NODES';
export const SEARCH_HIERARCHY = 'SEARCH_HIERARCHY';
export const POST_SEGMENTS_SUCCESS = 'POST_SEGMENTS_SUCCESS';
export const SHOW_ASSIGN_SEGMENTS_CONFIRMATION =
  'SHOW_ASSIGN_SEGMENTS_CONFIRMATION';
export const SHOW_ASSIGN_SEGMENTS_SUCCESS = 'SHOW_ASSIGN_SEGMENTS_SUCCESS';
export const CANCEL_ASSIGN_SEGMENTS_CONFIRMATION =
  'CANCEL_ASSIGN_SEGMENTS_CONFIRMATION';
