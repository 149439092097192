import React from 'react';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../types';

const ChargeType: React.FC<FieldComponentProps> = ({ adverseItem, testid }) => {
  const { charge_type } = adverseItem;

  return charge_type ? (
    <span data-testid={testid} className='value-width'>
      {humanizeOnUnderscore(charge_type)}
    </span>
  ) : null;
};

export default ChargeType;
