import Cookies from 'js-cookie';
import { omit } from 'lodash';
import {
  ALLOW_INTERNAL,
  CHECKR_DASHBOARD_URL,
  COOKIE_NAME,
  INTERNAL_DASHBOARD_URL,
  IS_ANGULAR_IFRAME,
  SANDBOX,
  STAGING,
} from 'Constants';
import { localStorageFallback } from '@dashboard-experience/utils';
import postMessageToDashboard from 'utils/PostMessageToDashboard';
import { transformDate } from 'components/Search/Filters/helpers';
import { PARAMS as SEARCH_PARAMS } from 'components/Search/constants';
import qs from 'querystring';
import { GenericObject } from 'types';
import { clearUserPreferences } from 'api/dashboardPreferences';

const hostnameToDomain = (hostname: string) => {
  const parts = hostname.split('.');
  return parts.slice(Math.max(parts.length - 2, 1)).join('.');
};

export const cookieDomain = (hostname: string) =>
  `.${hostnameToDomain(hostname).replace(/^https?:\/\//, '')}`;

/**
 * @name extendCookie
 * @function
 * @description Extend / set the checkr token cookie. Cookie may exist on
 *              the current domain or a domain specified by bellhop (usually
 *              starting with a '.').  It will remove the cookie on the domain
 *              with a dot, and force the cookie to remain on the current
 *              domain only.
 *              Also, this updates the expiry for the cookie to 30 minutes from now.
 * @param {String} token The token to set (optional)
 * @return {String} the value from the cookie
 */
export const extendCookie = (token?: string) => {
  const newExpiryDate = new Date();
  newExpiryDate.setTime(newExpiryDate.getTime() + 30 * 60 * 1000);

  // grab the cookie value, regardless of which domain it's
  // currently associated with
  const CHECKR_TOKEN = !token ? Cookies.get(COOKIE_NAME) : token;

  // remove any token set by bellhop, in favor of setting a new
  // cookie, specific to the current domain.
  Cookies.remove(COOKIE_NAME, {
    domain: cookieDomain(window.location.hostname),
  });

  if (CHECKR_TOKEN) {
    // now set the cookie which by default is scoped to the current domain
    Cookies.set(COOKIE_NAME, CHECKR_TOKEN, {
      expires: newExpiryDate,
    });
  }

  return CHECKR_TOKEN;
};

export const deleteToken = () => {
  Cookies.remove(COOKIE_NAME);
  localStorageFallback.removeItem('accessToken');
  clearUserPreferences();
  localStorageFallback.removeItem('currentUser');
  localStorageFallback.removeItem('tokenExpiryTime');
};

/**
 * @name extendParamsObject
 * @function
 * @memberOf URLHelper
 * @description Given some param keys, existing params object, and a search object taken from the URL, return a new object with values from the search object replacing any existing nodes along with any news ones
 * @returns {object}
 * @param {object} existingObject - The existing params object
 * @param {object} searchObject - The search object from the URL
 * @param {array} numericParamKeys - A list of numeric param keys to look for in the search object
 * @param {array} stringParamKeys - A list of the string param keys ot look for in the search object
 */
export const extendParamsObject = (
  existingObject: { [key: string]: any },
  searchObject: { [key: string]: any },
  numericParamKeys: string[],
  stringParamKeys: string[],
) => {
  const newParams = { ...existingObject };
  if (numericParamKeys) {
    numericParamKeys.forEach(key => {
      if (searchObject[key]) {
        newParams[key] = Number(searchObject[key]);
      }
    });
  }
  if (stringParamKeys) {
    stringParamKeys.forEach(key => {
      if (searchObject[key]) {
        newParams[key] = searchObject[key];
      }
    });
  }
  return newParams;
};

/**
 * @name updateParentWindowUrl
 * @function
 * @memberOf URLHelper
 * @description Updates the url based on pathname and params, and optionally sends postMessage with these arguments to the parent window
 * @param {object} args - Object containing the following arguments:
 * @param {string} args.contextId - The contextId from UI reducer.  This is required so that legacy dash can tie this message to the parent directive.
 * @param {string} args.path - The path update in the parent window.  Send null if not required.
 * @param {string} args.reload - `true` if the parent window should reload if the path updates.  Defaults to `false`.
 * @param {object} args.search - An object representing the query parameters to update in the parent window.  Send null if not required.
 */
export const updateParentWindowUrl = (args: {
  contextId: string;
  path?: string;
  reload?: boolean;
  search?: { [key: string]: any };
}) => {
  const { contextId, path, reload, search } = args;

  const finalSearch = extendWithMr(search);

  postMessageToDashboard({
    contextId,
    messageId: 'updateUrl',
    path,
    preventReload: !reload,
    search: finalSearch,
  });
};

/**
 * @name transformParams
 * @function
 * @memberOf URLHelper
 * @description Transforms url params in order to be gracefully consumed by the client
 * @param {object} initialSearch - Object containing the initial search URL params
 * @return {object} Transformed URL params object
 */
export const transformParams = (initialSearch: { [key: string]: any }) => {
  const params = omit({ ...initialSearch }, [
    'checkrCacheBust',
    'context',
    'contextId',
    'mr',
    'impersonating',
    'internalDashboard',
    'allowInternal',
    'clearUser',
  ]);

  if (params.screenings) {
    params.screenings = params.screenings.split(',');
  }

  if (params.components) {
    params.components = params.components.split(',');
  }

  if (params.q === '*') {
    delete params.q;
  }

  if (params.tags) {
    params.tags = params.tags.split(',');
  }

  if (params.packages) {
    params.packages = params.packages.split(',');
  }

  if (params.geo_ids) {
    params.geo_ids = params.geo_ids.split(',');
  }

  if (params.report_source) {
    params.report_source = params.report_source.split(',');
  }

  SEARCH_PARAMS.date.forEach((dateParam: string) => {
    if (params[dateParam]) {
      params[dateParam] = transformDate(params[dateParam]);
    }
  });

  // TODO: none selected
  if (params.missing) {
    params.missing.split(',').forEach((type: string) => {
      if (SEARCH_PARAMS.noneSelectValues.includes(type)) {
        params[type] = 'none';
      }
    });
    delete params.missing;
  }
  return params;
};

export const isMr = () =>
  (STAGING || SANDBOX) && window.location.toString().startsWith('https://mr-');

/**
 * Parse the domain in order to figure out what the current MR environment number is
 * @returns {string} the MR number, as a string
 */
export const getMrNumber = () => {
  if (!isMr()) return undefined;
  const loc = window.location.toString();

  const afterMR = loc.split('mr-')[1];
  const mr = afterMR.split('-')[0];

  return mr;
};

export const getInternalDashboardUrl = (
  path?: string,
  params?: GenericObject,
  hash?: string,
) => {
  const url = [INTERNAL_DASHBOARD_URL];

  return buildDashboardUrl({ url, path, params, hash, forceMr: true });
};

export const getDashboardUrl = (
  path?: string,
  params?: GenericObject,
  hash?: string,
) => {
  const url = [IS_ANGULAR_IFRAME ? CHECKR_DASHBOARD_URL : ''];

  return buildDashboardUrl({ url, path, params, hash });
};

export const buildDashboardUrl = ({
  url,
  path = '',
  params = {},
  hash = '',
  forceMr = false,
}: {
  url: (string | undefined)[];
  path?: string;
  params?: GenericObject;
  hash?: string;
  forceMr?: boolean;
}) => {
  // add the / before path if its missing
  if (path.length && !path.startsWith('/')) url.push('/');
  url.push(path);
  const finalParams = extendWithMr(params, forceMr);
  if (!IS_ANGULAR_IFRAME && ALLOW_INTERNAL) finalParams.allowInternal = true;
  if (Object.keys(finalParams).length > 0) {
    url.push('?');
    url.push(qs.stringify(finalParams));
  }
  if (hash.length > 0) {
    if (!hash.startsWith('#')) url.push('#');
    url.push(hash);
  }
  return url.join('');
};

export const extendWithMr = (
  obj?: GenericObject | null,
  forceMr: boolean = false, // for redirecting
) => {
  const finalObj = obj ? { ...obj } : ({} as GenericObject);
  if (isMr() && (IS_ANGULAR_IFRAME || forceMr)) {
    finalObj.mr = getMrNumber();
  }
  // If there is an "mr" property, but it has no value, then remove it
  else if ({}.hasOwnProperty.call(finalObj, 'mr') && !finalObj.mr) {
    delete finalObj.mr;
  }
  return finalObj;
};

export const isDashboardBeta = () =>
  window.location.host.includes('dashboard-beta');
