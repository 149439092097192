import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import {
  ScreeningRow,
  ScreeningText,
  InformationIcon,
  ScreeningTextContainer,
  Tooltip,
} from '../styles';
import IconCheckmark from '../IconCheckmark';

const InternationalProfessionalPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
}) => {
  const { t } = useTranslation('signup');
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'International Professional',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_international_professional_plus');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='International Professional+'
      description='Also verifies the authenticity of ID documents'
      price={12400}
      packageType='checkrdirect_international_professional_plus'
      selected={
        selectedPackage === 'checkrdirect_international_professional_plus'
      }
      onClick={handlePackageSelect}
    >
      <>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText fontWeight={700} className='screening-text'>
            Everything in International Essential
          </ScreeningText>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningTextContainer className='screening-text-container'>
            <ScreeningText className='screening-text'>
              Identity document verification
            </ScreeningText>
            <Tooltip
              align='left-top'
              label={t(
                `pages.packagesPage.tooltips.identityDocumentVerification`,
              )}
            >
              <InformationIcon icon='Information' size='20px' />
            </Tooltip>
          </ScreeningTextContainer>
        </ScreeningRow>
      </>
    </PackageCard>
  );
};

export default InternationalProfessionalPackageCard;
