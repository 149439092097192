import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UIContext from 'context/UI';
import { updateParentWindowUrl } from '../../../utils';

const useNavigate = () => {
  const history = useHistory();
  const { contextId } = useContext(UIContext);

  return useCallback(
    (path, state = {}) => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path, state);
    },
    [contextId, history],
  );
};

export default useNavigate;
