import { omit } from 'lodash';
import difference from 'lodash/difference';
import { FactRule } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import { getFactByNamespace, getValidFacts } from 'modules/assess/models';
import { Type as CountRule } from 'modules/assess/models/rules/count/entity';
import { Type as CustomRule } from 'modules/assess/models/rules/custom/entity';

export const CONDITION_FIELDS: Array<keyof FactRule> = [
  'fact',
  'operator',
  'value',
];

export enum errors {
  count = 'count',
  count_condition_missing = 'count_condition_missing',
  count_value_missing = 'count_value_missing',
  fields_missing = 'fields_missing',
  target_missing = 'target_missing',
  target_value_missing = 'target_value_missing',
  conditions_missing = 'conditions_missing',
  conditions_value_missing = 'conditions_value_missing',
  invalid_condition_for_target = 'invalid_condition_for_target',
}

export const validateCondition = (condition: Partial<FactRule>) => {
  return CONDITION_FIELDS.every(f => condition[f]?.toString().length);
};

export const validateConditions = (
  namespace: Namespace,
  target: FactRule,
  conditions: Array<Partial<FactRule>>,
) => {
  const valuesDefined = conditions.every(validateCondition);

  if (!valuesDefined) {
    return {
      valid: false,
      error: errors.conditions_value_missing,
    };
  }

  const facts = conditions.map(c => c.fact);
  const targets: Array<any> = Array.isArray(target.value)
    ? target.value
    : [target.value];
  const validFacts = getValidFacts(targets, namespace);

  if (arrayDiff(facts, validFacts)) {
    return {
      valid: false,
      error: errors.invalid_condition_for_target,
    };
  }

  return { valid: true };
};

export const validateCount = (
  conditions: Array<Partial<FactRule>>,
  namespace: Namespace,
) => {
  const Fact = getFactByNamespace(namespace);
  const count = conditions.find(c => c.fact === Fact.COUNT);

  if (count && !validateCondition(count)) {
    return {
      valid: false,
      error: errors.count_value_missing,
    };
  }

  return {
    valid: true,
  };
};

export const validateRule = (
  rule: CountRule | CustomRule,
  namespace: Namespace,
) => {
  const Fact = getFactByNamespace(namespace);
  const defined = Object.values(
    omit(rule, 'complex', 'filters', 'initial', 'item_visibility'),
  ).every(k => k);
  if (!defined) {
    return {
      valid: false,
      error: errors.fields_missing,
    };
  }

  const targets = rule.conditions.find(c => c.fact === Fact.TYPE);
  if (!targets) {
    return {
      valid: false,
      error: errors.target_missing,
    };
  }

  if (!validateCondition(targets)) {
    return {
      valid: false,
      error: errors.target_value_missing,
    };
  }

  const count = validateCount(rule.conditions, namespace);
  if (!count.valid) {
    return count;
  }

  const conditions = rule.conditions.filter(
    c => c.fact !== Fact.COUNT && c.fact !== Fact.TYPE,
  );

  return validateConditions(namespace, targets, conditions);
};

function arrayDiff(arr1: Array<any>, arr2: Array<any>): Boolean {
  return difference(arr1, arr2).length !== 0;
}
