import React from 'react';
import { Provider } from '../context';
import Modal from './modal';

type Props = {
  onClose: () => void;
  open: boolean;
};

const New: React.FC<Props> = ({ onClose, open }) => {
  return (
    <Provider onClose={onClose} open={open}>
      <Modal />
    </Provider>
  );
};

export default New;
