import { BillingAddress } from '../types/Billing/billingAddress';

const parseAddressToString = (address: BillingAddress | null): string => {
  if (!address) {
    return '';
  }

  const formattedAddress = [
    address.address_line_1,
    address.address_line_2,
    `${address.city},`,
    address.region,
    address.postal_code,
    address.country,
  ]
    .filter(Boolean)
    .join(' ');

  return formattedAddress;
};

export default parseAddressToString;
