import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from '../../common';
import Sections from '../../sections';
import Example from './Example';
import Quote from './Quote';
import Subsections from './subsections';

const Examples = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  > *:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const Container: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.benefits}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { quote, subsections, title } = translations;

  return (
    <Section data-testid='fair-chance-sections-benefits'>
      <h3>{title}</h3>
      <Quote quote={quote} />
      <Examples>
        <Example
          content={subsections[Subsections.good_eggs]}
          subsection={Subsections.good_eggs}
        />
        <Example
          content={subsections[Subsections.angi]}
          subsection={Subsections.angi}
        />
        <Example
          content={subsections[Subsections.rock_point]}
          subsection={Subsections.rock_point}
        />
      </Examples>
    </Section>
  );
};

export default Container;
