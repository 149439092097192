import React, { useMemo } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { TIMELINE_DATE_FORMAT } from 'Constants';
import { HistoryEvent } from './types';
import { KeyValueSection } from '../Sections';

type Props = {
  events: HistoryEvent[];
  itemKey?: string;
};

type EventProps = { event: HistoryEvent; itemKey?: string };

const formatTime = (time: string) => moment(time).format(TIMELINE_DATE_FORMAT);

const formatType = (type: string) => type.split('_').map(capitalize).join(' ');

const EventCol = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  .title {
    color: ${colors.uiTextPrimaryLight};
    font-weight: bold;
  }
  .text {
    color: ${colors.uiTextPrimaryLight};
    font-weight: normal;
    white-space: pre-line;
  }
`;

const Event: React.FC<EventProps> = ({ event, itemKey = 'type' }) => (
  <EventCol>
    <div className='title'>{formatType(event[itemKey])}</div>
    {event.text && <div className='text'>{event.text}</div>}
  </EventCol>
);

const EventHistory: React.FC<Props> = ({ events, itemKey }) => {
  const items = useMemo(
    () =>
      events
        .map((event: HistoryEvent) => ({
          itemKey: formatTime(event.created_at),
          itemValue: <Event event={event} itemKey={itemKey} />,
        }))
        .reverse(),
    [events, itemKey],
  );
  return (
    <M.SeeMore>
      <KeyValueSection title='Timeline' items={items} />
    </M.SeeMore>
  );
};

export default EventHistory;
