/* eslint-disable default-param-last */
import _ from 'lodash';
import {
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  POST_BANK_ACCOUNT_REQUEST,
  POST_BANK_ACCOUNT_SUCCESS,
  POST_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_CANCELED,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  VERIFY_BANK_ACCOUNT_REQUEST,
  VERIFY_BANK_ACCOUNT_SUCCESS,
  VERIFY_BANK_ACCOUNT_FAILURE,
  CREATE_BA_STRIPE_TOKEN_FAILURE,
  POST_CREDIT_CARD_REQUEST,
  POST_CREDIT_CARD_SUCCESS,
  POST_CREDIT_CARD_FAILURE,
  UPDATE_CREDIT_CARD_REQUEST,
  UPDATE_CREDIT_CARD_CANCELED,
  DELETE_CREDIT_CARD_REQUEST,
  DELETE_CREDIT_CARD_SUCCESS,
  DELETE_CREDIT_CARD_FAILURE,
  CREATE_CC_STRIPE_TOKEN_FAILURE,
  UPDATE_DEFAULT_PAYMENT_REQUEST,
  UPDATE_DEFAULT_PAYMENT_SUCCESS,
  UPDATE_DEFAULT_PAYMENT_FAILURE,
} from 'actions/ActionTypes';

export const loadBillingInfo = (
  state = {
    billingInfo: {},
  },
  { type, paymentMethods, defaultPaymentMethod },
) => {
  switch (type) {
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentBA: _.find(
          paymentMethods,
          pm => pm.payment_type === 'bank_account',
        ),
        currentCC: _.find(paymentMethods, pm => pm.payment_type === 'card'),
        defaultPaymentMethodType: defaultPaymentMethod.default_source_type,
      };
    case FETCH_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const setDefaultPayment = (
  state = {
    processingDefaultPaymentMethod: false,
  },
  { type, updateDefaultPaymentError },
) => {
  switch (type) {
    case UPDATE_DEFAULT_PAYMENT_REQUEST:
      return {
        ...state,
        updateDefaultPaymentError: null,
        processingDefaultPaymentMethod: true,
      };
    case UPDATE_DEFAULT_PAYMENT_SUCCESS:
      return {
        ...state,
        processingDefaultPaymentMethod: false,
      };
    case UPDATE_DEFAULT_PAYMENT_FAILURE:
      return {
        ...state,
        processingDefaultPaymentMethod: false,
        updateDefaultPaymentError,
      };
    default:
      return state;
  }
};

export const baInfo = (
  state = {
    processingBA: false,
    processingDeleteBA: false,
  },
  { type, newBA, stripeError, verificationError, postBAError, deleteBAError },
) => {
  switch (type) {
    case UPDATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        editBAMode: true,
      };
    case UPDATE_BANK_ACCOUNT_CANCELED:
      return {
        ...state,
        editBAMode: false,
      };
    case POST_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        processingBA: true,
        editBAMode: true,
        stripeError: null,
        postBAError: null,
      };
    case CREATE_BA_STRIPE_TOKEN_FAILURE:
      return {
        ...state,
        processingBA: false,
        stripeError,
      };
    case POST_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentBA: newBA,
        editBAMode: false,
        processingBA: false,
      };
    case POST_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        processingBA: false,
        postBAError,
      };
    case DELETE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        processingDeleteBA: true,
        deleteBAError: null,
      };
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentBA: null,
        editBAMode: true,
        processingDeleteBA: false,
      };
    case DELETE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        processingDeleteBA: false,
        deleteBAError,
      };
    case VERIFY_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        verified: true,
        processingVerifyBA: true,
        verificationError: null,
      };
    case VERIFY_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        verified: true,
        processingVerifyBA: false,
      };
    case VERIFY_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        verified: false,
        processingVerifyBA: false,
        verificationError,
      };
    default:
      return state;
  }
};

export const ccInfo = (
  state = {
    processingCC: false,
    processingDeleteCC: false,
  },
  { type, newCC, stripeError, postCCError, deleteCCError },
) => {
  switch (type) {
    case UPDATE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        editCCMode: true,
      };
    case UPDATE_CREDIT_CARD_CANCELED:
      return {
        ...state,
        editCCMode: false,
      };
    case POST_CREDIT_CARD_REQUEST:
      return {
        ...state,
        processingCC: true,
        editCCMode: true,
        stripeError: null,
        postCCError: null,
      };
    case CREATE_CC_STRIPE_TOKEN_FAILURE:
      return {
        ...state,
        processingCC: false,
        stripeError,
      };
    case POST_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        currentCC: newCC,
        editCCMode: false,
        processingCC: false,
      };
    case POST_CREDIT_CARD_FAILURE:
      return {
        ...state,
        processingCC: false,
        postCCError,
      };
    case DELETE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        processingDeleteCC: true,
        deleteCCError: null,
      };
    case DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        currentCC: null,
        editCCMode: true,
        processingDeleteCC: false,
      };
    case DELETE_CREDIT_CARD_FAILURE:
      return {
        ...state,
        processingDeleteCC: false,
        deleteCCError,
      };
    default:
      return state;
  }
};

export const ccInfoV2 = (
  state = {
    processingCC: false,
    processingDeleteCC: false,
  },
  { type, newCC, stripeError, postCCError, deleteCCError },
) => {
  switch (type) {
    case UPDATE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        editCCMode: true,
      };
    case UPDATE_CREDIT_CARD_CANCELED:
      return {
        ...state,
        editCCMode: false,
      };
    case POST_CREDIT_CARD_REQUEST:
      return {
        ...state,
        processingCC: true,
        editCCMode: true,
        stripeError: null,
        postCCError: null,
      };
    case CREATE_CC_STRIPE_TOKEN_FAILURE:
      return {
        ...state,
        processingCC: false,
        stripeError,
      };
    case POST_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        currentCC: newCC,
        editCCMode: false,
        processingCC: false,
      };
    case POST_CREDIT_CARD_FAILURE:
      return {
        ...state,
        processingCC: false,
        postCCError,
      };
    case DELETE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        processingDeleteCC: true,
        deleteCCError: null,
      };
    case DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        currentCC: null,
        editCCMode: false,
        processingDeleteCC: false,
      };
    case DELETE_CREDIT_CARD_FAILURE:
      return {
        ...state,
        processingDeleteCC: false,
        deleteCCError,
      };
    default:
      return {
        ...state,
        editCCMode: false,
      };
  }
};
