import React from 'react';
import moment from 'moment';
import { M } from '@dashboard-experience/mastodon';
import { DownloadLink } from './InvoicesStyledComponents';

type Props = {
  header: any;
  row: any;
  cell: any;
  handleDownload: Function;
};

const CreditMemoCell: React.FC<Props> = ({
  header,
  row,
  cell,
  handleDownload,
}) => {
  let cellValue;

  const centsToDollars = (cents: number) => {
    return cents / 100.0;
  };

  switch (header) {
    case 'memo':
      cellValue = (
        <M.TableCell key={cell.id}>
          <DownloadLink
            href='#'
            download
            key={`${row.id}-memodoc`}
            onClick={handleDownload}
            data-id={row.resource_id}
          >
            {cell.value}
          </DownloadLink>
        </M.TableCell>
      );
      break;
    case 'effective_at':
      cellValue = (
        <M.TableCell key={cell.id}>
          {moment(cell.value).format('YYYY-MM-DD')}
        </M.TableCell>
      );
      break;
    case 'total_credit':
    case 'available_credit':
      cellValue = (
        <M.TableCell key={cell.id}>
          {`${centsToDollars(cell.value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`}
        </M.TableCell>
      );
      break;
    case 'billing_entity':
      cellValue = (
        <M.TableCell key={cell.id}>
          {cell.value?.name || 'Main Account'}
        </M.TableCell>
      );
      break;
    default:
      cellValue = <M.TableCell key={cell.id}>{cell.value}</M.TableCell>;
  }
  return cellValue;
};

export default CreditMemoCell;
