import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useCities } from 'api/accounts';
import { useCreateGeo } from 'api/geos';
import { useTranslation } from 'react-i18next';
import { CityProps, StateProps } from './types';

type SubmitProps = {
  name: string;
  state: string;
  city?: string;
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  padding-left: 16px;
  padding-right: 16px;

  .geo-name-container {
    width: 34%;
    padding-right: 0.5rem;
  }

  .mastodon-state-select {
    width: 20%;
    padding-right: 0.5rem;
  }

  .mastodon-city-select {
    width: 34%;
    padding-right: 0.5rem;
  }

  .submit-container {
    width: 12%;

    display: flex;
    justify-content: flex-end;

    #submit-geo {
      margin-bottom: 0.5rem !important;
    }
  }
`;

type Props = {
  setNewGeoMade: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateGeo: React.FC<Props> = ({ setNewGeoMade }) => {
  const { data: cityList } = useCities();

  const { t } = useTranslation();

  const [geoName, setGeoName] = useState('');
  const updateGeoName = useCallback((e: any) => {
    setGeoName(e.target.value);
  }, []);

  const [geoState, setGeoState] = useState<StateProps | null>();
  const updateStateSelection = useCallback(selectedState => {
    setGeoState(selectedState);
    setGeoCity(null); // Clear out city selection whenever the State changes (since the City listing is tied to the selected State)
  }, []);

  const [geoCity, setGeoCity] = useState<CityProps | null>();
  const updateCity = useCallback(selectedCity => {
    setGeoCity(selectedCity);
  }, []);

  const { createGeoCall, createGeoResult } = useCreateGeo();
  const submitGeo = useCallback(() => {
    if (geoName && geoState) {
      const submitEntry: SubmitProps = {
        name: geoName,
        state: geoState.abbreviation,
        city: geoCity?.name, // City is optional, so this may be undefined
      };

      createGeoCall(submitEntry);
    }
  }, [createGeoCall, geoCity, geoName, geoState]);

  useEffect(() => {
    // If the new Geo was successfully made, clear out the form
    if (createGeoResult.isSuccess) {
      setGeoName('');
      setGeoState(null);
      setGeoCity(null);

      setNewGeoMade(true); // Used to communicate with GeosTable, so it can refresh for new data

      createGeoResult.reset();
    }
  }, [createGeoResult, setNewGeoMade]);

  // Filter down the entire dataset of cities to only ones within the specified state
  let relevantCities = [];
  if (geoState && cityList?.length) {
    relevantCities = cityList.filter(
      (city: CityProps) => city.state === geoState.abbreviation,
    );
  }

  const getCityLabel = useCallback((city: CityProps) => city?.name, []);

  return (
    <>
      <h4>{t('screening_settings.geos.create.title')}</h4>
      <InputContainer>
        <M.TextInput
          id='geo-name'
          className='geo-name-container'
          labelText={t('screening_settings.geos.create.name')}
          placeholder={t('screening_settings.geos.create.name_placeholder')}
          onChange={updateGeoName}
          value={geoName}
        />

        <M.StateSelect
          id='state-select'
          onChange={updateStateSelection}
          selectedState={geoState}
        />

        <M.CitySelect
          id='city-select'
          cities={relevantCities}
          itemToString={getCityLabel}
          optional
          disabled={!geoState}
          onChange={updateCity}
          selectedCity={geoCity}
        />

        <div className='submit-container'>
          <M.Button
            id='submit-geo'
            kind='secondary'
            size='sm'
            disabled={!geoName || !geoState || createGeoResult.isLoading}
            onClick={submitGeo}
          >
            <M.Icon icon='Add' size={24} />
            {t('verbs.add')}
          </M.Button>
        </div>
      </InputContainer>
    </>
  );
};

export default CreateGeo;
