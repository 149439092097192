import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Type } from 'modules/assess/models/rules/lookback';
import { useState as useStateContext } from 'modules/assess/ui/ruleset/version/store';
import { Item } from 'modules/assess/utilities/hierarchy';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import Charges from './charges';
import Row from './Row';
import './styles.scss';

type Props = {
  item: Item;
  showUpgrade?: boolean;
};

const Container = styled.div`
  width: 100%;
`;

const StyledExpandable = styled(M.Expandable)`
  margin-top: 0.3rem;
`;

const StyledHeading = styled(M.Screening.Heading)`
  .heading-left {
    span {
      width: 100%;
    }
    width: 30% !important;
  }

  .heading-right {
    width: 70% !important;
    min-width: auto !important;
  }
`;

const Title = styled.span`
  display: block;
  width: 100%;
  font-size: 16px;
`;

const Subcategory: React.FC<Props> = ({ item, showUpgrade }) => {
  const track = useTrackEvent();
  const [isExpanded, setIsExpanded] = useState(false);
  const state = useStateContext();
  const lookbackRulesList: Array<Type> = Object.values(
    state.rules.lookback.table,
  );
  const rule = lookbackRulesList.find(r => r.charge_subcategory === item.name);

  const onClickExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      track(LookbackPeriod.SubcategoryExpanded, {
        subcategory: item.name,
      });
    }
  }, [isExpanded, item, track]);

  return (
    <Container data-testid='assess-v2-ui-common-lookback-rules-subcategory-container'>
      <StyledExpandable
        customExpand
        initialExpanded={isExpanded}
        key={item.id}
        className='lookback'
        title={
          <StyledHeading
            headingLeft={{
              name: (
                <Title
                  onClick={onClickExpand}
                  data-testid='assess-v2-ui-common-lookback-rules-subcategory-title'
                >
                  {item.name}
                </Title>
              ),
              size: 's',
              strong: true,
            }}
            headingRight={<Row item={item} rule={rule} />}
            data-testid='assess-v2-ui-common-lookback-rules-subcategory-container-title'
          />
        }
      >
        <Charges
          charges={item.children}
          subcategory={item.name}
          showUpgrade={showUpgrade}
        />
      </StyledExpandable>
    </Container>
  );
};

export default Subcategory;
