import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  Candidate,
  CurrentUser,
  GenericObject,
  Report,
} from '@dashboard-experience/utils';
import Cell from './Cell';

/**
 * @name isPastReportField
 * @function
 * @memberOf SearchTable
 * @description Checks to see if this is a field we want to display in the past report text
 * @returns {boolean}
 * @param {string} field - The field name
 */
const isPastReportField = (field: any) =>
  ['created_at', 'status', 'pdf_url'].includes(field);

const ReportRow = styled.li`
  display: flex;
`;

type PastReportsType = {
  candidate: Candidate;
  currentUser: CurrentUser;
  columns: any;
  pastReports: Report[];
  geos: GenericObject[];
};

const PastReports = ({
  candidate,
  currentUser,
  columns,
  pastReports,
  geos,
}: PastReportsType) => {
  return (
    <M.TableCell colSpan={columns.length}>
      <strong>Past reports:</strong>
      <br />
      <br />
      <ol>
        {pastReports.map(report => (
          <ReportRow key={report.id}>
            {columns.map(
              (column: any) =>
                isPastReportField(column.key) &&
                column.hasPermission({ currentUser, geos }) && (
                  <React.Fragment key={`${column.key}-${Math.random()}`}>
                    {column.key !== 'created_at' && <>&nbsp;-&nbsp;</>}
                    <Cell
                      cellKey={column.key}
                      candidate={candidate}
                      report={report}
                      currentUser={currentUser}
                    />
                  </React.Fragment>
                ),
            )}
          </ReportRow>
        ))}
      </ol>
    </M.TableCell>
  );
};

PastReports.propTypes = {
  candidate: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  pastReports: PropTypes.array.isRequired,
  geos: PropTypes.array.isRequired,
};

export default PastReports;
