import React from 'react';
import { BackButton } from 'modules/assess/ui/common';
import { Actions } from 'modules/assess/ui/page';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { ID } from 'modules/entity';
import SaveButton from './SaveButton';

type Props = {
  id: ID;
};

export const ActionsContainer: React.FC<Props> = ({ id }) => {
  const { disabled, dirty } = useState();

  if (disabled) {
    return (
      <Actions>
        <BackButton navigateUp />
      </Actions>
    );
  }

  return (
    <Actions>
      <BackButton navigateUp />
      <SaveButton key='saveContextDatesButton' disabled={!dirty} id={id} />
    </Actions>
  );
};

export default ActionsContainer;
