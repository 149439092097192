import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Fact } from 'modules/assess';
import HelpText from './help-text';

type Props = {
  facts: Array<Fact>;
  fact?: Fact;
  setFact: (fact: Fact) => void;
};

export const FactSelector: React.FC<Props> = ({ fact, facts, setFact }) => {
  const { t } = useTranslation('');

  const text = (f: Fact) => {
    const name = t(`assess:rules.facts.${f}.name`);
    const verb = t(`assess:rules.facts.${f}.verb`);
    return `${name} ${verb}`;
  };

  const handleChange = useCallback(
    ({ selectedItem: { id } }) => {
      setFact(id);
    },
    [setFact],
  );

  if (fact && !facts.includes(fact)) {
    facts = [fact, ...facts];
  }

  const items = facts
    .map(f => ({
      id: f,
      label: text(f),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const selected = items.find(item => item.id === fact);

  return (
    <div>
      <M.Dropdown
        id='assess-rules-rule-editor-rule-condition-fact-selector'
        data-testid='assess-rules-rule-editor-rule-condition-fact-selector'
        label={t('selects.condition_placeholder')}
        items={items}
        selectedItem={selected}
        onChange={handleChange}
      />
      <HelpText fact={fact} />
    </div>
  );
};

export default FactSelector;
