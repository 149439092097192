import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import WorksiteDropDown from './WorksiteDropDown';
import { DatePicker, FlexRow, RemoveIcon } from './style';

type CandidateCellProps = {
  cell: any;
  id: string;
  error?: { field: string; message: string };
  onRemoveClick(id: string): void;
  onWorksiteChanged(id: string, worksiteId: string): void;
  onStartDateChanged(id: string, startDate: string): void;
};

const CandidateCell: React.FC<CandidateCellProps> = ({
  cell,
  id,
  error,
  onRemoveClick,
  onWorksiteChanged,
  onStartDateChanged,
}) => {
  const [currentError, setCurrentError] = useState(error);

  const onRemoveHandler = useCallback(() => {
    onRemoveClick(id);
  }, [id, onRemoveClick]);

  const onWorksiteChangedHandler = useCallback(
    (worksiteId: string) => {
      onWorksiteChanged(id, worksiteId);
    },
    [id, onWorksiteChanged],
  );

  const onStartDateChangedHandler = useCallback(
    dates => {
      const startDate = dates.length
        ? moment(dates[0]).format('YYYY-MM-DD')
        : '';
      onStartDateChanged(id, startDate);
      setCurrentError(undefined);
    },
    [id, onStartDateChanged],
  );

  const onStartDateInputHandler = useCallback(() => {
    setCurrentError(undefined);
  }, []);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  if (cell.info.header === 'start_date') {
    const isDateError =
      typeof currentError !== 'undefined' && currentError.field === 'startDate';
    return (
      <DatePicker
        id={`date-picker-${cell.id}`}
        dateFormat='m/d/Y'
        datePickerType='single'
        onChange={onStartDateChangedHandler}
        onInput={onStartDateInputHandler}
      >
        <M.DatePickerInput
          id='date-picker-input-id-start'
          placeholder='MM/DD/YYYY'
          invalid={isDateError}
          invalidText={isDateError ? currentError?.message : ''}
          pattern='\d{2}\/\d{2}\/\d{2}'
        />
      </DatePicker>
    );
  }
  if (cell.info.header === 'worksite')
    return (
      <WorksiteDropDown
        id={`worksite-${cell.id}`}
        name={`worksite-${cell.id}`}
        onChange={onWorksiteChangedHandler}
      />
    );
  return (
    <FlexRow gap='0.5rem' alignItems='center'>
      <RemoveIcon icon='Close' size='1rem' onClick={onRemoveHandler} />
      <span>{cell.value}</span>
    </FlexRow>
  );
};

export default CandidateCell;
