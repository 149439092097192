import { GenericObject } from '@dashboard-experience/utils';
import { colors } from '@dashboard-experience/mastodon';
import { kebabCase } from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import UIContext from '../context/UI';

const Wrapper = styled.div<{ isiframe: string }>`
  margin-bottom: 2rem;

  #mastodon & {
    h1 {
      color: ${colors.brandNavy4};
      font-size: 1.75rem;
      margin-bottom: 0;

      & + p {
        margin-top: 1rem;
      }

      @media (min-width: ${props => props.isiframe}) {
        font-size: 2rem;
      }
    }
  }

  p {
    color: ${colors.uiGrey700};

    a {
      color: ${colors.brandAqua4} !important;
    }

    strong {
      font-weight: 500;
    }
  }

  @media (min-width: ${props => props.isiframe}) {
    display: inline-block;
    margin-bottom: 3rem;

    p {
      max-width: 800px;
    }
  }
`;

type HeaderElement = {
  label: string;
  props?: GenericObject;
};

type Props = {
  title?: HeaderElement;
  description?: HeaderElement;
  descriptionNode?: any;
  style?: object;
};

export const PageHeader: React.FC<Props> = ({
  title,
  description,
  descriptionNode,
  style,
}) => {
  const { isIframe } = useContext(UIContext);

  return (
    <Wrapper style={style} isiframe={isIframe ? '720px' : '992px'}>
      <>
        {title && (
          <h1
            data-testid={`page-header-title-${kebabCase(title.label)}`}
            {...title?.props}
          >
            {title.label}
          </h1>
        )}
        {description && <p {...description?.props}>{description.label}</p>}
        {descriptionNode && descriptionNode}
      </>
    </Wrapper>
  );
};

export default PageHeader;
