import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as Entity from 'modules/assess/models/rulesets/ruleset';
import T from 'components/Table';
import * as Data from './data';
import RulesetDetails from './RulesetDetails';

type Props = {
  rulesets: Array<Entity.Type>;
};

const Container = styled.div`
  margin-top: 2.5em;
`;

export const Table: React.FC<Props> = ({ rulesets }) => {
  const headers = Data.useHeaders();
  const rows = Data.useRows(rulesets);

  const expansion = useCallback(
    (id: string) => {
      const ruleset = rulesets.find(r => r.id === id) as Entity.Type;
      return <RulesetDetails ruleset={ruleset} />;
    },
    [rulesets],
  );

  return (
    <Container data-testid='assess-rulesets-table'>
      <T expandable expansion={expansion} headers={headers} rows={rows} />
    </Container>
  );
};

export default Table;
