import React, { useRef, useEffect } from 'react';

type Props = {
  src?: string;
  id?: string;
  [key: string]: any;
};
const CustomIframe: React.FC<Props> = ({ src, id, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    const frame = ref?.current as any;
    if (frame) {
      // set the height of the iframe as
      // the height of the iframe content
      // frame.style.height = `${frame.contentWindow.document.body.offsetHeight}px`;
      // set the width of the iframe as the
      // width of the iframe content
      // frame.style.width = `${frame.contentWindow.document.body.scrollWidth}px`;
    }
  }, [ref?.current]);

  return <iframe id={id} src={src} ref={ref} {...props} />;
};

export default CustomIframe;
