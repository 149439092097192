import React from 'react';
import { Link } from 'react-router-dom';
import { M, colors, fonts } from '@dashboard-experience/mastodon';
import { RouteParams } from 'modules/assess/ui/route/Context';
import styled from 'styled-components';

type Props = {
  route: RouteParams;
};

const StyledBreadcrumb = styled(M.BreadcrumbItem)`
  :last-child {
    :after {
      display: none;
    }
  }

  a {
    font-family: ${fonts.nationalBold};
    &:hover {
      color: ${colors.uiTextPrimaryLight} !important;
    }

    &:focus {
      outline: none;
    }
  }
`;

const Breadcrumb: React.FC<Props> = ({ children, route }) => {
  return (
    <StyledBreadcrumb
      key={route.location}
      data-testid='assess-ui-page-v2-common-breadcrumb-assess'
    >
      <Link to={route.location}>{children}</Link>
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
