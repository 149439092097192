import { SET_GLOBAL_STATE } from '../actions/ActionTypes';

export const setNewState = state => newState => ({ ...state, ...newState });

// eslint-disable-next-line default-param-last
const globalStateReducer = (state = {}, { type, key, payload }) => {
  const setState = setNewState(state);

  switch (type) {
    case SET_GLOBAL_STATE:
      return setState({ [key]: payload });
    default:
      return state;
  }
};

export default globalStateReducer;
