import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'modules/case-management/types';
import UserSelect from '../UserSelect';
import { ActionButton } from './styles';

const BatchUserSelect = ({ callback = (user: UserType | null) => {} }) => {
  const { t } = useTranslation('case-management');

  const [openSelect, setOpenSelect] = useState(false);

  const closeSelect = useCallback(() => {
    setOpenSelect(false);
  }, [setOpenSelect]);

  const toggleList = useCallback(() => {
    setOpenSelect(!openSelect);
  }, [openSelect]);

  const onSelect = useCallback(
    (user: UserType | null) => {
      callback(user);
      closeSelect();
    },
    [closeSelect, callback],
  );
  return (
    <>
      <ActionButton kind='primary' onClick={toggleList}>
        {t('tasks.table.assign-reports')}
      </ActionButton>
      {openSelect && (
        <UserSelect closeMenu={closeSelect} onSelect={onSelect} top />
      )}
    </>
  );
};

export default BatchUserSelect;
