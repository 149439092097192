import _ from 'lodash';
import { Category } from 'modules/assess/models/mvr/category';
import { CategoryCode } from 'modules/assess/models/mvr/category-code';
import { ItemType } from 'modules/assess/models/mvr/codes';
import T from 'components/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAll } from 'modules/assess/ui/mvr/category-sets/instance/version/mappings/mappings/api';
import { ID } from 'modules/id';
import TableHeader from '../table-header';
import CategoryMappingTableCell from './category-mapping-table-cell';

type Props = {
  categories?: Array<Category>;
  instance: ID;
  type: ItemType;
  version: ID;
};

type Column = keyof CategoryCode;

const columns: Array<Column> = ['code', 'description', 'category'];

export const Table: React.FC<Props> = ({
  categories,
  instance,
  type,
  version,
}) => {
  const headers = columns.map(key => ({
    key,
    header: <TableHeader name={key} section='mappings' type={type} />,
  }));

  const { data } = useGetAll(instance, version);
  const codes = data?.data?.filter(c => c.type === type);

  const rows = _.sortBy(codes, 'code').map(item => ({
    id: item.code,
    ...item,
    category: (
      <CategoryMappingTableCell categories={categories} categoryCode={item} />
    ),
  }));

  const search = useSearch(type);

  return (
    <T
      data-testid='assess-mvr-category-set-version-mappings-mappings-table'
      columns={columns}
      headers={headers}
      rows={rows}
      search={search}
      underlineSearch
    />
  );
};

const useSearch = (type: ItemType) => {
  const { t } = useTranslation('assess');
  const placeholder = t(
    [
      `assess:mvr.category-sets.instance.versions.instance.${type}.mappings.table.search`,
      `assess:mvr.category-sets.instance.versions.instance.common.mappings.table.search`,
    ],
    { type },
  );

  return {
    placeholder,
  };
};

export default Table;
