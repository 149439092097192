import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDebouncedCallback } from '@dashboard-experience/utils';
import { PackagesSearchHeader } from './PackagesStyledComponents';
import { useUser } from '../../../context/CurrentUser';
import { useList } from '../../../api/programs';

type Props = {
  packagesCount: number;
  updatePrograms: Function;
  updateName: Function;
};

const PackagesSearch: React.FC<Props> = ({
  packagesCount,
  updatePrograms,
  updateName,
}) => {
  const {
    account: { id: accountId },
  } = useUser();

  const { data: programsList, isLoading: programsLoading } = useList({
    accountId,
  });

  const handleUpdatePrograms = useCallback(e => {
    const programIds = e.selectedItems.map((program: any) => program.id);
    updatePrograms(programIds);
  }, []);

  const handleUpdateName = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => debounced(e.currentTarget.value),
    [],
  );

  const debounced = useDebouncedCallback(value => updateName(value), 350, []);

  return (
    <M.Container>
      <M.Container.Row>
        <M.Container.Col>
          <PackagesSearchHeader>Search packages</PackagesSearchHeader>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          <M.Input
            id='name'
            name='name'
            labelText='Name'
            placeholder='Keyword'
            onChange={handleUpdateName}
          />
        </M.Container.Col>
        <M.Container.Col>
          {!programsLoading && programsList && (
            <M.MultiSelect
              items={programsList.map(
                (program: { id: string; name: string }) => ({
                  id: program.id,
                  label: program.name,
                }),
              )}
              titleText='Programs'
              label='Select programs'
              ariaLabel='Select programs'
              onChange={handleUpdatePrograms}
            />
          )}
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>{packagesCount} results</M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

export default PackagesSearch;
