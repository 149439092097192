const allHeaders = [
  {
    key: 'month',
    header: 'month',
    name: 'month',
    classes: 'left',
    tooltip: false,
  },
  {
    key: 'billingEntity',
    header: 'billingEntity',
    name: 'billingEntity',
    classes: 'left',
    tooltip: false,
  },
  {
    key: 'customId',
    header: 'customId',
    name: 'customId',
    classes: 'left',
    tooltip: false,
  },
  {
    key: 'amount',
    header: 'amount',
    name: 'amount',
    classes: 'left',
    tooltip: false,
  },
  {
    key: 'reports',
    header: 'reports',
    name: 'reports',
    classes: 'left',
    tooltip: true,
  },
  {
    key: 'invoice',
    header: 'invoice',
    name: 'invoice',
    classes: 'left',
    tooltip: true,
  },
  {
    key: 'status',
    header: 'status',
    name: 'status',
    classes: 'left',
    tooltip: true,
  },
];

export const defaultHeaders = allHeaders.filter(
  header => header.key !== 'billingEntity' && header.key !== 'customId',
);

export const billingEntityHeaders = allHeaders.filter(
  header => header.key !== 'customId',
);

export const accountHierarchyHeaders = allHeaders.filter(
  header => header.key !== 'billingEntity',
);
