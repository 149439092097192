import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  CurrentUser as User,
  formatDateIfDate,
} from '@dashboard-experience/utils';
import { GenericObject, Report } from 'types';
import { getAccidentItems } from './Helpers';
import AdditionalInfoPanel from './AdditionalInfoPanel';
import {
  MvrItem,
  StyledKeyValueList,
} from './MultiMotorVehicleReportComponents';

type AccidentsProps = {
  licenseReportOrCharge: GenericObject;
  assessEnabled: boolean;
  currentUser: User;
  report: Report;
};

const Accidents: React.FC<AccidentsProps> = ({
  licenseReportOrCharge,
  assessEnabled,
  currentUser,
  report,
}) => {
  return (
    <M.SubScreening title='Accidents' data-testid='mvr-accidents'>
      {licenseReportOrCharge.accidents.map(
        (accident: GenericObject, idx: number) => {
          const accidentItems = getAccidentItems(accident);

          return (
            <M.Expandable
              role='accidentsItem'
              key={`${accident.description}-${idx + 1}`}
              title={
                <>
                  <MvrItem item={accident} />
                  <span style={{ float: 'right' }}>
                    {formatDateIfDate(accident.accident_date)}
                  </span>
                </>
              }
            >
              <StyledKeyValueList items={accidentItems} />
              {accident.group && (
                <AdditionalInfoPanel
                  licenseReportOrCharge={accident}
                  // @ts-ignore TODO: Remove comment once Report type from utils is consumed
                  report={report}
                  assessEnabled={assessEnabled}
                  currentUser={currentUser}
                  panelType='accidents'
                />
              )}
            </M.Expandable>
          );
        },
      )}{' '}
    </M.SubScreening>
  );
};

export default Accidents;
