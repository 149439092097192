import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Title: React.FC<Props> = () => {
  const { t } = useTranslation();
  const text = t('assess:settings.title');

  return <h3>{text}</h3>;
};

export default Title;
