import React, { useCallback, useState, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { toastError } from 'actions';
import { Report } from 'types';
import { useDispatch } from 'react-redux';
import { useUpdateTags } from 'api/tags';
import { useUser } from 'context/CurrentUser';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';

type TagsContainerProps = {
  report: Report;
};

const noDataTag = 'No-Data';

const getFilteredTags = (report: Report) => {
  const tags = report?.tags || [];
  return tags.filter((tag: string) => tag !== noDataTag);
};

const TagsInput = styled(M.TagsInput)`
  .mastodon-tag {
    max-width: 97%;
  }

  .react-autosuggest__container {
    .react-tagsinput-input {
      font-style: italic;
      width: 80px !important;
    }
  }
`;

const TagsInputV2 = styled(M.TagsInput)`
  .mastodon-tag-v2 > span:first-of-type {
    max-width: 12rem;
  }
  /* .cds--tag__label {
    max-width: 12rem;
  } */
  .cds--tag__label {
    white-space: nowrap !important;
  }
`;

const TagsContainer: React.FC<TagsContainerProps> = ({ report }) => {
  const currentUser = useUser();
  const dispatch = useDispatch();
  const filteredTags = getFilteredTags(report);
  const [tags, setTags] = useState(filteredTags);
  const [tagsBackup, setTagsBackup] = useState(report.tags || []);
  const { updateTagsCall } = useUpdateTags(report.id, tags, tagsBackup);
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  useEffect(() => {
    setTags(getFilteredTags(report));
    setTagsBackup(report.tags || []);
  }, [report.id, report.tags]);

  const Component = candidateRedesignEnabled ? TagsInputV2 : TagsInput;

  const updateTags = useCallback(
    value => {
      let backupTags = tags;
      if (report.tags?.includes(noDataTag)) backupTags.push(noDataTag);

      if (!hasPermission(currentUser, 'manage_tags')) {
        backupTags = [...tagsBackup];

        dispatch(toastError('You do not have permission to edit tags'));
        return undefined;
      }

      updateTagsCall();
      setTags(value);
      return setTagsBackup(backupTags);
    },
    [report, currentUser, tagsBackup, updateTagsCall, dispatch, tags],
  );

  return (
    <div data-testid='report-information-tags'>
      <Component
        v2={candidateRedesignEnabled}
        tags={tags}
        onlyUnique
        onChange={updateTags}
      />
    </div>
  );
};

export default TagsContainer;
