import React from 'react';
import { useCandidate } from 'providers/Candidate';
import { getCandidateName } from './utils';
import './styles.scss';

type Props = { isScrolled?: boolean };

const Name: React.FC<Props> = ({ isScrolled = false }) => {
  const candidate = useCandidate();
  const name = getCandidateName(candidate);

  return (
    <span
      data-testid='candidate-header-name'
      className={`candidate-header-name ${
        isScrolled ? 'candidate-header-name-shrink' : ''
      }`}
    >
      {name}
    </span>
  );
};

export default Name;
