/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import * as searchActions from 'actions/SearchActions';
import { Report } from 'types';
import { Candidate } from '@dashboard-experience/utils';

/**
 * @name mightHavePdf
 * @function
 * @description Checks the status to see if the report has been completed, if so, there may be a PDF to retrieve.
 * @returns {boolean}
 * @param {object} candidate - The candidate object
 */
const mightHaveReportPdf = (candidate: Candidate) => {
  if (candidate.reports) {
    const { reports } = candidate;
    const lastReport = reports[reports.length - 1];
    if (lastReport) {
      return ['complete', 'clear', 'consider', 'suspended'].includes(
        lastReport.status as any,
      );
    }
  }
  return false;
};

/**
 * @name hasDocuments
 * @function
 * @description Checks to see if there are any documents available in a given report (not just report PDFs)
 * @returns {boolean}
 * @param {object} report - The report object
 */
const hasDocuments = (report: Report) =>
  report.documents && report.documents.length;

/**
 * @name reportPdf
 * @function
 * @description Returns the PDF report, if it exists
 * @returns {object} - The document object for the PDF report
 * @param {object} report - The report object
 */
const reportPdf = (report: Report) => {
  const encryptedPdf = _.find(
    report.documents,
    document => document.type === 'pdf_report_encrypted',
  );
  const pdf = _.find(
    report.documents,
    document => document.type === 'pdf_report',
  );
  return encryptedPdf || pdf;
};

type DownloadPdfProps = {
  candidate: Candidate;
  fetchCandidateReportDocuments: Function;
  reports: Report[];
};

/**
 * @name DownloadPdf
 * @component
 * @description The download PDF component
 * @returns {element}
 * @param {object} candidate - The candidates object
 * @param {function} fetchCandidateReportDocuments - The action to fetch the report object containing the documents, which may contain the PDF report
 * @param {object} reports - An object containing reports from the store, keyed by report ID
 */
const DownloadPdf = ({
  candidate,
  fetchCandidateReportDocuments,
  reports,
}: DownloadPdfProps) => {
  useEffect(() => {
    if (mightHaveReportPdf(candidate)) {
      if (candidate.reports)
        fetchCandidateReportDocuments(
          candidate.reports[candidate.reports.length - 1].id,
        );
    }
  }, [candidate, fetchCandidateReportDocuments]);
  const [pdf, setPdf] = useState<any>(null);
  useEffect(() => {
    // if(candidate.reports){ }
    if (
      candidate.reports &&
      candidate.reports[candidate.reports.length - 1] &&
      reports[candidate.reports[candidate.reports.length - 1].id as any] &&
      hasDocuments(
        reports[candidate.reports[candidate.reports.length - 1].id as any],
      )
    ) {
      setPdf(
        reportPdf(
          reports[candidate.reports[candidate.reports.length - 1].id as any],
        ),
      );
    }
  }, [candidate, reports, setPdf]);
  return pdf ? (
    <M.Link href={pdf.download_uri} target='_blank' rel='noopener'>
      Download
    </M.Link>
  ) : null;
};

DownloadPdf.defaultProps = {
  reports: null,
};

const mapStateToProps = (state: any) => {
  return {
    reports: state.search.reports,
  };
};

export default connect(mapStateToProps, searchActions)(DownloadPdf);
