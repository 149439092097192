import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Report } from '@dashboard-experience/utils';
import moment from 'moment';

import { useAdverseActions } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import { useModalState } from '../../../context';

const ActionableNotification = styled(M.ActionableNotification)`
  margin-top: 0;
  .mastodon-list-item {
    font-weight: bold;
  }
`;

const inlineProps = {
  title: '',
  kind: 'warning',
  hideCloseButton: true,
};

const WarningBanner: React.FC = () => {
  const { individualizedAssessment } = useModalState('state');
  const data = useGetReport();
  const report = data?.report as unknown as Report;
  const { adverseAction } = useAdverseActions(report.id);

  const { post_notice_scheduled_at, post_notice_ready_at } =
    adverseAction || {};
  const dateToCheck = post_notice_scheduled_at || post_notice_ready_at;
  const daysLeft = dateToCheck ? moment(dateToCheck).fromNow(true) : null;

  // TODO: add translations
  const listItemOne =
    'Please carefully review the list of charges and your contact information';
  const listItemTwo = `Please note that we will send this post adverse action email out in ${daysLeft} days`;
  const indvAssessmentNotice =
    'You have attached a new individualized assessment PDF for this candidate.';

  return (
    <ActionableNotification
      hideActionButton
      inline='classic'
      {...inlineProps}
      style={{ maxWidth: '100%' }}
    >
      <M.UnorderedList>
        <M.ListItem>{listItemOne}</M.ListItem>
        {daysLeft && <M.ListItem>{listItemTwo}</M.ListItem>}
        {individualizedAssessment?.uploadedFile && (
          <M.ListItem>{indvAssessmentNotice}</M.ListItem>
        )}
      </M.UnorderedList>
    </ActionableNotification>
  );
};

export default WarningBanner;
