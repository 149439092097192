import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from 'actions';
import {
  ArchiveInvitationParams,
  archiveInvitations,
  ArchiveReportParams,
  archiveReports,
  unarchiveInvitations,
  unarchiveReports,
} from './actions';

export const useArchiveReports = () => {
  const dispatch = useDispatch();
  const request = (params: ArchiveReportParams) => archiveReports(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('This report has been updated.'));
    },
    onError: error => {
      dispatch(
        toastError(
          'Something went wrong, please try again later.',
          error.message,
        ),
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useUnarchiveReports = () => {
  const dispatch = useDispatch();
  const request = (params: ArchiveReportParams) => unarchiveReports(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('This report has been updated'));
    },
    onError: error => {
      dispatch(
        toastError(
          'Something went wrong, please try again later.',
          error.message,
        ),
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useArchiveInvitations = () => {
  const dispatch = useDispatch();
  const request = (params: ArchiveInvitationParams) =>
    archiveInvitations(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('This invitation has been updated.'));
    },
    onError: error => {
      dispatch(
        toastError(
          'Something went wrong, please try again later.',
          error.message,
        ),
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useUnarchiveInvitations = () => {
  const dispatch = useDispatch();
  const request = (params: ArchiveInvitationParams) =>
    unarchiveInvitations(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('This invitation has been updated.'));
    },
    onError: error => {
      dispatch(
        toastError(
          'Something went wrong, please try again later.',
          error.message,
        ),
      );
    },
  });

  return {
    call,
    result,
  };
};
