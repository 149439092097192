import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { kebabCase, orderBy, reverse } from 'lodash';
import {
  hasPermission,
  getStatusTypeForUser,
  StatusTypes,
  Report,
  CurrentUser,
  GenericObject,
  localStorageFallback,
} from '@dashboard-experience/utils';

import { Candidate } from 'types';
import CellLink from 'components/Search/CellLink';
import { debug } from 'utils';
import { ProcessingStatus, TableHeaderWithSort } from './SearchTableContent';

import PastReports from './SearchTableContent/PastReports';
import ExpandedReportsRow from './SearchTableContent/ExpandedReportsRow';
import {
  ProcessingStatusTableCell,
  ReportCountTableCell,
  ReportCountTag,
  StyledSearchCell,
} from './SearchTableContent/SearchTableStyledComponents';
import Cell from './SearchTableContent/Cell';
import { getCellConfigType } from './SearchTableContent/Config';
import { useSearchState } from './search-context';

const EXPANDABLE_FILTERS = [
  'status',
  'adjudication',
  'reported_after',
  'reported_before',
  'reports_completed_after',
  'reports_completed_before',
  'tags',
];

type HeaderElem = {
  key: string;
  header: string;
};

/**
 * @name getPastReports
 * @function
 * @memberOf SearchTable
 * @description Return past reports
 * @returns {array} - New array with only past reports.
 * @param {array} reports - The full reports array
 */
const getPastReports = (reports: Report[]) => {
  const pastReports = [...reports];
  pastReports.pop();
  return pastReports;
};

/**
 * @name showMultipleReportsColumn
 * @function
 * @memberOf SearchTable
 * @description determine whether to show multiple reports column
 * @returns {boolean} - boolean
 * @param {array} candidates - The full candidates array
 */
const shouldShowMultipleReportsColumn = (candidates: any, isDSP: boolean) => {
  const manyReports = (candidate: Candidate) =>
    candidate.reports ? candidate.reports.length > 1 : false;

  return (
    candidates.data.some((candidate: Candidate) => manyReports(candidate)) &&
    !isDSP
  );
};

/**
 * @name getCandidateUri
 * @function
 * @memberOf SearchTable
 * @description Helper set the correct Candidate uri to navigate to candidate
 * @returns {array} - New array with only past reports
 * @param {object} candidate - The candidate object
 * @param {object} currentUser - The current user object
 */
const getCandidateUri = (candidate: Candidate, currentUser: CurrentUser) => {
  if (hasPermission(currentUser, 'read_candidate_details')) {
    const lastReport = candidate.reports?.[candidate.reports.length - 1];
    const path = lastReport
      ? `/candidates/${candidate.id}/reports/${lastReport.id}`
      : `/candidates/${candidate.id}`;
    return path;
  }
  return '';
};

const showProcessingStatus = (candidate: Candidate) => {
  const storageData = localStorageFallback.getItem('processingStatusData');
  if (storageData) {
    const processingStatusData = JSON.parse(storageData);

    if (processingStatusData[candidate.id]) {
      const processingTimestamp = processingStatusData[candidate.id].timestamp;
      const candidateIndexedAt = new Date(
        candidate.indexed_at as any,
      ).getTime();

      // If there is no indexed_at, or the indexed_at is earlier than the timestamp of the action, the update hasn't been indexed yet so its still processing. if the indexed_at happened after the action, its done processing
      if (!candidate.indexed_at || processingTimestamp > candidateIndexedAt)
        return true;
    }
  }
  return false;
};

type SearchTableProps = {
  candidates: GenericObject;
  config: any;
  currentUser: CurrentUser;
  contextId: null | undefined | string;
};

const SearchTable = ({
  candidates,
  config,
  currentUser,
  contextId,
}: SearchTableProps) => {
  const [, setUpdateProcessingStatusState] = useState({
    updateProcessingStatus: false,
  });

  useEffect(() => {
    const listener = () => {
      // When local storage changes, update the processing status state
      setUpdateProcessingStatusState(prevState => ({
        ...prevState,
        updateProcessingStatus: true,
      }));
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  const { geos, filters } = useSearchState();

  const shouldExpand = Object.keys(filters).some(key => {
    if (filters[key])
      return filters[key].length > 0 ? EXPANDABLE_FILTERS.includes(key) : false;
    return false;
  });

  const CELL_CONFIG = getCellConfigType();

  const getTableContent = useCallback(
    ({ getRowProps, headers, rows }) => {
      const columns = headers.map(({ header }: HeaderElem) => {
        return CELL_CONFIG[header as unknown as keyof typeof CELL_CONFIG];
      });
      debug({ columns });
      const isDSP = getStatusTypeForUser(currentUser) === StatusTypes.DSP;

      const showMultipleReportsColumn = shouldShowMultipleReportsColumn(
        candidates,
        isDSP,
      );

      const canReadCandidateDetails = hasPermission(
        currentUser,
        'read_candidate_details',
      );

      return (
        <M.TableContainer
          noScroll
          style={{ paddingBottom: '2rem', overflowX: 'scroll' }}
          className='newSearchTable'
        >
          <M.Table>
            <M.TableHead data-testid='search-table-head'>
              <M.TableRow style={{ fontSize: '12px' }}>
                <M.TableHeader style={{ width: '2%' }} />
                {showMultipleReportsColumn && (
                  <M.TableHeader
                    style={{
                      width: '3%',
                    }}
                  />
                )}
                {columns.map((column: any) => {
                  const { sortable, header, key } = column;
                  const hasPermission = column.hasPermission({
                    currentUser,
                    geos,
                  });
                  const tableHeaderDataTestId = `search-table-column-header-${kebabCase(
                    header,
                  )}`;

                  return (
                    hasPermission && (
                      <React.Fragment key={`column-header-wrapper-${key}`}>
                        {sortable ? (
                          <TableHeaderWithSort
                            key={`column-header-${key}`}
                            column={column}
                            isDSP={isDSP}
                            headerStyle={column.headerStyle}
                          />
                        ) : (
                          <M.TableHeader
                            key={`table-column-header-${key}`}
                            data-testid={tableHeaderDataTestId}
                            style={column.headerStyle}
                          >
                            {header}
                          </M.TableHeader>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </M.TableRow>
            </M.TableHead>
            <M.TableBody data-testid='search-table-body'>
              {rows.map((row: any, rowIdx: number) => {
                const candidate = candidates.data[rowIdx];
                // Fixes an intermediate state bug when the candidates list updates to a smaller amount
                if (!candidate) return null;
                const { reports } = candidate;
                const latestReport = reverse(
                  orderBy(reports, ['created_at'], ['asc']),
                )?.[0];
                const hasMultipleReports = reports && reports.length > 1;
                const Row = hasMultipleReports ? M.TableExpandRow : M.TableRow;
                const pastReports = getPastReports(reports);

                const candidateURI = getCandidateUri(candidate, currentUser);

                if (shouldExpand && hasMultipleReports) {
                  row.isExpanded = !row.isExpanded;
                }
                const shouldShowProcessingStatus =
                  showProcessingStatus(candidate);

                const newSearchTableCellClass = 'newSearchTableCell';

                return (
                  <React.Fragment key={candidate.id}>
                    <Row
                      {...getRowProps({ row })}
                      data-testid={`search-table-row-${rowIdx}`}
                    >
                      {!hasMultipleReports && !shouldShowProcessingStatus && (
                        <StyledSearchCell className='empty-cell' />
                      )}
                      {!hasMultipleReports &&
                        shouldShowProcessingStatus &&
                        !showMultipleReportsColumn && (
                          <ProcessingStatusTableCell
                            className={`start-cell flex-cell ${newSearchTableCellClass}`}
                          >
                            <ProcessingStatus tooltipAlignment='bottom-left' />
                          </ProcessingStatusTableCell>
                        )}
                      {!hasMultipleReports &&
                        shouldShowProcessingStatus &&
                        showMultipleReportsColumn && (
                          <>
                            <StyledSearchCell className='empty-cell' />
                            <ProcessingStatusTableCell
                              className={`flex-cell ${newSearchTableCellClass}`}
                            >
                              <ProcessingStatus tooltipAlignment='bottom' />
                            </ProcessingStatusTableCell>
                          </>
                        )}
                      {showMultipleReportsColumn &&
                        !shouldShowProcessingStatus && (
                          <ReportCountTableCell
                            className={newSearchTableCellClass}
                          >
                            {hasMultipleReports && (
                              <M.TooltipDefinition
                                align='bottom'
                                highlighted={false}
                                tabIndex={0}
                                definition={`${reports.length} reports`}
                              >
                                <ReportCountTag>{`${reports.length}`}</ReportCountTag>
                              </M.TooltipDefinition>
                            )}
                          </ReportCountTableCell>
                        )}
                      {showMultipleReportsColumn &&
                        hasMultipleReports &&
                        shouldShowProcessingStatus && (
                          <ProcessingStatusTableCell
                            className={`flex-cell ${newSearchTableCellClass}`}
                          >
                            <ProcessingStatus />
                          </ProcessingStatusTableCell>
                        )}
                      {columns.map(
                        (column: any) =>
                          column.hasPermission({ currentUser, geos }) && (
                            <StyledSearchCell
                              className={isDSP ? 'unclickable' : ''}
                              key={`${column.key}-${candidate.id}`}
                              style={column.cellStyle || {}}
                            >
                              {!isDSP ? (
                                <CellLink
                                  data-testid={`search-column-${kebabCase(
                                    column.key,
                                  )}-${rowIdx}`}
                                  className={
                                    !canReadCandidateDetails
                                      ? 'limited-role'
                                      : ''
                                  }
                                  path={candidateURI}
                                >
                                  <Cell
                                    report={latestReport}
                                    currentUser={currentUser}
                                    candidate={candidate}
                                    cellKey={column.key}
                                  />
                                </CellLink>
                              ) : (
                                <Cell
                                  currentUser={currentUser}
                                  data-testid={`search-column-${kebabCase(
                                    column.key,
                                  )}-${rowIdx}`}
                                  candidate={candidate}
                                  cellKey={column.key}
                                />
                              )}
                            </StyledSearchCell>
                          ),
                      )}
                    </Row>
                    {row.isExpanded && (
                      <>
                        {isDSP ? (
                          <M.TableRow>
                            <StyledSearchCell />
                            <PastReports
                              candidate={candidate}
                              currentUser={currentUser}
                              columns={columns}
                              pastReports={pastReports}
                              geos={geos}
                            />
                          </M.TableRow>
                        ) : (
                          <ExpandedReportsRow
                            candidate={candidate}
                            contextId={contextId}
                            currentUser={currentUser}
                            geos={geos}
                            columns={columns}
                            reports={reports}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </M.TableBody>
          </M.Table>
        </M.TableContainer>
      );
    },
    [candidates, contextId, currentUser, geos, shouldExpand, CELL_CONFIG],
  );
  debug({ config });

  // DataTable expects an Object with key and header values; just create one based off of the string values coming in
  const headers: HeaderElem[] = useMemo(() => {
    return config.map((name: string) => {
      return { key: name, header: name };
    });
  }, [config]);

  return (
    <M.DataTable
      headers={headers}
      rows={candidates.data}
      render={getTableContent}
    />
  );
};

export default SearchTable;
