import React from 'react';

type Props = {
  label: string;
  style?: object;
};

export const Field: React.FC<Props> = ({ label, style }) => (
  <p style={style}>
    <strong>{label}:</strong>
  </p>
);

export default Field;
