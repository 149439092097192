import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

const StyledAnchor = styled.a`
  cursor: pointer;
`;

export const ExpungeableRule: React.FC = () => {
  const { t } = useTranslation('');

  return (
    <Trans t={t}>
      <p>
        Records identified with this setting are potentially available for
        expungement.
      </p>
      <p>
        Many states are passing legislation that allows for certain offenses
        (such as marijuana possession) to be expunged, or removed from public
        record. However, awareness, cost and a complex legal process prevents
        most people from pursuing expungements –only about 6.5% of those legally
        eligible actually receive an expungement.
      </p>
      <p>
        Because expungements are not being equally accessed, many employers want
        to more consistently evaluate impacted records by potential eligibility.
        By allowing expungeable records to meet your criteria, you can help
        people with these records move forward. See
        <StyledAnchor
          role='link'
          target='_blank'
          href='https://help.checkr.com/hc/en-us/articles/360055769734'
        >
          {' '}
          Expungements{' '}
        </StyledAnchor>
        in the Checkr Help Center for more information on which types of
        expungeable records we have covered today.
      </p>
    </Trans>
  );
};

export default ExpungeableRule;
