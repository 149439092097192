import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useLastReportTask } from 'modules/case-management/ui/TasksPage/hooks';
import { useReport } from 'containers/Report';
import UnassignedAvatar from '../shared/UnassignedAvatar';

const Assignee = styled.div`
  display: flex;
`;

const GreyTitle = styled.span`
  color: ${colors.uiGrey600};
  margin-right: 1.5rem;
`;

const AssignedTo = () => {
  const report = useReport();
  const task = useLastReportTask({ report });

  if (!task) return null;

  let assignedName = 'Unassigned';
  let avatar = <UnassignedAvatar size={20} />;

  if (task?.assignee) {
    assignedName = task.assignee.display_label;
    avatar = <M.Avatar name={assignedName} />;
  }

  return (
    <Assignee data-testid='case-management-internal-notes-assigned-to'>
      <GreyTitle>Assigned To</GreyTitle>
      {avatar}
      <div data-testid='case-management-internal-notes-assignee-name'>
        {assignedName}
      </div>
    </Assignee>
  );
};

export default AssignedTo;
