import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Button } from 'modules/assess/ui/page';

type Props = {
  handleBulkEdit: () => void;
};

const StyledCell = styled(M.TableCell)`
  background-color: ${colors.uiNavy200} !important;
  width: 23%;
`;

const BulkEdit: React.FC<Props> = ({ handleBulkEdit }) => {
  return (
    <StyledCell className='bulk-edit charge'>
      <Button
        onClick={handleBulkEdit}
        kind='secondary'
        size='sm'
        trans='buttons.bulk_edit'
      />
    </StyledCell>
  );
};

export default BulkEdit;
