import { Callback, useRumError } from '@dashboard-experience/utils';
import { PickerFileMetadata } from 'filestack-js';
import { useCallback } from 'react';

type UploadEvent = {
  filesFailed: PickerFileMetadata[];
  filesUploaded: PickerFileMetadata[];
};

/**
 * Callback function to handle both the success and failure of Filestack uploads
 * @param param
 * @param param.filestackKey - The filestack key, used to get a better idea of which bucket the upload failed to upload to
 * @param param.successCallback - Optional callback function, to be called upon a successful upload
 * @param param.errorCallback - Optional callback function, to be called upon a failed upload
 * @returns The handler function to be passed to the Filestack component
 */
const useFilestackUploadResponse = ({
  filestackKey,
  successCallback,
  errorCallback,
}: {
  filestackKey?: string;
  successCallback?: Callback;
  errorCallback?: Callback;
}) => {
  const addDataDogError = useRumError();

  const uploadHandler = useCallback(
    (event: UploadEvent) => {
      const { filesFailed, filesUploaded } = event;

      if (filesFailed.length) {
        // Report any failures to datadog
        addDataDogError(new Error('Failed to upload to Filestack'), {
          filestackKey,
          file: filesFailed[0],
        });

        errorCallback?.(filesFailed[0]);
      }

      if (filesUploaded.length) {
        // Return the successful upload to the caller
        successCallback?.(filesUploaded[0]);
      }
    },
    [addDataDogError, errorCallback, filestackKey, successCallback],
  );

  return uploadHandler;
};

export default useFilestackUploadResponse;
