import StyledComponent from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Status } from '../../types';

export const InformationContainer = StyledComponent.div`
  padding: 0px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Link = StyledComponent(M.Link)`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`;
export const CsInfoSpan = StyledComponent.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
`;

export const SyncResultInfoContainer = StyledComponent.div`
  height: 20px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = StyledComponent(M.Icon)`
  height: 20px;
  width: 20px;
`;

export const SyncResultTypography = StyledComponent.div<{
  status: Omit<Status, 'Idle'>;
}>`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: ${props =>
    props.status === 'Success' ? colors.uiGreen600 : colors.brandOrangeAccent2};
`;
