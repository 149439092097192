import React from 'react';
import styled from 'styled-components';
import { GenericObject } from '@dashboard-experience/utils';
import { M, colors } from '@dashboard-experience/mastodon';

const ExceptionsContainer: React.FC<GenericObject> = ({
  children,
  ...props
}) => {
  return (
    <M.ActionableNotification inline='classic' hideActionButton {...props}>
      {children}
    </M.ActionableNotification>
  );
};

export const ExceptionsWrapper = styled(ExceptionsContainer)`
  line-height: 1.5;
  max-width: 100% !important;
  margin-top: 0 !important;

  .exception-container {
    width: 100%;
    margin-top: 1rem;
  }

  .exception-header {
    display: flex;
    width: 100%;
  }

  .mastodon-divider {
    width: 100%;
    margin: 0 !important;
    margin-top: 1rem !important;
  }

  .exception-body-text {
    color: ${colors.uiGrey700};
    font-style: italic;
  }

  .exception-footer {
    margin-top: 1rem;

    a {
      display: block;
      margin-top: 1rem;
    }
  }

  .exception-item {
    margin-top: 0.5rem;
  }
`;

export default ExceptionsWrapper;
