import {
  StatusTypes,
  CurrentUser as User,
  hasPermission,
  getReportDecision,
} from '@dashboard-experience/utils';
import { Report } from 'types';

type GetFieldParams = {
  report: Report;
  statusType: string;
  user: User;
};

const getFields = ({ report, statusType, user }: GetFieldParams) => {
  const isAssessEnabled = statusType === StatusTypes.Assess;

  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
  const displayAssessment = getReportDecision(report, user);

  // Check for adjudicators / non-admin users
  const displayAssessmentField =
    hasPermission(user, 'read_assessments') &&
    displayAssessment &&
    isAssessEnabled;

  const fields = [];

  if (displayAssessmentField && report.assessment?.mvr_ruleset)
    fields.push({
      getFieldName: () => 'MVR',
      fieldKey: 'mvr_ruleset',
    });

  if (displayAssessmentField && report.assessment?.ruleset)
    fields.push({
      getFieldName: () => 'Criminal',
      fieldKey: 'criminal_ruleset',
    });

  return fields;
};

export default getFields;
