import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { Segment, FetchParentObjectResponse } from 'api/segments';
import { GenericObject } from '@dashboard-experience/utils';
import {
  TYPE_BRAND,
  TYPE_PAM,
  TYPE_PKG,
  TYPE_USER,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
} from '../../Constants';
import SegmentsSummary from './SegmentsSummary';
import { ParentContext } from '../../api/segments';

type Props = {
  segments: Segment[];
  parentObject: FetchParentObjectResponse;
  parentContext: ParentContext;
  unselectedSegments: string[];
  confirmation: boolean;
  success: boolean;
  currentAssignment: string[];
};

const SegmentsAddConfirmationSuccess: React.FC<Props> = ({
  segments,
  parentObject,
  parentContext,
  unselectedSegments = [],
  confirmation,
  success,
  currentAssignment,
}) => {
  const Title = styled.h1`
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin: 0;
    padding: 1.2rem 0rem;
  `;

  const titleOptions: GenericObject = {
    [TYPE_PAM]: 'PAM',
    [TYPE_PKG]: 'Package',
    [TYPE_USER]: 'User',
    [TYPE_BRAND]: 'Brand',
    [TYPE_MVR_RULE]: 'MVR Rule',
    [TYPE_APP_INSTANCE_CUSTOMER]: 'Application Instance',
  };

  const getSegmentObject = (segmentId: string): Segment | undefined =>
    segments.find(segment => segment.custom_id === segmentId);

  const segmentsWithSingleAssignment = currentAssignment.filter(id => {
    const segment = getSegmentObject(id);
    if (parentContext === TYPE_PAM) return segment!.pam;
    if (parentContext === TYPE_BRAND) return segment!.brand;
    return false;
  });

  const unassignedSegmentIds = unselectedSegments.concat(
    segmentsWithSingleAssignment,
  );

  const unassignedSegmentObjects = segments.filter(segment =>
    unassignedSegmentIds.find(id => segment.custom_id === id),
  );

  const assignedSegmentObjects = segments.filter(segment =>
    currentAssignment.find(id => segment.custom_id === id),
  );

  return (
    <>
      <Title>
        {confirmation && (
          <>Are you sure you want to complete the actions below?</>
        )}
        {success && (
          <span style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
            <M.Icon
              icon='CheckmarkFilled'
              size={20}
              style={{ fill: colors.uiGreen600 }}
            />
            &nbsp;
            {titleOptions[parentContext]}
            &nbsp;has been successfully saved!
          </span>
        )}
      </Title>
      <SegmentsSummary
        mode='unassign'
        segments={unassignedSegmentObjects}
        parentContext={parentContext}
        parentObject={parentObject}
        confirmation={confirmation}
        success={success}
      />
      <SegmentsSummary
        mode='assign'
        segments={assignedSegmentObjects}
        parentContext={parentContext}
        parentObject={parentObject}
        confirmation={confirmation}
        success={success}
      />
    </>
  );
};

export default SegmentsAddConfirmationSuccess;
