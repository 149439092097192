import * as Custom from 'modules/assess/models/rules/custom';
import * as Entity from 'modules/assess/models/rules/entity';
import * as Premade from 'modules/assess/models/rules/premade';
import * as count from './count';
import * as lookback from './lookback';

export type RequestData = {
  count: Array<count.RequestData>;
  custom: Custom.List;
  lookback: Array<lookback.RequestData>;
  premade: Premade.List;
};

export type ResponseData = {
  count: Array<count.ResponseData> | null;
  custom: Custom.List | null;
  lookback: Array<lookback.ResponseData> | null;
  premade: Premade.List | null;
};

export function fromAPI(data: ResponseData): Entity.Map {
  const result: Entity.Map = {
    count: data.count?.map(count.fromAPI) || [],
    custom: data.custom || [],
    lookback: data.lookback?.map(lookback.fromAPI) || [],
    premade: data.premade || [],
  };

  return result;
}

export function toAPI(data: Entity.Map): RequestData {
  const result: RequestData = {
    count: Object.values(data.count).map(count.toAPI),
    custom: Object.values(data.custom),
    lookback: Object.values(data.lookback).map(lookback.toAPI),
    premade: Object.values(data.premade),
  };

  return result;
}
