import React from 'react';
import { PageError } from 'components';
import { Page } from 'modules/assess/ui';

export const Missing: React.FC = () => {
  return (
    <Page.Container data-testid='assess-missing'>
      <PageError code='404' />
    </Page.Container>
  );
};

export default Missing;
