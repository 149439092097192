import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Description } from 'modules/assess/models/rules/premade';
import List from './List';
import './styles.scss';

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.5em;
  color: ${colors.brandNavy3};
`;

type Props = {
  description: Description[];
  hideLabel?: boolean;
};

export const Container: React.FC<Props> = ({ description, hideLabel }) => {
  const { t } = useTranslation();
  const label = t('nouns.description');
  const basePath = 'assess-v2-ui-guideline-quickstart-common-rule-description';
  const titleId = `${basePath}-title`;
  const listId = `${basePath}-list`;

  return (
    <div>
      <Title className='title' data-testid={titleId}>
        {!hideLabel && label}
      </Title>
      <div data-testid={listId}>
        <List description={description} />
      </div>
    </div>
  );
};

export default Container;
