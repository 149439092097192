import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useFetchTurnaroundTime } from 'api/eta';
import {
  CountyCriminalSearch,
  CountyCriminalSearchWithEta,
  Report,
  EtaPredictions,
  Eta,
  ScreeningPredictions,
} from 'types';
import { orderByKey } from '@dashboard-experience/utils';
import { STATUS_ORDER } from 'Constants';

const baseEta: EtaPredictions = {
  estimatedCompletionTime: undefined,
  reportPrediction: undefined,
  reportEstimationGenerated: undefined,
  isLoading: false,
  isError: false,
};

const useReportEta = (report: Report) => {
  const searches = report?.county_criminal_searches || [];
  const screenings = orderByKey(
    searches,
    'status',
    STATUS_ORDER,
  ) as CountyCriminalSearch[];

  // Fetch the report eta
  const { data, isLoading, isError } = useFetchTurnaroundTime(report?.id);

  const eta = {
    ...baseEta,
    isLoading,
    isError,
  };

  const noEta =
    !data || !data?.screening_predictions || !data?.report_predictions;

  if (isLoading || isError || noEta) return eta;

  const etaData = data as Eta;
  const predictions = etaData?.screening_predictions;
  // Used in report info panel (est. comp)
  const reportPredictions = etaData?.report_predictions;

  const reportPrediction = reportPredictions[0].estimated_completion_time;
  // Used in report info panel (est. last updated at)
  const reportEstimationGenerated = moment(
    reportPredictions[0].estimate_generated_at,
  ).format('MMM D, YYYY');

  let estimatedCompletionTime: string | undefined;

  // Add eta meta data to county criminal screenings to display additional info panel
  let etaScreenings: CountyCriminalSearchWithEta[] = [];

  if (predictions?.length > 0 && screenings?.length > 0) {
    etaScreenings = screenings.map((s: CountyCriminalSearch) => {
      const screening = cloneDeep(s) as CountyCriminalSearchWithEta;
      screening.hasEta = false;
      predictions.forEach((screeningPrediction: ScreeningPredictions) => {
        const { estimated_completion_time, screening_id, meta } =
          screeningPrediction;
        // for the customer side we don't use the report prediction, instead we get the max eta
        // from all the screenings with an eta.
        if (screening.id === screening_id) {
          if (!estimatedCompletionTime) {
            estimatedCompletionTime = estimated_completion_time;
          } else {
            estimatedCompletionTime = new Date(
              Math.max(
                Date.parse(estimated_completion_time as string),
                Date.parse(estimatedCompletionTime),
              ),
            ).toISOString();
          }

          // add the needed metadata
          screening.prediction_datetime = estimated_completion_time;
          screening.county_speed = meta?.county_speed;
          screening.court_access_method =
            meta?.court_access_method || 'electronic_records_available';
          screening.hasEta = true;
        } else {
          // if the screening does not have a prediction, the default court
          // method should be the fastest method.
          screening.court_access_method = 'electronic_records_available';
        }
      });

      return screening;
    });
  }

  return {
    estimatedCompletionTime,
    isLoading,
    isError,
    reportPrediction,
    reportEstimationGenerated,
    screenings: etaScreenings,
  };
};

export default useReportEta;
