import React from 'react';
import styled from 'styled-components';
import { Namespace } from 'modules/assess/api';
import { useNamespace } from 'modules/assess/ui/router/context';
import { PremadeRuleChange } from 'modules/assess/models/rulesets/version-diff';
import Table from './Table';
import Title from './Title';

type Props = {
  changes?: PremadeRuleChange.List;
};

const StyledContainer = styled.div`
  padding-bottom: 1rem;
`;

export const Container: React.FC<Props> = ({ changes }) => {
  const namespace = useNamespace() || Namespace.criminal;

  if (!changes?.length) {
    return null;
  }

  return (
    <StyledContainer data-testid='assess-ui-ruleset-version-publish-fairness-container'>
      <Title />
      <Table changes={changes} namespace={namespace} />
    </StyledContainer>
  );
};

export default Container;
