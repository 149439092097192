import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';
import useGetUser from './useGetUser';

const deletePackage = async (accountId: string, packageId: any) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.delete(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/packages/${packageId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

type Params = {
  packageId: any;
};

function useDeletePackage() {
  const { data: user } = useGetUser();

  const [mutate, { data, isLoading, error }] = useMutation(
    async ({ packageId }: Params) => {
      const response = await deletePackage(user.account.id, packageId);
      return response.data;
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default useDeletePackage;
