import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Invite } from '../../SharedItems';

const StretchInput = styled(M.Input)`
  flex-grow: 1;
  flex-basis: 0;
`;

const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: content;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const InviteEntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 100%;
  padding: 16px;
`;

const RemoveButtonComponent = ({
  onClick,
  index,
  showDeleteButton,
}: {
  onClick: () => void;
  index: number;
  showDeleteButton: boolean;
}) => {
  return (
    <RemoveButtonContainer
      style={{ visibility: showDeleteButton ? 'visible' : 'hidden' }}
    >
      <RemoveButton
        onClick={onClick}
        data-testid={`delete-invite-entry-${index}`}
      >
        <M.Icon icon='Close' color={colors.uiGrey400} size={20} />
      </RemoveButton>
    </RemoveButtonContainer>
  );
};
interface InviteEntryProps {
  index: number;
  invites: Invite[];
  handleEmailChange: (e: any, id: string) => void;
  handleNameChange: (e: any, id: string) => void;
  handlePhoneNumberChange: (e: any, id: string) => void;
  handleRemove: (id: string) => void;
}

const InviteEntry = ({
  index = 0,
  invites,
  handleEmailChange,
  handleNameChange,
  handlePhoneNumberChange,
  handleRemove,
}: InviteEntryProps) => {
  const invite = invites[index];
  const deleteButtonEnabled = index !== 0 || invites.length > 1;
  const handleRemoveID = useCallback(
    () => handleRemove(invite.id),
    [handleRemove, invite.id],
  );

  return (
    <InviteEntryWrapper data-testid={`invite-entry-${index}`}>
      <StretchInput
        type='email'
        placeholder='name@email.com'
        labelText='Email'
        defaultValue={invite.email.value}
        data-dd-privacy='mask'
        id={`invite-email-${index}`}
        data-testid={`invite-email-${index}`}
        name='email'
        invalid={!invite.email.isValid}
        invalidText='Invalid email' // TODO: specific error codes
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleEmailChange(e, invite.id)
        }
      />
      <StretchInput
        type='text'
        placeholder='Name'
        labelText='First name (optional)'
        defaultValue={invite.firstName.value}
        id={`invite-first-name-${index}`}
        data-testid={`invite-first-name-${index}`}
        name='firstName'
        invalid={!invite.firstName.isValid}
        invalidText='Invalid name' // TODO: specific error codes?
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleNameChange(e, invite.id)
        }
      />
      <StretchInput
        type='phone'
        placeholder='(555) 555-5555'
        labelText='Phone number (optional)'
        defaultValue={invite.phoneNumber.value}
        id={`invite-phone-number-${index}`}
        data-testid={`invite-phone-number-${index}`}
        data-dd-privacy='mask'
        name='phoneNumber'
        invalid={!invite.phoneNumber.isValid}
        invalidText='Invalid phone number' // TODO: specific error codes?
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handlePhoneNumberChange(e, invite.id)
        }
      />
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <RemoveButtonComponent
        onClick={handleRemoveID}
        index={index}
        showDeleteButton={deleteButtonEnabled}
      />
    </InviteEntryWrapper>
  );
};

export default InviteEntry;
