import React from 'react';
import { formatDate } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../types';

const DateType: React.FC<FieldComponentProps> = ({ adverseItem, testid }) => {
  const { date } = adverseItem;
  return (
    <span data-testid={testid} className='value-width'>
      {formatDate(date, 'MMM Do YYYY')}
    </span>
  );
};

export default DateType;
