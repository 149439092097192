import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import * as Entity from 'modules/assess/models/settings/account';
import * as Package from 'modules/assess/models/settings/package';
import { Settings } from 'modules/assess/utilities';
import Options from './options';
import SelectPackages from './select-packages';
import SelectedPackages from './selected-packages';
import SettingToggle from '../setting-toggle';

type Props = {
  locals: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
  versionClass: string;
};

const Container: React.FC<Props> = ({ locals, setSettings, versionClass }) => {
  const selections = Settings.getAutoEngagePackages(locals?.packages);

  const setPackages = useCallback(
    (packages: Package.List) => {
      setSettings(state => {
        return { ...state, packages };
      });
    },
    [setSettings],
  );

  const disabled =
    !locals?.assess_auto_engage_eligible_reports ||
    locals?.assess_auto_engage_eligible_reports_value !== 'enabled_packages';

  return (
    <div>
      <SettingToggle
        name='assess_auto_engage_eligible_reports'
        versionClass={versionClass}
        settings={locals}
        setSettings={setSettings}
        showAlternateDescription={!isEmpty(selections)}
      />
      <Options locals={locals} setSettings={setSettings} />
      <SelectedPackages selections={selections} />
      <SelectPackages
        packages={locals?.packages}
        setPackages={setPackages}
        disabled={disabled}
      />
    </div>
  );
};

export default Container;
