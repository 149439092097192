import React, { useCallback, useEffect, useState } from 'react';
import { TaskType, UserType } from 'modules/case-management/types';
import { useUpdateTasks } from 'modules/case-management/ui/TasksPage/hooks';
import ConfirmModal from './ConfirmModal';
import BatchUserSelect from './BatchUserSelect';

type PropsType = {
  selectedTasks: TaskType[] | [];
  tasks: TaskType[] | [];
};

const BatchActionButton = ({ selectedTasks = [], tasks = [] }: PropsType) => {
  const [assignedUser, setAssignedUser] = useState<UserType | null | undefined>(
    undefined,
  );
  const [openModal, setOpenModal] = useState(false);

  const { call: updateTasks } = useUpdateTasks();

  const reassignTasks = useCallback(() => {
    const tasksToUpdate: { id: string; assignee_id: string | null }[] = [];
    selectedTasks.forEach(task => {
      tasksToUpdate.push({
        id: task.id,
        assignee_id: assignedUser?.id || null,
      });
    });
    updateTasks(tasksToUpdate);
  }, [assignedUser, selectedTasks, updateTasks]);

  useEffect(() => {
    if (assignedUser === undefined) return;
    const taskWithAssignees = selectedTasks.filter(task => task.assignee);
    if (taskWithAssignees.length > 0) {
      setOpenModal(true);
    } else {
      reassignTasks();
    }
  }, [assignedUser, reassignTasks, selectedTasks, tasks.length]);

  const closeModal = useCallback(() => {
    setAssignedUser(undefined);
    setOpenModal(false);
  }, [setOpenModal]);

  return (
    <>
      <BatchUserSelect callback={setAssignedUser} />
      <ConfirmModal
        assignee={assignedUser}
        onClose={closeModal}
        onConfirm={reassignTasks}
        open={openModal}
        selectedTasks={selectedTasks}
        tasks={tasks}
      />
    </>
  );
};

export default BatchActionButton;
