import React from 'react';
import { useReport } from 'containers/Report';
import { NoteType } from 'modules/case-management/types';
import 'moment-timezone';
import { useNotes } from '../hooks';
import Note from './Note';

type Props = {};

const ShowNotes: React.FC<Props> = () => {
  const report = useReport();
  const { data } = useNotes({ report_id: report.id });

  return (
    <div data-testid='case-management-internal-notes-show-notes'>
      {data?.data.map((note: NoteType) => (
        <Note key={Math.random()} note={note} />
      ))}
    </div>
  );
};

export default ShowNotes;
