import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { toastError } from 'actions';
import { GenericObject } from '@dashboard-experience/utils';
import { useEffect, useState } from 'react';
import { chunk } from 'lodash';
import {
  fetchAdverseActions,
  fetchCandidateReport,
  fetchPostAdverseActionsNoticeTemplate,
  sendBulkAdverseActionNotices,
  sendPostAdverseActionNotice,
} from './actions';
import { MAX_BULK_AA_IDS, PER_PAGE } from '../../Constants';
import { AdverseAction } from '../../components/AdverseActions/types';

export const useFetchAdverseActions = () => {
  const dispatch = useDispatch();
  const request = (params: GenericObject) => fetchAdverseActions(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {},
    onError: () => {
      dispatch(toastError('Something went wrong, please try again later.'));
    },
  });

  return {
    call,
    result,
  };
};

export const useFetchCandidateReport = (reportId: string) => {
  const key = `report_${reportId}`;
  const request = () => fetchCandidateReport(reportId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const useFetchPostAdverseActionsNoticeTemplate = (
  reportId: string,
  params?: { edit_mode: boolean; individualized_reassessment: string },
) => {
  const key = `template_${reportId}`;
  const request = () => fetchPostAdverseActionsNoticeTemplate(reportId, params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

export const useSendPostAdverseActionNotice = (id: string) => {
  const request = () => sendPostAdverseActionNotice(id);

  const [call, result] = useMutation(request, {});

  return {
    call,
    result,
  };
};

export const useSendBulkAdverseActionNotices = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const request = (sendableNotices: Array<string>) =>
    sendBulkAdverseActionNotices(sendableNotices);

  const [call] = useMutation(request);

  const submitAdverseActions = (sendableNotices: string[]) => {
    setIsProcessing(true);

    const idGroups = chunk(sendableNotices, MAX_BULK_AA_IDS);
    let tries = 0;
    idGroups.forEach(idGroup => {
      call(idGroup).then(() => {
        tries += 1;
        if (tries === idGroups.length) {
          setIsProcessing(false);
          setIsSuccess(true);
        }
      });
    });
  };

  const clear = () => {
    setIsProcessing(false);
    setIsSuccess(false);
  };

  return {
    clear,
    isProcessing,
    isSuccess,
    submitAdverseActions,
  };
};

export const useFetchBulkAdverseActions = () => {
  let query: GenericObject = {
    page: 1,
    per_page: PER_PAGE,
    order_by: 'created_at',
    status: 'non_scheduled',
    post_notice_ready: true,
  };

  const request = (params: GenericObject) => fetchAdverseActions(params);
  const [call, result] = useMutation(request);

  const [adverseActions, setAverseActions] = useState<AdverseAction[]>([]);
  const [adverseActionsProgress, setAdverseActionsProgress] = useState(0);
  const [sendableNotices, setSendableNotices] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (result.data?.data) {
      const arr = [...adverseActions, ...result.data.data];
      setAverseActions(arr);
      setAdverseActionsProgress((arr.length / result.data.count) * 100);
      setSendableNotices(getSendableNotices(arr));
    }
  }, [result.data]);

  const getSendableNotices = (adverseActions: AdverseAction[]): string[] => {
    return adverseActions
      .filter(adverseAction => {
        return (
          adverseAction.status !== 'dispute' &&
          adverseAction.status !== 'canceled' &&
          adverseAction.delivery.state !== 'error' &&
          adverseAction.post_notice_scheduled_at === null
        );
      })
      .map(adverseAction => adverseAction.id);
  };

  const getAdverseActions = (params: GenericObject) => {
    setIsProcessing(true);
    query = { ...query, ...params };
    call(query).then(r => {
      if (r.next_href) {
        query.page += 1;
        getAdverseActions(query);
      } else {
        setIsProcessing(false);
        setIsSuccess(true);
      }
    });
  };

  const clear = () => {
    setAverseActions([]);
    setAdverseActionsProgress(0);
    setSendableNotices([]);
    setIsSuccess(false);
  };

  return {
    adverseActionsProgress,
    clear,
    getAdverseActions,
    isProcessing,
    isSuccess,
    sendableNotices,
  };
};
