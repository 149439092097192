import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  CurrentUser,
  GenericObject,
  hasPermission,
} from '@dashboard-experience/utils';
import AdverseActionsBanner from './AdverseActionsBanner';
import AdverseActionExportCsv from './AdverseActionExportCsv';
import AdverseActionTableRow from './AdverseActionTableRow';
import AdverseActionsSearch from './AdverseActionsSearch';
import PostAdverseActionNoticeModal from './PostAdverseActionNoticeModal';
import AdverseActionsTableHead from './AdverseActionsTableHead';
import SendBulkNotices from './SendBulkNotices';
import { RightButtonsContainer } from './AdverseActionsStyledComponents';
import { PAUSED } from './Statuses';
import { AdverseAction, AdverseActionResponse } from './types';
import { useUser } from '../../context/CurrentUser';

type Props = {
  closeModal: Function;
  onSearchUpdate: Function;
  onSortClick: Function;
  showPostAdverseActionNoticeModal: Function;
  state: {
    params: GenericObject;
    statuses: Array<string>;
    openModal: boolean;
    currentAdverseAction: AdverseAction;
  };
  adverseActions?: AdverseActionResponse;
};

const AdverseActionsTable: React.FC<Props> = ({
  closeModal,
  onSearchUpdate,
  onSortClick,
  showPostAdverseActionNoticeModal,
  state,
  adverseActions,
}) => {
  const currentUser: CurrentUser = useUser();
  const handlePaginationClick = useCallback(
    index => {
      onSearchUpdate({ page: index + 1 });
    },
    [onSearchUpdate],
  );

  const getPageCount: (itemCount: number, perPage: number) => number = (
    itemCount,
    perPage,
  ) => Math.ceil(itemCount / perPage);

  const getAdverseActionsData: () => Array<AdverseAction> = () => {
    if (adverseActions) {
      return adverseActions.data;
    }

    return [];
  };

  const getResultsCount: () => number = () => {
    if (adverseActions) {
      return adverseActions.count;
    }
    return 0;
  };

  const showPaused: boolean = state.params.status === PAUSED.toLowerCase();
  const pageIndex: number = state.params.page - 1;
  const adverseActionsData: Array<AdverseAction> = getAdverseActionsData();
  const resultsCount: number = getResultsCount();
  const pageCount: number = getPageCount(resultsCount, state.params.per_page);

  return (
    <div data-testid='adverse-actions-table'>
      <RightButtonsContainer>
        <SendBulkNotices />
        {hasPermission(currentUser, 'perform_export_csv') && (
          <AdverseActionExportCsv
            fullName={state.params?.full_name}
            status={state.params?.status}
          />
        )}
      </RightButtonsContainer>
      <AdverseActionsSearch
        onSearchUpdate={onSearchUpdate}
        resultsCount={resultsCount}
        state={state}
      />
      <AdverseActionsBanner
        isPaused={state.params?.status === 'paused'}
        hasResults={resultsCount > 0}
      />
      <M.TableContainer>
        <M.Table data-testid='adverse-action-candidate-table'>
          <AdverseActionsTableHead
            showPaused={showPaused}
            onSortClick={onSortClick}
            {...state}
          />
          <M.TableBody>
            {adverseActionsData.map((adverseAction: AdverseAction) => {
              return (
                <AdverseActionTableRow
                  key={adverseAction.id}
                  adverseAction={adverseAction}
                  showPaused={showPaused}
                  showPostAdverseActionNoticeModal={
                    showPostAdverseActionNoticeModal
                  }
                />
              );
            })}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
      {!isNaN(pageCount) && (
        <M.Pagination
          pageCount={pageCount}
          onPageClick={handlePaginationClick}
          selectedIndex={pageIndex}
        />
      )}
      {state.openModal && (
        <PostAdverseActionNoticeModal
          closeModal={closeModal}
          adverseAction={state.currentAdverseAction}
        />
      )}
    </div>
  );
};

export default AdverseActionsTable;
