import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps, ChildAbuseSearch as ChildAbuseSearchType } from 'types';
import { SCREENING_TITLES } from 'utils';
import ChildAbuseSearch from './ChildAbuseSearch';
import DownloadLink from './DownloadLink';

const ChildAbuse: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { child_abuse_searches } = report;

  return child_abuse_searches && child_abuse_searches.length > 0 ? (
    <M.Screening
      title='Child Abuse'
      statusType={reportStatusType}
      screening={child_abuse_searches}
      id={SCREENING_TITLES.child_abuse_searches.id}
    >
      {child_abuse_searches.map((search: ChildAbuseSearchType) => (
        <ChildAbuseSearch
          search={search}
          key={search.id}
          statusType={reportStatusType}
        />
      ))}
      {/* @ts-ignore TODO: Remove comment once Report type is consumed/updated in Utils */}
      <DownloadLink report={report} />
    </M.Screening>
  ) : null;
};

export default ChildAbuse;
