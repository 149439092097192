import { Namespace } from 'modules/assess/api';
import { Category } from 'modules/assess/models/criminal/rules/rules';

export const CRIMINAL_CATEGORIES: Array<Category> = [
  Category.DISPOSITION_NON_CONVICTION,
  Category.CHARGE_CATEGORY,
  Category.CHARGE_SEVERITY_TIMING,
  Category.CANDIDATE,
];

export function getCategoriesByNamespace(namespace: Namespace) {
  if (namespace === Namespace.criminal) return CRIMINAL_CATEGORIES;

  return [];
}
