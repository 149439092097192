import { useCallback, useEffect, useRef } from 'react';
import { CHECKR_DASHBOARD_URL } from 'Constants';

export type RelayTypes = {
  callback: Function;
  isIframe: boolean;
  contextId: string | null;
  messageId: string;
  deps?: any[];
};

/**
 * @name useDashboardRelay
 * @function
 * @description Reusable hook to add a relay event with its listener
 * @param params
 * @param {function} params.callback - Callback to fire when event is detected
 * @param {boolean} params.isIframe - Whether this view is in an iframe or not
 * @param {string} params.contextId - The unique ID of the iframe
 * @param {string} params.messageId - The message ID to listen for
 * @param {any[]} params.deps - Optional additional dependencies
 */
export const useDashboardRelay = ({
  callback,
  isIframe,
  contextId,
  messageId,
  deps = [],
}: RelayTypes) => {
  const eventAdded = useRef(false);

  const eventHandler = useCallback(
    (e: MessageEvent) => {
      const { data, origin } = e;
      if (
        origin !== CHECKR_DASHBOARD_URL ||
        (data.contextId && data.contextId !== contextId) ||
        data.messageId !== messageId
      ) {
        return;
      }
      callback(data);
    },
    [callback, contextId, messageId],
  );

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;
    if (isIframe && !eventAdded.current) {
      window.addEventListener('message', eventHandler);
      eventAdded.current = true;
    }
    // eslint-disable-next-line consistent-return
    return () => {
      if (eventAdded.current) {
        window.removeEventListener('message', eventHandler);
        eventAdded.current = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId, ...deps]);
};

export default useDashboardRelay;
