/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import { debounce } from 'lodash';
import { PaymentProfilesTable } from 'components/PaymentProfiles';
import {
  HeadingButtonsContainer,
  HeadingContainer,
  SelectNode,
  StyledFormHeading,
} from 'components/PaymentProfiles/PaymentProfileStyledComponents';
import { useGetNodeWithBillingPrefs, usePutBillableNode } from 'api/billing';
import { scrollToTop } from 'utils';
import { useFetchNodes } from 'api/nodes';
import { useUser } from 'context/CurrentUser';
import NodeBillingForm from 'components/PaymentProfiles/NodeBillingForm';
import {
  parseNodeFilter,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { BillingEntity } from '../../types/Billing';
import NodeBillingDisablePopup from '../../components/PaymentProfiles/NodeBillingDisablePopup';

const tableHeaders = [
  {
    key: 'id',
    header: 'id',
    name: 'customId',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'name',
    header: 'name',
    name: 'nodeName',
    className: 'left',
    tooltip: false,
  },
  {
    key: 'email',
    header: 'email',
    name: 'billingContact',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'address',
    header: 'address',
    name: 'billingAddress',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'billable',
    header: 'billable',
    name: 'billable',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'editButton',
    header: 'editButton',
    name: '',
    className: 'left',
    tooltip: false,
  },
];

const formatTableElements = (nodes: any[]): BillingEntity[] => {
  return nodes.map((node: any) => ({
    id: node.custom_id,
    name: node.name,
    email: node.billing_info?.primary_email || '',
    parent_custom_id: node.parent_custom_id,
    tier: node.tier,
    address: {
      address_line_1: node.billing_info.billing_address.address_line_1,
      address_line_2: node.billing_info.billing_address.address_line_2 || '',
      city: node.billing_info.billing_address.city,
      region: node.billing_info.billing_address.region,
      country: node.billing_info.billing_address.country,
      postal_code: node.billing_info.billing_address.postal_code,
    },
    billable: node.billable,
  }));
};

const NodeBillingContainer: React.FC = () => {
  const { t } = useTranslation();

  const { account = {} } = useUser();
  const defaultNode = {
    id: '',
    custom_id: '',
    name: '',
    parent_custom_id: '',
    tier: '',
  };

  // Mark filter dirty immediately when changed
  // but debounce updating it to prevent over fetching
  const [nodeFilterIsDirty, setNodeFilterIsDirty] = useState(false);
  const [nodeFilter, setNodeFilter] = useState('');
  const [selectedNode, setSelectedNode] = useState(defaultNode);

  const { data: { data: nodes = [] } = {}, isLoading: nodeIsLoading } =
    useFetchNodes({
      account,
      nodeName: parseNodeFilter(nodeFilter).name,
    });

  const {
    data: { data: billableNodesData = [] } = {},
    isLoading: billableNodesLoading,
    error: billableNodesError,
    refetch,
  } = useGetNodeWithBillingPrefs();

  const refetchBillableNodes = useCallback(refetch, []);

  const nodeSelected = useCallback(
    value => {
      setSelectedNode(value);
    },
    [setSelectedNode],
  );

  const debouncedSetNodeFilter = useDebouncedCallback(
    (newFilter: string) => {
      setNodeFilter(newFilter);
      setNodeFilterIsDirty(false);
    },
    350,
    [debounce, setNodeFilter, setNodeFilterIsDirty],
  );

  const onNodeFilterChange = useCallback(
    newFilter => {
      setNodeFilterIsDirty(true);
      debouncedSetNodeFilter(newFilter);
    },
    [debouncedSetNodeFilter, setNodeFilterIsDirty],
  );

  const newPaymentProfile = {
    id: selectedNode?.custom_id,
    name: selectedNode?.name,
    email: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      country: '',
      city: '',
      region: '',
      postal_code: '',
    },
  };

  const buttonDisabled = !selectedNode?.id ? 'disabled' : '';
  const buttonTooltip = buttonDisabled
    ? t('tooltips.addBillableNodeButtonDisabled')
    : t('tooltips.addBillableNodeButtonEnabled');

  const { call: putBillableNode } = usePutBillableNode();

  const submit = useCallback(
    (params, isDisable) => {
      let payload;
      if (!isDisable) {
        payload = {
          billable: true,
          custom_id: params.id,
          billing_address: {
            address_line_1: params.address_line_1,
            address_line_2: params.address_line_2,
            city: params.city,
            region: params.region,
            country: params.country,
            postal_code: params.postal_code,
          },
          primary_email: params.email,
        };
      } else {
        payload = {
          billable: false,
          custom_id: params.id,
        };
      }
      putBillableNode(payload).then(() => {
        refetchBillableNodes();
      });
    },
    [refetchBillableNodes, putBillableNode],
  );

  // FOR BUTTON
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    if (buttonDisabled) return;

    setModalOpen(true);
    scrollToTop();
  }, [buttonDisabled, modalOpen, setModalOpen]);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  if (!billableNodesData || billableNodesLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <HeadingContainer data-testid='node-billing-heading-container'>
        <StyledFormHeading data-testid='node-billing-heading-text'>
          {t('headings.nodeBilling')}
        </StyledFormHeading>
        <HeadingButtonsContainer>
          <SelectNode
            data-testid='node-select'
            nodes={nodes}
            selectedNode={selectedNode}
            onChange={nodeSelected}
            onFilterChange={onNodeFilterChange}
            loading={nodeIsLoading || nodeFilterIsDirty}
          />
          <M.TooltipButton
            data-testid='add-billable-node-button'
            kind='primary'
            onClick={showModal}
            className={buttonDisabled}
            label={buttonTooltip}
          >
            <M.Icon icon='Add' />
            {t('payment_profile.add_billable_node_button')}
          </M.TooltipButton>
        </HeadingButtonsContainer>
      </HeadingContainer>
      <M.TableContainer style={{ overflow: 'auto' }}>
        <PaymentProfilesTable
          isLoading={billableNodesLoading}
          error={billableNodesError}
          submit={submit}
          tableHeaders={tableHeaders}
          records={formatTableElements(billableNodesData)}
          EditForm={NodeBillingForm}
          NodeBillingDisablePopup={NodeBillingDisablePopup}
        />
      </M.TableContainer>
      <NodeBillingForm
        data-testid='add-node-billing-form'
        paymentProfile={newPaymentProfile}
        submit={submit}
        modalOpen={modalOpen}
        hideModal={hideModal}
        isLoading={billableNodesLoading}
      />
    </div>
  );
};

export default NodeBillingContainer;
