import styled from 'styled-components';
import AlignItems from './AlignItems';

const FlexRowJustify = styled.div<{
  gap: string;
  alignItems: AlignItems;
  marginBottom?: string;
}>`
  align-items: ${props => props.alignItems};
  align-self: stretch;
  display: flex;
  gap: ${props => props.gap};
  margin-bottom: ${props => props.marginBottom};

  .mastodon-dropdown {
    flex: 1 0 0 !important;
  }

  .mastodon-input {
    flex: 1 0 0 !important;
  }

  .mastodon-loading-inline {
    flex: 1 0 0 !important;
  }

  .mastodon-search-container {
    display: flex;
    flex: 1 0 0 !important;
  }
`;

export default FlexRowJustify;
