import { useEffect, useCallback } from 'react';
import omit from 'lodash/omit';
import {
  BaseAAPayload,
  PostAAPayload,
  useFetchPreAdverseActionNoticePreview,
  usePostAdverseAction,
  useUploadIndividualizedAssessment,
} from 'modules/adjudication/api';
import { useModalState } from '../../../context';

export const useAAState = () => {
  const {
    adverseItems,
    individualizedAssessment,
    medium,
    templates,
    subtype,
    daysToPostAdverseAction,
    basis,
  } = useModalState('state');

  return {
    adverse_item_ids: adverseItems?.map((item: any) => item.fingerprint),
    adjudication_subtype: subtype?.slug || null,
    days_to_post_adverse_action: daysToPostAdverseAction,
    medium,
    template_ids: templates,
    individualized_assessment: individualizedAssessment?.assessment,
    basis,
  };
};

export const useGetPreview = () => {
  const params = omit(useAAState(), ['adjudication_subtype']) as BaseAAPayload;
  const { call: getPreview, result: getPreviewResult } =
    useFetchPreAdverseActionNoticePreview();
  const {
    data: { preview, attachments } = {},
    isLoading,
    isError,
    error,
  } = getPreviewResult;

  useEffect(() => {
    getPreview(params);
    // The preview notice should only be fetched once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    preview,
    attachments,
    isLoading,
    isError,
    error,
  };
};

export const usePostAA = () => {
  const nextScreen = useModalState('nextScreen');
  const { postAA, postAAResult } = usePostAdverseAction(nextScreen);
  const params = useAAState() as PostAAPayload;
  const { individualizedAssessment } = useModalState('state');

  const handlePostAA = useCallback(() => {
    postAA(params);
  }, [postAA, params]);

  const { uploadAssessment, uploadAssessmentResult } =
    useUploadIndividualizedAssessment(handlePostAA);

  const handlePostAssessment = useCallback(() => {
    if (individualizedAssessment?.uploadedFile) {
      uploadAssessment(individualizedAssessment.uploadedFile.file);
    }
  }, [uploadAssessment, individualizedAssessment]);

  const submit = individualizedAssessment?.uploadedFile
    ? handlePostAssessment
    : handlePostAA;

  const errors = [postAAResult.error, uploadAssessmentResult.error].filter(
    (error) => error && error,
  ) as Error[];

  const isLoading = postAAResult.isLoading || uploadAssessmentResult.isLoading;

  return {
    submit,
    errors,
    isLoading,
  };
};
