import React, { useEffect } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_BULKORDER_NAMES,
} from 'utils/analytics';
import BulkOrderHistoryTableContainer from './BulkOrderHistoryTableContainer';
import { BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY } from '../../../Flags';

const BulkOrderContainer = () => {
  const bulkCandidateOrderInviteUploadFlagr = useFlag(
    BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY,
  )?.variantKey;

  const bulkCandidateOrderInviteUploadFlagrOn = !isNaN(
    bulkCandidateOrderInviteUploadFlagr,
  );

  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (bulkCandidateOrderInviteUploadFlagrOn) {
      trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.HISTORY_HIDDEN_VIEWED);
    }
  }, [bulkCandidateOrderInviteUploadFlagrOn, trackEvent]);

  return <BulkOrderHistoryTableContainer />;
};

export default BulkOrderContainer;
