import React from 'react';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import styled from 'styled-components';
import Label from 'modules/assess/ui/rules/common/rule/editor/conditions/label';
import AddCondition from './add-condition';
import Condition from './condition';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NewConditions = ({ children }: any) => {
  const { conditions } = useConditions();

  return (
    <div data-testid='assess-rules-common-conditions-block'>
      <Label />
      {conditions.map((condition: { fact: any }, i: number) => (
        <Condition index={i} key={condition.fact || i} />
      ))}
      <ButtonContainer>
        <AddCondition />
        {children}
      </ButtonContainer>
    </div>
  );
};

export default NewConditions;
