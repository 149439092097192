import UIContext from 'context/UI';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CreateGeo, GeosSettings, GeosTable } from '.';

const SettingsContainer = styled.div`
  padding-bottom: 2rem;
`;
const NewGeoContainer = styled.div`
  padding-bottom: 1rem;
`;

const GeosContainer: React.FC = () => {
  const [newGeoMade, setNewGeoMade] = useState<boolean>(false);
  const { t } = useTranslation();

  const { isIframe } = useContext(UIContext);

  return (
    <>
      {!isIframe && <h2>{t('screening_settings.geos.geos')}</h2>}
      <SettingsContainer>
        <GeosSettings />
      </SettingsContainer>

      <h3>{t('screening_settings.geos.company_geos')}</h3>
      <NewGeoContainer>
        <CreateGeo setNewGeoMade={setNewGeoMade} />
      </NewGeoContainer>
      <GeosTable newGeoMade={newGeoMade} setNewGeoMade={setNewGeoMade} />
    </>
  );
};

export default GeosContainer;
