import React from 'react';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { entries } from 'state/utilities/entities/cache';
import { useNamespace } from '../../router/context';

import View from './View';

export type Props = {
  disabled?: boolean;
};

export const CustomRulesContainer: React.FC<Props> = ({ disabled }) => {
  const state = useState();
  const rules = entries(state.rules?.custom) || [];
  const namespace = useNamespace();

  return (
    <div data-testid='assess-rules-custom-container'>
      <View namespace={namespace} rules={rules} disabled={disabled} />
    </div>
  );
};

export default CustomRulesContainer;
