import React, { useEffect } from 'react';
import {
  accountHasPermission,
  getParamFromUrl,
} from '@dashboard-experience/utils';
import {
  ModalType,
  parseCustomerDateParam,
  useCsvError,
  useCsvExport,
} from 'components/CsvExport';
import { useSearchState } from 'components/Search/search-context';
import { CandidateExportText } from './Filters/StyledComponents';
import CsvReportAndInvitationExportModal from '../CsvExport/CsvReportAndInvitationExport';
import {
  useCreateCandidateCsv,
  useCreateInvitationCsv,
} from '../../api/csvExport';

const DashboardCandidatesExportCsv: React.FC = () => {
  const driver_license_state = getParamFromUrl(window, 'driver_license_state');
  const { screen, open, toggle, setScreen } = useCsvExport();
  const { call: callDownloadReportCsv, result: resultDownloadReportCsv } =
    useCreateCandidateCsv(setScreen);
  const {
    call: callDownloadInvitationCsv,
    result: resultDownloadInvitationCsv,
  } = useCreateInvitationCsv(setScreen);
  const processing: boolean =
    resultDownloadReportCsv.isLoading || resultDownloadInvitationCsv.isLoading;
  const isError: boolean =
    resultDownloadReportCsv.isError || resultDownloadInvitationCsv.isError;

  const errorMsg = () => {
    return [resultDownloadReportCsv, resultDownloadInvitationCsv].find(
      r => r.isError,
    )?.error;
  };

  const { currentUser, filters } = useSearchState();

  const {
    adjudication,
    assessment,
    dob,
    geo_ids,
    invitation_status,
    monitor_type,
    packages,
    program_id,
    q,
    report_source,
    reported_after,
    reported_before,
    status,
    tags,
    archived,
  } = filters;

  const downloadCsvDisabled: boolean =
    invitation_status === 'pending' || invitation_status === 'expired';

  const geoIds = geo_ids || [];

  let filteredTags = tags || [];

  if (filteredTags === 'none') {
    filteredTags = [];
  }

  const stringTags =
    typeof filteredTags === 'string' ? filteredTags : filteredTags.join(',');

  let assessmentFilter;

  if (accountHasPermission(currentUser, 'assess_enabled')) {
    assessmentFilter = assessment;
  }

  const defaultFilters = {
    created_after: reported_after,
    created_before: reported_before,
    filter_by_invitation_status: invitation_status,
    filter_by_name: q,
    filter_by_dl_state: driver_license_state,
    filter_by_tags: stringTags,
    filter_by_geos: geoIds.join(','),
    filter_by_status: status,
    filter_by_adjudication: adjudication,
    filter_by_program: program_id,
    filter_by_packages: packages,
    filter_by_monitor_type: monitor_type,
    filter_by_dob: dob,
    filter_by_assessment: assessmentFilter,
    report_source,
    archived,
  };

  if (defaultFilters.filter_by_dob) {
    defaultFilters.filter_by_dob = parseCustomerDateParam(
      defaultFilters.filter_by_dob,
    );
  }

  useCsvError(isError, errorMsg);

  useEffect(() => {
    if (
      resultDownloadReportCsv.isSuccess ||
      resultDownloadReportCsv.isError ||
      resultDownloadInvitationCsv.isSuccess ||
      resultDownloadInvitationCsv.isError
    ) {
      resultDownloadReportCsv.reset();
      resultDownloadInvitationCsv.reset();
    }
  }, [resultDownloadReportCsv, resultDownloadInvitationCsv]);

  return (
    <>
      <CandidateExportText data-testid='download-csv-export' onClick={toggle}>
        Download CSV
      </CandidateExportText>
      {open && (
        <CsvReportAndInvitationExportModal
          filters={defaultFilters}
          open={open}
          postDownloadReportCsv={callDownloadReportCsv}
          postDownloadInvitationCsv={callDownloadInvitationCsv}
          processing={processing}
          screen={screen}
          toggle={toggle}
          type={ModalType.CandidateReports}
          downloadCsvDisabled={downloadCsvDisabled}
        />
      )}
    </>
  );
};

export default DashboardCandidatesExportCsv;
