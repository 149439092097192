import isPlainObject from 'lodash/isPlainObject';
import { Namespace } from 'modules/assess/api';
import * as api from 'modules/assess/api/category-code-set/instance';
import * as CCS from 'modules/assess/models/mvr/category-code-set';
import { useNamespace } from 'modules/assess/ui/router/context';
import { ID } from 'modules/id';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

export const useCreate = () => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = (obj: api.CreateParams) => {
    return api.create(obj);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: CCS.Entity) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        return ns === namespace && !entity;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useDestroy = (id?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return api.destroy(id);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        return !(entity as { id: unknown }).id;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useFetch = (instance?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = ['assess', namespace, 'ccs', instance];

  const fn = (): Promise<CCS.Entity> => {
    if (!instance) {
      return Promise.reject();
    }
    return api.fetch(instance);
  };

  return useQuery(key, fn, { refetchOnWindowFocus: false });
};

export const useUpdate = (instance?: ID, params?: api.UpdateParams) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = [namespace, { id: instance }];

  const request = (params?: api.UpdateParams) => {
    if (!instance) {
      return Promise.reject();
    }
    return api.update(instance, params);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: CCS.Entity) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        const eid = (entity as { id: unknown }).id;

        if (!eid) {
          return true;
        }

        if (eid !== instance) {
          return false;
        }

        return !field && !data;
      });

      if (data) {
        queryCache.setQueryData(key, data);
        queryCache.setQueryData(['assess', namespace, 'ccs', instance], data);
      }
    },
  });

  return {
    call,
    result,
  };
};
