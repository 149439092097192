import { Context, createContext, Dispatch, useContext } from 'react';
import { Actions } from 'modules/assess/ui/state/context-date';

export type Value = Dispatch<Actions.Any>;

const INITIAL: Value = (action: Actions.Any) => {};

const context: Context<Value> = createContext(INITIAL);

export const { Consumer, Provider } = context;

export const useValue = (): Value => {
  return useContext(context);
};
