import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Package, Screening } from 'types';
import includesAlias, { hasAliasScreenings } from 'utils/PackageUtils';
import { colors } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import { useFlag } from '@dashboard-experience/react-flagr';
import { INVITE_PAGE_ADD_SCREENINGS, IS_IN_IFRAME } from 'Constants';
import UIContext from 'context/UI';
import styled from 'styled-components';
import { scrollToTop, updateParentWindowUrl } from 'utils';
import { toastError } from 'actions';
import { useCreateCandidate } from 'api/invitations';
import create from 'components/Invitations/New/create';
import { useUser } from 'context/CurrentUser';
import {
  ValidateCSVPayload,
  useAddPackage,
  useUploadCSV,
  useValidateCSV,
  useManualBulkOrder,
} from 'api/packages';
import * as utils from 'components/AddScreenings/shared/utils';
import {
  GenericObject,
  useRumError,
  useRumAction,
  getParamFromUrl,
  hasPermission,
} from '@dashboard-experience/utils';
import { isEmpty } from 'lodash';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import Breadcrumbs, {
  BREADCRUMB_STEPS,
  CUSTOMIZE_WITH_ADDONS_KEY,
  GET_STARTED_KEY,
  MANUAL_ENTRY_KEY,
  REVIEW_AND_SUBMIT_KEY,
  SELECT_YOUR_PACKAGE_KEY,
  STEP_NUMBER,
  STEP_PATH,
  Steps,
  getNextStep,
} from './Breadcrumbs';
import ReviewAndSubmitOrder from './ReviewAndSubmitOrder';
import SelectPackage from './SelectYourPackage/SelectPackage';
import {
  OrderBackgroundCheckContext,
  reducer,
  initialState,
  actionTypes,
  MYSELF,
  UPLOAD,
  MANUAL,
  CANDIDATE,
  MULTIPLE_PEOPLE,
} from './Context';
import CustomizeWithAddons from './CustomizeWithAddons/CustomizeWithAddons';
import SubmitModal from './ReviewAndSubmitOrder/SubmitModal';
import FooterButtons from './FooterButtons';
import ManualOrderEntry from './ManualOrderEntry';
import {
  ALIASES,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
} from '../Amplitude/analytics';
import GetStarted from './GetStarted/GetStarted';
import {
  ALIAS_AS_AN_ADDON,
  BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY,
} from '../../../Flags';
import {
  BULK_INVITE_CSV_STORAGE_PATH,
  DEFAULT_PACKAGE_NAMES,
  Invite,
} from './SharedItems';
import UploadCandidateInfo from './UploadCandidateInfo/UploadCandidateInfo';
import { removeErrorRows } from './UploadCandidateInfo/helper';
import { AliasesEnabledType } from '../Alias/types';
import { isAddOnsEnabled } from '../shared/utils';

const Container = styled.div`
  display: inline-block;
  height: auto;
  padding: 40px;
  border: 1px solid ${colors.uiGrey300};
  border-radius: 2px;

  @media all and (max-width: 1200px) {
    width: 100%;
  }
`;

const BreadCrumbContainer = styled.div`
  margin: 32px 0;
`;

export type PricesSnapshot = {
  basePackagePrice: number;
  addonPrices: Screening[];
  aliasPrice: number;
};

export const getKeyByValue = (object: { [x: string]: any }, value: any) => {
  return Object.keys(object).find(key => object[key] === value) || undefined;
};

const addInvitesToEventProps = (
  eventProps: GenericObject,
  invites: Invite[],
) => {
  let emailCount = 0;
  let phoneCount = 0;
  invites.forEach(invite => {
    if (invite.email.isValid && invite.email.value != '') {
      emailCount += 1;
    }

    if (invite.phoneNumber.isValid && invite.phoneNumber.value != '') {
      phoneCount += 1;
    }
  });

  if (emailCount > 0) {
    eventProps['Number Of Candidate Email Entered'] = emailCount;
    eventProps['Number Of Candidate Phone Entered'] = phoneCount;
  }
};

// Pricing breakdown for amplitude may look different from Review & Submit page's breakdown
export const extractAmplitudePricing = (
  useAliasSkus: boolean,
  aliasesEnabled: AliasesEnabledType, // Whether checkbox was clicked or not
  pricesSnapshot: PricesSnapshot,
  totalBasePackagePrice: number,
  numberOfCandidates = 1,
) => {
  const packageNoAliasAdded =
    useAliasSkus === false && aliasesEnabled !== AliasesEnabledType.ON;

  const addOnPriceSum = pricesSnapshot.addonPrices?.reduce(
    (total: any, addon: any) => total + (addon.base_price || 0),
    0,
  );

  const totalAddOnsPrice = addOnPriceSum ?? 0;
  const totalAddonsPriceDollar = totalAddOnsPrice / 100;

  // AliasesEnabledType.OFF and AliasesEnableType.ACCOUNT should not include alias prices when calculating the base package price and total order price
  // even if pricesSnapshot.aliasPrice contains a value
  const aliasPrice = !packageNoAliasAdded ? pricesSnapshot.aliasPrice : 0;
  const aliasPriceDollar = aliasPrice / 100;

  // Do not use pricesSnapshot.basePackage: Current bug with incorrect price
  // Use the calculation below to get the correct basePackagePrice
  const basePackagePrice = totalBasePackagePrice - addOnPriceSum - aliasPrice;
  const basePackagePriceDollar = basePackagePrice / 100;

  const subtotalPerCandidate = packageNoAliasAdded
    ? basePackagePriceDollar + totalAddonsPriceDollar
    : basePackagePriceDollar + totalAddonsPriceDollar + aliasPriceDollar;

  const totalOrderPrice = subtotalPerCandidate * numberOfCandidates;
  const totalOrderPriceRounded = Math.round(totalOrderPrice * 100) / 100;

  return {
    basePackagePriceDollar,
    totalAddonsPriceDollar,
    aliasPriceDollar,
    totalOrderPriceRounded,
  };
};

const OrderBackgroundCheck: React.FC<any> = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toastDispatch = useDispatch();
  const { contextId }: { contextId: any } = useContext(UIContext);
  const trackEvent = useTrackEvent();
  const currentUser = useUser();
  const history: any = useHistory();
  const { call, result } = useCreateCandidate();
  const addLoadAction = useRumAction('component-load/mount');
  const addUnloadAction = useRumAction('component-unload/unmount');
  const addDataDogError = useRumError();
  const providerState = useMemo(
    () => ({
      state,
      dispatch,
      addLoadAction,
      addUnloadAction,
      addDataDogError,
    }),
    [state],
  );
  const [currentPage, setCurrentPage] = useState(BREADCRUMB_STEPS.GET_STARTED);
  const [currentStepNumber, setCurrentStep] = useState(
    GET_STARTED_KEY as Steps,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [orderCertified, setOrderCertified] = useState(false);
  const [usedGetStarted, setUsedGetStarted] = useState(false);
  const [onReviewPage, setOnReviewPage] = useState(false);
  const [serviceAgreementClicked, setServiceAgreementClicked] = useState(false);
  const [learnMoreClicked, setLearnMoreClicked] = useState(false);
  const [goBackClicked, setGoBackClicked] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const bulkInviteFlagrEnabled: boolean = !isNaN(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const aliasIsEnabled = useRef(false);

  const requiresFMCSACompliance = state.basePackage.requires_fmcsa_compliance;
  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';

  const hasAliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  const hasManageAddOnsPermission = hasPermission(
    currentUser,
    'manage_order_add_ons',
  );

  const addOnsEnabled = isAddOnsEnabled(
    isAddOnsFlagEnabled,
    requiresFMCSACompliance,
    hasManageAddOnsPermission,
  );

  const manualBulkOrderEnabled = state.manualBulkUploadType === MULTIPLE_PEOPLE;

  const {
    providerType,
    inviteMethod,
    newPackageName,
    basePackage,
    addedScreeningTypes,
    node,
    geo,
    program,
    location,
    emails,
    csv,
    saveForNextTime,
    paymentProfile,
    aliasesEnabled,
    manualBulkUploadType,
    pricesSnapshot,
  } = state;

  const trackError = useCallback(
    (error: string) => {
      trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.ERROR_VIEWED, {
        'Page Name': currentPage,
        'Error Name': error,
      });
      addDataDogError(error, {});
    },
    [trackEvent, currentUser, currentPage],
  );

  const { call: addPackage, result: addPackageResult } = useAddPackage({
    accountId: currentUser.account.id,
    onSuccess: () => {
      currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY &&
        updateFlow(REVIEW_AND_SUBMIT_KEY);
    },
    trackError,
  });
  const bulkInviteEnabled: boolean = !!csv.fileStorageKey;

  const { uploadCall, uploadResult } = useUploadCSV();
  const { validateCall } = useValidateCSV();
  const { call: manualBulkCall, result: manualBulkResult } =
    useManualBulkOrder();

  const showSkipButton: boolean = useMemo(() => {
    const isBasePackageAliaseable = hasAliasScreenings(basePackage);

    if (state.selectedGeo === 'international') {
      return (
        currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY &&
        state.addedScreeningTypes.length === 0 &&
        !manualBulkOrderEnabled &&
        !isBasePackageAliaseable
      );
    }

    const isAliasNotEnabledOrApplicable =
      state.aliasesEnabled !== AliasesEnabledType.ON ||
      !isBasePackageAliaseable ||
      includesAlias(basePackage);
    return (
      currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY &&
      state.addedScreeningTypes.length === 0 &&
      isAliasNotEnabledOrApplicable &&
      !manualBulkOrderEnabled
    );
  }, [
    basePackage,
    state.selectedGeo,
    state.aliasesEnabled,
    state.addedScreeningTypes.length,
    currentStepNumber,
    manualBulkOrderEnabled,
  ]);

  const addonsAdded: boolean = useMemo(() => {
    return addedScreeningTypes.length > 0;
  }, [addedScreeningTypes.length]);

  const updateFlow = useCallback(
    (nextStep: Steps) => {
      setCurrentPage(STEP_NUMBER[nextStep]);
      setCurrentStep(nextStep);
      updateParentWindowUrl({
        contextId,
        path: STEP_PATH[nextStep],
        reload: false,
      });
      history.push(STEP_PATH[nextStep]);
    },
    [contextId, history],
  );

  const stepFromUrl = useMemo(() => {
    const step = getKeyByValue(STEP_PATH, history.location.pathname);
    return step ? (Number(step) as Steps) : 0; // 0 is an unexisting step, need a default to prevent NaN
  }, [history.location.pathname]);

  const resetEmails = (emails: string[]) => {
    dispatch({
      type: actionTypes.ADD_EMAILS,
      payload: { emails },
    });
  };

  const resetCSV = (csv: object) => {
    dispatch({
      type: actionTypes.UPLOAD_CSV,
      payload: { csv },
    });
  };

  useEffect(() => {
    const params = getParamFromUrl(window, 'from');
    const navigatedSource =
      params === 'button' ? 'Order BGC Button Candidate Page' : 'Order BGC Tab';

    if (params) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.GET_STARTED_PAGE_VIEWED,
        {
          Source: navigatedSource,
        },
      );
    }
    if (stepFromUrl) {
      updateFlow(stepFromUrl);
    }
  }, [history, updateFlow, stepFromUrl]);

  useEffect(() => {
    (providerType === MYSELF || inviteMethod === UPLOAD) && resetEmails([]);
    (providerType === MYSELF || inviteMethod === MANUAL) && resetCSV({});
  }, [inviteMethod, providerType]);

  const [addons, setAddons] = useState([]);

  useEffect(() => {
    if (
      DEFAULT_PACKAGE_NAMES.includes(state?.basePackage?.name) &&
      addPackageResult.data &&
      currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY
    ) {
      const responseData = utils.mapPackage(addPackageResult.data);
      if (responseData?.slug !== state.basePackage?.slug) {
        dispatch({
          type: actionTypes.SET_BASE_PACKAGE,
          payload: { basePackage: responseData },
        });
      }
    }
  }, [addPackageResult.data, currentStepNumber, state?.basePackage]);

  useEffect(() => {
    // Update the basePackage on the state if the user wants to save the package for next time
    // This should ONLY fire when a user moves from Addons Page => Review & Submit Page and is tracked by onReviewPage
    if (
      (saveForNextTime ||
        addonsAdded ||
        state.aliasesEnabled === AliasesEnabledType.ON) &&
      addPackageResult.data &&
      onReviewPage
    ) {
      setAddons(addedScreeningTypes);
      const responseData = utils.mapPackage(addPackageResult.data);
      dispatch({
        type: actionTypes.SET_OLD_BASE_PACKAGE,
        payload: { oldBasePackage: state.basePackage },
      });

      dispatch({
        type: actionTypes.SET_BASE_PACKAGE,
        payload: { basePackage: responseData },
      });

      // Reset add-ons without affecting state.additionalProperties
      if (saveForNextTime) {
        dispatch({
          type: actionTypes.RESET_ADDONS,
          payload: {},
        });
      }

      // Reset the package name since we are now using the newly created package
      dispatch({
        type: actionTypes.RESET_PACKAGE_NAME,
        payload: {},
      });
    }
  }, [
    addPackageResult.data,
    saveForNextTime,
    onReviewPage,
    state.aliasesEnabled,
  ]);

  useEffect(() => {
    if (state.aliasesEnabled === AliasesEnabledType.ON) {
      aliasIsEnabled.current = true;
      trackEvent(currentUser, ALIASES.ALIAS_ADDED);
    }
    if (
      aliasIsEnabled.current &&
      state.aliasesEnabled !== AliasesEnabledType.ON
    ) {
      aliasIsEnabled.current = false;
      trackEvent(currentUser, ALIASES.ALIAS_REMOVED);
    }
  }, [currentUser, state.aliasesEnabled, trackEvent]);

  useEffect(() => {
    if (currentStepNumber === REVIEW_AND_SUBMIT_KEY) {
      setOnReviewPage(true);
    } else {
      setOnReviewPage(false);
    }
  }, [currentStepNumber]);

  const handleLearnMoreClick = useCallback(() => {
    setLearnMoreClicked(true);
  }, []);

  const handleServiceAgreementClick = useCallback(() => {
    setServiceAgreementClicked(true);
  }, []);

  const buildPostBodyWithAddOns = useCallback(() => {
    const aliasEnabledToUse = utils.getAliasEnabled(
      basePackage.aliases_enabled,
      state.aliasesEnabled,
    );
    // If we save for next time, use the new package name
    const packageName = saveForNextTime
      ? newPackageName || basePackage.name
      : `${basePackage.name} with addons`;

    return utils.buildPostBodyWithAddOnsAndAlias({
      basePackage: state.basePackage,
      addedScreeningTypes: state.addedScreeningTypes,
      additionalProperties: state.additionalProperties,
      packageName,
      setSlug: saveForNextTime,
      isPrivate: !saveForNextTime,
      aliasesEnabled: !hasAliasScreenings(basePackage)
        ? AliasesEnabledType.OFF
        : aliasEnabledToUse,
    });
  }, [basePackage, state, saveForNextTime, newPackageName]);

  const buildDefaultPackagePostBodyWithAddons = useCallback(() => {
    // If we save for next time, use the new package name
    const packageName = basePackage.name;

    return utils.buildPostBodyWithAddOnsAndAlias({
      ...state,
      packageName,
      setSlug: false,
      isPrivate: true,
      aliasesEnabled: AliasesEnabledType.OFF,
    });
  }, [state, basePackage.name]);

  const createNewPackage = useCallback(() => {
    const body = buildPostBodyWithAddOns();
    addPackage(body);
  }, [buildPostBodyWithAddOns, addPackage]);

  const createNewDefaultPackage = useCallback(() => {
    const body = buildDefaultPackagePostBodyWithAddons();
    addPackage(body);
  }, [buildDefaultPackagePostBodyWithAddons, addPackage]);

  const step1AmplitudeEventProperties: GenericObject =
    useMemo((): GenericObject => {
      const eventProps: GenericObject = {
        Country: state?.location?.country || state?.geo?.country,
        State: state?.location?.state,
        City: state?.location?.city,
        'Candidate Info Provider': state.providerType,
        'Number Of Candidate Email Entered': state?.emails?.length,
      };
      addInvitesToEventProps(eventProps, state.invites);

      if (bulkInviteFlagrEnabled) {
        if (providerType === CANDIDATE) {
          if (inviteMethod === MANUAL) {
            return { ...eventProps, 'Email Entered Method': 'Enter Manually' };
          }
          return { ...eventProps, 'Email Entered Method': 'Upload CSV' };
        }
      }
      return eventProps;
    }, [
      bulkInviteFlagrEnabled,
      inviteMethod,
      providerType,
      state?.emails?.length,
      state?.geo?.country,
      state?.location?.city,
      state?.location?.country,
      state?.location?.state,
      state.providerType,
      state.invites,
    ]);

  const onSuccessCallback = useCallback(() => {
    updateFlow(SELECT_YOUR_PACKAGE_KEY);
  }, [updateFlow]);

  const shouldValidate = !isEmpty(csv) && bulkInviteEnabled;

  const handleContinueClick = useCallback(() => {
    if (currentStepNumber === GET_STARTED_KEY) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.GET_STARTED_PAGE_COMPLETED,
        step1AmplitudeEventProperties,
      );
      if (shouldValidate) {
        const validatePayload: ValidateCSVPayload = {
          accountId: currentUser.account.id,
          uploaded_list_type: BULK_INVITE_CSV_STORAGE_PATH,
          raw_csv_file_s3_path: csv.fileStorageKey,
          upload_params: {
            geo_id: geo.id,
            fileDisplayName: csv.fileDisplayName,
          },
        };

        validateCall({ validatePayload, onSuccessCallback });
      } else {
        dispatch({
          type: actionTypes.ADD_INVITES,
          payload: state.invites.filter(
            (invite: Invite) =>
              !(
                !invite.email.value &&
                !invite.firstName.value &&
                !invite.phoneNumber.value
              ),
          ),
        });
        updateFlow(SELECT_YOUR_PACKAGE_KEY);
      }
    }
    if (currentStepNumber === SELECT_YOUR_PACKAGE_KEY) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.SELECT_PACKAGE_PAGE_COMPLETED,
        {
          'Package Selected': state.basePackage?.name,
          'Search Used': state.selectPackageAmplitudeData.searchUsed
            ? 'Yes'
            : 'No',
          'Number of Pages Viewed':
            state.selectPackageAmplitudeData.numberOfPagesViewed,
        },
      );

      if (state?.basePackage?.is_default_package) {
        createNewDefaultPackage();
      }

      if (state.basePackage.slug !== state.selectedPackage.slug) {
        // Package changed from the previous selection. Customize page should be cleaned up
        dispatch({ type: actionTypes.RESET_CUSTOMIZE_PAGE, payload: {} });
      }

      dispatch({
        type: actionTypes.SET_SELECTED_PACKAGE,
        payload: { selectedPackage: state.basePackage },
      });

      const isSelectedBasePackageAliasable = hasAliasScreenings(
        state.basePackage as any as Package,
      );

      const aliasesEnabled =
        isSelectedBasePackageAliasable && hasAliasFlag
          ? AliasesEnabledType.ON
          : AliasesEnabledType.OFF;

      dispatch({
        type: actionTypes.SET_ALIASES_ENABLED,
        payload: { aliasesEnabled },
      });

      if (manualBulkOrderEnabled) {
        updateFlow(CUSTOMIZE_WITH_ADDONS_KEY);
      } else {
        addOnsEnabled
          ? updateFlow(CUSTOMIZE_WITH_ADDONS_KEY)
          : updateFlow(REVIEW_AND_SUBMIT_KEY);
      }
    }
    if (currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY) {
      if (manualBulkOrderEnabled) {
        if (!state.manualBulkUploadData.isValid) {
          const clearData = removeErrorRows(state.manualBulkUploadData);

          dispatch({
            type: actionTypes.SET_MANUAL_BULK_UPLOAD_DATA,
            payload: {
              manualBulkUploadData: clearData,
            },
          });
        }

        updateFlow(REVIEW_AND_SUBMIT_KEY);
        return;
      }
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.CUSTOMIZE_WITH_ADDONS_COMPLETED,
        {
          'Completion Type': addonsAdded ? 'Continue' : 'Skip',
          'Add-on Screenings': state.addedScreeningTypes,
          'Package Saved':
            state.newPackageName !== '' && !state.newPackageNameInvalid
              ? 'Yes'
              : 'No',
          'New Package Name': state.newPackageName,
          'International Pricing Page Clicked':
            state.internationalPriceLinkClicked,
        },
      );

      // Create a new package is the user wants to save the package for next time
      // If save for next is NOT checked, but additional screenings are added as an update
      if (
        saveForNextTime ||
        addonsAdded ||
        state.aliasesEnabled !== AliasesEnabledType.OFF
      ) {
        createNewPackage();
      } else {
        updateFlow(REVIEW_AND_SUBMIT_KEY);
      }
    }
    if (currentStepNumber === REVIEW_AND_SUBMIT_KEY) {
      updateFlow(MANUAL_ENTRY_KEY);
    }
  }, [
    currentStepNumber,
    currentUser,
    state.addedScreeningTypes,
    state.basePackage?.name,
    state?.emails?.length,
    state.internationalPriceLinkClicked,
    state?.location?.city,
    state?.location?.country,
    state?.location?.state,
    state.newPackageName,
    state.newPackageNameInvalid,
    state.providerType,
    state.selectPackageAmplitudeData.numberOfPagesViewed,
    state.selectPackageAmplitudeData.searchUsed,
    trackEvent,
    updateFlow,
    createNewPackage,
    saveForNextTime,
    validateCall,
    onSuccessCallback,
    state.aliasesEnabled,
  ]);

  const handleBackClick = useCallback(() => {
    setSubmitClicked && setSubmitClicked(false);
    if (currentStepNumber === MANUAL_ENTRY_KEY) {
      updateFlow(REVIEW_AND_SUBMIT_KEY);
    }
    if (currentStepNumber === REVIEW_AND_SUBMIT_KEY) {
      const newPrivatePackageCreated =
        state.basePackage.name.includes('with addons');
      if (newPrivatePackageCreated) {
        dispatch({
          type: actionTypes.SET_BASE_PACKAGE,
          payload: { basePackage: state.oldBasePackage },
        });
        dispatch({
          type: actionTypes.SET_OLD_BASE_PACKAGE,
          payload: { oldBasePackage: {} },
        });
      }

      if (manualBulkOrderEnabled) {
        updateFlow(CUSTOMIZE_WITH_ADDONS_KEY);
      } else {
        addOnsEnabled
          ? updateFlow(CUSTOMIZE_WITH_ADDONS_KEY)
          : updateFlow(SELECT_YOUR_PACKAGE_KEY);
      }
    }
    if (currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY) {
      updateFlow(SELECT_YOUR_PACKAGE_KEY);
    }
    if (currentStepNumber === SELECT_YOUR_PACKAGE_KEY) {
      updateFlow(GET_STARTED_KEY);
      setGoBackClicked(true);
      dispatch({
        type: actionTypes.SET_BASE_PACKAGE,
        payload: { basePackage: {} },
      });
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.GET_STARTED_PAGE_VIEWED,
        {
          Source: 'Back Button',
        },
      );
    }

    const eventProps: GenericObject = {
      'Page Name': currentPage,
      'Package Name': state.newPackageName || state.basePackage.name,
      'Search Used': state.selectPackageAmplitudeData.searchUsed,
      'Number of Pages Viewed':
        state.selectPackageAmplitudeData.numberOfPagesViewed,
      'Completion Type': addonsAdded ? 'Continue' : 'Skip',
      'Add-on Screenings': state.addedScreeningTypes,
      'Package Saved': state.saveForNextTime ? 'Yes' : 'No',
      'New Package Name': state.newPackageName,
      'International Pricing Page Clicked': state.internationalPriceLinkClicked,
      'Candidate Email Changed': state.emails.length > 0 ? 'Yes' : 'No',
      'Number Of Candidate Email Entered': state.emails.length,
    };
    addInvitesToEventProps(eventProps, state.invites);
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.GO_BACK_CLICKED,
      eventProps,
    );
  }, [
    currentPage,
    currentStepNumber,
    currentUser,
    state.addedScreeningTypes,
    state.basePackage,
    state.emails.length,
    state.csv,
    state.internationalPriceLinkClicked,
    state.newPackageName,
    state.saveForNextTime,
    state.selectPackageAmplitudeData.numberOfPagesViewed,
    state.selectPackageAmplitudeData.searchUsed,
    trackEvent,
    updateFlow,
  ]);

  const handleSkipClick = useCallback(() => {
    if (currentStepNumber === CUSTOMIZE_WITH_ADDONS_KEY) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.CUSTOMIZE_WITH_ADDONS_COMPLETED,
        {
          'Completion Type': addonsAdded ? 'Continue' : 'Skip',
          'Add-on Screenings': state.addedScreeningTypes,
          'Package Saved':
            state.newPackageName !== '' && !state.newPackageNameInvalid
              ? 'Yes'
              : 'No',
          'New Package Name': state.newPackageName,
          'International Pricing Page Clicked':
            state.internationalPriceLinkClicked,
        },
      );
    }

    dispatch({
      type: actionTypes.RESET_PACKAGE_NAME,
      payload: {},
    });

    updateFlow(getNextStep(currentStepNumber));
  }, [
    currentStepNumber,
    currentUser,
    state.addedScreeningTypes,
    state.internationalPriceLinkClicked,
    state.newPackageName,
    state.newPackageNameInvalid,
    trackEvent,
    updateFlow,
  ]);

  useEffect(() => {
    if (uploadResult.isError) {
      toastDispatch(toastError(`ERROR: ${uploadResult.error.message}`));
      trackError(uploadResult.error.name);
      return;
    }

    if (uploadResult.isSuccess) {
      setModalOpen(true);
      scrollToTop();
    }
  }, [toastDispatch, trackError, uploadResult.isError, uploadResult.isSuccess]);

  useEffect(() => {
    if (result.isError) {
      toastDispatch(toastError(`ERROR: ${result.error?.message}`));
      trackError(result.error.name);
      result.reset();
      return;
    }

    if (result.isSuccess) {
      setModalOpen(true);
      scrollToTop();
      dispatch({
        type: actionTypes.RESET_ALL,
        payload: {},
      });
      result.reset();
    }
  }, [result, toastDispatch, trackError]);

  useEffect(() => {
    if (manualBulkResult.isSuccess) {
      setModalOpen(true);
      scrollToTop();
      dispatch({
        type: actionTypes.RESET_ALL,
        payload: {},
      });
      manualBulkResult.reset();
    }
  }, [manualBulkResult]);

  const handleSubmit = useCallback(() => {
    const initialBasePackageScreenings =
      state.saveForNextTime && state.oldBasePackage?.screenings?.length
        ? state.oldBasePackage.screenings
        : state.basePackage.screenings;
    const initialAddons = state.saveForNextTime
      ? state.oldBasePackageAddedScreeningTypes
      : state.addedScreeningTypes;
    const originalBasePackageScreenings = initialBasePackageScreenings
      .filter(
        (screening: { type: ScreeningType }) =>
          !initialAddons.includes(screening.type),
      )
      .map((screening: { type: ScreeningType }) => screening.type);

    const eventProps = {
      'Checkr Service Agreement Clicked': serviceAgreementClicked
        ? 'Yes'
        : 'No',
      'Learn More Selected': learnMoreClicked ? 'Yes' : 'No',
      'Certification Button Clicked': 'Yes',
      'Number Of Candidate Email Entered': emails.length,
      'Original Package Name':
        state.oldBasePackage.name ?? state.basePackage.name,
      'Original Package Screenings': originalBasePackageScreenings.join(', '),
      'Original Package Price': 0,
      'Original Package ID': state.oldBasePackage.id,
      'Package Resource ID': state.basePackage.id,
      'Add-Ons Screenings': initialAddons.join(', '),
      'Add-on Price': 0,
      'Alias Price': 0,
      'Total Order Price': 0,
      'New Package Name': state.saveForNextTime ? state.basePackage.name : '',
      'Package Saved': state.saveForNextTime ? 'Yes' : 'No',
      'Aliases Enabled':
        state.aliasesEnabled === AliasesEnabledType.ON ||
        state.basePackage.use_alias_skus
          ? 'Yes'
          : 'No',
      'Order Type': providerType === 'MYSELF' ? 'Manual' : 'Non-manual',
      'Manual Bulk Upload Type':
        manualBulkUploadType === 'MULTIPLE' ? 'Multiple' : 'Single',
      'Recommended Package Selected': state.recommendedPackageSelected
        ? 'Yes'
        : 'No',
      'Recommended Package Type': state.recommendedPackageType ?? '',
      'Is First Order': currentUser?.account?.has_report_or_invitation
        ? 'No'
        : 'Yes',
    };
    addInvitesToEventProps(eventProps, state.invites);

    // All email state updates for bulk uploads (manual & invite) are handled upon successful file uploads
    // Manual uploads for a single person do not update the email's state
    const numberOfCandidatesFromState =
      eventProps['Number Of Candidate Email Entered'];
    const singleManualUpload =
      providerType === 'MYSELF' && numberOfCandidatesFromState === 0;
    const numberOfCandidates = singleManualUpload
      ? 1
      : numberOfCandidatesFromState;
    eventProps['Number Of Candidate Email Entered'] = numberOfCandidates;

    const {
      basePackagePriceDollar,
      totalAddonsPriceDollar,
      aliasPriceDollar,
      totalOrderPriceRounded,
    } = extractAmplitudePricing(
      basePackage.use_alias_skus,
      aliasesEnabled,
      pricesSnapshot,
      basePackage.price,
      numberOfCandidates,
    );

    eventProps['Original Package Price'] = basePackagePriceDollar;
    eventProps['Add-on Price'] = totalAddonsPriceDollar;
    eventProps['Alias Price'] = aliasPriceDollar;
    eventProps['Total Order Price'] = totalOrderPriceRounded;

    if (addonsAdded) {
      createNewPackage();
    }

    !submitClicked && setSubmitClicked(true);

    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_COMPLETED,
      eventProps,
    );
    if (state.aliasesEnabled !== AliasesEnabledType.ON) {
      trackEvent(currentUser, ALIASES.ALIAS_NOT_ORDERED);
    }
    if (providerType === 'MYSELF' && !manualBulkOrderEnabled) {
      updateFlow(MANUAL_ENTRY_KEY);
      return;
    }

    const currentAlias =
      basePackage.aliases_enabled !== AliasesEnabledType.OFF &&
      state.aliasesEnabled === AliasesEnabledType.OFF
        ? basePackage.aliases_enabled
        : AliasesEnabledType.OFF;

    create({
      selection: {
        node,
        geo,
        program,
        country: location.country,
        city: location.city,
        state: location.state,
        package: { ...basePackage, aliases_enabled: currentAlias },
        payment_profile: paymentProfile,
      },
      emails,
      account: currentUser.account,
      addons,
      call,
      setGeoError: () => {},
      setPermissionError: () => {},
      bulkInviteEnabled,
      csv,
      currentUser,
      uploadCall,
      manualBulkCall,
      manualBulkOrderEnabled,
      manualBulkUploadData: state.manualBulkUploadData,
      invites: state.invites,
    });
  }, [
    bulkInviteEnabled,
    providerType,
    currentUser,
    result,
    addPackageResult,
    call,
    uploadCall,
    csv,
    toastDispatch,
    trackError,
    node,
    geo,
    program,
    paymentProfile,
    location.country,
    location.city,
    location.state,
    basePackage,
    emails,
    saveForNextTime,
    addedScreeningTypes.length,
    updateFlow,
    createNewPackage,
    manualBulkCall,
    manualBulkOrderEnabled,
    state,
    state.aliasesEnabled,
  ]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (!usedGetStarted && history.location.pathname !== '/order/get-started') {
      updateFlow(GET_STARTED_KEY);
    }
    !usedGetStarted && setUsedGetStarted(true);
  }, [history.location.pathname, updateFlow, usedGetStarted]);

  useEffect(() => {
    // reset submitClicked if we go to previous step or click certify checkbox
    submitClicked && setSubmitClicked(false);
  }, [history.location.pathname, orderCertified]);

  const redirectPage = useCallback(
    (path: string, reload: boolean) => {
      if (IS_IN_IFRAME && contextId) {
        updateParentWindowUrl({
          contextId,
          path,
          reload,
        });
      } else {
        window.location.href = path;
      }
      if (path === '/order/get-started') {
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.GET_STARTED_PAGE_VIEWED,
          {
            Source: 'Order new check',
          },
        );
      }
    },
    [contextId, currentUser, trackEvent],
  );

  const isCreatePackageSubmitting =
    currentStepNumber === 3 &&
    addPackageResult?.isLoading &&
    !manualBulkOrderEnabled; // manual bulk order has a different 3rd step

  const continueButtonDisabled =
    stepFromUrl > 4 ||
    isCreatePackageSubmitting ||
    state.continueButtonDisabled;

  return (
    <OrderBackgroundCheckContext.Provider value={providerState}>
      {modalOpen && (
        <SubmitModal
          open={modalOpen}
          bulkInviteEnabled={bulkInviteEnabled}
          manualBulkOrderEnabled={manualBulkOrderEnabled}
          handleClose={handleModalClose}
          currentUser={currentUser}
          redirectPage={redirectPage}
        />
      )}
      {currentStepNumber === MANUAL_ENTRY_KEY ? (
        <Route exact path={STEP_PATH[5]}>
          <ManualOrderEntry updateFlow={updateFlow} />
        </Route>
      ) : (
        <>
          <BreadCrumbContainer>
            <Breadcrumbs
              currentPage={currentPage}
              currentStepNumber={currentStepNumber}
              addOnsEnabled={addOnsEnabled}
              manualBulkOrderEnabled={manualBulkOrderEnabled}
            />
          </BreadCrumbContainer>
          <Container>
            <Route exact path={STEP_PATH[1]}>
              <GetStarted
                goBackClicked={goBackClicked}
                redirectPage={redirectPage}
              />
            </Route>
            <Route exact path={STEP_PATH[2]}>
              <SelectPackage />
            </Route>
            {manualBulkOrderEnabled ? (
              <Route
                exact
                path={STEP_PATH[3]}
                component={UploadCandidateInfo}
              />
            ) : (
              <Route
                exact
                path={STEP_PATH[3]}
                component={CustomizeWithAddons}
              />
            )}
            <Route exact path={STEP_PATH[4]}>
              {state.basePackage.name && (
                <ReviewAndSubmitOrder
                  orderCertified={orderCertified}
                  setOrderCertified={setOrderCertified}
                  contextId={contextId}
                  handleLearnMoreClick={handleLearnMoreClick}
                  handleServiceAgreementClick={handleServiceAgreementClick}
                  setSubmitClicked={setSubmitClicked}
                />
              )}
            </Route>

            <FooterButtons
              backButton={{
                visible: stepFromUrl > 1,
                onClick: handleBackClick,
              }}
              skipButton={{
                visible: showSkipButton,
                onClick: handleSkipClick,
                disabled: isCreatePackageSubmitting,
              }}
              submitButton={{
                visible: stepFromUrl === 4,
                disabled:
                  !orderCertified ||
                  result.isLoading ||
                  result.isSuccess ||
                  result.isError ||
                  submitClicked,
                onClick: handleSubmit,
              }}
              continueButton={{
                visible: !showSkipButton && stepFromUrl !== 4,
                disabled: continueButtonDisabled,
                onClick: handleContinueClick,
                label:
                  !state.continueButtonDisabled &&
                  manualBulkOrderEnabled &&
                  stepFromUrl === 3 &&
                  !state.manualBulkUploadData.isValid
                    ? 'Remove rows and continue'
                    : 'Continue',
              }}
            />
          </Container>
        </>
      )}
    </OrderBackgroundCheckContext.Provider>
  );
};

export default OrderBackgroundCheck;
