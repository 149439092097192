import React from 'react';
import { MotorVehicleReport, GenericObject } from 'types';
import {
  getResultOrStatus,
  getScreeningStatus,
  underscoreToHuman,
  formatDateIfDate,
  AssessStatuses,
} from '@dashboard-experience/utils';
import _ from 'lodash';
import { STATUS_LABELS } from 'Constants';
import Note from './Note';
import ItemKeyWithTooltip from './ItemKeyWithTooltip';

const ADDITIONAL_INFO = [
  'alternate_description',
  'acd_code',
  'category',
  'assessment',
];

export const getCDLISNotFound = (screening: MotorVehicleReport) => {
  return screening?.not_found && screening.cdlis_record === null;
};

export const getHasCustomRules = (screening: MotorVehicleReport) => {
  return !!(
    screening?.custom_rules && Object.keys(screening.custom_rules).length
  );
};

export const getHasSuspensionReason = (screening: MotorVehicleReport) =>
  getResultOrStatus(screening) === 'suspended' && screening?.suspension_reason;

export const getExperienceRuleFailed = (screening: MotorVehicleReport) => {
  return screening?.experience_failed;
};

export const getStatusDecoratorText = (screening: MotorVehicleReport) => {
  const cdlisNotFound = getCDLISNotFound(screening);
  const currentLicense = screening.license_reports?.find(
    license => license.current_license,
  );
  const notFound =
    (currentLicense && isNotFound(currentLicense)) || cdlisNotFound;
  const isInsufficient = isInsufficientDrivingHistory(screening);
  if (notFound) return ' Not Found';
  if (isInsufficient) return ' Insufficient driving history';
  return '';
};

export const isInsufficientDrivingHistory = (screening: MotorVehicleReport) => {
  return (
    getExperienceRuleFailed(screening) ||
    (getHasSuspensionReason(screening) &&
      screening.suspension_reason ===
        'license_history_is_only_non_eligible_assessed_mvr_item')
  );
};

const licenseVerificationTags = (report: GenericObject) => {
  if (report.not_found) {
    return ['not_found'];
  }

  if (!report.status) {
    return [];
  }

  const mismatchTags = [];
  if (report.name_matches === false) {
    mismatchTags.push('name_mismatch');
  }
  if (report.dob_matches === false) {
    mismatchTags.push('dob_mismatch');
  }
  return mismatchTags;
};

export const hasNameMismatch = (report: GenericObject) => {
  return licenseVerificationTags(report).includes('name_mismatch');
};

export const hasDobMismatch = (report: GenericObject) => {
  return licenseVerificationTags(report).includes('dob_mismatch');
};

export const isNotFound = (report: GenericObject) => {
  return licenseVerificationTags(report).includes('not_found');
};

export const getDisplayCovidNotice = (report: GenericObject) => {
  return report.covid_extension;
};

export const isAdditionalInfo = (attribute: string) => {
  return _.includes(ADDITIONAL_INFO, attribute);
};

export const isAssessment = (attribute: string) => {
  return attribute === 'assessment';
};

export const getAccidentItems = (accident: GenericObject) => {
  const items = Object.keys(accident)
    .filter(parameter => {
      return (
        accident[parameter] && parameter !== 'group' && !isAssessment(parameter)
      );
    })
    .map(parameter => {
      let itemValue = null;
      const itemKeyValue = underscoreToHuman(parameter) || null;
      if (accident[parameter])
        itemValue = formatDateIfDate(accident[parameter]);
      if (parameter === 'note') itemValue = <Note>{itemValue}</Note>;
      return {
        itemKey: itemKeyValue,
        itemValue,
      };
    });

  return items;
};

export const getSuspensionReasonMessage = (
  suspensionReason: string | null | undefined,
) => {
  switch (suspensionReason) {
    case 'license_history_is_only_non_eligible_assessed_mvr_item':
      return 'This report is suspended because the license provided by the candidate did not provide sufficient driving history.';
    case 'not_found_license_status':
      return 'This report is suspended because the license provided by the candidate could not be found at the relevant DMV.';
    default:
      return null;
  }
};

export const getMisMatchOrNotFoundText = (licenseReport: GenericObject) => {
  let textString = '';
  if (hasNameMismatch(licenseReport)) textString += ' Name Mismatch';
  if (hasDobMismatch(licenseReport)) textString += ' Dob Mismatch';
  if (isNotFound(licenseReport)) textString += ' Not Found';
  return textString;
};

export const getLicenseReportItems = (licenseReportOrCharge: GenericObject) => {
  return [
    licenseReportOrCharge.inferred_issued_date && {
      itemKey: 'License issued date (inferred)',
      itemValue: formatDateIfDate(licenseReportOrCharge.inferred_issued_date),
    },
    licenseReportOrCharge.transcribed_issued_date && {
      itemKey: <ItemKeyWithTooltip itemType='transcribed_issued_date' />,
      itemValue:
        formatDateIfDate(licenseReportOrCharge.transcribed_issued_date) || '-',
    },
    licenseReportOrCharge.privilege_to_drive && {
      itemKey: <ItemKeyWithTooltip itemType='privilege_to_drive' />,
      itemValue:
        formatDateIfDate(licenseReportOrCharge.privilege_to_drive) || '-',
    },
    licenseReportOrCharge.source && {
      itemKey: 'Source',
      itemValue: licenseReportOrCharge.source,
    },
    licenseReportOrCharge.covid_extension && {
      itemKey: 'COVID Extension',
      itemValue: licenseReportOrCharge.covid_extension.toString(),
    },
  ].filter(Boolean);
};

export const getViolationItems = (
  licenseReportOrCharge: GenericObject,
  assessMvrEnabled: boolean,
) => {
  return Object.keys(licenseReportOrCharge)
    .filter(parameter => {
      return (
        licenseReportOrCharge[parameter] &&
        isAdditionalInfo(parameter) &&
        !(parameter === 'category' && assessMvrEnabled)
      );
    })
    .map(parameter => {
      let itemKeyValue = null;
      let itemValue = null;
      if (
        licenseReportOrCharge[parameter] &&
        isAdditionalInfo(parameter) &&
        !isAssessment(parameter)
      )
        itemKeyValue = underscoreToHuman(parameter);
      if (
        licenseReportOrCharge[parameter] &&
        isAdditionalInfo(parameter) &&
        !isAssessment(parameter)
      )
        itemValue = formatDateIfDate(licenseReportOrCharge[parameter]);
      return { itemKey: itemKeyValue, itemValue };
    });
};

export const allMvrRulesSatisfied = (mvrReport: MotorVehicleReport) => {
  return getHasCustomRules(mvrReport) && !!mvrReport.custom_rules
    ? Object.values(mvrReport.custom_rules).every((rule: GenericObject) => {
        return rule.satisfied;
      })
    : undefined;
};

export const getCustomFiltersStatusOrAssessment = (
  mvrReport: MotorVehicleReport,
  reportStatusType: string,
) => {
  const screeningStatus = getScreeningStatus(mvrReport, reportStatusType);

  if (screeningStatus) return screeningStatus;
  return allMvrRulesSatisfied(mvrReport) ? 'clear' : 'consider';
};

export const assessUnsatisfiedRuleClass = (assessment: string) => {
  const { does_not_meet_criteria, meets_criteria } = STATUS_LABELS;

  switch (assessment) {
    case meets_criteria:
      return 'success';
    case does_not_meet_criteria:
      return 'consider';
    default:
      return 'review';
  }
};

export const getUnsatisfiedRuleClass = (
  mvrReport: MotorVehicleReport,
  reportStatusType: string,
  assessEnabled: boolean,
) => {
  const assessment = getScreeningStatus(mvrReport, reportStatusType);

  if (assessEnabled) return assessment || AssessStatuses.ESCALATED;

  return assessment ? assessUnsatisfiedRuleClass(assessment) : 'label-consider';
};

export const getMvrRuleIconStatus = (
  mvrReport: MotorVehicleReport,
  reportStatusType: string,
) => {
  return getScreeningStatus(mvrReport, reportStatusType);
};
