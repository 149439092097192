/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import {
  useTrackEvent,
  PACKAGE_BUILDER_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';
import UserContext from 'context/CurrentUser';
import { LoadingScreen } from 'components/Account';
import ContextContainer from 'containers/ContextContainer';
import { PackagesContainer } from 'containers/Packages';
import UIContext from 'context/UI';
import { Provider } from 'components/Invitations';
import { useLocation } from 'react-router-dom';
import { removeParamFromUrl } from '@dashboard-experience/utils';
import Header from './Header';

const PackagesPage: React.FC = () => {
  const currentUser = useContext(UserContext);
  const { contextId } = useContext(UIContext);
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isRedirectedFromHomePage = searchParams.get('source') === 'home';

  useEffect(() => {
    const startBuildPackageGuide = () => {
      // @ts-ignore
      window.pendo &&
        // @ts-ignore
        window.pendo.showGuideById('fkgXCXq8tRalEOyU0SKg0MycI6k', {
          stepId: '3LSj6WrG5L0FLdcfxUhoJsS30aM',
        });
    };

    if (isRedirectedFromHomePage) {
      startBuildPackageGuide();
      removeParamFromUrl(window, 'source');
    }
  }, [isRedirectedFromHomePage, location]);

  const parentWindowNavigationBackToPackagesTableComplete = contextId
    ? contextId?.includes('packages') && !contextId?.includes('edit')
    : true;

  useEffect(() => {
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_PACKAGE_PAGE_VIEWED,
    );
  }, [currentUser, trackEvent]);

  return (
    <>
      {parentWindowNavigationBackToPackagesTableComplete &&
        (currentUser?.account?.id ? (
          <>
            <Header contextId={contextId} />
            <Provider account={currentUser?.account}>
              <PackagesContainer currentUser={currentUser} />
            </Provider>
          </>
        ) : (
          <LoadingScreen />
        ))}
    </>
  );
};

export default ContextContainer(PackagesPage);
