import { normalize } from 'normalizr';
import { reportSchema } from 'Schema';

import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
} from 'actions/ActionTypes';

import { httpHelper } from './reducerHelpers';

export const initialState = {
  fetchRequest: { ...httpHelper.initialState },

  // TODO: Is this really supposed to be pluralized?
  reports: {},
};

// eslint-disable-next-line default-param-last
const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORT_REQUEST:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onRequest },
      };

    case FETCH_REPORT_SUCCESS: {
      const { report } = action;

      return {
        ...state,
        fetchRequest: { ...httpHelper.onSuccess },

        ...normalize(report, reportSchema).entities,
      };
    }
    case FETCH_REPORT_FAILURE:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onFailure },
      };

    default:
      return state;
  }
};

export default reportsReducer;
