import React from 'react';
import PropTypes from 'prop-types';
import { M, TrashCan } from '@dashboard-experience/mastodon';

const WebhookToolbar = ({
  getToolbarProps,
  getBatchActionProps,
  selectedRows,
  batchDelete,
  onInputChange,
}) => (
  <M.TableToolbar {...getToolbarProps()}>
    <M.TableBatchActions {...getBatchActionProps()}>
      <M.TableBatchAction
        tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
        renderIcon={TrashCan}
        onClick={batchDelete(selectedRows)}
      >
        Delete
      </M.TableBatchAction>
    </M.TableBatchActions>
    <M.TableToolbarContent>
      <M.TableToolbarSearch
        tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
        onChange={onInputChange}
      />
    </M.TableToolbarContent>
  </M.TableToolbar>
);

WebhookToolbar.propTypes = {
  getToolbarProps: PropTypes.func.isRequired,
  getBatchActionProps: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  batchDelete: PropTypes.func.isRequired,
};

export default WebhookToolbar;
