// eslint-disable-next-line import/prefer-default-export
export const DATE_TYPES = [
  'accident_date',
  'arrest_date',
  'charge_date',
  'conviction_date',
  'deposition_date',
  'disposition_date',
  'file_date',
  'issued_date',
  'offense_date',
  'release_date',
  'sentence_date',
  'suspension_start_date',
  'suspension_end_date',
];
