import React from 'react';
import { TaxExemptionCertificate } from 'api/payment';

type Props = {
  taxExemptionCertificates: Array<TaxExemptionCertificate>;
};

const TaxExemptionCertificateDetails: React.FC<Props> = ({
  taxExemptionCertificates,
}) => {
  return (
    <>
      {taxExemptionCertificates.map((certificate: TaxExemptionCertificate) => (
        <div key={certificate.id}>
          <div>{certificate.filename}</div>
          <div>{certificate.status}</div>
          <div>{certificate.expiration_date}</div>
        </div>
      ))}
    </>
  );
};

export default TaxExemptionCertificateDetails;
