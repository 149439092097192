import React from 'react';
import Content from 'modules/assess/ui/ruleset/version/insights/Content';
import { ID } from 'modules/id';

type Props = {
  version?: ID;
};

export const Container: React.FC<Props> = ({ version }) => {
  return <Content version={version} />;
};

export default Container;
