import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'select-package-container',
})`
  display: flex;
  padding: 0 64px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
`;

export const Heading = styled.div.attrs({
  className: 'select-package-heading',
})`
  align-self: flex-start;
  color: ${colors.uiTextPrimaryLight};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`;

export const SubHeadingContainer = styled.div.attrs({
  className: 'select-package-subheading-container',
})`
  display: flex;
  justify-content: space-between;
`;

export const SubHeading = styled.div.attrs({
  className: 'select-package-subheading',
})`
  color: ${colors.uiTextSecondaryLight};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const Footnote = styled.div.attrs({
  className: 'select-package-footnote',
})`
  color: ${colors.uiTextTertiaryLight};
  padding-top: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const NoUnderlineLink = styled.a.attrs({
  className: 'select-package-no-underline-link',
})`
  text-decoration: none;
`;

export const CenteredIcon = styled(M.Icon).attrs({
  className: 'select-package-centered-icon',
})<{ color: string }>`
  color: ${props => props.color};
  margin-bottom: -3px;
`;
