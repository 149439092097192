import React, { useEffect, useState } from 'react';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import InternationalAddScreeningsList from 'components/AddScreenings/International/List';
import DomesticAddScreeningsList from 'components/AddScreenings/Domestic/List';
import { screeningTypes } from 'components/AddScreenings/shared/screeningPayloads';
import ScreeningType from './enums/ScreeningType';

export const getDisabledScreenings = (basePackageScreeningTypes: string[]) => {
  return screeningTypes.filter(screeningType =>
    basePackageScreeningTypes.includes(screeningType),
  );
};

type Props = {
  basePackage: BasePackage;
  previouslyOrderedScreenings?: ScreeningType[];
  onAddClick: Function;
  onRemoveClick: Function;
  addedScreenings: Array<any>;
  additionalProperties: Object;
  account: Object;
  basePackageScreeningTypes: Array<any>;
  selectedGeo: string;
  allAddOnPricesQuery: any;
  screeningSettingPrice: string[];
};

const AddScreeningsList: React.FC<Props> = ({
  basePackage,
  previouslyOrderedScreenings, // only used in add checks
  onAddClick,
  onRemoveClick,
  addedScreenings,
  additionalProperties,
  account,
  basePackageScreeningTypes,
  selectedGeo,
  allAddOnPricesQuery,
  screeningSettingPrice,
}) => {
  const [initialAdditionalProperties, setInitialAdditionalProperties] =
    useState({});

  const disabledScreenings = getDisabledScreenings(basePackageScreeningTypes);

  useEffect(() => {
    let additionalProperties = {};

    const [facisScreening] = basePackage.screenings.filter(
      screening => screening.type === 'facis_search',
    );
    const [employmentVerification] = basePackage.screenings.filter(
      screening => screening.type === 'employment_verification',
    );
    const [personalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'personal_reference_verification',
    );
    const [professionalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'professional_reference_verification',
    );

    if (facisScreening?.type && 'subtype' in facisScreening) {
      additionalProperties = {
        ...additionalProperties,
        facis_search: { subtype: facisScreening.subtype },
      };
    }

    if (
      employmentVerification?.type &&
      'maximum_allowed_employers' in employmentVerification &&
      !isNaN(employmentVerification.maximum_allowed_employers as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        employment_verification: {
          maximum_allowed_employers:
            employmentVerification.maximum_allowed_employers,
        },
      };
    }

    if (
      personalReferenceVerification?.type &&
      'required_quantity' in personalReferenceVerification &&
      !isNaN(personalReferenceVerification.required_quantity as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        personal_reference_verification: {
          required_quantity: personalReferenceVerification.required_quantity,
        },
      };
    }

    if (
      professionalReferenceVerification?.type &&
      'required_quantity' in professionalReferenceVerification &&
      !isNaN(professionalReferenceVerification.required_quantity as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        professional_reference_verification: {
          required_quantity:
            professionalReferenceVerification.required_quantity,
        },
      };
    }

    setInitialAdditionalProperties(additionalProperties);
  }, [basePackage.screenings]);

  if (selectedGeo === 'international') {
    return (
      <InternationalAddScreeningsList
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
        disabledScreenings={disabledScreenings}
        addedScreenings={addedScreenings}
        additionalProperties={{
          ...additionalProperties,
          ...initialAdditionalProperties,
        }}
        itemPrices={allAddOnPricesQuery || []}
        basePackage={basePackage}
      />
    );
  }
  return (
    <DomesticAddScreeningsList
      onAddClick={onAddClick}
      onRemoveClick={onRemoveClick}
      disabledScreenings={disabledScreenings}
      addedScreenings={addedScreenings}
      additionalProperties={{
        ...additionalProperties,
        ...initialAdditionalProperties,
      }}
      itemPrices={allAddOnPricesQuery || []}
      basePackage={basePackage}
      previouslyOrderedScreenings={previouslyOrderedScreenings}
      screeningSettingPrice={screeningSettingPrice}
    />
  );
};

export default AddScreeningsList;
