import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  icon: string;
};

const StyledIcon = styled(M.Icon)`
  margin-right: 0.75em;
`;

const StyledFilledIcon = styled(StyledIcon)`
  fill: ${colors.brandAqua3} !important;
`;

export const Icon: React.FC<Props> = ({ icon }) => {
  return <StyledIcon icon={icon} />;
};

export const FilledIcon: React.FC<Props> = ({ icon }) => {
  return <StyledFilledIcon icon={icon} />;
};

export const getComponents: any = () => {
  return {
    'add-icon': <Icon icon='Add' />,
    'checkmark-icon': <FilledIcon icon='Checkmark' />,
    'view-filled': <Icon icon='ViewFilled' />,
    'view-off-filled': <FilledIcon icon='ViewOffFilled' />,
  };
};

export default Icon;
