import React, { SyntheticEvent } from 'react';

import Button from 'components/Button';

type Props = {
  onCancel?: (event: SyntheticEvent) => void;
  confirmed: boolean;
  publish?: () => void;
};

export const Actions: React.FC<Props> = ({ onCancel, confirmed, publish }) => {
  return (
    <>
      <Button
        id='assess-ruleset-version-publish-actions-cancel-button'
        kind='secondary'
        trans='verbs.cancel'
        onClick={onCancel}
      />
      <Button
        id='assess-ruleset-version-publish-actions-publish-button'
        disabled={!confirmed}
        kind='primary'
        trans='verbs.publish'
        onClick={publish}
      />
    </>
  );
};

export default Actions;
