import React, { ReactNode } from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => React.ReactElement<any, any>;
  children: ReactNode;
};

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : <>{children}</>);

export default ConditionalWrapper;
