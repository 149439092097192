import React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  enabled: Boolean;
};

const DefaultDescription: React.FC<Props> = ({ enabled }) => {
  const { t } = useTranslation('assess');
  const text = t('assess:ruleset_assignments.account_default.description');
  if (!enabled) {
    return null;
  }
  return <div className='p3 p3-secondary'>{text}</div>;
};

export default DefaultDescription;
