import React, { useState } from 'react';
import { ApiStatusBannerContainer } from 'containers';
import {
  DeauthorizedBanner,
  ImpersonatorBanner,
  DashboardInternalBanner,
  DashboardBetaBanner,
} from 'components';
import { isSignupFlow } from 'utils';
import DashboardLandingPage from 'pages/DashboardLandingPage';
import NavigationContainer from 'modules/layout/navigation/ui/navigation-container';
import { useIsCandidateRoute } from 'modules/candidate';
import CandidateBetaBanner from 'components/Beta/BetaBanner';
import { BetaState, useBetaEnabled, useBetaState } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';
import {
  CANDIDATE_REDESIGN_LEARN_MORE_URL,
  IS_ANGULAR_IFRAME,
} from 'Constants';
import Footer from './Footer';
import {
  Body,
  MainContent,
  Content,
  ContentWrap,
  ContainerMain,
  FlexGrowContainer,
} from './DashboardStyles';
import { AccountCredentialingBanner } from '../DashboardLanding/ui';

export const DashboardWrapper: React.FC<{}> = ({ children }) => {
  const isSignup = isSignupFlow();
  const [navCollapsed, setNavCollapse] = useState(false);

  const candidateRedesignState = useBetaState(CANDIDATE_REDESIGN);
  const betaEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
  const withinCandidate = useIsCandidateRoute();

  // Show beta banner in standalone mode for candidate routes
  const showBetaCandidateBanner =
    !IS_ANGULAR_IFRAME &&
    withinCandidate &&
    candidateRedesignState === BetaState.OPT_OUT;

  const showFooter =
    !withinCandidate ||
    ![BetaState.OPT_IN, BetaState.INITIAL].includes(candidateRedesignState);

  return (
    <ContainerMain>
      <div>
        <Body>
          <MainContent>
            <NavigationContainer setNavCollapse={setNavCollapse} />
            <ContentWrap navCollapsed={navCollapsed}>
              {!isSignup && (
                <>
                  <ImpersonatorBanner />
                  <DashboardInternalBanner />
                  <DashboardBetaBanner />
                  <ApiStatusBannerContainer />
                  {showBetaCandidateBanner && (
                    <CandidateBetaBanner
                      flagKey={CANDIDATE_REDESIGN}
                      translationKey='candidate_redesign'
                      learnMoreLink={CANDIDATE_REDESIGN_LEARN_MORE_URL}
                    />
                  )}
                  <AccountCredentialingBanner />
                </>
              )}
              <Content removePadding={withinCandidate && betaEnabled}>
                {!isSignup && <DeauthorizedBanner />}
                {isSignup && <DashboardLandingPage />}

                <FlexGrowContainer>
                  {children}

                  {showFooter && <Footer className='footer-new' />}
                </FlexGrowContainer>
              </Content>
            </ContentWrap>
          </MainContent>
        </Body>
      </div>
    </ContainerMain>
  );
};

export default DashboardWrapper;
