import React from 'react';
import { useTranslation } from 'react-i18next';

export const Description: React.FC = () => {
  const { t } = useTranslation();
  const label = t('assess:context_dates.description');

  return <p>{label}</p>;
};

export default Description;
