import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button as ComponentButton } from 'components/Button';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { cleanCurrentUrl } from 'modules/assess/v2/ui/utils';

type Props = {
  isEmpty?: boolean;
  option: Navigation;
};

const StyledButton = styled(ComponentButton)`
  margin-left: auto !important;
`;

export const Button: React.FC<Props> = ({ isEmpty, option }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = cleanCurrentUrl(location.pathname);
  const noun = isEmpty ? 'start' : 'edit';
  const translation = `buttons.${noun}`;
  const path = `${currentPath}/guidelines/${option}`;
  const onClick = useCallback(() => history.push(path), [history, path]);
  const id = `assess-v2-ui-guideline-content-options-button`;

  return (
    <StyledButton
      data-testid={id}
      kind='secondary'
      onClick={onClick}
      size='sm'
      trans={translation}
    />
  );
};

export default Button;
