import React, { useCallback, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import UserContext from 'context/CurrentUser';

type Props = {
  confirm: (confirm: boolean) => void;
};

export const Signature: React.FC<Props> = ({ confirm }) => {
  const { t } = useTranslation('assess');
  const user: any = useContext(UserContext);
  const name = user?.full_name || user?.email || 'unknown';
  const date = new Date().toLocaleDateString();
  const signature = `${t('assess:common.signature', {
    name,
  })}, ${date}`;

  const handleCheck = useCallback(
    (event, { checked }) => confirm(checked),
    [confirm],
  );
  const id = 'assess-ui-common-signature';

  return (
    <M.Checkbox
      id={id}
      data-testid={id}
      labelText={signature}
      onChange={handleCheck}
    />
  );
};

export default Signature;
