import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, Add, ChevronDown, TrashCan } from '@dashboard-experience/mastodon';
import { API_KEY_TOKENS, API_KEY_INTERVALS } from 'Constants';
import { GenericObject } from '@dashboard-experience/utils';
import { useDeleteApiKey } from 'api/apiKeys';
import { useUser } from 'context/CurrentUser';
import moment from 'moment';

// Overflow Menu does not let us use a button as a trigger
// Since it nests the renderIcon inside a button, and React
// doesn't like having a button inside a button
// By using the button classes as a base, we gain the right coloring
const CreateButton = () => {
  return (
    <div
      className='mastodon-button primary'
      style={{
        width: 'auto',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      Create Key <Add size={16} style={{ marginLeft: '8px' }} />
    </div>
  );
};

const ToolbarMenu = styled(M.TableToolbarMenu)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
`;

const renderIcon = () => (
  <M.TableBatchAction renderIcon={ChevronDown}>Expire Key</M.TableBatchAction>
);

type ApiKeysToolbarTypes = {
  getToolbarProps: Function;
  getBatchActionProps: Function;
  selectedRows: GenericObject[];
  addApiKey: Function;
  onInputChange: Function;
};

const ApiKeysToolbar = ({
  getToolbarProps,
  getBatchActionProps,
  selectedRows,
  addApiKey,
  onInputChange,
}: ApiKeysToolbarTypes) => {
  const { call: deleteKey } = useDeleteApiKey();

  const deleteApiKeys = useCallback(
    (selectedItems: any) =>
      ({ currentTarget }: any) => {
        const when = currentTarget.innerText;
        const ids = selectedItems.map((key: any) => key.id);

        const expires_at = moment(
          moment.now() + API_KEY_INTERVALS[when],
        ).toISOString();
        // TODO : Make a real endpoint + corresponding action for batch actions
        ids.forEach((id: string) => deleteKey({ id, expires_at }));
      },
    [deleteKey],
  );

  const undeleteApiKeys = useCallback(
    (selectedItems: any) => () => {
      const expires_at = null;
      const ids = selectedItems.map((key: any) => key.id);
      // TODO : Make a real endpoint for batch actions
      ids.forEach((id: string) => deleteKey({ id, expires_at }));
    },
    [deleteKey],
  );

  const { account } = useUser();

  return (
    <M.TableToolbar {...getToolbarProps()}>
      <M.TableBatchActions {...getBatchActionProps()}>
        {selectedRows.every((row: GenericObject) => !!row.cells[4].value) ? (
          <M.TableBatchAction
            tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
            onClick={undeleteApiKeys(selectedRows)}
            renderIcon={TrashCan}
          >
            Unexpire
          </M.TableBatchAction>
        ) : (
          <ToolbarMenu
            flipped
            tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
            renderIcon={renderIcon}
          >
            {Object.keys(API_KEY_INTERVALS).map(interval => (
              <M.TableToolbarAction
                itemText={interval}
                key={`batch-${interval}`}
                onClick={deleteApiKeys(selectedRows)}
              />
            ))}
          </ToolbarMenu>
        )}
      </M.TableBatchActions>
      <M.TableToolbarContent>
        <M.TableToolbarSearch
          tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          onChange={onInputChange}
          autoComplete='off'
        />
        {account && account.api_authorized && (
          <ToolbarMenu flipped renderIcon={CreateButton}>
            {Object.values(API_KEY_TOKENS).map((token: string) => (
              <M.TableToolbarAction
                key={token}
                onClick={addApiKey}
                itemText={token}
              />
            ))}
          </ToolbarMenu>
        )}
        {(!account || !account.api_authorized) && (
          <M.Button
            title='Account must be authorized before API keys can be generated'
            disabled
          >
            Create Key <Add size={16} style={{ marginLeft: '8px' }} />
          </M.Button>
        )}
      </M.TableToolbarContent>
    </M.TableToolbar>
  );
};

export default ApiKeysToolbar;
