enum ScreeningTypes {
  Drug = 'drug',
  Alcohol = 'alcohol',
  DrugAlcohol = 'drug_alcohol',
}

export interface IPoll {
  id: number;
  createdAt: string;
  completedAlcoholTests: number;
  completedDrugTests: number;
  drugSelectionCount: number;
  alcoholSelectionCount: number;
  eligibleEmployeeCount: number;
}

export type RandomScreeningCandidateScreeningTypes =
  | ScreeningTypes.Drug
  | ScreeningTypes.Alcohol
  | ScreeningTypes.DrugAlcohol;

export interface ISelectedCandidate {
  id: number;
  candidateId: string;
  reportId: string | null;
  fullName: string;
  status: string | null;
  screeningType: RandomScreeningCandidateScreeningTypes;
  excused: boolean;
  alternate: boolean;
  screeningPassUri: string | null;
  notes: string | null;
}

export interface IPollDetail extends IPoll {
  randomlySelectedCandidates: ISelectedCandidate[];
}

export interface TableCell {
  errors: any;
  id: string;
  info: { header: string };
  isEditable: boolean;
  isEditing: boolean;
  isValid: boolean;
  value: string;
}

export interface TableRow {
  id: string;
  candidateId: string;
  isSelected: boolean;
  isExpanded: boolean;
  disabled: boolean;
  cells: TableCell[];
}

export interface TableHeader {
  key: TABLE_HEADER_KEYS;
  name: TABLE_HEADER_VALUES;
}

export enum TABLE_HEADER_KEYS {
  FULL_NAME = 'fullName',
  TEST_TYPE = 'testType',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export enum TABLE_HEADER_VALUES {
  FULL_NAME = 'Employee name',
  TEST_TYPE = 'Test type',
  STATUS = 'Status',
  ACTIONS = 'Actions',
}

export enum SORT_DIRECTIONS {
  NONE = 'NONE',
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export type SortState = {
  sortHeaderKey: TABLE_HEADER_KEYS | null;
  sortDirection: SORT_DIRECTIONS;
};
