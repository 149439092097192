import React from 'react';
import { useCandidateRededesignPhaseThree } from 'modules/candidate';
import ReportActions from './report-actions';
import ReportActionsV2 from './v2';

type Props = {
  isScrolled: boolean;
};

const Actions: React.FC<Props> = ({ isScrolled }) => {
  const candidateededesignPhaseThree = useCandidateRededesignPhaseThree();

  return candidateededesignPhaseThree ? (
    <ReportActionsV2 isScrolled={isScrolled} />
  ) : (
    <ReportActions isScrolled={isScrolled} />
  );
};

export default Actions;
