import { Invite } from 'components/Packages/OrderBackgroundCheck/SharedItems';

const validateInvites = (invites: Invite[]): boolean => {
  let isValid = true;
  let foundOne = false;

  invites.forEach(invite => {
    const { email, firstName, phoneNumber } = invite;

    if (!email.value && !firstName.value && !phoneNumber.value) {
      return;
    }

    foundOne = true;

    if ((email.value && !email.isValid) || !email.value) {
      isValid = false;
    }

    if (!firstName.isValid) {
      isValid = false;
    }

    if (!phoneNumber.isValid) {
      isValid = false;
    }
  });

  return foundOne && isValid;
};

export default validateInvites;
