import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Divider from './Divider';

const Container = styled.div`
  color: ${colors.uiTextPrimaryLight};
  padding-bottom: 2rem;
  padding-top: 1rem;
`;

type Props = {
  divider?: boolean;
};

const Section: React.FC<Props> = ({ children, divider, ...props }) => {
  return (
    <Container {...props}>
      {children}
      {divider && <Divider />}
    </Container>
  );
};

export default Section;
