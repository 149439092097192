import React from 'react';

import { Provider as FlagrProvider } from '@dashboard-experience/react-flagr';
import { useFetchFlagr } from 'utils/FlagrUtils';
import { useUser } from 'context/CurrentUser';
import { User } from 'types/report';
import { M } from '@dashboard-experience/mastodon';

import { FLAGR_URL } from 'Constants';

const FlagrProviderWrapper = ({ children }: any) => {
  const userData: User = useUser();

  const {
    flags,
    type,
    context,
    isLoading: isFlagrLoading,
  } = useFetchFlagr(userData);

  // Show the Loading screen while the Flagr call is still pending
  if (isFlagrLoading) {
    return <M.LoadingSpinner />;
  }

  return (
    <FlagrProvider
      flags={flags}
      entityId={userData?.account?.id ?? ''}
      entityType={type}
      entityContext={context}
      url={FLAGR_URL}
    >
      {children}
    </FlagrProvider>
  );
};

export default FlagrProviderWrapper;
