import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as BetterOrderExperience from 'components/BetterOrderExperience';

const BetterOrderExperiencePage: React.FC<{}> = () => {
  const { t } = useTranslation('better_order_experience');

  return (
    <div>
      <h1 data-testid='order-background-check-header'>{t('heading')}</h1>
      <Switch>
        <Route exact path='/order-experience/get-started'>
          <BetterOrderExperience.GetStartedStep />
        </Route>

        <Route exact path='/order-experience/select-your-package'>
          <BetterOrderExperience.SelectPackageStep />
        </Route>

        <Route exact path='/order-experience/add-ons'>
          <BetterOrderExperience.AddonsStep />
        </Route>

        <Route exact path='/order-experience/bulk-upload'>
          <BetterOrderExperience.BulkUploadStep />
        </Route>

        <Route exact path='/order-experience/review-and-submit'>
          <BetterOrderExperience.ReviewAndSubmitStep />
        </Route>

        <Route exact path='/order-experience/manual-entry'>
          <BetterOrderExperience.ManualEntryStep />
        </Route>
      </Switch>
    </div>
  );
};

export default BetterOrderExperiencePage;
