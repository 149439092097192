import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { Package } from 'types';

const Price = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: right;
  color: ${colors.uiTextPrimaryLight};
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: ${colors.brandSlate6};
  }

  .card-disabled & {
    color: ${colors.uiTextDisabledLight} !important;
  }
`;

const PriceVaries = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  color: ${colors.uiTextPrimaryLight};
`;

const PackagePrice: React.FC<{ basePackage: Package }> = ({ basePackage }) => {
  return basePackage.price !== 0 ? (
    <Price>
      <span>Starting at </span>
      {getDollarAmount(String(basePackage.price))}
    </Price>
  ) : (
    <PriceVaries data-testid='price-varies'>Price varies</PriceVaries>
  );
};

export default PackagePrice;
