import React from 'react';
import {
  AtsIntegrationValidationResult,
  useAtsIntegrationValidation,
} from 'api/atsIntegration';
import { LoadingSpinner, SpinnerContainer } from './styles';

type Props = {
  partnerSlug: string;
  validationId: string;
  onSuccess: (result: AtsIntegrationValidationResult) => void;
  onError: (error: Error, validationIds: string[]) => void;
};

const Spinner: React.FC<Props> = ({
  partnerSlug,
  validationId,
  onSuccess,
  onError,
}: Props) => {
  useAtsIntegrationValidation(partnerSlug, [validationId], onSuccess, onError);
  return (
    <SpinnerContainer>
      <LoadingSpinner data-testid={`ats-validation-spinner-${validationId}`} />
    </SpinnerContainer>
  );
};

export default Spinner;
