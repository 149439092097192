import React from 'react';
import { useMutation, useQuery, AnyQueryKey } from 'react-query';

import { Screens, Screen } from 'components/CsvExport';
import { requestContinuousCheckCsvExport } from 'actions';
import {
  createCandidateCsv,
  createAdverseActionCsv,
  createInvitationCsv,
  getCsv,
} from './actions';
import { CSVExportParams } from './types';

type SetScreen = React.Dispatch<React.SetStateAction<Screen>>;

export const createCsv =
  (exportCsv: (params: CSVExportParams) => Promise<any>) =>
  (setScreen: SetScreen) => {
    const request = (params: CSVExportParams) => exportCsv(params);

    const [call, result] = useMutation(request, {
      onSuccess: () => {
        setScreen(Screens.SUCCESS);
      },
    });

    return {
      call,
      result,
    };
  };

export const useCreateAdverseActionCsv = createCsv(createAdverseActionCsv);
export const useCreateCandidateCsv = createCsv(createCandidateCsv);
export const useCreateInvitationCsv = createCsv(createInvitationCsv);
export const useContinuousCheckCsv = createCsv(requestContinuousCheckCsvExport);

export const useGetCsv = (id: string) => {
  const key: AnyQueryKey = ['csv', { id }];

  const request = () => getCsv(id);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
