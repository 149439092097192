import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import BulkEditLink from './BulkEditLink';

type Props = {
  onClickBulkEdit: (event?: SyntheticEvent) => void;
};

const Title = styled.span`
  color: ${colors.uiTextPrimaryLight};
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: ${colors.uiTextSecondaryLight};
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 16px;
`;

const Header: React.FC<Props> = ({ onClickBulkEdit }) => {
  const { t } = useTranslation('assess:v2');
  const translation: any = t(`lite.time_since_offense.table.header`, {
    returnObjects: true,
  });

  return (
    <div>
      <M.Screening.Heading
        headingLeft={{
          name: (
            <Title data-testid='assess-v2-ui-lite-lookback-period-table-statutory-title'>
              {translation.title}
            </Title>
          ),
        }}
        headingRight={<BulkEditLink onClickBulkEdit={onClickBulkEdit} />}
      />
      <Subtitle>{translation.subtitle}</Subtitle>
    </div>
  );
};

export default Header;
