import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { WorksiteEverifyStatus } from 'api/i9';
import { ColoredSpan, FlexRow } from './style';

type WorksiteEverifyStatusLabelProps = {
  everifyStatus: WorksiteEverifyStatus;
};

const icons = new Map<WorksiteEverifyStatus, string>([
  ['active', 'Checkmark'],
  ['inactive', 'Close'],
  ['not_determined', ''],
]);

const labels = new Map<WorksiteEverifyStatus, string>([
  ['active', 'On'],
  ['inactive', 'Off'],
  ['not_determined', 'Pending'],
]);

const WorksiteEverifyStatusLabel = ({
  everifyStatus,
}: WorksiteEverifyStatusLabelProps) => {
  const icon = icons.get(everifyStatus);
  const label = labels.get(everifyStatus);

  return (
    <FlexRow gap='0.1rem' alignItems='center'>
      {icon && <M.Icon size='1rem' icon={icon} />}
      {label &&
        (everifyStatus === 'not_determined' ? (
          <ColoredSpan color={colors.coolBlack70}>{label}</ColoredSpan>
        ) : (
          <span>{label}</span>
        ))}
    </FlexRow>
  );
};

export default WorksiteEverifyStatusLabel;
