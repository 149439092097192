/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import '@dashboard-experience/mastodon/lib/styles.css';
import { FileInputGroup, FileInputGroupButtons } from 'components';
import ReactFilestack from 'filestack-react';
import {
  oauthAssetsFilestackKey as filestackKey,
  filestackOptions,
} from 'Constants';
import * as Styled from './StyledComponents';

type ApplicationModalProps = {
  handleChange: any;
  handleBlur: (e: any) => void;
  handleSubmit: () => void;
  filestackUpload: any;
  onFileUpload: (event: any) => void;
  values: any;
  errors: any;
  touched: any;
  clearForm: () => void;
  closeModalHeader: (keyEvent: any) => void;
  type: string;
};

const ApplicationModal: React.FC<ApplicationModalProps> = ({
  handleChange,
  handleBlur,
  handleSubmit,
  filestackUpload,
  onFileUpload,
  values,
  errors,
  touched,
  clearForm,
  closeModalHeader,
  type,
}) => {
  function invalid(key: string) {
    return Boolean(
      errors[key as keyof typeof errors] && touched[key as keyof typeof errors],
    );
  }

  function invalidText(key: string) {
    return errors[key as keyof typeof errors];
  }

  const [submitClicked, setSubmitClicked] = useState(false);

  // Helper function for building out some DOM attributes for the various inputs
  const setCommonDetails = (name: string) => ({
    'data-testid': name,
    name,
    id: name,
    onChange: handleChange,
  });

  const setTextDetails = (name: string) => ({
    ...setCommonDetails(name),
    value: values[name],
    type: 'text',
    invalid: invalid(name),
    invalidText: invalidText(name),
    onBlur: handleBlur,
  });

  const setCheckboxDetails = (name: string) => ({
    ...setCommonDetails(name),
    checked: values[name],
  });

  return (
    <M.ComposedModal
      size='md'
      open
      id='mastodon'
      onClose={clearForm}
      data-testid='modal'
    >
      <M.ModalHeader closeModal={closeModalHeader}>
        <h2>
          {type === 'create'
            ? 'Create a partner application'
            : 'Edit a partner application'}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <Styled.StyledDiv>
          The first step to building a Checkr Partner integration is to set up
          your Checkr account with a “Partner Application”. Partner Applications
          allow you to connect your customers&apos; Checkr accounts to yours.
        </Styled.StyledDiv>
        <form id='application' onSubmit={handleSubmit}>
          <M.TextInput
            {...setTextDetails('name')}
            labelText='Application name'
          />
          <Styled.StyledHomepageTextInput
            {...setTextDetails('homepage')}
            labelText='Application/Homepage URL'
            helperText="Your company's landing page."
          />
          <M.TextArea
            {...setTextDetails('description')}
            rows={4}
            label='Application description'
            count={100}
          />
          <Styled.StyledHr />
          <Styled.StyledHeaderLabel>Integration</Styled.StyledHeaderLabel>
          <Styled.StyledHeaderLabelHelperText>
            Your application&apos;s integration settings.
          </Styled.StyledHeaderLabelHelperText>
          <M.TextInput labelText='Webhook URL' {...setTextDetails('webhook')} />
          <Styled.StyledLabelHelperText>
            The endpoint where Checkr will post webhook events for your
            connected customer accounts.
            <M.TooltipDefinition
              align='bottom'
              highlighted={false}
              definition={
                <>
                  <p style={{ paddingRight: 0 }}>
                    Checkr uses webhooks to communicate asynchronous changes on
                    objects created with the API. Each time an event that you
                    subscribed to occurs, Checkr submits a POST request to this
                    URL with information about the event.
                  </p>
                  <p style={{ paddingRight: 0 }}>
                    Any webhook URL that fails to accept with a 2XX status code
                    at least one request over a period of 60 days will be
                    removed automatically - e.g. webhooks failing for 100% of
                    the requests during 60 or more days.
                  </p>
                  <p style={{ paddingRight: 0 }}>
                    For more information see{' '}
                    <a
                      href='https://docs.checkr.com/partners/#section/Webhooks'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Webhooks
                    </a>{' '}
                    in the Checkr API docs.
                  </p>
                </>
              }
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                paddingLeft: '5px',
              }}
            >
              <M.Icon icon='HelpFilled' />
            </M.TooltipDefinition>
          </Styled.StyledLabelHelperText>
          <Styled.StyledHr />
          <Styled.StyledHeaderLabel>
            Customer connection
          </Styled.StyledHeaderLabel>
          <Styled.StyledHeaderLabelHelperText>
            The settings used to brand and define this application&apos;s
            Connect-to-Checkr flow.
          </Styled.StyledHeaderLabelHelperText>
          <M.TextInput
            labelText='Redirect URL'
            {...setTextDetails('redirect')}
          />
          <Styled.StyledLabelHelperText>
            The page in your application to which your customers will be
            redirected after completing the Connect-to-Checkr flow.
            <M.TooltipDefinition
              align='bottom'
              highlighted={false}
              definition="This URL must be HTTPS. It is used to secure your
              customers' authentication and prevent Cross-Site Request
              Forgery (CSRF) attacks."
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                paddingLeft: '5px',
              }}
            >
              <M.Icon icon='HelpFilled' />
            </M.TooltipDefinition>
          </Styled.StyledLabelHelperText>
          <Styled.StyledLogoLabel htmlFor='upload-logo'>
            Logo
          </Styled.StyledLogoLabel>
          <FileInputGroup>
            {values.logo_url && (
              <img src={values.logo_url} alt='Logo' width={230} />
            )}
            <FileInputGroupButtons>
              <ReactFilestack
                actionOptions={filestackOptions}
                apikey={filestackKey}
                customRender={filestackUpload}
                onSuccess={onFileUpload}
              />
            </FileInputGroupButtons>
          </FileInputGroup>
          {errors.logo_url && submitClicked && (
            <div style={{ color: colors.uiOrange600, fontSize: '0.75rem' }}>
              {' '}
              {errors.logo_url}
            </div>
          )}
          <Styled.StyledLabelHelperText>
            Your logo which is used in the Connect-to-Checkr flow.
            <M.TooltipDefinition
              align='bottom'
              highlighted={false}
              definition="Square logos are preferred, like the kind you'd find on a
              Twitter or Facebook profile."
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                paddingLeft: '5px',
              }}
            >
              <M.Icon icon='HelpFilled' />
            </M.TooltipDefinition>
          </Styled.StyledLabelHelperText>
          <Styled.StyledLabel htmlFor='primary-color'>
            Your company color
          </Styled.StyledLabel>
          <Styled.ColorDisplay>
            <Styled.StyledColorDiv color={values['primary-color']} />
            <div>
              <M.TextInput {...setTextDetails('primary-color')} />
            </div>
          </Styled.ColorDisplay>
          <Styled.StyledLabelHelperText>
            Select the color or enter the hex color value which best represents
            your brand.
          </Styled.StyledLabelHelperText>
          <M.Checkbox
            {...setCheckboxDetails('partner_billing')}
            labelText='Bill API to partner'
          />
          <Styled.StyledLabelHelperTextCheckbox>
            Report created through connected accounts w/ API will be billed to
            application&apos;s account. Also removes Payment method step in
            customer sign-up flow.
          </Styled.StyledLabelHelperTextCheckbox>
          <M.Checkbox
            {...setCheckboxDetails('pre_credentialed_accounts')}
            labelText='Pre-credentialed accounts'
            disabled
          />
          <Styled.StyledLabelHelperTextCheckbox>
            Allow accounts created through this Application to skip
            credentialing and be immediately authorized upon creation.
          </Styled.StyledLabelHelperTextCheckbox>
          <M.Checkbox
            {...setCheckboxDetails('sign_up_flow')}
            labelText='Sign up flow'
          />
          <Styled.StyledLabelHelperTextCheckbox>
            Make sign-up flow available
          </Styled.StyledLabelHelperTextCheckbox>
        </form>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button
          data-testid='cancel'
          kind='secondary'
          onClick={clearForm}
          size='field'
        >
          Cancel
        </M.Button>
        <M.Button
          size='small'
          type='submit'
          variant='primary'
          onSubmit={handleSubmit}
          onClick={setSubmitClicked}
          form='application'
          data-testid='submit'
        >
          Submit
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ApplicationModal;
