import React from 'react';
import { FieldComponentProps } from '../types';

const PreviousDriverLicenseNumber: React.FC<FieldComponentProps> = ({
  candidate,
}) => {
  const { previous_driver_license_number, previous_driver_license_state } =
    candidate;
  return (
    <>
      {previous_driver_license_number && previous_driver_license_state && (
        <>
          {previous_driver_license_number} {previous_driver_license_state}
        </>
      )}
      {(!previous_driver_license_number || !previous_driver_license_state) && (
        <>-</>
      )}
    </>
  );
};

export default PreviousDriverLicenseNumber;
