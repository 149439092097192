import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  color: ${colors.uiTextPrimaryLight};
  border-bottom: 1px solid ${colors.brandSlate2};
  padding: 1.5rem 0 1.5rem 0;
  font-size: 16px;
`;

const FirstColumn = styled.section`
  display: flex;
  flex-flow: column;
  width: 25%;
`;

const MiddleColumn = styled.section<MiddleColumnProps>`
  margin: 0 0 0 80px;
  width: ${({ $stretch }) => ($stretch ? '75%' : '65%')};
  text-align: left;
`;

const LastColumn = styled.section`
  width: 10%;
  text-align: right;
`;

const SectionTitleTypography = styled.h3`
  font-size: 16px !important;
  font-weight: 800;
  line-height: 24px;
`;

const LinkStyled = styled(M.Link)`
  font-weight: 800;
  line-height: 24px;
  color: ${colors.brandNavy1};
  cursor: pointer;
`;

const DescriptionTitleTypography = styled.div`
  font-weight: 400;
  margin-bottom: 0.75rem !important;
`;

const BoldTypography = styled.p`
  font-weight: 800 !important;
`;

type MiddleColumnProps = {
  $stretch: boolean;
};

export type EditableSectionProps = {
  sectionTitle: string;
  onEditClick: (path: string) => void;
  description: {
    content?: ReactElement;
    title?: string;
    isCandidatesSection?: boolean; // Candidates section's edit occurs inline
    expand?: boolean; // Should show a 'view more' if > X # of items
  };
  showPricingColumn?: boolean;
  price?: string;
  priceDescription?: string;
  inlineEdit?: boolean;
  editMode?: boolean;
  id: string;
  hideLastColumn?: boolean;
};

const EditableSection: React.FC<EditableSectionProps> = ({
  sectionTitle,
  onEditClick,
  description,
  showPricingColumn,
  price,
  priceDescription,
  inlineEdit = false,
  editMode = false,
  id,
  hideLastColumn = false,
}) => {
  return (
    <Container>
      <FirstColumn>
        <SectionTitleTypography data-testid={`${id}-section-title`}>
          {sectionTitle}
        </SectionTitleTypography>
        <LinkStyled
          onClick={onEditClick}
          data-testid={`${id}-${inlineEdit && editMode ? 'save' : 'edit'}`}
        >
          {inlineEdit && editMode ? 'Save' : 'Edit'}
        </LinkStyled>
      </FirstColumn>

      <MiddleColumn $stretch={hideLastColumn}>
        {description.title && (
          <DescriptionTitleTypography>
            {description.title}
          </DescriptionTitleTypography>
        )}

        {description.content}
      </MiddleColumn>

      {!hideLastColumn && (
        <LastColumn>
          {showPricingColumn && priceDescription && (
            <>
              <div style={{ whiteSpace: 'nowrap' }}>{priceDescription}</div>
              <BoldTypography>{getDollarAmount(price)}</BoldTypography>
            </>
          )}
        </LastColumn>
      )}
    </Container>
  );
};

export default EditableSection;
