import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Content from '../../../Content';
import Sections from '../../../sections';
import Author from './Author';

const StyledContainer = styled(M.Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
  padding: 3rem 3rem 3rem 3rem !important;
`;

type Props = {
  quote: any;
};

const Container: React.FC<Props> = ({ quote }) => {
  const { author, paragraphs } = quote;
  const quoteText = (
    <Content paragraphs={paragraphs} section={Sections.benefits} />
  );

  return (
    <StyledContainer
      type='official'
      data-testid='fair-chance-sections-benefits-quote'
    >
      {quoteText}
      <Author author={author} />
    </StyledContainer>
  );
};

export default Container;
