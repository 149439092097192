import React from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import { ListVersionsFooter } from './versions-list';
import { PromoteFooter } from './promote';

export type Props = {
  item: Entity;
  selectedVersion?: Version.Basic;
  onClose: () => void;
};

export const Footer: React.FC<Props> = ({ item, selectedVersion, onClose }) => {
  return (
    <>
      {selectedVersion ? (
        <PromoteFooter
          item={item}
          selectedVersion={selectedVersion}
          onClose={onClose}
        />
      ) : (
        <ListVersionsFooter onClose={onClose} />
      )}
    </>
  );
};

export default Footer;
