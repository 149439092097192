import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const LabelContainer = styled.span`
  padding-right: 0.3em;
  cursor: pointer;
`;

type Props = {
  label: string;
  description: string;
};

const ChargeName: React.FC<Props> = ({ label, description }) => {
  // fix to split long words containing "/" e.g. Marijuana Manufacture/Sell/Distribute
  const name = label.split('/').join(' / ');
  return (
    <LabelContainer>
      <M.TooltipDefinition
        openOnHover={false}
        definition={description}
        align='right'
      >
        <p>{name}</p>
      </M.TooltipDefinition>
    </LabelContainer>
  );
};

export default ChargeName;
