import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const Search = styled(M.Search)`
  #mastodon && {
    max-width: 26rem;
    input {
      max-width: 26rem;
    }
  }
`;

export const LocationSearchContainer = styled.div`
  section:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  section:last-of-type {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 0.5rem !important;
  }

  p,
  label {
    color: ${colors.coolBlack70};
  }

  h2 + p {
    margin-top: 0 !important;
  }
`;

export const LocationPanelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .mastodon-choice-card {
    margin-right: 0 !important;
  }
`;

export const LocationLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .location-label-header-container {
    display: flex;
    flex-direction: column;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 16px;
`;

export const LabelAddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  gap: 10px;
`;

export const BusinessHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;

  p {
    margin-bottom: 0 !important;
  }
`;

export const DistanceContainer = styled.span`
  text-align: center;
`;

export const LoadingBlock = styled(M.LoadingBlock)`
  min-height: 100px;
`;
