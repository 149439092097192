import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view/value';

export enum Events {
  AutoEngage = 'Checkr Dashboard Assess {0} - Auto Engage Clear Setting Saved',
  DisplayClearRecords = 'Checkr Dashboard Assess {0} - Display Clear Records Setting Saved',
  DisplayClearRecordsSettings = 'Display Clear Records Setting',
  ExportGuidelines = 'Checkr Dashboard Assess {0} Export Guidelines',
  HideClearRecords = 'Checkr Dashboard Assess {0} - Hide Clear Records From AA Setting Saved',
  Page = 'Page',
  Pageview = 'Checkr Dashboard Assess {0} Settings Pageview',
  Pagename = 'Settings Page',
}

export enum DisplayClearRecordsProperty {
  Conceal = 'Conceal Clear Records',
  Display = 'Display Clear Records',
  Remove = 'Remove Clear Records',
}

type ClearRecordsPropertyMapType = {
  [key: string]: DisplayClearRecordsProperty;
};

export const CLEAR_RECORDS_PROPERTY_MAP: ClearRecordsPropertyMapType = {
  [Value.CONCEAL_ELIGIBLE_RECORDS]: DisplayClearRecordsProperty.Conceal,
  [Value.REMOVE_ELIGIBLE_RECORDS]: DisplayClearRecordsProperty.Remove,
  [Value.SHOW_ELIGIBLE_RECORDS]: DisplayClearRecordsProperty.Display,
};

export const BOOLEAN_SETTINGS = [
  'adverse_action_assessment_eligible',
  'assess_auto_engage_eligible_reports',
] as const;

type BooleanSettingsEventMapType = {
  [K in (typeof BOOLEAN_SETTINGS)[number]]: string;
};

export const BOOLEAN_SETTINGS_EVENT_MAP: BooleanSettingsEventMapType = {
  adverse_action_assessment_eligible: Events.HideClearRecords,
  assess_auto_engage_eligible_reports: Events.AutoEngage,
};

export default Events;
