import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useModalState, useDispatch } from '../../context';
import {
  FairChanceForm,
  FairChanceAssessment,
  FairChanceDocumentUpload,
  ChargesTable,
} from '../../../../fair-chance';
import Warnings from '../warnings';
import {
  useInitializeAdverseItems,
  useValidAdverseItems,
} from './adverse-items/hooks';
import { useGetReport } from '../../../../../../../../api/reports';

const FairChance: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { FCI_FLOW_ENABLED, OPTIONAL_FCI_FLOW_ENABLED } =
    useInitializeAdverseItems();
  const validAdverseItems = useValidAdverseItems();
  const state = useModalState('state');
  const { adverseItems = [], individualizedAssessment } = state;
  const currentScreen = useModalState('currentScreen');
  const transitionToScreen = useModalState('transitionToScreen');
  const initialAssessment = individualizedAssessment?.assessment || '';
  const { report } = useGetReport();
  const disabled =
    !(initialAssessment || individualizedAssessment) || !validAdverseItems;
  const previewEmail = t(
    `${namespace}:report.actions.pre_adverse.fair_chance.buttons.next_step`,
  );
  const next = t(`${namespace}:report.actions.pre_adverse.modal.footer.next`);

  useEffect(() => {
    const previousScreen = { onClick: () => {} };
    let skip = null;
    let nextName = previewEmail;
    let screenName = 'preview_email';

    if (report?.account?.adverse_action_medium_selection) {
      nextName = next;
      screenName = 'config_options';
    }

    if (FCI_FLOW_ENABLED) {
      previousScreen.onClick = () => transitionToScreen('adverse_items');
      skip = { onClick: () => transitionToScreen(screenName) };
    } else if (OPTIONAL_FCI_FLOW_ENABLED) {
      previousScreen.onClick = () => transitionToScreen('fair_chance');
    }

    const nextScreen = {
      onClick: () => transitionToScreen(screenName),
      name: nextName,
      disabled,
    };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next: nextScreen,
        back: previousScreen,
        skip,
      },
    });
    // transitionToScreen is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, FCI_FLOW_ENABLED, OPTIONAL_FCI_FLOW_ENABLED, disabled, t]);

  return (
    <div>
      <div>
        <Warnings currentScreen={currentScreen} />
        <FairChanceAssessment />
        <hr />
        {adverseItems?.length > 0 && (
          <>
            <ChargesTable adverseItems={adverseItems} />
            <hr />
          </>
        )}
        <FairChanceForm dispatch={dispatch} type='assessment' />
        <hr />
        <FairChanceDocumentUpload
          adjudicationDispatch={dispatch}
          state={state}
        />
      </div>
    </div>
  );
};

export default FairChance;
