import React, { useCallback, useState } from 'react';

import { M } from '@dashboard-experience/mastodon';
import { FormHeading } from 'components';
import { AddBrand, BrandsTable } from 'components/Account/Brands';
import { useFetchBrandsData } from 'api/brand/hooks';

const BrandsContainer = () => {
  const [page, setPage] = useState(1);
  let pageCount: number = 0;
  const { data: brandsData, isLoading } = useFetchBrandsData(page);

  const handlePaginationClick = useCallback(
    (pageIndex: number) => {
      setPage(pageIndex + 1);
    },
    [setPage],
  );

  if (brandsData?.count) {
    pageCount = Math.ceil(brandsData.count / 25) || 0;
  }

  return (
    <>
      {isLoading ? (
        <M.LoadingSpinner data-testid='loader' />
      ) : (
        <>
          <FormHeading data-testid='brand-heading'>Brand Settings</FormHeading>
          <AddBrand page={page} />
          <BrandsTable
            brands={brandsData?.data}
            handlePaginationClick={handlePaginationClick}
            selectedIndex={page - 1}
            pageCount={pageCount}
            setPage={setPage}
          />
        </>
      )}
    </>
  );
};

export default BrandsContainer;
