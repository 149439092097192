import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PageLoading from 'components/PageLoading';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  disabled: boolean;
  initialName: string;
  open: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  update?: boolean;
  onClose?: (event?: SyntheticEvent) => void;
  onSubmit: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({
  disabled,
  initialName,
  open,
  setName,
  status,
  update,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:ruleset.create.title');

  return (
    <>
      <PageLoading enabled={status === 'loading'} />
      <M.ComposedModal
        data-testid='assess-ruleset-dialog'
        onClose={onClose}
        open={open}
      >
        <M.ModalHeader
          data-testid='assess-ruleset-dialog-title'
          title={modalTitle}
          closeModal={onClose}
        />
        <M.ModalBody data-testid='assess-ruleset-dialog-content'>
          <Content onChange={setName} defaultValue={initialName} />
        </M.ModalBody>
        <M.ModalFooter data-testid='assess-ruleset-dialog-actions'>
          <Actions
            disabled={disabled}
            onCancel={onClose}
            onCreate={onSubmit}
            update={update}
          />
        </M.ModalFooter>
      </M.ComposedModal>
    </>
  );
};

export default Container;
