import { ID } from 'modules/id';
import { useNamespacedRoute } from '../context';

export enum PathName {
  categories = 'categories',
  rulesets = 'rulesets',
}

export const usePath = (section?: PathName, instance?: ID, version?: ID) => {
  let path = useNamespacedRoute(section);

  if (instance) {
    path += `/${instance}`;

    if (version) {
      path += `/versions/${version}`;
    }
  }

  return path;
};
