import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const I9OrderContainer = styled(M.Container).attrs({
  type: 'transparent',
})`
  padding: 0px !important;
`;

export default I9OrderContainer;
