import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import UserContext from 'context/CurrentUser';
import { New } from 'components/Invitations';

const NewInvitationContainer = () => {
  const currentUser = useContext(UserContext);

  return currentUser?.account.id ? (
    <New account={currentUser.account} />
  ) : (
    <M.LoadingSpinner />
  );
};

export default NewInvitationContainer;
