import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import { scrollToElement } from 'utils';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';

const OrderNewReportAction = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const history = useHistory();
  const trackEvent = useTrackEvent();

  const scrollToOrderSection = () => {
    const orderNewWrap = document.getElementById('order-new-wrapper');
    const orderNewElOffset = orderNewWrap?.offsetTop;

    if (orderNewElOffset) {
      window.scrollTo({
        top: orderNewElOffset,
        behavior: 'smooth',
      });
      scrollToElement(orderNewElOffset);
    }
  };

  const orderNewReport = useCallback(() => {
    if (currentUser) {
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.REPORT_VIEW_ORDER_NEW_REPORT_SELECTED,
      );
    }

    const path = `/candidates/${candidate.id}/new`;
    history.push(path);
    scrollToOrderSection();
  }, [currentUser, candidate.id, history, trackEvent]);

  return (
    <M.Button
      data-testid='order-new-report'
      kind='secondary'
      size='small'
      onClick={orderNewReport}
    >
      Order new report
    </M.Button>
  );
};

export default OrderNewReportAction;
