import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { Location } from 'components/LocationSearch/types';
import styled from 'styled-components';

interface ClinicSectionProps {
  selectedClinic: Location | null;
  onEdit?: Function;
}

const ClinicSection: React.FC<ClinicSectionProps> = ({
  selectedClinic,
  onEdit,
}) => {
  const isEditable = !!onEdit;
  return (
    <M.GridRow data-testid='clinic-section'>
      <M.Container.Col>
        <h6>Clinic location</h6>
        <StyledContainer isEditable={isEditable}>
          <M.Tooltip
            label={
              !isEditable && 'Clinic selection is unavailable at this time'
            }
            triggerProps={{
              onClick: () => {
                if (isEditable) {
                  onEdit!();
                }
              },
              kind: 'tertiary',
            }}
            highlighted={false}
          >
            <StyledLink isEditable={!!onEdit}>
              {!isEditable && <M.Icon icon='InformationFilled' size='14' />}
              Edit
            </StyledLink>
          </M.Tooltip>
        </StyledContainer>
      </M.Container.Col>
      <M.Container.Col>
        {selectedClinic ? (
          <StyledLocationContainer>
            <p>{selectedClinic.siteName}</p>
            <p>{selectedClinic.address1}</p>
            <p>{selectedClinic.address2}</p>
          </StyledLocationContainer>
        ) : (
          'Employees will pick'
        )}
      </M.Container.Col>
    </M.GridRow>
  );
};

export default ClinicSection;

const StyledLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLink: any = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
  color: ${props =>
    (props as any).isEditable ? colors.uiNavy600 : colors.uiGrey600};
  font-weight: 700;
  line-height: 100%;
`;

const StyledContainer: any = styled.div`
  .cds--popover--open .cds--popover-content {
    display: ${props =>
      (props as any).isEditable ? 'none' : 'block'} !important;
  }
`;
