import React from 'react';
import ReactDOM from 'react-dom';

const renderModalWithPortal = (Component: React.FC, props: any) => {
  return ReactDOM.createPortal(
    <Component {...props} />,
    document.querySelector('#mastodon') as Element,
  );
};

export default renderModalWithPortal;
