import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { HealthcareSanctionsRecord } from 'types';
import { StyledSpan } from '../MultiMotorVehicleReport/ReportHeading';
import CMSPreclusionListRecord, {
  isInvalidCMSPreclusionListRecord,
} from './CMSPreclusionListRecord';

/**
 * Renders a healthcare sanctions search record.
 * @param sourceType - The source type of the record.
 * @param record - The healthcare sanctions record.
 * @param index - The count for sanction numbering.
 * @returns The rendered healthcare sanctions search record component.
 */
const HealthcareSanctionsSearchRecord: React.FC<{
  sourceType: string;
  record: HealthcareSanctionsRecord;
  index: number;
}> = ({ sourceType, record, index }) => {
  const { details, sanction, id } = record;

  if (isInvalidCMSPreclusionListRecord(sourceType, record)) {
    return null;
  }

  return (
    <>
      {index > 1 && <M.Divider />}
      <M.Expandable
        role='healthcareSanctionsSearchRecord'
        key={`${details}-${sanction}-${id}`}
        initialExpanded
        title={<ReportHeading title={`Sanction #${index}`} />}
      >
        <RenderRecordBySourceType sourceType={sourceType} record={record} />
      </M.Expandable>
    </>
  );
};

/**
 * Renders the heading for the healthcare sanctions search record.
 * @param title - The title of the heading.
 * @returns The rendered heading component.
 */
const ReportHeading: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <M.Screening.Heading
      headingLeft={{
        name: (
          <StyledSpan>
            <span>{title}</span>
          </StyledSpan>
        ),
      }}
    />
  );
};

/**
 * Renders the record based on the source type.
 * @param sourceType - The source type of the record.
 * @param record - The healthcare sanctions record.
 * @returns The rendered record component.
 */
const RenderRecordBySourceType: React.FC<{
  sourceType: string;
  record: HealthcareSanctionsRecord;
}> = ({ sourceType, record }) => {
  switch (sourceType) {
    case 'cms_preclusion_list':
      return <CMSPreclusionListRecord record={record} />;
    default:
      return null;
  }
};

export default HealthcareSanctionsSearchRecord;
