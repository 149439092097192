import { Client } from 'api';
import { GenericObject } from 'types';
import { validateZipCode } from 'components/LocationSearch/utils';
import { Location } from 'components/LocationSearch/types';
import { HEALTH_SCREENING_PATH, HEALTH_SCREENING_V1_PATH } from './constants';
import { AddExamParams } from './types';

export type UpdateOrderParams = {
  id: string;
  params: {
    exam_slugs: string[];
    specimen_id: string;
  };
};

export type SetExpirationParams = {
  id: string;
  donor_pass_expires_at: string;
};

export const updateDrugScreeningOrder = ({
  id,
  params,
}: UpdateOrderParams): Promise<{ [key: string]: any }> => {
  const { exam_slugs, specimen_id } = params;
  return Client.post(`${HEALTH_SCREENING_V1_PATH}/order/${id}`, {
    exam_slugs,
    drug_screening_options: {
      specimen_id,
    },
  });
};

export const getExpiration = (
  appointment: GenericObject,
): Promise<GenericObject> => {
  const params = {
    appointment,
  };
  return Client.get(HEALTH_SCREENING_V1_PATH, { params });
};

export const setExpiration = ({
  id,
  donor_pass_expires_at,
}: SetExpirationParams): Promise<GenericObject> => {
  return Client.post(`${HEALTH_SCREENING_V1_PATH}/appointments/${id}`, {
    donor_pass_expires_at,
  });
};

export const renewScreeningPass = (
  appointment_id: string,
): Promise<GenericObject> => {
  return Client.post(
    `${HEALTH_SCREENING_PATH}/appointments/${appointment_id}/renew_screening_pass`,
    {},
  );
};

export const resendScreeningInvitation = (
  screening_id: string,
): Promise<GenericObject> => {
  return Client.post(
    `${HEALTH_SCREENING_V1_PATH}/${screening_id}/reminder`,
    {},
  );
};

export const getClinicLocations = (
  zipcode: string,
  exam_slugs: string | string[],
  account_id: string,
  package_id: string,
  test_package: boolean,
  requires_observed_collection: boolean,
  show_expanded_hours_only: boolean,
  timeout: number = 0,
): Promise<Location[]> => {
  return validateZipCode(zipcode)
    ? Client.get(`${HEALTH_SCREENING_PATH}/locations`, {
        params: {
          zipcode,
          exam_slugs,
          account_id,
          package_id,
          test_package,
          requires_observed_collection,
          show_expanded_hours_only,
        },
        timeout,
      })
    : Promise.resolve();
};

export const addExam = ({
  appointment_id,
  ...params
}: AddExamParams): Promise<GenericObject> => {
  return Client.post(
    `${HEALTH_SCREENING_PATH}/appointments/${appointment_id}/add_exam`,
    {
      appointment_id,
      ...params,
    },
  );
};
