import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import Record from './Record';
import CreditReportRecordUtils from '../CreditReportCommon/CreditReportRecordUtils';

const CreditReport: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { credit_report: screening } = report;

  if (!screening) return null;

  const creditReportUtils = new CreditReportRecordUtils(screening?.records);

  if (
    !creditReportUtils.isDomestic() ||
    creditReportUtils.findDomesticRecords().length === 0
  )
    return null;

  const completedAt = screening?.completed_at;

  return (
    <M.Screening
      title='Credit Report'
      screening={screening}
      statusType={reportStatusType}
      id={SCREENING_TITLES.credit_report.id}
      data-testid='screenings-credit-report'
      role='list'
    >
      {creditReportUtils
        .findDomesticRecords()
        .map((record: { [x: string]: any }, i: number) => (
          <Record
            record={record}
            key={`record-${i.toString()}`}
            completedAt={completedAt}
          />
        ))}
    </M.Screening>
  );
};

export default CreditReport;
