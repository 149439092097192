import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import * as FilterComponents from './Components';
import UIContext from '../../../context/UI';

/**
 * @name fillRow
 * @function
 * @memberOf Filters
 * @description Fill array of row components with '', in order to always
 *              have rows with 3 full occupied spaces.
 */
const fillRow = (row: Array<string>, size: number) => {
  const filledRow = [...row];
  for (let i = 0; i < size; i += 1) {
    if (!filledRow[i]) filledRow.push('');
  }
  return filledRow;
};

const StyledCol = styled(M.Container.Col)<{ width: string }>`
  @media (max-width: ${props => props.width}) {
    #mastodon .mastodon-container-row & {
      &.mastodon-container-col {
        padding-right: 0;
      }
    }

    &.col-empty {
      display: none;
    }
  }
`;

/**
 * @name renderFilters
 * @function
 * @memberOf Filters
 * @description Render row(s) of 3 cols of filters. It renders a div
 *              for empty cols
 */
const RenderFilters: React.FC<{ filters: Array<Array<string>> }> = ({
  filters,
}) => {
  const { isIframe } = useContext(UIContext);

  return (
    <>
      {filters.map((row: Array<string>, i: number) => {
        const r = row?.length < 3 ? fillRow(row, 3) : row;
        const k = `row-${i}`;
        return (
          <M.Container.Row style={{ padding: '0.75rem 0' }} key={k}>
            {r.map((filter: string, j: number) => {
              const Component = filter
                ? (FilterComponents as any)[filter]
                : 'div';
              const key = `${filter}-${j}`;
              return (
                <StyledCol
                  key={key}
                  className={!filter ? 'col-empty' : ''}
                  width={isIframe ? '719px' : '991px'}
                >
                  <Component />
                </StyledCol>
              );
            })}
          </M.Container.Row>
        );
      })}
    </>
  );
};

export default RenderFilters;
