import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ActionButtonsDiv = styled.div`
  text-align: right;
  margin-top: 0.5rem;
`;

type Props = {
  onCancel: () => void;
  onSave: () => void;
};

const ActionButtons: React.FC<Props> = ({ onCancel, onSave }) => {
  const { t } = useTranslation('case-management');

  return (
    <ActionButtonsDiv data-testid='case-management-note-action-buttons'>
      <M.BinaryButtons
        btnLeft={{
          'data-testid': 'case-management-note-cancel-button',
          name: t('internal-notes.cancel'),
          onClick: onCancel,
        }}
        btnRight={{
          'data-testid': 'case-management-note-save-button',
          name: t('internal-notes.save'),
          onClick: onSave,
        }}
        size='sm'
      />
    </ActionButtonsDiv>
  );
};

export default ActionButtons;
