import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getResultOrStatus,
  ReportStatuses,
  underscoreToHuman,
} from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import SearchPDF from './SearchPDF';
import formatCountry from './utils';

const InternationalIdentityDocumentValidation: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_identity_document_validation: search } = report;

  if (!search) return null;

  const title = formatCountry(search.country);
  const status = getResultOrStatus(search);

  return (
    <M.Screening
      id={SCREENING_TITLES.international_identity_document_validation.id}
      title='International Identity Document Validation'
      statusType={reportStatusType}
      screening={search}
    >
      {status !== ReportStatuses.CANCELED && (
        <M.SubScreening
          title={title}
          screening={search}
          statusType={reportStatusType}
          hideCompletedAt
        >
          <div>ID type: {underscoreToHuman(search.document_type)}</div>
          {status === ReportStatuses.CONSIDER && search.details && (
            <div>Discrepancies: {search.details}</div>
          )}
          {search.pdf_url && (
            <SearchPDF
              url={search.pdf_url}
              text={`View ${title} international identity document validation report`}
            />
          )}
        </M.SubScreening>
      )}
    </M.Screening>
  );
};

export default InternationalIdentityDocumentValidation;
