import React, { useEffect } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { FlexContainer } from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const Icon = styled(M.Icon)`
  fill: ${colors.brandAqua4};
  width: 100%;
  height: 75px;
`;

const AccountSetupComplete = () => {
  const trackEvent = useTrackEvent();

  // Track event and redirect user on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ACCOUNT_SETUP_COMPLETED, {
      'Page Type': 'Account Setup',
      'I9 Page Type': 'Account Setup Complete',
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, [trackEvent]);

  return (
    <FlexContainer gap='1.5rem' padding='7.5rem 15rem 7.5rem 15rem'>
      <h3>Setup Complete!</h3>
      <Icon icon='CheckmarkOutline' />
    </FlexContainer>
  );
};

export default AccountSetupComplete;
