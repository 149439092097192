import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { useCreateRandomSelectionReportOrder } from 'api/randomDrugTesting';
import { Location } from 'components/LocationSearch/types';
import { ISelectedCandidate } from '../Poll/types';
import OrderReview from './OrderReview';
import LocationSearch from '../LocationSearch/LocationSearch';
import shouldDisableButton from './utils';
import NonElectronicLocationConfirmationModal from './NonElectronicLocationConfirmationModal';

interface Props {
  cohortId: number;
  candidates: ISelectedCandidate[];
  exams: { alcoholScreening: string; drugScreening: string };
  onCancel: () => void;
  packageId: string;
}

const ScreeningOrderForm: FC<Props> = ({
  cohortId,
  candidates,
  exams,
  onCancel,
  packageId,
}) => {
  const [checked, toggleCheck] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedClinic, setSelectedClinic] = useState<Location | null>(null);
  const [shouldSelectClinic, setShouldSelectClinic] = useState<boolean>(false);
  const [
    showNonElectronicLocationConfirmation,
    setShowNonElectronicLocationConfirmation,
  ] = useState<boolean>(false);

  const onCancelLocWarning = useCallback(
    () => setShowNonElectronicLocationConfirmation(false),
    [],
  );

  const onConfirmLocWarning = useCallback(() => {
    setShowNonElectronicLocationConfirmation(false);
    setStepIndex(1);
  }, []);

  const { call: onOrderReports, result } =
    useCreateRandomSelectionReportOrder();

  const onSubmit = useCallback(() => {
    onOrderReports({
      cohort_id: cohortId,
      disclosure_acknowledged: checked,
      random_testing_poll_candidate_ids: candidates.map(({ id }) =>
        id.toString(),
      ),
      clinic_location_id: selectedClinic?.siteId,
      clinic_zipcode: selectedClinic?.zipcode,
    });
  }, [
    candidates,
    checked,
    cohortId,
    onOrderReports,
    selectedClinic?.siteId,
    selectedClinic?.zipcode,
  ]);

  useEffect(() => {
    if (result.status === 'success') onCancel();
  }, [result, onCancel]);

  const handleDisclosureCheck = useCallback(
    (_, { checked }) => toggleCheck(checked),
    [],
  );

  const continueButtonState = useMemo(
    () =>
      shouldDisableButton(
        stepIndex,
        checked,
        shouldSelectClinic,
        selectedClinic,
      ),
    [stepIndex, checked, shouldSelectClinic, selectedClinic],
  );

  const navigateToOrderReview = useCallback(() => {
    if (selectedClinic && !selectedClinic.electronicallyEnabled) {
      setShowNonElectronicLocationConfirmation(true);
    } else {
      setStepIndex(1);
    }
  }, [setStepIndex, selectedClinic]);

  const onClinicEdit = useCallback(() => {
    setStepIndex(0);
  }, [setStepIndex]);

  return (
    <M.Container data-testid='random-screening-ordering-container'>
      <NonElectronicLocationConfirmationModal
        isOpen={showNonElectronicLocationConfirmation}
        onCancel={onCancelLocWarning}
        onConfirm={onConfirmLocWarning}
      />
      {stepIndex === 0 && (
        <LocationSearch
          shouldSelectClinic={shouldSelectClinic}
          setShouldSelectClinic={setShouldSelectClinic}
          examSlugs={[exams.drugScreening, exams.alcoholScreening]}
          selectedClinic={selectedClinic}
          setSelectedClinic={setSelectedClinic}
          packageId={packageId}
        />
      )}
      {stepIndex === 1 && (
        <OrderReview
          candidates={candidates}
          exams={exams}
          isChecked={checked}
          onDisclosureCheck={handleDisclosureCheck}
          onEdit={onClinicEdit}
          clinicSelectionProps={{
            selectedClinic,
          }}
        />
      )}
      <M.GridRow style={{ width: '100%' }}>
        <M.GridCol style={{ padding: '0' }}>
          <M.Button
            data-testid='action-btn-secondary'
            onClick={stepIndex === 0 ? onCancel : onClinicEdit}
            kind='secondary'
          >
            {stepIndex === 0 ? 'Cancel' : 'Back'}
          </M.Button>
        </M.GridCol>
        <M.GridCol>
          <M.Button
            data-testid='action-btn-primary'
            style={{ float: 'right' }}
            onClick={stepIndex === 0 ? navigateToOrderReview : onSubmit}
            disabled={continueButtonState}
          >
            {stepIndex === 0 ? 'Continue' : 'Submit'}
          </M.Button>
        </M.GridCol>
      </M.GridRow>
    </M.Container>
  );
};

export default ScreeningOrderForm;
