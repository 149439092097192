import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales';

const CtaCardWrap = styled.div`
  background-color: ${colors.uiGrey0};
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;
  color: ${colors.uiTextSecondaryLight};
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  padding: 1.5rem 1.5rem 4.5rem;
  position: relative;

  &.order-background-check {
    max-width: 24rem;
  }

  &:hover {
    box-shadow: 0 0.5rem 1.5rem -0.5rem ${colors.uiGrey600}1a,
      0 0.5rem 2rem -1rem ${colors.uiHover};

    .icon-animated {
      transform: translateX(110%);
    }

    .cta-aqua {
      background-color: ${colors.brandAqua3};
    }

    .cta-navy {
      background-color: ${colors.uiLinkPrimaryLightHover};
    }
  }

  &:active {
    box-shadow: 0 0 0.25rem -0.5rem ${colors.uiGrey600}1a;
  }
`;

const CtaCardTitle = styled.h4`
  margin-bottom: 0 !important;
`;

const CtaCardDescription = styled.div`
  margin-top: 0.5rem;
`;

const CtaWrap = styled.div`
  align-items: center;
  bottom: 1.5rem;
  display: flex;
  height: 2rem;
  position: absolute;
`;

const IconWrap = styled.div`
  background-color: ${colors.uiGrey0};
  position: absolute;
  width: 100%;
  z-index: 1;
  transition: transform 0.25s cubic-bezier(0.68, -0.1, 0.27, 1.1);
`;

const IconArrow = styled.div`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;

  &.cta-aqua {
    background-color: ${colors.brandAqua2};
  }

  &.cta-navy {
    background-color: ${colors.uiLinkPrimaryLightDefault};
  }
`;

const CtaText = styled.span`
  position: relative;
  z-index: 0;
`;

type CtaCardProps = {
  translationKey: string;
  ctaColor: 'aqua' | 'navy';
  onCardClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  isShown?: boolean;
};

const CtaCard: React.FC<CtaCardProps> = ({
  translationKey,
  ctaColor,
  onCardClick,
  className,
  isShown = true,
  ...props
}) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:ctaCards`;
  const title = t(`${basePath}.${translationKey}.title`);
  const description = t(`${basePath}.${translationKey}.description`, {
    defaultValue: '',
  });
  const cta = t(`${basePath}.${translationKey}.cta`);

  if (!isShown) return null;

  return (
    <CtaCardWrap onClick={onCardClick} className={className} {...props}>
      <CtaCardTitle className='bold'>{title}</CtaCardTitle>
      {description && (
        <CtaCardDescription className='p3'>{description}</CtaCardDescription>
      )}
      <CtaWrap>
        <IconWrap className='icon-animated'>
          <IconArrow className={`cta-${ctaColor}`}>
            <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='m16.311 8.8514-1.2813 1.3758 5.3584 6.2044-5.5681 5.9707 1.2348 1.4278 6.8494-7.3466-6.5932-7.6322z'
                fill='#fff'
              />
              <path
                d='m21.895 17.361h-11.789v-2.0425h11.789v2.0425z'
                fill='#fff'
              />
            </svg>
          </IconArrow>
        </IconWrap>
        <CtaText>{cta}</CtaText>
      </CtaWrap>
    </CtaCardWrap>
  );
};

export default CtaCard;
