import React, { useCallback } from 'react';
import { Button } from 'modules/assess/ui/page';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

export const SaveButton: React.FC<Props> = ({ disabled, onClick }) => {
  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <Button
      id='assess-rules-rule-editor-rule-save-button'
      size='sm'
      kind='primary'
      trans='verbs.save'
      onClick={handleClick}
      disabled={disabled}
    />
  );
};

export default SaveButton;
