import { Client } from 'api';
import { GenericObject } from 'types';

export type BaseParams = {
  candidateId: string;
  params?: GenericObject;
};

export type PostDocuments = {
  candidateId: string;
  params: {
    verifiable_id: string;
    content_type: string;
    file_content: any;
  };
};

export type LogPDFDownload = {
  reportId: string;
  type: string;
};

export const list = ({
  candidateId,
  params,
}: BaseParams): Promise<GenericObject> => {
  return Client.get(`/candidates/${candidateId}/documents`, {
    params,
  });
};

export const postV1Documents = ({
  candidateId,
  params,
}: PostDocuments): Promise<GenericObject> => {
  return Client.post(`/v1/candidates/${candidateId}/documents`, {
    ...params,
    type: 'exam_waiver',
  });
};

export const logPDFDownload = ({
  reportId,
  type,
}: LogPDFDownload): Promise<GenericObject> => {
  return Client.post(`/reports/${reportId}/log_pdf_download/${type}`);
};

export const getConsentLink = (reportId: string): Promise<GenericObject> => {
  return Client.get(`reports/${reportId}/consent`);
};
