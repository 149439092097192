/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  useDebouncedCallback,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import { updateSearchParams } from 'actions/SearchActions';
import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { onFilterChange } from '../helpers';
import { styledFilter } from './StyledComponents';

type Props = { labelText?: boolean | string };

const StyledKeywordFilter = styled(M.KeywordFilter)`
  ${styledFilter}
`;

const Keyword: React.FC<Props> = ({
  labelText = 'Search for a background check or candidate',
}) => {
  const { filters, setFilters } = useSearchState();
  const { q } = filters;
  const dispatch = useDispatch();
  const ref = useRef(null);

  const updater = useCallback(
    () => onFilterChange(setFilters, 'q'),
    [setFilters],
  );

  useEffectAfterInitialized(() => {
    debouncedAmplitudeEvent(q);
    if (q && q.length > 0 && q.length < 3) return;
    if (q === undefined) {
      (ref as any).current.value = '';
      return;
    }
    dispatch(updateSearchParams({ q }));
  }, [q, dispatch]);

  const trackEvent = useTrackEvent();

  const debouncedAmplitudeEvent = useDebouncedCallback(
    (keyword: string) => {
      if (keyword?.length >= 3) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
          {
            Keywords: keyword,
            'Selected Filter': 'Keywords',
          },
        );
      }
    },
    5500,
    [],
  );

  return (
    <StyledKeywordFilter
      labelText={labelText}
      defaultValue={q || ''}
      onChange={updater()}
      ref={ref}
      placeholder='Keywords (name, email, phone, or ID)'
      data-dd-privacy='mask'
    />
  );
};

export default Keyword;
