import React, { useContext, useEffect } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_REDESIGN_KEY, ORDER_QUEUING_SUPPORT } from 'Constants';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';

import { NewInvitationContainer } from 'containers';
import { Redirect } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import { accountHasPermission } from '@dashboard-experience/utils';

const NewInvitationPage = () => {
  const orderRedesignKey = useFlag(ORDER_REDESIGN_KEY);
  const orderQueuingFlag = useFlag(ORDER_QUEUING_SUPPORT)?.variantKey === 'on';
  const { contextId } = useContext(UIContext);
  const currentUser = useUser();
  const isAuthorized = accountHasPermission(currentUser, 'authorized');
  const isFromGoodHire = currentUser?.account?.referrer?.startsWith('gh');
  const isPartner = currentUser?.account?.is_partner_account;
  const isPartnerCustomer =
    currentUser?.account?.partner_account_ids.length > 0;
  const isDeAuthorized =
    !isAuthorized && (isFromGoodHire || isPartner || isPartnerCustomer);

  useEffect(() => {
    if (orderRedesignKey?.variantKey === 'enabled' && contextId) {
      // TODO - redirect on standalone?
      updateParentWindowUrl({
        path: '/order/get-started',
        contextId,
        reload: true,
      });
    }
  }, [orderRedesignKey, contextId]);

  if (orderQueuingFlag && isDeAuthorized) {
    return <Redirect to='/candidates' />;
  }

  return <NewInvitationContainer />;
};

export default NewInvitationPage;
