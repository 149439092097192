import React from 'react';
import { ReportProps } from 'types';
import FederalSearch from './FederalSearch';

const FederalCivilSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { federal_civil_search } = report;
  if (!federal_civil_search) return null;

  return (
    <FederalSearch
      report={report}
      reportStatusType={reportStatusType}
      federalType='federal-civil-search'
    />
  );
};

export default FederalCivilSearch;
