import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  GenericObject,
  hasPermission,
  underscoreToHuman,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';

const Styles = styled(M.Container)`
  .document-element {
    padding-bottom: 1rem;
  }

  .document-picture img {
    max-width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .document-picture img.blur {
    -webkit-filter: blur(5px) grayscale(90%);
    filter: blur(5px) grayscale(90%);
  }

  .document-button {
    padding-left: 0.5rem;
  }
`;

const isRejected = (document: GenericObject) => {
  return (
    typeof document.rejected_at !== 'undefined' && document.rejected_at !== null
  );
};

const CandidateDocuments: React.FC<GenericObject> = ({ documents }) => {
  const currentUser = useUser();
  if (!hasPermission(currentUser, 'read_candidate_details')) return null;

  return (
    <Styles data-testid='candidate-documents'>
      <M.Expandable title={<strong>Documents</strong>}>
        <M.Grid>
          <M.GridRow>
            {documents.map((document: GenericObject) => {
              return (
                <M.GridCol
                  md={4}
                  key={document.id}
                  className='document-element'
                >
                  {document.content_type !== 'application/pdf' && (
                    <a
                      href={document.download_uri}
                      className='document-picture'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={document.download_uri}
                        alt={underscoreToHuman(document.type)}
                        className={isRejected(document) ? 'blur' : ''}
                      />
                    </a>
                  )}
                  <br />
                  <M.Button
                    kind='secondary'
                    size='lg'
                    href={document.download_uri}
                    target='_blank'
                    rel='noreferrer'
                    className={`${document.type}_button`}
                  >
                    <M.Icon icon='Launch' size={16} />
                    <span className='document-button'>
                      {underscoreToHuman(document.type)}
                    </span>
                  </M.Button>
                </M.GridCol>
              );
            })}
          </M.GridRow>
        </M.Grid>
      </M.Expandable>
    </Styles>
  );
};

export default CandidateDocuments;
