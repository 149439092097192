import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Option } from 'modules/assess/models/context-date';

type Props = {
  option: Option;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation();
  const description = t(`assess:context_dates.options.${option}.description`);
  const name = t(`assess:context_dates.options.${option}.name`);

  return (
    <Container>
      <strong>{name}</strong>
      {description}
    </Container>
  );
};

export default Description;
