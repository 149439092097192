import React from 'react';
import { Accept } from 'modules/adjudication/ui';

const LifeCycleActions = () => {
  return (
    <div data-testid='report-lifecycle-actions'>
      <Accept />
    </div>
  );
};

export default LifeCycleActions;
