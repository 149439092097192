import React from 'react';
import styled from 'styled-components';
import { AdverseActionLearnMoreButton } from 'components/FairChance/common';
import Content from 'components/FairChance/Content';
import Image from './BlurryAdverseActionImage';

type Props = {
  content: any;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
`;

const Container: React.FC<Props> = ({ content }) => {
  const { header, paragraphs } = content;

  return (
    <StyledContainer data-testid='fair-chance-sections-adverse-action-rate-without-data'>
      {header}
      <Image />
      <Content paragraphs={paragraphs} />
      <AdverseActionLearnMoreButton />
    </StyledContainer>
  );
};

export default Container;
