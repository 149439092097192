import * as Entity from 'modules/assess/models/rules/premade';

export const ACCEPT_LESS_THAN_MISDEMEANOR = 'ACCEPT_LESS_THAN_MISDEMEANOR';

export function build(
  premadeRules: Entity.List,
  items: Entity.List | Entity.Map,
) {
  const currentRules = Object.values(items);
  return premadeRules.map(rule => buildRule(rule, currentRules));
}

function buildRule(rule: Entity.Type, currentRules: Entity.List) {
  const current = currentRules.find(i => i.code === rule.code);

  return {
    id: rule.code,
    key: rule.code,
    disabled: !current,
    rule: {
      ...rule,
      id: current?.id || rule.code,
    },
    isSelected: !!current,
  };
}
