import { Candidate, GenericObject } from '@dashboard-experience/utils';
import { isEqual } from 'lodash';
import ASSESSMENT_MAP from './assessment-map';

function isAssessmentMatched(
  assessment: string,
  decision: string | null | undefined,
  status: string | null | undefined,
) {
  const assessmentMap = ASSESSMENT_MAP[assessment];

  if (!assessment) return true;

  if (assessmentMap.status) {
    return isEqual(assessmentMap, { decision, status });
  }

  return assessmentMap.decision === decision;
}

function isDateContainedWithin(
  beforeDate?: string,
  afterDate?: string,
  reportDate?: string,
) {
  if (!beforeDate && !afterDate) return true;
  if (!reportDate) return false;

  const reportDateParsed = Date.parse(reportDate);

  if (beforeDate && Date.parse(beforeDate) < reportDateParsed) {
    return false;
  }

  return !(afterDate && Date.parse(afterDate) > reportDateParsed);
}

export default function filterReports(
  candidates: Candidate[],
  searchParams: GenericObject,
) {
  if (
    !searchParams.status &&
    !searchParams.adjudication &&
    !searchParams.assessment &&
    !searchParams.geo_ids &&
    !searchParams.tags &&
    !searchParams.packages &&
    !(searchParams.reported_before || searchParams.reported_after) &&
    !(
      searchParams.reports_completed_before ||
      searchParams.reports_completed_after
    )
  )
    return candidates;

  candidates?.forEach(candidate => {
    candidate.reports = candidate.reports?.filter(report => {
      const statusMatched =
        !searchParams.status || searchParams.status.startsWith(report.status);

      const adjudicationMatched =
        !searchParams.adjudication ||
        searchParams.adjudication === report.adjudication ||
        (searchParams.adjudication === 'none' && report.adjudication === null);

      const assessmentMatched = isAssessmentMatched(
        searchParams.assessment,
        report?.assessment?.display?.decision,
        report?.status,
      );

      const createdAtDateMatched = isDateContainedWithin(
        searchParams.reported_before,
        searchParams.reported_after,
        report.created_at,
      );

      const geosMatched =
        !searchParams.geo_ids ||
        searchParams.geo_ids.length === 0 ||
        report.geos?.find((geo: GenericObject) =>
          searchParams.geo_ids.includes(geo.id),
        );

      const packageMatched =
        !searchParams?.packages?.length ||
        searchParams.packages.includes(report?.package);

      const completedMatched = isDateContainedWithin(
        searchParams.reports_completed_before,
        searchParams.reports_completed_after,
        report?.completed_at || undefined,
      );

      const tagsMatched =
        !searchParams?.tags?.length ||
        report.tags?.find((tag: any) => {
          return searchParams.tags.includes(tag?.name);
        });

      return (
        statusMatched &&
        adjudicationMatched &&
        assessmentMatched &&
        createdAtDateMatched &&
        completedMatched &&
        geosMatched &&
        packageMatched &&
        tagsMatched
      );
    });
  });

  return candidates?.filter(candidate => candidate.reports?.length);
}
