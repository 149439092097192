import { Entity } from 'modules/assess/models/mvr/category-code-set';
import React from 'react';

type Props = {
  item?: Entity;
};

export const ItemTime: React.FC<Props> = ({ item }) => {
  if (!item) {
    return null;
  }

  const active = item.versions?.find(v => v.active);
  const event = active?.events.find(e => e.type === 'published');

  if (!event) {
    return null;
  }

  const text = new Date(event.time).toLocaleString();

  return <span>{text}</span>;
};

export default ItemTime;
