/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// eslint-disable-next-line import/prefer-default-export
export const styledFilter = css`
  .cds--list-box__field,
  .cds--text-input__field-wrapper {
    input::placeholder {
      color: ${colors.uiGrey700} !important;

      font-style: normal !important;
    }
  }

  .cds--date-picker-container {
    .cds--label {
      color: ${colors.uiGrey600} !important;
    }
  }
`;
