import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { find, flatten } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import { usePackageSubtypesGlobal } from 'api/packages';
import {
  BaseWrapper,
  Section,
  SelectedScreening,
} from 'components/AddScreenings/shared/ModalComponents';
import OCC_HEALTH_IN_NETWORK_PRICES from './OccHealthPrices';

const Wrapper = styled(BaseWrapper)`
  span.cds--checkbox-label-price {
    margin-right: -25px;
  }
`;

const MultiSelectWithSectionsStyled = styled(M.MultiSelectWithSections)`
  .cds--list-box__menu-item__option {
    padding-right: 5px !important;
  }
`;

const sections: Array<Section> = [
  { title: 'Physical Evaluations', description: 'Physicals, lift tests' },
  { title: 'TB Tests & X-Rays', description: 'Tuberculosis tests' },
  { title: 'Hearing & Vision', description: 'Audiogram, vision tests' },
  {
    title: 'Immunizations',
    description:
      'Administer vaccines and blood-draw tests for resistance to common diseases',
  },
  { title: 'Other', description: 'OSHA respirator questionnaire' },
];

type Service = {
  name: string;
  label: string;
  integration: string;
  counterpart_slug: string;
  skus: {
    name: string;
    charge_type: string;
  }[];
};

export type OccHealthPackageSubtype = {
  description: string;
  name: string;
  screening_type: 'occupational_health';
  services: Service[];
};

type OccHealthDropdownProps = {
  accountId: string;
  selectedScreenings: SelectedScreening[];
  setSelectedScreenings: Dispatch<SetStateAction<SelectedScreening[]>>;
  invalid: boolean;
  isDOT: boolean | null;
};

export const filterDOTScreening = (screenings: SelectedScreening[] = []) => {
  return screenings.filter(screening => screening.value !== 'OHS_DOT_PHYSICAL');
};

export const formatForDropdown = (subtypes: OccHealthPackageSubtype[]) => {
  const formattedWithSectionAndPrice = subtypes.map(subtype => {
    const { services, name } = subtype;
    return services.map(service => {
      const sku = find(service.skus, { charge_type: 'in-network' });
      const price = OCC_HEALTH_IN_NETWORK_PRICES[sku ? sku.name : ''];
      return {
        label: service.label,
        value: service.name,
        section: name,
        price,
      };
    });
  });

  return flatten(formattedWithSectionAndPrice);
};

const OccHealthDropdown: React.FC<OccHealthDropdownProps> = ({
  accountId,
  selectedScreenings,
  setSelectedScreenings,
  invalid,
  isDOT,
}) => {
  const [occHealthScreenings, setOccHealthScreenings] = useState<
    SelectedScreening[]
  >([]);
  const [filteredOccHealthScreenings, setFilteredOccHealthScreenings] =
    useState<SelectedScreening[]>([]);

  const { data } = usePackageSubtypesGlobal('occupational_health_screening');

  useEffect(() => {
    if (data) {
      const formattedForDropdown = formatForDropdown(
        data as unknown as OccHealthPackageSubtype[],
      );
      const withoutDOT = filterDOTScreening(formattedForDropdown);

      setOccHealthScreenings(formattedForDropdown);
      setFilteredOccHealthScreenings(withoutDOT);
    }
  }, [data]);

  const handleChange = useCallback(
    e => {
      setSelectedScreenings(e);
    },
    [setSelectedScreenings],
  );

  const ohsScreeningsToUse = useMemo(() => {
    return isDOT === false ? filteredOccHealthScreenings : occHealthScreenings;
  }, [isDOT, filteredOccHealthScreenings, occHealthScreenings]);

  return (
    <Wrapper>
      <MultiSelectWithSectionsStyled
        items={ohsScreeningsToUse}
        invalid={invalid}
        invalidText='Select at least 1 screening'
        initialSelectedItems={selectedScreenings}
        placeholder='Start typing a screening name'
        sections={sections}
        disabled={isDOT === null}
        onChange={handleChange}
      />
    </Wrapper>
  );
};

export default OccHealthDropdown;
