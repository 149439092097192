export function updateSessionStorage(property: string, newValue: unknown) {
  const storedValue = sessionStorage.getItem('signup');
  const storedObject: { [key: string]: unknown } = storedValue
    ? JSON.parse(storedValue)
    : {};
  storedObject[property] = newValue;
  sessionStorage.setItem('signup', JSON.stringify(storedObject));
}

export function getFromSessionStorage(property: string, defaultValue: unknown) {
  const storedValue = sessionStorage.getItem('signup');
  if (storedValue) {
    const storedObject = JSON.parse(storedValue);
    return storedObject[property] || defaultValue;
  }
  return defaultValue;
}

export const storeUtmParamsInSessionStorage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utm_campaign: urlParams.get('utm_campaign'),
    utm_content: urlParams.get('utm_content'),
    utm_medium: urlParams.get('utm_medium'),
    utm_source: urlParams.get('utm_source'),
    utm_term: urlParams.get('utm_term'),
  };

  // Check if any UTM parameter is present
  const hasUtmParams = Object.values(utmParams).some(param => param !== null);

  if (hasUtmParams) {
    sessionStorage.setItem('utm_params', JSON.stringify(utmParams));
  }
};
