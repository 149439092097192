import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';

export const hasScreeningPointers = (screenings: any[]) =>
  screenings.reduce((curr, next) => {
    const { screening_pointers } = next;
    return (screening_pointers && screening_pointers?.length > 0) || curr;
  }, false);

export const hasCompleteScreenings = (screenings: any[]) =>
  screenings.reduce((curr, screening) => {
    const status = getResultOrStatus(screening);
    const isComplete = status !== ReportStatuses.PENDING;
    return curr || isComplete;
  }, false);
