import client from './client';

export const SUBTYPE_PATH = 'adjudication_subtypes';

export type AdjudicationSubtype = {
  deletable?: boolean;
  description?: string;
  id?: string;
  kind: string;
  name: string;
  slug: string | null;
};

export type AdjudicationSubtypes = AdjudicationSubtype[];

export const get = (accountId: string): Promise<AdjudicationSubtypes> => {
  const path = `accounts/${accountId}/${SUBTYPE_PATH}`;
  return client.get(path);
};
