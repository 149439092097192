/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { BillingAPI } from 'utils/APIClient';
import { get } from 'lodash';

export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';

export const POST_BANK_ACCOUNT_REQUEST = 'POST_BANK_ACCOUNT_REQUEST';
export const POST_BANK_ACCOUNT_SUCCESS = 'POST_BANK_ACCOUNT_SUCCESS';
export const POST_BANK_ACCOUNT_FAILURE = 'POST_BANK_ACCOUNT_FAILURE';

export const VERIFY_BANK_ACCOUNT_REQUEST = 'VERIFY_BANK_ACCOUNT_REQUEST';
export const VERIFY_BANK_ACCOUNT_SUCCESS = 'VERIFY_BANK_ACCOUNT_SUCCESS';
export const VERIFY_BANK_ACCOUNT_FAILURE = 'VERIFY_BANK_ACCOUNT_FAILURE';

export const UPDATE_BANK_ACCOUNT_REQUEST = 'UPDATE_BANK_ACCOUNT_REQUEST';
export const UPDATE_BANK_ACCOUNT_CANCELED = 'UPDATE_BANK_ACCOUNT_CANCELED';

export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';

export const CREATE_BA_STRIPE_TOKEN_FAILURE = 'CREATE_BA_STRIPE_TOKEN_FAILURE';

export const POST_CREDIT_CARD_REQUEST = 'POST_CREDIT_CARD_REQUEST';
export const POST_CREDIT_CARD_SUCCESS = 'POST_CREDIT_CARD_SUCCESS';
export const POST_CREDIT_CARD_FAILURE = 'POST_CREDIT_CARD_FAILURE';

export const UPDATE_CREDIT_CARD_REQUEST = 'UPDATE_CREDIT_CARD_REQUEST';
export const UPDATE_CREDIT_CARD_CANCELED = 'UPDATE_CREDIT_CARD_CANCELED';

export const DELETE_CREDIT_CARD_REQUEST = 'DELETE_CREDIT_CARD_REQUEST';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';

export const CREATE_CC_STRIPE_TOKEN_FAILURE = 'CREATE_CC_STRIPE_TOKEN_FAILURE';

export const UPDATE_DEFAULT_PAYMENT_REQUEST = 'UPDATE_DEFAULT_PAYMENT_REQUEST';
export const UPDATE_DEFAULT_PAYMENT_SUCCESS = 'UPDATE_DEFAULT_PAYMENT_SUCCESS';
export const UPDATE_DEFAULT_PAYMENT_FAILURE = 'UPDATE_DEFAULT_PAYMENT_FAILURE';

const createCardToken = values =>
  new Promise((resolve, reject) => {
    window.Stripe.card.createToken(values, (status, response) => {
      if (status !== 200) {
        const stripeError = response.error
          ? response.error.message
          : 'There was an error processing your credit card information.';
        return reject(new Error(stripeError));
      }
      const {
        id: stripe_token,
        card: { name },
      } = response;

      const tokenData = {
        stripe_token,
        name,
      };
      return resolve(tokenData);
    });
  });

const createBankToken = values =>
  new Promise((resolve, reject) => {
    window.Stripe.bankAccount.createToken(values, (status, response) => {
      if (status !== 200) {
        const stripeError = response.error
          ? response.error.message
          : 'There was an error processing your bank account information.';
        return reject(new Error(stripeError));
      }

      const { id: stripe_token } = response;
      const tokenData = { stripe_token };

      return resolve(tokenData);
    });
  });

const paymentMethodErrorMessage = (error, defaultMessage) =>
  get(error, 'response.data.error', defaultMessage);

export const fetchPaymentMethods =
  ({ accountId }) =>
  async dispatch => {
    try {
      const {
        payment_methods: paymentMethods,
        default_payment_method: defaultPaymentMethod,
      } = await BillingAPI.get(`/accounts/${accountId}/payment_methods`);
      dispatch({
        type: FETCH_PAYMENT_METHODS_SUCCESS,
        paymentMethods,
        defaultPaymentMethod,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PAYMENT_METHODS_FAILURE,
      });
    }
  };

export const updatePaymentMethod =
  ({ userData, stripe_id }) =>
  async dispatch => {
    dispatch({ type: UPDATE_DEFAULT_PAYMENT_REQUEST });
    const updateDefaultPaymentError =
      'There was an error setting this payment as your default payment';
    try {
      await BillingAPI.post(
        `/accounts/${userData.account.id}/payment_methods/set_default`,
        { default_source_id: stripe_id },
      );
      dispatch({
        type: UPDATE_DEFAULT_PAYMENT_SUCCESS,
      });
    } catch (error) {
      const errorMesssage = paymentMethodErrorMessage(
        error,
        updateDefaultPaymentError,
      );
      dispatch({
        type: UPDATE_DEFAULT_PAYMENT_FAILURE,
        updateDefaultPaymentError: errorMesssage,
      });
    }
  };

export const postBA =
  ({ values, userData, editBAMode, resetForm }) =>
  async dispatch => {
    if (!editBAMode) {
      dispatch({ type: UPDATE_BANK_ACCOUNT_REQUEST });
      return;
    }
    dispatch({ type: POST_BANK_ACCOUNT_REQUEST });

    const {
      account: { id: accountId, name: account_name, billing_email },
    } = userData;

    const vals = {
      ...values,
      currency: 'USD',
      country: 'US',
    };

    const postBAError = 'There was an error adding your bank account.';

    const { account_holder_name, account_holder_type } = vals;

    const stripeData = {
      account_holder_name,
      account_holder_type,
      account_name,
      billing_email,
    };

    try {
      const tokenData = await createBankToken(vals);

      stripeData.stripe_token = tokenData.stripe_token;
    } catch (e) {
      dispatch({
        type: CREATE_BA_STRIPE_TOKEN_FAILURE,
        stripeError: e.message,
      });
      return;
    }

    try {
      const resp = await BillingAPI.post(
        `/accounts/${accountId}/bank_accounts`,
        stripeData,
      );
      dispatch({
        type: POST_BANK_ACCOUNT_SUCCESS,
        newBA: resp,
      });
      resetForm();
    } catch (error) {
      const errorMessage = paymentMethodErrorMessage(error, postBAError);

      dispatch({
        type: POST_BANK_ACCOUNT_FAILURE,
        postBAError: errorMessage,
      });
    }
  };

export const postBAV2 =
  ({ values, userData, editBAMode, resetForm }) =>
  async dispatch => {
    if (!editBAMode) {
      dispatch({ type: UPDATE_BANK_ACCOUNT_REQUEST });
      return;
    }
    dispatch({ type: POST_BANK_ACCOUNT_REQUEST });

    const {
      account: { id: accountId, name: account_name, billing_email },
    } = userData;

    const vals = {
      ...values,
      currency: 'USD',
      country: 'US',
    };

    const postBAError = 'There was an error adding your bank account.';

    const { account_holder_name, account_holder_type } = vals;

    const stripeData = {
      account_holder_name,
      account_holder_type,
      account_name,
      billing_email,
    };

    try {
      const tokenData = await createBankToken(vals);

      stripeData.stripe_token = tokenData.stripe_token;
    } catch (e) {
      dispatch({
        type: CREATE_BA_STRIPE_TOKEN_FAILURE,
        stripeError: e.message,
      });
      return;
    }

    try {
      const resp = await BillingAPI.post(
        `/accounts/${accountId}/bank_accounts`,
        stripeData,
      );
      dispatch({
        type: POST_BANK_ACCOUNT_SUCCESS,
        newBA: resp,
      });
      resetForm(resp);
    } catch (error) {
      const errorMessage = paymentMethodErrorMessage(error, postBAError);

      dispatch({
        type: POST_BANK_ACCOUNT_FAILURE,
        postBAError: errorMessage,
      });
    }
  };

export const verifyBA =
  ({ userData, currentBA, values }) =>
  async dispatch => {
    const {
      account: { id: accountId },
    } = userData;
    const { id: bankAccountId } = currentBA;

    values.verify = true;

    dispatch({ type: VERIFY_BANK_ACCOUNT_REQUEST });

    try {
      await BillingAPI.put(
        `/accounts/${accountId}/bank_accounts/${bankAccountId}`,
        values,
      );
      dispatch({
        type: VERIFY_BANK_ACCOUNT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: VERIFY_BANK_ACCOUNT_FAILURE,
        verificationError:
          'The amounts do not match our records. Please try again.',
      });
    }
  };

export const deleteBA =
  ({ userData }) =>
  async dispatch => {
    const {
      account: { id: accountId },
    } = userData;

    dispatch({ type: DELETE_BANK_ACCOUNT_REQUEST });
    try {
      await BillingAPI.delete(`/accounts/${accountId}/bank_accounts`);
      dispatch({
        type: DELETE_BANK_ACCOUNT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DELETE_BANK_ACCOUNT_FAILURE,
        deleteBAError: error || 'Error deleting bank account.',
      });
    }
  };

export const cancelBAEdit = () => dispatch => {
  dispatch({ type: UPDATE_BANK_ACCOUNT_CANCELED });
};

export const postCC =
  ({ values, userData, editCCMode, resetForm }) =>
  async dispatch => {
    if (!editCCMode) {
      dispatch({ type: UPDATE_CREDIT_CARD_REQUEST });
      return;
    }
    dispatch({ type: POST_CREDIT_CARD_REQUEST });

    const {
      account: { id: accountId, name: account_name, billing_email },
    } = userData;

    const postCCError = 'There was an error adding your credit card.';

    const stripeData = {
      account_name,
      billing_email,
    };

    try {
      const tokenData = await createCardToken(values);

      stripeData.stripe_token = tokenData.stripe_token;
      stripeData.name = tokenData.name;
    } catch (e) {
      dispatch({
        type: CREATE_CC_STRIPE_TOKEN_FAILURE,
        stripeError: e.message,
      });
      return;
    }

    try {
      const resp = await BillingAPI.post(
        `/accounts/${accountId}/credit_cards`,
        stripeData,
      );
      dispatch({
        type: POST_CREDIT_CARD_SUCCESS,
        newCC: resp,
      });
      resetForm();
    } catch (error) {
      const errorMessage = paymentMethodErrorMessage(error, postCCError);

      dispatch({
        type: POST_CREDIT_CARD_FAILURE,
        postCCError: errorMessage,
      });
    }
  };

export const deleteCC =
  ({ userData }) =>
  async dispatch => {
    const {
      account: { id: accountId },
    } = userData;

    dispatch({ type: DELETE_CREDIT_CARD_REQUEST });
    try {
      await BillingAPI.delete(`/accounts/${accountId}/credit_cards`);
      dispatch({
        type: DELETE_CREDIT_CARD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CREDIT_CARD_FAILURE,
        deleteCCError: 'Error deleting credit card.',
      });
    }
  };

export const cancelCCEdit = () => dispatch => {
  dispatch({ type: UPDATE_CREDIT_CARD_CANCELED });
};
