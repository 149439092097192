import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Instructions: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t('assess:rules.standard.instructions');

  return <p data-testid='assess-rules-standard-instructions'>{text}</p>;
};

export default Instructions;
