import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import Title from './Title';
import Description from './Description';
import Actions from './Actions';

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
`;

type Props = {
  disabled?: boolean;
  handleSave?: (event: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ disabled, handleSave }) => {
  return (
    <Flex data-testid='assess-settings-header'>
      <div>
        <Title />
        <Description />
      </div>
      <Actions disabled={disabled} onSave={handleSave} />
    </Flex>
  );
};

export default Container;
