import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const Button: React.FC<Props> = ({ onClick, disabled = false }) => {
  const { t } = useTranslation('assess');
  const label = t('settings.v1.adverse_action_automation.geos.select_button');

  return (
    <M.Button
      data-testid='assess-v1-settings-adverse-action-automation-geos-select-button'
      disabled={disabled}
      kind='tertiary'
      onClick={onClick}
      style={{ maxWidth: '100%' }}
    >
      {label}
    </M.Button>
  );
};

export default Button;
