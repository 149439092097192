import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { formatDate } from '../Search';
import { OptionalProps } from './types';

type Props = OptionalProps & {
  type: string;
  handleFormFilling: Function;
  handleValidation?: Function;
};

const ExportDateFilter: React.FC<Props> = ({
  type,
  defaultDateDiffStartFromDays,
  handleFormFilling,
  disableDatesBefore,
  dateRangeHeadline,
  fromFieldLabel = 'From',
  toFieldLabel = 'To',
  dateRangeLimit,
  handleValidation,
}) => {
  const id = type.toLowerCase().split(' ').join('-');
  const currentDate = moment().toISOString();
  const [invalid, setInvalid] = useState(false);

  // Controls the inability to set date before outside the feature date range
  // Default lowest date is undefined. If incoming date is not outside the bounder
  // function return this date, if outside - return lowest day by feature setup
  const overrideOutOfBoundsBeforeDates = useCallback((date: string) => {
    if (disableDatesBefore && date) {
      date =
        new Date(date) < new Date(disableDatesBefore)
          ? disableDatesBefore
          : date;
    }
    return date;
  }, []);

  // Controls the inability to set date after outside the feature date range
  // Default highest date is today. If incoming date is not outside the bounder
  // function return this date, if outside - return highest day by feature setup
  const overrideOutOfBoundsAfterDates = useCallback((date: string) => {
    return new Date(date) > new Date(currentDate) ? currentDate : date;
  }, []);

  // For each date change we should control limits for current feature by
  // highest and lowest date. By default: highest date is today (customer can't
  // setup date in the future), lowest date is not set and customer can setup any date
  // in the past.
  const clipDateWithinFeatureLimits = useCallback((date: string) => {
    date = overrideOutOfBoundsBeforeDates(date);
    return overrideOutOfBoundsAfterDates(date);
  }, []);

  // Default date start from
  // if defaultDateDiffStartFromDays equals 0 then start date will be empty
  const defaultFromDate =
    defaultDateDiffStartFromDays && defaultDateDiffStartFromDays !== 0
      ? moment()
          .subtract(defaultDateDiffStartFromDays, 'days')
          .startOf('day')
          .toISOString()
      : '';

  // Setup default start date to (Today-defaultDateDiffStartFromDays)
  const initialFromDate = clipDateWithinFeatureLimits(defaultFromDate);

  const [fromDateVal, setFromDateVal] = useState(
    initialFromDate ? new Date(initialFromDate).toISOString() : '',
  );
  const [toDateVal, setToDateVal] = useState('');
  const onDateChange = useCallback(
    handler => (date: string) => handler(date),
    [],
  );

  // if dateRangeLimit is setup it controls that dates are not over this range
  const setToDateForDateRangeLimit = useCallback(
    (date: string) => {
      const maxDateForRange = new Date(date);
      maxDateForRange.setDate(
        maxDateForRange.getDate() + (dateRangeLimit || 0),
      );
      const currentToDateVal = new Date(toDateVal || new Date().toISOString());
      let newToDateVal = '';
      if (maxDateForRange < currentToDateVal) {
        setToDateVal(maxDateForRange.toISOString());
        newToDateVal = maxDateForRange.toISOString();
      } else if (new Date(date) > currentToDateVal) {
        setToDateVal(date);
        newToDateVal = date;
      }
      return newToDateVal;
    },
    [toDateVal, setToDateVal],
  );

  const validateDateRange = useCallback(
    (fromDate: string, toDate: string) => {
      const isValid = new Date(toDate) < new Date(fromDate);
      if (handleValidation) {
        setInvalid(isValid);
        handleValidation(isValid);
      }
    },
    [handleValidation],
  );

  const handleFromDate = useCallback(
    (date: string) => {
      date = clipDateWithinFeatureLimits(date);
      let newToDateVal = '';
      if (dateRangeLimit) {
        newToDateVal = setToDateForDateRangeLimit(date);
      }
      validateDateRange(date, toDateVal);
      setFromDateVal(date);
      handleFormFilling(date, toDateVal || newToDateVal);
    },
    [validateDateRange, toDateVal, setFromDateVal],
  );

  const setFromDateForDateRangeLimit = useCallback(
    (date: string) => {
      const minDateForRange = new Date(date);
      minDateForRange.setDate(
        minDateForRange.getDate() - (dateRangeLimit || 0),
      );

      if (minDateForRange > new Date(fromDateVal)) {
        setFromDateVal(minDateForRange.toISOString());
      } else if (new Date(date) < new Date(fromDateVal)) {
        setFromDateVal(date);
      }
    },
    [fromDateVal, setFromDateVal],
  );

  const handleToDate = useCallback(
    (date: string) => {
      date = clipDateWithinFeatureLimits(date);

      // if dateRangeLimit is setup it controls that dates are not over this range
      if (dateRangeLimit && date) {
        setFromDateForDateRangeLimit(date);
      }
      validateDateRange(fromDateVal, date);
      setToDateVal(date);
      handleFormFilling(fromDateVal, date);
    },
    [validateDateRange, fromDateVal, setToDateVal],
  );

  const fromDateChange = useMemo(
    () => onDateChange(handleFromDate),
    [onDateChange, handleFromDate],
  );

  const toDateChange = useMemo(
    () => onDateChange(handleToDate),
    [onDateChange, handleToDate],
  );

  useEffect(() => {
    handleFormFilling(fromDateVal);
  }, [handleFormFilling, fromDateVal]);

  return (
    <M.DateFilter
      helperText={dateRangeHeadline}
      inputs={[
        {
          value: fromDateVal ? formatDate(fromDateVal) : '',
          labelText: fromFieldLabel,
          id: `date-filter-${id}-start`,
          onDateChange: fromDateChange,
          datePickerProps: {
            minDate: disableDatesBefore ? formatDate(disableDatesBefore) : '',
            maxDate: currentDate ? formatDate(currentDate) : '',
          },
          autoComplete: 'off',
        },
        {
          value: toDateVal ? formatDate(toDateVal) : '',
          labelText: toFieldLabel,
          id: `date-filter-${id}-end`,
          onDateChange: toDateChange,
          datePickerProps: {
            minDate: disableDatesBefore ? formatDate(disableDatesBefore) : '',
            maxDate: currentDate ? formatDate(currentDate) : '',
          },
          invalid,
          autoComplete: 'off',
          invalidText: 'Start date must be before End date',
        },
      ]}
    />
  );
};

export default ExportDateFilter;
