import { ADJUDICATION_KINDS } from '@dashboard-experience/utils';
import { GenericObject } from 'types';
import client from './client';

export const ADJUDICATE_PATH = 'adjudicate';

export type SetAdjudicationPayload = {
  adjudication: (typeof ADJUDICATION_KINDS)[keyof typeof ADJUDICATION_KINDS];
  adjudication_subtype: string | null;
};

export const set = (
  reportId: string,
  data: SetAdjudicationPayload,
): Promise<GenericObject> => {
  const path = `reports/${reportId}/${ADJUDICATE_PATH}`;
  return client.post(path, data);
};
