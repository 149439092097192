import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScreeningRow,
  ScreeningText,
  Tooltip,
  InformationIcon,
} from './styles';
import IconCheckmark from './IconCheckmark';

const ScreeningsListItem: React.FC<{ screeningName: string }> = ({
  screeningName,
}) => {
  const { t } = useTranslation('signup');

  return (
    <ScreeningRow>
      <IconCheckmark />
      <ScreeningText>{screeningName}</ScreeningText>
      {screeningName === 'National criminal search' && (
        <Tooltip
          align='right-top'
          label={t(`pages.packagesPage.tooltips.nationalCriminal`)}
        >
          <InformationIcon icon='Information' size='20px' />
        </Tooltip>
      )}
    </ScreeningRow>
  );
};

export default ScreeningsListItem;
