export const accident = ['ACC00', 'ACC10', 'ACC20', 'ACC30'];

export const suspension = [
  'SUS00',
  'SUS01',
  'SUS10',
  'SUS11',
  'SUS20',
  'SUS30',
  'SUS31',
  'SUS32',
  'SUS33',
  'SUS40',
  'SUS50',
  'SUS60',
  'SUS70',
  'SUS71',
];

export const violation = [
  'V0000',
  'VA040',
  'VA080',
  'VA100',
  'VA110',
  'VA120',
  'VA200',
  'VA210',
  'VA220',
  'VA230',
  'VA240',
  'VA250',
  'VA260',
  'VA270',
  'VA300',
  'VA310',
  'VA320',
  'VA330',
  'VA340',
  'VA350',
  'VA400',
  'VA410',
  'VA500',
  'VA510',
  'VA520',
  'VA600',
  'VA610',
  'VA900',
  'VA910',
  'VA940',
  'VA950',
  'VA980',
  'VB010',
  'VB020',
  'VB030',
  'VB040',
  'VB050',
  'VB060',
  'VB070',
  'VB080',
  'VB090',
  'VB100',
  'VB110',
  'VB120',
  'VB130',
  'VB140',
  'VB190',
  'VB200',
  'VB210',
  'VB220',
  'VB230',
  'VB240',
  'VB250',
  'VB260',
  'VB270',
  'VB280',
  'VB290',
  'VB300',
  'VB400',
  'VB410',
  'VB420',
  'VB430',
  'VB440',
  'VB450',
  'VB460',
  'VB500',
  'VB510',
  'VB520',
  'VB530',
  'VB540',
  'VB550',
  'VB560',
  'VB570',
  'VB600',
  'VB610',
  'VB620',
  'VB630',
  'VB640',
  'VB650',
  'VB700',
  'VB710',
  'VB720',
  'VB730',
  'VB740',
  'VB750',
  'VB760',
  'VB770',
  'VB780',
  'VB800',
  'VB810',
  'VB820',
  'VB830',
  'VB840',
  'VB850',
  'VB860',
  'VB870',
  'VB880',
  'VB890',
  'VB900',
  'VB910',
  'VB920',
  'VB930',
  'VD010',
  'VD020',
  'VD030',
  'VD040',
  'VD050',
  'VD060',
  'VD070',
  'VD100',
  'VD110',
  'VD120',
  'VD150',
  'VD160',
  'VD170',
  'VD180',
  'VD190',
  'VD200',
  'VD210',
  'VD250',
  'VD260',
  'VD270',
  'VD280',
  'VD290',
  'VD300',
  'VD310',
  'VD350',
  'VD360',
  'VD370',
  'VD380',
  'VD390',
  'VD400',
  'VD410',
  'VD420',
  'VD430',
  'VD440',
  'VD450',
  'VD500',
  'VD510',
  'VD520',
  'VD530',
  'VD540',
  'VD550',
  'VD560',
  'VD650',
  'VD660',
  'VD670',
  'VD680',
  'VD700',
  'VD710',
  'VD720',
  'VD730',
  'VD740',
  'VD750',
  'VD760',
  'VD770',
  'VD780',
  'VE010',
  'VE020',
  'VE030',
  'VE040',
  'VE050',
  'VE060',
  'VE200',
  'VE210',
  'VE220',
  'VE230',
  'VE240',
  'VE300',
  'VE310',
  'VE320',
  'VE330',
  'VE340',
  'VE350',
  'VE360',
  'VE370',
  'VE500',
  'VE510',
  'VE520',
  'VE530',
  'VE540',
  'VE550',
  'VE560',
  'VE570',
  'VE700',
  'VE710',
  'VE720',
  'VE730',
  'VE740',
  'VE800',
  'VF010',
  'VF020',
  'VF030',
  'VF040',
  'VF050',
  'VF060',
  'VF100',
  'VF110',
  'VF120',
  'VF130',
  'VF140',
  'VF150',
  'VF160',
  'VF200',
  'VF210',
  'VF220',
  'VF230',
  'VF240',
  'VF300',
  'VF310',
  'VF320',
  'VF330',
  'VF340',
  'VF350',
  'VF400',
  'VF410',
  'VF600',
  'VF610',
  'VF620',
  'VF630',
  'VF640',
  'VF650',
  'VF660',
  'VM010',
  'VM020',
  'VM030',
  'VM040',
  'VM050',
  'VM060',
  'VM070',
  'VM080',
  'VM090',
  'VM100',
  'VM110',
  'VM120',
  'VM130',
  'VM140',
  'VM150',
  'VM160',
  'VM170',
  'VM180',
  'VM190',
  'VM200',
  'VM210',
  'VM220',
  'VM230',
  'VM240',
  'VM250',
  'VM300',
  'VM310',
  'VM320',
  'VM330',
  'VM340',
  'VM400',
  'VM410',
  'VM420',
  'VM430',
  'VM440',
  'VM450',
  'VM460',
  'VM470',
  'VM480',
  'VM490',
  'VM500',
  'VM510',
  'VM520',
  'VM530',
  'VM540',
  'VM550',
  'VM560',
  'VM570',
  'VM580',
  'VM600',
  'VM610',
  'VM620',
  'VM700',
  'VM710',
  'VM720',
  'VM730',
  'VM740',
  'VM750',
  'VM760',
  'VM770',
  'VM800',
  'VM810',
  'VM820',
  'VM830',
  'VM840',
  'VM850',
  'VM860',
  'VN010',
  'VN020',
  'VN030',
  'VN040',
  'VN050',
  'VN060',
  'VN070',
  'VN080',
  'VN090',
  'VN200',
  'VN210',
  'VN220',
  'VN230',
  'VN240',
  'VN250',
  'VN260',
  'VN300',
  'VN310',
  'VN400',
  'VN410',
  'VN420',
  'VN430',
  'VN440',
  'VN500',
  'VN510',
  'VN520',
  'VN530',
  'VN540',
  'VN550',
  'VN560',
  'VN600',
  'VN610',
  'VN620',
  'VN630',
  'VN700',
  'VN710',
  'VN720',
  'VN800',
  'VN810',
  'VN820',
  'VN830',
  'VN840',
  'VS010',
  'VS060',
  'VS110',
  'VS140',
  'VS150',
  'VS160',
  'VS210',
  'VS260',
  'VS310',
  'VS360',
  'VS410',
  'VS500',
  'VS510',
  'VS610',
  'VS710',
  'VS810',
  'VS910',
  'VS920',
  'VS930',
  'VS940',
  'VS950',
  'VS960',
  'VS970',
  'VS980',
  'VS990',
  'VU010',
  'VU020',
  'VU030',
  'VU040',
  'VU050',
  'VU060',
  'VU070',
  'VU080',
  'VU090',
  'VU100',
  'VU200',
  'VU210',
  'VU220',
  'VU230',
  'VU240',
  'VU250',
  'VU260',
  'VU300',
  'VU310',
  'VU320',
  'VU330',
];

const classification_codes = {
  accident,
  suspension,
  violation,
};

export default classification_codes;
