import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Note from '../Note';

const ApiKeysHeader = () => (
  <M.Container.Row>
    <M.Container.Col>
      <Note>
        <span>This section allows you to manage your API keys.</span>
        <span>
          <strong>Secrets</strong> can be used server-side for any API calls.
        </span>
        <span>
          <strong>Publishable</strong> tokens can be used on your webpage to
          create Candidates only (see{' '}
          <a
            href='https://github.com/checkr/checkr-js'
            target='_blank'
            rel='noopener noreferrer'
          >
            Checkr.js
          </a>
          ).
        </span>
      </Note>
    </M.Container.Col>
  </M.Container.Row>
);

export default ApiKeysHeader;
