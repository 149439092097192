import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { getDollarAmount } from './utils';
import LearnMoreLink from './LearnMoreLink';

const PriceSpan = styled.span`
  @media (max-device-width: 530px) {
    margin-left: 20px;
  }
  color: ${colors.brandNavy3};
  font-size: 14px;
`;

const getDisplayValueForAddonsPrice = (screening: any) => {
  if (
    screening.product_type.includes('drug_screening') ||
    screening.product_type.includes('occupational_health_screening')
  ) {
    return 'Price varies';
  }
  if (screening.product_type.includes('employment_verification')) {
    return getDollarAmount(screening.base_price);
  }
  return getDollarAmount(screening.base_price);
};

const AddOnsSummaryPrice: React.FC<any> = ({ screening, className, data }) => {
  if (screening.name.includes('International Criminal Search Charges')) {
    return (
      <span className={className}>
        <LearnMoreLink
          linkText='Price varies'
          href='https://checkr.com/pricing/international'
        />
      </span>
    );
  }
  if (screening.product_type.includes('international')) {
    return <span className={className} />;
  }
  return (
    <PriceSpan className={className}>
      {getDisplayValueForAddonsPrice(screening)}
    </PriceSpan>
  );
};

export default AddOnsSummaryPrice;
