/* eslint-disable no-unused-vars */
import { Entity } from 'modules/entity';
import { Initializer } from 'state/utilities/state';

export interface Type extends Entity, Params {}

export interface Params {
  kind: Kind;
  time?: Date;
  timeout?: number;
  title: string;
  message?: string;
  action?: {
    callback: Function;
    message: string;
  };
  key?: string;
}

export enum Kind {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export const initialize: Initializer<Params> = (params: Params) => ({
  time: new Date(),
  ...params,
});

export const error: Initializer<Params> = (params: Omit<Params, 'kind'>) => {
  return initialize({ kind: Kind.error, ...params });
};

export const info: Initializer<Params> = (params: Omit<Params, 'kind'>) => {
  return initialize({ kind: Kind.info, ...params });
};

export const success: Initializer<Params> = (params: Omit<Params, 'kind'>) => {
  return initialize({ kind: Kind.success, ...params });
};

export const warning: Initializer<Params> = (params: Omit<Params, 'kind'>) => {
  return initialize({ kind: Kind.warning, ...params });
};

export type List = Array<Type>;
export type Map = { [key: string]: Type };
