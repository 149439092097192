export type TReducerEvent = {
  processing: boolean;
  success: boolean;
  error: boolean;
  successMsg: string;
  errorMsg: string;
};

const initialState = Object.freeze<TReducerEvent>({
  processing: false,
  success: false,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onRequest = Object.freeze<TReducerEvent>({
  processing: true,
  success: false,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onSuccess = Object.freeze<TReducerEvent>({
  processing: false,
  success: true,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onFailure = Object.freeze<TReducerEvent>({
  processing: false,
  success: false,
  error: true,
  successMsg: '',
  errorMsg: '',
});

export const httpHelper = {
  initialState,
  onRequest,
  onSuccess,
  onFailure,
};

export default httpHelper;
