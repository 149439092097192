import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

/**
 * @name getEventCategory
 * @function
 * @memberOf Assessment
 * @description Determines the human-friendly category of the event
 * @returns {string}
 */
const getEventCategory = () => {
  return 'Assessment:';
};

/**
 * @name getEventAction
 * @function
 * @memberOf Assessment
 * @description Determines the human-friendly event action
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventAction = (timelineEvent: TimelineEvent) => {
  if (timelineEvent.to.value) {
    return `updated to "${timelineEvent.to.value}"`;
  }

  /**
   * If the event is a 'create' event for an assessment,
   * OR if the event is updating assessment.value from string to null.
   * The latter situation happens when a report is upgraded,
   * and its original assessment value is nullified while processing the new one.
   */
  const isUpdatingDueToReassessment =
    typeof timelineEvent.from === 'object' &&
    typeof timelineEvent?.from?.value === 'string' &&
    timelineEvent.to.value === null;

  if (timelineEvent.event === 'create' || isUpdatingDueToReassessment) {
    return `processing`;
  }

  return null;
};

const Assessment: React.FC<{ timelineEvent: TimelineEvent }> = ({
  timelineEvent,
}) => {
  const eventCategory = getEventCategory();
  const eventAction = getEventAction(timelineEvent);
  return eventCategory && eventAction ? (
    <M.TableRow>
      <TimelineCell>
        <EventName>{`${eventCategory} ${eventAction}`}</EventName>
      </TimelineCell>
      <TimelineCell>
        {timelineEvent.who !== 'system' && timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  ) : null;
};

export default Assessment;
