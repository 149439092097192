import React, { useCallback, useState } from 'react';
import * as Entity from 'modules/assess/models/rules/count';
import styled from 'styled-components';
import { getCountRuleDecisions } from 'modules/assess/models';
import { M, colors } from '@dashboard-experience/mastodon';
import { initializeComplexRule } from 'modules/assess/models/rules/count/entity';
import Separator from 'modules/assess/ui/rules/common/rule/editor/separator';
import { useValidationRules } from 'modules/assess/ui/rules/common/rule/hooks';
import Name from 'modules/assess/ui/rules/common/rule/editor/name';
import Assessment from 'modules/assess/ui/rules/common/rule/editor/assessment';
import ScrollIntoViewWrapper from 'modules/assess/ui/common/scrollInViewWrapper';
import AddGroupButton from './add-group-button';
import TopGroup from './top-group';
import Save from './save';
import Cancel from '../cancel';
import RowContainer from './row-container';
import FooterContainer from './footer-container';

const Grid = styled(M.Grid)`
  color: ${colors.uiTextPrimaryLight};
  background-color: ${colors.uiNavy100};
  padding-left: 1rem !important;
  padding-right: 1rem !important;
`;

const GRID_SIZE = {
  lg: 8,
  md: 3,
  sm: 1,
};

export type Props = {
  rule: Entity.Type;
};

const ComplexEditor: React.FC<Props> = ({ rule }) => {
  const [draftRule, setDraftRule] = useState(rule);
  const invalid = useValidationRules(draftRule);

  const buildTopGroup = () => initializeComplexRule().conditions[0];

  const addTopGroup = useCallback(() => {
    setDraftRule((oldDraftRule: any) => {
      const conditions = [...oldDraftRule.conditions, buildTopGroup()];
      return { ...oldDraftRule, conditions };
    });
  }, []);

  return (
    <ScrollIntoViewWrapper>
      <Grid data-testid='assess-complex-rule-editor'>
        <RowContainer>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-name'
          >
            <Name
              name={draftRule.name}
              setDraftRule={setDraftRule}
              invalid={invalid}
            />
          </M.GridCol>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-assessment'
          >
            <Assessment
              decisions={getCountRuleDecisions()}
              decision={draftRule.decision}
              setDraftRule={setDraftRule}
            />
          </M.GridCol>
        </RowContainer>

        {draftRule.conditions.map((rule, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`topgroup-${index}`}>
            {index > 0 && <Separator labelTranKey='assess:common.and' />}
            <TopGroup
              draftRule={draftRule}
              setDraftRule={setDraftRule}
              index={index}
            />
          </div>
        ))}
        <AddGroupButton onClick={addTopGroup} />
        <FooterContainer>
          <Cancel rule={draftRule} />
          <Save rule={draftRule} />
        </FooterContainer>
      </Grid>
    </ScrollIntoViewWrapper>
  );
};

export default ComplexEditor;
