import React from 'react';
import { ReportProps } from 'types';
import FederalDistrictSearch from './FederalDistrictSearch';

const FederalDistrictCriminalSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { federal_district_criminal_searches } = report;
  if (!federal_district_criminal_searches?.length) return null;

  return (
    <FederalDistrictSearch
      report={report}
      reportStatusType={reportStatusType}
      federalType='federal-district-criminal-searches'
    />
  );
};

export default FederalDistrictCriminalSearch;
