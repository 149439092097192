import { M } from '@dashboard-experience/mastodon';
import React, { useCallback, useEffect, useState } from 'react';
import { useMVRCustomCategories } from 'modules/assess/ui/hooks';
import { useSearch } from '../api';
import Table from './table/container';

const PAGE_SIZE = 10;

export type SearchProps = {};

export const Search: React.FC<SearchProps> = () => {
  const [name, setName] = useState('');
  const [page, setPage] = useState(0);

  const query = useSearch({
    name,
    page: page + 1,
    per_page: PAGE_SIZE,
  });
  const result = query?.data;
  const pageCount = Math.ceil((result?.count || 0) / PAGE_SIZE);

  useEffect(() => {
    if (pageCount && page >= pageCount) {
      setPage(pageCount - 1);
    }
  }, [page, pageCount]);

  const onSearchChange = useCallback(e => {
    setName(e.target.value);
  }, []);

  return (
    <div data-testid='assess-mvr-category-sets-search'>
      <div style={{ width: '50%' }}>
        <M.Search onChange={onSearchChange} defaultValue={name} />
      </div>

      <Table
        data={result?.data}
        page={{ count: pageCount, index: page, onClick: setPage }}
      />
    </div>
  );
};

export const Wrapper: React.FC<SearchProps> = props => {
  const assessMVRCustomCategories = useMVRCustomCategories();

  if (!assessMVRCustomCategories) return null;

  return <Search {...props} />;
};

export default Wrapper;
