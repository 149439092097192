import React from 'react';
import { ID } from 'modules/id';
import { Container } from '../common';

import { Table } from './table';
import Description from './Description';
import ImportExport from './ImportExport';

type Props = {
  disabled?: boolean;
  ruleset?: ID;
  version?: ID;
};

const View: React.FC<Props> = ({ disabled, ruleset, version }) => {
  return (
    <Container data-testid='assess-rules-lookback-view'>
      <Description />
      <ImportExport disabled={disabled} ruleset={ruleset} version={version} />
      <Table disabled={disabled} />
    </Container>
  );
};

export default View;
