import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Body from 'components/FairChance/Content';
import Sections from 'components/FairChance/sections';
import Subsections from '../subsections';

const Container = styled(M.Container)`
  box-shadow: 0px 4px 8px rgba(22, 22, 29, 0.1);
  border-radius: 4px;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
`;

type Props = {
  content: any;
  subsection: Subsections;
};

const Content: React.FC<Props> = ({ content, subsection }) => {
  const { paragraphs, title } = content;
  const body = (
    <Body
      paragraphs={paragraphs}
      section={Sections.engagement}
      subsection={subsection}
    />
  );

  return (
    <Container type='shadow'>
      <h4>{title}</h4>
      {body}
    </Container>
  );
};

export default Content;
