/* eslint-disable no-console */
import {
  purgeSessionStorage,
  GenericObject,
} from '@dashboard-experience/utils';
import { handleTokenExpired } from 'actions';
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  AMS_API_BASE_URL,
  CHECKR_API_BASE_URL,
  CHECKR_API_EU_BASE_URL,
  CHECKR_BILLING_URL,
  PARTNER_API_BASE_URL,
  SEARCH_API_BASE_URL,
  READ_ONLY,
  SAVED_SEARCH_API_BASE_URL,
  EXPUNGEMENTS_API_URL,
  ATS_INTEGRATION_API_BASE_URL,
} from 'Constants';
import { getAppropriateToken } from 'utils';

// After updating to axios version 0.28.0 TypeScript was inferring the type of the error object as 'never' with ES6
// import syntax, causing type errors. Using require ensures correct typing and prevents these TypeScript errors.
const axios = require('axios').default;

export const handleApiError = (
  error: AxiosError,
  throwError: boolean = true, // Changing this to false will prevent ALL bubbling up of errors -- even react-query's "onError" handling
) => {
  if (axios.isCancel(error)) {
    return null;
  }

  // If there was a response from the server, see if it was a 401, and act accordingly
  if (error.response) {
    if (error.response.status === 401) {
      console.info(
        'Error: 401 Response on',
        error.response.request.responseURL,
      );
      purgeSessionStorage();
      handleTokenExpired();
    } else {
      console.error('Error: Response', error.response);
    }
  }

  // If there was no `response` object, then it was an error that happened without even hitting the server
  // Just log the request itself then
  else if (error.request) {
    console.error('Error: Request', error.request, error.config);
  }

  // If there somehow wasn't even a `request` object, just log the message itself
  else {
    console.error('Error', error.message, error.config);
  }

  if (throwError) throw error;

  return error;
};

/**
 * After a successful API call, this function returns the relevant details from the response.
 * @param response Incoming - response object from the Axios call
 * @param returnFullResponse - Whether to return the ENTIRE response object, or JUST the data from inside of it
 */
export const handleApiSuccess = (
  response: AxiosResponse,
  returnFullResponse: boolean,
) => {
  // Some special scenarios will want to see all of the response (e.g. status code, headers, config, etc.)
  if (returnFullResponse) {
    return response;
  }

  // To streamline the handling of data for hooks, in most scenarios we just directly return the inner data:
  return response.data;
};

export const extractError = (e: AxiosError, field?: string) => {
  if (e.response) {
    // Backend doesn't always return errors in the same format, sometimes its plaintext, sometimes its a hash,
    // and sometimes its an array or string nested under error, errors, or message keys
    const { data } = e.response;

    if (typeof data === 'object' && data !== null) {
      const dataObj = data as { [key: string]: any };
      if (field && dataObj[field]) return dataObj[field];
      // extract a specific field
      if (dataObj.error) return dataObj.error;
      if (dataObj.errors) return dataObj.errors;
      if (dataObj.message) return dataObj.message;
    }
    return data as any;
  }
  return e.message; // default to the error message if it was an issue with the request
};

export type RequestConfig = AxiosRequestConfig;

/**
 * downloadBlobAsFile - takes data from the server and will save it to the user's computer as a file
 * @param {Blob} data - the raw response from the server
 * @param {any} headers - specification of what type of file to be downloaded
 * @param {string} filename - what to name the file as
 */
export const downloadBlobAsFile = ({
  data,
  headers,
  filename,
}: {
  data: Blob;
  headers: any;
  filename?: string;
}) => {
  const blob = new Blob([data], { type: headers['content-type'] });
  const href = URL.createObjectURL(blob);

  // Creates an invisible <a> tag
  const element = document.createElement('a');
  element.setAttribute('href', href);
  element.setAttribute('download', filename || '');
  element.style.display = 'none';

  // Add the <a> to the document, click on it, and then remove it from the document
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export class APIClient {
  readonly client: AxiosInstance;

  constructor(baseURL?: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        common: {
          'Content-Type': 'application/json',
        },
      },
    });
  }

  download(path: string, name?: string, options: RequestConfig = {}) {
    this.setToken();
    return this.client
      .get(path, options)
      .then(({ data, headers }) => {
        downloadBlobAsFile({ data, headers, filename: name });
      })
      .catch(handleApiError);
  }

  /**
   * Implemented especially for pdf-utils, this function will transmit data up to the specified endpoint, and then download the response as a file
   * @param {string} path - API endpoint to be called
   * @param {GenericObject} data - Data to be sent to the endpoint
   * @param {string} filename - Name of the file to download as
   * @param {RequestConfig} options - Axios config options
   * @returns {Promise}
   */
  downloadViaPOST({
    path,
    data,
    filename,
    options = {},
  }: {
    path: string;
    data: GenericObject;
    filename?: string;
    options?: RequestConfig;
  }) {
    this.setToken();

    return this.client.post(path, data, options).then(({ data, headers }) => {
      downloadBlobAsFile({ data, headers, filename });
    });
  }

  get(
    path: string,
    options: RequestConfig = {},
    throwError: boolean = true,
    returnFullResponse: boolean = false,
  ) {
    this.setToken();
    return this.client
      .get(path, options)
      .then(response => handleApiSuccess(response, returnFullResponse))
      .catch(error => handleApiError(error, throwError));
  }

  patch(path: string, params = {}, returnFullResponse: boolean = false) {
    if (READ_ONLY)
      return Promise.reject(
        new Error('Application is running in READ_ONLY mode'),
      );
    this.setToken();
    return this.client
      .patch(path, params)
      .then(response => handleApiSuccess(response, returnFullResponse))
      .catch(handleApiError);
  }

  post(
    path: string,
    params = {},
    options = {},
    returnFullResponse: boolean = false,
  ) {
    if (READ_ONLY)
      return Promise.reject(
        new Error('Application is running in READ_ONLY mode'),
      );
    this.setToken();
    return this.client
      .post(path, params, options)
      .then(response => handleApiSuccess(response, returnFullResponse))
      .catch(handleApiError);
  }

  put(path: string, params = {}, returnFullResponse: boolean = false) {
    if (READ_ONLY)
      return Promise.reject(
        new Error('Application is running in READ_ONLY mode'),
      );
    this.setToken();
    return this.client
      .put(path, params)
      .then(response => handleApiSuccess(response, returnFullResponse))
      .catch(handleApiError);
  }

  delete(path: string, returnFullResponse: boolean = false) {
    if (READ_ONLY)
      return Promise.reject(
        new Error('Application is running in READ_ONLY mode'),
      );
    this.setToken();
    return this.client
      .delete(path)
      .then(response => handleApiSuccess(response, returnFullResponse))
      .catch(handleApiError);
  }

  setToken() {
    // For an API call, we might need to either send the true user token or the token of whomever they're impersonating
    const token = getAppropriateToken();

    this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  getClient() {
    return this.client;
  }

  getBaseURL() {
    return this.client.defaults.baseURL;
  }

  uri(config?: RequestConfig) {
    return this.client.getUri(config);
  }
}

export const API = new APIClient(CHECKR_API_BASE_URL);
export const API_EU = new APIClient(CHECKR_API_EU_BASE_URL);
export const BillingAPI = new APIClient(CHECKR_BILLING_URL);
export const PartnerAPI = new APIClient(PARTNER_API_BASE_URL);
export const AmsAPI = new APIClient(AMS_API_BASE_URL);
export const SearchAPI = new APIClient(SEARCH_API_BASE_URL);
export const SavedSearchAPI = new APIClient(
  `${SAVED_SEARCH_API_BASE_URL}/account_searches`,
);
export const ExpungementsClient = new APIClient(EXPUNGEMENTS_API_URL);
export const AtsIntegrationAPI = new APIClient(ATS_INTEGRATION_API_BASE_URL);
