import { Callback, GenericObject } from '@dashboard-experience/utils';
import { DEVELOPERS_URL } from 'Constants';
import { debug, getAppropriateToken, shouldUseImpersonationData } from 'utils';
import { LocationData, NotificationData, ParamsData } from './types';

const logOutgoingMessage = (message: any) => {
  debug(`[Customer] Sending message to Dev Dashboard: ${message.messageType}`);
};

const logIncomingMessage = ({ state, ...props }: { state: string }) => {
  debug(`[Customer] Received Dev Dashboard data:`, state, props);
};

const logDevDashError = () => {
  debug(
    `[Customer] Cannot set up listener to Dev Dashboard, iframe window has not finished loading.`,
  );
};

// Initialize the connection to the DevEx iframe
export const sendInitialDataMessage = (userEmail: string) => {
  if (!DEVELOPERS_URL) return; // If the URL isn't declared, then exit pre-emptively

  const iframe = document.getElementById(
    'developer-dashboard-iframe',
  ) as HTMLIFrameElement;

  if (!iframe?.contentWindow) {
    logDevDashError();
    return; // If the iframe can't be found, then exit pre-emptively
  }

  const message = {
    messageType: 'loadInitialData',
    token: getAppropriateToken(),
    parentDashboardType: 'customer',
    isImpersonated: shouldUseImpersonationData(), // check if impersonator is enabled

    // If there are any additional Flags on the DevEx Dashboard side, these are the user properties that get used to test them:
    flagrContextID: {
      key: 'user_email',
      value: userEmail,
      entityType: 'email',
    },
  };

  logOutgoingMessage(message);

  iframe.contentWindow.postMessage(message, DEVELOPERS_URL);
};

// Replaces the current URL with the specified one
// We are replacing instead of adding, specifically because the iframe itself already does its own history manipulation
export const replaceURL = (newPath: string) => {
  window.history.replaceState({}, '', newPath);
};

// Takes in the params object, turns it into a string, and then adds it to the current URL
export const replaceParams = (paramObj: GenericObject) => {
  const paramString = new URLSearchParams(paramObj).toString();

  replaceURL(`${window.location.pathname}?${paramString}`);
};

export const updatedLocationHandler = ({ data }: { data: LocationData }) => {
  logIncomingMessage(data);
  replaceURL(data.URL);
};

export const updatedParamsHandler = (event: MessageEvent) => {
  // eslint-disable-next-line prefer-destructuring
  const params: ParamsData = event.data.params;

  logIncomingMessage(event.data);

  replaceParams(params);
};

export const badTokenHandler = (event: MessageEvent) => {
  logIncomingMessage(event.data);
  window.location.href = '/logout';
};

export const notificationHandler = (event: MessageEvent, toastFn: Callback) => {
  // eslint-disable-next-line prefer-destructuring
  const notification: NotificationData = event.data.notification;

  logIncomingMessage(event.data);

  const {
    title,
    body: message,
    timeout,
    type: kind, // These will only ever be Success or Error
  } = notification;

  toastFn({ title, message, timeout, kind });
};
