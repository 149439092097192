import React from 'react';
import {
  RightSideContainer,
  PriceSection,
  SubtotalContainer,
  SubtotalText,
  SubtotalPrice,
  ItemizedTotal,
  StyledFooter,
  SecondaryButton,
  PrimaryButton,
} from './styles';

type Props = {
  numCandidates?: number;
  packagePrice?: string;
  subtotal?: string;
  primaryBtnText: string;
  secondaryBtnText?: string;
  secondaryOnClick?: () => void;
  primaryButtonProps?: PrimaryButtonProps;
};

export type PrimaryButtonProps = {
  disabled: boolean;
  onClick: Function;
};

const Footer = ({
  numCandidates,
  packagePrice,
  subtotal,
  primaryBtnText,
  secondaryBtnText,
  secondaryOnClick,
  primaryButtonProps,
}: Props) => {
  return (
    <StyledFooter className='styled-footer'>
      {secondaryBtnText && (
        <SecondaryButton
          className='secondary-button'
          onClick={secondaryOnClick}
          data-testid='secondary-button'
          kind='tertiary'
          size='md'
        >
          {secondaryBtnText}
        </SecondaryButton>
      )}
      <RightSideContainer>
        <PriceSection>
          {subtotal && (
            <SubtotalContainer>
              <SubtotalText>Subtotal</SubtotalText>
              <SubtotalPrice data-testid='subtotal-price'>
                {' '}
                {subtotal}*
              </SubtotalPrice>
            </SubtotalContainer>
          )}
          {numCandidates !== undefined && numCandidates > 0 && (
            <ItemizedTotal data-testid='itemized-total'>
              {numCandidates} candidates × {packagePrice} per report
            </ItemizedTotal>
          )}
        </PriceSection>
        <PrimaryButton
          className='primary-button'
          kind='primary'
          data-testid='primary-button'
          size='md'
          {...primaryButtonProps}
        >
          {primaryBtnText}
        </PrimaryButton>
      </RightSideContainer>
    </StyledFooter>
  );
};

export default Footer;
