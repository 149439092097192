import React from 'react';
import { CsvExport, useCsvExport, ModalType } from 'components/CsvExport';
import {
  DriverRecordReportsCSVExportFilters,
  useContinuousCheckCsv,
} from 'api/csvExport';

const Body = () => (
  <>
    <p>
      Choose a date range up to 90 days in length. Please contact Checkr if you
      need to download more than 90 days of information.
    </p>
    <p>Downloads are limited to 100,000 rows.</p>
  </>
);

const disableDatesBefore = '01/01/2021';

type Filters = Partial<DriverRecordReportsCSVExportFilters>;

const DriverRecordReportsExportCsv: React.FC<{}> = () => {
  const { screen, open, toggle, setScreen } = useCsvExport();
  const { call, result } = useContinuousCheckCsv(setScreen);

  const processing = result?.isLoading;
  const error = result?.isError;
  const errorMsg = result?.error;

  const filters: Filters = {
    export_type: 'document_audit_log',
  };

  return (
    <CsvExport
      body={<Body />}
      error={error}
      errorMsg={errorMsg}
      filters={filters}
      open={open}
      post={call}
      processing={processing}
      screen={screen}
      toggle={toggle}
      type={ModalType.DriverRecordReports}
      modalHeader='Download DL 414 audit report'
      dateRangeHeadline='Date range'
      submitButtonName='Download'
      disableDatesBefore={disableDatesBefore}
      disableSubmitButtonUntilSetupDates
      dateRangeLimit={90}
      defaultDateDiffStartFromDays={0}
    />
  );
};

export default DriverRecordReportsExportCsv;
