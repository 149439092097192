import { API as Client } from 'utils/APIClient';

export type ArchiveReportParams = {
  candidate_report_ids: {
    candidate_id: string;
    report_id: string;
  }[];
  user_resource_id: string;
};

export type ArchiveInvitationParams = {
  candidate_invitation_ids: {
    candidate_id: string;
    invitation_id: string;
  }[];
  user_resource_id: string;
};

export const archiveReports = (params: ArchiveReportParams) => {
  return Client.patch(`/reports/archive`, params);
};

export const unarchiveReports = (params: ArchiveReportParams) => {
  return Client.patch(`/reports/unarchive`, params);
};

export const archiveInvitations = (params: ArchiveInvitationParams) => {
  return Client.patch(`/invitations/archive`, params);
};

export const unarchiveInvitations = (params: ArchiveInvitationParams) => {
  return Client.patch(`/invitations/unarchive`, params);
};
