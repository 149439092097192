/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React, { useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { isInternal } from '@dashboard-experience/utils';
import { useTranslation, Trans } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import { dashboardInternalUrl, INTERNAL_BANNER_FLAG_KEY } from 'Constants';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import { isDashboardBeta } from 'utils';

const ActionableNotification = styled(M.ActionableNotification)`
  #mastodon && {
    max-width: 100%;
    margin-top: 0;
    flex-shrink: 0;

    .cds--actionable-notification__title {
      cursor: pointer;
      margin-bottom: 0.75rem;
    }
    .cds--actionable-notification__details {
      margin: 0 2rem;
    }
  }
`;

const key = 'banners.internal';
export const JIRA_JSD_LINK =
  'https://checkr.atlassian.net/servicedesk/customer/portal/65';

const DashboardInternalBanner: React.FC<{}> = () => {
  const user = useUser();
  const { isIframe } = useContext(UIContext);
  const { pathname, search, hash } = useLocation();
  const { t } = useTranslation();
  const title = t(`${key}.title`);
  const subtitle = `${key}.subtitle`;

  const internalBannerFlag =
    useFlag(INTERNAL_BANNER_FLAG_KEY)?.variantKey === 'on';
  const route = `${pathname}${search}`;
  const internalUrl = `${dashboardInternalUrl}${route}${hash}`;

  const redirectToInternalDashboard = useCallback(() => {
    window.location.replace(internalUrl);
  }, [internalUrl]);

  return !isIframe &&
    !isDashboardBeta() &&
    internalBannerFlag &&
    isInternal(user) ? (
    <ActionableNotification
      hideActionButton
      hideCloseButton
      kind='warning'
      title={
        <div role='button' tabIndex={0} onClick={redirectToInternalDashboard}>
          <span>{title}</span>
        </div>
      }
      subtitle={
        <Trans
          t={t}
          i18nKey={subtitle}
          components={[
            <M.Link
              role='link'
              key={JIRA_JSD_LINK}
              href={JIRA_JSD_LINK}
              target='_blank'
              rel='noopener noreferrer'
            />,
          ]}
        />
      }
      data-testid='dashboard-internal-banner'
    />
  ) : null;
};

export default DashboardInternalBanner;
