import React, { ReactElement } from 'react';
import {
  AACSVExportFilters as AAFilters,
  CandidatesCSVExportFilters,
  CSVExportParams,
  DriverRecordReportsCSVExportFilters,
  SubscriptionErrorsCSVExportFilters,
} from 'api/csvExport';

import CsvExportModal from './CsvExportModal';
import { ModalType, OptionalProps, Screens } from './types';
import { useCsvError } from './hooks';
import { CandidateExportText } from '../Search/Filters/StyledComponents';
import CsvExportButton from './CsvExportButton';

type AACSVExportFilters = Partial<AAFilters>;

type Props = OptionalProps & {
  body: ReactElement;
  downloadCsvDisabled?: boolean;
  error: boolean;
  errorMsg: any;
  filters:
    | AACSVExportFilters
    | CandidatesCSVExportFilters
    | DriverRecordReportsCSVExportFilters
    | SubscriptionErrorsCSVExportFilters;
  open: boolean;
  post: (params: CSVExportParams) => Promise<any>;
  processing: boolean;
  screen: Screens.INITIAL | Screens.SUCCESS;
  toggle: () => void;
  type:
    | ModalType.AdverseAction
    | ModalType.CandidateReports
    | ModalType.DriverRecordReports
    | ModalType.SubscriptionErrors;
};

const CsvExport: React.FC<Props> = ({
  body,
  downloadCsvDisabled = false,
  error,
  errorMsg,
  filters,
  open,
  post,
  processing,
  screen,
  toggle,
  type,
  modalHeader,
  dateRangeHeadline,
  submitButtonName,
  defaultDateDiffStartFromDays,
  dateRangeLimit,
  disableDatesBefore,
  disableSubmitButtonUntilSetupDates,
}) => {
  useCsvError(error, errorMsg);

  let exportButton;
  if (type === ModalType.SubscriptionErrors) {
    exportButton = (
      <CandidateExportText
        data-testid='download-error-history-link'
        onClick={toggle}
      >
        Download error history
      </CandidateExportText>
    );
  } else if (type === ModalType.DriverRecordReports) {
    exportButton = (
      <CandidateExportText onClick={toggle}>
        Download audit report
      </CandidateExportText>
    );
  } else {
    exportButton = <CsvExportButton toggle={toggle} />;
  }

  return (
    <>
      {exportButton}
      {open && (
        <CsvExportModal
          body={body}
          filters={filters}
          open={open}
          post={post}
          processing={processing}
          screen={screen}
          toggle={toggle}
          type={type}
          downloadCsvDisabled={downloadCsvDisabled}
          modalHeader={modalHeader}
          dateRangeHeadline={dateRangeHeadline}
          defaultDateDiffStartFromDays={defaultDateDiffStartFromDays}
          submitButtonName={submitButtonName}
          dateRangeLimit={dateRangeLimit}
          disableDatesBefore={disableDatesBefore}
          disableSubmitButtonUntilSetupDates={
            disableSubmitButtonUntilSetupDates
          }
        />
      )}
    </>
  );
};

export default CsvExport;
