/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Route, useHistory } from 'react-router-dom';
import UIContext from 'context/UI';
import styled from 'styled-components';
import { updateParentWindowUrl, postMessageToDashboard } from 'utils';
import { M, colors } from '@dashboard-experience/mastodon';
import { localStorageFallback } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { useFlag } from '@dashboard-experience/react-flagr';
import { HIDE_CREATE_PACKAGE_SUCCESS_TOAST } from 'Constants';

import { useIncidents } from 'components/ApiStatusBanner';
import Steps from 'components/Steps';
import { useAddPackage } from 'api/packages';
import GetStarted from 'components/Packages/CreatePackage/GetStarted';
import SelectPackage from 'components/Packages/CreatePackage/SelectPackage/SelectPackageIndex';
import Customize from 'components/Packages/CreatePackage/Customize';
import { useDashboardRelay, useDashboardWindowScroll } from 'hooks';
import { DOMESTIC_GEO, useCreatePackageContext } from './Context';
import {
  PACKAGE_BUILDER_EVENT_NAMES,
  useTrackEvent,
  BasePackageTypes,
} from '../Amplitude/analytics';

const Container = styled.div`
  min-width: 600px;
  margin: 0 auto;
`;

const TopBar = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 10px auto;
  padding-bottom: 9px;
  padding: 9px 0;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.uiGrey0} !important;

  .short-text {
    display: none;
  }

  @media (max-width: 672px) {
    .short-text {
      display: inline-block;
    }
    .full-text {
      display: none;
    }
  }

  h3 {
    margin: 0 0 0 3rem !important;
    letter-spacing: 0;
    color: ${colors.uiTextPrimaryLight};
  }
`;

const InnerContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px !important;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media (min-width: 672px) {
    // flex-direction: row;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem !important;
`;

const SubHeaderContainer = styled.div`
  display: flex !important;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
`;

const MaxWidth = styled.div<MaxWidthProps>`
  position: sticky;
  background-color: ${colors.uiGrey0} !important;
  z-index: 10;
  margin: auto;
  width: 100%;
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid ${colors.uiGrey200};
  height: 62px;
  top: ${({ top }) => top}px;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
`;

const ContinueButton = styled(M.Button)`
  background-color: ${colors.brandNavy2};
`;

const SavePackageButton = styled(M.Button)`
  background-color: ${colors.brandNavy2};
`;

const Button = styled(M.Button)`
  height: 50px;
  padding: 10px !important;
  padding-right: 14px !important;
  margin-top: -15px !important;
  margin-left: 0px;
  margin-bottom: 1rem !important;
  font-weight: normal !important;
  &:hover:enabled {
    background-color: ${colors.brandSlate2} !important;
  }
`;

const StepsContainer = styled.div`
  margin-left: 0px;
`;

const RoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ADDITIONAL_PADDING_SUBHEADER = 24;
const INCIDENTS_BANNER_HEIGHT = 30;

export enum PATHS {
  PACKAGES = '/packages',
  GET_STARTED = '/package/get-started',
  SELECT_PACKAGE = '/package/select-package',
  CUSTOMIZE = '/package/customize',
}

type MaxWidthProps = {
  top: number;
};

const steps = ['Get started', 'Select a package', 'Customize with add-ons'];
export const getCurrentStep = (path: string) => {
  switch (path) {
    case PATHS.GET_STARTED:
      return 0;
    case PATHS.SELECT_PACKAGE:
      return 1;
    case PATHS.CUSTOMIZE:
      return 2;
    default:
      return 0;
  }
};

export const getPageName = (path: string) => {
  switch (path) {
    case PATHS.GET_STARTED:
      return steps[0];
    case PATHS.SELECT_PACKAGE:
      return steps[1];
    case PATHS.CUSTOMIZE:
      return steps[2];
    default:
      return '';
  }
};

const CreatePackage = () => {
  const { contextId, isIframe } = useContext(UIContext);
  const dispatchToast = useDispatch();
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const topbarRef = useRef<HTMLDivElement>(null);
  const subheaderRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [windowOffsetY] = useState(0);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);
  const [topbarHeight, setTopbarHeight] = useState(0);
  const [helpCenterClicked, setHelpCenterClicked] = useState(false);
  const trackEvent = useTrackEvent();
  const { incidents } = useIncidents();

  const {
    reducerState,
    search: { search, searchObject, searchCleaned, queryParamsPresent },
    currentUser,
    account,
    packageType,
    functions: {
      buildPostBodyWithAddOns,
      addErrorToReducerState,
      clearBasePackageAndAddonsFromReducerState,
      handleSkipBasePackageSelection,
      validateAndCleanUpSearch,
      resetLocalStorage,
      addDataDogError,
    },
  } = useCreatePackageContext();
  const { newPackageNameInvalid, basePackage, newPackageName } = reducerState;

  const hideCreatePackageSuccessToast =
    useFlag(HIDE_CREATE_PACKAGE_SUCCESS_TOAST)?.variantKey === 'true';

  const { t } = useTranslation();

  const title = {
    label: t(`pageHeaders.createPackage.title`),
    // Id to track component in Pendo
    props: { className: 'mastodon-create-package-title' },
  };
  const back = t('buttons.back');
  const [prices, setPrices] = useState({
    addonPrices: [
      {
        product_type: '',
        base_price: 0,
      },
    ],
    basePackagePrice: 0,
    aliasPrice: 0,
    subtotalPrice: 0,
  });

  const handlePriceChange = useCallback((value: any) => {
    setPrices(value);
  }, []);

  useEffect(() => {
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_CUSTOMIZE_VIEWED,
    );
  }, [currentUser, trackEvent]);

  const onModalClose = useCallback(
    type => {
      setCancelModalOpen(false);
      trackEvent(
        currentUser,
        PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_MODAL_COMPLETED,
        {
          'Exit Type': type,
          'Help Center Clicked': helpCenterClicked === true ? 'Yes' : 'No',
        },
      );
    },
    [currentUser, trackEvent, helpCenterClicked],
  );

  const backToPackages = useCallback(() => {
    const path = PATHS.PACKAGES;
    if (history?.location.pathname === PATHS.CUSTOMIZE) {
      trackEvent(
        currentUser,
        PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_CUSTOMIZE_COMPLETED,
        {
          'Add-Ons Screenings': reducerState.addedScreeningTypes?.length
            ? reducerState.addedScreeningTypes.join(', ')
            : null,
        },
      );
    }
    if (contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: false,
      });
    }
    history.push(path);

    if (cancelModalOpen) onModalClose('Cancel');

    resetLocalStorage();
  }, [
    contextId,
    history,
    cancelModalOpen,
    onModalClose,
    trackEvent,
    reducerState.addedScreeningTypes,
    currentUser,
    resetLocalStorage,
  ]);

  const goBack = useCallback(() => {
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_BACK_CLICKED,
      {
        'Page Name': getPageName(history?.location.pathname),
      },
    );
    if (history?.location.pathname === PATHS.GET_STARTED) {
      backToPackages();
    } else if (history?.location.pathname === PATHS.SELECT_PACKAGE) {
      const path = PATHS.GET_STARTED;
      const clientSidePath = queryParamsPresent
        ? `${path}?${search.toString()}`
        : path;

      if (contextId) {
        updateParentWindowUrl({
          contextId,
          path,
          reload: false,
          search: searchObject,
        });
      }
      history.push(clientSidePath, history?.location.pathname);
    } else if (history?.location.pathname === PATHS.CUSTOMIZE) {
      const path = PATHS.SELECT_PACKAGE;
      const clientSidePath = queryParamsPresent
        ? `${path}?${search.toString()}`
        : path;

      if (contextId) {
        updateParentWindowUrl({
          contextId,
          path,
          reload: false,
          search: searchObject,
        });
      }
      history.push(clientSidePath, history?.location.pathname);
    }

    if (!queryParamsPresent) {
      clearBasePackageAndAddonsFromReducerState();
    }
  }, [
    backToPackages,
    contextId,
    history,
    currentUser,
    search,
    queryParamsPresent,
    trackEvent,
    clearBasePackageAndAddonsFromReducerState,
    searchObject,
  ]);

  const { call: addPackage, result: addPackageResult } = useAddPackage({
    accountId: account && account.id,
    toggleShowAddScreenings: backToPackages,
    onSuccess: hideCreatePackageSuccessToast
      ? () => {
          backToPackages();
        }
      : null,
    onError: (e: any) => {
      const errorData = e?.response?.data;
      if (!errorData) {
        dispatchToast(
          toastError(
            'Failed to create package',
            'Unable to create package at this time',
          ),
        );
        return;
      }
      const { errors } = errorData;

      let errorMessage: string = errors.join(', ');

      const error = new Error(errorMessage);
      addDataDogError(error, {});

      if (
        errorData?.error?.includes(
          'Domestic packages with a criminal screening',
        )
      ) {
        dispatchToast(
          toastError(
            'Failed to create package',
            'Domestic packages with a criminal screening must include ALL of the following basic screenings: SSN Trace, Sex Offender Search, National Criminal Search',
          ),
        );
      }
      if (errors[0]?.toLowerCase() === 'slug has already been taken') {
        errorMessage = 'Please choose a unique name for your new package';
        addErrorToReducerState(errorMessage);
        if (nameFieldRef.current !== null) {
          nameFieldRef.current.focus();
        }
      } else if (errors[0]?.toLowerCase() === 'slug is invalid') {
        errorMessage =
          'Package name cannot be blank or include special characters';
        addErrorToReducerState(errorMessage);

        if (nameFieldRef.current !== null) {
          nameFieldRef.current.focus();
        }
      } else {
        dispatchToast(toastError('Failed to create package', errorMessage));
        addErrorToReducerState(errorMessage);
      }
    },
  });

  const savePackage = useCallback(() => {
    if (newPackageNameInvalid) {
      const errorMessage =
        'Package name cannot be blank or include special characters';
      addErrorToReducerState(errorMessage);
      if (nameFieldRef.current !== null) {
        nameFieldRef.current.focus();
      }
      return;
    }
    const body = buildPostBodyWithAddOns({
      aliasEnabled: reducerState.aliasesEnabled,
    });
    const addonSum = prices?.addonPrices
      ? prices.addonPrices.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.base_price;
        }, 0)
      : 0;
    const formattedAddonSum = `$${(addonSum / 100).toFixed(2)}`;
    addPackage(body);
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_PACKAGE_SAVED,
      {
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings
          .map(screening => screening.type)
          .join(', '),
        'Add-Ons Screenings': reducerState.addedScreeningTypes?.length
          ? reducerState.addedScreeningTypes.join(', ')
          : null,
        'Package Saved': 'Yes',
        'New Package Name': newPackageName,
        'Deep Link': queryParamsPresent ? 'Yes' : 'No',
        'Work Location':
          reducerState.selectedGeo !== DOMESTIC_GEO
            ? 'Outside of the US'
            : 'United States',
        ...(prices
          ? {
              'Original Package Price': `$${prices.basePackagePrice / 100}`,
              'Add-on Price': formattedAddonSum,
              'Alias Price': `$${prices.aliasPrice / 100}`,
              'Total Order Price': prices?.subtotalPrice,
            }
          : null),
      },
    );
  }, [
    newPackageNameInvalid,
    buildPostBodyWithAddOns,
    reducerState.aliasesEnabled,
    reducerState.addedScreeningTypes,
    reducerState.selectedGeo,
    addPackage,
    trackEvent,
    currentUser,
    basePackage.name,
    basePackage.screenings,
    newPackageName,
    queryParamsPresent,
    prices,
    addErrorToReducerState,
  ]);

  const continueToNextStep = useCallback(() => {
    if (history?.location.pathname === PATHS.CUSTOMIZE) {
      savePackage();
      resetLocalStorage();
      return;
    }

    if (history?.location.pathname === PATHS.SELECT_PACKAGE) {
      trackEvent(
        currentUser,
        PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_SELECT_PACKAGE_COMPLETED,
        {
          'Package Selected': packageType as BasePackageTypes,
          'Info Links Clicked': reducerState.infoLinksClicked?.join(', '),
        },
      );
      if (!queryParamsPresent && searchCleaned) {
        const prevSteps: any = localStorageFallback.getItem('previousStep');

        localStorageFallback.setItem(
          'previousStep',
          prevSteps?.concat(',select-package'),
        );
      }
    }
    if (history?.location.pathname === PATHS.GET_STARTED) {
      !queryParamsPresent &&
        searchCleaned &&
        localStorageFallback.setItem('previousStep', 'get-started');

      trackEvent(
        currentUser,
        PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_GET_STARTED_COMPLETED,
        {
          'Work Location':
            reducerState.selectedGeo !== DOMESTIC_GEO
              ? 'Outside of the US'
              : 'United States',
        },
      );
    }
    const path =
      history?.location.pathname === PATHS.GET_STARTED
        ? PATHS.SELECT_PACKAGE
        : PATHS.CUSTOMIZE;

    const clientSidePath = queryParamsPresent
      ? `${path}?${search.toString()}`
      : path;

    if (contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: false,
        search: searchObject,
      });
    }
    history.push(clientSidePath, history?.location.pathname);
  }, [
    contextId,
    history,
    savePackage,
    currentUser,
    trackEvent,
    reducerState,
    search,
    queryParamsPresent,
    packageType,
    searchCleaned,
    searchObject,
    resetLocalStorage,
  ]);

  const isNextStepDisabled = () => {
    return (
      addPackageResult.isLoading ||
      (newPackageNameInvalid &&
        getCurrentStep(history?.location.pathname) === 2) ||
      (getCurrentStep(history?.location.pathname) === 1 && !basePackage.name)
    );
  };

  const skipPackageSelection = () => {
    handleSkipBasePackageSelection();
    continueToNextStep();
  };

  useEffect(() => {
    if (!queryParamsPresent && searchCleaned) {
      localStorageFallback.setItem(
        'createPackageReducerState',
        JSON.stringify({ ...reducerState }),
      );
    }
  }, [reducerState, queryParamsPresent, searchCleaned]);

  const getWindowInnerHeight = useCallback((e: MessageEvent) => {
    setWindowInnerHeight(e.data.window.innerHeight);
  }, []);

  useDashboardWindowScroll(contextId);

  useEffect(() => {
    if (topbarRef.current) {
      setTopbarHeight(topbarRef.current?.clientHeight);
    }
  }, []);

  useDashboardRelay({
    messageId: 'window_iframe_positions',
    callback: getWindowInnerHeight,
    isIframe,
    contextId,
    deps: [getWindowInnerHeight],
  });

  useDashboardRelay({
    messageId: 'window_iframe_positions',
    callback: getWindowInnerHeight,
    isIframe,
    contextId,
    deps: [getWindowInnerHeight],
  });

  useEffect(() => {
    if (cancelModalOpen) {
      postMessageToDashboard({
        messageId: 'scroll_to_top',
      });
    }
  }, [cancelModalOpen]);

  useEffect(() => {
    if (history?.location.pathname === PATHS.SELECT_PACKAGE) {
      if (!history?.location.state) {
        if (contextId) {
          updateParentWindowUrl({
            contextId,
            path: PATHS.GET_STARTED,
            reload: false,
          });
        }
        history.push(PATHS.GET_STARTED);
      }
    }
    if (history?.location.pathname === PATHS.CUSTOMIZE) {
      if (!history?.location.state && !queryParamsPresent && searchCleaned) {
        if (contextId) {
          updateParentWindowUrl({
            contextId,
            path: PATHS.GET_STARTED,
            reload: false,
          });
        }
        history.push(PATHS.GET_STARTED);
      }
    }
  }, [history, contextId, search, searchCleaned, queryParamsPresent]);

  useEffect(() => {
    validateAndCleanUpSearch(search);
  }, [search, validateAndCleanUpSearch]);

  const skipVisibility =
    getCurrentStep(history?.location.pathname) === 1 &&
    account &&
    account.allow_standalone_screenings
      ? 'visible'
      : 'hidden';

  const onHelpCenterClick = useCallback(() => {
    setHelpCenterClicked(true);
  }, []);

  const onExitModal = useCallback(() => {
    onModalClose('Exit Mark');
  }, [onModalClose]);

  const onKeepEditing = useCallback(() => {
    onModalClose('Keep Editing');
  }, [onModalClose]);

  const handleCancelButtonClick = useCallback(() => {
    const currentPath = history?.location.pathname;
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_CANCEL_CLICKED,
      {
        'Page Name': getPageName(currentPath),
      },
    );
    if (currentPath === PATHS.CUSTOMIZE) {
      setCancelModalOpen(true);
    } else {
      backToPackages();
    }
  }, [history, currentUser, backToPackages, trackEvent]);

  const headerTopPosition = useMemo(() => {
    const hasIncidents = incidents.length > 0;
    const incidentBannerHeight =
      hasIncidents && window.scrollY === 0 ? INCIDENTS_BANNER_HEIGHT : 0;
    return windowOffsetY + incidentBannerHeight;
  }, [incidents, windowOffsetY]);

  return (
    <Container>
      <MaxWidth ref={topbarRef} top={headerTopPosition}>
        <M.ComposedModal open={cancelModalOpen} size='sm'>
          <M.ModalHeader closeModal={onExitModal}>
            <h2>Cancel progress?</h2>
          </M.ModalHeader>
          <M.ModalBody>
            {
              'If you cancel, your progress will be lost. If you are experiencing any issues or need additional information, please visit our '
            }
            <M.Link
              href='https://help.checkr.com/hc/en-us/articles/4410047632023-Help-me-understand-Checkr-packages'
              target='_blank'
              onClick={onHelpCenterClick}
            >
              Help Center.
            </M.Link>
          </M.ModalBody>
          <M.ModalFooter>
            <M.Button kind='secondary' onClick={onKeepEditing}>
              Keep editing
            </M.Button>

            <M.Button kind='danger' onClick={backToPackages}>
              Cancel
            </M.Button>
          </M.ModalFooter>
        </M.ComposedModal>
        <TopBar>
          <h3>
            <span>{title.label}</span>
          </h3>
          <NavButtons>
            <M.Button
              id='cancel-button'
              kind='secondary'
              onClick={handleCancelButtonClick}
            >
              Cancel
            </M.Button>
            {getCurrentStep(history?.location.pathname) === 2 ? (
              <SavePackageButton
                id='save-package-button'
                onClick={continueToNextStep}
                disabled={isNextStepDisabled()}
              >
                Save package
              </SavePackageButton>
            ) : (
              <ContinueButton
                id='continue-button'
                data-testid='create-package-continue-button'
                onClick={continueToNextStep}
                disabled={isNextStepDisabled()}
              >
                Continue
              </ContinueButton>
            )}
          </NavButtons>
        </TopBar>
      </MaxWidth>
      <Content>
        <InnerContainer type='transparent'>
          <SubHeaderContainer ref={subheaderRef}>
            <Button
              data-testid='create-package-go-back-button'
              kind='tertiary'
              onClick={goBack}
            >
              <M.Icon icon='ChevronLeft' /> {back}
            </Button>
            <StepsContainer>
              <Steps
                steps={steps}
                currentStep={getCurrentStep(history?.location.pathname)}
              />
            </StepsContainer>
            <Button
              kind='tertiary'
              onClick={skipPackageSelection}
              style={{ visibility: skipVisibility }}
            >
              Skip this step <M.Icon icon='ChevronRight' />
            </Button>
          </SubHeaderContainer>
          <RoutesContainer>
            <Route path={PATHS.GET_STARTED} component={GetStarted} />
            <Route path={PATHS.SELECT_PACKAGE} component={SelectPackage} />
            <Route path={PATHS.CUSTOMIZE}>
              <Customize
                windowOffsetY={
                  windowOffsetY === 0 ? 0 : windowOffsetY + topbarHeight
                }
                windowInnerHeight={
                  windowInnerHeight -
                  topbarHeight -
                  (subheaderRef.current?.offsetHeight || 0) -
                  ADDITIONAL_PADDING_SUBHEADER
                }
                goBack={goBack}
                getPrices={handlePriceChange}
              />
            </Route>
          </RoutesContainer>
        </InnerContainer>
      </Content>
    </Container>
  );
};

export default CreatePackage;
