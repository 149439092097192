import React, { FC } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PageHeaderSingle from '../PageHeaderSingle';
import { CreateApplicationButton } from '../Applications/DirectoryStyledComponents';

interface Props {
  handleCreateNewCohort: () => void;
}

const RandomizerHeader: FC<Props> = ({ handleCreateNewCohort }) => {
  return (
    <M.Grid>
      <M.GridRow>
        <M.GridCol>
          <PageHeaderSingle
            dataTestId='random-checks-title'
            className='header-wrap-secondary'
            title={{ label: 'Random testing' }}
          />
        </M.GridCol>
        <M.GridCol>
          <CreateApplicationButton
            data-testid='new-cohort-btn'
            style={{ float: 'right' }}
            onClick={handleCreateNewCohort}
          >
            + New pool
          </CreateApplicationButton>
        </M.GridCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default RandomizerHeader;
