import moment from 'moment';
import { createSelector } from 'reselect';
import { pipe, map } from 'lodash/fp';
import _ from 'lodash';

import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST,
  FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS,
  FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  EDIT_USER_ROLES_REQUEST,
  EDIT_USER_ROLES_SUCCESS,
  EDIT_USER_ROLES_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_GEO_SUBSCRIPTIONS_REQUEST,
  FETCH_GEO_SUBSCRIPTIONS_SUCCESS,
  FETCH_GEO_SUBSCRIPTIONS_FAILURE,
  UPDATE_GEO_SUBSCRIPTIONS_REQUEST,
  UPDATE_GEO_SUBSCRIPTIONS_SUCCESS,
  UPDATE_GEO_SUBSCRIPTIONS_FAILURE,
  UPDATE_SINGLE_GEO_SUBSCRIPTION,
} from 'actions/ActionTypes';

import { httpHelper } from './reducerHelpers';

export const initialState = {
  deleteUserRequest: { ...httpHelper.initialState },
  fetchGeoSubscriptionsRequest: { ...httpHelper.initialState },
  fetchRolesRequest: { ...httpHelper.initialState },
  fetchUsersAmsRequest: { ...httpHelper.initialState },
  geoSubscriptions: {},
  inviteUserRequest: { ...httpHelper.initialState },
  pendingLoadingUsers: undefined,
  pendingRoleEdits: {},
  roles: [],
  roleEditRequest: {},
  updateGeoSubscriptionsRequest: { ...httpHelper.initialState },
  users: {},
  usersCount: 0,
};

export const getDateOrNever = str => (str ? moment(str) : 'never');
export const getLastSignInDateOrNever = date => {
  const lastSignIn = getDateOrNever(date);
  return moment.isMoment(lastSignIn)
    ? lastSignIn.format('l LT')
    : lastSignIn || 'never';
};

const loadUserFromAMS = user => ({
  id: user.checkr_user_id || user.id,
  email: user.email,
  roles: user.roles.map(role => role.name),
  last_seen: getLastSignInDateOrNever(user.last_seen),
});

// eslint-disable-next-line default-param-last
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        fetchRolesRequest: { ...httpHelper.onRequest },
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        fetchRolesRequest: { ...httpHelper.onSuccess },
        roles: action.roles,
      };
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        fetchRolesRequest: { ...httpHelper.onFailure },
      };
    case FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST:
      return {
        ...state,
        fetchUsersAmsRequest: { ...httpHelper.onRequest },
      };
    case FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS: {
      const { data, count } = action.payload;
      const users = data.map(loadUserFromAMS).reduce((obj, user) => {
        obj[user.id] = user;
        return obj;
      }, {});

      return {
        ...state,
        users,
        usersCount: count,
        fetchUsersAmsRequest: { ...httpHelper.onSuccess },
      };
    }
    case FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchUsersAmsRequest: { ...httpHelper.onFailure },
      };

    case INVITE_USER_REQUEST:
      return {
        ...state,
        inviteUserRequest: { ...httpHelper.onRequest },
      };

    case INVITE_USER_SUCCESS: {
      const user = loadUserFromAMS(action.payload);
      const { users, usersCount } = state;
      return {
        ...state,
        inviteUserRequest: { ...httpHelper.onSuccess },
        users: { ...users, [user.id]: user },
        usersCount: usersCount + 1,
      };
    }
    case INVITE_USER_FAILURE:
      return {
        ...state,
        inviteUserRequest: { ...httpHelper.onFailure },
      };

    case EDIT_USER_ROLES_REQUEST:
      return {
        ...state,
        roleEditRequest: {
          ...state.roleEditRequest,
          [action.userId]: { ...httpHelper.onRequest },
        },
        pendingRoleEdits: {
          ...state.pendingRoleEdits,
          [action.userId]: action.roles,
        },
      };
    case EDIT_USER_ROLES_SUCCESS: {
      const { userId } = action;
      const { users, pendingRoleEdits } = state;

      const updatedRoles = pendingRoleEdits[userId];
      const updatedUser = { ...users[userId], roles: updatedRoles };

      return {
        ...state,
        users: { ...users, [userId]: updatedUser },
        roleEditRequest: {
          ...state.roleEditRequest,
          [userId]: { ...httpHelper.onSuccess },
        },
        pendingRoleEdits: {
          ...state.pendingRoleEdits,
          [userId]: undefined,
        },
      };
    }
    case EDIT_USER_ROLES_FAILURE: {
      let errMsg;
      if (action.msg.indexOf('403') >= 0) {
        errMsg = 'Cannot assign role with current permissions';
      }

      return {
        ...state,
        roleEditRequest: {
          ...state.roleEditRequest,
          [action.userId]: {
            ...httpHelper.onFailure,
            errorMsg: errMsg || action.msg,
          },
        },
        pendingRoleEdits: {
          ...state.pendingRoleEdits,
          [action.userId]: undefined,
        },
      };
    }
    case DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserRequest: {
          ...state.deleteUserRequest,
          [action.userId]: { ...httpHelper.onRequest },
        },
      };
    case DELETE_USER_SUCCESS: {
      const { userId } = action;
      const { users, usersCount } = state;

      delete users[userId];
      return {
        ...state,
        users,
        usersCount: usersCount - 1,
        deleteUserRequest: {
          ...state.deleteUserRequest,
          [userId]: { ...httpHelper.onSuccess },
        },
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        deleteUserRequest: {
          ...state.deleteUserRequest,
          [action.userId]: {
            ...httpHelper.onFailure,
            errorMsg: action.msg,
          },
        },
      };
    }
    case FETCH_GEO_SUBSCRIPTIONS_REQUEST: {
      return {
        ...state,
        fetchGeoSubscriptionsRequest: {
          ...state.fetchGeoSubscriptionsRequest,
          [action.userId]: { ...httpHelper.onRequest },
        },
      };
    }
    case FETCH_GEO_SUBSCRIPTIONS_SUCCESS: {
      const { userId, geoSubscriptions: subscriptions } = action;
      const { geoSubscriptions } = state;
      geoSubscriptions[userId] = subscriptions;
      return {
        ...state,
        geoSubscriptions,
        fetchGeoSubscriptionsRequest: {
          ...state.fetchGeoSubscriptionsRequest,
          [userId]: { ...httpHelper.onSuccess },
        },
      };
    }
    case FETCH_GEO_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        fetchGeoSubscriptionsRequest: {
          ...state.fetchGeoSubscriptionsRequest,
          [action.userId]: {
            ...httpHelper.onFailure,
            errorMsg: action.msg,
          },
        },
      };
    }
    case UPDATE_GEO_SUBSCRIPTIONS_REQUEST: {
      return {
        ...state,
        updateGeoSubscriptionsRequest: {
          ...state.updateGeoSubscriptionsRequest,
          [action.userId]: { ...httpHelper.onRequest },
        },
      };
    }
    case UPDATE_GEO_SUBSCRIPTIONS_SUCCESS: {
      const { userId } = action;
      return {
        ...state,
        updateGeoSubscriptionsRequest: {
          ...state.updateGeoSubscriptionsRequest,
          [userId]: { ...httpHelper.onSuccess },
        },
      };
    }
    case UPDATE_GEO_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        updateGeoSubscriptionsRequest: {
          ...state.updateGeoSubscriptionsRequest,
          [action.userId]: {
            ...httpHelper.onFailure,
            errorMsg: action.msg,
          },
        },
      };
    }
    case UPDATE_SINGLE_GEO_SUBSCRIPTION: {
      const { userId, itemIndex, itemValue } = action;
      const geoSubscriptions = _.cloneDeep(state.geoSubscriptions);
      geoSubscriptions[userId][itemIndex].subscribed = itemValue;
      return {
        ...state,
        geoSubscriptions,
      };
    }
    default:
      return state;
  }
};

export const usersSelector = state => state.users;

const getRoleEditRequest = createSelector(
  usersSelector,
  users => users.roleEditRequest,
);

const getUserId = (_unused, props) => props.user.id;

const defaultRequestState = {};
export const getRoleEditRequestByUser = createSelector(
  getRoleEditRequest,
  getUserId,
  (roleEditRequest, uId) => roleEditRequest[uId] || defaultRequestState,
);

export const getAvailableRoles = createSelector(usersSelector, users => {
  return users.roles;
});

export const getMultiSelectRoles = createSelector(
  getAvailableRoles,
  pipe(
    map(role => role.name),
    map(name => ({ id: name, label: name })),
  ),
);

export default usersReducer;
