import { useTranslation } from 'react-i18next';
import { ADJUDICATION_KINDS } from '@dashboard-experience/utils';
import { useGetAdjudicationSubtypes } from 'modules/adjudication/api';
import { namespace } from '../locales';

export type Subtype = {
  checked: boolean;
  deletable: boolean;
  description: string;
  id: string;
  kind: string;
  name: string;
  slug: string | null;
};

export type PostSubtypePayload = {
  subtypeKind: string;
  selection?: Subtype | undefined;
};

export const useGetPostSubtypePayload = ({
  subtypeKind,
  selection,
}: PostSubtypePayload) => {
  const { t } = useTranslation();
  const { engagedSubtypes = [], deferredSubtypes = [] } =
    useGetAdjudicationSubtypes();

  let subtypes;
  switch (subtypeKind) {
    case ADJUDICATION_KINDS.DEFERRED: {
      subtypes = deferredSubtypes;
      break;
    }
    case ADJUDICATION_KINDS.ENGAGED:
      subtypes = engagedSubtypes;
      break;
    default: {
      throw new Error(
        `${subtypeKind} ${t(`${namespace}:report.post_subtype.error`)}`,
      );
    }
  }
  const subtype = selection || subtypes[0] || { slug: null };
  const data = {
    adjudication: subtypeKind,
    adjudication_subtype: subtype.slug,
  };
  return data;
};
