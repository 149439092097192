import { AnyQueryKey, useQuery } from 'react-query';

import { list } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const useList = (enabled: boolean) => {
  const key: AnyQueryKey = ['savedSearches/list', { id: undefined }];

  const request = () => list();

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled,
  });
};
