import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { ModalFooter } from 'modules/adjudication/ui/common';
import { useGetIncompleteScreenings } from '../../../utils';

const InlineNotification = styled(M.InlineNotification)`
  .cds--inline-notification__title {
    font-weight: bold;
    margin-bottom: 0.75rem !important;
  }
`;

const CandidateSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  .candidate-name {
    color: ${colors.uiNavy600};
  }
  .candidate-title {
    font-weight: bold;
  }
  .candidate-subtitle {
    margin-bottom: 0.75rem !important;
  }
  .mastodon-icon {
    fill: ${colors.uiNavy600};
    height: 60px;
    width: 60px;
    margin: 0.5rem 0;
  }
  p {
    padding-right: 0;
  }
`;

type Props = {
  nextScreen: () => void;
  onClose: () => void;
};

const IncompleteScreenings: React.FC<Props> = ({ nextScreen, onClose }) => {
  const { report: { candidate } = {} } = useGetReport();
  const incompleteScreenings = useGetIncompleteScreenings();
  const { t } = useTranslation();

  const cancel = t(`${namespace}:report.actions.engaged.modal.actions.cancel`);
  const engage = t(`${namespace}:report.actions.engaged.modal.actions.submit`);
  const notificationTitle = t(
    `${namespace}:report.actions.engaged.modal.incomplete.notifification_title`,
  );
  const candidateTitle = t(
    `${namespace}:report.actions.engaged.modal.incomplete.candidate_title`,
  );
  const candidateSubtitle = t(
    `${namespace}:report.actions.engaged.modal.incomplete.candidate_subtitle`,
  );

  const candidateName = candidate?.middle_name
    ? `${candidate.first_name} ${candidate.middle_name} ${candidate.last_name}`
    : `${candidate?.first_name} ${candidate?.last_name}`;

  const next = {
    name: engage,
    onClick: () => nextScreen(),
  };

  const close = {
    name: cancel,
    onClick: onClose,
  };

  return (
    <>
      <section>
        {incompleteScreenings.length > 0 && (
          <InlineNotification
            kind='warning'
            title={notificationTitle}
            hideCloseButton
          >
            <M.UnorderedList>
              {incompleteScreenings.map((screening) => (
                <M.ListItem key={screening}>
                  {humanizeOnUnderscore(screening)}
                </M.ListItem>
              ))}
            </M.UnorderedList>
          </InlineNotification>
        )}
      </section>
      <CandidateSection>
        <h4 className='candidate-name'>{candidateName}</h4>
        <M.Icon icon='Chat' />
        <p className='candidate-title'>{candidateTitle}</p>
        <p className='candidate-subtitle'>{candidateSubtitle}</p>
      </CandidateSection>
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default IncompleteScreenings;
