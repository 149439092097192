/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import React, { useCallback, useRef, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { useEffectAfterInitialized } from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateSearchParams } from 'actions/SearchActions';
import { useUser } from 'context/CurrentUser';
import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { useTags } from '../../hooks';
import { styledFilter } from '../StyledComponents';

const StyledInput = styled(M.Input)`
  ${styledFilter}
`;

const noop = () => {};

// TODO: check , into % url params for array params
const Tags = () => {
  const ref: any = useRef();
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const currentUser = useUser();
  const accountId = currentUser?.account?.id;

  const handleTouch = useCallback(() => setTouched(true), [setTouched]);

  const {
    tags: apiTags,
    tagsLoading,
    defaultTags,
  } = useTags({ accountId, touched });

  const { filters, setFilters } = useSearchState();
  const { tags, isTagNone } = filters;
  const none = defaultTags === 'none';

  const trackEvent = useTrackEvent();

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onChange = useCallback(
    ({
      selectedItems: items,
      isNone,
    }: {
      selectedItems: Array<any>;
      isNone: boolean;
    }) => {
      const t: any = !items.length && isNone ? undefined : items;

      if (items.length > selectedItemsCount && currentUser) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
          {
            // get last item of array of selected tags which is equal to the latest added tag
            Tags: items.slice(-1).pop(),
            'Selected Filter': 'Tags',
          },
        );
      }
      setSelectedItemsCount(items.length);
      setFilters((prevState: any) => ({
        ...prevState,
        tags: t,
        isTagNone: isNone,
      }));
    },
    [selectedItemsCount, currentUser, setFilters, trackEvent],
  );

  useEffectAfterInitialized(() => {
    if (tags !== undefined) {
      dispatch(updateSearchParams({ tags }));
    } else if (isTagNone) {
      dispatch(updateSearchParams({ tags: 'none' }));
    } else if (tags === undefined && isTagNone === undefined) {
      ref?.current?.clearAll();
    }
  }, [tags, dispatch, isTagNone]);

  const placeholder =
    Array.isArray(apiTags) && !apiTags?.length
      ? 'You have no reports with tags'
      : 'Search for tags';

  return (
    <div data-testid='tags-filter'>
      {/* Once the tags load, create a filter dropdown with them: */}
      {apiTags?.length > 0 && defaultTags && !tagsLoading && (
        <M.TagFilter
          tags={apiTags}
          selectedItems={tags || []}
          initialSelectedItems={none ? [] : defaultTags}
          none={none}
          // TODO fix this in Mastodon
          onNoneChange={noop}
          onChange={onChange}
          ref={ref}
          maxItems={100}
          wrapText
        />
      )}

      {/* If no details (yet), then show a placeholder input */}
      {!apiTags?.length && !defaultTags?.length && (
        <>
          <StyledInput
            data-testid='load-tags-input'
            labelText='Tags'
            onClick={handleTouch}
            disabled={touched}
            placeholder={placeholder}
            id='load-tags-input'
          />
          {tagsLoading && (
            <M.LoadingInline description='Loading tags for your account...' />
          )}
        </>
      )}
    </div>
  );
};

export default Tags;
