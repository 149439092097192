import React from 'react';
import { ID } from 'modules/entity';
import RulesetVersion from './Container';
import { useDraft } from './api';

type Props = {
  ruleset?: ID;
};

export const DraftContainer: React.FC<Props> = ({ ruleset }) => {
  const query = useDraft(ruleset);

  return <RulesetVersion data={query?.data} status={query?.status} />;
};

export default DraftContainer;
