import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Decision from 'modules/assess/v2/models/decision';
import DecisionInfo from './DecisionInfo';

const Options = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Description = styled.span`
  color: ${colors.brandNavy4};
  display: block;
  line-height: 150%;
  width: 66%;
`;

const Content: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const content = t(`standard.guideline.info.start`);
  const basePath = 'assess-v2-ui-guideline-info-content';
  const descriptionId = `${basePath}-description`;

  return (
    <div id={basePath} data-testid='assess-v2-guideline_info-content'>
      <Description
        id={descriptionId}
        data-testid='assess-v2-guideline_info-description'
      >
        <Trans>{content}</Trans>
      </Description>
      <Options>
        <DecisionInfo decision={Decision.Eligible} />
        <DecisionInfo decision={Decision.Review} />
        <DecisionInfo decision={Decision.Escalated} />
      </Options>
    </div>
  );
};

export default Content;
