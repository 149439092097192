import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getStatusTypeForUser,
  getReportStatus,
  Report,
  getCandidateInvitationStatus,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import { GenericObject } from 'types';
import { CellProps } from './types';
import { mostRecent } from './utils';

//  DELETE ME: when canceled (complete now) is enabled for all users
const isGlobalUser = ({ email }: GenericObject = {}) => {
  if (!email) return false;
  const [, domain] = email.split('@');

  return ['checkr.com', 'checkr.io'].includes(domain);
};

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Status: React.FC<CellProps> = ({ candidate, report, currentUser }) => {
  const statusType = getStatusTypeForUser(currentUser);

  const hasCanceledEnabled =
    currentUser?.account &&
    (!currentUser.account.translate_canceled_status_to_suspended ||
      isGlobalUser(currentUser));

  const { reports } = candidate;
  const mostRecentReport: Report | undefined = mostRecent(reports);
  const displayedReport = report || mostRecentReport;

  const showIncludesCanceled = !!(
    // @ts-ignore TODO: Remove comment once Report type is updated in Utils
    (hasCanceledEnabled && displayedReport?.includes_canceled)
  );

  let status;

  // @ts-ignore TODO: Update in utils to accept Both types of Report (Search)
  if (displayedReport) status = getReportStatus(displayedReport, statusType);
  if (!status && candidate.invitations.length > 0) {
    status = getCandidateInvitationStatus(
      (mostRecent(candidate.invitations) as any).status,
    );
  }

  return status ? (
    <StatusItem>
      <M.StatusBadge statusType={statusType} status={status} />
      {showIncludesCanceled && (
        <M.TooltipDefinition
          align='bottom'
          style={{ marginLeft: '0.25rem' }}
          highlighted={false}
          tabIndex={0}
          definition='This report contains canceled items.'
        >
          <M.Icon icon='WarningAlt' />
        </M.TooltipDefinition>
      )}
    </StatusItem>
  ) : null;
};

export default Status;
