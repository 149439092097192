import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import UserVersionRow from './UserVersionRow';
import { UserVersionsResponse } from '../../../api/users/types';

interface UserVersionsTableProps {
  versions: UserVersionsResponse['versions'];
  totalPages: number;
  currentPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
}

const UserVersionsTable: React.FC<UserVersionsTableProps> = ({
  versions,
  totalPages,
  currentPage,
  handlePageClick,
}) => {
  return (
    <div>
      <M.Table>
        <M.TableHead>
          <M.TableRow role='rowheader'>
            <M.TableHeader>Timestamp</M.TableHeader>
            <M.TableHeader>Event</M.TableHeader>
            <M.TableHeader>Whodunnit</M.TableHeader>
            <M.TableHeader>Changes</M.TableHeader>
            <M.TableHeader>Roles</M.TableHeader>
          </M.TableRow>{' '}
        </M.TableHead>
        <M.TableBody>
          {versions &&
            versions.map(version => (
              <UserVersionRow key={version.created_at} version={version} />
            ))}
        </M.TableBody>
      </M.Table>

      {totalPages > 0 && (
        <M.Pagination
          data-testid='user-versions-pagination'
          pageCount={totalPages}
          selectedIndex={currentPage}
          onPageClick={handlePageClick}
        />
      )}
    </div>
  );
};

export default UserVersionsTable;
