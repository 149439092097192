import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'modules/assess/ui/hooks';
import { Type as FairChanceMetrics } from 'modules/fair-chance-metrics/models';
import { Section } from '../../common';
import Sections from '../../sections';
import AdverseActionedData from './AdverseActionedData';
import TotalCandidates from './TotalCandidates';
import Warning from './Warning';
import WarningType from './warning-type';

type Props = {
  metrics: FairChanceMetrics;
};

const Container: React.FC<Props> = ({ metrics }) => {
  const { t } = useTranslation('fairChance');
  const currentUser = useUser();
  const translationPath = `fairChance:fair_chance.sections.${Sections.fair_chance_opportunity}`;
  const translations: any = t(translationPath, {
    returnObjects: true,
    organization_name: currentUser?.account?.name,
  });
  const { adverse_actioned_data, total_candidates, title } = translations;

  const hideMetrics = invalidMetrics(metrics);
  const metricsChildren = [];

  if (!hideMetrics) {
    metricsChildren.push(
      <AdverseActionedData
        key='adverse-action-data'
        content={adverse_actioned_data}
        over_seven_year_charge_adverse_actions={
          metrics?.over_seven_year_charge_adverse_actions
        }
        pending_charge_adverse_actions={metrics?.pending_charge_adverse_actions}
        petty_offenses_adverse_actions={metrics?.petty_offenses_adverse_actions}
        under_twenty_five_charges_adverse_actions={
          metrics?.under_twenty_five_charges_adverse_actions
        }
      />,
    );

    const warningContent = getWarningContent(adverse_actioned_data, metrics);
    metricsChildren.push(<Warning key='warning' content={warningContent} />);
  }

  return (
    <Section data-testid='fair-chance-sections-fair-chance-opportunity'>
      <h3>{title}</h3>
      <TotalCandidates
        content={total_candidates}
        totalCandidates={metrics?.total_candidate_adverse_actions}
      />
      {metricsChildren}
    </Section>
  );
};

function getWarningContent(data: any, metrics: FairChanceMetrics): any {
  const type = getWarningType(metrics);
  if (!type) {
    return null;
  }

  return data[type].warning;
}

function getWarningType(metrics: FairChanceMetrics): WarningType | undefined {
  const adverseActionMetrics = [
    {
      metric: metrics.over_seven_year_charge_adverse_actions,
      type: WarningType.only_seven_year_old_charges,
    },
    {
      metric: metrics.pending_charge_adverse_actions,
      type: WarningType.pending_charges,
    },
    {
      metric: metrics.petty_offenses_adverse_actions,
      type: WarningType.petty_charges,
    },
    {
      metric: metrics.under_twenty_five_charges_adverse_actions,
      type: WarningType.younger_than_twenty_five_charges,
    },
  ];

  const metricValues = adverseActionMetrics.map(i => i.metric || 0);
  const maxValue = Math.max(...metricValues);
  const firstMaxValue = adverseActionMetrics.find(i => i.metric === maxValue);
  return firstMaxValue?.type;
}

function invalidMetrics(metrics: FairChanceMetrics): boolean {
  const adverseActionMetrics = [
    metrics.over_seven_year_charge_adverse_actions,
    metrics.pending_charge_adverse_actions,
    metrics.petty_offenses_adverse_actions,
    metrics.under_twenty_five_charges_adverse_actions,
  ];

  return adverseActionMetrics.map(invalidMetric).every(i => i);
}

function invalidMetric(value?: number): boolean {
  if (!value) {
    return true;
  }
  return value === 0;
}

export default Container;
