import { Client } from 'api';
import { GenericObject } from '@dashboard-experience/utils';
import querystring from 'querystring';
import { AdverseActionResponse } from '../../components/AdverseActions/types';

export const fetchAdverseActions = (
  params: GenericObject,
): Promise<AdverseActionResponse> => {
  const queryParams = querystring.encode(params);

  return Client.get(`/adverse_actions?${queryParams}`);
};

export const fetchCandidateReport = (
  reportId: string,
): Promise<GenericObject> =>
  Client.get(
    `/reports/${reportId}/dashboard?include=candidate&include=candidate`,
  );

export const fetchPostAdverseActionsNoticeTemplate = (
  reportId: string,
  newParams?: { edit_mode: boolean; individualized_reassessment: string },
): Promise<string> => {
  const params = newParams || {
    edit_mode: false,
    individualized_reassessment: null,
  };
  const queryParams = querystring.encode(params);

  return Client.get(
    `/reports/${reportId}/post_adverse_action_template?${queryParams}`,
  );
};

export const sendPostAdverseActionNotice = (
  id: string,
): Promise<GenericObject> => Client.post(`/adverse_actions/${id}`);

export const sendBulkAdverseActionNotices = (adverseActionIds: Array<string>) =>
  Client.post('/adverse_actions/bulk_send', {
    adverse_action_ids: adverseActionIds,
  });
