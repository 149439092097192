import React, { useContext } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { RootRoute } from 'modules/assess/ui/router/context';
import { useBreadcrumbTitle } from 'modules/assess/v2/ui/hooks';
import Breadcrumb from './Breadcrumb';

const Container = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  color: ${colors.uiGrey900};
  font-size: 32px;
  font-weight: bold;
`;

const HomeHeader: React.FC = () => {
  const root = useContext(RootRoute);
  const breadCrumbTitle = useBreadcrumbTitle();
  const routes = [{ location: root, title: breadCrumbTitle }];
  const breadCrumbTestId = `${breadCrumbTitle
    .toLowerCase()
    .replace(/ /g, '-')}-heading`;
  const items = routes.map(route => (
    <Breadcrumb key={route.title} route={route} />
  ));

  // TODO: EADA-3941 - [Assess v1/v2] move header component out of v2
  return (
    <Container>
      <M.Breadcrumb data-testid={breadCrumbTestId} noTrailingSlash>
        {items}
      </M.Breadcrumb>
    </Container>
  );
};

export default HomeHeader;
