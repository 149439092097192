import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';

import { StyledTooltipDefinition } from './SearchTableStyledComponents';

const ProcessingStatus = ({
  tooltipAlignment,
}: {
  tooltipAlignment: string;
}) => {
  return (
    <StyledTooltipDefinition
      align={tooltipAlignment}
      highlighted={false}
      tabIndex={0}
      definition='Processing...'
    >
      <M.LoadingInline data-testid='processing-status' />
    </StyledTooltipDefinition>
  );
};

ProcessingStatus.propTypes = {
  tooltipAlignment: PropTypes.string,
};

ProcessingStatus.defaultProps = {
  tooltipAlignment: 'bottom',
};

export default ProcessingStatus;
