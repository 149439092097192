import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Pill = styled.div`
  align-items: flex-start;
  background: ${colors.uiGrey0};
  border: 1px solid ${colors.brandSlate6};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  height: 21px;
  padding: 3px 10px 2px;

  /* Text formatting */
  color: ${colors.brandSlate6};
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
`;

const DraftPill: React.FC = () => {
  const { t } = useTranslation();
  const draft = t('nouns.draft').toUpperCase();
  return <Pill id='assess-v2-ui-common-components-draft-pill'>{draft}</Pill>;
};

export default DraftPill;
