import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import STATUS from '../enums/Status';

const EnabledTag = styled.div`
  background: ${colors.uiGreen200};
  color: ${colors.uiGreen800};
  border-radius: 4px;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const PendingTag = styled(EnabledTag)`
  background: ${colors.uiGrey200};
  color: ${colors.uiGrey800};
`;

const AddonSetupStatusTag: React.FC<{ status: STATUS; testId: string }> = ({
  status,
  testId,
}) => {
  return (
    <>
      {status === STATUS.pending && (
        <PendingTag data-testid={testId}>Setup in progress</PendingTag>
      )}
      {status === STATUS.enabled && (
        <EnabledTag data-testid={testId}>Setup complete</EnabledTag>
      )}
    </>
  );
};

export default AddonSetupStatusTag;
