import React, { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

export type Type = React.FC<Props>;

export type Props = {};

export type ActionProps = {
  justify?: string;
};

type ContextNodesType = Array<ReactNode>;
export const ContextNodes = React.createContext<ContextNodesType>([]);

type ContextUpdateType = (nodes: Array<ReactNode>) => void;
export const ContextUpdate = React.createContext<ContextUpdateType>(
  (): void => {},
);

export const Provider: Type = ({ children }) => {
  const [nodes, setNodes] = useState<Array<ReactNode>>([]);
  return (
    <ContextNodes.Provider value={nodes}>
      <ContextUpdate.Provider value={setNodes}>
        {children}
      </ContextUpdate.Provider>
    </ContextNodes.Provider>
  );
};

export const Container = styled.div<ActionProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || ''};
  .mastodon-button {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mastodon-button:last-child {
    margin-right: 0 !important;
  }
  .mastodon-button:first-child {
    margin-left: 0 !important;
  }
`;

export const Bar: Type = () => {
  const nodes = useContext(ContextNodes);
  return <Container>{nodes}</Container>;
};

export const Content: Type = ({ children }) => {
  const update = useContext(ContextUpdate);
  useEffect(() => update([children]), [children, update]);
  return null;
};

export default Provider;
