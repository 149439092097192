import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledContainer = styled.div`
  height: 21px;
  padding: 3px 10px 2px;
  margin-left: 1rem;
`;

const StyledText = styled.span`
  color: ${colors.brandSlate6};
  font-weight: bold;
  font-size: 8px;
  height: 16px;
  letter-spacing: 1px;
  line-height: 30px;
`;

type Props = {
  total: Number;
};

export const Empty: React.FC<Props> = ({ total }) => {
  const { t } = useTranslation('assess:v2');
  const text = t('common.count.initial_state', {
    total,
  }).toUpperCase();

  return (
    <StyledContainer>
      <StyledText data-testid='assess-v2-ui-common-components-count-empty'>
        {text}
      </StyledText>
    </StyledContainer>
  );
};

export default Empty;
