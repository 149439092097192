export enum Value {
  CONCEAL_ELIGIBLE_RECORDS = 'CONCEAL_ELIGIBLE_RECORDS',
  REMOVE_ELIGIBLE_RECORDS = 'REMOVE_ELIGIBLE_RECORDS',
  SHOW_ELIGIBLE_RECORDS = 'SHOW_ELIGIBLE_RECORDS',
  UNDEFINED = '',
}

export const DEFINED_VALUES: Array<Value> = [
  Value.CONCEAL_ELIGIBLE_RECORDS,
  Value.REMOVE_ELIGIBLE_RECORDS,
  Value.SHOW_ELIGIBLE_RECORDS,
];

export default Value;
