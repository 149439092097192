import {
  CATEGORIES,
  SUB_CATEGORIES,
  CHARGES,
  ChargeSeverity,
  Disposition,
} from 'modules/assess';

import Operator from 'modules/assess/models/operator';
import Result from 'modules/assess/models/result';

import {
  AgeNowRule,
  AgeRule,
  ChargeCategoryL1Rule,
  ChargeCategoryL2Rule,
  ChargeCategoryL3Rule,
  ChargeSeverityRule,
  CountRule,
  DispositionRule,
  Fact,
  IncarcerationEstimatedEndRule,
  LookbackRule,
  ProbationEstimatedEndRule,
  RawChargeRule,
  RawChargeTypeRule,
  RawDispositionRule,
  RawSentencingNotesRule,
  RecordType,
  ResultRule,
  ScreeningType,
  ScreeningTypeRule,
  TypeRule,
} from 'modules/assess/models/criminal/rules';
import FactRuleOptions from '../../fact-options';

export const AGE_AT_OFFENSE: FactRuleOptions<AgeRule, 'operator' | 'value'> = {
  fact: Fact.AGE_AT_OFFENSE,
  options: [
    { operator: Operator.LT, value: 18 },
    { operator: Operator.LT, value: 21 },
    { operator: Operator.LT, value: 23 },
    { operator: Operator.LT, value: 25 },
    { operator: Operator.GT, value: 25 },
  ],
};

export const AGE_NOW: FactRuleOptions<AgeNowRule, 'operator' | 'value'> = {
  fact: Fact.AGE_NOW,
  options: [
    { operator: Operator.LT, value: 18 },
    { operator: Operator.GE, value: 18 },
    { operator: Operator.LT, value: 21 },
    { operator: Operator.GE, value: 21 },
    { operator: Operator.GE, value: 25 },
  ],
};

export const CHARGE_CATEGORY_L1: FactRuleOptions<
  ChargeCategoryL1Rule,
  'value'
> = {
  fact: Fact.CHARGE_CATEGORY_L1,
  operator: Operator.IN,
  options: Object.values(CATEGORIES).map(item => ({
    value: item.name,
  })),
};

export const CHARGE_CATEGORY_L2: FactRuleOptions<
  ChargeCategoryL2Rule,
  'value'
> = {
  fact: Fact.CHARGE_CATEGORY_L2,
  operator: Operator.IN,
  options: Object.values(SUB_CATEGORIES).map(item => ({
    value: item.name,
  })),
};

export const CHARGE_CATEGORY_L3: FactRuleOptions<
  ChargeCategoryL3Rule,
  'value'
> = {
  fact: Fact.CHARGE_CATEGORY_L3,
  operator: Operator.IN,
  options: Object.values(CHARGES).map(item => ({
    value: item.name,
  })),
};

export const CHARGE_SEVERITY: FactRuleOptions<ChargeSeverityRule, 'value'> = {
  fact: Fact.CHARGE_SEVERITY,
  operator: Operator.IN,
  options: Object.values(ChargeSeverity).map(value => ({
    value,
  })),
};

export const COUNT: FactRuleOptions<CountRule, 'operator' | 'value'> = {
  fact: Fact.COUNT,
  options: [
    { operator: Operator.GT, value: 1 },
    { operator: Operator.GT, value: 2 },
    { operator: Operator.GT, value: 3 },
    { operator: Operator.GT, value: 4 },
    { operator: Operator.GT, value: 5 },
    { operator: Operator.GT, value: 6 },
  ],
};

export const DISPOSITION: FactRuleOptions<DispositionRule, 'value'> = {
  fact: Fact.DISPOSITION,
  operator: Operator.IN,
  options: Object.values(Disposition).map(value => ({
    value,
  })),
};

export const INCARCERATION_ESTIMATED_END_DATE: FactRuleOptions<
  IncarcerationEstimatedEndRule,
  'value'
> = {
  fact: Fact.INCARCERATION_ESTIMATED_END_DATE,
  operator: Operator.LE,
  options: Array(15)
    .fill(0)
    .map((_, value) => value + 1)
    .map(value => ({
      value,
    })),
};

type LookbackOptionsType = {
  operator: Operator.LE | Operator.LT | Operator.GE;
  value: number;
};

const lookbackOptionsLE = Array(15)
  .fill(0)
  .map((_, value) => value + 1)
  .map(value => ({
    operator: Operator.LE,
    value,
  })) as unknown as LookbackOptionsType[];

const lookbackOptionsGE = Array(15)
  .fill(0)
  .map((_, value) => value + 1)
  .map(value => ({
    operator: Operator.GE,
    value,
  })) as unknown as LookbackOptionsType[];

const lookbackOptions = lookbackOptionsLE.concat(lookbackOptionsGE);

export const LOOKBACK: FactRuleOptions<LookbackRule, 'operator' | 'value'> = {
  fact: Fact.LOOKBACK,
  options: lookbackOptions,
};

export const PROBATION_ESTIMATED_END_DATE: FactRuleOptions<
  ProbationEstimatedEndRule,
  'value'
> = {
  fact: Fact.PROBATION_ESTIMATED_END_DATE,
  operator: Operator.LE,
  options: Array(15)
    .fill(0)
    .map((_, value) => value + 1)
    .map(value => ({
      value,
    })),
};

export const RAW_CHARGE: FactRuleOptions<RawChargeRule, 'value'> = {
  fact: Fact.RAW_CHARGE,
  operator: Operator.MRI,
};

export const RAW_CHARGE_TYPE: FactRuleOptions<RawChargeTypeRule, 'value'> = {
  fact: Fact.RAW_CHARGE_TYPE,
  operator: Operator.MRI,
};

export const RAW_DISPOSITION: FactRuleOptions<RawDispositionRule, 'value'> = {
  fact: Fact.RAW_DISPOSITION,
  operator: Operator.MRI,
};

export const RAW_SENTENCING_NOTES: FactRuleOptions<
  RawSentencingNotesRule,
  'value'
> = {
  fact: Fact.RAW_SENTENCING_NOTES,
  operator: Operator.MRI,
};

export const RESULT: FactRuleOptions<ResultRule, 'value'> = {
  fact: Fact.RESULT,
  operator: Operator.EQ,
  options: Object.values(Result).map(value => ({
    value,
  })),
};

export const TYPE: FactRuleOptions<TypeRule, 'value'> = {
  fact: Fact.TYPE,
  operator: Operator.IN,
  options: Object.values(RecordType).map(value => ({
    value,
  })),
};

export const SCREENING_TYPE: FactRuleOptions<ScreeningTypeRule, 'value'> = {
  fact: Fact.SCREENING_TYPE,
  operator: Operator.IN,
  options: Object.values(ScreeningType).map(value => ({
    value,
  })),
};
