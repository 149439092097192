import React from 'react';
import { AtsIntegrationValidationResult } from 'api/atsIntegration';
import { SectionContainer, SectionHeader } from '../../shared';
import ValidationItem from './ValidationItem';

type ValidationSchema = {
  description: string;
  method: string;
  name: string;
};

type Props = {
  partnerSlug: string;
  validations: ValidationSchema[];
  onValidation: () => Promise<void>;
  onValidationFailure: (failedFields: Record<string, string>) => void;
};

const ValidationSection: React.FC<Props> = ({
  partnerSlug,
  validations,
  onValidation,
  onValidationFailure,
}: Props) => {
  const [validationErrors, setValidationErrors] = React.useState(
    {} as Record<string, string>,
  );

  const [ongoingValidations, setOngoingValidations] = React.useState(
    {} as Record<string, boolean>,
  );

  const onValidateAll = React.useCallback(async () => {
    const newOngoingValidations: Record<string, boolean> = {};
    validations.forEach(({ method }) => {
      newOngoingValidations[method] = true;
    });
    await onValidation();
    setOngoingValidations(newOngoingValidations);
  }, [validations, onValidation]);

  const onValidateOne = React.useCallback(
    async (validationId: string) => {
      const newOngoingValidations = {
        ...ongoingValidations,
      };
      newOngoingValidations[validationId] = true;
      await onValidation();
      setOngoingValidations(newOngoingValidations);
    },
    [onValidation, ongoingValidations],
  );

  const onResponse = React.useCallback(
    (response: AtsIntegrationValidationResult) => {
      const newValidationErrors = { ...validationErrors };
      const newOngoingValidations = {
        ...ongoingValidations,
      };
      const failedConfigs: Record<string, string> = {};
      for (const result of response) {
        delete newOngoingValidations[result.validation_id];
        if (result.success) {
          delete newValidationErrors[result.validation_id];
        } else {
          newValidationErrors[result.validation_id] = result.message;
          for (const { field_id, message } of result.linked_fields ?? []) {
            if (message) {
              failedConfigs[field_id] = message;
            }
          }
        }
      }
      setValidationErrors(newValidationErrors);
      setOngoingValidations(newOngoingValidations);
      if (Object.keys(failedConfigs).length > 0) {
        onValidationFailure(failedConfigs);
      }
    },
    [onValidationFailure, ongoingValidations, validationErrors],
  );

  const onRequestError = React.useCallback(
    (error: Error, validationIds: string[]) => {
      const newOngoingValidations = {
        ...ongoingValidations,
      };
      for (const validationId of validationIds) {
        delete newOngoingValidations[validationId];
      }
      setOngoingValidations(newOngoingValidations);
    },
    [ongoingValidations],
  );

  return (
    <SectionContainer data-testid='ats-validation-section'>
      <SectionHeader
        sectionKey='validations'
        header='Validations'
        headerBody='Any unsaved changes will be automatically saved upon clicking validate'
        bulkActionLabel='Validate all'
        onBulkAction={onValidateAll}
      />
      {validations.map(({ name, method }) => {
        return (
          <ValidationItem
            key={`ats-validation-item-${method}`}
            name={name}
            validationId={method}
            onValidateClick={onValidateOne}
            onValidateResult={onResponse}
            onValidationError={onRequestError}
            invalidText={validationErrors[method]}
            isValidating={ongoingValidations[method]}
            partnerSlug={partnerSlug}
          />
        );
      })}
    </SectionContainer>
  );
};

export default ValidationSection;
