import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useDeleteNote } from '../hooks';

type PropTypes = {
  noteId: string;
  open: boolean;
  toggleOpen: () => void;
};

const DeleteNoteModal = ({ noteId, open, toggleOpen }: PropTypes) => {
  const { t } = useTranslation();
  const trans = 'case-management:internal-notes.delete-modal.confirm';
  const { call: deleteNote } = useDeleteNote({
    noteId,
    successCallback: toggleOpen,
  });
  return (
    <M.ComposedModal
      data-testid='case-management-note-delete-confirmation-modal'
      type='small'
      kind='destructive'
      open={open}
      onClose={toggleOpen}
    >
      <M.ModalHeader
        icon={<M.Icon icon='WarningAltFilled' className='test-class' />}
        title={t(`${trans}.title`)}
        buttonOnClick={toggleOpen}
      />
      <M.ModalBody>
        <div>
          <p>{t(`${trans}.body`)}</p>
        </div>
      </M.ModalBody>
      <M.ModalFooter
        actions={{
          primary: {
            onClick: deleteNote,
          },
          secondary: {
            onClick: toggleOpen,
          },
        }}
      />
    </M.ComposedModal>
  );
};

export default DeleteNoteModal;
