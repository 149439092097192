import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const SlateContainer = styled(M.Container)`
  border: 1px solid ${colors.brandSlate3} !important;
`;

export const NavyContainer = styled(SlateContainer)`
  border-left: 4px solid ${colors.brandNavy1} !important;
  border-radius: 8px;
`;

export const AquaContainer = styled(M.Container)`
  border-left: 4px solid ${colors.brandAqua3} !important;
  border-radius: 8px;
`;

export const Title = styled.h1`
  color: ${colors.brandNavy4};
  font-size: 32px !important;
  font-weight: bold;
`;
