import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import EmailBox from '../../GetStarted/EmailBox';

const BulletStyled = styled.li`
  font-size: 16px;
  list-style-type: disc;
  margin-left: 25px;
  line-height: 24px;
`;

const ViewMoreStyled = styled.div`
  font-size: 16px;
  margin-top: 16px;
  color: ${colors.brandNavy1};
  cursor: pointer;
`;

const ErrorTypography = styled.span`
  color: ${colors.uiOrange600};
`;

export type EmailSectionContentProps = {
  emails: string[];
  editMode: boolean;
  candidatesError?: { state: boolean; msg: string };
};

const EmailSectionContent: React.FC<EmailSectionContentProps> = ({
  emails,
  editMode,
  candidatesError = { state: false, msg: '' },
}) => {
  const [visibleEmails, setVisibleEmails] = useState<string[]>([]);

  const showAllEmails = useCallback(() => {
    setVisibleEmails(emails);
  }, [emails]);

  const showFirstFive = useCallback(() => {
    setVisibleEmails(emails.slice(0, 5));
  }, [emails]);

  const showViewMore =
    emails.length > 5 && visibleEmails.length !== emails.length;

  const showViewLess =
    emails.length > 5 && visibleEmails.length === emails.length;

  useEffect(() => {
    showFirstFive();
  }, [showFirstFive]);

  return (
    <>
      {editMode ? (
        <EmailBox />
      ) : (
        <>
          <ul>
            {visibleEmails.map(email => (
              <BulletStyled key={email} data-dd-privacy='mask'>
                {email}
              </BulletStyled>
            ))}
          </ul>
          {showViewMore && (
            <ViewMoreStyled
              onClick={showAllEmails}
              data-testid='candidates-view-more'
            >
              View {emails.length - 5} more
              <M.Icon icon='ChevronDown' />
            </ViewMoreStyled>
          )}

          {showViewLess && (
            <ViewMoreStyled
              onClick={showFirstFive}
              data-testid='candidates-view-less'
            >
              View less <M.Icon icon='ChevronUp' />
            </ViewMoreStyled>
          )}
        </>
      )}
      {candidatesError.state && (
        <ErrorTypography>{candidatesError.msg}</ErrorTypography>
      )}
    </>
  );
};

export default EmailSectionContent;
