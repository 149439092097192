import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import pluralize from 'pluralize';

import { AdversableItem } from 'modules/adjudication/data';
import { useGetReport } from 'api/reports';
import List from './adverse-items-list';

const Expandable = styled(M.Expandable)`
  border-bottom: 2px solid ${colors.uiGrey100} !important;
  margin-bottom: 2rem !important;

  &.mastodon-expandable:not(.expanded):hover {
    margin-bottom: unset;
  }
`;

// TODO: add this to translations once en.json is refactored
const useFilter = () => {
  const { report } = useGetReport();
  if (report?.account?.assess_enabled) {
    return 'Assess and Positive Adjudication';
  }

  return 'Positive Adjudication';
};

const FilteredAdverseItems: React.FC<{ adverseItems: AdversableItem[] }> = ({
  adverseItems,
}) => {
  const recordFilter = useFilter();

  return (
    <Expandable
      title={`${adverseItems?.length} ${pluralize(
        'Record',
        adverseItems.length,
      )} filtered by ${recordFilter}`}
    >
      <List adversibleItems={adverseItems} />
    </Expandable>
  );
};

export default FilteredAdverseItems;
