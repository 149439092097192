import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { M } from '@dashboard-experience/mastodon';

const PhotoVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { photo_verification: photoVerification } = report;
  return photoVerification ? (
    <M.Screening
      title='Photo Verification'
      screening={photoVerification}
      statusType={reportStatusType}
      id={SCREENING_TITLES.photo_verification.id}
    />
  ) : null;
};

export default PhotoVerification;
