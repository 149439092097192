import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { ErrorMsg } from './styles';

const StyledChoiceCardGroup = styled(M.ChoiceCardGroup)`
  #mastodon && {
    fieldset {
      display: flex;
    }
  }
`;

type Props = {
  name: string;
  value: boolean;
  onChange: any;
  label1: string;
  label2: string;
  disabled?: boolean;
  error?: any;
};

const createId = (name: string, label: string) => {
  return `${name}-${label.replace(/\s+/g, '-').toLowerCase()}`;
};

const ChoiceCardGroup: React.FC<Props> = ({
  name,
  value,
  onChange,
  label1,
  label2,
  disabled,
  error,
}) => {
  const handleChange = useCallback(
    e => {
      onChange(name, Boolean(e));
    },
    [name, onChange],
  );

  return (
    <>
      <StyledChoiceCardGroup
        type='radio'
        data-testid={`${name.toLowerCase()}-input`}
        onChange={handleChange}
        defaultSelected={value == null ? null : +value}
        disabled={disabled}
        name={`${name.toLowerCase()}-input`}
      >
        <M.ChoiceCard
          id={createId(name, label1)}
          data-testid={createId(name, label1)}
          description={label1}
          value={1}
        />
        <M.ChoiceCard
          id={createId(name, label2)}
          data-testid={createId(name, label2)}
          description={label2}
          value={0}
        />
      </StyledChoiceCardGroup>
      {error && <ErrorMsg data-testid={`${name}-warn-msg`}>{error}</ErrorMsg>}
    </>
  );
};

export default ChoiceCardGroup;
