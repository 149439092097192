import { useGetReport } from '../../../../../../../../../api/reports';
import { useModalState } from '../../../context';

export const useNextDisabled = () => {
  const { report } = useGetReport();
  const { templates } = useModalState('state');
  const medium = useGetMedium();
  const { candidateAddress } = useModalState('state');

  return (
    (report?.account?.adverse_action_by_jurisdiction &&
      !(templates.pre && templates.post)) ||
    (medium === 'postal' && !candidateAddress.saved)
  );
};

export const useGetMedium = () => {
  const { medium } = useModalState('state');
  return medium.postal.priority === 1 ? 'postal' : 'email';
};

export const useAddressIsFilled = () => {
  const { candidateAddress } = useModalState('state');
  return (
    candidateAddress &&
    candidateAddress.street &&
    candidateAddress.city &&
    candidateAddress.state &&
    candidateAddress.zipcode
  );
};
