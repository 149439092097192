import React, { useCallback } from 'react';
import { useReport } from 'containers/Report';
import { useCreateNote } from '../hooks';
import BaseNoteEditor from './BaseNoteEditor';

type PropTypes = {
  onClose: () => void;
};

const NewNoteEditor = ({ onClose }: PropTypes) => {
  const { id } = useReport();
  const { call: createNote } = useCreateNote({
    report_id: id,
    successCallback: onClose,
  });

  const handleSave = useCallback(
    noteContent => {
      createNote({ content: noteContent });
    },
    [createNote],
  );

  return <BaseNoteEditor onSave={handleSave} onClose={onClose} />;
};

export default NewNoteEditor;
