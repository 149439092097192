import React from 'react';
import styled from 'styled-components';
import HistoryTimeline from '../HistoryTimeline';
import { HistoryEvent } from '../HistoryTimeline/types';

type Props = {
  statuses: HistoryEvent[];
  itemKey: string;
};

const Container = styled.div`
  .mastodon-key-value-list {
    margin-top: 0.75rem;
  }
`;

const Timeline: React.FC<Props> = ({ statuses, itemKey }) => {
  return (
    <Container data-testid='screenings-drug-health-timeline'>
      <HistoryTimeline events={statuses} itemKey={itemKey} />
    </Container>
  );
};

export default Timeline;
