import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Grid = styled(M.Grid)`
  color: ${colors.uiTextPrimaryLight};
  display: flex;
  justify-content: flex-start;
  > *:nth-child(2) {
    flex-grow: 30;
  }
`;

export const Row: React.FC = ({ children, ...props }) => {
  const items = React.Children.map(children, child => (
    <M.GridCol>{child}</M.GridCol>
  ));

  return (
    <Grid
      data-testid='assess-ruleset-version-context-dates-options-container'
      {...props}
    >
      {items}
    </Grid>
  );
};

export default Row;
