import { Report } from 'types';
import _ from 'lodash';
import { SCREENINGS, DELAYED_SCREENINGS_COUNTRY_CODES } from './screenings';

/**
 * @name hasDelayedScreenings
 * @function
 * @memberOf DelayedScreeningsBanner/DelayedScreeningsBanner
 * @description Searches report screenings for known countries with delayed screenings
 * @param {Report} report - The report payload
 * @returns {boolean}
 */
const hasDelayedScreenings = (report: Report) => {
  const screenings: any[] = _.flatten(
    _.filter(_.pick(report, SCREENINGS), screening => !_.isEmpty(screening)),
  );

  const countries: any[] = screenings.map(screening => screening.country);

  return _.intersection(DELAYED_SCREENINGS_COUNTRY_CODES, countries).length > 0;
};

export default hasDelayedScreenings;
