import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Attachments as AttachmentsType } from 'modules/adjudication/api';
import { IndividualizedAssessment } from 'modules/adjudication/data';
import { DocLink } from './components';

type Props = {
  attachments?: AttachmentsType;
  individualizedAssessment?: IndividualizedAssessment;
};

const title = `${namespace}:report.actions.pre_adverse.modal.preview_email.attachments`;

const Container = styled.div`
  margin-bottom: 1rem;

  .title {
    font-weight: bold;
    padding-bottom: 0.75rem;
  }

  .mastodon-unordered-list-container {
    padding-left: 0 !important;
    li::before {
      content: none !important;
    }
  }
`;

const Attachments: React.FC<Props> = ({
  attachments,
  individualizedAssessment,
}) => {
  const { t } = useTranslation();

  if (!attachments) return null;

  return (
    <Container>
      <p className='title'>{t(title)}</p>
      <M.UnorderedList>
        {attachments.map(({ title, location }) => (
          <M.ListItem key={title}>
            <DocLink href={location} text={title} />
          </M.ListItem>
        ))}
        {individualizedAssessment?.uploadedFile && (
          <M.ListItem>
            <DocLink
              href={individualizedAssessment.uploadedFile.url}
              text='Individualized Assessment'
            />
          </M.ListItem>
        )}
      </M.UnorderedList>
    </Container>
  );
};

export default Attachments;
