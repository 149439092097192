import React, { useCallback, useContext, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { useDashboardRelay } from 'hooks';
import {
  RightButtonsContainer,
  SendBulkNoticesProgressBar,
  SendBulkNoticesProgressBarContainer,
  SendBulkNoticesSendingSpinner,
  SendBulkNoticesSuccess,
} from './AdverseActionsStyledComponents';
import BulkNoticesDatePicker from './BulkNoticesDatePicker';
import UIContext from '../../context/UI';
import {
  useFetchBulkAdverseActions,
  useSendBulkAdverseActionNotices,
} from '../../api/adverseActions';
import { useUser } from '../../context/CurrentUser';

const SendBulkNotices: React.FC = () => {
  const { contextId, isIframe } = useContext(UIContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [certified, setCertified] = useState(false);

  const {
    clear: clearSubmit,
    isProcessing: isSubmitProcessing,
    isSuccess: isSubmitSuccess,
    submitAdverseActions,
  } = useSendBulkAdverseActionNotices();

  const {
    getAdverseActions,
    adverseActionsProgress,
    sendableNotices,
    clear: clearGet,
    isProcessing: isGetProcessing,
    isSuccess: isGetSuccess,
  } = useFetchBulkAdverseActions();

  /**
   * @name clearData
   * @function
   * @memberOf BulkSendNotices
   * @description Clear out adverse actions data
   */
  const clearData = useCallback(() => {
    clearGet();
    clearSubmit();
    setCertified(false);
  }, [clearGet, clearSubmit, setCertified]);

  const toggleModal = useCallback(() => {
    clearData();
    setModalOpen(!modalOpen);
  }, [modalOpen, clearData]);

  /**
   * @name canSend
   * @function
   * @memberOf BulkSendNotices
   * @description Whether we can submit the form or not, for the purpose of
   *              disabling the submit button
   * @returns {boolean}
   */
  const canSend = useCallback(() => {
    return (
      certified &&
      !isGetProcessing &&
      !isSubmitProcessing &&
      sendableNotices &&
      sendableNotices.length
    );
  }, [certified, isGetProcessing, isSubmitProcessing, sendableNotices]);

  useDashboardRelay({
    messageId: 'showSendBulkAANoticesModal',
    callback: toggleModal,
    isIframe,
    contextId,
  });

  const dateChange = useCallback(
    (before: string, after: string) => {
      clearData();
      getAdverseActions({
        created_before: before,
        created_after: after,
      });
    },
    [clearData, getAdverseActions],
  );

  /**
   * @name submitBulkSend
   * @function
   * @memberOf BulkSendNotices
   * @description Submit the bulk send form
   */
  const submitBulkSend = useCallback(() => {
    submitAdverseActions(sendableNotices);
  }, [submitAdverseActions, sendableNotices]);

  const handleCheck = useCallback(
    (event, { checked }) => setCertified(checked),
    [],
  );

  const { account } = useUser();
  if (!account.adverse_action_bulk_send) {
    return null;
  }

  return (
    <>
      <M.Button
        onClick={toggleModal}
        kind='secondary'
        size='sm'
        data-testid='open-bulk-send-post-notices-modal-button'
      >
        Bulk send post-notices
      </M.Button>
      <M.ComposedModal
        open={modalOpen}
        size='lg'
        data-testid='bulk-send-post-notices-modal'
      >
        <M.ModalHeader closeModal={toggleModal}>
          <h2>Bulk Send Post-Adverse Action Notices</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <M.Grid>
            <M.GridRow>
              <M.GridCol>
                Complete the adverse action process by sending post-notices to
                candidates who received pre-notices between these dates:
              </M.GridCol>
            </M.GridRow>
            <BulkNoticesDatePicker
              isProcessing={isGetProcessing}
              dateChange={dateChange}
              clearData={clearData}
            />
            {isGetProcessing && (
              <M.GridRow>
                <M.GridCol md={4}>
                  <SendBulkNoticesProgressBarContainer>
                    Loading notices...
                    <SendBulkNoticesProgressBar
                      progress={adverseActionsProgress}
                    />
                  </SendBulkNoticesProgressBarContainer>
                </M.GridCol>
              </M.GridRow>
            )}
            {isGetSuccess && (
              <M.GridRow>
                <M.GridCol>
                  <strong
                    data-testid='bulk-send-notices-count'
                    style={{
                      color: `${
                        sendableNotices.length
                          ? colors.uiGreen600
                          : colors.uiOrange600
                      }`,
                    }}
                  >
                    {`${sendableNotices.length} ${
                      sendableNotices.length && sendableNotices.length === 1
                        ? 'notice'
                        : 'notices'
                    } found`}
                  </strong>
                </M.GridCol>
              </M.GridRow>
            )}
            <M.GridRow>
              <M.GridCol>
                <M.Checkbox
                  id='bulk-send-aa-post-notices-certify'
                  className='bulk-send-aa-post-notices-certify'
                  name='bulk_send_aa_post_notices_certify'
                  labelText='I certify that these reports have been reviewed, and it has been determined that the candidates do not meet our standards for partnership at this time.'
                  onChange={handleCheck}
                  type='inline'
                  checked={certified}
                />
              </M.GridCol>
            </M.GridRow>
            <M.GridRow>
              <M.GridCol>
                Notices will be sent for undisputed notices only.
              </M.GridCol>
            </M.GridRow>
            <M.GridRow>
              <M.GridCol>
                {!isSubmitSuccess && (
                  <RightButtonsContainer>
                    {isSubmitProcessing && (
                      <SendBulkNoticesSendingSpinner description='Sending notices...' />
                    )}
                    <M.Button onClick={toggleModal} kind='secondary' size='sm'>
                      Cancel
                    </M.Button>
                    <M.Button
                      data-testid='bulk-send-aa-post-notices-submit-button'
                      onClick={submitBulkSend}
                      kind='primary'
                      size='sm'
                      disabled={!canSend()}
                    >
                      Continue
                    </M.Button>
                  </RightButtonsContainer>
                )}
                {isSubmitSuccess && (
                  <RightButtonsContainer>
                    <SendBulkNoticesSuccess>
                      Sent {sendableNotices.length} notices
                    </SendBulkNoticesSuccess>
                    <M.Button onClick={toggleModal} kind='primary' size='sm'>
                      Done
                    </M.Button>
                  </RightButtonsContainer>
                )}
              </M.GridCol>
            </M.GridRow>
          </M.Grid>
        </M.ModalBody>
      </M.ComposedModal>
    </>
  );
};

export default SendBulkNotices;
