import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { FacisRecord, FacisSanction } from 'types';

type FacisRecordProps = { record: FacisRecord };

const keyStyle = {
  width: '10rem',
};

const Record: React.FC<FacisRecordProps> = ({ record }) => {
  const { authority, database_registry, full_name, dob, sanctions } = record;
  const items = [];
  if (authority) items.push({ itemKey: 'Authority', itemValue: authority });
  if (database_registry)
    items.push({ itemKey: 'Database Registry', itemValue: database_registry });
  if (full_name) items.push({ itemKey: 'Full Name', itemValue: full_name });
  if (dob) items.push({ itemKey: 'Date of Birth', itemValue: dob });
  if (sanctions && sanctions.length > 0) {
    sanctions.forEach((sanction: FacisSanction, i: number) => {
      items.push({
        itemKey: sanctions.length === 1 ? 'Sanction' : `Sanction ${i + 1}`,
        itemValue:
          sanction.sanction && sanction.sanction.length > 0 ? (
            <M.SeeMore data-testid='facis-sanction' key={`sanction-${i + 1}`}>
              {sanction.sanction}
            </M.SeeMore>
          ) : (
            <span data-testid='facis-sanction' key={`sanction-${i + 1}`}>
              Unknown Sanction
            </span>
          ),
      });
    });
  } else {
    // Edge case when sanctions is null or empty
    items.push({
      itemKey: 'Sanction',
      itemValue: <span data-testid='facis-sanction'>Unknown Sanction</span>,
    });
  }
  return (
    <M.KeyValueList
      data-testid='facis-record'
      keyStyle={keyStyle}
      items={items}
    />
  );
};

export default Record;
