import { AnyQueryKey, useMutation, useQuery, queryCache } from 'react-query';
import { useUser } from 'context/CurrentUser';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from 'actions';
import { adjudicationClient } from 'modules/case-management/api/clients';
import {
  ACCOUNTS_PATH,
  SETTINGS_PATH,
} from 'modules/case-management/api/paths';

export const useCMSettings = () => {
  const { account } = useUser();
  const accountID = account?.id;
  const key: AnyQueryKey = ['cm/settings', accountID];
  const request = () =>
    adjudicationClient.get(`${ACCOUNTS_PATH}/${accountID}${SETTINGS_PATH}`);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });
};

export const useUpdateCMSettings = () => {
  const { account } = useUser();
  const accountID = account?.id;
  const dispatch = useDispatch();
  const request = (params: {
    settings: {
      candidate_story_review_tasks_enabled?: boolean;
    };
  }) =>
    adjudicationClient.patch(
      `${ACCOUNTS_PATH}/${accountID}${SETTINGS_PATH}`,
      params,
    );
  const [call, result] = useMutation(request, {
    onSuccess: (data: any) => {
      queryCache.invalidateQueries([`cm/settings`, accountID]);
      dispatch(toastSuccess('Settings updated successfully'));
    },
    onError: () => {
      dispatch(toastError('Unable to update settings'));
    },
  });
  return { call, result };
};
