import React from 'react';

import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import MunicipalSearches from './MunicipalStateSearches';

const StateCriminalSearches: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { state_criminal_searches: screening } = report;
  return screening?.length ? (
    <MunicipalSearches
      report={report}
      title='State Searches'
      reportStatusType={reportStatusType}
      screening={screening}
      screeningType='state_criminal_searches'
      id={SCREENING_TITLES.state_criminal_searches.id}
    />
  ) : null;
};

export default StateCriminalSearches;
