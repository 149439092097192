import SectionTitle from 'modules/assess/ui/common/section-title';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {};

export const Title: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const title = t('assess:mvr.category-sets.instance.assignments.title');

  return <SectionTitle>{title}</SectionTitle>;
};

export default Title;
