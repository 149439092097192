import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Csv from 'components/CsvExport/CsvExportView';

type RouterProps = {
  id: string;
};

type Props = RouteComponentProps<RouterProps>;

const AccountTabsPage: React.FC<Props> = ({ match }) => {
  const {
    params: { id },
  } = match;

  return <Csv id={id} />;
};

export default AccountTabsPage;
