import React from 'react';
import { useTranslation } from 'react-i18next';

import { Entity } from 'modules/assess/models/insights';

type Props = {
  data?: Entity.Type;
};

export const Footer: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('assess');
  const formatter = new Intl.NumberFormat();

  const count = getCount(data);

  const title = t('assess:ruleset.version.insights.footer.title');
  const message = t('assess:ruleset.version.insights.footer.message', {
    months: 12,
    n: formatter.format(100000),
    r: formatter.format(count),
  });

  return (
    <div>
      <h3>{title}</h3>
      <p>{message}</p>
    </div>
  );
};

function getCount(data?: Entity.Type) {
  if (!data) {
    return 0;
  }

  const { active, draft } = data;

  if (!active || !draft) {
    return 0;
  }

  return active.eligible + active.review + active.escalated;
}

export default Footer;
