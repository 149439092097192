import styled from 'styled-components';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import { Keyword } from './Components';

export const Container = styled(M.Container)`
  padding: 0rem !important;
  margin-bottom: 0rem !important;
`;

export const Row = styled.div`
  margin-bottom: 1rem;
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

export const Right = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const TitleRow = styled.div`
  display: flex;
  font-size: 24px;
  color: ${colors.uiTextPrimaryLight};
  vertical-align: middle;
  width: 100%;
`;

export const Title = styled.h1`
  display: flex;
  font-size: 24px;
  color: ${colors.uiTextPrimaryLight};
  vertical-align: bottom !important;
  padding-right: 2rem;
  padding-top: 1rem;
`;

export const Text = styled.p`
  color: ${colors.uiNavy600};
  cursor: pointer;
  display: inline-block;
  font-size: ${sizes.medium};
  line-height: 20px;
  padding-right: 1rem;
`;

export const Tag = styled(M.Tag)`
  margin-left: 1rem !important;
`;

export const KeywordFilter = styled(Keyword)`
  border: 1px solid ${colors.uiGrey100};
  box-shadow: 0px 4px 8px ${colors.uiGrey200};
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem !important;
  .cds--text-input {
    border-bottom: none !important;
  }
`;
