import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'payment-details-section-container',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  .cds--text-input__invalid-icon--warning path:first-of-type {
    fill: white !important;
  }
`;

export const Section = styled.div.attrs({
  className: 'payment-details-section',
})`
  display: flex;
  gap: 24px;
  padding: 0 0 32px 0;
  width: 100%;
  border-bottom: 1px solid ${colors.uiGrey200};
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    &:last-child {
      flex-direction: row;
    }
  }

  @media (max-width: 720px) {
    &:last-child {
      flex-direction: column;
    }
  }
`;

export const FlexColumn = styled.div.attrs({
  className: 'payment-details-flex-column',
})`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 33%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const SectionDescription = styled.div.attrs({
  className: 'payment-details-section-description',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    margin-top: 0;
  }
`;

type FormSectionProps = {
  gap?: string;
};

export const FormSection = styled.div.attrs({
  className: 'payment-details-form-section',
})<FormSectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '16px'};
  width: 66%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
