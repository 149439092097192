import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  getScreeningSummaryLabel,
  getDollarAmount,
} from 'components/AddScreenings/shared/utils';
import { M, colors } from '@dashboard-experience/mastodon';

type ScreeningTypographyProps = {
  isAddChecks: boolean;
};

type ScreeningListItemProps = {
  isAddChecks: boolean;
};

const ScreeningList = styled.ul`
  list-style: none !important;
  margin-left: 0px !important;
`;

const ScreeningListItem = styled.li<ScreeningListItemProps>`
  margin-bottom: ${({ isAddChecks }) => (isAddChecks ? '0' : '6')}px !important;
  display: flex;

  &:before {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
  }
`;

const View = styled.div`
  font-size: 16px;
  margin-top: 16px;
  color: ${colors.brandNavy1};
  cursor: pointer;
  display: flex;
`;

const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: ${({ $flow }) => $flow ?? 'row'};
`;

const ScreeningTypography = styled.p<ScreeningTypographyProps>`
  font-size: ${({ isAddChecks }) => (!isAddChecks ? '16' : '14')}px !important;
  margin-bottom: 6px !important;
  padding-left: 5px !important;
  margin-bottom: 0px !important;
`;

const LoaderStyled = styled(M.LoadingSpinner)`
  margin: auto 105px;
  vertical-align: center;
`;

type FlexProps = {
  $flow?: 'column' | 'row';
};

type Item = {
  base_price: number;
  description: string;
  max_price: number;
  min_price: number;
  name: string;
  product_type: string;
  type: string;
};

type AddOnPrice = {
  bundled_price: number;
  fixed_price: number;
  passthrough_fee_range: number[];
  service_fee_range: number[];
  items: Item[];
};

export type AddOnSectionContentProps = {
  addOnPricesData: AddOnPrice;
  isLoading: boolean;
  basePackageScreenings: string[];
  additionalProperties: Object;
  showPrices?: boolean;
  isAddChecks?: boolean;
  addonData?: Item[];
};

export const getReformattedAddonPrices = (
  addonData: any[],
  basePackageScreenings: any[],
  addOnPricesData: { items: any[] },
) => {
  if (addonData.length > 0) {
    return addonData;
  }

  const excludeOccHealth = (screening: any) =>
    screening.product_type === 'occupational_health_screening';

  const result = addOnPricesData?.items?.filter?.(
    screening =>
      !basePackageScreenings.includes(
        excludeOccHealth(screening) ? '' : screening.product_type,
      ),
  );

  return result || [];
};

const AddOnSectionContent: React.FC<AddOnSectionContentProps> = ({
  addOnPricesData,
  isLoading,
  basePackageScreenings,
  additionalProperties,
  showPrices,
  isAddChecks = false,
  addonData = [],
}) => {
  const reformattedAddOnPrices = getReformattedAddonPrices(
    addonData,
    basePackageScreenings,
    addOnPricesData,
  );

  const [visibleScreenings, setVisibleScreenings] = useState<Item[]>([]);

  const showViewMore =
    reformattedAddOnPrices?.length > 5 &&
    reformattedAddOnPrices?.length !== visibleScreenings.length;
  const showViewLess =
    reformattedAddOnPrices?.length > 5 &&
    reformattedAddOnPrices?.length === visibleScreenings.length;

  const showAllScreenings = useCallback(() => {
    setVisibleScreenings(reformattedAddOnPrices);
  }, [reformattedAddOnPrices]);

  const showFirstFiveScreenings = useCallback(() => {
    setVisibleScreenings(reformattedAddOnPrices.slice(0, 5));
  }, [reformattedAddOnPrices]);

  useEffect(() => {
    if (!visibleScreenings.length && reformattedAddOnPrices?.length)
      showFirstFiveScreenings();
  }, [reformattedAddOnPrices, showFirstFiveScreenings, visibleScreenings]);

  if (isLoading || !addOnPricesData) {
    return <LoaderStyled withOverlay={false} />;
  }

  return (
    <Flex $flow='column'>
      <ScreeningList>
        {visibleScreenings.map((screening: any) => {
          const price = getDollarAmount(screening.base_price.toString());
          return (
            screening.product_type !== 'international_adverse_media_search' && (
              <ScreeningListItem
                key={screening.product_type}
                isAddChecks={isAddChecks}
              >
                <Flex>
                  <ScreeningTypography
                    data-testid={`${screening.product_type}-label`}
                    isAddChecks={isAddChecks}
                  >
                    {getScreeningSummaryLabel(
                      screening.product_type,
                      additionalProperties,
                    )}
                  </ScreeningTypography>

                  {showPrices && (
                    <ScreeningTypography
                      data-testid={`${screening.product_type}-price`}
                      isAddChecks={isAddChecks}
                    >
                      {price === '$0.00' ? 'Price varies' : price}
                    </ScreeningTypography>
                  )}
                </Flex>
              </ScreeningListItem>
            )
          );
        })}
      </ScreeningList>

      {showViewMore && (
        <View onClick={showAllScreenings} data-testid='addon-section-view-more'>
          View {reformattedAddOnPrices.length - 5} more{' '}
          <M.Icon icon='ChevronDown' size='20' />
        </View>
      )}

      {showViewLess && (
        <View
          onClick={showFirstFiveScreenings}
          data-testid='addon-section-view-less'
        >
          View less <M.Icon icon='ChevronUp' size='20' />
        </View>
      )}
    </Flex>
  );
};

export default AddOnSectionContent;
