import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  OnlyAdvantagesBox,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type GlobalWatchlistSearchProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};
const GlobalWatchlistSearch = ({
  shouldOpen,
  actionProps,
}: GlobalWatchlistSearchProps) => {
  return (
    <FlexContainer data-testid='international-global-watchlist-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Global Watchlist Search'
            priceByType='international_global_watchlist_search'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Confirms whether a candidate is listed on certain US or
            international watchlists.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='gwl-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360056108714'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <OnlyAdvantagesBox>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='A comprehensive search of multiple national and international government watchlists' />
              <BulletPoint text='Checks numerous international, government, and regulatory databases' />
              <BulletPoint text='Helps maintain compliance, mitigate risk, and make informed hiring decisions' />
            </ul>
          </OnlyAdvantagesBox>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_global_watchlist_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default GlobalWatchlistSearch;
