/* eslint-disable react/jsx-no-bind */
import React from 'react';

import { ComplianceDropdown } from './styles';
import { complianceGeos } from '../../../constants/complianceGeos';

type Data = {
  [key: string]: {
    state: string;
    state_name: string;
    cities: string[];
  };
};

export const availableComplianceStates = () => {
  return complianceGeos.reduce((data: Data, geo) => {
    data[geo.state] = data[geo.state] || {
      state: geo.state,
      state_name: geo.state_name,
      cities: [],
    };

    if (geo.city) {
      data[geo.state].cities.push(geo.city);
    }

    return data;
  }, {});
};

export const availableComplianceCitiesForSelectedState = (
  complianceState: string,
) => {
  if (
    complianceState &&
    availableComplianceStates()[complianceState].cities.length > 0
  ) {
    return availableComplianceStates()
      [complianceState].cities.map(city => ({ label: city, id: city }))
      .concat({ label: 'Other', id: 'Other' });
  }
  return [];
};

type Props = {
  errors: any;
  values: any;
  setFieldValue: any;
  initialValues: any;
};

const ComplianceStateAndCitySelector = ({
  errors,
  values,
  setFieldValue,
  initialValues,
}: Props) => {
  const handleStateChange = (selectedOption: any) => {
    setFieldValue('complianceState', selectedOption.selectedItem.id, false);
    setFieldValue('complianceCity', '', false);
  };

  const handleCityChange = (selectedOption: any) => {
    setFieldValue('complianceCity', selectedOption.selectedItem.id, false);
  };

  const states = availableComplianceStates();
  const stateOptions = Object.keys(states).map(stateCode => {
    return {
      label: states[stateCode].state_name,
      id: states[stateCode].state,
    };
  });

  const initialSelectedState = stateOptions.find(
    state => state.id === initialValues.complianceState,
  );

  const citiesForState = availableComplianceCitiesForSelectedState(
    values.complianceState,
  );

  return (
    <>
      <ComplianceDropdown
        items={stateOptions}
        name='complianceState'
        onChange={handleStateChange}
        titleText='Compliance state'
        id='complianceState'
        data-testid='compliance-state-dropdown'
        label='Compliance state'
        initialSelectedItem={initialSelectedState}
        warn={!!errors.complianceState}
        warnText={errors.complianceState}
        selectedItem={values.complianceState}
      />
      {citiesForState.length > 0 && (
        <ComplianceDropdown
          name='complianceCity'
          items={citiesForState}
          onChange={handleCityChange}
          titleText='Compliance city'
          id='complianceCity'
          label='Compliance city'
          initialSelectedItem={initialValues.complianceCity}
          warn={!!errors.complianceCity}
          warnText={errors.complianceCity}
          selectedItem={{
            label: values.complianceCity,
            id: values.complianceCity,
          }}
        />
      )}
    </>
  );
};

export default ComplianceStateAndCitySelector;
