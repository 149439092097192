import { Namespace } from 'modules/assess/api';
import React from 'react';
import { useNamespace } from '../../router/context';

import Content from './content';
import { useInstanceVersion } from './state';

export type Props = {
  disabled?: boolean;
};

export const Container: React.FC<Props> = ({ disabled }) => {
  const instance = useInstanceVersion();
  const namespace = useNamespace();

  if (namespace !== Namespace.mvr) {
    return null;
  }

  return (
    <Content
      disabled={disabled}
      instance={instance?.id}
      version={instance?.version.id}
    />
  );
};

export default Container;
