import { createContext } from 'react';
import { getParamFromUrl } from '@dashboard-experience/utils';

type Context = {
  contextId: string | null;
  isIframe: boolean;
  isStandaloneIframe: boolean;
};

const UIContext = createContext<Context>({
  isIframe: getParamFromUrl(window, 'context') === 'iframe',
  isStandaloneIframe: getParamFromUrl(window, 'standaloneIframe') === 'true',
  contextId: getParamFromUrl(window, 'contextId'),
});

export default UIContext;
