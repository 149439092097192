import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  disabled?: boolean;
  onClick: (e: SyntheticEvent) => void;
};

export const TooltipButton: React.FC<Props> = ({ disabled, onClick }) => {
  const { t } = useTranslation('assess');
  const buttonText = t(`assess:rulesets.actions.create.label`);
  const tooltipText = t(`assess:rulesets.actions.create.disabled.tooltip`);
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <M.TooltipButton
      data-testid='assess-rulesets-actions-create'
      label={tooltipText}
      className={disabledClass}
      trans='assess:rulesets.actions.create'
      customStyles={{
        button: { width: '10.2rem' },
      }}
      // eslint-disable-next-line
      onClick={!disabled ? onClick : () => {}}
    >
      {buttonText}
    </M.TooltipButton>
  );
};

export default TooltipButton;
