import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Trans, useTranslation } from 'react-i18next';

import {
  exam_reorder_modal_body,
  exam_reorder_modal_footer,
} from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEventOnMount,
  ReorderEvent,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';
import { useModalState } from '../../../context';
import { ActionType } from '../../../types';
import InfoBanner from './InfoBanner';
import ChoiceGroup from '../choice-group';
import ModalBody from '../body';
import NewDeadlineSelection from './new-deadline-selection';
import { isNextButtonDisabled } from './utils';

const body = `${exam_reorder_modal_body}.appointment`;

const Appointment: React.FC<{}> = () => {
  const { t } = useTranslation();
  const nextScreen = useModalState('nextScreen');
  const previousScreen = useModalState('previousScreen');
  const { allowNewAppointment, appointmentDeadline } =
    useModalState('formData');
  const disabled = isNextButtonDisabled(
    appointmentDeadline,
    allowNewAppointment,
  );

  const navigation = {
    back: {
      name: t(`${exam_reorder_modal_footer}.back`),
      onClick: previousScreen,
    },
    next: {
      name: t(`${exam_reorder_modal_footer}.continue`),
      onClick: nextScreen,
      disabled,
    },
  };

  useTrackEventOnMount(ReorderEvent.ModalAppointmentDeadlineViewed);

  return (
    <>
      <ModalBody>
        <section data-testid='appointment-header'>
          <h2>{t(`${body}.title`)}</h2>
          <Trans t={t} i18nKey={`${body}.description`} />
        </section>

        <ChoiceGroup
          header='Do you want to set a new appointment deadline?'
          type={ActionType.SET_ALLOW_NEW_APPOINTMENT}
          defaultSelected={allowNewAppointment}
        />

        {allowNewAppointment === 'Yes' && <NewDeadlineSelection />}

        <InfoBanner />
      </ModalBody>
      <M.ModalNavigationFooter back={navigation.back} next={navigation.next} />
    </>
  );
};

export default Appointment;
