import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { findEnvVar } from '@dashboard-experience/utils';
import { CandidatePostalAddress } from '../../types';

type Props = {
  handleAddress: (address: CandidatePostalAddress) => void;
  address: CandidatePostalAddress;
};

const AddressAutocomplete: React.FC<Props> = ({ handleAddress, address }) => {
  const [selected, setSelected] = useState(null);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: `${findEnvVar('GOOGLE_PLACES_API_KEY')}&loading=async`,
    });

  const parseAddress = useCallback(
    addressComponents => {
      const newAddress: CandidatePostalAddress = {};

      addressComponents.forEach((component: any) => {
        if (component.types.includes('street_number')) {
          newAddress.street = newAddress.street
            ? component.long_name.concat(` ${newAddress.street}`)
            : component.long_name;
        }
        if (component.types.includes('route')) {
          newAddress.street = newAddress.street
            ? newAddress.street.concat(` ${component.short_name}`)
            : component.short_name;
        }
        if (component.types.includes('subpremise')) {
          newAddress.street2 = component.short_name;
        }
        if (component.types.includes('locality')) {
          newAddress.city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          newAddress.state = component.short_name;
        }
        if (component.types.includes('postal_code')) {
          newAddress.zipcode = component.long_name;
        }
      });
      if (address !== newAddress) handleAddress(newAddress);
    },
    [handleAddress],
  );

  useEffect(() => {
    if (selected) {
      placesService?.getDetails(
        {
          // @ts-ignore
          placeId: selected.place_id,
        },
        placeDetails => {
          if (placeDetails) parseAddress(placeDetails.address_components);
        },
      );
    }
  }, [parseAddress, placesService, selected]);

  const getPredictions = useCallback(
    (text: string) => {
      getPlacePredictions({
        input: text,
        locationBias: 'IP_BIAS',
        region: 'us',
      });
    },
    [getPlacePredictions],
  );

  const displayPrediction = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {
      return prediction ? prediction.description : '';
    },
    [],
  );

  const handleSelect = useCallback(({ selectedItem }) => {
    setSelected(selectedItem);
  }, []);

  return (
    <M.ComboBox
      id='address-select'
      onChange={handleSelect}
      onInputChange={getPredictions}
      items={placePredictions}
      itemToString={displayPrediction}
      initialSelectedItem={selected}
    />
  );
};

export default AddressAutocomplete;
