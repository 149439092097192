import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Disclaimer = styled(M.InlineNotification)`
  max-width: 100%;
`;

export const DateFields = styled.div`
  display: flex;
  .mastodon-copy-field {
    display: inline-flex;
    margin-right: 1rem;
    width: 32%;

    .mastodon-copy-text {
      width: 2rem;
    }

    :last-of-type {
      margin-right: 0rem;
    }
  }
`;

export const AdditionalInfo = styled(M.ActionableNotification)`
  flex-direction: unset !important;
  .cds--actionable-notification__text-wrapper {
    flex-direction: column;
    width: 100%;

    button {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    .mastodon-key-value-list {
      width: 80%;

      .mastodon-key-value-line-item .key {
        color: ${colors.uiTextPrimaryLight} !important;
      }
    }
  }
`;

type Props = {
  stateName: string | undefined;
};

export const StateDisclaimer: React.FC<Props> = ({ stateName }) => {
  return (
    <Disclaimer
      kind='info'
      title={`All ${stateName} county search results are listed under the ${stateName} State search`}
      hideCloseButton
    />
  );
};

const stateCrimInfoTooltip = (
  <>
    Checkr does not report records from this search. Instead, we use the records
    found as pointers that we verify by conducting a county search in the
    relevant county.
    <br />
    <br />
    We report verified records in the county search results. If no counties were
    searched, it means no records were found.
  </>
);

export const StateCriminalInfo = () => {
  return (
    <span>
      Checkr conducts county searches to verify records identified by this
      search.{' '}
      <M.TooltipDefinition
        role='additionalInfoTooltip'
        highlighted={false}
        definition={stateCrimInfoTooltip}
      >
        <M.Icon icon='InformationFilled' />
      </M.TooltipDefinition>
    </span>
  );
};
