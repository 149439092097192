import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

import { useSearchState } from 'components/Search/search-context';
import { Results as Text } from '../StyledComponents';

const LoadingBlock = styled.div`
  display: inline-block;
  .cds--inline-loading {
    min-height: 0;
    .cds--inline-loading__text {
      line-height: 1.7;
    }
  }
`;

const Results = ({ isLoading }: { isLoading: boolean }) => {
  const {
    state: { candidates },
  } = useSearchState();

  if (!candidates || isLoading)
    return (
      <LoadingBlock>
        <M.LoadingInline description='Searching...' />
      </LoadingBlock>
    );
  const { count } = candidates;
  return (
    <>
      {!isNaN(count) ? (
        <Text>{`${count.toLocaleString('en')} results`}</Text>
      ) : (
        <div />
      )}
    </>
  );
};

export default Results;
