import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { Report } from 'types';

import Alias from './Alias';

const Aliases: React.FC<{ report: Report }> = ({ report }) => {
  const { ssn_trace } = report;
  const aliases = ssn_trace?.aliases || [];

  if (!aliases || aliases.length === 0) return null;

  return (
    <M.Container data-testid='aliases'>
      <M.Container.Row>
        <M.Container.Col>
          <span className='heading-text-name heading-text h4'>Aliases</span>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          {aliases.map(alias => (
            <Alias key={alias.id} alias={alias} />
          ))}
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

export default Aliases;
