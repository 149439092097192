import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { CC_ENROLLMENT_VISIBILITY_KEY } from '../../Constants';
import {
  BulkActionTable,
  ContinuousServicesHeader,
  DriverRecordReportsTable,
} from '../../components/ContinuousServices';
import IneligiblePersonsView from '../../components/ContinuousServices/ContinuousMvr/IneligiblePersons/IneligiblePersonsView';
import EnrollmentsView from '../../components/ContinuousServices/EnrollmentsView';

const ContinuousServicesContainer: React.FC = () => {
  const currentUser = useUser();
  const canViewMvrTabs = currentUser?.account?.continuous_check_mvr;
  const canViewEnrollments =
    useFlag(CC_ENROLLMENT_VISIBILITY_KEY)?.variantKey === 'on';

  const tabs: {
    label: string;
    testid?: string;
    content: React.ReactNode;
  }[] = [];

  if (canViewEnrollments) {
    tabs.push({
      label: 'Active enrollments',
      content: <EnrollmentsView />,
    });
  }

  tabs.push({
    label: 'Bulk action',
    content: <BulkActionTable />,
  });

  if (canViewMvrTabs) {
    tabs.push(
      {
        label: 'Ineligible persons',
        testid: 'ineligible-persons-tab',
        content: <IneligiblePersonsView />,
      },
      {
        label: 'Driver record reports (DL 414)',
        content: <DriverRecordReportsTable />,
      },
    );
  }

  return (
    <span>
      <h2>Continuous checks</h2>
      <ContinuousServicesHeader />
      <br />
      <br />
      {tabs.length > 0 && (
        <M.Tabs>
          <M.TabList contained>
            {tabs.map(({ label, testid }) => (
              <M.Tab key={label} data-testid={testid}>
                {label}
              </M.Tab>
            ))}
          </M.TabList>
          <M.TabPanels>
            {tabs?.map(({ label, content }) => (
              <M.TabPanel key={label}>{content}</M.TabPanel>
            ))}
          </M.TabPanels>
        </M.Tabs>
      )}
    </span>
  );
};

export default ContinuousServicesContainer;
