import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 690px;
  color: ${colors.brandNavy4};
`;

const Bold = styled.span`
  font-weight: 700;
`;

const TitleSection: React.FC<{ createReportView?: boolean }> = ({
  createReportView = false,
}) => {
  if (createReportView === undefined) {
    return <M.LoadingSpinner withOverlay={false} />;
  }

  if (createReportView) {
    return (
      <Title>
        Your additional checks will begin processing as soon as you submit your
        order.
      </Title>
    );
  }

  return (
    <Title>
      At least one added check requires more information.{' '}
      <Bold>We&apos;ll email the candidates a new invitation link</Bold> to
      submit the required details.{' '}
    </Title>
  );
};

export default TitleSection;
