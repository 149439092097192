enum ValidationErrors {
  REQUIRED = 'Required field',
  TOO_SHORT = 'Too short',
  TOO_LONG = 'Too long',
  INVALID_EMAIL = 'Invalid email',
  INVALID_ZIP_CODE = 'Invalid Zip Format, please use #####',
  INVALID_ADDRESS = 'Invalid address format. P.O. Box not allowed',
}

export default ValidationErrors;
