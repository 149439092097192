import React, { useCallback, useMemo, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { AACSVExportFilters, CSVExportParams } from 'api/csvExport';
import { formatDate } from '@dashboard-experience/utils';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import moment from 'moment/moment';
import { CSV_TYPES, ModalType, OptionalProps, Screens } from './types';
import { submit } from './utils';
import {
  StyledComposedModal,
  StyledChoiceCardGroup,
} from './CsvExportStyledComponents';
import { isDateValid } from '../Search';

type Filters = Partial<AACSVExportFilters>;

type ChoiceCard = {
  id: string;
  value: string;
  title: JSX.Element | string;
  description: string;
  disabled?: boolean;
};

type Props = OptionalProps & {
  downloadCsvDisabled?: boolean;
  filters: Filters;
  open: boolean;
  postDownloadReportCsv: (params: CSVExportParams) => Promise<any>;
  postDownloadInvitationCsv: (params: CSVExportParams) => Promise<any>;
  processing: boolean;
  screen: Screens.INITIAL | Screens.SUCCESS;
  toggle: () => void;
  type:
    | ModalType.AdverseAction
    | ModalType.CandidateReports
    | ModalType.DriverRecordReports
    | ModalType.SubscriptionErrors;
};

const choiceCards: ChoiceCard[] = [
  {
    id: CSV_TYPES.REPORT_DATA,
    value: CSV_TYPES.REPORT_DATA,
    title: 'Report data',
    description: 'All candidate details and report outcomes',
  },
  {
    id: CSV_TYPES.INVITATION_STATUS,
    value: CSV_TYPES.INVITATION_STATUS,
    title: 'Invitation status',
    description: 'Name, email, invitation status & date only',
  },
];

const CsvReportAndInvitationExportModal: React.FC<Props> = ({
  downloadCsvDisabled,
  filters,
  open,
  postDownloadReportCsv,
  postDownloadInvitationCsv,
  processing,
  screen,
  toggle,
  type,
  defaultDateDiffStartFromDays = 30,
}) => {
  const user = useUser();
  const email: string = user?.email;
  const [selectedType, setSelectedType] = useState<CSV_TYPES>(
    downloadCsvDisabled ? CSV_TYPES.INVITATION_STATUS : CSV_TYPES.REPORT_DATA,
  );

  const defaultFromDate: string = defaultDateDiffStartFromDays
    ? moment()
        .subtract(defaultDateDiffStartFromDays, 'days')
        .startOf('day')
        .toISOString()
    : '';

  const [fromDate, setFromDate] = useState<any>(
    formatDate(defaultFromDate, 'MM/DD/YYYY'),
  );
  const [toDate, setToDate] = useState<any>('');
  const invalid: boolean = new Date(fromDate) > new Date(toDate);

  const handleCardChoice = useCallback(
    (value: CSV_TYPES): void => {
      setSelectedType(value);
    },
    [setSelectedType],
  );

  const hideModal = useCallback(() => {
    toggle();
  }, [toggle]);

  const trackEvent = useTrackEvent();

  const onClick = useCallback(() => {
    if (user) {
      const csvTypeMap = {
        [CSV_TYPES.REPORT_DATA]: 'Report data',
        [CSV_TYPES.INVITATION_STATUS]: 'Invitation status',
      };

      trackEvent(
        CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_DOWNLOAD_CSV_MODAL_EXPORT_BUTTON_CLICKED,
        {
          List: csvTypeMap[selectedType],
          'Start date': fromDate,
          'End date': toDate,
        },
      );
    }

    if (selectedType === CSV_TYPES.REPORT_DATA) {
      submit({
        from: new Date(fromDate).toISOString(),
        to: new Date(toDate || new Date()).toISOString(),
        post: postDownloadReportCsv,
        filters,
      });
    } else {
      submit({
        from: new Date(fromDate).toISOString(),
        to: new Date(toDate || new Date()).toISOString(),
        post: postDownloadInvitationCsv,
        filters,
      });
    }
    if (type === ModalType.DriverRecordReports) hideModal();
  }, [
    user,
    fromDate,
    toDate,
    filters,
    hideModal,
    postDownloadInvitationCsv,
    postDownloadReportCsv,
    selectedType,
    type,
    trackEvent,
  ]);

  const onInputChange = useCallback(
    (setState: (val: string) => void) =>
      (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e?.target as HTMLInputElement)?.value;

        if (isDateValid(value)) {
          setState(value);
        } else {
          setState('');
        }
      },
    [],
  );

  const onDateChange = useCallback(
    (setState: (val: any) => void) => (date: any) => {
      setState(formatDate(date, 'MM/DD/YYYY'));
    },
    [],
  );

  const reportDataCardLabelHTML = useMemo((): JSX.Element => {
    return (
      <>
        <M.TooltipLabel>Report data</M.TooltipLabel>
        {downloadCsvDisabled && (
          <M.TooltipDefinition
            highlighted={false}
            definition='Report data is not available for download when filtering invitation status by Sent or Expired'
            direction='bottom'
          >
            <M.Icon
              icon='InformationFilled'
              style={{ fill: colors.brandSlate5 }}
            />
          </M.TooltipDefinition>
        )}
      </>
    );
  }, [downloadCsvDisabled]);

  const updatedChoiceCards = useMemo((): ChoiceCard[] => {
    return choiceCards.map((item: ChoiceCard) => {
      if (item.id === CSV_TYPES.REPORT_DATA) {
        return {
          ...item,
          title: reportDataCardLabelHTML,
          disabled: downloadCsvDisabled,
        };
      }
      return item;
    });
  }, [reportDataCardLabelHTML, downloadCsvDisabled]);

  return (
    <StyledComposedModal onClose={toggle} open={open}>
      <M.ModalHeader closeModal={toggle}>
        <h2 data-testid='csv-export-modal-header'>
          {screen === Screens.INITIAL && 'Download candidate list'}
          {screen === Screens.SUCCESS && 'Request processing'}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <>
          {screen === Screens.INITIAL && (
            <>
              <p style={{ fontSize: '1rem' }}>
                Select the type of list you want to download
              </p>
              <StyledChoiceCardGroup
                type='radio'
                name='csv_type'
                defaultSelected={selectedType}
                onChange={handleCardChoice}
              >
                {updatedChoiceCards.map(({ id, ...props }) => (
                  <M.ChoiceCard key={id} id={id} {...props} />
                ))}
              </StyledChoiceCardGroup>
              {selectedType === CSV_TYPES.REPORT_DATA && (
                <>
                  <h6>Report created date range</h6>
                  <p className='helper-text'>
                    Downloads are limited to 10,000 rows and do{' '}
                    <strong>not</strong> include sent or expired invitations
                  </p>
                </>
              )}
              {selectedType === CSV_TYPES.INVITATION_STATUS && (
                <>
                  <h6>Invitation sent date range</h6>
                  <p className='helper-text'>
                    Downloads are limited to 10,000 rows
                  </p>
                </>
              )}
              <M.DateFilter
                inputs={[
                  {
                    value: fromDate,
                    labelText: 'Start',
                    id: 'date-filter-export-csv-start',
                    onChange: onInputChange(setFromDate),
                    onDateChange: onDateChange(setFromDate),
                    autoComplete: 'off',
                  },
                  {
                    labelText: 'End',
                    id: 'date-filter-export-csv-end',
                    onChange: onInputChange(setToDate),
                    onDateChange: onDateChange(setToDate),
                    invalid,
                    invalidText: 'Start date must be before End date',
                    autoComplete: 'off',
                  },
                ]}
              />
            </>
          )}
          {screen === Screens.SUCCESS && (
            <div style={{ textAlign: 'center', minHeight: '13rem' }}>
              <M.Icon
                icon='Email'
                size={92}
                style={{ fill: `${colors.uiNavy600}` }}
              />
              <h5>
                We received your request and your download will be ready soon.
              </h5>
              <p style={{ fontSize: '1rem' }}>
                You&apos;ll get an email at{' '}
                <M.Link visited={false} size='lg' disabled>
                  {email}
                </M.Link>{' '}
                when your download is ready. Please note that processing times
                may vary based on file size.
              </p>
            </div>
          )}
        </>
      </M.ModalBody>
      <M.ModalFooter>
        {screen === Screens.INITIAL ? (
          <M.BinaryButtons
            size='small'
            btnLeft={{
              name: 'Cancel',
              onClick: toggle,
            }}
            btnRight={{
              name: 'Export CSV',
              onClick,
              disabled: processing || !fromDate || invalid,
            }}
          />
        ) : (
          <M.Button onClick={toggle}>Close</M.Button>
        )}
      </M.ModalFooter>
    </StyledComposedModal>
  );
};

export default CsvReportAndInvitationExportModal;
