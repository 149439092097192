import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  Type as FairChanceMetrics,
  emptyAdverseActions,
  hasLessThanThirtyAdverseActions,
} from 'modules/fair-chance-metrics/models';
import useFetch, { useAnalyticsRole } from './api/hooks';
import Title from './Title';
import { HighPerformers, LowPerformers, Midmarket, SMB } from './views';

const StyledContainer = styled.div`
  max-width: 720px;
  margin: 30px 0;
`;

const FairChance: React.FC = () => {
  const isUserAnalyticsEnabled = useAnalyticsRole();
  const query = useFetch();
  const { data, status, isError } = query;
  const loading = status === 'loading';

  if (loading) {
    return <M.LoadingSpinner />;
  }

  const View = getView(isUserAnalyticsEnabled, isError, data);
  return (
    <StyledContainer data-testid='fair-chance-container'>
      <Title />
      {View && <View metrics={data} />}
    </StyledContainer>
  );
};

function getView(
  isUserAnalyticsEnabled: boolean,
  isError: boolean,
  metrics?: FairChanceMetrics,
) {
  if (isError) return SMB;

  if (!metrics) {
    return null;
  }

  if (emptyAdverseActions(metrics)) {
    return metrics.has_a_valid_segment ? Midmarket : SMB;
  }

  if (hasLessThanThirtyAdverseActions(metrics) || !isUserAnalyticsEnabled) {
    return SMB;
  }

  return metrics.has_above_than_average_adverse_actions_rate
    ? LowPerformers
    : HighPerformers;
}

export default FairChance;
