import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import Body from 'components/FairChance/Content';
import Sections from 'components/FairChance/sections';

const Container = styled(M.Container)`
  width: 152px;
`;

const StyledBody = styled.div`
  color: ${colors.uiGrey700};
  text-align: center;
`;

const TotalContainer = styled.h1`
  margin-top: 1rem !important;
  text-align: center;
`;

type Props = {
  content: any;
  data?: number;
};

const AdverseActionedDetail: React.FC<Props> = ({ content, data }) => {
  const { paragraphs } = content;
  const body = (
    <Body paragraphs={paragraphs} section={Sections.fair_chance_opportunity} />
  );
  return (
    <Container type='shadow'>
      <TotalContainer>{data}</TotalContainer>
      <StyledBody>{body}</StyledBody>
    </Container>
  );
};

export default AdverseActionedDetail;
