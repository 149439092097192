import { t } from 'i18next';
import { BillingEntities } from '../types/Billing';

export type PaymentProfileValidation = {
  valid: boolean;
  error_message?: string;
};

export const validateNewIdPattern = (
  newId: string,
): PaymentProfileValidation => {
  const regex = /^[a-zA-Z0-9_-]{0,50}$/;

  if (newId === '') {
    return {
      valid: false,
      error_message: t(`payment_profile.form.id.required_error`),
    };
  }
  if (regex.test(newId)) {
    return { valid: true };
  }

  return {
    valid: false,
    error_message: t(`payment_profile.form.id.pattern_error`),
  };
};

export const validateNewIdUniqueness = (
  newId: string,
  existingProfiles: BillingEntities,
): PaymentProfileValidation => {
  let matchingProfile;

  existingProfiles.forEach(profile => {
    if (newId === profile.id) {
      matchingProfile = profile;
    }
  });

  if (matchingProfile === undefined) {
    return { valid: true };
  }

  return {
    valid: false,
    error_message: t(`payment_profile.form.id.unique_error`),
  };
};
