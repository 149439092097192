import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports/hooks';
import {
  useDispatch,
  useModalState,
} from 'modules/adjudication/ui/adverse-action/initiate/flow/context';
import { AdversableItem, BasisAvailability } from 'modules/adjudication/data';

const BASE_PATH = `${namespace}:report.actions.pre_adverse.adverse_items`;

const Header = styled.p`
  margin-bottom: 10px !important;
`;

const Disclaimer = styled.p`
  margin-top: 20px !important;
`;

const Title = styled.strong`
  display: block;
`;

type Option = {
  id: string;
  value: string;
  title: string;
  disabled?: boolean;
};

const buildBasisOptions = (
  baseTranslationPath: string,
  bases: BasisAvailability[],
  t: any,
) => {
  const options: Option[] = [];

  bases?.forEach((basis) => {
    options.push({
      id: basis.id,
      value: basis.id,
      title: t(`${baseTranslationPath}.options.${basis.id}`),
      disabled: !basis.enabled,
    });
  });

  return options;
};

const Basis: React.FC<any> = ({ newAdverseAction }) => {
  const { report } = useGetReport();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { basis, adverseItems } = useModalState('state');

  const useCustomBasis =
    report?.account?.adverse_action_report_suspended_custom_basis;
  const basisTranslationKey = useCustomBasis ? 'custom_basis' : 'basis';
  const basePath = `${BASE_PATH}.${basisTranslationKey}`;
  const title = t(`${basePath}.title`);
  const disclaimer = t(`${basePath}.disclaimer`);

  const options: Option[] = buildBasisOptions(
    basePath,
    newAdverseAction.bases,
    t,
  );

  const onBasisChange = useCallback(
    (value: string) => {
      dispatch({ type: 'SET_BASIS', payload: value });
      if (value === 'candidate_action_unresolved') {
        adverseItems.forEach((adverseItem: AdversableItem) =>
          dispatch({ type: 'SET_ADVERSE_ITEM', payload: adverseItem }),
        );
      }
    },
    [dispatch, adverseItems],
  );

  const optionsAvailable = options.filter((option) => !option.disabled);
  const defaultBasis =
    optionsAvailable.length === 1 && !basis ? optionsAvailable[0].value : null;

  useEffect(() => {
    if (!report || !defaultBasis) {
      return;
    }

    onBasisChange(defaultBasis);
  }, [report, onBasisChange, defaultBasis]);

  if (options.length < 2) return null;

  return (
    <section>
      <Header data-testid='basis-header'>
        <Title>{title}</Title>
      </Header>
      <M.ChoiceCardGroup
        type='radio'
        name='basisGroup'
        data-testid='basis-group'
        defaultSelected={basis ?? defaultBasis}
        onChange={onBasisChange}
      >
        {options.map(({ id, ...props }) => (
          <M.ChoiceCard data-testid={id} key={id} id={id} {...props} />
        ))}
      </M.ChoiceCardGroup>
      {useCustomBasis && (
        <Disclaimer data-testid='basis-disclaimer'>{disclaimer}</Disclaimer>
      )}
    </section>
  );
};

export default Basis;
