import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

import { exam_reorder_modal_footer } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEvent,
  ReorderEvent,
  EventProperty,
  EventValue,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';
import CustomError from './custom-error';
import { useModalState } from '../../../context';
import { AddExamError } from '../../../types';
import Description from './description';

type Props = {
  error: AddExamError;
};

const ClinicError: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const onClose = useModalState('onClose');
  const nextScreen = useModalState('nextScreen');
  const trackEvent = useTrackEvent();

  const trackAction = useCallback(
    (action: string) =>
      trackEvent(ReorderEvent.ErrorAction, {
        [EventProperty.ErrorAction]: action,
      }),
    [trackEvent],
  );

  const handleClose = useCallback(() => {
    onClose();
    trackAction(EventValue.Cancel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEvent]);

  const handleNext = useCallback(() => {
    nextScreen();
    trackAction(EventValue.ChooseNewClinic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEvent]);

  const navigation = {
    close: {
      name: t(`${exam_reorder_modal_footer}.cancel`),
      onClick: handleClose,
    },
    next: {
      name: t(`${exam_reorder_modal_footer}.clinic`),
      onClick: handleNext,
    },
  };

  return (
    <>
      <CustomError description={<Description error={error} />} />
      <M.ModalNavigationFooter
        close={navigation.close}
        next={navigation.next}
      />
    </>
  );
};

export default ClinicError;
