import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import ManualBulkOrderHistoryTableCell from './ManualBulkOrderHistoryTableCell';
import ManualBulkOrderHistory from './ManualBulkOrderHistory';
import { TableContainer } from '../BulkOrderHistoryStyled';

type Props = {
  headers: Array<{ name: string }>;
  rows: Array<{ cells: any }>;
  data: ManualBulkOrderHistory[];
};

const ManualBulkOrderHistoryTable: React.FC<Props> = ({
  rows,
  headers,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <M.Table data-testid='manual-bulk-order-history-table'>
        <M.TableHead className='border-top'>
          <M.TableRow>
            {headers.map(header => (
              <M.TableHeader key={header.name}>
                {t(`headings.${header.name}`)}
              </M.TableHeader>
            ))}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {rows.map((row, i) => {
            const dataRow = data[i];
            return (
              dataRow && (
                <M.TableRow
                  key={dataRow.id}
                  data-testid={`manual-bulk-order-history-row-${i}`}
                >
                  {row.cells.map(
                    (cell: { info: { header: any }; id: string }) => {
                      const cellProps = {
                        header: cell.info.header,
                        row: dataRow,
                        cell,
                      };
                      return (
                        <ManualBulkOrderHistoryTableCell
                          key={cell.id}
                          {...cellProps}
                        />
                      );
                    },
                  )}
                </M.TableRow>
              )
            );
          })}
        </M.TableBody>
      </M.Table>
    </TableContainer>
  );
};

export default ManualBulkOrderHistoryTable;
