import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import isEmpty from 'lodash/isEmpty';
import Context from 'components/Packages/context';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  ADD_CHECKS,
} from 'components/Packages/OrderBackgroundCheck/Context';
import { useUpdatedState } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { NodeDropdown as SharedNodeDropdown } from '../../SharedItems';

const NodeDropdown = styled(SharedNodeDropdown)`
  margin-bottom: 0;
`;

const LoadingBlock = styled(M.LoadingBlock)`
  width: '280px';
  height: '40px';
`;

const SelectNode = ({ hierarchy_present }: { hierarchy_present: boolean }) => {
  const {
    isSingleNode,
    nodes,
    selectedNode,
    nodeIsLoading,
    onNodeChange,
    onNodeFilterChange,
  } = useContext(Context);

  const { dispatch, state } = useOrderBackgroundCheckContext();

  const [node, setNode] = useUpdatedState(selectedNode, onNodeChange);

  const [nodeFilter, setNodeFilter] = useState('');

  const onNodeFilter = useCallback(
    newFilter => {
      setNodeFilter(newFilter);
      onNodeFilterChange(newFilter);
    },
    [setNodeFilter, onNodeFilterChange],
  );

  // If nodes aren't loading anymore and a filter has been provided, and no nodes were found, its invalid
  const nodeInvalid = useMemo(
    () => !nodeIsLoading && nodeFilter?.length > 0 && nodes.length === 0,
    [nodeIsLoading, nodeFilter, nodes],
  );

  const resetCountry = useCallback(() => {
    if (state.workflow === ADD_CHECKS) return;
    dispatch({
      type: actionTypes.ADD_LOCATION,
      payload: { location: { country: 'US', state: '', city: '' } },
    });
  }, [dispatch]);

  const handleNodeChange = useCallback(
    (selectedItem: any) => {
      setNode(selectedItem);

      if (selectedItem) {
        dispatch({
          type: actionTypes.SET_NODE,
          payload: { node: selectedItem },
        });
      } else {
        dispatch({
          type: actionTypes.SET_NODE,
          payload: { node: {} },
        });
      }

      resetCountry();
    },
    [dispatch, resetCountry, setNode],
  );

  useEffect(() => {
    if (isSingleNode && selectedNode && Object.keys(selectedNode).length > 0) {
      handleNodeChange(selectedNode);
    }
  }, [selectedNode, isSingleNode, handleNodeChange]);

  const setSelectedNode = useCallback(() => {
    if (!isEmpty(state.node)) {
      return state.node;
    }
    if (!isEmpty(node)) {
      return node;
    }
    return null;
  }, [node, state.node]);

  return (
    <>
      {hierarchy_present && !nodes && state.workflow === ADD_CHECKS && (
        <LoadingBlock />
      )}
      {hierarchy_present && nodes && (
        <NodeDropdown
          data-testid='node-dropdown-testid'
          id='node-select'
          nodes={nodes}
          selectedNode={setSelectedNode}
          loading={nodeIsLoading}
          invalid={nodeInvalid}
          disabled={isSingleNode}
          onChange={handleNodeChange}
          onFilterChange={onNodeFilter}
        />
      )}
    </>
  );
};

export default SelectNode;
