import React from 'react';
import { ReportProps } from 'types';
import GeneralComments from './GeneralComments';

const StatementOfDispute: React.FC<ReportProps> = ({ report }) => {
  if (!report.dispute_comments?.length) return null;
  return <GeneralComments report={report} commentType='dispute_comments' />;
};

export default StatementOfDispute;
