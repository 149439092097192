import { GenericObject } from 'types';
import { ReportTaskType } from '../ui/report-tasks/types';
import client from './client';

export const REPORT_TASKS_PATH = 'report_tasks';

export const setReportTask = (
  reportId: string,
  params: ReportTaskType,
): Promise<GenericObject> => {
  const path = `reports/${reportId}/${REPORT_TASKS_PATH}`;
  return client.post(path, params);
};
