import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Icon from 'modules/assess/ui/common/icon';
import { Category } from 'modules/assess/models/mvr/category';
import { useDestroy } from './api';
import { useCCSV } from '../../context';
import CategoryNameInput from './category-name-input';

const CategoryRowContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const CategoryNameWrapper = styled.div`
  flex: 1;
`;

type Props = {
  row: Category;
};

const CategoryRow: React.FC<Props> = ({ row }) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const ccsv = useCCSV();
  const destroy = useDestroy(ccsv?.category_code_set?.id, 'draft', row.id);
  const focusOut = useCallback(() => setIsBeingEdited(false), [isBeingEdited]);
  const onRemove = useCallback(() => {
    destroy.call();
  }, [row]);
  const onEdit = useCallback(() => {
    setIsBeingEdited(true);
  }, [isBeingEdited]);

  if (!ccsv?.draft) {
    return <span data-testid='mvr-ccsv-category-row'>{row.name}</span>;
  }

  return (
    <CategoryRowContainer data-testid='mvr-ccsv-category-row'>
      {isBeingEdited ? (
        <CategoryNameInput
          focusOut={focusOut}
          categoryId={row.id}
          categoryName={row.name}
        />
      ) : (
        <>
          <CategoryNameWrapper>{row.name}</CategoryNameWrapper>
          <IconWrapper data-testid='mvr-ccsv-category-edit'>
            <Icon name='edit' onClick={onEdit} tooltip={false} />
          </IconWrapper>
          <IconWrapper data-testid='mvr-ccsv-category-delete'>
            <Icon name='remove' onClick={onRemove} tooltip={false} />
          </IconWrapper>
        </>
      )}
    </CategoryRowContainer>
  );
};

export default CategoryRow;
