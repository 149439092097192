import styled from 'styled-components';

const FlexContainer = styled.div<{ gap: string; padding: string }>`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap};
  padding: ${props => props.padding};

  .mastodon-table-container {
    align-self: stretch;
  }

  .mastodon-tabs-container {
    align-self: stretch;
  }

  .cds--radio-button__appearance {
    margin-right: 0 !important;
  }
`;

export default FlexContainer;
