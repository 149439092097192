import { TABLE_SORT_DIRECTION } from './enums';

const collationFactors = {
  [TABLE_SORT_DIRECTION.ASCENDING]: 1,
  [TABLE_SORT_DIRECTION.DESCENDING]: -1,
};

const useSortedRows = (rows: any, sortInfo: any) => {
  const { columnId: sortColumnId, direction: sortDirection } = sortInfo;

  const sortedRows =
    sortDirection === TABLE_SORT_DIRECTION.NONE
      ? rows
      : rows.slice().sort((lhs: any, rhs: any) => {
          if (!lhs[sortColumnId]) {
            return -1 * collationFactors[sortDirection];
          }
          if (!rhs[sortColumnId]) {
            return 1 * collationFactors[sortDirection];
          }

          return (
            collationFactors[sortDirection] *
            lhs[sortColumnId].localeCompare(rhs[sortColumnId])
          );
        });

  return [sortedRows];
};

export default useSortedRows;
