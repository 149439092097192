import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useGetFormsI9,
  FormI9Response,
  usePostFormI9CSVFile,
  useGetWorksites,
  AccountSettingsResponse,
} from 'api/i9';
import {
  removeParamFromUrl,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import moment from 'moment';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import SortableTableHeader from './SortableTableHeader';
import useSubmitFormsI9PDF from './hooks/useSubmitFormsI9PDF';
import downloadPDF from './utils/downloadPDF';
import useNavigate from './hooks/useNavigate';
import {
  FlexContainer,
  FlexRow,
  FlexRowAlignRight,
  FlexRowJustify,
} from './style';
import { useUser } from '../../context/CurrentUser';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const ActionsMenu = styled(M.Menu)`
  .mastodon-menu-button {
    padding: 0 !important;
  }
  .cds--overflow-menu {
    &:hover {
      background-color: unset;
    }
  }
`;

const ResultsText = styled.span`
  color: ${colors.uiTextSecondaryLight};
  font-size: 1rem !important;
`;

const EmptyResultsMessage = styled.span`
  color: #000;
  font-size: 1.25rem !important;
  line-height: 2rem !important;
`;

const PAGE_SIZE = 25;
const searchFilterDelayMs = 1000;

const headerDefinitions = [
  {
    key: 'full_name',
    header: 'Employee',
    sortBy: 'full_name',
  },
  {
    key: 'order_progress_text',
    header: 'Order progress',
  },
  {
    key: 'start_date',
    header: 'Employment dates',
    sortBy: 'start_date',
  },
  {
    key: 'worksite_name',
    header: 'Worksite',
    sortBy: 'worksite_name',
  },
  {
    key: 'open_tasks_text',
    header: 'Open tasks',
    sortBy: 'open_tasks',
  },
];

type DropDownOption = {
  id: string;
  label: string;
};

type SelectedRow = {
  cells: any[];
  disabled: boolean;
  id: string;
  isExpanded: boolean;
  isSelected: boolean;
};

const FormI9StatusesTable = ({
  rows,
  headers,
  getSelectionProps,
  currentSorting,
  selectRow,
  selectAll,
  handleHeaderClick,
  handleSelectedRow,
  formI9Data,
}: any) => {
  const [isSelect, setIsSelect] = useState(false);
  const location = useLocation();

  const handleOnSelect = useCallback(
    (rowId?: string) => () => {
      if (rowId) {
        selectRow(rowId);
      } else {
        selectAll();
      }
      setIsSelect(true);
    },
    [selectRow, selectAll],
  );

  useEffect(() => {
    if (isSelect) {
      setIsSelect(false);
      handleSelectedRow(
        rows.map(({ id, isSelected }: SelectedRow) => ({
          id,
          checked: isSelected,
        })),
      );
    }
  }, [rows, handleSelectedRow, isSelect]);

  const trackEvent = useTrackEvent();

  const currentUser = useUser();

  // Track event on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sourceParam = searchParams.get('source');
    let navigatedSource = 'Other';

    if (sourceParam) {
      const sourceMappings: { [key: string]: string } = {
        tab: 'Left tab',
        report: 'Candidate page order new report',
      };
      navigatedSource = sourceMappings[sourceParam];
      removeParamFromUrl(window, 'source');
    }
    trackEvent(I9_EVENT_NAMES.I9_STATUSES_PAGE_VIEWED, {
      'Page Type': 'Dashboard',
      'I9 Page Type': 'I9 Statuses Page',
      'I9 Page Source': navigatedSource,
    });
  }, [trackEvent, currentUser, location.search]);

  return (
    <M.TableContainer>
      <M.Table>
        <M.TableHead>
          <M.TableRow>
            <M.TableSelectAll
              {...getSelectionProps()}
              onSelect={handleOnSelect()}
            />
            {headers.map((header: any) => {
              const sortBy = headerDefinitions.find(
                h => h.key === header.key,
              )?.sortBy;
              return (
                <SortableTableHeader
                  key={header.key}
                  headerKey={header.key}
                  headerText={header.header}
                  sortable={!!sortBy}
                  sortDirection={
                    sortBy === currentSorting.sortBy
                      ? currentSorting.sortDirection
                      : 'NONE'
                  }
                  onHeaderClick={handleHeaderClick}
                />
              );
            })}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {rows.map((row: any) => (
            <M.TableRow key={row.id} data-testid={row.id}>
              <M.TableSelectRow
                {...getSelectionProps({ row })}
                onSelect={handleOnSelect(row.id)}
              />
              {row.cells.map((cell: any) => {
                const formI9Item = formI9Data?.find(
                  (item: any) => item.id === row.id,
                );

                if (cell.info.header === 'start_date') {
                  return (
                    <M.TableCell
                      key={cell.id}
                      data-testid={`${cell.info.header}_${row.id}`}
                    >
                      {moment(cell.value).format('MM/DD/YYYY')}{' '}
                    </M.TableCell>
                  );
                }
                if (
                  cell.info.header === 'open_tasks_text' &&
                  formI9Item?.open_tasks_link
                ) {
                  return (
                    <M.TableCell
                      key={cell.id}
                      data-testid={`${cell.info.header}_${row.id}`}
                    >
                      <M.Link
                        key={cell.id}
                        href={formI9Item.open_tasks_link}
                        target='_blank'
                        rel='noopener'
                      >
                        {cell.value}
                      </M.Link>
                    </M.TableCell>
                  );
                }
                if (
                  cell.info.header === 'full_name' &&
                  formI9Item?.form_i9_link &&
                  formI9Item?.order_progress !== 'i9_delete'
                ) {
                  return (
                    <M.TableCell
                      key={cell.id}
                      data-testid={`${cell.info.header}_${row.id}`}
                    >
                      <M.Link
                        key={cell.id}
                        href={formI9Item.form_i9_link}
                        target='_blank'
                        rel='noopener'
                      >
                        {cell.value}
                      </M.Link>
                    </M.TableCell>
                  );
                }
                return (
                  <M.TableCell
                    key={cell.id}
                    data-testid={`${cell.info.header}_${row.id}`}
                  >
                    {cell.value}
                  </M.TableCell>
                );
              })}
            </M.TableRow>
          ))}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

type FormI9StatusesProps = {
  settingsData?: AccountSettingsResponse;
};

const FormI9Statuses = ({ settingsData }: FormI9StatusesProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [formsI9IDs, setformsI9IDs] = useState<Set<string>>(new Set());
  const [disablePDFDownload, setDisablePDFDownload] = useState(true);
  const [currentSorting, setCurrentSorting] = useState({
    sortBy: 'start_date',
    sortDirection: 'DESC',
  });

  const orderProgressItems = useMemo(() => {
    const items = [
      { id: 'all', label: 'All' },
      { id: 'i9_complete', label: 'I-9 Complete' },
      { id: 'section_1_incomplete', label: 'Section 1 Incomplete' },
      { id: 'section_2_incomplete', label: 'Section 2 Incomplete' },
      { id: 'awaiting_approval', label: 'Awaiting Approval' },
    ];

    if (settingsData?.everify_status === 'activated') {
      items.push({ id: 'everify_pending', label: 'E-Verify Pending' });
      items.push({
        id: 'everify_tnc_issued',
        label: 'E-Verify TNC Issued - Action Required',
      });
    }

    return items;
  }, [settingsData?.everify_status]);

  const openTasksItems = useMemo(() => {
    const items = [
      { id: 'all', label: 'All' },
      { id: 'complete_section_2', label: 'Complete Section 2' },
      {
        id: 'appoint_section_2_representative',
        label: 'Appoint Section 2 Representative',
      },
      { id: 'accept_reject_i9', label: 'Accept/Reject I-9' },
    ];

    if (settingsData?.everify_status === 'activated') {
      items.push({ id: 'check_user_email', label: 'Check User Email' });
    }

    return items;
  }, [settingsData?.everify_status]);

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const [selectedWorksite, setSelectedWorksite] = useState<DropDownOption>();

  const [selectedOrderProgress, setSelectedOrderProgress] =
    useState<DropDownOption>();

  const [selectedOpenTasks, setSelectedOpenTasks] = useState<DropDownOption>();

  const formI9Results = useGetFormsI9(
    currentSorting.sortBy,
    currentSorting.sortDirection.toLowerCase(),
    currentPageIndex + 1,
    PAGE_SIZE,
    debouncedSearchValue || '',
    selectedWorksite?.id || '',
    selectedOrderProgress?.id || '',
    selectedOpenTasks?.id || '',
  );

  const [formI9ResultsEmpty, setFormI9ResultsEmpty] = useState(true);

  useEffect(() => {
    // Once formI9ResultsEmpty transitions to false, it should never get back to true
    // Example: account has forms I-9 but user typed some search and found no results
    if (formI9ResultsEmpty && formI9Results.resolvedData?.count) {
      setFormI9ResultsEmpty(false);
    }
  }, [formI9ResultsEmpty, formI9Results.resolvedData?.count]);

  const worksiteResults = useGetWorksites('name', 'ASC');

  const handleHeaderClick = useCallback(
    (headerKey, sortDirection) => {
      const sortBy =
        headerDefinitions.find(h => h.key === headerKey)?.sortBy || '';

      setCurrentSorting({ sortBy, sortDirection });
    },
    [setCurrentSorting],
  );

  const pageCount =
    Math.ceil((formI9Results.resolvedData?.count || 0) / PAGE_SIZE) || 1;

  const handlePageClick = useCallback(
    pageIndex => {
      setCurrentPageIndex(pageIndex);
    },
    [setCurrentPageIndex],
  );

  const { FromI9ResultsDownloadedCall } = usePostFormI9CSVFile();

  const handleOrderProgressChange = useCallback((selectedItem: any) => {
    setSelectedOrderProgress(selectedItem.selectedItem);
  }, []);

  const handleWorksitesChange = useCallback((selectedItem: any) => {
    setSelectedWorksite(selectedItem.selectedItem);
  }, []);

  const handleOpenTasksChange = useCallback((selectedItem: any) => {
    setSelectedOpenTasks(selectedItem.selectedItem);
  }, []);

  const debouncedSearch = useDebouncedCallback(
    value => {
      setDebouncedSearchValue(value);
    },
    searchFilterDelayMs,
    [],
  );

  const handleSearchChange = useCallback(
    (event: any) => {
      setSearchValue(event.target.value);
      debouncedSearch(event.target.value);
    },
    [debouncedSearch],
  );

  const handleReset = useCallback(() => {
    setSelectedOrderProgress({
      id: 'all',
      label: 'All',
    });
    setSelectedWorksite({
      id: 'all',
      label: 'All',
    });
    setSelectedOpenTasks({
      id: 'all',
      label: 'All',
    });
    setDebouncedSearchValue('');
    setSearchValue('');
  }, []);
  const handleSelectedRow = useCallback(
    (
      rows: {
        id: string;
        checked: boolean;
      }[],
    ) => {
      setformsI9IDs(prevValue => {
        const result = new Set(prevValue);
        rows.forEach(({ id, checked }) => {
          if (checked) {
            result.add(id);
          } else {
            result.delete(id);
          }
        });
        return result;
      });
    },
    [],
  );

  const { submit: submitPdf, pdfURLs } = useSubmitFormsI9PDF();

  const download = (url: string): Promise<any> => {
    return axios.get(url, { responseType: 'arraybuffer' }).then(({ data }) => {
      downloadPDF({ data });
    });
  };

  useEffect(() => {
    pdfURLs?.forEach(e => {
      download(e);
    });
  }, [pdfURLs]);

  const handlePDFSubmit = useCallback(() => {
    submitPdf(Array.from(formsI9IDs));
  }, [formsI9IDs, submitPdf]);

  const mappedData = React.useMemo(() => {
    return (formI9Results.resolvedData?.data || []).map(
      (formI9: FormI9Response) => ({
        ...formI9,
        isSelected: formsI9IDs.has(formI9.id),
      }),
    );
  }, [formI9Results.resolvedData?.data, formsI9IDs]);

  useEffect(() => {
    const formResult = formI9Results.resolvedData?.data || [];
    const formsIDs = Array.from(formsI9IDs);

    const mapped = new Map(formResult.map(e => [e.id, e.order_progress]));

    const hasCompleteI9 = formsIDs.some(e => mapped.get(e) === 'i9_complete');
    setDisablePDFDownload(!hasCompleteI9);
  }, [formI9Results.resolvedData?.data, formsI9IDs]);

  const handleCSVDownload = useCallback(() => {
    FromI9ResultsDownloadedCall(Array.from(formsI9IDs));
  }, [FromI9ResultsDownloadedCall, formsI9IDs]);

  const navigate = useNavigate();

  const goToOrderI9 = useCallback(() => navigate('/i-9/order'), [navigate]);

  const renderTable = useCallback(
    (renderProps: any) => (
      <FormI9StatusesTable
        {...renderProps}
        currentSorting={currentSorting}
        handleHeaderClick={handleHeaderClick}
        handleSelectedRow={handleSelectedRow}
        selectedIds={formsI9IDs}
        formI9Data={formI9Results.resolvedData?.data}
      />
    ),
    [
      currentSorting,
      handleHeaderClick,
      handleSelectedRow,
      formsI9IDs,
      formI9Results.resolvedData?.data,
    ],
  );

  if (formI9Results.isLoading) {
    return <M.LoadingInline description='Loading...' />;
  }
  if (formI9ResultsEmpty) {
    return (
      <FlexContainer gap='1rem' padding='1rem 1rem 16.25rem 1rem'>
        <EmptyResultsMessage>
          You don&apos;t have any orders in place yet. Select Order I-9 to get
          started.
        </EmptyResultsMessage>
        <M.Button onClick={goToOrderI9} data-id='i9-status-page-order-i9'>
          Order I-9
        </M.Button>
      </FlexContainer>
    );
  }
  return (
    <FlexContainer gap='1rem' padding='1rem'>
      <FlexContainer gap='1rem' padding='0'>
        <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
          <M.Search
            onChange={handleSearchChange}
            placeholder='Search'
            value={searchValue}
          />
          <M.Dropdown
            label='Order progress'
            items={orderProgressItems}
            onChange={handleOrderProgressChange}
            selectedItem={selectedOrderProgress}
          />
          {worksiteResults.isLoading ? (
            <M.LoadingInline description='Loading worksites...' />
          ) : (
            <M.Dropdown
              label='Worksite'
              items={[{ id: 'all', label: 'All' }].concat(
                worksiteResults.data?.data.map(w => ({
                  id: w.id,
                  label: w.name,
                })) || [],
              )}
              onChange={handleWorksitesChange}
              selectedItem={selectedWorksite}
            />
          )}
          <M.Dropdown
            label='Open tasks'
            items={openTasksItems}
            onChange={handleOpenTasksChange}
            selectedItem={selectedOpenTasks}
          />
        </FlexRowJustify>
        <M.Button kind='tertiary' onClick={handleReset}>
          Reset filters
        </M.Button>
      </FlexContainer>
      <FlexRowAlignRight gap='1.5rem' alignItems='center'>
        <ResultsText>
          {formI9Results.resolvedData?.count === 1 ? (
            <>{formI9Results.resolvedData?.count} result</>
          ) : (
            <>{formI9Results.resolvedData?.count || 0} results</>
          )}
        </ResultsText>
        <ActionsMenu
          hideTooltip
          icon={
            <M.Button kind='secondary'>
              <FlexRow gap='0.5rem' alignItems='center'>
                <span>Actions</span>
                <M.Icon icon='ChevronDown' size='1rem' />
              </FlexRow>
            </M.Button>
          }
        >
          <ActionsMenu.MenuItem
            itemText='Download CSV'
            onClick={handleCSVDownload}
          />
          <ActionsMenu.MenuItem
            itemText='Download PDF'
            disabled={disablePDFDownload}
            onClick={handlePDFSubmit}
          />
        </ActionsMenu>
      </FlexRowAlignRight>
      <M.DataTable
        headers={headerDefinitions}
        rows={mappedData}
        render={renderTable}
      />
      <FlexRowAlignRight gap='0' alignItems='center'>
        <M.Pagination
          pageCount={pageCount}
          selectedIndex={currentPageIndex}
          onPageClick={handlePageClick}
        />
      </FlexRowAlignRight>
    </FlexContainer>
  );
};

export default FormI9Statuses;
