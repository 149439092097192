import React from 'react';
import * as Entity from 'modules/assess/models/rules/custom';
import Rule from './rule';

type Rules = Entity.List | Entity.Map;
type Props = {
  rules: Rules;
};

const RuleList: React.FC<Props> = ({ rules }) => {
  const children = Object.values(rules).map(r => <Rule key={r.id} rule={r} />);

  return <div data-testid='assess-rules-custom-rule-list'>{children}</div>;
};

export default RuleList;
