import React, { useEffect, memo } from 'react';
import { setDebugState, removeDebugState, arePropsEqual } from 'utils';

type Obj = { [x: string]: any };
type Props = {
  context: Obj;
  persistState?: boolean;
  props: Obj;
  stateKey?: string;
};

const CustomProvider: React.FC<Props> = memo(function CustomProvider({
  children,
  context,
  persistState = false,
  props,
  stateKey,
}) {
  // optionally store the context as part of the state in both sentry and localStorage
  useEffect(() => {
    stateKey && setDebugState(stateKey, props);
  }, [stateKey, props]);

  // clean up localStorage on component unmount if state should not persist
  useEffect(() => {
    return () => {
      if (!persistState) {
        removeDebugState(stateKey);
      }
    };
  }, [persistState, stateKey]);

  return <context.Provider value={props}>{children}</context.Provider>;
},
arePropsEqual);

export default CustomProvider;
