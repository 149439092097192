import { ChargeSeverity } from 'modules/assess/models';
import { Level } from 'modules/assess/models/rules/lookback';
import { eligibleSeverity } from '../utils';

export function getBulkUpdatedRule(
  initialCharge: any,
  felonyYears?: number,
  misdemeanorYears?: number,
  pettyOffenseYears?: number,
  includeLessThanMisdemeanor?: boolean,
) {
  const charge = {
    ...initialCharge,
    ...eligibleSeverity(ChargeSeverity.felony, felonyYears),
    ...eligibleSeverity(ChargeSeverity.misdemeanor, misdemeanorYears),
    level: Level.CATEGORY_1,
    updated: true,
  };

  if (includeLessThanMisdemeanor) {
    return {
      ...charge,
      ...eligibleSeverity(ChargeSeverity.petty_offense, pettyOffenseYears),
    };
  }

  return charge;
}
