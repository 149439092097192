import { Cohort, CreateCohortModal } from 'components/RandomTestingProgram';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import DotTag from 'components/RandomTestingProgram/Cohort/DotTag';
import Poll from 'components/RandomTestingProgram/Poll';
import {
  useDeleteCohort,
  useEditCohort,
  useGetCohortDetails,
} from 'api/randomDrugTesting';
import RandomSelection from 'components/RandomTestingProgram/RandomSelection/RandomSelection';
import ScreeningOrderForm from 'components/RandomTestingProgram/ScreeningOrderForm';
import ActionableErrorNotification from 'components/RandomTestingProgram/Cohort/ActionableErrorNotification';
import {
  IPollDetail,
  ISelectedCandidate,
} from 'components/RandomTestingProgram/Poll/types';
import UserContext from 'context/CurrentUser';
import { transformInitialValuesForEditRender } from 'components/RandomTestingProgram/CohortModalForm/utils';

const RandomizerCohortPage = () => {
  const [showRandomSelectionModal, setShowRandomSelectionModal] =
    useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<
    ISelectedCandidate[]
  >([]);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const currentUser = useContext(UserContext);
  const accountId = currentUser?.account?.id;

  const params = useParams<any>();
  const { cohortId }: Record<string, string> = params;

  const {
    data: cohort,
    isLoading,
    isError,
    refetch,
  } = useGetCohortDetails(cohortId);

  const packageId = cohort?.locationSearchPackageResourceId as string | null;

  const { mutate: deleteCohort, isLoading: isDeleting } =
    useDeleteCohort(cohortId);

  const history = useHistory();

  const refreshPage = useCallback(() => {
    history.push('/random_testing_program');
  }, [history]);

  const onDelete = useCallback(async () => {
    await deleteCohort();
    refreshPage();
  }, [deleteCohort, refreshPage]);

  const openRandomSelectionModal = useCallback(
    () => setShowRandomSelectionModal(true),
    [],
  );
  const closeRandomSelectionModal = useCallback(
    () => setShowRandomSelectionModal(false),
    [],
  );

  const onOrderReports = useCallback((candidates: ISelectedCandidate[]) => {
    setSelectedCandidates(candidates);
    setShowOrderForm(true);
  }, []);

  const onCancel = useCallback(() => {
    setShowOrderForm(false);
  }, []);

  const openDeleteModal = useCallback(() => setIsDeleteModalOpen(true), []);

  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);

  const closeEditModal = useCallback(() => setIsEditModalOpen(false), []);

  const deleteModalBody = useMemo(() => {
    if (isDeleting)
      return (
        <M.LoadingSpinner
          description={`Deleting ${cohort?.name}`}
          small
          active
        />
      );
    return `Are you sure you want to delete ${cohort?.name}?`;
  }, [cohort?.name, isDeleting]);

  return (
    <StyledGrid>
      {showOrderForm && cohort && packageId ? (
        <ScreeningOrderForm
          cohortId={cohort.id}
          exams={{
            alcoholScreening: cohort.alcoholScreening,
            drugScreening: cohort.drugScreening,
          }}
          packageId={packageId}
          candidates={selectedCandidates}
          onCancel={onCancel}
        />
      ) : (
        <>
          {isError && (
            <StyledContainer type='transparent'>
              <ActionableErrorNotification
                title='There was an error retrieving your pool details.'
                message='Please try again and contact support if the issue persists.'
                refetchCallback={refetch}
              />
            </StyledContainer>
          )}
          {isLoading && (
            <LoadingContainer type='transparent'>
              <StyledHeaderSkeletonLoader type='multi' />
              <StyledSkeletonLoader type='multi' />
              <StyledSkeletonLoader type='multi' />
              <StyledSkeletonLoader type='multi' />
            </LoadingContainer>
          )}

          {cohort && (
            <>
              <M.ComposedModal open={isDeleteModalOpen}>
                <M.ModalHeader buttonOnClick={closeDeleteModal}>
                  Delete Pool
                </M.ModalHeader>
                <M.ModalBody>{deleteModalBody}</M.ModalBody>
                <M.ModalFooter>
                  <>
                    <M.Button kind='secondary' onClick={closeDeleteModal}>
                      Cancel
                    </M.Button>{' '}
                    <M.Button kind='danger' onClick={onDelete}>
                      Confirm
                    </M.Button>
                  </>
                </M.ModalFooter>
              </M.ComposedModal>
              {isEditModalOpen && (
                <CreateCohortModal
                  accountId={accountId}
                  isOpen={isEditModalOpen}
                  onClose={closeEditModal}
                  initialValues={transformInitialValuesForEditRender(cohort)}
                  onSubmitCallback={useEditCohort}
                  refetchCallback={refetch}
                />
              )}
              <Header data-testid='randomizer-cohort-page-header'>
                <Title>
                  <StyledLink to='/random_testing_program'>
                    <M.Icon icon='ArrowLeft' />
                  </StyledLink>
                  {cohort.name}
                  {cohort.dotComplianceAgency && <DotTag />}
                </Title>
                <HeadertnGroup>
                  <StyledDeleteButton
                    kind='tertiary'
                    size='sm'
                    onClick={openDeleteModal}
                  >
                    Delete pool
                  </StyledDeleteButton>
                  <M.Button
                    kind='tertiary'
                    size='sm'
                    onClick={setIsEditModalOpen}
                  >
                    Edit pool
                  </M.Button>
                  <M.Button
                    data-testid='randomizer-cohort-page-header-now-pool-btn'
                    kind='tertiary'
                    size='sm'
                    onClick={openRandomSelectionModal}
                  >
                    New random selection
                  </M.Button>
                </HeadertnGroup>
              </Header>
              <Cohort cohort={cohort} withinPage />
              <Body data-testid='randomizer-cohort-page-body'>
                {cohort.randomTestingPolls?.length ? (
                  cohort.randomTestingPolls.map(
                    (poll: IPollDetail, index: number) => (
                      <Poll
                        key={poll.id}
                        eligibleEmployees={cohort.eligibleEmployeesCount}
                        poll={poll}
                        initialOpenedState={index === 0}
                        onOrderReports={onOrderReports}
                      />
                    ),
                  )
                ) : (
                  <>
                    <LabelBold data-testid='randomizer-cohort-page-no-pools-msg'>
                      You haven&#39;t performed a random selection yet.
                    </LabelBold>
                    <Label>
                      After generating your first random selection, you&#39;ll
                      find its details here.
                    </Label>
                    <M.Button
                      kind='primary'
                      size='sm'
                      onClick={openRandomSelectionModal}
                    >
                      <M.Icon icon='Add' size={16} />
                      New random selection
                    </M.Button>
                  </>
                )}
              </Body>
              {showRandomSelectionModal && (
                <RandomSelection
                  cohortId={cohortId}
                  poolSize={cohort.eligibleEmployeesCount}
                  alcoholScreening={cohort.alcoholScreening}
                  drugScreening={cohort.drugScreening}
                  onClose={closeRandomSelectionModal}
                  isOpen={showRandomSelectionModal}
                />
              )}
            </>
          )}
        </>
      )}
    </StyledGrid>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
`;

const HeadertnGroup = styled.div`
  display: flex;
`;

const Title = styled.h3`
  margin-bottom: 0 !important;
`;

const StyledLink = styled(Link)`
  color: #000000 !important;
  margin-right: 10px;
`;

const LabelBold = styled.p`
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 5px !important;
`;

const Label = styled.p`
  margin-bottom: 2em !important;
`;

const StyledDeleteButton = styled(M.Button)`
  color: ${colors.uiButtonDestructiveLightDefault} !important;
  &:hover {
    color: ${colors.uiButtonDestructiveLightHover} !important;
  },
  &:pressed {
    color: ${colors.uiButtonDestructiveLightPressed} !important;
  },
`;

const StyledGrid = styled(M.Grid)`
  min-height: 80vh;
`;

const StyledContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  margin-top: 20%;
`;

const StyledHeaderSkeletonLoader = styled(M.CodeSnippetSkeleton)`
  height: 125px !important;
`;

const StyledSkeletonLoader = styled(M.CodeSnippetSkeleton)`
  height: 100px !important;
`;

const LoadingContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default RandomizerCohortPage;
