import React from 'react';
import { useTranslation } from 'react-i18next';

const OptionalFCI: React.FC = () => {
  const { t } = useTranslation('adjudication');
  const fciHeader = t(
    'adjudication:report.actions.pre_adverse.optional_fci.optional_fci_header',
  );
  const fciBody = t(
    'adjudication:report.actions.pre_adverse.optional_fci.optional_fci_body',
  );
  const learnMore = t(
    'adjudication:report.actions.pre_adverse.optional_fci.learn_more',
  );
  return (
    <div>
      <div style={{ padding: '30px' }}>
        <strong>{fciHeader}</strong>
        <p style={{ padding: '0' }}>{fciBody}</p>
        <a
          href='https://s3.amazonaws.com/checkr/resources/Fair_Chance_Initiative_Learn_More.pdf'
          target='_blank'
          rel='noreferrer'
        >
          {learnMore}
        </a>
      </div>
    </div>
  );
};

export default OptionalFCI;
