import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useLessThanMisdemeanorPremadeRule,
  useStateWithProp,
} from 'modules/assess/v2/ui/hooks';
import { M } from '@dashboard-experience/mastodon';
import ChargeSeverity from 'modules/assess/models/charge-severity';
import { Type } from 'modules/assess/models/rules/lookback';
import { useState as useQuery } from 'modules/assess/ui/ruleset/version/store';
import { Item } from 'modules/assess/utilities/hierarchy';
import RuleSeverityInput from '../RuleSeverityInput';

type Props = {
  item: Item;
  rule?: Type;
};

const Container = styled(M.GridRow)`
  width: 100%;
`;

type InputType = number | undefined;

const Row: React.FC<Props> = ({ item, rule }) => {
  const state = useQuery();
  const [felony, setFelony] = useStateWithProp<InputType>(
    getRuleEligibleValue(ChargeSeverity.felony, rule),
  );
  const [misdemeanor, setMisdemeanor] = useStateWithProp<InputType>(
    getRuleEligibleValue(ChargeSeverity.misdemeanor, rule),
  );
  const [lessThanMisdemeanor, setLessThanMisdemeanor] =
    useStateWithProp<InputType>(
      getRuleEligibleValue(ChargeSeverity.petty_offense, rule),
    );
  const [misdemeanorError, setMisdemeanorError] = useState(false);
  const [lessThanMisdemeanorError, setLessThanMisdemeanorError] =
    useState(false);

  const includeLessThanMisdemeanor =
    !useLessThanMisdemeanorPremadeRule() &&
    !state.settings?.lookback_rules_all_less_than_misdemeanor_eligible;

  useEffect(() => {
    if (shouldSetChargeSeverityErrorState(misdemeanor, felony)) {
      setMisdemeanorError(misdemeanor! > felony!);
    }
    if (shouldSetChargeSeverityErrorState(lessThanMisdemeanor, misdemeanor)) {
      setLessThanMisdemeanorError(lessThanMisdemeanor! > misdemeanor!);
    }
    if (shouldSetChargeSeverityErrorState(lessThanMisdemeanor, felony)) {
      setLessThanMisdemeanorError(lessThanMisdemeanor! > felony!);
    }
  }, [felony, lessThanMisdemeanor, misdemeanor]);

  return (
    <Container data-testid='assess-v2-ui-common-lookback-rules-subcategory-row'>
      <RuleSeverityInput
        rule={rule}
        severity={ChargeSeverity.felony}
        subcategory={item.name}
        setValue={setFelony}
        initial={felony}
      />
      <RuleSeverityInput
        rule={rule}
        severity={ChargeSeverity.misdemeanor}
        subcategory={item.name}
        error={misdemeanorError}
        setValue={setMisdemeanor}
        initial={misdemeanor}
      />
      {includeLessThanMisdemeanor && (
        <RuleSeverityInput
          rule={rule}
          severity={ChargeSeverity.petty_offense}
          subcategory={item.name}
          error={lessThanMisdemeanorError}
          setValue={setLessThanMisdemeanor}
          initial={lessThanMisdemeanor}
        />
      )}
    </Container>
  );
};

export default Row;

function getRuleEligibleValue(
  chargeType: ChargeSeverity,
  rule?: Type,
): InputType {
  if (!rule) return undefined;
  return rule[chargeType].eligible as number;
}

function shouldSetChargeSeverityErrorState(
  a: InputType,
  b: InputType,
): boolean {
  return typeof a === 'number' && typeof b === 'number';
}
