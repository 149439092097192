import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useLocationWatcher } from 'hooks';
import { useV2 } from 'modules/assess/v2/ui/hooks';
import * as Assess from 'pages/Assess';
import { RootRoute } from './context';
import Criminal from './criminal';
import Home from './home';
import Missing from './missing';
import MVR from './mvr';
import Settings from './settings';
import V2Router from '../../v2/ui/router';

export type Params = {};

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { loading, result: useV2Router } = useV2();
  const { path: base } = match;
  useLocationWatcher();

  if (loading) {
    return <M.LoadingSpinner />;
  }

  if (useV2Router) {
    return <V2Router />;
  }

  return (
    <RootRoute.Provider value={base}>
      <Switch>
        <Route path={`${base}/criminal`}>
          <Assess.Route.Breadcrumb location={base} title='Assess'>
            <Criminal />
          </Assess.Route.Breadcrumb>
        </Route>
        <Route path={`${base}/mvr`}>
          <Assess.Route.Breadcrumb location={base} title='Assess'>
            <MVR />
          </Assess.Route.Breadcrumb>
        </Route>
        <Route path={`${base}/settings`}>
          <Settings />
        </Route>
        <Route path={`${base}/:missing`}>
          <Missing />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </RootRoute.Provider>
  );
};

export default Router;
