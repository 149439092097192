import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Dialog } from 'modules/assess/ui/common';
import { useTranslation } from 'react-i18next';
import Content from './Content';

type Props = {
  open: boolean;
  onClose: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation('assess:v2');
  const modalTitle = t('review_and_apply.dialog.success');

  return (
    <Dialog
      data-testid='assess-v2-ruleset-version-review-and-apply-dialog'
      open={open}
      size='lg'
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-v2-ruleset-version-review-and-apply-dialog-title'
        title={modalTitle}
        closeModal={onClose}
      />
      <M.ModalBody data-testid='assess-v2-ruleset-version-review-and-apply-dialog-content'>
        <Content />
      </M.ModalBody>
    </Dialog>
  );
};

export default Container;
