import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { CellDashboardLink } from './AdverseActionsStyledComponents';
import StatusCell from './StatusCell';
import PostNoticeCell from './PostNoticeCell';
import { AdverseAction } from './types';

type Props = {
  adverseAction: AdverseAction;
  showPaused: boolean;
  showPostAdverseActionNoticeModal: Function;
};

const AdverseActionsTableRow: React.FC<Props> = ({
  adverseAction,
  showPaused,
  showPostAdverseActionNoticeModal,
}) => {
  const defaultCells = (
    <PostNoticeCell
      adverseAction={adverseAction}
      showPostAdverseActionNoticeModal={showPostAdverseActionNoticeModal}
    />
  );

  const daysSincePaused = (): string =>
    moment(adverseAction.pause_data?.time).fromNow(true);

  const pausedCells = (
    <>
      <M.TableCell data-testid='adverse-actions-table-paused-on'>
        <span>
          {moment(adverseAction.pause_data?.time).format('MMM D, YYYY')}
        </span>
      </M.TableCell>
      <M.TableCell data-testid='adverse-actions-table-paused-duration'>
        {/* TODO: URGENT if pause_duration > account.adverse_action_pause_esclated_days */}
        <span>{daysSincePaused()}</span>
      </M.TableCell>
      <M.TableCell data-testid='adverse-actions-table-paused-by'>
        <span>{adverseAction.pause_data?.user}</span>
      </M.TableCell>
    </>
  );

  return (
    <M.TableRow data-testid='adverse-actions-table-row'>
      <StatusCell adverseAction={adverseAction} />
      <M.TableCell data-testid='adverse-actions-table-candidate'>
        <CellDashboardLink path={`/reports/${adverseAction.report_id}`}>
          <span>{adverseAction.candidate_name}</span>
        </CellDashboardLink>
      </M.TableCell>
      <M.TableCell data-testid='adverse-actions-table-created-at'>
        <CellDashboardLink path={`/reports/${adverseAction.report_id}`}>
          <span>{moment(adverseAction.created_at).format('MMM D, YYYY')}</span>
        </CellDashboardLink>
      </M.TableCell>
      {showPaused ? pausedCells : defaultCells}
      <M.TableCell>
        {' '}
        {adverseAction.individualized_assessment_engaged && (
          <M.TooltipDefinition
            openOnHover={false}
            highlighted={false}
            align='top'
            definition='This adverse action is engaged in individualized assessment'
          >
            <M.Icon icon='Report' />
          </M.TooltipDefinition>
        )}
      </M.TableCell>
      <M.TableCell style={{ padding: '16px' }}>
        <CellDashboardLink path={`/reports/${adverseAction.report_id}`}>
          <M.Icon icon='ChevronRight' />
        </CellDashboardLink>
      </M.TableCell>
    </M.TableRow>
  );
};

export default AdverseActionsTableRow;
