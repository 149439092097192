/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Svg } from '../styles';

const ICON_SIZE = '20';

export const IconBase = ({ d }: { d: string }) => {
  return (
    <Svg
      width={ICON_SIZE}
      height={ICON_SIZE}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d={d} fillOpacity='0.78' />
    </Svg>
  );
};
