/* eslint-disable no-shadow */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import camelize from 'camelize-ts';

import { useFlag } from '@dashboard-experience/react-flagr';
import { CurrentUser, hasPermission } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';

import * as API from 'api';
import { GenericObject } from 'types';
import {
  CANDIDATE_SMS_SETTING_MOVED,
  DEFAULT_NOTIFICATION_STATE,
  SHOW_COMMUNICATIONS_SETTINGS_KEY,
} from 'Constants';
import UserContext from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import SettingsSchema from '../AccountSettings/Schema';
import { useUpdateAccountSettings } from '../AccountSettings';
import { StateType } from './Types';
import NotificationCheckbox from './NotificationCheckbox';
import {
  StyledForm,
  ListItem,
  StyledSecondaryMessage,
  StyledCheckboxWrapper,
  StyledCheckboxBox,
  Divider,
} from './PreferenceStyledComponents';
import { updateNotificationSettings } from '../../actions';

/**
 * @name getInitialState
 * @function
 * @memberOf Preferences
 * @description Updates default notification state
 * @param  {object} defaultState - The default notification state
 * @param {object} account - The user account
 * @param {object} settings - Updated settings
 * @param {object} user - current user
 * @return {object} initialState object
 */
const getInitialState = (
  defaultState: StateType,
  account: GenericObject,
  settings: GenericObject,
  user: GenericObject,
) => {
  const initialState = { ...defaultState };
  const { groupConfig } = initialState;
  const { adverse_action_pause, translate_canceled_status_to_suspended } =
    account;

  if (
    !adverse_action_pause ||
    !user?.permissions?.adverse_action_user_notifications
  ) {
    delete groupConfig.adverse_actions_paused;
  }

  if (translate_canceled_status_to_suspended) {
    const { items } = groupConfig.reports;

    delete items.notify_on_report_canceled;
  }

  groupConfig.reports.items.notify_on_report_disputed.disabled =
    settings.can_disable_notify_on_report_disputed;

  // updates default settings with user settings
  initialState.settings = { ...settings };

  Object.values(groupConfig).forEach(group => {
    // set the group toggle on value based on whether all values in respective
    // group's items evaluate to true based on settings
    initialState.groupToggleOn[group.name] = Object.keys(group.items).every(
      item => settings[item],
    );
    // sets indeterminate boolean value based on whether any/some of values
    // in items evaluate to true in settings
    initialState.groupToggleIndeterminate[group.name] = Object.keys(
      group.items,
    ).some(item => settings[item]);
    // if both groupToggleOn and groupToggleIndeterminate are true
    // then toggleIndeterminate to false
    if (
      initialState.groupToggleIndeterminate[group.name] &&
      initialState.groupToggleOn[group.name]
    ) {
      initialState.groupToggleIndeterminate[group.name] = false;
    }
  });

  return initialState;
};

const Preferences: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user: CurrentUser = useContext(UserContext);
  const { account, settings }: GenericObject = user;
  const { call } = API.preferences.useUpdatePreferences();
  const [currentState, updateCurrentState] = useState(
    getInitialState(DEFAULT_NOTIFICATION_STATE, account, settings, user),
  );
  const [settingsState, setSettingsState] = useState({
    suppressEmailInvites: false,
    suppressSmsNotifications: false,
  });
  const { call: callUpdateSettings } = useUpdateAccountSettings();

  const { suppressEmailInvites, suppressSmsNotifications } = settingsState;

  const newCommunicationSettingsEnabled =
    useFlag(SHOW_COMMUNICATIONS_SETTINGS_KEY)?.variantKey === 'on';
  const smsSettingMoved =
    useFlag(CANDIDATE_SMS_SETTING_MOVED)?.variantKey === 'on';

  /**
   * @name onSavePreference
   * @function
   * @memberOf Preferences
   * @description Save the notification setting to the API
   * @param  {object} newPreferences - The object representing the preference setting
   * @param {string} groupName - The name of the group the preference belongs to
   */
  const onSavePreference = useCallback(
    newPreferences => {
      const { settings } = currentState;
      let updatedPreferences = { ...settings, ...newPreferences };
      if (newPreferences.notify_on_report_disputed) {
        updatedPreferences = {
          ...updatedPreferences,
          notify_report_assessment_changes: true,
        };
      }

      // api call to update preferences
      call({ updatedPreferences, user });

      const updatedState = {
        ...currentState,
        settings: updatedPreferences,
      };

      dispatch(updateNotificationSettings(updatedPreferences));
      updateCurrentState(updatedState);
      updateGroupToggleStates(updatedState);
    },
    [currentState, call, user],
  );

  /**
   * @name updateGroupToggleStates
   * @function
   * @memberOf Preferences
   * @description Updates the group toggle checkboxes each time a child
   *              checkbox is changed.
   * @param {object} updatedState The updated state object
   */
  const updateGroupToggleStates = (updatedState: StateType) => {
    const { groupConfig, settings } = updatedState;
    const groupToggleOn: GenericObject = {};
    const groupToggleIndeterminate: GenericObject = {};

    Object.values(groupConfig).forEach(group => {
      groupToggleOn[group.name] = Object.keys(group.items).every(
        item => settings[item],
      );
      groupToggleIndeterminate[group.name] = Object.keys(group.items).some(
        item => settings[item],
      );
      if (groupToggleIndeterminate[group.name] && groupToggleOn[group.name]) {
        groupToggleIndeterminate[group.name] = false;
      }
    });

    updateCurrentState({
      ...updatedState,
      groupToggleOn,
      groupToggleIndeterminate,
    });
  };

  /**
   * @name getGroupItems
   * @function
   * @memberOf Preferences
   * @description Helper method to return the array of a group's items
   * @returns {array}
   * @param {string} groupName - The group name
   */
  const getGroupItems = useCallback(
    (groupName: string) => {
      const { groupConfig }: GenericObject = currentState;
      return Object.values(groupConfig[groupName].items);
    },
    [currentState],
  );

  /**
   * @name onGroupToggle
   * @function
   * @memberOf Preferences
   * @description Toggles all checkboxes in a section
   * @param {object} toggle - The object with { name: value } being returned by the checkbox component
   */
  const onGroupToggle = useCallback(
    (toggle: GenericObject) => {
      const groupName: string = Object.keys(toggle)[0];
      const value: boolean = toggle[groupName];
      const groupItems: Array<any> = getGroupItems(groupName);
      const updatedPreferences: GenericObject = {};
      groupItems.forEach(item => {
        // update checkbox status when item is not disabled
        !item.disabled && (updatedPreferences[item.name] = value);
      });

      onSavePreference(updatedPreferences);
    },
    [getGroupItems, onSavePreference],
  );

  const isAccountExists = (account: GenericObject) => {
    return Object.keys(account).length > 0;
  };

  const getSettings = useCallback((account: GenericObject) => {
    const settings = SettingsSchema() as GenericObject;

    if (isAccountExists(account)) {
      Object.keys(settings).forEach(s => {
        settings[s].value = account[s];
      });
    }

    return settings;
  }, []);

  useEffect(() => {
    setSettingsState(settings => {
      const newSettings: any = { ...settings };

      if (isAccountExists(account)) {
        const settings = getSettings(account);

        Object.keys(settings).forEach(s => {
          newSettings[camelize(s)] = account[s];
        });

        if (account.suppress_sms_notification) {
          newSettings.suppressSmsNotifications = true;
        }

        if (account.suppress_email_invitation) {
          newSettings.suppressEmailInvites = true;
        }
      }

      newSettings.disableSettings = !hasPermission(
        user,
        'manage_account_settings',
      );
      return newSettings;
    });
  }, [account, getSettings, user]);

  const updateSettingsState =
    (setSettingsState: React.Dispatch<React.SetStateAction<any>>) =>
    (key: string, newValue: any) => {
      setSettingsState((prevState: GenericObject) => ({
        ...prevState,
        [key]: newValue,
      }));
    };

  const setSettings = useCallback(
    (key: string, value: string | boolean | string[]) => {
      updateSettingsState(setSettingsState)(key, value);
    },
    [],
  );

  const onChange = useCallback(
    (key: string) => (event: any) => setSettings(key, event.target.value),
    [setSettings],
  );

  const toggleEmailSetting = useCallback(() => {
    const updatedState = {
      ...settingsState,
      suppressEmailInvites: !suppressEmailInvites,
    };

    callUpdateSettings({ changedSettings: updatedState, account });

    onChange('suppressEmailInvites')({
      target: { value: !suppressEmailInvites },
    });
  }, [
    account,
    callUpdateSettings,
    onChange,
    settingsState,
    suppressEmailInvites,
  ]);

  const toggleSmsSetting = useCallback(() => {
    const updatedState = {
      ...settingsState,
      suppressSmsNotifications: !suppressSmsNotifications,
    };

    callUpdateSettings({ changedSettings: updatedState, account });

    onChange('suppressSmsNotifications')({
      target: { value: !suppressSmsNotifications },
    });
  }, [
    account,
    callUpdateSettings,
    onChange,
    settingsState,
    suppressSmsNotifications,
  ]);

  return (
    <StyledForm id='checkr-preferences' data-testid='checkr-preferences'>
      {newCommunicationSettingsEnabled && !smsSettingMoved && (
        <>
          <h2>
            {t(
              'accountSettings.communicationPreferences.candidateCommunicationSettings.heading',
            )}
          </h2>
          <StyledSecondaryMessage>
            {t(
              'accountSettings.communicationPreferences.candidateCommunicationSettings.message_one',
            )}
            <strong>
              {t(
                'accountSettings.communicationPreferences.candidateCommunicationSettings.message_two',
              )}
            </strong>
          </StyledSecondaryMessage>
          <StyledCheckboxBox>
            <M.Checkbox
              style={{ marginBottom: '0.75rem' }}
              id='suppressEmailInvites'
              onChange={toggleEmailSetting}
              checked={!suppressEmailInvites}
              data-testid='suppressEmailInvites'
              labelText={t(
                'accountSettings.communicationPreferences.candidateCommunicationSettings.suppressEmailInvites.label',
              )}
            />

            <M.Checkbox
              id='suppressSmsNotifications'
              onChange={toggleSmsSetting}
              checked={!suppressSmsNotifications}
              data-testid='suppressSmsNotifications'
              labelText={
                <>
                  <strong>
                    {t(
                      'accountSettings.communicationPreferences.candidateCommunicationSettings.suppressSmsNotifications.label_part_one',
                    )}
                  </strong>
                  {t(
                    'accountSettings.communicationPreferences.candidateCommunicationSettings.suppressSmsNotifications.label_part_two',
                  )}
                </>
              }
            />
          </StyledCheckboxBox>

          <Divider />
        </>
      )}

      <h3>
        {t(
          'accountSettings.communicationPreferences.activityCommunicationSettings.heading',
        )}
      </h3>
      <StyledSecondaryMessage>
        {t(
          'accountSettings.communicationPreferences.activityCommunicationSettings.message',
        )}
      </StyledSecondaryMessage>
      <StyledCheckboxWrapper data-testid='customer-notifications'>
        {Object.values(currentState.groupConfig).map(
          (
            group: {
              name: string;
              label: keyof JSX.IntrinsicElements;
              items: GenericObject;
            },
            groupIdx: number,
          ) => (
            <span key={`${group.name}_${groupIdx.toString()}`}>
              <li>
                <NotificationCheckbox
                  name={group.name}
                  value={currentState.groupToggleOn[group.name]}
                  indeterminate={
                    currentState.groupToggleIndeterminate[group.name]
                  }
                  label={group.label}
                  onSave={onGroupToggle}
                />
                <ul>
                  {Object.values(group.items).map((item, itemIdx) => (
                    <ListItem key={`${item.name}_${itemIdx.toString()}`}>
                      <NotificationCheckbox
                        name={item.name}
                        value={currentState.settings[item.name]}
                        label={item.label}
                        onSave={onSavePreference}
                        disabled={item.disabled}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            </span>
          ),
        )}
      </StyledCheckboxWrapper>
    </StyledForm>
  );
};

export default Preferences;
