import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const StyledToast = styled(M.ToastNotification)`
  border-radius: 8px;
  line-height: 150%;
  position: fixed;
  right: 5em;
  top: 2em;
`;

type Props = {
  setShow: (value: boolean) => void;
  show?: boolean;
  timeout?: number;
};

const SavedNotification: React.FC<Props> = ({
  show,
  setShow,
  timeout = 10000,
}) => {
  const { t } = useTranslation('assess:v2');
  const title = t('assess:settings.notifications.success.title');
  const subtitle = t('assess:settings.notifications.success.message');
  const onClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  if (!show) return null;

  return (
    <StyledToast
      id='assess-v2-ui-home-settings-saved-notification'
      data-testid='assess-v2-settings-toast'
      kind='success'
      title={title}
      subtitle={subtitle}
      timeout={timeout}
      onClose={onClose}
    />
  );
};

export default SavedNotification;
