import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Header = styled.h4`
  padding-top: 1rem;
  font-weight: bold;
`;

export const Title: React.FC = () => {
  const { t } = useTranslation();
  const text = t('assess:ruleset.version.publish.changes.lookback.title');

  return <Header>{text}</Header>;
};

export default Title;
