import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { filterPackages } from 'utils';
import { useUpdatedState } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import Context from 'components/Packages/context';
import {
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import {
  buildBasicPackage,
  buildEssentialPackageV2,
  buildProfessionalPackageV2,
  buildCompleteCriminalPackageV2,
} from 'components/Packages/CreatePackage/Context';
import { RECOMMENDED_PACKAGES_FLAG } from 'Constants';
import { QueryResult } from 'react-query';
import WideChoiceCards from './WideChoiceCards';
import { useUser } from '../../../../context/CurrentUser';
import { useList } from '../../../../api/packages';
import { Package } from '../../../../types';
import RecommendedPackages from './RecommendedPackages';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  MULTIPLE_PEOPLE,
} from '../Context';
import {
  DEFAULT_PACKAGE_NAMES,
  DEFAULT_PACKAGE_NAMES_WITH_COMPLETE,
} from '../SharedItems';

const Container = styled.div`
  width: 744px;

  @media all and (max-width: 940px) {
    width: 100%;
  }
`;

const Header = styled.p`
  width: 744px;
  color: ${colors.brandNavy4};
  margin-bottom: 1rem !important;

  @media all and (max-width: 940px) {
    width: 100%;
  }
`;

const SearchContainer = styled(M.Search)`
  width: 300px;
  margin-bottom: 16px !important;
  .cds--search-input {
    color: ${colors.brandSlate6} !important;
    border-bottom: 1px solid ${colors.brandSlate7} !important;
  }
  .cds--search-input::placeholder {
    color: ${colors.brandSlate6};
  }
  .cds--search-magnifier-icon {
    fill: ${colors.brandSlate6};
  }
`;

const PaginationContainer = styled(M.Pagination)`
  display: flex;
  justify-content: center;
`;

const BoldTitle = styled.h4`
  margin-bottom: 1.5rem !important;
`;

const SecondBoldTitle = styled.div`
  color: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-top: 16px;
`;

const parsePackagesResponse = (
  response: QueryResult<{ [key: string]: any }>,
) => {
  const packages = response?.data as Package[];
  const packagesLoading = response?.isLoading as boolean;
  return {
    packages,
    packagesLoading,
  };
};

type SelectPackageProps = {
  setOnReviewPage?: (onReviewPage: boolean) => void;
};

export const availableForManualBulk = new Set([
  'county_criminal_search',
  'state_criminal_search',
  'federal_criminal_search',
  'county_civil_search',
  'federal_civil_search',
  'facis_search',
  'global_watchlist_search',
  'ssn_trace',
  'sex_offender_search',
  'national_criminal_search',
]);

const SelectPackage: React.FC<SelectPackageProps> = ({
  setOnReviewPage = () => {},
}) => {
  const { state, dispatch, addDataDogError } = useOrderBackgroundCheckContext();
  const recommendedPackagesFlagEnabled =
    useFlag(RECOMMENDED_PACKAGES_FLAG)?.variantKey === 'on';

  const showRecommendedPackages =
    recommendedPackagesFlagEnabled && state.selectedGeo === 'domestic';

  const currentUser = useUser();
  const {
    account: {
      id: accountId,
      show_package_price: showPackagePrice,
      package_price_state: packagePriceState,
    },
  } = currentUser;

  const trackEvent = useTrackEvent();
  const [searchParam, setSearchParam] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [defaultPackages, setDefaultPackages] = useState<any[]>([]);

  const showPrice: boolean = useMemo(() => {
    return showPackagePrice && packagePriceState !== 'disabled via partner';
  }, [packagePriceState, showPackagePrice]);
  const packagesPerPage = 10;
  const isManualBulkOrder = state.manualBulkUploadType === MULTIPLE_PEOPLE;

  const {
    account: { hierarchy_present, segmentation_enabled },
    nodePackages,
    selectedNode,
    onNodeChange,
  } = useContext(Context);

  const resp = useList({ accountId });

  const { packages, packagesLoading } = parsePackagesResponse(resp);

  const [node] = useUpdatedState(selectedNode, onNodeChange);

  const isAvailableForManualBulk = useCallback(
    (thisPackage: Package): boolean => {
      const screenings = thisPackage?.screenings || [];

      return (
        !isManualBulkOrder ||
        screenings.every(screening =>
          availableForManualBulk.has(screening.type),
        )
      );
    },
    [isManualBulkOrder],
  );

  // TODO: Read from serialized account key coming from backend when available [EGRO-772] this account can be used to test in Sandbox and MR environment currently
  const showCompleteCrim = accountId === 'e54a687470117260d3e2e0f5';

  const selectablePackages = useMemo(() => {
    // handle selectable packages for a segmentation account
    if (segmentation_enabled) {
      // with hierarchy present, use the nodePackages for the node to filter packages
      if (hierarchy_present && node) {
        return packages?.filter(pkg => {
          return nodePackages?.includes(pkg.slug);
        });
      }
      if (!hierarchy_present) {
        return packages;
      }
    }
    return packages;
  }, [packages, hierarchy_present, node, nodePackages, segmentation_enabled]);

  if (resp.isError) {
    const errorMessage = resp?.error?.message;
    trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.ERROR_VIEWED, {
      'Page Name': '2. Select a package',
      'Error Name': errorMessage,
    });
    addDataDogError(errorMessage, {}, undefined);
  }

  const filteredPackages = useMemo(() => {
    let filtered =
      state.selectedGeo === 'domestic'
        ? filterPackages(selectablePackages).domestic
        : filterPackages(selectablePackages).international;

    // Filter by program if a program is selected
    filtered = filtered.filter(pckg => {
      if (state.program?.id) return pckg.program_id === state.program?.id;
      return pckg;
    });

    setCount(filtered.length);
    return filtered;
  }, [selectablePackages, state.selectedGeo, state.program?.id]);

  useEffect(() => {
    const basicPackage = () => {
      const basicPkg = buildBasicPackage();
      return { ...basicPkg, is_default_package: true };
    };

    const essentialPackage = () => {
      const essentialPkg = buildEssentialPackageV2();
      return { ...essentialPkg, is_default_package: true };
    };

    const professionalPackage = () => {
      const professionalPkg = buildProfessionalPackageV2();
      return {
        ...professionalPkg,
        is_default_package: true,
      };
    };

    const completeCriminalPackage = () => {
      const completeCriminalPkg = buildCompleteCriminalPackageV2();
      return {
        ...completeCriminalPkg,
        is_default_package: showCompleteCrim,
      };
    };

    const actualDefaultPackages = showCompleteCrim
      ? [basicPackage(), essentialPackage(), completeCriminalPackage()]
      : [basicPackage(), essentialPackage(), professionalPackage()];

    return setDefaultPackages(actualDefaultPackages);
  }, [showCompleteCrim]);

  const recommendedPackages = useMemo(() => {
    const preferPackages = filteredPackages?.filter(
      (currentPackage: Package) =>
        currentPackage.origin?.includes('customer_via_dashboard') ||
        currentPackage.origin?.includes('customer_via_signup'),
    );

    // Get all names from packages array
    const packageNames = preferPackages.map(pkg => pkg.name);

    // Filter default packages where name doesn't exist in both
    const filteredDefault = defaultPackages.filter(defPkg => {
      const { name } = defPkg;

      return !(
        packageNames.includes(name) &&
        (!showCompleteCrim
          ? DEFAULT_PACKAGE_NAMES.includes(name)
          : DEFAULT_PACKAGE_NAMES_WITH_COMPLETE.includes(name))
      );
    });

    return [...preferPackages, ...filteredDefault]
      .sort((a, b) => {
        if (a.price !== b.price) {
          return a.price - b.price;
        }
        if (a.origin || b.origin) {
          // if one of the packages is a default package, it should be preferred
          if (a.origin) {
            return -1;
          }
          return 1;
        }

        return 0;
      })
      .slice(-3);
  }, [defaultPackages, filteredPackages, showCompleteCrim]);

  const nonPreferredPackages = useMemo(() => {
    const lookup = new Set(
      recommendedPackages.map((obj: { [x: string]: any }) => obj.slug),
    );
    return filteredPackages.filter(
      (obj: { [x: string]: any }) => !lookup.has(obj.slug),
    );
  }, [filteredPackages, recommendedPackages]);

  const sortedPackages: any = useMemo(() => {
    const packageList = showRecommendedPackages
      ? nonPreferredPackages
      : filteredPackages;
    if (state.manualBulkUploadType === MULTIPLE_PEOPLE) {
      return [...packageList].sort((a, b) => {
        const isAvailableA = isAvailableForManualBulk(a);
        const isAvailableB = isAvailableForManualBulk(b);

        // Sort to show available for manual bulk order packages first
        if (isAvailableA && !isAvailableB) {
          return -1;
        }
        if (!isAvailableA && isAvailableB) {
          return 1;
        }
        return 0;
      });
    }
    return packageList;
  }, [
    filteredPackages,
    isAvailableForManualBulk,
    nonPreferredPackages,
    showRecommendedPackages,
    state.manualBulkUploadType,
  ]);

  const searchedThruPackages: any = useMemo(() => {
    const searchedPackages = sortedPackages?.filter((currentPackage: Package) =>
      currentPackage.name
        ?.toLocaleLowerCase()
        .includes(searchParam.toLocaleLowerCase()),
    );
    setCount(searchedPackages.length);

    return searchedPackages;
  }, [sortedPackages, searchParam]);

  const currentPackages = useMemo(
    () =>
      searchedThruPackages.slice(
        currentPageIndex * packagesPerPage,
        (currentPageIndex + 1) * packagesPerPage,
      ) || [],
    [currentPageIndex, packagesPerPage, searchedThruPackages],
  );

  const handleSearchChange = useCallback(
    (e: any) => {
      if (!state.selectPackageAmplitudeData.searchUsed) {
        dispatch({
          type: actionTypes.SET_SELECT_PACKAGE_AMPLITUDE_DATA,
          payload: {
            selectPackageAmplitudeData: {
              ...state.selectPackageAmplitudeData,
              searchUsed: true,
            },
          },
        });
      }
      setSearchParam(e.target.value);
      setCurrentPageIndex(0);
    },
    [dispatch, state.selectPackageAmplitudeData],
  );

  const handlePageClick = useCallback(
    (pageIndex: number) => {
      setCurrentPageIndex(pageIndex);
      dispatch({
        type: actionTypes.SET_SELECT_PACKAGE_AMPLITUDE_DATA,
        payload: {
          selectPackageAmplitudeData: {
            ...state.selectPackageAmplitudeData,
            numberOfPagesViewed:
              state.selectPackageAmplitudeData.numberOfPagesViewed + 1,
          },
        },
      });
    },
    [dispatch, state.selectPackageAmplitudeData],
  );

  const isBasePackageInCurrentPackages = useMemo(() => {
    if (Object.keys(state.basePackage).length > 0) {
      return (
        currentPackages.filter(
          (_package: Package) => _package.name === state.basePackage.name,
        ).length > 0 ||
        recommendedPackages.filter(
          (_package: Package) => _package.name === state.basePackage.name,
        ).length > 0
      );
    }

    return false;
  }, [currentPackages, recommendedPackages, state.basePackage]);

  useEffect(() => {
    dispatch({
      type: actionTypes.CONTINUE_BUTTON_DISABLED,
      payload: { continueButtonDisabled: !isBasePackageInCurrentPackages },
    });
  }, [dispatch, isBasePackageInCurrentPackages, state.basePackage]);

  useEffect(() => {
    setOnReviewPage(false);
  }, [setOnReviewPage]);

  // TODO: If/When Recommended Packages are enabled, tests that look for the header will need to be updated
  const showHeader = useMemo(() => {
    return isManualBulkOrder || !showRecommendedPackages;
  }, [isManualBulkOrder, showRecommendedPackages]);

  return (
    <Container>
      {showRecommendedPackages && <BoldTitle>Your top packages</BoldTitle>}
      {showHeader && (
        <Header className='p2' data-testid='select-your-package-header'>
          {isManualBulkOrder
            ? `Select a package for your candidates. Additional searches (add-ons) are unavailable for bulk manual orders.`
            : `Select the package or search that most closely meets your needs.`}
        </Header>
      )}
      {showRecommendedPackages && (
        <RecommendedPackages
          recommendedPackages={recommendedPackages}
          showPackagePrice={showPrice}
          isAvailableForManualBulk={isAvailableForManualBulk}
        />
      )}
      {showRecommendedPackages && (
        <SecondBoldTitle>Other available packages</SecondBoldTitle>
      )}
      <SearchContainer
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
        data-testid='package-search'
        placeholder='Enter a package name'
      />
      {packagesLoading ? (
        <M.LoadingInline description='Loading packages...' />
      ) : (
        <WideChoiceCards
          packages={currentPackages}
          showPackagePrice={showPrice}
          segmentation_enabled={segmentation_enabled}
          hierarchy_present={hierarchy_present}
          node={node}
          isManualBulk={isAvailableForManualBulk}
          recommendedPackages={recommendedPackages}
        />
      )}
      {count > 10 && currentPackages.length > 0 && (
        <PaginationContainer
          data-testid='packages-pagination'
          pageCount={Math.ceil(count / packagesPerPage)}
          selectedIndex={currentPageIndex}
          onPageClick={handlePageClick}
        />
      )}
    </Container>
  );
};

export default SelectPackage;
