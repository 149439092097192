import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { M, colors } from '@dashboard-experience/mastodon';
import { AssessStatuses } from '@dashboard-experience/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearSearchParams, updateSearchParams } from 'actions/SearchActions';
import styled from 'styled-components';
import { useReportMetrics } from 'api/accounts';
import { useUser } from 'context/CurrentUser';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import { Section, StatusCard } from '../shared';
import { namespace } from '../../locales';

const ControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
  vertical-align: middle;
  margin-top: -0.25rem;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const ErrorContainer = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.uiGrey50};
  color: ${colors.uiTextTertiaryLight};
`;

const Dropdown = styled(M.Dropdown)`
  text-align: left;
`;

const statusParamsMap: {
  [key: string]: {
    param: string;
    filter: {
      status?: string;
      assessment?: string;
      invitation_status?: string;
    };
  };
} = {
  invited: {
    param: 'invitation_status=pending',
    filter: { invitation_status: 'pending' },
  },
  pending: {
    param: '',
    filter: {},
  },
  clear: {
    param: `assessment=${AssessStatuses.CLEAR}`,
    filter: { assessment: AssessStatuses.CLEAR },
  },
  review: {
    param: `assessment=${AssessStatuses.REVIEW}`,
    filter: { assessment: AssessStatuses.REVIEW },
  },
  escalated: {
    param: `assessment=${AssessStatuses.ESCALATED}`,
    filter: { assessment: AssessStatuses.ESCALATED },
  },
  disputed: {
    param: 'status=dispute',
    filter: { status: 'dispute' },
  },
};

const items = [
  {
    id: 7,
    label: 'Last 7 days',
  },
  {
    id: 14,
    label: 'Last 14 days',
  },
  {
    id: 30,
    label: 'Last 30 days',
  },
  {
    id: -1,
    label: 'All time',
  },
];

const OverviewMetrics = () => {
  const user = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const {
    call,
    result: { data, error, isLoading },
  } = useReportMetrics();
  const basePath = `${namespace}:metricsOverviewSection`;
  const showEscalatedMetrics = user?.account?.assess?.tier === 'premium';

  const clickHandler = useCallback(() => {
    dispatch(clearSearchParams());
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.REPORT_METRICS_CLICKED, {
        'Home Report Metrics Click': 'View all',
      });
    }

    history.push('/candidates');
  }, [dispatch, history, isSignup, trackEvent]);

  const handleRedirect = useCallback(
    (metricStatus: string) => () => {
      dispatch(clearSearchParams());
      dispatch(
        updateSearchParams({
          order_by: 'created_at',
          page: 1,
          per_page: 25,
          sort: 'desc',
          ...statusParamsMap[metricStatus].filter,
        }),
      );

      if (!isSignup) {
        trackEvent(DASHBOARD_LANDING_EVENT_NAMES.REPORT_METRICS_CLICKED, {
          'Home Report Metrics Click': metricStatus,
        });
      }

      history.push(
        `/candidates?&order_by=created_at&page=1&per_page=25&sort=desc&${statusParamsMap[metricStatus].param}`,
      );
    },
    [dispatch, history, isSignup, trackEvent],
  );

  const [selectedDateRange, setSelectedDateRange] = useState(items[0]);
  const handleDateRangeChange = useCallback(option => {
    setSelectedDateRange(option.selectedItem);

    if (!isSignup) {
      trackEvent(
        DASHBOARD_LANDING_EVENT_NAMES.REPORT_METRICS_DATE_FILTER_CLICKED,
        {
          'Date Filter': option.selectedItem.label,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (isSignup) return;

    call({
      accountId: user?.account?.id,
      lookBackDays: selectedDateRange.id,
    });
  }, [call, selectedDateRange, user?.account?.id, isSignup]);

  return (
    <Section
      header={t(`${basePath}.header`)}
      topRightComponent={
        <ControlsContainer>
          <Dropdown
            data-testid='overview-metrics-date-range'
            id='overview-metrics-date-range'
            items={items}
            selectedItem={selectedDateRange}
            onChange={handleDateRangeChange}
            size='sm'
          />
          <M.Button kind='tertiary' onClick={clickHandler}>
            {t(`${basePath}.viewAll`)}
          </M.Button>
        </ControlsContainer>
      }
    >
      <MetricsContainer>
        {error ? (
          <ErrorContainer data-testid='overview-metrics-error'>
            Unable to load report metrics
          </ErrorContainer>
        ) : (
          <>
            <StatusCard
              status='invited'
              value={data?.invited}
              isLoading={isLoading}
              handleRedirect={handleRedirect('invited')}
            />
            <StatusCard
              status='pending'
              value={data?.pending_reports}
              isLoading={isLoading}
              handleRedirect={handleRedirect('pending')}
            />
            <StatusCard
              status='clear'
              value={data?.clear}
              isLoading={isLoading}
              handleRedirect={handleRedirect('clear')}
            />
            <StatusCard
              status='review'
              value={data?.review}
              isLoading={isLoading}
              handleRedirect={handleRedirect('review')}
            />
            {showEscalatedMetrics && (
              <StatusCard
                status='escalated'
                value={data?.escalated}
                isLoading={isLoading}
                handleRedirect={handleRedirect('escalated')}
              />
            )}
            <StatusCard
              status='disputed'
              value={data?.disputed}
              isLoading={isLoading}
              handleRedirect={handleRedirect('disputed')}
            />
          </>
        )}
      </MetricsContainer>
    </Section>
  );
};

export default OverviewMetrics;
