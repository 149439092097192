import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { ViewerList } from 'api/realtime/types';
import UIContext from 'context/UI';

const avatarSize = 28;

const Container = styled.div<{ isiframe: string; hasMargin: boolean }>`
  display: flex;
  min-height: ${avatarSize}px;

  margin-right: ${props => (props.hasMargin ? '1rem' : '0')};
  margin-bottom: ${props => (props.hasMargin ? '1rem' : '0')};

  @media (min-width: ${props => props.isiframe}) {
    margin-bottom: 0;
  }
`;

// TODO: remove margins once candidate page redesign is completed
type Props = {
  viewers: ViewerList;
  hasMargin?: boolean;
};

const ViewerAvatars: React.FC<Props> = ({ viewers, hasMargin = true }) => {
  const { isIframe } = useContext(UIContext);

  return (
    <Container isiframe={isIframe ? '720px' : '1200px'} hasMargin={hasMargin}>
      <M.ViewerAvatars
        viewers={viewers.map(viewer => viewer.email)}
        avatarSize={avatarSize}
        maxIcons={4}
      />
    </Container>
  );
};

export default ViewerAvatars;
