import { ComposedModalProps } from 'carbon-components-react';
import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';

export const Dialog: React.FC<ComposedModalProps> = ({ ...props }) => {
  const { open } = props;

  useEffect(() => {
    if (open) {
      scrollToTop();
    }
  }, [open]);

  return <M.ComposedModal {...props} />;
};

export default Dialog;
