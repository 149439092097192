import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';

const StyledWelcome = styled.h1`
  color: ${colors.uiTextPrimaryLight};
`;

const WelcomeMessage = () => {
  const user = useUser();
  const { t } = useTranslation('case-management');
  const name = user?.full_name || user?.email;
  const displayName = name ? `, ${name}` : '';

  return (
    <StyledWelcome data-testid='case-management-welcome-message'>
      {t('welcome')}
      {displayName}
    </StyledWelcome>
  );
};

export default WelcomeMessage;
