import React from 'react';

import { colors, M } from '@dashboard-experience/mastodon';

import {
  isDatePastEta,
  parseDateString,
} from 'components/Report/Screenings/CountyCriminal/Eta/utils';
import styled from 'styled-components';
import { FieldComponentProps } from '../types';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  .mastodon-toggletip-content {
    max-width: 18rem !important;
  }
  .cds--toggletip-button {
    margin-left: 0.5rem;
  }

  .tooltip-header {
    color: ${colors.uiTextPrimaryLight} !important;
    font-weight: bold !important;
    margin-bottom: 0.5rem !important;
  }

  .tooltip-link {
    color: ${colors.uiNavy600} !important;
    margin-top: 0.5rem;

    &:hover {
      color: ${colors.uiNavy700};
    }
  }
`;

const Eta: React.FC<FieldComponentProps> = ({ reportPrediction, testid }) => {
  return (
    <StyledWrapper data-testid={testid}>
      <span>{parseDateString(reportPrediction, true)}</span>
      <M.Toggletip align='right' tabIndex={0}>
        <M.ToggletipButton>
          <M.Icon icon='HelpFilled' />
        </M.ToggletipButton>
        <M.ToggletipContent>
          <p className='tooltip-header'>Report Estimated Completion</p>
          {!isDatePastEta(reportPrediction) ? (
            <p>
              This date is based on historical turnaround times for all searches
              in the check.
            </p>
          ) : (
            <p>
              While we can accurately predict completion dates for more than 90%
              of reports, some take longer than expected. We&apos;re working to
              complete your report as quickly as possible.
            </p>
          )}
        </M.ToggletipContent>
      </M.Toggletip>
    </StyledWrapper>
  );
};

Eta.displayName = 'Eta';

export default Eta;
