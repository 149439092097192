import React from 'react';
import { Report, ArrestRecord as Record } from 'types';
import { StatusTypes } from '@dashboard-experience/utils';

import { CriminalRecord } from '../Components';

type Props = {
  record: Record;
  report: Report;
};

const arrestFields = [
  'booking_date',
  'booking_number',
  'booking_location',
  'full_name',
  'yob',
  'county',
  'state',
  'agency_phone_number',
  'agency_address',
];

const ArrestRecord: React.FC<Props> = ({ record, report }) => {
  const arrest = React.useMemo(
    () => ({
      ...record,
      agency_phone_number: record.phone_number,
      agency_address: record.address,
    }),
    [record],
  );

  return (
    <CriminalRecord
      key={record.id}
      record={arrest}
      report={report}
      // Since arrest records do not support asses, the status type should be "legacy"
      statusType={StatusTypes.Legacy}
      customFields={arrestFields}
    />
  );
};

export default ArrestRecord;
