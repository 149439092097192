import { AnyQueryKey, useQuery } from 'react-query';

import * as Actions from './actions';

export const useFetchNode = (params: Actions.Params) => {
  const {
    account: { id: accountId, hierarchy_present },
  } = params;
  const key: AnyQueryKey = ['nodes/fetch_single_node', { id: accountId }];

  const request = () => Actions.fetchNode(params);
  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!hierarchy_present,
  });
};

export const useFetchNodes = (params: Actions.FetchNodesParams) => {
  const {
    account: { id: accountId, segmentation_enabled },
    nodeName,
  } = params;
  const key: AnyQueryKey = [
    'nodes/fetch_nodes',
    { id: accountId, nodeName, segmentation_enabled },
  ];

  const request = () => Actions.fetchNodes(params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!segmentation_enabled,
  });
};

export const useFetchAllNodes = (params: Actions.FetchAllNodesParams) => {
  const {
    account: { id: accountId, segmentation_enabled },
  } = params;
  const key: AnyQueryKey = [
    'nodes/fetch_all_nodes',
    { id: accountId, segmentation_enabled },
  ];

  const allNodesRequest = () => Actions.fetchAllNodes(params);

  const singleNodeRequest = () => Actions.fetchNode(params);

  const { data, isLoading } = useQuery('fetch_a_node', singleNodeRequest, {
    refetchOnWindowFocus: false,
    enabled: !!segmentation_enabled,
  });

  const {
    data: nodes,
    isLoading: nodesLoading,
    refetch,
    isFetching,
  } = useQuery(key, allNodesRequest, {
    refetchOnWindowFocus: false,
    enabled: !!segmentation_enabled && data?.count >= 1,
  });

  const nodesWithID = nodes?.data?.map((node: any) => ({
    ...node,
    id: node.custom_id,
  }));

  const count: number = nodes?.count;

  return {
    nodes: nodesWithID,
    isLoading: isLoading || nodesLoading,
    count,
    refetch,
    isFetching,
  };
};
