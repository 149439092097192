/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  fetchGeoSubscriptions,
  updateGeoSubscriptions,
  updateSingleGeoSubscription,
} from 'actions/AccountUsersActions';

import { M } from '@dashboard-experience/mastodon';

import { ErrorText } from '../../Text';

class GeoSubscriptionsModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchGeoSubscriptions: fetch, user } = this.props;
    fetch(user.id);
  }

  /**
   * @name onCheckAllClick
   * @function
   * @memberOf GeoSubscriptionsModal
   * @description Handles checking all checkboxes on/off
   */
  onCheckAllClick = (event, { checked }) => {
    const {
      geoSubscriptions,
      user,
      updateSingleGeoSubscription: update,
    } = this.props;
    geoSubscriptions[user.id].map((subscription, idx) => {
      return update(user.id, idx, checked);
    });
  };

  /**
   * @name updateCheckbox
   * @function
   * @memberOf GeoSubscriptionsModal
   * @description Update an individual geo subscription checkbox
   * @param {integer} itemIndex - The index of the item in the geo
   * @param {boolean} checkboxValue - The current value of the checkbox
   */
  updateCheckbox =
    itemIndex =>
    (event, { checked }) => {
      const { updateSingleGeoSubscription: update, user } = this.props;
      update(user.id, itemIndex, checked);
    };

  /**
   * @name save
   * @function
   * @memberOf GeoSubscriptionsModal
   * @description Save the geo subscriptions
   */
  save = () => {
    const {
      user,
      geoSubscriptions,
      updateGeoSubscriptions: update,
      hideModal,
    } = this.props;
    update(user.id, geoSubscriptions[user.id]).then(() => {
      hideModal();
    });
  };

  render() {
    const { hideModal, open, fetchRequest, geoSubscriptions, user } =
      this.props;
    const subscriptions = geoSubscriptions[user.id];
    return (
      <M.ComposedModal onClose={hideModal} open={open}>
        <M.ModalHeader closeModal={hideModal}>
          <h2>Geo Subscriptions</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <M.Checkbox
            hideLabel={false}
            labelText='Check all'
            id='check-all'
            name='check_all'
            onChange={this.onCheckAllClick}
          />
          <br />
          {!fetchRequest.processing &&
            subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map((item, itemIndex) => (
              <M.Checkbox
                key={item.geo_id}
                checked={item.subscribed}
                disabled={false}
                hideLabel={false}
                labelText={item.geo_name}
                id={item.geo_id}
                name={`geo_id_${item.geo_id}`}
                onChange={this.updateCheckbox(itemIndex)}
              />
            ))}
          {!fetchRequest.processing &&
            subscriptions &&
            !subscriptions.length && <>No geo subscriptions.</>}
          {fetchRequest.processing && (
            <M.LoadingInline description='Loading geo subscriptions...' />
          )}
          {fetchRequest.error && (
            <ErrorText>
              There was a problem fetching geo subscriptions for this user.
            </ErrorText>
          )}
        </M.ModalBody>
        <M.ModalFooter>
          <M.BinaryButtons
            btnLeft={{ name: 'Cancel', onClick: hideModal }}
            btnRight={{ name: 'Save', onClick: this.save }}
            size='sm'
          />
        </M.ModalFooter>
      </M.ComposedModal>
    );
  }
}

GeoSubscriptionsModal.propTypes = {
  fetchGeoSubscriptions: PropTypes.func.isRequired,
  fetchRequest: PropTypes.object,
  geoSubscriptions: PropTypes.object,
  hideModal: PropTypes.func,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
  updateGeoSubscriptions: PropTypes.func,
  updateSingleGeoSubscription: PropTypes.func,
};

GeoSubscriptionsModal.defaultProps = {
  fetchRequest: {},
  geoSubscriptions: {},
  hideModal: () => {},
  user: {},
  updateGeoSubscriptions: () => {},
  updateSingleGeoSubscription: () => {},
};

const mapStateToProps = state => {
  return {
    geoSubscriptions: state.users.geoSubscriptions,
    fetchRequest: state.users.fetchGeoSubscriptionsRequest,
    updateRequest: state.users.updateGeoSubscriptionsRequest,
  };
};

const mapDispatchToProps = {
  fetchGeoSubscriptions,
  updateGeoSubscriptions,
  updateSingleGeoSubscription,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeoSubscriptionsModal);
