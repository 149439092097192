import React from 'react';
import Image from './Image';
import Content from './Content';
import Subsections from '../subsections';

type Props = {
  content: any;
  subsection: Subsections;
};

const Container: React.FC<Props> = ({ content, subsection }) => {
  return (
    <div data-testid='fair-chance-sections-engage-more-candidates-example'>
      <Image subsection={subsection} />
      <Content content={content} subsection={subsection} />
    </div>
  );
};

export default Container;
