export const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const uuidPattern =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const isEmailValid = (value: string) => {
  return emailRegex.test(value);
};

export function disabledRow(rowId: string): boolean {
  return uuidPattern.test(rowId);
}

export function isEmptyString(value: string): boolean {
  return value == null || value.trim().length === 0;
}

export function isRowDisabled(
  has_form_i9: boolean,
  full_name: string,
): boolean {
  let ret_val: boolean = false;
  if (has_form_i9) {
    return true;
  }

  if (isEmptyString(full_name)) {
    ret_val = true;
  }

  return ret_val;
}

export function orderTooltipText(full_name: string): string {
  let tt_text: string = '';
  if (isEmptyString(full_name)) {
    tt_text =
      'Candidate name is required. Name will be available after your candidate initiates their background check';
  } else {
    tt_text = 'This candidate already has a Form I9';
  }
  return tt_text;
}
