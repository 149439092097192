import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';

type Props = {
  children: any;
  col?: number;
  style?: GenericObject;
  noPadding?: boolean;
};

const CustomCol: React.FC<Props> = ({
  children,
  col = 1,
  style = {},
  noPadding = false,
  ...otherProps
}) => {
  const customPadding = noPadding ? { paddingLeft: 0, paddingRight: 0 } : {};
  return (
    <M.GridCol
      {...otherProps}
      style={{ marginTop: '2rem', ...customPadding, ...style }}
      md={col}
    >
      {children}
    </M.GridCol>
  );
};

export default CustomCol;
