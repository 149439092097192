import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import styled from 'styled-components';
import { PR_US_STATE_KEY, WASHINGTON_CONSENT_FLAG_KEY } from 'Constants';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { Context } from 'components/Invitations';
import { debug } from 'utils';
import { GenericObject } from 'types';
import { OrderNewReportParams, useOrderNewReport } from 'api/reports';
import { useCandidate } from 'providers/Candidate';

const Icon = styled(M.Icon)`
  margin-right: 0.5rem;
`;

const Button = styled(M.Button)`
  max-width: unset;
`;

const shouldDisablePackage = (p: GenericObject) =>
  !!(p.has_screenings_requiring_data || p.continuous_check);

const PACKAGE_SELECT_PROPS = Object.freeze({
  helperText:
    'Grayed out packages require more information and cannot be ordered from this menu. (e.g. Employment verification, etc.)',
});

export const getOrderParams = ({ candidateId, selection }: GenericObject) => {
  debug(candidateId, selection);

  if (!selection || !selection.package) return undefined;

  const {
    node,
    geo,
    // program
    country,
    city,
    state,
    package: pkg,
    purpose,
  } = selection;

  const data: OrderNewReportParams = {
    candidate_id: candidateId,
    package: pkg.slug,
  };

  if (geo && geo.id !== 'None') data.geo_ids = [geo.id];
  if (node) data.node = node.custom_id;

  const workLocation: GenericObject = {};
  if (country) workLocation.country = country;
  if (state) workLocation.state = state.abbreviation;
  if (city) workLocation.city = city.name;
  if (country || city || state) data.work_locations = [workLocation];
  if (purpose) data.permissible_purpose = purpose;

  return data;
};

const OrderNewReport = () => {
  const {
    account,
    isSingleNode,
    nodes,
    // only use geos present on the candidate
    // we wouldn't want to run them in a geo that requires different disclosures
    // geos,
    selectedNode,
    nodeIsLoading,
    nodePackages,
    onNodeChange,
    onNodeFilterChange,
    packages,
    // dashboard does not allow selecting programs, so preserve functionality
    // programs,
    anyLoading,
  } = useContext(Context);
  const candidate = useCandidate();
  const { geos, id: candidateId } = candidate;

  const { call, result } = useOrderNewReport();

  const packagesWithDisabledOptions = useMemo(
    () =>
      (packages || []).map((p: GenericObject) => ({
        ...p,
        disabled: shouldDisablePackage(p),
      })),
    [packages],
  );

  const [selection, setSelection] = useState(undefined);
  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;
  const washingtonConsentEnabled =
    useFlag(WASHINGTON_CONSENT_FLAG_KEY)?.variantKey === 'true' || false;

  const [canTrackEvent, setCanTrackEvent] = useState(false);
  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const order = useCallback(() => {
    if (!selection) return;
    const params = getOrderParams({ candidateId, selection });
    if (!params) return;
    setCanTrackEvent(true);
    call(params);
  }, [selection, candidateId, call]);

  useEffect(() => {
    const canTrack = canTrackEvent && result.isSuccess && currentUser;

    if (canTrack) {
      const params = getOrderParams({ candidateId, selection });
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.REPORT_REQUEST_NEW_REPORT_SUBMITTED,
        {
          'Package selected': params?.package
            ? humanizeOnUnderscore(params?.package)
            : '',
        },
      );
      setCanTrackEvent(false);
    }
  }, [candidateId, currentUser, result, selection, trackEvent, canTrackEvent]);

  return anyLoading ? (
    <M.Container>
      <h5 className='mb-0'>Order new report</h5>
      <M.LoadingInline description='Loading packages...' />
    </M.Container>
  ) : (
    <M.PackageOrder
      container='default'
      title='Order new report'
      id='order-new-report'
      onChange={setSelection}
      nodes={nodes}
      geos={geos}
      hidePrograms
      packages={packagesWithDisabledOptions}
      nodePackages={nodePackages}
      nodeIsLoading={nodeIsLoading}
      isSingleNode={isSingleNode}
      selectedNode={selectedNode}
      onNodeChange={onNodeChange}
      onNodeFilterChange={onNodeFilterChange}
      account={account}
      workLocationSelectProps={{ withPr }}
      isWashingtonConsentEnabled={washingtonConsentEnabled}
      packageSelectProps={PACKAGE_SELECT_PROPS}
      alwaysShowCertification
    >
      <M.Container.Row>
        <M.Container.Col>
          {selection &&
            (result.isLoading ? (
              <M.LoadingInline description='Requesting...' />
            ) : (
              <Button kind='primary' onClick={order}>
                <Icon icon='Add' size='24' />
                Request new report for this candidate
              </Button>
            ))}
        </M.Container.Col>
      </M.Container.Row>
    </M.PackageOrder>
  );
};

export default OrderNewReport;
