import { asEntityVersion } from 'modules/core-types/entity-version';
import { useState } from '../../ruleset/version/store';

export const useInstanceVersion = () => {
  const rsv = useState();
  const ccs = asEntityVersion(
    rsv.category_code_set_version,
    'category_code_set',
  );
  if (ccs) {
    ccs.id = (ccs as any)?.resource_id || ccs?.id;
    const { version } = ccs;
    version.id = (version as any)?.resource_id || version?.id;
  }
  return ccs;
};
