import { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';

import { useUniqueToast } from 'containers/Notifications/hooks';
import * as Notifications from 'state/notifications/entity';
import { HEALTH_SCREENING_EXAM_REORDER_UI_COMPONENTS } from 'Flags';
import { namespace } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEvent,
  ReorderEvent,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import { UseReorderableHealthToastParms } from './types';

const reorderableHealthScreeningToastId = 'reorderable_health_screening_toast';

const useReorderableHealthScreeningToast = ({
  report,
  healthTabIndex,
  tab,
  updateTab,
  ref,
}: UseReorderableHealthToastParms) => {
  const [hasNotification, setHasNotification] = useState(false);
  const [waitingForTab, setWaitingForTab] = useState(false);
  const { t } = useTranslation(namespace, {
    keyPrefix: 'exam_reorder.notification',
  });
  const createUniqueToast = useUniqueToast(reorderableHealthScreeningToastId); // Don't add to the effect dep array, even though useUniqueToast returns a memoized callback, it frequently creates a new function, on every render
  const examReorderEnabled =
    useFlag(HEALTH_SCREENING_EXAM_REORDER_UI_COMPONENTS)?.variantKey === 'on';
  const trackEvent = useTrackEvent();

  const reorderableExams = useMemo(
    () =>
      [
        ...(report?.occupational_health_screening?.tests || []),
        ...(report?.drug_screening?.exam_results || []),
      ].filter(exam => exam.status === 'suspended'),
    [report],
  );

  const renderNotification =
    !isEmpty(report) &&
    !hasNotification &&
    Boolean(reorderableExams.length) &&
    examReorderEnabled &&
    healthTabIndex;

  const scrollToRef = useCallback(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [ref]);

  useEffect(() => {
    if (renderNotification) {
      createUniqueToast({
        kind: Notifications.Kind.error,
        title: t('title'),
        message: t('message'),
        action: {
          callback: () => {
            if (tab !== 'Health Screening') {
              setWaitingForTab(true);
              updateTab({ selectedIndex: Number(healthTabIndex) });
            }
            trackEvent(ReorderEvent.ToastNotificationClicked);
          },
          message: t('action'),
        },
        timeout: 0,
      });
      setHasNotification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    renderNotification,
    healthTabIndex,
    ref,
    tab,
    updateTab,
    setHasNotification,
    trackEvent,
  ]);

  useEffect(() => {
    if (tab === 'Health Screening' && waitingForTab) {
      scrollToRef();
      setWaitingForTab(false);
    }
  }, [tab, waitingForTab, scrollToRef]);
};

export default useReorderableHealthScreeningToast;
