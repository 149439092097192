import React from 'react';
import { ReportProps } from 'types';
import { StatusTypes } from '@dashboard-experience/utils';

import ReferenceVerification from './ReferenceVerification';

const ProfessionalReferenceVerification: React.FC<ReportProps> = ({
  report,
  // reportStatusType,
}) => {
  const { professional_reference_verifications: prvs } = report;
  // TODO - remove this and use the prop once migrated to Assess - forces legacy for now
  const reportStatusType = StatusTypes.Legacy;

  return prvs && prvs.length ? (
    <ReferenceVerification
      expandableTestId='screenings-professional-reference-verification-expandable'
      title='Professional Reference Verification'
      referenceVerifications={prvs}
      screeningTestId='screenings-professional-reference-verification'
      reportStatusType={reportStatusType}
    />
  ) : null;
};

export default ProfessionalReferenceVerification;
