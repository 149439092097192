import React from 'react';
import styled from 'styled-components';
import { Kind } from 'modules/assess/models/entity';
import { ID } from 'modules/id';
import ActiveLink from './active-link';
import Byline, { User } from './byline';
import Indicator from './indicator';

export type Type = React.FC<Props>;

export type Props = {
  assigned?: boolean;
  id?: ID;
  kind?: Kind;
  latest?: boolean;
  time?: Date | string;
  user?: User | string;
  version?: number;
};

const Container = styled.div<{ align: string; justify: string }>`
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
`;

const Spacer = styled.span`
  margin: 0 1em;
`;

export const Status: Type = ({
  assigned,
  id,
  kind,
  latest,
  time,
  user,
  version,
}) => {
  return (
    <Container
      align='center'
      justify='space-between'
      data-testid='assess-entity-status'
    >
      <Container align='center' justify='flex-start'>
        {(assigned || kind === Kind.draft || latest === false) && (
          <>
            <Indicator assigned={assigned} kind={kind} latest={latest} />
            <Spacer />
          </>
        )}
        <Byline id={id} kind={kind} time={time} user={user} version={version} />
      </Container>
      <ActiveLink kind={kind} latest={latest} />
    </Container>
  );
};

export default Status;
