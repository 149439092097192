import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { TableHeader, TableRow } = M.DataTable;

const StyledHeader = styled(TableHeader)`
  border-top: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 25%;
  .cds--table-header-label {
    text-align: center;
  }
`;

const StyledRow = styled(TableRow)`
  th:first-of-type {
    border: 1px solid ${colors.uiGrey0} !important;
    font-weight: normal !important;
    padding-left: 0 !important;
    padding-top: 1em !important;
    span {
      text-align: left;
      padding-bottom: 0;
    }
    .cds--table-header-label {
      text-align: initial;
    }
  }
`;

const headerKeys = ['main', 'felony', 'misdemeanor', 'petty_offense'];

const Header: React.FC = () => {
  const { t } = useTranslation('assess');
  const headers = headerKeys.map(key => {
    const header = t(
      `assess:ruleset.version.publish.changes.lookback.table.headers.${key}`,
    );
    return {
      key,
      header,
    };
  });

  return (
    <StyledRow>
      {headers.map(header => (
        <StyledHeader key={header.key} colSpan={2}>
          {header.header}
        </StyledHeader>
      ))}
    </StyledRow>
  );
};

export default Header;
