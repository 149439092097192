import React, { useCallback, useState } from 'react';
import { TOSResponse } from 'api/i9';
import styled from 'styled-components';
import AccountGetStarted from './AccountGetStarted';
import AccountSetupForm from './AccountSetupForm';
import AccountSetupComplete from './AccountSetupComplete';
import AccountTermsOfService from './AccountTermsOfService';
import { Border, FlexContainer } from './style';

type AccountSetupProps = {
  tosData?: TOSResponse;
};

const CenteredContainerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 13.4rem);
  text-align: center;
`;

const CenteredContainer = styled.div`
  width: 54rem;
`;

const AccountSetupUpsell: React.FC<AccountSetupProps> = ({ tosData }) => {
  const [step, setStep] = useState(0);

  const handleGetStartedContinueClick = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const handleTermsOfServiceContinueClick = useCallback(() => {
    setStep(2);
  }, [setStep]);

  const handleSetupFormContinueSubmitted = useCallback(() => {
    setStep(3);
  }, [setStep]);

  const renderContainerContent = useCallback(() => {
    switch (step) {
      case 0:
        return (
          <CenteredContainerWrap>
            <CenteredContainer>
              <AccountGetStarted
                onContinueClick={handleGetStartedContinueClick}
              />
            </CenteredContainer>
          </CenteredContainerWrap>
        );

      case 1:
        return (
          <FlexContainer gap='2rem' padding='0'>
            <h2>I-9 verification</h2>
            <Border>
              <FlexContainer gap='3rem' padding='2.5rem'>
                <AccountTermsOfService
                  onContinueClick={handleTermsOfServiceContinueClick}
                  tosContent={tosData?.content || ''}
                />
              </FlexContainer>
            </Border>
          </FlexContainer>
        );

      case 2:
        return (
          <FlexContainer gap='2rem' padding='0'>
            <h2>I-9 verification</h2>
            <Border>
              <FlexContainer gap='3rem' padding='2.5rem'>
                <AccountSetupForm
                  onContinueFormSubmitted={handleSetupFormContinueSubmitted}
                  tosVersion={tosData?.version}
                />
              </FlexContainer>
            </Border>
          </FlexContainer>
        );

      case 3:
        return (
          <FlexContainer gap='2rem' padding='0'>
            <h2>I-9 verification</h2>
            <AccountSetupComplete />
          </FlexContainer>
        );

      default:
        throw new Error('unexpected state');
    }
  }, [
    step,
    handleGetStartedContinueClick,
    handleTermsOfServiceContinueClick,
    tosData?.content,
    tosData?.version,
    handleSetupFormContinueSubmitted,
  ]);

  return renderContainerContent();
};

export default AccountSetupUpsell;
