import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const CustomCell = styled(M.TableCell)`
  .cds--popover-content[role='tooltip'] {
    max-width: 15rem !important;
  }
  button.cds--definition-term {
    border-bottom: none;
    font-size: 0.9rem;
    color: ${colors.uiGrey800};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  span,
  .mastodon-status-icon {
    margin-right: 0.5rem;
  }
`;

export const Row: React.FC<{}> = ({ children }) => (
  <M.TableRow data-testid='drug-health-table-body-row'>{children}</M.TableRow>
);
