import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';
import TooltipModal from './TooltipModal';
import FilterLabel from '../FilterLabel';

const FilterTitle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = useCallback(
    e => {
      e.preventDefault(); // Prevents assessment menu from opening
      setIsOpen(true);
      scrollToTop();
    },
    [setIsOpen],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const icon = (
    <M.Icon
      icon='InformationFilled'
      size='14'
      style={{ fill: colors.uiNavy600 }}
      onClick={showModal}
    />
  );
  return (
    <div data-testid='case-management-tasks-page-assessment-filter-title'>
      <FilterLabel filterType='assessment' icon={icon} />
      <TooltipModal closeModal={closeModal} modalOpen={isOpen} />
    </div>
  );
};

export default FilterTitle;
