import React, { useReducer } from 'react';
import { useWorkflowScreenStates } from '@dashboard-experience/utils';
import CustomProvider from 'state/provider';
import { Report } from 'types';
import { context } from './context';
import examReorderTransitions from '../transitions';
import { ReorderableExams } from '../types';
import Reducer from './reducer';
import initialState from './initial-state';

type Props = {
  onClose: () => void;
  open: boolean;
  reorderableExams: ReorderableExams;
  report?: Report;
};

const initialScreen = 'ScreeningSelection';

const Provider: React.FC<Props> = ({
  onClose,
  open,
  reorderableExams,
  report,
  children,
}) => {
  const [formData, dispatch] = useReducer(Reducer, {
    ...initialState,
    reorderableExams,
  });

  const { currentScreen, transitionToScreen, nextScreen, previousScreen } =
    useWorkflowScreenStates(initialScreen, examReorderTransitions);

  return (
    <CustomProvider
      context={context}
      props={{
        open,
        onClose,
        currentScreen,
        transitionToScreen,
        nextScreen,
        previousScreen,
        formData,
        report,
        dispatch,
      }}
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
