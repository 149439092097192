import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import BaseFilter from './Components/BaseFilter';

export const Container = styled(M.Container)`
  padding: 0rem !important;
  margin-bottom: 1rem !important;
  border: none !important;
  border-radius: 0px !important;

  .cds--label {
    color: ${colors.brandNavy4} !important;
    min-height: 1rem;
  }
`;

export const AdvancedFiltersText = styled.span`
  align-items: center;
  color: ${colors.uiNavy600};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  font-weight: 700;

  svg {
    margin-right: 4px;
  }
`;

export const CandidateExportText = styled.span`
  color: ${colors.brandNavy1};
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  font-weight: 500;
`;

export const Results = styled.span`
  color: ${colors.brandSlate6};
  display: inline-block;
  line-height: 20px;
  font-weight: 400;
`;

export const Reset = styled.span`
  align-items: center;
  color: ${colors.uiNavy600};
  cursor: pointer;
  display: flex;
  line-height: 1.25rem;
  font-weight: 700;

  svg {
    margin-right: 0.25rem;
  }
`;

export const StatusModalContent = styled.p`
  font-size: 16px !important;
  line-height: 24px !important;
  padding-bottom: 1.5rem;
  padding-right: 0 !important;
`;

export const StyledBaseFilter = styled(BaseFilter)`
  svg {
    fill: ${colors.brandNavy1};
  }
`;
