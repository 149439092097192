import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  isOpen: any;
  closeModal: any;
  archive: any;
};

const ModalBody = styled(M.ModalBody)`
  p {
    color: ${colors.coolBlack70};
    padding-right: 0;
  }
`;

const ArchiveModal: React.FC<Props> = ({ isOpen, closeModal, archive }) => {
  return (
    <M.ComposedModal
      open={isOpen}
      onClose={closeModal}
      data-testid='archive-modal'
    >
      <M.ModalHeader closeModal={closeModal}>
        <h2>Archive report?</h2>
      </M.ModalHeader>
      <ModalBody>
        <p>
          Archiving this report will remove it from your <strong>Active</strong>{' '}
          tab and disable the report <strong>Actions</strong> menu. You can
          unarchive the report at any time.
        </p>
        <p>
          If its status changes while archived, the report will be automatically
          unarchived and added back to your <strong>Active</strong> tab.{' '}
        </p>
      </ModalBody>
      <M.ModalFooter>
        <M.Button kind='secondary' onClick={closeModal}>
          Cancel
        </M.Button>
        <M.Button
          type='submit'
          onClick={archive}
          data-testid='confirm-archive-modal'
        >
          Confirm
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ArchiveModal;
