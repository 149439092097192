/* eslint-disable react/display-name */
import React, { forwardRef, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import MultiSelectFilter from './MultiSelectFilter';

type PropsType = {
  items: [any];
  onChange: any;
};

const ReportTaskFilter: React.FC<PropsType> = forwardRef(
  ({ items, onChange, ...other }, ref) => {
    const report_task = 'Report task';

    return (
      <M.FilterMultiSelect
        data-testid='report-task-filter'
        className='report-task-filter'
        name={report_task.toLowerCase()}
        id='report_task'
        items={items}
        onChange={onChange}
        placeholder={report_task.toLowerCase()}
        titleText={report_task}
        type={report_task.toLowerCase()}
        ref={ref}
        {...other}
      />
    );
  },
);

const ReportTask = () => {
  const tasks = [
    {
      id: 'action_required',
      value: 'ActionRequired',
      label: 'Needs follow-up',
    },
  ];

  const onChange = useCallback(
    (setFilters: (params: any) => void) =>
      ({ selectedItems }: { selectedItems: Array<any> }) => {
        setFilters(selectedItems.map(item => item.value).join(','));
      },
    [],
  );

  return (
    <MultiSelectFilter
      items={tasks}
      onChange={onChange}
      component={ReportTaskFilter}
      param='report_tasks'
    />
  );
};

export default ReportTask;
