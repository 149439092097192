export enum LookbackPeriod {
  BulkEditUsed = 'Checkr Dashboard Assess {0} Lookback Period - Bulk Edit by Category',
  CategoryExpanded = 'Checkr Dashboard Assess {0} Lookback Period - Category Expansion',
  ExportedCSV = 'Checkr Dashboard Assess {0} Lookback Period - Export CSV',
  GuidelinesSaved = 'Checkr Dashboard Assess {0} Lookback Period - Guideline Saved',
  ImportedCSV = 'Checkr Dashboard Assess {0} Lookback Period - Import CSV',
  LessThanMisdemeanorSaved = 'Checkr Dashboard Assess {0} Lookback Period - Less Than Misdemeanor Saved',
  NumberPublished = 'Number Published',
  Page = 'Lookback Period Page',
  Pageview = 'Checkr Dashboard Assess {0} Lookback Period Pageview',
  Published = 'Checkr Dashboard Assess {0} Lookback Period - Rules Published',
  SearchUsed = 'Checkr Dashboard Assess {0} Lookback Period - Search Used',
  SubcategoriesPublished = 'Subcategories Published',
  SubcategoryExpanded = 'Checkr Dashboard Assess {0} Lookback Period - Subcategory Expansion',
  SubcategoryUpdated = 'Checkr Dashboard Assess {0} Lookback Period - Subcategory Entry',
}

export default LookbackPeriod;
