import React from 'react';
import { CsvExport, useCsvExport, ModalType } from 'components/CsvExport';
import { useCreateAdverseActionCsv, AACSVExportFilters } from 'api/csvExport';

const Body = () => (
  <>
    <p>
      Select a date range to export. Please note: If{' '}
      <strong>Adverse Actions to</strong> is left blank it will default to
      today&apos;s date.
    </p>
    <p>Exports are limited to 5,000 rows.</p>
  </>
);

type Props = {
  status: string;
  fullName?: string;
};

type Filters = Partial<AACSVExportFilters>;

// Current limitation in candidate search
const maxResultsPerPage = 5000;

const AdverseActionExportCsv: React.FC<Props> = ({
  fullName: full_name,
  status,
}) => {
  const { screen, open, toggle, setScreen } = useCsvExport();
  const { call, result } = useCreateAdverseActionCsv(setScreen);

  const processing = result?.isLoading;
  const error = result?.isError;
  const errorMsg = result?.error;

  const filters: Filters = {
    full_name,
    status,
    page: 1,
    per_page: maxResultsPerPage,
    order_by: 'created_at',
    sort: 'desc',
  };

  return (
    <CsvExport
      body={<Body />}
      error={error}
      errorMsg={errorMsg}
      filters={filters}
      open={open}
      post={call}
      processing={processing}
      screen={screen}
      toggle={toggle}
      type={ModalType.AdverseAction}
    />
  );
};

export default AdverseActionExportCsv;
