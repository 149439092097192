import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { EvictionRecord as EvictionRecordType } from 'types/report/screenings/evictionSearch';

import EvictionRecord from './EvictionRecord';

const EvictionSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { eviction_search } = report;
  const records = eviction_search?.records;

  return eviction_search ? (
    <M.Screening
      title='Civil Search'
      statusType={reportStatusType}
      screening={eviction_search}
      id={SCREENING_TITLES.eviction_search.id}
    >
      {records?.map((record: EvictionRecordType) => (
        <EvictionRecord key={record.id} record={record} />
      ))}
    </M.Screening>
  ) : null;
};

export default EvictionSearch;
