import React, { useCallback } from 'react';
import styled from 'styled-components';
import Sections from 'components/FairChance/sections';
import { Button } from 'modules/assess/ui/page';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const AdverseActionLearnMoreButton: React.FC = () => {
  const handleClick = useCallback(() => {
    window.open(
      'https://help.checkr.com/hc/en-us/articles/216873808-The-Adverse-Action-process-Deciding-not-to-move-forward-with-a-candidate',
    );
  }, []);
  const translation = `fairChance:fair_chance.sections.${Sections.adverse_action_rate}.without_data.learn-more-button`;

  return (
    <Container>
      <Button onClick={handleClick} size='sm' trans={translation} />
    </Container>
  );
};

export default AdverseActionLearnMoreButton;
