import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const Banner = styled(M.InlineNotification)`
  max-width: 100%;

  flex-shrink: 0;

  ${({ isHomePage }) =>
    isHomePage &&
    `
      min-width: calc(100% + 4rem);
      margin: -1.5rem -2rem 1.5rem !important;
      z-index: 2;

      @media screen and (min-width: 768px) {
        min-width: calc(100% + 8rem);
        margin: -3rem -4rem 3rem !important;
      }
    `}
`;

export const ActionableBanner = styled(M.ActionableNotification)`
  max-width: 100%;

  flex-shrink: 0;

  &.cds--actionable-notification--toast {
    box-shadow: none !important;
  }
`;

export const BannerFooter = styled.div`
  padding-top: 1em;
`;
