import { findEnvVar } from '@dashboard-experience/utils';
import { EditCohortFormFields, NewCohortFormFields } from './types';

export const INITIAL_VALUES: NewCohortFormFields | EditCohortFormFields = {
  id: '',
  cohortName: '',
  isDOTCompliant: null,
  dotComplianceAgency: '',
  drugAlcholSelection: '',
  drugScreening: '',
  alcoholScreening: '',
  fileStoragePath: '',
  fileDisplayName: '',
  fileStorageBucket: '',
};

export const CSV_UPLOAD_RANDOM_TESTING_CSV_TEMPLATE = findEnvVar(
  'CSV_UPLOAD_RANDOM_TESTING_CSV_TEMPLATE',
);
export const CSV_UPLOAD_FILESTACK_KEY = findEnvVar(
  'BULK_ORDER_CSV_UPLOAD_FILE_STACK_API_KEY',
);

export const DOT_ALCOHOL_SCREENING = 'DS_BREATH_ALCOHOL_DOT';
export const NON_DOT_ALCOHOL_SCREENING = 'DS_BREATH_ALCOHOL_NDOT';
export const SELECTABLE_ALCOHOL_PACKAGE_SUBTYPE_NAME = 'Other';

export const DOT_DRUG_SCREENING = 'DS_DOT_7_PANEL';
export const DRUG_SCREENING_PACKAGE_SUBTYPE = 'drug_screening_v2';
export const SELECTABLE_DRUG_PACKAGE_SUBTYPE_NAME = 'urine tests';

export const DOT_ALCOHOL_TEST_UNAVAILABLE = 'DOT alcohol test unavailable';
export const DOT_DRUG_TEST_UNAVAILABLE = 'DOT drug test unavailable';
export const ALCOHOL_TEST_UNAVAILABLE = 'Alcohol test unavailable';
export const DRUG_TEST_UNAVAILABLE = 'Drug test unavailable';

export const ERROR_FORM_FIELD_LABELS: Record<string, string> = {
  cohortName: 'Pool Name',
  isDOTCompliant: 'DOT Compliant',
  dotComplianceAgency: 'Dot Compliance Agency',
  drugScreening: 'Drug Screening',
  alcoholScreening: 'Alcohol Screening',
  csvFilePath: 'Candidate CSV',
};

export const FORM_FIELD_LABELS: Record<string, string> = {
  ...ERROR_FORM_FIELD_LABELS,
  cohortName: 'What do you want to name your pool?',
  isDOTCompliant: 'Is this pool Department of Transportation (DOT) compliant?',
  dotComplianceAgency: 'Which DOT agency are they compliant to?',
  drugScreening: 'Select drug test',
  alcoholScreening: 'Alcohol test',
  pollCount: 'Poll a specific number of employees/donors',
  pollPercentage: 'Poll a percentage of employees/donors',
};

export const DOT_AGENCIES: Record<string, string> = {
  FMCSA: 'Federal Motor Carrier Safety Administration (FMCSA)',
  FAA: 'Federal Aviation Administration (FAA)',
  FRA: 'Federal Railroad Administration (FRA)',
  FTA: 'Federal Transit Administration (FTA)',
  PHMSA: 'Pipeline & Hazardous Materials Safety Administration (PHMSA)',
  HHS: 'Health and Human Services (HHS)',
  NRC: 'Nuclear Regulatory Commission (NRC)',
  USCG: 'United States Coast Guard (USCG)',
};

export const DRUG_ALCOHOL_SELECTION: Record<string, string> = {
  DRUG_ONLY: 'Drug only',
  ALCOHOL_ONLY: 'Alcohol only',
  DRUG_ALCOHOL: 'Drug and alcohol',
};
