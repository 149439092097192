import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemVisibility as ItemVisibilityModel } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import { Field } from 'modules/assess/ui/rules/common';
import { Entity } from 'modules/assess/ui/state/rules/custom';

type Props = {
  namespace: Namespace;
  rule: Entity.Type;
};

export const ItemVisibility: React.FC<Props> = ({ namespace, rule }) => {
  const { t } = useTranslation('');

  if (
    namespace !== Namespace.criminal ||
    !rule.item_visibility ||
    rule.item_visibility === ItemVisibilityModel.DEFAULT
  ) {
    return null;
  }

  const label = t('inputs.item_visibility');
  const displayItemVisibility = t(
    `assess:item_visibility.${rule.item_visibility}`,
  );

  return (
    <Field
      data-testid='assess-ui-rules-custom-rule-viewer-item-visibility'
      label={label}
    >
      <span>{displayItemVisibility}</span>
    </Field>
  );
};

export default ItemVisibility;
