import React from 'react';
import {
  ContextContainer,
  BulkOrderContainer,
  ManualBulkOrderContainer,
} from 'containers';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import BulkOrderHistoryHeader from '../../containers/Billing/BulkOrderHistory/BulkOrderHistoryHeader';
import { MANUAL_BULK_UPLOAD } from '../../Flags';

export const StyledSubHeading = styled.h4`
  padding-top: 1rem;
  margin: 0 1rem 1rem;
`;

const BulkOrderHistoryPage = () => {
  const currentUser = useUser();
  const hasManageBulkInvitationsPermission = hasPermission(
    currentUser,
    'manage_bulk_invitations',
  );

  const manualBulkUploadFlag: boolean =
    useFlag(MANUAL_BULK_UPLOAD)?.variantKey === 'on';

  if (!hasManageBulkInvitationsPermission) {
    const noAccessNotification = `You don't have access to this page. Please contact an account admin to update your user permissions.`;
    return (
      <BulkOrderHistoryHeader>{noAccessNotification}</BulkOrderHistoryHeader>
    );
  }

  return (
    <>
      {manualBulkUploadFlag && (
        <>
          <StyledSubHeading data-testid='manual-bulk-order-history-header'>
            Manual orders
          </StyledSubHeading>
          <ManualBulkOrderContainer />
        </>
      )}
      <StyledSubHeading data-testid='bulk-order-history-header'>
        Bulk invitations
      </StyledSubHeading>
      <BulkOrderContainer />
    </>
  );
};

export default ContextContainer(BulkOrderHistoryPage);
