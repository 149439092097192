import React from 'react';
import { dateToFromNow } from 'modules/adjudication/utilities';

import { FieldComponentProps } from '../types';

const TimeSinceOffense: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  const { date } = adverseItem;
  return (
    <span data-testid={testid} className='value-width'>
      {dateToFromNow(date)}
    </span>
  );
};

export default TimeSinceOffense;
