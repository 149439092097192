import { CodeID, ItemType } from 'modules/assess/models/mvr/codes';
import { ID } from 'modules/id';
import client from '../client';
import { path } from './uri';

export type UpdateParams = {
  code: CodeID;
  categoryId: ID;
};

export const getAll = (instanceId?: ID, versionId?: ID) => {
  const codesPath = `${path(instanceId, versionId)}/codes/all`;
  return client.get(codesPath);
};

export const patch = (
  instanceId?: ID,
  versionId?: ID,
  code?: CodeID,
  categoryId?: ID,
) => {
  const codesPath = `${path(instanceId, versionId)}/codes/${code}`;
  return client.patch(codesPath, {
    category_id: categoryId,
  });
};

export type BatchUpdateParams = {
  file: File;
  type: string;
};

export const batchUpdate = (
  batchUpdateParams: BatchUpdateParams,
  instanceId?: ID,
  versionId?: ID,
) => {
  const codesPath = `${path(instanceId, versionId)}/codes`;
  const data = new FormData();
  data.set('file', batchUpdateParams.file);
  data.set('type', batchUpdateParams.type);
  data.set('replace', 'true');
  data.set('create', 'true');
  return client.patch(codesPath, data);
};

export const exportCodes = (
  instanceId?: ID,
  versionId?: ID,
  type?: ItemType,
  name: string = `Assess - Category Code Mappings - ${instanceId}.csv`,
) => {
  const codesPath = `${path(instanceId, versionId)}/codes`;
  const params = { type };
  const headers = { ACCEPT: 'text/csv' };

  return client.download(codesPath, name, { params, headers });
};
