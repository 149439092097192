import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  name?: string;
};

export const Title: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation('assess');
  const key = 'assess:ruleset.version.publish.changes.title';
  const text = (
    <Trans t={t} i18nKey={key} values={{ name }}>
      You are about to make the following changes to <strong>{{ name }}</strong>
      . Please confirm before publishing:
    </Trans>
  );
  return (
    <span data-testid='assess-ruleset-version-publish-dialog-title'>
      {text}
    </span>
  );
};

export default Title;
