import React, { useCallback } from 'react';
import { initializeComplexRule } from 'modules/assess/models/rules/count/entity';
import CreateButton from 'modules/assess/ui/rules/common/rule/editor/create-button';
import { Actions } from '../../../state/rules/count';
import { useDispatch } from '../../../ruleset/version/store';

export const CreateComplexButton = () => {
  const dispatch = useDispatch();

  const createComplexRuleButton = useCallback(() => {
    const rule = initializeComplexRule();
    dispatch(Actions.Create.create(rule as any));
  }, [dispatch]);

  return (
    <CreateButton
      dataTestid='assess-rules-count-create-complex-rule'
      iconKey='RuleDraft'
      onClick={createComplexRuleButton}
      labelTranKey='buttons.create_complex_rule'
    />
  );
};

export default CreateComplexButton;
