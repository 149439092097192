import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Settings } from 'modules/assess/models/rulesets/version';
import { useDispatch, useState } from 'modules/assess/ui/ruleset/version/store';
import { Update } from 'modules/assess/ui/state/rulesets/versions/instance/actions';
import { useLessThanMisdemeanorPremadeRule } from 'modules/assess/v2/ui/hooks';
import Button from './Button';
import Header from './Header';
import Type from '../Type';

const Buttons = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

type Props = {
  className?: string;
  header?: Type;
};

const Container: React.FC<Props> = ({ className, header }) => {
  const { t } = useTranslation();
  const state = useState();
  const dispatch = useDispatch();

  const adverbs: any = t(`adverbs`, {
    returnObjects: true,
  });

  const lessThanMisdemeanorPremadeRuleSelected =
    useLessThanMisdemeanorPremadeRule();

  const allLessThanMisdemeanorEligible =
    lessThanMisdemeanorPremadeRuleSelected ||
    state.settings?.lookback_rules_all_less_than_misdemeanor_eligible;

  const onYesClick = useCallback(() => {
    const { settings } = state;
    const payload = getSettingsPayload(true, settings);
    dispatch(Update.create(payload));
  }, [dispatch, state]);
  const onNoClick = useCallback(() => {
    const { settings } = state;
    const payload = getSettingsPayload(false, settings);
    dispatch(Update.create(payload));
  }, [dispatch, state]);
  const basePath =
    'assess-v2-ui-guideline-lookback-period-less-than-misdemeanor-container';
  const yesButtonId = `${basePath}-yes-button`;
  const noButtonId = `${basePath}-no-button`;

  return (
    <div className={className} data-testid={basePath}>
      <Header header={header} />
      <Buttons>
        <Button
          id={yesButtonId}
          isSelected={allLessThanMisdemeanorEligible}
          text={adverbs.yes}
          onClick={onYesClick}
          disabled={lessThanMisdemeanorPremadeRuleSelected}
        />
        <Button
          id={noButtonId}
          isSelected={!allLessThanMisdemeanorEligible}
          text={adverbs.no}
          onClick={onNoClick}
          disabled={lessThanMisdemeanorPremadeRuleSelected}
        />
      </Buttons>
    </div>
  );
};

function getSettingsPayload(selected: boolean, settings?: Settings): any {
  const updatedSettings = {
    lookback_rules_all_less_than_misdemeanor_eligible: selected,
  };

  return { settings: { ...settings, ...updatedSettings } };
}

export default Container;
