import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { renderModal } from 'modules/candidate';
import { FieldComponentProps } from '../../types';
import NodesModal from '../nodes-modal';

const ModalLink = styled(M.Link)`
  text-decoration: underline;
`;

const Nodes: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const [showNodesModal, setShowNodesModal] = useState(false);

  const openModal = useCallback(
    (event: any) => {
      event.preventDefault();
      setShowNodesModal(true);
    },
    [setShowNodesModal],
  );
  const closeModal = useCallback(() => {
    setShowNodesModal(false);
  }, []);

  if (!report.segment_stamps?.length) {
    return null;
  }

  return (
    <div test-id={testid}>
      <ModalLink onClick={openModal} href=''>
        Show nodes details
      </ModalLink>
      {/* @ts-ignore */}
      {renderModal(NodesModal, {
        open: showNodesModal,
        hideModal: closeModal,
        report,
      })}
    </div>
  );
};

export default Nodes;
