import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const style = { display: 'block' };

const CourtClosures = () => {
  return (
    <p>
      <strong>Where can I check court closures?</strong>
      <M.Link
        style={style}
        href='https://courts.checkrstatus.com/'
        target='_blank'
        rel='noreferrer'
      >
        Covid-19 court closures
      </M.Link>
      <M.Link
        style={style}
        href='https://data.checkrstatus.com/'
        target='_blank'
        rel='noreferrer'
      >
        General court closures
      </M.Link>
    </p>
  );
};

export default CourtClosures;
