/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { BillingAddress, BillingEntity } from 'types/Billing';
import { postPaymentProfileValidationSchema } from '../Account/Payment/utils';
import {
  GridCol,
  GridRow,
  StyledInput,
  StyledSelect,
} from '../Account/Payment/PaymentStyledComponents';

export type FormFieldsPropsType = {
  form_label: string;
  form_fields: {
    id: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    name: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    email: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    address_line_1: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    address_line_2: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    city: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    region: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    postal_code: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
    country: {
      label: string;
      placeholder: string;
      disabled: boolean;
    };
  };
};

type Props = {
  paymentProfile: BillingEntity;
  submit: any;
  modalOpen: boolean;
  hideModal: any;
  isLoading: boolean;
  newIdValidator?: any;
  fieldProps: FormFieldsPropsType;
};

const PaymentProfileForm: React.FC<Props> = ({
  paymentProfile,
  submit,
  modalOpen,
  hideModal,
  isLoading,
  newIdValidator,
  fieldProps,
}) => {
  const { t } = useTranslation();
  const [idErrorMessage, setIdErrorMessage] = useState('');

  const initialValues: {
    country: string;
    address?: BillingAddress;
    city: string;
    address_line_1: string;
    name: string;
    address_line_2: string;
    id?: string;
    postal_code: string;
    region: string;
    billable?: boolean;
    email?: string;
  } = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    postal_code: '',
    region: '',
    ...paymentProfile,
    ...paymentProfile.address,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: postPaymentProfileValidationSchema,
    onSubmit: values => {
      submit(values);
      hideModal();
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
  } = formik;

  const handleChangeId = (event: any) => {
    const idValue = event.target.value;
    const idValidation = newIdValidator(idValue);

    if (idValidation.valid) {
      setIdErrorMessage('');
    } else {
      setIdErrorMessage(idValidation.error_message);
    }

    setFieldValue('id', event.target.value);
  };

  const showError = (field: string): boolean => {
    return (errors[field as keyof typeof errors] &&
      touched[field as keyof typeof touched]) as boolean;
  };

  const errorMessage = (field: string): string | null => {
    return touched[field as keyof typeof touched]
      ? (errors[field as keyof typeof errors] as string)
      : null;
  };

  const disabledIdField =
    paymentProfile?.id !== '' || fieldProps.form_fields.id.disabled;

  return (
    <form onSubmit={handleSubmit}>
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.LoadingSpinner active={isLoading} />
        <M.ModalHeader closeModal={hideModal}>
          <h2>{fieldProps.form_label}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <M.Grid>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='id'
                  name='id'
                  data-testid='input-id'
                  labelText={t(fieldProps.form_fields.id.label)}
                  placeholder={t(fieldProps.form_fields.id.placeholder)}
                  value={values.id}
                  onChange={(e: any) => handleChangeId(e)}
                  onBlur={handleBlur}
                  invalid={idErrorMessage !== '' || showError('id')}
                  disabled={disabledIdField}
                  invalidText={idErrorMessage || errorMessage('id')}
                />
              </GridCol>
              <GridCol>
                <StyledInput
                  id='name'
                  name='name'
                  labelText={t(fieldProps.form_fields.name.label)}
                  placeholder={t(fieldProps.form_fields.name.placeholder)}
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('name')}
                  invalidText={errorMessage('name')}
                  disabled={fieldProps.form_fields.name.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='email'
                  name='email'
                  labelText={t(fieldProps.form_fields.email.label)}
                  placeholder={t(fieldProps.form_fields.email.placeholder)}
                  value={values.email || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('email')}
                  invalidText={errorMessage('email')}
                  disabled={fieldProps.form_fields.email.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='address_line_1'
                  name='address_line_1'
                  labelText={t(fieldProps.form_fields.address_line_1.label)}
                  placeholder={t(
                    fieldProps.form_fields.address_line_1.placeholder,
                  )}
                  value={values.address_line_1 || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_1')}
                  invalidText={errorMessage('address_line_1')}
                  disabled={fieldProps.form_fields.address_line_1.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='address_line_2'
                  name='address_line_2'
                  labelText={t(fieldProps.form_fields.address_line_2.label)}
                  placeholder={t(
                    fieldProps.form_fields.address_line_2.placeholder,
                  )}
                  value={values.address_line_2 || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_2')}
                  invalidText={errorMessage('address_line_2')}
                  disabled={fieldProps.form_fields.address_line_2.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='city'
                  name='city'
                  labelText={t(fieldProps.form_fields.city.label)}
                  placeholder={t(fieldProps.form_fields.city.placeholder)}
                  value={values.city || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('city')}
                  invalidText={errorMessage('city')}
                  disabled={fieldProps.form_fields.city.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol style={{ marginRight: '16px' }}>
                <StyledInput
                  id='region'
                  name='region'
                  labelText={t(fieldProps.form_fields.region.label)}
                  placeholder={t(fieldProps.form_fields.region.placeholder)}
                  value={values.region || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('region')}
                  invalidText={errorMessage('region')}
                  disabled={fieldProps.form_fields.region.disabled}
                />
              </GridCol>
              <GridCol>
                <StyledInput
                  id='postal_code'
                  name='postal_code'
                  labelText={t(fieldProps.form_fields.postal_code.label)}
                  placeholder={t(
                    fieldProps.form_fields.postal_code.placeholder,
                  )}
                  value={values.postal_code || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('postal_code')}
                  invalidText={errorMessage('postal_code')}
                  disabled={fieldProps.form_fields.postal_code.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledSelect
                  id='country'
                  name='country'
                  labelText={t(fieldProps.form_fields.country.label)}
                  placeholder={
                    values.country
                      ? ''
                      : t(fieldProps.form_fields.country.placeholder)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('country')}
                  invalidText={errorMessage('country')}
                  defaultValue={values.country || 'placeholder-item'}
                  disabled={fieldProps.form_fields.country.disabled}
                >
                  <M.Select.Item
                    disabled
                    value='placeholder-item'
                    text={t(fieldProps.form_fields.country.placeholder)}
                  />
                  <M.Select.Item value='US' text='United States' />
                  <M.Select.Item value='CA' text='Canada' />
                </StyledSelect>
              </GridCol>
            </GridRow>
          </M.Grid>
        </M.ModalBody>
        <M.ModalFooter>
          <M.Button
            onClick={() => {
              formik.resetForm();
              setIdErrorMessage('');
              hideModal();
            }}
            kind='secondary'
            data-testid='cancel-btn'
          >
            {t(`billing_address.add_address_form.cancel_btn`)}
          </M.Button>
          <M.Button
            type='submit'
            data-testid='submit-btn'
            disabled={!formik.isValid}
          >
            {t(`billing_address.add_address_form.continue_btn`)}
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>
    </form>
  );
};

export default PaymentProfileForm;
