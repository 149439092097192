import AssignedStatus from 'modules/assess/ui/rulesets/AssignedStatus';
import UnassignedStatus from 'modules/assess/ui/rulesets/UnassignedStatus';
import React from 'react';

export type Props = {
  active_rulesets_count?: number;
};

const ItemAssignedStatus: React.FC<Props> = ({ active_rulesets_count }) => {
  if (typeof active_rulesets_count !== 'number') {
    return null;
  }

  return active_rulesets_count > 0 ? (
    <AssignedStatus count={active_rulesets_count} />
  ) : (
    <UnassignedStatus />
  );
};

export default ItemAssignedStatus;
