import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { CountyCriminalSearchWithEta } from 'types';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';
import { parseDateString, getEstimateKey } from './utils';

import CourtTypeTooltip from './CourtTypeTooltip';
import PointerList from '../../PointerList';

const getScreeningProps = (
  screening: CountyCriminalSearchWithEta,
  createdAt?: string | null,
) => {
  const { county, state, prediction_datetime, court_access_method, hasEta } =
    screening;

  const title = `${county}, ${state}`;
  const estimateValue = hasEta
    ? parseDateString(prediction_datetime)
    : parseDateString(createdAt);
  const items = [
    {
      itemKey: `${getEstimateKey(screening)}:`,
      itemValue: estimateValue,
    },
    {
      itemKey: 'County type:',
      itemValue: <CourtTypeTooltip courtType={court_access_method} />,
    },
  ];

  return {
    title,
    items,
  };
};

type Props = {
  screenings?: CountyCriminalSearchWithEta[];
  createdAt?: string | null;
};

const AdditionalInfoWithEta: React.FC<Props> = ({ screenings, createdAt }) => {
  return (
    <>
      {screenings?.map(screening => {
        const { title, items } = getScreeningProps(screening, createdAt);
        const { screening_pointers } = screening;
        const status = getResultOrStatus(screening);
        const isScreeningComplete = status !== ReportStatuses.PENDING;
        const shouldAddPointers = screening_pointers && isScreeningComplete;

        // Add pointers to Additional Info items when flag is on and screening is complete
        if (shouldAddPointers) {
          items.push({
            itemKey: 'Pointer(s):',
            itemValue: <PointerList pointers={screening_pointers} />,
          });
        }

        return (
          <M.AdditionalInfo
            data-testid='eta-additional-info'
            key={title}
            title={title}
            items={items}
            type='shadow'
          />
        );
      })}
    </>
  );
};

export default AdditionalInfoWithEta;
