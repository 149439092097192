import React, { useMemo, useContext, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getStatusTypeForReport,
  hasPermission,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useReportLoading, useReport, useExceptions } from 'containers/Report';
import { useComponents, useReportTabs } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import HealthScreeningReorderNotification from 'components/Report/Screenings/DrugHealthScreenings/ExamReorder/notification';
import {
  Tabs,
  TabComponents,
  useReportTabsIndices,
  useUpdateReportTab,
} from 'modules/report';

const TabContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const Body = () => {
  const isLoading = useReportLoading();
  const report = useReport();
  const componentsContext = useComponents();
  const currentUser = useUser();
  const reportExceptions = useExceptions();
  const { isIframe, isStandaloneIframe: standalone } = useContext(UIContext);
  const isStandaloneIframe = !isIframe || standalone;
  const { tab, selectedIndex } = useReportTabs();

  const visibleTabs = useMemo(
    () => Object.values(Tabs).filter(tab => TabComponents[tab].when(report)),
    [report],
  );

  const tabIndices = useReportTabsIndices(report);
  const updateTab = useUpdateReportTab(report);

  const components = useMemo(() => TabComponents[tab].components || [], [tab]);

  const reportStatusType = useMemo(
    // @ts-ignore TODO: Update to consume Report type from Utils once is updated
    () => getStatusTypeForReport(report, currentUser),
    [report, currentUser],
  );

  const canViewReportTabs = useMemo(
    () =>
      Object.values(Tabs).filter(tab => TabComponents[tab].when(report))
        .length > 1, // Background Report is always visible, show tabs when others are as well
    [report],
  );

  const showTabSelector = // The components should always show, only the tab selector itself is conditionally shown
    (isStandaloneIframe || // Standalone context: can always see it
      componentsContext.includes('screenings_and_tabs')) && // Iframe context: only show when flagred component prop is passed
    canViewReportTabs; // Only show the tab selector when permissioned and a screening exists that needs a separate tab

  const tabsRef = React.useRef<HTMLDivElement>(null);
  const prevTabValueRef = React.useRef<string | null>(null);

  useEffect(() => {
    if (prevTabValueRef.current !== null && tabsRef.current) {
      tabsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    prevTabValueRef.current = tab;
  }, [tab]);

  if (
    !(
      hasPermission(currentUser, 'read_reports') ||
      hasPermission(currentUser, 'read_full_reports')
    )
  )
    return null;

  return isLoading ? null : (
    <>
      <HealthScreeningReorderNotification
        {...{ report, tabIndices, tab, updateTab, ref: tabsRef }}
      />
      {showTabSelector && (
        <TabContainer ref={tabsRef}>
          <M.Tabs selectedIndex={selectedIndex} onChange={updateTab}>
            <M.TabList>
              {visibleTabs.map(tab => (
                <M.Tab key={tab}>{tab}</M.Tab>
              ))}
            </M.TabList>
          </M.Tabs>
        </TabContainer>
      )}
      {components.length > 0 && (
        <div id='report-body-screenings'>
          {components.map(Component => (
            <Component
              {...{
                report,
                reportStatusType,
                reportExceptions,
              }}
              key={Component.name}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Body;
