import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import LoadingCell from '../LoadingCell';

const CopyField = styled(M.CopyField)`
  pre {
    width: 200px;
  }
`;

const WebhookEntry = ({
  getRowProps,
  getSelectionProps,
  row,
  processing,
  confirmDelete,
}) => {
  const webhookUrl = row.cells[0].value;
  const included = row.cells[1].value;
  const { id } = row;

  return (
    <M.TableRow key={id} {...getRowProps({ row })}>
      <M.TableSelectRow {...getSelectionProps({ row })} />
      <M.TableCell>
        <CopyField text={webhookUrl} />
      </M.TableCell>
      <M.TableCell>{included ? 'Included' : 'Not Included'}</M.TableCell>
      <M.TableCell style={{ textAlign: 'right' }}>
        {processing ? (
          <LoadingCell />
        ) : (
          <M.DeleteButton name={id} onClick={confirmDelete} />
        )}
      </M.TableCell>
    </M.TableRow>
  );
};

WebhookEntry.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any.isRequired,
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  getRowProps: PropTypes.func.isRequired,
  getSelectionProps: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default WebhookEntry;
