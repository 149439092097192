import { createContext, useContext } from 'react';
import { DOMESTIC_GEO, CreatePackageContextType } from './utils';

const CreatePackageContext = createContext<CreatePackageContextType>({
  reducerState: {
    addedScreeningTypes: [],
    additionalProperties: {},
    newPackageName: '',
    newPackageNameInvalid: true,
    basePackage: {
      name: '',
      id: '',
      screenings: [],
    },
    selectedGeo: DOMESTIC_GEO,
    error: '',
    aliasesEnabled: 'off',
    aliasPrice: 0,
  },
} as unknown as CreatePackageContextType);

CreatePackageContext.displayName = 'Create Package Context';

export const useCreatePackageContext = () => {
  return useContext(CreatePackageContext);
};

export default CreatePackageContext;
