import { AnyQueryKey, useQuery } from 'react-query';

import { fetchCandidateDocuments } from './actions';

/**
 * Retrieve documents belonging to the Candidate (e.g. Driver's License)
 * (in contrast to useList, which gets Checkr-made documents)
 */
const useFetchCandidateDocuments = (candidateId: string) => {
  const params = {
    types: [
      'driver_license',
      'previous_driver_license',
      'driver_license_back',
      'selfie',
    ],
  };

  const key: AnyQueryKey = ['documents/list', { id: candidateId }];

  const request = () => fetchCandidateDocuments({ candidateId, params });

  const result = useQuery(key, request, { refetchOnWindowFocus: false });

  return result?.data?.data;
};

export default useFetchCandidateDocuments;
