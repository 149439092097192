import React from 'react';
import { FieldComponentProps } from '../../types';
import Date from './date';

const UpgradedAt: React.FC<Pick<FieldComponentProps, 'report' | 'testid'>> = ({
  report,
  testid,
}) => {
  return <Date report={report} fieldKey='upgraded_at' testid={testid} />;
};

export default UpgradedAt;
