import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { useClinicLocations } from '../../../api/healthScreening/hooks';
import {
  Search,
  SkeletonContainer,
  LocationPanelList,
  LoadingBlock,
} from '../styles';
import { convertCaseInArray } from '../utils';
import { Location, Props, Field } from '../types';
import Panel from './Panel';

const LocationPicker: React.FC<Props> = ({
  accountId,
  examSlugs,
  packageId,
  testPackage,
  requiresObservedCollection,
  state,
  updateState,
  translations,
}) => {
  const { zipcode } = state;
  const {
    search: { title, placeholder, error: error_msg, clinic },
  } = translations;
  const {
    data: locations = [],
    error,
    isLoading,
  } = useClinicLocations(
    zipcode as string,
    examSlugs,
    accountId,
    packageId,
    testPackage,
    requiresObservedCollection,
  );

  const handleZipcodeChange = useCallback(
    (event: React.BaseSyntheticEvent) =>
      updateState(
        { ...state, [Field.Zipcode]: event?.target?.value },
        Field.Zipcode,
      ),
    [state, updateState],
  );

  return (
    <section>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor='zipcode'>{title}</label>
      <Search
        placeholder={placeholder}
        searchId='zipcode'
        onChange={handleZipcodeChange}
        value={zipcode}
      />
      {isLoading && (
        <SkeletonContainer data-testid='loading-container'>
          <LoadingBlock />
          <LoadingBlock />
          <LoadingBlock />
        </SkeletonContainer>
      )}
      {locations?.length > 0 && (
        <LocationPanelList data-testid='location-panel-list'>
          {convertCaseInArray(locations).map((location: Location) => (
            <Panel
              data-testid={`panel-${location.siteId}`}
              key={location.siteId}
              appState={state}
              updateState={updateState}
              translations={clinic}
              {...location}
            />
          ))}
        </LocationPanelList>
      )}
      {error && (
        <M.InlineNotification
          data-testid='error-notification'
          role='alert'
          iconDescription='indicates error retrieving data'
          kind='error'
          title={error_msg.title}
        >
          {error_msg.description}
        </M.InlineNotification>
      )}
    </section>
  );
};

export default LocationPicker;
