import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { useGetAssessRuleValues } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';
import InputNumericValues from './input-numeric-values';
import InputTextValues from './input-text-values';
import MultiSelectValues from './multi-select-values';
import SingleSelectValues from './single-select-values';

const Values = ({ index }: { index: number }) => {
  const { conditions, selectedItemTypes } = useConditions();
  const condition = conditions[index];
  const { data } = useGetAssessRuleValues(condition.fact, selectedItemTypes());
  const isMultiSelect =
    data?.type === 'array' && data?.operators.includes('in');
  const isSingleSelect =
    (data?.type === 'array' || data?.type === 'boolean') &&
    data?.operators.includes('eq');
  const isInputNumeric =
    data?.type === 'number' && data?.operators.includes('lt');
  const isInputText =
    data?.operators.includes('amri') || data?.operators.includes('mri');

  return (
    <>
      <M.GridCol
        lg={5}
        md={2}
        sm={1}
        data-testid='assess-rules-new-condition-values-field'
      >
        {isMultiSelect && <MultiSelectValues index={index} />}
        {isSingleSelect && <SingleSelectValues index={index} />}
        {isInputNumeric && <InputNumericValues index={index} />}
      </M.GridCol>

      {isInputText && (
        <M.GridCol>
          <InputTextValues index={index} />
        </M.GridCol>
      )}
    </>
  );
};

export default Values;
