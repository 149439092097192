/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { API, AmsAPI, extractError } from 'utils/APIClient';

import {
  FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST,
  FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS,
  FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  EDIT_USER_ROLES_REQUEST,
  EDIT_USER_ROLES_SUCCESS,
  EDIT_USER_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_GEO_SUBSCRIPTIONS_REQUEST,
  FETCH_GEO_SUBSCRIPTIONS_SUCCESS,
  FETCH_GEO_SUBSCRIPTIONS_FAILURE,
  UPDATE_GEO_SUBSCRIPTIONS_REQUEST,
  UPDATE_GEO_SUBSCRIPTIONS_SUCCESS,
  UPDATE_GEO_SUBSCRIPTIONS_FAILURE,
  UPDATE_SINGLE_GEO_SUBSCRIPTION,
} from './ActionTypes';
import { toastError, toastSuccess } from './ToastActions';

export const fetchAmsUsersRequest = () => ({
  type: FETCH_AMS_USERS_FOR_ACCOUNT_REQUEST,
});
export const fetchAmsUsersSuccess = users => ({
  type: FETCH_AMS_USERS_FOR_ACCOUNT_SUCCESS,
  payload: users,
});
export const fetchAmsUsersFailure = error => ({
  type: FETCH_AMS_USERS_FOR_ACCOUNT_FAILURE,
  payload: error,
});

export const inviteUserRequest = () => ({ type: INVITE_USER_REQUEST });
export const inviteUserSuccess = user => ({
  type: INVITE_USER_SUCCESS,
  payload: user,
});
export const inviteUserFailure = error => ({
  type: INVITE_USER_FAILURE,
  payload: error,
});

export const editUserRolesRequest = (userId, roles) => ({
  type: EDIT_USER_ROLES_REQUEST,
  userId,
  roles,
});
export const editUserRolesSuccess = userId => ({
  type: EDIT_USER_ROLES_SUCCESS,
  userId,
});
export const editUserRolesFailure = (userId, msg) => ({
  type: EDIT_USER_ROLES_FAILURE,
  userId,
  msg,
});

export const fetchRolesRequest = () => ({ type: FETCH_ROLES_REQUEST });
export const fetchRolesSuccess = roles => ({
  type: FETCH_ROLES_SUCCESS,
  roles,
});
export const fetchRolesFailure = error => ({
  type: FETCH_ROLES_FAILURE,
  error,
});

export const deleteUserRequest = userId => ({
  type: DELETE_USER_REQUEST,
  userId,
});
export const deleteUserSuccess = userId => ({
  type: DELETE_USER_SUCCESS,
  userId,
});
export const deleteUserFailure = (userId, msg) => ({
  type: DELETE_USER_FAILURE,
  userId,
  msg,
});

export const fetchGeoSubscriptionsRequest = userId => ({
  type: FETCH_GEO_SUBSCRIPTIONS_REQUEST,
  userId,
});
export const fetchGeoSubscriptionsSuccess = (userId, geoSubscriptions) => ({
  type: FETCH_GEO_SUBSCRIPTIONS_SUCCESS,
  userId,
  geoSubscriptions,
});
export const fetchGeoSubscriptionsFailure = msg => ({
  type: FETCH_GEO_SUBSCRIPTIONS_FAILURE,
  msg,
});

export const updateGeoSubscriptionsRequest = userId => ({
  type: UPDATE_GEO_SUBSCRIPTIONS_REQUEST,
  userId,
});
export const updateGeoSubscriptionsSuccess = userId => ({
  type: UPDATE_GEO_SUBSCRIPTIONS_SUCCESS,
  userId,
});
export const updateGeoSubscriptionsFailure = msg => ({
  type: UPDATE_GEO_SUBSCRIPTIONS_FAILURE,
  msg,
});

export const updateSingleGeoSubscription = (userId, itemIndex, itemValue) => ({
  type: UPDATE_SINGLE_GEO_SUBSCRIPTION,
  userId,
  itemIndex,
  itemValue,
});

export const getRoles = currentUser => dispatch => {
  dispatch(fetchRolesRequest());

  AmsAPI.get(`/api/public/v1/roles`)
    .then(({ roles }) => {
      const showInternal = currentUser.permissions.manage_users;
      const segmentationEnabled = currentUser.account.segmentation_enabled;
      const filteredRoles = [...roles]
        .filter(role => showInternal || !role.is_internal)
        .filter(role => segmentationEnabled || role.name !== 'restricted_admin')
        .map(role => role);
      dispatch(fetchRolesSuccess(filteredRoles));
    })
    .catch(error => dispatch(fetchRolesFailure(error)));
};

export const fetchUsersForAccount =
  (accountId, params = {}) =>
  dispatch => {
    dispatch(fetchAmsUsersRequest());

    params.pagination = true;
    AmsAPI.get(`/api/public/v1/accounts/${accountId}/users`, { params })
      .then(res => dispatch(fetchAmsUsersSuccess(res)))
      .catch(e => {
        const error = extractError(e);
        dispatch(toastError('Error fetching users', Array(error).join(', ')));
        dispatch(fetchAmsUsersFailure(e));
      });
  };

export const inviteUser = (account_id, email, full_name) => dispatch => {
  dispatch(inviteUserRequest());
  AmsAPI.post(`/api/public/v1/users`, { email, account_id, full_name })
    .then(res => {
      dispatch(toastSuccess('Invitation sent'));
      dispatch(inviteUserSuccess(res));
    })
    .catch(e => {
      const error = extractError(e);
      dispatch(toastError('Error inviting user', Array(error).join(', ')));
      dispatch(inviteUserFailure(e));
    });
};

export const editUser = (userId, roles) => dispatch => {
  dispatch(editUserRolesRequest(userId, roles));

  AmsAPI.patch(`/api/public/v1/users/${userId}`, {
    roles,
  })
    .then(() => dispatch(editUserRolesSuccess(userId)))
    .catch(e => dispatch(editUserRolesFailure(userId, e.message)));
};

/**
 * @name deleteUser
 * @function
 * @memberOf AccountUsersActions
 * @description Delete a user
 * @returns {promise}
 * @param {string} userId - The user id
 */
export const deleteUser = userId => async dispatch => {
  dispatch(deleteUserRequest(userId));
  AmsAPI.delete(`/api/public/v1/users/${userId}`, {})
    .then(() => dispatch(deleteUserSuccess(userId)))
    .catch(e => {
      const error = extractError(e);
      dispatch(toastError('Error deleting user', Array(error).join(', ')));
      dispatch(deleteUserFailure(userId, e.message));
    });
};

/**
 * @name fetchGeoSubscriptions
 * @function
 * @memberOf AccountUsersActions
 * @description Fetch a user's geo subscriptions
 * @returns {promise}
 * @param {string} userId - The user id
 */
export const fetchGeoSubscriptions = userId => async dispatch => {
  dispatch(fetchGeoSubscriptionsRequest(userId));
  API.get(`/users/${userId}/geo_subscriptions`)
    .then(geoSubscriptions =>
      dispatch(fetchGeoSubscriptionsSuccess(userId, geoSubscriptions)),
    )
    .catch(e => dispatch(fetchGeoSubscriptionsFailure(e)));
};

/**
 * @name updateGeoSubscriptions
 * @function
 * @memberOf AccountUsersActions
 * @description Save to the api
 * @param {string} userId - The user id
 * @param {array} subscriptions - The list of geo subscriptions
 */
export const updateGeoSubscriptions =
  (userId, subscriptions) => async dispatch => {
    dispatch(updateGeoSubscriptionsRequest(userId));
    API.put(`/users/${userId}/geo_subscriptions`, {
      geo_subscriptions: subscriptions,
    })
      .then(() => dispatch(updateGeoSubscriptionsSuccess(userId)))
      .catch(e => dispatch(updateGeoSubscriptionsFailure(e)));
  };
