/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** *
//* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { windowResize } from 'actions';

const mapStateToProps = ({ ui: { context } }) => ({ context });

const mapDispatchToProps = dispatch => ({
  windowResize: () => dispatch(windowResize()),
});

export default function ContextContainer(Wrapped) {
  class ContextWrapper extends Component {
    componentDidMount() {
      const {
        props: { windowResize },
        isContextIframe,
      } = this;
      if (isContextIframe()) {
        windowResize();
        window.addEventListener('resize', windowResize);
      }
    }

    componentDidUpdate() {
      const {
        props: { windowResize },
        isContextIframe,
      } = this;
      isContextIframe() && windowResize();
    }

    componentWillUnmount() {
      this.isContextIframe() &&
        // eslint-disable-next-line react/destructuring-assignment
        window.removeEventListener('resize', this.props.windowResize);
    }

    // eslint-disable-next-line react/destructuring-assignment
    isContextIframe = () => this.props.context === 'iframe';

    render() {
      return <Wrapped {...this.props} />;
    }
  }

  ContextWrapper.propTypes = {
    context: PropTypes.string,
    windowResize: PropTypes.func.isRequired,
  };

  ContextWrapper.defaultProps = {
    context: null,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ContextWrapper);
}
