import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation, queryCache } from 'react-query';
import usePatchUtmParams from './usePatchUtmParams';

import useGetUser from './useGetUser';
import usePostOauth from './usePostOauth';

const postPaymentMethod = async (stripeId: any, id: string) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/billing/accounts/${id}/payment_methods`,
    { stripe_id: stripeId, set_as_default: true },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

function usePostPaymentMethod(onSuccess: () => void) {
  const { data: user } = useGetUser();
  const { mutate: patchUtmParams } = usePatchUtmParams();
  const { mutate: postOauth } = usePostOauth();

  const [mutate, result] = useMutation(
    async ({ stripeId }: { stripeId: string }) => {
      const response = await postPaymentMethod(stripeId, user.account.id);
      return { res: response.data };
    },
    {
      onSuccess: async (data: any) => {
        onSuccess();
        await patchUtmParams({ accountId: user.account.id });
        await postOauth({ clientId: user.account.oauth_progress.client_id });
        queryCache.invalidateQueries('signup-packages');
        const signup = JSON.parse(sessionStorage.getItem('signup') || '{}');
        signup.last4 = data.res.last4;
        sessionStorage.setItem('signup', JSON.stringify(signup));
        window.dispatchEvent(new Event('storage'));
      },
    },
  );

  const { isLoading, error } = result;

  return {
    mutate,
    data: result,
    isLoading,
    error,
  };
}

export default usePostPaymentMethod;
