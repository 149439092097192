import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Timeline from 'components/Timeline/Timeline';
import { useRouteMatch } from 'react-router-dom';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const Row = styled(M.GridRow)`
  margin-bottom: 1.5rem;
`;

const TimelineContainer = styled.div`
  max-height: '70vh';
  overflow-x: auto;
  width: 100%;
`;

interface ReportActionLogProps {
  style?: React.CSSProperties;
}

const ReportActionLog: React.FC<ReportActionLogProps> = ({ style }) => {
  // Figure out the currently selected report to mark it active
  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  // Figure out the currently selected invitation to mark it active
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationOrReportId =
    reportMatch?.params?.reportId || invitationMatch?.params?.invitationId;

  if (invitationOrReportId) {
    return (
      <Row>
        <TimelineContainer style={style}>
          <Timeline
            data-testid='timeline'
            contextName='reports'
            contextId={invitationOrReportId}
          />
        </TimelineContainer>
      </Row>
    );
  }

  return null;
};

export default ReportActionLog;
