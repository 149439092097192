import React from 'react';
import AdverseActionRate from '../../sections/AdverseActionRate';
import Benefits from '../../sections/Benefits';
import Description from '../../sections/Intro';
import EngageMoreCandidates from '../../sections/EngageMoreCandidates';
import HowToEvaluateBackgroundChecks from '../../sections/HowToEvaluateBackgroundChecks';

const Midmarket: React.FC = () => {
  return (
    <div data-testid='fair-chance-midmarket-container'>
      <Description />
      <AdverseActionRate emptyAdverseActionData />
      <HowToEvaluateBackgroundChecks />
      <EngageMoreCandidates />
      <Benefits />
    </div>
  );
};

export default Midmarket;
