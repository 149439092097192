import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'modules/assess/api';
import {
  Fact as FactType,
  getFactByNamespace,
} from 'modules/assess/models/fact';
import { FactRule, getValidFacts } from 'modules/assess/models';
import {
  AddButton,
  Condition,
  getFactsByNamespace,
} from 'modules/assess/ui/rules/common/rule/editor/conditions';
import { Field, Row } from 'modules/assess/ui/rules/common/rule';
import Kind from 'modules/assess/models/rules/common/kind';
import {
  OptionValues,
  useOptionValuesConfig,
} from 'modules/assess/ui/rules/common/rule/editor/conditions/context';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

type Props = {
  namespace: Namespace;
};

const Conditions: React.FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('');
  const label = t('nouns.condition_plural');
  const {
    conditions,
    updateConditionByIndex,
    insertCondition,
    deleteConditionByIndex,
  } = useConditions();

  const addCondition = useCallback(
    (fact?: FactType) => insertCondition({ fact }),
    [insertCondition],
  );
  const deleteCondition = useCallback(
    (index: number) => deleteConditionByIndex(index),
    [deleteConditionByIndex],
  );
  const editCondition = useCallback(
    (index: number, condition: Partial<FactRule>) =>
      updateConditionByIndex(index, condition),
    [updateConditionByIndex],
  );

  const factsUsed = conditions.map(c => c.fact);
  const Fact = getFactByNamespace(namespace);
  const selectedTargetCondition = conditions.find(c => c.fact === Fact.TYPE);
  const selectedTarget = selectedTargetCondition?.value;
  const targets = Array.isArray(selectedTarget)
    ? selectedTarget
    : [selectedTarget];

  const optionValues = useOptionValuesConfig(namespace, targets);

  const FACTS = getFactsByNamespace(namespace);
  let filteredFacts: Array<FactType | undefined> = FACTS;
  if (targets) {
    filteredFacts = getValidFacts(targets as any, namespace);
  }

  filteredFacts = filteredFacts.filter(f => f !== Fact.COUNT);

  const factsFree = FACTS.filter(
    (f: any) =>
      f !== Fact.TYPE && !factsUsed.includes(f) && filteredFacts.includes(f),
  );

  const addButtonDisabled =
    !Array.isArray(selectedTarget) || !selectedTarget.length;

  return (
    <OptionValues.Provider value={optionValues}>
      <Row>
        <M.GridCol data-testid='assess-rules-count-rule-editor-rule-conditions'>
          <Field label={label} style={{ marginBottom: '1.5em' }} />
        </M.GridCol>
      </Row>

      {conditions.map((condition, i) =>
        condition.fact === Fact.TYPE || condition.fact === Fact.COUNT ? null : (
          <Condition
            index={i}
            key={condition.fact || i}
            condition={condition as Partial<FactRule>}
            namespace={namespace}
            facts={factsFree}
            kind={Kind.COUNT}
            deleteCondition={deleteCondition}
            editCondition={editCondition}
          />
        ),
      )}

      <AddButton disabled={addButtonDisabled} addCondition={addCondition} />
    </OptionValues.Provider>
  );
};

export default Conditions;
