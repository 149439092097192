import { Fact } from './fact';
import { RecordType } from './record';

export const TYPE_FACTS: Record<RecordType, Array<Fact>> = {
  [RecordType.ACCIDENT]: [
    Fact.AGE_NOW,
    Fact.CATEGORY,
    Fact.CLASSIFICATION_CODE,
    Fact.DESCRIPTION,
    Fact.RELATIVE_CONTEXT_DATE_IN_DAYS,
  ],
  [RecordType.LICENSE]: [
    Fact.AGE_NOW,
    Fact.CURRENT,
    Fact.EXPIRATION_EXTENDED,
    Fact.LICENSE_CLASS,
    Fact.LICENSE_TYPE,
    Fact.PRIVILEGE_TO_DRIVE,
    Fact.RELATIVE_EXPIRATION_DATE_IN_DAYS,
    Fact.RESTRICTED_LICENSE_STATUS,
    Fact.RESTRICTIONS,
    Fact.STATE,
    Fact.STATUS,
    Fact.WORK_STATE,
  ],
  [RecordType.LICENSE_HISTORY]: [Fact.AGE_NOW, Fact.RELATIVE_HISTORY_IN_YEARS],
  [RecordType.SUSPENSION]: [
    Fact.AGE_NOW,
    Fact.CATEGORY,
    Fact.CLASSIFICATION_CODE,
    Fact.DESCRIPTION,
    Fact.RELATIVE_CONTEXT_DATE_IN_DAYS,
    Fact.RELATIVE_END_DATE_IN_YEARS,
    Fact.RELATIVE_START_DATE_IN_YEARS,
  ],
  [RecordType.VIOLATION]: [
    Fact.AGE_AT_ISSUED_DATE,
    Fact.AGE_NOW,
    Fact.CATEGORY,
    Fact.CLASSIFICATION_CODE,
    Fact.DESCRIPTION,
    Fact.POINTS,
    Fact.RELATIVE_CONTEXT_DATE_IN_DAYS,
    Fact.RELATIVE_CONVICTION_DATE_IN_DAYS,
    Fact.RELATIVE_ISSUED_DATE_IN_DAYS,
    Fact.STATE,
  ],
};

export default TYPE_FACTS;
