import React from 'react';
// TODO: migrate this helper to utils
import { getTat } from '@dashboard-experience/mastodon';
import { FieldComponentProps } from '../../types';

const TurnaroundTime: React.FC<
  Pick<FieldComponentProps, 'report' | 'testid'>
> = ({ report, testid }) => {
  const tat = getTat(report.turnaround_time);
  return <div data-testid={testid}>{tat}</div>;
};

export default TurnaroundTime;
