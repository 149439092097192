import { GenericObject } from '@dashboard-experience/utils';
import React, { useContext } from 'react';
import styled from 'styled-components';
import UIContext from '../context/UI';

const Wrapper = styled.div<{ framewidth: number }>`
  #mastodon & {
    margin-bottom: 1.5rem;

    @media (min-width: ${props => props.framewidth}px) {
      margin-bottom: 3rem;
    }

    h2 {
      display: inline-flex;
      align-items: center;
      margin: 0 1rem 0 0;
    }

    &.header-wrap-secondary {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @media (min-width: ${props => props.framewidth}px) {
        flex-direction: row;
      }
    }
  }
`;

type HeaderElement = {
  label: string;
  props?: GenericObject;
};

type Props = {
  title?: HeaderElement;
  headerNode?: any;
  style?: object;
  className?: string;
  dataTestId?: string;
};

export const PageHeaderSingle: React.FC<Props> = ({
  title,
  headerNode,
  style,
  className,
  dataTestId,
}) => {
  const { isIframe } = useContext(UIContext);

  return (
    <Wrapper
      style={style}
      className={className}
      framewidth={isIframe ? 720 : 1200}
      data-testid={dataTestId}
    >
      {title && <h2 {...title?.props}>{title.label}</h2>}
      {headerNode && headerNode}
    </Wrapper>
  );
};

export default PageHeaderSingle;
