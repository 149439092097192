import {
  fetchAllAdverseActions,
  SearchParams,
} from 'modules/adjudication/api/adverse-actions';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'utils/APIClient';
import { useUser } from 'context/CurrentUser';
import { localStorageFallback } from '@dashboard-experience/utils';
import ConfirmModal from '../ConfirmModal';

type PauseReviewModalProps = {
  params: SearchParams;
  onSearchUpdate: Function;
};

const enum Events {
  CONFIRMED = 'confirmed',
  DISMISSED = 'dismissed',
}

const NAMESPACE = 'adverse_actions.notified_of_paused';
const SEARCH_PARAMS = { status: 'paused' };

const setModalViewed = () => {
  localStorageFallback.setItem(NAMESPACE, new Date().toISOString());
};

const shouldBeTriggered = () => {
  const localView = localStorageFallback.getItem(NAMESPACE);
  const lastViewed = localView ? Date.parse(localView) : null;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const diff =
    today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
  const startOfWeek = today.setDate(diff);

  return !lastViewed || lastViewed < startOfWeek;
};

const PauseReviewModal = ({
  params,
  onSearchUpdate,
}: PauseReviewModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const user = useUser();

  const logAction = useCallback(
    (action: Events) => {
      const params = { event: `${NAMESPACE}.${action}` };
      API.post(`/users/${user?.id}/actions`, params);
    },
    [user?.id],
  );

  const getPausedAdverseActions = () => {
    return fetchAllAdverseActions(SEARCH_PARAMS);
  };

  useEffect(() => {
    if (!shouldBeTriggered() || params.status === SEARCH_PARAMS.status) return;

    getPausedAdverseActions().then(adverseActions => {
      if (adverseActions.data.length > 0) {
        setCount(adverseActions.data.length);
        setModalOpen(true);
        setModalViewed();
      }
    });
  }, [params.status]);

  const closeModal = useCallback(() => {
    logAction(Events.DISMISSED);
    setModalOpen(false);
  }, [logAction]);

  const viewPausedReports = useCallback(() => {
    logAction(Events.CONFIRMED);
    setModalOpen(false);
    onSearchUpdate(SEARCH_PARAMS);
  }, [logAction, onSearchUpdate]);

  return (
    <ConfirmModal
      data-testid='review-paused-adverse-action-modal'
      header='Review paused Adverse Action reports'
      message={`You have ${count} reports with paused adverse actions that require review.`}
      open={modalOpen}
      confirmMessage='Review paused reports'
      cancelMessage='Dismiss'
      hideModal={closeModal}
      callback={viewPausedReports}
    />
  );
};

export default PauseReviewModal;
