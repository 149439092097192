import React, { useCallback } from 'react';
import Kind from 'modules/assess/models/rulesets/kind';
import { Actions } from 'modules/assess/ui/state/rulesets/versions/instance';
import { useReadOnly } from 'modules/assess/ui/hooks';
import Description from '../../common/entity/description';
import * as Store from './store';

export type Type = React.FC<Props>;

export type Props = {};

export const DescriptionContainer: Type = props => {
  const state = Store.useState();
  const dispatch = Store.useDispatch();
  const handleSave = useCallback(
    value => {
      const updatedDescription = {
        description: value,
      };
      dispatch(Actions.Update.create(updatedDescription));
    },
    [dispatch],
  );

  return (
    <Description
      value={state.description}
      onSave={handleSave}
      editable={!useReadOnly() && state.kind === Kind.draft}
    />
  );
};

export default DescriptionContainer;
