import { AnyQueryKey, useQuery } from 'react-query';
import getFilePolicy from 'api/filePolicy/actions';

function useFilestackPolicy(filestackKey: string) {
  const key: AnyQueryKey = ['file_policy/get', filestackKey];

  return useQuery(key, () => getFilePolicy(filestackKey), {
    staleTime: 25 * 60 * 1000, // 25 mins
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
  });
}

export default useFilestackPolicy;
