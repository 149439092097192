import React from 'react';
import { FormI9Params, useCreateFormsI9 } from 'api/i9';
import { IFormI9 } from '../Models';

const useSubmitFormsI9 = (successCallback: () => void) => {
  const { formI9CreatedCall } = useCreateFormsI9(successCallback);
  const submit = React.useCallback(
    (formsI9FormValues: IFormI9[]) => {
      const formI9Objects: FormI9Params = {
        params: formsI9FormValues.map(f => ({
          candidate_id: f.candidateId,
          first_name: f.firstName,
          middle_name: f.middleName,
          last_name: f.lastName,
          full_name: f.fullName,
          email: f.email,
          start_date: f.startDate,
          worksite_id: f.worksiteId,
          workflow_type: f.workflowType,
          authorized_representative_first_name:
            f.authorizedRepresentativeFirstName,
          authorized_representative_last_name:
            f.authorizedRepresentativeLastName,
          authorized_representative_email: f.authorizedRepresentativeEmail,
        })),
      };

      formI9CreatedCall(formI9Objects);
    },
    [formI9CreatedCall],
  );

  return submit;
};

export default useSubmitFormsI9;
