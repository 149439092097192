import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useClickOutside } from '@dashboard-experience/utils';
import { ARROW_KEY_CODES } from './utils';

type InputContainerProps = {
  invalid: boolean;
};

type Props = {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickOutside: () => void;
  handleKeyPress?: (event: KeyboardEvent) => void;
  handleOnPaste?: (event: any) => void;
  invalid?: boolean;
  value?: number;
};

const StyledInput = styled(M.NumberInput)<InputContainerProps>`
  height: 100%;
  .cds--number input[type='number'] {
    padding-right: 0 !important;
    min-width: 0;
  }
  .cds--number__controls {
    display: none !important;
  }
  .cds--number__invalid {
    right: 1.15rem !important;
  }
  .cds--number[data-invalid] input[type='number']:focus {
    ${({ invalid }) => {
      if (!invalid) {
        return '';
      }
      return `border-bottom: 1px solid ${colors.uiOrange600} !important;`;
    }}
  }
  .cds--form-requirement {
    font-size: 10px !important;
  }
`;

const Edit: React.FC<Props> = ({
  handleChange,
  handleClickOutside,
  handleKeyPress,
  handleOnPaste,
  invalid,
  value,
}) => {
  const { t } = useTranslation('assess:v2');
  const invalidText: string = t(
    `common.lookback_rules.severity_input.edit.error_message`,
  );

  const ref = useRef<HTMLElement>(null!);
  useClickOutside({
    ref,
    handler: () => {
      handleClickOutside();
    },
  });

  const handleOnKeyDown = useCallback((event: any) => {
    if (ARROW_KEY_CODES.includes(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  return (
    <StyledInput
      disableWheel
      data-testid='assess-v2-ui-common-lookback-rules-severity-input-edit'
      ref={ref}
      invalid={invalid}
      invalidText={invalidText}
      value={value || ''}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onPaste={handleOnPaste}
      onKeyDown={handleOnKeyDown}
      allowEmpty
      hideLabel
      autoFocus
    />
  );
};

export default Edit;
