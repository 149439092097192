import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useSearch } from 'modules/assess/ui/mvr/category-sets/collection/api';
import {
  useState as useStateVersion,
  useDispatch,
} from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rulesets/versions/instance';
import { ID } from 'modules/id';
import { CategoryCodeSetVersionBasic } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import { Link } from 'react-router-dom';
import WrongVersionBanner from './wrong-version-banner';
import { useNamespacedRoute } from '../../router/context';
import CorrectVersionBanner from './correct-version-banner';
import TextSectionWithComponents from '../common/text-section-with-components';

const CCS_PAGE_SIZE = 100;

const DropdownContainer = styled.div`
  margin-top: 20px;
  width: 320px;
`;

export type Props = {
  disabled?: boolean;
  version?: ID;
};

const CategorySetSelect: React.FC<Props> = ({ disabled, version }) => {
  const { data } = useSearch({ per_page: CCS_PAGE_SIZE });
  const { category_code_set_version } = useStateVersion();
  const [showBanner, setShowBanner] = useState(false);
  const dispatch = useDispatch();
  const categorySets = useMemo(() => data?.data || [], [data]);
  const items = categorySets.map(categorySet => ({
    id: categorySet.id,
    label: categorySet.name,
    versions: categorySet.versions,
  }));
  const selectedCategorySet = items.find(
    item => item.id === category_code_set_version?.category_code_set?.id,
  );
  const activeCategoryVersion = selectedCategorySet?.versions?.find(
    version => version.active,
  );

  const path = useNamespacedRoute('categories');
  const components = {
    'categories-link': <Link to={path}>MVR Categories</Link>,
  };
  const updateVersion = useCallback(
    (category_code_set_version: CategoryCodeSetVersionBasic | undefined) => {
      dispatch(
        Actions.Update.create({
          // @ts-ignore
          category_code_set_version,
        }),
      );
      setShowBanner(true);
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      activeCategoryVersion &&
      activeCategoryVersion.id !== version &&
      !disabled
    ) {
      updateVersion(activeCategoryVersion);
    }
  }, [activeCategoryVersion, disabled, updateVersion, version]);

  const onChange = useCallback(
    ({ selectedItem: { id } }) => {
      const categorySet = categorySets.find(cs => cs.id === id);
      const version = categorySet?.versions?.find(ccsv => ccsv.active);
      updateVersion(version);
    },
    [categorySets, updateVersion],
  );

  return (
    <div data-testid='assess-ui-rules-categories-category-set-select'>
      {showBanner && activeCategoryVersion && (
        <WrongVersionBanner
          activeCategoryVersion={activeCategoryVersion}
          categoryName={selectedCategorySet?.label}
        />
      )}
      <TextSectionWithComponents
        components={components}
        tkey='rules.categories.category-set-select'
      />
      {activeCategoryVersion?.id === version && (
        <CorrectVersionBanner
          components={components}
          categoryName={selectedCategorySet?.label}
        />
      )}
      <DropdownContainer>
        <M.Dropdown
          id='assess-rules-category-set-select'
          data-testid='assess-rules-category-set-select'
          disabled={disabled}
          items={items}
          onChange={onChange}
          selectedItem={selectedCategorySet}
        />
      </DropdownContainer>
    </div>
  );
};

export default CategorySetSelect;
