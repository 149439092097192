import * as Yup from 'yup';

enum ValidationErrors {
  REQUIRED = 'Required field',
  MIN_NUMBER = 'The number cannot be less than 0',
  MAX_PERCENTAGE = 'The number cannot be larger than 100',
  ALTERNATE_LESS_THAN_PRIMARY = 'Alternate number should be less than primary number',
  UNIQUE_NAME = 'The pool should have a unique name',
}

const newCohortValidations = Yup.object().shape({
  cohortName: Yup.string().required(ValidationErrors.REQUIRED),
  isDOTCompliant: Yup.string().required(ValidationErrors.REQUIRED),
  dotComplianceAgency: Yup.string().when('isDOTCompliant', {
    is: 'true',
    then: Yup.string().required(ValidationErrors.REQUIRED),
    otherwise: Yup.string(),
  }),
  drugScreening: Yup.string()
    .nullable()
    .when('drugAlcholSelection', {
      is: (value: string) => value === 'DRUG_ONLY' || value === 'DRUG_ALCOHOL',
      then: Yup.string().required(ValidationErrors.REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
  alcoholScreening: Yup.string()
    .nullable()
    .when('drugAlcholSelection', {
      is: (value: string) =>
        value === 'ALCOHOL_ONLY' || value === 'DRUG_ALCOHOL',
      then: Yup.string().required(ValidationErrors.REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
  csvFilePath: Yup.string(),
});

export { ValidationErrors };
export default newCohortValidations;
