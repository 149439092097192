import {
  CURRENT_USER_ACCOUNT_UPDATE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  NOTIFICATIONS_SETTINGS_UPDATE,
} from 'actions/ActionTypes';

import { httpHelper } from './reducerHelpers';

export const initialState = {
  fetchRequest: { ...httpHelper.initialState },
  currentUser: {},
  needsAuth: true,
};

// eslint-disable-next-line default-param-last
const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onRequest },
      };

    case FETCH_USER_SUCCESS: {
      const { currentUser } = action;

      return {
        ...state,
        fetchRequest: { ...httpHelper.onSuccess },

        currentUser,
        needsAuth: false,
      };
    }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onFailure },

        needsAuth: true,
      };

    case CURRENT_USER_ACCOUNT_UPDATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          account: {
            ...state.currentUser.account,
            ...action.payload,
          },
        },
      };

    case NOTIFICATIONS_SETTINGS_UPDATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: {
            ...state.currentUser.settings,
            ...action.settings,
          },
        },
      };

    default:
      return state;
  }
};

export const getCurrentUser = state => state.currentUser?.currentUser;
export const getCurrentUserAccount = state => getCurrentUser(state)?.account;

export default currentUserReducer;
