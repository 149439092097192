import React, { useEffect, useState, useCallback } from 'react';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { M } from '@dashboard-experience/mastodon';
import { useGetPartnerApplications } from 'api/partners/hooks';
import ApplicationDetails from './ApplicationDetails';
import NewApplicationModal from './NewApplicationModal';
import {
  ApplicationsContainer,
  CreateApplicationButton,
} from './DirectoryStyledComponents';

const PartnerApplicationIndex: React.FC = () => {
  const [showAddApplication, setShowAddApplication] = useState<boolean>(false);
  const accountId = useUserAccount()?.id || '';

  const { data, refetch } = useGetPartnerApplications(accountId);
  const [applications, setApplications] = useState<any[]>([]);

  useEffect(() => {
    setApplications(data?.applications);
  }, [data]);

  const refetchPage = useCallback(() => {
    if (applications?.length > 0) {
      refetch();
    }
  }, [applications?.length, refetch]);

  const setOpenModal = useCallback(() => {
    setShowAddApplication(true);
  }, []);

  const setCloseAddModal = useCallback(() => {
    setShowAddApplication(false);
  }, []);

  return (
    <M.Container>
      <ApplicationsContainer>
        <CreateApplicationButton onClick={setOpenModal}>
          + Create New Partner Application
        </CreateApplicationButton>
        {showAddApplication && (
          <NewApplicationModal
            setCloseAddModal={setCloseAddModal}
            partnerId={accountId}
            refetchPage={refetchPage}
          />
        )}
        {applications &&
          applications.map((application: any) => {
            return (
              <ApplicationDetails
                key={application.resource_id}
                application={application}
                partnerId={accountId}
                refetchPage={refetchPage}
              />
            );
          })}
      </ApplicationsContainer>
    </M.Container>
  );
};

export default PartnerApplicationIndex;
