import { Dispatch } from 'react';
import { Screening } from 'components/AddScreenings/types/Screenings';
import { CurrentUser, GenericObject } from '@dashboard-experience/utils';
import { AliasesEnabledType } from 'components/Packages/Alias/types';

export enum PackageType {
  BASIC = 'basic+',
  COMPLETE_CRIMINAL = 'complete',
  ESSENTIAL = 'essential',
  PROFESSIONAL = 'professional',
  INTERNATIONAL_BASIC = 'international_basic_+',
  INTERNATIONAL_PROFESSIONAL = 'international_professional',
}

export enum PackageTypeParam {
  BASIC = 'basic',
  COMPLETE_CRIMINAL = 'comp',
  ESSENTIAL = 'ess',
  PROFESSIONAL = 'prof',
  INTERNATIONAL_BASIC = 'intbasic',
  INTERNATIONAL_PROFESSIONAL = 'intprof',
}

export enum Geo {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international',
}

export enum GeoParam {
  DOMESTIC = 'dom',
  INTERNATIONAL = 'int',
}

const { DOMESTIC: DOMESTIC_GEO, INTERNATIONAL: INTERNATIONAL_GEO } = Geo;
export { DOMESTIC_GEO, INTERNATIONAL_GEO };

const { DOMESTIC: DOMESTIC_GEO_PARAM, INTERNATIONAL: INTERNATIONAL_GEO_PARAM } =
  GeoParam;
export { DOMESTIC_GEO_PARAM, INTERNATIONAL_GEO_PARAM };

const {
  BASIC,
  COMPLETE_CRIMINAL,
  ESSENTIAL,
  PROFESSIONAL,
  INTERNATIONAL_BASIC,
  INTERNATIONAL_PROFESSIONAL,
} = PackageType;
export {
  BASIC,
  COMPLETE_CRIMINAL,
  ESSENTIAL,
  PROFESSIONAL,
  INTERNATIONAL_BASIC,
  INTERNATIONAL_PROFESSIONAL,
};

const {
  BASIC: BASIC_PACKAGE_TYPE_PARAM,
  COMPLETE_CRIMINAL: COMPLETE_CRIMINAL_PACKAGE_TYPE_PARAM,
  ESSENTIAL: ESSENTIAL_PACKAGE_TYPE_PARAM,
  PROFESSIONAL: PROFESSIONAL_PACKAGE_TYPE_PARAM,
  INTERNATIONAL_BASIC: INTERNATIONAL_BASIC_PACKAGE_TYPE__PARAM,
  INTERNATIONAL_PROFESSIONAL: INTERNATIONAL_PROFESSIONAL_PACKAGE_TYPE_PARAM,
} = PackageTypeParam;

export {
  BASIC_PACKAGE_TYPE_PARAM,
  COMPLETE_CRIMINAL_PACKAGE_TYPE_PARAM,
  ESSENTIAL_PACKAGE_TYPE_PARAM,
  PROFESSIONAL_PACKAGE_TYPE_PARAM,
  INTERNATIONAL_BASIC_PACKAGE_TYPE__PARAM,
  INTERNATIONAL_PROFESSIONAL_PACKAGE_TYPE_PARAM,
};

export type BasePackage = {
  name?: string;
  id?: string;
  screenings: any[];
  slug?: string;
  base_package_sku?: string;
  price?: number;
  source_version_id?: string;
  applicant_pay?: any | null;
  requires_fmcsa_compliance?: boolean;
  dl_history_verification_enabled?: boolean;
  county_coverage_for_all_state_searches?: boolean;
  skip_county_fees?: boolean;
  skip_mandatory_county_fees?: boolean;
  protected_invitation_flow?: boolean;
  criminal_self_disclosure?: boolean;
  skip_self_disclosure_validation?: boolean;
  skip_ssn_collection_on_candidate_create?: boolean;
  education_history_based_county_criminal_searches?: boolean;
  education_history_based_state_criminal_searches?: boolean;
  employment_history_based_county_criminal_searches?: boolean;
  employment_history_based_state_criminal_searches?: boolean;
  candidates_can_declare_no_ssn?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties?: boolean;
  skip_criminal_searches_in_inaccessible_states_and_counties_type?: any;
  minors_allowed?: boolean;
  assess_auto_engage_eligible_reports?: boolean;
  county_criminal_search_skip?: boolean;
  has_screenings_requiring_data?: boolean;
  continuous_check?: boolean;
  override_international_report_locales?: boolean;
  international_only?: boolean;
  county_criminal_search_scope?: string;
  apply_url?: string;
  [key: string]: any;
};

// Adding to state? Update this and initialState in reducer
export type CreatePackageReducerState = {
  addedScreeningTypes: any;
  additionalProperties: any;
  newPackageName: string;
  newPackageNameInvalid: boolean;
  basePackage: BasePackage;
  selectedGeo: string;
  infoLinksClicked?: string[];
  error: string | null;
  aliasesEnabled: string;
  aliasPrice: number;
};

type BuildPostBodyParams = {
  aliasEnabled: AliasesEnabledType | string;
};

export type CreatePackageContextType = {
  reducerState: CreatePackageReducerState;
  search: {
    search: URLSearchParams;
    searchObject: GenericObject;
    searchString: string;
    searchCleaned: boolean;
    queryParamsPresent: boolean;
    screeningTypesInSearch: string[];
  };
  basePackageScreeningTypes: string[];
  canCreateInternationalPackage: boolean;
  packageType: string;
  errorObjectStringified: string;
  currentUser: CurrentUser;
  account:
    | {
        hierarchy_present: boolean;
        id: string;
        allow_standalone_screenings: boolean;
        [key: string]: any;
      }
    | GenericObject
    | undefined;
  functions: {
    buildPostBodyWithAddOns: (params: BuildPostBodyParams) => any;
    addSelectedGeoToReducerState: (geo: string) => void;
    addBasePackageToReducerState: (packageType: string) => void;
    addScreeningToReducerState: (screening: Screening) => void;
    removeScreeningFromReducerState: (screeningType: string) => void;
    addPackageNameToReducerState: (packageName: string) => void;
    clearBasePackageAndAddonsFromReducerState: () => void;
    addErrorToReducerState: (errorMessage: string) => void;
    handleSkipBasePackageSelection: () => void;
    setPackageType: (packageType: string) => void;
    getBasePackage: (packageType: string) => BasePackage;
    validateAndCleanUpSearch: (search: URLSearchParams) => void;
    handleQueryParams: () => void;
    resetLocalStorage: () => void;
    dispatch: Dispatch<Action>;
    addDataDogError: (
      error: unknown,
      customAttributes: object | undefined,
    ) => void;
    addInfoLinksClickedToReducerState: (infoLink: string) => void;
    setScreeningTypesInSearch: (screeningTypes: string[]) => void;
  };
};

export type Action = {
  type: ActionType;
  payload?: any;
};

export enum ActionType {
  SCREENING_ADDED = 'SCREENING_ADDED',
  SCREENING_REMOVED = 'SCREENING_REMOVED',
  SET_PACKAGE_NAME = 'SET_PACKAGE_NAME',
  SET_INFO_LINKS_CLICKED = 'SET_INFO_LINKS_CLICKED',
  SET_BASE_PACKAGE = 'SET_BASE_PACKAGE',
  SET_SELECTED_GEO = 'SET_SELECTED_GEO',
  CLEAR_BASE_PACKAGE_AND_ADDONS = 'CLEAR_BASE_PACKAGE_AND_ADDONS',
  SET_ERROR = 'SET_ERROR',
  SKIP_BASE_PACKAGE_SELECTION = 'SKIP_BASE_PACKAGE_SELECTION',
  SET_ALIASES_ENABLED = 'SET_ALIAS_ENABLED',
  SET_ALIASES_PRICE = 'SET_ALIASES_PRICE',
}
