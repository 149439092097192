import { toastError } from 'actions';
import { useQuery, AnyQueryKey, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Candidate, GenericObject } from 'types';
import { useTranslation } from 'react-i18next';
import { getSuppressions, removeSuppressions } from './actions';

// Hook for seeing if a Candidate's email is being suppressed
export const useGetSuppressions = (candidate: GenericObject) => {
  const { id: candidateId } = candidate;
  const request = () => getSuppressions(candidate);
  const key: AnyQueryKey = ['getSuppressions', { candidateId }];
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

// Hook for removing the Candidate's email from the suppression list, if applicable
export const useRemoveSuppressions = (candidate: Candidate) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const request = () => removeSuppressions(candidate);

  const [removalCall, removalResult] = useMutation(request, {
    onError: (data: GenericObject) => {
      // Special handling for 404 because that's what we get if the user isn't found in the suppressions
      const errorMessage =
        data.response?.status === 404
          ? t('email_suppression.suppression_not_found')
          : data.message;

      dispatch(toastError('Error:', errorMessage));
    },
  });

  return {
    removalCall,
    removalResult,
  };
};
