import React from 'react';
import { usePostFormI9PDF, FormsI9PDFIDsParams } from 'api/i9';

const useSubmitFormsI9PDF = () => {
  const { postFormsI9PDFCreatedCall, postFormsI9PDFCreatedResult } =
    usePostFormI9PDF();
  const submit = React.useCallback(
    (forms_i9_ids: string[]) => {
      const object: FormsI9PDFIDsParams = {
        param: {
          forms_i9_ids,
        },
      };
      postFormsI9PDFCreatedCall(object);
    },
    [postFormsI9PDFCreatedCall],
  );

  return {
    submit,
    pdfURLs: postFormsI9PDFCreatedResult?.data?.pdf_urls,
  };
};

export default useSubmitFormsI9PDF;
