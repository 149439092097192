import React, { useReducer, ReactNode, useMemo, useEffect } from 'react';
import { useContextSelector, createContext } from 'use-context-selector';
import CustomProvider from 'state/provider';
import { useGetApiKeys } from 'api/apiKeys';
import { useUser } from 'context/CurrentUser';
import { GenericObject } from '@dashboard-experience/utils';

export type Action =
  | { type: 'set api keys'; payload: { apiKeys: GenericObject[] } }
  | { type: 'add api key'; payload: { apiKeys: GenericObject[] } }
  | { type: 'delete api keys'; payload: { apiKeys: GenericObject[] } }
  | { type: 'undelete api keys'; payload: { apiKeys: GenericObject[] } };

export type Dispatch = (action: Action) => void;

export type State = {
  [key: string]: any;
  apiKeys: GenericObject[];
};

export type ApiKeysProviderProps = {
  children: ReactNode;
};

const initialState = {
  apiKeys: [],
};

export const ApiKeysContext = createContext<{
  state: State;
  dispatch: Dispatch;
}>({
  state: initialState,
  dispatch: () => {},
});

const apiKeysReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set api keys': {
      const apiKeys = [...action.payload.apiKeys];
      return {
        ...state,
        apiKeys,
      };
    }
    case 'add api key': {
      const apiKeys = [...state.apiKeys, action.payload];
      return {
        ...state,
        apiKeys,
      };
    }
    case 'delete api keys': {
      const apiKeys = [...state.apiKeys].map(apiKey => {
        // @ts-ignore
        if (apiKey.id === action.payload.id) {
          return action.payload;
        }
        return apiKey;
      });
      return {
        ...state,
        apiKeys,
      };
    }
    case 'undelete api keys': {
      const apiKeys = [...state.apiKeys].map(apiKey => {
        // @ts-ignore
        if (apiKey.id === action.payload.id) return action.payload;
        return apiKey;
      });
      return {
        ...state,
        apiKeys,
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

const ApiKeysProvider = ({ children }: ApiKeysProviderProps) => {
  const { account } = useUser();
  const { data: apiKeys, isLoading } = useGetApiKeys(account.id);

  const [state, dispatch] = useReducer(apiKeysReducer, {
    ...initialState,
  });

  const props = useMemo(() => ({ state, dispatch }), [state]);
  useEffect(() => {
    if (apiKeys && !isLoading) {
      dispatch({
        type: 'set api keys',
        payload: {
          apiKeys,
        },
      });
    }
  }, [apiKeys, isLoading]);

  return (
    <CustomProvider context={ApiKeysContext} props={props}>
      {children}
    </CustomProvider>
  );
};

const useApiKeys = () => {
  const context = useContextSelector(ApiKeysContext, ({ state }) => {
    return state.apiKeys;
  });

  if (context === undefined) {
    throw new Error('useApiKeys must be used within ApiKeysProvider');
  }
  return context;
};

const useDispatch = () =>
  useContextSelector(ApiKeysContext, ({ dispatch }) => dispatch);

export { ApiKeysProvider, useApiKeys, useDispatch };
