import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNamespacedRoute } from 'modules/assess/ui/router/context';
import { Full as Entity } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import BaseDialog, {
  Action,
} from 'modules/assess/ui/common/base-dialog/container';
import { Button } from 'components/Button';
import PageLoading from 'components/PageLoading';
import { usePublish, useGetChanges } from '../version/api';
import Footer from './footer';
import Content from './content';

export type Props = {
  version: Entity;
};

const DRAFT_ID = 'draft';

export const Publish: React.FC<Props> = ({ version }) => {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const publish = usePublish(version.category_code_set?.id, DRAFT_ID);
  const isLoading = publish.result.status === 'loading';

  const path = useNamespacedRoute('categories');
  const history = useHistory();

  const changes = useGetChanges(version?.category_code_set?.id, DRAFT_ID);
  const categories = changes?.data?.categories;
  const categoryCodes = changes?.data?.category_codes;

  useEffect(() => {
    if (publish.result.status === 'success') {
      setOpen(false);
      history.push(`${path}/${version.category_code_set?.id}`);
    }
  }, [publish.result.status]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setConfirmed(false);
  }, []);

  if (!version || !version.category_code_set) {
    return null;
  }

  return (
    <>
      <PageLoading enabled={isLoading} />
      <Button
        data-testid='open-modal-button'
        kind='primary'
        trans='verbs.publish'
        onClick={handleClick}
        disabled={!(categories?.length || categoryCodes?.length)}
      />
      <BaseDialog
        action={Action.publish}
        content={
          <Content
            name={version.category_code_set.name}
            confirm={setConfirmed}
          />
        }
        footer={
          <Footer
            disabled={!confirmed || isLoading}
            onCancel={handleClose}
            onConfirm={publish.call}
          />
        }
        open={open}
        onClose={handleClose}
        name={version.category_code_set.name}
        type='category_set'
      />
    </>
  );
};

export default Publish;
