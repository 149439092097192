import React, { useCallback } from 'react';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { M } from '@dashboard-experience/mastodon';

const DeleteCondition = ({ index }: { index: number }) => {
  const { deleteConditionByIndex } = useConditions();
  const onClick = useCallback(() => {
    return deleteConditionByIndex(index);
  }, [index, deleteConditionByIndex]);

  return (
    <M.GridCol
      lg={1}
      md={1}
      sm={1}
      style={{ alignSelf: 'center' }}
      data-testid='assess-rules-common-rule-editor-condition-delete'
    >
      <M.Icon icon='TrashCan' size='16' onClick={onClick} />
    </M.GridCol>
  );
};

export default DeleteCondition;
