import { TOAST_TIMEOUT } from 'Constants';
import postMessageToDashboard from 'utils/PostMessageToDashboard';

const postToast = ({
  contextId,
  type,
  title,
  body,
  timeout = TOAST_TIMEOUT,
  key,
}) => {
  return postMessageToDashboard({
    messageId: 'toast',
    type,
    title,
    body,
    timeout,
    contextId,
    key,
  });
};

export default postToast;
