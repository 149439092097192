import { useList as usePackagesForAccount } from 'api/packages';
import { Packages, Package, Report } from 'types';

const usePackagesFromApi = (report: Report) => {
  const packagesRes = usePackagesForAccount({
    accountId: report?.account?.id,
    params: { with_cc: true },
    useCache: true,
  });
  const packages = packagesRes?.data?.sort((a: Package, b: Package) =>
    a.name > b.name ? 1 : -1,
  ) as Packages;
  const packagesLoading = packagesRes?.isLoading as boolean;

  const findBySlug = (slug: string | undefined | null) => {
    if (!slug || !packages) return undefined;
    return packages.find(p => p.slug === slug);
  };

  const packageObj = findBySlug(report?.package) as Package;
  const upgradePackages = packages?.filter(
    (upgradablePackage: Package) =>
      packageObj || upgradablePackage?.slug !== (packageObj as Package)?.slug, // if the report's package wasn't found, just inlcude all packages
  );

  return {
    packageObj,
    packages,
    packagesLoading,
    upgradePackages,
  };
};

export default usePackagesFromApi;
