import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  Report,
  getDocument,
  GenericObject,
} from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';
import * as filestack from 'filestack-js';
import { toastSuccess, toastError, toastInfo } from 'actions';
import { useDispatch } from 'react-redux';
import { individualizedAssessmentFilestackKey as filestackKey } from 'Constants';

import { useFilestackPolicy } from 'api/filePolicy';

import FormLinks from './formLinks';

const StyledDiv = styled.div`
  padding-top: 30px;
  strong {
    font-size: 14px;
    padding: 0 0 10px;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

const UploadDocumentButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${colors.uiNavy700};
  &:hover {
    cursor: pointer;
  }
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-color: ${colors.uiGrey400};
  border-radius: 5px;
  height: 70px;
  cursor: pointer;
  background: none;
  color: ${colors.uiNavy700};
  max-width: none;
  box-shadow: none;
  font-weight: normal;
`;

const AttachmentDiv = styled.div`
  padding: 5px;
  color: ${colors.uiNavy700};
  border-radius: 4px;
  display: inline-block;
  border: 1px solid ${colors.uiGrey200};
`;

const X = styled(M.Icon)`
  fill: ${colors.uiNavy700};
  margin-left: 5px;
  margin-top: 2px;
`;

const AttachmentIcon = styled(M.Icon)`
  fill: ${colors.uiNavy700};
  margin-right: 5px;
  margin-top: 1px;
`;

type Props = {
  adjudicationDispatch: (params: any) => void;
  state: GenericObject;
};

type Security = {
  policy: string;
  signature: string;
};

const initializeFileStackClient = (
  filestackKey: string | undefined,
  isSuccess: boolean,
  data: Security | undefined,
) => {
  if (filestackKey && isSuccess && data) {
    const filestackSecurity: {
      security: Security;
    } = { security: { ...data } };

    return filestack.init(filestackKey, filestackSecurity);
  }
  return undefined;
};

const DocumentUpload: React.FC<Props> = ({ adjudicationDispatch, state }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('adjudication');
  const report = useGetReport() as unknown as Report;
  const pdfIndividualizedAssessment = getDocument(
    report,
    'pdf_individualized_assessment',
  );
  const dispatch = useDispatch();

  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const { data, isSuccess } = useFilestackPolicy(filestackKey as string);

  const client = initializeFileStackClient(filestackKey, isSuccess, data);

  const successText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.upload_success',
  );
  const removeUploadText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.remove_upload',
  );
  const fillInText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.fill_in_the_form_above_or',
  );
  const uploadADocumentText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.upload_a_document',
  );
  const uploadDocumentHeaderText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.upload_document_header',
  );
  const uploadDocumentBodyText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.upload_document_body',
  );
  const clickHereToUploadText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.click_here_to_upload',
  );
  const uploadText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.pdf',
  );

  const onFileUpload = useCallback(() => {
    const onUploadDone = (file: any) => {
      const { filesUploaded } = file;
      dispatch(toastSuccess(successText));
      adjudicationDispatch({
        type: 'UPLOAD_INDIVIDUALIZED_ASSESSMENT',
        payload: filesUploaded[0],
      });
    };

    const onFileUploadFailed = (err: any) => {
      dispatch(
        toastError(
          t(
            'adjudication:report.actions.pre_adverse.fair_chance_upload.upload_failed',
            {
              err,
            },
          ),
        ),
      );
    };

    const options = {
      accept: ['application/pdf'],
      storeTo: { location: 'S3' },
      maxFiles: 1,
      uploadInBackground: false,
      fromSources: ['local_file_system'],
      onUploadDone,
      onFileUploadFailed,
    };

    client?.picker(options).open();
  }, [client, adjudicationDispatch, dispatch, t, successText]);

  const toggleShowDocumentUpload = useCallback(() => {
    setShowDocumentUpload(!showDocumentUpload);
  }, [showDocumentUpload, setShowDocumentUpload]);

  const removeIndividualizedAssessment = useCallback(() => {
    adjudicationDispatch({
      type: 'REMOVE_INDIVIDUALIZED_ASSESSMENT',
    });
    dispatch(toastInfo(removeUploadText));
  }, [adjudicationDispatch, dispatch, removeUploadText]);

  useEffect(() => {
    if (showDocumentUpload) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showDocumentUpload, ref]);

  if (!isSuccess || !data) {
    return <M.LoadingInline />;
  }

  return (
    <div style={{ margin: '30px' }}>
      <p>
        {fillInText}
        <UploadDocumentButton type='button' onClick={toggleShowDocumentUpload}>
          {uploadADocumentText}
        </UploadDocumentButton>
      </p>
      {showDocumentUpload && (
        <>
          <StyledDiv data-testid='upload-document-section'>
            <strong>{uploadDocumentHeaderText}</strong>
            <p>{uploadDocumentBodyText}</p>
            <FormLinks />
            {pdfIndividualizedAssessment?.download_uri && (
              <AttachmentDiv style={{ marginLeft: '1rem' }}>
                <span style={{ display: 'flex' }}>
                  <AttachmentIcon icon='Attachment' />
                  <a href={pdfIndividualizedAssessment.download_uri}>
                    {uploadText}
                  </a>
                </span>
              </AttachmentDiv>
            )}
            <FileUpload onClick={onFileUpload} ref={ref}>
              <span style={{ display: 'flex' }}>
                <M.Icon
                  icon='CloudUpload'
                  style={{ fill: colors.uiNavy700, marginRight: '5px' }}
                />
                {clickHereToUploadText}
              </span>
            </FileUpload>
          </StyledDiv>
          {state.individualizedAssessment?.uploadedFile && (
            <AttachmentDiv>
              <span style={{ display: 'flex' }}>
                <AttachmentIcon icon='Attachment' />
                <a href={state.individualizedAssessment.uploadedFile.url}>
                  {uploadText}
                </a>
                <X onClick={removeIndividualizedAssessment} icon='Close' />
              </span>
            </AttachmentDiv>
          )}
        </>
      )}
    </div>
  );
};

export default DocumentUpload;
