import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { NoteType } from 'modules/case-management/types';
import ExpandableNoteDiv from './ExpandableNoteDiv';
import ExistingNoteEditor from '../NoteEditor/ExistingNoteEditor';

type PropTypes = {
  currentUserNote?: boolean;
  editMode?: boolean;
  note: NoteType;
  toggleEditMode: () => void;
};
const NoteContent = ({
  editMode = false,
  currentUserNote = false,
  note,
  toggleEditMode,
}: PropTypes) => {
  const { t } = useTranslation();
  const timezone = moment.tz.guess();

  const discarded = !!note.discarded_at;

  const content = discarded
    ? t('case-management:internal-notes.deleted-content', {
        deleted_at: moment.tz(note.discarded_at, timezone).format('lll z'),
      })
    : note.content;

  return (
    <div data-testid='case-management-note-content'>
      {!editMode ? (
        <ExpandableNoteDiv
          currentUserNote={currentUserNote}
          content={content}
          discarded={discarded}
          noteId={note.id}
        />
      ) : (
        <ExistingNoteEditor note={note} onClose={toggleEditMode} />
      )}
    </div>
  );
};

export default NoteContent;
