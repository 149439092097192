import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledCheckIcon = styled(M.Icon)`
  text-align: center !important;
  width: 100% !important;
`;
const StyledTitle = styled('h1')`
  margin-bottom: 0 !important;
`;
const StyledCloseButton = styled(M.Button)`
  margin: 8px !important;
`;

interface UpdatedDeletedSuccessDialogProps {
  name: string;
  handleClose: (target: any) => void;
}

const UpdatedDeletedSuccessDialog: React.FC<
  UpdatedDeletedSuccessDialogProps
> = ({ name, handleClose }: UpdatedDeletedSuccessDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <M.ModalBody padding='0'>
        <StyledTitle data-testid='delete-successful'>
          {t('data_deletion.deleted_success.title')}
        </StyledTitle>
        <StyledCheckIcon icon='CertificateCheck' size='48' />
        <p>{t('data_deletion.deleted_success.text', { name })}</p>
      </M.ModalBody>
      <M.ModalFooter>
        <StyledCloseButton onClick={handleClose}>
          {t('verbs.close')}
        </StyledCloseButton>
      </M.ModalFooter>
    </>
  );
};

export default UpdatedDeletedSuccessDialog;
