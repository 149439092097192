import React, { useEffect } from 'react';
import { Page } from 'modules/assess/ui';
import * as State from 'modules/assess/ui/state/context-date';
import { ID } from 'modules/id';
import ActionsContainer from './ActionsContainer';
import { useFetch } from './api/hooks';
import Create from './CreateContainer';
import { Description } from './Description';
import List from './List';
import * as Store from './store';
import { Title } from './Title';

type Props = {
  disabled?: boolean;
  id: ID;
};

export const ContextDatesContainer: React.FC<Props> = ({ disabled, id }) => {
  const query = useFetch(id);
  const { data: response } = query;
  const store = Store.useStore();

  const { dispatch } = store;

  useEffect(() => {
    if (!response) {
      return;
    }

    dispatch(State.Actions.Load.create({ disabled, options: response.data }));
  }, [disabled, dispatch, response]);

  return (
    <Store.Provider value={store}>
      <Page.Container data-testid='assess-ruleset-version-context-date-container'>
        <ActionsContainer id={id} />
        <Title disabled={disabled} />
        <Description />
        <List />
        <Create />
      </Page.Container>
    </Store.Provider>
  );
};

export default ContextDatesContainer;
