/* eslint-disable react/no-children-prop */
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { Assess } from 'components';
import { GROWTH_INSIGHTS_FLAG_KEY } from 'Constants';
import { Query, useFetch } from 'modules/assess/ui/ruleset/api';
import GrowthInsights from 'modules/assess/ui/rulesets/growth_insights';
import Versions from './versions';
import NotFound from '../missing';

type Params = {
  ruleset: string;
};

const Instance: React.FC = () => {
  const growthInsightEnabled =
    useFlag(GROWTH_INSIGHTS_FLAG_KEY)?.variantKey === 'on';

  const match = useRouteMatch<Params>();
  const { path: base, params, url } = match;
  const { ruleset } = params;
  const query = useFetch(ruleset);
  const title = query.data?.name || ruleset;

  return (
    <Query.Provider value={query}>
      <Assess.Route.Breadcrumb location={url} title={title}>
        <Switch>
          {growthInsightEnabled && (
            <Route
              path={`${base}/growth_insights`}
              children={<GrowthInsights id={ruleset} />}
            />
          )}
          <Route path={`${base}/versions`} children={<Versions />} />
          <Route path={`${base}/:missing`} children={<NotFound />} />
          <Redirect to={`${base}/versions/active`} />
        </Switch>
      </Assess.Route.Breadcrumb>
    </Query.Provider>
  );
};

export default Instance;
