import { useState, useEffect } from 'react';
import {
  getObjFromLocalStorage,
  localStorageFallback,
} from '@dashboard-experience/utils';
/* eslint-disable no-console */
export const debug =
  process.env.NODE_ENV === 'development' ||
  localStorageFallback.getItem('debug')
    ? (...args) => console.debug('DEBUG:', ...args)
    : () => {};

// Debugging helper to help you figure out why a component rerendered
// Pass in an object of all the props, and log which one changed to force a rerender
export const useWhyDidIRender = props => {
  const [previous, setPrevious] = useState(props);
  useEffect(() => {
    let changed = false;
    Object.keys(previous).forEach(key => {
      if (previous[key] !== props[key]) {
        debug('Changed', {
          key,
          previousValue: previous[key],
          newValue: props[key],
        });
        changed = true;
      }
    });
    if (changed) setPrevious(props);
  }, [setPrevious, previous, props]);
};

export const setDebugState = (key, pageState) => {
  debug(key, pageState);
  return null;
  // const state = getObjFromLocalStorage('state');
  // localStorageFallback.setItem(
  //   'state',
  //   JSON.stringify({
  //     ...state,
  //     [key]: pageState,
  //   }),
  // );
};

export const removeDebugState = key => {
  const state = getObjFromLocalStorage('state');
  delete state[key];
  localStorageFallback.setItem('state', JSON.stringify(state));
};
