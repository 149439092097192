import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { QueryResult } from 'react-query';
import { GenericObject } from '@dashboard-experience/utils';
import { LoadingScreen } from '../Account';
import { DefaultIntegrationPanel } from '../Integrations';
import { Instance, useGetAppInstances } from '../../api/integrations';
import {
  DEFAULT_ERROR,
  HELP_CREATE_INTEGRATION_URL,
} from '../../api/integrations/constants';

const Integrations: React.FC = () => {
  const {
    data: appInstances = [],
    isLoading: apiLoading,
    isError: hasError,
  }: QueryResult<GenericObject> = useGetAppInstances();
  const instances: Instance[] = appInstances?.application_instances;

  return apiLoading ? (
    <LoadingScreen />
  ) : (
    <>
      {hasError && (
        <M.InlineNotification
          hideCloseButton
          kind='error'
          title={DEFAULT_ERROR}
        />
      )}
      {!hasError && (
        <div id='integrations' style={{ minHeight: '400px' }}>
          {instances?.length > 0 ? (
            instances.map((instance: Instance) => (
              <DefaultIntegrationPanel
                key={instance.application.id?.toString()}
                instance={instance}
              />
            ))
          ) : (
            <M.Container type='official'>
              <M.Container.Row>
                <M.Container.Col>
                  <span data-testid='no-integrations'>
                    Your account has no integrations enabled.{' '}
                    <M.Link href={HELP_CREATE_INTEGRATION_URL} target='_blank'>
                      Click here
                    </M.Link>{' '}
                    to learn how to set up an integration.
                  </span>
                </M.Container.Col>
              </M.Container.Row>
            </M.Container>
          )}
        </div>
      )}
    </>
  );
};

export default Integrations;
