import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import AnalyticsContainer from 'containers/AnalyticsContainer';
import { useUser } from 'context/CurrentUser';

type RouterProps = { category: string };

type Props = RouteComponentProps<RouterProps>;

const AnalyticsPage: React.FC<Props> = ({ match }) => {
  const currentUser = useUser();
  const {
    params: { category },
  } = match;

  return accountHasPermission(currentUser, 'see_full_dashboard') &&
    hasPermission(currentUser, 'read_analytics') ? (
    <AnalyticsContainer initialCategory={category} />
  ) : null;
};

export default AnalyticsPage;
