import React from 'react';
import { useTranslation } from 'react-i18next';
import { REPORT_STATUS_HELP_CENTER_URL } from 'components/Search/constants';
import { M } from '@dashboard-experience/mastodon';
import { StatusModalContent } from 'components/Search/Filters/StyledComponents';

type TooltipModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
};

export const TooltipModal: React.FC<TooltipModalProps> = ({
  modalOpen,
  closeModal,
}) => {
  const { t } = useTranslation();

  const translationKey = 'filterTooltips.assessmentFilterDefinitions';

  const tooltipText = (
    <StatusModalContent>
      <strong>{t(`${translationKey}.eligible.title`)}: </strong>
      {t(`${translationKey}.eligible.definition`)} <br />
      <strong>{t(`${translationKey}.review.title`)}: </strong>
      {t(`${translationKey}.review.definition`)} <br />
      <strong>{t(`${translationKey}.escalated.title`)}: </strong>
      {t(`${translationKey}.escalated.definition`)} <br />
      <a
        href={REPORT_STATUS_HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        {t('filterTooltips.continueReadingLink')}...
      </a>
    </StatusModalContent>
  );

  return (
    <M.ComposedModal
      open={modalOpen}
      onClose={closeModal}
      data-testid='case-management-tasks-page-tooltip-modal'
    >
      <M.ModalHeader
        closeModal={closeModal}
        title={t(`${translationKey}.modalHeading`)}
      />
      <M.ModalBody>{tooltipText}</M.ModalBody>
    </M.ComposedModal>
  );
};

export default TooltipModal;
