import React from 'react';
import AnalyticsTabs from 'components/Analytics/AnalyticsTabs';

type AnalyticsProps = { initialCategory: string };

const AnalyticsContainer: React.FC<AnalyticsProps> = ({ initialCategory }) => (
  <AnalyticsTabs initialCategory={initialCategory} />
);

export default AnalyticsContainer;
