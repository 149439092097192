import React, { useMemo } from 'react';
import { Document as CandidateDocument, EducationRecord } from 'types';
import Document from '../Document';

type Props = {
  documents?: CandidateDocument[];
  record: EducationRecord;
};

const RecordDocuments: React.FC<Props> = ({ documents, record }) => {
  const documentsFilteredForRecord: CandidateDocument[] = useMemo(
    () =>
      documents?.filter(
        document =>
          document.alternate_documentable_id === record.school.id ||
          document.alternate_documentable_id === record.id,
      ) || [],
    [documents, record],
  );

  return (
    <>
      {documentsFilteredForRecord.map((document: CandidateDocument) => (
        <Document
          key={document.id}
          document={document}
          dataTestId={`document-of-${document.alternate_documentable_id}`}
        />
      ))}
    </>
  );
};

export default RecordDocuments;
