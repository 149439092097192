import * as Entity from 'modules/assess/models/rules/count';

type Base = Omit<Entity.Type, 'filters'>;

export type RequestData = Base & {
  selection_conditions: any[];
};

export type ResponseData = Base & {
  selection_conditions: any[];
};

export function fromAPI(data: ResponseData): Entity.Type {
  const result: Entity.Type = {
    filters: data.selection_conditions,
    ...data,
  };

  delete (result as any).selection_conditions;

  return result;
}

export function toAPI(data: Entity.Type): RequestData {
  const result: RequestData = {
    selection_conditions: data.filters,
    ...data,
  };

  delete (result as any).filters;

  return result;
}
