import { AnyQueryKey, useQuery } from 'react-query';

import * as Actions from './actions';

// eslint-disable-next-line import/prefer-default-export
export const useList = (params: Actions.Params) => {
  const {
    account: { hierarchy_present, id },
    customId,
    includeAccountId,
  } = params;
  const key: AnyQueryKey = [
    'package-node/list',
    { customId, id, includeAccountId, hierarchy_present },
  ];

  const request = () => Actions.list(params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!(hierarchy_present && customId),
  });
};
