/* eslint-disable import/prefer-default-export */
import { groupBy, sortBy } from 'lodash/fp';
import { ISelectedCandidate } from './types';

export const sortExcused = (candidates: ISelectedCandidate[]) =>
  sortBy(
    (candidate: ISelectedCandidate) =>
      candidate.excused || !!candidate.reportId,
    candidates,
  );

export const getDrugAlcoholCandidates = (
  candidates: ISelectedCandidate[],
): ISelectedCandidate[] => {
  const {
    drug: drugTestCandidates,
    alcohol: alcoholTestCandidates,
    drug_alcohol: drugAlcoholTestCandidates,
  } = groupBy(
    (candidate: ISelectedCandidate) => candidate.screeningType,
    candidates,
  );

  return sortExcused([
    ...(drugTestCandidates || []),
    ...(alcoholTestCandidates || []),
    ...(drugAlcoholTestCandidates || []),
  ]);
};

export enum SORT_DIRECTIONS {
  NONE = 'NONE',
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export const sortDirections: typeof SORT_DIRECTIONS = {
  NONE: SORT_DIRECTIONS.NONE,
  ASCENDING: SORT_DIRECTIONS.ASCENDING,
  DESCENDING: SORT_DIRECTIONS.DESCENDING,
};

export const getNextDirection = (currentDirection: SORT_DIRECTIONS) => {
  const { ASCENDING, DESCENDING, NONE } = sortDirections;
  switch (currentDirection) {
    case SORT_DIRECTIONS.NONE:
      return ASCENDING;
    case SORT_DIRECTIONS.ASCENDING:
      return DESCENDING;
    case SORT_DIRECTIONS.DESCENDING:
      return NONE;
    default:
      return NONE;
  }
};
