import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'components/AddScreenings/shared/ModalComponents';

import { useSignupPageContext } from 'pages/Signup/context';
import {
  SIGNUP_EVENT_NAMES,
  FOX_SIGNUP_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import { Modal, Footer } from './ui/Modal';
import {
  Container,
  TitleContainer,
  PageSubtitleContainer,
  PageSubtitle,
} from './styles';
import { CandidateInfoSection } from './ui/CandidateInfo';
import { updateSessionStorage } from './utils/sessionStorage';
import {
  calculateSubtotal,
  getPackagePriceWithAddOns,
  priceToString,
} from './utils/pricing';
import { isSelectedPackageInternational } from './utils/package';
import { countCandidates } from './utils/candidates';
import useQueueInvites from './hooks/useQueueInvites';
import BASE_PACKAGES from './constants/basePackages';
import OrderProcessing from './ui/OrderProcessing';
import Errors from './ui/Error';

const CandidateInfoPage = () => {
  const { t } = useTranslation('signup');
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const [error, setError] = useState<any>(null);
  const modalRef = useRef(null);

  const {
    addOns,
    candidates,
    setCandidates,
    selectedPackage,
    workLocation,
    setWorkLocation,
  } = useSignupPageContext();
  const isInternational = isSelectedPackageInternational(selectedPackage);

  const { isLoading, queueInvites } = useQueueInvites({
    addOns,
    candidates,
    newPackageName: `Custom ${BASE_PACKAGES[selectedPackage].name}`,
    selectedPackage,
    workLocation,
    setError,
  });

  const handleContinue = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_INVITE_CANDIDATES_STEP_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    queueInvites();
  }, [queueInvites, trackEvent]);

  const handleBack = useCallback(() => {
    if (isInternational) {
      history.push({ pathname: '/signup/packages', search: location.search });
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_BACK_BUTTON_CLICKED, {
        Modal: 'Packages',
      });
    } else {
      history.push({ pathname: '/signup/add-ons', search: location.search });
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_BACK_BUTTON_CLICKED, {
        Modal: 'Add-ons',
      });
    }
  }, [history, isInternational, location.search, trackEvent]);

  const packagePrice = getPackagePriceWithAddOns(
    selectedPackage,
    addOns[selectedPackage],
  );
  const subtotal = calculateSubtotal(
    selectedPackage,
    addOns[selectedPackage],
    candidates,
  );

  const containsInvalidEmails = candidates.some(
    candidate => !validateEmail(candidate.email),
  );
  const hasNoCandidates = countCandidates(candidates) === 0;

  const continueButtonDisabled =
    containsInvalidEmails ||
    hasNoCandidates ||
    (isInternational && !workLocation.country) ||
    (!isInternational && !workLocation.state);

  useEffect(() => {
    updateSessionStorage('candidates', candidates);
  }, [addOns, candidates]);

  useEffect(() => {
    updateSessionStorage('workLocation', workLocation);
  }, [workLocation]);

  useEffect(() => {
    trackEvent(
      SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_INVITE_CANDIDATES_STEP_VIEWED,
      {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      },
    );
  }, [trackEvent]);

  if (isLoading) {
    return <OrderProcessing />;
  }

  return (
    <Modal
      footer={
        <Footer
          packagePrice={priceToString(packagePrice)}
          subtotal={subtotal}
          numCandidates={countCandidates(candidates)}
          primaryBtnText={t(`footer.submit`)}
          secondaryBtnText={t(`footer.secondary`)}
          primaryButtonProps={{
            onClick: handleContinue,
            disabled: continueButtonDisabled,
          }}
          secondaryOnClick={handleBack}
        />
      }
      stepNumber={3}
      data-dd-privacy='allow'
    >
      <Container ref={modalRef} data-testid='candidate-info-page'>
        <TitleContainer>
          <h3 className='mb-0'>{t('pages.candidateInfoPage.title')}</h3>
          <PageSubtitleContainer>
            <PageSubtitle>{t(`pages.candidateInfoPage.subtitle`)}</PageSubtitle>
          </PageSubtitleContainer>
        </TitleContainer>
        <Errors modalRef={modalRef} error={error} />
        <CandidateInfoSection
          addOns={addOns[selectedPackage]}
          isInternational={isInternational}
          candidates={candidates}
          setCandidates={setCandidates}
          selectedPackage={selectedPackage}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
        />
      </Container>
    </Modal>
  );
};

export default CandidateInfoPage;
