import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

const PicVSRegulatoryRequirements: React.FC<{}> = () => {
  return (
    <div>
      <style>
        {`.expandable-title {
                color: ${colors.uiTextPrimaryLight};
            }`}
      </style>
      <M.Expandable title='Regulatory requirements'>
        <div className='mastodon-unordered-list-container'>
          <ul className='cds--list--unordered mastodon-unordered-list'>
            <li className='cds--list__item mastodon-list-item'>
              <p>
                A conviction, within the previous 10 years, for any offence
                under the Criminal Code or Controlled Drugs and Substances Act
                (Canada)
              </p>
              <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
                <li className='cds--list__item mastodon-list-item '>
                  <p>
                    <strong>Not eligible</strong>
                  </p>
                </li>
              </ul>
            </li>
            <li className='cds--list__item mastodon-list-item'>
              <p>
                An outstanding charge for any offence under the Criminal Code or
                Controlled Drugs and Substances Act (Canada)
              </p>
              <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
                <li className='cds--list__item mastodon-list-item '>
                  <p>
                    <strong>Not eligible</strong>
                  </p>
                </li>
              </ul>
            </li>
            <li className='cds--list__item mastodon-list-item'>
              <p>
                A conviction for any of the following offences under the
                Criminal Code regardless of time frame, including any
                predecessors (previous versions) of these offences:
              </p>
              <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Air or maritime safety
                    offences (sections 76 to 78)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Offences involving
                    explosives (sections 79 to 82.6)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Terrorism (part II.1)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Use of firearm in
                    commission of offence (section 85)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Possession of weapon for
                    dangerous purpose (section 88)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Robbery to steal firearm
                    (section 98.1)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Criminal negligence,
                    murder, infanticide, bodily harm (sections 219 to 248)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Assault offences
                    (sections 267 to 269.1)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Assaulting peace officer
                    (sections 270.01 and 270.02)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Kidnapping, trafficking
                    in persons, hostage taking (sections 279 to 281)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Hate crimes (sections 318
                    and 319)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Robbery and extortion
                    (sections 343 to 346)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Breaking and entering
                    offences (sections 348 to 351)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Mischief causing actual
                    danger to life (section 430 (2))
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Arson - disregard for
                    human life (section 433)
                  </p>
                </li>
              </ul>
            </li>
            <li className='cds--list__item mastodon-list-item'>
              <p>
                A conviction for any offence of a sexual nature under the
                Criminal Code regardless of time frame, including any
                predecessors (previous versions) of these offences:
              </p>
              <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Sexual interference,
                    sexual touching, sexual exploitation (sections 151 to 153.1)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Child pornography
                    (section 163.1)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Sexual assault (sections
                    271 to 273)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Removal of child from
                    Canada for sexual purposes (section 273.3)
                  </p>
                </li>
                <li className='cds--list__item mastodon-list-item'>
                  <p>
                    <strong>Not eligible&nbsp;</strong>Obtaining, producing or
                    advertising sexual services (section 286.1 to 286.4)
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </M.Expandable>
    </div>
  );
};

export default PicVSRegulatoryRequirements;
