import {
  FIRST_TABS,
  HELP_TABS,
  MORE_PRODUCTS_TABS,
  SECOND_TABS,
} from 'components/DashboardWrapper/tabs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import LogoImage from 'components/DashboardWrapper/Logo';
import { useUser } from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { CurrentUser, hasPermission } from '@dashboard-experience/utils';
import {
  multiUserAccountSwitch,
  multiAccountEnabledIds,
} from 'utils/AuthUtils';
import { fetchUser } from 'actions';
import { useDispatch } from 'react-redux';
import NavElement from './nav-element';
import PredefinedNavElement from './predefined-nav-element';
import {
  Divider,
  LogoSection,
  CollapseSection,
  NavContainer,
  LinksSection,
  UL,
  Menu,
  ListElement,
} from './styles';
import { useNavCollapse } from '../utilities/hooks';
import NavElementWithSubmenu from './nav-element-with-submenu';
import LogoInitial from './logo-initial';
import { NavIcon } from './nav-icon';

const restrictedAccounts: Array<string> = [
  'amazon',
  'amazon-dsp',
  'sparkdriver',
  'uber_eats',
  'uber',
  'uberblack',
];

type Props = {
  setNavCollapse: Function;
};

const NavigationContainer: React.FC<Props> = ({ setNavCollapse }) => {
  const { pathname } = useLocation();
  const user: CurrentUser = useUser();
  const [multiAccountIds, setMultiAccountIds] = React.useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setMultiAccountIds(multiAccountEnabledIds());
  }, []);

  const handleAccountSwitch = useCallback(
    async event => {
      const { value } = event.target;
      await multiUserAccountSwitch(value);
      dispatch(fetchUser());
    },
    [dispatch],
  );

  const { isCollapsed, isCollapseForced, savePreferCollapsed } =
    useNavCollapse();

  const onClickCollapse = useCallback(() => {
    savePreferCollapsed(!isCollapsed);
  }, [isCollapsed, savePreferCollapsed]);

  useEffect(() => {
    // Some state management has to get passed up to the wrapper for some CSS classes in the main page body
    setNavCollapse(isCollapsed);
  }, [isCollapsed, setNavCollapse]);

  const sizeClassName = isCollapsed ? 'collapsed' : 'expanded';

  const handleLogout = useCallback(() => {
    window.location.href = '/logout';
  }, []);

  const canViewMoreProducts = useMemo(() => {
    // User can be null in the case of the FOX signup flow
    if (!user?.account) {
      return true;
    }

    // verifications team custom user roles with manage_post_hire_drug permission only should
    // not be able to see more products
    const hasOnlyPostHireDrugPermission =
      hasPermission(user, 'manage_post_hire_drug') &&
      user.permissions &&
      Object.keys(user.permissions).length === 2;

    if (hasOnlyPostHireDrugPermission) {
      return false;
    }

    return !restrictedAccounts.includes(user.account.uri_name);
  }, [user]);

  return (
    <NavContainer className={sizeClassName}>
      <LogoSection className={sizeClassName}>
        {/* Swap between the full "Checkr" logo vs. the "C" initial. To avoid re-requesting the image from the server on each collapse/expand, both are technically always rendered, just shown/hidden with css */}
        <LogoImage /> <LogoInitial />
      </LogoSection>
      <LinksSection>
        <UL>
          {multiAccountIds.length > 1 && (
            <M.Select
              id='select-mam-account'
              labelText=''
              onChange={handleAccountSwitch}
              defaultValue={user?.account?.id}
            >
              {multiAccountIds.map((account: string) => (
                <M.Select.Item key={account} value={account} text={account} />
              ))}
            </M.Select>
          )}

          {/* The core pages of the Dashboard - Candidates, Assess, Account, etc. */}
          {Object.entries(FIRST_TABS).map(([titleKey, tab]) => {
            return (
              <PredefinedNavElement
                key={titleKey}
                titleKey={titleKey}
                tab={tab}
                currentPath={pathname}
                navCollapsed={isCollapsed}
              />
            );
          })}
        </UL>
        <Divider />
        <UL>
          {/* These are some secondary products like I9, Randomized testing, etc. */}
          {Object.entries(SECOND_TABS).map(([titleKey, tab]) => {
            return (
              <PredefinedNavElement
                key={titleKey}
                titleKey={titleKey}
                tab={tab}
                currentPath={pathname}
                navCollapsed={isCollapsed}
              />
            );
          })}
          {/* "More products" contains things like Checkr Pay, Checkr Trust */}
          {!!canViewMoreProducts && (
            <NavElementWithSubmenu
              tabName='more_products'
              navCollapsed={isCollapsed}
              submenuTabs={MORE_PRODUCTS_TABS}
            />
          )}
        </UL>
        <Divider />
        <UL>
          {/* Help has links to our help center, API docs, etc */}
          <NavElementWithSubmenu
            tabName='help'
            navCollapsed={isCollapsed}
            submenuTabs={HELP_TABS}
          />

          <ListElement>
            <Menu
              icon={<NavIcon tabName='mail' />}
              label={user?.email || 'Current User'}
              menuOffset={{
                // Offsets to make the menu open to the right of the nav item
                top: -40,
                left: isCollapsed ? 114 : 212,
              }}
              hideTooltip
            >
              <M.Menu.MenuItem itemText='Logout' onClick={handleLogout} />
            </Menu>
          </ListElement>
        </UL>
      </LinksSection>

      {/* Only show the Collapse/Expand button if the user is actually allowed to change it */}
      {!isCollapseForced && (
        <CollapseSection>
          <UL>
            <NavElement
              text={!isCollapsed ? 'Collapse' : 'Expand'}
              navCollapsed={isCollapsed}
              tabName={!isCollapsed ? 'collapse' : 'expand'}
              onClick={onClickCollapse}
            />
          </UL>
        </CollapseSection>
      )}
    </NavContainer>
  );
};

export default NavigationContainer;
