import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { DraftPill } from 'modules/assess/v2/ui/common';

const Container = styled.div`
  display: flex;
`;

const PillContainer = styled.div`
  margin-left: 1rem;
  margin-top: 3px;
`;

const Header = styled.h1`
  color: ${colors.brandNavy4};
`;

const Title: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const text = t(`standard.guideline.content.title`);
  return (
    <Container>
      <Header id='assess-v2-ui-guideline-title-header'>{text}</Header>
      <PillContainer id='assess-v2-ui-guideline-title-pill'>
        <DraftPill />
      </PillContainer>
    </Container>
  );
};

export default Title;
