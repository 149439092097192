import React from 'react';
import styled from 'styled-components';
import BreadcrumbItem from '../OrderBackgroundCheck/BreadcrumbItem';

export const GET_STARTED_PAGE_KEY = 1 as const;
export const SELECT_A_PACKAGE_PAGE_KEY = 2 as const;
export const ADD_CHECKS_PAGE_KEY = 3 as const;
export const REVIEW_AND_SUBMIT_PAGE_KEY = 4 as const;

export type Steps =
  | typeof GET_STARTED_PAGE_KEY
  | typeof SELECT_A_PACKAGE_PAGE_KEY
  | typeof ADD_CHECKS_PAGE_KEY
  | typeof REVIEW_AND_SUBMIT_PAGE_KEY;

export const BREADCRUMB_STEPS = {
  GET_STARTED: '1. Get started',
  SELECT_A_PACKAGE: '2. Select a package',
  ADD_CHECKS: '3. Add checks',
  REVIEW_AND_SUBMIT: '4. Review & submit order',
  REVIEW_AND_SUBMIT_ADDONS_DISABLED: '3. Review & submit order',
};

export const STEP_NAME: Record<Steps, string> = {
  [GET_STARTED_PAGE_KEY]: 'Get started',
  [SELECT_A_PACKAGE_PAGE_KEY]: 'Select a package',
  [ADD_CHECKS_PAGE_KEY]: 'Add checks',
  [REVIEW_AND_SUBMIT_PAGE_KEY]: 'Review & submit order',
};

export const STEP_NUMBER: Record<Steps, string> = {
  [GET_STARTED_PAGE_KEY]: BREADCRUMB_STEPS.GET_STARTED,
  [SELECT_A_PACKAGE_PAGE_KEY]: BREADCRUMB_STEPS.SELECT_A_PACKAGE,
  [ADD_CHECKS_PAGE_KEY]: BREADCRUMB_STEPS.ADD_CHECKS,
  [REVIEW_AND_SUBMIT_PAGE_KEY]: BREADCRUMB_STEPS.REVIEW_AND_SUBMIT,
};

export const STEP_PATH: Record<Steps, string> = {
  [GET_STARTED_PAGE_KEY]: '/add-check/get-started',
  [SELECT_A_PACKAGE_PAGE_KEY]: '/add-check/select-a-package',
  [ADD_CHECKS_PAGE_KEY]: '/add-check/add-checks',
  [REVIEW_AND_SUBMIT_PAGE_KEY]: '/add-check/review-and-submit',
};

const OrderedList = styled.ol`
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

type BreadcrumbProps = {
  currentStepNumber: number;
  currentPage: string;
  addOnsEnabled: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  currentPage,
  currentStepNumber,
  addOnsEnabled,
}) => {
  return (
    <OrderedList>
      <BreadcrumbItem
        data-testid='bread-crumb-get-started'
        step={BREADCRUMB_STEPS.GET_STARTED}
        stepNumber={GET_STARTED_PAGE_KEY}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
      <BreadcrumbItem
        data-testid='bread-crumb-select-a-package'
        step={BREADCRUMB_STEPS.SELECT_A_PACKAGE}
        stepNumber={SELECT_A_PACKAGE_PAGE_KEY}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
      {addOnsEnabled && (
        <BreadcrumbItem
          data-testid='bread-crumb-add-checks'
          step={BREADCRUMB_STEPS.ADD_CHECKS}
          stepNumber={ADD_CHECKS_PAGE_KEY}
          currentStepNumber={currentStepNumber}
          currentPage={currentPage}
        />
      )}
      <BreadcrumbItem
        data-testid='bread-crumb-review-and-submit'
        step={
          addOnsEnabled
            ? BREADCRUMB_STEPS.REVIEW_AND_SUBMIT
            : BREADCRUMB_STEPS.REVIEW_AND_SUBMIT_ADDONS_DISABLED
        }
        stepNumber={REVIEW_AND_SUBMIT_PAGE_KEY}
        currentStepNumber={currentStepNumber}
        currentPage={currentPage}
      />
    </OrderedList>
  );
};

export default Breadcrumbs;
