/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { CURRENT_USER_ACCOUNT_UPDATE } from 'actions/ActionTypes';

import { queryCache, useMutation, useQuery } from 'react-query';

import { useDispatch } from 'react-redux';
import * as settings from 'modules/assess/api/settings';
import * as Entity from 'modules/assess/models/settings/account';

const namespace = settings.uri();

export const useFetch = () => {
  const request = () => {
    return settings.fetch();
  };

  return useQuery(namespace, request);
};

export const useUpdate = () => {
  const dispatch = useDispatch();

  const request = (updates: Partial<Entity.Type>) => {
    return settings.update(updates);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns] }) => {
        return ns === namespace && !data;
      });

      if (data) {
        queryCache.setQueryData(namespace, data);
        dispatch({ type: CURRENT_USER_ACCOUNT_UPDATE, payload: data });
      }
    },
  });

  return {
    call,
    result,
  };
};
