import React from 'react';
import { useTranslation } from 'react-i18next';
import { Banner } from 'components/Banner';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import { DATE_FORMAT } from 'Constants';
import moment from 'moment';
import { getTimeDelta } from '@dashboard-experience/utils';

// See if this AA deserves an Escalated status, for being paused for too long (as set in the User config)
const isEscalated = (
  time: string | null,
  threshold: number | null,
): boolean => {
  if (time && threshold) {
    // asDays() is a float, so even being 1 second above the threshold will count as truthy
    return getTimeDelta(time, Date.now()).asDays() > threshold;
  }

  return false;
};

// Banner to be shown for any Candidate who has paused Adverse Actions
const AdverseActionPaused: React.FC = () => {
  const { t } = useTranslation();
  const report = useReport();
  const { adverseAction } = useAdverseActions(report.id);

  if (adverseAction?.pause_data) {
    const { user, duration, time } = adverseAction.pause_data;

    // If the AA has been paused for a long time, consider it "Escalated" (more urgent colors+copy)
    const threshold = report?.account?.adverse_action_pause_escalated_days;
    const escalated = isEscalated(time, threshold);
    const key = `banners.adverseActionPaused${escalated ? 'Escalated' : ''}`;

    return (
      <Banner
        kind={escalated ? 'error' : 'warning'}
        title={t(`${key}.title`, { duration })}
        subtitle={t(`${key}.subtitle`, {
          email: user,
          date: moment(time).format(DATE_FORMAT),
        })}
      />
    );
  }

  return null;
};

export default AdverseActionPaused;
