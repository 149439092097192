import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AdverseAction } from 'types';
import { usePatchAdverseAction } from 'modules/adjudication/api';
import { useAdjudicationChangeAllowed } from 'modules/adjudication/utilities';
import ResolveUndeliverableAdverseModal from './ResolveUndeliverableAdverseModal';

const BodySection = styled.div`
  #copy-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 0.5rem;
    }
  }

  #downloadLinks {
    padding-top: 1rem;

    p {
      margin-bottom: 0px;
    }

    small {
      color: ${colors.uiGrey500};
    }
  }
`;

// Helper component to simplify the PDF download markup
const DownloadLink: React.FC<GenericObject> = ({ href, kind }) => {
  const { t } = useTranslation();
  return (
    <p>
      {t('verbs.download')}{' '}
      <M.Link href={href} target='_blank'>
        {kind}_adverse_action.pdf
      </M.Link>
    </p>
  );
};

const FooterManual: React.FC<GenericObject> = ({ resolveClick, t }) => {
  // Make sure the user has permission to modify AA statuses
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();

  return (
    <M.Restrict authorized={adjudicationChangeAllowed}>
      <M.Button kind='primary' onClick={resolveClick}>
        {t('manual.resolve_button')}
      </M.Button>
    </M.Restrict>
  );
};

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  adverseAction: AdverseAction;
};
const ResolveUndeliverableManually: React.FC<ModalProps> = ({
  showModal,
  onClose,
  adverseAction,
}) => {
  const {
    pre_adverse_action_pdf_encrypted_url: encrypted_pdf, // PRE-adverse sometimes has an encrypted version, but not always
    pre_adverse_action_pdf_url,
    post_adverse_action_pdf_url,
    aa_delivery_state_reason,
  } = adverseAction;
  const { t } = useTranslation('', { keyPrefix: 'resolve_undeliverable_aa' });

  const instructionString = t('manual.reach_out');

  // Function for copying instructions to clipboard if desired
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const copyInstructions = useCallback(() => {
    navigator.clipboard.writeText(instructionString);
    setIsCopied(!isCopied);
  }, [instructionString, isCopied]);

  // PATCH is called when the User clicks the button to indicate that they're manually handling this issue.
  const { patchCall, patchResult } = usePatchAdverseAction(adverseAction);
  const resolveClick = useCallback(() => {
    patchCall();
  }, [patchCall]);

  useEffect(() => {
    // Close the modal after getting a response from the server on the PATCH call
    if (patchResult.isSuccess || patchResult.isError) {
      onClose();
      patchResult.reset();
    }
  }, [onClose, patchResult]);

  return (
    <ResolveUndeliverableAdverseModal
      showModal={showModal}
      onClose={onClose}
      reason={aa_delivery_state_reason}
      footer={<FooterManual resolveClick={resolveClick} t={t} />}
    >
      <BodySection>
        <p>{instructionString}</p>

        <M.Button id='copy-button' kind='tertiary' onClick={copyInstructions}>
          <M.Icon icon='CopyFile' size={16} />
          {t(isCopied ? 'copied' : 'copy_instructions')}
        </M.Button>

        <div id='downloadLinks'>
          {encrypted_pdf ? (
            <>
              <DownloadLink href={encrypted_pdf} kind='PRE' />
              <small>{t('manual.encrypted_instructions')}</small>
            </>
          ) : (
            <DownloadLink href={pre_adverse_action_pdf_url} kind='PRE' />
          )}

          <DownloadLink href={post_adverse_action_pdf_url} kind='POST' />
        </div>
      </BodySection>
    </ResolveUndeliverableAdverseModal>
  );
};

export default ResolveUndeliverableManually;
