import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';

const StyledDiv = styled.div`
  color: ${colors.uiGrey500};
  font-weight: bolder;
`;

type Props = {
  count?: number;
};

const AssignedStatus: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('assess');
  const text = t(`assess:ruleset_assignments.status.assigned`, 'Assigned');
  return (
    <StyledDiv data-testid='assess-rulesets-assigned-status'>
      {text} ({count})
    </StyledDiv>
  );
};

export default AssignedStatus;
