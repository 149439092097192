import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/package';
import { Dialog } from 'modules/assess/ui/settings/packages/dialog';
import Button from '../../button';

type Props = {
  disabled?: boolean;
  packages?: Entity.List;
  setPackages: (packages: Entity.List) => void;
};

const StyledRow = styled(M.Container.Row)`
  color: ${colors.uiGrey800};
`;

const SelectPackages: React.FC<Props> = ({
  disabled = false,
  packages,
  setPackages,
}) => {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!packages || packages.length === 0) return null;

  return (
    <StyledRow
      data-testid='assess-v1-settings-adverse-action-automation-select-packages'
      style={{ marginLeft: '2rem' }}
    >
      <Button onClick={onClick} disabled={disabled} />
      <Dialog
        field='assess_auto_adverse_action_escalated_reports'
        onClose={onClose}
        open={open}
        packages={packages}
        setPackages={setPackages}
      />
    </StyledRow>
  );
};

export default SelectPackages;
