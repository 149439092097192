import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useToggle } from '@dashboard-experience/utils';

// Possibly create a class in Mastodon?
export const StyledLinkButton = styled(M.Button)`
  color: ${colors.uiNavy600} !important;
  background-color: ${colors.uiGrey0} !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 1.25rem !important;
  padding-left: 0 !important;
  min-height: 0 !important;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: normal !important;
  max-width: 24rem;

  &:hover {
    color: ${colors.uiNavy700} !important;
  }
`;

export const LinkButton: React.FC<{
  className?: string;
  onClick?: Function;
}> = ({ ...props }) => (
  <StyledLinkButton size='sm' kind='tertiary' {...props} />
);

const Text = styled.p`
  color: ${colors.uiGrey400} !important;
  font-size: 12px !important;
  white-space: normal;
  margin-top: 8px;
`;

type Props = {
  packageURL: string;
  excludeDescription?: boolean;
};

const CertificationClickComponent: React.FC<Props> = ({
  packageURL,
  excludeDescription = false,
}) => {
  const { isToggled, toggle } = useToggle();
  const handleCertifyClick = useCallback(() => toggle(), [toggle]);
  return !isToggled ? (
    <>
      <LinkButton className='certification-link' onClick={handleCertifyClick}>
        View invitation link
      </LinkButton>
      {!excludeDescription && (
        <Text id='certification-text'>
          By clicking above, I certify that: (1) I have reviewed and approved of
          all documents that will be presented to the candidates; (2) I have a
          permissible purpose to request a consumer report on the candidates;
          and (3) my use of consumer reports will comply with all applicable
          laws.
        </Text>
      )}
    </>
  ) : (
    <M.CopyField text={packageURL} style={{ maxWidth: '45rem' }} />
  );
};

export default CertificationClickComponent;
