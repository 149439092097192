import { cloneDeep } from 'lodash';

const invoiceStatusesForDisplay = {
  to_generate: 'unpaid',
  in_process: 'unpaid',
  scheduled: 'unpaid',
  pending: 'unpaid',
  void: 'void',
  paid: 'paid',
  closed: 'closed',
};

const convertInvoiceStatuses = invoiceItems => {
  const invoiceItemsClone = cloneDeep(invoiceItems);
  return invoiceItemsClone.map(item => {
    item.status = invoiceStatusesForDisplay[item.status];
    return item;
  });
};

export default convertInvoiceStatuses;
