import React, { ChangeEvent, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import PageLoading from 'components/PageLoading';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { useDraftImportCSV } from 'modules/assess/ui/ruleset/version/api/hooks';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { Tooltip } from '../common';

const StyledContainer = styled.div`
  padding-top: 0.8rem;
  display: flex;
`;

const StyledIcon = styled(M.Icon)`
  margin-right: 8px;
  padding-right: 2px;
`;

const StyledLabel = styled.div`
  display: flex;
`;

const StyledText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.brandNavy4};
`;

export const Upload: React.FC = () => {
  const track = useTrackEvent();

  const { t } = useTranslation('assess:v2');
  const translations: any = t(
    `standard.guidelines.lookback_period.csv.upload`,
    {
      returnObjects: true,
    },
  );
  const ruleset = useRuleset();
  const importer = useDraftImportCSV(ruleset?.data?.id);

  const handleImport = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || !files[0]) return;

      importer.call(files[0]);
      track(LookbackPeriod.ImportedCSV);
    },
    [importer, track],
  );

  const basePath = 'assess-v2-ui-guideline-lookback-period-csv-actions-upload';
  const descriptionId = `${basePath}-description`;
  const buttonId = `${basePath}-button`;
  const buttonContainerId = `${basePath}-button-container`;

  return (
    <M.ListItem data-testid={basePath}>
      <StyledText data-testid={descriptionId}>
        <Trans
          t={t}
          i18nKey='standard.guidelines.lookback_period.csv.upload.description'
        >
          <Tooltip alignment='top' />
        </Trans>
      </StyledText>
      <PageLoading enabled={importer.result.status === 'loading'} />
      <StyledContainer data-testid={buttonContainerId}>
        <M.FileInput
          data-testid={buttonId}
          accept='text/csv'
          disableLabelChanges
          onChange={handleImport}
          role='button'
          label={
            <StyledLabel>
              <StyledIcon icon='CloudUpload' size='16' />
              {translations.button}
            </StyledLabel>
          }
        />
      </StyledContainer>
    </M.ListItem>
  );
};

export default Upload;
