import React from 'react';
import STATUS from 'components/Packages/enums/Status';
import AddScreeningsAction, { SetupButton } from './AddScreeningsAction';
import {
  SetupButtonContainer,
  SetupPendingContainer,
  SetupPendingIcon,
  AddButtonContainer,
} from './ListItemElements';

export type CreditSetupButtonSelectionProps = {
  status: string;
  actionProps: object;
  handleOpen: Function;
};

const CreditSetupButtonSelection: React.FC<CreditSetupButtonSelectionProps> = ({
  status,
  actionProps,
  handleOpen,
}) => {
  return (
    <>
      {status === STATUS.disabled && (
        <SetupButtonContainer>
          <SetupButton data-testid='setup-button' onClick={handleOpen}>
            Set up
          </SetupButton>
        </SetupButtonContainer>
      )}
      {status === STATUS.pending && (
        <SetupPendingContainer>
          <SetupPendingIcon icon='Renew' /> Setup in progress
        </SetupPendingContainer>
      )}
      {status === STATUS.enabled && (
        <AddButtonContainer>
          <AddScreeningsAction screeningType='credit_report' {...actionProps} />
        </AddButtonContainer>
      )}
    </>
  );
};

export default CreditSetupButtonSelection;
