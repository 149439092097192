import client from './client';
import * as Entity from '../models/fair-chance-metrics';

const BASE_PATH = 'fair_chance_metrics';

export const fetch = (): Promise<Entity.Type> => {
  const path = `/${BASE_PATH}`;
  return client.get(path, {}, false);
};

export const uri = (): string => {
  const path = `/${BASE_PATH}`;
  return client.uri({ url: path });
};
