import { Kind } from 'modules/assess/models/entity';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type Type = React.FC<Props>;

export type Props = {
  kind?: Kind;
  latest?: boolean;
};

export const ActiveLink: Type = ({ kind, latest }) => {
  const { t } = useTranslation('assess');

  if (kind === Kind.final && latest) {
    return null;
  }

  const text = t('assess:entity.status.active_link');

  return <Link to='active'>{text}</Link>;
};

export default ActiveLink;
