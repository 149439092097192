import { useLocation } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { CANDIDATE_REDESIGN_PHASE_THREE } from 'Flags';

// eslint-disable-next-line import/prefer-default-export
export const useIsCandidateRoute = () => {
  const location = useLocation();
  // Regular expression to match /candidates/:id
  const candidateDetailRegex = /^\/candidates\/[^\/]+/;

  return candidateDetailRegex.test(location.pathname);
};

export const useCandidateRededesignPhaseThree = () => {
  return useFlag(CANDIDATE_REDESIGN_PHASE_THREE)?.variantKey === 'on';
};
