/* eslint-disable import/prefer-default-export */
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { BETA_EVENT_NAMES } from 'utils/analytics/events';
import { useTrackEvent } from 'utils/analytics/analytics';
import { usePreference } from 'api/dashboardPreferences';
import { BetaState } from './types';
import { BetaContext } from './context';

const PREFERENCE_KEY = 'betaStates';

export const BetaProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [betaStates, setBetaStates] = usePreference(PREFERENCE_KEY);
  const trackEvent = useTrackEvent();

  const showBeta = useCallback(
    (flagKey: string) => () => {
      const newStates = { ...betaStates, [flagKey]: BetaState.OPT_IN };
      trackEvent(BETA_EVENT_NAMES.BETA_OPT_IN, { flagKey });
      setBetaStates(newStates);
    },
    [betaStates, setBetaStates, trackEvent],
  );

  const hideBeta = useCallback(
    (flagKey: string) => () => {
      const newStates = { ...betaStates, [flagKey]: BetaState.OPT_OUT };
      trackEvent(BETA_EVENT_NAMES.BETA_OPT_OUT, { flagKey });
      setBetaStates(newStates);
    },
    [betaStates, setBetaStates, trackEvent],
  );

  const value = useMemo(
    () => ({
      betaStates,
      showBeta,
      hideBeta,
    }),
    [betaStates, hideBeta, showBeta],
  );
  return <BetaContext.Provider value={value}>{children}</BetaContext.Provider>;
};
