import React from 'react';
import _ from 'lodash';
import { FieldComponentProps } from '../types';

const Judgement: React.FC<FieldComponentProps> = ({ adverseItem, testid }) => {
  const { judgement } = adverseItem;
  return judgement ? (
    <span data-testid={testid} className='value-width'>
      {_.capitalize(judgement)}
    </span>
  ) : null;
};

export default Judgement;
