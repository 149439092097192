import React, { useContext, useEffect } from 'react';
import querystring from 'querystring';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import UserContext from 'context/CurrentUser';
import { CurrentUser, GenericObject } from '@dashboard-experience/utils';
import { useOrderBackgroundCheckContext } from './Context';

const Description = styled.div`
  margin-top: 15px;
  margin-bottom: 37px;
  width: 744px;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandNavy3};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const NewWindowMessage = styled.div`
  size: 16px;
  color: ${colors.brandNavy3} !important;
  line-height: 24px;
  margin-bottom: 7px;
`;

const HereLink = styled.a`
  color: ${colors.brandNavy3} !important;
  text-decoration: underline;
  size: 16px;
`;

type ApplyUrlProps = {
  selection: any; // our state
  currentUser: CurrentUser;
};

const getApplyUrl = ({ selection, currentUser }: ApplyUrlProps) => {
  if (!selection || !selection.basePackage) return undefined;

  const {
    node,
    geo,
    location: { country, city, state },
    basePackage,
  } = selection;

  const base_url = basePackage.apply_url;
  const query = { order: currentUser.id } as GenericObject;
  if (!currentUser?.account?.segmentation_enabled && geo && geo.id !== 'None')
    query.geo_id = geo.id;
  if (node) query.node_id = node.custom_id;
  if (country) query.wl_country = country;
  if (state) query.wl_state = state.abbreviation;
  if (city) query.wl_city = city.name;
  return `${base_url}?${querystring.stringify(query)}`;
};

const iframeStyle = {
  width: '100%',
  height: '100%',
  minHeight: '1000px',
};

const ManualOrderEntry: React.FC<any> = ({ updateFlow }) => {
  const { state } = useOrderBackgroundCheckContext();

  useEffect(() => {
    if (!state?.basePackage?.apply_url) {
      updateFlow(1);
    }
  }, [state.basePackage?.apply_url, updateFlow]);

  const currentUser = useContext(UserContext);
  const applyUrl = getApplyUrl({ selection: state, currentUser });
  return (
    <div>
      <Description>
        Simply add the details you need and we’ll take care of the rest. Once
        the secure background check is started, you’ll be able to view its
        progress in the Candidates tab.
      </Description>
      <Container>
        <NewWindowMessage>
          If you don’t see the forms below, open them in a new window{' '}
          <HereLink
            data-testid='here-link-manual-order'
            href={applyUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </HereLink>
          .
        </NewWindowMessage>
      </Container>
      <iframe
        data-testid='iframe-manual-order'
        src={applyUrl}
        title='Manual Order'
        data-align='middle'
        style={iframeStyle}
      />
    </div>
  );
};

export default ManualOrderEntry;
