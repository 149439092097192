import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CandidatePostalAddress } from 'types';
import { M } from '@dashboard-experience/mastodon';
import PostalAddressForm from 'components/PostalAddress/PostalAddressForm';
import {
  useGetCandidatePostalAddress,
  useUpdateCandidatePostalAddress,
} from 'api/candidate';
import { useCandidate } from 'providers/Candidate';
import ConfigSection from '../config-section';
import { useDispatch, useModalState } from '../../../../context';
import { useAddressIsFilled } from '../hooks';

const CandidateAddress: React.FC<{}> = () => {
  const addressFilled = useAddressIsFilled();
  const dispatch = useDispatch();
  const { candidateAddress } = useModalState('state');
  const { candidateId } = useParams();
  const { first_name, last_name } = useCandidate();
  const { updateCandidatePostalAddressCall } = useUpdateCandidatePostalAddress(
    candidateId,
    candidateAddress,
  );
  const addressData = useGetCandidatePostalAddress(candidateId);
  const [editingAddress, setEditingAddress] = useState(false);

  useEffect(() => {
    if (!addressFilled && addressData && !editingAddress) {
      dispatch({
        type: 'SET_CANDIDATE_ADDRESS',
        payload: { ...addressData, saved: true },
      });
      setEditingAddress(true);
    }
  }, [addressFilled, addressData, dispatch]);

  const handleAddress = useCallback(
    (address: CandidatePostalAddress & { saved?: boolean }) => {
      dispatch({
        type: 'SET_CANDIDATE_ADDRESS',
        payload: { ...address, name: `${first_name} ${last_name}` },
      });
    },
    [dispatch, first_name, last_name],
  );

  const submitAddress = useCallback(() => {
    updateCandidatePostalAddressCall(candidateAddress).then((result) => {
      handleAddress({ ...result, saved: true });
    });
  }, [candidateAddress, updateCandidatePostalAddressCall, handleAddress]);

  return (
    <ConfigSection>
      <strong>Candidate address for delivery</strong>
      <PostalAddressForm
        handleAddress={handleAddress}
        address={candidateAddress}
      />
      <M.Button
        kind='primary'
        onClick={submitAddress}
        disabled={!candidateAddress}
        style={{ float: 'right', marginRight: '2rem', marginTop: '0.2rem' }}
      >
        Save
      </M.Button>
    </ConfigSection>
  );
};

export default CandidateAddress;
