import React from 'react';
import { useLocation } from 'react-router-dom';
import View, { TabName } from '../home';

export const Home: React.FC = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const tab = search.get('tab') as TabName;

  return <View tab={tab} />;
};

export default Home;
