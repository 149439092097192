import React from 'react';
import PageContainer from 'components/PageContainer';
import { PageError } from 'components';

const NotFoundPage: React.FC<any> = () => (
  <div>
    <PageContainer>
      <PageError code='404' />
    </PageContainer>
  </div>
);

export default NotFoundPage;
