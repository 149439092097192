import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';
import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view';
import { ItemVisibility } from 'modules/assess';
import Option from './Option';

type Props = {
  locals: Entity.Type;
  remote?: Entity.Type;
  setNeedsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const StyledRow = styled(M.Container.Row)`
  #assess-v2-settings-eligible-records-options {
    .mastodon-screening-row {
      padding: 0;
    }
    .expandable-title {
      font-weight: 500;
      font-size: 1rem;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Container: React.FC<Props> = ({
  locals,
  remote,
  setNeedsConfirmation,
  setSettings,
}) => {
  const current = getSettingsOption(locals);

  const handleChange = useCallback(
    (selected: Value) => {
      switch (selected) {
        case Value.SHOW_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.VISIBLE,
          }));
          setNeedsConfirmation(false);
          break;
        }
        case Value.CONCEAL_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.HIDDEN,
          }));
          setNeedsConfirmation(false);
          break;
        }
        case Value.REMOVE_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.REMOVED,
          }));
          setNeedsConfirmation(true);
        }
      }
    },
    [remote, setNeedsConfirmation, setSettings],
  );

  return (
    <StyledRow data-testid='assess-v2-settings-eligible-records'>
      <Flex id='assess-v2-settings-eligible-records-options'>
        <Option
          example={{ disabled: false }}
          onChange={handleChange}
          selected={current === Value.SHOW_ELIGIBLE_RECORDS}
          value={Value.SHOW_ELIGIBLE_RECORDS}
        />
        <Option
          example={{ disabled: true }}
          onChange={handleChange}
          selected={current === Value.CONCEAL_ELIGIBLE_RECORDS}
          value={Value.CONCEAL_ELIGIBLE_RECORDS}
        />
        <Option
          onChange={handleChange}
          selected={current === Value.REMOVE_ELIGIBLE_RECORDS}
          value={Value.REMOVE_ELIGIBLE_RECORDS}
        />
      </Flex>
    </StyledRow>
  );
};

export function getSettingsOption(settings: Entity.Type) {
  if (settings.assess_eligible_item_display === ItemVisibility.VISIBLE) {
    return Value.SHOW_ELIGIBLE_RECORDS;
  }

  if (settings.assess_eligible_item_display === ItemVisibility.HIDDEN) {
    return Value.CONCEAL_ELIGIBLE_RECORDS;
  }

  return Value.REMOVE_ELIGIBLE_RECORDS;
}

export default Container;
