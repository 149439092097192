import { useGetReport } from 'api/reports';
import {
  computeScreeningsStatus,
  StatusTypes,
  ReportStatuses,
} from '@dashboard-experience/utils';

const { PENDING, SUSPENDED } = ReportStatuses;
const Statuses = Object.values(ReportStatuses);

export const isIncompleteStatus = (status: (typeof Statuses)[number]) =>
  [PENDING, SUSPENDED].includes(status);

export const useGetIncompleteScreenings = () => {
  const { report } = useGetReport();

  const screenings = ['sex_offender_search'];

  return screenings.reduce((incompleteScreens: string[], screening) => {
    const screen = report[screening];

    if (!screen) {
      return incompleteScreens;
    }

    if (Array.isArray(screen)) {
      const status = computeScreeningsStatus(
        screen,
        StatusTypes.Legacy,
      ) as (typeof Statuses)[number];

      if (status && isIncompleteStatus(status)) {
        incompleteScreens.push(screening);
      }
    } else if (isIncompleteStatus(screen?.status)) {
      incompleteScreens.push(screening);
    }

    return incompleteScreens;
  }, []);
};
