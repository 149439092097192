import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const Label = styled.div`
  color: ${colors.coolBlack70};
  margin-bottom: 0.5rem;
`;

const ChoiceCardGroup = styled(M.ChoiceCardGroup)`
  #mastodon && {
    fieldset {
      grid-template-columns: 218px 218px;
    }

    @media (max-width: 671px) {
      fieldset {
        grid-template-columns: 150px 150px;
      }
    }
  }
`;

type Props = {
  header: string | React.ReactNode;
  defaultSelected?: string;
  onChange: (value: string) => void | Function;
  firstChoice: string;
  secondChoice: string;
};

const BinaryChoiceCard: React.FC<Props> = ({
  header,
  onChange,
  defaultSelected,
  firstChoice,
  secondChoice,
}) => {
  return (
    <section data-testid='binary-choice-card'>
      <Label>{header}</Label>
      <ChoiceCardGroup
        type='radio'
        name='choice-group'
        defaultSelected={defaultSelected}
        onChange={onChange}
      >
        <M.ChoiceCard
          id={firstChoice}
          value={firstChoice}
          description={firstChoice}
        />
        <M.ChoiceCard
          id={secondChoice}
          value={secondChoice}
          description={secondChoice}
        />
      </ChoiceCardGroup>
    </section>
  );
};

export default BinaryChoiceCard;
