import { useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Candidate } from 'types';
import CandidateContext from './context';

export const useCandidate = () =>
  useContextSelector(CandidateContext, ({ candidate }) => candidate);

export const useCandidateId = () =>
  useContextSelector(CandidateContext, ({ candidateId }) => candidateId);

export const useComponents = () =>
  useContextSelector(CandidateContext, ({ components }) => components);

export const useCandidateExceptions = () =>
  useContextSelector(
    CandidateContext,
    ({ candidateExceptions }) => candidateExceptions,
  );

export const useSetWindowTitle = (candidate?: Candidate) => {
  useEffect(() => {
    const originalTitle = window.document.title;

    if (candidate) {
      const fullName = [candidate.first_name, candidate.last_name]
        .filter(Boolean)
        .join(' ');

      const subtitle = fullName || candidate.email;

      if (subtitle) window.document.title = `${subtitle} - ${originalTitle}`;
    }
    return () => {
      // restore original title
      window.document.title = originalTitle;
    };
  }, [candidate]);
};

export const useShowReportLog = () =>
  useContextSelector(CandidateContext, ({ showReportLog }) => showReportLog);

export const useSetShowReportLog = () =>
  useContextSelector(
    CandidateContext,
    ({ setShowReportLog }) => setShowReportLog,
  );

export const useIframeWindow = () =>
  useContextSelector(CandidateContext, ({ iframeWindow, setIframeWindow }) => ({
    iframeWindow,
    setIframeWindow,
  }));

export const useReportTabs = () =>
  useContextSelector(CandidateContext, ({ reportTabs }) => reportTabs);
