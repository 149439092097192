import React from 'react';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import BaseFilter from './BaseFilter';
import { AnalyticsProps } from '../helpers';

const NodeID = () => {
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.NODE_ID,
    trackEvent,
    debouncedEvent: true,
  };

  return (
    <BaseFilter
      component='NodeIDFilter'
      param='segment_custom_id'
      trackAnalyticsObject={trackAnalyticsObject}
    />
  );
};

export default NodeID;
