/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Home from './home';
import Version from './version';

type Params = {};

type Props = {};

const Router: React.FC<Props> = () => {
  const match = useRouteMatch<Params>();
  const { path: base } = match;

  return (
    <Switch>
      <Route path={`${base}/:version`} children={<Version />} />
      <Route path={base} children={<Home />} />
    </Switch>
  );
};

export default Router;
