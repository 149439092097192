import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Assess } from 'components';
import { useDraft } from 'modules/assess/ui/ruleset/version/api';
import { useStandard } from 'modules/assess/v2/ui/hooks';
import { LookbackPeriod, Quickstart } from 'modules/assess/v2/ui/standard';
import { ID } from 'modules/id';

type Props = {
  ruleset?: ID;
};

const Router: React.FC<Props> = ({ ruleset }) => {
  const match = useRouteMatch();
  const { path: base, url } = match;
  const query = useDraft(ruleset);

  const isStandard = useStandard();

  if (!isStandard) {
    return <Redirect to='..' />;
  }

  return (
    <Switch>
      <Route path={`${base}/quickstart`}>
        <Assess.Route.Breadcrumb
          location={`${url}/quickstart`}
          title='Quickstart templates'
        >
          <Quickstart data={query?.data} status={query?.status} />
        </Assess.Route.Breadcrumb>
      </Route>
      <Route path={`${base}/lookback-period`}>
        <Assess.Route.Breadcrumb
          location={`${url}/lookback-period`}
          title='Lookback period'
        >
          <LookbackPeriod data={query?.data} status={query?.status} />
        </Assess.Route.Breadcrumb>
      </Route>
    </Switch>
  );
};

export default Router;
