import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useHandlePasteForInviteForm } from 'hooks';
import { AddOnsType, BasePackage, Candidate } from 'components/Signup/types';
import OrderSummary from '../../OrderSummary';
import WorkLocationSection from '../WorkLocationSection';
import ContactInfoSection from '../ContactInfoSection';
import { Container, InnerContainer } from './styles';
import { ADD_ONS_PRICES } from '../../../constants/screenings';
import BasePackages from '../../../constants/basePackages';

type Props = {
  addOns: AddOnsType[];
  candidates: Candidate[];
  setCandidates: Dispatch<SetStateAction<Candidate[]>>;
  isInternational: boolean;
  selectedPackage: BasePackage;
  workLocation: any;
  setWorkLocation: Dispatch<SetStateAction<any>>;
};

export const transformCandidate = (candidates: Candidate[]) => {
  return candidates.map(candidate => {
    return {
      email: {
        value: candidate.email,
      },
    };
  });
};

export const transformDispatchPayload = (data: any[]) => {
  return data.map(candidateInfo => {
    return {
      id: candidateInfo.id,
      email: candidateInfo.email.value,
    };
  });
};

const CandidateInfoSection: React.FC<Props> = ({
  addOns,
  candidates,
  setCandidates,
  isInternational,
  selectedPackage,
  workLocation,
  setWorkLocation,
}) => {
  const candidateSetter = useCallback(
    data => {
      const pastedCandidates = transformDispatchPayload(data.payload);
      setCandidates(pastedCandidates);
    },
    [setCandidates],
  );

  // TODO: Refactor or create a new reusable paste function
  const transformCandidateState = transformCandidate(candidates);

  const handlePaste = useHandlePasteForInviteForm(
    { invites: transformCandidateState }, // Acting as our "state" input
    candidateSetter, // Acting as our "dispatch" input
  );
  return (
    <Container data-testid='candidate-info-section' onPaste={handlePaste}>
      <InnerContainer>
        <WorkLocationSection
          isInternational={isInternational}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
        />
        <ContactInfoSection
          candidates={candidates}
          setCandidates={setCandidates}
        />
      </InnerContainer>
      <OrderSummary
        packageName={BasePackages[selectedPackage].name}
        packagePrice={BasePackages[selectedPackage].price}
        screenings={addOns}
        candidates={candidates}
        screeningPrices={ADD_ONS_PRICES}
        isInternational={isInternational}
      />
    </Container>
  );
};

export default CandidateInfoSection;
