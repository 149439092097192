/* eslint-disable no-shadow */
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const IdpSubmitButton = ({
  connectionId,
  postIdpConnection,
  putIdpConnection,
  isProcessing,
}) => {
  const button = isEmpty(connectionId) ? (
    <M.Button kind='achievement' size='md' onClick={postIdpConnection}>
      Create
    </M.Button>
  ) : (
    <M.Button kind='achievement' size='md' onClick={putIdpConnection}>
      Save
    </M.Button>
  );

  return (
    <>
      {isProcessing ? (
        <span>
          <M.LoadingInline />
        </span>
      ) : (
        button
      )}
    </>
  );
};

IdpSubmitButton.propTypes = {
  connectionId: PropTypes.string,
  postIdpConnection: PropTypes.func.isRequired,
  putIdpConnection: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

IdpSubmitButton.defaultProps = {
  connectionId: '',
};

export default IdpSubmitButton;
