import React from 'react';
import { Entity } from 'modules/assess/ui/state/rules/count';
import * as Common from 'modules/assess/ui/rules/common/rule';
import ActionMenuButtons from 'modules/assess/ui/rules/common/action-menu-buttons';
import { toInternal } from 'modules/assess/ui/rules/count/utils';

type Props = {
  rule: Entity.Type;
};

const Container: React.FC<Props> = ({ rule }) => {
  const r = toInternal(rule);

  return (
    <Common.Container data-testid='assess-count-rule-viewer'>
      <ActionMenuButtons rule={r} />
      <Common.Assessment rule={r} />
      <Common.Counts rule={r} />
      <Common.Targets rule={r} />
      <Common.Conditions rule={r} />
    </Common.Container>
  );
};

export default Container;
