import React from 'react';
import { BillingEntity } from 'types/Billing';
import PaymentProfileForm from './PaymentProfileForm';
import { NodeBillingFormProps } from './PaymentProfileFormProps';

type Props = {
  paymentProfile: BillingEntity;
  submit: any;
  modalOpen: boolean;
  hideModal: any;
  isLoading: boolean;
  newIdValidator?: any;
};

const NodeBillingForm: React.FC<Props> = ({
  paymentProfile,
  submit,
  modalOpen,
  hideModal,
  isLoading,
  newIdValidator,
}) => {
  const props = NodeBillingFormProps;
  return (
    <PaymentProfileForm
      paymentProfile={paymentProfile}
      submit={submit}
      modalOpen={modalOpen}
      hideModal={hideModal}
      isLoading={isLoading}
      newIdValidator={newIdValidator}
      fieldProps={props}
    />
  );
};

export default NodeBillingForm;
