import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Entity } from 'modules/assess/models/rules/common/entity';
import { Field } from '../field';

type Props = {
  rule: Entity;
};

export const Assessment: React.FC<Props> = ({ rule }) => {
  const { t } = useTranslation('');
  const label = t('nouns.assessment');
  const assessment = t(`assess:decisions.${rule.decision}`);

  return (
    <Field data-testid='assess-rules-common-rule-assessment' label={label}>
      <M.StatusBadge status={assessment.toLowerCase()} />
    </Field>
  );
};

export default Assessment;
