import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { ID } from 'modules/id';
import client from '../client';
import { Namespace } from '../constants';
import { fromAPI } from './utilities/rulesets';

const BASE_PATH = 'rulesets';

export type CreateParams = {
  name: string;
  source?: ID;
};

export const create = (
  namespace: Namespace,
  data: CreateParams,
): Promise<Entity.Type> => {
  const path = `${namespace}/${BASE_PATH}`;
  return client.post(path, data).then(fromAPI);
};

export const destroy = (
  namespace: Namespace,
  id: string,
): Promise<undefined> => {
  const path = `${namespace}/${BASE_PATH}/${id}`;
  return client.delete(path);
};

export const fetch = (
  namespace: Namespace,
  id: string,
): Promise<Entity.Type> => {
  const path = `${namespace}/${BASE_PATH}/${id}`;
  return client.get(path).then(fromAPI);
};

export const update = (
  namespace: Namespace,
  { id, ...updates }: Partial<Entity.Type>,
): Promise<Entity.Type> => {
  const path = `${namespace}/${BASE_PATH}/${id}`;
  return client.patch(path, updates).then(fromAPI);
};

export const uri = (namespace: Namespace, id?: string): string => {
  const base = `${namespace}/${BASE_PATH}`;
  const path = id ? `${base}/${id}` : base;
  return client.uri({ url: path });
};
