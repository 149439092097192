import { Candidate } from '@dashboard-experience/utils';
import { useContextSelector } from 'use-context-selector';
import Context from './context';

// TODO: Add real actions with correct payload
export type Action = {
  type: 'set search';
  payload: { candidates: Candidate[] };
};

export type Dispatch = (action: Action) => void;

// TODO: Add real State
export type State = {
  [key: string]: any;
  candidates: null | Candidate[];
};

export const CandidateSearchContext = Context;

// TODO: Add real payload
export const candidateSearchReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set search': {
      const { candidates } = action.payload;
      return {
        ...state,
        candidates,
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export const useCandidateSearch = () => {
  const context = useContextSelector(CandidateSearchContext, ({ state }) => {
    return state.candidates;
  });

  if (context === undefined) {
    throw new Error(
      'useCandidateSearch must be used within CandidateSearchProvider',
    );
  }
  return context;
};

export const useDispatch = () =>
  useContextSelector(CandidateSearchContext, ({ dispatch }) => dispatch);

export const useSearchState = () =>
  useContextSelector(CandidateSearchContext, state => state);
