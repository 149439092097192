import React, { useContext, useEffect } from 'react';
import { localStorageFallback } from '@dashboard-experience/utils';
import UserContext from 'context/CurrentUser';
import { DOMESTIC_GEO, useCreatePackageContext } from '../Context';
import DomesticPackageSelector from './DomesticPackageSelector';
import InternationalPackageSelector from './InternationalPackageSelector';
import {
  PACKAGE_BUILDER_EVENT_NAMES,
  useTrackEvent,
} from '../../Amplitude/analytics';

const SelectPackage = () => {
  const {
    reducerState: { selectedGeo },
    packageType,
    search: { queryParamsPresent, searchCleaned },
    functions: { addBasePackageToReducerState, setPackageType },
  } = useCreatePackageContext();
  const currentUser = useContext(UserContext);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (
      !queryParamsPresent &&
      searchCleaned &&
      localStorageFallback.getItem('packageType') !== null &&
      localStorageFallback.getItem('packageType') !== ''
    ) {
      const lastPackageType: any = localStorageFallback.getItem('packageType');
      setPackageType(lastPackageType);
    }
  }, [queryParamsPresent, searchCleaned, setPackageType]);

  useEffect(() => {
    packageType &&
      packageType !== '' &&
      addBasePackageToReducerState(packageType);
  }, [packageType, setPackageType, addBasePackageToReducerState]);

  useEffect(() => {
    if (
      packageType !== null &&
      packageType !== '' &&
      !queryParamsPresent &&
      searchCleaned
    ) {
      localStorageFallback.setItem('packageType', packageType);
    }
  }, [packageType, queryParamsPresent, searchCleaned]);

  useEffect(() => {
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES.PACKAGE_BUILDER_SELECT_PACKAGE_VIEWED,
    );
  }, [currentUser, trackEvent]);

  return (
    <>
      {selectedGeo === DOMESTIC_GEO ? (
        <DomesticPackageSelector />
      ) : (
        <InternationalPackageSelector />
      )}
    </>
  );
};

export default SelectPackage;
