import React, { useEffect, useState } from 'react';
import { Namespace } from 'modules/assess/api';
import { Decision, getCurrentDecisions } from 'modules/assess/models';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Entity } from 'modules/assess/ui/state/rules/custom';
import { M } from '@dashboard-experience/mastodon';
import { Grid, Row } from 'modules/assess/ui/rules/common/rule';
import { useAssessRuleAPI } from 'modules/assess/ui/rules/common/helpers';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import NewConditions from 'modules/assess/ui/rules/common/rule/editor/new-conditions';
import Assessment from 'modules/assess/ui/rules/common/rule/editor/assessment';
import { useValidationRules } from 'modules/assess/ui/rules/common/rule/hooks';
import Name from 'modules/assess/ui/rules/common/rule/editor/name';
import { toInternal } from 'modules/assess/ui/rules/custom/utils';
import ScrollIntoViewWrapper from 'modules/assess/ui/common/scrollInViewWrapper';
import Conditions from './conditions';
import Footer from './footer';
import ItemVisibility from './item-visibility';
import Targets from './targets';

const GRID_SIZE = {
  lg: 6,
  md: 3,
  sm: 1,
};

type Props = {
  rule: Entity.Type;
};

const Editor: React.FC<Props> = ({ rule }) => {
  const namespace = useNamespace();
  const { setConditions, conditions } = useConditions();
  const account = useUserAccount();
  const internalRule = toInternal(rule);
  const [draftRule, setDraftRule] = useState(internalRule);
  const isAssessRuleApiEnabled = useAssessRuleAPI();
  const invalid = useValidationRules(draftRule);

  useEffect(() => {
    setConditions([...internalRule.conditions]);
  }, []);

  if (!namespace) {
    return null;
  }

  const displayItemVisibility =
    namespace === Namespace.criminal &&
    account?.assess_eligible_item_treatment_enabled &&
    draftRule.decision === Decision.ELIGIBLE;

  return (
    <ScrollIntoViewWrapper>
      <Grid style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <Row>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-name'
          >
            <Name
              name={draftRule.name}
              setDraftRule={setDraftRule}
              invalid={invalid}
            />
          </M.GridCol>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-assessment'
          >
            <Assessment
              decisions={getCurrentDecisions()}
              data-testid='assess-rules-custom-rule-editor-rule-assessment'
              decision={draftRule.decision}
              setDraftRule={setDraftRule}
            />
          </M.GridCol>
        </Row>
        <Row>
          <Targets namespace={namespace} />
          {displayItemVisibility && (
            <ItemVisibility
              option={draftRule.item_visibility}
              setDraftRule={setDraftRule}
            />
          )}
        </Row>
        {isAssessRuleApiEnabled ? (
          <NewConditions />
        ) : (
          <Conditions namespace={namespace} />
        )}

        <Footer
          disabled={invalid}
          rule={rule}
          draftRule={{ ...draftRule, conditions } as Entity.Type}
          namespace={namespace}
        />
      </Grid>
    </ScrollIntoViewWrapper>
  );
};

export default Editor;
