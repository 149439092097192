import React, { useEffect, useRef } from 'react';

const ScrollIntoViewWrapper = ({ children }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
      }),
    [],
  );

  return <div ref={ref}>{children}</div>;
};

export default ScrollIntoViewWrapper;
