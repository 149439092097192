import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  parseNodeFilter,
  useDebouncedCallback,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import * as ContinuousCheckSubscriptionType from 'modules/core-types/continuous_check_subscription';
import { WorkLocation } from 'modules/core-types/continuous_check_subscription';
import { debounce } from 'lodash';
import { useFetchNodes } from 'api/nodes';
import {
  CreateContinuousCheckParams,
  UpdateContinuousCheckParams,
  useCreateContinuousCheck,
  useDeleteContinuousCheck,
  useUpdateContinuousCheck,
} from 'api/continuousChecks';
import { useUser } from 'context/CurrentUser';
import useWorkLocation from './hooks/useWorkLocation';
import PostHireModuleCard from '../PostHire/PostHireModuleCard';
import {
  C_CRIMINAL,
  C_FACIS,
  C_MVR,
  MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY,
} from '../../Constants';

type Props = {
  candidate: any;
  subscription: ContinuousCheckSubscriptionType.Type;
  type: string;
  updateSubscriptions(data: object, type: string, action: string): void;
};

function convertWorkLocationToSubmit(workLocation: WorkLocation) {
  const submitWorkLocation = {} as { city?: string; state?: string };
  if (workLocation.city) {
    // @ts-ignore
    submitWorkLocation.city = workLocation.city.name;
  }
  if (workLocation.state) {
    // @ts-ignore
    submitWorkLocation.state = workLocation.state.abbreviation;
  }

  return submitWorkLocation;
}

function subscriptionDisplay(subscriptionType: string) {
  if (subscriptionType === 'mvr') {
    return C_MVR;
  }

  if (subscriptionType === 'facis') {
    return C_FACIS;
  }

  return C_CRIMINAL;
}

function subscriptionSubText(
  subscriptionType: string,
  subscription: ContinuousCheckSubscriptionType.Type,
) {
  if (subscriptionType === 'facis' && subscription?.params) {
    return `(Level ${subscription.params.facis_level})`;
  }

  return '';
}

const isObject = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const ContinuousCheckSubscription: React.FC<Props> = ({
  candidate,
  subscription,
  type,
  updateSubscriptions,
}) => {
  const { account } = candidate;
  const candidateId = candidate.id;
  const segmentationEnabled = account?.segmentation_enabled;
  const hierarchyPresent = account?.hierarchy_present;
  const facisLevelPresent = type === 'facis';
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectedNode, setSelectedNode] = useState(subscription?.node);
  const [selectedFacisLevel, setSelectedFacisLevel] = useState(
    subscription ? '' : '1M',
  );
  // Mark filter dirty immediately when changed
  // but debounce updating it to prevent over fetching
  const [nodeFilterIsDirty, setNodeFilterIsDirty] = useState(false);
  const [nodeFilter, setNodeFilter] = useState('');
  const {
    selectedWorkLocation,
    setSelectedWorkLocation,
    setUsState,
    setCountry,
    setCity,
  } = useWorkLocation(subscription?.work_locations?.[0]);

  const { createCall, createResult } = useCreateContinuousCheck();
  const { deleteCall, deleteResult } = useDeleteContinuousCheck();
  const { updateCall, updateResult } = useUpdateContinuousCheck();

  // Initial values to be used if editing canceled
  const [initialSubscription, updateInitialSubscription] =
    useState(subscription);

  const currentUser = useUser();

  const permissionsFlag =
    useFlag(MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY)?.variantKey === 'enabled';

  const canManageContinuousSubscriptions = permissionsFlag
    ? hasPermission(currentUser, 'manage_continuous')
    : true;

  useEffect(() => {
    updateInitialSubscription(subscription);
  }, [subscription, updateInitialSubscription]);

  useEffect(() => {
    setSubscriptionActive(!!subscription);
  }, [subscription]);

  useEffect(() => {
    setSelectedNode(subscription?.node);
  }, [subscription?.node]);

  const onCheckboxClick = useCallback((event, { checked }) => {
    setCheckboxChecked(checked);
  }, []);

  const handleEditLinkClick = useCallback(() => {
    setCheckboxChecked(true);
    setEditing(true);
  }, []);

  function handleSubscriptionSubmit() {
    if (subscriptionActive) {
      return submitUpdate();
    }

    return submitCreate();
  }

  const handleToggleClick = useCallback(
    e => {
      e.stopPropagation();

      if (!canManageContinuousSubscriptions) {
        return;
      }

      if (subscriptionActive) {
        setDeleting(!deleting);
        setEditing(false);
      } else {
        setEditing(!editing);
      }
    },
    [subscriptionActive, deleting, editing, canManageContinuousSubscriptions],
  );

  const handleDeleteCancelClick = useCallback(() => {
    setDeleting(false);
  }, []);

  const displayWorkLocation = useCallback(() => {
    // @ts-ignore
    if (initialSubscription?.work_locations[0] === undefined) {
      return <em> None </em>;
    }
    const work_location = initialSubscription.work_locations[0];

    let output = '';
    if (work_location?.city) {
      isObject(work_location.city)
        ? // @ts-ignore
          (output = work_location.city.name)
        : (output = work_location.city);
    }
    if (work_location?.state) {
      if (work_location?.city) {
        output += ', ';
      }
      isObject(work_location.state)
        ? // @ts-ignore
          (output += work_location.state.abbreviation)
        : (output += work_location.state);
    }
    return <span> {output} </span>;
    // @ts-ignore
  }, [initialSubscription]);

  const submitCreate = useCallback(() => {
    const payload: CreateContinuousCheckParams = {
      candidate_id: candidateId,
      type,
      account: account.uri_name,
    };

    if (selectedNode) {
      payload.node = selectedNode;
    }

    if (selectedFacisLevel) {
      payload.facis_level = selectedFacisLevel;
    }

    if (selectedWorkLocation) {
      payload.work_locations = [
        convertWorkLocationToSubmit(selectedWorkLocation),
      ];
    }

    createCall(payload);
  }, [
    candidateId,
    type,
    account.uri_name,
    selectedNode,
    selectedWorkLocation,
    selectedFacisLevel,
    createCall,
  ]);

  useEffectAfterInitialized(() => {
    setEditing(false);
    if (!createResult.isSuccess) return;
    subscription = createResult.data as ContinuousCheckSubscriptionType.Type;
    updateInitialSubscription(subscription);
    setSubscriptionActive(true);
    updateSubscriptions(subscription, type, 'add');
  }, [createResult.data, createResult.isSuccess]);

  const submitUpdate = useCallback(() => {
    const payload: UpdateContinuousCheckParams = {
      // @ts-ignore
      id: subscription?.id,
      account: account.uri_name,
    };

    if (selectedNode) {
      payload.node = selectedNode;
    }

    if (selectedWorkLocation) {
      payload.work_locations = [
        convertWorkLocationToSubmit(selectedWorkLocation),
      ];
    }

    updateCall(payload);
  }, [
    subscription?.id,
    account.uri_name,
    selectedNode,
    selectedWorkLocation,
    updateCall,
  ]);

  useEffectAfterInitialized(() => {
    setEditing(false);
    if (!updateResult.isSuccess) return;
    subscription = updateResult.data as ContinuousCheckSubscriptionType.Type;
    updateInitialSubscription(subscription);
    updateSubscriptions(subscription, type, 'update');
  }, [updateResult.data, updateResult.isSuccess]);

  useEffect(() => {
    setLoading(createResult.isLoading);
  }, [createResult.isLoading]);

  const submitDelete = useCallback(() => {
    const payload: { id: string } = {
      // @ts-ignore
      id: subscription.id,
    };

    deleteCall(payload);
  }, [deleteCall, subscription]);

  useEffect(() => {
    setLoading(deleteResult.isLoading);
  }, [deleteResult.isLoading]);

  useEffectAfterInitialized(() => {
    setDeleting(false);
    if (!deleteResult.isSuccess) return;
    setSubscriptionActive(false);
    updateSubscriptions(subscription, type, 'delete');
  }, [deleteResult.data, deleteResult.isSuccess]);

  const debouncedSetNodeFilter = useDebouncedCallback(
    (newFilter: string) => {
      setNodeFilter(newFilter);
      setNodeFilterIsDirty(false);
    },
    350,
    [debounce, setNodeFilter, setNodeFilterIsDirty],
  );

  const onNodeFilterChange = useCallback(
    newFilter => {
      setNodeFilterIsDirty(true);
      debouncedSetNodeFilter(newFilter);
    },
    [debouncedSetNodeFilter, setNodeFilterIsDirty],
  );

  const handleCancelClick = useCallback(() => {
    // @ts-ignore
    setSelectedWorkLocation(initialSubscription?.work_locations?.[0]);
    setSelectedNode(initialSubscription?.node);
    setCheckboxChecked(false);
    setEditing(false);
    onNodeFilterChange(nodeFilter);
  }, [
    initialSubscription?.node,
    initialSubscription?.work_locations,
    nodeFilter,
    onNodeFilterChange,
    setSelectedWorkLocation,
  ]);

  // To determine if the Enroll button should be active
  useEffectAfterInitialized(() => {
    if (!checkboxChecked) {
      setCanEnroll(false);
      return;
    }

    if (hierarchyPresent) {
      selectedNode && selectedWorkLocation
        ? setCanEnroll(true)
        : setCanEnroll(false);
      return;
    }

    if (segmentationEnabled) {
      selectedWorkLocation ? setCanEnroll(true) : setCanEnroll(false);
      return;
    }

    setCanEnroll(true);
  }, [
    checkboxChecked,
    hierarchyPresent,
    segmentationEnabled,
    selectedNode,
    selectedWorkLocation,
  ]);

  const { data: { data: nodes = [] } = {}, isLoading: nodeIsLoading } =
    useFetchNodes({
      account,
      nodeName: parseNodeFilter(nodeFilter).name,
    });

  const nodeSelected = useCallback(
    value => {
      setSelectedNode(value?.custom_id);
    },
    [setSelectedNode],
  );

  const facisLevelSelected = useCallback(
    value => {
      setSelectedFacisLevel(value?.target?.value);
    },
    [setSelectedFacisLevel],
  );

  return (
    <>
      <M.GridRow data-testid={`${type}-row`}>
        <M.GridCol md={2}>
          <span style={{ display: 'flex', alignItems: 'flex-end' }}>
            <M.Toggle
              id={`${type}-toggle`}
              size='sm'
              untoggledText={subscriptionDisplay(type)}
              toggledText={`${subscriptionDisplay(type)} ${subscriptionSubText(
                type,
                subscription,
              )}`}
              toggled={subscriptionActive || editing}
              onClick={handleToggleClick}
              disabled={
                !canManageContinuousSubscriptions &&
                !subscriptionActive &&
                editing
              }
              data-testid={`${type}-toggle`}
            />
            {loading && <M.LoadingSpinner small withOverlay={false} />}
          </span>
        </M.GridCol>
        {subscriptionActive && hierarchyPresent && !deleting && (
          <M.GridCol md={2}>
            <div className='cds--form-item'>
              <span>
                <strong>Node</strong>:{' '}
                {initialSubscription?.node || <em>None</em>}
              </span>
            </div>
          </M.GridCol>
        )}
        {subscriptionActive && segmentationEnabled && !deleting && (
          <M.GridCol>
            <div className='cds--form-item'>
              <span>
                <strong>Work Location(s)</strong>: {displayWorkLocation()}
              </span>
            </div>
          </M.GridCol>
        )}
        {canManageContinuousSubscriptions &&
          subscriptionActive &&
          (hierarchyPresent || segmentationEnabled) &&
          !deleting && (
            <M.GridCol md={1}>
              <M.Link
                href='#'
                onClick={handleEditLinkClick}
                style={{ cursor: 'pointer' }}
              >
                Edit nodes
              </M.Link>
            </M.GridCol>
          )}
      </M.GridRow>
      {canManageContinuousSubscriptions && deleting && (
        <PostHireModuleCard type='shadow'>
          <M.Container.Row>
            <M.Container.Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>
                Are you sure you want to unenroll this candidate from{' '}
                {subscriptionDisplay(type)}?
              </span>
              <div>
                <M.BinaryButtons
                  size='sm'
                  btnLeft={{
                    onClick: handleDeleteCancelClick,
                    name: 'Cancel',
                  }}
                  btnRight={{
                    onClick: submitDelete,
                    name: `Unenroll ${subscriptionDisplay(type)}`,
                    kind: 'danger',
                  }}
                />
              </div>
            </M.Container.Col>
          </M.Container.Row>
        </PostHireModuleCard>
      )}
      {canManageContinuousSubscriptions && editing && (
        <PostHireModuleCard type='shadow'>
          <M.Container.Row>
            <M.Container.Col>
              <M.Checkbox
                labelText={
                  'I certify collection of the appropriate authorization from the ' +
                  'consumer as required by the Fair Credit Reporting Act and all ' +
                  'applicable state laws.'
                }
                id={`criminal-check-${type}-certify`}
                defaultChecked={subscriptionActive}
                onChange={onCheckboxClick}
              />
            </M.Container.Col>
          </M.Container.Row>
          {(hierarchyPresent || segmentationEnabled || facisLevelPresent) && (
            <M.Container.Row>
              {facisLevelPresent && !subscriptionActive && (
                <M.Container.Col>
                  <M.Select
                    id='facis-level-selector'
                    labelText='Select a Level'
                    onChange={facisLevelSelected}
                    defaultValue={selectedFacisLevel}
                  >
                    <M.Select.Item value='1M' text='FACIS Level 1M' />
                    <M.Select.Item value='3' text='FACIS Level 3' />
                  </M.Select>
                </M.Container.Col>
              )}
              {hierarchyPresent && (
                <M.Container.Col>
                  <M.NodeSelect
                    nodes={nodes}
                    onChange={nodeSelected}
                    onFilterChange={onNodeFilterChange}
                    loading={nodeIsLoading || nodeFilterIsDirty}
                  />
                </M.Container.Col>
              )}
              {segmentationEnabled && (
                <M.Container.Col>
                  <M.WorkLocationSelect
                    countries={['US']}
                    onCountryChange={setCountry}
                    onStateChange={setUsState}
                    onCityChange={setCity}
                    countrySelectProps={{ withWrapper: false }}
                    localeSelectProps={{ withWrapper: false }}
                    stateSelectProps={{ withWrapper: false }}
                    citySelectProps={{ withWrapper: false }}
                  />
                </M.Container.Col>
              )}
            </M.Container.Row>
          )}
          <M.Container.Row>
            <M.Container.Col
              style={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <M.BinaryButtons
                btnLeft={{ name: 'Cancel', onClick: handleCancelClick }}
                btnRight={{
                  name: `${
                    subscriptionActive ? 'Update' : 'Enroll'
                  } ${subscriptionDisplay(type)}`,
                  onClick: handleSubscriptionSubmit,
                  disabled: !canManageContinuousSubscriptions && !canEnroll,
                }}
                size='sm'
              />
            </M.Container.Col>
          </M.Container.Row>
        </PostHireModuleCard>
      )}
    </>
  );
};

export default ContinuousCheckSubscription;
