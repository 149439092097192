import React from 'react';
import styled from 'styled-components';
import { SCREENING_NAME_MAP, SCREENING_SUBTYPE_NAME_MAP } from 'Constants';
import * as Entity from 'modules/assess/models/settings/package';

const List = styled.ul`
  padding-left: 15px !important;
  li {
    list-style-type: initial !important;
    list-style-position: inside !important;
    margin-bottom: 0.5em !important;
  }
`;

const getScreeningType = (type: string | null) => {
  const screeningTypeMap: any = SCREENING_NAME_MAP;
  return (type && screeningTypeMap[type]) || type || '';
};

const getScreeningSubType = (subtype: string | null) => {
  const screeningSubTypeMap: any = SCREENING_SUBTYPE_NAME_MAP;
  return (subtype && screeningSubTypeMap[subtype]) || subtype || '';
};

type Props = {
  id: string;
  packages: Entity.List;
};

export const Screenings: React.FC<Props> = ({ id, packages }) => {
  const pkg = packages.find((p: { id: string }) => p.id === id);

  const content = pkg?.screenings?.map((s: any) => {
    const type = getScreeningType(s.type);
    const subType = getScreeningSubType(s.subtype);

    const label = subType ? `${type} (${subType})` : type;
    return <li key={`${id}_${s.type}`}>{label}</li>;
  });

  return <List>{content}</List>;
};

export default Screenings;
