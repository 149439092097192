import React from 'react';
import { BaseRuleChange } from 'modules/assess/models/rulesets/version-diff';
import Changes, { Type } from '../common/custom-count-rules';

type Props = {
  changes?: BaseRuleChange.List;
};

export const Container: React.FC<Props> = ({ changes }) => {
  if (!changes?.length) {
    return null;
  }

  return <Changes changes={changes} type={Type.CUSTOM} />;
};

export default Container;
