import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Namespace } from 'modules/assess/api';
import { useDefaultCriminalRuleset, useStartPage } from '../hooks';
import HomeContent from '../home';

export const Home: React.FC = () => {
  const renderDraftPage = useStartPage();
  const match = useRouteMatch();
  const criminalRuleset = useDefaultCriminalRuleset();
  const { path: base } = match;

  if (renderDraftPage === undefined) return null;

  if (renderDraftPage) {
    return (
      <Redirect
        to={`${base}/${Namespace.criminal}/rulesets/${criminalRuleset?.id}/versions/draft`}
      />
    );
  }

  return <HomeContent />;
};

export default Home;
