import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Actions as ActionsContainer } from 'modules/assess/ui/page';
import Edit from 'modules/assess/ui/ruleset/version/actions/Edit';
import { ID } from 'modules/id';
import { useCCSV } from '../../context';
import { Publish as PublishButton } from '../../../publish';
import { Menu } from '../../../menu';
import { useFetch } from '../../../api';
import DeleteDraftButton from './delete-draft-button';

type Props = {
  instance: ID;
};

const Actions: React.FC<Props> = ({ instance }) => {
  const ccsv = useCCSV();
  const { data } = useFetch(instance);
  const history = useHistory();
  const openDraft = useCallback(() => {
    const path = `/assess/mvr/categories/${ccsv?.category_code_set?.id}/versions/draft`;
    history.push(path);
  }, [history, ccsv]);

  if (!ccsv?.category_code_set?.account_id) return null;

  return (
    <ActionsContainer>
      {ccsv?.active && (
        <>
          <Menu item={data} kind='options' />
          <Edit onClick={openDraft} />
        </>
      )}
      {ccsv?.draft && (
        <>
          <DeleteDraftButton version={ccsv} />
          <PublishButton version={ccsv} />
        </>
      )}
    </ActionsContainer>
  );
};

export default Actions;
