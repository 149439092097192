import React, { useMemo, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import { useUser } from 'context/CurrentUser';
import {
  ClinicSelection as ClinicSelectionView,
  Translations,
  Field,
} from 'components/LocationSearch';
import {
  exam_reorder_modal_body,
  exam_reorder_modal_footer,
} from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEventOnMount,
  ReorderEvent,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';
import ModalBody from '../body';
import { useModalState, useDispatch } from '../../../context';
import { ActionType } from '../../../types';
import { getSelectedExams } from '../screening-selection/utils';

const ActionsMap = {
  selectNewClinic: ActionType.SET_SELECT_NEW_CLINIC,
  zipcode: ActionType.SET_CLINIC_ZIP_CODE,
  clinic: ActionType.SET_CLINIC,
};

type Action =
  | ActionType.SET_SELECT_NEW_CLINIC
  | ActionType.SET_CLINIC_ZIP_CODE
  | ActionType.SET_CLINIC;

const ClinicSelection = () => {
  const user = useUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const report = useModalState('report');
  const nextScreen = useModalState('nextScreen');
  const previousScreen = useModalState('previousScreen');
  const {
    reorderableExams,
    selectNewClinic,
    zipcode,
    clinic,
    drugObservedCollection,
  } = useModalState('formData');
  const translations = t(`${exam_reorder_modal_body}.clinic_selection`, {
    returnObjects: true,
  }) as Translations;

  const examSlugs = getSelectedExams(reorderableExams).map(exam => exam.slug);
  const disabled =
    selectNewClinic === undefined || (!clinic && selectNewClinic === 'Yes');

  const navigation = {
    back: {
      name: t(`${exam_reorder_modal_footer}.back`),
      onClick: previousScreen,
    },
    next: {
      name: t(`${exam_reorder_modal_footer}.continue`),
      onClick: nextScreen,
      disabled,
    },
  };

  const state = useMemo(
    () => ({
      selectNewClinic,
      zipcode,
      clinic,
    }),
    [selectNewClinic, zipcode, clinic],
  );

  const updateState = useCallback(
    (newState: any, field: Field) => {
      const type = ActionsMap[field] as Action;

      dispatch({ type, payload: newState[field] });
    },
    [dispatch],
  );

  useTrackEventOnMount(ReorderEvent.ModalClinicSelectionViewed);

  return (
    <>
      <ModalBody>
        <ClinicSelectionView
          requiresObservedCollection={drugObservedCollection === 'Yes'}
          state={state}
          updateState={updateState}
          translations={translations}
          accountId={user?.account?.id}
          examSlugs={examSlugs}
          testPackage={report?.package_object === 'test_package'}
          packageId={report?.package_id as string}
        />
      </ModalBody>
      <M.ModalNavigationFooter back={navigation.back} next={navigation.next} />
    </>
  );
};

export default ClinicSelection;
