import styled from 'styled-components';
import AlignItems from './AlignItems';

const MAX_WIDTHS = ['45rem', '45rem', '58rem', 'none'];
const MIN_WIDTHS = ['none', '58rem', '58rem', 'none'];

const FlexRowDistribute = styled.div<{
  alignItems: AlignItems;
  step?: number;
}>`
  align-items: ${props => props.alignItems};
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  max-width: ${props => MAX_WIDTHS[props.step ?? 3]};
  min-width: ${props => MIN_WIDTHS[props.step ?? 3]};
`;

export default FlexRowDistribute;
