import React from 'react';
import {
  PasswordHelperBox,
  PasswordHelperText,
  StatusIcon,
  StatusIconWrapper,
} from './styles';

import error from '../../svg/error.svg';
import success from '../../svg/success.svg';

type PasswordHelperProps = {
  value?: string;
  validator: (val: string) => boolean;
  text: string;
};

const PasswordHelperRow = ({ value, validator, text }: PasswordHelperProps) => {
  return (
    <PasswordHelperBox>
      <StatusIconWrapper>
        {value && validator(value) ? (
          <StatusIcon data-testid={`test-icon-success-${text}`} src={success} />
        ) : (
          <StatusIcon data-testid={`test-icon-warning-${text}`} src={error} />
        )}
      </StatusIconWrapper>
      <PasswordHelperText> {text} </PasswordHelperText>
    </PasswordHelperBox>
  );
};

export default PasswordHelperRow;
