/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { normalizeToSize } from '@sentry/utils';
import { cloneDeep } from 'lodash';
import traverse from 'traverse';

import { getObjFromLocalStorage } from '@dashboard-experience/utils';
import {
  COMMIT_SHA,
  IS_ANGULAR_IFRAME,
  SENTRY_DSN,
  SENTRY_ENV,
} from 'Constants';

const sanitizableScreenings = [
  /credit_report/i,
  /drug_screening/i,
  /facis_search/i,
  /personal_reference_verifications/i,
  /professional_reference_verifications/i,
];
const sanitizeKeys = [
  /dob/i,
  /driverlicense/i,
  /i18n/i,
  /phone/i,
  /ssn/i,
  /token/i,
  ...sanitizableScreenings,
];

const ignoreErrors = [
  /privacyportal\.onetrust\.com\/request\/v1\/consentreceipts/i,
];

const paths = [
  /https?:\/\/(customer|dashboard)\.checkr\.com/,
  /https?:\/\/(customer|dashboard)\.checkr-sandbox\.com/,
  /https?:\/\/(customer|dashboard)\.checkrhq-staging\.net/,
  /https?:\/\/(customer|dashboard)\.checkr\.localhost/,
  /https?:\/\/.*onetrust\.com/,
  /https?:\/\/.*cookielaw\.org/,
];

const tags = {
  team: 'dash_experience',
  is_standalone: IS_ANGULAR_IFRAME ? 'false' : 'true',
};

// 125kB, as 200kB is max payload size for sentry
const MAX_SIZE = 125 * 1024;

/**
 * @name sanitizeState
 * @function
 * @memberOf sentry
 * @description Sanitize state based on sanitizeKeys
 * @param {(array|object)} state - state to sanitize
 * @returns {array}
 */
const sanitizeState = state => {
  // eslint-disable-next-line
  traverse(state).forEach(function (_) {
    sanitizeKeys.forEach(k => {
      if (this.key && this.key.match(k)) this.update(null);
    });
  });
};

/**
 * @name sanitizeState
 * @function
 * @memberOf sentry
 * @description remove circular references
 *              note that traverse.map is not [].map, so it isn't actually returning undefined
 *              see https://www.npmjs.com/package//traverse#scrub-circular-references
 * @param {(array|object)} state - state to transform
 * @returns {array}
 */
const transformState = state =>
  // eslint-disable-next-line
  traverse(state).map(function (_) {
    if (this.circular) this.remove();
  });

export const initializeSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: COMMIT_SHA,
    // normalize context depth
    normalizeDepth: 12,
    ignoreErrors,
    allowUrls: [
      /checkr\.com/,
      /checkr-sandbox\.com/,
      /checkrhq-staging\.net/,
      /checkr\.localhost/,
    ],
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          const fr = frame;
          if (fr.filename) {
            for (const path of paths) {
              if (path.test(fr.filename)) {
                fr.in_app = true;
              }
            }
          }
          return fr;
        },
      }),
    ],
    // clean up redux state from sensitive information
    beforeSend(event) {
      const reduxState = event?.contexts?.['redux.state'];
      const newReduxState = { ...cloneDeep(reduxState) };
      const contextState = getObjFromLocalStorage('state');

      const transformedReduxState = transformState(newReduxState);
      const transformedContextState = transformState(contextState);

      // sanitize states
      sanitizeState(transformedReduxState);
      sanitizeState(transformedContextState);

      // sanitize breadcrumbs
      sanitizeState(event.breadcrumbs, MAX_SIZE);

      const newEvent = {
        ...event,
        contexts: {
          ...event?.contexts,
          'redux.state': transformedReduxState,
          'context.state': transformedContextState,
        },
      };

      // ensure that the final event payload is less than the max size accepted by Sentry
      const normalizedEventToSize = normalizeToSize(newEvent, 12, MAX_SIZE);

      return normalizedEventToSize;
    },
    beforeBreadcrumb(breadcrumb) {
      // filter non console error events due to size limitations in breadcrumbs
      if (breadcrumb.category === 'console' && breadcrumb.level !== 'error') {
        return null;
      }
      return breadcrumb;
    },
  });

  Object.keys(tags).forEach(tag => {
    Sentry.configureScope(scope => {
      scope.setTag(tag, tags[tag]);
    });
  });
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
