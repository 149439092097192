import React, { useMemo } from 'react';
import { getReportAssessment } from '@dashboard-experience/utils';
import Badges from './Badges';
import { FieldComponentProps } from '../../types';

const Container: React.FC<FieldComponentProps> = ({
  displayAssessment,
  report,
  statusType,
  testid,
}) => {
  // @ts-ignore TODO: Update to consume Report type from Utils once is updated
  const assessment = useMemo(() => getReportAssessment(report), [report]);
  const children = [];

  if (!displayAssessment || !assessment) {
    children.push(<span>-</span>);
  }

  if (displayAssessment && !!assessment) {
    children.push(
      <Badges
        key='assessment-badge'
        assessment={assessment}
        report={report}
        statusType={statusType}
      />,
    );
  }

  return <div data-testid={testid}>{children}</div>;
};

export default Container;
