import { toastError, toastSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { postCustomerAction } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const usePostCustomerAction = () => {
  const dispatch = useDispatch();

  const request = (params: { id: string; action: string }) =>
    postCustomerAction(params.id, params.action);

  const [postCustomerActionCall, postCustomerActionResult] = useMutation(
    request,
    {
      onError: data => {
        dispatch(toastError('ERROR:', data.message));
      },
      onSuccess: data => {
        dispatch(toastSuccess(`Successfully took action`));
      },
    },
  );
  return {
    postCustomerActionCall,
    postCustomerActionResult,
  };
};
