import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  hasPermission,
  getStatusTypeForReport,
  getReportDecision,
  StatusTypes,
  CurrentUser as User,
} from '@dashboard-experience/utils';
import { M, colors } from '@dashboard-experience/mastodon';
import { GenericObject, Report } from 'types';
import { useCandidate } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';
import { useEta } from 'containers/Report';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import TagsContainer from 'modules/candidate/ui/overview/sections/report-information/fields/tags/tags';
import { ENABLE_ARCHIVE_REPORTS } from '../../../Flags';
import * as FieldComponents from './Fields';
import { FieldConfig, FieldKVListConfig } from './types';
import DownloadPdfButton from './PdfDownload/PdfDownload';
import ArchiveReportAction from './ArchivedReport/ArchiveReportAction';

const fields = [
  'status',
  'assessment',
  'rulesetApplied',
  'mvrRulesetApplied',
  'adjudication',
  'package',
  'nodes',
  'program',
  'source',
  'createdAt',
  'upgradedAt',
  'completedAt',
  'revisedAt',
  'eta',
  'turnaroundTime',
  'geos',
  'workLocation',
];

const ReportInformationContainer = styled(M.Container)`
  .cds--overflow-menu-options {
    overflow-y: auto;
    max-height: 350px;
    width: 275px !important;
    background-color: ${colors.uiGrey0};
    margin-left: -1px;
    margin-top: 2px;

    > .cds--overflow-menu-options__option {
      padding: 0.25rem;

      &:hover {
        background-color: ${colors.uiGrey100};

        button {
          background-color: ${colors.uiGrey100};
        }
      }

      > .cds--overflow-menu-options__btn {
        max-width: 275px;
      }
    }
  }
`;

const ArchivedReportBanner = styled.div`
  background: ${colors.uiGrey100};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 0.75rem;
  padding: 1rem;
  color: ${colors.uiGrey900};
  line-height: 1.25;

  svg {
    vertical-align: bottom;
    margin-right: 0.75rem;
    color: ${colors.uiGrey900}c7;
  }
`;

const buildReportInfoFields = (
  statusType: string,
  report: Report,
  currentUser: User,
  reportPrediction?: string,
  reportEstimationGenerated?: string,
) => {
  const isAssessEnabled = statusType === StatusTypes.Assess;

  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
  const displayAssessment = getReportDecision(report, currentUser);

  // Check for adjudicators / non-admin users
  const displayAssessmentField =
    hasPermission(currentUser, 'read_assessments') &&
    displayAssessment &&
    isAssessEnabled;

  const segmentationEnabled =
    report?.segment_stamps && report?.segment_stamps?.length > 0;

  const fieldsArr: FieldConfig[] = [];

  fields.forEach(field => {
    switch (field) {
      case 'status':
        fieldsArr.push({
          fieldName: () => 'Status',
          fieldKey: 'status',
          hasTemplate: true,
        });
        break;
      case 'assessment':
        if (displayAssessmentField)
          fieldsArr.push({
            fieldName: () => 'Customer assessment',
            fieldKey: 'assessment',
            hasTemplate: true,
          });
        break;
      case 'rulesetApplied':
        if (
          displayAssessmentField &&
          displayAssessment &&
          report.assessment?.ruleset
        )
          fieldsArr.push({
            fieldName: () => 'Ruleset applied',
            fieldKey: 'rulesetApplied',
            hasTemplate: true,
          });
        break;
      case 'mvrRulesetApplied':
        if (
          displayAssessmentField &&
          displayAssessment &&
          report.assessment?.mvr_ruleset
        )
          fieldsArr.push({
            fieldName: () => 'Mvr Ruleset Applied',
            fieldKey: 'mvrRulesetApplied',
            hasTemplate: true,
          });
        break;
      case 'adjudication':
        fieldsArr.push({
          fieldName: () => 'Adjudication',
          fieldKey: 'adjudication',
          hasTemplate: true,
        });
        break;
      case 'package':
        fieldsArr.push({
          fieldName: () => 'Package',
          fieldKey: 'package',
          hasTemplate: true,
        });
        break;
      case 'nodes':
        if (segmentationEnabled) {
          fieldsArr.push({
            fieldName: () => 'Nodes',
            fieldKey: 'nodes',
            hasTemplate: true,
          });
        }
        break;
      case 'program':
        if (report?.program?.name) {
          fieldsArr.push({
            fieldName: () => 'Program',
            fieldKey: 'program',
            hasTemplate: true,
          });
        }
        break;
      case 'source':
        fieldsArr.push({
          fieldName: () => 'Source',
          fieldKey: 'source',
          hasTemplate: true,
        });
        break;
      case 'createdAt':
        fieldsArr.push({
          fieldName: () => 'Created at',
          fieldKey: 'created_at',
          hasTemplate: true,
        });
        break;
      case 'upgradedAt':
        if (report.upgraded_at)
          fieldsArr.push({
            fieldName: () => 'Upgraded at',
            fieldKey: 'upgraded_at',
            hasTemplate: true,
          });
        break;
      case 'completedAt':
        fieldsArr.push({
          fieldName: () => 'Completed at',
          fieldKey: 'completed_at',
          hasTemplate: true,
        });
        break;
      case 'revisedAt':
        if (report.revised_at)
          fieldsArr.push({
            fieldName: () => 'Revised at',
            fieldKey: 'revised_at',
            hasTemplate: true,
          });
        break;
      case 'eta':
        if (report?.status === 'pending' && reportPrediction) {
          fieldsArr.push(
            {
              fieldName: () => 'Est. Completion',
              fieldKey: 'eta_completed',
              hasTemplate: true,
            },
            {
              fieldName: () => 'Est. Last updated at',
              fieldKey: 'eta_updated',
              hasTemplate: true,
            },
          );
        }
        break;
      case 'turnaroundTime':
        fieldsArr.push({
          fieldName: () => 'Turnaround time',
          fieldKey: 'turnaround_time',
          hasTemplate: true,
        });
        break;
      case 'geos':
        if (report.geos && report?.geos?.length > 0)
          fieldsArr.push({
            fieldName: () => {
              const multipleGeos = report.geos && report?.geos?.length > 1;
              if (currentUser?.account?.segmentation_enabled) {
                return !multipleGeos ? 'Geo' : 'Geos';
              }
              return !multipleGeos ? 'Compliance geo' : 'Compliance geos';
            },
            fieldKey: 'geos',
            hasTemplate: true,
          });
        break;
      case 'workLocation':
        if (report?.work_locations?.length > 0)
          fieldsArr.push({
            fieldName: () => {
              const multipleLocations = report.work_locations.length > 1;
              return !multipleLocations ? 'Work Location' : 'Work Locations';
            },
            fieldKey: 'work_locations',
            hasTemplate: true,
          });
        break;
    }
  });

  return fieldsArr;
};

/**
 * @name buildCol1Config
 * @function
 * @memberOf ReportInformation/ReportInformation
 * @description Build the config to be used with M.KeyValueList
 * @returns {array} - The config
 * @param {object} report - The report object
 * @param {object} currentUser - The currentUser object
 * @param {Function} allowCandidateEdit - Whether this user can edit candidate details
 */
const buildColumnConfig = (
  report: Report,
  currentUser: User,
  allowCandidateEdit: Function,
  statusType: string,
  reportPrediction?: string,
  isIframe?: boolean,
  contextId?: string | null,
  reportEstimationGenerated?: string,
) => {
  const reportInfoFields = buildReportInfoFields(
    statusType,
    report,
    currentUser,
    reportPrediction,
    reportEstimationGenerated,
  );

  const fields: FieldConfig[] = reportInfoFields;

  const config: FieldKVListConfig[] = fields
    .map((field: FieldConfig) => {
      const { fieldName, fieldKey, hasTemplate }: FieldConfig = field;
      let item: FieldKVListConfig = {
        itemKey: null,
        itemValue: null,
      };

      // @ts-ignore TODO: Remove comment once CurrentUser type is updated in Utils
      const renderedFieldName = fieldName(report, currentUser);
      item = getColConfigItem(
        renderedFieldName,
        fieldKey,
        hasTemplate,
        report,
        currentUser,
        allowCandidateEdit,
        statusType,
        reportPrediction,
        isIframe,
        contextId,
        reportEstimationGenerated,
      );
      return item;
    })
    .filter(item => item.itemKey);
  return config;
};

// map of api field keys to components
const componentMap: {
  [fieldKey: string]: React.FC<any>;
} = {
  status: FieldComponents.Status,
  assessment: FieldComponents.Assessment,
  rulesetApplied: FieldComponents.RulesetApplied,
  mvrRulesetApplied: FieldComponents.MvrRulesetApplied,
  adjudication: FieldComponents.Adjudication,
  package: FieldComponents.Package,
  nodes: FieldComponents.Nodes,
  program: FieldComponents.Program,
  source: FieldComponents.Source,
  created_at: FieldComponents.Date,
  upgraded_at: FieldComponents.Date,
  completed_at: FieldComponents.Date,
  revised_at: FieldComponents.Date,
  eta_completed: FieldComponents.EtaCompleted,
  eta_updated: FieldComponents.EtaUpdated,
  turnaround_time: FieldComponents.TurnaroundTime,
  geos: FieldComponents.Geos,
  work_locations: FieldComponents.WorkLocations,
};

/**
 * @name getColConfigItem
 * @function
 * @memberOf ReportInformation/ReportInformation
 * @description Get a single config object to be used with M.KeyValueList
 * @returns {object} - The single config object
 */
const getColConfigItem = (
  fieldName: string,
  fieldKey: string,
  hasTemplate: boolean,
  report: Report,
  currentUser: User,
  allowCandidateEdit: Function,
  statusType: String,
  reportPrediction?: string,
  isIframe?: boolean,
  contextId?: string | null,
  reportEstimationGenerated?: string,
) => {
  // define KVList config item object
  const configItem: FieldKVListConfig = {
    itemKey: null,
    itemValue: null,
  };

  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
  const displayAssessment = getReportDecision(report, currentUser);
  const displayAdjudication = report.adjudication;

  // set KVList key
  configItem.itemKey = fieldName;

  // set KVList value
  if (hasTemplate) {
    const FieldComponent = componentMap[fieldKey];
    configItem.itemValue = (
      <FieldComponent
        fieldKey={fieldKey}
        displayName={fieldName}
        allowCandidateEdit={allowCandidateEdit(fieldKey)}
        report={report}
        displayAssessment={displayAssessment}
        displayAdjudication={displayAdjudication}
        currentUser={currentUser}
        statusType={statusType}
        reportPrediction={reportPrediction}
        testid={`report-information-field-${fieldKey}`}
        isIframe={isIframe}
        contextId={contextId}
        reportEstimationGenerated={reportEstimationGenerated}
      />
    );
  } else {
    configItem.itemValue = (
      <span data-testid={`report-information-field-${fieldKey}`}>
        {report[fieldKey] || '-'}
      </span>
    );
  }

  return configItem;
};

const ReportInformation: React.FC<{
  report: Report;
  isIframe?: boolean;
  contextId?: string | null;
}> = ({ report, isIframe, contextId }) => {
  const [reportInfoList, setReportInfoList] = useState<GenericObject[]>([]);
  const candidate = useCandidate();
  const currentUser = useUser();
  const { reportPrediction, reportEstimationGenerated } = useEta();

  const statusType = useMemo(
    // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
    () => getStatusTypeForReport(report, currentUser),
    [report, currentUser],
  );

  const allowCandidateEdit = useCallback(
    (field: string) => {
      if (field === 'package') {
        return !!report && hasPermission(currentUser, `perform_upgrade`);
      }
      return !!report && currentUser.roles?.includes('admin');
    },
    [report, currentUser],
  );

  useEffect(() => {
    if (report && report.id && currentUser) {
      setReportInfoList(
        buildColumnConfig(
          report,
          currentUser,
          allowCandidateEdit,
          statusType,
          reportPrediction,
          isIframe,
          contextId,
          reportEstimationGenerated,
        ),
      );
    }
  }, [
    allowCandidateEdit,
    candidate,
    currentUser,
    report,
    statusType,
    reportPrediction,
    isIframe,
    contextId,
    reportEstimationGenerated,
  ]);

  const reportInfoItems = reportInfoList;
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';
  const showArchivedReportBanner = archiveReportFlagEnabled && report.archived;

  return (
    <ReportInformationContainer
      data-testid='report-information'
      data-floating-menu-container
    >
      <M.Grid>
        {showArchivedReportBanner && (
          <ArchivedReportBanner>
            <M.Icon icon='Box' size={20} />
            This report is archived
          </ArchivedReportBanner>
        )}
        <M.Container.Row>
          <h5 className='mb-0'>Report information</h5>
        </M.Container.Row>
        <M.Container.Row style={{ paddingTop: 0 }}>
          <M.KeyValueList
            items={reportInfoItems}
            data-testid='report-information-column-1'
          />
        </M.Container.Row>
        {!archiveReportFlagEnabled && (
          <M.Container.Row className='report-actions'>
            <M.Container.Col style={{ width: '50%' }}>
              <TagsContainer report={report} />
            </M.Container.Col>
            <M.Container.Col
              style={{ display: 'flex', flexDirection: 'column' }}
              className='download-pdfs-column'
            >
              {(hasPermission(currentUser, 'read_reports') ||
                hasPermission(currentUser, 'download_health_pdf')) && (
                // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
                <DownloadPdfButton currentUser={currentUser} report={report} />
              )}
            </M.Container.Col>
          </M.Container.Row>
        )}
        {archiveReportFlagEnabled && (
          <>
            <M.Container.Row className='report-actions'>
              <M.Container.Col style={{ maxWidth: '50%' }}>
                <TagsContainer report={report} />
              </M.Container.Col>
            </M.Container.Row>

            <M.Container.Row style={{ paddingTop: '1.25rem', display: 'flex' }}>
              {(hasPermission(currentUser, 'read_reports') ||
                hasPermission(currentUser, 'download_health_pdf')) && (
                <DownloadPdfButton
                  currentUser={currentUser}
                  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
                  report={report}
                />
              )}

              <ArchiveReportAction report={report} />
            </M.Container.Row>
          </>
        )}
      </M.Grid>
    </ReportInformationContainer>
  );
};

export default ReportInformation;
