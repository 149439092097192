import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type Props = {
  subcategory?: string;
};

const Container = styled.span`
  color: ${colors.brandNavy4};
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
`;

const Header: React.FC<Props> = ({ subcategory }) => {
  const { t } = useTranslation('assess:v2');
  const header: string = t('common.lookback_rules.subcategory.charges.header', {
    subcategory,
  });

  return (
    <Container
      className='charges-header'
      data-testid='assess-v2-ui-common-lookback-rules-subcategory-charges-header'
    >
      {header}
    </Container>
  );
};

export default Header;
