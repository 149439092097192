import * as Entity from 'modules/assess/models/settings/account';
import React from 'react';
import SettingToggle from 'modules/assess/ui/common/settings/setting-toggle';
import Title from './title';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const Container: React.FC<Props> = ({ settings, setSettings }) => {
  return (
    <div>
      <Title />
      <SettingToggle
        name='assess_context_date_override'
        versionClass='v1'
        settings={settings}
        setSettings={setSettings}
      />
    </div>
  );
};

export default Container;
