import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const statusColorMap: { [key: string]: string } = {
  invited: colors.brandSlate5,
  pending: colors.uiNavy500,
  clear: colors.uiGreen500,
  review: colors.uiYellow500,
  escalated: colors.uiPink500,
  disputed: colors.uiOrange500,
};

const StatusCardIcon = styled(M.Icon)`
  visibility: hidden;
  color: ${colors.uiGrey600};
`;

const StatusCardContainer = styled.div`
  cursor: pointer;
  background-color: ${colors.uiGrey0};
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 16px 16px 16px 20px;
  flex: 1;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 24px -8px rgba(98, 110, 122, 0.1),
      0px 8px 32px -16px var(--ui-hover, rgba(135, 147, 161, 0.1));
    ${StatusCardIcon} {
      visibility: visible;
    }
  }

  &:focus {
    box-shadow: 0px 0px 4px -8px rgba(98, 110, 122, 0.1);
  }
`;

const StatusCardColor = styled.div<{
  status: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 200px;
  background-color: ${props => statusColorMap[props.status]};
`;

const StatusCardPrimaryTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 8px;

  tr td div {
    width: 48px !important;
  }
`;

const StatusCardPrimaryText = styled.h3`
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;
  font-family: Helvetica Neue;
  color: ${colors.uiTextPrimaryLight};
`;

const StatusCardSecondaryTextContainer = styled.div`
  width: 100%;
  font-size: 0.8rem !important;
  font-weight: 500;
  color: ${colors.uiGrey600};

  label {
    cursor: pointer;
  }
`;

const styles = {
  StatusCardIcon,
  StatusCardContainer,
  StatusCardColor,
  StatusCardPrimaryTextContainer,
  StatusCardPrimaryText,
  StatusCardSecondaryTextContainer,
};

export default styles;
