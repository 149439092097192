import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const StyledAvatar = styled.span<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  border: 1.5px dashed ${colors.borderPrimaryLight};
  color: ${colors.uiTextDisabledLight};
`;

export const UnassignedAvatar = ({ size = 25 }) => (
  <StyledAvatar data-testid='unassigned-avatar' size={size}>
    <M.Icon icon='Add' />
  </StyledAvatar>
);

export default UnassignedAvatar;
