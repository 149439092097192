import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY } from 'Constants';
import { Preferences, PreferencesOld } from 'components/Preferences';

const PreferencesContainer: React.FC<{}> = () => {
  const newUserPreferencesEnabled =
    useFlag(USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY)?.variantKey === 'on' ||
    false;

  return newUserPreferencesEnabled ? (
    <Preferences />
  ) : (
    // TODO Remove after delivery the user preference improvements
    // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
    <PreferencesOld />
  );
};

export default PreferencesContainer;
