import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';

import { CountyCriminalSearch } from 'types';

import PointerList from '../PointerList';

type Props = {
  screenings?: CountyCriminalSearch[];
};

const AdditionalInfo: React.FC<Props> = ({ screenings }) => {
  return (
    <>
      {screenings?.map(screening => {
        const additionalInfoItems = [];
        const { screening_pointers, county, state } = screening;
        const title = `${county}, ${state}`;
        const status = getResultOrStatus(screening);
        const isScreeningComplete = status !== ReportStatuses.PENDING;

        // Add pointers to Additional Info items
        if (screening_pointers) {
          additionalInfoItems.push({
            itemKey: 'Pointer(s):',
            itemValue: <PointerList pointers={screening_pointers} />,
          });
        }

        return (
          isScreeningComplete && (
            <M.AdditionalInfo
              data-testid='county-crim-search-additional-info'
              key={title}
              title={title}
              items={additionalInfoItems}
              type='shadow'
            />
          )
        );
      })}
    </>
  );
};

export default AdditionalInfo;
