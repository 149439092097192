import React from 'react';
import { formatDate } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../types';

const DispositionDate: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  const dispositionDate: any = adverseItem.adversible.disposition_date;
  return (
    <span data-testid={testid} className='value-width'>
      {formatDate(dispositionDate, 'MMM Do YYYY')}
    </span>
  );
};

export default DispositionDate;
