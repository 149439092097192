import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetCsvExport } from 'api/continuousChecks';
import ContinuousServicesCsvExport from 'components/ContinuousServices/ContinuousServicesCsvExport';

type Props = {
  exportId: string;
};

const ContinuousServicesCsvExportContainer: React.FC<Props> = ({
  exportId,
}) => {
  const { isLoading, data } = useGetCsvExport(exportId);

  if (isLoading) {
    return <M.LoadingInline />;
  }

  return (
    <ContinuousServicesCsvExport
      success={data !== undefined && data.download_uri != null}
      downloadUri={data ? data.download_uri : ''}
    />
  );
};

export default ContinuousServicesCsvExportContainer;
