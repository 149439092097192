import { colors } from '@dashboard-experience/mastodon';
import React from 'react';
import {
  CsInfoSpan,
  Icon,
  InformationContainer,
  Link,
  SyncResultInfoContainer,
  SyncResultTypography,
} from './styles';
import { Status } from '../../types';

type Props = {
  syncId: string;
  status: Status;
};

const SyncResult: React.FC<Props> = ({ syncId, status }: Props) => {
  const isSyncing = status === 'Syncing';
  return (
    <InformationContainer data-testid={`ats-sync-result-container-${syncId}`}>
      {!isSyncing && status !== 'Idle' && (
        <SyncResultInfoContainer
          data-testid={`ats-sync-result-info-container-${syncId}`}
        >
          <Icon
            fill={
              status === 'Success'
                ? colors.uiGreen600
                : colors.brandOrangeAccent2
            }
            icon={status === 'Success' ? 'Checkmark' : 'WarningFilled'}
            data-testid={`ats-sync-result-icon-${syncId}-${status.toLowerCase()}`}
          />
          <SyncResultTypography
            status={status}
            data-testid={`ats-sync-result-text-${syncId}`}
          >
            {status === 'Success' ? 'Sync successful' : 'Sync failed'}
          </SyncResultTypography>
        </SyncResultInfoContainer>
      )}
      {status === 'Failure' && (
        <div data-testid={`ats-sync-result-failure-container-${syncId}`}>
          <CsInfoSpan data-testid={`ats-sync-result-failure-span-${syncId}`}>
            Contact support:
          </CsInfoSpan>
          <Link
            data-testid={`ats-sync-result-failure-link-${syncId}`}
            href='mailto:help@checkr.com'
            target='_blank'
          >
            help@checkr.com
          </Link>
        </div>
      )}
    </InformationContainer>
  );
};

export default SyncResult;
