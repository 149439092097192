import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  ExpandableDescription,
  StyledComponents,
} from 'modules/assess/v2/ui/common';
import { Description, Row } from 'modules/assess/v2/ui/common/premade/rule';
import Delete from './Delete';
import Title from './Title';

const BadgeContainer = styled.div`
  width: 90px;
`;

const StyledDiv = styled.div`
  display: flex;
  padding-bottom: 0;
`;

const StyledDescription = styled.div`
  margin-left: 90px;
`;

const BadgeTitle = styled.div`
  align-items: center;
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

type Props = {
  row: Row;
};

export const Container: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const status = t('assess:decisions.eligible').toLowerCase();
  const basePath =
    'assess-v2-ui-guideline-quickstart-added-guidelines-category-rule-container';
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;
  const statusId = `${basePath}-status`;

  return (
    <StyledComponents.SlateContainer
      id={`templated-added-guideline-${row.rule.code}`}
      data-testid='assess-v2-ui-standard-guidelines-quickstart-added-guidelines-category-rule-container'
    >
      <StyledDiv>
        <BadgeTitle>
          <BadgeContainer>
            <M.StatusBadge status={status} data-testid={statusId} />
          </BadgeContainer>
          <Title title={row.rule.name} data-testid={titleId} />
        </BadgeTitle>
        <Actions>
          <Delete row={row} />
        </Actions>
      </StyledDiv>
      <ExpandableDescription>
        <StyledDescription>
          <Description
            hideLabel
            description={row.rule.description}
            data-testid={descriptionId}
          />
        </StyledDescription>
      </ExpandableDescription>
    </StyledComponents.SlateContainer>
  );
};

export default Container;
