import React, { useEffect } from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { colors, M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useGetCandidates } from 'api/search';
import { GenericObject } from '@dashboard-experience/utils';
import { ORDER_QUEUING_SUPPORT_FLAG_KEY } from '../../Flags';

const VerificationBanner = styled.div`
  background-color: ${colors.uiNavy100};
  border: 1px solid ${colors.uiNavy200};
  color: ${colors.uiTextPrimaryLight};
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 2rem;

  #mastodon & {
    h3,
    p:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    h3 + p {
      margin-top: 0;
    }

    .link-banner {
      color: ${colors.uiTextPrimaryLight};
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;
const UncredentialedAccountNotification = ({ candidates }: GenericObject) => {
  const orderQueuingFlagrEnabled =
    useFlag(ORDER_QUEUING_SUPPORT_FLAG_KEY)?.variantKey === 'on';

  const { call: candidatesCall, result: candidatesResult } = useGetCandidates();

  const candidatesList = candidates || candidatesResult?.data;

  useEffect(() => {
    if (!candidatesList)
      candidatesCall({
        params: 'search?order_by=created_at&page=1&per_page=25&sort=desc',
        cancelToken: undefined,
      });
  }, [candidatesCall, candidatesList]);

  return orderQueuingFlagrEnabled ? (
    <M.Restrict authorized={!!candidatesList}>
      <VerificationBanner>
        <h3>We’re verifying your account</h3>
        <p>
          This usually takes less than one business day. We&apos;ll send
          candidate invitations and email you a confirmation after your business
          is verified. To place a new order, select{' '}
          <strong>Order background check</strong> above.{' '}
          <a
            className='link-banner'
            href='https://help.checkr.com/hc/en-us/articles/360000020208-Welcome-to-Checkr-'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more
          </a>
        </p>
        {candidatesList?.count > 0 && (
          <p>
            You have{' '}
            {pluralize('scheduled invitation', candidatesList?.count, true)}.
          </p>
        )}
      </VerificationBanner>
    </M.Restrict>
  ) : null;
};

export default UncredentialedAccountNotification;
