import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

interface SortableTableHeaderProps {
  headerKey: string;
  headerText: string;
  sortable: boolean;
  sortDirection: string;
  onHeaderClick: (headerKey: string, sortDirection: string) => void;
}

const sortDirectionTransitions: { [old: string]: string } = {
  NONE: 'ASC',
  ASC: 'DESC',
  DESC: 'ASC',
};

const SortableTableHeader = ({
  headerKey,
  headerText,
  sortable,
  sortDirection,
  onHeaderClick,
}: SortableTableHeaderProps) => {
  const handleHeaderClick = useCallback(() => {
    onHeaderClick(headerKey, sortDirectionTransitions[sortDirection]);
  }, [headerKey, sortDirection, onHeaderClick]);

  return (
    <M.TableHeader
      key={headerKey}
      isSortable={sortable}
      isSortHeader={sortDirection !== 'NONE'}
      sortDirection={sortDirection}
      onClick={sortable ? handleHeaderClick : undefined}
    >
      {headerText}
    </M.TableHeader>
  );
};

export default SortableTableHeader;
