import React, { useRef } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useMultiSelectFilter } from '../hooks';
import { onFilterChange } from '../helpers';

type Props = {
  component: any;
  param: string;
  onChange: (updateFilters: any) => void;
  [x: string]: any;
};

const MultiSelectFilter: React.FC<Props> = ({
  component,
  param,
  onChange,
  ...props
}) => {
  const ref = useRef();
  const { state, setFilters } = useMultiSelectFilter(param, ref);
  const Filter = M[component] || component;

  const updateFilters = onFilterChange(setFilters, param);

  return (
    <Filter
      onChange={onChange(updateFilters)}
      ref={ref}
      selectedItems={state || []}
      maxItems={100}
      wrapText
      {...props}
    />
  );
};

export default MultiSelectFilter;
