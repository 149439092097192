import { RequestConfig } from 'utils/APIClient';
import * as CCS from 'modules/assess/models/mvr/category-code-set';
import {
  AccountParams,
  CollectionResults,
  PageParams,
} from 'modules/core-types/api-core';
import { path } from 'modules/assess/api/category-code-set/uri';
import { Changes as ChangeResults } from 'modules/assess/models/mvr/category-code-set-versions/version-diff/changes';
import { ID } from '../../models/entity';
import client from '../client';

const BASE_PATH = 'mvr/ccs';

export type CreateResult = CCS.Version.Basic;
export const create = (instance: ID): Promise<CreateResult> => {
  return client.post(`/${BASE_PATH}/${instance}/versions`);
};

export const fetch = (
  instance: ID,
  version: ID,
  options: RequestConfig,
): Promise<CCS.Version.Full> => {
  return client.get(`/${BASE_PATH}/${instance}/versions/${version}`, options);
};

export const getChanges = (
  instance: ID,
  version: ID,
): Promise<ChangeResults> => {
  return client.get(`/${BASE_PATH}/${instance}/versions/${version}/changes`);
};

export const promote = (instance: ID, version: ID): Promise<void> => {
  return client.post(`${path(instance, version)}/promote`);
};

export const publish = (instance: ID, version: ID): Promise<void> => {
  return client.post(`${path(instance, version)}/publish`);
};

export interface SearchParams extends AccountParams, PageParams {}

export type SearchResults = CollectionResults<CCS.Version.Basic>;

export const search = (
  instance: ID,
  params?: SearchParams,
): Promise<SearchResults> => {
  return client.get(`/${BASE_PATH}/${instance}/versions`, { params });
};

export const destroy = (instance?: ID, version?: ID) => {
  return client.delete(path(instance, version));
};
