import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledImage = styled.img`
  display: block;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  object-position: right;
  height: 105.42px;
  width: 100%;
`;

const ImageContainer = styled.div`
  justify-content: end;
  display: flex;
  overflow: hidden;
  height: 105.42px;
`;

const FeaturedWrapper = styled.a`
  display: flex;
  flex-direction: column;

  gap: 0px;
  border-radius: 8px;
  border: 1px solid ${colors.uiGrey200};

  cursor: pointer;
  font-style: normal;
  position: relative;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.hover};
    svg {
      position: absolute;
      top: 12px;
      right: 12px;
      color: ${colors.uiTextTertiaryLight};
    }
  }

  svg {
    position: absolute;
    color: ${colors.uiTextDisabledLight};
    top: 16px;
    right: 16px;
    height: 20px;
    width: 20px;
    transition: 0.3s;
  }

  @media only screen and (min-width: 0px) {
    min-width: 100%;
  }

  @media only screen and (min-width: 990px) {
    min-width: 50%;
  }
`;

const FeaturedContentsContainer = styled.div`
  width: 100%;
  padding: 24px;
  gap: 8px;
  opacity: 0px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.h5`
  color: ${colors.uiTextTertiaryLight} !important;
  margin: 0 !important;
`;

const Title = styled.h3`
  margin: 0 !important;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: ${colors.uiTextSecondaryLight};
`;

const styles = {
  Description,
  FeaturedContentsContainer,
  FeaturedWrapper,
  ImageContainer,
  Label,
  StyledImage,
  Title,
};

export default styles;
