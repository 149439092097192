import React, { SyntheticEvent } from 'react';
import CustomButton from 'components/Button';

type Props = {
  disabled?: boolean;
  onClick: (e: SyntheticEvent) => void;
};

export const Button: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <CustomButton
      data-testid='assess-rulesets-actions-create'
      disabled={disabled}
      size='sm'
      trans='assess:rulesets.actions.create.label'
      onClick={onClick}
    />
  );
};

export default Button;
