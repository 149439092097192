import React, { useCallback, useEffect, useMemo } from 'react';
import DisplayAliasPrice from 'components/Packages/Alias/DisplayAliasPrice';
import { ALIAS_AS_AN_ADDON } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import { aliasScreenings } from 'utils/PackageUtils';
import { Screening } from 'types';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  ALIASES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import {
  getAdditionalPropertiesLabel,
  getDollarAmount,
  getScreeningSummaryLabel,
} from '../shared/utils';

import LearnMoreLink from '../shared/LearnMoreLink';
import ThirdPartyFees from '../shared/ThirdPartyFees';
import {
  employerCurrentOrLast10Years,
  employersLast7Years as defaultEmploymentVerificationPanel,
} from './List/VerificationScreenings/EmploymentVerification';
import AddAlias from '../../Packages/Alias/AddAlias';
import {
  LinePrice,
  LinePriceSection,
  SummaryHeading,
  SubtotalHeading,
  PackageNameHeading,
  SummarySection,
  PackagePriceSpan,
  ScreeningList,
  ScreeningListItem,
  ScreeningLabel,
  AddOnsHeading,
  BoldLinePrice,
  LoadingSpinner,
  FlexDiv,
  ScreeningListLinePrice,
  BulletPoint,
  StyledAddOnsSummaryPrice,
  NoSelectedPackageLabel,
  PackageHeaderWrapper,
  EditButton,
  AliasPriceRow,
} from './shared/SummaryStyles';

const DomesticAddScreeningsSummary: React.FC<any> = ({
  basePackage,
  basePackagePrices,
  basePackageScreeningTypes,
  pendingAddOnPricesQuery,
  pendingAddOnPricesQueryAlias,
  hasAddedScreenings,
  additionalProperties,
  goBack,
  handleAliasClick = () => {},
  aliasEnabled = AliasesEnabledType.OFF,
  setAliasPrice = () => {},
  handlePriceSnapshot = () => {},
  getPrices,
}) => {
  const hasAliasSkus = Boolean(basePackage?.use_alias_skus);
  const currentUser = useUser();
  const trackEvent = useTrackEvent();

  const addedOnScreenings = useMemo(() => {
    return pendingAddOnPricesQuery?.data?.items?.filter(
      (screening: any) =>
        !basePackageScreeningTypes.includes(screening.product_type),
    );
  }, [basePackageScreeningTypes, pendingAddOnPricesQuery?.data?.items]);

  const hasAliasScreening = useMemo(() => {
    return basePackage.screenings
      .map((p: Screening) => p.type)
      .some((t: string) => aliasScreenings.includes(t));
  }, [basePackage]);
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  const displayAlias = aliasFlag && hasAliasScreening;

  useEffect(() => {
    if (displayAlias) {
      trackEvent(currentUser, ALIASES.ALIAS_OFFERED);
    }
  }, [currentUser, displayAlias, trackEvent]);

  const trackTooltipClicked = useCallback(() => {
    trackEvent(currentUser, ALIASES.ALIAS_TOOLTIP_CLICKED);
  }, [currentUser, trackEvent]);

  const theAliasPrice = useMemo(() => {
    const withoutAlias =
      (pendingAddOnPricesQuery &&
        pendingAddOnPricesQuery?.data &&
        pendingAddOnPricesQuery?.data?.bundled_price) ??
      0;

    const withAlias =
      (pendingAddOnPricesQueryAlias &&
        pendingAddOnPricesQueryAlias?.data &&
        pendingAddOnPricesQueryAlias?.data?.bundled_price) ??
      0;

    if (withAlias === 0) return withAlias;

    return withAlias - withoutAlias;
  }, [pendingAddOnPricesQuery, pendingAddOnPricesQueryAlias]);

  const basePackagePrice = useMemo(() => {
    if (!basePackagePrices || !basePackagePrices.fixed_price) {
      return null;
    }
    if (hasAliasSkus) {
      return basePackagePrices.fixed_price - theAliasPrice;
    }
    return basePackagePrices.fixed_price;
  }, [basePackagePrices, hasAliasSkus]);

  const fees = useMemo(() => {
    if (
      Boolean(basePackage?.use_alias_skus) ||
      aliasEnabled === AliasesEnabledType.ON ||
      (aliasEnabled === AliasesEnabledType.ACCOUNT &&
        currentUser.account.aliases_enabled)
    ) {
      return {
        serviceFeeMin:
          pendingAddOnPricesQueryAlias?.data?.service_fee_range[0] || 0,
        serviceFeeMax:
          pendingAddOnPricesQueryAlias?.data?.service_fee_range[1] || 0,
        passthroughFeeMin:
          pendingAddOnPricesQueryAlias?.data?.passthrough_fee_range[0] || 0,
        passthroughFeeMax:
          pendingAddOnPricesQueryAlias?.data?.passthrough_fee_range[1] || 0,
      };
    }
    return {
      serviceFeeMin: pendingAddOnPricesQuery?.data?.service_fee_range[0] || 0,
      serviceFeeMax: pendingAddOnPricesQuery?.data?.service_fee_range[1] || 0,
      passthroughFeeMin:
        pendingAddOnPricesQuery?.data?.passthrough_fee_range[0] || 0,
      passthroughFeeMax:
        pendingAddOnPricesQuery?.data?.passthrough_fee_range[1] || 0,
    };
  }, [
    aliasEnabled,
    basePackage,
    pendingAddOnPricesQuery,
    pendingAddOnPricesQueryAlias,
  ]);

  const subtotalPrice = useMemo(() => {
    const basePackagePriceWithoutAlias =
      pendingAddOnPricesQuery?.data?.fixed_price;
    if (hasAliasSkus) {
      return getDollarAmount(basePackagePriceWithoutAlias + theAliasPrice);
    }

    if (aliasEnabled === AliasesEnabledType.ON) {
      return getDollarAmount(
        pendingAddOnPricesQueryAlias?.data?.fixed_price ?? 0,
      );
    }
    return getDollarAmount(pendingAddOnPricesQuery?.data?.fixed_price ?? 0);
  }, [
    aliasEnabled,
    hasAliasSkus,
    pendingAddOnPricesQuery?.data?.fixed_price,
    pendingAddOnPricesQueryAlias?.data?.fixed_price,
    theAliasPrice,
  ]);

  useEffect(() => {
    setAliasPrice(theAliasPrice);
  }, [setAliasPrice, theAliasPrice]);

  useEffect(() => {
    const priceObj = {
      basePackagePrice,
      addonPrices: addedOnScreenings,
      aliasPrice: theAliasPrice,
      subtotalPrice,
    };
    if (getPrices) {
      getPrices(priceObj);
    }
  }, [
    addedOnScreenings,
    basePackagePrice,
    getPrices,
    subtotalPrice,
    theAliasPrice,
  ]);

  useEffect(() => {
    if (pendingAddOnPricesQuery?.data || pendingAddOnPricesQueryAlias?.data) {
      handlePriceSnapshot({
        basePackagePrice,
        addonPrices: addedOnScreenings,
        aliasPrice: theAliasPrice,
        subtotalPerCandidate: subtotalPrice,
      });
    }
  }, [
    handlePriceSnapshot,
    addedOnScreenings,
    basePackagePrice,
    subtotalPrice,
    theAliasPrice,
    pendingAddOnPricesQuery?.data,
    pendingAddOnPricesQueryAlias?.data,
  ]);

  if (!pendingAddOnPricesQuery) {
    return <LoadingSpinner withOverlay={false} />;
  }

  const { data, isLoading, isError } = pendingAddOnPricesQuery;

  return (
    <>
      <SummaryHeading
        data-testid='legacy-screening-summary-heading'
        className='summary-heading'
      >
        Package summary
      </SummaryHeading>
      {basePackage.screenings.length !== 0 ? (
        <SummarySection className='summary-section'>
          <LinePrice className='line-price'>
            <PackageHeaderWrapper>
              <PackageNameHeading
                className='package-name-heading'
                data-testid='summary-section-package-name'
              >
                {basePackage.name}
              </PackageNameHeading>
              {!!goBack && (
                <EditButton kind='tertiary' onClick={goBack}>
                  Edit
                </EditButton>
              )}
            </PackageHeaderWrapper>
            {basePackage.international_only ? (
              <LearnMoreLink
                linkText='Price varies'
                data-testid='summary-section-line-price'
                href='https://checkr.com/pricing/international'
              />
            ) : (
              <PackagePriceSpan data-testid='summary-section-line-price'>
                {!basePackagePrice || isNaN(basePackagePrice)
                  ? 'Loading price...'
                  : getDollarAmount(`${basePackagePrice}`)}
              </PackagePriceSpan>
            )}
          </LinePrice>
          <ScreeningList data-testid='summary-section-screening-list'>
            {basePackageScreeningTypes.map(
              (screeningType: any) =>
                screeningType !== 'international_adverse_media_search' && (
                  <ScreeningListItem
                    className='screening-list-item'
                    key={screeningType}
                  >
                    <ScreeningLabel
                      className='screening-label'
                      data-testId='package-screening-label'
                      key={`${screeningType}-label`}
                    >
                      {getScreeningSummaryLabel(
                        screeningType,
                        additionalProperties,
                      )}
                    </ScreeningLabel>
                  </ScreeningListItem>
                ),
            )}
          </ScreeningList>
        </SummarySection>
      ) : (
        <SummarySection className='summary-section'>
          <PackageHeaderWrapper>
            <PackageNameHeading
              className='package-name-heading'
              data-testid='summary-section-package-name'
            >
              Package
            </PackageNameHeading>
            {!!goBack && (
              <EditButton kind='tertiary' onClick={goBack}>
                Edit
              </EditButton>
            )}
          </PackageHeaderWrapper>
          <NoSelectedPackageLabel className='no-selected-package-label'>
            <div>You haven&apos;t selected a package</div>
          </NoSelectedPackageLabel>
        </SummarySection>
      )}
      {isError && (
        <SummarySection className='summary-section'>
          Something went wrong. Please try adding your screening again.
        </SummarySection>
      )}
      {data && (
        <>
          {hasAddedScreenings && (
            <SummarySection className='summary-section'>
              {basePackage.international_only ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h5>Add-ons </h5>
                  <LearnMoreLink
                    linkText='Price varies'
                    href='https://checkr.com/pricing/international'
                  />
                </div>
              ) : (
                <AddOnsHeading>Add-ons</AddOnsHeading>
              )}
              <ScreeningList>
                {data.items
                  ?.filter((screening: any) => {
                    if (
                      screening.product_type === 'occupational_health_screening'
                    ) {
                      return true;
                    }
                    return !basePackageScreeningTypes.includes(
                      screening.product_type,
                    );
                  })
                  .map(
                    (screening: any) =>
                      screening.product_type !==
                        'international_adverse_media_search' && (
                        <ScreeningListItem
                          className='screening-list-item'
                          key={screening.product_type}
                        >
                          <ScreeningListLinePrice className='screening-list-line-price'>
                            <LinePriceSection className='line-price-section'>
                              <div className='add-on-summary-name-container'>
                                <ScreeningLabel className='screening-label'>
                                  {getScreeningSummaryLabel(
                                    screening.product_type,
                                    additionalProperties,
                                  )}
                                </ScreeningLabel>
                                {getAdditionalPropertiesLabel(
                                  screening.product_type,
                                  additionalProperties,
                                ).map((property: string) => {
                                  return (
                                    <FlexDiv
                                      key={
                                        property ||
                                        defaultEmploymentVerificationPanel.label
                                      }
                                    >
                                      <BulletPoint />
                                      {/* eslint-disable-next-line no-nested-ternary */}
                                      {property ===
                                      employerCurrentOrLast10Years.label
                                        ? 'Current or most recent (10 years)'
                                        : property ||
                                          defaultEmploymentVerificationPanel.label}
                                    </FlexDiv>
                                  );
                                })}
                              </div>
                            </LinePriceSection>
                            <StyledAddOnsSummaryPrice
                              className='styled-add-ons-summary-price'
                              screening={screening}
                              data={data}
                            />
                          </ScreeningListLinePrice>
                        </ScreeningListItem>
                      ),
                  )}
              </ScreeningList>
            </SummarySection>
          )}
          {!hasAddedScreenings && basePackage.screenings.length === 0 && (
            <SummarySection className='summary-section'>
              <AddOnsHeading>Add-ons</AddOnsHeading>
              <NoSelectedPackageLabel className='no-selected-package-label'>
                <div>You haven&apos;t selected any add-ons.</div>
              </NoSelectedPackageLabel>
            </SummarySection>
          )}
        </>
      )}
      {displayAlias && !hasAliasSkus && (
        <SummarySection>
          <AddAlias
            onClick={handleAliasClick}
            price={getDollarAmount(theAliasPrice)}
            aliasesEnabled={aliasEnabled}
            onTooltipClick={trackTooltipClicked}
          />
        </SummarySection>
      )}
      {isLoading && <LoadingSpinner withOverlay={false} />}
      {data && (
        <>
          <SummarySection className='summary-section' data-testid='subtotal'>
            {displayAlias && hasAliasSkus && (
              <AliasPriceRow className='bold-line-price'>
                <DisplayAliasPrice
                  price={theAliasPrice}
                  onTooltipClick={trackTooltipClicked}
                />
              </AliasPriceRow>
            )}
            <BoldLinePrice className='bold-line-price'>
              <SubtotalHeading>Subtotal</SubtotalHeading>
              <SubtotalHeading
                className='subtotal-heading'
                data-testid='summary-section-subtotal-price'
              >
                {basePackage.international_only ? (
                  <LearnMoreLink
                    linkText='Price varies'
                    href='https://checkr.com/pricing/international'
                    data-testid='learn-more-link-international-price-varies'
                  />
                ) : (
                  subtotalPrice
                )}
              </SubtotalHeading>
            </BoldLinePrice>
          </SummarySection>
          <ThirdPartyFees
            serviceFeeMin={fees.serviceFeeMin}
            serviceFeeMax={fees.serviceFeeMax}
            passthroughFeeMin={fees.passthroughFeeMin}
            passthroughFeeMax={fees.passthroughFeeMax}
          />
        </>
      )}
    </>
  );
};

export default DomesticAddScreeningsSummary;
