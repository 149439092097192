import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { HeaderWithTooltipStyled } from './InvoicesStyledComponents';

export type Header = {
  name: string;
  tooltip: any;
  classes?: string;
};

type Props = {
  header: Header;
};

const HeaderWithTooltip: React.FC<Props> = ({ header, ...props }) => {
  const { t } = useTranslation();
  return (
    <HeaderWithTooltipStyled {...props}>
      <span data-testid='heading'>{t(`headings.${header.name}`)}</span>
      <M.TooltipDefinition
        style={{ marginLeft: '0.5rem' }}
        highlighted={false}
        openOnHover={false}
        align='bottom'
        definition={t(`tooltips.${header.name}`)}
      >
        <M.Icon icon='Information' />
      </M.TooltipDefinition>
    </HeaderWithTooltipStyled>
  );
};

export default HeaderWithTooltip;
