import React, { useCallback, useState } from 'react';
import { TOSResponse } from 'api/i9';
import AccountGetStarted from './AccountGetStarted';
import AccountSetupForm from './AccountSetupForm';
import AccountSetupComplete from './AccountSetupComplete';
import AccountTermsOfService from './AccountTermsOfService';
import { Border, FlexContainer } from './style';

type AccountSetupProps = {
  tosData?: TOSResponse;
};

const AccountSetup: React.FC<AccountSetupProps> = ({ tosData }) => {
  const [step, setStep] = useState(0);

  const handleGetStartedContinueClick = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const handleTermsOfServiceContinueClick = useCallback(() => {
    setStep(2);
  }, [setStep]);

  const handleSetupFormContinueSubmitted = useCallback(() => {
    setStep(3);
  }, [setStep]);

  const renderContainerContent = useCallback(() => {
    switch (step) {
      case 0:
        return (
          <AccountGetStarted onContinueClick={handleGetStartedContinueClick} />
        );

      case 1:
        return (
          <AccountTermsOfService
            onContinueClick={handleTermsOfServiceContinueClick}
            tosContent={tosData?.content || ''}
          />
        );

      case 2:
        return (
          <AccountSetupForm
            onContinueFormSubmitted={handleSetupFormContinueSubmitted}
            tosVersion={tosData?.version}
          />
        );

      case 3:
        return <AccountSetupComplete />;

      default:
        throw new Error('unexpected state');
    }
  }, [
    step,
    handleGetStartedContinueClick,
    handleTermsOfServiceContinueClick,
    tosData?.content,
    tosData?.version,
    handleSetupFormContinueSubmitted,
  ]);

  return (
    <FlexContainer gap='2rem' padding='0'>
      <h2>I-9 verification</h2>
      <Border>
        <FlexContainer gap='3rem' padding='2.5rem'>
          {renderContainerContent()}
        </FlexContainer>
      </Border>
    </FlexContainer>
  );
};

export default AccountSetup;
