import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useWorkflowScreenStates } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useGetAdjudicationSubtypes } from 'modules/adjudication/api';
import { LoadingModal } from 'modules/adjudication/ui';

import Content from './content';
import engageflowScreenStates from '../transitions';

type Props = {
  open: boolean;
  hideModal: () => void;
};

enum Screens {
  Subtypes = 'Subtypes',
  Confirm = 'Confirm',
}

const Modal: React.FC<Props> = ({ open, hideModal }) => {
  const { isLoading } = useGetAdjudicationSubtypes();

  return isLoading ? (
    <LoadingModal onClose={hideModal} open={open} />
  ) : (
    <DeferModal open={open} hideModal={hideModal} />
  );
};

const DeferModal: React.FC<Props> = ({ open, hideModal }) => {
  const [selection, setSelection] = useState();
  const { t } = useTranslation();
  const { deferredSubtypes } = useGetAdjudicationSubtypes();

  const getInitialScreen = useCallback(() => {
    if (deferredSubtypes.length >= 2) {
      return Screens.Subtypes;
    }
    return Screens.Confirm;
  }, [deferredSubtypes]);

  const { currentScreen, transitionToScreen, nextScreen } =
    useWorkflowScreenStates(getInitialScreen(), engageflowScreenStates);

  const getHeader = useCallback(() => {
    if (currentScreen === 'Subtypes') {
      return t(`${namespace}:report.actions.defer.modal.header.select`);
    }
    return t(`${namespace}:report.actions.defer.modal.header.confirm`);
  }, [currentScreen, t]);

  return (
    <M.ComposedModal
      onClose={hideModal}
      open={open}
      data-testid='deferred-modal'
      className='actions-modal'
    >
      <M.ModalHeader closeModal={hideModal}>
        <h3>{getHeader()}</h3>
      </M.ModalHeader>
      <M.ModalBody>
        <Content
          nextScreen={nextScreen}
          onClose={hideModal}
          transitionToScreen={transitionToScreen}
          currentScreen={currentScreen}
          onChange={setSelection}
          selection={selection}
        />
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default Modal;
