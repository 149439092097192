import React from 'react';
import styled from 'styled-components';
import { Namespace } from 'modules/assess/api';
import { BaseRuleChange } from 'modules/assess/models/rulesets/version-diff';
import {
  ChangeType,
  Field,
} from 'modules/assess/models/rulesets/version-diff/common';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useNamespace } from 'modules/assess/ui/router/context';
import RuleChange from './rule-change';
import Title from './Title';
import { Type } from '../type';

type Props = {
  changes: BaseRuleChange.List;
  changeType: ChangeType;
  type: Type;
};

const StyledContainer = styled.div`
  padding-top: 1rem;
`;

export const Container: React.FC<Props> = ({ changes, changeType, type }) => {
  const namespace = useNamespace();
  const account = useUserAccount();

  if (!changes.length) {
    return null;
  }

  const displayItemVisibility =
    namespace === Namespace.criminal &&
    account?.assess_eligible_item_treatment_enabled;

  const content = changes.map(c => {
    const key =
      (c.name as Field.FieldChanged)?.draft ||
      (c.name as Field.FieldUnchanged)?.value;
    return (
      <RuleChange
        key={key}
        change={c}
        displayItemVisibility={displayItemVisibility}
        type={type}
      />
    );
  });

  return (
    <StyledContainer>
      <Title changeType={changeType} />
      <div>{content}</div>
    </StyledContainer>
  );
};

export default Container;
