import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  ReportStatuses,
  StatusTypes,
  underscoreToHuman,
} from '@dashboard-experience/utils';
import moment from 'moment/moment';
import { BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTrackEvent, PAYMENT_AND_BILLING_NAMES } from 'utils/analytics';
import Humanize from 'humanize-plus';
import {
  Accordion,
  TableCell,
  Tooltip,
  UnorderedList,
} from '../BulkOrderHistoryStyled';

const Container = styled.div`
  display: flex;
  margin-top: 8px;
  #mastodon && {
    .cds--tooltip .cds--popover-content {
      max-width: 18rem;
      text-wrap: wrap;
    }
  }
`;

const StyledLabel = styled(M.TooltipLabel)`
  color: ${colors.uiOrange600};
`;

const StyledIcon = styled(M.Icon)`
  fill: ${colors.uiOrange600} !important;
`;

type Props = {
  header: any;
  row: any;
  cell: any;
};

const BulkOrderHistoryTableCell: React.FC<Props> = ({ header, row, cell }) => {
  let cellValue;
  let hasAddons;

  const { t } = useTranslation();
  const bulkInviteFlagr: number = Number(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const analyticsFlagrOn: boolean = !isNaN(bulkInviteFlagr);
  const { invitation_status } = row;

  const trackEvent = useTrackEvent();
  const statusItemsMap = new Map<
    string,
    { icon: ReportStatuses; label: string }
  >([
    ['pending', { icon: ReportStatuses.PENDING, label: 'Queued' }],
    ['in_progress', { icon: ReportStatuses.PENDING, label: 'Sending' }],
    [
      'completed',
      { icon: ReportStatuses.INVITATION_SENT, label: 'Invitations sent' },
    ],
    ['failed', { icon: ReportStatuses.CANCELED, label: 'Failed' }],
  ]);

  const handleDownloadFile = useCallback(() => {
    if (analyticsFlagrOn) {
      trackEvent(PAYMENT_AND_BILLING_NAMES.CSV_FILE_HYPERLINK_CLICKED, {
        'File Name': row.file_display_name,
        'Timestamp of Upload Request': row.submitted_date,
      });
    }
  }, [analyticsFlagrOn, row, trackEvent]);

  const handleErrorFile = useCallback(() => {
    if (analyticsFlagrOn) {
      trackEvent(PAYMENT_AND_BILLING_NAMES.ERRORS_FILE_HYPERLINK_CLICKED, {
        'File Name': row.result_file_display_name,
        'Timestamp of Upload Request': row.submitted_date,
      });
    }
  }, [analyticsFlagrOn, row, trackEvent]);

  const statusBadge = () => {
    const statusItem =
      statusItemsMap.get(cell.value) || statusItemsMap.get('pending');

    return (
      <M.StatusBadge
        statusType={StatusTypes.Assess}
        status={statusItem?.icon}
        label={statusItem?.label}
      />
    );
  };

  const truncatesString = (value: string) => {
    const valueLengthToTruncate: number = 30;

    return (
      <>
        {value.length <= valueLengthToTruncate ? (
          value
        ) : (
          <Tooltip highlighted={false} label={value}>
            <M.TooltipLabel>
              {Humanize.truncate(value, valueLengthToTruncate)}
            </M.TooltipLabel>
          </Tooltip>
        )}
      </>
    );
  };

  const ErrorTooltip = () => {
    return (
      <Container>
        <StyledLabel>{t('tooltips.bulkHistoryErrors.label')}</StyledLabel>
        <M.Tooltip
          align='right-bottom'
          label={t('tooltips.bulkHistoryErrors.msg')}
        >
          <StyledIcon icon='WarningFilled' />
        </M.Tooltip>
      </Container>
    );
  };

  switch (header) {
    case 'invitation_status':
      cellValue = (
        <TableCell key={cell.id} style={{ width: 164 }}>
          {statusBadge()}
        </TableCell>
      );
      break;
    case 'submitted_date':
      cellValue = (
        <TableCell key={cell.id} style={{ whiteSpace: 'nowrap', width: 140 }}>
          <div>{moment(cell.value).format('MM/DD/YY hh:mm A')}</div>
        </TableCell>
      );
      break;
    case 'package':
      hasAddons = row.addons?.length > 0;
      cellValue = (
        <TableCell key={cell.id} style={{ minWidth: 172 }}>
          {typeof cell.value === 'string' ? (
            <strong>{cell.value}</strong>
          ) : (
            <strong>{cell.value.name}</strong>
          )}

          {hasAddons && (
            <Accordion>
              <M.AccordionItem title={`Add-ons (${row.addons.length})`}>
                <UnorderedList>
                  {row.addons.map((addon: any, index: number) => (
                    <M.ListItem key={`${row.id}_addon_${index.toString()}`}>
                      {typeof addon === 'string'
                        ? underscoreToHuman(addon)
                        : underscoreToHuman(addon.type)}
                    </M.ListItem>
                  ))}
                </UnorderedList>
              </M.AccordionItem>
            </Accordion>
          )}
        </TableCell>
      );
      break;
    case 'private_uri':
      cellValue = (
        <TableCell
          key={cell.id}
          style={{ whiteSpace: 'nowrap', minWidth: 172 }}
        >
          <M.Link
            href={cell.value}
            download
            key={`${row.id}-bulk-order-history-file`}
            onClick={handleDownloadFile}
          >
            {row.file_display_name
              ? truncatesString(row.file_display_name)
              : 'batch_invite.csv'}
          </M.Link>
          {invitation_status === 'failed' && <ErrorTooltip />}
        </TableCell>
      );
      break;
    case 'uploader_email':
      cellValue = (
        <TableCell
          key={cell.id}
          style={{ whiteSpace: 'nowrap', minWidth: 172 }}
        >
          {truncatesString(cell.value)}
        </TableCell>
      );
      break;
    case 'result_private_uri':
      cellValue = (
        <TableCell key={cell.id} style={{ whiteSpace: 'nowrap', width: 164 }}>
          {cell.value ? (
            <M.Link
              href={row.result_private_uri}
              download
              key={`${row.id}-bulk-order-history-errors`}
              onClick={handleErrorFile}
            >
              {row.result_file_display_name}
            </M.Link>
          ) : (
            'N/A'
          )}
        </TableCell>
      );
      break;
    default:
      cellValue = <TableCell key={cell.id}>{cell.value}</TableCell>;
  }
  return cellValue;
};

export default BulkOrderHistoryTableCell;
