import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Entity from 'modules/assess/models/settings/account';
import { ItemVisibility } from 'modules/assess';
import { useUserAccount } from 'modules/assess/ui/hooks';
import Option from './option';
import { ReportView as TranslationHelper } from './translation-helpers';
import Value from './value';

type Props = {
  locals: Entity.Type;
  remote?: Entity.Type;
  setNeedsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const Container: React.FC<Props> = ({
  locals,
  remote,
  setNeedsConfirmation,
  setSettings,
}) => {
  const account = useUserAccount();
  const enabled = account?.assess_eligible_item_treatment_enabled;
  const helper = new TranslationHelper(enabled);
  const { t } = useTranslation('assess');
  const title = t(helper.title);
  const note = t(helper.description);
  const current = toOption(locals);

  const handleChange = useCallback(
    (selected: Value) => {
      switch (selected) {
        case Value.SHOW_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.VISIBLE,
          }));
          break;
        }
        case Value.CONCEAL_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.HIDDEN,
          }));
          break;
        }
        case Value.REMOVE_ELIGIBLE_RECORDS: {
          setSettings((state: Entity.Type) => ({
            ...state,
            assess_eligible_item_display: ItemVisibility.REMOVED,
          }));
        }
      }
    },
    [remote, setSettings],
  );

  return (
    <div>
      <h4>{title}</h4>
      <Note>
        <Trans t={t} i18nKey={note} />
      </Note>
      <Option
        example={{ disabled: true }}
        onChange={handleChange}
        selected={current === Value.SHOW_ELIGIBLE_RECORDS}
        value={Value.SHOW_ELIGIBLE_RECORDS}
      />
      <Option
        example={{ disabled: false }}
        onChange={handleChange}
        selected={current === Value.CONCEAL_ELIGIBLE_RECORDS}
        value={Value.CONCEAL_ELIGIBLE_RECORDS}
      />
      <Option
        confirmation
        onChange={handleChange}
        onConfirm={setNeedsConfirmation}
        selected={current === Value.REMOVE_ELIGIBLE_RECORDS}
        value={Value.REMOVE_ELIGIBLE_RECORDS}
      />
    </div>
  );
};

const Note = styled.div`
  padding-bottom: 0.2em;
  padding-top: 0.1em;
`;

function toOption(settings?: Entity.Type) {
  if (!settings) {
    return undefined;
  }

  if (settings.assess_eligible_item_display === ItemVisibility.VISIBLE) {
    return Value.SHOW_ELIGIBLE_RECORDS;
  }

  if (settings.assess_eligible_item_display === ItemVisibility.HIDDEN) {
    return Value.CONCEAL_ELIGIBLE_RECORDS;
  }

  return Value.REMOVE_ELIGIBLE_RECORDS;
}

export default Container;
