import * as entities from 'state/utilities/entities/reducers';
import * as Actions from './actions';
import * as State from './state';

const updatePositions = (state: State.Type): State.Type => {
  const { ids, table } = state;
  const updatedTable = { ...table };
  ids.forEach((id, index) => {
    const option = { ...updatedTable[id] };
    option.position = index + 1;
    updatedTable[id] = option;
  });
  return {
    ids,
    table: updatedTable,
  };
};

export const reducer = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Create.TYPE: {
      return entities.create(state, action as Actions.Create.Action);
    }
    case Actions.Insert.TYPE: {
      return entities.insert(state, action as Actions.Insert.Action);
    }
    case Actions.Load.TYPE: {
      return entities.load(state, action as Actions.Load.Action);
    }
    case Actions.Position.TYPE: {
      const next = entities.position(state, action as Actions.Position.Action);
      return updatePositions(next);
    }
    case Actions.Remove.TYPE: {
      return entities.remove(state, action as Actions.Remove.Action);
    }
    case Actions.Update.TYPE: {
      return entities.update(state, action as Actions.Update.Action);
    }
    default: {
      break;
    }
  }
  return state;
};

export default reducer;
