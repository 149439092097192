import { LocationDescriptor } from 'history';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { RootRoute } from './context';

export const Settings: React.FC = () => {
  const root = useContext(RootRoute);

  const home: LocationDescriptor = {
    pathname: root,
    search: new URLSearchParams({ tab: 'settings' }).toString(),
  };

  return <Redirect to={home} />;
};

export default Settings;
