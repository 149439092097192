import React, { useCallback } from 'react';
import { AddButton } from 'modules/assess/ui/rules/common/rule/editor/conditions';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

const AddCondition = () => {
  const { conditions, insertCondition } = useConditions();
  const itemTypes = conditions.find(
    (c: { fact: string }) => c.fact === 'object_type',
  )?.value as string[];
  const addCondition = useCallback(
    () => insertCondition({}),
    [insertCondition],
  );

  return (
    <AddButton disabled={!itemTypes?.length} addCondition={addCondition} />
  );
};

export default AddCondition;
