import { isPlainObject } from 'lodash';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { Namespace } from 'modules/assess/api';
import * as contextDates from 'modules/assess/api/context-dates';
import * as Entity from 'modules/assess/models/rulesets/context-dates';
import { ID } from 'modules/id';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useFetch = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = contextDates.uri(context, id);
  const key: AnyQueryKey = [namespace, { id }];

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return contextDates.fetch(context, id);
  };

  const result = useQuery(key, request);
  return result;
};

export const useUpdate = (id?: ID, ruleset?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const update = (options: Entity.List) => {
    if (!id) {
      return Promise.reject();
    }
    return contextDates.update(context, id, options);
  };

  const [call, result] = useMutation(update, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // invalidate ruleset
        if ((ns as string) === `${context}/rulesets`) {
          const entityId = (entity as { id: unknown }).id;
          return entityId === ruleset;
        }

        const match = Object.values(Namespace)
          .map(n => contextDates.uri(n, id))
          .includes(ns as string);
        if (!match) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        const entityId = (entity as { id: unknown }).id;
        return entityId === id;
      });
    },
  });

  return {
    call,
    result,
  };
};
