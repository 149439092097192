import { dateWithTimezone } from '@dashboard-experience/utils';
import { colors } from '@dashboard-experience/mastodon';
import { TaskResult } from 'types';
import { Substatus } from './types';

const buildSubstatus = (
  substatus: string,
  completed_at: string,
  task_result?: null | TaskResult,
): Substatus => {
  const idv_method = task_result?.method_of_identification;
  const is_digital =
    idv_method === 'DIGITAL' || idv_method === null || idv_method === undefined;

  const idv = is_digital
    ? 'Digital Identity Verification'
    : 'Identity Verification';

  const ts = completed_at ? `on ${dateWithTimezone(completed_at)}` : '';
  let statusInfo;

  switch (substatus) {
    case 'in_progress':
      statusInfo = {
        icon: 'Renew',
        color: colors.uiGrey800,
        title: `${idv} in progress`,
      };
      break;
    case 'successful':
      statusInfo = {
        icon: 'CheckmarkFilled',
        color: colors.uiGreen400,
        title: `${idv} completed ${ts}`,
      };
      break;
    case 'canceled':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `User opted out of ${idv} ${ts}`,
      };
      break;
    case 'expired':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} expired ${ts}`,
      };
      break;
    case 'failed':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} not successful ${ts}`,
      };
      break;
    default:
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} has status: ${substatus} ${ts}`,
      };
  }

  return statusInfo;
};

export default buildSubstatus;
