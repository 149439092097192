import React from 'react';
import { CandidateBanners, PageHeader } from 'components/Candidate';
import { OrderNewReport } from 'containers/Candidate';

import { RoutesContent } from 'modules/candidate';
import { useCandidateId } from 'providers/Candidate';

// NOTE: components/Candidate is in the process of being deprecated
// in favor of modules/candidate. While the migration is in process,
// updates have to be made to both locations. For guidance, please
// reach out at #prod-dashboard-reports-page
const CandidateContent: React.FC = () => {
  const candidateId = useCandidateId();
  return (
    <div data-testid='candidate-legacy-content'>
      <PageHeader />
      <CandidateBanners />
      <RoutesContent candidateId={candidateId} />
      <OrderNewReport />
    </div>
  );
};

export default CandidateContent;
