import React, { useContext, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  getStatusTypeForUser,
  hasPermission,
  StatusTypes,
} from '@dashboard-experience/utils';
import {
  ENABLE_ARCHIVE_REPORTS,
  EXPERIMENTAL_SEARCH_FILTER_UI_FLAG_KEY,
} from 'Constants';

import FiltersConfigs from 'containers/Search/helpers/FilterConfigs';
import {
  AdvancedFiltersToggle,
  Reset,
  Results,
  SwitchContentType,
} from './Components';
import { Container } from './StyledComponents';

import ExperimentalSearchFilterUI from './ExperimentalSearchFilterUI';
import RenderFilters from './RenderFilters';
import { useSearchState } from '../search-context';
import { useWindowResize } from '../../../hooks';
import UIContext from '../../../context/UI';
import DashboardCandidatesExportCsv from '../DashboardCandidatesExportCsv';

type Props = {
  isLoading: boolean;
};

/**
 * @name chunkArrayInGroups
 * @function
 * @memberOf Filters
 * @description Takes an array of strings representing components and returns
 *              an array of arrays grouped based on the given size
 */
const chunkArrayInGroups = (
  arr: Array<string>,
  size: number,
): Array<Array<string>> => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

const Filters: React.FC<Props> = ({ isLoading }) => {
  const {
    currentUser,
    advancedFiltersLoading,
    assessEnabled,
    geos,
    packages,
    originalPrograms: programs,
    showAdvancedFilters,
  } = useSearchState();

  const segmentationEnabled = currentUser?.account?.segmentation_enabled;
  const canceledEnabled =
    currentUser?.account &&
    !currentUser.account.translate_canceled_status_to_suspended;

  const userType = getStatusTypeForUser(currentUser);
  const isLimitedPartner = userType === StatusTypes.DSP;

  let advancedFilters = segmentationEnabled
    ? FiltersConfigs.advanceWithNodes
    : FiltersConfigs.advanced;

  if (!assessEnabled) {
    advancedFilters = advancedFilters.filter(
      filter => filter !== 'Adjudication',
    );
  }

  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';
  if (!currentUser?.account?.collaboration_suite) {
    advancedFilters = advancedFilters.filter(filter => filter !== 'ReportTask');
  }

  const [advFilters, setAdvFilters] = useState<Array<string>>(advancedFilters);
  const [colNumber, setColNumber] = useState<number>(1);

  let basicFilters = assessEnabled
    ? FiltersConfigs.basicWithAssess
    : FiltersConfigs.basic;

  if (isLimitedPartner) basicFilters = FiltersConfigs.dsp;

  const { isIframe } = useContext(UIContext);
  const breakpoint = isIframe ? 720 : 992;
  const getColNumber = () => {
    const colSizeResize = window.innerWidth >= breakpoint ? 3 : 1;
    setColNumber(colSizeResize);
  };
  useWindowResize('iframe', getColNumber);
  const basic = chunkArrayInGroups(basicFilters, colNumber);
  const advanced = [
    ...chunkArrayInGroups(advFilters, colNumber),
    ...chunkArrayInGroups(FiltersConfigs.dates, colNumber),
  ];

  if (canceledEnabled) advanced.push(FiltersConfigs.canceled);

  // For new experimental search UI - all filters except keyword and tags
  const allMinusKeywordAndTags = [
    ...chunkArrayInGroups(
      basicFilters.slice(4, 5).concat(basicFilters.slice(2, 4)),
      colNumber,
    ),
    ...advanced,
  ];

  useEffect(() => {
    if (!advancedFiltersLoading) {
      const unavailableFilters: Array<string> = [];
      if (!packages.length) unavailableFilters.push('Package');
      if (!geos.length) unavailableFilters.push('Geo');
      if (!programs.length) unavailableFilters.push('Program');

      setAdvFilters((prevFilters: Array<string>) =>
        prevFilters.filter(filter => !unavailableFilters.includes(filter)),
      );
    }
  }, [advancedFiltersLoading, packages, geos, programs, setAdvFilters]);

  // Hackweek 2022 experimental version - We may productize this, but remove if we decide not to.
  const experimentalSearchFilterUI =
    useFlag(EXPERIMENTAL_SEARCH_FILTER_UI_FLAG_KEY)?.variantKey === 'on';

  return experimentalSearchFilterUI ? (
    <ExperimentalSearchFilterUI
      filters={allMinusKeywordAndTags}
      isLoading={isLoading}
    />
  ) : (
    <Container>
      <RenderFilters filters={basic} />
      {!isLimitedPartner && showAdvancedFilters && (
        <RenderFilters filters={advanced} />
      )}
      <M.Container.Row style={{ paddingTop: '0' }}>
        {!isLimitedPartner && (
          <M.GridCol md={16} style={{ paddingLeft: '0' }}>
            <AdvancedFiltersToggle />
          </M.GridCol>
        )}
        <Reset />
      </M.Container.Row>
      <M.Container.Row
        style={{ justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <M.GridCol style={{ paddingLeft: 0 }}>
          {archiveReportFlagEnabled && <SwitchContentType />}
        </M.GridCol>
        <M.GridCol md={16}>
          <Results isLoading={isLoading} />
        </M.GridCol>
        <M.Restrict
          authorized={hasPermission(currentUser, 'perform_export_csv')}
        >
          <M.GridCol style={{ marginLeft: '1rem' }} md={16}>
            <DashboardCandidatesExportCsv />
          </M.GridCol>
        </M.Restrict>
      </M.Container.Row>
    </Container>
  );
};

export default Filters;
