const preAAtransitionStates = {
  Subtypes: {
    transitions: {
      next: 'AdverseItems',
    },
  },
  AdverseItems: {
    transitions: {
      subtypes: 'Subtypes',
      fair_chance_form: 'FairChanceForm',
      fair_chance: 'FairChance',
      preview_email: 'PreviewEmail',
      config_options: 'ConfigOptions',
    },
  },
  ConfigOptions: {
    transitions: {
      adverse_items: 'AdverseItems',
      fair_chance: 'FairChance',
      fair_chance_form: 'FairChanceForm',
      preview_email: 'PreviewEmail',
    },
  },
  FairChance: {
    transitions: {
      adverse_items: 'AdverseItems',
      config_options: 'ConfigOptions',
      fair_chance_form: 'FairChanceForm',
      preview_email: 'PreviewEmail',
    },
  },
  FairChanceForm: {
    transitions: {
      adverse_items: 'AdverseItems',
      config_options: 'ConfigOptions',
      fair_chance: 'FairChance',
      preview_email: 'PreviewEmail',
    },
  },
  PreviewEmail: {
    transitions: {
      adverse_items: 'AdverseItems',
      config_options: 'ConfigOptions',
      fair_chance: 'FairChance',
      fair_chance_form: 'FairChanceForm',
      previous: 'AdverseItems',
      next: 'Confirm',
    },
  },
  Confirm: {
    transitions: {
      previous: 'PreviewEmail',
      next: 'SuccessMessage',
    },
  },
  // TODO: Verify if success selector is needed
  SuccessMessage: {
    transitions: {},
  },
};

export default preAAtransitionStates;
