import React from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import { Trans, useTranslation } from 'react-i18next';

export type Type = React.FC<Props>;

type Props = {
  item: Entity;
  selectedVersion: Version.Basic;
};

export const Container: Type = ({ item, selectedVersion }) => {
  const { t } = useTranslation('assess');
  const key = 'assess:mvr.category-sets.instance.versions.promote.note';

  const activeVersionNumber = item.versions?.find(v => v.active)?.number;

  return (
    <Trans
      t={t}
      i18nKey={key}
      components={{ strong: <strong /> }}
      values={{
        activeVersionNumber,
        versionName: item.name,
        versionNumber: selectedVersion.number,
      }}
    />
  );
};

export default Container;
