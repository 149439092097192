import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { useGetAssessRuleValues } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';
import SingleSelect from 'modules/assess/ui/rules/common/rule/editor/single-select';

const SingleSelectValues = ({ index }: { index: number }) => {
  const { conditions, updateConditionByIndex, selectedItemTypes } =
    useConditions();
  const { t, i18n } = useTranslation('');
  const label = t('selects.condition_option_placeholder');
  const condition = conditions[index];
  const { data } = useGetAssessRuleValues(condition.fact, selectedItemTypes());
  const factValues = data?.values;
  const items = factValues?.map((value: string) => {
    const key = `assess:rules.facts.${condition.fact}.values.${value}`;
    return {
      id: value,
      label: i18n.exists(key) ? t(key) : value,
    };
  });

  const onChange = useCallback(
    selected => {
      updateConditionByIndex(index, { value: selected.selectedItem.id });
    },
    [index, updateConditionByIndex],
  );

  let selectedItem;
  if (factValues?.includes(condition.value)) {
    const valueKey = `assess:rules.facts.${condition.fact}.values.${condition.value}`;
    selectedItem = {
      id: condition.value as string,
      label: i18n.exists(valueKey) ? t(valueKey) : (condition.value as string),
    };
  }

  return (
    <SingleSelect
      items={items || []}
      label={label}
      onChange={onChange}
      selectedItem={selectedItem}
    />
  );
};

export default SingleSelectValues;
