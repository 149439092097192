export enum Sections {
  adverse_action_information = 'adverse_action_information',
  adverse_action_rate = 'adverse_action_rate',
  benefits = 'benefits',
  engagement = 'engagement',
  fair_chance_opportunity = 'fair_chance_opportunity',
  how_to_evaluate_background_checks = 'how_to_evaluate_background_checks',
  intro = 'intro',
  state_requirements = 'state_requirements',
}

export default Sections;
