import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  flex: 0 0 55%;
`;

export const Description: React.FC = () => {
  return (
    <StyledDiv>
      <h3>Manage Account Hierarchy</h3>
      <p>
        Add, remove, or edit up to 300 nodes below. Any unsubmitted changes will
        be lost upon leaving this page. Using this page for hierarchies over 150
        nodes is not optimized and may be slow.
      </p>
    </StyledDiv>
  );
};

export default Description;
