import { AccountsClient as client } from 'api';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { clearUserCache, toastError, updateAccountState } from 'actions';
import { Account } from 'types/Packages/Account';

export type OccupationalHealthData = {
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  business_address_street: string;
  business_address_city: string;
  business_address_state: string;
  business_address_zipcode: string;
  occ_health_dot_regulated: boolean;
  occ_health_screenings: string[];
};

const PATH = 'addon_enablement/occupational_health_screening';

export const useOccupationalHealth = ({ accountId }: { accountId: string }) => {
  const request = (params: OccupationalHealthData) =>
    setupOccupationalHealth(accountId, params);
  const dispatch = useDispatch();
  const [call, result] = useMutation(request, {
    onSuccess: (updatedAccount: Account) => {
      dispatch(clearUserCache());
      dispatch(updateAccountState({ updatedAccount }));
    },
    onError: error => {
      dispatch(toastError('Error:', error.message));
    },
  });
  return { call, result };
};

const setupOccupationalHealth = (
  accountId: string,
  params: OccupationalHealthData,
): Promise<{ [key: string]: any }> => {
  return client.post(`${accountId}/${PATH}`, params);
};
