import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Namespace } from 'modules/assess/api';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import Rule from './rule';

export type Props = {
  category: string;
  namespace: Namespace;
  rules: Array<Row>;
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 0.75em;
  color: ${colors.brandNavy4};
`;

export const Container: React.FC<Props> = ({ category, namespace, rules }) => {
  const { t } = useTranslation('assess:v2');

  if (rules.length === 0) {
    return null;
  }

  const title = t(
    `standard.guidelines.quickstart.categories.${namespace}.${category}.title`,
  );

  const ruleItems = rules.map(rule => <Rule key={rule.id} row={rule} />);
  const basePath =
    'assess-v2-ui-guideline-quickstart-added-guidelines-category-container';
  const titleId = `${basePath}-title`;

  return (
    <div data-testid={basePath}>
      <Title data-testid={titleId}>{title}</Title>
      {ruleItems}
    </div>
  );
};

export default Container;
