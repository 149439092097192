import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view';
import ExampleRecord from './ExampleRecord';

type Props = {
  example?: { disabled?: boolean };
  value: Value;
};

const StyledDiv = styled.div`
  margin-top: 1rem;
`;

const Heading = styled.div`
  margin-bottom: 0.5em;
`;

const Example: React.FC<Props> = ({ example, value }) => {
  const { t } = useTranslation('assess:v2');

  if (!example) {
    return null;
  }

  const key = `home.settings.eligible_records.options.${value.toLowerCase()}.example`;
  const optionExample: any = t(key, { returnObjects: true });

  return (
    <StyledDiv
      data-testid={key}
      id='assess-v2-ui-home-settings-eligible-records-example'
    >
      <Heading>{optionExample.heading}</Heading>
      <ExampleRecord
        actionText={optionExample.action}
        disabled={example.disabled}
        descriptionText={optionExample.description}
      />
    </StyledDiv>
  );
};

export default Example;
