import React from 'react';
import {
  ContextContainer,
  CreditMemosContainer,
  DocumentsContainer,
} from 'containers';

const DocumentsPage = () => {
  return (
    <>
      <DocumentsContainer />
      <CreditMemosContainer />
    </>
  );
};

export default ContextContainer(DocumentsPage);
