import React from 'react';
import { Screenings, TableParams } from '../types';
import DrugRow from './DrugRow';
import V2DrugRow from './V2DrugRow';
import HealthRow from './HealthRow';

type RenderRow = Pick<
  TableParams,
  'type' | 'assessEnabled' | 'statusOrAssessment' | 'canceledStatusEnabled'
> & {
  index: number;
  row: { [x: string]: any };
};

const renderRow = (props: RenderRow) => {
  const { type, index } = props;

  if (type === Screenings.I3Report) {
    return <DrugRow row={props.row} key={index.toString()} />;
  }
  if (type === Screenings.V2Drug) {
    return <V2DrugRow {...props} key={index.toString()} />;
  }
  if (type === Screenings.Health) {
    return (
      <HealthRow {...props} key={`${props.row.name}-${index.toString()}`} />
    );
  }
  return null;
};

export default renderRow;
