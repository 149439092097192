import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

type Props = {
  alignment: string;
};

const StyledTooltip = styled(M.TooltipDefinition)`
  #mastodon && {
    .cds--definition-term {
      font-weight: bold;
      color: ${colors.brandNavy2};
    }

    .cds--definition-tooltip {
      max-width: 300px;
      padding: 24px;
      color: ${colors.brandNavy4};
    }
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandNavy4};
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1rem;
`;

const StyledLink = styled.a`
  p {
    font-size: 16px;
    padding-top: 1rem;
    color: ${colors.brandAqua3};
    text-decoration: underline;
    font-weight: bold;
  }
`;
const ToolTip: React.FC<Props> = ({ alignment }) => {
  const { t } = useTranslation('assess:v2');
  const trans: any = t(`standard.guidelines.lookback_period.tooltip`, {
    returnObjects: true,
  });
  const bulletPoints = trans.options.map((date: any, i: any) => {
    const key = `bullet-${i}`;
    return <li key={key}>{date}</li>;
  });
  const basePath = 'assess-v2-ui-guideline-lookback-period-common-tooltip';
  const titleId = `${basePath}-title`;
  const dialogId = `${basePath}-dialog-container`;
  const descriptionId = `${basePath}-description`;
  const bulletPointsId = `${basePath}-bullet-points`;
  const linkId = `${basePath}-link`;
  return (
    <StyledTooltip
      data-testid={titleId}
      highlighted={false}
      align={alignment}
      definition={
        <DialogContainer data-testid={dialogId}>
          <p data-testid={descriptionId}>{trans.description}</p>
          <StyledList data-testid={bulletPointsId}>{bulletPoints}</StyledList>
          <StyledLink href={trans.path} target='_blank'>
            <p data-testid={linkId}>{trans.link}</p>
          </StyledLink>
        </DialogContainer>
      }
    >
      {trans.title}
    </StyledTooltip>
  );
};

export default ToolTip;
