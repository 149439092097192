import React, { SyntheticEvent, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/package';
import { Dialog } from 'modules/assess/ui/common';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  field: keyof Entity.Settings;
  packages: Entity.List;
  open: boolean;
  setPackages: (packages: Entity.List) => void;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({
  field,
  packages,
  open,
  onClose,
  setPackages,
}) => {
  const [tmpPackages, setTmpPackages] = useState(packages);
  const { t } = useTranslation('assess');
  const name = t(`assess:settings.v1.${field}.name`);
  const modalTitle = t('assess:settings.packages.dialog.title', { name });

  const handleClose = useCallback(() => {
    setTmpPackages(packages);
    onClose?.();
  }, [onClose, packages]);

  const handleAdd = useCallback(() => {
    setPackages(tmpPackages);
    onClose?.();
  }, [onClose, setPackages, tmpPackages]);

  const disabled = useReadOnly();

  if (!open) {
    return null;
  }

  return (
    <Dialog
      data-testid='assess-settings-dialog'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='assess-settings-title'
        title={modalTitle}
        closeModal={handleClose}
      />
      <M.ModalBody data-testid='assess-settings-content'>
        <Content
          disabled={disabled}
          field={field}
          packages={tmpPackages}
          setPackages={setTmpPackages}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-settings-actions'>
        <Actions disabled={disabled} onClose={handleClose} onAdd={handleAdd} />
      </M.ModalFooter>
    </Dialog>
  );
};

export default Container;
