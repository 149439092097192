import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  activeVersionNumber?: number;
  selectedVersionNumber?: number;
  rulesetName: string;
};

export const Content: React.FC<Props> = ({
  activeVersionNumber,
  selectedVersionNumber,
  rulesetName,
}) => {
  const { t } = useTranslation('assess');

  return (
    <Trans t={t} i18nKey='assess:ruleset.versions.promote.note'>
      You are about to apply
      <strong>Version number {{ selectedVersionNumber }}</strong> of the
      {{ rulesetName }} ruleset. The current version {{ activeVersionNumber }}{' '}
      will be archived. Are you sure you want to do this?
    </Trans>
  );
};

export default Content;
