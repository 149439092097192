import React from 'react';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import View from './View';

export type Props = {
  disabled?: boolean;
};

export const LookbackRulesContainer: React.FC<Props> = ({ disabled }) => {
  const ruleset = useRuleset();
  const state = useState();

  return (
    <div data-testid='assess-rules-lookback-container'>
      <View
        disabled={disabled}
        ruleset={ruleset?.data?.id}
        version={state.id}
      />
    </div>
  );
};

export default LookbackRulesContainer;
