import { Namespace } from 'modules/assess/api';
import * as versions from 'modules/assess/api/category-code-set/versions';
import * as CCS from 'modules/assess/models/mvr/category-code-set';
import { useNamespace } from 'modules/assess/ui/router/context';
import { ID } from 'modules/id';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import {
  SearchParams,
  SearchResults,
} from 'modules/assess/api/category-code-set/versions';
import { Changes as ChangeResults } from 'modules/assess/models/mvr/category-code-set-versions/version-diff/changes';

export const useCreate = (instance: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = () => {
    return versions.create(instance);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: CCS.Version.Basic) => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instanceId] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === instanceId) ||
            (type === 'ccsv' && instanceId === 'draft') ||
            (ns === 'assess' && entity === namespace && type === instance)
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useFetch = (instance?: ID, version?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = ['assess', namespace, 'ccsv', version];

  const fetch = (): Promise<CCS.Version.Full> => {
    if (!instance || !version) {
      return Promise.reject();
    }
    return versions.fetch(instance, version, {
      validateStatus: () => true,
    });
  };

  return useQuery(key, fetch, { refetchOnWindowFocus: false });
};

export const useGetChanges = (instance?: ID, version?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = ['assess', namespace, 'ccsv', version, 'changes'];

  const getChanges = (): Promise<ChangeResults> => {
    if (!instance || !version) {
      return Promise.reject();
    }
    return versions.getChanges(instance, version);
  };

  return useQuery(key, getChanges);
};

export const usePromote = (instance: ID, version: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = () => {
    if (!instance || !version) {
      return Promise.reject();
    }
    return versions.promote(instance, version);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        return ns === namespace && !entity;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const usePublish = (instance?: ID, version?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = () => {
    if (!instance || !version) {
      return Promise.reject();
    }
    return versions.publish(instance, version);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        return ns === namespace && !entity;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useSearch = (instance?: ID, params?: SearchParams) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = ['assess', namespace, 'ccsv'];

  const search = (): Promise<SearchResults> => {
    if (!instance) {
      return Promise.reject();
    }
    return versions.search(instance, params);
  };

  return useQuery(key, search);
};

export const useDestroy = (instance?: ID, version?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = () => {
    return versions.destroy(instance, version);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instanceId] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === instanceId)
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};
