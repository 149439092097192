import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { M } from '@dashboard-experience/mastodon';
import { BillingEntity } from '../../types/Billing';
import { scrollToTop } from '../../utils';

type Props = {
  profile: BillingEntity;
  isLoading: boolean;
  submit: (params: any, isDisable: boolean) => void;
  EditForm: React.ComponentType<any>;
  DisablePopup: React.ComponentType<any>;
};

const PaymentProfileBillableToggleCell: React.FC<Props> = ({
  profile,
  isLoading,
  submit,
  EditForm,
  DisablePopup,
}) => {
  // FOR BUTTON
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editDeleteModalOpen, setEditDeleteModalOpen] =
    useState<boolean>(false);

  const hideEditModal = useCallback(() => {
    setEditModalOpen(false);
  }, []);

  const hideEditDeleteModal = useCallback(() => {
    setEditDeleteModalOpen(false);
  }, []);

  const toggleModal = useCallback(toggleVal => {
    if (toggleVal === true) {
      setEditModalOpen(true);
      setEditDeleteModalOpen(false);
    } else {
      setEditDeleteModalOpen(true);
      setEditModalOpen(false);
    }
    scrollToTop();
  }, []);

  return (
    <M.TableCell>
      <M.Toggle
        data-testid='billable-toggle'
        labelA={t('labels.billable_off')}
        labelB={t('labels.billable_on')}
        toggled={profile.billable}
        onToggle={toggleModal}
      />
      <EditForm
        paymentProfile={profile}
        submit={submit}
        modalOpen={editModalOpen}
        hideModal={hideEditModal}
        isLoading={isLoading}
      />
      <DisablePopup
        paymentProfile={profile}
        submit={submit}
        modalOpen={editDeleteModalOpen}
        hideModal={hideEditDeleteModal}
        isLoading={isLoading}
      />
    </M.TableCell>
  );
};

export default PaymentProfileBillableToggleCell;
