import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Type } from 'modules/assess/models/rules/premade';
import { Quickstart } from 'modules/assess/v2/models/amplitude/guidelines';
import { Description, Row } from 'modules/assess/v2/ui/common/premade/rule';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import ButtonType from '../ButtonType';
import AddButton from './button';
import './styles.scss';

const StyledHeading = styled(M.Screening.Heading)`
  .heading-text-name {
    color: ${colors.brandNavy3} !important;
  }

  .heading-left {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    span {
      width: 100%;
    }
  }
  .heading-right {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.span`
  display: block;
  width: 100%;
`;

export type Props = {
  handleRuleUnselected: (rule: Type) => void;
  row: Row;
  buttonType?: ButtonType;
};

export const Item: React.FC<Props> = ({
  handleRuleUnselected,
  row,
  buttonType,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const track = useTrackEvent();

  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      track(Quickstart.RuleExpanded, {
        Guideline: row.rule.code,
      });
    }
  }, [isExpanded, row.rule.code, track]);

  const basePath =
    'assess-v2-ui-guideline-quickstart-table-category-rule-container';
  const expandableId = `${basePath}-expandable`;
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;

  return (
    <M.Expandable
      data-testid={expandableId}
      initialExpanded={isExpanded}
      customExpand
      key={row.id}
      className='premade'
      title={
        <StyledHeading
          headingLeft={{
            name: (
              <Title onClick={onClick} className='header' data-testid={titleId}>
                {row.rule.name}
              </Title>
            ),
          }}
          headingRight={
            <AddButton
              row={row}
              handleRuleUnselected={handleRuleUnselected}
              buttonType={buttonType}
            />
          }
        />
      }
    >
      <Description
        description={row.rule.description}
        data-testid={descriptionId}
      />
    </M.Expandable>
  );
};

export default Item;
