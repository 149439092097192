import { useMutation, useQuery } from 'react-query';
import { useUpdatedState } from '@dashboard-experience/utils';

import { useCallback, useMemo } from 'react';
import {
  initializeUserPreferences,
  postDashboardPreference,
  postNestedDashboardPreference,
} from './actions';
import { getDashboardPreference, getNestedDashboardPreference } from './utils';

export const usePostDashboardPreference = () =>
  useMutation(postDashboardPreference);

export const usePostNestedDashboardPreference = () =>
  useMutation(postNestedDashboardPreference);

export const useInitializeUserPreferences = (enabled: boolean) =>
  useQuery('initializeUserPreferences', initializeUserPreferences, {
    refetchOnWindowFocus: false,
    retry: 1,
    // Wait until we have a user before we initialize preferences
    enabled,
  });

export const usePreference = (key: string) => {
  const loadedPreference = useMemo(() => getDashboardPreference(key), [key]);
  const [savedPreference, setSavedPreference] =
    useUpdatedState(loadedPreference);

  const [post, result] = usePostDashboardPreference();
  const savePreference = useCallback(
    async (value: any) => {
      setSavedPreference(value);
      return post({ preference: key, value });
    },
    [post, key, setSavedPreference],
  );
  return useMemo(
    () => [savedPreference, savePreference, result],
    [savedPreference, savePreference, result],
  );
};

export const useNestedPreference = (key: string, subKey: string) => {
  const loadedPreference = useMemo(
    () => getNestedDashboardPreference(key, subKey),
    [key, subKey],
  );
  const [savedPreference, setSavedPreference] =
    useUpdatedState(loadedPreference);

  const [post, result] = usePostNestedDashboardPreference();
  const savePreference = useCallback(
    async (value: any) => {
      setSavedPreference(value);
      return post({ parentKey: key, subKey, value });
    },
    [setSavedPreference, post, key, subKey],
  );
  return useMemo(
    () => [savedPreference, savePreference, result],
    [savedPreference, savePreference, result],
  );
};
