import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { ApiKeysTable, ApiKeysHeader } from 'components';
import { LoadingScreen } from 'components/Account';
import { useUser } from 'context/CurrentUser';
import { ApiKeysProvider } from 'components/Account/DeveloperSettings/ApiKeys/apiKeys-context';

export type AddApiKeyParams = {
  accountId: string;
  type: any;
};

export type DeleteApiKeyParams = {
  id: string;
  expires_at: string | null;
};

const ApiKeysContainer = () => {
  const { account } = useUser();

  const isLoading = Boolean(!account);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <ApiKeysProvider>
      <M.Container type='transparent'>
        <ApiKeysHeader />
        <ApiKeysTable account={account} />
      </M.Container>
    </ApiKeysProvider>
  );
};

export default ApiKeysContainer;
