import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { Action } from './types';

export type Props = {
  action: Action;
  closeMenu?: () => void;
  danger?: boolean;
  disabled?: boolean;
  divider?: boolean;
  select: (action: Action) => void;
};

type RenderFunction = React.ForwardRefRenderFunction<any, Props>;

const Render: RenderFunction = (
  { action, closeMenu, danger, disabled, divider, select },
  ref,
) => {
  const { t } = useTranslation('assess');
  const onClick = useCallback(() => select(action), [action, select]);
  const tkey = `assess:mvr.category-sets.instance.menu.item.${action}`;
  const text = t(tkey, action);

  if (disabled) {
    return null;
  }

  return (
    <M.Menu.MenuItem
      data-testid={`assess-mvr-category-set-menu-item-${action}`}
      hasDivider={divider}
      isDelete={danger}
      itemText={text}
      closeMenu={closeMenu}
      onClick={onClick}
      ref={ref}
    />
  );
};

export const MenuItem = React.forwardRef(Render);

export default MenuItem;
