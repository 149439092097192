import { useEffect, useRef } from 'react';

/**
 * @name useWindowResize
 * @function
 * @description Reusable hook to add a resize event with it's listener
 * @param {string} context - Know if the component is inside and iFrame context
 * @param {function} windowResize - Listener for the event
 * @param {boolean} shouldAddEvent - Optional conditional to add the listener
 */

const useWindowResize = (context, windowResize, shouldAddEvent = true) => {
  const eventAdded = useRef(false);

  useEffect(() => {
    const isSupported = window && window.addEventListener && true;
    if (!isSupported || !shouldAddEvent) return;

    if (context === 'iframe' && shouldAddEvent) {
      windowResize();
      window.addEventListener('resize', windowResize);
      eventAdded.current = true;
    }
    // eslint-disable-next-line consistent-return
    return () => {
      eventAdded.current && window.removeEventListener('resize', windowResize);
    };
  }, [context, windowResize, shouldAddEvent]);
};

export default useWindowResize;
