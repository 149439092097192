import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Candidate } from 'components/Signup/types';
import { validateEmail } from 'components/AddScreenings/shared/ModalComponents';
import {
  ContactInfoListItem,
  ContactInfoItemContainer,
  StretchInput,
} from './styles';

type CandidateInfoChangeType = (
  e: React.ChangeEvent<HTMLInputElement>,
  candidateId: string,
) => void;

type Props = {
  candidate: Candidate;
  index: number;
  showDelete: boolean;
  handleEmailChange: CandidateInfoChangeType;
  handleRemoveCandidate: CandidateInfoChangeType;
};

const ContactInfoItem: React.FC<Props> = ({
  candidate,
  index,
  showDelete,
  handleEmailChange,
  handleRemoveCandidate,
}) => {
  const onEmailChange = useCallback(
    e => {
      handleEmailChange(e, candidate.id);
    },
    [candidate.id, handleEmailChange],
  );

  const onRemoveCandidate = useCallback(
    e => {
      handleRemoveCandidate(e, candidate.id);
    },
    [candidate.id, handleRemoveCandidate],
  );

  return (
    <ContactInfoItemContainer>
      <ContactInfoListItem>
        {' '}
        <StretchInput
          type='email'
          required
          placeholder='name@email.com'
          labelText='Email'
          defaultValue={candidate.email}
          id={`contact-info-email-${index}`}
          data-testid={`contact-info-email-${index}`}
          data-dd-privacy='mask'
          name='email'
          invalidText='Invalid email'
          invalid={candidate.email && !validateEmail(candidate.email)}
          onChange={onEmailChange}
        />
      </ContactInfoListItem>
      {showDelete && (
        <M.Icon
          icon='Close'
          data-testid={`delete-candidate-${index}`}
          onClick={onRemoveCandidate}
        />
      )}
    </ContactInfoItemContainer>
  );
};

export default ContactInfoItem;
