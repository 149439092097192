/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import { M, colors } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import {
  fetchSamlConfig,
  fetchIdpConnections,
  addIdpConnection,
  updateIdpConnection,
  deleteIdpConnection,
} from 'actions';
import {
  IdpSubmitInputGroup,
  IdpInlineNotifications,
  InputGroup,
  InputLabel,
  IdentityProviderConfig,
  IdpSubmitButton,
  IdpDeleteButton,
  ConfirmModal,
} from 'components';
import { scrollToTop } from 'utils';

const AccountSingleSignOnContainer = ({
  // Provided account means an admin is viewing other account single sign on information
  // If null, it is a customer viewing their own account
  providedAccount,
  // data props
  account,
  connectionProp,
  samlConfigProp,

  // action props
  fetchIdpConnections,
  fetchIdpSamlConfig,
  addIdpConnection,
  updateIdpConnection,
  removeIdpConnection,

  // action status props
  addIdpRequest,
  updateIdpRequest,
  deleteIdpRequest,
  fetchIdpsRequest,
  fetchSamlConfigRequest,
}) => {
  const [connection, setConnection] = useState(connectionProp);
  const [fileName, setFileName] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const accountId = providedAccount != null ? providedAccount.id : account.id;
  const accountName =
    providedAccount != null ? providedAccount.name : account.name;

  const fileInput = React.createRef();

  const getFile = useCallback(
    () => get(fileInput, 'current.files[0]'),
    [fileInput],
  );

  useEffect(() => {
    fetchIdpConnections(accountId);
    fetchIdpSamlConfig(accountId);
  }, [fetchIdpConnections, fetchIdpSamlConfig, accountId]);

  useEffect(() => {
    setConnection({ ...connectionProp, displayName: accountName });
  }, [connectionProp, accountName]);

  const handleChange = useCallback(
    e => {
      setConnection({ ...connection, [e.target.name]: e.target.value });
    },
    [setConnection, connection],
  );

  const postIdpConnection = useCallback(() => {
    addIdpConnection({
      accountId,
      idpConnection: { ...connection, signingCert: getFile() },
    });
  }, [addIdpConnection, accountId, connection, getFile]);

  const putIdpConnection = useCallback(() => {
    if (getFile()) {
      return updateIdpConnection({
        accountId,
        idpConnection: {
          ...connection,
          signingCert: getFile(),
        },
      });
    }

    return updateIdpConnection({
      accountId,
      idpConnection: {
        ...connection,
        signingCert: new Blob([connection.signingCert]),
      },
    });
  }, [updateIdpConnection, getFile, accountId, connection]);

  const deleteIdpConnection = useCallback(() => {
    removeIdpConnection({
      accountId,
      connectionId: connection.id,
    });
    hideModal();
  }, [removeIdpConnection, accountId, connection, hideModal]);

  const removeSigningCert = useCallback(() => {
    fileInput.current = { files: [null] };
    setConnection({ ...connection, signingCert: null });
    setFileName(null);
  }, [fileInput, setConnection, connection, setFileName]);

  const uploadFile = useCallback(() => fileInput.current.click(), [fileInput]);

  const customerHelpText = (
    <M.Grid>
      <M.Container
        style={{ border: '0px', paddingBottom: '0px', paddingTop: '0px' }}
      >
        <h5>
          <em>
            If you encounter an issue,&nbsp;
            <M.Link
              style={{ color: colors.uiNavy600 }}
              className='text-muted'
              target='_blank'
              rel='noopener noreferrer'
              href='https://help.checkr.com/hc/en-us/requests/new'
            >
              please contact Support
            </M.Link>
            .
          </em>
        </h5>
      </M.Container>
    </M.Grid>
  );

  const adminHelpText = (
    <M.Grid>
      <M.Container style={{ border: '0px', paddingBottom: '0px' }}>
        <h5>
          <em>
            You are viewing IDP single sign on connection information for&nbsp;
            {String(accountName)}.
          </em>
        </h5>
      </M.Container>
    </M.Grid>
  );

  const updateFileName = useCallback(
    () => setFileName(getFile().name),
    [setFileName, getFile],
  );

  const signingCertDisplay = useCallback(() => {
    if (connection.signingCert) {
      return (
        <>
          <p>
            You currently have a certificate uploaded. To upload a different
            cert, click below to remove the current, then select a new
            certificate file, and save.
          </p>
          <br />
          <M.Button
            kind='danger'
            size='sm'
            onClick={removeSigningCert}
            style={{ margin: '0' }}
          >
            Remove Current Cert
          </M.Button>
          <M.Input
            ref={fileInput}
            hidden
            name='signingCert'
            id='connection.signingCert'
            type='file'
          />
        </>
      );
    }

    return (
      <>
        <p>Click to upload a signing certificate</p>
        <M.Button size='sm' onClick={uploadFile} style={{ margin: '0' }}>
          Choose File
        </M.Button>
        <br />
        <p>{fileName}</p>
        <M.Input
          ref={fileInput}
          onChange={updateFileName}
          hidden
          name='signingCert'
          id='connection.signingCert'
          type='file'
        />
      </>
    );
  }, [
    connection,
    removeSigningCert,
    fileInput,
    uploadFile,
    fileName,
    updateFileName,
  ]);

  return (
    <>
      <ConfirmModal
        header='Delete Connection'
        message='Are you sure you want to delete this connection?'
        open={modalOpen}
        hideModal={hideModal}
        callback={deleteIdpConnection}
      />
      {providedAccount == null ? customerHelpText : adminHelpText}
      <IdentityProviderConfig
        accountName={accountName}
        fetchSamlConfigRequest={fetchSamlConfigRequest}
        samlConfigProp={samlConfigProp}
      />
      <M.Grid>
        <M.Container>
          <M.Container.Row>
            <h3>{accountName} Service Provider Configuration</h3>
          </M.Container.Row>
          {fetchIdpsRequest.error ? (
            <M.Container.Row>
              <M.InlineNotification
                kind='error'
                title='Error Retrieving Connection. If the problem persists please contact Customer Support.'
              />
            </M.Container.Row>
          ) : null}
          <M.Container.Row>
            <IdpInlineNotifications
              addIdpRequest={addIdpRequest}
              updateIdpRequest={updateIdpRequest}
              deleteIdpRequest={deleteIdpRequest}
            />
          </M.Container.Row>
          <M.Container.Row>
            <InputGroup key='connection.signInUrl'>
              <M.Input
                helperText='SAML single login URL for the connection to be created.'
                placeholder='https://idp-provider.com/app/tenant-name/app-id/sso/saml'
                onChange={handleChange}
                labelText='Sign In URL'
                name='signInUrl'
                id='connection.signInUrl'
                type='string'
                value={connection.signInUrl}
              />
            </InputGroup>
          </M.Container.Row>
          <M.Container.Row>
            <InputGroup key='connection.emailDomain'>
              <M.Input
                helperText='Optional, only needed for Service Provider initiated SSO. We recommend testing the Identity Provider initiated SSO before updating the connection to enable SP-initiated SSO.'
                placeholder='customer-domain.com'
                onChange={handleChange}
                labelText='Email Domain'
                name='emailDomain'
                id='connection.emailDomain'
                type='string'
                value={connection.emailDomain || ''}
              />
            </InputGroup>
          </M.Container.Row>
          <M.Container.Row>
            <InputGroup key='connection.signingCert'>
              <InputLabel htmlFor='connection.signingCert'>
                Signing Certificate
              </InputLabel>
              {signingCertDisplay()}
            </InputGroup>
          </M.Container.Row>
          <IdpSubmitInputGroup>
            <IdpDeleteButton
              connectionId={connection.id}
              deleteIdpConnection={showModal}
              isProcessing={deleteIdpRequest.processing}
            />
            <IdpSubmitButton
              connectionId={connection.id}
              postIdpConnection={postIdpConnection}
              putIdpConnection={putIdpConnection}
              isProcessing={
                addIdpRequest.processing || updateIdpRequest.processing
              }
            />
          </IdpSubmitInputGroup>
        </M.Container>
      </M.Grid>
    </>
  );
};

AccountSingleSignOnContainer.propTypes = {
  providedAccount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  account: PropTypes.object.isRequired,
  connectionProp: PropTypes.object.isRequired,
  samlConfigProp: PropTypes.object.isRequired,
  fetchIdpConnections: PropTypes.func.isRequired,
  fetchIdpSamlConfig: PropTypes.func.isRequired,
  addIdpConnection: PropTypes.func.isRequired,
  updateIdpConnection: PropTypes.func.isRequired,
  removeIdpConnection: PropTypes.func.isRequired,
  addIdpRequest: PropTypes.object.isRequired,
  updateIdpRequest: PropTypes.object.isRequired,
  deleteIdpRequest: PropTypes.object.isRequired,
  fetchIdpsRequest: PropTypes.object.isRequired,
  fetchSamlConfigRequest: PropTypes.object.isRequired,
};

AccountSingleSignOnContainer.defaultProps = {
  providedAccount: null,
};

const mapStateToProps = state => ({
  user: state.currentUser.currentUser,
  account: state.currentUser.currentUser.account,
  connectionProp: state.idpConnections.connection,
  samlConfigProp: state.idpSamlConfig.config,
  fetchIdpsRequest: state.idpConnections.fetchRequest,
  addIdpRequest: state.idpConnections.addRequest,
  updateIdpRequest: state.idpConnections.updateRequest,
  deleteIdpRequest: state.idpConnections.deleteRequest,
  fetchSamlConfigRequest: state.idpSamlConfig.fetchRequest,
});

const mapDispatchToProps = dispatch => ({
  fetchIdpConnections: accountId => dispatch(fetchIdpConnections(accountId)),
  fetchIdpSamlConfig: accountId => dispatch(fetchSamlConfig(accountId)),
  addIdpConnection: ({ accountId, idpConnection }) =>
    dispatch(addIdpConnection({ accountId, idpConnection })),
  updateIdpConnection: ({ accountId, idpConnection }) =>
    dispatch(updateIdpConnection({ accountId, idpConnection })),
  removeIdpConnection: ({ accountId, connectionId }) =>
    dispatch(deleteIdpConnection({ accountId, connectionId })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountSingleSignOnContainer),
);
