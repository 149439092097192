/* eslint-disable no-console */
import { Experiment } from '@amplitude/experiment-js-client';
import { datadogRum } from '@datadog/browser-rum';
import { AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY } from 'Constants';

let experiment: any;

export const initializeAndStart = async (userProperties: any) => {
  const deploymentKey = AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY;

  if (!deploymentKey) {
    console.warn(
      'Amplitude experiment: no deployment key defined. Cannot initialize experiment!',
    );
    return null;
  }

  experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
    fetchTimeoutMillis: 3000,
    fetchOnStart: false,
    automaticExposureTracking: true,
  });

  if (experiment) {
    try {
      await experiment.start({ user: userProperties });
      datadogRum.addAction('Amplitude Initialization', { status: 'Success' });
      return experiment;
    } catch (err) {
      let message;

      if (err instanceof Error) {
        message = err.message;
      } else {
        message = String(err);
      }

      datadogRum.addAction('Amplitude Initialization', {
        status: 'Failed',
        message,
      });
      console.warn('Amplitude experiment: could not fetch variants', message);
    }
  } else {
    console.warn('Amplitude experiment: initialization failed');
  }

  return null;
};

export const getVariantValue = (flagKey: string) => {
  if (!experiment) {
    console.warn(
      'Amplitude experiment: tried to get variant but experiment not initialized!',
    );
    return null;
  }

  if (!flagKey) {
    console.warn(
      'Amplitude experiment: no amplitude experiment flag key provided!',
    );
    return null;
  }

  const variant = experiment.variant(flagKey);

  if (!variant.value) {
    console.warn('Amplitude experiment: no variant found for flag key!');
    return null;
  }

  return variant.value;
};

export const getExperiment = () => {
  return experiment;
};
