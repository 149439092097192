import { AnyQueryKey, useQuery } from 'react-query';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import * as fairChanceMetrics from 'modules/fair-chance-metrics/api/fair-chance-metrics';

export const useFetch = () => {
  const namespace = fairChanceMetrics.uri();
  const key: AnyQueryKey = [namespace];

  const request = () => {
    return fairChanceMetrics.fetch();
  };

  const result = useQuery(key, request);
  return result;
};

export const useAnalyticsRole = () => {
  const currentUser = useUser();
  return hasPermission(currentUser, 'read_analytics');
};

export default useFetch;
