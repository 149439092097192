/* eslint-disable no-shadow */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { AccountSettings, AccountSettingsOld } from 'components';
import { USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY } from 'Constants';
import { M } from '@dashboard-experience/mastodon';
import { PreferencesOld } from '../../components';

type Props = {
  t: (arg0: string) => Promise<string>;
};

const AccountSettingsContainer: React.FC<Props & RouteComponentProps> = ({
  t,
}) => {
  const newUserPreferencesEnabled =
    useFlag(USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY)?.variantKey === 'on' ||
    false;

  return newUserPreferencesEnabled ? (
    <AccountSettings t={t} />
  ) : (
    // TODO Remove after delivery the user preference improvements
    // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
    <>
      <AccountSettingsOld t={t} />
      <M.Divider style={{ margin: '2rem 0' }} />
      <PreferencesOld />
    </>
  );
};

export default withTranslation()(withRouter(AccountSettingsContainer));
