import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Warning = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    fill: ${colors.uiTextPrimaryLight};
    margin-right: 0.5rem;
  }
  svg [data-icon-path='inner-path'] {
    fill: ${colors.uiGrey0};
    opacity: 1;
  }
`;

const DoNotContact = () => (
  <Warning>
    <M.Icon icon='WarningFilled' size='16' />
    The candidate requested Checkr to not contact this employer.
  </Warning>
);

export default DoNotContact;
