export enum Tab {
  settings = 'settings',
  users = 'users',
  'single-sign-on' = 'single-sign-on',
  developer_settings = 'developer_settings',
  integrations = 'integrations',
  applications = 'applications',
  brands = 'brands',
  hierarchy = 'hierarchy',
  notifications = 'notifications',
  ats_settings = 'ats_settings',
}

export type TabName = keyof typeof Tab;

export type TabItem = {
  content: any;
  slug: TabName;
  url: string;
};

export type Tabs = {
  [key: string]: TabItem;
};
