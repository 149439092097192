import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React, { useCallback } from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const PersonalReferenceVerification = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}: ScreeningProps) => {
  const { onAddClick } = actionProps;

  const handleAddClick = useCallback(() => {
    onAddClick('personal_reference_verification', {
      required_quantity: [true, '1'],
    });
  }, [onAddClick]);

  return (
    <FlexContainer data-testid='expandable-personal-reference-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='personal-reference-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Personal Reference Verification'
            priceByType='personal_reference_verification'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Contacts a reference known to the candidate.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='personal-reference-verification-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360000989328'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Vouches for the character of a candidate' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='References may not respond to the information request or reveal full truths about candidate' />
              <BulletPoint text='Checks personal references, not identity' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='personal_reference_verification'
          {...actionProps}
          onAddClick={handleAddClick}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default PersonalReferenceVerification;
