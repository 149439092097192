import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'modules/assess/api';
import { PremadeRuleChange } from 'modules/assess/models/rulesets/version-diff';
import T from 'components/Table';
import Change from './Change';
import TableHeader from './TableHeader';

type Props = {
  changes?: PremadeRuleChange.List;
  namespace: Namespace;
};

const columns: Array<string> = ['rule', 'change'];

export const Table: React.FC<Props> = ({ changes, namespace }) => {
  const { t } = useTranslation('assess');
  const headers = columns.map(key => ({
    key,
    header: <TableHeader name={key} />,
  }));

  const rows = changes?.map(item => ({
    id: item.code?.value,
    ...item,
    change: <Change change={item} />,
    rule: t(
      `assess:rules.standard.rules.${namespace}.${item.code?.value}.title`,
    ),
  }));

  return (
    <T
      data-testid='assess-ui-ruleset-version-publish-fairness-table'
      columns={columns}
      headers={headers}
      rows={rows}
    />
  );
};

export default Table;
