import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const LogoPlaceholder = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  background: ${colors.uiGrey100};
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LogoPlaceholder;
