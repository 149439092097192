import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import { getComponents } from './Icon';
import ButtonType from '../../ButtonType';

type Props = {
  selected?: boolean;
  onClick: () => void;
  buttonType?: ButtonType;
};

export const Button: React.FC<Props> = ({
  selected,
  onClick,
  buttonType = ButtonType.Add,
}) => {
  const { t } = useTranslation('assess:v2');
  const selectedKey: string = selected ? 'selected' : 'unselected';
  const color = selected ? colors.brandAqua3 : colors.uiTextPrimaryLight;
  const key = `common.rules.premade.buttons.${buttonType}.${selectedKey}`;
  const translation = (
    <Trans t={t} i18nKey={key} components={getComponents()} />
  );

  const dataTestid = `assess-v2-ui-guideline-premade-table-category-rule-button-${selectedKey}`;

  return (
    <M.Button
      onClick={onClick}
      style={{ color, borderColor: color }}
      kind='secondary'
      size='sm'
      data-testid={dataTestid}
    >
      {translation}
    </M.Button>
  );
};

export default Button;
