/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import createActionCreator from './ActionHelper';
import { AmsAPI } from '../utils/APIClient';

export const FETCH_IDP_SAML_CONFIG_REQUEST = 'FETCH_IDP_SAML_CONFIG_REQUEST';
export const FETCH_IDP_SAML_CONFIG_SUCCESS = 'FETCH_IDP_SAML_CONFIG_SUCCESS';
export const FETCH_IDP_SAML_CONFIG_FAILURE = 'FETCH_IDP_SAML_CONFIG_FAILURE';

export const fetchSamlConfigRequest = createActionCreator(
  FETCH_IDP_SAML_CONFIG_REQUEST,
);

export const fetchSamlConfigSuccess = createActionCreator(
  FETCH_IDP_SAML_CONFIG_SUCCESS,
);

export const fetchSamlConfigFailure = createActionCreator(
  FETCH_IDP_SAML_CONFIG_FAILURE,
);

export const fetchSamlConfig = accountId => dispatch => {
  dispatch(fetchSamlConfigRequest());

  return AmsAPI.get(
    `/api/public/v1/accounts/${accountId}/idp_connections/saml_config`,
  )
    .then(payload => {
      dispatch(fetchSamlConfigSuccess({ payload }));
    })
    .catch(error => {
      dispatch(fetchSamlConfigFailure({ error }));
    });
};
