import { API as Client } from 'utils/APIClient';
import {
  AddApiKeyParams,
  DeleteApiKeyParams,
} from 'containers/ApiKeysContainer';

export const getApiKeys = (accountId: string) => {
  return Client.get(`/accounts/${accountId}/tokens`);
};

export const addApiKey = ({ accountId, type }: AddApiKeyParams) => {
  return Client.post(`/accounts/${accountId}/tokens`, { type });
};

export const deleteApiKey = ({ id, expires_at }: DeleteApiKeyParams) => {
  return Client.post(`/tokens/${id}`, { expires_at });
};
