import React from 'react';
import merge from 'lodash/merge';
import {
  ReactQueryConfigProvider,
  ReactQueryProviderConfig,
} from 'react-query';

import { useErrorNotifier } from 'hooks';

const defaults: ReactQueryProviderConfig = {
  queries: {
    retry: (failureCount, error: Error) => {
      if (!error || !error?.message) return false;
      return /Network Error/i.test(error?.message) && failureCount <= 3;
    },
  },
};

export const ReactQueryProvider: React.FC = ({ children }) => {
  const onError = useErrorNotifier();

  const config: ReactQueryProviderConfig = {
    queries: {
      onError,
    },
    mutations: {
      onError,
    },
  };

  merge(config, defaults);

  return (
    <ReactQueryConfigProvider config={config}>
      {children}
    </ReactQueryConfigProvider>
  );
};

export default ReactQueryProvider;
