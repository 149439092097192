import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import {
  ScreeningRow,
  ScreeningText,
  InformationIcon,
  ScreeningTextContainer,
  Tooltip,
} from '../styles';
import IconCheckmark from '../IconCheckmark';

const ProfessionalPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
}) => {
  const { t } = useTranslation('signup');
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Professional',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_professional_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Professional'
      description='Additional verifications for advanced roles'
      price={7999}
      packageType='checkrdirect_professional_criminal'
      selected={selectedPackage === 'checkrdirect_professional_criminal'}
      onClick={handlePackageSelect}
    >
      <>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningText fontWeight={700} className='screening-text'>
            Everything in Essential
          </ScreeningText>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningTextContainer className='screening-text-container'>
            <ScreeningText className='screening-text'>
              Education verification
            </ScreeningText>
            <Tooltip
              align='left-top'
              label={t(`pages.packagesPage.tooltips.educationVerification`)}
            >
              <InformationIcon icon='Information' size='20px' />
            </Tooltip>
          </ScreeningTextContainer>
        </ScreeningRow>
        <ScreeningRow className='screening-row'>
          <IconCheckmark />
          <ScreeningTextContainer className='screening-text-container'>
            <ScreeningText className='screening-text'>
              Employment verification
            </ScreeningText>
            <Tooltip
              align='left-top'
              label={t(`pages.packagesPage.tooltips.employmentVerification`)}
            >
              <InformationIcon icon='Information' size='20px' />
            </Tooltip>
          </ScreeningTextContainer>
        </ScreeningRow>
      </>
    </PackageCard>
  );
};

export default ProfessionalPackageCard;
