import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useGetWorksites,
  useGetAllUsers,
  UserResponse,
  AccountSettingsResponse,
} from 'api/i9';

import { useUser } from 'context/CurrentUser';
import CreateOrEditWorksite from './CreateOrEditWorksite';
import SortableTableHeader from './SortableTableHeader';
import {
  CreateUserModal,
  CreateUserConfirmationModal,
} from './CreateUserModal';
import useSubmitAddUser from './hooks/useSubmitAddUser';
import { IUser } from './Models';
import WorksiteEverifyStatusLabel from './WorksiteEverifyStatusLabel';
import ActivateEVerifyModal from './ActivateEVerifyModal';
import WorksiteActionsMenu from './WorksiteActionsMenu';
import {
  Divider,
  FlexContainer,
  FlexRow,
  FlexRowDistribute,
  InformationIcon,
} from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const EverifyText = styled.p`
  max-width: 33rem;
  color: ${colors.coolBlack70};
`;

const headerUserDefinitions = [
  {
    key: 'name',
    header: 'Full name',
  },
  {
    key: 'email',
    header: 'Email address',
  },
];

type FormI9SettingProps = {
  settingsData?: AccountSettingsResponse;
  onChangeDefaultWorksite: () => void;
};

const FormI9Settings: React.FC<FormI9SettingProps> = ({
  settingsData,
  onChangeDefaultWorksite,
}) => {
  const headerWorksiteDefinitions = useMemo(() => {
    const headers = [
      {
        key: 'name',
        header: 'Worksite name',
        sortable: true,
      },
      {
        key: 'street_line1',
        header: 'Street address 1',
        sortable: true,
      },
      {
        key: 'city',
        header: 'City',
        sortable: true,
      },
      {
        key: 'state',
        header: 'State',
        sortable: true,
      },
      {
        key: 'zip_code',
        header: 'ZIP code',
        sortable: true,
      },
    ];
    if (settingsData?.everify_status === 'activated') {
      headers.push({
        key: 'everify_status',
        header: 'E-Verify',
        sortable: false,
      });
    }
    return headers;
  }, [settingsData?.everify_status]);

  const [currentSorting, setCurrentSorting] = useState({
    headerKey: 'name',
    sortDirection: 'ASC',
  });

  const worksites = useGetWorksites(
    currentSorting.headerKey,
    currentSorting.sortDirection.toLowerCase(),
  );
  const users = useGetAllUsers();
  const currentUser = useUser();

  const handleChangeDefaultWorksite = useCallback(() => {
    onChangeDefaultWorksite();
  }, [onChangeDefaultWorksite]);

  const useSuccessCreateUser = useCallback(() => {
    users.refetch();
  }, [users]);

  const submit = useSubmitAddUser(useSuccessCreateUser);

  const [userData, setUserData] = useState<IUser>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const mappedData = useMemo(() => {
    const mapData = (u: UserResponse) => ({
      ...u,
      name: `${u.first_name} ${u.last_name}`,
    });

    return users.data?.data.map(u => mapData(u));
  }, [users.data?.data]);

  const [isCreateOrEditWorksiteOpen, setCreateOrEditWorksiteOpenState] =
    useState(false);
  const [isAddWorksiteMode, setAddWorksiteModeState] = useState(false);
  const [worksiteEditObject, setAddWorksiteEditObject] = useState({
    id: '',
    name: '',
    street_line1: '',
    street_line2: '',
    city: '',
    state: '',
    zip_code: '',
    everify_status: '',
  });

  const handleWorksiteDeleted = useCallback(() => {
    worksites.refetch();
  }, [worksites]);

  const handleAddNewWorksiteClick = useCallback(() => {
    setCreateOrEditWorksiteOpenState(true);
    setAddWorksiteModeState(true);
    setAddWorksiteEditObject({
      id: '',
      name: '',
      street_line1: '',
      street_line2: '',
      city: '',
      state: '',
      zip_code: '',
      everify_status: '',
    });
  }, []);

  const handleAddNewWorksiteClose = useCallback(() => {
    setCreateOrEditWorksiteOpenState(false);
    setAddWorksiteModeState(false);
  }, []);

  const useHandleCreateOrEditWorksiteSuccessOperation = useCallback(() => {
    setCreateOrEditWorksiteOpenState(false);
    setAddWorksiteModeState(false);
    worksites.refetch();
  }, [worksites]);

  const handleWorksiteEdit = useCallback(worksiteEditObject => {
    setCreateOrEditWorksiteOpenState(true);
    setAddWorksiteModeState(false);
    setAddWorksiteEditObject(worksiteEditObject);
  }, []);

  const handleHeaderClick = useCallback(
    (headerKey, sortDirection) => {
      setCurrentSorting({ headerKey, sortDirection });
    },
    [setCurrentSorting],
  );

  const [showCreateUserConfirmationModal, setShowCreateUserConfirmationModal] =
    useState(false);

  const setCreateUserConfirmationModalOpen = useCallback(() => {
    setShowCreateUserConfirmationModal(true);
  }, [setShowCreateUserConfirmationModal]);

  const hideCreateUserConfirmationModal = useCallback((): void => {
    submit(userData);
    setShowCreateUserConfirmationModal(false);
  }, [submit, userData]);

  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const setCreateUserModalOpen = useCallback(() => {
    setShowCreateUserModal(true);
  }, [setShowCreateUserModal]);

  const hideCreateUserModal = useCallback((): void => {
    setShowCreateUserModal(false);
  }, []);

  const [showActivateEVerifyModal, setActivateEVerifyModal] = useState(false);

  const setActivateEVerifyModalOpen = useCallback(() => {
    setActivateEVerifyModal(true);
  }, [setActivateEVerifyModal]);

  const hideActivateEVerifyModal = useCallback((): void => {
    setActivateEVerifyModal(false);
  }, []);

  const setNextStepModal = useCallback(
    (values: IUser) => {
      setCreateUserConfirmationModalOpen();
      setUserData(values);
      setShowCreateUserModal(false);
    },
    [setCreateUserConfirmationModalOpen],
  );

  const trackEvent = useTrackEvent();

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_SETTINGS_PAGE_VIEWED, {
      'Page Type': 'Dashboard',
      'I9 Page Type': 'I9 Settings Page',
    });
  }, [trackEvent]);

  const renderWorksiteTable = useCallback(
    ({ rows, headers }: any) => (
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              {headers.map((header: any) => (
                <SortableTableHeader
                  key={header.key}
                  headerKey={header.key}
                  headerText={header.header}
                  sortable={
                    headerWorksiteDefinitions.find(h => h.key === header.key)
                      ?.sortable === true
                  }
                  sortDirection={
                    header.key === currentSorting.headerKey
                      ? currentSorting.sortDirection
                      : 'NONE'
                  }
                  onHeaderClick={handleHeaderClick}
                />
              ))}
              <M.TableHeader key='actions'>Actions</M.TableHeader>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((row: any) => (
              <M.TableRow key={row.id}>
                {row.cells.map((cell: any) => {
                  const getCellContent = () => {
                    if (
                      cell.info.header === 'name' &&
                      row.id === settingsData?.default_worksite_id
                    ) {
                      return (
                        <>
                          {cell.value}
                          <M.Tag data-testid='default-worksite'>Default</M.Tag>
                        </>
                      );
                    }
                    if (cell.info.header === 'everify_status') {
                      return (
                        <WorksiteEverifyStatusLabel
                          everifyStatus={cell.value}
                        />
                      );
                    }
                    return cell.value;
                  };
                  return (
                    <M.TableCell key={cell.id}>{getCellContent()}</M.TableCell>
                  );
                })}
                <M.TableCell key='actions'>
                  <WorksiteActionsMenu
                    worksite={worksites.data?.data.find(w => w.id === row.id)}
                    onWorksiteDeleted={handleWorksiteDeleted}
                    onWorksiteEdited={handleWorksiteEdit}
                    onChangeDefaultWorksite={handleChangeDefaultWorksite}
                    settingsData={settingsData}
                  />
                </M.TableCell>
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    ),
    [
      headerWorksiteDefinitions,
      currentSorting.headerKey,
      currentSorting.sortDirection,
      handleHeaderClick,
      handleWorksiteDeleted,
      handleWorksiteEdit,
      handleChangeDefaultWorksite,
      settingsData,
      worksites.data?.data,
    ],
  );

  const eVerifyEnabled = settingsData?.everify_status === 'activated';

  const renderUserTable = useCallback(
    ({ rows, headers }: any) => (
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              {headers.map((header: any) => (
                <M.TableHeader key={header.key}>{header.header}</M.TableHeader>
              ))}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((row: any) => (
              <M.TableRow key={row.id}>
                {row.cells.map((cell: any) => (
                  <M.TableCell key={cell.id}>{cell.value}</M.TableCell>
                ))}
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    ),
    [],
  );
  return (
    <>
      {showActivateEVerifyModal && (
        <ActivateEVerifyModal
          open={showActivateEVerifyModal}
          hideModal={hideActivateEVerifyModal}
        />
      )}
      <CreateOrEditWorksite
        isOpen={isCreateOrEditWorksiteOpen}
        onClose={handleAddNewWorksiteClose}
        onSuccessManipulation={useHandleCreateOrEditWorksiteSuccessOperation}
        isAddWorksiteMode={isAddWorksiteMode}
        worksiteEditObj={worksiteEditObject}
        isEverifyActive={eVerifyEnabled}
      />
      {showCreateUserModal && (
        <CreateUserModal
          open={showCreateUserModal}
          hideModal={hideCreateUserModal}
          setNextStep={setNextStepModal}
        />
      )}
      {showCreateUserConfirmationModal && (
        <CreateUserConfirmationModal
          open={showCreateUserConfirmationModal}
          hideModal={hideCreateUserConfirmationModal}
        />
      )}
      <FlexContainer gap='3rem' padding='1rem'>
        <FlexContainer gap='0.5rem' padding='0'>
          <FlexRowDistribute alignItems='center'>
            <h3>E-Verify</h3>
            <M.Button
              onClick={setActivateEVerifyModalOpen}
              disabled={settingsData?.everify_status === 'activated'}
              kind='secondary'
              size='sm'
              data-testid='i9-settings-page-activate-everify'
            >
              Activate E-Verify
            </M.Button>
          </FlexRowDistribute>
          <EverifyText>
            E-Verify may not be required for your organization. Please consult
            with independent legal counsel about whether E-verify is right for
            your organization.
          </EverifyText>
        </FlexContainer>
        <Divider />
        <FlexContainer gap='0.5rem' padding='0'>
          <FlexRowDistribute alignItems='center'>
            <h3>Worksites</h3>
            <M.Button
              onClick={handleAddNewWorksiteClick}
              kind='secondary'
              size='sm'
              data-testid='i9-settings-page-add-new-worksite'
            >
              Add new
            </M.Button>
          </FlexRowDistribute>
          {worksites.isLoading ? (
            <M.LoadingInline description='Loading...' />
          ) : (
            <M.DataTable
              headers={headerWorksiteDefinitions}
              rows={worksites.data?.data || []}
              render={renderWorksiteTable}
            />
          )}
        </FlexContainer>
        <Divider />
        <FlexContainer gap='0.5rem' padding='0'>
          <FlexRowDistribute alignItems='center'>
            <FlexRow gap='0.25rem' alignItems='center'>
              <h3>Registered Tracker users</h3>
              <M.Tooltip
                align='top'
                label='Individuals on your team who have initiated their Tracker account registration.'
              >
                <InformationIcon icon='InformationFilled' size='1.5rem' />
              </M.Tooltip>
            </FlexRow>
            <M.Button
              kind='secondary'
              size='sm'
              data-testid='i9-settings-page-add-new-tracker-user'
              onClick={setCreateUserModalOpen}
              disabled={
                users.isLoading ||
                users.data?.data.some(
                  u =>
                    u.email.toLowerCase() === currentUser?.email?.toLowerCase(),
                )
              }
            >
              Register my account
            </M.Button>
          </FlexRowDistribute>
          {users.isLoading ? (
            <M.LoadingInline description='Loading...' />
          ) : (
            <M.DataTable
              headers={headerUserDefinitions}
              rows={mappedData || []}
              render={renderUserTable}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default FormI9Settings;
