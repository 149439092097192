import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

import { useAddExam } from 'components/Report/Screenings/DrugHealthScreenings/ExamReorder/flow/api';
import { exam_reorder_modal_footer } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEvent,
  ReorderEvent,
  EventProperty,
  EventValue,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import CustomError from './custom-error';
import { ActionType, AddExamError } from '../../../types';
import { useModalState, useDispatch } from '../../../context';
import Loading from '../loading';
import Description from './description';

type Props = {
  error: AddExamError;
};

const Error: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const { call: resubmit, result } = useAddExam();
  const transitionToScreen = useModalState('transitionToScreen');
  const dispatch = useDispatch();

  const handleResubmit = useCallback(() => {
    resubmit();
    trackEvent(ReorderEvent.ErrorAction, {
      [EventProperty.ErrorAction]: EventValue.Resubmit,
    });
  }, [trackEvent, resubmit]);

  const handleBack = useCallback(() => {
    dispatch({ type: ActionType.SET_ADD_EXAM_ERROR, payload: undefined });
    transitionToScreen('review');
    trackEvent(ReorderEvent.ErrorAction, {
      [EventProperty.ErrorAction]: EventValue.Back,
    });
  }, [dispatch, transitionToScreen, trackEvent]);

  const navigation = {
    next: {
      name: t(`${exam_reorder_modal_footer}.resubmit`),
      onClick: handleResubmit,
    },
    back: {
      name: t(`${exam_reorder_modal_footer}.back`),
      onClick: handleBack,
    },
  };

  return (
    <>
      {!result.isLoading && (
        <>
          <CustomError description={<Description error={error} />} />
          <M.ModalNavigationFooter
            next={navigation.next}
            back={navigation.back}
          />
        </>
      )}
      {result.isLoading && <Loading />}
    </>
  );
};

export default Error;
