import React from 'react';
import withReportContainer from 'containers/Report/withReportContainer';
import { useReport } from 'containers/Report/hooks';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import VerifiedStarIcon from 'assets/VerifiedStar.svg';
import {
  IdentityContainer,
  IdentityHeader,
  IdentityHeaderLeft,
  IdentityHeaderRight,
  IdentityVerificationOutcomeInfo,
  IdentityVerificationUpdatedAt,
  Tooltip,
  UnverifiedBadge,
  VerifiedBadge,
} from './CandidateInformationStyledComponents';

const IdentityVerification: React.FC = () => {
  const report = useReport();

  if (!report) {
    return null;
  }
  const idv = report.identity_verification;
  if (!idv) {
    return null;
  }
  const updatedAt = moment(idv.updated_at).format('MMM D, YYYY h:mmA');
  return (
    <div data-testid='candidate-information-identity-verification'>
      <hr />
      <IdentityContainer>
        <IdentityHeader>
          <IdentityHeaderLeft>
            <strong>Identity</strong>
            <Tooltip
              align='right'
              highlighted={false}
              definition=' Checkr partners with Socure to validate the candidate’s identity
                and assess fraud risk.'
            >
              <M.Icon icon='InformationFilled' />
            </Tooltip>
          </IdentityHeaderLeft>
          <IdentityHeaderRight>
            {idv.outcome === 'passed' && (
              <VerifiedBadge data-testid='idv-verified'>
                <img src={VerifiedStarIcon} alt='verified star icon' />
                <span>Verified</span>
              </VerifiedBadge>
            )}
            {idv.outcome !== 'passed' && (
              <UnverifiedBadge data-testid='idv-unverified'>
                <M.Icon icon='HelpFilled' />
                <span>Unverified</span>
              </UnverifiedBadge>
            )}
          </IdentityHeaderRight>
        </IdentityHeader>
        <IdentityVerificationOutcomeInfo>
          {idv.outcome_reasoning &&
            (idv.customer_action || report.status === 'complete') && (
              <div data-testid='idv-outcome-reason'>
                <M.Icon icon='WarningFilled' />
                <span>{idv.outcome_reasoning}</span>
              </div>
            )}
          <IdentityVerificationUpdatedAt data-testid='idv-last-updated'>
            Last updated on {updatedAt}
          </IdentityVerificationUpdatedAt>
        </IdentityVerificationOutcomeInfo>
      </IdentityContainer>
    </div>
  );
};

export default withReportContainer({
  component: IdentityVerification,
  type: 'report',
});
