import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';

interface Props {
  type: string;
  bodyText: string;
  expiresAt: string;
  expireAtText: string;
}

const Body: React.FC<Props> = ({
  type,
  bodyText,
  expiresAt,
  expireAtText = 'Report will be suspended on',
}) =>
  moment(expiresAt).isAfter(moment()) ? (
    <div>
      {type !== 'mvr_consent' && (
        <div className='exception-item' style={{ display: 'flex' }}>
          <M.Icon
            icon='WarningAltFilled'
            size='16'
            style={{
              fill: colors.coolBlack80,
              marginRight: '0.5rem',
            }}
          />{' '}
          <span className='exception-body-text'>
            {expireAtText}{' '}
            <span className='expires-at-date'>
              {moment(expiresAt).format('ll')} at{' '}
              {moment(expiresAt).format('LT')}
            </span>
          </span>
        </div>
      )}
      <div className='exception-item exception-body-text'>{bodyText}</div>
    </div>
  ) : null;

export default Body;
