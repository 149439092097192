import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { emailValidationRegex } from '@dashboard-experience/utils';
import { MAX_BULK_INVITATIONS } from '../../../../Constants';
import { Flex } from '../SharedItems';
import { useOrderBackgroundCheckContext, actionTypes } from '../Context';

const addKeys = [9, 13, 32, 186, 188];
const EmailContainer = styled(M.TagsInput)`
  margin-top: 20px;
  height: 150px !important;
  border: 1px solid ${colors.uiGrey200} !important;
  overflow-y: auto !important;
`;
// split emails list by "," or ";"
const pasteSplit = (text: string) =>
  text
    .replace(/[\r\n,;]/g, ' ')
    .split(' ')
    .map(d => d.trim());

const MaxEmailsContainer = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 50px;
`;

const MaxEmails = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${colors.uiGrey400};
`;

const EmailBox: React.FC<any> = () => {
  const { state, dispatch } = useOrderBackgroundCheckContext();

  const handleEmails = useCallback(
    (emails: any) => {
      dispatch({
        type: actionTypes.ADD_EMAILS,
        payload: { emails },
      });
    },
    [dispatch],
  );

  const emailInputProps = {
    placeholder: state.emails.length === 0 ? ' Candidate email addresses' : '',
    style: { minWidth: '16rem' },
    id: 'email-address',
  };

  return (
    <div data-testid='email-container' data-dd-privacy='mask'>
      <EmailContainer
        data-testid='email-box'
        onlyUnique
        addOnBlur
        addOnPaste
        addKeys={addKeys}
        inputProps={emailInputProps}
        maxTags={MAX_BULK_INVITATIONS}
        onChange={handleEmails}
        pasteSplit={pasteSplit}
        tags={state.emails}
        validationRegex={emailValidationRegex}
      />
      <MaxEmailsContainer>
        <MaxEmails>Maximum {MAX_BULK_INVITATIONS} candidates</MaxEmails>
        <MaxEmails>
          {MAX_BULK_INVITATIONS - state.emails.length} remaining
        </MaxEmails>
      </MaxEmailsContainer>
    </div>
  );
};

export default EmailBox;
