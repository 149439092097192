import React, { useCallback, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifier } from 'hooks';
import Button from 'components/Button';
import * as Version from 'modules/assess/models/rulesets/version';
import { usePromote } from 'modules/assess/ui/ruleset/version/api/hooks';
import * as Notifications from 'state/notifications/entity';
import styled from 'styled-components';

const ModalButton = styled(Button)`
  text-transform: capitalize;
`;

export type Props = {
  onClose: (event: SyntheticEvent) => void;
  version: Version.Type;
};

export const Actions: React.FC<Props> = ({ onClose, version }) => {
  const { t } = useTranslation('assess');
  const title = t('assess:ruleset.versions.promote.title');
  const notifier = useNotifier();
  const promote = usePromote();

  const onPromoteHandler = useCallback(
    async (e: SyntheticEvent) => {
      try {
        if (version?.id) {
          await promote.call(version?.id);

          const message = t('assess:ruleset.versions.promote.messages.success');
          const params = Notifications.success({ title, message });
          notifier(params);
        } else {
          throw new Error('Version not selected');
        }
      } catch {
        const message = t('assess:ruleset.versions.promote.messages.error');
        const params = Notifications.error({
          title,
          message,
        });
        notifier(params);
      } finally {
        onClose(e);
      }
    },
    [version, promote, onClose, notifier, t, title],
  );
  return (
    <>
      <ModalButton kind='secondary' trans='adverbs.no' onClick={onClose} />
      <ModalButton
        kind='danger'
        trans='adverbs.yes'
        onClick={onPromoteHandler}
      />
    </>
  );
};

export default Actions;
