import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import DashboardLink from 'components/DashboardLink';

const CellLink = styled(DashboardLink)`
  color: ${colors.uiTextPrimaryLight} !important;
  cursor: pointer;
  display: flex;

  &.limited-role {
    cursor: default;
  }
`;

export default CellLink;
