import { Base } from './base';

export class ReportView extends Base {
  title: string;

  description: string;

  constructor(isEligibleItemTreatmentEnabled: boolean) {
    super(isEligibleItemTreatmentEnabled);
    const baseFormattedPath = isEligibleItemTreatmentEnabled
      ? `${this.basePath}.${this.eligibleItemTreatmentPath}`
      : this.basePath;
    this.description = `${baseFormattedPath}.description`;
    this.title = `${baseFormattedPath}.title`;
  }
}
