import React from 'react';
import styled from 'styled-components';
import SubscriptionErrorsExportCsv from './SubscriptionErrorsExportCsv';

export const RightButtonsContainer = styled.span`
  display: flex;
  justify-content: flex-end;
`;

const IneligiblePersonsButtons = () => (
  <RightButtonsContainer>
    <SubscriptionErrorsExportCsv />
  </RightButtonsContainer>
);

export default IneligiblePersonsButtons;
