import React, {
  useState,
  useCallback,
  MouseEvent,
  SyntheticEvent,
} from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useShowCompleteNow } from 'modules/adjudication/utilities';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import CompleteModal from '../flow/modal/modal';
import Guard from './guard';

const Styles = styled.div`
  display: flex;
  align-items: center;
`;

const tooltipButtonStyles = {
  tooltip: {
    container: {
      display: 'inline-block',
    },
  },
};

const CompleteButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement> | SyntheticEvent<Element>) => {
      e?.stopPropagation();
      setOpen(true);
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        { Action: 'Complete Now' },
      );
      // TODO: remove once customer is standalone app
      scrollToTop();
    },
    [setOpen],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const action = report.completable ? 'action' : 'action_unavailable';

  const { t } = useTranslation();
  const tooltipDefinitionText = t(
    `${namespace}:report.actions.complete.info_tooltip`,
  );
  const text = t(`${namespace}:report.actions.complete.${action}`);
  const tooltipText = t(`${namespace}:report.actions.complete.tooltip`);
  const showCompleteNow = useShowCompleteNow();

  const disabled = !report.completable ? 'disabled' : '';

  return (
    <Guard>
      <M.Container.Col style={{ padding: 0, marginTop: '1rem' }}>
        <Styles>
          <M.TooltipButton
            align='top'
            label={tooltipText}
            kind='secondary'
            className={disabled}
            id='complete-button'
            // eslint-disable-next-line
            onClick={!disabled ? handleOpen : () => {}}
            customStyles={tooltipButtonStyles}
            fullWidth
          >
            {text}
          </M.TooltipButton>
          {showCompleteNow && (
            <M.TooltipDefinition
              highlighted={false}
              align='left'
              tooltipText={tooltipDefinitionText}
              style={{ marginLeft: '0.5rem' }}
            >
              <M.Icon icon='InformationFilled' />
            </M.TooltipDefinition>
          )}
          {open && <CompleteModal open={open} hideModal={handleClose} />}
        </Styles>
      </M.Container.Col>
    </Guard>
  );
};

export default CompleteButton;
