import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as Assess from 'modules/assess/ui';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import Container from 'modules/assess/ui/ruleset/version/ActiveContainer';
import ContextDates from './context-dates';
import Missing from '../../missing';

export const Active: React.FC = () => {
  const match = useRouteMatch();
  const { path: base, url } = match;

  const query = useRuleset();
  const loading = query?.status === 'loading';
  const ruleset = query?.data;
  const version = ruleset?.active;
  const id = version && typeof version === 'object' ? version.id : version;

  if (loading) {
    return <M.LoadingSpinner />;
  }

  if (!ruleset) {
    return <Missing />;
  }

  if (!id) {
    return <Redirect to='draft' />;
  }

  return (
    <Switch>
      <Route path={`${base}/context-dates`}>
        <Assess.Route.Breadcrumb location={url} title='Active'>
          <ContextDates id={id} disabled />
        </Assess.Route.Breadcrumb>
      </Route>
      <Route path={base}>
        <Container ruleset={ruleset?.id} />
      </Route>
    </Switch>
  );
};

export default Active;
