import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes, orderByKey } from '@dashboard-experience/utils';
import { PointerStateCriminalSearches, ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { STATUS_ORDER } from 'Constants';

const PointerStateCriminalSearch: React.FC<ReportProps> = ({ report }) => {
  const { pointer_state_criminal_searches: searches } = report;
  const orderedSearches = orderByKey(
    searches || [],
    'status',
    STATUS_ORDER,
  ) as PointerStateCriminalSearches[];
  // TODO - remove this and use the prop once migrated to Assess - forces legacy for now
  const reportStatusType = StatusTypes.Legacy;

  return orderedSearches?.length ? (
    <M.Screening
      report={report}
      title='Pointer State Searches'
      statusType={reportStatusType}
      screening={orderedSearches}
      id={SCREENING_TITLES.pointer_state_criminal_searches.id}
    >
      {orderedSearches.map(search => {
        return (
          <M.SubScreening
            key={search.id}
            title={search.state}
            screening={search}
            statusType={reportStatusType}
          />
        );
      })}
    </M.Screening>
  ) : null;
};

export default PointerStateCriminalSearch;
