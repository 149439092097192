import React from 'react';
import { Namespace } from 'modules/assess/api';
import { Category as CrimCategory } from 'modules/assess/models/criminal/rules/rules';
import {
  Category as CategoryType,
  RulesType,
  getRulesByNamespace,
} from 'modules/assess/models/rules';
import { usePremadeRules } from 'modules/assess/ui/api';
import { useNamespace } from 'modules/assess/ui/router/context';
import { useState as useStateContext } from 'modules/assess/ui/ruleset/version/store';
import { Base, Standard } from 'modules/assess/v2/models/rules';
import { Row } from 'modules/assess/v2/ui/common/premade/rule/Row';
import Category from './category';

export const Container: React.FC = () => {
  const state = useStateContext();
  const rules = state.rules.premade.table;
  const namespace = useNamespace() || Namespace.criminal;
  const premadeRules = usePremadeRules().data || [];
  const Rules: any = getRulesByNamespace(namespace);
  const rows: Array<Row> = Base.build(premadeRules, rules);
  const categories = Standard.Rules.getCategoriesByNamespace(namespace);
  const categorySections = categories.map((category: CrimCategory) => (
    <Category
      category={category}
      key={category}
      namespace={namespace}
      rules={getCategoryRules(rows, Rules, category)}
    />
  ));

  return (
    <div data-testid='assess-v2-ruleset-version-guidelines-templated-categories-container'>
      {categorySections}
    </div>
  );
};

export function getCategoryRules(
  rows: Array<Row>,
  rules: RulesType,
  category: CategoryType,
) {
  const categoryRules = rows.filter(
    row => rules.category(row.rule.code) === category,
  );

  return categoryRules;
}

export default Container;
