import React from 'react';
import styled from 'styled-components';
import { Segment, FetchParentObjectResponse } from 'api/segments';
import { GenericObject } from '@dashboard-experience/utils';
import {
  TYPE_BRAND,
  TYPE_PAM,
  TYPE_PKG,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
  TYPE_USER,
} from '../../Constants';
import { ParentContext } from '../../api/segments';

const List = styled.ul`
  padding: 1.2rem 0rem;
  list-style: disc inside !important;
`;

const ListItem = styled.li`
  padding: 0.5rem 1rem;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const listOptions: GenericObject = {
  [TYPE_PAM]: 'the adjudication matrix',
  [TYPE_PKG]: 'the package',
  [TYPE_USER]: 'the user',
  [TYPE_BRAND]: 'the brand',
  [TYPE_MVR_RULE]: 'the MVR rule',
  [TYPE_APP_INSTANCE_CUSTOMER]: 'the application instance',
};

type Props = {
  mode: string;
  segments: Segment[];
  parentContext: ParentContext;
  parentObject: FetchParentObjectResponse;
  confirmation: boolean;
  success: boolean;
};

const SegmentsSummary: React.FC<Props> = ({
  mode,
  segments,
  parentContext,
  parentObject,
  confirmation,
  success,
}) => {
  return (
    <>
      <p data-testid='segments-summary-title'>
        {confirmation && mode === 'unassign' && <>Unassigning:</>}
        {success && mode === 'unassign' && <>Unassigned:</>}
        {confirmation && mode === 'assign' && <>Assigning:</>}
        {success && mode === 'assign' && <>Assigned:</>}
      </p>
      <List data-testid='segments-summary-list'>
        {segments.map(segment => {
          return (
            <ListItem
              key={`segment_${segment.custom_id}`}
              data-testid={`segment_${segment.custom_id}`}
            >
              <Strong>{segment.name}</Strong>
              {mode === 'unassign' && <span>&nbsp;from&nbsp;</span>}
              {mode === 'assign' && <span>&nbsp;to&nbsp;</span>}
              {listOptions[parentContext]}
              &nbsp;
              <Strong>
                {mode === 'unassign' &&
                  parentContext === TYPE_PAM &&
                  segment.pam.name}
                {mode === 'unassign' &&
                  parentContext === TYPE_BRAND &&
                  segment.brand.name}
                {mode === 'assign' &&
                  (parentContext === TYPE_BRAND ||
                    parentContext === TYPE_PAM) &&
                  parentObject.name}
                {parentContext === TYPE_PKG && parentObject.name}
                {parentContext === TYPE_MVR_RULE && parentObject.description}
                {parentContext === TYPE_APP_INSTANCE_CUSTOMER &&
                  parentObject.application.description}
              </Strong>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default SegmentsSummary;
