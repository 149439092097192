import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ObjectInspector } from 'react-inspector';
import { UserVersion } from '../../../api/users/types';

export type UserVersionRowProps = {
  version: UserVersion;
};

const UserVersionRow: React.FC<UserVersionRowProps> = ({ version }) => (
  <M.TableRow>
    <M.TableCell>
      <small className='text-muted'>{version.created_at}</small>
    </M.TableCell>
    <M.TableCell>
      <small>{version.event}</small>
    </M.TableCell>
    <M.TableCell>
      <small>{version.whodunnit}</small>
    </M.TableCell>
    <M.TableCell data-testid='object-inspector'>
      {version.object_changes && (
        <ObjectInspector data={version.object_changes} />
      )}
    </M.TableCell>
    <M.TableCell>
      {version.roles?.role_names?.replaceAll(';', ', ')}
    </M.TableCell>
  </M.TableRow>
);

export default UserVersionRow;
