import { validateEmail } from 'components/AddScreenings/shared/ModalComponents';
import { Candidate } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const countCandidates = (candidates: Candidate[]) => {
  let count = 0;
  candidates.forEach(candidate => {
    if (candidate.email && validateEmail(candidate.email)) count += 1;
  });
  return count;
};
