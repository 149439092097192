import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getDashboardUrl } from 'utils';
import moment from 'moment';
import { C_CRIMINAL, C_FACIS, C_MVR } from 'Constants';

type Props = {
  cell: any;
};

const productNames = {
  criminal: C_CRIMINAL,
  mvr: C_MVR,
  facis: C_FACIS,
};

const EnrollmentsTableCell: React.FC<Props> = ({ cell }) => {
  const { value } = cell;

  let cellBody;
  switch (cell.info.header) {
    case 'worker': {
      const candidate_page_uri = getDashboardUrl(
        `/candidates/${value.candidate_id}`,
      );
      cellBody = (
        <M.Link href={candidate_page_uri} target='_blank' rel='noopener'>
          {value.full_name}
        </M.Link>
      );
      break;
    }
    case 'last_activity': {
      cellBody = moment(value).utc().calendar('L');
      break;
    }
    case 'type': {
      value.sort();
      const productList = value.map((product: string) => {
        return productNames[product as keyof Object];
      });
      cellBody = productList.join(', ');
      break;
    }
    default: {
      cellBody = value;
    }
  }

  return <M.TableCell>{cellBody}</M.TableCell>;
};

export default EnrollmentsTableCell;
