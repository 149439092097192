import React from 'react';
import { useDisplayDeferredAction } from 'modules/adjudication/utilities';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const displayDefer = useDisplayDeferredAction();

  return displayDefer ? <>{children}</> : null;
};

export default Guard;
