import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  internationalGeos,
  stateGeos,
  cityGeosByState,
} from 'components/Signup/constants/geos';
import Section from '../styles';
import { InfoIcon, DomesticWorkLocation } from './styles';

type Props = {
  isInternational: boolean;
  workLocation: any;
  setWorkLocation: React.Dispatch<React.SetStateAction<any>>;
};

const itemToString = (item: { name?: string }) => item?.name;

const WorkLocationSection: React.FC<Props> = ({
  isInternational,
  workLocation,
  setWorkLocation,
}) => {
  const { t } = useTranslation('signup');
  const handleStateChange = useCallback(
    e => {
      setWorkLocation((prevLocation: any) => ({
        ...prevLocation,
        city: null,
        state: e,
      }));
    },
    [setWorkLocation],
  );

  const handleCityChange = useCallback(
    e => {
      setWorkLocation((prevLocation: any) => ({ ...prevLocation, city: e }));
    },
    [setWorkLocation],
  );

  const handleCountryChange = useCallback(
    e => {
      setWorkLocation(() => ({ state: null, city: null, country: e }));
    },
    [setWorkLocation],
  );

  const selectedCountry = workLocation?.country;
  const selectedState = workLocation?.state;
  const selectedCity = workLocation?.city;

  const cities =
    selectedState?.state && cityGeosByState[selectedState?.state]
      ? cityGeosByState[selectedState?.state]
      : [];

  return (
    <Section data-testid='work-location-section'>
      <h4 data-testid='work-location-title'>
        {t(`pages.candidateInfoPage.candidateInfoSection.workLocation.title`)}{' '}
        <M.Tooltip
          align='top'
          label='Most locations have laws, regulations, and requirements for ordering background checks.'
        >
          <InfoIcon icon='Information' />
        </M.Tooltip>
      </h4>
      {isInternational ? (
        <M.Typeahead
          id='country-select'
          className='country-select'
          data-testid='country-select'
          selectedItem={selectedCountry}
          titleText='Country'
          items={internationalGeos}
          invalidText='Country not found'
          itemToString={itemToString}
          placeholder='Select country'
          onChange={handleCountryChange}
          autoComplete='nope'
        />
      ) : (
        <DomesticWorkLocation data-testid='domestic-work-location-seleects'>
          <M.Typeahead
            id='select-state'
            className='state-select'
            data-testid='state-select'
            selectedItem={selectedState}
            titleText='State'
            items={stateGeos}
            invalidText='State not found'
            itemToString={itemToString}
            placeholder='Select state'
            onChange={handleStateChange}
            autoComplete='nope'
          />
          {cities.length > 0 && (
            <M.Typeahead
              id='city-select'
              className='city-select'
              data-testid='city-select'
              selectedItem={selectedCity}
              titleText='City (optional)'
              items={cities}
              invalidText='City not found'
              itemToString={itemToString}
              placeholder='Select city'
              onChange={handleCityChange}
              autoComplete='nope'
            />
          )}
        </DomesticWorkLocation>
      )}
    </Section>
  );
};

export default WorkLocationSection;
