import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const ModalBody = styled(M.ModalBody)`
  section:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  section:last-of-type {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 0.5rem !important;
  }

  p {
    color: ${colors.coolBlack70};
  }

  h2 + p {
    margin-top: 0 !important;
  }
`;

export default ModalBody;
