import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import * as API from 'api';
import { useUser } from 'context/CurrentUser';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import { useTaskSearchParams } from 'modules/case-management/ui/TasksPage/hooks';
import FilterLabel from '../FilterLabel';

export const Packages: React.FC = () => {
  const {
    account: { id: accountId },
  } = useUser();
  const history = useHistory();
  const taskParams = useTaskSearchParams();

  const selectedItems = taskParams?.report_package
    ? taskParams.report_package.split(',')
    : [];

  const onChange = useCallback(
    ({ selectedItems }) => {
      const selections = selectedItems.map(
        (selection: { id: any }) => selection.id,
      );
      const searchParams = new URLSearchParams({
        ...taskParams,
        page: '1',
      } as Record<string, string>);
      if (selectedItems.length) {
        searchParams.set('report_package', selections.join(','));
      } else {
        searchParams.delete('report_package');
      }
      history.push({
        pathname: TASKS_PATH,
        search: searchParams.toString(),
      });
    },
    [history, taskParams],
  );

  const formatOptions = (options: any) => {
    if (!options.length) return [];

    return options.map((option: string) => ({
      id: option,
    }));
  };
  const packagesRes = API.packages.useList({
    accountId,
    params: { with_cc: true },
    useCache: true,
  });
  const packages = packagesRes?.data?.map((p: { slug: any }) => p.slug) || [];

  const items = formatOptions(packages);

  const formattedSelections = formatOptions(selectedItems);

  const itemToString = useCallback((item: { id: string }) => item.id, []);

  const placeholderText = !formattedSelections.length ? 'All' : '';

  return (
    <M.MultiSelect
      id='case-management-tasks-page-packages-filter'
      label={placeholderText}
      initialSelectedItems={formattedSelections}
      items={items || []}
      itemToString={itemToString}
      onChange={onChange}
      titleText={<FilterLabel filterType='package' />}
    />
  );
};

export default Packages;
