import { Type } from 'modules/assess/models/rulesets/growth-insights/entity';

export const mockData: Type = {
  lookback_rules: [
    {
      annual_candidates: 33,
      annual_revenue: 29700,
      charge_category: 'Vehicles & Traffic',
      charge_subcategory: 'Unsafe Operation',
      charge_subsubcategory: 'Disobey Traffic Rules',
      eligible_years: {
        original: 7,
        recommended: 5,
      },
      escalated_years: {
        original: 3,
        recommended: 2,
      },
      severity: 'petty_offense',
    },
    {
      annual_candidates: 67,
      annual_revenue: 60300,
      charge_category: 'Violence',
      charge_subcategory: 'Harassment & Threats',
      charge_subsubcategory: 'Blackmail/Extortion',
      eligible_years: {
        original: 7,
        recommended: 5,
      },
      escalated_years: {
        original: 3,
        recommended: 2,
      },
      severity: 'misdemeanor',
    },
    {
      annual_candidates: 100,
      annual_revenue: 90000,
      charge_category: 'Sexual',
      charge_subcategory: 'Sexual Abuse',
      charge_subsubcategory: 'Incest',
      eligible_years: {
        original: 7,
        recommended: 5,
      },
      escalated_years: {
        original: 3,
        recommended: 2,
      },
      severity: 'felony',
    },
  ],
  fairness_and_compliance: [
    {
      name: 'All pending charges are eligible',
      code: 'ACCEPT_CHARGES_PENDING',
      annual_candidates: 3000,
      annual_revenue: 8000,
    },
    {
      name: 'All charges older than 7 years are eligible',
      code: 'ACCEPT_RECORDS_OLDER_THAN_7_YEARS',
      annual_candidates: 1500,
      annual_revenue: 4000,
    },
    {
      name: "Charges when candidate's age was under 23 are eligible",
      code: 'ACCEPT_AGE_UNDER_23',
      annual_candidates: 2000,
      annual_revenue: 7000,
    },
  ],
};
