import React from 'react';
import * as Common from 'modules/assess/ui/rules/common/rule';
import ActionMenuButtons from 'modules/assess/ui/rules/common/action-menu-buttons';
import Separator from 'modules/assess/ui/rules/common/rule/editor/separator';
import { TopGroupType } from 'modules/assess/ui/rules/common/rule/editor/types';
import TopGroupViewer from './top-group-viewer';

type Props = {
  rule: any;
};

const ComplexRuleViewer: React.FC<Props> = ({ rule }) => {
  return (
    <div data-testid='assess-complex-rule-viewer'>
      <Common.Container>
        <ActionMenuButtons rule={rule} />
        <Common.Assessment rule={rule} />
      </Common.Container>
      {rule.conditions.map((condition: TopGroupType, index: number) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`complex-rule-viewer-${index}`}
            data-testid='assess-complex-rule-top-group-viewer'
          >
            {index > 0 && (
              <Separator
                style={{ marginLeft: '0.8em' }}
                labelTranKey='assess:common.and'
              />
            )}
            <TopGroupViewer condition={condition} />
          </div>
        );
      })}
    </div>
  );
};

export default ComplexRuleViewer;
