import React from 'react';
import { ReportProps } from 'types';
import { M } from '@dashboard-experience/mastodon';
import { ReportStatuses, getResultOrStatus } from '@dashboard-experience/utils';
import { SCREENING_TITLES } from 'utils';
import { useUser } from 'context/CurrentUser';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';

const TerroristWatchlist: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const currentUser = useUser();

  const { terrorist_watchlist_search: terroristWatchlist } = report;

  return terroristWatchlist ? (
    <M.Screening
      title='Global Watchlist Search'
      screening={terroristWatchlist}
      statusType={reportStatusType}
      id={SCREENING_TITLES.terrorist_watchlist_search.id}
    >
      {getResultOrStatus(terroristWatchlist) === ReportStatuses.CONSIDER &&
        terroristWatchlist?.records?.map((record: any) => (
          <M.Record
            key={record.id}
            record={record}
            currentUser={currentUser}
            report={report}
            reportStatusType={reportStatusType}
            chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
          />
        ))}
    </M.Screening>
  ) : null;
};

export default TerroristWatchlist;
