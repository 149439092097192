import React from 'react';

type Node = {
  custom_id: string;
  parent_custom_id?: any;
  tier?: any;
  name: string;
  id: string;
};

// TODO refactor to proper types when API types are defined
export type ContextType = {
  account: any;
  geos: any;
  anyLoading: boolean;
  isSingleNode: boolean;
  nodes: any;
  nodeIsLoading: boolean;
  nodePackages: any;
  onNodeChange: (value?: Node | undefined) => void;
  onNodeFilterChange: Function;
  selectedNode: any;
  packages: any;
  programs: any;
  payment_profiles: any;
  billing_entity_enabled: boolean;
};

const Context = React.createContext<ContextType>({
  account: undefined,
  geos: [],
  anyLoading: false,
  isSingleNode: false,
  nodes: [],
  nodeIsLoading: false,
  nodePackages: [],
  onNodeChange: () => {},
  onNodeFilterChange: () => {},
  selectedNode: undefined,
  packages: [],
  programs: [],
  payment_profiles: [],
  billing_entity_enabled: false,
});

export default Context;
