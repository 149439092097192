import React from 'react';
import * as Entity from 'modules/assess/models/settings/account';
import Reports from './reports/container';

type Props = {
  locals: Entity.Type;
  remote?: Entity.Type;
  setNeedsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const Content: React.FC<Props> = ({
  locals,
  remote,
  setNeedsConfirmation,
  setSettings,
}) => {
  return (
    <div>
      <Reports
        locals={locals}
        remote={remote}
        setNeedsConfirmation={setNeedsConfirmation}
        setSettings={setSettings}
      />
    </div>
  );
};

export default Content;
