import styled from 'styled-components';
import AlignItems from './AlignItems';

const FlexRowAlignRight = styled.div<{ gap: string; alignItems: AlignItems }>`
  align-items: ${props => props.alignItems};
  align-self: stretch;
  display: flex;
  gap: ${props => props.gap};
  justify-content: flex-end;
`;

export default FlexRowAlignRight;
