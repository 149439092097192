import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Text } from '../../Text';

const IneligiblePersonsHeader = () => (
  <M.Container.Row style={{ marginBottom: '1rem' }}>
    <M.Container.Col>
      <h4>Showing results from last 30 days</h4>
      <Text>
        The following individuals are no longer eligible for Continuous MVR due
        to an error with their enrollment.
      </Text>
    </M.Container.Col>
  </M.Container.Row>
);

export default IneligiblePersonsHeader;
