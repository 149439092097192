import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { AssessStatuses, StatusTypes } from '@dashboard-experience/utils';
import { Stats } from 'modules/assess/models/rulesets/ruleset';

type Props = {
  stats?: Stats;
};

const BoldSpan = styled.span`
  font-weight: bold;
`;

const TotalReportsSpan = styled.span`
  padding: 0 1.5em;
`;

const StatusBadgeSpan = styled.span`
  padding: 0 1.2em 0 0.6em;
`;

const EligibleReportsSpan = styled.span`
  padding-right: 0.3em;
`;

const Reports: React.FC<Props> = ({ stats }) => {
  const { t } = useTranslation('assess:v2');
  const totalReports = capitalize(t('home.guidelines.total_reports'));
  const reportsMarked = capitalize(t('home.guidelines.reports_marked'));

  if (!stats) {
    return null;
  }
  const { eligible_reports_percentage, num_eligible_reports, total_reports } =
    stats;
  const style = {
    alignItems: 'center',
    color: colors.uiTextPrimaryLight,
  };
  const basePath = 'assess-v2-ui-home-guidelines-record-reports';
  const containerId = `${basePath}-container`;
  const totalId = `${basePath}-total`;
  const totalReportsId = `${basePath}-total-reports`;
  const reportsMarkedId = `${basePath}-reports-marked`;
  const statusBadgeId = `${basePath}-status-badge`;
  const eligibleReportsId = `${basePath}-eligible-reports`;
  const eligiblePercentId = `${basePath}-eligible-percent`;

  return (
    <M.Container.Row
      id={containerId}
      data-testid='assess-v2-reports'
      style={style}
    >
      <BoldSpan id={totalId}>{totalReports}</BoldSpan>
      <TotalReportsSpan
        id={totalReportsId}
        data-testid='assess-v2-reports-total'
      >
        {total_reports}
      </TotalReportsSpan>
      <BoldSpan id={reportsMarkedId}> {reportsMarked} </BoldSpan>
      <StatusBadgeSpan id={statusBadgeId}>
        <M.StatusBadge
          status={AssessStatuses.ELIGIBLE}
          statusType={StatusTypes.Assess}
        />
      </StatusBadgeSpan>
      <EligibleReportsSpan
        id={eligibleReportsId}
        data-testid='assess-v2-reports-eligible'
      >
        {num_eligible_reports}
      </EligibleReportsSpan>
      (
      <BoldSpan
        id={eligiblePercentId}
        data-testid='assess-v2-reports-eligible-percent'
      >
        {eligible_reports_percentage}%
      </BoldSpan>
      )
    </M.Container.Row>
  );
};

export default Reports;
