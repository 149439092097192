import React from 'react';
import styles from './styles';

type Props = {
  header: string;
  subheader?: string;
  topRightComponent?: React.ReactNode;
  isShown?: boolean;
};

const Section: React.FC<Props> = ({
  children,
  header,
  subheader,
  topRightComponent,
  isShown = true,
  ...props
}) => {
  if (!isShown) return null;

  return (
    <styles.Container {...props}>
      <styles.HeaderContainer>
        <styles.Header>{header}</styles.Header>
        {!!topRightComponent && (
          <styles.TopRightComponent>
            {topRightComponent}
          </styles.TopRightComponent>
        )}
      </styles.HeaderContainer>
      {!!subheader && <styles.Subheader>{subheader}</styles.Subheader>}
      <styles.ChildContainer>{children}</styles.ChildContainer>
    </styles.Container>
  );
};

export default Section;
