import React from 'react';
import { getResultOrStatus } from '@dashboard-experience/utils';
import { MutedText } from 'components/Text';
import { GenericObject } from 'types';
import Certification from './Certification';
import { hasManualEntries, isCanceled, isComplete, isPending } from './Helpers';
import ResultDocuments from './ResultDocuments';
import Timeline from './Timeline';

type Props = {
  licenseVerification: GenericObject;
  reportStatusType: string;
};

const LicenseVerification: React.FC<Props> = ({
  licenseVerification,
  reportStatusType,
}) => {
  const { certifications, documents, cancellation_reason_description } =
    licenseVerification;
  const status = getResultOrStatus(licenseVerification);

  return (
    <>
      {cancellation_reason_description && (
        <div>Cancellation Reason: {cancellation_reason_description}</div>
      )}
      {isPending(status) && (
        <MutedText>
          Details pending professional license verification.
        </MutedText>
      )}
      {isComplete(status) && !isCanceled(status) && !certifications.length && (
        <>Candidate did not provide any professional license information.</>
      )}
      {isComplete(status) && !!certifications.length && (
        <>
          {certifications.map((certification: { [x: string]: any }) => {
            const {
              input: { license_number },
            } = certification;
            return (
              <Certification
                key={license_number}
                certification={certification}
                reportStatusType={reportStatusType}
              />
            );
          })}
          <ResultDocuments documents={documents} />
        </>
      )}
      {!hasManualEntries(certifications) && (
        <Timeline licenseVerification={licenseVerification} />
      )}
    </>
  );
};

export default LicenseVerification;
