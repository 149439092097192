import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFetch as useQueryFetch } from 'modules/assess/ui/ruleset/api';
import { useFetch as useSettingsFetch } from 'modules/assess/ui/settings/api/hooks';
import { useDefaultCriminalRuleset, useLite } from 'modules/assess/v2/ui/hooks';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { Upgrade } from 'modules/assess/v2/ui/common';
import { Page } from 'pages/Assess';
import Guidelines from './guidelines';
import Settings from './settings';

export const Home: React.FC<{}> = () => {
  const defaultCriminalRuleset = useDefaultCriminalRuleset();
  const guidelinesFetch = useQueryFetch(defaultCriminalRuleset?.id);

  const settingsFetch = useSettingsFetch();
  const isLite = useLite();

  const loading =
    guidelinesFetch.status === 'loading' || settingsFetch.status === 'loading';

  if (loading) {
    return <M.LoadingSpinner />;
  }

  const dataTestId = 'assess-v2-home';

  return (
    <Page.V2.Container
      id={dataTestId}
      data-testid={dataTestId}
      event={Amplitude.Home.Events.Pageview}
    >
      <Guidelines data={guidelinesFetch.data} />
      <Settings data={settingsFetch.data} status={settingsFetch.status} />
      {isLite && (
        <Upgrade.Standard eventProperty={Amplitude.Home.Events.Pagename} />
      )}
    </Page.V2.Container>
  );
};

export default Home;
