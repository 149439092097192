import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import Cell from './Cell';

type PropsType = {
  assessment: string | null;
  url: string;
};

const Assessment = ({ assessment, url }: PropsType) => {
  if (!assessment) return null;

  return (
    <Cell url={url} testid='cm-ui-tasks-page-table-rows-assessment'>
      <M.StatusBadge statusType={StatusTypes.Assess} status={assessment} />
    </Cell>
  );
};

export default Assessment;
