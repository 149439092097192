import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const SuccessContainer = styled.div`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .title {
    padding: 0.75rem 0;
  }
  .subtitle {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .mastodon-icon {
    color: ${colors.checkrBlue};
  }
`;
const Success = () => {
  return (
    <SuccessContainer>
      <M.Icon name='CheckmarkFilled' size='48' />
      <strong className='title'>
        Fair Chance Assessment successfully updated
      </strong>
      <p className='subtitle'>
        We will send a post-adverse action email in the time specified. You will
        have between now and then to update the assessment.
      </p>
    </SuccessContainer>
  );
};

export default Success;
