import { M } from '@dashboard-experience/mastodon';
import React, { useCallback, useState, SyntheticEvent } from 'react';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import * as Version from 'modules/assess/models/rulesets/version';
import { useTranslation } from 'react-i18next';
import PromoteActions from './promote/Actions';
import PromoteContent from './promote/Content';
import VersionsActions from './Actions';
import VersionsContent from './Content';

type Props = {
  open: boolean;
  onClose?: (event: SyntheticEvent) => void;
  ruleset?: Ruleset.Type;
};

export const Container: React.FC<Props> = ({ open, onClose, ruleset }) => {
  const [selectedVersion, setSelectedVersion] = useState<Version.Type>();
  const { t } = useTranslation();
  const modalTitle = t('assess:ruleset.versions.title', {
    id: ruleset?.id,
    name: ruleset?.name,
    interpolation: { escapeValue: false },
  });

  const handleClose = useCallback(
    (e: SyntheticEvent) => {
      setSelectedVersion(undefined);
      onClose?.(e);
    },
    [onClose],
  );

  if (!open || !ruleset) {
    return null;
  }

  let activeVersionNumber: number | undefined;
  if (ruleset.active && typeof ruleset.active === 'object') {
    activeVersionNumber = ruleset.active.number;
  }

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-versions-dialog'
      open={open}
      size='lg'
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-versions-title'
        title={modalTitle}
        closeModal={handleClose}
      />
      <M.ModalBody data-testid='assess-ruleset-versions-content'>
        {selectedVersion ? (
          <PromoteContent
            rulesetName={ruleset.name}
            activeVersionNumber={activeVersionNumber}
            selectedVersionNumber={selectedVersion.number}
          />
        ) : (
          <VersionsContent
            ruleset={ruleset}
            close={handleClose}
            setSelectedVersion={setSelectedVersion}
          />
        )}
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-versions-actions'>
        {selectedVersion ? (
          <PromoteActions onClose={handleClose} version={selectedVersion} />
        ) : (
          <VersionsActions onClose={handleClose} />
        )}
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
