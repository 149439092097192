import React, { useCallback } from 'react';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import { Checkbox } from 'components/AddScreenings/shared/ListItemElements';

const CommercialMvrCheckbox: React.FC<{
  onAddClick: Function;
  checked: boolean;
}> = ({ onAddClick, checked }) => {
  const handleOnChange = useCallback(
    (event: any, { checked }: { checked: boolean }) => {
      onAddClick(ScreeningType.motor_vehicle_report, {
        mvr_type: [checked, checked ? 'commercial' : ''],
        perform_cdlis_after_current_dl: checked,
        perform_additional_license_after_cdlis: false,
      });
    },
    [onAddClick],
  );

  return (
    <Checkbox
      data-testid='requires-commercial-mvr-checkbox'
      id='requires-commercial-mvr'
      name='requires-commercial-mvr'
      value='requires-commercial-mvr'
      labelText='Requires commercial MVR'
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

export default CommercialMvrCheckbox;
