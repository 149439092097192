import React from 'react';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { Kind } from 'modules/assess/models/rules/common/kind';
import { Namespace } from 'modules/assess/api';
import TextSections from '../text-sections';
import Rules from './Rules';

type Props = {
  kind: Kind;
  namespace?: Namespace;
};

export const Container: React.FC<Props> = ({ kind, namespace }) => {
  const state = useState();
  const rulesMap = state.rules?.premade.table || {};
  const rulesList = Object.values(rulesMap);

  if (!namespace) {
    return null;
  }

  return (
    <div data-testid='assess-rules-premade-list-container'>
      <TextSections
        data-testid='assess-rules-premade-list-description'
        namespace={namespace}
        tkey={`rules.${kind}.fairness_description`}
      />
      <Rules namespace={namespace} rules={rulesList} />
    </div>
  );
};

export default Container;
