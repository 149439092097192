import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  useGetAdjudicationSubtypes,
  AdjudicationSubtype,
} from 'modules/adjudication/api';
import { Subtype, SubtypeKind } from 'modules/adjudication/data';
import _ from 'lodash';

const RadioButtonGroup = styled(M.RadioButtonGroup)`
  .cds--radio-button-group {
    width: 100%;
  }
  .mastodon-radio-button {
    width: 100%;
    display: inline-flex;
    padding: 0.75rem;
    border-bottom: 1px solid ${colors.uiGrey200};
    .cds--radio-button__label {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .cds--radio-button__appearance {
    margin-right: 1.25rem;
  }
  legend {
    font-size: 14px !important;
  }
`;

type Props = {
  subtypeKind:
    | Subtype.Adjudication
    | Subtype.Engaged
    | Subtype.AdverseAction
    | Subtype.Deferred;
  onChange?: Function;
  legendText?: string;
  value: string;
};

const SubtypeSelection: React.FC<Props> = ({
  subtypeKind,
  onChange,
  legendText,
  value,
}) => {
  const adjudicationSubtypes = useGetAdjudicationSubtypes();
  const kind = `${_.camelCase(subtypeKind)}Subtypes` as SubtypeKind;
  const subtypes = adjudicationSubtypes[kind] as AdjudicationSubtype[];

  const handleChange = useCallback(
    (newSelection: string) => {
      onChange && onChange(subtypes.find((type) => type.name === newSelection));
    },
    [onChange, subtypes],
  );

  return (
    <RadioButtonGroup
      legendText={legendText}
      orientation='vertical'
      onChange={handleChange}
      valueSelected={value}
    >
      {subtypes?.map((subtype) => {
        const { name, slug } = subtype;
        return <M.RadioButton key={slug} labelText={name} value={name} />;
      })}
    </RadioButtonGroup>
  );
};

export default SubtypeSelection;
