import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetAccountSettings, useGetWorksites } from 'api/i9';

const WorksiteDropDown: React.FC<{
  id: string;
  name: string;
  onChange?: (id: string) => void;
}> = ({ id, name, onChange }) => {
  const {
    data: settingsData,
    isError,
    isLoading: accountSettingsIsLoading,
  } = useGetAccountSettings();
  const [selected, setSelected] = useState('');

  const { data: allWorksites, isLoading: worksitesIsLoading } = useGetWorksites(
    'name',
    'ASC',
  );

  const [items, setItems] = useState<{ id: string; label: string }[]>([]);

  useEffect(() => {
    if (allWorksites?.data && !items.length) {
      setItems(allWorksites.data.map(w => ({ id: w.id, label: w.name })));
    }
  }, [allWorksites?.data, items.length, setSelected, onChange]);

  useEffect(() => {
    if (items.length && !selected && !isError) {
      setSelected(
        items.find(w => w.id === settingsData?.default_worksite_id)?.label ||
          '',
      );
      onChange?.(settingsData?.default_worksite_id || '');
    }
  }, [settingsData?.default_worksite_id, isError, items, onChange, selected]);

  const onChangeHandler = useCallback(
    event => {
      const worksite = allWorksites?.data.find(
        w => w.id === event.selectedItem.id,
      );
      setSelected(worksite?.name || '');
      onChange?.(worksite?.id || '');
    },
    [allWorksites?.data, onChange],
  );

  if (worksitesIsLoading || accountSettingsIsLoading) {
    return <M.LoadingInline description='Loading...' />;
  }
  return (
    <M.Dropdown
      id={id}
      name={name}
      items={items}
      selectedItem={selected}
      onChange={onChangeHandler}
    />
  );
};

export default WorksiteDropDown;
