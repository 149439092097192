import React, { useMemo } from 'react';
import styled from 'styled-components';

import { colors, M } from '@dashboard-experience/mastodon';

import { EmploymentQuestion } from 'types';

export type DOTv2QuestionsProps = {
  testId: string;
  questions: EmploymentQuestion[];
};

type QuestionProps = {
  text: string;
  response: string;
};

const QA = styled.div`
  line-height: 20px;

  .question-text {
    color: ${colors.uiTextPrimaryLight};
    display: inline-block;
    width: 80%;
  }

  .response-text {
    color: ${colors.uiTextPrimaryLight};
  }

  .response-details {
    width: 80%;
    color: ${colors.uiTextSecondaryLight};
  }

  // The line which says "Recordable Accidents Details-":

  .details-line {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  // Inline answer "Yes" or "No" to the side of the question:

  .inline {
    float: right;
    width: 6em;
  }

  margin-top: 0;
  margin-bottom: 16px;

  ul {
    list-style-type: disc;
    margin-left: 2em;
  }
`;

// An affirmative response will be in this format:
// Yes, Recordable Accidents Details- Date of 1st Accident: 09/06/2023; Accident Location: Austin TX; Preventable: No; Recordable: Yes; Injuries: No; Fatalities: No; Hazmat Spill: No; Description: NA | Date of 2nd Accident: 10/09/2023; Accident Location: Paris TX; Preventable: Yes; Recordable: Yes; Injuries: Yes; Fatalities: No; Hazmat Spill: No; Description: Not Available | Date of 3rd Accident: 11/28/2023; Accident Location: San Antonio TX; Preventable: Yes; Recordable: Yes; Injuries: Yes; Fatalities: Yes; Hazmat Spill: No; Description: No | Date of 4th Accident: 12/01/2023; Accident Location: Tyler TX; Preventable: No; Recordable: Yes; Injuries: No; Fatalities: No; Hazmat Spill: Yes; Description: No |
const MultianswerQuestion: React.FC<QuestionProps> = ({ text, response }) => {
  // Parse the text up to the first comma as the yes/no answer, shown inline on the right.
  const firstCommaIndex = response.indexOf(',');
  const yesOrNo =
    firstCommaIndex === -1
      ? response // If there is no comma, the entire response is the yes/no answer.
      : response.slice(0, firstCommaIndex).trim();

  // Parse the text between the first comma and the first dash as the details line, shown below the question.
  const firstDashIndex = response.indexOf('-');
  const detailsLine =
    firstDashIndex === -1
      ? response.slice(firstCommaIndex + 1).trim() // If there is no dash, the entire text after the first comma is the details line.
      : response.slice(firstCommaIndex + 1, firstDashIndex + 1).trim();

  // Parse the text after the first dash as the list of pipe-delimited accidents.
  const accidentsString =
    firstDashIndex === -1
      ? '' // If there is no dash, there are no accidents.
      : response.slice(firstDashIndex + 1);
  const accidents = accidentsString
    .split('|')
    .reverse() // Show most recent accident first.
    .map(accident => accident.trim())
    .filter(accident => accident !== '');

  return (
    <QA data-testid='employment-dot-v2-question'>
      <span
        data-testid='employment-dot-v2-question-text'
        className='question-text'
      >
        {text}
      </span>
      <span
        data-testid='employment-dot-v2-response-text'
        className='response-text inline'
      >
        {yesOrNo}
      </span>
      {yesOrNo.toLowerCase() === 'yes' && (
        <div className='response-details'>
          <div
            data-testid='employment-dot-v2-multianswer-details-line'
            className='details-line'
          >
            {detailsLine}
          </div>
          {accidents.map((accident, index) => {
            const key = `employment-dot-v2-multianswer-accident-${index}`;
            return (
              <div key={key}>
                <ul>
                  <li data-testid='employment-dot-v2-multianswer-accident-text'>
                    {accident}
                  </li>
                </ul>
                <hr />
              </div>
            );
          })}
        </div>
      )}
    </QA>
  );
};

const InlineQuestion: React.FC<QuestionProps> = ({ text, response }) => (
  <QA data-testid='employment-dot-v2-question'>
    <span
      data-testid='employment-dot-v2-question-text'
      className='question-text'
    >
      {text}
    </span>
    <span
      data-testid='employment-dot-v2-response-text'
      className='response-text inline'
    >
      {response}
    </span>
  </QA>
);

const DOTv2Questions: React.FC<DOTv2QuestionsProps> = ({
  testId,
  questions,
}) => {
  const sortedQuestions = useMemo(
    () =>
      [...questions].sort(
        (a: EmploymentQuestion, b: EmploymentQuestion) =>
          a.sort_number - b.sort_number,
      ),
    [questions],
  );

  return (
    <div data-testid={testId}>
      <M.Container type='shadow'>
        <M.Expandable
          titleRowProps={{
            'data-testid': 'employment-dot-v2-questions-expandable',
          }}
          title={<h3>DOT Employment Verification Information</h3>}
        >
          {sortedQuestions.map((question: EmploymentQuestion) =>
            question.sort_number === 2 ? (
              <MultianswerQuestion
                key={`employment-dot-v2-multianswer-question-${question.sort_number}`}
                text={question.text}
                response={question.response}
              />
            ) : (
              <InlineQuestion
                key={`employment-dot-v2-inline-question-${question.sort_number}`}
                text={question.text}
                response={question.response}
              />
            ),
          )}
        </M.Expandable>
      </M.Container>
    </div>
  );
};

export default DOTv2Questions;
