import { Fact } from 'modules/assess/models/criminal/rules';
import * as Entity from 'modules/assess/models/rules/count';

type Rule = Entity.Type;

export function toExternal(rule: Rule): Rule {
  const external: Entity.Type = {
    ...rule,
    conditions: rule.conditions.filter(c => c.fact === Fact.COUNT),
    filters: rule.conditions.filter(c => c.fact !== Fact.COUNT),
  };
  return external;
}

export function toInternal(rule: Rule): Rule {
  const internal: Entity.Type = {
    ...rule,
    conditions: [...rule.conditions, ...rule.filters],
    filters: [],
  };
  return internal;
}
