import { Event } from 'modules/assess/models/event';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import * as CCS from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import ItemEvents from 'modules/assess/ui/common/item-event-times';
import React from 'react';

const EVENTS = ['published', 'updated'];

type Props = {
  item?: Entity;
};

export const ItemEventTimes: React.FC<Props> = ({ item }) => {
  if (!item) {
    return null;
  }

  const active = item.versions?.find(v => v.active);
  const draft = item.versions?.find(v => v.draft);

  const activeEvent = findEvent(active);
  const draftEvent = findEvent(draft);

  return <ItemEvents active={activeEvent} draft={draftEvent} />;
};

function findEvent(version?: CCS.Basic): Event | undefined {
  const events = version?.events.reverse();

  if (!events) {
    return undefined;
  }

  return events.find(e => EVENTS.includes(e.type));
}

export default ItemEventTimes;
