import React from 'react';
import { isEmpty } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';

const IdpDeleteButton = ({
  connectionId,
  deleteIdpConnection,
  isProcessing,
}) => {
  const button = isEmpty(connectionId) ? (
    <span> </span>
  ) : (
    <M.Button
      kind='danger'
      size='md'
      onClick={deleteIdpConnection}
      style={{ margin: '0rem 3rem' }}
    >
      Delete
    </M.Button>
  );

  return (
    <>
      {isProcessing ? (
        <span>
          <M.LoadingInline />
        </span>
      ) : (
        button
      )}
    </>
  );
};

IdpDeleteButton.propTypes = {
  connectionId: PropTypes.string,
  deleteIdpConnection: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

IdpDeleteButton.defaultProps = {
  connectionId: '',
};

export default IdpDeleteButton;
