import React from 'react';
import ExpandableKeyValue from './ExpandableKeyValue';

type Props = {
  publicRecords: { [x: string]: any };
  completedAt: string;
};

const list = [
  {
    name: 'Docket Number',
    field: 'docket_number',
  },
  {
    name: 'Date Reported',
    field: 'date_reported',
  },
  {
    name: 'Date Filed',
    field: 'date_filed',
  },
  {
    name: 'Date Paid',
    field: 'date_paid',
  },
  {
    name: 'Liabilities',
    field: 'liabilities',
  },
];
const amountFields = ['liabilities'];
const kind = 'Public Records';

const PublicRecords: React.FC<Props> = ({ publicRecords, completedAt }) => {
  const missing = publicRecords.length === 0;
  const missingInfo = {
    missing,
    completedAt,
    type: kind.toLocaleLowerCase(),
  };

  return (
    <ExpandableKeyValue
      items={publicRecords}
      heading={kind}
      kind={kind}
      list={list}
      amountFields={amountFields}
      id='screenings-credit-report-public-records'
      missingInfo={missingInfo}
    />
  );
};

export default PublicRecords;
