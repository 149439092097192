import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { includes, get } from 'lodash';
import { isInternationalCountry } from 'utils/Filters';
import { M } from '@dashboard-experience/mastodon';
import { Geo, Item, noneGeo } from '../../SharedItems';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  ADD_CHECKS,
} from '../../Context';

const COUNTRIES_NOT_AVAILABLE = ['AF'];

const defaultItemToString = (item: Item) => {
  const geoName = get(item, 'name');

  if (includes(COUNTRIES_NOT_AVAILABLE, get(item, 'country'))) {
    return `${geoName} (Unavailable)`;
  }

  if (get(item, 'country') == 'GE' && get(item, 'name') == 'Georgia') {
    return `${geoName} (country)`;
  }

  return geoName;
};

const GeoDropdown = styled(M.ComboBox)`
  width: 280px;
  margin: 10px 20px 10px 0;

  @media all and (max-width: 480px) {
    width: 100%;
  }

  .cds--list-box__selection {
    display: none;
  }

  .cds--text-input {
    padding-right: 2.5rem;
  }
`;

export const geoFilter = (menu: any) => {
  return menu?.item?.name
    ?.toLowerCase()
    .includes(menu?.inputValue?.toLowerCase());
};

const SelectGeo = ({
  segmentation_enabled,
  geos,
  geos_required,
}: {
  segmentation_enabled: boolean;
  geos: Geo[];
  geos_required: boolean;
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  // Use base package to see whether the user has come from the review & submit page
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  const resetProgram = useCallback(
    ({ program }) => {
      dispatch({
        type: actionTypes.ADD_PROGRAM,
        payload: { program },
      });
    },
    [dispatch],
  );
  // Determines if 'Domestic' or 'International'
  const updateGeoType = useCallback(
    (geo: Geo) => {
      dispatch({
        type: actionTypes.SET_SELECTED_GEO,
        payload: {
          selectedGeo: isInternationalCountry(geo?.country)
            ? 'international'
            : 'domestic',
        },
      });
    },
    [dispatch],
  );

  const handleGeoChange = useCallback(
    ({ selectedItem }: { selectedItem: Geo }) => {
      dispatch({
        type: actionTypes.SET_GEO,
        payload: { geo: selectedItem },
      });
      updateGeoType(selectedItem);
      if (Object.keys(state.program).length > 0) {
        resetProgram({ program: {} });
      }
      // Reset if user comes back to page and changes geo
      if (
        state.geo.country !== selectedItem.country &&
        fromReviewAndSubmitPage
      ) {
        dispatch({
          type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
          payload: {},
        });
      }
    },
    [dispatch, resetProgram, state.program, state.geo, fromReviewAndSubmitPage],
  );

  const selectableGeos = useMemo(() => {
    const selectable = [...geos];
    selectable.sort((a, b) =>
      defaultItemToString(a).localeCompare(defaultItemToString(b)),
    );

    if (geos.length === 0) return selectable;
    if (!geos_required) selectable.push(noneGeo);
    return selectable;
  }, [geos_required, geos]);

  return (
    <>
      {!segmentation_enabled && selectableGeos.length > 0 && (
        <GeoDropdown
          className='geo-dropdown-wrapper'
          data-testid='geo-dropdown-testid'
          id='geo-dropdown'
          placeholder='Geo'
          items={selectableGeos}
          itemToString={defaultItemToString}
          onChange={handleGeoChange}
          selectedItem={Object.keys(state.geo).length > 0 ? state.geo : null}
          disabled={state.workflow === ADD_CHECKS}
          shouldFilterItem={geoFilter}
        />
      )}
    </>
  );
};

export default SelectGeo;
