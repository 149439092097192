import React from 'react';
import { AdversableItem } from 'modules/adjudication/data';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';

import StyledChargesTable from './charges-table';
import { useTimeSinceOffense, useAgeAt } from '../../../../utilities/filters';
import { DATE_TYPES } from './constants';

type Props = {
  // TODO: enhance type
  adverseItems: any;
};

const ChargesTable: React.FC<Props> = ({ adverseItems }) => {
  const { report } = useGetReport();
  const { call: timeSinceOffense } = useTimeSinceOffense();
  const { call: ageAt } = useAgeAt();
  const { t } = useTranslation();

  const displayContextDate =
    report?.account?.adverse_action_item_date_alignment;

  const rows = adverseItems.map((adverseAction: AdversableItem) => {
    let context = 'default';
    const age = ageAt(adverseAction.date, '-', report.candidate?.dob);
    const time = timeSinceOffense(adverseAction.date, '-');
    const { date_type } = adverseAction;

    const path =
      'adjudication:report.actions.pre_adverse.optional_fci.charges_table.columns';

    if (displayContextDate && date_type && DATE_TYPES.includes(date_type)) {
      context = 'dynamic';
    }

    const display_age = t(`${path}.age.${context}`, { age, type: date_type });
    const display_time = t(`${path}.time.${context}`, {
      time,
      type: date_type,
      interpolation: { escapeValue: false },
    });

    return {
      text: adverseAction.text,
      age: display_age,
      time: display_time,
    };
  });

  return (
    <StyledChargesTable rows={rows} displayContextDate={displayContextDate} />
  );
};

export default ChargesTable;
