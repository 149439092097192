import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import styled from 'styled-components';

import Heading from './Heading';
import NoInformation from './NoInformation';

type Props = {
  inquiries: { [x: string]: any };
  completedAt: string;
};

const ScreeningHeading = styled(M.Screening.Heading)`
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  :hover {
    border-bottom: 1px solid ${colors.uiGrey200};
    margin-bottom: -1px;
  }
`;

const testId = 'screenings-credit-report-inquiries';

const Inquiries: React.FC<Props> = ({ inquiries, completedAt }) => {
  const hasInquiries = inquiries.length > 0;
  return (
    <div data-testid={testId}>
      <Heading>Inquiries</Heading>
      {hasInquiries ? (
        inquiries.map((inquiry: any, i: number) => (
          <ScreeningHeading
            data-testid={`${testId}-heading`}
            key={`${inquiry}-${i.toString()}`}
            headingLeft={{ name: inquiry.subscriber_name }}
            headingRight={<p>{moment(inquiry.date).format('ll')}</p>}
          />
        ))
      ) : (
        <NoInformation date={completedAt} type='inquiries' />
      )}
    </div>
  );
};

export default Inquiries;
