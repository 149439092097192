import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLElement> {}

const StyledTitle = styled.h3`
  display: inline;
`;

const SectionTitle: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledTitle {...rest}>{children}</StyledTitle>;
};

export default SectionTitle;
