import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { M, colors } from '@dashboard-experience/mastodon';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;
const ModalBody = styled.div`
  z-index: 100;
  background: ${colors.uiGrey0};
  position: relative;
  margin: 1.75rem auto;
  border-radius: 10px;
  width: 800px;
  padding: 2rem;
  border: 2px solid ${colors.uiGrey900};
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ModalContent = styled.div``;
const ModalCloseButton = styled.button`
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: ${colors.uiGrey900};
  opacity: 0.3;
  cursor: pointer;
  border: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

class Modal extends React.PureComponent {
  render() {
    const { isShowing, title, hide, children, showSubmit, onSubmit } =
      this.props;
    return (
      <ModalOverlay key='modaloverlay' visible={isShowing}>
        <ModalWrapper
          key='modalwrapper'
          aria-modal
          aria-hidden
          tabIndex={-1}
          role='dialog'
        >
          <ModalBody>
            <ModalHeader>
              <h2>{title}</h2>
              <ModalCloseButton
                type='button'
                data-dismiss='modal'
                aria-label='Close'
                onClick={hide}
              >
                <span aria-hidden='true'>&times;</span>
              </ModalCloseButton>
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
            {showSubmit && (
              <ButtonContainer>
                <M.Button onClick={onSubmit}>Submit</M.Button>
              </ButtonContainer>
            )}
          </ModalBody>
        </ModalWrapper>
      </ModalOverlay>
    );
  }
}

Modal.propTypes = {
  isShowing: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  showSubmit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  isShowing: false,
};

export default Modal;
