import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

import { Status } from 'modules/assess/models/insights';

type Props = {
  status?: Status;
};

export const ResultsStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('assess');

  if (status === Status.DONE) {
    return null;
  }

  const message = t(`assess:ruleset.version.insights.results.status.message`, {
    context: status,
  });

  return (
    <M.InlineNotification
      kind='warning'
      title=''
      subtitle={message}
      hideCloseButton
    />
  );
};

export default ResultsStatus;
