import React from 'react';
import styled from 'styled-components';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import {
  List,
  Type,
} from 'modules/assess/models/rulesets/growth-insights/lookback-rules';
import Benefit from './Benefit';
import Box from './Box';
import Recommendation from './Recommendation';

export type Props = {
  lookback_rules: List;
};

export const NUM_DISPLAYED = 3;

const SeveritySpan = styled.span`
  font-weight: bold;
  margin-right: 0.5em;
`;

const TitleParagraph = styled.p`
  margin-top: 0.2em;
`;

const expandableTitle = (item: Type) => {
  const severity = humanizeOnUnderscore(item.severity);
  return (
    <>
      <SeveritySpan>{severity} </SeveritySpan>
      {item.charge_subsubcategory} ({item.charge_subcategory})
    </>
  );
};

export const LookbackRules: React.FC<Props> = ({ lookback_rules }) => {
  const { t } = useTranslation('assess');
  if (!lookback_rules) return null;

  const sortedDisplayedLookbackRules = lookback_rules
    .sort((a, b) => b.annual_revenue - a.annual_revenue)
    .slice(0, NUM_DISPLAYED);
  const title = t('assess:rulesets.growth_insights.lookback_rules.title');

  return (
    <div data-testid='assess-rulesets-growth_insights-lb'>
      <h5>{title}</h5>
      <ul>
        {sortedDisplayedLookbackRules.map(item => {
          return (
            <M.Expandable
              title={<TitleParagraph>{expandableTitle(item)}</TitleParagraph>}
              initialExpanded
              key={item.charge_subsubcategory}
            >
              <Box>
                <Recommendation item={item} />
                <Benefit item={item} />
              </Box>
            </M.Expandable>
          );
        })}
      </ul>
    </div>
  );
};

export default LookbackRules;
