import * as Package from 'modules/assess/models/settings/package';
import * as Geo from 'modules/assess/models/settings/geo';

export function getAdverseActionAutomationGeos(geos: Geo.List = []) {
  return geos.filter(
    g =>
      g.settings.adverse_action_automation.enabled === true &&
      g.settings.adverse_action_automation.value === 'false',
  );
}

export function getGeoSelections(geos: Geo.List | undefined) {
  return geos?.reduce<{ [id: string]: boolean }>((m, g) => {
    m[g.id] =
      g.settings.adverse_action_automation.enabled === true &&
      g.settings.adverse_action_automation.value === 'false';
    return m;
  }, {});
}

export function getPackageSelections(
  packages: Package.List | undefined,
  field: keyof Package.Settings,
) {
  return packages?.reduce<{ [id: string]: boolean }>((m, p) => {
    m[p.id] = !!p.settings[field];
    return m;
  }, {});
}

export function getAutoEngagePackages(packages: Package.List = []) {
  return packages.filter(p => p.settings.assess_auto_engage_eligible_reports);
}

export function getAutoAdverseActionPackages(packages: Package.List = []) {
  return packages.filter(p => {
    return p.settings.assess_auto_adverse_action_escalated_reports;
  });
}

export function getAdverseActionPausePackages(packages: Package.List = []) {
  return packages.filter(p => {
    return p.settings.adverse_action_pause_on_candidate_story_submitted;
  });
}

export function getAutoRequestCandidateStoryPackages(
  packages: Package.List = [],
) {
  return packages.filter(p => {
    return p.settings.candidate_stories_request_on_adverse_action;
  });
}
