import React, { useMemo } from 'react';
import { GenericObject } from 'types';
import { CellProps } from './types';
import { mostRecent } from './utils';

const getComplianceGeo = (source?: GenericObject) => {
  if (source?.geos) return source.geos as GenericObject[];
  return [] as GenericObject[];
};

const formatComplianceGeos = (geos: GenericObject[]) => {
  if (!geos || !geos.length) return '';

  const formattedGeos = geos.map(geo => {
    if (geo.country === 'US' || !geo.country) {
      return [geo.state, geo.city].filter(x => x).join(' - ');
    }
    return geo.name;
  });
  return formattedGeos.join(', ');
};

const ComplianceGeos: React.FC<CellProps> = ({ candidate, report }) => {
  const { reports, invitations } = candidate;
  const mostRecentInvitation: GenericObject | undefined =
    mostRecent(invitations);
  const complianceGeoInfo = useMemo(
    () =>
      getComplianceGeo(
        reports.length
          ? // if we are showing a specific report, use that, otherwise use the aggregate geos for the candiate
            report || candidate
          : mostRecentInvitation,
      ),
    [reports, report, candidate, mostRecentInvitation],
  );

  const formattedGeos = useMemo(
    () => formatComplianceGeos(complianceGeoInfo),
    [complianceGeoInfo],
  );

  return <span>{formattedGeos}</span>;
};

export default ComplianceGeos;
