import React, { ReactElement, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { AACSVExportFilters, CSVExportParams } from 'api/csvExport';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { ModalType, OptionalProps, Screens } from './types';
import { submit } from './utils';
import ExportDateFilter from './CsvExportDateFilter';
import {
  SuccessContainer,
  DateContainer,
  ModalBody,
} from './CsvExportStyledComponents';

type Filters = Partial<AACSVExportFilters>;

type Props = OptionalProps & {
  body: ReactElement;
  downloadCsvDisabled?: boolean;
  filters: Filters;
  open: boolean;
  post: (params: CSVExportParams) => Promise<any>;
  processing: boolean;
  screen: Screens.INITIAL | Screens.SUCCESS;
  toggle: () => void;
  type:
    | ModalType.AdverseAction
    | ModalType.CandidateReports
    | ModalType.DriverRecordReports
    | ModalType.SubscriptionErrors;
};

const CsvModal: React.FC<Props> = ({
  body,
  downloadCsvDisabled,
  filters,
  open,
  post,
  processing,
  screen,
  toggle,
  type,
  modalHeader = `Export ${type} to CSV`,
  dateRangeHeadline = `${type} Created`,
  submitButtonName = 'Export CSV',
  defaultDateDiffStartFromDays = 30,
  dateRangeLimit = undefined,
  disableDatesBefore = undefined,
  disableSubmitButtonUntilSetupDates = false,
}) => {
  const user = useUser();
  const email = user?.email;

  const [isFormEmpty, setIsFormEmpty] = useState(
    disableSubmitButtonUntilSetupDates,
  );

  const [fromDateVal, setFromDateVal] = useState('');
  const [toDateVal, setToDateVal] = useState('');

  // Controls setting fromDate and toDate on modal form and enable submit button
  const handleFormFilling = useCallback((fromDate: string, toDate: string) => {
    setFromDateVal(fromDate);
    setToDateVal(toDate);
    setIsFormEmpty(
      disableSubmitButtonUntilSetupDates
        ? !(Boolean(fromDate) && Boolean(toDate))
        : false,
    );
  }, []);

  const hideModal = useCallback(() => {
    toggle();
  }, []);

  const trackEvent = useTrackEvent();

  const onClick = useCallback(() => {
    if (user) {
      trackEvent(
        CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_DOWNLOAD_CSV_MODAL_EXPORT_BUTTON_CLICKED,
      );
    }
    submit({ from: fromDateVal, to: toDateVal, post, filters });
    if (type === ModalType.DriverRecordReports) hideModal();
  }, [user, fromDateVal, toDateVal, post, filters, trackEvent]);

  return (
    <M.ComposedModal onClose={toggle} open={open} className='csv-export-modal'>
      <M.ModalHeader closeModal={toggle}>
        <h2>{modalHeader}</h2>
      </M.ModalHeader>
      <ModalBody className={downloadCsvDisabled ? 'downloadCsvDisabled' : ''}>
        <>
          {screen === Screens.INITIAL && !downloadCsvDisabled && (
            <>
              {body}
              <DateContainer>
                <ExportDateFilter
                  type={type}
                  handleFormFilling={handleFormFilling}
                  defaultDateDiffStartFromDays={defaultDateDiffStartFromDays}
                  dateRangeHeadline={dateRangeHeadline}
                  dateRangeLimit={dateRangeLimit}
                  disableDatesBefore={disableDatesBefore}
                />
              </DateContainer>
            </>
          )}
          {screen === Screens.INITIAL && downloadCsvDisabled && (
            <SuccessContainer>
              <span className='success-message'>
                Note: Invitation status of Sent or Expired will not include any
                completed Reports.
              </span>
            </SuccessContainer>
          )}
          {screen === Screens.SUCCESS && (
            <SuccessContainer>
              <M.Icon icon='Email'>Envelope icon</M.Icon>
              <span className='success-message'>
                Thanks, we&apos;re processing your export request.
              </span>
              <div>
                {' '}
                We&apos;ll email you the export when it is ready at{' '}
                <span className='email'>{email}</span>. In certain cases it may
                take longer, depending on the volume of activity.
              </div>
            </SuccessContainer>
          )}
        </>
      </ModalBody>
      <M.ModalFooter>
        {screen === Screens.INITIAL ? (
          <M.BinaryButtons
            size='sm'
            btnLeft={{
              name: 'Cancel',
              onClick: toggle,
            }}
            btnRight={{
              name: submitButtonName,
              onClick,
              disabled: processing || downloadCsvDisabled || isFormEmpty,
            }}
          />
        ) : (
          <M.Button onClick={toggle}>Close</M.Button>
        )}
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default CsvModal;
