import { includes, reduce } from 'lodash';
import { Package } from 'types';

const US_COUNTRY_TERRITORIES = ['AS', 'GU', 'MP', 'PR', 'VI', 'UM', 'US'];

export interface FilteredPackages {
  domestic: Package[];
  international: Package[];
}

export const filterPackages = (packages: Package[]): FilteredPackages =>
  reduce(
    packages,
    (acc: FilteredPackages, p: Package) => {
      p.international_only ? acc.international.push(p) : acc.domestic.push(p);
      return acc;
    },
    {
      domestic: [],
      international: [],
    },
  );

export const isInternationalCountry = (country?: string | null) =>
  Boolean(country && !includes(US_COUNTRY_TERRITORIES, country));
