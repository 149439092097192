import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Screenings } from 'components/AddScreenings/types/Screenings';
import * as utils from 'components/AddScreenings/shared/utils';

type ScreeningLabelProps = {
  isAddChecks: boolean;
};

const ScreeningList = styled.ul`
  list-style-position: inside;

  li::marker {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
    font-size: 16px;
  }
`;

const ScreeningListItem = styled.li`
  font-size: 14px !important;
`;

const ScreeningLabel = styled.span<ScreeningLabelProps>`
  color: ${colors.uiGrey900};
  font-size: ${({ isAddChecks }) => (!isAddChecks ? '16' : '14')}px !important;

  @media (max-device-width: 530px) {
    font-size: 12px !important;
  }
`;

type ScreeningsContentProps = {
  screenings: Screenings;
  additionalProperties: object;
  isAddChecks?: boolean;
};

const ScreeningsContent: React.FC<ScreeningsContentProps> = ({
  screenings,
  additionalProperties,
  isAddChecks = false,
}) => {
  const filteredScreenings = screenings.filter(screening => {
    return !screening?.type?.includes('international');
  });
  const isDomestic = filteredScreenings.length >= 1;

  return (
    <ScreeningList data-testid='review-page-screening-list'>
      {screenings.map(screening => {
        return (
          screening.type !== 'international_adverse_media_search' && (
            <ScreeningListItem
              className='review-page-screening-list-item'
              key={screening.type}
            >
              <ScreeningLabel
                isAddChecks={isAddChecks}
                className='review-page-screening-label'
              >
                {utils.getScreeningSummaryLabel(
                  screening.type === 'international_criminal_search_v2' &&
                    !isDomestic
                    ? 'international_criminal_or_adverse_media'
                    : screening.type,
                  additionalProperties,
                )}
              </ScreeningLabel>
            </ScreeningListItem>
          )
        );
      })}
    </ScreeningList>
  );
};

export default ScreeningsContent;
