import React, { FC, useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { useSetExpiration } from 'api/healthScreening';
import styled from 'styled-components';

type GeneralProps = {
  appointmentId: string;
  expiresAt: string;
  isOpen: boolean;
  onClose: () => void;
};

const StyledDatePickerWrapper = styled.div`
  padding: 10px 0;
`;

const StyledP = styled.p`
  margin-bottom: 2px !important;
`;

const StyledError = styled.p`
  color: ${colors.uiOrange600};
`;

const HealthScreeningAddMoreTimeModal: FC<GeneralProps> = ({
  appointmentId,
  expiresAt,
  isOpen,
  onClose,
}) => {
  const [date, setDate] = useState(expiresAt);

  const { call: setExpiration, result } = useSetExpiration({
    id: appointmentId,
    donor_pass_expires_at: date,
  });

  const errorMsg = (result?.error as any)?.response?.data?.error;

  useEffect(() => {
    result.isSuccess && onClose();
  }, [result, onClose]);

  const onDateChange = useCallback(value => setDate(value[0]), [setDate]);

  const submitExpiration = useCallback(() => {
    setExpiration({
      id: appointmentId,
      donor_pass_expires_at: date,
    });
  }, [appointmentId, date, setExpiration]);

  return (
    <M.ComposedModal open={isOpen} onClose={onClose} size='sm'>
      <M.ModalHeader closeModal={onClose}>
        <h3>Change pass expiration</h3>
      </M.ModalHeader>
      <M.ModalBody>
        <StyledP>
          Choose the last day that the candidate may complete their screening.
        </StyledP>
        <StyledP>
          An updated screening pass with be emailed with the new expiration
          date.
        </StyledP>
        <StyledDatePickerWrapper>
          <M.DatePicker
            id='bulk-send-created-after'
            dateFormat='Y-m-d'
            onChange={onDateChange}
            datePickerType='single'
            value={date}
            minDate={moment().format('YYYY-MM-DD')}
          >
            <M.DatePickerInput
              labelText='New expiration date'
              placeholder='YYYY-MM-DD'
            />
          </M.DatePicker>
        </StyledDatePickerWrapper>
        {result.isError ? <StyledError>{errorMsg}</StyledError> : null}
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='secondary' onClick={onClose}>
          Cancel
        </M.Button>
        <M.Button kind='primary' onClick={submitExpiration} disabled={!date}>
          Save
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default HealthScreeningAddMoreTimeModal;
