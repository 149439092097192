import { assign, reduce } from 'state/utilities/reducers';
import * as Actions from './actions';
import * as State from './state';
import * as Options from './options';

export const meta = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Load.TYPE: {
      const {
        payload: { options: _options, ...data },
      } = action as Actions.Load.Action;

      return assign.apply(state, { dirty: false, ...data });
    }
    default: {
      break;
    }
  }
  return state;
};

const updateOptions = (state: State.Type, action: Actions.Any): State.Type => {
  const options = Options.reducers.default(state.options, action);
  if (options === state.options) {
    return state;
  }

  let dirty = true;

  switch (action.type) {
    case Options.Actions.Load.TYPE: {
      dirty = false;
      break;
    }
    default: {
      break;
    }
  }

  return assign.apply(state, { dirty, options });
};

export const options = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Load.TYPE: {
      const { payload: data } = action as Actions.Load.Action;
      if (!data) {
        return state;
      }
      const load = Options.Actions.Load.create(data.options);
      return updateOptions(state, load);
    }
    case Actions.Update.TYPE: {
      const {
        payload: { dirty },
      } = action as Actions.Update.Action;
      return assign.apply(state, { dirty });
    }
    default: {
      break;
    }
  }
  return updateOptions(state, action);
};

export const reducer = reduce(null, meta, options);

export default reducer;
