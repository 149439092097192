import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
// import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/package';

type Props = {
  selections: Entity.List;
};

const StyledUl = styled(M.UnorderedList)`
  margin-left: 3.5rem;
  li {
    margin-left: 0.9rem;
  }
  span {
    font-style: italic;
  }
`;

const SelectedPackages: React.FC<Props> = ({ selections }) => {
  const { t } = useTranslation('assess');
  const noneSelectedText = t('settings.no_packages_selected');

  const selectedList = selections.map(item => {
    return <M.ListItem key={item.id}> {item.name}</M.ListItem>;
  });

  const selectedPackages = isEmpty(selectedList) ? (
    <span>{noneSelectedText}</span>
  ) : (
    selectedList
  );

  return (
    <StyledUl data-testid='assess-v2-ui-home-settings-boolean-settings-auto-aa'>
      {selectedPackages}
    </StyledUl>
  );
};

export default SelectedPackages;
