import StyledComponent from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const ItemWrapper = StyledComponent.div<{ invalid?: boolean }>`
  padding: 0px 0px 16px 0px;
  gap: 16px;
  border: 0px 0px 1px 0px;
  border-bottom: 1px solid ${colors.uiGrey200};
  height: ${props => (props.invalid ? '76px' : '52px')};
  display: flex;
  flex-direction: row;
`;

export const DescriptionContainer = StyledComponent.div<{ invalid?: boolean }>`
  width: 50%;
  height: ${props => (props.invalid ? '60px' : '36px')};
  gap: 0px;
  position: relative;
`;

export const ValidationFailureTypography = StyledComponent.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: ${colors.brandOrangeAccent2};
`;

export const ColoredIcon = StyledComponent(M.Icon).attrs({
  fill: colors.brandOrangeAccent2,
})`
  height: 20px;
  width: 20px;
`;

export const ButtonContainer = StyledComponent.div`
  width: 50%;
  padding: 0px 24px 0px 16px;
  display: flex;
  flex-direction: row-reverse;
`;

export const Button = StyledComponent(M.Button).attrs({
  kind: 'tertiary',
})`
  min-height: 36px;
  height: 36px;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
  opacity: 0px;
`;

export const Description = StyledComponent.span`
  height: 18px;
  top: 9px;
  left: 24px;
  gap: 0px;
  opacity: 0px;
  position: absolute;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;  
  color: ${colors.uiTextPrimaryLight};
`;

export const ValidationFailure = StyledComponent.div`
  height: 20px;
  top: 40px;
  left: 24px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
`;
