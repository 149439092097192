import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Box = styled.div`
  border-radius: 5px;
  border: 1px solid ${colors.uiAqua300};
  padding: 2em;
  margin-top: 1em;
  color: ${colors.uiTextPrimaryLight} !important;
  b {
    font-weight: bold;
  }
`;

export default Box;
