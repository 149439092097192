/* eslint-disable no-unreachable */
import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { formatScreeningPreferences } from '@dashboard-experience/utils';

const ScreeningListItem = styled.li<ScreeningListItemProps>`
  &:before {
    content: '✓' !important;
    color: ${colors.brandAqua3};
  }
  display: flex;
  padding-bottom: ${({ condensed }) => (condensed ? '0' : '10')}px;
`;
const ScreeningUl = styled.ul`
  list-style: none !important;
  padding-left: 5px;
`;
const ScreeningType = styled.span`
  padding-left: 5px;
`;
const ScreeningSettings = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;
const ScreeningListContainer = styled.div`
  ul {
    list-style-type: square;
  }
  ul > li {
    color: ${colors.brandGreen4};
  }

  ul > li > span {
    color: ${colors.uiTextPrimaryLight};
  }
`;

export const getScreeningType = (screeningType: any) => {
  if (screeningType === 'international_criminal_search_v2') {
    screeningType = 'out-of-Country_criminal_history';
  }
  if (screeningType === 'international_criminal_or_adverse_media') {
    screeningType = 'international_criminal_(Or_adverse_media)';
  }
  if (screeningType === 'international_canada_bc_enhanced_criminal_search') {
    screeningType = 'canada_BC_enhanced_criminal_search';
  }
  if (screeningType === 'international_right_to_work') {
    screeningType = 'right_to_work_uk';
  }
  const splitScreeningType = screeningType.split('_');
  const result = splitScreeningType.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1),
  );

  return result.join(' ');
};

export const isInternationalMotorVehicleReport = (screeningType: any) => {
  return screeningType === 'international_motor_vehicle_report';
};

export const getIncludeDrivingHistorySetting = (screening: any) => {
  const setting = '';
  if (isInternationalMotorVehicleReport(screening.type)) {
    screening = screening?.preferences?.include_driving_history
      ? 'Include driving history'
      : '';
  }
  return setting;
};

export const getScreeningSetting = (screening: any, screeningSettings: any) => {
  let setting = '';
  let mvrTypeSetting = null;

  switch (screening.type) {
    case 'motor_vehicle_report':
      mvrTypeSetting = screeningSettings?.find(
        (setting: { name: string }) => setting.name === 'mvr_type',
      );
      setting =
        mvrTypeSetting?.enabled && mvrTypeSetting?.value === 'commercial'
          ? 'Commercial MVR'
          : '';
      break;
    case 'employment_verification':
      switch (true) {
        case screening?.preferences?.international_upgrade:
          setting = 'Out-of-country history';
          break;
        case screening?.preferences?.dot_compliance:
          setting = 'DOT Compliance';
          break;
        default:
          setting = '';
          break;
      }
      break;
    case 'education_verification':
      setting = screening?.preferences?.international_upgrade
        ? 'Out-of-country history'
        : '';
      break;
    case 'personal_reference_verification':
    case 'professional_reference_verification':
      setting = screening?.preferences?.required_quantity
        ? screening.preferences.required_quantity.toString()
        : '-1';
      break;
    default:
      setting = formatScreeningPreferences(screening)
        .replace(/[^a-z0-9\s,]/gi, ' ')
        .replace(/,/g, ', ');
  }

  return setting && `(${setting.trim()})`;
};

type ScreeningListItemProps = {
  condensed?: boolean;
};

type PackageScreeningsProps = {
  screenings: any;
  screeningSettings: any;
  condensed?: boolean;
};

const PackageScreenings: React.FC<PackageScreeningsProps> = ({
  screenings,
  screeningSettings,
  condensed = true,
}) => {
  return (
    <ScreeningListContainer>
      <ScreeningUl>
        {screenings.map((screening: any) => (
          <ScreeningListItem key={`${screening.type}`} condensed={condensed}>
            <ScreeningType>
              {getScreeningType(screening.type)}{' '}
              <ScreeningSettings>
                {`${getScreeningSetting(screening, screeningSettings)}`}
              </ScreeningSettings>
              {isInternationalMotorVehicleReport(screening.type) && (
                <ScreeningSettings>
                  {`${getIncludeDrivingHistorySetting(screening)}`}
                </ScreeningSettings>
              )}
            </ScreeningType>
          </ScreeningListItem>
        ))}
      </ScreeningUl>
    </ScreeningListContainer>
  );
};

export default PackageScreenings;
