import React from 'react';
import { CellProps } from './Cells/types';
import DefaultCell from './Cells/DefaultCell';
import { CELL_CONFIG } from './Config';

const Cell: React.FC<CellProps> = ({
  cellKey,
  candidate,
  report,
  currentUser,
}) => {
  const cellProps = {
    candidate,
    report,
    cellKey,
    currentUser,
  };

  const ColumnCell = CELL_CONFIG[cellKey]?.component || DefaultCell;

  return <ColumnCell {...cellProps} />;
};

export default Cell;
