import React, { useContext } from 'react';
import Context, { RouteParams } from './Context';

export type Type = React.FC<Props>;
export type Props = RouteParams;

const Breadcrumb: Type = props => {
  const { children, ...route } = props;
  const routes = useContext(Context);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={[...routes, route]}>{children}</Context.Provider>
  );
};

export default Breadcrumb;
