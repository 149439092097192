import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { SCREENING_NAME_MAP } from 'Constants';
import {
  EventName,
  TimelineCell,
  TimelineEventDetailsCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

/**
 * @name getEventName
 * @function
 * @memberOf Screening
 * @description Determines the human-friendly event name
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventName = (timelineEvent: TimelineEvent) => {
  return SCREENING_NAME_MAP[
    timelineEvent.klass as keyof typeof SCREENING_NAME_MAP
  ]
    ? SCREENING_NAME_MAP[timelineEvent.klass as keyof typeof SCREENING_NAME_MAP]
    : timelineEvent.klass;
};

/**
 * @name getEventAction
 * @function
 * @memberOf Screening
 * @description Determines the event action
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventAction = ({ event, to = {}, source }: TimelineEvent) => {
  const { internal_state, status } = to;
  let action = '';

  if (event === 'update' && internal_state === 'request_schedule') {
    action = 'scheduled';
  } else if (event === 'update' && status) {
    action = `status set to "${status}"`;
  } else if (
    (event === 'update' && internal_state === 'complete') ||
    (event === 'advance' && to === 'complete')
  ) {
    action = 'completed';
  } else if (event === 'update') {
    action = 'updated';
  } else if (event === 'skipped') {
    action = 'skipped';
  } else if (event === 'in_lieu_of_county') {
    action = 'in lieu of county';
  } else if (source === 'HistoryTimelineEvent') {
    if (event !== 'completed') {
      action += ': ';
    }
    action += event.split('_').join(' ');
  }

  return action;
};

/**
 * @name getEventNote
 * @function
 * @memberOf Screening
 * @description Renders the event note
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventNote = (timelineEvent: TimelineEvent) => {
  return timelineEvent.note || null;
};

/**
 * @name getEventExtraInfo
 * @function
 * @memberOf Screening
 * @description Renders the event extra info
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventExtraInfo = (timelineEvent: TimelineEvent) => {
  return timelineEvent.extra_info
    ? JSON.parse(timelineEvent.extra_info).join(', ')
    : null;
};

const Screening: React.FC<{
  timelineEvent: TimelineEvent;
}> = ({ timelineEvent }) => {
  const eventName = getEventName(timelineEvent);
  const eventAction = getEventAction(timelineEvent);
  const eventNote = getEventNote(timelineEvent);
  const eventExtraInfo = getEventExtraInfo(timelineEvent);

  return (
    <M.TableRow>
      <TimelineEventDetailsCell>
        <EventName>
          {eventName} {eventAction}
        </EventName>
        {eventNote && <div>{eventNote}</div>}
        {eventExtraInfo && <div>{eventExtraInfo}</div>}
      </TimelineEventDetailsCell>
      <TimelineCell />
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default Screening;
