import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Events } from 'modules/assess/v2/models/amplitude/home';
import { PremiumUpgrade } from 'modules/assess/v2/ui/common';
import { useStandard } from 'modules/assess/v2/ui/hooks';

const StyledPremiumUpgrade = styled(PremiumUpgrade)`
  margin-top: 0.7rem;
`;

export const Upgrade: React.FC<{}> = () => {
  const { t } = useTranslation('assess:v2');
  const isStandard = useStandard();

  if (!isStandard) {
    return null;
  }

  const more = t('common.upgrade.premium.more');
  const style = {
    color: colors.uiGrey800,
    display: 'block',
  };

  return (
    <M.Container.Row data-testid='assess-v2-upgrade' style={style}>
      <div id='assess-v2-ui-home-guidelines-records-actions-upgrade-content'>
        {more}
      </div>
      <StyledPremiumUpgrade eventProperty={Events.Pagename} />
    </M.Container.Row>
  );
};

export default Upgrade;
