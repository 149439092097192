import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type FilterProps = {
  count: number;
  text: string;
  color: string;
  onClick: () => void;
};

type ButtonProps = {
  color: string;
};

const Filter = styled.div`
  padding: 1rem 1rem 0 0;
`;

const Button = styled(M.Button)<ButtonProps>`
  background-color: transparent !important;
  color: ${colors.uiGrey400} !important;
  border-color: ${colors.uiGrey200} !important;
  border-left: 4px solid ${props => props.color} !important;
  display: block !important;
  min-width: 320px !important;
  height: 85px !important;
  margin: 0 !important;
`;

const CountText = styled.div`
  font-size: 28px;
  color: ${colors.bgPrimaryDark};
`;

export const FilterButton: React.FC<FilterProps> = ({
  count,
  text,
  color,
  onClick,
}) => {
  return (
    <Filter data-testid='case-management-tasks-page-saved-filter'>
      <Button color={color} onClick={onClick}>
        <CountText>{count}</CountText>
        <div>{text}</div>
      </Button>
    </Filter>
  );
};

export default FilterButton;
