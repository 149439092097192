import React, { FC } from 'react';
import { FormikErrors } from 'formik/dist/types';
import { map } from 'lodash/fp';
import { NewCohortFormFields } from './types';
import {
  ErrorLabelContainer,
  ErrorLabelWrapper,
  ErrorLabel,
  ErrorIcon,
  ErrorText,
} from './styles';
import { ERROR_FORM_FIELD_LABELS } from './Constants';

interface Props {
  errors: FormikErrors<NewCohortFormFields>;
  serverError?: string;
}

const Errors: FC<Props> = ({ errors, serverError }) => {
  return (
    <>
      {serverError && (
        <ErrorLabelContainer>
          <ErrorIcon icon='ErrorFilled' />
          <ErrorText>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: serverError }} />
          </ErrorText>
        </ErrorLabelContainer>
      )}
      {map(
        (key: string) => (
          <ErrorLabelWrapper key={key}>
            <ErrorLabel>{ERROR_FORM_FIELD_LABELS[key]}: </ErrorLabel>
            <span>{errors[key]}</span>
          </ErrorLabelWrapper>
        ),
        Object.keys(errors),
      )}
    </>
  );
};

export default Errors;
