/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import {
  CURRENT_USER_ACCOUNT_UPDATE,
  NOTIFICATIONS_SETTINGS_UPDATE,
} from './ActionTypes';

export const updateAccountState =
  ({ updatedAccount }) =>
  dispatch => {
    dispatch({
      payload: updatedAccount,
      type: CURRENT_USER_ACCOUNT_UPDATE,
    });
  };

export const updateNotificationSettings = settings => {
  return dispatch =>
    dispatch({
      settings,
      type: NOTIFICATIONS_SETTINGS_UPDATE,
    });
};
