import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view';
import OptionLabel from './OptionLabel';

type Props = {
  example?: { disabled?: boolean };
  onChange: (e: Value) => void;
  selected: boolean;
  value: Value;
};

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;

  .mastodon-radio-button {
    margin-right: 0;
  }
`;

const Option: React.FC<Props> = ({ example, onChange, selected, value }) => {
  const dataTestid = `assess-v2-settings-eligible-records-${value.toLowerCase()}`;
  const eligibleRecordsId = `assess-v2-ui-home-settings-eligible-records-${value.toLowerCase()}`;

  return (
    <Flex id={eligibleRecordsId} data-testid={dataTestid}>
      <M.RadioButton checked={selected} value={value} onChange={onChange} />
      <OptionLabel example={example} value={value} />
    </Flex>
  );
};

export default Option;
