import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const CandidateInfoContainer = styled(M.Container)`
  position: relative;
`;

export const CandidateInfoInPlaceContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const CandidateInfoEmailSuppressedContainer = styled.div`
  padding-left: 0.25rem;
`;

export const CandidateInfoEmailSuppressedButton = styled(M.Icon)`
  cursor: pointer;
`;

export const MinorTag = styled(M.Tag)`
  background-color: ${colors.uiYellow400} !important;
  color: ${colors.uiGrey0};
`;

export const CandidateInfoExpandable = styled(M.Expandable)`
  #mastodon & {
    .expandable-content {
      padding-left: 0;
    }
  }
`;

export const CandidateInfoRow = styled(M.Container.Row)`
  #mastodon & {
    &.mastodon-container-row {
      padding-left: 2rem;
    }
  }
`;

export const IconText = styled.span`
  display: inline-block;
  position: relative;
  .text {
    display: inline-block;
    padding-left: 1.25rem;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const IconTextLink = styled.a`
  display: inline-block;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  .text {
    display: inline-block;
    &:nth-child(2) {
      padding-left: 1.25rem;
    }
    &:nth-child(1) {
      padding-right: 1.25rem;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const DeleteCandidateButton = styled(M.Button)`
  margin-left: 1rem !important;
`;

export const IdentityContainer = styled.div`
  padding: 0.5rem 2rem 0rem 2rem;
`;

export const IdentityHeader = styled.div`
  display: flex;
`;

export const Tooltip = styled(M.TooltipDefinition)`
  button {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
`;

export const IdentityHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const IdentityHeaderRight = styled.div`
  margin-left: auto;
`;

export const VerifiedBadge = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.uiAqua100};
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  img {
    height: 24px;
    width: 24px;
    color: ${colors.uiAqua500};
    margin-right: 0.5rem;
  }
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const UnverifiedBadge = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.uiGrey100};
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  border-radius: 4px;
  .mastodon-icon {
    height: 24px;
    width: 24px;
    color: ${colors.uiGrey500};
    margin-right: 0.5rem;
  }
  span {
    color: ${colors.uiGrey500};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const IdentityVerificationOutcomeInfo = styled.div`
  padding: 0.5rem 1rem 0.5rem 0rem;
  .mastodon-icon {
    color: ${colors.uiYellow600};
    margin-right: 0.5rem;
    height: 20px;
    width: 20px;
  }
  span {
    color: #1a2026;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
`;

export const IdentityVerificationUpdatedAt = styled.div`
  color: #1a2026;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.65;
`;
