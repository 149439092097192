import React from 'react';
import { useTranslation } from 'react-i18next';

import Section from '../../shared/Section';

import { namespace } from '../../../locales';
import SetupAndExploreContent from './SetupAndExploreContent';

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  geoModalSubmitted: boolean;
};

const SetupAndExploreSection: React.FC<Props> = ({
  showModal,
  setShowModal,
  geoModalSubmitted,
}) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:setup_and_explore`;
  const header = t(`${basePath}.header`);
  const subheader = t(`${basePath}.sub_header`);

  return (
    <Section header={header} subheader={subheader}>
      <SetupAndExploreContent
        setShowModal={setShowModal}
        showModal={showModal}
        geoModalSubmitted={geoModalSubmitted}
      />
    </Section>
  );
};

export default SetupAndExploreSection;
