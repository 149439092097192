import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isDefault?: boolean;
};

export const RulesetLabel: React.FC<Props> = ({ isDefault }) => {
  const { t } = useTranslation(['assess', 'translation']);
  const label = t('nouns.default', { ns: 'translation' });
  const tooltip = t('rulesets.table.tooltips.default');

  if (!isDefault) {
    return null;
  }
  return (
    <M.TooltipDefinition definition={tooltip} highlighted={false} align='top'>
      <M.Tag>{label}</M.Tag>
    </M.TooltipDefinition>
  );
};

export default RulesetLabel;
