import { Client as client } from 'api';
import { AnyQueryKey, useQuery } from 'react-query';

const PATH = 'credit_report_reasons';

export const useCreditReportReasons = () => {
  const key: AnyQueryKey = ['credit_report_reasons'];

  const request = () => fetchCreditReportReasons();

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const fetchCreditReportReasons = (): Promise<{ [key: string]: any }> => {
  return client.get(`${PATH}`);
};
