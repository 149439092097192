import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const ActionsContainer = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: left;
  font-size: 14px;
  color: ${colors.brandNavy3};
  cursor: pointer;
  line-height: 18px;
  margin-left: 90px;
  margin-top: 0.5em;
`;

const StyledIcon = styled(M.Icon)`
  padding-left: 5px;
`;

export const ExpandableDescription: React.FC = ({ children }) => {
  const { t } = useTranslation('assess:v2');
  const [isOpen, setIsOpent] = useState(false);
  const onClick = useCallback(() => setIsOpent(!isOpen), [isOpen]);

  const text = isOpen
    ? t(`standard.guidelines.quickstart.added.description.hide`)
    : t(`standard.guidelines.quickstart.added.description.show`);
  const icon = isOpen ? 'ChevronUp' : 'ChevronDown';

  return (
    <div>
      <ActionsContainer
        onClick={onClick}
        data-testid='assess-v2-ui-common-components-expandable-description'
      >
        {text}
        <StyledIcon icon={icon} size='20' />
      </ActionsContainer>
      {isOpen && children}
    </div>
  );
};

export default ExpandableDescription;
