import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetScreeningAdditionalInformation } from 'api/reports';

import { AdditionalInfo, DateFields } from './StateCriminalComponents';

type PatchRequestProps = {
  reportId: string;
  screeningId: string;
};

const PatchRequest: React.FC<PatchRequestProps> = ({
  reportId,
  screeningId,
}) => {
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow(current => !current), []);

  // API call for additional screening information
  const { screeningAdditionalInformation, isSuccess } =
    useGetScreeningAdditionalInformation(reportId, screeningId);

  const items = [
    {
      itemKey: 'Control number: ',
      itemValue: (
        <M.CopyField
          className='control-number'
          text={screeningAdditionalInformation?.control_number}
        />
      ),
    },
    {
      itemKey: 'First name: ',
      itemValue: (
        <M.CopyField
          className='first-name'
          text={screeningAdditionalInformation?.first_name}
        />
      ),
    },
    {
      itemKey: 'Middle name: ',
      itemValue: (
        <M.CopyField
          className='middle-name'
          text={screeningAdditionalInformation?.middle_name}
        />
      ),
    },
    {
      itemKey: 'Last name: ',
      itemValue: (
        <M.CopyField
          className='last-name'
          text={screeningAdditionalInformation?.last_name}
        />
      ),
    },
    {
      itemKey: 'Date of request: ',
      itemValue: (
        <DateFields className='date-fields'>
          <M.CopyField
            className='month'
            text={screeningAdditionalInformation?.request_month}
          />
          <M.CopyField
            className='day'
            text={screeningAdditionalInformation?.request_day}
          />
          <M.CopyField
            className='year'
            text={screeningAdditionalInformation?.request_year}
          />
        </DateFields>
      ),
    },
  ];

  return (
    <AdditionalInfo
      inline='classic'
      hideActionButton
      kind='info'
      title='Additional Information.'
      hideCloseButton
    >
      {isSuccess && (
        <M.NotificationActionButton onClick={handleClick}>
          {show ? 'Hide' : 'Show'} PATCH request
        </M.NotificationActionButton>
      )}
      {show && (
        <div>
          <h3>Search Details</h3>
          <M.KeyValueList items={items} />
        </div>
      )}
    </AdditionalInfo>
  );
};

export default PatchRequest;
