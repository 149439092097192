import { AnyQueryKey, useQuery } from 'react-query';
import { Namespace } from 'modules/assess/api';
import { fetchPremadeRules } from 'modules/assess/api/rules/premade';
import { useNamespace } from '../../router/context';

const NAMESPACE = 'premade';

export const usePremadeRules = () => {
  const rulesNamespace = useNamespace() || Namespace.criminal;
  const key: AnyQueryKey = [NAMESPACE, 'premade', 'rules'];
  const request = () => fetchPremadeRules(rulesNamespace);

  const { isLoading, isError, data } = useQuery(key, request);

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
