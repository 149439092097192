import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { UserType } from 'modules/case-management/types';
import StyledUser from './StyledUser';

const User = ({ user, className }: { user: UserType; className?: string }) => {
  const { t } = useTranslation('case-management');
  const text = t('case-management:tasks.user_select.self');

  const currentUser = useUser();
  const { display_label, email, id } = user;
  const name = currentUser?.id === id ? text : display_label;

  return (
    <StyledUser data-testid='user' className={className}>
      <M.Avatar name={display_label} label={email || ''} />
      <span className='name' data-testid='user-name'>
        {name}
      </span>
    </StyledUser>
  );
};

export default User;
