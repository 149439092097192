import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { ItemVisibility } from 'modules/assess';

type Props = {
  disabled?: boolean;
  selectedOption?: ItemVisibility | null;
  onChange: (e: any) => void;
};

export const ItemVisibilityDropdown: React.FC<Props> = ({
  disabled,
  selectedOption,
  onChange,
}) => {
  const { t } = useTranslation('');

  const items = Object.values(ItemVisibility).map(i => ({
    id: i,
    label: t(`assess:item_visibility.${i}`),
  }));
  const selectedItem =
    items.find(item => item.id === selectedOption) || items[0];

  return (
    <M.Dropdown
      id='assess-rules-common-item-visibility-select'
      disabled={disabled}
      items={items}
      selectedItem={selectedItem}
      onChange={onChange}
    />
  );
};

export default ItemVisibilityDropdown;
