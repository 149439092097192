import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/package';
import { Dialog } from 'modules/assess/ui/settings/packages/dialog';
import Button from '../button';

type Props = {
  packages?: Entity.List;
  setPackages: (packages: Entity.List) => void;
  disabled?: boolean;
};

const StyledRow = styled(M.Container.Row)`
  color: ${colors.uiGrey800};
`;

const SelectPackages: React.FC<Props> = ({
  packages,
  setPackages,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!packages) return null;

  return (
    <StyledRow
      id='assess-v2-ui-home-settings-packages-content'
      data-testid='assess-v2-settings-packages'
      style={{ marginLeft: '4.25rem', paddingTop: '0.5rem' }}
    >
      <Button onClick={onClick} disabled={disabled} />
      <Dialog
        data-testid='assess-v2-settings-packages-dialog'
        field='candidate_stories_request_on_adverse_action'
        packages={packages}
        open={open}
        setPackages={setPackages}
        onClose={onClose}
      />
    </StyledRow>
  );
};

export default SelectPackages;
