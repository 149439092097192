import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DefaultLink from '../instance/default-link';

export type Props = {};

export const Description: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const tkey = 'assess:mvr.category-sets.collection.description';

  const components = {
    'clients-mailto-link': (
      <a href='mailto:clients@checkr.com?subject=Assess: MVR Category Customization'>
        clients
      </a>
    ),
    'default-categories-link': <DefaultLink>default categories</DefaultLink>,
  };

  return (
    <div data-testid='assess-mvr-category-sets-collection-description'>
      <p>
        <Trans i18nKey={`${tkey}`} t={t} components={components} />
      </p>
    </div>
  );
};

export default Description;
