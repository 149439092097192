import { TABLE_SORT_DIRECTION } from './enums';

export type Header = {
  key: string;
  header: string;
  id: string;
  sortCycle?: string;
};

export const headers: Header[] = [
  {
    id: 'id',
    sortCycle: 'id-states-from-ascending',
    key: 'id',
    header: 'Custom ID (required)',
  },
  {
    id: 'name',
    sortCycle: 'name-states-from-ascending',
    key: 'name',
    header: 'Name (required)',
  },
  {
    id: 'tier',
    sortCycle: 'tier-states-from-ascending',
    key: 'tier',
    header: 'Tier',
  },
  {
    id: 'parent_custom_id',
    sortCycle: 'parent-custom-id-states-from-ascending',
    key: 'parent_custom_id',
    header: 'Parent Custom ID',
  },
];

export const initialSortInfo = {
  columnId: 'id',
  direction: TABLE_SORT_DIRECTION.NONE,
};

export const templateNodes = [
  {
    custom_id: 'Node1',
    tier: 'root',
    name: 'Example Store',
    id: 'Node1',
  },
  {
    custom_id: 'Node1-1',
    parent_custom_id: 'Node1',
    name: 'Example Branch',
    id: 'Node1-1',
  },
  {
    custom_id: 'Node1-2',
    parent_custom_id: 'Node1',
    tier: 'Tier1',
    name: 'Exmaple Branch 2',
    id: 'Node1-2',
  },
  {
    custom_id: 'Node2',
    name: 'Test Store',
    id: 'Node2',
  },
];

export function newEmptyRow() {
  const emptyRow = {
    parent_custom_id: null,
    tier: null,
    name: '',
    id: '',
    cells: [
      { id: 'id', value: '' },
      { id: 'name', value: '' },
      { id: 'tier', value: '' },
      { id: 'parent_custom_id', value: '' },
    ],
  };
  return emptyRow;
}
