import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Assess } from 'components';
import { useBreadcrumbTitle } from 'modules/assess/v2/ui/hooks';
import Draft from './draft';

export type Params = {};

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { path: base, url } = match;
  const title = useBreadcrumbTitle();

  return (
    <Switch>
      <Route path={`${base}/draft`}>
        <Assess.Route.Breadcrumb location={url} title={title}>
          <Draft />
        </Assess.Route.Breadcrumb>
      </Route>
      <Redirect to={`${base}/draft`} />
    </Switch>
  );
};

export default Router;
