import React, { useCallback, useMemo } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFormik } from 'formik';

import { useCreateRandomSelectionPoll } from 'api/randomDrugTesting';
import styled from 'styled-components';
import { FooterButtonContainer, TestInformationContainer } from './styles';
import { RandomSelectionProps } from './types';
import {
  TEST_TYPES,
  INITIAL_VALUES as initialValues,
  generateValidationSchema,
  transformRandomSelectionFormValuesForApi,
} from './utils';

import TestInformationForm from './TestInformationForm';

const Title = styled.h6`
  font-size: 14px;
  color: ${colors.uiTextPrimaryLight};
`;

const Description = styled.p`
  font-size: 14px;
  color: ${colors.uiTextSecondaryLight} !important;
  margin-bottom: 1.8rem !important;
  padding-right: 0 !important;
`;

const RandomSelection: React.FC<RandomSelectionProps> = ({
  cohortId,
  poolSize,
  alcoholScreening,
  drugScreening,
  isOpen,
  onClose,
}) => {
  const { call: createRandomSelection } = useCreateRandomSelectionPoll();

  const onSubmit: any = async (values: any) => {
    const response = await createRandomSelection(
      transformRandomSelectionFormValuesForApi(cohortId, values, poolSize),
    );
    if (response.id) window.location.reload();
  };

  const validationSchema = generateValidationSchema(poolSize, {
    includeDrug: !!drugScreening,
    includeAlcohol: !!alcoholScreening,
  });

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );

  const isSubmitDisabled = useMemo(() => {
    const hasErrors = !!Object.keys(errors).length;
    const drugValid = drugScreening
      ? values.drugNumberType !== '' &&
        values.drugRandomSelectionSize !== null &&
        values.drugRandomSelectionSize >= 0
      : true;
    const alcoholValid = alcoholScreening
      ? values.alcoholNumberType !== '' &&
        values.alcoholRandomSelectionSize !== null &&
        values.alcoholRandomSelectionSize >= 0
      : true;

    // Disable if both sizes are 0 (when both screenings are enabled)
    const areBothSizesZero =
      drugScreening &&
      alcoholScreening &&
      values.drugRandomSelectionSize === 0 &&
      values.alcoholRandomSelectionSize === 0;

    // Disable if only drug screening and size is 0
    const isDrugSizeZero =
      drugScreening &&
      !alcoholScreening &&
      values.drugRandomSelectionSize === 0;

    // Disable if only alcohol screening and size is 0
    const isAlcoholSizeZero =
      alcoholScreening &&
      !drugScreening &&
      values.alcoholRandomSelectionSize === 0;

    return (
      hasErrors ||
      !drugValid ||
      !alcoholValid ||
      areBothSizesZero ||
      isDrugSizeZero ||
      isAlcoholSizeZero
    );
  }, [errors, values, drugScreening, alcoholScreening]);

  return (
    <M.ComposedModal open={isOpen} onClose={onClose}>
      <M.ModalHeader title='New random selection' buttonOnClick={onClose} />
      <M.ModalBody>
        <Title>How many people do you want to select for testing?</Title>
        <Description>
          Enter the amount as a fixed number or a percentage. For example, you
          can set the testing rate as 20 people from the pool or 20% of the
          pool. Values can be zero.
        </Description>
        <TestInformationContainer>
          {drugScreening && (
            <TestInformationForm
              screeningType={TEST_TYPES.DRUG}
              handleChange={handleChange}
              poolSize={poolSize}
              values={values}
              showError={showErrorMessage}
              handleBlur={handleBlur}
              screening={drugScreening}
            />
          )}
          {alcoholScreening && (
            <TestInformationForm
              screeningType={TEST_TYPES.ALCOHOL}
              handleChange={handleChange}
              poolSize={poolSize}
              values={values}
              showError={showErrorMessage}
              handleBlur={handleBlur}
              screening={alcoholScreening}
            />
          )}
        </TestInformationContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <FooterButtonContainer>
          <M.Button
            kind='secondary'
            onClick={onClose}
            data-testid='btn-close-random-selection'
          >
            Close
          </M.Button>

          <M.Button
            kind='primary'
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            data-testid='btn-submit-random-selection'
          >
            Submit
          </M.Button>
        </FooterButtonContainer>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default RandomSelection;
