import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { TableHeader } = M.DataTable;

const BaseHeader = styled(TableHeader)`
  border: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

const SubcategoryHeader = styled(BaseHeader)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 25% !important;
`;

const StyledHeader = styled(BaseHeader)`
  width: 12.5% !important;

  .cds--table-header-label {
    text-align: center;
    font-weight: 500;
  }
`;

const columns: Array<string> = [
  'subcategory',
  'felony.eligible',
  'felony.escalated',
  'misdemeanor.eligible',
  'misdemeanor.escalated',
  'less_than_misdemeanor.eligible',
  'less_than_misdemeanor.escalated',
];

const TableSubheader: React.FC = () => {
  const { t } = useTranslation('assess');

  const headers = columns.map(c => ({
    key: c,
    text: t(
      `assess:ruleset.version.publish.changes.lookback.table.subheaders.${c}`,
    ),
  }));

  const children = headers.map((s, i) => {
    if (i === 0) {
      return (
        <SubcategoryHeader key={s.key} colSpan={1}>
          {s.text}
        </SubcategoryHeader>
      );
    }

    return <StyledHeader key={s.key}>{s.text}</StyledHeader>;
  });

  return <>{children}</>;
};

export default TableSubheader;
