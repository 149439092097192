import React, { useCallback } from 'react';

import { M } from '@dashboard-experience/mastodon';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Actions, Entity } from 'modules/assess/ui/state/rules/custom';

import { useTranslation } from 'react-i18next';
import * as Common from '../../../common/rule';

import { toInternal } from '../../utils';
import ItemVisibility from './item-visibility';
import Header from '../../../common/header';

type Props = {
  rule: Entity.Type;
};

const Container: React.FC<Props> = ({ rule }) => {
  const account = useUserAccount();
  const namespace = useNamespace();

  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const r = toInternal(rule);
  const copy = useCallback(
    () =>
      dispatch(
        Actions.Create.create({
          ...rule,
          name: `${rule.name} (duplicate #${Math.floor(Math.random() * 1000)})`,
        }),
      ),
    [dispatch, rule],
  );

  const edit = useCallback(
    () =>
      dispatch(
        Actions.Update.create({
          ...rule,
          editing: true,
        }),
      ),
    [dispatch, rule],
  );

  const positionDown = useCallback(
    () =>
      dispatch(
        Actions.Position.create({
          id: rule.id,
          position: +1,
          relative: true,
        }),
      ),
    [dispatch, rule],
  );

  const positionUp = useCallback(
    () =>
      dispatch(
        Actions.Position.create({
          id: rule.id,
          position: -1,
          relative: true,
        }),
      ),
    [dispatch, rule],
  );

  const remove = useCallback(
    () => dispatch(Actions.Remove.create(rule)),
    [dispatch, rule],
  );

  if (!namespace) {
    return null;
  }

  const editable = Common.editable(account, namespace, r.conditions);

  return (
    <Common.Container>
      {!editable && (
        <M.InlineNotification
          kind='warning'
          title={t(`assess:rules.notifications.unsupported.title`)}
          subtitle={t(
            `assess:rules.notifications.unsupported.custom_rule.subtitle`,
          )}
        />
      )}
      <Header
        rule={r}
        actions={{ copy, edit, positionDown, positionUp, remove }}
        editable={editable}
      />
      <Common.Assessment rule={r} />
      <Common.Targets rule={r} />
      <Common.Conditions rule={r} />
      <ItemVisibility namespace={namespace} rule={r} />
    </Common.Container>
  );
};

export default Container;
