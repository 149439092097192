import React from 'react';

type Props = {
  stepIndex: number;
  totalSteps: number;
  title: string;
};

export const StepTitle: React.FC<Props> = ({
  stepIndex,
  totalSteps,
  title,
}) => {
  return (
    <h1>
      Step {stepIndex + 1} of {totalSteps}: {title}
    </h1>
  );
};

export default StepTitle;
