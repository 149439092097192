import { useQuery, AnyQueryKey } from 'react-query';
import { getStates, getCounties } from './actions';

export const useGetStates = (
  handleSuccess: (data: any) => void,
  handleError: (error: Error) => void,
  enabled: boolean = true,
) => {
  return useQuery('states', getStates, {
    onSuccess: handleSuccess,
    onError: handleError,
    enabled,
  });
};

// ex states: 'CA' or 'CA,TX'
export const useGetCounties = (
  states: string,
  handleSuccess: (data: any) => void,
  handleError: (error: Error) => void,
  enabled: boolean = true,
) => {
  const key: AnyQueryKey = ['counties', states];
  const request = () => getCounties(states);
  return useQuery(key, request, {
    onSuccess: handleSuccess,
    onError: handleError,
    enabled,
  });
};
