import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// TODO: Implement new mastodon container when ready (EUIP-3600)
const StyledContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -4px 0px 0px ${colors.uiPink600};
  border-radius: 4px;
  color: ${colors.uiTextPrimaryLight};
  display: flex;
  padding-bottom: 2rem;
  padding-right: 1.5rem;
  padding-top: 1em;
`;

const Content = styled.div`
  margin-left: 2rem;
`;

const StyledBody = styled.span`
  color: ${colors.uiGrey700};
  margin-top: 1.5rem;
`;

const StyledHeader = styled.h2`
  font-weight: bold;
`;

const StyledIcon = styled(M.StatusIcon)`
  margin-left: 1.5rem;
`;

type Props = {
  content: any;
};

const Container: React.FC<Props> = ({ content }) => {
  const { description, title } = content;
  return (
    <StyledContainer data-testid='fair-chance-sections-fair-chance-opportunity-warning'>
      <StyledIcon icon='escalated' />
      <Content>
        <StyledHeader>{title}</StyledHeader>
        <StyledBody>{description}</StyledBody>
      </Content>
    </StyledContainer>
  );
};

export default Container;
