const isEuResourceId = (resourceId: string) => {
  const HARD_CODED_EU_COUNTRIES_LIST = [
    250, // FR / France
    276, // DE / Germany
  ];

  let isEuResource = false;

  if (resourceId.length === 30) {
    // extract country code (numeric) from bytes 2-3 of the resource id
    const header = resourceId.substring(0, 6);
    const headerNumeric = parseInt(header, 16);
    // eslint-disable-next-line no-bitwise
    const countryCode = headerNumeric & 0xffff;

    isEuResource = HARD_CODED_EU_COUNTRIES_LIST.includes(countryCode);
  }

  return isEuResource;
};

export default isEuResourceId;
