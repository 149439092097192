import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { Trans, useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { ActionableNotification, DocLink } from './components';
import { useModalState } from '../../../context';
import { useGetMedium } from '../config-options/hooks';

const base_path = `${namespace}:report.actions.pre_adverse.modal.preview_email.warning_banners`;
const path_with_email = `${base_path}.with_adverse_action_notices`;

const inlineProps = {
  title: '',
  kind: 'warning',
  hideCloseButton: true,
};

const WarningBanners = () => {
  const currentUser = useUser();
  const { t } = useTranslation();
  const { daysToPostAdverseAction, individualizedAssessment } =
    useModalState('state');
  const medium = useGetMedium();
  const chargesNotice = t(`${path_with_email}.charges`);
  const emailNotice = t(`${path_with_email}.email`, {
    numberOfDays: daysToPostAdverseAction,
  });
  const assessmentNotice = t(`${path_with_email}.assessment`);
  const uploadFileNoticeText = t(`${path_with_email}.upload_file.text`);
  const uploadFileNoticeLink = t(`${path_with_email}.upload_file.link`);
  const missingAAEmail =
    medium === 'email' && !currentUser?.account?.adverse_action_email;
  const missingAAPostal =
    medium === 'postal' && !currentUser?.account?.adverse_action_postal_address;

  return (
    <>
      {missingAAEmail || missingAAPostal ? (
        <ActionableNotification
          {...inlineProps}
          hideActionButton
          inline='classic'
          title={<WithOutDeliveryInfo />}
        />
      ) : (
        <ActionableNotification
          hideActionButton
          inline='classic'
          {...inlineProps}
        >
          <M.UnorderedList>
            <M.ListItem>{chargesNotice}</M.ListItem>
            <M.ListItem>{emailNotice}</M.ListItem>
            {individualizedAssessment?.assessment && (
              <M.ListItem>{assessmentNotice}</M.ListItem>
            )}
            {individualizedAssessment?.uploadedFile && (
              <M.ListItem>
                {uploadFileNoticeText}
                <DocLink
                  text={uploadFileNoticeLink}
                  href={individualizedAssessment.uploadedFile.url}
                />
              </M.ListItem>
            )}
          </M.UnorderedList>
        </ActionableNotification>
      )}
    </>
  );
};

const WithOutDeliveryInfo = () => {
  const { t } = useTranslation();
  const medium = useGetMedium();
  const path_without_delivery_info = `${base_path}.${
    medium === 'postal'
      ? 'without_adverse_action_postal.provide_address'
      : 'without_adverse_action_email.provide_email'
  }`;

  return (
    <Trans t={t} i18nKey={`${path_without_delivery_info}`}>
      <span>
        Please provide the {medium} address you want to use for adverse actions
        in your
      </span>
      <M.Link style={{ marginLeft: '0.25rem' }} href='/account/settings'>
        account settings.
      </M.Link>
    </Trans>
  );
};

export default WarningBanners;
