import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import HeaderCircles from 'assets/HeaderCircles.svg';
import OrderBackgroundCheck from './OrderBackgroundCheck';

const Grid = styled(M.Grid)`
  background: ${colors.aqua03} url(${HeaderCircles}) no-repeat center right /
    auto 100%;
  margin: -1.5rem -1.5rem 0;

  .banners-visible & {
    margin-top: -2.4rem;
  }

  @media (min-width: 48rem) {
    min-width: calc(100% + 8rem);
    margin: -3rem -4rem 0;

    .banners-visible & {
      margin-top: -3rem;
    }
  }
`;

const Row = styled(M.GridRow)`
  #mastodon & {
    align-items: center;
    max-width: 75rem;
    margin: auto;
    padding: 1.5rem 0;

    @media (min-width: 42rem) {
      padding: 0;
    }
  }
`;

const Col = styled(M.GridCol)`
  padding: 0 1rem;

  @media (min-width: 42rem) {
    padding: 2rem;
  }
`;

const Greeting = styled.h1`
  color: ${colors.white} !important;
  margin-top: 0.5rem;
`;

const Today = styled.div`
  color: ${colors.uiTextSecondaryDark};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
`;

const Header: React.FC = () => {
  const { t } = useTranslation('dashboard_landing');
  const date = new Date();
  const formattedDate = date.toLocaleString('en-us', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
  const currentHour = date.getHours();
  const user = useUser();
  let greeting = '';

  if (currentHour >= 5 && currentHour < 12) {
    greeting = t('header.morning');
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = t('header.afternoon');
  } else {
    greeting = t('header.evening');
  }

  return (
    <Grid>
      <Row>
        <Col sm={4} md={4} lg={8}>
          <Today>{formattedDate}</Today>
          <Greeting>
            {greeting}
            {user?.full_name ? `, ${user.full_name.replace(/ .*/, '')}` : ''}
          </Greeting>
        </Col>
        <Col sm={4} md={4} lg={8}>
          <OrderBackgroundCheck />
        </Col>
      </Row>
    </Grid>
  );
};

export default Header;
