import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ID } from 'modules/id';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { useCreate, useUpdate } from './api';
import { useCCSV } from '../../context';

const Form = styled.form`
  height: 100%;
  flex: 1;
`;

const InputField = styled.input`
  border: none;
  height: 100%;
  width: 100%;
`;

type Props = {
  focusOut: () => void;
  type?: ItemType;
  categoryId?: ID;
  categoryName?: string;
};

const CategoryNameInput: React.FC<Props> = ({
  focusOut,
  type,
  categoryId,
  categoryName = '',
}) => {
  const [name, setName] = useState(categoryName);
  const ccsv = useCCSV();
  const create = useCreate(ccsv?.category_code_set?.id, 'draft');
  const update = useUpdate(ccsv?.category_code_set?.id, 'draft');

  const onChange = useCallback(
    ({ target: { value } }) => {
      setName(value);
    },
    [name],
  );

  const onSubmit = useCallback(() => {
    if (name) {
      if (categoryId && categoryName !== name) {
        update.call({
          id: categoryId,
          name,
        });
      } else if (type) {
        create.call({ type, name });
      }
    }
    focusOut();
  }, [name]);

  return (
    <Form onSubmit={onSubmit}>
      <InputField
        autoFocus
        data-testid='mvr-ccsv-category-name-input'
        onBlur={onSubmit}
        onChange={onChange}
        type='text'
        value={name}
      />
    </Form>
  );
};

export default CategoryNameInput;
