import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Option } from 'modules/assess/models/context-date';

export type Props = {
  option: Option;
};

const StyledContainer = styled.p`
  display: flex;
  margin-top: 2em;
`;

export const Content: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation('assess');
  const optionName = t(`assess:context_dates.options.${option}.name`);
  const warning = t('assess:context_dates.remove.warning', {
    option: optionName,
  });

  return <StyledContainer>{warning}</StyledContainer>;
};

export default Content;
