import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { FieldComponentProps } from '../types';

const CreatedAt: React.FC<FieldComponentProps> = ({ candidate }) => {
  const { created_at: createdAt } = candidate;
  const timezone = moment.tz.guess();
  const formatted = createdAt
    ? moment.tz(createdAt, timezone).format('lll z')
    : '-';
  return <>{formatted}</>;
};

export default CreatedAt;
