import React from 'react';
import styled from 'styled-components';
import { ContextDateOptionChange } from 'modules/assess/models/rulesets/version-diff';
import { useUserAccount } from 'modules/assess/ui/hooks';
import Table from './Table';
import Title from './Title';

type Props = {
  changes?: ContextDateOptionChange.List;
};

const StyledContainer = styled.div`
  padding-bottom: 1rem;
`;

export const Container: React.FC<Props> = ({ changes }) => {
  const account = useUserAccount();

  if (!account?.assess_context_date_override) {
    return null;
  }

  if (!changes?.length) {
    return null;
  }

  return (
    <StyledContainer data-testid='assess-ruleset-version-publish-dialog-cdo'>
      <Title />
      <Table changes={changes} />
    </StyledContainer>
  );
};

export default Container;
