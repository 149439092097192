import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'common-add-on-container',
})`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid ${colors.uiGrey200};
  background: ${colors.uiGrey0};

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 992px) {
    flex-direction: row;
  }
`;

export const ScreeningDescription = styled.p.attrs({
  className: 'common-add-on-screening-description',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
    margin-top: 0;
  }
`;

export const Price = styled.span.attrs({
  className: 'common-add-on-price p3',
})`
  color: ${colors.uiTextTertiaryLight};
  align-self: center;
`;

export const AddButton = styled(M.Button).attrs({
  className: 'common-add-on-add-button',
})`
  #mastodon & {
    border: 1px solid ${colors.uiNavy100} !important;
    background-color: ${colors.uiNavy100} !important;

    &:hover {
      border: 1px solid ${colors.uiHover} !important;
      background-color: ${colors.uiHover} !important;
    }
  }
`;

export const InnerContainer = styled.div.attrs({
  className: 'common-add-on-inner-container',
})`
  display: flex;
  gap: 16px;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 720px) {
    flex-direction: row;
  }
`;

export const ButtonRow = styled.div.attrs({
  className: 'common-add-on-button-row',
})`
  display: flex;
  gap: 16px;

  @media (max-width: 992px) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const ScreeningRow = styled.div.attrs({
  className: 'common-add-on-screening-row',
})`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RemoveButton = styled(M.Button).attrs({
  className: 'common-add-on-remove-button',
})`
  gap: 0.375rem;
  padding: 0.4rem 0.875rem 0.4rem 0.5rem !important;
`;

export const Check = styled(M.Icon).attrs({
  className: 'common-add-on-check-icon',
  size: '16',
})`
  color: ${colors.uiGrey0};
`;
