import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const VerticalSeparator = styled.div<{ height: string }>`
  border-left: 1px solid;
  color: ${colors.coolBlack20};
  height: ${props => props.height};
`;

export default VerticalSeparator;
