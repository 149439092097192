import React from 'react';
import { titleize } from '@dashboard-experience/utils';
import { SCREENING_NAME_MAP, SCREENING_SUBTYPE_NAME_MAP } from 'Constants';
import { PackagesTableListItem } from './PackagesStyledComponents';
import { Screening } from '../../../types';

type Props = {
  screening: Screening;
};

const ScreeningName: React.FC<Props> = ({ screening }) => {
  return (
    <PackagesTableListItem data-testid='screening-name'>
      {titleize(SCREENING_NAME_MAP[screening.type])}
      {screening.subtype && SCREENING_SUBTYPE_NAME_MAP[screening.subtype] && (
        <>&nbsp;{titleize(SCREENING_SUBTYPE_NAME_MAP[screening.subtype])}</>
      )}
    </PackagesTableListItem>
  );
};

export default ScreeningName;
