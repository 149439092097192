import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  expandableTestId: string;
  referenceVerifications: Array<any>;
  reportStatusType: string;
  screeningTestId: string;
  title: string;
};

const keyStyle = {
  width: '7rem',
};

const KVList = styled(M.KeyValueList)`
  margin-top: 0.5rem;
  .mastodon-key-value-line-item {
    &:nth-child(even) {
      div {
        padding-bottom: 1rem !important;
      }
    }
  }
`;

/**
 * @name getList
 * @function
 * @memberOf ReferenceVerification
 * @description
 * @returns {array}
 * @param {object} verificationItem - The object describing the verification
 */
const getList = (verificationItem: {
  email: string;
  phone: string;
  questions: [{ id: string; question: string; answer: string }];
}) => {
  const { email, phone, questions } = verificationItem;
  const questionStyle = {
    fontWeight: 'bold' as 'bold',
  };
  const list = [];
  if (email) {
    list.push({
      itemKey: 'Email',
      itemValue: email,
    });
  }
  if (phone) {
    list.push({
      itemKey: 'Phone',
      itemValue: phone,
    });
  }
  questions.forEach((kv, kvIdx) => {
    // question
    list.push({
      itemKey: `Question ${kvIdx + 1}`,
      itemValue: <span style={questionStyle}>{kv.question}</span>,
    });
    // answer
    list.push({
      itemKey: `Answer ${kvIdx + 1}`,
      itemValue: kv.answer,
    });
  });
  return list;
};

const ReferenceVerification: React.FC<Props> = ({
  expandableTestId,
  referenceVerifications,
  reportStatusType,
  screeningTestId,
  title,
}) => {
  return (
    <M.Screening
      title={title}
      screening={referenceVerifications}
      statusType={reportStatusType}
      id={screeningTestId}
      data-testid={screeningTestId}
      role='list'
    >
      {referenceVerifications &&
        referenceVerifications.map((item: any) => {
          if (item.cancellation_reason_description) {
            return (
              <div>
                Cancellation Reason: {item.cancellation_reason_description}
              </div>
            );
          }
          return item.questions && item.questions.length ? (
            <M.Expandable
              key={item.id}
              title={
                <M.Screening.Heading
                  headingLeft={{
                    name: `${item.name} (${item.relationship})`,
                    strong: true,
                  }}
                  headingRight={
                    <M.StatusBadge
                      status={item.status}
                      statusType={reportStatusType}
                    />
                  }
                />
              }
              data-testid={expandableTestId}
              role='listitem'
            >
              <KVList items={getList(item)} keyStyle={keyStyle} />
            </M.Expandable>
          ) : (
            <M.Screening.Row indentation={2} key={item.id} role='listitem'>
              <M.Screening.Heading
                headingLeft={{
                  name: `${item.name} (${item.relationship})`,
                  strong: true,
                }}
                headingRight={
                  <M.StatusBadge
                    status={item.status}
                    statusType={reportStatusType}
                  />
                }
              />
            </M.Screening.Row>
          );
        })}
    </M.Screening>
  );
};

export default ReferenceVerification;
