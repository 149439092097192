import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getTimelineEvents } from './Helpers';
import { TimelineContainer, TimelineHeader } from './PLVStyledComponents';

type Props = {
  licenseVerification: { [x: string]: any };
};

const Timeline: React.FC<Props> = ({ licenseVerification }) => {
  const timelineList = getTimelineEvents(licenseVerification);
  return (
    <TimelineContainer>
      <TimelineHeader>Timeline</TimelineHeader>
      <M.KeyValueList
        items={timelineList}
        data-testid='screenings-professional-license-verification-timeline-list'
      />
    </TimelineContainer>
  );
};

export default Timeline;
