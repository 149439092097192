import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  StatusTypes,
  getScreeningStatus,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { ReportProps, FacisRecord } from 'types';
import { SCREENING_TITLES } from 'utils';
import Record from './FacisRecord';

const Facis: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { facis_search } = report;
  const legacyStatus = getScreeningStatus(
    facis_search || {},
    StatusTypes.Legacy,
  );
  const { subtype } = facis_search || {};
  const facis_level = subtype === '1' ? '1M' : subtype;

  return facis_search ? (
    <M.Screening
      title={`FACIS Level ${facis_level}`}
      screening={facis_search}
      statusType={reportStatusType}
      id={SCREENING_TITLES.facis_search.id}
      data-testid='facis'
      role='list'
    >
      {legacyStatus === ReportStatuses.CONSIDER &&
        facis_search.records?.map((record: FacisRecord, index: number) => (
          <>
            <Record record={record} key={record.id} />
            {index < facis_search.records.length - 1 && (
              <M.Divider key={`divider-${record.id}`} />
            )}
          </>
        ))}
    </M.Screening>
  ) : null;
};

export default Facis;
