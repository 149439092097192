import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Link = styled.a`
  color: ${colors.brandNavy1} !important;
  cursor: pointer;
  font-size: 14px !important;
`;

const LearnMoreLink: React.FC<any> = ({ linkText = 'Learn More', ...rest }) => {
  return (
    <Link rel='noreferrer' target='_blank' {...rest}>
      {linkText}
    </Link>
  );
};

export default LearnMoreLink;
