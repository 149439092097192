import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useEta, useReport, useExceptions } from 'containers/Report';

import {
  hasCourtClosureException,
  genericTriggerText,
  processingTooltipText,
} from './utils';
import Unavailable from './Unavailable';
import Overdue from './Overdue';
import GenericTooltip from './GenericTooltip';

const tooltips = {
  unavailable: {
    triggerText: 'Why is the estimate unavailable?',
    text: 'Due to court closures, we cannot provide an accurate estimate for some in-person county searches.',
  },
  processing: {
    triggerText: genericTriggerText,
    text: (
      <GenericTooltip
        text={processingTooltipText}
        link='https://help.checkr.com/hc/en-us/articles/360025682094'
      />
    ),
  },
  in_progress: {
    triggerText: genericTriggerText,
    text: <GenericTooltip />,
  },
  overdue: {
    triggerText: 'Why is my report still pending?',
    text: (
      <GenericTooltip link='https://help.checkr.com/hc/en-us/articles/217111267-Why-is-the-background-check-taking-longer-than-anticipated-' />
    ),
  },
};

const PendingEta: React.FC<{}> = () => {
  const report = useReport();
  const { estimatedCompletionTime } = useEta();
  const reportExceptions = useExceptions();
  const exceptions = reportExceptions?.exceptions || [];

  return (
    <M.Eta
      data-testid='eta-container'
      createdAt={report.created_at}
      estimatedCompletionTime={estimatedCompletionTime}
      tooltips={tooltips}
    >
      {hasCourtClosureException(exceptions) && (
        <M.Eta.Unavailable>
          <Unavailable />
        </M.Eta.Unavailable>
      )}
      <M.Eta.Overdue>
        <Overdue />
      </M.Eta.Overdue>
    </M.Eta>
  );
};

export default PendingEta;
