import React, { useEffect } from 'react';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { useHistory } from 'react-router-dom';
import { PathName, usePath } from 'modules/assess/ui/router/mvr/paths';
import BaseDialog, {
  Action,
} from 'modules/assess/ui/common/base-dialog/container';
import { useDestroy } from '../api';
import Footer from './footer';
import Content from './content';

export type Props = {
  open: boolean;
  ccs: Entity;
  onClose: () => void;
};

export const Container: React.FC<Props> = ({ open, ccs, onClose }) => {
  const base = usePath(PathName.categories);
  const deleteCCS = useDestroy(ccs?.id);
  const history = useHistory();

  useEffect(() => {
    if (deleteCCS.result.status === 'success') {
      history.push(base);
    }
  }, [base, history, deleteCCS.result.status]);

  if (!open || !ccs) {
    return null;
  }

  const numRulesets = ccs?.active_rulesets_count || 0;
  const hasRulesets = numRulesets > 0;

  return (
    <BaseDialog
      action={Action.delete}
      content={<Content ccs={ccs} />}
      footer={
        <Footer
          disabled={hasRulesets}
          onCancel={onClose}
          onDelete={deleteCCS.call}
        />
      }
      open={open}
      onClose={onClose}
      name={ccs?.name}
      type='category_set'
    />
  );
};

export default Container;
