import React, { useCallback } from 'react';
import * as Entity from 'modules/assess/models/rules/custom';
import { Kind } from 'modules/assess/models/rules/common/kind';
import { Context, CreateButton } from 'modules/assess/ui/rules/common/rule';
import { Namespace } from 'modules/assess/api';
import { useUserAccount } from '../../hooks';
import { Container, TextSections } from '../common';
import RuleList from './RuleList';
import PremadeRules from '../common/premade-list';
import * as Rule from '../common/rule';
import RuleLibrary from '../common/rule-library';
import { Actions } from '../../state/rules/custom';
import { useDispatch } from '../../ruleset/version/store';

export type Props = {
  rules: Entity.List | Entity.Map;
  disabled?: boolean;
  namespace?: Namespace;
};

const View: React.FC<Props> = ({ rules, disabled, namespace }) => {
  const account = useUserAccount();
  const dispatch = useDispatch();

  const create = useCallback(
    (template?: Omit<Entity.Type, 'id'>) => {
      const position = (rules as Entity.List).length + 1;
      const rule = {
        ...Entity.Custom.initialize(),
        position,
        ...template,
      };
      const editable = Rule.editable(account, namespace, rule.conditions);
      if (!editable) {
        rule.editing = false;
      }
      dispatch(Actions.Create.create(rule));
    },
    [account, dispatch, namespace, rules],
  );

  if (!namespace) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ disabled }}>
      <Container data-testid='assess-rules-custom-view'>
        <TextSections namespace={namespace} tkey='rules.custom.description' />
        <PremadeRules namespace={namespace} kind={Kind.CUSTOM} />
        <RuleLibrary
          disabled={disabled}
          namespace={namespace}
          kind={Kind.CUSTOM}
          handleAddClick={create}
        />
        <CreateButton
          data-testid='assess-rules-custom-create-rule'
          create={create}
          disabled={disabled}
        />
        <RuleList rules={rules} />
      </Container>
    </Context.Provider>
  );
};

export default View;
