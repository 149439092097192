import React from 'react';
import AdverseActionInformation from '../../sections/AdverseActionInformation';
import Benefits from '../../sections/Benefits';
import Description from '../../sections/Intro';
import EngageMoreCandidates from '../../sections/EngageMoreCandidates';
import HowToEvaluateBackgroundChecks from '../../sections/HowToEvaluateBackgroundChecks';

const SMB: React.FC = () => {
  return (
    <div data-testid='fair-chance-smb-container'>
      <Description />
      <Benefits />
      <AdverseActionInformation />
      <HowToEvaluateBackgroundChecks />
      <EngageMoreCandidates />
    </div>
  );
};

export default SMB;
