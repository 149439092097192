import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { UserType } from 'modules/case-management/types';
import { useUpdateTask } from '../../hooks';
import Assignee from '../User/Assignee';
import UserSelect from '../UserSelect';

type PropTypes = {
  assignee: UserType | null;
  taskID: string;
};
const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const DropdownToggle = styled.div`
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    background-color: ${colors.bgTertiaryLight};
  }
`;

const Owner = ({ assignee, taskID }: PropTypes) => {
  const [assignedUser, setAssignedUser] = useState<any>(assignee);
  const [open, setOpen] = useState(false);

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const toggleList = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const successCallback = (res: any) => {
    setAssignedUser(res.assignee);
    if (open) {
      toggleList();
    }
  };
  const { call: updateTask } = useUpdateTask(successCallback);

  const reassignTask = useCallback(
    (user: UserType | null) => {
      updateTask({
        assigneeID: user?.id || null,
        taskID,
      });
    },
    [updateTask, taskID],
  );
  const onSelect = useCallback(reassignTask, [
    reassignTask,
    taskID,
    updateTask,
  ]);

  // TODO: Update after EADA-4150
  // User Avatar btn triggers nested btn warning
  return (
    <StyledWrapper data-testid='case-management-tasks-page-task-owner'>
      <DropdownToggle
        data-testid='case-management-tasks-page-selection-menu-toggle'
        onClick={toggleList}
      >
        <Assignee assignee={assignedUser} reassignTask={reassignTask} />
      </DropdownToggle>
      {open && <UserSelect closeMenu={closeMenu} onSelect={onSelect} />}
    </StyledWrapper>
  );
};

export default Owner;
