import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { usePath } from './api';

type Props = {
  action: 'create' | 'view';
  ruleset: Entity.Type;
};

export type Type = React.FC<Props>;

export const DraftLink: Type = ({ action, ruleset }) => {
  const path = usePath(ruleset.id, 'draft');
  const { t } = useTranslation('assess');
  const text = t(`assess:rulesets.instance.version_link.draft.${action}`);
  return <Link to={path}>{text}</Link>;
};

export default DraftLink;
