import React, { useCallback, useState } from 'react';
import { useUser } from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import {
  ORDER_BACKGROUND_CHECK_BULKORDER_NAMES,
  useTrackEvent,
} from 'utils/analytics';
import moment from 'moment/moment';
import { useGetAggregatedManualBulkOrderHistory } from '../../../api/billing';
import ManualBulkOrderHistoryTable from './ManualBulkOrderHistoryTable';
import ManualBulkOrderHistory from './ManualBulkOrderHistory';
import {
  Pagination,
  ResultsText,
  TableToolbar,
} from '../BulkOrderHistoryStyled';

const tableHeaders = [
  {
    key: 'order_status',
    header: 'orderStatus',
    name: 'orderStatus',
  },
  {
    key: 'ran_at',
    header: 'date',
    name: 'date',
  },
  {
    key: 'package_name',
    header: 'package',
    name: 'package',
  },
  {
    key: 'original_uri',
    header: 'file',
    name: 'file',
  },
  {
    key: 'invoked_by',
    header: 'uploader',
    name: 'uploader',
  },
  {
    key: 'errors_uri',
    header: 'errors',
    name: 'errors',
  },
];

const ManualBulkOrderHistoryContainer: React.FC = () => {
  const currentUser = useUser();
  const PAGE_SIZE = 10;
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<string>('');

  const trackEvent = useTrackEvent();

  const { data: responseData, isLoading } =
    useGetAggregatedManualBulkOrderHistory(currentUser.account.id);
  const indexedData =
    responseData
      ?.sort((a, b) => {
        const getDate = (date: string) =>
          moment(date, 'DD/MM/YYYY HH:mm').toDate().getTime();
        return getDate(b.ran_at) - getDate(a.ran_at);
      })
      .map((item, i) => ({
        ...item,
        id: i,
      })) ?? [];

  const filteredData: ManualBulkOrderHistory[] = indexedData.filter(item =>
    item.invoked_by.toLowerCase().includes(filter),
  );

  const pageCount = Math.ceil((filteredData?.length || 0) / PAGE_SIZE);

  const indexOfLastHistory = page * PAGE_SIZE + 10;
  const indexOfFirstHistory = indexOfLastHistory - PAGE_SIZE;

  const paginatedData: ManualBulkOrderHistory[] = filteredData.slice(
    indexOfFirstHistory,
    indexOfLastHistory,
  ) as ManualBulkOrderHistory[];

  const onPageClick = useCallback(
    pageIndex => {
      trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.HISTORY_NAVIGATION, {
        Pagination: `Page number ${pageIndex + 1}`,
      });

      setPage(pageIndex);
    },
    [trackEvent],
  );

  const onSearch = useCallback(
    event => {
      setPage(0);
      setFilter(event.target.value.toLowerCase());
    },
    [setFilter],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isLoading && indexedData?.length === 0) {
    return null;
  }

  return (
    <>
      <M.DataTable rows={paginatedData} headers={tableHeaders}>
        {({ rows, headers, getToolbarProps }: any) => (
          <>
            <TableToolbar {...getToolbarProps()}>
              <M.Search onChange={onSearch} placeholder='Search uploader' />
              <ResultsText>
                {filteredData?.length === 1 ? (
                  <>1 result</>
                ) : (
                  <>{filteredData?.length || 0} results</>
                )}
              </ResultsText>
            </TableToolbar>
            <ManualBulkOrderHistoryTable
              headers={headers}
              rows={rows}
              data={paginatedData}
            />
          </>
        )}
      </M.DataTable>

      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          selectedIndex={page}
          onPageClick={onPageClick}
        />
      )}
    </>
  );
};

export default ManualBulkOrderHistoryContainer;
