export enum Quickstart {
  CategoryExpanded = 'Checkr Dashboard Assess {0} Quickstart Guidelines - Category Expansion',
  GuidelinesPublished = 'Guidelines Published',
  NumberPublished = 'Number Published',
  Pagename = 'Quickstart Guidelines',
  Pageview = 'Checkr Dashboard Assess {0} Quickstart Guidelines Pageview',
  Published = 'Checkr Dashboard Assess {0} Quickstart Guidelines - Rules Published',
  RuleExpanded = 'Checkr Dashboard Assess {0} Quickstart Guidelines - Guideline Expansion',
  RuleSelected = 'Checkr Dashboard Assess {0} Quickstart Guidelines - Guideline Added',
  Save = 'Checkr Dashboard Assess {0} Quickstart Guidelines - Guideline Saved',
}

export default Quickstart;
