import { useCallback } from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';

export const assessmentToAlias = (
  assessment: null | string,
  aliases: GenericObject,
) => {
  if (!assessment) return '';
  for (let i = 0; i < aliases.length; i += 1) {
    const decisionAlias = aliases[i];
    if (decisionAlias.decision_tag === assessment) {
      return decisionAlias.value.toLowerCase();
    }
  }

  return assessment;
};

export const calculateDateDiff = (
  startDate: string | moment.Moment,
  endDate: string | moment.Moment,
) => {
  const start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');
  let years;
  let months;

  if (start.isValid() && end.isValid()) {
    years = end.diff(start, 'year');
    start.add(years, 'years');
    months = end.diff(start, 'months');
  }

  return { years, months };
};

export const useAgeAt = () => {
  const { t } = useTranslation();
  const call = useCallback(
    (value: null | string, defaultStr: string, dob?: null | string) => {
      if (!dob) return defaultStr;

      const momentDate = moment(value, 'YYYY-MM-DD');
      const dobMoment = moment(dob, 'YYYY-MM-DD');

      if (!dobMoment.isValid() || !momentDate.isValid()) {
        return defaultStr;
      }
      const duration = moment.duration(momentDate.diff(dobMoment));
      const years = Math.floor(duration.asYears());

      if (years <= 0) return defaultStr;
      if (years === 1) return t(`${namespace}:utilities.one_year_old`);
      return t(`${namespace}:utilities.years_old`, { years });
    },
    [t],
  );

  return { call };
};

export const useTimeSinceOffense = () => {
  const { t } = useTranslation();
  const call = useCallback(
    (date: string, defaultStr: string) => {
      if (!date) return defaultStr;

      const now = moment();
      const { years, months } = calculateDateDiff(date, now);

      if (years == null || years < 0) return defaultStr;
      if (years === 0 && months === 0) {
        return t(`${namespace}:utilities.less_than_one_month`);
      }
      if (years === 0) {
        return t(`${namespace}:utilities.number_of_months`, { months });
      }
      return t(`${namespace}:utilities.number_of_years_and_months`, {
        years,
        months,
      });
    },
    [t],
  );

  return { call };
};

export const dateToFromNow = (value: string, str = '') => {
  const now = new Date().getTime();
  const date = moment(value);

  if (!date.isValid() || date.unix() > now) {
    return '';
  }

  const fromNow = date.fromNow(true);
  return `${fromNow} ${str}`;
};
