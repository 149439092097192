/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Flag } from '@dashboard-experience/react-flagr';
import { fetchContinuousCheckDriverRecordReports } from 'actions';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import { CC_DOWNLOAD_BUTTON_KEY } from '../../../Constants';
import DriverRecordReportsHeader from './DriverRecordReportsHeader';
import DriverRecordReportsTableCell from './DriverRecordReportsTableCell';
import DriverRecordReportsSearch from './DriverRecordReportsSearch';
import DriverRecordReportsExportCsv from './DriverRecordReportsExportCsv';

// Remove when table styles are finalized in Mastodon
const TableHeader = styled(M.TableHeader)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

const tableHeaders = [
  { key: 'created_at', name: 'date' },
  { key: 'candidate_id', name: 'candidateId' },
  { key: 'candidate_name', name: 'name' },
  { key: 'driver_record_report', name: 'driverRecordReport' },
];

const SORTABLE_HEADERS = ['created_at'];

const SORT_STATE_NEXT_VALUE = {
  asc: 'desc',
  desc: 'asc',
};

export class DriverRecordReportsTable extends PureComponent {
  static propTypes = {
    account: PropTypes.object,
    currentUser: PropTypes.object,
    t: PropTypes.func.isRequired,
    reports: PropTypes.array,
    searchValues: PropTypes.object,
    fetchContinuousCheckDriverRecordReports: PropTypes.func,
    fetchContinuousCheckDriverRecordReportsRequest: PropTypes.object,
    params: PropTypes.object.isRequired,
    driverRecordReportsTotal: PropTypes.number.isRequired,
    isInitialDriverRecordReportsState: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    account: {},
    currentUser: {},
    reports: [],
    searchValues: {},
    params: {},
    fetchContinuousCheckDriverRecordReports: () => {},
    fetchContinuousCheckDriverRecordReportsRequest: {},
  };

  async componentDidMount() {
    const {
      accountExists,
      props: {
        fetchContinuousCheckDriverRecordReports,
        account,
        searchValues,
        params,
      },
    } = this;

    if (accountExists()) {
      const { id } = account;

      await fetchContinuousCheckDriverRecordReports(
        id,
        'dl414',
        searchValues,
        params,
      );
    }
  }

  onSortClick = column => {
    const {
      account,
      fetchContinuousCheckDriverRecordReports,
      searchValues,
      params,
    } = this.props;
    const { id } = account;

    const newParams = { ...params };
    if (params.order_by !== column) {
      delete newParams.sort;
    }
    const sortState = SORT_STATE_NEXT_VALUE[newParams.sort];
    newParams.sort = sortState;
    newParams.order_by = column;

    fetchContinuousCheckDriverRecordReports(
      id,
      'dl414',
      searchValues,
      newParams,
    );
  };

  accountExists = () => {
    const { account } = this.props;
    return Object.keys(account).length > 0;
  };

  getSortDirection = header => {
    if (!SORTABLE_HEADERS.includes(header)) {
      return 'NONE';
    }
    const { params } = this.props;

    if (header == params.order_by) {
      return params.sort.toUpperCase();
    }

    return 'NONE';
  };

  getTableContent = ({ rows, headers, getRowProps, getHeaderProps }) => {
    const { t, reports } = this.props;

    return (
      <M.TableContainer className='hackathon'>
        <M.Table>
          <M.TableHead className='border-bottom'>
            <M.TableRow>
              {headers.map(header => {
                const sorting = {
                  isSortable: SORTABLE_HEADERS.includes(header.key),
                  onClick: () => {
                    this.onSortClick(header.key);
                  },
                };

                return (
                  <TableHeader
                    key={header.name}
                    {...getHeaderProps({
                      header,
                      ...sorting,
                    })}
                    isSortHeader={SORTABLE_HEADERS.includes(header.key)}
                    sortDirection={this.getSortDirection(header.key)}
                  >
                    {t(`headings.${header.name}`)}
                  </TableHeader>
                );
              })}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((report, i) => {
              // Ensure rows and reports stay in sync
              const row = reports[i];
              if (!row) return null;
              return (
                <Fragment key={row.id}>
                  <M.TableRow key={row.id} {...getRowProps({ row: report })}>
                    {report.cells.map((cell, j) => {
                      const cellProps = {
                        header: headers[j],
                        row,
                        cell,
                      };
                      return (
                        <DriverRecordReportsTableCell
                          key={cell.id}
                          {...cellProps}
                        />
                      );
                    })}
                  </M.TableRow>
                </Fragment>
              );
            })}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    );
  };

  /**
   * @name handlePaginationClick
   * @function
   * @memberOf AssignedReportsQueueTable
   * @description Callback method for pagination interaction
   * @param {integer} pageIndex - The zero-index of the page clicked
   */
  handlePaginationClick = pageIndex => {
    // eslint-disable-next-line no-shadow
    const {
      account,
      fetchContinuousCheckDriverRecordReports,
      searchValues,
      params,
    } = this.props;
    const { id } = account;
    const newParams = { ...params };
    newParams.page = pageIndex + 1;
    fetchContinuousCheckDriverRecordReports(
      id,
      'dl414',
      searchValues,
      newParams,
    );
  };

  /**
   * @name getPageCount
   * @function
   * @memberOf AssignedReportsQueueTable
   * @description Calculate the page count for the purposes of rendering the Pagination component
   * @returns {integer}  - The page count
   */
  getPageCount = (itemCount, perPage) => Math.ceil(itemCount / perPage);

  /**
   * @name handleChangeValue
   * @function
   * @memberOf AssignedReportsQueueTable
   * @description Callback method to update search values
   */
  handleChangeValue = searchupdate => {
    const {
      account,
      fetchContinuousCheckDriverRecordReports,
      searchValues,
      params,
    } = this.props;
    const { id } = account;
    const source = { ...searchValues };
    const newSearchValues = Object.assign(source, searchupdate);
    const newParams = { ...params };
    newParams.page = 1;
    fetchContinuousCheckDriverRecordReports(
      id,
      'dl414',
      newSearchValues,
      newParams,
    );
  };

  render() {
    const {
      reports,
      driverRecordReportsTotal,
      params,
      isInitialDriverRecordReportsState,
    } = this.props;
    const pageIndex = params.page - 1;
    const pageCount = this.getPageCount(
      driverRecordReportsTotal,
      params.per_page,
    );
    const { handlePaginationClick, handleChangeValue } = this;

    if (isInitialDriverRecordReportsState) {
      return <LoadingScreen />;
    }

    return (
      <>
        <DriverRecordReportsHeader />
        <DriverRecordReportsSearch
          resultsCount={driverRecordReportsTotal}
          onChangeValue={handleChangeValue}
        />
        <Flag flagKey={CC_DOWNLOAD_BUTTON_KEY} variantKey='on'>
          <RightButtonsContainer>
            <DriverRecordReportsExportCsv />
          </RightButtonsContainer>
        </Flag>
        {reports.length > 0 && (
          <M.DataTable
            rows={reports}
            headers={tableHeaders}
            render={this.getTableContent}
            isSortable
          />
        )}
        {reports.length > 0 && (
          <M.Pagination
            pageCount={pageCount}
            onPageClick={handlePaginationClick}
            selectedIndex={pageIndex}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.currentUser,
  account: state.currentUser.currentUser.account,
  reports:
    state.continuousServices.driverRecordReportsReducer.driverRecordReports,
  params: state.continuousServices.driverRecordReportsReducer.params,
  searchValues:
    state.continuousServices.driverRecordReportsReducer.searchValues,
  driverRecordReportsTotal:
    state.continuousServices.driverRecordReportsReducer
      .driverRecordReportsTotal,
  fetchContinuousCheckDriverRecordReports:
    state.continuousServices.driverRecordReportsReducer.fetchRequest,
  isInitialDriverRecordReportsState:
    state.continuousServices.driverRecordReportsReducer
      .isInitialDriverRecordReportsState,
});

const mapDispatchToProps = dispatch => ({
  fetchContinuousCheckDriverRecordReports: (
    accountId,
    documentType,
    searchValues,
    params,
  ) =>
    dispatch(
      fetchContinuousCheckDriverRecordReports(
        accountId,
        documentType,
        searchValues,
        params,
      ),
    ),
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DriverRecordReportsTable),
  ),
);
