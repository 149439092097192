import { M } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { reloadReport, toastError } from 'actions';
import UserContext from 'context/CurrentUser';
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Report } from 'types';
import { API } from 'utils/APIClient';

const CandidateStoryStyled = styled(M.CandidateStory)`
  padding-left: 0rem !important;
`;

export function useCSReview() {
  const user = useContext(UserContext);
  const account = user?.account;
  return account.candidate_stories && account.candidate_stories_review;
}

const CandidateStories: React.FC<{ report: Report }> = ({ report }) => {
  const dispatch = useDispatch();
  const onReview = useCallback(
    storyId => {
      API.post(`/candidate_stories/${storyId}/review`)
        .then(() => dispatch(reloadReport()))
        .catch(error => {
          dispatch(toastError('Something went wrong'));
        });
    },
    [dispatch],
  );

  const displayReview = useCSReview();
  const user = useContext(UserContext);
  const { permissions } = user;
  const canReview = !!permissions?.perform_adjudication;

  if (
    !(
      hasPermission(user, 'read_reports') ||
      hasPermission(user, 'read_full_reports')
    )
  )
    return null;

  return (
    <CandidateStoryStyled
      isReport
      report={report}
      candidateStories={report.candidate_stories}
      onReview={displayReview ? onReview : null}
      canReview={canReview}
    />
  );
};

export default CandidateStories;
