import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

interface UpdatedDeletionDialogProps {
  name: string;
  hasCandidateConsent: boolean;
  handleConsentCheck: (event: any, { checked }: any) => void;
  handleClose: (event: { target: any }) => void;
  handleSubmit: (e: any) => void;
  isError: boolean;
}

const UpdatedDeletionDialog: React.FC<UpdatedDeletionDialogProps> = ({
  name,
  hasCandidateConsent,
  handleConsentCheck,
  handleClose,
  handleSubmit,
  isError,
}: UpdatedDeletionDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <M.ModalBody padding='0'>
        <h5>{t('data_deletion.updated_prompt.text.title', { name })}</h5>
        <p>
          {t('data_deletion.updated_prompt.text.first_paragraph', { name })}
        </p>
        <p>
          {t('data_deletion.updated_prompt.text.second_paragraph', { name })}
        </p>

        <M.Checkbox
          id='consentCheckbox'
          data-testid='consentCheckbox'
          labelText={t('data_deletion.updated_prompt.text.checkbox_text', {
            name,
          })}
          checked={hasCandidateConsent}
          onChange={handleConsentCheck}
        />
      </M.ModalBody>

      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{
            name: t('verbs.cancel'),
            onClick: handleClose,
          }}
          btnRight={{
            name: t('data_deletion.updated_prompt.button_confirm'),
            disabled: !hasCandidateConsent,
            onClick: handleSubmit,
            kind: 'danger',
          }}
        />

        {isError && (
          <div className='errorContainer'>
            <span>{t('data_deletion.error')}</span>
          </div>
        )}
      </M.ModalFooter>
    </>
  );
};

export default UpdatedDeletionDialog;
