/* eslint-disable react/destructuring-assignment */
import { AdjudicationSubtype } from 'modules/adjudication/api';
import {
  AdversableItem,
  IndividualizedAssessment,
  FileStackResponse,
  Templates,
  DaystoPostAA,
  Basis,
  MediaConfig,
} from 'modules/adjudication/data';
import { CandidatePostalAddress } from '../../../../../../../types';

export type InitialStatePayload = {
  templates: Templates;
  daysToPostAdverseAction: DaystoPostAA;
};

export type NavigationAction = {
  onClick?: () => void;
  name?: string;
  disabled?: boolean;
} | null;

export type Navigation = {
  next?: NavigationAction;
  back?: NavigationAction;
  close?: NavigationAction;
  skip?: NavigationAction;
};

export type Action =
  | { type: 'SET_SUBTYPE'; payload: AdjudicationSubtype }
  | { type: 'SET_ADVERSE_ITEM'; payload: AdversableItem }
  | { type: 'SET_INITIAL_ASSESSMENT'; payload: string }
  | { type: 'UPLOAD_INDIVIDUALIZED_ASSESSMENT'; payload: FileStackResponse }
  | { type: 'REMOVE_INDIVIDUALIZED_ASSESSMENT' }
  | { type: 'SET_DAYS_TO_POST_ADVERSE_ACTIONS'; payload: number }
  | { type: 'SET_TEMPLATES'; payload: { type: string; value: number } }
  | { type: 'SET_DEFAULT_TEMPLATES'; payload: Templates }
  | { type: 'SET_INITIAL_STATE'; payload: InitialStatePayload }
  | { type: 'SET_BASIS'; payload: string }
  | { type: 'SET_MEDIUM'; payload: MediaConfig }
  | { type: 'SET_NAVIGATION'; payload: Navigation }
  | {
      type: 'SET_CANDIDATE_ADDRESS';
      payload: CandidatePostalAddress & { saved?: boolean };
    };

export type Dispatch = (action: Action) => void;

export type State = {
  [key: string]: any;
  initialized: boolean;
  subtype: AdjudicationSubtype;
  adverseItems: Array<AdversableItem>;
  individualizedAssessment: IndividualizedAssessment | null;
  templates: Templates;
  daysToPostAdverseAction: DaystoPostAA;
  basis?: Basis;
  medium: MediaConfig;
  candidateAddress: CandidatePostalAddress | null;
  navigation?: Navigation;
};

export const initialState = {
  initialized: false,
  subtype: {
    deletable: false,
    description: '',
    id: '',
    kind: '',
    name: '',
    slug: '',
  } as AdjudicationSubtype,
  adverseItems: [] as Array<AdversableItem>,
  individualizedAssessment: null,
  templates: {
    pre: null,
    post: null,
  },
  daysToPostAdverseAction: null,
  basis: null,
  medium: {
    email: { priority: 1, required: true },
    postal: { priority: 0, required: false },
  },
  candidateAddress: {
    name: null,
    city: null,
    state: null,
    street: null,
    street2: null,
    zipcode: null,
    saved: false,
  },
  navigation: {
    next: null,
    back: null,
    skip: null,
    close: null,
  },
};

const removeAdverseItem = (
  adverseItems: AdversableItem[],
  item: AdversableItem,
) =>
  adverseItems.filter(
    (adverseItem: AdversableItem) =>
      adverseItem.adversible_id !== item.adversible_id,
  );

const Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SUBTYPE': {
      return { ...state, subtype: action.payload };
    }
    case 'SET_ADVERSE_ITEM': {
      const adverseItems = removeAdverseItem(
        state.adverseItems,
        action.payload,
      );
      if (adverseItems.length === state.adverseItems.length) {
        return { ...state, adverseItems: [...adverseItems, action.payload] };
      }
      return { ...state, adverseItems };
    }
    case 'SET_INITIAL_ASSESSMENT': {
      return {
        ...state,
        individualizedAssessment: {
          ...state.individualizedAssessment,
          assessment: action.payload,
        },
      };
    }
    case 'UPLOAD_INDIVIDUALIZED_ASSESSMENT': {
      const assessment = state.individualizedAssessment || {};

      return {
        ...state,
        individualizedAssessment: {
          ...assessment,
          uploadedFile: { url: action.payload.url, file: action.payload },
        },
      };
    }
    case 'REMOVE_INDIVIDUALIZED_ASSESSMENT': {
      return {
        ...state,
        individualizedAssessment: {
          uploadedFile: null,
          assessment: null,
          assessmentUrl: null,
        },
      };
    }
    case 'SET_DAYS_TO_POST_ADVERSE_ACTIONS': {
      return { ...state, daysToPostAdverseAction: action.payload };
    }
    case 'SET_TEMPLATES': {
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.payload.type]: action.payload.value,
        },
      };
    }
    case 'SET_DEFAULT_TEMPLATES': {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case 'SET_INITIAL_STATE': {
      return {
        ...state,
        initialized: true,
        templates: action.payload.templates,
        daysToPostAdverseAction: action.payload.daysToPostAdverseAction,
      };
    }
    case 'SET_BASIS': {
      return {
        ...state,
        basis: action.payload,
        templates: {
          pre: null,
          post: null,
        },
      };
    }
    case 'SET_MEDIUM': {
      return {
        ...state,
        medium: action.payload,
      };
    }
    case 'SET_CANDIDATE_ADDRESS': {
      return {
        ...state,
        candidateAddress: action.payload,
      };
    }
    case 'SET_NAVIGATION': {
      return {
        ...state,
        navigation: {
          ...initialState.navigation,
          ...action.payload,
        },
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export default Reducer;
