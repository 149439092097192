import { Client } from 'api';
import { GenericObject } from 'types';
import querystring from 'querystring';
import { PATH } from './constants';

export type PostHierarchyPayload = {
  nodes: any[];
};

export const postHierarchy = (
  payload: PostHierarchyPayload,
): Promise<GenericObject> => {
  return Client.post(`${PATH}`, payload);
};

export const postHierarchyCsv = (payload: {
  raw_csv_file_s3_path: string;
}): Promise<GenericObject> => {
  return Client.post(`${PATH}/documents`, payload);
};

export const getStatus = (): Promise<GenericObject> => {
  return Client.get(`${PATH}/status`);
};

export const getCsvValidations = (payload: {
  raw_csv_file_s3_path: string;
}): Promise<{ errors: string[]; line_count: number }> => {
  const queryParams = querystring.encode(payload);

  return Client.get(`${PATH}/csv_validations?${queryParams}`);
};
