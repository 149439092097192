import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import styles from './styles';
import LinkCard from '../../shared/LinkCard';
import Featured from './Featured';
import { namespace } from '../../../locales';

const ResourcesContent: React.FC = () => {
  const { t } = useTranslation();
  const basePath = `${namespace}:linkCards`;
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');

  const handleResourceCenter = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.RESOURCES_CLICKED, {
        'Home Report Metrics Click': 'Get help at any time',
      });
    }

    // @ts-ignore
    if (window.pendo) {
      // @ts-ignore
      if (window.pendo.isGuideShown()) window.pendo.hideGuides();
      // @ts-ignore
      window.pendo.showGuideById('iCLfiHjI01xZfeLbmwXIeuj6QDI');
    }
  }, [isSignup, trackEvent]);

  const handleTracking = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.RESOURCES_CLICKED, {
        'Home Report Metrics Click': 'Court closures and delays',
      });
    }
  }, [isSignup, trackEvent]);

  return (
    <styles.ContentsContainer data-testid='home-resources-contents-container'>
      <Featured data-testid='home-resources-featured' />
      <styles.LinkCardContainer>
        <LinkCard
          title={t(`${basePath}.linkCardGetHelp.title`)}
          description={t(`${basePath}.linkCardGetHelp.description`)}
          onClick={handleResourceCenter}
          tag='get-help'
        />
        <LinkCard
          title={t(`${basePath}.linkCardStatus.title`)}
          description={t(`${basePath}.linkCardStatus.description`)}
          externalLink='https://data.checkrstatus.com/'
          onClick={handleTracking}
          data-testid='home-resources-link-card-status'
          tag='status'
        />
      </styles.LinkCardContainer>
    </styles.ContentsContainer>
  );
};

export default ResourcesContent;
