export enum Disposition {
  alternative = 'alternative_adjudication_classification',
  arrest = 'arrest_classification',
  conviction = 'conviction_classification',
  dismissed = 'dismissed_classification',
  expunged = 'expunged_classification',
  invalid = 'invalid_classification',
  merged = 'merged_classification',
  pending = 'pending_classification',
  transferred = 'transferred_classification',
  warrant = 'warrant_classification',
}

export default Disposition;
