import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { newEmptyRow } from './table-data';

type Props = {
  sortedRows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddButton: React.FC<Props> = ({
  sortedRows,
  setRows,
  setModified,
}) => {
  const hasEmptyRow = sortedRows.some(
    (row: any) => !row.id && !row.name && !row.tier && !row.parent_custom_id,
  );

  const addRow = useCallback(() => {
    const newRows: any = [...sortedRows, newEmptyRow()];
    setRows(newRows);
    setModified(true);
  }, [sortedRows, setRows, setModified]);

  return (
    <M.Button
      style={{ marginTop: '10px' }}
      data-testid='account-hierarchy-add-row-button'
      onClick={addRow}
      disabled={hasEmptyRow}
    >
      <M.Icon icon='Add' /> Add Row
    </M.Button>
  );
};

export default AddButton;
