import { GenericObject } from '@dashboard-experience/utils';
import React from 'react';
import { FieldComponentProps } from '../types';

const CustomerRuleActivated: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  return (
    <div data-testid={testid} className='value-width'>
      {adverseItem?.assessment?.display?.rules.map((rule: GenericObject) => {
        return <div key={rule.name}>{rule.name}</div>;
      })}
    </div>
  );
};

export default CustomerRuleActivated;
