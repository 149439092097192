/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';

import {
  AddWebhookUrls,
  WebhooksHeader,
  WebhookSubscriptions,
  WebhooksTable,
} from 'components';
import { LoadingScreen } from 'components/Account';
import {
  useDeleteWebhook,
  useGetWebhooks,
  useAddWebhook,
  useUpdateWebhookSubscriptions,
} from 'api/webhooks';

export type Webhook = {
  account_id: string;
  application_id: null | string;
  application_instance_id?: string;
  created_at: string;
  deleted_at: null | string;
  id: string;
  include_object: boolean;
  live: boolean;
  object: string;
  region: string;
  uri: string;
  webhook_url: string;
};

export type DeleteWebhookParams = {
  id: string;
  live: boolean;
};

export type AddWebhookParams = {
  include_object: boolean;
  webhook_url: string;
  live: boolean;
};

export type UpdateWebhookSubscriptionsParams = {
  accountId: string;
  subscriptions: { [key: string]: boolean };
};

const WebhookContainer = () => {
  const { account = {} } = useUser();
  const { call: deleteWebhook, result: deleteResult } = useDeleteWebhook();
  const { call: addWebhook, result: addResult } = useAddWebhook();
  const { call: updateWebhookSubscriptions } = useUpdateWebhookSubscriptions();
  const {
    data: webhooks,
    isLoading: isApiLoading,
    isError,
    isFetching,
  } = useGetWebhooks(account?.id, deleteResult, addResult);

  const isLoading = Boolean(!account?.id);

  return (
    <>
      {isApiLoading && isLoading && <LoadingScreen />}
      {webhooks && !isError && !isApiLoading && (
        <M.Container type='transparent'>
          <WebhooksHeader />
          <AddWebhookUrls
            addWebhook={addWebhook}
            isSuccess={addResult.isSuccess}
          />
          <WebhooksTable
            webhooks={webhooks}
            deleteWebhook={deleteWebhook}
            account={account}
            isFetching={isFetching}
            processing={deleteResult.isLoading}
          />
          <WebhookSubscriptions
            account={account}
            updateWebhookSubscriptions={updateWebhookSubscriptions}
          />
        </M.Container>
      )}
    </>
  );
};

export default WebhookContainer;
