import { createContext, useContext } from 'react';
import { CategoryCodeSetVersionFull } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';

const context = createContext<CategoryCodeSetVersionFull | undefined>(
  undefined,
);

export const { Consumer, Provider } = context;

export const useCCSV = () => {
  return useContext(context);
};
