import { Client as client, AccountsClient as accounts } from 'api';
import * as Account from 'modules/core-types/account';
import { CollectionResults } from 'modules/core-types/api-core';
import { GenericObject } from '@dashboard-experience/utils';
import {
  ADJUDICATION_SUBTYPES_PATH,
  CITIES_PATH,
  TAGS_PATH,
  TRACKED_MODALS_PATH,
} from './constants';

export const adjudicationSubtypes = (
  account: Account.Type,
): Promise<CollectionResults | undefined> => {
  if (!account.adjudication_subtypes_enabled) {
    return Promise.resolve(undefined);
  }
  return accounts.get(`${account.id}/${ADJUDICATION_SUBTYPES_PATH}`);
};

export const cities = (): Promise<{ [key: string]: any }> => {
  return client.get(`${CITIES_PATH}`);
};

export const reportMetrics = (
  accountId: string,
  lookBackDays?: number,
): Promise<{ [key: string]: any }> => {
  return accounts.get(`${accountId}/report_metrics`, {
    params: { look_back_days: lookBackDays },
  });
};

export const tags = ({
  accountId,
  name,
}: {
  accountId: string;
  name?: string;
}): Promise<{ [key: string]: any }> => {
  return accounts.get(`${accountId}/${TAGS_PATH}`, { params: { name } });
};

export const getTrackedModals = ({
  accountId,
}: {
  accountId: string;
}): Promise<GenericObject> => {
  return accounts.get(`${accountId}/${TRACKED_MODALS_PATH}`);
};

export const createTrackedModal = ({
  accountId,
  name,
}: {
  accountId: string;
  name: string;
}): Promise<GenericObject> => {
  return accounts.post(`${accountId}/${TRACKED_MODALS_PATH}`, { name });
};

export const updateDismissAddonEnablementBanner = ({
  accountId,
  dismissStatus,
}: {
  accountId: string;
  dismissStatus: boolean;
}): Promise<GenericObject> => {
  return accounts.put(`${accountId}`, {
    dismiss_addon_enablement_banner: dismissStatus,
  });
};

export const updateI9Upsell = ({
  accountId,
  dismissStatus,
}: {
  accountId: string;
  dismissStatus: boolean;
}): Promise<GenericObject> => {
  return accounts.put(`${accountId}`, {
    dismiss_i9_upsell: dismissStatus,
  });
};

export const requestManualOrders = ({
  accountId,
}: {
  accountId: string;
}): Promise<GenericObject> => {
  return accounts.post(`${accountId}/manual_orders_risk_events`, {
    manual_orders_risk_event_type: 'ManualOrdersReviewRiskEvent',
  });
};
