import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useSignupPageContext } from 'pages/Signup/context';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils';
import { Modal, Footer } from './ui/Modal';
import CountryToggleButton from './ui/CountryToggleButton';
import { DomesticPackages, InternationalPackages } from './ui/BasePackages';
import {
  Container,
  TitleContainer,
  PageSubtitleContainer,
  PageSubtitle,
  PriceDisclaimer,
  DisclaimerLink,
} from './styles';
import { BasePackage } from './types';
import { PrimaryButtonProps } from './ui/Modal/Footer';
import BasePackages from './constants/basePackages';
import { priceToString } from './utils/pricing';
import { updateSessionStorage } from './utils/sessionStorage';
import { isSelectedPackageInternational } from './utils/package';
import { unitedStateGeo } from './constants/geos';

const getPackagePrice = (selectedPackage: BasePackage) => {
  const { price } = BasePackages[selectedPackage];
  return priceToString(price);
};

const PackagesPage = () => {
  const history = useHistory();
  const location = useLocation();
  const trackEvent = useTrackEvent();
  const { selectedPackage, setSelectedPackage, setWorkLocation } =
    useSignupPageContext();
  const isInternational = isSelectedPackageInternational(selectedPackage);
  const [isExampleReportOpen, setIsExampleReportOpen] = useState(false);

  const { t } = useTranslation('signup');
  const goToNextPage = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SELECT_PACKAGE_STEP_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SELECT_PACKAGE_STEP_COMPLETED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    if (isInternational) {
      history.push({
        pathname: '/signup/candidate-info',
        search: location.search,
      });
    } else {
      history.push({ pathname: '/signup/add-ons', search: location.search });
    }
  }, [history, isInternational, location.search, trackEvent]);

  const continueButtonProps: PrimaryButtonProps = useMemo(() => {
    return {
      disabled: false,
      onClick: goToNextPage,
    };
  }, [goToNextPage]);

  const handleCountryToggle = useCallback(() => {
    if (isInternational) {
      setSelectedPackage('checkrdirect_essential_criminal');
      setWorkLocation(() => ({
        state: null,
        city: null,
        country: unitedStateGeo,
      }));
    } else {
      setSelectedPackage('checkrdirect_international_professional');
      setWorkLocation(() => ({
        state: null,
        city: null,
        country: null,
      }));
    }
  }, [isInternational, setSelectedPackage, setWorkLocation]);

  const footerProps = useMemo(
    () => ({
      packagePrice: `${getPackagePrice(selectedPackage)}`,
      subtotal: `${getPackagePrice(selectedPackage)}`,
      primaryBtnText: t(`footer.primary`),
      primaryButtonProps: continueButtonProps,
    }),
    [continueButtonProps, selectedPackage, t],
  );

  useEffect(() => {
    updateSessionStorage('selectedPackage', selectedPackage);
  }, [selectedPackage]);

  useEffect(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_SELECT_PACKAGE_VIEWED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  const handleLearnMoreLinkedClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_LEARN_MORE_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  useEffect(() => {
    const closeExampleReport = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isExampleReportOpen) {
        setIsExampleReportOpen(false);
      }
    };
    document.addEventListener('keydown', closeExampleReport, false);

    return () =>
      document.removeEventListener('keydown', closeExampleReport, false);
  }, [isExampleReportOpen]);

  return (
    <div data-dd-privacy='allow'>
      <Modal footer={<Footer {...footerProps} />} stepNumber={2}>
        <Container>
          <TitleContainer>
            <h3 className='mb-0' data-testid='packages-page-title'>
              {t('pages.packagesPage.title')}
            </h3>
            <PageSubtitleContainer>
              <PageSubtitle>{t('pages.packagesPage.subtitle')}</PageSubtitle>
              <CountryToggleButton
                isInternational={isInternational}
                onClick={handleCountryToggle}
              />
            </PageSubtitleContainer>
          </TitleContainer>
          {!isInternational ? (
            <DomesticPackages
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
            />
          ) : (
            <InternationalPackages
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
            />
          )}
          <PriceDisclaimer>
            {t('disclaimer')}
            <DisclaimerLink onClick={handleLearnMoreLinkedClick}>
              {' '}
              {t('disclaimerLink')}
            </DisclaimerLink>
            .
          </PriceDisclaimer>
        </Container>
      </Modal>
    </div>
  );
};

export default PackagesPage;
