import React from 'react';
import * as Entity from 'modules/assess/models/settings/account';
import SettingToggle from '../setting-toggle';

type Props = {
  locals: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
  versionClass: string;
};

const Container: React.FC<Props> = ({ locals, setSettings, versionClass }) => {
  return (
    <SettingToggle
      name='adverse_action_assessment_eligible'
      versionClass={versionClass}
      settings={locals}
      setSettings={setSettings}
    />
  );
};

export default Container;
