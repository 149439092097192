import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'customize-with-add-ons-container',
})`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 64px 0 64px;

  @media (max-width: 992px) {
    padding: 0 24px 0 24px;
  }

  @media (max-width: 420px) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div.attrs({
  className: 'customize-with-add-ons-title-container',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const PageSubtitle = styled.p.attrs({
  className: 'customize-with-add-ons-page-subtitle p2',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
  }
`;

export const MostCommonlyAdded = styled.div.attrs({
  className: 'customize-with-add-ons-most-commonly-added',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 66.67%;

  @media (max-width: 992px) {
    gap: 16px;
    width: 50%;
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const InnerContainer = styled.div.attrs({
  className: 'customize-with-add-ons-inner-container',
})`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const LearnMore = styled.a.attrs({
  className: 'customize-with-add-ons-learn-more',
})`
  color: ${colors.uiNavy600};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const Icon = styled(M.Icon).attrs({
  className: 'customize-with-add-ons-screening-icon',
})<{ fill?: string }>`
  fill: ${colors.uiGrey600};
  padding: 6px;
  border-radius: 4px;
  background: ${colors.uiGrey100};
  min-height: 36px;
  min-width: 36px;
  height: 36px;
  width: 36px;
`;
