import { ID } from 'modules/id';

export enum Kind {
  ACCOUNT = 'account_default',
  GEO = 'geo',
  PACKAGE = 'package',
  PACKAGE_AND_GEO = 'package_and_geo',
  PACKAGE_AND_SEGMENT = 'package_and_segment',
  PROGRAM = 'program',
  SEGMENT = 'segment',
  STATE = 'state',
}

export type Type = {
  id: ID;
  type: Kind;
  name: string;
};
