import React, { useCallback, useEffect, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { namespace } from 'containers/Account/locales';
import UIContext from 'context/UI';
import { updateParentWindowUrl } from 'utils';
import GetTabs, { getInitialTabIndex } from './utils';
import { TabName } from './types';
import PageHeaderSingle from '../../../components/PageHeaderSingle';

type Props = {
  tab?: TabName;
};

const TabsWrapper = styled.div`
  #mastodon & {
    .mastodon-tabs-container {
      box-shadow: none;
    }

    .mastodon-tab-panel {
      padding: 2rem 0;

      @media (min-width: 42rem) {
        padding: 2rem;
      }
    }
  }
`;

const Tab = styled(M.Tab)`
  #mastodon && {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

const Tabs: React.FC<Props> = ({ tab }) => {
  const { contextId, isIframe } = useContext(UIContext);
  const history = useHistory();
  const { t } = useTranslation();
  const tabs = GetTabs();
  const selected = getInitialTabIndex(tabs, tab);

  const setTab = useCallback(
    (index: number) => {
      if (selected === index) return;

      const tab = tabs[index];
      // TODO: test this when tabs are migrated to angular
      if (isIframe && contextId) {
        updateParentWindowUrl({
          contextId,
          path: tab.url,
          reload: false,
        });
      } else {
        history.push(tab.url);
      }
    },
    [history, tabs, isIframe, contextId, selected],
  );

  useEffect(() => {
    setTab(selected);
    // eslint-disable-next-line
  }, []);

  const handleSelectionChange = useCallback(
    (e: { selectedIndex: number }) => {
      setTab(e.selectedIndex);
    },
    [setTab],
  );

  const title = {
    label: t(`pageHeaderSingle.accountSettings.title`),
  };

  return (
    <>
      <PageHeaderSingle title={title} />
      <TabsWrapper data-testid='account-tabs'>
        <M.Tabs onChange={handleSelectionChange} selectedIndex={selected}>
          <M.TabList contained>
            {Object.keys(tabs).map(currentTab => {
              const tab = tabs[currentTab];
              const label = t(`${namespace}:tabs.${tab.slug}.name`);
              return <Tab key={label}>{label}</Tab>;
            })}
          </M.TabList>
          <M.TabPanels>
            {Object.keys(tabs).map((currentTab, i) => {
              const tab = tabs[currentTab];
              const Content = tab.content;
              return (
                <M.TabPanel key={tab.slug}>
                  {/* Only ever load the active tab, to avoid excessive API calls */}
                  {selected === i && <Content />}
                </M.TabPanel>
              );
            })}
          </M.TabPanels>
        </M.Tabs>
      </TabsWrapper>
    </>
  );
};

export default Tabs;
