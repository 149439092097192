import React, { createContext, useState, useContext, useMemo } from 'react';
import { Condition } from 'modules/assess/models/rules/condition';
import { cloneDeep } from 'lodash';
import { ConditionType } from './types';

export type ConditionsContextType = {
  conditions: Array<ConditionType>;
  deleteConditionByIndex: (index: number) => void;
  selectedItemTypes: () => Array<string>;
  insertCondition: (args: Partial<Condition>) => void;
  setConditions: any;
  updateConditionByIndex: (index: number, args: Partial<Condition>) => void;
};

const initDraftRuleContext = {
  conditions: [],
  deleteConditionByIndex: () => {},
  selectedItemTypes: () => [],
  setConditions: () => {},
  insertCondition: () => {},
  updateConditionByIndex: () => {},
};

export const ConditionsContext =
  createContext<ConditionsContextType>(initDraftRuleContext);

export const ConditionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [conditions, setConditions] = useState<Array<ConditionType>>([]);

  const updateConditionByIndex = (index: number, args: Partial<Condition>) => {
    setConditions((oldConditions: Array<ConditionType>) => {
      const newConditions = cloneDeep(oldConditions);
      Object.assign(newConditions[index], args);
      return newConditions;
    });
  };

  const deleteConditionByIndex = (index: number) => {
    setConditions((oldConditions: Array<ConditionType>) => {
      const newConditions = cloneDeep(oldConditions);
      newConditions.splice(index, 1);
      return newConditions;
    });
  };

  const insertCondition = (args: Partial<Condition>) => {
    setConditions((oldConditions: Array<ConditionType>) => {
      const newConditions = cloneDeep(oldConditions);
      return [...newConditions, args as ConditionType];
    });
  };

  const selectedItemTypes = () => {
    const value = conditions.find(
      (c: { fact: string }) => c.fact === 'object_type',
    )?.value;

    return Array.isArray(value) ? value : [];
  };

  const value = useMemo(
    () => ({
      conditions,
      deleteConditionByIndex,
      selectedItemTypes,
      setConditions,
      updateConditionByIndex,
      insertCondition,
    }),
    [conditions],
  );

  return (
    <ConditionsContext.Provider value={value}>
      {children}
    </ConditionsContext.Provider>
  );
};

export const useConditions = () => {
  return useContext(ConditionsContext);
};
