import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import UIContext from 'context/UI';
import Content from './content';
import { useRefetchReportOnClose } from './hooks';
import { useModalState } from '../context';
import ModalFooter from './footer';

const StyledModal = styled(M.ComposedModal)`
  #mastodon && {
    .cds--modal-container {
      max-width: 50rem;
    }
  }
`;

const Modal = () => {
  const { t } = useTranslation();
  const { isIframe } = useContext(UIContext);
  const { navigation } = useModalState('state');
  const header = t(`${namespace}:report.actions.pre_adverse.modal.header`);

  const open = useModalState('open');
  const handleClose = useRefetchReportOnClose();

  return (
    <StyledModal
      onClose={handleClose}
      open={open}
      isIframe={isIframe}
      className='actions-modal'
    >
      <M.ModalHeader closeModal={handleClose}>
        <h2>{header}</h2>
      </M.ModalHeader>
      <Content />
      <ModalFooter {...navigation} />
    </StyledModal>
  );
};

export default Modal;
