import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';

import EmptyTableBody from '../EmptyTableBody';
import FormHeading from '../FormHeading';
import WebhookEntry from './WebhookEntry';
import WebhookToolbar from './WebhookToolbar';
import ConfirmModal from '../../../ConfirmModal';

const tableHeaders = [
  { key: 'webhook_url', header: 'URL' },
  { key: 'include_object', header: 'Related Object' },
  { key: 'id', header: '' },
];

export default class WebhooksTable extends React.Component {
  static propTypes = {
    webhooks: PropTypes.object.isRequired,
    deleteWebhook: PropTypes.func.isRequired,
    processing: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalAction: () => {},
      modalTitle: 'Delete Webhook',
      modalMessage: 'Are you sure you want to delete this?',
    };
  }

  batchDelete = selectedItems => () => {
    const { webhooks } = this.props;
    const selectedWebhooks = selectedItems.map(item => webhooks[item.id]);
    this.setState({
      modalOpen: true,
      modalTitle: 'Delete webhooks',
      modalMessage: 'Are you sure you want to delete the selected webhooks?',
      modalAction: () => {
        const { deleteWebhook } = this.props;

        selectedWebhooks.forEach(({ id, live }) => deleteWebhook({ id, live }));
        this.setState({ modalOpen: false });
      },
    });
    scrollToTop();
  };

  confirmDelete = e => {
    e.preventDefault();
    const {
      currentTarget: { name },
    } = e;

    const { webhooks } = this.props;
    const webhook = webhooks[name];

    const { webhook_url, id, live } = webhook;

    this.setState({
      modalOpen: true,
      modalTitle: 'Delete webhook',
      modalMessage: `Are you sure you want to delete the webhook ${webhook_url}?`,
      modalAction: () => {
        const { deleteWebhook } = this.props;

        deleteWebhook({ id, live });
        this.setState({ modalOpen: false });
      },
    });
    scrollToTop();
  };

  hideModal = () => this.setState({ modalOpen: false });

  getTableContent = ({
    rows,
    headers,
    getHeaderProps,
    getRowProps,
    getSelectionProps,
    getToolbarProps,
    getBatchActionProps,
    onInputChange,
    selectedRows,
    getTableProps,
    getTableContainerProps,
  }) => {
    const {
      confirmDelete,
      batchDelete,
      props: { isFetching, processing },
    } = this;

    return (
      <M.TableContainer {...getTableContainerProps()}>
        <WebhookToolbar
          {...{
            getToolbarProps,
            getBatchActionProps,
            selectedRows,
            batchDelete,
            onInputChange,
          }}
        />
        <M.Table {...getTableProps()}>
          <M.TableHead className='border-top'>
            <M.TableRow>
              <M.TableSelectAll {...getSelectionProps()} />
              {headers.map(header => (
                <M.TableHeader
                  key={header.header}
                  {...getHeaderProps({ header })}
                >
                  {header.header}
                </M.TableHeader>
              ))}
            </M.TableRow>
          </M.TableHead>
          {rows.length === 0 ? (
            <EmptyTableBody
              isFetching={isFetching}
              colSpan={tableHeaders.length + 1}
            />
          ) : (
            <M.TableBody>
              {rows.map(row => (
                <WebhookEntry
                  key={row.id}
                  {...{
                    getRowProps,
                    getSelectionProps,
                    row,
                    processing,
                    confirmDelete,
                  }}
                />
              ))}
            </M.TableBody>
          )}
        </M.Table>
      </M.TableContainer>
    );
  };

  render() {
    const {
      getTableContent,
      hideModal,
      props: { webhooks },
      state: { modalOpen, modalAction, modalTitle, modalMessage },
    } = this;

    const webhookRows = Object.values(webhooks);
    return (
      <>
        <ConfirmModal
          open={modalOpen}
          hideModal={hideModal}
          callback={modalAction}
          header={modalTitle}
          message={modalMessage}
        />
        <M.Container.Row>
          <M.Container.Col>
            <FormHeading>Configured Webhooks</FormHeading>
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            <M.DataTable
              rows={webhookRows}
              headers={tableHeaders}
              key={webhookRows.map(webhook => webhook.id).join('-')}
              render={getTableContent}
            />
          </M.Container.Col>
        </M.Container.Row>
      </>
    );
  }
}
