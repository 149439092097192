import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { M, colors } from '@dashboard-experience/mastodon';
import { disabledRow, orderTooltipText } from './utils/patterns';

let fname: string;

const CandidateLink = styled.a`
  color: inherit !important;
  display: block;
`;

const DisabledText = styled.p`
  color: ${colors.uiGrey500} !important;
`;

type TextCellContentProps = {
  row: Record<string, any>;
  cell: Record<string, any>;
  disabled: boolean;
};

const TextCellContent: React.FC<TextCellContentProps> = ({
  row,
  cell,
  disabled,
}) => {
  if (cell.info.header === 'full_name') {
    fname = String(cell.value);
  }

  if (cell.info.header === 'updated_at') {
    if (cell.value !== 'N/A') {
      return <>{moment(cell.value).format('MMM D, YYYY')}</>;
    }
  }
  if (disabledRow(row.id)) {
    return cell.info.header === 'full_name' ? (
      <CandidateLink target='_blank' rel='noreferrer'>
        {cell.value}
      </CandidateLink>
    ) : (
      <p>{cell.value}</p>
    );
  }

  if (!disabled) {
    return cell.info.header === 'full_name' ? (
      <CandidateLink
        href={`/candidates/${row.id}`}
        target='_blank'
        rel='noreferrer'
      >
        {cell.value}
      </CandidateLink>
    ) : (
      <p>{cell.value}</p>
    );
  }

  return (
    <M.TooltipDefinition
      highlighted={false}
      align='top'
      definition={orderTooltipText(fname)}
    >
      <DisabledText>{cell.value}</DisabledText>
    </M.TooltipDefinition>
  );
};

export default TextCellContent;
