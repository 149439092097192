/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React from 'react';
import { connect } from 'react-redux';
import { useWindowResize } from 'hooks';
import { windowResize } from 'actions';
import ReactResizeDetector from 'react-resize-detector';

type Props = {
  context: string;
  onResize: () => void;
};

const App: React.FC<Props> = ({ context, onResize }) => {
  useWindowResize(context, onResize);

  return (
    <>
      {context === 'iframe' ? (
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      ) : null}
    </>
  );
};

// TODO: Refactor 'any' when adding TS to Redux setup
const mapStateToProps = ({ ui }: any) => ({
  context: ui.context,
});

const mapDispatchToProps = (dispatch: any) => ({
  onResize: () => dispatch(windowResize()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
