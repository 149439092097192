import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetReport } from 'api/reports';
import ConfigSection from './config-section';
import { namespace } from '../../../../../../../locales';
import { useDispatch } from '../../../context';
import { useGetMedium } from './hooks';
import CandidateAddress from './postal-address/candidate-address';
import { useSetNewAdverseAction } from '../../../../../../../api';

const MediumSelectionSection = styled(ConfigSection)`
  margin-top: 1rem;
`;
const base_path = `${namespace}:report.actions.pre_adverse.modal.config_options.medium_selection`;

const MediumSelection: React.FC = () => {
  const { t } = useTranslation();
  const title = t(`${base_path}.title`);
  // const subtitle = t(`${base_path}.subtitle`);
  const dispatch = useDispatch();
  const { report } = useGetReport();
  const medium = useGetMedium();
  const { newAdverseAction } = useSetNewAdverseAction();

  const onChange = useCallback(
    (e) => {
      const payload =
        e.target.value.toLowerCase() === 'postal'
          ? {
              email: { priority: 1, required: false },
              postal: { priority: 1, required: true },
            }
          : {
              email: { priority: 1, required: true },
              postal: { priority: 0, required: false },
            };

      dispatch({ type: 'SET_MEDIUM', payload });

      let waitTime = newAdverseAction.daysToPostAdverseAction;

      if (e.target.value.toLowerCase() === 'postal') {
        waitTime += 15;
      }

      dispatch({
        type: 'SET_DAYS_TO_POST_ADVERSE_ACTIONS',
        payload: waitTime,
      });
    },
    [dispatch],
  );

  if (!report?.account?.adverse_action_medium_selection) {
    return null;
  }

  return (
    <MediumSelectionSection>
      <div className='flex-container'>
        <div className='text-container'>
          <strong>{title}</strong>
          <p>
            {'Email is default. Postal mail incurs '}
            <M.Link href='https://checkr.com/pricing' target='_blank'>
              additional charges.
            </M.Link>
          </p>
        </div>
        <div>
          <M.Select
            data-testid='delivery-method-select'
            noLabel
            id='delivery-method-select'
            defaultValue={medium.charAt(0).toUpperCase() + medium.slice(1)}
            onChange={onChange}
          >
            <M.Select.Item disabled hidden value='placeholder-item' />
            {['Email', 'Postal'].map((mediaType: string) => {
              return (
                <M.Select.Item
                  key={mediaType}
                  value={mediaType}
                  text={mediaType}
                />
              );
            })}
          </M.Select>
        </div>
      </div>
      {medium === 'postal' && <CandidateAddress />}
    </MediumSelectionSection>
  );
};

export default MediumSelection;
