import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledAccordion = styled(M.Accordion)`
  .cds--accordion__item {
    border: 1px solid ${colors.uiGrey200};
    border-radius: 6px;
    box-shadow: 0 4px 32px -16px rgba(26, 32, 38, 0.1);
  }
  .cds--accordion__heading:focus {
    box-shadow: none;
  }

  .cds--accordion__heading:hover {
    background-color: unset;
  }

  .cds--accordion__item {
    .cds--accordion__wrapper {
      padding: 0 24px;
    }
  }

  .cds--accordion__title {
    padding-left: 0 !important;
  }

  .cds--accordion__heading {
    padding-right: 24px !important;
  }

  @media (min-width: 640px) {
    .cds--accordion__content {
      padding-right: 16px !important;
    }
  }
`;

export const AccordionItem = styled(M.AccordionItem)`
  .cds--accordion__content {
    padding: 0 0 24px !important;
  }
`;

export const Container = styled.div.attrs({
  className: 'accordion-title-container',
})`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px;
`;

export const SecondaryText = styled.div.attrs({
  className: 'accordion-secondary-text',
})`
  margin-left: auto;
  color: ${colors.uiTextSecondaryLight};
  text-align: right;
`;

export const Icon = styled(M.Icon)<{ fill?: string }>`
  fill: ${props => props.fill};
`;
