import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {};

const Grid = styled(M.Grid)`
  color: ${colors.uiTextPrimaryLight};
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

export const Container: React.FC<Props> = ({ children, ...props }) => {
  const rows = React.Children.map(children, child => (
    <M.GridRow>{child}</M.GridRow>
  ));

  return (
    <Grid data-testid='assess-rules-common-rule-container' {...props}>
      {rows}
    </Grid>
  );
};

export default Container;
