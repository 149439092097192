import React, { useCallback, useState } from 'react';
import { Full as Entity } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import { Button as Btn } from 'components/Button';
import DeleteDraft from 'modules/assess/ui/mvr/category-sets/instance/delete-draft';

export type Props = {
  version: Entity;
};

export const DeleteDraftButton: React.FC<Props> = ({ version }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Btn
        data-testid='open-modal-button'
        kind='secondary'
        trans='assess:common.base-dialog.actions.delete-draft'
        onClick={handleClick}
      />
      <DeleteDraft open={open} onClose={handleClose} ccsvDraft={version} />
    </>
  );
};

export default DeleteDraftButton;
