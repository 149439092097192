import { capitalize } from 'lodash';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import Decision from 'modules/assess/v2/models/decision';
import { StyledComponents, Premium } from 'modules/assess/v2/ui/common';

type Props = {
  decision: Decision;
};

const StyledSpan = styled.span`
  color: ${colors.brandNavy3};
  font-weight: bold;
`;

const StyledCol = styled.div`
  flex: 1 1 0;
  width: 0;
`;

const StyledContainer = styled(StyledComponents.SlateContainer)`
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`;

const BadgeContainer = styled(M.Container.Row)`
  align-items: center;
`;

const StyledParagraph = styled.p`
  color: ${colors.brandNavy4};
`;

const DecisionInfo: React.FC<Props> = ({ decision }) => {
  const { t } = useTranslation('assess:v2');
  const text = t(`standard.guideline.info.decisions.${decision}`);
  const dataTestId = `assess-v2-guideline_info-decision_info-${decision}`;
  const basePath = `assess-v2-ui-guideline-info-decision-info-${decision}`;
  const badgeId = `${basePath}-badge`;

  const premium = decision === Decision.Escalated ? <Premium /> : null;
  const decisionId = `${basePath}-decision`;
  const paragraphId = `${basePath}-paragraph`;

  return (
    <StyledCol>
      <StyledContainer id={basePath} data-testid={dataTestId}>
        <BadgeContainer id={badgeId}>
          <M.StatusBadge statusType={StatusTypes.Assess} status={decision} />
        </BadgeContainer>
        <BadgeContainer>
          <StyledSpan id={decisionId}>{capitalize(decision)}</StyledSpan>
          {premium}
        </BadgeContainer>
        <BadgeContainer>
          <StyledParagraph id={paragraphId}>
            <Trans>{text}</Trans>
          </StyledParagraph>
        </BadgeContainer>
      </StyledContainer>
    </StyledCol>
  );
};

export default DecisionInfo;
