import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ChildAbuseSearch as ChildAbuseSearchType } from 'types';
import ChildAbuseRecord from './ChildAbuseRecord';

type SearchProps = {
  search: ChildAbuseSearchType;
  statusType: string;
};

const ChildAbuseSearch: React.FC<SearchProps> = ({ search, statusType }) => (
  <M.SubScreening
    screening={search}
    statusType={statusType}
    title={search.state}
  >
    {search.records.map(record => (
      <ChildAbuseRecord key={record.id} record={record} />
    ))}
  </M.SubScreening>
);

export default ChildAbuseSearch;
