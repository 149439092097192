import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { v4 as uuidv4 } from 'uuid';
import { ManualBulkUploadRow } from './UploadCandidateInfo/types';

export type ValidField = {
  value: string;
  isValid: boolean;
};

export type Invite = {
  id: string;
  email: ValidField;
  firstName: ValidField;
  phoneNumber: ValidField;
};

export const emptyInvite: () => Invite = () => {
  return {
    id: uuidv4(),
    email: { value: '', isValid: true },
    firstName: { value: '', isValid: true },
    phoneNumber: { value: '', isValid: true },
  };
};

export type Node = {
  custom_id: string;
  parent_custom_id?: any;
  tier?: any;
  name: string;
  id: string;
};

export type Geo = {
  id: string;
  name: string;
  state?: string;
  city?: string;
  country?: string;
};

export type Item = {
  id?: string;
  name: string;
  country?: string;
  disabled?: boolean;
};

export type Program = {
  id: string;
  geo_ids: string[];
  name: string;
  object?: string;
  packages?: any[];
  created_at?: string;
  deleted_at?: string | null;
};

export type BulkInviteCSV = {
  fileStorageKey: string;
  fileDisplayName: string;
  fileS3URL: string;
};

export type ManualBulkUploadData = {
  fileName: string;
  fileSize: number;
  parsedData: ManualBulkUploadRow[];
  rowsWithError: number[];
  isValid: boolean;
};

export const Dropdown = styled(M.Dropdown)`
  width: 280px;
  margin: 10px 0;
`;

export const NodeDropdown = styled(M.NodeSelect)`
  margin-right: 20px !important;
  width: 280px;
  margin: 10px 0;
  flex-grow: 0 !important;

  label {
    display: none;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const noneGeo = { name: 'None', id: 'None' };
export const BULK_INVITE_CSV_STORAGE_PATH: string = 'bulk_order';

export const StyledChoiceCardGroup = styled.div`
  display: flex;
  margin: 1rem 0;
`;
export const ChoiceCardWrap = styled.div`
  width: 17.5rem;
  max-width: 100%;

  &:first-child {
    margin-right: 1rem;
  }

  .mastodon-choice-card {
    width: 100%;

    .cds--radio-button__appearance {
      margin-right: 0 !important;
    }
  }
`;

export const Tooltip = styled(M.TooltipDefinition)`
  border-bottom: none !important;
  margin-left: 3px;

  .cds--definition-tooltip {
    padding: 1rem;
    max-width: 18rem;
  }
`;

export const HelpIcon = styled(M.Icon)`
  color: ${colors.brandNavy2};
  margin-bottom: -3px;
`;

export const HelpFilledIconWithTooltip: React.FC<{
  definition: string | JSX.Element;
  align?: string;
  props?: any;
  onClick?: Function;
}> = ({ definition, align = 'bottom', props, onClick = () => {} }) => (
  <Tooltip align={align} definition={definition} {...props}>
    <HelpIcon
      onClick={onClick}
      icon='HelpFilled'
      size='16'
      style={{
        fill: colors.uiNavy600,
      }}
    />
  </Tooltip>
);

export const DEFAULT_PACKAGE_NAMES = ['Basic+', 'Essential', 'Professional'];
export const DEFAULT_PACKAGE_NAMES_WITH_COMPLETE = [
  'Basic+',
  'Essential',
  'Complete',
];
