import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div`
  margin: 0 30px 30px;
  strong {
    font-size: 14px;
    padding: 0 0 10px;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

const FairChanceAssessment: React.FC = () => {
  const { t } = useTranslation('adjudication');
  const header = t(
    'adjudication:report.actions.pre_adverse.fair_chance_assessment.header',
  );
  const body = t(
    'adjudication:report.actions.pre_adverse.fair_chance_assessment.body',
  );
  const learnMore = t(
    'adjudication:report.actions.pre_adverse.fair_chance_assessment.learn_more',
  );

  return (
    <StyledDiv>
      <strong>{header}</strong>
      <p>{body}</p>
      <a href='https://s3.amazonaws.com/checkr/resources/Fair_Chance_Initiative_Learn_More.pdf'>
        {learnMore}
      </a>
    </StyledDiv>
  );
};

export default FairChanceAssessment;
