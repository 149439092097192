import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { PageError } from 'components';
import { Page } from 'modules/assess/ui';
import { ID } from 'modules/id';
import { useDraftCreate } from './api/hooks';

type Props = {
  ruleset?: ID;
};

export const DraftCreator: React.FC<Props> = ({ ruleset }) => {
  const { call: create, result } = useDraftCreate(ruleset);

  useEffect(() => {
    if (!ruleset) {
      return;
    }

    create();
  }, [create, ruleset]);

  let content;

  switch (result.status) {
    case 'idle':
    case 'loading': {
      content = <M.LoadingSpinner />;
      break;
    }
    case 'error': {
      content = <PageError code='404' />;
      break;
    }
  }

  return (
    <Page.Container data-testid='assess-ruleset-version-draft-creator'>
      {content}
    </Page.Container>
  );
};

export default DraftCreator;
