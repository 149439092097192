import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Report } from 'types';
import { RealtimeContext } from 'containers/RealtimeProvider';
import { useCandidate } from 'providers/Candidate';

import ViewerAvatars from './ViewerAvatars';

type Props = {
  reportsAndInvitations: Report[];
};

const Viewers: React.FC<Props> = ({ reportsAndInvitations }) => {
  const candidate = useCandidate();
  const { t } = useTranslation('', { keyPrefix: 'realtime.reload' });
  const { subscribeToRoom, roomViewers } = useContext(RealtimeContext);

  useEffect(() => {
    subscribeToRoom({
      roomID: candidate.id,
      roomType: 'candidate',
      toastMessage: t('updated_candidate', {
        count: reportsAndInvitations.length,
      }),
    });
  }, [candidate.id, reportsAndInvitations.length, subscribeToRoom, t]);

  return (
    <div data-testid='candidate-header-viewers'>
      {roomViewers.length > 0 && (
        <ViewerAvatars viewers={roomViewers} hasMargin={false} />
      )}
    </div>
  );
};

export default Viewers;
