import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  AssessStatuses,
  GenericObject,
  StatusTypes,
  kebabToSnake,
} from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';

const FederalSearch: React.FC<ReportProps & { federalType: string }> = ({
  report,
  reportStatusType,
  federalType = '',
}) => {
  const formattedType = kebabToSnake(federalType);
  const getTitleName = (type: string | undefined) =>
    type === 'federal-civil-search' ? 'Federal Civil Search' : 'Federal Search';
  const { [`${formattedType}`]: screeningType } = report;

  const searchStatus = getStatus(screeningType, reportStatusType);
  const screeningId = (SCREENING_TITLES as GenericObject)[`${formattedType}`]
    ?.id;

  return (
    <M.Screening
      title={getTitleName(federalType)}
      screening={screeningType}
      statusType={reportStatusType}
      status={searchStatus}
      id={screeningId || federalType}
      data-testid={`screenings-${federalType}`}
    >
      <span>
        Checkr conducts federal district searches to verify records identified
        by this search.{' '}
        <M.TooltipDefinition
          role='additionalInfoTooltip'
          highlighted={false}
          align='top'
          definition='Checkr does not report records from this search. 
          Instead, we use the records found as pointers that we verify by 
          conducting a federal district search in the relevant federal district.
          We report verified records in the federal district search results. 
          If no federal districts were searched, it means no records were found.'
        >
          <M.Icon icon='InformationFilled' />
        </M.TooltipDefinition>
      </span>
    </M.Screening>
  );
};

function getStatus(screeningType: any, reportStatusType: string) {
  if (reportStatusType !== StatusTypes.Assess) {
    return null;
  }

  const districtSearches = screeningType?.district_searches || [];
  const canceledOrClearStatuses = ['canceled', 'clear'];
  const anyNotCanceledOrClearSearches = !districtSearches.some(
    (search: { status: string; result: string }) =>
      canceledOrClearStatuses.includes(search.status) ||
      canceledOrClearStatuses.includes(search.result),
  );

  if (anyNotCanceledOrClearSearches) {
    return null;
  }

  const anyCanceledDistrictSearchesCanceled = districtSearches.some(
    (search: { status: string }) => search.status === 'canceled',
  );

  if (anyCanceledDistrictSearchesCanceled) {
    return AssessStatuses.REVIEW;
  }

  return null;
}

export default FederalSearch;
