import React from 'react';
import { Description, Header } from 'modules/assess/ui/page/v2/home';
import Activate from '../activate';
import * as Page from '../page';
import Tabs, { TabName } from './tabs';

export type Props = {
  tab?: TabName;
};

export const Content: React.FC<Props> = ({ tab }) => {
  return (
    <Page.Content data-testid='assess-home'>
      <Header />
      <Description />
      <Activate />
      <Tabs tab={tab} />
    </Page.Content>
  );
};

export default Content;
