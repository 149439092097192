import React from 'react';
import { Category } from 'modules/assess/models/mvr/category';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { ID } from 'modules/id';
import ImportExport from './import-export';
import Description from '../description';
import Title from '../title';
import Table from './table';

type Props = {
  categories?: Array<Category>;
  instance: ID;
  type: ItemType;
  version: ID;
};

export const Mappings: React.FC<Props> = ({
  categories,
  instance,
  type,
  version,
}) => {
  return (
    <section data-testid='assess-mvr-category-set-version-mappings-mappings'>
      <Title section='mappings' type={type} />
      <Description section='mappings' type={type} />
      <ImportExport type={type} />
      <Table
        categories={categories}
        instance={instance}
        type={type}
        version={version}
      />
    </section>
  );
};

export default Mappings;
