import React, { useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import '@dashboard-experience/mastodon/lib/styles.css';
import { useFormik } from 'formik';
import { usePutPartnerApplication } from 'api/partners/hooks';
import { useDispatch } from 'react-redux';
import { useFilestackUploadResponse } from 'hooks';
import { oauthAssetsFilestackKey } from 'Constants';
import { useUploadError } from '../../api/accountSettings/hooks';
import validationSchema from './ApplicationValidationSchema';
import ApplicationModal from './ApplicationModal';

const BILL_CUSTOMER_DIRECTLY = 'bill-customer';
const CREDENTIAL_CHECKR = 'credential-checkr';

type EditApplicationModalProps = {
  toggleEditModal: () => void;
  partnerId: string;
  application: any;
  refetchPage: () => void;
};

const EditApplicationModal: React.FC<EditApplicationModalProps> = ({
  toggleEditModal,
  partnerId,
  application,
  refetchPage,
}) => {
  const {
    editApplicationCall,
    editApplicationResult: { isSuccess: editApplicationSuccess },
  } = usePutPartnerApplication(partnerId, application.resource_id);

  const { callUploadError } = useUploadError();

  const dispatch = useDispatch();

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: application.name,
      homepage: application.url,
      description: application.description,
      'partner-billing': BILL_CUSTOMER_DIRECTLY,
      'pre-credentialed-accounts': CREDENTIAL_CHECKR,
      webhook: application.webhook?.url,
      redirect: application.redirect_uri,
      'primary-color': application.primary_color,
      partner_billing: application.partner_billing,
      pre_credentialed_accounts: application.pre_credentialed_accounts,
      sign_up_flow: application.sign_up_flow,
      logo_url: application.logo_url,
    },
    validationSchema,
    onSubmit: submitForm,
  });

  const clearForm = useCallback(() => {
    resetForm();
    toggleEditModal();
  }, [resetForm, toggleEditModal]);

  const closeModalHeader = useCallback(
    (keyEvent: any) => {
      if (keyEvent.screenX !== 0 && keyEvent.screenY !== 0) {
        resetForm();
        toggleEditModal?.();
      }
    },
    [resetForm, toggleEditModal],
  );

  useEffect(() => {
    // Upon successfully saving the new geo details, call for a refetch of the page
    if (editApplicationSuccess) {
      clearForm();
      refetchPage();
    }
  }, [refetchPage, editApplicationSuccess, clearForm, dispatch]);

  const filestackUpload = useCallback(({ onPick }) => {
    return (
      <M.Button
        kind='primary'
        name='logo_url'
        style={{ margin: '8px' }}
        onClick={onPick}
      >
        Upload Logo
      </M.Button>
    );
  }, []);

  const onFileUpload = useFilestackUploadResponse({
    filestackKey: oauthAssetsFilestackKey,
    errorCallback: callUploadError,
    successCallback: file => {
      setFieldValue('logo_url', file.url);
    },
  });

  async function submitForm(formValues: any) {
    const updatedParams = {
      name: formValues.name,
      description: formValues.description,
      url: formValues.homepage,
      webhook_url: formValues.webhook,
      primary_color: formValues['primary-color'],
      redirect_uri: formValues.redirect,
      partner_billing: formValues.partner_billing,
      pre_credentialed_accounts: formValues.pre_credentialed_accounts,
      sign_up_flow: formValues.sign_up_flow,
      logo_url: formValues.logo_url,
    };

    const finalApplication = { ...application, ...updatedParams };

    editApplicationCall(finalApplication);
  }

  return (
    <ApplicationModal
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      filestackUpload={filestackUpload}
      onFileUpload={onFileUpload}
      values={values}
      errors={errors}
      touched={touched}
      clearForm={clearForm}
      closeModalHeader={closeModalHeader}
      type='edit'
    />
  );
};

export default EditApplicationModal;
