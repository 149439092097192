import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useCandidate } from 'providers/Candidate';
import { Banner } from 'components/Banner';

const StyledBanner = styled(Banner)`
  margin-top: 0px;
`;

export const PiiRemovedBanner: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledBanner
      kind='error'
      title={t(`banners.piiRemoved.title`)}
      subtitle={t(`banners.piiRemoved.subtitle`)}
      hideCloseButton
      data-testid='candidate-pii-removed-banner'
    />
  );
};

export const PiiRemovedBannerWrapper: React.FC = () => {
  const candidate = useCandidate();

  return candidate?.pii_removed ? <PiiRemovedBanner /> : null;
};

export default PiiRemovedBannerWrapper;
