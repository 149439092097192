import React, { useMemo } from 'react';
import { EmploymentGap } from 'types';
import { KeyValueSection } from '../Sections';
import { dateRange } from './utils';

type GapProps = { gap: EmploymentGap };

const Gap: React.FC<GapProps> = ({ gap }) => {
  const items = useMemo(
    () => [
      {
        itemKey: 'Dates',
        itemValue: dateRange(gap.start_date, gap.end_date),
      },
      { itemKey: 'Candidate explanation', itemValue: gap.note || 'None' },
    ],
    [gap],
  );

  return <KeyValueSection title='Employment Gap' items={items} />;
};

export default Gap;
