import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';

import { MunicipalCriminalSearch } from 'types';
import { hasCompleteScreenings } from 'utils/ScreeningPointerUtils';

import PointerList from '../PointerList';

type Props = {
  screenings: MunicipalCriminalSearch[];
};

const AboutThisSearch: React.FC<Props> = ({ screenings }) => {
  if (!hasCompleteScreenings(screenings)) return null;

  return (
    <>
      <M.Divider />
      <M.AboutThisSearch initialExpanded={false}>
        <>
          {screenings?.map(screening => {
            const {
              court,
              county,
              state,
              object,
              state_source_description,
              screening_pointers,
            } = screening;
            const isStateCrim = object === 'state_criminal_search';
            const stateCrimTitle = state_source_description || state;
            const muniCrimTitle = court
              ? `${court}, ${county}, ${state}`
              : `${county}, ${state}`;
            const screeningTitle = isStateCrim ? stateCrimTitle : muniCrimTitle;
            const additionalInfoItems = [];
            const status = getResultOrStatus(screening);
            const isScreeningComplete = status !== ReportStatuses.PENDING;

            // Add pointers to Additional Info items
            if (screening_pointers) {
              additionalInfoItems.push({
                itemKey: 'Pointer(s):',
                itemValue: <PointerList pointers={screening_pointers} />,
              });
            }

            return (
              isScreeningComplete && (
                <M.AdditionalInfo
                  data-testid={`${
                    isStateCrim ? 'state' : 'muni'
                  }-crim-additional-info`}
                  key={screeningTitle}
                  title={screeningTitle}
                  items={additionalInfoItems}
                  type='shadow'
                />
              )
            );
          })}
        </>
      </M.AboutThisSearch>
    </>
  );
};

export default AboutThisSearch;
