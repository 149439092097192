import React from 'react';
import styled from 'styled-components';
import { Row } from 'modules/assess/ui/rules/common/rule';

const RowStyle = styled(Row)`
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '1rem !important',
  marginRight: '1rem !important',
`;

const RowContainer: React.FC = ({ children }) => {
  return <RowStyle>{children}</RowStyle>;
};

export default RowContainer;
