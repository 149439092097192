import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  icon: string;
};

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  margin: 0 0.1rem;
`;

const StyledIcon = styled(M.StatusIcon)`
  position: relative;
  top: 0.2rem;
  right: 0.1rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
`;

// TODO: Move component under common folder (EADA-1729)
const Icon: React.FC<Props> = ({ icon }) => {
  return (
    <IconContainer
      id='assess-v2-ui-home-settings-eligible-records-icon'
      role='img'
    >
      <StyledIcon icon={icon} />
    </IconContainer>
  );
};

export default Icon;
