import { Package } from '../types';

export const aliasScreenings: string[] = [
  'county_civil_search',
  'county_criminal_search',
  'federal_civil_search',
  'federal_criminal_search',
  'global_watchlist_search',
  'municipal_criminal_search',
  'national_criminal_search',
  'sex_offender_search',
  'state_criminal_search',
];

const includesAlias = (p: Package): boolean => {
  if (!p.screenings || !p.use_alias_skus) {
    return false;
  }

  return p.screenings.map(p => p.type).some(t => aliasScreenings.includes(t));
};

export const hasAliasScreenings = (p: Package): boolean => {
  if (!p.screenings) {
    return false;
  }

  return p.screenings.some(p => aliasScreenings.includes(p.type));
};

export default includesAlias;
