// Returns the last element of the array
export const mostRecent = <T>(arr?: T[]) => {
  if (!arr || arr.length === 0) return undefined;
  const last = arr.length - 1;
  return arr[last];
};

// Return a new array with the element moved to a new index
export const move = (arr: any[], fromIdx: number, toIdx: number) => {
  const newArr = [...arr];
  newArr.splice(toIdx, 0, newArr.splice(fromIdx, 1)[0]);
  return newArr;
};
