import each from 'lodash/each';
import * as locales from './locales';
import namespace from './namespace';

type Resources = {
  [key: string]: { assess: object };
};

export const resources: Resources = {};

each(locales, (data, locale) => {
  resources[locale] = { [namespace]: data };
});

export default resources;
