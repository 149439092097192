import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { FieldComponentProps } from '../types';
import { IconText } from '../CandidateInformationStyledComponents';

const OnHold: React.FC<FieldComponentProps> = () => {
  return (
    <IconText>
      <M.Icon icon='Warning' size='16' />{' '}
      <strong className='text'>On Hold</strong>
    </IconText>
  );
};

export default OnHold;
