import { colors, fonts } from '@dashboard-experience/mastodon';
import { createGlobalStyle } from 'styled-components';

const iframe = `
  .cds--modal.is-visible {
    background-color: transparent;
    height: auto;
  }
  .cds--modal-container {
    box-shadow: 0 0.25rem 1.5rem 0 rgba(22, 22, 29, 0.3);
    margin: 1.5rem;
    max-height: calc(100vh - 3rem);
  }

  // actions pane modals styles
  .cds--modal.is-visible.actions-modal {
    padding-top: 8rem;
    padding-bottom: 100vh;
    .cds--modal-container {
      max-height: 90vh !important;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: ${fonts.legacy}, ${fonts.ui};
    background-color: ${colors.uiGrey0};
    color: ${colors.uiGrey700};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: ${colors.uiGrey700};
    text-decoration: none;
  }

  .cds--list__item { color: ${colors.uiGrey700} }

  ${({ context }) => context === 'iframe' && iframe}

  #mastodon {
    overflow-x: hidden;
    margin-bottom: 0;
  }

  // Fix for tooltip being covered by Pendo backdrop
  .page-addons ._pendo-guide-tt_ {
    z-index: 5000 !important;
  }
`;

export default GlobalStyle;
