import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

type Props = {
  onClose?: (event: SyntheticEvent) => void;
};

export const Actions: React.FC<Props> = ({ onClose }) => {
  return <Button kind='secondary' trans='verbs.close' onClick={onClose} />;
};

export default Actions;
