import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import debounce from 'lodash/debounce';
import styled from 'styled-components';

import DomesticAddScreeningsSummary from 'components/AddScreenings/Domestic/Summary';
import * as utils from 'components/AddScreenings/shared/utils';
import InternationalAddScreeningsSummary from 'components/AddScreenings/International/Summary';
import {
  usePackagePrice,
  useAllAddOnPricesFromSkus,
} from 'components/AddScreenings/hooks/usePackagePrice';
import AddScreeningsList, {
  getDisabledScreenings,
} from 'components/AddScreenings/AddScreeningList';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import Context from 'components/Packages/context';
import PopularAddOns from 'components/AddScreenings/Domestic/PopularAddOns';
import OriginType from 'components/Packages/enums/OriginType';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import { useOrderBackgroundCheckContext, actionTypes } from '../Context';

const InnerContainer = styled.div`
  display: flex;
  padding: 0px !important;
  margin-bottom: 0px;
  flex-direction: column;

  @media (min-width: 672px) {
    flex-direction: row;
  }
`;

const ScreeningsSection = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  margin-bottom: 24px !important;
  width: 100%;

  @media (min-width: 672px) {
    flex: 1 1 65%;
    border-bottom: 0;
  }

  @media all and (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 672px) {
    width: 100%;
    min-height: 400px !important;
  }
`;

const SummaryContainer = styled.div<SummaryContainerProps>`
  min-width: 375px !important;
  position: relative;

  @media all and (max-width: 1400px) {
    min-width: 0 !important;
  }

  @media all and (max-width: 780px) {
    top: 0;
  }

  @media all and (max-width: 672px) {
    padding-top: 50px !important;
  }
`;

const SummarySection = styled.div<SummarySectionProps>`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 24px;
  background-color: ${colors.uiGrey0};
  overflow-y: auto;

  @media all and (min-width: 1400px) {
    min-width: 375px;
  }

  @media all and (min-width: 672px) {
    max-width: 375px;
    flex: 1 1 35%;
    margin-left: 16px;
  }
`;

const SaveForNextTimeContainer = styled.div`
  margin-top: 16px;
  border-radius: 10px;
  padding: 24px;
  background-color: ${colors.uiNavy50};
  color: ${colors.uiTextPrimaryLight};
  border: 1px solid ${colors.uiGrey200};
  @media (min-width: 672px) {
    flex: 1 1 35%;
  }
`;

type TopbarProps = {
  top?: number;
};

const SavePackageNameContainer = styled.div`
  margin-top: 16px;
`;

type SummarySectionProps = {
  maxHeight: number;
};

const TitleSection = styled.div<TopbarProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({ top }) => top}px;
  margin-bottom: 15px;
  width: 690px;
  color: ${colors.brandNavy4};
`;

const Margin16 = styled.div`
  margin-top: 16px;
`;
const ADDITIONAL_PACKAGE_SUMMARY_PADDING = 24;
// const TOP_SECTION_HEIGHT = 240; // Title + Breadcrumbs + Padding

type SummaryContainerProps = {
  maxHeight: number;
};

type Props = {
  windowOffsetY?: number;
  windowInnerHeight?: number;
};

const AddAddons: React.FC<Props> = ({
  windowOffsetY = 0,
  windowInnerHeight = 0,
}) => {
  const currentUser = useUser();

  const { account } = useContext(Context);
  const [saveForNextTime, setSaveForNextTime] = useState(false);
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const {
    basePackage,
    addedScreeningTypes,
    additionalProperties,
    newPackageName,
    selectedGeo,
    newPackageNameInvalid,
    error,
    aliasesEnabled,
  } = state;
  const packageSummaryRef = useRef<HTMLDivElement>(null);
  const basePackageScreeningTypes = basePackage.screenings.map(
    (screening: { type: any }) => screening.type,
  );

  // query for the price of all possible add ons
  // this data populates each individual screening price in the list section
  const allAddOnPricesQuery = useAllAddOnPricesFromSkus(account?.id);
  const mappedSkusQuery = utils.mapAddonSkus(allAddOnPricesQuery);

  const screeningAdded = debounce(
    useCallback((screeningType, additionalProperties) => {
      dispatch({
        type: actionTypes.SCREENING_ADDED,
        payload: { screeningType, additionalProperties },
      });
    }, []),
    200,
    { leading: false },
  );

  const screeningRemoved = useCallback(
    (screeningType, additionalProperties) => {
      dispatch({
        type: actionTypes.SCREENING_REMOVED,
        payload: { screeningType, additionalProperties },
      });
    },
    [dispatch],
  );

  // query for the price of the initial package with no-add ons
  // this data populates the base price in the summary
  const basePackagePricesQuery = usePackagePrice(
    ['base-package-prices', basePackage.name],
    account,
    { ...basePackage },
  );

  const packageName = useMemo(
    () =>
      !state.newPackageNameInvalid && state.newPackageName !== ''
        ? state.newPackageName
        : state.basePackage.name,
    [state.newPackageNameInvalid, state.newPackageName, state.basePackage.name],
  );

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...state.basePackage,
        aliases_enabled: AliasesEnabledType.OFF,
      },
      addedScreeningTypes: state.addedScreeningTypes,
      additionalProperties: state.additionalProperties,
      packageName,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    packageName,
    state.addedScreeningTypes,
    state.additionalProperties,
    state.basePackage,
  ]);

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
      false,
    ],
    account,
    {
      ...buildPostBodyWithAddOns(),
    },
  );

  const buildPostBodyWithAddOnsAlias = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...state.basePackage,
        aliases_enabled: AliasesEnabledType.ON,
      },
      addedScreeningTypes: state.addedScreeningTypes,
      additionalProperties: state.additionalProperties,
      packageName,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    packageName,
    state.addedScreeningTypes,
    state.additionalProperties,
    state.basePackage,
  ]);

  const pendingAddOnPricesQueryAlias = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    account,
    {
      ...buildPostBodyWithAddOnsAlias(),
    },
  );

  const completedAddons = useMemo(() => {
    let status = false;
    if (saveForNextTime) {
      if (
        (state.addedScreeningTypes.length > 0 &&
          state.newPackageName &&
          !state.newPackageNameInvalid) ||
        state.aliasesEnabled === AliasesEnabledType.ON
      ) {
        status = true;
      }
    } else {
      status =
        state.addedScreeningTypes.length > 0 ||
        state.aliasesEnabled === AliasesEnabledType.ON;
    }
    return status;
  }, [
    saveForNextTime,
    state.addedScreeningTypes.length,
    state.aliasesEnabled,
    state.newPackageName,
    state.newPackageNameInvalid,
  ]);

  useEffect(() => {
    if (completedAddons) {
      state.continueButtonDisabled &&
        dispatch({
          type: actionTypes.CONTINUE_BUTTON_DISABLED,
          payload: { continueButtonDisabled: false },
        });
    } else {
      !state.continueButtonDisabled &&
        dispatch({
          type: actionTypes.CONTINUE_BUTTON_DISABLED,
          payload: { continueButtonDisabled: true },
        });
    }
  }, [completedAddons, dispatch, state.continueButtonDisabled]);

  const handlePackageNameChange = useCallback(
    (e: any) => {
      dispatch({
        type: actionTypes.SET_PACKAGE_NAME,
        payload: { name: e.target.value },
      });
    },
    [dispatch],
  );

  const handleAliasClick = useCallback(() => {
    const isNotOn =
      (basePackage.aliases_enabled === AliasesEnabledType.ACCOUNT ||
        basePackage.aliases_enabled === AliasesEnabledType.OFF) &&
      state.aliasesEnabled !== AliasesEnabledType.ON;

    if (state.aliasesEnabled === AliasesEnabledType.ON) {
      dispatch({
        type: actionTypes.SET_ALIASES_PRICE,
        payload: {
          aliasPrice: 0,
        },
      });
    }

    if (isNotOn) {
      dispatch({
        type: actionTypes.SET_ALIASES_ENABLED,
        payload: {
          aliasesEnabled: AliasesEnabledType.ON,
        },
      });
    } else {
      dispatch({
        type: actionTypes.SET_ALIASES_ENABLED,
        payload: {
          aliasesEnabled: basePackage.aliases_enabled,
        },
      });
    }
  }, [basePackage.aliases_enabled, dispatch, state.aliasesEnabled]);

  const handlePriceSnapshot = useCallback(
    ({ basePackagePrice, addonPrices, aliasPrice, subtotalPerCandidate }) => {
      if (
        state.pricesSnapshot?.basePackagePrice !== basePackagePrice ||
        state.pricesSnapshot?.addonPrices?.length !== addonPrices?.length ||
        state.pricesSnapshot?.aliasPrice !== aliasPrice ||
        state.pricesSnapshot?.subtotalPerCandidate !== subtotalPerCandidate
      ) {
        dispatch({
          type: actionTypes.SET_PRICE_SNAPSHOT,
          payload: {
            pricesSnapshot: {
              basePackagePrice,
              addonPrices,
              aliasPrice,
              subtotalPerCandidate,
            },
          },
        });
      }
    },
    [
      dispatch,
      state.pricesSnapshot?.addonPrices?.length,
      state.pricesSnapshot?.aliasPrice,
      state.pricesSnapshot?.basePackagePrice,
      state.pricesSnapshot?.subtotalPerCandidate,
    ],
  );

  const setAliasPrice = useCallback(
    (aliasPrice: number) => {
      if (aliasPrice) {
        dispatch({
          type: actionTypes.SET_ALIASES_PRICE,
          payload: {
            aliasPrice,
          },
        });
      }
    },
    [dispatch],
  );

  const handleSaveForNextTimeCheckboxClick = useCallback(() => {
    setSaveForNextTime(!saveForNextTime);
    dispatch({ type: actionTypes.RESET_PACKAGE_NAME, payload: {} });
    dispatch({ type: actionTypes.SAVE_FOR_NEXT_TIME, payload: {} });
  }, [dispatch, saveForNextTime]);

  const handleInternationalPriceLinkClick = useCallback(() => {
    dispatch({
      type: actionTypes.SET_INTERNATIONAL_PRICE_LINK_CLICK,
      payload: { internationalPriceLinkClicked: true },
    });
  }, [dispatch]);

  return (
    <>
      <TitleSection>
        Add searches to meet industry, location, or role requirements.
      </TitleSection>
      <InnerContainer>
        <ScreeningsSection>
          {selectedGeo === OriginType.domestic && (
            <>
              <PopularAddOns
                onAddClick={screeningAdded}
                onRemoveClick={screeningRemoved}
                addedScreenings={addedScreeningTypes}
                disabledScreenings={getDisabledScreenings(
                  basePackageScreeningTypes,
                )}
                additionalProperties={additionalProperties}
                allAddOnPricesQuery={mappedSkusQuery}
                basePackageScreenings={basePackage.screenings}
              />
              <Margin16 />
            </>
          )}
          <AddScreeningsList
            onAddClick={screeningAdded}
            onRemoveClick={screeningRemoved}
            addedScreenings={addedScreeningTypes}
            additionalProperties={additionalProperties}
            basePackage={basePackage as any}
            account={account}
            basePackageScreeningTypes={basePackageScreeningTypes}
            selectedGeo={selectedGeo}
            allAddOnPricesQuery={mappedSkusQuery}
            screeningSettingPrice={pendingAddOnPricesQuery?.data?.items || []}
          />
        </ScreeningsSection>
        <SummaryContainer
          ref={packageSummaryRef}
          maxHeight={windowInnerHeight - ADDITIONAL_PACKAGE_SUMMARY_PADDING}
        >
          <SummarySection
            data-testid='package-summary'
            maxHeight={
              windowInnerHeight - 2 * ADDITIONAL_PACKAGE_SUMMARY_PADDING
            }
          >
            {selectedGeo === 'domestic' ? (
              <DomesticAddScreeningsSummary
                basePackage={basePackage}
                basePackagePrices={basePackagePricesQuery.data}
                basePackageScreeningTypes={basePackageScreeningTypes}
                pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                pendingAddOnPricesQueryAlias={pendingAddOnPricesQueryAlias}
                hasAddedScreenings={Boolean(addedScreeningTypes.length)}
                additionalProperties={additionalProperties}
                handleAliasClick={handleAliasClick}
                aliasEnabled={aliasesEnabled}
                setAliasPrice={setAliasPrice}
                handlePriceSnapshot={handlePriceSnapshot}
              />
            ) : (
              <InternationalAddScreeningsSummary
                basePackage={basePackage}
                basePackageScreeningTypes={basePackageScreeningTypes}
                pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                hasAddedScreenings={Boolean(addedScreeningTypes.length)}
                additionalProperties={additionalProperties}
                handleInternationalPriceLinkClick={
                  handleInternationalPriceLinkClick
                }
              />
            )}
            <div>
              {hasPermission(currentUser, 'manage_packages') && (
                <SaveForNextTimeContainer data-testid='save-for-next-time-container'>
                  <M.Checkbox
                    data-testid='save-for-next-time-checkbox'
                    id='save-for-next-time-checkbox'
                    checked={saveForNextTime}
                    labelText='Save package for next time'
                    onChange={handleSaveForNextTimeCheckboxClick}
                  />

                  {saveForNextTime && (
                    <SavePackageNameContainer>
                      <M.TextInput
                        data-testid='name-your-package-textinput'
                        id='name-your-package-textinput'
                        labelText='Name your package'
                        value={newPackageName}
                        invalid={error || newPackageNameInvalid}
                        onChange={handlePackageNameChange}
                        invalidText={
                          error ||
                          'Package name cannot be blank or include special characters'
                        }
                      />
                    </SavePackageNameContainer>
                  )}
                </SaveForNextTimeContainer>
              )}
            </div>
          </SummarySection>
        </SummaryContainer>
      </InnerContainer>
    </>
  );
};

export default AddAddons;
