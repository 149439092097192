import React, { useCallback, useEffect, useState } from 'react';
import { colors, M, sizes } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import ReactFilestack from 'filestack-react';
import { useUser } from 'context/CurrentUser';
import { scrollToTop } from 'utils';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clickAndRedirectUri, toastError } from 'actions';
import { usePerformBulkAction } from 'api/continuousChecks';
import { snakeCase } from 'lodash';
import { ccFilestackKey as filestackKey } from 'Constants';
import { accountHasPermission } from '@dashboard-experience/utils';
import { useFilestackUploadResponse } from 'hooks';
import BulkActionText from './BulkActionText';
import BulkActionModal from './BulkActionModal';
import {
  C_CRIMINAL,
  C_CRIMINAL_PRODUCT,
  C_MVR,
  C_MVR_PRODUCT,
  CC_BULK_ACTION_AH_CSV_TEMPLATE,
  CC_BULK_ACTION_CSV_TEMPLATE,
  CONTINUOUS_CHECK,
} from '../../Constants';

const BULK_ACTION_STORAGE_PATH = 'raw_csv_uploads';

const filestackOptions = {
  accept: ['text/csv'],
  storeTo: {
    location: 's3',
    path: `/${BULK_ACTION_STORAGE_PATH}/`,
  },
  uploadInBackground: false,
  fromSources: ['local_file_system'],
  customText: {
    Files: 'File',
    'Select Files to Upload': 'Select file to upload',
    'Selected Files': 'Selected File',
    'Deselect All': 'Deselect File',
    'or Drag and Drop, Copy and Paste Files':
      'or drag and drop, copy and paste file ',
  },
};

const TemplateLink = styled(M.Link)`
  cursor: pointer;
  display: inline-block;
  margin: auto 0 auto 1.5rem;
`;

const BoldText = styled.h6`
  padding-top: 1rem;
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight} !important;
`;

const Heading = styled.h1`
  padding-top: 1rem;
  color: ${colors.uiTextPrimaryLight};
`;

const Text = styled.p`
  display: block;
  margin: 1rem;
  color: ${colors.uiTextPrimaryLight};
`;

const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const ButtonAsLink = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: ${sizes.medium};
  padding: 0 !important;
`;

const customRender = ({ onPick }) => (
  <M.Button kind='secondary' size='sm' onClick={onPick}>
    <M.Icon icon='CloudUpload' style={{ marginRight: '0.5rem' }} />
    Upload
  </M.Button>
);

const BulkActionContainer = () => {
  const DEFAULT_STATE = {
    productCheck: '',
    actionCheck: '',
    certifyCheck: false,
    modalOpen: false,
    fileStorageKey: '',
    fileDisplayName: '',
  };
  const dispatch = useDispatch();
  const [state, setState] = useState(DEFAULT_STATE);
  const currentUser = useUser();
  const history = useHistory();
  const navigate = useCallback(
    path => {
      history.push(path);
    },
    [history],
  );

  const handlePathClick = useCallback(
    () => navigate('/continuous_services'),
    [navigate],
  );

  const updateState = (key, defaultState) => e =>
    setState({ ...state, [key]: e || defaultState });
  const updateProduct = updateState('productCheck', '');
  const updateAction = updateState('actionCheck', '');

  const updateCertifyCheck = useCallback(
    (_, { checked }) => setState({ ...state, certifyCheck: checked }),
    [state],
  );

  const showModal = useCallback(() => {
    setState({
      ...state,
      modalOpen: true,
    });
    scrollToTop();
  }, [state]);

  const hideModal = useCallback(() => {
    setState({
      ...state,
      modalOpen: false,
    });
    handlePathClick();
  }, [state]);

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatch(toastError('Failed to upload file'));
    },
    successCallback: file => {
      setState({
        ...state,
        fileStorageKey: file.key,
        fileDisplayName: file.filename,
      });
    },
  });

  const onFileDelete = useCallback(() => {
    setState({
      ...state,
      fileDisplayName: '',
      fileStorageKey: '',
    });
  }, [state]);

  const accountHierarchyEnabled = currentUser.account.segmentation_enabled;

  const onTemplateDownload = useCallback(() => {
    if (accountHierarchyEnabled && state.actionCheck != 'Unenroll') {
      clickAndRedirectUri(CC_BULK_ACTION_AH_CSV_TEMPLATE);
    } else {
      clickAndRedirectUri(CC_BULK_ACTION_CSV_TEMPLATE);
    }
  });

  const mvrApproved = accountHasPermission(currentUser, 'continuous_check_mvr');
  const criminalApproved = accountHasPermission(
    currentUser,
    'continuous_monitoring',
  );

  const getContinuousEnabledProducts = () => {
    const enabledProducts = [];
    if (mvrApproved) {
      enabledProducts.push({ key: C_MVR_PRODUCT, name: C_MVR });
    }
    if (criminalApproved) {
      enabledProducts.push({ key: C_CRIMINAL_PRODUCT, name: C_CRIMINAL });
    }
    return enabledProducts;
  };

  const products = getContinuousEnabledProducts();

  const getActions = () => {
    const actions = [{ name: 'Enroll' }, { name: 'Unenroll' }];
    if (accountHierarchyEnabled) {
      actions.push({ name: 'Update' });
    }
    return actions;
  };

  const actions = getActions();

  const { bulkActionCall, bulkActionResult } = usePerformBulkAction();

  const performBulkAction = useCallback(() => {
    const params = {
      account_id: currentUser.account.id,
      action: state.actionCheck.toLowerCase(),
      file_storage_key: state.fileStorageKey,
      subscription_type: state.productCheck,
      uploader_email: currentUser.email,
    };
    bulkActionCall(params);
  }, [bulkActionCall, state]);

  useEffect(() => {
    if (bulkActionResult.isSuccess) {
      showModal();
    }
  }, [bulkActionResult]);

  return (
    <>
      <BulkActionModal
        header='Request processing'
        open={state.modalOpen}
        hideModal={hideModal}
        email={currentUser.email}
      />
      <M.Container>
        <span>
          <M.Container.Row>
            <Heading>Bulk action</Heading>
          </M.Container.Row>
          <M.Container.Row>
            <BoldText>Select a {CONTINUOUS_CHECK}</BoldText>
          </M.Container.Row>
          <M.Container.Row>
            <M.RadioButtonGroup
              orientation='horizontal'
              name='selection-products'
              onChange={updateProduct}
            >
              {products.map(item => (
                <M.RadioButton
                  key={snakeCase(item.name)}
                  value={item.key}
                  labelText={item.name}
                />
              ))}
            </M.RadioButtonGroup>
          </M.Container.Row>
          <M.Container.Row>
            <BoldText>Select an action</BoldText>
          </M.Container.Row>
          <M.Container.Row>
            <M.RadioButtonGroup
              orientation='horizontal'
              name='selection-actions'
              onChange={updateAction}
            >
              {actions.map(item => {
                return (
                  <M.RadioButton
                    key={snakeCase(item.name)}
                    value={item.name}
                    labelText={item.name}
                  />
                );
              })}
            </M.RadioButtonGroup>
          </M.Container.Row>
          {state.actionCheck !== '' && state.productCheck !== '' && (
            <BulkActionText
              action={state.actionCheck}
              accountHierarchyEnabled={accountHierarchyEnabled}
            />
          )}
          {state.productCheck !== '' && state.actionCheck !== '' && (
            <>
              <M.Container.Row>
                <br />
                <ReactFilestack
                  actionOptions={filestackOptions}
                  apikey={filestackKey}
                  customRender={customRender}
                  onSuccess={onFileUpload}
                />
                <TemplateLink onClick={onTemplateDownload}>
                  Download CSV template
                </TemplateLink>
              </M.Container.Row>
              {state.fileDisplayName && (
                <M.Container.Row>
                  <M.StatusIcon icon='clear' />
                  <Text> {state.fileDisplayName} </Text>
                  <ButtonAsLink
                    role='button'
                    type='button'
                    onClick={onFileDelete}
                  >
                    Delete
                  </ButtonAsLink>
                </M.Container.Row>
              )}
            </>
          )}
          {(state.actionCheck === 'Enroll' || state.actionCheck === 'Update') &&
            state.productCheck !== '' && (
              <M.Container.Row>
                <br />
                <M.Container.Col>
                  <M.Checkbox
                    id='include-object'
                    name='include_object'
                    labelText={
                      <div>
                        <span>
                          I certify the consumer has received disclosures as
                          required by the{' '}
                          <M.Link
                            href='https://help.checkr.com/hc/en-us/articles/216557368-What-is-the-Fair-Credit-Reporting-Act-FCRA-'
                            target='_blank'
                          >
                            Fair Credit Reporting Act
                          </M.Link>{' '}
                          and all{' '}
                          <M.Link
                            href='https://help.checkr.com/hc/en-us/articles/360001906708-How-do-I-gather-evergreen-consent-to-run-ongoing-background-checks-'
                            target='_blank'
                          >
                            applicable state laws
                          </M.Link>{' '}
                          and that I obtained the consumer’s consent.
                        </span>
                      </div>
                    }
                    onChange={updateCertifyCheck}
                    type='inline'
                  />
                </M.Container.Col>
              </M.Container.Row>
            )}
        </span>
        <RightButtonsContainer>
          <M.Button kind='secondary' size='md' onClick={handlePathClick}>
            Cancel
          </M.Button>
          {(state.actionCheck === 'Enroll' ||
            state.actionCheck === 'Update') && (
            <M.Button
              kind='primary'
              size='md'
              onClick={performBulkAction}
              disabled={
                state.fileDisplayName === '' ||
                !state.certifyCheck ||
                bulkActionResult.isLoading
              }
            >
              Submit
            </M.Button>
          )}
          {state.actionCheck === 'Unenroll' && (
            <M.Button
              kind='primary'
              size='md'
              onClick={performBulkAction}
              disabled={
                state.fileDisplayName === '' || bulkActionResult.isLoading
              }
            >
              Submit
            </M.Button>
          )}
        </RightButtonsContainer>
      </M.Container>
    </>
  );
};

export default BulkActionContainer;
