import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useComponents } from 'providers/Candidate';
import UIContext from 'context/UI';
import DelayedScreeningsBanner from './DelayedScreeningsBanner/DelayedScreeningsBanner';
import CandidateReportFairChanceBanner from './CandidateReportFairChance';
import AdverseActionPaused from './AdverseActionPaused';
import AdverseActionUndeliverableBanner from './AdverseActionUndeliverableBanner';
import IdentityVerificationAction from './IdentityVerificationAction';

// For report banners, add them to this map in the order they should be shown
const banners: { [key: string]: React.FC<any> } = {
  // some_banner: SomeBannerComponent
  delayed_screenings_banner: DelayedScreeningsBanner,
  adverseActionPauseEscalatedDaysBanner: AdverseActionPaused,
  adverseActionUndeliverableBanner: AdverseActionUndeliverableBanner,
  candidateReportFairChanceBanner: CandidateReportFairChanceBanner,
  identityVerificationAction: IdentityVerificationAction,
};

const ReportBanners: React.FC = () => {
  const { isIframe, isStandaloneIframe } = useContext(UIContext);
  const components = useComponents();

  return (
    <M.Grid>
      <M.GridRow>
        {Object.entries(banners).map(([key, Component]) =>
          !isIframe || isStandaloneIframe || components.includes(key) ? (
            <Component key={key} />
          ) : null,
        )}
      </M.GridRow>
    </M.Grid>
  );
};

export default ReportBanners;
