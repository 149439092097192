import { Entity as Base, ID } from 'modules/entity';
import { Eventable } from '../event';
import * as Version from './category-code-set-versions/category-code-set-version';

export type Entity = CategoryCodeSet;

export interface CategoryCodeSet extends Base, Eventable<'list'> {
  account_id: ID | undefined;
  name: string;
  active_rulesets_count?: number;
  versions?: Array<Version.Full>;
}

export { Version };

export const DEFAULT_INSTANCE = 'default';
