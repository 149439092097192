import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, orderByKey } from '@dashboard-experience/utils';
import {
  HealthcareSanctionsSearch,
  HealthcareSanctionsRecord,
  ReportProps,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { STATUS_ORDER } from 'Constants';
import HealthcareSanctionsSearchRecord from './HealthcareSanctionSearchRecord';

/**
 * Renders the healthcare sanctions searches component.
 * @param report - The report object.
 * @param reportStatusType - The report status type.
 * @returns The rendered healthcare sanctions searches component.
 */
const HealthcareSanctionsSearches: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { healthcare_sanctions_searches } = report;

  const screenings: false | HealthcareSanctionsSearch[] = useMemo(
    () =>
      !!healthcare_sanctions_searches &&
      (orderByKey(
        healthcare_sanctions_searches,
        'status',
        STATUS_ORDER,
      ) as HealthcareSanctionsSearch[]),
    [healthcare_sanctions_searches],
  );

  const ScreeningTitle =
    Array.isArray(screenings) && screenings.length === 1
      ? 'Healthcare Sanctions Search'
      : 'Healthcare Sanctions Searches';

  return screenings && screenings.length > 0 ? (
    <M.Screening
      id={SCREENING_TITLES.healthcare_sanctions_searches.id}
      data-testid='healthcare-sanctions-search'
      title={ScreeningTitle}
      statusType={reportStatusType}
      screening={screenings}
      hideCompletedAt
    >
      {screenings.map((screening: any, idx: number) => {
        const hssRecords = screening.records;
        const SourceTypeTitle = getTitleBySourceType(screening.source_type);
        const status = getResultOrStatus(screening);
        const { result } = screening;
        const key = `healthcare-sanctions-search-${idx}`;
        const screeningSourceType = screening.source_type;

        return (
          <React.Fragment key={key}>
            <RenderScreeningByResults
              hssRecords={hssRecords}
              title={SourceTypeTitle}
              status={status}
              result={result}
              statusType={reportStatusType}
              screeningSourceType={screeningSourceType}
              screening={screening}
            />
          </React.Fragment>
        );
      })}
    </M.Screening>
  ) : null;
};

/**
 * Renders the screening element, with or without the expandable, for healthcare sanctions search.
 * @param hssRecords - The array of healthcare sanctions records.
 * @param title - The title of the screening.
 * @param status - The status of the screening.
 * @param result - The result of the screening.
 * @param statusType - The type of status.
 * @param screeningSourceType - The source type of the screening.
 * @param screening - The healthcare sanctions search object.
 * @returns The JSX element for rendering the screening results.
 */
const RenderScreeningByResults: React.FC<{
  hssRecords: HealthcareSanctionsRecord[];
  title: string;
  status: string;
  result: string;
  statusType: string;
  screeningSourceType: string;
  screening: HealthcareSanctionsSearch;
}> = ({
  hssRecords,
  title,
  status,
  result,
  statusType,
  screeningSourceType,
  screening,
}) => {
  /**
   * Creates the heading for the healthcare sanctions search screening in expandable state.
   * @returns The JSX element for the heading.
   */
  const createHeading = () => {
    return (
      <M.Screening.Heading
        headingLeft={{
          name: title,
          strong: true,
        }}
        headingRight={<M.StatusBadge statusType={statusType} status={status} />}
      />
    );
  };

  /**
   * Initializes the initialExpanded state for the Expandable component.
   * It checks if the hssRecords array has any elements and sets the initialExpanded value accordingly.
   * @returns The initialExpanded value.
   */
  const initialExpanded = useMemo(
    () => hssRecords && hssRecords.length > 0,
    [hssRecords],
  );

  if (result === 'clear' || status === 'pending') {
    return (
      <M.SubScreening
        title={title}
        status={status}
        statusType={statusType}
        screening={screening}
      />
    );
  }

  return (
    <M.Expandable title={createHeading()} initialExpanded={initialExpanded}>
      <HealthcareSanctionsSearchRecords
        hssRecords={hssRecords}
        screeningSourceType={screeningSourceType}
      />
    </M.Expandable>
  );
};

/**
 * Renders the healthcare sanctions search records.
 * @param hssRecords - The healthcare sanctions search records.
 * @param screeningSourceType - The screening source type.
 * @returns The rendered healthcare sanctions search records.
 */
const HealthcareSanctionsSearchRecords: React.FC<{
  hssRecords: HealthcareSanctionsRecord[];
  screeningSourceType: string;
}> = ({ hssRecords, screeningSourceType }) => {
  const verifiedRecords = hssRecords.filter(record => record.verified); // Filters the healthcare sanctions search records to include only verified records

  return (
    <>
      {verifiedRecords.map((record: HealthcareSanctionsRecord, idx: number) => (
        <React.Fragment key={record.id}>
          <HealthcareSanctionsSearchRecord
            sourceType={screeningSourceType}
            record={record}
            index={idx + 1}
          />
        </React.Fragment>
      ))}
    </>
  );
};

/**
 * Retrieves the title based on the source type.
 * @param sourceType - The source type.
 * @returns The corresponding title.
 */
const getTitleBySourceType = (sourceType: string) => {
  switch (sourceType) {
    case 'cms_preclusion_list':
      return 'CMS Preclusion List';
    default:
      return '';
  }
};

export default HealthcareSanctionsSearches;
