import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useAdverseActionAllowedBySelfDisclosure } from 'modules/adjudication/utilities';

const base_path = `${namespace}:report.actions.pre_adverse.adverse_items.header`;

const Header: React.FC<{}> = () => {
  const adverseActionAllowedBySelfDisclosure =
    useAdverseActionAllowedBySelfDisclosure();

  const { t } = useTranslation();
  const self_disclosure = t(`${base_path}.self_disclosure`);
  const content = t(`${base_path}.content`);

  return (
    <>
      {adverseActionAllowedBySelfDisclosure && (
        <section>
          <strong>{self_disclosure}</strong>
        </section>
      )}
      <section>{content}</section>
    </>
  );
};

export default Header;
