import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { InputGroupTopAlignedOld as InputGroup, InputLabel } from 'components';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY } from '../../Flags';

type props = {
  setting: any;
  setSettings: (key: string, value: boolean | string[]) => void;
};

const StyledInput = styled(M.TextInput)`
  width: 100%;

  @media only screen and (min-width: 1056px) and (max-width: 1322px) {
    width: calc(50% - 0.75rem);
  }

  @media only screen and (min-width: 1323px) {
    width: calc(33.3% - 0.75rem);
  }
`;

export const AdverseActionEmailCC: React.FC<props> = ({
  setting,
  setSettings,
}) => {
  const { t } = useTranslation();
  const newUserPreferencesEnabled =
    useFlag(USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY)?.variantKey === 'on' ||
    false;

  const toggleSetting = (current: boolean | string[]) => (_: undefined) => {
    onChange()({
      target: { value: current ? false : [] },
    });
  };

  const onChange = useCallback(
    () => (event: any) => {
      const key = 'adverseActionEmailCC';
      event.target.value === false
        ? setSettings(key, false)
        : setSettings(key, [event.target.value]);
    },
    [setSettings],
  );

  return newUserPreferencesEnabled ? (
    <div key='adverseActionEmailCCInputGroup' style={{ marginTop: '0.5rem' }}>
      <InputLabel>
        {t('accountSettings.adverseActionEmailCC.heading')}
      </InputLabel>
      <p>{t('accountSettings.adverseActionEmailCC.message')}</p>
      <M.Checkbox
        id='adverseActionEmailCCCheckbox'
        labelText={t('accountSettings.adverseActionEmailCC.checkbox.label')}
        checked={setting}
        onClick={toggleSetting(setting)}
      />
      {setting && (
        <StyledInput
          id='adverseActionEmailCCInput'
          placeholder={t(
            'accountSettings.adverseActionEmailCC.emailInput.placeholder',
          )}
          value={setting}
          type='email'
          onChange={onChange()}
        />
      )}
    </div>
  ) : (
    // TODO Remove after delivery the user preference improvements
    // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
    <InputGroup key='adverseActionEmailCCInputGroup'>
      <InputLabel>
        {t('accountSettings.adverseActionEmailCC.heading')}
      </InputLabel>
      <p>{t('accountSettings.adverseActionEmailCC.message')}</p>
      <M.Checkbox
        id='adverseActionEmailCCCheckbox'
        labelText={t('accountSettings.adverseActionEmailCC.checkbox.label')}
        checked={setting}
        onClick={toggleSetting(setting)}
      />
      {setting && (
        <M.TextInput
          id='adverseActionEmailCCInput'
          placeholder={t(
            'accountSettings.adverseActionEmailCC.emailInput.placeholder',
          )}
          value={setting}
          type='email'
          onChange={onChange()}
        />
      )}
    </InputGroup>
  );
};

export default AdverseActionEmailCC;
