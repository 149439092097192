export enum Type {
  CUSTOM = 'CUSTOM',
  COUNT = 'COUNT',
}

const countRuleChanges = ['name', 'decision'];
const customRuleChanges = countRuleChanges.concat('item_visibility');

export const TypeFields: Map<Type, string[]> = new Map<Type, string[]>([
  [Type.CUSTOM, customRuleChanges],
  [Type.COUNT, countRuleChanges],
]);
