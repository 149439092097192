import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';

type Props = {
  onCancel: () => void;
  onSave: () => void;
};

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Buttons: React.FC<Props> = ({ onCancel, onSave }) => {
  return (
    <ButtonsDiv data-testid='assess-entity-description-buttons'>
      <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
      <Button kind='primary' trans='verbs.save' onClick={onSave} />
    </ButtonsDiv>
  );
};

export default Buttons;
