/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { findEnvVar } from '@dashboard-experience/utils';
import UIContext from 'context/UI';
import { ENV } from 'Constants';
import { useScript } from 'hooks';

const oneTrustUrl = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
const key = findEnvVar('ONE_TRUST_KEY');

const OneTrustEmbed: React.FC = () => {
  const { isIframe } = useContext(UIContext);

  // Only embed in Production Standalone (and make sure the Key is available).
  const shouldLoad = !isIframe && ENV === 'production' && !!key;

  // Setting the URL to a blank string will avoid injecting the script
  useScript(shouldLoad ? oneTrustUrl : '', {
    'data-domain-script': key,
  });

  // Since useScript does the DOM injection of the script, there's nothing to actually render or return here.
  return null;
};

export default OneTrustEmbed;
