import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { SegmentsTableBody, SegmentsEmptyTableBody } from 'components/Segments';
import {
  TYPE_BRAND,
  TYPE_PAM,
  TYPE_PKG,
  TYPE_USER,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
} from '../../Constants';
import { ParentContext } from '../../api/segments';

type Props = {
  virtual: any;
  onSelect: Function;
  selectedSegments: string[];
  parentContext: ParentContext;
};

const SegmentsVirtualTableList: React.FC<Props> = ({
  virtual,
  onSelect,
  selectedSegments,
  parentContext,
}) => {
  const titleOptions = {
    [TYPE_PAM]: 'PAM',
    [TYPE_PKG]: '# of Packages',
    [TYPE_USER]: '',
    [TYPE_BRAND]: 'Brand',
    [TYPE_MVR_RULE]: '# of MVR Rules',
    [TYPE_APP_INSTANCE_CUSTOMER]: '# of Application Instances',
  };

  return (
    <div style={virtual.style}>
      <M.Table style={{ width: '100%' }}>
        <M.TableHead className='border-top'>
          <M.TableRow>
            <M.TableHeader>Node Type</M.TableHeader>
            <M.TableHeader>Node Name</M.TableHeader>
            <M.TableHeader>{titleOptions[parentContext]}</M.TableHeader>
            <M.TableHeader>Node ID</M.TableHeader>
          </M.TableRow>
        </M.TableHead>
        {virtual.items.length !== 0 ? (
          <SegmentsTableBody
            items={virtual.items}
            onSelect={onSelect}
            selectedSegments={selectedSegments}
            parentContext={parentContext}
          />
        ) : (
          <SegmentsEmptyTableBody />
        )}
      </M.Table>
    </div>
  );
};

export default SegmentsVirtualTableList;
