import React, { useCallback } from 'react';
import styled from 'styled-components';
/*
  The use of react-virtual-list should be eventually deprecated since it's not maintained and
  it has an issue with Webpack 5: https://github.com/developerdizzle/react-virtual-list/pull/87

  More context on how this was solved:
  https://gitlab.checkrhq.net/dashboard-experience/customer/-/merge_requests/2666
*/
import VirtualList from 'react-virtual-list';
import { SegmentsVirtualTableList } from 'components/Segments';
import { Segment, ParentContext } from '../../api/segments';

const TableContainer = styled.div`
  margin-top: 1rem;
  height: 300px;
  overflow: auto;
`;

type Props = {
  filteredSegments: Segment[];
  selectedSegments: string[];
  updateSelectedSegments: Function;
  initialSelectedSegments: string[];
  updateUnselectedSegments: Function;
  parentContext: ParentContext;
};

const SegmentsTable: React.FC<Props> = ({
  filteredSegments,
  selectedSegments,
  updateSelectedSegments,
  initialSelectedSegments,
  updateUnselectedSegments,
  parentContext,
}) => {
  const containerRef = React.useRef<HTMLInputElement>(null);

  const onSelect = useCallback(
    (segmentId: string) => {
      if (initialSelectedSegments.includes(segmentId)) {
        updateUnselectedSegments(segmentId);
      }
      updateSelectedSegments(segmentId);
    },
    [initialSelectedSegments, updateSelectedSegments, updateUnselectedSegments],
  );

  const VirtualTable = VirtualList({ container: containerRef.current })(
    SegmentsVirtualTableList,
  );

  return (
    <TableContainer ref={containerRef}>
      {containerRef.current && (
        <VirtualTable
          items={filteredSegments}
          itemBuffer={10}
          itemHeight={40}
          onSelect={onSelect}
          selectedSegments={selectedSegments}
          parentContext={parentContext}
        />
      )}
    </TableContainer>
  );
};

export default SegmentsTable;
