import { GenericObject } from '@dashboard-experience/utils';

const ASSESSMENT_MAP: GenericObject = {
  eligible: {
    decision: 'eligible',
  },
  escalated: {
    decision: 'escalated',
  },
  review: {
    decision: 'review',
  },
  clear: {
    decision: 'clear',
  },
  processing: {
    decision: 'none_status_started',
    status: 'assess-complete',
  },
  clear_legacy: {
    decision: null,
    status: 'clear',
  },
  consider_legacy: {
    decision: null,
    status: 'consider',
  },
  fail: {
    decision: 'Fail',
  },
  pass: {
    decision: 'Pass',
  },
  decisional: {
    decision: 'Needs Review',
  },
};

export default ASSESSMENT_MAP;
