import { accountHasPermission } from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import { useAdverseActions } from 'modules/adjudication/api';
import React from 'react';
import { Report } from 'types';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const { report } = useGetReport() as unknown as Report;
  const canPause = accountHasPermission(report, 'adverse_action_pause');
  const { adverseAction } = useAdverseActions(report?.id);
  const hasPauseData = adverseAction?.pause_data;

  return canPause && hasPauseData ? <>{children}</> : null;
};

export default Guard;
