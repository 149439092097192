import React from 'react';
import * as Dispatch from './dispatch';
import * as State from './state';

export type Props = {
  value: {
    dispatch: Dispatch.Value;
    state: State.Value;
  };
};

export const Provider: React.FC<Props> = ({ children, value }) => {
  return (
    <Dispatch.Provider value={value.dispatch}>
      <State.Provider value={value.state}>{children}</State.Provider>
    </Dispatch.Provider>
  );
};

export default Provider;
