import React, { FC, useMemo } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { ICohort } from './types';
import DotTag from './DotTag';
import EligibleEmployees from './EligibleEmployees';

interface Props {
  withinPage?: boolean;
  cohort: ICohort;
}

const Cohort: FC<Props> = ({
  withinPage = false,
  cohort: {
    name,
    randomTestingPolls,
    eligibleEmployeesCount,
    updatedAt,
    drugScreening,
    alcoholScreening,
    dotComplianceAgency,
    latestPollDate,
    id,
  },
}) => {
  const lastSelectionDate = useMemo(() => {
    if (latestPollDate) return dateWithTimezone(latestPollDate);
    return '-';
  }, [latestPollDate]);

  return (
    <Container data-testid='randomizer-cohort-container-summary-header'>
      {!withinPage && (
        <Title data-testid='cohort-header'>
          {name}
          {dotComplianceAgency && <DotTag />}
        </Title>
      )}
      <M.GridRow>
        <M.GridCol>
          <Label>Last selection date</Label>
          <Text>{lastSelectionDate}</Text>
        </M.GridCol>
        <M.GridCol>
          <Label>Employees in pool</Label>
          <EligibleEmployees
            id={id}
            withinPage={withinPage}
            eligibleEmployeesCount={eligibleEmployeesCount}
          />
          <Text>{`Last updated: ${dateWithTimezone(updatedAt)}`}</Text>
        </M.GridCol>
        {drugScreening && (
          <M.GridCol>
            <Label>Drug screening</Label>
            <Text>{drugScreening?.replace(/_/g, ' ')}</Text>
          </M.GridCol>
        )}
        {!drugScreening && (
          <NonActiveGridCol>
            <Label>Drug screening</Label>
            <Text>-</Text>
          </NonActiveGridCol>
        )}
        {alcoholScreening && (
          <M.GridCol>
            <Label>Alcohol screening</Label>
            <Text>{alcoholScreening?.replace(/_/g, ' ')}</Text>
          </M.GridCol>
        )}
        {!alcoholScreening && (
          <NonActiveGridCol>
            <Label>Alcohol screening</Label>
            <Text>-</Text>
          </NonActiveGridCol>
        )}
      </M.GridRow>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #e1e6eb;
  border-left: 4px solid ${colors.uiNavy800};
  border-radius: 3px;
  width: 100%;
  margin: 1em 0;
  padding: 1em 0;
`;

const Title = styled.h5`
  border-bottom: 1px solid #e1e6eb;
  margin: 0 1em;
  padding-bottom: 1em;
`;

const Label = styled.p`
  font-weight: 600;
  margin-bottom: 5px !important;
`;

const Text = styled.p`
  margin-bottom: 5px !important;
`;

const NonActiveGridCol = styled(M.GridCol)`
  p {
    color: ${colors.uiGrey500} !important;
  }
`;

export default Cohort;
