import React from 'react';
import { GenericObject } from 'types';
import {
  DocumentButton,
  DocumentContainer,
  DocumentHeader,
  DocumentImage,
} from './PLVStyledComponents';

type Props = {
  documents: GenericObject[];
};

// Content Types which can be displayed inline as images by browsers.
const PREVIEWABLE_CONTENT_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/heic',
  'image/png',
  'image/tiff',
  'image/bmp',
];

const ResultDocuments: React.FC<Props> = ({ documents }) => {
  return documents && documents.length ? (
    <>
      <DocumentHeader data-testid='screenings-professional-license-verification-result-documents-header'>
        Result Documents
      </DocumentHeader>
      {documents.map((document: GenericObject) => {
        return (
          <DocumentContainer key={document.id}>
            {PREVIEWABLE_CONTENT_TYPES.includes(document.content_type) ? (
              <a href={document.download_uri} target='_blank' rel='noreferrer'>
                <DocumentImage
                  src={document.download_uri}
                  data-testid='screenings-professional-license-verification-result-document-image'
                />
              </a>
            ) : null}

            <DocumentButton
              kind='secondary'
              size='sm'
              href={document.download_uri}
              target='_blank'
              rel='noreferrer'
              data-testid='screenings-professional-license-verification-result-document-button'
            >
              View Result Document
            </DocumentButton>
          </DocumentContainer>
        );
      })}
    </>
  ) : null;
};

export default ResultDocuments;
