import React from 'react';
import { BrandContainer } from 'containers';
import { RouteComponentProps } from 'react-router-dom';

type RouterProps = { brandId: string };

type BrandPageProps = RouteComponentProps<RouterProps>;

const BrandPage: React.FC<BrandPageProps> = ({ match }) => {
  const { brandId } = match.params;
  return <BrandContainer brandId={brandId} />;
};

export default BrandPage;
