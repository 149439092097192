import React, { useCallback, useEffect } from 'react';
import { Subtype } from 'modules/adjudication/data';
import SubtypeSelection from 'modules/adjudication/ui/subtype-selection';
import { useTranslation } from 'react-i18next';
import { useModalState, useDispatch } from '../../context';
import Warnings from '../warnings';

type Props = {};

const Subtypes: React.FC<Props> = () => {
  const currentScreen = useModalState('currentScreen');
  const nextScreen = useModalState('nextScreen');
  const state = useModalState('state');
  const dispatch = useDispatch();
  const { t } = useTranslation('adjudication');

  const onChange = useCallback(
    (subtype) => {
      dispatch({ type: 'SET_SUBTYPE', payload: subtype });
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch({
      type: 'SET_NAVIGATION',
      payload: { next: { onClick: nextScreen } },
    });
    // nextScreen is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div style={{ padding: '30px' }}>
      <Warnings currentScreen={currentScreen} />
      <div style={{ paddingBottom: '1rem' }}>
        {t('adjudication:report.subtype.select')}
      </div>
      <SubtypeSelection
        onChange={onChange}
        subtypeKind={Subtype.AdverseAction}
        value={state.subtype.name}
      />
    </div>
  );
};

export default Subtypes;
