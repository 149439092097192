import { useFlag } from '@dashboard-experience/react-flagr';
import {
  accountHasPermission,
  hasPermission,
  getStatusTypeForReport,
  StatusTypes,
  getReportDecision,
  getReportStatus,
  Report,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { useCandidate } from 'providers/Candidate';
import { useGetDashboardCandidateReports } from 'api/candidate/hooks';
import { ENABLE_ARCHIVE_REPORTS } from 'Constants';
import { useUser } from 'context/CurrentUser';
import { Candidate } from 'types';
import { Invitation } from 'types/report/invitation';

export const useReportsAndInvitations = () => {
  const candidate = useCandidate();
  const { reports: candidateReports, isLoading } =
    useGetDashboardCandidateReports(candidate.id);
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  const terminalInvitationStatuses = ['cancelled', 'completed', 'expired'];

  const invitations = candidate?.invitations?.filter(
    invitation => !terminalInvitationStatuses.includes(invitation.status),
  );

  const terminalInvitations =
    candidate?.invitations?.filter(invitation =>
      terminalInvitationStatuses.includes(invitation.status),
    ) || [];

  const reports = [
    ...(candidateReports || []).concat(...(invitations || [])),
  ].reverse();

  if (!archiveReportFlagEnabled)
    return {
      reportsAndInvitations: reports,
      reportsLoading: isLoading,
      terminalInvitations,
    };

  const sortedReports = reports.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.archived === b.archived ? 0 : a.archived ? 1 : -1,
  );

  return {
    reportsAndInvitations: sortedReports,
    reportsLoading: isLoading,
    terminalInvitations,
  };
};

// TODO - Should this be using the candidate's account info to determine permissions? (Migrated 1:1)
export const useCanOrderNewReport = () => {
  const currentUser = useUser();
  return (
    hasPermission(currentUser, 'create_manual_orders') &&
    accountHasPermission(currentUser, 'allow_dashboard_report_ordering')
  );
};

export const getCandidateName = (candidate: Candidate) => {
  if (!candidate.first_name) return candidate.email || 'Unknown candidate';
  const name = [candidate.first_name];
  if (candidate.middle_name) name.push(candidate.middle_name);
  if (candidate.last_name) name.push(candidate.last_name);
  return name.join(' ');
};

// TODO - this should happen in Mastodon
export const useReportStatusDecision = (report?: Report) => {
  const currentUser = useUser();

  if (!report) {
    return null;
  }

  const statusType = getStatusTypeForReport(report, currentUser);

  const status =
    StatusTypes.Assess === statusType
      ? // @ts-ignore Current user should be optional in getReportDecision
        getReportDecision(report) || getReportStatus(report, statusType)
      : getReportStatus(report, statusType);
  return status;
};

export const useReportInvitationStatusDecision = (
  report?: Report | Invitation,
) => {
  const status = useReportStatusDecision(report);

  if (!report) {
    return '';
  }

  // @ts-ignore
  if (report.object === 'invitation' && status === ReportStatuses.PENDING) {
    return ReportStatuses.INVITATION_SENT;
  }
  return status || '';
};
