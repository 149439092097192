import React, { useCallback } from 'react';
import { useDispatch, useState } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/premade';
import View from './View';
import { useNamespace } from '../../router/context';

type Props = {
  disabled?: boolean;
};

export const StandardRulesContainer: React.FC<Props> = ({ disabled }) => {
  const namespace = useNamespace();
  const state = useState();
  const rules = state.rules?.premade.table || {};
  const dispatch = useDispatch();
  const handleRuleSelected = useCallback(
    (rule, isSelected) => {
      if (isSelected) {
        dispatch(Actions.Insert.create(rule));
      } else {
        dispatch(Actions.Remove.create(rule));
      }
    },
    [dispatch],
  );

  const handleRuleUpdated = useCallback(
    (rule, itemVisibility) => {
      dispatch(
        Actions.Update.create({ ...rule, item_visibility: itemVisibility }),
      );
    },
    [dispatch],
  );

  return (
    <div data-testid='assess-rules-standard-container'>
      <View
        disabled={disabled}
        namespace={namespace}
        rules={rules}
        onRuleClicked={handleRuleSelected}
        onRuleUpdated={handleRuleUpdated}
      />
    </div>
  );
};

export default StandardRulesContainer;
