import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from 'actions';
import {
  deleteApplicationInstance,
  getApplicationInstances,
  getApplicationInstance,
  Instance,
} from './actions';

export const useGetAppInstances = () => {
  const key: AnyQueryKey = ['account/integrations'];
  const request = () => getApplicationInstances();

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const useGetApplicationInstance = (id: string) => {
  const key: AnyQueryKey = ['account/application_instances/', { id }];
  const request = () => getApplicationInstance(id);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const useDeleteApplicationInstance = () => {
  const dispatch = useDispatch();
  const request = (params: Instance) => deleteApplicationInstance(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries('account/integrations');
      dispatch(toastSuccess('Instance Deleted'));
    },
    onError: error => {
      dispatch(toastError('Error deleting instance', error.message));
    },
  });

  return {
    call,
    result,
  };
};
