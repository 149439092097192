import React from 'react';
import Button from 'components/Button';

type Props = {
  onCancel: () => void;
  onUpload: () => void;
  disableUpload: boolean;
};

export const Actions: React.FC<Props> = ({
  onCancel,
  onUpload,
  disableUpload,
}) => {
  return (
    <>
      <Button
        kind='secondary'
        trans='verbs.cancel'
        onClick={onCancel}
        data-testid='account-hierarchy-csv-modal-cancel'
      />
      <Button
        disabled={disableUpload}
        kind='primary'
        trans='verbs.upload'
        onClick={onUpload}
        data-testid='account-hierarchy-csv-modal-upload'
      />
    </>
  );
};

export default Actions;
