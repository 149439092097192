import React, { useContext } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { RootRoute } from 'modules/assess/ui/router/context';
import Instance from './instance';

export type Params = {};

const Router: React.FC = () => {
  const match = useRouteMatch();
  const root = useContext(RootRoute);
  const { path: base } = match;

  return (
    <Switch>
      <Route path={`${base}/:ruleset`}>
        <Instance />
      </Route>
      <Redirect to={root} />
    </Switch>
  );
};

export default Router;
