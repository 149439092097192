import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const MContainerRowRightAlign = styled(M.Container.Row)`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  gap: 0;
`;

export default MContainerRowRightAlign;
