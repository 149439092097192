import { Report } from 'types';
import { TaskType } from 'modules/case-management/types';
import { ReportStatuses } from '@dashboard-experience/utils';

type GetFieldParams = {
  report: Report;
  task?: TaskType;
};

const getFields = ({ report, task }: GetFieldParams) => {
  const fields = [
    {
      getFieldName: () => 'Report status',
      fieldKey: 'status',
    },
  ];

  if (report.status !== ReportStatuses.EXPIRED)
    fields.push({
      getFieldName: () => 'Adjudication',
      fieldKey: 'adjudication',
    });

  if (task)
    fields.push({
      getFieldName: () => 'Assigned to',
      fieldKey: 'assigned_to',
    });

  return [
    ...fields,
    {
      getFieldName: () => 'Tags',
      fieldKey: 'tags',
    },
  ];
};

export default getFields;
