import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { useTranslation } from 'react-i18next';

const InputTextValues = ({ index }: { index: number }) => {
  const { conditions, updateConditionByIndex } = useConditions();
  const { t } = useTranslation();
  const condition = conditions[index];
  const value = condition.value as string[];
  const label = t('assess:rules.custom.conditions.regex.input.label');

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      updateConditionByIndex(index, { value: value.split('\n') });
    },
    [index],
  );

  const LabelStyle = styled.div`
    margin-top: 1em;
    margin-bottom: 1em;
  `;

  return (
    <>
      <LabelStyle>{label}</LabelStyle>
      <M.TextArea
        data-testid='assess-common-rule-input-text-value'
        defaultValue={value?.join('\n') || ''}
        onChange={handleChange}
        light
      />
    </>
  );
};

export default InputTextValues;
