import React, { useCallback } from 'react';
import { Callback } from '@dashboard-experience/utils';
import { AnalyticsEvent, useTrackEvent } from 'utils';
import { LinklessElement, ListElement, StyledLink, TabName } from './styles';
import { NavIcon } from './nav-icon';

type ContentProps = {
  navCollapsed: boolean;
  text: string;
  tabName?: string;
};

type ElementProps = {
  url?: string;
  isActive?: boolean;
  onClick?: Callback;
  className?: string;
  trackingEventName?: AnalyticsEvent;
} & ContentProps;

const Contents: React.FC<ContentProps> = ({ navCollapsed, text, tabName }) => {
  return (
    <>
      <NavIcon tabName={tabName || ''} />
      <TabName className={navCollapsed ? 'collapsed' : ''}>{text}</TabName>
    </>
  );
};

/** Creates a Nav element based on the basic props passed directly to it */
const NavElement: React.FC<ElementProps> = ({
  url,
  isActive = false,
  navCollapsed,
  text,
  onClick,
  tabName,
  className,
  trackingEventName,
}) => {
  const trackEvent = useTrackEvent();
  const testId = ['nav_link', tabName, 'side-nav'].filter(Boolean).join('-');

  const commonElementProps = {
    className: navCollapsed ? 'collapsed' : 'expanded',
    id: `${tabName}_left`, // This ID is required for Pendo elements to latch onto
    ...(tabName ? { 'data-pendo-id': `nav-${tabName}` } : {}),
    'data-testid': testId,
  };

  const trackingHandler = useCallback(() => {
    // If this tab has a defined tracking event associated with it, then trigger that event onclick
    if (trackingEventName) {
      trackEvent(trackingEventName);
    }
  }, [trackEvent, trackingEventName]);

  return (
    <ListElement
      className={`${isActive ? 'active' : ''} ${className || ''}`}
      onClick={onClick}
      title={navCollapsed ? text : ''}
    >
      {url ? (
        <StyledLink {...commonElementProps} to={url} onClick={trackingHandler}>
          <Contents navCollapsed={navCollapsed} text={text} tabName={tabName} />
        </StyledLink>
      ) : (
        // e.g. the Collapse/Expand button
        <LinklessElement {...commonElementProps}>
          <Contents navCollapsed={navCollapsed} text={text} tabName={tabName} />
        </LinklessElement>
      )}
    </ListElement>
  );
};

export default NavElement;
