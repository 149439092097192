import { ID } from 'modules/id';
import React from 'react';
import { useMVRCustomCategories } from 'modules/assess/ui/hooks';
import { Container, TextSections } from '../common';
import CategorySet from './category-set';
import CategorySetSelect from './category-set-select';

export type Props = {
  disabled?: boolean;
  instance?: ID;
  version?: ID;
};

export const Content: React.FC<Props> = ({ disabled, instance, version }) => {
  const assessMVRCustomCategories = useMVRCustomCategories();

  return (
    <Container data-testid='assess-rules-categories-content'>
      {assessMVRCustomCategories && (
        <CategorySetSelect disabled={disabled} version={version} />
      )}
      <TextSections tkey='rules.categories.description' />
      <CategorySet disabled={disabled} instance={instance} version={version} />
    </Container>
  );
};

export default Content;
