import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@dashboard-experience/mastodon';
import { postMessageToDashboard } from 'utils';
import { Trans, useTranslation } from 'react-i18next';
import { Incident, IncidentComponent } from './types';

const StyledBanner = styled.div`
  background-color: ${colors.uiYellow300};
  color: ${colors.uiTextPrimaryLight};
  padding: 0.5rem 1.5rem;
  position: relative;
  text-align: center;
  @media print {
    display: none;
  }

  p {
    font-family: ${fonts.legacy}, ${fonts.ui};
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 12px !important;
    }
  }
`;

type Props = {
  incidents: Incident[];
};

enum statuses {
  'major_outage' = 'major outage',
  'partial_outage' = 'partial outage',
  'degraded_performance' = 'degraded performance',
  'operational' = 'operational',
  'under_maintenance' = 'under maintenance',
}

/**
 * Takes in an array of components, and sends back the most relevant one
 * @param components - Array of components affected by an outage (i.e. "Client Dashboard", "API", "Webhooks", etc.)
 * @returns The single most important component from the bunch
 */
export const getRelevantComponent = (components: IncidentComponent[]) => {
  const sorted = components.sort((left, right) => {
    // If they have differing severity levels, then prioritize major outages
    if (left.status !== right.status) {
      if (left.status === 'major_outage') {
        return -1;
      }
      if (right.status === 'major_outage') {
        return 1;
      }
    }

    // If they have different affected components, then prioritize Dashboard incidents
    if (left.name !== right.name) {
      if (left.name === 'Client Dashboard') {
        return -1;
      }
      if (right.name === 'Client Dashboard') {
        return 1;
      }
    }

    // If severity and component name weren't a factor, then just leave it as is.
    return 0;
  });

  return sorted[0]; // After sorting the array, the 0th element is the one that's highest-priority
};

const generateMessage = (incidents: Incident[], t: Function) => {
  // For multiple incidents, use a more broad message
  if (incidents.length > 1) {
    return t('multipleIncidents');
  }

  const [incident] = incidents;

  // For a single incident, there can be multiple affected components (i.e. Dashboard, API, Webhooks).
  // Use this function to find the one that matters to us most.
  const component = getRelevantComponent(incident.components);

  const { name, status } = component;
  const timestring = new Date(incident.started_at).toLocaleTimeString(); // This will be in the "1:57:34 PM" format

  // Special wording for maintenance
  if (status === 'under_maintenance') {
    // TODO: Design also wants expected duration to be shown in this string.
    // Will need to look at the data for a genuine maintenance event to see what's available there, that might be a whole separate API call.
    return t('maintenance', {
      time: timestring,
      component: name,
    });
  }

  return t('singleIncident', {
    time: timestring,
    component: name,
    status: statuses[status],
  });
};

const ApiStatusBanner: React.FC<Props> = ({ incidents }) => {
  const hasIncident = incidents.length;
  const { t } = useTranslation(undefined, { keyPrefix: 'banners.apiStatus' });

  if (!hasIncident) {
    return null;
  }

  // On dashboard-internal, the status banner is a completely separate iframe.
  // This sends the message to force that iframe to actually show.
  postMessageToDashboard({
    messageId: 'showApiStatusBanner',
  });

  return (
    <StyledBanner>
      <p data-testid='incident-banner'>
        {generateMessage(incidents, t)} <br />
        <Trans t={t} i18nKey='statusPageLink'>
          <span>View our </span>
          <a href='https://checkrstatus.com/' target='_blank' rel='noreferrer'>
            status page
          </a>
          <span> for a list of updates and affected areas.</span>
        </Trans>
      </p>
    </StyledBanner>
  );
};

export default ApiStatusBanner;
