import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Invite } from '../components/Packages/OrderBackgroundCheck/SharedItems';
import { actionTypes } from '../components/Packages/OrderBackgroundCheck/Context';
import {
  validateEmail,
  validateFirstName,
  validatePhoneNumber,
} from '../components/AddScreenings/shared/ModalComponents';

const TAB_COMMA_REGEX = /\t|,/;

type Browsers = 'chrome' | 'edge' | 'firefox' | 'opera' | 'safari' | 'unknown';

const userBrowser: () => Browsers = () => {
  if (
    (navigator.userAgent.indexOf('Opera') !== -1 ||
      navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'opera';
  }

  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'edge';
  }

  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'chrome';
  }

  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'safari';
  }

  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'firefox';
  }

  return 'unknown';
};

const isSingleValue = (clipboardData: string) => {
  return (
    !(clipboardData.includes('\t') || clipboardData.includes(',')) &&
    !(clipboardData.includes('\r\n') || clipboardData.includes('\n'))
  );
};

const handleSinglePaste = (
  event: any,
  line: string,
  state: any,
  dispatch: any,
) => {
  const { id, name } = event.target;
  const index = Number(id.split('-').pop());
  const stateCloned = cloneDeep(state);

  stateCloned.invites[index][name] = {
    value: line,
    isValid: isValid(name, line),
  };

  dispatch({
    type: actionTypes.ADD_INVITES,
    payload: [...filteredOldInvites(stateCloned)],
  });
};

const isEmailsOnly = (clipboardData: string) => {
  return (
    !clipboardData.includes('\t') &&
    !clipboardData.includes(',') &&
    (clipboardData.includes('\r\n') || clipboardData.includes('\n'))
  );
};

const handleEmailsOnly = (lines: string[], state: any, dispatch: any) => {
  const emails = lines.map(line => ({
    id: uuidv4(),
    email: {
      value: line,
      isValid: validateEmail(line),
    },
    firstName: {
      value: '',
      isValid: true,
    },
    phoneNumber: {
      value: '',
      isValid: true,
    },
  }));

  dispatch({
    type: actionTypes.ADD_INVITES,
    payload: [...filteredOldInvites(state), ...emails],
  });
};

const isMultipleValues = (clipboardData: string) => {
  return clipboardData.includes('\t') || clipboardData.includes(',');
};

const checkValueType = (value: string) => {
  if (value.includes('email')) {
    return 'email';
  }

  if (value.includes('phone')) {
    return 'phoneNumber';
  }

  // can include fullName, full_name, firstName, first_name, candidate_name
  if (
    value.includes('name') &&
    !(value.includes('last') || value.includes('middle'))
  ) {
    return 'firstName';
  }

  return '';
};

const getFieldOrder = (headerValues: string[]) => {
  const fieldOrder: string[] = [];
  headerValues.forEach(value => {
    const field = checkValueType(value);
    fieldOrder.push(field);
  });

  return fieldOrder;
};

const organizeValues = (lines: string[]) => {
  const header = lines[0].toLocaleLowerCase();
  const values: Record<string, string>[] = [];
  const hasHeader =
    header?.includes('email') &&
    header?.includes('name') &&
    header?.includes('phone');
  // if their is a header row, handle based on header row
  if (hasHeader) {
    lines.shift(); // remove header
    const headerValues = header?.split(TAB_COMMA_REGEX);
    const fieldOrder = getFieldOrder(headerValues);
    for (const line of lines) {
      const lineValues = line.split(TAB_COMMA_REGEX).map(s => s.trim());
      const value: { [key: string]: string } = {};

      lineValues.forEach((val, index) => {
        if (fieldOrder[index]) {
          value[fieldOrder[index]] = val;
        }
      });

      values.push(value);
    }

    return values;
  }

  // else handle as normal assuming email, name, phone order
  for (const line of lines) {
    const [email, firstName, phoneNumber] = line
      .split(TAB_COMMA_REGEX)
      .map(s => s.trim());
    values.push({
      email,
      firstName,
      phoneNumber,
    });
  }

  return values;
};

const handleMultipleValues = (lines: string[], state: any, dispatch: any) => {
  const invites = [];
  const organizedValues = organizeValues(lines);

  if (organizedValues) {
    for (const values of organizedValues) {
      const { email, firstName, phoneNumber } = values;
      const firstNameVal = firstName.split(' ')[0];

      if (email) {
        invites.push({
          id: uuidv4(),
          email: {
            value: email,
            isValid: validateEmail(email),
          },
          firstName: {
            value: firstNameVal,
            isValid: !firstNameVal || validateFirstName(firstNameVal), // optional fields are valid when blank
          },
          phoneNumber: {
            value: phoneNumber,
            isValid: !phoneNumber || validatePhoneNumber(phoneNumber), // optional fields are valid when blank
          },
        });
      }
    }

    dispatch({
      type: actionTypes.ADD_INVITES,
      payload: [...filteredOldInvites(state), ...invites],
    });
  }
};

const isValid = (name: string, value: string) => {
  let isValid = false;
  if (name === 'email') {
    isValid = validateEmail(value);
  } else if (name === 'firstName') {
    isValid = validateFirstName(value);
  } else if (name === 'phoneNumber') {
    isValid = validatePhoneNumber(value);
  } else {
    isValid = false;
  }

  return isValid;
};

const filteredOldInvites = ({ invites }: { invites: Invite[] }) => {
  return invites.filter(
    (invite: Invite) =>
      invite.email.value ||
      invite.firstName?.value ||
      invite.phoneNumber?.value,
  );
};

export default function useHandlePasteForInviteForm(state: any, dispatch: any) {
  const handlePaste = useCallback(
    event => {
      const browserName = userBrowser();
      if (browserName === 'firefox' || browserName === 'safari') {
        // eslint-disable-next-line no-console
        return console.log(
          `Unfortunately, this feature is not permitted in ${browserName} due to browser restrictions. Please paste as single values.`,
        );
      }

      return navigator.permissions
        .query({ name: 'clipboard-read' as PermissionName })
        .then(async result => {
          // if user provided permission to clipboard
          event.preventDefault();
          if (result.state === 'granted' || result.state === 'prompt') {
            const clipboardData = await window.navigator.clipboard.readText();
            const lines = clipboardData.split(/\r\n|\n/).filter(line => line);
            if (!lines.length) return;

            // if there is one one value in clipboard, paste as normal
            if (isSingleValue(clipboardData)) {
              handleSinglePaste(event, lines[0], state, dispatch);
            }

            // if multiple lines, but only email
            if (isEmailsOnly(clipboardData)) {
              handleEmailsOnly(lines, state, dispatch);
            }

            if (isMultipleValues(clipboardData)) {
              handleMultipleValues(lines, state, dispatch);
            }
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e instanceof Error ? e.message : `error: ${e}`);
        });
    },
    [state, dispatch],
  );

  return handlePaste;
}
