import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({ onClick, disabled = false }) => {
  const { t } = useTranslation('assess:v2');
  const label = t('home.settings.packages.title');

  return (
    <M.Button
      data-testid='assess-v2-settings-packages-link'
      onClick={onClick}
      kind='tertiary'
      disabled={disabled}
    >
      {label}
    </M.Button>
  );
};

export default Button;
