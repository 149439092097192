import React from 'react';
import { ArrestRecord as Record, ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { M } from '@dashboard-experience/mastodon';
import {
  ReportStatuses,
  StatusTypes,
  getScreeningStatus,
} from '@dashboard-experience/utils';

import ArrestRecord from './ArrestRecord';

const ArrestSearch: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { arrest_search: arrestSearch } = report;

  if (!arrestSearch) return null;

  const legacyStatus = getScreeningStatus(arrestSearch, StatusTypes.Legacy);

  return (
    <M.Screening
      title='Arrest Search'
      screening={arrestSearch}
      statusType={reportStatusType}
      id={SCREENING_TITLES.arrest_search.id}
    >
      {legacyStatus === ReportStatuses.CONSIDER &&
        arrestSearch?.records?.map((record: Record) => (
          <ArrestRecord key={record.id} record={record} report={report} />
        ))}
    </M.Screening>
  );
};

export default ArrestSearch;
