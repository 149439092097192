import React from 'react';
import styled from 'styled-components';
import { Report } from 'types';
import Tags from './tags';

// TODO: Update styles when the new reskin Mastodon tags component is ready
const Container = styled.div`
  .cds--tag__label {
    white-space: unset;
  }
  .react-tagsinput {
    padding-top: 0;
  }
`;

type Props = { report: Report };

const TagsContainer: React.FC<Props> = ({ report }) => {
  return (
    <Container data-testid='report-information-tags-container'>
      <Tags report={report} />
    </Container>
  );
};

export default TagsContainer;
