import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

interface Props {
  handleCreateNewCohort: () => void;
  setHeaderVisibility: (value: boolean) => void;
}

const FlexContainer = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.5rem;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`;

const FlexContainerWithBorder = styled.div`
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.6rem;
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 4px;
  max-width: 1068px;
  margin: 1em auto;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  height: 1px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 26px;
`;

const FlexGrid = styled.div`
  display: flex;
  flex: 1;
  gap: 2rem;
  margin-bottom: 2.1rem;
  align-self: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const FlexGridSecondPage = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-self: flex-start;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const FlexCard = styled.div`
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;
  display: flex;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 264px;
  }
`;

const FlexSection = styled.div`
  display: flex;
  margin: 0 auto;
  align-content: flex-start;
  flex-direction: column;
  margin-bottom: 1rem !important;
`;

const IconWrapGreen = styled.div`
  background-color: ${colors.uiGreen100};
  border-radius: 0.5rem;
  color: ${colors.uiGreen500};
  height: 2.5rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  width: 2.5rem;
`;

const IconWrapGray = styled.div`
  color: ${colors.uiTextPrimaryLight};
  height: 3.2rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  width: 3.2rem;
`;

const CardWrap = styled.div`
  font-size: 1rem !important;
  line-height: 1.5rem;
  margin-left: 1.5rem;
  text-align: left;

  @media screen and (min-width: 1024px) {
    margin-left: 0;
    max-width: 16rem;
  }
`;

const CardHeading = styled.h5`
  color: ${colors.uiTextPrimaryLight};
  font-size: 1.125rem;
  line-height: 1;
`;

const CardBody = styled.p`
  color: ${colors.uiTextSecondaryLight};
  font-size: 1rem !important;
  line-height: 24px !important;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: -10px;
`;

const CardTitle = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-weight: bold;
  margin-bottom: 8px !important;
`;

const CardSubTitle = styled.p`
  color: ${colors.uiTextSecondaryLight};
  line-height: 2rem;
`;

const LeftSection = styled.div`
  flex: 0 0 32px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
`;

const BtnWrap = styled.div`
  align-self: center;
  margin-top: 22px !important;
`;

const BtnWrapNewPool = styled.div`
  align-self: center;
`;

const SubTitle = styled.p`
  color: ${colors.uiTextSecondaryLight};
  font-size: 1.125rem !important;
  line-height: 1.5rem;
  align-self: center;
  margin-bottom: 34px !important;
`;

const Description = styled.p`
  color: ${colors.uiTextSecondaryLight};
  font-size: 1.125rem !important;
  line-height: 1.5rem;
  align-self: center;
  margin-bottom: 14px !important;
`;

const ActionTitle = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-size: 18px !important;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 6px !important;
`;

const ActionSubTitle = styled.p`
  color: ${colors.uiTextSecondaryLight};
  font-size: 1rem !important;
  line-height: 24px;
`;

const PageTitle = styled.p`
  color: ${colors.brandNavy4};
  font-size: 2rem !important;
  font-weight: bold;
  line-height: 1 !important;
  align-self: center;
  margin-bottom: 6px !important;
`;

const Landing: FC<Props> = ({ handleCreateNewCohort, setHeaderVisibility }) => {
  const [showSecondPage, setShowSecondPage] = useState(false);

  const togglePage = useCallback((): void => {
    setShowSecondPage(true);
    setHeaderVisibility(true);
  }, [setHeaderVisibility]);

  return (
    <M.Grid>
      {!showSecondPage && (
        <FlexContainer>
          <PageTitle data-testid='landing-title'>
            Streamlined random drug and alcohol testing
          </PageTitle>
          <SubTitle>
            Manage your random drug and alcohol testing program directly from
            your dashboard.
          </SubTitle>
          <FlexGrid>
            <FlexCard>
              <IconWrapGreen>
                <M.Icon icon='CheckmarkOutline' size={24} />
              </IconWrapGreen>
              <CardWrap>
                <CardHeading>Efficient</CardHeading>
                <CardBody>
                  Easily randomize testers, order tests, and view results all in
                  one centralized location.
                </CardBody>
              </CardWrap>
            </FlexCard>
            <FlexCard>
              <IconWrapGreen>
                <M.Icon icon='ArrowsHorizontal' size={24} />
              </IconWrapGreen>
              <CardWrap>
                <CardHeading>Simple</CardHeading>
                <CardBody>
                  Independently manage your random testing program with built-in
                  tools for a personalized experience.
                </CardBody>
              </CardWrap>
            </FlexCard>
            <FlexCard>
              <IconWrapGreen>
                <M.Icon icon='DocumentTasks' size={24} />
              </IconWrapGreen>
              <CardWrap>
                <CardHeading>Adaptable</CardHeading>
                <CardBody>
                  Configure your random testing program to meet the requirements
                  of both the DOT and non-DOT standards.
                </CardBody>
              </CardWrap>
            </FlexCard>
          </FlexGrid>
          <Description>
            First, you create pools of people eligible for selection. Creating
            pools is free.
          </Description>
          <Description>
            Then, Checkr randomly selects people to test. Checkr charges you for
            each test, plus a $10 fee.
          </Description>
          <BtnWrap>
            <M.Button
              kind='primary'
              size='md'
              onClick={togglePage}
              data-testid='get-started-btn'
            >
              Get started
            </M.Button>
          </BtnWrap>
        </FlexContainer>
      )}
      {showSecondPage && (
        <FlexContainerWithBorder>
          <FlexGridSecondPage>
            <FlexSection>
              <ActionTitle data-testid='landing-title-second-screen'>
                Set up your first random testing pool.
              </ActionTitle>
              <ActionSubTitle>
                A <strong>pool</strong> includes everyone eligible for testing
                based on based on your company policy or industry regulations.
              </ActionSubTitle>
            </FlexSection>
          </FlexGridSecondPage>
          <Divider />
          <FlexGridSecondPage>
            <FlexSection>
              <CardContainer>
                <LeftSection>
                  <IconWrapGray>
                    <M.Icon icon='Category' size={32} />
                  </IconWrapGray>
                </LeftSection>
                <RightSection>
                  <CardTitle>Manage multiple pools</CardTitle>
                  <CardSubTitle>
                    Create more than one pool to meet different regulatory
                    needs.
                  </CardSubTitle>
                </RightSection>
              </CardContainer>
              <CardContainer>
                <LeftSection>
                  <IconWrapGray>
                    <M.Icon icon='WatsonHealthMagnify' size={32} />
                  </IconWrapGray>
                </LeftSection>
                <RightSection>
                  <CardTitle>Review pool data</CardTitle>
                  <CardSubTitle>
                    Check the selection history and test types for each pool and
                    see who’s currently in the pool.
                  </CardSubTitle>
                </RightSection>
              </CardContainer>
            </FlexSection>
          </FlexGridSecondPage>
          <BtnWrapNewPool>
            <M.Button
              kind='primary'
              size='md'
              onClick={handleCreateNewCohort}
              data-testid='landing-new-cohort-btn'
            >
              + New pool
            </M.Button>
          </BtnWrapNewPool>
        </FlexContainerWithBorder>
      )}
    </M.Grid>
  );
};

export default Landing;
