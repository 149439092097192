import React, { useContext, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import Context from 'modules/assess/ui/rules/lookback/table/context';

type Props = {};

const Search: React.FC<Props> = () => {
  const { expandAll, collapseAll, setSearch, setExpandAll, setCollapseAll } =
    useContext(Context);

  const onChange = useCallback(
    e => {
      if (expandAll) {
        setExpandAll(false);
      }

      if (collapseAll) {
        setCollapseAll(false);
      }

      setSearch(e.target.value);
    },
    [expandAll, collapseAll, setSearch, setExpandAll, setCollapseAll],
  );

  return <M.Search data-testid='assess-lp-search-bar' onChange={onChange} />;
};

export default Search;
