import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const LoadingWrapper = styled.div`
  height: 500px;
  display: flex;
  margin: 1rem auto;
`;

// eslint-disable-next-line react/function-component-definition
export default function LoadingScreen() {
  return (
    <LoadingWrapper>
      <M.LoadingSpinner />
    </LoadingWrapper>
  );
}
