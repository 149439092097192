import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  title: string;
  icon: string;
  subtitle?: string;
  explanation: string;
};

const Container = styled.div`
  text-align: left;
  .mastodon-icon {
    margin-right: 0.4rem;
    margin-bottom: -0.1rem;
  }
  .court-title {
    margin-right: 1.5rem;
  }
  .court-sub-title {
    color: ${colors.uiGrey600};
  }
  .court-explanation {
    margin-top: 0.5rem;
  }
`;

const CourtType: React.FC<{ type: Props; selected: boolean }> = ({
  type,
  selected,
}) => {
  const { title, icon, subtitle, explanation } = type;
  const selectedClass = selected ? 'selected' : '';
  return (
    <Container className={`court-type ${selectedClass}`}>
      <M.Icon name={icon} size={16} />
      <span className='court-title'>{title}</span>
      {subtitle && <span className='court-sub-title'>{subtitle}</span>}
      <div className='court-explanation'>{explanation}</div>
    </Container>
  );
};

export default CourtType;
