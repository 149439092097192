import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Namespace } from 'modules/assess/api';

type Props = {
  namespace: Namespace;
};

const Header = styled.h1`
  color: ${colors.brandNavy4};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1em;
`;

const Title: React.FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('assess:v2');
  const title = t(`home.guidelines.${namespace}.title`);
  return (
    <Header id='assess-v2-ui-home-guidelines-title-header'>{title}</Header>
  );
};

export default Title;
