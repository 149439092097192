import React, { useMemo } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useLocation } from 'react-router-dom';
import { isCandidateReportURL } from 'components/Packages/AddChecks/helper';
import { useOrderBackgroundCheckContext } from 'components/Packages/OrderBackgroundCheck/Context';
import AddedLabel from './AddedLabel';
import ScreeningType from '../enums/ScreeningType';
import OrderedLabel from './OrderedLabel';

export const Button = styled(M.Button)`
  min-width: 80px;
  height: 40px;

  .mastodon-button.secondary {
    padding: 0.5rem 1.1rem !important;
  }

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export const DeleteButton = styled(Button)`
  justify-content: end;
  min-width: 40px;
  &:focus {
    border: none !important;
  }
  svg {
    fill: ${colors.brandNavy2} !important;
  }
`;

export const SetupButton = styled(M.Button)`
  min-width: 80px;
  height: 40px;
  background: ${colors.uiGrey0} !important;
  color: ${colors.brandNavy4} !important;
  border-color: ${colors.brandNavy4} !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  align-items: center;

  .mastodon-button.secondary {
    padding: 0.5rem 1.1rem !important;
  }

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export type SpecialAddScreeningsActionProps = {
  screeningType: ScreeningType;
  kind: string;
  onClick: Function;
  added: boolean;
  disabled: boolean;
  id?: string;
  specialDisabled?: boolean;
};

const SpecialAddScreeningsAction: React.FC<SpecialAddScreeningsActionProps> = ({
  screeningType,
  kind,
  onClick,
  added,
  disabled,
  id,
  specialDisabled = false,
}) => {
  const location = useLocation();
  const usingAddChecks = useMemo(
    () => isCandidateReportURL(location.pathname),
    [location],
  );
  const { state } = useOrderBackgroundCheckContext();

  // This is for FACIS. We only allow one FACIS type on a package
  if (specialDisabled) {
    return (
      <Button
        kind='secondary'
        disabled
        data-testid={`${screeningType.replace('_', '-')}-add-button`}
      >
        <M.Icon icon='Add' /> Add
      </Button>
    );
  }

  if (disabled) {
    if (usingAddChecks && !state.basePackage?.screenings) {
      return <OrderedLabel dataTestId={screeningType} />;
    }

    return <AddedLabel />;
  }

  if (added) {
    return (
      <DeleteButton
        id={id}
        kind={kind}
        data-testid={`${screeningType.replace('_', '-')}-delete-button`}
        onClick={onClick}
      >
        <M.Icon icon='TrashCan' />
      </DeleteButton>
    );
  }

  return (
    <Button
      kind={kind}
      onClick={onClick}
      data-testid={`${screeningType.replace('_', '-')}-add-button`}
    >
      <M.Icon icon='Add' /> Add
    </Button>
  );
};

export default SpecialAddScreeningsAction;
