import React, { useCallback } from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { INTERNATIONAL_BASIC_SCREENINGS } from 'components/Signup/constants/screenings';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import PackageCard from '../../PackageCard';
import ScreeningsListItem from '../ScreeningsListItem';

const InternationalBasicPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
}) => {
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'International Basic',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_international_basic_plus');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='International Basic+'
      description='Simple and limited search'
      price={3200}
      packageType='checkrdirect_international_basic_plus'
      selected={selectedPackage === 'checkrdirect_international_basic_plus'}
      onClick={handlePackageSelect}
    >
      <>
        {INTERNATIONAL_BASIC_SCREENINGS.map(screeningName => (
          <ScreeningsListItem
            key={screeningName}
            screeningName={screeningName}
          />
        ))}
      </>
    </PackageCard>
  );
};

export default InternationalBasicPackageCard;
