import { GenericObject } from '@dashboard-experience/utils';
import client from './client';

export const COMPLETE_PATH = 'complete';

export const sendCompleteReport = (
  reportId: string,
): Promise<GenericObject> => {
  const path = `v1/reports/${reportId}/${COMPLETE_PATH}`;
  return client.post(path);
};
