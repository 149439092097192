import React from 'react';

interface Props {
  uri: string | null;
  clientControlled?: string | null;
}

const VerificationLink: React.FC<Props> = ({ uri, clientControlled }) =>
  uri && !clientControlled ? (
    <a
      href={uri}
      target='_blank'
      rel='noreferrer'
      className='exceptions-verification-link'
    >
      View verification request sent to the candidate
    </a>
  ) : null;

export default VerificationLink;
