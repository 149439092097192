import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetReport } from 'api/reports';
import { useUser } from 'context/CurrentUser';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { continuousCheckPackages } from 'Constants';
import { useSetNewAdverseAction } from 'modules/adjudication/api';
import { useDispatch, useModalState } from '../../../context';
import ConfigSection from '../config-options/config-section';

const base_path = `${namespace}:report.actions.pre_adverse.modal.adverse_items.auto_send_post_adverse`;

type Props = {};

const AutoSendPostAdverse: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useUser();
  const { newAdverseAction, isLoading } = useSetNewAdverseAction();
  const { report } = useGetReport();
  const hasContinousCheckPackage = continuousCheckPackages.includes(
    report?.package || '',
  );
  const { daysToPostAdverseAction } = useModalState('state');

  const onChange = useCallback(
    (e, { value, direction }) => {
      const valueAsNumber = isNaN(value) ? value.valueAsNumber : value;
      const inputValue =
        e.type === 'click' ? valueAsNumber : e.target.valueAsNumber;
      dispatch({
        type: 'SET_DAYS_TO_POST_ADVERSE_ACTIONS',
        payload: inputValue ?? 0,
      });
    },
    [dispatch],
  );

  if (isLoading)
    return <M.LoadingSpinner data-testid='auto-send-post-aa-loading' />;

  const title = t(`${base_path}.title`);
  const subtitle = t(`${base_path}.subtitle`);
  const continuous_check = t(`${base_path}.continuous_check`);
  const days_label = t(`${base_path}.days.label`);
  const days_validation = t(`${base_path}.days.validation`);

  return (
    <ConfigSection>
      <div className='flex-container'>
        <div className='text-container'>
          <strong>{title}</strong>
          <p>{subtitle}</p>
          {hasContinousCheckPackage && <p>{continuous_check}</p>}
        </div>
        <div>
          <M.NumberInput
            min={7}
            max={30}
            onChange={onChange}
            value={
              daysToPostAdverseAction ??
              newAdverseAction.daysToPostAdverseAction
            }
            invalidText={days_validation}
            helperText={days_label}
            disabled={currentUser?.account?.adverse_action_notice_days_locked}
            allowEmpty={false}
            role='input'
          />
        </div>
      </div>
    </ConfigSection>
  );
};

export default AutoSendPostAdverse;
