import { M, colors } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const Container = styled.span`
  cursor: pointer;
  span {
    color: ${colors.uiGrey500};
    font-weight: bolder;
    margin-left: 0.5em;
  }
`;

type Props = {};

const UnassignedStatus: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t(`assess:ruleset_assignments.status.unassigned`, 'Unassigned');

  return (
    <Container data-testid='assess-rulesets-unassigned-status'>
      <M.TooltipDefinition definition='This ruleset has no candidate segments assigned to it. It will not evaluate any candidates.'>
        <M.StatusIcon
          icon='warning'
          filled
          style={{ display: 'inline-block' }}
        />
        <span>{text}</span>
      </M.TooltipDefinition>
    </Container>
  );
};

export default UnassignedStatus;
