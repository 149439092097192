import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type StatusBannerProps = {
  translationKey: string;
};

const Banner = styled.div`
  width: 100%;
  background-color: ${colors.uiYellow200};
`;

const BannerText = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
`;

const StatusBanner = ({ translationKey }: StatusBannerProps) => {
  const { t } = useTranslation();
  const title = t(`banners.${translationKey}.title`);
  const subtitle = t(`banners.${translationKey}.subtitle`);
  const link = t(`banners.${translationKey}.link`);
  const linkText = t(`banners.${translationKey}.linkText`);

  return (
    <Banner>
      <BannerText>
        <p>
          <strong> {title}</strong> {subtitle}
          {link !== `banners.${translationKey}.link` && (
            <a href={link} target='_blank' rel='noopener noreferrer'>
              {linkText}
            </a>
          )}
        </p>
      </BannerText>
    </Banner>
  );
};

export default StatusBanner;
