import React from 'react';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../../types';

const Date: React.FC<
  Pick<FieldComponentProps, 'report' | 'testid' | 'fieldKey'>
> = ({ report, fieldKey, testid }) => {
  return <div data-testid={testid}>{dateWithTimezone(report[fieldKey])}</div>;
};

export default Date;
