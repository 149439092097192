import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { useUser } from 'context/CurrentUser';
import Skeleton from './Skeleton';
import { getPackageSlugs } from '../helpers';
import MultiSelectFilter from './MultiSelectFilter';
import { styledFilter } from './StyledComponents';

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  ${styledFilter}
`;

const Package = () => {
  const {
    packages: apiPackages,
    packagesLoading,
    defaultPackages,
  } = useSearchState();

  const currentUser = useUser();

  const trackEvent = useTrackEvent();

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onChange = useCallback(
    (setFilters: (params: any) => void) =>
      ({ selectedItems }: { selectedItems: Array<any> }) => {
        const p = getPackageSlugs(selectedItems);

        if (selectedItems.length > selectedItemsCount && currentUser) {
          trackEvent(
            CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
            {
              // get last item of array of selected packages which is equal to the latest added package
              Packages: p.slice(-1).pop(),
              'Selected Filter': 'Packages',
            },
          );
        }
        setSelectedItemsCount(selectedItems.length);
        setFilters(p);
      },
    [currentUser, selectedItemsCount, trackEvent],
  );

  return (
    <>
      {apiPackages.length > 0 && defaultPackages && !packagesLoading ? (
        <StyledMultiSelectFilter
          packages={apiPackages}
          initialSelectedItems={defaultPackages}
          onChange={onChange}
          component='PackageFilter'
          param='packages'
        />
      ) : (
        <div />
      )}
      <Skeleton isLoading={packagesLoading} />
    </>
  );
};

export default Package;
