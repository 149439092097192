import { useLocation } from 'react-router-dom';

function useInitialValues(defaultValues: Record<string, any>) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const storedValue = sessionStorage.getItem('signup');
  let storedObject: Record<string, any> = {};
  if (storedValue) {
    storedObject = JSON.parse(storedValue);
  }

  const storedFormValues = storedObject.createAccountSection || {};

  const initialValues = { ...defaultValues };

  for (const key in initialValues) {
    if (queryParams.has(key)) {
      initialValues[key] = queryParams.get(key);
    } else if (Object.prototype.hasOwnProperty.call(storedFormValues, key)) {
      initialValues[key] = storedFormValues[key];
    }
  }

  return initialValues;
}

export default useInitialValues;
