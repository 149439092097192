import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Provider from 'components/Invitations/Provider';
import { OrderNewReport } from 'components/Candidate';
import { useCandidate } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';

const NewReportContainer = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const account = candidate.account || currentUser.account;

  return account?.id ? (
    <Provider account={account}>
      <div id='order-new-wrapper'>
        <OrderNewReport />
      </div>
    </Provider>
  ) : (
    <M.LoadingInline />
  );
};

export default NewReportContainer;
