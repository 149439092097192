import React, { useCallback } from 'react';
import { BillingEntity } from 'types/Billing';
import { M } from '@dashboard-experience/mastodon';
import Button from '../Button';

type Props = {
  paymentProfile: BillingEntity;
  submit: (paymentProfile: BillingEntity, disable: boolean) => void;
  modalOpen: boolean;
  hideModal: () => void;
};

const NodeBillingDisablePopup: React.FC<Props> = ({
  paymentProfile,
  submit,
  modalOpen,
  hideModal,
}) => {
  const onDelete = useCallback(() => {
    submit(paymentProfile, true);
    hideModal();
  }, [submit, paymentProfile, hideModal]);

  const onClose = useCallback(() => {
    hideModal();
  }, [hideModal]);
  if (!modalOpen) {
    return null;
  }
  return (
    <M.ComposedModal data-testid='node-billing-disable-popup' open={modalOpen}>
      <M.ModalHeader>Warning</M.ModalHeader>
      <M.ModalBody>
        This will disable billing for {paymentProfile.name}
      </M.ModalBody>
      <M.ModalFooter>
        <>
          <Button kind='secondary' trans='verbs.cancel' onClick={onClose} />
          <Button kind='danger' trans='verbs.disable' onClick={onDelete} />
        </>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default NodeBillingDisablePopup;
