import React from 'react';
import {
  ContextContainer,
  BulkOrderContainer,
  ManualBulkOrderContainer,
} from 'containers';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import BulkOrderHistoryHeader from '../../containers/Billing/BulkOrderHistory/BulkOrderHistoryHeader';
import { StyledSubHeading } from './BulkOrderHistoryPage';
import { MANUAL_BULK_UPLOAD } from '../../Flags';

const BulkOrderHistorySinglePage = () => {
  const currentUser = useUser();
  const hasManageBulkInvitationsPermission = hasPermission(
    currentUser,
    'manage_bulk_invitations',
  );
  const manualBulkUploadFlag: boolean =
    useFlag(MANUAL_BULK_UPLOAD)?.variantKey === 'on';

  const noPermissionNotification = (
    <>
      You don&apos;t have access to <b>Payment & billing</b> and were redirected
      here to view <b>Bulk order history</b>. You can use the same link to
      return to this page at any time.
    </>
  );

  if (!hasManageBulkInvitationsPermission) {
    const noAccessNotification = `You don't have access to this page. Please contact an account admin to update your user permissions.`;
    return (
      <BulkOrderHistoryHeader>{noAccessNotification}</BulkOrderHistoryHeader>
    );
  }

  return (
    <>
      <BulkOrderHistoryHeader>
        {noPermissionNotification}
      </BulkOrderHistoryHeader>
      {manualBulkUploadFlag && (
        <>
          <StyledSubHeading data-testid='manual-bulk-order-history-header'>
            Manual orders
          </StyledSubHeading>
          <ManualBulkOrderContainer />
        </>
      )}
      <StyledSubHeading data-testid='bulk-order-history-header'>
        Bulk invitations
      </StyledSubHeading>
      <BulkOrderContainer />
    </>
  );
};

export default ContextContainer(BulkOrderHistorySinglePage);
