import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { StyledChoiceCardGroup, ChoiceCardWrap } from '../../SharedItems';
import { MANUAL, UPLOAD } from '../../Context';

type HowToAddContactInfoProps = {
  maxEmails: number;
  bulkUploadMax: string;
  handleManual: () => void;
  handleUpload: () => void;
  inviteMethod: string;
};

const ChoiceCardGroup = styled(StyledChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;

const ChoiceCardWrapped = styled(ChoiceCardWrap)`
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const HowToAddContactInfoSection: React.FC<HowToAddContactInfoProps> = ({
  maxEmails,
  bulkUploadMax,
  handleManual,
  handleUpload,
  inviteMethod,
}) => {
  return (
    <>
      <h4 className='section-title' data-testid='get-started-invitations-title'>
        How would you like to add your candidate contact information?
      </h4>
      <ChoiceCardGroup data-testid='candidate-invitations-choice-card-group'>
        <ChoiceCardWrapped data-testid='candidate-invitations-enter-manually-card-wrap'>
          <M.ChoiceCard
            title='Enter manually'
            description={`Up to ${maxEmails} at a time`}
            onClick={handleManual}
            checked={inviteMethod === MANUAL}
            value={MANUAL}
            data-testid='candidate-invitations-enter-manually'
          />
        </ChoiceCardWrapped>
        <ChoiceCardWrapped data-testid='candidate-invitations-upload-csv-card-wrap'>
          <M.ChoiceCard
            title='Upload CSV'
            description={`Up to ${bulkUploadMax} at a time`}
            onClick={handleUpload}
            checked={inviteMethod === UPLOAD}
            value={UPLOAD}
            data-testid='candidate-invitations-upload-csv'
          />
        </ChoiceCardWrapped>
      </ChoiceCardGroup>
    </>
  );
};

export default HowToAddContactInfoSection;
