import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import styled from 'styled-components';
import { useCCSV } from './context';

const StyledAnchor = styled.a`
  cursor: pointer;
`;

type Props = {};

const ActiveVersionBanner: React.FC<Props> = () => {
  const ccsv = useCCSV();
  const history = useHistory();
  const openDraft = useCallback(() => {
    const path = `/assess/mvr/categories/${ccsv?.category_code_set?.id}/versions/draft`;
    history.push(path);
  }, [history, ccsv]);

  if (!ccsv?.category_code_set?.account_id) return null;

  const translation = () => {
    return (
      <Trans t={t} i18nKey='assess:mvr.category-sets.banner.active'>
        You&apos;re in read-only mode. To edit your category set, click
        <StyledAnchor role='link' onClick={openDraft}>
          Edit Draft
        </StyledAnchor>
      </Trans>
    );
  };
  return (
    <M.ActionableNotification
      hideActionButton
      inline='classic'
      data-testid='assess-mvr-category-set-active-banner'
      kind='warning'
      style={{ minWidth: '100%' }}
      title={translation()}
    />
  );
};

export default ActiveVersionBanner;
