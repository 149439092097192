import { CATEGORIES, ChargeSeverity, Decision } from 'modules/assess';

export const ASSESS_STATUSES = {
  REVIEW: 'review',
  ESCALATED: 'escalated',
  ELIGIBLE: 'eligible',
  ALWAYS_MEETS: 'ALWAYS_MEETS',
  NEVER_MEETS: 'NEVER_MEETS',
} as const;

export const HEADERS = [
  {
    key: 'main',
  },
  {
    key: ChargeSeverity.felony,
  },
  {
    key: ChargeSeverity.misdemeanor,
  },
  {
    key: ChargeSeverity.petty_offense,
  },
];

export const SUB_HEADERS: Decision[] = [Decision.ELIGIBLE, Decision.ESCALATED];

export const ROWS = CATEGORIES.map(main => ({
  id: main.id,
  main: {
    name: main.name,
    subCategories: main.children?.map(sub => ({
      id: sub.id,
      name: sub.name,
      charges: sub.children?.map(subsub => ({
        ...subsub,
        charge_subcategory: sub.id,
      })),
    })),
  },
}));
