import { Config } from 'modules/assess/models/config';
import * as Entity from 'modules/assess/models/rulesets/default';
import client from './client';

export const BASE_PATH = 'accounts';

export const fetchConfiguration = (id?: string): Promise<Config> => {
  const path = `${BASE_PATH}/${id}/configuration`;
  return client.get(path);
};

export const fetchDefaultRulesets = (id: string): Promise<Entity.Type> => {
  const path = `/${BASE_PATH}/${id}/rulesets/default`;
  return client.get(path);
};
