import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ContextContainer, CandidateContainer } from 'containers';

type RouterProps = {
  candidateId: string;
};

type Props = RouteComponentProps<RouterProps>;

const CandidatePage: React.FC<Props> = ({ match }) => {
  const {
    params: { candidateId },
  } = match;
  return <CandidateContainer candidateId={candidateId} />;
};

export default ContextContainer(CandidatePage);
