import React, { useCallback } from 'react';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Actions as Container } from 'modules/assess/ui/page';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { State } from 'modules/assess/ui/ruleset/version/store';
import {
  LookbackPeriod,
  Quickstart,
} from 'modules/assess/v2/models/amplitude/guidelines';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { BackButton, SaveButton } from '../../common/actions';

type Props = {
  option?: Navigation;
};

export const Actions: React.FC<Props> = ({ option }) => {
  const state = State.useValue();

  const track = useTrackEvent();

  const quickstartRules = Object.values(state.rules.premade.table)
    .filter(rule => rule.updated)
    .map(rule => rule.code);

  const lookbackPeriodRules = Object.values(state.rules.lookback.table)
    .filter(rule => rule.updated)
    .map(rule => rule.key);

  const includeLessThanMisdemeanor = state.settings
    ?.lookback_rules_all_less_than_misdemeanor_eligible
    ? 'Yes'
    : 'No';

  const amplitudeTrackNewRules = useCallback(() => {
    let rules;
    let event;

    switch (option) {
      case Navigation.Quickstart:
        rules = quickstartRules;
        event = Quickstart.Save;
        break;
      case Navigation.Lookback:
        rules = lookbackPeriodRules;
        event = LookbackPeriod.GuidelinesSaved;
        track(LookbackPeriod.LessThanMisdemeanorSaved, {
          choice: includeLessThanMisdemeanor,
        });
        break;
    }

    if (!rules || !event) return;

    track(event, {
      'Guidelines Saved': rules,
      'Number Saved': rules.length,
    });
  }, [
    includeLessThanMisdemeanor,
    lookbackPeriodRules,
    option,
    quickstartRules,
    track,
  ]);

  const namespace = useNamespace();
  const query = useRuleset();
  const ruleset = query?.data;

  return (
    <Container>
      <BackButton namespace={namespace} ruleset={ruleset?.id} />
      <SaveButton
        kind='primary'
        namespace={namespace}
        ruleset={ruleset?.id}
        trans='buttons.done'
        trackNewRulesCallback={amplitudeTrackNewRules}
      />
    </Container>
  );
};

export default Actions;
