/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import querystring from 'querystring';
import {
  localStorageFallback,
  removeAccessToken,
} from '@dashboard-experience/utils';
import { API } from 'utils/APIClient';
import {
  authorize,
  postMessageToDashboard,
  saveRedirectPath,
  endImpersonationAndRedirect,
  storeUserObject,
  getUserObject,
  getImpersonation,
} from 'utils';

import { IS_ANGULAR_IFRAME, PER_PAGE, reportIncludes } from 'Constants';
import { clearUserPreferences } from 'api/dashboardPreferences';

import {
  FETCH_CANDIDATE_FAILURE,
  FETCH_CANDIDATE_REQUEST,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATES_LEGACY_FAILURE,
  FETCH_CANDIDATES_LEGACY_REQUEST,
  FETCH_CANDIDATES_LEGACY_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SET_CONTEXT_PARAM,
  UPDATE_ACCOUNT_SETTINGS_FAILURE,
  WINDOW_RESIZE,
} from './ActionTypes';
import { toastError } from './ToastActions';

export * from './AccountActions';
export * from './DashboardActions';
export * from './ContinuousServicesActions';
export * from './GlobalActions';
export * from './IdpConnectionsActions';
export * from './IdpSamlConfigActions';
export { default as reloadReport } from './ReportActions';
export * from './SegmentActions';
export * from './ToastActions';

export const DEFAULT_QUERY_PARAMS = {
  q: '*',
  page: 1,
  per_page: PER_PAGE,
  order_by: 'created_at',
  sort: 'desc',
  test: false,
};

const sanitize = p => {
  let k;
  // eslint-disable-next-line guard-for-in
  for (k in p) {
    if (p[k] && p[k].join) {
      p[k] = p[k].join(',').toLowerCase().replace(/ /gi, '_');
    }

    if (p[k] == null || p[k].length < 1 || p[k] === '_') {
      delete p[k];
    }
  }

  return p;
};

export const fetchCandidate = params => dispatch => {
  const { candidateID } = params;

  dispatch({ type: FETCH_CANDIDATE_REQUEST });

  return API.get(`/v1/candidates/${candidateID}?include=geos,reports`)
    .then(res => {
      dispatch({
        type: FETCH_CANDIDATE_SUCCESS,
        candidate: res,
      });
    })
    .catch(() =>
      dispatch({
        type: FETCH_CANDIDATE_FAILURE,
      }),
    );
};

export const fetchCandidates = params => async dispatch => {
  try {
    const p = sanitize(Object.assign(DEFAULT_QUERY_PARAMS, params));
    const qs = querystring.stringify(p);

    if (p.test !== 'true' && p.test !== true) {
      window.history.replaceState({}, '', `/?${qs}`);
    }

    dispatch({ type: FETCH_CANDIDATES_LEGACY_REQUEST });

    const res = await API.get(`/candidates?${qs}`);
    if (res && res.count && res.count > 0) {
      return dispatch({
        type: FETCH_CANDIDATES_LEGACY_SUCCESS,
        candidates: res.data,
        count: res.count,
        urlParams: p,
      });
    }

    return dispatch({
      type: FETCH_CANDIDATES_LEGACY_FAILURE,
    });
  } catch (e) {
    return dispatch({
      type: FETCH_CANDIDATES_LEGACY_FAILURE,
    });
  }
};

export const fetchReport = params => dispatch => {
  const { reportID } = params;

  dispatch({ type: FETCH_REPORT_REQUEST });

  return API.get(`/reports/${reportID}?include=${reportIncludes}`)
    .then(res => {
      dispatch({
        type: FETCH_REPORT_SUCCESS,
        report: res,
      });
    })
    .catch(() =>
      dispatch({
        type: FETCH_REPORT_FAILURE,
      }),
    );
};

export const fetchUser = () => dispatch => {
  dispatch({ type: FETCH_USER_REQUEST });

  // Get either the currentUser or the impersonated user, as appropriate
  const existingUser = getUserObject();

  if (existingUser) {
    return Promise.resolve(
      dispatch({
        type: FETCH_USER_SUCCESS,
        currentUser: existingUser,
      }),
    );
  }

  return API.get('/user')
    .then(res => {
      if (!{}.hasOwnProperty.call(res, 'error')) {
        // Store this user as either currentUser or the impersonated user, as appropriate
        storeUserObject(res);

        dispatch({
          type: FETCH_USER_SUCCESS,
          currentUser: res,
        });
      } else {
        dispatch({
          type: FETCH_USER_FAILURE,
        });
      }
    })
    .catch(() =>
      dispatch({
        type: FETCH_USER_FAILURE,
      }),
    );
};

export const uploadError = () => dispatch => {
  dispatch(toastError('Failed to upload file'));
  return dispatch({
    type: UPDATE_ACCOUNT_SETTINGS_FAILURE,
  });
};

export const windowResize = () => (dispatch, getState) => {
  const messageId = 'windowResize';
  const element = document.getElementById('mastodon');
  const width = element.offsetWidth;
  const height = element.offsetHeight + 24;
  const state = getState();
  const {
    ui: { contextId },
  } = state;
  postMessageToDashboard({
    messageId,
    contextId,
    width,
    height,
  });
  return dispatch({
    width,
    height,
    type: WINDOW_RESIZE,
  });
};

export const handleTokenExpired = () => {
  removeAccessToken();
  clearUserPreferences();
  localStorageFallback.removeItem('currentUser');

  // In iframe - let Angular handle all of the logic
  if (IS_ANGULAR_IFRAME) {
    postMessageToDashboard({
      messageId: 'tokenExpired',
    });
  }
  // If impersonating (and not iframed) - Remove impersonation, booting user back to Angular
  else if (getImpersonation().enabled) {
    endImpersonationAndRedirect();
  }
  // Otherwise handle all re-auth in standalone
  else {
    saveRedirectPath();
    authorize();
  }
};

export const setContextParam = (context, contextId) => dispatch =>
  dispatch({
    type: SET_CONTEXT_PARAM,
    context,
    contextId,
  });
