import React, { useCallback } from 'react';
import { Type } from 'modules/assess/models/rules/premade';
import { useDispatch, useState } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/premade';
import { Base } from 'modules/assess/v2/models/rules';
import List from 'modules/assess/v2/ui/common/premade/table/category/List';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import ButtonType from 'modules/assess/v2/ui/common/premade/table/category/ButtonType';
import { usePremadeRules } from 'modules/assess/ui/api';

export const Table: React.FC = () => {
  const state = useState();
  const rules = state.rules.premade.table;
  const premadeRules = usePremadeRules().data || [];
  const dispatch = useDispatch();
  const rows: Array<Row> = Base.build(premadeRules, rules);

  const handleRuleUnselected = useCallback(
    (rule: Type) => {
      dispatch(Actions.Remove.create(rule));
    },
    [dispatch],
  );

  return (
    <div data-testid='assess-v2-ui-lite-nature-of-offense-table'>
      <List
        rules={rows}
        handleRuleUnselected={handleRuleUnselected}
        hideTopDivider
        buttonType={ButtonType.Hide}
      />
    </div>
  );
};

export default Table;
