import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const LoadingCell = () => (
  <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    Loading...
    <M.LoadingInline style={{ width: 'auto' }} />
  </div>
);

export default LoadingCell;
