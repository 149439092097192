import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const PaymentWrapper = styled.div`
  a.cds--link.mastodon-link.stripeVerificationLink {
    display: flex;
    margin-top: 15px;
  }

  .StripeElement {
    width: 100%;
  }

  .justify-right {
    justify-content: end;
    padding-bottom: 1.5rem;
  }

  .cds--text-input {
    font-style: normal;
    padding: 0 8px;

    &:hover {
      background-color: ${colors.brandSlate1};
    }
  }

  #mastodon#mastodon & {
    .mb-0 {
      margin-bottom: 0;
    }
    .mt-0 {
      margin-top: 0;
    }
    .mt-16 {
      margin-top: 16px;
    }
    .primary.mastodon-button {
      &:hover {
        background-color: ${colors.uiNavy700};
      }
    }
    .cds--tooltip__label {
      vertical-align: text-bottom;
      svg {
        fill: ${colors.brandNavy4};
        height: 14px;
        width: 14px;
      }
    }
    .fz-16 {
      font-size: 16px;
    }
  }
`;

export const StyledP = styled.p`
  margin-top: 0.5rem;
  font-size: 15px;
`;

export const StyledH2 = styled.h2`
  #mastodon#mastodon & {
    color: ${colors.uiGrey900};
    font-size: 20px;
    line-height: 1.1;
  }
`;

export const StyledH3 = styled.h3`
  #mastodon#mastodon & {
    color: ${colors.uiGrey900};
    font-size: 16px;
    line-height: 22.5px;
  }
`;

export const Divider = styled.hr`
  border-top: 3px solid ${colors.uiGrey200};
  margin: 16px 0;
`;

export const StyledLink = styled.a`
  color: ${colors.brandAqua4} !important;
  text-decoration: underline;

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

export const StyledInput = styled(M.Input)`
  #mastodon#mastodon & {
    .cds--label {
      color: ${colors.brandNavy4};
      font-weight: 500;
    }
    .cds--text-input::placeholder {
      font-style: normal;
    }
  }
`;

export const StyledFormLabel = styled.div`
  #mastodon#mastodon & {
    color: ${colors.brandNavy4};
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0.25rem;
  }
`;

export const StyledSelect = styled(M.Select)`
  #mastodon#mastodon & {
    .cds--label {
      color: ${colors.brandNavy4};
      font-weight: 500;
    }
    .cds--select-input {
      font-style: normal;
    }
  }
`;

export const StyledSubTitle = styled.div`
  #mastodon#mastodon & {
    * {
      color: ${colors.brandSlate6};
    }
    .note-inner {
      font-size: 14px;
      margin-top: 6px;
    }
  }
`;

export const StyledSSLSideTitle = styled.div`
  #mastodon#mastodon & {
    display: inline-flex;
    margin-left: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    div {
      padding: 1px 0 0 2px;
      color: ${colors.brandSlate6};
    }
  }
`;

export const StyledNote = styled.p`
  #mastodon#mastodon & {
    color: ${colors.brandSlate7};
    margin-bottom: 16px;
  }
`;

export const StyledBadgeDiv = styled.div`
  display: inline;
  margin-left: 16px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${colors.uiGrey900};
  background: ${colors.uiGrey200};
  border-radius: 12px;
  vertical-align: revert;
`;

export const StyledErrorBadgeDiv = styled(StyledBadgeDiv)`
  color: ${colors.brandOrangeAccent2};
  background: rgba(205, 44, 29, 0.2);
`;

export const Button = styled(M.Button)`
  .cds--btn--primary {
    background-color: ${colors.brandNavy3} !important;
  }
  &:disabled {
    opacity: 50% !important;
  }
`;

export const OverflowMenu = styled(M.OverflowMenu)`
  &.cds--overflow-menu {
    height: unset;
    > div {
      padding: 0;
      margin-bottom: 0.25rem;
    }
    &.cds--overflow-menu--open {
      box-shadow: none;
      background-color: ${colors.uiGrey0};

      .cds--overflow-menu-options__btn:focus {
        outline: none;
      }
    }

    .cds--overflow-menu__icon {
      transform: rotate(90deg);
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const OverflowMenuItem = styled(M.OverflowMenuItem)`
  button.cds--overflow-menu-options__btn {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  .cds--overflow-menu-options__option-content {
    color: ${colors.uiGrey700};
  }
`;

export const StyledWarningInlineNotification = styled(M.ActionableNotification)`
  max-width: 100%;
  padding-top: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .cds--inline-notification__title {
    margin-bottom: 8px !important;
  }
`;

export const StyledErrorInlineNotification = styled(M.InlineNotification)`
  word-break: break-word;
  margin-top: 0;
  background-color: ${colors.uiGrey0} !important;
  border-color: ${colors.uiGrey0} !important;

  .cds--inline-notification__details {
    margin: 0 !important;
  }

  .cds--inline-notification__icon {
    margin-top: 0 !important;
  }

  .cds--inline-notification__text-wrapper {
    padding: 0 !important;
  }

  .cds--inline-notification__title {
    padding-right: 0 !important;
    color: ${colors.uiOrange600};
  }
`;

export const StyledButton = styled(M.Button)`
  .mastodon-loading-spinner {
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0;
    svg {
      fill: ${colors.uiGrey0} !important;
    }
  }
  &:disabled {
    opacity: 50% !important;
  }
  .mastodon-button {
    background-color: ${colors.brandNavy3} !important;
  }
`;

export const GridRow = styled(M.GridRow)`
  margin-bottom: 16px;
`;

export const GridCol = styled(M.GridCol)`
  padding-left: 0;
  padding-right: 0;
`;

export const IconCard = styled('span')`
  background: url(/images/accepted_cards.svg);
  background-size: 273px 24px;
  background-position-y: 0;
  display: inline-block;
  height: 24px;
  width: 39px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  &.icon-master {
    background-position-x: -39px;
  }
  &.icon-discover {
    background-position-x: -78px;
  }
  &.icon-express {
    background-position-x: -117px;
  }
  &.icon-jcb {
    background-position-x: -156px;
  }
  &.icon-diners {
    background-position-x: -195px;
  }
  &.icon-union {
    background-position-x: -234px;
  }
`;

export const ErrorMsg = styled(StyledP)`
  color: ${colors.uiOrange600};
`;

export const Grid = styled(M.Grid)`
  background-color: ${colors.brandSlate1};
  padding: 24px !important;
`;

export const EmailContainer = styled(M.TagsInput)`
  margin-top: 20px;
  height: 150px !important;
  border: 1px solid ${colors.uiGrey200} !important;
  overflow-y: auto !important;
`;

export const InvalidEmailError = styled.div`
  color: ${colors.uiOrange600};
  font-size: 12px;
  margin-top: 4px;
`;

export const ItalicizedMessage = styled.p`
  font-style: italic;
  display: inline;
  font-size: 12px !important;
`;
