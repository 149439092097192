import React, { useCallback } from 'react';
import SortedHeader from 'components/SortedHeader';
import { Headers, headerText } from './headers';

type PropTypes = {
  params: any;
  header: Headers;
  onSortClick: Function;
};

const SortingHeader: React.FC<PropTypes> = ({
  params,
  header,
  onSortClick,
}) => {
  const sortDirection = params.sort?.toUpperCase();

  // Because of pagination, a sort click requires an API call
  // The main "AdverseActions" component is where this event actually gets handled
  const onClick = useCallback(() => {
    onSortClick(header);
  }, [onSortClick, header]);

  const text = headerText[header];

  const isSortHeader = header === params.order_by;

  return (
    <SortedHeader
      data-testid={`adverse-actions-table-header-${header}`}
      isSortHeader={isSortHeader}
      sortDirection={sortDirection}
      onClick={onClick}
      label={text}
    >
      {text}
    </SortedHeader>
  );
};

export default SortingHeader;
