import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const CertificationContainer = styled.div`
  margin-top: 1rem;
  &:nth-child(1) {
    margin-top: 0rem;
  }
`;

export const DocumentContainer = styled.div`
  display: inline-block;
  padding: 1rem;
  max-width: 50%;
`;

export const DocumentHeader = styled.strong`
  display: block;
  padding: 1rem 0rem;
`;

export const DocumentImage = styled.img`
  width: 100%;
  max-height: 512px;
`;

export const DocumentButton = styled(M.Button)`
  margin: 1rem 0rem !important;
`;

export const InfoStatusIcon = styled(M.StatusIcon)`
  display: inline-block !important;
  padding-bottom: 0px !important;
`;

export const TimelineContainer = styled.div`
  margin-top: 1rem;
`;

export const TimelineHeader = styled.strong`
  display: block;
  padding: 1rem 0rem;
`;
