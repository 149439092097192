import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import PropTypes from 'prop-types';
import { IdpThinRow } from './IdpStyledComponents';

const IdentityProviderConfig = ({
  accountName,
  samlConfigProp,
  fetchSamlConfigRequest,
}) => {
  return (
    <M.Grid>
      <M.Container>
        <M.Container.Row>
          <h3>{accountName} Identity Provider Configuration </h3>
          <span> </span>
          <small>
            <a
              className='text-muted'
              target='_blank'
              rel='noopener noreferrer'
              href='https://help.checkr.com/hc/en-us/articles/4403169289495'
            >
              &nbsp; Learn More
            </a>
          </small>
        </M.Container.Row>
        {fetchSamlConfigRequest.error ? (
          <M.Container.Row>
            <M.InlineNotification
              kind='error'
              title='Error Retrieving Configuration. If the problem persists please contact Customer Support.'
            />
          </M.Container.Row>
        ) : null}
        <IdpThinRow>
          <M.GridCol lg={4}>
            <strong>
              <u>Setting</u>
            </strong>
          </M.GridCol>
          <M.GridCol lg={12}>
            <strong>
              <u>Value</u>
            </strong>
          </M.GridCol>
        </IdpThinRow>
        <IdpThinRow>
          <M.GridCol lg={4}>Strategy</M.GridCol>
          <M.GridCol lg={12}>SAML</M.GridCol>
        </IdpThinRow>
        <IdpThinRow>
          <M.GridCol lg={4}>Single Sign On URL</M.GridCol>
          <M.GridCol lg={12}>{samlConfigProp.sso_url}</M.GridCol>
        </IdpThinRow>
        <IdpThinRow>
          <M.GridCol lg={4}>Audience</M.GridCol>
          <M.GridCol lg={12}>{samlConfigProp.audience}</M.GridCol>
        </IdpThinRow>
        <IdpThinRow>
          <M.GridCol lg={4}>Attributes (key is case-sensitive)</M.GridCol>
          <M.GridCol lg={12}>email</M.GridCol>
        </IdpThinRow>
        <IdpThinRow>
          <M.GridCol lg={4}>subject:NameID</M.GridCol>
          <M.GridCol lg={12}>
            populate with a UUID or GUID from your IdP
          </M.GridCol>
        </IdpThinRow>
      </M.Container>
    </M.Grid>
  );
};

IdentityProviderConfig.propTypes = {
  accountName: PropTypes.string.isRequired,
  samlConfigProp: PropTypes.object.isRequired,
  fetchSamlConfigRequest: PropTypes.object.isRequired,
};

export default IdentityProviderConfig;
