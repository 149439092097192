import RecordType from './rules/record';

export interface Code {
  code: CodeID;
  description?: string;
  type: ItemType;
}

export type CodeID = string;

export enum ItemType {
  ACCIDENT = RecordType.ACCIDENT,
  SUSPENSION = RecordType.SUSPENSION,
  VIOLATION = RecordType.VIOLATION,
}
