import React, { useState } from 'react';
import Header from './header';
import { Container } from './styles';
import { OverviewSection } from './overview';
import { TabsSection } from './entries';
import { IframeContainer } from './components';

type Props = {};

const CandidateContent: React.FC<Props> = () => {
  const [showOverview, setShowOverview] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <div data-testid='candidate-content-milestone-one'>
      <Header isScrolled={isScrolled} />
      <IframeContainer>
        <Container showOverview={showOverview}>
          <TabsSection
            setShowOverview={setShowOverview}
            showOverview={showOverview}
            setIsScrolled={setIsScrolled}
            isScrolled={isScrolled}
          />
          <OverviewSection showOverview={showOverview} />
        </Container>
      </IframeContainer>
    </div>
  );
};

export default CandidateContent;
