import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import CreateButton from 'modules/assess/ui/rules/common/rule/editor/create-button';
import { Row } from 'modules/assess/ui/rules/common/rule';
import { InnerGroupType } from 'modules/assess/ui/rules/common/rule/editor/types';
import { initializeComplexRule } from 'modules/assess/models/rules/count/entity';
import RemoveButton from 'modules/assess/ui/rules/common/rule/editor/remove-button';
import Separator from 'modules/assess/ui/rules/common/rule/editor/separator';
import InnerGroup from './inner-group';
import CountField from './count-field';
import GroupButtonsContainer from './group-buttons-container';

type Props = {
  draftRule: any;
  setDraftRule: any;
  index: number;
};

const TopGroup: React.FC<Props> = ({ index, draftRule, setDraftRule }) => {
  const TopGroupStyle = styled(M.Container)`
    background-color: #cdd9e9 !important;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem !important;
  `;

  const deleteTopGroup = useCallback(() => {
    setDraftRule((oldDraftRule: any) => {
      const conditions = [...oldDraftRule.conditions];
      conditions.splice(index, 1);
      return { ...oldDraftRule, conditions };
    });
  }, []);

  const buildInnerGroup = () =>
    initializeComplexRule().conditions[0].conditions[0];
  const addInnerGroup = useCallback(() => {
    setDraftRule((oldDraftRule: any) => {
      const conditions = [...oldDraftRule.conditions];
      conditions[index].conditions.push(buildInnerGroup());
      return { ...oldDraftRule, conditions };
    });
  }, []);

  const topGroups = draftRule.conditions;
  const innerGroups = draftRule.conditions[index].conditions;

  return (
    <TopGroupStyle data-testid='assess-complex-editor-top-group'>
      <Row>
        <CountField
          draftRule={draftRule}
          setDraftRule={setDraftRule}
          index={index}
        />
      </Row>
      {innerGroups.map((innerGroup: InnerGroupType, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`innergroup-${i}`}>
          {i > 0 && <Separator labelTranKey='assess:common.or' />}
          <InnerGroup
            setDraftRule={setDraftRule}
            topGroupIndex={index}
            innerGroup={innerGroup}
            index={i}
            showRemove={innerGroups.length > 1}
          />
        </div>
      ))}
      <GroupButtonsContainer>
        <CreateButton
          onClick={addInnerGroup}
          dataTestid='assess-rules-count-add-inner-group'
          labelTranKey='assess:rules.custom.rule.buttons.add_inner_group'
          iconKey='GroupObjects'
        />
        {topGroups.length > 1 && (
          <RemoveButton
            onClick={deleteTopGroup}
            labelTranKey='assess:rules.custom.rule.buttons.remove_top_group'
          />
        )}
      </GroupButtonsContainer>
    </TopGroupStyle>
  );
};

export default TopGroup;
