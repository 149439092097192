import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const StyledTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 1rem;
`;

const StyledIcon = styled(M.Icon)`
  margin-left: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  color: ${colors.uiTextPrimaryLight};
`;

type Props = {
  categoryName: string;
  handleClose: (event?: SyntheticEvent) => void;
};

export const Heading: React.FC<Props> = ({ categoryName, handleClose }) => {
  return (
    <StyledContainer data-testid='assess-v2-ui-common-lookback-rules-dialog-heading-container'>
      <StyledTitle data-testid='assess-v2-ui-common-lookback-rules-heading-title'>
        {categoryName}
      </StyledTitle>
      <StyledIcon icon='Close' size={20} onClick={handleClose} />
    </StyledContainer>
  );
};

export default Heading;
