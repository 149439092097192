import React, { useCallback, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { SelectedScreening } from 'components/AddScreenings/shared/ModalComponents';
import OccHealthDropdown from './OccHealthDropdown/OccHealthDropdown';

const Container = styled.div`
  color: ${colors.brandNavy4};
  font-size: 14px;
  width: 576px;
  height: 485px;
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 26px;
`;

const Flex = styled.div`
  display: flex;
`;

const SubHeading = styled.h3`
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 19px;
`;

const Typography = styled.p`
  padding: 23px 0 4px 0;
  line-height: 20px;
`;

const ChoiceCard = styled(M.ChoiceCard)<ChoiceCardProps>`
  width: 189px;
  height: 48px;
  border-radius: 4px;
  background-color: ${({ checked }) =>
    checked ? colors.brandSlate2 : colors.brandSlate3};
  border-color: ${({ checked }) =>
    checked ? colors.brandNavy2 : colors.brandSlate3};
  padding-right: 16px;
`;

const TooltipIcon = styled(M.Icon)`
  fill: ${colors.brandNavy2} !important;
  margin: 2px 0 0 7px !important;
`;

type ChoiceCardProps = {
  checked: boolean;
};

type OccupationalHealthPageTwoProps = {
  selectedScreenings: SelectedScreening[];
  setSelectedScreenings: Dispatch<SetStateAction<SelectedScreening[]>>;
  screeningsError: boolean;
  accountId: string;
  isDOT: boolean | null;
  setIsDot: Function;
};

const OccupationalHealthPageTwo: React.FC<OccupationalHealthPageTwoProps> = ({
  selectedScreenings = [],
  setSelectedScreenings,
  screeningsError = false,
  accountId,
  isDOT,
  setIsDot,
}) => {
  const handleDOTChange = useCallback(
    e => {
      const isDOTRegulated = e.target.value === 'YES';
      setIsDot(isDOTRegulated);
    },
    [setIsDot],
  );

  return (
    <Container>
      <Heading data-testid='occ-health-select-secreening-title'>
        Select screenings
      </Heading>

      <Flex>
        <SubHeading>Is your company DOT-regulated? </SubHeading>
        <M.Tooltip
          data-testid='dot-tooltip'
          label='DOT regulations outline specific guidelines that companies must follow. This helps us provide you with the right screening options. '
          align='bottom'
        >
          <TooltipIcon icon='HelpFilled' />
        </M.Tooltip>
      </Flex>

      <div>
        <ChoiceCard
          data-testid='occ-health-dot-no'
          labelText='No'
          onClick={handleDOTChange}
          checked={isDOT === false}
          description='No'
          value='NO'
        />
        <ChoiceCard
          data-testid='occ-health-dot-yes'
          labelText='Yes'
          onClick={handleDOTChange}
          checked={isDOT === true}
          description='Yes'
          value='YES'
        />
      </div>
      <Typography data-testid='occ-health-dropdown-label'>
        Select the occupational health screenings you want to setup:{' '}
      </Typography>
      <OccHealthDropdown
        accountId={accountId}
        selectedScreenings={selectedScreenings}
        setSelectedScreenings={setSelectedScreenings}
        isDOT={isDOT}
        invalid={screeningsError}
      />
    </Container>
  );
};

export default OccupationalHealthPageTwo;
export type { SelectedScreening };
