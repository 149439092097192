import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { Header, TableParams } from '../types';
import renderRow from './renderRow';

const Table = ({
  rows,
  type,
  headers,
  assessEnabled,
  statusOrAssessment,
  tableProps,
  canceledStatusEnabled,
}: TableParams) => {
  return (
    <M.Table
      data-testid='screenings-drug-health-table'
      style={{ width: '35%', ...tableProps?.style }}
      {...tableProps}
    >
      <M.TableHead>
        <M.TableRow>
          {headers.map((header: Header) => (
            <M.TableHeader
              key={header.key}
              data-testid='drug-health-table-header'
              {...header.props}
            >
              {header.header}
            </M.TableHeader>
          ))}
        </M.TableRow>
      </M.TableHead>
      <M.TableBody>
        {rows.map((row: any, index: number) => {
          return renderRow({
            row,
            type,
            index,
            assessEnabled,
            statusOrAssessment,
            canceledStatusEnabled,
          });
        })}
      </M.TableBody>
    </M.Table>
  );
};

export default Table;
