import * as Entity from 'modules/assess/models/rules/entity';
import { Initializer } from 'state/utilities/state';
import * as Count from './count/state';
import * as Custom from './custom/state';
import * as Lookback from './lookback/state';
import * as Premade from './premade/state';

export type Type = Readonly<{
  count: Count.Type;
  custom: Custom.Type;
  lookback: Lookback.Type;
  premade: Premade.Type;
}>;

export const initialize: Initializer<Type> = () => ({
  count: Count.initialize(),
  custom: Custom.initialize(),
  lookback: Lookback.initialize(),
  premade: Premade.initialize(),
});

export const entities = (state: Type): Entity.Map => {
  return {
    count: Count.entries(state.count),
    custom: Custom.entries(state.custom),
    lookback: Lookback.entries(state.lookback),
    premade: Premade.entries(state.premade),
  };
};
