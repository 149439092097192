import React from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
  filterType: 'assignment' | 'assessment' | 'package';
  icon?: any;
};

const FilterLabel = ({ filterType, icon = null }: PropsType) => {
  const { t } = useTranslation('case-management');
  const labelText = t(`tasks.filters.${filterType}.label`);

  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ marginRight: '4px' }}>{labelText}</span>
      {icon}
    </span>
  );
};

export default FilterLabel;
