import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {};

export const KeySelector: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t('assess:ruleset.assign.filters.label');

  return (
    <M.FormLabel data-testid='assess-ruleset-assign-filters-label'>
      {text}
    </M.FormLabel>
  );
};

export default KeySelector;
