import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import LookerTile from './LookerTile';
import Warning from './Warning';

type Props = {
  content: any;
};

const Footer = styled.div`
  color: ${colors.uiGrey500};
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  padding-top: 2em;
`;

const Container: React.FC<Props> = ({ content }) => {
  const { footer, warning } = content;
  return (
    <div data-testid='fair-chance-sections-adverse-action-rate-with-data'>
      <LookerTile />
      <Warning warning={warning} />
      <Footer>{footer}</Footer>
    </div>
  );
};

export default Container;
