import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Assessment from './Assessment';
import Assignment from './Assignment';
import Packages from './Packages';

export const StyledFilterContainer = styled(M.Grid)`
  gap: 1.5em;
`;

const Filters = () => {
  return (
    <StyledFilterContainer
      data-testid='case-management-task-search-filters'
      sm={2}
    >
      <M.GridRow>
        <M.GridCol>
          <Assessment />
        </M.GridCol>
        <M.GridCol>
          <Packages />
        </M.GridCol>
        <M.GridCol>
          <Assignment />
        </M.GridCol>
      </M.GridRow>
    </StyledFilterContainer>
  );
};

export default Filters;
