import React from 'react';
import styled from 'styled-components';
import { LookbackRuleChange } from 'modules/assess/models/rulesets/version-diff';
import Table from './Table';
import Title from './Title';

type Props = {
  changes?: LookbackRuleChange.List;
};

const StyledContainer = styled.div`
  padding-bottom: 1rem;
`;

export const Container: React.FC<Props> = ({ changes }) => {
  if (!changes?.length) {
    return null;
  }

  return (
    <StyledContainer>
      <Title />
      <Table changes={changes} />
    </StyledContainer>
  );
};

export default Container;
