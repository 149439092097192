import { M, colors, fonts } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const Overlay = styled.div.attrs({
  className: 'modal-overlay',
})`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(4px);
  z-index: 1000;
`;

export const RightSideContainer = styled.div.attrs({
  className: 'modal-right-side-container',
})`
  display: flex;
  gap: 24px;
  margin-left: auto;
  @media (max-width: 720px) {
    width: 50% !important;
  }
`;

export const PriceSection = styled.div.attrs({
  className: 'modal-price-section',
})`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const SubtotalContainer = styled.div.attrs({
  className: 'modal-subtotal-container',
})`
  display: flex;
  justify-content: end;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const SubtotalText = styled.span.attrs({
  className: 'modal-subtotal-text p4',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    text-align: right;
  }
`;

export const SubtotalPrice = styled.span.attrs({
  className: 'modal-subtotal-price p2 bold',
})`
  color: ${colors.uiTextSecondaryLight};
`;

export const ItemizedTotal = styled.span.attrs({
  className: 'modal-itemized-total',
})`
  color: ${colors.uiTextTertiaryLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

export const SecondaryButton = styled(M.Button).attrs({
  className: 'modal-secondary-button',
})`
  justify-content: center;
  @media (max-width: 720px) {
    width: 50% !important;
  }
`;

export const PrimaryButton = styled(M.Button).attrs({
  className: 'modal-primary-button',
})`
  justify-content: center;
  @media (max-width: 720px) {
    width: 100% !important;
  }
`;

type TitleContainerProps = {
  flexDirection?: 'row' | 'column';
};

export const TitleContainer = styled.div.attrs({
  className: 'title-container',
})<TitleContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: ${props => props.flexDirection || 'row'};
`;

export const Subtitle = styled.p.attrs({
  className: 'page-subtitle p2',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
  }
`;

export const StyledModal = styled(M.ComposedModal)`
  > .cds--modal-scroll-content:focus {
    outline: none !important;
  }

  .cds--modal-content:focus {
    outline: none !important;
  }

  .signup-modal.cds--modal-container {
    font-family: ${fonts.legacy} !important;
    width: 75vw !important;

    @media (min-width: 672px) and (max-width: 1583px) {
      width: 90vw !important;
    }

    @media (max-width: 671px) {
      width: 100vw !important;
      max-height: 100% !important;
      position: fixed !important;
    }
  }
`;

export const StyledFooter = styled(M.ModalFooter)`
  display: flex;
  width: 100%;
  padding: 16px 40px !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  border-top: 1px solid ${colors.uiGrey200};
  background: ${colors.uiGrey0};
  box-shadow: 0 0 20px 0 rgba(22, 22, 29, 0.12) !important;
`;

export const ModalHeader = styled(M.ModalHeader)`
  &.mastodon-modal-header {
    padding: 0 2rem !important;
    min-height: 4.5rem;

    .mastodon-modal-header-subtitle {
      margin-top: 0 !important;
    }
  }

  .cds--modal-close-button {
    display: none;
  }
`;

export const ModalBody = styled(M.ModalBody)`
  .mastodon-modal-body-content-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    max-width: none !important;
    gap: 32px;
  }

  padding: 40px !important;
  border-bottom: none;
  border-radius: 0 !important;

  @media (max-width: 720px) {
    padding: 32px !important;
  }

  @media (max-width: 420px) {
    padding: 24px !important;
  }

  &.welcome-modal-body {
    .mastodon-modal-body-content-large {
      height: 100%;
      justify-content: center;
    }
  }
`;

export const ComposedModal = styled(M.ComposedModal)`
  &.mastodon-composed-modal .mastodon-modal-body {
    outline: none !important;
  }
`;
