import { ID } from 'modules/id';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Missing from '../../../missing';
import Version from '../../../../mvr/category-sets/instance/version/page';

type Params = {
  instance: ID;
  version: ID;
};

export const Instance: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { params } = match;
  const { instance, version } = params;

  if (!instance || !version) {
    return <Missing />;
  }

  return <Version instance={instance} version={version} />;
};

export default Instance;
