import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import BulkOrderHistoryTableCell from './BulkOrderHistoryTableCell';
import BulkOrderHistory from './BulkOrderHistory';
import { TableContainer } from '../BulkOrderHistoryStyled';

type Props = {
  headers: Array<{ name: string }>;
  rows: Array<{ cells: any }>;
  data: BulkOrderHistory[];
};

const BulkOrderHistoryTable: React.FC<Props> = ({ rows, headers, data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <M.Table data-testid='bulk-order-history-table'>
        <M.TableHead className='border-top'>
          <M.TableRow>
            {headers.map(header => (
              <M.TableHeader key={header.name}>
                {t(`headings.${header.name}`)}
              </M.TableHeader>
            ))}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {rows.map((rowMetaData, i) => {
            // rows has the bound data, and taxDocData has the raw source data.  We need both
            const row = data[i];
            const showErrorIndication = rowMetaData.cells.find(
              (cell: { info: { header: string }; id: string; value: string }) =>
                cell.info.header === 'invitation_status' &&
                cell.value === 'failed',
            );
            return (
              row && (
                <M.TableRow
                  key={row.id}
                  data-test-id={`bulk-order-history-row-${i}`}
                  data-testid={`bulk-order-history-row-${i}`}
                  style={showErrorIndication ? { background: '#FFF6EF' } : {}}
                >
                  {rowMetaData.cells.map(
                    (cell: { info: { header: any }; id: string }) => {
                      const cellProps = {
                        header: cell.info.header,
                        row,
                        cell,
                      };
                      return (
                        <BulkOrderHistoryTableCell
                          key={cell.id}
                          {...cellProps}
                        />
                      );
                    },
                  )}
                </M.TableRow>
              )
            );
          })}
        </M.TableBody>
      </M.Table>
    </TableContainer>
  );
};

export default BulkOrderHistoryTable;
