import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { SUB_CATEGORIES } from 'modules/assess/models';
import { List } from 'modules/assess/models/rules/premade';
import { usePremadeRules } from 'modules/assess/ui/api';
import Navigation, {
  isPremadeOrLookbackOption,
} from 'modules/assess/v2/models/navigation/standard';
import { Premium } from 'modules/assess/v2/ui/common';
import Button from './Button';
import Count from './Count';

type Props = {
  count: number;
  option: Navigation;
  showPremiumBadge?: boolean;
  title: string;
};

const StyledContainer = styled.div`
  align-items: center;
  color: ${colors.brandNavy3};
  display: flex;
`;

const StyledHeader = styled.h2`
  // Overwrite mastodon behavior
  margin-bottom: 0 !important;
`;

const Header: React.FC<Props> = ({
  count,
  option,
  showPremiumBadge,
  title,
}) => {
  const premadeRules = usePremadeRules().data || [];
  const total = getTotalGuidelines(option, premadeRules);
  const isEmpty = count === 0;
  const showGuidelinesCount = isPremadeOrLookbackOption(option) && !isEmpty;

  const basePath = `assess-v2-ui-guideline-options-header-${option}`;
  const containerId = `${basePath}-container`;
  const titleId = `${basePath}-title`;

  return (
    <StyledContainer id={containerId}>
      <StyledHeader id={titleId}>{title}</StyledHeader>
      {showGuidelinesCount && <Count current={count} total={total} />}
      {showPremiumBadge && <Premium />}
      {!showPremiumBadge && <Button isEmpty={isEmpty} option={option} />}
    </StyledContainer>
  );
};

function getTotalGuidelines(option: Navigation, premadeRules: List): number {
  switch (option) {
    case Navigation.Quickstart:
      return premadeRules.length;
    case Navigation.Lookback:
      return SUB_CATEGORIES.length;
    default:
      return 0;
  }
}

export default Header;
