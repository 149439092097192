import * as Entity from 'modules/assess/models/rulesets/context-dates';
import client from './client';
import { Namespace } from './constants';

export const BASE_PATH = 'ruleset_versions';

export type SearchResults = {
  count: number;
  data: Entity.List;
};

export const fetch = (
  namespace: Namespace,
  id: string,
): Promise<SearchResults> => {
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.get(path);
};

export const update = (
  namespace: Namespace,
  id: string,
  contextDates: Entity.List,
): Promise<void> => {
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.put(path, { context_dates: contextDates });
};

export const uri = (namespace: Namespace, id?: string): string => {
  const path = `/${namespace}/${BASE_PATH}/${id}/context_dates`;
  return client.uri({ url: path });
};
