import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { AccountSettingsResponse } from 'api/i9';
import { TRACKER_URL } from 'Constants';
import FormI9Statuses from './FormI9Statuses';
import LearnMoreModal from './LearnMoreModal';
import FormI9Settings from './FormI9Settings';
import useNavigate from './hooks/useNavigate';
import EverifyBadge from './EverifyBadge';
import {
  FlexContainer,
  FlexRow,
  FlexRowDistribute,
  PageLink,
  VerticalSeparator,
} from './style';

type FormI9Props = {
  settingsData?: AccountSettingsResponse;
  changedDefaultWorksite: () => void;
};

const FormI9: React.FC<FormI9Props> = ({
  settingsData,
  changedDefaultWorksite,
}) => {
  const [showModal, setShowModal] = useState(false);
  const OrderStep = 3;

  const setModalOpen = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const hideModal = useCallback((): void => {
    setShowModal(false);
  }, []);

  const onChangeDefaultWorksite = useCallback(() => {
    changedDefaultWorksite();
  }, [changedDefaultWorksite]);

  const navigate = useNavigate();
  const everifyCondition = settingsData?.everify_status || 'deactivated';

  sessionStorage.setItem('everifyCondition', everifyCondition);

  const goToOrderI9 = useCallback(() => navigate('/i-9/order'), [navigate]);

  return (
    <>
      {showModal && <LearnMoreModal open={showModal} hideModal={hideModal} />}
      <FlexContainer gap='2rem' padding='0'>
        <FlexRowDistribute alignItems='center'>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <h2>I-9 verification</h2>
            <VerticalSeparator height='0.75rem' />
            <PageLink href='#' onClick={setModalOpen}>
              Learn more
            </PageLink>
            <VerticalSeparator height='0.75rem' />
            <PageLink href={TRACKER_URL} target='_blank' rel='noreferrer'>
              Open Tracker
            </PageLink>
          </FlexRow>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <EverifyBadge step={OrderStep} status={everifyCondition} />
            <M.Button
              onClick={goToOrderI9}
              data-id='order-form-i9'
              data-testid='order-form-i9'
            >
              Order I-9
            </M.Button>
          </FlexRow>
        </FlexRowDistribute>
        <M.Tabs>
          <M.TabList contained>
            <M.Tab data-testid='form-i9-results'>Statuses</M.Tab>
            <M.Tab data-testid='form-i9-settings'>Settings</M.Tab>
          </M.TabList>
          <M.TabPanels>
            <M.TabPanel>
              <FormI9Statuses settingsData={settingsData} />
            </M.TabPanel>
            <M.TabPanel>
              <FormI9Settings
                settingsData={settingsData}
                onChangeDefaultWorksite={onChangeDefaultWorksite}
              />
            </M.TabPanel>
          </M.TabPanels>
        </M.Tabs>
      </FlexContainer>
    </>
  );
};

export default FormI9;
