import React, { useEffect } from 'react';
import * as Entity from 'modules/assess/models/rulesets/version';
import * as Store from 'modules/assess/ui/ruleset/version/store';
import * as State from 'modules/assess/ui/state/rulesets/versions/instance';
import { Type as Amplitude } from 'modules/assess/v2/models/amplitude';
import { Page } from 'pages/Assess';

export type Props = {
  data?: Entity.Type;
  id?: string;
  status?: string;
  event?: Amplitude;
};

export const Container: React.FC<Props> = ({
  children,
  data,
  id,
  status,
  event,
}) => {
  const store = Store.useStore();
  const { dispatch, state } = store;
  const { updated_at } = state;

  useEffect(() => {
    if (!data || data.updated_at?.getTime() === updated_at?.getTime()) {
      return;
    }

    dispatch(State.Actions.Insert.create(data));
  }, [data, dispatch, updated_at]);
  return (
    <Store.Provider value={store}>
      <Page.V2.Container id={id} status={status} event={event}>
        {children}
      </Page.V2.Container>
    </Store.Provider>
  );
};

export default Container;
