import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
`;

const ButtonStyled = styled(M.Button)`
  margin-right: 0px !important;
  margin-left: 0px !important;
`;

const GoBackButton = styled(M.Button)`
  margin-right: 15px !important;
`;

type BaseButtonProps = {
  visible: boolean;
  onClick: () => void;
  disabled?: boolean;
};

type Props = {
  backButton: BaseButtonProps;
  skipButton: BaseButtonProps;
  submitButton: BaseButtonProps & { disabled: boolean };
  continueButton: BaseButtonProps & { disabled: boolean; label: string };
};

const FooterButtons: React.FC<Props> = ({
  backButton,
  skipButton,
  submitButton,
  continueButton,
}) => {
  return (
    <ButtonContainer>
      {backButton.visible && (
        <GoBackButton
          data-testid='goBack-button-testid'
          onClick={backButton.onClick}
          kind='secondary'
        >
          Go back
        </GoBackButton>
      )}
      {skipButton.visible && (
        <ButtonStyled
          data-testid='skip-button-testid'
          onClick={skipButton.onClick}
          disabled={skipButton.disabled}
        >
          Skip
        </ButtonStyled>
      )}
      {submitButton.visible && (
        <M.Button
          data-testid='order-background-check-submit-button'
          disabled={submitButton.disabled}
          onClick={submitButton.onClick}
        >
          Submit Order
        </M.Button>
      )}
      {continueButton.visible && (
        <ButtonStyled
          data-testid='continue-button-testid'
          onClick={continueButton.onClick}
          disabled={continueButton.disabled}
        >
          {continueButton.label}
        </ButtonStyled>
      )}
    </ButtonContainer>
  );
};

export default FooterButtons;
