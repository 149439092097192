import React, { useCallback } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { MANUAL_BULKORDER_NAMES, useTrackEvent } from 'utils/analytics';
import { ManualOrdersReview } from '../Context';

const ComposedModal = styled(M.ComposedModal)`
  #mastodon & {
    .cds--modal-container {
      max-height: 80vh;
    }
  }
`;

const StyledContent = styled.div`
  color: ${colors.uiTextSecondaryLight};

  #mastodon & {
    p {
      color: ${colors.uiTextSecondaryLight};
      margin-bottom: 1.5rem;
    }

    h5 {
      color: ${colors.uiTextSecondaryLight};
      margin-bottom: 0.75rem;
    }
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 1rem;

    li {
      &::before {
        content: '•';
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }
`;

const FormsSection = styled.div`
  margin-top: 1rem;

  .form-item {
    color: ${colors.uiTextDisabledLight};
    display: flex;
    margin-bottom: 0.5rem;

    svg {
      color: ${colors.uiLinkPrimaryLightDefault};
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

type FormProps = {
  title: string;
  href: string;
  spanishHref: string;
  testId: string;
};

const STORAGE_PREFIX: string =
  'https://us-east-1-checkr.s3.amazonaws.com/manual_order/';
const ALL_FORMS_ARCHIVE: string = `${STORAGE_PREFIX}Manual_order_forms.zip`;
export const manualOrderForms: Array<FormProps> = [
  {
    title: 'Acknowledgement and authorization form',
    href: `${STORAGE_PREFIX}Acknowledgment_and_Authorization.pdf`,
  },
  {
    title: 'Disclosure Regarding Background Investigation',
    href: `${STORAGE_PREFIX}Disclosure_Regarding_Background_Investigation.pdf`,
  },
  {
    title: 'California CRA Disclosure',
    href: `${STORAGE_PREFIX}California_CRA_Disclosure.pdf`,
  },
  {
    title: 'Summary of rights under the FCRA',
    href: `${STORAGE_PREFIX}FCRA_Summary_of_Rights.pdf`,
  },
].map(i => ({
  ...i,
  testId: `manual-order-${i.title.replace(/ /g, '-').toLowerCase()}`,
  spanishHref: i.href.replace('.pdf', '_Spanish.pdf'),
}));

type ManualOrderModalProps = {
  show: boolean;
  handleClose: Function;
  handleSubmit: Function;
  manualOrdersReviewStatus: ManualOrdersReview;
};

const ManualOrderModal: React.FC<ManualOrderModalProps> = ({
  show,
  handleClose,
  handleSubmit,
  manualOrdersReviewStatus,
}) => {
  const trackEvent = useTrackEvent();

  const handleDownloadAllForms = useCallback(() => {
    trackEvent(MANUAL_BULKORDER_NAMES.DOWNLOAD_ALL_FORMS_BUTTON_CLICKED);
    window.open(ALL_FORMS_ARCHIVE, '_blank');
    handleClose();
  }, [handleClose, trackEvent]);

  const handleSubmitRequest = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <ComposedModal open={show} onClose={handleClose}>
      <M.ModalHeader closeModal={handleClose}>
        <h2 data-testid='manual-order-modal-header'>
          {manualOrdersReviewStatus === ManualOrdersReview.NOT_REQUESTED
            ? 'Set up manual orders'
            : 'About manual orders'}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        <StyledContent>
          <p>
            <strong>Manual orders</strong> allow you to instantly start
            background checks by submitting candidate information on their
            behalf. Admins can disable this feature in{' '}
            <strong>Account &gt; Settings</strong>.
          </p>
          <h5>How it works</h5>
          To place manual orders, you’ll:
          <ul>
            <li>
              Provide candidates with a clear stand-alone disclosure and obtain
              their prior written consent. Sample documents are linked below.
            </li>
            <li>
              Enter candidates’ information on their behalf, including name,
              social security number (SSN), and date of birth (DOB).
            </li>
            <li>
              Optionally, include the candidate’s email to send them a copy of
              their completed report. This is legally required in some
              locations.
            </li>
          </ul>
          <h5>Requesting access</h5>
          <p>
            This process involves inputting others’ sensitive information, so
            we’ll need to confirm that you are operating in a compliant manner.
            Your request for access will be reviewed within 48 hours. You may be
            contacted to verify certain information.
          </p>
          <FormsSection data-testid='manual-order-forms'>
            {manualOrderForms.map((i: FormProps) => (
              <div className='form-item' key={i.testId}>
                <M.Link
                  href={i.href}
                  data-testid={i.testId}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <M.Icon icon='DocumentDownload' size={20} />
                  {i.title}
                </M.Link>
                <span style={{ margin: '0 0.25rem' }}>&bull;</span>
                <M.Link
                  href={i.spanishHref}
                  data-testid={`${i.testId}-spanish`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Spanish version
                </M.Link>
              </div>
            ))}
          </FormsSection>
        </StyledContent>
      </M.ModalBody>
      <M.ModalFooter>
        {manualOrdersReviewStatus === ManualOrdersReview.NOT_REQUESTED ||
        manualOrdersReviewStatus === ManualOrdersReview.REQUESTED ? (
          <>
            <M.Button
              kind='tertiary'
              onClick={handleDownloadAllForms}
              data-testid='manual-orders-download-all'
            >
              Download forms
            </M.Button>
            {manualOrdersReviewStatus === ManualOrdersReview.NOT_REQUESTED ? (
              <M.Button
                kind='primary'
                onClick={handleSubmitRequest}
                data-testid='manual-orders-submit-request'
              >
                Submit Request
              </M.Button>
            ) : (
              <M.Button
                kind='primary'
                data-testid='manual-orders-request-submitted'
                disabled
              >
                Request Submitted
              </M.Button>
            )}
          </>
        ) : (
          <>
            <M.Button
              kind='tertiary'
              onClick={handleClose}
              data-testid='manual-orders-modal-close'
            >
              Close
            </M.Button>
            <M.Button
              kind='primary'
              onClick={handleDownloadAllForms}
              data-testid='manual-orders-download-all'
            >
              Download forms
            </M.Button>
          </>
        )}
      </M.ModalFooter>
    </ComposedModal>
  );
};

export default ManualOrderModal;
