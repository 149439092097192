import React from 'react';
import { Type as FairChanceMetrics } from 'modules/fair-chance-metrics/models';
import AdverseActionRate from '../../sections/AdverseActionRate';
import Benefits from '../../sections/Benefits';
import Description from '../../sections/Intro';
import EngageMoreCandidates from '../../sections/EngageMoreCandidates';
import FairChanceOpportunity from '../../sections/FairChanceOpportunity';
import StateRequirements from '../../sections/StateRequirements';

type Props = {
  metrics?: FairChanceMetrics;
};

const LowPerformers: React.FC<Props> = ({ metrics }) => {
  if (!metrics) return null;

  return (
    <div data-testid='fair-chance-low-performers-container'>
      <Description />
      <AdverseActionRate />
      <FairChanceOpportunity metrics={metrics} />
      <StateRequirements />
      <EngageMoreCandidates />
      <Benefits />
    </div>
  );
};

export default LowPerformers;
