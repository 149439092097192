import React, { useCallback, useContext, useState, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';
import { useTranslation } from 'react-i18next';
import UserContext from 'context/CurrentUser';
import {
  Divider,
  StyledH3,
  StyledButton,
  GridCol,
  StyledFormLabel,
  ItalicizedMessage,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import {
  BillingAddressResponse,
  PutContactsParams,
  usePutBillingContacts,
} from '../../../api/payment';
import BillingContactForm from './BillingContactForm';

type Props = {
  contactsData?: Partial<BillingAddressResponse>;
  fetchLoading: any;
  fetchSuccess: any;
  fetchError: any;
};

const BillingContacts: React.FC<Props> = ({
  contactsData,
  fetchLoading,
  fetchSuccess,
  fetchError,
}) => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (!contactsData) {
      hideModal();
    }
  }, [contactsData, hideModal]);

  const { call: putContacts, result } = usePutBillingContacts();

  const submit = useCallback(
    (params: PutContactsParams) => {
      params.account_id = userData.account.id;
      putContacts(params).finally(() => {
        hideModal();
      });
    },
    [hideModal, putContacts, userData.account.id],
  );

  const data: Partial<PutContactsParams> = result?.data
    ? {
        preferred_email: result.data.email,
        additional_emails: result.data.emails
          ? result.data.emails
              .filter((email: { primary: boolean }) => !email.primary)
              .map((email: { email: string }) => email.email)
          : [],
      }
    : {
        preferred_email: contactsData?.email,
        additional_emails: contactsData?.emails
          ? contactsData?.emails
              .filter((email: { primary: boolean }) => !email.primary)
              .map((email: { email: string }) => email.email)
          : [],
      };

  const isLoaded: boolean = result?.isSuccess || fetchSuccess;
  const isProcessing: boolean = result?.isLoading || fetchLoading;
  const error: true | Error | null = result?.isError || fetchError;

  return (
    <>
      <M.Grid>
        <M.GridRow>
          <CustomCol col={5} noPadding style={{ marginTop: '0' }}>
            <M.GridRow>
              <GridCol>
                <StyledH3 className='mb-0'>
                  {t(`billing_contacts.title`)}
                </StyledH3>
              </GridCol>
              {!data.preferred_email && (
                <GridCol style={{ textAlign: 'right' }}>
                  <StyledButton kind='primary' onClick={showModal}>
                    {t(`billing_contacts.add_new_btn`)}
                  </StyledButton>
                </GridCol>
              )}
            </M.GridRow>
            {data.preferred_email && (
              <M.GridRow className='mt-16'>
                <GridCol>
                  <div>
                    <StyledFormLabel>
                      {t(`billing_contacts.preferred_email_header`)}
                    </StyledFormLabel>
                    {data.preferred_email}
                    <br />
                    <br />
                    <StyledFormLabel>
                      {t(`billing_contacts.additional_emails_header`)}
                    </StyledFormLabel>
                    {data.additional_emails &&
                    data.additional_emails.length > 0 ? (
                      data.additional_emails.join(', ')
                    ) : (
                      <>
                        {t(`billing_contacts.no_additional_emails`)}{' '}
                        <ItalicizedMessage>
                          {t(`billing_contacts.add_additional_emails_message`)}
                        </ItalicizedMessage>
                      </>
                    )}
                  </div>
                </GridCol>
                <GridCol style={{ textAlign: 'right' }}>
                  <StyledButton
                    kind='secondary'
                    data-test-id='contacts-edit-button'
                    onClick={showModal}
                  >
                    {t(`payment.button_labels.edit`)}
                  </StyledButton>
                </GridCol>
              </M.GridRow>
            )}
            <Divider />
          </CustomCol>
        </M.GridRow>
      </M.Grid>

      {isLoaded && (
        <BillingContactForm
          contactsData={data}
          submit={submit}
          modalOpen={modalOpen}
          hideModal={hideModal}
          isProcessing={isProcessing}
          error={error}
        />
      )}
    </>
  );
};

export default BillingContacts;
