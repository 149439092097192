import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { Button, InternalContainer, Typography } from './styles';

import { CenteredIcon } from '../SelectPackage/styles';

type Props = {
  isInternational: boolean;
  onClick: any;
};

const CountryToggleButton: React.FC<Props> = ({
  onClick,
  isInternational,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      id='country-toggle-button'
      data-testid='country-toggle-button'
      className='country-toggle-button'
    >
      <InternalContainer>
        <Typography>
          {isInternational ? 'Hiring in the US?' : 'Hiring outside of the US?'}
        </Typography>
        <CenteredIcon icon='Globe' color={colors.uiNavy600} />
      </InternalContainer>
    </Button>
  );
};

export default CountryToggleButton;
