import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 8px 0 16px 0;
  h3 {
    padding-bottom: 0;
    margin-bottom: 4px;
    color: ${colors.uiTextPrimaryLight};
    line-height: 20px;
  }
`;
const listStyle = { marginTop: '0' };

const defaultKeyStyle = {
  width: '10rem',
  paddingRight: '0',
};

type Props = {
  keyStyle?: any;
  title?: string;
  items: { itemKey: any; itemValue: any }[];
};

const Section: React.FC<Props> = ({
  title,
  items,
  keyStyle = defaultKeyStyle,
}) => (
  <Wrapper>
    {title && <h6>{title}</h6>}
    <M.KeyValueList style={listStyle} keyStyle={keyStyle} items={items} />
  </Wrapper>
);

export default Section;
