import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { FieldComponentProps } from '../types';

const Adjudication: React.FC<FieldComponentProps> = ({
  report,
  statusType,
  displayAdjudication,
  testid,
}) => {
  return (
    <div data-testid={testid}>
      {displayAdjudication && (
        <M.StatusBadge statusType={statusType} status={report.adjudication} />
      )}
      {!displayAdjudication && <span>-</span>}
    </div>
  );
};

export default Adjudication;
