import { GenericObject } from 'types';

export enum Screenings {
  I3Report = 'i3Report',
  Health = 'Health',
  V2Drug = 'V2Drug',
}
export type ReportScreening = Screenings[keyof Screenings];
export type Header = {
  key: string;
  header: string;
  props?: { [x: string]: any };
};
export type Headers = Header[];
export type TableParams = {
  rows: any[];
  headers: Headers;
  assessEnabled: boolean | undefined;
  statusOrAssessment: string;
  type: Screenings[keyof Screenings];
  tableProps?: { [x: string]: any };
  canceledStatusEnabled: boolean | null | undefined;
};
export type DrugHealthProps = {
  screening: { [x: string]: any };
  title: string;
  disposition: string;
  reportStatusType: string;
  screeningTestId: string;
  timelineStatuses: any[];
  timelineKey: string;
};
export type RefType = GenericObject;
