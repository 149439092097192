import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifier } from 'hooks';
import * as Notifications from 'state/notifications/entity';
import Button from 'components/Button';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import styled from 'styled-components';
import { usePromote } from '../../version/api';

const ModalButton = styled(Button)`
  text-transform: capitalize;
`;

export type Type = React.FC<Props>;

type Props = {
  item: Entity;
  selectedVersion: Version.Basic;
  onClose: () => void;
};

export const Footer: Type = ({ item, selectedVersion, onClose }) => {
  const { t } = useTranslation('assess');
  const title = t('assess:mvr.category-sets.instance.versions.promote.title');
  const promote = usePromote(item.id, selectedVersion.id);
  const notifier = useNotifier();

  const onPromoteHandler = useCallback(async () => {
    try {
      await promote.call();

      const message = t(
        'assess:mvr.category-sets.instance.versions.promote.messages.success',
      );
      const params = Notifications.success({ title, message });
      notifier(params);
    } catch {
      const message = t(
        'assess:mvr.category-sets.instance.versions.promote.messages.error',
      );
      const params = Notifications.error({
        title,
        message,
      });
      notifier(params);
    } finally {
      onClose();
    }
  }, [onClose, notifier, promote, t, title]);

  return (
    <>
      <ModalButton kind='secondary' trans='adverbs.no' onClick={onClose} />
      <ModalButton
        kind='danger'
        trans='adverbs.yes'
        onClick={onPromoteHandler}
      />
    </>
  );
};

export default Footer;
