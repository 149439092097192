import { isObject } from 'lodash';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Namespace } from 'modules/assess/api';
import { Type } from 'modules/assess/models/rulesets/ruleset';
import { VersionInfo } from 'modules/assess/v2/ui/common';
import { ID } from 'modules/id';
import { Export, Upgrade } from './actions';
import Header from './Header';
import Reports from './Reports';

type Props = {
  namespace?: Namespace;
  ruleset?: Type;
};

const Content: React.FC<Props> = ({
  namespace = Namespace.criminal,
  ruleset,
}) => {
  if (!isObject(ruleset)) {
    return null;
  }

  const { id } = ruleset;
  const dataTestId = `assess-v2-${namespace}-records`;

  return (
    <M.Container
      type='default'
      data-testid={dataTestId}
      id='assess-v2-ui-home-guidelines-records-content'
    >
      <Header namespace={namespace} id={id as ID} />
      <Reports stats={ruleset.stats} />
      <VersionInfo ruleset={ruleset} />
      <M.Container.Row style={{ padding: '1.5rem 0' }}>
        <Export id={id} />
      </M.Container.Row>
      <Upgrade />
    </M.Container>
  );
};

export default Content;
