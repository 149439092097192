/* eslint-disable import/prefer-default-export */
export const isNextButtonDisabled = (
  appointmentDeadline: string,
  allowNewAppointment?: string,
) => {
  return (
    !allowNewAppointment ||
    (allowNewAppointment === 'Yes' && !appointmentDeadline)
  );
};
