import React, { useEffect } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useGetAdjudicationSubtypes,
  useFetchAdverseActionTemplates,
} from 'modules/adjudication/api';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useModalState, useDispatch } from '../../../context';
import { useInitializeAdverseItems, useNextDisabled } from './hooks';
import Header from './header';
import Content from '../content';
import Warnings from '../../warnings';
import ErrorMessage from '../../errors';
import ItemsAndBasisSelector from './items-and-basis-selector';
import Options from '../shared/options';
import { useGetReport } from '../../../../../../../../../api/reports';

const Container = styled.div`
  overflow: auto;
  background: ${colors.uiGrey0};
`;

const preview_email = `${namespace}:report.actions.pre_adverse.modal.preview_email.action`;
const next_action = `${namespace}:report.actions.pre_adverse.modal.footer.next`;

const AdverseItems: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { basis } = useModalState('state');
  const { adverseActionSubtypes } = useGetAdjudicationSubtypes();
  const currentScreen = useModalState('currentScreen');
  const transitionToScreen = useModalState('transitionToScreen');
  const dispatch = useDispatch();
  const { FCI_FLOW_ENABLED, OPTIONAL_FCI_FLOW_ENABLED, isLoading } =
    useInitializeAdverseItems();
  const { error } = useFetchAdverseActionTemplates();
  const disabled = useNextDisabled();
  const { report } = useGetReport();

  const mediumSelectionEnabled =
    report?.account?.adverse_action_medium_selection;

  const preivewEmailAction = t(preview_email);

  useEffect(() => {
    const nextName = t(next_action);

    const back =
      adverseActionSubtypes?.length > 1
        ? { onClick: () => transitionToScreen('subtypes') }
        : null;
    const next = {
      onClick: () => transitionToScreen('preview_email'),
      name: preivewEmailAction,
      disabled,
    };

    if (basis !== 'candidate_action_unresolved') {
      if (FCI_FLOW_ENABLED) {
        next.onClick = () => transitionToScreen('fair_chance_form');
        next.name = nextName;
      } else if (OPTIONAL_FCI_FLOW_ENABLED) {
        next.onClick = () => transitionToScreen('fair_chance');
        next.name = nextName;
      }
    }

    if (next.name !== nextName && mediumSelectionEnabled) {
      next.onClick = () => transitionToScreen('config_options');
      next.name = nextName;
    }

    const skip = null;

    dispatch({ type: 'SET_NAVIGATION', payload: { back, next, skip } });
    // transitionToScreen is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    dispatch,
    disabled,
    preivewEmailAction,
    adverseActionSubtypes?.length,
    FCI_FLOW_ENABLED,
    OPTIONAL_FCI_FLOW_ENABLED,
  ]);

  return (
    <Container>
      {isLoading ? (
        <M.LoadingInline />
      ) : (
        <Content>
          <ErrorMessage errors={error} />
          <Warnings currentScreen={currentScreen} />
          <Header />
          <ItemsAndBasisSelector />
          {!mediumSelectionEnabled && <Options />}
        </Content>
      )}
    </Container>
  );
};

export default AdverseItems;
