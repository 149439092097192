import React from 'react';
import Button from 'components/Button';
import { useReadOnly } from 'modules/assess/ui/hooks';

type Props = {
  onClick: () => void;
};

type Type = React.FC<Props>;

export const Edit: Type = ({ onClick }) => {
  const action = useReadOnly() ? 'view' : 'edit';

  return (
    <Button
      data-testid={`assess-ruleset-version-actions-${action}`}
      size='sm'
      trans={`assess:ruleset.version.actions.${action}`}
      onClick={onClick}
    />
  );
};

export default Edit;
