import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from '../../context/CurrentUser';
import { GH_DASHBOARD_URL } from '../../Constants';

const BackToGoodhireBanner: React.FC = () => {
  const userData = useUser();
  const showBanner = userData.account.referrer?.startsWith('gh');

  if (!showBanner) {
    return null;
  }

  return (
    <M.Container type='info'>
      <M.Container.Row>
        <M.Container.Col sm={12} md={4} style={{ fontSize: '16px' }}>
          Review historical GoodHire reports
        </M.Container.Col>
        <M.Container.Col sm={12} md={4} style={{ textAlign: 'right' }}>
          <a
            target='_blank'
            rel='noreferrer'
            href={GH_DASHBOARD_URL}
            style={{ fontWeight: 'bold' }}
          >
            <M.Icon
              icon='Link'
              size={16}
              style={{ marginRight: '0.5rem', verticalAlign: 'top' }}
            />
            Log into GoodHire
          </a>
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

export default BackToGoodhireBanner;
