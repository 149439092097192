import React, { useCallback, useState } from 'react';
import { useUser } from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY } from 'Constants';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_BULKORDER_NAMES,
} from 'utils/analytics';

import BulkOrderHistoryTable from './BulkOrderHistoryTable';
import { useGetAggregatedBulkOrderHistory } from '../../../api/billing';
import { Pagination } from '../BulkOrderHistoryStyled';

const tableHeaders = [
  {
    key: 'invitation_status',
    header: 'invitationStatus',
    name: 'invitationStatus',
  },
  {
    key: 'submitted_date',
    header: 'date',
    name: 'date',
  },
  {
    key: 'package',
    header: 'package',
    name: 'package',
  },
  {
    key: 'private_uri',
    header: 'file',
    name: 'file',
  },
  {
    key: 'uploader_email',
    header: 'uploader',
    name: 'uploader',
  },
  {
    key: 'result_private_uri',
    header: 'errors',
    name: 'errors',
  },
];

const BulkOrderHistoryTableContainer = () => {
  const currentUser = useUser();
  const PAGE_SIZE = 10;
  const [page, setPage] = useState(0);

  const bulkInviteFlagr: number = Number(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const bulkInviteFlagrEnabled: boolean = !isNaN(bulkInviteFlagr);

  const trackEvent = useTrackEvent();

  const { data, isLoading } = useGetAggregatedBulkOrderHistory(
    currentUser.account.id,
  );
  const orderHistoryResponse = data?.data;

  const pageCount = Math.ceil((orderHistoryResponse?.length || 0) / PAGE_SIZE);

  const indexOfLastHistory = page * PAGE_SIZE + 10;
  const indexOfFirstHistory = indexOfLastHistory - PAGE_SIZE;

  const currentOrderHistory = orderHistoryResponse?.slice(
    indexOfFirstHistory,
    indexOfLastHistory,
  );

  const onPageClick = useCallback(
    pageIndex => {
      if (bulkInviteFlagrEnabled) {
        trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.HISTORY_NAVIGATION, {
          Pagination: `Page number ${pageIndex + 1}`,
        });
      }

      setPage(pageIndex);
    },
    [bulkInviteFlagrEnabled, trackEvent],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isLoading && !orderHistoryResponse?.length) {
    return null;
  }

  return (
    <>
      <M.DataTable rows={currentOrderHistory} headers={tableHeaders}>
        {({ rows, headers }: any) => (
          <BulkOrderHistoryTable
            headers={headers}
            rows={rows}
            data={currentOrderHistory}
          />
        )}
      </M.DataTable>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          selectedIndex={page}
          onPageClick={onPageClick}
        />
      )}
    </>
  );
};

export default BulkOrderHistoryTableContainer;
