import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StepsContainer = styled.div`
  .cd-multi-steps {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    border-radius: 0.25em;
    padding: 0px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .cd-multi-steps:after {
    content: '';
    display: table;
    clear: both;
  }
  .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
  }
  .cd-multi-steps li::after {
    /* this is the separator between items */
    display: inline-block;
    content: '>';
    margin: 0 0.6em;
    color: ${colors.brandSlate5};
  }
  .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
  }
  .cd-multi-steps li > * {
    /* single step */
    display: inline-flex;
  }
  .cd-multi-steps li.current > * {
    position: relative;
    color: ${colors.uiNavy600};
    transition: color 0.5s;
  }

  .cd-multi-steps li.current .cd-multi-steps li.current > * {
    /* selected step */
    color: ${colors.uiNavy700};
  }
  .no-touch .cd-multi-steps a:hover {
    /* steps already visited */
    color: ${colors.uiNavy700};
  }
  .cd-multi-steps.custom-separator li::after {
    /* replace the default arrow separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    vertical-align: middle;
  }

  @media only screen and (min-width: 768px) {
    .cd-multi-steps {
      padding: 0 1.2em;
    }
    .cd-multi-steps li {
      margin: 1.2em 0;
    }
    .cd-multi-steps li::after {
      margin: 0 1em;
    }
  }

  @media only screen and (min-width: 768px) {
    .cd-multi-steps {
      /* reset style */
      background-color: transparent;
      padding: 0;
      text-align: center;
    }

    .cd-multi-steps li {
      position: relative;
      float: none;
      margin: 0 40px 0 60px;
    }
    .cd-multi-steps li:last-of-type {
      margin-right: 0;
      margin-left: 0;
    }
    .cd-multi-steps li::after {
      /* this is the line connecting 2 adjacent items */
      position: absolute;
      content: '';
      height: 1px;
      background: ${colors.brandSlate3};
      /* reset style */
      margin: 0;
    }
    .cd-multi-steps li.visited::after {
    }
    .cd-multi-steps li > *,
    .cd-multi-steps li.current > * {
      position: relative;
      transition: color 0.5s;
    }

    .cd-multi-steps.custom-separator li::after {
      /* reset style */
      height: 4px;
      background: ${colors.uiNavy50};
    }

    .cd-multi-steps.text-center li::after {
      width: 100%;
      top: 50%;
      left: 100%;
      -webkit-transform: translateY(-50%) translateX(-1px);
      -moz-transform: translateY(-50%) translateX(-1px);
      -ms-transform: translateY(-50%) translateX(-1px);
      -o-transform: translateY(-50%) translateX(-1px);
      transform: translateY(-50%) translateX(-1px);
    }
    .cd-multi-steps.text-center li > * {
      z-index: 1;
      padding: 0.6em 1em;
      border-radius: 0.25em;
      background-color: ${colors.uiNavy50};
    }
    .no-touch .cd-multi-steps.text-center a:hover {
      background-color: ${colors.uiGrey500};
    }
    .cd-multi-steps.text-center li.current > *,
    .cd-multi-steps.text-center li.visited > * {
      color: ${colors.uiGrey0};
      background-color: ${colors.uiNavy700};
    }
    .cd-multi-steps.text-center.custom-icons li.visited a::before {
      /* change the custom icon for the visited item - check icon */
      background-position: 0 -60px;
    }

    .cd-multi-steps.text-top li,
    .cd-multi-steps.text-bottom li {
      text-align: center;
    }
    .cd-multi-steps.text-top li::after,
    .cd-multi-steps.text-bottom li::after {
      /* this is the line connecting 2 adjacent items */
      position: absolute;
      left: 50%;
      /* 40px is the <li> right margin value */
      width: 186px;
    }
    .cd-multi-steps.text-top li > *::before,
    .cd-multi-steps.text-bottom li > *::before {
      /* this is the spot indicator */
      content: '';
      position: absolute;
      z-index: 1;
      left: 50%;
      right: auto;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: ${colors.uiNavy50};
    }
    .cd-multi-steps.text-top li.visited > *::before,
    .cd-multi-steps.text-top li.current > *::before,
    .cd-multi-steps.text-bottom li.visited > *::before,
    .cd-multi-steps.text-bottom li.current > *::before {
      background-color: ${colors.uiNavy700};
    }
    .no-touch .cd-multi-steps.text-top a:hover,
    .no-touch .cd-multi-steps.text-bottom a:hover {
      color: ${colors.uiNavy700};
    }
    .no-touch .cd-multi-steps.text-top a:hover::before,
    .no-touch .cd-multi-steps.text-bottom a:hover::before {
      box-shadow: 0 0 0 3px rgba(150, 192, 61, 0.3);
    }

    .cd-multi-steps.text-top li::after {
      /* this is the line connecting 2 adjacent items */
      bottom: 4px;
    }
    .cd-multi-steps.text-top li > * {
      padding-bottom: 20px;
    }
    .cd-multi-steps.text-top li > *::before {
      /* this is the spot indicator */
      bottom: 0;
    }

    .cd-multi-steps.text-bottom li::after {
      /* this is the line connecting 2 adjacent items */
      top: 3px;
    }
    .cd-multi-steps.text-bottom li > * {
      padding-top: 10px;
    }
    .cd-multi-steps.text-bottom li > *::before {
      /* this is the spot indicator */
      top: 0;
    }
  }

  .cd-multi-steps.count li {
    counter-increment: steps;
  }

  .cd-multi-steps.count li > *::before {
    margin-right: 3px;
  }

  @media only screen and (min-width: 768px) {
    .cd-multi-steps.text-top.count li > *::before,
    .cd-multi-steps.text-bottom.count li > *::before {
      /* this is the spot indicator */
      height: 19px;
      width: 19px;
      background: ${colors.brandSlate3};
      border: 4px solid ${colors.uiNavy50};
      transition: background-color 0.5s, border 0.5s, box-shadow 0.25s;
    }

    .cd-multi-steps.text-top.count li span::before,
    .cd-multi-steps.text-bottom.count li span::before {
      color: ${colors.brandSlate7};
    }

    .cd-multi-steps.text-top.count li.current span::before,
    .cd-multi-steps.text-bottom.count li.current span::before {
      background-color: ${colors.uiNavy600};
      border: 4px solid ${colors.uiNavy50};
      box-shadow: 0 0 0 3px ${colors.uiNavy600}, 0 0 0 7px ${colors.uiNavy50};
    }

    .cd-multi-steps.text-top.count li.current span,
    .cd-multi-steps.text-bottom.count li.current span {
      color: ${colors.brandNavy2};
    }

    .cd-multi-steps.text-top.count li.visited span::before,
    .cd-multi-steps.text-bottom.count li.visited span::before {
      /* steps not visited yet - counter color */
      background: ${colors.uiNavy600};
    }

    .cd-multi-steps.text-top.count li::after {
      bottom: 11px;
    }

    .cd-multi-steps.text-top.count li > * {
      padding-bottom: 35px;
    }

    .cd-multi-steps.text-bottom.count li::after {
      top: 9px;
    }

    .cd-multi-steps.text-bottom.count li > * {
      padding-top: 30px;
    }

    .current {
      font-weight: 700;
      color: ${colors.brandNavy2} !important;
    }

    .visited {
      font-weight: 700;
      color: ${colors.brandNavy2} !important;
    }
  }
`;

export default StepsContainer;
