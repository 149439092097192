import React from 'react';
import { Ctx } from './types';

const initialCtx = {
  errors: {},
  touched: {},
  values: {},
  handleChange: () => {},
  handleBlur: () => {},
};

export const CCContext = React.createContext<Ctx>(initialCtx);

export const BAContext = React.createContext<Ctx>(initialCtx);

export const BillingAddressContext = React.createContext<Ctx>(initialCtx);

export const BillingContactContext = React.createContext<Ctx>(initialCtx);
