import { useMemo } from 'react';
import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { GenericObject } from 'types';
import { getUserTags, updateTags } from './actions';

export const useUpdateTags = (
  reportId: string,
  tags: string[],
  tagsBackup: string[],
) => {
  const request = () => updateTags(reportId, tags, tagsBackup);

  const dispatch = useDispatch();

  const [updateTagsCall, updateTagsResult] = useMutation(request, {
    onError: err => {
      dispatch(toastError(`Error Updating Tags: ${err}`));
    },
  });

  return { updateTagsCall, updateTagsResult };
};

export const useGetUserTags = (accountId: string) => {
  const request = () => getUserTags(accountId);
  const key: AnyQueryKey = ['userTags', { accountId }];
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  const userTags = useMemo(
    () => result?.data || { tags: [] },
    [result?.data],
  ) as GenericObject;
  return userTags;
};
