import React, { useMemo } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { EmploymentQuestion } from 'types';

export type DOTv1QuestionsProps = {
  testId: string;
  questions: EmploymentQuestion[];
};

type QuestionProps = {
  text: string;
  response: string;
};

const QA = styled.div`
  line-height: 20px;
  .question-text {
    color: ${colors.uiTextPrimaryLight};
  }
  .response-text {
    color: ${colors.uiGrey600};
  }
  margin-top: 0;
  margin-bottom: 16px;
`;

const Question: React.FC<QuestionProps> = ({ text, response }) => (
  <QA data-testid='employment-dot-v1-question'>
    <div
      data-testid='employment-dot-v1-question-text'
      className='question-text'
    >
      {text}
    </div>
    <div
      data-testid='employment-dot-v1-response-text'
      className='response-text'
    >
      {response}
    </div>
  </QA>
);

const DOTv1Questions: React.FC<DOTv1QuestionsProps> = ({
  testId,
  questions,
}) => {
  const sortedQuestions = useMemo(
    () =>
      [...questions].sort(
        (a: EmploymentQuestion, b: EmploymentQuestion) =>
          a.sort_number - b.sort_number,
      ),
    [questions],
  );
  return (
    <div data-testid={testId}>
      <h3>DOT Drug and Alcohol Testing</h3>
      {sortedQuestions.map((question: EmploymentQuestion) => (
        <Question
          key={question.sort_number}
          text={question.text}
          response={question.response}
        />
      ))}
    </div>
  );
};

export default DOTv1Questions;
