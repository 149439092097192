import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  ONE,
  MODAL_STEPS,
  TWO,
  INITIAL_VALIDATIONS,
  fieldValidator,
  mapBackendFieldsToFrontend,
  getInvalidErrorMessage,
} from 'components/AddScreenings/shared/ModalComponents';
import DrugScreeningSetup, {
  DrugScreeningStateType,
} from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/DrugScreeningSetup';
import { useUser } from 'context/CurrentUser';
import { stateName } from 'utils';
import {
  DrugSetupData,
  useDrugSetup,
} from 'components/AddScreenings/hooks/useDrugSetup';
import CompletedSetup from './CompletedSetup';

const Footer = styled(M.ModalFooter)`
  display: flex;
  justify-content: right;
`;

type DrugScreeningSetupModalProps = {
  open: boolean;
  handleClose: Function;
};

const DrugSetupDescription = {
  email: 'We’ll email you after set up is complete.',
  calendar: 'This typically takes 7-10 business days. ',
  shoppingCatalog: 'Then you can start using drug tests.',
};

export const checkEmptyFields = (object: any) => {
  return Object.keys(object).filter((key: any) => {
    const value = object[key];
    return value === '' || value === null || value === undefined;
  });
};

const DrugScreeningSetupModal: React.FC<DrugScreeningSetupModalProps> = ({
  open,
  handleClose,
}) => {
  const currentUser = useUser();
  const {
    account: {
      id: accountId,
      company: { street, city, zipcode, state: companyState },
    },
  } = currentUser;
  const companyStateName = stateName(companyState);
  const [state, setState] = useState<DrugScreeningStateType>({
    name: '',
    email: '',
    phoneNumber: '',
    street: street || '',
    city: city || '',
    zip: zipcode || '',
    state: companyStateName || '',
  });
  const [hasError, setHasError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [validationWarnings, setValidationWarnings] =
    useState(INITIAL_VALIDATIONS);

  const { call: setupDrug, result: setupDrugResult } = useDrugSetup({
    accountId,
  });

  const handleSubmitClick = useCallback(() => {
    const { fieldsAreValid, invalidFields } = fieldValidator(
      state.email,
      state.phoneNumber,
      state.city,
      state.zip,
      state.street,
      state.state,
      state.name,
    );
    setValidationWarnings(invalidFields);

    if (fieldsAreValid) {
      const data: DrugSetupData = {
        contact_name: state.name,
        contact_email: state.email,
        contact_phone: state.phoneNumber,
        business_address_street: state.street,
        business_address_city: state.city,
        business_address_state: state.state,
        business_address_zipcode: state.zip,
      };
      setupDrug(data);
    }
  }, [
    setupDrug,
    state.city,
    state.email,
    state.name,
    state.phoneNumber,
    state.state,
    state.street,
    state.zip,
  ]);

  useEffect(() => {
    if (setupDrugResult.isSuccess && MODAL_STEPS[currentStep] === ONE) {
      setCurrentStep(currentStep + 1);
      setValidationWarnings(INITIAL_VALIDATIONS);
    }
    if (
      setupDrugResult.isError &&
      validationWarnings.email.isValid &&
      !hasError
    ) {
      const errorField = (setupDrugResult.error as any).response.data.errors[0]
        .field;
      const field = mapBackendFieldsToFrontend(errorField);
      const fieldErrorMessage = getInvalidErrorMessage(field);
      setValidationWarnings({
        ...validationWarnings,
        [field]: { isValid: false, error: fieldErrorMessage },
      });
      setHasError(true);
    }
  }, [
    setupDrugResult.error,
    setupDrugResult.isSuccess,
    currentStep,
    setupDrugResult.isError,
    validationWarnings,
    setupDrugResult,
    hasError,
  ]);

  return (
    <M.ComposedModal
      data-testid='drug-test-setup-modal'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='drug-test-setup-modal-header'
        closeModal={handleClose}
      >
        <h2>Set up drug tests</h2>
      </M.ModalHeader>
      <M.ModalBody id='drug-test-setup-modal-body'>
        <div data-testid='drug-test-modal-body'>
          {setupDrugResult.isLoading && <M.LoadingSpinner />}
          {MODAL_STEPS[currentStep] === ONE && (
            <DrugScreeningSetup
              state={state}
              setState={setState}
              validationWarnings={validationWarnings}
            />
          )}
          {MODAL_STEPS[currentStep] === TWO && (
            <CompletedSetup description={DrugSetupDescription} />
          )}
        </div>
      </M.ModalBody>
      {MODAL_STEPS[currentStep] === ONE && (
        <Footer>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='drug-test-modal-submit-button'
            onClick={handleSubmitClick}
          >
            Submit
          </M.Button>
        </Footer>
      )}
      {MODAL_STEPS[currentStep] === TWO && (
        <Footer>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='drug-test-modal-close-button'
            onClick={handleClose}
          >
            Close
          </M.Button>
        </Footer>
      )}
    </M.ComposedModal>
  );
};

export default DrugScreeningSetupModal;
