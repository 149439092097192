import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { PanelProps, Field } from '../types';
import {
  LocationLabelContainer,
  BusinessHoursContainer,
  LabelAddressContainer,
  DistanceContainer,
} from '../styles';
import { formatPhoneNumber, getAvailability, ScheduleHelpers } from '../utils';

const Panel: React.FC<PanelProps> = ({
  siteId,
  siteName,
  address1,
  address2,
  appState,
  updateState,
  distance,
  distanceUnit,
  phoneNumber,
  translations,
  hours,
}) => {
  const { clinic } = appState;
  const isSelected = siteId === clinic?.id;
  const businessHours = getAvailability({
    hours,
  } as Pick<ScheduleHelpers, 'hours'>);
  const { schedule } = translations;

  const businessHoursString = `${schedule} ${businessHours.daysOpen.join(
    ' - ',
  )}`;

  const setClicId = useCallback(
    (value: string) =>
      updateState(
        {
          ...appState,
          [Field.Clinic]: { name: siteName?.toUpperCase(), id: value },
        },
        Field.Clinic,
      ),
    [appState, siteName, updateState],
  );

  return (
    <M.ChoiceCard
      id={siteId}
      name={siteName}
      value={siteId}
      onChange={setClicId}
      checked={isSelected}
      title={
        <LocationLabelContainer>
          <div className='location-label-header-container'>
            {siteName?.toUpperCase()}
            <br />
            <LabelAddressContainer>
              {`${address1}
            
        ${address2 ? `, ${address2}` : ''}
        `}
            </LabelAddressContainer>
          </div>
          <DistanceContainer>{`${distance} ${distanceUnit}`}</DistanceContainer>
        </LocationLabelContainer>
      }
      description={
        <LabelAddressContainer>
          <span>{formatPhoneNumber(phoneNumber)}</span>
          {isSelected ? (
            <div>
              {businessHours?.dayBlocks?.map(
                ({ daySpanFull, businessHours }, idx) => (
                  <BusinessHoursContainer key={`days-${idx + 1}`}>
                    <p>
                      <strong>{daySpanFull.join(' - ')}</strong>
                    </p>
                    <p>{businessHours}</p>
                  </BusinessHoursContainer>
                ),
              )}
            </div>
          ) : (
            <DistanceContainer>{businessHoursString}</DistanceContainer>
          )}
        </LabelAddressContainer>
      }
    />
  );
};

export default Panel;
