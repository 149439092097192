import { Geo, User } from 'types';

export const formatGeo = (geo: Geo, currentUser: User) => {
  const domesticGeo = !geo.country || geo.country === 'US';

  if (currentUser?.account?.segmentation_enabled && domesticGeo) {
    return [geo.state, geo.city].filter(x => x).join(' - ');
  }
  return geo.name;
};

export const formatLocation = (
  country: string,
  state: string,
  city: string,
) => {
  return [country, state, city].filter(x => x).join(' - ');
};
