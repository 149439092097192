import React from 'react';
import styled from 'styled-components';

export type Props = {};

const BoxContainer = styled.div`
  box-shadow: -1px 1px 2px 1px rgb(0 100 0);
  border-radius: 0.25em;
  padding: 1em 0 1em 2em;
  width: 20em;
`;

export const Box: React.FC<Props> = ({ children }) => {
  return <BoxContainer className='box'>{children}</BoxContainer>;
};

export default Box;
