import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { InfoPanel } from 'components/Segments';

const PanelContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SegmentsEmptyTableBody: React.FC = () => {
  return (
    <M.TableBody>
      <M.TableRow>
        <td colSpan={4}>
          <PanelContainer>
            <InfoPanel>
              <p>
                <span className='icon'>⚠</span>
                Find nodes by using the search parameters above
              </p>
            </InfoPanel>
          </PanelContainer>
        </td>
      </M.TableRow>
    </M.TableBody>
  );
};

export default SegmentsEmptyTableBody;
