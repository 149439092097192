import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useGetCsv } from 'api/csvExport';
import { Flex } from 'components';
import { useTrackEvent, CSV_EVENTS } from 'utils/analytics';
import { namespace } from './locales';

type Props = {
  id: string;
};

const Container = styled(Flex)`
  > * {
    margin-bottom: 1rem;
  }
  margin: 2.5rem auto 0;
  text-align: center;

  .title {
    font-size: 20px;
    color: ${colors.uiNavy600};
  }
  .error-icon {
    fill: ${colors.brandOrangeAccent2};
  }
  .success-icon {
    fill: ${colors.brandGreen3};
  }
  .loading-icon {
    fill: ${colors.uiNavy600};
  }
`;

const View: React.FC<Props> = ({ id }) => {
  const { data = {}, isError, isSuccess, isLoading } = useGetCsv(id);
  // @ts-ignore
  const { private_uri } = data;
  const error = isError || !private_uri;

  return (
    // @ts-ignore
    <Container testId='csv-export-view' align='center' column>
      {error && !isLoading && <Error />}
      {isSuccess && <Success uri={private_uri} />}
      {isLoading && <Loading />}
    </Container>
  );
};

const Loading = () => {
  const { t } = useTranslation();
  const title = t(`${namespace}:loading.title`);
  return (
    <>
      <M.Icon className='loading-icon' icon='Package' size={40} />
      <div className='title'>{title}</div>
      <M.LoadingInline style={{ width: 'auto' }} />
    </>
  );
};

const Error = () => {
  const { t } = useTranslation();
  const title = t(`${namespace}:error.title`);
  const subtitle = t(`${namespace}:error.subtitle`);

  return (
    <>
      <M.Icon className='error-icon' icon='Misuse' size={40} />
      <div className='title'>{title}</div>
      <span>{subtitle}</span>
    </>
  );
};

const Success = ({ uri }: { uri: string }) => {
  const { t } = useTranslation();
  const title = t(`${namespace}:success.title`);
  const action = t(`${namespace}:success.action`);

  const trackEvent = useTrackEvent();

  const onClick = useCallback(() => {
    trackEvent(CSV_EVENTS.CSV_VIEW_CSV_DOWNLOAD);
  }, [trackEvent]);

  return (
    <>
      <M.Icon className='success-icon' icon='CheckmarkFilled' size={40} />
      <div className='title'>{title}</div>
      <M.Button role='link' href={uri} onClick={onClick} download='export.csv'>
        {action}
      </M.Button>
    </>
  );
};

export default View;
