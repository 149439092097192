import isPlainObject from 'lodash/isPlainObject';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

import { Namespace } from 'modules/assess/api';
import * as API from 'modules/assess/api/insights';
import { uri } from 'modules/assess/api/ruleset-versions';
import { ID } from 'modules/id';
import { Entity } from 'modules/assess/models/insights';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useFetch = (version?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = uri(context, version);
  const key: AnyQueryKey = [namespace, { id: version }, 'insights'];

  const request = () => {
    if (!version) {
      return Promise.reject();
    }
    return API.fetch(context, version);
  };

  const result = useQuery(key, request);

  return result;
};

export const useUpdate = (version?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = uri(context, version);
  const key: AnyQueryKey = [namespace, { id: version }, 'insights'];

  const request = (params: Partial<Entity.Type>) => {
    if (!version) {
      return Promise.reject();
    }
    return Promise.reject(new Error('Not implemented'));
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return false;
        }

        if ((entity as { id: unknown }).id !== version) {
          return false;
        }

        return field === 'insights' && !data;
      });

      if (data) {
        // Update query if updated object was returned
        queryCache.setQueryData(key, data);
      }
    },
  });

  return {
    call,
    result,
  };
};
