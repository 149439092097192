import React from 'react';
import styled from 'styled-components';
import { ID } from 'modules/entity';
import { useDraft } from 'modules/assess/ui/ruleset/version/api';
import { Guideline as Amplitude } from 'modules/assess/v2/models/amplitude';
import { StorePage, Upgrade } from 'modules/assess/v2/ui/common';
import Actions from './Actions';
import Description from './Description';
import NatureOfOffense from './nature-of-offense';
import TimeSinceOffense from './time-since-offense';
import Title from './Title';

export type Props = {
  ruleset?: ID;
};

const Content = styled.div`
  > div {
    margin-bottom: 2rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Container: React.FC<Props> = ({ ruleset }) => {
  const query = useDraft(ruleset);

  return (
    <StorePage
      data={query?.data}
      id='assess-v2-ui-lite-container'
      status={query?.status}
      event={Amplitude.Pageview}
    >
      <Content>
        <Title />
        <Description />
        <NatureOfOffense />
        <TimeSinceOffense />
        <Actions />
        <Upgrade.Standard eventProperty={Amplitude.Page} />
      </Content>
    </StorePage>
  );
};

export default Container;
