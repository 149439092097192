import { List } from 'modules/assess/models/rules/condition';
import {
  CategoryCodeSetChange,
  ContextDateOptionChange,
  CountRuleChange,
  CustomRuleChange,
  PremadeRuleChange,
  RulesetVersionDiff,
  SettingsDiff,
} from 'modules/assess/models/rulesets/version-diff';
import { Field } from 'modules/assess/models/rulesets/version-diff/common';
import { isFieldUpdated } from 'modules/assess/models/rulesets/version-diff/common/field';

type RulesBase = Omit<RulesetVersionDiff.Map, 'count'>;
type CountRuleChangeRequest = CountRuleChange.Type & {
  selection_conditions: Field.Type<List>;
};

type RequestRules = RulesBase & {
  count: Array<CountRuleChangeRequest>;
};

type RequestData = RulesBase & {
  category_code_set_version?: CategoryCodeSetChange.Type;
  context_date_options?: ContextDateOptionChange.List;
  rules: RequestRules;
  settings: SettingsDiff.List;
};

export function fromAPI(data: RequestData): RulesetVersionDiff.Type {
  return {
    ...data,
    rules: rulesFromAPI(data.rules),
  };
}

function rulesFromAPI(rules: RequestRules): RulesetVersionDiff.Map {
  return {
    ...rules,
    custom: rules.custom.map(customRulesFromAPI),
    count: rules.count.map(countRulesFromAPI),
    premade: rules.premade.map(premadeRulesFromAPI),
  };
}

function countRulesFromAPI(
  change: CountRuleChangeRequest,
): CountRuleChange.Type {
  return {
    ...change,
    conditions: getCountRuleConditions(change),
  };
}

function customRulesFromAPI(
  change: CustomRuleChange.Type,
): CustomRuleChange.Type {
  return {
    ...change,
    item_visibility: itemVisibilityFromAPI(change.item_visibility),
  };
}

function premadeRulesFromAPI(
  change: PremadeRuleChange.Type,
): PremadeRuleChange.Type {
  return {
    ...change,
    item_visibility: itemVisibilityFromAPI(change.item_visibility),
  };
}

function itemVisibilityFromAPI(change?: Field.Type): Field.Type | undefined {
  if (!change) {
    return undefined;
  }

  return {
    translatePath: 'assess:item_visibility',
    ...change,
  };
}

function getCountRuleConditions(
  change: CountRuleChangeRequest,
): Field.Type<List> {
  if (
    isFieldUpdated(change.conditions) ||
    isFieldUpdated(change.selection_conditions)
  ) {
    return getChangedCountRuleConditions(change);
  }

  return getUnchangedCountRuleConditions(change);
}

function getChangedCountRuleConditions(
  change: CountRuleChangeRequest,
): Field.Type<List> {
  const conditions = change.conditions as Field.FieldChanged<List>;
  const activeConditions = conditions?.active || [];
  const draftConditions = conditions?.draft || [];

  const selectedConditions =
    change.selection_conditions as Field.FieldChanged<List>;
  const activeSelectionConditions = selectedConditions?.active || [];
  const draftSelectionConditions = selectedConditions?.draft || [];

  const unchangedConditions =
    (change.conditions as Field.FieldUnchanged<List>)?.value || [];
  const unchangedSelectionConditions =
    (change.selection_conditions as Field.FieldUnchanged<List>)?.value || [];

  const aggregateUnchangedConditions = unchangedConditions.concat(
    unchangedSelectionConditions,
  );

  return {
    active: activeConditions
      .concat(activeSelectionConditions)
      .concat(aggregateUnchangedConditions),
    draft: draftConditions
      .concat(draftSelectionConditions)
      .concat(aggregateUnchangedConditions),
  };
}

function getUnchangedCountRuleConditions(
  change: CountRuleChangeRequest,
): Field.Type<List> {
  const conditions =
    (change.conditions as Field.FieldUnchanged<List>)?.value || [];

  const selectionConditions =
    (change.selection_conditions as Field.FieldUnchanged<List>)?.value || [];

  return {
    value: conditions.concat(selectionConditions),
  };
}
