import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const BadgeStyled = styled.span`
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 0.7em;
  font-weight: bold;
  &.Complete {
    background-color: ${colors.uiGreen400};
    color: ${colors.uiGrey0} !important;
  }
  &.Processing {
    background-color: ${colors.uiYellow400};
    color: ${colors.uiGrey0} !important;
  }
  &.Review {
    background-color: ${colors.uiPink600};
    color: ${colors.uiGrey0} !important;
  }
`;

const StatusBadge = ({ status, text }) => {
  return <BadgeStyled className={status}>{text}</BadgeStyled>;
};

StatusBadge.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
};

StatusBadge.defaultProps = {
  status: '',
  text: '',
};

export default StatusBadge;
