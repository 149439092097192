import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

import { Namespace } from 'modules/assess/api';
import * as ruleset from 'modules/assess/api/ruleset';
import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { ID } from 'modules/id';
import isPlainObject from 'lodash/isPlainObject';
import { useNamespace } from '../../router/context';

export { usePath } from '../../rulesets/api';

export const useCreate = () => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);

  const request = (obj: ruleset.CreateParams) => {
    return ruleset.create(context, obj);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        return ns === namespace && !entity;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useDestroy = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.destroy(context, id);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        return !(entity as { id: unknown }).id;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useFetch = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }];

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.fetch(context, id);
  };

  const result = useQuery(key, request);

  return result;
};

export const useUpdate = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }];

  const request = (updates: Partial<Entity.Type>) => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.update(context, { id, ...updates });
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        const eid = (entity as { id: unknown }).id;

        if (!eid) {
          return true;
        }

        if (eid !== id) {
          return false;
        }

        return !field && !data;
      });

      if (data) {
        // Update query if updated object was returned
        queryCache.setQueryData(key, data);
      }
    },
  });

  return {
    call,
    result,
  };
};
