import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// Common styling for simpler modals found in the Reports page
const ReportModal = styled(M.ComposedModal)`
  .cds--modal-container {
    .mastodon-modal-body {
      p {
        padding-right: 0;
      }

      .cds--checkbox-wrapper {
        margin-top: 1rem;
      }

      .mastodon-inline-notification {
        margin-top: 0 !important;
      }
    }

    .mastodon-modal-footer {
      flex-wrap: wrap;

      .errorContainer {
        padding-top: 1rem;
        color: ${colors.uiPink600};
        text-align: right;
        width: 100%;
      }
    }
  }
`;

export default ReportModal;
