import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const PackagesSearchHeader = styled.h2`
  color: ${colors.uiTextPrimaryLight};
`;

export const PackagesTableCell = styled(M.TableCell)`
  white-space: nowrap;

  &.package-name-cell {
    max-width: 24rem;
  }
`;

export const PackagesTableListItem = styled.li`
  white-space: nowrap;
`;

export const PackageName = styled.div`
  margin-bottom: 1rem;
`;
