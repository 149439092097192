import { Screening } from 'components/AddScreenings/types/Screenings';
import { ActionType, BasePackage } from './CONSTANTS';
import { getBasePackage } from './packageHelpers';

export const setSelectedGeo = (geo: string) => {
  return {
    type: ActionType.SET_SELECTED_GEO,
    payload: { selectedGeo: geo },
  };
};

export const setBasePackage = (packageType: string) => {
  return {
    type: ActionType.SET_BASE_PACKAGE,
    payload: { package: getBasePackage(packageType) },
  };
};

export const addScreening = (screening: Screening) => {
  return {
    type: ActionType.SCREENING_ADDED,
    payload: screening,
  };
};

export const removeScreening = (screeningType: string) => {
  return {
    type: ActionType.SCREENING_REMOVED,
    payload: { type: screeningType },
  };
};

export const setPackageName = (packageName: string = '') => {
  return {
    type: ActionType.SET_PACKAGE_NAME,
    payload: { name: packageName },
  };
};

export const clearBasePackageAndAddons = (initialBasePackage: BasePackage) => {
  return {
    type: ActionType.CLEAR_BASE_PACKAGE_AND_ADDONS,
    payload: { package: initialBasePackage },
  };
};

export const setError = (errorMessage: string) => {
  return {
    type: ActionType.SET_ERROR,
    payload: { error: errorMessage },
  };
};

export const skipBasePackageSelection = () => {
  return {
    type: ActionType.SKIP_BASE_PACKAGE_SELECTION,
  };
};

export const setInfoLinksClicked = (infoLink: string) => {
  return {
    type: ActionType.SET_INFO_LINKS_CLICKED,
    payload: {
      infoLink,
    },
  };
};
