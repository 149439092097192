import * as Entity from 'modules/assess/models/rulesets/version';
import * as Actions from 'state/utilities/actions';

export type Action = Actions.AnyWithPayload<Payload>;
export type Creator = Actions.Creators.Payload<Payload>;
export type Payload = {
  dirty?: boolean;
} & Partial<Entity.Type>;

export const TYPE = 'checkr/assess/rulesets/versions/instance/update';
export const create: Creator = Actions.Creators.create(TYPE);
