/* eslint-disable react/display-name */
import React, { useContext, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  useToggle,
  GenericObject,
} from '@dashboard-experience/utils';
import { useUpgradeReport } from 'api/reports';
import UIContext from 'context/UI';
import { scrollToTop } from 'utils';
import { ConfirmModal } from 'components';
import { Package } from 'types';
import { renderModal } from 'modules/candidate';

export type OverflowItemProps = {
  packageItem: Package;
  reportId: string;
};

const noop = () => {};

const UpgradePackage = React.forwardRef<GenericObject, any>(
  ({ packageItem, reportId }, ref) => {
    const packageSlug = packageItem.slug;
    const { upgradeReportCall } = useUpgradeReport(reportId, packageSlug);
    const { isToggled, toggle } = useToggle();
    const { isStandaloneIframe } = useContext(UIContext);

    const confirmUpgrade = useCallback(() => {
      upgradeReportCall();
      toggle();
    }, [upgradeReportCall, toggle]);

    const itemClickHandler = useCallback(() => {
      if (isStandaloneIframe) {
        scrollToTop(); // Standalone iframe needs to scroll up in order to see the modal
      }
      toggle();
    }, [isStandaloneIframe, toggle]);

    return (
      <>
        {renderModal(
          // @ts-ignore
          ConfirmModal,
          {
            open: isToggled,
            hideModal: toggle,
            callback: confirmUpgrade,
            header: 'Upgrade Package',
            message: `Are you sure you want to upgrade to ${underscoreToHuman(
              packageSlug,
            )}.`,
          },
        )}
        <M.OverflowMenuItem
          key={packageSlug}
          ref={ref}
          itemText={underscoreToHuman(packageSlug)}
          onClick={itemClickHandler}
          // these props should have a default of noop in Mastodon
          setPrimaryItem={noop}
          closeMenu={noop}
        />
      </>
    );
  },
);

export default UpgradePackage;
