import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { StorePage } from 'modules/assess/v2/ui/common';
import { useDisableReview } from 'modules/assess/v2/ui/hooks';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { ID } from 'modules/entity';
import Actions from './actions';
import AssignGuidelines from './assign-guidelines';
import Consent from './consent';
import Review from './review';
import Title from './Title';

type Props = {
  ruleset?: ID;
};

export const Container: React.FC<Props> = ({ ruleset }) => {
  const [consent, setConsent] = useState(false);
  const history = useHistory();
  const { disabled: redirectToHome, isLoading } = useDisableReview(ruleset);

  if (isLoading) {
    return <M.LoadingSpinner />;
  }

  if (redirectToHome) {
    history.push('/assess');
  }

  return (
    <StorePage
      id='assess-v2-ruleset-version-review-and-apply-container'
      event={Amplitude.ReviewAndApply.Pageview}
    >
      <Title />
      <AssignGuidelines />
      <Review ruleset={ruleset} />
      <Consent setConsent={setConsent} />
      <Actions disabled={!consent} ruleset={ruleset} />
    </StorePage>
  );
};

export default Container;
