import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import Upsell from 'assets/assess/v2/common';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { useIsVisible, useTrackEvent } from 'modules/assess/v2/ui/hooks';
import LearnMoreButton from './LearnMoreButton';

type Props = {
  eventProperty?: Amplitude.Type;
};

const FlexContainer = styled.div`
  background-color: ${colors.brandPaper1};
  box-shadow: 2px 2px 4px 2px rgba(22, 22, 29, 0.08);
  border-radius: 8px 0 0 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ContentFlex = styled.div`
  display: inline-flex;
`;

const Content = styled.div`
  padding: 48px;
  width: 400px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 1.1;
  color: ${colors.brandNavy4};
  margin-bottom: 1rem !important;
`;

const StyledRow = styled(M.Container.Row)`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.brandNavy4};
  margin-bottom: 2rem;
`;

const ImageFlex = styled.div`
  display: inline-flex;
  overflow: hidden;
`;

const Image = styled.img`
  height: 325px;
`;

const Container: React.FC<Props> = ({ eventProperty }) => {
  const { t } = useTranslation('assess:v2');
  const titleText = t(`common.upgrade.standard.title`);
  const descriptionKey = 'common.upgrade.standard.description';

  const baseDataTestId = 'assess-v2-ui-standard-upgrade';

  const [hasTrackedIsVisible, setHasTrackedIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null!);
  const isVisible = useIsVisible(ref);
  const track = useTrackEvent();

  const amplitudeTrackIsVisible = useCallback(() => {
    setHasTrackedIsVisible(true);
    track(Amplitude.Common.StandardUpgradeView, {
      [Amplitude.Common.Page]: eventProperty,
    });
  }, [eventProperty, track]);

  useEffect(() => {
    if (isVisible && !hasTrackedIsVisible) {
      amplitudeTrackIsVisible();
    }
  }, [amplitudeTrackIsVisible, isVisible, hasTrackedIsVisible]);

  return (
    <FlexContainer ref={ref} data-testid={baseDataTestId}>
      <ContentFlex>
        <Content>
          <Title data-testid={`${baseDataTestId}-title`}>{titleText}</Title>
          <StyledRow data-testid={`${baseDataTestId}-description`}>
            <Trans t={t} i18nKey={descriptionKey} />
          </StyledRow>
          <LearnMoreButton eventProperty={eventProperty} />
        </Content>
      </ContentFlex>
      <ImageFlex>
        <Image src={Upsell} />
      </ImageFlex>
    </FlexContainer>
  );
};

export default Container;
