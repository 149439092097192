import { capitalize } from 'lodash';
import React from 'react';
import { FieldComponentProps } from '../types';

const Nodes: React.FC<FieldComponentProps> = ({ report, testid }) => {
  return report.segment_stamps.map((segment: string) => {
    const [prop, value] = segment.split('|');
    return (
      <div key={segment} data-testid={testid}>
        {capitalize(prop)}: {value}
      </div>
    );
  });
};

export default Nodes;
