import React from 'react';
import { M, RECORD_TYPES } from '@dashboard-experience/mastodon';
import { Report, GenericObject } from 'types';
import { useUser } from 'context/CurrentUser';
import { useRecordsFilter } from 'containers/Report';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';

type Props = {
  search: GenericObject;
  filteredRecords: number;
  report: Report;
  reportStatusType: string;
  filter?: string;
};

const FilteredByAdjudicationRecords: React.FC<Props> = ({
  search,
  filteredRecords,
  report,
  reportStatusType,
  filter,
}) => {
  const currentUser = useUser();
  const recordsFilter = useRecordsFilter();
  const finalFilter = recordsFilter || filter;

  return (
    <M.Expandable
      title={
        <M.Screening.Heading
          headingLeft={{
            name: `${filteredRecords} Record(s) filtered by ${finalFilter}`,
          }}
        />
      }
    >
      {search.filtered_by_positive_adjudication_records?.map(
        (filteredRecord: GenericObject) => (
          <M.Record
            key={filteredRecord.id}
            record={filteredRecord}
            recordType={RECORD_TYPES.filteredByAdjudication}
            currentUser={currentUser}
            report={report}
            reportStatusType={reportStatusType}
            chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
          />
        ),
      )}
    </M.Expandable>
  );
};

export default FilteredByAdjudicationRecords;
