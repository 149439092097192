import { useReducer } from 'react';
import {
  State,
  reducers,
} from 'modules/assess/ui/state/rulesets/versions/instance';

export const useStore = () => {
  const [state, dispatch] = useReducer(
    reducers.default,
    null,
    State.initialize,
  );
  return { state, dispatch };
};

export default useStore;
