import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from 'utils';
import { TaxExemptionCertificate } from 'api/payment';
import {
  StyledH2,
  StyledH3,
  GridCol,
  StyledButton,
  StyledNote,
  Divider,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import TaxExemptionModal from './TaxExemptionModal';
import TaxExemptionCertificateDetails from './TaxExemptionCertificateDetails';

type Props = {
  taxExemptionCertificates: Array<TaxExemptionCertificate>;
};

const TaxExemptionCertificateSection: React.FC<Props> = ({
  taxExemptionCertificates,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = React.useState(false);

  const showTaxExemptionModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideTaxExemptionModal = useCallback((): void => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <M.Grid>
      <M.GridRow>
        <CustomCol col={5} noPadding style={{ marginTop: '0.5rem' }}>
          <Divider />
          <M.GridRow>
            <GridCol>
              <StyledH2 className='mb-0'>
                {t(`tax_exemption_header.title`)}
              </StyledH2>
            </GridCol>
          </M.GridRow>
          <M.GridRow
            style={{ marginTop: '1.5rem', display: 'flex', flexWrap: 'nowrap' }}
          >
            <M.GridCol
              sm={10}
              md={7}
              style={{ padding: '0rem 1rem 0rem 0rem' }}
            >
              <StyledH3 className='mb-0' style={{ padding: '0rem' }}>
                {t(`tax_exemption_certificate.title`)}
              </StyledH3>
              <StyledNote className='mb-0' style={{ marginTop: '0.25rem' }}>
                {t(`tax_exemption_certificate.description`)}
              </StyledNote>
            </M.GridCol>
            <GridCol style={{ textAlign: 'right' }}>
              <StyledButton
                kind='secondary'
                onClick={showTaxExemptionModal}
                data-testid='tax-exemption-modal-btn'
              >
                {t(`tax_exemption_certificate.add_new_btn`)}
              </StyledButton>
            </GridCol>
          </M.GridRow>
          <TaxExemptionCertificateDetails
            taxExemptionCertificates={taxExemptionCertificates}
          />
        </CustomCol>
      </M.GridRow>
      <TaxExemptionModal
        modalOpen={modalOpen}
        hideModal={hideTaxExemptionModal}
      />
    </M.Grid>
  );
};
export default TaxExemptionCertificateSection;
