import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/premade';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';

const StyledButton = styled(M.Button)`
  font-weight: normal !important;
  color: ${colors.brandNavy4} !important;
  border: 0 !important;
  background: none !important;
`;

const StyledIcon = styled(M.Icon)`
  fill: ${colors.brandNavy4};
  margin-right: 8px;
`;

type Props = {
  row: Row;
};

export const Delete: React.FC<Props> = ({ row }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const deleteText = t(`verbs.delete`);

  const unselectRule = useCallback(() => {
    dispatch(Actions.Remove.create(row.rule));
  }, [dispatch, row.rule]);

  return (
    <StyledButton
      kind='tertiary'
      size='sm'
      onClick={unselectRule}
      data-testid='assess-v2-ui-guideline-quickstart-added-guidelines-category-rule-delete-button'
    >
      <StyledIcon icon='TrashCan' />
      {deleteText}
    </StyledButton>
  );
};

export default Delete;
