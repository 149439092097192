import React, { useCallback } from 'react';
import { Container } from 'modules/assess/ui/page/Actions';
import { useNamespace } from 'modules/assess/ui/router/context';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import {
  LookbackPeriod,
  Quickstart,
} from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { ReviewButton, SaveButton } from '../common/actions';

export const Actions: React.FC = () => {
  const state = useState();
  const track = useTrackEvent();

  const quickstartRules = Object.values(state.rules.premade.table)
    .filter(rule => rule.updated)
    .map(rule => rule.code);

  const lookbackPeriodRules = Object.values(state.rules.lookback.table)
    .filter(rule => rule.updated)
    .map(rule => rule.key);

  const amplitudeTrackNewRules = useCallback(() => {
    if (quickstartRules.length) {
      track(Quickstart.Save, {
        'Guidelines Saved': quickstartRules,
        'Number Saved': quickstartRules.length,
      });
    }

    if (lookbackPeriodRules.length) {
      track(LookbackPeriod.GuidelinesSaved, {
        'Guidelines Saved': lookbackPeriodRules,
        'Number Saved': lookbackPeriodRules.length,
      });
    }
  }, [lookbackPeriodRules, quickstartRules, track]);

  const namespace = useNamespace();
  const query = useRuleset();
  const ruleset = query?.data;

  const baseDataTestId = 'assess-v2-ui-lite-actions';

  return (
    <Container justify='flex-end' data-testid={`${baseDataTestId}-container`}>
      <SaveButton
        kind='secondary'
        namespace={namespace}
        ruleset={ruleset?.id}
        trans='verbs.save'
        trackNewRulesCallback={amplitudeTrackNewRules}
      />
      <ReviewButton
        ruleset={ruleset?.id}
        trackNewRulesCallback={amplitudeTrackNewRules}
        translation='common.actions.review_and_apply'
      />
    </Container>
  );
};

export default Actions;
