import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.25em;
  }
`;

export const Description: React.FC = () => {
  const { t } = useTranslation('assess');
  const header = t('assess:context_dates.create.dialog.description.header');
  const subheader = t(
    'assess:context_dates.create.dialog.description.subheader',
  );

  return (
    <Container>
      <h2>{header}</h2>
      {subheader}
    </Container>
  );
};

export default Description;
