import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useState as useQuery } from 'modules/assess/ui/ruleset/version/store';
import {
  Dialog as BulkEditModal,
  Type,
} from 'modules/assess/v2/ui/common/lookback-rules';
import LessThanMisdemeanor from 'modules/assess/v2/ui/common/lookback-rules/less-than-misdemeanor';
import { STATUTORY_CATEGORY } from 'modules/assess/v2/ui/common/lookback-rules/utils';
import { useLessThanMisdemeanorPremadeRule } from 'modules/assess/v2/ui/hooks';
import Header from './Header';
import Subcategories from './subcategories';
import InformationPanel from './InformationPanel';

const StyledLessThanMisdemeanor = styled(LessThanMisdemeanor)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Container: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null!);
  const state = useQuery();

  const [showBulkEditDialog, setShowBulkEditDialog] = useState(false);
  const onClose = useCallback(() => {
    setShowBulkEditDialog(false);

    const current = ref.current as unknown as HTMLDivElement;
    current && current.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [setShowBulkEditDialog]);

  const handleBulkEdit = useCallback(() => {
    setShowBulkEditDialog(true);
  }, [setShowBulkEditDialog]);

  const includeLessThanMisdemeanor =
    !useLessThanMisdemeanorPremadeRule() &&
    !state.settings?.lookback_rules_all_less_than_misdemeanor_eligible;

  return (
    <div
      ref={ref}
      data-testid='assess-v2-ui-lite-time-since-offense-table-container'
    >
      <Header onClickBulkEdit={handleBulkEdit} />
      <StyledLessThanMisdemeanor header={Type.Statutory} />
      <InformationPanel />
      <Subcategories />
      <BulkEditModal
        open={showBulkEditDialog}
        onClose={onClose}
        item={STATUTORY_CATEGORY!}
        includeLessThanMisdemeanor={includeLessThanMisdemeanor}
        type={Type.Statutory}
      />
    </div>
  );
};

export default Container;
