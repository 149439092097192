import { useEffect, useCallback } from 'react';
import { postMessageToDashboard } from 'utils';

// Attaches a scroll event listener on iframe's parent
const useDashboardWindowScroll = (contextId: string | null) => {
  const postToParent = useCallback(
    messageId => {
      postMessageToDashboard({
        contextId,
        messageId,
      });
    },
    [contextId],
  );

  useEffect(() => {
    postToParent('window_iframe_positions');
    postToParent('window_iframe_positions_on_scroll');
  }, [postToParent]);
};

export default useDashboardWindowScroll;
