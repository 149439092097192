import React from 'react';
import { useTranslation } from 'react-i18next';

import { Fact } from 'modules/assess/models/criminal/rules';
import { Entity } from 'modules/assess/models/rules/common/entity';
import { Field } from '../field';

type Props = {
  rule: Entity;
};

// TODO: refactor to only take in the condition applicable
export const Counts: React.FC<Props> = ({ rule }) => {
  const { t } = useTranslation('assess');
  const translation: any = t('assess:rules.facts.count', {
    returnObjects: true,
  });
  const label = `${translation.name} ${translation.verb}`.trim();
  const condition = rule.conditions.find(c => c.fact === Fact.COUNT);

  let value;

  if (condition) {
    value = `${translation.operators[condition.operator]} ${condition.value}`;
  }

  return (
    <Field data-testid='assess-rules-common-rule-counts' label={label}>
      <span>{value}</span>
    </Field>
  );
};

export default Counts;
