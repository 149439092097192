import React from 'react';
import { useTranslation } from 'react-i18next';

import { Fact } from 'modules/assess/models/criminal/rules';
import { Entity } from 'modules/assess/models/rules/common/entity';
import { Field } from '../field';

type Props = {
  rule: Entity;
};

export const Targets: React.FC<Props> = ({ rule }) => {
  const { t } = useTranslation('assess');
  const translation: any = t('assess:rules.facts.object_type', {
    returnObjects: true,
  });
  const label = `${translation.name} ${translation.verb}`.trim();
  const condition = rule.conditions.find(c => c.fact === Fact.TYPE);

  let value: string = translation.values?.any;

  if (condition) {
    const values = Array.isArray(condition.value)
      ? condition.value
      : [condition.value];
    value = values.map((v: string) => translation.values?.[v] ?? v).join(', ');
  }

  return (
    <Field data-testid='assess-rules-common-rule-targets' label={label}>
      <span>{value}</span>
    </Field>
  );
};

export default Targets;
