import React from 'react';
import styled from 'styled-components';
import Body from 'components/FairChance/Content';
import Sections from 'components/FairChance/sections';
import Subsections from '../subsections';
import Footer from './Footer';
import Image from './Image';

const StyledContainer = styled.div``;

type Props = {
  content: any;
  subsection: Subsections;
};

const Container: React.FC<Props> = ({ content, subsection }) => {
  const { paragraphs, footer, title } = content;
  const body = (
    <Body
      paragraphs={paragraphs}
      section={Sections.how_to_evaluate_background_checks}
      subsection={subsection}
    />
  );

  return (
    <StyledContainer data-testid='fair-chance-sections-how-to-evaluate-background-checks-example'>
      <h5>{title}</h5>
      {body}
      <Image subsection={subsection} />
      {footer && <Footer subsection={subsection} text={footer} />}
    </StyledContainer>
  );
};

export default Container;
