import styled from 'styled-components';

export const Container = styled.div`
  > * {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  p {
    max-width: 48em;
  }
`;

export default Container;
