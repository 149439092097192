import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import { useModalState } from '../context';

const SubmitStates: { [k: string]: string } = {
  Success: 'success',
  Error: 'error',
};

const Header = () => {
  const { t } = useTranslation();
  const currentScreen = useModalState('currentScreen') as string;
  const onClose = useModalState('onClose');

  const kind = SubmitStates[currentScreen] || 'default';
  const title = t(`${namespace}:exam_reorder.modal.header.${kind}`);

  return (
    <M.ModalHeader
      title={title}
      buttonOnClick={onClose}
      data-testid='exam-reorder-modal-header'
    />
  );
};

export default Header;
