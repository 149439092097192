/* eslint-disable */
import { AACSVExportFilters, CSVExportParams } from 'api/csvExport';

export type Filters = Partial<AACSVExportFilters>;

export type Submit = {
  from: string;
  to: string;
  post: (params: CSVExportParams) => Promise<any>;
  filters: Filters;
};

export const sanitizeFilters = (f: Filters) => {
  const filters = { ...f };
  for (const filter in filters) {
    if (filter in filters) {
      // @ts-ignore
      const val = filters[filter];
      if (!val || val === '' || (Array.isArray(val) && !val.length)) {
        // @ts-ignore
        delete filters[filter];
      }
    }
  }
  return filters as AACSVExportFilters;
};

export const submit = ({ from, to, post, filters }: Submit) => {
  const apiFilters: Filters = {
    ...filters,
    created_after: from,
    created_before: to,
  };

  const sanitizedFilters = sanitizeFilters(apiFilters);
  const params: CSVExportParams = {
    filters: sanitizedFilters,
    send_email: true,
  };

  post(params);
};

export const parseCustomerDateParam = (dateParam: String) => {
  const d = dateParam.split(/\/|-/).map(Number);
  if (d.length === 3) {
    // legacy format YYYY-MM-DD
    if (d[0].toString().length === 4) {
      return new Date(d[0], d[1] - 1, d[2]).toISOString();
    }
    return new Date(d[2], d[0] - 1, d[1]).toISOString();
  }
  return dateParam;
};
