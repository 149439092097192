import React, { useCallback } from 'react';
import { useUpdateCandidate } from 'api/candidate';

import { FieldComponentProps } from '../../types';
import { InPlaceTextEditor } from './styles';

const CustomID: React.FC<FieldComponentProps> = ({
  fieldKey,
  allowCandidateEdit,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
  testid,
}) => {
  const { custom_id: customId, id: candidateId } = candidate;
  const updatedCustomId = candidateUpdates?.custom_id;
  // updatedCustomId may be empty string
  const displayCustomId =
    updatedCustomId == undefined ? customId : updatedCustomId;

  const { updateCandidateCall } = useUpdateCandidate(fieldKey, 'custom ID');

  const onCustomIdEditSubmit = useCallback(
    (newCustomIdValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          custom_id: newCustomIdValue,
        });
        setCandidateUpdates?.({
          ...candidateUpdates,
          custom_id: newCustomIdValue,
        });
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  return (
    <InPlaceTextEditor
      data-testid={testid}
      allowEdit={allowCandidateEdit}
      ariaLabel='candidate-custom-id'
      initialDisplayValue={displayCustomId || '-'}
      initialValue={customId || ''}
      inputId='candidate-custom-id'
      submitCallback={onCustomIdEditSubmit}
    />
  );
};

export default CustomID;
