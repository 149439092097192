import React, { FC, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { map, filter } from 'lodash/fp';
import { LoadingBlock } from 'components/LocationSearch/styles';
import { ISelectedCandidate } from './types';
import SelectionTable from './SelectionTable';

interface Props {
  id: number;
  isLoading: boolean;
  onOrderReports: (candidates: ISelectedCandidate[]) => void;
  discloseAlternateSelectionModal: Function;
  setSelectedCandidateId: Function;
  isAlternateAvailable: boolean;
  groupedCandidates: ISelectedCandidate[];
  setGroupedCandidates: Function;
}

const PollBody: FC<Props> = ({
  id,
  groupedCandidates,
  isLoading,
  onOrderReports,
  discloseAlternateSelectionModal,
  setSelectedCandidateId,
  isAlternateAvailable,
  setGroupedCandidates,
}) => {
  const onDrugSchedule = useCallback(
    (selectedItems: ISelectedCandidate[]) => () => {
      const ids = map(({ id }) => Number(id), selectedItems);
      const selectedCandidates = filter(
        ({ id }) => ids.includes(id),
        groupedCandidates,
      );
      if (selectedCandidates.length) {
        onOrderReports(selectedCandidates);
      }
    },
    [groupedCandidates, onOrderReports],
  );

  return (
    <Container data-testid='random-poll-body'>
      <M.GridRow>
        <ExpandedBody>
          {isLoading ? (
            <LoadingBlockContainer>
              <StyledLoadingBlock />
              <StyledLoadingBlock />
            </LoadingBlockContainer>
          ) : (
            <M.GridRow>
              <SelectionSection data-testid='testing-selectiom-table'>
                <SelectionTable
                  id={id}
                  selectedCandidates={groupedCandidates}
                  setSelectedCandidateId={setSelectedCandidateId}
                  discloseAlternateSelectionModal={
                    discloseAlternateSelectionModal
                  }
                  onSchedule={onDrugSchedule}
                  isAlternateAvailable={isAlternateAvailable}
                  setGroupedCandidates={setGroupedCandidates}
                />
              </SelectionSection>
            </M.GridRow>
          )}
        </ExpandedBody>
      </M.GridRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const ExpandedBody = styled.div`
  width: 100%;
  border-top: 1px solid #e1e6eb;
  margin: 0 1em;
  padding: 1em 0;
`;

const SelectionSection = styled.div`
  width: 100%;
`;

const LoadingBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const StyledLoadingBlock = styled(LoadingBlock)`
  height: 100px;
  width: 95%;
  margin: 0 auto;
`;

export default PollBody;
