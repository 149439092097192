import React, { useCallback } from 'react';
import moment from 'moment';
import { M } from '@dashboard-experience/mastodon';
import {
  CellDashboardLink,
  FlexSpan,
  PostNotice,
} from './AdverseActionsStyledComponents';
import { AdverseAction } from './types';

type Props = {
  adverseAction: AdverseAction;
  showPostAdverseActionNoticeModal: Function;
};

const PostNoticeCell: React.FC<Props> = ({
  adverseAction,
  showPostAdverseActionNoticeModal,
}) => {
  const noticeIsReady = (noticeReadyAt: string): boolean => {
    return new Date() > new Date(noticeReadyAt);
  };

  const canSendPostNotice = (adverseAction: AdverseAction): boolean => {
    return (
      adverseAction.delivery.state !== 'error' &&
      noticeIsReady(adverseAction.post_notice_ready_at)
    );
  };

  const postNoticeToolTipMessage = (adverseAction: AdverseAction): string => {
    if (adverseAction.delivery.state !== 'error') {
      return 'Complete the adverse action process by sending the Post-Notice now';
    }
    return 'Cannot send Post-Notice to undeliverable email';
  };

  const showModal = useCallback(() => {
    showPostAdverseActionNoticeModal(adverseAction);
  }, [adverseAction, showPostAdverseActionNoticeModal]);

  return (
    <M.TableCell>
      <CellDashboardLink path={`/reports/${adverseAction.report_id}`}>
        {!adverseAction.post_notice_scheduled_at &&
          adverseAction.status === 'dispute' && <span>Canceled</span>}
        {adverseAction.post_notice_scheduled_at &&
          noticeIsReady(adverseAction.post_notice_scheduled_at) && (
            <span>
              {moment(adverseAction.post_notice_scheduled_at).format(
                'MMM D, YYYY',
              )}
            </span>
          )}
        {adverseAction.post_notice_scheduled_at &&
          !noticeIsReady(adverseAction.post_notice_scheduled_at) && (
            <span>
              Due on{' '}
              {moment(adverseAction.post_notice_scheduled_at).format(
                'MMM D, YYYY',
              )}
            </span>
          )}
      </CellDashboardLink>
      {!adverseAction.post_notice_scheduled_at &&
        adverseAction.status !== 'dispute' &&
        adverseAction.status !== 'canceled' && (
          <FlexSpan>
            {' '}
            <PostNotice
              role='button'
              onClick={showModal}
              disabled={!canSendPostNotice(adverseAction)}
            >
              Send post-notice
            </PostNotice>
            <M.TooltipDefinition
              openOnHover={false}
              highlighted={false}
              align='right'
              definition={postNoticeToolTipMessage(adverseAction)}
              style={{ marginLeft: '0.5rem' }}
            >
              <M.Icon icon='HelpFilled' />
            </M.TooltipDefinition>
          </FlexSpan>
        )}
    </M.TableCell>
  );
};

export default PostNoticeCell;
