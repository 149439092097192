import React, { useState, useMemo } from 'react';
import Context from './context';

// eslint-disable-next-line react/prop-types
const ModalContextProvider = ({ children }) => {
  const [modalState, setModalState] = useState({});
  const value = useMemo(() => ({ ...modalState, setModalState }), [modalState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ModalContextProvider;
