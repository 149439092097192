import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useSegmentsContext,
  useDispatch as useSegmentsDispatch,
} from 'components/Segments/SegmentsContext';
import { postMessageToDashboard } from 'utils';
import { SegmentsFooterLeft, SegmentsFooterRight } from './SegmentsComponents';
import {
  ParentContext,
  usePostSegments,
  SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
  SHOW_ASSIGN_SEGMENTS_SUCCESS,
  CANCEL_ASSIGN_SEGMENTS_CONFIRMATION,
} from '../../api/segments';

type Props = {
  accountId: string;
  parentId: string;
  parentContext: ParentContext;
  selectedSegments: string[];
  unselectedSegments: string[];
  currentAssignment: string[];
  closeModal: any;
};

const SegmentsFooter: React.FC<Props> = ({
  accountId,
  parentId,
  parentContext,
  selectedSegments,
  unselectedSegments,
  currentAssignment,
  closeModal,
}) => {
  const hasSelectedSegments = selectedSegments.length > 0;
  const hasAddedSegments = currentAssignment.length > 0;
  const hasRemovedSegments = unselectedSegments.length > 0;
  const hasAddedNoun = currentAssignment.length > 1 ? 'nodes' : 'node';
  const hasRemovedNoun = unselectedSegments.length > 1 ? 'nodes' : 'node';
  const noun = selectedSegments.length > 1 ? 'nodes' : 'node';
  const disabled = !hasAddedSegments && !hasRemovedSegments;

  const segmentsDispatch = useSegmentsDispatch();

  const {
    showAssignSegmentsConfirmationView: confirmationView,
    showAssignSegmentsSuccessView: successView,
    search,
  } = useSegmentsContext();

  const { call: postSegments, result: postSegmentsResult } = usePostSegments(
    accountId,
    parentContext,
    parentId,
    selectedSegments,
  );

  const save = () => {
    postSegments();
  };

  const onCloseModal = useCallback(() => {
    if (closeModal) {
      segmentsDispatch({
        type: SHOW_ASSIGN_SEGMENTS_SUCCESS,
        payload: {
          showAssignSegmentsSuccessView: false,
        },
      });
      closeModal();
    } else {
      postMessageToDashboard({
        broadcastMessage: 'segments_assign_modal_close',
      });
    }
  }, [closeModal, segmentsDispatch]);

  const beforeConfirmBtnLeft = {
    name: 'Cancel',
    disabled: false,
    style: {},
    onClick: () => {
      onCloseModal();
    },
  };
  const beforeConfirmBtnRight = {
    name: 'Save',
    disabled,
    style: {},
    onClick: () => {
      segmentsDispatch({
        type: SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
        payload: {
          showAssignSegmentsConfirmationView: true,
        },
      });
    },
  };
  const confirmBtnLeft = {
    name: 'No',
    disabled: false,
    onClick: () => {
      if (search.isSearching) {
        segmentsDispatch({
          type: CANCEL_ASSIGN_SEGMENTS_CONFIRMATION,
        });
      } else {
        onCloseModal();
      }
    },
  };
  const confirmBtnRight = {
    name: 'Yes',
    disabled,
    onClick: () => {
      save();
    },
  };

  return (
    <M.ModalFooter data-testid='segments-footer-container'>
      {!confirmationView && !successView && (
        <SegmentsFooterLeft>
          <p
            style={{ color: colors.uiGrey700, marginBottom: 0 }}
            data-testid='segments-footer-actions-counter'
          >
            {(hasAddedSegments || hasRemovedSegments) && 'Actions:'}&nbsp;
            {hasAddedSegments &&
              `Adding ${currentAssignment.length} ${hasAddedNoun}`}
            {hasAddedSegments && hasRemovedSegments && ', '}
            {hasRemovedSegments &&
              `Removing ${unselectedSegments.length} ${hasRemovedNoun}`}
          </p>
          {hasSelectedSegments && (
            <p data-testid='segments-footer-selected-counter'>
              <span
                style={{
                  background: colors.uiOrange200,
                  padding: '0.25rem 0.375rem',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {selectedSegments.length}{' '}
                </span>
                <span style={{ fontStyle: 'italic' }}>
                  {' '}
                  {` ${noun} selected total`}{' '}
                </span>
              </span>
            </p>
          )}
        </SegmentsFooterLeft>
      )}

      <SegmentsFooterRight>
        {postSegmentsResult.isLoading && <M.LoadingSpinner />}
        {!confirmationView && !successView && (
          <M.BinaryButtons
            btnLeft={beforeConfirmBtnLeft}
            btnRight={beforeConfirmBtnRight}
          />
        )}
        {confirmationView && (
          <M.BinaryButtons
            btnLeft={confirmBtnLeft}
            btnRight={confirmBtnRight}
          />
        )}
        {successView && <M.Button onClick={onCloseModal}>Close</M.Button>}
      </SegmentsFooterRight>
    </M.ModalFooter>
  );
};

export default SegmentsFooter;
