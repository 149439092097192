import { Category } from 'modules/assess/models/mvr/category';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { Container } from 'modules/assess/ui/rules/common';
import React from 'react';
import { ID } from 'modules/id';
import Categories from './categories';
import Mappings from './mappings';

export type Props = {
  categories?: Array<Category>;
  instance: ID;
  type: ItemType;
  version: ID;
};

const Content: React.FC<Props> = ({ categories, type, instance, version }) => {
  return (
    <Container
      data-testid={`assess-mvr-category-set-version-mappings-content-${type}`}
    >
      <Categories data={categories} type={type} />
      <Mappings
        categories={categories}
        instance={instance}
        type={type}
        version={version}
      />
    </Container>
  );
};

export default Content;
