import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import * as Assess from 'modules/assess/ui';
import Container from 'modules/assess/ui/ruleset/version/context-dates/Container';

export type Props = {
  disabled?: boolean;
  id: string;
};

export const ContextDates: React.FC<Props> = ({ disabled, id }) => {
  const match = useRouteMatch();
  const { path: base, url } = match;

  return (
    <Assess.Route.Breadcrumb location={url} title='Context Dates'>
      <Route path={base}>
        <Container disabled={disabled} id={id} />
      </Route>
    </Assess.Route.Breadcrumb>
  );
};

export default ContextDates;
