import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNamespace } from '../router/context';

export type Props = {};

export const Description: React.FC<Props> = () => {
  const namespace = useNamespace();
  const { t } = useTranslation('assess');
  const text = t(`assess:rulesets.description.${namespace}`);

  return <p>{text}</p>;
};

export default Description;
