import React from 'react';
import * as Entity from 'modules/assess/models/settings/account';
import SettingToggle from 'modules/assess/ui/common/settings/setting-toggle';
import SelectPackagesSetting from './packages-sub-setting/select-packages-setting';
import SelectGeosSetting from './geos-sub-setting/select-geos-setting';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const AdverseActionAutomationSetting: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  const disableSubSetting = !settings?.adverse_action_automation?.enabled;

  return (
    <>
      <SettingToggle
        name='adverse_action_automation'
        versionClass='v1'
        settings={settings}
        setSettings={setSettings}
      />
      <SelectPackagesSetting
        disabled={disableSubSetting}
        settings={settings}
        setSettings={setSettings}
      />
      <SelectGeosSetting
        disabled={disableSubSetting}
        settings={settings}
        setSettings={setSettings}
      />
    </>
  );
};

export default AdverseActionAutomationSetting;
