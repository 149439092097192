import React from 'react';
import styled from 'styled-components';
import { Description } from 'modules/assess/models/rules/premade';
import { Bullet } from 'modules/assess/v2/ui/common';

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 1.15em;
  margin-top: 0.5em;
  width: calc(100% - 250px);
  min-width: 250px;
  max-width: 750px;
`;

type Props = {
  description: Description[];
};

export const List: React.FC<Props> = ({ description }) => {
  const bulletPoints = description.map((child, i) => {
    const key = `bullet-${i}`;
    return (
      <Bullet key={key} text={child.paragraph}>
        {child.description && <List description={child.description} />}
      </Bullet>
    );
  });

  return <StyledList>{bulletPoints}</StyledList>;
};

export default List;
