import React from 'react';
import { FieldComponentProps } from '../types';
import { formatGeo } from './utils';

const Geos = ({ report, currentUser, testid }: FieldComponentProps) => {
  const { geos } = report;
  return (
    <div data-testid={testid}>
      {geos?.map((geo, idx) => {
        return (
          <div key={geo.id}>
            {/* @ts-ignore TODO: remove comment once CurrentUser type is updated in Utils */}
            {formatGeo(geo, currentUser)}
            {idx !== geos.length - 1 ? ',' : ''}
          </div>
        );
      })}
    </div>
  );
};

export default Geos;
