import { usePauseAdverseActionAllowed } from 'modules/adjudication/utilities';
import React from 'react';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const displayPause = usePauseAdverseActionAllowed();

  return displayPause ? <>{children}</> : null;
};

export default Guard;
