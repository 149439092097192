import React from 'react';
import { colors, ChevronDown, ChevronUp } from '@dashboard-experience/mastodon';

type Props = {
  isOpen: boolean;
};

export const Actions: React.FC<Props> = ({ isOpen }) => {
  const Icon = isOpen ? ChevronUp : ChevronDown;
  return (
    <Icon
      style={{ color: colors.brandAqua3 }}
      size={32}
      data-testid='assess-v2-ui-guideline-quickstart-table-category-actions-icon'
    />
  );
};

export default Actions;
