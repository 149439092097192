/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Decision } from 'modules/assess';
import { Field } from 'modules/assess/ui/rules/common/rule';

type Props = {
  setDraftRule: any;
  decision: Decision;
  decisions: Decision[];
};

const Assessment: React.FC<Props> = ({ decision, setDraftRule, decisions }) => {
  const { t } = useTranslation('');
  const label = t('nouns.assessment');

  const handleChange = useCallback(
    ({ selectedItem: { id } }) => {
      const d = id as Decision;
      setDraftRule((prev: any): any => ({
        ...prev,
        decision: d,
      }));
    },
    [setDraftRule],
  );

  const items = decisions.map(d => ({
    id: d,
    label: <M.StatusBadge status={t(`assess:decisions.${d}`).toLowerCase()} />,
  }));

  const selected = items.find(i => i.id === decision);

  return (
    <>
      <Field label={label} style={{ marginBottom: '0.75em' }} />
      <M.Dropdown
        data-testid='assess-rules-common-rule-editor-assessment-select'
        label={t('selects.assessment_placeholder')}
        items={items}
        selectedItem={selected}
        onChange={handleChange}
        itemToString={(item: any) => item.label}
      />
    </>
  );
};

export default Assessment;
