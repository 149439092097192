import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { NoteType } from 'modules/case-management/types';
import Timestamp from './Timestamp';
import NoteContent from './NoteContent';
import UpdateButtons from './UpdateButtons';

type Props = {
  note: NoteType;
};

const AssigneeHeaderInfo = styled.div`
  margin-bottom: 0.3rem;
  max-width: 720px;
  color: ${colors.uiGrey600};
`;

const Note: React.FC<Props> = ({ note }) => {
  const currentUser = useUser();
  const [editMode, setEditMode] = React.useState(false);

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
  }, [editMode, setEditMode]);

  const currentUserNote = currentUser.id === note.created_by.id;

  return (
    <div data-testid='case-management-note'>
      <AssigneeHeaderInfo data-testid='case-management-note-assignee-header'>
        <M.GridRow>
          <M.Avatar name={note.created_by.display_label} />
          <M.GridCol style={{ padding: 0 }}>
            <div style={{ paddingTop: '3px' }}>
              <span
                style={{
                  marginLeft: '0.5rem',
                  paddingTop: '1px',
                }}
              >
                {note.created_by.display_label}{' '}
              </span>
              <Timestamp note={note} />
            </div>
          </M.GridCol>
          <M.GridCol
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 0,
            }}
            lg={2}
            md={2}
            sm={1}
          >
            <UpdateButtons
              currentUserNote={currentUserNote}
              note={note}
              onEdit={toggleEditMode}
            />
          </M.GridCol>
        </M.GridRow>
      </AssigneeHeaderInfo>
      <NoteContent
        currentUserNote={currentUserNote}
        editMode={editMode}
        note={note}
        toggleEditMode={toggleEditMode}
      />
    </div>
  );
};

export default Note;
