/* eslint-disable import/prefer-default-export */
export enum ModalType {
  AdverseAction = 'Adverse Action',
  CandidateReports = 'Candidate Reports',
  DriverRecordReports = 'Driver Record Reports',
  SubscriptionErrors = 'Ineligible Persons',
}

export enum Screens {
  INITIAL = 'INITIAL',
  SUCCESS = 'SUCCESS',
}

export type Screen = Screens.INITIAL | Screens.SUCCESS;

export type OptionalProps = {
  modalHeader?: string;
  dateRangeHeadline?: string;
  submitButtonName?: string;
  defaultDateDiffStartFromDays?: number;
  disableSubmitButtonUntilSetupDates?: boolean;
  dateRangeLimit?: number;
  disableDatesBefore?: string;
  fromFieldLabel?: string;
  toFieldLabel?: string;
};

export enum CSV_TYPES {
  REPORT_DATA = 'report_data',
  INVITATION_STATUS = 'invitation_status',
}
