import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import {
  getUniqueStatesFromRecords,
  formatWord,
} from '@dashboard-experience/utils';

import { ItemType } from 'types/report/item';
import { KeyValueSection } from '../Sections';

const SexOffenderSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { sex_offender_search } = report;
  const records = sex_offender_search?.records;

  const isUniqueSexOffenderState = getUniqueStatesFromRecords(
    records || [],
  ).includes('MT');
  const title = isUniqueSexOffenderState
    ? 'Sexual & Violent Offender Search'
    : 'Sex Offender Search';

  return sex_offender_search ? (
    <M.Screening
      title={title}
      statusType={reportStatusType}
      screening={sex_offender_search}
      id={SCREENING_TITLES.sex_offender_search.id}
    >
      {records?.map(record => {
        const items: ItemType[] = [];
        // iterate over record object and add key/val pairs to items
        Object.entries(record).forEach(keyValArr => {
          const [key, val] = keyValArr;

          if (val) {
            items.push({
              itemKey: formatWord(key),
              itemValue: val,
            });
          }
        });

        return (
          <M.Expandable
            titleRowProps={{ 'data-testid': 'expandable_sex-record' }}
            key={record.registry}
            title={record.registry}
          >
            <KeyValueSection items={items} />
          </M.Expandable>
        );
      })}
    </M.Screening>
  ) : null;
};

export default SexOffenderSearch;
