/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import SortedHeader, { SortDirections } from 'components/SortedHeader';
import { useHistory } from 'react-router-dom';
import UsersToolBar from './UsersToolsBar';
import EditRoleCell from './EditRoleCell';
import OverflowUserActions from './OverflowUserActions';
import UserStatusCell from './UserStatusCell';
import {
  UsersTableCell,
  RolesHeader,
  TableContainer,
} from './UsersStyledComponents';
import UIContext from '../../../context/UI';

const h: Header[] = [
  { key: 'first', header: '' },
  { key: 'email', header: 'Email' },
  { key: 'roles', header: 'Roles' },
  { key: 'last_seen', header: 'Last Seen' },
  { key: 'last', header: '' },
];

const SORTABLE_HEADERS = ['email', 'last_seen'];
const { ASC, DESC, NONE } = SortDirections;

type Props = {
  users: any;
  pageCount: number;
  filterByEmail: (str: string) => any;
  filterByRoles: (items: string[]) => any;
  sort: (a: any) => any;
  sort_by: string;
  sort_direction: string;
  handlePaginationClick: (a: number) => any;
  multiSelectRoles: string[];
  selectedIndex: number;
  isLoading: boolean;
};

type Obj = { [key: string]: any };

type Header = {
  key: string;
  header: string;
};

// Override the client-side sort to do nothing; because what we really care about is the server-side sort
function customSort(): number {
  return 0;
}

const UsersTable: React.FC<Props> = ({
  users = {},
  pageCount,
  filterByEmail,
  filterByRoles,
  sort,
  sort_by,
  sort_direction,
  handlePaginationClick,
  multiSelectRoles,
  selectedIndex,
  isLoading,
}) => {
  const { isIframe } = useContext(UIContext);
  const history = useHistory();
  const goToUserPage = useCallback(
    user => () => {
      const newURL = `user/${user.id}`;
      history.push(newURL);
    },
    [history],
  );

  const getTableContent = useCallback(
    ({
      rows,
      headers,
      getHeaderProps,
      getToolbarProps,
      getTableProps,
      getTableContainerProps,
    }: any) => (
      <>
        <UsersToolBar
          getToolbarProps={getToolbarProps}
          filterByEmail={filterByEmail}
          filterByRoles={filterByRoles}
          multiSelectRoles={multiSelectRoles}
          isLoading={isLoading}
        />
        <M.Grid>
          <M.GridRow>
            <M.GridCol style={{ padding: 0 }}>
              <M.TableContainer {...getTableContainerProps()}>
                <M.Table {...getTableProps()}>
                  <M.TableHead>
                    <M.TableRow>
                      {headers.map((header: Header) => {
                        const isSortable = SORTABLE_HEADERS.includes(
                          header.key,
                        );

                        if (!isSortable) {
                          return (
                            <M.TableHeader
                              {...getHeaderProps({ header })}
                              isSortable={false}
                            >
                              {header.key === 'roles' ? (
                                <RolesHeader />
                              ) : (
                                `${header.header}`
                              )}
                            </M.TableHeader>
                          );
                        }

                        const sorting = {
                          onClick: (e: any, sortParams: any) => {
                            const {
                              sortHeaderKey: incomingHeader,
                              sortDirection: incomingDir,
                            } = sortParams;

                            // For the actively-sorted column, we might need some overrides:
                            if (incomingHeader === sort_by) {
                              // Initial load - the table is sorted by Email ASC, but doesn't think it is; so override the upcoming direction
                              if (incomingDir === sort_direction) {
                                sortParams.sortDirection = incomingDir === ASC ? DESC : ASC; // prettier-ignore
                              }

                              // These paginated tables can never really be unsorted. So if the next state would be "NONE", just skip over it to the one after
                              else if (incomingDir === NONE) {
                                sortParams.sortDirection = ASC;
                              }
                            }

                            sort(sortParams);
                          },
                        };

                        return (
                          <SortedHeader
                            key={header.key}
                            label={header.header}
                            {...getHeaderProps({
                              header,
                              ...sorting,
                            })}
                            isSortHeader={header.key === sort_by}
                            sortDirection={sort_direction}
                            className={header.key}
                            firstSort={ASC}
                          />
                        );
                      })}
                    </M.TableRow>
                  </M.TableHead>

                  <M.TableBody>
                    {rows.map((u: Obj, userIndex: number) => {
                      const last = userIndex === users.length - 1;
                      const user = users[u.id];
                      if (!user) return null;
                      const { id, email, last_seen } = user;

                      return (
                        <M.TableRow key={id}>
                          <UserStatusCell user={user} />
                          <UsersTableCell>{email}</UsersTableCell>
                          <EditRoleCell user={user} />
                          <UsersTableCell>{last_seen}</UsersTableCell>
                          {!isIframe && (
                            <UsersTableCell>
                              <M.Button onClick={goToUserPage(user)}>
                                View
                              </M.Button>{' '}
                            </UsersTableCell>
                          )}
                          <UsersTableCell className='cds--table-column-menu'>
                            <OverflowUserActions user={user} last={last} />
                          </UsersTableCell>
                        </M.TableRow>
                      );
                    })}
                  </M.TableBody>
                </M.Table>
                <M.Pagination
                  style={{ textAlign: 'right', marginTop: '1.5rem' }}
                  selectedIndex={selectedIndex || 0}
                  pageCount={pageCount || 0}
                  onPageClick={handlePaginationClick}
                />
              </M.TableContainer>
            </M.GridCol>
          </M.GridRow>
        </M.Grid>
      </>
    ),
    [
      filterByEmail,
      filterByRoles,
      handlePaginationClick,
      isLoading,
      multiSelectRoles,
      pageCount,
      selectedIndex,
      sort,
      sort_by,
      sort_direction,
      users,
    ],
  );

  return (
    <TableContainer>
      <M.DataTable
        rows={Object.values(users)}
        headers={h}
        isSortable
        size='lg'
        sortRow={customSort}
        render={getTableContent}
      />
    </TableContainer>
  );
};

export default UsersTable;
