import { colors, M } from '@dashboard-experience/mastodon';
import {
  DataTableCustomRenderProps,
  DataTableHeader,
  DataTableRow,
  DenormalizedRow,
} from 'carbon-components-react';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

type ExtraProps = Pick<
  React.HTMLAttributes<HTMLElement>,
  'className' | 'style'
>;

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

type SortProps = {
  isSortHeader?: boolean;
  onSort?: () => void;
  sortable?: boolean;
  sortDirection?: SortDirections;
};

export type HeaderData = DataTableHeader & SortProps;

export type PageProps = {
  count?: number;
  index?: number;
  onClick?: (index: number) => void;
};

export type RowData = DataTableRow;

export type Search = {
  onChange?: RenderProps['onInputChange'];
  persistent?: boolean;
  placeholder?: string;
};

export type Props = {
  columns?: Array<HeaderData['key']>;
  disabled?: boolean;
  empty?: React.ReactElement | boolean;
  expandable?: boolean;
  expandedHighlight?: boolean;
  expansion?: (id: RowData['id']) => React.ReactNode;
  headers?: Array<HeaderData> | Record<HeaderData['key'], HeaderData['header']>;
  page?: PageProps;
  rows?: Array<RowData>;
  search?: boolean | Search;
  searchable?: boolean;
  selectable?: boolean;
  selections?: Record<string, boolean>;
  useSelections?: boolean;
  title?: string;
  underlineSearch?: boolean;
  onSelect?: (id: string, selected: boolean) => void;
} & ExtraProps;

export type Theme = {
  expanded?: {
    color?: CSSStyleDeclaration['color'];
    highlight?: boolean;
  };
};

export const Table: React.FC<Props> = ({
  columns,
  disabled,
  empty = true,
  expandable: _expandable,
  expandedHighlight,
  expansion,
  headers,
  page,
  rows,
  search,
  searchable,
  selectable,
  selections,
  useSelections = false,
  title,
  underlineSearch,
  onSelect,
  ...extras
}) => {
  const header = !!headers;

  if (!columns && Array.isArray(headers)) {
    columns = headers.map(h => h.key);
  }

  if (!columns) {
    throw new Error('An array of columns or headers must be specified');
  }

  if (!headers || !Array.isArray(headers)) {
    const c2h = headers || {};
    headers = columns?.map(key => ({ key, header: c2h[key] || '' }));
  }

  const expandable = !!expansion && !!rows?.length;
  const loading = !rows;

  if (!rows) {
    rows = Array.from({ length: 1 }, (_, index) => {
      const row = { id: `loading-${index}` };
      if (!columns) {
        return row;
      }
      return columns.reduce(
        (m, k) => ({ ...m, [k]: <TableCellLoading small /> }),
        row,
      );
    });
  }

  if (!header) {
    empty = false;
  }

  if (!search) {
    search = searchable;
  }

  if (!selectable) {
    selectable = !!selections;
  }

  rows.forEach(row => {
    row.disabled = row.disabled || disabled;
    // To enable external state updates on selections.
    // Workaround for instances where multiple tables are used in the same component.
    row.isSelected = useSelections
      ? selections?.[row.id]
      : row.isSelected || selections?.[row.id];
  });

  if (!selections) {
    selections = rows.reduce((acc: Record<string, boolean>, row: RowData) => {
      if (row.isSelected !== undefined) {
        acc[row.id] = row.isSelected;
      }
      return acc;
    }, {});
  }

  const render = renderer({
    disabled,
    empty,
    expandable,
    expansion,
    extras,
    header,
    loading,
    page,
    search,
    selectable,
    selections,
    title,
    underlineSearch,
    onSelect,
  });

  const theme: Theme = {
    expanded: {
      color: colors.uiNavy50,
      highlight: expandedHighlight,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <M.DataTable headers={headers} rows={rows} render={render} />
    </ThemeProvider>
  );
};

export default Table;

interface RendererProps
  extends Pick<
    Props,
    | 'disabled'
    | 'empty'
    | 'expandable'
    | 'expandedHighlight'
    | 'expansion'
    | 'page'
    | 'search'
    | 'onSelect'
  > {
  extras: ExtraProps;
  header: boolean;
  loading: boolean;
  selectable: boolean;
  selections: Record<string, boolean>;
  title?: string;
  underlineSearch?: boolean;
}

type RenderProps = DataTableCustomRenderProps<RowData, HeaderData>;

function renderer({
  disabled,
  empty,
  expandable,
  expansion,
  extras,
  header,
  loading,
  page,
  search,
  selectable,
  selections,
  title,
  underlineSearch,
  onSelect,
}: RendererProps) {
  return function render({
    headers,
    rows,
    getHeaderProps,
    getRowProps,
    getSelectionProps,
    getTableProps,
    onInputChange,
  }: RenderProps) {
    const getSelectProps = (row?: DenormalizedRow) => {
      const onClick = () => {
        if (row) {
          onSelect?.(row.id, !row.isSelected);
        } else {
          const selected = rows.some(r => r.isSelected);
          if (selected) {
            rows.forEach(r => onSelect?.(r.id, false));
          } else {
            rows.forEach(r => onSelect?.(r.id, true));
          }
        }
      };

      if (row) {
        row.isSelected =
          !row.id.includes('loading') &&
          !!selections[row.id] &&
          selections[row.id];
      } else {
        const selectionValues = Object.values(selections);
        const isIndeterminate =
          selectionValues.some(value => value) &&
          !selectionValues.every(value => value);
        const isChecked =
          selectionValues.length > 0 && selectionValues.every(value => value);

        return {
          ...getSelectionProps({ onClick }),
          indeterminate: isIndeterminate,
          checked: isChecked,
        };
      }

      return getSelectionProps({ row, onClick });
    };

    return (
      <TableContainer {...extras}>
        <TableTitle>{title}</TableTitle>
        <TableToolbar
          search={search}
          onInputChange={onInputChange}
          underlineSearch={underlineSearch}
        />
        <M.Table {...getTableProps()}>
          <TableHeader
            disabled={disabled}
            expandable={expandable}
            headers={headers}
            selectable={selectable}
            show={header}
            getHeaderProps={getHeaderProps}
            // eslint-disable-next-line react/jsx-no-bind
            getSelectProps={getSelectProps}
          />
          <M.TableBody className={!header ? 'no-dividers' : undefined}>
            <Empty empty={!rows.length && empty} span={headers.length + 2} />
            {rows.map(row => (
              <TableRow
                expandable={expandable}
                expansion={expansion}
                header={header}
                key={row.id}
                row={row}
                selectable={selectable}
                span={headers.length + 2}
                getRowProps={getRowProps}
                // eslint-disable-next-line react/jsx-no-bind
                getSelectProps={getSelectProps}
              />
            ))}
          </M.TableBody>
        </M.Table>
        <Pagination loading={loading} page={page} />
      </TableContainer>
    );
  };
}

const Empty: React.FC<Pick<RendererProps, 'empty'> & { span: number }> = ({
  empty,
  span,
}) => {
  if (!empty) {
    return null;
  }

  const content = empty === true ? null : empty;

  return (
    <M.TableRow data-testid='table-row-empty'>
      <M.TableCell colSpan={span}>{content}</M.TableCell>
    </M.TableRow>
  );
};

const Pagination: React.FC<Pick<RendererProps, 'loading' | 'page'>> = ({
  loading,
  page,
}) => {
  if (loading || !page || !page.count) {
    return null;
  }

  return (
    <M.Pagination
      data-testid='table-pagination'
      selectedIndex={page.index}
      pageCount={page.count}
      onPageClick={page.onClick}
    />
  );
};

const flexStartToolbarContent = styled(M.TableToolbarContent)`
  justify-content: flex-start;
  margin: 1rem 0rem 3rem 0rem;
`;

const TableContainer = styled(M.TableContainer)`
  // remove position relative on container to not break M.Menu behavior
  position: unset;

  .cds--data-table-content {
    overflow: visible;
  }
`;

const TableCellLoading = styled(M.LoadingBlock)`
  max-width: 10em;
`;

const TableExpandRow = styled(M.TableExpandRow)`
  ${({ isExpanded, theme }) => {
    if (!isExpanded || !theme.expanded.highlight) {
      return '';
    }
    const { color } = theme.expanded;
    return `
      background-color: ${color};
      td {
        background-color: ${color} !important;
      }
    `;
  }}
`;

const TableExpandedRow = styled(M.TableExpandedRow)`
  ${({ theme }) => {
    if (!theme.expanded.highlight) {
      return '';
    }
    const { color } = theme.expanded;
    return `
      background-color: ${color};
      td {
        background-color: ${color} !important;
      }
    `;
  }}
`;

const underlinedToolBarSearch = styled(M.TableToolbarSearch)`
  border-bottom: 1px solid black;
  width: 50%;
`;

interface TableHeaderProps
  extends Pick<RendererProps, 'disabled' | 'expandable' | 'selectable'>,
    Pick<RenderProps, 'headers' | 'getHeaderProps'> {
  getSelectProps: (row?: DenormalizedRow) => any;
  show: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  disabled,
  expandable,
  headers,
  selectable,
  show,
  getHeaderProps,
  getSelectProps,
}) => {
  if (!show) {
    return null;
  }

  return (
    <M.TableHead>
      <M.TableRow>
        {expandable && <M.TableExpandHeader key='expand-header' />}
        {selectable && (
          <M.TableSelectAll
            key='select-all'
            {...getSelectProps()}
            disabled={disabled}
          />
        )}
        {headers.map(h => {
          return (
            <M.TableHeader
              {...getHeaderProps({ header: h })}
              key={h.key}
              isSortable={h.sortable}
              isSortHeader={h.isSortHeader}
              sortDirection={h.sortDirection}
              onClick={h.onSort}
            >
              {h.header}
            </M.TableHeader>
          );
        })}
      </M.TableRow>
    </M.TableHead>
  );
};

interface TableRowProps
  extends Pick<RendererProps, 'expandable' | 'expansion' | 'selectable'>,
    Pick<RenderProps, 'getRowProps'> {
  header: boolean;
  row: DenormalizedRow;
  span: number;
  getSelectProps: (row?: DenormalizedRow) => any;
}

const TableRow: React.FC<TableRowProps> = ({
  expandable,
  expansion,
  header,
  row,
  selectable,
  span,
  getRowProps,
  getSelectProps,
}) => {
  const selector = selectable ? (
    <M.TableSelectRow
      className={!header ? 'no-table-header' : undefined}
      {...getSelectProps(row)}
    />
  ) : null;

  const Row = expandable ? TableExpandRow : M.TableRow;

  return (
    <>
      <Row data-testid={`table-row-${row.id}`} {...getRowProps({ row })}>
        {selector}
        {row.cells.map(cell => (
          <M.TableCell key={cell.id}>{cell.value}</M.TableCell>
        ))}
      </Row>
      {expandable && row.isExpanded && (
        <TableExpandedRow colSpan={span}>
          {expansion?.(row.id)}
        </TableExpandedRow>
      )}
    </>
  );
};

interface TableToolbarProps
  extends Pick<RendererProps, 'search'>,
    Pick<RenderProps, 'onInputChange'> {
  underlineSearch?: boolean;
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  search,
  underlineSearch,
  onInputChange,
}) => {
  if (!search) {
    return null;
  }
  const TableToolbarContent = underlineSearch
    ? flexStartToolbarContent
    : M.TableToolbarContent;
  return (
    <M.TableToolbar>
      <TableToolbarContent>
        <TableToolbarSearch
          onInputChange={onInputChange}
          search={search}
          underlineSearch={underlineSearch}
        />
      </TableToolbarContent>
    </M.TableToolbar>
  );
};

interface TableToolbarSearchProps
  extends Pick<RendererProps, 'search'>,
    Pick<RenderProps, 'onInputChange'> {
  underlineSearch?: boolean;
}

const TableToolbarSearch: React.FC<TableToolbarSearchProps> = ({
  search,
  underlineSearch,
  onInputChange,
}) => {
  if (!search) {
    return null;
  }

  const props = typeof search === 'boolean' ? {} : search;
  const ToolbarSearch = underlineSearch
    ? underlinedToolBarSearch
    : M.TableToolbarSearch;
  return (
    <ToolbarSearch
      onChange={onInputChange}
      persistent
      placeholder={props.placeholder}
      {...props}
    />
  );
};

const TableTitle: React.FC = ({ children }) => {
  if (!children) {
    return null;
  }
  return <h3>{children}</h3>;
};
