import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { queryCache } from 'react-query';

import {
  RealtimeStatuses,
  SubscriptionProps,
  ViewerList,
} from 'api/realtime/types';
import useRealtime from 'hooks/useRealtime';
import { useDispatch } from 'react-redux';
import { toastSuccess } from 'actions';
import * as Notifications from 'state/notifications/entity';
import { useTranslation } from 'react-i18next';
import { SECONDS } from 'utils';
import { useDashboardRelay } from 'hooks';
import UIContext from 'context/UI';
import { useUniqueToast } from './Notifications/hooks';

const keysAssociatedWithRoom = {
  candidate: [
    'accounts/adjudication_subtypes', // Note: currently not set up to be called within a Candidate; but that might be an oversight.
    'adjudication-events',
    'adverse-actionable',
    'adverse-actions',
    'candidateExceptions',
    'candidates/dashboard',
    'documents',
    'documents/list',
    'eta',
    'expungements/list', // Note: only ever called for Candidates with County searches
    'getContinuousChecks',
    'getSubscriptions',
    'nodes/fetch_nodes', // Note: only ever called for accounts with Hierarchy
    'packages/list', // Note: this MIGHT never actually refect; the query VERY intentionally uses cached data
    'report-action-events',
    'reportExceptions',
    'reports/multi-mvr',
  ],
  // Realtime's server can designate 3 other types of room which we currently don't make use of:
  report: [],
  account: [],
  invitation: [],
};

// For consistency, this ID is used as a uniqueness indicator for the toast,
// as well as a key to listen to for Dashboard<->Customer messaging
const UPDATE_MESSAGE_ID = 'realtimeUpdate';

export const RealtimeProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('', { keyPrefix: 'realtime.reload' });
  const dispatch = useDispatch();
  const refetchTriggered = useRef(false);
  const { contextId, isIframe } = useContext(UIContext);

  const [room, setRoom] = useState<SubscriptionProps>({
    roomID: null,
    roomType: null,
  });

  const createUniqueToast = useUniqueToast(UPDATE_MESSAGE_ID);

  const toastMessage = useRef(t('generic_updated')); // Initialize to a generic message, but this can change upon subscribing to a room

  // Refetch new data for whatever type of room we're in
  const triggerRefetches = useCallback(() => {
    // If we're not subscribed to a room, then there's nothing to go get
    if (!room.roomType) return;

    refetchTriggered.current = true;

    // Invalidate all of the predefined querykeys, which will cause them to do their own respective re-fetches
    keysAssociatedWithRoom[room.roomType].forEach((key: string) => {
      queryCache.invalidateQueries(key);
    });
  }, [room]);

  useDashboardRelay({
    messageId: UPDATE_MESSAGE_ID,
    callback: triggerRefetches,
    contextId,
    isIframe,
    deps: [triggerRefetches],
  });

  // Inform the user whenever the page has finished all pending refetches.
  // Wrapped in a useEffect to avoid setting up a bunch of identical subscriptions
  useEffect(() => {
    queryCache.subscribe(qc => {
      // isFetching holds a count of all non-completed queries; so whenever that hits 0, we know that everything is settled
      if (refetchTriggered.current && qc.isFetching === 0) {
        refetchTriggered.current = false;
        dispatch(toastSuccess(t('refreshed'), '', 3 * SECONDS));
      }
    });
  }, [dispatch, t]);

  // Function to display a toast to the user to let them know that there are updates.
  // This ultimately gets used by the event handlers within the usePrimus hook
  const realtimeToast = useCallback(
    (message: string) => {
      // If the Realtime service specified a "message", then use that. Else, use whatever the component offered.
      const title: string = message || toastMessage.current;

      createUniqueToast({
        kind: Notifications.Kind.info,
        title,
        action: {
          callback: triggerRefetches,
          message: t('call_to_action', { type: room.roomType }),
        },
        timeout: 0,
      });
    },
    [createUniqueToast, room.roomType, t, triggerRefetches],
  );

  const { roomViewers, realtimeStatus } = useRealtime({
    ...room,
    updateCallback: realtimeToast,
  });

  // Avoid letting users subscribe to rooms before everything is set up
  const subscribeToRoom = useCallback(
    (details: SubscriptionProps & { toastMessage: string }) => {
      if (
        realtimeStatus === RealtimeStatuses.READY ||
        realtimeStatus === RealtimeStatuses.ACTIVE
      ) {
        // Check that we're not attempting to re-subscribe to the current room
        if (
          room.roomID !== details.roomID ||
          room.roomType !== details.roomType
        ) {
          setRoom({ roomID: details.roomID, roomType: details.roomType });
        }
      }

      toastMessage.current = details.toastMessage;
    },
    [realtimeStatus, room.roomID, room.roomType],
  );

  const value = useMemo(() => {
    return {
      subscribeToRoom,
      roomViewers,
      triggerRefetches,
      realtimeToast,
    };
  }, [roomViewers, subscribeToRoom, triggerRefetches, realtimeToast]);

  return (
    <RealtimeContext.Provider value={value}>
      {children}
    </RealtimeContext.Provider>
  );
};

type ContextType = {
  subscribeToRoom: Function;
  roomViewers: ViewerList;
  triggerRefetches: Function;
  realtimeToast: Function;
};

export const RealtimeContext = React.createContext<ContextType>({
  subscribeToRoom: () => {},
  roomViewers: [],
  triggerRefetches: () => {},
  realtimeToast: () => {},
});
