import groupBy from 'lodash/groupBy';
import unique from 'lodash/uniq';
import { Namespace } from 'modules/assess/api';
import classification_codes from 'modules/assess/models/mvr/classification-codes';
import { ItemType } from 'modules/assess/models/mvr/codes';
import * as MVR from 'modules/assess/models/mvr/rules';
import { useFetch } from 'modules/assess/ui/mvr/category-sets/instance/version/api';
import { useInstanceVersion } from 'modules/assess/ui/rules/categories/state';
import { createContext, useContext, useMemo } from 'react';

const useCategories = () => {
  const instance = useInstanceVersion();
  const query = useFetch(instance?.id, instance?.version.id);
  const categories: Record<ItemType, Array<any>> = groupBy(
    query.data?.categories,
    c => c.type,
  );

  Object.values(categories).forEach(list => {
    list.forEach((c, i) => list.splice(i, 1, c.name));
  });

  return categories;
};

export type OptionValuesType = Record<string, Array<any>>;

export const OptionValues = createContext<OptionValuesType>({});

export const useOptionValues = () => useContext(OptionValues);

export const useOptionValuesConfig = (
  namespace: Namespace,
  targets: Array<any>,
) => {
  const categories = useCategories();

  return useMemo(() => {
    const config: OptionValuesType = {};

    if (namespace === Namespace.mvr) {
      const dynamicFacts: { [key: string]: any } = {
        [MVR.Fact.CATEGORY]: categories,
        [MVR.Fact.CLASSIFICATION_CODE]: classification_codes,
      };

      targets?.forEach((target: MVR.RecordType) => {
        Object.entries(dynamicFacts).forEach(([fact, options]) => {
          if (MVR.TYPE_FACTS[target]?.includes(fact as any)) {
            const values = config[fact] || [];
            const updates = dynamicFacts[fact][target] || [];
            values.push(...updates);
            config[fact] = unique(values);
          }
        });
      });
    }

    return config;
  }, [categories, namespace, targets]);
};
