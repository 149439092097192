import { HostProvider as CoreHostProvider } from '@checkrhq/adjudication-ui-core';
import { ADJUDICATION_API_URL } from 'Constants';
import React, { FC, PropsWithChildren } from 'react';
import { getAppropriateToken } from 'utils';

export { useHost } from '@checkrhq/adjudication-ui-core';
export type { HostContextValue } from '@checkrhq/adjudication-ui-core';

const getAccessToken = () => {
  const token = getAppropriateToken() || '';
  return Promise.resolve(token);
};

export type HostProviderProps = PropsWithChildren<{}>;

export const HostProvider: FC<HostProviderProps> = ({ children }) => {
  return (
    <CoreHostProvider
      getAccessToken={getAccessToken}
      heartbeat={0}
      url={ADJUDICATION_API_URL}
    >
      {children}
    </CoreHostProvider>
  );
};
