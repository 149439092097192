import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { useEta, useReport } from 'containers/Report';
import { CountyCriminalSearch } from 'types';
import { hasScreeningPointers } from 'utils/ScreeningPointerUtils';
import { genericTriggerText } from './Eta/utils';

import AdditionalInfo from './AdditionalInfo';
import AdditionalInfoWithEta from './Eta/AdditionalInfoWithEta';
import GenericTooltip from './Eta/GenericTooltip';
import Overdue from './Eta/Overdue';

const tooltips = {
  complete: {
    triggerText: genericTriggerText,
    text: <GenericTooltip />,
  },
};

type Props = {
  isComplete: boolean;
  searches: CountyCriminalSearch[];
};

const AboutThisSearch: React.FC<Props> = ({ isComplete, searches }) => {
  const { estimatedCompletionTime, isLoading, screenings } = useEta();
  const report = useReport();
  const shouldUseEta = screenings && screenings?.length > 0;

  if (!estimatedCompletionTime && !hasScreeningPointers(searches)) return null;

  return (
    <>
      <M.Divider />
      <M.AboutThisSearch initialExpanded={!isComplete}>
        {!isLoading && (
          <>
            {isComplete && estimatedCompletionTime && (
              <M.Eta
                complete
                data-testid='eta-container'
                tooltips={tooltips}
                createdAt={report.created_at}
                completedAt={report.completed_at}
                estimatedCompletionTime={estimatedCompletionTime}
              >
                <M.Eta.Overdue>
                  <Overdue />
                </M.Eta.Overdue>
              </M.Eta>
            )}
            {shouldUseEta ? (
              <AdditionalInfoWithEta
                screenings={screenings}
                createdAt={report.created_at}
              />
            ) : (
              <AdditionalInfo screenings={searches} />
            )}
          </>
        )}
      </M.AboutThisSearch>
    </>
  );
};

export default AboutThisSearch;
