import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

type Props = {
  disabled?: boolean;
  onCancel?: (event: SyntheticEvent) => void;
  onSave?: (event: SyntheticEvent) => void;
};

export const Actions: React.FC<Props> = ({ disabled, onCancel, onSave }) => {
  return (
    <>
      <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
      <Button
        kind='primary'
        trans='verbs.save'
        onClick={onSave}
        disabled={disabled}
      />
    </>
  );
};

export default Actions;
