import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import IneligiblePersonsTableCell from './IneligiblePersonsTableCell';

type ContinuousCandidateErrors = {
  id: string;
  created_at: string;
  candidate_full_name: string;
  candidate_id: string;
  error_message: string;
};

type Header = {
  key: string;
  name: string;
};

const tableHeaders: Header[] = [
  { key: 'created_at', name: 'Unenrolled' },
  { key: 'candidate_id', name: 'Candidate ID' },
  { key: 'candidate_full_name', name: 'Name' },
  { key: 'error_message', name: 'Reason' },
];

type Props = {
  tableRows: ContinuousCandidateErrors[];
};

const IneligiblePersonsTable: React.FC<Props> = ({ tableRows }) => {
  const getTableContent = useCallback(
    ({ rows, headers }: any) => (
      <M.TableContainer className='hackathon'>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              {headers.map((header: Header) => (
                <M.TableHeader key={header.key}>{header.name}</M.TableHeader>
              ))}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((row: any) => (
              <M.TableRow key={row.id}>
                {row.cells.map((cell: any) => (
                  <IneligiblePersonsTableCell key={cell.id} cell={cell} />
                ))}
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    ),
    [],
  );

  return (
    <M.DataTable
      headers={tableHeaders}
      rows={tableRows}
      render={getTableContent}
    />
  );
};

export default IneligiblePersonsTable;

export type { ContinuousCandidateErrors };
