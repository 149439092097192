import { AnyQueryKey, useQuery, useMutation, queryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDispatch as useApiKeysDispatch } from 'components/Account/DeveloperSettings/ApiKeys/apiKeys-context';
import { toastError, toastSuccess } from 'actions';
import {
  AddApiKeyParams,
  DeleteApiKeyParams,
} from 'containers/ApiKeysContainer';
import { useErrorNotifier } from 'hooks';
import { addApiKey, deleteApiKey, getApiKeys } from './actions';

export const useGetApiKeys = (accountId: string) => {
  const errorNotifier = useErrorNotifier();

  const key: AnyQueryKey = ['containers/apiKeys', { id: accountId }];

  const request = () => getApiKeys(accountId);

  const result = useQuery(key, request, {
    enabled: Boolean(accountId),
    refetchOnWindowFocus: false,
    onError: error => {
      errorNotifier(error, { title: 'Error fetching API Keys' });
    },
  });
  return result;
};

export const useAddApiKey = () => {
  const dispatch = useDispatch();
  const apiKeysDispatch = useApiKeysDispatch();
  const request = (params: AddApiKeyParams) => addApiKey(params);

  const [call, result] = useMutation(request, {
    onSuccess: (apiKeys: any) => {
      queryCache.invalidateQueries('containers/apiKeys');
      apiKeysDispatch({
        type: 'add api key',
        payload: apiKeys,
      });
      dispatch(toastSuccess('API Key Successfully Created'));
    },
    onError: error => {
      dispatch(toastError('Error adding API Key', error.message));
    },
  });

  return {
    call,
    result,
  };
};

export const useDeleteApiKey = () => {
  const dispatch = useDispatch();
  const apiKeysDispatch = useApiKeysDispatch();
  const request = (params: DeleteApiKeyParams) => deleteApiKey(params);

  const [call, result] = useMutation(request, {
    onSuccess: (apiKey: any) => {
      queryCache.invalidateQueries('containers/apiKeys');
      apiKeysDispatch({
        type: 'delete api keys',
        payload: apiKey,
      });
      dispatch(
        toastSuccess(
          apiKey.expires_at ? 'API Key Deleted' : 'API Key Restored',
        ),
      );
    },
    onError: error => {
      dispatch(toastError('Error deleting API Key', error.message));
    },
  });

  return {
    call,
    result,
  };
};

export const useUnDeleteApiKey = () => {
  const dispatch = useDispatch();
  const apiKeysDispatch = useApiKeysDispatch();
  const request = (params: DeleteApiKeyParams) => deleteApiKey(params);

  const [call, result] = useMutation(request, {
    onSuccess: (apiKey: any) => {
      queryCache.invalidateQueries('containers/apiKeys');
      apiKeysDispatch({
        type: 'undelete api keys',
        payload: apiKey,
      });
      dispatch(
        toastSuccess(
          apiKey.expires_at ? 'API Key Deleted' : 'API Key Restored',
        ),
      );
    },
    onError: error => {
      queryCache.invalidateQueries('containers/apiKeys');
      dispatch(toastError('Error deleting API Key', error.message));
    },
  });

  return {
    call,
    result,
  };
};
