import React from 'react';
import { useCandidateRededesignPhaseThree } from 'modules/candidate';
import { Report } from 'types';
import ReportActions from './report-actions';
import Viewers from './viewers';

type Props = {
  isScrolled: boolean;
  reportsAndInvitations: Report[];
};

const RightSection: React.FC<Props> = ({
  isScrolled,
  reportsAndInvitations,
}) => {
  const candidateededesignPhaseThree = useCandidateRededesignPhaseThree();
  const className = candidateededesignPhaseThree
    ? 'candidate-header-right-v2'
    : 'candidate-header-right';

  return (
    <div className={className}>
      <Viewers reportsAndInvitations={reportsAndInvitations} />
      <ReportActions isScrolled={isScrolled} />
    </div>
  );
};

export default RightSection;
