import React from 'react';
import { FieldComponentProps } from '../../types';

const PostalCode: React.FC<FieldComponentProps> = ({ candidate, testid }) => {
  return (
    <div data-testid={testid}>
      {candidate?.zipcode || candidate?.international_postal_code}
    </div>
  );
};

export default PostalCode;
