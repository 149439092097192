import React, { useCallback, useState, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDebouncedCallback } from '@dashboard-experience/utils';
import {
  useSegmentsContext,
  useDispatch as useSegmentsDispatch,
} from 'components/Segments/SegmentsContext';
import {
  Segment,
  ParentContext,
  useSearchHierarchy,
  SEARCH_HIERARCHY,
} from '../../api/segments';
import {
  FilterLabel,
  SearchContainer,
  FiltersContainer,
  CheckboxContainer,
} from './SegmentsComponents';

type Props = {
  accountId: string;
  parentContext: ParentContext;
  segments: Segment[];
  selectedSegments: string[];
};

const SegmentsSearch: React.FC<Props> = ({
  accountId,
  parentContext,
  segments,
  selectedSegments,
}) => {
  const [checked, setChecked] = useState(true);
  const [filter, setFilter] = useState('');

  const { call: searchHierarchy } = useSearchHierarchy();

  const { search } = useSegmentsContext();

  const segmentsDispatch = useSegmentsDispatch();

  const assignedSegments = segments.filter(segment => {
    return selectedSegments.indexOf(segment.custom_id) > -1;
  });

  const updateSegments = useCallback(
    (filteredSegments: Segment[]) => {
      segmentsDispatch({
        type: SEARCH_HIERARCHY,
        payload: {
          filteredSegments,
          search: {
            ...search,
          },
        },
      });
    },
    [segmentsDispatch],
  );

  useEffect(() => {
    if (checked) {
      updateSegments(assignedSegments);
    }
  }, [checked, assignedSegments]);

  const debouncedSearch = useDebouncedCallback(
    target => {
      const { value } = target;
      if (value) {
        searchHierarchy({
          accountId,
          parentContext,
          query: value,
        });
        setChecked(false);
      } else {
        updateSegments([]);
      }
    },
    350,
    [accountId, parentContext, updateSegments],
  );

  const handleSearch = useCallback(
    e => {
      debouncedSearch(e.target);
      setFilter(e.target.value);
    },
    [debouncedSearch],
  );

  const handleCheck = useCallback(() => {
    setChecked(!checked);
    if (checked) {
      setFilter('');
      updateSegments([]);
    }
  }, [checked, updateSegments]);

  return (
    <FiltersContainer data-testid='segments-filter-container'>
      <SearchContainer size='30rem'>
        <FilterLabel>Search Node List</FilterLabel>
        <M.Search
          name='name'
          onChange={handleSearch}
          value={filter}
          autoComplete='off'
          placeholder='Search for a node name or custom id'
          data-testid='segments-filter-search-input'
        />
      </SearchContainer>
      <CheckboxContainer>
        <M.Checkbox
          className='round'
          key='selected'
          id='selected'
          name='selected'
          disabled={false}
          hideLabel={false}
          checked={checked}
          labelText='Show only selected nodes'
          onClick={handleCheck}
          data-testid='segments-filter-checkbox'
        />
      </CheckboxContainer>
    </FiltersContainer>
  );
};

export default SegmentsSearch;
