import { accountHasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import {
  useAtsIntegrationConfigSchema,
  useAtsIntegrationConfigs,
  useUpdateAtsIntegrationConfigs,
} from 'api/atsIntegration';
import React from 'react';
import { isEqual } from 'lodash';
import {
  ConfigurationsSection,
  ObjectSyncSection,
  ValidationSection,
} from './sections';
import { Wrapper } from './styles';

const hasItem = (arr: unknown[] | null | undefined) => (arr?.length ?? 0) > 0;

const AtsIntegrationSettings = () => {
  const user = useUser();
  const hrisEnabled = accountHasPermission(user, 'hris_configurations_enabled');

  const { data: integrationConfigData } = useAtsIntegrationConfigs(hrisEnabled);

  const { data: schemaData } = useAtsIntegrationConfigSchema();

  const [configs, setConfigs] = React.useState(
    integrationConfigData?.tenant_config ?? {},
  );
  const [configErrors, setConfigErrors] = React.useState(
    {} as Record<string, string>,
  );

  const { call: saveConfig, result: configUpdateResult } =
    useUpdateAtsIntegrationConfigs();

  const defaultTenantConfig = React.useMemo(() => {
    return (
      configUpdateResult.data?.tenant_config ??
      integrationConfigData?.tenant_config ??
      {}
    );
  }, [
    configUpdateResult.data?.tenant_config,
    integrationConfigData?.tenant_config,
  ]);

  // Update server side config on save button click always
  const onConfigSave = React.useCallback(() => {
    saveConfig({ tenant_config: configs });
  }, [configs, saveConfig]);

  // Try to update server side config only if there is config update
  const onValidation: () => Promise<void> = React.useCallback(async () => {
    if (!isEqual(configs, defaultTenantConfig)) {
      await saveConfig({ tenant_config: configs });
    }
  }, [configs, defaultTenantConfig, saveConfig]);

  const onConfigUpdate = React.useCallback(
    (key: string, value: any) => {
      setConfigs({
        ...configs,
        [key]: value,
      });
      if (configErrors[key]) {
        // remove error msg
        const newConfigErrors = { ...configErrors };
        delete newConfigErrors[key];
        setConfigErrors(newConfigErrors);
      }
    },
    [configs, configErrors],
  );

  const onConfigReset = React.useCallback(() => {
    setConfigs(defaultTenantConfig);
  }, [defaultTenantConfig]);

  const onValidationFailure = React.useCallback(
    (failedFields: Record<string, string>) => {
      setConfigErrors({ ...configErrors, ...failedFields });
    },
    [configErrors],
  );

  return (
    <Wrapper>
      {schemaData && hasItem(schemaData.config_schema) && (
        <ConfigurationsSection
          configs={configs}
          onSave={onConfigSave}
          onUpdate={onConfigUpdate}
          onReset={onConfigReset}
          configErrors={configErrors}
          configSchema={schemaData.config_schema}
        />
      )}
      {schemaData && hasItem(schemaData.validation_schema) && (
        <ValidationSection
          validations={schemaData.validation_schema}
          partnerSlug={integrationConfigData?.partner ?? ''}
          onValidation={onValidation}
          onValidationFailure={onValidationFailure}
        />
      )}
      {schemaData && hasItem(schemaData.object_sync_schema) && (
        <ObjectSyncSection
          objectSyncs={schemaData.object_sync_schema}
          partnerSlug={integrationConfigData?.partner ?? ''}
        />
      )}
    </Wrapper>
  );
};

export default AtsIntegrationSettings;
