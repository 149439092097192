import React from 'react';
import { UserType } from 'modules/case-management/types';
import UnassignedUser from './UnassignedUser';
import User from './User';

type PropTypes = {
  assignee: UserType | null;
  reassignTask: (user: UserType | null) => void;
};

const Assignee = ({ assignee, reassignTask }: PropTypes) => {
  return assignee ? (
    <User user={assignee} />
  ) : (
    <UnassignedUser reassignTask={reassignTask} />
  );
};

export default Assignee;
