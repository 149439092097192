import React, { useCallback, useState } from 'react';
import Button from 'components/Button';
import { ID } from 'modules/id';
import Dialog from '../insights';

type Props = {
  version?: ID;
};

type Type = React.FC<Props>;

export const Insights: Type = ({ version }) => {
  const [open, show] = useState(false);

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  return (
    <>
      <Button
        data-testid='assess-ruleset-version-actions-insights'
        kind='secondary'
        size='sm'
        trans='nouns.predictive_insights'
        onClick={handleClick}
      />
      <Dialog open={open} version={version} onClose={handleClose} />
    </>
  );
};

export default Insights;
