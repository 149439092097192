export enum Operator {
  AMRI = 'amri',
  EQ = 'eq',
  GE = 'ge',
  GT = 'gt',
  IN = 'in',
  LE = 'le',
  LT = 'lt',
  MRI = 'mri',
  NE = 'ne',
  UN = '',
}

export default Operator;
