import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { MULTIPLE_PEOPLE } from '../Context';
import { useUser } from '../../../../context/CurrentUser';
import { getDashboardUrl } from '../../../../utils';

const HeaderContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
`;

const Description = styled.p`
  font-size: 16px !important;
  margin: 0 !important;
  line-height: 24px !important;
  max-width: 775px !important;
`;

type HeaderProps = {
  providerType: 'CANDIDATE' | 'MYSELF';
  inviteMethod: string;
  manualBulkUploadType?: string;
};

const Header: React.FC<HeaderProps> = ({
  providerType,
  inviteMethod,
  manualBulkUploadType,
}) => {
  const currentUser = useUser();
  const bulkOrderHistoryUrl = hasPermission(
    currentUser,
    'read_account_invoices',
  )
    ? getDashboardUrl(`/billing/bulk-history`)
    : getDashboardUrl(`/bulk-order-history`);
  const headerDescriptionText = useMemo((): ReactNode => {
    let textNode: ReactNode;
    if (providerType === 'CANDIDATE') {
      if (inviteMethod === 'UPLOAD') {
        textNode = (
          <>
            After you submit your order, you can track its progress&nbsp;
            <M.Link href={bulkOrderHistoryUrl} target='_blank' size='lg'>
              here
            </M.Link>
            . Invitations cannot be unsent.
          </>
        );
      } else {
        textNode = `After you submit your order, we'll email each candidate a unique invitation link.`;
      }
    } else if (manualBulkUploadType === MULTIPLE_PEOPLE) {
      textNode = (
        <>
          After you submit your order, you can track its progress&nbsp;
          <M.Link href={bulkOrderHistoryUrl} target='_blank' size='lg'>
            here
          </M.Link>
          .
        </>
      );
    } else {
      textNode = `After submitting your order, we'll take you to the candidate information form so you can add the candidate's remaining details.`;
    }
    return textNode;
  }, [bulkOrderHistoryUrl, inviteMethod, manualBulkUploadType, providerType]);
  return (
    <HeaderContainer>
      <h3 data-testid='review-and-submit-header-title'>
        Review and submit order
      </h3>
      <Description data-testid='review-and-submit-description'>
        Make sure all of the information on this page is correct.{' '}
        {headerDescriptionText}
      </Description>
    </HeaderContainer>
  );
};

export default Header;
