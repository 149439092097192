import React from 'react';
import styled from 'styled-components';
import Description from './Description';
import Title from './Title';

export type Props = {
  enabled?: boolean;
};

const StyledContainer = styled.div`
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

export const Container: React.FC<Props> = ({ enabled }) => {
  if (!enabled) {
    return null;
  }

  return (
    <StyledContainer data-testid='assess-ruleset-version-context-dates-panel'>
      <Title />
      <Description />
    </StyledContainer>
  );
};

export default Container;
