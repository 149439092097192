import React from 'react';
import { PackagesComponent } from 'components/Packages';
import LoadingScreen from 'components/Account/LoadingScreen';
import { CurrentUser } from '@dashboard-experience/utils';

type Prop = {
  currentUser?: CurrentUser;
};

const PackagesContainer: React.FC<Prop> = ({ currentUser }) => {
  return currentUser?.account?.id ? (
    <PackagesComponent currentUser={currentUser} />
  ) : (
    <LoadingScreen />
  );
};

export default PackagesContainer;
