import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { PageSubtitle } from '../../styles';
import { Modal } from '../Modal';
import { WelcomeContainer, WelcomeLoader } from './styles';
import welcomeLoader from '../svg/welcomeLoader.json';

const OrderProcessing = () => {
  const { t } = useTranslation('signup');

  return (
    <Modal bodyClassName='welcome-modal-body' hideHeader>
      <WelcomeContainer>
        <WelcomeLoader data-testid='loader'>
          <Player src={welcomeLoader} autoplay loop />
        </WelcomeLoader>
        <h3>{t(`pages.welcomePage.title`)}</h3>
        <PageSubtitle>{t(`pages.welcomePage.subtitle`)}</PageSubtitle>
      </WelcomeContainer>
    </Modal>
  );
};

export default OrderProcessing;
