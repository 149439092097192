import React, { useCallback, useContext, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDashboardRelay, RelayTypes } from 'hooks';
import UIContext from 'context/UI';
import { useGetSubscriptions } from 'api/continuousChecks/hooks';
import _ from 'lodash';
import { GenericObject } from 'types';
import { postMessageToDashboard } from 'utils';
import { SubscriptionToggleProps } from './types';

const SubscriptionToggle: React.FC<SubscriptionToggleProps> = ({
  candidate,
}) => {
  const { id: candidateId } = candidate;
  const { contextId, isIframe } = useContext(UIContext);
  const {
    data: subscriptionsResponse,
    refetch: refetchSubscriptions,
  }: GenericObject = useGetSubscriptions(candidateId);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    if (subscriptionsResponse) {
      const { data } = subscriptionsResponse;
      setSubscriptions(data);
    }
  }, [subscriptionsResponse]);

  const subscriptionsUpdatedPostMessageObject: RelayTypes = {
    messageId: 'subscriptions_updated',
    callback: refetchSubscriptions,
    isIframe,
    contextId,
  };

  useDashboardRelay(subscriptionsUpdatedPostMessageObject);

  /**
   * @name showSubscriptionSettingModal
   * @function
   * @memberOf CandidateInformation
   * @description Sends message to Angular dashboard to show subscription modal
   */
  const showSubscriptionsModal = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'open_subscriptions_modal',
      });
    }
  }, [contextId, isIframe]);

  /**
   * @name candidateHasSubscriptions
   * @function
   * @memberOf CandidateInformation
   * @description Determines if there are any subscriptions applicable
   * @returns {boolean}
   */
  const candidateHasSubscriptions = useCallback(
    () => !!_.find(subscriptions, (s: GenericObject) => !!s.id),
    [subscriptions],
  );

  return (
    <>
      {subscriptionsResponse && (
        <M.Toggle
          id='subscriptions-toggle'
          size='sm'
          onClick={showSubscriptionsModal}
          toggled={candidateHasSubscriptions()}
          toggledText='Subscriptions'
          untoggledText='Subscriptions'
        />
      )}
    </>
  );
};

export default SubscriptionToggle;
