import React from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryChange } from 'modules/assess/models/mvr/category-code-set-versions/version-diff/category-change';
import CategoryChangeSection from './category-change-section';

export type Props = {
  categories?: Array<CategoryChange>;
};

const CategoryChanges: React.FC<Props> = ({ categories }) => {
  const { t } = useTranslation('assess');
  const baseKey =
    'mvr.category-sets.instance.versions.publish.changes.category';

  if (!categories) return null;

  const createdChanges: any[] = [];
  const editedChanges: any[] = [];
  const deletedChanges: any[] = [];

  categories?.forEach(({ name }) => {
    if (name.new && name.old) {
      editedChanges.push(`${name.old} → ${name.new}`);
    } else if (name.new && !name.old) {
      createdChanges.push(name.new);
    } else {
      deletedChanges.push(name.old);
    }
  });

  return (
    <div data-testid='assess-ccsv-category-changes'>
      <h2>{t(`${baseKey}.title`)}</h2>
      <CategoryChangeSection type='created' changes={createdChanges} />
      <CategoryChangeSection type='edited' changes={editedChanges} />
      <CategoryChangeSection type='deleted' changes={deletedChanges} />
    </div>
  );
};

export default CategoryChanges;
