import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Text = styled.p`
  display: flex;
  flex-direction: column;
  color: ${colors.uiTextPrimaryLight};
`;

const Heading = styled.h3`
  color: ${colors.uiTextPrimaryLight};
`;

export { Text, Heading };
