import React, { useMemo } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Alias,
  formatDate,
  getDecisionAliases,
  StatusTypes,
} from '@dashboard-experience/utils';
import { STATUS_LABELS } from 'Constants';
import { AdversableItem } from 'modules/adjudication/data';
import { assessmentToAlias } from 'modules/adjudication/utilities';
import { useUser } from 'context/CurrentUser';
import { namespace } from '../../../../../../../../locales';
import { FieldComponentProps } from '../types';

const StyledContainer = styled.div`
  .muted-text {
    color: ${colors.uiGrey600};
    flex-basis: 100%;
  }
`;

const getFromIncident = (adversibleType: string) => {
  const incidentAdversibleTypes = ['Accident', 'Violation'];

  return incidentAdversibleTypes.includes(adversibleType);
};

const getAdverseItemAssessStatus = (
  adverseItem: AdversableItem,
  adverseItemHasAssessDecision: null | string,
  decisionAliases: Alias[],
) => {
  if (adverseItemHasAssessDecision) {
    return assessmentToAlias(
      adverseItem?.assessment?.display?.decision,
      decisionAliases,
    );
  }
  // default state if there is no assess value
  return STATUS_LABELS.needs_review;
};

const fromMvrRule = (item: AdversableItem) => {
  const mvrRuleAdversibleTypes = [
    'Accident',
    'Violation',
    'AgeCriterion',
    'LicenseInStatesCriterion',
    'LocalLicenseCriterion',
    'RestrictionCriterion',
  ];

  return mvrRuleAdversibleTypes.includes(item.adversible_type);
};

const AdversibleType: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
  isAssessEnabled,
  isAssessedWithMvrAssessRules,
}) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const decisionAliases = useMemo(
    // @ts-ignore - ignoring, might need update in utils to make report optional
    () => getDecisionAliases({ currentUser }),
    [currentUser],
  );
  const { adversible_type, charge, date, text } = adverseItem;
  const fromIncident = getFromIncident(adversible_type);
  const adverseItemHasAssessDecision =
    adverseItem?.assessment?.display?.decision;
  const assessStatus = getAdverseItemAssessStatus(
    adverseItem,
    adverseItemHasAssessDecision,
    decisionAliases,
  );

  return (
    <StyledContainer
      data-testid={testid}
      style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
    >
      {fromIncident && (
        <span className='value-width'>
          {formatDate(date, 'MMM Do YYYY')}: {text}
        </span>
      )}
      {!fromIncident && <span className='value-width'>{charge || text}</span>}
      {isAssessEnabled && adverseItemHasAssessDecision && (
        <M.StatusBadge statusType={StatusTypes.Assess} status={assessStatus} />
      )}
      {fromMvrRule(adverseItem) && !isAssessedWithMvrAssessRules && (
        <span className='muted-text'>
          {t(
            `${namespace}:report.actions.pre_adverse.fields.adversible_type.text`,
          )}
        </span>
      )}
    </StyledContainer>
  );
};

export default AdversibleType;
