import * as categories from 'modules/assess/api/category-code-set/categories';
import { Category } from 'modules/assess/models/mvr/category';
import { ID } from 'modules/id';
import { Namespace } from 'modules/assess/api';
import { queryCache, useMutation } from 'react-query';
import { useNamespace } from 'modules/assess/ui/router/context';
import { CategoryCode } from 'modules/assess/models/mvr/category-code';

export const useCreate = (categorySetId?: ID, versionId?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = (params: categories.CreateParams) => {
    return categories.create(categorySetId, versionId, params);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Category) => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instance] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === categorySetId) ||
            (type === 'ccsv' && instance === 'draft')
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useUpdate = (categorySetId?: ID, versionId?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = (updateParams: Omit<Category, 'type'>) => {
    return categories.update(
      categorySetId,
      versionId,
      updateParams.id,
      updateParams.name,
    );
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: CategoryCode) => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instance] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === categorySetId) ||
            (type === 'ccsv' && instance === 'draft') ||
            (ns === 'assess' && entity === namespace && type === categorySetId)
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useDestroy = (
  instanceId?: ID,
  versionId?: ID,
  categoryId?: ID,
) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = () => {
    return categories.destroy(instanceId, versionId, categoryId);
  };

  const [call, result] = useMutation(request, {
    onSuccess: (data: Category) => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instance] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === instanceId) ||
            (type === 'ccsv' && instance === 'draft') ||
            (ns === 'assess' && entity === namespace && type === instanceId)
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};
