import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onSubmit: Function;
  onClose: Function;
  isLoading: boolean;
};

export const VALID_ALTERNATE_REASONS = {
  COMPANY_DEPARTURE: 'Company departure',
  ON_LEAVE: 'On leave',
  WORKERS_COMP: "Worker's comp",
  VACATION: 'Vacation',
  OTHER: 'Other',
};

const AlternateSelectionModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | null>(null);

  const postCloseCallback = useCallback(() => {
    if (selectedReason) setSelectedReason(null);
  }, [selectedReason]);

  const onChange = useCallback((event: React.ChangeEvent) => {
    setSelectedReason((event.target as HTMLInputElement).value);
  }, []);

  const isSubmitAllowed = useMemo(
    () =>
      !selectedReason ||
      (selectedReason === VALID_ALTERNATE_REASONS.OTHER && !notes),

    [notes, selectedReason],
  );

  return (
    <M.ComposedModal onClose={postCloseCallback} open={isOpen}>
      <M.ModalHeader closeModal={onClose}>
        <h2>Swap for alternate</h2>
      </M.ModalHeader>
      <StyledModalBody>
        {isLoading ? (
          <M.LoadingSpinner description='Submitting alternate selection request' />
        ) : (
          <>
            <StyledLabelText>
              Select a reason why the original employee(s) cannot be tested
            </StyledLabelText>
            <StyledRadioButtonGroup
              name='reason-for-alternate'
              valueSelected={selectedReason}
              orientation='vertical'
            >
              {Object.entries(VALID_ALTERNATE_REASONS).map(
                ([key, value]: [string, string]) => (
                  <M.RadioButton
                    key={key}
                    labelText={value}
                    checked={selectedReason === value}
                    value={value}
                    onClick={onChange}
                  />
                ),
              )}
            </StyledRadioButtonGroup>
            {selectedReason === VALID_ALTERNATE_REASONS.OTHER && (
              <StyledInput
                id='other-reason'
                labelText='Notes'
                helperText='If other, please provide explanation'
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event: SyntheticEvent) => {
                  setNotes((event.target as HTMLInputElement).value);
                }}
              />
            )}
          </>
        )}
      </StyledModalBody>
      <M.ModalFooter>
        <M.GridRow>
          <M.Button onClick={onClose} kind='tertiary'>
            Cancel
          </M.Button>
          <M.Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              onSubmit(`${selectedReason}${notes ? `; ${notes}` : ''}`);
              setSelectedReason(null);
              setNotes(null);
              onClose();
            }}
            disabled={isSubmitAllowed}
          >
            Confirm
          </M.Button>
        </M.GridRow>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

const StyledLabelText = styled.p`
  color: ${colors.coolBlack70};
`;

const StyledRadioButtonGroup = styled(M.RadioButtonGroup)``;

const StyledModalBody = styled(M.ModalBody)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const StyledInput = styled(M.Input)`
  width: 80%;
`;

export default AlternateSelectionModal;
