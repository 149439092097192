import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Signature } from 'modules/assess/ui/common';
import { Container, Spacer } from 'modules/assess/ui/common/content';
import CategoryCodeChanges from './category-code-changes';
import CategoryChanges from './category-changes';
import { useGetChanges } from '../version/api';
import { useCCSV } from '../version/context';

export type Props = {
  name: string;
  confirm: (confirmed: boolean) => void;
};

export const Content: React.FC<Props> = ({ name, confirm }) => {
  const { t } = useTranslation('assess');
  const ccsv = useCCSV();
  const query = useGetChanges(ccsv?.category_code_set?.id, 'draft');

  return (
    <Container>
      <Trans
        i18nKey='assess:mvr.category-sets.instance.versions.publish.top'
        t={t}
        values={{ name }}
      />
      <CategoryChanges categories={query?.data?.categories} />
      <Spacer />
      <CategoryCodeChanges categoryCodes={query?.data?.category_codes} />
      <Spacer />
      <Trans
        i18nKey='assess:mvr.category-sets.instance.versions.publish.bottom'
        t={t}
      />
      <Spacer />
      <Signature confirm={confirm} />
    </Container>
  );
};

export default Content;
