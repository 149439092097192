import React from 'react';
import styled from 'styled-components';
import { CompletedStepIcon } from 'components/AddScreenings/shared/ModalComponents';
import { M, colors } from '@dashboard-experience/mastodon';
import AquaFilledCheckmark from '../../../shared/svg/AquaFilledCheckmark.svg';

const Container = styled.div`
  width: 240px;
  margin: auto;
  .cds--text-input .cds--list-box__field {
    border-bottom: 1px solid ${colors.uiGrey300} !important;
  }
  text-align: center;
`;

const Image = styled.img`
  margin-top: 40px;
  margin-bottom: 16px;
`;

const ConfirmationHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
`;

const DividerLine = styled.div`
  border-bottom: 1px solid ${colors.brandSlate2};
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 16px;
`;

const InfoBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 66px;
`;

const InfoBox = styled.div`
  padding: 16px 16px 24px 16px;
  width: 181px;
  min-height: 136px;
  background: ${colors.brandSlate1};
  border-radius: 8px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandNavy4};
`;

type CompletedSetupProps = {
  description: {
    email: string;
    calendar: string;
    shoppingCatalog: string;
  };
};

const CompletedSetup: React.FC<CompletedSetupProps> = ({ description }) => {
  const { email, calendar, shoppingCatalog } = description;
  return (
    <>
      <Container>
        <Image src={AquaFilledCheckmark} alt='' />
        <ConfirmationHeader>We received your request</ConfirmationHeader>
      </Container>
      <DividerLine />
      <Header>What&apos;s next?</Header>
      <InfoBoxContainer>
        <InfoBox>
          <CompletedStepIcon>
            <M.Icon icon='Email' size='20' />
          </CompletedStepIcon>
          <Description>{email}</Description>
        </InfoBox>
        <InfoBox>
          <CompletedStepIcon>
            <M.Icon icon='Calendar' size='20' />
          </CompletedStepIcon>
          <Description>{calendar}</Description>
        </InfoBox>
        <InfoBox>
          <CompletedStepIcon>
            <M.Icon icon='ShoppingCatalog' size='20' />
          </CompletedStepIcon>
          <Description>{shoppingCatalog}</Description>
        </InfoBox>
      </InfoBoxContainer>
    </>
  );
};

export default CompletedSetup;
