import * as Entity from 'modules/assess/models/rules/lookback';

type Base = Omit<Entity.Type, 'key' | 'level'>;

export type RequestData = Base;

export type ResponseData = Base & {
  [key: string]: string | undefined;
};

// There are two types of lookback rules: Subcategory and Subsubcategory rules.
// We use a 'key' property for rendering purposes on the lookback rules table, we need to set the value according to the rule level/type.
export function fromAPI(data: ResponseData): Entity.Type {
  const level = data.charge_subsubcategory
    ? Entity.Level.CATEGORY_2
    : Entity.Level.CATEGORY_1;

  const key = getLevelKey(level);
  const result = {
    ...data,
    key: data[key] || '',
    level,
  };

  return result;
}

// Set key value to the right property (charge_subcategory/charge_subsubcategory)
export function toAPI(data: Entity.Type): Base {
  const categorykey = getLevelKey(data.level);
  const payload = {
    [categorykey]: data.key,
  };

  const result: Base = {
    ...payload,
    ...data,
  };

  return result;
}

function getLevelKey(level: Entity.Level): string {
  return level === Entity.Level.CATEGORY_1
    ? 'charge_subcategory'
    : 'charge_subsubcategory';
}
