/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { Namespace } from 'modules/assess/api/constants';
import React from 'react';
import * as Assignables from 'modules/assess/models/assignables';
import { useSelector } from 'react-redux';
import { getCurrentUserAccount } from 'reducers/CurrentUser.reducer';
import { useNamespace } from '../router/context';
import { useSearch } from '../ruleset/assign/hooks';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const account = useSelector(getCurrentUserAccount);
  const namespace = useNamespace() || Namespace.criminal;
  const mvrNamespace = namespace === 'mvr';
  const search = useSearch({ type: Assignables.Kind.ACCOUNT });
  const { data = [] } = search.data || {};
  const defaultRulesetExists = data.find(assignable => assignable.ruleset);
  const assessNotEnabled = mvrNamespace
    ? account?.assess_enabled && !account?.assess_mvr_enabled
    : !account?.assess_enabled;

  return defaultRulesetExists && assessNotEnabled ? <>{children}</> : null;
};

export default Guard;
