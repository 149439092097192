import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const StyledProgressBarWrapper = styled.div`
  width: 100%;
  gap: 16px;
`;

export const StyledProgressBar = styled.div.attrs({
  className: 'progress-bar',
})`
  height: 4px;
  width: 100%;
  display: flex;
`;

type AquaSectionProps = {
  activeStepNumber: number;
  totalSteps: number;
};

export const AquaSection = styled.div.attrs({
  className: 'aqua-section',
})<AquaSectionProps>`
  width: ${props => (props.activeStepNumber / props.totalSteps) * 100}%;
  background-color: ${colors.uiAqua500};
`;

export const GreySection = styled.div.attrs({
  className: 'grey-section',
})`
  flex-grow: 1;
  background-color: ${colors.uiGrey200};
`;

export const StyledStepNames = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const StyledStepName = styled.span<{
  isActiveStep: boolean;
  totalSteps: number;
}>`
  font-size: 12px;
  width: ${props => (1 / props.totalSteps) * 100}%;
  color: ${props =>
    props.isActiveStep
      ? colors.uiTextTertiaryLight
      : colors.uiTextDisabledLight};

  &:not(:first-child) {
    padding-left: 4px;
  }
`;
