import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container, ContextNodes } from '../Actions';

export const StyledContainer = styled(Container)`
  margin-right: 0.5rem !important;
`;

export const Bar: React.FC = () => {
  const nodes = useContext(ContextNodes);
  return <StyledContainer justify='flex-end'>{nodes}</StyledContainer>;
};

export default Bar;
