import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import { M, colors } from '@dashboard-experience/mastodon';
import { FlexRowAlignRight } from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
`;

const Title = styled.span`
  color: ${colors.brandNavy4};
  font-size: 1.125rem !important;
  font-weight: 700 !important;
`;

const ContentPanel = styled.div`
  background-color: ${colors.bgTertiaryLight};
  border-radius: 0.25rem;
  max-width: 46.5rem;
  height: 17.7rem;
  overflow-x: auto;
  padding: 1rem;
`;

interface AccountTOSProps {
  onContinueClick: () => void;
  tosContent: string;
}

const AccountTermsOfService = ({
  onContinueClick,
  tosContent,
}: AccountTOSProps) => {
  const [checked, toggleCheck] = useState(false);

  const tosText = 'Checkr Terms and Conditions for Connection to Tracker I-9';

  const handleCheck = useCallback(
    (event, { checked }) => toggleCheck(checked),
    [],
  );

  const htmlSanitized = DOMPurify.sanitize(tosContent, {
    ALLOWED_ATTR: ['style', 'class', 'href', 'target'],
  });

  const trackEvent = useTrackEvent();

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ACCOUNT_SETUP_TOS_VIEWED, {
      'Page Type': 'Account Setup',
      'I9 Page Type': 'Account Setup terms of service',
    });
  }, [trackEvent]);

  return (
    <>
      <Section>
        <Title>Terms of Service</Title>
        <ContentPanel>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: htmlSanitized }} />
        </ContentPanel>
        <M.Checkbox
          id='tos'
          name='tos'
          onChange={handleCheck}
          labelText={
            <>
              I acknowledge I have read, understand, and agree to the{' '}
              <u>{tosText}</u>
            </>
          }
          checked={checked}
        />
      </Section>
      <FlexRowAlignRight gap='0' alignItems='center'>
        <M.Button onClick={onContinueClick} disabled={!checked}>
          Continue
        </M.Button>
      </FlexRowAlignRight>
    </>
  );
};

export default AccountTermsOfService;
