import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Header from './header';
import Content from './content';
import { useModalState } from '../context';

const Modal = () => {
  const open = useModalState('open');
  const onClose = useModalState('onClose');

  return (
    <M.ComposedModal
      open={open}
      onClose={onClose}
      data-testid='exam-reorder-modal'
    >
      <Header />
      <Content />
    </M.ComposedModal>
  );
};

export default Modal;
