import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { useParams } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import { useFetchUserDetails, useFetchUserVersions } from '../api/users';
import UserVersionsTable from '../components/Account/Users/UserVersionsTable';

const UserContainer = () => {
  const currentUser = useUser();
  const accountId = currentUser.account.id;
  const { userId }: GenericObject = useParams();

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const {
    isLoading: userVersionsLoading,
    isError: userVersionsError,
    versions,
  } = useFetchUserVersions(accountId, userId, currentPageIndex, 20);
  const {
    isLoading: userDetailsLoading,
    isError: userDetailsError,
    user,
  } = useFetchUserDetails(accountId, userId);

  const handlePageClick = useCallback(
    pageIndex => {
      setCurrentPageIndex(pageIndex);
    },
    [setCurrentPageIndex],
  );

  return (
    <div>
      {!userDetailsLoading && userDetailsError && (
        <div>There was a problem fetching user data</div>
      )}
      {!userDetailsLoading && !userDetailsError && (
        <M.Form>
          <h1>User Overview</h1>
          <M.TextInput
            id='email'
            labelText='Email'
            value={user?.email}
            readOnly
          />
          <M.TextInput
            id='full_name'
            labelText='Full Name'
            value={user?.full_name}
            readOnly
          />
          <M.TextInput
            id='created_at'
            labelText='Created'
            value={user?.created_at}
            readOnly
          />
          <M.TextInput
            id='last_seen'
            labelText='Last Seen'
            value={user?.last_seen}
            readOnly
          />
          <M.TextInput
            id='roles'
            labelText='Roles'
            value={user?.roles
              ?.map((role: { name: string }) => role.name)
              .toString()}
            readOnly
          />
        </M.Form>
      )}
      {!userVersionsLoading && userVersionsError && (
        <div data-testid='versionsError'>
          There was a problem fetching the user history data
        </div>
      )}
      <div>
        {userVersionsLoading && <div>Loading...</div>}
        {!userVersionsLoading && !userVersionsError && (
          <div>
            <h1 style={{ paddingTop: '15px' }}>History</h1>
            <UserVersionsTable
              versions={versions.versions}
              totalPages={versions.total_pages}
              currentPage={versions.current_page}
              handlePageClick={handlePageClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserContainer;
