import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { HealthcareSanctionsRecord } from 'types';
import { formatDateIfDate } from '@dashboard-experience/utils';

/**
 * Renders a CMSPreclusionListRecord component.
 * @param record The CMSPreclusionListRecord to render.
 * @returns The rendered CMSPreclusionListRecord component.
 */
const CMSPreclusionListRecord: React.FC<{
  record: HealthcareSanctionsRecord;
}> = ({ record }) => {
  const {
    authority,
    address,
    full_name,
    dob,
    database_registry,
    sanction,
    exclusion_date,
    claim_reject_date,
  } = record;

  const keyValueItems = [
    { itemKey: 'Authority', itemValue: authority },
    { itemKey: 'State', itemValue: address?.state },
    { itemKey: 'Full Name', itemValue: full_name },
    { itemKey: 'Date Of Birth', itemValue: formatDateIfDate(dob) },
    { itemKey: 'Database Registry', itemValue: database_registry },
    { itemKey: 'Sanction', itemValue: sanction },
    { itemKey: 'Exclusion Date', itemValue: formatDateIfDate(exclusion_date) },
    {
      itemKey: 'Claim Reject Date',
      itemValue: formatDateIfDate(claim_reject_date),
    },
  ];

  return (
    <>
      <M.KeyValueList items={keyValueItems.slice(0, 4)} />
      <div style={{ height: '0.5rem' }} />
      <M.KeyValueList items={keyValueItems.slice(4)} />
    </>
  );
};

/**
 * Checks if a CMSPreclusionListRecord is invalid.
 * @param sourceType The source type of the record.
 * @param record The CMSPreclusionListRecord to check.
 * @returns True if the record is invalid, false otherwise.
 */
export const isInvalidCMSPreclusionListRecord = (
  sourceType: string,
  record: HealthcareSanctionsRecord,
) => {
  return sourceType === 'cms_preclusion_list' && record.reinstatement_date;
};

export default CMSPreclusionListRecord;
