import { ReportProps } from 'types';
import { M, colors } from '@dashboard-experience/mastodon';
import React from 'react';
import { SCREENING_TITLES } from 'utils';
import {
  dateWithTimezone,
  getResultOrStatus,
} from '@dashboard-experience/utils';
import SearchPDF from './SearchPDF';
import formatCountry from './utils';

const COMPONENTS = {
  VERIFIED_RTW: (timestamp: string) => {
    return <h3>Right to work was completed digitally on {timestamp}</h3>;
  },
  OPT_OUT: (timestamp: string) => {
    return (
      <>
        <h3
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <M.Icon
            icon='WarningFilled'
            size={16}
            style={{ fill: colors.uiPink600, marginRight: '0.5rem' }}
          />
          <div className='title'>
            Candidate opted out of Digital Right to Work Verification on{' '}
            {timestamp}
          </div>
        </h3>
        <p>{COMPONENTS.MANUAL_REVIEW()}</p>
      </>
    );
  },
  MANUAL_REVIEW: () => {
    return (
      <>
        <h3 data-testid='rtw-manual-review-text-title'>
          Candidate must provide physical documents to the employer to verify
          right to work
        </h3>
        <p data-testid='rtw-manual-review-text-description'>
          Refer to{' '}
          <a
            href='https://www.gov.uk/government/publications/right-to-work-checks-employers-guide'
            target='_blank'
            rel='noopener noreferrer'
            data-testid='rtw-checks-employers-guide-link'
          >
            Right to work checks: an employer&apos;s guide
          </a>{' '}
          for more information.
        </p>
      </>
    );
  },
  SHARE_CODE: () => {
    return (
      <>
        <h3>
          Candidate is a foreign national and needs to provide a share code
        </h3>
        <p>Contact the candidate to get their share code.</p>
      </>
    );
  },
  SHARE_CODE_PROVIDED: (share_code: string) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          Candidate provided a share code:
          <strong style={{ marginLeft: '3px' }}>{share_code}</strong>
          <M.Button
            onClick={copyToClipboard}
            kind='tertiary'
            data-testid='share-code-copy-btn'
            style={{ padding: '0.5rem' }}
          >
            <M.Icon icon='Copy' />
          </M.Button>
        </div>
        <div>
          <a
            href='https://www.gov.uk/view-right-to-work'
            target='_blank'
            rel='noreferrer'
            data-testid='verify-share-code-link'
          >
            Verify share code
          </a>
        </div>
      </>
    );

    function copyToClipboard() {
      return navigator.clipboard.writeText(share_code);
    }
  },
  FAILED_RTW: (timestamp: string) => {
    return (
      <>
        <h3
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <M.Icon
            icon='WarningFilled'
            size={16}
            style={{ fill: colors.uiPink600, marginRight: '0.5rem' }}
          />
          <div className='title'>
            Digital Right to Work Verification not successful on {timestamp}
          </div>
        </h3>
        <p>{COMPONENTS.MANUAL_REVIEW()}</p>
      </>
    );
  },
  DEFAULT: () => {},
};

const GetSubstatusContent = ({
  completed_at,
  result_substatus,
  share_code,
}: any = {}) => {
  const timestamp = dateWithTimezone(completed_at);

  switch (result_substatus) {
    case 'INVALID_SHARE_CODE': {
      return COMPONENTS.SHARE_CODE();
    }
    case 'SHARE_CODE_NOT_PROVIDED': {
      return COMPONENTS.MANUAL_REVIEW();
    }
    case 'SHARE_CODE_PROVIDED': {
      return COMPONENTS.SHARE_CODE_PROVIDED(share_code);
    }
    case 'VENDOR_FAILURE': {
      return COMPONENTS.FAILED_RTW(timestamp);
    }
    case 'IDENTITY_MISMATCH': {
      return COMPONENTS.FAILED_RTW(timestamp);
    }
    case 'INVALID_DOCUMENTS': {
      return COMPONENTS.MANUAL_REVIEW();
    }
    case 'OPT_OUT': {
      return COMPONENTS.OPT_OUT(timestamp);
    }
    case 'EXPIRED': {
      return COMPONENTS.MANUAL_REVIEW();
    }
    default: {
      return COMPONENTS.DEFAULT();
    }
  }
};

const InternationalRightToWork: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_right_to_work: search } = report;

  const country = formatCountry(search?.country);

  const title = country ? `Right to Work - ${country}` : 'Right to Work';

  return search ? (
    <M.Screening
      id={SCREENING_TITLES.international_right_to_work.id}
      data-testid='international-right-to-work'
      title={title}
      statusType={reportStatusType}
      screening={search}
      status={getResultOrStatus(search)}
    >
      {search.status === 'pending'}

      {(search.status === 'suspended' || search.status === 'canceled') && (
        <>{COMPONENTS.MANUAL_REVIEW()}</>
      )}

      {search.result === 'clear' && (
        <>{COMPONENTS.VERIFIED_RTW(dateWithTimezone(search.completed_at))}</>
      )}

      {search.result === 'consider' && <>{GetSubstatusContent(search)}</>}

      {search.pdf_url && <SearchPDF url={search.pdf_url} text='View report' />}
    </M.Screening>
  ) : null;
};

export default InternationalRightToWork;
