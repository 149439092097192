import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from 'utils/AuthRoute';
import { RedirectToReport } from 'containers/Candidate';
import {
  InvitationContainer,
  ReportContainer,
  ReportBannerContainer,
} from 'containers/Report';
import Information from './information';

type Props = {
  candidateId: string;
};

const RoutesContent = ({ candidateId }: Props) => {
  return (
    <Switch>
      <AuthRoute
        exact
        path='/candidates/:candidateId/invitations/:invitationId'
        component={InvitationContainer}
        redirectTo={`/candidates/${candidateId}`}
        authorized
      />
      <Route path='/candidates/:candidateId'>
        <Route
          exact
          path='/candidates/:candidateId/reports/:reportId'
          component={ReportBannerContainer}
        />
        {/* Always render the candidate information component, regardless of route: */}
        <Information />
        <Route
          exact
          path='/candidates/:candidateId/reports/:reportId'
          component={ReportContainer}
        />
        <Route
          exact
          path='/candidates/:candidateId'
          component={RedirectToReport}
        />
      </Route>
    </Switch>
  );
};

export default RoutesContent;
