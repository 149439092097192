import React, { ChangeEvent, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

export type Props = {
  onChange: (value: string) => void;
  type: string;
  value?: string;
};

export const Content: React.FC<Props> = ({ onChange, type, value }) => {
  const { t } = useTranslation();
  const typeTrans = t(`translation:nouns.${type}`);
  const label = t('assess:mvr.name-dialog.label', {
    type: typeTrans.toLowerCase(),
  });
  const placeholder = t('assess:mvr.name-dialog.placeholder', {
    type: typeTrans,
  });
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value || ''),
    [onChange],
  );

  return (
    <M.Input
      data-testid='assess-mvr-name-dialog-input'
      defaultValue={value}
      labelText={label}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default Content;
