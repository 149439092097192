import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

const StyledLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  color: ${props =>
    props.className === 'disabled' ? colors.uiNavy200 : colors.brandNavy1};
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`;

const Button: React.FC<Props> = ({ onClick, disabled = false }) => {
  const { t } = useTranslation('assess:v2');
  const label = t('home.settings.packages.title');

  return (
    <StyledLink
      data-testid='assess-v2-settings-packages-link'
      onClick={onClick}
      className={disabled ? 'disabled' : ''}
    >
      {label}
    </StyledLink>
  );
};

export default Button;
