import * as React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { Results } from 'modules/assess/models/insights';
import Graph from './ResultsGraph';

type Props = {
  active?: Results.Type;
  draft?: Results.Type;
};

export const Graphs: React.FC<Props> = ({ active, draft }) => {
  const max = getMaxValue([active, draft]);

  return (
    <Container>
      <Element>
        <M.StatusBadge status='eligible' />
        <Graph
          draft={draft?.eligible}
          draftColor={colors.uiGreen400}
          live={active?.eligible}
          liveColor={colors.uiGreen50}
          max={max}
        />
      </Element>
      <Element>
        <M.StatusBadge status='review' />
        <Graph
          draft={draft?.review}
          draftColor={colors.uiYellow400}
          live={active?.review}
          liveColor={colors.uiYellow100}
          max={max}
        />
      </Element>
      <Element>
        <M.StatusBadge status='escalated' />
        <Graph
          draft={draft?.escalated}
          draftColor={colors.uiPink600}
          live={active?.escalated}
          liveColor={colors.uiPink200}
          max={max}
        />
      </Element>
    </Container>
  );
};

function getMaxValue(types: Array<Results.Type | undefined>) {
  const maxValues = types?.map(getResultMaxValue);
  return Math.max(...maxValues);
}

function getResultMaxValue(type: Results.Type | undefined): number {
  if (!type) {
    return 0;
  }

  return Math.max(type.eligible, type.escalated, type.review);
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Element = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:first-child {
    width: 80px;
  }
  > *:nth-child(2) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default Graphs;
