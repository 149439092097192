import { isInternal } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  ALLOW_INTERNAL,
  AUTOMATIC_REDIRECT,
  DEVELOPMENT,
  IS_IN_IFRAME,
} from 'Constants';
import { useUser } from 'context/CurrentUser';
import { shouldUseImpersonationData } from './ImpersonationUtils';

/* for testing */
export const shouldInternalRedirect = ({
  isInternalUser,
  isInIframe,
  isImpersonating,
  allowInternal,
  development,
  webdriver,
  enableAutomaticRedirect,
  route,
}: {
  isInternalUser: boolean;
  isInIframe: boolean;
  isImpersonating: boolean;
  allowInternal: boolean;
  development: boolean;
  webdriver: boolean;
  enableAutomaticRedirect: boolean;
  route: string;
}) => {
  // do not redirect an iframe
  if (isInIframe) {
    return false;
  }

  // Admin routes have some nuanced handling
  if (route.startsWith('/admin')) {
    // There is ONE specific admin route that's allowed in Customer, one for SSO settings
    if (route.endsWith('/single-sign-on')) {
      return false;
    }

    // For any other admin routes, redirect if currentUser is internal OR if currently impersonating
    return isInternalUser || isImpersonating;
  }

  // // don't redirect for automation tests
  if (webdriver) {
    return false;
  }

  // only internal users should redirect ever
  if (!isInternalUser) {
    return false;
  }

  // bypass for the param on non-admin routes
  if (allowInternal) {
    return false;
  }

  // disable for local dev
  if (development) {
    return false;
  }

  // Flagr to allow auto-redirect
  if (!enableAutomaticRedirect) {
    return false;
  }

  // Catchall - always redirect back if not explicitly told otherwise.
  return true;
};

export const useShouldInternalRedirect = () => {
  const user = useUser();
  const enableAutomaticRedirect =
    useFlag(AUTOMATIC_REDIRECT)?.variantKey === 'on';

  return shouldInternalRedirect({
    isInternalUser: isInternal(user),
    isImpersonating: shouldUseImpersonationData(),
    isInIframe: IS_IN_IFRAME,
    allowInternal: ALLOW_INTERNAL,
    development: DEVELOPMENT, // disable for local dev
    webdriver: navigator.webdriver, // don't redirect for automation tests
    enableAutomaticRedirect,
    route: window.location.pathname,
  });
};

export default useShouldInternalRedirect;
