import React from 'react';
import styled from 'styled-components';
import { EmploymentRecord, Manager as ManagerType } from 'types';
import { KeyValueSection } from '../Sections';
import { duration, dateRange } from './utils';

type Props = { record: EmploymentRecord };
type ManagerProps = { manager?: ManagerType };

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const hasManagerInfo = (manager?: ManagerType) =>
  Boolean(manager?.email || manager?.name || manager?.phone || manager?.title);

const managerNameAndTitle = (manager?: ManagerType) =>
  [manager?.name, manager?.title].filter(n => n).join(', ');

const Manager: React.FC<ManagerProps> = ({ manager }) => (
  <Column>
    {hasManagerInfo(manager) ? (
      <>
        <span>{managerNameAndTitle(manager)}</span>
        {manager?.email && <span>{manager.email}</span>}
        {manager?.phone && <span>{manager.phone}</span>}
      </>
    ) : (
      <span>No contact info provided.</span>
    )}
  </Column>
);

const Position: React.FC<Props> = ({ record }) => {
  const { employer } = record;
  const { manager } = employer;
  const items = [
    {
      itemKey: 'Position',
      itemValue: (
        <Column>
          <span>{employer.position}</span>
          <span>
            {dateRange(employer.start_date, employer.end_date)} (
            {duration(employer.start_date, employer.end_date)})
          </span>
        </Column>
      ),
    },
    {
      itemKey: 'Manager',
      itemValue: <Manager manager={manager} />,
    },
  ];
  return <KeyValueSection items={items} />;
};

export default Position;
