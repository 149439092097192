import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/rulesets/version';
import { Amplitude } from 'modules/assess/v2/models';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { StorePage } from 'modules/assess/v2/ui/common';
import { Actions } from '../../common';
import AddedGuidelines from './added-guidelines';
import UpgradeToPremium from './upgrade-to-premium';
import Table from './table';

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  color: ${colors.brandNavy4};
`;

const Instruction = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 1.5rem;
  color: ${colors.brandNavy4};
`;

export type Props = {
  data?: Entity.Type;
  status?: string;
};

export const Container: React.FC<Props> = ({ data, status }) => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('standard.guidelines.quickstart', {
    returnObjects: true,
  });
  const basePath = 'assess-v2-ui-guideline-quickstart-container';
  const titleId = `${basePath}-title`;
  const instructionsId = `${basePath}-instructions`;
  const addedTitleId = `${basePath}-added-guidelines-title`;
  const addedInstructionsId = `${basePath}-added-guidelines-instructions`;

  return (
    <StorePage
      data={data}
      status={status}
      event={Amplitude.Guidelines.Quickstart.Pageview}
      id='assess-v2-ruleset-version-guidelines-templated-container'
    >
      <Title data-testid={titleId}>{translations.title}</Title>
      <Instruction data-testid={instructionsId}>
        <Trans>{translations.instructions}</Trans>
      </Instruction>
      <Table />
      <Title data-testid={addedTitleId}>{translations.added.title}</Title>
      <Instruction data-testid={addedInstructionsId}>
        <Trans>{translations.added.instructions}</Trans>
      </Instruction>
      <AddedGuidelines />
      <Actions option={Navigation.Quickstart} />
      <UpgradeToPremium />
    </StorePage>
  );
};

export default Container;
