/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { getCurrentUserAccount } from 'reducers/CurrentUser.reducer';
import Button from 'components/Button';

export type Props = {
  assess_enabled: boolean;
  onBack?: (event: SyntheticEvent) => void;
  onClose?: (event?: SyntheticEvent) => void;
  onNext?: (event: SyntheticEvent) => void;
  stepIndex: number;
  totalSteps: number;
};

const Actions: React.FC<Props> = ({
  assess_enabled,
  onBack,
  onClose,
  onNext,
  stepIndex,
  totalSteps,
}) => {
  const nextLabel = stepIndex === totalSteps - 1 ? 'Finish' : 'Next';
  const backAction = stepIndex === 0 ? onClose : onBack;
  const nextAction = stepIndex === totalSteps - 1 ? onClose : onNext;
  const disabled = stepIndex === totalSteps - 1 && !assess_enabled;

  return (
    <>
      <Button kind='secondary' onClick={backAction} label='Back' />
      <Button onClick={nextAction} label={nextLabel} disabled={disabled} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  assess_enabled: getCurrentUserAccount(state)?.assess_enabled,
});

export default connect(mapStateToProps)(Actions);
