import React, { useCallback } from 'react';
import { ItemVisibility } from 'modules/assess';
import * as Entity from 'modules/assess/models/rules/premade';
import { Namespace } from 'modules/assess/api';
import { Category as CrimCategory } from 'modules/assess/models/criminal/rules/rules';
import { Category as MVRCategory } from 'modules/assess/models/mvr/rules/rules';
import { getRulesByNamespace } from 'modules/assess/models/rules';
import Section, { Row } from './Section';

type Props = {
  categorize?: boolean;
  disabled?: boolean;
  namespace: Namespace;
  rules: Entity.List | Entity.Map;
  onRuleClicked: (rule: Entity.Type, isSelected: boolean) => void;
  onRuleUpdated: (rule: Entity.Type, itemVisibility?: ItemVisibility) => void;
};

export const Container: React.FC<Props> = ({
  categorize = true,
  disabled,
  namespace,
  rules,
  onRuleClicked,
  onRuleUpdated,
}) => {
  const Rules: any = getRulesByNamespace(namespace);

  const rows: Array<Row> = Rules.build(rules);

  rows.forEach(r => {
    if (!r.disabled) {
      r.disabled = !!disabled;
    }
  });

  const onSelect = useCallback(
    (id: string, selected: boolean) => {
      const rule = rows.find(r => r.id === id)?.rule as Entity.Type;
      onRuleClicked(rule, selected);
    },
    [rows, onRuleClicked],
  );

  const onUpdate = useCallback(
    (id: string, itemVisibility?: ItemVisibility) => {
      const rule = rows.find(r => r.id === id)?.rule as Entity.Type;
      onRuleUpdated(rule, itemVisibility);
    },
    [rows, onRuleUpdated],
  );

  const sections = categorize ? (
    Rules.CATEGORIES.map((category: CrimCategory | MVRCategory) => (
      <Section
        category={category}
        key={category}
        namespace={namespace}
        rows={rows}
        rules={Rules}
        onSelect={onSelect}
        onUpdate={onUpdate}
      />
    ))
  ) : (
    <Section
      namespace={namespace}
      rows={rows}
      rules={Rules}
      onSelect={onSelect}
      onUpdate={onUpdate}
    />
  );

  return <div data-testid='assess-rules-standard-table'>{sections}</div>;
};

export default Container;
