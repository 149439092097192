/* eslint-disable react/jsx-no-bind */
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';

const ParentCustomIdSelect = styled.select`
  @media (min-device-width: 1085px) {
    width: 150px;
  }
`;

const ParentCustomIdCell = ({
  rows,
  tiers,
  cell,
  rowIndex,
  handleCellValueChange,
  setSortInfo,
  sortedRowsId,
}: any) => {
  const idOccurrences: Map<string, number> = new Map();
  rows.forEach((obj: { id: string }) => {
    idOccurrences.set(obj.id, (idOccurrences.get(obj.id) || 0) + 1);
  });
  const duplicateIds = new Set();
  idOccurrences.forEach((value, key) => {
    if (value > 1) {
      duplicateIds.add(key);
    }
  });

  const nameOfCell = rows[rowIndex].name;
  return (
    <M.TableCell key={cell.id}>
      <ParentCustomIdSelect
        id={`parentCustomIdSelection-${sortedRowsId}-${nameOfCell}`}
        className='form-control'
        name='parent-custom-id'
        value={cell.value}
        defaultValue={undefined}
        data-testid='account-hierarchy-parent-custom-id-input'
        onChange={(e: any) => {
          // removes the visual indicator of sortedness
          setSortInfo({
            columnId: cell.id,
            sortCycle: 'parent-custom-id-states-from-ascending',
            oldDirection: 'DESC',
          });

          handleCellValueChange(
            cell.id,
            e.target.value,
            sortedRowsId,
            rowIndex,
            cell.value,
          );
        }}
        required
      >
        <option value='' label='None' />
        {tiers.map((value: any) => (
          <optgroup key={value} label={value ? `Tier: ${value}` : 'Tier: None'}>
            {rows.map(
              (
                item: {
                  id: string;
                  parent_custom_id: any;
                  tier: any;
                  name: any;
                },
                index: any,
              ) => {
                if (
                  (item.tier === value ||
                    (value === 'None' &&
                      (item.tier == null || item.tier === ''))) &&
                  item.id &&
                  item.name
                ) {
                  return (
                    <option
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item.id}-${index}`}
                      value={item.id}
                      disabled={
                        item.id === rows[rowIndex].id ||
                        duplicateIds.has(item.id)
                      }
                    >
                      {`${item.id} - ${item.name}`}
                    </option>
                  );
                }
                return null;
              },
            )}
          </optgroup>
        ))}
      </ParentCustomIdSelect>
    </M.TableCell>
  );
};
export default ParentCustomIdCell;
