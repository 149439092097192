import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Trans, useTranslation } from 'react-i18next';
import { CHECKR_CUSTOMER_SUPPORT_URL } from 'Constants';
import {
  GridCol,
  StyledWarningInlineNotification,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';

type Props = {
  amount?: number;
  action?: string;
  handleButtonClick: Function;
  disabled?: boolean;
};

const ReauthorizeBanner: React.FC<Props> = ({
  amount,
  action,
  handleButtonClick,
  disabled,
}) => {
  const { t } = useTranslation();

  if (amount === undefined || !action) {
    return null;
  }

  const help_center_link_component = (
    <a
      href={CHECKR_CUSTOMER_SUPPORT_URL}
      data-testid='reauthorize_help_center_link_component_test'
      target='_blank'
      rel='noreferrer'
    >
      Contact Checkr
    </a>
  );

  const formattedAmount = (amount / 100.0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const translationKey = () => {
    return amount === 0 || amount === null
      ? `banners.reauthorizeAccount.zero_amount`
      : `banners.reauthorizeAccount.${action}`;
  };

  const message = (
    <Trans
      t={t}
      i18nKey={translationKey()}
      values={{ amount: formattedAmount }}
    >
      {help_center_link_component}
    </Trans>
  );

  const label = <Trans t={t} i18nKey='banners.reauthorizeAccount.button' />;

  return (
    <M.Grid>
      <M.GridRow>
        <CustomCol col={5} noPadding style={{ marginTop: '3px' }}>
          <GridCol>
            <StyledWarningInlineNotification
              data-testid='reauthorize-banner'
              kind='warning'
              subtitle={message}
              inline='classic'
              actionButtonLabel={label}
              onActionButtonClick={handleButtonClick}
              hideActionButton={disabled}
              hideCloseButton
            />
          </GridCol>
        </CustomCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default ReauthorizeBanner;
