import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';

const ConfirmModal = ({
  cancelMessage,
  confirmMessage,
  callback,
  hideModal,
  header,
  message,
  open,
  ...props
}) => {
  const messageContent =
    typeof message === 'string' ? <p>{message}</p> : message;
  return (
    <M.ComposedModal onClose={hideModal} open={open} {...props}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>{header}</h2>
      </M.ModalHeader>
      <M.ModalBody>{messageContent}</M.ModalBody>
      <M.ModalFooter data-testid='confirm-modal-footer'>
        <M.BinaryButtons
          btnLeft={{ name: cancelMessage, onClick: hideModal }}
          btnRight={{
            name: confirmMessage,
            onClick: callback,
          }}
          size='sm'
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

ConfirmModal.propTypes = {
  hideModal: PropTypes.func,
  open: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  header: PropTypes.string,
  message: PropTypes.any,
  cancelMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
};

ConfirmModal.defaultProps = {
  hideModal: () => {},
  callback: () => {},
  header: '',
  message: '',
  cancelMessage: 'Cancel',
  confirmMessage: 'Yes',
};

export default ConfirmModal;
