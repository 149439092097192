import React, {
  useState,
  useCallback,
  MouseEvent,
  SyntheticEvent,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';

import Guard from './guard';
import EngageModal from '../flow/modal/modal';

const EngageButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const text = t(`${namespace}:report.actions.engaged.action`);
  const tooltipText = t(`${namespace}:report.actions.engaged.tooltip`);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement> | SyntheticEvent<Element>) => {
      e?.stopPropagation();
      setOpen(true);
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        { Action: 'Engage' },
      );
      // TODO: remove once customer is standalone app
      scrollToTop();
    },
    [setOpen],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Guard>
      <M.Container.Col>
        <M.TooltipButton
          data-testid='engage-button'
          kind='achievement'
          label={tooltipText}
          className={report.archived ? 'disabled' : ''}
          onClick={handleOpen}
          fullWidth
          disabled={report.archived}
        >
          {text}
        </M.TooltipButton>
      </M.Container.Col>
      {open && <EngageModal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default EngageButton;
