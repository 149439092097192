import React from 'react';
import _ from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import {
  CurrentUser,
  GenericObject,
  hasPermission,
  Report,
} from '@dashboard-experience/utils';
import CellLink from 'components/Search/CellLink';
import { Candidate } from 'types';
import Cell from './Cell';

import { MultipleReportsRow } from './SearchTableStyledComponents';

/**
 * @name getReportUri
 * @function
 * @memberOf ExpandedReportsRow
 * @description Helper to set the correct report uri used to navigate to report for candidate
 * @returns {array} - New array with only past reports
 * @param {object} candidate - The candidate object
 * @param {object} report - The report object
 * @param {object} currentUser - The current user object
 */
const getReportUri = (
  candidate: Candidate,
  report: Report,
  currentUser: CurrentUser,
) => {
  if (hasPermission(currentUser, 'read_candidate_details')) {
    return `/candidates/${candidate.id}/reports/${report.id}`;
  }
  return '';
};

type ExpandedReportsRowProps = {
  candidate: Candidate;
  currentUser: CurrentUser;
  geos: GenericObject[];
  columns: any;
  reports: Report[];
  contextId?: null | undefined | string;
};

const ExpandedReportsRow = ({
  candidate,
  currentUser,
  geos,
  columns,
  reports,
  contextId,
}: ExpandedReportsRowProps): JSX.Element => {
  const sortedReports = _.reverse(_.orderBy(reports, ['created_at'], ['asc']));

  const canReadCandidateDetails = hasPermission(
    currentUser,
    'read_candidate_details',
  );

  return (
    <>
      {sortedReports.map(report => {
        const reportURI = getReportUri(candidate, report, currentUser);
        return (
          <MultipleReportsRow key={report.id} className='newSearchTable'>
            <M.TableCell />
            <M.TableCell />
            {columns.map(
              (column: any) =>
                column.hasPermission({ currentUser, geos }) && (
                  <M.TableCell
                    key={`${column.key}-${Math.random()}`}
                    className={`${column.key}`}
                    style={column.cellStyle || {}}
                  >
                    <CellLink
                      className={!canReadCandidateDetails ? 'limited-role' : ''}
                      path={reportURI}
                    >
                      <Cell
                        currentUser={currentUser}
                        candidate={candidate}
                        report={report}
                        cellKey={column.key}
                      />
                    </CellLink>
                  </M.TableCell>
                ),
            )}
          </MultipleReportsRow>
        );
      })}
    </>
  );
};

export default ExpandedReportsRow;
