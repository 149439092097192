import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_ADDONS_FLOW } from 'Constants';
import { useCanOrderNewReport } from '../utils';
import { AddChecksAction } from './add-checks';
import OrderNewReportAction from './order-new-report-action';
import '../styles.scss';

const ReportActions: React.FC<{
  isScrolled: boolean;
}> = ({ isScrolled }) => {
  const canOrderNewReport = useCanOrderNewReport();
  const orderAddonsFlowEnabled =
    useFlag(ORDER_ADDONS_FLOW)?.variantKey === 'enabled';

  return (
    <div
      data-testid='candidate-header-report-actions'
      className={`candidate-header-right ${
        isScrolled ? 'candidate-header-right-shrink' : ''
      }`}
    >
      {canOrderNewReport && !orderAddonsFlowEnabled && <OrderNewReportAction />}
      <AddChecksAction
        canOrderNewReport={canOrderNewReport}
        orderAddonsFlowEnabled={orderAddonsFlowEnabled}
      />
    </div>
  );
};

export default ReportActions;
