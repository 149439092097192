import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { AccountEverifyStatus } from 'api/i9';

const Tag = styled.div`
  display: flex;
  padding: 0.375rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 115%;
`;

const DeactivatedTag = styled(Tag)`
  color: #1a2026;
  background: #e1e6eb;
  cursor: pointer;
`;

const ActivatedTag = styled(Tag)`
  color: ${colors.successGreenHover};
  background: rgba(42, 212, 93, 0.2);
`;

const TooltipContent = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const defaultTooltipMessage =
  "Your orders won't be sent to E-Verify. You can activate it in Settings.\n\nE-Verify may not be required for your organization. Consult with independent legal counsel about whether E-Verify is right for your organization.";

const messages: { [key: number]: string } = {
  3: 'To use E-Verify, go to Settings and select “Activate E-Verify.” Fill out and submit the linked questionnaire.\n\nActivation takes 3-5 business days.',
};

const DeactivatedTooltipContent = (step: number) => {
  const message = messages[step] || defaultTooltipMessage;

  return (
    <TooltipContent>
      <p style={{ whiteSpace: 'pre-wrap' }}>{message}</p>
      <a
        href='https://help.checkr.com/hc/en-us/articles/15483238166423'
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </a>
    </TooltipContent>
  );
};

interface EverifyBadgeProps {
  step: number;
  status: AccountEverifyStatus;
  onActivateEverifyClick?: () => void;
}

const EverifyBadge: React.FC<EverifyBadgeProps> = ({
  step,
  status,
  onActivateEverifyClick,
}) => {
  const handleActivateEverifyClick = useCallback(() => {
    if (onActivateEverifyClick) {
      onActivateEverifyClick();
    }
  }, [onActivateEverifyClick]);

  if (status === 'activated') {
    return (
      <M.TooltipDefinition
        align='bottom-right'
        highlighted={false}
        definition='After Section 1 is complete, I-9s from enabled worksites are sent to E-Verify automatically.'
      >
        <ActivatedTag>
          <span>E-Verify activated</span>
        </ActivatedTag>
      </M.TooltipDefinition>
    );
  }
  return (
    <M.TooltipDefinition
      align='bottom-right'
      highlighted={false}
      definition={DeactivatedTooltipContent(step)}
    >
      <DeactivatedTag
        data-id='i9-settings-page-activate-e-verify'
        onClick={handleActivateEverifyClick}
      >
        <span>E-Verify deactivated</span>
      </DeactivatedTag>
    </M.TooltipDefinition>
  );
};

export default EverifyBadge;
