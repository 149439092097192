import React, { SyntheticEvent, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Dialog } from 'modules/assess/ui/common';
import {
  useDispatch,
  useState as useStateContext,
} from 'modules/assess/ui/ruleset/version/store';
import { Actions as StateActions } from 'modules/assess/ui/state/rules/lookback';
import { Item } from 'modules/assess/utilities/hierarchy';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { Type } from 'modules/assess/v2/ui/common/lookback-rules';
import { getInitialRule } from '../utils';
import Actions from './Actions';
import Heading from './Heading';
import SeverityFields from './SeverityFields';
import SubcategoriesList from './SubcategoriesList';
import { getBulkUpdatedRule } from './utils';

type Props = {
  item: Item;
  open: boolean;
  onClose: (event?: SyntheticEvent) => void;
  includeLessThanMisdemeanor?: boolean;
  type?: Type;
};

type InputType = number | undefined;

export const Container: React.FC<Props> = ({
  open,
  onClose,
  item,
  includeLessThanMisdemeanor,
  type = Type.Default,
}) => {
  const track = useTrackEvent();

  const [allRecordClear, setAllRecordClear] = useState(false);
  const [felonyEligible, setFelonyEligible] = useState<InputType>();
  const [misdemeanorEligible, setMisdemeanorEligible] = useState<InputType>();
  const [pettyOffenseEligible, setPettyOffenseEligible] = useState<InputType>();
  const disabled =
    !felonyEligible &&
    felonyEligible !== 0 &&
    !misdemeanorEligible &&
    misdemeanorEligible !== 0 &&
    !pettyOffenseEligible &&
    pettyOffenseEligible !== 0 &&
    !allRecordClear;

  const dispatch = useDispatch();
  const state = useStateContext();
  const lookbackRulesList: Array<any> = Object.values(
    state.rules.lookback.table,
  );

  const handleOnClose = useCallback(() => {
    setFelonyEligible(undefined);
    setMisdemeanorEligible(undefined);
    setPettyOffenseEligible(undefined);
    setAllRecordClear(false);
    onClose();
  }, [onClose]);

  const handleAllRecordClear = useCallback(() => {
    const updatedState = !allRecordClear;
    const clearValue = updatedState ? 0 : undefined;
    setFelonyEligible(clearValue);
    setMisdemeanorEligible(clearValue);
    setPettyOffenseEligible(clearValue);
    setAllRecordClear(updatedState);
  }, [allRecordClear]);

  const setCategoryEligibleValue = useCallback(() => {
    const subcategories = item.children || [];
    subcategories.forEach(subcategory => {
      const rule = lookbackRulesList.find(
        r => r.charge_subcategory === subcategory.name,
      );
      const initialCharge = getInitialRule(subcategory.name, rule);
      const charge = getBulkUpdatedRule(
        initialCharge,
        felonyEligible,
        misdemeanorEligible,
        pettyOffenseEligible,
        includeLessThanMisdemeanor,
      );
      const type = rule ? StateActions.Update : StateActions.Create;
      const action = type.create(charge);
      dispatch(action);
    });
    track(LookbackPeriod.BulkEditUsed, {
      category: item.name,
    });
    handleOnClose();
  }, [
    dispatch,
    felonyEligible,
    handleOnClose,
    includeLessThanMisdemeanor,
    item,
    lookbackRulesList,
    misdemeanorEligible,
    pettyOffenseEligible,
    track,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      size='sm'
      data-testid='assess-v2-ui-common-lookback-rules-dialog-container'
    >
      <M.ModalBody>
        <Heading categoryName={item.name} handleClose={handleOnClose} />
        <SubcategoriesList subcategories={item.children} />
        <SeverityFields
          checked={allRecordClear}
          felonyEligible={felonyEligible}
          item={item}
          handleOnClick={handleAllRecordClear}
          includeLessThanMisdemeanor={includeLessThanMisdemeanor}
          misdemeanorEligible={misdemeanorEligible}
          pettyOffenseEligible={pettyOffenseEligible}
          setFelonyValue={setFelonyEligible}
          setMisdemeanorValue={setMisdemeanorEligible}
          setPettyOffenseValue={setPettyOffenseEligible}
          type={type}
        />
        <Actions disabled={disabled} onBulkEdit={setCategoryEligibleValue} />
      </M.ModalBody>
    </Dialog>
  );
};

export default Container;
