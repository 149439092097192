import { Namespace } from 'modules/assess/api/constants';
import React from 'react';
import { NamespaceRoute } from '../router/context';
import Rulesets from '../rulesets/Page';

type Props = {};

const namespace = Namespace.criminal;

export const Home: React.FC<Props> = () => {
  return (
    <NamespaceRoute.Provider value={namespace}>
      <Rulesets />
    </NamespaceRoute.Provider>
  );
};

export default Home;
