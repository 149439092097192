import axios, { AxiosError } from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';

// Type guard to check if the error is an Axios error
function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

const getUser = async (token: string | null) => {
  try {
    const response = await axios.get(`${CHECKR_API_BASE_URL}/user`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 401
    ) {
      localStorage.removeItem('accessToken');
      const signup = JSON.parse(sessionStorage.getItem('signup') || '{}');
      delete signup.last4;
      sessionStorage.setItem('signup', JSON.stringify(signup));
      window.location.reload();
    }
    throw error;
  }
};

function useGetUser() {
  const token = localStorage.getItem('accessToken');

  const result = useQuery(['signup-user'], () => getUser(token), {
    enabled: !!token,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const { isLoading, error, data } = result;

  return {
    data,
    isLoading,
    error,
  };
}

export default useGetUser;
