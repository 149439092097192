import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';

const choice_group = `${exam_reorder_modal_body}.screening_selection.choice_group`;

const Container = styled.div`
  display: flex;
  #mastodon && {
    .cds--tooltip .cds--popover-content {
      max-width: 18rem;
    }
  }
`;

const ChoiceGroupHeader = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <M.TooltipLabel>{t(`${choice_group}.label`)}</M.TooltipLabel>
      <M.Tooltip align='top-left' label={t(`${choice_group}.tooltip`)}>
        <M.Icon icon='WarningFilled' />
      </M.Tooltip>
    </Container>
  );
};

export default ChoiceGroupHeader;
