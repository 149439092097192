/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

const createActionCreator =
  type =>
  ({ ...payload } = {}) => ({
    type,
    ...payload,
  });

export default createActionCreator;
