import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { IconTableHeader } from './AdverseActionsStyledComponents';
import { Headers } from './headers';
import SortingHeader from './SortingHeader';

type Props = {
  onSortClick: Function;
  params: any;
  showPaused: boolean;
};

const AdverseActionsTableHead: React.FC<Props> = ({
  onSortClick,
  params,
  showPaused,
}) => {
  const {
    created_at,
    paused_duration,
    paused_time,
    paused_user,
    post_notice_scheduled_at,
  } = Headers;

  const defaultHeaders = [post_notice_scheduled_at];
  const pausedHeaders = [paused_time, paused_duration, paused_user];
  const displayHeaders = showPaused ? pausedHeaders : defaultHeaders;

  const headerElems = displayHeaders.map(header => (
    <SortingHeader
      key={header}
      params={params}
      header={header}
      onSortClick={onSortClick}
    />
  ));

  return (
    <M.TableHead>
      <M.TableRow>
        <M.TableHeader
          data-testid='adverse-actions-table-header-status'
          style={{ width: '16.5%' }}
        >
          Status
        </M.TableHeader>
        <M.TableHeader
          data-testid='adverse-actions-table-header-candidate'
          style={{ width: '28.5%' }}
        >
          Candidate name
        </M.TableHeader>
        <SortingHeader
          params={params}
          header={created_at}
          onSortClick={onSortClick}
        />
        {headerElems}
        <IconTableHeader style={{ width: '3%', minWidth: '64px' }} />
        <IconTableHeader style={{ width: '3%', minWidth: '80px' }} />
      </M.TableRow>
    </M.TableHead>
  );
};

export default AdverseActionsTableHead;
