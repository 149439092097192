import { AnyQueryKey, useQuery } from 'react-query';
import { IS_ANGULAR_IFRAME } from 'Constants';
import getIncidents from './actions';
import { Incident } from './types';

const ALLOWED_STATUSES = ['major_outage']; // Currently we only want to inform customers about Major outages. This array can be expanded to allow for more.

/**
 * Get a list of incidents with high enough severity, and only their relevant components
 * @param incidents - List of current incidents
 * @param hasFiltration - Whether to  filter by severity or not
 * @returns A curated list of incidents
 */
export const filterIncidents = (
  incidents: Incident[] | undefined,
  hasFiltration?: boolean,
) => {
  if (!incidents?.length) {
    return [];
  }

  // If filtration isn't enabled, then return early with the full list.
  if (!hasFiltration) {
    return incidents;
  }

  return incidents
    .map(incident => {
      // For a given incident, ONLY return the components that match our desired severity
      const matchedComponents = incident.components.filter(({ status }) => {
        return ALLOWED_STATUSES.includes(status);
      });

      // If there were positive matches, then return a modified version of the object, with the filtered-down set of components.
      if (matchedComponents.length) {
        return { ...incident, components: matchedComponents };
      }

      // If there aren't any severe-enough components, return the whole incident as null (to be filtered out in the next step)
      return null;
    })
    .filter(incident => {
      // Go through the results of the map, and filter out anything that was marked as null.
      return incident !== null;
    }) as Incident[];
};

export const useIncidents = () => {
  const hasFiltration = !IS_ANGULAR_IFRAME; // We still want to show all types of incidents on the dashboard-internal side

  const key: AnyQueryKey = ['api_status'];

  const request = () => getIncidents();

  const result = useQuery(key, request, { refetchOnWindowFocus: false });

  const { data: { page, incidents } = {}, isLoading, isError } = result;

  return {
    incidents: filterIncidents(incidents, hasFiltration),
    isLoading,
    isError,
    page,
  };
};

export default useIncidents;
