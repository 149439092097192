import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import { getTimeDelta } from '@dashboard-experience/utils';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { namespace } from 'modules/adjudication/locales';
import PostAdverseActionNoticeModal from 'components/AdverseActions/PostAdverseActionNoticeModal';

const Btn = styled(M.Button)`
  max-width: none;
  width: 100%;
  justify-content: center;
`;

const kind = 'pre_adverse';

const PostAdverseAction: React.FC = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { id: reportId } = useReport();
  const { adverseAction } = useAdverseActions(reportId);
  const handlePostAAButtonClick = useCallback(() => {
    setShowModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Post Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, []);
  const closeModal = useCallback(() => setShowModal(false), []);

  if (!adverseAction) {
    return null;
  }
  const postDate = moment(adverseAction?.post_notice_ready_at);
  const postDateDelta = getTimeDelta(postDate, moment.now());
  const enablePostAA = postDateDelta.asMilliseconds() >= 0;

  const formattedPostDate = moment(adverseAction?.post_notice_ready_at).format(
    'MMM D, YYYY [at] h:mm:ss a',
  );
  const tooltipText = t(`${namespace}:report.actions.post_adverse.tooltip`, {
    postDate: formattedPostDate,
  });
  const buttonText = t(`${namespace}:report.actions.post_adverse.action`);

  return (
    <M.Container.Col>
      {enablePostAA ? (
        <Btn
          kind={kind}
          data-testid='post-aa-button'
          onClick={handlePostAAButtonClick}
          disabled={report.archived}
        >
          {buttonText}
        </Btn>
      ) : (
        <M.TooltipButton
          kind={kind}
          data-testid='post-aa-button-tooltip'
          className='disabled'
          label={tooltipText}
          fullWidth
        >
          {buttonText}
        </M.TooltipButton>
      )}
      {showModal && adverseAction && (
        <PostAdverseActionNoticeModal
          data-testid='post-aa-notice-modal'
          closeModal={closeModal}
          adverseAction={adverseAction}
        />
      )}
    </M.Container.Col>
  );
};

export default PostAdverseAction;
