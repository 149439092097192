import { ItemType } from 'modules/assess/models/mvr/codes';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  section: 'categories' | 'mappings';
  type: ItemType;
};

export const TableHeader: React.FC<Props> = ({ name, section, type }) => {
  const { t } = useTranslation('assess');
  const text = t(
    [
      `assess:mvr.category-sets.instance.versions.instance.${type}.${section}.table.${name}`,
      `assess:mvr.category-sets.instance.versions.instance.common.${section}.table.${name}`,
    ],
    { type },
  );
  return <strong>{text}</strong>;
};

export default TableHeader;
