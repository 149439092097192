import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { ASSESS_STANDARD_PRODUCT_PAGE } from 'modules/assess/v2/constants';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import { fromUrlToTranslation } from 'modules/assess/v2/ui/utils';

type Props = {
  option: Option;
};

const Divider = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  border-top: 1px solid ${colors.brandSlate2};
`;

const StyledDiv = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 14px;
  margin-left: 2rem;
`;

const Text = styled.span`
  color: ${colors.uiTextSecondaryLight};
`;

const StyledLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.uiNavy600};
`;

export const Container: React.FC<Props> = ({ option }) => {
  const history = useHistory();
  const { t } = useTranslation('assess:v2');
  const translationOption = fromUrlToTranslation(option);
  const translations: any = t(`lite.${translationOption}.footer`, {
    returnObjects: true,
  });

  const goToSettings = useCallback(() => {
    history.push('/assess');
  }, [history]);

  const openAssessInfoTab = useCallback(() => {
    window.open(ASSESS_STANDARD_PRODUCT_PAGE, '_blank');
  }, []);

  const onClick =
    option === Option.NatureOfOffense ? goToSettings : openAssessInfoTab;

  return (
    <div data-testid='assess-v2-ui-lite-common-footer-container'>
      <Divider />
      <StyledDiv>
        <Text>{translations.text}</Text>
        <StyledLink onClick={onClick}>{translations.link}</StyledLink>
      </StyledDiv>
    </div>
  );
};

export default Container;
