import React, { useCallback } from 'react';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { getScreeningSummaryLabel } from '../shared/utils';
import LearnMoreLink from '../shared/LearnMoreLink';
import AddOnsSummaryPrice from '../shared/AddOnsSummaryPrice';
import ThirdPartyFees from '../shared/ThirdPartyFees';

const LinePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

const StyledAddOnsSummaryPrice = styled(AddOnsSummaryPrice)`
  padding-top: 3px;
  @media (max-device-width: 400px) {
    margin-left: 20px;
  }
  float: right;
`;

const LinePriceSection = styled.span`
  display: inline-flex;
  padding-top: 3px;
`;

const BasePackageScreeningLabel = styled.span`
  @media (max-device-width: 530px) {
    font-size: 12px !important;
  }
`;

const SummaryHeading = styled.h4`
  margin-bottom: 0px !important;
  padding-bottom: 0px;
`;

const PackageNameHeading = styled.h5`
  align-self: center;
  margin-bottom: 0px !important;
`;

const SummarySection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.brandSlate3};

  @media (max-device-width: 530px) {
    padding-top: 16px;
  }
`;

const ScreeningList = styled.ul`
  list-style-position: inside;
  margin-top: 12px !important;

  li::marker {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
    font-size: 14px;
  }
`;

const ScreeningListLinePrice = styled.div`
  display: inline;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

const AddOnsHeading = styled.h5`
  margin-bottom: 0px !important;
`;

const ScreeningListItem = styled.li`
  margin-bottom: 4px;
  padding-top: 3px;
  color: ${colors.brandNavy3};
  height: 17px;
`;

const LoadingSpinner = styled(M.LoadingSpinner)`
  margin: 24px auto;
  vertical-align: center;
`;

const NoSelectedPackageLabel = styled(SummarySection)`
  padding-bottom: 0px;
  border-bottom: none;
`;

const PackageHeaderWrapper = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 0px !important;
`;

const EditButton = styled(M.Button)`
  padding: 0px !important;
  border-radius: 0px !important;
  margin-left: 7px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

const InternationalAddScreeningsSummary: React.FC<any> = ({
  basePackage,
  basePackageScreeningTypes,
  pendingAddOnPricesQuery,
  hasAddedScreenings,
  additionalProperties,
  helpCenterClicked,
  handleInternationalPriceLinkClick = () => {},
  goBack,
}) => {
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const handlePriceVariesClick = useCallback(() => {
    handleInternationalPriceLinkClick();
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.REMOVE_ADD_ONS_PAGE_COMPLETED,
      {
        'Completion Type': 'Exit',
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings,
        'Add-on Screenings': data?.items?.filter(
          (screening: any) =>
            !basePackageScreeningTypes.includes(screening.product_type),
        ),
        'Package Saved': 'No',
        'New Package Name': basePackage.name,
        'International Pricing Page Clicked': 'No',
        'Help Center Clicked': helpCenterClicked,
      },
    );
  }, [helpCenterClicked]);

  if (!pendingAddOnPricesQuery) {
    return <LoadingSpinner withOverlay={false} />;
  }

  const { data, isLoading, isError } = pendingAddOnPricesQuery;

  return (
    <>
      <SummaryHeading className='summary-heading'>
        Package summary
      </SummaryHeading>
      {basePackage.screenings.length !== 0 ? (
        <SummarySection className='summary-section'>
          <LinePrice className='line-price'>
            <PackageHeaderWrapper>
              <PackageNameHeading
                className='package-name-heading'
                data-testid='summary-section-package-name'
              >
                {basePackage.name}
              </PackageNameHeading>
              {!!goBack && (
                <EditButton kind='tertiary' onClick={goBack}>
                  Edit
                </EditButton>
              )}
            </PackageHeaderWrapper>
            <LearnMoreLink
              linkText='Price varies'
              href='https://checkr.com/pricing/international'
              onClick={handlePriceVariesClick}
            />
          </LinePrice>
          <ScreeningList>
            {basePackageScreeningTypes.map(
              (screeningType: any) =>
                screeningType !== 'international_adverse_media_search' && (
                  <ScreeningListItem
                    className='screeing-list-item'
                    key={screeningType}
                  >
                    <BasePackageScreeningLabel className='screening-label'>
                      {getScreeningSummaryLabel(
                        screeningType === 'international_criminal_search_v2'
                          ? 'international_criminal_or_adverse_media'
                          : screeningType,
                      )}
                    </BasePackageScreeningLabel>
                  </ScreeningListItem>
                ),
            )}
          </ScreeningList>
        </SummarySection>
      ) : (
        <SummarySection className='summary-section'>
          <PackageHeaderWrapper>
            <PackageNameHeading
              className='package-name-heading'
              data-testid='summary-section-package-name'
            >
              Package
            </PackageNameHeading>
            {!!goBack && (
              <EditButton kind='tertiary' onClick={goBack}>
                Edit
              </EditButton>
            )}
          </PackageHeaderWrapper>
          <NoSelectedPackageLabel className='no-selected-package-label'>
            <div>You haven&apos;t selected a package</div>
          </NoSelectedPackageLabel>
        </SummarySection>
      )}
      {isLoading && (
        <LoadingSpinner className='loading-spinner' withOverlay={false} />
      )}
      {isError && (
        <SummarySection className='summary-section'>
          Something went wrong. Please try adding your screening again.
        </SummarySection>
      )}
      {data && (
        <>
          {hasAddedScreenings && (
            <SummarySection className='summary-section'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AddOnsHeading>Add-ons </AddOnsHeading>
                <LearnMoreLink
                  linkText='Price varies'
                  href='https://checkr.com/pricing/international'
                  onClick={handlePriceVariesClick}
                />
              </div>
              <ScreeningList>
                {data?.items
                  ?.filter(
                    (screening: any) =>
                      !basePackageScreeningTypes.includes(
                        screening.product_type,
                      ),
                  )
                  .map((screening: any) => (
                    <ScreeningListItem
                      className='screening-list-item'
                      key={screening.product_type}
                    >
                      <ScreeningListLinePrice className='screening-list-line-price'>
                        <LinePriceSection className='line-price-section'>
                          {getScreeningSummaryLabel(
                            screening.product_type,
                            additionalProperties,
                          )}
                        </LinePriceSection>
                        <StyledAddOnsSummaryPrice screening={screening} />
                      </ScreeningListLinePrice>
                    </ScreeningListItem>
                  ))}
              </ScreeningList>
            </SummarySection>
          )}
          {!hasAddedScreenings && basePackage.screenings.length === 0 && (
            <SummarySection className='summary-section'>
              <AddOnsHeading>Add-ons</AddOnsHeading>
              <NoSelectedPackageLabel className='no-selected-package-label'>
                <div>You haven&apos;t selected any add-ons.</div>
              </NoSelectedPackageLabel>
            </SummarySection>
          )}
          <ThirdPartyFees
            serviceFeeMin={data?.service_fee_range[0] || 0}
            serviceFeeMax={data?.service_fee_range[1] || 0}
            passthroughFeeMin={data?.passthrough_fee_range[0] || 0}
            passthroughFeeMax={data?.passthrough_fee_range[1] || 0}
          />
        </>
      )}
    </>
  );
};

export default InternationalAddScreeningsSummary;
