import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useState as useQuery } from 'modules/assess/ui/ruleset/version/store';
import { Item } from 'modules/assess/utilities/hierarchy';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import BulkEditModal from 'modules/assess/v2/ui/common/lookback-rules/dialog';
import { getFilteredSubcategories } from 'modules/assess/v2/ui/common/lookback-rules/utils';
import {
  useLessThanMisdemeanorPremadeRule,
  useTrackEvent,
} from 'modules/assess/v2/ui/hooks';
import Actions from './Actions';
import Subcategories from './subcategories';

type Props = {
  item: Item;
  search?: string;
};

const StyledExpandable = styled(M.Expandable)`
  border-bottom: 1px solid ${colors.uiGrey200};
  min-height: 48px !important;
  margin-bottom: -1px;

  .heading-left {
    margin-right: auto;
    width: 100%;
    span {
      width: 100%;
    }
  }
  .heading-right {
    margin-left: 0;
    min-width: fit-content;
  }
`;

const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
`;

const Category: React.FC<Props> = ({ item, search }) => {
  const track = useTrackEvent();
  const state = useQuery();
  const ref = useRef<HTMLDivElement>(null!);

  const [isExpanded, setIsExpanded] = useState(false);
  const [showBulkEditDialog, setShowBulkEditDialog] = useState(false);
  const onClose = useCallback(() => {
    setShowBulkEditDialog(false);

    const current = ref.current as unknown as HTMLDivElement;
    current && current.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [setShowBulkEditDialog]);

  const handleBulkEdit = useCallback(() => {
    setShowBulkEditDialog(true);
  }, [setShowBulkEditDialog]);

  const onExpandableClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      track(LookbackPeriod.CategoryExpanded, {
        category: item.name,
      });
    }
  }, [isExpanded, item, track]);

  const filteredSubcategories = getFilteredSubcategories(item, search);

  const includeLessThanMisdemeanor =
    !useLessThanMisdemeanorPremadeRule() &&
    !state.settings?.lookback_rules_all_less_than_misdemeanor_eligible;

  useEffect(() => {
    const expanded = !!search && search !== '';
    setIsExpanded(expanded);
  }, [search, setIsExpanded]);

  return (
    <div ref={ref}>
      <StyledExpandable
        data-testid='assess-v2-ui-guideline-lookback-period-table-category'
        customExpand
        initialExpanded={isExpanded}
        key={item.id}
        title={
          <M.Screening.Heading
            headingLeft={{
              name: (
                <Title
                  data-testid='assess-v2-ui-guideline-lookback-period-table-category-title'
                  onClick={onExpandableClick}
                >
                  {item.name}
                </Title>
              ),
            }}
            headingRight={
              <Actions
                showActions={isExpanded}
                onClickBulkEdit={handleBulkEdit}
              />
            }
          />
        }
      >
        <BulkEditModal
          open={showBulkEditDialog}
          onClose={onClose}
          item={item}
          includeLessThanMisdemeanor={includeLessThanMisdemeanor}
        />
        <Subcategories items={filteredSubcategories} />
      </StyledExpandable>
    </div>
  );
};

export default Category;
