import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AccountTabsContainer, ContextContainer } from 'containers';
import { TabName } from 'containers/Account/Tabs/types';

type RouterProps = {
  tab: TabName;
};

type Props = RouteComponentProps<RouterProps>;

const AccountTabsPage: React.FC<Props> = ({ match }) => {
  const {
    params: { tab },
  } = match;

  return <AccountTabsContainer tab={tab} />;
};

export default ContextContainer(AccountTabsPage);
