import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import * as Screens from './views';
import { useModalState } from '../context';

type Props = {};

const Content: React.FC<Props> = () => {
  const currentScreen = useModalState('currentScreen');
  const ComponentScreen = (Screens as GenericObject)[currentScreen];

  return (
    <M.ModalBody>
      <ComponentScreen />
    </M.ModalBody>
  );
};

export default Content;
