import React from 'react';
import { Timeline } from 'components/Timeline';
import { RouteComponentProps } from 'react-router';

type MatchParams = {
  contextName: string;
  contextId: string;
};
type Props = RouteComponentProps<MatchParams>;

const TimelineContainer: React.FC<Props> = ({ match }) => {
  const { params } = match;
  const { contextName, contextId } = params;
  return <Timeline contextName={contextName} contextId={contextId} />;
};

export default TimelineContainer;
