import React from 'react';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import BaseFilter from './BaseFilter';
import { onSelectChange, AnalyticsProps } from '../helpers';

const MonitorType = () => {
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.MONITOR_TYPE,
    trackEvent,
  };

  return (
    <BaseFilter
      component='MonitorTypeFilter'
      param='monitor_type'
      onChange={onSelectChange}
      labelText='Monitor type'
      trackAnalyticsObject={trackAnalyticsObject}
    />
  );
};

export default MonitorType;
