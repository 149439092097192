import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useClickOutside } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { UserType } from 'modules/case-management/types';
import { useUsers } from 'modules/case-management/ui/TasksPage/hooks';
import Option from './Option';
import { useFetchAccountUsers } from './hooks';

type PropTypes = {
  closeMenu: () => void;
  onSelect: (user: UserType | null) => void;
  top?: boolean;
};

const StyledDiv = styled.div`
  position: absolute;
  box-shadow: 0px 1px 12px ${colors.uiGrey200} !important;
  width: 100%;
  background-color: ${colors.bgPrimaryLight};
  z-index: 99;
  display: flex;
  flex-direction: column;
  max-height: 15rem;
  overflow-y: scroll;
  padding: 5px;

  &.top {
    bottom: 56px;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 4px 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:hover {
      background-color: ${colors.uiGrey100};
    }
  }
`;

const StyledSearch = styled(M.Search)`
  #case-management-tasks-page-user-selection-search {
    border-bottom-color: ${colors.uiGrey200} !important;

    &:focus {
      box-shadow: none !important;
    }
  }
`;
const UserSelect = ({ closeMenu, onSelect, top }: PropTypes) => {
  const [filterPattern, setFilterPattern] = useState('');
  const currentUser = useUser();
  const { data } = useUsers();
  const users = data?.data;
  let assignees = users || [];

  const accountId = currentUser?.account?.id;
  const params = {
    'filter[roles]': ['admin', 'adjudicator'],
    pagination: true,
  };

  const isAdmin = currentUser?.roles.includes('admin');
  const accountUsersRes = useFetchAccountUsers({ accountId, params });
  if (isAdmin && accountUsersRes?.data) {
    const adjudicatorsAndAdmins = accountUsersRes?.data.data.map(
      (user: { checkr_user_id: any }) => user.checkr_user_id,
    );

    const filteredUsers = users?.filter((user: any) => {
      return adjudicatorsAndAdmins.includes(user?.id);
    });

    assignees = filteredUsers;
  }

  const ref = useRef<HTMLDivElement>(null!);
  useClickOutside({
    ref,
    handler: () => {
      closeMenu();
    },
  });

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterPattern(e.target.value);
    },
    [setFilterPattern],
  );

  if (!assignees || !currentUser) return null;

  const currUser = assignees.find(
    (user: { id: any }) => user?.id === currentUser.id,
  );
  const otherUsers = assignees.filter(
    (user: { id: any }) => user?.id !== currentUser.id,
  );

  const filteredOptions = otherUsers.filter((user: UserType) => {
    const pattern = new RegExp(filterPattern, 'i');
    return pattern.test(user.display_label) || pattern.test(String(user.email));
  });

  const selectionOptions = [currUser, 'unassigned', ...filteredOptions];
  const selectionList = selectionOptions.map((user: any) => {
    return <Option key={user.id} user={user} onSelect={onSelect} />;
  });

  return (
    <StyledDiv
      data-testid='case-management-tasks-page-user-selection-dropdown'
      className={top ? 'top' : ''}
      ref={ref}
    >
      <StyledSearch
        id='case-management-tasks-page-user-selection-search'
        placeholder='Search teammates'
        data-testid='case-management-tasks-page-user-selection-search'
        onChange={onSearchChange}
      />
      {selectionList}
    </StyledDiv>
  );
};

export default UserSelect;
