/* eslint-disable react/jsx-no-bind */
import { M } from '@dashboard-experience/mastodon';
import { RefType } from 'components/Report/ReportInformation/types';
import UIContext from 'context/UI';
import { useCandidate } from 'providers/Candidate';
import React, { forwardRef, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Report } from 'types';
import { updateParentWindowUrl } from 'utils';
import ReportInvitationDropdownItem from './report-invitation-dropdown-item';

type Props = {
  selectedReport: Report;
  reports: Report[];
};

const ReportDropdownItems = forwardRef<RefType, Props>(
  ({ selectedReport, reports }) => {
    const history = useHistory();
    const { contextId } = useContext(UIContext);

    const candidate = useCandidate();
    const updateUrl = useCallback(
      (url, reportOrInvite) => {
        const path =
          reportOrInvite === 'report'
            ? `/candidates/${candidate.id}/reports/${url}`
            : `/candidates/${candidate.id}/invitations/${url}`;
        // if in iframe, update parent url as well when we switch reports

        if (contextId) {
          updateParentWindowUrl({
            contextId,
            path,
            reload: true,
          });
        }
        history.push(path);
      },
      [history, contextId, candidate],
    );

    const onSelect = useCallback(
      selectedItem =>
        selectedItem && updateUrl(selectedItem.id, selectedItem.object),
      [updateUrl],
    );

    return reports.length > 0 ? (
      <>
        {reports.map(report => {
          return (
            <ReportInvitationDropdownItem
              key={`report-${report.id}`}
              // @ts-ignore TODO: this error should be fixed once report type is updated in Utils
              report={report}
              isSelected={report.id === selectedReport.id}
              onSelect={onSelect}
            />
          );
        })}
      </>
    ) : (
      <M.ActionMenuItem title={<M.LoadingInline />} />
    );
  },
);

ReportDropdownItems.displayName = 'ReportDropdownItems';

export default ReportDropdownItems;
