import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useGetAnalyticsDashboard } from 'api/analytics';
import { M } from '@dashboard-experience/mastodon';

type AnalyticsIframeProps = { lookerName: string };

const StyledIframe = styled.iframe`
  width: 100%;

  &.adjudication {
    min-height: 7075px;
  }

  &.continuous {
    min-height: 2475px;
  }

  &.criminal {
    min-height: 3225px;
  }

  &.mvr {
    min-height: 3475px;
  }

  &.overview {
    min-height: 3775px;
  }

  &.verifications {
    min-height: 3385px;
  }
`;

const AnalyticsIframe: React.FC<AnalyticsIframeProps> = ({ lookerName }) => {
  const [showLoader, setShowLoader] = useState(true);
  const {
    url: lookerUrl,
    isLoading: lookerUrlIsLoading,
    isError: lookerUrlIsError,
  } = useGetAnalyticsDashboard({
    lookerName,
    includePermutationArray: true,
  });

  const hideLoader = useCallback(() => {
    setShowLoader(false);
  }, [setShowLoader]);

  return lookerUrl && !lookerUrlIsLoading && !lookerUrlIsError ? (
    <>
      {showLoader && <M.LoadingSpinner />}
      <StyledIframe
        src={lookerUrl}
        className={lookerName}
        data-testid='analytics-iframe'
        onLoad={hideLoader}
      />
    </>
  ) : null;
};

export default AnalyticsIframe;
