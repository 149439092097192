import React from 'react';
import { AtsIntegrationValidationResult } from 'api/atsIntegration';
import {
  Button,
  ColoredIcon,
  Description,
  DescriptionContainer,
  ValidationFailure,
  ValidationFailureTypography,
  ItemWrapper,
  ButtonContainer,
} from './styles';
import Spinner from './Spinner';

type Props = {
  name: string;
  validationId: string;
  onValidateResult: (response: AtsIntegrationValidationResult) => void;
  onValidateClick: (validationId: string) => void;
  onValidationError: (error: Error, validationIds: string[]) => void;
  invalidText?: string;
  isValidating: boolean;
  partnerSlug: string;
};

const ValidationItem: React.FC<Props> = ({
  name,
  validationId,
  invalidText,
  onValidateClick,
  onValidateResult,
  onValidationError,
  isValidating,
  partnerSlug,
}: Props) => {
  const invalid = invalidText !== undefined;
  const onClick = React.useCallback(() => {
    onValidateClick(validationId);
  }, [onValidateClick, validationId]);
  return (
    <ItemWrapper
      invalid={invalid}
      data-testid={`ats-validation-item-wrapper-${validationId}`}
    >
      <DescriptionContainer invalid={invalid}>
        <Description
          data-testid={`ats-validation-item-description-${validationId}`}
        >
          {name}
        </Description>
        {invalid && (
          <ValidationFailure
            data-testid={`ats-validation-item-validation-failure-${validationId}`}
          >
            <ColoredIcon
              data-testid={`ats-validation-item-validation-failure-icon-${validationId}`}
              icon='WarningFilled'
            />
            <ValidationFailureTypography>
              {invalidText}
            </ValidationFailureTypography>
          </ValidationFailure>
        )}
      </DescriptionContainer>
      <ButtonContainer>
        {isValidating ? (
          <Spinner
            onSuccess={onValidateResult}
            onError={onValidationError}
            validationId={validationId}
            partnerSlug={partnerSlug}
          />
        ) : (
          <Button
            data-testid={`ats-validation-item-button-${validationId}`}
            onClick={onClick}
          >
            Validate
          </Button>
        )}
      </ButtonContainer>
    </ItemWrapper>
  );
};

export default ValidationItem;
