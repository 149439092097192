import * as Entity from 'modules/assess/models/rulesets/growth-insights/entity';
import { ID } from 'modules/id';
import { mockData } from 'modules/assess/api/ruleset/__mocks__/data/growth_insights';
import client from '../client';
import { Namespace } from '../constants';

const BASE_PATH = 'rulesets';

export const path = (namespace: Namespace, id: ID) => {
  return `/${namespace}/${BASE_PATH}/${id}/growth_insights`;
};

// TODO: EADA-1299 Remove mockData after growth_insights route works on staging

// Add delay to display spinner for 1 second
const delay: number = 1000;

// Note: staging growth_insights API returns 400 since it cannot access Snowflake
// set query parameter ?mock=true to use mock data

export const fetch = (
  namespace: Namespace,
  id: string,
  mock?: boolean,
): Promise<Entity.Type> => {
  if (mock) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(mockData);
      }, delay);
    });
  }
  return client.get(path(namespace, id));
};
