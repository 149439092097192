import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContextContainer, GeosContainer, PackagesContainer } from 'containers';
import { M } from '@dashboard-experience/mastodon';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { updateParentWindowUrl } from 'utils';

import UIContext from 'context/UI';
import { useTranslation } from 'react-i18next';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';

type RouterProps = { category: string; programId?: string };
type Props = RouteComponentProps<RouterProps>;

enum TABS {
  PACKAGES = 'packages',
  GEOS = 'geos',
}

type TabConfig = {
  [key: string]: {
    titleKey: string;
    component: React.FC<any>;
    authorized: (user: any) => boolean;
  };
};

const Tabs: TabConfig = {
  [TABS.PACKAGES]: {
    titleKey: 'packages.packages',
    component: PackagesContainer,
    authorized: (user: any) =>
      accountHasPermission(user, 'see_full_dashboard') &&
      hasPermission(user, 'manage_packages') &&
      accountHasPermission(user, 'self_serve_packages_enabled'),
  },
  [TABS.GEOS]: {
    titleKey: 'geos.geos',
    component: GeosContainer,
    authorized: (user: any) =>
      (hasPermission(user, 'manage_geos') &&
        !accountHasPermission(user, 'segmentation_enabled')) ||
      user.roles.includes('internal_admin'), // TODO - remove role check, migrated verbatim
  },
};

const ScreeningSettingPage: React.FC<Props> = ({ match }) => {
  const { category: initialCategory, programId } = match.params; // 'program' could be an actual program ID, or just the word 'account'
  const { t } = useTranslation('', { keyPrefix: 'screening_settings' });
  const user = useUser();
  const singleIframeFlag =
    useFlag('single_iframe_screening_settings')?.variantKey === 'on';

  const { isIframe, contextId } = useContext(UIContext);
  const history = useHistory();

  const [category, setCategory] = useState(initialCategory);
  const [currentAction, setCurrentAction] = useState('');
  // select just the tabs the user can view
  const visibleTabs = useMemo(
    () =>
      Object.entries(Tabs).reduce((permissionedTabs, [tabKey, tabContent]) => {
        if (tabContent.authorized(user)) permissionedTabs[tabKey] = tabContent;
        return permissionedTabs;
      }, {} as TabConfig),
    [user],
  );

  const handleChange = useCallback(
    (e: { selectedIndex: number }) => {
      const newCategory = Object.keys(visibleTabs)[e.selectedIndex];
      setCurrentAction('PUSH');
      setCategory(newCategory);
    },
    [visibleTabs],
  );

  const updateUrl = useCallback(() => {
    if (currentAction === 'PUSH') {
      const newPath = category;

      if (isIframe && contextId) {
        updateParentWindowUrl({
          contextId,
          path: `/screening/${newPath}`,
          reload: false,
        });
      } else {
        history.push(`/screening/${newPath}`);
      }
    }
  }, [category, contextId, currentAction, history, isIframe]);

  const getCategoryIndex = useCallback(() => {
    const categoryIndex = (Object.keys(visibleTabs) as string[]).indexOf(
      category,
    );

    return categoryIndex >= 0 ? categoryIndex : 0;
  }, [category, visibleTabs]);

  useEffect(() => {
    setCurrentAction('POP');
    setCategory(initialCategory);
  }, [initialCategory]);

  useEffect(() => {
    updateUrl();
  }, [category, getCategoryIndex, updateUrl]);

  // If not permissioned for a valid category, send them back to the candidates list
  if (Tabs[category] && !Tabs[category].authorized(user))
    return <Redirect to='/candidates' />;

  // Invalid route
  if (!visibleTabs[category]?.component) return <Redirect to='/404' />;

  // In iframe, simply return the desired component (since the Dashboard side manages the tabs for us
  if (!singleIframeFlag && isIframe) {
    const Component = visibleTabs[category].component;
    return <Component programId={programId} />;
  }

  // For standalone, return a Tabs container with both components, but with one selected based on the URL
  return (
    <M.Tabs selectedIndex={getCategoryIndex()} onChange={handleChange}>
      <M.TabList>
        {Object.values(visibleTabs).map(tabContent => (
          <M.Tab key={tabContent.titleKey}>{t(tabContent.titleKey)}</M.Tab>
        ))}
      </M.TabList>
      <M.TabPanels>
        {Object.entries(visibleTabs).map(([tabKey, tabContent]) => {
          const Component = tabContent.component;
          return (
            <M.TabPanel key={tabKey}>
              {category === tabKey && <Component programId={programId} />}
            </M.TabPanel>
          );
        })}
      </M.TabPanels>
    </M.Tabs>
  );
};

export default ContextContainer(ScreeningSettingPage);
