import React, { useCallback, useContext } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Context from 'modules/assess/ui/rules/lookback/table/context';

type Props = {};

const Text = styled.span`
  color: ${colors.uiNavy600};
  cursor: pointer;
  font-size: 12px;
`;

const ExpandCollapseAll: React.FC<Props> = () => {
  const { expandAll, collapseAll, setExpandAll, setCollapseAll } =
    useContext(Context);

  const handleExpandAll = useCallback(() => {
    if (!expandAll) {
      setCollapseAll(false);
    }
    setExpandAll(true);
  }, [expandAll, setCollapseAll, setExpandAll]);

  const handleCollapseAll = useCallback(() => {
    if (!collapseAll) {
      setExpandAll(false);
    }
    setCollapseAll(true);
  }, [collapseAll, setCollapseAll, setExpandAll]);

  return (
    <span>
      <Text onClick={handleExpandAll}>Expand All</Text>
      {' | '}
      <Text onClick={handleCollapseAll}>Collapse All</Text>
    </span>
  );
};

export default ExpandCollapseAll;
