import { Client as webhookClient } from 'api';
import {
  Webhook,
  AddWebhookParams,
  DeleteWebhookParams,
  UpdateWebhookSubscriptionsParams,
} from 'containers/WebhookContainer';

export type FormattedWebhook = { [key: string]: Webhook };

export const webhooks = async (
  accountId: string,
): Promise<{ [key: string]: any }> => {
  const liveWebhooks = await webhookClient.get(
    `/accounts/${accountId}/webhooks`,
  );

  const webhooks: FormattedWebhook = {};

  const unformattedWebhooks = [
    ...liveWebhooks.webhooks
      // filtering out live webhooks with application_ids & application_instance_ids to not see webhooks from the partner app & integration
      .filter(
        (accountLiveWebhooks: Webhook) =>
          !accountLiveWebhooks.application_id &&
          !accountLiveWebhooks.application_instance_id,
      )
      .map((webhook: Webhook) => ({
        ...webhook,
        live: true,
      })),
  ];

  unformattedWebhooks.forEach((webhook: Webhook) => {
    webhooks[webhook.id] = webhook;
  });

  return webhooks;
};

export const deleteWebhook = ({ id, live = true }: DeleteWebhookParams) => {
  const url = `/v1/webhooks/${id}`;
  return webhookClient.delete(url);
};

export const addWebhook = (params: AddWebhookParams) => {
  const url = '/v1/webhooks';
  return webhookClient.post(url, params);
};

export const updateWebhookSubscriptions = (
  params: UpdateWebhookSubscriptionsParams,
) => {
  const { accountId, subscriptions } = params;

  return webhookClient.put(
    `/accounts/${accountId}/webhook_setting`,
    subscriptions,
  );
};
