import React from 'react';
import { ContextDateOptionChange } from 'modules/assess/models/rulesets/version-diff';
import T from 'components/Table';
import TableHeader from './TableHeader';
import { useRows } from './data';

type Props = {
  changes: ContextDateOptionChange.List;
};

const columns: Array<string> = ['active', 'draft'];

export const Table: React.FC<Props> = ({ changes = [] }) => {
  const headers = columns.map(key => ({
    key,
    header: <TableHeader name={key} />,
  }));

  return (
    <T
      data-testid='assess-ui-ruleset-version-publish-changes-context-date-options'
      columns={columns}
      headers={headers}
      rows={useRows(changes)}
    />
  );
};

export default Table;
