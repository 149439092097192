import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { MULTIPLE_PEOPLE, useOrderBackgroundCheckContext } from '../Context';

const FooterStyled = styled.div<FooterStyleProps>`
  border-top: ${({ $showBorder }) =>
    $showBorder ? `1px solid ${colors.uiTextPrimaryLight}` : 'none'};
  margin-top: 16px;
  padding-top: 16px;
`;

const FooterTypography = styled.div`
  color: ${colors.uiTextPrimaryLight};
  line-height: 16px;
  font-size: 12px;
`;

const LinkStyled = styled(M.Link)`
  font-size: 12px;
`;

const CheckboxStyled = styled(M.Checkbox)`
  margin-top: 48px;
  margin-bottom: 18px;
`;

type FooterStyleProps = {
  $showBorder?: boolean;
};

type FooterProps = {
  orderCertified: boolean;
  setOrderCertified: (orderCertified: boolean) => void;
  showPrices: boolean;
  handleLearnMoreClick: () => void;
  handleServiceAgreementClick: () => void;
};

const Footer: React.FC<FooterProps> = ({
  orderCertified,
  setOrderCertified,
  showPrices,
  handleLearnMoreClick,
  handleServiceAgreementClick,
}) => {
  const handleCheck = useCallback(
    (event, _) => {
      setOrderCertified(!orderCertified);
    },
    [orderCertified, setOrderCertified],
  );
  const { state } = useOrderBackgroundCheckContext();
  const manualBulkOrderEnabled = state.manualBulkUploadType === MULTIPLE_PEOPLE;

  return (
    <FooterStyled $showBorder={showPrices}>
      {showPrices && (
        <FooterTypography>
          *Doesn&apos;t include third-party fees. These will be calculated on
          your invoice, along with any other applicable taxes and fees.{' '}
          <LinkStyled
            href='https://checkr.com/pricing/pass-through-fees'
            data-testid='footer-learn-more'
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleLearnMoreClick}
          >
            Learn more
          </LinkStyled>
        </FooterTypography>
      )}

      <CheckboxStyled
        id='review-and-submit-order-legal-check'
        checked={orderCertified}
        onChange={handleCheck}
        labelText={
          <FooterTypography>
            {manualBulkOrderEnabled
              ? `I certify that: (1) I am requesting background check report(s) only
              for an employment permissible purpose and no other purpose; (2) I
              have provided the consumer(s) with all necessary disclosures, and
              collected each consumer’s written authorization to obtain the report
              and to send notices electronically, including SMS messages where applicable;
              (3) The disclosure and authorization forms have been reviewed by my legal
              counsel and satisfy the FCRA and other legal requirements, including a clear
              and conspicuous disclosure in a document that consists solely of the disclosure;
              (4) I understand my obligations, have complied with, and will comply
              with all applicable laws pertaining to Consumer Reports/Investigative
              Consumer Reports as defined by the FCRA as amended; and (5) my use of the
              reports will comply with all applicable laws, including any applicable
              federal or state equal employment opportunity law or regulation,
              and the terms set forth in the `
              : `I certify that I will order and use the Reports only for a lawful
              and appropriate permissible purpose, as defined by applicable law
              (including but not limited to the FCRA) and will use Reports in
              accordance with all applicable law and the terms set forth in the `}
            <LinkStyled
              data-testid='checkr-services-agreement-link'
              href='https://checkr.com/customer-agreement'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleServiceAgreementClick}
            >
              Checkr, Inc. Services Agreement
            </LinkStyled>
            .
          </FooterTypography>
        }
      />
    </FooterStyled>
  );
};

export default Footer;
