import React, { useContext } from 'react';
import { BillingCustomer } from '../../types/Billing';

export type ContextType = {
  customerData: BillingCustomer | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  refetch: any;
};

const BillingCustomerContext = React.createContext<ContextType>({
  customerData: undefined,
  isLoading: false,
  isSuccess: false,
  error: null,
  refetch: undefined,
});

export default BillingCustomerContext;

export const useBillingCustomerContext = () =>
  useContext(BillingCustomerContext);
