import React from 'react';
import { FieldComponentProps } from '../types';

const EtaUpdated: React.FC<FieldComponentProps> = ({
  reportEstimationGenerated,
  testid,
}) => {
  return <div data-testid={testid}>{reportEstimationGenerated}</div>;
};

export default EtaUpdated;
