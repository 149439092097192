import Description from 'modules/assess/ui/common/entity/description';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { ID } from 'modules/id';
import React, { useCallback } from 'react';
import { useFetch } from './api';

type Props = {
  instance?: ID;
  version?: ID;
};

const Container: React.FC<Props> = ({ instance, version }) => {
  const query = useFetch(instance, version);
  const { data } = query;
  const disabled = useReadOnly() || !data?.draft;
  const value = data?.description || '';

  const handleSave = useCallback(() => {}, []);

  return (
    <Description
      data-testid='assess-mvr-category-set-version-description'
      editable={!disabled}
      value={value}
      onSave={handleSave}
    />
  );
};

export default Container;
