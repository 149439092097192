import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { FieldArray, useFormik, FormikProvider, FormikErrors } from 'formik';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import EmployeeValidations from './utils/employeeSchema';
import { useEmailRegistration } from './utils/emailRegistered';
import {
  FlexRowJustify,
  MButtonRowBottomAlign,
  MInput,
  DivMarginTop,
} from './style';
import { IEmployee } from './Models';
// import useSubmitEmployee from './hooks/useSubmitEmployee';

interface AddEmployeeModalProps {
  hideModal: () => void;
  open: boolean;
  onFormSubmit: (formValues: { employees: IEmployee[] }) => void;
  onFormCheckEmail: (value: any) => boolean;
}

const AddEmployeeModal = ({
  hideModal,
  open,
  onFormSubmit,
  onFormCheckEmail,
}: AddEmployeeModalProps) => {
  const initialValues = {
    employees: [
      {
        id: uuid(),
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
      } as IEmployee,
    ],
  };

  const { isEmailRegistered } = useEmailRegistration();
  const formik = useFormik({
    initialValues,
    validationSchema: EmployeeValidations(isEmailRegistered, onFormCheckEmail),
    onSubmit: values => {
      onFormSubmit(values);
    },
  });

  const { values, errors, touched, handleSubmit, handleChange } = formik;

  const show = (field: string, index: number): string => {
    const t = touched?.employees?.[index];
    const e = (errors?.employees?.[index] as FormikErrors<IEmployee>)?.[
      field as keyof IEmployee
    ];

    const duplicatedEmail = _.filter(
      _.uniq(
        _.map(values.employees, item => {
          if (_.filter(values.employees, { email: item.email }).length > 1) {
            return item.email;
          }
          return false;
        }),
      ),
    );

    if (
      field === 'email' &&
      _.includes(duplicatedEmail, values.employees[index].email)
    ) {
      return 'Duplicate email';
    }

    if (t && e) {
      return e;
    }
    return '';
  };

  const handleSaveClick = useCallback(
    args => {
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <M.ComposedModal type='large' onClose={hideModal} open={open}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>Add new employees</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div>
          <p>
            Add new employees who currently don&apos;t exist in Checkr. Enter
            the full legal name of your new employee(s).
          </p>
          <p>
            If the employee already exists in Checkr, go back to find and select
            their name.
          </p>
        </div>
        <DivMarginTop>
          <FormikProvider value={formik}>
            <FieldArray name='employees'>
              {({ insert, remove, push }) => (
                <div>
                  {values.employees.length > 0 &&
                    values.employees.map((employee, index) => (
                      <FlexRowJustify
                        gap='2rem'
                        alignItems='flex-start'
                        key={employee.id}
                        marginBottom='24px'
                      >
                        <MInput
                          id={`firstName-${employee.id}`}
                          name={`employees.${index}.firstName`}
                          labelText='First name'
                          onChange={handleChange}
                          invalid={!!show('firstName', index)}
                          invalidText={show('firstName', index)}
                          placeholder='Enter first name'
                        />
                        <MInput
                          id={`middleName-${employee.id}`}
                          name={`employees.${index}.middleName`}
                          labelText='Middle&nbsp;name&nbsp;(optional)'
                          onChange={handleChange}
                          invalid={!!show('middleName', index)}
                          invalidText={show('middleName', index)}
                          placeholder='Enter middle name'
                        />
                        <MInput
                          id={`lastName-${employee.id}`}
                          name={`employees.${index}.lastName`}
                          labelText='Last name'
                          onChange={handleChange}
                          invalid={!!show('lastName', index)}
                          invalidText={show('lastName', index)}
                          placeholder='Enter last name'
                        />
                        <MInput
                          id={`email-${employee.id}`}
                          name={`employees.${index}.email`}
                          labelText='Email address'
                          onChange={handleChange}
                          invalid={!!show('email', index)}
                          invalidText={show('email', index)}
                          placeholder='Enter email address'
                        />
                        {values.employees.length > 1 && (
                          <MButtonRowBottomAlign
                            kind='secondary'
                            size='sm'
                            data-id='i9-employee-delete-employee'
                            /* eslint-disable-next-line react/jsx-no-bind */
                            onClick={() => remove(index)}
                          >
                            <M.Icon icon='TrashCan' />
                          </MButtonRowBottomAlign>
                        )}
                      </FlexRowJustify>
                    ))}
                  <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
                    <M.Button
                      kind='secondary'
                      size='sm'
                      data-id='i9-employee-modal-add-another-employee'
                      /* eslint-disable-next-line react/jsx-no-bind */
                      onClick={() => {
                        const newEmployee = {} as IEmployee;
                        newEmployee.id = uuid();
                        push(newEmployee);
                      }}
                    >
                      <M.Icon icon='Add' size={16} />
                      &nbsp;&nbsp;Add another
                    </M.Button>
                  </FlexRowJustify>
                </div>
              )}
            </FieldArray>
          </FormikProvider>
        </DivMarginTop>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button type='reset' kind='secondary' onClick={hideModal}>
          Cancel
        </M.Button>
        <M.Button kind='primary' size='sm' onClick={handleSaveClick}>
          Save
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default AddEmployeeModal;
