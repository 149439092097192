/* eslint-disable react/jsx-no-bind */
import React, { forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { formatDate, Report as UtilReport } from '@dashboard-experience/utils';
import { Report } from 'types';
import { RefType } from 'components/Report/ReportInformation/types';

import { useReportInvitationStatusDecision } from '../utils';

type Props = {
  report: UtilReport;
  isSelected: boolean;
  onSelect: (selectedItem: any) => any;
};

const Option = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  padding: 8px 16px 8px 12px;

  border-radius: 2px;
  border: ${({ isSelected }) => (isSelected ? '2px solid #d1e4f8' : 'none')};
  outline: ${({ isSelected }) => (isSelected ? '2px solid #5399df' : 'none')};
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
`;

const DateTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: rgba(26, 32, 38, 0.78);
  font-size: 14px;
  line-height: 20px;

  overflow: hidden;
  white-space: nowrap;
`;

const AdjudicationLabel = styled.div`
  align-self: stretch;
  color: rgba(26, 32, 38, 0.65);
  font-size: 12px;
  line-height: 16px;

  overflow: hidden;
  white-space: nowrap;
`;

const StyledActionMenuItem = styled(M.ActionMenuItem)`
  .mastodon-action-menu-item-title {
    width: 100%;
  }

  .mastodon-action-menu-item-content {
    padding: 6px 0px 8px 7px !important;
  }
`;

const generateItemText = (
  report: Report,
  isSelected: boolean,
  status: string,
) => {
  return (
    <Option
      isSelected={isSelected}
      data-testid={`report-invitation-dropdown-item-${report.id}`}
    >
      <Labels>
        <DateTypeLabel>
          {formatDate(report.created_at || '', 'MMM D, YYYY')}
          <M.Icon icon='DotMark' size='8px' />
          <p style={{ maxWidth: '170px' }}>{report.package}</p>
        </DateTypeLabel>
        <AdjudicationLabel>
          {report.object == 'report'
            ? report.adjudication || 'No action taken'
            : 'Invitation'}
        </AdjudicationLabel>
      </Labels>
      <M.StatusIcon icon={status} />
    </Option>
  );
};

const ReportInvitationDropdownItem = forwardRef<RefType, Props>(
  ({ report, isSelected, onSelect }, ref) => {
    const handleClick = useCallback(() => {
      onSelect(report);
    }, [onSelect, report]);

    const status = useReportInvitationStatusDecision(report);
    // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
    const itemText = generateItemText(report, isSelected, status);

    // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
    // const archived = report?.archived || false;

    return <StyledActionMenuItem title={itemText} onClick={handleClick} />;
  },
);

ReportInvitationDropdownItem.displayName = 'ReportDropdownItem';

export default ReportInvitationDropdownItem;
