import React, { SyntheticEvent, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Entity } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';

export type Type = React.FC<Props>;

type Props = {
  version: Entity;
  onClick: (version: Entity) => void;
};

const StyledAnchor = styled.a`
  cursor: pointer;
`;

const StyledIndicator = styled(M.LivenessIndicator)`
  div {
    padding-left: 0.5rem;
  }
`;

export const PromoteLink: Type = ({ version, onClick }) => {
  const { t } = useTranslation('assess');

  const onPromoteHandler = useCallback(
    (e: SyntheticEvent) => onClick?.(version),
    [onClick, version],
  );

  const promoteText = t(
    'assess:mvr.category-sets.instance.versions.link.promote',
  );

  if (version.active) {
    return (
      <StyledIndicator
        live
        text={t('assess:mvr.category-sets.instance.versions.link.live')}
      />
    );
  }

  return <StyledAnchor onClick={onPromoteHandler}>{promoteText}</StyledAnchor>;
};

export default PromoteLink;
