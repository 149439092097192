import moment from 'moment';
import { Client } from 'api';

/**
 * @name track
 * @function
 * @memberOf UserActions
 * @description Format a user actions event and send to api
 * @returns {promise}
 * @param {string} endpointPath - The path of the endpoint
 * @param {object} - The event object
 */

const track = (
  endpointPath: string,
  payload: object,
): Promise<{ [key: string]: any }> => {
  const utcMoment = moment.utc();
  const utcDate = new Date(utcMoment.format());
  const body = {
    time: utcDate,
    ...payload,
  };

  return Client.post(endpointPath, body);
};

/**
 * @name callUserActions
 * @function
 * @memberOf UserActions
 * @description Determines and formats path and calls the track method
 * @returns {promise}
 * @param {string} externalId - The id of the report
 * @param {string} externalType - The type of action
 * @param {object} eventProperties - The properties of the event to be tracked
 */

const callUserActions = (
  externalId: string,
  externalType: string,
  eventProperties: object,
) => {
  let pathPart1;
  if (externalType === 'report') {
    pathPart1 = `reports/${externalId}`;
  }
  const path = `${pathPart1}/timeline/user_actions`;
  track(path, eventProperties);
};

export { callUserActions, track };
