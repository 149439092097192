import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  rowMetaData: {
    isExpanded: boolean;
    id: number;
  };
  expandRow: Function;
};
const InvoicesSeeMoreFilesCell: React.FC<Props> = ({
  rowMetaData,
  expandRow,
}) => {
  const toggleExpandRow = useCallback(() => {
    expandRow(rowMetaData.id);
  }, [expandRow, rowMetaData.id]);

  return (
    <M.TableCell style={{ width: '15%' }}>
      <M.Link onClick={toggleExpandRow} href='#'>
        {rowMetaData.isExpanded ? (
          <div>
            <span>hide files</span>
            <M.Icon icon='ChevronUp' height='12' width='12' />
          </div>
        ) : (
          <div>
            <span>see more files</span>
            <M.Icon icon='ChevronRight' height='12' width='12' />
          </div>
        )}
      </M.Link>
    </M.TableCell>
  );
};

export default InvoicesSeeMoreFilesCell;
