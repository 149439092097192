import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Tooltip } from './common';

const Container = styled.div`
  color: ${colors.brandNavy4};
  max-width: 40%;
  margin-bottom: 1rem;
`;

const Description = styled.div`
  width: 460px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Header: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('standard.guidelines.lookback_period.header', {
    returnObjects: true,
  });
  const basePath = 'assess-v2-ui-guideline-lookback-period-header';
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;
  return (
    <Container data-testid={basePath}>
      <Title data-testid={titleId}>{translations.title}</Title>
      <Description data-testid={descriptionId}>
        <Trans
          t={t}
          i18nKey='standard.guidelines.lookback_period.header.description'
        >
          <Tooltip alignment='bottom' />
        </Trans>
      </Description>
    </Container>
  );
};

export default Header;
