const SCREENINGS: string[] = [
  'international_criminal_searches',
  'international_adverse_media_searches',
  'international_canada_bc_enhanced_criminal_search',
  'international_criminal_searches_v2',
  'international_education_verification',
  'international_employment_verification',
  'international_identity_document_validation',
  'international_global_watchlist_search',
  'international_motor_vehicle_reports',
];

const DELAYED_SCREENINGS_COUNTRY_CODES: string[] = ['AF'];
const DELAYED_SCREENINGS_COUNTRY_NAMES: string[] = ['Afghanistan'];

const combineDelayedScreeningCountryNames = () => {
  return DELAYED_SCREENINGS_COUNTRY_NAMES.join(', ').replace(
    /,(?=[^,]*$)/,
    ' and',
  );
};

export {
  SCREENINGS,
  DELAYED_SCREENINGS_COUNTRY_CODES,
  DELAYED_SCREENINGS_COUNTRY_NAMES,
  combineDelayedScreeningCountryNames,
};
