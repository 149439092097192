import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { ID } from 'modules/id';
import { Status } from 'modules/assess/models/insights';
import * as hooks from './hooks';
import Description from './Footer';
import ResultsGraphs from './ResultsGraphs';
import ResultsStatus from './ResultsStatus';
import ResultsSummary from './ResultsSummary';

type Props = {
  version?: ID;
};

export const Content: React.FC<Props> = ({ version }) => {
  const query = hooks.useFetch(version);
  const { data, refetch } = query;
  const loading = query.status === 'loading';
  const pending = data?.status === Status.PENDING;

  useEffect((): void | (() => void) => {
    if (!pending) {
      return;
    }
    const timer = setInterval(() => refetch(), 10000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(timer);
  }, [pending, refetch]);

  if (loading) {
    return <Loading withOverlay={false} />;
  }

  return (
    <>
      <ResultsStatus status={data?.status} />
      <ResultsSummary active={data?.active} draft={data?.draft} />
      <ResultsGraphs active={data?.active} draft={data?.draft} />
      <Description data={data} />
    </>
  );
};

const Loading = styled(M.LoadingSpinner)`
  margin: auto;
`;

export default Content;
