/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffectAfterInitialized } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import pick from 'lodash/pick';

import * as API from 'api';
import { updateSearchParams } from 'actions/SearchActions';
import { removeRestrictedKeys } from 'api/savedSearches';
import { GenericObject } from 'types';
import {
  EXPERIMENTAL_SEARCH_FILTER_UI_FLAG_KEY,
  SAVED_SEARCH_API_BASE_URL,
} from 'Constants';

import {
  getInitialGeos,
  getInitialPackages,
  getInitialTags,
  getInitialSavedSearchId,
} from './helpers';
import { useSearchState } from '../search-context';

export const useReduxState = () =>
  useSelector(
    (state: any) => ({
      searchParams: state.search?.searchParams,
      contextId: state.ui?.contextId,
      candidates: state.search?.candidates,
      isLoading: state.search?.fetchCandidatesRequest?.processing,
    }),
    shallowEqual,
  );

export const useFilters = (account: { [x: string]: any }) => {
  const accountId = account?.id;
  const { searchParams } = useReduxState();

  const geoRes = API.geos.useList({ accountId });
  const geos = geoRes?.data?.geos;
  const geosLoading = geoRes?.isLoading;

  const programsRes = API.programs.useAllPrograms(account.segmentation_enabled);
  const programs = programsRes?.data;
  const programsLoading = programsRes?.isLoading;

  const enableSavedSearches =
    useFlag(EXPERIMENTAL_SEARCH_FILTER_UI_FLAG_KEY)?.variant_key === 'on' &&
    !!SAVED_SEARCH_API_BASE_URL &&
    SAVED_SEARCH_API_BASE_URL?.length > 0;

  const savedSearchesRes = API.savedSearches.useList(enableSavedSearches);
  const savedSearches = useMemo(
    () =>
      savedSearchesRes?.data?.searches?.map((search: GenericObject) => {
        const { params } = search;
        return {
          ...search,
          params: removeRestrictedKeys(params),
        };
      }),
    [savedSearchesRes?.data],
  );
  const savedSearchesLoading = savedSearchesRes?.isLoading;

  const packagesRes = API.packages.useList({
    accountId,
    params: { with_cc: true },
    useCache: true,
  });
  const packages = packagesRes?.data as any;
  const packagesLoading = packagesRes?.isLoading;

  const advancedFiltersLoading =
    geosLoading || programsLoading || packagesLoading;

  // async dependent params
  const defaultGeos = useUpdateDefaultParams({
    success: geoRes?.isSuccess,
    searchParam: searchParams.geo_ids,
    options: geos,
    getInitialParams: getInitialGeos,
  });

  const defaultPackages = useUpdateDefaultParams({
    success: packagesRes?.isSuccess,
    searchParam: searchParams.packages,
    options: packages,
    getInitialParams: getInitialPackages,
  });

  const defaultSavedSearch = useUpdateDefaultParams({
    success: savedSearchesRes?.isSuccess,
    searchParam: [searchParams.saved_search_id],
    options: savedSearches,
    getInitialParams: getInitialSavedSearchId,
  });

  return {
    geos,
    geosLoading,
    defaultGeos,
    packages,
    packagesLoading,
    defaultPackages,
    programs,
    programsLoading,
    advancedFiltersLoading,
    savedSearches,
    savedSearchesLoading,
    defaultSavedSearch,
  };
};

export const useDefaultValue = (param: string) => {
  const { searchParams } = useReduxState();
  return searchParams[param];
};

export const useUpdateDefaultParams = ({
  success,
  searchParam,
  options,
  getInitialParams,
}: {
  [x: string]: any;
}) => {
  const [state, setState] = useState();
  useEffect(() => {
    if (!success) return;

    if (!state) {
      const newDefaultState = getInitialParams(searchParam, options);
      setState(newDefaultState);
    }
  }, [options, setState, success, searchParam, state, getInitialParams]);

  return state;
};

export const useGetFilterState = (param: string) => {
  const dispatch = useDispatch();
  const { filters, setFilters } = useSearchState();
  const state = filters[param];

  return { state, dispatch, setFilters };
};

export const useGetFilterStates = (params: Array<string>) => {
  const dispatch = useDispatch();
  const { filters, setFilters } = useSearchState();
  // Do not include the `params` array in the list of dependencies.
  // This causes an infinite render loop if the array object is reconstructed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const states = useMemo(() => pick(filters, params), [filters, ...params]);
  return { states, dispatch, setFilters };
};

export const useBaseFilter = (param: string, ref: any) => {
  const { state, dispatch, setFilters } = useGetFilterState(param);

  useEffectAfterInitialized(() => {
    if (state !== undefined) {
      dispatch(updateSearchParams({ [param]: state }));
    } else if (state === undefined) {
      ref.current.value = '';
    }
  }, [state, param, dispatch]);

  return { state, setFilters };
};

export const useBaseFilters = (
  params: Array<string>,
  ref: any,
  filterName: string,
) => {
  const { states, dispatch, setFilters } = useGetFilterStates(params);

  const filterState = states[filterName];

  useEffectAfterInitialized(() => {
    if (filterState !== undefined) {
      dispatch(updateSearchParams(states));
    } else if (filterState === undefined) {
      ref.current.value = '';
    }
  }, [filterState, states, dispatch]);

  return { states, setFilters };
};

export const useMultiSelectFilter = (param: string, ref: any) => {
  const { state, dispatch, setFilters } = useGetFilterState(param);

  useEffectAfterInitialized(() => {
    if (state !== undefined) {
      dispatch(updateSearchParams({ [param]: state }));
    } else if (state === undefined) {
      ref?.current?.clearAll();
    }
  }, [state, param, dispatch]);

  return { state, setFilters };
};

type UseTagsProps = {
  accountId: string;
  touched: boolean;
};

type UseLimitedTagsProps = {
  selectedTags: any;
  result?: {
    data: any;
    isLoading: boolean;
    isSuccess: boolean;
  };
};

export const useTags = ({ accountId, touched }: UseTagsProps) => {
  const { searchParams } = useReduxState();

  const tagsRes = API.accounts.useTags({ accountId, touched });
  const tags = tagsRes?.data?.tags;
  const tagsLoading = tagsRes?.isLoading;

  const defaultTags: any = useUpdateDefaultParams({
    success: tagsRes?.isSuccess,
    // TODO revisit missing param
    searchParam: searchParams.tags,
    options: tags,
    getInitialParams: getInitialTags,
  });

  return {
    tags,
    tagsLoading,
    defaultTags,
  };
};

export const useGetLimitedTags = ({
  result,
  selectedTags,
}: UseLimitedTagsProps) => {
  const { searchParams } = useReduxState();
  const tagsRes = result;

  const tags = tagsRes?.data?.tags;
  const tagsLoading = tagsRes?.isLoading;

  const newTags = Array.from(new Set(tags?.concat(selectedTags || [])));

  const defaultTags: any = useUpdateDefaultParams({
    success: tagsRes?.isSuccess,
    // TODO revisit missing param
    searchParam: searchParams.tags,
    options: newTags,
    getInitialParams: getInitialTags,
  });

  return {
    tags,
    tagsLoading,
    defaultTags,
  };
};
