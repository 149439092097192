import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import CourtType from './CourtType';

const COURT_TYPES = [
  {
    slug: 'clerk_assisted_county',
    title: 'Clerk Assisted County',
    subtitle: 'Slowest',
    icon: 'Book',
    explanation:
      'Records are available at the courthouse, and require the presence of a court clerk.',
  },
  {
    slug: 'in_court_researcher',
    title: 'In Person',
    icon: 'User',
    explanation:
      'Records are available at the courthouse, and can be accessed directly.',
  },
  {
    slug: 'electronic_records_available',
    title: 'In Person / Online',
    subtitle: 'Fastest',
    icon: 'Flash',
    explanation:
      'Records are available at the courthouse, and can be accessed directly. Records may be available online, giving access at any time making this process faster.',
  },
];

const TooltipContainer = styled.div`
  #mastodon & {
    display: flex;
    .eta-court-type-tooltip {
      .cds--definition-tooltip {
        max-width: 25rem;
        padding: 1rem;
        color: ${colors.uiTextPrimaryLight};
        font-size: 14px;
      }

      .mastodon-icon {
        fill: ${colors.uiTextPrimaryLight};
      }

      .court-type.selected {
        .mastodon-icon {
          fill: ${colors.uiNavy600};
        }
        .court-title {
          color: ${colors.uiNavy600};
        }
      }

      .court-type:not(:last-child) {
        margin-bottom: 1rem;
      }

      .cds--definition-term:hover svg {
        fill: ${colors.uiGrey700};
      }
    }
  }
`;

const CourtTypeTooltip: React.FC<{ courtType: string }> = ({ courtType }) => {
  const currentType = COURT_TYPES.filter(type => courtType === type.slug)[0];

  const tooltipContent = COURT_TYPES.map(type => (
    <CourtType type={type} key={type.slug} selected={courtType === type.slug} />
  ));

  return (
    <TooltipContainer data-floating-menu-container>
      <M.TooltipLabel>{currentType.title}</M.TooltipLabel>
      <M.TooltipDefinition
        className='eta-court-type-tooltip'
        align='top'
        highlighted={false}
        openOnHover={false}
        definition={tooltipContent}
      >
        <M.Icon icon='InformationFilled' />
      </M.TooltipDefinition>
    </TooltipContainer>
  );
};

export default CourtTypeTooltip;
