export type Item = {
  id: string;
  name: string;
  description?: string;
  parent?: Item['id'];
  children?: Array<Item>;
};

export type ItemList = Array<Item>;
export type ItemMap = { [key: string]: Item };

export const children = (items: ItemList | ItemMap): Array<Item> => {
  const list = Object.values(items);
  return list.flatMap(
    parent =>
      parent.children?.map(child => ({ ...child, parent: parent.id })) || [],
  );
};
