/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const KeyValueList = styled(M.KeyValueList)<{ withPadding?: boolean }>`
  #mastodon && {
    ${({ withPadding }) =>
      withPadding &&
      css`
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      `}

    .mastodon-key-value-line-item:not(:last-child) {
      .key,
      .value {
        padding-bottom: 0.75rem;
      }
    }
    .key {
      min-width: 8rem;
    }
    .value {
      width: 100%;
      max-width: 14rem;
      overflow-wrap: break-word;
    }
  }
`;

export const ExpandablePanel = styled(M.ExpandablePanel)`
  .expandable-panel-content-body {
    padding: 1.5rem;
  }
`;
