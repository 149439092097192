import styled from 'styled-components';
import { Screening as ScreeningT } from 'types';
import { M, colors } from '@dashboard-experience/mastodon';
import React from 'react';
import LearnMoreLink from './LearnMoreLink';

export const Toggletip = styled(M.Toggletip)`
  #mastodon && {
    margin-left: 0.25rem;
    margin-top: 0.275rem;
    .mastodon-toggletip-content {
      max-width: 18rem;
    }
    .mastodon-toggletip-button > svg {
      fill: ${colors.uiNavy600};
    }
  }
`;

export const Icon = styled.img`
  padding: 6px;
  border-radius: 25px;
  background: ${colors.brandSlate2};
  margin: 2px 16px 0 0;
`;

export const AccordionItem = styled(M.AccordionItem)`
  #mastodon && {
    &.cds--accordion__item {
      .cds--accordion__arrow {
        transform: rotate(-270deg) scale(1.5) !important;
        margin: 8px 24px 0px 0px !important;
      }
    }

    &.cds--accordion__item--active {
      .cds--accordion__arrow {
        transform: rotate(-90deg) scale(1.5) !important;
      }
    }
  }

  .mastodon-screening-row {
    padding-left: 5px !important;
  }

  border-left: 2px solid ${colors.brandNavy1};
  border-right: 1px solid ${colors.uiGrey200};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${colors.uiGrey0};

  @media (min-width: 640px) {
    .cds--accordion__content {
      padding-right: 32px;
    }
  }
  .cds--accordion__heading {
    height: 64px;
    &:hover ${Icon} {
      background: ${colors.uiGrey0};
    }
  }

  @media (max-device-width: 530px) {
    .cds--accordion__content {
      padding-left: 8px !important;
    }
  }
`;

export const Screening = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px 16px 16px 0px;

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.uiGrey200};
  }

  #professional-license-verification-id {
    width: 255px !important;

    @media (max-device-width: 400px) {
      width: 125px !important;
    }
  }
`;

export const ExpandableTitle = styled(M.Expandable)`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: regular;
  color: ${colors.brandNavy3};
  width: 50%;

  .expandable-content {
    width: calc(100% + 100px) !important;
    margin-top: 16px;
  }
`;

export const AddButtonContainer = styled.div`
  flex: 0 0 100px;
  display: flex;
  min-width: 100px;
  justify-content: flex-end;
  margin-left: 34px;

  @media (max-device-width: 530px) {
    margin-left: 16px;
  }

  :has(#delete-trash-can) {
    @media (max-device-width: 530px) {
      margin-left: -30px;
    }
  }
`;

export const SetupButtonContainer = styled.div`
  flex: 0 0 100px;
  display: flex;
  min-width: 100px;
  justify-content: flex-end;
  margin-left: 34px;

  @media (max-device-width: 530px) {
    margin-left: 16px;
  }
`;

export const SetupPendingContainer = styled.div`
  color: ${colors.brandSlate5};
  padding-top: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  vertical-align: middle;
`;

export const SetupPendingIcon = styled(M.Icon)`
  padding-top: 5px;
`;

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.brandNavy3};
  margin-top: 3px;
  @media (max-device-width: 530px) {
    margin-top: 14px;
    font-size: 12px;
    .screening-icon {
      display: none;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexContainer = styled(Flex)`
  padding: 16px 24px 16px 0px;

  .mastodon-expandable:not(.expanded):hover {
    border-bottom: none !important;
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.uiGrey200};
  }

  #professional-license-verification-id {
    width: 255px !important;

    @media (max-device-width: 400px) {
      width: 125px !important;
    }
  }
`;

export const FlexContainerNoBorder = styled(Flex)`
  padding: 16px 24px 16px 0px;

  .mastodon-expandable:not(.expanded):hover {
    border-bottom: none !important;
  }

  #professional-license-verification-id {
    width: 255px !important;

    @media (max-device-width: 400px) {
      width: 125px !important;
    }
  }
`;

export const JustifyContentSpaceBetween = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ScreeningDescriptionContainer = styled(JustifyContentSpaceBetween)`
  margin-bottom: 10px;
`;

export const Box = styled.div`
  width: 50%;
  min-height: 100px;
  background: ${colors.brandSlate1};
  border-radius: 8px;
  padding: 16px;
  align-self: stretch;
`;

export const OnlyAdvantagesBox = styled.div`
  width: 100%;
  min-height: 100px;
  background: ${colors.brandSlate1};
  border-radius: 8px;
  padding: 16px;
  align-self: stretch;
`;

export const BoxDivider = styled.div`
  width: 20px;
`;

export const Price = styled.div`
  text-align: right;
  color: ${colors.brandNavy3};
  flex-basis: 76px;
  margin-right: 40px;

  @media (max-device-width: 530px) {
    flex: 1 100%;
    text-align: left;
    margin: 4px 0px 16px 32px;
  }
`;

export const ExpandTitle = styled.div`
  flex: 1 1 0%;
  text-align: left;
`;

export const PendingSetupTag = styled(M.Tag)`
  background: ${colors.brandYellow2};
  margin-left: 8px !important;
  border-radius: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const ScreeningInfo = styled.p`
  max-width: 472px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brandSlate6} !important;
`;

export const TurnaroundTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: medium;
  width: 80px;
  height: 22px;
  color: ${colors.brandSlate6} !important;
  background: ${colors.uiNavy50};
  border-radius: 4px;
  font-size: 12px;
`;

export const TimeIcon = styled(M.Icon)`
  color: ${colors.brandSlate6};
  margin-right: 3px;
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  width: 66px;
  height: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brandNavy3};
  margin-bottom: 8px;
`;

export const Checkbox = styled(M.Checkbox)`
  margin-left: 28px;
`;
export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const ContainerWithoutBorder = styled.div`
  margin-top: -15px;
  padding-bottom: 16px;

  .cds--form-item {
    flex: none !important;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.uiNavy600};
  text-decoration: underline;
`;

export const Container = styled.div`
  margin-top: -15px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.uiGrey200};

  .cds--form-item {
    flex: none !important;
  }
`;

export const List = styled.div`
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${colors.brandSlate6} !important;

  padding-top: 3px;
`;

const BulletContainer = styled.div`
  line-height: 14px !important;
  min-height: 20px;
`;

const Bullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${colors.brandAqua3};
  margin-right: 8px;
  margin-top: 8px;
`;

export const BulletPoint = ({ text }: { text: string }) => {
  return (
    <Flex>
      <BulletContainer>
        <Bullet />
      </BulletContainer>
      <List>{text}</List>
    </Flex>
  );
};

export const getPriceVaries = (priceByType: any) => {
  if (priceByType === 'international_criminal_search_v2') {
    return (
      <LearnMoreLink
        linkText='Price Varies'
        href='https://checkr.com/pricing/international'
      />
    );
  }

  return 'Price Varies';
};

export const Title: React.FC<any> = ({
  getPriceByType,
  title,
  priceByType,
  priceVaries = false,
}) => {
  return (
    <JustifyContentSpaceBetween>
      <ExpandTitle>{title}</ExpandTitle>
      <Price data-testid={`${priceByType?.replace('_', '-')}-price-id`}>
        {priceVaries
          ? getPriceVaries(priceByType)
          : getPriceByType(priceByType, '7years')}
      </Price>
    </JustifyContentSpaceBetween>
  );
};

export const TitleWithTag: React.FC<any> = ({
  getPriceByType,
  title,
  priceByType,
  priceVaries = false,
  setupStatus,
}) => {
  return (
    <JustifyContentSpaceBetween>
      <ExpandTitle>
        {title}
        {setupStatus === 'disabled' ? (
          <PendingSetupTag>Pending setup</PendingSetupTag>
        ) : null}
      </ExpandTitle>
      <Price data-testid={`${priceByType?.replace('_', '-')}-price-id`}>
        {priceVaries
          ? getPriceVaries(priceByType)
          : getPriceByType(priceByType)}
      </Price>
    </JustifyContentSpaceBetween>
  );
};

export const InternationalTitle: React.FC<any> = ({ title }) => {
  return (
    <JustifyContentSpaceBetween>
      <ExpandTitle>{title}</ExpandTitle>
    </JustifyContentSpaceBetween>
  );
};

export const TurnAroundTime = () => {
  return (
    <TurnaroundTime>
      <TimeIcon icon='Time' size='16' />
      1-2 days
    </TurnaroundTime>
  );
};

export const BoldInfoText = styled.span`
  font-weight: 700;
`;

const ThirdPartyFeeParagraph = styled.p`
  color: ${colors.brandSlate6} !important;
  margin-bottom: 4px !important;
`;

export const ThirdPartyTooltip = () => {
  return (
    <M.ToggletipContent>
      <ThirdPartyFeeParagraph>
        Third-party fees may include withholding, sales, value added or other
        taxes, duties or charges applicable to service fees. Some counties and
        all state DMVs also charge fixed fees to retrieve criminal or driving
        records, while state courts have additional fixed fees to retrieve
        criminal records.{' '}
      </ThirdPartyFeeParagraph>
      <M.Link
        href='https://checkr.com/pricing/passthrough-fees'
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </M.Link>
    </M.ToggletipContent>
  );
};

export type ActionPropsProps = {
  onAddClick: Function;
  onRemoveClick: Function;
  disabledScreenings: string[];
  addedScreenings: string[];
  previouslyOrderedScreenings?: string[];
  basePackageScreenings?: ScreeningT[];
};

export type ScreeningProps = {
  shouldOpen: boolean;
  getPriceByType: (screeningType: string) => void;
  actionProps: ActionPropsProps;
};

export type AccordionItemProps = {
  screeningTypesInSearch: string[];
  getPriceByType: (screeningType: string) => void;
  actionProps: ActionPropsProps;
};
