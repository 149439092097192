import React, { useCallback, useContext, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import styled from 'styled-components';
import { PR_US_STATE_KEY, WASHINGTON_CONSENT_FLAG_KEY } from 'Constants';
import { Context } from 'components/Invitations';
import { debug } from 'utils';
import { GenericObject } from 'types';
import { useCandidate } from 'providers/Candidate';
import {
  NewCandidateInvitationParams,
  useNewCandidateInvitation,
} from 'api/invitations';

const Icon = styled(M.Icon)`
  margin-right: 0.5rem;
`;

const Button = styled(M.Button)`
  max-width: unset;
`;

export const getInvitationParams = ({
  candidateId,
  selection,
}: GenericObject) => {
  debug(candidateId, selection);

  if (!selection || !selection.package) return undefined;

  const {
    node,
    // geo,
    // program
    country,
    city,
    state,
    package: pkg,
    purpose,
  } = selection;

  const data: NewCandidateInvitationParams = {
    candidate_id: candidateId,
    package: pkg.slug,
  };

  // if (geo && geo.id !== 'None') data.geo_ids = [geo.id];
  if (node) data.node = node.custom_id;

  const workLocation: GenericObject = {};
  if (country) workLocation.country = country;
  if (state) workLocation.state = state.abbreviation;
  if (city) workLocation.city = city.name;
  if (country || city || state) data.work_locations = [workLocation];
  if (purpose) data.permissible_purpose = purpose;

  return data;
};

const NewCandidateInvitation = () => {
  const {
    account,
    geos,
    isSingleNode,
    nodes,
    selectedNode,
    nodeIsLoading,
    nodePackages,
    onNodeChange,
    onNodeFilterChange,
    packages,
    // dashboard does not allow selecting programs, so preserve functionality
    // programs,
    anyLoading,
  } = useContext(Context);
  const candidate = useCandidate();
  const { id: candidateId } = candidate;

  const { call, result } = useNewCandidateInvitation();

  const [selection, setSelection] = useState(undefined);
  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;
  const washingtonConsentEnabled =
    useFlag(WASHINGTON_CONSENT_FLAG_KEY)?.variantKey === 'true' || false;

  const invite = useCallback(() => {
    if (!selection) return;
    const params = getInvitationParams({ candidateId, selection });
    if (!params) return;
    call(params);
  }, [selection, call, candidateId]);

  return anyLoading ? (
    <M.Container>
      <h5 className='mb-0'>Invite candidate</h5>
      <M.LoadingInline description='Loading packages...' />
    </M.Container>
  ) : (
    <M.PackageOrder
      container='default'
      title='Invite candidate'
      id='new-candidate-invitation'
      onChange={setSelection}
      nodes={nodes}
      geos={geos}
      hideGeos
      hidePrograms
      packages={packages}
      nodePackages={nodePackages}
      nodeIsLoading={nodeIsLoading}
      isSingleNode={isSingleNode}
      selectedNode={selectedNode}
      onNodeChange={onNodeChange}
      onNodeFilterChange={onNodeFilterChange}
      account={account}
      workLocationSelectProps={{ withPr }}
      isWashingtonConsentEnabled={washingtonConsentEnabled}
      alwaysShowPurpose
      alwaysShowCertification
    >
      <M.Container.Row>
        <M.Container.Col>
          {selection &&
            (result.isLoading ? (
              <M.LoadingInline description='Inviting...' />
            ) : (
              <Button kind='primary' onClick={invite}>
                <Icon icon='Add' size='24' />
                Invite candidate
              </Button>
            ))}
        </M.Container.Col>
      </M.Container.Row>
    </M.PackageOrder>
  );
};

export default NewCandidateInvitation;
