/* eslint-disable import/prefer-default-export */
import { ADDON_SKUS } from 'components/AddScreenings/shared/screeningPayloads';

function convertStringToCents(valueString: string) {
  return parseFloat(valueString) * 100;
}
type UpdatedItemType = {
  sku: {
    id: string;
    name: string;
    revenue_category_id: string;
    pricing_type: string;
    charge_type: string;
    recurrence_frequency: string;
    deactivated_at: null | string;
  };
  amount: Number;
  effective_start_at: {
    seconds: Number;
    nanos: Number;
  };
  currency_amount: string;
  currency_iso_code: string;
  maximum_allowed_employers: Number | null;
  lookback_years: Number | null;
  required_quantity: [Boolean, string] | null;
  subtype: string | null;
  product_type: string | null;
  base_price: Number;
};

const handleEmploymentVerificationMapping = (updatedItem: UpdatedItemType) => {
  updatedItem.maximum_allowed_employers = null;
  if (updatedItem.sku.id.includes('-C')) {
    updatedItem.lookback_years = 10;
    updatedItem.maximum_allowed_employers = 1;
  }
  if (updatedItem.sku.id.includes('-3')) {
    updatedItem.lookback_years = 3;
  }
  if (updatedItem.sku.id.includes('-5')) {
    updatedItem.lookback_years = 5;
  }
  if (updatedItem.sku.id.includes('-7')) {
    updatedItem.lookback_years = 7;
  }
  if (updatedItem.sku.id.includes('-10')) {
    updatedItem.lookback_years = 10;
  }
};

const handlePersonalReferenceVerificationMapping = (
  updatedItem: UpdatedItemType,
) => {
  if (updatedItem.sku.id.includes('-3')) {
    updatedItem.required_quantity = [true, '3'];
  } else if (updatedItem.sku.id.includes('-2')) {
    updatedItem.required_quantity = [true, '2'];
  } else {
    updatedItem.required_quantity = [true, '1'];
  }
};

const handleProfessionalReferenceVerificationMapping = (
  updatedItem: UpdatedItemType,
) => {
  if (updatedItem.sku.id.includes('-3')) {
    updatedItem.required_quantity = [true, '3'];
  } else if (updatedItem.sku.id.includes('-2')) {
    updatedItem.required_quantity = [true, '2'];
  } else {
    updatedItem.required_quantity = [true, '1'];
  }
};

const handleFacisSearchMapping = (updatedItem: UpdatedItemType) => {
  if (updatedItem.sku.id.includes('-3')) {
    updatedItem.subtype = '3';
  }
  if (updatedItem.sku.id.includes('-1')) {
    updatedItem.subtype = '1';
  }
};

const handleCountyCriminalSearchMapping = (updatedItem: UpdatedItemType) => {
  if (updatedItem.sku.id.includes('-C')) {
    updatedItem.subtype = 'current';
  }
};

const handleStateCriminalSearchMapping = (updatedItem: UpdatedItemType) => {
  if (updatedItem.sku.id.includes('-C')) {
    updatedItem.subtype = 'current';
  }
  if (updatedItem.sku.id.includes('7-7')) {
    updatedItem.subtype = '7years';
  }
};

export const mapAddonSkus = (skuEntitiesQuery: any): any => {
  if (!skuEntitiesQuery || !skuEntitiesQuery.data) {
    return [];
  }
  return skuEntitiesQuery.data?.map((item: UpdatedItemType) => {
    if (
      typeof item !== 'object' ||
      !item.sku ||
      !item.sku.id ||
      !item.currency_amount
    ) {
      // eslint-disable-next-line no-console
      console.error('Unexpected sku entity entry, missing fields', item);
      throw new Error('Unexpected sku entity entry, missing fields');
    }
    const updatedItem = { ...item };
    updatedItem.product_type = ADDON_SKUS[updatedItem.sku.id]?.product_type;
    updatedItem.base_price = convertStringToCents(updatedItem.currency_amount);
    switch (updatedItem.product_type) {
      case 'employment_verification':
        handleEmploymentVerificationMapping(updatedItem);
        break;
      case 'personal_reference_verification':
        handlePersonalReferenceVerificationMapping(updatedItem);
        break;
      case 'professional_reference_verification':
        handleProfessionalReferenceVerificationMapping(updatedItem);
        break;
      case 'facis_search':
        handleFacisSearchMapping(updatedItem);
        break;
      case 'county_criminal_search':
        handleCountyCriminalSearchMapping(updatedItem);
        break;
      case 'state_criminal_search':
        handleStateCriminalSearchMapping(updatedItem);
        break;
      default:
        break;
    }
    return updatedItem;
  });
};
