import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { PremiumUpgrade } from 'modules/assess/v2/ui/common';

const Container = styled.div`
  align-items: center;
  background-color: ${colors.brandSlate1};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding: 20px;
`;

const Text = styled.span`
  color: ${colors.uiTextPrimaryLight};
  font-size: 12px;
  line-height: 130%;
`;

const StyledPremiumUpgrade = styled(PremiumUpgrade)`
  font-size: 12px;
  line-height: 18px;
  width: 100%;
`;

const Upgrade: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const header: string = t('common.lookback_rules.subcategory.charges.upgrade');

  return (
    <Container>
      <Text data-testid='assess-v2-ui-common-lookback-rules-subcategory-charges-upgrade-header'>
        {header}
      </Text>
      <StyledPremiumUpgrade eventProperty={LookbackPeriod.Page} />
    </Container>
  );
};

export default Upgrade;
