import { AnyQueryKey, useQuery } from 'react-query';

import { list, getAllPrograms, BaseParams } from './actions';

// This retrieves a full list of Programs with details
export const useList = (params: BaseParams) => {
  const key: AnyQueryKey = ['programs/list', { id: undefined }, params];

  const request = () => list(params);

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useAllPrograms = (segmentationEnabled: boolean = false): any => {
  const key: AnyQueryKey = ['programs/all-programs', { id: undefined }];

  const request = () => getAllPrograms([{ id: 'none', name: 'None' }]);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !segmentationEnabled,
  });
};
