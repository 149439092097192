import { useUser } from 'context/CurrentUser';
import React, { ReactNode, useEffect } from 'react';
import { setUserProperties } from 'utils';
import { getExperiment } from './amplitudeExperiment';

// This wrapper starts the amplitude experiment in the current flow
const AmplitudeExperimentWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const currentUser = useUser();
  const userProperties = setUserProperties(currentUser);

  useEffect(() => {
    const fetchAmplitudeExperiment = async () => {
      try {
        const experiment = await getExperiment();
        if (experiment) await experiment.fetch(userProperties);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    fetchAmplitudeExperiment();
  }, []);

  return <>{children}</>;
};

export default AmplitudeExperimentWrapper;
