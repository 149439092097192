import { toastError, toastSuccess } from 'actions';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Geo } from 'types';

import { list, create, ListParams, update, deleteGeo } from './actions';

// Get a paginated list of Geos
export const useList = (params: ListParams) => {
  const key: AnyQueryKey = ['geos/list', { id: undefined }, params];

  const request = () => list(params);

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

// Create a new Geo
export const useCreateGeo = () => {
  const dispatch = useDispatch();
  const accountId = useUserAccount()?.id;

  const request = (geo: any) => create({ accountId, geo });

  const [createGeoCall, createGeoResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess('Success:', 'Geo created'));
    },
  });

  return {
    createGeoCall,
    createGeoResult,
  };
};

// Submit new details to save to an existing Geo
export const useUpdateGeo = () => {
  const dispatch = useDispatch();
  const accountId = useUserAccount()?.id;

  const request = (geo: Geo) => update({ accountId, geo });

  const [updateGeoCall, updateGeoResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess('Success:', 'Geo updated'));
    },
  });

  return {
    updateGeoCall,
    updateGeoResult,
  };
};

// Delete an existing Geo
export const useDeleteGeo = () => {
  const dispatch = useDispatch();
  const accountId = useUserAccount()?.id;

  const request = (geoId: string) => deleteGeo({ accountId, geoId });

  const [deleteGeoCall, deleteGeoResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess('Success:', 'Geo deleted'));
    },
  });

  return {
    deleteGeoCall,
    deleteGeoResult,
  };
};
