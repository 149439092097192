import React from 'react';
import styled from 'styled-components';
import { Field as Common } from 'modules/assess/models/rulesets/version-diff/common';
import { isFieldUpdated } from 'modules/assess/models/rulesets/version-diff/common/field';
import FieldChanged from './FieldChanged';
import { FieldStyle } from './FieldStyle';
import FieldUnchanged from './FieldUnchanged';

type Props = {
  field?: Common.Type;
  fieldStyle?: FieldStyle;
  includeName?: boolean;
  isAssessmentDecision?: boolean;
  name?: string;
};

const Name = styled.span`
  font-weight: bold;
`;

const Container = styled.div`
  margin-top: 0.3rem;
`;

export const Field: React.FC<Props> = ({
  field,
  name,
  fieldStyle = FieldStyle.PREMADE,
  includeName = true,
  isAssessmentDecision = false,
}) => {
  if (!field) {
    return null;
  }

  const updated = isFieldUpdated(field);
  const component = updated ? (
    <FieldChanged
      fieldStyle={fieldStyle}
      field={field as Common.FieldChanged}
      isAssessmentDecision={isAssessmentDecision}
    />
  ) : (
    <FieldUnchanged
      isAssessmentDecision={isAssessmentDecision}
      field={field as Common.FieldUnchanged}
    />
  );

  return (
    <Container>
      {includeName && <Name>{name}:</Name>}
      {component}
    </Container>
  );
};

export default Field;
