import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import SingleSelect from 'modules/assess/ui/rules/common/rule/editor/single-select';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { useGetAssessRuleValues } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';

const Operator = ({ index }: { index: number }) => {
  const { conditions, updateConditionByIndex, selectedItemTypes } =
    useConditions();
  const { t } = useTranslation('');
  const label = t('selects.operator_placeholder');
  const condition = conditions[index];
  const { data } = useGetAssessRuleValues(condition.fact, selectedItemTypes());

  const items = data?.operators?.map((value: string) => ({
    id: value,
    label: t(`assess:rules.facts.count.operators.${value}`),
  }));

  const onChange = useCallback(
    ({ selectedItem: { id } }) => {
      updateConditionByIndex(index, { operator: id });
    },
    [index, updateConditionByIndex],
  );

  let selectedItem;

  if (data?.operators?.includes(condition.operator)) {
    selectedItem = {
      id: condition.operator,
      label: t(`assess:rules.facts.count.operators.${condition.operator}`),
    };
  }

  return (
    <M.GridCol
      data-testid='assess-rules-common-rule-editor-rule-condition-operators'
      lg={5}
      md={2}
      sm={1}
    >
      <SingleSelect
        items={items || []}
        label={label}
        selectedItem={selectedItem}
        onChange={onChange}
      />
    </M.GridCol>
  );
};

export default Operator;
