import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Pill = styled.div`
  align-items: center;
  background-color: ${colors.brandAqua3};
  border-radius: 2rem;
  display: flex;
  height: 1.4rem;
  justify-content: center;
  margin-left: 0.8rem;
  width: 3.2rem;

  /* Text formatting */
  color: ${colors.uiGrey0};
  font-size: 0.6rem;
  letter-spacing: 1.5px;
`;

const Live: React.FC = () => {
  const { t } = useTranslation('assess:v2');

  return (
    <Pill
      id='assess-v2-ui-common-components-live-pill'
      data-testid='assess-v2-live'
    >
      {t('common.live').toUpperCase()}
    </Pill>
  );
};

export default Live;
