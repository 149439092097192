import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import { fromUrlToTranslation } from 'modules/assess/v2/ui/utils';

type Props = {
  option: Option;
  showSettings?: boolean;
};

const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.uiTextSecondaryLight};
`;

const Description: React.FC<Props> = ({ option, showSettings }) => {
  const { t } = useTranslation('assess:v2');
  const translationOption = fromUrlToTranslation(option);
  const text = t(`lite.${translationOption}.header.description`);
  const settingsInfo = t(`lite.${translationOption}.header.settings_info`);

  return (
    <StyledDiv data-testid='assess-v2-ui-lite-common-header-description'>
      <Trans>{text}</Trans>
      {showSettings ? <Trans>{settingsInfo}</Trans> : null}
    </StyledDiv>
  );
};
export default Description;
