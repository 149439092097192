import React from 'react';
import { ExceptionInstanceProps } from '../types';
import { Body, Header, ResolvedAt, VerificationLink } from './components';

const InternationalDocumentationRequestedException: React.FC<
  ExceptionInstanceProps
> = ({ exception, report }) => {
  const {
    client_controlled,
    comment,
    created_at,
    expires_at,
    resolved_at,
    verification_uri,
    resolution_period,
    type,
  } = exception;
  const { status } = report;

  const expirationConsequence = (() => {
    if (comment?.match(/Police Information Check\./)) {
      return 'the International Criminal Search will be completed with a consider result';
    }

    return 'the report will be placed in Suspended status';
  })();

  const expiresAtText = `${
    expirationConsequence[0].toUpperCase() + expirationConsequence.slice(1)
  } on`;

  const bodyText = `If the candidate does not respond within
                    ${
                      resolution_period || 7
                    } days after the verification request
                    is sent, ${expirationConsequence}. If the
                    candidate does respond within these ${
                      resolution_period || 7
                    }
                    days, Checkr will review the uploaded information, and if all
                    delays are resolved, the report will resume processing.`;

  return (
    <div className='exception-container'>
      <Header comment={comment} createdAt={created_at} type={type} />
      <VerificationLink
        uri={verification_uri}
        clientControlled={client_controlled}
      />
      <ResolvedAt resolvedAt={resolved_at} type={type} />
      {status === 'pending' && !resolved_at && expires_at && (
        <Body
          type={type}
          bodyText={bodyText}
          expiresAt={expires_at}
          expireAtText={expiresAtText}
        />
      )}
    </div>
  );
};

export default InternationalDocumentationRequestedException;
