import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteCandidateData } from 'api/candidate';
import { M } from '@dashboard-experience/mastodon';
import { candidateName, GenericObject } from '@dashboard-experience/utils';
import ReportModal from 'components/Report/ReportModal';
import styled from 'styled-components';
import UpdatedDeletionDialog from './DeleteCandidatePii/UpdatedDeletionDialog';
import DeleteValidationFailedDialog from './DeleteCandidatePii/DeleteValidationFailedDialog';
import DeleteInternalErrorDialog from './DeleteCandidatePii/DeleteInternalErrorDialog';
import UpdatedDeletedSuccessDialog from './DeleteCandidatePii/UpdatedDeletedSuccessDialog';

const StyledSpinnerEnclosure = styled('div')`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  width: 100%;
`;

const DeleteCandidateData: React.FC<GenericObject> = ({
  showModal,
  onClose,
  candidate,
  ...props
}) => {
  // Call & Response for the API call to delete data
  const { deleteDataCall, deleteDataResult } =
    useDeleteCandidateData(candidate);

  useEffect(() => {
    // Check for a response from the server, for success/error on deleting Candidate information
    if (deleteDataResult.isSuccess || deleteDataResult.isError) {
      if (deleteDataResult.isSuccess) {
        const status = deleteDataResult.data?.status ?? 0;

        setIsSuccess(true);
        setStatusCode(status);
      } else {
        setIsError(true);
      }

      // After acting upon the success/error server response, reset its state
      deleteDataResult.reset();
    }
  }, [deleteDataResult]);

  // Status of the checkbox where the User confirms that they received a request from the Candidate to delete
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  // Error & Success states from the API requests
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);

  const { t } = useTranslation();
  const name = candidateName(candidate);

  const handleConsentCheck = useCallback(
    (event, { checked }) => setHasConsent(checked),
    [],
  );

  // Define reason of modal close for tracking in Amplitude
  const onCloseCustom = useCallback(
    (event: { target: any }) => {
      let completeReason;

      if (isSuccess) {
        completeReason = 'Delete Candidate Data';
      } else if (event.target.name === t('verbs.cancel')) {
        completeReason = 'Keep Candidate Data';
      } else {
        completeReason = 'Close the Modal';
      }
      onClose(completeReason);
    },
    [isSuccess, t, onClose],
  );

  const renderPiiCandidateDeletionDialog = () => {
    return (
      <UpdatedDeletionDialog
        name={name || ''}
        hasCandidateConsent={hasConsent}
        handleConsentCheck={handleConsentCheck}
        handleClose={onCloseCustom}
        handleSubmit={deleteDataCall}
        isError={isError}
      />
    );
  };

  const apiRequestCompletedWithErrors = () => {
    return (
      <>
        {isError ? (
          <DeleteInternalErrorDialog handleClose={onCloseCustom} />
        ) : (
          renderPiiCandidateDeletionDialog()
        )}
      </>
    );
  };

  const showPiiDeletedSuccessDialog = () => {
    if (statusCode === 202) {
      return <DeleteValidationFailedDialog handleClose={onCloseCustom} />;
    }

    return (
      <UpdatedDeletedSuccessDialog
        name={name || ''}
        handleClose={onCloseCustom}
      />
    );
  };

  const apiRequestCompleted = () => {
    return isSuccess
      ? showPiiDeletedSuccessDialog()
      : apiRequestCompletedWithErrors();
  };

  return (
    <ReportModal open={showModal} onClose={onCloseCustom} {...props}>
      <M.ModalHeader closeModal={onCloseCustom}>
        <h2> {t(`data_deletion.heading`)} </h2>
      </M.ModalHeader>

      {deleteDataResult.isLoading ? (
        <M.ModalBody>
          <h1>{t('data_deletion.delete_in_progress.notification')}</h1>
          <StyledSpinnerEnclosure>
            <M.LoadingSpinner withOverlay={false} />
          </StyledSpinnerEnclosure>
        </M.ModalBody>
      ) : (
        apiRequestCompleted()
      )}
    </ReportModal>
  );
};

export default DeleteCandidateData;
