import React, { ReactNode, SyntheticEvent, CSSProperties } from 'react';
import styled from 'styled-components';
import {
  Namespace,
  useTranslation,
  UseTranslationOptions,
} from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { isPlainObject } from 'lodash';

type Type = React.FC<Props>;

type Props = {
  disabled?: boolean;
  icon?: string | ReactNode;
  kind?: Kind;
  label?: string;
  size?: Size;
  trans?: Translation;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
  id?: string;
};

export type Kind =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'achievement'
  | 'adverse';

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type Translation = TranslationKey | TranslationConfig;
type TranslationKey = string | string[];
type TranslationConfig = {
  key: TranslationKey;
  ns?: Namespace;
  use?: UseTranslationOptions;
};

const StyledButton = styled(M.Button)`
  > *:not(:first-child) {
    margin-left: 1em;
  }
`;

export const Button: Type = ({
  children,
  icon,
  label,
  trans,
  style,
  id,
  ...props
}) => {
  const config = getTranslationConfig(trans);
  const { t } = useTranslation(config.ns, config.use);
  const text = label || t(config.key);
  const elements = [];

  if (text) {
    elements.push(<span key='text'>{text}</span>);
  }

  if (icon) {
    elements.push(
      typeof icon === 'string' ? <M.Icon key='icon' icon={icon} /> : icon,
    );
  }

  return (
    <StyledButton data-testid={id} aria-label={text} style={style} {...props}>
      {children || elements}
    </StyledButton>
  );
};

function getTranslationConfig(trans?: Translation): TranslationConfig {
  if (isPlainObject(trans)) {
    return trans as TranslationConfig;
  }
  return { key: trans as TranslationKey };
}

export default Button;
