import { Kind } from 'modules/assess/models/entity';
import { Event } from 'modules/assess/models/event';
import React from 'react';
import styled from 'styled-components';
import StatusByline from './entity/status/byline';

export type Props = {
  active?: Event;
  draft?: Event;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemEventTimes: React.FC<Props> = ({ active, draft }) => {
  if (!draft) {
    draft = active;
  }

  if (!active && !draft) {
    return null;
  }

  return (
    <Container data-testid='assess-common-item-event-times'>
      <StatusByline kind={Kind.final} time={active?.time} user={active?.user} />
      <StatusByline kind={Kind.draft} time={draft?.time} user={draft?.user} />
    </Container>
  );
};

export default ItemEventTimes;
