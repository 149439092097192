import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { Context } from 'components/Packages/context';
import { M, colors } from '@dashboard-experience/mastodon';
import { uniq } from 'lodash';
import { LoadingScreen } from 'components/Account';
import { useFlag } from '@dashboard-experience/react-flagr';
import { MANUAL_BULK_UPLOAD } from 'Constants';
import { validateInvites } from 'utils';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, MANUAL_BULKORDER_NAMES } from 'utils/analytics';
import { hasPermission } from '@dashboard-experience/utils';
import { useRequestManualOrders } from 'api/accounts';
import {
  Geo,
  Program,
  noneGeo,
  ChoiceCardWrap,
  StyledChoiceCardGroup,
} from '../SharedItems';
import SelectGeo from './DropdownSelections/SelectGeo';
import SelectLocation from './DropdownSelections/SelectLocation';
import SelectProgram from './DropdownSelections/SelectProgram';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  CANDIDATE,
  MYSELF,
  MANUAL,
  UPLOAD,
  SINGLE_PERSON,
  MULTIPLE_PEOPLE,
  ManualOrdersReview,
  CANDIDATE_TYPE,
  MYSELF_TYPE,
} from '../Context';
import LocationModal from './LocationModal';
import BulkInvite from './BulkInvite';
import { isWorkLocationVisible } from '../ReviewAndSubmitOrder/ReviewAndSubmitOrder';
import SelectPaymentProfile from './DropdownSelections/SelectPaymentProfile';
import ManualBulkUpload from './ManualBulkUpload';
import ManualOrderModal from './ManualOrderModal';
import PaymentProfileModal from './PaymentProfileModal';

export type AccountDetails = {
  hierarchy_present: boolean;
  segmentation_enabled: boolean;
  geos_required: boolean;
  purpose: string;
  manual_orders_enabled: boolean;
  manual_orders_review: ManualOrdersReview;
  programs_enabled: boolean;
};

const Container = styled.div`
  max-width: 825px;

  @media all and (max-width: 940px) {
    width: 100%;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandNavy4};
`;

const RadioButtonWidth = styled(M.ChoiceCard)`
  border: 1px solid
    ${props => (props.checked ? colors.brandNavy2 : colors.brandSlate3)};
`;

const ChoiceCardWithBadge = styled(RadioButtonWidth)`
  .mastodon-choice-card-title {
    &::after {
      display: inline-block;
      border-radius: 0.25rem;
      padding: 0.125rem 0.5rem;
      color: ${colors.uiTextPrimaryLight};
      font-weight: 400;
      font-size: 0.75rem;
      margin: -0.0625rem 0 -0.0625rem 0.5rem;
      line-height: 1rem;
    }
  }

  &.${ManualOrdersReview.NOT_REQUESTED} {
    pointer-events: none;
    .mastodon-choice-card-title {
      &::after {
        content: 'Pending setup';
        background: ${colors.uiYellow200};
      }
    }
  }

  &.${ManualOrdersReview.REQUESTED} {
    pointer-events: none;
    .mastodon-choice-card-title {
      &::after {
        content: 'Pending approval';
        background: ${colors.uiGrey200};
      }
    }
  }
`;

const LearnMoreText = styled.span`
  color: ${colors.uiNavy600};
`;

const LearnMoreIcon = styled(M.Icon)`
  color: ${colors.uiNavy600};

  &:hover {
    cursor: pointer;
  }
`;

const SectionMargin = styled.div`
  margin-top: 2.5rem;
`;

const Tooltip = styled(M.Tooltip)`
  border-bottom: none !important;
`;

const ManualOrderNote = styled.p`
  color: ${colors.uiTextSecondaryLight};

  a {
    cursor: pointer;
  }
`;

const OpenManualOrderModal = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
`;

const ChoiceCardGroup = styled(StyledChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;

const DEFAULT_GEOS: Geo[] = [];

export const checkNodesCompleted = (
  hierarchy_present: boolean,
  nodeName: string,
  country: string,
  state: string,
) => {
  if (hierarchy_present) {
    const hasNode = !!nodeName;
    const hasCountry = !!country;
    const isUnitedStates = country === 'US';
    const hasState = !!state;
    const hasStatesIfUS = isUnitedStates && hasState;
    const hasCountryIfNotUs = hasCountry && !isUnitedStates;
    return hasNode && hasCountry && (hasStatesIfUS || hasCountryIfNotUs);
  }
  return true;
};

export const checkWorkLocationCompleted = (
  segmentation_enabled: boolean,
  hasMoreThanOneCountry: boolean,
  country: string,
  state: string,
) => {
  const hasCountry = !!country;
  const hasState = !!state;
  const isUnitedStates = country === 'US';
  const hasStatesIfUS = isUnitedStates && hasState;

  if (segmentation_enabled && hasMoreThanOneCountry) {
    const hasCountryIfNotUs = hasCountry && !isUnitedStates;
    return hasCountry && (hasStatesIfUS || hasCountryIfNotUs);
  }

  if (!hasMoreThanOneCountry && segmentation_enabled) {
    return hasCountry && hasStatesIfUS;
  }

  return true;
};

export const checkGeoCompleted = (
  segmentation_enabled: boolean,
  hasAtLeastOneGeo: boolean,
  geo?: Geo | null | undefined,
) => {
  if (hasAtLeastOneGeo && !segmentation_enabled) {
    return !!geo?.id;
  }

  return true;
};

export const checkProgramCompleted = (
  hasAtLeastOneProgram: boolean,
  program: Program | null | undefined,
) => {
  if (hasAtLeastOneProgram) {
    return !!program?.id;
  }
  return true;
};

type GetStartedProps = {
  goBackClicked: boolean;
  redirectPage: Function;
};

type ProvideDataByMyselfProps = {
  isInternational: boolean;
  manualOrderReviewStatus: ManualOrdersReview;
  onClick: () => void;
  onModalOpen: () => void;
  providerType: CANDIDATE_TYPE | MYSELF_TYPE;
};

const ProvideDataByMyself: React.FC<ProvideDataByMyselfProps> = ({
  isInternational,
  manualOrderReviewStatus,
  onClick,
  onModalOpen,
  providerType,
}) => {
  const showModal =
    manualOrderReviewStatus === ManualOrdersReview.NOT_REQUESTED ||
    manualOrderReviewStatus === ManualOrdersReview.REQUESTED;
  const content: JSX.Element = (
    <ChoiceCardWrap data-testid='myself-radio-button-card-wrap'>
      <ChoiceCardWithBadge
        className={manualOrderReviewStatus}
        data-testid={`${
          isInternational ? 'international' : 'domestic'
        }-myself-radio-button-testid`}
        value={MYSELF}
        onChange={onClick}
        title='Myself'
        description='Enter details manually on behalf of your candidates'
        disabled={
          isInternational ||
          manualOrderReviewStatus === ManualOrdersReview.REQUESTED
        }
        checked={providerType === MYSELF}
      />
    </ChoiceCardWrap>
  );

  if (showModal) {
    return (
      <OpenManualOrderModal onClick={onModalOpen}>
        {content}
      </OpenManualOrderModal>
    );
  }

  if (isInternational) {
    return (
      <Tooltip
        label='Not available for candidates located outside of the United States'
        align='top-right'
      >
        {content}
      </Tooltip>
    );
  }

  return <>{content}</>;
};

const GetStarted: React.FC<GetStartedProps> = ({
  goBackClicked,
  redirectPage,
}) => {
  const {
    account,
    geos = DEFAULT_GEOS,
    programs,
    payment_profiles,
    billing_entity_enabled,
    anyLoading,
  } = useContext(Context);
  const {
    hierarchy_present,
    segmentation_enabled,
    geos_required,
    manual_orders_enabled,
    programs_enabled,
    manual_orders_review: manualOrdersReviewStatus,
  } = account as AccountDetails;
  const user = useUser();
  const manualOrdersEnabledForUserAndAccount =
    manual_orders_enabled && hasPermission(user, 'create_manual_orders');
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const [showPaymentProfileModal, setShowPaymentProfileModal] = useState(false);
  const [showLocationIconModal, setShowLocationIconModal] = useState(false);
  const [showManualOrdersModal, setShowManualOrdersModal] = useState(false);

  const { call: requestManualOrdersCall } = useRequestManualOrders();

  const manualBulkUploadFlag: boolean =
    useFlag(MANUAL_BULK_UPLOAD)?.variantKey === 'on';
  const trackEvent = useTrackEvent();

  const countries: string[] = useMemo(
    () =>
      uniq(
        geos
          .filter((geo: any) => geo.country)
          .map((geo: any) => geo.country)
          .concat(['US']),
      ),
    [geos],
  );

  const selectablePrograms = useMemo(() => {
    // If there are no geos, or none geo is selected, you can select all programs
    // otherwise only show ones for the current geo
    return geos.length === 0 || state.geo === noneGeo
      ? programs
      : programs.filter((p: any) =>
          (p.geo_ids || []).includes(state.geo?.id || null),
        );
  }, [geos.length, programs, state.geo]);

  const handleProviderTypeChange = useCallback(
    (provider: any) => {
      dispatch({
        type: actionTypes.ADD_PROVIDER_TYPE,
        payload: { providerType: provider },
      });
    },
    [dispatch],
  );

  const nodesCompleted = useCallback(() => {
    return checkNodesCompleted(
      hierarchy_present,
      state.node?.name,
      state.location.country,
      state.location.state,
    );
  }, [
    hierarchy_present,
    state.location.country,
    state.location.state,
    state.node?.name,
  ]);

  const workLocationCompleted = useCallback(() => {
    return checkWorkLocationCompleted(
      segmentation_enabled,
      countries.length > 1,
      state.location.country,
      state.location.state,
    );
  }, [
    countries.length,
    segmentation_enabled,
    state.location.country,
    state.location.state,
  ]);

  const completedForGeos = useCallback(() => {
    return (
      checkGeoCompleted(segmentation_enabled, geos.length > 0, state.geo) ||
      !geos_required
    );
  }, [segmentation_enabled, geos.length, state.geo, geos_required]);

  const completedForPrograms = useCallback(() => {
    if (!programs_enabled) {
      return true;
    }
    return checkProgramCompleted(selectablePrograms.length > 0, state.program);
  }, [programs_enabled, selectablePrograms.length, state.program]);

  const completedForm: boolean = useMemo(() => {
    // Check required inputs are all created
    return (
      nodesCompleted() &&
      completedForGeos() &&
      completedForPrograms() &&
      workLocationCompleted()
    );
  }, [
    completedForGeos,
    completedForPrograms,
    nodesCompleted,
    workLocationCompleted,
  ]);

  useEffect(() => {
    let isButtonDisabled = true;
    if (state.providerType === MYSELF && completedForm) {
      isButtonDisabled = false;
    }

    if (state.providerType === CANDIDATE && completedForm) {
      if (state.inviteMethod === MANUAL) {
        const areEmailsPresent = state.emails.length > 0;

        isButtonDisabled = !areEmailsPresent && !validateInvites(state.invites);
      }

      if (
        state.inviteMethod === UPLOAD &&
        Object.keys(state?.csv ?? {}).length
      ) {
        isButtonDisabled = false;
      }
    }

    state.continueButtonDisabled !== isButtonDisabled &&
      dispatch({
        type: actionTypes.CONTINUE_BUTTON_DISABLED,
        payload: { continueButtonDisabled: isButtonDisabled },
      });
  }, [
    completedForm,
    dispatch,
    state.continueButtonDisabled,
    state.emails.length,
    state.csv,
    state.geo,
    state.location.country,
    state.location.state,
    state.node,
    state.program,
    state.providerType,
    state.inviteMethod,
    state.invites,
  ]);

  const showWorkLocation = useMemo(() => {
    return isWorkLocationVisible(segmentation_enabled, geos);
  }, [segmentation_enabled, geos]);

  const paymentProfileModalShow = useCallback(() => {
    setShowPaymentProfileModal(true);
  }, []);

  const paymentProfileModalClose = useCallback(() => {
    setShowPaymentProfileModal(false);
  }, []);

  const showModal = useCallback(() => {
    setShowLocationIconModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowLocationIconModal(false);
  }, []);

  const handleCandidate = useCallback(() => {
    handleProviderTypeChange(CANDIDATE);
    if (state.manualBulkUploadType === MULTIPLE_PEOPLE) {
      dispatch({
        type: actionTypes.CHANGE_MANUAL_BULK_UPLOAD_TYPE,
        payload: { manualBulkUploadType: SINGLE_PERSON },
      });
    }
  }, [dispatch, handleProviderTypeChange, state.manualBulkUploadType]);

  const handleMyself = useCallback(
    () => handleProviderTypeChange(MYSELF),
    [handleProviderTypeChange],
  );

  const manualOrdersModalShow = useCallback(() => {
    trackEvent(MANUAL_BULKORDER_NAMES.MANUAL_ORDERS_MODAL_OPENED);
    setShowManualOrdersModal(true);
  }, [trackEvent]);

  const manualOrdersModalClose = useCallback(() => {
    setShowManualOrdersModal(false);
  }, []);

  const manualOrdersModalSubmit = useCallback(() => {
    requestManualOrdersCall({ accountId: user.account.id });
  }, [requestManualOrdersCall, user.account.id]);

  return (
    <Container>
      {anyLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {billing_entity_enabled && (
            <>
              {showPaymentProfileModal && (
                <PaymentProfileModal
                  showPaymentProfileModal={showPaymentProfileModal}
                  handleClose={paymentProfileModalClose}
                />
              )}
              <h4 data-testid='get-started-payment-profile-title'>
                Who should we invoice?{' '}
                <LearnMoreText
                  data-testid='get-started-payment-profile-learn-more-icon'
                  onClick={paymentProfileModalShow}
                >
                  <LearnMoreIcon size='16' icon='InformationFilled' />
                </LearnMoreText>
              </h4>
              <Description data-testid='get-started-payment-profile-description'>
                We need to know which payment profile you want to use for this
                to use for this order.
              </Description>
              {payment_profiles.length > 0 ? (
                <SelectPaymentProfile
                  data-testid='get-started-payment-profile-dropdown'
                  payment_profiles={payment_profiles}
                  billing_entity_enabled={billing_entity_enabled}
                  goBackClicked={goBackClicked}
                />
              ) : (
                <M.InlineNotification
                  inline='classic'
                  hideActionButton
                  data-testid='get-started-payment-profile-not-loaded-message'
                  kind='warning'
                  title='Oops, it looks like we are having trouble loading your payment profiles.'
                  subtitle='By default, this invitation will be ordered under
                  the main account. If you need to order under a different
                  payment profile, please contact customer service or try again
                  later.'
                />
              )}
              <SectionMargin />
            </>
          )}
          {showLocationIconModal && (
            <LocationModal
              showLocationIconModal={showLocationIconModal}
              handleClose={closeModal}
            />
          )}
          {showWorkLocation && (
            <>
              <h4 data-testid='get-started-location-title'>
                Where will your candidates be located?{' '}
                <LearnMoreText
                  data-testid='get-started-learn-more-icon'
                  onClick={showModal}
                >
                  <LearnMoreIcon size='16' icon='InformationFilled' />
                </LearnMoreText>
              </h4>
              <Description>
                We need to know your candidate(s) work location. If it differs
                from the employer’s location, we recommend choosing the one with
                the strictest{' '}
                <M.Link
                  data-testid='get-started-reporting-requirements'
                  href='https://help.checkr.com/hc/en-us/articles/360000739988'
                  target='_blank'
                  size='lg'
                  rel='noopener noreferrer'
                >
                  reporting requirements
                </M.Link>
                .
              </Description>
              <SelectGeo
                segmentation_enabled={segmentation_enabled}
                geos={geos}
                geos_required={geos_required}
              />
              <SelectLocation
                countries={countries}
                segmentation_enabled={segmentation_enabled}
                hierarchy_present={hierarchy_present}
                goBackClicked={goBackClicked}
              />
            </>
          )}
          {programs_enabled && (
            <SelectProgram selectablePrograms={selectablePrograms} />
          )}
          <SectionMargin />
          {manualOrdersEnabledForUserAndAccount && (
            <>
              <h4>Who will provide the required details?</h4>
              <ChoiceCardGroup>
                <ChoiceCardWrap data-testid='candidate-radio-button-card-wrap'>
                  <RadioButtonWidth
                    data-testid='candidate-radio-button-testid'
                    onClick={handleCandidate}
                    checked={state.providerType === CANDIDATE}
                    value={CANDIDATE}
                    title='The candidate'
                    description='Send a link for candidates to fill out their own details'
                  />
                </ChoiceCardWrap>
                <ProvideDataByMyself
                  isInternational={state.selectedGeo === 'international'}
                  manualOrderReviewStatus={manualOrdersReviewStatus}
                  onClick={handleMyself}
                  onModalOpen={manualOrdersModalShow}
                  providerType={state.providerType}
                />
              </ChoiceCardGroup>
            </>
          )}
          {state.providerType === CANDIDATE && (
            <BulkInvite redirectPage={redirectPage} />
          )}
          {state.providerType === MYSELF && (
            <>
              <ManualOrderNote data-testid='manual-order-note'>
                You must provide compliant forms and get written consent prior
                to manually ordering.{' '}
                <M.Link
                  onClick={manualOrdersModalShow}
                  data-testid='manual-order-note-link'
                >
                  Learn more
                </M.Link>
              </ManualOrderNote>
              {manualBulkUploadFlag ? (
                <ManualBulkUpload />
              ) : (
                <Description style={{ marginTop: '1rem' }}>
                  Great! Since you’re providing details on behalf of your
                  candidate that’s all we need for now. After submitting your
                  order, you can add their remaining details.
                </Description>
              )}
            </>
          )}
          <ManualOrderModal
            show={showManualOrdersModal}
            handleClose={manualOrdersModalClose}
            handleSubmit={manualOrdersModalSubmit}
            manualOrdersReviewStatus={manualOrdersReviewStatus}
          />
        </div>
      )}
    </Container>
  );
};

export default GetStarted;
