import * as Entity from 'modules/assess/models/rulesets/version';
import Kind from 'modules/assess/models/rulesets/kind';
import { ID } from 'modules/id';
import * as rules from './rules';

type Base = Omit<
  Entity.Type,
  'id' | 'kind' | 'rules' | 'created_at' | 'updated_at' | 'published_at'
>;

type RequestData = Partial<Base> & {
  id?: ID;
  draft: boolean;
  rules?: rules.RequestData;
};

type ResponseData = Base & {
  id: ID;
  draft: boolean;
  rules: rules.ResponseData;
  created_at: string;
  updated_at: string;
  published_at?: string;
};

export function fromAPI(data: ResponseData): Entity.Type {
  const result: Entity.Type = {
    ...data,
    kind: data.draft ? Kind.draft : Kind.final,
    rules: rules.fromAPI(data.rules),
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    published_at: data.published_at ? new Date(data.published_at) : undefined,
  };

  return result;
}

export function toAPI(data: Partial<Entity.Type>): RequestData {
  const result: RequestData = {
    ...data,
    draft: data.kind === Kind.draft,
    rules: data.rules ? rules.toAPI(data.rules) : undefined,
  };

  return result;
}
