export abstract class Base {
  protected readonly basePath =
    'assess:settings.reports.eligible-records.report-view';

  protected readonly eligibleItemTreatmentPath =
    'assess_eligible_item_treatment_enabled';

  // eslint-disable-next-line no-empty-function
  constructor(protected isEligibleItemTreatmentEnabled: boolean) {}
}
