/* eslint-disable react/jsx-no-bind */
import { M } from '@dashboard-experience/mastodon';
import { formatDate } from '@dashboard-experience/utils';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Report } from 'types';
import { useReportInvitationStatusDecision } from '../utils';
import ReportInvitationDropdownItems from './report-invitation-dropdown-items';

type Props = {
  isScrolled: boolean;
  reportsLoading: boolean;
  selectedReport: Report;
  reports: Report[];
};

const ShrinkButton = styled(M.Button)`
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const AllReportsButton = styled(M.Button)`
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const ReportsDropdown: React.FC<Props> = ({
  isScrolled,
  reportsLoading,
  selectedReport,
  reports,
}) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(selectedReport);

  const RenderTitle = useCallback(
    () => (
      <>
        {isScrolled ? (
          <ShrinkButton kind='secondary' disabled={reportsLoading}>
            <span>
              {formatDate(selectedReport.created_at || '', 'MMM D, YYYY')}
            </span>
            {status ? <M.StatusIcon icon={status} /> : <M.LoadingInline />}
            <M.Icon icon='ChevronDown' size='20' />
          </ShrinkButton>
        ) : (
          <AllReportsButton kind='tertiary' disabled={reportsLoading}>
            <span>All reports</span>
            <M.Icon icon='ChevronDown' size='20' />
          </AllReportsButton>
        )}
      </>
    ),
    [isScrolled, status, reportsLoading, selectedReport.created_at],
  );

  return (
    <M.ActionMenu
      trigger={RenderTitle()}
      className='all-reports-action-menu'
      position='bottom'
      size='l'
      shouldCloseOnItemSelect
    >
      {!!reports.length && (
        <ReportInvitationDropdownItems
          selectedReport={selectedReport}
          reports={reports}
        />
      )}
    </M.ActionMenu>
  );
};

export default ReportsDropdown;
