import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import Record from './Record/Record';
import CreditReportRecordUtils from '../CreditReportCommon/CreditReportRecordUtils';

const InternationalCreditReport: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { credit_report: screening } = report;

  if (!screening) return null;

  const creditReportUtils = new CreditReportRecordUtils(screening?.records);

  if (!creditReportUtils.isEmpty()) return null;

  const title = `${
    creditReportUtils.findInternationalEmptyObject().country_name
  } Credit Report`;

  return (
    <M.Screening
      title={title}
      screening={screening}
      statusType={reportStatusType}
      id={SCREENING_TITLES.international_credit_report.id}
      data-testid='screenings-international-credit-report'
      role='list'
      status={screening.status}
    >
      {creditReportUtils.isInternationalHasNonEmptyRecords() && (
        <Record
          record={creditReportUtils.findInternationalRecords()}
          key='record-internationalCreditObject'
          lastLine
        />
      )}
    </M.Screening>
  );
};

export default InternationalCreditReport;
