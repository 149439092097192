import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import LicenseVerification from './LicenseVerification';

const ProfessionalLicenseVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { professional_license_verifications: screening } = report;
  return screening && screening.length ? (
    <M.Screening
      title='Professional License Verification'
      screening={screening}
      statusType={reportStatusType}
      id={SCREENING_TITLES.professional_license_verifications.id}
      data-testid='screenings-professional-license-verification'
      role='list'
    >
      {screening.map((licenseVerification: any) => (
        <LicenseVerification
          key={licenseVerification.id}
          licenseVerification={licenseVerification}
          reportStatusType={reportStatusType}
        />
      ))}
    </M.Screening>
  ) : null;
};

export default ProfessionalLicenseVerification;
