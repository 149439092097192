import React from 'react';

import styled from 'styled-components';
import { useSearchState } from 'components/Search/search-context';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import Skeleton from './Skeleton';
import BaseFilter from './BaseFilter';
import { onSelectChange, AnalyticsProps } from '../helpers';
import { styledFilter } from './StyledComponents';

const StyledDiv = styled.div`
  ${styledFilter}
`;

const Program = () => {
  const { programs, programsLoading } = useSearchState();

  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.PROGRAM,
    trackEvent,
  };

  return (
    <StyledDiv>
      {programs.length > 0 && !programsLoading ? (
        <BaseFilter
          component='ProgramFilter'
          param='program_id'
          onChange={onSelectChange}
          programs={programs}
          trackAnalyticsObject={trackAnalyticsObject}
        />
      ) : (
        <div />
      )}
      <Skeleton isLoading={programsLoading} />
    </StyledDiv>
  );
};

export default Program;
