/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useRef, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useClickOutside } from '@dashboard-experience/utils';

const ComboButton = styled.div`
  #mastodon && {
    position: relative;
    display: inline-flex;
    border: 1px solid ${colors.uiGrey200};
    border-radius: 4px;
    z-index: 1;
    margin-left: 0.5rem;

    &.disabled {
      cursor: not-allowed;
      background-color: ${colors.uiGrey200};

      .cds--btn--disabled {
        background: none;
      }
      .divider {
        display: none;
      }
      [data-testid='add-checks-button'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      [data-testid='add-checks-menu-button'] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .mastodon-button {
      border: none !important;
      margin: 0 !important;
      box-shadow: none;

      &:focus {
        outline: ${colors.uiLinkPrimaryLightDefault} solid 1px;
      }
    }

    .cds--overflow-menu {
      box-shadow: none;
    }

    .divider {
      border-right: 1px solid ${colors.borderPrimaryLight};
      height: 1.375rem;
      margin: 0.375rem 0;
    }
  }
`;

const Button = styled(M.Button)`
  padding: 0.5rem 0.75rem !important;
`;

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 2px;

  align-items: flex-start;
  background: ${colors.uiGrey0};
  box-shadow: 0 2px 6px ${colors.borderPrimaryDark}80;
  z-index: 6000;
`;

const DropdownItem = styled.button`
  padding: 0.625rem 1rem;
  line-height: 1.25rem;
  cursor: pointer;
  color: ${colors.uiTextPrimaryLight};
  white-space: nowrap;
  background: none;
  border: none;
  width: 100%;
  text-align: left;

  &:hover {
    background: ${colors.uiGrey100};
  }

  &:focus {
    outline: ${colors.uiLinkPrimaryLightDefault} solid 1px;
  }

  span {
    display: block;
    font-size: 0.75rem;
    color: ${colors.uiTextTertiaryLight};
  }
`;

const AddChecksButton: React.FC<{
  isDisabled: boolean;
  addChecks: (e: any) => void;
  openI9: (e: any) => void;
}> = ({ isDisabled, addChecks, openI9 }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenuClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const closeMenu = useCallback((callback: Function) => {
    setIsMenuOpen(false);
    callback();
  }, []);

  const ref = useRef<HTMLDivElement>(null!);
  useClickOutside({
    ref,
    handler: () => {
      setIsMenuOpen(false);
    },
  });

  return (
    <ComboButton className={isDisabled ? 'disabled' : ''} ref={ref}>
      <M.Button
        data-testid='add-checks-button'
        kind='secondary'
        size='sm'
        onClick={addChecks}
        disabled={isDisabled}
      >
        Add checks
      </M.Button>
      <span className='divider' />
      <Button
        kind='secondary'
        size='sm'
        onClick={handleOpenMenuClick}
        data-testid='add-checks-menu-button'
        disabled={isDisabled}
      >
        <M.Icon icon='ChevronDown' size={16} />
      </Button>
      {isMenuOpen && (
        <Dropdown>
          <DropdownItem
            data-testid='add-checks-a-la-carte'
            onClick={() => closeMenu(addChecks)}
          >
            A-la-carte order
            <span>Add checks to this order</span>
          </DropdownItem>
          <DropdownItem
            data-testid='add-checks-i9'
            onClick={() => closeMenu(openI9)}
          >
            Order I-9
            <span>Prepare to hire this candidate</span>
          </DropdownItem>
        </Dropdown>
      )}
    </ComboButton>
  );
};

export default AddChecksButton;
