import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';
import useGetUser from './useGetUser';

const createPaymentIntent = async (user: any) => {
  const token = localStorage.getItem('accessToken');

  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/billing/accounts/${user.account.id}/setup_intent`,
    { payment_type: 'card' },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

type Props = {
  onError?: (error: any) => void;
};

function useCreatePaymentIntent({ onError }: Props) {
  const { data: user, isLoading, error: userError } = useGetUser();
  const request = () => createPaymentIntent(user);

  return useQuery('create-payment-intent', request, {
    refetchOnWindowFocus: false,
    enabled: !isLoading && !userError && user !== undefined,
    onError,
  });
}

export default useCreatePaymentIntent;
