import { Decision } from 'modules/assess/models';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import * as Entity from 'modules/assess/models/rulesets/version';
import { RulesetVersionDiff } from 'modules/assess/models/rulesets/version-diff';
import { ID } from 'modules/id';
import client from '../client';
import { Namespace } from '../constants';
import { fromAPI, toAPI } from './utilities/versions';
import { fromAPI as diffFromAPI } from './utilities/versions-diff';

const BASE_PATH = 'rulesets';

const path = (namespace: Namespace, ruleset: string) => {
  return `/${namespace}/${BASE_PATH}/${ruleset}/versions/draft`;
};

export const fetch = (
  namespace: Namespace,
  id: string,
): Promise<Entity.Type> => {
  return client.get(path(namespace, id)).then(fromAPI);
};

export const create = (
  namespace: Namespace,
  id: string,
): Promise<Entity.Type> => {
  return client.post(path(namespace, id)).then(fromAPI);
};

export const destroy = (namespace: Namespace, id: string): Promise<void> => {
  return client.delete(path(namespace, id));
};

export const lookbackFromCSV = (
  namespace: Namespace,
  id: string,
  file: File,
): Promise<any> => {
  const data = new FormData();
  data.set('file', file);
  return client.put(`${path(namespace, id)}/lookback/csv`, data);
};

export const update = (
  namespace: Namespace,
  id: string,
  updates: Partial<Entity.Type>,
): Promise<Entity.Type> => {
  return client.patch(path(namespace, id), toAPI(updates)).then(fromAPI);
};

export const publish = (namespace: Namespace, id: string): Promise<void> => {
  return client.post(`${path(namespace, id)}/publish`);
};

type InsightsParams = {
  ruleset: Ruleset.Type | ID;
  base?: Entity.Type | ID;
  from: Date;
  to?: Date;
};

type InsightsResponse = {
  base: ID;
  from: string;
  to: string;
  counts: {
    base: Record<Decision, number>;
    self: Record<Decision, number>;
  };
};

export const insights = (
  namespace: Namespace,
  { ruleset, base, from, to = new Date() }: InsightsParams,
): Promise<InsightsResponse> => {
  const id = typeof ruleset === 'string' ? ruleset : ruleset.id;

  if (!id) {
    return Promise.reject(new Error('ruleset missing'));
  }

  let baseId;
  if (base) {
    baseId = typeof base === 'string' ? base : base.id;
  }

  const queryParams = {
    base: baseId,
    from: from.toISOString(),
    to: to.toISOString(),
  };

  return client.get(`${path(namespace, id)}/insights`, {
    params: queryParams,
  });
};

export const diff = (
  namespace: Namespace,
  id: string,
): Promise<RulesetVersionDiff.Type> => {
  return client.get(`${path(namespace, id)}/diff`).then(diffFromAPI);
};
