/* eslint-disable import/prefer-default-export */
import React from 'react';

export const CheckrInitial = () => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        position: 'relative',
        top: '2px',
      }}
    >
      <path d='M7.65625 12.3477C5.56641 12.3477 3.96484 10.6094 3.96484 8.38281C3.96484 6.15625 5.54687 4.45703 7.65625 4.45703C9.12109 4.45703 10.4297 5.21875 11.0742 6.60547L14.6289 5.375C13.5156 2.5625 10.8984 0.785156 7.69531 0.785156C3.30078 0.785156 0 4.02734 0 8.34375C0 12.6602 3.32031 16 7.63672 16C10.8984 16 13.5937 14.1445 14.668 11.2344L11.0547 9.98438C10.5078 11.4492 9.16016 12.3477 7.65625 12.3477Z' />
    </svg>
  );
};
