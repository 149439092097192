import {
  GenericObject,
  localStorageFallback,
} from '@dashboard-experience/utils';
import { IS_IN_IFRAME } from 'Constants';
import { clearUserPreferences } from 'api/dashboardPreferences';
import { getInternalDashboardUrl, getMrNumber } from './URLHelper';

export type Impersonation = {
  enabled: boolean;
  impersonatorEmail: string;
  impersonatedToken: string;
  impersonatedUser: GenericObject;
  dashboardOrigin: string;
};

/**
 * Customer is able to hold 2 different user states at once (The actual user, as well as whomever they're impersonating),
 * and as such, sometimes it needs to transmit one of those as opposed to the other.
 * This is a helper function to determine which to transmit.
 * @returns {bool} whether you should pass in the related Impersonated data
 */
export const shouldUseImpersonationData = (): boolean => {
  const impersonation = getImpersonation();

  // Whenever an impersonation is active and the user is in Standalone,
  // then the relevant user data is the IMPERSONATION data.
  return !!impersonation.enabled && !IS_IN_IFRAME;
};

export const getImpersonation = () => {
  const impersonationFromStorage =
    localStorageFallback.getItem('impersonating');

  const impersonation: Impersonation = impersonationFromStorage
    ? JSON.parse(impersonationFromStorage)
    : {};

  return impersonation;
};

export const updateImpersonatedUser = (data: any) => {
  const impersonation = getImpersonation();
  localStorageFallback.setItem(
    'impersonating',
    JSON.stringify({
      ...impersonation,
      impersonatedUser: data,
    }),
  );
};

export const removeImpersonation = () => {
  clearUserPreferences();
  localStorageFallback.removeItem('impersonating');
};

export const isImpersonationExpired = () => {
  const { enabled } = getImpersonation();
  const expiry = parseInt(
    localStorageFallback.getItem('tokenExpiryTime') || '0',
    10,
  );

  if (enabled && (!expiry || expiry < Date.now())) {
    endImpersonationAndRedirect();
  }
};

export const endImpersonationAndRedirect = () => {
  // If there was a pre-defined location, go to that. If not, use the safe fallback.
  const path = getImpersonation().dashboardOrigin || '/admin/users';

  removeImpersonation();

  // Send the user back to Dashboard, with a query param to remove impersonation from that side
  const redirectURL = getInternalDashboardUrl(path, {
    removeImpersonation: true,
    mr: getMrNumber(),
  });

  window.location.href = redirectURL;

  return false;
};
