import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import { NoteType } from 'modules/case-management/types';

type Props = {
  note: NoteType;
};

const Note: React.FC<Props> = ({ note }) => {
  const { t } = useTranslation('case-management');
  const timezone = moment.tz.guess();

  let event;
  let time;

  if (note.discarded_at) {
    event = '';
    time = note.discarded_at;
  } else if (note.updated_at !== note.created_at) {
    event = t('internal-notes.event.updated');
    time = note.updated_at;
  } else {
    event = '';
    time = note.created_at;
  }

  return (
    <span data-testid='case-management-note-created-date'>
      &bull; {event} {moment.tz(time, timezone).format('llll z')}
    </span>
  );
};

export default Note;
