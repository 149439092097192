import React from 'react';
import { useReport } from 'containers/Report';
import BannerNotification from './DelayedScreeningsBannerStyledComponents';
import hasDelayedScreenings from './utils';
import { combineDelayedScreeningCountryNames } from './screenings';

const DelayedScreeningsBanner: React.FC = () => {
  const report = useReport();
  return hasDelayedScreenings(report) ? (
    <BannerNotification
      kind='warning'
      title='Important:'
      subtitle={`Checkr is currently unable to initiate or complete searches in ${combineDelayedScreeningCountryNames()}.`}
      hideCloseButton='true'
      data-testid='delayed-screening-notification'
    />
  ) : null;
};

export default DelayedScreeningsBanner;
