import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import Dialog from 'modules/assess/ui/ruleset/delete';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { useDraftDestroy } from '../api/hooks';
import { usePath } from '../../api';

type Props = {
  draft: boolean;
  ruleset?: Ruleset.Type;
};

type Type = React.FC<Props>;

export const Delete: Type = ({ draft, ruleset }) => {
  const base = usePath(ruleset?.id);
  const destroy = useDraftDestroy(ruleset?.id);
  const history = useHistory();
  const [open, show] = useState(false);

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  useEffect(() => {
    if (destroy.result.status === 'success') {
      history.push(base);
    }
  }, [base, history, destroy.result.status]);

  return (
    <>
      <PageLoading enabled={destroy.result.status === 'loading'} />
      <Button
        disabled={useReadOnly()}
        data-testid='assess-ruleset-version-actions-delete'
        kind='secondary'
        size='sm'
        trans='verbs.delete'
        onClick={handleClick}
      />
      <Dialog
        open={open}
        draft={draft}
        ruleset={ruleset}
        onDelete={destroy.call}
        onClose={handleClose}
      />
    </>
  );
};

export default Delete;
