/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getStatusTypeForUser } from '@dashboard-experience/utils';
import { CellProps } from './types';

const Adjudication: React.FC<CellProps> = ({ report, currentUser }) => {
  const { account } = currentUser;
  const adjudication = report?.adjudication;
  const statusType = getStatusTypeForUser(currentUser);

  const displayActionRequired =
    account?.collaboration_suite &&
    report?.report_tasks.includes('ActionRequired');

  return (
    <div data-testid='search-cell-adjudication'>
      {adjudication ? (
        <M.StatusBadge
          data-testid='search-cell-adjudication-status'
          statusType={statusType}
          status={adjudication}
        />
      ) : null}
      {displayActionRequired && (
        <M.StatusBadge
          data-testid='search-cell-action-required'
          status='Needs_follow-up'
        />
      )}
    </div>
  );
};

export default Adjudication;
