import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Image from '../../assets/americans-with-criminal-record.png';

const StyledImage = styled.img`
  width: 150px;
  height: 150px;
  background: ${colors.uiGrey0};
  margin-left: 4rem;
`;

const AmericansImage: React.FC = () => {
  return <StyledImage src={Image} alt={Image} />;
};

export default AmericansImage;
