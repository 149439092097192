import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import LoadingCell from './LoadingCell';

const EmptyTableBody = ({ colSpan, isFetching = false }) => (
  <M.TableBody>
    <M.TableRow>
      <M.TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
        {isFetching ? <LoadingCell /> : 'No Entries'}
      </M.TableCell>
    </M.TableRow>
  </M.TableBody>
);

EmptyTableBody.propTypes = {
  colSpan: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
};

EmptyTableBody.defaultProps = {
  isFetching: false,
};

export default EmptyTableBody;
