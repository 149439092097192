/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateParentWindowUrl } from 'utils';
import querystring from 'querystring';

export type LocationWatcherParams = {
  reload?: boolean;
};

const useLocationWatcher = ({ reload }: LocationWatcherParams = {}) => {
  const contextId = useSelector((state: any) => state.ui?.contextId);
  const location = useLocation();
  const { pathname: path } = location;
  const search = querystring.parse(location.search.replace(/^\?/, ''));

  useEffect(() => {
    if (!contextId) {
      return;
    }

    updateParentWindowUrl({
      contextId,
      path,
      reload,
      search,
    });
  }, [contextId, path, reload, search]);
};

export default useLocationWatcher;
