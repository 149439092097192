import * as Yup from 'yup';
import ValidationErrors from './types';

export const containsPOBox = (string: string) =>
  /(p(\s*\.?\s*|\s+)(o|0)?(\s*\.?\s*|\s+)((st|stal)\b|\s?)\s*(?:(o|0)(ffice)?)?\s+b(o|0)x\s+\w+)/i.test(
    string,
  );

export const WorksiteValidations = Yup.object().shape({
  worksiteName: Yup.string().required(ValidationErrors.REQUIRED),
  street1: Yup.string()
    .required(ValidationErrors.REQUIRED)
    .test(
      'not-po-box',
      ValidationErrors.INVALID_ADDRESS,
      value => !containsPOBox(value || ''),
    ),
  street2: Yup.string().test(
    'not-po-box',
    ValidationErrors.INVALID_ADDRESS,
    value => !containsPOBox(value || ''),
  ),
  city: Yup.string().required(ValidationErrors.REQUIRED),
  state: Yup.string().required(ValidationErrors.REQUIRED),
  zipCode: Yup.string()
    .matches(/(^\d{5}$)/, {
      message: ValidationErrors.INVALID_ZIP_CODE,
    })
    .required(ValidationErrors.REQUIRED),
});

export default WorksiteValidations;
