import React from 'react';
import { Provider } from 'components/Invitations';
import View from './View';

type Props = {
  account: {
    id: string;
    hierarchy_present: boolean;
    segmentation_enabled: boolean;
    [key: string]: any;
  };
};

const New: React.FC<Props> = ({ account }) => {
  return (
    <Provider account={account}>
      <View />
    </Provider>
  );
};

export default New;
