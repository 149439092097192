import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { ASSESS_SALES_EMAIL } from 'modules/assess/v2/constants';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';

type Props = {
  className?: string;
  eventProperty: Amplitude.Type;
};

const Container = styled.div`
  color: ${colors.brandNavy2};
  font-weight: bold;
  margin-top: 20px;
`;

const PremiumUpgrade: React.FC<Props> = ({ className, eventProperty }) => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('common.upgrade.premium', {
    returnObjects: true,
  });
  const track = useTrackEvent();
  const mail = `mailto:${ASSESS_SALES_EMAIL}?subject=${translations.email.subject}&body=${translations.email.body}`;

  const trackUpgrade = useCallback(() => {
    track(Amplitude.Common.PremiumUpgrade, {
      [Amplitude.Common.Page]: eventProperty,
    });
  }, [eventProperty, track]);

  return (
    <Container
      className={className}
      data-testid='assess-v2-ui-common-components-premium-upgrade'
    >
      <a
        href={mail}
        onClick={trackUpgrade}
        data-testid='assess-v2-ui-common-components-premium-upgrade-link'
      >
        {translations.link}
      </a>
    </Container>
  );
};

export default PremiumUpgrade;
