import { Namespace } from 'modules/assess/api/constants';
import React from 'react';
import Activate from '../activate';
import { NamespaceRoute } from '../router/context';
import * as CategorySets from './category-sets';
import Rulesets from '../rulesets';

type Props = {};

const namespace = Namespace.mvr;

export const Home: React.FC<Props> = () => {
  return (
    <NamespaceRoute.Provider value={namespace}>
      <Activate />
      <Rulesets />
      <CategorySets.Collection />
    </NamespaceRoute.Provider>
  );
};

export default Home;
