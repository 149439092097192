import { AnyQueryKey, useQuery } from 'react-query';

import { Namespace } from 'modules/assess/api';
import * as ruleset from 'modules/assess/api/ruleset';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useFetch = (id?: string) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'versions'];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.versions.fetch(context, id);
  };

  const result = useQuery(key, fetch);
  return result;
};
