// Experimental new filter for Hack Week 2022 - remove if we decide not to productize
import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { saveSearch, updateSearchParams } from 'actions/SearchActions';

import { M, colors, sizes } from '@dashboard-experience/mastodon';
import { GenericObject } from 'types';

import styled from 'styled-components';

import { v4 as uuid } from 'uuid';
import { useSearchState } from 'components/Search/search-context';
import BaseFilter from './BaseFilter';
import { getSelectionValue } from '../helpers';
import Skeleton from './Skeleton';
import { useReduxState } from '../hooks';

// TODO: this block should be a new component in Mastodon
const labelText = ''; // 'Saved Search';

export const SavedSearchFilter = forwardRef<HTMLSelectElement, GenericObject>(
  ({ id = 'saved-search-id-select', savedSearches, ...other }, ref) => {
    return (
      <M.FilterSelect
        id={id}
        labelText={labelText}
        name='saved_search'
        ref={ref}
        unselectedText='Select a saved search'
        {...other}
      >
        {M.FilterSelect.renderFilters(savedSearches, 'saved_searches')}
      </M.FilterSelect>
    );
  },
);

SavedSearchFilter.displayName = 'SavedSearchFilter';
// TODO end mastodon block

const SaveSearchLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.uiNavy600};
  font-size: ${sizes.medium};
  line-height: 20px;
  margin-left: 16px;
  margin-right: 16px;
  svg {
    margin-left: 0.5rem;
  }
`;

const SavedSearchId = () => {
  const dispatch = useDispatch();
  const { savedSearches, savedSearchesLoading, defaultSavedSearch } =
    useSearchState();

  // when we get the default saved search ID from context,
  // update the search params to that value
  const [usedDefaultsState, setUsedDefaultsState] = useState<boolean>();
  useEffect(() => {
    if (!defaultSavedSearch) return;
    if (!usedDefaultsState) {
      dispatch(updateSearchParams({ saved_search_id: defaultSavedSearch }));
      setUsedDefaultsState(true);
    }
  }, [dispatch, usedDefaultsState, setUsedDefaultsState, defaultSavedSearch]);

  const eventToValue = getSelectionValue;
  const stateToValue = useCallback(
    (state: any) => state || defaultSavedSearch || '',
    [defaultSavedSearch],
  );
  const valueToState = useCallback(
    (value: any) => savedSearches.find(s => s.id === value),
    [savedSearches],
  );

  const { searchParams: currentSearchParams } = useReduxState();
  const savedSearchId = currentSearchParams?.saved_search_id;

  const [newSavedSearchName, setNewSavedSearchName] = useState();
  const saveSearchOnClick = useCallback(() => {
    dispatch(
      saveSearch({
        id: uuid(),
        description: newSavedSearchName,
        params: currentSearchParams,
      }),
    );
  }, [currentSearchParams, dispatch, newSavedSearchName]);

  const onSavedSearchNameChanged = useCallback(
    e => {
      setNewSavedSearchName(e.target.value);
    },
    [setNewSavedSearchName],
  );

  return (
    <>
      {savedSearches?.length > 0 && !savedSearchesLoading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BaseFilter
            component={SavedSearchFilter}
            param='saved_search_id'
            savedSearches={savedSearches}
            eventToValue={eventToValue}
            stateToValue={stateToValue}
            valueToState={valueToState}
          />
          {!savedSearchId && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SaveSearchLink onClick={saveSearchOnClick}>
                Save the search
              </SaveSearchLink>
              <M.TextInput
                id='saved-search-name'
                placeHolder='Enter a name'
                onChange={onSavedSearchNameChanged}
              />
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      <Skeleton isLoading={savedSearchesLoading} />
    </>
  );
};

export default SavedSearchId;
