import React, { useRef, useCallback } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  label: string;
  onChange: (event: any) => void;
};

const Button = styled.button`
  background: none;
  color: ${colors.uiNavy600};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

const FileLabel: React.FC<Props> = ({ label, onChange, ...props }) => {
  const fileInputRef = useRef(null);

  const handleClick = useCallback(
    () => (fileInputRef?.current as any).click(),
    [fileInputRef],
  );

  return (
    <div {...props} data-testid='file-label'>
      <Button type='button' onClick={handleClick}>
        {label}
      </Button>
      <input
        onChange={onChange}
        multiple={false}
        ref={fileInputRef}
        type='file'
        hidden
      />
    </div>
  );
};

export default FileLabel;
