import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Logo = styled.img`
  width: 48px;
  border-radius: 6px;
`;

export const ContainerRow = styled(M.Container.Row)`
  padding: 0 !important;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCol = styled(M.Container.Col)`
  max-width: 64px !important;
`;

export const StyledButton = styled(M.Button)`
  float: right;
`;

export const StyledLink = styled(M.Link)`
  color: ${colors.uiNavy600};

  &:hover,
  &:visited {
    color: ${colors.uiNavy700};
  }
`;

export const StyledP = styled.p`
  font-size: 85% !important;
  margin-top: 0 !important;
`;

export const StyledH5 = styled.h5`
  margin-bottom: 0 !important;
`;
