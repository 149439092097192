import React from 'react';
import { Namespace } from 'modules/assess/api';
import { category as findRuleCategory } from 'modules/assess/models/criminal/rules/rules';
import { useNamespace } from 'modules/assess/ui/router/context';
import { getCategoriesByNamespace } from 'modules/assess/v2/models/rules/standard/rules';
import { Row } from 'modules/assess/v2/ui/common/premade/rule/Row';
import Category from './category';

export type Props = {
  rows: Array<Row>;
};

export const List: React.FC<Props> = ({ rows }) => {
  // TODO: Handle MVR as well when developing assess premium v2.
  const namespace = useNamespace() || Namespace.criminal;
  const categories = getCategoriesByNamespace(namespace);

  const categorySections = categories.map((category: string) => (
    <Category
      key={category}
      category={category}
      namespace={namespace}
      rules={getCategoryRules(rows, category)}
    />
  ));

  return (
    <div data-testid='assess-v2-ui-guideline-quickstart-added-guidelines-list-category-sections'>
      {categorySections}
    </div>
  );
};

function getCategoryRules(rows: Array<Row>, category: string) {
  const categoryRules = rows.filter(
    row => findRuleCategory(row.rule.code) === category,
  );
  return categoryRules;
}

export default List;
