import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
// import { namespace } from 'modules/adjudication/locales';
import Content from './content';
import { useModalState } from '../context';
import ModalFooter from './footer';

const StyledModal = styled(M.ComposedModal)`
  #mastodon && {
    .cds--modal-container {
      max-width: 50rem;
    }
  }
`;

const Modal = () => {
  // const { t } = useTranslation();
  const { navigation } = useModalState('state');
  const header = 'Fair Chance Assessment';

  const open = useModalState('open');
  const onClose = useModalState('onClose');

  return (
    <StyledModal onClose={onClose} open={open} className='actions-modal'>
      <M.ModalHeader closeModal={onClose}>
        <h2>{header}</h2>
      </M.ModalHeader>
      <Content />
      <ModalFooter {...navigation} />
    </StyledModal>
  );
};

export default Modal;
