import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import { BannerFooter } from 'components/Banner';
import moment from 'moment';
import UIContext from 'context/UI';
import { postMessageToDashboard, scrollToTop } from 'utils';
import { useAdverseActions } from 'modules/adjudication/api';
import { AdverseAction } from 'modules/adjudication/data';
import { useReport } from 'containers/Report';
import FairChanceModal from 'modules/adjudication/ui/report/fair-chance-initiative-update/modal';

const TimeRemaining = styled.span`
  display: inline-block;
  margin-left: 1rem;
  margin-top: 0.7rem;
  font-style: italic;
  color: ${colors.uiGrey500};
`;

const CandidateReportFairChanceBanner: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { contextId, isIframe } = useContext(UIContext);
  const report = useReport();
  const { adverseAction } = useAdverseActions(report?.id);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpen]);

  const showFairChanceInitiativeUpdate = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'open_fci_update_modal',
      });
    } else {
      handleOpen();
    }
    // else open react FCI Update modal here
  }, [contextId, isIframe]);

  const showFCIUpdateWarning = () =>
    adverseAction &&
    adverseAction.individualized_assessment_engaged &&
    adverseAction.status === 'pending';

  return showFCIUpdateWarning() ? (
    <M.ActionableNotification
      hideActionButton
      inline='classic'
      kind='warning'
      title={t(`banners.candidateReportFairChance.title`)}
      subtitle={t(`banners.candidateReportFairChance.subtitle`)}
      style={{ maxWidth: '100%' }}
      hideCloseButton
    >
      <BannerFooter>
        <M.Button kind='secondary' onClick={showFairChanceInitiativeUpdate}>
          Update Assessment
        </M.Button>

        <CandidateReportFairChanceBannerTimeRemaining
          adverseAction={adverseAction}
        />
      </BannerFooter>
      {open && <FairChanceModal onClose={handleClose} open={open} />}
    </M.ActionableNotification>
  ) : null;
};

type CandidateReportFairChanceBannerTimeRemainingProps = {
  adverseAction?: AdverseAction;
};

export const CandidateReportFairChanceBannerTimeRemaining: React.FC<
  CandidateReportFairChanceBannerTimeRemainingProps
> = ({ adverseAction }) => {
  if (!adverseAction || adverseAction.pause_data) {
    return null;
  }

  const scheduledEvent = adverseAction.events
    ?.slice()
    .reverse()
    .find(
      e => e.event_type === 'scheduled' && e.notice === 'post_adverse_action',
    );

  const completionTime = moment(
    scheduledEvent?.metadata?.scheduled_at ||
      adverseAction.post_notice_scheduled_at ||
      adverseAction.post_notice_ready_at,
  );

  if (!completionTime.isValid() || completionTime.isSameOrBefore(moment())) {
    return null;
  }

  const time = completionTime.fromNow(true);

  return <TimeRemaining>{`${time} remaining`}</TimeRemaining>;
};

export default CandidateReportFairChanceBanner;
