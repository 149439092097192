import React, { SyntheticEvent, useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';
import * as Steps from './steps';

type Props = {
  open: boolean;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:activate.title');
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [Steps.StepReview, Steps.StepInform, Steps.StepActivate];
  const currentStep = steps[stepIndex];

  const handleBack = useCallback(
    () => setStepIndex(Math.max(0, stepIndex - 1)),
    [setStepIndex, stepIndex],
  );

  const handleNext = useCallback(
    () => setStepIndex(Math.min(steps.length - 1, stepIndex + 1)),
    [setStepIndex, steps.length, stepIndex],
  );

  return (
    <M.ComposedModal
      data-testid='assess-activate-dialog'
      onClose={onClose}
      open={open}
    >
      <M.ModalHeader
        data-testid='assess-activate-dialog-title'
        title={modalTitle}
        closeModal={onClose}
      />
      <M.ModalBody data-testid='assess-activate-dialog-content'>
        <Content
          stepIndex={stepIndex}
          totalSteps={steps.length}
          currentStep={currentStep}
          onClose={onClose}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-activate-dialog-actions'>
        <Actions
          onBack={handleBack}
          onNext={handleNext}
          onClose={onClose}
          stepIndex={stepIndex}
          totalSteps={steps.length}
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
