import React from 'react';
import styled from 'styled-components';

export type Type = React.FC<Props>;

export type Props = {
  padding?: string;
};

const Content: Type = styled.div<Props>`
  padding: ${({ padding }) => padding || '0.5rem'};

  > * {
    margin: 1rem 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

export default Content;
