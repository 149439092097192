import React from 'react';

import {
  ActionContainer,
  Button,
  ButtonContainer,
  Description,
  DescriptionContainer,
  ItemWrapper,
  Spinner,
  SpinnerContainer,
} from './styles';
import SyncResult from './SyncResult';
import { Status } from '../types';

type Props = {
  name: string;
  syncId: string;
  status: Status;
  onSync: (syncId: string) => void;
};

const SyncItem: React.FC<Props> = ({ name, status, syncId, onSync }: Props) => {
  const onClick = React.useCallback(() => {
    onSync(syncId);
  }, [onSync, syncId]);

  const isSyncing = status === 'Syncing';
  return (
    <ItemWrapper
      status={status}
      data-testid={`ats-sync-item-wrapper-${syncId}`}
    >
      <DescriptionContainer>
        <Description data-testid={`ats-sync-item-description-${syncId}`}>
          {name}
        </Description>
      </DescriptionContainer>
      <ButtonContainer>
        <ActionContainer>
          {isSyncing ? (
            <SpinnerContainer data-testid={`ats-sync-item-spinner-${syncId}`}>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <Button
              data-testid={`ats-sync-item-button-${syncId}`}
              onClick={onClick}
            >
              Sync
            </Button>
          )}
          <SyncResult status={status} syncId={syncId} />
        </ActionContainer>
      </ButtonContainer>
    </ItemWrapper>
  );
};

export default SyncItem;
