const initialState = {
  reorderableExams: [],
  appointmentDeadline: undefined,
  allowNewAppointment: undefined,
  selectNewClinic: undefined,
  clinic: undefined,
  zipcode: '',
  addExamError: undefined,
};

export default initialState;
