import { ID } from 'modules/id';
import React from 'react';
import Mappings from '../../mvr/category-sets/instance/version/mappings';

export type Props = {
  disabled?: boolean;
  instance?: ID;
  version?: ID;
};

const style = { width: '60em' };

const CategorySet: React.FC<Props> = ({ instance, version }) => {
  if (!instance || !version) {
    return null;
  }

  return (
    <Mappings
      instance={instance}
      style={style}
      tab='underline'
      version={version}
    />
  );
};

export default CategorySet;
