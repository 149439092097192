import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  state: boolean;
  text: String;
};

const Span = styled.span`
  margin-left: 0.3rem;
`;

export const ToogledChangeState: React.FC<Props> = ({ state, text }) => {
  const color = state ? colors.uiGreen400 : colors.uiGrey300;

  return (
    <>
      <M.Icon
        icon='CircleSolid'
        size='16'
        color={color}
        style={{ marginBottom: '-0.1rem' }}
      />
      <Span>{text}</Span>
    </>
  );
};

export default ToogledChangeState;
