import React from 'react';
import styled from 'styled-components';
import { Item } from 'modules/assess/utilities/hierarchy';
import Headers from 'modules/assess/v2/ui/common/lookback-rules/table/Headers';
import Subcategory from 'modules/assess/v2/ui/common/lookback-rules/subcategory';

type Props = {
  items: Item[];
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container: React.FC<Props> = ({ items }) => {
  const subcategories = items.map(item => (
    <Subcategory key={item.id} item={item} showUpgrade />
  ));

  return (
    <StyledContainer data-testid='assess-v2-ui-guideline-lookback-period-table-subcategories-container'>
      <Headers />
      {subcategories}
    </StyledContainer>
  );
};

export default Container;
