import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  State,
  US_STATES,
} from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import { CandidatePostalAddress } from 'types';

const AddressFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .address-field {
    flex: 1;
    margin-right: 1rem;
    min-width: 15rem;
  }
`;

type Props = {
  handleAddress: Function;
  address: CandidatePostalAddress;
};

const AddressManual: React.FC<Props> = ({ handleAddress, address }) => {
  const handleStreetChange = useCallback(
    e => {
      handleAddress({ ...address, street: e.target.value });
    },
    [address, handleAddress],
  );

  const handleStreet2Change = useCallback(
    e => {
      handleAddress({ ...address, street2: e.target.value });
    },
    [address, handleAddress],
  );

  const handleCityChange = useCallback(
    e => {
      handleAddress({ ...address, city: e.target.value });
    },
    [address, handleAddress],
  );

  const handleStateChange = useCallback(
    e => {
      const state = e.selectedItem ? e.selectedItem.abbreviation : '';

      handleAddress({
        ...address,
        state,
      });
    },
    [address, handleAddress],
  );

  const handleZipcodeChange = useCallback(
    e => {
      handleAddress({ ...address, zipcode: e.target.value });
    },
    [address, handleAddress],
  );

  return (
    <div>
      <AddressFieldContainer>
        <M.Input
          className='address-field'
          id='street'
          labelText=''
          placeholder='Street'
          defaultValue={address.street}
          onChange={handleStreetChange}
        />
        <M.Input
          className='address-field'
          id='street2'
          labelText=''
          placeholder='Unit/Suite'
          defaultValue={address.street2}
          onChange={handleStreet2Change}
        />
        <M.Input
          className='address-field'
          id='city'
          labelText=''
          placeholder='City'
          defaultValue={address.city}
          onChange={handleCityChange}
        />
        <M.ComboBox
          className='address-field'
          id='state'
          items={US_STATES}
          itemToString={useCallback((item: State) => item?.name, [])}
          placeholder='State'
          initialSelectedItem={US_STATES.find(
            state => state.abbreviation === address.state,
          )}
          onChange={handleStateChange}
        />
        <M.Input
          className='address-field'
          id='zipcode'
          labelText=''
          placeholder='Zipcode'
          defaultValue={address.zipcode}
          onChange={handleZipcodeChange}
        />
      </AddressFieldContainer>
    </div>
  );
};

export default AddressManual;
