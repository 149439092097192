import React, { useCallback, useEffect } from 'react';
import { M, TrashCan } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { DataTableCell } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { GenericObject } from 'types';

import { useDeleteGeo, useUpdateGeo } from 'api/geos';
import CityCell from './CityCell';

const TableRow = styled(M.TableRow)`
  .mastodon-button.danger {
    float: right;
  }
`;

const GeoRow: React.FC<GenericObject> = ({
  row,
  geo = {}, // Default empty object to safely and simply handle edge cases of loading new data
  getRowProps,
  getRelevantCities,
  refetchPage,
}) => {
  const { id: geoId, state: geoState } = geo;

  const { updateGeoCall, updateGeoResult } = useUpdateGeo();

  const { t } = useTranslation('', {
    keyPrefix: 'screening_settings.geos.table',
  });

  // Called when the user submits a new city for the Geo
  const saveGeoDetails = useCallback(
    newCity => {
      const updatedGeo = { ...geo, city: newCity.name };

      // Confirm that they know they can only do this once for a geo, before submitting to server
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm(t('city_confirm'))) {
        updateGeoCall(updatedGeo);
      }
    },
    [geo, t, updateGeoCall],
  );

  useEffect(() => {
    // Upon successfully saving the new geo details, call for a refetch of the page
    if (updateGeoResult.isSuccess) {
      refetchPage();
      updateGeoResult.reset();
    }
  }, [refetchPage, updateGeoResult]);

  const { deleteGeoCall, deleteGeoResult } = useDeleteGeo();
  const deleteClicked = useCallback(() => {
    // Double-check with the user before actually submitting the deletion
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(t('delete_confirm'))) {
      deleteGeoCall(geoId);
    }
  }, [deleteGeoCall, geoId, t]);

  useEffect(() => {
    // Upon successfully deleting a geo, call for a refetch of the page
    if (deleteGeoResult.isSuccess) {
      refetchPage();
    }
  }, [refetchPage, deleteGeoResult]);

  // Flag used to disable interactions on a row if it has any pending requests
  const hasPendingRequest: boolean = updateGeoResult.isLoading || deleteGeoResult.isLoading; // prettier-ignore

  return (
    <TableRow key={row.id} {...getRowProps({ row })}>
      {row.cells.map((cell: DataTableCell) => {
        // Cities need unique handling because they might be a dropdown menu instead of just a plain display
        const isCityCell = cell.info.header === 'city';

        return (
          <M.TableCell key={cell.id}>
            {!isCityCell ? (
              cell.value
            ) : (
              <CityCell
                geoState={geoState}
                cell={cell}
                getRelevantCities={getRelevantCities}
                saveGeoDetails={saveGeoDetails}
                isPending={hasPendingRequest}
              />
            )}
          </M.TableCell>
        );
      })}

      <M.TableCell>
        <M.Button
          data-testid='delete-geo'
          kind='danger'
          onClick={deleteClicked}
          disabled={hasPendingRequest}
          hasIconOnly
          renderIcon={TrashCan}
          iconDescription={t('delete_title')}
        />
      </M.TableCell>
    </TableRow>
  );
};

export default GeoRow;
