const SUBCATEGORY_CVS_EXPORT_LINK =
  'https://us-east-1-checkr-documents.s3.amazonaws.com/assess/lookback_rules/subcategory/template.csv';

const ASSESS_SALES_EMAIL = 'assess-sales@checkr.com';

const ASSESS_STANDARD_PRODUCT_PAGE =
  'https://checkr.com/customer/assess-standard';

const FEATURES_OF_ASSESS_PAGE =
  'https://help.checkr.com/hc/en-us/articles/14197799992215-Features-of-Assess';

const ASSESS_PRODUCTS_PAGE = 'https://checkr.com/products/assess';

const HOW_ASSESS_WORKS_PAGE =
  'https://help.checkr.com/hc/en-us/sections/18368905710615-Assess';

export {
  ASSESS_PRODUCTS_PAGE,
  ASSESS_SALES_EMAIL,
  ASSESS_STANDARD_PRODUCT_PAGE,
  FEATURES_OF_ASSESS_PAGE,
  HOW_ASSESS_WORKS_PAGE,
  SUBCATEGORY_CVS_EXPORT_LINK,
};
