import { ID } from '../../models/entity';
import client from '../client';

const BASE_PATH = 'mvr/ccs';

export const path = (instance?: ID, version?: ID) => {
  let path = `/${BASE_PATH}`;

  if (instance) {
    path += `/${instance}`;

    if (version) {
      path += `/versions/${version}`;
    }
  }

  return path;
};

export const uri = (instance?: ID, version?: ID): string => {
  return client.uri({ url: path(instance, version) });
};
