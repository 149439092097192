import { colors } from '@dashboard-experience/mastodon';
import { EventView } from 'modules/adjudication/data';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const TimelineEventContainer = styled.article`
  display: block;
  position: relative;
  margin: 0px 0px 0px 22px;
  clear: both;
  line-height: 1.43;
  color: ${colors.uiGrey700};

  .events-timeline-icon {
    position: absolute;
    left: -22px;
    top: 5px;
    display: block;
    width: 9px;
    height: 9px;
    padding: 0px;
    border-radius: 50%;
    text-align: center;
    float: left;
    background: ${colors.uiGrey200};
  }
`;

const Event: React.FC<{
  event: EventView;
  t: Function;
}> = ({ event, t }) => {
  const timezone = moment.tz.guess();

  return (
    <TimelineEventContainer data-testid='adverse-action-timeline-item-action'>
      <div className='events-timeline-icon' />
      <div style={{ paddingBottom: '15px' }}>
        <strong style={{ fontWeight: 'bold' }}>{event.text}</strong>
        <span style={{ color: colors.uiGrey500, fontSize: '12px' }}>
          {' '}
          {moment.tz(event.time, timezone).format('MMM. DD, YYYY h:mm a z')}
        </span>
        {event.byline && (
          <div>
            <span style={{ whiteSpace: 'pre-wrap' }}>{event.byline}</span>
          </div>
        )}
        {event.reason && (
          <div>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {`${t('adjudication:report.events.reason')}: ${event.reason}`}
            </span>
          </div>
        )}
        {event.post_notice_unscheduled && (
          <div>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {t('adjudication:report.events.post_notice_unscheduled')}
            </span>
          </div>
        )}
        {event.pdf_url && (
          <div>
            <a target='_blank' href={event.pdf_url} rel='noreferrer'>
              {t('adjudication:report.events.view_pdf', {
                notice: event.notice,
              })}
            </a>
          </div>
        )}
        {event.pdf_encrypted && (
          <div>
            <small style={{ fontSize: '12px', color: colors.uiGrey500 }}>
              {t('adjudication:report.events.password_protected_pdf')}
            </small>
          </div>
        )}
      </div>
    </TimelineEventContainer>
  );
};

export default Event;
