import React, { useCallback } from 'react';
import { UserType } from 'modules/case-management/types';
import UnassignOption from '../User/UnassignOption';
import User from '../User/User';

type Props = {
  onSelect: (user: UserType | null) => void;
  user: UserType | 'unassigned';
};

const Option = ({ onSelect, user }: Props) => {
  const selectUser = useCallback(() => {
    let assignee = null;
    if (user !== 'unassigned') {
      assignee = user;
    }
    onSelect(assignee);
  }, [onSelect, user]);

  if (user === 'unassigned') {
    return <UnassignOption key='unassigned' onSelect={selectUser} />;
  }

  return (
    <button
      data-testid={`case-management-tasks-page-user-${user.id}`}
      type='button'
      key={user.id}
      onClick={selectUser}
    >
      <User user={user} />
    </button>
  );
};

export default Option;
