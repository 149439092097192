import React from 'react';

export type Context = React.Context<Array<RouteParams>>;

export type RouteParams = {
  location: string;
  title: string;
};

const initial: Array<RouteParams> = [];
const context: Context = React.createContext(initial);

export default context;
