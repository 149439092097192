import { AnyQueryKey, useQuery } from 'react-query';
import { fetchConfiguration } from 'modules/assess/api/accounts';

const namespace = 'config';

export const useConfig = (accountId?: string) => {
  const key: AnyQueryKey = [namespace, { accountId }];

  const request = () => {
    if (!accountId) {
      return Promise.reject();
    }
    return fetchConfiguration(accountId);
  };

  const result = useQuery(key, request);
  return result;
};
