/* eslint-disable import/prefer-default-export */
import { GenericObject } from '@dashboard-experience/utils';
import { Client } from 'api';

export const fetchTimeline = (
  contextName: string,
  contextId: string,
): Promise<GenericObject> => {
  return Client.get(`/${contextName}/${contextId}/timeline`);
};
