import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { ASSESS_STANDARD_PRODUCT_PAGE } from 'modules/assess/v2/constants';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';

type Props = {
  eventProperty?: Amplitude.Type;
};

const StyledButton = styled.button`
  background-color: ${colors.brandAqua3};
  border: none;
  color: ${colors.uiGrey0};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background-color: ${colors.brandAqua4};
    cursor: pointer;
  }
`;

export const LearnMoreButton: React.FC<Props> = ({ eventProperty }) => {
  const track = useTrackEvent();

  const { t } = useTranslation('assess:v2');
  const buttonText = t(`common.upgrade.standard.button`);

  const onClick = useCallback(() => {
    track(Amplitude.Common.StandardUpgrade, {
      [Amplitude.Common.Page]: eventProperty,
    });
    window.open(ASSESS_STANDARD_PRODUCT_PAGE, '_blank');
  }, [eventProperty, track]);

  return (
    <StyledButton
      color={colors.brandAqua4}
      data-testid='assess-v2-ui-standard-upgrade-button'
      onClick={onClick}
    >
      {buttonText}
    </StyledButton>
  );
};

export default LearnMoreButton;
