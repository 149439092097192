import { AxiosError } from 'axios';

/**
 * Check whether the current network code is one to ignore.
 * There are many endpoints from which certain errors (particularly 404s) are totally expected, and don't merit displaying a toast to the user
 * @param code - The incoming network code, if applicable
 * @param ignoreCodes - The set of network codes to be ignored
 * @returns {boolean} whether the code should be ignored or not
 */
export const codeIsIgnored = (
  code?: Number,
  ignoreCodes?: Number | Number[],
): boolean => {
  // If this wasn't a network-related error, or if there are no codes to be ignored
  if (!code || !ignoreCodes) {
    return false;
  }

  // If the ignore list is just a single number, compare directly against that
  if (typeof ignoreCodes === 'number') {
    return code === ignoreCodes;
  }

  // If the ignore list is an actual array, then see if the code is contained within
  if (Array.isArray(ignoreCodes)) {
    return ignoreCodes.includes(code);
  }

  return false;
};

/**
 * Builds an appropriate title for an error toast
 * @param {string} name - a name for the error, as defined by the network response
 * @param {number | undefined} code - The network code for the error
 * @param {string} noun - Localized version of the word "Error"
 * @param {string | undefined} customTitle - an optional desired title for a particular toast, as defined by wherever the error handler got called
 * @returns {string} The finalized title for the toast message
 */
export const buildTitle = (
  name: string,
  code: number | undefined,
  noun: string,
  customTitle: string | undefined,
): string => {
  if (customTitle) {
    return customTitle;
  }
  if (!code) {
    return name;
  }
  return `${noun} ${code}: ${name}`;
};

/**
 * Builds the appropriate body text for an error toast, iterating through all different possible shapes of error responses
 * @param {any | undefined} data - the data that came back from the server (if possible) regarding the error
 * @param {string} statusCodeString - Localized text describing the network error, if applicable
 * @param {string} fallbackErrorMessage - A fallback string to use if there was no other error message generated
 * @returns {string} The finalized body for the toast message
 */
export const buildMessage = (
  data: any | undefined,
  statusCodeString: string,
  fallbackErrorMessage: string,
): string => {
  let messageList: string[] = [];

  // If we got an actual response from the server, use that
  if (data) {
    const { message, errors } = data;

    // Iterate through the different possibilities of what the server would send back for an error
    if (message) {
      if (Array.isArray(message)) {
        // Ordinarily the `message` is actually an array
        messageList = message.map((m: any) => m.message);
      } else {
        // In some cases (notably Assess), it is just a string
        messageList = [message];
      }
    } else if (errors?.length) {
      messageList = errors.filter((e: any[]) => typeof e === 'string');
    } else if (data.length > 0) {
      messageList = [data];
    }
  }

  // If the server didn't send anything useful back, then try to fall back to the network code itself
  else if (statusCodeString) {
    messageList = [statusCodeString];
  }

  // If the prior checks resulted in an actual error message, then use that. Otherwise, rely on the fallback message
  return messageList.length ? messageList.join('. ') : fallbackErrorMessage;
};

/**
 * Format an Axios error in ordet to have a standard title and error format.
 * @param error - Error instance of an AxiosError
 * @param t - Translate function from react-i18next
 * @returns {ErrorObject} Formatted error message { title, message, statusCode }
 */
export const formatAxiosError = (
  error: AxiosError,
  t: (k: string, d?: string) => string,
) => {
  const {
    response: { status: statusCode, statusText, data } = {},
    name: errName,
    message: fallbackErrorMessage,
  } = error;

  const statusCodeString = t(`http.codes.${statusCode}`, '');
  const noun = t('nouns.error');
  const name = statusText ?? errName;

  const title = buildTitle(name, statusCode, noun, undefined);
  const message = buildMessage(data, statusCodeString, fallbackErrorMessage);

  return { message, statusCode, title };
};

export type ErrorObject = {
  message: string;
  title: string;
  statusCode?: number;
};
