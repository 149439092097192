import React, { useState, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ConfirmModal } from 'components';
import { scrollToTop } from 'utils';
import { SIGNUP_FLOW_BASE_URI } from 'Constants';
import { useDeletePartnerApplication } from 'api/partners/hooks';
import EditApplicationModal from './EditApplicationModal';
import Logo from './Logo';
import {
  ApplicationTable,
  StyledButton,
  StyledDeleteIcon,
  StyledEditIcon,
  ApplicationValueCell,
  FormControl,
  StyledSpan,
  TableHeader,
  TableRow,
  TableCell,
} from './DirectoryStyledComponents';
import { ColorDisplay, StyledDiv } from './StyledComponents';

type ApplicationDetailsProps = {
  application: {
    account_id: string;
    name: string;
    description: string;
    url: string;
    primary_color: string;
    redirect_uri: string;
    client_id: string;
    client_secret: string;
    live: boolean;
    logo_url: string;
    partner_billing: boolean;
    pre_credentialed_accounts: boolean;
    resource_id: string;
    sign_up_flow: boolean;
    webhook_signing_use_client_id: boolean;
    webhook: {
      url: string;
    };
  };
  partnerId: string;
  refetchPage: () => void;
};

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
  application,
  partnerId,
  refetchPage,
}) => {
  const {
    name,
    description,
    url,
    primary_color,
    redirect_uri,
    client_id,
    client_secret,
    webhook,
    logo_url,
    live,
    partner_billing,
    pre_credentialed_accounts,
    resource_id,
    sign_up_flow,
    webhook_signing_use_client_id,
  } = application;

  const {
    deleteApplicationCall,
    deleteApplicationResult: { isSuccess: deleteSuccessful },
  } = useDeletePartnerApplication(resource_id, partnerId);

  const [hideClientSecret, setHideClientSecret] = useState(true);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [showEditApplication, setShowEditApplication] = useState(false);

  const showDeleteModal = useCallback(() => {
    setModalDeleteOpen(true);
    scrollToTop();
  }, [setModalDeleteOpen]);

  const hideDeleteModal = useCallback(() => {
    setModalDeleteOpen(false);
  }, [setModalDeleteOpen]);

  const toggleClientSecret = useCallback(() => {
    setHideClientSecret(prev => !prev);
  }, []);

  const toggleEditModal = useCallback(() => {
    setShowEditApplication(prev => !prev);
    scrollToTop();
  }, []);

  const deleteClicked = useCallback(() => {
    // Double-check with the user before actually submitting the deletion
    deleteApplicationCall();
  }, [deleteApplicationCall]);

  useEffect(() => {
    // Upon successfully deleting an application, call for a refetch of the page
    if (deleteSuccessful) {
      refetchPage();
    }
  }, [deleteSuccessful, refetchPage]);

  return (
    <>
      <ConfirmModal
        header='Delete Application'
        message='Are you sure you want to delete this Application?'
        open={modalDeleteOpen}
        hideModal={hideDeleteModal}
        callback={deleteClicked}
        confirmMessage='Yes'
        cancelMessage='Cancel'
      />
      {showEditApplication && (
        <EditApplicationModal
          application={application}
          toggleEditModal={toggleEditModal}
          partnerId={application.account_id}
          refetchPage={refetchPage}
        />
      )}
      <ApplicationTable size='sm' data-testid='application-row'>
        <M.TableHead>
          <M.TableRow>
            <TableHeader>
              {name}
              <StyledButton
                onClick={showDeleteModal}
                data-testid='application-deleteButton'
              >
                <StyledDeleteIcon />
              </StyledButton>
              <StyledButton
                onClick={toggleEditModal}
                data-testid='application-editButton'
              >
                <StyledEditIcon />
              </StyledButton>
            </TableHeader>
            <M.TableHeader />
          </M.TableRow>
        </M.TableHead>

        <M.TableBody>
          <TableRow>
            <TableCell>Application Name</TableCell>
            <ApplicationValueCell data-testid='application-name'>
              {name}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Application Description</TableCell>
            <ApplicationValueCell>{description}</ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Application HomePage</TableCell>
            <ApplicationValueCell data-testid='application-url'>
              {url}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Application Logo</TableCell>
            <ApplicationValueCell>
              <Logo.Flex src={logo_url} altPartial={name} />
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Application Color</TableCell>
            <ApplicationValueCell>
              <ColorDisplay>
                <StyledDiv style={{ background: primary_color }} />
                <div>
                  <FormControl
                    type='text'
                    name='partnerColor'
                    value={primary_color || ''}
                    readOnly
                  />
                </div>
              </ColorDisplay>
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>OAuth redirect URL</TableCell>
            <ApplicationValueCell>{redirect_uri}</ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>OAuth client ID</TableCell>
            <ApplicationValueCell>{client_id}</ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>OAuth client secret</TableCell>
            <ApplicationValueCell>
              <span>
                {hideClientSecret
                  ? new Array(client_secret?.length).fill('*')
                  : client_secret}
              </span>
              <span>
                <M.Button
                  kind='secondary'
                  onClick={toggleClientSecret}
                  style={{ marginLeft: '1rem' }}
                >
                  {hideClientSecret ? 'Show' : 'Hide'}
                </M.Button>
              </span>
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>OAuth webhook URL</TableCell>
            <ApplicationValueCell>{webhook?.url}</ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Environment</TableCell>
            <ApplicationValueCell>
              {live ? 'Live' : 'Test'}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Bill API calls to parent account (removes payment method step)
            </TableCell>
            <ApplicationValueCell>
              {partner_billing ? 'Yes' : 'No'}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Pre-Credentialed accounts</TableCell>
            <ApplicationValueCell>
              {pre_credentialed_accounts ? 'Yes' : 'No'}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Sign-up flow</TableCell>
            <ApplicationValueCell>
              {sign_up_flow ? (
                <a
                  href={`${SIGNUP_FLOW_BASE_URI}/authorize/${client_id}/welcome`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <StyledSpan>Sign-up plus sign-in</StyledSpan>
                  <M.Icon icon='ArrowUpRight' size='16' />
                </a>
              ) : (
                'No'
              )}
            </ApplicationValueCell>
          </TableRow>
          <TableRow>
            <TableCell>Webhook Signature</TableCell>
            <ApplicationValueCell>
              Use{' '}
              {webhook_signing_use_client_id
                ? 'OAuth Client ID'
                : 'OAuth Client Secret'}{' '}
              to verify{' '}
              <a href='https://docs.checkr.com/#section/Webhooks/Securing-webhooks'>
                webhook payload signature
              </a>
            </ApplicationValueCell>
          </TableRow>
        </M.TableBody>
      </ApplicationTable>
    </>
  );
};

export default ApplicationDetails;
