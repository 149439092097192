import T from 'components/Table';
import React from 'react';
import { Entity } from 'modules/assess/ui/state/rules';
import { AddButton } from './AddButton';

type Props = {
  data?: Entity.List;
  handleAddClick: Function;
};

const columns = ['name', 'add'];

export const Table: React.FC<Props> = ({ data, handleAddClick }) => {
  const rows = data?.map(rule => ({
    ...rule,
    add: <AddButton handleAddClick={handleAddClick} rule={rule} />,
  }));

  return (
    <T
      data-testid='assess-rules-library-table'
      columns={columns}
      rows={rows}
      style={{ maxWidth: '50em' }}
    />
  );
};

export default Table;
