import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import React from 'react';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

type Props = {
  timelineEvent: TimelineEvent;
};

const eventCategory = 'Candidate Story:';

/**
 * @name getEventAction
 * @function
 * @memberOf CandidateStory
 * @description Determines the human-friendly event action
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */

const getEventAction = (timelineEvent: TimelineEvent) => {
  switch (timelineEvent.event) {
    case 'create':
      return 'Added';
    case 'update':
      return timelineEvent.to.reviewer_id ? 'Reviewed' : 'Updated';
    default:
      return null;
  }
};

const CandidateStory: React.FC<Props> = ({ timelineEvent }) => {
  const eventAction = getEventAction(timelineEvent);
  if (!eventAction) {
    return null;
  }
  return (
    <M.TableRow>
      <TimelineCell>
        <EventName>{`${eventCategory} ${eventAction}`}</EventName>
      </TimelineCell>
      <TimelineCell>
        {timelineEvent.who !== 'system' && timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default CandidateStory;
