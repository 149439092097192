// eslint-disable-next-line no-shadow
export enum ValidationErrors {
  NUMBER = 'Invalid credit card number',
  CVC = 'Invalid cvc',
  EXP_MONTH = 'Invalid month',
  EXP_YEAR = 'Invalid year',
  ROUTING = 'Invalid routing number',
  POSTAL_CODE = 'Invalid postal code',
  ACC_NUMBER = 'Invalid account number',
  EMAIL = 'Invalid email',
  AMOUNT = 'Invalid amount, the format should be 0.XX',
  REQUIRED = 'Required field',
  CONFIRM_ACC_NUMBER = 'Account numbers must match',
}

export type Ctx = {
  errors: any;
  touched: any;
  values: any;
  handleChange: any;
  handleBlur: any;
};
