import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Text } from '../Text';

const DriverRecordReportsHeader = () => (
  <M.Container.Row>
    <M.Container.Col>
      <Text>
        <span>
          Keep up with people even after the onboarding process. Once enrolled,
          you can track any changes to their driving record.
        </span>
        <br />
        <span>
          We use <strong>Employer Pull Notices (EPN)</strong>, which are created
          within 10 business days of enrollment. These update annually and if
          there are any changes to a driving record.
        </span>
        <br />
      </Text>
    </M.Container.Col>
  </M.Container.Row>
);

export default DriverRecordReportsHeader;
