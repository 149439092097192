import * as Yup from 'yup';

// eslint-disable-next-line
const validWebhookUrlProtocols = /^(https|http|sns|ftp):\/\//;

export default Yup.object().shape({
  name: Yup.string().required().min(2).label('Application name'),
  homepage: Yup.string()
    .required()
    .min(7)
    .url()
    .label('Application/Homepage URL'),
  description: Yup.string()
    .required()
    .min(2)
    .max(255)
    .label('Application description'),
  webhook: Yup.string()
    .optional()
    .nullable()
    .min(7)
    .matches(validWebhookUrlProtocols, {
      message:
        'Webhook is not valid. Please use a publicly available host and the HTTPS or SNS protocol.',
    })
    .label('Webhook URL'),
  redirect: Yup.string().required().min(7).url().label('Redirect URL'),
  'primary-color': Yup.string()
    .required()
    .matches(/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, {
      message: 'Must be a valid HEX color',
    })
    .label('Your company color'),
  logo_url: Yup.string().required().label('Logo'),
});
