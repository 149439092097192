import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const FederalCivilSearch = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}: ScreeningProps) => {
  return (
    <FlexContainer data-testid='federal-civil-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-federal-civil-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Federal Civil Records Search'
            priceByType='federal_civil_search'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Searches the US District Court level for civil cases filed in
            federal court.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='federal-civil-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360010443533'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Helps provide a comprehensive screening for financial and managerial roles' />
              <BulletPoint text='Centralized source for all federal records' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Limited to 10 years of history' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='federal_civil_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default FederalCivilSearch;
