import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'candidate-info-container',
})`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;

  @media (max-width: 992px) {
    gap: 16px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const InnerContainer = styled.div.attrs({
  className: 'candidate-info-inner-container',
})`
  display: flex;
  flex-direction: column;
  width: 66.67%;
  gap: 24px;

  @media (max-width: 992px) {
    gap: 16px;
    width: 50%;
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`;
