import { GenericObject } from '@dashboard-experience/utils';
import { Client } from 'api';
import { PATH } from './constants';

export type Instance = {
  application: {
    logo_url: string;
    name: string;
    url: string;
    live: boolean;
    id?: string;
  };
  created_at: Date | string;
  id?: string;
};

export const getApplicationInstances = (): Promise<GenericObject> => {
  return Client.get(`${PATH}`);
};

export const deleteApplicationInstance = (instance: Instance) => {
  return Client.delete(`${PATH}/${instance.id}`);
};

export const getApplicationInstance = (id: string): Promise<Instance> => {
  return Client.get(`${PATH}/${id}`);
};
