export enum Segment {
  ENTERPRISE = 'enterprise',
  MID_MARKET = 'mid market',
  NAMED = 'named',
  OTHER = 'other',
  SMB = 'smb',
  STRATEGIC = 'strategic',
}

export default Segment;
