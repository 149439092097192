type Base = {
  translatePath?: string;
};

export type FieldUnchanged<Value = string> = Base & {
  value: Value;
};

export type FieldChanged<Value = string> = Base & {
  active: Value;
  draft: Value;
};

export type Type<Value = string> = FieldUnchanged<Value> | FieldChanged<Value>;

export const isFieldUpdated = <Value>(type: Type<Value>): boolean => {
  return (
    !!(type as FieldChanged<Value>)?.active ||
    !!(type as FieldChanged<Value>)?.draft
  );
};

export default Type;
