import React from 'react';
import StepsContainer from './StepsContainer';

type StepProps = {
  className: string;
  name: string;
};

type StepsProps = {
  steps: Array<string>;
  currentStep: number;
};

const Step = ({ className, name }: StepProps) => (
  <li className={className}>
    <span>{name}</span>
  </li>
);

const Steps = ({ steps, currentStep }: StepsProps) => (
  <StepsContainer>
    <ul className='cd-multi-steps text-bottom count'>
      {steps.map((step, i) => {
        let stepClass = '';

        if (i < currentStep) {
          stepClass = 'visited';
        }

        if (i === currentStep) {
          stepClass = 'current';
        }

        return <Step key={`step-${i + 1}`} name={step} className={stepClass} />;
      })}
    </ul>
  </StepsContainer>
);

export default Steps;
