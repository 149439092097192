import React, { useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { useVerifyBA } from 'api/payment';
import {
  ErrorMsg,
  Button,
  StyledInput,
  Grid,
  GridCol,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import {
  baVerificationValidationSchema as validationSchema,
  useBAVerificationValidation as useValidation,
} from './utils';

type Props = {
  bankInfo?: any;
  setIsVerified: any;
};

const BAVerification: React.FC<Props> = ({ bankInfo, setIsVerified }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { verifyBACall, verifyBAResult }: any = useVerifyBA();
  const verificationError: string =
    'The amounts do not match our records. Please try again.';

  const onSubmit = useCallback(
    (values): void => {
      const {
        account: { id: accountId },
      } = user;
      const { id: bankAccountId } = bankInfo;

      values.verify = true;

      verifyBACall({ accountId, bankAccountId, values });
    },
    [bankInfo, user, verifyBACall],
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  const { invalid: invalidA1, invalidText: invalidTextA1 } = useValidation(
    'amount1',
    { errors, touched },
  );
  const { invalid: invalidA2, invalidText: invalidTextA2 } = useValidation(
    'amount2',
    { errors, touched },
  );

  useEffect((): void => {
    if (verifyBAResult.isSuccess) {
      setIsVerified(true);
    }
  }, [verifyBAResult.isSuccess, setIsVerified]);

  const valid = isEmpty(errors) && Boolean(Object.keys(values).length);

  return (
    <Grid>
      <M.GridRow>
        <GridCol style={{ marginRight: '24px' }}>
          <StyledInput
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={invalidA1}
            invalidText={invalidTextA1}
            labelText={t(
              `payment.bank_account_verification.payment_label_text_1`,
            )}
            placeholder={`${t(
              `payment.bank_account_verification.amount_input_placeholder`,
            )}`}
            name='amount1'
            autoComplete='off'
            data-testid='ach-verification-amount-1'
            required
          />
        </GridCol>
        <GridCol>
          <StyledInput
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={invalidA2}
            invalidText={invalidTextA2}
            labelText={t(
              `payment.bank_account_verification.payment_label_text_2`,
            )}
            placeholder={`${t(
              `payment.bank_account_verification.amount_input_placeholder`,
            )}`}
            name='amount2'
            autoComplete='off'
            data-testid='ach-verification-amount-2'
            required
          />
        </GridCol>
        <M.GridCol md={2} style={{ paddingLeft: '48px', marginTop: '20px' }}>
          {!verifyBAResult.isLoading && (
            <Button
              onClick={handleSubmit}
              disabled={!valid}
              data-testid='ach-verification-verify-btn'
            >
              {t(`payment.button_labels.verify`)}
            </Button>
          )}
          {verifyBAResult.isLoading && (
            <Button onClick={handleSubmit} style={{ width: '108px' }} disabled>
              <M.LoadingSpinner small withOverlay={false} />
              {t(`payment.bank_account_verification.spinner_label`)}
            </Button>
          )}
        </M.GridCol>
      </M.GridRow>

      {verifyBAResult.isError && (
        <M.GridRow>
          <CustomCol col={3} noPadding style={{ marginTop: '1rem' }}>
            <ErrorMsg data-testid='ach-verification-error-msg'>
              {verificationError}
            </ErrorMsg>
          </CustomCol>
        </M.GridRow>
      )}
    </Grid>
  );
};

export default BAVerification;
