import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toastError } from 'actions';
import SecureFilestack from 'components/SecureFilestack/secureFilestack';
import { useFilestackUploadResponse } from 'hooks';
import {
  CSV_UPLOAD_RANDOM_TESTING_CSV_TEMPLATE,
  CSV_UPLOAD_FILESTACK_KEY as filestackKey,
} from './Constants';
import { FormInputContainer, Label } from './styles';
import { EditCohortFormFields, NewCohortFormFields } from './types';

interface Props {
  accountId: string;
  handleChange: any;
  values: Partial<NewCohortFormFields | EditCohortFormFields>;
  setServerError: Function;
  reset: Function;
  setDeleteFileRef: (ref: () => void) => void;
}

const FileStackOptions = {
  accept: ['text/csv'],
  storeTo: {
    location: 's3',
    path: '/random_testing_csv/',
  },
  maxFiles: 1,
  uploadInBackground: false,
  fromSources: ['local_file_system'],
  maxSize: 1000000,
  customText: {
    Files: 'File',
    'Select Files to Upload': 'Select file to upload',
    'Selected Files': 'Selected file',
    'Deselect All': 'Deselect file',
    'or Drag and Drop, Copy and Paste Files':
      'or drag and drop, copy and paste file ',
  },
};

const CandidateUploadSection: FC<Props> = ({
  accountId,
  handleChange,
  values: { fileDisplayName },
  setServerError,
  reset,
  setDeleteFileRef,
}) => {
  const [filename, setFilename] = useState(fileDisplayName);
  const fileStackOptions = useMemo(() => {
    return {
      ...FileStackOptions,
      storeTo: {
        ...FileStackOptions.storeTo,
        path: `/random_testing_csv/${accountId}/`,
      },
    };
  }, [accountId]);

  const dispatchToast = useDispatch();

  const handleFileChange = useCallback(
    filesUploaded => {
      handleChange('fileStoragePath')(filesUploaded?.key);
      if (filesUploaded?.container)
        handleChange('fileStorageBucket')(filesUploaded?.container);
      handleChange('fileDisplayName')(filesUploaded?.filename);
      setFilename(filesUploaded?.filename);
    },
    [handleChange],
  );

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatchToast(toastError('Failed to upload file'));
    },
    successCallback: handleFileChange,
  });

  const filestackUpload = useCallback(({ onPick }: any) => {
    return (
      <UploadContainerButton kind='secondary' onClick={onPick}>
        <UploadIconColumn>
          <M.Icon icon='DocumentAttachment' size='48' />
        </UploadIconColumn>
        <UploadTextColumn>
          <strong>Click to upload</strong>
          <p>CSV</p>
        </UploadTextColumn>
      </UploadContainerButton>
    );
  }, []);

  const handleFileDelete = useCallback(() => {
    handleFileChange({ key: '', filename: '', url: '' });
    setServerError(false);
    reset();
  }, [handleFileChange, reset, setServerError]);

  useEffect(() => {
    setDeleteFileRef(handleFileDelete);
  }, [setDeleteFileRef, handleFileDelete]);

  return (
    <FormInputContainer data-testid='csv-upload-section'>
      <FormInputContainer>
        <StyledGrid>
          <Label>Add individuals to pool </Label>
          <p>
            Fill in the CSV template with information about the members of this
            pool.
          </p>
          <M.GridRow>
            <StyledUploadLink
              data-testid='random-testing-csv-template-link'
              download
              href={CSV_UPLOAD_RANDOM_TESTING_CSV_TEMPLATE}
            >
              Download a CSV template
            </StyledUploadLink>
          </M.GridRow>
          <strong>Template instructions:</strong>
          <StyledList>
            <StyledListItem>
              First row must contain column names.
            </StyledListItem>
            <StyledListItem>
              All fields except middle name are required.
            </StyledListItem>
          </StyledList>

          {filename ? (
            <UploadedFile data-testid='uploaded-csv-file'>
              <M.Icon icon='CheckmarkFilled' size={16} />
              <p className='filename' data-testid='uploaded-file-name'>
                {filename}
              </p>
              <DeleteButton
                data-testid='delete-uploaded-csv'
                onClick={handleFileDelete}
              >
                Delete
              </DeleteButton>
            </UploadedFile>
          ) : (
            <M.GridRow style={{ marginTop: '2em' }}>
              <UploadButtonColumn>
                <SecureFilestack
                  actionOptions={fileStackOptions}
                  apikey={filestackKey}
                  customRender={filestackUpload}
                  onSuccess={onFileUpload}
                />
              </UploadButtonColumn>
            </M.GridRow>
          )}
        </StyledGrid>
      </FormInputContainer>
    </FormInputContainer>
  );
};

const UploadedFile = styled(M.Container.Row)`
  align-items: center;
  margin-top: 2rem;

  .mastodon-icon {
    fill: ${colors.uiGreen600};
  }

  .filename {
    color: ${colors.uiTextPrimaryLight};
    font-size: 0.875rem !important;
    margin: 0 1rem;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0 !important;
`;

const StyledUploadLink = styled(M.Link)`
  margin: 20px 0px;
  text-decoration: underline;
`;

const StyledColumn = styled(M.GridCol)`
  padding: 0;
  text-wrap: nowrap;
`;

const UploadIconColumn = styled(StyledColumn)`
  max-width: 48px !important;
`;

const UploadTextColumn = styled(M.GridCol)`
  padding-left: 16px;
  text-wrap: nowrap;
  width: 30%;

  p {
    color: ${colors.uiTextSecondaryLight};
  }
`;

const UploadContainerButton = styled(M.Button)`
  padding: 16px !important;
  width: 480px !important;
  border-radius: 6px !important;
  border: 1px solid ${colors.uiGrey200} !important;
`;

const UploadButtonColumn = styled(StyledColumn)`
  width: 480px !important;
`;

const StyledList = styled.ul`
  margin-left: 20px;
`;

const StyledListItem = styled.li`
  list-style-type: disc;
`;

const StyledGrid = styled(M.Grid)`
  color: ${colors.uiTextSecondaryLight};
`;

export default CandidateUploadSection;
