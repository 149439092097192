import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { formatWord } from '@dashboard-experience/utils';
import { parserI9, scrollToTop } from 'utils';

import FormHeading from '../FormHeading';
import ConfirmModal from '../../../ConfirmModal';

const DISABLED_SETTINGS = new Set([
  'clearinghouse_incident_withdrawn',
  'clearinghouse_incident_submitted',
  'clearinghouse_incident_updated',
]);

const Checklist = styled.ul``;
const ChecklistSelectAll = styled.li`
  margin-left: 0px;
`;
const ChecklistItem = styled.li`
  margin-left: 24px;
`;
const LEFT_COLUMN = ['candidate', 'invitation', 'report'];
const RIGHT_COLUMN = ['verification', 'package', 'I9', 'other'];

export default class WebhookSubscriptions extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    updateWebhookSubscriptions: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      account: { webhook_setting },
    } = props;
    const groups = {
      candidate: [],
      invitation: [],
      report: [],
      verification: [],
      package: [],
      I9: [],
      other: [],
    };
    const webhookSettings = {};
    let webhookSettingsPrev = {};

    Object.keys(webhook_setting).forEach(setting => {
      if (setting.startsWith('candidate_')) {
        groups.candidate.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else if (setting.startsWith('report_')) {
        groups.report.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else if (setting.startsWith('invitation_')) {
        groups.invitation.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else if (setting.startsWith('verification_')) {
        groups.verification.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else if (setting.startsWith('package_')) {
        groups.package.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else if (setting.startsWith('form_i9_')) {
        groups.I9.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      } else {
        groups.other.push(setting);
        webhookSettings[setting] = webhook_setting[setting];
      }
    });

    webhookSettingsPrev = { ...webhookSettings };

    this.state = {
      webhookSettings,
      webhookSettingsPrev,
      groups,
      disableEdit: true,

      modalOpen: false,
      modalAction: () => {},
      modalTitle: 'Update Webhook Subscriptions',
      modalMessage: `Are you sure you want to update the Webhook Subcriptions?`,
    };
  }

  allItemsChecked = name => {
    const {
      state: { webhookSettings, groups },
      isDisabled,
    } = this;
    return groups[name].every(
      // only check the settings that are enabled and therefore toggle-able
      setting => isDisabled(setting) || webhookSettings[setting],
    );
  };

  toggleGroup = (event, { checked, id }) => {
    const { isDisabled } = this;
    this.setState(({ webhookSettings, groups }) => {
      const newSettings = { ...webhookSettings };
      groups[id].forEach(setting => {
        if (!isDisabled(setting)) {
          newSettings[setting] = checked;
        }
      });
      return { webhookSettings: newSettings };
    });
  };

  toggleSetting = (event, { checked, id }) => {
    this.setState(({ webhookSettings }) => ({
      webhookSettings: {
        ...webhookSettings,
        [id]: checked,
      },
    }));
  };

  isDisabled = name => {
    return DISABLED_SETTINGS.has(name);
  };

  save = () => {
    const {
      props: { account, updateWebhookSubscriptions },
      state: { webhookSettings },
    } = this;

    updateWebhookSubscriptions({
      accountId: account.id,
      subscriptions: webhookSettings,
    });

    this.setState({
      webhookSettingsPrev: { ...webhookSettings },
    });
  };

  hideModal = () => this.setState({ modalOpen: false });

  confirmChange = () => {
    this.setState({
      modalOpen: true,
      modalAction: () => {
        this.save();
        this.setState({ modalOpen: false, disableEdit: true });
      },
    });

    scrollToTop();
  };

  onCancel = () => {
    const {
      state: { disableEdit, webhookSettingsPrev },
    } = this;

    this.setState({
      disableEdit: !disableEdit,
      webhookSettings: { ...webhookSettingsPrev },
    });
  };

  onToggleEdit = () => {
    const {
      state: { disableEdit },
    } = this;

    this.setState({ disableEdit: !disableEdit });
  };

  renderActions = () => {
    const {
      onCancel,
      onToggleEdit,
      confirmChange,
      state: { disableEdit },
    } = this;

    if (disableEdit) {
      return <M.Button onClick={onToggleEdit}>Edit</M.Button>;
    }
    return (
      <>
        <M.Button onClick={onCancel} kind='secondary'>
          Cancel
        </M.Button>
        <M.Button onClick={confirmChange}>Apply Changes</M.Button>
      </>
    );
  };

  renderGroup = groupName => {
    const {
      state: { webhookSettings, groups, disableEdit },
      toggleGroup,
      toggleSetting,
      allItemsChecked,
      isDisabled,
    } = this;
    if (groups[groupName].length === 0) return false;
    return (
      <Checklist key={groupName}>
        <ChecklistSelectAll>
          <M.Checkbox
            name={groupName}
            id={groupName}
            onChange={toggleGroup}
            checked={allItemsChecked(groupName)}
            labelText={`All ${groupName} updates`}
            disabled={disableEdit}
          />
        </ChecklistSelectAll>
        {groups[groupName].map(setting => {
          return (
            <ChecklistItem key={setting}>
              <M.Checkbox
                id={setting}
                name={setting}
                onChange={toggleSetting}
                checked={webhookSettings[setting]}
                labelText={parserI9(formatWord(setting))}
                disabled={isDisabled(setting) || disableEdit}
              />
            </ChecklistItem>
          );
        })}
      </Checklist>
    );
  };

  render() {
    const {
      renderGroup,
      hideModal,
      renderActions,
      props: {
        account: { webhook_setting },
      },
      state: { modalOpen, modalAction, modalTitle, modalMessage },
    } = this;

    const isLoading = Boolean(!webhook_setting);
    return isLoading ? (
      <M.LoadingBlock />
    ) : (
      <>
        <M.Container.Row>
          <M.Container.Col>
            <FormHeading>Webhook Subscriptions</FormHeading>
            <M.InlineNotification
              kind='warning'
              title='Important:'
              subtitle='Webhooks are used in integrations with Checkr. Please review any changes carefully.'
              hideCloseButton
            />
            {renderActions()}
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col md={4}>
            {LEFT_COLUMN.map(renderGroup)}
          </M.Container.Col>
          <M.Container.Col md={4}>
            {RIGHT_COLUMN.map(renderGroup)}
          </M.Container.Col>
        </M.Container.Row>
        <ConfirmModal
          open={modalOpen}
          hideModal={hideModal}
          callback={modalAction}
          header={modalTitle}
          message={modalMessage}
        />
      </>
    );
  }
}
