import { Fact as FactType, FactRule, Operator } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import * as CrimFacts from 'modules/assess/models/criminal/conditions/fact-options';
import * as MVRFacts from 'modules/assess/models/mvr/conditions/fact-options';

export type FactRuleConfig = Partial<Pick<FactRule, 'fact' | 'operator'>> & {
  options?: Array<Partial<FactRuleOption>>;
};

export type FactRuleOption = Pick<FactRule, 'operator' | 'value'>;

export type FactConfigsType = FactRuleConfig[];

export const getFactConfigsByNamespace = (
  namespace: Namespace,
): FactConfigsType => {
  switch (namespace) {
    case Namespace.criminal:
      return Object.values(CrimFacts);
    case Namespace.mvr:
      return Object.values(MVRFacts);
    default:
      throw new Error(`Invalid Namespace: ${namespace}`);
  }
};

export const getFactsByNamespace = (namespace: Namespace) => {
  const FACT_CONFIGS = getFactConfigsByNamespace(namespace);
  return FACT_CONFIGS?.map(c => c.fact);
};

export const getFactConfig = (
  namespace: Namespace,
  fact?: FactType,
  values?: Record<string, Array<any>>,
): FactRuleConfig | undefined => {
  if (!fact) {
    return undefined;
  }
  const FACT_CONFIGS = getFactConfigsByNamespace(namespace);
  const config = FACT_CONFIGS.find(c => c.fact === fact);

  if (config && values && values[fact]) {
    config.options = values[fact].map(value => {
      return {
        value,
      };
    });
  }

  return config;
};

export const getFactOptions = (
  config?: FactRuleConfig,
): Array<FactRuleOption> | undefined => {
  const operator: Operator | undefined = config?.operator;
  return config?.options?.map((option: any) => ({
    operator,
    ...option,
  }));
};
