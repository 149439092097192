import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericObject } from 'types';
import { M } from '@dashboard-experience/mastodon';
import { useGetReport } from 'api/reports';
import FilePreviewWarnings from './views/preview-email/warning-banners';
import { FairChanceWarning } from '../../../fair-chance';

type Props = {
  currentScreen: string;
  style?: GenericObject;
};

const Warnings: React.FC<Props> = ({ currentScreen, style = {} }) => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  let warnings = '';

  if (report?.candidate?.driver_license_state === 'PA') {
    warnings += `
      ${t(
        `adjudication:report.actions.pre_adverse.modal.footer.warnings.philadelphia`,
      )}
    `;
  }

  return (
    <div>
      {warnings && (
        <M.InlineNotification
          style={{ ...style, maxWidth: '100%' }}
          kind='warning'
          title=''
          subtitle={warnings}
          hideCloseButton
        />
      )}
      {['FairChance', 'FairChanceForm'].includes(currentScreen) && (
        <FairChanceWarning />
      )}
      {currentScreen === 'PreviewEmail' && <FilePreviewWarnings />}
    </div>
  );
};

export default Warnings;
