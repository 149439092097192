/* eslint-disable react/jsx-no-bind */
import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { Flag } from '@dashboard-experience/react-flagr';
import { FLAG_KEY_EDIT_PACKAGE_OUT_OF_COUNTRY_HISTORY } from 'Constants';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainerNoBorder,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  Container,
  Checkbox,
  CheckboxWrapper,
  StyledLink,
  Toggletip,
  ScreeningProps,
} from '../../../shared/ListItemElements';

export type EducationVerificationAdditionalPropertiesProps = {
  [education_verification: string]: {
    international_upgrade: boolean;
  };
};

type EducationVerificationProps = ScreeningProps & {
  additionalProperties: EducationVerificationAdditionalPropertiesProps;
};

const EducationVerification = ({
  shouldOpen,
  getPriceByType,
  actionProps,
  additionalProperties,
}: EducationVerificationProps) => {
  const { disabledScreenings, onAddClick } = actionProps;

  return (
    <>
      <FlexContainerNoBorder data-testid='education-verification-search-section'>
        <ExpandableTitle
          initialExpanded={shouldOpen}
          data-testid='expandable-education-verification-search-section'
          title={
            <Title
              getPriceByType={getPriceByType}
              actionProps={actionProps}
              title='Education Verification'
              priceByType='education_verification'
            />
          }
        >
          <ScreeningDescriptionContainer>
            <ScreeningInfo>
              Verifies the highest degree obtained from high school or
              equivalent through doctorates.{` `}
              <LearnMoreLink
                style={{ textDecoration: 'underline' }}
                linkText='Learn more'
                data-testid='education-verification-learn-more-link'
                href='https://help.checkr.com/hc/en-us/articles/360001863468'
              />
            </ScreeningInfo>
            {/* <TurnAroundTime /> */}
          </ScreeningDescriptionContainer>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <ul>
                <BulletPoint text='National Student Clearinghouse used as primary data source' />
                <BulletPoint text='Reports degrees earned and dates of enrollment' />
                <BulletPoint text='Eliminates burden of verifying degrees' />
              </ul>
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <ul>
                <BulletPoint text='Some schools may have to be verified manually with longer turnaround' />
                <BulletPoint text='Verifies education, not identity' />
              </ul>
            </Box>
          </Flex>
        </ExpandableTitle>
        <AddButtonContainer>
          <AddScreeningsAction
            screeningType='education_verification'
            {...actionProps}
          />
        </AddButtonContainer>
      </FlexContainerNoBorder>
      <Container>
        <Flag
          flagKey={FLAG_KEY_EDIT_PACKAGE_OUT_OF_COUNTRY_HISTORY}
          variantKey='enabled'
        >
          {!disabledScreenings.includes('education_verification') && (
            <CheckboxWrapper>
              <Checkbox
                data-testid='education-out-of-country-history-checkbox'
                id='education-out-of-country-history'
                name='education-out-of-country-history'
                value='education-out-of-country-history'
                labelText='Include out-of-country history'
                checked={
                  additionalProperties.education_verification
                    ?.international_upgrade || false
                }
                onChange={(event: any, { checked }: { checked: boolean }) => {
                  onAddClick('education_verification', {
                    international_upgrade: checked,
                  });
                }}
              />
              <div data-testid='help-filled-icon-education'>
                <Toggletip align='bottom' tabIndex={0}>
                  <M.ToggletipButton>
                    <M.Icon icon='HelpFilled' />
                  </M.ToggletipButton>
                  <M.ToggletipContent>
                    <div>
                      <p>
                        This option applies to candidates who will perform their
                        work inside the US, but have education history outside
                        the country.{' '}
                      </p>
                      <br />
                      <p>
                        Visit{' '}
                        <StyledLink
                          href='https://help.checkr.com/hc/en-us/articles/360056108714-International-Screenings'
                          target='_blank'
                          rel='noreferrer'
                          data-testid='learn-more-link-international-screenings'
                        >
                          International Screenings
                        </StyledLink>{' '}
                        to learn more.
                      </p>
                    </div>
                  </M.ToggletipContent>
                </Toggletip>
              </div>
            </CheckboxWrapper>
          )}
        </Flag>
      </Container>
    </>
  );
};

export default EducationVerification;
