import { capitalize } from 'lodash';

import { ReportStatuses } from '@dashboard-experience/utils';

const { CLEAR, CONSIDER, COMPLETE, DISPUTE, SUSPENDED, CANCELED } =
  ReportStatuses;

type Filter = {
  value: string;
  text: string;
};

const REPORT_STATUS_FILTERS = [
  { value: CONSIDER, text: capitalize(CONSIDER) },
  { value: CLEAR, text: capitalize(CLEAR) },
  { value: COMPLETE, text: capitalize(COMPLETE) },
  { value: 'pending_candidate', text: 'Pending (Candidate)' },
  { value: 'pending_research', text: 'Pending (Research)' },
  { value: DISPUTE, text: capitalize(DISPUTE) },
  { value: SUSPENDED, text: capitalize(SUSPENDED) },
  { value: CANCELED, text: capitalize(CANCELED) },
];

const NONE_ASSESS_STATUSES = REPORT_STATUS_FILTERS.slice(0, 2);
const COMMON_STATUSES = REPORT_STATUS_FILTERS.slice(2, 7);
const CANCEL_STATUS = REPORT_STATUS_FILTERS.slice(7);

const ASSESS_STATUS_FILTERS = [
  { value: 'complete_action_needed', text: 'Complete (Action required)' },
];

const getFilters = (
  assessEnabled: boolean,
  canceledEnabled: boolean | undefined,
) => {
  let filters: Filter[] = [];
  if (!assessEnabled) filters = filters.concat(NONE_ASSESS_STATUSES);
  if (assessEnabled) filters = filters.concat(ASSESS_STATUS_FILTERS);
  if (canceledEnabled) filters = filters.concat(CANCEL_STATUS);
  filters = filters.concat(COMMON_STATUSES);
  return filters;
};

export default getFilters;
