import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CONTINUOUS_CHECK } from 'Constants';

const BoldText = styled.h6`
  text-align: center !important;
  padding: 1rem;
  padding-top: 1rem;
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight} !important;
`;

const Text = styled.p`
  text-align: center !important;
  padding-right: 0 !important;
  margin: 1rem;
  color: ${colors.uiTextPrimaryLight};
`;

const BulkActionModal = ({ hideModal, header, open }) => (
  <M.ComposedModal open={open} onClose={hideModal}>
    <M.ModalHeader closeModal={hideModal}>
      <h2>{header}</h2>
    </M.ModalHeader>
    <M.ModalBody>
      <span>
        <BoldText>
          Your file was successfully uploaded and your request is processing.
        </BoldText>
      </span>
      <span>
        <Text>
          You can view the status in <strong>{CONTINUOUS_CHECK}s</strong>.
          Please note that processing times may vary based on file size.
        </Text>
      </span>
    </M.ModalBody>
    <M.ModalFooter>
      <M.Button onClick={hideModal}>Close</M.Button>
    </M.ModalFooter>
  </M.ComposedModal>
);

BulkActionModal.propTypes = {
  hideModal: PropTypes.func,
  open: PropTypes.bool.isRequired,
  header: PropTypes.string,
};

BulkActionModal.defaultProps = {
  hideModal: () => {},
  header: '',
};

export default BulkActionModal;
