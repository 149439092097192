import * as Yup from 'yup';
import { ValidationErrors } from './types';

export const baVerificationValidationSchema = Yup.object().shape({
  amount1: Yup.string()
    .matches(/^\d.\d{2}$/, { message: ValidationErrors.AMOUNT })
    .required(ValidationErrors.REQUIRED),
  amount2: Yup.string()
    .matches(/^\d.\d{2}$/, { message: ValidationErrors.AMOUNT })
    .required(ValidationErrors.REQUIRED),
});

export const postbillingAddressValidationSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.REQUIRED),
  address_line_1: Yup.string().required(ValidationErrors.REQUIRED),
  address_line_2: Yup.string(),
  country: Yup.string().required(ValidationErrors.REQUIRED),
  city: Yup.string().required(ValidationErrors.REQUIRED),
  region: Yup.string().required(ValidationErrors.REQUIRED),
  postal_code: Yup.string().required(ValidationErrors.REQUIRED),
});

export const billingContactsValidationSchema = Yup.object().shape({
  preferred_email: Yup.string()
    .required(ValidationErrors.REQUIRED)
    .email(ValidationErrors.EMAIL),
});

export const postPaymentProfileValidationSchema = Yup.object().shape({
  id: Yup.string().required(ValidationErrors.REQUIRED),
  name: Yup.string().required(ValidationErrors.REQUIRED),
  email: Yup.string()
    .required(ValidationErrors.REQUIRED)
    .email(ValidationErrors.EMAIL),
  address_line_1: Yup.string().required(ValidationErrors.REQUIRED),
  address_line_2: Yup.string().nullable(),
  country: Yup.string().required(ValidationErrors.REQUIRED),
  city: Yup.string().required(ValidationErrors.REQUIRED),
  region: Yup.string().required(ValidationErrors.REQUIRED),
  postal_code: Yup.string().required(ValidationErrors.REQUIRED),
});
