import React, { useEffect } from 'react';
import { useTrackEvent, CASE_MANAGEMENT_EVENT_NAMES } from 'utils/analytics';
import Table from './Table';
import Filters from './Filters';
import SavedFilters from './SavedFilters';
import PageContainer from '../shared/PageContainer';

export const TasksPage: React.FC = () => {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(CASE_MANAGEMENT_EVENT_NAMES.TASKS_PAGE_VIEWED);
  }, [trackEvent]);

  return (
    <PageContainer headerText='Tasks' testId='case-management-tasks-page'>
      <SavedFilters />
      <Filters />
      <Table />
    </PageContainer>
  );
};

export default TasksPage;
