import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  employerCurrentOrLast10Years,
  employersLast7Years as defaultEmploymentVerificationPanel,
} from 'components/AddScreenings/Domestic/List/VerificationScreenings/EmploymentVerification';
import AddOnsSummaryPrice from 'components/AddScreenings/shared/AddOnsSummaryPrice';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import ThirdPartyFees from 'components/AddScreenings/shared/ThirdPartyFees';
import {
  getDollarAmount,
  getScreeningSummaryLabel,
  getAdditionalPropertiesLabel,
} from 'components/AddScreenings/shared/utils';

const LinePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

const LinePriceSection = styled.span`
  display: inline-flex;
  width: 220px;
`;

const SummaryHeading = styled.h4`
  margin-bottom: 0 !important;
  padding-bottom: 0;
  color: ${colors.uiTextPrimaryLight};

  @media (max-device-width: 530px) {
    font-size: 15px !important;
  }
`;

const SubtotalHeading = styled.span`
  color: ${colors.brandNavy4};
`;

const PackageNameHeading = styled.h5`
  align-self: center;
  margin-bottom: 0 !important;
`;

const SummarySection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.brandSlate3};

  @media (max-device-width: 530px) {
    padding-top: 16px;
  }
`;

const PackagePriceSpan = styled.span`
  color: ${colors.brandNavy3};
  font-size: 14px;
`;

const ScreeningList = styled.ul`
  list-style-position: inside;
  margin-top: 12px !important;

  li::marker {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
    font-size: 14px;
  }
`;

const ScreeningListItem = styled.li`
  margin-bottom: 4px;
  padding-top: 3px;
`;

const ScreeningLabel = styled.span`
  color: ${colors.brandNavy3};
  font-size: 14px;
  width: 250px !important;
  @media (max-device-width: 530px) {
    font-size: 12px !important;
  }
`;

const AddOnsHeading = styled.h3`
  color: ${colors.brandNavy4};
  margin-bottom: 0 !important;
`;

const BoldLinePrice = styled(LinePrice)`
  font-size: 14px;
  line-height: unset;
  font-weight: bold;

  @media (max-device-width: 400px) {
    flex-direction: row;
    margin-bottom: 2px;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const LoadingSpinner = styled(M.LoadingSpinner)`
  margin: 24px auto;
  vertical-align: center;
`;

const FlexDiv = styled.div`
  display: flex;
  width: 250px !important;
  margin-top: 8px;
  font-size: 14px;
  color: ${colors.brandSlate6};
`;

const ScreeningListLinePrice = styled.div`
  display: inline;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

const BulletPoint = styled.div`
  width: 6px;
  height: 6px;
  background: ${colors.uiAqua500};
  margin: auto 0;
  margin-right: 7px;
  margin-top: 7px;
  border-radius: 50%;
`;

const StyledAddOnsSummaryPrice = styled(AddOnsSummaryPrice)`
  @media (max-device-width: 530px) {
    margin-left: 20px;
  }
  float: right;
`;

const NoSelectedPackageLabel = styled(SummarySection)`
  padding-bottom: 0px;
  padding-top: 8px;
  border-bottom: none;
`;

const PackageHeaderWrapper = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 0 !important;
`;

const EditButton = styled(M.Button)`
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 0 0 7px !important;
`;
export const filterScreenings = (
  items: any[],
  basePackageScreeningTypes: string[],
) => {
  if (!items || items.length < 1) {
    return [];
  }

  return items?.filter((screening: any) => {
    if (screening.product_type === 'occupational_health_screening') {
      return true;
    }
    return !basePackageScreeningTypes.includes(screening.product_type);
  });
};

const DomesticAddScreeningsSummary: React.FC<any> = ({
  basePackage,
  basePackagePrices,
  basePackageScreeningTypes,
  pendingAddOnPricesQuery,
  hasAddedScreenings,
  additionalProperties,
  goBack,
}) => {
  if (!pendingAddOnPricesQuery) {
    return <LoadingSpinner withOverlay={false} />;
  }

  const { data, isLoading, isError } = pendingAddOnPricesQuery;

  const addedScreenings = data
    ? filterScreenings(data.items, basePackageScreeningTypes)
    : [];

  return (
    <>
      <SummaryHeading className='summary-heading'>Order summary</SummaryHeading>
      {basePackage.screenings.length !== 0 && (
        <SummarySection className='summary-section'>
          <LinePrice className='line-price'>
            <PackageHeaderWrapper>
              <PackageNameHeading
                className='package-name-heading'
                data-testid='summary-section-package-name'
              >
                {basePackage.name}
              </PackageNameHeading>
              {!!goBack && (
                <EditButton kind='tertiary' onClick={goBack}>
                  Edit
                </EditButton>
              )}
            </PackageHeaderWrapper>
            {basePackage.international_only ? (
              <LearnMoreLink
                linkText='Price varies'
                data-testid='summary-section-line-price'
                href='https://checkr.com/pricing/international'
              />
            ) : (
              <PackagePriceSpan data-testid='summary-section-line-price'>
                {isNaN(basePackagePrices?.fixed_price)
                  ? 'Loading price...'
                  : getDollarAmount(basePackagePrices?.fixed_price)}
              </PackagePriceSpan>
            )}
          </LinePrice>
          <ScreeningList data-testid='summary-section-screening-list'>
            {basePackageScreeningTypes.map(
              (screeningType: any) =>
                screeningType !== 'international_adverse_media_search' && (
                  <ScreeningListItem
                    className='screening-list-item'
                    key={screeningType}
                  >
                    <ScreeningLabel
                      className='screening-label'
                      key={`${screeningType}-label`}
                    >
                      {getScreeningSummaryLabel(
                        screeningType,
                        additionalProperties,
                      )}
                    </ScreeningLabel>
                  </ScreeningListItem>
                ),
            )}
          </ScreeningList>
        </SummarySection>
      )}
      {isLoading && <LoadingSpinner withOverlay={false} />}
      {isError && (
        <SummarySection className='summary-section'>
          Something went wrong. Please try adding your screening again.
        </SummarySection>
      )}
      {data && (
        <>
          {hasAddedScreenings && (
            <SummarySection className='summary-section'>
              {basePackage.international_only ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h3>Additional Checks </h3>
                  <LearnMoreLink
                    linkText='Price varies'
                    href='https://checkr.com/pricing/international'
                  />
                </div>
              ) : (
                <AddOnsHeading>Additional Checks</AddOnsHeading>
              )}
              <ScreeningList>
                {addedScreenings.map(
                  (screening: any) =>
                    screening.product_type !==
                      'international_adverse_media_search' && (
                      <ScreeningListItem
                        className='screening-list-item'
                        key={screening.product_type}
                      >
                        <ScreeningListLinePrice className='screening-list-line-price'>
                          <LinePriceSection className='line-price-section'>
                            <div className='add-on-summary-name-container'>
                              <ScreeningLabel className='screening-label'>
                                {getScreeningSummaryLabel(
                                  screening.product_type,
                                  additionalProperties,
                                )}
                              </ScreeningLabel>
                              {getAdditionalPropertiesLabel(
                                screening.product_type,
                                additionalProperties,
                              ).map((property: string) => {
                                return (
                                  <FlexDiv
                                    key={
                                      property ||
                                      defaultEmploymentVerificationPanel.label
                                    }
                                  >
                                    <BulletPoint />
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {property ===
                                    employerCurrentOrLast10Years.label
                                      ? 'Current or most recent (10 years)'
                                      : property ||
                                        defaultEmploymentVerificationPanel.label}
                                  </FlexDiv>
                                );
                              })}
                            </div>
                          </LinePriceSection>
                          <StyledAddOnsSummaryPrice
                            className='styled-add-ons-summary-price'
                            screening={screening}
                            data={data}
                          />
                        </ScreeningListLinePrice>
                      </ScreeningListItem>
                    ),
                )}
              </ScreeningList>
            </SummarySection>
          )}
          {!hasAddedScreenings && basePackage.screenings.length === 0 && (
            <SummarySection className='summary-section'>
              <AddOnsHeading>Additional checks</AddOnsHeading>
              <NoSelectedPackageLabel className='no-selected-package-label'>
                <div>You haven&apos;t selected any additional checks.</div>
              </NoSelectedPackageLabel>
            </SummarySection>
          )}
          <SummarySection className='summary-section' data-testid='subtotal'>
            <BoldLinePrice className='bold-line-price'>
              <SubtotalHeading>Subtotal</SubtotalHeading>
              <SubtotalHeading
                className='subtotal-heading'
                data-testid='summary-section-subtotal-price'
              >
                {basePackage.international_only ? (
                  <LearnMoreLink
                    linkText='Price varies'
                    href='https://checkr.com/pricing/international'
                    data-testid='learn-more-link-international-price-varies'
                  />
                ) : (
                  getDollarAmount(data?.fixed_price)
                )}
              </SubtotalHeading>
            </BoldLinePrice>
          </SummarySection>
          <ThirdPartyFees
            serviceFeeMin={data?.service_fee_range[0] || 0}
            serviceFeeMax={data?.service_fee_range[1] || 0}
            passthroughFeeMin={data?.passthrough_fee_range[0] || 0}
            passthroughFeeMax={data?.passthrough_fee_range[1] || 0}
          />
        </>
      )}
    </>
  );
};

export default DomesticAddScreeningsSummary;
