import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HEADERS, SUB_HEADERS } from './constants';
import Search from './Search';

const { TableRow, TableHeader } = M.DataTable;

type SubCategoryProps = {};

type SubHeaderProps = {
  k: string;
};

const BaseHeader = styled(TableHeader)`
  border: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

const SearchHeader = styled(BaseHeader)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 25% !important;
`;

const StyledHeader = styled(BaseHeader)`
  width: 12.5% !important;

  .cds--table-header-label {
    text-align: center;
    font-weight: 500;
  }
`;

const SeveritySubHeaders: React.FC<SubHeaderProps> = ({ k }) => {
  const { t } = useTranslation('assess');

  const headers = SUB_HEADERS.map(v => ({
    key: v,
    text: t(`assess:decisions.${v}`),
  }));

  const children = headers.map((s, i) => {
    const last = i % 2;
    return (
      <StyledHeader key={`${k}-${s.key}`} last={last}>
        {s.text}
      </StyledHeader>
    );
  });

  return <>{children}</>;
};

const renderSubHeaders = () =>
  HEADERS.slice(1).map(h => <SeveritySubHeaders key={h.key} k={h.key} />);

const SubCategory: React.FC<SubCategoryProps> = () => (
  <TableRow>
    <SearchHeader colSpan={1}>
      <Search />
    </SearchHeader>
    {renderSubHeaders()}
  </TableRow>
);

export default SubCategory;
