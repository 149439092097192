import React, { useContext } from 'react';
import UIContext from 'context/UI';
import { useCandidate } from 'providers/Candidate';
import { Redirect } from 'react-router-dom';

// Redirect /candidates/:candidateId to the latest report if reports are present
// This way, the current report can always come from route match params
// If no reports are present, and not in iframe, redirect to order new report
const RedirectToReport: React.FC = () => {
  const candidate = useCandidate();

  const { isIframe, isStandaloneIframe } = useContext(UIContext);

  // Don't redirect inside iframes
  if (isIframe && !isStandaloneIframe) return null;

  const lastReportId = candidate.last_report_id;

  // If a report is found, redirect to the most recent one
  if (lastReportId) {
    const { hash, search } = window.location;
    return (
      <Redirect
        to={`/candidates/${candidate.id}/reports/${lastReportId}${search}${hash}`}
      />
    );
  }

  return null;
};

export default RedirectToReport;
