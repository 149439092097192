import React, { useCallback } from 'react';
import { ASSESS_STATUSES } from '../constants';
import SeverityCell from './SeverityCell';
import { ChargeYears, Severity, SEVERITY_STATUS, Years } from './types';

const getStatus = (years: Years, type: SEVERITY_STATUS): SEVERITY_STATUS => {
  const { ELIGIBLE, REVIEW, ESCALATED, ALWAYS_MEETS, NEVER_MEETS } =
    ASSESS_STATUSES;

  if (years === undefined || years === null) {
    return REVIEW;
  }
  if (years === 0 && type === ELIGIBLE) {
    return ALWAYS_MEETS;
  }
  if (years === 99 && type === ESCALATED) {
    return NEVER_MEETS;
  }
  return type;
};

type Props = {
  header: string;
  years: ChargeYears;
  setChargeState: (data: Partial<Severity>) => void;
};

const SeverityCellGroup: React.FC<Props> = ({
  header,
  years,
  setChargeState,
}) => {
  const eligibleYears = years.eligible;
  const escalatedYears = years.escalated;

  const setMeetYears = useCallback(
    (data: Years) => {
      setChargeState({
        [header]: {
          ...years,
          eligible: data,
        },
      });
    },
    [header, years, setChargeState],
  );

  const setEscalatedYears = useCallback(
    (data: Years) => {
      setChargeState({
        [header]: {
          ...years,
          escalated: data,
        },
      });
    },
    [header, years, setChargeState],
  );

  const { ELIGIBLE, ESCALATED } = ASSESS_STATUSES;

  const eligibleStatus = getStatus(eligibleYears, ELIGIBLE);
  const escalatedStatus = getStatus(escalatedYears, ESCALATED);

  const alwaysEligible =
    eligibleYears === 0 && (!escalatedYears || escalatedYears === 0);
  const neverMeets =
    escalatedYears === 99 && (!eligibleYears || eligibleYears === 99);

  const shouldFocusOnEligible =
    !!escalatedYears && eligibleYears === 0 && escalatedYears !== 0;
  const shouldFocusOnEscalated =
    !!eligibleYears && escalatedYears === 99 && eligibleYears !== 99;

  return (
    <>
      <SeverityCell
        min={escalatedYears ?? 0}
        max={99}
        shouldFocus={shouldFocusOnEligible}
        years={eligibleYears}
        setYears={setMeetYears}
        status={!neverMeets ? eligibleStatus : escalatedStatus}
      />
      <SeverityCell
        min={1}
        max={eligibleYears ?? 99}
        shouldFocus={shouldFocusOnEscalated}
        years={escalatedYears}
        setYears={setEscalatedYears}
        status={!alwaysEligible ? escalatedStatus : eligibleStatus}
        last='true'
      />
    </>
  );
};

export default SeverityCellGroup;
