import { useContextSelector } from 'use-context-selector';
import ReportContext from '../context';

export const useReport = () =>
  useContextSelector(ReportContext, ({ report }) => report || {});

export const useReportLoading = () =>
  useContextSelector(ReportContext, ({ isLoading }) => isLoading);

export const useReportError = () =>
  useContextSelector(ReportContext, ({ isError }) => isError);

export const usePackageObj = () =>
  useContextSelector(ReportContext, ({ packageObj }) => packageObj);

export const usePackages = () =>
  useContextSelector(ReportContext, ({ packages }) => packages);

export const usePackagesLoading = () =>
  useContextSelector(ReportContext, ({ packagesLoading }) => packagesLoading);

export const useRecordsFilter = () =>
  useContextSelector(ReportContext, ({ recordsFilter }) => recordsFilter);

export const useScreenings = () =>
  useContextSelector(ReportContext, ({ screenings }) => screenings);

export const useEta = () => useContextSelector(ReportContext, ({ eta }) => eta);

export const useExceptions = () =>
  useContextSelector(
    ReportContext,
    ({ reportExceptions }) => reportExceptions || null,
  );
