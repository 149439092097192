/* eslint-disable react/display-name */
import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { usePackagesFromApi } from 'containers/Report/hooks';
import { useUser } from 'context/CurrentUser';
import { UPGRADE_PACKAGES_FLAG_KEY } from 'Constants';

import styled from 'styled-components';
import { FieldComponentProps } from '../../../types';
import OverflowItem from './overflow-item';
import { Container, OverflowMenu } from './styles';
import PackageModal from '../../package-modal';
import renderModalWithPortal from '../../../render-modal';

const ModalLink = styled(M.Link)`
  text-decoration: underline;
`;

const RenderedIcon = () => <div>Upgrade</div>;

const Package: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const reportPackage = report.package_name ?? report.package;
  const { packageObj, packages, packagesLoading } = usePackagesFromApi(report);
  const currentUser = useUser();

  const [showPackageModal, setShowPackageModal] = useState(false);

  const openModal = useCallback((event: any) => {
    event.preventDefault();
    setShowPackageModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowPackageModal(false);
  }, []);

  const upgradePackagesFlag =
    useFlag(UPGRADE_PACKAGES_FLAG_KEY)?.variantKey === 'on';

  // if a report's package wasn't found, include all packages
  const upgradePackages = packages?.filter(
    upgradablePackage =>
      !packageObj || upgradablePackage.slug !== packageObj.slug,
  );

  return (
    <Container data-testid={testid}>
      <span className='package-name'>{reportPackage}</span>
      {!!packageObj && (
        <>
          <ModalLink onClick={openModal} href=''>
            Show package contents
          </ModalLink>
          {/* @ts-ignore */}
          {renderModalWithPortal(PackageModal, {
            open: showPackageModal,
            closeModal,
            packageObj,
          })}
        </>
      )}
      {upgradePackagesFlag && (
        <>
          {packagesLoading && <M.LoadingInline />}
          {!packagesLoading &&
            upgradePackages?.length > 0 &&
            hasPermission(currentUser, 'perform_upgrade') && (
              <OverflowMenu
                data-floating-menu-container
                id='manual-upgrade'
                renderIcon={RenderedIcon}
                hideTooltip
              >
                {upgradePackages.map(packageItem => {
                  return (
                    <OverflowItem
                      key={packageItem.slug}
                      packageItem={packageItem}
                      reportId={report.id}
                    />
                  );
                })}
              </OverflowMenu>
            )}
        </>
      )}
    </Container>
  );
};

export default Package;
