import React from 'react';
import { Field, Row } from 'modules/assess/ui/rules/common/rule';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

const Label = () => {
  const { t } = useTranslation('');
  const label = t('nouns.condition_plural');

  return (
    <Row>
      <M.GridCol data-testid='assess-rules-common-rule-editor-rule-conditions-label'>
        <Field label={label} />
      </M.GridCol>
    </Row>
  );
};

export default Label;
