import React, { useCallback } from 'react';
import { GenericObject, Report } from 'types';
import { useLogPDFDownload } from 'api/documents';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { RefType } from '../types';

type PdfLinkProps = {
  item: GenericObject;
  report: Report;
  closeWithAction: Function;
};

const PdfLinks = React.forwardRef<RefType, PdfLinkProps>(
  ({ item, report, closeWithAction, ...props }, ref) => {
    const { docType, type, link, text, isPdfReport, disabled } = item;
    const { id } = report;
    const isConsent = docType === 'consent';

    const { call: logDownload } = useLogPDFDownload({
      reportId: id,
      type: isPdfReport ? type.replace('pdf_', '') : docType,
    });

    const currentUser = useUser();

    const trackEvent = useTrackEvent();

    const trackPdfLink = useCallback(() => {
      closeWithAction();

      if (currentUser) {
        trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
          'Document Selected': item.text,
        });
      }
    }, [closeWithAction, currentUser, item.text, trackEvent]);

    const trackPdfLinkWithLog = useCallback(() => {
      trackPdfLink();
      logDownload();
    }, [logDownload, trackPdfLink]);

    return item ? (
      <>
        {isConsent && (
          <M.OverflowMenuItem
            href={link}
            target='_blank'
            download
            rel='noopener noreferrer'
            ref={ref}
            itemText={text}
            onClick={trackPdfLink}
            {...props}
          />
        )}
        {!isConsent && (
          <M.OverflowMenuItem
            href={link}
            target='_blank'
            download
            rel='noopener noreferrer'
            onClick={trackPdfLinkWithLog}
            disabled={disabled}
            ref={ref}
            itemText={text}
            {...props}
          />
        )}
      </>
    ) : null;
  },
);

PdfLinks.displayName = 'PdfLinks';

export default PdfLinks;
