import React from 'react';
import Humanize from 'humanize-plus';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';

import {
  FairnessAndCompliance,
  LookbackRules,
} from 'modules/assess/models/rulesets/growth-insights';

type Props = {
  item: FairnessAndCompliance.Type | LookbackRules.Type;
};

const Container = styled.div`
  li::marker {
    content: '▲';
    color: ${colors.brandGreen4};
    padding-bottom: 0.2em;
    font-size: 0.8em;
  }
  li span {
    font-weight: bold;
    margin: 0 0.4em;
  }
`;

export const Benefit: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('assess');
  const additionalCandidates = t(
    'assess:rulesets.growth_insights.benefit.annual_candidates',
  );
  const additionalRevenue = t(
    'assess:rulesets.growth_insights.benefit.annual_revenue',
  );
  return (
    <Container className='benefit'>
      <ul>
        <li>
          <span className='candidates'>
            {Humanize.formatNumber(item.annual_candidates)}
          </span>
          {additionalCandidates}
        </li>
        <li>
          <span className='revenue'>
            ${Humanize.formatNumber(item.annual_revenue)}
          </span>
          {additionalRevenue}
        </li>
      </ul>
    </Container>
  );
};

export default Benefit;
