import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import SetupType from '../enums/AddonSetupType';
import {
  getToolTip,
  getSetupButtonTestId,
  getIconTestId,
  getTitleTestId,
  Flex,
  Title,
  HelpIcon,
  SetupButtonWrapper,
  Tooltip,
} from './AddonSetup';

const CompactContainer = styled.div`
  display: flex;
  width: 32.5%;
  min-height: 64px;
  padding: 16px;
  flex-direction: column;
  align-items: center:
  align-self: stretch;
  justify-content: space-between;
  gap: 16px;

  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);
`;

type AddonSetupCompactProps = {
  setupType: SetupType;
  handleSetupClick: Function;
};

const AddonSetupCompact: React.FC<AddonSetupCompactProps> = ({
  setupType,
  handleSetupClick,
}) => {
  return (
    <CompactContainer data-testid='compact-addon-setup-container'>
      <Flex>
        <Title data-testid={getTitleTestId(setupType)}>
          {setupType}
          <Tooltip
            openOnHover
            align='bottom'
            definition={getToolTip(setupType)}
          >
            <HelpIcon
              data-testid={getIconTestId(setupType)}
              icon='HelpFilled'
              size='16'
              style={{
                fill: colors.checkrBlue,
              }}
            />
          </Tooltip>
        </Title>
        <SetupButtonWrapper
          data-testid={getSetupButtonTestId(setupType)}
          onClick={handleSetupClick}
        >
          Set up
        </SetupButtonWrapper>
      </Flex>
    </CompactContainer>
  );
};

export default AddonSetupCompact;
