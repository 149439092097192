import { ID } from 'modules/id';
import { Initializer } from 'state/utilities/state';

export enum Level {
  CATEGORY_1 = 1,
  CATEGORY_2 = 2,
}

export const categoryLevelKeyMap: { [key: string]: Level } = {
  charge_subcategory: Level.CATEGORY_1,
  charge_subsubcategory: Level.CATEGORY_2,
};

export type Type = {
  id: ID;
  key: string;
  name: string;
  felony: Range;
  misdemeanor: Range;
  petty_offense: Range;
  charge_subcategory: string;
  charge_subsubcategory?: string | null;
  level: Level;
  updated?: boolean;
};

export type Range = {
  eligible?: number | null;
  escalated?: number | null;
};

export const initialize: Initializer<
  Omit<Type, 'id' | 'key' | 'level'>
> = () => ({
  name: '',
  felony: {},
  misdemeanor: {},
  petty_offense: {},
  charge_subcategory: '',
  updated: false,
});

export type List = Array<Type>;
export type Map = { [key: string]: Type };
