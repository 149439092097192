import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { ChangeType } from 'modules/assess/models/rulesets/version-diff/common';

const Header = styled.h2`
  font-weight: bold;
`;

const Icon = styled(M.Icon)`
  margin-left: 0.2rem;
  margin-top: 0.3rem;
`;

type Props = {
  changeType: ChangeType;
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  width: 100%;
`;

export const Title: React.FC<Props> = ({ changeType }) => {
  const { t } = useTranslation();
  const icon = t(
    `assess:ruleset.version.publish.changes.common-custom-count.${changeType}.icon`,
  );
  const title = t(
    `assess:ruleset.version.publish.changes.common-custom-count.${changeType}.title`,
  );

  return (
    <Container>
      <Header>{title}</Header>
      <Icon icon={icon} size='16' />
    </Container>
  );
};

export default Title;
