import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';

import FormHeading from '../FormHeading';

const DEFAULT_STATE = {
  include_object: true,
  live: true,
  webhook_url: '',
};

Object.freeze(DEFAULT_STATE);

export default class AddWebhookUrls extends React.Component {
  static propTypes = {
    addWebhook: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
  }

  componentDidUpdate(prevProps) {
    const { isSuccess } = this.props;
    const { isSuccess: prevSuccess } = prevProps;
    if (isSuccess !== prevSuccess && isSuccess) {
      // eslint-disable-next-line
      this.setState(DEFAULT_STATE);
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const { include_object, webhook_url, live } = this.state;
    const { addWebhook } = this.props;

    addWebhook({
      include_object,
      webhook_url,
      live,
    });
  };

  handleInput = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };

  handleCheck = (event, { checked, id }) => {
    this.setState({ [id]: checked });
  };

  render() {
    const {
      handleCheck,
      handleInput,
      onSubmit,

      state: { include_object, webhook_url },
    } = this;

    return (
      <form onSubmit={onSubmit}>
        <M.Container.Row>
          <M.Container.Col>
            <FormHeading>New Webhook</FormHeading>
          </M.Container.Col>
        </M.Container.Row>
        <M.ActionableNotification
          hideActionButton
          kind='warning'
          inline='classic'
          style={{ maxWidth: '52rem' }}
          title='Important:'
          subtitle={
            <>
              <p>
                Any webhook URL that fails to accept with a 2XX status code at
                least one request over a period of 60 days will be removed
                automatically - e.g. webhooks failing for 100% of the requests
                during 60 or more days.
              </p>
              <p>
                For more information see{' '}
                <a
                  href='https://docs.checkr.com/partners/#section/Webhooks'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Webhooks
                </a>{' '}
                in the Checkr API docs.
              </p>
            </>
          }
          hideCloseButton
        />
        <M.Container.Row>
          <M.Container.Col>
            <M.Input
              placeholder='URL'
              labelText='Webhook URL'
              value={webhook_url}
              onChange={handleInput}
              name='webhook_url'
              id='webhook_url'
            />
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            <M.Checkbox
              id='include_object'
              name='include_object'
              labelText='Include related object in payload'
              onChange={handleCheck}
              checked={include_object}
              type='inline'
            />
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row style={{ justifyContent: 'flex-end' }}>
          <M.Container.Col style={{ textAlign: 'right' }}>
            <M.Button onClick={onSubmit} className='add-webhook'>
              Add
            </M.Button>
          </M.Container.Col>
        </M.Container.Row>
      </form>
    );
  }
}
