import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const MultipleReportsRow = styled(M.TableRow)`
  background: ${colors.uiGrey100};

  &.newSearchTable {
    background: ${colors.brandSlate1};
    overflow-x: scroll;

    td.mastodon-table-cell {
      padding-left: 1rem !important;
      padding-right: 1rem;

      &.status {
        padding-right: 0rem;
      }

      &.created_at, &.completed_at {
        padding-left: 1rem !important;
      }

      &.full_name, &.geos {
        padding-left: 0rem !important;
      }
    }
  }

  td.mastodon-table-cell {
    padding-left: 1.5rem !important;
    padding-right: 2rem;

    &.full_name, &.geos {
      padding-left: 0rem !important;
    }

  }
}`;

export const ReportCountTag = styled(M.Tag)`
  color: ${colors.uiGrey0};
  background: ${colors.uiNavy600};
  white-space: nowrap;
}`;

export const ReportCountTableCell = styled(M.TableCell)`
  padding: 0.125rem 0 0 0.25rem !important;

  &.newSearchTableCell {
    padding: 0.125rem 0 0 0rem !important;
  }

  span.cds--popover {
    & span.cds--popover-content.cds--definition-tooltip {
      color: ${colors.uiTextPrimaryLight} !important;
    }
  }

  .cds--tag {
    border-radius: 2px;
    height: 19px;
    width: 16px;
    min-width: unset;
  }
`;

export const StyledSearchCell = styled(M.TableCell)`
  font-size: 12px !important;

  &.unclickable {
    cursor: default;
  }
`;

export const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .mastodon-icon {
    margin-right: 16px;
  }
`;

export const ProcessingStatusTableCell = styled(M.TableCell)`
  padding: 0 !important;

  &.newSearchTableCell {
    padding: 0.4rem 0rem 0.4rem 0rem !important;
  }

  max-width: 1rem !important;
  &.flex-cell {
    display: flex;
  }
  &.start-cell {
    margin: 0 0.5rem;
  }
`;

export const StyledTooltipDefinition = styled(M.TooltipDefinition)`
  .cds--definition-term svg {
    fill: ${colors.uiGrey0};
  }
  .cds--loading--small {
    margin: -0.5rem 0rem;
  }
`;
