import moment from 'moment';
import { Exception, CountyCriminalSearchWithEta } from 'types';

export const hasCourtClosureException = (exceptions: Exception[] | null) => {
  if (exceptions) {
    return exceptions.some(
      exception => exception.type === 'courts_delayed_or_inaccessible',
    );
  }
  return false;
};

export const genericTriggerText = 'How are estimates calculated?';
export const processingTooltipText =
  'We use historical data for counties in the United States to estimate completion times for all searches in this report.';

export const normalizeDate = (dateString: string) => {
  const rawDate = new Date(dateString);
  return new Date(
    rawDate.getTime() + rawDate.getTimezoneOffset() * 60000,
  ).toISOString();
};

export const isDatePastEta = (
  predictionDate?: string,
  completedAt?: string,
) => {
  if (!predictionDate) return false;

  const normalizedDate = normalizeDate(predictionDate);
  const predictionDateInSeconds =
    new Date(normalizedDate).setHours(23, 59, 59, 0) / 1000;

  if (completedAt) {
    const completedInSeconds = new Date(completedAt).getTime() / 1000;

    return predictionDateInSeconds < completedInSeconds;
  }

  const now = new Date().setHours(0, 0, 0, 0) / 1000;
  return predictionDateInSeconds < now;
};

export const parseDateString = (
  dateString?: string | null,
  estCompletion?: boolean,
) => {
  if (!dateString) return null;

  const normalizedDate = normalizeDate(dateString);
  const dateObj = new Date(normalizedDate);
  if (estCompletion) {
    return moment(dateObj).format('ll');
  }
  const date = moment(dateObj).format('M/DD/YYYY');
  const day = moment(dateObj).format('ddd');
  return `${day}, ${date}`;
};

export const getEstimateKey = (screening: CountyCriminalSearchWithEta) => {
  const { hasEta, prediction_datetime } = screening;
  let estimate = 'Estimated';
  if (hasEta && isDatePastEta(prediction_datetime)) {
    estimate = 'Previous Estimate';
  }
  return estimate;
};
