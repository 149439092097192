import React from 'react';

type AliasType = {
  id: string;
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
};

const Alias: React.FC<{ alias: AliasType }> = ({ alias }) => {
  const { first_name, middle_name, last_name } = alias;

  return (
    <p data-testid='alias-names'>
      {first_name && `${first_name} `}
      {middle_name && `${middle_name} `}
      {last_name && last_name}
    </p>
  );
};

export default Alias;
