import React from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  toggle: () => void;
};

const btnStyle = { margin: '0rem 0.5rem' };
const CsvExportButton: React.FC<Props> = ({ toggle }) => (
  <M.Button
    kind='secondary'
    size='sm'
    onClick={toggle}
    style={btnStyle}
    className='export-candidate-csv'
  >
    <M.Icon icon='Download' style={{ marginRight: '0.5rem' }} />
    Export to CSV
  </M.Button>
);

export default CsvExportButton;
