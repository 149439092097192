import React, { useEffect } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useDispatch } from '../../context';

type Props = {
  [key: string]: any;
};

const Message = styled.div`
  text-align: center;
  padding: 5rem;
  background: ${colors.uiGrey0};
`;

const Icon = styled(M.Icon)`
  fill: ${colors.uiNavy600};
  margin-bottom: 0.5rem;
`;

const SuccessMessage: React.FC<Props> = () => {
  // TODO: add analytics to the buttons
  const dispatch = useDispatch();

  // reset modal navigation on success
  useEffect(() => {
    dispatch({
      type: 'SET_NAVIGATION',
      payload: {},
    });
  }, [dispatch]);

  return (
    <Message>
      <Icon icon='Email' size='32' />
      <h5>Pre-adverse action notice successfully sent</h5>
    </Message>
  );
};

export default SuccessMessage;
