import React from 'react';

import {
  Checkbox,
  Container,
  Description,
  SensitiveInput,
  TextInput,
} from './styles';

type StringProps = {
  configKey: string;
  description: string;
  fieldType: 'string'; // TODO: Add more field types. Workday has only three types
  value: string;
  invalidText: string | undefined;
  encrypted: boolean;
  onChange: (key: string, value: string) => void;
};
type BooleanProps = {
  configKey: string;
  description: string;
  fieldType: 'boolean'; // TODO: Add more field types. Workday has only three types
  value: boolean;
  invalidText: string | undefined;
  encrypted?: undefined;
  onChange: (key: string, value: string) => void;
};

type Props = StringProps | BooleanProps;

const ConfigInput: React.FC<Props> = ({
  configKey,
  description,
  value,
  invalidText,
  fieldType,
  encrypted,
  onChange,
}: Props) => {
  const onInputChange = React.useCallback(
    event => {
      const value =
        fieldType === 'boolean' ? event.target.checked : event.target.value;
      onChange(configKey, value);
    },
    [fieldType, onChange, configKey],
  );
  const invalid = invalidText !== undefined;
  return (
    <Container data-testid={`ats-config-input-container-${configKey}`}>
      <Description data-testid={`ats-config-input-description-${configKey}`}>
        {description}
      </Description>
      {fieldType === 'string' && !encrypted && (
        <TextInput
          id={`ats-config-input-${configKey}`}
          data-testid={`ats-config-input-${configKey}`}
          type={fieldType}
          value={value}
          onChange={onInputChange}
          invalid={invalid}
          invalidText={invalidText}
        />
      )}
      {fieldType === 'string' && encrypted && (
        <SensitiveInput
          id={`ats-config-sensitive-input-${configKey}`}
          data-testid={`ats-config-sensitive-input-${configKey}`}
          value={value}
          defaultValue={value}
          onChange={onInputChange}
          invalid={invalid}
          invalidText={invalidText}
        />
      )}
      {fieldType === 'boolean' && (
        <Checkbox
          id={`ats-config-checkbox-${configKey}`}
          data-testid={`ats-config-checkbox-${configKey}`}
          checked={value}
          onChange={onInputChange}
          invalid={invalid}
          invalidText={invalidText}
        />
      )}
    </Container>
  );
};

export default ConfigInput;
