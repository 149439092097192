import React from 'react';
import styled from 'styled-components';
import AddAddons from './AddAddons';
import { useOrderBackgroundCheckContext } from '../Context';

const Container = styled.div`
  width: 100%;
  max-width: 1170px;
`;

const CustomizeWithAddons: React.FC<any> = () => {
  const { state } = useOrderBackgroundCheckContext();

  return !state.basePackage.name ? null : (
    <Container>
      <AddAddons />
    </Container>
  );
};

export default CustomizeWithAddons;
