import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  GetBulkNoticesError,
  SendBulkNoticesClearDatesButton,
  SendBulkNoticesDatePickerContainer,
} from './AdverseActionsStyledComponents';

type Props = {
  isProcessing: boolean;
  dateChange: Function;
  clearData: Function;
};

const BulkNoticesDatePicker: React.FC<Props> = ({
  isProcessing = false,
  dateChange,
  clearData,
}) => {
  window.moment = moment;
  const [dateRangeError, setDateRangeError] = useState(false);
  const [createdAfter, setCreatedAfter] = useState('');
  const [createdBefore, setCreatedBefore] = useState('');

  /**
   * @name dateRangeIsValid
   * @function
   * @memberOf BulkSendNotices
   * @description Validates that the dates selected are within the allowed range
   * @returns {boolean}
   * @param {string} before
   * @param {string} after
   */
  const dateRangeIsValid = useCallback((before, after) => {
    let delta = moment(before).diff(after, 'days');
    if (delta < 0) {
      delta *= -1;
    }
    return delta <= 7;
  }, []);

  /**
   * @name clearDates
   * @function
   * @memberOf BulkSendNotices
   * @description Clear the dates
   */
  const clearDates = useCallback(() => {
    setCreatedBefore('');
    setCreatedAfter('');
    clearData();
  }, [clearData]);

  /**
   * @name bothDatesSet
   * @function
   * @memberOf BulkSendNotices
   * @description Checks to see if both dates have been set.  If the dates are passed in, we compared those, otherwise we compare the dates that are already stored in state.
   * @returns {boolean}
   * @param {string} before
   * @param {string} after
   */
  const bothDatesSet = useCallback((before?, after?) => before && after, []);

  /**
   * @name isAfter
   * @function
   * @memberOf BulkSendNotices
   * @description Checks to see if the after date comes after the before date
   * @returns {boolean}
   * @param {string} newAfter - The new after string
   */
  const isAfter = useCallback(
    newAfter => moment(createdBefore).isAfter(newAfter) && moment,
    [createdBefore],
  );

  /**
   * @name isBefore
   * @function
   * @memberOf BulkSendNotices
   * @description Checks to see if the after date comes after the before date
   * @returns {boolean}
   * @param {string} newBefore - The new before string
   */
  const isBefore = useCallback(
    newBefore => moment(createdAfter).isBefore(newBefore),
    [createdAfter],
  );

  /**
   * @name onDateChange
   * @function
   * @memberOf BulkSendNotices
   * @description Method description
   * @param {string} mode - This indicates which was changed, before or after
   * @param {string} before
   * @param {string} after
   */
  const onDateChange = useCallback(
    (mode, { before, after }) => {
      setDateRangeError(false);

      if (mode === 'before' && !isBefore(before)) {
        clearData();
        after = '';
      }

      if (mode === 'after' && !isAfter(after)) {
        clearData();
        before = '';
      }

      if (bothDatesSet(before, after) && !dateRangeIsValid(before, after)) {
        setDateRangeError(true);
        return;
      }

      setCreatedBefore(before);
      setCreatedAfter(after);
      if (bothDatesSet(before, after) && dateRangeIsValid(before, after)) {
        dateChange(before, after);
      }
    },
    [
      setDateRangeError,
      bothDatesSet,
      dateRangeIsValid,
      clearData,
      isBefore,
      isAfter,
      dateChange,
    ],
  );

  /**
   * @name getDate
   * @function
   * @memberOf BulkSendNotices
   * @description Returns a value to be set to the state
   * @returns {string}
   * @param {array} dates - The array with dates returned by the Mastodon date picker
   */
  const getDate = useCallback(
    dates => (dates.length ? dates[0].toISOString() : ''),
    [],
  );

  /**
   * @name onCreatedBeforeChange
   * @function
   * @memberOf BulkSendNotices
   * @description Update created before date
   * @param {array} dates - The array of dates returned by the Mastodon date picker
   */
  const onCreatedBeforeChange = useCallback(
    dates => {
      const before = getDate(dates);
      const after = createdAfter;
      onDateChange('before', { before, after });
    },
    [getDate, onDateChange, createdAfter],
  );

  /**
   * @name onCreatedAfterChange
   * @function
   * @memberOf BulkSendNotices
   * @description Update created before date
   * @param {array} dates - The array of dates returned by the Mastodon date picker
   */
  const onCreatedAfterChange = useCallback(
    dates => {
      const after = getDate(dates);
      const before = createdBefore;
      onDateChange('after', { before, after });
    },
    [onDateChange, getDate, createdBefore],
  );

  return (
    <>
      <M.GridRow>
        <M.GridCol>
          <SendBulkNoticesDatePickerContainer>
            <M.DatePicker
              id='bulk-send-created-after'
              dateFormat='m/d/y'
              onChange={onCreatedAfterChange}
              datePickerType='single'
              value={createdAfter}
            >
              <M.DatePickerInput
                id='bulk-send-created-after-input'
                labelText='From'
                placeholder='MM/DD/YY'
                disabled={isProcessing}
              />
            </M.DatePicker>
            <M.DatePicker
              id='bulk-send-created-before'
              dateFormat='m/d/y'
              onChange={onCreatedBeforeChange}
              datePickerType='single'
              value={createdBefore}
            >
              <M.DatePickerInput
                id='bulk-send-created-before-input'
                labelText='To'
                placeholder='MM/DD/YY'
                disabled={isProcessing}
              />
            </M.DatePicker>
          </SendBulkNoticesDatePickerContainer>
        </M.GridCol>
        <M.GridCol>
          {(createdBefore !== '' || createdAfter !== '') && (
            <SendBulkNoticesClearDatesButton
              data-testid='clear-dates-button'
              kind='tertiary'
              size='lg'
              onClick={clearDates}
            >
              Clear dates
            </SendBulkNoticesClearDatesButton>
          )}
        </M.GridCol>
      </M.GridRow>
      {dateRangeError && (
        <M.GridRow>
          <M.GridCol>
            <GetBulkNoticesError>
              The maximum allowed date-range is 1 week.
            </GetBulkNoticesError>
          </M.GridCol>
        </M.GridRow>
      )}
    </>
  );
};

export default BulkNoticesDatePicker;
