import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { shortDateTime24h } from '@dashboard-experience/utils';
import { Report, GenericObject } from 'types';
import { SCREENING_TITLES } from 'utils';
import { ItemType } from 'types/report/item';
import KeyValueSection from '../Sections/KeyValueSection';

type GeneralProps = {
  report: Report;
  commentType: string;
};

const GeneralComments: React.FC<GeneralProps> = ({ report, commentType }) => {
  const comment = report[commentType];
  const title =
    commentType === 'public_comments' ? 'Comments' : 'Statement of Dispute';
  const id =
    commentType === 'public_comments'
      ? SCREENING_TITLES.public_comment.id
      : SCREENING_TITLES.statement_of_dispute.id;

  if (!comment) return null;

  const commentsArr: ItemType[] = [];
  comment?.forEach((comObj: GenericObject) => {
    commentsArr.push({
      itemKey: shortDateTime24h(comObj.updated_at),
      itemValue: comObj.comment,
    });
  });

  return (
    <>
      {!!comment && (
        <M.Screening title={title} id={id} data-testid={comment}>
          <KeyValueSection items={commentsArr} />
        </M.Screening>
      )}
    </>
  );
};

export default GeneralComments;
