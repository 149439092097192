import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useUpdate } from '../api';
import { Dialog } from '../dialog';

type Props = {
  open: boolean;
  ruleset?: Ruleset.Type;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ open, ruleset, onClose }) => {
  const update = useUpdate(ruleset?.id);
  const initialName = ruleset ? `${ruleset.name}` : '';
  const [name, setName] = useState(initialName);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleOnClose = useCallback(() => {
    setName(initialName);
    onClose?.();
  }, [initialName, onClose]);

  const handleUpdate = useCallback(() => {
    update.call({ name });
  }, [update, name]);

  useEffect(() => {
    setSubmitDisabled(name === '');
  }, [name]);

  useEffect(() => {
    switch (update.result.status) {
      case 'idle':
        return;
      case 'loading':
        setSubmitDisabled(true);
        return;
      case 'error':
        setSubmitDisabled(false);
        return;
    }

    handleOnClose();
  }, [handleOnClose, update.result.data, update.result.status]);

  return (
    <Dialog
      disabled={submitDisabled}
      initialName={initialName}
      open={open}
      setName={setName}
      status={update.result.status}
      onClose={handleOnClose}
      onSubmit={handleUpdate}
      update
    />
  );
};

export default Container;
