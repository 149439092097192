import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';
import styled from 'styled-components';

type Props = {
  disabled?: boolean;
  onSave?: (event: SyntheticEvent) => void;
};

const Container = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const Actions: React.FC<Props> = ({ disabled, onSave }) => {
  return (
    <Container>
      <Button
        disabled={disabled}
        kind='primary'
        trans='verbs.save'
        onClick={onSave}
      />
    </Container>
  );
};

export default Actions;
