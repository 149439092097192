import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const StyledIcon = styled(M.Icon)`
  margin-right: 16px;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${colors.uiTextPrimaryLight};
  margin-bottom: 16px;
`;

const AddScreeningsButton: React.FC<any> = props => {
  return (
    <div>
      <Label>
        <strong>Need to add another screening?</strong>
      </Label>
      <M.Button id='choose-add-ons' {...props}>
        <StyledIcon icon='Add' /> Choose add-ons
      </M.Button>
    </div>
  );
};

export default AddScreeningsButton;
