import { Category } from 'modules/assess/models/mvr/category';
import { ItemType } from 'modules/assess/models/mvr/codes';
import React from 'react';
import Description from '../description';
import Title from '../title';
import Table from './table';

type Props = {
  data?: Array<Category>;
  type: ItemType;
};

export const Categories: React.FC<Props> = ({ data, type }) => {
  return (
    <section data-testid='assess-mvr-category-set-version-mappings-categories'>
      <Title section='categories' type={type} />
      <Description section='categories' type={type} />
      <Table data={data} type={type} />
    </section>
  );
};

export default Categories;
