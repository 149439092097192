import React, { SyntheticEvent, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import Option from 'modules/assess/models/context-date/option';
import { useDispatch } from 'modules/assess/ui/ruleset/version/context-dates/store';
import * as Entity from 'modules/assess/models/rulesets/context-dates';
import { useTranslation } from 'react-i18next';
import { Actions } from 'modules/assess/ui/state/context-date/options';
import Content from './Content';
import Save from './Save';

type Props = {
  onClose: (event?: SyntheticEvent) => void;
  open: boolean;
  options: Option[];
};

export const Container: React.FC<Props> = ({ onClose, open, options }) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:context_dates.create.dialog.title');

  const handleClose = useCallback(() => {
    setSelectedOptions([]);
    onClose();
  }, [onClose, setSelectedOptions]);

  const handleCreate = useCallback(() => {
    dispatch(Actions.CreateAll.create(optionsToEntities(selectedOptions)));
    handleClose();
  }, [dispatch, handleClose, selectedOptions]);

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-version-context-dates-create-dialog'
      onClose={handleClose}
      open={open}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-version-context-dates-create-dialog-title'
        title={modalTitle}
        closeModal={handleClose}
      />
      <M.ModalBody data-testid='assess-ruleset-version-context-dates-create-dialog-content'>
        <Content
          options={options}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-version-context-dates-create-dialog-actions'>
        <Save disabled={!selectedOptions.length} onCreate={handleCreate} />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

function optionsToEntities(options: Option[]) {
  return options.map(o => Entity.initialize({ option: o }));
}

export default Container;
