import * as Entity from 'modules/assess/models/rulesets/version';
import client from '../client';
import { Namespace } from '../constants';
import { fromAPI } from './utilities/versions';

const BASE_PATH = 'rulesets';

export const active = (
  namespace: Namespace,
  id: string,
): Promise<Entity.Type> => {
  const path = `${namespace}/${BASE_PATH}/${id}/versions/active`;
  return client.get(path).then(fromAPI);
};
