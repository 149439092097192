import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const ActionableNotification = styled(M.ActionableNotification)`
  margin-top: 0;
  max-width: 100% !important;
  .mastodon-list-item {
    font-weight: bold;
  }
`;

type LinkProps = { text: string; href: string | null; [key: string]: any };

const Container = styled.span`
  svg {
    margin-right: 0.25rem;
  }
`;

export const DocLink = ({ text, href, ...props }: LinkProps) => {
  const children = (
    <Container>
      <M.Icon icon='Attachment' />
      {text}
    </Container>
  );

  return href ? (
    <M.Link target='_blank' rel='noopener noreferrer' href={href} {...props}>
      {children}
    </M.Link>
  ) : (
    children
  );
};
