/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useCallback, useState, useMemo, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useEffectAfterInitialized } from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import { useSearchState } from 'components/Search/search-context';
import { updateSearchParams } from 'actions/SearchActions';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { isDateValid, formatDate, onFilterChange } from '../helpers';
import UserContext from '../../../../context/CurrentUser';

type Props = {};

const Dob: React.FC<Props> = () => {
  const { filters, setFilters } = useSearchState();
  const { dob } = filters;
  const dispatch = useDispatch();
  const [dobVal, setDobVal] = useState(dob ? formatDate(dob) : undefined);
  const handleFilterChange = useMemo(
    () => onFilterChange(setFilters, 'dob'),
    [setFilters],
  );
  const currentUser = useContext(UserContext);

  useEffectAfterInitialized(() => {
    if (dob !== undefined) {
      dispatch(updateSearchParams({ dob }));

      // If the local value hasn't been set yet, then initialize it based on Redux's value:
      if (dobVal === undefined) {
        setDobVal(dob.replaceAll('-', '/'));
      }
    }
    // If Redux's value has been cleared out, then also clear out the local value:
    else if (dob === undefined && dobVal) {
      setDobVal('');
    }
  }, [dob, dispatch]);

  const onInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const value = (e?.target as HTMLInputElement)?.value;
      if (!value) {
        handleFilterChange('');
        setDobVal('');
      }
      // update filter only when it's a complete and valid date
      if ((value.length === 8 || value.length === 10) && isDateValid(value)) {
        handleFilterChange(formatDate(value, 'MM-DD-YYYY'));
        trackEventCallback();
      }
      // set only the internal state to avoid multiple calls to the search endpoint
      setDobVal(value);
    },
    [handleFilterChange],
  );

  const onDateChange = useCallback(
    (value: string) => {
      setFilters((prevState: any) => {
        if (prevState.dob !== formatDate(value, 'MM-DD-YYYY')) {
          trackEventCallback();
        }

        return {
          ...prevState,
          dob: formatDate(value, 'MM-DD-YYYY'),
        };
      });
      setDobVal(formatDate(value));
    },
    [setFilters],
  );

  const trackEvent = useTrackEvent();

  const trackEventCallback = () => {
    if (currentUser) {
      trackEvent(
        CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
        {
          'Date of Birth': '*Sensitive data is not displayed',
          'Selected Filter': 'Date of Birth',
        },
      );
    }
  };

  return (
    <M.DobFilter
      inputProps={{
        value: dobVal || '',
        onChange: onInputChange,
        onDateChange,
        autoComplete: 'off',
      }}
    />
  );
};

export default Dob;
