import React from 'react';
import moment from 'moment';
import { GenericObject } from 'types';
import { CellProps } from './types';
import { mostRecent } from './utils';

const CompletedAt: React.FC<CellProps> = ({ candidate, report }) => {
  const { reports } = candidate;
  const mostRecentReport: GenericObject | undefined = mostRecent(reports);
  const displayedReport = report || mostRecentReport;

  const completedAt = displayedReport?.completed_at
    ? moment(displayedReport.completed_at).format('MMM D, YYYY')
    : '';

  return <span>{completedAt}</span>;
};

export default CompletedAt;
