import React, { useCallback, useContext, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';
import { useTranslation } from 'react-i18next';
import UserContext from 'context/CurrentUser';
import {
  Divider,
  StyledH3,
  StyledButton,
  GridCol,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import {
  BillingAddressResponse,
  PostAddressParams,
  usePostBillingAddress,
} from '../../../api/payment';
import BillingAddressForm from './BillingAddressForm';

type Props = {
  addressData?: Partial<BillingAddressResponse>;
  fetchLoading: any;
  fetchSuccess: any;
  fetchError: any;
};

const BillingAddress: React.FC<Props> = ({
  addressData,
  fetchLoading,
  fetchSuccess,
  fetchError,
}) => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const { call: postAddress, result } = usePostBillingAddress();

  const submit = useCallback(
    (params: PostAddressParams) => {
      params.account_id = userData.account.id;
      postAddress(params).finally(() => {
        hideModal();
      });
    },
    [hideModal, postAddress, userData.account.id],
  );

  const data: Partial<PostAddressParams> = result?.data
    ? {
        name: result.data.name,
        ...result.data.address,
      }
    : {
        name: addressData?.name,
        ...addressData?.address,
      };
  const isLoaded: boolean = result?.isSuccess || fetchSuccess;
  const isProcessing: boolean = result?.isLoading || fetchLoading;
  const error: true | Error | null = result?.isError || fetchError;

  return (
    <>
      <M.Grid>
        <M.GridRow>
          <CustomCol col={5} noPadding style={{ marginTop: '0' }}>
            <M.GridRow>
              <GridCol>
                <StyledH3 className='mb-0'>
                  {t(`billing_address.title`)}
                </StyledH3>
              </GridCol>
              {!data.name && (
                <GridCol style={{ textAlign: 'right' }}>
                  <StyledButton kind='primary' onClick={showModal}>
                    {t(`billing_address.add_new_btn`)}
                  </StyledButton>
                </GridCol>
              )}
            </M.GridRow>
            {data.name && (
              <M.GridRow className='mt-16'>
                <GridCol>
                  <p>
                    {data.name}
                    <br />
                    {data.address_line_1} {data.address_line_2}
                    <br />
                    {data.city} {data.region} {data.postal_code}
                    <br />
                    {data.country}
                  </p>
                </GridCol>
                <GridCol style={{ textAlign: 'right' }}>
                  <StyledButton
                    kind='secondary'
                    data-test-id='address-edit-button'
                    onClick={showModal}
                  >
                    {t(`payment.button_labels.edit`)}
                  </StyledButton>
                </GridCol>
              </M.GridRow>
            )}
            <Divider />
          </CustomCol>
        </M.GridRow>
      </M.Grid>

      {isLoaded && (
        <BillingAddressForm
          addressData={data}
          submit={submit}
          modalOpen={modalOpen}
          hideModal={hideModal}
          isProcessing={isProcessing}
          error={error}
        />
      )}
    </>
  );
};

export default BillingAddress;
