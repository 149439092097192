/* eslint-disable no-shadow */
import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';

import { colors } from '@dashboard-experience/mastodon';

import * as API from 'api';
import { GenericObject } from 'types';
import { DEFAULT_NOTIFICATION_STATE } from 'Constants';
import * as User from 'modules/core-types/user';
import UserContext from 'context/CurrentUser';
import { StateType } from './Types';

import NotificationCheckbox from './NotificationCheckbox';

const Form = styled.form`
  margin-top: 20px;
  color: ${colors.uiTextPrimaryLight};
`;

const ListItem = styled.li`
  margin-left: 24px;
`;

/**
 * @name getInitialState
 * @function
 * @memberOf Preferences
 * @description Updates default notification state
 * @param  {object} defaultState - The default notification state
 * @param {object} account - The user account
 * @param {object} settings - Updated settings
 * @param {object} user - current user
 * @return {object} initialState object
 */
const getInitialState = (
  defaultState: StateType,
  account: GenericObject,
  settings: GenericObject,
  user: GenericObject,
) => {
  const initialState = { ...defaultState };
  const { groupConfigOld } = initialState;

  const {
    adverse_action_pause,
    assess_enabled,
    translate_canceled_status_to_suspended,
  } = account;

  if (!assess_enabled) {
    delete groupConfigOld.assessments;
  }

  if (
    !adverse_action_pause ||
    !user?.permissions?.adverse_action_user_notifications
  ) {
    delete groupConfigOld.adverse_actions_paused;
  }

  if (translate_canceled_status_to_suspended) {
    const { items } = groupConfigOld.reports;

    delete items.notify_on_report_canceled;
  }

  groupConfigOld.reports.items.notify_on_report_disputed.disabled =
    settings.can_disable_notify_on_report_disputed;

  // updates default settings with user settings
  initialState.settings = { ...settings };

  Object.values(groupConfigOld).forEach(group => {
    // set the group toggle on value based on whether all values in respective
    // group's items evaluate to true based on settings
    initialState.groupToggleOn[group.name] = Object.keys(group.items).every(
      item => settings[item],
    );
    // sets indeterminate boolean value based on whether any/some of values
    // in items evaluate to true in settings
    initialState.groupToggleIndeterminate[group.name] = Object.keys(
      group.items,
    ).some(item => settings[item]);
    // if both groupToggleOn and groupToggleIndeterminate are true
    // then toggleIndeterminate to false
    if (
      initialState.groupToggleIndeterminate[group.name] &&
      initialState.groupToggleOn[group.name]
    ) {
      initialState.groupToggleIndeterminate[group.name] = false;
    }
  });

  return initialState;
};

const PreferencesOld: React.FC<{}> = () => {
  const user: User.Type = useContext(UserContext);
  const { account, settings }: GenericObject = user;
  const { call } = API.preferences.useUpdatePreferences();
  const [currentState, updateCurrentState] = useState(
    getInitialState(DEFAULT_NOTIFICATION_STATE, account, settings, user),
  );

  /**
   * @name onSavePreference
   * @function
   * @memberOf Preferences
   * @description Save the notification setting to the API
   * @param  {object} newPreferences - The object representing the preference setting
   * @param {string} groupName - The name of the group the preference belongs to
   */
  const onSavePreference = useCallback(
    newPreferences => {
      const { settings } = currentState;
      const updatedPreferences = { ...settings, ...newPreferences };

      // api call to update preferences
      call({ updatedPreferences, user });

      const updatedState = {
        ...currentState,
        settings: updatedPreferences,
      };

      updateCurrentState(updatedState);
      updateGroupToggleStates(updatedState);
    },
    [currentState, call, user],
  );

  /**
   * @name updateGroupToggleStates
   * @function
   * @memberOf Preferences
   * @description Updates the group toggle checkboxes each time a child
   *              checkbox is changed.
   * @param {object} updatedState The updated state object
   */
  const updateGroupToggleStates = (updatedState: StateType) => {
    const { groupConfigOld, settings } = updatedState;
    const groupToggleOn: GenericObject = {};
    const groupToggleIndeterminate: GenericObject = {};

    Object.values(groupConfigOld).forEach(group => {
      groupToggleOn[group.name] = Object.keys(group.items).every(
        item => settings[item],
      );
      groupToggleIndeterminate[group.name] = Object.keys(group.items).some(
        item => settings[item],
      );
      if (groupToggleIndeterminate[group.name] && groupToggleOn[group.name]) {
        groupToggleIndeterminate[group.name] = false;
      }
    });

    updateCurrentState({
      ...updatedState,
      groupToggleOn,
      groupToggleIndeterminate,
    });
  };

  /**
   * @name getGroupItems
   * @function
   * @memberOf Preferences
   * @description Helper method to return the array of a group's items
   * @returns {array}
   * @param {string} groupName - The group name
   */
  const getGroupItems = useCallback(
    (groupName: string) => {
      const { groupConfigOld }: GenericObject = currentState;
      return Object.values(groupConfigOld[groupName].items);
    },
    [currentState],
  );

  /**
   * @name onGroupToggle
   * @function
   * @memberOf Preferences
   * @description Toggles all checkboxes in a section
   * @param {object} toggle - The object with { name: value } being returned by the checkbox component
   */
  const onGroupToggle = useCallback(
    (toggle: GenericObject) => {
      const groupName: string = Object.keys(toggle)[0];
      const value: boolean = toggle[groupName];
      const groupItems: Array<any> = getGroupItems(groupName);
      const updatedPreferences: GenericObject = {};
      groupItems.forEach(item => {
        // update checkbox status when item is not disabled
        !item.disabled && (updatedPreferences[item.name] = value);
      });

      onSavePreference(updatedPreferences);
    },
    [getGroupItems, onSavePreference],
  );

  return (
    <Form id='checkr-preferences'>
      <h1>Notifications</h1>
      <p>Send me an email for...</p>
      <ul>
        {Object.values(currentState.groupConfigOld).map(
          (
            group: { name: string; label: string; items: GenericObject },
            groupIdx: number,
          ) => (
            <span key={`${group.name}_${groupIdx.toString()}`}>
              <li>
                <NotificationCheckbox
                  name={group.name}
                  value={currentState.groupToggleOn[group.name]}
                  indeterminate={
                    currentState.groupToggleIndeterminate[group.name]
                  }
                  label={group.label}
                  onSave={onGroupToggle}
                />
                <ul>
                  {Object.values(group.items).map((item, itemIdx) => (
                    <ListItem key={`${item.name}_${itemIdx.toString()}`}>
                      <NotificationCheckbox
                        name={item.name}
                        value={currentState.settings[item.name]}
                        label={item.label}
                        onSave={onSavePreference}
                        disabled={item.disabled}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            </span>
          ),
        )}
      </ul>
    </Form>
  );
};

export default PreferencesOld;
