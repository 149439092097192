import * as Account from './account';
import * as Base from './base';
import * as Geo from './geo';
import * as Package from './package';
import * as PackageAndGeo from './package_geo';
import * as PackageAndSegment from './package_segment';
import * as Program from './program';
import * as Segment from './segment';
import * as State from './state';

export {
  Account,
  Base,
  Geo,
  Package,
  PackageAndGeo,
  PackageAndSegment,
  Program,
  Segment,
  State,
};

export const { Kind } = Base;

export type Type =
  | Account.Type
  | Geo.Type
  | Package.Type
  | PackageAndGeo.Type
  | PackageAndSegment.Type
  | Program.Type
  | Segment.Type
  | State.Type;

export type List = Array<Type>;
export type Map = { [key: string]: Type };
