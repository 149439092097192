import React from 'react';
import { get } from 'lodash';
import { CellProps } from './types';

const DefaultCell: React.FC<CellProps> = ({ candidate, cellKey }) => {
  const value = get(candidate, cellKey);
  return value ? (
    <span>
      {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
    </span>
  ) : null;
};

export default DefaultCell;
