import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const StyledIcon = styled(M.StatusIcon)`
  background-color: ${colors.brandAqua3} !important;
  margin-top: 0.7em;
  margin-right: 5px;
  height: 10px !important;
  width: 10px !important;
  svg {
    fill: ${colors.brandAqua3} !important;
  }
`;

const StyledContainer = styled.div`
  height: 21px;
  padding: 3px 15px 2px;
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
`;

const StyledText = styled.span`
  color: ${colors.brandAqua3};
  font-weight: bold;
  font-size: 8px;
  height: 16px;
  letter-spacing: 1px;
  line-height: 30px;
`;

type Props = {
  current: Number;
  total: Number;
};

export const Added: React.FC<Props> = ({ current, total }) => {
  const { t } = useTranslation('assess:v2');
  const text = t('common.count.current_state', {
    current,
    total,
  }).toUpperCase();

  return (
    <StyledContainer>
      <StyledIcon icon='clear' />
      <StyledText data-testid='assess-v2-ui-common-components-count-added'>
        {text}
      </StyledText>
    </StyledContainer>
  );
};

export default Added;
