import { SET_CONTEXT_PARAM, WINDOW_RESIZE } from 'actions/ActionTypes';

export const initialState = {
  width: typeof window === 'object' ? window.innerWidth : null,
  height: typeof window === 'object' ? window.innerHeight : null,
  context: 'default',
  contextId: null,
};

// eslint-disable-next-line default-param-last
const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    case SET_CONTEXT_PARAM:
      return {
        ...state,
        context: action.context,
        contextId: action.contextId,
      };
    default:
      return state;
  }
};

export default uiReducer;
