import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import ModalBody from '../body';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.75rem 1rem;

  .mastodon-icon {
    flex-shrink: 0;
    fill: ${colors.uiOrange600};
  }
`;

type Props = {
  description: string | React.ReactNode;
};

const GenericError: React.FC<Props> = ({ description }) => {
  return (
    <ModalBody>
      <Container>
        <M.Icon icon='WarningAlt' size={32} />
        {description}
      </Container>
    </ModalBody>
  );
};

export default GenericError;
