import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import * as Route from '../../route';
import { RootRoute } from '../../router/context';
import Breadcrumb from './Breadcrumb';
import { Container as HomeHeader } from './home';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.brandSlate3};
  box-shadow: 0px 2px 4px -2px ${colors.brandSlate2};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
`;

const Header: React.FC = () => {
  const routes = useContext(Route.Context);
  const match = useRouteMatch();
  const root = useContext(RootRoute);

  if (match.path === root) {
    return <HomeHeader />;
  }

  const items = routes.map(route => (
    <Breadcrumb key={route.title} route={route} />
  ));

  return (
    <Container>
      <M.Breadcrumb noTrailingSlash>{items}</M.Breadcrumb>
    </Container>
  );
};

export default Header;
