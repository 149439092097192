import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import { Quickstart } from 'modules/assess/v2/models/amplitude/guidelines';
import { PremiumUpgrade } from 'modules/assess/v2/ui/common';
import { useStandard } from 'modules/assess/v2/ui/hooks';

const Title = styled.div`
  /* Text formatting */
  color: ${colors.brandNavy4};
  font-size: 18px;
  font-weight: bold;
`;

export const Container: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const text = t(`standard.guidelines.quickstart.upgrade.title`);

  const isStandard = useStandard();

  if (!isStandard) {
    return null;
  }

  return (
    <div>
      <Title>{text}</Title>
      <PremiumUpgrade eventProperty={Quickstart.Pagename} />
    </div>
  );
};

export default Container;
