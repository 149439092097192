import React from 'react';
import { FieldComponentProps } from '../../types';
import Date from './date';

const RevisedAt: React.FC<Pick<FieldComponentProps, 'report' | 'testid'>> = ({
  report,
  testid,
}) => <Date report={report} fieldKey='revised_at' testid={testid} />;

export default RevisedAt;
