import { Client } from 'api';
import { GenericObject } from 'types';
import { WorkLocation } from 'modules/core-types/continuous_check_subscription';

import {
  CANDIDATES_PATH,
  CONTINUOUS_CHECK_PATH,
  SUBSCRIPTIONS_PATH,
  BULK_ACTION_PATH,
  SUBSCRIPTION_ERROR_PATH,
  CSV_EXPORT_PATH,
  WORKER_PATH,
} from './constants';

export const deleteContinuousCheck = (params: {
  id: string;
}): Promise<GenericObject> => {
  return Client.delete(`v1/${CONTINUOUS_CHECK_PATH}/${params.id}`);
};

export type CreateContinuousCheckParams = {
  candidate_id: string;
  type: string;
  node?: string;
  work_locations?: Array<WorkLocation>;
  account: string; // account_uri_name
  facis_level?: string;
};

export const createContinuousCheck = (
  params: CreateContinuousCheckParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(
    `${CANDIDATES_PATH}/${params.candidate_id}/${CONTINUOUS_CHECK_PATH}`,
    params,
  );
};

export type UpdateContinuousCheckParams = {
  id: string;
  node?: string;
  work_locations?: Array<any>;
  account: string; // account_uri_name
};

export const updateContinuousCheck = (
  params: UpdateContinuousCheckParams,
): Promise<GenericObject> => {
  return Client.post(`v1/${CONTINUOUS_CHECK_PATH}/${params.id}`, params);
};

export const getContinuousChecks = (
  candidateId: string,
): Promise<GenericObject> => {
  return Client.get(
    `${CANDIDATES_PATH}/${candidateId}/${CONTINUOUS_CHECK_PATH}`,
  );
};

export const getSubscriptions = (
  candidateId: string,
): Promise<GenericObject> => {
  const params = {
    status: 'active',
    candidate_id: candidateId,
  };
  return Client.get(SUBSCRIPTIONS_PATH, { params });
};

export type CreateSubscriptionParams = {
  package: string;
  candidate_id: string;
  start_date: string;
  interval_count: string | number;
  interval_unit: string;
  node?: string;
  work_locations?: Array<WorkLocation>;
};

export const createSubscription = (
  params: CreateSubscriptionParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(`${SUBSCRIPTIONS_PATH}`, params);
};

export const deleteSubscription = (params: {
  id: string;
}): Promise<GenericObject> => {
  return Client.delete(`${SUBSCRIPTIONS_PATH}/${params.id}`);
};

export const requestPerformBulkAction = (params: {
  account_id: string;
  action: string;
  file_storage_key: string;
  subscription_type: string;
  uploader_email: string;
}): Promise<GenericObject> => {
  return Client.post(`${BULK_ACTION_PATH}`, {
    account_id: params.account_id,
    action: params.action,
    file_name: params.file_storage_key,
    subscription_type: params.subscription_type,
    uploader_email: params.uploader_email,
  });
};

export const getSubscriptionErrors = (params: {
  page: number;
  per_page: number;
  candidate_id: string | null;
  error_code: string | null;
}): Promise<GenericObject> => {
  return Client.get(`${SUBSCRIPTION_ERROR_PATH}`, { params });
};

export const getCsvExport = (params: {
  exportId: string;
}): Promise<GenericObject> => {
  return Client.get(`${CSV_EXPORT_PATH}/${params.exportId}`).catch(error => {
    if (error.response) {
      if (error.response.status === 404) {
        return {};
      }
    }
    throw error;
  });
};

export const getEnrollments = (params: {
  page: number;
  per_page: number;
}): Promise<GenericObject> => {
  return Client.get(WORKER_PATH, { params });
};
