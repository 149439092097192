import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Kind } from 'modules/assess/models/entity';
import { Type } from 'modules/assess/models/rulesets/ruleset';
import { StatusByline } from 'modules/assess/ui/common/entity/status/byline';

type Props = {
  ruleset: Type;
};

const DarkSpan = styled.span`
  color: ${colors.uiTextPrimaryLight};
`;

const LightSpan = styled.span`
  color: ${colors.uiGrey600};
`;

const VerticalBorder = styled.span`
  border-color: ${colors.uiGrey400};
  border-left: 1px solid;
  height: 2em;
  margin: 0 1.5em;
`;

const VersionInfo: React.FC<Props> = ({ ruleset }) => {
  const active =
    ruleset.active && typeof ruleset.active === 'object'
      ? ruleset.active
      : undefined;
  const draft =
    ruleset.draft && typeof ruleset.draft === 'object'
      ? ruleset.draft
      : undefined;

  const lastEditedVersion = draft || active;

  const style = {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '20px',
    paddingBottom: '0.3rem',
  };

  const basePath = 'assess-v2-ui-common-components-version-info';
  const publishedInfoId = `${basePath}-published-info`;
  const updatedInfoId = `${basePath}-updated-info`;

  return (
    <M.Container.Row data-testid='assess-v2-version-info' style={style}>
      <DarkSpan id={publishedInfoId} data-testid='assess-v2-published-info'>
        <StatusByline
          kind={Kind.final}
          time={active?.published_at && new Date(active.published_at)}
          user={active?.published_by}
        />
      </DarkSpan>
      <VerticalBorder />
      <LightSpan id={updatedInfoId} data-testid='assess-v2-updated-info'>
        <StatusByline
          kind={Kind.draft}
          time={
            lastEditedVersion?.updated_at &&
            new Date(lastEditedVersion.updated_at)
          }
          user={lastEditedVersion?.updated_by}
        />
      </LightSpan>
    </M.Container.Row>
  );
};

export default VersionInfo;
