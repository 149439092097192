import React from 'react';
import styled from 'styled-components';
import * as Images from '../../../assets/engagement';
import Subsections from '../subsections';

const StyledImage = styled.img`
  width: 340px;
  height: 239px;
`;

type Props = {
  subsection: Subsections;
};

const Image: React.FC<Props> = ({ subsection }) => {
  const image = getImage(subsection);
  return <StyledImage src={image} alt={image} />;
};

function getImage(subsection: string): any {
  switch (subsection) {
    case Subsections.candidate_stories:
      return Images.CandidateStoriesImage;
    case Subsections.explore:
      return Images.ExploreImage;
    default:
      return null;
  }
}

export default Image;
