import PropTypes from 'prop-types';
import styled from 'styled-components';

const JustifyValues = [
  'center',
  'space-around',
  'space-between',
  'flex-start',
  'flex-end',
  'space-evenly',
];

const AlignValues = ['stretch', 'center', 'baseline', 'flex-start', 'flex-end'];

const Flex = styled.div.attrs(({ testId }) => ({
  'data-testid': testId || 'flex-container',
}))`
  display: ${({ display }) => display};
  flex: ${({ auto }) => (auto ? '1 1 auto' : 'initial')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
`;

Flex.propTypes = {
  display: PropTypes.string,
  children: PropTypes.any.isRequired,
  column: PropTypes.bool,
  align: PropTypes.oneOf(AlignValues),
  justify: PropTypes.oneOf(JustifyValues),
  auto: PropTypes.bool,
};

Flex.defaultProps = {
  display: 'flex',
  align: AlignValues[0],
  auto: false,
  column: false,
  justify: JustifyValues[0],
};

export default Flex;
