import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  disabled: boolean;
  onBulkEdit: () => void;
};

const Container = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledButton = styled(M.Button)`
  height: 48px;
`;

const Actions: React.FC<Props> = ({ disabled, onBulkEdit }) => {
  const { t } = useTranslation('assess:v2');
  const text = t(`common.lookback_rules.dialog.button`);

  return (
    <Container>
      <StyledButton
        data-testid='assess-v2-ui-common-lookback-rules-dialog-actions-button'
        disabled={disabled}
        kind='secondary'
        size='sm'
        onClick={onBulkEdit}
      >
        {text}
      </StyledButton>
    </Container>
  );
};

export default Actions;
