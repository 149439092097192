import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { TaskType, UserType } from 'modules/case-management/types';

type PropsType = {
  assignee: UserType | null | undefined;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  selectedTasks: TaskType[];
  tasks: TaskType[];
};

const ConfirmModal = ({
  assignee,
  onClose = () => {},
  onConfirm = () => {},
  open,
  selectedTasks = [],
  tasks = [],
}: PropsType) => {
  const { t } = useTranslation();

  const onContinue = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  if (
    tasks.length === 0 ||
    selectedTasks.length === 0 ||
    assignee === undefined
  )
    return null;
  const assignedTasks = selectedTasks.filter(task => task.assignee);
  const assignedCount = assignedTasks.length;
  const unassignedCount = selectedTasks.length - assignedCount;

  const {
    title,
    header,
    assigned_to_msg,
    unassigned_msg,
    assigned_count,
    unassigned_count,
  }: any = t('case-management:tasks.table.confirm_modal', {
    returnObjects: true,
    assigned: assignedCount,
    unassigned: unassignedCount,
    user: assignee?.display_label,
  });

  return (
    <M.ComposedModal
      open={open}
      data-testid='case-management-task-reassign-confirm-modal'
    >
      <M.ModalHeader buttonOnClick={onClose}>{title}</M.ModalHeader>
      <M.ModalBody>
        <h3>{header}</h3>
        <p>{assignee ? assigned_to_msg : unassigned_msg}</p>
        <ul>
          <li>{assigned_count}</li>
          <li>{unassigned_count}</li>
        </ul>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='secondary' onClick={onClose}>
          {t('buttons.cancel')}
        </M.Button>{' '}
        <M.Button kind='primary' onClick={onContinue}>
          {t('buttons.continue')}
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ConfirmModal;
