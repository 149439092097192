import React from 'react';
import moment from 'moment';

type Props = { date: string; type: string };

const NoInformation: React.FC<Props> = ({ date, type }) => {
  const parsedDate = moment(date).format('ll');
  const id = 'credit-report-no-information';
  const testId = `${id}-${type.split(' ').join('-')}`;
  return (
    <p
      data-testid={testId}
    >{`As of ${parsedDate}, you have no ${type} on your credit report.`}</p>
  );
};

export default NoInformation;
