import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';

export const GrowthInsights = () => {
  const history = useHistory();
  const path = `../growth_insights`;
  const handleClick = useCallback(() => history.push(path), [history, path]);

  return (
    <Button
      data-testid='assess-ruleset-version-actions-growth-insights'
      kind='secondary'
      size='sm'
      trans='nouns.growth_insights'
      onClick={handleClick}
    />
  );
};

export default GrowthInsights;
