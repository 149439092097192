import React from 'react';
import { ApiStatusBanner, useIncidents } from 'components';

const ApiStatusBannerContainer = () => {
  const { incidents, isLoading, isError } = useIncidents();

  return !isLoading && !isError ? (
    <ApiStatusBanner incidents={incidents} />
  ) : null;
};

export default ApiStatusBannerContainer;
