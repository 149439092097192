import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  formatDateIfDate,
  CurrentUser as User,
} from '@dashboard-experience/utils';
import { GenericObject, Report } from 'types';
import AdditionalInfoPanel from './AdditionalInfoPanel';
import { MvrItem } from './MultiMotorVehicleReportComponents';

type SuspensionsProps = {
  licenseReportOrCharge: GenericObject;
  assessEnabled: boolean;
  currentUser: User;
  report: Report;
};

const Suspensions: React.FC<SuspensionsProps> = ({
  licenseReportOrCharge,
  assessEnabled,
  currentUser,
  report,
}) => {
  return (
    <M.SubScreening title='Suspensions' data-testid='mvr-suspensions'>
      {licenseReportOrCharge.suspensions.map(
        (suspension: GenericObject, idx: number) => {
          const { state, description, start_date, end_date } = suspension;

          const suspensionItems = [];
          if (state || licenseReportOrCharge.state)
            suspensionItems.push({
              itemKey: 'Source',
              itemValue: `${
                suspension.state || licenseReportOrCharge.state
              } DMV`,
            });
          if (description)
            suspensionItems.push({
              itemKey: 'Description',
              itemValue: underscoreToHuman(suspension.description),
            });
          if (start_date)
            suspensionItems.push({
              itemKey: 'Start Date',
              itemValue: formatDateIfDate(suspension.start_date),
            });
          if (end_date)
            suspensionItems.push({
              itemKey: 'End Date',
              itemValue: formatDateIfDate(suspension.end_date),
            });
          if (state)
            suspensionItems.push({
              itemKey: 'State',
              itemValue: suspension.state,
            });

          return (
            <M.Expandable
              role='suspensionsItem'
              key={`${suspension.description}-${idx + 1}`}
              title={
                <>
                  <MvrItem item={suspension} />
                  <span style={{ float: 'right' }}>
                    {formatDateIfDate(suspension.start_date)}
                  </span>
                </>
              }
            >
              <M.KeyValueList items={suspensionItems} />
              {suspension.group && (
                <AdditionalInfoPanel
                  licenseReportOrCharge={suspension}
                  // @ts-ignore Remove comment once the Report type is consumed from Utils
                  report={report}
                  assessEnabled={assessEnabled}
                  currentUser={currentUser}
                  panelType='suspensions'
                />
              )}
            </M.Expandable>
          );
        },
      )}{' '}
    </M.SubScreening>
  );
};

export default Suspensions;
