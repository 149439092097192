import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const EditRoleCellContent = styled.div`
  display: flex;
  align-items: flex-start;

  .roles {
    padding-right: 0.5rem;
  }

  .cds--list-box__wrapper--inline {
    display: inline-flex;
    align-items: flex-start;
  }

  .loading {
    width: auto;
    min-height: auto;

    .cds--inline-loading__animation {
      height: auto;
    }
  }
`;

export const UsersTableCell = styled(M.TableCell)`
  line-height: 40px;
`;

export const TableContainer = styled.div`
  .mastodon-table-container {
    .mastodon-tooltip-definition {
      margin-left: 0.5rem;
      top: 0.25rem;

      svg {
        fill: ${colors.uiNavy600} !important;
      }
    }

    .last_seen {
      width: 12rem;
    }
  }
`;

export const StyledContainer = styled(M.Grid)`
  .mastodon-grid-col {
    padding: 0;

    @media (min-width: 42rem) {
      &:first-of-type {
        padding-right: 2rem;
      }
    }

    @media (min-width: 66rem) {
      padding-right: 2rem;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .mastodon-text-input {
    margin-bottom: 0 !important;
  }
`;

export const ToolBarContainer = styled.div`
  margin-bottom: 1.5rem;

  .mastodon-grid {
    margin: 0;
    width: 100%;

    .mastodon-grid-row {
      align-items: end;
    }
  }

  .cds--table-toolbar {
    margin-top: 1.5rem;
    min-height: unset;

    .cds--toolbar-content {
      height: auto;
    }

    .cds--toolbar-search-container-persistent {
      height: 2.5rem;

      .cds--search-input {
        border-bottom: 0.063rem solid ${colors.uiGrey500};
        height: 2.5rem;
        outline: none;

        &:hover {
          background-color: ${colors.uiGrey100};
        }

        &:focus {
          box-shadow: 0 0.25rem 0.5rem rgba(22, 22, 29, 0.1);
          border-bottom: 0.063rem solid ${colors.uiNavy600};
          outline: none;
        }

        &:not(:placeholder-shown),
        &:active {
          background: unset;
        }
      }

      .cds--search-close {
        height: 2.5rem;
      }
    }
  }

  .mastodon-multi-select-wrapper {
    margin-top: 1.5rem;
  }
`;

export const RolesHeader = () => {
  const helpURL =
    'https://help.checkr.com/hc/en-us/articles/216688038-How-do-I-manage-user-permissions';

  return (
    <>
      <span>Roles</span>
      <M.TooltipDefinition
        highlighted={false}
        align='top'
        definition='Click to learn more'
      >
        <M.Link target='_blank' rel='noopener noreferrer' href={helpURL}>
          <M.Icon icon='InformationFilled' />
        </M.Link>
      </M.TooltipDefinition>
    </>
  );
};
