import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const ContentPanel = styled.div`
  border-radius: 4px;
  border: 1px solid var(--grey-200, #e1e6eb);
  background: var(--grey-100, #f2f5f7);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  animation: showBanner 300ms ease-in-out;

  @keyframes showBanner {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const NewEmployeeBannerBoldText = styled.p`
  color: var(--text-icon-black-secondary-78, rgba(26, 32, 38, 0.78));
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0px !important;
`;

const NewEmployeeBannerText = styled.p`
  color: var(--text-icon-black-secondary-78, rgba(26, 32, 38, 0.78));
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TextContainer = styled.div`
  display: flex;
`;

type NewEmployeeBannerProps = {
  count: number;
  isLoading: boolean;
  openModal?: () => void;
};

const NewEmployeeBanner: React.FC<NewEmployeeBannerProps> = ({
  count,
  isLoading,
  openModal = () => {},
}) => {
  const showBanner = count === 0 && !isLoading;
  return showBanner ? (
    <ContentPanel>
      <TextContainer>
        <NewEmployeeBannerBoldText>No match found.</NewEmployeeBannerBoldText>
        <NewEmployeeBannerText>
          &nbsp;This employee currently doesn’t exist in Checkr. Do you want to
          add a new employee?
        </NewEmployeeBannerText>
      </TextContainer>
      <M.Button kind='secondary' onClick={openModal}>
        Add new employees
      </M.Button>
    </ContentPanel>
  ) : null;
};

export default NewEmployeeBanner;
