/* eslint-disable react/jsx-no-bind */
import React from 'react';

import { StyledAccordion, AccordionItem } from './styles';
import AccordionTitle from './AccordionTitle';

type Props = {
  primaryText: string;
  secondaryText?: string;
  status: 'warning' | 'complete' | 'incomplete' | 'not-started';
  children: React.ReactNode;
  open?: boolean;
  disabled?: boolean;
  onHeadingClick?: () => void;
};

const Accordion = ({
  children,
  primaryText: title,
  secondaryText,
  status,
  open,
  disabled,
  onHeadingClick,
}: Props) => {
  return (
    <StyledAccordion disabled={disabled}>
      <AccordionItem
        title={
          <AccordionTitle
            primaryText={title}
            secondaryText={secondaryText}
            status={status}
          />
        }
        open={open}
        onHeadingClick={onHeadingClick}
        data-testid='accordion-item'
      >
        {children}
      </AccordionItem>
    </StyledAccordion>
  );
};

export default Accordion;
