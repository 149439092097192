import { zip as _zip } from 'lodash';
import { Decision } from 'modules/assess';
import Operator from 'modules/assess/models/operator';
import * as Entity from 'modules/assess/models/rules/premade';
import { Fact } from './fact';
import RecordType from './record';

export enum Category {
  COUNT_AND_RECENCY_OF_VIOLATIONS = 'count_and_recency_of_violations',
  LOW_SEVERITY_MOVING_VIOLATIONS = 'low_severity_moving_violations',
  NON_MOVING_VIOLATIONS = 'non_moving_violations',
  NONE = 'none',
}

export const CATEGORIES: Array<Category> = [
  Category.NON_MOVING_VIOLATIONS,
  Category.COUNT_AND_RECENCY_OF_VIOLATIONS,
  Category.LOW_SEVERITY_MOVING_VIOLATIONS,
  Category.NONE,
];

export enum Code {
  ACCEPT_ALL_ACCIDENTS = 'ACCEPT_ALL_ACCIDENTS',
  ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS = 'ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS',
  ACCEPT_EXPIRED_CDL_VIOLATIONS = 'ACCEPT_EXPIRED_CDL_VIOLATIONS',
  ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS = 'ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS',
  ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS = 'ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS',
  ACCEPT_FAILURE_TO_SHOW_VIOLATIONS = 'ACCEPT_FAILURE_TO_SHOW_VIOLATIONS',
  ACCEPT_ITEMS_OLDER_THAN_3_YEARS = 'ACCEPT_ITEMS_OLDER_THAN_3_YEARS',
  ACCEPT_LITTERING_NON_MOVING_VIOLATIONS = 'ACCEPT_LITTERING_NON_MOVING_VIOLATIONS',
  ACCEPT_PARKING_NON_MOVING_VIOLATIONS = 'ACCEPT_PARKING_NON_MOVING_VIOLATIONS',
  ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS = 'ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS',
  ACCEPT_UNDER_AGE_25_VIOLATIONS = 'ACCEPT_UNDER_AGE_25_VIOLATIONS',
}

export const CODE_CATEGORY: Record<Code, Category> = {
  [Code.ACCEPT_ALL_ACCIDENTS]: Category.COUNT_AND_RECENCY_OF_VIOLATIONS,
  [Code.ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS]: Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_EXPIRED_CDL_VIOLATIONS]: Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS]:
    Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS]:
    Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_FAILURE_TO_SHOW_VIOLATIONS]: Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_ITEMS_OLDER_THAN_3_YEARS]:
    Category.COUNT_AND_RECENCY_OF_VIOLATIONS,
  [Code.ACCEPT_LITTERING_NON_MOVING_VIOLATIONS]: Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_PARKING_NON_MOVING_VIOLATIONS]: Category.NON_MOVING_VIOLATIONS,
  [Code.ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS]:
    Category.LOW_SEVERITY_MOVING_VIOLATIONS,
  [Code.ACCEPT_UNDER_AGE_25_VIOLATIONS]:
    Category.COUNT_AND_RECENCY_OF_VIOLATIONS,
};

export const CODES = Object.values(Code);

export const ITEMS: Entity.Map = {
  'premade/ACCEPT_PARKING_NON_MOVING_VIOLATIONS/1': {
    id: 'premade/ACCEPT_PARKING_NON_MOVING_VIOLATIONS/1',
    code: Code.ACCEPT_PARKING_NON_MOVING_VIOLATIONS,
    name: 'Parking non-moving violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 1,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: ['VF300', 'VF310', 'VF320', 'VF330', 'VF340', 'VF350'],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS/1': {
    id: 'premade/ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS/1',
    code: Code.ACCEPT_EQUIPMENT_NON_MOVING_VIOLATIONS,
    name: 'Equipment non-moving violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 2,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: [
          'VF610',
          'VF620',
          'VF630',
          'VF650',
          'VF660',
          'VE300',
          'VE310',
          'VE320',
          'VE330',
          'VE340',
          'VE350',
          'VE360',
          'VE370',
        ],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_LITTERING_NON_MOVING_VIOLATIONS/1': {
    id: 'premade/ACCEPT_LITTERING_NON_MOVING_VIOLATIONS/1',
    code: Code.ACCEPT_LITTERING_NON_MOVING_VIOLATIONS,
    name: 'Littering non-moving violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 3,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: ['VD650', 'VD660', 'VD670'],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS/1': {
    id: 'premade/ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS/1',
    code: Code.ACCEPT_FAILURE_TO_FILE_NON_MOVING_VIOLATIONS,
    name: '“Failed to file” non-moving violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 4,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: ['VB620', 'VB600', 'VB650'],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS/1': {
    id: 'premade/ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS/1',
    code: Code.ACCEPT_FAILURE_TO_APPEAR_OR_MAKE_PAYMENTS_VIOLATIONS,
    name: '“Failure to appear” or “make required payments” violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 5,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: [
          'VD400',
          'VD410',
          'VD420',
          'VD430',
          'VD500',
          'VD510',
          'VD520',
          'VD530',
          'VD540',
          'VD550',
        ],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_FAILURE_TO_SHOW_VIOLATIONS/1': {
    id: 'premade/ACCEPT_FAILURE_TO_SHOW_VIOLATIONS/1',
    code: Code.ACCEPT_FAILURE_TO_SHOW_VIOLATIONS,
    name: '“Failure to show” violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 6,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: [
          'VB700',
          'VB710',
          'VB720',
          'VB730',
          'VB740',
          'VB750',
          'VB760',
          'VB770',
          'VB780',
        ],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_EXPIRED_CDL_VIOLATIONS/1': {
    id: 'premade/ACCEPT_EXPIRED_CDL_VIOLATIONS/1',
    code: Code.ACCEPT_EXPIRED_CDL_VIOLATIONS,
    name: 'Expired commercial driver license violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 7,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: ['VB510'],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_UNDER_AGE_25_VIOLATIONS/1': {
    id: 'premade/ACCEPT_UNDER_AGE_25_VIOLATIONS/1',
    code: Code.ACCEPT_UNDER_AGE_25_VIOLATIONS,
    name: `Violations when candidate's age was under 25 if the candidate is now over 28 are eligible (excluding DUI or fatalities)`,
    decision: Decision.ELIGIBLE,
    position: 8,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.AGE_AT_ISSUED_DATE,
        operator: Operator.LT,
        value: 25,
      },
      {
        fact: Fact.AGE_NOW,
        operator: Operator.GT,
        value: 28,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: [
          'VU060',
          'VU070',
          'VU080',
          'VU090',
          'VU100',
          'VA080',
          'VA100',
          'VA110',
          'VA120',
          'VA200',
          'VA210',
          'VA220',
          'VA230',
          'VA240',
          'VB010',
          'VB020',
          'VB030',
          'VB040',
        ],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_ITEMS_OLDER_THAN_3_YEARS/1': {
    id: 'premade/ACCEPT_ITEMS_OLDER_THAN_3_YEARS/1',
    code: Code.ACCEPT_ITEMS_OLDER_THAN_3_YEARS,
    name: 'All incidents older than 3 years are eligible',
    decision: Decision.ELIGIBLE,
    position: 9,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.IN,
        value: [
          RecordType.VIOLATION,
          RecordType.ACCIDENT,
          RecordType.SUSPENSION,
        ],
      },
      {
        fact: Fact.RELATIVE_CONTEXT_DATE_IN_DAYS,
        operator: Operator.GT,
        value: 1095,
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS/1': {
    id: 'premade/ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS/1',
    code: Code.ACCEPT_SPEEDING_LESS_THAN_25_OVER_VIOLATIONS,
    name: 'Speeding less than 25 mph over the speed limit violations are eligible',
    decision: Decision.ELIGIBLE,
    position: 10,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.VIOLATION,
      },
      {
        fact: Fact.CLASSIFICATION_CODE,
        operator: Operator.IN,
        value: ['VS010', 'VS060', 'VS110', 'VS160', 'VS210'],
      },
    ],
    version: 1,
    description: [],
  },
  'premade/ACCEPT_ALL_ACCIDENTS/1': {
    id: 'premade/ACCEPT_ALL_ACCIDENTS/1',
    code: Code.ACCEPT_ALL_ACCIDENTS,
    name: 'All accidents are eligible',
    decision: Decision.ELIGIBLE,
    position: 11,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.ACCIDENT,
      },
    ],
    version: 1,
    description: [],
  },
};

export const LATEST = buildLatest();

export function build(items?: Entity.List | Entity.Map) {
  const itemsSorted = build.sort(items || []);
  const latestSorted = build.sort(LATEST);
  const zipped = _zip(CODES, itemsSorted, latestSorted);

  return zipped
    .map(([key, current, latest]) => ({
      id: (current?.id || latest?.id) as string,
      key: key as string,
      disabled: !current && latest?.deprecated,
      rule: current || latest || missing(),
      position: latest?.position ?? Number.POSITIVE_INFINITY,
      isSelected: !!current,
      updated: current?.version === latest?.version,
    }))
    .filter(r => !r.rule.deprecated)
    .sort((a, b) => a.position - b.position);
}

build.sort = function sort(
  items: Entity.List | Entity.Map,
): Array<Entity.Type | undefined> {
  const list = Object.values(items);
  return CODES.map(code => list.find(item => item.code === code));
};

function buildLatest(): Entity.Map {
  return Object.values(ITEMS).reduce<Entity.Map>((items, item) => {
    const current = items[item.code];

    if (!current || item.version > current.version) {
      items[item.code] = item;
    }

    return items;
  }, {});
}

export function category(code: string) {
  return CODE_CATEGORY[code as Code] || Category.NONE;
}

export function missing() {
  const partial = Entity.initialize();
  return { ...partial, id: 'missing', key: 'missing' };
}
