import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { M } from '@dashboard-experience/mastodon';
import { DownloadLink } from './InvoicesStyledComponents';
import { useDownloadTaxDocument } from '../../../api/billing';

type Props = {
  header: any;
  row: any;
  cell: any;
};

const handleDownloadTaxDocument = (call: any, e: any) => {
  e.preventDefault();
  const { id } = e.target.dataset;
  call(id);
};

const InvoicesTaxDocCell: React.FC<Props> = ({ header, row, cell }) => {
  let cellValue;
  const { call, result } = useDownloadTaxDocument();
  const downloadClick = useCallback(
    e => handleDownloadTaxDocument(call, e),
    [call],
  );

  useEffect((): void => {
    if (result.isSuccess) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = result.data.url;
      a.click();
    }
  }, [result.isSuccess]);

  switch (header) {
    case 'document_name':
      cellValue = (
        <M.TableCell key={cell.id}>
          <DownloadLink
            href='#'
            download
            key={`${row.id}-taxdoc`}
            onClick={downloadClick}
            data-id={row.id}
          >
            {cell.value}
          </DownloadLink>
        </M.TableCell>
      );
      break;
    case 'last_modified':
      cellValue = (
        <M.TableCell key={cell.id}>
          {moment(cell.value).format('YYYY-MM-DD')}
        </M.TableCell>
      );
      break;
    default:
      cellValue = <M.TableCell key={cell.id}>{cell.value}</M.TableCell>;
  }
  return cellValue;
};

export default InvoicesTaxDocCell;
