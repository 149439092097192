import { AnyQueryKey, useMutation, useQuery, queryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  Brand,
  BrandResponse,
  fetchBrandObject,
  postBrand,
  updateBrand,
  fetchBrandsData,
  deleteBrand,
} from './actions';
import { toastError, toastSuccess } from '../../actions';

export const useFetchBrandObject = (brandId: string) => {
  const request = () => fetchBrandObject(brandId);
  const key: AnyQueryKey = ['brandObject', { brandId }];

  const { data, isLoading, isSuccess } = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isSuccess };
};

export const useUpdateBrand = (page: number = 1) => {
  const dispatch = useDispatch();
  const request = (brand: BrandResponse) => updateBrand(brand);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries([`brandsData_${page}`]);
      dispatch(toastSuccess('Brand was successfully updated!'));
    },
    onError: () => {
      dispatch(toastError('Something went wrong, please try again later.'));
    },
  });

  return { call, result };
};

export const useFetchBrandsData = (page: number = 1) => {
  const key: AnyQueryKey = [`brandsData_${page}`];
  const request = () => fetchBrandsData(page);

  const { data, isLoading } = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export const useSaveBrand = (page: number) => {
  const dispatch = useDispatch();
  const request = (payload: Brand) => postBrand(payload);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries([`brandsData_${page}`]);
      dispatch(toastSuccess('Brand was saved successfully!'));
    },
    onError: () => {
      dispatch(toastError('Something went wrong, please try again later.'));
    },
  });

  return {
    call,
    result,
  };
};

export const useDeleteBrand = (page: number) => {
  const dispatch = useDispatch();
  const request = (brandId: string) => deleteBrand(brandId);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries([`brandsData_${page}`]);
      dispatch(toastSuccess('Brand was deleted successfully!'));
    },
    onError: () => {
      dispatch(toastError('Something went wrong, please try again later.'));
    },
  });

  return {
    call,
    result,
  };
};
