import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { updateParentWindowUrl } from 'utils';
import { ButtonAsLink } from './AdverseActionsStyledComponents';
import UIContext from '../../context/UI';

type Props = {};

const NoEmailMessage: React.FC<Props> = () => {
  const { contextId } = useContext(UIContext);
  const history = useHistory();

  const postMessageAndClose = useCallback(() => {
    const path = '/account/settings';

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  return (
    <div>
      Please provide the email you want to use for adverse actions in your{' '}
      <ButtonAsLink onClick={postMessageAndClose}>
        account settings
      </ButtonAsLink>
    </div>
  );
};

export default NoEmailMessage;
