import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { accountHasPermission } from '@dashboard-experience/utils';
import Provider from 'components/Invitations/Provider';
import NewCandidateInvitation from 'components/Candidate/NewCandidateInvitation';
import { useCandidate } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';

export const NewInvitationContainer = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const account = candidate.account || currentUser.account;

  // TODO - Should this be using the candidate's account info to determine permissions? (Migrated 1:1)
  if (!accountHasPermission(currentUser, 'allow_dashboard_report_ordering'))
    return null;

  return account?.id ? (
    <Provider account={account}>
      <NewCandidateInvitation />
    </Provider>
  ) : (
    <M.LoadingInline />
  );
};

export default NewInvitationContainer;
