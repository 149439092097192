/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React from 'react';
import { useUser } from 'context/CurrentUser';

import styled from 'styled-components';

import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';

import { TaxDocs, useGetTaxDocuments } from 'api/billing';
import { useTranslation } from 'react-i18next';
import { InvoicesTaxDocCell } from '../../components/Account/Invoices';

const StyledFormHeading = styled.h3`
  margin: 1rem;
`;

export const StyledSubHeading = styled.h4`
  margin: 1rem;
`;

const taxDocHeaders = [
  {
    key: 'document_name',
    header: 'documentName',
    name: 'documentName',
  },
  {
    key: 'last_modified',
    header: 'lastUpdated',
    name: 'lastUpdated',
  },
];

type DocumentsTableContainerProps = {
  headers: Array<{ name: string }>;
  rows: Array<{ cells: any }>;
  taxDocs: TaxDocs;
};

const DocumentsTableContainer: React.FC<DocumentsTableContainerProps> = ({
  rows,
  headers,
  taxDocs,
}) => {
  const { t } = useTranslation();

  return (
    <M.TableContainer>
      <M.Table>
        <M.TableHead className='border-top'>
          <M.TableRow>
            {headers.map(header => {
              return (
                <M.TableHeader key={header.name}>
                  {t(`headings.${header.name}`)}
                </M.TableHeader>
              );
            })}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {rows.map((rowMetaData, i) => {
            // rows has the bound data, and taxDocData has the raw source data.  We need both
            const row = taxDocs[i];
            return (
              <M.TableRow key={row.id}>
                {rowMetaData.cells.map(
                  (cell: { info: { header: any }; id: string }) => {
                    const cellProps = {
                      header: cell.info.header,
                      row,
                      cell,
                    };
                    return <InvoicesTaxDocCell key={cell.id} {...cellProps} />;
                  },
                )}
              </M.TableRow>
            );
          })}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

const DocumentsContainer: React.FC = () => {
  const { t } = useTranslation();
  const { account = {} } = useUser();

  const { data: taxDocs = [], isLoading: apiLoading } = useGetTaxDocuments();

  const isLoading = Boolean(!account.id);

  if (isLoading || apiLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <StyledFormHeading>{t('headings.taxDocuments')}</StyledFormHeading>
      <StyledSubHeading>Tax documents</StyledSubHeading>
      <M.DataTable rows={taxDocs} headers={taxDocHeaders}>
        {({ rows, headers }: any) => (
          <DocumentsTableContainer
            headers={headers}
            rows={rows}
            taxDocs={taxDocs}
          />
        )}
      </M.DataTable>
    </div>
  );
};

export default DocumentsContainer;
