import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Premium } from 'modules/assess/v2/ui/common';

type Props = {
  text: string;
};

const StyledContainer = styled.div`
  background: ${colors.uiGrey0};
  border: 1px solid ${colors.brandSlate3};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  left: 31px;
  top: 81px;
  width: 322px;
`;

const PremiumContainer = styled.div`
  margin-top: 14px !important;
`;

export const Option: React.FC<Props> = ({ text }) => {
  const basePath =
    'assess-v2-ui-guideline-review-and-apply-assign-guidelines-upgrade-option';
  const checkboxContainerId = `${basePath}-checkbox`;
  const premiumContainerId = `${basePath}-premium-container`;
  return (
    <StyledContainer>
      <M.Checkbox disabled labelText={text} data-testid={checkboxContainerId} />
      <PremiumContainer data-testid={premiumContainerId}>
        <Premium />
      </PremiumContainer>
    </StyledContainer>
  );
};

export default Option;
