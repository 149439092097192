import React, { useReducer, ReactNode, useMemo } from 'react';
import { useContextSelector, createContext } from 'use-context-selector';
import CustomProvider from 'state/provider';
import {
  Segment,
  GET_ASSIGNED_NODES,
  SEARCH_HIERARCHY,
  POST_SEGMENTS_SUCCESS,
  SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
  SHOW_ASSIGN_SEGMENTS_SUCCESS,
  CANCEL_ASSIGN_SEGMENTS_CONFIRMATION,
} from '../../api/segments';

export type Action =
  | {
      type: typeof GET_ASSIGNED_NODES;
      payload: {
        segments: Segment[];
        initialSelectedSegments: string[];
        filteredSegments: Segment[];
      };
    }
  | {
      type: typeof SEARCH_HIERARCHY;
      payload: {
        filteredSegments: Segment[];
        search: Search;
      };
    }
  | {
      type: typeof POST_SEGMENTS_SUCCESS;
    }
  | {
      type: typeof SHOW_ASSIGN_SEGMENTS_CONFIRMATION;
      payload: { showAssignSegmentsConfirmationView: boolean };
    }
  | {
      type: typeof SHOW_ASSIGN_SEGMENTS_SUCCESS;
      payload: { showAssignSegmentsSuccessView: boolean };
    }
  | {
      type: typeof CANCEL_ASSIGN_SEGMENTS_CONFIRMATION;
    };

export type Dispatch = (action: Action) => void;

export type Search = {
  query: string | null;
  pageCount: number;
  pageIndex: number;
  isSearching: boolean;
};

export type State = {
  [key: string]: any;
  segments: Segment[];
  filteredSegments: Segment[];
  initialSelectedSegments: string[];
  search: Search;
  showAssignSegmentsConfirmationView: boolean;
  showAssignSegmentsSuccessView: boolean;
};

export type SegmentsProviderProps = {
  children: ReactNode;
};

const initialState: State = {
  segments: [],
  filteredSegments: [],
  initialSelectedSegments: [],
  search: {
    query: null,
    pageCount: 1,
    pageIndex: 0,
    isSearching: false,
  },
  showAssignSegmentsConfirmationView: false,
  showAssignSegmentsSuccessView: false,
};

export const SegmentsContext = createContext<{
  state: State;
  dispatch: Dispatch;
}>({
  state: initialState,
  dispatch: () => {},
});

const segmentsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case GET_ASSIGNED_NODES: {
      return {
        ...state,
        segments: action.payload.segments,
        initialSelectedSegments: action.payload.initialSelectedSegments,
        filteredSegments: action.payload.filteredSegments,
      };
    }
    case SEARCH_HIERARCHY: {
      return {
        ...state,
        filteredSegments: action.payload.filteredSegments,
        search: {
          ...state.search,
          pageCount: action.payload.search.pageCount,
          pageIndex: action.payload.search.pageIndex,
          query: action.payload.search.query,
          isSearching: action.payload.search.isSearching,
        },
      };
    }
    case POST_SEGMENTS_SUCCESS: {
      return {
        ...state,
        showAssignSegmentsConfirmationView: false,
        showAssignSegmentsSuccessView: true,
      };
    }
    case SHOW_ASSIGN_SEGMENTS_CONFIRMATION: {
      return {
        ...state,
        showAssignSegmentsConfirmationView:
          action.payload.showAssignSegmentsConfirmationView,
      };
    }
    case SHOW_ASSIGN_SEGMENTS_SUCCESS: {
      return {
        ...state,
        showAssignSegmentsSuccessView:
          action.payload.showAssignSegmentsSuccessView,
      };
    }
    case CANCEL_ASSIGN_SEGMENTS_CONFIRMATION: {
      return {
        ...state,
        showAssignSegmentsConfirmationView: false,
        search: {
          ...state.search,
          query: null,
          pageCount: 1,
          pageIndex: 0,
          isSearching: false,
        },
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

const SegmentsProvider = ({ children }: SegmentsProviderProps) => {
  const [state, dispatch] = useReducer(segmentsReducer, {
    ...initialState,
  });

  const props = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <CustomProvider context={SegmentsContext} props={props}>
      {children}
    </CustomProvider>
  );
};

const useSegmentsContext = () => {
  const context = useContextSelector(SegmentsContext, ({ state }) => {
    return state;
  });

  if (context === undefined) {
    throw new Error('useSegments must be used within SegmentsProvider');
  }
  return context;
};

const useDispatch = () =>
  useContextSelector(SegmentsContext, ({ dispatch }) => dispatch);

export { SegmentsProvider, useSegmentsContext, useDispatch };
