import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useNamespacedRoute } from '../../router/context';
import { useCreate, usePath } from '../api';
import { Dialog } from '../dialog';

type Props = {
  open: boolean;
  ruleset?: Ruleset.Type;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ open, ruleset, onClose }) => {
  const base = useNamespacedRoute();
  const { t } = useTranslation('');
  const history = useHistory();
  const create = useCreate();
  const initialName = ruleset ? `${ruleset.name} ${t('nouns.copy')}` : '';
  const [name, setName] = useState(initialName);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const path = usePath(create.result.data?.id, 'draft');

  const handleOnClose = useCallback(() => {
    setName(initialName);
    onClose?.();
  }, [initialName, onClose]);

  const handleCreate = useCallback(() => {
    create.call({ name, source: ruleset?.id });
  }, [create, name, ruleset]);

  useEffect(() => {
    setSubmitDisabled(name === '');
  }, [name]);

  useEffect(() => {
    switch (create.result.status) {
      case 'idle':
        return;
      case 'loading':
        setSubmitDisabled(true);
        return;
      case 'error':
        setSubmitDisabled(false);
        return;
    }

    handleOnClose();

    if (path) {
      history.push(path);
    }
  }, [base, create.result.status, handleOnClose, history, path]);

  return (
    <Dialog
      disabled={submitDisabled}
      initialName={initialName}
      open={open}
      setName={setName}
      status={create.result.status}
      onClose={handleOnClose}
      onSubmit={handleCreate}
    />
  );
};

export default Container;
