import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { StyledComponents } from 'modules/assess/v2/ui/common';

const StyledText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.brandNavy3};
`;

export const NoRulesSelected: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const instructionText = t(`standard.guidelines.quickstart.added.empty`);

  return (
    <StyledComponents.SlateContainer>
      <StyledText data-testid='assess-v2-ui-guideline-quickstart-added-guidelines-no-rules-selected'>
        {instructionText}
      </StyledText>
    </StyledComponents.SlateContainer>
  );
};

export default NoRulesSelected;
