import React from 'react';
import { capitalize } from 'lodash';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { KeyValueProps } from 'types';
import styled from 'styled-components';
import { namespace } from '../../../../../../../locales';

const StyledContainer = styled.div`
  .text-container {
    display: flex;
    width: 40%;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 1rem;
  }
`;

const StyledTile = styled(M.Tile)`
  background-color: ${colors.uiNavy50};
  padding: 1rem;
`;

type ReportDetails = {
  assessment: null | string | undefined;
  geo: null | string | undefined;
  tags: null | string | undefined;
  workLocations: null | string[] | undefined;
};

type CandidateLocation = {
  zipCode: null | string | undefined;
  location: null | string;
};

type CandidateDetailsProps = {
  reportDetails: ReportDetails;
  candidateLocation: CandidateLocation;
};

const buildDetailsList = (
  reportDetails: ReportDetails,
  candidateLocation: CandidateLocation,
  t: TFunction,
) => {
  const list = [] as KeyValueProps[];

  if (reportDetails.assessment)
    list.push({
      itemKey: t(
        `${namespace}:report.actions.pre_adverse.candidate_details.fields.assessment`,
      ),
      itemValue: capitalize(reportDetails.assessment),
    });

  list.push({
    itemKey: t(
      `${namespace}:report.actions.pre_adverse.candidate_details.fields.tags`,
    ),
    itemValue: reportDetails.tags,
  });

  list.push({
    itemKey: t(
      `${namespace}:report.actions.pre_adverse.candidate_details.fields.location`,
    ),
    itemValue: `ZIP code ${candidateLocation.zipCode} - ${candidateLocation.location}`,
  });

  if (reportDetails.workLocations)
    list.push({
      itemKey: t(
        `${namespace}:report.actions.pre_adverse.candidate_details.fields.work_locations`,
      ),
      itemValue:
        reportDetails.workLocations.length > 0
          ? reportDetails.workLocations.join(', ')
          : '-',
    });

  if (reportDetails.geo)
    list.push({
      itemKey: t(
        `${namespace}:report.actions.pre_adverse.candidate_details.fields.geo`,
      ),
      itemValue: reportDetails.geo,
    });

  return list;
};

const CandidateDetails: React.FC<CandidateDetailsProps> = ({
  reportDetails,
  candidateLocation,
}) => {
  const { t } = useTranslation();
  const title = t(
    `${namespace}:report.actions.pre_adverse.candidate_details.title`,
  );

  const detailsList = buildDetailsList(reportDetails, candidateLocation, t);

  return (
    <StyledContainer>
      <StyledTile className='flex-container'>
        <div className='text-container'>
          <strong>{title}</strong>
        </div>
        <M.KeyValueList items={detailsList} />
      </StyledTile>
    </StyledContainer>
  );
};

export default CandidateDetails;
