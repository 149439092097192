/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { usePackageSubtypes } from 'api/packages';
import {
  hasPermission,
  accountHasPermission,
  accountHasPermissions,
} from '@dashboard-experience/utils';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
// import { DRUG_SCREENING, ORDER_ADDONS_FLOW } from 'Flags'; // TODO: reuse with EVER-6384
import { ORDER_ADDONS_FLOW } from 'Flags';
import { useLocation } from 'react-router-dom';
import { isCandidateReportURL } from 'components/Packages/AddChecks/helper';
import { getDollarAmount } from '../../shared/utils';
import MotorVehicleRecordsCheckItem from './MotorVehicleRecordsCheckItem';
import CriminalRecordsSearchesItem from './CriminalRecordsSearchesItem';
import VerificationsItem from './VerificationsItem';
import DrugAndHealthScreeningsItem, {
  determineDrugScreenTypes,
  DrugPanel,
} from './DrugAndHealthScreeningsItem';
import CivilRecordsSearchesItem from './CivilRecordsSearchesItem';
import HealthcareSanctionsFACISItem from './HealthcareSanctionsFACISItem';
import CreditReportItem from './CreditReportItem';

const Accordion = styled(M.Accordion)`
  #mastodon && {
    .cds--accordion__item--active > .cds--accordion__heading {
      border-bottom: 1px solid ${colors.uiGrey200} !important;

      .cds--accordion__arrow {
        transform: rotate(-90deg) scale(1.5) !important;
      }
    }

    .cds--accordion__wrapper {
      padding: 0px;
    }

    .cds--accordion__content {
      padding: 0px 0px 0px 48px !important;
    }

    .cds--accordion__item:last-child {
      margin-bottom: 0px;
    }
  }
`;

const ScreeningList = styled.ul``;

export const showDropdown = (
  usingAddChecks: boolean,
  canOrderAddon: boolean,
  canEnrollForAddon: boolean,
): boolean => {
  const result = usingAddChecks
    ? canOrderAddon
    : canOrderAddon || canEnrollForAddon;

  return result;
};

const DomesticAddScreeningsList: React.FC<any> = ({
  onAddClick,
  onRemoveClick,
  disabledScreenings,
  previouslyOrderedScreenings,
  screeningTypesInSearch,
  searchString,
  addedScreenings,
  additionalProperties,
  basePackage,
  itemPrices = [],
  screeningSettingPrice = [],
}) => {
  const actionProps = {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
    previouslyOrderedScreenings,
    basePackageScreenings: basePackage.screenings,
  };

  const currentUser = useUser();

  const {
    account: { id },
  } = currentUser;

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname, [location]);
  const onCandidateReportPage = isCandidateReportURL(currentPage);

  // Feature Flags
  // const drugScreeningsEnrollmentFlagEnabled =
  //   useFlag(DRUG_SCREENING)?.variantKey === 'enabled'; // TODO: reuse with EVER-6384

  const orderAddonsFlowEnabled =
    useFlag(ORDER_ADDONS_FLOW)?.variantKey === 'enabled';

  const { data } = usePackageSubtypes(id);

  const occupationalHealthServices: any[] = [];

  data?.occupational_health_screening?.map((screening: any) =>
    occupationalHealthServices.push(...screening.services),
  );

  // Can user the self-serve enrollment feature permission vars
  const canEnrollForAddonCredit = hasPermission(
    currentUser,
    'manage_account_settings',
  );
  const canEnrollForAddonDrug = hasPermission(
    currentUser,
    'manage_account_settings',
  );
  const canEnrollForAddonOccHealth = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  // Can order with addon permission vars
  const canOrderAddonCredit = accountHasPermissions(currentUser, [
    'credit_report_setting',
    'credit_report_ready',
  ]);
  const canOrderAddonDrug = accountHasPermission(
    currentUser,
    'drug_screening_enabled',
  );
  const canOrderAddonOccHealth =
    currentUser?.account.occ_health_screening_setup === 'enabled' &&
    occupationalHealthServices.length > 0;

  // Should render the control
  const usingAddChecks = onCandidateReportPage && orderAddonsFlowEnabled;
  const shouldRenderCreditDropdown = showDropdown(
    usingAddChecks,
    canOrderAddonCredit,
    canEnrollForAddonCredit,
  );

  const shouldRenderDrugScreenDropdown = showDropdown(
    usingAddChecks,
    canOrderAddonDrug,
    canEnrollForAddonDrug,
  );
  const shouldRenderOccHealthScreenDropdown = showDropdown(
    usingAddChecks,
    canOrderAddonOccHealth,
    canEnrollForAddonOccHealth,
  );
  const drugScreenTypes: DrugPanel[] = determineDrugScreenTypes(
    canOrderAddonDrug,
    data,
  );
  const drugStatus = useMemo(() => {
    return currentUser?.account?.drug_screening_setup;
  }, [currentUser?.account?.drug_screening_setup]);

  // EGRO-753 - does not change current functionality. Adds bugfix for state criminal search bug
  const getPriceByType = (screeningType: string, subtype?: string) => {
    let itemPrice;
    const foundItems =
      itemPrices.filter((item: any) => item.product_type === screeningType) ||
      {};

    if (foundItems.length > 1 && screeningType === 'state_criminal_search') {
      itemPrice =
        foundItems.find((item: any) => item.subtype === subtype) || {};
    } else if (foundItems.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      itemPrice = foundItems[0];
    } else if (
      foundItems.length > 1 &&
      screeningType !== 'state_criminal_search'
    ) {
      // eslint-disable-next-line prefer-destructuring
      itemPrice = foundItems[0];
    }

    return isNaN(itemPrice?.base_price)
      ? 'Loading price...'
      : getDollarAmount(itemPrice?.base_price);
  };

  return (
    <ScreeningList>
      <Accordion>
        <MotorVehicleRecordsCheckItem
          screeningTypesInSearch={screeningTypesInSearch}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
          additionalProperties={additionalProperties}
          basePackage={basePackage}
        />
        <CriminalRecordsSearchesItem
          screeningTypesInSearch={screeningTypesInSearch}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
          usingAddChecks={usingAddChecks}
          itemPrices={itemPrices}
        />
        <VerificationsItem
          screeningTypesInSearch={screeningTypesInSearch}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
          basePackage={basePackage}
          itemPrices={itemPrices}
          additionalProperties={additionalProperties}
          screeningSettingPrice={screeningSettingPrice}
        />
        {(shouldRenderDrugScreenDropdown ||
          shouldRenderOccHealthScreenDropdown) && (
          <DrugAndHealthScreeningsItem
            screeningTypesInSearch={screeningTypesInSearch}
            actionProps={actionProps}
            basePackage={basePackage}
            drugScreenTypes={drugScreenTypes}
            shouldRenderDrugScreenDropdown={shouldRenderDrugScreenDropdown}
            drugStatus={drugStatus}
            occupationalHealthServices={occupationalHealthServices}
            shouldRenderOccHealthScreenDropdown={
              shouldRenderOccHealthScreenDropdown
            }
          />
        )}
        <CivilRecordsSearchesItem
          screeningTypesInSearch={screeningTypesInSearch}
          getPriceByType={getPriceByType}
          actionProps={actionProps}
        />
        <HealthcareSanctionsFACISItem
          screeningTypesInSearch={screeningTypesInSearch}
          searchString={searchString}
          actionProps={actionProps}
          itemPrices={itemPrices}
          additionalProperties={additionalProperties}
        />
        {shouldRenderCreditDropdown && (
          <CreditReportItem
            screeningTypesInSearch={screeningTypesInSearch}
            account={currentUser?.account}
            getPriceByType={getPriceByType}
            actionProps={actionProps}
          />
        )}
      </Accordion>
    </ScreeningList>
  );
};

export default DomesticAddScreeningsList;
