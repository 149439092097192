import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';
import { useTranslation } from 'react-i18next';
import { GenericObject } from '@dashboard-experience/utils';
import { PaymentMethod } from 'api/payment';
import {
  Button,
  Divider,
  StyledH3,
  StyledNote,
  GridCol,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import AddPaymentModal from './AddPaymentModal';
import PaymentDisplay from './PaymentDisplay';

type Props = {
  banks: Array<PaymentMethod>;
  postPaymentMethod: GenericObject;
  paymentMethodRequired: boolean;
  paymentMethodDisabled: boolean;
};

const BankInformation: React.FC<Props> = ({
  banks,
  postPaymentMethod,
  paymentMethodRequired,
  paymentMethodDisabled,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [bankElementOptions, setBankElementOptions]: any = useState();

  const hasStoredBank = banks.length > 0;

  const showModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal = useCallback((): void => {
    setError('');
    setModalOpen(false);
  }, []);

  useEffect((): void => {
    if (postPaymentMethod.postPaymentMethodResult.isSuccess) {
      hideModal();
      setError('');
    }
  }, [postPaymentMethod.postPaymentMethodResult.isSuccess, hideModal]);

  const renderBanks = banks.map((bankInfo: PaymentMethod) => {
    return (
      <div key={bankInfo.stripe_id}>
        <PaymentDisplay
          paymentInfo={bankInfo}
          verified={bankInfo.verified}
          paymentMethodRequired={paymentMethodRequired}
        />
      </div>
    );
  });

  return (
    <section data-testid='ach-wrapper'>
      <M.Grid>
        <M.GridRow>
          <CustomCol col={5} noPadding style={{ marginTop: '0' }}>
            <M.GridRow>
              <GridCol>
                <StyledH3 className='mb-0' data-testid='ach-section-title'>
                  {t(`payment.bank_information.title`)}
                </StyledH3>
                <StyledNote className='mb-0 mt-0'>
                  {t(`payment.bank_information.form_heading`)}
                </StyledNote>
              </GridCol>
              <GridCol style={{ textAlign: 'right' }}>
                <Button
                  kind='secondary'
                  disabled={paymentMethodDisabled}
                  onClick={showModal}
                  data-testid='ach-add-new-btn'
                >
                  {t(`payment.bank_information.add_new_btn`)}
                </Button>
              </GridCol>
            </M.GridRow>
            {hasStoredBank && renderBanks}
            <Divider />
          </CustomCol>
        </M.GridRow>
      </M.Grid>

      {modalOpen && (
        <AddPaymentModal
          modalOpen={modalOpen}
          hideModal={hideModal}
          elementOptions={bankElementOptions}
          setElementOptions={setBankElementOptions}
          error={error}
          setError={setError}
          postPaymentMethod={postPaymentMethod}
          paymentType='us_bank_account'
        />
      )}
    </section>
  );
};

export default BankInformation;
