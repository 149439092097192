import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Option from 'modules/assess/models/context-date/option';
import { useState as useContextDateState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { Button } from 'modules/assess/ui/page';
import { entries } from 'state/utilities/entities/cache';
import * as Dialog from './create';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container: React.FC = () => {
  const [open, show] = useState(false);
  const { disabled, options } = useContextDateState();
  const allOptions = Object.values(Option);
  const unvailableOptions = (entries(options) || []).map(o => o.option);
  const availableOptions = allOptions.filter(
    o => !unvailableOptions.includes(o),
  );

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  if (disabled || !availableOptions.length) {
    return null;
  }

  return (
    <StyledContainer>
      <Button
        kind='secondary'
        onClick={handleClick}
        size='sm'
        trans='assess:context_dates.create.button'
      />
      <Dialog.Create
        open={open}
        onClose={handleClose}
        options={availableOptions}
      />
    </StyledContainer>
  );
};

export default Container;
