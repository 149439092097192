import { formatDate } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldComponentProps } from '../types';

const SuspensionDate: React.FC<FieldComponentProps> = ({
  adverseItem,
  displayName,
  testid,
}) => {
  const { t } = useTranslation();
  const suspensionDate =
    displayName.toLowerCase() === 'suspension start date'
      ? adverseItem?.adversible.start_date
      : adverseItem?.adversible.end_date;
  return (
    <span data-testid={testid} className='value-width'>
      {(suspensionDate && formatDate(suspensionDate, 'MMM Do YYYY')) ||
        t(
          `${namespace}:report.actions.pre_adverse.optional_fci.charges_table.columns.not_provided`,
        )}
    </span>
  );
};

export default SuspensionDate;
