import { object, string, number } from 'yup';
import { NewRandomSelectionPollParams } from 'api/randomDrugTesting';
import { FormInputShape } from './types';

export const TEST_TYPES: Record<string, 'Alcohol' | 'Drug'> = {
  ALCOHOL: 'Alcohol',
  DRUG: 'Drug',
};

export const DROPDOWN_OPTIONS: Record<string, 'Fixed' | '%'> = {
  FIXED: 'Fixed',
  PERCENTAGE: '%',
};

export const VALIDATION_MESSAGES = {
  REQUIRED: 'This is a required field',
  EXCEEDS_COHORT_SIZE: 'Number exceeds total employees on uploaded CSV.',
  EXCEEDS_PERCENTAGE_LIMIT: 'Percentages cannot exceed 100',
};

export const INITIAL_VALUES: FormInputShape = {
  drugRandomSelectionSize: 0,
  alcoholRandomSelectionSize: 0,
  drugNumberType: '',
  alcoholNumberType: '',
};

export const generateValidationSchema = (
  poolSize: number,
  options: {
    includeDrug: boolean;
    includeAlcohol: boolean;
  },
) => {
  const { includeDrug, includeAlcohol } = options;
  const schema: Record<string, any> = {};

  if (includeDrug) {
    schema.drugRandomSelectionSize = number()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(0)
      .when('drugNumberType', {
        is: (value: string) => value === DROPDOWN_OPTIONS.FIXED,
        then: schema =>
          schema.max(poolSize, VALIDATION_MESSAGES.EXCEEDS_COHORT_SIZE),
        otherwise: schema =>
          schema.max(100, VALIDATION_MESSAGES.EXCEEDS_PERCENTAGE_LIMIT),
      });
    schema.drugNumberType = string().required(VALIDATION_MESSAGES.REQUIRED);
  }

  if (includeAlcohol) {
    schema.alcoholRandomSelectionSize = number()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(0)
      .when('alcoholNumberType', {
        is: (value: string) => value === DROPDOWN_OPTIONS.FIXED,
        then: schema =>
          schema.max(poolSize, VALIDATION_MESSAGES.EXCEEDS_COHORT_SIZE),
        otherwise: schema =>
          schema.max(100, VALIDATION_MESSAGES.EXCEEDS_PERCENTAGE_LIMIT),
      });
    schema.alcoholNumberType = string().required(VALIDATION_MESSAGES.REQUIRED);
  }

  return object().shape(schema);
};

export const calculatePercentage = (numberValue: number, totalSize: number) => {
  if (totalSize === 0) return 0;
  return Math.round((numberValue * 100) / totalSize);
};

export const calculateSelectedCountFromPercentage = (
  numberValue: number,
  totalSize: number,
) => Math.ceil((numberValue / 100) * totalSize);

export const transformRandomSelectionFormValuesForApi = (
  cohortId: string,
  {
    drugRandomSelectionSize,
    alcoholRandomSelectionSize,
    drugNumberType,
    alcoholNumberType,
  }: FormInputShape,
  totalSize: number,
): NewRandomSelectionPollParams => ({
  cohort_id: Number(cohortId),
  drug_selection_count:
    drugNumberType === DROPDOWN_OPTIONS.FIXED
      ? drugRandomSelectionSize
      : calculateSelectedCountFromPercentage(
          drugRandomSelectionSize,
          totalSize,
        ),
  alcohol_selection_count:
    alcoholNumberType === DROPDOWN_OPTIONS.FIXED
      ? alcoholRandomSelectionSize
      : calculateSelectedCountFromPercentage(
          alcoholRandomSelectionSize,
          totalSize,
        ),
});
