import React from 'react';
import { getTat } from '@dashboard-experience/mastodon';
import { FieldComponentProps } from '../types';

const TurnaroundTime: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const tat = getTat(report.turnaround_time);
  return <div data-testid={testid}>{tat}</div>;
};

export default TurnaroundTime;
