import isString from 'lodash/isString';
import { Namespace } from 'modules/assess/api';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  namespace: Namespace;
};

export const Description: React.FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('assess');

  const components = {
    'eeoc-link': (
      <a
        href='https://www.eeoc.gov/laws/guidance/enforcement-guidance-consideration-arrest-and-conviction-records-employment-decisions'
        target='_blank'
        rel='noopener noreferrer'
      >
        2012 EEOC Guidelines
      </a>
    ),
  };

  let translations: Array<string> | undefined = t(
    `assess:rules.standard.description.${namespace}`,
    {
      returnObjects: true,
    },
  );

  let children;

  if (isString(translations)) {
    translations = undefined;
  } else {
    children = translations?.map((paragraph: string) => (
      <p key={Math.random()}>
        <Trans components={components}>{paragraph}</Trans>
      </p>
    ));
  }

  return <div data-testid='assess-rules-standard-description'>{children}</div>;
};

export default Description;
