import { CreateCandidateParams, CandidateContactInfo } from 'api/invitations';
import { accountHasPermission } from '@dashboard-experience/utils';
import { UploadCSVPayload } from '../../../api/packages';
import {
  BULK_INVITE_CSV_STORAGE_PATH,
  Invite,
} from '../../Packages/OrderBackgroundCheck/SharedItems';
import { getCSVContent } from '../../Packages/OrderBackgroundCheck/UploadCandidateInfo/helper';

const create = ({
  selection,
  emails,
  csv,
  account,
  call,
  uploadCall,
  setGeoError,
  setPermissionError,
  currentUser,
  bulkInviteEnabled,
  addons,
  createCandidateInvitation = undefined,
  manualBulkOrderEnabled,
  manualBulkCall,
  manualBulkUploadData,
  invites,
}: {
  [key: string]: any;
}): any => {
  const geoRequired = account?.geos_required;
  const segmentationEnabled = account?.segmentation_enabled;
  const hierarchyPresent = account?.hierarchy_present;
  const unauthorized =
    !accountHasPermission(currentUser, 'authorized') &&
    !accountHasPermission(currentUser, 'api_authorized') &&
    currentUser?.account?.credentialed_at;

  const {
    node,
    geo,
    program,
    country,
    city,
    state,
    package: pkg,
    purpose,
    payment_profile,
  } = selection;

  if (unauthorized) {
    setPermissionError(true);
    return;
  }

  if (geoRequired && !segmentationEnabled && !geo) {
    setGeoError(true);
    return;
  }

  const mails = emails.join(',').replace(/,\$/, '');

  const payload: Partial<CreateCandidateParams> = {
    emails: mails,
    package: pkg.slug,
    invited_from: 'dashboard',
    billing_entity: payment_profile?.id,
  };

  if (geo && geo.id !== 'None') payload.geo_id = geo.id;
  if (program) payload.program_id = program.id;
  if (purpose) payload.permissible_purpose = purpose;
  if (invites) {
    const invitation_parameters: Array<CandidateContactInfo> = [];
    invites.forEach((i: Invite) => {
      if (i.email.isValid && i.firstName.isValid && i.phoneNumber.isValid) {
        invitation_parameters.push({
          email: i.email.value,
          first_name: i.firstName.value,
          phone_number: i.phoneNumber.value,
        });
      }
    });

    if (invitation_parameters.length > 0) {
      payload.invitation_parameters = invitation_parameters;
    }
  }

  if (segmentationEnabled) {
    if (hierarchyPresent) {
      payload.node = node.custom_id;
    }

    const location: any = {
      country,
      state: state?.abbreviation,
      city: city?.name,
    };
    Object.keys(location).forEach(
      l => location[l] == null && delete location[l],
    );

    payload.work_locations = [location];
  } else if (geo?.country && geo.country !== 'US') {
    const location: any = {
      country: geo.country,
      state: geo.state,
      city: geo.city,
    };
    Object.keys(location).forEach(
      l => location[l] == null && delete location[l],
    );

    payload.work_locations = [location];
  }

  if (bulkInviteEnabled) {
    const { emails, ...modifiedPayload } = payload;

    const uploadPayload: UploadCSVPayload = {
      accountId: currentUser.account.id,
      uploaded_list_type: BULK_INVITE_CSV_STORAGE_PATH,
      raw_csv_file_s3_path: csv.fileStorageKey,
      upload_params: {
        addons,
        fileDisplayName: csv.fileDisplayName,
        ...modifiedPayload,
        package: pkg,
      },
    };
    uploadCall(uploadPayload);
  } else if (manualBulkOrderEnabled) {
    const params = {
      bulk_apply_csv: window.btoa(
        getCSVContent(manualBulkUploadData.parsedData),
      ),
      geo_id: geo.id ?? '',
      order: currentUser.id,
      account_id: currentUser.account.id,
      node: node?.custom_id,
      work_locations: payload.work_locations,
      package_resource_id: pkg.source_version_id,
      config_options: {
        company: currentUser.account?.company?.name,
        geo_id: geo.id ?? '',
        is_test: false,
        order: currentUser.id,
        node_id: node?.custom_id,
        zipcode: currentUser.account?.company?.zipcode,
      },
    };

    manualBulkCall(params);
  } else {
    // call({ payload, bulkInviteEnabled });
    createCandidateInvitation
      ? call(createCandidateInvitation)
      : call({ payload, bulkInviteEnabled });
  }
};

export default create;
