import React from 'react';
import Category from './Category';
import { Row } from './types';

type Props = {
  rows: Row[];
};

const Categories: React.FC<Props> = ({ rows }) => {
  return (
    <>
      {rows.map(
        (row: Row): React.ReactNode => (
          <Category key={row.id} row={row} />
        ),
      )}
    </>
  );
};

export default Categories;
