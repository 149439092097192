import {
  localStorageFallback,
  purgeSessionStorage,
  removeAccessToken,
} from '@dashboard-experience/utils';

import { deleteToken } from 'utils/URLHelper';
import { logout, removeImpersonation } from 'utils';
import { clearUserPreferences } from 'api/dashboardPreferences';

const LogoutPage = () => {
  removeAccessToken();
  removeImpersonation();
  clearUserPreferences();
  localStorageFallback.removeItem('currentUser');
  purgeSessionStorage();
  deleteToken();
  logout();
  return null;
};

export default LogoutPage;
