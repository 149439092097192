import React from 'react';
import { Redirect } from 'react-router-dom';
import useGetUser from '../../components/Signup/hooks/useGetUser';

type Props = {
  children: React.ReactNode;
};

const AuthWrapper = ({ children }: Props) => {
  const { data: user, isLoading } = useGetUser();

  if (!user && !isLoading) {
    return <Redirect to='/login' />;
  }

  return <>{children}</>;
};

const withAuthWrapper = (Component: any) => {
  const WrappedComponent = (props: any) => (
    <AuthWrapper>
      <Component {...props} />
    </AuthWrapper>
  );

  WrappedComponent.displayName = `withAuthWrapper(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default withAuthWrapper;
