import { capitalize } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Button as ComponentButton } from 'components/Button';
import { Namespace } from 'modules/assess/api';
import * as api from 'modules/assess/api/ruleset-versions';
import { useUser } from 'modules/assess/ui/hooks';
import { useNamespace } from 'modules/assess/ui/router/context';
import { useActive } from 'modules/assess/ui/ruleset/version/api';
import { Events } from 'modules/assess/v2/models/amplitude/home';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { ID } from 'modules/id';

type Props = {
  id?: ID;
};

const StyledIcon = styled(M.Icon)`
  margin-left: 0.8rem;
`;

export const Export: React.FC<Props> = ({ id }) => {
  const version = useActive(id).data?.id;
  const { t } = useTranslation('assess:v2');
  const text = capitalize(t('home.guidelines.export'));
  const namespace = useNamespace() || Namespace.criminal;

  const currentUser = useUser();
  const track = useTrackEvent();

  const handleExport = useCallback(() => {
    if (!version) {
      return;
    }

    api.downloadPdf(namespace, version);
    if (currentUser) {
      track(Events.ExportGuidelines, {
        [Events.Page]: Events.Pagename,
      });
    }
  }, [currentUser, namespace, track, version]);

  return (
    <ComponentButton
      kind='secondary'
      onClick={handleExport}
      size='sm'
      id='assess-v2-ui-home-guidelines-records-actions-export'
    >
      {text}
      <StyledIcon icon='Download' />
    </ComponentButton>
  );
};

export default Export;
