import { useList } from 'api/geos';
import { useUserAccount } from 'modules/assess/ui/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from 'carbon-components-react';
import styled from 'styled-components';

import { useCities } from 'api/accounts';
import GeoRow from './GeoRow';
import { CityProps } from '../types';

const PAGE_SIZE: number = 8;

const TableHeader = styled(M.TableHeader)`
  &.table-header-name {
    width: 34%;
  }

  &.table-header-state {
    width: 20%;
  }
  &.table-header-city {
    width: 40%;
  }

  &.table-header-delete {
    width: 6% !important;
  }
`;

type Rows = DataTableRow & {
  cells: DataTableCell[];
};

type GeosTableProps = {
  newGeoMade: boolean;
  setNewGeoMade: React.Dispatch<React.SetStateAction<boolean>>;
};
const GeosTable: React.FC<GeosTableProps> = ({ newGeoMade, setNewGeoMade }) => {
  const [pageIndex, setPageIndex] = useState(0); // index is 0-based, because of how our pagination works
  const [pageCount, setPageCount] = useState(1);

  const [geos, setGeos] = useState([]);

  const accountId = useUserAccount()?.id || '';
  const {
    data,
    isSuccess: listSuccess,
    refetch,
    isLoading,
  } = useList({
    accountId,
    params: {
      page: pageIndex + 1,
      per_page: PAGE_SIZE,
    },
  });

  const { t } = useTranslation('', { keyPrefix: 'screening_settings.geos' });

  const { data: cityList } = useCities();

  // Get all of the cities within a given State
  const getRelevantCities = useCallback(
    stateInitials => {
      // avoid running .filter against `undefined`, while the useCities API call is being made
      if (!cityList) {
        return [];
      }

      return (cityList as CityProps[]).filter(
        (city: CityProps) => city.state === stateInitials,
      );
    },
    [cityList],
  );

  // Function to trigger a re-fetch of the current set of geos
  const refetchPage = useCallback(() => {
    // For most cases, simply re-fetch the current page
    if (geos.length > 0) {
      refetch();
    }
    // Special case where we just deleted the last geo of the page - need to go back a page
    else {
      setPageIndex(Math.max(pageIndex - 1, 0)); // .max to avoid going to a negative page
    }
  }, [geos.length, pageIndex, refetch]);

  // When a new Geo gets made (as signaled by the CreateGeo component):
  useEffect(() => {
    if (newGeoMade) {
      setNewGeoMade(false);
      refetch();
    }
  }, [newGeoMade, refetch, setNewGeoMade]);

  // Anytime we get a response for a geo list
  useEffect(() => {
    // If it was successful, then update our Geos locally
    if (listSuccess) {
      setGeos(data?.geos?.data);

      // and make sure the pagination tracker is accurate
      const totalGeosCount: number = data?.geos?.count;
      setPageCount(Math.ceil(totalGeosCount / PAGE_SIZE));
    }
  }, [data, listSuccess]);

  const headers = [
    { key: 'name', header: t('table.name') },
    { key: 'state', header: t('table.state') },
    { key: 'city', header: t('table.city') },
  ];

  if (isLoading) {
    return <M.LoadingSpinner />;
  }

  return (
    <>
      <M.DataTable headers={headers} rows={geos}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
        }: any) => (
          <M.Table {...getTableProps()}>
            <M.TableHead>
              <M.TableRow>
                {headers.map((header: DataTableHeader) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    className={`table-header-${header.key}`}
                  >
                    {header.header}
                  </TableHeader>
                ))}
                <TableHeader className='table-header-delete' />
              </M.TableRow>
            </M.TableHead>

            <M.TableBody>
              {rows.map((row: Rows, index: number) => {
                return (
                  <GeoRow
                    key={row.id}
                    row={row}
                    geo={geos?.[index]} // If swapping pages, while the API call pends, there's a possibility that the geo object isn't defined yet
                    getRowProps={getRowProps}
                    getRelevantCities={getRelevantCities}
                    refetchPage={refetchPage}
                  />
                );
              })}
            </M.TableBody>
          </M.Table>
        )}
      </M.DataTable>
      <M.Pagination
        pageCount={pageCount}
        selectedIndex={pageIndex}
        onPageClick={setPageIndex}
      />
    </>
  );
};

export default GeosTable;
