import { reject } from 'lodash';
import { Fact } from 'modules/assess';
import { EXCLUDED_CONTEXT_DATE_OVERRIDE_FACTS } from 'modules/assess/models/criminal/rules';
import Kind from 'modules/assess/models/rules/common/kind';
import { Fact as CriminalFact } from './criminal/rules/fact';

export class FactsFilter {
  readonly facts: Fact[];

  readonly kind: Kind;

  readonly regexFacts: Fact[];

  readonly includeContextDateFacts: boolean;

  readonly includeRegexFacts: boolean;

  constructor(
    facts: Fact[],
    regexFacts: Fact[],
    kind: Kind,
    includeContextDateFacts: boolean,
    includeRegexFacts: boolean,
  ) {
    this.facts = facts;
    this.kind = kind;
    this.regexFacts = regexFacts;
    this.includeContextDateFacts = includeContextDateFacts;
    this.includeRegexFacts = includeRegexFacts;
  }

  getFilteredFacts(): Fact[] {
    return reject(this.facts, f => this.isExcluded(f));
  }

  isExcluded(fact: Fact): boolean {
    return (
      this.isExcludedByContextDate(fact) ||
      this.isExcludedByRegex(fact) ||
      this.isExcludedByKind(fact)
    );
  }

  isExcludedByContextDate(fact: Fact): boolean {
    return FactsFilter.isExcludedBySetting(
      fact,
      EXCLUDED_CONTEXT_DATE_OVERRIDE_FACTS,
      this.includeContextDateFacts,
    );
  }

  isExcludedByRegex(fact: Fact): boolean {
    return FactsFilter.isExcludedBySetting(
      fact,
      this.regexFacts,
      this.includeRegexFacts,
    );
  }

  isExcludedByKind(fact: Fact): boolean {
    if (this.kind === Kind.COUNT) {
      if (fact === CriminalFact.SCREENING_TYPE) return true;
    }

    return false;
  }

  static isExcludedBySetting(fact: Fact, facts: Fact[], setting: boolean) {
    if (setting) return false;

    return facts.includes(fact);
  }
}
