import React, { useCallback } from 'react';
import { hasPermission, emailIsValid } from '@dashboard-experience/utils';
import { useUpdateCandidate } from 'api/candidate';
import { Candidate } from 'types';
import Suppression from 'components/Report/CandidateInformation/fields/Suppression';
import { FieldComponentProps } from '../../types';
import { InPlaceTextEditor } from './styles';

const Email: React.FC<FieldComponentProps> = ({
  fieldKey,
  allowCandidateEdit,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
  currentUser,
  testid,
}) => {
  const { email, id: candidateId } = candidate;
  const updatedEmail = candidateUpdates?.email;
  // updatedEmail may be empty string
  const displayEmail = updatedEmail == undefined ? email : updatedEmail;

  const { updateCandidateCall } = useUpdateCandidate(fieldKey, 'email');

  const onEmailEditSubmit = useCallback(
    (newEmailValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          email: newEmailValue,
        });
        if (!emailIsValid(newEmailValue)) {
          return;
        }
        setCandidateUpdates?.({ ...candidateUpdates, email: newEmailValue });
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  const allowManageSuppressions = hasPermission(
    currentUser,
    'manage_communicator',
  );

  return (
    <div data-testid={testid}>
      <InPlaceTextEditor
        allowEdit={allowCandidateEdit}
        ariaLabel='candidate-email'
        initialValue={displayEmail}
        inputId='candidate-email'
        submitCallback={onEmailEditSubmit}
      />

      {allowManageSuppressions && (
        <Suppression
          candidate={candidate as Candidate}
          allowEdit={allowCandidateEdit || false}
        />
      )}
    </div>
  );
};

export default Email;
