import { fetchFlagBatch } from '@dashboard-experience/react-flagr';
import { GenericObject } from '@dashboard-experience/utils';
import { FLAGR_FLAG_KEYS, FLAGR_URL, ENV } from 'Constants';
import { useMemo } from 'react';
import { AnyQueryKey, useQuery } from 'react-query';
import { User } from 'types/report';

const type = 'account';

export type FlagrResults = {
  flags: GenericObject;
  type: string;
  context: GenericObject;
};

const buildContext = (userData: User) => {
  userData = userData || {};
  const { account = {}, email, roles, permissions } = userData;
  return {
    account_id: account.id,
    account_uri: account.uri_name,
    account_uri_name: account.uri_name,
    env: ENV,
    user_email: email,
    manual_orders_enabled: account.manual_orders_enabled,
    referrer: account.referrer,
    partner_account_ids: account.partner_account_ids,
    segmentation_enabled: account.segmentation_enabled,
    roles,
    permissions,
    market_segment: account.market_segment,
  };
};

export const useFetchFlagr = (userData: User) => {
  const context = useMemo(() => buildContext(userData), [userData]);

  const key: AnyQueryKey = ['flagr/batch'];

  const request = () =>
    fetchFlagBatch(
      FLAGR_FLAG_KEYS,
      {
        id: context.account_id,
        type,
        context,
      },
      { url: FLAGR_URL },
    );

  const {
    isLoading,
    isError,
    isSuccess,
    data: flags,
  } = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  return {
    isSuccess,
    isLoading,
    isError,
    type,
    context,
    flags,
  };
};
