import { Client } from 'api';
import { REALTIME_URL } from 'Constants';
import { CommandProps } from './types';

type AuthReturnValue = {
  success?: boolean;
};
/**
 * Authenticate with the Realtime server.
 * If identity is valid, then it sets a cookie named realtimeToken, which gets used for actually connecting
 *
 * @returns {boolean} success - if successfully authenticated, returns this flag
 */
export const authenticate = (): Promise<AuthReturnValue> => {
  // If this environment doesn't have a Realtime URL specified, then we stop the attempt to use it here
  if (!REALTIME_URL) {
    return Promise.resolve({ success: false });
  }

  return Client.post(
    `${REALTIME_URL}/authenticate`,
    {}, // No params needed
    {
      // Axios needs this flag in order to allow the incoming Cookie from realtime to get stored, to deal with cross-site Access-Control requests
      withCredentials: true,
    },
  );
};

/**
 * Subscribe and unsubscribe to rooms on the Realtime server
 * @param primusConnection - The Primus object that gets used to send commands through
 * @param command - the action to send to Realtime (as of now, "subscribe" is used for everything)
 * @param roomType - The type of room to subscribe to (Candidate, Report, etc.)
 * @param roomId - Unique ID for the given type of resource (e.g. Candidate ID when viewing a Candidate)
 */
export const updateRealtimeSubscription = ({
  primusConnection,
  command,
  roomType,
  roomID,
}: CommandProps) => {
  const destination = roomType && roomID ? `${roomType}:${roomID}` : null; // Null destination means that the user is unsubscribing

  const payload = [command, destination];

  primusConnection.write(payload);
};
