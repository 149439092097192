import { colors } from '@dashboard-experience/mastodon';
import styled, { css } from 'styled-components';

export const headerHeight = '3.125rem';

export const headerSectionStyles = css`
  height: ${headerHeight};
  border-top: 1px solid ${colors.uiGrey200};
  border-bottom: 1px solid ${colors.uiGrey200};
  box-shadow: 0px 4px 4px -3px rgba(72, 82, 94, 0.1);
`;

export const Container = styled.div<{ showOverview: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: ${({ showOverview }) => (showOverview ? 'auto' : 'hidden')};
`;
