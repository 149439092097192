import React, { useCallback, useState } from 'react';
import { REPORT_SOURCE_FILTER } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { useReduxState } from '../hooks';
import { getInitialReportSources } from '../helpers';
import MultiSelectFilter from './MultiSelectFilter';
import { styledFilter } from './StyledComponents';

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  ${styledFilter}
`;

const ReportSource = () => {
  const { searchParams } = useReduxState();
  const currentUser = useUser();

  const getInitialSources = (params: any) =>
    params.filter((t: string) =>
      REPORT_SOURCE_FILTER.map((s: any) => s.report_source.includes(t)),
    );

  const initialReportSources = getInitialReportSources(
    getInitialSources(searchParams.report_source || []),
    REPORT_SOURCE_FILTER,
  );

  const trackEvent = useTrackEvent();

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onChange = useCallback(
    (setFilters: (params: any) => void) =>
      ({ selectedItems }: { selectedItems: Array<any> }) => {
        const s = selectedItems.map(s => s.report_source);

        if (selectedItems.length > selectedItemsCount && currentUser) {
          trackEvent(
            CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
            {
              // get last item of array of selected sources which is equal to the latest clicked source
              'Report source': humanizeOnUnderscore(s.slice(-1).pop()),
              'Selected Filter': 'Report source',
            },
          );
        }
        setSelectedItemsCount(selectedItems.length);
        setFilters(s);
      },
    [currentUser, selectedItemsCount, trackEvent],
  );

  return (
    <StyledMultiSelectFilter
      initialSelectedItems={initialReportSources}
      onChange={onChange}
      component='ReportSourceFilter'
      param='report_source'
      titleText='Report source'
    />
  );
};

export default ReportSource;
