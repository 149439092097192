import React, { SyntheticEvent } from 'react';

export type Props = {
  stepIndex: number;
  totalSteps: number;
  currentStep: React.ElementType;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Content: React.FC<Props> = ({
  stepIndex,
  totalSteps,
  currentStep,
  onClose,
}) => {
  const Step = currentStep;

  return (
    <Step stepIndex={stepIndex} totalSteps={totalSteps} onClose={onClose} />
  );
};

export default Content;
