import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useUserAccount } from 'modules/assess/ui/hooks';
import Icon from 'modules/assess/v2/ui/home/settings/eligible-records/Icon';
import Example from './example';
import { Option as TranslationHelper } from './translation-helpers';
import Value from './value';

type Props = {
  confirmation?: boolean;
  example?: { disabled?: boolean };
  onChange: (e: Value) => void;
  onConfirm?: (e: boolean) => void;
  selected?: boolean;
  value: Value;
};

const Container = styled.div`
  margin-left: 2em;
  margin-bottom: 3em;
`;

const Description = styled.div`
  padding-top: 0.2em;
`;

const StyleRadioButton = styled(M.RadioButton)`
  margin-top: 1rem;
  > * {
    justify-content: flex-start !important;
  }
`;

const Label = styled.span`
  color: ${colors.uiNavy600};
  font-weight: bold;
`;

const Option: React.FC<Props> = ({
  confirmation,
  example,
  onChange,
  onConfirm,
  selected,
  value,
}) => {
  const { t } = useTranslation('assess');
  const account = useUserAccount();
  const helper = new TranslationHelper(
    account?.assess_eligible_item_treatment_enabled,
    value.toLowerCase(),
  );

  const confirm = confirmation && selected;
  const optionDescription = getTransComponent(t, `${helper.description}`);
  const optionTitle = getTransComponent(t, `${helper.title}`);
  const exampleDescription = getTransComponent(
    t,
    `${helper.example.description}`,
  );
  const exampleAction = t(`${helper.example.action}`);

  useEffect(() => {
    onConfirm?.(confirm === true);
  }, [confirm, onConfirm]);

  return (
    <Container>
      <StyleRadioButton
        checked={selected}
        value={value}
        labelText={<Label>{optionTitle}</Label>}
        onChange={onChange}
      />
      <Container>
        <Description>{optionDescription}</Description>
        <Example
          actionText={exampleAction}
          example={example}
          descriptionText={exampleDescription}
        />
      </Container>
    </Container>
  );
};

function getTransComponent(t: TFunction, key: string) {
  const components = {
    'eligible-icon': <Icon icon='eligible' />,
  };

  return <Trans t={t} i18nKey={key} components={components} />;
}

export default Option;
