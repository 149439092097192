import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

import { Results } from 'modules/assess/models/insights';

type Props = {
  active?: Results.Type;
  draft?: Results.Type;
};

export const ResultsSummary: React.FC<Props> = ({ active, draft }) => {
  const { t } = useTranslation('assess');

  if (!active || !draft) {
    return null;
  }

  const next = draft.eligible || 0;
  const prev = active.eligible || 0;

  const diff = next - prev;
  const count = Math.abs(diff);
  const percentage = 100 * (count / prev) || 0;
  const direction = diff < 0 ? 'negative' : 'positive';
  const icon = diff < 0 ? 'CaretDown' : 'CaretUp';
  const message = t(
    `assess:ruleset.version.insights.results.summary.${direction}`,
    {
      count,
    },
  );
  const formatter = new Intl.NumberFormat(undefined, {
    maximumSignificantDigits: 3,
  });

  return (
    <Container>
      {message}
      <Percentage diff={diff}>
        <M.Icon icon={icon} size='16' />
        {Number.isFinite(percentage) ? formatter.format(percentage) : '🚀'} %
      </Percentage>
    </Container>
  );
};

type StyleProps = {
  diff: number;
};

const green = colors.uiGreen600;
const red = colors.uiOrange600;

const Container = styled.div`
  display: flex;
  font-weight: bold;
`;

const Percentage = styled.span<StyleProps>`
  align-items: center;
  display: flex;
  margin-left: 1em;

  svg {
    fill: ${({ diff }) => (diff < 0 ? red : green)};
  }
`;

export default ResultsSummary;
