/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import {
  CurrentUser,
  localStorageFallback,
  removeAccessToken,
  removeParamsFromUrl,
} from '@dashboard-experience/utils';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';

import { fetchUser, handleTokenExpired } from 'actions';
import UserContext from 'context/CurrentUser';
import { REMOVEABLE_URL_PARAMS, USE_DD_RUM } from 'Constants';
import { datadogRum } from '@datadog/browser-rum';
import {
  getRemainingSessionDuration,
  isImpersonationExpired,
  saveRedirectPath,
  silentReauthenticate,
  isSignupFlow,
  accessTokenAuthorizations,
} from 'utils';
import { TReducerEvent } from 'reducers/reducerHelpers';
import { M } from '@dashboard-experience/mastodon';
import {
  clearUserPreferences,
  useInitializeUserPreferences,
} from 'api/dashboardPreferences';

type TState = {
  fetchRequest: TReducerEvent;
  needsAuth: boolean;
  currentUser: CurrentUser;
};

type Props = {
  children: ReactNode;
  fetchUser: () => (dispatch: any) => Promise<any>;
} & TState;

const AuthContainer = ({
  children,
  fetchUser,
  fetchRequest,
  needsAuth,
  currentUser,
}: Props) => {
  const history = useHistory();
  const hasRemainingDuration = getRemainingSessionDuration() > 0;
  const isSignup = isSignupFlow();

  const initializeUserPreferences =
    useInitializeUserPreferences(hasRemainingDuration);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (
      needsAuth === false &&
      fetchRequest.success &&
      currentUser &&
      currentUser.account &&
      USE_DD_RUM
    ) {
      datadogRum.setUser({
        id: currentUser.id,
        roles: currentUser.roles,
        is_internal: currentUser.is_internal,
        account: {
          allow_dashboard_report_ordering:
            currentUser.account.allow_dashboard_report_ordering,
          authorized: currentUser.account.authorized,
          assess_enabled: currentUser.account.assess_enabled,
          hierarchy_present: currentUser.account.hierarchy_present,
          see_full_dashboard: currentUser.account.see_full_dashboard,
          segmentation_enabled: currentUser.account.segmentation_enabled,
          uri_name: currentUser.account.uri_name,
          manual_orders_enabled: currentUser.account.manual_orders_enabled,
          programs_enabled: currentUser.account.programs_enabled,
          package_price_state: currentUser.account.package_price_state,
          show_package_price: currentUser.account.show_package_price,
          permissions: {
            manage_packages: currentUser.permissions?.manage_packages,
            write_accounts_and_invoices:
              currentUser.permissions?.write_accounts_and_invoices,
            read_invoices: currentUser.permissions?.read_invoices,
            read_account_invoices:
              currentUser.permissions?.read_account_invoices,
            manage_bulk_invitations:
              currentUser.permissions?.manage_bulk_invitations,
          },
        },
      });
      datadogRum.startSessionReplayRecording();
      return () => datadogRum.clearUser();
    }
    return () => {};
  }, [currentUser, fetchRequest.success, needsAuth]);

  const refetchUser = () => {
    const currentTokenTokenAuthorizations = accessTokenAuthorizations();
    if (
      currentTokenTokenAuthorizations &&
      currentTokenTokenAuthorizations.checkr_user_id !== currentUser.id
    ) {
      localStorageFallback.removeItem('currentUser');
      fetchUser();
    }
  };

  const onClick = useCallback(async () => {
    if (isSignup) {
      noop();
    } else {
      isImpersonationExpired();
      await silentReauthenticate();
      refetchUser();
    }
  }, [currentUser, refetchUser, isSignup]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick],
  );

  // If there was an error in trying to authenticate, then force a relog event.
  if (needsAuth === true && fetchRequest.error) {
    removeAccessToken();
    clearUserPreferences();
    localStorageFallback.removeItem('currentUser');
    saveRedirectPath();
    setTimeout(() => history.replace('/login'), 0);
    return null;
  }

  removeParamsFromUrl(window, REMOVEABLE_URL_PARAMS);

  // If auth hasn't succeded yet, then show the loading screen
  if (needsAuth || !fetchRequest.success) {
    return <M.LoadingSpinner />;
  }

  // If there was a session, but it's expired, then force a re-auth.
  if (!hasRemainingDuration) {
    handleTokenExpired();
    return <M.LoadingSpinner />; // Show loading screen because there IS a non-zero amount of lagtime before refreshing.
  }

  if (!initializeUserPreferences.isSuccess) {
    return <M.LoadingSpinner />;
  }

  return (
    <UserContext.Provider value={currentUser}>
      <div
        onClick={onClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role='button'
      >
        {children}
      </div>
    </UserContext.Provider>
  );
};

const mapStateToProps = ({ currentUser }: { currentUser: TState }) => {
  // "currentUser" here contains status info on the authentication state, in addition to the actual currentUser object
  return currentUser;
};

const mapDispatchToProps = (dispatch: Function) => ({
  fetchUser: () => dispatch(fetchUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
