import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { MotorVehicleReport, GenericObject } from 'types';

import { useNestedPreference } from 'api/dashboardPreferences';
import {
  getCustomFiltersStatusOrAssessment,
  getUnsatisfiedRuleClass,
  getMvrRuleIconStatus,
} from './Helpers';

type CustomFiltersProps = {
  mvrReport: MotorVehicleReport;
  reportStatusType: string;
  assessEnabled: boolean;
};
type ContentProps = {
  rule: GenericObject;
  mvrReport: MotorVehicleReport;
  reportStatusType: string;
  assessEnabled: boolean;
};

const Content: React.FC<ContentProps> = ({
  rule,
  mvrReport,
  reportStatusType,
  assessEnabled,
}) => {
  const { satisfied, description } = rule;
  const unsatisfiedRuleClass = getUnsatisfiedRuleClass(
    mvrReport,
    reportStatusType,
    assessEnabled,
  );

  const mvrRuleIconStatus = getMvrRuleIconStatus(mvrReport, reportStatusType);

  return (
    <M.Screening.Row style={{ display: 'flex' }} role='customFiltersItem'>
      {satisfied && (
        <M.StatusIcon
          icon='clear'
          solid={!assessEnabled}
          style={{ alignSelf: 'center', marginRight: '0.5rem' }}
        />
      )}
      {!satisfied && (
        <M.StatusIcon
          icon={!assessEnabled ? unsatisfiedRuleClass : mvrRuleIconStatus}
          solid={!assessEnabled}
          style={{ alignSelf: 'center', marginRight: '0.5rem' }}
        />
      )}{' '}
      {description}
    </M.Screening.Row>
  );
};

const CustomFilters: React.FC<CustomFiltersProps> = ({
  mvrReport,
  reportStatusType,
  assessEnabled,
}) => {
  const { custom_rules } = mvrReport;
  const customFiltersStatusOrAssessment = getCustomFiltersStatusOrAssessment(
    mvrReport,
    reportStatusType,
  );

  let customRules: GenericObject = [];
  if (custom_rules)
    customRules = Object.keys(custom_rules).map(rule => {
      return custom_rules[rule];
    });

  const [
    mvrCustomFiltersDropdownPreference,
    setMvrCustomFiltersDropdownPreference,
  ] = useNestedPreference('report', 'mvrCustomFiltersDropdown');

  const { expanded } = mvrCustomFiltersDropdownPreference;

  /**
   * @name onExpand
   * @function
   * @memberOf CustomerFilters
   * @description Callback to handle setting user preference for expand
   */
  const onExpand = useCallback(
    expanded => {
      setMvrCustomFiltersDropdownPreference({ expanded });
    },
    [setMvrCustomFiltersDropdownPreference],
  );

  return (
    <M.SubScreening
      className='mvr-custom-filters'
      title='Custom filters'
      status={customFiltersStatusOrAssessment}
      statusType={reportStatusType}
      expandable
      onExpand={onExpand}
      initialExpanded={expanded}
    >
      <M.Screening.Row>
        {customRules.map((rule: GenericObject, idx: number) => {
          return (
            <Content
              key={`${idx + 1}`}
              rule={rule}
              mvrReport={mvrReport}
              reportStatusType={reportStatusType}
              assessEnabled={assessEnabled}
            />
          );
        })}
      </M.Screening.Row>
    </M.SubScreening>
  );
};

export default CustomFilters;
