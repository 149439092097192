import { AccountsClient as client } from 'api';
import { GenericObject, Geo } from 'types';
import { PATH } from './constants';

export type ListParams = {
  accountId: string;
  params?: { [key: string]: any };
};

export const list = ({
  accountId,
  params,
}: ListParams): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/${PATH}`, {
    params,
  });
};

export type CreateParams = {
  accountId?: string;
  geo: any; // This technically cannot be a "Geo" type because it lacks an ID
};
export const create = ({
  accountId,
  geo,
}: CreateParams): Promise<GenericObject> => {
  return client.post(`${accountId}/${PATH}`, geo);
};

export type DeleteParams = {
  accountId?: string;
  geoId: string;
};

export const deleteGeo = ({
  accountId,
  geoId,
}: DeleteParams): Promise<GenericObject> => {
  return client.delete(`${accountId}/${PATH}/${geoId}`);
};

export type UpdateParams = {
  accountId?: string;
  geo: Geo;
};

export const update = ({
  accountId,
  geo,
}: UpdateParams): Promise<GenericObject> => {
  return client.put(`${accountId}/${PATH}/${geo.id}`, geo);
};
