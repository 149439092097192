import React from 'react';
import { RulesetVersionDiff } from 'modules/assess/models/rulesets/version-diff';
import CategorySetChanges from './category-code-set-version/CategorySetChanges';
import ContextDateOptionChanges from './context-date-options';
import CountRulesChanges from './count-rules';
import CustomRulesChanges from './custom-rules';
import FairnessRulesChanges from './fairness-rules';
import LookbackRulesChanges from './lookback-rules';
import Title from './Title';

type Props = {
  changes?: RulesetVersionDiff.Type;
  ruleset?: string;
};

export const Container: React.FC<Props> = ({ changes, ruleset }) => {
  return (
    <div data-testid='assess-ruleset-version-publish-dialog-content'>
      <Title name={ruleset} />
      <FairnessRulesChanges changes={changes?.rules?.premade} />
      <LookbackRulesChanges changes={changes?.rules?.lookback} />
      <CustomRulesChanges changes={changes?.rules?.custom} />
      <CountRulesChanges changes={changes?.rules?.count} />
      <CategorySetChanges changes={changes?.category_code_set_version} />
      <ContextDateOptionChanges changes={changes?.context_date_options} />
    </div>
  );
};

export default Container;
