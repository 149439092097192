import React, { useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import { Location } from 'history';

import { useUser } from 'context/CurrentUser';
import * as Notifications from 'state/notifications/entity';
import * as User from 'modules/core-types/user';
import { DEVELOPERS_URL, ENV } from 'Constants';
import { toast } from 'actions';
import {
  badTokenHandler,
  updatedLocationHandler,
  notificationHandler,
  updatedParamsHandler,
  sendInitialDataMessage,
} from './iframeHelpers';
import { DEV_DASHBOARD_STATES, IframeEvent } from './types';

type Props = {
  location: Location;
};

const DevexLogsIframe: React.FC<Props> = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const user: User.Type = useUser();

  const events: IframeEvent[] = [
    // Note: no resize handler here because that is inherently handled within the M.Iframe component
    {
      eventName: DEV_DASHBOARD_STATES.READY_FOR_INITIAL_DATA,
      cb: () => {
        sendInitialDataMessage(user.email);
        setLoading(false);
      },
    },
    {
      eventName: DEV_DASHBOARD_STATES.UPDATE_LOCATION_PATHNAME,
      cb: updatedLocationHandler,
    },
    {
      eventName: DEV_DASHBOARD_STATES.UPDATE_URL_PARAMS,
      cb: updatedParamsHandler,
    },
    {
      // Note: "Expired" message is considered deprecated, in favor of "Invalid"
      eventName: DEV_DASHBOARD_STATES.TOKEN_EXPIRED,
      cb: badTokenHandler,
    },
    {
      eventName: DEV_DASHBOARD_STATES.TOKEN_INVALID,
      cb: badTokenHandler,
    },
    {
      eventName: DEV_DASHBOARD_STATES.DISPLAY_NOTIFICATION,
      cb: event =>
        notificationHandler(event, (toastData: Notifications.Params) => {
          dispatch(toast(toastData));
        }),
    },
  ];
  // console.log(`constants...`, { DEVELOPERS_URL, ENV });

  return (
    <M.Iframe
      appName='developers'
      ENV={ENV}
      apiUrl={DEVELOPERS_URL}
      iframeId='developer-dashboard-iframe'
      location={location}
      loading={loading}
      urlParamsPassthrough
      postMessageEvents={events}
      sentTokens // Hardcoded true to bypass a check, because DevEx's iframe deals with token transmission AFTER creation
    />
  );
};

export default DevexLogsIframe;
