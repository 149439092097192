import findLast from 'lodash/findLast';
import { ID, Kind } from 'modules/assess/models/entity';
import { Event } from 'modules/assess/models/event';
import Status from 'modules/assess/ui/common/entity/status';
import React from 'react';
import { useFetch } from './api';

type Props = {
  instance?: ID;
  version?: ID;
};

const Container: React.FC<Props> = ({ instance, version }) => {
  const query = useFetch(instance, version);
  const { data } = query;
  const event = findEvent(data?.events);

  const id = instance;
  const kind = data?.draft ? Kind.draft : Kind.final;
  const latest = data?.draft || data?.active;
  const time = event?.time;
  const user = event?.user;
  const number = data?.number;

  return (
    <Status
      data-testid='assess-mvr-category-set-version-status'
      id={id}
      kind={kind}
      latest={latest}
      time={time}
      user={user}
      version={number}
    />
  );
};

function findEvent(events?: Event[]) {
  return findLast(events, e => e.type === 'published' || e.type === 'updated');
}

export default Container;
