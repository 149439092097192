import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ChevronRight, colors, M } from '@dashboard-experience/mastodon';

type Props = {
  actionText?: ReactNode;
  descriptionText?: ReactNode;
  disabled?: boolean;
};

const Container = styled.div<Props>`
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${colors.brandSlate3};
  display: flex;
  justify-content: space-between;
  padding: 1em;
  color: ${({ disabled }) => (disabled ? colors.uiGrey600 : colors.uiGrey800)};
`;

const Flex = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-right: 1em;
  > * {
    width: 1em;
    height: 1em;
    fill: ${colors.uiGrey800};
  }
`;

const StatusIcon = styled(M.StatusIcon)`
  margin-right: 0.5em;
`;

const ExampleRecord: React.FC<Props> = ({
  actionText,
  descriptionText,
  disabled,
}) => {
  return (
    <Container
      id='assess-v2-ui-home-settings-eligible-records-example-record'
      disabled={disabled}
    >
      <Flex>
        <IconContainer>
          <ChevronRight size={32} />
        </IconContainer>
        {!disabled && <StatusIcon icon='eligible' />}
        {descriptionText}
      </Flex>
      <div>{actionText}</div>
    </Container>
  );
};

export default ExampleRecord;
