import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { InputGroup } from '../AccountSettings';

export const IdpSubmitInputGroup = styled(InputGroup)`
  padding: 1rem;
  justify-content: flex-end;
  width: calc(100% - 2rem);
  display: flex;
  align-items: flex-end;
`;

export const IdpThinRow = styled(M.Container.Row)`
  padding: 10px;
`;
