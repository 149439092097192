/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { reloadDashboard } from 'utils';

const reloadReport = () => (dispatch, getState) => {
  const state = getState();
  const { ui = {} } = state;
  const { contextId } = ui;
  // Try to reload dashboard to refresh the report object
  // if we aren't in the iframe, reload customer so we trigger a fresh fetch.
  if (!(contextId && reloadDashboard())) {
    window.location.reload();
  }
};

export default reloadReport;
