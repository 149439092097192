import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Screening } from 'types';
import { M, colors } from '@dashboard-experience/mastodon';
import { isCandidateReportURL } from 'components/Packages/AddChecks/helper';
import { useLocation } from 'react-router-dom';
import AddedLabel from './AddedLabel';
import ScreeningType from '../enums/ScreeningType';
import { ssnDependentScreeningTypes } from './screeningPayloads';
import OrderedLabel from './OrderedLabel';

export const Button = styled(({ hasBasePackageScreenings, ...props }: any) => (
  <M.Button {...props} />
))`
  min-width: 80px;
  height: 40px;

  .mastodon-button.secondary {
    padding: 0.5rem 1.1rem !important;
  }

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export const DeleteButton = styled(Button)`
  justify-content: end;
  min-width: 40px;
  &:focus {
    border: none !important;
  }
  svg {
    fill: ${colors.brandNavy2} !important;
  }
`;

export const SetupButton = styled(M.Button)`
  min-width: 80px;
  height: 40px;
  background: ${colors.uiGrey0} !important;
  color: ${colors.brandNavy4} !important;
  border-color: ${colors.brandNavy4} !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  align-items: center;

  .mastodon-button.secondary {
    padding: 0.5rem 1.1rem !important;
  }

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export const hasAllBasicPackageScreenings = (
  basePackageScreeningTypes: ScreeningType[],
) => {
  const basicPackageScreenings: ScreeningType[] = [
    ScreeningType.ssn_trace,
    ScreeningType.global_watchlist_search,
    ScreeningType.sex_offender_search,
    ScreeningType.national_criminal_search,
  ];

  return Object.values(basicPackageScreenings).every(
    (screening: ScreeningType) => basePackageScreeningTypes.includes(screening),
  );
};

export const showAddedLabel = (
  screeningType: ScreeningType,
  usingAddChecks: boolean,
  disabledScreenings: ScreeningType[],
) => {
  const isStateOrCountyCrimScreening =
    screeningType === ScreeningType.county_criminal_search ||
    screeningType === ScreeningType.state_criminal_search;
  const showPreviouslyAddedInAddChecks =
    usingAddChecks &&
    !isStateOrCountyCrimScreening &&
    disabledScreenings.includes(screeningType);
  const showPreviouslyAddedInOthers =
    !usingAddChecks && disabledScreenings.includes(screeningType);
  return showPreviouslyAddedInOthers || showPreviouslyAddedInAddChecks;
};

export const isDisabledScreening = (
  screeningType: ScreeningType,
  basePackageScreeningTypes: ScreeningType[],
  disabledScreenings: ScreeningType[], // Need both basePackage & disabledScreenings
  previouslyOrderedScreenings: ScreeningType[],
  isStandaloneScreening = false,
  usingAddChecks = false,
) => {
  const currentBasePackageHasAllBasic = hasAllBasicPackageScreenings(
    basePackageScreeningTypes,
  );
  const previouslyOrderedHasAllBasic = hasAllBasicPackageScreenings(
    previouslyOrderedScreenings,
  );

  const isSsnDepedentType = ssnDependentScreeningTypes.includes(screeningType);
  const isStateOrCountyCrimScreening =
    screeningType === ScreeningType.county_criminal_search ||
    screeningType === ScreeningType.state_criminal_search;

  const shouldBeDisabledForAddChecks =
    !previouslyOrderedHasAllBasic &&
    !currentBasePackageHasAllBasic &&
    isSsnDepedentType &&
    !isStateOrCountyCrimScreening;

  if (
    usingAddChecks &&
    isStateOrCountyCrimScreening &&
    previouslyOrderedScreenings.includes(screeningType) &&
    !basePackageScreeningTypes.includes(screeningType)
  ) {
    return false;
  }

  if (usingAddChecks && shouldBeDisabledForAddChecks) {
    return true;
  }
  if (
    !usingAddChecks &&
    !currentBasePackageHasAllBasic &&
    !isStandaloneScreening &&
    isSsnDepedentType
  ) {
    return true;
  }

  return disabledScreenings.includes(screeningType);
};

const AddScreeningsAction: React.FC<any> = ({
  screeningType,
  onAddClick,
  onRemoveClick,
  disabledScreenings, // Includes screenings in base package (already been added)
  addedScreenings,
  basePackageScreenings = [],
  previouslyOrderedScreenings = [],
  isPopularAddon = false,
}) => {
  const location = useLocation();
  const usingAddChecks = useMemo(
    () => isCandidateReportURL(location.pathname),
    [location],
  );

  const standaloneScreeningType: ScreeningType =
    basePackageScreenings.length === 1 && basePackageScreenings[0].type;

  const isStandaloneScreening =
    (disabledScreenings as string[]).includes(standaloneScreeningType) &&
    standaloneScreeningType === screeningType;

  const currentBasePackageScreenings: ScreeningType[] =
    basePackageScreenings.map(
      (screening: Screening) => screening.type as ScreeningType,
    );

  const handleOnAddClick = useCallback(() => {
    const typeToAdd = [screeningType];
    onAddClick(typeToAdd);
  }, [screeningType, onAddClick]);

  const dataTestId = isPopularAddon
    ? `popular-${screeningType.replace('_', '-')}`
    : `${screeningType.replace('_', '-')}`;

  const isStateOrCountyCrimScreening =
    screeningType === ScreeningType.county_criminal_search ||
    screeningType === ScreeningType.state_criminal_search;

  const previouslyAdded = showAddedLabel(
    screeningType,
    usingAddChecks,
    disabledScreenings,
  );

  const ordered =
    usingAddChecks &&
    previouslyOrderedScreenings.includes(screeningType) &&
    !isStateOrCountyCrimScreening;

  const added = addedScreenings.includes(screeningType);

  const disabled = isDisabledScreening(
    screeningType,
    currentBasePackageScreenings,
    disabledScreenings,
    previouslyOrderedScreenings,
    isStandaloneScreening,
    usingAddChecks,
  );

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(screeningType);
  }, [onRemoveClick, screeningType]);

  // Used in Add Checks flow only
  if (ordered) {
    return <OrderedLabel dataTestId={dataTestId} />;
  }

  if (previouslyAdded) {
    return <AddedLabel />;
  }

  if (added) {
    return (
      <DeleteButton
        id='delete-trash-can'
        kind='tertiary'
        data-testid={`${dataTestId}-delete-button`}
        onClick={handleRemoveClick}
      >
        <M.Icon icon='TrashCan' />
      </DeleteButton>
    );
  }

  return (
    <Button
      kind='secondary'
      onClick={handleOnAddClick}
      disabled={disabled}
      data-testid={`${dataTestId}-add-button`}
    >
      <M.Icon icon='Add' /> Add
    </Button>
  );
};

export default AddScreeningsAction;
