import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useReport } from 'containers/Report';
import styled from 'styled-components';
import { usePostCustomerAction } from 'api/identity_verification';

const IdvDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  color: ${colors.uiGrey700};
`;

const IdvReasonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin-bottom: 0 !important;
  }
`;

const IdvReasonText = styled.p`
  color: ${colors.uiGrey900};
`;

const IdentityVerificationAction = () => {
  const report = useReport();
  const { postCustomerActionCall, postCustomerActionResult } =
    usePostCustomerAction();

  const idv = report.identity_verification;

  const handleCancel = useCallback(() => {
    if (!idv) {
      return;
    }
    const payload: { id: string; action: string } = {
      id: idv.id,
      action: 'cancel',
    };
    postCustomerActionCall(payload);
  }, [idv, postCustomerActionCall]);

  const handleContinue = useCallback(() => {
    if (!idv) {
      return;
    }
    const payload: { id: string; action: string } = {
      id: idv.id,
      action: 'continue',
    };
    postCustomerActionCall(payload);
  }, [idv, postCustomerActionCall]);

  if (
    !report ||
    !idv ||
    idv.outcome === 'passed' ||
    idv.customer_action ||
    report.status === 'complete'
  ) {
    return null;
  }

  return (
    <M.ActionableNotification
      hideActionButton
      inline='classic'
      kind='warning'
      title='Identity verification: risk detected'
      style={{ maxWidth: '100%' }}
      hideCloseButton
    >
      <IdvDetailContainer>
        {idv.outcome_reasoning && (
          <IdvReasonContainer>
            <p>Reason: </p>
            <IdvReasonText>{idv.outcome_reasoning}</IdvReasonText>
          </IdvReasonContainer>
        )}
        <div>
          <strong>Do you want to move forward with this candidate?</strong> If
          you cancel, you will only be billed for identity verification.
        </div>
        <div>
          <M.Button
            kind='secondary'
            onClick={handleCancel}
            disabled={postCustomerActionResult.isLoading}
          >
            Cancel order
          </M.Button>
          <M.Button
            kind='secondary'
            onClick={handleContinue}
            disabled={postCustomerActionResult.isLoading}
          >
            Begin background check
          </M.Button>
        </div>
      </IdvDetailContainer>
    </M.ActionableNotification>
  );
};

export default IdentityVerificationAction;
