import StyledComponent from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const LoadingSpinner = StyledComponent(M.LoadingSpinner).attrs({
  withOverlay: false,
  small: true,
})`
  height: 16px;
  width: 16px;
`;

export const SpinnerContainer = StyledComponent.div`
  padding: 10px 16px;
`;
