import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import Pill from './Pill';
import Description from './Description';
import Title from './Title';

type Props = {
  option: Option;
  showSettings?: boolean;
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background: ${colors.brandSlate1};
  border-radius: 8px 8px 0px 0px;

  // text formatting
  font-style: normal;
`;

export const Container: React.FC<Props> = ({ option, showSettings }) => {
  return (
    <StyledDiv data-testid='assess-v2-ui-lite-common-header-container'>
      <Pill option={option} />
      <Title option={option} />
      <Description option={option} showSettings={showSettings} />
    </StyledDiv>
  );
};

export default Container;
