import React from 'react';
import { AdversableItem } from 'modules/adjudication/data';
import { useModalState } from 'modules/adjudication/ui/adverse-action/initiate/flow/context';
import AdverseItem from './adverse-item';

type Props = {
  adversibleItems: AdversableItem[];
  isAssessEnabled?: boolean;
  isAssessedWithMvrAssessRules?: boolean;
};

const AdverseItemsList: React.FC<Props> = ({
  adversibleItems,
  isAssessEnabled,
  isAssessedWithMvrAssessRules,
}) => {
  const { adverseItems } = useModalState('state');

  return (
    <div className='list-container'>
      {adversibleItems?.map((item: AdversableItem) => {
        const selected = adverseItems.some(
          (selected: AdversableItem) =>
            selected.fingerprint === item.fingerprint,
        );
        return (
          <AdverseItem
            key={item.fingerprint}
            item={item}
            isAssessEnabled={isAssessEnabled}
            isAssessedWithMvrAssessRules={isAssessedWithMvrAssessRules}
            selected={selected}
          />
        );
      })}
    </div>
  );
};

export default AdverseItemsList;
