import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { colors } from '@dashboard-experience/mastodon';
import AddButton from './add-button';
import CategoryNameInput from '../category-name-input';

const NewCategoryContainer = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  height: 3em;
  max-width: 40em;
  padding: 0.6em;
`;

type Props = {
  type: ItemType;
};

const NewCategory: React.FC<Props> = ({ type }) => {
  const [isFocused, setIsFocused] = useState(false);
  const focusOn = useCallback(() => setIsFocused(true), [isFocused]);
  const focusOut = useCallback(() => setIsFocused(false), [isFocused]);

  return (
    <NewCategoryContainer data-testid='assess-mvr-category-set-version-new-category'>
      {isFocused ? (
        <CategoryNameInput focusOut={focusOut} type={type} />
      ) : (
        <AddButton onClick={focusOn} />
      )}
    </NewCategoryContainer>
  );
};

export default NewCategory;
