import React from 'react';
import { mapZipcodeToState } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../../types';

const Location: React.FC<FieldComponentProps> = ({ candidate, testid }) => {
  const stateName = mapZipcodeToState(candidate.zipcode!).state_name || '-';

  return <div data-testid={testid}>{stateName}</div>;
};

export default Location;
