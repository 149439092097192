import React from 'react';
import Description from './Description';
import HomeHeader from './Header';

const Container: React.FC = () => {
  return (
    <>
      <HomeHeader />
      <Description />
    </>
  );
};

export default Container;
