import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import {
  useCities,
  useCreateTrackedModal,
  useGetTrackedModals,
} from 'api/accounts';
import { useUpdateAccountSettings } from 'components';
import { states } from 'utils';
import {
  GenericObject,
  accountHasPermission,
} from '@dashboard-experience/utils';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useModal } from '../../providers/Modal';

const Row = styled(M.GridRow)`
  margin-top: 1rem;
`;

type Props = {
  showModal: boolean | null;
  setShowModal: (showModal: boolean) => void;
  setGeoModalSubmitted?: (geoModalSubmitted: boolean) => void;
};

const DefaultComplianceModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  setGeoModalSubmitted,
}) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const account = currentUser?.account;
  const accountId = account?.id;

  const modalContext = useModal();

  const { call: updateAccountCall, result: updateAccountResult } =
    useUpdateAccountSettings();
  const {
    isLoading: updateAccountIsLoading,
    isSuccess: updateAccountIsSuccess,
  } = updateAccountResult;

  const { data: trackedModals } = useGetTrackedModals({
    accountId,
  });
  const { createTrackedModalCall, createTrackedModalResult } =
    useCreateTrackedModal();
  const { isSuccess: createTrackedModalIsSuccess } = createTrackedModalResult;

  /**
   * @name getInitialModalState
   * @function
   * @memberOf DefaultComplianceModal
   * @description Determines if the modal should be presented
   * based on account attributes.
   * @returns {boolean}
   */
  const getInitialModalState = () => {
    if (
      account?.purpose === 'employment' &&
      !account?.default_compliance_city &&
      currentUser.permissions?.manage_account_settings
    ) {
      modalContext.setModalState({
        ...modalContext,
        prompt_compliance: true,
      });

      const show =
        !trackedModals?.some(
          (t: GenericObject) => t.name === 'default_location',
        ) && !accountHasPermission(currentUser, 'is_self_service_account'); // Do not show if self service

      return show;
    }
    return false;
  };

  const updateModalContext = (value: boolean) => {
    modalContext.setModalState({
      ...modalContext,
      prompt_compliance: value,
    });
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
    updateModalContext(false);
  }, []);

  useEffect(() => {
    if (
      account &&
      currentUser &&
      trackedModals &&
      setShowModal &&
      showModal === null
    ) {
      setShowModal(getInitialModalState());
    }
  }, [account, currentUser, trackedModals, setShowModal, showModal]);

  const statesList = Object.entries(states);
  const [selectedState, setSelectedState] = useState('');

  const { data: cityList } = useCities();
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const filtered = cityList?.filter(
      (city: GenericObject) => city.state === selectedState,
    );
    setFilteredCities(filtered);
  }, [cityList, selectedState]);

  const onStateSelect = useCallback(e => {
    const selectedStateCode = e.target?.value;
    setSelectedState(selectedStateCode);
  }, []);

  const onCitySelect = useCallback(e => {
    const selectedCityName = e.target?.value;
    setSelectedCity(selectedCityName);
  }, []);

  const submit = useCallback(async () => {
    const { account } = currentUser;
    const changedSettings = {
      default_compliance_state: selectedState !== '' ? selectedState : null,
      default_compliance_city: selectedCity !== '' ? selectedCity : null,
    };
    updateAccountCall({ changedSettings, account });
  }, [currentUser, selectedCity, selectedState, updateAccountCall]);

  useEffect(() => {
    // update tracked modals only if account is updated successfully.
    if (updateAccountIsSuccess) {
      createTrackedModalCall({ accountId, name: 'default_location' });
      if (setGeoModalSubmitted) {
        setGeoModalSubmitted(true);
      }
    }
  }, [
    accountId,
    createTrackedModalCall,
    setGeoModalSubmitted,
    updateAccountIsSuccess,
  ]);

  useEffect(() => {
    // close the modal if both account and tracked modals are updated successfully
    if (createTrackedModalIsSuccess && updateAccountIsSuccess) {
      closeModal();
    }
  }, [createTrackedModalIsSuccess, updateAccountIsSuccess, closeModal]);

  useEffect(() => {
    if (account?.default_compliance_state) {
      setSelectedState(account?.default_compliance_state);
    }
    if (account?.default_compliance_city) {
      setSelectedCity(account?.default_compliance_city);
    }
  }, [
    account?.default_compliance_city,
    account?.default_compliance_state,
    setSelectedCity,
    setSelectedState,
  ]);

  return (
    <M.ComposedModal
      onClose={closeModal}
      open={showModal}
      data-testid='default-compliance-modal'
    >
      <M.ModalHeader closeModal={closeModal}>
        <h2>{t('default_compliance_modal.heading')}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p className='p2 bold' style={{ paddingRight: '0' }}>
          {t('default_compliance_modal.sub_heading')}
        </p>
        <p>{t('default_compliance_modal.description')}</p>
        <M.Grid>
          <Row>
            <M.Select
              id='employment_state'
              data-testid='employment_state'
              labelText={t('default_compliance_modal.state_select_label')}
              onChange={onStateSelect}
              disabled={updateAccountIsLoading}
              value={selectedState}
            >
              <M.Select.Item
                value=''
                text={t('default_compliance_modal.default_option')}
              />
              {statesList.map(stateArray => {
                const stateCode = stateArray[0];
                const stateName = stateArray[1];
                return (
                  <M.Select.Item
                    key={stateCode}
                    value={stateCode}
                    text={stateName}
                  />
                );
              })}
            </M.Select>
          </Row>
          {filteredCities && (
            <Row>
              <M.Select
                id='employment_city'
                data-testid='employment_city'
                labelText={t('default_compliance_modal.city_select_label')}
                disabled={
                  !selectedState ||
                  !filteredCities.length ||
                  updateAccountIsLoading
                }
                onChange={onCitySelect}
                value={selectedCity}
              >
                <M.Select.Item
                  value=''
                  text={t('default_compliance_modal.default_option')}
                />
                {filteredCities.map((cityObj: GenericObject) => {
                  const { name: city } = cityObj;
                  return <M.Select.Item key={city} value={city} text={city} />;
                })}
              </M.Select>
            </Row>
          )}
        </M.Grid>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button
          type='submit'
          onClick={submit}
          disabled={updateAccountIsLoading}
          data-testid='default-compliance-modal-save-button'
        >
          {t('verbs.save')}
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default DefaultComplianceModal;
