import React from 'react';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api/context';
import Status from '../../common/entity/status';
import { useState } from './store';

type Props = {};

export const StatusContainer: React.FC<Props> = () => {
  const ruleset = useRuleset();
  const state = useState();

  const active = ruleset?.data?.active;
  const ruleset_id = ruleset?.data?.id;
  const assigned = !!ruleset?.data?.assignments?.length;
  const latest =
    state.kind === 'draft' ||
    (typeof active === 'string' ? active : active?.id) === state.id;
  const time = state.published_at ?? state.updated_at;
  const user = state.published_by ?? state.updated_by;
  const name = typeof user === 'object' ? user.name || user.email : user;
  const version = state.number;

  return (
    <Status
      id={ruleset_id}
      assigned={assigned}
      kind={state.kind}
      latest={latest}
      time={time}
      user={name}
      version={version}
    />
  );
};

export default StatusContainer;
