import React from 'react';
import { UserParams, useCreateUser } from 'api/i9';
import { IUser } from '../Models';

const useSubmitAddUser = (successCallback: () => void) => {
  const { postUserCreatedCall } = useCreateUser(successCallback);
  const submit = React.useCallback(
    (userFormValues: IUser) => {
      const { firstName, lastName, email } = userFormValues;
      const userObject: UserParams = {
        params: {
          first_name: firstName,
          last_name: lastName,
          email,
        },
      };

      postUserCreatedCall(userObject);
    },
    [postUserCreatedCall],
  );

  return submit;
};

export default useSubmitAddUser;
