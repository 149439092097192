import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';

type Props = {
  onChange: (e: Entity.AutoEngageOptions) => void;
  selected: boolean;
  value: Entity.AutoEngageOptions;
  disabled: boolean;
};

const Description = styled.div`
  font-size: 14px;
  margin-top: 0.2rem;
  margin-left: 1.75rem;
`;

const Option: React.FC<Props> = ({ onChange, selected, value, disabled }) => {
  const dataTestId = `assess-v2-settings-auto-engage-options-${value.toLowerCase()}`;
  const { t } = useTranslation('assess:v2');
  const base = `home.settings.assess_auto_engage_eligible_reports_options.${value.toLowerCase()}`;
  const title = t(`${base}.title`);
  const description = t(`${base}.description`);

  return (
    <div data-testid={dataTestId}>
      <M.RadioButton
        data-testid={`${dataTestId}-radio`}
        checked={selected}
        value={value}
        onChange={onChange}
        labelText={title}
        disabled={disabled}
      />
      <Description>
        <Trans>{description}</Trans>
      </Description>
    </div>
  );
};

export default Option;
