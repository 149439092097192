import { useGetReport } from 'api/reports';
import { useReportTasksAction } from 'modules/adjudication/api';
import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import styled from 'styled-components';
import { namespace } from 'modules/adjudication/locales';
import { t } from 'i18next';
import ReportTasks from './constants';

const StyledToggle = styled(M.Toggle)`
  margin-top: 1rem;
  .cds--toggle__text {
    font-weight: bold;
  }
  > * {
    justify-content: flex-start !important;
  }
`;

const ActionRequired: React.FC = () => {
  const { report } = useGetReport();
  const { report_tasks } = report;
  const { ActionRequired } = ReportTasks;

  const task = report_tasks.find(({ kind }: { kind?: string }) => {
    return kind === ActionRequired;
  });

  const [actionRequiredTask, setActionRequiredTask] = useState(task);
  const { call } = useReportTasksAction(
    report.id,
    setActionRequiredTask,
    actionRequiredTask,
  );

  const handleUpdate = useCallback(() => {
    call();
  }, [call]);

  const toggled = !!actionRequiredTask?.value;
  const needsFollowUpTranslation = `${namespace}:report.actions.needs_follow_up`;

  let actionRequiredTaskInfo = null;
  if (toggled && task) {
    const { adjudicator, updated_at } = task;
    const date = moment(updated_at).format('MMM D, YYYY');
    actionRequiredTaskInfo = (
      <div>
        <br />
        <span data-testid='action-required-task-info'>
          {t(`${needsFollowUpTranslation}.task_info`, {
            email: adjudicator?.email,
            date,
          })}
        </span>
      </div>
    );
  }

  return (
    <div data-testid='action-required-report-task'>
      <StyledToggle
        id='action-required-toggle-id'
        data-testid='action-required-toggle'
        toggled={toggled}
        toggledText={t(`${needsFollowUpTranslation}.label`)}
        untoggledText={t(`${needsFollowUpTranslation}.label`)}
        onToggle={handleUpdate}
        disabled={report.archived}
      />
      {actionRequiredTaskInfo}
    </div>
  );
};

export default ActionRequired;
