import { Namespace } from 'modules/assess/api';
import Kind from 'modules/assess/models/rules/common/kind';
import * as Entity from 'modules/assess/models/rules/entity';
import React from 'react';
import Container from '../container';
import TextSections from '../text-sections';
import { useSearch } from './api/hooks';
import Table from './table';

export type Props = {
  handleAddClick: Function;
  kind: Kind;
  namespace?: Namespace;
};

export const Content: React.FC<Props> = ({
  namespace,
  kind,
  handleAddClick,
}) => {
  const searchRules = useSearch(namespace, kind);
  const data: Entity.List = searchRules?.data?.data || [];

  if (data.length === 0) {
    return null;
  }

  return (
    <Container data-testid='assess-rules-library-content'>
      <TextSections namespace={namespace} tkey='rules.library.description' />
      <Table handleAddClick={handleAddClick} data={data} />
    </Container>
  );
};

export default Content;
