import moment from 'moment/moment';
import { GenericObject } from '@dashboard-experience/utils';
import { TimelineEvent } from './types';

/**
 * @name deduplicateCommunicatorEvents
 * @function
 * @memberOf Timeline
 * @description This method removes duplicate events coming from
 *              Communicator service with a 15-min window of
 *              tolerance between same-looking events.
 * @returns {array} - The deduped timeline
 * @param {array} timeline - The timeline array
 */
export const deduplicateCommunicatorEvents = (timeline: TimelineEvent[]) => {
  const communicatorEvents: GenericObject = {};
  const deduped: TimelineEvent[] = [];
  timeline.forEach((event: TimelineEvent) => {
    let eventIsUnique = false;
    if (event.type !== 'communicator') {
      // we only care about communicator events
      eventIsUnique = true;
    } else {
      // set an identifier for this general event type
      const eventName = `${event.campaign_id}_${event.event}_${event.recipient}`;
      if (!communicatorEvents[eventName]) {
        // we haven't tracked this event type before, store it and the event time
        // for later comparison should we encounter the event type again, and
        // mark this event as unique
        communicatorEvents[eventName] = event.time;
        eventIsUnique = true;
      } else {
        // we have encountered this event type before, so we need to dig deeper
        // and do a comparison of time to see if it's within our 15 min window
        const previousEventTime = communicatorEvents[eventName];
        const timeDelta = moment
          .duration(moment(previousEventTime).diff(moment(event.time)))
          .asMinutes();
        if (timeDelta > 15) {
          // the delta is greater than 15 min so we'll go ahead and track this as
          // the latest event of its type, as well as mark the event as unique
          communicatorEvents[eventName] = event.time;
          eventIsUnique = true;
        }
      }
    }
    if (eventIsUnique) {
      // push the event if it's unique
      deduped.push(event);
    }
  });
  return deduped;
};

export const filterTimeline = (timeline: TimelineEvent[]) => {
  // filter the timeline for nps
  const npsCompletedScreeningKeys = [
    'StatefulSsnTrace',
    'MatrixNationalCriminalSearch',
  ];
  let filteredTimeline = timeline;
  npsCompletedScreeningKeys.map(key => {
    const completedScreening = timeline.filter(
      event => event.klass === key && event.to === 'complete',
    );
    if (completedScreening.length) {
      filteredTimeline = filteredTimeline.filter(
        event => event.klass === undefined || event.klass !== key,
      );
    }
    return key;
  });
  return filteredTimeline;
};
