import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

const InputNumericValues = ({ index }: { index: number }) => {
  const { conditions, updateConditionByIndex } = useConditions();
  const { t } = useTranslation();
  const condition = conditions[index];
  const label = t('inputs.number');

  const handleChange = useCallback(
    (e: any) => {
      const newValue = e.target.value;
      const value = newValue ? parseInt(newValue, 10) : undefined;
      updateConditionByIndex(index, { value });
    },
    [index, updateConditionByIndex],
  );

  return (
    <M.NumberInput
      data-testid='assess-common-rule-input-numeric-value'
      allowEmpty
      defaultValue={condition.value || ''}
      max={100}
      min={0}
      placeholder={label}
      onChange={handleChange}
      hideSteppers
    />
  );
};

export default InputNumericValues;
