import React from 'react';
import Button from 'components/Button';

export type Props = {
  onClose: () => void;
};

export const Footer: React.FC<Props> = ({ onClose }) => {
  return <Button kind='secondary' trans='verbs.close' onClick={onClose} />;
};

export default Footer;
