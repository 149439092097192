import React from 'react';
import styled from 'styled-components';
import Humanize from 'humanize-plus';
import { M, colors } from '@dashboard-experience/mastodon';
import { AssessStatuses, StatusTypes } from '@dashboard-experience/utils';
import { Table } from '../../../../components/Table';
import * as Ruleset from '../../models/rulesets/ruleset';

type Column = keyof Ruleset.Stats | 'total';

type Props = {
  stats?: Ruleset.Stats;
};

const columns: Array<Column> = [
  'total',
  'num_clear_reports',
  'num_eligible_reports',
  'num_review_reports',
  'num_escalated_reports',
];

const StyledHeader = styled.div`
  color: ${colors.uiTextSecondaryLight};
`;

const headers = {
  total: <StyledHeader className='p3 bold'>Total Reports</StyledHeader>,
  num_clear_reports: (
    <M.StatusBadge
      status={AssessStatuses.CLEAR}
      statusType={StatusTypes.Assess}
    />
  ),
  num_eligible_reports: (
    <M.StatusBadge
      status={AssessStatuses.ELIGIBLE}
      statusType={StatusTypes.Assess}
    />
  ),
  num_review_reports: (
    <M.StatusBadge
      status={AssessStatuses.REVIEW}
      statusType={StatusTypes.Assess}
    />
  ),
  num_escalated_reports: (
    <M.StatusBadge
      status={AssessStatuses.ESCALATED}
      statusType={StatusTypes.Assess}
    />
  ),
};

export const RulesetStats: React.FC<Props> = ({ stats }) => {
  const {
    num_clear_reports = 0,
    num_eligible_reports = 0,
    num_review_reports = 0,
    num_escalated_reports = 0,
    total_reports = 0,
  } = stats || ({} as Ruleset.Stats);

  const formatValue = (value: number, total: number) => {
    const percent: string = total
      ? Humanize.toFixed((100 * value) / total, 0)
      : '0';
    return (
      <>
        {Humanize.formatNumber(value)}&nbsp; (<span>{percent}%</span>)
      </>
    );
  };
  const data = [
    {
      id: '0',
      total: Humanize.formatNumber(total_reports),
      num_clear_reports: Humanize.formatNumber(num_clear_reports),
      num_eligible_reports: formatValue(num_eligible_reports, total_reports),
      num_review_reports: formatValue(num_review_reports, total_reports),
      num_escalated_reports: formatValue(num_escalated_reports, total_reports),
    },
  ];
  const Container = styled.div`
    #assess-ruleset-stats h5 {
      margin: 0;
    }
    #assess-ruleset-stats th {
      border-top: none;
    }
    #assess-ruleset-stats th,
    #assess-ruleset-stats td {
      padding: 0 2em 0 0;
      text-align: left;
      white-space: pre;
    }
    #assess-ruleset-stats td span {
      font-weight: bold;
    }
  `;
  return (
    <Container>
      <div id='assess-ruleset-stats'>
        <h5>Reports run</h5>
        <Table
          data-testid='assess-rulesets-stats'
          columns={columns}
          headers={headers}
          rows={data}
        />
      </div>
    </Container>
  );
};

export default RulesetStats;
