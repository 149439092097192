import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { usePostHierarchy } from 'api/hierarchy/hooks';
import styled from 'styled-components';
import ConfirmModal from '../../ConfirmModal';

type Props = {
  account: any;
  rows: any;
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  areCustomIdsUnique: boolean;
};

const ButtonStyled = styled(M.Button)`
  float: right;
  margin: 0 !important;
  font-weight: normal !important;
  margin-top: 50px !important;
`;

export const SubmitButton: React.FC<Props> = ({
  account,
  rows,
  modified,
  setModified,
  areCustomIdsUnique,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const { postHierarchyCall } = usePostHierarchy();

  const UPDATE_HIERARCHY_MESSAGE =
    'Are you sure you want to submit this hierarchy? Any empty rows will be deleted.';
  const CREATE_HIERARCHY_MESSAGE =
    'Are you sure you want to submit this hierarchy? This action cannot be undone and going forward there must be at least one node on the account.';

  const submitMessage = account.hierarchy_present
    ? UPDATE_HIERARCHY_MESSAGE
    : CREATE_HIERARCHY_MESSAGE;

  const showModal = useCallback(() => {
    setConfirmModal(true);
  }, [setConfirmModal]);

  const hideModal = useCallback(() => {
    setConfirmModal(false);
  }, [setConfirmModal]);

  const submitHierarchy = useCallback(() => {
    postHierarchyCall(rows);
    setModified(false);
    hideModal();
  }, [postHierarchyCall, setModified, hideModal, rows]);

  const isSubmitDisabled = rows.some(
    (row: { id: any; name: any }) => !row.id || !row.name,
  );

  let errorMessage = '';
  if (isSubmitDisabled) {
    errorMessage = 'Every row must have a Custom ID and a Name';
  } else if (!areCustomIdsUnique) {
    errorMessage = 'All Custom IDs must be unique';
  }

  return (
    <>
      <ButtonStyled
        kind='achievement'
        title={errorMessage}
        onClick={showModal}
        data-testid='submitHierarchy'
        disabled={!modified || isSubmitDisabled || !areCustomIdsUnique}
      >
        Submit
      </ButtonStyled>
      <ConfirmModal
        header='Submit Hierarchy'
        open={confirmModal}
        hideModal={hideModal}
        callback={submitHierarchy}
        message={submitMessage}
        cancelMessage='Cancel'
        confirmMessage='Yes'
      />
    </>
  );
};

export default SubmitButton;
