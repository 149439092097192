import { updateRealtimeSubscription, useSetupRealtime } from 'api/realtime';
import { RealtimeStatuses, SubscriptionProps } from 'api/realtime/types';
import { useCallback, useEffect, useState } from 'react';

/**
 * A React Hook used for sending/receiving Realtime data, for a given room.
 * @param {SubscriptionProps} params - Details on what room to subscribe to, using an ID and a Type
 * @param {string} params.roomID - The unique identifier for the specific resource type (e.g. a report ID)
 * @param {string} params.roomType - The type of resource being subscribed to (e.g. Report, or Candidate, or Account )
 * @returns The running Realtime data
 */
const useRealtime = ({
  roomID,
  roomType,
  updateCallback,
}: SubscriptionProps & { updateCallback: Function }) => {
  const [realtimeStatus, setRealtimeStatus] = useState<RealtimeStatuses>(
    RealtimeStatuses.IDLE,
  );

  const {
    primus,
    status: primusStatus,
    roomViewers,
  } = useSetupRealtime(updateCallback); // Connect to the Realtime server (opening up a websocket connection)

  const sendCommand = useCallback(
    (roomType, roomID) => {
      updateRealtimeSubscription({
        primusConnection: primus,
        command: 'subscribe',
        roomType,
        roomID,
      });
    },
    [primus],
  );

  useEffect(() => {
    // Aside from READY and ACTIVE, do a 1:1 translation of statuses
    if (
      primusStatus !== RealtimeStatuses.READY &&
      primusStatus !== RealtimeStatuses.ACTIVE
    ) {
      setRealtimeStatus(primusStatus);
    }
    // If Primus is considered ready...
    else if (primusStatus === RealtimeStatuses.READY) {
      // AND we have room details - then subscribe to the room and consider us ACTIVE
      if (roomID && roomType) {
        if (realtimeStatus !== RealtimeStatuses.ACTIVE) {
          sendCommand(roomType, roomID);
          setRealtimeStatus(RealtimeStatuses.ACTIVE);
        }
      }
      // If primus is ready but we DON'T have room details - then consider realtime to be READY and waiting
      else {
        setRealtimeStatus(RealtimeStatuses.READY);
      }
    }
    // If Realtime (not just Primus) was previously considered to be active, but now we no longer have room details, then dial back to READY and waiting
    else if (
      realtimeStatus === RealtimeStatuses.ACTIVE &&
      (!roomID || !roomType)
    ) {
      setRealtimeStatus(RealtimeStatuses.READY);
    }
  }, [primus, primusStatus, realtimeStatus, roomID, roomType, sendCommand]);

  return {
    realtimeStatus,
    roomViewers,
  };
};

export default useRealtime;
