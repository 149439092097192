/* eslint-disable react/jsx-no-bind */
import { PR_US_STATE_KEY } from 'Constants';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { US_STATES } from '../../OrderBackgroundCheck/GetStarted/StatesOfUS';
import { Dropdown, Item } from '../../OrderBackgroundCheck/SharedItems';

const PR = { name: 'Puerto Rico', abbreviation: 'PR', id: 'PR' };

const StateDropdown = styled(Dropdown)`
  margin-right: 20px;
`;

// convert state object to string
const stateItemToString = (item: Item) => item?.name;

const SelectState: React.FC<any> = ({
  states = US_STATES,
  disabled = false,
  setSelectedState,
  selectedState,
}) => {
  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;

  // Use base package to see whether the user has come from the review & submit page
  const selectableStates = useMemo(
    () => (withPr ? [...states.slice(0, 39), PR, ...states.slice(39)] : states),
    [withPr, states],
  );

  // handle state change
  const handleStateChange = (selectedItem: any) => {
    setSelectedState(selectedItem.selectedItem);
  };

  return (
    <StateDropdown
      data-testid='state-dropdown-testid'
      label='State (Required)'
      items={selectableStates}
      disabled={disabled}
      itemToString={stateItemToString}
      onChange={handleStateChange}
      selectedItem={selectedState.name ? selectedState : null}
    />
  );
};

export default SelectState;
