import postMessageToDashboard from 'utils/PostMessageToDashboard';

const scrollToTop = () => {
  postMessageToDashboard({
    messageId: 'scroll_to_top',
  });
};

export const scrollToTopIfIframe = () => {
  if (window.self !== window.top) {
    scrollToTop();
  }
};

export const scrollToTopWithCallback = cb => () => {
  cb();
  scrollToTop();
};

export default scrollToTop;
