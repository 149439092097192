/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { M } from '@dashboard-experience/mastodon';

import { editUser } from 'actions/AccountUsersActions';
import {
  getMultiSelectRoles,
  getRoleEditRequestByUser,
} from 'reducers/Users.reducer';

import { EditRoleCellContent, UsersTableCell } from './UsersStyledComponents';

class EditRoleCell extends PureComponent {
  getInlineLoadingStatus = ({ processing, success, error }) => {
    if (processing) {
      return 'active';
    }
    if (success) {
      return 'finished';
    }
    if (error) {
      return 'error';
    }
    return null;
  };

  triggerEditUser = ({ selectedItems }) => {
    const { user, editUser: edit } = this.props;
    edit(
      user.id,
      selectedItems.map(role => role.label),
    );
  };

  render() {
    const { currentUser, multiSelectRoles, roleEditRequest, user } = this.props;
    const initialRoles = user.roles.map(r => ({ id: r, label: r }));
    return (
      <UsersTableCell>
        <EditRoleCellContent>
          {roleEditRequest.processing ? (
            <span>Editing roles...</span>
          ) : (
            <>
              <div className='roles'>{user.roles.join(', ')}</div>
              {(currentUser.permissions.manage_account_users ||
                currentUser.permissions.ams_manage_account_non_admin_users) && (
                <M.MultiSelect
                  className='multiselect'
                  id='role-dropdown'
                  compact
                  label='Edit'
                  onChange={this.triggerEditUser}
                  initialSelectedItems={initialRoles}
                  items={multiSelectRoles}
                  selectionFeedback='fixed'
                  direction='bottom'
                />
              )}
            </>
          )}
          <M.LoadingInline
            className='loading'
            status={this.getInlineLoadingStatus(roleEditRequest)}
            description={roleEditRequest.errorMsg}
          />
        </EditRoleCellContent>
      </UsersTableCell>
    );
  }
}

EditRoleCell.propTypes = {
  user: PropTypes.object,
  currentUser: PropTypes.object,
  editUser: PropTypes.func,
  multiSelectRoles: PropTypes.array.isRequired,
  roleEditRequest: PropTypes.object,
};

EditRoleCell.defaultProps = {
  currentUser: {},
  editUser: () => {},
  roleEditRequest: {},
  user: {},
};

const mapStateToProps = (state, props) => {
  return {
    roleEditRequest: getRoleEditRequestByUser(state, props),
    multiSelectRoles: getMultiSelectRoles(state),
    currentUser: state.currentUser.currentUser,
  };
};

const mapDispatchToProps = {
  editUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleCell);
