/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { AnyQueryKey, useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as API from 'api';
import { setGlobalState } from 'actions';

type Params = {
  [key: string]: any;
};

const INVITATION = 'invitation';

const getData = (params: Params) => {
  const {
    account: { id: accountId, hierarchy_present },
    account,
  } = params;

  return Promise.all([
    API.geos.list({ accountId }),
    API.packages.list({ accountId }),
    API.programs.list({ accountId }),
    hierarchy_present && API.nodes.fetchNode({ account }),
    getBillingData(accountId),
  ]);
};

const getBillingData = (accountId: string) => {
  return API.payment.fetchBillingAddress(accountId).catch(error => {
    if (error.response.status === 404) {
      return null;
    }
    throw error;
  });
};

// eslint-disable-next-line import/prefer-default-export
export const useNewInvitations = (params: Params) => {
  const key: AnyQueryKey = [
    'new_invitations',
    {
      id: params?.account?.id,
      hierarchy_present: params?.account?.hierarchy_present,
    },
  ];

  const request = () => getData(params);
  const dispatch = useDispatch();

  const res = useQuery(key, request, { refetchOnWindowFocus: false });
  const data = res?.data;
  const isLoading = res?.isLoading;
  const isError = res?.isError;
  const error = res?.error;

  const geos = data?.[0].geos;
  const node = data?.[3];
  const packages = data?.[1];
  const programs = data?.[2];
  const customer = data?.[4];
  const billing_entity_enabled = customer?.billing_entity_enabled || false;
  const payment_profiles = billing_entity_enabled
    ? [
        {
          name: 'Main Account',
          email: customer.email,
          address: customer.address,
        },
        ...customer.billing_entities,
      ]
    : [];

  const payload = useMemo(
    () => ({
      geos,
      node,
      packages,
      programs,
      payment_profiles,
      billing_entity_enabled,
    }),
    [geos, node, packages, programs, payment_profiles],
  );

  useEffect(() => {
    if (!isLoading && !isError) {
      node?.data?.forEach((n: any) => {
        // eslint-disable-next-line no-param-reassign
        if (n.id === undefined) n.id = [n.name, n.custom_id].join(', ');
      });

      // Add api data to global redux state on success
      dispatch(setGlobalState({ key: INVITATION, payload }));
    }
  }, [isLoading, isError, node, dispatch, payload]);

  return {
    ...payload,
    error,
    isError,
    isLoading,
  };
};
