/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { AnyQueryKey, useQuery } from 'react-query';
import { useUser } from 'context/CurrentUser';
import { getDashboard } from './actions';

export const useGetAnalyticsDashboard = ({
  lookerName,
  includePermutationArray = false,
}: {
  lookerName: string;
  includePermutationArray?: boolean;
}) => {
  const currentUser = useUser();
  const {
    account: { analytics_dashboards: accountAnalyticsConfig },
  } = currentUser;
  const key: AnyQueryKey = [
    'analytics/lookerDashboards',
    { dashboard_name: lookerName },
  ];
  const permutationArray: string[] = includePermutationArray
    ? Object.keys(accountAnalyticsConfig).filter(
        item => item.indexOf('mod_') === 0 && accountAnalyticsConfig[item],
      )
    : [];

  const request = () =>
    getDashboard({ lookerName, currentUser, permutationArray });

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const isLoading = result?.isLoading;
  const isError = result?.isError;

  const url = useMemo(() => result?.data?.url || '', [result?.data]) as string;

  return {
    url,
    isLoading,
    isError,
  };
};
