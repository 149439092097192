export enum License {
  COMMERCIAL = 'commercial',
  COMMERCIAL_PERMIT = 'commercial_permit',
  IDENTIFICATION = 'identification',
  MOTORCYCLE = 'motorcycle',
  MOTORCYCLE_PERMIT = 'motorcycle_permit',
  PASSENGER = 'passenger',
  PASSENGER_PERMIT = 'passenger_permit',
}

export enum LicenseStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  EXPIRED = 'expired',
}
