import { Client } from 'api';
import { GenericObject } from '../accountSettings/actions';

export interface Brand {
  logo_uri?: string;
  name: string;
  website?: string;
}

export interface BrandResponse {
  created_at: string;
  id: string;
  logo_uri: string;
  name: string;
  postal_address: string;
  updated_at: string;
  website: string;
}

export const fetchBrandObject = (brandId: string) => {
  return Client.get(`/brands/${brandId}`);
};

export const updateBrand = (brand: BrandResponse): Promise<GenericObject> => {
  return Client.put(`/brands/${brand.id}`, brand);
};

export const fetchBrandsData = (page: number = 1) => {
  return Client.get(`/brands?per_page=25&page=${page}`);
};

export const postBrand = (payload: Brand) => {
  return Client.post(`/brands`, payload);
};

export const deleteBrand = (brandId: string) => {
  return Client.delete(`/brands/${brandId}`);
};
