import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledComponents } from 'modules/assess/v2/ui/common';

const Title: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const titleText = t(`lite.title`);

  return (
    <StyledComponents.Title
      id='assess-v2-ui-lite-title'
      data-testid='assess-v2-ui-lite-title'
    >
      {titleText}
    </StyledComponents.Title>
  );
};

export default Title;
