/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React from 'react';
import { connect } from 'react-redux';

import SortedHeader, { SortDirections } from 'components/SortedHeader';
import * as searchActions from 'actions/SearchActions';

import {
  GenericObject,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { useUser } from '../../../context/CurrentUser';

const { ASC, DESC } = SortDirections;

type TableHeaderWithSortProps = {
  column: GenericObject;
  searchParams: GenericObject;
  updateSearchParams: Function;
  isDSP: boolean;
  headerStyle?: GenericObject;
};

const TableHeaderWithSort = ({
  column,
  searchParams,
  updateSearchParams,
  isDSP,
  headerStyle,
}: TableHeaderWithSortProps) => {
  const sortByColumn =
    searchParams.order_by === 'reports.completed_at'
      ? 'completed_at'
      : searchParams.order_by;

  const { header, key } = column;

  const isSortHeader = key === sortByColumn;

  const sortDirection = searchParams?.sort?.toUpperCase();
  const user = useUser();

  const trackEvent = useTrackEvent();

  /**
   * @name handleSortClick
   * @function
   * @memberOf TableHeaderWithSort
   * @description Refresh the search data if the sort button is clicked
   * @@param {string}
   */
  const handleSortClick = useDebouncedCallback(
    () => {
      const order_by = key === 'completed_at' ? 'reports.completed_at' : key;

      const sort = isSortHeader && sortDirection === DESC ? ASC : DESC;

      updateSearchParams({ order_by, sort });

      if (user) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_RESULTS_SORTED,
          {
            Column: order_by,
            'Sort Order': sort,
          },
        );
      }
    },
    350,
    [user, key, updateSearchParams, sortDirection, isSortHeader, trackEvent],
  );

  const label = header === 'Created' && isDSP ? 'Created on' : header;

  return (
    <SortedHeader
      isSortHeader={isSortHeader}
      sortDirection={sortDirection}
      onClick={handleSortClick}
      label={label}
    />
  );
};

TableHeaderWithSort.defaultProps = {
  column: {},
};

const mapStateToProps = (state: any) => {
  return {
    contextId: state.ui.contextId,
    currentUser: state.currentUser.currentUser,
    searchParams: state.search.searchParams,
  };
};

export default connect(mapStateToProps, searchActions)(TableHeaderWithSort);
