import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  AccountSettingsResponse,
  WorksiteResponse,
  useDeleteWorksite,
  useUpdateAccountSettings,
} from 'api/i9';
import ConfirmModal from 'components/ConfirmModal';

interface WorksiteActionsMenuProps {
  worksite?: WorksiteResponse;
  onWorksiteDeleted: () => void;
  onWorksiteEdited: (worksiteEditObj: any) => void;
  onChangeDefaultWorksite: () => void;
  settingsData?: AccountSettingsResponse;
}

const WorksiteActionsMenu: React.FC<WorksiteActionsMenuProps> = ({
  worksite,
  onWorksiteDeleted,
  onWorksiteEdited,
  onChangeDefaultWorksite,
  settingsData,
}) => {
  const handleEditClick = useCallback(() => {
    onWorksiteEdited({
      id: worksite?.id || '',
      name: worksite?.name || '',
      street_line1: worksite?.street_line1 || '',
      street_line2: worksite?.street_line2 || '',
      city: worksite?.city || '',
      state: worksite?.state || '',
      zip_code: worksite?.zip_code || '',
      everify_status: worksite?.everify_status || '',
    });
  }, [onWorksiteEdited, worksite]);

  const { accountSettingsUpdatedCall } = useUpdateAccountSettings();

  const handleMakeDefaultClick = useCallback(() => {
    accountSettingsUpdatedCall({
      params: {
        employer_name: settingsData?.employer_name || '',
        default_worksite_id: worksite?.id || '',
        everify_status: settingsData?.everify_status || 'deactivated',
      },
    }).then(() => {
      onChangeDefaultWorksite();
    });
  }, [
    accountSettingsUpdatedCall,
    onChangeDefaultWorksite,
    worksite?.id,
    settingsData?.employer_name,
    settingsData?.everify_status,
  ]);

  const { deleteWorksiteCall, deleteWorksiteResult } = useDeleteWorksite(
    worksite?.name || '',
  );

  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const handleRemoveClick = useCallback(() => {
    setRemoveModalOpen(true);
  }, [setRemoveModalOpen]);

  const handleRemoveModalCallback = useCallback(() => {
    deleteWorksiteCall(worksite?.id || '');
    setRemoveModalOpen(false);
  }, [worksite?.id, deleteWorksiteCall, setRemoveModalOpen]);

  const handleRemoveModalHide = useCallback(() => {
    setRemoveModalOpen(false);
  }, [setRemoveModalOpen]);

  useEffect(() => {
    if (deleteWorksiteResult.isSuccess) {
      onWorksiteDeleted();
    }
  }, [deleteWorksiteResult.isSuccess, onWorksiteDeleted]);

  const isDefaultWorksite = settingsData?.default_worksite_id === worksite?.id;

  return (
    <>
      {/* @ts-ignore */}
      <ConfirmModal
        header='Confirm worksite removal'
        message={
          <p>
            Are you sure you want to remove{' '}
            <strong>{worksite?.name || ''}</strong> from your list of worksites?
          </p>
        }
        confirmMessage='Yes, remove worksite'
        open={removeModalOpen}
        callback={handleRemoveModalCallback}
        hideModal={handleRemoveModalHide}
      />
      <M.OverflowMenu flipped hideTooltip>
        <M.OverflowMenuItem itemText='Edit' onClick={handleEditClick} />
        <M.OverflowMenuItem
          itemText='Make default'
          onClick={handleMakeDefaultClick}
          disabled={isDefaultWorksite}
        />
        <M.OverflowMenuItem
          itemText='Remove'
          onClick={handleRemoveClick}
          disabled={isDefaultWorksite}
        />
      </M.OverflowMenu>
    </>
  );
};

export default WorksiteActionsMenu;
