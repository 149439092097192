import React, { useCallback } from 'react';
import moment from 'moment';
import { M } from '@dashboard-experience/mastodon';
import { ButtonAsLink, StatusDropdown } from './AdverseActionsStyledComponents';
import { statusItemToParam, statusParamToItem } from './Statuses';

type Props = {
  onSearchUpdate: Function;
  resultsCount: number;
  state: {
    params: any;
    statuses: any;
  };
};

const AdverseActionsSearch: React.FC<Props> = ({
  onSearchUpdate,
  resultsCount = 0,
  state: { params, statuses },
}) => {
  const timezone = new Date().getTimezoneOffset();
  let dates = [params.created_after, params.created_before];
  dates = dates.map(d => d && new Date(d));
  dates.forEach(d => d && d.setMinutes(timezone));

  const handleSelect = useCallback(
    ({ selectedItem }) => {
      const updates = {
        page: 1,
        status: statusItemToParam(selectedItem),
      };

      onSearchUpdate(updates);
    },
    [onSearchUpdate],
  );

  const updateSearch = useCallback(
    (e: any) => {
      const updates = {
        full_name: e.currentTarget.value,
        page: 1,
      };

      if (updates.full_name === '') {
        updates.full_name = undefined;
      }

      onSearchUpdate(updates);
    },
    [onSearchUpdate],
  );

  const datePickerOnChange = useCallback(
    (e: Array<any>) => {
      const updates = {
        created_after: e[0] && moment(e[0]).format('YYYY-MM-DD'),
        created_before: e[1] && moment(e[1]).format('YYYY-MM-DD'),
        page: 1,
      };

      if (onSearchUpdate) {
        onSearchUpdate(updates);
      }
    },
    [onSearchUpdate],
  );

  const resetFilters = useCallback(() => onSearchUpdate(), [onSearchUpdate]);

  return (
    <M.Container
      id='adverse-actions-search'
      data-testid='adverse-actions-search'
    >
      {params && (
        <M.Container.Row>
          <M.Container.Col>
            <h3>Search</h3>
          </M.Container.Col>
        </M.Container.Row>
      )}
      <M.Container.Row>
        <M.Container.Col>
          {params && (
            <>
              <M.Input
                id='keyword-id'
                name='keyword'
                labelText='Keyword'
                placeholder='Enter name, email, phone, or ID'
                onChange={updateSearch}
                value={params.full_name || ''}
              />
              <br />
            </>
          )}
          <M.DatePicker
            id='date-picker'
            dateFormat='m/d/y'
            onChange={datePickerOnChange}
            datePickerType='range'
          >
            <M.DatePickerInput
              id='date-picker-input-id-start'
              labelText='From'
              placeholder='MM/DD/YY'
            />
            <M.DatePickerInput
              id='date-picker-input-id-end'
              labelText='To'
              placeholder='MM/DD/YY'
            />
          </M.DatePicker>
        </M.Container.Col>
        <M.Container.Col>
          {params && (
            <>
              <StatusDropdown
                data-testid='adverse-actions-search-status'
                items={statuses}
                titleText='Status'
                name='status'
                selectedItem={statusParamToItem(params.status)}
                onChange={handleSelect}
              />
              <br />
            </>
          )}
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          {!isNaN(resultsCount) && (
            <div style={{ display: 'flex' }}>
              <div
                style={{ marginRight: '1rem' }}
              >{`${resultsCount} results`}</div>
              <ButtonAsLink role='button' type='button' onClick={resetFilters}>
                Reset filters
              </ButtonAsLink>
            </div>
          )}
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

export default AdverseActionsSearch;
