import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  ReportStatuses,
  computeStatusForScreenings,
  getResultOrStatus,
  orderByKey,
} from '@dashboard-experience/utils';
import { isEmpty } from 'lodash';
import { CountyCriminalSearch, CountyCriminalRecord, ReportProps } from 'types';
import { useUser } from 'context/CurrentUser';
import { STATUS_ORDER } from 'Constants';
import { SCREENING_TITLES } from 'utils';

import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import { FilteredByAdjudicationRecords } from '../Components';
import PendingEta from './Eta/PendingEta';
import AboutThisSearch from './AboutThisSearch';
import { useExpungements } from '../../../../containers/Report';

const CountyCriminal: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const currentUser = useUser();
  const expungements = useExpungements(report);
  const {
    skipped_screenings,
    county_criminal_search_ids,
    county_criminal_searches: screening,
  } = report;
  const countyCrimSearches = orderByKey(
    screening || [],
    'status',
    STATUS_ORDER,
  ) as CountyCriminalSearch[];

  const hasCountyCriminalSearch = Boolean(
    countyCrimSearches.length || isEmpty(county_criminal_search_ids),
  );
  const isComplete =
    hasCountyCriminalSearch &&
    computeStatusForScreenings(screening || []) !== ReportStatuses.PENDING;

  const displaySkippedBanner = skipped_screenings?.some(
    screening =>
      screening.action === 'skipped' &&
      !countyCrimSearches?.some(
        countyCrimSearch =>
          `${countyCrimSearch.county}${countyCrimSearch.state}`.toUpperCase() ===
          `${screening?.county}${screening.state}`.toUpperCase(),
      ),
  );

  return displaySkippedBanner ||
    (countyCrimSearches && countyCrimSearches?.length > 0) ? (
    <M.Screening
      title='County Searches'
      screening={countyCrimSearches}
      statusType={reportStatusType}
      id={SCREENING_TITLES.county_criminal_searches.id}
    >
      {displaySkippedBanner && (
        <M.InlineNotification
          hideCloseButton
          kind='info'
          title='Disclaimer'
          subtitle={`${currentUser.account.name} has chosen to skip the following county search(es):`}
        >
          <ul>
            {skipped_screenings?.map(({ action, county, state }) =>
              action === 'skipped' ? (
                <li key={county}>{`${county}, ${state}`}</li>
              ) : (
                ''
              ),
            )}
          </ul>
        </M.InlineNotification>
      )}
      {!isComplete && <PendingEta />}
      {countyCrimSearches.map(countyCrimSearch => {
        const searchTitle = `${countyCrimSearch.county}, ${countyCrimSearch.state}`;
        const filteredByPAMRecords =
          countyCrimSearch?.filtered_by_positive_adjudication_records?.length ||
          0;
        const hasFilteredByPAMRecords = filteredByPAMRecords > 0;
        const status = getResultOrStatus(countyCrimSearch);

        return (
          <M.SubScreening
            key={searchTitle}
            title={searchTitle}
            screening={countyCrimSearch}
            statusType={reportStatusType}
          >
            {status === ReportStatuses.CONSIDER &&
              countyCrimSearch?.records?.map((record: CountyCriminalRecord) => (
                <M.Record
                  key={record.id}
                  record={record}
                  currentUser={currentUser}
                  report={report}
                  reportStatusType={reportStatusType}
                  expungements={expungements}
                  chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
                />
              ))}
            {hasFilteredByPAMRecords && (
              <FilteredByAdjudicationRecords
                search={countyCrimSearch}
                filter='Positive Adjudication Matrix'
                filteredRecords={filteredByPAMRecords}
                report={report}
                reportStatusType={reportStatusType}
              />
            )}
          </M.SubScreening>
        );
      })}
      <AboutThisSearch isComplete={isComplete} searches={countyCrimSearches} />
    </M.Screening>
  ) : null;
};

export default CountyCriminal;
