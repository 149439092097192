import React from 'react';
import ExpandableKeyValue from './ExpandableKeyValue';

type Props = { trades: { [x: string]: any } };
type List = { name: string; field: string };

const list: List[] = [
  {
    name: 'Industry',
    field: 'subscriber_industry',
  },
  {
    name: 'Creditor',
    field: 'subscriber_name',
  },
  {
    name: 'Portfolio Type',
    field: 'portfolio_type',
  },
  {
    name: 'Account Number',
    field: 'account_number',
  },
  {
    name: 'ECOA Designator',
    field: 'ecoa_designator',
  },
  {
    name: 'Date Opened',
    field: 'date_opened',
  },
  {
    name: 'Date Effective',
    field: 'date_effective',
  },
  {
    name: 'Date Paid Out',
    field: 'date_paid_out',
  },
  {
    name: 'Current Balance',
    field: 'current_balance',
  },
  {
    name: 'High Credit',
    field: 'high_credit',
  },
  {
    name: 'Credit Limit',
    field: 'credit_limit',
  },
  {
    name: 'Account Rating',
    field: 'account_rating',
  },
  {
    name: 'Past Due',
    field: 'past_due',
  },
];

const amountFields = [
  'current_balance',
  'high_credit',
  'credit_limit',
  'past_due',
];

const Accounts: React.FC<Props> = ({ trades }) => {
  return (
    <ExpandableKeyValue
      items={trades}
      heading='Accounts'
      list={list}
      amountFields={amountFields}
      id='screenings-credit-report-accounts'
    />
  );
};

export default Accounts;
