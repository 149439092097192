import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import AddOnsSummaryPrice from '../../shared/AddOnsSummaryPrice';

export const LinePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const LinePriceSection = styled.span`
  display: inline-flex;
  width: 220px;
`;

export const SummaryHeading = styled.h4`
  margin-bottom: 0px !important;
  padding-bottom: 0px;
`;

export const SubtotalHeading = styled.span`
  color: ${colors.brandNavy4};
`;

export const PackageNameHeading = styled.h5`
  align-self: center;
  margin-bottom: 0px !important;
`;

export const SummarySection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.brandSlate3};

  @media (max-device-width: 530px) {
    padding-top: 16px;
  }
`;

export const PackagePriceSpan = styled.span`
  color: ${colors.brandNavy3};
  font-size: 14px;
`;

export const ScreeningList = styled.ul`
  list-style-position: inside;
  margin-top: 12px !important;

  li::marker {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
    font-size: 14px;
  }
`;

export const ScreeningListItem = styled.li`
  margin-bottom: 4px;
  padding-top: 3px;
`;

export const ScreeningLabel = styled.span`
  color: ${colors.brandNavy3};
  font-size: 14px;
  @media (max-device-width: 530px) {
    font-size: 12px !important;
  }
`;

export const AddOnsHeading = styled.h5`
  margin-bottom: 0px !important;
`;

export const BoldLinePrice = styled(LinePrice)`
  font-size: 14px;
  line-height: unset;
  font-weight: bold;

  @media (max-device-width: 400px) {
    flex-direction: row;
    margin-bottom: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

export const LoadingSpinner = styled(M.LoadingSpinner)`
  margin: 24px auto;
  vertical-align: center;
`;

export const FlexDiv = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.brandSlate6};
`;

export const ScreeningListLinePrice = styled.div`
  display: inline;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const BulletPoint = styled.div`
  width: 6px;
  height: 6px;
  background: ${colors.uiAqua500};
  margin: auto 0;
  margin-right: 7px;
  border-radius: 50%;
`;

export const StyledAddOnsSummaryPrice = styled(AddOnsSummaryPrice)`
  @media (max-device-width: 530px) {
    margin-left: 20px;
  }
  float: right;
`;

export const NoSelectedPackageLabel = styled(SummarySection)`
  padding-bottom: 0px;
  border-bottom: none;
`;

export const PackageHeaderWrapper = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 0px !important;
`;

export const EditButton = styled(M.Button)`
  padding: 0px !important;
  border-radius: 0px !important;
  margin-left: 7px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

export const AliasPriceRow = styled.div`
  margin-bottom: 8px;
`;
