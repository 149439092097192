import React, { useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  useReportsAndInvitations,
  useReportInvitationStatusDecision,
} from './utils';
import Name from './name';
import ReportInformation from './report-information';
import './styles.scss';
import RightSection from './right-section';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const getStatusClass = (status: string): string =>
  `candidate-header-status-${status.replace(/\s+|_+/g, '-')}`;

const Header: React.FC<{ isScrolled?: boolean }> = ({ isScrolled = false }) => {
  const { reportsAndInvitations, terminalInvitations, reportsLoading } =
    useReportsAndInvitations();
  const allReportsAndInvitations = [
    ...reportsAndInvitations,
    ...terminalInvitations,
  ];

  // Figure out the currently selected report to mark it active
  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  // Figure out the currently selected invitation to mark it active
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationOrReportId =
    reportMatch?.params?.reportId ||
    invitationMatch?.params?.invitationId ||
    allReportsAndInvitations[0]?.id; // For case where candidate has no reports and route looks like /candidates/[candidate_id]?

  const selectedReport = useMemo(
    () =>
      allReportsAndInvitations.find(
        report => report.id === invitationOrReportId,
      ),
    [invitationOrReportId, reportsAndInvitations, terminalInvitations],
  );

  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(selectedReport);

  const headerRef = useRef(null);

  return (
    <header
      ref={headerRef}
      className={`candidate-header  ${status && getStatusClass(status)} ${
        isScrolled ? 'candidate-header-shrink' : ''
      }`}
    >
      <div
        className={`candidate-header-left ${
          isScrolled ? 'candidate-header-left-shrink' : ''
        }`}
      >
        <Name isScrolled={isScrolled} />
        <ReportInformation
          isScrolled={isScrolled}
          reportsLoading={reportsLoading}
          selectedReport={selectedReport}
          reports={reportsAndInvitations}
        />
      </div>
      <RightSection
        isScrolled={isScrolled}
        reportsAndInvitations={reportsAndInvitations}
      />
    </header>
  );
};
export default Header;
