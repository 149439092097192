import { M } from '@dashboard-experience/mastodon';
import CheckrAcademyHeader from 'assets/home/checkr-academy-header.png';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import styles from './styles';
import { namespace } from '../../../../locales';

const Featured: React.FC = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const basePath = `${namespace}:resourcesSection.featured`;
  const label = t(`${basePath}.label`);
  const title = t(`${basePath}.title`);
  const description = t(`${basePath}.description`);

  const handleTracking = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.RESOURCES_CLICKED, {
        'Home Report Metrics Click': 'Checkr Academy',
      });
    }
  }, [isSignup, trackEvent]);

  return (
    <styles.FeaturedWrapper
      href='https://learn.checkr.com'
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleTracking}
      data-testid='checkr-academy-featured'
    >
      <M.Icon icon='ArrowUpRight' data-testid='checkr-academy-featured-arrow' />
      <styles.ImageContainer>
        <styles.StyledImage
          src={CheckrAcademyHeader}
          alt=''
          data-testid='checkr-academy-featured-image'
        />
      </styles.ImageContainer>
      <styles.FeaturedContentsContainer>
        <styles.Label>{label}</styles.Label>
        <styles.Title>{title}</styles.Title>
        <styles.Description>{description}</styles.Description>
      </styles.FeaturedContentsContainer>
    </styles.FeaturedWrapper>
  );
};

export default Featured;
