/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useEffectAfterInitialized } from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import { updateSearchParams } from 'actions/SearchActions';
import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { isDateValid, formatDate } from '../helpers';
import { useUser } from '../../../../context/CurrentUser';

type Props = {
  component: string;
  from: string;
  to: string;
};

const ReportDateFilter: React.FC<Props> = ({ component, from, to }) => {
  const dispatch = useDispatch();
  const { filters, setFilters } = useSearchState();
  const fromDate = filters[from];
  const toDate = filters[to];
  const user = useUser();
  const amplitudeEventPropertyNames = new Map([
    ['reported_before', 'Report Created To'],
    ['reported_after', 'Report Created From'],
    ['reports_completed_after', 'Report Completed From'],
    ['reports_completed_before', 'Report Completed To'],
  ]);

  const [fromDateVal, setFromDateVal] = useState(
    fromDate ? formatDate(fromDate) : undefined,
  );
  const [toDateVal, setToDateVal] = useState(
    toDate ? formatDate(toDate) : undefined,
  );

  useEffectAfterInitialized(() => {
    // If the local state has values, then transmit those up to Redux:
    if (fromDate !== undefined || toDate !== undefined) {
      const params = {
        [from]: fromDate,
        [to]: toDate,
      };
      dispatch(updateSearchParams(params));
    }

    // If the local values haven't been set yet, then initialize them based on Redux's value:
    if (toDateVal === undefined && toDate)
      setToDateVal(toDate.replaceAll('-', '/'));
    if (fromDateVal === undefined && fromDate)
      setFromDateVal(fromDate.replaceAll('-', '/'));

    // If Redux's value has been cleared out, then also clear out the local value:
    if (fromDate === undefined && fromDateVal) setFromDateVal('');
    if (toDate === undefined && toDateVal) setToDateVal('');
  }, [fromDate, toDate, from, to, dispatch]);

  // Handler called from typing into the input
  const onInputChange = useCallback(
    (
        param: string,
        setFilters: (params: any) => void,
        setState: (val: string) => void,
      ) =>
      (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e?.target as HTMLInputElement)?.value;
        if (!value) {
          setFilters((prevState: any) => ({
            ...prevState,
            [param]: '',
          }));
          setState('');
        }
        // update filter only when it's a complete and valid date
        if ((value.length === 8 || value.length === 10) && isDateValid(value)) {
          setFilters((prevState: any) => ({
            ...prevState,
            [param]: formatDate(value, 'MM-DD-YYYY'),
          }));
        }
        // set only the internal state to avoid multiple calls to the search endpoint
        setState(value);
      },
    [],
  );

  // Handler called when clicking on a date from the picker
  const onDateChange = useCallback(
    (param: string, setState: (val: string) => void) => (date: string) => {
      setFilters((prevState: any) => {
        if (prevState[param] !== formatDate(date, 'MM-DD-YYYY')) {
          trackEventCallback(date, param);
        }

        return {
          ...prevState,
          [param]: formatDate(date, 'MM-DD-YYYY'),
        };
      });
      setState(formatDate(date));
    },
    [setFilters, user, amplitudeEventPropertyNames],
  );

  const trackEvent = useTrackEvent();

  const trackEventCallback = (date: any, param: string) => {
    if (user && date) {
      trackEvent(
        CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
        {
          [amplitudeEventPropertyNames.get(param) || '']: formatDate(date),
          'Selected Filter': amplitudeEventPropertyNames.get(param) || '',
        },
      );
    }
  };

  const DateFilter = M[component];

  return (
    <DateFilter
      fromProps={{
        value: fromDateVal || '',
        onChange: onInputChange(from, setFilters, setFromDateVal),
        onDateChange: onDateChange(from, setFromDateVal),
        autoComplete: 'off',
      }}
      toProps={{
        value: toDateVal || '',
        onChange: onInputChange(to, setFilters, setToDateVal),
        onDateChange: onDateChange(to, setToDateVal),
        autoComplete: 'off',
      }}
    />
  );
};

export default ReportDateFilter;
