import React from 'react';
import { ConditionsProvider } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import InnerGroup, { Props } from './inner-group';

const InnerGroupContainer = (props: Props) => {
  return (
    <ConditionsProvider>
      <InnerGroup {...props} />
    </ConditionsProvider>
  );
};

export default InnerGroupContainer;
