import React, { useCallback, useContext, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDashboardRelay } from 'hooks';
import UIContext from 'context/UI';
import { postMessageToDashboard } from 'utils';
import { useIframeWindow } from 'providers/Candidate';

const messageId = 'window_iframe_positions';

const IframeContainer: React.FC<any> = ({ children }) => {
  const { contextId, isIframe } = useContext(UIContext);
  const { iframeWindow, setIframeWindow } = useIframeWindow();

  const getIframeWindowPositions = useCallback(
    (e: MessageEvent) => {
      const { window } = e.data;

      setIframeWindow({
        innerHeight: window.innerHeight,
        scrollY: window.scrollY,
      });
    },
    [setIframeWindow],
  );

  useDashboardRelay({
    messageId,
    callback: getIframeWindowPositions,
    isIframe,
    contextId,
    deps: [getIframeWindowPositions],
  });

  useEffect(() => {
    if (isIframe) {
      postMessageToDashboard({
        contextId,
        messageId,
      });
    }
  }, [isIframe]);

  // Don't render until the iframe post message is done
  if (isIframe && !iframeWindow) {
    return <M.LoadingSpinner />;
  }

  return children;
};

export default IframeContainer;
