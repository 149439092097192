/* eslint-disable prefer-destructuring */
import React, { useCallback } from 'react';
import { useFetchAllNodes } from 'api/nodes/hooks';
import Button from 'components/Button';
import { Node } from 'components/Packages/OrderBackgroundCheck/SharedItems';
import { useUser } from 'context/CurrentUser';

export function downloadCsv(
  csvContent: string,
  fileName: string = 'download.csv',
): void {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const nodesToCsv = (nodes: Node[]): string => {
  const headers = 'custom_id,name,tier,parent_custom_id';
  const rows = nodes.map(node => {
    const customId = node.custom_id;
    const name = node.name;
    const parentId = node.parent_custom_id || '';
    const tier = node.tier || '';
    return `${customId},${name},${tier},${parentId}`;
  });
  return [headers, ...rows].join('\n');
};

export const DownloadNodes: React.FC = () => {
  const { account } = useUser();
  const { nodes } = useFetchAllNodes({ account });

  const handleClick = useCallback(() => {
    if (nodes && nodes.length > 0) {
      const csvData = nodesToCsv(nodes);
      downloadCsv(csvData, 'nodes.csv');
    }
  }, [nodes]);

  return (
    <Button
      kind='tertiary'
      onClick={handleClick}
      trans='Download nodes'
      data-testid='account-hierarchy-downloaddNodesButton'
    />
  );
};

export default DownloadNodes;
