import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';
import useGetUser from './useGetUser';

const postAccountPackage = async (accountId: string, body: any) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/packages`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

type Params = {
  body: any;
};

function usePostAccountPackage() {
  const { data: user } = useGetUser();

  const [mutate, { data, isLoading, error }] = useMutation(
    async ({ body }: Params) => {
      const response = await postAccountPackage(user.account.id, body);
      return response.data;
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default usePostAccountPackage;
