/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { useCallback, useContext, useEffect } from 'react';
import { useUser } from 'context/CurrentUser';

import styled from 'styled-components';

import { M } from '@dashboard-experience/mastodon';

import {
  CreditMemos,
  useDownloadCreditMemo,
  useGetCreditMemos,
} from 'api/billing';
import { useTranslation } from 'react-i18next';
import { CreditMemoCell } from '../../components/Account/Invoices';
import BillingCustomerContext from './context';
import { Header } from '../../components/Account/Invoices/HeaderWithTooltip';

export const StyledSubHeading = styled.h4`
  padding-top: 3rem;
  margin: 1rem;
`;

const creditMemoHeaders = [
  {
    key: 'memo',
    header: 'creditMemo',
    name: 'creditMemo',
  },
  {
    key: 'effective_at',
    header: 'effectiveDate',
    name: 'effectiveDate',
  },
  {
    key: 'total_credit',
    header: 'totalCredit',
    name: 'totalCredit',
  },
  {
    key: 'available_credit',
    header: 'availableCredit',
    name: 'availableCredit',
  },
  {
    key: 'billing_entity',
    header: 'billingEntity',
    name: 'billingEntity',
  },
];

type CreditMemosTableContainerProps = {
  headers: Array<{ name: string }>;
  rows: Array<{ cells: any }>;
  creditMemos: CreditMemos;
  handleDownload: Function;
};

const CreditMemosTableContainer: React.FC<CreditMemosTableContainerProps> = ({
  rows,
  headers,
  creditMemos,
  handleDownload,
}) => {
  const { t } = useTranslation();

  return (
    <M.TableContainer>
      <M.Table>
        <M.TableHead className='border-top'>
          <M.TableRow>
            {headers.map(header => {
              return (
                <M.TableHeader key={header.name}>
                  {t(`headings.${header.name}`)}
                </M.TableHeader>
              );
            })}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {rows.map((rowMetaData, i) => {
            const row = creditMemos[i];
            return (
              <M.TableRow key={row.id}>
                {rowMetaData.cells.map(
                  (cell: { info: { header: any }; id: string }) => {
                    const cellProps = {
                      header: cell.info.header,
                      row,
                      cell,
                      handleDownload,
                    };
                    return <CreditMemoCell key={cell.id} {...cellProps} />;
                  },
                )}
              </M.TableRow>
            );
          })}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

const CreditMemosContainer: React.FC = () => {
  const { account = {} } = useUser();
  const { downloadMemoCall, downloadMemoResult } = useDownloadCreditMemo();

  const handleDownloadMemo = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = e.target.dataset;
      downloadMemoCall(id);
    },
    [downloadMemoCall],
  );

  useEffect((): void => {
    if (downloadMemoResult.isSuccess) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = downloadMemoResult.data.url;
      a.click();
    }
  }, [downloadMemoResult.isSuccess, downloadMemoResult?.data?.url]);

  const { data: rowData = [] } = useGetCreditMemos(account?.id);
  const { customerData } = useContext(BillingCustomerContext);

  const multiEntityEnabled = customerData?.billing_entity_enabled || false;

  const filteredHeaders = (headers: any[]) =>
    headers.filter(
      (header: Header) => header.name != 'billingEntity' || multiEntityEnabled,
    );

  return (
    <div>
      <StyledSubHeading>Credit memos</StyledSubHeading>
      <M.DataTable rows={rowData} headers={filteredHeaders(creditMemoHeaders)}>
        {({ rows, headers }: any) => (
          <CreditMemosTableContainer
            headers={headers}
            rows={rows}
            creditMemos={rowData}
            handleDownload={handleDownloadMemo}
          />
        )}
      </M.DataTable>
    </div>
  );
};

export default CreditMemosContainer;
