import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useFetchPostAdverseActionsNoticeTemplate } from 'api/adverseActions';
import Preview from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/views/preview-email/preview';
import ErrorMessage from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/errors';
import Header from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/views/preview-email/header';
import Attachments from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/views/preview-email/attachments';
import Warnings from './warnings';
import { useModalState, useDispatch } from '../../../context';

const dividerCss = { margin: '1.5rem 0' };

const email_base_path = `${namespace}:report.reassessment.modal.preview_email.header`;

const PreviewEmail = () => {
  const { report } = useGetReport();
  const dispatch = useDispatch();
  const { individualizedAssessment } = useModalState('state');
  const nextScreenAction = useModalState('nextScreen');
  const previousScreen = useModalState('previousScreen');

  const { uploadedFile, oldAssessmentUrl, reassessment } =
    individualizedAssessment;
  const params = {
    edit_mode: true,
    individualized_reassessment: reassessment,
  };

  const newAssesment = uploadedFile
    ? individualizedAssessment
    : { uploadedFile: oldAssessmentUrl };

  const {
    data: preview,
    isLoading,
    error,
  } = useFetchPostAdverseActionsNoticeTemplate(report.id, params);

  useEffect(() => {
    const nextScreen = {
      onClick: nextScreenAction,
      // TODO: add translation
      name: 'Update Assessment',
      disabled: isLoading,
    };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next: nextScreen,
        back: {
          onClick: previousScreen,
          disabled: isLoading,
        },
        close: {
          disabled: isLoading,
        },
      },
    });
    // nextScreenAction & previousScreen are the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoading]);

  return (
    <>
      <ErrorMessage errors={error} />
      <Warnings />
      <Header base_path={email_base_path} />
      <M.Divider style={dividerCss} />
      <Preview
        isLoading={isLoading}
        preview={preview}
        type='fair-chance-initiative'
      />
      {newAssesment?.uploadedFile && (
        <>
          <M.Divider style={dividerCss} />
          <Attachments
            attachments={[]}
            individualizedAssessment={newAssesment}
          />
        </>
      )}
    </>
  );
};

export default PreviewEmail;
