import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {
  create: () => void;
  disabled?: boolean;
};

export const CreateButton: React.FC<Props> = ({
  create,
  disabled,
  ...extras
}) => {
  const handleClick = useCallback(() => create(), [create]);
  const { t } = useTranslation('');
  const label = t('buttons.create_rule');

  if (disabled) {
    return null;
  }

  return (
    <M.Button
      data-testid='assess-rules-common-create-button'
      onClick={handleClick}
      size='sm'
      {...extras}
    >
      <M.Icon icon='Rule' style={{ marginRight: '0.5rem' }} />
      {label}
    </M.Button>
  );
};

export default CreateButton;
