import React from 'react';
import {
  StyledProgressBar,
  AquaSection,
  GreySection,
  StyledStepNames,
  StyledProgressBarWrapper,
  StyledStepName,
} from './styles';

type ProgressBarProps = {
  activeStepNumber: number;
  stepNames: Array<string>;
};

const ProgressBar = ({ activeStepNumber, stepNames }: ProgressBarProps) => {
  const totalSteps = stepNames.length;

  if (activeStepNumber > totalSteps || activeStepNumber <= 0) return null;

  return (
    <StyledProgressBarWrapper data-testid='progress-bar'>
      <StyledProgressBar>
        <AquaSection
          totalSteps={totalSteps}
          data-testid='aqua-section'
          activeStepNumber={activeStepNumber}
        />
        <GreySection />
      </StyledProgressBar>
      <StyledStepNames>
        {stepNames.map((name, i) => {
          return (
            <StyledStepName
              data-testid={`progress-bar-step-${i + 1}`}
              key={name}
              isActiveStep={activeStepNumber === i + 1}
              totalSteps={totalSteps}
            >
              {i + 1} • {name}
            </StyledStepName>
          );
        })}
      </StyledStepNames>
    </StyledProgressBarWrapper>
  );
};

export default ProgressBar;
