import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Page from '../../../page';
import * as Route from '../../../route';
import { useNamespacedRoute } from '../../../router/context';
import Description from './description';
import Search from './search';
import AddButton from './add-button';

export type Props = {};

export const Container: React.FC<Props> = () => {
  const path = useNamespacedRoute('categories');
  const { t } = useTranslation('assess');
  const title = t('assess:mvr.category-sets.collection.title');

  return (
    <Route.Breadcrumb location={path} title={title}>
      <Page.Container
        data-testid='assess-mvr-category-sets-collection'
        divider={false}
      >
        <Page.Actions>
          <AddButton />
        </Page.Actions>
        <Description />
        <Search />
      </Page.Container>
    </Route.Breadcrumb>
  );
};

export default Container;
