/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { M } from '@dashboard-experience/mastodon';

import { fetchUsersForAccount } from 'actions/AccountUsersActions';
import { hideAssignSegmentsSuccess } from 'actions';
import AssignNodesModal from './AssignNodesModal';
import DeleteUserModal from './DeleteUserModal';
import GeoSubscriptionsModal from './GeoSubscriptionsModal';

class OverflowUserActions extends PureComponent {
  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentUserDeleteConfirmation: null,
      showGeoSubscriptionsModal: false,
      showAssignNodesModal: false,
    };
  }

  /**
   * @name showDeleteUserModal
   * @function
   * @memberOf OverflowUserActions
   * @description Trigger the delete user flow
   */
  showDeleteUserModal = () => {
    const {
      props: { user },
    } = this;

    this.setState({
      currentUserDeleteConfirmation: user.id,
    });
  };

  /**
   * @name hideDeleteUserModal
   * @function
   * @memberOf OverflowUserActions
   * @description Trigger the delete user flow
   * @param {object} user - The user object
   */
  hideDeleteUserModal = () => {
    this.setState({
      currentUserDeleteConfirmation: null,
    });
  };

  /**
   * @name toggleGeoSubscriptionsModal
   * @function
   * @memberOf OverflowUserActions
   * @description Toggle the Geo Subscriptions modal
   */
  toggleGeoSubscriptionsModal = () => {
    const { showGeoSubscriptionsModal } = this.state;
    this.setState({
      showGeoSubscriptionsModal: !showGeoSubscriptionsModal,
    });
  };

  /**
   * @name toggleAssignNodesModal
   * @function
   * @memberOf OverflowUserActions
   * @description Toggle the assign nodes modal
   */
  toggleAssignNodesModal = () => {
    const { showAssignNodesModal } = this.state;
    const { hideAssignSegmentsSuccess: hideSuccess } = this.props;
    this.setState(
      {
        showAssignNodesModal: !showAssignNodesModal,
      },
      () => {
        !this.state.showAssignNodesModal && hideSuccess();
      },
    );
  };

  /**
   * @name canAssignNodesToUsers
   * @function
   * @memberOf OverflowUserActions
   * @description Flag for whether a user has node assignment capabilities
   * @returns {boolean}
   */
  canAssignNodesToUsers = () => {
    const { currentUser } = this.props;
    return (
      currentUser.account.hierarchy_present &&
      currentUser.account.node_based_restriction_enabled
    );
  };

  render() {
    const { user, last, currentUser } = this.props;
    const {
      currentUserDeleteConfirmation,
      showAssignNodesModal,
      showGeoSubscriptionsModal,
    } = this.state;
    const {
      canAssignNodesToUsers,
      hideDeleteUserModal,
      showDeleteUserModal,
      toggleAssignNodesModal,
      toggleGeoSubscriptionsModal,
    } = this;
    return (
      <>
        <M.OverflowMenu
          flipped
          direction={last ? 'top' : 'bottom'}
          className='user-row-overflow-actions'
        >
          {canAssignNodesToUsers() ? (
            <M.OverflowMenuItem
              itemText='Assign Nodes'
              onClick={toggleAssignNodesModal}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
          {!currentUser.account.segmentation_enabled ? (
            <M.OverflowMenuItem
              itemText='Edit Geos'
              onClick={toggleGeoSubscriptionsModal}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
          {currentUser.id !== user.id &&
          currentUser.permissions.manage_account_users ? (
            <M.OverflowMenuItem
              itemText='Delete User'
              isDelete
              onClick={showDeleteUserModal}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </M.OverflowMenu>

        {showAssignNodesModal && (
          <AssignNodesModal
            open
            context='users'
            user={user}
            accountId={currentUser.account.id}
            hideModal={toggleAssignNodesModal}
          />
        )}
        {showGeoSubscriptionsModal && (
          <GeoSubscriptionsModal
            open
            user={user}
            hideModal={toggleGeoSubscriptionsModal}
          />
        )}
        {currentUserDeleteConfirmation === user.id && (
          <DeleteUserModal open user={user} hideModal={hideDeleteUserModal} />
        )}
      </>
    );
  }
}

OverflowUserActions.propTypes = {
  currentUser: PropTypes.object.isRequired,
  hideAssignSegmentsSuccess: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.currentUser,
  };
};

const mapDispatchToProps = {
  fetchUsersForAccount,
  hideAssignSegmentsSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverflowUserActions);
