import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';

type SectionTitleProps = {
  isAddChecks: boolean;
};

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  color: ${colors.uiTextPrimaryLight};
  border-bottom: 1px solid ${colors.uiGrey300};
  padding: 16px 0 16px 0;
  font-size: 16px;
  width: 745px;
`;

const FirstColumn = styled.section`
  display: flex;
  flex-flow: column;
  width: 26%;
`;

const MiddleColumn = styled.section<MiddleColumnProps>`
  margin: 0 0 0 80px;
  width: ${({ $stretch }) => ($stretch ? '75%' : '65%')};
  text-align: left;
`;

const LastColumn = styled.section`
  width: 13%;
  text-align: right;
`;

const SectionTitleTypography = styled.h3<SectionTitleProps>`
  font-size: 14px !important;
  font-weight: 800;
  line-height: 24px;
  font-size: ${({ isAddChecks }) => (isAddChecks ? '14' : '16')}px !important;
`;

const LinkStyled = styled(M.Link)`
  font-weight: 800;
  line-height: 24px;
  color: ${colors.brandNavy1};
  cursor: pointer;
`;

const DescriptionTitleTypography = styled.div`
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0.5rem;
`;

const ScreeningsList = styled.div`
  padding: 0px;
`;

const BoldTypography = styled.p`
  font-weight: 800 !important;
  font-size: 16px !important;
`;

const PriceDescription = styled.div`
  margin-bottom: 0.25rem;
`;

type MiddleColumnProps = {
  $stretch: boolean;
};

export type EditableSectionProps = {
  sectionTitle: string;
  onEditClick: (stepNumber: number) => void;
  description: {
    content?: ReactElement;
    title?: string;
    isCandidatesSection?: boolean; // Candidates section's edit occurs inline
    expand?: boolean; // Should show a 'view more' if > X # of items
  };
  showPricingColumn?: boolean;
  price?: string;
  priceDescription?: string;
  inlineEdit?: boolean;
  editMode?: boolean;
  id: string;
  hideLastColumn?: boolean;
  isAddChecks?: boolean;
};

const EditableSection = ({
  sectionTitle,
  onEditClick,
  description,
  showPricingColumn,
  price,
  priceDescription,
  inlineEdit = false,
  editMode = false,
  id,
  hideLastColumn = false,
  isAddChecks = false,
}: EditableSectionProps) => {
  return (
    <Container>
      <FirstColumn>
        <SectionTitleTypography
          data-testid={`${id}-section-title`}
          isAddChecks={isAddChecks}
        >
          {sectionTitle}
        </SectionTitleTypography>
        <LinkStyled
          onClick={onEditClick}
          data-testid={`${id}-${inlineEdit && editMode ? 'save' : 'edit'}`}
        >
          {inlineEdit && editMode ? 'Save' : 'Edit'}
        </LinkStyled>
      </FirstColumn>

      <MiddleColumn $stretch={hideLastColumn}>
        {description.title && (
          <DescriptionTitleTypography>
            {description.title}
          </DescriptionTitleTypography>
        )}
        <ScreeningsList>{description.content}</ScreeningsList>
      </MiddleColumn>

      {!hideLastColumn && (
        <LastColumn>
          {showPricingColumn && priceDescription && (
            <>
              <PriceDescription>{priceDescription}</PriceDescription>
              <BoldTypography>{getDollarAmount(price)}</BoldTypography>
            </>
          )}
        </LastColumn>
      )}
    </Container>
  );
};

export default EditableSection;
