import React, { useEffect, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetReport } from 'api/reports';
import { Report } from '@dashboard-experience/utils';
import {
  useUpdateReassessment,
  useAdverseActions,
} from 'modules/adjudication/api';

import Errors from './errors';
import Success from './success';
import { useModalState, useDispatch } from '../../../context';

const Confirm = () => {
  const {
    updateReassessment,
    uploadAssessment,
    updateReassessmentResult,
    uploadAssessmentResult,
    isError,
    isLoading,
  } = useUpdateReassessment();
  const dispatch = useDispatch();
  const { individualizedAssessment } = useModalState('state');
  const previousScreen = useModalState('previousScreen');

  const data = useGetReport();
  const report = data?.report as unknown as Report;
  const { adverseAction } = useAdverseActions(report.id);

  const { uploadedFile, reassessment, oldReassessment } =
    individualizedAssessment;
  const reassessmentChanged = reassessment !== oldReassessment;

  const submit = useCallback(() => {
    if (uploadedFile) {
      uploadAssessment(uploadedFile.file);
    }
    if (reassessmentChanged)
      updateReassessment({
        adverseActionId: adverseAction?.id,
        params: { individualized_reassessment: reassessment },
      });
  }, [
    uploadAssessment,
    updateReassessment,
    report.id,
    reassessmentChanged,
    reassessment,
    uploadedFile,
  ]);

  useEffect(() => {
    submit();
  }, []);

  useEffect(() => {
    const back = !isError ? null : { onClick: previousScreen };
    const close = {
      disabled: isLoading,
      name: 'Done',
    };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        back,
        close,
      },
    });
    // previousScreen is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isError, isLoading]);

  if (isLoading) {
    return <M.LoadingInline description='Loading...' />;
  }

  return (
    <div>
      {!isError && !isLoading && <Success />}
      {isError && !isLoading && (
        <Errors
          updateReassessmentResult={updateReassessmentResult}
          uploadAssessmentResult={uploadAssessmentResult}
        />
      )}
    </div>
  );
};

export default Confirm;
