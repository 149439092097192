import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const InputLabel = styled.label`
  display: inline-block;
  font: 600 normal 0.9em/0.9em sans-serif;
  margin-bottom: 0.5rem;
  width: 100%;
  color: ${colors.uiTextPrimaryLight};
`;

export default InputLabel;
