import React, { useCallback } from 'react';
import EditCell from './Edit';
import ReadonlyCell from './Readonly';
import { MAX, MIN, isInvalid } from './utils';

type Props = {
  error?: boolean;
  disabled?: boolean;
  focus: boolean;
  onUpdate: () => void;
  onValidateValue: () => void;
  setFocus: (value: boolean) => void;
  setValue: (value?: number) => void;
  valid: boolean;
  value?: number;
};

const Input: React.FC<Props> = ({
  error,
  disabled,
  focus,
  onUpdate,
  onValidateValue,
  setFocus,
  setValue,
  valid,
  value,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value === '' ? undefined : Number(e.target.value));
    },
    [setValue],
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.charCode === 45 || e.charCode === 46) {
        e.preventDefault();
        return;
      }
      if (e.key === 'Enter' && valid) {
        onValidateValue();
      }
    },
    [onValidateValue, valid],
  );

  const handleOnPaste = useCallback(
    (event: any) => {
      const { clipboardData } = event;
      const pastedData = clipboardData.getData('Text');
      const invalid = isNaN(pastedData) || isInvalid(MIN, MAX, pastedData);
      const valid = !invalid;

      if (valid) {
        setValue(Math.floor(pastedData));
      }

      event.stopPropagation();
      event.preventDefault();
    },
    [setValue],
  );

  const handleClickOutside = useCallback(() => {
    setFocus(false);
    onUpdate();
  }, [onUpdate, setFocus]);

  if (focus && !disabled) {
    return (
      <EditCell
        handleClickOutside={handleClickOutside}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        handleOnPaste={handleOnPaste}
        invalid={!valid}
        value={value}
      />
    );
  }

  return <ReadonlyCell setFocus={setFocus} value={value} error={error} />;
};

export default Input;
