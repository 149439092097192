import React from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import { Subtype } from 'modules/adjudication/data';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { ModalFooter } from 'modules/adjudication/ui/common';
import SubtypeSelection from '../../../../subtype-selection';

type Props = {
  onChange: React.Dispatch<React.SetStateAction<undefined>>;
  onClose: () => void;
  transitionToScreen: (screen: string) => void;
  selection: undefined | GenericObject;
};

const AdjudicationSubtypeSelection: React.FC<Props> = ({
  onChange,
  onClose,
  transitionToScreen,
  selection,
}) => {
  const { t } = useTranslation();
  const cancel = t(`${namespace}:report.actions.defer.modal.footer.cancel`);
  const defer = t(`${namespace}:report.actions.defer.modal.footer.select`);
  const legend = t(`${namespace}:report.actions.defer.modal.subtypes.legend`);

  const next = {
    name: defer,
    onClick: () => transitionToScreen('confirm'),
    disabled: Boolean(!selection),
  };

  const close = {
    name: cancel,
    onClick: onClose,
  };

  return (
    <>
      <SubtypeSelection
        legendText={legend}
        subtypeKind={Subtype.Deferred}
        onChange={onChange}
        value={selection?.name}
      />
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default AdjudicationSubtypeSelection;
