import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const DotTag = () => {
  return <Tag data-testid='dot-header-tag'>DOT</Tag>;
};

const Tag = styled(M.Tag)`
  margin: 0 0 0 1em !important;
`;

export default DotTag;
