import React, { useEffect, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { PutContactsParams } from 'api/payment';
import { emailValidationRegex } from '@dashboard-experience/utils';
import {
  StyledInput,
  StyledNote,
  GridRow,
  GridCol,
  StyledFormLabel,
  InvalidEmailError,
  EmailContainer,
} from './PaymentStyledComponents';
import { billingContactsValidationSchema } from './utils';

type Props = {
  contactsData: Partial<PutContactsParams>;
  submit: any;
  modalOpen: boolean;
  hideModal: any;
  isProcessing: boolean;
  error: any;
};

// split emails list by "," or ";"
const pasteSplit = (text: string) =>
  text
    .replace(/[\r\n,;]/g, ' ')
    .split(' ')
    .map(d => d.trim());

// tab, enter, space, semicolon, comma
const addKeys = [9, 13, 32, 186, 188];

const inputProps = {
  placeholder: 'Add additional email address',
  style: { minWidth: '16rem' },
  id: 'additional_emails',
  name: 'additional_emails',
};

const BillingContactForm: React.FC<Props> = ({
  contactsData,
  submit,
  modalOpen,
  hideModal,
  isProcessing,
  error,
}) => {
  const { t } = useTranslation();
  const initialValues: Partial<PutContactsParams> = {
    preferred_email: '',
    additional_emails: [],
  };

  const [hasInvalidEmails, setHasInvalidEmails] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: billingContactsValidationSchema,
    onSubmit: values => {
      submit(values);
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    formik.setValues(contactsData);
  }, [modalOpen]);

  const showError = (field: string): boolean => {
    return (errors[field as keyof typeof errors] &&
      touched[field as keyof typeof touched]) as boolean;
  };

  const errorMessage = (field: string): string | null => {
    return touched[field as keyof typeof touched]
      ? (errors[field as keyof typeof errors] as string)
      : null;
  };

  const handleEmailsChange = useCallback(
    (tags: string[], changed: string[], changedIndexes: number[]) => {
      let hasInvalid = false;
      for (const tag of tags) {
        if (!emailValidationRegex.test(tag)) {
          hasInvalid = true;
          break;
        }
      }

      setHasInvalidEmails(hasInvalid);
      setFieldValue('additional_emails', tags, true);
    },
    [],
  );

  return (
    <form onSubmit={handleSubmit}>
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.LoadingSpinner active={isProcessing} />
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`billing_contacts.modal_header`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          {error && (
            <M.InlineNotification
              kind='error'
              title='Something went wrong, please try again later.'
            />
          )}
          <StyledNote>{t(`billing_contacts.description`)}</StyledNote>
          <M.Grid>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='preferred_email'
                  name='preferred_email'
                  labelText={t(
                    `billing_contacts.add_contacts_form.preferred_email.label`,
                  )}
                  placeholder={t(
                    `billing_contacts.add_contacts_form.preferred_email.placeholder`,
                  )}
                  value={values.preferred_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('preferred_email')}
                  invalidText={errorMessage('preferred_email')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledFormLabel>
                  {t(
                    `billing_contacts.add_contacts_form.additional_emails.label`,
                  )}
                </StyledFormLabel>
                <EmailContainer
                  onlyUnique
                  addOnPaste
                  addKeys={addKeys}
                  inputProps={inputProps}
                  onChange={handleEmailsChange}
                  pasteSplit={pasteSplit}
                  tags={values.additional_emails}
                />
                {hasInvalidEmails && (
                  <InvalidEmailError> Invalid email(s) </InvalidEmailError>
                )}
              </GridCol>
            </GridRow>
          </M.Grid>
        </M.ModalBody>
        <M.ModalFooter>
          <M.Button onClick={hideModal} kind='secondary'>
            {t(`billing_contacts.add_contacts_form.cancel_btn`)}
          </M.Button>
          <M.Button type='submit' disabled={hasInvalidEmails}>
            {t(`billing_contacts.add_contacts_form.continue_btn`)}
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>
    </form>
  );
};

export default BillingContactForm;
