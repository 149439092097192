import React, { useEffect } from 'react';
import PageLoading from 'components/PageLoading';
import { Type as Amplitude } from 'modules/assess/v2/models/amplitude';
import { useTier, useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { Provider } from '../Actions';
import Content from '../Content';
import { Bar } from './Actions';
import Header from './Header';

type Props = {
  id?: string;
  status?: string;
  event?: Amplitude;
};

const Container: React.FC<Props> = ({
  children,
  id,
  status,
  event,
  ...rest
}) => {
  const track = useTrackEvent();
  const tier = useTier();

  const loading = status === 'loading';

  useEffect(() => {
    if (event && track) {
      track(event);
    }
  }, [event, track]);

  return (
    <div data-testid={id} className={tier} {...rest}>
      <PageLoading enabled={loading} />
      <Provider>
        <Header />
        <Content>{children}</Content>
        <Bar />
      </Provider>
    </div>
  );
};

export default Container;
