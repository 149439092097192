import React, { useEffect } from 'react';
import { Page } from 'modules/assess/ui';
import Rules from 'modules/assess/ui/rules/container';
import * as Entity from 'modules/assess/models/rulesets/version';
import * as State from 'modules/assess/ui/state/rulesets/versions/instance';

import Actions from './ActionsContainer';
import ContextDatesPanel from './ContextDatesPanelContainer';
import Description from './DescriptionContainer';
import ReadOnlyNotification from './ReadOnlyNotification';
import Status from './StatusContainer';
import * as Store from './store';

export type Props = {
  data?: Entity.Type;
  status?: string;
};

export const RulesetVersionContainer: React.FC<Props> = ({ data, status }) => {
  const store = Store.useStore();

  const { dispatch, state } = store;
  const { updated_at } = state;

  useEffect(() => {
    if (!data || data.updated_at?.getTime() === updated_at?.getTime()) {
      return;
    }

    dispatch(State.Actions.Insert.create(data));
  }, [data, dispatch, updated_at]);

  return (
    <Store.Provider value={store}>
      <Page.Container
        data-testid='assess-ruleset-version-container'
        loading={status === 'loading'}
      >
        <Actions />
        <Status />
        <Description />
        <ContextDatesPanel />
        <ReadOnlyNotification />
        <Rules />
      </Page.Container>
    </Store.Provider>
  );
};

export default RulesetVersionContainer;
