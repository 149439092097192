/* eslint-disable no-else-return */
/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as Notifications from 'state/notifications';

type Props = {
  data: Notifications.Entity.Type;
};

const Toast = styled(M.ToastNotification)`
  min-width: 30em;
`;

const Actionable = styled(M.ActionableNotification)`
  min-width: 30em;
`;

export const NotificationContainer: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const handleClose = useCallback(
    () => dispatch(Notifications.Actions.Remove.init(data)),
    [data, dispatch],
  );

  // Trigger the specified callback and then remove the toast after a small delay
  const actionAndClose = useCallback(() => {
    if (data.action) {
      data.action.callback();
    }
    setTimeout(() => {
      dispatch(Notifications.Actions.Remove.init(data));
    }, 500);
  }, [data, dispatch]);

  useEffect((): void | (() => void) => {
    let timer: ReturnType<typeof setTimeout>;

    if (data.timeout) {
      timer = setTimeout(() => {
        dispatch(Notifications.Actions.Remove.init(data));
      }, data.timeout);
    }

    return () => clearTimeout(timer);
  }, [data, dispatch]);

  // Both types of notification have a lot of identical details:
  const baseDetails = {
    key: data.id,
    kind: data.kind,
    title: data.title,
    subtitle: data.message,
    caption: '',
    onCloseButtonClick: handleClose,
  };

  // Toast - the vast majority of notifications; purely a show of information
  if (!data.action) {
    return <Toast {...baseDetails} />;
  }

  // Actionable - rare subset of notifications; adding some kind of action item with a clickable button
  else {
    return (
      <Actionable
        {...baseDetails}
        actionButtonLabel={data.action.message}
        onActionButtonClick={actionAndClose}
      />
    );
  }
};

export default NotificationContainer;
