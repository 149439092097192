import React, { useCallback, useEffect, useState, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { CurrentUser, hasPermission } from '@dashboard-experience/utils';
import {
  CANDIDATE_INFO_FIELDS,
  DELETE_CANDIDATE_DATA_FLAG_KEY,
} from 'Constants';
import { GenericObject } from 'types';
import { postMessageToDashboard } from 'utils';
import { useCandidate } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { Route } from 'react-router-dom';
import { useNestedPreference } from 'api/dashboardPreferences';
import {
  CandidateInfoContainer,
  CandidateInfoExpandable,
  CandidateInfoRow,
  DeleteCandidateButton,
} from './CandidateInformationStyledComponents';
import DeleteCandidateData from './DeleteCandidateData';
import * as FieldComponents from './fields';
import { FieldConfig, FieldKVListConfig } from './types';
import IdentityVerification from './IdentityVerification';

/**
 * @name buildCol1Config
 * @function
 * @memberOf CandidateInformation/CandidateInformation
 * @description Build the config to be used with M.KeyValueList
 * @returns {array} - The config
 * @param {object} candidate - The candidate object
 * @param {integer} columnIndex
 * @param {object} currentUser - The currentUser object
 * @param {Function} allowCandidateEdit - Whether this user can edit candidate details
 */
const buildColumnConfig = (
  candidate: GenericObject,
  columnIndex: number,
  currentUser: CurrentUser,
  allowCandidateEdit: Function,
) => {
  const fields: FieldConfig[] = CANDIDATE_INFO_FIELDS.filter(
    field => field.column === columnIndex,
  );
  const config: FieldKVListConfig[] = fields
    .map((field: FieldConfig) => {
      const {
        fieldName,
        fieldKey,
        hasTemplate,
        optional,
        simpleCheck,
      }: FieldConfig = field;
      let item: FieldKVListConfig = {
        itemKey: null,
        itemValue: null,
      };
      if (
        !optional ||
        (simpleCheck && candidate?.[fieldKey]) ||
        (fieldKey === 'geos' && candidate?.geos?.length)
      ) {
        const renderedFieldName = fieldName(candidate, currentUser);
        item = getColConfigItem(
          renderedFieldName,
          fieldKey,
          hasTemplate,
          candidate,
          currentUser,
          allowCandidateEdit,
        );
      }
      return item;
    })
    .filter(item => item.itemKey);
  return config;
};

// map of api field keys to components
const componentMap: {
  [fieldKey: string]: React.FC<any>;
} = {
  created_at: FieldComponents.CreatedAt,
  custom_id: FieldComponents.CustomId,
  dob: FieldComponents.Dob,
  driver_license_number: FieldComponents.DriverLicenseNumber,
  email: FieldComponents.Email,
  geos: FieldComponents.Geos,
  on_hold: FieldComponents.OnHold,
  previous_driver_license_number: FieldComponents.PreviousDriverLicenseNumber,
  ssn: FieldComponents.Ssn,
};

/**
 * @name getColConfigItem
 * @function
 * @memberOf CandidateInformation/CandidateInformation
 * @description Get a single config object to be used with M.KeyValueList
 * @returns {object} - The single config object
 */
const getColConfigItem = (
  fieldName: string,
  fieldKey: string,
  hasTemplate: boolean,
  candidate: GenericObject,
  currentUser: GenericObject,
  allowCandidateEdit: Function,
) => {
  // define KVList config item object
  const configItem: FieldKVListConfig = {
    itemKey: null,
    itemValue: null,
  };

  // set KVList key
  configItem.itemKey = fieldName;

  // set KVList value
  if (hasTemplate) {
    const FieldComponent = componentMap[fieldKey];
    configItem.itemValue = (
      <FieldComponent
        fieldKey={fieldKey}
        displayName={fieldName}
        allowCandidateEdit={allowCandidateEdit(fieldKey)}
        candidate={candidate}
        currentUser={currentUser}
        testid={`candidate-information-field-${fieldKey}`}
      />
    );
  } else {
    configItem.itemValue = (
      <span data-testid={`candidate-information-field-${fieldKey}`}>
        {candidate[fieldKey] || '-'}
      </span>
    );
  }

  return configItem;
};

const CandidateInformation: React.FC = () => {
  const candidate = useCandidate();
  const currentUser = useUser();
  const { contextId, isIframe } = useContext(UIContext);
  const hasReport = !!candidate.last_report_id;
  const [candidateInfoListCol1, setCandidateInfoListCol1] = useState<
    GenericObject[]
  >([]);
  const [candidateInfoListCol2, setCandidateInfoListCol2] = useState<
    GenericObject[]
  >([]);
  const [candidateInformationPreference, setCandidateInformationPreference] =
    useNestedPreference('report', 'candidateInformation');

  const { expanded } = candidateInformationPreference;
  const allowCandidateEdit = useCallback(
    (field: string) => {
      if (field === 'email' || field === 'custom_id') {
        return (
          hasReport && hasPermission(currentUser, `update_candidate_${field}s`)
        );
      }
      return hasReport && currentUser.roles?.includes('admin');
    },
    [hasReport, currentUser],
  );
  useEffect(() => {
    if (candidate && candidate.id && currentUser) {
      setCandidateInfoListCol1(
        buildColumnConfig(candidate, 0, currentUser, allowCandidateEdit),
      );
      setCandidateInfoListCol2(
        buildColumnConfig(candidate, 1, currentUser, allowCandidateEdit),
      );
    }
  }, [allowCandidateEdit, candidate, currentUser, hasReport]);

  // Angular -> React migration flag, for CCPA data deletion
  const migratedDeletionModal =
    useFlag(DELETE_CANDIDATE_DATA_FLAG_KEY)?.variantKey === 'on' || false;
  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<boolean>(false); // State to handle the open/closed state of the migrated modal

  const trackEvent = useTrackEvent();

  /**
   * @name showDeleteCandidateDataModal
   * @function
   * @memberOf CandidateInformation/CandidateInformation
   * @description Show the ccpa modal
   */
  const showDeleteCandidateDataModal = useCallback(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DELETE_CANDIDATE_DATA);
    }

    // Legacy deletion modal - only show while in iframe AND lacking the Flagr
    if (isIframe && !migratedDeletionModal) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'open_delete_candidate_data_modal',
      });
    }
    // Migrated modal - show while in standalone and/or with the Flagr
    else {
      setDisplayDeletionModal(true);
    }
  }, [currentUser, isIframe, migratedDeletionModal, trackEvent, contextId]);

  const closeDeletionModal = useCallback(
    completeType => {
      setDisplayDeletionModal(false);

      if (currentUser) {
        trackEvent(
          CANDIDATE_REPORT_EVENT_NAMES.REPORT_CANDIDATE_MODAL_COMPLETED,
          {
            'Complete Type': completeType,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  /**
   * @name onExpand
   * @function
   * @memberOf CandidateInformation/CandidateInformation
   * @description Callback to handle setting user preference for expand
   */
  const onExpand = useCallback(
    expanded => {
      setCandidateInformationPreference({ expanded });
    },
    [setCandidateInformationPreference],
  );

  return (
    <CandidateInfoContainer data-testid='candidate-information'>
      <CandidateInfoExpandable
        title={<h5 className='mb-0'>Candidate information</h5>}
        initialExpanded={expanded}
        onExpand={onExpand}
      >
        <M.Grid>
          <CandidateInfoRow>
            <M.Container.Col>
              <M.KeyValueList
                items={candidateInfoListCol1}
                data-testid='candidate-information-column-1'
              />
            </M.Container.Col>
            <M.Container.Col>
              <M.KeyValueList
                items={candidateInfoListCol2}
                data-testid='candidate-information-column-2'
              />
              {displayDeletionModal && (
                <DeleteCandidateData
                  showModal={displayDeletionModal}
                  onClose={closeDeletionModal}
                  candidate={candidate}
                  data-testid='delete-candidate-data-modal'
                />
              )}
            </M.Container.Col>
          </CandidateInfoRow>
        </M.Grid>
        <DeleteCandidateButton
          size='small'
          type='button'
          kind='tertiary'
          onClick={showDeleteCandidateDataModal}
          data-testid='delete-candidate-data-button'
        >
          Delete candidate data
        </DeleteCandidateButton>
        <Route
          path='/candidates/:candidateId/reports/:reportId'
          component={IdentityVerification}
        />
      </CandidateInfoExpandable>
    </CandidateInfoContainer>
  );
};

export default CandidateInformation;
