import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Kind } from 'modules/assess/models/assignables/base';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import DefaultDescription from './DefaultDescription';
import List from './List';

type Props = {
  assignments?: Assignment.List | Assignment.Map;
  withDetails?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  margin-top: 2em;

  > * {
    margin: 0 2em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  > * {
    margin: 1em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  .p3-secondary {
    color: ${colors.uiTextSecondaryLight};
  }
`;

export const Container: React.FC<Props> = ({ assignments, withDetails }) => {
  const values = Object.values(assignments || []);
  const account = filterByAssignmentType(values, Kind.ACCOUNT);
  const geos = filterByAssignmentType(values, Kind.GEO);
  const packages = filterByAssignmentType(values, Kind.PACKAGE);
  const packages_and_geos = filterByAssignmentType(
    values,
    Kind.PACKAGE_AND_GEO,
  );
  const packages_and_segments = filterByAssignmentType(
    values,
    Kind.PACKAGE_AND_SEGMENT,
  );
  const programs = filterByAssignmentType(values, Kind.PROGRAM);
  const segments = filterByAssignmentType(values, Kind.SEGMENT);
  const states = filterByAssignmentType(values, Kind.STATE);
  const totalAssignments =
    geos.length +
    packages.length +
    packages_and_geos.length +
    packages_and_segments.length +
    programs.length +
    segments.length +
    states.length;
  return (
    <Wrapper>
      {withDetails && <h5 style={{ margin: 0 }}>Assignments</h5>}
      <DefaultDescription enabled={account.length > 0} />
      <StyledContainer>
        {geos.length > 0 && (
          <List assignments={geos} noun='ruleset_assignments.geo.noun_plural' />
        )}
        {packages.length > 0 && (
          <List
            assignments={packages}
            noun='ruleset_assignments.package.noun_plural'
          />
        )}
        {packages_and_geos.length > 0 && (
          <List
            assignments={packages_and_geos}
            noun='ruleset_assignments.package_and_geo.noun_plural'
          />
        )}
        {packages_and_segments.length > 0 && (
          <List
            assignments={packages_and_segments}
            noun='ruleset_assignments.package_and_segment.noun_plural'
          />
        )}
        {programs.length > 0 && (
          <List
            assignments={programs}
            noun='ruleset_assignments.program.noun_plural'
          />
        )}
        {segments.length > 0 && (
          <List
            assignments={segments}
            noun='ruleset_assignments.segment.noun_plural'
          />
        )}
        {states.length > 0 && (
          <List
            assignments={states}
            noun='ruleset_assignments.state.noun_plural'
          />
        )}
        {withDetails && !totalAssignments && (
          <div className='p3 p3-secondary'>No current assignments</div>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

function filterByAssignmentType(
  assignments: Array<Assignment.Type>,
  kind: Kind,
) {
  return assignments.filter(assignment => assignment.type === kind);
}

export default Container;
