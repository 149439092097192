import * as Yup from 'yup';
import ValidationErrors from './types';

export const FormI9Validations = Yup.object().shape({
  employeeSelection: Yup.string(),
  employer: Yup.string().required(ValidationErrors.REQUIRED),
  workflowType: Yup.string().required(ValidationErrors.REQUIRED),
  worksite: Yup.string().required(ValidationErrors.REQUIRED),
  i9Manager: Yup.string(),
  employees: Yup.array().when('employeeSelection', {
    is: 'newEmployees',
    then: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        firstName: Yup.string().required(ValidationErrors.REQUIRED),
        middleName: Yup.string(),
        lastName: Yup.string().required(ValidationErrors.REQUIRED),
        otherLastName: Yup.string(),
        email: Yup.string()
          .email(ValidationErrors.INVALID_EMAIL)
          .matches(/^(?!.*@[^,]*,)/)
          .required(ValidationErrors.REQUIRED),
        ssn: Yup.string(),
        dob: Yup.string(),
        employeeId: Yup.string(),
        startDate: Yup.string().required(ValidationErrors.REQUIRED),
      }),
    ),
  }),

  existingEmployees: Yup.array().when('employeeSelection', {
    is: 'existingProfile',
    then: Yup.array().min(1, ValidationErrors.REQUIRED),
  }),
  existingEmployeeStartDate: Yup.string().when('employeeSelection', {
    is: 'existingProfile',
    then: Yup.string().required(ValidationErrors.REQUIRED),
  }),
});

export default FormI9Validations;

export const UserValidations = Yup.object().shape({
  firstName: Yup.string().required(ValidationErrors.REQUIRED),
  lastName: Yup.string().required(ValidationErrors.REQUIRED),
  email: Yup.string()
    .email(ValidationErrors.INVALID_EMAIL)
    .matches(/^(?!.*@[^,]*,)/)
    .required(ValidationErrors.REQUIRED),
});
