import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from 'types';
import { Cell, RenderTableParams } from './types';
import { getCertificationDetailsRows } from './Helpers';

type Props = {
  certification: GenericObject;
};

const HEADERS = [
  {
    key: 'label',
    header: '',
  },
  {
    key: 'candidateInput',
    header: 'Candidate input',
  },
  {
    key: 'matchedResult',
    header: 'Matched result',
  },
];

const renderTable = ({ rows, headers }: RenderTableParams) => {
  return (
    <M.Table data-testid='screenings-professional-license-verification-details-table'>
      <M.TableHead>
        <M.TableRow>
          {headers.map((item: GenericObject) => (
            <M.TableHeader key={item.key}>{item.header}</M.TableHeader>
          ))}
        </M.TableRow>
      </M.TableHead>
      <M.TableBody>
        {rows.map((row: any) => {
          return (
            <M.TableRow
              key={row.id}
              data-testid='screenings-professional-license-verification-table-row'
            >
              {row.cells.map(({ id, value }: Cell) => {
                return <M.TableCell key={id}>{value}</M.TableCell>;
              })}
            </M.TableRow>
          );
        })}
      </M.TableBody>
    </M.Table>
  );
};

const Details: React.FC<Props> = ({ certification }) => {
  return (
    <M.DataTable
      rows={getCertificationDetailsRows(certification)}
      headers={HEADERS}
      render={renderTable}
    />
  );
};

export default Details;
