import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CategoryCodeSetVersionFull } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import BaseDialog, {
  Action,
} from 'modules/assess/ui/common/base-dialog/container';
import { useDestroy } from '../version/api';
import Footer from '../delete/footer';
import Content from './content';

export type Props = {
  open: boolean;
  ccsvDraft?: CategoryCodeSetVersionFull;
  onClose: () => void;
};

export const DeleteDraft: React.FC<Props> = ({ open, ccsvDraft, onClose }) => {
  const deleteCCS = useDestroy(ccsvDraft?.category_code_set?.id, 'draft');
  const history = useHistory();

  const onDelete = useCallback(() => {
    deleteCCS.call().then(() => {
      if (history.location.pathname.includes('draft')) {
        history.push('active');
      }
      onClose();
    });
  }, [deleteCCS, history, onClose]);

  return (
    <BaseDialog
      action={Action.deleteDraft}
      content={<Content ccsvDraft={ccsvDraft} />}
      footer={<Footer onCancel={onClose} onDelete={onDelete} />}
      open={open}
      onClose={onClose}
      name={ccsvDraft?.category_code_set?.name}
      type='category_set'
    />
  );
};

export default DeleteDraft;
