import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Note = styled.p`
  display: flex;
  flex-direction: column;
  color: ${colors.brandNavy4} !important;
`;

export default Note;
