import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { BillingAddressResponse, DUE_ON_RECEIPT } from 'api/payment';
import { Divider, StyledH2, GridCol } from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';
import AutopayIndicator from './AutopayIndicator';
import { ActionableBanner } from '../../Banner';

type Props = {
  customerData?: Partial<BillingAddressResponse>;
};

const PaymentSchedule: React.FC<Props> = ({ customerData }) => {
  const { t } = useTranslation();

  if (!customerData) {
    return null;
  }

  const { payment_term, autopay_enabled } = customerData;

  let helperText = null;

  if (autopay_enabled) {
    if (payment_term?.name === DUE_ON_RECEIPT) {
      helperText = t('payment_schedule.payment_term.due_on_receipt');
    } else {
      helperText = t('payment_schedule.payment_term.net_term', {
        days: payment_term?.due_days,
      });
    }
  }

  return (
    <M.Grid>
      <M.GridRow>
        <CustomCol col={5} noPadding style={{ marginTop: '0' }}>
          <M.GridRow>
            <GridCol>
              <>
                {customerData.billing_entity_enabled && (
                  <ActionableBanner
                    kind='warning'
                    title={t(
                      `banners.billingEntityPaymentMethodsDisabled.title`,
                    )}
                    subtitle={t(
                      `banners.billingEntityPaymentMethodsDisabled.subtitle`,
                    )}
                    data-test-id='billing_entity_enabled_banner'
                  />
                )}
                <StyledH2 className='mb-0'>Payment Schedule</StyledH2>
              </>
            </GridCol>
          </M.GridRow>
          <M.GridRow>
            <CustomCol col={2} noPadding style={{ marginTop: '0.75rem' }}>
              <AutopayIndicator enabled={customerData.autopay_enabled!} />
            </CustomCol>
          </M.GridRow>
          {autopay_enabled && (
            <M.GridRow>
              <CustomCol col={10} noPadding style={{ marginTop: '1.0rem' }}>
                <p>{helperText}</p>
              </CustomCol>
            </M.GridRow>
          )}
          <Divider />
        </CustomCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default PaymentSchedule;
