import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  isOpen: any;
  closeModal: any;
  unarchive: any;
};

const ModalBody = styled(M.ModalBody)`
  p {
    color: ${colors.coolBlack70};
    padding-right: 0;
  }
`;

const UnarchiveModal: React.FC<Props> = ({ isOpen, closeModal, unarchive }) => {
  return (
    <M.ComposedModal
      open={isOpen}
      onClose={closeModal}
      data-testid='unarchive-modal'
    >
      <M.ModalHeader closeModal={closeModal}>
        <h2>Unarchive report?</h2>
      </M.ModalHeader>
      <ModalBody>
        <p>
          Unarchiving this report will add it back to your{' '}
          <strong>Active</strong> tabx and enable the report{' '}
          <strong>Actions</strong> menu. You can re-archive the report at any
          time.
        </p>
      </ModalBody>
      <M.ModalFooter>
        <M.Button kind='secondary' onClick={closeModal}>
          Cancel
        </M.Button>
        <M.Button
          type='submit'
          onClick={unarchive}
          data-testid='confirm-unarchive-modal'
        >
          Confirm
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default UnarchiveModal;
