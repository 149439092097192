import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ASSESS_PRODUCTS_PAGE,
  FEATURES_OF_ASSESS_PAGE,
  HOW_ASSESS_WORKS_PAGE,
} from 'modules/assess/v2/constants';
import { useTier } from 'modules/assess/v2/ui/hooks';

const DescriptionStyle = styled.div`
  padding-left: 0.5rem;
  margin-bottom: 1.5rem;

  p {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
`;

const AssessTierLink = () => {
  const tier = useTier();
  const { t } = useTranslation('assess:v2');

  return (
    <a href={ASSESS_PRODUCTS_PAGE} target='_blank' rel='noopener noreferrer'>
      {t(`home.description.${tier}.link`)}
    </a>
  );
};

const HowAssessWorksLink = () => {
  const { t } = useTranslation('assess:v2');

  return (
    <span>
      &nbsp;
      <a href={HOW_ASSESS_WORKS_PAGE} target='_blank' rel='noopener noreferrer'>
        {t('home.description.premium.link')}
      </a>
    </span>
  );
};

const LearnMoreLink = () => {
  const { t } = useTranslation('assess:v2');

  return (
    <span>
      &nbsp;
      <a
        href={FEATURES_OF_ASSESS_PAGE}
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('home.description.link.learn_more')}
      </a>
    </span>
  );
};

const components = {
  'assess-tier': <AssessTierLink />,
  'how-assess-works': <HowAssessWorksLink />,
  'learn-more': <LearnMoreLink />,
};

const Description: React.FC = () => {
  const tier = useTier();
  const { t } = useTranslation('assess:v2');

  // TODO: EADA-3943 - [Assess v1/v2] move description component out of v2
  return (
    <DescriptionStyle data-testid={`assess-${tier}-home-header-description`}>
      <Trans
        t={t}
        i18nKey={`home.description.${tier}.text`}
        components={components}
      />
    </DescriptionStyle>
  );
};

export default Description;
