/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { forwardRef } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import BaseFilter from './BaseFilter';
import { onSelectChange, AnalyticsProps } from '../helpers';

type Props = {};

const INCLUDES_CANCEL_FILTERS = [
  { value: 'true', text: 'Show only reports with canceled items' },
  { value: 'false', text: 'Hide reports with canceled items' },
];

const IncludeCancelFilter = forwardRef(({ ...other }, ref) => {
  return (
    <M.FilterSelect
      {...{
        id: 'includes-canceled',
        labelText: 'Reports with canceled items',
        name: 'includes_canceled',
        className: 'includes-cancel-filter',
        unselectedText: 'Show all reports',
        ref,
        ...other,
      }}
    >
      {M.FilterSelect.renderFilters(INCLUDES_CANCEL_FILTERS)}
    </M.FilterSelect>
  );
});
IncludeCancelFilter.displayName = 'IncludeCancelFilter';

const IncludesCanceled: React.FC<Props> = () => {
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.REPORTS_WITH_CANCELED_ITEMS,
    trackEvent,
  };

  return (
    <BaseFilter
      {...{
        component: IncludeCancelFilter,
        onChange: onSelectChange,
        param: 'includes_canceled',
        trackAnalyticsObject,
      }}
    />
  );
};

export default IncludesCanceled;
