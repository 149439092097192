import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { StyledComponents } from 'modules/assess/v2/ui/common';
import Content from './Content';

const StyledContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
`;

const Container: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const titleText = t(`standard.guideline.info.title`);

  return (
    <StyledContainer>
      <StyledComponents.Title
        id='assess-v2-ui-guideline-info-container-title'
        data-testid='assess-v2-guideline_info-title'
      >
        {titleText}
      </StyledComponents.Title>
      <Content />
    </StyledContainer>
  );
};

export default Container;
