import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchIndividualizedAssessmentDocuments } from 'modules/adjudication/api';

import {
  LA_INDIVIDUALIZED_ASSESSMENT_FORM,
  NY_INDIVIDUALIZED_ASSESSMENT_FORM,
} from './constants';

const FormLinks: React.FC = () => {
  const { t } = useTranslation('adjudication');

  const downloadNYFormText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.download_new_york_form',
  );
  const downloadLAFormText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_upload.download_los_angeles_form',
  );

  const { data } = useFetchIndividualizedAssessmentDocuments();

  const laForm = data?.data?.find(
    (form: any) => form?.name === LA_INDIVIDUALIZED_ASSESSMENT_FORM,
  );
  const nyForm = data?.data?.find(
    (form: any) => form?.name === NY_INDIVIDUALIZED_ASSESSMENT_FORM,
  );

  return (
    <div data-testid='ia-form-links'>
      <a
        data-testid='ia-form-link-ny'
        href={nyForm?.uri}
        target='_blank'
        rel='noreferrer'
      >
        {downloadNYFormText}
      </a>
      {' - '}
      <a
        data-testid='ia-form-link-la'
        href={laForm?.uri}
        target='_blank'
        rel='noreferrer'
      >
        {downloadLAFormText}
      </a>
    </div>
  );
};

export default FormLinks;
