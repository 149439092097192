import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { ChargeSeverity } from 'modules/assess/models';
import { Level, Type } from 'modules/assess/models/rules/lookback';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/lookback';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import SeverityInput from './severity-input';
import { eligibleSeverity, getInitialRule } from './utils';

type Props = {
  initial: number | undefined;
  rule?: Type;
  severity: ChargeSeverity;
  subcategory: string;
  error?: boolean;
  setValue: (value?: number) => void;
};

const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.uiYellow500};
`;

const RuleSeverityInput: React.FC<Props> = ({
  initial,
  error,
  rule,
  severity,
  subcategory,
  setValue,
}) => {
  const track = useTrackEvent();
  const dispatch = useDispatch();
  const initialCharge = getInitialRule(subcategory, rule);
  const setEligibleValue = useCallback(
    (data?: number) => {
      const type = rule ? Actions.Update : Actions.Create;
      const charge = {
        ...initialCharge,
        ...eligibleSeverity(severity, data),
        level: Level.CATEGORY_1,
        updated: true,
      };
      const action = type.create(charge);
      dispatch(action);
      setValue(data);
      track(LookbackPeriod.SubcategoryUpdated, { subcategory });
    },
    [dispatch, initialCharge, rule, setValue, severity, subcategory, track],
  );
  const { t } = useTranslation('assess:v2');
  const errorText = t('common.lookback_rules.severity_input.readonly.error');

  return (
    <M.GridCol data-testid='assess-v2-ui-common-lookback-rules-subcategory-col'>
      <SeverityInput
        key={rule?.name}
        initial={initial}
        setEligibleValue={setEligibleValue}
        error={error}
      />
      {error && <ErrorMessage>{errorText}</ErrorMessage>}
    </M.GridCol>
  );
};

export default RuleSeverityInput;
