import React from 'react';

import {
  BulkActionButton,
  BulkActionContainer,
  Header,
  HeaderContainer,
  SectionDescription,
  Wrapper,
} from './styles';

type Props = {
  header: string;
  sectionKey: string;
  headerBody?: string;
  bulkActionLabel?: string;
  onBulkAction?: () => void;
};

const SectionHeader: React.FC<Props> = ({
  bulkActionLabel,
  header,
  headerBody,
  sectionKey,
  onBulkAction,
}: Props) => (
  <Wrapper data-testid={`section-header-wrapper-${sectionKey}`}>
    <HeaderContainer data-testid={`section-header-container-${sectionKey}`}>
      <Header data-testid={`section-header-${sectionKey}`}>{header}</Header>
      {headerBody && (
        <SectionDescription data-testid={`section-header-body-${sectionKey}`}>
          {headerBody}
        </SectionDescription>
      )}
    </HeaderContainer>
    {bulkActionLabel && onBulkAction && (
      <BulkActionContainer
        data-testid={`section-header-bulk-action-container-${sectionKey}`}
      >
        <BulkActionButton
          data-testid={`section-header-bulk-action-button-${sectionKey}`}
          onClick={onBulkAction}
        >
          {bulkActionLabel}
        </BulkActionButton>
      </BulkActionContainer>
    )}
  </Wrapper>
);

export default SectionHeader;
