import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Column = styled(M.GridCol)`
  padding-left: 0;
`;

export const SectionTitle = styled.h6`
  color: ${colors.uiTextPrimaryLight};
  font-weight: bold;
  margin-bottom: 0 !important;
`;

export const Detail = styled.p`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 0 !important;
`;

export const Days = styled.span`
  color: ${colors.uiGrey600};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonTitle = styled.span`
  font-weight: bold;
`;

export const EmailButtonWrapper = styled.div`
  margin-right: 1rem;
`;

export const EmailIcon = styled(M.Icon)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ChevronIcon = styled(M.Icon)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const StyledDownloadButton = styled(M.Button)`
  :hover {
    background-color: ${colors.uiGrey100} !important;
  }
`;

export const StyledEmailCandidateButton = styled(M.OverflowMenu)`
  max-height: 3rem;
  border: none;
  background-color: ${colors.uiNavy600} !important;
  color: ${colors.uiGrey0};
  border-radius: 0.125rem;
  box-shadow: 0 0.25rem 0.5rem rgb(22 22 29 / 10%);
  width: 100%;
  height: 2.25rem;
  :hover {
    background-color: ${colors.uiNavy700} !important;
  }
  .cds--overflow-menu-options {
    ::after {
      display: none;
    }
  }
  ul {
    padding: 0.25rem;
    background-color: ${colors.uiGrey0};
    :hover {
      background-color: ${colors.uiGrey100};
    }
  }

  .mastodon-icon {
    fill: ${colors.white} !important;
  }
`;

export const StyledMenuItem = styled(M.OverflowMenuItem)`
  .cds--overflow-menu-options__option-content {
    overflow: visible;
    text-overflow: inherit;
    white-space: normal;
    text-align: center;
    color: ${colors.uiNavy600};
  }
`;
