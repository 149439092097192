import { ID } from 'modules/id';
import { Namespace } from 'modules/assess/api';
import { AnyQueryKey, useQuery } from 'react-query';
import { useNamespace } from 'modules/assess/ui/router/context';
import * as ruleset from 'modules/assess/api/ruleset';
import { growth_insights } from 'modules/assess/api/ruleset';

export const useGrowthInsights = (id?: ID, mock?: boolean) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'growth_insights'];

  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return growth_insights.fetch(context, id, mock);
  };

  const result = useQuery(key, request);

  return result;
};
