import React, { useContext } from 'react';
import styled from 'styled-components';

import { ActionMap } from 'modules/assess/ui/common';
import * as Entity from 'modules/assess/models/rules/entity';
import { Context } from 'modules/assess/ui/rules/common/rule';
import { Name } from 'modules/assess/ui/rules/common/rule/viewer';
import Actions from 'modules/assess/ui/rules/common/rule/viewer/actions/container';

type Props = {
  actions: ActionMap;
  editable?: boolean;
  rule: Entity.Type;
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Header: React.FC<Props> = ({ editable, rule, actions }) => {
  const { disabled } = useContext(Context);

  return (
    <Flex data-testid='assess-rules-common-rule-list-header'>
      <Name rule={rule} />
      <Actions disabled={disabled} editable={editable} {...actions} />
    </Flex>
  );
};

export default Header;
