/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { pick } from 'lodash';
import { API, extractError, SearchAPI, SavedSearchAPI } from 'utils/APIClient';

import moment from 'moment';
import qs from 'qs';
import { debug } from 'utils';
import { PARAMS } from 'components/Search/constants';

// CANDIDATE DOCUMENTS
export const FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST =
  'FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST';
export const FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS =
  'FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS';
export const FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE =
  'FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE';
export const INITIALIZE_SEARCH = 'INITIALIZE_SEARCH';

export const fetchCandidateReportDocumentsRequest = () => ({
  type: FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST,
});
export const fetchCandidateReportDocumentsSuccess = report => ({
  type: FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS,
  report,
});
export const fetchCandidateReportDocumentsFailure = () => ({
  type: FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE,
  error: 'Something went wrong, please try again later.',
});

export const initializeSearch = () => ({
  type: INITIALIZE_SEARCH,
});

export const fetchCandidateReportDocuments = (reportId, params) => dispatch => {
  dispatch(fetchCandidateReportDocumentsRequest());
  const search = qs.stringify(params);
  return API.get(`/reports/${reportId}/dashboard?include=documents&${search}`)
    .then(report => dispatch(fetchCandidateReportDocumentsSuccess(report)))
    .catch(error => dispatch(fetchCandidateReportDocumentsFailure(error)));
};

// CANDIDATES SEARCH
export const SAVE_SEARCH_REQUEST = 'SAVE_SEARCH_REQUEST';
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';
export const SAVE_SEARCH_FAILURE = 'SAVE_SEARCH_FAILURE';

export const saveSearchRequest = () => ({
  type: SAVE_SEARCH_REQUEST,
});
export const saveSearchSuccess = () => ({
  type: SAVE_SEARCH_SUCCESS,
});
export const saveSearchFailure = error => ({
  type: SAVE_SEARCH_FAILURE,
  error,
});

export const saveSearch = params => dispatch => {
  dispatch({
    type: SAVE_SEARCH_REQUEST,
  });

  return SavedSearchAPI.post('', params)
    .then(candidates => dispatch(saveSearchSuccess(candidates)))
    .catch(e => {
      let error;
      try {
        error = extractError(e);
      } catch (e) {
        error =
          'Something went wrong, and we were unable to fetch candidates. Please try again later.';
      }
      return dispatch(saveSearchFailure(error));
    });
};

export const FETCH_CANDIDATES_REQUEST = 'FETCH_CANDIDATES_REQUEST';
export const FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS';
export const FETCH_CANDIDATES_FAILURE = 'FETCH_CANDIDATES_FAILURE';

export const fetchCandidatesRequest = () => ({
  type: FETCH_CANDIDATES_REQUEST,
});
export const fetchCandidatesSuccess = candidates => ({
  type: FETCH_CANDIDATES_SUCCESS,
  candidates,
});
export const fetchCandidatesFailure = error => ({
  type: FETCH_CANDIDATES_FAILURE,
  error,
});

export const fetchCandidates = (params, account, cancelToken) => dispatch => {
  const query = pick(params, PARAMS.whitelisted());
  if (query.q === '') query.q = '*';

  for (const param in query) {
    if (param in query) {
      const val = query[param];
      if (!val || (Array.isArray(val) && !val.length)) {
        // eliminate empty '' and []
        delete query[param];
      } else if (Array.isArray(val) && val.length) {
        query[param] = val.join(',');
      } else if (PARAMS.noneSelectValues.includes(param) && val === 'none') {
        query.missing = query.missing ? `${query.missing},${param}` : param;
        delete query[param];
      } else if (PARAMS.date.includes(param) && typeof val === 'string') {
        // url params need to be '-' separated to be backwards compatible with the legacy
        // implementation, in order to properly parse the date it needs to be '/' separated
        const d = val.split('-').join('/');
        const date = new Date(d);

        const dateParam =
          param === 'dob'
            ? moment(date).format('YYYY-MM-DD')
            : date.toISOString();
        query[param] = dateParam;
      }
    }
  }

  dispatch(fetchCandidatesRequest());
  let queryWithSecondaryOwnership = null;
  if (account?.business_entities?.length) {
    queryWithSecondaryOwnership = {
      ...query,
      secondary_ownership: JSON.stringify(account.business_entities),
    };
  }

  debug('Search query update:', query);

  const search = qs.stringify(queryWithSecondaryOwnership || query);
  const url = `/search?${search}`;

  return SearchAPI.get(url, { cancelToken })
    .then(candidates => dispatch(fetchCandidatesSuccess(candidates)))
    .catch(e => {
      let error;
      try {
        error = extractError(e);
      } catch (e) {
        error =
          'Something went wrong, and we were unable to fetch candidates. Please try again later.';
      }
      return dispatch(fetchCandidatesFailure(error));
    });
};

// SEARCH PARAMS
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';
export const updateSearchParams = newParams => {
  // TODO BUG: when we change the drop-down menu value, it's sending
  // the saved search struct instead of just the ID.  Patch it here so we
  // send the right parameters
  if (typeof newParams.saved_search_id === 'object') {
    const ss = newParams.saved_search_id;
    delete newParams.saved_search_id;

    return {
      type: UPDATE_SEARCH_PARAMS,
      newParams,
      savedSearch: ss,
    };
  }

  if (
    Object.keys(newParams).filter(k => k === 'saved_search_id').length > 0 &&
    !(typeof newParams.saved_search_id === 'string')
  ) {
    // DELETING a saved search
    return {
      type: DELETE_SAVED_SEARCH,
    };
  }

  return {
    type: UPDATE_SEARCH_PARAMS,
    newParams,
  };
};

export const UPDATE_PAGE_PARAM = 'UPDATE_PAGE_PARAM';
export const updatePageParam = page => ({
  type: UPDATE_PAGE_PARAM,
  page,
});

export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS';
export const clearSearchParams = () => ({
  type: CLEAR_SEARCH_PARAMS,
});
