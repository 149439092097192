import React from 'react';
import moment from 'moment';
import { KNOWN_EXCEPTION_TYPES } from 'Constants';

interface Props {
  comment: string | null;
  createdAt: string;
  type: string;
}

const Header: React.FC<Props> = ({ comment, createdAt, type }) => (
  <div className='exception-header'>
    <div style={{ flex: 1 }}>
      {KNOWN_EXCEPTION_TYPES.includes(type) && <strong>{comment}</strong>}
      {!KNOWN_EXCEPTION_TYPES.includes(type) && (
        <>
          <strong>{type}</strong>
          <br />
          <span>{comment}</span>
        </>
      )}
    </div>
    {createdAt && (
      <span className='exception-timestamp'>
        {moment(createdAt).format('lll')}
      </span>
    )}
  </div>
);

export default Header;
