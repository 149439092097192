import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTrackEvent, CASE_MANAGEMENT_EVENT_NAMES } from 'utils/analytics';

export const Column = styled.div`
  color: black;
  position: relative;

  &:after {
    background: transparent;
    content: '';
    cursor: pointer;
    inset: 0;
    position: absolute;
  }
`;

type CellProps = {
  children: React.ReactNode;
  testid: string | null;
  url?: string;
};

const Cell: React.FC<CellProps> = ({ url, testid, children }) => {
  const trackEvent = useTrackEvent();

  const logReportLinkClickEvent = useCallback(() => {
    trackEvent(CASE_MANAGEMENT_EVENT_NAMES.TASKS_PAGE_REPORT_LINK_CLICKED);
  }, [trackEvent]);

  const column = <Column data-testid={testid}>{children}</Column>;

  if (!url) return column;

  return (
    <Link
      to={url}
      onClick={logReportLinkClickEvent}
      data-testid='case-management-tasks-table-report-link'
    >
      {column}
    </Link>
  );
};

export default Cell;
