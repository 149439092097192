import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

export type Props = {
  disabled?: boolean;
  onCancel?: (event: SyntheticEvent) => void;
  onConfirm?: () => void;
};

export const Footer: React.FC<Props> = ({ disabled, onCancel, onConfirm }) => (
  <>
    <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
    <Button
      disabled={disabled}
      kind='primary'
      trans='verbs.publish'
      onClick={onConfirm}
    />
  </>
);

export default Footer;
