import Humanize from 'humanize-plus';
import { uniqBy, orderBy } from 'lodash';
import { SUPPORTED_LOCALES } from 'Constants';
import {
  computeScreeningsStatus,
  getScreeningStatus,
  getStatusTypeForReport,
} from '@dashboard-experience/utils';
import Tabs from 'modules/report/ui/tabs/variations';
import { isV2DrugScreening } from 'components/Report/Screenings/DrugHealthScreenings/utils';

export const SCREENING_TITLES = {
  arrest_search: {
    title: 'Arrest Search',
    id: 'arrest-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  child_abuse_searches: {
    title: 'Child Abuse',
    id: 'child-abuse',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  clearinghouse_search: {
    title: 'Clearinghouse Search',
    id: 'clearinghouse',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  county_civil_searches: {
    title: 'County Civil Searches',
    id: 'county-civil',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  county_criminal_searches: {
    title: 'County Searches',
    id: 'county-criminal',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  credit_report: {
    title: 'Credit Report',
    id: 'screenings-credit-report',
    tab: () => Tabs.CREDIT_REPORT,
  },
  drug_alcohol_clearinghouse: {
    title: 'Drug and Alcohol ClearingHouse',
    id: 'screenings-drug-alcohol-clearinghouse-report',
    tab: () => Tabs.DRUG_ALCOHOL_CLEARINGHOUSE,
  },
  drug_screening: {
    title: 'Drug Screening',
    id: 'screenings-drug-screening',
    tab: report =>
      isV2DrugScreening(report) ? Tabs.HEALTH_SCREENING : Tabs.DRUG_SCREENING,
  },
  education_verification: {
    title: 'Education Verification',
    id: 'education-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  employment_verification: {
    title: 'Employment Verification',
    id: 'employment-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  eviction_search: {
    title: 'Eviction Search',
    id: 'eviction-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  facis_search: {
    title: 'FACIS',
    id: 'FACIS',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  federal_civil_search: {
    title: 'Federal Civil Search',
    id: 'federal-civil-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  federal_criminal_search: {
    title: 'Federal Search',
    id: 'federal-criminal-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  federal_district_civil_searches: {
    title: 'Federal District Civil Search',
    id: 'federal-district-civil-searches',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  federal_district_criminal_searches: {
    title: 'Federal District Search',
    id: 'federal-district-criminal-searches',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  healthcare_sanctions_searches: {
    title: 'Healthcare Sanctions Search',
    id: 'healthcare-sanctions-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  identity_verification: {
    title: 'Identity Verification',
    id: 'screenings-identity-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_adverse_media_searches: {
    title: 'International Adverse Media Searches',
    id: 'international-adverse-media-searches',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_canada_bc_enhanced_criminal_search: {
    title: 'Canada BC Enhanced Criminal Search',
    id: 'international-canada-bc-enhanced-criminal-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_criminal_searches: {
    title: 'International Searches',
    id: 'international-criminal-searches',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_criminal_searches_v2: {
    title: 'International Criminal Search',
    id: 'international-criminal-searches-v2',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_credit_report: {
    title: 'International Credit Report',
    id: 'screenings-international-credit-report',
    tab: () => Tabs.CREDIT_REPORT,
  },
  international_education_verification: {
    title: 'International Education Verification',
    id: 'international-education-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_employment_verification: {
    title: 'International Employment Verification',
    id: 'international-employment-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_global_watchlist_search: {
    title: 'International Global Watchlist Search',
    id: 'international-global-watchlist-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_identity_document_validation: {
    title: 'International Identity Document Validation',
    id: 'international-identity-document-validation',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_motor_vehicle_reports: {
    title: 'International Motor Vehicle Report',
    id: 'international-motor-vehicle-report',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  international_right_to_work: {
    title: 'Right to Work',
    id: 'international-right-to-work',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  motor_vehicle_report: {
    title: 'Motor vehicle record (MVR)',
    id: 'multi-mvr',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  municipal_criminal_searches: {
    title: 'Municipal Criminal Searches',
    id: 'municipal-criminal',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  national_criminal_search: {
    title: 'National Search',
    id: 'national-criminal',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  occupational_health_screening: {
    title: 'Health Screening',
    id: 'screenings-health-screening',
    tab: () => Tabs.HEALTH_SCREENING,
  },
  personal_reference_verifications: {
    title: 'Personal Reference Verification',
    id: 'screenings-personal-reference-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  photo_verification: {
    title: 'Photo Verification',
    id: 'photo-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  pointer_state_criminal_searches: {
    title: 'Pointer State Searches',
    id: 'pointer-state-criminal',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  professional_license_verifications: {
    title: 'Professional License Verification',
    id: 'screenings-professional-license-verification',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  professional_reference_verifications: {
    title: 'Professional Reference Verification',
    id: 'screenings-professional-reference-verifications',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  public_comment: {
    title: 'Public Comment',
    id: 'public-comments',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  self_disclosure_screening: {
    title: 'Self Disclosure',
    id: 'self-disclosure',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  sex_offender_search: {
    title: 'Sex Offender Search',
    id: 'sex-offender-search',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  ssn_trace: {
    title: 'SSN Trace',
    id: 'ssn-trace',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  state_criminal_searches: {
    title: 'State Searches',
    id: 'state-criminal',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  statement_of_dispute: {
    title: 'Statement of Dispute',
    id: 'statement-of-dispute',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
  terrorist_watchlist_search: {
    title: 'Global Watchlist Search',
    id: 'terrorist-watchlist',
    tab: () => Tabs.BACKGROUND_REPORT,
  },
};

export const statusOrder = [
  'escalated',
  'consider',
  'review',
  'eligible',
  'clear',
  'completed',
];

const screeningKey = screeningName => {
  const key = screeningName
    .replace(/matrix_|stateful_/, '')
    .replace('county_criminal_search', 'county_criminal_searches');
  return screeningName ? key : null;
};

export const formatScreeningName = screeningName => {
  const withoutUnderscores = screeningName.split('_').join(' ');
  return Humanize.capitalizeAll(withoutUnderscores).replace('Ssn', 'SSN');
};

const getScreening = (report, screeningName) => {
  const key = screeningKey(screeningName);

  if ({}.hasOwnProperty.call(report, key)) {
    const screen = report[key];
    screen.displayName = formatScreeningName(key);
    return screen;
  }

  return null;
};

export const reportScreenings = report =>
  report &&
  report.screening_types
    .map(screeningName => getScreening(report, screeningName))
    .filter(obj => obj);

export const getStatusIndex = status => {
  const index = statusOrder.indexOf(status);
  // -1 if not found, place before 'completed'
  return index === -1 ? statusOrder.length - 1 : index;
};

export const getScreeningsSummary = (report, user, onClick) => {
  const summary = [];
  const statusType = getStatusTypeForReport(report, user);

  for (const key in SCREENING_TITLES) {
    if (report[key]) {
      const { title, id, tab } = SCREENING_TITLES[key];
      const screeningObj = report[key];

      let count = 0;
      let records = 0;
      let screeningStatus = '';

      if (Array.isArray(screeningObj)) {
        count = screeningObj.length;
        screeningStatus = computeScreeningsStatus(screeningObj, statusType);
        records = screeningObj.reduce(
          (sum, screening) => sum + (screening.records?.length ?? 0),
          0,
        );
      } else if (screeningObj) {
        count = 1;
        records = screeningObj.records?.length ?? 0;
        screeningStatus = getScreeningStatus(screeningObj, statusType);
      }

      if (count > 0 && screeningStatus) {
        summary.push({
          name: title,
          id,
          status: screeningStatus,
          count,
          records,
          onClick: onClick(id, tab(report)),
        });
      }
    }
  }

  // order screenings by status severity
  summary.sort((a, b) => {
    const aStatusIndex = getStatusIndex(a.status);
    const bStatusIndex = getStatusIndex(b.status);

    if (aStatusIndex !== bStatusIndex) {
      return aStatusIndex - bStatusIndex;
    }

    // If statuses are the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  return summary;
};

export const reportCriminalRecords = report => {
  if (report === null) {
    return null;
  }
  const records = [];

  report.county_criminal_searches.map(screening =>
    screening.records.map(record => {
      const recordWithMetaData = record;
      recordWithMetaData.displayName = 'County Criminal Search';
      recordWithMetaData.screening = screening;
      records.push(recordWithMetaData);
      return recordWithMetaData;
    }),
  );

  return records;
};

export const flatRecords = report => {
  if (report === null) {
    return [];
  }

  const records = [];

  reportScreenings(report).map(screening => {
    if (screening.displayName === 'Motor Vehicle Report') {
      return null;
    }
    if (!{}.hasOwnProperty.call(screening, 'records')) {
      return null;
    }

    screening.records.map(record => {
      const recordWithMetaData = record;
      recordWithMetaData.displayName = screening.displayName;
      recordWithMetaData.screening = screening;
      records.push(recordWithMetaData);
      return recordWithMetaData;
    });

    return screening;
  });

  return [...records, ...reportCriminalRecords(report)];
};

export const getUniqueReports = pdfInternationalReport => {
  pdfInternationalReport.map(document => {
    if (document.locale == null || document.locale.startsWith('{')) {
      document.locale = '';
    }
  });
  const reportData = orderBy(
    uniqBy(pdfInternationalReport, 'locale'),
    ['locale'],
    ['desc'],
  );
  return reportData;
};

export const getPdfLocaleOrNull = pdfReport => {
  return pdfReport?.locale ? pdfReport.locale : null;
};

const getPdfLocaleFileSuffix = locale => {
  locale = locale?.toLowerCase();
  return (
    SUPPORTED_LOCALES[locale] ||
    SUPPORTED_LOCALES[locale?.slice(0, 2)] ||
    SUPPORTED_LOCALES.unknown
  );
};

export const getInternationalReportPdfButtonText = pdfReport => {
  const docLocale = getPdfLocaleOrNull(pdfReport);
  const buttonText = 'International Report PDF';
  if (docLocale) {
    return `${buttonText} ${getPdfLocaleFileSuffix(docLocale)}`;
  }
  return buttonText;
};
