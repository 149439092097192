import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledUser = styled.div`
  display: flex;
  place-items: center;
  margin: 0.25rem 0.5rem;

  .name {
    margin-left: 8px;
  }

  .unassign {
    color: ${colors.uiTextDisabledLight};
  }
`;

export default StyledUser;
