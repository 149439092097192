import React from 'react';
import CourtClosures from './CourtClosures';

const style = { display: 'block' };

const Unavailable: React.FC<{}> = () => {
  return (
    <div>
      <p>
        <strong>Why is estimated completion unavailable?</strong>
        <span style={style}>
          Due to court closures in one or more counties below, we are not able
          to complete the report at this time. We will resume all county checks
          when all relevant courts are reopened.
        </span>
      </p>

      <p>
        <strong>Why are electronic counties also pending?</strong>
        <span style={style}>
          In order to provide the most up-to-date county record information on a
          candidate, we will resume all county checks when all relevant courts
          are reopened.
        </span>
      </p>

      <CourtClosures />
    </div>
  );
};

export default Unavailable;
