import React from 'react';
import { useUser } from 'context/CurrentUser';
import getFields from './get-fields';
import withReportContentContainer from '../report-content-container';
import { buildColumnConfig } from '../utils';
import { KeyValueList, ExpandablePanel } from '../styles';
import { SectionProps } from '../types';

const AssessGuidelines: React.FC<SectionProps> = ({ report, statusType }) => {
  const user = useUser();
  const fields = getFields({ report, statusType, user });

  const reportInformation = buildColumnConfig({
    fields,
    report,
    statusType,
    user,
  });

  return fields.length > 0 ? (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='report-assess-guidelines-panel'
      title='Assess guidelines'
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={reportInformation}
        data-testid='overview-assess-guidelines'
      />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(AssessGuidelines);
