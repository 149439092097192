import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'base-packages-container',
})`
  display: flex;
  gap: 24px;
  align-self: stretch;
  margin: 24px 0 0 0;

  @media (max-width: 992px) {
    gap: 16px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 24px;
    margin: 0;
  }
`;

export const ScreeningRow = styled.div.attrs({
  className: 'screening-row',
})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ScreeningTextContainer = styled.div.attrs({
  className: 'screening-text-container',
})`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type ScreeningTextProps = {
  fontWeight?: number;
};

export const ScreeningText = styled.p.attrs({
  className: 'screening-text',
})<ScreeningTextProps>`
  color: ${colors.uiTextSecondaryLight} !important;
  font-weight: ${props => props.fontWeight || 400};
  line-height: 20px;
`;

export const InformationIcon = styled(M.Icon).attrs({
  className: 'order-summary-container',
})`
  fill: ${colors.brandSlate5} !important;
`;

export const Tooltip = styled(M.Tooltip).attrs({
  className: 'base-packages-tooltip',
})`
  height: 20px;
`;
