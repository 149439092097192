/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  Container,
  ScreeningDescription,
  Price,
  AddButton,
  InnerContainer,
  ScreeningRow,
  RemoveButton,
  Check,
  ButtonRow,
} from './styles';

type Props = {
  name: string;
  description: string;
  price: string;
  icon: JSX.Element;
  onAddClick?: any;
  onRemoveClick?: any;
  selected?: boolean;
  screening: string;
};

const CommonAddOn = ({
  name,
  description,
  price,
  icon,
  onAddClick,
  onRemoveClick,
  selected,
  screening,
}: Props) => {
  return (
    <Container>
      <InnerContainer>
        {icon}
        <ScreeningRow>
          <h5 className='mb-0'>{name}</h5>
          <ScreeningDescription>{description}</ScreeningDescription>
        </ScreeningRow>
      </InnerContainer>
      <ButtonRow>
        <Price>{price}</Price>
        {selected ? (
          <RemoveButton
            data-testid={`${name}-added-button`}
            type='button'
            onClick={() => onRemoveClick(screening)}
          >
            <Check icon='Checkmark' />
            Added
          </RemoveButton>
        ) : (
          <AddButton
            data-testid={`${name}-add-button`}
            kind='tertiary'
            size='sm'
            onClick={() => onAddClick(screening)}
          >
            Add
          </AddButton>
        )}
      </ButtonRow>
    </Container>
  );
};

export default CommonAddOn;
