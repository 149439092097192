import { useMutation, useQuery, queryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  getCsvValidations,
  getStatus,
  postHierarchy,
  postHierarchyCsv,
} from './actions';
import { toastError, toastSuccess } from '../../actions/ToastActions';

export const usePostHierarchy = () => {
  const dispatch = useDispatch();

  const request = (hierarchy: any[]) => {
    const customIds = new Set();

    const filteredHierarchy = hierarchy.map((rows: any) => {
      // if a tier has a value 'input', change it to null
      return {
        custom_id: rows.id,
        name: rows.name,
        tier: rows.tier === 'input' || rows.tier === '' ? null : rows.tier,
        parent_custom_id: rows.parent_custom_id,
      };
    });

    // remove empty rows before posting
    const rows = filteredHierarchy.filter((row: any) => {
      if (row.name && row.custom_id) {
        customIds.add(row.custom_id);
        return true;
      }
      return false;
    });

    if (customIds.size !== rows.length) {
      dispatch(
        toastError(
          'error',
          'Custom IDs must be unique within the account hierarchy',
        ),
      );
    }

    const nodes: any[] = rows.map((row: any) => {
      if (!customIds.has(row.parent_custom_id)) {
        return { ...row, parent_custom_id: null };
      }
      return row;
    });

    return postHierarchy({ nodes });
  };

  const [postHierarchyCall, postHierarchyResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: () => {
      dispatch(toastSuccess('Success:', 'Hierarchy posted'));
      queryCache.invalidateQueries('nodes/fetch_all_nodes');
      queryCache.invalidateQueries('hierarchyStatus');
    },
  });

  return {
    postHierarchyCall,
    postHierarchyResult,
  };
};

type PostCsvParamsType = {
  key: string;
};

export const usePostHierarchyCsv = () => {
  const dispatch = useDispatch();

  const request = (params: PostCsvParamsType) => {
    return postHierarchyCsv({ raw_csv_file_s3_path: params.key });
  };

  const [postHierarchyCsvCall, postHierarchyCsvResult] = useMutation(request, {
    onError: (data: any, params: PostCsvParamsType) => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: (data, params: PostCsvParamsType) => {
      dispatch(toastSuccess('Success:', 'Hierarchy posted'));
      queryCache.invalidateQueries('nodes/fetch_all_nodes');
      queryCache.invalidateQueries('hierarchyStatus');
    },
  });

  return {
    postHierarchyCsvCall,
    postHierarchyCsvResult,
  };
};

export const useFetchHierarchyStatus = () => {
  const request = () => getStatus();

  return useQuery('hierarchyStatus', request, {
    refetchOnWindowFocus: false,
  });
};

type HandleCsvParamsType = {
  key: string;
  onSuccessCallback: (errors: string[], line_count: number) => void;
  onErrorCallback: (error: any) => void;
};

export const useValidateCsv = () => {
  const request = (params: HandleCsvParamsType) =>
    getCsvValidations({ raw_csv_file_s3_path: params.key });

  const [validateCsvCall, validateCsvResult] = useMutation(request, {
    onError: (error: any, params: HandleCsvParamsType) => {
      params.onErrorCallback(error);
    },
    onSuccess: (res, params: HandleCsvParamsType) => {
      params.onSuccessCallback(res.errors, res.line_count);
    },
  });

  return {
    validateCsvCall,
    validateCsvResult,
  };
};
