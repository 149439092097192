import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { useDownloadContinuousCheckFile } from 'actions';

const DownloadLink = styled(M.Link)`
  display: block;
  flex: 1;
  min-width: 2.6rem;
  max-width: 4rem;
  color: ${colors.uiNavy600};
  &:hover,
  &:visited {
    color: ${colors.uiNavy700};
  }
`;

const StyledCell = styled(M.TableCell)`
  padding-right: 7rem !important;
  white-space: nowrap;
`;

const handleLogReportDownload = (call, e) => {
  e.preventDefault();
  const { redirectUrl } = e.target.dataset;
  call(redirectUrl);
};

const DriverRecordReportsTableCell = ({ header, row, cell }) => {
  const { call } = useDownloadContinuousCheckFile();
  const downloadAndLog = useCallback(
    e => handleLogReportDownload(call, e),
    [call],
  );

  let cellValue;
  switch (header.name) {
    case 'name':
      cellValue = (
        <StyledCell key={cell.id}>
          {row.metadata.candidate_full_name}
        </StyledCell>
      );
      break;
    case 'candidateId':
      cellValue = (
        <StyledCell key={cell.id}>{row.metadata.candidate_id} </StyledCell>
      );
      break;
    case 'date':
      cellValue = (
        <StyledCell key={cell.id}>
          {moment(row.created_at).utc().format('l')}
        </StyledCell>
      );
      break;
    case 'driverRecordReport':
      cellValue = (
        <StyledCell key={cell.id}>
          <DownloadLink
            href='#'
            target='_blank'
            rel='noopener noreferrer'
            onClick={downloadAndLog}
            data-redirect-url={row.download_uri}
          >
            Download
          </DownloadLink>
        </StyledCell>
      );
      break;
    default:
  }
  return cellValue;
};

export default DriverRecordReportsTableCell;
