import React from 'react';
import PostHireContainer from 'containers/PostHire/PostHireContainer';
import CandidateInformation from 'components/Report/CandidateInformation/CandidateInformation';
import SecondaryPanelInvitations from 'modules/adjudication/ui/report/actions/secondary-panel-invitations';
import { useRouteMatch } from 'react-router-dom';
// TODO: migrate Invitations to module
import Invitation from 'components/Candidate/Invitation';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';
import ReportProgressSummary from './report-progress-summary';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const Information: React.FC = () => {
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  const reportId = reportMatch?.params?.reportId;

  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  const invitationId = invitationMatch?.params?.invitationId;

  return (
    <div data-testid='candidate-information-container'>
      {candidateRedesignEnabled && reportId && (
        <ReportProgressSummary reportId={reportId} />
      )}
      {!candidateRedesignEnabled && <CandidateInformation />}
      {candidateRedesignEnabled && (
        <SecondaryPanelInvitations invitationId={invitationId} />
      )}
      <PostHireContainer />
      <Invitation invitationId={invitationId} />
    </div>
  );
};

export default Information;
