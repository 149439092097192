import React, { useCallback, useMemo, memo } from 'react';
import { arePropsEqual } from 'utils';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Template } from 'modules/adjudication/data';
import { namespace } from '../../../../../../../../locales';
import { useDispatch, useModalState } from '../../../../context';

const StyledSection = styled.section`
  .email-template-select > .mastodon-key-value-line-item {
    display: flex !important;
    align-items: baseline;
    gap: 20rem;
  }
`;

type AdverseActionEmailTemplateProps = {
  type: string;
  templates: Template;
};

type TemplateSelectComponentProps = {
  type: string;
  options: Template[];
  onTemplatesChange: Function;
};

const TemplateSelectComponent: React.FC<TemplateSelectComponentProps> = ({
  type,
  options,
  onTemplatesChange,
}) => {
  const { templates } = useModalState('state');
  const selectedTemplate = templates[type];

  return (
    <M.Select
      noLabel
      id={`${type}-select`}
      defaultValue={selectedTemplate || 'placeholder-item'}
      onChange={onTemplatesChange}
    >
      <M.Select.Item disabled hidden value='placeholder-item' />
      {options?.map((template: Template) => {
        return (
          <M.Select.Item
            key={template.id}
            value={template.id}
            text={template.name}
          />
        );
      })}
    </M.Select>
  );
};

const AdverseActionEmailTemplate: React.FC<AdverseActionEmailTemplateProps> = ({
  type,
  templates,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onTemplatesChange = useCallback(
    (e) => {
      dispatch({
        type: 'SET_TEMPLATES',
        payload: {
          type,
          value: e.target.value,
        },
      });
    },
    [dispatch, type],
  );

  const formList = useMemo(
    () => [
      {
        itemKey: t(`${namespace}:report.actions.pre_adverse.templates.keyVal`),
        itemValue: (
          <TemplateSelectComponent
            options={templates.options as Template[]}
            type={type}
            onTemplatesChange={onTemplatesChange}
          />
        ),
      },
    ],
    [t, type, onTemplatesChange, templates?.options],
  );

  if (!templates?.options) return null;

  return (
    <StyledSection>
      <strong>
        {t(`${namespace}:report.actions.pre_adverse.templates.${type}.heading`)}
      </strong>
      <M.KeyValueList className='email-template-select' items={formList} />
    </StyledSection>
  );
};

export default memo(AdverseActionEmailTemplate, arePropsEqual);
