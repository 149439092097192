import { AccountsClient as client } from 'api';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { clearUserCache, toastError, updateAccountState } from 'actions';
import { Account } from 'types/Packages/Account';

export type DrugSetupData = {
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  business_address_street: string;
  business_address_city: string;
  business_address_state: string;
  business_address_zipcode: string;
};

const DRUG_PATH = 'addon_enablement/drug_screening';

export const useDrugSetup = ({ accountId }: { accountId: string }) => {
  const request = (params: DrugSetupData) => setupDrug(accountId, params);
  const dispatch = useDispatch();
  const [call, result] = useMutation(request, {
    onSuccess: (updatedAccount: Account) => {
      dispatch(clearUserCache());
      dispatch(updateAccountState({ updatedAccount }));
    },
    onError: error => {
      dispatch(toastError('Error:', error.message));
    },
  });
  return { call, result };
};

const setupDrug = (
  accountId: string,
  params: DrugSetupData,
): Promise<{ [key: string]: any }> => {
  return client.post(`${accountId}/${DRUG_PATH}`, params);
};
