import { AnyQueryKey, useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { GenericObject } from 'types';
import { clearUserCache, toastError, toastSuccess } from 'actions';
import {
  AddWebhookParams,
  DeleteWebhookParams,
  UpdateWebhookSubscriptionsParams,
} from 'containers/WebhookContainer';
import { useErrorNotifier } from 'hooks';
import {
  webhooks,
  deleteWebhook,
  addWebhook,
  updateWebhookSubscriptions,
} from './actions';

export const useGetWebhooks = (
  accountId: string,
  deleteResult: any,
  addResult: any,
) => {
  const errorNotifier = useErrorNotifier();

  const key: AnyQueryKey = [
    'containers/webhooks',
    { id: accountId, deleteResult, addResult },
  ];

  const request = () => webhooks(accountId);

  return useQuery(key, request, {
    enabled: Boolean(accountId),
    refetchOnWindowFocus: false,
    onError: error => {
      errorNotifier(error, { title: 'Error fetching Webhooks' });
    },
  });
};

export const useDeleteWebhook = () => {
  const dispatch = useDispatch();
  const request = (params: DeleteWebhookParams) => deleteWebhook(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Webhook Deleted'));
    },
    onError: error => {
      dispatch(toastError('Error deleting Webook', error.message));
    },
  });

  return {
    call,
    result,
  };
};

export const useAddWebhook = () => {
  const dispatch = useDispatch();
  const request = (params: AddWebhookParams) => addWebhook(params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Webhook Added'));
    },
    onError: (error: GenericObject) => {
      dispatch(
        toastError(
          'Error adding webhook',
          `${error.response.status} ${error.response.statusText}: ${error.response.data.error}`,
        ),
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useUpdateWebhookSubscriptions = () => {
  const dispatch = useDispatch();

  const request = (params: UpdateWebhookSubscriptionsParams) =>
    updateWebhookSubscriptions(params);

  const [call] = useMutation(request, {
    onSuccess: () => {
      dispatch(clearUserCache());
      dispatch(toastSuccess('Webhook Subscriptions Updated'));
    },
    onError: error => {
      dispatch(
        toastError('Failed to update Webhook Subscriptions', error.message),
      );
    },
  });

  return {
    call,
  };
};
