import { Client } from 'api';
import { PATH } from './constants';

export type Params = {
  account: {
    hierarchy_present: boolean;
    id: string;
  };
  customId: string;
  includeAccountId?: boolean;
  params?: { [key: string]: any };
};

export const list = ({
  customId,
  includeAccountId,
  account: { id },
  params,
}: Params): Promise<{ [key: string]: any }> => {
  const query = includeAccountId ? `?account_id=${id}` : '';
  return Client.get(`${PATH}/${customId}/packages${query}`, {
    params,
  });
};
