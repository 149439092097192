import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const Divider = styled(M.Divider)`
  color: ${colors.uiGrey300};
  weight: 1px;
  border-bottom: 1px solid !important;
  margin-top: 2rem !important;
`;

export default Divider;
