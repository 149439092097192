import { ReportStatuses } from '@dashboard-experience/utils';
import {
  isDatePastEta,
  parseDateString,
} from 'components/Report/Screenings/CountyCriminal/Eta/utils';
import capitalize from 'lodash/capitalize';
import { Exception } from 'types';

// eslint-disable-next-line import/prefer-default-export
export const getSummaryEtaTitle = (
  status: string,
  unresolvedExceptions: Exception[],
  reportPrediction: string | undefined,
) => {
  if (status !== ReportStatuses.PENDING) return capitalize(status);

  const getReportEtaTitle = () => {
    if (!reportPrediction) return '';

    const etaFormattedDate = parseDateString(reportPrediction, true);

    return isDatePastEta(reportPrediction)
      ? 'Estimated: Longer than usual'
      : `Estimated: ${etaFormattedDate}`;
  };

  if (unresolvedExceptions.length) return 'Waiting for candidate';

  const etaTitle = getReportEtaTitle();
  if (etaTitle) return etaTitle;

  return capitalize(status);
};
