import React from 'react';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { Trans, useTranslation } from 'react-i18next';
import Item from '../../collection/search/table/item-name';

export type Props = {
  ccs: Entity;
};

export const Content: React.FC<Props> = ({ ccs }) => {
  const { t } = useTranslation('assess');

  const categorySetName = ccs.name;

  const components = {
    'item-name-link': <Item item={ccs} />,
  };

  return (
    <Trans
      i18nKey='assess:mvr.category-sets.instance.delete.prompt_assigned'
      t={t}
      values={{ count: ccs?.active_rulesets_count, name: categorySetName }}
      components={components}
    />
  );
};

export default Content;
