import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;

  color: ${colors.brandNavy3};
`;

export type Props = {
  title: string;
};

export const Title: React.FC<Props> = ({ title }) => {
  return (
    <StyledTitle data-testid='assess-v2-ui-guideline-quickstart-added-guidelines-category-rule-title'>
      {title}
    </StyledTitle>
  );
};

export default Title;
