import React from 'react';
import { CellProps } from './types';

const PartialSSN: React.FC<CellProps> = ({ candidate }) => {
  const partialSsn = candidate.partial_ssn;
  const text = partialSsn ? `XXX-XX-${partialSsn}` : '';
  return <span>{text}</span>;
};

export default PartialSSN;
