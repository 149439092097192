import React, { useCallback, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import Button from 'components/Button';
import styled from 'styled-components';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { usePauseAdverseAction } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import Guard from './guard';

const StyledButton = styled(Button)`
  width: 100%;
  max-width: none !important;
  margin: 1rem 0 0 !important;
  justify-content: center;
`;

const Pause: React.FC = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const style = {
    borderColor: colors.uiNavy600,
  };

  const [openModal, setOpenModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const currentUser = useUser();
  const { call } = usePauseAdverseAction();

  const { t } = useTranslation();
  const header = t(`${namespace}:report.actions.pause.modal.header`);
  const message1 = t(`${namespace}:report.actions.pause.modal.msg_1`);
  const message2 = t(`${namespace}:report.actions.pause.modal.msg_2`);
  const signed = t(`${namespace}:report.actions.pause.modal.signed`, {
    nameOrEmail: currentUser.full_name || currentUser.email,
  });
  const cancel = t(`${namespace}:report.actions.pause.modal.cancel`);
  const confirm = t(`${namespace}:report.actions.pause.modal.continue`);

  const openConfirmModal = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Pause Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, []);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const toggleSigned = useCallback(() => {
    setIsSigned((prevCheck) => !prevCheck);
  }, [setIsSigned]);

  return (
    <Guard>
      <StyledButton
        data-testid='pause'
        trans={`${namespace}:report.actions.pause.action`}
        style={style}
        onClick={openConfirmModal}
        disabled={report.archived}
      />
      <M.ComposedModal
        onClose={hideConfirmModal}
        open={openModal}
        className='actions-modal'
      >
        <M.ModalHeader closeModal={hideConfirmModal}>
          <h2>{header}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <p>{message1}</p>
          <p style={{ marginBottom: '1rem' }}>{message2}</p>
          <M.Checkbox
            labelText={signed}
            id='signed_by'
            onChange={toggleSigned}
          />
        </M.ModalBody>
        <M.ModalFooter>
          <M.BinaryButtons
            btnLeft={{ name: cancel, onClick: hideConfirmModal }}
            btnRight={{
              name: confirm,
              onClick: handleConfirm,
              disabled: !isSigned,
            }}
            size='sm'
          />
        </M.ModalFooter>
      </M.ComposedModal>
    </Guard>
  );
};

export default Pause;
