import { createContext, useContext } from 'react';
import { Namespace } from '../../api';

export const NamespaceRoute = createContext<Namespace | undefined>(undefined);
export const RootRoute = createContext<string>('');

export const useNamespace = () => useContext(NamespaceRoute);

export const useNamespacedRoute = (path?: string) => {
  const namespace = useContext(NamespaceRoute);
  const root = useContext(RootRoute);
  const base = namespace ? `${root}/${namespace}` : root;
  return path ? `${base}/${path}` : base;
};

const contexts = { useNamespace, NamespaceRoute, RootRoute };

export default contexts;
