import React from 'react';
import moment from 'moment';
import { GenericObject } from 'types';
import { CellProps } from './types';
import { mostRecent } from './utils';

const CreatedAt: React.FC<CellProps> = ({ candidate, report }) => {
  const { reports } = candidate;
  const mostRecentReport: GenericObject | undefined = mostRecent(reports);
  const displayedReport = report || mostRecentReport;

  const { created_at } = displayedReport || candidate;
  const dateStr = created_at ? moment(created_at).format('MMM D, YYYY') : '';

  return <span>{dateStr}</span>;
};

export default CreatedAt;
