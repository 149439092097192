import React from 'react';
import styled from 'styled-components';
import Body from 'components/FairChance/Content';
import Sections from 'components/FairChance/sections';
import { colors } from '@dashboard-experience/mastodon';
import Subsections from '../subsections';

const StyledFooter = styled.div`
  border-bottom: 1px solid ${colors.brandSlate5};
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

type Props = {
  subsection: Subsections;
  text: string;
};

const Footer: React.FC<Props> = ({ subsection, text }) => {
  const footer = (
    <Body
      paragraphs={[text]}
      section={Sections.how_to_evaluate_background_checks}
      subsection={subsection}
    />
  );

  return <StyledFooter>{footer}</StyledFooter>;
};

export default Footer;
