import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import styled from 'styled-components';
import { ACCOUNT_HIERARCHY_UI_CSV_FLAG_KEY } from 'Constants';
import Button from 'components/Button';

import ConfirmationModal from './ConfirmationModal';
import CsvModal from './CsvModal';
import DownloadNodes from './DownloadNodes';

type Props = {
  disableUploads: boolean;
};

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const Container: React.FC<Props> = ({ disableUploads }) => {
  const csvFlagEnabled =
    useFlag(ACCOUNT_HIERARCHY_UI_CSV_FLAG_KEY)?.variantKey === 'enabled';

  const [open, show] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [asyncPost, setAsyncPost] = useState(false);

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);
  const handleConfirmationClose = useCallback(
    () => setShowConfirm(false),
    [showConfirm],
  );

  return csvFlagEnabled ? (
    <StyledDiv>
      <DownloadNodes />
      <M.TooltipDefinition
        align='bottom-right'
        definition={
          disableUploads
            ? 'To continue uploading nodes your account must have only one node.'
            : 'Upload a CSV to create your account hierarchy.'
        }
      >
        <Button
          disabled={disableUploads}
          kind='primary'
          onClick={handleClick}
          trans='Upload nodes'
          data-testid='account-hierarchy-uploadNodesButton'
        />
      </M.TooltipDefinition>
      <CsvModal
        open={open}
        onClose={handleClose}
        setShowConfirm={setShowConfirm}
        setAsyncPost={setAsyncPost}
      />
      <ConfirmationModal
        open={showConfirm}
        onClose={handleConfirmationClose}
        isAsync={asyncPost}
      />
    </StyledDiv>
  ) : null;
};

export default Container;
