import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import Tooltip from './Tooltip';
import Type from '../Type';

const StyledHeader = styled.span`
  color: ${colors.brandNavy4};
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
`;

type Props = {
  header?: Type;
};

const Header: React.FC<Props> = ({ header = Type.Default }) => {
  const { t } = useTranslation('assess:v2');
  const translation: any = t('common.lookback_rules.configurator', {
    returnObjects: true,
  });
  const headerTranslation = t(
    `common.lookback_rules.configurator.header.${header}`,
  );

  return (
    <Container>
      <StyledHeader data-testid='assess-v2-ui-guideline-lookback-period-less-than-misdemeanor-header'>
        {headerTranslation}
      </StyledHeader>
      <Tooltip paragraphs={translation.tooltip} />
    </Container>
  );
};

export default Header;
