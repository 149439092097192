import { Entity } from 'modules/assess/models/mvr/category-code-set';
import React from 'react';
import List from './list';
import Title from './title';

export type Props = {
  item?: Entity;
};

export const Container: React.FC<Props> = ({ item }) => {
  if (!item) {
    return null;
  }

  return (
    <div data-testid='assess-mvr-category-set-assignments'>
      <Title />
      <List category={item} />
    </div>
  );
};

export default Container;
