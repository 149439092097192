/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const InPlaceTextEditor = styled(M.InPlaceTextEditor)`
  #mastodon && {
    display: block;
    .cds--text-input__label-wrapper {
      display: none;
    }
  }
`;
