import React from 'react';
import { StyledImg, StyledSmallImg } from './DirectoryStyledComponents';

/*
  by default there is no size constraint, so the image will fit a flex container
  or render in its natural dimensions

  define a size class only if a particular size needs to be enforced
*/

type ImageProps = {
  src: string;
  altPartial?: string;
  size?: string;
  className?: string;
};

const Flex: React.FC<ImageProps> = ({
  src,
  altPartial = 'application',
  ...rest
}) => {
  if (!src) return null;

  return <StyledImg src={src} alt={`${altPartial} logo`} {...rest} />;
};

const Small: React.FC<ImageProps> = ({
  src,
  altPartial = 'application',
  ...rest
}) => {
  if (!src) return null;

  return <StyledSmallImg src={src} alt={`${altPartial} logo`} {...rest} />;
};

const Logo = {
  Flex,
  Small,
};

export default Logo;
