import React from 'react';
import { Document as CandidateDocument } from 'types';
import { DocumentHeader } from './PLVStyledComponents';
import Document from '../Document';

type Props = {
  documents: CandidateDocument[];
};

const CandidateDocuments: React.FC<Props> = ({ documents }) => {
  return documents && documents.length ? (
    <>
      <DocumentHeader data-testid='screenings-professional-license-verification-candidate-documents-header'>
        Candidate-Uploaded Documents
      </DocumentHeader>
      {documents.map((document: CandidateDocument) => (
        <div
          key={document.id}
          data-testid='screenings-professional-license-verification-candidate-document'
        >
          <Document key={document.id} document={document} />
        </div>
      ))}
    </>
  ) : null;
};

export default CandidateDocuments;
