import React from 'react';
import styled from 'styled-components';

import ReportDateFilter from './ReportDateFilter';
import { styledFilter } from './StyledComponents';

const StyledDiv = styled.div`
  ${styledFilter}
`;

const ReportCreatedFilter = () => {
  return (
    <StyledDiv>
      <ReportDateFilter
        component='ReportCreatedFilter'
        from='reported_after'
        to='reported_before'
      />
    </StyledDiv>
  );
};

export default ReportCreatedFilter;
