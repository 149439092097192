import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const POINTER_TOOLTIP_MAP = [
  {
    pointer: 'Arrest Record',
    tooltip:
      'Developed from records identified through public record database searches',
  },
  {
    pointer: 'Candidate Zip Code',
    tooltip: 'Zip code provided by or on behalf of the candidate',
  },
  {
    pointer: 'Candidate Address',
    tooltip: 'Address provided by or on behalf of the candidate',
  },
  {
    pointer: 'County Search',
    tooltip: 'Developed from records identified through a county search',
  },
  {
    pointer: 'National Search',
    tooltip:
      'Developed from records identified through public record database searches',
  },
  {
    pointer: 'State Search',
    tooltip: 'Developed from records identified through a statewide search',
  },
  {
    pointer: 'Account Policy',
    tooltip: 'Required by account configuration',
  },
  {
    pointer: 'Employment History',
    tooltip: 'Employment history provided by or on behalf of the candidate',
  },
  {
    pointer: 'Education History',
    tooltip: 'Education history provided by or on behalf of the candidate',
  },
  {
    pointer: 'Report Geo',
    tooltip: 'Geo assigned to the report',
  },
  {
    pointer: 'MVR Address',
    tooltip:
      'Developed from address information identified in a motor vehicle report',
  },
  {
    pointer: 'Package Policy',
    tooltip: 'Required by package configuration',
  },
  {
    pointer: 'Candidate Self Disclosure',
    tooltip:
      'Self disclosure information provided by or on behalf of the candidate',
  },
  {
    pointer: 'SSN Trace Address History',
    tooltip: 'Developed from address history identified through an SSN Trace',
  },
  {
    pointer: 'Checkr Initiated',
    tooltip: 'Initiated by Checkr',
  },
];

const TooltipContainer = styled.div`
  #mastodon & {
    display: flex;
    .mastodon-tooltip-definition {
      .cds--definition-tooltip {
        max-width: 25rem;
        padding: 1rem;
        color: ${colors.uiTextPrimaryLight};
        font-size: 14px;
      }

      .mastodon-icon {
        fill: ${colors.uiTextPrimaryLight};
      }

      .court-type:not(:last-child) {
        margin-bottom: 1rem;
      }

      .court-type.selected {
        .mastodon-icon {
          fill: ${colors.uiNavy600};
        }
        .court-title {
          color: ${colors.uiNavy600};
        }
      }

      .cds--definition-term:hover svg {
        fill: ${colors.uiGrey700};
      }
    }
  }
`;

type Props = {
  pointers?: string[];
};

const PointerList: React.FC<Props> = ({ pointers }) => (
  <>
    {pointers?.map(pointer => {
      const p = POINTER_TOOLTIP_MAP.filter(item => {
        return pointer.indexOf(item.pointer) > -1;
      })[0];
      return (
        pointer && (
          <TooltipContainer key={pointer} data-floating-menu-container>
            <M.TooltipLabel>{pointer}</M.TooltipLabel>
            <M.TooltipDefinition
              className='pointer-list-tooltip'
              definition={p?.tooltip || 'No info provided'}
              align='top'
              highlighted={false}
              openOnHover={false}
            >
              <M.Icon icon='InformationFilled' />
            </M.TooltipDefinition>
          </TooltipContainer>
        )
      );
    })}
  </>
);

export default PointerList;
