import { ExpungementsClient as client } from 'utils/APIClient';
import { PATH } from './constants';

export type ListParams = {
  reportId: string;
  hasCountyCriminalSearches: boolean;
  params?: { [key: string]: any };
};
export const list = ({
  reportId,
  params,
}: ListParams): Promise<{ [key: string]: any }> => {
  return client.get(
    `${PATH}?report_id=${reportId}&sort_by=updated_at&sort_direction=desc`,
    {
      params,
    },
  );
};
