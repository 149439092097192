import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as Entity from 'modules/assess/models/rules/premade';
import { M } from '@dashboard-experience/mastodon';
import { Namespace } from 'modules/assess/api';
import ExpungeableRule from './ExpungeableRule';

// eslint-disable-next-line react/function-component-definition
export function Description({
  namespace,
  rule,
}: {
  namespace: Namespace;
  rule: Entity.Type;
}) {
  const { t } = useTranslation('');
  const label = t('nouns.description');
  const text = getRuleDescription(namespace, rule, t);

  return (
    <M.GridRow data-testid='assess-rules-standard-table-description'>
      <M.GridCol>
        <strong>{label}</strong>
        <br />
        {rule.code === 'ACCEPT_EXPUNGEABLE_CHARGES' && <ExpungeableRule />}
        {rule.code !== 'ACCEPT_EXPUNGEABLE_CHARGES' && text}
      </M.GridCol>
    </M.GridRow>
  );
}

function getRuleDescription(
  namespace: string,
  rule: Entity.Type,
  t: TFunction,
) {
  const description: string | string[] = t(
    `assess:rules.standard.rules.${namespace}.${rule.code}.description`,
    {
      returnObjects: true,
    },
  );

  const text = Array.isArray(description)
    ? description.map((p, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={i}>{p}</p>
      ))
    : description;

  return text;
}

export default Description;
