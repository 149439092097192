import { ADD_ONS_PRICES } from '../constants/screenings';
import BASE_PACKAGES from '../constants/basePackages';
import { AddOnsType, BasePackage, Candidate } from '../types';
import { countCandidates } from './candidates';

export function priceToString(price: number) {
  return `$${(price / 100).toFixed(2)}`;
}

export function getAddOnsTotal(addOns: AddOnsType[]) {
  return addOns.reduce((acc, addOn) => acc + ADD_ONS_PRICES[addOn], 0);
}

export function getPackagePriceWithAddOns(
  selectedPackage: BasePackage,
  addOns: AddOnsType[],
) {
  const basePackagePrice = BASE_PACKAGES[selectedPackage].price;
  const addOnsPrice = getAddOnsTotal(addOns);
  return basePackagePrice + addOnsPrice;
}

export function calculateSubtotal(
  selectedPackage: BasePackage,
  addOns: AddOnsType[],
  candidates: Candidate[],
) {
  const packagePrice = getPackagePriceWithAddOns(selectedPackage, addOns);
  const numCandidates = countCandidates(candidates);
  return numCandidates > 0
    ? priceToString(packagePrice * numCandidates)
    : priceToString(packagePrice);
}

export function calculateSubtotalAsNumber(
  selectedPackage: BasePackage,
  addOns: AddOnsType[],
  candidates: Candidate[],
) {
  const packagePrice = getPackagePriceWithAddOns(selectedPackage, addOns);
  const numCandidates = countCandidates(candidates);
  const totalPriceToUse =
    numCandidates > 0 ? packagePrice * numCandidates : packagePrice;
  const totalPrice = (totalPriceToUse / 100).toFixed(2);
  return parseFloat(totalPrice);
}
