import React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {};

export const Header: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t('assess:activate.panel.header');

  return <h4>{text}</h4>;
};

export default Header;
