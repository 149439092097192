import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { debounce } from 'lodash';
import pluralize from 'pluralize';

const SearchHeading = styled.h2`
  color: ${colors.uiTextPrimaryLight};
`;

export const ButtonAsLink = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: ${sizes.medium};
  padding: 0 !important;
`;

const ErrorCodeLabels = {
  all: 'All',
  invalid_pii: "PII doesn't match / can't be located",
  dl_already_enrolled: 'License already enrolled',
  invalid_pii_for_status_monitoring:
    "Can't enroll in license status monitoring",
  candidate_ineligible_for_enrollment: "Person can't enroll electronically",
  dl_state_changed: 'Driver license changed states',
};

const IneligiblePersonsSearch = ({
  resultsCount = 0,
  onChangeCandidateId,
  candidateId,
  onChangeErrorType,
  errorCode,
}) => {
  const updateCandidateId = useMemo(
    () =>
      debounce(e => {
        onChangeCandidateId(e.target.value);
      }, 600),
    [onChangeCandidateId],
  );

  const updateErrorType = useCallback(
    e => {
      let selectedErrorType = e?.selectedItem;
      if (!selectedErrorType || selectedErrorType === 'all') {
        selectedErrorType = '';
      }
      onChangeErrorType(selectedErrorType);
    },
    [onChangeErrorType],
  );

  const resetFilters = useCallback(() => {
    document.getElementById('candidate-id-search').value = '';
    onChangeCandidateId('');
    onChangeErrorType('');
  }, [onChangeCandidateId, onChangeErrorType]);

  const errorCodeToLabel = function () {
    return error_code => ErrorCodeLabels[error_code];
  };

  return (
    <M.Container
      id='ineligible-persons-search'
      data-testid='ineligible-persons-search'
      style={{ maxWidth: 'unset' }}
    >
      <M.Container.Row>
        <M.Container.Col>
          <SearchHeading>Search</SearchHeading>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          <M.Input
            id='candidate-id-search'
            name='candidate-id'
            labelText='Candidate ID'
            placeholder='Enter Candidate ID'
            onChange={updateCandidateId}
          />
        </M.Container.Col>
        <M.Container.Col>
          <M.Dropdown
            id='error-code-selector'
            titleText='Error type'
            label='Error type'
            items={Object.keys(ErrorCodeLabels)}
            itemToString={errorCodeToLabel()}
            onChange={updateErrorType}
            selectedItem={errorCode || 'all'}
          />
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          {!isNaN(resultsCount) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ marginRight: '1rem' }}
              >{`${resultsCount} ${pluralize('result', resultsCount)}`}</div>
              {(candidateId !== '' || errorCode !== '') && (
                <ButtonAsLink
                  role='button'
                  type='button'
                  onClick={resetFilters}
                >
                  Reset filters
                </ButtonAsLink>
              )}
            </div>
          )}
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

IneligiblePersonsSearch.propTypes = {
  resultsCount: PropTypes.number,
  onChangeCandidateId: PropTypes.func.isRequired,
  candidateId: PropTypes.string,
  onChangeErrorType: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
};

export default IneligiblePersonsSearch;
