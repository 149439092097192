import React from 'react';
import Button from 'components/Button';

export type Props = {
  disabled?: boolean;
  onCreate: () => void;
};

export const Save: React.FC<Props> = ({ disabled, onCreate }) => {
  return (
    <Button
      disabled={disabled}
      kind='primary'
      trans='assess:context_dates.create.dialog.save'
      onClick={onCreate}
    />
  );
};

export default Save;
