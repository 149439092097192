import { isEmpty, isNil } from 'lodash';
import { Map as Rules } from 'modules/assess/models/rules/entity';
import {
  List as LookbackRules,
  Type as LookbackRule,
} from 'modules/assess/models/rules/lookback';
import {
  Map as DiffRules,
  Type as DiffType,
} from 'modules/assess/models/rulesets/version-diff/rules-changes';

// Sometimes the current url would have an '/' at the end, causing navigation formatting problems.
export function cleanCurrentUrl(url: string): string {
  return url.replace(/\/$/, '');
}

// We use underscores for complex words in our translation files, but hyphens are used in urls.
// This is needed when looking up navigation enum values in our translation files.
export function fromUrlToTranslation(translation: string): string {
  return translation.replaceAll('-', '_');
}

// Lookback rules that have no values are still triggered as review.
// For the purposes of displaying the number of configured rules we filter those out.
export function getDefinedLookbackRules(result: LookbackRules) {
  return result.reduce(reduceEmptyLookbackRules, []);
}

export function formatString(toFormat: string, params: string[]): string {
  return toFormat.replace(/{(\d+)}/g, (match, index) => {
    return typeof params[index] !== 'undefined' ? params[index] : match;
  });
}

export function isEmptyRules(rules?: Rules): boolean {
  if (!rules) return true;

  const lookbackRules = Object.values(rules.lookback);

  return (
    rules.custom.length === 0 &&
    rules.count.length === 0 &&
    isEmptyLookbackRules(lookbackRules) &&
    rules.premade.length === 0
  );
}

export function isEmptyDiff(diff?: DiffType) {
  if (!diff) {
    return true;
  }

  const emptyDiffRules = isEmptyDiffRules(diff.rules);
  const emptySettings = diff.settings.length === 0;
  return emptyDiffRules && emptySettings;
}

export function isEmptyDiffRules(rules: DiffRules): boolean {
  const emptyLookbackRules = isEmpty(rules.lookback);

  return (
    rules.custom.length === 0 &&
    rules.count.length === 0 &&
    rules.premade.length === 0 &&
    emptyLookbackRules
  );
}

export function isEmptyLookbackRule(lookbackRule: LookbackRule): boolean {
  return (
    isNil(lookbackRule.felony.eligible) &&
    isNil(lookbackRule.misdemeanor.eligible) &&
    isNil(lookbackRule.petty_offense.eligible) &&
    isNil(lookbackRule.felony.escalated) &&
    isNil(lookbackRule.misdemeanor.escalated) &&
    isNil(lookbackRule.petty_offense.escalated)
  );
}

function isEmptyLookbackRules(lookbackRules: LookbackRules): boolean {
  return lookbackRules.every(isEmptyLookbackRule);
}

function reduceEmptyLookbackRules(result: LookbackRules, rule: LookbackRule) {
  if (!isEmptyLookbackRule(rule)) {
    result.push(rule);
  }
  return result;
}
