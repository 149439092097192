import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const ModalBodyText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding-bottom: 1.5rem;

  a {
    font-size: 1rem;
  }
`;

type PaymentProfileModalProps = {
  showPaymentProfileModal: boolean;
  handleClose: Function;
};

const PaymentProfileModal: React.FC<PaymentProfileModalProps> = ({
  showPaymentProfileModal,
  handleClose,
}) => {
  return (
    <M.ComposedModal
      open={showPaymentProfileModal}
      onClose={handleClose}
      data-testid='payment-profile-modal'
    >
      <M.ModalHeader closeModal={handleClose}>
        <h2>Who should we invoice?</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <ModalBodyText>
          To provide you flexibility when it comes to billing, you have the
          option to choose a payment profile for this order. These profiles can
          represent different departments or cater for different currencies,
          accommodating your diverse business requirements. This flexibility
          helps streamline the way you manage your invoices.
        </ModalBodyText>
        <ModalBodyText>
          By default, your new order will be invoiced under your main account
          payment profile.
        </ModalBodyText>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default PaymentProfileModal;
