import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Container = styled.div`
  padding: 24px 0px 20px 0;
  color: ${colors.brandNavy4};
`;

const BoldTypography = styled.p`
  font-weight: 800;
  padding-bottom: 16px;
  font-size: 14px !important;
`;

const TableStyled = styled.table`
  width: 100%;
  border: 1px solid ${colors.brandSlate2};
  border-radius: 10px;
  border-collapse: initial;
`;

const TableHeaderGroup = styled.tr`
  font-size: 14px !important;
  font-weight: 800;
  background-color: ${colors.brandSlate1};
  height: 40px;
`;

const TableHeaderItem = styled.th`
  padding: 13px 22px;

  &:first-child {
    text-align: left;
    border-top-left-radius: 10px;
  }

  &:last-child {
    text-align: right;
    border-top-right-radius: 10px;
  }
`;

const TableBodyGroup = styled.tr``;

const TableBodyItem = styled.td`
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.brandSlate2};
`;

const DrugName = styled.p`
  margin-bottom: 0 !important;
`;

const Description = styled.p`
  color: ${colors.brandSlate5} !important;
  margin-bottom: 0 !important;
`;

const DRUG_SCREENING_LIST = [
  {
    name: 'DS_5_PANEL',
    displayName: '5-panel',
    description: 'Tests Amphetamine, Cocaine, Marijuana, Opiates, PCP',
  },
  {
    name: 'DS_5_PANEL_NO_THC',
    displayName: '5-panel no THC',
    description: 'Tests everything in 5-panel minus Marijuana',
  },
  {
    name: 'DS_INSTANT_5PANEL',
    displayName: 'Instant 5-Panel',
    description: 'Rapid test for everything in 5 panel',
  },
  {
    name: 'DS_7_PANEL',
    displayName: '7-panel',
    description:
      'Tests Amphetmine, Cocaine, Marijuana, Opiates, PCP, Barbiturate and Benzodiazepine',
  },
  {
    name: 'DS_9_PANEL',
    displayName: '9-panel',
    description:
      'Tests Amphetamine, Cocaine, Marijuana, Opiates, PCP, Barbiturate, Benzodiazepine, Propoxyphene and Methadone',
  },
  {
    name: 'DS_9_PANEL_NO_THC',
    displayName: '9-panel no THC',
    description: 'Tests everything in 9 panel minus Marijuana',
  },
  {
    name: 'DS_10_PANEL',
    displayName: '10-panel',
    description:
      'Tests Amphetamine, Cocaine, Marijuana, Opiates, PCP, Barbiturate, Benzodiazepine, Propoxyphene, Methadone and Methaqualone',
  },
  {
    name: 'DS_10_PANEL_NO_THC',
    displayName: '10-panel no THC',
    description: 'Tests everything in 10 panel minus Marijuana',
  },
];

const DrugPriceList: React.FC<{}> = () => {
  return (
    <Container>
      <BoldTypography>
        You can use the following drug tests after setup. Prices are for
        in-network locations only.
      </BoldTypography>

      <TableStyled>
        <thead>
          <TableHeaderGroup>
            <TableHeaderItem data-testid='drug-table-header-name'>
              Name
            </TableHeaderItem>
            <TableHeaderItem data-testid='drug-table-header-price'>
              Price
            </TableHeaderItem>
          </TableHeaderGroup>
        </thead>

        <tbody>
          {DRUG_SCREENING_LIST.map(drugScreening => {
            return (
              <TableBodyGroup key={drugScreening.name}>
                <TableBodyItem data-testid='drug-table-body-name'>
                  <DrugName>{drugScreening.displayName} </DrugName>
                  <Description>{drugScreening.description}</Description>
                </TableBodyItem>
                <TableBodyItem data-testid='drug-table-body-price'>
                  <p>$60.00</p>
                </TableBodyItem>
              </TableBodyGroup>
            );
          })}
        </tbody>
      </TableStyled>
    </Container>
  );
};

export default DrugPriceList;
