import OrderBackgroundCheck from 'components/Packages/OrderBackgroundCheck';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { RumComponentContextProvider } from '@dashboard-experience/utils';
import { ORDER_REDESIGN_KEY } from 'Constants';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { M, colors } from '@dashboard-experience/mastodon';
import Provider from 'components/Packages/Provider';
import UserContext from 'context/CurrentUser';
import AmplitudeExperimentWrapper from 'components/AmplitudeExperimentWrapper';
import { AwaitingCredentialingNotification } from '../components/Search';

const OrderBackgroundCheckContainer = styled.div`
  @media only screen and (max-width: 1000px) {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 9px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${colors.uiGrey200};
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    padding-top: 1.5rem;
  }
`;

const StyledNotificationDiv = styled.div`
  margin-top: 2rem;
`;

const OrderBackgroundCheckPage = () => {
  const { contextId } = useContext(UIContext);
  const currentUser = useContext(UserContext);
  const orderRedesignKey = useFlag(ORDER_REDESIGN_KEY);
  const { account } = currentUser;

  useEffect(() => {
    if (orderRedesignKey?.variantKey !== 'enabled' && contextId) {
      updateParentWindowUrl({
        path: '/invitations/new',
        contextId,
        reload: true,
      });
    }
  }, [orderRedesignKey, contextId]);

  const showAwaitingCredentialingNotification =
    account?.pre_credentialed_invitations_allowed &&
    !account?.api_authorized &&
    !account?.authorized &&
    !account?.credentialed_at;

  return account ? (
    <Provider account={account}>
      <RumComponentContextProvider componentName='OrderBackgroundCheck-index'>
        <AmplitudeExperimentWrapper>
          <OrderBackgroundCheckContainer
            data-testid='order-background-check-container'
            data-dd-privacy='allow'
          >
            <h2 data-testid='order-background-check-header'>
              Order background check
            </h2>
            {showAwaitingCredentialingNotification && (
              <StyledNotificationDiv>
                <AwaitingCredentialingNotification />
              </StyledNotificationDiv>
            )}
            <OrderBackgroundCheck />
          </OrderBackgroundCheckContainer>
        </AmplitudeExperimentWrapper>
      </RumComponentContextProvider>
    </Provider>
  ) : (
    <M.LoadingSpinner />
  );
};

export default OrderBackgroundCheckPage;
