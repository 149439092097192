import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Button from 'components/Button';
import { useUpdate as useSettingsUpdate } from 'modules/assess/ui/settings/api/hooks';
import { Trans, useTranslation } from 'react-i18next';
import StepTitle from './StepTitle';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  box-shadow: 2px 2px 10px 0px ${colors.uiGrey400};
  border-radius: 5px;
  border: 1px solid ${colors.uiAqua300};
  padding: 2em;
  margin-top: 1em;
  color: ${colors.uiTextPrimaryLight} !important;
  b {
    font-weight: bold;
  }
`;

const StyledButtonContainer = styled.div`
  :not(#mastodon) .mastodon-button.primary {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

type Props = {
  stepIndex: number;
  totalSteps: number;
  onClose?: (event?: SyntheticEvent) => void;
};

export const StepActivate: React.FC<Props> = ({
  stepIndex,
  totalSteps,
  onClose,
}) => {
  const { t } = useTranslation('assess');
  const action = useSettingsUpdate();

  useEffect(() => {
    if (action.result.status === 'success' && onClose) {
      onClose();
      action.result.reset();
    }
  }, [action.result, onClose]);

  const handleActivate = useCallback(() => {
    action.call({ assess_enabled: true });
  }, [action]);

  return (
    <div>
      <StepTitle
        stepIndex={stepIndex}
        totalSteps={totalSteps}
        title={t('activate.steps.activate.title')}
      />
      <Container>
        <Trans t={t} i18nKey='assess:activate.steps.activate.body'>
          <p>
            <b>Click below to activate Assess for your account</b>
          </p>
        </Trans>
        <StyledButtonContainer>
          <Button
            label={t('translation:verbs.activate')}
            onClick={handleActivate}
          />
        </StyledButtonContainer>
      </Container>
    </div>
  );
};

export default StepActivate;
