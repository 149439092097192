import styled from 'styled-components';

const ConfigSection = styled.section`
  .flex-container {
    display: flex !important;
    .text-container {
      flex: 1;
      p {
        padding-right: 0;
        margin-bottom: 0 !important;
      }
    }
  }
`;

export default ConfigSection;
