import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const InternalContainer = styled.div.attrs({
  className: 'country-toggle-button-internal-container',
})`
  gap: 8px;
  display: flex;
  flex-direction: row;
`;

export const Typography = styled.span.attrs({
  className: 'country-toggle-button-typography p3 bold',
})`
  #mastodon & {
    color: ${colors.uiLinkPrimaryLightDefault};
    line-height: 16.8px;

    &:hover {
      color: ${colors.uiLinkPrimaryLightHover};
    }
  }
`;

export const Button = styled.button.attrs({
  className: 'country-toggle-button',
  type: 'button',
})`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;

  @media (max-width: 720px) {
    padding: 6px 0;
  }
`;
