import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { useCancelAdverseAction } from 'modules/adjudication/api';
import { ConfirmDangerModal } from 'components';
import Guard from './guard';
import H3 from '../../common/h3';

const Cancel: React.FC = () => {
  const style = {
    maxWidth: 'none',
    width: '100%',
    justifyContent: 'center',
    marginTop: '1rem 0 0',
  };
  const trackEvent = useTrackEvent();
  const [openModal, setOpenModal] = useState(false);
  const { call } = useCancelAdverseAction();
  const { t } = useTranslation();
  const header = t(`${namespace}:report.actions.cancel.modal.header`);
  const warn = <H3>{t(`${namespace}:report.actions.cancel.modal.msg`)}</H3>;
  const confirm = t(`${namespace}:report.actions.cancel.modal.confirm`);
  const label = t(`${namespace}:report.actions.cancel.action`);

  const openConfirmModal = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Cancel Adverse Action' },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [setOpenModal]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  return (
    <Guard>
      <div style={{ padding: 0, marginTop: '1rem' }}>
        <M.Button data-testid='cancel' style={style} onClick={openConfirmModal}>
          {label}
        </M.Button>
      </div>
      {/* @ts-ignore */}
      <ConfirmDangerModal
        className='actions-modal'
        header={header}
        callback={handleConfirm}
        confirmBtnName={confirm}
        open={openModal}
        hideModal={hideConfirmModal}
        message={warn}
      />
    </Guard>
  );
};

export default Cancel;
