import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import * as FilterComponents from './Components';

import {
  Container,
  TitleRow,
  Title,
  FilterRow,
  Row,
  Right,
  KeywordFilter,
} from './ExperimentalSearchFiltersStyledComponents';
import StatWidget from '../StatWidget';
import FilterCount from './FilterCount';
import RenderFilters from './RenderFilters';
import { useSearchState } from '../search-context';

// These have hardcoded data and are unfunctional currently, for hack-week demo.
// If we decide to productize, these need to function properly, otherwise remove.
const StatWidgets = () => (
  <M.Container.Row>
    <M.Container.Col md={3} lg={4}>
      <StatWidget className='info-needed'>
        <h3>Information needed</h3>
        <div style={{ fontSize: '24px' }}>14</div>
      </StatWidget>
    </M.Container.Col>
    <M.Container.Col md={3} lg={4}>
      <StatWidget className='info-needed'>
        <h3>Pending</h3>
        <div style={{ fontSize: '24px' }}>54</div>
      </StatWidget>
    </M.Container.Col>
    <M.Container.Col md={3} lg={4}>
      <StatWidget className='info-needed'>
        <h3>Review</h3>
        <div style={{ fontSize: '24px' }}>26</div>
      </StatWidget>
    </M.Container.Col>
    <M.Container.Col md={3} lg={4}>
      <StatWidget className='info-needed'>
        <h3>Clear</h3>
        <div style={{ fontSize: '24px' }}>253</div>
      </StatWidget>
    </M.Container.Col>
  </M.Container.Row>
);

const ExperimentalSearchFilterUI: React.FC<{
  filters: Array<Array<string>>;
  isLoading: boolean;
}> = ({ filters, isLoading }) => {
  const { showAllFilters, savedSearches, savedSearchId } = useSearchState();

  let filterCount = 0;
  if (savedSearchId && savedSearches) {
    const params = savedSearches.find(
      // TODO - type saved searches
      (s: any) => s.id === savedSearchId,
    )?.params;
    if (params) {
      filterCount = Object.keys(params).length;
    }
  }

  // Hide stat widgets until functional
  const showStatWidgets = false;

  return (
    <Container type='transparent' padding='50px'>
      {showStatWidgets && <StatWidgets />}
      <TitleRow>
        <Title>Candidate search</Title>
        <FilterComponents.SavedSearchId />
      </TitleRow>
      <Row>
        <KeywordFilter labelText={false} />
        <FilterRow>
          <FilterComponents.AllFiltersToggle />
          <FilterCount filterCount={filterCount} />
          <Right>
            <FilterComponents.Results isLoading={isLoading} />
          </Right>
        </FilterRow>
        {showAllFilters && <RenderFilters filters={filters} />}
      </Row>
      <Row>
        <M.Divider />
      </Row>
    </Container>
  );
};

export default ExperimentalSearchFilterUI;
