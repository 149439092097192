import React from 'react';
import Base, { Props as BaseProps } from 'components/Dialog';
import { useTranslation } from 'react-i18next';

export enum Action {
  create = 'create',
  delete = 'delete',
  deleteDraft = 'delete-draft',
  duplicate = 'duplicate',
  publish = 'publish',
  update = 'update',
  versions = 'versions',
}

export interface Props extends BaseProps {
  action: Action;
  name?: string;
  type?: string;
}

export const BaseDialog: React.FC<Props> = ({
  action,
  content,
  footer,
  onClose,
  open,
  name,
  type,
}) => {
  const titles = useTitles(action, name, type);

  return (
    <Base
      content={content}
      footer={footer}
      onClose={onClose}
      open={open}
      titles={titles}
    />
  );
};

function useTitles(action: Action, name?: string, type?: string) {
  const { t } = useTranslation();
  const titles = [t(`translation:nouns.${type}`)];

  if (name) {
    titles.push(name);
  }

  if (action) {
    const verb = t(`assess:common.base-dialog.actions.${action}`);
    titles.push(verb);
  }

  return titles;
}

export default BaseDialog;
