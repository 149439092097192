import _ from 'lodash';
import {
  FETCH_IDP_SAML_CONFIG_REQUEST,
  FETCH_IDP_SAML_CONFIG_SUCCESS,
  FETCH_IDP_SAML_CONFIG_FAILURE,
} from '../actions';

import { extractError } from '../utils/APIClient';
import { httpHelper } from './reducerHelpers';

export const initialState = {
  config: {
    sso_url: '',
    audience: '',
  },
  fetchRequest: {
    ...httpHelper.initialState,
  },
};

// eslint-disable-next-line default-param-last
export const IdpSamlConfigReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case FETCH_IDP_SAML_CONFIG_REQUEST:
      return {
        ...state,
        fetchRequest: {
          ...httpHelper.onRequest,
        },
      };
    case FETCH_IDP_SAML_CONFIG_SUCCESS:
      return {
        ...state,
        config: _.pick(action.payload, ['sso_url', 'audience']),
        fetchRequest: {
          ...httpHelper.onSuccess,
        },
      };
    case FETCH_IDP_SAML_CONFIG_FAILURE:
      return {
        ...state,
        fetchRequest: {
          ...httpHelper.onFailure,
          errorMsg: extractError(action.error),
        },
      };
    default:
      return state;
  }
};
