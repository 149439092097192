import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const BaseWrapper = styled.div`
  .mastodon-multi-select-with-sections-wrapper {
    width: 375px;
  }
  .cds--list-box--disabled {
    .cds--list-box__field {
      background-color: ${colors.uiGrey100} !important;
    }
  }
  & .cds--tag {
    background-color: ${colors.brandSlate2} !important;
    svg {
      fill: ${colors.brandSlate5} !important;
    }
    .cds--tag__label {
      color: ${colors.brandSlate7} !important;
      font-size: 12px !important;
    }
  }
  .cds--list-box__menu-item--active {
    background-color: ${colors.brandSlate2} !important;
    .cds--checkbox-label {
      color: ${colors.brandNavy2} !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .cds--checkbox-label[data-contained-checkbox-state='true']::before {
    background-color: ${colors.brandNavy2};
  }
  .cds--list-box[data-invalid] .cds--list-box__field {
    border-bottom: 1px solid ${colors.uiOrange600} !important;
  }
  .cds--combo-box[data-invalid] .cds--text-input:not(:focus) {
    outline: none !important;
  }
  .menu-item--disabled {
    .cds--checkbox-label::before {
      border-color: ${colors.brandSlate3} !important;
    }
  }
  .cds--list-box__field {
    input::placeholder {
      color: ${colors.brandSlate6} !important;
      font-style: italic;
      font-size: 14px;
    }
    .cds--tag--filter {
      background-color: ${colors.brandNavy3} !important;
      .cds--tag__label {
        color: ${colors.uiGrey0} !important;
      }
      & :hover {
        background-color: ${colors.brandNavy3} !important;
      }
    }
  }

  .disabled-button {
    cursor: not-allowed;

  .disabled-button:disabled {
    cursor: not-allowed; 
  }
`;

export const Icon = styled.div`
  padding: 6px;
  border-radius: 50%;
  height: 32px;
  width: 32px;

  background: ${colors.brandSlate2};
  color: ${colors.uiNavy600};
  margin-top: -2px;
  margin-right: 16px;
`;

export const CompletedStepIcon = styled(Icon)`
  margin-bottom: 16px;
`;

export const ONE = 'ONE';
export const TWO = 'TWO';
export const THREE = 'THREE';
export const FOUR = 'FOUR';

type ModalSteps = {
  [key: number]: string;
};
export const MODAL_STEPS: ModalSteps = {
  1: ONE,
  2: TWO,
  3: THREE,
  4: FOUR,
};

export const validateName = (name: string): boolean => {
  const regex = /^[a-zA-Z\s\-.0-9]+$/;
  return regex.test(name);
};

export const validateFirstName = (
  name: string,
  isOptional: boolean = false,
): boolean => {
  if (isOptional && !name) return true;

  const regex = /^[a-zA-Z0-9ªµºÀ-ÖØ-öø-ÿ\-'.,]+$/;
  return regex.test(name);
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const consecutiveDotRegex = /\.\./;

  if (!emailRegex.test(email) || consecutiveDotRegex.test(email)) {
    return false;
  }

  return true;
};

export const validatePhoneNumber = (
  phoneNumber: string,
  isOptional: boolean = false,
): boolean => {
  if (isOptional && !phoneNumber) return true;

  const phoneNumberRegex = /^[\d()-\s]+$/;
  return (
    phoneNumberRegex.test(phoneNumber) &&
    phoneNumber.length >= 10 &&
    phoneNumber.length <= 15
  );
};

export const validateZipCode = (zipCode: string): boolean => {
  const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
  return zipCodeRegex.test(zipCode) && zipCode !== '11111';
};

export const validateStreet = (street: string): boolean => {
  const streetRegex = /^[a-zA-Z0-9\s.,'-]+$/;
  return streetRegex.test(street);
};

export const validateCity = (city: string): boolean => {
  const cityRegex = /^[a-zA-Z\s.-]+$/;
  return cityRegex.test(city);
};

export type Section = {
  title: string;
  description: string;
};

export type SelectedScreening = {
  label: string;
  section: string;
  value: string;
  price?: string;
};

type ValidationError = {
  isValid: boolean;
  error: string;
};

export type InitialValidations = {
  email: ValidationError;
  phoneNumber: ValidationError;
  city: ValidationError;
  zipcode: ValidationError;
  state: ValidationError;
  street: ValidationError;
  name: ValidationError;
};

export type InitialCreditReportValidations = {
  email: ValidationError;
  phoneNumber: ValidationError;
  city: ValidationError;
  zipcode: ValidationError;
  state: ValidationError;
  street: ValidationError;
  name: ValidationError;
  title: ValidationError;
  businessType: ValidationError;
};

export const INITIAL_VALIDATIONS: InitialValidations = {
  email: { isValid: true, error: '' },
  phoneNumber: { isValid: true, error: '' },
  city: { isValid: true, error: '' },
  zipcode: { isValid: true, error: '' },
  state: { isValid: true, error: '' },
  street: { isValid: true, error: '' },
  name: { isValid: true, error: '' },
};

export const INITIAL_CREDIT_REPORT_VALIDATIONS: InitialCreditReportValidations =
  {
    email: { isValid: true, error: '' },
    phoneNumber: { isValid: true, error: '' },
    city: { isValid: true, error: '' },
    zipcode: { isValid: true, error: '' },
    state: { isValid: true, error: '' },
    street: { isValid: true, error: '' },
    name: { isValid: true, error: '' },
    title: { isValid: true, error: '' },
    businessType: { isValid: true, error: '' },
  };

export const fieldValidator = (
  email: string,
  phoneNumber: string,
  city: string,
  zip: string,
  street: string,
  state: string,
  name: string,
) => {
  let fieldsAreValid = true;
  let build = {};

  if (!validateName(name)) {
    fieldsAreValid = false;
    build = {
      ...build,
      name: {
        isValid: false,
        error: 'Enter a contact name',
      },
    };
  }

  if (!validateEmail(email)) {
    fieldsAreValid = false;
    build = {
      ...build,
      email: {
        isValid: false,
        error:
          email === '' ? 'Enter an email address' : 'Invalid email address',
      },
    };
  }

  if (!validatePhoneNumber(phoneNumber)) {
    fieldsAreValid = false;
    build = {
      ...build,
      phoneNumber: {
        isValid: false,
        error:
          phoneNumber === '' ? 'Enter a phone number' : 'Invalid phone number',
      },
    };
  }

  if (!validateCity(city)) {
    fieldsAreValid = false;
    build = {
      ...build,
      city: {
        isValid: false,
        error: city === '' ? 'Enter a city' : 'Invalid city',
      },
    };
  }

  if (!validateZipCode(zip)) {
    fieldsAreValid = false;
    build = {
      ...build,
      zipcode: {
        isValid: false,
        error: zip === '' ? 'Enter a ZIP code' : 'Invalid ZIP code',
      },
    };
  }

  if (!validateStreet(street)) {
    fieldsAreValid = false;
    build = {
      ...build,
      street: {
        isValid: false,
        error: street === '' ? 'Enter a company address' : 'Invalid street',
      },
    };
  }

  if (state === '') {
    fieldsAreValid = false;
    build = {
      ...build,
      street: {
        isValid: false,
        error: 'Enter a state',
      },
    };
  }

  return {
    fieldsAreValid,
    invalidFields: { ...INITIAL_VALIDATIONS, ...build },
  };
};

export const creditReportFieldValidator = (
  email: string,
  phoneNumber: string,
  city: string,
  zip: string,
  street: string,
  state: string,
  name: string,
  title: string,
  businessType: string,
) => {
  let fieldsAreValid = true;
  let build = {};

  const inCommonFieldValidations = fieldValidator(
    email,
    phoneNumber,
    city,
    zip,
    street,
    state,
    name,
  );

  fieldsAreValid = inCommonFieldValidations.fieldsAreValid;
  build = inCommonFieldValidations.invalidFields;

  if (!title) {
    fieldsAreValid = false;
    build = {
      ...build,
      title: {
        isValid: false,
        error: 'Enter a title',
      },
    };
  }

  if (!businessType) {
    fieldsAreValid = false;
    build = {
      ...build,
      businessType: {
        isValid: false,
        error: 'Enter a business type',
      },
    };
  }

  return {
    fieldsAreValid,
    invalidFields: { ...INITIAL_CREDIT_REPORT_VALIDATIONS, ...build },
  };
};

export const mapBackendFieldsToFrontend = (field: string) => {
  if (field === 'contact_name') {
    return 'name';
  }
  if (field === 'contact_email') {
    return 'email';
  }
  if (field === 'business_address_street') {
    return 'street';
  }
  if (field === 'business_address_city') {
    return 'city';
  }
  if (field === 'business_address_state') {
    return 'state';
  }
  if (field === 'business_address_zipcode') {
    return 'zip';
  }
  return '';
};

export const getInvalidErrorMessage = (field: string) => {
  if (field === 'email') {
    return 'Invalid email address';
  }
  if (field === 'phoneNumber') {
    return 'Invalid phone number';
  }
  if (field === 'zip') {
    return 'Invalid Invalid ZIP code';
  }
  if (field === 'city') {
    return 'Invalid city';
  }
  if (field === 'state') {
    return 'Invalid state';
  }

  return '';
};
