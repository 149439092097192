import React, { useCallback } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Humanize from 'humanize-plus';
import { ManualBulkUploadData } from '../SharedItems';

const UploadBox = styled.div`
  align-items: center;
  background-color: ${colors.bgPrimaryLight};
  border-radius: 6px;
  border: 1px solid ${colors.borderPrimaryLight};
  display: flex;
  min-width: 20rem;
  max-width: 30rem;
  padding: 1rem 1.5rem 1rem 1rem;
  position: relative;
`;

const UploadedBox = styled(UploadBox)`
  padding: 0.5rem 1.5rem 0.5rem 1rem;
`;

const UploadBoxLabel = styled.div`
  font-size: 0.875rem;
  color: ${colors.uiTextPrimaryLight};
  line-height: 1.25rem;
  font-weight: 700;

  .file-size {
    font-weight: 400;
    color: ${colors.uiTextSecondaryLight};
    margin-left: 0.5rem;
  }
`;

const UploadBoxDetails = styled.div`
  font-size: 0.875rem;
  color: ${colors.uiTextSecondaryLight};
  line-height: 1.25rem;
  margin-top: 0.25rem;
`;

const UploadIcon = styled.div`
  align-items: center;
  background-color: ${colors.bgSecondaryLight};
  border-radius: 6px;
  border: 1px dashed ${colors.borderPrimaryLight};
  color: ${colors.grey600};
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 1rem;
  width: 48px;
`;

const RemoveIcon = styled.div`
  color: ${colors.grey600};
  margin-right: 1rem;

  svg {
    vertical-align: bottom;
  }
`;

const RemoveButton = styled(M.Button)`
  margin-left: auto !important;
  color: ${colors.uiButtonDestructiveLightDefault} !important;
`;

const UploadInput = styled(M.FileInput)`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;

type Props = {
  onUpload: (file: File) => void;
  onDelete: () => void;
  manualBulkUploadData: ManualBulkUploadData;
};

const FileUploadInput: React.FC<Props> = ({
  onUpload,
  onDelete,
  manualBulkUploadData,
}) => {
  const handleUpload = useCallback(
    event => onUpload(event.target.files[0]),
    [],
  );

  const handleDelete = useCallback(() => onDelete(), []);

  const truncatesString = (value: string) => {
    if (value.length <= 16) return value;

    return Humanize.truncate(value, 11) + value.slice(-6);
  };

  const formatBytes = (bytes: number) => {
    if (!+bytes) return '0B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(0))}${sizes[i]}`;
  };

  return (
    <>
      {manualBulkUploadData.fileName ? (
        <UploadedBox>
          <RemoveIcon>
            <M.Icon icon='Csv' size='24' />
          </RemoveIcon>
          <UploadBoxLabel>
            {truncatesString(manualBulkUploadData.fileName)}
            <span className='file-size'>
              {formatBytes(manualBulkUploadData.fileSize)}
            </span>
          </UploadBoxLabel>
          <RemoveButton
            onClick={handleDelete}
            kind='tertiary'
            data-testid='delete-button'
          >
            Remove
          </RemoveButton>
        </UploadedBox>
      ) : (
        <UploadBox>
          <UploadInput
            accept={['.csv']}
            onChange={handleUpload}
            data-testid='file-upload-input'
          />
          <UploadIcon>
            <M.Icon icon='DocumentAttachment' size='24' />
          </UploadIcon>
          <div>
            <UploadBoxLabel>Select to upload</UploadBoxLabel>
            <UploadBoxDetails>CSV • Max 300 rows</UploadBoxDetails>
          </div>
        </UploadBox>
      )}
    </>
  );
};

export default FileUploadInput;
