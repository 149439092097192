import styled from 'styled-components';

const CtaCardContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  @media (min-width: 992px) {
    & > * {
      max-width: 50%;
    }
  }
`;

const Styles = {
  CtaCardContainer,
};

export default Styles;
