import React from 'react';
import styled from 'styled-components';

const StyledH3 = styled.h3`
  text-align: center;
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
`;

type Props = {
  children: React.ReactNode | string;
  className?: string;
};

const H3: React.FC<Props> = ({ children, className }) => (
  <StyledH3 className={className}>{children}</StyledH3>
);

export default H3;
