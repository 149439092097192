import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { GenericObject } from 'types';

export const StatusDecoratorIcon = styled(M.Icon)`
  vertical-align: text-top;
`;

export const StatusDecorator = ({
  statusDecoratorText,
}: {
  statusDecoratorText: string;
}) => {
  return statusDecoratorText.length > 0 ? (
    <span>
      <StatusDecoratorIcon icon='WarningAltFilled' fill={colors.uiYellow400} />
      {statusDecoratorText}
    </span>
  ) : null;
};

export const Covid19Notice = () => {
  return (
    <M.InlineNotification
      kind='warning'
      title='License expiration date extended by COVID-related extension.'
    />
  );
};

export const StatusIcon = styled(M.StatusIcon)`
  display: inline-flex !important;
  margin-right: 0.5rem !important;
  vertical-align: text-bottom;
`;

type MvrItemProps = {
  item: GenericObject;
};

export const MvrItem: React.FC<MvrItemProps> = ({ item }) => {
  return (
    <>
      {item.assessment && (
        <StatusIcon icon={item.assessment.display.decision} />
      )}
      {item.description}
    </>
  );
};

export const StyledKeyValueList = styled(M.KeyValueList)`
  color: ${colors.uiTextPrimaryLight};
`;
