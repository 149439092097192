import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  InternationalLicenseReport as Record,
  InternationalLicenseInfo,
  Candidate,
} from 'types';
import {
  formatDateIfDate,
  underscoreToHuman,
} from '@dashboard-experience/utils';
import { StyledSpan } from '../MultiMotorVehicleReport/ReportHeading';
import formatCountry from './utils';

const InternationalMotorVehicleReportRecord: React.FC<{
  candidate: null | undefined | Candidate;
  licenseReport: Record;
  pdfUrl: null | string;
}> = ({ candidate, licenseReport, pdfUrl }) => {
  const licenseInfo = licenseReport.license_infos[0]; // currently license report contains always only one license info for IMVR
  const licenseNumber = licenseInfo.license_number;
  const { violations } = licenseReport;

  return (
    <M.Expandable
      role='licenseReport'
      key={`${licenseNumber}`}
      initialExpanded
      title={<ReportHeading licenseInfo={licenseInfo} />}
    >
      <>
        <IntlMVRKeyValueList
          candidate={candidate}
          licenseReport={licenseReport}
        />
        <M.SubScreening title='Results' data-testid='intl-mvr-results'>
          <IntlMVRResultSection licenseReport={licenseReport} pdfUrl={pdfUrl} />
        </M.SubScreening>
        {violations && violations.length > 0 && (
          <M.SubScreening title='Violations' data-testid='intl-mvr-violations'>
            <IntlMVRViolationSection violations={violations} />
          </M.SubScreening>
        )}
      </>
    </M.Expandable>
  );
};

const ReportHeading: React.FC<{
  licenseInfo: InternationalLicenseInfo;
}> = ({ licenseInfo }) => {
  const licenseClass = licenseInfo.license_class;
  const licenseState = licenseInfo.subdivision?.slice(-2);
  const prefix = licenseInfo.is_current ? 'Current ' : '';

  return (
    <M.Screening.Heading
      headingLeft={{
        name: (
          <StyledSpan>
            <span>
              {prefix}Driver Information ({licenseState} Class {licenseClass})
            </span>
          </StyledSpan>
        ),
      }}
    />
  );
};

const IntlMVRKeyValueList: React.FC<{
  candidate: null | undefined | Candidate;
  licenseReport: Record;
}> = ({ candidate, licenseReport }) => {
  const dlInfo = licenseReport.license_infos[0];
  const fullName =
    candidate?.full_name || `${candidate?.first_name} ${candidate?.last_name}`;
  const licenseStatus = underscoreToHuman(
    licenseReport.license_status,
  )?.toUpperCase();
  const items = [
    {
      itemKey: 'Full name',
      itemValue: fullName || '-',
    },
    {
      itemKey: 'Country of issue',
      itemValue: formatCountry(dlInfo.country) || '-',
    },
    {
      itemKey: 'Province of issue',
      itemValue: dlInfo.subdivision?.slice(-2) || '-',
    },
    {
      itemKey: 'License status',
      itemValue: showAttrWithIcon(
        licenseStatus,
        licenseStatus.startsWith('VALID'),
      ),
    },
    {
      itemKey: 'License class',
      itemValue: showAttrWithIcon(
        licenseReport.license_infos[0].license_class,
        licenseReport.is_valid_license_class,
      ),
    },
    {
      itemKey: 'License number',
      itemValue: dlInfo.license_number,
    },
    {
      itemKey: 'License expiration date',
      itemValue: formatDateIfDate(dlInfo.expiration_date) || '-',
    },
  ];

  return <M.KeyValueList items={items} role='mvrKeyValueList' />;
};

const IntlMVRResultSection: React.FC<{
  licenseReport: Record;
  pdfUrl: string | null;
}> = ({ licenseReport, pdfUrl }) => {
  const licenseStatus = underscoreToHuman(
    licenseReport.license_status,
  )?.toUpperCase();
  const licenseResults: {
    itemKey: string;
    itemValue: JSX.Element | string;
  }[] = [
    {
      itemKey: 'License status',
      itemValue: showAttrWithIcon(
        licenseStatus,
        licenseStatus.startsWith('VALID'),
      ),
    },
    {
      itemKey: 'Actual license class',
      itemValue: showAttrWithIcon(
        licenseReport.actual_license_class,
        licenseReport.is_valid_license_class,
      ),
    },
  ];
  if (pdfUrl) {
    licenseResults.unshift({
      itemKey: 'Download result',
      itemValue: renderHyperlink(pdfUrl, "Driver's abstract"),
    });
  }
  return <M.KeyValueList items={licenseResults} />;
};

const IntlMVRViolationSection: React.FC<{
  violations: Record['violations'];
}> = ({ violations }) => {
  const violationDetails = violations.map((violation, i) => (
    <tr key={`violation-${i + 1}`}>
      <td style={{ textAlign: 'left' }}>
        {showAttrWithIcon(violation.description?.toUpperCase()!, false)}
      </td>
      <td style={{ textAlign: 'right' }}>
        {formatDateIfDate(violation.issued_date) || '-'}
      </td>
    </tr>
  ));
  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <tbody>{violationDetails}</tbody>
    </table>
  );
};

const renderHyperlink = (url: string, linkText: string) => {
  return (
    <M.Link href={url} target='_blank'>
      {linkText}
    </M.Link>
  );
};

const showAttrWithIcon = (
  value: string | null,
  hasClearStatus: boolean | null,
) => {
  const iconStyle = hasClearStatus ? 'clear' : 'consider';
  return (
    <StyleStatus>
      <M.StatusIcon icon={iconStyle} />
      {value || '-'}
    </StyleStatus>
  );
};

const StyleStatus = styled.div`
  display: flex;
  align-items: center;
  .mastodon-status-icon {
    margin-right: 8px;
  }
`;

export default InternationalMotorVehicleReportRecord;
