import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { BrandsTableRow } from 'components/Account/Brands';

type BrandsTableProps = {
  brands: Array<any>;
  handlePaginationClick?: Function;
  selectedIndex: number;
  pageCount: number;
  setPage: (page: number) => void;
};

const BrandsTable = ({
  brands,
  handlePaginationClick,
  selectedIndex,
  pageCount,
  setPage,
}: BrandsTableProps) => {
  return (
    <div data-testId='brands-table'>
      <M.Table>
        <M.TableHead className='border-top'>
          <M.TableRow>
            <M.TableHeader>Brand Logo</M.TableHeader>
            <M.TableHeader>Brand Name</M.TableHeader>
            <M.TableHeader />
          </M.TableRow>
        </M.TableHead>

        <M.TableBody>
          {brands?.map(brand => (
            <BrandsTableRow
              brand={brand}
              key={brand.id}
              setPage={setPage}
              page={selectedIndex + 1}
            />
          ))}
        </M.TableBody>
      </M.Table>
      {pageCount > 1 && (
        <M.Pagination
          selectedIndex={selectedIndex}
          pageCount={pageCount}
          onPageClick={handlePaginationClick}
        />
      )}
    </div>
  );
};

export default BrandsTable;
