import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Type } from './custom-count-rules/type';

const Header = styled.h4`
  padding-top: 1rem;
  font-weight: bold;
`;

type Props = {
  type: Type | string;
};

export const Title: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const text = t(
    `assess:ruleset.version.publish.changes.${type.toLowerCase()}.title`,
  );

  return <Header>{text}</Header>;
};

export default Title;
