export enum ScreeningType {
  CREDIT_REPORT = 'credit_report',
  DRUG_SCREENING = 'drug_screening',
  EDUCATION_VERIFICATION = 'education_verification',
  EMPLOYMENT_VERIFICATION = 'employment_verification',
  FACIS_SEARCH = 'facis_search',
  GLOBAL_WATCHLIST = 'global_watchlist',
  MOTOR_VEHICLE_REPORT = 'motor_vehicle_report',
  SEX_OFFENDER_SEARCH = 'sex_offender_search',
}

export default ScreeningType;
