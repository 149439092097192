import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCandidate } from 'providers/Candidate';
import { useUser } from 'context/CurrentUser';
import { Banner } from 'components/Banner';

const CandidateOnHoldBanner: React.FC = () => {
  const { t } = useTranslation();
  const candidate = useCandidate();
  const currentUser = useUser();

  return candidate?.on_hold ? (
    <Banner
      kind='info'
      title={t(`banners.candidateOnHold.title`)}
      subtitle={t(`banners.candidateOnHold.subtitle`, {
        name: currentUser.account.name,
      })}
      hideCloseButton
      data-test-id='candidate-on-hold-banner'
    />
  ) : null;
};

export default CandidateOnHoldBanner;
