import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Note from '../Note';

const WebhooksHeader = () => (
  <M.Container.Row>
    <M.Container.Col>
      <Note>
        <span>
          Note that these are <strong>account level</strong> webhooks and are
          not meant to be used for partner integrations.
        </span>
        <span>
          If you have an existing OAuth integration and want to update your
          webhook url, please contact your Business Development Manager
        </span>
      </Note>
    </M.Container.Col>
  </M.Container.Row>
);

export default WebhooksHeader;
