import * as Assignable from 'modules/assess/models/assignables';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import client from './client';
import { Namespace } from './constants';

export const path = (namespace: Namespace) => {
  return `/${namespace}/assignables`;
};

export type SearchParams = {
  key?: string;
  type?: Assignable.Base.Kind;
  page?: number;
  per_page?: number;
};

export type SearchResult = {
  count: number;
  data: Array<Assignment.Type>;
};

const SEARCH_DEFAULTS: SearchParams = {
  type: Assignable.Base.Kind.ACCOUNT,
  page: 1,
  per_page: 20,
};

export const search = (
  namespace: Namespace,
  params?: SearchParams,
): Promise<SearchResult> => {
  return client.get(path(namespace), {
    params: { ...SEARCH_DEFAULTS, ...params },
  });
};
