import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Subtitle = styled.p.attrs({
  className: 'candidate-info-subtitle',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
    margin-top: 0;
    padding-bottom: 1.5rem;
  }
`;

export const ContactInfoList = styled.ul.attrs({
  className: 'candidate-info-contact-info-list',
})`
  display: flex;
  flex-direction: column;
`;

export const StretchInput = styled(M.Input).attrs({
  className: 'candidate-info-stretch-input',
})`
  width: 100%;
`;

export const ContactInfoItemContainer = styled.div.attrs({
  className: 'candidate-info-contact-info-item-container',
})`
  display: flex;
  width: 100%;
  gap: 6px;

  @media all and (max-width: 1200px) {
    flex-direction: row;
  }
`;

export const ContactInfoListItem = styled.li.attrs({
  className: 'candidate-info-contact-info-list-item',
})`
  display: flex;
  width: 100%;
  gap: 16px;
  padding-bottom: 16px;

  @media all and (max-width: 1200px) {
    flex-direction: column;
  }
`;
