export enum Fact {
  AGE_AT_OFFENSE = 'age_at_offense',
  AGE_NOW = 'age_now',
  CHARGE_CATEGORY_L1 = 'charge_category',
  CHARGE_CATEGORY_L2 = 'charge_subcategory',
  CHARGE_CATEGORY_L3 = 'charge_subsubcategory',
  CHARGE_SEVERITY = 'severity',
  COUNT = 'count',
  DISPOSITION = 'disposition_classification',
  INCARCERATION_ESTIMATED_END_DATE = 'relative_incarceration_estimated_release_date_in_years',
  LOOKBACK = 'relative_context_date_in_years',
  POTENTIALLY_EXPUNGEABLE = 'potentially_expungeable',
  PROBATION_ESTIMATED_END_DATE = 'relative_probation_estimated_end_date_in_years',
  RAW_CHARGE = 'raw_charge',
  RAW_CHARGE_TYPE = 'raw_charge_type',
  RAW_DISPOSITION = 'raw_disposition',
  RAW_SENTENCING_NOTES = 'raw_sentencing_notes',
  RESULT = 'result',
  SCREENING_TYPE = 'screening_type',
  STATUS = 'status',
  TYPE = 'object_type',
}

export const REGEX_FACTS = [
  Fact.RAW_CHARGE,
  Fact.RAW_CHARGE_TYPE,
  Fact.RAW_DISPOSITION,
  Fact.RAW_SENTENCING_NOTES,
];

export const EXCLUDED_CONTEXT_DATE_OVERRIDE_FACTS = [
  Fact.INCARCERATION_ESTIMATED_END_DATE,
  Fact.PROBATION_ESTIMATED_END_DATE,
];

export default Fact;
