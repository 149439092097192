import { List } from 'modules/assess/models/rules/premade';
import client from '../client';
import { Namespace } from '../constants';

type Response = {
  data: List;
};

export const fetchPremadeRules = (namespace: Namespace): Promise<Response> => {
  return client.get(`/${namespace}/rules/premade`);
};
