import React, { useCallback, useEffect, useState } from 'react';
import { useGetAssessRuleItemTypes } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';
import { useTranslation } from 'react-i18next';
import Kind from 'modules/assess/models/rules/common/kind';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import MultiSelect from './multi-select';

type Props = {
  kind: Kind;
  index: number;
};

const ItemTypes: React.FC<Props> = ({ kind, index }) => {
  const { updateConditionByIndex, selectedItemTypes } = useConditions();
  const { data } = useGetAssessRuleItemTypes(kind);
  const { t } = useTranslation('assess');
  const buildItem = (itemType: string) => ({
    id: itemType,
    label: t(`rules.facts.object_type.values.${itemType}`),
  });
  const items = data?.map((itemType: string) => buildItem(itemType));
  const initialSelectedItems = selectedItemTypes().map(itemType =>
    buildItem(itemType),
  );
  const [renderKey, setRenderKey] = useState('');
  const [updatedFromCallback, setUpdatedFromCallback] = useState(false);
  const onChange = useCallback(
    selectedItems => {
      const value: string[] = selectedItems?.map(
        (item: { id: string }) => item.id,
      );
      setUpdatedFromCallback(true);
      updateConditionByIndex(index, { value });
    },
    [index, updateConditionByIndex],
  );

  useEffect(() => {
    if (
      renderKey === '' &&
      initialSelectedItems.length > 0 &&
      !updatedFromCallback
    ) {
      setRenderKey('rerender');
    }
  }, [initialSelectedItems, updatedFromCallback]);

  return (
    <MultiSelect
      key={renderKey + index}
      items={items}
      onChange={onChange}
      initialSelectedItems={initialSelectedItems}
    />
  );
};

export default ItemTypes;
