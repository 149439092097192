import { GenericObject } from '@dashboard-experience/utils';
import { OccupationalHealthScreeningTest } from 'types';

export enum ExamType {
  Drug = 'drug',
  OccHealth = 'occ_health',
}
export type OccReorderableExam = OccupationalHealthScreeningTest & {
  type: ExamType.OccHealth;
  checked?: boolean;
};
export type DrugReorderableExam = GenericObject & {
  checked?: boolean;
  type: ExamType.Drug;
};
export type Exam = {
  type: ExamType.Drug | ExamType.OccHealth;
  name: string;
  status: string;
  slug: string;
  checked?: boolean;
};

export type ReorderableExams = Exam[];
export type AppointmentDeadline = string;
export type AddExamError = {
  message: string;
  title: string;
  status?: number;
};

// state management
export enum ActionType {
  SET_EXAMS = 'SET_EXAMS',
  SET_APPOINTMENT_DEADLINE = 'SET_APPOINTMENT_DEADLINE',
  SET_DRUG_OBSERVED_COLLECTION = 'SET_DRUG_OBSERVED_COLLECTION',
  SET_ALLOW_NEW_APPOINTMENT = 'SET_ALLOW_NEW_APPOINTMENT',
  SET_SELECT_NEW_CLINIC = 'SET_SELECT_NEW_CLINIC',
  SET_CLINIC = 'SET_CLINIC',
  SET_CLINIC_ZIP_CODE = 'SET_CLINIC_ZIP_CODE',
  SET_ADD_EXAM_ERROR = 'SET_ADD_EXAM_ERROR',
}

export type Action =
  | { type: ActionType.SET_EXAMS; payload: ReorderableExams }
  | { type: ActionType.SET_DRUG_OBSERVED_COLLECTION; payload: string }
  | { type: ActionType.SET_APPOINTMENT_DEADLINE; payload: AppointmentDeadline }
  | { type: ActionType.SET_ALLOW_NEW_APPOINTMENT; payload: string }
  | { type: ActionType.SET_SELECT_NEW_CLINIC; payload: string }
  | { type: ActionType.SET_CLINIC; payload: { name: string; id: string } }
  | { type: ActionType.SET_CLINIC_ZIP_CODE; payload: string }
  | { type: ActionType.SET_ADD_EXAM_ERROR; payload?: AddExamError };

export type FormData = {
  reorderableExams: ReorderableExams;
  appointmentDeadline: AppointmentDeadline | undefined;
  drugObservedCollection?: string;
  allowNewAppointment?: string;
  selectNewClinic?: string;
  clinic?: { name: string; id: string };
  zipcode: string;
  addExamError?: AddExamError;
};

export type Dispatch = (action: Action) => void;
