type OccHealthPrices = { [key: string]: string };

// Temporarily use hard-coded prices
const OCC_HEALTH_IN_NETWORK_PRICES: OccHealthPrices = {
  'OCH-0001-AGI': '$45',
  'OCH-0001-HWBPI': '$31',
  'OCH-0001-ORQI': '$43',
  'OCH-0001-RFTQLI': '$56',
  'OCH-0001-RFTQNI': '$56',
  'OCH-0001-VTSI': '$23',
  'OCH-0001-VTTI': '$35',
  'OCH-0001-VTJI': '$28',
  'OCH-0001-VTII': '$28',
  'OCH-0001-IVI': '$50',
  'OCH-0001-PHNDOTI': '$81',
  'OCH-0001-PHDOTI': '$110',
  'OCH-0001-LT1I': '$63',
  'OCH-0001-LT2I': '$71',
  'OCH-0001-PFI': '$71',
  'OCH-0001-PFOI': '$124',
  'OCH-0001-TBPPD1I': '$44',
  'OCH-0001-TBPPD2I': '$44',
  'OCH-0001-TBCXI': '$44',
  'OCH-0001-CX1I': '$105',
  'OCH-0001-CX2I': '$123',
  'OCH-0001-VHAI': '$144',
  'OCH-0001-VHB1I': '$113',
  'OCH-0001-VHB2I': '$113',
  'OCH-0001-VHB3I': '$113',
  'OCH-0001-VMMR1I': '$126',
  'OCH-0001-VMMR2I': '$126',
  'OCH-0001-VTDAPI': '$100',
  'OCH-0001-VV1I': '$180',
  'OCH-0001-VV2I': '$180',
  'OCH-0001-THAI': '$88',
  'OCH-0001-THBI': '$75',
  'OCH-0001-THBSAI': '$63',
  'OCH-0001-THCAI': '$75',
  'OCH-0001-TMI': '$63',
  'OCH-0001-TMMRI': '$100',
  'OCH-0001-TRBI': '$56',
  'OCH-0001-TVAI': '$81',
  'OCH-0001-THBHI': '$69',
  'OCH-0001-OXTNI': '$225',
  'OCH-0001-KWEI': '$63',
  'OCH-0001-ASRI': '$38',
  'OCH-0001-QFGI': '$315',
  'OCH-0001-AGO': '$61',
  'OCH-0001-HWBPO': '$46',
  'OCH-0001-ORQO': '$66',
  'OCH-0001-RFTQLO': '$78',
  'OCH-0001-RFTQNO': '$85',
  'OCH-0001-VTSO': '$46',
  'OCH-0001-VTTO': '$59',
  'OCH-0001-VTJO': '$46',
  'OCH-0001-VTIOV': '$46',
  'OCH-0001-IVO': '$66',
  'OCH-0001-PHNDOTO': '$118',
  'OCH-0001-PHDOTO': '$118',
  'OCH-0001-LT1O': '$78',
  'OCH-0001-LT2O': '$96',
  'OCH-0001-PFO': '$78',
  'OCH-0001-PFOO': '$144',
  'OCH-0001-TBPPD1O': '$60',
  'OCH-0001-TBPPD2O': '$60',
  'OCH-0001-TBCXO': '$118',
  'OCH-0001-CX1O': '$118',
  'OCH-0001-CX2O': '$161',
  'OCH-0001-VHAO': '$200',
  'OCH-0001-VHB1O': '$132',
  'OCH-0001-VHB2O': '$132',
  'OCH-0001-VHB3O': '$132',
  'OCH-0001-VMMR1O': '$191',
  'OCH-0001-VMMR2O': '$191',
  'OCH-0001-VTDAPO': '$130',
  'OCH-0001-VV1O': '$240',
  'OCH-0001-VV2O': '$240',
  'OCH-0001-THAO': '$106',
  'OCH-0001-THBO': '$106',
  'OCH-0001-THBSAO': '$81',
  'OCH-0001-THCAO': '$110',
  'OCH-0001-TMO': '$90',
  'OCH-0001-TMMRO': '$138',
  'OCH-0001-TRBO': '$90',
  'OCH-0001-TVAO': '$110',
  'OCH-0001-THBHO': '$100',
  'OCH-0001-OXTNO': '$260',
  'OCH-0001-OXTI': '$275',
  'OCH-0001-KWEO': '$78',
  'OCH-0001-ASRO': '$53',
  'OCH-0001-QFGO': '$315',
};

export default OCC_HEALTH_IN_NETWORK_PRICES;
