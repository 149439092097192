import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';

import Heading from './Heading';
import NoInformation from './NoInformation';
import { toAmount } from './utils';

type List = { name: string; field: string };
type Props = {
  items: { [x: string]: any };
  heading: string;
  list: List[];
  amountFields: string[];
  id: string;
  kind?: string;
  missingInfo?: {
    missing: boolean;
    completedAt: string;
    type: string;
  };
};

const getKeyListItems = (
  list: List[],
  map: { [x: string]: any },
  amountFields: string[],
) =>
  list.map(({ name, field }) => ({
    itemKey: name,
    itemValue:
      (amountFields.includes(field) ? toAmount(map[field]) : map[field]) || '-',
  }));

const Accounts: React.FC<Props> = ({
  items,
  heading,
  list,
  amountFields,
  id,
  kind,
  missingInfo = {
    type: '',
    completedAt: '',
    missing: false,
  },
}) => {
  const { completedAt, type, missing } = missingInfo;
  return (
    <div data-testid={id}>
      <Heading>{heading}</Heading>
      {missing ? (
        <NoInformation date={completedAt} type={type} />
      ) : (
        items.map((item: any, i: number) => {
          const name =
            kind === 'Public Records'
              ? item.plaintiff || `DOCKET #${item.docket_number}`
              : item.subscriber_name;
          return (
            <M.Expandable
              data-testid={`${id}-expandable`}
              key={`${heading}-${i.toString()}`}
              title={
                <M.Screening.Heading
                  headingLeft={{ name }}
                  headingRight={
                    <p>
                      {moment(item.date_opened || item.date_filed).format('ll')}
                    </p>
                  }
                />
              }
            >
              <M.KeyValueList
                items={getKeyListItems(list, item, amountFields)}
              />
            </M.Expandable>
          );
        })
      )}
    </div>
  );
};

export default Accounts;
