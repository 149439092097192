import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { CandidatePostalAddress } from 'types';
import { useAddressIsFilled } from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/views/config-options/hooks';
import AddressAutocomplete from 'components/PostalAddress/AddressAutocomplete';
import AddressManual from 'components/PostalAddress/AddressManual';

type Props = {
  handleAddress: (address: CandidatePostalAddress) => void;
  address: CandidatePostalAddress;
};

const PostalAddressForm: React.FC<Props> = ({ handleAddress, address }) => {
  const addressFilled = useAddressIsFilled();
  const [autoComplete, setAutoComplete] = useState(!addressFilled);

  useEffect(() => {
    if (addressFilled) {
      setAutoComplete(false);
    }
  }, [addressFilled]);

  const handleClick = useCallback(() => {
    setAutoComplete(!autoComplete);
  }, [autoComplete]);

  return (
    <>
      {autoComplete ? (
        <AddressAutocomplete handleAddress={handleAddress} address={address} />
      ) : (
        <AddressManual handleAddress={handleAddress} address={address} />
      )}
      <M.Button kind='tertiary' size='sm' onClick={handleClick}>
        {autoComplete ? 'Enter manually' : 'Use auto-fill'}
      </M.Button>
    </>
  );
};

export default PostalAddressForm;
