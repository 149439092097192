import React from 'react';
import { Namespace } from 'modules/assess/api';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useNamespace } from 'modules/assess/ui/router/context';
import Container from './context-dates-panel';

export const ContextDatesPanelContainer: React.FC = () => {
  const account = useUserAccount();
  const namespace = useNamespace();

  if (namespace !== Namespace.criminal) {
    return null;
  }
  const enabled = account?.assess_context_date_override;

  return <Container enabled={enabled} />;
};

export default ContextDatesPanelContainer;
