import styled from 'styled-components';
import { Container } from '../../styles';

export const WelcomeContainer = styled(Container)`
  align-items: center;
  gap: 1rem;
  text-align: center;
`;

export const WelcomeLoader = styled.div`
  margin-bottom: -0.5rem;
  width: 10rem;
  height: 10rem;
`;
