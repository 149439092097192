import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FactValue, Operator } from 'modules/assess';
import { FactRuleOption } from 'modules/assess/ui/rules/common/rule/editor/conditions';

type Props = {
  setValue: (value: FactRuleOption) => void;
  operator: Operator;
  value?: FactValue;
};

const RegexExplanationContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const textAreaColSizeMap = { lg: 12, md: 12, sm: 12 };

const RegexInput: React.FC<Props> = ({ setValue, operator, value }) => {
  const { t } = useTranslation('');
  const text = internalize(value);

  const handleChange = useCallback(
    event => {
      const values = externalize(event.target.value);
      setValue({ operator, value: values });
    },
    [setValue],
  );

  return (
    <>
      <M.GridCol {...textAreaColSizeMap}>
        <RegexExplanationContainer>
          {t('assess:rules.custom.conditions.regex.input.label')}
        </RegexExplanationContainer>
      </M.GridCol>
      <M.GridCol {...textAreaColSizeMap}>
        <M.TextArea light value={text} onChange={handleChange} />
      </M.GridCol>
    </>
  );
};

function externalize(value: FactValue): string[] {
  let values: string[];
  if (Array.isArray(value)) {
    values = value as string[];
  } else {
    values = (value as string).split('\n');
  }
  return values;
}

function internalize(value?: FactValue): string {
  let text: string;
  if (Array.isArray(value)) {
    text = value.join('\n');
  } else {
    text = (value as string) || '';
  }
  text = text.replace(/ /g, '\\s');
  return text;
}

export default RegexInput;
