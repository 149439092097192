import { TFunction } from 'i18next';
import { startCase } from 'lodash';
import { Report } from 'types';
import {
  AdjudicationEvent,
  AdjudicationEventTypes,
  EventView,
} from '../../../data/events';

const containsAdjudicationEvent = (type: string) =>
  Object.values<string>(AdjudicationEventTypes).includes(type) || type === null;

const adjudicationEventText = (
  t: TFunction,
  event: AdjudicationEvent,
): string => {
  switch (event.type) {
    case null:
      return t('adjudication:report.events.reset');
    case AdjudicationEventTypes.DEFERRED:
      return t('adjudication:report.events.deferral_decision');
    case AdjudicationEventTypes.ENGAGED:
      return t('adjudication:report.events.engaged');
    case AdjudicationEventTypes.PRE_ADVERSE_ACTION:
      return t('adjudication:report.events.pre_adverse_action');
    case AdjudicationEventTypes.POST_ADVERSE_ACTION:
      return t('adjudication:report.events.post_adverse_action');
    case AdjudicationEventTypes.DISPUTED:
      return t('adjudication:report.events.report');
    default:
      return '';
  }
};

const transformAdjudicationEvent = (t: TFunction, event: AdjudicationEvent) => {
  const byline =
    event.user?.email && event.type
      ? t('adjudication:report.events.byline', {
          type: startCase(event.type),
          user: event.user?.email,
        })
      : undefined;
  const textString = !event.subtype
    ? adjudicationEventText(t, event)
    : `${adjudicationEventText(t, event)}: ${event.subtype.name}`;

  const result: EventView = {
    time: event.time,
    byline,
    text: textString,
  };
  return result;
};

const processAdjudicationEvents = (
  t: TFunction,
  adjudicationEvents: AdjudicationEvent[],
  report: Report,
): EventView[] => {
  let events = adjudicationEvents;
  if (!report?.account?.adjudication_subtypes_enabled) {
    events = events.filter((e) => {
      return !(
        e.type === AdjudicationEventTypes.PRE_ADVERSE_ACTION ||
        e.type === AdjudicationEventTypes.POST_ADVERSE_ACTION
      );
    });
  }
  return events
    .filter((e) => containsAdjudicationEvent(e.type))
    .map((adj) => {
      return transformAdjudicationEvent(t, adj);
    });
};
export default processAdjudicationEvents;
