import React, { FC, useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { reduce } from 'lodash/fp';

import { Location } from 'components/LocationSearch/types';
import { ISelectedCandidate } from '../Poll/types';
import ClinicSection from './ClinicSection';

interface Props {
  candidates: ISelectedCandidate[];
  isChecked: boolean;
  onDisclosureCheck: (event: any, { checked }: any) => void;
  exams: { alcoholScreening: string; drugScreening: string };
  onEdit?: Function;
  clinicSelectionProps: {
    selectedClinic: Location | null;
  };
}

export const getExamDisplayNames = ({
  exams,
  candidates,
}: Pick<Props, 'candidates' | 'exams'>) =>
  reduce(
    (acc: any, { screeningType }) => {
      switch (screeningType) {
        case 'drug':
          return { ...acc, drug: exams.drugScreening.split('_').join(' ') };
        case 'alcohol':
          return {
            ...acc,
            alcohol: exams.alcoholScreening.split('_').join(' '),
          };
        case 'drug_alcohol':
          return {
            drug: exams.drugScreening.split('_').join(' '),
            alcohol: exams.alcoholScreening.split('_').join(' '),
          };
        default:
          return acc;
      }
    },
    {
      drug: null,
      alcohol: null,
    },
    candidates,
  );

const OrderReview: FC<Props> = ({
  candidates,
  exams,
  isChecked,
  onDisclosureCheck,
  onEdit,
  clinicSelectionProps,
}) => {
  const { selectedClinic } = clinicSelectionProps || {};

  useEffect(() => {
    const scrollTarget = document.getElementById('scroll-target');
    scrollTarget?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      {reviewAndSubmitHeader}
      <StyledDivider />
      <TestersSection candidates={candidates} />
      <StyledDivider />
      <ClinicSection selectedClinic={selectedClinic} onEdit={onEdit} />
      <StyledDivider />
      {selectedScreeningsSection(getExamDisplayNames({ exams, candidates }))}
      <StyledDivider />
      {disclosureSection(isChecked, onDisclosureCheck)}
      <StyledDivider />
    </>
  );
};

const StyledList = styled.ul`
  list-style-type: disc;
  margin-top: 8px;
  padding-left: 1.5em;
  line-height: 24px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledExpandableList = styled(M.Expandable)`
  margin-left: -32px;
`;

const StyledDivider = styled(M.Divider)`
  margin: 2em 0 !important;
`;

const ReviewTitle = styled('h2')`
  padding: 1em 0;
`;

const DisclosureNote = styled('p')`
  padding-bottom: 1em;
`;

const reviewAndSubmitHeader = (
  <>
    <M.GridRow
      style={{ 'scroll-margin-top': 75 }}
      id='scroll-target'
      data-testid='review-header-title'
    >
      <ReviewTitle>Review and submit order</ReviewTitle>
    </M.GridRow>
    <M.GridRow data-testid='review-header-description'>
      <p>
        After submitting, you can download the screening pass from your random
        selection details list.
      </p>
    </M.GridRow>
  </>
);

const DisplayFilteredCandidates: React.FC<{
  screeningType: string;
  candidates: ISelectedCandidate[];
}> = ({ screeningType, candidates }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const onExpand = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);
  const recordsToShow = 3;
  const hasMore = candidates.length > recordsToShow;

  return candidates?.length === 0 ? null : (
    <>
      {screeningType}
      <StyledList>
        {candidates.slice(0, recordsToShow).map(({ candidateId, fullName }) => (
          <li key={candidateId}>{fullName}</li>
        ))}
        {hasMore && (
          <StyledExpandableList
            title={
              <M.Link>
                {isExpanded
                  ? 'View less'
                  : `View ${candidates.length - recordsToShow} more`}
              </M.Link>
            }
            onExpand={onExpand}
          >
            {candidates
              .slice(recordsToShow)
              .map(({ candidateId, fullName }) => (
                <li key={candidateId}>{fullName}</li>
              ))}
          </StyledExpandableList>
        )}
      </StyledList>
    </>
  );
};

const TestersSection: React.FC<{ candidates: ISelectedCandidate[] }> = ({
  candidates,
}) => {
  const filterByScreeningType = (type: string) =>
    candidates.filter(candidate => candidate.screeningType === type);

  const filteredCandidatesDrug = filterByScreeningType('drug');
  const filteredCandidatesAlcohol = filterByScreeningType('alcohol');
  const filteredCandidatesDrugAndAlcohol =
    filterByScreeningType('drug_alcohol');

  return (
    <M.GridRow data-testid='testers-section'>
      <M.Container.Col>
        <h6>Testers</h6>
      </M.Container.Col>
      <M.Container.Col>
        <DisplayFilteredCandidates
          screeningType='Drug screening'
          candidates={filteredCandidatesDrug}
        />
        <DisplayFilteredCandidates
          screeningType='Alcohol screening'
          candidates={filteredCandidatesAlcohol}
        />
        <DisplayFilteredCandidates
          screeningType='Drug & alcohol screening'
          candidates={filteredCandidatesDrugAndAlcohol}
        />
      </M.Container.Col>
    </M.GridRow>
  );
};

const selectedScreeningsSection = (tests: any) => (
  <M.GridRow data-testid='selected-screenings-section'>
    <M.Container.Col>
      <h6>Selected screenings</h6>
    </M.Container.Col>
    <M.Container.Col>
      {tests.drug && (
        <>
          Drug test
          <StyledList>
            <li>{tests.drug}</li>
          </StyledList>
          <br />
        </>
      )}
      {tests.alcohol && (
        <>
          Alcohol test
          <StyledList>
            <li>{tests.alcohol}</li>
          </StyledList>
        </>
      )}
    </M.Container.Col>
  </M.GridRow>
);

const disclosureSection = (checked: boolean, handleDisclosureCheck: any) => (
  <>
    <M.GridRow data-testid='disclosure-section-note'>
      <DisclosureNote>
        *Doesn’t include third-party fees. These will be calculated on your
        invoice, along with any other applicable taxes and fees.
      </DisclosureNote>
    </M.GridRow>
    <M.GridRow>
      <M.Checkbox
        data-testid='disclosure-section-checkbox'
        id='random-order-disclosure'
        name='randomOrderDisclosure'
        onChange={handleDisclosureCheck}
        labelText={
          <>
            By checking this box, I certify that: (1) this request for a
            background check report is made in connection with an employment
            purpose and no other purpose; (2) I have provided the consumer with
            disclosures relating to the background check, and obtained the
            consumer’s written authorization to obtain the report and to
            dispatch notices electronically, including SMS messages where
            applicable; (3) The disclosure and authorization forms have been
            reviewing by my company and legal counsel and satisfy all Fair
            Credit Reporting Act and other legal requirements, including a clear
            and conspicuous disclosure in a document that consists solely of the
            disclosure; (4) I understand my obligations, have complied with, and
            will comply with all applicable laws pertaining to Consumer
            Reports/Investigative Consumer Reports as defined in the Fair Credit
            Reporting as amended; (5) the report will not be used in violation
            of any applicable federal or state equal employment opportunity laws
            or regulation, and its use will comply with all applicable laws; and
            (6) have received appropriate authorization for the transfer of the
            individual’s personal and criminal information outside the EU where
            applicable.
          </>
        }
        checked={checked}
      />
    </M.GridRow>
  </>
);

export default OrderReview;
