import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledContainer = styled.div`
  font-size: 24px;
  line-height: 36px;
  color: ${colors.uiTextSecondaryLight};
`;

const Description: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const descriptionText = t(`lite.description`);

  return (
    <StyledContainer
      id='assess-v2-ui-lite-description'
      data-testid='assess-v2-ui-lite-description'
    >
      <Trans>{descriptionText}</Trans>
    </StyledContainer>
  );
};

export default Description;
