import React from 'react';
import { useTranslation } from 'react-i18next';

export const Description: React.FC = () => {
  const { t } = useTranslation('');
  const description = t(
    'assess:ruleset.version.context_dates_panel.description',
  );

  return (
    <p data-testid='assess-ruleset-version-context-dates-panel-description'>
      {description}
    </p>
  );
};

export default Description;
