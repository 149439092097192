import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Button } from 'modules/assess/ui/page';
import Row from '../../row';

const ButtonContainer = styled.div`
  button {
    margin: 0 !important;
    margin-top: 1em !important;
  }
`;

type Props = {
  disabled?: boolean;
  addCondition: () => void;
};

export const AddButton: React.FC<Props> = ({ disabled, addCondition }) => {
  const handleClick = useCallback(() => addCondition(), [addCondition]);

  return (
    <Row data-testid='assess-rules-rule-editor-rule-add-condition'>
      <M.GridCol style={{ paddingRight: 0 }}>
        <ButtonContainer>
          <Button
            id='assess-rules-rule-editor-rule-add-condition-button'
            disabled={disabled}
            size='sm'
            kind='secondary'
            trans='assess:rules.custom.rule.buttons.add_condition'
            onClick={handleClick}
          />
        </ButtonContainer>
      </M.GridCol>
    </Row>
  );
};

export default AddButton;
