import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const CountyCivilSearch: React.FC<ScreeningProps> = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}) => {
  return (
    <FlexContainer data-testid='county-civil-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-county-civil-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='County Civil Records Search - 7-year address history'
            priceByType='county_civil_search'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Searches both upper- and lower-level state courts (sometimes
            referred to as county or circuit courts) for civil cases.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='county-civil-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360010342574'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Helps provide a comprehensive screening for financial and managerial roles' />
              <BulletPoint text='Identifies non-criminal lawsuits at the county or state level' />
              <BulletPoint text='Shows claims, suits, or judgments where the candidate is named as a plaintiff or defendant' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='May be limited to 7 years of history' />
              <BulletPoint text='Turnaround varies based on county' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='county_civil_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default CountyCivilSearch;
