import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from './text';
import Title from './title';

export type Props = {
  editable: boolean;
  value: string;
  onSave: (value: String) => void;
};

const StyledContainer = styled.div`
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

export const Container: React.FC<Props> = ({ editable, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => setEditedValue(value), [value]);

  const handleClickEdit = useCallback(() => setIsEditing(true), [setIsEditing]);
  const handleCancel = useCallback(() => {
    setEditedValue(value);
    setIsEditing(false);
  }, [setEditedValue, setIsEditing, value]);

  const handleSave = useCallback(() => {
    setIsEditing(false);
    onSave(editedValue);
  }, [setIsEditing, onSave, editedValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e?.target) {
        setEditedValue(e.target.value || '');
      }
    },
    [setEditedValue],
  );

  return (
    <StyledContainer data-testid='assess-entity-description'>
      <Title
        onClick={handleClickEdit}
        isEditing={isEditing}
        editable={editable}
      />
      <Text
        isEditing={isEditing}
        value={editedValue}
        onChange={handleChange}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </StyledContainer>
  );
};

export default Container;
