import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const ModalBodyText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding-bottom: 1.5rem;

  a {
    font-size: 1rem;
  }
`;

type LocationModalProps = {
  showLocationIconModal: boolean;
  handleClose: Function;
};

const LocationModal: React.FC<LocationModalProps> = ({
  showLocationIconModal,
  handleClose,
}) => {
  return (
    <M.ComposedModal open={showLocationIconModal} onClose={handleClose}>
      <M.ModalHeader closeModal={handleClose}>
        <h2>Where will your candidates be located?</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <ModalBodyText>
          Most locations have their own laws, regulations, and required
          information related to running background checks.
        </ModalBodyText>
        <ModalBodyText>
          This information helps us support your compliance with local laws and
          inform you of possible fees.{' '}
          <M.Link
            data-testid='get-started-learn-more-link'
            href='https://checkr.com/pricing'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more
          </M.Link>
        </ModalBodyText>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default LocationModal;
