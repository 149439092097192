import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Namespace } from 'modules/assess/api';
import { Live } from 'modules/assess/v2/ui/common';
import { ID } from 'modules/id';
import { Edit } from './actions';

type Props = {
  id: ID;
  namespace: Namespace;
};

const FlexDiv = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;

  /* Text formatting */
  color: ${colors.uiGrey800};
  font-size: 1.5em;
  font-weight: bold;
`;

const InlineFlex = styled.div`
  display: inline-flex;
  font-size: 18px;
`;

const Header: React.FC<Props> = ({ namespace, id }) => {
  const { t } = useTranslation('assess:v2');
  const text = capitalize(t('home.guidelines.your_guidelines'));
  const textId = `assess-v2-ui-home-guidelines-records-header-${text}`;

  return (
    <M.Container.Row
      id='assess-v2-ui-home-guidelines-records-header-container'
      data-testid='assess-v2-home-guidelines-records-header'
    >
      <FlexDiv>
        <InlineFlex id={textId}>
          {text}
          <Live />
        </InlineFlex>
        <InlineFlex>
          <Edit namespace={namespace} id={id} />
        </InlineFlex>
      </FlexDiv>
    </M.Container.Row>
  );
};

export default Header;
