import * as icons from 'assets/assess/v2/guidelines/templated';
import {
  CATEGORIES as CRIMINAL_CATEGORIES,
  Category as CriminalCategory,
} from 'modules/assess/models/criminal/rules/rules';
import { Category } from 'modules/assess/models/rules';

export function getCategoryIcon(category: Category): string | undefined {
  const criminalCategories = Object.values(CRIMINAL_CATEGORIES as string[]);
  if (criminalCategories.includes(category)) {
    return getCriminalCategoryIcon(category as CriminalCategory);
  }

  return undefined;
}

export function getCriminalCategoryIcon(
  category: CriminalCategory,
): string | undefined {
  switch (category) {
    case CriminalCategory.CANDIDATE:
      return icons.CandidateDetails;
    case CriminalCategory.CHARGE_CATEGORY:
      return icons.RecordType;
    case CriminalCategory.CHARGE_SEVERITY_TIMING:
      return icons.RecordSeverityTiming;
    case CriminalCategory.DISPOSITION_NON_CONVICTION:
      return icons.NonConvictions;
    default:
      return undefined;
  }
}
