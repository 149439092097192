import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PageLoading from 'components/PageLoading';
import { useSearch } from './api';
import Table from './Table';

const PAGE_SIZE = 10;

export const TableContainer: React.FC = () => {
  const [name, setName] = useState('');
  const [page, setPage] = useState(0);

  const searchRulesets = useSearch({
    name,
    page: page + 1,
    per_page: PAGE_SIZE,
  });
  const rulesets = searchRulesets?.data?.data || [];
  const dataCount = searchRulesets?.data?.count || 0;
  const pageCount = Math.ceil(dataCount / PAGE_SIZE);

  useEffect(() => {
    if (pageCount && page >= pageCount) {
      setPage(pageCount - 1);
    }
  }, [page, pageCount]);

  const onChange = useCallback(e => {
    setName(e.target.value);
  }, []);

  return (
    <>
      <PageLoading enabled={searchRulesets.status === 'loading'} />
      <div style={{ width: '50%' }}>
        <M.Search onChange={onChange} />
      </div>
      <Table rulesets={rulesets} />
      <M.Pagination
        selectedIndex={page}
        pageCount={pageCount}
        onPageClick={setPage}
      />
    </>
  );
};

export default TableContainer;
