import React from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import { Table as BaseTable, PageProps } from 'components/Table';
import { useTranslation } from 'react-i18next';
import { VersionLink } from './version-link';
import { PromoteLink } from './promote-link';

type Props = {
  item: Entity;
  page: PageProps;
  versions: Version.Basic[];
  setSelectedVersion: (version: Version.Basic) => void;
  onClose: () => void;
};

const keys = ['index', 'published_by', 'published_at', 'link', 'promote'];

export const Table: React.FC<Props> = ({
  item,
  page,
  versions,
  setSelectedVersion,
  onClose,
}) => {
  const { t } = useTranslation('assess');

  const headers = keys.map(key => ({
    key,
    header: t(`assess:mvr.category-sets.instance.versions.headers.${key}`, ''),
  }));

  const rows = versions.map((v, i) => ({
    id: v.id,
    index: v.number,
    link: <VersionLink item={item.id} version={v.id} onClose={onClose} />,
    promote: <PromoteLink version={v} onClick={setSelectedVersion} />,
    published_at: v.published_at?.toLocaleString(),
    published_by: v.published_by,
  }));

  return (
    <BaseTable
      data-testid='assess-mvr-category-sets-versions-table'
      headers={headers}
      page={page}
      rows={rows}
    />
  );
};

export default Table;
