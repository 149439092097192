import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import Option from 'modules/assess/models/context-date/option';

type Props = {
  checked?: boolean;
  onChange: (confirm: boolean) => void;
  option: Option;
};

export const Select: React.FC<Props> = ({ checked, onChange, option }) => {
  const handleCheck = useCallback(
    (event, { checked }) => onChange(checked),
    [onChange],
  );

  return (
    <M.Checkbox
      id={`assess-ruleset-version-context-dates-create-option-select-${option}`}
      data-testid={`assess-ruleset-version-context-dates-create-option-select-${option}`}
      checked={checked}
      onChange={handleCheck}
    />
  );
};

export default Select;
