export type State = { name: string; abbreviation: string; id: string };

export const US_STATES: State[] = [
  { name: 'Alabama', abbreviation: 'AL', id: 'AL' },
  { name: 'Alaska', abbreviation: 'AK', id: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ', id: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR', id: 'AR' },
  { name: 'California', abbreviation: 'CA', id: 'CA' },
  { name: 'Colorado', abbreviation: 'CO', id: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT', id: 'CT' },
  { name: 'Delaware', abbreviation: 'DE', id: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC', id: 'DC' },
  { name: 'Florida', abbreviation: 'FL', id: 'FL' },
  { name: 'Georgia', abbreviation: 'GA', id: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI', id: 'HI' },
  { name: 'Idaho', abbreviation: 'ID', id: 'ID' },
  { name: 'Illinois', abbreviation: 'IL', id: 'IL' },
  { name: 'Indiana', abbreviation: 'IN', id: 'IN' },
  { name: 'Iowa', abbreviation: 'IA', id: 'IA' },
  { name: 'Kansas', abbreviation: 'KS', id: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY', id: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA', id: 'LA' },
  { name: 'Maine', abbreviation: 'ME', id: 'ME' },
  { name: 'Maryland', abbreviation: 'MD', id: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA', id: 'MA' },
  { name: 'Michigan', abbreviation: 'MI', id: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN', id: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS', id: 'MS' },
  { name: 'Missouri', abbreviation: 'MO', id: 'MO' },
  { name: 'Montana', abbreviation: 'MT', id: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE', id: 'NE' },
  { name: 'Nevada', abbreviation: 'NV', id: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH', id: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ', id: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM', id: 'NM' },
  { name: 'New York', abbreviation: 'NY', id: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC', id: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND', id: 'ND' },
  { name: 'Ohio', abbreviation: 'OH', id: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK', id: 'OK' },
  { name: 'Oregon', abbreviation: 'OR', id: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA', id: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI', id: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC', id: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD', id: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN', id: 'TN' },
  { name: 'Texas', abbreviation: 'TX', id: 'TX' },
  { name: 'Utah', abbreviation: 'UT', id: 'UT' },
  { name: 'Vermont', abbreviation: 'VT', id: 'VT' },
  { name: 'Virginia', abbreviation: 'VA', id: 'VA' },
  { name: 'Washington', abbreviation: 'WA', id: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV', id: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI', id: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY', id: 'WY' },
];

export const US_STATES_NAMES: string[] = US_STATES.map((s: State) => s.name);

export const getStateByAbbreviation = (abbreviation: string) => {
  return US_STATES.find(state => state.abbreviation === abbreviation) || '';
};
