import React from 'react';
import { ReportStatuses } from '@dashboard-experience/utils';
import { Report } from 'types';
import { useReportInvitationStatusDecision } from '../utils';
import { DateLabel, StatusBadge } from './report-invitation-labels';

type Props = {
  report: Report;
};

const ReportInvitationDateStatus: React.FC<Props> = ({ report }) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(report);
  const isReport = report.object === 'report';

  return (
    <>
      {isReport ? (
        <>
          <DateLabel isReport={isReport} createdAt={report.created_at || ''} />
          <StatusBadge isReport={isReport} status={status} />
        </>
      ) : (
        // Invitations
        <>
          {status !== ReportStatuses.EXPIRED && (
            <DateLabel
              isReport={isReport}
              createdAt={report.created_at || ''}
            />
          )}
          <StatusBadge isReport={isReport} status={status} />
        </>
      )}
    </>
  );
};

export default ReportInvitationDateStatus;
