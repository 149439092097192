import React from 'react';
import { find } from 'lodash';

import moment from 'moment';
import {
  humanizeOnUnderscore,
  localStorageFallback,
} from '@dashboard-experience/utils';
import { CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { PARAMS } from '../constants';

export type AnalyticsProps = {
  propertyName: string;
  trackEvent: Function;
  debouncedEvent?: boolean;
};

export const getSelectionValue = (e: React.ChangeEvent<HTMLSelectElement>) =>
  e.target.value;

export const onSelectChange =
  (cb: (value: any) => void) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    cb(getSelectionValue(e));
  };

export const onMultiSelectChange =
  (cb: (value: Array<any>) => void) => (items: Array<any>) =>
    cb(items);

export const getInitialOptions = (
  matchFunction: (options: Array<any>, apiOptions: Array<any>) => any,
) => {
  return (options: Array<any>, apiOptions: Array<any>) => {
    if (options?.length && apiOptions?.length) {
      return matchFunction(options, apiOptions);
    }
    return [];
  };
};

const conditional = (option: string, property: string) => (item: any) =>
  item[property] === option;

export const matchArrayOfObj =
  (property: string) => (options: Array<any>, apiOptions: Array<any>) => {
    return options.reduce((matches: Array<string>, current: string) => {
      const match = find(apiOptions, conditional(current, property));
      if (match) matches.push(match);

      return matches;
    }, []);
  };

export const matchArray =
  (type: string) => (options: any, apiOptions: Array<any>) => {
    if (PARAMS.noneSelectValues.includes(type) && options === 'none') {
      return 'none';
    }
    return options.filter((t: string) => apiOptions.includes(t));
  };

export const getInitialGeos = getInitialOptions(matchArrayOfObj('id'));
export const getInitialPackages = getInitialOptions(matchArrayOfObj('slug'));
export const getInitialReportSources = getInitialOptions(
  matchArrayOfObj('report_source'),
);
export const getInitialTags = getInitialOptions(matchArray('tags'));
export const getInitialSavedSearchId = (
  options: Array<any>,
  apiOptions: Array<any>,
) => {
  let opt;
  // if query param provided, find the saved search matching that ID
  if (options[0]) {
    opt = apiOptions?.find(s => s.id === options[0]);
  }
  // otherwise, find the one marked 'default'
  opt = opt || apiOptions?.find(s => s.is_default);
  return opt;
};

export const getGeoIds = (geos: Array<any>) => geos.map(g => g.id);

export const getPackageSlugs = (packs: Array<any>) => packs.map(p => p.slug);

export const formatDate = (date: any, type: string = 'MM/DD/YYYY') =>
  moment(new Date(date)).format(type);

export const isDateValid = (date: string) => {
  const d = new Date(date);
  return d instanceof Date && !isNaN(d as any);
};

export const transformDate = (date: string) => {
  const d = date.split(/\/|-/).map(Number);
  // reformat date to be consumed properly by DateFilter
  // add additional check for valid param
  // it also handles MM-DD-YYYY && YYYY-MM-DD
  if (d.length === 3) {
    // legacy format YYYY-MM-DD
    if (d[0].toString().length === 4) {
      return formatDate(new Date(d[0], d[1] - 1, d[2]), 'MM-DD-YYYY');
    }
    return formatDate(new Date(d[2], d[0] - 1, d[1]), 'MM-DD-YYYY');
  }
  return date;
};

export const getProgramsByGeo = (programs: Array<any>, geoIds: Array<string>) =>
  programs
    .slice(1)
    .filter(({ geo_ids }: { geo_ids: Array<string> }) =>
      geo_ids.some((geo: string) => geoIds.includes(geo)),
    );

export const getSavedAdvanceFilters = () =>
  JSON.parse(localStorageFallback.getItem('showAdvancedFilters') || 'false');

export const getAllFilters = () =>
  JSON.parse(localStorageFallback.getItem('showAllFilters') || 'false');

export const toggleAdvancedFilters = (
  showAdvancedFilters: boolean,
  setShowAdvancedFilters: (advFilters: boolean) => void,
) => {
  localStorageFallback.setItem(
    'showAdvancedFilters',
    JSON.stringify(!showAdvancedFilters),
  );
  setShowAdvancedFilters(!showAdvancedFilters);
};

// FOR HACKWEEK 2022 NEW TOP SECTION OF CANDIDATE SEARCH
export const toggleAllFilters = (
  showAllFilters: boolean,
  setShowAllFilters: (allFilters: boolean) => void,
) => {
  localStorageFallback.setItem(
    'showAllFilters',
    JSON.stringify(!showAllFilters),
  );
  setShowAllFilters(!showAllFilters);
};

let debounceTimer: ReturnType<typeof setTimeout> | null = null;

export const onFilterChange =
  (
    setFilters: (params: any) => void,
    param?: string,
    trackAnalyticsObject?: AnalyticsProps | null,
  ) =>
  (value: any) => {
    const updates = param ? { [param]: value } : value;

    if (trackAnalyticsObject) {
      const valueToAnalytics = param ? value : value.adjudication;

      if (trackAnalyticsObject.debouncedEvent) {
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }

        debounceTimer = setTimeout(() => {
          callAnalyticsEvent(valueToAnalytics, trackAnalyticsObject);
        }, 5500);
      } else {
        callAnalyticsEvent(
          humanizeOnUnderscore(valueToAnalytics),
          trackAnalyticsObject,
        );
      }
    }

    setFilters((prevState: any) => ({
      ...prevState,
      ...updates,
    }));
  };

const callAnalyticsEvent = (
  valueToAnalytics: string,
  trackAnalyticsObject: AnalyticsProps,
) => {
  if (valueToAnalytics && trackAnalyticsObject.trackEvent) {
    trackAnalyticsObject.trackEvent(
      CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
      {
        [trackAnalyticsObject.propertyName]: valueToAnalytics,
        'Selected Filter': trackAnalyticsObject.propertyName,
      },
    );
  }
};
