import React from 'react';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { FieldComponentProps } from '../../types';

const EstimationUpdated: React.FC<
  Pick<FieldComponentProps, 'reportEstimationGenerated' | 'testid'>
> = ({ reportEstimationGenerated, testid }) => (
  <div data-testid={testid}>{dateWithTimezone(reportEstimationGenerated!)}</div>
);

export default EstimationUpdated;
