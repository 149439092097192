import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import { Field } from 'modules/assess/models/rulesets/version-diff/common';

type Props = {
  field: Field.FieldChanged;
};

const StatusBadge = styled(M.StatusBadge)`
  margin-left: 0.3rem;
`;

export const AssessmentChanged: React.FC<Props> = ({ field }) => {
  const { t } = useTranslation('assess');
  const activeStatus = t(`assess:decisions.${field.active}`).toLowerCase();
  const draftStatus = t(`assess:decisions.${field.draft}`).toLowerCase();

  return (
    <>
      <StatusBadge statusType={StatusTypes.Assess} status={activeStatus} />
      <M.Icon
        icon='ArrowRight'
        size='16'
        style={{ marginLeft: '0.3rem', marginBottom: '-0.1rem' }}
      />
      <StatusBadge statusType={StatusTypes.Assess} status={draftStatus} />
    </>
  );
};

export default AssessmentChanged;
