import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type Props = {
  error?: boolean;
  setFocus: (value: boolean) => void;
  value?: number;
};

type CellProps = {
  readonly: boolean;
  error?: boolean;
};

const Content = styled.div<CellProps>`
  align-items: center;
  background: ${({ readonly, error }) =>
    readonly || error ? colors.uiGrey0 : 'rgba(99, 225, 135, 0.25)'};
  border: 1.5px solid
    ${({ readonly, error }) => getBorderColor(readonly, error)};
  border-radius: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Text = styled.span`
  color: ${colors.uiTextPrimaryLight};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const StyledIcon = styled(M.Icon)`
  fill: ${colors.uiYellow500};
  margin-right: 0.5rem;
  [data-icon-path='inner-path'] {
    fill: ${colors.uiTextPrimaryLight};
    opacity: 1;
  }
`;

const Readonly: React.FC<Props> = ({ error, setFocus, value }) => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('common.lookback_rules.severity_input.readonly', {
    returnObjects: true,
    years: value,
  });

  const readonly = value === undefined || value === null;
  const text = getTranslation(translations, readonly, value);
  const handleClick = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  return (
    <Content
      data-testid='assess-v2-ui-common-lookback-rules-severity-input-readonly'
      error={error}
      readonly={readonly}
      onClick={handleClick}
    >
      {error && (
        <StyledIcon
          icon='WarningFilled'
          size='20'
          data-testid='assess-v2-ui-common-lookback-rules-severity-input-readonly-error'
        />
      )}
      <Text>{text}</Text>
    </Content>
  );
};

function getTranslation(
  translations: any,
  readonly: boolean,
  value?: number,
): string {
  if (readonly) {
    return translations.empty;
  }

  if (value && value > 0) {
    return translations.value;
  }

  return translations.min;
}

export default Readonly;

function getBorderColor(readonly: boolean, error?: boolean) {
  if (error) {
    return colors.uiYellow500;
  }
  if (readonly) {
    return colors.uiGrey300;
  }
  return colors.brandGreen1;
}
