import React, { useCallback, useState, useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useSearchState } from 'components/Search/search-context';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import { onSelectChange, AnalyticsProps } from '../helpers';
import getFilters from './StatusHelpers';
import { scrollToTop } from '../../../../utils';
import { REPORT_STATUS_HELP_CENTER_URL } from '../../constants';
import { StatusModalContent, StyledBaseFilter } from '../StyledComponents';

const Status = () => {
  const { assessEnabled, currentUser } = useSearchState();
  const account = currentUser?.account;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.REPORT_STATUS,
    trackEvent,
  };

  const canceledEnabled =
    account && !account.translate_canceled_status_to_suspended;

  const filters = useMemo(
    () => getFilters(assessEnabled, canceledEnabled),
    [assessEnabled, canceledEnabled],
  );

  const showModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback((): void => {
    setModalOpen(false);
  }, []);

  const translationKey = 'filterTooltips.statusFilterDefinitions';
  const labelText = t(`${translationKey}.label`);

  const assetsEnabledStatusTooltipText = (
    <StatusModalContent>
      <strong>{t(`${translationKey}.complete_action_required.title`)}: </strong>
      {t(`${translationKey}.complete_action_required.definition`)} <br />
      <strong>{t(`${translationKey}.complete.title`)}: </strong>
      {t(`${translationKey}.complete.definition`)} <br />
      <strong>{t(`${translationKey}.pending_candidate.title`)}: </strong>
      {t(`${translationKey}.pending_candidate.definition`)} <br />
      <strong>{t(`${translationKey}.pending_research.title`)}: </strong>
      {t(`${translationKey}.pending_research.definition`)} <br />
      <strong>{t(`${translationKey}.dispute.title`)}: </strong>
      {t(`${translationKey}.dispute.definition`)} <br />
      <strong>{t(`${translationKey}.suspended.title`)}: </strong>
      {t(`${translationKey}.suspended.definition`)} <br /> <br />
      <a
        href={REPORT_STATUS_HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        {t('filterTooltips.continueReadingLink')}...
      </a>
    </StatusModalContent>
  );

  const assetsDisabledStatusTooltipText = (
    <StatusModalContent>
      <strong>{t(`${translationKey}.consider.title`)}: </strong>
      {t(`${translationKey}.consider.definition`)} <br />
      <strong>{t(`${translationKey}.clear.title`)}: </strong>
      {t(`${translationKey}.clear.definition`)} <br />
      <strong>{t(`${translationKey}.complete_no_assessment.title`)}: </strong>
      {t(`${translationKey}.complete_no_assessment.definition`)} <br />
      <strong>{t(`${translationKey}.pending_candidate.title`)}: </strong>
      {t(`${translationKey}.pending_candidate.definition`)} <br />
      <strong>{t(`${translationKey}.pending_research.title`)}: </strong>
      {t(`${translationKey}.pending_research.definition`)} <br />
      <strong>{t(`${translationKey}.dispute.title`)}: </strong>
      {t(`${translationKey}.dispute.definition`)} <br />
      <strong>{t(`${translationKey}.suspended.title`)}: </strong>
      {t(`${translationKey}.suspended.definition`)} <br />
      <strong>{t(`${translationKey}.canceled.title`)}: </strong>
      {t(`${translationKey}.canceled.definition`)} <br /> <br />
      <a
        href={REPORT_STATUS_HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        {t('filterTooltips.continueReadingLink')}...
      </a>
    </StatusModalContent>
  );

  const tooltipText = assessEnabled
    ? assetsEnabledStatusTooltipText
    : assetsDisabledStatusTooltipText;

  const labelHTML = (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ marginRight: '4px' }}>{labelText}</span>
      <M.Icon icon='InformationFilled' onClick={showModal} />
    </span>
  );

  return (
    <>
      <StyledBaseFilter
        component='StatusFilter'
        param='status'
        onChange={onSelectChange}
        assessEnabled={assessEnabled}
        canceledEnabled={canceledEnabled}
        labelText={labelHTML}
        filters={filters}
        trackAnalyticsObject={trackAnalyticsObject}
      />
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`${translationKey}.modalHeading`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>{tooltipText}</M.ModalBody>
      </M.ComposedModal>
    </>
  );
};

export default Status;
