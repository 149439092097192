import React, { useMemo } from 'react';
import styled from 'styled-components';
import { US_STATES, State } from '../StatesOfUS';
import { City, MAJOR_CITIES } from '../MajorCities';
import { useOrderBackgroundCheckContext } from '../../Context';
import SelectCountry from './SelectCountry';
import SelectState from './SelectState';
import SelectCity from './SelectCity';
import { Flex } from '../../SharedItems';
import SelectNode from './SelectNode';

const Container = styled.div`
  margin: 0 0 -10px 0;
`;

type SelectLocationProps = {
  countries: string[];
  segmentation_enabled: boolean;
  hierarchy_present: boolean;
  states?: State[];
  cities?: City[];
  goBackClicked: boolean;
};

const LocationFlexContainer = styled(Flex)``;
const SectionMargin = styled.div`
  margin-top: 10px;
`;

const SelectLocation: React.FC<SelectLocationProps> = ({
  countries = ['US'],
  segmentation_enabled,
  hierarchy_present,
  states = US_STATES,
  cities = MAJOR_CITIES,
  goBackClicked,
}) => {
  const { state } = useOrderBackgroundCheckContext();

  const internationalWorkLocationSelection = useMemo(() => {
    return countries.length > 1;
  }, [countries]);

  return (
    <>
      {segmentation_enabled && (
        <Container>
          <LocationFlexContainer>
            <SelectNode hierarchy_present={hierarchy_present} />
            <SelectCountry
              countries={countries}
              hierarchy_present={hierarchy_present}
              segmentation_enabled={segmentation_enabled}
              internationalWorkLocationSelection={
                internationalWorkLocationSelection
              }
              goBackClicked={goBackClicked}
            />
          </LocationFlexContainer>
          {(!internationalWorkLocationSelection ||
            state.location.country === 'US') && (
            <>
              <SectionMargin />
              <LocationFlexContainer>
                <SelectState
                  states={states}
                  disabled={
                    (hierarchy_present &&
                      Object.keys(state.node).length === 0) ||
                    !segmentation_enabled
                  }
                />
                <SelectCity cities={cities} />
              </LocationFlexContainer>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default SelectLocation;
