import React from 'react';
import { useCandidate } from 'providers/Candidate';
import { useAgeAt } from 'modules/adjudication/utilities';

import { FieldComponentProps } from '../types';

const AgeAtOffense: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  const { call: ageAt } = useAgeAt();
  const { date } = adverseItem;
  const { dob } = useCandidate();
  return (
    <span data-testid={testid} className='value-width'>
      {ageAt(date, '', dob)}
    </span>
  );
};

export default AgeAtOffense;
