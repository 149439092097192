import React from 'react';

import { InternationalCandidateAddress, Report } from 'types';
import moment from 'moment';
import { useUser } from 'context/CurrentUser';
import { hasPermission } from '@dashboard-experience/utils';
import AddressSection from './AddressSection';

export const formatAddress = (address: InternationalCandidateAddress) =>
  `${address.street},${address.street2 ? ` ${address.street2},` : ''} ${
    address.city
  }, ${address.state} ${address.zipcode} ${address.country}`;

export const formatDate = (address: InternationalCandidateAddress) =>
  address.start_date
    ? `From ${moment(address.start_date).format('MMM, y')} to ${
        address.end_date ? moment(address.end_date).format('MMM, y') : 'present'
      }`
    : null;

const formatting = Object.freeze({
  formatAddress,
  formatDate,
});

export const InternationalCandidateAddresses: React.FC<{ report: Report }> = ({
  report,
}) => {
  const { international_candidate_addresses, ssn_trace } = report;
  const title = ssn_trace ? 'International Addresses' : 'Addresses';
  const currentUser = useUser();
  if (
    !(
      hasPermission(currentUser, 'read_reports') ||
      hasPermission(currentUser, 'read_full_reports')
    )
  )
    return null;

  if (
    !international_candidate_addresses ||
    international_candidate_addresses.length === 0
  )
    return null;

  return (
    <AddressSection
      title={title}
      id='international-candidate-addresses'
      addresses={international_candidate_addresses}
      {...formatting}
    />
  );
};
