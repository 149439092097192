import React from 'react';
import { usePremadeRules } from 'modules/assess/ui/api';
import { useState as useStateContext } from 'modules/assess/ui/ruleset/version/store';
import { Base } from 'modules/assess/v2/models/rules';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import List from './List';
import NoRulesSelected from './NoRulesSelected';

export const Container: React.FC = () => {
  const state = useStateContext();
  const premadeRules = usePremadeRules().data || [];
  const rules = state.rules.premade.table;

  const rows: Array<Row> = Base.build(premadeRules, rules);
  const selectedRules = rows.filter(r => r.isSelected);

  return (
    <div data-testid='assess-v2-ui-guideline-quickstart-added-guidelines-container'>
      {selectedRules.length === 0 ? (
        <NoRulesSelected />
      ) : (
        <List rows={selectedRules} />
      )}
    </div>
  );
};

export default Container;
