import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import {
  useAllowRulesetCreation,
  useAssignments,
  useCanDeleteRuleset,
} from '../../hooks';
import * as Assign from './Assign';
import * as Delete from './Delete';
import * as Duplicate from './Duplicate';
import * as Rename from './Rename';
import * as Versions from './Versions';

type Type = React.FC<Props>;

type Props = {
  kind: Kind;
  ruleset?: Ruleset.Type;
  onDelete: () => void;
  onDeleteDraft: () => void;
};

type Kind = 'options' | 'settings';

enum Option {
  assign = 'assign',
  discard = 'discard',
  discardDraft = 'discardDraft',
  duplicate = 'duplicate',
  rename = 'rename',
  versions = 'versions',
}

export const Menu: Type = ({ kind, ruleset, onDelete, onDeleteDraft }) => {
  const [selected, select] = useState<Option | undefined>(undefined);

  const reset = useCallback(() => select(undefined), [select]);

  const showDelete = useCanDeleteRuleset();
  const showAssignments = useAssignments();
  const showDuplicateRuleset = useAllowRulesetCreation();
  const showAssign = useCallback(() => select(Option.assign), [select]);
  const showDiscard = useCallback(() => select(Option.discard), [select]);
  const showDiscardDraft = useCallback(
    () => select(Option.discardDraft),
    [select],
  );
  const showDuplicate = useCallback(() => select(Option.duplicate), [select]);
  const showRename = useCallback(() => select(Option.rename), [select]);
  const showVersions = useCallback(() => select(Option.versions), [select]);
  const handleDelete = useCallback(() => {
    onDelete();
    select(undefined);
  }, [select, onDelete]);
  const handleDeleteDraft = useCallback(() => {
    onDeleteDraft();
    select(undefined);
  }, [select, onDeleteDraft]);

  return (
    <div>
      <M.Menu
        data-floating-menu-container
        data-testid='assess-ruleset-menu'
        icon={icon(kind)}
        hideTooltip
        flipped
      >
        {showAssignments && <Assign.MenuItem onClick={showAssign} />}
        {showDuplicateRuleset && <Duplicate.MenuItem onClick={showDuplicate} />}
        <Rename.MenuItem onClick={showRename} />
        <Versions.MenuItem onClick={showVersions} />
        <Delete.MenuItem
          divider
          draft
          ruleset={ruleset}
          onClick={showDiscardDraft}
        />
        {showDelete && <Delete.MenuItem draft={false} onClick={showDiscard} />}
      </M.Menu>
      <Assign.Dialog
        ruleset={ruleset}
        open={selected === Option.assign}
        onClose={reset}
      />
      <Delete.Dialog
        draft
        ruleset={ruleset}
        open={selected === Option.discardDraft}
        onClose={reset}
        onDelete={handleDeleteDraft}
      />
      <Delete.Dialog
        draft={false}
        ruleset={ruleset}
        open={selected === Option.discard}
        onClose={reset}
        onDelete={handleDelete}
      />
      <Duplicate.Dialog
        open={selected === Option.duplicate}
        ruleset={ruleset}
        onClose={reset}
      />
      <Rename.Dialog
        open={selected === Option.rename}
        ruleset={ruleset}
        onClose={reset}
      />
      <Versions.Dialog
        open={selected === Option.versions}
        onClose={reset}
        ruleset={ruleset}
      />
    </div>
  );
};

function icon(kind: Kind) {
  switch (kind) {
    case 'options':
      return (
        <M.Icon
          data-testid='assess-ruleset-menu-icon-dots'
          icon='OverflowMenuVertical'
          size={24}
        />
      );
    case 'settings':
      return (
        <M.Icon
          data-testid='assess-ruleset-menu-icon-gear'
          icon='Settings'
          size={24}
        />
      );
  }
  return undefined;
}

export default Menu;
