import { AnyQueryKey, useQuery } from 'react-query';

import * as Actions from './actions';

// eslint-disable-next-line import/prefer-default-export
export const useList = (params: Actions.ListParams) => {
  const { reportId, hasCountyCriminalSearches } = params;
  const key: AnyQueryKey = ['expungements/list', { id: reportId }];

  const request = () => Actions.list(params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!hasCountyCriminalSearches,
  });
};
