import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Entity from 'modules/assess/models/settings/package';
import { Settings } from 'modules/assess/utilities';
import Table from './Table';

type Props = {
  disabled: boolean;
  field: keyof Entity.Settings;
  packages: Entity.List;
  setPackages?: React.Dispatch<React.SetStateAction<Entity.List>>;
};

export const Content: React.FC<Props> = ({
  disabled,
  field,
  packages,
  setPackages,
}) => {
  const { t } = useTranslation('assess');
  const header = t(`assess:settings.v1.${field}.title`);
  const description = t(`assess:settings.v1.${field}.description`);
  const instructions = t(`assess:settings.packages.dialog.instructions`);

  const selections = Settings.getPackageSelections(packages, field);

  const onSelect = useSelector(field, packages, setPackages);

  return (
    <>
      <h3>{header}</h3>
      <p>
        <Trans>
          {description} {instructions}
        </Trans>
      </p>
      <Table
        packages={packages.sort((a, b) => a.name.localeCompare(b.name))}
        selections={selections}
        onSelect={onSelect}
        readOnly={disabled}
      />
    </>
  );
};

export default Content;

export const useSelector = (
  field: keyof Entity.Settings,
  packages: Entity.List | undefined,
  setPackages?: React.Dispatch<React.SetStateAction<Entity.List>>,
) => {
  const onSelect = useCallback(
    (p: string, selected: boolean) => {
      setPackages?.(state => {
        const pkg = packages?.find(s => s.id === p);
        if (!pkg) {
          return state;
        }
        const next = state.filter(s => s.id !== p);
        return next.concat({
          ...pkg,
          isSelected: selected,
          settings: {
            ...pkg?.settings,
            [field]: selected,
          },
        });
      });
    },
    [packages, setPackages, field],
  );

  return onSelect;
};
