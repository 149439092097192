import React, { useEffect } from 'react';
import { useFetchAdverseActionTemplates } from 'modules/adjudication/api';
import Template from './template';
import { useDispatch } from '../../../../context';

const Templates: React.FC<{}> = () => {
  const { templates } = useFetchAdverseActionTemplates();

  const dispatch = useDispatch();

  useEffect(() => {
    if (templates.pre.selected || templates.post.selected) {
      dispatch({
        type: 'SET_DEFAULT_TEMPLATES',
        payload: {
          pre: templates.pre.selected || null,
          post: templates.post.selected || null,
        },
      });
    }
  }, [
    dispatch,
    templates.pre.options,
    templates.post.options,
    templates.pre.selected,
    templates.post.selected,
  ]);

  if (!templates) return null;

  return (
    <>
      {templates.pre.options.length > 1 && (
        <Template type='pre' templates={templates.pre} />
      )}
      {templates.post.options.length > 1 && (
        <Template type='post' templates={templates.post} />
      )}
    </>
  );
};

export default Templates;
