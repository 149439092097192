import React from 'react';
import { ConditionsProvider } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { Type } from 'modules/assess/models/rules/count';
import Editor from './editor';

const EditorContainer = ({ rule }: { rule: Type }) => {
  return (
    <div data-testid='assess-count-rule-editor'>
      <ConditionsProvider>
        <Editor rule={rule} />
      </ConditionsProvider>
    </div>
  );
};

export default EditorContainer;
