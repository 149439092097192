import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/geo';
import Button from './button';
import GeosDialog from './geos-dialog';

type Props = {
  disabled?: boolean;
  geos?: Entity.List;
  setGeos: (geos: Entity.List) => void;
};

const StyledRow = styled(M.Container.Row)`
  color: ${colors.uiGrey800};
`;

export const SelectGeos: React.FC<Props> = ({ disabled, geos, setGeos }) => {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!geos) return null;

  return (
    <StyledRow
      data-testid='assess-v1-settings-adverse-action-automation-select-geos'
      style={{ marginLeft: '2rem' }}
    >
      <Button disabled={disabled} onClick={onClick} />
      <GeosDialog geos={geos} onClose={onClose} open={open} setGeos={setGeos} />
    </StyledRow>
  );
};

export default SelectGeos;
