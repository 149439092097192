/* eslint-disable react/no-children-prop */
import { LocationDescriptor } from 'history';
import React, { useContext } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import * as Assess from 'modules/assess/ui';
import { NamespaceRoute, RootRoute } from '../context';
import Instance from './instance';

export type Params = {};

const Router: React.FC = () => {
  const match = useRouteMatch();
  const namespace = useContext(NamespaceRoute);
  const root = useContext(RootRoute);
  const { path: base, url } = match;

  const home: LocationDescriptor = {
    pathname: root,
    search: new URLSearchParams({ tab: namespace || '' }).toString(),
  };

  return (
    <Assess.Route.Breadcrumb location={url} title='Rulesets'>
      <Switch>
        <Route path={`${base}/:ruleset`} children={<Instance />} />
        <Redirect to={home} />
      </Switch>
    </Assess.Route.Breadcrumb>
  );
};

export default Router;
