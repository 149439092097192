import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Button as ComponentButton } from 'components/Button';
import { Namespace } from 'modules/assess/api';
import * as api from 'modules/assess/api/ruleset-versions';
import { useNamespace } from 'modules/assess/ui/router/context';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { SUBCATEGORY_CVS_EXPORT_LINK } from 'modules/assess/v2/constants';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';

const StyledContainer = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  display: flex;
`;

const StyledIcon = styled(M.Icon)`
  margin-right: 8px;
  padding-right: 2px;
`;

export const Export: React.FC = () => {
  const track = useTrackEvent();
  const state = useState();
  const version = state.id;
  const { t } = useTranslation('assess:v2');
  const translations: any = t(
    `standard.guidelines.lookback_period.csv.export`,
    {
      returnObjects: true,
    },
  );
  const namespace = useNamespace() || Namespace.criminal;

  const handleExport = useCallback(() => {
    if (!version) {
      return;
    }
    api.lookbackToCSV(namespace, version);
    track(LookbackPeriod.ExportedCSV, {
      choice: 'Current Lookback Rules',
    });
  }, [namespace, track, version]);

  const handleCSVExport = useCallback(() => {
    window.open(SUBCATEGORY_CVS_EXPORT_LINK, '_blank');
    track(LookbackPeriod.ExportedCSV, { choice: 'Template' });
  }, [track]);

  const basePath = 'assess-v2-ui-guideline-lookback-period-csv-actions-export';
  const descriptionId = `${basePath}-description`;
  const containerId = `${basePath}-container`;
  const exportTemplateButtonId = `${basePath}-template-button`;
  const exportGuidelinesButtonId = `${basePath}-guidelines-button`;

  return (
    <M.ListItem data-testid={descriptionId}>
      {translations.description}
      <StyledContainer data-testid={containerId}>
        <ComponentButton
          data-testid={exportTemplateButtonId}
          kind='secondary'
          onClick={handleCSVExport}
        >
          <StyledIcon icon='Download' size='16' />
          {translations.button}
        </ComponentButton>
        <ComponentButton
          data-testid={exportGuidelinesButtonId}
          kind='secondary'
          onClick={handleExport}
        >
          <StyledIcon icon='Download' size='16' />
          {translations.template.button}
        </ComponentButton>
      </StyledContainer>
    </M.ListItem>
  );
};

export default Export;
