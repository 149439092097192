import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { SelectItem } from './types';

type Props = {
  items: SelectItem[];
  onChange: (value: any) => void;
  initialSelectedItems: SelectItem[];
};

const MultiSelect: React.FC<Props> = ({
  items,
  onChange,
  initialSelectedItems,
}) => {
  const { t } = useTranslation('');
  const placeholder = t('selects.condition_option_placeholder');
  const buildSelectedString = (length: number) =>
    length ? `(${length} selected)` : '';
  const [selectedString, setSelectedString] = useState(
    buildSelectedString(initialSelectedItems?.length),
  );

  const onChangeWrapper = useCallback(
    ({ selectedItems }: { selectedItems: SelectItem[] }) => {
      setSelectedString(buildSelectedString(selectedItems.length));
      onChange(selectedItems);
    },
    [onChange],
  );

  return (
    <div data-testid='assess-rules-common-multiselect'>
      <M.MultiSelect
        items={items}
        initialSelectedItems={initialSelectedItems}
        placeholder={` ${placeholder} ${selectedString}`}
        onChange={onChangeWrapper}
        filterable
        className='consistent-separator'
        alwaysShowSelectionTags
      />
    </div>
  );
};

export default MultiSelect;
