import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { GenericObject } from 'types';
import { AxiosError } from 'axios';
import { useErrorNotifier } from 'hooks';
import {
  postPartnerApplication,
  getPartnerApplications,
  putPartnerApplication,
  deletePartnerApplication,
} from './actions';
import { toastError, toastSuccess } from '../../actions/ToastActions';

const cleanUpEmptyParams = (params: GenericObject) => {
  return Object.keys(params).reduce((acc: GenericObject, key) => {
    if (params[key] !== '') {
      acc[key] = params[key];
    }
    return acc;
  }, {});
};

export const usePostPartnerApplication = (partnerId: string) => {
  const dispatch = useDispatch();
  const request = (params: any) =>
    postPartnerApplication(partnerId, cleanUpEmptyParams(params));

  const [postPartnerApplicationCall, postPartnerApplicationResult] =
    useMutation(request, {
      onSuccess: () => {
        dispatch(toastSuccess('Success:', 'Partner Application Created'));
      },
    });

  return {
    postPartnerApplicationCall,
    postPartnerApplicationResult,
  };
};

export const useGetPartnerApplications = (partnerId: string) => {
  const errorNotifier = useErrorNotifier();
  const key: AnyQueryKey = ['partner', partnerId];

  const request = () => getPartnerApplications(partnerId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      // 404's are to be expected where no partner applications exist,
      // so only announce other kinds of errors
      errorNotifier(err, {
        title: 'Error retrieving partner applications',
        ignoreCodes: [404],
      });
    },
  });
};

export const usePutPartnerApplication = (
  partnerId: string,
  applicationId: string,
) => {
  const dispatch = useDispatch();

  const request = (params: any) =>
    putPartnerApplication(applicationId, partnerId, params);

  const [editApplicationCall, editApplicationResult] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Application was successfully updated!'));
    },
  });
  return { editApplicationCall, editApplicationResult };
};

export const useDeletePartnerApplication = (
  applicationId: string,
  partnerId: string,
) => {
  const dispatch = useDispatch();

  const request = () => deletePartnerApplication(applicationId, partnerId);

  const [deleteApplicationCall, deleteApplicationResult] = useMutation(
    request,
    {
      onError: data => {
        dispatch(toastError('ERROR', data.message));
      },
      onSuccess: data => {
        dispatch(toastSuccess('Success:', 'Application deleted'));
      },
    },
  );

  return {
    deleteApplicationCall,
    deleteApplicationResult,
  };
};
