import styled from 'styled-components';
import { colors, sizes } from '@dashboard-experience/mastodon';

// eslint-disable-next-line import/prefer-default-export
export const ErrorText = styled.span`
  color: ${colors.uiOrange600};
`;

export const Small = styled.small`
  font-size: ${sizes.xsmall};
`;

export const MutedText = styled.span`
  color: ${colors.uiGrey500};
`;
