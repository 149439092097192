import React from 'react';
import { GenericObject } from 'types';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  title?: string;
  errors?: Error | Array<Error> | null;
  style?: GenericObject;
};

const createMessage = (errors: Array<Error>) => {
  return errors.reduce(
    (previousValue, currentValue) => `${previousValue}${currentValue}\n`,
    '',
  );
};

const ErrorMessage: React.FC<Props> = ({ errors, style, title }) => {
  if (!errors || (Array.isArray(errors) && !errors?.length)) return null;
  return (
    <M.InlineNotification
      style={{ ...style, whiteSpace: 'pre-wrap' }}
      kind='error'
      title={title}
      subtitle={Array.isArray(errors) ? createMessage(errors) : errors.message}
      hideCloseButton
    />
  );
};

export default ErrorMessage;
