import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import ConditionsList from 'modules/assess/ui/rules/common/rule/conditions-list';
import { List } from 'modules/assess/models/rules/condition';
import { Field } from 'modules/assess/models/rulesets/version-diff/common';
import { isFieldUpdated } from 'modules/assess/models/rulesets/version-diff/common/field';

type Props = {
  conditions: Field.Type<List>;
};

type StyledConditionsProps = {
  color: any;
};

const Container = styled.div`
  display: flex;
  margin-top: 0.3rem;
`;

const Title = styled.span`
  font-weight: bold;
  margin-right: 0.3rem;
`;

const StyledConditions = styled.div`
  color: ${(props: StyledConditionsProps) => props.color};
`;

export const Conditions: React.FC<Props> = ({ conditions }) => {
  const { t } = useTranslation('assess');
  const title = t(
    `assess:ruleset.version.publish.changes.common-custom-count.conditions.title`,
  );

  const activeConditions =
    (conditions as Field.FieldChanged<List>)?.active || [];
  const draftConditions =
    (conditions as Field.FieldChanged<List>)?.draft ||
    (conditions as Field.FieldUnchanged<List>)?.value ||
    [];

  const updatedChangeType = isFieldUpdated(conditions);

  return (
    <Container>
      <Title>{title}:</Title>
      <StyledConditions
        color={updatedChangeType ? colors.uiGrey700 : colors.uiTextPrimaryLight}
      >
        <ConditionsList
          conditions={updatedChangeType ? activeConditions : draftConditions}
          hideCountFact
        />
      </StyledConditions>
      {updatedChangeType && (
        <>
          <M.Icon
            icon='ArrowRight'
            size='16'
            style={{
              marginLeft: '0.3rem',
              marginRight: '0.3rem',
              marginBottom: '-0.1rem',
            }}
          />
          <ConditionsList conditions={draftConditions} hideCountFact />
        </>
      )}
    </Container>
  );
};

export default Conditions;
