/* eslint-disable react/jsx-no-bind */
import { M } from '@dashboard-experience/mastodon';
import React, { useCallback, useState } from 'react';

const TierCell = ({
  setTiers,
  tiers,
  cell,
  handleCellValueChange,
  rowIndex,
  sortedRowsId,
  setSortInfo,
}: any) => {
  const [displayNewTier, setDisplayNewTier] = useState({
    display: false,
    rowIndex: 0,
  });

  const [newTier, setNewTier] = useState('');

  const changeValue = useCallback(
    (e: { target: { value: any } }, rowIndex: number) => {
      if (e.target.value === 'input') {
        setDisplayNewTier({ display: true, rowIndex });
      }
    },
    [],
  );

  const addTier = (cell: any, rowIndex: number, sortedRowsId: string) => {
    if (tiers.includes(newTier) || newTier === '') {
      setDisplayNewTier({ display: false, rowIndex: 0 });
    } else {
      setTiers((prevTiers: any) => [...prevTiers, newTier]);
      setDisplayNewTier({ display: false, rowIndex: 0 });
      handleCellValueChange(
        cell.id,
        newTier,
        sortedRowsId,
        rowIndex,
        cell.value,
      );
      setNewTier('');
    }
  };

  const handleNewOptionChange = (event: any) => {
    const inputOption = event.target.value;
    setNewTier(inputOption);
  };

  return (
    <M.TableCell key={cell.id}>
      <div>
        <select
          id={`tierSelection-${sortedRowsId}`}
          className='form-control'
          data-testid='account-hierarchy-tier-input'
          name='tier'
          value={cell.value}
          onChange={(e: any) => {
            setSortInfo({
              columnId: cell.id,
              sortCycle: 'tier-states-from-ascending',
              oldDirection: 'DESC',
            });
            changeValue(e, rowIndex);
            handleCellValueChange(
              cell.id,
              e.target.value,
              sortedRowsId,
              rowIndex,
              cell.value,
            );
          }}
          required
        >
          {tiers.map((tier: any) => {
            return (
              <option
                key={tier}
                value={tier === 'None' ? '' : tier}
                label={tier}
              />
            );
          })}
          <option value='input'>Add New Option</option>
        </select>
        {displayNewTier.display && displayNewTier.rowIndex === rowIndex && (
          <div>
            <M.Input
              type='text'
              value={newTier}
              onChange={(e: any) => {
                handleNewOptionChange(e);
              }}
            />
            <M.Button
              type='button'
              style={{
                fontSize: '12px',
                padding: '2px 1rem',
                minHeight: '1rem',
              }}
              onClick={() => addTier(cell, rowIndex, sortedRowsId)}
            >
              Add
            </M.Button>
          </div>
        )}
      </div>
    </M.TableCell>
  );
};

export default TierCell;
