import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const DeleteCandidateData = () => {
  return (
    <M.ActionMenuItem
      title='Delete candidate data'
      kind='node'
      actionNode={<M.Icon icon='TrashCan' size='16' />}
      // TODO: only add the divider when Download PDF is available
      // divider
    />
  );
};

export default DeleteCandidateData;
