import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetSubscriptionErrors } from 'api/continuousChecks';
import { Text } from 'components/ContinuousServices/Text';
import IneligiblePersonsTable, {
  ContinuousCandidateErrors,
} from './IneligiblePersonsTable';
import IneligiblePersonsHeader from './IneligiblePersonsHeader';
import IneligiblePersonsSearch from './IneligiblePersonsSearch';
import IneligiblePersonsButtons from './IneligiblePersonsButtons';

const PAGE_SIZE = 20;

const IneligiblePersonsView: React.FC = () => {
  const [selectedPage, setPage] = useState(0);
  const [candidateId, setCandidateId] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const { isLoading, data } = useGetSubscriptionErrors(
    selectedPage + 1,
    PAGE_SIZE,
    candidateId,
    errorCode,
  );

  const handlePaginationClick = useCallback(
    pageIndex => {
      setPage(pageIndex);
    },
    [setPage],
  );

  const handleCandidateIdFilter = useCallback(
    newCandidateId => {
      setPage(0);
      setCandidateId(newCandidateId);
    },
    [setCandidateId],
  );

  const handleErrorCodeFilter = useCallback(
    newErrorCode => {
      setPage(0);
      setErrorCode(newErrorCode);
    },
    [setErrorCode],
  );

  const tableRows = (data?.candidate_errors || []).map(
    ({ resource_id, ...body }: any) => ({ ...body, id: resource_id }),
  ) as ContinuousCandidateErrors[];

  const pageCount = Math.ceil((data?.total || 0) / PAGE_SIZE);
  const isSearching = candidateId || errorCode;

  if (isLoading && !isSearching) {
    return <M.LoadingInline description='Loading data...' />;
  }
  return (
    <>
      <IneligiblePersonsHeader />
      {(tableRows.length !== 0 || isSearching) && (
        <IneligiblePersonsSearch
          resultsCount={data?.total || 0}
          onChangeCandidateId={handleCandidateIdFilter}
          candidateId={candidateId}
          onChangeErrorType={handleErrorCodeFilter}
          errorCode={errorCode}
        />
      )}
      <IneligiblePersonsButtons />
      {isLoading ? (
        <M.LoadingInline description='Loading data...' />
      ) : (
        <>
          {tableRows.length === 0 && !isSearching ? (
            <Text style={{ marginLeft: '1rem' }}>
              No errors found in the past 30 days.
            </Text>
          ) : (
            <>
              <IneligiblePersonsTable tableRows={tableRows} />
              {tableRows.length !== 0 ? (
                <M.Pagination
                  pageCount={pageCount}
                  selectedIndex={selectedPage}
                  onPageClick={handlePaginationClick}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IneligiblePersonsView;
