import StyledComponent from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const Header = StyledComponent.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: ${colors.uiTextPrimaryLight};
`;
export const SectionDescription = StyledComponent.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${colors.uiTextPrimaryLight};
`;
export const Wrapper = StyledComponent.div`
  background-color: ${colors.bgSecondaryLight};
  min-height: 38px;
  padding: 10px 24px 10px 24px;
  border-radius: 4px 4px 0px 0px;
  opacity: 0px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = StyledComponent.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BulkActionContainer = StyledComponent.div`
  align-content:center
`;

export const BulkActionButton = StyledComponent(M.Button).attrs({
  kind: 'secondary',
  size: 'lg',
})`
  min-height: 36px;
  height: 36px;
`;
