import PageLoading from 'components/PageLoading';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import * as Version from 'modules/assess/models/rulesets/version';
import Table from 'components/Table';
import { useFetch as useVersions } from './api/hooks';

import { VersionLink } from './VersionLink';
import { PromoteLink } from './PromoteLink';
import { useReadOnly } from '../../hooks';

type Props = {
  ruleset?: Ruleset.Type;
  close?: (event: SyntheticEvent) => void;
  setSelectedVersion: (version: Version.Type) => void;
};

const keys = ['index', 'published_by', 'published_at', 'link', 'promote'];

export const Content: React.FC<Props> = ({
  ruleset,
  close,
  setSelectedVersion,
}) => {
  const { t } = useTranslation('assess');
  const query = useVersions(ruleset?.id);

  const count = query?.data?.count || 0;
  const versions = query?.data?.data || [];

  const headers = keys.map(key => ({
    key,
    header: t(`assess:ruleset.versions.headers.${key}`, ''),
  }));

  let activeVersionId: string | undefined;
  if (ruleset?.active && typeof ruleset?.active === 'object') {
    activeVersionId = ruleset?.active.id;
  }

  const editable = !useReadOnly();

  const rows = versions.map((v, i) => ({
    id: '',
    ...v,
    index: count - i,
    link: <VersionLink ruleset={ruleset?.id} version={v.id} onClick={close} />,
    promote: editable ? (
      <PromoteLink
        activeVersionId={activeVersionId}
        version={v}
        onClick={setSelectedVersion}
      />
    ) : null,
    published_at: v.published_at?.toLocaleString(),
    published_by:
      v.published_by && typeof v.published_by === 'object'
        ? v.published_by.name || v.published_by.email
        : v.published_by,
  }));

  return (
    <div data-testid='assess-ruleset-versions-table'>
      <PageLoading enabled={query.status === 'loading'} />
      <Table disabled={!editable} headers={headers} rows={rows} />
    </div>
  );
};

export default Content;
