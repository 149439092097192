import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModalState } from '../../modules/adjudication/ui/adverse-action/initiate/flow/context';

const StyledExampleAssessment = styled.div`
  background-color: ${colors.uiGrey100};
  border: 1px solid ${colors.uiGrey200};
  border-top: 0;
  border-radius: 0 0 3px 3px;
  font-style: italic;
  padding: 15px;

  strong {
    color: ${colors.uiGrey800};
    font-size: 14px;
    padding: 0 0 5px;
    margin: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

const StyledDiv = styled.div`
  padding: 30px 30px 0;
  .cds--form-item {
    margin-bottom: 30px;
  }
  .cds--form__helper-text {
    margin-top: 0;
    color: ${colors.uiGrey500};
  }
`;

const ExampleAssessment: React.FC = () => {
  const { t } = useTranslation('adjudication');
  const header = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.initial_assessment_helper_text_header',
  );
  const body = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.initial_assessment_helper_text_body',
  );

  return (
    <StyledExampleAssessment>
      <strong>{header}</strong>
      <p>{body}</p>
    </StyledExampleAssessment>
  );
};

type Props = {
  initialAssessmentValue?: string;
  initialAssessmentPlaceholder?: string;
  initialAssessmentOnChange?: Function;
  initialAssessmentDisabled?: boolean;
  reassessmentValue?: string;
  reassessmentPlaceholder?: string;
  reassessmentOnChange?: Function;
  reassessmentDisabled?: boolean;
};

const FairChanceForm: React.FC<Props> = ({
  initialAssessmentValue,
  initialAssessmentPlaceholder,
  initialAssessmentOnChange,
  initialAssessmentDisabled,
  reassessmentValue,
  reassessmentPlaceholder,
  reassessmentOnChange,
  reassessmentDisabled,
}) => {
  const { individualizedAssessment } = useModalState('state');
  const { t } = useTranslation('adjudication');
  const initialAssessment = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.initial_assessment',
  );
  const reassessment = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.reassessment',
  );
  const reassessmentHelperText = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.reassessment_helper_text',
  );

  return (
    <StyledDiv>
      <M.TextArea
        value={individualizedAssessment?.assessment || ''}
        label={initialAssessment}
        placeholder={initialAssessmentPlaceholder}
        helperText={<ExampleAssessment />}
        onChange={initialAssessmentOnChange}
        disabled={initialAssessmentDisabled}
        data-testid='fair-chance-banner-initial-assessment-text-input'
      />
      <M.TextArea
        value={reassessmentValue}
        label={reassessment}
        placeholder={reassessmentPlaceholder}
        helperText={reassessmentHelperText}
        onChange={reassessmentOnChange}
        disabled={reassessmentDisabled}
        data-testid='fair-chance-banner-reassessment-text-input'
      />
    </StyledDiv>
  );
};

export default FairChanceForm;
