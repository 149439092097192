import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

import { ASSESS_STATUSES } from '../constants';

const { NEVER_MEETS, ESCALATED, ELIGIBLE, ALWAYS_MEETS } = ASSESS_STATUSES;

const getBackgroundColor = (status: string) => {
  if (status === ESCALATED || status === NEVER_MEETS) {
    return `${colors.uiOrange100}`;
  }
  if (status === ELIGIBLE || status === ALWAYS_MEETS) {
    return `${colors.uiGrey0}`;
  }
  return `${colors.uiYellow100}`;
};

const Cell = styled(M.TableCell)`
  background-color: ${({ status }) =>
    `${getBackgroundColor(status)} !important`};
  border-right: ${({ last }) => (last ? `10px solid ${colors.uiGrey0}` : '')};
  height: inherit;
  text-align: center !important;
  transition: none !important;
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
`;

export default Cell;
