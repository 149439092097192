import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFormik } from 'formik';
import { useUser } from 'context/CurrentUser';
import { UserValidations } from './utils';
import { IUser } from './Models';
import { ColoredParagraph, FlexContainer, FlexRowJustify } from './style';

const paragraphColor = colors.coolBlack70;

interface CreateUserModalProps {
  hideModal: () => void;
  setNextStep: (values: IUser) => void;
  open: boolean;
}

export const CreateUserModal = ({
  setNextStep,
  hideModal,
  open,
}: CreateUserModalProps) => {
  const user = useUser();

  const fullNameParts =
    user?.full_name?.split(' ').filter((part: string) => part) || [];

  const { values, errors, touched, handleSubmit, handleChange } =
    useFormik<IUser>({
      initialValues: {
        firstName: fullNameParts.length > 0 ? fullNameParts[0] : '',
        lastName:
          fullNameParts.length > 1
            ? fullNameParts[fullNameParts.length - 1]
            : '',
        email: user?.email || '',
      },
      validationSchema: UserValidations,
      onSubmit: values => setNextStep(values),
    });

  const showError = useCallback(
    (field: string): boolean => {
      return (errors[field as keyof typeof errors] &&
        touched[field as keyof typeof touched]) as boolean;
    },
    [errors, touched],
  );

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );
  return (
    <M.ComposedModal onClose={hideModal} open={open}>
      <M.ModalHeader
        title='Register with Tracker'
        subtitle='Step 1 of 2'
        closeModal={hideModal}
      />
      <M.ModalBody>
        <FlexContainer gap='1rem' padding='0'>
          <ColoredParagraph color={paragraphColor}>
            To process and manage I-9 forms, you must register with Tracker, our
            third-party provider.
          </ColoredParagraph>
          <ColoredParagraph color={paragraphColor}>
            Just confirm the details below and select <strong>Next</strong> to
            register your account. Your new login information will be sent to
            the email listed.
          </ColoredParagraph>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='firstName'
              name='firstName'
              labelText='First Name'
              onChange={handleChange}
              invalid={showError('firstName')}
              invalidText={showErrorMessage('firstName')}
              value={values.firstName}
            />
            <M.Input
              id='lastName'
              name='lastName'
              labelText='Last Name'
              onChange={handleChange}
              invalid={showError('lastName')}
              invalidText={showErrorMessage('lastName')}
              value={values.lastName}
            />
          </FlexRowJustify>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='email'
              name='email'
              labelText='Email address'
              onChange={handleChange}
              invalid={showError('email')}
              invalidText={showErrorMessage('email')}
              value={values.email}
              disabled
            />
          </FlexRowJustify>
        </FlexContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='primary' onClick={handleSubmit}>
          Next
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

interface CreateUserConfirmationModalProps {
  hideModal: () => void;
  open: boolean;
}

export const CreateUserConfirmationModal = ({
  hideModal,
  open,
}: CreateUserConfirmationModalProps) => {
  return (
    <M.ComposedModal onClose={hideModal} open={open}>
      <M.ModalHeader
        title='Check your inbox'
        subtitle='Step 2 of 2'
        closeModal={hideModal}
      />
      <M.ModalBody>
        <FlexContainer gap='1rem' padding='0'>
          <ColoredParagraph color={paragraphColor}>
            You&apos;ll receive an email from{' '}
            <strong>i9complete@trackercorp.com</strong> shortly. Follow the
            instructions included with your new login information to complete
            your registration.
          </ColoredParagraph>
          <ColoredParagraph color={paragraphColor}>
            After you&apos;re done, Section 2 will be available for review in
            the <strong>Statuses</strong> tab of <strong>I-9</strong>.
          </ColoredParagraph>
        </FlexContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='primary' onClick={hideModal}>
          Done
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};
