import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { M } from '@dashboard-experience/mastodon';

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}
const { ASC, DESC, NONE } = SortDirections;

type SortHeaderProps = {
  isSortHeader: boolean;
  sortDirection: SortDirections;
  onClick: any;
  label: string;
  firstSort?: SortDirections.ASC | SortDirections.DESC; // When a user clicks on an unsorted column, determine which way to sort first (ASC vs DESC)
};

// Component to be used for common logic in paginated tables where sorting requires an API call
const SortedHeader: React.FC<SortHeaderProps> = ({
  isSortHeader = false,
  sortDirection = NONE,
  onClick,
  label,
  children,
  firstSort = DESC,
  ...props
}) => {
  const { t } = useTranslation();

  // String to be used for an Aria label to describe the upcoming sorting action
  // Override needed here to account for the fact that paginated tables don't allow you to ever un-sort the table
  const translateWithId = useCallback(() => {
    let nextSortState: string;

    // If this column IS actively sorted already - then we simply alternate between ASC & DESC
    if (isSortHeader) {
      nextSortState = sortDirection === DESC ? ASC : DESC;
    }
    // If this column ISN'T sorted - then we just pass in whatever the "first" sort dir is
    else {
      nextSortState = firstSort;
    }

    return t('table_sorting.action', {
      label,
      order: t(`table_sorting.${nextSortState}`),
    });
  }, [isSortHeader, t, label, firstSort, sortDirection]);

  return (
    <M.TableHeader
      isSortable
      role='button'
      isSortHeader={isSortHeader}
      sortDirection={sortDirection}
      onClick={onClick}
      translateWithId={translateWithId}
      {...props}
    >
      {label}
    </M.TableHeader>
  );
};

export default SortedHeader;
