import React from 'react';
import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxDivider,
  BoxHeader,
  BulletPoint,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalRightToWorkProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};

const InternationalRightToWork = ({
  shouldOpen,
  actionProps,
}: InternationalRightToWorkProps) => {
  return (
    <FlexContainer data-testid='international-right-to-work-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='Right to Work UK'
            priceByType='international_right_to_work'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Verifies whether a candidate is allowed to work in the United
            Kingdom based on their immigration status
          </ScreeningInfo>
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Provides digital verification for British and Irish citizens' />
              <BulletPoint text='Allows foreign nationals to provide a share code, which employers can use to digitally verify via a link in the candidate’s report' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Not available for candidates applying for asylum' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_right_to_work'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalRightToWork;
