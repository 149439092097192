import React, { useContext, useEffect } from 'react';
import { ContextContainer, ManualOrderContainer } from 'containers';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_REDESIGN_KEY } from 'Constants';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';

const ManualOrderPage = () => {
  const orderRedesignKey = useFlag(ORDER_REDESIGN_KEY);
  const { contextId } = useContext(UIContext);

  useEffect(() => {
    if (orderRedesignKey?.variantKey === 'enabled' && contextId) {
      // TODO - redirect in standalone?
      updateParentWindowUrl({
        path: '/order/get-started',
        contextId,
        reload: true,
      });
    }
  }, [orderRedesignKey, contextId]);

  return <ManualOrderContainer />;
};

export default ContextContainer(ManualOrderPage);
