import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Text } from './Text';

const BulkActionHeader = () => (
  <M.Container.Row>
    <M.Container.Col>
      <Text>
        <span>
          We&apos;ll let you know if anything changes post-onboarding to help
          ensure your entire workforce continues to meet your standards. You can
          enroll or unenroll multiple people at once, update existing
          enrollments, and view any errors.
        </span>
        <br />
      </Text>
    </M.Container.Col>
  </M.Container.Row>
);

export default BulkActionHeader;
