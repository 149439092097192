import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { StyledComponents } from 'modules/assess/v2/ui/common';
import Export from './Export';
import Upload from './Upload';

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.brandNavy4};
  padding-bottom: 0.8rem;
`;

const StyledOrderedList = styled(M.OrderedList)`
  .mastodon-list-item {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: ${colors.brandNavy4};
  }
`;

export const Container: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const title = t(`standard.guidelines.lookback_period.csv.title`);
  const basePath =
    'assess-v2-ui-guideline-lookback-period-csv-actions-container';
  const titleId = `${basePath}-title`;
  const listId = `${basePath}-ordered-list`;

  return (
    <StyledComponents.SlateContainer data-testid={basePath}>
      <Title data-testid={titleId}>{title}</Title>
      <StyledOrderedList data-testid={listId}>
        <Export />
        <Upload />
      </StyledOrderedList>
    </StyledComponents.SlateContainer>
  );
};

export default Container;
