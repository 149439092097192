import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { RouteParams } from 'modules/assess/ui/route/Context';
import { Breadcrumb as CommonBreadCrumb } from '../common';

type Props = {
  route: RouteParams;
};

const StyledBreadcrumb = styled.span`
  color: ${colors.brandNavy4};
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
`;

const Breadcrumb: React.FC<Props> = ({ route }) => {
  return (
    <CommonBreadCrumb route={route}>
      <StyledBreadcrumb>{route.title}</StyledBreadcrumb>
    </CommonBreadCrumb>
  );
};

export default Breadcrumb;
