import React from 'react';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import BaseFilter from './BaseFilter';
import { onSelectChange, AnalyticsProps } from '../helpers';

const InvitationStatus = () => {
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.INVITATION_STATUS,
    trackEvent,
  };

  return (
    <BaseFilter
      component='InvitationStatusFilter'
      param='invitation_status'
      onChange={onSelectChange}
      labelText='Invitation status'
      trackAnalyticsObject={trackAnalyticsObject}
    />
  );
};

export default InvitationStatus;
