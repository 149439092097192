import { Context, useContext } from 'react';
import {
  BAContext,
  BillingAddressContext,
  BillingContactContext,
  CCContext,
} from './context';
import { Ctx } from './types';

const validation = (ctx?: Context<Ctx>) => {
  return (name: string, options?: any) => {
    const context = ctx ? useContext(ctx) : options;

    const { touched, errors } = context;

    const invalid = Boolean(errors?.[name]) && touched?.[name];
    const invalidText = errors?.[name];

    return {
      invalid,
      invalidText,
    };
  };
};

export const useCCValidation = validation(CCContext);
export const useBAValidation = validation(BAContext);
export const useBAVerificationValidation = validation();
export const useBillingAddressValidation = validation(BillingAddressContext);
export const useBillingContactValidation = validation(BillingContactContext);
