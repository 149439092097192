import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';
import { AutoEngage, HideEligible } from 'modules/assess/ui/common/settings';
import EligibleRecords from './eligible-records';

type Props = {
  locals: Entity.Type;
  remote: Entity.Type;
  setNeedsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const StyledRow = styled(M.Container.Row)`
  color: ${colors.brandNavy4};
`;

const Title = styled.h2`
  color: ${colors.brandNavy4};
`;

const Content: React.FC<Props> = ({
  locals,
  remote,
  setNeedsConfirmation,
  setSettings,
}) => {
  const { t } = useTranslation('assess:v2');
  const title = t(`home.settings.eligible_records.title`);
  const description = t(`home.settings.eligible_records.description`);
  const basePath = 'assess-v2-ui-home-settings-content';
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;

  return (
    <div id={basePath} data-testid='assess-v2-settings-content'>
      <Title id={titleId}>{title}</Title>
      <StyledRow>
        <p id={descriptionId}>
          <Trans>{description}</Trans>
        </p>
      </StyledRow>
      <EligibleRecords
        locals={locals}
        remote={remote}
        setNeedsConfirmation={setNeedsConfirmation}
        setSettings={setSettings}
      />
      <HideEligible
        locals={locals}
        setSettings={setSettings}
        versionClass='v2'
      />
      <AutoEngage locals={locals} setSettings={setSettings} versionClass='v2' />
    </div>
  );
};

export default Content;
