import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  title?: string;
  message: string;
  refetchCallback: Function;
};

const ActionableErrorNotification: React.FC<Props> = ({
  title,
  message,
  refetchCallback,
}) => (
  <StyledContainer type='transparent'>
    <M.Icon icon='Error' size='52' />
    <h2>{title || 'Error'}</h2>
    {message && <StyledText>{message}</StyledText>}
    <M.Button kind='primary' onClick={refetchCallback}>
      Retry
    </M.Button>
  </StyledContainer>
);

export default ActionableErrorNotification;

const StyledContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.p`
  margin-top: -20px !important;
`;
