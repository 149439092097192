import React from 'react';
import { ContentContainer } from '../components';
import { OverviewSection } from './styles';
import Content from './content';

type Props = {
  showOverview: boolean;
};

const Main: React.FC<Props> = ({ showOverview }) => {
  return (
    <OverviewSection
      data-testid='candidate-overview-section'
      className={showOverview ? 'show' : 'hidden'}
    >
      <div
        className={`candidate-overview-shrink-section ${
          showOverview ? 'show' : 'hidden'
        }`}
      >
        <div data-testid='candidate-overview-header' />
        <div className='candidate-overview-container'>
          <ContentContainer>
            <Content />
          </ContentContainer>
        </div>
      </div>
    </OverviewSection>
  );
};

export default Main;
