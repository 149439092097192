import React from 'react';
import { BillingEntity } from '../../../../../types/Billing';
import SelectPaymentProfile from '../../GetStarted/DropdownSelections/SelectPaymentProfile';
import parseAddressToString from '../../../../../utils/BillingAddressUtils';

export type PaymentProfileSectionContentProps = {
  payment_profiles: BillingEntity[];
  billing_entity_enabled: boolean;
  payment_profile: any;
  goBackClicked: boolean;
  editMode: boolean;
};

const PaymentProfileSectionContent: React.FC<
  PaymentProfileSectionContentProps
> = ({
  payment_profiles,
  billing_entity_enabled,
  payment_profile,
  goBackClicked,
  editMode,
}) => {
  return (
    <>
      {editMode ? (
        <SelectPaymentProfile
          payment_profiles={payment_profiles}
          billing_entity_enabled={billing_entity_enabled}
          goBackClicked={goBackClicked}
        />
      ) : (
        <div data-testid='payment-profile-summary'>
          {parseAddressToString(payment_profile?.address) || ''}
        </div>
      )}
    </>
  );
};

export default PaymentProfileSectionContent;
