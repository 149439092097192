import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Assess from 'modules/assess/ui';
import RulesetVersionsContainer from 'modules/assess/ui/ruleset/versions/Page';

const RulesetVersionsPage: React.FC = () => {
  const match = useRouteMatch();
  const { url } = match;

  return (
    <Assess.Route.Breadcrumb location={url} title='Versions'>
      <RulesetVersionsContainer />
    </Assess.Route.Breadcrumb>
  );
};

export default RulesetVersionsPage;
