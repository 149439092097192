import React from 'react';
import _ from 'lodash';
import { FieldComponentProps } from '../types';

const Disposition: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  const { disposition } = adverseItem;
  return disposition ? (
    <span data-testid={testid} className='value-width'>
      {_.capitalize(disposition)}
    </span>
  ) : null;
};

export default Disposition;
