/* eslint-disable import/prefer-default-export */
import { BasePackage } from '../types';

export const isSelectedPackageInternational = (
  selectedPackage: BasePackage,
) => {
  return (
    selectedPackage === 'checkrdirect_international_basic_plus' ||
    selectedPackage === 'checkrdirect_international_professional' ||
    selectedPackage === 'checkrdirect_international_professional_plus'
  );
};
