import { combineReducers } from 'redux';

import notifications from 'state/notifications/reducers';

import candidate from './Candidate.reducer';
import candidates from './Candidates.reducer';
import currentUser from './CurrentUser.reducer';
import globalState from './Global.reducer';
import {
  baInfo,
  ccInfo,
  ccInfoV2,
  loadBillingInfo,
  setDefaultPayment,
} from './Payment.reducers';
import report from './Report.reducer';
import searchReducer from './Search.reducer';
import segments from './Segments.reducer';
import { IdpConnectionsReducer as idpConnections } from './IdpConnections.reducer';
import { IdpSamlConfigReducer as idpSamlConfig } from './IdpSamlConfig.reducer';
import ui from './UI.reducer';
import users from './Users.reducer';
import continuousServices from './ContinuousServices.reducer';

export default combineReducers({
  baInfo,
  billingInfo: loadBillingInfo,
  setDefaultPayment,
  candidate,
  candidates,
  ccInfo,
  ccInfoV2,
  currentUser,
  globalState,
  idpConnections,
  idpSamlConfig,
  notifications,
  report,
  search: searchReducer,
  segments,
  ui,
  users,
  continuousServices,
});
