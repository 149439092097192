import React from 'react';
import FairChanceContainer from 'containers/FairChanceContainer';
import { Redirect } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { FAIR_CHANCE_FLAGR_KEY } from 'Constants';

const FairChance = () => {
  const canSeeFairChance = useFlag(FAIR_CHANCE_FLAGR_KEY)?.variantKey === 'on';
  if (!canSeeFairChance) {
    return <Redirect to='/login' />;
  }
  return <FairChanceContainer />;
};

export default FairChance;
