import {
  TopGroupType,
  InnerGroupType,
  ConditionType,
} from 'modules/assess/ui/rules/common/rule/editor/types';

function validateCondition(condition: ConditionType) {
  if (!condition.fact) throw 'condition has no fact';
  if (!condition.operator) throw 'condition has no operator';
  if (
    (Array.isArray(condition.value) && !condition.value[0]) ||
    !condition.value
  ) {
    throw 'condition value is empty';
  }
}

function validateInnerGroup(innerGroup: InnerGroupType) {
  if (!innerGroup[0]) throw 'inner group has no item_type';
  if (innerGroup.length < 2) throw 'inner group has no conditions';

  innerGroup.forEach(condition => validateCondition(condition));
}

function validateTopGroup(topGroup: TopGroupType) {
  if (topGroup.threshold === undefined || topGroup.threshold == null)
    throw 'top group has no threshold';
  if (!topGroup.operator) throw 'top group has no operator';
  if (!topGroup.conditions[0]) throw 'top group has no inner group';

  topGroup.conditions.forEach(innerGroup => validateInnerGroup(innerGroup));
}

export default function validateComplexRule(rule: any) {
  if (!rule.name) throw 'no name';
  if (!rule.decision) throw 'no decision';
  if (!rule.conditions[0]) throw 'no top groups';

  rule.conditions.forEach((topGroup: TopGroupType) =>
    validateTopGroup(topGroup),
  );
}
