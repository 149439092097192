import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const StyledSetting = styled.div`
  #mastodon,
  & p.settings-label {
    color: ${colors.uiNavy600} !important;
    font-weight: bold;
    font-size: 16px;
  }
`;

const Description = styled.div`
  margin-top: 0.2rem;
  margin-left: 2.75rem;
`;

type ToggleProps = {
  setting: string;
  value: boolean;
  updateSetting: (setting: string, value: any) => void;
};

export const ToggleSetting: React.FC<ToggleProps> = ({
  setting,
  value,
  updateSetting,
}) => {
  const { t } = useTranslation();
  const tKey = `case-management:settings.${setting}`;

  const dataTestId = `cm-setting-${setting}`;
  const description = t(`${tKey}.description`);
  const title = t(`${tKey}.title`);
  const label = <p className='settings-label'>{title}</p>;

  const onUpdate = useCallback(() => {
    updateSetting(setting, !value);
  }, [setting, value, updateSetting]);

  return (
    <StyledSetting data-testid={dataTestId}>
      <M.Toggle
        id={`${dataTestId}-toggle`}
        data-testid={`${dataTestId}-toggle`}
        toggled={!!value}
        toggledText={label}
        untoggledText={label}
        onToggle={onUpdate}
      />
      <Description data-testid={`${dataTestId}-description`}>
        {description}
      </Description>
    </StyledSetting>
  );
};

export default ToggleSetting;
