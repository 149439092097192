import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { LookbackPeriod } from 'modules/assess/v2/models/amplitude/guidelines';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';

type Props = {
  setSearch: (value: string) => void;
};

const StyledSearch = styled(M.Search)`
  margin-bottom: 0.5m;
  margin-top: 1rem;
  width: 33%;
`;

const Search: React.FC<Props> = ({ setSearch }) => {
  const track = useTrackEvent();
  const { t } = useTranslation('assess:v2');
  const placeholder: any = t(
    'standard.guidelines.lookback_period.filter.placeholder',
  );

  const onSearchChange = useCallback(
    event => setSearch(event.target.value),
    [setSearch],
  );

  const triggerSearchAmplitudeEvent = useCallback(
    event => {
      track(LookbackPeriod.SearchUsed, {
        search: event.target.value,
      });
    },
    [track],
  );

  return (
    <StyledSearch
      data-testid='assess-v2-ui-guideline-lookback-period-search'
      onBlur={triggerSearchAmplitudeEvent}
      onChange={onSearchChange}
      placeholder={placeholder}
    />
  );
};

export default Search;
