import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

export type Props = {
  disabled?: boolean;
  onCancel?: (event: SyntheticEvent) => void;
  onDelete?: () => void;
};

export const Actions: React.FC<Props> = ({ disabled, onCancel, onDelete }) => (
  <>
    <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
    <Button
      disabled={disabled}
      kind='danger'
      trans='verbs.delete'
      onClick={onDelete}
    />
  </>
);

export default Actions;
