/* eslint-disable react/jsx-no-bind */
import React from 'react';
import SpecialAddScreeningsAction, {
  SpecialAddScreeningsActionProps,
} from 'components/AddScreenings/shared/SpecialAddScreeningsAction';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type ProfessionalLicenseVerificationProps = {
  shouldOpen: boolean;
  getProfessionalLicenseVerificationPrice: (itemPrices: string[]) => void;
  itemPrices: string[];
  actionProps: ActionPropsProps;
  plvProps: SpecialAddScreeningsActionProps;
};

const ProfessionalLicenseVerification: React.FC<
  ProfessionalLicenseVerificationProps
> = ({
  shouldOpen,
  getProfessionalLicenseVerificationPrice,
  itemPrices,
  actionProps,
  plvProps,
}) => {
  return (
    <FlexContainer data-testid='professional-license-verification'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-professional-license-verification'
        title={
          <Title
            getPriceByType={() =>
              getProfessionalLicenseVerificationPrice(itemPrices)
            }
            actionProps={actionProps}
            title='Professional License Verification'
            priceByType={ScreeningType.professional_license_verification}
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Verifies a candidate’s professional license.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='professional-license-verification-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/9296500364823'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Determines eligibility for organizations with specialized or regulated roles' />
              <BulletPoint text='License types include: Accounting, Coast Guard, Contractors, Dietitian, Healthcare, Insurance, IT (Technology), Mental health, Nursing, Pharmaceutical, Real estate, and Veterinary medicine' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Many professional licenses can be checked instantly, while others may take up to 1-2 business days' />
              <BulletPoint text='Verifies license, not identity' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <SpecialAddScreeningsAction {...plvProps} />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default ProfessionalLicenseVerification;
