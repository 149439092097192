import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import styled from 'styled-components';
import { Document as CandidateDocument } from 'types';
import _ from 'lodash';

type DocumentProps = { document: CandidateDocument; dataTestId?: string };

const DocumentRow = styled.div`
  display: flex;
  margin: 16px 0px;
`;
const DocumentDetails = styled.div`
  margin-left: 24px;
  display: grid;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  color: ${colors.uiGrey700};
  span {
    line-height: 20px;
  }
`;
const DocumentType = styled.div`
  font-weight: bold;
  display: inline-block;
`;

const download = (uri: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = uri;
  a.click();
};

const formatTime = (created_at: string) =>
  moment(created_at).format('MM/DD/YYYY [at] h:mm:ssA');

const Document: React.FC<DocumentProps> = ({ document, dataTestId }) => {
  const viewDocument = useCallback(() => {
    download(document.download_uri);
  }, [document]);
  return (
    <DocumentRow className='candidate-document' data-testid={dataTestId}>
      <M.Button kind='secondary' onClick={viewDocument}>
        View document
      </M.Button>
      <DocumentDetails>
        <span>Uploaded at: {formatTime(document.created_at)}</span>
        <span>
          <DocumentType>Document type:</DocumentType>
          &nbsp;
          {document.type === 'tax_form_w2'
            ? 'Tax Form W2'
            : _.startCase(document.type)}
        </span>
      </DocumentDetails>
    </DocumentRow>
  );
};

export default Document;
