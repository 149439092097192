import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const getBreadcrumbColor = (visited: boolean, isCurrentPage: boolean) => {
  if (visited && isCurrentPage) {
    return colors.brandAqua4;
  }
  if (visited) {
    return colors.brandNavy3;
  }
  return colors.brandSlate5;
};

type BreadcrumbItemContainerProps = {
  visited: boolean;
  isCurrentPage: boolean;
};

const BreadcrumbItemContainer = styled.div<BreadcrumbItemContainerProps>`
  border-top: 3px solid
    ${props => (props.visited ? colors.brandAqua4 : colors.brandSlate4)};
  color: ${props => getBreadcrumbColor(props.visited, props.isCurrentPage)};
  font-size: 12px;
  font-weight: ${props => (props.isCurrentPage ? 'bold' : 'normal')};
  padding-top: 15px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

type BreadcrumbItemProps = {
  step: string;
  stepNumber: number;
  currentStepNumber: number;
  currentPage: string;
};

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  step,
  currentStepNumber,
  stepNumber,
  currentPage,
}) => {
  const [visited, setVisited] = useState(false);
  const isCurrentPage = step === currentPage;

  useEffect(() => {
    if (stepNumber > currentStepNumber) {
      setVisited(false);
    } else {
      setVisited(true);
    }
  }, [currentStepNumber, stepNumber]);

  useEffect(() => {
    isCurrentPage && setVisited(true);
  }, [currentPage, isCurrentPage, step]);

  return (
    <BreadcrumbItemContainer
      data-testid={`bread-crumb-${step.replace(' ', '-')}`}
      visited={visited}
      isCurrentPage={isCurrentPage}
    >
      {step}
    </BreadcrumbItemContainer>
  );
};

export default BreadcrumbItem;
