import client from '../client';
import { Category } from '../../models/mvr/category';
import { ID } from '../../models/entity';
import { path } from './uri';

export type CreateParams = Omit<Category, 'id'>;

export const create = (
  categorySetId?: ID,
  versionId?: ID,
  createParams?: CreateParams,
) => {
  const categoriesPath = `${path(categorySetId, versionId)}/categories`;
  return client.post(categoriesPath, createParams);
};

export const update = (
  categorySetId?: ID,
  versionId?: ID,
  categoryId?: ID,
  categoryName?: string,
) => {
  const categoriesPath = `${path(
    categorySetId,
    versionId,
  )}/categories/${categoryId}`;
  return client.patch(categoriesPath, {
    name: categoryName,
  });
};

export const destroy = (instanceId?: ID, versionId?: ID, categoryId?: ID) => {
  const categoryPath = `${path(
    instanceId,
    versionId,
  )}/categories/${categoryId}`;
  return client.delete(categoryPath);
};
