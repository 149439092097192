import { useMemo, useCallback } from 'react';
import { Report } from 'types';
import { useReportTabs } from 'providers/Candidate';
import { TabComponents } from './tabs';
import Tabs from './variations';

export const useReportTabsIndices = (report: Report) => {
  const visibleTabs = useMemo(
    () => Object.values(Tabs).filter(tab => TabComponents[tab].when(report)),
    [report],
  );

  const tabIndices = useMemo(
    () =>
      visibleTabs.reduce((indices, tab, index) => {
        indices[index] = tab;
        return indices;
      }, {} as { [key: number]: string }),
    [visibleTabs],
  );

  return tabIndices;
};

export const useUpdateReportTab = (report: Report) => {
  const { setTab, setSelectedIndex } = useReportTabs();
  const tabIndices = useReportTabsIndices(report);

  const updateTab = useCallback(
    ({ selectedIndex }) => {
      setTab(tabIndices[selectedIndex]);
      setSelectedIndex(selectedIndex);
    },
    [setTab, setSelectedIndex, tabIndices],
  );

  return updateTab;
};
