import moment from 'moment';
import { map, mapKeys } from 'lodash/fp';
import { camelCase } from 'lodash';
import { Location } from './types';

// eslint-disable-next-line import/prefer-default-export
export const validateZipCode = (zipCode: string): boolean => {
  if (!zipCode) return false;
  const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
  return zipCodeRegex.test(zipCode);
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const match = phoneNumber?.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
};

export type ScheduleHelpers = {
  hours: { [key: string]: string };
  bizClosedKey: string;
  bizOpenTimeKey: string;
  lunchFromKey: string;
  lunchToKey: string;
  bizClosedTimeKey: string;
  formattedTime: (hourKey: string) => string | undefined;
  open: Function;
  close: Function;
  lunchFrom: Function;
  lunchTo: Function;
  closed: Function;
  closeForLunch: Function;
};

const rotateArr = (arr: any[]): any[] => {
  arr.push(arr.shift());
  return arr;
};

const weekDaysShort = rotateArr(moment.weekdaysShort());
const weekDays = rotateArr(moment.weekdays());

const dayScheduleHelpers: Partial<ScheduleHelpers> = {
  formattedTime(hourKey: string): string | undefined {
    return moment(this.hours![hourKey], 'hh').format('LT');
  },

  open() {
    return this.formattedTime!(this.bizOpenTimeKey!);
  },

  close() {
    return this.formattedTime!(this.bizClosedTimeKey!);
  },

  lunchFrom() {
    return this.formattedTime!(this.lunchFromKey!);
  },

  lunchTo() {
    return this.formattedTime!(this.lunchToKey!);
  },

  closed() {
    return this.hours![this.bizClosedKey!] === 'True';
  },

  closeForLunch() {
    return (
      this.hours![this.lunchFromKey!] &&
      this.hours![this.lunchFromKey!].length > 0 &&
      this.hours![this.lunchToKey!] &&
      this.hours![this.lunchToKey!].length > 0 &&
      this.hours![this.lunchFromKey!] !== this.hours![this.lunchToKey!]
    );
  },
};

const constructSchedule = (
  day: string,
  hours: ScheduleHelpers['hours'],
): Partial<ScheduleHelpers> => ({
  hours,
  bizClosedKey: `closed${day}`,
  bizOpenTimeKey: `${day}HoursOpen`,
  lunchFromKey: `${day}LunchFrom`,
  lunchToKey: `${day}LunchTo`,
  bizClosedTimeKey: `${day}HoursClose`,
  ...dayScheduleHelpers,
});

const getScheduleForDay = (day: string, hours: ScheduleHelpers['hours']) => {
  const schedule = constructSchedule(day, hours) as ScheduleHelpers;
  if (schedule.closed()) return 'Closed';
  if (schedule.closeForLunch()) {
    return `${schedule.open()} - ${schedule.lunchFrom()}, ${schedule.lunchTo()} - ${schedule.close()}`;
  }
  return `${schedule.open()} - ${schedule.close()}`;
};

// A 'block' is a group of days with the same business hours
// eg: Mon-Fri 8:00 AM - 5:00 PM, Sat-Sun 10:00 AM - 4:00pm
export const getAvailability = (location: Pick<ScheduleHelpers, 'hours'>) => {
  const daysOpen: string[] = [];
  const dayBlocks: { daySpanFull: {}[]; businessHours: {} }[] = [];

  weekDays.forEach((day, index) => {
    const businessHours = getScheduleForDay(day, location.hours);
    const curBlock = dayBlocks[dayBlocks.length - 1];

    const setDayAsOpen = () => {
      if (daysOpen.length > 1) daysOpen.pop();
      daysOpen.push(weekDaysShort[index]);
    };
    const putDayInCurrentBlock = () => {
      if (curBlock.daySpanFull.length > 1) curBlock.daySpanFull.pop();
      curBlock.daySpanFull.push(weekDays[index]);
    };
    const putDayInNewBlock = () => {
      dayBlocks.push({
        daySpanFull: [weekDays[index]],
        businessHours,
      });
    };

    if (businessHours !== 'Closed') setDayAsOpen();
    if (curBlock && curBlock.businessHours === businessHours)
      putDayInCurrentBlock();
    else putDayInNewBlock();
  });
  return { daysOpen, dayBlocks };
};

export const convertCaseInArray = (operand: { [key: string]: any }[]) => {
  return map(location => {
    return mapKeys(camelCase, location) as Location;
  }, operand);
};
