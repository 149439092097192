import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import { fromUrlToTranslation } from 'modules/assess/v2/ui/utils';

type Props = {
  option: Option;
};

const StyledDiv = styled.div`
  padding: 8px 12px;
  background: ${colors.brandAqua3};
  border-radius: 30px;
  justify-content: center;
  display: flex;

  // text formatting
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.uiGrey0};
`;

const StyledIcon = styled(M.Icon)`
  margin-right: 0.4rem;
`;

const Pill: React.FC<Props> = ({ option }) => {
  const { t } = useTranslation('assess:v2');
  const translationOption = fromUrlToTranslation(option);
  const text = t(`lite.${translationOption}.header.pill.title`).toUpperCase();
  const icon = t(`lite.${translationOption}.header.pill.icon`);

  return (
    <StyledDiv data-testid='assess-v2-ui-lite-common-header-pill'>
      <StyledIcon icon={icon} size='16' />
      {text}
    </StyledDiv>
  );
};

export default Pill;
