import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { InternationalAdverseMediaSearch as MediaSearch } from 'types';
import SearchPDF from './SearchPDF';
import formatCountry from './utils';

const InternationalAdverseMediaSearch: React.FC<{
  search: MediaSearch;
  statusType: any;
}> = ({ search, statusType }) => {
  const title = formatCountry(search.country);
  return (
    <M.SubScreening title={title} statusType={statusType} screening={search}>
      {search.pdf_url && (
        <SearchPDF
          url={search.pdf_url}
          text={`View ${title} international adverse media report`}
        />
      )}
    </M.SubScreening>
  );
};

export default InternationalAdverseMediaSearch;
