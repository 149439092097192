import * as Entity from 'modules/assess/models/settings/account';
import client from './client';

export const BASE_PATH = '/settings';

export const fetch = (): Promise<Entity.Type> => {
  return client.get(`${BASE_PATH}`);
};

export const update = (updates: Partial<Entity.Type>): Promise<Entity.Type> => {
  return client.patch(`${BASE_PATH}`, updates);
};

export const uri = (): string => {
  return client.uri({ url: BASE_PATH });
};
