import { ID } from 'modules/id';
import { Initializer } from 'state/utilities/state';
import { Option } from 'modules/assess/models/context-date';

export type Type = {
  id: ID;
  option: Option;
  position: number;
};

export const initialize: Initializer<Omit<Type, 'id'>> = (
  context_date: Partial<Type> = {},
) => ({
  option: Option.ARREST_DATE,
  position: 1,
  ...context_date,
});

export type List = Array<Type>;
export type Map = { [key: string]: List };
