import React from 'react';
import Button from 'components/Button';

export type Props = {
  action: string;
  submitDisabled: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
};

export const Footer: React.FC<Props> = ({
  action,
  submitDisabled,
  onCancel,
  onSubmit,
}) => {
  return (
    <>
      <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
      <Button
        disabled={submitDisabled}
        kind='primary'
        trans={`verbs.${action}`}
        onClick={onSubmit}
      />
    </>
  );
};

export default Footer;
