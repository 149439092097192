import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDispatch as useCandidateSearchDispatch } from 'components/Search/search-context';
import { GenericObject } from '@dashboard-experience/utils';
import { pick } from 'lodash';
import { PARAMS } from 'components/Search';
import moment from 'moment';
import { debug } from 'utils';
import qs from 'qs';
import { toastError } from '../../actions';
import { performSearch, getCandidates } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const usePerformSearch = () => {
  const dispatch = useDispatch();
  const request = (query: string) => performSearch(query);

  const [performSearchCall, performSearchResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
  });

  return {
    performSearchCall,
    performSearchResult,
  };
};

export const useGetCandidates = () => {
  const request = (parameters: GenericObject) => {
    const { params, account, cancelToken } = parameters;
    const query = pick(params, PARAMS.whitelisted());
    query.not_pii_removed = true;
    if (query.q === '') query.q = '*';

    for (const param in query) {
      if (param in query) {
        const val = query[param];
        if (!val || (Array.isArray(val) && !val.length)) {
          // eliminate empty '' and []
          delete query[param];
        } else if (Array.isArray(val) && val.length) {
          query[param] = val.join(',');
        } else if (PARAMS.noneSelectValues.includes(param) && val === 'none') {
          query.missing = query.missing ? `${query.missing},${param}` : param;
          delete query[param];
        } else if (PARAMS.date.includes(param) && typeof val === 'string') {
          // url params need to be '-' separated to be backwards compatible with the legacy
          // implementation, in order to properly parse the date it needs to be '/' separated
          const d = val.split('-').join('/');
          const date = new Date(d);

          const dateParam =
            param === 'dob'
              ? moment(date).format('YYYY-MM-DD')
              : date.toISOString();
          query[param] = dateParam;
        }
      }
    }

    let queryWithSecondaryOwnership = null;
    if (account?.business_entities?.length) {
      queryWithSecondaryOwnership = {
        ...query,
        secondary_ownership: JSON.stringify(account.business_entities),
      };
    }

    debug('Search query update:', query);

    const search = qs.stringify(queryWithSecondaryOwnership || query);
    const url = `/search?${search}`;
    return getCandidates({ url, cancelToken });
  };

  const candidateSearchDispatch = useCandidateSearchDispatch();

  const [call, result] = useMutation(request, {
    onSuccess: res => {
      candidateSearchDispatch({
        type: 'set search',
        payload: {
          candidates: res,
        },
      });
    },
  });
  return {
    call,
    result,
  };
};
