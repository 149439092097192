import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import { Kind } from 'modules/assess/models/assignables';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';

type Props = {
  assignments?: Array<Assignment.Type>;
  ruleset?: Ruleset.Type;
};

export const DefaultMessages: React.FC<Props> = ({ assignments, ruleset }) => {
  const { t } = useTranslation('');

  const show = assignments?.find(assignment => {
    const assigned =
      typeof assignment.ruleset === 'string'
        ? { id: assignment.id, name: assignment.ruleset }
        : assignment.ruleset;
    return assignment.type === Kind.ACCOUNT && assigned?.id === ruleset?.id;
  });

  if (!show) {
    return null;
  }

  const info = t(`assess:ruleset.assign.messages.default_definition`);
  const warn = t(`assess:ruleset.assign.messages.default_disabled`);

  return (
    <div>
      <p>{info}</p>
      <M.InlineNotification
        kind='warning'
        title=''
        subtitle={warn}
        hideCloseButton
      />
    </div>
  );
};

export default DefaultMessages;
