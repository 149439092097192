import { Client } from 'api';
import { GenericObject } from '@dashboard-experience/utils';

export type BaseParams = {
  candidateId: string;
  params?: GenericObject;
};

export const fetchCandidateDocuments = ({
  candidateId,
  params,
}: BaseParams): Promise<GenericObject> => {
  return Client.get(`/v1/candidates/${candidateId}/documents`, {
    params,
  });
};
