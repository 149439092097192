import debounce from 'lodash/debounce';
import React, {
  useReducer,
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { useAddPackage } from 'api/packages';
import styled from 'styled-components';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';

import { M, colors } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import throttle from 'lodash/throttle';
import Dialog from 'components/Dialog';
import UIContext from 'context/UI';
import { useDashboardRelay, useDashboardWindowScroll } from 'hooks';
import screeningPayloads, {
  screeningTypes,
  ssnDependentScreeningTypes,
} from './shared/screeningPayloads';
import { useAllAddOnPrices, usePackagePrice } from './hooks/usePackagePrice';

import * as utils from './shared/utils';

const SaveForNextTimeContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 16px;

  border: 1px solid ${colors.uiGrey200};
  border-radius: 10px;
  padding: 24px;
  background-color: ${colors.uiNavy50};

  @media (min-width: 780px) {
    flex: 1 1 35%;
  }

  @media (max-device-width: 530px) {
    padding: 8px;
  }
`;

const SavePackageNameContainer = styled.div`
  margin-top: 16px;
`;

const TopBar = styled.div<TopbarProps>`
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid ${colors.uiGrey200};
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.uiGrey0} !important;
  position: sticky;
  z-index: 99999999;
  top: ${({ top }) => top}px;

  .short-text {
    display: none;
  }

  @media (max-width: 780px) {
    .short-text {
      display: inline-block;
    }
    .full-text {
      display: none;
    }
  }

  @media (max-device-width: 530px) {
    .continue-buttons {
      display: none;
    }

    .short-text {
      display: inline-block;
      font-size: 17px;
      font-weight: 700;
    }
    .full-text {
      display: none;
    }
  }

  h2 {
    margin: 2rem !important;
    letter-spacing: 0;
    color: ${colors.uiTextPrimaryLight};
  }
`;

const ScreeningsSection = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  min-height: 85vh !important;
  max-height: 85vh !important;
  height: auto !important;
  overflow-y: auto !important;
  margin-bottom: -24px !important;

  @media (min-width: 780px) {
    flex: 1 1 65%;
    border-bottom: 0;
  }

  @media (max-width: 780px) {
    min-height: 400px !important;
  }

  @media (max-device-width: 530px) {
    min-height: 400px !important;
    max-height: 500px !important;
  }

  @media (max-device-width: 420px) {
    min-height: 300px !important;
    max-height: 400px !important;
  }
`;

const SummarySection = styled.div<SummarySectionProps>`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 24px;
  padding-bottom: 32px !important;
  background-color: ${colors.uiGrey0};
  margin-bottom: 50px !important;
  overflow-y: scroll;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (min-width: 780px) {
    min-width: 375px;
    max-width: 375px;
    flex: 1 1 35%;
    margin-left: 16px;
  }

  @media (max-device-width: 530px) {
    padding: 8px !important;
    max-height: 450px;
    overflow-y: auto;
  }
`;

const InnerContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  border: 0 !important;
  margin-bottom: 0px !important;
  background-color: ${colors.uiNavy50} !important;
  height: 100vh !important;
  overflow-y: auto !important;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media (min-width: 780px) {
    flex-direction: row;
  }

  @media (max-device-width: 530px) {
    padding: 16px !important;
  }
`;

const SummaryContainer = styled.div<SummaryContainerPropsType>`
  position: relative;
  min-width: 375px !important;
  padding-bottom: 60px !important;
  padding-top: 50px !important;
  top: ${props => props.top}px;

  @media (max-width: 780px) {
    top: 0;
  }

  @media (min-width: 780px) {
    padding-top: 0px !important;
  }
  @media (max-device-width: 530px) {
    padding-top: 30px !important;
    min-width: 125px !important;
    top: 0;
  }
`;

const ContinueSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-bottom: 10px !important;
  margin-right: 2rem !important;
`;

const FooterSection = styled(TopBar)`
  display: none;
  @media (max-device-width: 530px) {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 64px;
    padding: 10px 10px 10px 0;
  }
`;

const InnerSection = styled.div`
  height: 100vh !important;
`;

const NoteContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;

  font-size: 14px;
`;

const CONTAINER_TOP_BOTTOM_PADDING = 24;

type TopbarProps = {
  top?: number;
};

type SummarySectionProps = {
  maxHeight: number;
};

type SummaryContainerPropsType = {
  top: number;
};

type Package = {
  name: string;
  slug: string;
  screenings: (
    | {
        type: string;
        subtype?: undefined;
      }
    | {
        type: string;
        subtype: string;
      }
    | {
        contract_type: boolean;
        date_matching: number;
        international_upgrade: boolean;
        lookback_years: number;
        manager_contact: boolean;
        maximum_allowed_employers: number;
        position_matching: string;
        requires_employment_history: boolean;
        requires_full_lookback: boolean;
        salary: boolean;
        type: string;
      }
    | {
        type: string;
        required_quantity: Array<boolean | number>;
      }
  )[];
};

type Props = {
  currentUser: any;
  account: any;
  setSelectedPackage: Function;
  onCancel: any;
  basePackage: Package;
  setAddonsAdded: Function;
  AddScreeningsList: React.FC<any>;
  AddScreeningsSummary: React.FC<any>;
  Note?: React.FC<any>;
  setAddonsPrice?: Function;
  isExpanded?: boolean;
};

export const initialState = {
  addedScreeningTypes: [],
  additionalProperties: {},
  saveForNextTime: false,
};

export const actionTypes = {
  SCREENING_ADDED: 'SCREENING_ADDED',
  SCREENING_REMOVED: 'SCREENING_REMOVED',
  ADD_ONS_CANCELLED: 'ADD_ONS_CANCELLED',
  SAVE_FOR_NEXT_TIME: 'SAVE_FOR_NEXT_TIME',
};

export const reducer = (state: any, action: any) => {
  let newState;
  switch (action.type) {
    case actionTypes.SCREENING_ADDED:
      newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.includes(
          action.payload.screeningType,
        )
          ? state.addedScreeningTypes
          : state.addedScreeningTypes.concat(action.payload.screeningType),
        additionalProperties: action.payload.additionalProperties
          ? {
              ...state.additionalProperties,
              [action.payload.screeningType]: {
                ...(state.additionalProperties[action.payload.screeningType] ||
                  {}),
                ...action.payload.additionalProperties,
              },
            }
          : state.additionalProperties,
      };
      break;
    case actionTypes.SCREENING_REMOVED:
      newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.filter(
          (screeningType: any) =>
            screeningType !== action.payload.screeningType,
        ),
      };
      delete newState.additionalProperties[action.payload.screeningType];
      break;
    case actionTypes.SAVE_FOR_NEXT_TIME:
      newState = {
        ...state,
        saveForNextTime: !state.saveForNextTime,
      };
      break;
    case actionTypes.ADD_ONS_CANCELLED:
      return { ...initialState };
  }
  return newState;
};

const AddScreenings: React.FC<Props> = ({
  currentUser,
  account,
  onCancel,
  setSelectedPackage,
  basePackage,
  setAddonsAdded,
  AddScreeningsList,
  AddScreeningsSummary,
  Note,
  setAddonsPrice,
  isExpanded,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const trackEvent = useTrackEvent();
  const [clickedInternationalPriceVaries, setClickedInternationalPriceVaries] =
    useState('No');
  const [clickedHelpCenter, setClickedHelpCenter] = useState('No');
  const [newPackageName, setNewPackageName] = useState('');
  const [packageNameInvalid, setPackageNameInvalid] = useState(false);
  const [packageNameTouched, setPackageNameTouched] = useState(false);
  const { call: addPackage, result: addPackageResult } = useAddPackage({
    accountId: account.id,
    toggleShowAddScreenings: onCancel,
    saveForNextTime: state.saveForNextTime,
  });
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [initialAdditionalProperties, setInitialAdditionalProperties] =
    useState({});
  const [packageSummaryTop, setPackageSummaryTop] = useState(0);
  const [topbarTop, setTopbarTop] = useState(0);
  const [iframeTop, setIframeTop] = useState(0);
  const [packageSummaryOffsetTop, setPackageSummaryOffsetTop] = useState(0);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);

  const packageSummaryRef = useRef<HTMLDivElement>(null);
  const topbarRef = useRef<HTMLDivElement>(null);
  const { contextId, isIframe } = useContext(UIContext);

  const basePackageScreeningTypes = basePackage.screenings.map(
    screening => screening.type,
  );

  let disabledScreenings = screeningTypes.filter(screeningType =>
    basePackageScreeningTypes.includes(screeningType),
  );

  if (!basePackageScreeningTypes.includes('ssn_trace')) {
    disabledScreenings = disabledScreenings.concat(ssnDependentScreeningTypes);
  }

  if (basePackageScreeningTypes.includes('county_criminal_search')) {
    const countyCrimScreening = basePackage.screenings.find(screening => {
      return screening.type === 'county_criminal_search';
    });

    if (
      countyCrimScreening &&
      'subtype' in countyCrimScreening &&
      countyCrimScreening?.subtype === 'current'
    ) {
      disabledScreenings = disabledScreenings.concat('state_criminal_search');
    }
  }

  window.onbeforeunload = () => {
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.REMOVE_ADD_ONS_PAGE_COMPLETED,
      {
        'Completion Type': 'Exit',
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings as any,
        'Add-on Screenings': state.addedScreeningTypes,
        'Package Saved': 'No',
        'New Package Name': newPackageName,
        'International Pricing Page Clicked':
          clickedInternationalPriceVaries as any,
        'Help Center Clicked': clickedHelpCenter as any,
      },
    );
  };

  const buildInitialAdditonalProperties = () => {
    let additionalProperties = {};

    const [facisScreening] = basePackage.screenings.filter(
      screening => screening.type === 'facis_search',
    );
    const [employmentVerification] = basePackage.screenings.filter(
      screening => screening.type === 'employment_verification',
    );
    const [personalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'personal_reference_verification',
    );
    const [professionalReferenceVerification] = basePackage.screenings.filter(
      screening => screening.type === 'professional_reference_verification',
    );

    if (facisScreening?.type && 'subtype' in facisScreening) {
      additionalProperties = {
        ...additionalProperties,
        facis_search: { subtype: facisScreening.subtype },
      };
    }

    if (
      employmentVerification?.type &&
      'maximum_allowed_employers' in employmentVerification &&
      !isNaN(employmentVerification.maximum_allowed_employers as any)
    ) {
      additionalProperties = {
        ...additionalProperties,
        employment_verification: {
          maximum_allowed_employers:
            employmentVerification.maximum_allowed_employers,
        },
      };
    }

    if (
      personalReferenceVerification?.type &&
      'required_quantity' in personalReferenceVerification &&
      !isNaN(personalReferenceVerification.required_quantity[1] as number)
    ) {
      additionalProperties = {
        ...additionalProperties,
        personal_reference_verification: {
          required_quantity: personalReferenceVerification.required_quantity,
        },
      };
    }

    if (
      professionalReferenceVerification?.type &&
      'required_quantity' in professionalReferenceVerification &&
      !isNaN(professionalReferenceVerification.required_quantity[1] as number)
    ) {
      additionalProperties = {
        ...additionalProperties,
        professional_reference_verification: {
          required_quantity:
            professionalReferenceVerification.required_quantity,
        },
      };
    }

    setInitialAdditionalProperties(additionalProperties);
  };

  const screeningAdded = debounce(
    useCallback(
      (screeningType, additionalProperties) =>
        dispatch({
          type: actionTypes.SCREENING_ADDED,
          payload: { screeningType, additionalProperties },
        }),
      [],
    ),
    200,
    { leading: false },
  );

  const screeningRemoved = useCallback(
    screeningType =>
      dispatch({
        type: actionTypes.SCREENING_REMOVED,
        payload: { screeningType },
      }),
    [],
  );

  const addOnsCancelled = useCallback(() => {
    if (state.addedScreeningTypes.length > 0) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.REMOVE_ADD_ONS_PAGE_COMPLETED,
        {
          'Completion Type': 'Remove',
          'Original Package': basePackage.name,
          'Original Package Screenings': basePackage.screenings as any,
          'Add-on Screenings': state.addedScreeningTypes,
          'Package Saved': 'No',
          'New Package Name': newPackageName,
          'International Pricing Page Clicked':
            clickedInternationalPriceVaries as any,
          'Help Center Clicked': clickedHelpCenter as any,
        },
      );
    } else {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.ADD_ONS_PAGE_COMPLETED,
        {
          'Completion Type': 'Cancel',
          'Original Package': basePackage.name,
          'Original Package Screenings': basePackage.screenings as any,
          'Add-on Screenings': state.addedScreeningTypes,
          'Package Saved': 'No',
          'New Package Name': newPackageName,
          'International Pricing Page Clicked':
            clickedInternationalPriceVaries as any,
        },
      );
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.REMOVE_ADD_ONS_PAGE_COMPLETED,
        {
          'Completion Type': 'Cancel',
          'Original Package': basePackage.name,
          'Original Package Screenings': basePackage.screenings as any,
          'Add-on Screenings': state.addedScreeningTypes,
          'Package Saved': 'No',
          'New Package Name': newPackageName,
          'International Pricing Page Clicked':
            clickedInternationalPriceVaries as any,
          'Help Center Clicked': clickedHelpCenter as any,
        },
      );
    }
    toggleCancelConfirmation();
    onCancel();
    setNewPackageName('');
    setPackageNameInvalid(false);
    setPackageNameTouched(false);
    return dispatch({ type: actionTypes.ADD_ONS_CANCELLED });
  }, [onCancel]);

  const handleSaveForNextTimeCheckboxClick = useCallback(() => {
    return dispatch({ type: actionTypes.SAVE_FOR_NEXT_TIME });
  }, []);

  const buildPostBodyWithAddOns = useCallback(() => {
    const packageName = state.saveForNextTime
      ? (!packageNameInvalid && newPackageName) || basePackage.name
      : `${basePackage.name} with addons`;
    return utils.buildPostBodyWithAddOns({
      basePackage,
      ...state,
      packageName,
      setSlug: state.saveForNextTime,
      isPrivate: !state.saveForNextTime,
    });
  }, [
    basePackage,
    state.addedScreeningTypes,
    state.additionalProperties,
    state.saveForNextTime,
    newPackageName,
    packageNameInvalid,
  ]);

  const savePackage = useCallback(() => {
    const body = buildPostBodyWithAddOns();
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.ADD_ONS_PAGE_COMPLETED,
      {
        'Completion Type': 'Continue' as any,
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings as any,
        'Add-on Screenings': state.addedScreeningTypes,
        'Package Saved': 'Yes',
        'New Package Name': newPackageName,
        'International Pricing Page Clicked':
          clickedInternationalPriceVaries as any,
      },
    );
    addPackage(body);
  }, [buildPostBodyWithAddOns, addPackage]);

  const toggleCancelConfirmation = useCallback(() => {
    setShowCancelConfirmation(!showCancelConfirmation);
  }, [showCancelConfirmation]);

  useEffect(() => {
    if (addPackageResult.data) {
      setSelectedPackage(addPackageResult.data);
      setAddonsAdded(true);
    }
  }, [
    addPackageResult.isSuccess,
    addPackageResult.data,
    setSelectedPackage,
    setAddonsAdded,
  ]);

  // query for the price of the initial package with no-add ons
  // this data populates the base price in the summary
  const basePackagePricesQuery = usePackagePrice(
    ['base-package-prices', basePackage.name],
    account,
    basePackage,
  );

  // query for the price of the base package plus all possible add ons
  // this data populates each individual screening price in the list section
  const allAddOnPricesQuery = useAllAddOnPrices(account, {
    ...basePackage,
    screenings: [...basePackage.screenings, ...screeningPayloads],
  });

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      state.addedScreeningTypes,
      state.additionalProperties,
    ],
    account,
    {
      ...buildPostBodyWithAddOns(),
    },
    setAddonsPrice,
  );

  const handlePackageNameChange = useCallback((e: any) => {
    setPackageNameTouched(true);
    setNewPackageName(e.target.value);
    setPackageNameInvalid(
      !e.target.value.trim().length || !/^[a-zA-Z0-9\s]*$/.test(e.target.value),
    );
  }, []);

  const getIframeWindowPositions = useCallback(
    (e: MessageEvent) => {
      const { iframe, window } = e.data;
      setIframeTop(iframe.offsetTop);
      setWindowInnerHeight(window.innerHeight);
    },
    [iframeTop],
  );

  const calculatePosOnScroll = useCallback(
    throttle((e: MessageEvent) => {
      const { window } = e.data;
      const parentOffsetY = window.pageYOffset;
      if (parentOffsetY <= packageSummaryOffsetTop) {
        setPackageSummaryTop(0);
      } else {
        setPackageSummaryTop(parentOffsetY - iframeTop);
      }
      setTopbarTop(parentOffsetY - iframeTop);
    }, 200),
    [iframeTop, packageSummaryOffsetTop],
  );

  useEffect(() => {
    buildInitialAdditonalProperties();
  }, []);

  const topbarHeight = useMemo(() => {
    return topbarRef.current?.clientHeight || 0;
  }, [topbarRef.current]);

  useEffect(() => {
    if (packageSummaryRef.current) {
      setPackageSummaryOffsetTop(packageSummaryRef.current?.offsetTop);
    }
  }, []);

  useDashboardWindowScroll(contextId);

  useDashboardRelay({
    messageId: 'window_iframe_positions',
    callback: getIframeWindowPositions,
    isIframe,
    contextId,
    deps: [getIframeWindowPositions, isIframe],
  });

  useDashboardRelay({
    messageId: 'window_iframe_positions_on_scroll',
    callback: calculatePosOnScroll,
    isIframe,
    contextId,
    deps: [calculatePosOnScroll, isIframe],
  });

  const skipAddons = useCallback(() => {
    onCancel();
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.ADD_ONS_PAGE_COMPLETED,
      {
        'Completion Type': 'Skip' as any,
        'Original Package': basePackage.name,
        'Original Package Screenings': basePackage.screenings as any,
        'Add-on Screenings': state.addedScreeningTypes,
        'Package Saved': 'No',
        'New Package Name': newPackageName,
        'International Pricing Page Clicked':
          clickedInternationalPriceVaries as any,
      },
    );
  }, [onCancel]);
  const handlePriceVariesClick = useCallback(() => {
    setClickedInternationalPriceVaries('Yes');
  }, []);
  const handleHelpCenterClick = useCallback(() => {
    setClickedHelpCenter('Yes');
  }, []);

  return (
    <>
      <Dialog
        open={showCancelConfirmation}
        header='Remove add-ons'
        content={
          <span>
            This will remove all of the selected add-ons from your package. If
            you are experiencing any issues or need additional info, please
            visit our&nbsp;
            <a
              href='https://help.checkr.com/hc/en-us/articles/8789699142551-Package-add-ons-Add-screenings-to-an-existing-package'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleHelpCenterClick}
            >
              Help Center
            </a>
            .
          </span>
        }
        footer={
          <div>
            <M.Button onClick={toggleCancelConfirmation} kind='secondary'>
              Cancel
            </M.Button>
            <M.Button onClick={addOnsCancelled}>Remove</M.Button>
          </div>
        }
        onClose={toggleCancelConfirmation}
      />
      <InnerSection>
        <TopBar top={topbarTop} ref={topbarRef}>
          <h2>
            <span className='full-text'>
              Customize your package with add-ons
            </span>
            <span className='short-text'>Choose Add-ons</span>
          </h2>
          <ContinueSection className='continue-buttons'>
            {state.addedScreeningTypes.length ? (
              <M.Button
                kind='secondary'
                onClick={toggleCancelConfirmation}
                id='cancel-addons-button'
              >
                Cancel
              </M.Button>
            ) : (
              <M.Button
                kind='secondary'
                onClick={skipAddons}
                id='skip-addons-button'
              >
                Skip
              </M.Button>
            )}
            <M.Button
              disabled={
                addPackageResult.isLoading ||
                !state.addedScreeningTypes.length ||
                (state.saveForNextTime &&
                  (!packageNameTouched || packageNameInvalid))
              }
              onClick={savePackage}
              id='confirm-addons-button'
            >
              Continue
            </M.Button>
          </ContinueSection>
        </TopBar>
        {Note && (
          <NoteContainer>
            <Note onClick={handlePriceVariesClick} />
          </NoteContainer>
        )}
        <InnerContainer>
          <ScreeningsSection>
            <AddScreeningsList
              onAddClick={screeningAdded}
              onRemoveClick={screeningRemoved}
              disabledScreenings={disabledScreenings}
              addedScreenings={state.addedScreeningTypes}
              additionalProperties={state.additionalProperties}
              itemPrices={allAddOnPricesQuery.data?.items || []}
              basePackage={basePackage}
            />
          </ScreeningsSection>
          <div>
            <SummaryContainer top={packageSummaryTop} ref={packageSummaryRef}>
              <SummarySection
                data-testid='package-summary'
                maxHeight={
                  windowInnerHeight -
                  topbarHeight -
                  iframeTop -
                  2 * CONTAINER_TOP_BOTTOM_PADDING
                }
              >
                <AddScreeningsSummary
                  basePackage={basePackage}
                  basePackagePrices={basePackagePricesQuery.data}
                  basePackageScreeningTypes={basePackageScreeningTypes}
                  pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                  hasAddedScreenings={Boolean(state.addedScreeningTypes.length)}
                  additionalProperties={{
                    ...initialAdditionalProperties,
                    ...state.additionalProperties,
                  }}
                  helpCenterClicked={clickedHelpCenter}
                />
                <div>
                  {hasPermission(currentUser, 'manage_packages') && (
                    <SaveForNextTimeContainer data-testid='save-for-next-time-container'>
                      <M.Checkbox
                        data-testid='save-for-next-time-checkbox'
                        id='save-for-next-time-checkbox'
                        checked={state.saveForNextTime}
                        labelText='Save package for next time'
                        onChange={handleSaveForNextTimeCheckboxClick}
                      />

                      {state.saveForNextTime && (
                        <SavePackageNameContainer>
                          <M.TextInput
                            data-testid='name-your-package-textinput'
                            id='name-your-package-textinput'
                            labelText='Name your package'
                            value={newPackageName}
                            invalid={packageNameInvalid}
                            onChange={handlePackageNameChange}
                            invalidText='Package name cannot be blank or include special characters'
                          />
                        </SavePackageNameContainer>
                      )}
                    </SaveForNextTimeContainer>
                  )}
                </div>
              </SummarySection>
            </SummaryContainer>
          </div>
        </InnerContainer>
        <FooterSection>
          {state.addedScreeningTypes.length ? (
            <M.Button
              kind='secondary'
              onClick={toggleCancelConfirmation}
              id='cancel-addons-button'
            >
              Cancel
            </M.Button>
          ) : (
            <M.Button
              kind='secondary'
              onClick={onCancel}
              id='skip-addons-button'
            >
              Skip
            </M.Button>
          )}
          <M.Button
            disabled={
              addPackageResult.isLoading ||
              !state.addedScreeningTypes.length ||
              (state.saveForNextTime &&
                (!packageNameTouched || packageNameInvalid))
            }
            onClick={savePackage}
            id='confirm-addons-button'
          >
            Continue
          </M.Button>
        </FooterSection>
      </InnerSection>
    </>
  );
};

export default AddScreenings;
