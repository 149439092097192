import * as Entity from 'modules/assess/models/rulesets/version';
import client from './client';
import { Namespace } from './constants';

import { fromAPI } from './ruleset/utilities/versions';

export const BASE_PATH = 'ruleset_versions';

// export const create = (data: Entity.Type): Promise<Entity.Type> => {
//   return client.post(BASE_PATH, data);
// };

// export const destroy = (id: string) => {
//   return client.delete(`${BASE_PATH}/${id}`);
// };

export const fetch = (
  namespace: Namespace,
  id: string,
): Promise<Entity.Type> => {
  const path = `/${namespace}/${BASE_PATH}/${id}`;
  return client.get(path).then(fromAPI);
};

export const lookbackToCSV = (
  namespace: Namespace,
  id: string,
  name: string = `Assess - Lookback Rules - ${id}.csv`,
): Promise<any> => {
  const path = `/${namespace}/${BASE_PATH}/${id}/lookback/csv`;
  return client.download(path, name);
};

export const promote = (namespace: Namespace, id: string) => {
  const path = `/${namespace}/${BASE_PATH}/${id}/promote`;
  return client.post(path);
};

// export type SearchParams = Pick<Entity.Type, 'kind'>;
// export type SearchResults = {
//   count: number;
//   data: Array<Entity.Type>;
// };

// export const search = ({ kind }: SearchParams): Promise<SearchResults> => {
//   return client.get(BASE_PATH, { params: { type: kind } });
// };

export const update = (
  namespace: Namespace,
  { id, kind, ...updates }: Entity.Type,
): Promise<Entity.Type> => {
  const path = `/${namespace}/${BASE_PATH}/${id}`;
  return client.patch(path, updates).then(fromAPI);
};

export const downloadPdf = (
  namespace: Namespace,
  id: string,
  name: string = `Assess - Guidelines - ${id}.pdf`,
): Promise<any> => {
  const path = `/${namespace}/${BASE_PATH}/${id}/pdf`;
  return client.download(path, name, { responseType: 'blob' });
};

export const uri = (namespace: Namespace, id?: string): string => {
  const base = `/${namespace}/${BASE_PATH}`;
  const path = id ? `${base}/${id}` : base;
  return client.uri({ url: path });
};
