import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { SegmentsContainer } from 'containers';
import { SegmentsProvider } from 'components/Segments/SegmentsContext';
import { ParentContext } from 'api/segments';

type Props = {
  context: ParentContext;
  user: any;
  accountId: string;
  hideModal: Function;
  open: boolean;
};

const AssignNodesModal: React.FC<Props> = ({
  context,
  user,
  accountId,
  hideModal,
  open,
}) => {
  return (
    <M.ComposedModal onClose={hideModal} open={open} size='lg'>
      <M.ModalHeader closeModal={hideModal}>
        <h2>{`${context} - ${user.email}`}</h2>
      </M.ModalHeader>
      <SegmentsProvider>
        <SegmentsContainer
          parentContext={context}
          parentId={user.id}
          accountId={accountId}
          closeModal={hideModal}
        />
      </SegmentsProvider>
    </M.ComposedModal>
  );
};

export default AssignNodesModal;
