import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
};

export const TableHeader: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation('assess');
  const text = t(
    `assess:ruleset.version.publish.changes.context_date_options.table.headers.${name}`,
  );
  return <strong>{text}</strong>;
};

export default TableHeader;
