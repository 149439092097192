import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import AddChecks from 'components/Packages/AddChecks';
import { I9_ORDERING } from 'Flags';
import {
  ADD_CHECKS_EVENT_NAMES,
  useTrackEvent as useTrackAddChecksEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import { useCandidate } from 'providers/Candidate';
import queryString from 'querystring';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateParentWindowUrl } from 'utils';

interface AddChecksActionProps {
  canOrderNewReport: boolean;
  orderAddonsFlowEnabled: boolean;
}

const AddChecksAction: React.FC<AddChecksActionProps> = ({
  canOrderNewReport,
  orderAddonsFlowEnabled,
}) => {
  const [addCheckModalOpen, setAddCheckModalOpen] = useState(false);
  const candidate = useCandidate();
  const { contextId } = useContext(UIContext);
  const currentUser = useUser();
  const history = useHistory();
  const { isIframe } = useContext(UIContext);
  const trackAddChecksEvent = useTrackAddChecksEvent();

  const showAddChecksButton =
    canOrderNewReport && orderAddonsFlowEnabled && !!candidate?.last_report_id;

  const showI9Button = useFlag(I9_ORDERING)?.variantKey === 'on';

  const isInternationalCandidate = candidate?.geos
    ? candidate.geos?.filter(
        geo => geo.country !== 'US' && geo.country !== null,
      )?.length > 0
    : false;

  const isCandidateEmailEmpty = !candidate?.email?.length;

  const addChecksToolTipLabel = useMemo(() => {
    if (isInternationalCandidate) {
      return 'This feature is not available for international candidates yet.';
    }
    if (isCandidateEmailEmpty) {
      return 'This feature is unavailable because the candidate requested removal of their personal information.';
    }
    return 'This feature is currently not available';
  }, [isInternationalCandidate, isCandidateEmailEmpty]);

  const openModal = useCallback(
    e => {
      trackAddChecksEvent(
        currentUser,
        ADD_CHECKS_EVENT_NAMES.ADD_CHECKS_SELECTED,
      );
      setAddCheckModalOpen(true);
    },
    [currentUser, trackAddChecksEvent],
  );

  const handleI9Click = useCallback(() => {
    const queryParams = { source: 'report' };
    const queryStringified = queryString.stringify(queryParams);
    const path = '/i-9';
    if (isIframe && contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: true,
      });
    } else {
      history.push({
        pathname: '/i-9',
        search: queryStringified,
      });
    }
  }, [contextId, history, isIframe]);

  return (
    <>
      {showAddChecksButton &&
        (isInternationalCandidate || isCandidateEmailEmpty) && (
          <M.Tooltip
            label={addChecksToolTipLabel}
            data-testid='add-checks-tooltip'
            align='bottom'
            style={{
              paddingTop: '2px',
            }}
          >
            <M.Icon icon='InformationFilled' />
          </M.Tooltip>
        )}
      {showAddChecksButton && !showI9Button && (
        <M.Button
          data-testid='add-checks-button'
          kind='secondary'
          size='small'
          onClick={openModal}
          disabled={isInternationalCandidate || isCandidateEmailEmpty}
        >
          Add checks
        </M.Button>
      )}
      {showAddChecksButton && showI9Button && (
        <M.SplitActionButton
          kind='secondary'
          isDisabled={isInternationalCandidate || isCandidateEmailEmpty}
          buttonLabel='Add Checks'
          buttonAction={openModal}
          showLeftIcon={false}
          showRightIcon={false}
          dropdownItems={[
            {
              title: 'A-la-carte order',
              subtitle: 'Add checks to this order',
              action: openModal,
            },
            {
              title: 'Order I-9',
              subtitle: 'Prepare to hire this candidate',
              action: handleI9Click,
            },
          ]}
        />
      )}
      {showAddChecksButton && (
        <AddChecks
          candidate={candidate}
          modalOpen={addCheckModalOpen}
          setModalOpen={setAddCheckModalOpen}
        />
      )}
    </>
  );
};

export default AddChecksAction;
