import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { PostAddressParams } from 'api/payment';
import {
  StyledInput,
  StyledNote,
  StyledSelect,
  GridRow,
  GridCol,
} from './PaymentStyledComponents';
import { postbillingAddressValidationSchema } from './utils';

type Props = {
  addressData: Partial<PostAddressParams>;
  submit: any;
  modalOpen: boolean;
  hideModal: any;
  isProcessing: boolean;
  error: any;
};

const BillingAddressForm: React.FC<Props> = ({
  addressData,
  submit,
  modalOpen,
  hideModal,
  isProcessing,
  error,
}) => {
  const { t } = useTranslation();
  const initialValues: Partial<PostAddressParams> = {
    name: '',
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    region: '',
    postal_code: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: postbillingAddressValidationSchema,
    onSubmit: values => {
      submit(values);
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;

  useEffect(() => {
    formik.setValues(addressData);
  }, [modalOpen]);

  const showError = (field: string): boolean => {
    return (errors[field as keyof typeof errors] &&
      touched[field as keyof typeof touched]) as boolean;
  };

  const errorMessage = (field: string): string | null => {
    return touched[field as keyof typeof touched]
      ? (errors[field as keyof typeof errors] as string)
      : null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.LoadingSpinner active={isProcessing} />
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`billing_address.modal_header`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          {error && (
            <M.InlineNotification
              kind='error'
              title='Something went wrong, please try again later.'
            />
          )}
          <StyledNote>{t(`billing_address.description`)}</StyledNote>
          <M.Grid>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='name'
                  name='name'
                  labelText={t(`billing_address.add_address_form.name.label`)}
                  placeholder={t(
                    `billing_address.add_address_form.name.placeholder`,
                  )}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('name')}
                  invalidText={errorMessage('name')}
                  disabled
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='address_line_1'
                  name='address_line_1'
                  labelText={t(
                    `billing_address.add_address_form.address_1.label`,
                  )}
                  placeholder={t(
                    `billing_address.add_address_form.address_1.placeholder`,
                  )}
                  value={values.address_line_1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_1')}
                  invalidText={errorMessage('address_line_1')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='address_line_2'
                  name='address_line_2'
                  labelText={t(
                    `billing_address.add_address_form.address_2.label`,
                  )}
                  placeholder={t(
                    `billing_address.add_address_form.address_2.placeholder`,
                  )}
                  value={values.address_line_2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_2')}
                  invalidText={errorMessage('address_line_2')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  id='city'
                  name='city'
                  labelText={t(`billing_address.add_address_form.city.label`)}
                  placeholder={t(
                    `billing_address.add_address_form.city.placeholder`,
                  )}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('city')}
                  invalidText={errorMessage('city')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol style={{ marginRight: '16px' }}>
                <StyledInput
                  id='region'
                  name='region'
                  labelText={t(`billing_address.add_address_form.state.label`)}
                  placeholder={t(
                    `billing_address.add_address_form.state.placeholder`,
                  )}
                  value={values.region}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('region')}
                  invalidText={errorMessage('region')}
                />
              </GridCol>
              <GridCol>
                <StyledInput
                  id='postal_code'
                  name='postal_code'
                  labelText={t(`billing_address.add_address_form.zip.label`)}
                  placeholder={t(
                    `billing_address.add_address_form.zip.placeholder`,
                  )}
                  value={values.postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('postal_code')}
                  invalidText={errorMessage('postal_code')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledSelect
                  id='country'
                  name='country'
                  labelText={t(
                    `billing_address.add_address_form.country.label`,
                  )}
                  placeholder={
                    addressData.country
                      ? null
                      : t(
                          `billing_address.add_address_form.country.placeholder`,
                        )
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('country')}
                  invalidText={errorMessage('country')}
                  defaultValue={addressData.country || 'placeholder-item'}
                >
                  <M.Select.Item
                    disabled
                    hidden
                    value='placeholder-item'
                    text={t(
                      `billing_address.add_address_form.country.placeholder`,
                    )}
                  />
                  <M.Select.Item value='US' text='United States' />
                  <M.Select.Item value='CA' text='Canada' />
                </StyledSelect>
              </GridCol>
            </GridRow>
          </M.Grid>
        </M.ModalBody>
        <M.ModalFooter>
          <M.Button onClick={hideModal} kind='secondary'>
            {t(`billing_address.add_address_form.cancel_btn`)}
          </M.Button>
          <M.Button type='submit'>
            {t(`billing_address.add_address_form.continue_btn`)}
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>
    </form>
  );
};

export default BillingAddressForm;
