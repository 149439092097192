import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const EditBrandContainer = styled.div`
  height: 400px;
`;

export const BrandsButton = styled(M.Button)`
  font-size: 12px;
  padding: 0.5rem !important;
  margin: 0.2rem !important;
  min-height: 2rem;
`;

export const BrandFooterContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const BrandFooterItemRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const BrandFileInputGroup = styled.div`
  background-color: ${colors.uiGrey200};
  align-items: flex-start;
  flex-direction: row;
  padding: 1rem;
  width: calc(100% - 2rem);
  display: flex;
  margin: 1rem;
  border-radius: 0.25rem;
`;
