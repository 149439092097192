import React, { SyntheticEvent, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUserAccount } from 'modules/assess/ui/hooks';
import Decision from 'modules/assess/v2/models/decision';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../common';
import Actions from './Actions';
import Content from './Content';

type Props = {
  decision?: Decision;
  open: boolean;
  onClose?: (event?: SyntheticEvent) => void;
  onConfirm?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({
  decision = Decision.Eligible,
  open,
  onClose,
  onConfirm,
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation('assess');
  const account = useUserAccount();
  const path = account?.assess_eligible_item_treatment_enabled
    ? '.assess_eligible_item_treatment_enabled'
    : '';
  const modalTitle: any = t(
    `assess:settings.reports.eligible-records.report-view.confirmation-dialog${path}.title`,
    { returnObjects: true, decision },
  );
  const handleConfirm = useCallback(() => {
    setChecked(false);
    onConfirm?.();
  }, [onConfirm]);

  const handleClose = useCallback(() => {
    setChecked(false);
    onClose?.();
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} size='sm'>
      <M.ModalHeader closeModal={handleClose} title={modalTitle} />
      <M.ModalBody data-testid='assess-settings-confirmation-dialog-content'>
        <Content
          assessEligibleTreatment={
            account?.assess_eligible_item_treatment_enabled
          }
          decision={decision}
          checked={checked}
          handleChange={setChecked}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-settings-confirmation-dialog-footer'>
        <Actions
          disabled={!checked}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      </M.ModalFooter>
    </Dialog>
  );
};

export default Container;
