import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledSpan = styled.span`
  font-weight: bold;
  color: ${colors.brandAqua4};
`;

const StyledList = styled.ul`
  margin: 0.5rem 0 1rem 0;
  padding-left: 1rem;

  li {
    &::before {
      content: '•';
      margin-left: -0.5rem;
      margin-right: 0.25rem;
    }
  }
`;

export const UploadInstructions: React.FC = () => {
  return (
    <div>
      <p>
        <strong>Your CSV file should include:</strong>
      </p>
      <StyledList>
        <li>
          <StyledSpan>Name</StyledSpan> - your preferred name for the node in
          the hierarchy.
        </li>
        <li>
          <StyledSpan>Custom Id</StyledSpan> - a unique identifier for the node.
          May contain letters A-Z, numbers 0-9, ampersands (&), hyphens (-),
          colons (:), underscores (_), or spaces.
        </li>
        <li>
          <StyledSpan>Parent Custom Id</StyledSpan> - (optional) the Custom Id
          of an existing node, that you intend to specify as the parent.
        </li>
        <li>
          <StyledSpan>Tier</StyledSpan> - (optional) grouping of nodes in your
          hierarchy. Example: Department name or gepgraphic location.
        </li>
      </StyledList>
    </div>
  );
};

export default UploadInstructions;
