import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { useLite } from 'modules/assess/v2/ui/hooks';
import { StyledComponents } from 'modules/assess/v2/ui/common';
import Option from './Option';
import UpgradeOption from './UpgradeOption';

const StyledContainer = styled(StyledComponents.SlateContainer)`
  color: ${colors.brandNavy3};
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const Options = styled.div`
  display: flex;
  margin-top: 1rem;
  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const Container: React.FC = () => {
  const isLite = useLite();
  const { t } = useTranslation('assess:v2');
  const allCandidates = t('review_and_apply.assign_guidelines.all');
  const selectCandidates = humanizeOnUnderscore(
    t('review_and_apply.assign_guidelines.select'),
  );
  const title = humanizeOnUnderscore(
    t('review_and_apply.assign_guidelines.title'),
  );

  const containerId =
    'assess-v2-ui-review-and-apply-assign-guidelines-container';
  const titleId = `${containerId}-title`;

  if (isLite) {
    return null;
  }

  return (
    <StyledContainer data-testid={containerId}>
      <Title data-testid={titleId}>
        <Trans>{title}</Trans>
      </Title>
      <Options>
        <Option checked text={allCandidates} />
        <UpgradeOption text={selectCandidates} />
      </Options>
    </StyledContainer>
  );
};

export default Container;
