import { AnyQueryKey, useQuery } from 'react-query';
import * as accounts from 'modules/assess/api/accounts';

const namespace = 'accounts';

export const useDefaultRulesets = (accountId?: string) => {
  const key: AnyQueryKey = [namespace, { accountId }];

  const request = () => {
    if (!accountId) {
      return Promise.reject();
    }
    return accounts.fetchDefaultRulesets(accountId);
  };

  const result = useQuery(key, request);
  return result;
};
