import { ButtonAsLink } from 'components/AdverseActions/AdverseActionsStyledComponents';
import { useNamespace } from 'modules/assess/ui/router/context';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  toggleRules: (event: MouseEvent<HTMLButtonElement>) => void;
  hidden: boolean;
};
const LinkWrapper = styled.div`
  margin-top: 1rem;
`;

export const ToggleFairnessSettings: React.FC<Props> = ({
  toggleRules,
  hidden,
}) => {
  const namespace = useNamespace();
  const { t } = useTranslation('assess');
  const context = hidden ? 'show' : 'hide';
  const text = t(`assess:rules.premade-list.toggle`, { context, namespace });
  return (
    <LinkWrapper>
      <ButtonAsLink onClick={toggleRules}>{text}</ButtonAsLink>
    </LinkWrapper>
  );
};

export default ToggleFairnessSettings;
