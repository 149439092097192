import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { LookbackRuleChange } from 'modules/assess/models/rulesets/version-diff';
import { Field } from 'modules/assess/models/rulesets/version-diff/common';
import BaseChange from '../common/Field';
import { FieldStyle } from '../common/FieldStyle';

const { TableCell, TableRow } = M.DataTable;

const BaseCell = styled(TableCell)`
  border: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

const SubcategoryCell = styled(BaseCell)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 25% !important;
`;

const StyledCell = styled(BaseCell)`
  width: 12.5% !important;

  div {
    text-align: center;
    font-weight: 500;
  }
`;

const columns: Array<string> = [
  'charge_subsubcategory',
  'felony_eligible_years',
  'felony_escalated_years',
  'misdemeanor_eligible_years',
  'misdemeanor_escalated_years',
  'petty_offense_eligible_years',
  'petty_offense_escalated_years',
];

type Props = {
  change: LookbackRuleChange.Type;
};

const Change: React.FC<Props> = ({ change }) => {
  const children = columns.map((c, i) => {
    const changeProp = change[c] as Field.Type;
    const baseChange = (
      <BaseChange
        field={changeProp}
        fieldStyle={FieldStyle.LOOKBACK}
        includeName={false}
      />
    );

    if (i === 0) {
      return (
        <SubcategoryCell key='header' colSpan={1}>
          {baseChange}
        </SubcategoryCell>
      );
    }

    return <StyledCell key={c}>{baseChange} </StyledCell>;
  });

  return <TableRow>{children}</TableRow>;
};

export default Change;
