import React, { useCallback, useState } from 'react';
import { accountHasPermission, CurrentUser } from '@dashboard-experience/utils';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { accounts } from '../../../../api';
import { useSearchState } from '../../search-context';
import { useTrackEvent } from '../../../../utils/analytics/analytics';
import { CANDIDATE_SEARCH_DROPDOWN_NAMES } from '../../../../utils/analytics/events';
import { getSelectionValue, AnalyticsProps } from '../helpers';
import { scrollToTop } from '../../../../utils';
import { REPORT_STATUS_HELP_CENTER_URL } from '../../constants';
import { StatusModalContent, StyledBaseFilter } from '../StyledComponents';

const Adjudication = () => {
  const { currentUser } = useSearchState();
  const account = currentUser?.account as unknown as {
    id: string;
    name: string;
    adjudication_deferred_enabled: boolean;
  };
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const adverseActionEnabled = accountHasPermission(
    currentUser as unknown as CurrentUser,
    'adverse_action_enabled',
  );

  const subtypesQuery = accounts.useAdjudicationSubtypes(account);
  const subtypes = subtypesQuery?.data;

  const eventToValue = getSelectionValue;
  const stateToValue = useCallback((state: any) => {
    let value = state.adjudication || '';
    if (state.adjudication_subtype) {
      value = `${value}:${state.adjudication_subtype}`;
    }
    return value;
  }, []);
  const valueToState = useCallback((value: any) => {
    const [adjudication, id] = value.split(':');
    const adjudication_subtype = id ? { id } : undefined;
    return {
      adjudication,
      adjudication_subtype,
    };
  }, []);

  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.ADJUDICATION,
    trackEvent,
  };

  const showModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback((): void => {
    setModalOpen(false);
  }, []);

  const accountHasDeferredEnabled = account?.adjudication_deferred_enabled;

  const translationKey = 'filterTooltips.adjudicationFilterDefinitions';
  const labelText = t(`${translationKey}.label`);

  const tooltipText = (
    <StatusModalContent>
      <strong>{t(`${translationKey}.none.title`)}: </strong>
      {t(`${translationKey}.none.definition`)} <br />
      <strong>{t(`${translationKey}.engaged.title`)}: </strong>
      {t(`${translationKey}.engaged.definition`)} <br />
      <strong>{t(`${translationKey}.pre_aa.title`)}: </strong>
      {t(`${translationKey}.pre_aa.definition`)} <br />
      <strong>{t(`${translationKey}.post_aa.title`)}: </strong>
      {t(`${translationKey}.post_aa.definition`)} <br />
      <strong>{t(`${translationKey}.manual.title`)}: </strong>
      {t(`${translationKey}.manual.definition`)} <br /> <br />
      <a
        href={REPORT_STATUS_HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        {t('filterTooltips.continueReadingLink')}...
      </a>
    </StatusModalContent>
  );

  const labelHTML = (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ marginRight: '4px' }}>{labelText}</span>
      <M.Icon icon='InformationFilled' onClick={showModal} />
    </span>
  );

  return (
    <div data-testid='adjudication-filter'>
      <StyledBaseFilter
        component='AdjudicationFilter'
        filterName='adjudication'
        params={['adjudication', 'adjudication_subtype']}
        adverseActionEnabled={adverseActionEnabled}
        deferredEnabled={accountHasDeferredEnabled}
        subtypes={subtypes}
        eventToValue={eventToValue}
        stateToValue={stateToValue}
        valueToState={valueToState}
        labelText={labelHTML}
        trackAnalyticsObject={trackAnalyticsObject}
      />
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`${translationKey}.modalHeading`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>{tooltipText}</M.ModalBody>
      </M.ComposedModal>
    </div>
  );
};

export default Adjudication;
