import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { SSNTrace } from 'types';

interface Props {
  resolvedAt: string | null;
  type: string;
  ssnTrace?: SSNTrace | null;
}

const ResolvedAt: React.FC<Props> = ({ resolvedAt, type, ssnTrace }) =>
  resolvedAt ? (
    <div className='exception-item' style={{ display: 'flex' }}>
      {ssnTrace?.ssn_already_taken && type === 'ssn_confirmation_requested' && (
        <>
          <M.Icon
            icon='Misuse'
            size='16'
            style={{
              fill: colors.dangerRed100,
              marginRight: '0.5rem',
              alignSelf: 'center',
            }}
          />{' '}
          <span
            className='ssn-confirmation-requested-message'
            style={{ fontWeight: 'bold' }}
          >
            Requested action was blocked due to duplicate SSN
          </span>
        </>
      )}
      {(!ssnTrace ||
        !ssnTrace.ssn_already_taken ||
        type !== 'ssn_confirmation_requested') && (
        <>
          <M.Icon
            icon='CheckmarkFilled'
            size='16'
            style={{
              fill: colors.successGreen100,
              marginRight: '0.5rem',
              alignSelf: 'center',
            }}
          />{' '}
          <span style={{ fontWeight: 'bold' }}>
            Requested action has been completed on{' '}
            {moment(resolvedAt).format('lll')}{' '}
          </span>
        </>
      )}
    </div>
  ) : null;

export default ResolvedAt;
