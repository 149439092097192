const downloadPDF = ({ data }: { data: Blob }) => {
  const blob = new Blob([data], { type: 'application/pdf' });

  const href = URL.createObjectURL(blob);

  // Creates an invisible <a> tag
  const element = document.createElement('a');
  element.setAttribute('href', href);
  element.setAttribute('download', '');
  element.style.display = 'none';

  // Add the <a> to the document, click on it, and then remove it from the document
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default downloadPDF;
