import moment from 'moment';
import humanizeDuration from 'humanize-duration';

export const formatDate = (date?: string | null, fallback?: string | null) =>
  date ? moment(date).format('MMM y') : fallback;

export const dateRange = (startDate?: string | null, endDate?: string | null) =>
  `${formatDate(startDate, 'Unknown')} - ${formatDate(endDate, 'Present')}`;

export const duration = (
  startDate?: string | null,
  endDate?: string | null,
) => {
  if (!startDate) {
    return '';
  }

  const from = new Date(startDate).getTime();
  const to = endDate ? new Date(endDate).getTime() : new Date().getTime();

  return humanizeDuration(to - from, {
    round: true,
    units: ['y', 'mo'],
  });
};
