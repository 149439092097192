import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { STRIPE_KEY } from 'Constants';
import { StyledErrorInlineNotification } from './PaymentStyledComponents';
import useSetClientSecret from './utils/stripeHelpers';
import AddPaymentForm from './AddPaymentForm';

const AddPaymentModal = ({
  modalOpen,
  hideModal,
  elementOptions,
  setElementOptions,
  error,
  setError,
  postPaymentMethod,
  paymentType,
}: any) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();

  const stripePromise = loadStripe(STRIPE_KEY!);

  useSetClientSecret({
    setElementOptions,
    elementOptions,
    setIsProcessing,
    setError,
    paymentType,
  });

  const paymentTypeKey =
    paymentType === 'card' ? 'cc_info' : 'bank_information';
  const modalTitle = t(`payment.${paymentTypeKey}.modal_header_add`);

  return (
    <M.ComposedModal
      open={modalOpen}
      onClose={hideModal}
      data-testid='add-payment-modal'
    >
      <M.LoadingSpinner active={isProcessing} />
      <M.ModalHeader closeModal={hideModal}>
        <h2>{modalTitle}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        {error && (
          <StyledErrorInlineNotification
            kind='error'
            title={error}
            hideCloseButton
          />
        )}
        {!isProcessing && elementOptions && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: elementOptions }}
          >
            <AddPaymentForm
              hideModal={hideModal}
              setError={setError}
              setElementOptions={setElementOptions}
              postPaymentMethod={postPaymentMethod}
            />
          </Elements>
        )}
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default AddPaymentModal;
