import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const SearchPDF: React.FC<{ text: string; url: string }> = ({ text, url }) => (
  <M.Link href={url} target='_blank'>
    {text}
  </M.Link>
);

export default SearchPDF;
