import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const UnavailablePackageTooltip = styled(M.Tooltip)`
  &.cds--tooltip {
    margin-bottom: 16px !important;

    .cds--popover-content {
      max-width: 100% !important;
    }
  }
`;

const UnavailableForManualBulkTooltip = (children: ReactNode) => (
  <UnavailablePackageTooltip
    align='top'
    label='This package contains checks that are unavailable for bulk manual orders.'
    highlighted={false}
    trigger='button'
    enterDelayMs={0}
    leaveDelayMs={0}
    mini
  >
    {children}
  </UnavailablePackageTooltip>
);

export default UnavailableForManualBulkTooltip;
