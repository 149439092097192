import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

type Props = {
  paragraphs: string[];
};

const StyledTooltip = styled(M.TooltipDefinition)`
  #mastodon && {
    .cds--definition-tooltip {
      max-width: 500px;
      padding: 24px;
      color: ${colors.brandNavy4};
    }

    svg {
      fill: ${colors.uiNavy600};
    }
  }
  margin-left: 0.5rem;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tooltip: React.FC<Props> = ({ paragraphs }) => {
  const tooltipParagraphs = paragraphs.map((t: string) => <p key={t}>{t}</p>);

  return (
    <StyledTooltip
      highlighted={false}
      align='bottom'
      definition={<DialogContainer>{tooltipParagraphs}</DialogContainer>}
    >
      <M.Icon icon='HelpFilled' />
    </StyledTooltip>
  );
};

export default Tooltip;
