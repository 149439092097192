import { SearchClient } from 'api';
import { GenericObject } from 'types';

export const performSearch = (searchQuery: string): Promise<GenericObject> =>
  SearchClient.get(`/search?order_by=created_at&q=${searchQuery}`);

export type BaseParams = {
  url: string;
  cancelToken: any;
};

export const getCandidates = ({
  url,
  cancelToken,
}: BaseParams): Promise<GenericObject> => {
  return SearchClient.get(url, { cancelToken });
};
