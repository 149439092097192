import { Client } from 'api';
import { GenericObject } from 'types';
import { ExpungementsClient } from 'utils/APIClient';
import {
  IndividualizedAssessment,
  Subtype,
  Templates,
  DaystoPostAA,
  Basis,
  MediaConfig,
} from 'modules/adjudication/data';
import { ParamKeys } from '../../../components/AdverseActions/param-keys';
import SortingDirection from '../../../components/AdverseActions/sort-direction';
import {
  AdverseAction,
  AdverseActionTemplatesPayload,
  AdversibleItemsPayload,
  ExpungementsPayload,
  PreAdverseActionNoticeTemplatePayload,
} from '../data';

export type AdverseActionsPayload = {
  data: AdverseAction[];
  object: 'string';
  count: number;
};

export type SearchParams = {
  page: number;
  per_page: number;
  order_by: ParamKeys;
  sort: SortingDirection;
  status: string;
};

export type TemplateParams = {
  basis?: string;
};

export type BaseAAPayload = {
  adverse_item_ids: string[];
  days_to_post_adverse_action: DaystoPostAA;
  template_ids: Templates;
  medium: MediaConfig;
  individualized_assessment: IndividualizedAssessment | null;
  basis?: Basis;
};

export type PostAAPayload = BaseAAPayload & {
  adjudication_subtype: Subtype | null;
  report_id: string;
};

export type PreAANoticePreviewPayload = {
  adverse_item_ids: string[];
  days_to_post_adverse_action: number;
  individualized_assessment: IndividualizedAssessment | null;
  template_ids: { pre: null | number; post: null | number };
};

export type Attachments = Array<{ location: string | null; title: string }>;
export type PreAANoticePreview = {
  attachments: Attachments;
  preview: string;
};

export const ADVERSE_ACTIONS_PATH = 'adverse_actions';
export const ADVERSE_ACTION_TEMPLATES_PATH = 'adverse_action_templates';
export const PRE_ADVERSE_ACTION_NOTICE_TEMPLATE_PATH =
  'pre_adverse_action_template';
export const ADVERSIBLE_ITEMS_PATH = 'adversible_items';
export const PRE_ADVERSE_ACTION_PREVIEW_PATH = 'pre_adverse_action_preview';
export const RESUME_PATH = 'resume';
export const DEFER_PATH = 'defer';
export const RESTART_PATH = 'restart';

export const fetchAllAdverseActions = (
  params: any,
): Promise<AdverseActionsPayload> => {
  return Client.get(`/${ADVERSE_ACTIONS_PATH}`, { params });
};

export const fetchAdverseActions = (
  reportId: string,
): Promise<AdverseActionsPayload> => {
  return Client.get(`/reports/${reportId}/${ADVERSE_ACTIONS_PATH}`);
};

export const fetchAdverseActionTemplates = (
  reportId: string,
  params: TemplateParams,
): Promise<AdverseActionTemplatesPayload> => {
  return Client.get(`/reports/${reportId}/${ADVERSE_ACTION_TEMPLATES_PATH}`, {
    params,
  });
};

export const fetchPreAdverseActionNoticeTemplate = (
  reportId: string,
): Promise<PreAdverseActionNoticeTemplatePayload> => {
  return Client.get(
    `/reports/${reportId}/${PRE_ADVERSE_ACTION_NOTICE_TEMPLATE_PATH}`,
  );
};

export const pauseAdverseAction = (id: any): Promise<any> => {
  return Client.post(`/${ADVERSE_ACTIONS_PATH}/${id}/pause`);
};

export const fetchAdversibleItems = (
  reportId: string,
): Promise<AdversibleItemsPayload[]> => {
  return Client.get(`/reports/${reportId}/${ADVERSIBLE_ITEMS_PATH}`);
};

export const fetchExpungements = (
  params: GenericObject,
): Promise<ExpungementsPayload> => {
  return ExpungementsClient.get('/expungements', { params });
};

export const updateAdverseActionReassessment = ({
  adverseActionId,
  params,
}: // TODO: add type
any): Promise<any> => {
  return Client.patch(
    `/${ADVERSE_ACTIONS_PATH}/${adverseActionId}/individualized_reassessment`,
    params,
  );
};

export const fetchIndividualizedAssessmentDocuments = (
  params: any,
): Promise<any> => {
  return Client.get(
    `/${ADVERSE_ACTIONS_PATH}/individualized_assessment_documents`,
    { params },
  );
};

export const fetchPreAdverseActionNoticePreview = (
  reportId: string,
  params: BaseAAPayload,
): Promise<PreAANoticePreview> => {
  return Client.post(
    `/reports/${reportId}/${PRE_ADVERSE_ACTION_PREVIEW_PATH}`,
    params,
  );
};

export const uploadIndividualizedAssessment = (
  reportId: string,
  file: GenericObject,
  // TODO: type reponse properly
): Promise<GenericObject> => {
  return Client.post(`/reports/${reportId}/documents`, file);
};

export const cancelAdverseAction = (
  adverseActionId?: string,
): Promise<GenericObject> => {
  const path = `/${ADVERSE_ACTIONS_PATH}/${adverseActionId}`;
  return Client.delete(path);
};

export const postAdverseAction = (
  params: PostAAPayload,
): Promise<GenericObject> => {
  const path = `/${ADVERSE_ACTIONS_PATH}`;
  return Client.post(path, params);
};

export const resumeAdverseAction = (
  adverseActionId?: string,
): Promise<AdverseAction> => {
  const path = `/${ADVERSE_ACTIONS_PATH}/${adverseActionId}/${RESUME_PATH}`;

  return Client.post(path);
};

export const restartAdverseAction = (
  adverseActionId?: string,
): Promise<AdverseAction> => {
  const path = `/${ADVERSE_ACTIONS_PATH}/${adverseActionId}/${RESTART_PATH}`;

  return Client.post(path);
};

export const patchAdverseAction = (
  adverseActionId: string,
): Promise<GenericObject> => {
  const path = `/${ADVERSE_ACTIONS_PATH}/${adverseActionId}`;

  // The only state accepted in the backend for this endpoint is 'delivered', so just hardcode it here
  const data = {
    aa_delivery_state: 'delivered',
  };

  return Client.patch(path, data);
};
