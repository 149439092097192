import React from 'react';

const InternationalNote: React.FC<any> = ({ onClick }) => (
  <p>
    Please note that international screening price and turnaround time (TAT)
    varies by country or territory. Visit our{' '}
    <a
      href='https://checkr.com/pricing/international'
      rel='noreferrer'
      target='_blank'
      onClick={onClick}
    >
      International Pricing page
    </a>{' '}
    to learn more.
  </p>
);

export default InternationalNote;
