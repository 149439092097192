import React from 'react';

const ExceptionFooter = () => (
  <div className='exception-footer'>
    <strong>How do I resolve delays so the report will process?</strong>
    <br />
    <p>
      Ask your candidate to follow the instructions they&#39;ve received. You
      can copy and resend the request link(s) above to remind your candidate.
    </p>
    <a
      href='https://help.checkr.com/hc/en-us/articles/16981191393047-Suspended-and-delayed-background-checks'
      target='_blank'
      rel='noreferrer'
    >
      Learn more about exceptions
    </a>
  </div>
);

export default ExceptionFooter;
