import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const StyledToast = styled(M.ToastNotification)`
  border-radius: 8px;
  line-height: 150%;
  position: fixed;
  width: 400px;
  right: 0;
  top: 1;
  margin: 1rem;
  z-index: 10;

  .cds--toast-notification__title {
    padding-bottom: 0.25rem;
  }
`;

const SavedNotification: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t(`home.guidelines.notification`, {
    returnObjects: true,
  });

  return (
    <StyledToast
      kind='success'
      title={translations.title}
      subtitle={<Trans>{translations.subtitle}</Trans>}
      timeout={10000}
    />
  );
};

export default SavedNotification;
