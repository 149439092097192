import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { GenericObject } from '@dashboard-experience/utils';
import { PaymentMethod } from 'api/payment';
import { scrollToTop } from '../../../utils';
import {
  GridCol,
  StyledButton,
  StyledH3,
  StyledNote,
} from './PaymentStyledComponents';
import AddPaymentModal from './AddPaymentModal';
import PaymentDisplay from './PaymentDisplay';

type Props = {
  cards: Array<PaymentMethod>;
  postPaymentMethod: GenericObject;
  paymentMethodRequired: boolean;
  paymentMethodDisabled: boolean;
};

const CreditCardInformation: React.FC<Props> = ({
  cards,
  postPaymentMethod,
  paymentMethodRequired,
  paymentMethodDisabled,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [elementOptions, setElementOptions]: any = useState();

  const hasStoredCard: boolean = cards.length > 0;

  const showAddModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal: any = useCallback((): void => {
    setError('');
    setModalOpen(false);
  }, []);

  useEffect((): void => {
    if (postPaymentMethod.postPaymentMethodResult.isSuccess) {
      hideModal();
      setError('');
    }
  }, [postPaymentMethod.postPaymentMethodResult.isSuccess, hideModal]);

  const renderCards = cards.map((cardInfo: PaymentMethod) => {
    return (
      <div key={cardInfo.stripe_id}>
        <PaymentDisplay
          paymentInfo={cardInfo}
          verified
          paymentMethodRequired={paymentMethodRequired}
        />
      </div>
    );
  });

  return (
    <section data-testid='cc-wrapper'>
      <M.Grid>
        <M.GridRow>
          <GridCol md={5}>
            <M.GridRow>
              <GridCol>
                <StyledH3 className='mb-0'>{t(`credit_card.title`)}</StyledH3>
                {!hasStoredCard && (
                  <>
                    <StyledNote>{t(`credit_card.description`)}</StyledNote>
                    <div>
                      <img
                        src='/images/accepted_cards.svg'
                        alt='Accepted cards'
                      />
                    </div>
                  </>
                )}
              </GridCol>
              <GridCol style={{ textAlign: 'right' }}>
                <StyledButton
                  kind='secondary'
                  disabled={paymentMethodDisabled}
                  onClick={showAddModal}
                  data-testid='cc-add-btn'
                >
                  {t(`credit_card.add_new_btn`)}
                </StyledButton>
              </GridCol>
            </M.GridRow>
            {hasStoredCard && renderCards}
          </GridCol>
        </M.GridRow>
      </M.Grid>

      {modalOpen && (
        <AddPaymentModal
          modalOpen={modalOpen}
          hideModal={hideModal}
          elementOptions={elementOptions}
          setElementOptions={setElementOptions}
          error={error}
          setError={setError}
          postPaymentMethod={postPaymentMethod}
          paymentType='card'
        />
      )}
    </section>
  );
};

export default CreditCardInformation;
