import React, { useState, useCallback } from 'react';
import Button from 'components/Button';
import CreateDialog from 'modules/assess/ui/mvr/category-sets/instance/create';
import { useMVRCustomCategories, useReadOnly } from 'modules/assess/ui/hooks';
import { scrollToTopIfIframe } from 'utils';

type Props = {};

export const AddButton: React.FC<Props> = () => {
  const assessMVRCustomCategories = useMVRCustomCategories();
  const disabled = useReadOnly();
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
    scrollToTopIfIframe();
  }, [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  if (!assessMVRCustomCategories) return null;

  return (
    <>
      <Button
        data-testid='assess-mvr-category-sets-actions-create'
        disabled={disabled}
        kind='secondary'
        onClick={handleClick}
        size='sm'
        trans='assess:mvr.category-sets.actions.create'
      />
      <CreateDialog open={open} onClose={handleClose} />
    </>
  );
};

export default AddButton;
