import React, { useMemo, useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';
import {
  isInternal,
  removeParamsFromUrl,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { ARCHIVE_REPORTS_NAMES, useTrackEvent } from 'utils';
import { updateSearchParams } from 'actions/SearchActions';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { useSearchState } from '../../search-context';
import { onFilterChange } from '../helpers';

type ContentSwitchItem = {
  key: number;
  name: string;
  value: string;
};

const contentTypes: ContentSwitchItem[] = [
  { key: 0, name: 'active', value: 'false' },
  { key: 1, name: 'archived', value: 'true' },
  { key: 2, name: 'all', value: 'all' },
];

const StyledContentSwitcher = styled(M.ContentSwitcher)`
  max-width: 22rem;
  min-width: 18rem;
  width: 100%;

  @media (max-width: 28.75rem) {
    margin-bottom: 1rem;
  }
`;

const SwitchContentType: React.FC = () => {
  const trackEvent = useTrackEvent();
  const { filters, setFilters } = useSearchState();
  const dispatch = useDispatch();
  const { archived } = filters;
  const [showArchived, setShowArchived] = useState(archived || 'false');
  const currentUser = useUser();

  const selectedContentIndex =
    isInternal(currentUser) && !filters.archived
      ? 2
      : contentTypes.find(
          i => i.value === sessionStorage.getItem('resultsContentType'),
        )?.key || 0;

  const handleFilterChange = useMemo(
    () => onFilterChange(setFilters, 'archived'),
    [setFilters],
  );

  useEffectAfterInitialized(() => {
    if (archived !== undefined) {
      if (showArchived !== 'all') {
        dispatch(updateSearchParams({ archived: showArchived }));
      } else {
        dispatch(updateSearchParams({ archived: '' }));
        removeParamsFromUrl(window, ['archived']);
      }
    }
    if (archived === undefined && showArchived) {
      setShowArchived('false');
    }
  }, [archived, dispatch]);

  const getSelectedContentType = useCallback((name: string) => {
    return contentTypes.find(i => i.name === name)?.value;
  }, []);

  const handleContentSwitch = useCallback(
    (item: ContentSwitchItem) => {
      const { name } = item;
      const selected = getSelectedContentType(name);
      setShowArchived(selected);
      setFilters((prevState: any) => ({
        ...prevState,
        archived: selected,
      }));
      handleFilterChange(selected);
      sessionStorage.setItem('resultsContentType', selected || 'false');
      trackEvent(ARCHIVE_REPORTS_NAMES.DASHBOARD_RESULTS_CONTENT_TYPE, {
        'Results Content Type': capitalize(name),
      });
    },
    [getSelectedContentType, handleFilterChange, setFilters, trackEvent],
  );

  return (
    <StyledContentSwitcher
      selectedIndex={selectedContentIndex}
      onChange={handleContentSwitch}
      data-testid='switch_content_type'
    >
      {contentTypes.map((i: ContentSwitchItem) => {
        return (
          <M.Switch
            key={i.key}
            name={i.name}
            text={capitalize(i.name)}
            data-testid={`switch_content_type_${i.name}`}
          />
        );
      })}
    </StyledContentSwitcher>
  );
};

export default SwitchContentType;
