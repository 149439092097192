import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { useLessThanMisdemeanorPremadeRule } from 'modules/assess/v2/ui/hooks';
import './styles.scss';

// Simulate space taken from the expandable arrow icon
const ExpandableGap = styled.div`
  padding-left: calc(1rem + 16px);
`;

const StyledContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;
`;

const Row = styled(M.GridRow)`
  width: 70% !important;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled(M.GridCol)`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
`;

const TextGap = styled.div`
  width: 30% !important;
`;

const Headers: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('common.lookback_rules.table.headers', {
    returnObjects: true,
  });
  const state = useState();
  const includeLessThanMisdemeanor =
    !useLessThanMisdemeanorPremadeRule() &&
    !state.settings?.lookback_rules_all_less_than_misdemeanor_eligible;

  const basePath = 'assess-v2-ui-common-lookback-rules-severity-field-headers';
  const felonyId = `${basePath}-felony`;
  const misdemeanorId = `${basePath}-misdemeanor`;
  const pettyOffenseId = `${basePath}-petty-offense`;

  return (
    <StyledContainer className='lookback-rules-table-headers'>
      <ExpandableGap />
      <Content>
        <TextGap />
        <Row>
          <Column data-testid={felonyId}>
            {translations.felony.toUpperCase()}
          </Column>
          <Column data-testid={misdemeanorId}>
            {translations.misdemeanor.toUpperCase()}
          </Column>
          {includeLessThanMisdemeanor && (
            <Column data-testid={pettyOffenseId}>
              {translations.petty_offense.toUpperCase()}
            </Column>
          )}
        </Row>
      </Content>
    </StyledContainer>
  );
};

export default Headers;
