import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from 'actions';
import { adjudicationClient } from 'modules/case-management/api/clients';
import { NOTES_PATH } from 'modules/case-management/api/paths';
import { useErrorNotifier } from 'hooks';

type NoteParam = {
  report_id: string;
  successCallback?: () => void;
};

export const useCreateNote = ({
  report_id,
  successCallback = () => {},
}: NoteParam): any => {
  const dispatch = useDispatch();
  const request = ({ content }: { content: string }) => {
    const params = {
      report_id,
      content,
    };
    return adjudicationClient.post(`${NOTES_PATH}`, params);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Note successfully created'));
      queryCache.invalidateQueries('cm/notes');
      successCallback();
    },
    onError: () => {
      dispatch(toastError('Unable to create a note at this time'));
    },
  });
  return { call, result };
};

export const useNotes = (params: NoteParam) => {
  const errorNotifier = useErrorNotifier();
  const key: AnyQueryKey = ['cm/notes', params];
  const request = () => adjudicationClient.get(NOTES_PATH, { params });

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: (err: any) => {
      // No toast because we won't render notes if 400
      errorNotifier(err, { ignoreCodes: 400 });
    },
  });
};

export const useUpdateNote = ({
  noteId,
  successCallback,
}: {
  noteId: string;
  successCallback: () => void;
}) => {
  const dispatch = useDispatch();
  const request = ({ content }: { content: string }) =>
    adjudicationClient.patch(`${NOTES_PATH}/${noteId}`, { content });

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Note successfully updated'));
      queryCache.invalidateQueries('cm/notes');
      successCallback();
    },
    onError: () => {
      dispatch(toastError('Unable to save note at this time'));
    },
  });
  return { call, result };
};

export const useDeleteNote = ({
  noteId,
  successCallback,
}: {
  noteId: string;
  successCallback: () => void;
}) => {
  const dispatch = useDispatch();
  const request = () => adjudicationClient.delete(`${NOTES_PATH}/${noteId}`);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Note successfully deleted'));
      queryCache.invalidateQueries('cm/notes');
      successCallback();
    },
    onError: () => {
      dispatch(toastError('Unable to delete note at this time'));
    },
  });
  return { call, result };
};
