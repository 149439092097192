import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { SelectItem } from './types';

type Props = {
  label: string;
  items: SelectItem[];
  onChange: (value: any) => void;
  selectedItem: SelectItem | undefined;
};

const SingleSelect: React.FC<Props> = ({
  label,
  items,
  onChange,
  selectedItem = undefined,
}) => {
  return (
    <M.Dropdown
      data-testid='assess-rules-common-singleselect'
      label={label}
      items={items}
      onChange={onChange}
      selectedItem={selectedItem}
    />
  );
};

export default SingleSelect;
