/* eslint-disable import/prefer-default-export */
import { createContext, MouseEventHandler } from 'react';
import { BetaContextType } from './types';

const defaultBetaStates = {};
const handler: MouseEventHandler = () => {};
const noop = () => handler;

export const BetaContext = createContext<BetaContextType>({
  betaStates: defaultBetaStates,
  showBeta: noop,
  hideBeta: noop,
});
