import { filter, omitBy, isNil } from 'lodash/fp';
import { NewCohortParams } from 'api/randomDrugTesting';
import {
  DOT_AGENCIES,
  DOT_ALCOHOL_SCREENING,
  DOT_DRUG_SCREENING,
  SELECTABLE_ALCOHOL_PACKAGE_SUBTYPE_NAME,
  SELECTABLE_DRUG_PACKAGE_SUBTYPE_NAME,
} from './Constants';
import {
  EditCohortFormFields,
  NewCohortFormFields,
  PackageSubtype,
  Service,
} from './types';

const filterDotDrugScreenings = (services: Service[]) => ({
  dotDrug: filter(({ name }) => name === DOT_DRUG_SCREENING, services),
  nonDotDrug: filter(({ name }) => name !== DOT_DRUG_SCREENING, services),
});

export const filterDrugScreenings = (data: PackageSubtype[]) => {
  if (!data?.length) return { dotDrug: [], nonDotDrug: [] };
  const services = filter(
    ({ name }: PackageSubtype) =>
      name.toLowerCase().includes(SELECTABLE_DRUG_PACKAGE_SUBTYPE_NAME),
    data,
  )
    .map(({ services }) => services)
    .flat();
  return filterDotDrugScreenings(services);
};

const filterDotAlcoholScreenings = (services: Service[]) => ({
  dotAlcohol: filter(({ name }) => name === DOT_ALCOHOL_SCREENING, services),
  nonDotAlcohol: filter(({ name }) => name !== DOT_ALCOHOL_SCREENING, services),
});

export const filterAlcoholScreenings = (data: PackageSubtype[]) => {
  if (!data?.length) return { dotAlcohol: [], nonDotAlcohol: [] };

  const { services } = filter(
    ({ name }: PackageSubtype) =>
      name.toLowerCase() ===
      SELECTABLE_ALCOHOL_PACKAGE_SUBTYPE_NAME.toLowerCase(),
    data,
  )[0];
  return filterDotAlcoholScreenings(services);
};

const removeNullValues = <T>(obj: Record<string, any>): T => {
  return omitBy(value => isNil(value) || value === '', obj) as T;
};

export const transformFormValuesForApi = (
  values: NewCohortFormFields | EditCohortFormFields,
) => {
  return removeNullValues<NewCohortParams>({
    id: values.id,
    name: values.cohortName,
    dot_compliant: values.isDOTCompliant === 'true',
    dot_compliance_agency: values.dotComplianceAgency,
    drug_screening: values.drugScreening,
    alcohol_screening: values.alcoholScreening,
    csv_file_bucket: values.fileStorageBucket,
    csv_file_path: values.fileStoragePath,
  });
};

export const transformInitialValuesForEditRender = (
  values: any,
): EditCohortFormFields => ({
  id: values.id,
  cohortName: values.name,
  isDOTCompliant: values.dotComplianceAgency ? 'true' : 'false',
  drugScreening: values.drugScreening,
  alcoholScreening: values.alcoholScreening,
  dotComplianceAgency:
    !!values.dotComplianceAgency &&
    Object.keys(DOT_AGENCIES).includes(values.dotComplianceAgency)
      ? values.dotComplianceAgency
      : '',
  fileStoragePath: values.csvFilePath,
  fileStorageBucket: values.csvFileBucket,
  fileDisplayName: values.fileDisplayName,
});
