import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import parseAddressToString from 'utils/BillingAddressUtils';
import { BillingEntity } from 'types/Billing';

type Props = {
  header: string;
  row: BillingEntity;
  cell: any;
};

const PaymentProfileTableCell: React.FC<Props> = ({ header, row, cell }) => {
  let cellValue;

  switch (header) {
    case 'address':
      cellValue = (
        <M.TableCell key={cell.id}>
          {parseAddressToString(cell.value) || ''}
        </M.TableCell>
      );
      break;
    case 'id':
      cellValue = (
        <M.TableCell key={cell.id}>
          {cell.value === 'account' ? null : cell.value}
        </M.TableCell>
      );
      break;
    default:
      cellValue = <M.TableCell key={cell.id}>{cell.value}</M.TableCell>;
  }
  return cellValue;
};

export default PaymentProfileTableCell;
