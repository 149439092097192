import React from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import * as Screens from './views';

type Props = {
  currentScreen: string;
  transitionToScreen: (screen: string) => void;
  onClose: () => void;
  nextScreen: () => void;
  onChange: React.Dispatch<React.SetStateAction<undefined>>;
  selection: undefined | GenericObject;
};

const Content: React.FC<Props> = ({
  currentScreen,
  selection,
  transitionToScreen,
  nextScreen,
  onChange,
  onClose,
}) => {
  const ComponentScreen = (Screens as GenericObject)[currentScreen];

  return (
    <ComponentScreen
      transitionToScreen={transitionToScreen}
      nextScreen={nextScreen}
      onChange={onChange}
      onClose={onClose}
      selection={selection}
    />
  );
};

export default Content;
