import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import TasksPage from 'modules/case-management/ui/TasksPage';
import Settings from 'modules/case-management/ui/Settings';

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { path: base } = match;
  const RootRoute = React.createContext<string>(base);

  const user = useUser();
  const canCMManageAll = user?.permissions?.case_management_manage_all;

  return (
    <RootRoute.Provider value={base}>
      <Switch>
        <Route exact path={`${base}`}>
          <Redirect to={`${base}/tasks`} />
        </Route>
        <Route path={`${base}/tasks`} component={TasksPage} />
        {canCMManageAll && (
          <Route path={`${base}/settings`} component={Settings} />
        )}
      </Switch>
    </RootRoute.Provider>
  );
};

export default Router;
