import React from 'react';
import { ContextContainer, SearchContainer } from 'containers';
import { useUser } from 'context/CurrentUser';
import { Provider } from '../components/Search';

const SearchPage = () => {
  const currentUser = useUser();
  return (
    <Provider currentUser={currentUser}>
      <SearchContainer
        data-floating-menu-container
        data-testid='search-container'
      />
    </Provider>
  );
};
export default ContextContainer(SearchPage);
