import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { cloneDeep } from 'lodash';
import { Field } from 'modules/assess/ui/rules/common/rule';
import { useTranslation } from 'react-i18next';
import SingleSelect from 'modules/assess/ui/rules/common/rule/editor/single-select';

type Props = {
  draftRule: any;
  setDraftRule: any;
  index: number;
};

const thresholdOperators = ['gt', 'ge'];

const CountField: React.FC<Props> = ({ draftRule, setDraftRule, index }) => {
  const { t } = useTranslation();
  const operatorTitle = t(
    'assess:ruleset.version.publish.changes.count.changes.operator',
  );
  const valueTitle = t(
    'assess:ruleset.version.publish.changes.count.changes.value',
  );
  const operatorLabel = t('selects.operator_placeholder');
  const valueLabel = t('inputs.number');

  const operators = thresholdOperators.map((value: string) => ({
    id: value,
    label: t(`assess:rules.facts.count.operators.${value}`),
  }));

  const handleOperatorChange = useCallback(
    ({ selectedItem: { id } }) => {
      setDraftRule((oldDraftRule: any) => {
        const newDraftRule = cloneDeep(oldDraftRule);
        newDraftRule.conditions[index].operator = id;
        return newDraftRule;
      });
    },
    [index, setDraftRule],
  );

  let selectedOperator;
  const data = draftRule.conditions[index].operator;
  if (thresholdOperators.includes(data)) {
    selectedOperator = {
      id: draftRule.conditions[index].operator,
      label: t(
        `assess:rules.facts.count.operators.${draftRule.conditions[index].operator}`,
      ),
    };
  }

  const handleValueChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      const parsedValue = value ? parseInt(value, 10) : undefined;
      setDraftRule((oldDraftRule: any) => {
        const newDraftRule = cloneDeep(oldDraftRule);
        newDraftRule.conditions[index].threshold = parsedValue;
        return newDraftRule;
      });
    },
    [index, setDraftRule],
  );

  const { threshold } = draftRule.conditions[index];

  return (
    <>
      <M.GridCol
        data-testid='assess-complex-editor-top-group-count-operator'
        lg={8}
        md={3}
        sm={1}
      >
        <Field label={operatorTitle} style={{ marginBottom: '0.75em' }} />
        <SingleSelect
          items={operators || []}
          label={operatorLabel}
          selectedItem={selectedOperator}
          onChange={handleOperatorChange}
        />
      </M.GridCol>
      <M.GridCol
        data-testid='assess-complex-editor-top-group-count-value'
        lg={8}
        md={3}
        sm={1}
      >
        <Field label={valueTitle} style={{ marginBottom: '0.75em' }} />
        <M.NumberInput
          data-testid='assess-complex-editor-top-group-count-value-input-numeric-value'
          allowEmpty
          defaultValue={threshold === undefined ? '' : threshold}
          max={5}
          min={0}
          placeholder={valueLabel}
          onChange={handleValueChange}
          hideSteppers
        />
      </M.GridCol>
    </>
  );
};

export default CountField;
