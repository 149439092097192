import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';

const ConfirmDangerModal = ({
  callback,
  hideModal,
  open,
  cancelBtnName,
  confirmBtnName,
  header,
  message,
  ...props
}) => {
  const messageContent =
    typeof message === 'string' ? <p className>{message}</p> : message;

  return (
    <M.ComposedModal onClose={hideModal} open={open} {...props}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>{header}</h2>
      </M.ModalHeader>
      <M.ModalBody>{messageContent}</M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{ name: cancelBtnName, onClick: hideModal }}
          btnRight={{
            name: confirmBtnName,
            onClick: callback,
            style: {
              backgroundColor: colors.uiOrange600,
              borderColor: colors.uiOrange600,
            },
          }}
          size='sm'
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

ConfirmDangerModal.propTypes = {
  hideModal: PropTypes.func,
  open: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  cancelBtnName: PropTypes.string,
  confirmBtnName: PropTypes.string,
  header: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ConfirmDangerModal.defaultProps = {
  hideModal: () => {},
  callback: () => {},
  cancelBtnName: 'Cancel',
  confirmBtnName: 'Yes',
  header: '',
  message: '' || {},
};

export default ConfirmDangerModal;
