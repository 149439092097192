import React, { useCallback, useState } from 'react';
import Dialog from '../dialog';
import Guard from '../guard';
import Box from './box';
import Content from './content';
import Header from './header';

export type Props = {
  onOpen?: () => void;
};

export const Container: React.FC<Props> = () => {
  const [open, show] = useState(false);

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  return (
    <Guard>
      <Box>
        <Header />
        <Content onOpen={handleClick} />
      </Box>
      <Dialog open={open} onClose={handleClose} />
    </Guard>
  );
};

export default Container;
