/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ActionableBanner } from 'components/Banner';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import { useTranslation } from 'react-i18next';
import { IMPERSONATING } from 'Constants';
import { endImpersonationAndRedirect, shouldUseImpersonationData } from 'utils';

const StyledBanner = styled(ActionableBanner)`
  #mastodon && {
    margin-top: 0;
    .cds--actionable-notification__title {
      cursor: pointer;
      .title-container {
        display: flex;
        .impersonator {
          flex: 1;
        }
      }
    }
    .cds--actionable-notification__details {
      margin: 0 2rem;

      .cds--actionable-notification__content {
        width: 100%;
      }
    }
    svg {
      display: none;
    }
  }
`;

const ImpersonatorBanner: React.FC<{}> = () => {
  const { isIframe } = useContext(UIContext);
  const user = useUser();
  const { t } = useTranslation();

  const key = 'banners.impersontor.title';

  return !isIframe && shouldUseImpersonationData() ? (
    <StyledBanner
      hideCloseButton
      kind='error'
      title={
        <div
          role='button'
          tabIndex={0}
          className='title-container'
          onClick={endImpersonationAndRedirect}
        >
          <div className='impersonator'>
            {t(key, { email: IMPERSONATING.impersonatorEmail })}
          </div>
          <div>{user.email}</div>
        </div>
      }
      data-testid='impersonator-banner'
    />
  ) : null;
};

export default ImpersonatorBanner;
