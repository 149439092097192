import React from 'react';
import { FieldComponentProps } from '../types';

const RulesetApplied: React.FC<FieldComponentProps> = ({ report, testid }) => {
  return (
    <div data-testid={testid}>
      {report?.assessment?.ruleset?.name} (Version{' '}
      {report?.assessment?.ruleset?.version?.number})
    </div>
  );
};

export default RulesetApplied;
