import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  localStorageFallback,
  getParamFromUrl,
  useRumError,
  CurrentUser,
  GenericObject,
} from '@dashboard-experience/utils';
import * as utils from 'components/AddScreenings/shared/utils';

import { Screening } from 'components/AddScreenings/types/Screenings';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import { defaultDrugPanels } from 'components/AddScreenings/Domestic/List/DrugAndHealthScreeningsItem';
import { findObjectByKey } from 'components/AddScreenings/shared/utils';
import { initialState, CreatePackageReducer } from './reducer';
import CreatePackageContext from './context';
import {
  setSelectedGeo,
  setBasePackage,
  addScreening,
  removeScreening,
  setPackageName,
  clearBasePackageAndAddons,
  setError,
  getPackageTypeFromParam,
  getBasePackage,
  getScreeningByTypeFromParam,
  skipBasePackageSelection,
  setInfoLinksClicked,
  DOMESTIC_GEO_PARAM,
  INTERNATIONAL_GEO_PARAM,
  DOMESTIC_GEO,
  INTERNATIONAL_GEO,
  CreatePackageContextType,
  getScreeningTypeFromParam,
} from './utils';

export const resetLocalStorageIfNeeded = (
  queryParamsPresent: boolean,
  searchCleaned: boolean,
  resetLocalStorage: () => void,
) => {
  if (queryParamsPresent && searchCleaned) {
    resetLocalStorage();
  }
};

export const handleGeoParam = (
  geoParam: string | null,
  addSelectedGeoToReducerState: (geo: string) => void,
) => {
  if (geoParam) {
    let geo = '';
    if (geoParam === DOMESTIC_GEO_PARAM) {
      geo = DOMESTIC_GEO;
    }
    if (geoParam === INTERNATIONAL_GEO_PARAM) {
      geo = INTERNATIONAL_GEO;
    }

    if (geo === DOMESTIC_GEO || geo === INTERNATIONAL_GEO) {
      addSelectedGeoToReducerState(geo);
    }
  }
};

export const handleBasePackageTypeParam = (
  basePackageTypeParam: string | null,
  geoParam: string | null,
  setPackageType: (packageType: string) => void,
  addBasePackageToReducerState: (packageType: string) => void,
) => {
  if (basePackageTypeParam) {
    const packageTypeFromUrl = getPackageTypeFromParam(
      basePackageTypeParam,
      geoParam as string,
    );
    setPackageType(packageTypeFromUrl);
    addBasePackageToReducerState(packageTypeFromUrl);
  }
};

export const handleAddOns = (
  addOnsArray: string[] | undefined,
  searchCleaned: boolean,
  basePackageScreeningTypes: string[],
  geoParam: string | null,
  autoAddOnsParam: boolean | string,
  account: any,
  setScreeningTypesInSearch: Function,
  addScreeningToReducerState: Function,
) => {
  if (addOnsArray?.length && addOnsArray.length > 0 && searchCleaned) {
    addOnsArray.forEach((addOnParam: string) => {
      let screening: Screening = {} as Screening;

      if (
        !basePackageScreeningTypes.includes(
          getScreeningTypeFromParam(addOnParam),
        )
      ) {
        if (addOnParam === 'ooccrim' || addOnParam === 'intcrim') {
          const crimScreeningObject = getScreeningByTypeFromParam(
            addOnParam,
            geoParam as string,
          ) as GenericObject;

          setScreeningTypesInSearch((prevScreeningTypesInSearch: any) =>
            Array.from(
              new Set([
                ...prevScreeningTypesInSearch,
                crimScreeningObject.screening_one.type,
                crimScreeningObject.screening_two.type,
              ]),
            ),
          );
        } else {
          setScreeningTypesInSearch((prevScreeningTypesInSearch: any) =>
            Array.from(
              new Set([
                ...prevScreeningTypesInSearch,
                getScreeningTypeFromParam(addOnParam),
              ]),
            ),
          );
        }
      }

      if (
        !(
          addOnParam === 'drug' ||
          addOnParam === 'ohs' ||
          (addOnParam === 'credit' &&
            account?.credit_report_setup !== 'enabled') ||
          (addOnParam === 'dotdrug' &&
            account?.drug_screening_setup !== 'enabled')
        )
      ) {
        if (addOnParam === 'ooccrim' || addOnParam === 'intcrim') {
          const crimScreeningObject = getScreeningByTypeFromParam(
            addOnParam,
            geoParam as string,
          ) as GenericObject;

          const { screening_one, screening_two } = crimScreeningObject;

          if (autoAddOnsParam) {
            if (screening_one.type) {
              addScreeningToReducerState(screening_one);
            }

            if (screening_two.type) {
              addScreeningToReducerState(screening_two);
            }
          }
        } else if (addOnParam === 'dotdrug') {
          screening = getScreeningByTypeFromParam(
            addOnParam,
            geoParam as string,
          ) as Screening;

          if (autoAddOnsParam && screening.type) {
            const { type } = screening;
            addScreeningToReducerState({
              type,
              additionalProperties: {
                selectedDrugScreen: findObjectByKey(
                  defaultDrugPanels,
                  'name',
                  'DS_7_PANEL',
                ),
                subtype: ['DS_7_PANEL'],
              },
            } as unknown as Screening);
          }
        } else {
          screening = getScreeningByTypeFromParam(
            addOnParam,
            geoParam as string,
          ) as Screening;
          if (autoAddOnsParam && screening.type) {
            const { type, ...additionalProperties } = screening;
            addScreeningToReducerState({
              type,
              additionalProperties,
            } as unknown as Screening);
          }
        }
      }
    });
  }
};

export const handlePackageNameParam = (
  packageNameParam: string | undefined,
  addPackageNameToReducerState: (packageName: string) => void,
) => {
  if (packageNameParam) {
    addPackageNameToReducerState(packageNameParam);
  }
};

type CreatePackageContextProviderProps = {
  currentUser: CurrentUser;
  children: React.ReactNode;
};

const CreatePackageContextProvider = ({
  currentUser,
  children,
}: CreatePackageContextProviderProps) => {
  const addDataDogError = useRumError();
  const localStorageState: any = localStorageFallback.getItem(
    'createPackageReducerState',
  );
  const history = useHistory();
  const { account } = currentUser;

  const [canCreateInternationalPackage, setCanCreateInternationalPackage] =
    useState(
      Boolean(
        currentUser.geos &&
          currentUser?.geos.find((geo: any) => geo.country !== null),
      ),
    );
  const [packageType, setPackageType] = useState('');
  const [queryParamsPresent, setQueryParamsPresent] = useState(false);
  const [search, setSearch] = useState(
    new URLSearchParams(history?.location.search),
  );
  const searchObject = Object.fromEntries(search);
  const [searchString, setSearchString] = useState(search.toString());
  const [searchCleaned, setSearchCleaned] = useState(false);
  const [screeningTypesInSearch, setScreeningTypesInSearch] = useState<
    string[]
  >([]);
  const [errorObjectStringified, setErrorObjectStringified] =
    useState<string>('');

  const [reducerState, dispatch] = useReducer(
    CreatePackageReducer,
    !queryParamsPresent && searchCleaned
      ? JSON.parse(localStorageState) || initialState
      : initialState,
  );

  const {
    addedScreeningTypes,
    additionalProperties,
    newPackageName,
    newPackageNameInvalid,
    basePackage,
  } = reducerState;

  const basePackageScreeningTypes = useMemo(() => {
    return basePackage?.screenings.map(
      (screening: { type: any }) => screening.type,
    );
  }, [basePackage]);

  useEffect(() => {
    setSearchString(search.toString());
  }, [search]);

  useEffect(() => {
    const hasInternationalGeos =
      currentUser.geos &&
      currentUser?.geos.find((geo: any) => geo.country !== null);
    setCanCreateInternationalPackage(Boolean(hasInternationalGeos));
  }, [currentUser]);

  const resetLocalStorage = useCallback(() => {
    localStorageFallback.removeItem('createPackageReducerState');
    localStorageFallback.removeItem('previousStep');
    localStorageFallback.removeItem('packageType');
    localStorageFallback.removeItem('selectedGeo');
  }, []);

  const addSelectedGeoToReducerState = useCallback(
    (geo: string) => {
      dispatch(setSelectedGeo(geo));
    },
    [dispatch],
  );

  const addBasePackageToReducerState = useCallback(
    (packageType: string) => {
      dispatch(setBasePackage(packageType));
    },
    [dispatch],
  );

  const addScreeningToReducerState = useCallback(
    (screening: Screening) => {
      dispatch(addScreening(screening));
    },
    [dispatch],
  );

  const removeScreeningFromReducerState = useCallback(
    (screeningType: string) => {
      dispatch(removeScreening(screeningType));
    },
    [dispatch],
  );

  const addPackageNameToReducerState = useCallback(
    (packageName: string) => {
      dispatch(setPackageName(packageName));
    },
    [dispatch],
  );

  const clearBasePackageAndAddonsFromReducerState = useCallback(() => {
    dispatch(clearBasePackageAndAddons(basePackage));
  }, [dispatch, basePackage]);

  const addErrorToReducerState = useCallback(
    (errorMessage: string) => {
      const errorForDataDog = new Error(errorMessage);

      addDataDogError(errorForDataDog, {});
      dispatch(setError(errorMessage));

      errorForDataDog.stack && setErrorObjectStringified(errorForDataDog.stack);
    },
    [addDataDogError],
  );

  const handleSkipBasePackageSelection = useCallback(() => {
    dispatch(skipBasePackageSelection());
  }, [dispatch]);

  const addInfoLinksClickedToReducerState = useCallback((infoLink: string) => {
    dispatch(setInfoLinksClicked(infoLink));
  }, []);

  const buildPostBodyWithAddOns = useCallback(
    ({ aliasEnabled = AliasesEnabledType.OFF }) => {
      const packageName = !newPackageNameInvalid
        ? newPackageName
        : basePackage.name;

      return utils.buildPostBodyWithAddOns({
        basePackage: { ...basePackage, aliases_enabled: aliasEnabled },
        addedScreeningTypes,
        additionalProperties,
        packageName,
        setSlug: true,
        isPrivate: false,
      });
    },
    [
      addedScreeningTypes,
      additionalProperties,
      basePackage,
      newPackageName,
      newPackageNameInvalid,
    ],
  );

  const handleQueryParams = useCallback(() => {
    const geoParam = getParamFromUrl(window, 'geo');
    const basePackageTypeParam = getParamFromUrl(window, 'base');
    const addOnsParam = getParamFromUrl(window, 'add');
    const addOnsArray = addOnsParam?.split(',');
    const packageNameParam = search.get('name')?.replaceAll('_', ' ');
    const autoAddOnsParam = search.get('auto-add') || false;

    resetLocalStorageIfNeeded(
      queryParamsPresent,
      searchCleaned,
      resetLocalStorage,
    );
    handleGeoParam(geoParam, addSelectedGeoToReducerState);
    handleBasePackageTypeParam(
      basePackageTypeParam,
      geoParam,
      setPackageType,
      addBasePackageToReducerState,
    );
    handleAddOns(
      addOnsArray,
      searchCleaned,
      basePackageScreeningTypes,
      geoParam,
      autoAddOnsParam,
      account,
      setScreeningTypesInSearch,
      addScreeningToReducerState,
    );
    handlePackageNameParam(packageNameParam, addPackageNameToReducerState);
  }, [
    searchCleaned,
    addBasePackageToReducerState,
    addScreeningToReducerState,
    addSelectedGeoToReducerState,
    addPackageNameToReducerState,
    queryParamsPresent,
    resetLocalStorage,
    search,
    basePackageScreeningTypes,
    account,
  ]);

  const validateAndCleanUpSearch = useCallback(
    (search: URLSearchParams) => {
      search.delete('context');
      search.delete('contextId');
      search.delete('internalDashboard');
      search.delete('allowInternal');

      setSearch(() => search);
      setSearchCleaned(true);

      const geoParam = search.get('geo');
      const basePackageParam = search.get('base');
      const addOnsParam = getParamFromUrl(window, 'add');

      const autoAddOnsArray = addOnsParam?.split(',');

      const geoParamValid =
        geoParam === 'dom' ||
        (geoParam === 'int' && canCreateInternationalPackage);

      const basePackageParamValueValid =
        getPackageTypeFromParam(
          basePackageParam as string,
          geoParam as string,
        ) !== 'invalid';

      const addOnParamValuesValid =
        autoAddOnsArray && autoAddOnsArray?.length > 0
          ? autoAddOnsArray
              ?.map((addOn: string) => {
                return getScreeningByTypeFromParam(addOn, geoParam as string);
              })
              .every((entry: Screening | string) => entry !== 'invalid')
          : true;

      const tempSearch = new URLSearchParams(search.toString());
      tempSearch.delete('geo');
      tempSearch.delete('base');
      tempSearch.delete('add');
      tempSearch.delete('name');
      tempSearch.delete('auto-add');

      const paramsValid =
        geoParamValid && basePackageParamValueValid && addOnParamValuesValid;

      const requiredParamsPresent = geoParam && basePackageParam !== 'invalid';

      if (requiredParamsPresent && paramsValid) {
        setQueryParamsPresent(true);
        handleQueryParams();
      }
    },
    [handleQueryParams, canCreateInternationalPackage],
  );

  const value: CreatePackageContextType = useMemo(
    () => ({
      reducerState,
      search: {
        search,
        searchObject,
        searchString,
        searchCleaned,
        queryParamsPresent,
        screeningTypesInSearch,
      },
      basePackageScreeningTypes,
      canCreateInternationalPackage,
      packageType,
      errorObjectStringified,
      currentUser,
      account,
      functions: {
        buildPostBodyWithAddOns,
        addSelectedGeoToReducerState,
        addBasePackageToReducerState,
        addScreeningToReducerState,
        removeScreeningFromReducerState,
        addPackageNameToReducerState,
        clearBasePackageAndAddonsFromReducerState,
        addErrorToReducerState,
        handleSkipBasePackageSelection,
        setPackageType,
        getBasePackage,
        validateAndCleanUpSearch,
        handleQueryParams,
        resetLocalStorage,
        dispatch,
        addDataDogError,
        addInfoLinksClickedToReducerState,
        setScreeningTypesInSearch,
      },
    }),
    [
      reducerState,
      buildPostBodyWithAddOns,
      currentUser,
      account,
      canCreateInternationalPackage,
      addSelectedGeoToReducerState,
      addBasePackageToReducerState,
      addScreeningToReducerState,
      removeScreeningFromReducerState,
      addPackageNameToReducerState,
      clearBasePackageAndAddonsFromReducerState,
      addErrorToReducerState,
      handleSkipBasePackageSelection,
      packageType,
      setPackageType,
      queryParamsPresent,
      search,
      searchObject,
      searchString,
      validateAndCleanUpSearch,
      searchCleaned,
      handleQueryParams,
      resetLocalStorage,
      dispatch,
      errorObjectStringified,
      addDataDogError,
      addInfoLinksClickedToReducerState,
      screeningTypesInSearch,
      setScreeningTypesInSearch,
      basePackageScreeningTypes,
    ],
  );

  return (
    <CreatePackageContext.Provider value={value}>
      {children}
    </CreatePackageContext.Provider>
  );
};

export default CreatePackageContextProvider;
