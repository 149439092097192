import React from 'react';
import { SCREENING_TITLES } from 'utils';
import { StatusTypes, getScreeningStatus } from '@dashboard-experience/utils';
import { useHealthScreening } from './health-context';
import DrugHealthScreening from '../DrugHealthScreening';
import { Screenings } from '../types';
import Table from '../Table';

type Props = {
  reportStatusType: string;
  screening: { [x: string]: any };
  canceledStatusEnabled: boolean | null | undefined;
};

type OccHealthCategory = {
  name: string;
  tests: any[];
};

const headers = [
  { key: 'name', header: 'Test', props: { style: { width: '18%' } } },
  { key: 'results', header: 'Result', props: { style: { width: '24%' } } },
  { key: 'uploaded_docs', header: '', props: { style: { width: '25%' } } },
  { key: 'upload_docs', header: '', props: { style: { width: '18%' } } },
  { key: 'status', header: '', props: { style: { width: '15%' } } },
];

const OccHealthScreening: React.FC<Props> = ({
  reportStatusType,
  screening,
  canceledStatusEnabled,
}) => {
  const statusOrAssessment = getScreeningStatus(screening, reportStatusType);
  const rows = useHealthScreening('rows');
  return rows?.length > 0 ? (
    <DrugHealthScreening
      screening={screening}
      title='Health Screening'
      disposition='Disposition'
      reportStatusType={reportStatusType}
      timelineStatuses={screening.events}
      timelineKey='type'
      screeningTestId={SCREENING_TITLES.occupational_health_screening.id}
    >
      {rows.map((row: OccHealthCategory) => {
        const { name, tests } = row;
        return (
          <div key={name}>
            <strong data-testid='screenings-drug-health-category-name'>
              {name}
            </strong>
            <Table
              rows={tests}
              headers={headers}
              type={Screenings.Health}
              assessEnabled={reportStatusType === StatusTypes.Assess}
              canceledStatusEnabled={canceledStatusEnabled}
              statusOrAssessment={statusOrAssessment}
              tableProps={{ style: { width: '100%' } }}
            />
          </div>
        );
      })}
    </DrugHealthScreening>
  ) : null;
};

export default OccHealthScreening;
