/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import { clearUserCache, toastError, toastSuccess } from 'actions';
import { updatePreferences } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const useUpdatePreferences = () => {
  const dispatch = useDispatch();
  const request = ({ updatedPreferences, user }: any) =>
    updatePreferences(updatedPreferences, user);

  const [call, result] = useMutation(request, {
    onError: (err: any) => {
      dispatch(toastError('Error updating preferences'));
    },
    onSuccess: (res: any) => {
      dispatch(toastSuccess('Preferences updated'));
      dispatch(clearUserCache());
    },
  });
  return {
    call,
    result,
  };
};
