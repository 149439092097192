import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { addressObjToString } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AdverseAction, CandidatePostalAddress } from 'types';
import { useCandidate } from 'providers/Candidate';
import { useUpdateCandidatePostalAddress } from 'api/candidate';
import PostalAddressForm from 'components/PostalAddress/PostalAddressForm';
import ResolveUndeliverableAdverseModal from './ResolveUndeliverableAdverseModal';

const EntrySection = styled.div`
  #postal-section {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .postal-input {
      margin-top: 1rem;
      width: 75%;

      label {
        font-size: 14px;
      }
    }

    .mastodon-button.primary {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
      align-self: flex-end;
    }
  }
`;

const ErrorBox = styled.div`
  width: 100%;
  height: 14px;
`;

const ConfirmSection = styled.div`
  #addresses {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  b {
    font-weight: bold;
  }
  ol {
    padding: revert;
    list-style: auto;
    padding-top: 0.5rem;
  }
`;

export type Props = {
  address: CandidatePostalAddress;
  handleAddress: (address: CandidatePostalAddress) => void;
  isValid: boolean;
  goToConfirmPage: () => void;
  t: (key: string) => string;
  newAddress: CandidatePostalAddress;
  submitAddress: () => void;
  goToEntry: () => void;
  showModal: boolean;
  onClose: () => void;
  adverseAction: AdverseAction;
};

type EntryPageProps = Pick<
  Props,
  'address' | 'handleAddress' | 'isValid' | 'goToConfirmPage'
>;

type EntryFooterProps = Pick<Props, 'isValid' | 't'>;

type ConfirmPageProps = Pick<Props, 'newAddress' | 't'>;

type ConfirmFooterProps = Pick<Props, 'submitAddress' | 'goToEntry'>;

type ModalProps = Pick<Props, 'showModal' | 'onClose' | 'adverseAction'>;

// First page of the modal - prompts user to input a postal address
const EntryPage: React.FC<EntryPageProps> = ({
  address,
  handleAddress,
  isValid,
  goToConfirmPage,
}) => {
  const { t } = useTranslation();
  return (
    <EntrySection>
      <p>{t('resolve_undeliverable_aa.postal.bad_address')}</p>
      <div id='postal-section'>
        <span className='postal-input'>
          <PostalAddressForm handleAddress={handleAddress} address={address} />
        </span>
        <M.Button onClick={goToConfirmPage} disabled={!isValid}>
          {t('buttons.continue')}
        </M.Button>
      </div>
    </EntrySection>
  );
};

const EntryFooter: React.FC<EntryFooterProps> = ({ isValid, t }) => {
  // ErrorBox ensures that a consistent height is applied, and then it contains a message if applicable
  return <ErrorBox>{!isValid && t('postal.invalid_entry')}</ErrorBox>;
};

// Second page of the modal - asks user to confirm the new candidate's address
const ConfirmPage: React.FC<ConfirmPageProps> = ({ newAddress, t }) => {
  const addressString = addressObjToString(newAddress);
  return (
    <ConfirmSection>
      <p>
        {t('postal.confirm_entry')}
        <span id='addresses'>
          <b>{addressString}</b>
        </span>
      </p>

      <p>{t('postal.explanation.resolve')}</p>
      <ol>
        <li>{t('postal.explanation.resolve_notice')}</li>
        <li>
          {t('postal.explanation.resend')}
          <b>{addressString}</b>
        </li>
      </ol>
    </ConfirmSection>
  );
};

const ConfirmFooter: React.FC<ConfirmFooterProps> = ({
  submitAddress,
  goToEntry,
}) => {
  const { t } = useTranslation();
  return (
    <M.BinaryButtons
      btnLeft={{
        kind: 'secondary',
        name: t('buttons.back'),
        onClick: goToEntry,
      }}
      btnRight={{
        kind: 'primary',
        name: t('buttons.confirm'),
        onClick: submitAddress,
      }}
    />
  );
};

const ResolveUndeliverableWithPostal: React.FC<ModalProps> = ({
  showModal,
  onClose,
  adverseAction,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'resolve_undeliverable_aa' });

  const candidate = useCandidate();
  const { id, first_name, last_name, middle_name } = candidate;
  const name = `${first_name || ''}${middle_name ? ` ${middle_name}` : ''}${
    last_name ? ` ${last_name}` : ''
  }`;

  const [address, setAddress] = useState<CandidatePostalAddress>({
    name,
    street: '',
    city: '',
    state: '',
    zipcode: '',
  });

  const handleAddress = useCallback(
    (newAddress: CandidatePostalAddress) => {
      newAddress.name = name;
      setAddress(newAddress);
    },
    [name],
  );

  const newAdressIsValid: boolean =
    !!address.street && !!address.city && !!address.state && !!address.zipcode;

  const {
    updateCandidatePostalAddressCall,
    updateCandidatePostalAddressResult,
  } = useUpdateCandidatePostalAddress(id, address);

  // This modal contains 2 pages, keep track of the current one here
  const [onEntryPage, setOnEntryPage] = useState(true);
  const goToConfirmPage = useCallback(() => setOnEntryPage(false), []);
  const goToEntryPage = useCallback(() => setOnEntryPage(true), []);

  const submitAddress = useCallback(() => {
    updateCandidatePostalAddressCall();
  }, [updateCandidatePostalAddressCall]);

  useEffect(() => {
    if (
      updateCandidatePostalAddressResult.isSuccess ||
      updateCandidatePostalAddressResult.isError
    ) {
      if (updateCandidatePostalAddressResult.isSuccess) {
        onClose();
      }
      updateCandidatePostalAddressResult.reset();
    }
  }, [onClose, updateCandidatePostalAddressResult]);

  return (
    <ResolveUndeliverableAdverseModal
      showModal={showModal}
      onClose={onClose}
      reason={adverseAction.aa_delivery_state_reason}
      footer={
        onEntryPage ? (
          <EntryFooter isValid={newAdressIsValid} t={t} />
        ) : (
          <ConfirmFooter
            submitAddress={submitAddress}
            goToEntry={goToEntryPage}
          />
        )
      }
    >
      {onEntryPage ? (
        <EntryPage
          address={address}
          isValid={newAdressIsValid}
          handleAddress={handleAddress}
          goToConfirmPage={goToConfirmPage}
        />
      ) : (
        <ConfirmPage newAddress={address} t={t} />
      )}
    </ResolveUndeliverableAdverseModal>
  );
};

export default ResolveUndeliverableWithPostal;
