import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Assess } from 'components';
import { RootRoute } from 'modules/assess/ui/router/context';
import Criminal from './criminal';
import Home from './home';

const Router: React.FC = () => {
  const match = useRouteMatch();
  const { path: base } = match;

  return (
    <RootRoute.Provider value={base}>
      <Switch>
        <Route path={`${base}/criminal`}>
          <Criminal />
        </Route>
        <Route>
          <Assess.Route.Breadcrumb location='assess' title='Assess'>
            <Home />
          </Assess.Route.Breadcrumb>
        </Route>
      </Switch>
    </RootRoute.Provider>
  );
};

export default Router;
