import React, { useRef, useContext, useEffect } from 'react';
import { useCandidate } from 'providers/Candidate';
import { useLocation } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ORDER_ADDONS_FLOW } from 'Flags';
import UIContext from 'context/UI';
import { scrollToElement } from 'utils';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';

import NewReportContainer from './NewReportContainer';

const OrderNewReportWrapper: React.FC = () => {
  const orderAddOnsFlowEnabled =
    useFlag(ORDER_ADDONS_FLOW)?.variantKey === 'enabled';
  const candidate = useCandidate();
  const location = useLocation();
  const orderSectionRef = useRef<HTMLElement | null>(null);
  const user = useUser();
  const { isIframe, isStandaloneIframe } = useContext(UIContext);

  // non-stand-alone-iframe shouldn't show it in every iframe, only the one with the /new route
  const isMultiPartIframe = isIframe && !isStandaloneIframe;

  // TODO - Should this be using the candidate's account info to determine permissions? (Migrated 1:1)
  const canOrderNewReport =
    hasPermission(user, 'create_manual_orders') &&
    accountHasPermission(user, 'allow_dashboard_report_ordering');

  const isOrderNewReportPath =
    location.pathname === `/candidates/${candidate.id}/new`;
  const hasReports = !!candidate.last_report_id;

  const showOrderNewReport =
    !orderAddOnsFlowEnabled &&
    canOrderNewReport &&
    (isOrderNewReportPath || (!hasReports && !isMultiPartIframe));

  useEffect(() => {
    if (showOrderNewReport) {
      if (orderSectionRef?.current) {
        const orderSectionRefOffset = orderSectionRef.current.offsetTop;

        window.scrollTo({
          top: orderSectionRefOffset,
          behavior: 'smooth',
        });

        scrollToElement(orderSectionRefOffset);
      }
    }
  }, [showOrderNewReport]);

  return showOrderNewReport ? (
    <section ref={orderSectionRef}>
      <NewReportContainer />
    </section>
  ) : null;
};

export default OrderNewReportWrapper;
