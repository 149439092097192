import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus } from 'react-query';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Type } from 'modules/assess/models/settings/account';
import * as Entity from 'modules/assess/models/settings/account';
import { useUpdate as useSettingsUpdate } from 'modules/assess/ui/settings/api/hooks';
import RemoveEligibleRecordsDialogConfirmation from 'modules/assess/ui/settings/confirmation-dialog';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import Decision from 'modules/assess/v2/models/decision';
import { useStateWithProp, useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { scrollToTop } from 'utils';
import Actions from './Actions';
import Content from './Content';
import { getSettingsOption } from './eligible-records/Container';
import SavedNotification from './SavedNotification';

type Props = {
  data: Type | undefined;
  status: QueryStatus;
};

const Title = styled.h1`
  color: ${colors.brandNavy4};
  font-weight: bold;
  margin-bottom: 1em;
`;

const Container: React.FC<Props> = ({ data, status }) => {
  const { t } = useTranslation('assess:v2');
  const text = t('home.settings.title');

  const action = useSettingsUpdate();
  const track = useTrackEvent();
  const [settings, setSettings] = useState(data || Entity.initialize());
  const persisted = isEqual(data, settings);
  const [disabled, setDisabled] = useStateWithProp<boolean>(persisted);
  const [showToast, setShowToast] = useState(false);

  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const remote = data as Entity.Type;

  const reset = useCallback(() => {
    if (data && status === 'success') {
      setSettings(data);
    }
  }, [data, status]);

  const trackSettingsSaved = useCallback(() => {
    const localValue = getSettingsOption(settings);
    const remoteValue = getSettingsOption(remote);
    const {
      Events,
      BOOLEAN_SETTINGS,
      BOOLEAN_SETTINGS_EVENT_MAP,
      CLEAR_RECORDS_PROPERTY_MAP,
    } = Amplitude.Home;
    if (localValue !== remoteValue) {
      const event = Events.DisplayClearRecords;
      const property = CLEAR_RECORDS_PROPERTY_MAP[localValue];
      track(event, {
        [Events.DisplayClearRecordsSettings]: property,
      });
    }
    BOOLEAN_SETTINGS.forEach(setting => {
      if (settings[setting] !== remote[setting]) {
        const eventName = BOOLEAN_SETTINGS_EVENT_MAP[setting] as Amplitude.Type;
        track(eventName);
      }
    });
  }, [settings, remote, track]);

  useEffect(() => {
    if (action.result.isSuccess) {
      trackSettingsSaved();
      action.result.reset();
      setDisabled(true);
      setShowToast(true);
      scrollToTop();
    }
  }, [action, setDisabled, setShowToast, trackSettingsSaved]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onSave = useCallback(() => {
    if (needsConfirmation) {
      setShowRemoveEligibleRecordsDialogConfirmation(true);
      return;
    }

    action.call(settings);
  }, [action, needsConfirmation, settings]);

  const [
    showRemoveEligibleRecordsDialogConfirmation,
    setShowRemoveEligibleRecordsDialogConfirmation,
  ] = useState(false);

  const closeRemoveEligibleRecordsDialogConfirmation = useCallback(() => {
    setShowRemoveEligibleRecordsDialogConfirmation(false);
  }, []);

  const handleConfirmRemoveEligibleRecordsOption = useCallback(() => {
    setNeedsConfirmation(false);
    setShowRemoveEligibleRecordsDialogConfirmation(false);
    setConfirmed(true);
  }, [setNeedsConfirmation, setShowRemoveEligibleRecordsDialogConfirmation]);

  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      action.call(settings);
    }
  }, [action, confirmed, setConfirmed, settings]);

  const basePath = 'assess-v2-ui-home-settings-container';
  const titleId = `${basePath}-title`;
  const containerId = `${basePath}-container`;

  return (
    <>
      <Title id={titleId} data-testid='assess-v2-settings-title'>
        {text}
      </Title>
      <M.Container
        type='default'
        data-testid='assess-v2-settings-container'
        id={containerId}
      >
        <Content
          locals={settings}
          remote={remote}
          setNeedsConfirmation={setNeedsConfirmation}
          setSettings={setSettings}
        />
        <Actions disabled={disabled} onSave={onSave} />
        <RemoveEligibleRecordsDialogConfirmation
          decision={Decision.Clear}
          open={showRemoveEligibleRecordsDialogConfirmation}
          onClose={closeRemoveEligibleRecordsDialogConfirmation}
          onConfirm={handleConfirmRemoveEligibleRecordsOption}
        />
        <SavedNotification setShow={setShowToast} show={showToast} />
      </M.Container>
    </>
  );
};

export default Container;
