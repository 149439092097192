import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Indicator = styled(M.LoadingSpinner)`
  z-index: 10000;
`;

type Props = {
  enabled?: boolean;
};

export const PageLoading: React.FC<Props> = ({ enabled }) => {
  return enabled ? <Indicator /> : null;
};

export default PageLoading;
