import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { Report } from 'types';
import styled from 'styled-components';
import ReportBadge from './ReportBadge';

const TabsDropdown = styled(M.Dropdown)`
  #mastodon & {
    .cds--list-box__field {
      padding-left: 0.75rem;

      .cds--list-box__label {
        padding-left: 0.25rem;
      }
    }

    .cds--list-box__menu {
      text-align: left;

      .cds--list-box__menu-item__option {
        margin: 0;
        padding-left: 1rem;

        & > div {
          justify-content: left;
        }
      }

      .cds--list-box__menu-item[disabled] {
        .cds--list-box__menu-item__option {
          border-bottom: 1px solid ${colors.brandSlate3};
          opacity: 1;

          & > div > span:first-child {
            display: none;
          }

          .mastodon-status-badge {
            color: ${colors.brandAqua4};
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
      }
    }
  }
`;

// @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
const itemToElement = (report: Report) => <ReportBadge report={report} />;

const ReportDropdown: React.FC<{
  reports: Report[];
  selectedReport?: Report;
  updateUrl: Function;
}> = ({ reports, selectedReport, updateUrl }) => {
  // For dropdown change
  const onChange = useCallback(
    ({ selectedItem }) => {
      return selectedItem && updateUrl(selectedItem.id, selectedItem.object);
    },
    [updateUrl],
  );

  return (
    <TabsDropdown
      itemToString={itemToElement}
      items={reports}
      selectedItem={selectedReport}
      onChange={onChange}
    />
  );
};

export default ReportDropdown;
