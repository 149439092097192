import React, { useCallback } from 'react';
import * as Entity from 'modules/assess/models/settings/account';
import * as Geo from 'modules/assess/models/settings/geo';
import { Settings } from 'modules/assess/utilities';
import styled from 'styled-components';
import SelectGeos from './select-geos';
import SelectedGeos from './selected-geos';
import SubSettingToggle from '../sub-setting-toggle';

type Props = {
  disabled?: boolean;
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.5rem;
`;

export const SelectGeosSetting: React.FC<Props> = ({
  disabled,
  settings,
  setSettings,
}) => {
  const disabledButton =
    disabled || settings?.adverse_action_automation?.value?.geos === null;
  const settingGeos = settings?.geos;
  const selections = Settings.getAdverseActionAutomationGeos(settingGeos);

  const setGeos = useCallback(
    (geos: Geo.List) => {
      setSettings(state => {
        return { ...state, geos };
      });
    },
    [setSettings],
  );

  return (
    <Flex>
      <SubSettingToggle
        disabled={disabled}
        name='geos'
        settings={settings}
        setSettings={setSettings}
      />
      <SelectedGeos selections={selections} />
      <SelectGeos
        disabled={disabledButton}
        geos={settingGeos}
        setGeos={setGeos}
      />
    </Flex>
  );
};

export default SelectGeosSetting;
