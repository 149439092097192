import React, { FC, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { map } from 'lodash/fp';
import { EditCohortFormFields, NewCohortFormFields } from './types';
import { FormInputContainer, Label } from './styles';
import { FORM_FIELD_LABELS, DOT_AGENCIES } from './Constants';

interface Props {
  values: Partial<NewCohortFormFields | EditCohortFormFields>;
  showError: (field: string) => string | null;
  handleChange: any;
}
const DetailsSection: FC<Props> = ({ values, handleChange, showError }) => {
  const handleDOTChange = useCallback(
    (value: string) => {
      handleChange('isDOTCompliant')(value);
      if (value === 'false') handleChange('dotComplianceAgency')('');
    },
    [handleChange],
  );

  const handleDOTAgencyChange = useCallback(
    (value: string) => {
      handleChange('dotComplianceAgency')(value);
    },
    [handleChange],
  );

  return (
    <FormInputContainer data-testid='details-setion'>
      <FormInputContainer>
        <Label>{FORM_FIELD_LABELS.cohortName}</Label>
        <M.Input
          id='cohortName'
          name='cohortName'
          placeholder='Pool name'
          type='text'
          invalid={showError('cohortName')}
          invalidText={showError('cohortName')}
          onChange={handleChange}
          value={values.cohortName}
          labelText=''
        />
      </FormInputContainer>
      <FormInputContainer>
        <Label>{FORM_FIELD_LABELS.isDOTCompliant}</Label>
        <M.RadioButtonGroup
          id='isDOTCompliant'
          name='isDOTCompliant'
          onChange={handleDOTChange}
          orientation='vertical'
          valueSelected={values.isDOTCompliant}
        >
          <M.RadioButton id='isDOTCompliantTrue' labelText='Yes' value='true' />
          <M.RadioButton
            id='isDOTCompliantFalse'
            labelText='No'
            value='false'
          />
        </M.RadioButtonGroup>
      </FormInputContainer>
      {values.isDOTCompliant === 'true' && (
        <FormInputContainer>
          <Label>{FORM_FIELD_LABELS.dotComplianceAgency}</Label>
          <M.RadioButtonGroup
            id='dotComplianceAgency'
            name='dotComplianceAgency'
            onChange={handleDOTAgencyChange}
            orientation='vertical'
            valueSelected={values.dotComplianceAgency}
          >
            {map(
              (key: string) => (
                <M.RadioButton
                  key={key}
                  id={key}
                  labelText={DOT_AGENCIES[key]}
                  value={key}
                />
              ),
              Object.keys(DOT_AGENCIES),
            )}
          </M.RadioButtonGroup>
        </FormInputContainer>
      )}
    </FormInputContainer>
  );
};

export default DetailsSection;
