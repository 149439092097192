import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useOrderBackgroundCheckContext, actionTypes } from '../../Context';
import { Dropdown, Item } from '../../SharedItems';

const defaultItemToString = (item: Item) => item?.name;

const ProgramDropdown = styled(Dropdown)`
  margin-right: 20px;
`;

type SelectProgramProps = {
  selectablePrograms: any;
};

const SelectProgram: React.FC<SelectProgramProps> = ({
  selectablePrograms,
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();

  const handleProgramChange = useCallback(
    ({ selectedItem }) => {
      dispatch({
        type: actionTypes.ADD_PROGRAM,
        payload: { program: selectedItem },
      });
    },
    [dispatch],
  );
  return (
    <>
      {selectablePrograms.length > 0 && (
        <ProgramDropdown
          data-testid='program-dropdown-testid'
          label='Programs'
          invalidText='Program not found'
          items={selectablePrograms}
          selectedItem={
            Object.keys(state.program).length !== 0 ? state.program : null
          }
          onChange={handleProgramChange}
          itemToString={defaultItemToString}
        />
      )}
    </>
  );
};

export default SelectProgram;
