import * as Yup from 'yup';
import ValidationErrors from './types';

export const EmployeeValidations = (isEmailRegistered: any, checkEmail: any) =>
  Yup.object().shape({
    employees: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(ValidationErrors.REQUIRED),
        middleName: Yup.string().notRequired(),
        lastName: Yup.string().required(ValidationErrors.REQUIRED),
        email: Yup.string()
          .required(ValidationErrors.INVALID_EMAIL)
          .email('Invalid email address')
          .test('is-email-registered', function check(value) {
            return isEmailRegistered(value)
              .then((res: boolean) => {
                if (res) {
                  return this.createError({
                    message: 'This email has already been added.',
                  });
                }
                return Promise.resolve(true);
              })
              .catch(() => {
                return this.createError({
                  message: 'Server error was happened while checking email',
                });
              });
          })
          .test('duplicated-email', function checkDuplicated(value) {
            if (checkEmail(value)) {
              return this.createError({
                message: 'This email has already been added.',
              });
            }
            return Promise.resolve(true);
          }),
      }),
    ),
  });

export default EmployeeValidations;
