import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'components/Button';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 10px;
`;

type HeaderProps = {
  disableSave: boolean;
  onSave: () => void;
};

export const SettingsHeader: React.FC<HeaderProps> = ({
  disableSave,
  onSave,
}) => {
  const { t } = useTranslation();
  const description = t('case-management:settings.description');

  return (
    <StyledHeader data-testid='case-management-settings-header'>
      <p>{description}</p>
      <Button
        data-testid='case-management-settings-save-button'
        disabled={disableSave}
        kind='primary'
        onClick={onSave}
        trans='verbs.save'
      />
    </StyledHeader>
  );
};

export default SettingsHeader;
