import React from 'react';
import { Namespace } from 'modules/assess/api';
import { Type } from 'modules/assess/models/rulesets/ruleset';
import Records from './records';
import Title from './Title';

type Props = {
  data: Type | undefined;
};

const Container: React.FC<Props> = ({ data }) => {
  const namespace = Namespace.criminal;

  return (
    <>
      <Title namespace={namespace} />
      <Records namespace={namespace} ruleset={data} />
    </>
  );
};

export default Container;
