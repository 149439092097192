/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { STRIPE_KEY } from 'Constants';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  FOX_SIGNUP_EVENT_NAMES,
  SIGNUP_EVENT_NAMES,
  scrollToElement,
  useTrackEvent,
} from 'utils';

import { Modal, Footer } from './ui/Modal';
import { CreateAccountSection } from './ui/CreateAccount';
import { Container as ModalContainer } from './styles';
import Error from './ui/Error';
import useCreatePaymentIntent from './hooks/useCreatePaymentIntent';
import Accordion from './ui/Accordion';
import usePostPaymentMethod from './hooks/usePostPaymentMethod';
import {
  Container,
  Section,
  FlexColumn,
  FormSection,
  SectionDescription,
} from './ui/CreateAccount/PaymentDetailsSection/styles';

type Props = {
  error: any;
  setError: (error: any) => void;
};

const PaymentForm = ({ error, setError }: Props) => {
  const { t } = useTranslation('signup');
  const modalRef = useRef(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const trackEvent = useTrackEvent();
  const { mutate: postPaymentMethodCall } = usePostPaymentMethod(() => {
    setStripeLoading(false);
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PAYMENT_INFORMATION_COMPLETED, {
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    history.push({
      pathname: '/signup/packages',
      search: location.search,
    });
  });
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_CREATE_PAYMENT_INFORMATION_VIEWED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  useEffect(() => {
    const cardElement = elements?.getElement('payment');
    if (cardElement) {
      const handleChange = (event: any) => {
        if (event.complete) {
          setIsFormFilled(true);
          return;
        }
        setIsFormFilled(false);
      };

      cardElement.on('change', handleChange);

      return () => {
        cardElement.off('change', handleChange);
      };
    }
    return () => {};
  }, [elements]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setStripeLoading(true);

      trackEvent(
        SIGNUP_EVENT_NAMES.SIGNUP_PAYMENT_INFORMATION_NEXT_PAGE_CLICKED,
        {
          'Self Serve Signup Version': 'FOX Signup v2',
        },
      );

      if (!stripe || !elements) {
        setStripeLoading(false);
        return;
      }

      stripe
        .confirmSetup({
          elements,
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setError({
              error: result.error,
              source: 'setup-intent-unexpected-state',
            });
            setStripeLoading(false);
            return;
          }
          const stripeId: any = result.setupIntent?.payment_method!;
          postPaymentMethodCall(
            { stripeId },
            {
              onError: (error: unknown) => {
                setError({ error, source: 'payment' });
                setStripeLoading(false);
                scrollToElement(Error);
              },
            },
          );
        })
        .catch(error => {
          setError({ error, source: 'payment' });
          setStripeLoading(false);
        });
    },
    [stripe, elements, postPaymentMethodCall, setError, trackEvent],
  );

  return (
    <Modal
      footer={
        <Footer
          primaryBtnText={t(`footer.complete`)}
          primaryButtonProps={{
            onClick: handleSubmit,
            disabled: stripeLoading || !isFormFilled,
          }}
        />
      }
      stepNumber={1}
    >
      <ModalContainer ref={modalRef}>
        <Error modalRef={modalRef} error={error} />
        <CreateAccountSection
          setPaymentDetailsOpen={() => {}}
          setError={setError}
        />
        <Accordion
          primaryText={t(`components.PaymentSection.paymentDetails`)}
          status='incomplete'
          disabled={false}
          open
          onHeadingClick={() =>
            trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_ACCORDION_EXPANDED, {
              Accordion: 'Payment Details',
            })
          }
        >
          <Container>
            <Section>
              <FlexColumn>
                <h5 className='mb-0'>{t(`components.PaymentSection.title`)}</h5>
                <SectionDescription>
                  {t(`components.PaymentSection.description`)}
                </SectionDescription>
              </FlexColumn>
              <FormSection>
                <PaymentElement
                  options={{
                    wallets: { applePay: 'never', googlePay: 'never' },
                  }}
                />
              </FormSection>
            </Section>
          </Container>
        </Accordion>
      </ModalContainer>
    </Modal>
  );
};

const CreateAccountPage = () => {
  const { t } = useTranslation('signup');
  const stripePromise = useMemo(() => loadStripe(STRIPE_KEY!), []);
  const [error, setError] = useState<any>(null);
  const modalRef = React.useRef(null);

  const { data } = useCreatePaymentIntent({
    onError: error => setError({ error, source: 'payment-intent' }),
  });

  const handleSubmit = useCallback(() => {}, []);
  if (!data)
    return (
      <Modal
        footer={
          <Footer
            primaryBtnText={t(`footer.complete`)}
            primaryButtonProps={{
              onClick: handleSubmit,
              disabled: true,
            }}
          />
        }
        stepNumber={1}
      >
        <ModalContainer ref={modalRef}>
          <Error modalRef={modalRef} error={error} />
          <CreateAccountSection
            setPaymentDetailsOpen={() => {}}
            setError={setError}
          />
          <Accordion
            primaryText={t(`components.PaymentSection.paymentDetails`)}
            secondaryText=''
            status={
              localStorage.getItem('accessToken') ? 'incomplete' : 'not-started'
            }
            disabled={!localStorage.getItem('accessToken')}
            open={!!localStorage.getItem('accessToken')}
            onHeadingClick={() => {}}
          >
            <Container>
              <Section>
                <FlexColumn>
                  <h5 className='mb-0'>
                    {t(`components.PaymentSection.title`)}
                  </h5>
                  <SectionDescription>
                    {t(`components.PaymentSection.description`)}
                  </SectionDescription>
                </FlexColumn>
                <FormSection />
              </Section>
            </Container>
          </Accordion>
        </ModalContainer>
      </Modal>
    );

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: data.client_secret,
        appearance: {
          theme: 'stripe',
        },
        loader: 'never',
      }}
    >
      <PaymentForm error={error} setError={setError} />
    </Elements>
  );
};

export default CreateAccountPage;
