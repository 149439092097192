import { Namespace } from 'modules/assess/api';
import * as API from 'modules/assess/api/rules/library/library';
import Kind from 'modules/assess/models/rules/common/kind';
import { AnyQueryKey, useQuery } from 'react-query';

export const useSearch = (
  ns: Namespace | undefined,
  kind: Kind,
  params?: API.SearchParams,
) => {
  const namespace = API.uri(kind, ns);
  const key: AnyQueryKey = [namespace, params];

  const request = () => {
    return ns ? API.search(kind, ns, params) : Promise.reject();
  };

  const result = useQuery(key, request);
  return result;
};
