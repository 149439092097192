import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { M } from '@dashboard-experience/mastodon';
import {
  getResultOrStatus,
  dashedPhoneNumber,
  titleize,
  getDocument,
  getFullLocationHours,
  hasPermission,
} from '@dashboard-experience/utils';
import UIContext from 'context/UI';
import { APPLY_URL } from 'Constants';
import { useReport } from 'containers/Report';
import { GenericObject } from 'types';
import { useUser } from 'context/CurrentUser';
import { useDashboardRelay, RelayTypes } from 'hooks';
import { postMessageToDashboard, scrollToTop } from 'utils';
import {
  useRenewScreeningPass,
  useResendScreeningInvitation,
} from 'api/healthScreening';
import {
  Column,
  SectionTitle,
  Detail,
  Days,
  ButtonsWrapper,
  EmailButtonWrapper,
  EmailIcon,
  ChevronIcon,
  ButtonTitle,
  StyledEmailCandidateButton,
  StyledMenuItem,
  StyledDownloadButton,
} from './HealthScreeningAppointmentPaneStyledComponents';
import HealthScreeningAddMoreTimeModal from './HealthScreeningAddMoreTimeModal';

type GeneralProps = {
  appointment: GenericObject;
};

const StyledModalWrapper = styled.div`
  position: absolute;
`;

const RenderEmailButton = () => (
  <div className='mastodon-menu-title' style={{ display: 'flex' }}>
    <EmailIcon icon='Email' size='16' />
    <ButtonTitle className='mastodon-menu-label'>
      <b>Email candidate</b>
    </ButtonTitle>
    <ChevronIcon icon='ChevronUp' size='16' />
  </div>
);

const HealthScreeningAppointmentPane: React.FC<GeneralProps> = ({
  appointment,
}) => {
  const { contextId, isIframe } = useContext(UIContext);
  const report = useReport();
  const currentUser = useUser();
  const userAccountName = currentUser.account.uri_name;
  const {
    id,
    can_renew_screening_pass,
    can_resend_reminder,
    location,
    updateable,
  } = appointment;

  const visible =
    (hasPermission(currentUser, 'read_full_reports') ||
      hasPermission(currentUser, 'create_manual_orders')) &&
    !!appointment;

  const appointment_scheduled = !!location;
  const streetAddressLine1 = `${location?.name}`;
  const streetAddressLine2 = `${location?.street} ${location?.unit}`;
  const streetAddressLine3 = `${location?.city}, ${location?.state} ${location?.zipcode}`;
  const facilityPhoneNumber = location
    ? dashedPhoneNumber(location?.phone_number)
    : null;
  const businessHours = location ? getFullLocationHours(location) : null;

  const occupationalHealthScreening = report.occupational_health_screening;
  const drugScreening = report.drug_screening;
  const screening = occupationalHealthScreening || drugScreening;
  const drugStatus = getResultOrStatus(drugScreening || {});
  const healthStatus = getResultOrStatus(occupationalHealthScreening || {});

  const showEmailCandidateButton =
    can_renew_screening_pass || can_resend_reminder;
  const [passExpirationDate, setPassExpiration] = useState(
    appointment.donor_pass_expires_at,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, []);

  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const screeningPass = getDocument(report, 'screening_pass');

  const appointmentUpdateable =
    hasPermission(currentUser, 'manage_health_appointments') && updateable;

  const healthScreeningAppointmentPanePostMessageObject: RelayTypes = {
    messageId: 'screening_pass_expiration_updated',
    callback: (event: any) => {
      setPassExpiration(event.data.new_expiration_date);
    },
    isIframe,
    contextId,
  };

  useDashboardRelay(healthScreeningAppointmentPanePostMessageObject);

  /**
   * @name refreshChangeLocationUrl
   * @function
   * @memberOf HealthScreeningAppointmentPane
   * @description Sends message to Angular dashboard to open change location url
   */
  const refreshChangeLocationUrl = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'refresh_change_location_url',
      });
    }
  }, [contextId, isIframe]);

  const { call: renewScreeningPass } = useRenewScreeningPass(id);
  const { call: resendScreeningInvitation } = useResendScreeningInvitation(
    screening?.id,
  );

  return visible ? (
    <M.Screening
      title='Health screening appointment'
      id='health-screening-appointment-pane'
      data-testid='health-screening-appointment-pane'
    >
      <M.Grid>
        <M.GridRow>
          <Column>
            <SectionTitle>Screening location</SectionTitle>
            {location ? (
              <Detail>
                {streetAddressLine1}
                <br />
                {streetAddressLine2}
                <br />
                {streetAddressLine3}
                <br />
                {facilityPhoneNumber}
              </Detail>
            ) : (
              <Detail>Screening location pending</Detail>
            )}
            {appointmentUpdateable && location ? (
              <M.Link
                href={`${APPLY_URL}/appointments/${userAccountName}/${id}`}
                target='_blank'
                onClick={refreshChangeLocationUrl}
              >
                Change location
              </M.Link>
            ) : null}
            {appointmentUpdateable && !location ? (
              <M.Link
                href={`${APPLY_URL}/appointments/${userAccountName}/${id}`}
                target='_blank'
                onClick={refreshChangeLocationUrl}
              >
                Choose location
              </M.Link>
            ) : null}
          </Column>
          <Column>
            <SectionTitle>Business hours</SectionTitle>
            {businessHours?.map((timeObj: GenericObject, idx: number) => (
              <Detail key={idx.toString()}>
                <Days>{timeObj.days}</Days> <br />
                {timeObj.hours}
              </Detail>
            ))}
          </Column>
          <Column>
            <SectionTitle>Pass expiration</SectionTitle>
            <Detail>
              {moment(passExpirationDate).format('MMMM Do, YYYY')}
            </Detail>
            {appointmentUpdateable ? (
              <M.Link onClick={openModal}>Add more time</M.Link>
            ) : null}
          </Column>
          {drugStatus && (
            <Column>
              <SectionTitle>Drug Screening Status</SectionTitle>
              <Detail>{titleize(drugStatus)}</Detail>
            </Column>
          )}
          {healthStatus && (
            <Column>
              <SectionTitle>Health Screening Status</SectionTitle>
              <Detail>{titleize(healthStatus)}</Detail>
            </Column>
          )}
        </M.GridRow>
      </M.Grid>
      <ButtonsWrapper>
        <EmailButtonWrapper>
          {showEmailCandidateButton ? (
            <StyledEmailCandidateButton
              data-testid='email-candidate-button'
              data-floating-menu-container
              direction='top'
              renderIcon={RenderEmailButton}
              id='email-candidate-options'
              flipped
            >
              {can_resend_reminder ? (
                <StyledMenuItem
                  data-testid='resend-reminder-menu-item'
                  requireTitle
                  onClick={resendScreeningInvitation}
                  itemText={
                    appointment_scheduled
                      ? 'Re-send screening pass'
                      : 'Re-send notice to schedule'
                  }
                />
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}

              {can_renew_screening_pass ? (
                <StyledMenuItem
                  data-testid='renew-screening-pass-menu-item'
                  requireTitle
                  onClick={renewScreeningPass}
                  itemText={
                    appointment_scheduled
                      ? 'Send updated screening pass'
                      : 'Re-send screening invitation'
                  }
                />
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
            </StyledEmailCandidateButton>
          ) : null}
        </EmailButtonWrapper>

        {screeningPass?.download_uri ? (
          <StyledDownloadButton
            kind='secondary'
            size='sm'
            href={screeningPass?.download_uri}
            target='_blank'
          >
            Download screening pass
          </StyledDownloadButton>
        ) : null}
      </ButtonsWrapper>
      {isModalOpen && (
        <StyledModalWrapper>
          <HealthScreeningAddMoreTimeModal
            appointmentId={id}
            expiresAt={passExpirationDate}
            isOpen={isModalOpen}
            onClose={closeModal}
          />
        </StyledModalWrapper>
      )}
    </M.Screening>
  ) : null;
};

export default HealthScreeningAppointmentPane;
