/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const FormInputContainer = styled.div`
  margin-bottom: 0.8rem;
`;

export const Label = styled.p`
  margin-bottom: 1em !important;
  padding-top: 16px !important;
  font-weight: bold;
`;

export const ErrorLabelWrapper = styled.div`
  margin-bottom: 1em;
`;

export const ErrorLabel = styled.span`
  font-weight: bold;
  color: red;
`;

export const ErrorLabelContainer = styled.div`
  margin-bottom: 1em;
  color: ${colors.uiOrange600};
  display: flex;
  align-items: initial;
`;

export const ErrorIcon = styled(M.Icon)`
  flex-shrink: 0;
  margin-right: 8px;
}`;

export const ErrorText = styled.span`
  color: ${colors.uiOrange600};
  flex-grow: 1;
  font-weight: 400;
`;
