import { AccountsClient as client } from 'api';
import { useQuery } from 'react-query';
import {
  itemizedAddonRequest1,
  itemizedAddonRequest2,
  itemizedAddonRequest3,
  itemizedAddonRequest4, // ← credit report only
  itemizedAddonRequest5,
  itemizedAddonRequest6,
  ADDON_SKU_IDS,
} from '../shared/screeningPayloads';

export function usePackagePrice(
  cacheKey: any,
  account: any,
  basePackage: any,
  setAddonsPrice?: Function,
  enabled: boolean = true,
) {
  return useQuery(
    cacheKey,
    async () => {
      if (basePackage.screenings.length === 0) {
        return {
          bundled_price: 0,
          fixed_price: 0,
          passthrough_fee_range: [0, 0],
          service_fee_range: [0, 0],
          items: [],
        };
      }
      const path = `${account.id}/packages/calculate_prices`;
      const resp = await client.put(path, basePackage);
      return resp;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data: any) => {
        if (setAddonsPrice) {
          setAddonsPrice(data.fixed_price);
        }
      },
      enabled,
    },
  );
}

export function useAllAddOnPricesFromSkus(id: number) {
  return useQuery(
    [id],
    async () => {
      const path = `${id}/packages/addon_prices`;

      const params = {
        sku_ids: ADDON_SKU_IDS,
      };

      const addon = await client.get(path, { params });
      return addon;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export function useAllAddOnPrices(account: any, basePackage: any) {
  return useQuery(
    ['base package prices', basePackage.name],
    async () => {
      const path = `${account.id}/packages/calculate_prices`;

      const itemizedAddon1 = await client.put(path, itemizedAddonRequest1);
      const itemizedAddon2 = await client.put(path, itemizedAddonRequest2);
      const itemizedAddon3 = await client.put(path, itemizedAddonRequest3);
      let itemizedAddon4;
      if (account?.credit_report_setting && account?.credit_report_ready) {
        itemizedAddon4 = await client.put(path, itemizedAddonRequest4);

        const creditReport = itemizedAddon4.items.find(
          (item: any) => item.product_type === 'credit_report',
        );

        itemizedAddon1.items.push({
          ...creditReport,
        });
      }
      const itemizedAddon5 = await client.put(path, itemizedAddonRequest5);
      const itemizedAddon6 = await client.put(path, itemizedAddonRequest6);

      const facis1 = itemizedAddon1.items.find(
        (item: any) => item.product_type === 'facis_search',
      );
      const employmentVerification1 = itemizedAddon1.items.find(
        (item: any) => item.product_type === 'employment_verification',
      );
      const personalReferenceVerification1 = itemizedAddon1.items.find(
        (item: any) => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification1 = itemizedAddon1.items.find(
        (item: any) =>
          item.product_type === 'professional_reference_verification',
      );

      facis1.subtype = '1';
      employmentVerification1.lookback_years = 3;
      employmentVerification1.maximum_allowed_employers = null;
      personalReferenceVerification1.required_quantity = [true, '1'];
      professionalReferenceVerification1.required_quantity = [true, '1'];

      const facis3 = itemizedAddon2.items.find(
        (item: any) => item.product_type === 'facis_search',
      );
      const employmentVerification2 = itemizedAddon2.items.find(
        (item: any) => item.product_type === 'employment_verification',
      );
      const personalReferenceVerification2 = itemizedAddon2.items.find(
        (item: any) => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification2 = itemizedAddon2.items.find(
        (item: any) =>
          item.product_type === 'professional_reference_verification',
      );
      const countyCriminalSearch2 = itemizedAddon2.items.find(
        (item: any) => item.product_type === 'county_criminal_search',
      );
      const stateCriminalSearch2 = itemizedAddon2.items.find(
        (item: any) => item.product_type === 'state_criminal_search',
      );

      const employmentVerification3 = itemizedAddon3.items.find(
        (item: any) => item.product_type === 'employment_verification',
      );

      const employmentVerification4 = itemizedAddon5.items.find((item: any) => {
        return item.product_type === 'employment_verification';
      });

      const employmentVerification5 = itemizedAddon6.items.find((item: any) => {
        return item.product_type === 'employment_verification';
      });

      const personalReferenceVerification3 = itemizedAddon3.items.find(
        (item: any) => item.product_type === 'personal_reference_verification',
      );
      const professionalReferenceVerification3 = itemizedAddon3.items.find(
        (item: any) =>
          item.product_type === 'professional_reference_verification',
      );

      itemizedAddon1.items.push({ ...facis3, subtype: '3' });
      itemizedAddon1.items.push({
        ...employmentVerification2,
        lookback_years: 5,
        maximum_allowed_employers: null,
      });
      itemizedAddon1.items.push({
        ...employmentVerification3,
        lookback_years: 7,
        maximum_allowed_employers: null,
      });
      itemizedAddon1.items.push({
        ...employmentVerification4,
        lookback_years: 10,
        maximum_allowed_employers: 1,
      });
      itemizedAddon1.items.push({
        ...employmentVerification5,
        lookback_years: 10,
        maximum_allowed_employers: null,
      });
      itemizedAddon1.items.push({
        ...personalReferenceVerification2,
        required_quantity: [true, '2'],
      });
      itemizedAddon1.items.push({
        ...professionalReferenceVerification2,
        required_quantity: [true, '2'],
      });
      itemizedAddon1.items.push({
        ...countyCriminalSearch2,
        subtype: 'current',
      });
      itemizedAddon1.items.push({
        ...stateCriminalSearch2,
        subtype: 'current',
      });
      itemizedAddon1.items.push({
        ...personalReferenceVerification3,
        required_quantity: [true, '3'],
      });
      itemizedAddon1.items.push({
        ...professionalReferenceVerification3,
        required_quantity: [true, '3'],
      });
      return itemizedAddon1;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

// to find out what the screen looks like, look at screeningPayloads.tsx
export function useAddonPrice(id: any, screen: any) {
  return useQuery(
    [id, screen],
    async () => {
      const path = `${id}/packages/calculate_prices`;

      const first = {
        name: 'singular add on',
        slug: 'singular_add_on',
        screenings: [screen],
      };

      const addon = await client.put(path, first);
      return addon;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}
