import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  InternationalTitle,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ActionPropsProps,
} from '../../../shared/ListItemElements';

type InternationalCriminalSearchProps = {
  shouldOpen: boolean;
  actionProps: ActionPropsProps;
};
const InternationalCriminalSearch = ({
  shouldOpen,
  actionProps,
}: InternationalCriminalSearchProps) => {
  return (
    <FlexContainer data-testid='international-criminal-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        title={
          <InternationalTitle
            actionProps={actionProps}
            title='International Criminal Search'
            priceByType='international_criminal_search_v2'
            priceVaries
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Searches location-appropriate databases for any criminal offenses.
            {` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='intl-crim-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360056108714'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Leverages multi-jurisdictional repositories and databases to return major offense conviction records' />
              <BulletPoint text='Helps maintain compliance, mitigate risk, and make informed hiring decisions' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Turnaround may be longer compared to US screenings' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='international_criminal_search_v2'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default InternationalCriminalSearch;
