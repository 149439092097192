import { M } from '@dashboard-experience/mastodon';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Invitation } from 'types/report/invitation';
import { ExpandablePanel, KeyValueList } from '../styles';
import { SectionProps } from '../types';

const StyledLaunchIcon = styled(M.Icon)`
  margin-left: 5px;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const OrderInvitationInformation: React.FC<SectionProps> = ({
  candidate,
  invitation,
}) => {
  const { invitations = [] } = candidate;

  const getDefaultIndex = () => {
    const defaultIndex = invitations.findIndex(
      (inv: Invitation) => inv.id === invitation?.id,
    );
    return defaultIndex !== -1 ? defaultIndex : 0;
  };

  const [invitationIndex, setInvitationIndex] = useState(() =>
    getDefaultIndex(),
  );

  const updateInvitationIndex = useCallback(
    ({ target: { value } }) => setInvitationIndex(value),
    [setInvitationIndex],
  );

  if (!invitation) {
    return null;
  }

  const items = [
    {
      itemKey: 'Link',
      itemValue: invitation?.invitation_url ? (
        <M.Link href={invitation?.invitation_url} target='_blank'>
          Open invitation link
          <StyledLaunchIcon icon='Launch' />
        </M.Link>
      ) : (
        'Temporarily unavailable'
      ),
    },
    {
      itemKey: 'Link expires',
      itemValue: moment(invitation?.expires_at).format('lll'),
    },
    { itemKey: 'Package', itemValue: invitation?.package },
  ];

  const invitationSelectString = (invitation: Invitation) => {
    const invite_status = capitalize(invitation.status);
    const d = new Date(Date.parse(invitation.created_at));
    const display_date = d.toLocaleString('en-us', {
      // @ts-ignore
      dateStyle: 'short',
      // @ts-ignore
      timeStyle: 'short',
    });

    return `${invite_status} Invitation sent at ${display_date}`;
  };

  return (
    <ExpandablePanel
      initialExpanded
      title='Order information'
      style={{ marginTop: '2rem' }}
    >
      {invitation && <KeyValueList items={items} />}
      {invitations.length > 1 && (
        <SelectWrapper>
          <M.Select
            inline
            noLabel
            value={invitationIndex}
            onChange={updateInvitationIndex}
            data-testid='multiple-invitation-selector'
          >
            {invitations.map((invitation, i) => (
              <M.Select.Item
                key={invitation.id}
                value={i}
                text={invitationSelectString(invitation)}
              />
            ))}
          </M.Select>
        </SelectWrapper>
      )}
    </ExpandablePanel>
  );
};
export default OrderInvitationInformation;
