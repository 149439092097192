export type Location = {
  hours: { [key: string]: string };
  siteId: string;
  siteName: string;
  address1: string;
  address2: string;
  distance: string;
  distanceUnit: string;
  phoneNumber: string;
  electronicallyEnabled: boolean;
  isAdditionalLocation: boolean;
  zipcode: string;
};

export type State = {
  selectNewClinic?: string;
  zipcode: string;
  clinic?: {
    name: string;
    id: string;
  };
};

export type Props = {
  accountId: string;
  packageId: string;
  examSlugs: string | string[];
  testPackage: boolean;
  requiresObservedCollection: boolean;
  updateState: (state: State, field: string | any) => void;
  state: State;
  translations: Translations;
};

export type PanelProps = Location & {
  appState: State;
  updateState: (state: State, field: string | any) => void;
  translations: ClinicTranslations;
};

export type Translations = {
  title: string;
  choices: {
    header: string;
    first: string;
    second: string;
  };
  search: {
    title: string;
    placeholder: string;
    error: {
      title: string;
      description: string;
    };
    clinic: ClinicTranslations;
  };
  no_clinic: string;
};

export type ClinicTranslations = {
  schedule: string;
};

export enum Field {
  Zipcode = 'zipcode',
  Clinic = 'clinic',
  SelectNewClinic = 'selectNewClinic',
}
