import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useDownloadContinuousCheckFile } from 'actions';

type Props = {
  success: boolean;
  downloadUri: string;
};

const handleCsvExportDownload = (call: any, e: any) => {
  e.preventDefault();
  const { redirectUrl } = e.target.dataset;
  call(redirectUrl);
};

const Container = styled.div`
  text-align: center;
`;

const ContinuousServicesCsvExport: React.FC<Props> = ({
  success,
  downloadUri,
}) => {
  const { call } = useDownloadContinuousCheckFile();
  const csvExportDownload = useCallback(
    e => handleCsvExportDownload(call, e),
    [call],
  );

  const iconAttributes = success
    ? {
        icon: 'CheckmarkFilled',
        iconColor: colors.uiGreen600,
      }
    : {
        icon: 'CloseFilled',
        iconColor: colors.uiOrange600,
      };

  const content = success ? (
    <M.Button
      href='#'
      target='_blank'
      rel='noopener noreferrer'
      onClick={csvExportDownload}
      data-redirect-url={downloadUri}
      kind='primary'
    >
      Download
    </M.Button>
  ) : (
    <span>This CSV is not available. Please request a new one.</span>
  );

  return (
    <Container>
      <M.Icon
        icon={iconAttributes.icon}
        size='32'
        style={{ fill: iconAttributes.iconColor, marginBottom: '10px' }}
      />
      <h2>CSV is {success ? '' : 'not '}available</h2>
      {content}
    </Container>
  );
};

export default ContinuousServicesCsvExport;
