import { analytics } from '@dashboard-experience/utils';
import { AMPLITUDE_KEY, PENDO_KEY } from 'Constants';
import * as Sentry from '@sentry/react';

const services: { name: string; apiKey: string }[] = [];
if (AMPLITUDE_KEY)
  services.push({
    name: 'amplitude',
    apiKey: AMPLITUDE_KEY,
  });
// Don't include pendo service in e2e automation
if (PENDO_KEY && !navigator.webdriver)
  services.push({
    name: 'pendo',
    apiKey: PENDO_KEY,
  });

// eslint-disable-next-line import/no-mutable-exports
let checkrAnalytics = {};

try {
  checkrAnalytics = analytics(services);
} catch (err) {
  Sentry.captureEvent(err as Error);
}

export default checkrAnalytics;
