import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import BulkEditLink from './BulkEditLink';

type Props = {
  onClickBulkEdit: (event?: SyntheticEvent) => void;
  showActions?: boolean;
};

const Container = styled.div`
  display: flex;
  padding-right: 1rem;
`;

const StyledIcon = styled(M.Icon)`
  color: ${colors.brandNavy2};
  margin-left: 2em;
  top: 0.4em;
`;

const Actions: React.FC<Props> = ({ onClickBulkEdit, showActions }) => {
  if (!showActions) return null;

  return (
    <Container
      onClick={onClickBulkEdit}
      data-testid='assess-v2-ui-guideline-lookback-period-table-actions-container'
    >
      <BulkEditLink />
      <StyledIcon icon='Edit' size='20' />
    </Container>
  );
};

export default Actions;
