import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import * as Entity from 'modules/assess/models/settings/geo';

type Props = {
  selections: Entity.List;
};

const StyledUl = styled.ul`
  margin-left: 2.8rem;
  list-style-type: disc;
  li {
    margin-bottom: 0.4rem;
    margin-left: 0.9rem;
  }
  span {
    font-style: italic;
  }
`;

const SelectedGeos: React.FC<Props> = ({ selections }) => {
  const { t } = useTranslation('assess');
  const noneSelectedText = t('settings.no_geos_selected');

  const selectedList = selections.map(item => {
    return <li key={item.id}> {item.name}</li>;
  });

  const selectedGeos = isEmpty(selectedList) ? (
    <span>{noneSelectedText}</span>
  ) : (
    selectedList
  );

  return (
    <StyledUl data-testid='assess-v1-settings-adverse-action-automation-selected-geos'>
      {selectedGeos}
    </StyledUl>
  );
};

export default SelectedGeos;
