import React from 'react';
import styled from 'styled-components';
import { Decision, ItemVisibility as ItemVisibilityEnum } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import { useUserAccount } from 'modules/assess/ui/hooks';
import Description from './Description';
import ItemVisibility from './ItemVisibility';
import * as Common from '../../common/rule';
import * as Rules from '../../../../models/criminal/rules/rules';

type Props = {
  disabled?: boolean;
  namespace: Namespace;
  rule: Rules.Entity.Type;
  selected?: boolean;
  onUpdate: (id: string, itemVisibility?: ItemVisibilityEnum) => void;
};

const Container = styled(Common.Container)`
  padding-top: 0.8rem !important;
  padding-bottom: 1.5rem !important;

  [data-testid='assess-rules-standard-table-description'] .mastodon-grid-col {
    padding: 0;
  }
`;

export const Content: React.FC<Props> = ({
  disabled,
  namespace,
  rule,
  selected,
  onUpdate,
}) => {
  const account = useUserAccount();

  const displayItemVisibility =
    namespace === Namespace.criminal &&
    account?.assess_eligible_item_treatment_enabled &&
    Decision.ELIGIBLE === rule.decision &&
    selected;

  return (
    <Container>
      <Common.Assessment rule={rule} />
      <Common.Targets rule={rule} />
      <Common.Conditions rule={rule} />
      <Description namespace={namespace} rule={rule} />
      {displayItemVisibility && (
        <ItemVisibility disabled={disabled} rule={rule} onUpdate={onUpdate} />
      )}
    </Container>
  );
};

export default Content;
