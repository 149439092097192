/* eslint-disable import/prefer-default-export */
import { AnyQueryKey, useQuery } from 'react-query';
import {
  UserVersionsResponse,
  UserDetailsResponse,
  FetchUserVersionsResponse,
  FetchUserDetailsResponse,
} from './types';
import { fetchUserVersions, fetchUserDetails } from './actions';

export const useFetchUserVersions = (
  accountId: string,
  userId: string,
  page: number,
  per_page: number,
): FetchUserVersionsResponse => {
  const key: AnyQueryKey = [`users/versions/get-${page}`, { accountId }];

  const request = () => fetchUserVersions(accountId, userId, page, per_page);

  const { data, isLoading, isError } = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  const versions: FetchUserVersionsResponse['versions'] =
    data as UserVersionsResponse;
  return { isError, isLoading, versions };
};

export const useFetchUserDetails = (
  accountId: string,
  userId: string,
): FetchUserDetailsResponse => {
  const key: AnyQueryKey = ['users/get', { accountId }];

  const request = () => fetchUserDetails(accountId, userId);
  const { data, isLoading, isError } = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  const user: FetchUserDetailsResponse['user'] =
    (data as UserDetailsResponse) || {
      email: '',
      full_name: null,
      account_id: '',
      last_seen: '',
      created_at: '',
      updated_at: '',
      checkr_user_id: '',
      roles: [],
    };
  return { isError, isLoading, user };
};
