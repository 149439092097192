import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const BetaBanner = () => {
  return (
    <M.Container
      data-testid='case-management-beta-banner'
      type='info'
      rows={[
        <h3 key='h'>Thanks for participating in our Beta</h3>,
        <p key='p'>
          This feature is still in development. Please share any feedback to{' '}
          <a href='mailto:cm-beta@checkr.com'>cm-beta@checkr.com</a>
        </p>,
      ]}
    />
  );
};

export default BetaBanner;
