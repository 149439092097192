import React, { useEffect, useState } from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import { Table } from './table';
import { useSearch } from '../../version/api';

export type Props = {
  item: Entity;
  setSelectedVersion: (version: Version.Basic) => void;
  onClose: () => void;
};

const PAGE_SIZE = 10;

export const Container: React.FC<Props> = ({
  item,
  setSelectedVersion,
  onClose,
}) => {
  const [page, setPage] = useState(1);

  const query = useSearch(item.id, {
    page,
    per_page: PAGE_SIZE,
  });
  const result = query?.data;
  const pageCount = Math.ceil((result?.count || 0) / PAGE_SIZE);

  useEffect(() => {
    if (pageCount && page >= pageCount) {
      setPage(pageCount - 1);
    }
  }, [page, pageCount]);

  if (!result) {
    return null;
  }

  return (
    <Table
      item={item}
      page={{ count: pageCount, index: page - 1, onClick: setPage }}
      versions={result.data}
      setSelectedVersion={setSelectedVersion}
      onClose={onClose}
    />
  );
};

export default Container;
