import { AnyQueryKey, useQuery } from 'react-query';
import { useMemo } from 'react';
import * as API from 'api';

type Params = {
  [key: string]: any;
};

export const getData = async (params: Params) => {
  const {
    account: { id: accountId, hierarchy_present },
    account,
  } = params;

  const safeApiCall = (promise: Promise<any>) => promise.catch(() => null);

  const [geos, programs, node, billing] = await Promise.all([
    API.geos.list({ accountId }),
    API.programs.list({ accountId }),
    hierarchy_present && API.nodes.fetchNode({ account }),
    safeApiCall(getBillingData(accountId)),
  ]);

  return {
    geos: geos.geos,
    programs,
    node,
    billing,
  };
};

const getBillingData = (accountId: string) => {
  return API.payment.fetchBillingAddress(accountId).catch(error => {
    if (error.response.status === 404) {
      return null;
    }
    throw error;
  });
};

// eslint-disable-next-line import/prefer-default-export
export const useGetData = (params: Params) => {
  const key: AnyQueryKey = [
    'packages-order-flow',
    {
      id: params?.account?.id,
      hierarchy_present: params?.account?.hierarchy_present,
    },
  ];

  const request = () => getData(params);

  const res = useQuery(key, request, { refetchOnWindowFocus: false });
  const data = res?.data;
  const isLoading = res?.isLoading;
  const isError = res?.isError;
  const error = res?.error;

  const geos = data?.geos;
  const node = data?.node;
  const programs = data?.programs;
  const customer = data?.billing;
  const billing_entity_enabled = customer?.billing_entity_enabled || false;
  const payment_profiles = useMemo(
    () =>
      billing_entity_enabled
        ? [
            {
              name: 'Main Account',
              email: customer.email,
              address: customer?.address,
            },
            ...customer.billing_entities,
          ]
        : [],
    [customer, billing_entity_enabled],
  );

  const payload = useMemo(
    () => ({
      geos,
      node,
      programs,
      payment_profiles,
      billing_entity_enabled,
    }),
    [geos, node, programs, payment_profiles, billing_entity_enabled],
  );

  return {
    ...payload,
    error,
    isError,
    isLoading,
  };
};
