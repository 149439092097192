import React, { useCallback, SyntheticEvent } from 'react';

import Button from 'components/Button';
import { Step } from '../Step';

type Props = {
  currentStep: Step;
  onCancel?: (event: SyntheticEvent) => void;
  onContinue: (nextStep: Step) => void;
};

export const Actions: React.FC<Props> = ({
  currentStep,
  onCancel,
  onContinue,
}) => {
  const handleContinue = useCallback(
    (e: SyntheticEvent) => {
      onContinue(currentStep);
    },
    [currentStep, onContinue],
  );

  return (
    <>
      <Button
        data-testid='assess-ruleset-version-publish-actions-cancel-button'
        kind='secondary'
        trans='verbs.cancel'
        onClick={onCancel}
      />
      <Button
        data-testid='assess-ruleset-version-publish-actions-continue-button'
        kind='primary'
        trans='buttons.continue'
        onClick={handleContinue}
      />
    </>
  );
};

export default Actions;
