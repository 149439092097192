import React from 'react';
import * as Images from '../../../assets/benefits';
import Subsections from '../subsections';

type Props = {
  link: string;
  subsection: Subsections;
};

const IMAGES = {
  angi: {
    dimensions: {
      height: '60px',
      width: '109px',
    },
    image: Images.AngiLogo,
  },
  good_eggs: {
    dimensions: {
      height: '60px',
      width: '180px',
    },
    image: Images.GoodEggsLogo,
  },
  rock_point: {
    dimensions: {
      height: '69px',
      width: '221px',
    },
    image: Images.RockPointChurchLogo,
  },
};

const Image: React.FC<Props> = ({ link, subsection }) => {
  const data = IMAGES[subsection];

  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <img
        height={data.dimensions.height}
        width={data.dimensions.width}
        src={data.image}
        alt={data.image}
      />
    </a>
  );
};

export default Image;
