import { combineReducers } from 'redux';

import {
  FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST,
  FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS,
  FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE,
  FETCH_BULK_ACTION_REQUEST,
  FETCH_BULK_ACTION_SUCCESS,
  FETCH_BULK_ACTION_FAILURE,
} from '../actions/ActionTypes';

import { httpHelper } from './reducerHelpers';

export const initialState = {
  fetchRequest: { ...httpHelper.initialState },
  isInitialState: true,
  csvExports: [],
  isInitialDriverRecordReportsState: true,
  driverRecordReports: [],
  driverRecordReportsTotal: 0,
  searchValues: {},
  params: {
    page: 1,
    per_page: 10,
    order_by: 'created_at',
    sort: 'desc',
  },
  isInitialBulkActionState: true,
  bulkActions: [],
  bulkActionsTotal: 0,
  paramsBulkAction: {
    page: 1,
    per_page: 20,
  },
};

// eslint-disable-next-line default-param-last
const driverRecordReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CC_DRIVER_RECORD_REPORTS_REQUEST:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onRequest },
      };

    case FETCH_CC_DRIVER_RECORD_REPORTS_SUCCESS: {
      const {
        driverRecordReports,
        driverRecordReportsTotal,
        searchValues,
        params,
      } = action;
      return {
        ...state,
        fetchRequest: { ...httpHelper.onSuccess },
        isInitialDriverRecordReportsState: false,
        driverRecordReports,
        driverRecordReportsTotal,
        searchValues,
        params,
      };
    }
    case FETCH_CC_DRIVER_RECORD_REPORTS_FAILURE:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onFailure },
      };

    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const bulkActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BULK_ACTION_REQUEST:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onRequest },
      };

    case FETCH_BULK_ACTION_SUCCESS: {
      const { bulkActions, bulkActionsTotal, paramsBulkAction } = action;
      return {
        ...state,
        fetchRequest: { ...httpHelper.onSuccess },
        isInitialBulkActionState: false,
        bulkActions,
        bulkActionsTotal,
        paramsBulkAction,
      };
    }
    case FETCH_BULK_ACTION_FAILURE:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onFailure },
      };

    default:
      return state;
  }
};

const continuousServicesReducer = combineReducers({
  driverRecordReportsReducer,
  bulkActionReducer,
});

export default continuousServicesReducer;
