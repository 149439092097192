/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';

import { M, colors } from '@dashboard-experience/mastodon';
import {
  emailValidationRegex,
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { toastError } from 'actions';
import {
  FilteredPackages,
  filterPackages,
  isInternationalCountry,
  postMessageToDashboard,
} from 'utils';

import { LoadingScreen } from 'components/Account';
import {
  AddScreenings,
  AddScreeningsButton,
  Slideout,
} from 'components/AddScreenings/';
import { useUser } from 'context/CurrentUser';
import { useCreateCandidate } from 'api/invitations';
import { useFlag, Flag } from '@dashboard-experience/react-flagr';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';
import UIContext from 'context/UI';
import {
  ENV,
  PR_US_STATE_KEY,
  WASHINGTON_CONSENT_FLAG_KEY,
  INVITE_PAGE_ADD_SCREENINGS,
  AUTO_SHOW_ADDONS_FLAG_KEY,
} from 'Constants';
import { Geo } from 'types';
import { mapPackage, sleep } from 'components/AddScreenings/shared/utils';
import InternationalAddScreeningsList from 'components/AddScreenings/International/List';
import DomesticAddScreeningsList from 'components/AddScreenings/Domestic/List';
import DomesticAddScreeningsSummary from 'components/AddScreenings/Domestic/Summary';
import InternationalAddScreeningsSummary from 'components/AddScreenings/International/Summary';
import InternationalNote from 'components/AddScreenings/International/Note';
import { MAX_BULK_INVITATIONS } from '../../../Constants';

import Context from '../context';
import { Buffer, Wrapper } from '../Order/View';
import create from './create';
import Header from './Header';

const Button = styled(M.Button)`
  &:disabled {
    opacity: 50% !important;
  }
`;

const Icon = styled(M.Icon)`
  margin-right: 0.2rem;
`;

const ProcessingText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`;

const AddonsInlineNotification = styled(M.ActionableNotification)`
  border-radius: 4px;
  min-width: 100% !important;
  background-color: ${colors.uiNavy100} !important;
  border-left-color: ${colors.uiNavy600} !important;

  .cds--toast-notification__icon {
    fill: ${colors.uiNavy600} !important;
  }
  .cds--actionable-notification__subtitle {
    margin-top: 10px !important;
  }
`;

const AnchorForScrollAfterAddonsAddition = styled.div``;

// split emails list by "," or ";"
const pasteSplit = (text: string) =>
  text
    .replace(/[\r\n,;]/g, ' ')
    .split(' ')
    .map(d => d.trim());

// tab, enter, space, semicolon, comma
const addKeys = [9, 13, 32, 186, 188];

const inputProps = {
  placeholder: 'Add email address',
  style: { minWidth: '16rem' },
  id: 'email-address',
};

const View: React.FC = () => {
  const {
    account,
    geos,
    isSingleNode,
    nodes,
    selectedNode,
    nodeIsLoading,
    nodePackages,
    onNodeChange,
    onNodeFilterChange,
    packages,
    programs,
    anyLoading,
  } = useContext(Context);
  const { isIframe } = useContext(UIContext);
  const currentUser = useUser();
  const { hierarchy_present, segmentation_enabled } = account || {};
  const trackEvent = useTrackEvent();
  const [selection, setSelection] = useState();
  const [hasAddonsAddedToPackage, setHasAddonsAddedToPackage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [basePackage, setBasePackage] = useState<any>(
    selectedPackage && mapPackage(selectedPackage),
  );
  const [emails, setEmails] = useState([]);
  const [emailsLength, setEmailsLength] = useState(0);
  const [geoError, setGeoError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const { call, result } = useCreateCandidate();
  const [showAddScreenings, setShowAddScreenings] = useState(false);
  const [showInternationalPackages, setShowInternationalPackages] =
    useState<boolean>(false);
  const [filteredPackages, setFilteredPackages] = useState<FilteredPackages>(
    filterPackages(packages),
  );
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedState, setSelectedState] = useState('');
  const [selectedGeo, setSelectedGeo] = useState<Geo>();
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [addonsAdded, setAddonsAdded] = useState(false);
  const [addonsPrice, setAddonsPrice] = useState();
  const [focusedPackageOrder, setFocusedPackageOrder] = useState(false);
  const [addOnsShownOnce, setAddOnsShownOnce] = useState(false);
  const onFocus = useCallback(
    () => setFocusedPackageOrder(true),
    [setFocusedPackageOrder],
  );
  const onBlur = useCallback(
    () => setFocusedPackageOrder(false),
    [setFocusedPackageOrder],
  );
  const onWindowBlur = useCallback(() => {
    /*
        chrome seemes to fire both a blur and a beforeunload when the tab/window is closed
        so this sleep and subsequent conditional check is to make sure that PAGE_LEFT events
        aren't tracked twice in chrome when closing the tab/window.
    */
    sleep(1500);
    if (window.closed) return;
    trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.PAGE_LEFT, {
      'Left Method': 'Left Tab',
    });
  }, [currentUser, trackEvent]);
  const onWindowClose = useCallback(() => {
    trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.PAGE_LEFT, {
      'Left Method': 'Close Window',
    });
  }, [currentUser, trackEvent]);

  const dispatch = useDispatch();
  const btnText = result.isLoading ? 'Processing...' : 'Send Invitations';
  const textFieldDisabledColor = colors.uiGrey500;
  const inlineNotificationRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (geos?.length && !selectedGeo) {
      const usGeo = geos.filter((g: Geo) => g.country === 'US')[0];
      usGeo && setSelectedGeo(usGeo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geos]);

  useEffect(() => {
    try {
      (window as any)?.pendo?.loadGuides();
    } catch (err) {
      // this error is not visible to the user, but helps us know when
      // pendo is not loading correctly.
      Sentry.captureException(err);
    }
  }, [basePackage]);

  const submit = useCallback(
    () =>
      create({
        selection,
        emails,
        account,
        call,
        setGeoError,
        setPermissionError,
        currentUser,
      }),
    [selection, emails, account, call, currentUser],
  );

  const toggleShowAddScreenings = useCallback(
    () => setShowAddScreenings(prev => !prev),
    [],
  );

  useEffect(() => {
    geoError && dispatch(toastError('Geo is required.'));
  }, [geoError, dispatch]);

  useEffect(() => {
    permissionError && dispatch(toastError('Account Unauthorized.'));
  }, [permissionError, dispatch]);

  useEffect(() => {
    result.isSuccess && setEmails([]);
    result.isSuccess && setEmailsLength(0);
    result.isSuccess &&
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.INVITATION_SENT,
        {
          'Number Of Candidate Email Entered': emails.length,
          'Number Of Candidate Phone Entered': 0,
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess]);

  useEffect(() => {
    setFilteredPackages(filterPackages(packages));
  }, [packages]);

  useEffect(() => {
    setShowInternationalPackages(isInternationalCountry(selectedGeo?.country));
  }, [selectedGeo]);

  useEffect(() => {
    setShowInternationalPackages(isInternationalCountry(selectedCountry));
  }, [selectedCountry]);

  useEffect(() => {
    if (inlineNotificationRef.current) {
      inlineNotificationRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [addonsAdded]);

  useEffect(() => {
    if (emails.length > 0) {
      if (emailsLength < emails.length) {
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.CANDIDATES_EMAIL_ENTERED,
        );
      }
      setEmailsLength(emails.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  useEffect(() => {
    if (isIframe) {
      postMessageToDashboard({
        messageId: 'focus_iFrame',
      });
    } else {
      window.focus();
    }
    window.addEventListener('blur', onWindowBlur);
    window.addEventListener('beforeunload', onWindowClose);

    return () => {
      window.removeEventListener('blur', onWindowBlur);
      window.removeEventListener('beforeunload', onWindowClose);
    };
  }, [isIframe, onWindowBlur, onWindowClose]);

  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;
  const washingtonConsentEnabled =
    useFlag(WASHINGTON_CONSENT_FLAG_KEY)?.variantKey === 'true' || false;
  const screeningListProps = {
    showPriceFlag:
      accountHasPermission(currentUser, 'show_package_price') &&
      currentUser?.account?.package_price_state !== 'disabled via partner',
    packagePrice: addonsAdded ? addonsPrice : basePackage?.price,
  };

  const trackCertificationButtonClick = useCallback(() => {
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.CERTIFICATION_BUTTON_CLICKED,
    );
  }, [currentUser, trackEvent]);

  const onGeoChange = useCallback(
    (geo: Geo) => {
      if (geo) {
        !segmentation_enabled &&
          trackEvent(
            currentUser,
            ORDER_BACKGROUND_CHECK_EVENT_NAMES.COUNTRY_SELECTED,
            {
              Country: geo.country!,
            },
          );
        setSelectedGeo(geo);
      }
    },
    [currentUser, segmentation_enabled, trackEvent],
  );

  const onCountryChange = useCallback(
    (country: string) => {
      if (country) {
        setSelectedCountry(country);
        segmentation_enabled &&
          trackEvent(
            currentUser,
            ORDER_BACKGROUND_CHECK_EVENT_NAMES.COUNTRY_SELECTED,
            {
              Country: country,
            },
          );
      }
    },
    [currentUser, segmentation_enabled, trackEvent],
  );

  const onStateChange = useCallback(
    (state: any) => {
      if (state) {
        setSelectedState(state);
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.STATE_SELECTED,
          {
            State: state.name,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  const onCityChange = useCallback(
    (city: any) => {
      if (city) {
        setSelectedCity(city);
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.CITY_SELECTED,
          {
            City: city.name,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  const onWAConsentChange = useCallback(() => {
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.WASHINGTON_CONSENT_SELECTED,
    );
  }, [currentUser, trackEvent]);

  const onPermissiblePurposeChange = useCallback(
    (purpose: any) => {
      if (purpose) {
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.PERMISSIBLE_PURPOSE_SELECTED,
          {
            'Permissible Purpose': purpose,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  const handleAddonsPackageSelection = useCallback((packageData: any) => {
    if (packageData) {
      setSelectedPackage(packageData);
      setHasAddonsAddedToPackage(true);
    }
  }, []);

  const handlePackageChange = useCallback(
    (item: any) => {
      if (item) {
        setSelectedPackage(item);
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.PACKAGE_SELECTED,
          {
            Package: item.name,
          },
        );
        setBasePackage(mapPackage(item));
      } else {
        setHasAddonsAddedToPackage(false);
      }
    },
    [currentUser, trackEvent],
  );

  const handleProgramChange = useCallback(
    (program: any) => {
      if (program) {
        trackEvent(
          currentUser,
          ORDER_BACKGROUND_CHECK_EVENT_NAMES.PROGRAM_SELECTED,
          {
            Program: program.name,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  const autoShowAddons =
    useFlag(AUTO_SHOW_ADDONS_FLAG_KEY)?.variantKey === 'true';

  const addInternationalScreeningsEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';

  const hasManageOrderAddOnsPermission = hasPermission(
    currentUser,
    'manage_order_add_ons',
  );

  useEffect(() => {
    if (
      !hasAddonsAddedToPackage &&
      basePackage &&
      autoShowAddons &&
      !focusedPackageOrder &&
      hasManageOrderAddOnsPermission
    ) {
      const { international_only: internationalPackage } = basePackage;

      if (!addOnsShownOnce) {
        if (internationalPackage) {
          if (addInternationalScreeningsEnabled) {
            toggleShowAddScreenings();
            setAddOnsShownOnce(true);
          }
        } else {
          toggleShowAddScreenings();
          setAddOnsShownOnce(true);
        }
      }
    }
  }, [
    autoShowAddons,
    addInternationalScreeningsEnabled,
    addOnsShownOnce,
    focusedPackageOrder,
    selectedPackage,
    hasAddonsAddedToPackage,
    basePackage,
    toggleShowAddScreenings,
    hasManageOrderAddOnsPermission,
  ]);

  const reloadPage = useCallback(() => {
    trackEvent(
      currentUser,
      ORDER_BACKGROUND_CHECK_EVENT_NAMES.START_OVER_CLICKED,
    );
    window.location.reload();
  }, [currentUser, trackEvent]);

  useEffect(() => {
    if (showAddScreenings) {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.CUSTOMIZE_ADD_ONS_VIEWED,
      );
    }
  }, [currentUser, showAddScreenings, trackEvent]);

  const trackLearnMore = useCallback(
    () =>
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.LEARN_MORE_BUTTON_SELECTED,
      ),
    [currentUser, trackEvent],
  );
  const showAddScreeningsClick = useCallback(
    () => setShowAddScreenings(true),
    [setShowAddScreenings],
  );

  const textFieldProps = {
    disabled: addonsAdded,
    style: { WebkitTextFillColor: addonsAdded && textFieldDisabledColor },
  };

  const workLocationProps = {
    disabled: (hierarchy_present && !selectedNode) || addonsAdded,
  };

  const countrySelectProps = {
    ...textFieldProps,
    ...workLocationProps,
    filterOnSelectedItem: false,
  };

  const geoSelectProps = {
    ...textFieldProps,
    filterOnSelectedItem: false,
  };

  return (
    <div data-testid='new-invitations-container'>
      {anyLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Header />
          <Buffer>
            {basePackage && !hasAddonsAddedToPackage && (
              <Flag flagKey={INVITE_PAGE_ADD_SCREENINGS} variantKey='enabled'>
                <Slideout isExpanded={showAddScreenings}>
                  <AddScreenings
                    currentUser={currentUser}
                    account={account}
                    setSelectedPackage={handleAddonsPackageSelection}
                    onCancel={toggleShowAddScreenings}
                    setAddonsAdded={setAddonsAdded}
                    basePackage={basePackage as any}
                    AddScreeningsList={
                      basePackage.international_only
                        ? InternationalAddScreeningsList
                        : DomesticAddScreeningsList
                    }
                    AddScreeningsSummary={
                      basePackage.international_only
                        ? InternationalAddScreeningsSummary
                        : DomesticAddScreeningsSummary
                    }
                    Note={basePackage.international_only && InternationalNote}
                    setAddonsPrice={setAddonsPrice}
                  />
                </Slideout>
              </Flag>
            )}
            <div onFocus={onFocus} onBlur={onBlur}>
              {addonsAdded && (
                <AddonsInlineNotification
                  onActionButtonClick={reloadPage}
                  actionButtonLabel='Start over'
                  inline='classic'
                  kind='info'
                  title='Need to change your add-ons?'
                  subtitle="Add-ons can't be changed once a package is saved. If you want to choose new add-ons, start over to select another package or create a new one."
                  hasFocus={false}
                />
              )}
              <M.PackageOrder
                container='default'
                onChange={setSelection}
                nodes={nodes}
                geos={geos}
                selectedGeo={selectedGeo}
                programs={programs}
                packages={
                  showInternationalPackages
                    ? filteredPackages.international
                    : filteredPackages.domestic
                }
                onPackageChange={handlePackageChange}
                nodeSelectProps={textFieldProps}
                geoSelectProps={geoSelectProps}
                programSelectProps={textFieldProps}
                packageSelectProps={textFieldProps}
                onProgramChange={handleProgramChange}
                nodePackages={nodePackages}
                nodeIsLoading={nodeIsLoading}
                isSingleNode={isSingleNode}
                selectedNode={selectedNode}
                onNodeChange={onNodeChange}
                onNodeFilterChange={onNodeFilterChange}
                account={account}
                appEnv={ENV}
                selectedPackage={selectedPackage}
                selectedCountry={selectedCountry}
                selectedState={selectedState}
                selectedCity={selectedCity}
                workLocationSelectProps={{
                  withPr,
                  countrySelectProps,
                  stateSelectProps: { ...textFieldProps, ...workLocationProps },
                  citySelectProps: { ...textFieldProps, ...workLocationProps },
                }}
                isWashingtonConsentEnabled={washingtonConsentEnabled}
                alwaysShowCertification
                screeninglistProps={screeningListProps}
                addScreeningsButton={
                  !hasAddonsAddedToPackage &&
                  basePackage &&
                  hasManageOrderAddOnsPermission && (
                    <Flag
                      flagKey={INVITE_PAGE_ADD_SCREENINGS}
                      variantKey='enabled'
                    >
                      <AddScreeningsButton onClick={showAddScreeningsClick} />
                    </Flag>
                  )
                }
                onWAConsentChange={onWAConsentChange}
                onPermissiblePurposeChange={onPermissiblePurposeChange}
                onGeoChange={onGeoChange}
                onCountryChange={onCountryChange}
                onStateChange={onStateChange}
                onCityChange={onCityChange}
                trackCertificationButtonClick={trackCertificationButtonClick}
              >
                {selection && (
                  // @ts-ignore
                  <Wrapper>
                    <h3 style={{ color: colors.uiTextPrimaryLight }}>
                      Email Addresses (max {MAX_BULK_INVITATIONS})
                    </h3>
                    <M.TagsInput
                      onlyUnique
                      addOnBlur
                      addOnPaste
                      addKeys={addKeys}
                      inputProps={inputProps}
                      maxTags={MAX_BULK_INVITATIONS}
                      onChange={setEmails}
                      pasteSplit={pasteSplit}
                      tags={emails}
                      validationRegex={emailValidationRegex}
                    />
                    {MAX_BULK_INVITATIONS > 25 && result.isLoading && (
                      <ProcessingText>
                        <Icon icon='Warning' size='16' />
                        Invitations are processing. Please do not close your
                        browser.
                      </ProcessingText>
                    )}
                    <Button
                      onClick={submit}
                      disabled={result.isLoading || !emails.length}
                      style={{ marginLeft: 0, marginTop: '1.5rem' }}
                      id='send-invitations-button'
                    >
                      {btnText}
                    </Button>
                  </Wrapper>
                )}
              </M.PackageOrder>
            </div>
            <AnchorForScrollAfterAddonsAddition ref={inlineNotificationRef} />
            <span>* Additional third-party fees may apply. </span>
            <M.Link
              style={{ color: colors.uiNavy600 }}
              href='https://checkr.com/pricing/pass-through-fees'
              target='_blank'
              rel='noreferrer'
              onClick={trackLearnMore}
            >
              Learn More
            </M.Link>
          </Buffer>
        </>
      )}
    </div>
  );
};

export default View;
