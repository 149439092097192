/* eslint-disable no-debugger, guard-for-in */
import { useEffect, useContext } from 'react';
import pick from 'lodash/pick';
import { GenericObject } from '@dashboard-experience/utils';
import { PARAMS } from 'components/Search';
import UIContext from 'context/UI';
import { useHistory } from 'react-router-dom';

const useSearchURLParams = (filters: GenericObject) => {
  // TODO: remove after cutover
  const { isIframe, isStandaloneIframe } = useContext(UIContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const history = useHistory();

  useEffect(() => {
    if (!isStandaloneIframe && !isIframe) {
      const query = pick(filters, PARAMS.whitelisted());

      for (const key in query) {
        const value = query[key];
        const hasValue = Array.isArray(value)
          ? Boolean(value.length)
          : Boolean(value);
        if (hasValue) {
          urlParams.set(key, value);
        } else {
          urlParams.delete(key);
        }
      }

      history.replace({
        pathname: window.location.pathname,
        search: decodeURIComponent(urlParams.toString()),
      });
    }
  }, [filters]);
};

export default useSearchURLParams;
