/* eslint-disable react/no-children-prop */
import { Assess } from 'components';
import { LocationDescriptor } from 'history';
import { Namespace } from 'modules/assess/api';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useMVR } from '../../hooks';
import { NamespaceRoute, RootRoute } from '../context';
import Missing from '../missing';
import Categories from './categories';
import Rulesets from './rulesets';

const namespace = Namespace.mvr;

export const MVR: React.FC = () => {
  const enabled = useMVR();
  const match = useRouteMatch();
  const root = useContext(RootRoute);
  const { path: base, url } = match;

  const home: LocationDescriptor = {
    pathname: root,
    search: new URLSearchParams({ tab: namespace }).toString(),
  };

  if (!enabled) {
    return <Missing />;
  }

  return (
    <Assess.Route.Breadcrumb location={url} title='MVR'>
      <NamespaceRoute.Provider value={namespace}>
        <Switch>
          <Route path={`${base}/categories`} children={<Categories />} />
          <Route path={`${base}/rulesets`} children={<Rulesets />} />
          <Route path={`${base}/:missing`} children={<Missing />} />
          <Redirect to={home} />
        </Switch>
      </NamespaceRoute.Provider>
    </Assess.Route.Breadcrumb>
  );
};

export default MVR;
