import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  labelTranKey: string;
  style?: object;
};

const RemoveButton: React.FC<Props> = ({ onClick, labelTranKey, style }) => {
  const { t } = useTranslation('');
  const label = t(labelTranKey);

  return (
    <M.GridCol>
      <M.Button
        kind='tertiary'
        size='sm'
        data-testid='assess-rule-editor-remove-button'
        onClick={onClick}
        style={style}
      >
        {label}
      </M.Button>
    </M.GridCol>
  );
};

export default RemoveButton;
