import React, { useState, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import {
  useGetAdjudicationSubtypes,
  useSendCompleteReport,
} from 'modules/adjudication/api';
import { LoadingModal } from 'modules/adjudication/ui';
import { useAllowCompleteNow } from 'modules/adjudication/utilities';
import { arePropsEqual } from 'utils';

import Content from './content';

const Styled = styled.div`
  .hidden {
    display: none;
  }
`;

type Props = {
  open: boolean;
  hideModal: () => void;
};

const Modal: React.FC<Props> = ({ open, hideModal }) => {
  const { isLoading } = useGetAdjudicationSubtypes();

  return isLoading ? (
    <LoadingModal onClose={hideModal} open={open} />
  ) : (
    <CompleteModal open={open} hideModal={hideModal} />
  );
};

// eslint-disable-next-line react/display-name
const CompleteModal: React.FC<Props> = memo(({ open, hideModal }) => {
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();
  const { call } = useSendCompleteReport();

  const handleOnClick = useCallback(() => {
    call();
    hideModal();
  }, [call, hideModal]);

  const allowCompleteNow = useAllowCompleteNow();

  return (
    <Styled>
      <M.ComposedModal
        onClose={hideModal}
        open={open}
        className='actions-modal'
      >
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`${namespace}:report.actions.complete.modal.heading`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <Content setConfirm={setConfirm} confirm={confirm} />
        </M.ModalBody>
        <M.ModalFooter>
          <M.BinaryButtons
            btnLeft={{
              name: t(`${namespace}:report.actions.complete.modal.cancel`),
              onClick: hideModal,
            }}
            btnRight={{
              name: t(
                `${namespace}:report.actions.complete.modal.complete_now`,
              ),
              disabled: !confirm,
              onClick: handleOnClick,
              className: allowCompleteNow ? '' : 'hidden',
            }}
            size='sm'
          />
        </M.ModalFooter>
      </M.ComposedModal>
    </Styled>
  );
}, arePropsEqual);

export default Modal;
