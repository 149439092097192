import React, { SyntheticEvent, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  open: boolean;
  onClose?: (event?: SyntheticEvent) => void;
  onConfirm?: (event?: SyntheticEvent) => void;
};

export const Container: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:settings.unsaved_changes.title');
  const handleConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <M.ComposedModal open={open} onClose={handleClose} size='sm'>
      <M.ModalHeader closeModal={handleClose} title={modalTitle} />
      <M.ModalBody data-testid='assess-activate-dialog-content'>
        <Content />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-activate-dialog-actions'>
        <Actions onClose={handleClose} onConfirm={handleConfirm} />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
