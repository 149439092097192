import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { toastError } from 'actions';
import { accountHierarchyFilestackKey as filestackKey } from 'Constants';
import { useUser } from 'context/CurrentUser';
import { useFilestackUploadResponse } from 'hooks';
import { useValidateCsv } from 'api/hierarchy/hooks';
import SecureFilestack from 'components/SecureFilestack/secureFilestack';
import { templateNodes } from '../table-data';
import { downloadCsv, nodesToCsv } from './DownloadNodes';
import { AccountHierarchyCsv } from './types';

export type Props = {
  csv: AccountHierarchyCsv;
  setCsv: React.Dispatch<React.SetStateAction<AccountHierarchyCsv>>;
  setAsyncPost: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setIsValidating: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyledButton = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${colors.brandSlate2};
  border-radius: 6px;
  cursor: pointer;
  padding: 4px 6px;
  margin-right: 12px;
`;

const StyledIcon = styled(M.Icon)`
  border-radius: 4px;
  margin-right: 6px;
`;

const RemoveButton = styled.div`
  color: red;
  padding-left: 12px;
  padding-right: 6px;
`;

export const UploadInput: React.FC<Props> = ({
  csv,
  setCsv,
  setAsyncPost,
  setValidationErrors,
  setIsValidating,
}) => {
  const currentUser = useUser();
  const dispatchToast = useDispatch();
  const { validateCsvCall } = useValidateCsv();

  const customRender = useCallback(({ onPick }: any) => {
    return (
      <StyledButton onClick={onPick}>
        <StyledIcon icon='Upload' size='24' />
        <span>
          <strong>Upload</strong> • Max 10 MB
        </span>
      </StyledButton>
    );
  }, []);

  const onValidateCsvSuccess = (errors: string[], line_count: number) => {
    setValidationErrors(errors);
    setAsyncPost(line_count ? line_count >= 300 : false);
    setIsValidating(false);
  };

  const onValidateCsvError = (error: any) => {
    setValidationErrors([
      'Unexpected error encountered when validating your CSV',
    ]);
    setAsyncPost(false);
    setIsValidating(false);
  };

  const handleFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatchToast(toastError('Failed to upload file'));
    },
    successCallback: file => {
      const csv = {
        fileStorageKey: file.key,
        fileDisplayName: file.filename,
        fileS3URL: file.url,
      };
      setIsValidating(true);
      validateCsvCall({
        key: file.key,
        onSuccessCallback: onValidateCsvSuccess,
        onErrorCallback: onValidateCsvError,
      });
      setCsv(csv);
    },
  });

  const handleRemoveFile = useCallback(() => {
    setValidationErrors([]);
    setCsv({});
  }, [setCsv, setValidationErrors]);

  const handleDownload = useCallback(() => {
    downloadCsv(nodesToCsv(templateNodes), 'template.csv');
  }, [templateNodes]);

  // TODO: ESAAS-263: store to Account Hierarchy location
  const fileStackOptions = {
    accept: ['text/csv'],
    storeTo: { location: 's3', path: `/${currentUser.account.id}/` },
    maxFiles: 1,
    uploadInBackground: false,
    fromSources: ['local_file_system'],
    maxSize: 1024 * 1024 * 10,
    customText: {
      Files: 'File',
      'Select Files to Upload': 'Select file to upload',
      'Selected Files': 'Selected file',
      'Deselect All': 'Deselect file',
      'or Drag and Drop, Copy and Paste Files':
        'or drag and drop, copy and paste file ',
    },
  };

  return (
    <>
      {csv.fileDisplayName ? (
        <StyledButton>
          <StyledIcon
            icon='Csv'
            size='24'
            style={{
              fill: `${colors.brandSlate6}`,
              backgroundCOlor: `${colors.brandSlate1}`,
            }}
          />
          <span>
            <strong>{csv.fileDisplayName}</strong>
          </span>
          <RemoveButton onClick={handleRemoveFile}>
            <span>
              <strong>Remove</strong>
            </span>
          </RemoveButton>
        </StyledButton>
      ) : (
        <SecureFilestack
          actionOptions={fileStackOptions}
          apikey={filestackKey}
          customRender={customRender}
          onSuccess={handleFileUpload}
        />
      )}
      <StyledButton
        onClick={handleDownload}
        style={{
          border: '0px',
          color: `${colors.brandAqua4}`,
        }}
      >
        <StyledIcon
          icon='Download'
          size='24'
          style={{ fill: `${colors.brandAqua4}` }}
        />
        <strong>Download template</strong>
      </StyledButton>
    </>
  );
};

export default UploadInput;
