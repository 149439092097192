import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const MButtonRowBottomAlign = styled(M.Button)`
  margin-top: 26px !important;
  border: none !important;
  box-shadow: none !important;
`;

export default MButtonRowBottomAlign;
