import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountEverifyStatus, Candidate } from 'api/i9';
import { Prompt } from 'react-router';
import OrderI9 from './OrderI9';
import OrderI9ReviewSubmit from './OrderI9ReviewSubmit';
import SelectWorkflow from './SelectWorkflow';
import useNavigate from './hooks/useNavigate';
import EverifyBadge from './EverifyBadge';
import { Border, FlexRow, FlexRowDistribute, FlexContainer } from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const PROMPT_MESSAGE =
  'You have unsaved changes, are you sure you want to leave?';

interface FormI9PageProps {
  step: number;
  handleNextStep?: () => void;
  handlePreviousStep?: () => void;
  handleCandidateSelection?: (selectedCandidates: any) => void;
  handleSubmitDone?: () => void;
  selectedCandidates: Map<string, Candidate & { isAdded: boolean }>;
  selectedWorkflow: string;
  handleWorkflowChange: (newSelection: string) => void;
}

const ContainerContent: React.FC<FormI9PageProps> = ({
  step,
  selectedCandidates,
  selectedWorkflow,
  handleNextStep = () => {},
  handlePreviousStep = () => {},
  handleCandidateSelection = () => {},
  handleSubmitDone = () => {},
  handleWorkflowChange = () => {},
}) => {
  switch (step) {
    case 0:
      return (
        <SelectWorkflow
          onSelectWorkflowChanged={handleWorkflowChange}
          handleNextStep={handleNextStep}
        />
      );
    case 1:
      return (
        <OrderI9
          candidatesData={selectedCandidates}
          handleNextStep={handleNextStep}
          handleCandidateSelection={handleCandidateSelection}
        />
      );

    case 2:
      return (
        <OrderI9ReviewSubmit
          candidatesData={selectedCandidates}
          handlePreviousStep={handlePreviousStep}
          handleCandidateSelection={handleCandidateSelection}
          handleSubmitDone={handleSubmitDone}
          selectedWorkflow={selectedWorkflow}
        />
      );

    default:
      throw new Error('unexpected state');
  }
};

const onBeforeUnload = (event: any) => {
  const confirmationMessage = PROMPT_MESSAGE;

  (event || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
};

const FormI9Order: React.FC = () => {
  const trackEvent = useTrackEvent();

  const everifyCondition =
    (sessionStorage.getItem('everifyCondition') as AccountEverifyStatus) ||
    ('deactivated' as AccountEverifyStatus);

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ORDER_STARTED, {
      'Page Type': 'Order',
    });
  }, [trackEvent]);

  const [step, setStep] = useState(0);

  const [selectedCandidates, setSelectedCandidates] = useState<
    Map<string, Candidate & { isAdded: boolean }>
  >(new Map());

  const [selectedWorkflow, setSelectedWorkflow] = useState('');

  const handleNextStep = useCallback(() => {
    setStep(prevState => prevState + 1);
  }, [setStep]);
  const handlePreviousStep = useCallback(() => {
    setStep(prevState => prevState - 1);
  }, [setStep]);

  const handleCandidateSelection = useCallback(
    (
      candidates: {
        selectedCandidate: Candidate & { isAdded: boolean };
        checked: boolean;
      }[],
    ) => {
      setSelectedCandidates(prevValue => {
        const result = new Map(prevValue);
        candidates.forEach(({ selectedCandidate, checked }) => {
          if (checked) {
            result.set(selectedCandidate.candidate_id, selectedCandidate);
          } else {
            result.delete(selectedCandidate.candidate_id);
          }
        });
        return result;
      });
    },
    [],
  );

  const [submitDone, setSubmitDone] = useState(false);

  const shouldBlockNavigation = useMemo(
    () => selectedCandidates.size > 0 && !submitDone,
    [selectedCandidates.size, submitDone],
  );

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }
  }, [shouldBlockNavigation]);

  const handleWorkflowChange = useCallback((newSelection: string) => {
    setSelectedWorkflow(newSelection);
  }, []);

  const navigate = useNavigate();

  const handleSubmitDone = useCallback(() => {
    trackEvent(I9_EVENT_NAMES.I9_ORDER_COMPLETED, {
      'Page Type': 'Order',
    });
    setSubmitDone(true);
    navigate('/i-9');
  }, [navigate, trackEvent]);

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={PROMPT_MESSAGE} />
      <FlexContainer gap='2rem' padding='0'>
        <FlexRowDistribute alignItems='center' step={step}>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <h2>Order I-9</h2>
          </FlexRow>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <EverifyBadge step={step} status={everifyCondition} />
          </FlexRow>
        </FlexRowDistribute>
        <Border>
          <FlexContainer gap='2rem' padding='2.5rem'>
            <ContainerContent
              step={step}
              handlePreviousStep={handlePreviousStep}
              handleCandidateSelection={handleCandidateSelection}
              handleSubmitDone={handleSubmitDone}
              handleNextStep={handleNextStep}
              selectedCandidates={selectedCandidates}
              selectedWorkflow={selectedWorkflow}
              handleWorkflowChange={handleWorkflowChange}
            />
          </FlexContainer>
        </Border>
      </FlexContainer>
    </>
  );
};

export default FormI9Order;
