import React, { useCallback } from 'react';
import { NoteType } from 'modules/case-management/types';
import { useUpdateNote } from '../hooks';
import BaseNoteEditor from './BaseNoteEditor';

type PropTypes = {
  note: NoteType;
  onClose: () => void;
};

const ExistingNoteEditor = ({ note, onClose }: PropTypes) => {
  const { call: editNote } = useUpdateNote({
    noteId: note.id,
    successCallback: onClose,
  });

  const handleSave = useCallback(
    noteContent => {
      editNote({ content: noteContent });
    },
    [editNote],
  );

  return (
    <BaseNoteEditor
      content={note.content}
      onSave={handleSave}
      onClose={onClose}
    />
  );
};

export default ExistingNoteEditor;
