/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { TABLE_SORT_DIRECTION } from './enums';
import { headers } from './table-data';
import NodesTableCells from './NodesTableCells';

type Props = {
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  tiers: string[];
  setTiers: React.Dispatch<React.SetStateAction<string[]>>;
  sortInfo: any;
  setSortInfo: any;
  sortedRows: any;
};

export const NodesTable: React.FC<Props> = ({
  modified,
  setModified,
  setRows,
  tiers,
  setTiers,
  sortInfo,
  setSortInfo,
  sortedRows,
}) => {
  const { columnId: sortColumnId, direction: sortDirection } = sortInfo;

  const removeRow = (index: number) => {
    if (window.confirm('Are you sure you want to delete this node?')) {
      const { name, id } = sortedRows[index];
      if (!modified && name && id) {
        setModified(true);
      }

      const removedRowArray = sortedRows
        .filter((row: { id: any; name: any }, i: number) => {
          return row.id !== id || row.name !== name || index !== i;
        })
        .map((row: { parent_custom_id: any; cells: any }) => {
          if (row.parent_custom_id === id) {
            const newCell = [
              { id: 'id', value: row.cells[0].value },
              { id: 'name', value: row.cells[1].value },
              { id: 'tier', value: row.cells[2].value },
              { id: 'parent_custom_id', value: '' },
            ];
            return { ...row, parent_custom_id: '', cells: newCell };
          }
          return row;
        });

      setRows([...removedRowArray]);
    }
  };

  const handleChangeSort = useCallback(
    event => {
      const { parentNode } = event.currentTarget;
      const {
        columnId,
        sortCycle,
        sortDirection: oldDirection,
      } = parentNode.dataset;

      setSortInfo({ columnId, sortCycle, oldDirection });
    },
    [setSortInfo],
  );

  return (
    <M.TableContainer>
      <M.Table>
        <M.TableHead>
          <M.TableRow>
            {headers.map(({ id: columnId, sortCycle, header }) => {
              const sortDirectionForThisCell =
                sortCycle &&
                (columnId === sortColumnId
                  ? sortDirection
                  : TABLE_SORT_DIRECTION.NONE);
              return (
                <M.TableHeader
                  data-testid='account-hierarchy-table-header'
                  key={columnId}
                  isSortable={Boolean(sortCycle)}
                  isSortHeader={sortCycle && columnId === sortColumnId}
                  sortDirection={sortDirectionForThisCell}
                  data-column-id={columnId}
                  data-sort-cycle={sortCycle}
                  data-sort-direction={sortDirectionForThisCell}
                  onClick={handleChangeSort}
                  style={{ width: '60%' }}
                >
                  {header}
                </M.TableHeader>
              );
            })}
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {sortedRows.map((row: any, rowIndex: number) => {
            return (
              <M.TableRow
                style={{
                  height: '100px',
                }}
                data-testid='account-hierarchy-table-body-row'
              >
                {row.cells.map((cell: any) => (
                  <NodesTableCells
                    cell={cell}
                    rowIndex={rowIndex}
                    setModified={setModified}
                    sortedRows={sortedRows}
                    setSortInfo={setSortInfo}
                    setRows={setRows}
                    tiers={tiers}
                    setTiers={setTiers}
                  />
                ))}
                <M.TableCell key={rowIndex}>
                  {sortedRows?.length > 1 && (
                    <M.Button
                      kind='danger'
                      data-testid='removeRow'
                      className='btn btn-danger pull-right'
                      onClick={() => removeRow(rowIndex)}
                    >
                      <M.Icon icon='TrashCan' />
                    </M.Button>
                  )}
                </M.TableCell>
              </M.TableRow>
            );
          })}
        </M.TableBody>
      </M.Table>
    </M.TableContainer>
  );
};

export default NodesTable;
