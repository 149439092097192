import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { ADJUDICATION_KINDS, GenericObject } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useSetAdjudication } from 'modules/adjudication/api';
import {
  useGetPostSubtypePayload,
  Subtype,
} from 'modules/adjudication/utilities';
import { ModalFooter } from 'modules/adjudication/ui/common';
import { useGetReport, useUpgradeReport } from 'api/reports';
import { useProgressivePackages } from 'api/packages/hooks';
import { Package } from 'types';
import styled from 'styled-components';

export type Props = {
  selection: Subtype | undefined;
  onClose: () => void;
  transitionToScreen: (screen: string) => void;
};

const Message = styled.p`
  margin-bottom: 1rem !important;
  white-space: nowrap;
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
`;

const Label = styled.label`
  font-weight: bold;
  margin-top: 1rem;
`;

const Select = styled(M.Select)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 94.5%;
`;

const Progressive: React.FC<Props> = ({
  selection,
  onClose,
  transitionToScreen,
}) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:report.actions.progressive.progressive_modal`;
  const cancel = t(`${basePath}.cancel`);
  const nextStep = t(`${basePath}.progress`);
  const message = t(`${basePath}.message`);
  const engage = t(`${basePath}.engage`);
  const header = t(`${basePath}.header`);
  const selectTooltip = t(`${basePath}.select-tooltip`);

  const [selected, setSelected] = useState({} as Package);

  const { candidateId }: GenericObject = useParams();
  const { report } = useGetReport();
  const payload = useGetPostSubtypePayload({
    subtypeKind: ADJUDICATION_KINDS.ENGAGED,
    selection,
  });
  const { call } = useSetAdjudication(report?.id, candidateId);
  const close = {
    name: cancel,
    onClick: onClose,
  };
  const engageCandidate = {
    name: engage,
    onClick: () => {
      call(payload);
      onClose();
    },
  };
  const next = {
    name: nextStep,
    onClick: async () => {
      await upgradeReportCall();
      onClose();
    },
  };

  const packages = useProgressivePackages(report.id);

  useEffect(() => {
    const nextPackageSlug = report?.progressive_info?.next_package_slug;
    const packageData = packages?.data;

    if (nextPackageSlug && packageData?.length) {
      const pkg = packageData?.find(
        (pack: Package) => pack.slug === nextPackageSlug,
      );
      setSelected(pkg as Package);
    }
  }, [packages?.data, report?.progressive_info?.next_package_slug]);

  const { upgradeReportCall } = useUpgradeReport(report.id, selected?.slug);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const pkg = packages?.data?.find(
        (pkg: Package) => pkg.slug === e.target.value,
      );
      setSelected(pkg as Package);
    },
    [packages?.data],
  );

  let preConfiguredPackage = report?.progressive_info?.next_package_slug;
  if (
    !preConfiguredPackage &&
    packages &&
    packages.data &&
    packages.data.length > 0
  ) {
    preConfiguredPackage = packages.data[0].slug;
  }

  return (
    <>
      <h6>{header}</h6>
      <Message>{message}</Message>
      <Label htmlFor='upgrade-modal-select'>{selectTooltip}</Label>
      <Select
        id='upgrade-modal-select'
        noLabel
        defaultValue={preConfiguredPackage}
        onChange={handleChange}
        value={selected?.slug}
      >
        {packages?.data?.map((pkg: Package) => (
          <M.Select.Item key={pkg.id} value={pkg.slug} text={pkg.name} />
        ))}
      </Select>
      <ModalFooter back={close} next={next} close={engageCandidate} />
    </>
  );
};

export default Progressive;
