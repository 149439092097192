import React from 'react';
import { sortBy } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import { PackageName, PackagesTableCell } from './PackagesStyledComponents';
import CertificationClickComponent from './CertificationClickComponent';
import ScreeningName from './ScreeningName';
import { useUser } from '../../../context/CurrentUser';
import { Package } from '../../../types';

type Props = {
  pkg: Package;
};

const PackagesTableRow: React.FC<Props> = ({ pkg }) => {
  const {
    account: { segmentation_enabled, allow_dashboard_report_ordering },
  } = useUser();

  const showInvitationLink =
    !segmentation_enabled && allow_dashboard_report_ordering;

  return (
    <M.TableRow>
      <PackagesTableCell className='package-name-cell'>
        <PackageName>{pkg.name}</PackageName>
        {showInvitationLink && pkg.apply_url && (
          <CertificationClickComponent packageURL={pkg.apply_url} />
        )}
      </PackagesTableCell>
      <PackagesTableCell>
        {pkg.program_name ? <>{pkg.program_name}</> : <>N/A</>}
      </PackagesTableCell>
      <PackagesTableCell>
        {pkg.screenings && pkg.screenings.length > 0 && (
          <ul>
            {sortBy(pkg.screenings, screening => screening.type).map(
              screening => (
                <ScreeningName
                  screening={screening}
                  key={`${screening.type}_${Math.random()}`}
                />
              ),
            )}
          </ul>
        )}
      </PackagesTableCell>
    </M.TableRow>
  );
};

export default PackagesTableRow;
