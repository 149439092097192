import React, { useCallback, useEffect, useState } from 'react';
import { parse, unparse } from 'papaparse';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { toastError } from 'actions';
import { downloadBlobAsFile } from 'utils/APIClient';
import { useDispatch } from 'react-redux';
import { actionTypes, useOrderBackgroundCheckContext } from '../Context';
import FileUploadInput from './FileUploadInput';
import { ManualBulkUploadRow } from './types';
import { CsvHeaders } from './constants';
import { showLinesWithError, getRowsWithErrors } from './helper';

export const DownloadLink = styled(M.Link)`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
`;

const GridCol = styled(M.GridCol)`
  padding: 0;
`;

const InlineNotification = styled(M.InlineNotification)`
  p {
    margin-bottom: 0 !important;
  }
`;

const Upload: React.FC = () => {
  const {
    state: { manualBulkUploadData },
    dispatch,
  } = useOrderBackgroundCheckContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const dispatchToast = useDispatch();

  useEffect(() => {
    if (manualBulkUploadData?.isValid) {
      setIsButtonDisabled(false);
    }
  }, [manualBulkUploadData]);

  useEffect(() => {
    dispatch({
      type: actionTypes.CONTINUE_BUTTON_DISABLED,
      payload: { continueButtonDisabled: isButtonDisabled },
    });
  }, [dispatch, isButtonDisabled]);

  const changeHandler = useCallback(file => {
    if (!file) return;

    parseFile(file);
  }, []);

  const isCsvValidFormat = (headings: string[]) => {
    let isValid = true;
    CsvHeaders.forEach(heading => {
      if (!headings.includes(heading)) isValid = false;
    });

    return isValid;
  };

  const parseFile = (file: File) => {
    parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: result => {
        const data = result.data as ManualBulkUploadRow[];

        if (data.length === 0) {
          dispatchToast(
            toastError(
              '',
              'Your CSV file upload is unsuccessful. Please ensure the CSV file has data before uploading.',
            ),
          );
          return;
        }

        if (data.length > 300) {
          dispatchToast(
            toastError(
              '',
              'Maximum number of rows exceeded. Upload a CSV with 300 or fewer rows.',
            ),
          );
          return;
        }

        if (!isCsvValidFormat(Object.keys(data[0]))) {
          dispatchToast(
            toastError(
              '',
              'The CSV file contains invalid columns. Please ensure the CSV file has the correct column headers or use template before uploading.',
            ),
          );
          return;
        }

        if (getRowsWithErrors(data).length === data.length) {
          dispatchToast(
            toastError(
              '',
              'Every row in the CSV validation has failed. Please ensure the CSV file has correct data before uploading.',
            ),
          );
          return;
        }

        setIsButtonDisabled(false);

        dispatch({
          type: actionTypes.SET_MANUAL_BULK_UPLOAD_DATA,
          payload: {
            manualBulkUploadData: {
              fileName: file?.name,
              fileSize: file?.size,
              parsedData: data,
              rowsWithError: getRowsWithErrors(data),
              isValid: getRowsWithErrors(data).length === 0,
            },
          },
        });
        dispatch({
          type: actionTypes.ADD_EMAILS,
          payload: { emails: data.map(row => row.email) },
        });
      },
    });
  };

  const onDelete = useCallback(() => {
    dispatch({
      type: actionTypes.SET_MANUAL_BULK_UPLOAD_DATA,
      payload: { manualBulkUploadData: {} },
    });
    setIsButtonDisabled(true);
  }, [dispatch]);

  const viewList = useCallback(
    (e: any) => {
      e.preventDefault();
      const { parsedData, fileName, rowsWithError } = manualBulkUploadData;
      const errors: ManualBulkUploadRow[] = [];
      rowsWithError.forEach(rowIndex => {
        errors.push(parsedData[rowIndex - 1]);
      });

      const blob = new Blob([unparse(errors)]);
      downloadBlobAsFile({
        data: blob,
        headers: {
          'content-type': 'application/CSV',
        },
        filename: `${fileName.slice(0, -4)}-errors.csv`,
      });
    },
    [manualBulkUploadData],
  );

  return (
    <>
      <M.Grid>
        <M.GridRow>
          <GridCol lg={8}>
            <FileUploadInput
              onUpload={changeHandler}
              onDelete={onDelete}
              manualBulkUploadData={manualBulkUploadData}
            />
          </GridCol>
        </M.GridRow>
      </M.Grid>
      {manualBulkUploadData.rowsWithError?.length > 0 && (
        <InlineNotification kind='error' hideCloseButton>
          <p>
            <strong>Formatting errors in rows</strong>{' '}
            {showLinesWithError(manualBulkUploadData.rowsWithError)}. Correct
            these errors and try again or continue your order without these
            rows.
          </p>
          <DownloadLink onClick={viewList} download>
            View complete list
          </DownloadLink>
        </InlineNotification>
      )}
    </>
  );
};

export default Upload;
