import React, { ReactElement } from 'react';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { BasePackage } from '../../types';
import IconCheckmark from '../BasePackages/IconCheckmark';

import {
  Container,
  Header,
  MostPopular,
  PackageCardBody,
  PriceContainer,
  ReportText,
  PackageName,
  PackageDescription,
  SelectedCheckContainer,
} from './styles';

type Props = {
  name: string;
  description: string;
  price: number;
  mostPopular?: boolean;
  selected?: boolean;
  children?: ReactElement;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  packageType: BasePackage;
};

const PackageCard = ({
  name,
  description,
  packageType,
  price,
  mostPopular = false,
  children,
  onClick = () => {},
  selected = false,
}: Props) => {
  const darkText =
    packageType === 'checkrdirect_basic_plus_criminal' ||
    packageType === 'checkrdirect_international_basic_plus';

  return (
    <Container
      className={`package-card ${mostPopular ? 'most-popular' : ''}`}
      data-testid={`${name}-package-card-container`}
      selected={selected}
      onClick={onClick}
    >
      {mostPopular && <MostPopular>Most Popular</MostPopular>}
      <Header
        data-testid='package-card-header'
        packageType={packageType}
        selected={selected}
      >
        {selected && (
          <SelectedCheckContainer data-testid='selected-check-icon'>
            <IconCheckmark iconType={packageType} />
          </SelectedCheckContainer>
        )}
        <PackageName dark={darkText}>{name}</PackageName>
        <PackageDescription dark={darkText}>{description}</PackageDescription>
      </Header>
      <PackageCardBody>
        <PriceContainer>
          <h3 className='mb-0'>{getDollarAmount(price.toString())}</h3>{' '}
          <ReportText>/ report*</ReportText>
        </PriceContainer>
        {children}
      </PackageCardBody>
    </Container>
  );
};

export default PackageCard;
