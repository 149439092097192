import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { PathName, usePath } from 'modules/assess/ui/router/mvr/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const tkey = 'assess:mvr.category-sets.collection.search.table.item.draft';

type Props = {
  item?: Entity;
};

export const ItemDraft: React.FC<Props> = ({ item }) => {
  const path = usePath(PathName.categories, item?.id, 'draft');
  const { t } = useTranslation('assess');

  if (!item || !item.account_id) {
    return null;
  }

  const draft = item.versions?.find(v => v.draft);
  const action = draft ? 'view' : 'create';
  const text = t(`${tkey}.${action}`);

  return <Link to={path}>{text}</Link>;
};

export default ItemDraft;
