import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';

import { ANALYTICS_TABS_CONFIG, IS_IN_IFRAME } from 'Constants';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';

import AnalyticsIframe from './AnalyticsIframe';

type AnalyticsProps = { initialCategory: string };

const AnalyticsTabs: React.FC<AnalyticsProps> = ({ initialCategory }) => {
  const [category, setCategory] = useState(initialCategory);

  const [currentAction, setCurrentAction] = useState('');

  const currentUser = useUser();

  const {
    account: { analytics_dashboards: accountAnalyticsConfig },
  } = currentUser;

  const { contextId } = useContext(UIContext);

  const history = useHistory();

  const tabsConfig = ANALYTICS_TABS_CONFIG.filter(
    config => accountAnalyticsConfig[config.id],
  );

  /**
   * @name getCategoryIndex
   * @function
   * @memberOf AnalyticsContainer
   * @description The route param category is a string, this helper finds the matching config object's index in the config array
   * @returns {int}
   */
  const getCategoryIndex = () => {
    const categoryIndex = tabsConfig.findIndex(
      config => config.lookerName === category,
    );
    return categoryIndex >= 0 ? categoryIndex : 0;
  };

  const [selectedIndex, setSelectedIndex] = useState(getCategoryIndex());

  /**
   * @name handleChange
   * @function
   * @memberOf AnalyticsContainer
   * @description Callback method called when the user selects a tab
   * @param {int} newIndex - The index of the new tab
   */
  const handleChange = useCallback(
    (e: { selectedIndex: number }) => {
      const newCategory = tabsConfig[e.selectedIndex].lookerName;
      setCurrentAction('PUSH');
      setCategory(newCategory);
    },
    [tabsConfig],
  );

  useEffect(() => {
    setCurrentAction('POP');
    setCategory(initialCategory);
  }, [initialCategory]);

  useEffect(() => {
    const categoryIndex = getCategoryIndex();
    updateUrl(categoryIndex);
  }, [category]);

  const updateUrl = (newIndex: number) => {
    setSelectedIndex(newIndex);
    if (currentAction === 'PUSH') {
      if (IS_IN_IFRAME && contextId) {
        updateParentWindowUrl({
          contextId,
          path: tabsConfig[newIndex].url,
          reload: false,
        });
      } else {
        history.push(tabsConfig[newIndex].url);
      }
    }
  };

  return (
    <M.Tabs selectedIndex={selectedIndex} onChange={handleChange}>
      <M.TabList data-testid='analytics-tabs'>
        {tabsConfig.map(tab => (
          <M.Tab key={tab.id} id={tab.id}>
            {tab.title}
          </M.Tab>
        ))}
      </M.TabList>
      <M.TabPanels>
        {tabsConfig.map((tab, i) => (
          <M.TabPanel key={tab.id} data-testid='analytics-tab'>
            {selectedIndex === i && (
              <AnalyticsIframe
                lookerName={tabsConfig[selectedIndex].lookerName}
              />
            )}
          </M.TabPanel>
        ))}
      </M.TabPanels>
    </M.Tabs>
  );
};

export default AnalyticsTabs;
