import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Kind } from 'modules/assess/models/entity';
import { ID } from 'modules/id';
import styled from 'styled-components';

export type Type = React.FC<Props>;

export type Props = {
  id?: ID;
  kind?: Kind;
  style?: CSSProperties;
  time?: Date | string;
  user?: string | User;
  version?: number;
};

export type User = {
  name?: string;
  email?: string;
};

const Container = styled.span`
  :not(:first-of-type) {
    filter: opacity(0.5);
  }
`;

export const StatusByline: Type = ({
  kind,
  style,
  time,
  user,
  id,
  version,
}) => {
  const date = typeof time === 'string' ? new Date(time) : time;

  const qualifier = useQualifier(kind);
  const byline = useByline(date, user);
  const idDisplay = id ? `ID: ${id}, ` : '';
  const versionDisplay = version ? `Version: ${version}, ` : '';

  return (
    <Container id='assess-ui-common-entity-status-byline'>
      <span>{idDisplay}</span>
      <span>{versionDisplay}</span>
      <span>{qualifier}</span>
      <span>{`: ${byline}`}</span>
    </Container>
  );
};

function useQualifier(kind?: Kind) {
  const { t } = useTranslation('assess');
  return t(`assess:entity.status.action.${kind}`, '');
}

function useByline(time?: Date, user?: string | User) {
  const { t } = useTranslation();
  const actor =
    user && typeof user === 'object' ? user.name || user.email : user;
  const by = actor ? t('prepositions.by.actor', { actor }) : '';
  return `${time?.toLocaleString() || ''} ${by}`;
}

export default StatusByline;
