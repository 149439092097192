import { flatMap, startCase, compact } from 'lodash';
import { formattedDate } from '@dashboard-experience/utils';
import { TFunction } from 'i18next';

import { AdverseAction } from '../../../data/adverse-actions';
import {
  AdverseActionEventTypes,
  EventView,
  AdverseActionEvent,
} from '../../../data/events';

const containsAdverseActionEvent = (event_type: string) =>
  Object.values<string>(AdverseActionEventTypes).includes(event_type);

const byline = (t: TFunction, event: AdverseActionEvent) => {
  const { metadata, event_type } = event;
  const user = metadata?.user || metadata?.canceled_by || metadata?.sent_by;
  if (!user || !event_type) return undefined;
  return t('adjudication:report.events.byline', {
    type: startCase(event_type),
    user,
  });
};

const notice = (event: AdverseActionEvent) => {
  const { notice } = event;
  if (!notice) return undefined;
  const match = notice.match(/pre|post/);
  return match?.length ? match[0] : notice;
};

const text = (t: TFunction, event: AdverseActionEvent) => {
  let text = '';
  if (notice(event)) text += `${startCase(notice(event))}-`;
  text += t('adjudication:report.events.adverse_action');
  const medium = event.delivery_type ? event.delivery_type : '';
  if (medium) text += ` ${medium}`;
  return text;
};

const eventName = (eventType: string) => {
  if (eventType === AdverseActionEventTypes.DELIVERY_UNKNOWN) {
    return 'delivery error';
  }
  return eventType;
};

const getPdfFields = (
  adverseAction: AdverseAction,
  event: AdverseActionEvent,
) => {
  if (event.event_type === AdverseActionEventTypes.MANUAL) {
    event.notice = 'post';
  }

  if (!event.notice) return {};
  const prefix = `${notice(event)}_adverse_action_pdf`;

  let pdf_encrypted = false;
  let pdf_url = adverseAction[`${prefix}_url` as keyof AdverseAction];

  if (adverseAction[`${prefix}_encrypted_url` as keyof AdverseAction]) {
    pdf_encrypted = true;
    pdf_url = adverseAction[`${prefix}_encrypted_url` as keyof AdverseAction];
  }

  return {
    pdf_encrypted,
    pdf_url,
  };
};

const transformAdverseActionEvents = (
  t: TFunction,
  adverseAction: AdverseAction,
  event: AdverseActionEvent,
) => {
  const eventMode = event.metadata?.mode === 'automated' ? ' - automated ' : '';
  const result: EventView = {
    text: `${text(t, event)} ${eventName(event.event_type)}${eventMode}`,
    time: event.created_at,
    byline: byline(t, event),
    reason: event?.metadata?.reason,
    notice: notice(event),
  };

  switch (event.event_type) {
    case AdverseActionEventTypes.MANUAL: {
      Object.assign(result, getPdfFields(adverseAction, event));
      result.text = t('adjudication:report.events.manual');
      result.byline = t('adjudication:report.events.byline', {
        type: 'Resolved',
        user: event.metadata?.user,
      });
      break;
    }
    case AdverseActionEventTypes.SENT: {
      Object.assign(result, getPdfFields(adverseAction, event));
      break;
    }
    case AdverseActionEventTypes.QUEUED: {
      Object.assign(result, getPdfFields(adverseAction, event));
      break;
    }
    case AdverseActionEventTypes.SCHEDULED: {
      if (!event.metadata?.scheduled_at) break;
      result.text += `: ${formattedDate(
        event.metadata?.scheduled_at,
        'MM/DD/YYYY',
      )}`;
      break;
    }
    case AdverseActionEventTypes.DISPUTED: {
      if (!event.metadata?.post_notice_unscheduled) break;
      result.post_notice_unscheduled = event.metadata.post_notice_unscheduled;
      break;
    }
    case AdverseActionEventTypes.DELIVERY_UNKNOWN: {
      result.text = 'Delivery status unknown: adverse action email';
      result.byline =
        "Please update the candidate's email address or resend the adverse action email.";
      result.reason = undefined;
      break;
    }
    default: {
      break;
    }
  }

  return result;
};

const processAdverseActionEvents = (
  t: TFunction,
  adverseActions: AdverseAction[],
): EventView[] => {
  return compact(
    flatMap(adverseActions, (aa: AdverseAction): EventView[] | undefined => {
      if (!aa.events) return undefined;
      return aa.events
        .filter((e) => containsAdverseActionEvent(e.event_type))
        .map((e) => transformAdverseActionEvents(t, aa, e));
    }),
  );
};

export default processAdverseActionEvents;
