import React from 'react';
import * as Page from '../page';
import * as Route from '../route';
import { useNamespacedRoute } from '../router/context';
import Description from './description';
import AddButton from './add-button';
import Table from './TableContainer';
import { useAllowRulesetCreation } from '../hooks';

export type Props = {};

export const RulesetsContainer: React.FC<Props> = () => {
  const path = useNamespacedRoute('rulesets');
  const addRulesetsDisabled = !useAllowRulesetCreation();

  return (
    <Route.Breadcrumb location={path} title='Rulesets'>
      <Page.Container data-testid='assess-rulesets-container' divider={false}>
        <Page.Actions>
          <AddButton disabled={addRulesetsDisabled} />
        </Page.Actions>
        <Description />
        <Table />
      </Page.Container>
    </Route.Breadcrumb>
  );
};

export default RulesetsContainer;
