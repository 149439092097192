import React from 'react';
import { age } from 'utils/TimeUtils';
import { FieldComponentProps } from '../types';
import { MinorTag } from '../CandidateInformationStyledComponents';

const Dob: React.FC<FieldComponentProps> = ({ candidate }) => {
  const { dob } = candidate;
  const candidateAge = age(dob);

  if (!dob) return <>-</>;

  return (
    <>
      {dob}
      {candidateAge && <> ({candidateAge} years old)</>}
      {candidateAge && candidateAge < 18 && (
        <MinorTag type='red'>Minor</MinorTag>
      )}
    </>
  );
};

export default Dob;
