import React from 'react';

import { Tag } from './ExperimentalSearchFiltersStyledComponents';

const FilterCount: React.FC<{ filterCount: number }> = ({ filterCount }) =>
  filterCount > 0 ? (
    <Tag type='cool-gray' filter='true'>
      <span>
        {filterCount}
        {filterCount > 1 || filterCount === 0
          ? ' Filters Applied '
          : ' Filter Applied '}
      </span>
    </Tag>
  ) : (
    <span />
  );

export default FilterCount;
