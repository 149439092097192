import React from 'react';
import { useAddExam } from 'components/Report/Screenings/DrugHealthScreenings/ExamReorder/flow/api';
import {
  useTrackEventOnMount,
  ReorderEvent,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import Summary from './summary';
import Loading from './loading';

const Review = () => {
  const { call, result } = useAddExam();
  const { isLoading, isError, isSuccess } = result;

  useTrackEventOnMount(ReorderEvent.ModalReviewViewed);

  return (
    <>
      {!isLoading && !isError && !isSuccess && <Summary call={call} />}
      {isLoading && <Loading />}
    </>
  );
};

export default Review;
