import React, { useCallback } from 'react';
import { updateParentWindowUrl } from 'utils';
import { M } from '@dashboard-experience/mastodon';
import { useHistory } from 'react-router-dom';

const BulkActionButton = contextId => {
  const history = useHistory();

  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  const handleBulkActionClick = useCallback(
    () => navigate('bulk_action/new'),
    [navigate],
  );

  return (
    <M.Button kind='primary' size='sm' onClick={handleBulkActionClick}>
      <M.Icon icon='CloudUpload' style={{ marginRight: '0.5rem' }} />
      Start bulk action
    </M.Button>
  );
};

BulkActionButton.defaultProps = {
  contextId: null,
};

export default BulkActionButton;
