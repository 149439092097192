import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';

const postOauth = async (clientId: string) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/oauth/authorize`,
    {
      client_id: clientId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

type Props = {
  clientId: string;
};

function usePostOauth() {
  const [mutate, { data, isLoading, error }] = useMutation(
    async ({ clientId }: Props) => {
      const response = await postOauth(clientId);
      return response.data;
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default usePostOauth;
