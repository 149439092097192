import React, {
  useState,
  Fragment,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  DownloadInvoiceParams,
  useDownloadCreditMemo,
  useDownloadInvoiceFile,
  useGetInvoices,
} from 'api/billing';
import { useUser } from 'context/CurrentUser';
import { Trans, useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { IdpThinRow, LoadingScreen } from 'components';
import convertInvoiceStatuses from '../../utils/InvoiceStatusUtils';
import {
  CustomTableExpandRow,
  NoBillsYetMessage,
} from '../../components/Account/Invoices/InvoicesStyledComponents';
import { Header } from '../../components/Account/Invoices/HeaderWithTooltip';
import {
  DefaultHeader,
  HeaderWithTooltip,
  InvoicesSeeMoreFilesCell,
  InvoicesStructuredListRow,
  InvoicesTableCell,
  NoHeader,
} from '../../components/Account/Invoices';
import BillingCustomerContext from './context';
import { BillingCustomer } from '../../types/Billing';
import {
  accountHierarchyHeaders,
  defaultHeaders,
  billingEntityHeaders,
} from './InvoiceTableHeaders';

type InvoiceFile = {
  document_type: string;
};

type MemoFile = {
  id: number;
  resource_id: string;
  memo: string;
};

interface File {
  document_type: string;
  identifier: string;
  value: string;
}

const InvoicesContainer: React.FC = () => {
  const [params, setParams] = useState({ page: 1, perPage: 5 });
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation();
  const { account = {} } = useUser();
  const { downloadInvoiceCall, downloadInvoiceResult } =
    useDownloadInvoiceFile();
  const { downloadMemoCall, downloadMemoResult } = useDownloadCreditMemo();

  const { data: rowData = [], isLoading: apiLoading } = useGetInvoices(
    account?.id,
    // "unknown" class is done as a "middleman" to cast the entire data as genericObject for its use.
  ) as unknown as { data: GenericObject[]; isLoading: boolean };

  const { customerData } = useContext(BillingCustomerContext);

  const handlePaginationClick = useCallback(
    (index: number) => {
      setParams(params => ({ ...params, page: index + 1 }));
      setPageIndex(index);
    },
    [setPageIndex],
  );

  const isSuppressed = (file: InvoiceFile) => {
    const suppressed_doc_types = ['report_usage', 'invoice'];
    if (suppressed_doc_types.includes(file.document_type || '')) {
      return false;
    }
    return file;
  };

  const ahBillingEnabled = account?.account_hierarchy_billing === true;
  const billingEntityEnabled = customerData?.billing_entity_enabled === true;

  let headers;
  if (ahBillingEnabled) {
    headers = accountHierarchyHeaders;
  } else if (billingEntityEnabled) {
    headers = billingEntityHeaders;
  } else {
    headers = defaultHeaders;
  }

  const filteredInvoiceFiles = (invoice_files: any[]) =>
    invoice_files.filter(isSuppressed);

  const getInvoiceFileFields = (row: GenericObject): Array<File> =>
    filteredInvoiceFiles(row.invoice_files).map((file: InvoiceFile) => ({
      document_type: file.document_type,
      identifier: file.document_type,
      value: '',
    }));

  const getCreditMemoFileFields = (row: GenericObject): Array<File> =>
    row.applied_credit_memos.map((file: MemoFile) => ({
      document_type: 'credit_memo',
      identifier: file.resource_id,
      value: file.memo,
    }));

  const getPageCount = (itemCount: number, perPage: number) =>
    Math.ceil(itemCount / perPage);

  const paginate = (rows: GenericObject, pageIndex: number, perPage: number) =>
    rows.slice(pageIndex * perPage, pageIndex * perPage + perPage);

  const handleInvoiceDownload = useCallback(
    (e: any) => {
      e.preventDefault();

      const { id, type } =
        Object.keys(e.target.dataset).length === 0
          ? e.target.parentElement.dataset
          : e.target.dataset;
      const params: DownloadInvoiceParams = { id, type };
      downloadInvoiceCall(params);
    },
    [downloadInvoiceCall],
  );

  useEffect((): void => {
    if (downloadInvoiceResult.isSuccess) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = downloadInvoiceResult.data.url;
      a.click();
    }
  }, [downloadInvoiceResult.isSuccess, downloadInvoiceResult?.data?.url]);

  const handleMemoDownload = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = e.target.dataset;
      downloadMemoCall(id);
    },
    [downloadMemoCall],
  );

  useEffect((): void => {
    if (downloadMemoResult.isSuccess) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = downloadMemoResult.data.url;
      a.click();
    }
  }, [downloadMemoResult.isSuccess, downloadMemoResult?.data?.url]);

  const getTableContent = useCallback(
    ({ rows, headers, getHeaderProps, getRowProps, expandRow }) => {
      const pageCount = getPageCount(rows.length, params.perPage);
      const convertedRowData = convertInvoiceStatuses(rowData);
      const rowsPage = paginate(rows, pageIndex, params.perPage);
      const rowDataPage = paginate(convertedRowData, pageIndex, params.perPage);

      return (
        <M.TableContainer style={{ overflow: 'auto' }}>
          <M.Table>
            <M.TableHead className='border-top'>
              <M.TableRow>
                {headers.map((header: Header, i: number) => {
                  let headerValue;
                  if (header.name) {
                    if (header.tooltip) {
                      headerValue = (
                        <HeaderWithTooltip
                          key={header.name}
                          header={header}
                          {...getHeaderProps({ header, isSortable: true })}
                          className={header.classes || ''}
                        />
                      );
                    } else {
                      headerValue = (
                        <DefaultHeader
                          key={header.name}
                          header={header}
                          {...getHeaderProps({ header, isSortable: true })}
                          className={header.classes || ''}
                        />
                      );
                    }
                  } else {
                    headerValue = (
                      <NoHeader
                        {...getHeaderProps({ header, isSortable: true })}
                        className={header.classes || ''}
                        key={i.toString()}
                      />
                    );
                  }
                  return headerValue;
                })}
              </M.TableRow>
            </M.TableHead>
            <M.TableBody>
              {rowsPage.map(
                (
                  rowMetaData: {
                    isExpanded: boolean;
                    id: number;
                    cells: any[];
                  },
                  i: number,
                ) => {
                  // rowsPage is the metadata from the grid.  rowDataPage is the raw data from the api.
                  // Ensure rows and rowData stay in sync
                  const row = rowDataPage[i];
                  if (row.id !== rowMetaData.id) {
                    // eslint-disable-next-line no-console
                    console.error('Row and rowData not in sync!');
                  }

                  return (
                    <Fragment key={rowMetaData.id}>
                      <CustomTableExpandRow
                        {...getRowProps({ row: rowMetaData })}
                      >
                        {rowMetaData.cells.map(
                          (cell: GenericObject, j: number) => {
                            const cellProps = {
                              header: headers[j],
                              row,
                              cell,
                              customer: customerData as BillingCustomer,
                            };
                            return (
                              <InvoicesTableCell
                                handleDownload={handleInvoiceDownload}
                                key={cell.id}
                                {...cellProps}
                              />
                            );
                          },
                        )}
                        {(getInvoiceFileFields(row).length > 0 ||
                          getCreditMemoFileFields(row).length > 0) && (
                          <InvoicesSeeMoreFilesCell
                            rowMetaData={rowMetaData}
                            expandRow={expandRow}
                          />
                        )}
                      </CustomTableExpandRow>

                      {rowMetaData.isExpanded && (
                        <M.TableExpandedRow colSpan={12}>
                          <M.StructuredListWrapper style={{ marginBottom: 0 }}>
                            <M.StructuredListBody>
                              {getInvoiceFileFields(row).map((file: File) => {
                                return (
                                  <InvoicesStructuredListRow
                                    key={`structured-list-row-${file.identifier}`}
                                    docId={row.id}
                                    filename={file.value}
                                    fileType={file.document_type}
                                    translation={t}
                                    handleDownload={handleInvoiceDownload}
                                  />
                                );
                              })}
                              {getCreditMemoFileFields(row).map(
                                (file: File) => {
                                  return (
                                    <InvoicesStructuredListRow
                                      key={`structured-list-row-${file.identifier}`}
                                      docId={file.identifier}
                                      filename={file.value}
                                      fileType={file.document_type}
                                      translation={t}
                                      handleDownload={handleMemoDownload}
                                    />
                                  );
                                },
                              )}
                            </M.StructuredListBody>
                          </M.StructuredListWrapper>
                        </M.TableExpandedRow>
                      )}
                    </Fragment>
                  );
                },
              )}
            </M.TableBody>
          </M.Table>
          <M.Pagination
            pageCount={pageCount}
            onPageClick={handlePaginationClick}
            selectedIndex={pageIndex}
          />
        </M.TableContainer>
      );
    },
    [pageIndex, params.perPage, rowData],
  );

  const isLoading = Boolean(!account?.id);
  const isEmpty = rowData.length < 1;

  const getTotalAmountOwed = (invoices: GenericObject[]) => {
    return invoices.reduce((sum, invoice) => {
      return invoice.status === 'pending' ? sum + invoice.amount : sum;
    }, 0);
  };

  const currencyTranslationKey = (currency: string) => {
    return t('currencies.local_currency_code') === currency ? 'local' : 'fx';
  };

  const getTotalAmountOwedFormatted = (invoices: GenericObject[]) => {
    if (invoices.length < 1) return null;
    const isoCode = invoices[0].currency.iso_code;
    return t(`currencies.${currencyTranslationKey(isoCode)}`, {
      value: getTotalAmountOwed(invoices),
      currencyCode: isoCode,
      formatOptions: { currency: isoCode },
    });
  };

  const getTotalUnpaidInvoices = (invoices: GenericObject[]) => {
    return invoices.filter(invoice => invoice.status === 'pending').length;
  };

  if (isLoading || apiLoading) {
    return <LoadingScreen />;
  }

  const unpaidWarn = (
    <M.ActionableNotification
      kind='warning'
      style={{ maxWidth: '52rem' }}
      title='Unpaid invoices'
      hideActionButton
      hideCloseButton
    >
      <p>
        <Trans
          t={t}
          i18nKey='banners.invoices.invoice_unpaid_invoices_message'
          values={{
            unpaidInvoices: getTotalUnpaidInvoices(rowData),
            unpaidAmount: getTotalAmountOwedFormatted(rowData),
          }}
        >
          <M.Link href='https://help.checkr.com/hc/en-us/sections/17191111994775-Payment-and-billing'>
            {t('invoices.invoice_payment_billing_help_center')}
          </M.Link>
        </Trans>
        <br />
        {t('banners.invoices.invoice_unpaid_invoices_message_system_delay')}
      </p>
    </M.ActionableNotification>
  );

  if (!apiLoading) {
    return (
      <div>
        {getTotalAmountOwed(rowData) > 0 ? unpaidWarn : null}
        <IdpThinRow>
          <M.ToggletipLabel>
            <h4>{t('headings.invoices')}</h4>
          </M.ToggletipLabel>
          <M.Toggletip>
            <M.ToggletipButton label='invoice information'>
              <M.Icon icon='Information' />
            </M.ToggletipButton>
            <M.ToggletipContent>
              <p>
                <Trans
                  t={t}
                  i18nKey='tooltips.invoice_shows_invoice_info_tooltip'
                  values={{}}
                >
                  <M.Link href='https://help.checkr.com/hc/en-us/sections/17191111994775-Payment-and-billing'>
                    {t('invoices.invoice_payment_billing_help_center')}
                  </M.Link>
                </Trans>
              </p>
            </M.ToggletipContent>
          </M.Toggletip>
        </IdpThinRow>
        {!isEmpty ? (
          <M.DataTable
            rows={rowData}
            headers={headers}
            render={getTableContent}
          />
        ) : (
          <div>
            <NoBillsYetMessage>{t('invoices.no_bills_yet')}</NoBillsYetMessage>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default InvoicesContainer;
