import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Brand from 'containers/Account/BrandContainer';

type Props = {
  name: string;
  id: string;
  hideModal: () => void;
  open: boolean;
  page: number;
};

const EditModal: React.FC<Props> = ({ name, id, open, hideModal, page }) => {
  return (
    <M.ComposedModal
      open={open}
      hideModal={hideModal}
      onClose={hideModal}
      data-testId='modal-edit'
    >
      <M.ModalHeader closeModal={hideModal}>
        <h2>Update Brand - {name}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <Brand brandId={id} hideModal={hideModal} page={page} />
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default EditModal;
