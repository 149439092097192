/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { AnyQueryKey, useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as API from 'api';
import { setGlobalState, toastError } from 'actions';
import { Document } from 'types';

// eslint-disable-next-line import/prefer-default-export
export const useCandidateDocuments = (candidateId: string) => {
  const key: AnyQueryKey = [
    'documents',
    {
      candidateId,
    },
  ];

  const request = () => API.documents.list({ candidateId });
  const dispatch = useDispatch();

  const res = useQuery(key, request, { refetchOnWindowFocus: false });
  const documents = useMemo(() => res?.data || [], [res?.data]) as Document[];

  const isLoading = res?.isLoading as boolean;
  const isError = res?.isError as boolean;
  const error = res?.error as any;

  useEffect(() => {
    error && dispatch(toastError('Error Downloading Documents'));
  }, [error, dispatch]);

  const payload = useMemo(
    () => ({
      documents,
      isLoading,
      isError,
      error,
    }),
    [documents, isLoading, isError, error],
  );

  useEffect(() => {
    // Add api data to global redux state on success
    dispatch(setGlobalState({ key: 'DOCUMENT', payload }));
  }, [payload, dispatch]);

  return payload;
};
