import { Decision, ItemVisibility } from 'modules/assess';
import { Initializer } from 'state/utilities/state';
import { Entity } from '../common/entity';

export type Type = Entity & {
  editing: boolean;
  initial: boolean;
  item_visibility?: ItemVisibility | null;
  position?: number;
};

export const initialize: Initializer<Omit<Type, 'id'>> = () => ({
  conditions: [],
  decision: Decision.REVIEW,
  editing: true,
  item_visibility: null,
  initial: true,
  position: 1,
  name: '',
});
