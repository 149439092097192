import React, { FC } from 'react';
import { IS_ANGULAR_IFRAME } from 'Constants';
import { getDashboardUrl } from 'utils';
import { Link } from 'react-router-dom';
import { GenericObject } from '@dashboard-experience/utils';

export type LinkProps = {
  path: string;
  params?: GenericObject;
  hash?: string;
  className?: string;
  iframeProps?: GenericObject; // props for links only applied in iframe context
  standaloneProps?: GenericObject; // props for links only applied in standalone context
};

const DashboardLink: FC<LinkProps> = ({
  path,
  params,
  hash,
  className,
  iframeProps = {},
  standaloneProps = {},
  ...props
}) => {
  const url = getDashboardUrl(path, params, hash);
  return IS_ANGULAR_IFRAME ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={url}
      className={className}
      rel='noopener noreferrer'
      target='_parent'
      {...iframeProps}
      {...props}
    />
  ) : (
    <Link to={url} className={className} {...standaloneProps} {...props} />
  );
};

export default DashboardLink;
