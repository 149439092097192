import React from 'react';
import { SegmentsContainer } from 'containers';
import { getParamFromUrl } from '@dashboard-experience/utils';
import { SegmentsProvider } from 'components/Segments/SegmentsContext';

type Props = {
  match: any;
};

const SegmentsPage: React.FC<Props> = ({ match }) => {
  const accountId = match.accountId
    ? match.accountId
    : getParamFromUrl(window, 'accountId');

  return (
    <SegmentsProvider>
      <SegmentsContainer
        parentContext={match.params.parentContext}
        parentId={match.params.parentId}
        accountId={accountId}
        closeModal={match.params.closeModal}
      />
    </SegmentsProvider>
  );
};

export default SegmentsPage;
