import { Tabs } from 'components/DashboardWrapper/tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListElement, Menu } from './styles';
import { NavIcon } from './nav-icon';
import SubmenuItem from './submenu-item';

type Props = {
  tabName: string;
  navCollapsed: boolean;
  submenuTabs: Tabs;
};

const NavElementWithSubmenu: React.FC<Props> = ({
  tabName,
  navCollapsed,
  submenuTabs,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'navigation' });

  const menuLabelText = t(tabName);

  return (
    <ListElement title={navCollapsed ? menuLabelText : ''}>
      <Menu
        icon={<NavIcon tabName={tabName} />}
        label={menuLabelText}
        menuOffset={{
          // Offsets to make the menu open to the RIGHT of the nav item, and align the bottom-most submenu item with the nav item.
          top: -36,
          left: navCollapsed ? 114 : 212,
        }}
        direction='top'
      >
        {Object.entries(submenuTabs).map(([titleKey, tab]) => {
          return <SubmenuItem key={titleKey} titleKey={titleKey} tab={tab} />;
        })}
      </Menu>
    </ListElement>
  );
};

export default NavElementWithSubmenu;
