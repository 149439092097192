/* eslint-disable no-shadow */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AccountSingleSignOnContainer } from 'containers';

const AdminSingleSignOnContainer = ({
  match: {
    params: { accountId, accountName },
  },
}) => {
  const account = { name: accountName, id: accountId };
  return <AccountSingleSignOnContainer providedAccount={account} />;
};

AdminSingleSignOnContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      accountName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(AdminSingleSignOnContainer);
