import React, { useCallback, useMemo } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { mapPackage } from 'components/AddScreenings/shared/utils';
import { useLocation } from 'react-router-dom';
import { isCandidateReportURL } from 'components/Packages/AddChecks/helper';
import { Package, Screening } from '../../../../types';
import UnavailableForManualBulkTooltip from './ManualBulkTooltip';
import ConditionalWrapper from './ConditionalWrapper';
import { useOrderBackgroundCheckContext, actionTypes } from '../Context';
import PackageCard from './PackageCard';

const TableHeadersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  text-align: left;
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  min-width: 280px;
  color: ${colors.uiTextPrimaryLight};
`;

const TableHeaderPrice = styled(TableHeader)`
  min-width: 5px;
  padding-right: 25px;
`;

const TableHeadersWideScreen = styled.span`
  display: flex;

  @media all and (max-width: 940px) {
    display: none;
  }
`;

const TableHeadersSmallScreen = styled.span`
  display: none;

  @media all and (max-width: 940px) {
    display: flex;
  }
`;

const TableHeadersMobileScreen = styled.span`
  display: flex;

  @media all and (max-width: 540px) {
    display: none;
  }
`;

export type Props = {
  packages: Package[];
  showPackagePrice: boolean;
  segmentation_enabled?: boolean;
  hierarchy_present?: boolean;
  node?: any;
  isManualBulk?: (thisPackage: Package) => boolean;
  recommendedPackages?: Package[];
};

const WideChoiceCards: React.FC<Props> = ({
  packages,
  showPackagePrice,
  segmentation_enabled,
  hierarchy_present,
  node,
  isManualBulk,
  recommendedPackages = [], // Needed as prop for amplitude tracking
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const location = useLocation();
  const usingAddChecks = useMemo(
    () => isCandidateReportURL(location.pathname),
    [location],
  );

  const isManualBulkOrder = (thisPackage: Package) => {
    return isManualBulk ? isManualBulk(thisPackage) : false;
  };

  const updateContinueButtonState = useCallback(
    (basePackage: Package) => {
      const isPackageSelected = Boolean(basePackage);
      if (state.continueButtonDisabled !== !isPackageSelected) {
        dispatch({
          type: actionTypes.CONTINUE_BUTTON_DISABLED,
          payload: { continueButtonDisabled: !isPackageSelected },
        });
      }
    },
    [dispatch, state?.continueButtonDisabled],
  );

  const handleSelectPackageWrap = useCallback(
    (selectedPackage: Package) => {
      // If the user selects a previously selected package, deselect it by setting state's basePackage to {}
      if (selectedPackage.slug === state.basePackage.slug) {
        dispatch({
          type: actionTypes.SET_BASE_PACKAGE,
          payload: {
            basePackage: {},
          },
        });
        return;
      }
      if (usingAddChecks) {
        dispatch({
          type: actionTypes.RESET_ADDITIONAL_PROPERTIES,
          payload: {},
        });
      }
      dispatch({
        type: actionTypes.SET_BASE_PACKAGE,
        payload: {
          basePackage: {
            ...mapPackage(selectedPackage),
            id: selectedPackage.id,
          },
          recommendedPackageSelected: false,
          recommendedPackageType: '',
        },
      });
      updateContinueButtonState(selectedPackage);
    },
    [
      state.basePackage.slug,
      usingAddChecks,
      dispatch,
      updateContinueButtonState,
    ],
  );

  if (!packages || packages.length < 1) {
    if (segmentation_enabled && hierarchy_present && node) {
      return (
        <em>
          {`No ${
            state.selectedGeo === 'domestic' ? 'domestic' : 'international'
          } packages found for node: ${node?.custom_id}, ${node?.name}.`}
        </em>
      );
    }
    return (
      <em>
        {`No ${
          state.selectedGeo === 'domestic' ? 'domestic' : 'international'
        } packages found.`}
      </em>
    );
  }

  const filteredBasePackage = (basePackage: Package) => {
    // makes sure adverse media is not shown as a separate line item for international_only packages
    if (basePackage.international_only) {
      const filtered = basePackage.screenings?.filter(
        (screening: Screening) =>
          screening.type !== 'international_adverse_media_search',
      );
      return { ...basePackage, screenings: filtered };
    }
    return basePackage;
  };

  return (
    <>
      <TableHeadersContainer>
        <TableHeadersWideScreen>
          <TableHeader>Package name</TableHeader>
          <TableHeader>Included searches</TableHeader>
        </TableHeadersWideScreen>
        <TableHeadersSmallScreen>
          <TableHeader>Package</TableHeader>
        </TableHeadersSmallScreen>

        <TableHeadersMobileScreen>
          {showPackagePrice && (
            <TableHeaderPrice data-testid='select-package-price-table-header'>
              Price
            </TableHeaderPrice>
          )}
        </TableHeadersMobileScreen>
      </TableHeadersContainer>
      {packages.map((basePackage, idx: number) => (
        <ConditionalWrapper
          key={basePackage.id}
          condition={!isManualBulkOrder(basePackage)}
          wrapper={UnavailableForManualBulkTooltip}
        >
          <PackageCard
            basePackage={basePackage}
            idx={idx}
            showPackagePrice={showPackagePrice}
            isDisabled={!isManualBulkOrder(basePackage)}
            basePackageScreenings={filteredBasePackage(basePackage).screenings}
            handleSelectPackageWrap={handleSelectPackageWrap}
          />
        </ConditionalWrapper>
      ))}
    </>
  );
};

export default WideChoiceCards;
