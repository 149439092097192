/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import ButtonSelection from 'components/AddScreenings/shared/SetupModalButtonSelection';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';

import {
  BaseWrapper,
  Section,
} from 'components/AddScreenings/shared/ModalComponents';
import {
  BasePackage,
  useOrderBackgroundCheckContext,
} from 'components/Packages/OrderBackgroundCheck/Context';
import {
  ExpandableTitle,
  BoxDivider,
  BoxHeader,
  Flex,
  Box,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  BulletPoint,
  FlexContainerNoBorder,
  TitleWithTag,
  ActionPropsProps,
} from '../../../shared/ListItemElements';
import OccupationalHealthSetupModal from './OccupationalHealthSetupModal';

export type Item = {
  label: string;
  section: string;
  value: string;
  disables: Array<string>;
};

const Container = styled.div`
  margin-left: 32px;
  margin-bottom: 24px;
  margin-top: -10px;
`;

export const OCC_HEALTH_SERVICES: Record<string, Item> = {
  OHS_DOT_PHYSICAL: {
    label: 'DOT physical',
    section: 'Physical evaluations',
    value: 'OHS_DOT_PHYSICAL',
    disables: [],
  },
  OHS_HEIGHT_WEIGHT_BLOOD_PRESSURE: {
    label: 'Height/weight, blood pressure',
    section: 'Physical evaluations',
    value: 'OHS_HEIGHT_WEIGHT_BLOOD_PRESSURE',
    disables: [],
  },
  OHS_KRAUS_WEBER_EVALUATION: {
    label: 'Kraus Weber evaluation',
    section: 'Physical evaluations',
    value: 'OHS_KRAUS_WEBER_EVALUATION',
    disables: [],
  },
  OHS_LIFT_TESTING_L1_50_LBS: {
    label: 'Lift testing L1 50 pounds',
    section: 'Physical evaluations',
    value: 'OHS_LIFT_TESTING_L1_50_LBS',
    disables: ['OHS_LIFT_TESTING_L2_75_LBS'],
  },
  OHS_LIFT_TESTING_L2_75_LBS: {
    label: 'Lift testing L2 75 pounds',
    section: 'Physical evaluations',
    value: 'OHS_LIFT_TESTING_L2_75_LBS',
    disables: ['OHS_LIFT_TESTING_L1_50_LBS'],
  },
  OHS_WELLNESS_SCREEN: {
    label: 'Wellness test',
    section: 'Physical evaluations',
    value: 'OHS_WELLNESS_SCREEN',
    disables: [],
  },
  OHS_NON_DOT_PHYSICAL: {
    label: 'Non-DOT Physical',
    section: 'Physical evaluations',
    value: 'OHS_NON_DOT_PHYSICAL',
    disables: [],
  },
  OHS_AUDIOGRAM: {
    label: 'Audiogram',
    section: 'Hearing & vision',
    value: 'OHS_AUDIOGRAM',
    disables: [],
  },
  OHS_VISION_TEST_ISHIHARA: {
    label: 'Vision test Ishihara',
    section: 'Hearing & vision',
    value: 'OHS_VISION_TEST_ISHIHARA',
    disables: [],
  },
  OHS_VISION_TEST_JAEGER: {
    label: 'Vision test Jaeger',
    section: 'Hearing & vision',
    value: 'OHS_VISION_TEST_JAEGER',
    disables: [],
  },
  OHS_VISION_TEST_SNELLEN: {
    label: 'Vision test Snellen',
    section: 'Hearing & vision',
    value: 'OHS_VISION_TEST_SNELLEN',
    disables: [],
  },
  OHS_VISION_TEST_TITMUS: {
    label: 'Vision test Titmus',
    section: 'Hearing & vision',
    value: 'OHS_VISION_TEST_TITMUS',
    disables: [],
  },
  OHS_TB_PPD_TEST_1: {
    label: 'TB PPD test 1',
    section: 'TB tests & x-rays',
    value: 'OHS_TB_PPD_TEST_1',
    disables: ['OHS_TB_CHEST_XRAY', 'OHS_TB_PPD_TEST_2'],
  },
  OHS_TB_PPD_TEST_2: {
    label: 'TB PPD test 2',
    section: 'TB tests & x-rays',
    value: 'OHS_TB_PPD_TEST_2',
    disables: ['OHS_TB_PPD_TEST_1', 'OHS_TB_CHEST_XRAY'],
  },
  OHS_TB_CHEST_XRAY: {
    label: 'TB chest x-ray',
    section: 'TB tests & x-rays',
    value: 'OHS_TB_CHEST_XRAY',
    disables: ['OHS_TB_PPD_TEST_1', 'OHS_TB_PPD_TEST_2'],
  },
  OHS_CHEST_XRAY_1_VIEW: {
    label: 'Chest x-ray 1 view',
    section: 'TB tests & x-rays',
    value: 'OHS_CHEST_XRAY_1_VIEW',
    disables: ['OHS_CHEST_XRAY_2_VIEW'],
  },
  OHS_CHEST_XRAY_2_VIEW: {
    label: 'Chest x-ray 2 view',
    section: 'TB tests & x-rays',
    value: 'OHS_CHEST_XRAY_2_VIEW',
    disables: ['OHS_CHEST_XRAY_1_VIEW'],
  },
  OHS_QUANTIFERON_GOLD_TB: {
    label: 'Quantiferon Gold TB Blood Test',
    section: 'TB tests & x-rays',
    value: 'OHS_QUANTIFERON_GOLD_TB',
    disables: [],
  },
  OHS_OXFORD_TSPOT_TB: {
    label: 'Oxford TSpot TB',
    section: 'TB tests & x-rays',
    value: 'OHS_OXFORD_TSPOT_TB',
    disables: [],
  },
  OHS_INFLUENZA_VACCINE: {
    label: 'Influenza vaccine',
    section: 'Immunizations',
    value: 'OHS_INFLUENZA_VACCINE',
    disables: [],
  },
  OHS_TITER_HEPATITIS_A: {
    label: 'Titer hepatitis A',
    section: 'Immunizations',
    value: 'OHS_TITER_HEPATITIS_A',
    disables: ['OHS_VACCINE_HEPATITIS_A'],
  },
  OHS_TITER_HEPATITIS_B_HBSAB: {
    label: 'Titer hepatitis B HbsAb',
    section: 'Immunizations',
    value: 'OHS_TITER_HEPATITIS_B_HBSAB',
    disables: ['OHS_VACCINE_HEPATITIS_B_1'],
  },
  OHS_TITER_HEPATITIS_B_SURFACE_ANTIGEN: {
    label: 'Titer hepatitis B surface antigen',
    section: 'Immunizations',
    value: 'OHS_TITER_HEPATITIS_B_SURFACE_ANTIGEN',
    disables: [],
  },
  OHS_TEST_HEPATITIS_C_ANTIBODY: {
    label: 'Titer hepatitis C antibody',
    section: 'Immunizations',
    value: 'OHS_TEST_HEPATITIS_C_ANTIBODY',
    disables: [],
  },
  OHS_TITER_MMR: {
    label: 'Titer MMR',
    section: 'Immunizations',
    value: 'OHS_TITER_MMR',
    disables: ['OHS_VACCINE_MMR_1'],
  },
  OHS_TITER_MUMPS: {
    label: 'Titer mumps',
    section: 'Immunizations',
    value: 'OHS_TITER_MUMPS',
    disables: [],
  },
  OHS_TITER_RUBELLA: {
    label: 'Titer rubella',
    section: 'Immunizations',
    value: 'OHS_TITER_RUBELLA',
    disables: [],
  },
  OHS_TITER_VARICELLA_ANTIBODY_IGG: {
    label: 'Titer varicella antibody IgG',
    section: 'Immunizations',
    value: 'OHS_TITER_VARICELLA_ANTIBODY_IGG',
    disables: ['OHS_VACCINE_VARICELLA_1'],
  },
  OHS_VACCINE_HEPATITIS_A: {
    label: 'Vaccine hepatitis A',
    section: 'Immunizations',
    value: 'OHS_VACCINE_HEPATITIS_A',
    disables: ['OHS_TITER_HEPATITIS_A'],
  },
  OHS_VACCINE_HEPATITIS_B_1: {
    label: 'Vaccine hepatitis B 1',
    section: 'Immunizations',
    value: 'OHS_VACCINE_HEPATITIS_B_1',
    disables: [
      'OHS_VACCINE_HEPATITIS_B_2',
      'OHS_VACCINE_HEPATITIS_B_3',
      'OHS_TITER_HEPATITIS_B_HBSAB',
    ],
  },
  OHS_VACCINE_HEPATITIS_B_2: {
    label: 'Vaccine hepatitis B 2',
    section: 'Immunizations',
    value: 'OHS_VACCINE_HEPATITIS_B_2',
    disables: ['OHS_VACCINE_HEPATITIS_B_1', 'OHS_VACCINE_HEPATITIS_B_3'],
  },
  OHS_VACCINE_HEPATITIS_B_3: {
    label: 'Vaccine hepatitis B 3',
    section: 'Immunizations',
    value: 'OHS_VACCINE_HEPATITIS_B_3',
    disables: ['OHS_VACCINE_HEPATITIS_B_1', 'OHS_VACCINE_HEPATITIS_B_2'],
  },
  OHS_VACCINE_MMR_1: {
    label: 'Vaccine MMR 1',
    section: 'Immunizations',
    value: 'OHS_VACCINE_MMR_1',
    disables: ['OHS_VACCINE_MMR_2', 'OHS_TITER_MMR'],
  },
  OHS_VACCINE_MMR_2: {
    label: 'Vaccine MMR 2',
    section: 'Immunizations',
    value: 'OHS_VACCINE_MMR_2',
    disables: ['OHS_VACCINE_MMR_1'],
  },
  OHS_VACCINE_TDAP: {
    label: 'Vaccine Tdap',
    section: 'Immunizations',
    value: 'OHS_VACCINE_TDAP',
    disables: [],
  },
  OHS_VACCINE_VARICELLA_1: {
    label: 'Vaccine varicella 1',
    section: 'Immunizations',
    value: 'OHS_VACCINE_VARICELLA_1',
    disables: ['OHS_VACCINE_VARICELLA_2', 'OHS_TITER_VARICELLA_ANTIBODY_IGG'],
  },
  OHS_VACCINE_VARICELLA_2: {
    label: 'Vaccine varicella 2',
    section: 'Immunizations',
    value: 'OHS_VACCINE_VARICELLA_2',
    disables: ['OHS_VACCINE_VARICELLA_1'],
  },
  OHS_ASR_TEST: {
    label: 'ASR test',
    section: 'Other',
    value: 'OHS_ASR_TEST',
    disables: [],
  },
  OHS_OSHA_RESPIRATOR_QS: {
    label: 'OSHA respirator questions',
    section: 'Other',
    value: 'OHS_OSHA_RESPIRATOR_QS',
    disables: [],
  },
  OHS_PULMONARY_FUNCTION: {
    label: 'Pulmonary function',
    section: 'Other',
    value: 'OHS_PULMONARY_FUNCTION',
    disables: [],
  },
  OHS_PULMONARY_FUNCTION_WITH_OSHA_QS: {
    label: 'Pulmonary function with OSHA questions',
    section: 'Other',
    value: 'OHS_PULMONARY_FUNCTION_WITH_OSHA_QS',
    disables: [],
  },
  OHS_RESPIRATOR_FIT_TEST_QUALITATIVE: {
    label: 'Respirator fit test qualitative',
    section: 'Other',
    value: 'OHS_RESPIRATOR_FIT_TEST_QUALITATIVE',
    disables: [],
  },
  OHS_RESPIRATOR_FIT_TEST_QUANTITATIVE: {
    label: 'Respirator fit test quantitative',
    section: 'Other',
    value: 'OHS_RESPIRATOR_FIT_TEST_QUANTITATIVE',
    disables: [],
  },
};

export const handleOnClick = (
  actionProps: { onAddClick: any; onRemoveClick: any; addedScreenings: any },
  selectedOccupationalHealth: any[],
  initialSelectedItems: any[],
  setInvalid: Function,
) => {
  const { onAddClick, onRemoveClick, addedScreenings } = actionProps;
  if (addedScreenings.includes('occupational_health_screening')) {
    return onRemoveClick('occupational_health_screening');
  }
  const hasAdditionalOccHealthScreenings =
    (selectedOccupationalHealth.length > 0 && !initialSelectedItems) ||
    (initialSelectedItems &&
      selectedOccupationalHealth.length - initialSelectedItems.length > 0);

  if (hasAdditionalOccHealthScreenings) {
    return onAddClick('occupational_health_screening', {
      subtype: selectedOccupationalHealth.map(occ => occ.name),
      selectedOccupationalHealth,
    });
  }
  return setInvalid(true);
};

export const sections: Array<Section> = [
  { title: 'Physical evaluations', description: 'Physicals, lift tests' },
  { title: 'TB tests & x-rays', description: 'Tuberculosis tests' },
  { title: 'Hearing & vision', description: 'Audiogram, vision tests' },
  {
    title: 'Immunizations',
    description:
      'Administer vaccines and blood-draw tests for resistance to common diseases',
  },
  { title: 'Other', description: 'OSHA respirator questionnaire' },
];

type SelectedOccupationalHealthState = {
  name: string;
  label: string;
};

type OccupationHealthService = {
  disables: string[];
  label: string;
  section: string;
  value: string;
};

type OccupationalHealthProps = {
  shouldOpen: boolean;
  occupationalHealthServices: any[];
  actionProps: ActionPropsProps;
  basePackage: BasePackage;
};

export const getItems = (occupationalHealthServices: any[]) => {
  if (!occupationalHealthServices) return [];

  const items = occupationalHealthServices
    .filter((service: any) => service && OCC_HEALTH_SERVICES[service.name])
    .map((service: any) => {
      const { section, disables, label } = OCC_HEALTH_SERVICES[service.name];
      const x = {
        value: service.name,
        label,
        section,
        disables,
      };
      return x;
    });

  return items;
};

const OccupationalHealth = ({
  shouldOpen,
  occupationalHealthServices = [],
  actionProps,
  basePackage,
}: OccupationalHealthProps) => {
  const { state } = useOrderBackgroundCheckContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentUser = useUser();
  const currentOccHealthStatus =
    currentUser?.account.occ_health_screening_setup;
  const [selectedOccupationalHealth, setSelectedOccupationalHealth] = useState<
    SelectedOccupationalHealthState[]
  >([]);
  const [invalid, setInvalid] = useState(false);

  const { addedScreenings } = actionProps;

  const existingScreening =
    basePackage?.screenings.find(
      (screening: any) => screening.type === 'occupational_health_screening',
    ) || state?.additionalProperties?.occupational_health_screening;

  const initialSelectedItems = existingScreening?.subtype
    .filter((service: string) => OCC_HEALTH_SERVICES[service] && service)
    .map((service: string) => ({
      ...OCC_HEALTH_SERVICES[service],
      disables: [...OCC_HEALTH_SERVICES[service].disables, service],
    }));

  const validItems = useMemo(() => {
    if (occupationalHealthServices?.length < 1) return [];

    const items = getItems(occupationalHealthServices);

    return items;
  }, [occupationalHealthServices]);

  const subtypeProps = {
    kind: addedScreenings.includes('occupational_health_screening')
      ? 'tertiary'
      : 'secondary',

    onClick: () =>
      handleOnClick(
        actionProps,
        selectedOccupationalHealth,
        initialSelectedItems,
        setInvalid,
      ),

    disabled: false,

    id: addedScreenings.includes('occupational_health_screening')
      ? 'delete-trash-can'
      : '',
  };

  const onChange = useCallback((services: OccupationHealthService[]) => {
    const occHealthClean = services.map(service => {
      return {
        name: service.value,
        label: service.label,
      };
    });
    setSelectedOccupationalHealth(occHealthClean);
  }, []);

  useEffect(() => {
    setInvalid(false);
  }, [selectedOccupationalHealth]);

  const handleOpen = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (!initialSelectedItems || initialSelectedItems?.length < 1) {
      return;
    }

    const quantityTagTestId = 'multi-select-list-box-tag';
    const quantityTagContainer = document.querySelector(
      `[data-testid="${quantityTagTestId}"]`,
    );

    if (quantityTagContainer) {
      const buttonElement = quantityTagContainer.lastElementChild;
      if (buttonElement && buttonElement.tagName === 'BUTTON') {
        (buttonElement as any).disabled = true;
        buttonElement.classList.add('disabled-button');
      }
    }

    initialSelectedItems.forEach((service: any) => {
      const parentTestId = `multi-select-tag-${service.label
        .toLowerCase()
        .split(' ')
        .join('-')}`;
      const parentElement = document.querySelector(
        `[data-testid="${parentTestId}"]`,
      );

      if (parentElement) {
        const buttonElement = parentElement.lastElementChild;
        if (buttonElement && buttonElement.tagName === 'BUTTON') {
          (buttonElement as any).disabled = true;
          buttonElement.classList.add('disabled-button');
        }
      }
    });
  }, [initialSelectedItems]);

  return (
    <>
      {showModal && (
        <OccupationalHealthSetupModal
          open={showModal}
          handleClose={handleClose}
        />
      )}
      <FlexContainerNoBorder
        style={{ borderTop: `1px solid ${colors.brandSlate2}` }}
        data-testid='occupational_health_screening_section'
      >
        <ExpandableTitle
          initialExpanded={shouldOpen}
          title={
            <TitleWithTag
              getPriceByType
              actionProps={actionProps}
              title='Occupational Health'
              priceByType='occupational_health_screening'
              priceVaries
              setupStatus={currentOccHealthStatus}
            />
          }
        >
          <ScreeningDescriptionContainer>
            <ScreeningInfo>
              Evaluates a candidate’s physical and mental capability for
              performing job-related responsibilities.{` `}
              <LearnMoreLink
                style={{ textDecoration: 'underline' }}
                linkText='Learn more'
                data-testid='occ-health-learn-more-link'
                href='https://help.checkr.com/hc/en-us/articles/5895674204055'
              />
            </ScreeningInfo>
            {/* <TurnAroundTime /> */}
          </ScreeningDescriptionContainer>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <ul>
                <BulletPoint
                  text={`Evaluates a candidate's physical fitness for job requirements`}
                />
                <BulletPoint text='Promotes employee well-being and lower health costs' />
                <BulletPoint text='Can be combined with a drug screening' />
              </ul>
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <ul>
                <BulletPoint text='Some states have more specific laws or guidelines' />
              </ul>
            </Box>
          </Flex>
        </ExpandableTitle>
        <ButtonSelection
          status={currentOccHealthStatus}
          addedScreenings={addedScreenings}
          subtypeProps={subtypeProps}
          handleOpen={handleOpen}
          screening='occupational_health_screening'
        />
      </FlexContainerNoBorder>
      {currentOccHealthStatus === 'enabled' && (
        <Container>
          <BaseWrapper>
            <M.MultiSelectWithSections
              items={validItems}
              invalid={invalid}
              invalidText='Selection Required'
              initialSelectedItems={initialSelectedItems}
              sections={sections}
              disabled={addedScreenings.includes(
                'occupational_health_screening',
              )}
              placeholder='Type screening name'
              onChange={onChange}
            />
          </BaseWrapper>
        </Container>
      )}
    </>
  );
};

export default OccupationalHealth;
