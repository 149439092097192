import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import * as Entity from 'modules/assess/models/rules/count';
import { Field } from 'modules/assess/ui/rules/common/rule';

type Props = {
  name: string;
  invalid: boolean;
  setDraftRule: any;
};

const Name: React.FC<Props> = ({ name, invalid, setDraftRule }) => {
  const { t } = useTranslation('');
  const label = t('inputs.rule_name');

  const handleChange = useCallback(
    e => {
      e.persist();
      const n = e.target.value;
      setDraftRule((prev: Entity.Type) => ({ ...prev, name: n }));
    },
    [setDraftRule],
  );

  return (
    <>
      <Field label={label} style={{ marginBottom: '0.75em' }} />
      <M.Input
        onChange={handleChange}
        defaultValue={name}
        invalid={invalid}
        invalidText={t('assess:rules.custom.rule.validation.name')}
      />
    </>
  );
};

export default Name;
