import React from 'react';
import { Route } from 'react-router';

import { TimelineContainer } from 'containers';

const TimelinePage: React.FC = () => (
  <Route
    exact
    path='/timeline/:contextName/:contextId'
    render={TimelineContainer}
  />
);

export default TimelinePage;
