import React from 'react';
import { GenericObject } from 'types';
import { FieldComponentProps } from '../types';

const reportSourceMap: GenericObject = {
  api: 'API',
  continuous_check: 'Continuous Check',
  form: 'Form',
  manual_order: 'Manual Order',
  recurrence: 'Recurrence',
  web: 'Web',
};

const Source: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const source = report.source ? reportSourceMap[report.source] : '-';
  return <div data-testid={testid}>{source}</div>;
};

export default Source;
