import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useLastReportTask } from 'modules/case-management/ui/TasksPage/hooks';
import User from 'modules/case-management/ui/TasksPage/Table/User/User';
import { FieldComponentProps } from '../../types';

const StyledUser = styled(User)`
  margin: 0;
`;

const AssignedTo: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const task = useLastReportTask({ report });

  const avatar: ReactElement | string = task?.assignee ? (
    <StyledUser user={task.assignee} />
  ) : (
    'Not assigned'
  );

  return <div data-testid={testid}>{avatar}</div>;
};

export default AssignedTo;
