import React from 'react';
import styled, { css } from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

type LinkCardProps = {
  date?: string;
  tag: string;
  title: string;
  description?: string;
  externalLink?: string;
  onClick?: (() => void) | undefined;
  colour?: 'white' | 'paper';
  isShown?: boolean;
};

const LinkCardWrapper = styled.a<{
  isExternalLink?: boolean;
  theme: { default: string; hover: string; border?: string };
}>`
  border-radius: 0.5rem;
  display: inline-block;
  padding: 1.5rem;
  background: ${props => props.theme.default};
  font-style: normal;
  position: relative;
  ${({ theme }) =>
    !!theme.border &&
    css`
      border: 1px solid ${theme.border};
    `}
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.hover};
    svg {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
      color: ${colors.uiTextTertiaryLight};
    }
  }
  svg {
    position: absolute;
    color: ${colors.uiTextDisabledLight};
    top: 1rem;
    right: 1rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s;
  }
`;

const LinkCardDate = styled.div`
  color: ${colors.uiTextTertiaryLight};
  padding: 0 0 0.5rem;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
`;

const LinkCardTitle = styled.h5`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0 0.8rem 0.6rem 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkCardDescription = styled.div`
  color: ${colors.uiTextSecondaryLight};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25;
`;

const LinkCard = ({
  date,
  tag,
  title,
  description,
  externalLink,
  onClick,
  colour = 'white',
  isShown = true,
}: LinkCardProps) => {
  const theme = {
    white: {
      default: colors.uiGrey0,
      hover: colors.uiGrey50,
      border: colors.uiGrey200,
    },
    paper: {
      default: colors.brandPaper1,
      hover: colors.brandPaper2,
    },
  };

  if (!isShown) return null;

  return (
    <LinkCardWrapper
      isExternalLink={!!externalLink}
      href={externalLink}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
      theme={theme[colour]}
      data-testid={`home-page-link-card-${tag}`}
    >
      {!!externalLink && (
        <M.Icon
          icon='ArrowUpRight'
          data-testid={`home-page-link-card-arrow-${tag}`}
        />
      )}
      {date && (
        <LinkCardDate data-testid={`home-page-link-card-date-${tag}`}>
          {date}
        </LinkCardDate>
      )}
      <LinkCardTitle data-testid={`home-page-link-card-title-${tag}`}>
        {title}
      </LinkCardTitle>
      {description && (
        <LinkCardDescription data-testid={`home-page-link-description-${tag}`}>
          {description}
        </LinkCardDescription>
      )}
    </LinkCardWrapper>
  );
};

export default LinkCard;
