import { CATEGORIES } from 'modules/assess';
import ChargeSeverity from 'modules/assess/models/charge-severity';
import { severity as initSeverity } from 'modules/assess/ui/rules/lookback/table/severity-period/Charge';
import { Item } from 'modules/assess/utilities/hierarchy';

const STATUTORY = 'Statutory';

export function eligibleSeverity(
  severity: ChargeSeverity,
  eligible?: number | null,
) {
  return {
    [severity]: {
      eligible,
      escalated: null,
    },
  };
}

export function getFilteredCategories(
  categories: Item[],
  search?: string,
): Item[] {
  if (!search) return categories;

  return categories.filter(c => isCategoryFiltered(c, search));
}

export function getFilteredSubcategories(
  category: Item,
  search?: string,
): Item[] {
  const subcategories = category.children || [];
  if (!search) return subcategories;

  if (isMatch(category.name, search)) return subcategories;

  return subcategories.filter(c => isMatch(c.name, search));
}

export function getInitialRule(subcategory: string, stateRule: any) {
  const baseRule = {
    key: subcategory,
    charge_subcategory: subcategory,
    ...initSeverity,
  };
  return stateRule || baseRule;
}

function isCategoryFiltered(category: Item, search: string): boolean {
  const categoryMatch = isMatch(category.name, search);
  const subcategoriesMatch =
    category.children?.some(s => isMatch(s.name, search)) || false;

  return categoryMatch || subcategoriesMatch;
}

function isMatch(name: string, search: string): boolean {
  const match = new RegExp(search, 'i');
  return match.test(name);
}

export const STATUTORY_CATEGORY: Item | undefined = CATEGORIES.find(
  c => c.name === STATUTORY,
);
