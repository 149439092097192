import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Field as Common } from 'modules/assess/models/rulesets/version-diff/common';
import AssessmentChange from './AssessmentChange';
import { FieldStyle } from './FieldStyle';

type Props = {
  field: Common.FieldChanged;
  fieldStyle?: FieldStyle;
  isAssessmentDecision?: boolean;
};

type NextStateProps = {
  color: any;
  fontWeight: string;
};

const PreviousState = styled.span`
  color: ${props => props.color};
  margin-left: 0.3rem;
`;

const NextState = styled.span`
  color: ${(props: NextStateProps) => props.color};
  font-weight: ${(props: NextStateProps) => props.fontWeight};
  margin-left: 0.3rem;
`;

export const Field: React.FC<Props> = ({
  field,
  fieldStyle,
  isAssessmentDecision,
}) => {
  const { t } = useTranslation('assess');
  if (isAssessmentDecision) {
    return <AssessmentChange field={field} />;
  }

  let { active, draft } = field;

  if (field.translatePath) {
    active = t(`${field.translatePath}.${field.active || 'default'}`);
    draft = t(`${field.translatePath}.${field.draft || 'default'}`);
  }

  if (draft && !active) {
    active = t('assess:rules.lookback.changes.default');
  }

  if (!draft && active) {
    draft = t('assess:rules.lookback.changes.default');
  }

  return (
    <>
      <PreviousState color={getPreviousStateColor(fieldStyle)}>
        {active}
      </PreviousState>
      <M.Icon
        icon='ArrowRight'
        size='16'
        style={{ marginLeft: '0.3rem', marginBottom: '-0.1rem' }}
      />
      <NextState
        color={getNextStateColor(field, fieldStyle)}
        fontWeight={getNextStateFontWeigth(fieldStyle)}
      >
        {draft}
      </NextState>
    </>
  );
};

function getPreviousStateColor(fieldStyle?: FieldStyle) {
  switch (fieldStyle) {
    case FieldStyle.PREMADE:
      return colors.uiGrey700;
    default:
      return colors.uiGrey900;
  }
}

function getNextStateColor(
  field: Common.FieldChanged,
  fieldStyle?: FieldStyle,
) {
  switch (fieldStyle) {
    case FieldStyle.PREMADE:
      return colors.uiGrey900;
    case FieldStyle.LOOKBACK:
      return getLookbackChangeColor(field);
    default:
      return null;
  }
}

function getLookbackChangeColor(field: Common.FieldChanged) {
  if (!Number(field.draft)) {
    return colors.uiGrey900;
  }

  const active = +field.active;
  const draft = +field.draft;

  return active > draft ? colors.uiGreen400 : colors.uiPink600;
}

function getNextStateFontWeigth(fieldStyle?: FieldStyle) {
  switch (fieldStyle) {
    case FieldStyle.LOOKBACK:
      return 'bold';
    default:
      return 'normal';
  }
}

export default Field;
