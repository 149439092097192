import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const BatchActionContainer = styled.div`
  position: fixed;
  bottom: 50px;
  left: 30%;
  right: 30%;
  background: ${colors.bgSecondaryDark};
  z-index: 100;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

export const IconButton = styled(M.Button)`
  background: none !important;
  border: none !important;

  > svg {
    margin-right: 1rem;
  }
`;

export const ActionButton = styled(M.Button)`
  background: ${colors.borderPrimaryDark} !important;
  color: ${colors.uiNavy200} !important;
  border: none !important;
  border-radius: 5px;
`;
