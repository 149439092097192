import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const StatWidget: React.FC<any> = ({
  children,
  className,
  ...rest
}: {
  className: string;
  children: any;
}) => (
  <M.Container type='shadow' className={className}>
    {children}
  </M.Container>
);

export default styled(StatWidget)`
  border-radius: 4px;
`;
