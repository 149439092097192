import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

const baseText = (
  <span>
    We use historical county data to predict completion times for all searches
    in this report. Note that other searches in the report can affect the{' '}
    <strong>Est. completion</strong> time shown in{' '}
    <strong>Report information</strong>.
  </span>
);
const baseLink = 'https://help.checkr.com/hc/en-us/articles/360025682094';

type Props = {
  text?: string | JSX.Element;
  link?: string;
};

const GenericTooltip: React.FC<Props> = ({ text, link }) => {
  return (
    <span data-testid='eta-tooltip-content'>
      {text || baseText}
      <br />
      <br />
      <M.Link
        style={{ color: colors.uiNavy600 }}
        href={link || baseLink}
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </M.Link>
    </span>
  );
};

export default GenericTooltip;
