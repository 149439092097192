import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  formatDateIfDate,
} from '@dashboard-experience/utils';
import { GenericObject } from 'types';
import { MvrItem } from './MultiMotorVehicleReportComponents';

type MedCertsProps = {
  licenseReport: GenericObject;
};

const medCertKeys = [
  { key: 'status', title: 'Status', func: underscoreToHuman },
  {
    key: 'self_certification',
    title: 'Self Certification',
    func: underscoreToHuman,
  },
  { key: 'issued_date', title: 'Issued Date', func: formatDateIfDate },
  {
    key: 'expiration_date',
    title: 'Expiration Date',
    func: formatDateIfDate,
  },
];

const examinerKeys = [
  { key: 'full_name', title: 'Examiner Full Name' },
  { key: 'license_number', title: 'Examiner License Number' },
  { key: 'license_state', title: 'Examiner License State' },
  { key: 'registration_number', title: 'Examiner Registration Number' },
  { key: 'phone_number', title: 'Examiner Phone Number' },
];

const MedCerts: React.FC<MedCertsProps> = ({ licenseReport }) => {
  return (
    <M.SubScreening title='Medical certificates' data-testid='mvr-med-certs'>
      {licenseReport.medical_certificates.map(
        (medCert: GenericObject, idx: number) => {
          const medCertItems: { itemKey: string; itemValue: any }[] = [];
          const medCertStr = 'MEDICAL_CERTIFICATE';

          medCertKeys.forEach(({ key, title, func }) => {
            if (medCert[key]) {
              medCertItems.push({
                itemKey: title,
                itemValue: func(medCert[key]),
              });
            }
          });

          examinerKeys.forEach(({ key, title }) => {
            if (medCert.examiner[key]) {
              medCertItems.push({
                itemKey: title,
                itemValue: medCert.examiner[key],
              });
            }
          });

          const mvrItem = {
            description: underscoreToHuman(medCertStr),
            assessment: null,
          };

          return (
            <M.Expandable
              role='medCertItem'
              key={`${medCertStr}-${idx + 1}`}
              title={
                <>
                  <MvrItem item={mvrItem} />
                  <span style={{ float: 'right' }}>
                    {formatDateIfDate(medCert.issued_date)}
                  </span>
                </>
              }
            >
              <M.KeyValueList items={medCertItems} />
            </M.Expandable>
          );
        },
      )}{' '}
    </M.SubScreening>
  );
};

export default MedCerts;
