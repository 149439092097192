import { Location } from 'components/LocationSearch/types';

const shouldDisableButton = (
  stepIndex: number,
  checked: boolean,
  shouldSelectClinic: boolean,
  selectedClinic: Location | null,
) =>
  (stepIndex === 1 && !checked) ||
  (stepIndex === 0 && shouldSelectClinic && !selectedClinic);

export default shouldDisableButton; // make this an object when there is more than one export
