import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import PageLoading from 'components/PageLoading';
import * as Assignable from 'modules/assess/models/assignables';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { filter, useSearch, useSelector, PAGE_SIZE } from './hooks';
import DefaultMessages from './DefaultMessages';
import Filters from './Filters';
import Table from './Table';
import * as Types from './types';

type Props = {
  assignments?: Assignment.List;
  setAssignments?: React.Dispatch<React.SetStateAction<Assignment.List>>;
  ruleset?: Ruleset.Type;
};

const Container = styled.div`
  > * {
    margin: 2em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

export const Content: React.FC<Props> = ({
  assignments = [],
  setAssignments,
  ruleset,
}) => {
  const [key, setKey] = useState<Types.FilterKey>(undefined);
  const [show, setShow] = useState<Types.FilterShow>(undefined);
  const [type, setType] = useState<Types.FilterType>(Assignable.Kind.ACCOUNT);

  const onKeyChange = useCallback((n: Types.FilterKey) => setKey(n), []);
  const onShowChange = useCallback((s: Types.FilterShow) => setShow(s), []);
  const onTypeChange = useCallback((t: Types.FilterType) => setType(t), []);

  const selections = assignments.reduce<Types.Selections>((m, a) => {
    m[a.id] = true;
    return m;
  }, {});

  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [type]);

  const search = useSearch({ key, type, page: page + 1, per_page: PAGE_SIZE });

  const assignables = filter(
    assignments,
    search.data?.data,
    key,
    show,
    type,
    selections,
  );

  const pageCount = Math.ceil((search.data?.count || 0) / PAGE_SIZE);

  const onSelect = useSelector(assignables, setAssignments);

  return (
    <Container>
      <PageLoading enabled={assignables === undefined} />
      <Filters
        onKeyChange={onKeyChange}
        onShowChange={onShowChange}
        onTypeChange={onTypeChange}
      />
      <DefaultMessages assignments={assignables} ruleset={ruleset} />
      <Table
        assignments={assignables}
        selections={selections}
        onSelect={onSelect}
        ruleset={ruleset}
      />
      <M.Pagination
        selectedIndex={page}
        pageCount={pageCount}
        onPageClick={setPage}
      />
    </Container>
  );
};

export default Content;
