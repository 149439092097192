import React from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  header: string;
  message: string;
  buttonText: string;
  hideModal: Function;
  open: boolean;
};

const DialogModal: React.FC<Props> = ({
  header,
  message,
  buttonText,
  hideModal,
  open,
}) => {
  return (
    <M.ComposedModal onClose={hideModal} open={open}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>{header}</h2>
      </M.ModalHeader>
      <M.ModalBody>{message}</M.ModalBody>
      <M.ModalFooter data-testid='dialog-modal-footer'>
        <M.Button onClick={hideModal}>{buttonText}</M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default DialogModal;
