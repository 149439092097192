import { GenericObject } from '@dashboard-experience/utils';
import { REALTIME_URL } from 'Constants';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { debug } from 'utils';
import { useScript, ScriptStatuses } from 'hooks/useScript';

import { authenticate } from './actions';
import {
  RealtimeEventData,
  RealtimeStatuses,
  Update,
  ViewerList,
} from './types';

/**
 * Authenticate with the Realtime server
 */
export const useRealtimeAuth = () => {
  const request = () => authenticate();

  const [authCall, authResult] = useMutation(request);

  return {
    authCall,
    authResult,
  };
};

const PrimusOptions = {
  reconnect: {
    max: Infinity,
    min: 500,
    retries: 10,
  },
};

/**
 * Creates a connection to Realtime's Primus server, and establishes appropriate event handlers
 * @param param - Information needed to connect to primus
 * @param param.authData - The response from the Authenticate call
 * @param param.GlobalPrimus - The whole "Primus" object loaded from an external file, to be used as the connection target
 * @param param.updateCallback - Callback to get triggered whenever there is an "event" type update in Realtime (i.e. Candidate got updated)
 * @returns The established connection (`primus`), the ongoing list of current room viewers (`roomViewers`), and the state of the connection (`primusStatus`)
 */
export const usePrimus = ({
  authData,
  GlobalPrimus,
  updateCallback,
}: {
  authData?: GenericObject;
  GlobalPrimus: any;
  updateCallback?: Function;
}) => {
  const [primusStatus, setPrimusStatus] = useState<RealtimeStatuses>(
    RealtimeStatuses.IDLE,
  ); // Track the status of the connection to the Realtime server
  const [roomViewers, setRoomViewers] = useState<ViewerList>([]); // The running viewer list for the subscribed room
  const [roomHasUpdates, setRoomHasUpdates] = useState<Update | null>(null);

  const success = authData?.success || false;

  // If the Primus connection noticed any updates, then call the callback function if possible
  useEffect(() => {
    if (roomHasUpdates) {
      updateCallback?.(roomHasUpdates.message);
      setRoomHasUpdates(null);
    }
  }, [roomHasUpdates, updateCallback]);

  // Memoize the whole Primus connection object with its handlers
  const primus = useMemo(() => {
    // Return early if the prerequisites aren't ready yet:
    if (!success || !GlobalPrimus) {
      return undefined;
    }

    // If the Authentication was successful, and Primus.js is finished loading, then we can actually connect:
    const connection = GlobalPrimus.connect(
      `${REALTIME_URL}/listen`,
      PrimusOptions,
    );

    // After connecting, set up some event handlers:
    connection.on('open', () => {
      // "ready" means that the Primus connection is truly fully established.
      setPrimusStatus(RealtimeStatuses.READY);
    });
    connection.on('error', (err: any) => {
      debug(`Realtime error:`, err);
      setPrimusStatus(RealtimeStatuses.ERROR);
    });
    connection.on('end', () => {
      setPrimusStatus(RealtimeStatuses.END);
    });

    connection.on('data', (response: RealtimeEventData) => {
      // List of viewers in the room:
      if (response.cmd === 'roomState') {
        setRoomViewers(response.data.users);
      }
      // Alert that something in the page was updated:
      else if (response.cmd === 'event') {
        setRoomHasUpdates(response.data as Update);
      }
    });

    return connection;
  }, [success, GlobalPrimus]);

  return {
    primus,
    primusStatus,
    roomViewers,
  };
};

/**
 * Makes all of the calls necessary for a component to be prepared to make use of Realtime
 * @param updateCallback - Callback to get triggered whenever there is an "event" type update in Realtime (i.e. Candidate got updated)
 * @returns The established connection (`primus`), the ongoing realtime data (`roomViewers`), and the state of the connection (`primusStatus`)
 */
export const useSetupRealtime = (updateCallback?: Function) => {
  // First step - begin an async download of the primus.js file from the server
  const PrimusLoadStatus = useScript(`${REALTIME_URL}/listen/primus.js`);
  const GlobalPrimus = useMemo(() => {
    // Once the Primus.js file has finished downloading, grab it as a variable for easy use
    if (PrimusLoadStatus === ScriptStatuses.READY) {
      return (window as any)?.Primus;
    }
    return undefined;
  }, [PrimusLoadStatus]);

  // Second step - Make an auth call to the Realtime server to get a token
  const {
    authCall,
    authResult: { data: authData },
  } = useRealtimeAuth();

  // Third step - establish the true Realtime connection, and set up proper event handlers. This won't go through until the prior steps succeeded
  const { primus, primusStatus, roomViewers } = usePrimus({
    authData,
    GlobalPrimus,
    updateCallback,
  });

  useEffect(() => {
    authCall(); // Only trigger the auth call on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    status: primusStatus,
    primus,
    roomViewers,
  };
};
