import styled from 'styled-components';
import { colors, M, sizes } from '@dashboard-experience/mastodon';
import DashboardLink from 'components/DashboardLink';

export const StatusDropdown = styled(M.Dropdown)`
  .cds--list-box__menu {
    max-height: 20rem !important;
  }
`;

export const SpacedIcon = styled(M.Icon)`
  margin-left: 0.5rem;
`;

export const PostNotice = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: ${sizes.medium};
  padding: 0 !important;
  vertical-align: top;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CellDashboardLink = styled(DashboardLink)`
  color: ${colors.uiTextPrimaryLight} !important;
  cursor: pointer;
  display: flex;

  &.limited-role {
    cursor: default;
  }
`;

export const StyledPendingStatus = styled.span`
  color: ${colors.uiGrey500} !important;
  display: inline-flex;
  font-style: italic;
`;

export const IconTableHeader = styled(M.TableHeader)`
  &&&.mastodon-table-header {
    padding: 0 !important;
  }
`;

export const StyledModalBody = styled(M.ModalBody)`
  i {
    font-style: italic;
  }

  ul {
    padding-inline-start: 40px;

    li {
      list-style-type: disc !important;
    }
  }

  b {
    font-weight: bold !important;
  }
`;

export const ButtonAsLink = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: ${sizes.medium};
  padding: 0 !important;
`;

export const StyledDiv = styled.div`
  min-height: 575px;
`;

export const FlexSpan = styled.span`
  display: flex;
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const SendBulkNoticesProgressBar = styled(M.ProgressBar)`
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important;
  height: 0.2rem !important;
`;

export const SendBulkNoticesProgressBarContainer = styled.div`
  padding: 0.5rem 0;
`;

export const SendBulkNoticesDatePickerContainer = styled.div`
  display: flex;
  div.cds--form-item {
    flex: 0 1 auto;
  }
`;

export const SendBulkNoticesClearDatesButton = styled(M.Button)`
  margin-top: 1rem !important;
`;

export const SendBulkNoticesSendingSpinner = styled(M.LoadingInline)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 1rem;
`;

export const SendBulkNoticesError = styled.span`
  color: ${colors.uiOrange600};
  padding: 0.5rem 0.5rem 0 0;
`;

export const SendBulkNoticesSuccess = styled.span`
  color: ${colors.uiGreen600};
  padding: 0.5rem 0.5rem 0 0;
`;

export const GetBulkNoticesError = styled.span`
  color: ${colors.uiOrange600};
`;

export const GetBulkNoticesSuccess = styled.span`
  color: ${colors.uiGreen600};
`;
