import React, { useContext } from 'react';
import UserContext from 'context/CurrentUser';
import { ManualOrder } from 'components/Invitations';
import { LoadingScreen } from 'components/Account';

const ManualOrderContainer = () => {
  const currentUser = useContext(UserContext);
  return currentUser?.account?.id ? (
    <ManualOrder account={currentUser.account} />
  ) : (
    <LoadingScreen />
  );
};

ManualOrderContainer.propTypes = {};

export default ManualOrderContainer;
