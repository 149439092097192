import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { useGetReport } from 'api/reports';

const Container = styled.div`
  color: ${colors.uiTextPrimaryLight};
  padding-top: 0.5rem;
`;

type Props = {
  base_path: string;
};

const Header: React.FC<Props> = ({ base_path }) => {
  const from = `${base_path}.from`;
  const via = `${base_path}.via`;
  const to = `${base_path}.to`;
  const subject = `${base_path}.subject`;
  const notice = `${base_path}.notice`;

  const currentUser = useUser();
  const { report } = useGetReport();
  const { t } = useTranslation();

  return (
    <Container data-testid='pre-aa-modal-header'>
      <div>
        <strong>{t(from)}:</strong> {currentUser.account.adverse_action_email}{' '}
        <strong>{t(via)}</strong> checkr.com
      </div>

      <div>
        <strong>{t(to)}:</strong> {report?.candidate?.email}
      </div>

      <br />

      <div>
        <strong>{t(subject)}:</strong>{' '}
        {`${t(notice)} - ${currentUser.account.name}`}
      </div>
    </Container>
  );
};

export default Header;
