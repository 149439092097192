import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Dialog } from 'modules/assess/ui/common';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/geo';
import Content from './content';
import Actions from './actions';

type Props = {
  geos: Entity.List;
  onClose?: (event?: SyntheticEvent) => void;
  open: boolean;
  setGeos: (geos: Entity.List) => void;
};

export const GeosDialog: React.FC<Props> = ({
  geos,
  onClose,
  open,
  setGeos,
}) => {
  const [tmpGeos, setTmpGeos] = useState(geos);
  const { t } = useTranslation('assess');
  const modalTitle = t(
    'settings.v1.adverse_action_automation.dialog.geos.title',
  );

  const handleAdd = useCallback(() => {
    setGeos(tmpGeos);
    onClose?.();
  }, [onClose, setGeos, tmpGeos]);

  const handleClose = useCallback(() => {
    setTmpGeos(geos);
    onClose?.();
  }, [geos, onClose]);

  return (
    <Dialog
      data-testid='assess-v1-settings-adverse-action-automation-geos-dialog'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='assess-v1-settings-adverse-action-automation-geos-dialog-title'
        title={modalTitle}
        closeModal={handleClose}
      />
      <M.ModalBody data-testid='assess-v1-settings-adverse-action-automation-geos-dialog-content'>
        <Content geos={tmpGeos} setGeos={setTmpGeos} />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-v1-settings-adverse-action-automation-geos-dialog-actions'>
        <Actions disabled={false} onClose={handleClose} onAdd={handleAdd} />
      </M.ModalFooter>
    </Dialog>
  );
};

export default GeosDialog;
