import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Flag } from '@dashboard-experience/react-flagr';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components';
import { updateParentWindowUrl } from 'utils';
import { ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY } from 'Constants';
import { useUser } from 'context/CurrentUser';
import { hasPermission } from '@dashboard-experience/utils';

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  @media (max-width: 519px) {
    flex-direction: column;
  }

  h1 {
    font-size: 2rem !important;
  }
`;

const HeadingButtonsContainer = styled.div`
  display: inline-flex;
  margin-left: auto;

  .mastodon-button.primary {
    height: fit-content;
  }
  @media (max-width: 519px) {
    flex-direction: column-reverse;
    margin-left: unset;
    max-width: fit-content;

    #order-background-check {
      margin: 1rem 0 !important;
    }

    .manual-order {
      max-width: fit-content;
    }
  }
`;

const StyledIcon = styled(M.Icon)`
  stroke: ${colors.uiGrey0};
  stroke-width: 1.5px;
`;

type HeaderProps = {
  contextId: string | null;
};

const Header = ({ contextId }: HeaderProps) => {
  const history = useHistory();

  const { t } = useTranslation();
  const title = {
    label: t(`pageHeaders.packages.title`),
    // Id to track component in Pendo
    props: { className: 'mastodon-packages-title' },
  };

  const currentUser = useUser();
  const userCanManagePackages = hasPermission(currentUser, 'manage_packages');

  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  const handleButtonClick = useCallback(
    () => navigate('/package/get-started'),
    [navigate],
  );

  return (
    <HeadingContainer>
      <PageHeader
        style={{
          color: colors.uiTextPrimaryLight,
          marginBottom: '0',
          display: 'inline-block',
        }}
        title={title}
      />
      <Flag flagKey={ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY} variantKey='true'>
        {userCanManagePackages && (
          <HeadingButtonsContainer>
            <M.Button
              id='create-new-package'
              kind='primary'
              size='sm'
              onClick={handleButtonClick}
            >
              <StyledIcon icon='Add' />
              Create new package
            </M.Button>
          </HeadingButtonsContainer>
        )}
      </Flag>
    </HeadingContainer>
  );
};

export default Header;
