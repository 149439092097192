import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Text } from './Text';
import { C_MVR, C_CRIMINAL } from '../../Constants';

const ContinuousServicesHeader = () => (
  <Text>
    <span>
      Keep up with your candidates by tracking updates to their eligibility.
      Continuous checks improve your overall trust and safety program by
      surfacing recent reportable information throughout your engagement.
    </span>
    <br />
    <span>
      Visit our Help Center to learn more about{' '}
      <M.Link
        href='https://help.checkr.com/hc/en-us/articles/360016337054-Continuous-Crim-The-new-standard-of-safety'
        target='_blank'
      >
        {C_CRIMINAL}
      </M.Link>{' '}
      and{' '}
      <M.Link
        href='https://help.checkr.com/hc/en-us/articles/4410112521111-Continuous-MVR-The-new-standard-in-driver-safety'
        target='_blank'
      >
        {C_MVR}.
      </M.Link>{' '}
    </span>
  </Text>
);

export default ContinuousServicesHeader;
