import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  RuleYears,
  Type,
} from 'modules/assess/models/rulesets/growth-insights/lookback-rules';

type Props = {
  item: Type;
};

const recommendationYears = (ruleYears: RuleYears) => {
  return (
    <span>
      <span className='original'>{ruleYears.original}</span> &rarr;{' '}
      <span className='recommended'>{ruleYears.recommended}</span>
    </span>
  );
};

const Container = styled.div`
  margin-bottom: 1em;

  .decision {
    display: inline-block;
    width: 6em;
    text-align: right;
    padding-right: 1em;
  }

  .recommended {
    color: ${colors.brandGreen4};
  }
`;

export const Recommendation: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('assess');
  const recommendation = t('assess:rulesets.growth_insights.recommendation');
  const eligible = t('assess:decisions.eligible');
  const escalated = t('assess:decisions.escalated');
  const recommendationEligible = recommendationYears(item.eligible_years);
  const recommendationEscalated = recommendationYears(item.escalated_years);
  return (
    <Container className='recommendation'>
      <h6>{recommendation}</h6>
      <ul>
        <li className='eligible'>
          <span className='decision'>{eligible}:</span>
          <span className='years'>{recommendationEligible}</span>
        </li>
        <li className='escalated'>
          <span className='decision'>{escalated}:</span>
          <span className='years'>{recommendationEscalated}</span>
        </li>
      </ul>
    </Container>
  );
};

export default Recommendation;
