import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';
import {
  accountHasPermission,
  CurrentUser,
  hasPermission,
} from '@dashboard-experience/utils';

type ManualOrderButtonProps = {
  currentUser: CurrentUser;
  navigate: Function;
};

const ManualOrderButton = ({
  currentUser,
  navigate,
}: ManualOrderButtonProps) => {
  const handleOrderClick = useCallback(
    () => navigate('reports/new'),
    [navigate],
  );

  return (
    <M.Restrict
      authorized={
        accountHasPermission(currentUser, 'manual_orders_enabled') &&
        hasPermission(currentUser, 'create_manual_orders') &&
        accountHasPermission(currentUser, 'allow_dashboard_report_ordering')
      }
    >
      <M.Button
        className='manual-order'
        kind='secondary'
        size='sm'
        onClick={handleOrderClick}
      >
        Manual order
      </M.Button>
    </M.Restrict>
  );
};

ManualOrderButton.propTypes = {
  currentUser: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ManualOrderButton;
