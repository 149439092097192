/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce, defaults, isEqual } from 'lodash';
import querystring from 'querystring';
import { extendParamsObject } from 'utils';
import { ErrorText } from 'components/Text';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import AdverseActionsTable from './AdverseActionsTable';
import { StyledDiv } from './AdverseActionsStyledComponents';
import PauseReviewModal from './PauseReviewModal';
import { numericParamKeys, ParamKeys, stringParamKeys } from './param-keys';
import { PAUSED, STATUSES, statusItemToParam } from './Statuses';
import SortingDirection from './sort-direction';
import { AdverseAction } from './types';
import { useFetchAdverseActions } from '../../api/adverseActions';
import { SearchParams } from '../../modules/adjudication/api';
import { useUser } from '../../context/CurrentUser';

const { ASC, DESC } = SortingDirection;
const DEFAULT_PARAMS = {
  page: 1,
  per_page: 25,
  order_by: ParamKeys.CREATED_AT,
  sort: DESC,
  status: undefined,
};

const clean = (params: GenericObject) => {
  Object.keys(params).forEach(
    key => params[key] === undefined && delete params[key],
  );
  return params;
};

export type State = {
  currentAdverseAction: AdverseAction;
  openModal: boolean;
  params: GenericObject;
  statuses: Array<string>;
};

const AdverseActionsPage = () => {
  const { account } = useUser();

  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const [state, setState] = useState<State>({
    currentAdverseAction: {} as AdverseAction,
    openModal: false,
    params: {} as SearchParams,
    statuses: [],
  });

  const {
    call,
    result: { isError, data },
  } = useFetchAdverseActions();

  const fetch = useRef(
    debounce(params => {
      call(params).then(() => {
        setHasBeenInitialized(true);
      });
    }, 500),
  ).current;

  useEffect(
    () => () => {
      fetch.cancel();
    },
    [fetch],
  );

  // on account changes
  useEffect(() => {
    const { adverse_action_pause, offline_adverse_action_enabled } = account;
    let statuses = STATUSES;
    if (!adverse_action_pause) {
      statuses = statuses.filter(status => status !== PAUSED);
    }
    // only render paused status for Amazon (offline_adverse_action_enabled)
    if (offline_adverse_action_enabled) {
      statuses = statuses.filter(status => status === PAUSED);
    }
    setState(state => ({ ...state, statuses }));
  }, []);

  // on location changes

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // update state.params
    const search = querystring.parse(location.search.replace('?', ''));

    setState(state => {
      const params = extendParamsObject(
        state.params,
        search,
        numericParamKeys,
        stringParamKeys,
      );

      if (isEqual(state.params, params)) {
        return state;
      }

      return { ...state, params };
    });
  }, [location.search]);

  // on state.params changes
  useEffect(() => {
    // set default params

    setState((state: State) => {
      const params = { ...state.params };

      defaults(params, DEFAULT_PARAMS, {
        status: statusItemToParam(state.statuses[0]),
      });

      if (isEqual(state.params, params)) {
        return state;
      }

      return { ...state, params };
    });
  }, [state.params, state.statuses]);

  // on state.params changes
  useEffect(() => {
    // fetch data
    if (!state.params.status) {
      return;
    }

    const params = clean({ ...state.params });
    fetch(params);
  }, [fetch, state.params]);

  // on state.params changes
  useEffect(() => {
    // update url
    const params = clean({ ...state.params });
    const search = querystring.encode(params);
    history.push({ search });
  }, [history, state.params]);

  const onSortClick = debounce(key => {
    const { order_by, sort } = state.params;
    let newSort;
    if (order_by !== key) {
      newSort = DESC;
    } else {
      newSort = sort === DESC ? ASC : DESC;
    }
    const newParams = {
      ...state.params,
      order_by: key,
      sort: newSort,
    };

    setState(state => ({ ...state, params: newParams }));
  }, 500);

  const onSearchUpdate = (updates: any) => {
    setState(state => {
      const params = updates ? { ...state.params, ...updates } : {};

      if (isEqual(state.params, params)) {
        return state;
      }

      return { ...state, params };
    });
  };

  const showPostAdverseActionNoticeModal = (adverseAction: AdverseAction) => {
    setState(state => ({
      ...state,
      openModal: true,
      currentAdverseAction: adverseAction,
    }));
  };

  const closeModal = () => {
    const { openModal } = state;
    setState(state => ({ ...state, openModal: !openModal }));
  };

  if (!hasBeenInitialized) {
    return <M.LoadingSpinner data-testid='loading-aa' />;
  }

  if (isError) {
    return (
      <StyledDiv>
        <ErrorText>
          <em>There was an error fetching the logs.</em>
        </ErrorText>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <AdverseActionsTable
        state={state}
        closeModal={closeModal}
        onSearchUpdate={onSearchUpdate}
        onSortClick={onSortClick}
        showPostAdverseActionNoticeModal={showPostAdverseActionNoticeModal}
        adverseActions={data}
      />
      <PauseReviewModal
        params={state.params as SearchParams}
        onSearchUpdate={onSearchUpdate}
      />
    </StyledDiv>
  );
};

export default AdverseActionsPage;
