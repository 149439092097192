import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Entity from 'modules/assess/models/settings/package';
import T from 'components/Table';
import Screenings from './Screenings';

type Props = {
  packages?: Entity.List;
  selections?: { [id: string]: boolean };
  onSelect?: (p: string, selected: boolean) => void;
  readOnly: boolean;
};

const keys = ['name'];

export const Table: React.FC<Props> = ({
  packages = [],
  selections = {},
  onSelect = () => {},
  readOnly,
}) => {
  const { t } = useTranslation('assess');

  const expansion = useCallback(
    (id: string) => {
      return <Screenings id={id} packages={packages} />;
    },
    [packages],
  );

  const headers = keys.map(key => ({
    key,
    header: t(`assess:settings.packages.dialog.table.${key}`),
  }));

  return (
    <T
      disabled={readOnly}
      expandable
      expansion={expansion}
      headers={headers}
      rows={packages}
      selections={selections}
      useSelections
      onSelect={onSelect}
    />
  );
};

export default Table;
