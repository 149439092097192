import React, { useCallback, useState } from 'react';
import Button from 'components/Button';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { exportCodes } from 'modules/assess/api/category-code-set/codes';
import styled from 'styled-components';
import UploadCsvButton from 'modules/assess/ui/mvr/category-sets/collection/upload-csv-button';
import { useCCSV } from '../../context';

type Props = {
  type: ItemType;
};

const ActionsRow = styled.div`
  margin: 1.5rem 0 0 0;
  .mastodon-button {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mastodon-button:last-child {
    margin-right: 0 !important;
  }
  .mastodon-button:first-child {
    margin-left: 0 !important;
  }
`;

const ImportExport: React.FC<Props> = ({ type }) => {
  const [exportDisabled, setExportDisabled] = useState(false);
  const ccsv = useCCSV();

  const handleExport = useCallback(() => {
    setExportDisabled(true);
    exportCodes(ccsv?.category_code_set?.id, ccsv?.id, type).then(() => {
      setExportDisabled(false);
    });
  }, [ccsv, type]);

  if (!ccsv || !ccsv.category_code_set) {
    return null;
  }

  return (
    <ActionsRow>
      <Button
        icon='Download'
        kind='secondary'
        trans='assess:mvr.category-sets.instance.versions.instance.common.codes.buttons.export'
        disabled={exportDisabled}
        onClick={handleExport}
      />
      {ccsv?.draft && <UploadCsvButton type={type} />}
    </ActionsRow>
  );
};

export default ImportExport;
