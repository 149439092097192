import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { Kind } from 'modules/assess/models/entity';

export type Type = React.FC<Props>;

export type Props = {
  assigned?: boolean;
  kind?: Kind;
  latest?: boolean;
};

export const StatusIndicator: Type = ({ assigned, kind, latest }) => {
  const label = useLabel(assigned, kind, latest);

  if (!kind) {
    return null;
  }

  return (
    <M.LivenessIndicator live={kind === Kind.final && latest} text={label} />
  );
};

function useLabel(assigned?: boolean, kind?: Kind, latest?: boolean) {
  const { t } = useTranslation('assess');
  const selector = latest ? 'current' : 'default';
  const context = assigned ? 'assigned' : undefined;
  return t(`assess:entity.status.label.${kind}.${selector}`, '', {
    context,
  });
}

export default StatusIndicator;
