import React from 'react';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';
import ReportActionLog from '../header/report-actions/report-action-log';

const EntriesPhaseTwo: React.FC<{}> = ({}) => {
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  return (
    <>
      {candidateRedesignEnabled && (
        <ReportActionLog style={{ margin: '2.5rem 2rem 0px' }} />
      )}
    </>
  );
};

export default EntriesPhaseTwo;
