import * as Dispatch from './dispatch';
import Provider from './provider';
import * as State from './state';

export * from './use-store';

export { Dispatch, Provider, State };

export const useDispatch = Dispatch.useValue;
export const useState = State.useValue;
