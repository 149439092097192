import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';

export const getUtmParams = () => {
  if (window.location.pathname === '/signup/payment') {
    const storedUtmParams = sessionStorage.getItem('utm_params');
    if (storedUtmParams) {
      const utmParams = JSON.parse(storedUtmParams);
      return {
        utm_campaign: utmParams.utm_campaign,
        utm_medium: utmParams.utm_medium,
        utm_content: utmParams.utm_content,
        utm_source: utmParams.utm_source,
        utm_term: utmParams.utm_term,
      };
    }
  }

  const params = new URLSearchParams(window.location.search);
  return {
    utm_campaign: params.get('utm_campaign'),
    utm_medium: params.get('utm_medium'),
    utm_content: params.get('utm_content'),
    utm_source: params.get('utm_source'),
    utm_term: params.get('utm_term'),
  };
};

const patchUtmParams = async (accountId: string) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.patch(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/oauth_progress`,
    getUtmParams(),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

type Props = {
  accountId: string;
};

function usePatchUtmParams() {
  const [mutate, { data, isLoading, error }] = useMutation(
    async ({ accountId }: Props) => {
      const response = await patchUtmParams(accountId);
      return response.data;
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default usePatchUtmParams;
