import { Decision, ItemVisibility } from 'modules/assess';
import { Initializer } from 'state/utilities/state';
import { Entity } from './common/entity';

export type Description = {
  paragraph: string;
  description?: Description[];
};

export type Type = Entity & {
  code: string;
  deprecated?: boolean;
  version: number;
  item_visibility?: ItemVisibility | null;
  position?: number;
  updated?: boolean;
  description: Description[];
};

export const initialize: Initializer<Omit<Type, 'id'>> = () => ({
  code: '',
  conditions: [],
  decision: Decision.REVIEW,
  item_visibility: null,
  name: '',
  version: 0,
  description: [],
});

export type List = Array<Type>;
export type Map = { [key: string]: Type };
