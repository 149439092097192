import { useEffect } from 'react';
import { useUser } from 'context/CurrentUser';
import { createSetupIntent, SetupIntentParams } from 'api/payment';

const useSetClientSecret = ({
  setElementOptions,
  elementOptions,
  setIsProcessing,
  setError,
  paymentType,
}: any) => {
  const user = useUser();

  useEffect(() => {
    const params: SetupIntentParams = {
      accountId: user.account.id,
      paymentType,
    };

    if (!elementOptions) {
      createSetupIntent(params)
        .then(response => {
          setElementOptions(response.client_secret);
        })
        .catch(e => {
          setIsProcessing(false);
          setError(e.message);
        });
    }
  }, [
    elementOptions,
    setElementOptions,
    setIsProcessing,
    setError,
    paymentType,
    user.account.id,
  ]);
};

export default useSetClientSecret;
