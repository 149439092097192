/* eslint-disable import/prefer-default-export */
import { AnyQueryKey, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useErrorNotifier } from 'hooks';
import { fetchTurnaroundTime } from './actions';

export const useFetchTurnaroundTime = (reportId: string) => {
  const errorNotifier = useErrorNotifier();

  const key: AnyQueryKey = ['eta', { id: reportId }];

  const request = () => fetchTurnaroundTime(reportId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: !!reportId,
    onError: (err: AxiosError) => {
      errorNotifier(err, {
        title: 'Error retrieving ETA:',
        ignoreCodes: [404],
      });
    },
  });
};
