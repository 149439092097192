/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable import/prefer-default-export */
import { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { useUser } from 'context/CurrentUser';
import { postMessageToDashboard } from 'utils';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { Screens } from './types';
import UIContext from '../../context/UI';

export const useCsvExport = () => {
  const [screen, setScreen] = useState(Screens.INITIAL);
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useUser();
  const { isIframe } = useContext(UIContext);

  const trackEvent = useTrackEvent();

  const toggle = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        messageId: 'scroll_to_top',
      });
    }
    setOpen(!open);
    if (!open) {
      if (user) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_DOWNLOAD_CSV_BUTTON_CLICKED,
        );
      }
    }
  }, [isIframe, open, user, trackEvent]);

  useEffect(() => {
    open && setScreen(Screens.INITIAL);
  }, [open, setScreen]);

  return {
    screen,
    processing,
    open,
    setScreen,
    setProcessing,
    setOpen,
    toggle,
  };
};

export const useCsvError = (error: boolean, errorMsg: any) => {
  const dispatch = useDispatch();
  const err =
    typeof errorMsg === 'string'
      ? errorMsg
      : 'Error trying to process the CSV export';

  useEffect(() => {
    error && dispatch(toastError(err));
  }, [dispatch, err, error]);
};
