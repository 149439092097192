import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  formatWord,
  addressObjToString,
  GenericObject,
} from '@dashboard-experience/utils';
import { EvictionRecord as EvictionRecordType } from 'types/report/screenings/evictionSearch';

import { ItemType } from 'types/report/item';
import { KeyValueSection } from '../Sections';

export type EvictionRecordProps = {
  record: EvictionRecordType;
};

const EvictionRecord: React.FC<EvictionRecordProps> = ({ record }) => {
  const items: ItemType[] = [];
  const { events } = record;
  const eventItems: ItemType[] = [];

  // iterate over record object and add key/val pairs to items
  Object.entries(record).forEach(keyValArr => {
    const [key, val] = keyValArr;
    const shouldInclude = key !== 'events' && key !== 'id';

    if (val && shouldInclude) {
      items.push({
        itemKey: formatWord(key),
        itemValue:
          key === 'address' ? addressObjToString(val as GenericObject) : val,
      });
    }
  });
  // iterate over events to get Event and File Date
  events.forEach((event: { event: any; file_date: any }) => {
    eventItems.push(
      {
        itemKey: 'Event',
        itemValue: event.event,
      },
      { itemKey: 'File Date', itemValue: event.file_date },
    );
  });

  return (
    <M.Expandable
      titleRowProps={{
        'data-testid': 'expandable_eviction-record',
      }}
      key={record.id}
      title='Eviction Record'
    >
      <KeyValueSection items={items} />
      <KeyValueSection items={eventItems} />
    </M.Expandable>
  );
};

export default EvictionRecord;
