import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Candidate } from 'api/i9';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import CandidateCell from './CandidateCell';
import useSubmitFormsI9 from './hooks/useSubmitFormsI9';
import { IFormI9 } from './Models';
import ValidationErrors from './utils/types';
import I9CoordinatorInformationValidations from './utils/i9coordinatorInformation';
import useNavigate from './hooks/useNavigate';
import {
  FlexContainer,
  FlexRow,
  FlexRowDistribute,
  FlexRowJustify,
  InformationIcon,
} from './style';

const TableContainer = styled.div`
  .mastodon-table-container {
    width: 53rem;
  }
`;

const headerDefinitions = [
  {
    key: 'full_name',
    header: 'Name',
  },
  {
    key: 'worksite',
    header: 'Worksite address',
  },
  {
    key: 'start_date',
    header: 'Start date',
  },
];

type OrderI9ReviewSubmitProps = {
  candidatesData: Map<string, Candidate & { isAdded: boolean }>;
  handlePreviousStep: () => void;
  handleCandidateSelection: (
    param: {
      selectedCandidate: Candidate;
      checked: boolean;
    }[],
  ) => void;
  handleSubmitDone: () => void;
  selectedWorkflow: string;
};

const setMapStateValue =
  (id: string, value: string) => (prevState: Map<string, string>) => {
    const result = new Map(prevState);
    result.set(id, value);
    return result;
  };

const OrderI9ReviewSubmit: React.FC<OrderI9ReviewSubmitProps> = ({
  candidatesData,
  handlePreviousStep,
  handleCandidateSelection,
  handleSubmitDone,
  selectedWorkflow,
}) => {
  const [worksiteIds, setWorksiteIds] = useState(new Map<string, string>());
  const [startDates, setStartDates] = useState(new Map<string, string>());
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, { field: string; message: string }>
  >({});

  useEffect(() => {
    if (candidatesData?.size === 0) handlePreviousStep();
  }, [candidatesData, handlePreviousStep]);

  const onRemoveClickHandler = useCallback(
    rowId => {
      const selectedCandidate = candidatesData.get(rowId);

      if (selectedCandidate) {
        handleCandidateSelection([
          {
            selectedCandidate,
            checked: false,
          },
        ]);
      }
    },
    [candidatesData, handleCandidateSelection],
  );

  const onWorksiteChangedHandler = useCallback(
    (rowId, worksiteId) => {
      setWorksiteIds(setMapStateValue(rowId, worksiteId));
    },
    [setWorksiteIds],
  );

  const onStartDateChangedHandler = useCallback(
    (rowId, startDate) => {
      setStartDates(setMapStateValue(rowId, startDate));
    },
    [setStartDates],
  );

  const submit = useSubmitFormsI9(() => handleSubmitDone());

  const initialValues: I9Coordinator = {
    firstName: '',
    lastName: '',
    emailAddress: '',
  };

  type I9Coordinator = {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };

  const i9CoordinatorVisible = useMemo(
    () => selectedWorkflow === 'employer_appointment',
    [selectedWorkflow],
  );

  const { values, errors, touched, handleSubmit, handleChange } =
    useFormik<I9Coordinator>({
      initialValues,
      validationSchema: I9CoordinatorInformationValidations,
      onSubmit: () => {},
    });

  const showError = useCallback(
    (field: string): boolean => {
      return (errors[field as keyof typeof errors] &&
        touched[field as keyof typeof touched]) as boolean;
    },
    [errors, touched],
  );

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );
  const goBackHandler = useCallback(() => {
    handlePreviousStep();
  }, [handlePreviousStep]);

  const navigate = useNavigate();

  const goToMainI9Page = useCallback(() => navigate('/i-9'), [navigate]);

  const formI9Schema = object({
    candidateId: string(),
    firstName: string(),
    middleName: string(),
    lastName: string(),
    email: string().email().required(),
    fullName: string(),
    startDate: string().required(),
    workflowType: string().required(),
    worksiteId: string().required(),
    authorizedRepresentativeFirstName: string().when('workflowType', {
      is: 'employer_appointment',
      then: string().required(),
    }),
    authorizedRepresentativeLastName: string().when('workflowType', {
      is: 'employer_appointment',
      then: string().required(),
    }),
    authorizedRepresentativeEmail: string().when('workflowType', {
      is: 'employer_appointment',
      then: string()
        .email(ValidationErrors.INVALID_EMAIL)
        .matches(/^(?!.*@[^,]*,)/)
        .required(ValidationErrors.REQUIRED),
    }),
  });
  const arraySchema = array().of(formI9Schema);

  const handleSendI9sClick = useCallback(() => {
    handleSubmit();

    const formsI9: IFormI9[] = [];
    candidatesData.forEach((candidate, key, map) => {
      formsI9.push({
        candidateId: candidate.isAdded ? '' : candidate.candidate_id,
        firstName: candidate.first_name,
        middleName: candidate.middle_name,
        lastName: candidate.last_name,
        fullName: candidate.full_name,
        email: candidate.email,
        startDate: startDates.get(candidate.candidate_id) || '',
        worksiteId: worksiteIds.get(candidate.candidate_id) || '',
        workflowType: selectedWorkflow,
        authorizedRepresentativeFirstName: i9CoordinatorVisible
          ? values.firstName
          : '',
        authorizedRepresentativeLastName: i9CoordinatorVisible
          ? values.lastName
          : '',
        authorizedRepresentativeEmail: i9CoordinatorVisible
          ? values.emailAddress
          : '',
      });
    });

    arraySchema
      .validate(formsI9, { strict: true, abortEarly: false })
      .then(() => {
        setValidationErrors({});
        submit(formsI9);
      })
      .catch(({ errors }: { errors: string[] }) => {
        setValidationErrors(
          errors.reduce((resultErrors, error) => {
            const errorMatch = error.match(/\[(\d+)\]\.(\w+)/);
            if (errorMatch) {
              const candidatesArray = Array.from(candidatesData.entries());
              const [, key, field] = errorMatch;
              const numericKey = +key;
              const id = candidatesArray[numericKey][0] || '';
              return {
                ...resultErrors,
                [id]: {
                  field,
                  message: ValidationErrors.REQUIRED,
                },
              };
            }
            return resultErrors;
          }, {}),
        );
      });
  }, [
    candidatesData,
    i9CoordinatorVisible,
    values.firstName,
    values.lastName,
    values.emailAddress,
    arraySchema,
    startDates,
    worksiteIds,
    selectedWorkflow,
    handleSubmit,
    submit,
  ]);

  const renderTable = useCallback(
    ({ rows, headers }: any) => (
      <TableContainer>
        <M.TableContainer>
          <M.Table>
            <M.TableHead>
              <M.TableRow>
                {headers.map((header: any) => {
                  let informationText;
                  switch (header.key) {
                    case 'worksite':
                      informationText =
                        'Where the employee will be working, which may differ from the primary worksite address.';
                      break;
                    case 'start_date':
                      informationText =
                        'The first day your employee is considered active. ';
                      break;
                    default:
                      informationText = '';
                      break;
                  }

                  return (
                    <M.TableHeader key={header.key}>
                      {informationText ? (
                        <FlexRow gap='0.25rem' alignItems='center'>
                          <span>{header.header}</span>
                          <M.Tooltip label={informationText} align='right-top'>
                            <InformationIcon
                              icon='InformationFilled'
                              size='1.5rem'
                            />
                          </M.Tooltip>
                        </FlexRow>
                      ) : (
                        <span>{header.header}</span>
                      )}
                    </M.TableHeader>
                  );
                })}
              </M.TableRow>
            </M.TableHead>
            <M.TableBody>
              {rows.map((row: any) => (
                <M.TableRow key={row.id}>
                  {row.cells.map((cell: any, i: number) => (
                    <M.TableCell key={headers[i].header}>
                      <CandidateCell
                        cell={cell}
                        id={row.id}
                        error={validationErrors[row.id]}
                        onRemoveClick={onRemoveClickHandler}
                        onWorksiteChanged={onWorksiteChangedHandler}
                        onStartDateChanged={onStartDateChangedHandler}
                      />
                    </M.TableCell>
                  ))}
                </M.TableRow>
              ))}
            </M.TableBody>
          </M.Table>
        </M.TableContainer>
      </TableContainer>
    ),
    [
      validationErrors,
      onRemoveClickHandler,
      onWorksiteChangedHandler,
      onStartDateChangedHandler,
    ],
  );

  return (
    <>
      {i9CoordinatorVisible && (
        <FlexContainer gap='1rem' padding='0'>
          <FlexRow gap='0.25rem' alignItems='center'>
            <h3>I-9 coordinator information</h3>
            <M.Tooltip
              label="The individual responsible for verifying employees' identification and authorization to work."
              align='right-top'
            >
              <InformationIcon icon='InformationFilled' size='1.5rem' />
            </M.Tooltip>
          </FlexRow>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='firstName'
              name='firstName'
              labelText='First name'
              placeholder='Enter first name'
              onChange={handleChange}
              invalid={showError('firstName')}
              invalidText={showErrorMessage('firstName')}
              value={values.firstName}
            />
            <M.Input
              id='lastName'
              name='lastName'
              labelText='Last name'
              placeholder='Enter last name'
              onChange={handleChange}
              invalid={showError('lastName')}
              invalidText={showErrorMessage('lastName')}
              value={values.lastName}
            />
            <M.Input
              id='emailAddress'
              name='emailAddress'
              labelText='Email address'
              placeholder='Enter primary email address'
              onChange={handleChange}
              invalid={showError('emailAddress')}
              invalidText={showErrorMessage('emailAddress')}
              value={values.emailAddress}
            />
          </FlexRowJustify>
        </FlexContainer>
      )}
      <FlexContainer gap='1rem' padding='0'>
        <h3>Employees</h3>
        <M.DataTable
          headers={headerDefinitions}
          rows={Array.from(candidatesData.values())}
          render={renderTable}
        />
      </FlexContainer>
      <FlexRowDistribute alignItems='center'>
        <M.Button
          kind='secondary'
          onClick={goBackHandler}
          data-id='order-i9-final-step-back'
        >
          Back
        </M.Button>
        <FlexRow gap='0' alignItems='center'>
          <M.Button
            kind='secondary'
            onClick={goToMainI9Page}
            data-id='order-i9-final-step-cancel'
          >
            Cancel
          </M.Button>
          <M.Button
            kind='primary'
            onClick={handleSendI9sClick}
            data-id='order-i9-final-step-send-i9s'
            data-testid='order-i9-final-step-send-i9s'
          >
            Submit order
          </M.Button>
        </FlexRow>
      </FlexRowDistribute>
    </>
  );
};

export default OrderI9ReviewSubmit;
