/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { GenericObject } from 'types';
import { toastError, toastSuccess } from 'actions';
import {
  updateDrugScreeningOrder,
  UpdateOrderParams,
  getExpiration,
  setExpiration,
  SetExpirationParams,
  renewScreeningPass,
  resendScreeningInvitation,
  getClinicLocations,
} from './actions';

export const useUpdateDrugScreeningOrder = () => {
  const dispatch = useDispatch();

  const request = (params: UpdateOrderParams) =>
    updateDrugScreeningOrder(params);

  const [call, result] = useMutation(request, {
    onError: err => {
      dispatch(toastError(`Error Updating Drug Screening Order: ${err}`));
    },
  });

  return {
    call,
    result,
  };
};

export const useGetExpiration = (appointment: GenericObject) => {
  const request = () => getExpiration(appointment.appointment_id);
  const key = `getExpiration_${appointment.appointment_id}`;
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useSetExpiration = (params: SetExpirationParams) => {
  const dispatch = useDispatch();

  const request = (params: SetExpirationParams) => setExpiration(params);

  const [call, result] = useMutation(request, {
    onError: err => {
      dispatch(toastError(`Error Updating Health Screening Order: ${err}`));
    },
    onSuccess: () => {
      dispatch(
        toastSuccess('Success:', 'Screening pass expiration has been updated'),
      );
    },
  });

  return {
    call,
    result,
  };
};

export const useRenewScreeningPass = (appointment_id: string) => {
  const dispatch = useDispatch();

  const request = () => renewScreeningPass(appointment_id);

  const [call] = useMutation(request, {
    onError: () => {
      dispatch(
        toastError('error', 'Something went wrong, please try again later.'),
      );
    },
    onSuccess: () => {
      dispatch(toastSuccess('success', 'The screening pass has been renewed.'));
    },
  });

  return {
    call,
  };
};

export const useResendScreeningInvitation = (screening_id: string) => {
  const dispatch = useDispatch();

  const request = () => resendScreeningInvitation(screening_id);

  const [call] = useMutation(request, {
    onError: () => {
      dispatch(
        toastError('error', 'Something went wrong, please try again later.'),
      );
    },
    onSuccess: () => {
      dispatch(
        toastSuccess('success', 'The screening invitation has been re-sent.'),
      );
    },
  });

  return {
    call,
  };
};

export const useClinicLocations = (
  zipcode: string,
  exam_slugs: string | string[],
  account_id: string,
  package_id: string,
  test_package: boolean,
  requires_observed_collection: boolean,
  show_expanded_hours_only: boolean = false,
) => {
  const queryKey = `${zipcode}_${show_expanded_hours_only}`;
  const request = () =>
    getClinicLocations(
      zipcode,
      exam_slugs,
      account_id,
      package_id,
      test_package,
      requires_observed_collection,
      show_expanded_hours_only,
    );

  return useQuery(queryKey, request);
};
