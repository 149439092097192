import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  exam_reorder_modal_body,
  exam_reorder_modal_footer,
} from 'components/Report/Screenings/DrugHealthScreenings/locales';

import { useModalState } from '../../context';
import { getSelectedExams } from './screening-selection/utils';
import ModalBody from './body';

const body = `${exam_reorder_modal_body}.review`;

type Props = {
  call: () => void;
};

const Review: React.FC<Props> = ({ call }) => {
  const { t } = useTranslation();
  const previousScreen = useModalState('previousScreen');
  const { reorderableExams, clinic, appointmentDeadline } =
    useModalState('formData');

  const exams = getSelectedExams(reorderableExams);

  const navigation = {
    back: {
      name: t(`${exam_reorder_modal_footer}.back`),
      onClick: previousScreen,
    },
    next: {
      name: t(`${exam_reorder_modal_footer}.confirm`),
      onClick: call,
    },
  };

  const clinicDescription = clinic?.name
    ? clinic.name
    : t(`${body}.clinic_description`);

  return (
    <>
      <ModalBody>
        <section>
          <h2>{t(`${body}.title`)}</h2>
          <p style={{ marginBottom: '0.25rem' }}>
            <strong>{t(`${body}.selected_exams_heading`)}</strong>
          </p>
          <M.UnorderedList data-testid='reorderable-exams'>
            {exams.map(exam => (
              <M.ListItem key={exam.slug} data-testid='exam'>
                {exam.name}
              </M.ListItem>
            ))}
          </M.UnorderedList>
        </section>
        {appointmentDeadline && (
          <section data-testid='appointment-deadline'>
            <p>
              <strong>{t(`${body}.new_appointment_deadline_heading`)}</strong>
              <br /> {moment(appointmentDeadline).format('MMMM D, YYYY')}
            </p>
          </section>
        )}
        <section>
          <p>
            <strong>{t(`${body}.clinic_heading`)}</strong>
            <br /> {clinicDescription}
          </p>
        </section>
      </ModalBody>
      <M.ModalNavigationFooter back={navigation.back} next={navigation.next} />
    </>
  );
};

export default Review;
