import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment/moment';
import { scrollToTop } from '../../utils';
import ConfirmModal from '../ConfirmModal';
import {
  Logo,
  ContainerRow,
  StyledCol,
  StyledButton,
  StyledP,
  StyledH5,
} from './IntegrationsStyledComponents';
import { Instance, useDeleteApplicationInstance } from '../../api/integrations';

type Props = {
  instance: Instance;
};

const DefaultIntegrationPanel: React.FC<Props> = ({ instance }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { call: deauthorize }: any = useDeleteApplicationInstance();
  const showModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal = useCallback((): void => {
    setModalOpen(false);
  }, [setModalOpen]);

  const confirmDeauthorizeInstance = useCallback((): void => {
    deauthorize(instance);
    hideModal();
  }, [deauthorize, instance, hideModal]);

  return (
    <M.Container type='official' data-testid='integration-item'>
      {/* @ts-ignore */}
      <ConfirmModal
        open={modalOpen}
        callback={confirmDeauthorizeInstance}
        hideModal={hideModal}
        header='Disable integration'
        message='Are you sure you want to turn off this integration?'
      />
      <ContainerRow>
        <StyledCol lg={1} md={1} sm={1}>
          {instance.application.logo_url && (
            <Logo alt='logo' src={instance.application.logo_url} />
          )}
        </StyledCol>
        <M.Container.Col lg={13} md={5} sm={2}>
          <StyledH5>
            {instance.application.name}{' '}
            <span
              style={{
                fontWeight: 'normal',
                fontStyle: 'italic',
                marginTop: 0,
              }}
            >
              {instance.application.url}
            </span>
          </StyledH5>
          <StyledP>
            active since {moment(instance.created_at).format('ll h:mm:ss A')}{' '}
            {!instance.application.live && <span>(test)</span>}
          </StyledP>
        </M.Container.Col>
        <M.Container.Col lg={2} md={2} sm={1}>
          <StyledButton kind='danger' onClick={showModal}>
            Turn off
          </StyledButton>
        </M.Container.Col>
      </ContainerRow>
    </M.Container>
  );
};

export default DefaultIntegrationPanel;
