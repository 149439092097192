import React, { useCallback } from 'react';
import { Button } from 'modules/assess/ui/page';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

export const CancelButton: React.FC<Props> = ({ disabled, onClick }) => {
  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <Button
      size='sm'
      kind='secondary'
      trans='verbs.cancel'
      onClick={handleClick}
      disabled={disabled}
      data-testid='assess-count-editor-cancel-button'
    />
  );
};

export default CancelButton;
