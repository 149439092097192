import React from 'react';
import { useTranslation } from 'react-i18next';

import { Fact } from 'modules/assess/models/criminal/rules';
import { Entity } from 'modules/assess/models/rules/common/entity';
import { Field } from '../field';
import { ConditionsList } from './conditions-list';

type Props = {
  rule: Entity;
};

export const Conditions: React.FC<Props> = ({ rule }) => {
  const { t } = useTranslation('');

  const conditions = rule.conditions.filter(c => c.fact !== Fact.TYPE);
  const label = t('nouns.condition', { count: conditions.length });

  return (
    <Field data-testid='assess-rules-common-rule-conditions' label={label}>
      <ConditionsList conditions={conditions} />
    </Field>
  );
};

export default Conditions;
