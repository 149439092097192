/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const ContentDiv = styled.div<{ isExpanded: boolean }>`
  overflow: hidden;
  overflow-wrap: break-word;
  display: ${props => (props.isExpanded ? 'block' : '-webkit-box')};
  -webkit-line-clamp: ${props => (props.isExpanded ? 'none' : 3)};
  -webkit-box-orient: vertical;
`;

const ExpandableContainerDiv = styled.div<{
  currentUserNote: boolean;
  discarded: boolean;
}>`
  background-color: ${props =>
    props.currentUserNote ? colors.uiNavy100 : colors.uiGrey100};
  border-radius: 4px;
  color: ${props => (props.discarded ? colors.uiGrey400 : colors.uiGrey600)};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 720px;
  padding: 1rem 2rem 1rem 1rem;
`;

type Props = {
  content: string | null;
  currentUserNote: boolean;
  discarded: boolean;
  noteId: string;
};

const ExpandableNoteDiv: React.FC<Props> = ({
  content,
  currentUserNote,
  discarded,
  noteId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = document.getElementById(`content-div-${noteId}`);
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  }, [noteId]);

  const handleClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ExpandableContainerDiv
      data-testid='case-management-internal-notes-content'
      currentUserNote={currentUserNote}
      discarded={discarded}
    >
      <ContentDiv id={`content-div-${noteId}`} isExpanded={isExpanded}>
        {content}
      </ContentDiv>
      {isOverflowing && (
        <div
          data-testid='case-management-internal-notes-expand-view'
          style={{ display: 'flex' }}
        >
          <span
            onClick={handleClick}
            style={{ cursor: 'pointer', color: `${colors.checkrBlue}` }}
          >
            {isExpanded ? 'View less' : 'View more'}
          </span>
        </div>
      )}
    </ExpandableContainerDiv>
  );
};

export default ExpandableNoteDiv;
