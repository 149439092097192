import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FairChanceForm } from 'components/Banners';

type Props = {
  // TODO: better typing
  dispatch: (params: any) => void;
  type: 'assessment' | 're-assessment';
  initialAssessmentValue?: string;
  reassessmentValue?: string;
};

const Form: React.FC<Props> = ({
  dispatch,
  type,
  initialAssessmentValue,
  reassessmentValue,
}) => {
  const { t } = useTranslation('adjudication');

  const onAssessmentChange = useCallback(
    (e) => {
      dispatch({ type: 'SET_INITIAL_ASSESSMENT', payload: e.target.value });
    },
    [dispatch],
  );

  const initialAssessmentPlaceholder = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.initial_assessment_placeholder',
  );

  const reassessmentPlaceholder = t(
    'adjudication:report.actions.pre_adverse.fair_chance_form.reassessment_placeholder',
  );

  return type === 'assessment' ? (
    <FairChanceForm
      initialAssessmentPlaceholder={initialAssessmentPlaceholder}
      initialAssessmentOnChange={onAssessmentChange}
      initialAssessmentDisabled={false}
      reassessmentPlaceholder={reassessmentPlaceholder}
      reassessmentDisabled
    />
  ) : (
    <FairChanceForm
      initialAssessmentValue={initialAssessmentValue}
      initialAssessmentDisabled
      reassessmentValue={reassessmentValue}
      reassessmentOnChange={onAssessmentChange}
      reassessmentPlaceholder={reassessmentPlaceholder}
      reassessmentDisabled={false}
    />
  );
};

export default Form;
