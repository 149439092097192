import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { FactValue, Operator } from 'modules/assess';
import { FactRuleOption } from 'modules/assess/ui/rules/common/rule/editor/conditions';
import { Fact } from 'modules/assess/models/criminal/rules/fact';
import { Fact as FactType } from 'modules/assess/models';

type Props = {
  options: FactRuleOption[];
  selectedOptions?: FactRuleOption;
  fact?: FactType;
  getOptionLabel: (option: FactRuleOption) => string;
  onChange: (option: FactRuleOption) => void;
};

export const ValueSelectorMultiple: React.FC<Props> = ({
  options,
  selectedOptions,
  fact,
  getOptionLabel,
  onChange,
}) => {
  const { t } = useTranslation('');
  const unsortItems = fact === Fact.CHARGE_SEVERITY;
  const getPlaceholder = () => {
    const selectedOptionsLength = (selectedOptions?.value as any[])?.length;

    return selectedOptionsLength > 0
      ? `(${selectedOptionsLength} selected)`
      : '';
  };

  const handleChange = useCallback(
    e => {
      const value = e.selectedItems as FactValue;
      onChange({ operator: Operator.IN, value });
    },
    [onChange],
  );

  const renderOption = useCallback(
    (value: FactValue) => getOptionLabel({ operator: Operator.IN, value }),
    [getOptionLabel],
  );

  return (
    <div data-testid='assess-rules-common-value-selector-multiple'>
      <M.MultiSelect
        id='assess-rules-common-value-selector-multiple'
        filterable
        // TODO: Solve initial selected items issue
        {...(unsortItems ? { compareItems: () => 1 } : undefined)}
        initialSelectedItems={selectedOptions?.value || []}
        items={options.map(o => o.value)}
        itemToString={renderOption}
        placeholder={`${t(
          'selects.condition_option_placeholder',
        )} ${getPlaceholder()}`}
        onChange={handleChange}
        className='consistent-separator'
        alwaysShowSelectionTags
      />
    </div>
  );
};

export default ValueSelectorMultiple;
