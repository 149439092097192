import { useState, useEffect } from 'react';
import { WorkLocation } from 'modules/core-types/continuous_check_subscription';

const useWorkLocation = (workLocation: WorkLocation | undefined) => {
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(
    workLocation || {},
  );
  const [usState, setUsState] = useState(workLocation?.state);
  const [country, setCountry] = useState(workLocation?.country);
  const [city, setCity] = useState(workLocation?.city);

  // if initial workLocation changes update it's state
  useEffect(() => {
    workLocation && setSelectedWorkLocation(workLocation);
  }, [workLocation, setSelectedWorkLocation]);

  useEffect(() => {
    setSelectedWorkLocation({
      country,
      city,
      state: usState,
    });
  }, [usState, country, city, setSelectedWorkLocation]);

  return {
    selectedWorkLocation,
    setSelectedWorkLocation,
    setUsState,
    setCountry,
    setCity,
  };
};

export default useWorkLocation;
