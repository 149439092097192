import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  getStatusTypeForReport,
  StatusTypes,
  getReportDecision,
  getReportStatus,
  Report,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import moment from 'moment';
import { useUser } from 'context/CurrentUser';

const Badge = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;
const ReportDate = styled.span`
  margin-right: 0.25rem;
  font-weight: bold;
`;
const ArchivedIcon = styled(M.Icon)`
  vertical-align: bottom;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
  fill: ${colors.uiGrey900}a6 !important;
`;

const ReportBadge: React.FC<{ report: Report }> = ({ report }) => {
  const currentUser = useUser();
  const statusType = getStatusTypeForReport(report, currentUser);
  // TODO - this should happen in Mastodon
  const status =
    StatusTypes.Assess === statusType
      ? // @ts-ignore Current user should be optional in getReportDecision
        getReportDecision(report) || getReportStatus(report, statusType)
      : getReportStatus(report, statusType);
  const { created_at } = report;
  const date = created_at ? moment(created_at).format('MM/YYYY') : '-';
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const archived = report?.archived || false;
  return (
    <Badge>
      {archived && <ArchivedIcon icon='Box' size={16} />}
      <ReportDate>{date}</ReportDate>
      <M.StatusBadge status={status} label={status} />
    </Badge>
  );
};

export default ReportBadge;
