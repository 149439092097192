import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useFetchNodes } from 'api/nodes';
import { useList as useNodePackages } from 'api/nodePackage';
import {
  parseNodeFilter,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useNewInvitations } from './hooks';
import Context from './context';

type Props = {
  account: {
    hierarchy_present: boolean;
    segmentation_enabled: boolean;
    id: string;
    [key: string]: any;
  };
};

const Provider: React.FC<Props> = ({ account, children }) => {
  const { hierarchy_present } = account;
  const {
    geos = [],
    programs = [],
    packages = [],
    node: singleNode = {},
    payment_profiles = [],
    billing_entity_enabled,
    isLoading: anyLoading,
  } = useNewInvitations({
    account,
  });

  const isSingleNode = singleNode?.count === 1;

  // Mark filter dirty immediately when changed
  // but debounce updating it to prevent overfetching
  const [nodeFilterIsDirty, setNodeFilterIsDirty] = useState(false);
  const [nodeFilter, setNodeFilter] = useState('');

  const debouncedSetNodeFilter = useDebouncedCallback(
    (newFilter: string) => {
      setNodeFilter(newFilter);
      setNodeFilterIsDirty(false);
    },
    350,
    [debounce, setNodeFilter, setNodeFilterIsDirty],
  );

  const onNodeFilterChange = useCallback(
    newFilter => {
      setNodeFilterIsDirty(true);
      debouncedSetNodeFilter(newFilter);
    },
    [debouncedSetNodeFilter, setNodeFilterIsDirty],
  );

  const { data: { data: nodes = [] } = {}, isLoading: nodeIsLoading } =
    useFetchNodes({
      account,
      nodeName: isSingleNode ? '' : parseNodeFilter(nodeFilter).name,
    });

  const [selectedNode, setSelectedNode] = useState<any>();
  // If there is only one node on the account, select it automatically
  useEffect(() => {
    if (isSingleNode && hierarchy_present) setSelectedNode(singleNode.data[0]);
  }, [isSingleNode, singleNode, setSelectedNode, hierarchy_present]);

  // If we are ordering a new report for an existing candidate, we need to handle internal users viewing external account's candidates
  const currentUser = useUser();
  const includeAccountId = currentUser.account.id !== account.id;

  const { data: { packages: nodePackages = [] } = {} } = useNodePackages({
    account,
    customId: selectedNode?.custom_id,
    includeAccountId,
  });

  const value = useMemo(
    () => ({
      account,
      geos,
      anyLoading,
      isSingleNode,
      nodes: isSingleNode ? singleNode.data : nodes,
      nodeIsLoading: nodeIsLoading || nodeFilterIsDirty,
      nodePackages,
      onNodeChange: setSelectedNode,
      onNodeFilterChange,
      selectedNode,
      packages,
      programs,
      payment_profiles,
      billing_entity_enabled,
    }),
    [
      account,
      geos,
      anyLoading,
      isSingleNode,
      singleNode?.data,
      nodes,
      nodeIsLoading,
      nodeFilterIsDirty,
      nodePackages,
      setSelectedNode,
      onNodeFilterChange,
      selectedNode,
      packages,
      programs,
      payment_profiles,
      billing_entity_enabled,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
