import { camelCase, isArray, isObject, map, reduce } from 'lodash/fp';

export const convertCaseInObject = <T>(item: object): T =>
  reduce(
    (acc: any, key: string) => {
      // @ts-ignore
      if (isArray(item[key])) {
        // @ts-ignore
        return { ...acc, [`${camelCase(key)}`]: convertCaseInArray(item[key]) };
      }
      // @ts-ignore
      if (isObject(item[key])) {
        return {
          ...acc,
          // @ts-ignore
          [`${camelCase(key)}`]: convertCaseInObject(item[key]),
        };
      }
      // @ts-ignore
      return { ...acc, [`${camelCase(key)}`]: item[key] };
    },
    {},
    Object.keys(item),
  );

export const convertCaseInArray = <T>(operand: T[]): T[] =>
  map(convertCaseInObject, operand) as unknown as T[];
