/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import CriminalRecordsSearchesItem from './CriminalRecordsSearchesItem';
import VerificationsItem from './VerificationsItem';

const Accordion = styled(M.Accordion)`
  #mastodon && {
    .cds--accordion__item--active > .cds--accordion__heading {
      border-bottom: 1px solid ${colors.uiGrey200} !important;
      .cds--accordion__arrow {
        transform: rotate(-90deg) scale(1.5) !important;
      }
    }

    .cds--accordion__wrapper {
      padding: 0px;
    }

    .cds--accordion__wrapper {
      padding: 0px 0px 0px 48px !important;
    }

    .cds--accordion__item:last-child {
      margin-bottom: 0px;
    }
  }
`;

const ScreeningList = styled.ul``;

const InternationalAddScreeningsList: React.FC<any> = ({
  onAddClick,
  onRemoveClick,
  screeningTypesInSearch,
  disabledScreenings,
  addedScreenings,
}) => {
  const actionProps = {
    onAddClick,
    onRemoveClick,
    disabledScreenings,
    addedScreenings,
  };

  return (
    <ScreeningList>
      <Accordion>
        <CriminalRecordsSearchesItem
          screeningTypesInSearch={screeningTypesInSearch}
          actionProps={actionProps}
        />
        <VerificationsItem
          screeningTypesInSearch={screeningTypesInSearch}
          actionProps={actionProps}
        />
      </Accordion>
    </ScreeningList>
  );
};

export default InternationalAddScreeningsList;
