import React, { useCallback, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Categories from './Categories';
import ExpandCollapseAll from './ExpandCollapseAll';
import { HEADERS, ROWS } from './constants';
import Context from './context';
import SubHeaders from './SubHeaders';
import { RenderProps, Column } from './types';

type Props = {
  disabled?: boolean;
};

const { TableContainer, Table, TableHead, TableRow, TableBody, TableHeader } =
  M.DataTable;

const StyledRow = styled(TableRow)`
  th:first-of-type {
    border: 1px solid ${colors.uiGrey0} !important;
    font-weight: normal !important;
    padding-left: 0 !important;
    padding-top: 1em !important;
    span {
      text-align: left;
      padding-bottom: 0;
    }
    .cds--table-header-label {
      text-align: initial;
    }
  }
`;

const StyledHeader = styled(TableHeader)`
  border-top: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 25%;
  .cds--table-header-label {
    text-align: center;
  }
`;

const LookbackTable: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation('assess');

  const [search, setSearch] = useState('');
  const [bulkEdit, setBulkEdit] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  const columns: Column[] = HEADERS.map(h => ({
    ...h,
    header: t(`assess:rules.lookback.table.headers.${h.key}`, ''),
  }));

  columns[0].header = <ExpandCollapseAll />;

  const render = useCallback(
    ({ rows, headers, getHeaderProps }: RenderProps) => {
      return (
        <TableContainer data-testid='assess-lp-table'>
          <Table>
            <TableHead>
              <StyledRow data-testid='assess-lp-table-header-rows'>
                {headers.map((header: Column) => (
                  <StyledHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    colSpan={2}
                    data-testid='assess-lp-table-header'
                  >
                    {header.header}
                  </StyledHeader>
                ))}
              </StyledRow>
            </TableHead>
          </Table>
          <Table>
            <TableHead>
              <SubHeaders />
            </TableHead>
          </Table>
          <Table>
            <TableBody>
              <Categories rows={rows} />
            </TableBody>
          </Table>
        </TableContainer>
      );
    },
    [],
  );

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        disabled,
        search,
        bulkEdit,
        setSearch,
        setBulkEdit,
        expandAll,
        collapseAll,
        setExpandAll,
        setCollapseAll,
      }}
    >
      <M.DataTable rows={ROWS} headers={columns} render={render} />
    </Context.Provider>
  );
};

export default LookbackTable;
