import React, { useEffect, useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useGetCohortCsv } from 'api/randomDrugTesting';

type Props = {
  id: number;
  eligibleEmployeesCount: number;
  withinPage: boolean;
};

const LoadingInline = styled(M.LoadingInline)`
  min-height: 0.875rem;
  margin-bottom: 5px;
  .cds--inline-loading__text {
    font-size: 0.875rem;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 5px;

  p[data-testid='eligible-employees-error'] {
    color: ${colors.uiOrange600};
    margin-top: 5px !important;
  }
`;

export const downloadCsv = (csvData: string) => {
  // Convert CSV data to downloadable format and initiate download
  if (!csvData) return;
  const url = `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`;
  const a = document.createElement('a');
  a.href = url;
  a.download = 'exported_random_testing_candidates.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const EligibleEmployees: React.FC<Props> = ({
  id,
  withinPage,
  eligibleEmployeesCount,
}) => {
  const [fetchCsv, { isLoading, isError, data }] = useGetCohortCsv(id);
  const label = `${eligibleEmployeesCount} eligible employees`;

  const handleDownload = useCallback(() => {
    if (!isLoading && data) {
      return downloadCsv(data);
    }
    return fetchCsv();
  }, [data, isLoading, fetchCsv]);

  // trigger CSV download when data is available
  useEffect(() => {
    if (!isLoading && !isError && data) {
      downloadCsv(data as string);
    }
  }, [isLoading, isError, data]);

  if (!withinPage || !eligibleEmployeesCount) {
    return (
      <p
        style={{ marginBottom: '5px' }}
        data-testid='eligible-employees-paragraph'
      >
        {label}
      </p>
    );
  }

  if (isLoading) {
    return (
      <LoadingInline
        data-testid='eligible-employees-loading'
        description='Downloading CSV'
      />
    );
  }

  return (
    <LinkContainer>
      <M.Link role='link' href='#' onClick={handleDownload}>
        {label}
      </M.Link>
      {isError && (
        <p data-testid='eligible-employees-error'>
          Error downloading the CSV. Please try again.
        </p>
      )}
    </LinkContainer>
  );
};

export default EligibleEmployees;
