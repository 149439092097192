import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view';
import Icon from './Icon';

type Props = {
  value: Value;
};

const Container = styled.div`
  margin-top: 1rem;
`;

const Description: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation('assess:v2');
  const key = `home.settings.eligible_records.options.${value.toLowerCase()}.description`;
  const components = {
    'eligible-icon': <Icon icon='eligible' />,
  };
  const translation = <Trans t={t} i18nKey={key} components={components} />;
  return (
    <Container id='assess-v2-ui-home-settings-eligible-records-description'>
      {translation}
    </Container>
  );
};

export default Description;
