import { TFunction } from 'i18next';
import { compact, flattenDeep, startCase } from 'lodash';

import {
  CandidateStoryEvent,
  EventView,
  ReportActionEvents,
  ReportActionEventTypes,
} from '../../../data/events';

const byline = (t: Function, type: string, user: string | undefined) => {
  if (!user || type === 'shared') {
    return null;
  }
  return t('adjudication:report.events.byline', {
    type: startCase(type),
    user,
  });
};

const transformReportActionEvent = (
  t: TFunction,
  source: string,
  events: CandidateStoryEvent[] | undefined,
  // add event types to events as they are created
): EventView[] => {
  if (!events) return [];
  if (source === ReportActionEventTypes.CANDIDATE_STORIES) {
    return events.map((event) => {
      const eventMode =
        event.metadata?.mode === 'automated' ? ' - automated ' : '';
      return {
        time: event.time,
        byline: byline(t, event.type, event.user),
        text: `${t('adjudication:report.events.candidate_story')} ${
          event.type
        }${eventMode}`,
      };
    });
  }
  return [];
};

const processReportActionEvents = (
  t: TFunction,
  reportActionEvents: ReportActionEvents,
): EventView[] => {
  return compact(
    flattenDeep(
      Object.entries(reportActionEvents).map((reportAction) =>
        transformReportActionEvent(t, reportAction[0], reportAction[1]),
      ),
    ),
  );
};

export default processReportActionEvents;
