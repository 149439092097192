import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Image from 'components/FairChance/assets/blurry-adverse-action-graph.png';

const StyledImage = styled.img`
  width: 445px;
  height: 140px;
  background: ${colors.uiGrey0};
  margin-bottom: 2.5rem;
  margin-top: 2rem;
`;

const BlurryAdverseActionImage: React.FC = () => {
  return <StyledImage src={Image} alt={Image} />;
};

export default BlurryAdverseActionImage;
