import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type Props = {};

const StyledContainer = styled.div`
  display: flex;
  margin-top: 2em;
`;

export const Content: React.FC<Props> = () => {
  const { t } = useTranslation('assess');

  const mainWarning = t('rules.delete_rule_warning.message');

  return <StyledContainer>{mainWarning}</StyledContainer>;
};

export default Content;
