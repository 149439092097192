import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getResultOrStatus,
  screeningSlugToHumanReadable,
  dateWithTimezone,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { GenericObject, IncidentItem, ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { KeyValueSection } from '../Sections';

const INCIDENT_ITEM_CATEGORIES: GenericObject = Object.freeze({
  non_consensual_kissing_non_sexual_body_part:
    'Non Consensual Kissing Non Sexual Body Part',
  attempted_non_consensual_sexual_penetration:
    'Attempted Non Consensual Sexual Penetration',
  non_consensual_touching_sexual_body_part:
    'Non Consensual Touching Sexual Body Part',
  non_consensual_kissing_sexual_body_part:
    'Non Consensual Kissing Sexual Body Part',
  non_consensual_sexual_penetration: 'Non Consensual Sexual Penetration',
  physically_injured_requiring_medical_attention_not_involving_non_body_part_physical_object:
    'Physically Injured Requiring Medical Attention - Not Involving Physical (Non-Body Part) Object',
  physically_injured_requiring_medical_attention_involving_non_body_part_physical_object:
    'Physically Injured Requiring Medical Attention - Involving Physical (Non-Body Part) Object',
});

const readableIncidentItemCategory = (category: string) =>
  INCIDENT_ITEM_CATEGORIES[category] || screeningSlugToHumanReadable(category);

const formatIncidentItem = (incidentItem: IncidentItem) =>
  readableIncidentItemCategory(incidentItem.category);

const formatIncidentItems = (incidentItems?: IncidentItem[] | null) =>
  incidentItems?.map(formatIncidentItem).join(', ') || '';

const Clearinghouse: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const { clearinghouse_search: search } = report;

  if (!search) return null;

  const result = getResultOrStatus(search);
  const shouldShowIncidentFields = result === ReportStatuses.CONSIDER;
  return (
    <M.Screening
      id={SCREENING_TITLES.clearinghouse_search.id}
      title='Clearinghouse Search'
      statusType={reportStatusType}
      screening={search}
    >
      {shouldShowIncidentFields && (
        <KeyValueSection
          items={[
            {
              itemKey: 'Reporting Organization',
              itemValue: search.reporting_organization_name,
            },
            {
              itemKey: 'Incident Items',
              itemValue: formatIncidentItems(search?.incident_items),
            },
            {
              itemKey: 'Incident Date',
              itemValue: search.incident_date,
            },
            {
              itemKey: 'Reported At',
              itemValue: dateWithTimezone(search.reported_at || ''),
            },
          ]}
        />
      )}
    </M.Screening>
  );
};

export default Clearinghouse;
