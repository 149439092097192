export type RealtimeCommands = 'subscribe'; // Types of commands that can be send to the Realtime server; currently everything is managed with "subscribe"

// List of the types of resources that the server can manage a Realtime room for
export type RealtimeResource =
  | 'candidate'
  | 'report'
  | 'account'
  | 'invitation';

/**
 * Details to pass for subscribing to a room
 */
export type SubscriptionProps = {
  roomID: string | null;
  roomType: RealtimeResource | null;
};

export type ViewerList = {
  email: string;
  internal?: 1; // Conditional flag used to sometimes differentiate internal/external users. Will only be either undefined or 1.
}[];

export type Update = {
  type: 'update';
  message?: string;
};

/**
 * Data that comes in via Realtime connection
 */
export type RealtimeEventData =
  | {
      cmd: 'roomState'; // Someone joined/left the room
      data: { users: ViewerList };
    }
  | {
      cmd: 'event'; // Something about the page itself got updated
      data: Update;
    };

export type CommandProps = {
  primusConnection: any;
  command: RealtimeCommands;
  roomType?: RealtimeResource;
  roomID?: string;
};

export enum RealtimeStatuses {
  IDLE = 'idle', // Has not been set up yet
  LOADING = 'loading', // In the process of setting up
  READY = 'ready', // Fully set up, but not connected to a room
  ACTIVE = 'active', // Actively sending+receiving messages
  ERROR = 'error', // Something went wrong
  END = 'end', // Entirely terminated
}
