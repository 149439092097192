import React, { useCallback, useState } from 'react';
import { M, Save } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { GenericObject } from 'types';
import { CityProps } from '../types';

const CitySelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mastodon-city-select {
    max-width: 400px;
  }

  .cds--dropdown__wrapper {
    width: calc(100% - 60px);
  }
`;

// Cell for displaying either the geo's current city, or a dropdown to choose a city for it
const CityCell: React.FC<GenericObject> = ({
  cell,
  geoState,
  getRelevantCities,
  saveGeoDetails,
  isPending,
}) => {
  const [city, setCity] = useState<CityProps | null>(null);

  // Update the local state
  const updateCity = useCallback(selectedCity => {
    setCity(selectedCity.selectedItem);
  }, []);

  // Submit the save request to the API
  const saveGeo = useCallback(() => {
    saveGeoDetails(city);
  }, [city, saveGeoDetails]);

  const getCityLabel = useCallback((city: CityProps) => city.name, []);

  const { t } = useTranslation('', {
    keyPrefix: 'screening_settings.geos.table',
  });

  // If the Geo doesn't have a City (but there IS a State), then get a list of cities from that state, and offer a dropdown to pick one
  if (!cell.value && geoState) {
    const relevantCities: CityProps[] = getRelevantCities(geoState);

    return (
      <CitySelectContainer>
        <M.Dropdown
          data-testid='city-select-dropdown'
          id='city-select'
          items={relevantCities}
          itemToString={getCityLabel}
          onChange={updateCity}
          disabled={isPending}
          label={t('add_city_placeholder')}
        />

        <M.Button
          kind='secondary'
          onClick={saveGeo}
          disabled={!city || isPending}
          hasIconOnly
          renderIcon={Save}
          iconDescription={t('save_title')}
          data-testid='save-city-button'
        />
      </CitySelectContainer>
    );
  }

  // Default fallback; either there is a city and we just display it, or we have some reason to just display an empty cell anyway
  return cell.value;
};

export default CityCell;
