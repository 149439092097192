/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Guard from './guard';
import ActionRequired from './action-required';

const ReportTaskContainer = () => {
  return (
    <Guard>
      <ActionRequired />
    </Guard>
  );
};

export default ReportTaskContainer;
