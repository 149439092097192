import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

const StyledContainer = styled(M.Container)`
  font-size: 14px;
  border-radius: 8px;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  align-items: center;
  display: flex;
`;

const StyledIcon = styled(M.Icon)`
  fill: ${colors.uiNavy500};
  margin-right: 0.75rem;
`;

const InformationPanel: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const explanation = t('lite.time_since_offense.information_panel');

  return (
    <StyledContainer data-testid='assess-v2-ui-lite-time-since-offense-information-panel'>
      <StyledIcon icon='InformationFilled' />
      <div>
        <Trans>{explanation}</Trans>
      </div>
    </StyledContainer>
  );
};

export default InformationPanel;
