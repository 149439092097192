import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const MInput = styled(M.Input)`
  min-width: 8rem;
  [placeholder] {
    text-overflow: ellipsis;
  }
`;

export default MInput;
