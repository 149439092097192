import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import React, { useCallback, SyntheticEvent } from 'react';
import styled from 'styled-components';

import { ID } from 'modules/id';
import * as Version from 'modules/assess/models/rulesets/version';

export type Type = React.FC<Props>;

type Props = {
  activeVersionId?: ID;
  version: Version.Type;
  onClick?: (version: Version.Type) => void;
};

const NoLink = styled.span`
  color: ${colors.uiOrange600};
  pointer-events: none;
  text-decoration: underline;
`;

const StyledAnchor = styled.a`
  cursor: pointer;
`;

const StyledIndicator = styled(M.LivenessIndicator)`
  div {
    padding-left: 0.5rem;
  }
`;

export const PromoteLink: Type = ({ activeVersionId, version, onClick }) => {
  const { t } = useTranslation('assess');

  const onPromoteHandler = useCallback(
    (e: SyntheticEvent) => onClick?.(version),
    [onClick, version],
  );

  const promote_text = t('assess:ruleset.versions.link.promote');

  if (!version.id) {
    return <NoLink>{promote_text}</NoLink>;
  }

  if (activeVersionId === version.id) {
    return (
      <StyledIndicator live text={t('assess:ruleset.versions.link.live')} />
    );
  }

  return <StyledAnchor onClick={onPromoteHandler}>{promote_text}</StyledAnchor>;
};

export default PromoteLink;
