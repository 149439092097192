import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericObject } from '@dashboard-experience/utils';
import { PaymentMethod } from 'api/payment';
import {
  GridCol,
  StyledBadgeDiv,
  StyledSubTitle,
  StyledErrorBadgeDiv,
  IconCard,
} from './PaymentStyledComponents';

type Props = {
  cardInfo: PaymentMethod;
};

const CardDetails: React.FC<Props> = ({ cardInfo }) => {
  const { t } = useTranslation();

  const isExpiredPaymentMethod = (): boolean => {
    const currentDate = new Date();
    const expirationDate =
      cardInfo.exp_year !== undefined && cardInfo.exp_month !== undefined
        ? new Date(+cardInfo.exp_year, +cardInfo.exp_month + 1, 0)
        : new Date();
    return currentDate > expirationDate;
  };

  const cardIconClassNames = useCallback((): string => {
    const classes: GenericObject = {
      'icon-card': true,
      'icon-visa': cardInfo.brand === 'Visa',
      'icon-master': cardInfo.brand === 'MasterCard',
      'icon-discover': cardInfo.brand === 'Discover',
      'icon-express': cardInfo.brand === 'American Express',
      'icon-jcb': cardInfo.brand === 'JCB',
      'icon-diners': cardInfo.brand === 'Diners Club',
      'icon-union': cardInfo.brand === 'UnionPay',
    };
    const toBeClasses = Object.keys(classes).map(key =>
      classes[key] === true ? key : '',
    );
    return toBeClasses.join(' ');
  }, [cardInfo.brand]);

  return (
    <>
      <GridCol lg={1}>
        <IconCard className={cardIconClassNames()}>{cardInfo.brand}</IconCard>
      </GridCol>
      <GridCol md={2}>
        <StyledSubTitle data-testid='cc-details'>
          <div className='fz-16'>
            <span data-testid={`cc-brand-${cardInfo.stripe_id}`}>
              {cardInfo.brand}
            </span>
            &nbsp;••••&nbsp;
            <span data-testid='cc-last4'>{cardInfo.last4}</span>
          </div>
          <p className='note-inner'>
            Expiration:&nbsp;
            <span data-testid='cc-exp-month'>{cardInfo.exp_month}</span>/
            <span data-testid='cc-exp-year'>{cardInfo.exp_year}</span>
          </p>
        </StyledSubTitle>
      </GridCol>

      <GridCol>
        {isExpiredPaymentMethod() && (
          <StyledErrorBadgeDiv>
            {t(`payment.badge.expired`)}
          </StyledErrorBadgeDiv>
        )}
        {cardInfo.is_default_payment && (
          <StyledBadgeDiv data-testid='cc-default'>
            {t(`payment.badge.default`)}
          </StyledBadgeDiv>
        )}
      </GridCol>
    </>
  );
};

export default CardDetails;
