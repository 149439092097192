import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoading as Loading } from 'components';
import { Button } from 'modules/assess/ui/page';
import { useDraftUpdate } from 'modules/assess/ui/ruleset/version/api';
import { State, useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions as VersionActions } from 'modules/assess/ui/state/rulesets/versions/instance';
import { Base } from 'modules/assess/v2/models/navigation';
import { useDisableReview } from 'modules/assess/v2/ui/hooks';
import { cleanCurrentUrl } from 'modules/assess/v2/ui/utils';
import { ID } from 'modules/id';

type Props = {
  ruleset?: ID;
  trackNewRulesCallback?: () => void;
  translation?: string;
};

export const ReviewButton: React.FC<Props> = ({
  ruleset,
  trackNewRulesCallback,
  translation = 'common.actions.review',
}) => {
  const { t } = useTranslation('assess:v2');
  const history = useHistory();
  const location = useLocation();
  const state = State.useValue();
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(false);
  const entity = State.useEntity(state);
  const action = useDraftUpdate(ruleset);
  const { disabled, isLoading: isLoadingDisableReview } =
    useDisableReview(ruleset);

  const currentPath = cleanCurrentUrl(location.pathname);
  const path = `${currentPath}/${Base.ReviewAndApply}`;
  const loading = action.result.status === 'loading' || isLoadingDisableReview;

  const onClick = useCallback(() => {
    if (state.dirty) {
      action.call(entity);
    } else {
      setIsSaved(true);
    }
  }, [action, entity, state.dirty]);

  useEffect(() => {
    if (action.result.status === 'success') {
      trackNewRulesCallback?.();
      dispatch(VersionActions.Update.create({ dirty: false }));
      action.result.reset();
      setIsSaved(true);
    }
  }, [action, dispatch, trackNewRulesCallback]);

  useEffect(() => {
    if (isSaved) {
      history.push(path);
    }
  }, [history, isSaved, path]);

  return (
    <>
      <Loading enabled={loading} />
      <Button
        disabled={disabled}
        kind='primary'
        onClick={onClick}
        size='sm'
        trans={t(translation)}
        data-testid='assess-v2-common-actions-review-button'
      />
    </>
  );
};

export default ReviewButton;
