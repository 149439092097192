import React from 'react';
import styled from 'styled-components';
import { M, fonts, colors } from '@dashboard-experience/mastodon';
import { CHECKR_MARKETING_URL } from 'Constants';

const FooterContainer = styled.footer`
  &.footer-new {
    padding-top: 2rem;
    padding-bottom: 0;
    width: auto;

    #mastodon & {
      hr {
        background-color: ${colors.brandSlate2};
        border: none;
        height: 1px;
      }
    }

    .footer-container {
      padding: 2rem 0 0;
    }

    @media (min-width: 768px) {
      padding-top: 4rem;
      padding-bottom: 0;

      .footer-container {
        padding-top: 3rem;
      }
    }
  }

  .footer-container {
    display: block;
    text-align: center;
  }

  .twitter-container {
    align-items: center;
  }

  .footer-item {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mastodon-icon {
    fill: ${colors.uiGrey700};
    vertical-align: text-bottom;
  }

  span,
  a,
  a:hover {
    line-height: 1.42857143;
    color: ${colors.uiGrey700} !important;
    font-family: ${fonts.legacy}, ${fonts.ui};
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  a:focus {
    color: ${colors.uiNavy900} !important;
  }
`;

const HorizontalLine = styled.hr`
  margin-top: 0px;
  margin-bottom: 0px;
`;

type Props = {
  className?: string;
};

const Footer: React.FC<Props> = ({ className }) => {
  return (
    <FooterContainer className={className}>
      <HorizontalLine />
      <span className='footer-container'>
        <span className='footer-item'>
          &copy; Checkr {new Date().getFullYear()}&nbsp;|
        </span>
        <a
          href={`${CHECKR_MARKETING_URL}/careers`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Jobs&nbsp;|
        </a>
        <a
          className='footer-item'
          href={`${CHECKR_MARKETING_URL}/customer-agreement`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Customer Agreement&nbsp;|
        </a>
        <a
          href={`${CHECKR_MARKETING_URL}/privacy-policy`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy&nbsp;|
        </a>
        <span className='footer-item twitter-container'>
          <M.Icon icon='LogoTwitter' />
          <a
            href='https://twitter.com/checkr'
            target='_blank'
            rel='noopener noreferrer'
          >
            Follow&nbsp;@checkr
          </a>
        </span>
      </span>
    </FooterContainer>
  );
};

export default Footer;
