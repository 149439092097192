import React from 'react';
import { CategoryCodeSetVersionFull } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  ccsvDraft?: CategoryCodeSetVersionFull;
};

export const Content: React.FC<Props> = ({ ccsvDraft }) => {
  const { t } = useTranslation();
  const name = ccsvDraft?.category_code_set?.name;
  const edited_by = t('prepositions.by.actor', {
    actor: ccsvDraft?.updated_by,
  });
  const edited_on = t('prepositions.on.date', {
    date: new Date(ccsvDraft?.updated_at || 0).toLocaleString(),
    interpolation: { escapeValue: false },
  });

  return (
    <div>
      <p>
        <Trans
          i18nKey='assess:ruleset.version.delete.main'
          t={t}
          values={{ name }}
        />
      </p>
      <p>
        <Trans
          i18nKey='assess:ruleset.version.delete.last_edit'
          shouldUnescape
          t={t}
          values={{ edited_by, edited_on }}
        />
      </p>
    </div>
  );
};

export default Content;
