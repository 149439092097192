import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ACCOUNT_HIERARCHY_UI_MAX_NODES } from 'Constants';
import { useFetchHierarchyStatus } from 'api/hierarchy/hooks';

type ErrorBannerProps = {
  useAPI: boolean;
};

const ErrorBanner: React.FC<ErrorBannerProps> = ({ useAPI }) => {
  let ingestionInProgress = false;
  let hasErrors = false;

  const { data: hierarchyStatus = {} } = useFetchHierarchyStatus();

  if (hierarchyStatus.hiearchy_pending) {
    ingestionInProgress = true;
  }
  if (hierarchyStatus.latest_ingestion_errors?.length) {
    hasErrors = true;
  }

  const maxNodesMessage = `Your account hierarchy has more than ${ACCOUNT_HIERARCHY_UI_MAX_NODES}
    nodes and cannot be managed from the dashboard.`;

  return (
    <>
      {useAPI && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='alert alert-danger'>
              <M.InlineNotification
                hideCloseButton
                kind='error'
                title='Maximum amount of nodes'
                subtitle={maxNodesMessage}
              />
            </div>
          </div>
        </div>
      )}
      {!useAPI && ingestionInProgress && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='alert alert-warning'>
              <M.InlineNotification
                hideCloseButton
                kind='error'
                title='Error'
                subtitle='Hierarchy ingestion is in progress. The account hierarchy
                    shown below may not reflect your latest changes.'
              />
            </div>
          </div>
        </div>
      )}
      {!useAPI && !ingestionInProgress && hasErrors && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='alert alert-danger'>
              <M.InlineNotification
                hideCloseButton
                kind='error'
                title='Error'
                subtitle='There was an error with your latest hierarchy ingestion.
                    Please try again.'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorBanner;
