import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type ContainerProps = {
  flexDirection?: 'column' | 'row';
  gap?: string;
  fullWidth?: boolean;
};

export const Container = styled.div.attrs({
  className: 'packages-page-container',
})<ContainerProps>`
  padding: 0 64px 0 64px;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  align-items: flex-start;
  gap: ${({ gap }) => gap ?? '24'}px;
  align-self: stretch;

  @media (max-width: 992px) {
    padding: 0 24px 0 24px;
  }

  @media (max-width: 420px) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div.attrs({
  className: 'title-container',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const TitleContainerWithButton = styled(TitleContainer)`
  flex-direction: row;
  justify-content: space-between;
`;

export const PageSubtitleContainer = styled.div.attrs({
  className: 'page-subtitle-container',
})`
  display: flex;
  gap: 8px;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const PageSubtitle = styled.p.attrs({
  className: 'page-subtitle p2',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
    margin-top: 0;
  }
`;

export const PriceDisclaimer = styled.p.attrs({
  className: 'page-subtitle p4',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    margin-bottom: 0;
  }
`;

export const DisclaimerLink = styled(M.Link).attrs(props => ({
  className: 'learn-more',
  target: '_blank',
  href: 'https://checkr.com/pricing/passthrough-fees',
}))`
  font-size: inherit;
  margin-left: 4px;
`;
