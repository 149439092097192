import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useStateWithProp } from 'modules/assess/v2/ui/hooks';
import Input from './Input';
import { MAX, MIN, isInvalid } from './utils';

type Props = {
  error?: boolean;
  disabled?: boolean;
  key?: string;
  initial?: number;
  setEligibleValue: (value: number | undefined) => void;
};

const StyledContainer = styled.div`
  height: 48px !important;
  width: 100%;
`;

const Container: React.FC<Props> = ({
  error,
  disabled,
  key,
  initial,
  setEligibleValue,
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useStateWithProp<number | undefined>(initial);
  const invalidRange = isInvalid(MIN, MAX, value);
  const valid = !invalidRange;

  const handleOnFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const handleValidateValue = useCallback(() => {
    setFocus(false);
    if (valid) {
      setEligibleValue(value);
      return;
    }

    setValue(initial);
  }, [initial, setEligibleValue, setValue, valid, value]);

  return (
    <StyledContainer
      data-testid='assess-v2-ui-common-lookback-rules-severity-input-container'
      key={key}
      onBlur={handleValidateValue}
      onFocus={handleOnFocus}
      tabIndex={0}
    >
      <Input
        disabled={disabled}
        focus={focus}
        onUpdate={handleValidateValue}
        onValidateValue={handleValidateValue}
        setFocus={setFocus}
        setValue={setValue}
        valid={valid}
        value={value}
        error={error}
      />
    </StyledContainer>
  );
};

export default Container;
