import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { Callback } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { useAdverseActions } from 'modules/adjudication/api';
import { useGetReport } from 'api/reports';
import moment from 'moment';
import styled from 'styled-components';

type ModalProps = {
  open: boolean;
  hideModal: Function;
  callback: Callback;
};

const StyledComposedModal = styled(M.ComposedModal)`
  p {
    padding-right: unset;
  }

  .bolded {
    font-weight: bold;
  }

  .text-muted {
    color: ${colors.uiGrey500};
  }
`;

const Modal: React.FC<ModalProps> = ({ open, hideModal, callback }) => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  const { id } = report;

  const action = t(`${namespace}:report.actions.resume.action`);

  const { adverseAction } = useAdverseActions(id);

  const pausedAt = moment(adverseAction?.pause_data?.time).format('MM/DD/YYYY');

  const timeRemaining = adverseAction?.pause_data?.remaining;

  let timeStamp;

  if (timeRemaining) {
    timeStamp = moment(Date.now() + 1000 * timeRemaining)
      .format('MM/DD/YYYY hh:mm a')
      .replace(' ', ' at ');
  }

  return (
    <StyledComposedModal
      onClose={hideModal}
      open={open}
      className='actions-modal'
    >
      <M.ModalHeader closeModal={hideModal}>
        <h2>{action}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p className='bolded'>
          {t(`${namespace}:report.actions.resume.modal.bold`)}
        </p>
        <p className='text-muted'>
          {t(`${namespace}:report.actions.resume.modal.muted-pre`)}
          {pausedAt}. {t(`${namespace}:report.actions.resume.modal.muted-post`)}
        </p>
        <p>{timeStamp}</p>
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{
            name: t(`${namespace}:report.actions.resume.modal.cancel`),
            onClick: hideModal,
          }}
          btnRight={{
            name: action,
            onClick: callback,
          }}
          size='sm'
        />
      </M.ModalFooter>
    </StyledComposedModal>
  );
};

export default Modal;
