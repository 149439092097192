import React, { useCallback, useEffect, useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import { FactRule, getFactByNamespace, Operator } from 'modules/assess';
import { Namespace } from 'modules/assess/api';

import { Field } from 'modules/assess/ui/rules/common/rule';
import { FactRuleOption } from 'modules/assess/ui/rules/common/rule/editor/conditions';
import ValueSelectorLegacy from 'modules/assess/ui/rules/common/rule/editor/conditions/value-selector-legacy';
import Kind from 'modules/assess/models/rules/common/kind';
import { useAssessRuleAPI } from 'modules/assess/ui/rules/common/helpers';
import ItemTypes from 'modules/assess/ui/rules/common/rule/editor/item-types';
import HelpText from 'modules/assess/ui/rules/common/rule/editor/conditions/help-text';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

type Props = {
  namespace: Namespace;
};

const Targets: React.FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('assess');
  const translation: any = t('assess:rules.facts.object_type', {
    returnObjects: true,
  });
  const { conditions, updateConditionByIndex, insertCondition } =
    useConditions();
  const label = `${translation.name} ${translation.verb}`.trim();
  const Fact = getFactByNamespace(namespace);
  const index = conditions.findIndex(c => c.fact === Fact.TYPE);
  const condition = useMemo(() => {
    return conditions[index] || { fact: Fact.TYPE };
  }, [Fact.TYPE, index, conditions]);

  useEffect(() => {
    if (index === -1) {
      insertCondition({ fact: Fact.TYPE, operator: Operator.IN });
    }
  }, [index, conditions, insertCondition, Fact.TYPE]);

  const setValue = useCallback(
    (value: FactRuleOption) =>
      updateConditionByIndex(index, { ...condition, ...value }),
    [condition, index, updateConditionByIndex],
  );

  return (
    <M.GridCol
      lg={6}
      md={3}
      sm={1}
      data-testid='assess-rules-custom-rule-editor-rule-targets'
    >
      <Field label={label} style={{ marginBottom: '0.75em' }} />
      {useAssessRuleAPI() ? (
        <ItemTypes kind={Kind.CUSTOM} index={index} />
      ) : (
        <ValueSelectorLegacy
          condition={condition as Partial<FactRule>}
          kind={Kind.CUSTOM}
          namespace={namespace}
          setValue={setValue}
        />
      )}
      <HelpText
        fact={Fact.TYPE}
        namespace={namespace}
        selectedValue={condition.value as string[]}
      />
    </M.GridCol>
  );
};

export default Targets;
