import { DataTableRow } from 'carbon-components-react';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import React, { ReactElement } from 'react';
import Menu from '../../../instance/menu';
import { Key } from './headers';
import Assigned from './item-assigned-status';
import Draft from './item-draft';
import Name from './item-name';
import Time from './item-time';

export interface Row extends DataTableRow, Record<Key, ReactElement> {}

export const toRow = (item: Entity): Row => {
  return {
    id: item.id || '',
    assigned: <Assigned active_rulesets_count={item?.active_rulesets_count} />,
    draft: <Draft item={item} />,
    menu: <Menu item={item} kind='options' />,
    name: <Name item={item} />,
    time: <Time item={item} />,
  };
};

export const useRows = (data?: Array<Entity>): Array<Row> | undefined => {
  return data?.map(toRow);
};
