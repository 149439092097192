import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { map } from 'lodash/fp';
import styled from 'styled-components';
import {
  EditCohortFormFields,
  NewCohortFormFields,
  PackageSubtype,
  Service,
} from './types';
import { FormInputContainer, Label } from './styles';
import {
  DOT_ALCOHOL_SCREENING,
  DOT_DRUG_SCREENING,
  FORM_FIELD_LABELS,
  NON_DOT_ALCOHOL_SCREENING,
  DRUG_ALCOHOL_SELECTION,
  DOT_DRUG_TEST_UNAVAILABLE,
  DOT_ALCOHOL_TEST_UNAVAILABLE,
  ALCOHOL_TEST_UNAVAILABLE,
  DRUG_TEST_UNAVAILABLE,
} from './Constants';
import { filterAlcoholScreenings, filterDrugScreenings } from './utils';

interface Props {
  screenings: PackageSubtype[];
  values: Partial<NewCohortFormFields | EditCohortFormFields>;
  showError: (field: string) => string | null;
  handleChange: any;
  setErrors: (errors: any) => void;
}

const Title = styled.h6`
  font-size: 14px;
  color: ${colors.uiTextPrimaryLight};
`;

const Description = styled.p`
  font-size: 14px;
  color: ${colors.uiTextSecondaryLight} !important;
  margin-bottom: 18px !important;
  padding-right: 0 !important;
`;

const BulletPoint = styled.li`
  font-size: 14px;
  color: ${colors.uiTextPrimaryLight};
  list-style-type: disc;
  margin-left: 20px;
`;

const Tooltip = styled(M.TooltipDefinition)`
  align-items: center;
  margin-left: 1.1rem;
  text-align: center;
`;

const InformationIcon = styled(M.Icon)`
  fill: ${colors.uiNavy600} !important;
  vertical-align: text-bottom;
`;

const ScreeningOptionsSection: FC<Props> = ({
  screenings = [],
  values,
  showError,
  handleChange,
  setErrors,
}) => {
  const isDOTCompliant = values?.isDOTCompliant === 'true';

  const [drugScreeningsDot, setDrugScreeningsDot] = useState<Service[] | null>(
    null,
  );
  const [drugScreeningsNonDot, setDrugScreeningsNonDot] = useState<
    Service[] | null
  >(null);

  const [alcoholScreeningsDot, setAlcoholScreeningsDot] = useState<Service[]>(
    [],
  );
  const [alcoholScreeningsNonDot, setAlcoholScreeningsNonDot] = useState<
    Service[]
  >([]);

  const [onlyDrugScreenings, setOnlyDrugScreenings] = useState(false);
  const [onlyAlcoholScreenings, setOnlyAlcoholScreenings] = useState(false);

  const displayConfigErrorForAlcohol = useMemo(() => {
    const targetAlcoholArray = isDOTCompliant
      ? alcoholScreeningsDot
      : alcoholScreeningsNonDot;

    if (!targetAlcoholArray || targetAlcoholArray.length === 0) return true;

    if (isDOTCompliant) {
      return !targetAlcoholArray!.some(
        (elem: Service) => elem.name === DOT_ALCOHOL_SCREENING,
      );
    }
    return !targetAlcoholArray.some(
      (elem: Service) => elem.name === NON_DOT_ALCOHOL_SCREENING,
    );
  }, [alcoholScreeningsDot, alcoholScreeningsNonDot, isDOTCompliant]);

  const displayConfigErrorForDrug = useMemo(() => {
    const targetDrugArray = isDOTCompliant
      ? drugScreeningsDot
      : drugScreeningsNonDot;

    if (!targetDrugArray || targetDrugArray.length === 0) return true;

    if (isDOTCompliant) {
      return !targetDrugArray!.some(
        (elem: Service) => elem.name === DOT_DRUG_SCREENING,
      );
    }
    return false;
  }, [drugScreeningsDot, drugScreeningsNonDot, isDOTCompliant]);

  const handleDrugAlcholSelection = useCallback(
    (value: string) => {
      handleChange('drugAlcholSelection')(value);

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        drugScreening: undefined,
        alcoholScreening: undefined,
      }));
    },
    [handleChange, setErrors],
  );

  useEffect(() => {
    const { dotDrug, nonDotDrug } = filterDrugScreenings(screenings);
    setDrugScreeningsDot(dotDrug);
    setDrugScreeningsNonDot(nonDotDrug);

    const { dotAlcohol, nonDotAlcohol } = filterAlcoholScreenings(screenings);
    setAlcoholScreeningsDot(dotAlcohol);
    setAlcoholScreeningsNonDot(nonDotAlcohol);

    if (!displayConfigErrorForDrug) {
      if (values.isDOTCompliant === 'true') {
        handleChange('drugScreening')(DOT_DRUG_SCREENING);
      } else {
        handleChange('drugScreening')(values.drugScreening || '');
      }

      // Prepopulate drug screening if there's only one option
      if (!isDOTCompliant && nonDotDrug.length === 1) {
        handleChange('drugScreening')(nonDotDrug[0].name);
      } else if (isDOTCompliant && dotDrug.length === 1) {
        handleChange('drugScreening')(dotDrug[0].name);
      }
    }

    if (!displayConfigErrorForAlcohol) {
      if (values.isDOTCompliant === 'true') {
        handleChange('alcoholScreening')(DOT_ALCOHOL_SCREENING);
      } else {
        handleChange('alcoholScreening')(NON_DOT_ALCOHOL_SCREENING);
      }
    }

    // Determine if only drug or only alcohol screenings are available
    const onlyDrug =
      (dotDrug.length > 0 || nonDotDrug.length > 0) &&
      dotAlcohol.length === 0 &&
      nonDotAlcohol.length === 0;
    const onlyAlcohol =
      (dotAlcohol.length > 0 || nonDotAlcohol.length > 0) &&
      dotDrug.length === 0 &&
      nonDotDrug.length === 0;

    setOnlyDrugScreenings(onlyDrug);
    setOnlyAlcoholScreenings(onlyAlcohol);

    // Preselect the radio button if only one type of screening is available
    if (onlyDrug) {
      handleDrugAlcholSelection('DRUG_ONLY');
    } else if (onlyAlcohol) {
      handleDrugAlcholSelection('ALCOHOL_ONLY');
    }

    if (values.drugAlcholSelection === 'DRUG_ONLY') {
      handleChange('alcoholScreening')('');
    } else if (values.drugAlcholSelection === 'ALCOHOL_ONLY') {
      handleChange('drugScreening')('');
    }
  }, [
    displayConfigErrorForDrug,
    displayConfigErrorForAlcohol,
    handleChange,
    handleDrugAlcholSelection,
    screenings,
    values.drugAlcholSelection,
    values.drugScreening,
    values.alcoholScreening,
    values.isDOTCompliant,
    isDOTCompliant,
  ]);

  return (
    <FormInputContainer data-testid='screening-options-setion'>
      {isDOTCompliant ? (
        <Description>
          We&apos;ve selected your test types to comply with DOT regulations.
        </Description>
      ) : (
        <>
          <Title>What tests do you need?</Title>
          <Description>
            Tests you select will be permanently linked to this pool, and you
            can&apos;t change them later.
            <br />
            <br />
            If this page doesn&apos;t show the test you need, you can customize
            a package to include it or contact Checkr.
          </Description>
        </>
      )}

      <FormInputContainer>
        <M.RadioButtonGroup
          id='drugAlcholSelection'
          name='drugAlcholSelection'
          onChange={handleDrugAlcholSelection}
          orientation='vertical'
          valueSelected={values.drugAlcholSelection || ''}
        >
          <M.RadioButton
            key='DRUG_ONLY'
            id='DRUG_ONLY'
            labelText={DRUG_ALCOHOL_SELECTION.DRUG_ONLY}
            value='DRUG_ONLY'
            disabled={onlyAlcoholScreenings}
          />
          <M.RadioButton
            key='ALCOHOL_ONLY'
            id='ALCOHOL_ONLY'
            labelText={DRUG_ALCOHOL_SELECTION.ALCOHOL_ONLY}
            value='ALCOHOL_ONLY'
            disabled={onlyDrugScreenings}
            touched={onlyAlcoholScreenings}
          />
          <M.RadioButton
            key='DRUG_ALCOHOL'
            id='DRUG_ALCOHOL'
            labelText={DRUG_ALCOHOL_SELECTION.DRUG_ALCOHOL}
            value='DRUG_ALCOHOL'
            disabled={onlyDrugScreenings || onlyAlcoholScreenings}
          />
        </M.RadioButtonGroup>
      </FormInputContainer>

      {(values.drugAlcholSelection === 'DRUG_ONLY' ||
        values.drugAlcholSelection === 'DRUG_ALCOHOL') && (
        <FormInputContainer>
          <Label>{FORM_FIELD_LABELS.drugScreening}</Label>
          {isDOTCompliant ? (
            <ul>
              <BulletPoint>
                {!displayConfigErrorForDrug
                  ? DOT_DRUG_SCREENING
                  : DOT_DRUG_TEST_UNAVAILABLE}
              </BulletPoint>
            </ul>
          ) : (
            <M.Select
              noLabel
              key={`drugScreening-${values.drugAlcholSelection}`}
              id='drugScreening'
              name='drugScreening'
              placeholder='Select screening'
              invalid={showError('drugScreening')}
              invalidText={showError('drugScreening')}
              onChange={handleChange}
              disabled={isDOTCompliant}
              defaultValue={
                isDOTCompliant && !displayConfigErrorForDrug
                  ? DRUG_TEST_UNAVAILABLE
                  : values.drugScreening
              }
            >
              {!isDOTCompliant && !values.drugScreening && (
                <M.Select.Item value='' text='' />
              )}
              {map(
                ({ name, label }: Service) => (
                  <M.Select.Item
                    key={name}
                    id={name}
                    text={label}
                    value={name}
                  />
                ),
                isDOTCompliant ? drugScreeningsDot : drugScreeningsNonDot,
              )}
            </M.Select>
          )}
        </FormInputContainer>
      )}

      {(values.drugAlcholSelection === 'ALCOHOL_ONLY' ||
        values.drugAlcholSelection === 'DRUG_ALCOHOL') && (
        <FormInputContainer>
          <Label>
            {FORM_FIELD_LABELS.alcoholScreening}
            <Tooltip
              align='top-left'
              highlighted={false}
              definition='There is only one option for alcohol tests.'
            >
              <InformationIcon icon='InformationFilled' />
            </Tooltip>
          </Label>
          <ul>
            <BulletPoint>
              {/* eslint-disable-next-line no-nested-ternary */}
              {isDOTCompliant
                ? !displayConfigErrorForAlcohol
                  ? DOT_ALCOHOL_SCREENING
                  : DOT_ALCOHOL_TEST_UNAVAILABLE
                : !displayConfigErrorForAlcohol
                ? NON_DOT_ALCOHOL_SCREENING
                : ALCOHOL_TEST_UNAVAILABLE}
            </BulletPoint>
          </ul>
        </FormInputContainer>
      )}

      {((displayConfigErrorForDrug &&
        (values.drugAlcholSelection === 'DRUG_ONLY' ||
          values.drugAlcholSelection === 'DRUG_ALCOHOL')) ||
        (displayConfigErrorForAlcohol &&
          (values.drugAlcholSelection === 'ALCOHOL_ONLY' ||
            values.drugAlcholSelection === 'DRUG_ALCOHOL'))) && (
        <M.InlineNotification
          kind='error'
          title='Configuration Issue'
          subtitle='It looks like there is a configuration issue with the drug and/or alcohol screenings associated with random drug testing on your account. Please contact customer support to continue.'
        />
      )}
    </FormInputContainer>
  );
};

export default ScreeningOptionsSection;
