import * as CCS from 'modules/assess/models/mvr/category-code-set';
import { capitalize } from 'lodash';
import React from 'react';
import { useSearch } from 'modules/assess/ui/rulesets/api/hooks';
import Empty from './empty';

export type Props = {
  category: CCS.Entity;
};

export const List: React.FC<Props> = ({ category }) => {
  const query = useSearch({ ccs_id: category.id });
  const activeRulesets = query.data?.data;

  if (!activeRulesets?.length && query.isSuccess) {
    return <Empty categoryName={category.name} />;
  }

  const content = activeRulesets?.map((item, index) => (
    <span key={item.id}>{(index ? ', ' : '') + capitalize(item.name)}</span>
  ));

  return <p>{content}</p>;
};

export default List;
