import React from 'react';
import styled from 'styled-components';
import { Subcategory, Table } from 'modules/assess/v2/ui/common/lookback-rules';
import { STATUTORY_CATEGORY } from 'modules/assess/v2/ui/common/lookback-rules/utils';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container: React.FC = () => {
  const subcategories = STATUTORY_CATEGORY?.children?.map(item => (
    <Subcategory key={item.id} item={item} />
  ));

  return (
    <StyledContainer data-testid='assess-v2-ui-lite-lookback-period-subcategories-container'>
      <Table.Headers />
      {subcategories}
    </StyledContainer>
  );
};

export default Container;
