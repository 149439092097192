import React, { useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import SingleSelect from 'modules/assess/ui/rules/common/rule/editor/single-select';
import { SelectItem } from 'modules/assess/ui/rules/common/rule/editor/types';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import HelpText from 'modules/assess/ui/rules/common/rule/editor/conditions/help-text';
import { useGetAssessRuleFacts } from 'modules/assess/ui/rules/common/rule/editor/conditions/hooks';

const Facts = ({ index }: { index: number }) => {
  const {
    conditions,
    updateConditionByIndex,
    deleteConditionByIndex,
    selectedItemTypes,
  } = useConditions();
  const { t } = useTranslation('');
  const label = t('selects.condition_placeholder');
  const condition = conditions[index];
  const { data, isLoading } = useGetAssessRuleFacts(selectedItemTypes());
  const items = data
    ?.map((fact: string) => ({
      id: fact,
      label: t(`assess:rules.facts.${fact}.name`),
    }))
    .sort((a: SelectItem, b: SelectItem) => a.label.localeCompare(b.label));
  const onChange = useCallback(
    ({ selectedItem: { id } }) => {
      updateConditionByIndex(index, {
        fact: id,
        value: undefined,
        operator: undefined,
      });
    },
    [updateConditionByIndex, index],
  );

  let selectedItem;

  if (data?.includes(condition.fact)) {
    selectedItem = {
      id: condition.fact,
      label: t(`assess:rules.facts.${condition.fact}.name`),
    };
  }

  const usedFacts = conditions.map((c: { fact: any }) => c.fact);
  const filteredItems = items?.filter((i: any) => !usedFacts.includes(i.id));

  useEffect(() => {
    if (!isLoading && condition.fact && !data.includes(condition.fact)) {
      return deleteConditionByIndex(index);
    }
    return () => {};
  }, [isLoading, data, index, condition.fact]);

  return (
    <M.GridCol
      data-testid='assess-rules-common-rule-editor-rule-condition-facts'
      lg={5}
      md={2}
      sm={1}
    >
      <SingleSelect
        items={filteredItems || []}
        label={label}
        selectedItem={selectedItem}
        onChange={onChange}
      />
      <HelpText fact={condition.fact} />
    </M.GridCol>
  );
};

export default Facts;
