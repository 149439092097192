import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from '../../common';
import Sections from '../../sections';
import Example from './Example/Container';
import Subsections from './subsections';

const Examples = styled.div`
  display: flex;
  flex-direction: column;
  > *:nth-child(2) {
    margin-top: 1rem;
  }
`;

const Container: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.how_to_evaluate_background_checks}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { subsections, title } = translations;
  const { attention, process } = subsections;

  return (
    <Section
      divider
      data-testid='fair-chance-sections-how-to-evaluate-background-checks'
    >
      <h3>{title}</h3>
      <Examples>
        <Example content={process} subsection={Subsections.process} />
        <Example content={attention} subsection={Subsections.attention} />
      </Examples>
    </Section>
  );
};

export default Container;
