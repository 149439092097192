import React from 'react';
import Summary from './Summary';
import Info from './Info';
import Accounts from './Accounts';
import Collections from './Collections';
import PublicRecords from './PublicRecords';
import Inquiries from './Inquiries';

type Props = {
  record: { [x: string]: any };
  completedAt: string;
};

const Record: React.FC<Props> = ({ record, completedAt }) => {
  return (
    <div data-testid='credit-report-record' style={{ paddingTop: '.75rem' }}>
      <Info record={record} />
      {record.credit_summary && <Summary summary={record.credit_summary} />}
      {record.trades && <Accounts trades={record.trades} />}
      <Collections collections={record.collections} completedAt={completedAt} />
      <PublicRecords
        publicRecords={record.public_records}
        completedAt={completedAt}
      />
      <Inquiries inquiries={record.inquiries} completedAt={completedAt} />
    </div>
  );
};

export default Record;
