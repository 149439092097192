/* eslint-disable react/jsx-no-bind */
import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React, { useState, useEffect } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Flag } from '@dashboard-experience/react-flagr';
import {
  FLAG_KEY_EDIT_PACKAGE_OUT_OF_COUNTRY_HISTORY,
  COMMERCIAL_MVR_ADD_ON_FLAG_KEY,
} from 'Constants';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import {
  FlexContainerNoBorder,
  ExpandableTitle,
  Title,
  JustifyContentSpaceBetween,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  Container,
  Checkbox,
  CheckboxWrapper,
  StyledLink,
  Toggletip,
  ActionPropsProps,
} from '../../../shared/ListItemElements';
import EmploymentVerificationModal from './EmploymentVerificationModal';

const DropdownContainer = styled.div`
  margin-left: 32px;
  margin-bottom: 24px;
  margin-top: -10px;
`;

const Dropdown = styled(M.Dropdown)`
  width: 375px;
  margin-bottom: 16px;
  @media (max-device-width: 530px) {
    width: 200px !important;
  }
  .cds--list-box__invalid-icon {
    z-index: 1 !important;
  }
  .cds--list-box__label {
    color: ${colors.slate06} !important;
    font-style: normal !important;
  }
`;

export type EmploymentVerifPanel = {
  type: string;
  name: string;
  label: string;
  lookback_years: number;
  international_upgrade: boolean;
  maximum_allowed_employers?: number;
  dot_compliance: boolean;
};

// not including a maximum_allowed_employers value means number is 'unlimited'
export const employerCurrentOrLast10Years: EmploymentVerifPanel = {
  type: 'employment_verification',
  name: 'EMPLOYER_CURRENT_OR_LAST_10_YEARS',
  label: 'Current employer or most recent in last 10 years',
  lookback_years: 10,
  international_upgrade: false,
  maximum_allowed_employers: 1,
  dot_compliance: false,
};
export const employersLast3Years: EmploymentVerifPanel = {
  type: 'employment_verification',
  name: 'EMPLOYERS_LAST_3_YEARS',
  label: 'Employers within last 3 years',
  lookback_years: 3,
  international_upgrade: false,
  dot_compliance: false,
};
export const employersLast5Years: EmploymentVerifPanel = {
  type: 'employment_verification',
  name: 'EMPLOYERS_LAST_5_YEARS',
  label: 'Employers within last 5 years',
  lookback_years: 5,
  international_upgrade: false,
  dot_compliance: false,
};
export const employersLast7Years: EmploymentVerifPanel = {
  type: 'employment_verification',
  name: 'EMPLOYERS_LAST_7_YEARS',
  label: 'Employers within last 7 years',
  lookback_years: 7,
  international_upgrade: false,
  dot_compliance: false,
};
export const employersLast10Years: EmploymentVerifPanel = {
  type: 'employment_verification',
  name: 'EMPLOYERS_LAST_10_YEARS',
  label: 'Employers within last 10 years',
  lookback_years: 10,
  international_upgrade: false,
  dot_compliance: false,
};

export const employmentScreenTypes: EmploymentVerifPanel[] = [
  employerCurrentOrLast10Years,
  employersLast3Years,
  employersLast5Years,
  employersLast7Years,
  employersLast10Years,
];

const getEmploymentScreenLabel = (employmentScreen: EmploymentVerifPanel) => {
  return employmentScreen.label || employersLast7Years.label;
};

type EmploymentVerificationAdditionalProperties = {
  [employment_verification: string]: {
    international_upgrade: boolean;
    dot_compliance: boolean;
  };
};

type EmploymentVerificationProps = {
  shouldOpen: boolean;
  handlePrice: () => void;
  actionProps: ActionPropsProps;
  basePackage: BasePackage;
  additionalProperties: EmploymentVerificationAdditionalProperties;
};

const LESS_THAN_SEVEN_ADVANTAGES = [
  'Reports dates of employment and titles held',
  'Helps to determine if candidate’s work history is accurate',
  'Eliminates burden of calling employers',
];

const SEVEN_YEAR_PLUS_CONSIDERATIONS = [
  'Turnaround may depend on employers',
  'Verifies employment, not identity',
];

const LESS_THAN_SEVEN_CONSIDERATIONS = [
  'Shorter lookback periods may limit results if candidate has a large employment gap or has switched careers',
  ...SEVEN_YEAR_PLUS_CONSIDERATIONS,
];

const BulletPointList = ({
  advantagesOrConsiderations,
}: {
  advantagesOrConsiderations: string[];
}) => (
  <ul>
    {advantagesOrConsiderations.map((item: string) => (
      <React.Fragment key={Math.random()}>
        <BulletPoint text={item} />
      </React.Fragment>
    ))}
  </ul>
);

const EmploymentVerification = ({
  shouldOpen,
  handlePrice,
  actionProps,
  basePackage,
  additionalProperties,
}: EmploymentVerificationProps) => {
  const { onAddClick, disabledScreenings, addedScreenings } = actionProps;

  const [selectedEmploymentScreen, setSelectedEmploymentScreen] =
    useState<EmploymentVerifPanel | null>(null);

  const existingScreening = basePackage?.screenings?.find(
    (screening: any) => screening.type === 'employment_verification',
  );

  const initialSelectedItem = () => {
    if (existingScreening) {
      switch (existingScreening?.lookback_years) {
        case 3: {
          existingScreening.name = 'EMPLOYERS_LAST_3_YEARS';
          return existingScreening;
        }
        case 5: {
          existingScreening.name = 'EMPLOYERS_LAST_5_YEARS';
          return existingScreening;
        }
        case 7: {
          existingScreening.name = 'EMPLOYERS_LAST_7_YEARS';
          return existingScreening;
        }
        case 10: {
          if (existingScreening?.maximum_allowed_employers === 1) {
            existingScreening.name = 'EMPLOYER_CURRENT_OR_LAST_10_YEARS';
            return existingScreening;
          }
          existingScreening.name = 'EMPLOYERS_LAST_10_YEARS';
          return existingScreening;
        }
      }
    }
    if (!existingScreening && additionalProperties?.employment_verification) {
      return additionalProperties?.employment_verification;
    }
    return employersLast7Years;
  };

  useEffect(() => {
    const initialSelItem = initialSelectedItem();
    setSelectedEmploymentScreen(initialSelItem);
  }, [additionalProperties]);

  const advantages =
    selectedEmploymentScreen && selectedEmploymentScreen?.lookback_years >= 7
      ? [
          `A ${selectedEmploymentScreen?.lookback_years}-year lookback period offers a more thorough assessment of longevity, career progression, and relevant skills`,
          ...LESS_THAN_SEVEN_ADVANTAGES,
        ]
      : LESS_THAN_SEVEN_ADVANTAGES;

  let considerations = LESS_THAN_SEVEN_CONSIDERATIONS;

  if (
    selectedEmploymentScreen &&
    selectedEmploymentScreen?.lookback_years >= 7
  ) {
    considerations =
      selectedEmploymentScreen?.lookback_years === 7
        ? SEVEN_YEAR_PLUS_CONSIDERATIONS
        : [
            'Not available in all jurisdictions, review your regional compliance needs',
            ...SEVEN_YEAR_PLUS_CONSIDERATIONS,
          ];
  }

  return (
    <>
      <FlexContainerNoBorder data-testid='employment-verification-search-section'>
        <ExpandableTitle
          initialExpanded={shouldOpen}
          data-testid='expandable-employment-verification-search-section'
          title={
            <Title
              getPriceByType={handlePrice}
              actionProps={actionProps}
              title='Employment Verification'
              priceByType='employment_verification'
            />
          }
        >
          <JustifyContentSpaceBetween>
            <ScreeningInfo>
              Verifies candidate’s employment history for a selected time
              period.{` `}
              <EmploymentVerificationModal />
            </ScreeningInfo>
            {/* <TurnAroundTime /> */}
          </JustifyContentSpaceBetween>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <BulletPointList advantagesOrConsiderations={advantages} />
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <BulletPointList advantagesOrConsiderations={considerations} />
            </Box>
          </Flex>
        </ExpandableTitle>
        <AddButtonContainer>
          <AddScreeningsAction
            screeningType='employment_verification'
            {...actionProps}
            addedScreenings={addedScreenings}
            onAddClick={() =>
              onAddClick('employment_verification', {
                type: selectedEmploymentScreen?.type,
                name: selectedEmploymentScreen?.name,
                label: selectedEmploymentScreen?.label,
                lookback_years: selectedEmploymentScreen?.lookback_years,
                maximum_allowed_employers:
                  selectedEmploymentScreen?.maximum_allowed_employers,
                additionalProperties,
              })
            }
          />
        </AddButtonContainer>
      </FlexContainerNoBorder>
      {!existingScreening && (
        <DropdownContainer>
          <Dropdown
            label='Select employment verification'
            data-testid='employment-verification-dropdown'
            id='employment-verification-dropdown'
            items={employmentScreenTypes}
            itemToString={getEmploymentScreenLabel}
            initialSelectedItem={
              existingScreening?.type === 'employment_verification'
                ? selectedEmploymentScreen
                : initialSelectedItem
            }
            onChange={({ selectedItem }: any) => {
              setSelectedEmploymentScreen(selectedItem);
              onAddClick('employment_verification', {
                ...selectedItem,
                maximum_allowed_employers:
                  selectedItem.maximum_allowed_employers ?? null,
                name: selectedItem.name,
                additionalProperties,
              });
            }}
            invalidText='Employment verification screen required'
            selectedItem={employmentScreenTypes.find((screen: any) => {
              return screen?.name === selectedEmploymentScreen?.name;
            })}
            disabled={
              existingScreening?.type === 'employment_verification' ||
              disabledScreenings?.includes('employment_verification')
            }
          />
        </DropdownContainer>
      )}
      <Container>
        <Flag flagKey={COMMERCIAL_MVR_ADD_ON_FLAG_KEY} variantKey='enabled'>
          {!disabledScreenings.includes('employment_verification') && (
            <CheckboxWrapper>
              <Checkbox
                data-testid='employment-verification-dot-compliance-checkbox'
                id='employment-verification-dot-compliance-checkbox'
                name='employment-verification-dot-compliance-checkbox'
                value='employment-verification-dot-compliance-checkbox'
                labelText='DOT Employment verification'
                disabled={
                  additionalProperties.employment_verification
                    ?.international_upgrade || false
                }
                checked={
                  additionalProperties.employment_verification
                    ?.dot_compliance || false
                }
                onChange={(event: any, { checked }: { checked: boolean }) => {
                  onAddClick('employment_verification', {
                    selectedEmploymentScreen,
                    dot_compliance: checked,
                    additionalProperties,
                  });
                }}
              />
              <div data-testid='help-filled-icon-employment'>
                <Toggletip align='bottom' tabIndex={0}>
                  <M.ToggletipButton>
                    <M.Icon icon='HelpFilled' />
                  </M.ToggletipButton>
                  <M.ToggletipContent>
                    <div>
                      <p>
                        Verifies driver safety performance for DOT-regulated
                        roles. Drug and alcohol history is also collected if the
                        candidate was a non-CDL commercial driver.
                      </p>
                    </div>
                  </M.ToggletipContent>
                </Toggletip>
              </div>
            </CheckboxWrapper>
          )}
        </Flag>
        <Flag
          flagKey={FLAG_KEY_EDIT_PACKAGE_OUT_OF_COUNTRY_HISTORY}
          variantKey='enabled'
        >
          {!disabledScreenings.includes('employment_verification') && (
            <CheckboxWrapper>
              <Checkbox
                data-testid='employment-out-of-country-history-checkbox'
                id='employment-out-of-country-history'
                name='employment-out-of-country-history'
                value='employment-out-of-country-history'
                labelText='Include out-of-country history'
                disabled={
                  additionalProperties.employment_verification
                    ?.dot_compliance || false
                }
                checked={
                  additionalProperties.employment_verification
                    ?.international_upgrade || false
                }
                onChange={(event: any, { checked }: { checked: boolean }) => {
                  onAddClick('employment_verification', {
                    selectedEmploymentScreen,
                    international_upgrade: checked,
                    additionalProperties,
                  });
                }}
              />
              <div data-testid='help-filled-icon-employment'>
                <Toggletip align='bottom' tabIndex={0}>
                  <M.ToggletipButton>
                    <M.Icon icon='HelpFilled' />
                  </M.ToggletipButton>
                  <M.ToggletipContent>
                    <div>
                      <p>
                        This option applies to candidates who will perform their
                        work inside the US, but have employment history outside
                        the country.{' '}
                      </p>
                      <br />
                      <p>
                        Visit{' '}
                        <StyledLink
                          href='https://help.checkr.com/hc/en-us/articles/360056108714-International-Screenings'
                          target='_blank'
                          rel='noreferrer'
                          data-testid='learn-more-link-international-screenings'
                        >
                          International Screenings
                        </StyledLink>{' '}
                        to learn more.
                      </p>
                    </div>
                  </M.ToggletipContent>
                </Toggletip>
              </div>
            </CheckboxWrapper>
          )}
        </Flag>
      </Container>
    </>
  );
};

export default EmploymentVerification;
