import React from 'react';
import {
  AccountSettingsContainer,
  ApiKeysContainer,
  PreferencesContainer,
  WebhookContainer,
} from 'containers';

import { Tab } from './types';
import Users from '../../UsersContainer';
import AtsSettings from '../../../components/AtsIntegrationSettings';
import Integrations from '../../../components/AccountSettings/Integrations';
import SingleSignOn from '../../AccountSingleSignOn/AccountSingleSignOnContainer';
import Brands from '../BrandsContainer';
import PartnerApplicationIndex from '../../../components/Applications/PartnerApplications';

import Grid from '../../../pages/Account/DeveloperSettings/DeveloperSettingsGrid';
import AccountHierarchyContainer from '../../AccountHierarchyContainer';

const Settings = () => <AccountSettingsContainer />;

const DeveloperSettings = () => (
  <Grid data-floating-menu-container>
    <ApiKeysContainer />
    <WebhookContainer />
  </Grid>
);

const AccountHierarchy = () => <AccountHierarchyContainer />;
const Notifications = () => <PreferencesContainer />;

const Components = {
  [Tab.settings]: Settings,
  [Tab.users]: Users,
  [Tab['single-sign-on']]: SingleSignOn,
  [Tab.developer_settings]: DeveloperSettings,
  [Tab.integrations]: Integrations,
  [Tab.applications]: PartnerApplicationIndex,
  [Tab.brands]: Brands,
  [Tab.hierarchy]: AccountHierarchy,
  [Tab.notifications]: Notifications,
  [Tab.ats_settings]: AtsSettings,
};

export default Components;
