import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Box = styled.div`
  > * {
    margin: 1rem 0 !important;
  }
`;

const Heading = styled.h1`
  color: ${colors.uiTextPrimaryLight};
`;

type Props = {
  code?: number | string;
};

export const PageError: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation();

  const error = t('nouns.error');
  const heading = t([
    `pages.error.heading.${code}`,
    'pages.error.heading.default',
  ]);
  const message = t('pages.error.message', { context: code && 'code', code });
  const prefix = code ? `${error} ${code}` : error;

  return (
    <Box data-testid='page-error-view'>
      <Heading>{heading}</Heading>
      <h2>
        {prefix}: {message}.
      </h2>
    </Box>
  );
};

export default PageError;
