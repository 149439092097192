import { Namespace } from 'modules/assess/api';
import { ID } from 'modules/id';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = React.HTMLAttributes<HTMLElement> & {
  tkey: String;
  namespace?: Namespace;
};

type Section = {
  id?: ID;
  header: string;
  paragraphs: Array<string>;
};

export const TextSections: React.FC<Props> = ({
  tkey,
  namespace,
  ...extras
}) => {
  const { t } = useTranslation('assess');
  let translations: Array<Section> | undefined = t(
    [`assess:${tkey}.${namespace}`, `assess:${tkey}`],
    {
      returnObjects: true,
    },
  );

  if (!translations) {
    return null;
  }

  if (!Array.isArray(translations)) {
    translations = [translations];
  }

  const sections = translations?.map(section => (
    <React.Fragment key={section.id || section.header}>
      <h3>{section.header}</h3>
      {section.paragraphs?.map(p => (
        <p key={Math.random()}>{p}</p>
      ))}
    </React.Fragment>
  ));

  return (
    <div data-testid='assess-rules-text-sections' {...extras}>
      {sections}
    </div>
  );
};

export default TextSections;
