import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ItemVisibility as ItemVisibilityEnum } from 'modules/assess';
import * as Entity from 'modules/assess/models/rules/premade';
import styled from 'styled-components';
import { ItemVisibilityDropdown } from '../../common';

type Props = {
  disabled?: boolean;
  rule: Entity.Type;
  onUpdate: (id: string, itemVisibility?: ItemVisibilityEnum) => void;
};

const StyledDiv = styled.div`
  width: 50%;
`;

const ItemVisibility: React.FC<Props> = ({ disabled, rule, onUpdate }) => {
  const { t } = useTranslation('assess');

  const translations: any = t(`assess:rules.standard.item_visibility`, {
    returnObjects: true,
  });

  const description = (
    <Trans t={t}>
      Select whether and how Eligible records will appear in reports, the
      Candidate Portal, the Checkr Dashboard, and through the Checkr APIs.&nbsp;
      <a target='_blank' href='https://docs.checkr.com/' rel='noreferrer'>
        Learn More
      </a>
    </Trans>
  );

  const footer = (
    <Trans t={t}>
      See&nbsp;
      <a rel='noreferrer' target='_blank' href='/assess?tab=settings'>
        Assess Settings
      </a>
    </Trans>
  );

  const handleChange = useCallback(
    e => {
      const option = e.selectedItem.id;
      const itemVisibility =
        option === ItemVisibilityEnum.DEFAULT ? null : option;
      onUpdate(rule.id, itemVisibility);
    },
    [onUpdate, rule.id],
  );

  return (
    <div>
      <p>
        <strong>{translations?.title}</strong>
      </p>
      <p>{description}</p>
      <p>{translations?.select}</p>
      <StyledDiv>
        <ItemVisibilityDropdown
          disabled={disabled}
          onChange={handleChange}
          selectedOption={rule.item_visibility}
        />
      </StyledDiv>
      <i>{footer}</i>
    </div>
  );
};

export default ItemVisibility;
