import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { FactRuleOption } from 'modules/assess/ui/rules/common/rule/editor/conditions';

type Props = {
  options: FactRuleOption[];
  selectedOptions?: FactRuleOption;
  onChange: (value: FactRuleOption) => void;
  getOptionLabel: (option: FactRuleOption) => string;
};

export const ValueSelectorSingle: React.FC<Props> = ({
  options,
  selectedOptions,
  getOptionLabel,
  onChange,
}) => {
  const { t } = useTranslation('');

  const handleChange = useCallback(
    ({ selectedItem: { id } }) => {
      const value = JSON.parse(id) as FactRuleOption;
      onChange(value);
    },
    [onChange],
  );

  const items = options.map((option: FactRuleOption) => ({
    id: JSON.stringify(option),
    label: getOptionLabel(option),
  }));
  const selected = items.find(
    item => item.id === JSON.stringify(selectedOptions),
  );

  return (
    <M.Dropdown
      data-testid='assess-rules-common-value-selector-single'
      id='assess-rules-custom-rule-editor-condition-value-selector'
      label={t('selects.option_placeholder')}
      items={items}
      selectedItem={selected}
      onChange={handleChange}
    />
  );
};

export default ValueSelectorSingle;
