import React from 'react';
import { Prompt as NavigationPrompt, PromptProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = Pick<PromptProps, 'when'> & {
  translation: string;
};

const Prompt: React.FC<Props> = ({ translation, ...props }) => {
  const { t } = useTranslation();
  const message = t(translation);

  return <NavigationPrompt message={message} {...props} />;
};

export default Prompt;
