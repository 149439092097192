import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section, SpecialContainer } from '../common';
import Sections from '../sections';

const Container = styled(SpecialContainer)`
  > *:first-child {
    padding-top: 0.5rem;
  }

  > *:last-child {
    padding-top: 0.5rem;
  }
  > * {
    padding-right: 1rem;
    padding-left: 2rem;
  }
`;

const AdverseActionInformation: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.adverse_action_information}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { body, description, title } = translations;
  const { content, subtitle } = body;
  return (
    <Section data-testid='fair-chance-sections-adverse-action-information'>
      <Container type='special'>
        <h3>{title}</h3>
        <p>{description}</p>
        <h5>{subtitle}</h5>
        <p>
          <Trans>{content}</Trans>
        </p>
      </Container>
    </Section>
  );
};

export default AdverseActionInformation;
