import React from 'react';
import { useHistory } from 'react-router-dom';
import { login } from 'utils';
import { M } from '@dashboard-experience/mastodon';

const LoginPage = () => {
  const history = useHistory();
  login(history.replace);
  return <M.LoadingSpinner />;
};

export default LoginPage;
