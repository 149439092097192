import React from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import Errors from 'modules/adjudication/ui/adverse-action/initiate/flow/modal/errors';

// TODO: type this properly
type Props = {
  updateReassessmentResult: GenericObject;
  uploadAssessmentResult: GenericObject;
};

const ErrorMessages: React.FC<Props> = ({
  updateReassessmentResult,
  uploadAssessmentResult,
}) => {
  return (
    <div>
      {updateReassessmentResult.isError && (
        <>
          <Msg kind='uptadate the assesmsent' />
          <Errors
            title='Error updating reassessment'
            errors={updateReassessmentResult.error}
          />
        </>
      )}
      {uploadAssessmentResult.isError && (
        <>
          <Msg kind='upload the new assessment file' />
          <Errors
            title='Error uploading reassessment file'
            errors={uploadAssessmentResult.error}
          />
        </>
      )}
    </div>
  );
};

const Msg = ({ kind }: { kind: string }) => {
  return (
    <p>
      {`There was an error when trying to ${kind}, pleaste try
    again:`}
    </p>
  );
};

export default ErrorMessages;
