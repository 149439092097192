import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components';

const Header = () => {
  const { t } = useTranslation();
  const title = {
    label: t(`pageHeaders.orderBackgroundCheck.title`),
    // Id to track component in Pendo
    props: { className: 'mastodon-package-order-title' },
  };
  const description = {
    label: t('pageHeaders.orderBackgroundCheck.description'),
  };

  return <PageHeader title={title} description={description} />;
};

export default Header;
