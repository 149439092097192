import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { Item } from 'modules/assess/utilities/hierarchy';
import { Type } from 'modules/assess/v2/ui/common/lookback-rules';
import SeverityInput from '../severity-input';

type Props = {
  checked: boolean;
  felonyEligible?: number;
  includeLessThanMisdemeanor?: boolean;
  misdemeanorEligible?: number;
  pettyOffenseEligible?: number;
  item: Item;
  handleOnClick: () => void;
  setFelonyValue: (value: number | undefined) => void;
  setMisdemeanorValue: (value: number | undefined) => void;
  setPettyOffenseValue: (value: number | undefined) => void;
  type?: Type;
};

const StyledContainer = styled.div`
  justify-content: left;
`;

const SeverityContainer = styled(M.GridRow)`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Text = styled(M.GridCol)`
  color: ${colors.uiTextPrimaryLight};
  font-weight: bold;
  font-size: 14px;
  line-height: 45px;
  padding-left: 0;
`;

const SeverityInputCol = styled(M.GridCol)`
  padding-right: 0;
`;

const SeverityFields: React.FC<Props> = ({
  checked,
  includeLessThanMisdemeanor,
  felonyEligible,
  item,
  handleOnClick,
  misdemeanorEligible,
  pettyOffenseEligible,
  setFelonyValue,
  setMisdemeanorValue,
  setPettyOffenseValue,
  type = Type.Default,
}) => {
  const { t } = useTranslation('assess:v2');
  const severity: any = t('common.lookback_rules.table.headers', {
    returnObjects: true,
  });

  const checkboxText = t(`common.lookback_rules.dialog.checkbox.${type}`, {
    category: item.name,
  });

  const basePath = 'assess-v2-ui-common-lookback-rules-dialog-severity-fields';
  const severityId = `${basePath}-felony`;
  const misdemeanorId = `${basePath}-misdemeanor`;
  const pettyOffenseId = `${basePath}-petty-offense`;
  const clearAllRecordsCheckbox = `${basePath}-clear-all-records-checkbox`;

  return (
    <StyledContainer data-testid={basePath}>
      <M.Checkbox
        id={clearAllRecordsCheckbox}
        data-testid={clearAllRecordsCheckbox}
        checked={checked}
        labelText={<Trans>{checkboxText}</Trans>}
        onChange={handleOnClick}
      />
      <SeverityContainer data-testid={severityId}>
        <Text>{severity.felony}</Text>
        <SeverityInputCol>
          <SeverityInput
            disabled={checked}
            initial={felonyEligible}
            setEligibleValue={setFelonyValue}
          />
        </SeverityInputCol>
      </SeverityContainer>
      <SeverityContainer data-testid={misdemeanorId}>
        <Text>{severity.misdemeanor}</Text>
        <SeverityInputCol>
          <SeverityInput
            disabled={checked}
            initial={misdemeanorEligible}
            setEligibleValue={setMisdemeanorValue}
          />
        </SeverityInputCol>
      </SeverityContainer>
      {includeLessThanMisdemeanor && (
        <SeverityContainer data-testid={pettyOffenseId}>
          <Text>{severity.petty_offense}</Text>
          <SeverityInputCol>
            <SeverityInput
              disabled={checked}
              initial={pettyOffenseEligible}
              setEligibleValue={setPettyOffenseValue}
            />
          </SeverityInputCol>
        </SeverityContainer>
      )}
    </StyledContainer>
  );
};

export default SeverityFields;
