import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { Candidate } from 'components/Signup/types';
import Section from '../styles';
import { ContactInfoList, Subtitle } from './styles';
import ContactInfoItem from './ContactInfoItem';

type Props = {
  candidates: Candidate[];
  setCandidates: (candidates: Candidate[]) => void;
};

const createEmptyCandidate = () => {
  return {
    id: uuidv4(),
    name: undefined,
    email: '',
    phone: undefined,
  };
};

const ContactInfoSection: React.FC<Props> = ({
  candidates = [],
  setCandidates,
}) => {
  const { t } = useTranslation('signup');
  const [showDelete, setShowDelete] = useState(false);

  const handleEmailChange = useCallback(
    (e, candidateId) => {
      const newCandidates = candidates.map(candidate => {
        if (candidate.id === candidateId) {
          return {
            ...candidate,
            email: e.target.value,
          };
        }
        return candidate;
      });
      setCandidates(newCandidates);
    },
    [candidates, setCandidates],
  );

  const handleAddInvite = useCallback(() => {
    const emptyCandidate = createEmptyCandidate();
    const newCandidateList = [...candidates, emptyCandidate];
    setCandidates(newCandidateList);
    if (newCandidateList.length > 1) setShowDelete(true);
  }, [candidates, setCandidates]);

  const handleRemoveCandidate = useCallback(
    (_, candidateId) => {
      const newInvites = candidates.filter(candidate => {
        return candidate.id !== candidateId;
      });
      setCandidates(newInvites);
      if (newInvites.length === 1) setShowDelete(false);
    },
    [candidates, setCandidates],
  );

  useEffect(() => {
    if (!candidates.length) {
      const emptyCandidate: Candidate = createEmptyCandidate();
      setCandidates([emptyCandidate]);
    }
    if (candidates.length > 1) {
      setShowDelete(true);
    }
  }, [candidates.length, setCandidates]);

  return (
    <Section data-testid='contact-information-section'>
      <h4 data-testid='contact-information-title'>
        {t('pages.candidateInfoPage.candidateInfoSection.contactInfo.title')}
      </h4>
      <Subtitle>
        {t('pages.candidateInfoPage.candidateInfoSection.contactInfo.subtitle')}
      </Subtitle>
      <ContactInfoList>
        {candidates.map((candidate, index) => (
          <ContactInfoItem
            key={candidate.id}
            candidate={candidate}
            index={index}
            handleEmailChange={handleEmailChange}
            handleRemoveCandidate={handleRemoveCandidate}
            showDelete={showDelete}
          />
        ))}
      </ContactInfoList>
      {candidates.length < 10 && (
        <M.Button
          kind='tertiary'
          onClick={handleAddInvite}
          data-testid='add-another-candidate-button'
          size='md'
          style={{ position: 'relative', left: 0, bottom: 0, border: 'none' }}
        >
          <M.Icon icon='Add' /> Add another candidate
        </M.Button>
      )}
    </Section>
  );
};

export default ContactInfoSection;
