import {
  AdjudicationEvent,
  ReportActionEvents,
} from 'modules/adjudication/data';
import client from './client';

export const fetchAdjudicationEvents = (
  reportId: string,
): Promise<AdjudicationEvent[]> => {
  return client.get(`/reports/${reportId}/adjudication_events`);
};

export const fetchReportActionEvents = (
  reportId: string,
): Promise<ReportActionEvents> => {
  return client.get(`/reports/${reportId}/action_events`);
};
