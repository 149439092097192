import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import NewNoteEditor from '../NoteEditor';

const AddNote = () => {
  const { t } = useTranslation('case-management');
  const [showTextArea, setShowTextArea] = useState(false);

  const handleShowTextArea = useCallback(() => {
    setShowTextArea(!showTextArea);
  }, [showTextArea]);

  return (
    <div>
      {showTextArea && <NewNoteEditor onClose={handleShowTextArea} />}
      {!showTextArea && (
        <M.Button
          kind='secondary'
          data-testid='case-management-add-note-button'
          onClick={handleShowTextArea}
        >
          <M.Icon icon='Add' size={20} />
          {t('internal-notes.add-note')}
        </M.Button>
      )}
    </div>
  );
};

export default AddNote;
