import React from 'react';
import { SectionContainer, SectionHeader } from '../../shared';
import SyncItem from './SyncItem';
import { Status } from './types';

type ObjectSyncSchema = {
  description: string;
  method: string;
  name: string;
};

type Props = {
  partnerSlug: string;
  objectSyncs: ObjectSyncSchema[];
};

const ObjectSyncSection: React.FC<Props> = ({
  partnerSlug,
  objectSyncs,
}: Props) => {
  const [syncStatus, setSyncStatus] = React.useState(
    {} as Record<string, Status>,
  );

  const syncBulk = React.useCallback(
    (methods: string[]) => {
      const newSyncStatus = { ...syncStatus };
      // TODO: EECO-5121: call the service
      for (const method of methods) {
        const status =
          // eslint-disable-next-line no-nested-ternary
          method === 'package_sync'
            ? 'Syncing'
            : // eslint-disable-next-line no-nested-ternary
            method === 'package_sync1'
            ? 'Success'
            : method === 'package_sync2'
            ? 'Failure'
            : 'Syncing';
        newSyncStatus[method] = status;
      }
      setSyncStatus(newSyncStatus);
    },
    [syncStatus],
  );

  const syncOne = React.useCallback(
    (method: string) => {
      syncBulk([method]);
    },
    [syncBulk],
  );

  const syncAll = React.useCallback(() => {
    syncBulk(objectSyncs.map(({ method }) => method));
  }, [syncBulk, objectSyncs]);

  return (
    <SectionContainer data-testid='ats-sync-section'>
      <SectionHeader
        sectionKey='sync'
        header='Data syncs'
        bulkActionLabel='Sync All'
        onBulkAction={syncAll}
      />
      {objectSyncs.map(({ name, method }) => {
        return (
          <SyncItem
            key={`ats-sync-item-${method}`}
            name={name}
            syncId={method}
            status={syncStatus[method] ?? 'Idle'}
            onSync={syncOne}
          />
        );
      })}
    </SectionContainer>
  );
};

export default ObjectSyncSection;
