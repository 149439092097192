import React, { useCallback, ChangeEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import { Namespace } from 'modules/assess/api';
import * as api from 'modules/assess/api/ruleset-versions';
import { useDraftImportCSV } from 'modules/assess/ui/ruleset/version/api/hooks';
import { ID } from 'modules/id';
import { useTranslation } from 'react-i18next';
import { useNamespace } from '../../router/context';

type Props = {
  disabled?: boolean;
  ruleset?: ID;
  version?: ID;
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .mastodon-button {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mastodon-button:last-child {
    margin-right: 0 !important;
  }
  .mastodon-button:first-child {
    margin-left: 0 !important;
  }
`;

const ImportExport: React.FC<Props> = ({ disabled, ruleset, version }) => {
  const { t } = useTranslation();
  const importer = useDraftImportCSV(ruleset);
  const namespace = useNamespace() || Namespace.criminal;

  const importText = t('verbs.import');

  const handleImport = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!ruleset || !file) {
        return;
      }
      importer.call(file);
    },
    [importer, ruleset],
  );

  const handleExport = useCallback(() => {
    if (!version) {
      return;
    }
    api.lookbackToCSV(namespace, version);
  }, [namespace, version]);

  const handleDownloadTemplate = useCallback(() => {
    window.open(
      'https://us-east-1-checkr-documents.s3.amazonaws.com/assess/lookback_rules_template.csv',
      '_blank',
    );
  }, []);

  return (
    <Container data-testid='assess-rules-lookback-import-export'>
      <PageLoading enabled={importer.result.status === 'loading'} />
      {!disabled && (
        <M.FileInput
          accept='text/csv'
          disableLabelChanges
          icon='Download'
          kind='secondary'
          label={importText}
          onChange={handleImport}
        />
      )}
      <Button
        icon='Download'
        kind='secondary'
        trans='verbs.export'
        onClick={handleExport}
      />
      <Button
        icon='Download'
        kind='secondary'
        trans='assess:rules.lookback.buttons.download_template'
        onClick={handleDownloadTemplate}
      />
    </Container>
  );
};

export default ImportExport;
