import React, { useCallback, useState } from 'react';
import { M, ChevronDown, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import moment from 'moment';
import { timeFromNow } from 'utils/TimeUtils';
import { API_KEY_INTERVALS, API_KEY_TOKENS } from 'Constants';
import { useDeleteApiKey, useUnDeleteApiKey } from 'api/apiKeys';
import { GenericObject } from '@dashboard-experience/utils';
import checkrAnalytics from 'components/analytics';
import { useUser } from 'context/CurrentUser';
import { Type as Account } from 'modules/core-types/account';
import { shouldUseImpersonationData } from 'utils';
import LoadingCell from '../LoadingCell';

const ExpireMenu = styled(M.OverflowMenu)`
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
`;

const ExpireColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .cds--popover {
    display: none;
  }
`;

const ExpiryTime = styled.div`
  color: ${colors.uiOrange600};
  font-size: 12px;
  margin-bottom: 4px;
`;

const Row = styled(M.TableRow)`
  .mastodon-sensitive-input-wrapper {
    background-color: ${colors.uiGrey100};

    .mastodon-sensitive-input {
      border: none !important;
      background-color: ${colors.uiGrey100} !important;
      color: ${colors.uiTextPrimaryLight} !important;
    }

    .mastodon-sensitive-input[type='text'] {
      text-overflow: ellipsis;
    }
  }
`;

const ExpireButton = () => (
  <div className='mastodon-button danger' style={{ display: 'flex' }}>
    Expire <ChevronDown size={16} style={{ marginLeft: '0.5rem' }} />
  </div>
);

type ExpireCellType = {
  expires_at: string;
  id: string;
  deleteApiKey: Function;
  undeleteApiKey: Function;
};

const ExpireCell = ({
  expires_at,
  id,
  deleteApiKey,
  undeleteApiKey,
}: ExpireCellType) => {
  return (
    <ExpireColumn>
      {expires_at ? (
        <>
          <ExpiryTime>Expires in {timeFromNow(expires_at)}</ExpiryTime>
          <M.Button
            kind='secondary'
            size='sm'
            name={id}
            onClick={undeleteApiKey}
          >
            Cancel
          </M.Button>
        </>
      ) : (
        <ExpireMenu flipped renderIcon={ExpireButton}>
          {Object.keys(API_KEY_INTERVALS as GenericObject).map(interval => (
            <M.OverflowMenuItem
              name={id}
              itemText={interval}
              key={`${id}-${interval}`}
              onClick={deleteApiKey}
            />
          ))}
        </ExpireMenu>
      )}
    </ExpireColumn>
  );
};

export type API_KEY_TOKENS_TYPE = {
  token: string;
  publishable_token: string;
};

const ApiKeyEntry = ({
  account,
  row,
  getRowProps,
  getSelectionProps,
}: ApiKeyEntryType) => {
  const type = API_KEY_TOKENS[row.cells[0].value as keyof API_KEY_TOKENS_TYPE];
  const token = row.cells[1].value;
  const created_at = row.cells[2].value;
  const last_used_at = row.cells[3].value;
  const { call: deleteKey, result: deleteResult } = useDeleteApiKey();
  const { call: unDeleteKey, result: unDeleteResult } = useUnDeleteApiKey();

  const { isLoading: isDeleteLoading } = deleteResult;
  const { isLoading: isUnDeleteLoading } = unDeleteResult;

  // This state is just used for the amplitude event since we don't
  // have access to the internal state of PasswordInput (aka whether the
  // value is being displayed or not).
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const user = useUser();
  const analytics = checkrAnalytics as any;

  const expires_at = row.cells[4].value;
  const { id } = row;

  const deleteApiKey = useCallback(
    ({ currentTarget }: any) => {
      const id = currentTarget.name;
      const when = currentTarget.innerText;

      const expires_at = moment(
        moment.now() + API_KEY_INTERVALS[when],
      ).toISOString();
      deleteKey({ id, expires_at });
    },
    [deleteKey],
  );

  const undeleteApiKey = useCallback(
    ({ currentTarget }: any) => {
      const expires_at = null;
      const id = currentTarget.name;

      unDeleteKey({ id, expires_at });
    },
    [unDeleteKey],
  );

  const trackApiKeyInteraction = (eventName: string, isVisible: boolean) => {
    if (analytics) {
      analytics.track(
        user,
        eventName,
        {
          IsVisible: isVisible,
          AccountId: account.id,
          KeyId: id,
        },
        {
          IsInternal: user.is_internal,
          IsImpersonated: shouldUseImpersonationData(),
        },
      );
    }
  };

  const onTogglePasswordVisibilityHandler = (
    _evt: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Have to pass !isApiKeyVisible because the user just toggled it :)
    trackApiKeyInteraction('API Key Toggle Clicked', !isApiKeyVisible);
    setIsApiKeyVisible(!isApiKeyVisible);
  };

  const onCopyClickHandler = () =>
    trackApiKeyInteraction('API Key Copy Clicked', isApiKeyVisible);

  return (
    <Row key={row.id} {...getRowProps({ row })}>
      <M.TableSelectRow {...getSelectionProps({ row })} />
      <M.TableCell>{type}</M.TableCell>
      <M.TableCell>
        <M.SensitiveInput
          defaultValue={token}
          id={`key_${row.id}`}
          // eslint-disable-next-line react/jsx-no-bind
          onTogglePasswordVisibility={onTogglePasswordVisibilityHandler}
          readOnly
          withCopy
          // eslint-disable-next-line react/jsx-no-bind
          onCopyClick={onCopyClickHandler}
        />
      </M.TableCell>
      <M.TableCell>{moment(created_at).format('l LT')}</M.TableCell>
      <M.TableCell>
        {last_used_at ? moment(last_used_at).format('l LT') : 'Never'}
      </M.TableCell>
      <M.TableCell>
        {isDeleteLoading ||
        isUnDeleteLoading ||
        (expires_at && moment(expires_at).isSameOrBefore(moment.now())) ? (
          <LoadingCell />
        ) : (
          <ExpireCell
            expires_at={expires_at}
            id={id}
            deleteApiKey={deleteApiKey}
            undeleteApiKey={undeleteApiKey}
          />
        )}
      </M.TableCell>
    </Row>
  );
};

type ApiKeyEntryType = {
  account: Account;
  row: {
    id: string;
    cells: {
      value: any;
    }[];
  };
  getRowProps: Function;
  getSelectionProps: Function;
};

export default ApiKeyEntry;
