import React from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ALIAS_AS_AN_ADDON } from '../../../../Flags';

const PricingContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: end;
  margin: 1.5rem 0 calc(1.5rem - 5px) 0;
`;

const PricingTypography = styled.p<PricingTypographyProps>`
  color: ${colors.uiTextPrimaryLight};
  font-size: ${props => (props.size === 'lrg' ? '20' : '16')}px !important;
  font-weight: ${props => (props.bold ? '700' : '400')} !important;
  margin-bottom: 5px !important;
`;

const LoaderStyled = styled(M.LoadingSpinner)`
  margin: 0 auto;
  vertical-align: center;
`;

type PricingTypographyProps = {
  size?: string;
  bold?: boolean;
};

type Item = {
  base_price: number;
  description: string;
  max_price: number;
  min_price: number;
  name: string;
  product_type: string;
  type: string;
};

export type AddOnPrice = {
  bundled_price: number;
  fixed_price: number;
  passthrough_fee_range: number[];
  service_fee_range: number[];
  items: Item[];
};

export type SummaryOfPricingProps = {
  isLoading: boolean;
  addOnData: AddOnPrice;
  aliasPrice?: number;
  hasAliasSkus?: boolean;
  saveForLater?: boolean;
  pricesSnapshot?: any;
};

export const getPricesWhenSaveForLater = ({
  addOnData,
  basePackagePrice,
  aliasPrice,
  aliasFlag,
  hasAliasSkus,
}: any) => {
  const basePackageDollar = getDollarAmount(
    hasAliasSkus
      ? (basePackagePrice - aliasPrice)?.toString()
      : basePackagePrice?.toString(),
  );
  const subtotal = aliasFlag
    ? addOnData.fixed_price + aliasPrice
    : addOnData.fixed_price;

  const subtotalPerCandidate = hasAliasSkus
    ? getDollarAmount(basePackagePrice?.toString())
    : getDollarAmount(subtotal?.toString());

  const addOnPrice = `${getDollarAmount(
    (addOnData.fixed_price - basePackagePrice)?.toString(),
  )}`;

  return { basePackageDollar, subtotalPerCandidate, addOnPrice };
};

export const getPrices = ({
  addOnData,
  basePackagePrice,
  aliasPrice,
  aliasFlag,
}: any) => {
  const basePackageDollar = getDollarAmount(basePackagePrice);
  const subtotal = aliasFlag
    ? addOnData.fixed_price + aliasPrice
    : addOnData.fixed_price;

  const subtotalPerCandidate = getDollarAmount(subtotal?.toString());

  const addOnPrice = getDollarAmount(
    (addOnData.fixed_price - basePackagePrice)?.toString(),
  );

  return { basePackageDollar, subtotalPerCandidate, addOnPrice };
};

const SummaryOfPricing: React.FC<SummaryOfPricingProps> = ({
  isLoading,
  addOnData,
  aliasPrice = 0,
  hasAliasSkus = false,
  saveForLater = false,
  pricesSnapshot = {},
}) => {
  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';
  if (isLoading) {
    return <LoaderStyled withOverlay={false} />;
  }

  const {
    basePackagePrice: displayBasePackagePrice,
    addonPrices = [],
    subtotalPerCandidate,
  } = pricesSnapshot;

  const displayableAddonPrice = getDollarAmount(
    addonPrices.reduce(
      (total: any, addon: any) => total + (addon.base_price || 0),
      0,
    ),
  );
  const displayableAliasPrice = getDollarAmount(aliasPrice?.toString() ?? 0);

  const minTotalFees =
    addOnData.service_fee_range[0] + addOnData.passthrough_fee_range[0];
  const maxTotalFees =
    addOnData.service_fee_range[1] + addOnData.passthrough_fee_range[1];

  return (
    <PricingContainer data-testid='summary-of-pricing'>
      <PricingTypography data-testid='summary-base-package-price'>
        Package: {getDollarAmount(displayBasePackagePrice)}
      </PricingTypography>
      <PricingTypography data-testid='summary-addons-price'>
        Add-ons: {displayableAddonPrice}
      </PricingTypography>
      {aliasFlag && hasAliasSkus && (
        <PricingTypography data-testid='summary-aliases-price'>
          Aliases: {displayableAliasPrice}
        </PricingTypography>
      )}
      <PricingTypography size='lrg' bold data-testid='summary-subtotal-price'>
        *Subtotal per candidate: {subtotalPerCandidate}
      </PricingTypography>
      {aliasFlag && maxTotalFees > 0 && (
        <PricingTypography data-testid='summary-third-party-price'>
          Estimated third-party fees per candidate:{' '}
          {`${getDollarAmount(minTotalFees.toString())}-${getDollarAmount(
            maxTotalFees.toString(),
          )}`}
        </PricingTypography>
      )}
    </PricingContainer>
  );
};

export default SummaryOfPricing;
