import React, { useState, useCallback, useEffect } from 'react';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import NameDialog from 'modules/assess/ui/common/name-dialog';
import { Action } from 'modules/assess/ui/common/base-dialog';
import { useUpdate } from '../api';

export type Props = {
  open: boolean;
  onClose: () => void;
  ccs: Entity;
};

export const RenameDialog: React.FC<Props> = ({ open, onClose, ccs }) => {
  const [name, setName] = useState(ccs?.name);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const update = useUpdate(ccs?.id);

  const handleOnClose = useCallback(() => {
    setName(ccs?.name);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    update.call({ name });
  }, [update, name]);

  useEffect(() => {
    setSubmitDisabled(name === '');
  }, [name]);

  useEffect(() => {
    switch (update.result.status) {
      case 'idle':
        return;
      case 'loading':
        setSubmitDisabled(true);
        return;
      case 'error':
        setSubmitDisabled(false);
        return;
    }

    handleOnClose();
  }, [update.result.status]);

  return (
    <NameDialog
      action={Action.update}
      open={open}
      onClose={handleOnClose}
      onChange={setName}
      onSubmit={handleSubmit}
      name={ccs?.name}
      submitDisabled={submitDisabled}
      type='category_set'
    />
  );
};

export default RenameDialog;
