import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { SetupButton } from 'components/AddScreenings/shared/AddScreeningsAction';
import STATUS from '../enums/Status';
import AddonSetupStatusTag from './AddonSetupStatusTag';
import SetupType from '../enums/AddonSetupType';

const Container = styled.div`
  display: flex;
  width: 32.5%;
  min-height: 111px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Title = styled.div`
  color: ${colors.uiTextPrimaryLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
`;

export const HelpIcon = styled(M.Icon)`
  color: ${colors.navy02};
  margin-bottom: -3px;
  margin-left: 2px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
`;

const TextContainer = styled.div`
  color: var(--text-icon-black-secondary, rgba(26, 32, 38, 0.78));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const BoldText = styled.span`
  color: var(--text-icon-black-secondary, rgba(26, 32, 38, 0.78));
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const SetupButtonWrapper = styled(SetupButton)`
  display: flex;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid ${colors.navy04};
  background: ${colors.white};
  box-shadow: 0px 4px 8px 0px rgba(22, 22, 29, 0.1);
`;

export const Tooltip = styled(M.TooltipDefinition)`
  border-bottom: none !important;

  .cds--definition-tooltip {
    padding: 1rem;
    margin-left: 10px !important;
    max-width: 255px !important;
  }
`;

export const getToolTip = (setupType: SetupType) => {
  if (setupType === SetupType.drug) {
    return 'Tests candidates for a broad range of substances to reduce the costs and risks of drug use.';
  }
  if (setupType === SetupType.credit) {
    return 'Identifies public financial information such as tax liens, accounts in collection, and bankruptcies';
  }
  if (setupType === SetupType.occupationalHealth) {
    return 'Evaluates a candidate’s physical and mental capability for performing job-related responsibilities.';
  }

  return '';
};

export const getSetupButtonTestId = (setupType: SetupType) => {
  if (setupType === SetupType.drug) {
    return 'drug-screening-setup-button';
  }
  if (setupType === SetupType.credit) {
    return 'credit-screening-setup-button';
  }
  if (setupType === SetupType.occupationalHealth) {
    return 'occ-health-screening-setup-button';
  }
  return '';
};

export const getIconTestId = (setupType: SetupType) => {
  if (setupType === SetupType.drug) {
    return 'drug-screening-icon';
  }
  if (setupType === SetupType.credit) {
    return 'credit-screening-icon';
  }
  if (setupType === SetupType.occupationalHealth) {
    return 'occ-health-screening-icon';
  }
  return '';
};

export const getStatusTagTestId = (setupType: SetupType) => {
  if (setupType === SetupType.drug) {
    return 'drug-status-tag';
  }
  if (setupType === SetupType.credit) {
    return 'credit-status-tag';
  }
  if (setupType === SetupType.occupationalHealth) {
    return 'occ-health-status-tag';
  }
  return '';
};

export const getTitleTestId = (setupType: SetupType) => {
  if (setupType === SetupType.drug) {
    return 'drug-title';
  }
  if (setupType === SetupType.credit) {
    return 'credit-title';
  }
  if (setupType === SetupType.occupationalHealth) {
    return 'occ-health-title';
  }
  return '';
};

type AddonSetupProps = {
  setupType: SetupType;
  status: STATUS;
  handleSetupClick: Function;
  initiationDate: string;
};

const AddonSetup: React.FC<AddonSetupProps> = ({
  setupType,
  status,
  handleSetupClick,
  initiationDate,
}) => {
  return (
    <Container>
      <Flex>
        <Title data-testid={getTitleTestId(setupType)}>
          {setupType}
          <Tooltip
            openOnHover
            align='bottom'
            definition={getToolTip(setupType)}
          >
            <HelpIcon
              data-testid={getIconTestId(setupType)}
              icon='HelpFilled'
              size='16'
              style={{
                fill: colors.checkrBlue,
              }}
            />
          </Tooltip>
        </Title>
        <AddonSetupStatusTag
          testId={getStatusTagTestId(setupType)}
          status={status}
        />
      </Flex>
      {status === STATUS.disabled && (
        <ButtonContainer>
          <SetupButtonWrapper
            data-testid={getSetupButtonTestId(setupType)}
            onClick={handleSetupClick}
          >
            Set up
          </SetupButtonWrapper>
        </ButtonContainer>
      )}
      {status === STATUS.pending && (
        <TextContainer>
          Setup initiated on <BoldText>{initiationDate}</BoldText>. Setup
          typically takes{' '}
          <BoldText>
            {setupType === SetupType.credit ? '14-21' : '7-10'}
          </BoldText>{' '}
          business days.
        </TextContainer>
      )}
      {status === STATUS.enabled && (
        <TextContainer>Add to new or existing packages.</TextContainer>
      )}
    </Container>
  );
};

export default AddonSetup;
