import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { UserType } from 'modules/case-management/types';
import UnassignedAvatar from 'modules/case-management/ui/shared/UnassignedAvatar';
import StyledUser from './StyledUser';

const StyledSpacer = styled.span`
  margin: 0 3px;
`;

type PropTypes = {
  reassignTask: (user: UserType | null) => void;
};

const UnassignedUser = ({ reassignTask }: PropTypes) => {
  const { t } = useTranslation();
  const currentUser = useUser();

  const onSelect = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
      reassignTask(currentUser);
    },
    [currentUser, reassignTask],
  );

  const tKey = 'case-management:tasks.user';

  return (
    <StyledUser data-testid='case-management-tasks-page-unassigned-user'>
      <UnassignedAvatar />
      <span className='name'>{t(`${tKey}.unassigned`)}</span>
      <StyledSpacer className='spacer unassign'>
        <M.Icon icon='DotMark' size='8px' />
      </StyledSpacer>
      <M.Button
        data-testid='case-management-tasks-page-assign-to-self-btn'
        onClick={onSelect}
        kind='tertiary'
        style={{ padding: 0 }}
      >
        {t(`${tKey}.assign_to_you`)}
      </M.Button>
    </StyledUser>
  );
};

export default UnassignedUser;
