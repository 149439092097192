import styled from 'styled-components';
import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { BasePackage } from '../../types';
import { getPackageColor } from '../PackageCard/styles';

interface IconCheckmarkProps {
  iconType?: BasePackage | undefined;
}

const IconCircle = styled.span<IconCheckmarkProps>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: ${props => (props.iconType ? '2.5rem' : '1rem')};
  height: ${props => (props.iconType ? '2.5rem' : '1rem')};
  border-radius: 50%;
  background: ${props => (props.iconType ? colors.uiGrey0 : colors.uiGrey200)};
  border: ${props => (props.iconType ? `1px solid ${colors.uiGrey300}` : '')};
  margin: ${props => (props.iconType ? '.5rem auto 0' : '')};
`;

const CheckmarkSVG = styled.svg<IconCheckmarkProps>`
  color: ${props =>
    props.iconType
      ? getPackageColor(props.iconType).color
      : colors.brandSlate6};
  stroke: ${props =>
    props.iconType
      ? getPackageColor(props.iconType).color
      : colors.brandSlate6};
  stroke-width: ${props => (props.iconType ? '1' : '0.5')};
  width: ${props => (props.iconType ? '1.125rem' : '0.644rem')};
`;

const IconCheckmark: React.FC<IconCheckmarkProps> = ({ iconType }) => {
  return (
    <IconCircle iconType={iconType}>
      <CheckmarkSVG
        className='check-icon'
        fill='none'
        viewBox='0 0 16 17'
        xmlns='http://www.w3.org/2000/svg'
        iconType={iconType}
      >
        <path
          d='m5.8533 13.22c-0.19932-0.0249-0.38346-0.1193-0.52-0.2666l-3.2533-3.4267c-0.17607-0.32834-0.10216-0.73477 0.17823-0.98011 0.2804-0.24535 0.69303-0.26465 0.99511-0.04655l2.6 2.8 6.9067-7.2666c0.1478-0.14983 0.3495-0.23418 0.56-0.23418s0.4122 0.08435 0.56 0.23418c0.1506 0.15011 0.2353 0.35401 0.2353 0.56667 0 0.21265-0.0847 0.41656-0.2353 0.56666l-7.4533 7.8c-0.14776 0.1602-0.35538 0.252-0.57333 0.2533z'
          clipRule='evenodd'
          fill='currentColor'
          fillRule='evenodd'
        />
      </CheckmarkSVG>
    </IconCircle>
  );
};

export default IconCheckmark;
