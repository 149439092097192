import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { cloneDeep } from 'lodash';
import { InnerGroupType } from 'modules/assess/ui/rules/common/rule/editor/types';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import Field from 'modules/assess/ui/rules/common/rule/field';
import ItemTypes from 'modules/assess/ui/rules/common/rule/editor/item-types';
import Kind from 'modules/assess/models/rules/common/kind';
import NewConditions from 'modules/assess/ui/rules/common/rule/editor/new-conditions';
import RemoveButton from 'modules/assess/ui/rules/common/rule/editor/remove-button';

export type Props = {
  index: number;
  topGroupIndex: number;
  setDraftRule: any;
  innerGroup: InnerGroupType;
  showRemove: boolean;
};

const InnerGroupContainer = styled(M.Container)`
  background-color: #b4c5df !important;
  margin: 1.5rem 1rem !important;
`;

const ItemTypeContainer = styled.div`
  margin: 0 1em;
`;

const NewConditionContainer = styled.div`
  margin-left: 0;
`;

const InnerGroup = ({
  index,
  topGroupIndex,
  setDraftRule,
  innerGroup,
  showRemove,
}: Props) => {
  const { setConditions, conditions } = useConditions();
  const deleteInnerGroup = useCallback(() => {
    setDraftRule((oldDraftRule: any) => {
      const newDraftRule = cloneDeep(oldDraftRule);
      newDraftRule.conditions[topGroupIndex].conditions.splice(index, 1);
      return newDraftRule;
    });
  }, []);

  useEffect(() => {
    setConditions(cloneDeep(innerGroup));
  }, []);

  useEffect(() => {
    setDraftRule((oldDraftRule: any) => {
      // EADP-2525: by intention to avoid re-render loop
      oldDraftRule.conditions[topGroupIndex].conditions[index] = [
        ...conditions,
      ];
      return oldDraftRule;
    });
  }, [conditions]);

  return (
    <InnerGroupContainer data-testid='assess-complex-editor-inner-group'>
      <ItemTypeContainer>
        <Field label='Applies to' style={{ marginBottom: '0.75em' }} />
        <ItemTypes index={0} kind={Kind.COUNT} />
      </ItemTypeContainer>
      <NewConditionContainer>
        <NewConditions>
          {showRemove && (
            <RemoveButton
              style={{ marginTop: '1em' }}
              onClick={deleteInnerGroup}
              labelTranKey='assess:rules.custom.rule.buttons.remove_inner_group'
            />
          )}
        </NewConditions>
      </NewConditionContainer>
    </InnerGroupContainer>
  );
};

export default InnerGroup;
