import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import Dialog from 'modules/assess/ui/ruleset/delete';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useReadOnly } from 'modules/assess/ui/hooks';

type Type = React.ForwardRefRenderFunction<any, Props>;

type Props = {
  draft: boolean;
  ruleset?: Ruleset.Type;
  divider?: boolean;
  closeMenu?: () => void;
  onClick: () => void;
};

export { Dialog };

const MenuItemRef: Type = (
  { draft, ruleset, divider, closeMenu, onClick },
  ref,
) => {
  const { t } = useTranslation('assess');
  const resource = draft ? 'ruleset.version' : 'ruleset';
  const text = t(`assess:${resource}.actions.delete`);

  if (useReadOnly() || (ruleset && !ruleset.draft)) {
    return null;
  }

  return (
    <M.Menu.MenuItem
      data-testid='assess-ruleset-menu-delete-item'
      hasDivider={divider}
      isDelete
      itemText={text}
      closeMenu={closeMenu}
      onClick={onClick}
      ref={ref}
    />
  );
};

export const MenuItem = React.forwardRef(MenuItemRef);
