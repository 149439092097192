import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { SegmentsTableRow } from 'components/Segments';
import { Segment, ParentContext } from '../../api/segments';

type Props = {
  onSelect: Function;
  selectedSegments: string[];
  items: Segment[];
  parentContext: ParentContext;
};

const SegmentsTableBody: React.FC<Props> = ({
  onSelect,
  selectedSegments,
  items,
  parentContext,
}) => {
  return (
    <M.TableBody data-testid='segments-table-body-container'>
      {items.map(segment => (
        <SegmentsTableRow
          key={`segment_${segment.custom_id}`}
          segment={segment}
          onSelect={onSelect}
          selected={selectedSegments.indexOf(segment.custom_id) > -1}
          parentContext={parentContext}
        />
      ))}
    </M.TableBody>
  );
};

export default SegmentsTableBody;
