import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  underscoreToHuman,
  formatDateIfDate,
  GenericObject,
  CurrentUser as User,
  Report,
} from '@dashboard-experience/utils';
import { isAdditionalInfo } from './Helpers';
import AdditionalInfoPanel from './AdditionalInfoPanel';
import { MvrItem } from './MultiMotorVehicleReportComponents';

type ViolationsProps = {
  licenseReportOrCharge: GenericObject;
  assessEnabled: boolean;
  currentUser: User;
  report: Report;
};

const Violations: React.FC<ViolationsProps> = ({
  licenseReportOrCharge,
  assessEnabled,
  currentUser,
  report,
}) => {
  return (
    <M.SubScreening title='Violations' data-testid='mvr-violations'>
      {licenseReportOrCharge.violations.map(
        (violation: GenericObject, idx: number) => {
          const sourceValue = () => {
            return violation.state
              ? `${violation.state} DMV`
              : `${licenseReportOrCharge.state} DMV`;
          };

          const violationsItems = Object.keys(violation)
            .filter(parameter => {
              return violation[parameter] && !isAdditionalInfo(parameter);
            })
            .map(parameter => {
              let itemKeyValue = null;
              let itemValue = null;
              if (parameter === 'conviction_date')
                itemKeyValue = 'Disposition Date';
              if (parameter !== 'conviction_date')
                itemKeyValue = underscoreToHuman(parameter);
              if (violation[parameter] && !isAdditionalInfo(parameter))
                itemValue = formatDateIfDate(violation[parameter]);
              return { itemKey: itemKeyValue, itemValue };
            });
          const jointViolationItems = [
            { itemKey: 'Source', itemValue: sourceValue() },
            ...violationsItems,
          ];

          return (
            <M.Expandable
              data-testid={`${violation.description}-${idx + 1}`}
              role='violationsItem'
              key={`${violation.description}-${idx + 1}`}
              title={
                <>
                  <MvrItem item={violation} />
                  <span style={{ float: 'right' }}>
                    {formatDateIfDate(violation.issued_date)}
                  </span>
                </>
              }
            >
              <M.KeyValueList items={jointViolationItems} />
              <AdditionalInfoPanel
                licenseReportOrCharge={violation}
                report={report}
                assessEnabled={assessEnabled}
                currentUser={currentUser}
                panelType='violations'
              />
            </M.Expandable>
          );
        },
      )}{' '}
    </M.SubScreening>
  );
};

export default Violations;
