import React, { useReducer, useEffect, ReactNode } from 'react';
import { useContextSelector, createContext } from 'use-context-selector';
import { StatusTypes } from '@dashboard-experience/utils';
import CustomProvider from 'state/provider';
import { context as ReportContext } from 'containers/Report';
import { GenericObject } from 'types';
import { useCandidateDocuments } from '../../Document/hooks';
import { populateRows } from './helpers';

export type Action =
  | { type: 'post document success'; payload: { [x: string]: any } }
  | { type: 'set documents'; payload: { [x: string]: any } };
export type Dispatch = (action: Action) => void;
export type State = {
  [key: string]: any;
  documents: any[];
  rows: Array<{ [x: string]: any }>;
  screening: { [x: string]: any };
  reportStatusType: string;
};
export type HealthProviderProps = {
  children: ReactNode;
  reportStatusType: string;
};

const initialState = {
  documents: [],
  rows: [],
  screening: {},
  reportStatusType: StatusTypes.Legacy,
};

const HealthContext = createContext<{ state: State; dispatch: Dispatch }>({
  state: initialState,
  dispatch: () => {},
});

const healthReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'post document success': {
      const documents = [...state.documents, action.payload];
      return {
        ...state,
        documents,
        rows: populateRows(state.screening, documents),
      };
    }
    case 'set documents': {
      const {
        payload: { documents },
      } = action;
      return {
        ...state,
        documents,
        rows: populateRows(state.screening, documents),
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

const HealthProvider = ({
  children,
  reportStatusType,
}: HealthProviderProps) => {
  const report = useContextSelector(ReportContext, ({ report }) => report);
  const [state, dispatch] = useReducer(healthReducer, {
    ...initialState,
    reportStatusType,
    screening: report?.occupational_health_screening as GenericObject,
  });

  // get candidate documents
  const { documents, isLoading } = useCandidateDocuments(
    report?.candidate?.id as string,
  );

  useEffect(() => {
    if (documents && !isLoading)
      dispatch({ type: 'set documents', payload: { documents } });
  }, [documents, isLoading]);

  return (
    <CustomProvider context={HealthContext} props={{ state, dispatch }}>
      {children}
    </CustomProvider>
  );
};

const useHealthScreening = (selector: string) => {
  const context = useContextSelector(HealthContext, ({ state }) => {
    return state[selector];
  });

  if (context === undefined) {
    throw new Error('useHealthScreening must be used within a HealthProvider');
  }

  return context;
};

const useDispatch = () =>
  useContextSelector(HealthContext, ({ dispatch }) => dispatch);

export { HealthProvider, useHealthScreening, useDispatch };
