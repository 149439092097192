import { useCallback, useMemo } from 'react';
import checkrAnalytics from 'components/analytics';
import { CurrentUser, GenericObject } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { shouldUseImpersonationData } from 'utils/ImpersonationUtils';
import { CANDIDATE_REDESIGN } from 'Flags';
import { BetaStateValues } from 'providers/Beta/types';
import { useGetBetaState } from 'providers/Beta/hooks';
import { AnalyticsEvent, CANDIDATE_SEARCH_EVENT_NAMES } from './events';

export const analytics = checkrAnalytics as any;

export type TUseTrackEvent = {
  event: AnalyticsEvent;
  eventProperties?: GenericObject;
};

const FILTERED_EVENTS: AnalyticsEvent[] = [
  CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
];

const BETA_KEYS: string[] = [
  // Adds the state of these beta features to user context
  CANDIDATE_REDESIGN,
];

type BetaStateContext = {
  [key: string]: BetaStateValues;
};

export const setUserProperties = (currentUser: CurrentUser) => {
  return {
    user_id: currentUser?.id,
    account_resource_id: currentUser?.account?.id,
    account_name: currentUser?.account?.name,
    account_uri_name: currentUser?.account?.uri_name,
    'User Roles': currentUser?.roles ? currentUser.roles?.join(', ') : '',
    'Is Impersonated': shouldUseImpersonationData(),
    'Is Internal': currentUser?.is_internal,
    'Market Segment': currentUser?.account?.market_segment,
  };
};

export const useTrackEvent = () => {
  const user = useUser();
  const getBetaState = useGetBetaState();
  const betaStates = useMemo(
    () =>
      BETA_KEYS.reduce((states, key) => {
        states[key] = getBetaState(key);
        return states;
      }, {} as BetaStateContext),
    [getBetaState],
  );

  const userProperties: UserProperties = useMemo(
    () => ({
      user_id: user?.id,
      account_resource_id: user?.account?.id,
      account_name: user?.account?.name,
      account_uri_name: user?.account?.uri_name,
      'User Roles': user?.roles ? user.roles?.join(', ') : '',
      'Is Impersonated': shouldUseImpersonationData(),
      'Is Internal': user?.is_internal,
      'Market Segment': user?.account?.market_segment,
      beta: betaStates,
    }),
    [user, betaStates],
  );

  return useCallback(
    (event: AnalyticsEvent, eventProperties: GenericObject = {}) =>
      !FILTERED_EVENTS.includes(event)
        ? analytics.track(user, event, eventProperties, userProperties)
        : null,
    [user, userProperties],
  );
};

export type UserProperties = {
  user_id: string;
  account_resource_id: string;
  account_name: string;
  account_uri_name: string;
  'User Roles': string;
  'Is Impersonated': boolean;
  'Is Internal': boolean | undefined;
  'Market Segment': string | undefined;
  beta?: BetaStateContext;
};
