import React, { useCallback } from 'react';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions, Entity } from 'modules/assess/ui/state/rules/count';
import Header from 'modules/assess/ui/rules/common/header';

type Props = {
  rule: Entity.Type;
};

const ActionMenuButtons: React.FC<Props> = ({ rule }) => {
  const dispatch = useDispatch();

  const duplicateName = (name: string): string => {
    const randomNumber = Math.floor(Math.random() * 1000);
    return `${name} (duplicate #${randomNumber})`;
  };

  const copy = useCallback(
    () =>
      dispatch(
        Actions.Create.create({
          ...rule,
          name: duplicateName(rule.name),
        }),
      ),
    [dispatch, rule],
  );

  const edit = useCallback(
    () =>
      dispatch(
        Actions.Update.create({
          ...rule,
          editing: true,
        }),
      ),
    [dispatch, rule],
  );

  const remove = useCallback(
    () => dispatch(Actions.Remove.create(rule)),
    [dispatch, rule],
  );

  return <Header editable rule={rule} actions={{ copy, edit, remove }} />;
};

export default ActionMenuButtons;
