export type City = {
  name: string;
  state: string;
};
export const MAJOR_CITIES: City[] = [
  { name: 'Akron', state: 'OH' },
  { name: 'Albany', state: 'NY' },
  { name: 'Albuquerque', state: 'NM' },
  { name: 'Allentown', state: 'PA' },
  { name: 'Anchorage', state: 'AK' },
  { name: 'Ann Arbor', state: 'MI' },
  { name: 'Annapolis', state: 'MD' },
  { name: 'Atlanta', state: 'GA' },
  { name: 'Auburn', state: 'ME' },
  { name: 'Augusta', state: 'GA' },
  { name: 'Augusta', state: 'ME' },
  { name: 'Aurora', state: 'CO' },
  { name: 'Aurora', state: 'IL' },
  { name: 'Austin', state: 'TX' },
  { name: 'Baltimore', state: 'MD' },
  { name: 'Bangor', state: 'ME' },
  { name: 'Baton Rouge', state: 'LA' },
  { name: 'Bellevue', state: 'NE' },
  { name: 'Bellevue', state: 'WA' },
  { name: 'Billings', state: 'MT' },
  { name: 'Biloxi', state: 'MS' },
  { name: 'Birmingham', state: 'AL' },
  { name: 'Bismarck', state: 'ND' },
  { name: 'Bloomington', state: 'MN' },
  { name: 'Boise', state: 'ID' },
  { name: 'Boston', state: 'MA' },
  { name: 'Bowling Green', state: 'KY' },
  { name: 'Bozeman', state: 'MT' },
  { name: 'Bridgeport', state: 'CT' },
  { name: 'Buffalo', state: 'NY' },
  { name: 'Burlington', state: 'VT' },
  { name: 'Butte', state: 'MT' },
  { name: 'Cambridge', state: 'MA' },
  { name: 'Carmel', state: 'IN' },
  { name: 'Carson City', state: 'NV' },
  { name: 'Casper', state: 'WY' },
  { name: 'Cedar Rapids', state: 'IA' },
  { name: 'Chandler', state: 'AZ' },
  { name: 'Charleston', state: 'SC' },
  { name: 'Charleston', state: 'WV' },
  { name: 'Charlotte', state: 'NC' },
  { name: 'Chattanooga', state: 'TN' },
  { name: 'Chesapeake', state: 'VA' },
  { name: 'Cheyenne', state: 'WY' },
  { name: 'Chicago', state: 'IL' },
  { name: 'Cincinnati', state: 'OH' },
  { name: 'Cleveland', state: 'OH' },
  { name: 'Colorado Springs', state: 'CO' },
  { name: 'Columbia', state: 'MO' },
  { name: 'Columbia', state: 'SC' },
  { name: 'Columbus', state: 'GA' },
  { name: 'Columbus', state: 'OH' },
  { name: 'Concord', state: 'NH' },
  { name: 'Covington', state: 'KY' },
  { name: 'Cranston', state: 'RI' },
  { name: 'Dallas', state: 'TX' },
  { name: 'Davenport', state: 'IA' },
  { name: 'Denver', state: 'CO' },
  { name: 'Derry', state: 'NH' },
  { name: 'Des Moines', state: 'IA' },
  { name: 'Detroit', state: 'MI' },
  { name: 'Dover', state: 'DE' },
  { name: 'Duluth', state: 'MN' },
  { name: 'Durham', state: 'NC' },
  { name: 'Edison', state: 'NJ' },
  { name: 'Elizabeth', state: 'NJ' },
  { name: 'Erie', state: 'PA' },
  { name: 'Eugene', state: 'OR' },
  { name: 'Evansville', state: 'IN' },
  { name: 'Fairbanks', state: 'AK' },
  { name: 'Fargo', state: 'ND' },
  { name: 'Fayetteville', state: 'AR' },
  { name: 'Fort Collins', state: 'CO' },
  { name: 'Fort Smith', state: 'AR' },
  { name: 'Fort Wayne', state: 'IN' },
  { name: 'Fort Worth', state: 'TX' },
  { name: 'Frederick', state: 'MD' },
  { name: 'Fresno', state: 'CA' },
  { name: 'Gaithersburg', state: 'MD' },
  { name: 'Grand Forks', state: 'ND' },
  { name: 'Grand Island', state: 'NE' },
  { name: 'Grand Rapids', state: 'MI' },
  { name: 'Great Falls', state: 'MT' },
  { name: 'Green Bay', state: 'WI' },
  { name: 'Greensboro', state: 'NC' },
  { name: 'Gresham', state: 'OR' },
  { name: 'Gulfport', state: 'MS' },
  { name: 'Harrisburg', state: 'PA' },
  { name: 'Hartford', state: 'CT' },
  { name: 'Hattiesburg', state: 'MS' },
  { name: 'Helena', state: 'MT' },
  { name: 'Henderson', state: 'NV' },
  { name: 'Hillsboro', state: 'OR' },
  { name: 'Hilo', state: 'HI' },
  { name: 'Honolulu', state: 'HI' },
  { name: 'Houston', state: 'TX' },
  { name: 'Huntington', state: 'WV' },
  { name: 'Huntsville', state: 'AL' },
  { name: 'Independence', state: 'MO' },
  { name: 'Indianapolis', state: 'IN' },
  { name: 'Iowa City', state: 'IA' },
  { name: 'Jackson', state: 'MS' },
  { name: 'Jacksonville', state: 'FL' },
  { name: 'Jefferson City', state: 'MO' },
  { name: 'Jersey City', state: 'NJ' },
  { name: 'Joliet', state: 'IL' },
  { name: 'Jonesboro', state: 'AR' },
  { name: 'Juneau', state: 'AK' },
  { name: 'Kailua', state: 'HI' },
  { name: 'Kaneohe', state: 'HI' },
  { name: 'Kansas City', state: 'KS' },
  { name: 'Kansas City', state: 'MO' },
  { name: 'Kapolei', state: 'HI' },
  { name: 'Kearney', state: 'NE' },
  { name: 'Knoxville', state: 'TN' },
  { name: 'Lafayette', state: 'LA' },
  { name: 'Lake Charles', state: 'LA' },
  { name: 'Lakewood', state: 'CO' },
  { name: 'Lansing', state: 'MI' },
  { name: 'Las Cruces', state: 'NM' },
  { name: 'Las Vegas', state: 'NV' },
  { name: 'Lewiston', state: 'ME' },
  { name: 'Lexington', state: 'KY' },
  { name: 'Lincoln', state: 'NE' },
  { name: 'Little Rock', state: 'AR' },
  { name: 'Los Angeles', state: 'CA' },
  { name: 'Louisville', state: 'KY' },
  { name: 'Lowell', state: 'MA' },
  { name: 'Macon', state: 'GA' },
  { name: 'Madison', state: 'WI' },
  { name: 'Manchester', state: 'NH' },
  { name: 'Memphis', state: 'TN' },
  { name: 'Meridian', state: 'ID' },
  { name: 'Mesa', state: 'AZ' },
  { name: 'Miami', state: 'FL' },
  { name: 'Milwaukee', state: 'WI' },
  { name: 'Minneapolis', state: 'MN' },
  { name: 'Minot', state: 'ND' },
  { name: 'Missoula', state: 'MT' },
  { name: 'Mobile', state: 'AL' },
  { name: 'Montgomery', state: 'AL' },
  { name: 'Montpelier', state: 'VT' },
  { name: 'Naperville', state: 'IL' },
  { name: 'Nashua', state: 'NH' },
  { name: 'Nashville', state: 'TN' },
  { name: 'New Haven', state: 'CT' },
  { name: 'New Orleans', state: 'LA' },
  { name: 'New York', state: 'NY' },
  { name: 'Newark', state: 'DE' },
  { name: 'Newark', state: 'NJ' },
  { name: 'Newport News', state: 'VA' },
  { name: 'Norfolk', state: 'VA' },
  { name: 'Norman', state: 'OK' },
  { name: 'Oakland', state: 'CA' },
  { name: 'Oklahoma City', state: 'OK' },
  { name: 'Olympia', state: 'WA' },
  { name: 'Omaha', state: 'NE' },
  { name: 'Orem', state: 'UT' },
  { name: 'Orlando', state: 'FL' },
  { name: 'Overland Park', state: 'KS' },
  { name: 'Owensboro', state: 'KY' },
  { name: 'Paterson', state: 'NJ' },
  { name: 'Philadelphia', state: 'PA' },
  { name: 'Phoenix', state: 'AZ' },
  { name: 'Pittsburgh', state: 'PA' },
  { name: 'Portland', state: 'ME' },
  { name: 'Portland', state: 'OR' },
  { name: 'Providence', state: 'RI' },
  { name: 'Provo', state: 'UT' },
  { name: 'Raleigh', state: 'NC' },
  { name: 'Rapid City', state: 'SD' },
  { name: 'Reading', state: 'PA' },
  { name: 'Reno', state: 'NV' },
  { name: 'Richmond', state: 'VA' },
  { name: 'Rio Rancho', state: 'NM' },
  { name: 'Rochester', state: 'MN' },
  { name: 'Rochester', state: 'NH' },
  { name: 'Rochester', state: 'NY' },
  { name: 'Rockford', state: 'IL' },
  { name: 'Rockville', state: 'MD' },
  { name: 'Roswell', state: 'NM' },
  { name: 'Rutland', state: 'VT' },
  { name: 'Sacramento', state: 'CA' },
  { name: 'Saint Louis', state: 'MO' },
  { name: 'Saint Paul', state: 'MN' },
  { name: 'Saint Petersburg', state: 'FL' },
  { name: 'Salem', state: 'OR' },
  { name: 'Salt Lake City', state: 'UT' },
  { name: 'San Antonio', state: 'TX' },
  { name: 'San Diego', state: 'CA' },
  { name: 'San Francisco', state: 'CA' },
  { name: 'San Jose', state: 'CA' },
  { name: 'Santa Fe', state: 'NM' },
  { name: 'Savannah', state: 'GA' },
  { name: 'Scottsdale', state: 'AZ' },
  { name: 'Seattle', state: 'WA' },
  { name: 'Shreveport', state: 'LA' },
  { name: 'Sioux City', state: 'IA' },
  { name: 'Sioux Falls', state: 'SD' },
  { name: 'South Bend', state: 'IN' },
  { name: 'South Portland', state: 'ME' },
  { name: 'Southaven', state: 'MS' },
  { name: 'Sparks', state: 'NV' },
  { name: 'Spokane', state: 'WA' },
  { name: 'Springdale', state: 'AR' },
  { name: 'Springfield', state: 'IL' },
  { name: 'Springfield', state: 'MA' },
  { name: 'Springfield', state: 'MO' },
  { name: 'Stamford', state: 'CT' },
  { name: 'Sterling Heights', state: 'MI' },
  { name: 'Syracuse', state: 'NY' },
  { name: 'Tacoma', state: 'WA' },
  { name: 'Tallahassee', state: 'FL' },
  { name: 'Tampa', state: 'FL' },
  { name: 'Toledo', state: 'OH' },
  { name: 'Topeka', state: 'KS' },
  { name: 'Trenton', state: 'NJ' },
  { name: 'Tucson', state: 'AZ' },
  { name: 'Tulsa', state: 'OK' },
  { name: 'Tuscaloosa', state: 'AL' },
  { name: 'Vancouver', state: 'WA' },
  { name: 'Virginia Beach', state: 'VA' },
  { name: 'Warren', state: 'MI' },
  { name: 'Warwick', state: 'RI' },
  { name: 'Waterbury', state: 'CT' },
  { name: 'Wheeling', state: 'WV' },
  { name: 'Wichita', state: 'KS' },
  { name: 'Wilmington', state: 'DE' },
  { name: 'Winston Salem', state: 'NC' },
  { name: 'Worcester', state: 'MA' },
];
