import React from 'react';
import { ReportProps } from 'types';
import { isV2DrugScreening } from './utils';
import V1DrugScreening from './v1DrugScreening';
import V2DrugScreening from './v2DrugScreening';

const DrugScreening: React.FC<ReportProps> = ({ report, ...props }) => {
  const { drug_screening } = report;
  if (!drug_screening) return null;
  const Screening = isV2DrugScreening(report)
    ? V2DrugScreening
    : V1DrugScreening;
  return <Screening report={report} {...props} />;
};

export default DrugScreening;
