import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import isEmpty from 'lodash/isEmpty';
import { SectionProps } from './types';

const withReportContentContainer = <P extends SectionProps>(
  Component: React.FC<P>,
) => {
  const WrappedComponent: React.FC<P> = props => {
    const { report, isLoading } = props;

    if (isLoading)
      return <M.LoadingBlock data-testid='overview-report-loading' />;

    if (isEmpty(report) && !isLoading) return null;

    return <Component {...props} />;
  };
  WrappedComponent.displayName = `withReportContentContainer(${Component.displayName}`;
  return WrappedComponent;
};

export default withReportContentContainer;
