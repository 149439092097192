import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  FlexContainer,
  FlexRow,
  FlexRowAlignRight,
  InformationIcon,
} from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

type SelectWorkflowProps = {
  onSelectWorkflowChanged: (newSelection: string) => void;
  handleNextStep: () => void;
};

const SelectWorkflow: React.FC<SelectWorkflowProps> = ({
  onSelectWorkflowChanged,
  handleNextStep,
}) => {
  const RADIO_GROUP = [
    {
      id: 1,
      description:
        'Your employee will complete Section 1 on their own. Then you complete Section 2 either in person or virtually, if E-Verify is activated.',
      label: 'Employer',
      value: 'remote_section_1_only',
    },
    {
      id: 2,
      description:
        'Your employee will complete Section 1 on their own. Then you authorize a representative to help them complete Section 2.',
      label: 'Employer-appointed representative',
      value: 'employer_appointment',
    },
    {
      id: 3,
      description:
        'Your employee will complete Section 1 on their own. Then you authorize an employee-designated representative to help them complete Section 2.',
      label: 'Employee-appointed representative',
      value: 'employee_appointment',
    },
  ];

  const [workflowSelection, setWorkflowSelection] = useState('');

  const onSelectWorkflowChange = useCallback(
    (newSelection: any) => {
      setWorkflowSelection(newSelection);
      onSelectWorkflowChanged(newSelection);
    },
    [onSelectWorkflowChanged],
  );

  const trackEvent = useTrackEvent();

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ORDER_SELECT_WORKFLOW_STARTED, {
      'Page Type': 'Order',
      'I9 Page Type': 'I9 Select Workflow Page',
    });
  }, [trackEvent]);

  const handleSelectWorkflowCompleted = useCallback(() => {
    // Track event on component mount

    trackEvent(I9_EVENT_NAMES.I9_ORDER_SELECT_WORKFLOW_COMPLETED, {
      'Page Type': 'Dashboard',
      'I9 Page Type': 'I9 Select Workflow Page',
    });
    handleNextStep();
  }, [handleNextStep, trackEvent]);

  return (
    <>
      <FlexContainer gap='1rem' padding='0'>
        <FlexRow gap='0.25rem' alignItems='center'>
          <h3>Workflow options</h3>
          <M.Tooltip
            label='Your preferred method for completing and verifying Section 2 after a candidate completes Section 1.'
            align='right-top'
          >
            <InformationIcon icon='InformationFilled' size='1.5rem' />
          </M.Tooltip>
        </FlexRow>
        <M.ChoiceCardGroup
          type='radio'
          name='select-workflow-group'
          onChange={onSelectWorkflowChange}
          grid={false}
        >
          {RADIO_GROUP.map(item => (
            <M.ChoiceCard
              key={item.id}
              id={item.id}
              description={item.description}
              title={item.label}
              value={item.value}
              data-testid={item.value}
            />
          ))}
        </M.ChoiceCardGroup>
      </FlexContainer>
      <FlexRowAlignRight gap='0' alignItems='center'>
        <M.Button
          kind='primary'
          onClick={handleSelectWorkflowCompleted}
          disabled={workflowSelection === ''}
          data-id='order-i9-select-workflow-next-step'
          data-testid='order-i9-select-workflow-next-step'
        >
          Continue
        </M.Button>
      </FlexRowAlignRight>
    </>
  );
};

export default SelectWorkflow;
