import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { ID } from 'modules/id';
import client from './client';
import { Namespace } from './constants';
import { fromAPI } from './ruleset/utilities/rulesets';

const BASE_PATH = 'rulesets';
const SEARCH_DEFAULTS = { page: 1, per_page: 100 };

export type SearchParams = {
  name?: string;
  page?: number;
  per_page?: number;
  ccs_id?: ID;
};

export type SearchResults = {
  count: number;
  data: Array<Entity.Type>;
};

export const search = (
  namespace: Namespace,
  params: SearchParams = SEARCH_DEFAULTS,
): Promise<SearchResults> => {
  const path = `${namespace}/${BASE_PATH}`;
  return client
    .get(path, { params: { ...SEARCH_DEFAULTS, ...params } })
    .then(response => {
      const data = response.data.map(fromAPI);
      return {
        ...response,
        data,
      };
    });
};

export const uri = (namespace: Namespace): string => {
  const path = `${namespace}/${BASE_PATH}`;
  return client.uri({ url: path });
};
