import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

const Button = styled.button`
  background: none;
  border: none;
  color: ${colors.uiGrey500};
  cursor: pointer;
  height: 100%;
  text-align: left;
  width: 100%;
`;

type Props = {
  onClick: () => void;
};

const AddButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('assess');

  return (
    <Button
      type='button'
      onClick={onClick}
      data-testid='assess-mvr-category-set-version-new-category-btn'
    >
      {t('mvr.category-sets.instance.versions.instance.common.categories.add')}
    </Button>
  );
};

export default AddButton;
