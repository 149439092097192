/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const SlimBold = styled.span`
  font-weight: 600;
`;

const DeleteConfirmationModal: React.FC<any> = ({
  showDeleteModal,
  setShowDeleteModal,
  packageName,
  accountId,
  packageId,
  setCurrentPackage,
  deletePackage,
}) => {
  return (
    <M.ComposedModal
      open={showDeleteModal}
      onClose={() => {
        setShowDeleteModal(false);
      }}
    >
      <M.ModalHeader
        closeModal={() => {
          setShowDeleteModal(false);
        }}
      >
        <h2>Delete package?</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <div>
          {'If you delete this package, '}
          <SlimBold>{packageName}</SlimBold>
          {", it can't be restored. "}
        </div>
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={{
            name: 'Cancel',
            onClick: () => {
              setShowDeleteModal(false);
            },
          }}
          btnRight={{
            name: 'Delete',
            kind: 'danger',
            className: 'danger',
            onClick: () => {
              setShowDeleteModal(false);
              setCurrentPackage({});
              deletePackage({ accountId, packageId });
            },
          }}
          size='sm'
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default DeleteConfirmationModal;
