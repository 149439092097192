import React from 'react';
import { DEGREES } from 'Constants';
import { School } from 'types';
import { KeyValueSection } from '../Sections';

type Props = {
  school: School;
};

const getDegree = (degree: string | null) => {
  const degreeMap: any = DEGREES; // typescript why :(
  return (degree && degreeMap[degree]) || degree || '';
};

const CandidateInput: React.FC<Props> = ({ school }) => (
  <KeyValueSection
    title='Candidate Input'
    items={[
      { itemKey: 'Degree', itemValue: getDegree(school.degree) },
      { itemKey: 'Major', itemValue: school.major || '-' },
    ]}
  />
);

export default CandidateInput;
