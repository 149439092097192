import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const StyledContainer = styled(M.Container)`
  padding-bottom: 2rem;
  padding-right: 1.5rem;
  padding-top: 1em;
`;

const StyledBody = styled.p`
  color: ${colors.uiGrey700};
  margin-top: 1.5rem;
  text-align: center;
`;

const StyledHeader = styled.h1`
  font-size: 48px !important;
  font-weight: bold;
  text-align: center;
`;

type Props = {
  content: any;
  totalCandidates?: number;
};

const Container: React.FC<Props> = ({ content, totalCandidates }) => {
  const { description } = content;
  return (
    <StyledContainer
      type='shadow'
      data-testid='fair-chance-sections-fair-chance-opportunity-total-candidates'
    >
      <StyledHeader>{totalCandidates}</StyledHeader>
      <Trans>
        <StyledBody>{description}</StyledBody>
      </Trans>
    </StyledContainer>
  );
};

export default Container;
