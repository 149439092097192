import { GenericObject } from '@dashboard-experience/utils';
import { Client, AmsClient } from 'api';
import {
  FetchParentObjectResponse,
  SegmentAssignedNodes,
  SegmentSearchHierarchy,
} from './types';

export const patchAMSSegments = (path: string, payload: GenericObject) => {
  return AmsClient.patch(`${path}`, payload);
};

export const postSegments = (path: string, payload: GenericObject) => {
  return Client.post(`${path}`, payload);
};

export const fetchParentObject = (
  path: string,
): Promise<FetchParentObjectResponse> => {
  return Client.get(`${path}`);
};

export const getAssignedNodes = (
  path: string,
): Promise<SegmentAssignedNodes> => {
  return Client.get(`${path}`);
};

export const searchHierarchy = (
  path: string,
): Promise<SegmentSearchHierarchy> => {
  return Client.get(`${path}`);
};
