import { ID } from 'modules/id';
import React, { useEffect } from 'react';
import { useCreate } from 'modules/assess/ui/mvr/category-sets/instance/version/api';
import { DEFAULT_INSTANCE } from 'modules/assess/models/mvr/category-code-set';
import * as Page from 'modules/assess/ui/page';
import { useFetch } from '../api';
import Actions from './actions';
import Assignment from '../../assignments';
import Description from '../description';
import Mappings from '../mappings';
import Status from '../status';
import { Provider } from '../context';
import ActiveVersionBanner from '../ActiveVersionBanner';

type Props = {
  instance: ID;
  version: ID;
};

const Container: React.FC<Props> = ({ instance, version }) => {
  const query = useFetch(instance, version);
  const create = useCreate(instance);
  const ccsv = query.data;

  useEffect(() => {
    if (version === 'draft' && !ccsv?.id && !query.isLoading) {
      create.call();
    }
  }, [ccsv?.id, query.isLoading]);

  return (
    <Provider value={ccsv}>
      <Page.Container
        data-testid='assess-mvr-category-set-version-page'
        loading={query.isLoading}
      >
        {instance !== DEFAULT_INSTANCE && <Actions instance={instance} />}
        <Status instance={instance} version={version} />
        <Description instance={instance} version={version} />
        <Assignment item={ccsv?.category_code_set} />
        {ccsv?.active && <ActiveVersionBanner />}
        <Mappings instance={instance} version={version} />
      </Page.Container>
    </Provider>
  );
};

export default Container;
