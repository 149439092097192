import React from 'react';
import { Link } from 'react-router-dom';
import * as Entity from 'modules/assess/models/rulesets/ruleset';
import styled from 'styled-components';
import { usePath } from './api';
import RulesetLabel from './RulesetLabel';

type Props = {
  ruleset: Entity.Type;
  isDefault?: boolean;
};

const Spacer = styled.span`
  margin: 0 0.5em;
`;

export const RulesetName: React.FC<Props> = ({ ruleset, isDefault }) => {
  const path = usePath(ruleset.id);

  return (
    <>
      <Link to={path}>
        <strong>{ruleset.name}</strong>
      </Link>
      <Spacer />
      <RulesetLabel isDefault={isDefault} />
    </>
  );
};

export default RulesetName;
