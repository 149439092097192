import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Row from 'modules/assess/ui/rules/common/rule/row';
import CreateButton from 'modules/assess/ui/rules/common/rule/editor/create-button';

const ButtonContainer = styled.div`
  margin: 0 !important;
  padding-bottom: 0.5em;
`;

type Props = {
  onClick: () => void;
};

const AddGroupButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Row data-testid='assess-rules-rule-editor-rule-add-condition'>
      <M.GridCol>
        <ButtonContainer>
          <CreateButton
            dataTestid='assess-complex-editor-add-group-button'
            iconKey='GroupObjectsNew'
            onClick={onClick}
            labelTranKey='assess:rules.custom.rule.buttons.add_top_group'
          />
        </ButtonContainer>
      </M.GridCol>
    </Row>
  );
};

export default AddGroupButton;
