import React, { useCallback } from 'react';

type Props = {
  content: string;
  [key: string]: any;
};

const Iframe: React.FC<Props> = ({ content, ...props }) => {
  const writeHTML = useCallback((frame: any) => {
    if (!frame) {
      return;
    }
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
    frame.style.width = '100%';
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  }, []);

  return <iframe src='about:blank' ref={writeHTML} {...props} />;
};

export default Iframe;
