import React from 'react';
import {
  useAdjudicationChangeAllowed,
  usePostAdverseActionAllowed,
} from 'modules/adjudication/utilities';
import { M } from '@dashboard-experience/mastodon';
import EngageButton from '../../engage/button';
import PostAdverseAction from '../../adverse-action/post-adverse-action';
import PreAAButton from '../../adverse-action/initiate';
import Cancel from '../../adverse-action/cancel';
import CompleteButton from '../../adverse-action/complete-single';
import Resume from '../../adverse-action/resume';
import Pause from '../../adverse-action/pause';
import Defer from '../../defer/button';
import RequestCandidateStory from '../../adverse-action/candidate-story';
import ReportTaskContainer from '../../report-tasks';

const Actions = () => {
  const adjudicationChangeButtonsEnabled = useAdjudicationChangeAllowed();
  const postAdverseActionAllowed = usePostAdverseActionAllowed();

  if (!adjudicationChangeButtonsEnabled) return null;
  return (
    <div aria-label='Report Actions'>
      <CompleteButton />
      <Cancel />
      <M.Container.Row style={{ padding: '1rem 0' }}>
        <EngageButton />
        {postAdverseActionAllowed ? <PostAdverseAction /> : <PreAAButton />}
      </M.Container.Row>
      <hr style={{ margin: '0' }} />
      <Resume />
      <Pause />
      <Defer />
      <RequestCandidateStory />
      <ReportTaskContainer />
    </div>
  );
};

export default Actions;
