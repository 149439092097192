import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useReport } from 'containers/Report';
import Notes from './Notes';
import AssignedTo from './AssignedTo';
import { useNotes } from './Notes/hooks';

const InternalNotes = () => {
  const { id } = useReport();
  const notes = useNotes({ report_id: id });
  if (!notes.isSuccess) return null;

  return (
    <M.Container data-testid='case-management-internal-notes'>
      <M.Expandable title={<strong>Internal Notes</strong>}>
        <AssignedTo />
        <Notes />
      </M.Expandable>
    </M.Container>
  );
};

export default InternalNotes;
