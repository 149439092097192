import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import assessCandidates from 'assets/assess-candidates.png';
import StepTitle from './StepTitle';

const StyledUnorderedList = styled.ul`
  margin-left: 1em;
  li:before {
    content: '-';
    margin-right: 0.3em;
  }
`;

const StyledImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  width: 75%;
`;

type Props = {
  stepIndex: number;
  totalSteps: number;
};

export const StepInform: React.FC<Props> = ({ stepIndex, totalSteps }) => {
  const { t } = useTranslation('assess');

  return (
    <div>
      <StepTitle
        stepIndex={stepIndex}
        totalSteps={totalSteps}
        title={t('activate.steps.inform.title')}
      />
      <Trans t={t} i18nKey='assess:activate.steps.inform.body'>
        <p>
          After activating Assess there will be important changes to several key
          dashboard pages:
        </p>
        <StyledUnorderedList>
          <li>
            Only <b>NEW</b> reports will use updated Assess labels of Eligible,
            Review, and Escalated.
          </li>
          <li>
            Your Candidates page will include a new column called Assessment.
          </li>
          <li>Old reports will remain the same.</li>
        </StyledUnorderedList>
        <p>
          Be sure to inform your adjudicators and/or recruiters about the
          changes before you activate! You can review some of the key
          differences in this user guide:
          <a href={t('rulesets.welcome.link')}>Assess User Guide</a>
        </p>
      </Trans>
      <StyledImage src={assessCandidates} />
    </div>
  );
};

export default StepInform;
