/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { toast } from 'actions';
import * as Notifications from 'state/notifications/entity';
import { useUniqueToast } from 'containers/Notifications/hooks';
import { buildTitle, buildMessage, codeIsIgnored } from 'utils';

export const useNotifier = () => {
  const dispatch = useDispatch();

  return useCallback(
    (params?: Notifications.Params) => {
      if (params) {
        dispatch(toast(params));
      }
    },
    [dispatch],
  );
};

/**
 * This hook is used for interpreting incoming errors and building appropriate Toasts for them.
 * @returns The callback function to use for creating a toast
 */
export const useErrorNotifier = () => {
  const notifier = useNotifier();
  const unauthorizedToast = useUniqueToast('expired session');
  const { t } = useTranslation();

  return useCallback(
    /**
     * @description Callback function for interpreting an error and building the appropriate toast
     * @param error - The actual Error object
     * @param options - Optional, more values for some customization.
     * @param options.title - Optional, a custom title to be displayed for the toast
     * @param {number | array} options.ignoreCodes - Optional, specific network codes to NOT generate toasts for.
     */
    (error: Error, options?: any) => {
      // Exit early if there's nothing contained within the error object - this happens if there's a Promise.reject() with no params
      if (!error || Object.keys(error).length === 0) return;

      const {
        response: { status: code, statusText, data } = {},
        name: errName,
        message: fallbackErrorMessage,
      } = error as AxiosError;

      // Exit early if the incoming error code matches one that the caller has explicitly said to ignore
      if (codeIsIgnored(code, options?.ignoreCodes)) {
        return;
      }

      // 401 errors get special handling because they are a bit of a special case.
      // They often happen in bunches, and they aren't really an error that needs correcting. So limit how many get shown, and treat them just as warnings.
      if (code === 401) {
        unauthorizedToast({
          kind: Notifications.Kind.warning,
          title: 'Expired session',
          message: `Attempting to re-authorize now`,
        });
      }
      // For all other kinds of errors, need to carefully build a toast based on what the error was, given that there is a wide variety of different types
      else {
        const noun = t('nouns.error');
        const name = statusText ?? errName;
        const title = buildTitle(name, code, noun, options?.title);

        const statusCodeString = t(`http.codes.${code}`, ''); // If there is no localized string, it falls back to just an empty string

        const message = buildMessage(
          data,
          statusCodeString,
          fallbackErrorMessage,
        );
        const params = Notifications.error({ title, message });
        notifier(params);
      }
    },
    [notifier, t, unauthorizedToast],
  );
};
