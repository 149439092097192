import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  exam_reorder_modal_body,
  exam_reorder_modal_footer,
} from 'components/Report/Screenings/DrugHealthScreenings/locales';
import {
  useTrackEventOnMount,
  ReorderEvent,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import { useModalState } from '../../../context';
import { ActionType, ExamType } from '../../../types';
import { getSelectedExamsByType, isNextButtonDisabled } from './utils';

import ModalBody from '../body';
import Exams from './exams';
import ChoiceGroup from '../choice-group';
import ChoiceGroupHeader from './choice-group-header';

const body = `${exam_reorder_modal_body}.screening_selection`;

const ScreeningSelection = () => {
  const { t } = useTranslation();
  const onClose = useModalState('onClose');
  const nextScreen = useModalState('nextScreen');
  const { reorderableExams, drugObservedCollection } =
    useModalState('formData');
  const disabled = isNextButtonDisabled(
    reorderableExams,
    drugObservedCollection,
  );

  const navigation = {
    close: {
      name: t(`${exam_reorder_modal_footer}.cancel`),
      onClick: onClose,
    },
    next: {
      name: t(`${exam_reorder_modal_footer}.continue`),
      onClick: nextScreen,
      disabled,
    },
  };

  useTrackEventOnMount(ReorderEvent.ModalScreeningSelectionViewed);

  return (
    <>
      <ModalBody>
        <section>
          <h2>{t(`${body}.title`)}</h2>
          <Exams exams={reorderableExams} />
        </section>
        {!isEmpty(getSelectedExamsByType(ExamType.Drug, reorderableExams)) && (
          <ChoiceGroup
            header={<ChoiceGroupHeader />}
            type={ActionType.SET_DRUG_OBSERVED_COLLECTION}
            defaultSelected={drugObservedCollection}
          />
        )}
      </ModalBody>
      <M.ModalNavigationFooter
        close={navigation.close}
        next={navigation.next}
      />
    </>
  );
};

export default ScreeningSelection;
