import { CurrentUser } from '@dashboard-experience/utils';
import React from 'react';
import { createContext } from 'use-context-selector';

export type ContextType = {
  currentUser: CurrentUser | undefined;
  geos: Array<any>;
  geosLoading: boolean;
  defaultGeos: Array<string> | undefined;
  packages: Array<any>;
  packagesLoading: boolean;
  defaultPackages: Array<string> | undefined;
  programs: Array<any>;
  programsLoading: boolean;
  originalPrograms: Array<any>;
  setPrograms: React.Dispatch<React.SetStateAction<any>>;
  filters: { [key: string]: any };
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  assessEnabled: boolean;
  advancedFiltersLoading: boolean;
  showAdvancedFilters: boolean;
  showAllFilters: boolean;
  setShowAdvancedFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAllFilters: React.Dispatch<React.SetStateAction<boolean>>;
  savedSearches: Array<any>;
  savedSearchesLoading: boolean;
  defaultSavedSearch?: string;
  savedSearchId?: string;
  dispatch: any;
  state: any;
};

export const Context = createContext<ContextType>({
  currentUser: undefined,
  dispatch: () => {},
  state: {},
  geos: [],
  geosLoading: false,
  defaultGeos: [],
  packages: [],
  packagesLoading: false,
  defaultPackages: [],
  programs: [],
  programsLoading: false,
  originalPrograms: [],
  setPrograms: () => {},
  filters: {},
  setFilters: () => {},
  assessEnabled: false,
  advancedFiltersLoading: false,
  showAdvancedFilters: false,
  showAllFilters: false,
  setShowAdvancedFilters: () => {},
  setShowAllFilters: () => {},
  savedSearches: [],
  savedSearchesLoading: false,
  defaultSavedSearch: undefined,
  savedSearchId: undefined,
});

export default Context;
