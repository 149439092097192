import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { SetDefaultParams, useSetDefaultPayment } from 'api/payment';
import { OverflowMenu, OverflowMenuItem } from './PaymentStyledComponents';
import { scrollToTop } from '../../../utils';

type Props = {
  showDeleteModal: Function;
  isDefault?: boolean;
  testId?: string;
  stripeId: string;
  verified: boolean;
};

const EditPaymentMenu: React.FC<Props> = ({
  showDeleteModal,
  isDefault,
  testId,
  stripeId,
  verified,
}) => {
  const { t } = useTranslation();
  const user = useUser();
  const { setDefaultPaymentCall } = useSetDefaultPayment();

  const handleSetDefault = useCallback(() => {
    const params: SetDefaultParams = {
      accountId: user.account.id,
      stripeId,
    };
    setDefaultPaymentCall(params);
  }, [setDefaultPaymentCall, stripeId, user.account.id]);

  return (
    <OverflowMenu
      hideTooltip
      flipped
      className='user-row-overflow-actions'
      data-testid={testId}
    >
      <OverflowMenuItem
        itemText={t(`payment.button_labels.remove`)}
        onClick={(scrollToTop(), showDeleteModal)}
        data-testid={`${testId}-delete`}
      />
      <OverflowMenuItem
        disabled={isDefault || !verified}
        itemText={t(`payment.button_labels.make_default`)}
        onClick={handleSetDefault}
        data-testid={`${testId}-make-default`}
      />
    </OverflowMenu>
  );
};

export default EditPaymentMenu;
