import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TableContainer from 'modules/assess/ui/rulesets/TableContainer';
import StepTitle from './StepTitle';

type Props = {
  stepIndex: number;
  totalSteps: number;
};

export const StepReview: React.FC<Props> = ({ stepIndex, totalSteps }) => {
  const { t } = useTranslation('assess');

  return (
    <div>
      <StepTitle
        stepIndex={stepIndex}
        totalSteps={totalSteps}
        title={t('activate.steps.review.title')}
      />
      <Trans t={t} i18nKey='assess:activate.steps.review.body'>
        <p>
          You are a step closer to a fairer and faster process for evaluating
          your candidates. Review the rulesets that will go live below:
        </p>
      </Trans>
      <TableContainer />
    </div>
  );
};

export default StepReview;
