import { getStatusTypeForReport } from '@dashboard-experience/utils';
import { useReportsWithMultiMvr } from 'api/reports';
import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import React, { memo, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Sections from './sections';
import InvitationStatus from './sections/invitation-status';

const baseStyle = { margin: '1.5rem' };

const Overview: React.FC<{}> = () => {
  const reportMatch = useRouteMatch<{ reportId: string }>(
    '/candidates/:candidateId/reports/:reportId',
  );

  const invitationMatch = useRouteMatch<{ invitationId: string }>({
    path: '/candidates/:candidateId/invitations/:invitationId',
  });

  const candidateMatch = useRouteMatch({
    path: '/candidates/:candidateId',
    exact: true,
  });

  const invitationId = invitationMatch?.params?.invitationId as string;
  const reportId = reportMatch?.params?.reportId as string;
  const { report, isLoading: isReportLoading } = useReportsWithMultiMvr({
    reportId,
  });

  const candidate = useCandidate();
  const { invitations = [] } = candidate;

  const invitation = useMemo(() => {
    if (invitationId) {
      return invitations.find(invitation => invitation.id === invitationId);
    }
    if (candidateMatch) {
      return invitations[0];
    }
    return undefined;
  }, [invitationId, candidateMatch, invitations]);

  const user = useUser();
  const statusType = useMemo(
    // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
    () => getStatusTypeForReport(report, user),
    [report, user],
  );

  return (
    <div data-testid='candidate-overview' style={baseStyle}>
      {/* TODO: add error state for report info sections, define this with design */}
      {invitation && <InvitationStatus invitation={invitation} />}
      {(Object.keys(Sections) as Array<keyof typeof Sections>).map(section => {
        const Component = Sections[section];
        return (
          <Component
            key={section}
            report={report}
            isLoading={isReportLoading}
            statusType={statusType}
            candidate={candidate}
            invitation={invitation}
          />
        );
      })}
    </div>
  );
};

export default memo(Overview);
