import { TFunction } from 'i18next';
import { Report } from 'types';
import { AdverseAction } from '../../../data/adverse-actions';
import {
  EventView,
  AdjudicationEvent,
  AdjudicationEventTypes,
  ReportActionEvents,
} from '../../../data/events';
import processAdjudicationEvents from './adjudicationEvents';
import processAdverseActionEvents from './adverseActionEvents';
import processReportActionEvents from './reportActionEvents';

const sortDates = (a: EventView, b: EventView): number => {
  if (Date.parse(a.time) < Date.parse(b.time)) return 1;
  return -1;
};

const processEvents = (
  t: TFunction,
  report: Report,
  adverseActions: AdverseAction[],
  reportActionEvents: ReportActionEvents,
  adjudicationEvents: AdjudicationEvent[],
): EventView[] => {
  const aa_events = processAdverseActionEvents(t, adverseActions);
  const report_action_events = processReportActionEvents(t, reportActionEvents);
  const adj_events = processAdjudicationEvents(t, adjudicationEvents, report);
  const processedEvents = [
    ...aa_events,
    ...adj_events,
    ...report_action_events,
  ].sort((a, b) => sortDates(a, b));

  if (
    report.adjudication === AdjudicationEventTypes.ENGAGED &&
    !adjudicationEvents.some(
      (event) => event.type === AdjudicationEventTypes.ENGAGED,
    )
  ) {
    const candidateEngaged: EventView = {
      text: t('adjudication:report.events.candidate_engaged'),
      time: new Date().toISOString(),
    };
    processedEvents.push(candidateEngaged);
  }
  return processedEvents;
};

export default processEvents;
