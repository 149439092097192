import React, { useContext } from 'react';
import { useReport, useExceptions } from 'containers/Report';
import { useCandidate, useCandidateExceptions } from 'providers/Candidate';
import UIContext from 'context/UI';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';

import CandidateActions from 'modules/adjudication/ui/report/actions';
import InternalNotes from 'modules/case-management/ui/InternalNotes';
import useFetchCandidateDocuments from 'api/candidateDocuments/hooks';
import Aliases from './Aliases';
import { Addresses, InternationalCandidateAddresses } from './Addresses';
import ReportInformation from './ReportInformation/ReportInformation';
import Body from './Body';
import CandidateStories from './CandidateStories';
import CandidateDocuments from './CandidateDocuments';
import Exceptions from './Exceptions/Exceptions';
import HealthScreeningAppointmentPane from './Screenings/DrugHealthScreenings/HealthScreeningAppointmentPane';

type Props = {
  showCustomerViewToggle: boolean;
  customerView: boolean;
  customerViewProps: GenericObject;
  toggleCustomerView: () => void;
  showInternalNotes: boolean;
  showCustomerViewComponents: boolean;
};

const ReportContent: React.FC<Props> = ({
  showCustomerViewToggle,
  customerView,
  customerViewProps,
  toggleCustomerView,
  showInternalNotes,
  showCustomerViewComponents,
}) => {
  const report = useReport();
  const { contextId, isIframe } = useContext(UIContext);
  const candidate = useCandidate();
  const candidateDocuments = useFetchCandidateDocuments(candidate.id);
  const reportExceptions = useExceptions();
  const candidateExceptions = useCandidateExceptions();

  return (
    <>
      <M.Grid>
        <M.GridRow
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(365px, 1fr))',
            gridColumnGap: '30px',
          }}
        >
          <div>
            <ReportInformation
              report={report}
              isIframe={isIframe}
              contextId={contextId}
            />
          </div>
          <div>
            <CandidateActions report={report} />
          </div>
        </M.GridRow>
      </M.Grid>
      {!!report.appointment && (
        <HealthScreeningAppointmentPane appointment={report.appointment} />
      )}
      {showCustomerViewToggle && (
        <M.Expandable
          title='Customer View'
          initialExpanded={customerView}
          onExpand={toggleCustomerView}
          titleRowProps={customerViewProps}
        />
      )}
      {showInternalNotes && <InternalNotes />}
      {showCustomerViewComponents && (
        <>
          {!!candidateDocuments?.length && (
            <CandidateDocuments documents={candidateDocuments} />
          )}

          {!!reportExceptions?.exceptions?.length && (
            <Exceptions
              report={report}
              reportExceptions={reportExceptions.exceptions}
              candidateExceptions={[]}
              meta={reportExceptions.meta}
            />
          )}
          {!!candidateExceptions?.length && (
            <Exceptions
              report={report}
              reportExceptions={[]}
              candidateExceptions={candidateExceptions}
              meta={[]}
            />
          )}
          {!!report.candidate_stories?.length && (
            <CandidateStories report={report} />
          )}
          <Aliases report={report} />
          <Addresses report={report} />
          <InternationalCandidateAddresses report={report} />
          <Body />
        </>
      )}
    </>
  );
};

export default ReportContent;
