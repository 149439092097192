import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import Kind from 'modules/assess/models/rulesets/kind';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { useState } from './store';

const StyledAnchor = styled.a`
  cursor: pointer;
`;

export type Props = {};

export const ReadOnlyNotification: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const history = useHistory();
  const state = useState();

  const edit = useCallback(() => {
    history.push(Kind.draft);
  }, [history]);

  const editable = !useReadOnly();

  if (state.kind === Kind.draft) {
    return null;
  }

  const translation = () => {
    if (editable) {
      return (
        <Trans t={t} i18nKey='assess:rules.notifications.preview'>
          You&apos;re in read-only mode. To edit your ruleset, click
          <StyledAnchor role='link' onClick={edit}>
            Edit Draft
          </StyledAnchor>
        </Trans>
      );
    }
    return <Trans t={t} i18nKey='assess:rules.notifications.read_only' />;
  };

  return (
    <M.ActionableNotification
      hideActionButton
      inline='classic'
      kind='warning'
      title={translation()}
    />
  );
};

export default ReadOnlyNotification;
