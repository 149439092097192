import { useState as useStateContext } from 'modules/assess/ui/ruleset/version/store';

export const useValidationRules = (draftRule: any) => {
  const state = useStateContext();
  const rules = Object.values(state.rules?.count.table);

  return rules.some(
    ({ id, name }: { id: string; name: string }) =>
      id !== draftRule.id &&
      name.normalize().toLowerCase() ===
        draftRule.name.normalize().toLowerCase(),
  );
};
