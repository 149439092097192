import {
  StatusTypes,
  AssessStatuses,
  getStatusTypeForReport,
  Report,
} from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import { Assessment } from 'types';
import { useUser } from 'context/CurrentUser';
import { ItemVisibility } from 'modules/assess';

export const isAssessmentEligible = (assessment: Assessment) =>
  assessment?.display?.decision === AssessStatuses.ELIGIBLE;

export const removeEligibleRecords = (report: Report) => {
  const displaySetting = report?.account?.assess_eligible_item_display;

  return displaySetting === ItemVisibility.REMOVED;
};

export const isRecordShown = (statusOrAssessment: any, report: Report) =>
  Boolean(
    statusOrAssessment !== AssessStatuses.ELIGIBLE ||
      !removeEligibleRecords(report),
  );

export const useAssessEnabled = () => {
  const currentUser = useUser();
  const { report } = useGetReport();

  // TODO: Update remove once Report type is fully migrated to utils
  const reportStatusType = getStatusTypeForReport(
    report as Report,
    currentUser,
  );
  return reportStatusType === StatusTypes.Assess;
};
