import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  & p {
    margin-top: 24px !important;
  }
  & ul {
    list-style-type: disc;
    padding-left: 24px;
  }
`;

type ProvideContactInfoProps = {
  smsInvite?: React.ReactNode | null;
  allowBulkInvite: boolean;
};

const ProvideContactInfoManuallySection: React.FC<ProvideContactInfoProps> = ({
  smsInvite,
  allowBulkInvite,
}) => {
  return (
    <>
      <h4
        className='section-title'
        data-testid='provide-contact-info-section-title'
      >
        Provide contact information for your candidate(s).
      </h4>
      <p>We’ll send the invitation links after you place your order.</p>
      <StyledDiv>
        <p>Want to improve engagement rates?</p>
        <ul>
          <li>Personalize invitations with the candidate&apos;s name.</li>
          <li>Add a phone number to send links via text and email.</li>
        </ul>
      </StyledDiv>
      {smsInvite}
    </>
  );
};

export default ProvideContactInfoManuallySection;
