import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Dot = styled.div`
  background-color: ${({ enabled }) =>
    enabled ? `${colors.uiGreen600}` : `${colors.uiOrange600}`};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;

const Label = styled.p`
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AutopayIndicator = ({ enabled }) => {
  const { t } = useTranslation();

  const autopayStatus = enabled
    ? t(`payment.autopay.enabled`)
    : t(`payment.autopay.disabled`);

  return (
    <IndicatorContainer>
      <Dot enabled={enabled} />
      <Label>{autopayStatus}</Label>
    </IndicatorContainer>
  );
};

AutopayIndicator.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default AutopayIndicator;
