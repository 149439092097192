import React from 'react';
import { Report } from 'types';
import { M } from '@dashboard-experience/mastodon';
import { Decision, getEligibleDecisions } from 'modules/assess';
import LookaheadAssessment from './LookaheadAssessment';

type Props = {
  assessment: any;
  report: Report;
  statusType: string;
};

const eligibleDecisions = getEligibleDecisions();

const Badges: React.FC<Props> = ({ assessment, report, statusType }) => {
  const eligibleLookaheadAssessment =
    report.assessment?.lookahead_assessments?.find(
      a => a.decision === Decision.ELIGIBLE,
    );

  const eligibleAssessment = eligibleDecisions.includes(assessment);

  return (
    <>
      <M.StatusBadge statusType={statusType} status={assessment} />
      {!eligibleAssessment && (
        <LookaheadAssessment
          statusType={statusType}
          lookaheadAssessment={eligibleLookaheadAssessment}
        />
      )}
    </>
  );
};

export default Badges;
