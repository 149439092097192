/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { ThunkAction } from 'redux-thunk';
import { postToast } from 'utils';
import { init as createNotification } from 'state/notifications/actions/create';
import * as Notifications from 'state/notifications/entity';
import { TOAST_TIMEOUT } from 'Constants';

// Dispatch these actions to pop context-aware toasts
// set `timeout` to 0 to create "Sticky" toasts
// `kind` can be 'success' 'error' 'warning' or 'info'

export const toast =
  ({
    kind,
    title,
    message,
    timeout = TOAST_TIMEOUT,
    action,
    key,
  }: Notifications.Params): ThunkAction<any, any, any, any> =>
  (dispatch, getState) => {
    const state = getState();
    const { ui = {} } = state;
    const { contextId } = ui;

    const posted =
      contextId &&
      postToast({
        contextId,
        type: kind,
        title,
        body: message || action?.message, // If there is no direct message, try checking the Action instead
        timeout,
        key,
      });

    if (!posted) {
      dispatch(
        createNotification({
          kind,
          title,
          message,
          timeout,
          action,
          key,
        }),
      );
    }
  };

export const toastWarning = (
  title: Notifications.Params['title'],
  message?: Notifications.Params['message'],
  timeout: Notifications.Params['timeout'] = TOAST_TIMEOUT,
) =>
  toast({
    kind: Notifications.Kind.warning,
    title,
    message,
    timeout,
  });

export const toastInfo = (
  title: Notifications.Params['title'],
  message?: Notifications.Params['message'],
  timeout: Notifications.Params['timeout'] = TOAST_TIMEOUT,
) =>
  toast({
    kind: Notifications.Kind.info,
    title,
    message,
    timeout,
  });

export const toastError = (
  title: Notifications.Params['title'],
  message?: Notifications.Params['message'],
  timeout: Notifications.Params['timeout'] = TOAST_TIMEOUT,
) =>
  toast({
    kind: Notifications.Kind.error,
    title,
    message,
    timeout,
  });

export const toastSuccess = (
  title: Notifications.Params['title'],
  message?: Notifications.Params['message'],
  timeout: Notifications.Params['timeout'] = TOAST_TIMEOUT,
) =>
  toast({
    kind: Notifications.Kind.success,
    title,
    message,
    timeout,
  });

// Toasts with a clickable button:
export const actionableToast = (
  kind: Notifications.Params['kind'],
  title: Notifications.Params['title'],
  action: Notifications.Params['action'], // Action to perform upon clicking the button
  key: Notifications.Params['key'],
  timeout: Notifications.Params['timeout'] = 0, // Default to 0 (i.e. never timeout)
) => toast({ kind, title, action, timeout, key });
