import React, { useEffect } from 'react';
import { ID } from 'modules/id';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import * as Page from 'modules/assess/ui/page';
import * as Route from 'modules/assess/ui/route';
import { useNamespacedRoute } from 'modules/assess/ui/router/context';
import { Type } from 'modules/assess/models/rulesets/growth-insights/entity';
import { useGrowthInsights } from './api';
import { GrowthInsightsContent } from './Content';

export type Props = {
  id?: ID;
};

const Loading = styled(M.LoadingSpinner)`
  margin: auto;
`;

const BoldHeading = styled.h1`
  font-weight: bold;
`;

// TODO: EADA-1299 Remove mock parameter

export const GrowthInsightsContainer: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation('assess');
  const title = t('assess:rulesets.growth_insights.title');
  const path = useNamespacedRoute(`rulesets/${id}/growth_insights`);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const mock = search.get('mock') === 'true';
  const query = useGrowthInsights(id, mock);
  const loading = query.status === 'loading';
  const { refetch } = query;

  useEffect((): void | (() => void) => {
    const timer = setInterval(() => refetch(), 10000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(timer);
  }, [refetch]);

  if (loading) {
    return <Loading withOverlay={false} />;
  }

  const growthInsights: Type | undefined = query.data;

  return (
    <Route.Breadcrumb location={path} title='Growth Insights'>
      <Page.Container data-testid='assess-ruleset-growth-insights-container'>
        <BoldHeading>{title}</BoldHeading>
        <GrowthInsightsContent data={growthInsights} />
      </Page.Container>
    </Route.Breadcrumb>
  );
};

export default GrowthInsightsContainer;
