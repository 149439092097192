import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { camelCase } from 'lodash';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import { GenericObject } from 'types';
import {
  Report,
  CurrentUser as User,
  hasPermission,
  getDocument,
  getParamFromUrl,
  getInternationalDocument,
} from '@dashboard-experience/utils';
import { useGetConsentLink } from 'api/documents';
import { useFlag } from '@dashboard-experience/react-flagr';
import { SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY } from 'Constants';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import PdfLinks from './PdfLinks';
import {
  getUniqueReports,
  getInternationalReportPdfButtonText,
} from '../../../../utils';

const RenderTitle = () => (
  <div className='mastodon-menu-title' style={{ display: 'flex' }}>
    <span className='mastodon-menu-label'>Download PDF</span>
    <M.Icon icon='ChevronDown' size='16' style={{ marginLeft: '0.5rem' }} />
  </div>
);

const Note = () => (
  <p
    data-testid='note'
    style={{
      marginTop: '1rem',
      fontSize: sizes.small,
      color: `${colors.uiGrey900}a6`,
      lineHeight: '1rem',
    }}
  >
    Report PDF is password protected <br />
    with the last four digits of the <br />
    candidate’s SSN
  </p>
);

const StyledDropdownButtonWrapper = styled('div')`
  .cds--popover-container {
    width: 208px;
  }

  .mastodon-overflow-menu {
    max-height: 3rem;
    color: ${colors.uiTextPrimaryLight};
    width: 100%;
    box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
    border: 1px solid ${colors.uiGrey200};
    border-radius: 4px;
    height: 36px;
    margin: 0 0.5rem;
  }

  .cds--popover {
    display: none !important;
  }

  .mastodon-menu-label {
    font-weight: bold;
  }
`;

const PDFS = [
  'pdf_report_encrypted',
  'pdf_report',
  'pdf_health_report',
  'pdf_credit_report',
  'pdf_wisconsin_doj',
  'pdf_state_criminal_search',
  'pdf_self_disclosure',
  'pa_child_abuse_result_certificate',
  'dot_employment_authorization',
  'pdf_international_report',
  'pdf_professional_license_verification_report',
  'order_summary_pdf',
];

const useGetDocuments = (documents: string[], report: Report) => {
  const docs = {} as GenericObject;

  documents.forEach((document: string) => {
    if (
      report?.documents &&
      report.documents.length > 1 &&
      document === 'pdf_international_report'
    ) {
      docs[camelCase(document)] = getInternationalDocument(report, document);
    } else {
      docs[camelCase(document)] = getDocument(report, document);
    }
  });

  return docs;
};

const DownloadPdfButton: React.FC<{ currentUser: User; report: Report }> = ({
  currentUser,
  report,
}) => {
  const isDocQa = useCallback(() => {
    const requestedDocView =
      getParamFromUrl(window, 'view') === 'doc_view' &&
      hasPermission(currentUser, 'doc_view');
    const enforcedDocView =
      hasPermission(currentUser, 'doc_view') &&
      !hasPermission(currentUser, 'read_full_reports');
    return requestedDocView || enforcedDocView;
  }, [currentUser]);

  const {
    pdfReport,
    pdfReportEncrypted,
    pdfHealthReport,
    pdfWisconsinDoj,
    pdfStateCriminalSearch,
    pdfCreditReport,
    pdfSelfDisclosure,
    dotEmploymentAuthorization,
    pdfInternationalReport,
    paChildAbuseResultCertificate,
    pdfProfessionalLicenseVerificationReport,
    orderSummaryPdf,
  } = useGetDocuments(PDFS, report);

  const isPdfReport = pdfReportEncrypted || pdfReport;

  const showPdfEncryptionNote =
    isPdfReport && isPdfReport.type === 'pdf_report_encrypted';

  const { consentLink = null, internationalConsentLink = null } =
    useGetConsentLink(report.id, isDocQa(), currentUser);

  const pdfItems = [] as GenericObject[];

  const selfDislosureAvailableWhilePending =
    useFlag(SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY)
      ?.variantKey === 'enabled';

  if (hasPermission(currentUser, 'read_reports') && consentLink) {
    pdfItems.push({
      link: consentLink,
      text: 'Download Consent',
      docType: 'consent',
    });
  }

  if (hasPermission(currentUser, 'read_reports') && internationalConsentLink) {
    pdfItems.push({
      link: internationalConsentLink,
      text: 'Download International Consent',
      docType: 'consent',
    });
  }

  if (isPdfReport) {
    pdfItems.push({
      link: isPdfReport.download_uri,
      text: 'Report PDF',
      type: isPdfReport.type,
      isPdfReport: true,
      disabled: report.status === 'pending',
    });
  }

  if (pdfHealthReport) {
    pdfItems.push({
      link: pdfHealthReport.download_uri,
      text: 'Health Screening PDF',
      docType: 'health_report',
      disabled: report.status === 'pending',
    });
  }

  if (pdfWisconsinDoj) {
    pdfItems.push({
      link: pdfWisconsinDoj.download_uri,
      text: 'Health Screening PDF',
      docType: 'wisconsin_doj',
      disabled: report.status === 'pending',
    });
  }

  if (pdfStateCriminalSearch) {
    pdfItems.push({
      link: pdfStateCriminalSearch.download_uri,
      text: 'State Criminal PDF',
      docType: 'state_criminal_search',
      disabled: report.status === 'pending',
    });
  }

  if (pdfCreditReport) {
    pdfItems.push({
      link: pdfCreditReport.download_uri,
      text: 'Credit Report PDF',
      docType: 'credit_report',
    });
  }

  if (
    pdfSelfDisclosure &&
    (selfDislosureAvailableWhilePending || report.status !== 'pending')
  ) {
    pdfItems.push({
      link: pdfSelfDisclosure.download_uri,
      text: 'Self Disclosure PDF',
      docType: 'self_disclosure',
    });
  }

  if (dotEmploymentAuthorization) {
    pdfItems.push({
      link: dotEmploymentAuthorization.download_uri,
      text: 'DOT Employment Authorizations',
      docType: 'dot_emplooyment_authorization',
      disabled: report.status === 'pending',
    });
  }

  if (pdfInternationalReport) {
    if (Array.isArray(pdfInternationalReport)) {
      let localeDocCount = 0;
      let nullLocalDocCount = 0;
      const reportData = getUniqueReports(pdfInternationalReport);
      reportData.forEach((pdfReport: any) => {
        const docLocale = pdfReport?.locale ? pdfReport.locale : null;
        const buttonText = getInternationalReportPdfButtonText(pdfReport);
        if (docLocale) {
          pdfItems.push({
            link: pdfReport.download_uri,
            text: buttonText,
            docType: 'international_report',
            disabled: report.status === 'pending',
          });
          localeDocCount += 1;
        } else if (localeDocCount === 0 && nullLocalDocCount === 0) {
          pdfItems.push({
            link: pdfReport.download_uri,
            text: buttonText,
            docType: 'international_report',
            disabled: report.status === 'pending',
          });
          nullLocalDocCount += 1;
        }
      });
    } else {
      pdfItems.push({
        link: pdfInternationalReport.download_uri,
        text: getInternationalReportPdfButtonText(pdfInternationalReport),
        docType: 'international_report',
        disabled: report.status === 'pending',
      });
    }
  }

  if (paChildAbuseResultCertificate) {
    pdfItems.push({
      link: paChildAbuseResultCertificate.download_uri,
      text: 'PA State Registry Certificate',
      docType: 'pdf_pa_child_abuse_certificate',
    });
  }

  if (pdfProfessionalLicenseVerificationReport) {
    pdfItems.push({
      link: pdfProfessionalLicenseVerificationReport.download_uri,
      text: 'Professional License Report PDF',
      docType: 'professional_license_verification_report',
      disabled: report.status === 'pending',
    });
  }

  if (orderSummaryPdf) {
    pdfItems.push({
      link: orderSummaryPdf.download_uri,
      text: 'Order Summary PDF',
      docType: 'order_summary_pdf',
      disabled: report.status === 'pending',
    });
  }

  const [thereWasAction, setThereWasAction] = useState(false);

  const onOpenMenu = useCallback(() => {
    setThereWasAction(false);
  }, [setThereWasAction]);

  const closeWithAction = useCallback(() => {
    setThereWasAction(true);
  }, [setThereWasAction]);

  const trackEvent = useTrackEvent();

  const onCloseMenu = useCallback(() => {
    if (currentUser && !thereWasAction) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Document Selected': 'No Action',
      });
    }
  }, [currentUser, thereWasAction, trackEvent]);

  // @ts-ignore
  return (
    <div>
      <StyledDropdownButtonWrapper>
        <M.OverflowMenu
          data-floating-menu-container
          renderIcon={RenderTitle}
          id='pdf-downloads'
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
        >
          {pdfItems.map((pdf, idx) => {
            return (
              <PdfLinks
                key={`pdf.docType-${idx + 1}`}
                item={pdf}
                // @ts-ignore TODO: this error should be fixed once report type is updated in Utils
                report={report}
                closeWithAction={closeWithAction}
              />
            );
          })}
        </M.OverflowMenu>
      </StyledDropdownButtonWrapper>

      {showPdfEncryptionNote && <Note />}
    </div>
  );
};

export default DownloadPdfButton;
