import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

import styled from 'styled-components';
import { List, TestNumberContainer } from './styles';
import {
  DROPDOWN_OPTIONS,
  calculateSelectedCountFromPercentage,
} from './utils';
import { TestInformationFormProps } from './types';

const QuestionLabel = styled.label`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight};
`;

const TitleTest = styled.h6`
  margin-bottom: 0 !important;
`;

const TestInformationForm: React.FC<TestInformationFormProps> = ({
  values,
  screeningType,
  screening,
  handleChange,
  poolSize,
  showError,
  handleBlur,
}) => {
  const stringKeyPrefixForValues = screeningType.toLowerCase();

  const numberValue =
    stringKeyPrefixForValues === 'drug'
      ? values.drugRandomSelectionSize
      : values.alcoholRandomSelectionSize;

  const typeValue =
    stringKeyPrefixForValues === 'drug'
      ? values.drugNumberType
      : values.alcoholNumberType;

  const isFixed = typeValue === DROPDOWN_OPTIONS.FIXED;

  return (
    <M.Grid style={{ width: '100%' }}>
      <M.GridRow>
        <TitleTest>{screeningType} test</TitleTest>
      </M.GridRow>
      <M.GridRow>
        <List>
          {' '}
          <li>{screening}</li>
        </List>
      </M.GridRow>
      <M.GridRow>
        <QuestionLabel data-testid='question-label'>How many?</QuestionLabel>
      </M.GridRow>
      <M.GridRow>
        <TestNumberContainer>
          <M.NumberInput
            data-testid={`${stringKeyPrefixForValues}RandomSelectionSize`}
            id={`${stringKeyPrefixForValues}RandomSelectionSize`}
            name={`${stringKeyPrefixForValues}RandomSelectionSize`}
            placeholder='Number'
            value={numberValue}
            onChange={handleChange}
            onBlur={handleBlur}
            iconDescription='increase or decrease cohort size'
            aria-label='number of individuals in cohort'
            helperText={
              <>
                {typeValue && !isFixed
                  ? `${calculateSelectedCountFromPercentage(
                      numberValue,
                      poolSize,
                    )} employee(s)`
                  : ''}
              </>
            }
            invalid={showError(
              `${stringKeyPrefixForValues}RandomSelectionSize`,
            )}
            invalidText={showError(
              `${stringKeyPrefixForValues}RandomSelectionSize`,
            )}
            min={0}
            max={isFixed ? poolSize : 100}
            hideSteppers
          />
          <M.Select
            id={`${stringKeyPrefixForValues}NumberType`}
            name={`${stringKeyPrefixForValues}NumberType`}
            placeholder='Fixed or %'
            onChange={handleChange}
            labelText='Fixed or %'
            hideLabel
          >
            <M.Select.Item value='' text='' />
            {Object.keys(DROPDOWN_OPTIONS).map(item => (
              <M.Select.Item
                key={item}
                value={DROPDOWN_OPTIONS[item]}
                text={DROPDOWN_OPTIONS[item]}
              />
            ))}
          </M.Select>
        </TestNumberContainer>
      </M.GridRow>
    </M.Grid>
  );
};

export default TestInformationForm;
