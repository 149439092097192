import React, { useCallback } from 'react';
import { ItemVisibility } from 'modules/assess';
import { Category, RulesType } from 'modules/assess/models/rules';
import { DataTableHeader, DataTableRow } from 'carbon-components-react';
import * as Entity from 'modules/assess/models/rules/premade';
import { Namespace } from 'modules/assess/api';
import T from 'components/Table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Content from './Content';
import Title from './Title';

export type Header = DataTableHeader;

export type Row = DataTableRow & {
  rule: Entity.Type;
};

export const Table = styled(T)`
  margin-top: 2em;
`;

type Props = {
  category?: Category;
  namespace: Namespace;
  rows: Array<Row>;
  rules: RulesType;
  onSelect: (id: string, selected: boolean) => void;
  onUpdate: (id: string, itemVisibility?: ItemVisibility) => void;
};

const columns = ['title'];

export const Section: React.FC<Props> = ({
  category,
  namespace,
  rows,
  rules,
  onSelect,
  onUpdate,
}) => {
  const { t } = useTranslation('assess');
  const title = t(`rules.standard.categories.${namespace}.${category}.title`);
  const selected = rows.filter(r => rules.category(r.rule.code) === category);

  const expansion = useCallback(
    (id: string) => {
      const rule = selected.find(r => r.id === id) as Row;
      if (!rule) {
        return null;
      }

      return (
        <Content
          disabled={rule.disabled}
          selected={rule.isSelected}
          namespace={namespace}
          rule={rule.rule}
          onUpdate={onUpdate}
        />
      );
    },
    [namespace, onUpdate, selected],
  );

  if (selected.length === 0) {
    return null;
  }

  const items = selected.map(item => {
    return {
      ...item,
      title: <Title namespace={namespace} rule={item.rule} />,
    };
  });

  return (
    <Table
      columns={columns}
      expandable
      expandedHighlight
      expansion={expansion}
      rows={items}
      selectable
      title={title}
      onSelect={onSelect}
    />
  );
};

export default Section;
