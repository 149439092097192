import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { colors, M } from '@dashboard-experience/mastodon';
import { useFetchBrandObject, useUpdateBrand } from 'api/brand/hooks';
import ReactFilestack from 'filestack-react';
import {
  brandingFilestackKey as filestackKey,
  filestackOptions,
} from 'Constants';

import { postMessageToDashboard, scrollToTop } from 'utils';

import { BodyContainer } from 'components/Segments';
import { EditBrandFooter } from 'components/Account/Brands';
import {
  LogoPlaceholder,
  FileInputGroupButtons,
  InputGroup,
  InputLabel,
  EditBrandContainer,
  Delete,
  BrandFileInputGroup,
} from 'components';
import { GenericObject } from '@dashboard-experience/utils';
import UIContext from 'context/UI';

import { useFilestackUploadResponse } from 'hooks';
import { toastError } from '../../actions';

type Props = {
  brandId: string;
  hideModal?: () => void;
  page?: number;
};

const customRender = ({ onPick }: GenericObject) => (
  <M.Button kind='primary' style={{ marginLeft: '30px' }} onClick={onPick}>
    Choose File
  </M.Button>
);

const BrandContainer: React.FC<Props> = ({ brandId, hideModal, page }) => {
  const { isIframe } = useContext(UIContext);
  const [brand, setBrand] = useState<any>();
  const {
    data: brandObjectData = {},
    isLoading,
    isSuccess,
  } = useFetchBrandObject(brandId);
  const { call: updateBrands } = useUpdateBrand(page);
  const data = brand || brandObjectData;
  const [onDelete, setOnDelete] = useState(false);
  const dispatch = useDispatch();

  const showModal = useCallback(() => {
    setOnDelete(true);
    scrollToTop();
  }, [setOnDelete]);

  const hideDelete = useCallback(() => {
    setOnDelete(false);
  }, [setOnDelete]);

  const onCloseModal = useCallback(() => {
    if (isIframe) {
      postMessageToDashboard({
        broadcastMessage: 'edit_brand_close_modal',
      });
    } else {
      hideModal?.();
    }
  }, [hideModal, isIframe]);

  useEffect((): void => {
    if (isSuccess) {
      setBrand(brandObjectData);
    }
  }, [isSuccess, brandObjectData]);

  const updateAndRefresh = useCallback(() => {
    updateBrands(brand).then(() => {
      if (isIframe) {
        // will let dashboard know when to reload brands
        postMessageToDashboard({
          broadcastMessage: 'updated_brand',
        });
      } else {
        hideModal?.();
      }
    });
  }, [brand, hideModal, isIframe, updateBrands]);

  const handleChange = useCallback(
    event => {
      let { value } = event.target;
      const { name } = event.target;
      if (!value) value = null;
      setBrand((prevBrandValues: any) => ({
        ...prevBrandValues,
        [name]: value,
      }));
    },
    [setBrand],
  );

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: () => {
      dispatch(toastError('Failed to upload file'));
    },
    successCallback: file => {
      setBrand((prevBrandValues: any) => ({
        ...prevBrandValues,
        logo_uri: file.url,
      }));
    },
  });

  const onFileDelete = useCallback(() => {
    setBrand((prevBrandValues: any) => ({
      ...prevBrandValues,
      logo_uri: null,
    }));
    hideDelete();
  }, [setBrand, hideDelete]);

  if (onDelete) {
    return <Delete hideDelete={hideDelete} onFileDelete={onFileDelete} />;
  }

  return (
    <EditBrandContainer data-testid='brand-info'>
      {isLoading ? (
        <M.LoadingSpinner />
      ) : (
        <BodyContainer>
          <InputGroup>
            <InputLabel>Name (required)</InputLabel>
            <M.Input
              onChange={handleChange}
              name='name'
              value={data.name || ''}
              placeholder='Enter brand name'
              data-testid='brand-name'
            />
          </InputGroup>

          <InputGroup>
            <InputLabel>Website</InputLabel>
            <M.Input
              onChange={handleChange}
              name='website'
              type='url'
              value={data.website || ''}
              placeholder='http://'
            />
          </InputGroup>

          <BrandFileInputGroup>
            {data.logo_uri ? (
              <img src={data.logo_uri} alt='Logo' width='auto' height={160} />
            ) : (
              <LogoPlaceholder data-testid='logo-placeholder'>
                <M.Icon
                  icon='Image'
                  size='20'
                  style={{
                    fill: colors.uiTextSecondaryLight,
                  }}
                />
              </LogoPlaceholder>
            )}
            <FileInputGroupButtons>
              <ReactFilestack
                actionOptions={filestackOptions}
                apikey={filestackKey}
                customRender={customRender}
                onSuccess={onFileUpload}
              />
              <M.Button
                kind='primary'
                style={{ marginTop: '10px', marginLeft: '30px' }}
                onClick={showModal}
                data-testid='delete-logo-btn'
              >
                Delete
              </M.Button>
            </FileInputGroupButtons>
          </BrandFileInputGroup>
        </BodyContainer>
      )}
      <EditBrandFooter
        disableSave={!data.name}
        updateAndRefresh={updateAndRefresh}
        onCloseModal={onCloseModal}
      />
    </EditBrandContainer>
  );
};

export default BrandContainer;
