import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { Value } from 'modules/assess/ui/settings/reports/eligible-records/report-view';
import Description from './Description';
import Example from './Example';

type Props = {
  example?: { disabled?: boolean };
  value: Value;
};

const Label = styled(M.Expandable)`
  color: ${colors.brandNavy3};
  font-size: 14px;
  font-weight: bold;
`;

const OptionLabel: React.FC<Props> = ({ example, value }) => {
  const { t } = useTranslation('assess:v2');
  const [expanded, setExpanded] = useState(false);
  const onExpand = useCallback(isExpanded => {
    setExpanded(isExpanded);
  }, []);

  const expandedKey = expanded ? 'expanded' : 'default';
  const title = t(
    `home.settings.eligible_records.options.${value.toLowerCase()}.${expandedKey}`,
  );

  return (
    <Label
      id='assess-v2-ui-home-settings-eligible-records-option-label-title'
      title={title}
      onExpand={onExpand}
    >
      <Description value={value} />
      <Example example={example} value={value} />
    </Label>
  );
};

export default OptionLabel;
