import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useModalState, useDispatch } from '../../context';
import { OptionalFCI, ChargesTable } from '../../../../fair-chance';
import Warnings from '../warnings';
import { useGetReport } from '../../../../../../../../api/reports';

type Props = {};

const FairChance: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentScreen = useModalState('currentScreen');
  const transitionToScreen = useModalState('transitionToScreen');
  const { adverseItems = [] } = useModalState('state');
  const { report } = useGetReport();

  useEffect(() => {
    const next = t(
      `${namespace}:report.actions.pre_adverse.fair_chance.buttons.add_fair_chance`,
    );

    const previousScreen = {
      onClick: () => transitionToScreen('adverse_items'),
    };
    const nextScreen = {
      onClick: () => transitionToScreen('fair_chance_form'),
      name: next,
    };
    const screenName = report?.account?.adverse_action_medium_selection
      ? 'config_options'
      : 'preview_email';

    const skip = {
      onClick: () => {
        dispatch({
          type: 'REMOVE_INDIVIDUALIZED_ASSESSMENT',
        });
        transitionToScreen(screenName);
      },
    };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next: nextScreen,
        back: previousScreen,
        skip,
      },
    });
    // transitionToScreen is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, dispatch]);

  return (
    <div>
      <div style={{ paddingBottom: '30px' }}>
        <Warnings currentScreen={currentScreen} />
        <OptionalFCI />
        <ChargesTable adverseItems={adverseItems} />
      </div>
    </div>
  );
};

export default FairChance;
