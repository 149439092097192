import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { TaskType } from 'modules/case-management/types';
import { BatchActionContainer, IconButton } from './styles';
import BatchActionButton from './BatchActionButton';

type Props = {
  clearSelections: () => void;
  selections: any;
  tasks: [] | TaskType[];
};

const Container: React.FC<Props> = ({
  clearSelections,
  selections,
  tasks = [],
}: Props) => {
  const { t } = useTranslation('case-management');

  const selectedTasks = tasks.filter(task => selections[task.id]);

  if (selectedTasks.length === 0) return null;

  return (
    <BatchActionContainer data-testid='case-management-table-batch-action'>
      <IconButton kind='primary' onClick={clearSelections}>
        <M.Icon icon='Close' size={24} />
        {selectedTasks.length} {t('tasks.table.selected')}
      </IconButton>
      <BatchActionButton selectedTasks={selectedTasks} tasks={tasks} />
    </BatchActionContainer>
  );
};

export default Container;
