import React from 'react';
import { BaseRuleChange } from 'modules/assess/models/rulesets/version-diff';
import { ChangeType } from 'modules/assess/models/rulesets/version-diff/common';
import { Content } from 'modules/assess/ui/page';
import Changes from './action';
import Title from '../Title';
import { Type } from './type';

type Props = {
  changes: BaseRuleChange.List;
  type: Type;
};

export const Container: React.FC<Props> = ({ changes, type }) => {
  return (
    <Content>
      <Title type={type} />
      <Changes
        changeType={ChangeType.deleted}
        changes={getFilteredChanges(changes, ChangeType.deleted)}
        type={type}
      />
      <Changes
        changeType={ChangeType.created}
        changes={getFilteredChanges(changes, ChangeType.created)}
        type={type}
      />
      <Changes
        changeType={ChangeType.updated}
        changes={getFilteredChanges(changes, ChangeType.updated)}
        type={type}
      />
    </Content>
  );
};

function getFilteredChanges(
  changes: BaseRuleChange.List,
  changeType: ChangeType,
) {
  return changes.filter(c => c.change_type === changeType);
}

export default Container;
