import * as Entity from 'modules/assess/models/settings/account';
import React, { useCallback } from 'react';
import SettingToggle from 'modules/assess/ui/common/settings/setting-toggle';
import Options from './options';
import SelectedPackages from './selected-packages';
import SelectPackages from './select-packages';
import { Settings } from '../../../../../utilities';
import * as Package from '../../../../../models/settings/package';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

export const AutoAdverseActionSetting: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  const selections = Settings.getAutoAdverseActionPackages(settings?.packages);

  const setPackages = useCallback(
    (packages: Package.List) => {
      setSettings(state => {
        return { ...state, packages };
      });
    },
    [setSettings],
  );

  const disabled =
    !settings?.assess_auto_adverse_action_escalated_reports ||
    settings?.assess_auto_adverse_action_escalated_reports_value !==
      'enabled_packages';

  return (
    <>
      <SettingToggle
        name='assess_auto_adverse_action_escalated_reports'
        versionClass='v1'
        settings={settings}
        setSettings={setSettings}
      />
      <Options settings={settings} setSettings={setSettings} />
      <SelectedPackages selections={selections} />
      <SelectPackages
        packages={settings?.packages}
        setPackages={setPackages}
        disabled={disabled}
      />
    </>
  );
};

export default AutoAdverseActionSetting;
