import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';
import { InternationalCriminalSearch as Search, Report } from 'types';
import CriminalRecord from '../Components/CriminalRecord';
import formatCountry from './utils';

const InternationalCriminalSearch: React.FC<{
  report: Report;
  search: Search;
  statusType: any;
}> = ({ report, search, statusType }) => {
  const title = formatCountry(search.country);
  const result = getResultOrStatus(search);

  return (
    <M.SubScreening title={title} statusType={statusType} screening={search}>
      {result === ReportStatuses.CONSIDER &&
        search.records?.map(record => (
          <CriminalRecord
            key={record.id}
            report={report}
            statusType={statusType}
            record={record}
          />
        ))}
    </M.SubScreening>
  );
};

export default InternationalCriminalSearch;
