import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  onOpen?: () => void;
};

export const Content: React.FC<Props> = ({ onOpen }) => {
  const { t } = useTranslation('assess');

  return (
    <p>
      <Trans t={t} i18nKey='assess:activate.panel.body' />
    </p>
  );
};

export default Content;
