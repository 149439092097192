import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Content = styled.div.attrs(() => ({
  className: 'pre-aa-modal-content',
}))`
  background: ${colors.uiGrey0};
  section {
    padding: 1.5rem 0rem;
    border-bottom: 2px solid ${colors.uiGrey100};
  }
  section:first-of-type {
    padding-top: 0;
  }
  section:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
  overflow-x: hidden;
`;

export default Content;
