import React, { useCallback, ChangeEvent, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { capitalize } from 'lodash';
import { usePostV1Documents } from 'api/documents';
import { useReport } from 'containers/Report';
import { underscoresToSpaces } from '@dashboard-experience/utils';
import { toBase64 } from 'utils/FileUpload';
import FileLabel from 'components/FileLabel';
import { Row } from './TableComponents';
import { useHealthScreening } from '../OccHealthScreening/health-context';
import { showTestAssessmentOrStatus } from '../utils';

type Props = {
  row: { [x: string]: any };
  statusOrAssessment: string;
  canceledStatusEnabled: boolean | null | undefined;
};

const testResultString = (providerStatus: string, disposition: string) =>
  [providerStatus, disposition].filter(Boolean).join(', ');

const Cell = styled(M.TableCell)`
  [data-testid='drug-health-table-body-cell-doc-info']:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

const StatusItem = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Tooltip = styled(M.TooltipDefinition)`
  padding-left: 5px;
`;

const HealthRow: React.FC<Props> = ({
  row,
  statusOrAssessment,
  canceledStatusEnabled,
}) => {
  const { name, status } = row;

  const report = useReport();
  const reportStatusType = useHealthScreening('reportStatusType');

  const testStatus = showTestAssessmentOrStatus(
    reportStatusType,
    statusOrAssessment,
    status,
  );

  const candidateId = report?.candidate?.id as string;

  const { call } = usePostV1Documents();

  const handleImport = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const parsedFile = await toBase64(file);
      call({
        candidateId,
        params: {
          content_type: file.type,
          file_content: parsedFile,
          verifiable_id: row.slug.toUpperCase(),
        },
      });
    },
    [call, candidateId, row.slug],
  );

  return (
    <Row data-testid='drug-health-table-body-row'>
      <M.TableCell>{name}</M.TableCell>

      <M.TableCell>
        {row?.documents?.length ? (
          <div>{row.documents.length} uploaded document(s)</div>
        ) : (
          <div data-testid='drug-health-table-body-cell-test-info'>
            {testResultString(row.provider_status, row.disposition)}
            {row.comments &&
              row.comments.map((comment: string) => (
                <Fragment key={comment}>
                  <br />
                  {comment}
                </Fragment>
              ))}
          </div>
        )}
      </M.TableCell>

      <Cell>
        {row.documents.length > 0 &&
          row.documents.map((doc: any, index: number) => {
            const { created_at, download_uri, type } = doc;
            return (
              <div
                key={`${download_uri}-${index.toString()}`}
                data-testid='drug-health-table-body-cell-doc-info'
              >
                <a
                  data-testid='drug-health-table-body-cell-doc-info-link'
                  href={download_uri}
                  rel='noreferrer'
                  target='_blank'
                >
                  {capitalize(underscoresToSpaces(type))}
                </a>
                <span
                  data-testid='drug-health-table-body-cell-doc-info-date'
                  style={{ marginLeft: '0.5rem', color: colors.uiGrey600 }}
                >
                  {moment(created_at).format('l hh:mm a')}
                </span>
              </div>
            );
          })}
      </Cell>

      <M.TableCell>
        {row.documents.length > 0 && (
          <FileLabel label='Upload documents' onChange={handleImport} />
        )}
      </M.TableCell>

      <M.TableCell>
        <StatusItem data-testid='drug-health-table-body-cell-status-item'>
          <M.StatusBadge status={testStatus} statusType={reportStatusType} />
          {canceledStatusEnabled && statusOrAssessment === 'canceled' && (
            <Tooltip
              highlighted={false}
              align='top-right'
              definition={row.cancellation_reason_description}
            >
              <M.Icon icon='WarningAlt' />
            </Tooltip>
          )}
        </StatusItem>
      </M.TableCell>
    </Row>
  );
};

export default HealthRow;
