import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .package-name {
    overflow-wrap: anywhere;
  }

  .mastodon-loading-inline {
    min-height: 1rem;
    width: 1rem;
  }
`;

export const OverflowMenu = styled(M.OverflowMenu)`
  display: inline;
  width: auto;

  .cds--overflow-menu-options {
    max-height: 110px;
    overflow-y: auto;
  }

  .cds--overflow-menu-options__option-content {
    line-height: 1.5rem;
  }

  &.cds--overflow-menu {
    height: unset;
    color: ${colors.uiNavy600};

    &.cds--btn--md {
      min-height: 1.4rem;
      padding: 0;
    }

    &.cds--overflow-menu > :first-child {
      text-decoration: underline !important;
    }

    &.cds--overflow-menu--open {
      box-shadow: none;
      background-color: ${colors.uiGrey0};

      .cds--overflow-menu-options__btn:focus {
        outline: none;
      }
    }

    &:hover {
      background: unset;
      color: ${colors.uiNavy700};
    }
  }
`;
