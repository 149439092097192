import { ItemType } from 'modules/assess/models/mvr/codes';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  section: 'categories' | 'mappings';
  type: ItemType;
};

const Description: React.FC<Props> = ({ section, type }) => {
  const { t } = useTranslation('assess');
  const text = t(
    [
      `assess:mvr.category-sets.instance.versions.instance.${type}.${section}.description`,
      `assess:mvr.category-sets.instance.versions.instance.common.${section}.description`,
    ],
    { section, type },
  );

  const components = {
    'clients-mailto-link': (
      <a href='mailto:clients@checkr.com?subject=Assess: MVR Category Customization'>
        clients
      </a>
    ),
  };

  return (
    <p
      data-testid={`assess-mvr-category-set-version-mappings-${section}-description`}
    >
      <Trans components={components} defaults={text} />
    </p>
  );
};

export default Description;
