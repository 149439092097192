import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { capitalize } from 'lodash';
import StatusCircle from 'components/StatusCircle';
import { Row, FlexContainer } from './TableComponents';

const DrugRow = ({ row }: { [x: string]: any }) => {
  const { substance_type, result } = row;
  const status =
    result.toLowerCase() === 'positive' ? (
      <StatusCircle status='positive' />
    ) : null;

  return (
    <Row data-testid='drug-health-table-body-row'>
      <M.TableCell>{substance_type}</M.TableCell>
      <M.TableCell>
        <FlexContainer>
          {status}
          {capitalize(result)}
        </FlexContainer>
      </M.TableCell>
    </Row>
  );
};

export default DrugRow;
