import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFormik } from 'formik';
import { GenericObject } from '@dashboard-experience/utils';
import { IWorksite } from './Models';
import useSubmitAddWorksite from './hooks/useSubmitAddWorksite';
import WorksiteValidations from './utils/worksiteSchema';
import useSubmitEditWorksite from './hooks/useSubmitEditWorksite';
import StateDropdown from './StateDropdown';
import { FlexContainer, FlexRowJustify } from './style';

type CreateOrEditWorksiteProps = {
  isOpen: boolean;
  onClose: any;
  onSuccessManipulation: Function;
  isAddWorksiteMode: boolean;
  worksiteEditObj: GenericObject;
  isEverifyActive: boolean;
};

const CreateOrEditWorksite: React.FC<CreateOrEditWorksiteProps> = ({
  isOpen,
  onClose,
  onSuccessManipulation,
  isAddWorksiteMode,
  worksiteEditObj,
  isEverifyActive,
}) => {
  const handleCreateOrEditWorksiteClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const initialValues: IWorksite = {
    worksiteName: isAddWorksiteMode ? '' : worksiteEditObj.name,
    street1: isAddWorksiteMode ? '' : worksiteEditObj.street_line1,
    street2: isAddWorksiteMode ? '' : worksiteEditObj.street_line2,
    city: isAddWorksiteMode ? '' : worksiteEditObj.city,
    state: isAddWorksiteMode ? '' : worksiteEditObj.state,
    zipCode: isAddWorksiteMode ? '' : worksiteEditObj.zip_code,
    eVerifyStatus: isAddWorksiteMode
      ? false
      : worksiteEditObj.everify_status === 'active',
  };

  const [modalOpenState, setCreateOrEditWorksiteOpenState] = useState(false);

  useEffect(() => {
    setCreateOrEditWorksiteOpenState(isOpen);
  }, [isOpen]);

  const add = useSubmitAddWorksite(() => {
    setCreateOrEditWorksiteOpenState(false);
    onSuccessManipulation();
  });

  const edit = useSubmitEditWorksite(worksiteEditObj?.id, () => {
    setCreateOrEditWorksiteOpenState(false);
    onSuccessManipulation();
  });

  const closeModal = useCallback(() => {
    setCreateOrEditWorksiteOpenState(false);
    onClose();
  }, [onClose]);

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: WorksiteValidations,
      onSubmit: isAddWorksiteMode ? add : edit,
      enableReinitialize: true,
    });

  const handleStateDropdownChange = useCallback(
    ({ selectedItem }: any) => {
      setFieldValue('state', selectedItem);
    },
    [setFieldValue],
  );

  const showError = useCallback(
    (field: string): boolean => {
      return (errors[field as keyof typeof errors] &&
        touched[field as keyof typeof touched]) as boolean;
    },
    [errors, touched],
  );

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );

  const toggleEverify = useCallback(
    toggled => {
      setFieldValue('eVerifyStatus', toggled);
    },
    [setFieldValue],
  );

  const showEVerifyToogle = isEverifyActive && !isAddWorksiteMode;

  return (
    <M.ComposedModal
      open={modalOpenState}
      onClose={handleCreateOrEditWorksiteClose}
    >
      <M.ModalHeader closeModal={handleCreateOrEditWorksiteClose}>
        <h2>{isAddWorksiteMode ? 'Add new worksite' : 'Edit worksite'}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <FlexContainer gap='1rem' padding='0'>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='worksiteName'
              name='worksiteName'
              labelText='Worksite nickname'
              onChange={handleChange}
              invalid={showError('worksiteName')}
              invalidText={showErrorMessage('worksiteName')}
              value={values.worksiteName}
              placeholder='Enter worksite name'
              maxLength={100}
            />
          </FlexRowJustify>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='street1'
              name='street1'
              labelText='Address line 1'
              onChange={handleChange}
              invalid={showError('street1')}
              invalidText={showErrorMessage('street1')}
              value={values.street1}
              placeholder='Enter'
              maxLength={100}
            />
          </FlexRowJustify>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='street2'
              name='street2'
              labelText='Address line 2'
              onChange={handleChange}
              invalid={showError('street2')}
              invalidText={showErrorMessage('street2')}
              value={values.street2}
              placeholder='Enter'
              maxLength={50}
            />
          </FlexRowJustify>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <M.Input
              id='city'
              name='city'
              labelText='City'
              onChange={handleChange}
              invalid={showError('city')}
              invalidText={showErrorMessage('city')}
              value={values.city}
              placeholder='Enter'
              maxLength={50}
            />
          </FlexRowJustify>
          <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
            <StateDropdown
              id='state'
              name='state'
              titleText='State'
              onChange={handleStateDropdownChange}
              invalid={showError('state')}
              invalidText={showErrorMessage('state')}
              selectedItem={values.state}
              initialSelectedItem={worksiteEditObj?.state}
              label='Select'
            />
            <M.Input
              id='zipCode'
              name='zipCode'
              labelText='ZIP code'
              onChange={handleChange}
              invalid={showError('zipCode')}
              invalidText={showErrorMessage('zipCode')}
              value={values.zipCode}
              placeholder='Enter'
              maxLength={5}
            />
          </FlexRowJustify>
          {showEVerifyToogle && (
            <FlexRowJustify gap='1.5rem' alignItems='flex-start'>
              <M.Toggle
                id='eVerifyStatus'
                onToggle={toggleEverify}
                defaultToggled={worksiteEditObj.everify_status === 'active'}
                toggledText='On'
                untoggledText='Off'
                labelText='E-verify'
              />
            </FlexRowJustify>
          )}
        </FlexContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button type='reset' kind='secondary' onClick={closeModal}>
          Cancel
        </M.Button>
        <M.Button kind='primary' size='sm' onClick={handleSubmit}>
          Save
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default CreateOrEditWorksite;
