import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Heading from './Heading';
import SummaryTable from './SummaryTable';

type Props = {
  summary: { [x: string]: any };
};
type Col = {
  name: string;
  field: string;
};
type Columns = {
  cols: Col[];
  size: number;
};

const leftCol: Col[] = [
  {
    name: 'Public Records',
    field: 'public_record_count',
  },
  {
    name: 'Collections',
    field: 'collection_count',
  },
  {
    name: 'Accounts',
    field: 'total_trade_count',
  },
  {
    name: 'Inquiries',
    field: 'total_inquiry_count',
  },
];

const rightCol: Col[] = [
  {
    name: 'Negative Accounts',
    field: 'negative_trade_count',
  },
  {
    name: 'Accounts with any Historical Negatives',
    field: 'historical_negative_trade_count',
  },
  {
    name: 'Occurrence of Historical Negatives',
    field: 'historical_negative_occurrences_count',
  },
];

const columns: Columns[] = [
  { cols: leftCol, size: 4 },
  { cols: rightCol, size: 3 },
];

const getKeyListItems = (list: Col[], map: { [x: string]: any }) =>
  list.map(({ name, field }: Col) => ({
    itemKey: name,
    itemValue: Number(map[field]),
  }));

const Summary: React.FC<Props> = ({ summary }) => {
  return (
    <div data-testid='screenings-credit-report-summary'>
      <Heading>Summary</Heading>
      <M.GridRow style={{ marginBottom: '2rem' }}>
        {columns.map(({ cols, size }, i) => (
          <M.GridCol key={`col-${i.toString()}`} lg={size}>
            <M.KeyValueList
              data-testid='screenings-credit-report-summary-key-values'
              items={getKeyListItems(cols, summary.record_counts)}
            />
          </M.GridCol>
        ))}
      </M.GridRow>
      <M.GridRow style={{ marginBottom: '2rem' }}>
        <M.GridCol>
          <SummaryTable summary={summary} />
        </M.GridCol>
      </M.GridRow>
    </div>
  );
};

export default Summary;
