import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from '../../../context';
import { ActionType, ReorderableExams, Exam } from '../../../types';

type ExamProps = {
  exams: ReorderableExams;
};

const Exams: React.FC<ExamProps> = ({ exams }) => {
  const dispatch = useDispatch();

  const onChange = useCallback(
    updatedExams => {
      const newExams = updatedExams.map((exam: Exam, i: number) => {
        return { ...exams[i], checked: exam.checked };
      });

      dispatch({ type: ActionType.SET_EXAMS, payload: newExams });
    },
    [dispatch, exams],
  );

  if (isEmpty(exams)) return null;

  return (
    <M.CheckboxGroup onChange={onChange}>
      {/* @ts-ignore */}
      {exams.map((exam: Exam) => (
        <M.Checkbox
          key={exam.slug}
          id={exam.slug}
          labelText={exam.name}
          checked={exam.checked}
        />
      ))}
    </M.CheckboxGroup>
  );
};

export default Exams;
