import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  open: boolean;
  onClose?: (event: SyntheticEvent) => void;
  onDelete: () => void;
};

export const Container: React.FC<Props> = ({ open, onClose, onDelete }) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('rules.delete_rule_dialog_title');

  if (!open) {
    return null;
  }

  return (
    <M.ComposedModal
      data-testid='assess-rule-delete-dialog'
      open={open}
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-rule-delete-title'
        title={modalTitle}
        closeModal={onClose}
      />
      <M.ModalBody data-testid='assess-rule-delete-content'>
        <Content />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-rule-delete-actions'>
        <Actions onCancel={onClose} onDelete={onDelete} />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
