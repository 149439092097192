import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { orderByKey } from '@dashboard-experience/utils';
import {
  ReportProps,
  Document as CandidateDocument,
  InternationalCriminalSearchV2 as Search,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { STATUS_ORDER, INTL_CRIM_SEARCH_DOC_TYPES } from 'Constants';
import InternationalCriminalSearchV2 from './InternationalCriminalSearchV2';
import Document from '../Document';
import { useCandidateDocuments } from '../Document/hooks';

const InternationalCriminalSearchesV2: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_criminal_searches_v2 } = report;
  const { documents } = useCandidateDocuments(report?.candidate?.id as string);
  const filteredDocuments = useMemo(
    () =>
      documents.filter(
        (document: CandidateDocument) =>
          INTL_CRIM_SEARCH_DOC_TYPES.includes(document.type) &&
          document.context_attributes?.report_id?.toString() === report.id,
      ),
    [documents, report.id],
  );

  const searches: false | Search[] = useMemo(
    () =>
      !!international_criminal_searches_v2 &&
      (orderByKey(
        international_criminal_searches_v2,
        'status',
        STATUS_ORDER,
      ) as Search[]),
    [international_criminal_searches_v2],
  );

  return searches && searches.length > 0 ? (
    <M.Screening
      title='International Criminal Searches'
      statusType={reportStatusType}
      screening={searches}
      id={SCREENING_TITLES.international_criminal_searches_v2.id}
    >
      {searches.map((search: any, i: number) => (
        <React.Fragment key={search.id}>
          <InternationalCriminalSearchV2
            report={report}
            search={search}
            statusType={reportStatusType}
          />
          {i < searches.length - 1 && <M.Divider />}
        </React.Fragment>
      ))}

      {filteredDocuments.map((document: CandidateDocument) => (
        <Document key={document.id} document={document} />
      ))}
    </M.Screening>
  ) : null;
};

export default InternationalCriminalSearchesV2;
