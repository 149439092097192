// Used in Order Flow & Add Checks to determine whether Add ons step is visible/hidden
export const isAddOnsEnabled = (
  isAddOnsFlagEnabled: boolean,
  requiresFMCSACompliance: boolean,
  hasManageAddOnsPermission: boolean,
): boolean => {
  if (!isAddOnsFlagEnabled) return false;
  return !requiresFMCSACompliance && hasManageAddOnsPermission;
};

export const isPackageNameValid = (name: string): boolean =>
  !!name.trim().length && /^[a-zA-Z0-9\s]*$/.test(name);
