import styled, { css } from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import ReCAPTCHA from 'react-google-recaptcha';

export const Container = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  .cds--text-input__invalid-icon--warning path:first-of-type {
    fill: white !important;
  }
`;

export const Section = styled.div.attrs({
  className: 'create-account-section',
})`
  display: flex;
  gap: 24px;
  padding: 0 0 32px 0;
  width: 100%;
  border-bottom: 1px solid ${colors.uiGrey200};
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }
  @media (max-width: 420px) {
    gap: 16px;
  }
`;

export const FlexColumn = styled.div.attrs({
  className: 'create-account-flex-column',
})`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 33.3%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const SectionDescription = styled.p.attrs({
  className: 'create-account-section-description p3',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    margin-top: 0;
  }
`;

type FormSectionProps = {
  gap?: string;
};

export const FormSection = styled.div.attrs({
  className: 'create-account-form-section',
})<FormSectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '16px'};
  width: 66.6%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const FormRow = styled.div.attrs({
  className: 'create-account-form-row',
})`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (max-width: 671px) {
    .mastodon-text-input {
      width: 100%;
    }
    flex-direction: column;
  }
`;

export const RadioButton = styled.div.attrs({
  className: 'create-account-radio-button',
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ErrorText = styled.p.attrs({
  className: 'create-account-error-text',
})`
  color: ${colors.uiOrange600};
  font-size: 12px !important;
`;

export const RadioButtonTitle = styled.div.attrs({
  className: 'create-account-radio-button-title',
})`
  color: ${colors.uiTextTertiaryLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const RadioButtonSubtitle = styled.div.attrs({
  className: 'create-account-radio-button-subtitle',
})`
  color: ${colors.uiTextSecondaryLight};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 100%;
`;

export const Bold = styled.span.attrs({
  className: 'create-account-bold',
})`
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
`;

export const TypeaheadStyled = styled(M.Typeahead)`
  .mastodon-combobox {
    margin-top: -9px;
  }

  .cds--list-box__selection,
  .cds--list-box__menu-icon {
    padding-top: 5px;
  }

  .cds--text-input,
  .cds--text-input:hover {
    padding-top: 10px;
  }

  .cds--form-requirement {
    color: ${colors.uiOrange600};
    padding-top: 3px;
  }

  .cds--checkbox__invalid-icon--warning path:first-of-type {
    fill: white !important;
  }

  .cds--list-box__invalid-icon--warning {
    fill: ${colors.uiOrange600} !important;
  }

  [data-icon-path='inner-path'] {
    display: none;
  }
`;

export const CheckboxRow = styled(FormRow)`
  max-width: 558px;
`;

export const CheckboxStyled = styled(M.Checkbox)`
  #mastodon & {
    .cds--checkbox-label-text {
      color: ${colors.uiTextSecondaryLight};
    }
  }

  .cds--form-requirement {
    color: ${colors.uiOrange600};
    padding-top: 3px;
  }

  .cds--checkbox__invalid-icon--warning {
    fill: ${colors.uiOrange600} !important;
  }

  .cds--checkbox__invalid-icon--warning path:first-of-type {
    fill: white !important;
  }
`;

export const HiddenReCAPTCHA = styled(ReCAPTCHA)`
  .grecaptcha-badge {
    visibility: hidden !important;
  }
`;

export const Dropdown = styled(M.Dropdown).attrs({
  className: 'create-account-dropdown',
})`
  width: 100%;

  .cds--form-requirement {
    color: ${colors.uiOrange600};
  }
`;

export const ComplianceDropdown = styled(Dropdown)`
  width: 100%;

  @media (min-width: 421px) {
    width: calc(50% - 8px);
  }
`;

export const CaptchaPolicy = styled.div.attrs({
  className: 'create-account-captcha-policy',
})`
  margin-top: -0.5rem;
  font-size: 0.75rem;
  color: ${colors.uiTextTertiaryLight};
`;

export const RadioButtonGroupContainer = styled.div.attrs({
  className: 'create-account-radio-button-group-container',
})`
  display: flex;
  gap: 16px;
`;

type RadioButtonContainerProps = {
  checked: boolean;
  disabled: boolean;
};

export const RadioButtonContainer = styled.div.attrs({
  className: 'create-account-radio-button-container',
})<RadioButtonContainerProps>`
  display: flex;
  padding: 10px 24px 12px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: ${props =>
    props.checked ? `1px solid ${colors.uiNavy200}` : `${colors.uiGrey200}`};
  background-color: ${props =>
    props.checked ? `${colors.uiNavy100}` : `${colors.uiGrey0}`};
  cursor: pointer;
  input[type='radio'] {
    accent-color: ${colors.uiNavy600};
    margin-top: 0;
    align-self: center;
    cursor: pointer;
    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }
  label {
    cursor: pointer;
    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${colors.uiGrey200};
      border-radius: 4px;
      background-color: ${colors.uiGrey100};
      cursor: not-allowed;
    `}
`;

export const PasswordHelperDropdown = styled.div.attrs({
  className: 'create-account-password-helper-dropdown',
})`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  z-index: 2;
  gap: 0.5rem;
  box-shadow: 0 2px 6px 0 #16161d4d;
  padding: 0.8rem;
  border-radius: 0.4rem;
`;

export const PasswordInputWrapper = styled.div.attrs({
  className: 'create-account-password-input-wrapper',
})`
  flex-direction: column;
  width: 100%;

  .cds--form-requirement {
    color: ${colors.uiOrange600} !important;
  }

  .cds--text-input__invalid-icon--warning {
    fill: ${colors.uiOrange600} !important;
  }
`;

export const PasswordInputForm = styled(M.SensitiveInput).attrs({
  className: 'create-account-password-input-form',
})<InputProps>`
  width: ${props => props.width || '100%'};
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-color: ${colors.uiGrey500} !important;
  transition: none !important;

  &:hover {
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    background-color: ${colors.uiGrey100} !important;
  }
  &:focus {
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-bottom: 1px solid ${colors.uiLinkPrimaryLightHover} !important;
    background-color: ${colors.uiGrey100} !important;
  }

  .cds--text-input__label-wrapper {
    height: ${props => (props.labelText === '' ? '19px' : '')};
  }
`;

export const PasswordHelperBox = styled.div.attrs({
  className: 'create-account-password-helper-box',
})`
  flex-direction: row;
  background-color: white;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
`;

export const PasswordHelperText = styled.div.attrs({
  className: 'create-account-password-helper-text',
})`
  font-weight: 400;
  color: ${colors.uiTextSecondaryLight};
  line-height: 16px;
  background-color: white;
  font-style: normal;
  word-wrap: break-word;
`;

export const StatusIconWrapper = styled.div.attrs({
  className: 'create-account-status-icon-wrapper',
})`
  width: initial;
  display: flex;
`;

export const StatusIcon = styled.img.attrs({
  className: 'create-account-status-icon',
})`
  flex-shrink: 0;
`;

type InputProps = {
  width?: string;
  labelText?: string;
};

export const Input = styled(M.Input).attrs({
  className: 'create-account-input',
})<InputProps>`
  width: ${props => props.width || '100%'};

  .cds--text-input__label-wrapper {
    height: ${props => (props.labelText === '' ? '19px' : '')};
  }

  @media (max-width: 420px) {
    .cds--text-input__label-wrapper {
      display: ${props => (props.labelText === '' ? 'none' : '')};
    }
  }

  @media (max-width: 420px) {
    width: 100%;
  }
`;

export const ErrorMsg = styled.div`
  color: ${colors.brandOrangeAccent2};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const TooltipIcon = styled(M.Icon)`
  fill: ${colors.uiTextDisabledLight} !important;
`;
