import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { InternationalCriminalSearch as Search, ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { orderByKey } from '@dashboard-experience/utils';
import { STATUS_ORDER } from 'Constants';
import InternationalCriminalSearch from './InternationalCriminalSearch';

const InternationalCriminalSearches: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_criminal_searches } = report;

  const searches: false | Search[] = useMemo(
    () =>
      !!international_criminal_searches &&
      (orderByKey(
        international_criminal_searches,
        'status',
        STATUS_ORDER,
      ) as Search[]),
    [international_criminal_searches],
  );

  return searches && searches.length > 0 ? (
    <M.Screening
      title='International Searches'
      statusType={reportStatusType}
      screening={searches}
      id={SCREENING_TITLES.international_criminal_searches.id}
    >
      {searches.map((search: any, i: number) => (
        <React.Fragment key={search.id}>
          <InternationalCriminalSearch
            report={report}
            search={search}
            statusType={reportStatusType}
          />
          {i < searches.length - 1 && <M.Divider />}
        </React.Fragment>
      ))}
    </M.Screening>
  ) : null;
};

export default InternationalCriminalSearches;
