import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Field } from 'modules/assess/models/rulesets/version-diff/common';
import { LookbackRuleChange } from 'modules/assess/models/rulesets/version-diff';
import Change from './Change';
import TableHeader from './TableHeader';
import TableSubheader from './TableSubheader';

type Props = {
  changes?: LookbackRuleChange.List;
};

const { TableContainer, Table, TableHead, TableRow, TableBody } = M.DataTable;

export const T: React.FC<Props> = ({ changes }) => {
  const rows = changes?.map(c => {
    const key =
      (c.charge_subsubcategory as Field.FieldUnchanged)?.value ||
      (c.charge_subsubcategory as Field.FieldChanged)?.draft;
    return <Change key={key} change={c} />;
  });

  return (
    <TableContainer data-testid='assess-ui-ruleset-version-publish-changes-lookback-rules-changes-table'>
      <Table>
        <TableHead>
          <TableHeader />
        </TableHead>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableSubheader />
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default T;
