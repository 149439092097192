import React, { useCallback } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { hasPermission } from '@dashboard-experience/utils';
import { BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY } from 'Flags';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_BULKORDER_NAMES,
} from 'utils/analytics';
import HowToAddContactInfoSection from './Sections/HowToAddContactInfoSection';
import ProvideContactInfoManuallySection from './Sections/ProvideContactInfoManuallySection';
import ProvideContactInfoViaUploadSection from './Sections/ProvideContactInfoViaUploadSection';
import { MAX_BULK_INVITATIONS } from '../../../../Constants';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  MANUAL,
  UPLOAD,
} from '../Context';

import InviteEntryContainer from './CandidateInviteForm/InviteEntryContainer';

const BulkInviteStyled = styled.div`
  .section-title {
    color: ${colors.brandNavy4};
    font-size: 1.125rem !important;
    margin: 2.5rem 0 1rem !important;
  }

  p {
    color: ${colors.brandNavy4};
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
`;

type Props = {
  redirectPage: Function;
};

const BulkInvite: React.FC<Props> = ({ redirectPage }) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const bulkInviteFlagr: number = Number(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const bulkInviteFlagrEnabled: boolean = !isNaN(bulkInviteFlagr);
  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const handleInviteMethodChange = useCallback(
    (method: string) => {
      dispatch({
        type: actionTypes.ADD_INVITE_METHOD,
        payload: { inviteMethod: method },
      });
    },
    [dispatch],
  );

  const handleManual = useCallback(() => {
    trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.MANUAL_INVITE_CLICKED);
    handleInviteMethodChange(MANUAL);
  }, [handleInviteMethodChange, trackEvent]);

  const handleUpload = useCallback(() => {
    trackEvent(ORDER_BACKGROUND_CHECK_BULKORDER_NAMES.UPLOAD_INVITE_CLICKED);
    handleInviteMethodChange(UPLOAD);
  }, [handleInviteMethodChange, trackEvent]);

  const smsInvite = <InviteEntryContainer maxEmails={MAX_BULK_INVITATIONS} />;

  const canManageBulkInvitations =
    bulkInviteFlagrEnabled &&
    hasPermission(currentUser, 'manage_bulk_invitations');

  return (
    <BulkInviteStyled>
      {canManageBulkInvitations ? (
        <>
          <HowToAddContactInfoSection
            maxEmails={MAX_BULK_INVITATIONS}
            bulkUploadMax={bulkInviteFlagr.toLocaleString()}
            handleManual={handleManual}
            handleUpload={handleUpload}
            inviteMethod={state.inviteMethod}
          />
          {state.inviteMethod === MANUAL && (
            <ProvideContactInfoManuallySection
              smsInvite={smsInvite}
              allowBulkInvite={canManageBulkInvitations}
            />
          )}
          {state.inviteMethod === UPLOAD && (
            <ProvideContactInfoViaUploadSection
              bulkInviteFlagrEnabled={bulkInviteFlagrEnabled}
              redirectPage={redirectPage}
              bulkUploadMax={bulkInviteFlagr.toLocaleString()}
              fileDisplayName={state.csv.fileDisplayName}
            />
          )}
        </>
      ) : (
        <ProvideContactInfoManuallySection
          smsInvite={smsInvite}
          allowBulkInvite={canManageBulkInvitations}
        />
      )}
    </BulkInviteStyled>
  );
};

export default BulkInvite;
