import React, { SyntheticEvent, useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { ID } from 'modules/id';
import { useTranslation } from 'react-i18next';
import { Actions } from './common';
import ChangesContent from './changes';
import InsightsContent from './insights';
import { Actions as SignatureActions } from './signature';
import SignatureContent from './signature/Content';
import { Step } from './Step';
import { useDraftDiff } from '../api';

type Props = {
  disabled: boolean;
  open: boolean;
  onClose?: (event: SyntheticEvent) => void;
  publish?: () => void;
  version?: ID;
};

export const Container: React.FC<Props> = ({
  disabled,
  publish,
  open,
  onClose,
  version,
}) => {
  const account = useUserAccount();
  const ruleset = useRuleset()?.data;
  const changes = useDraftDiff(ruleset?.id)?.data;
  const steps = getSteps(account?.assess_insights);
  const initialStep = steps[0];
  const { t } = useTranslation();
  const modalTitle = t('assess:ruleset.version.publish.title');

  const [confirmed, setConfirmed] = useState(false);
  const [currentStep, setCurrentStep] = useState<Step>(initialStep);

  const resetDialog = useCallback(
    (e: SyntheticEvent) => {
      setCurrentStep(initialStep);
      onClose?.(e);
    },
    [initialStep, setCurrentStep, onClose],
  );

  const handleNextStep = useCallback(
    (currentStep: Step) => {
      const step = steps.findIndex(s => s === currentStep);
      setCurrentStep(steps[step + 1]);
    },
    [setCurrentStep, steps],
  );

  if (!open) {
    return null;
  }

  const changesActions = (
    <Actions
      currentStep={Step.RULESET_DIFF}
      onCancel={resetDialog}
      onContinue={handleNextStep}
    />
  );
  const changesContent = (
    <ChangesContent changes={changes} ruleset={ruleset?.name} />
  );

  const signatureActions = (
    <SignatureActions
      onCancel={resetDialog}
      confirmed={confirmed && !disabled}
      publish={publish}
    />
  );
  const signatureContent = <SignatureContent confirm={setConfirmed} />;

  const insightsActions = (
    <Actions
      currentStep={Step.INSIGHTS}
      onCancel={resetDialog}
      onContinue={handleNextStep}
    />
  );
  const insightsContent = <InsightsContent version={version} />;

  const body = getStepContent(
    currentStep,
    changesContent,
    insightsContent,
    signatureContent,
  );
  const footer = getStepContent(
    currentStep,
    changesActions,
    insightsActions,
    signatureActions,
  );

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-version-publish-dialog'
      open={open}
      size='lg'
      onClose={resetDialog}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-version-publish-title'
        title={modalTitle}
        closeModal={resetDialog}
      />
      <M.ModalBody data-testid='assess-ruleset-version-publish-content'>
        {body}
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-version-publish-actions'>
        {footer}
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

function getStepContent(step: Step, ...params: any[]) {
  return params[step];
}

function getSteps(assessInsights: boolean): Step[] {
  const steps = [];
  steps.push(Step.RULESET_DIFF);

  if (assessInsights) {
    steps.push(Step.INSIGHTS);
  }

  steps.push(Step.SIGNATURE);
  return steps;
}

export default Container;
