import React from 'react';
import MediumSelection from '../config-options/medium-selection';
import EmailTemplates from './email-template';
import AutoSendPostAA from './auto-send-post-adverse';
import CandidateDetails from './candidate-details';
import { useFetchAdverseActionTemplates } from '../../../../../../../api';

const Options: React.FC<{}> = () => {
  const { reportDetails, candidateLocation } = useFetchAdverseActionTemplates();

  return (
    <>
      <CandidateDetails
        reportDetails={reportDetails}
        candidateLocation={candidateLocation}
      />
      <MediumSelection />
      <EmailTemplates />
      <AutoSendPostAA />
    </>
  );
};

export default Options;
