import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import Cell from './Cell';

type PropsType = {
  completedAt: string | null;
  url: string;
};

const Completed = ({ completedAt, url }: PropsType) => {
  if (!completedAt) return null;
  const timezone = moment.tz.guess();

  return (
    <Cell url={url} testid='cm-ui-tasks-page-table-rows-completed-at'>
      {moment.tz(completedAt, timezone).format('lll z')}
    </Cell>
  );
};

export default Completed;
