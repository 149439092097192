import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { toastError } from 'actions';
import ActionButtons from './ActionButtons';

const NoteTextArea = styled(M.TextArea)`
  border-style: solid;
  border-width: thin;
  border-color: ${colors.uiGrey200};
`;

type Props = {
  onClose: () => void;
  onSave: (content: string) => void;
  content?: string | null;
};

const noop = () => {};

const NoteEditor: React.FC<Props> = ({
  content = '',
  onSave = noop,
  onClose = noop,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('case-management');
  const [note, setNote] = useState(content);

  const handleNoteChange = useCallback(e => {
    setNote(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    if (note === '') {
      dispatch(toastError('Cannot save an empty note'));
    } else {
      onSave(note || '');
    }
  }, [dispatch, note, onSave]);

  return (
    <div style={{ maxWidth: '720px' }}>
      <NoteTextArea
        data-testid='case-management-note-text-area'
        label=''
        light
        maxLength={999}
        onChange={handleNoteChange}
        placeholder={t('internal-notes.text-area.placeholder')}
        rows={3}
        value={note}
      />
      <ActionButtons onCancel={onClose} onSave={handleSave} />
    </div>
  );
};

export default NoteEditor;
