import React from 'react';
import { useDisplayCancelAdverseAction } from 'modules/adjudication/utilities';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const displayCancelAdverseAction = useDisplayCancelAdverseAction();

  return displayCancelAdverseAction ? <>{children}</> : null;
};

export default Guard;
