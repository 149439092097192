import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../../common';
import Sections from '../../sections';
import WithDataContent from './with-data';
import WithoutDataContent from './without-data';

type Props = {
  emptyAdverseActionData?: boolean;
};

const Container: React.FC<Props> = ({ emptyAdverseActionData }) => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.adverse_action_rate}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { with_data, without_data, title } = translations;
  const Content = emptyAdverseActionData ? WithoutDataContent : WithDataContent;
  const content = emptyAdverseActionData ? without_data : with_data;
  return (
    <Section divider data-testid='fair-chance-sections-adverse-action-rate'>
      <h3>{title}</h3>
      <Content content={content} />
    </Section>
  );
};

export default Container;
