import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Upload from './Upload';

const CSV_TEMPLATE =
  'https://us-east-1-checkr.s3.amazonaws.com/assets/csv_uploads/candidate-info-csv-template.csv';
const CSV_EXAMPLE =
  'https://us-east-1-checkr.s3.amazonaws.com/assets/csv_uploads/example-manual-candidate-info.csv';

const ContainerStyled = styled.div`
  width: 42.5rem;
`;

const StepTitleStyled = styled.h4`
  margin: 2rem 0 1rem !important;
`;

const StepContentStyled = styled.div`
  margin-bottom: 1rem;
`;

const SecondaryBtnStyled = styled(M.Button)`
  margin-right: 0 !important;

  .mastodon-icon {
    margin-right: 0.5rem;
  }
`;

const UploadCandidateInfo: React.FC = () => {
  const handleDownloadTemplate = useCallback(() => {
    window.location.href = CSV_TEMPLATE;
  }, []);

  const handleDownloadExample = useCallback(() => {
    window.location.href = CSV_EXAMPLE;
  }, []);

  return (
    <ContainerStyled>
      <h3 data-testid='upload-info-heading'>Upload candidate information</h3>
      <StepTitleStyled data-testid='upload-info-step-1-title'>
        1. Download our CSV template
      </StepTitleStyled>
      <StepContentStyled>
        <SecondaryBtnStyled
          kind='secondary'
          onClick={handleDownloadTemplate}
          data-testid='upload-info-download-template'
        >
          <M.Icon icon='Download' />
          Download template
        </SecondaryBtnStyled>
        <M.Button
          kind='tertiary'
          onClick={handleDownloadExample}
          data-testid='upload-info-download-example'
        >
          Download example
        </M.Button>
      </StepContentStyled>
      <StepTitleStyled data-testid='upload-info-step-2-title'>
        2. Upload a completed template or existing CSV
      </StepTitleStyled>
      <StepContentStyled data-testid='upload-info-upload-section'>
        <Upload />
      </StepContentStyled>
      <p>
        By continuing, you confirm you have reviewed your upload and that all
        information is correct.
      </p>
    </ContainerStyled>
  );
};

export default UploadCandidateInfo;
