import React from 'react';
import { Banner } from 'components/Banner';
import { useUser } from 'context/CurrentUser';
import { Flag, useFlag } from '@dashboard-experience/react-flagr';
import { Trans, useTranslation } from 'react-i18next';
import {
  ACCOUNT_DEAUTHORIZATION_KEY,
  CHECKR_CUSTOMER_SUPPORT_URL,
  SELF_SERVICE_REAUTHORIZATION_KEY,
} from 'Constants';
import { postMessageToDashboard } from 'utils';
import { Link, useLocation } from 'react-router-dom';

const BILLING_REDIRECT_ACTIONS = ['insufficient_funds', 'nonpayment'];

const DeauthorizedBanner: React.FC<{}> = () => {
  const currentUser = useUser();
  const { t } = useTranslation();
  const location = useLocation();
  const homePage = '/home';
  const isHomePage = location.pathname === homePage;

  const { account } = currentUser;

  const { account_deauthorization } = account;

  const reason = account_deauthorization?.reason;

  const billingUrl = location.pathname.includes('billing')
    ? 'payment'
    : '/billing/payment';

  const billing_link_component = (
    <Link data-testid='billing-redirect' to={billingUrl}>
      Payment and billing
    </Link>
  );

  const help_center_link_component = (
    <a
      href={CHECKR_CUSTOMER_SUPPORT_URL}
      data-testid='help_center_link_component_test'
      target='_blank'
      rel='noreferrer'
    >
      Checkr Customer Support
    </a>
  );

  const enableReauthorize =
    useFlag(SELF_SERVICE_REAUTHORIZATION_KEY)?.variantKey === 'on';

  const billingRedirect =
    account_deauthorization &&
    (reason === 'insufficient_funds' ||
      (reason === 'nonpayment' && enableReauthorize)) &&
    BILLING_REDIRECT_ACTIONS.includes(reason);

  const link_component = billingRedirect
    ? billing_link_component
    : help_center_link_component;

  const translation_key = () => {
    if (account_deauthorization) {
      return billingRedirect
        ? `banners.deauthorized.${reason}`
        : `banners.deauthorized.default_reason`;
    }
    return 'banners.deauthorized.without_reason';
  };

  const subtitle = (
    <Trans t={t} i18nKey={translation_key()} values={{ reason }}>
      {link_component}
    </Trans>
  );
  const legacyIsDeauthorized =
    !account?.authorized &&
    !account?.api_authorized &&
    account?.credentialed_at;

  const isDeauthorized =
    (account_deauthorization && !account?.authorized) || legacyIsDeauthorized;

  if (isDeauthorized) {
    postMessageToDashboard({
      messageId: 'showDeauthorizedBanner',
    });
  }

  return isDeauthorized ? (
    <Flag flagKey={ACCOUNT_DEAUTHORIZATION_KEY} variantKey='on'>
      <Banner
        kind='error'
        title='Account Deauthorized'
        subtitle={subtitle}
        data-testid='account_deauthorized_banner'
        isHomePage={isHomePage}
      />
    </Flag>
  ) : null;
};

export default DeauthorizedBanner;
