import { ButtonAsLink } from 'components/AdverseActions/AdverseActionsStyledComponents';
import React, { useCallback } from 'react';
import { Entity } from 'modules/assess/ui/state/rules';

type Props = {
  handleAddClick: Function;
  rule: Entity.Type;
};
export const AddButton: React.FC<Props> = ({ handleAddClick, rule }) => {
  const handleClick = useCallback(() => {
    handleAddClick(rule);
  }, [handleAddClick, rule]);

  return <ButtonAsLink onClick={handleClick}>Add Rule</ButtonAsLink>;
};
