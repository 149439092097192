import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { ChangeType } from 'modules/assess/models/rulesets/version-diff/common';
import ToogleChangeState from './ToogledChangeState';

type Props = {
  type: ChangeType;
};

const Icon = styled(M.Icon)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: -0.1rem;
`;

export const ToogledChange: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const on = t(`assess:ruleset.version.publish.changes.premade.change.on`);
  const off = t(`assess:ruleset.version.publish.changes.premade.change.off`);

  const from = (
    <ToogleChangeState
      state={type === ChangeType.deleted}
      text={type === ChangeType.deleted ? on : off}
    />
  );

  const to = (
    <ToogleChangeState
      state={type === ChangeType.created}
      text={type === ChangeType.created ? on : off}
    />
  );

  return (
    <>
      {from}
      <Icon icon='ArrowRight' size='16' />
      {to}
    </>
  );
};

export default ToogledChange;
