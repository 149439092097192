/* eslint-disable react/destructuring-assignment */
import { FormData, ActionType, Action, ExamType } from '../types';
import { getSelectedExamsByType } from '../modal/views/screening-selection/utils';

const Reducer = (state: FormData, action: Action): FormData => {
  switch (action.type) {
    case ActionType.SET_EXAMS: {
      const selectedDrugExams = getSelectedExamsByType(
        ExamType.Drug,
        action.payload,
      );

      return {
        ...state,
        reorderableExams: action.payload,
        drugObservedCollection: !selectedDrugExams.length
          ? undefined
          : state.drugObservedCollection,
      };
    }
    case ActionType.SET_DRUG_OBSERVED_COLLECTION: {
      return {
        ...state,
        drugObservedCollection: action.payload,
      };
    }
    case ActionType.SET_APPOINTMENT_DEADLINE: {
      return {
        ...state,
        appointmentDeadline: action.payload,
      };
    }
    case ActionType.SET_ALLOW_NEW_APPOINTMENT: {
      return {
        ...state,
        allowNewAppointment: action.payload,
        appointmentDeadline:
          action.payload === 'No' ? undefined : state.appointmentDeadline,
      };
    }
    case ActionType.SET_SELECT_NEW_CLINIC: {
      return {
        ...state,
        selectNewClinic: action.payload,
        clinic: action.payload === 'No' ? undefined : state.clinic,
        zipcode: action.payload === 'No' ? '' : state.zipcode,
      };
    }
    case ActionType.SET_CLINIC_ZIP_CODE: {
      const clinic = !action.payload ? undefined : state.clinic;
      return {
        ...state,
        zipcode: action.payload,
        clinic,
      };
    }
    case ActionType.SET_CLINIC: {
      return {
        ...state,
        clinic: action.payload,
      };
    }
    case ActionType.SET_ADD_EXAM_ERROR: {
      return {
        ...state,
        addExamError: action.payload,
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export default Reducer;
