import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';

type Props = {
  cell: any;
};

const IneligiblePersonsTableCell: React.FC<Props> = ({ cell }) => {
  const { value } = cell;

  let cellBody;
  switch (cell.info.header) {
    case 'created_at':
      cellBody = moment(value).format('l');
      break;
    default:
      cellBody = value;
  }

  return <M.TableCell>{cellBody}</M.TableCell>;
};

export default IneligiblePersonsTableCell;
