import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'api/payment';
import {
  GridCol,
  StyledBadgeDiv,
  StyledSubTitle,
} from './PaymentStyledComponents';

type Props = {
  bankInfo: PaymentMethod;
};

const BankDetails: React.FC<Props> = ({ bankInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridCol lg={1}>
        <svg
          width='40'
          height='24'
          viewBox='0 0 40 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='39'
            height='23'
            rx='1.5'
            fill='#F6F6F7'
          />
          <g clipPath='url(#clip0_732_64829)'>
            <path
              d='M16.334 10.667H15.0007V15.3337H16.334V10.667ZM20.334 10.667H19.0007V15.3337H20.334V10.667ZM26.0007 16.667H13.334V18.0003H26.0007V16.667ZM24.334 10.667H23.0007V15.3337H24.334V10.667ZM19.6673 6.17366L23.1407 8.00033H16.194L19.6673 6.17366ZM19.6673 4.66699L13.334 8.00033V9.33366H26.0007V8.00033L19.6673 4.66699Z'
              fill='#334559'
            />
          </g>
          <rect
            x='0.5'
            y='0.5'
            width='39'
            height='23'
            rx='1.5'
            stroke='#CBD2DB'
          />
          <defs>
            <clipPath id='clip0_732_64829'>
              <rect
                width='16'
                height='16'
                fill='white'
                transform='translate(12 4)'
              />
            </clipPath>
          </defs>
        </svg>
      </GridCol>
      <GridCol md={2}>
        <StyledSubTitle>
          <div
            className='fz-16'
            data-testid={`ach-bank-name-${bankInfo.stripe_id}`}
          >
            {bankInfo.bank_name}
          </div>
          <p className='note-inner'>
            {t(`payment.bank_information.account_label`)}: •••••
            <span data-testid='ach-last-4'>{bankInfo.last4}</span>
          </p>
        </StyledSubTitle>
      </GridCol>
      <GridCol>
        {bankInfo.is_default_payment && (
          <StyledBadgeDiv data-testid='ach-default'>
            {t(`payment.badge.default`)}
          </StyledBadgeDiv>
        )}
      </GridCol>
    </>
  );
};

export default BankDetails;
