import React from 'react';
import { useTranslation } from 'react-i18next';
import Base, { Action, Props as BaseProps } from '../base-dialog';
import Content from './content';
import Footer from './footer';

export interface Props extends Omit<BaseProps, 'onChange'> {
  action: Action;
  name?: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
  submitDisabled: boolean;
  type: string;
}

export const NameDialog: React.FC<Props> = ({
  action,
  name,
  open,
  onClose,
  onChange,
  onSubmit,
  submitDisabled,
  type,
}) => {
  const { t } = useTranslation('');
  let value = name;

  if (action === Action.duplicate) {
    value += ` ${t('nouns.copy')}`;
  }

  return (
    <Base
      action={action}
      content={<Content onChange={onChange} type={type} value={value} />}
      footer={
        <Footer
          action={action}
          submitDisabled={submitDisabled}
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      }
      onClose={onClose}
      open={open}
      name={name}
      type={type}
    />
  );
};

export default NameDialog;
