import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import MunicipalStateSearches from './MunicipalStateSearches';

const MunicipalSearches: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { municipal_criminal_searches: screening } = report;
  if (!screening) return null;

  return screening?.length > 0 ? (
    <MunicipalStateSearches
      report={report}
      title='Municipal Criminal Searches'
      reportStatusType={reportStatusType}
      screening={screening}
      id={SCREENING_TITLES.municipal_criminal_searches.id}
    />
  ) : null;
};

export default MunicipalSearches;
