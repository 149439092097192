import React from 'react';
import styled from 'styled-components';
import { Lite as Option } from 'modules/assess/v2/models/navigation/lite';
import { Footer, Header } from '../common';
import Table from './table';
import { useStartPage } from '../../hooks';

const StyledContainer = styled.div`
  box-shadow: 2px 2px 4px 2px rgba(22, 22, 29, 0.08);
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  margin-top: -1px;
  padding: 0 1.5rem 0.5rem 1.5rem;
`;

export const Container: React.FC = () => {
  return (
    <StyledContainer data-testid='assess-v2-ui-lite-nature-of-offense-container'>
      <Header option={Option.NatureOfOffense} showSettings={useStartPage()} />
      <ContentContainer>
        <Table />
      </ContentContainer>
      {useStartPage() ? null : <Footer option={Option.NatureOfOffense} />}
    </StyledContainer>
  );
};

export default Container;
