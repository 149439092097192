import React from 'react';
import styled from 'styled-components';

const GroupButtonsStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
`;

const GroupButtonsContainer: React.FC = ({ children }) => {
  return <GroupButtonsStyle>{children}</GroupButtonsStyle>;
};

export default GroupButtonsContainer;
