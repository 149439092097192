import React, { useCallback, useEffect, useRef, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import debounce from 'lodash/debounce';
import styled from 'styled-components';

import DomesticAddScreeningsSummary from 'components/AddScreenings/Domestic/Summary';
import InternationalAddScreeningsSummary from 'components/AddScreenings/International/Summary';
import * as utils from 'components/AddScreenings/shared/utils';
import OriginType from 'components/Packages/enums/OriginType';
import { useIncidents } from 'components/ApiStatusBanner';
import {
  useAllAddOnPricesFromSkus,
  usePackagePrice,
} from 'components/AddScreenings/hooks/usePackagePrice';
import PopularAddOns from 'components/AddScreenings/Domestic/PopularAddOns';
import { ActionType } from 'components/Packages/CreatePackage/Context';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import { Account } from 'types';
import AddScreeningsList, { getDisabledScreenings } from './AddScreeningList';

const InnerContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  border: 0 !important;
  margin-bottom: 0px;

  @media (min-width: 672px) {
    flex-direction: row;
  }
`;

const ScreeningsSection = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  margin-bottom: 24px !important;

  @media (min-width: 672px) {
    flex: 1 1 65%;
    border-bottom: 0;
  }

  @media (max-width: 672px) {
    min-height: 400px !important;
  }
`;

const SummaryContainer = styled.div<SummaryContainerProps>`
  min-width: 375px !important;
  padding-bottom: 60px !important;
  position: relative;
  top: ${({ top }) => top}px;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (max-width: 672px) {
    padding-top: 50px !important;
  }
`;

const SummarySection = styled.div<SummarySectionProps>`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 24px;
  padding-bottom: 32px !important;
  background-color: ${colors.uiGrey0};
  margin-bottom: 50px !important;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (min-width: 672px) {
    min-width: 375px;
    max-width: 375px;
    flex: 1 1 35%;
    margin-left: 16px;
    overflow-y: auto;
  }
`;

const SaveForNextTimeContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 16px;

  border: 1px solid ${colors.uiGrey200};
  border-radius: 10px;
  padding: 0 20px 10px 20px;
  background-color: ${colors.uiNavy50};

  @media (min-width: 672px) {
    flex: 1 1 35%;
  }
`;

const SavePackageNameContainer = styled.div`
  margin-top: 16px;
`;

const NameInput = styled(M.TextInput)`
  .cds--text-input {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      background-color: ${colors.uiGrey0};
      transition: background-color 9999s ease-in-out 0s;
    }
  }
  .cds--label {
    margin-bottom: 0.5rem !important;
  }
`;

const Margin16 = styled.div`
  margin-top: 16px;
`;

const ADDITIONAL_PACKAGE_SUMMARY_PADDING = 24;
const INCIDENTS_BANNER_HEIGHT = 30;

type SummaryContainerProps = {
  top: number;
  maxHeight: number;
};

type SummarySectionProps = {
  maxHeight: number;
};

type Props = {
  basePackage: any;
  addedScreeningTypes: Array<any>;
  additionalProperties: object;
  newPackageName: string;
  selectedGeo: string;
  dispatch: Function;
  newPackageNameInvalid: boolean;
  account: Account;
  error: string;
  handlePackageNameChange: Function | null | undefined;
  windowOffsetY?: number;
  windowInnerHeight?: number;
  goBack?: Function;
  aliasesEnabled?: AliasesEnabledType;
  getPrices?: Function;
};

const AddAddons: React.FC<Props> = ({
  basePackage,
  addedScreeningTypes,
  additionalProperties,
  newPackageName,
  selectedGeo,
  dispatch,
  newPackageNameInvalid,
  account,
  error,
  handlePackageNameChange,
  windowOffsetY = 0,
  windowInnerHeight = 0,
  goBack,
  aliasesEnabled = AliasesEnabledType.OFF,
  getPrices,
}) => {
  const [packageNameDirty, setPackageNameDirty] = useState(false);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const packageSummaryRef = useRef<HTMLDivElement>(null);
  const [packageSummaryOffsetTop, setPackageSummaryOffsetTop] = useState(0);
  const [packageSummaryTop, setPackageSummaryTop] = useState(0);
  const { incidents } = useIncidents();

  const basePackageScreeningTypes = basePackage.screenings.map(
    (screening: { type: any }) => screening.type,
  );

  // query for the price of all possible add ons
  // this data populates each individual screening price in the list section
  const allAddOnPricesQuery = useAllAddOnPricesFromSkus(account.id as number);
  const mappedSkusQuery = utils.mapAddonSkus(allAddOnPricesQuery);

  const screeningAdded = debounce(
    useCallback(
      (screeningType, additionalProperties) =>
        dispatch({
          type: ActionType.SCREENING_ADDED,
          payload: { screeningType, additionalProperties },
        }),
      [],
    ),
    200,
    { leading: false },
  );

  const screeningRemoved = useCallback(
    screeningType =>
      dispatch({
        type: ActionType.SCREENING_REMOVED,
        payload: { screeningType },
      }),
    [dispatch],
  );

  // query for the price of the initial package with no-add ons
  // this data populates the base price in the summary
  const basePackagePricesQuery = usePackagePrice(
    ['base-package-prices', basePackage.name],
    account,
    basePackage,
  );

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...basePackage,
        aliases_enabled: AliasesEnabledType.OFF,
      },
      addedScreeningTypes,
      additionalProperties,
      packageName: basePackage.name,
      setSlug: true,
      isPrivate: false,
    });
  }, [basePackage, addedScreeningTypes, additionalProperties]);

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
    ],
    account,
    {
      ...buildPostBodyWithAddOns(),
    },
  );

  const buildPostBodyWithAddOnsAlias = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage: {
        ...basePackage,
        aliases_enabled: AliasesEnabledType.ON,
      },
      addedScreeningTypes,
      additionalProperties,
      packageName: basePackage.name,
      setSlug: true,
      isPrivate: false,
    });
  }, [basePackage, addedScreeningTypes, additionalProperties]);

  const pendingAddOnPricesQueryAlias = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    account,
    {
      ...buildPostBodyWithAddOnsAlias(),
    },
  );

  const handleAliasClick = useCallback(() => {
    const isNotOn =
      (basePackage.aliases_enabled === AliasesEnabledType.ACCOUNT ||
        basePackage.aliases_enabled === AliasesEnabledType.OFF) &&
      aliasesEnabled !== AliasesEnabledType.ON;

    if (aliasesEnabled === AliasesEnabledType.ON) {
      dispatch({
        type: ActionType.SET_ALIASES_PRICE,
        payload: {
          aliasPrice: 0,
        },
      });
    }
    if (isNotOn) {
      dispatch({
        type: ActionType.SET_ALIASES_ENABLED,
        payload: { aliasesEnabled: AliasesEnabledType.ON },
      });
    } else {
      dispatch({
        type: ActionType.SET_ALIASES_ENABLED,
        payload: {
          aliasesEnabled: basePackage.aliases_enabled,
        },
      });
    }
  }, [basePackage.aliases_enabled, dispatch, aliasesEnabled]);

  const setAliasPrice = useCallback(
    (aliasPrice: number) => {
      if (aliasPrice) {
        dispatch({
          type: ActionType.SET_ALIASES_PRICE,
          payload: {
            aliasPrice,
          },
        });
      }
    },
    [dispatch],
  );

  const defaultHandlePackageNameChange = useCallback(
    (e: any) => {
      handlePackageNameChange && handlePackageNameChange(e.target.value);
      dispatch({
        type: ActionType.SET_PACKAGE_NAME,
        payload: { name: e.target.value },
      });
      setPackageNameDirty(true);
    },
    [dispatch],
  );

  useEffect(() => {
    handlePackageNameChange &&
      dispatch({
        type: ActionType.SET_PACKAGE_NAME,
        payload: { name: basePackage.name },
      });
  }, []);

  useEffect(() => {
    const hasIncidents = incidents && incidents.length > 0;
    const incidentBannerHeight = hasIncidents ? INCIDENTS_BANNER_HEIGHT : 0;

    if (windowOffsetY <= packageSummaryOffsetTop) {
      setPackageSummaryTop(0);
      return;
    }

    setPackageSummaryTop(
      windowOffsetY +
        incidentBannerHeight +
        ADDITIONAL_PACKAGE_SUMMARY_PADDING -
        packageSummaryOffsetTop,
    );
  }, [windowOffsetY, incidents]);

  useEffect(() => {
    if (packageSummaryRef.current) {
      setPackageSummaryOffsetTop(packageSummaryRef.current.offsetTop);
    }
  }, [packageSummaryRef.current]);

  return (
    <InnerContainer>
      <ScreeningsSection>
        {selectedGeo === OriginType.domestic && (
          <>
            <PopularAddOns
              onAddClick={screeningAdded}
              onRemoveClick={screeningRemoved}
              addedScreenings={addedScreeningTypes}
              disabledScreenings={getDisabledScreenings(
                basePackageScreeningTypes,
              )}
              additionalProperties={additionalProperties}
              allAddOnPricesQuery={mappedSkusQuery}
              basePackageScreenings={basePackage.screenings}
            />
            <Margin16 />
          </>
        )}
        <AddScreeningsList
          onAddClick={screeningAdded}
          onRemoveClick={screeningRemoved}
          addedScreenings={addedScreeningTypes}
          additionalProperties={additionalProperties}
          basePackage={basePackage as any}
          account={account}
          basePackageScreeningTypes={basePackageScreeningTypes}
          selectedGeo={selectedGeo}
          allAddOnPricesQuery={mappedSkusQuery}
          screeningSettingPrice={pendingAddOnPricesQuery?.data?.items || []}
        />
      </ScreeningsSection>
      <SummaryContainer
        ref={packageSummaryRef}
        top={packageSummaryTop}
        maxHeight={windowInnerHeight - ADDITIONAL_PACKAGE_SUMMARY_PADDING}
      >
        <SummarySection
          data-testid='package-summary'
          maxHeight={windowInnerHeight - 2 * ADDITIONAL_PACKAGE_SUMMARY_PADDING}
        >
          {selectedGeo === 'domestic' ? (
            <DomesticAddScreeningsSummary
              basePackage={basePackage}
              basePackagePrices={basePackagePricesQuery.data}
              basePackageScreeningTypes={basePackageScreeningTypes}
              pendingAddOnPricesQuery={pendingAddOnPricesQuery}
              pendingAddOnPricesQueryAlias={pendingAddOnPricesQueryAlias}
              hasAddedScreenings={Boolean(addedScreeningTypes.length)}
              additionalProperties={additionalProperties}
              goBack={goBack}
              screeningAdded={screeningAdded}
              handleAliasClick={handleAliasClick}
              aliasEnabled={aliasesEnabled}
              setAliasPrice={setAliasPrice}
              getPrices={getPrices}
            />
          ) : (
            <InternationalAddScreeningsSummary
              basePackage={basePackage}
              basePackageScreeningTypes={basePackageScreeningTypes}
              pendingAddOnPricesQuery={pendingAddOnPricesQuery}
              hasAddedScreenings={Boolean(addedScreeningTypes.length)}
              additionalProperties={additionalProperties}
              goBack={goBack}
            />
          )}
          <div>
            <SaveForNextTimeContainer data-testid='save-for-next-time-container'>
              <SavePackageNameContainer>
                <NameInput
                  ref={nameFieldRef}
                  data-testid='name-your-package-textinput'
                  id='name-your-package-textinput'
                  labelText='Name your package'
                  value={newPackageName}
                  invalid={error || (packageNameDirty && newPackageNameInvalid)}
                  onChange={defaultHandlePackageNameChange}
                  invalidText={
                    error ||
                    'Package name cannot be blank or include special characters'
                  }
                />
              </SavePackageNameContainer>
            </SaveForNextTimeContainer>
          </div>
        </SummarySection>
      </SummaryContainer>
    </InnerContainer>
  );
};

export default AddAddons;
