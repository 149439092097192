import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from 'types';

type GenericPanelProps = {
  licenseReport: GenericObject;
  title: string;
  reportKey: string;
};

const GenericPanel: React.FC<GenericPanelProps> = ({
  licenseReport,
  title,
  reportKey,
}) => {
  return (
    <M.SubScreening title={title}>
      <M.Screening.Row>
        {licenseReport[reportKey].map((item: GenericObject, idx: number) => {
          const itemValue =
            reportKey === 'endorsements' ? item.dmv_description : item;

          const items = [{ itemKey: itemValue }];
          return <M.KeyValueList key={`${item}-${idx + 1}`} items={items} />;
        })}
      </M.Screening.Row>
    </M.SubScreening>
  );
};

export default GenericPanel;
