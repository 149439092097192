/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import createActionCreator from './ActionHelper';
import { AmsAPI } from '../utils/APIClient';

export const FETCH_IDP_CONNECTIONS_REQUEST = 'FETCH_IDP_CONNECTIONS_REQUEST';
export const FETCH_IDP_CONNECTIONS_SUCCESS = 'FETCH_IDP_CONNECTIONS_SUCCESS';
export const FETCH_IDP_CONNECTIONS_FAILURE = 'FETCH_IDP_CONNECTIONS_FAILURE';

export const ADD_IDP_CONNECTION_REQUEST = 'ADD_IDP_CONNECTION_REQUEST';
export const ADD_IDP_CONNECTION_SUCCESS = 'ADD_IDP_CONNECTION_SUCCESS';
export const ADD_IDP_CONNECTION_FAILURE = 'ADD_IDP_CONNECTION_FAILURE';

export const UPDATE_IDP_CONNECTION_REQUEST = 'UPDATE_IDP_CONNECTION_REQUEST';
export const UPDATE_IDP_CONNECTION_SUCCESS = 'UPDATE_IDP_CONNECTION_SUCCESS';
export const UPDATE_IDP_CONNECTION_FAILURE = 'UPDATE_IDP_CONNECTION_FAILURE';

export const DELETE_IDP_CONNECTION_REQUEST = 'DELETE_IDP_CONNECTION_REQUEST';
export const DELETE_IDP_CONNECTION_SUCCESS = 'DELETE_IDP_CONNECTION_SUCCESS';
export const DELETE_IDP_CONNECTION_FAILURE = 'DELETE_IDP_CONNECTION_FAILURE';

export const fetchIdpConnectionsRequest = createActionCreator(
  FETCH_IDP_CONNECTIONS_REQUEST,
);
export const fetchIdpConnectionsSuccess = createActionCreator(
  FETCH_IDP_CONNECTIONS_SUCCESS,
);
export const fetchIdpConnectionsFailure = createActionCreator(
  FETCH_IDP_CONNECTIONS_FAILURE,
);

export const addIdpConnectionRequest = createActionCreator(
  ADD_IDP_CONNECTION_REQUEST,
);
export const addIdpConnectionSuccess = createActionCreator(
  ADD_IDP_CONNECTION_SUCCESS,
);
export const addIdpConnectionFailure = createActionCreator(
  ADD_IDP_CONNECTION_FAILURE,
);

export const updateIdpConnectionRequest = createActionCreator(
  UPDATE_IDP_CONNECTION_REQUEST,
);
export const updateIdpConnectionSuccess = createActionCreator(
  UPDATE_IDP_CONNECTION_SUCCESS,
);
export const updateIdpConnectionFailure = createActionCreator(
  UPDATE_IDP_CONNECTION_FAILURE,
);

export const deleteIdpConnectionRequest = createActionCreator(
  DELETE_IDP_CONNECTION_REQUEST,
);
export const deleteIdpConnectionSuccess = createActionCreator(
  DELETE_IDP_CONNECTION_SUCCESS,
);
export const deleteIdpConnectionFailure = createActionCreator(
  DELETE_IDP_CONNECTION_FAILURE,
);

export const fetchIdpConnections = accountId => dispatch => {
  dispatch(fetchIdpConnectionsRequest());

  return AmsAPI.get(`/api/public/v1/accounts/${accountId}/idp_connections`)
    .then(payload => {
      dispatch(fetchIdpConnectionsSuccess({ payload }));
    })
    .catch(error => {
      dispatch(fetchIdpConnectionsFailure({ error }));
    });
};

const readCertFile = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const serializeIdpConnection = async idpConnection => {
  let signingCert = null;
  if (idpConnection.signingCert) {
    signingCert = await readCertFile(idpConnection.signingCert);
  }

  return {
    configuration: {
      display_name: idpConnection.displayName,
      strategy: 'samlp',
      options: {
        sign_in_endpoint: idpConnection.signInUrl,
        domain_aliases:
          idpConnection.emailDomain === '' ? [] : [idpConnection.emailDomain],
        signing_cert: signingCert,
      },
    },
  };
};

export const addIdpConnection =
  ({ accountId, idpConnection, serializer }) =>
  dispatch => {
    dispatch(addIdpConnectionRequest());

    const serializeFn =
      serializer != null ? serializer : serializeIdpConnection;

    return serializeFn(idpConnection)
      .then(idpSerialized => {
        return AmsAPI.post(
          `/api/public/v1/accounts/${accountId}/idp_connections`,
          idpSerialized,
        );
      })
      .then(payload => {
        dispatch(addIdpConnectionSuccess({ payload }));
      })
      .catch(error => {
        dispatch(addIdpConnectionFailure({ error }));
      });
  };

export const updateIdpConnection =
  ({ accountId, idpConnection, serializer }) =>
  dispatch => {
    dispatch(updateIdpConnectionRequest());

    const serializeFn =
      serializer != null ? serializer : serializeIdpConnection;

    return serializeFn(idpConnection)
      .then(idpSerialized => {
        return AmsAPI.put(
          `/api/public/v1/accounts/${accountId}/idp_connections/${idpConnection.id}`,
          idpSerialized,
        );
      })
      .then(payload => {
        dispatch(updateIdpConnectionSuccess({ payload }));
      })
      .catch(error => {
        dispatch(updateIdpConnectionFailure({ error }));
      });
  };

export const deleteIdpConnection =
  ({ accountId, connectionId }) =>
  dispatch => {
    dispatch(deleteIdpConnectionRequest());

    return AmsAPI.delete(
      `/api/public/v1/accounts/${accountId}/idp_connections/${connectionId}`,
    )
      .then(() => dispatch(deleteIdpConnectionSuccess()))
      .catch(error => {
        dispatch(deleteIdpConnectionFailure({ error }));
      });
  };

export const deserializeIdpConnection = idpConnection => {
  if (idpConnection) {
    return {
      id: idpConnection.id,
      displayName: idpConnection.configuration.display_name,
      signInUrl: idpConnection.configuration.options.signInEndpoint,
      signingCert: idpConnection.configuration.options.cert,
      emailDomain: idpConnection.configuration.options.domain_aliases[0],
    };
  }
  return null;
};
