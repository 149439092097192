import React, { useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useGetPreview } from './hooks';
import { useModalState, useDispatch } from '../../../context';
import Attachments from './attachments';
import Header from './header';
import Preview from './preview';
import Warnings from '../../warnings';
import ErrorMessage from '../../errors';
import { useInitializeAdverseItems } from '../adverse-items/hooks';
import { useGetReport } from '../../../../../../../../../api/reports';
import { useGetMedium } from '../config-options/hooks';

const dividerCss = { margin: '1.5rem 0' };
const send_pre_aa = `${namespace}:report.actions.pre_adverse.submit`;
const email_base_path = `${namespace}:report.actions.pre_adverse.modal.preview_email.header`;

type Props = {};

const PreviewEmail: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { report } = useGetReport();
  const { individualizedAssessment } = useModalState('state');
  const currentScreen = useModalState('currentScreen');
  const previousScreen = useModalState('previousScreen');
  const nextScreen = useModalState('nextScreen');
  const transitionToScreen = useModalState('transitionToScreen');
  const medium = useGetMedium();
  const { FCI_FLOW_ENABLED, OPTIONAL_FCI_FLOW_ENABLED } =
    useInitializeAdverseItems();
  const mediumSelectionEnabled =
    report?.account?.adverse_action_medium_selection;
  const postalAddressPresent = report?.account?.adverse_action_postal_address;

  const {
    preview,
    attachments,
    isLoading: previewLoading,
    isError,
    error,
  } = useGetPreview();

  const disabled =
    isError || previewLoading || (medium === 'postal' && !postalAddressPresent);

  useEffect(() => {
    const submitAction = t(send_pre_aa);

    const next = {
      name: submitAction,
      onClick: nextScreen,
      disabled,
    };

    const back = {
      onClick: previousScreen,
    };

    if (FCI_FLOW_ENABLED) {
      back.onClick = () => transitionToScreen('fair_chance_form');
    } else if (OPTIONAL_FCI_FLOW_ENABLED) {
      back.onClick = () => transitionToScreen('fair_chance');
    }

    if (mediumSelectionEnabled) {
      back.onClick = () => transitionToScreen('config_options');
    }

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next,
        back,
      },
    });
    // nextScreen and previousScreen are the same function but change on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, dispatch, disabled]);

  return (
    <>
      <ErrorMessage errors={error} />
      <Warnings currentScreen={currentScreen} />
      <Header base_path={email_base_path} />
      <M.Divider style={dividerCss} />
      <Preview
        preview={preview}
        isLoading={previewLoading}
        type='pre-aa-email'
      />
      <M.Divider style={dividerCss} />
      <Attachments
        attachments={attachments}
        individualizedAssessment={individualizedAssessment}
      />
    </>
  );
};

export default PreviewEmail;
