import each from 'lodash/each';
import { namespace } from './constants';
import * as data from './data';

type Resources = Record<string, Record<typeof namespace, object>>;

export const resources: Resources = {};

each(data, (translations, locale) => {
  resources[locale] = { [namespace]: translations };
});

export default resources;
