import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Type } from 'modules/assess/models/rulesets/growth-insights/entity';
import FairnessAndCompliance from './FairnessAndCompliance';
import LookbackRules from './LookbackRules';

export type Props = {
  data?: Type;
};

const Container = styled.div`
  > div {
    margin-top: 1em;
  }
`;

export const GrowthInsightsContent: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('assess');
  const fairness_and_compliance = data?.fairness_and_compliance || [];
  const lookback_rules = data?.lookback_rules || [];
  if (!fairness_and_compliance.length && !lookback_rules.length) {
    return null;
  }
  const heading = t('assess:rulesets.growth_insights.container.heading');
  const details = t('assess:rulesets.growth_insights.container.details');

  return (
    <Container data-testid='assess-rulesets-growth_insights-content'>
      <h2>{heading}</h2>
      <p>{details}</p>
      <FairnessAndCompliance
        fairness_and_compliance={fairness_and_compliance}
      />
      <LookbackRules lookback_rules={lookback_rules} />
    </Container>
  );
};

export default GrowthInsightsContent;
