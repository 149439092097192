import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useCMSettings, useUpdateCMSettings } from './hooks';
import ToggleSetting from './ToggleSetting';
import SettingsHeader from './SettingsHeader';

const INIT_SETTINGS = {
  candidate_story_review_tasks_enabled: false,
};

export const SettingsContainer: React.FC = () => {
  const { data, isLoading } = useCMSettings();
  const { call } = useUpdateCMSettings();
  const [settings, setSettings] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (data) {
      const settingsConfig: { [key: string]: any } = {
        ...INIT_SETTINGS,
        ...data,
      };
      setSettings(settingsConfig);
    }
  }, [data]);

  const onSave = useCallback(() => {
    call({ settings });
  }, [call, settings]);

  const updateSetting = useCallback(
    (setting: string, value: any) => {
      const newSettings = {
        ...settings,
        [setting]: value,
      };
      setSettings(newSettings);
      setIsUpdated(JSON.stringify(newSettings) !== JSON.stringify(data));
    },
    [data, settings],
  );

  const settingsComponents = Object.keys(settings).map(settingName => {
    const value = (settings as { [key: string]: any })[settingName];

    return (
      <ToggleSetting
        key={settingName}
        setting={settingName}
        value={value}
        updateSetting={updateSetting}
      />
    );
  });

  const disableSave = isLoading || !isUpdated;

  return (
    <div data-testid='case-management-settings-container'>
      <SettingsHeader disableSave={disableSave} onSave={onSave} />
      {isLoading ? <M.LoadingInline /> : settingsComponents}
    </div>
  );
};

export default SettingsContainer;
