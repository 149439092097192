/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, {
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Context from 'components/Invitations/context';
import { NodeDropdown } from '../../OrderBackgroundCheck/SharedItems';

const SelectNode = ({
  account,
  setSelectedNode,
  selectedNode,
}: {
  account: any;
  setSelectedNode: any;
  selectedNode: any;
}) => {
  const { nodes, nodeIsLoading, onNodeFilterChange } = useContext(Context);
  const [nodeFilter, setNodeFilter] = useState('');

  const onNodeFilter = useCallback(
    newFilter => {
      setNodeFilter(newFilter);
      onNodeFilterChange(newFilter);
    },
    [setNodeFilter, onNodeFilterChange],
  );

  // If nodes aren't loading anymore and a filter has been provided, and no nodes were found, its invalid
  const nodeInvalid = useMemo(
    () => !nodeIsLoading && nodeFilter?.length > 0 && nodes.length === 0,
    [nodeIsLoading, nodeFilter, nodes],
  );

  function handleNodeChange(selectedItem: any) {
    if (!selectedItem) {
      setSelectedNode({
        custom_id: '',
        parent_custom_id: '',
        tier: '',
        name: '',
        id: '',
      });
      return;
    }
    setSelectedNode(selectedItem);
  }

  return (
    <NodeDropdown
      data-testid='node-dropdown-testid'
      id='node-select'
      nodes={nodes}
      selectedNode={selectedNode}
      loading={nodeIsLoading}
      invalid={nodeInvalid}
      onChange={handleNodeChange}
      onFilterChange={onNodeFilter}
    />
  );
};

export default SelectNode;
