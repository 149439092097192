import { createContext, useContext } from 'react';
import { QueryResult } from 'react-query';

import * as Entity from 'modules/assess/models/rulesets/ruleset';

export const Query = createContext<QueryResult<Entity.Type> | undefined>(
  undefined,
);

export const useQuery = () => {
  return useContext(Query);
};
