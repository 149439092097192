import { useContextSelector, createContext } from 'use-context-selector';
import {
  TransitionCallback,
  TransitionHelper,
} from '@dashboard-experience/utils';

import { State, Dispatch, initialState } from './reducer';

type ContextType = {
  currentScreen: string;
  open: boolean;
  onClose: () => void;
  transitionToScreen: TransitionCallback;
  previousScreen: TransitionHelper;
  nextScreen: TransitionHelper;
  state: State;
  dispatch: Dispatch;
};

export const context = createContext<ContextType>({
  currentScreen: '',
  open: false,
  onClose: () => {},
  transitionToScreen: () => {},
  previousScreen: () => {},
  state: initialState,
  dispatch: () => {},
  nextScreen: () => {},
});

export const useModalState = (selector: keyof ContextType) => {
  const ctx = useContextSelector(context, (state) => {
    // TODO: try to properly type the return value
    return state[selector] as any;
  });

  if (ctx === undefined) {
    throw new Error(
      'useModalState must be used within a Update Individualized Assessment modal provider',
    );
  }

  return ctx;
};

export const useDispatch = () =>
  useContextSelector(context, ({ dispatch }) => dispatch);
