import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { usePostHierarchyCsv } from 'api/hierarchy/hooks';

import Actions from './Actions';
import ErrorNotifications from './ErrorNotifications';
import UploadInput from './UploadInput';
import UploadInstructions from './UploadInstructions';
import { AccountHierarchyCsv } from './types';

type Props = {
  open: boolean;
  onClose: () => void;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setAsyncPost: React.Dispatch<React.SetStateAction<boolean>>;
};

const MarginContainer = styled.div`
  > * {
    margin-bottom: 1rem;
  }
`;

export const CsvModal: React.FC<Props> = ({
  open,
  onClose,
  setShowConfirm,
  setAsyncPost,
}) => {
  const modalTestId = 'account-hierarchy-csv-modal';
  const { postHierarchyCsvCall } = usePostHierarchyCsv();

  const [csv, setCsv] = useState<AccountHierarchyCsv>({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState(false);

  const handleOnUpload = useCallback(() => {
    if (!csv.fileStorageKey) return;
    postHierarchyCsvCall({ key: csv.fileStorageKey });
    onClose();

    if (!csv.fileS3URL) {
      return;
    }

    setShowConfirm(true);
  }, [csv, postHierarchyCsvCall, onClose]);

  return (
    <M.ComposedModal data-testid={modalTestId} onClose={onClose} open={open}>
      <M.ModalHeader data-testid={`${modalTestId}-header`} closeModal={onClose}>
        <h4>Create account hierarchy</h4>
      </M.ModalHeader>
      <M.ModalBody data-testid={`${modalTestId}-body`}>
        <MarginContainer>
          <MarginContainer>
            Upload a CSV file of all nodes you want in your account hierarchy.
          </MarginContainer>
          <UploadInput
            csv={csv}
            setCsv={setCsv}
            setAsyncPost={setAsyncPost}
            setValidationErrors={setValidationErrors}
            setIsValidating={setIsValidating}
          />
          <ErrorNotifications
            validationErrors={validationErrors}
            isValidating={isValidating}
          />
          <UploadInstructions />
        </MarginContainer>
      </M.ModalBody>
      <M.ModalFooter data-testid={`${modalTestId}-actions`}>
        <Actions
          onCancel={onClose}
          onUpload={handleOnUpload}
          disableUpload={
            isValidating || validationErrors.length > 0 || !csv.fileS3URL
          }
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default CsvModal;
