import client from './client';
import Kind from '../models/rules/common/kind';
import { Namespace } from './constants';

export const fetchAssessRuleFacts = (
  namespace: Namespace,
  item_types: Array<string>,
): Promise<any> => {
  return client.get(`/${namespace}/rules/facts`, {
    params: { item_types },
  });
};

export const fetchAssessRuleItemType = (
  namespace: Namespace,
  kind: Kind,
): Promise<any> => {
  return client.get(`/${namespace}/rules/item_types/${kind}`);
};

export const fetchAssessRuleValues = (
  namespace: Namespace,
  fact: string,
  itemTypes: Array<string>,
  ruleset_id?: string,
): Promise<any> => {
  return client.get(`/${namespace}/rules/facts/${fact}`, {
    params: { item_types: itemTypes, ruleset_id },
  });
};
