import { AnyQueryKey, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Namespace } from 'modules/assess/api/constants';
import {
  fetchAssessRuleFacts,
  fetchAssessRuleItemType,
  fetchAssessRuleValues,
} from 'modules/assess/api/rules';
import { Kind } from 'modules/assess/models/rules/common/kind';
import { useNamespace } from 'modules/assess/ui/router/context';

export const useGetAssessRuleFacts = (item_types: Array<string> | []) => {
  const namespace = useNamespace() || Namespace.criminal;
  const key: AnyQueryKey = [
    'assess',
    namespace,
    'rule-facts',
    [...item_types].sort(),
  ];
  const request = () => fetchAssessRuleFacts(namespace, item_types);

  const { isLoading, isError, data } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};

export const useGetAssessRuleItemTypes = (kind: Kind) => {
  const namespace = useNamespace() || Namespace.criminal;
  const key: AnyQueryKey = ['assess', namespace, 'rule-item-types', kind];
  const request = () => fetchAssessRuleItemType(namespace, kind);

  const { isLoading, isError, data } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};

export const useGetAssessRuleValues = (
  fact: string,
  itemTypes: Array<string>,
) => {
  const namespace = useNamespace() || Namespace.criminal;
  const key: AnyQueryKey = [
    'assess',
    namespace,
    'rule-values',
    fact,
    [...itemTypes].sort(),
  ];
  const { ruleset } = useParams();
  const request = () =>
    fetchAssessRuleValues(namespace, fact, itemTypes, ruleset);

  const { isLoading, isError, data } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
