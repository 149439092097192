/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { M, colors } from '@dashboard-experience/mastodon';

import { deleteUser } from 'actions/AccountUsersActions';

class DeleteUserModal extends PureComponent {
  static defaultProps = {};

  /**
   * @name deleteUser
   * @function
   * @memberOf UsersTable
   * @returns {promise}
   * @description Trigger the delete user flow
   */
  deleteUser = () => {
    const { deleteUser: execDelete, user, hideModal } = this.props;
    return execDelete(user.id).then(hideModal);
  };

  render() {
    const { open, user, hideModal } = this.props;
    return (
      <M.ComposedModal onClose={hideModal} open={open}>
        <M.ModalHeader closeModal={hideModal}>
          <h2>Delete User</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <p>
            Are you sure you want to delete user <strong>{user.email}</strong>?
          </p>
        </M.ModalBody>
        <M.ModalFooter>
          <M.BinaryButtons
            btnLeft={{ name: 'Cancel', onClick: hideModal }}
            btnRight={{
              name: 'Delete User',
              onClick: this.deleteUser,
              style: {
                'background-color': colors.uiOrange600,
                'border-color': colors.uiOrange600,
              },
            }}
            size='sm'
          />
        </M.ModalFooter>
      </M.ComposedModal>
    );
  }
}

DeleteUserModal.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  hideModal: PropTypes.func,
  deleteUser: PropTypes.func,
};

DeleteUserModal.defaultProps = {
  hideModal: () => {},
  deleteUser: () => {},
};

const mapDispatchToProps = {
  deleteUser,
};

export default connect(null, mapDispatchToProps)(DeleteUserModal);
