import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Route from '../route';

export type Type = React.FC<Props>;

export type Props = {};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  a {
    text-decoration: none !important;
  }
`;

const Item = styled(M.BreadcrumbItem)`
  ::after {
    content: '⟩';
  }
`;

const Title: Type = () => {
  const routes = useContext(Route.Context);
  const items = routes.map(route => (
    <Item key={route.location}>
      <Link to={route.location}>
        <h3 className='mb-0'>{route.title}</h3>
      </Link>
    </Item>
  ));

  return (
    <Container>
      <M.Breadcrumb noTrailingSlash>{items}</M.Breadcrumb>
    </Container>
  );
};

export default Title;
