import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const DomesticWorkLocation = styled.div.attrs({
  className: 'domestic-work-location-container',
})`
  display: flex;
  gap: 16px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const InfoIcon = styled(M.Icon).attrs({
  className: 'candidate-info-icon',
})`
  color: ${colors.uiGrey500};
  padding-top: 2px;
`;
