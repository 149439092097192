import React from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import { ListVersions } from './versions-list';
import { Promote } from './promote';

export type Props = {
  item: Entity;
  selectedVersion?: Version.Basic;
  setSelectedVersion: (version: Version.Basic) => void;
  onClose: () => void;
};

export const Content: React.FC<Props> = ({
  item,
  selectedVersion,
  setSelectedVersion,
  onClose,
}) => {
  return (
    <div data-testid='assess-mvr-category-set-versions-content'>
      {selectedVersion ? (
        <Promote item={item} selectedVersion={selectedVersion} />
      ) : (
        <ListVersions
          item={item}
          setSelectedVersion={setSelectedVersion}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default Content;
