import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import {
  actionTypes,
  MULTIPLE_PEOPLE,
  SINGLE_PERSON,
  useOrderBackgroundCheckContext,
} from '../Context';
import { StyledChoiceCardGroup, ChoiceCardWrap } from '../SharedItems';
import { getDashboardUrl } from '../../../../utils';

const ManualBulkUploadStyled = styled.div`
  .section-title {
    color: ${colors.coolBlack100};
    font-size: 1.125rem !important;
    margin: 2.5rem 0 1rem !important;
  }

  p {
    color: ${colors.coolBlack100};
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
`;

const ChoiceCardGroup = styled(StyledChoiceCardGroup)`
  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;

const ChoiceCardWrapped = styled(ChoiceCardWrap)`
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const ManualBulkUpload: React.FC = () => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const currentUser = useUser();

  const handleManualBulkUploadTypeChange = useCallback(
    (type: string) => {
      dispatch({
        type: actionTypes.CHANGE_MANUAL_BULK_UPLOAD_TYPE,
        payload: { manualBulkUploadType: type },
      });
    },
    [dispatch],
  );

  const bulkOrderHistoryUrl = hasPermission(
    currentUser,
    'read_account_invoices',
  )
    ? getDashboardUrl(`/billing/bulk-history`)
    : getDashboardUrl(`/bulk-order-history`);

  const handleOnePerson = useCallback(() => {
    handleManualBulkUploadTypeChange(SINGLE_PERSON);
  }, [handleManualBulkUploadTypeChange]);

  const handleMultiplePeople = useCallback(() => {
    handleManualBulkUploadTypeChange(MULTIPLE_PEOPLE);
  }, [handleManualBulkUploadTypeChange]);

  return (
    <ManualBulkUploadStyled>
      <h3
        className='section-title'
        data-testid='get-started-manual-bulk-upload-title'
      >
        How many people are you ordering checks for?
      </h3>
      <p>
        We&apos;ll take you to our step-by-step process for one person or our
        CSV upload form for multiple people.
      </p>
      <ChoiceCardGroup data-testid='manual-bulk-order-choice-card-group'>
        <ChoiceCardWrapped data-testid='manual-bulk-order-single-person-card-wrap'>
          <M.ChoiceCard
            description='One person'
            onClick={handleOnePerson}
            checked={state.manualBulkUploadType === SINGLE_PERSON}
            value={SINGLE_PERSON}
            data-testid='manual-bulk-order-single-person'
          />
        </ChoiceCardWrapped>
        <ChoiceCardWrapped data-testid='manual-bulk-order-multiple-people-card-wrap'>
          <M.ChoiceCard
            description='Multiple people'
            onClick={handleMultiplePeople}
            checked={state.manualBulkUploadType === MULTIPLE_PEOPLE}
            value={MULTIPLE_PEOPLE}
            data-testid='manual-bulk-order-multiple-people'
          />
        </ChoiceCardWrapped>
      </ChoiceCardGroup>
      {state.manualBulkUploadType === SINGLE_PERSON && (
        <p>
          Additional candidate details will be added after you submit your
          order.
        </p>
      )}
      {state.manualBulkUploadType === MULTIPLE_PEOPLE && (
        <p>
          A downloadable template is available for use in step 3. Bulk order
          history can be found{' '}
          <M.Link href={bulkOrderHistoryUrl} target='_blank' size='lg'>
            here
          </M.Link>
          .
        </p>
      )}
    </ManualBulkUploadStyled>
  );
};

export default ManualBulkUpload;
