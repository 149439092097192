import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const FooterStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.5px solid ${colors.uiGrey300};
  padding-top: 1em;
`;

const FooterContainer: React.FC = ({ children }) => {
  return <FooterStyle>{children}</FooterStyle>;
};

export default FooterContainer;
