import { zip as _zip } from 'lodash';
import { Decision, Disposition, ChargeSeverity } from 'modules/assess';
import Operator from 'modules/assess/models/operator';
import * as Entity from 'modules/assess/models/rules/premade';
import { Fact } from './fact';
import { RecordType } from './record';

export { Decision, Entity };

export enum Category {
  CANDIDATE = 'candidate',
  CHARGE_CATEGORY = 'charge_category',
  CHARGE_SEVERITY_TIMING = 'charge_severity_timing',
  DISPOSITION_NON_CONVICTION = 'disposition_non_conviction',
  NONE = 'none',
}

export enum Code {
  ACCEPT_AGE_UNDER_18 = 'ACCEPT_AGE_AT_OFFENSE_UNDER_18',
  ACCEPT_AGE_UNDER_23 = 'ACCEPT_AGE_UNDER_23',
  ACCEPT_AGE_UNDER_25 = 'ACCEPT_AGE_UNDER_25_IF_NOW_OVER_28',
  ACCEPT_CHARGES_PENDING = 'ACCEPT_CHARGES_PENDING',
  ACCEPT_DISMISSED = 'ACCEPT_DISMISSED',
  ACCEPT_EXPUNGEABLE_CHARGES = 'ACCEPT_EXPUNGEABLE_CHARGES',
  ACCEPT_LESS_THAN_MISDEMEANOR = 'ACCEPT_LESS_THAN_MISDEMEANOR',
  ACCEPT_NON_CONVICTION = 'ACCEPT_NON_CONVICTION',
  ACCEPT_NON_FELONY_ALTERNATIVE = 'ACCEPT_NON_FELONY_ALTERNATIVE',
  ACCEPT_NON_FELONY_DRUG_POSSESSION = 'ACCEPT_NON_FELONY_DRUG_POSSESSION',
  ACCEPT_NON_FELONY_MARIJUANA = 'ACCEPT_NON_FELONY_MARIJUANA',
  ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS = 'ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS',
  ACCEPT_PROSTITUTION = 'ACCEPT_PROSTITUTION',
  ACCEPT_PUBLIC_NUISANCE = 'ACCEPT_PUBLIC_NUISANCE',
  ACCEPT_RECORDS_OLDER_THAN_7_YEARS = 'ACCEPT_RECORDS_OLDER_THAN_7_YEARS',
  ACCEPT_VEHICLES_TRAFFIC = 'ACCEPT_VEHICLES_TRAFFIC_EXCEPT_DEATH_DUI',
}

export const CATEGORIES: Array<Category> = [
  Category.DISPOSITION_NON_CONVICTION,
  Category.CHARGE_SEVERITY_TIMING,
  Category.CHARGE_CATEGORY,
  Category.CANDIDATE,
  Category.NONE,
];

export const CODES = Object.values(Code);

export const CODE_CATEGORY: Record<Code, Category> = {
  [Code.ACCEPT_AGE_UNDER_18]: Category.CANDIDATE,
  [Code.ACCEPT_AGE_UNDER_23]: Category.CANDIDATE,
  [Code.ACCEPT_AGE_UNDER_25]: Category.CANDIDATE,
  [Code.ACCEPT_CHARGES_PENDING]: Category.DISPOSITION_NON_CONVICTION,
  [Code.ACCEPT_DISMISSED]: Category.DISPOSITION_NON_CONVICTION,
  [Code.ACCEPT_EXPUNGEABLE_CHARGES]: Category.CHARGE_CATEGORY,
  [Code.ACCEPT_LESS_THAN_MISDEMEANOR]: Category.CHARGE_SEVERITY_TIMING,
  [Code.ACCEPT_NON_CONVICTION]: Category.DISPOSITION_NON_CONVICTION,
  [Code.ACCEPT_NON_FELONY_ALTERNATIVE]: Category.DISPOSITION_NON_CONVICTION,
  [Code.ACCEPT_NON_FELONY_DRUG_POSSESSION]: Category.CHARGE_CATEGORY,
  [Code.ACCEPT_NON_FELONY_MARIJUANA]: Category.CHARGE_CATEGORY,
  [Code.ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS]: Category.CHARGE_SEVERITY_TIMING,
  [Code.ACCEPT_PROSTITUTION]: Category.CHARGE_CATEGORY,
  [Code.ACCEPT_PUBLIC_NUISANCE]: Category.CHARGE_CATEGORY,
  [Code.ACCEPT_RECORDS_OLDER_THAN_7_YEARS]: Category.CHARGE_SEVERITY_TIMING,
  [Code.ACCEPT_VEHICLES_TRAFFIC]: Category.CHARGE_CATEGORY,
};

export const ITEMS: Entity.Map = {
  'premade/ACCEPT_AGE_UNDER_18/1': {
    id: 'premade/ACCEPT_AGE_UNDER_18/1',
    code: Code.ACCEPT_AGE_UNDER_18,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 70,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.AGE_AT_OFFENSE,
        operator: Operator.LE,
        value: 18,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_AGE_UNDER_23/1': {
    id: 'premade/ACCEPT_AGE_UNDER_23/1',
    code: Code.ACCEPT_AGE_UNDER_23,
    name: '',
    decision: Decision.ELIGIBLE,
    deprecated: true,
    position: 70,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.AGE_AT_OFFENSE,
        operator: Operator.LE,
        value: 23,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_AGE_UNDER_25/1': {
    id: 'premade/ACCEPT_AGE_UNDER_25/1',
    code: Code.ACCEPT_AGE_UNDER_25,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 70,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.AGE_AT_OFFENSE,
        operator: Operator.LE,
        value: 25,
      },
      {
        fact: Fact.AGE_NOW,
        operator: Operator.GT,
        value: 28,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_CHARGES_PENDING/1': {
    id: 'premade/ACCEPT_CHARGES_PENDING/1',
    code: Code.ACCEPT_CHARGES_PENDING,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 80,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.DISPOSITION,
        operator: Operator.IN,
        value: [Disposition.arrest, Disposition.pending],
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_DISMISSED/1': {
    id: 'premade/ACCEPT_DISMISSED/1',
    code: Code.ACCEPT_DISMISSED,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 10,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.DISPOSITION,
        operator: Operator.EQ,
        value: Disposition.dismissed,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_EXPUNGABLE/1': {
    id: 'premade/ACCEPT_EXPUNGABLE/1',
    code: Code.ACCEPT_EXPUNGEABLE_CHARGES,
    name: '',
    decision: Decision.ELIGIBLE,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.POTENTIALLY_EXPUNGEABLE,
        operator: Operator.EQ,
        value: true,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_LESS_THAN_MISDEMEANOR/1': {
    id: 'premade/ACCEPT_LESS_THAN_MISDEMEANOR/1',
    code: Code.ACCEPT_LESS_THAN_MISDEMEANOR,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 30,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_SEVERITY,
        operator: Operator.EQ,
        value: ChargeSeverity.petty_offense,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_NON_CONVICTION/1': {
    id: 'premade/ACCEPT_NON_CONVICTION/1',
    code: Code.ACCEPT_NON_CONVICTION,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 19,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.DISPOSITION,
        operator: Operator.IN,
        value: [
          Disposition.alternative,
          Disposition.dismissed,
          Disposition.expunged,
          Disposition.merged,
          Disposition.transferred,
        ],
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_NON_FELONY_ALTERNATIVE/1': {
    id: 'premade/ACCEPT_NON_FELONY_ALTERNATIVE/1',
    code: Code.ACCEPT_NON_FELONY_ALTERNATIVE,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 20,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_SEVERITY,
        operator: Operator.IN,
        value: [ChargeSeverity.misdemeanor, ChargeSeverity.petty_offense],
      },
      {
        fact: Fact.DISPOSITION,
        operator: Operator.EQ,
        value: Disposition.alternative,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_NON_FELONY_DRUG_POSSESSION/1': {
    id: 'premade/ACCEPT_NON_FELONY_DRUG_POSSESSION/1',
    code: Code.ACCEPT_NON_FELONY_DRUG_POSSESSION,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 50,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_SEVERITY,
        operator: Operator.IN,
        value: [ChargeSeverity.misdemeanor, ChargeSeverity.petty_offense],
      },
      {
        fact: Fact.CHARGE_CATEGORY_L3,
        operator: Operator.IN,
        value: [
          'Drug Possession',
          'Intent to Possess Drugs',
          'Possession of Drug Paraphernalia',
          'Possession of Drug without Prescription',
        ],
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_NON_FELONY_MARIJUANA/1': {
    id: 'premade/ACCEPT_NON_FELONY_MARIJUANA/1',
    code: Code.ACCEPT_NON_FELONY_MARIJUANA,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 40,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_SEVERITY,
        operator: Operator.IN,
        value: [ChargeSeverity.misdemeanor, ChargeSeverity.petty_offense],
      },
      {
        fact: Fact.CHARGE_CATEGORY_L3,
        operator: Operator.EQ,
        value: 'Marijuana Possession/Use',
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS/1': {
    id: 'premade/ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS/1',
    code: Code.ACCEPT_NON_FELONY_OLDER_THAN_7_YEARS,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 50,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_SEVERITY,
        operator: Operator.IN,
        value: [ChargeSeverity.misdemeanor, ChargeSeverity.petty_offense],
      },
      {
        fact: Fact.LOOKBACK,
        operator: Operator.GE,
        value: 7,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_PROSTITUTION/1': {
    id: 'premade/ACCEPT_PROSTITUTION/1',
    code: Code.ACCEPT_PROSTITUTION,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 59,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_CATEGORY_L3,
        operator: Operator.IN,
        value: [
          'Promoting Prostitution',
          'Prostitution',
          'Soliciting a Prostitute',
        ],
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_PUBLIC_NUISANCE/1': {
    id: 'premade/ACCEPT_PUBLIC_NUISANCE/1',
    code: Code.ACCEPT_PUBLIC_NUISANCE,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 56,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_CATEGORY_L2,
        operator: Operator.EQ,
        value: 'Public Nuisance',
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_RECORDS_OLDER_THAN_7_YEARS/1': {
    id: 'premade/ACCEPT_RECORDS_OLDER_THAN_7_YEARS/1',
    code: Code.ACCEPT_RECORDS_OLDER_THAN_7_YEARS,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 60,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.LOOKBACK,
        operator: Operator.GE,
        value: 7,
      },
    ],
    version: 1,
    description: [],
  },

  'premade/ACCEPT_VEHICLES_TRAFFIC/1': {
    id: 'premade/ACCEPT_VEHICLES_TRAFFIC/1',
    code: Code.ACCEPT_VEHICLES_TRAFFIC,
    name: '',
    decision: Decision.ELIGIBLE,
    position: 53,
    conditions: [
      {
        fact: Fact.TYPE,
        operator: Operator.EQ,
        value: RecordType.CRIMINAL_CHARGE,
      },
      {
        fact: Fact.CHARGE_CATEGORY_L1,
        operator: Operator.EQ,
        value: 'Vehicles & Traffic',
      },
    ],
    version: 1,
    description: [],
  },
};

export const LATEST = buildLatest();

export function build(items?: Entity.List | Entity.Map) {
  const itemsSorted = build.sort(items || []);
  const latestSorted = build.sort(LATEST);
  const zipped = _zip(CODES, itemsSorted, latestSorted);

  return zipped
    .map(([key, current, latest]) => ({
      id: (current?.id || latest?.id) as string,
      key: key as string,
      disabled: !current && latest?.deprecated,
      rule: current || latest || missing(),
      position: latest?.position ?? Number.POSITIVE_INFINITY,
      isSelected: !!current,
      updated: current?.version === latest?.version,
    }))
    .filter(r => !r.rule.deprecated)
    .sort((a, b) => a.position - b.position);
}

build.sort = function sort(
  items: Entity.List | Entity.Map,
): Array<Entity.Type | undefined> {
  const list = Object.values(items);
  return CODES.map(code => list.find(item => item.code === code));
};

function buildLatest(): Entity.Map {
  return Object.values(ITEMS).reduce<Entity.Map>((items, item) => {
    const current = items[item.code];

    if (!current || item.version > current.version) {
      items[item.code] = item;
    }

    return items;
  }, {});
}

export function category(code: string) {
  return CODE_CATEGORY[code as Code] || Category.NONE;
}

export function missing() {
  const partial = Entity.initialize();

  return { ...partial, id: 'missing', key: 'missing' };
}
