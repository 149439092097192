import React, { useCallback, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { BillingEntity } from '../../types/Billing';
import { scrollToTop } from '../../utils';

const EditButton = styled(M.Button)`
  div.cds--assistive-text {
    background-color: ${colors.uiGrey0} !important;
    color: ${colors.uiGrey800} !important;
  }
`;

type Props = {
  profile: BillingEntity;
  isLoading: boolean;
  submit: any;
  EditForm: React.ComponentType<any>;
};

const PaymentProfileTableEditCell: React.FC<Props> = ({
  profile,
  isLoading,
  submit,
  EditForm,
}) => {
  // FOR BUTTON
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const showEditModal = useCallback(() => {
    setEditModalOpen(true);
    scrollToTop();
  }, []);

  const hideEditModal = useCallback(() => {
    setEditModalOpen(false);
  }, []);

  const iconDescription = `Edit ${profile.name}`;

  return (
    <M.TableCell>
      <EditButton
        kind='tertiary'
        size='lg'
        hasIconOnly
        iconDescription={iconDescription}
        onClick={showEditModal}
        disabled={profile.name === 'Main Account'}
      >
        <M.Icon icon='Pen' size={16} />
      </EditButton>
      <EditForm
        paymentProfile={profile}
        submit={submit}
        modalOpen={editModalOpen}
        hideModal={hideEditModal}
        isLoading={isLoading}
      />
    </M.TableCell>
  );
};

export default PaymentProfileTableEditCell;
