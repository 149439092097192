import * as entities from 'state/utilities/entities/reducers';
import * as Actions from './actions';
import * as State from './state';

export const reducer = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Create.TYPE: {
      return entities.create(state, action as Actions.Create.Action);
    }
    case Actions.Remove.TYPE: {
      return entities.remove(state, action as Actions.Remove.Action);
    }
    default: {
      break;
    }
  }
  return state;
};

export default reducer;
