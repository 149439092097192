import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReportStatuses } from '@dashboard-experience/utils';
import { address, Report } from 'types';
import { useGetAssociatedAddresses } from 'api/reports';
import { usePackageObj } from 'containers/Report';

import AddressSection from './AddressSection';

export const formatSSNAddress = (address: address) => {
  const { street, city, state, zipcode } = address;
  const hasStreetAndZip = street && zipcode;
  return hasStreetAndZip
    ? `${street}, ${city}, ${state} ${zipcode}`
    : `${city}, ${state}`;
};

export const formatAdditionalAddress = (address: address) => {
  const { city, state, county } = address;
  const hasCounty = !!county && county.length > 0;
  return hasCounty ? `${county} County, ${state}` : `${city}, ${state}`;
};

export const formatDate = (address: address) => {
  const { from_date, to_date } = address;
  const hasToAndFromDate = from_date && to_date;
  return hasToAndFromDate
    ? `From ${moment(from_date).format('MMM, y')} to ${moment(to_date).format(
        'MMM, y',
      )}`
    : null;
};

const formatExtraInfo = (address: address) => {
  const { county } = address;
  return county ? `County: ${county}` : null;
};

const ssnFormatting = Object.freeze({
  formatAddress: formatSSNAddress,
  formatDate,
  formatExtraInfo,
});

const additionalFormatting = Object.freeze({
  formatAddress: formatAdditionalAddress,
  formatDate,
});

export const Addresses: React.FC<{ report: Report }> = ({ report }) => {
  const { ssn_trace } = report;
  const addresses = ssn_trace?.addresses || [];
  const isPending = ssn_trace?.status === ReportStatuses.PENDING;
  const [showAssociatedAddresses, setShowAssociatedAddresses] = useState(false);

  const associatedAddresses = useGetAssociatedAddresses(
    report?.id,
    showAssociatedAddresses,
  );
  const packageObj = usePackageObj();
  const shouldShow =
    packageObj?.education_history_based_county_criminal_searches ||
    packageObj?.education_history_based_state_criminal_searches ||
    packageObj?.employment_history_based_county_criminal_searches ||
    packageObj?.employment_history_based_state_criminal_searches ||
    packageObj?.criminal_self_disclosure;

  useEffect(() => {
    // use double bang to convert undefined to boolean
    setShowAssociatedAddresses(!!shouldShow);
  }, [shouldShow]);

  if (!report?.id) return null;

  if (!report?.account?.display_address_and_alias_history) return null;

  return (
    <>
      {showAssociatedAddresses && ssn_trace && !isPending && (
        <>
          <AddressSection
            title='Addresses'
            id='addresses'
            addresses={addresses}
            {...ssnFormatting}
          />
          <AddressSection
            title='Other Addresses'
            id='other-addresses'
            addresses={associatedAddresses}
            {...additionalFormatting}
          />
        </>
      )}
      {showAssociatedAddresses && ssn_trace && isPending && (
        <AddressSection
          title='Other Addresses'
          id='other-addresses'
          addresses={associatedAddresses}
          {...additionalFormatting}
        />
      )}
      {showAssociatedAddresses && !ssn_trace && (
        <AddressSection
          title='Other Addresses'
          id='other-addresses'
          addresses={associatedAddresses}
          {...additionalFormatting}
        />
      )}
      {!showAssociatedAddresses &&
        !!ssn_trace?.addresses?.length &&
        !isPending && (
          <AddressSection
            title='Addresses'
            id='addresses'
            addresses={addresses}
            {...ssnFormatting}
          />
        )}
    </>
  );
};

export default Addresses;
