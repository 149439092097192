import React from 'react';
import { Row } from 'modules/assess/ui/rules/common/rule';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import Operator from './operator';
import DeleteCondition from './delete-condition';
import Facts from './facts';
import Values from './values';

const Condition = ({ index }: { index: number }) => {
  const { conditions } = useConditions();

  const condition = conditions[index];

  if (condition.fact === 'object_type' || condition.fact === 'count') {
    return null;
  }

  return (
    <Row data-testid='assess-rules-common-rule-editor-rule-condition'>
      <Facts index={index} />
      {condition.fact && <Operator index={index} />}
      {condition.fact && <Values index={index} />}
      <DeleteCondition index={index} />
    </Row>
  );
};

export default Condition;
