import React, { useCallback, useMemo } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  getResultOrStatus,
  ReportStatuses,
  getScreeningStatus,
} from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY } from 'Constants';
import styled from 'styled-components';
import moment from 'moment';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { useLogPDFDownload } from 'api/documents';
import { usePackageObj } from 'containers/Report';

const selfDisclosureType = 'pdf_self_disclosure';

const None = styled.div`
  color: ${colors.uiTextPrimaryLight};
  font-style: italic;
`;

const Note = styled.div`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 1rem;
`;

const CustomCompletedAt = styled.span`
  max-height: 1rem;
  color: ${colors.uiGrey600};
  font-size: 14px;
  line-height: 20px;
`;

const download = (uri: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = uri;
  a.click();
};

const PDFButton: React.FC<{
  uri?: string;
  logDownload: Function;
  count: number;
}> = ({ uri, logDownload, count }) => {
  const onClick = useCallback(() => {
    if (uri) {
      download(uri);
      logDownload();
    }
  }, [logDownload, uri]);
  return (
    <M.Button kind='secondary' size='sm' onClick={onClick} disabled={!uri}>
      Download {count} self disclosure(s)
    </M.Button>
  );
};

export const SelfDisclosure: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { self_disclosure_screening, documents } = report;
  const selfDisclosureCompletionEnabled =
    report.account?.self_disclosure_review;
  const packageObj = usePackageObj();
  const { call: logDownload } = useLogPDFDownload({
    reportId: report.id,
    type: selfDisclosureType,
  });

  const pdf = documents?.find(doc => doc.type === selfDisclosureType);
  const title = useMemo(
    () => ({
      name: (
        <span>
          <span className='heading-text-name heading-text heading-text-large'>
            Self Disclosure
          </span>
          <CustomCompletedAt>
            {moment(report.created_at).format('lll')}
          </CustomCompletedAt>
        </span>
      ),
    }),
    [report],
  );
  const selfDislosureAvailableWhilePending =
    useFlag(SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY)
      ?.variantKey === 'enabled';
  if (!packageObj?.criminal_self_disclosure) return null;
  const reportPending = getResultOrStatus(report) === ReportStatuses.PENDING;
  // Self disclosure has unique status logic
  const status =
    !!self_disclosure_screening && getResultOrStatus(self_disclosure_screening);
  // if pending, we use pending
  const pending = status === ReportStatuses.PENDING && status;
  const statusOrAssessment =
    selfDisclosureCompletionEnabled &&
    !!self_disclosure_screening &&
    getScreeningStatus(self_disclosure_screening, reportStatusType);

  return (
    <M.Screening
      title={title}
      statusType={reportStatusType}
      status={pending || statusOrAssessment}
      id={SCREENING_TITLES.self_disclosure_screening.id}
    >
      {(!reportPending || selfDislosureAvailableWhilePending) && (
        <>
          {self_disclosure_screening?.self_disclosures.length ? (
            <PDFButton
              logDownload={logDownload}
              count={self_disclosure_screening.self_disclosures.length}
              uri={pdf?.download_uri}
            />
          ) : (
            <None>No self-disclosures</None>
          )}
          <Note>
            <strong>Important:</strong> Any self-disclosed information is
            provided directly by the candidate and has not been verified by
            Checkr.
          </Note>
        </>
      )}
    </M.Screening>
  );
};

export default SelfDisclosure;
