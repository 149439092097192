import React, { useCallback } from 'react';
import * as Entity from 'modules/assess/models/rules/count';
import { Kind } from 'modules/assess/models/rules/common/kind';
import { Context, CreateButton } from 'modules/assess/ui/rules/common/rule';
import { Namespace } from 'modules/assess/api';
import { useUser } from 'context/CurrentUser';
import { CreateComplexButton } from './rule/create-complex-button';
import { Container, TextSections } from '../common';
import RuleList from './RuleList';
import PremadeRules from '../common/premade-list';
import RuleLibrary from '../common/rule-library';
import { Actions } from '../../state/rules/count';
import { useDispatch } from '../../ruleset/version/store';

export type Props = {
  rules: Entity.List | Entity.Map;
  disabled?: boolean;
  namespace?: Namespace;
};

const View: React.FC<Props> = ({ rules, disabled, namespace }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const displayCreateComplexRule =
    user?.account?.assess_mvr_complex_rules &&
    namespace === Namespace.mvr &&
    !disabled;

  const create = useCallback(
    (template?: Omit<Entity.Type, 'id'>) => {
      const rule = { ...Entity.Count.initialize(), ...template };
      dispatch(Actions.Create.create(rule));
    },
    [dispatch],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ disabled }}>
      <Container data-testid='assess-rules-count-view'>
        <TextSections namespace={namespace} tkey='rules.count.description' />
        <PremadeRules namespace={namespace} kind={Kind.COUNT} />
        <RuleLibrary
          disabled={disabled}
          namespace={namespace}
          kind={Kind.COUNT}
          handleAddClick={create}
        />
        <div className='buttons'>
          <CreateButton
            data-testid='assess-rules-count-create-rule'
            create={create}
            disabled={disabled}
          />
          {displayCreateComplexRule && <CreateComplexButton />}
        </div>
        <RuleList rules={rules} />
      </Container>
    </Context.Provider>
  );
};

export default View;
