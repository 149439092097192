import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getScreeningStatus } from '@dashboard-experience/utils';
import {
  ReportProps,
  Document as CandidateDocument,
  EducationRecord,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { EDUCATION_PROOF_DOC_TYPES } from 'Constants';
import Document from '../Document';
import Record from './EducationRecord';
import { useCandidateDocuments } from '../Document/hooks';

const EducationVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { education_verification } = report;
  const { documents } = useCandidateDocuments(report?.candidate?.id as string);
  const filteredDocuments = useMemo(
    () =>
      documents.filter((document: CandidateDocument) =>
        EDUCATION_PROOF_DOC_TYPES.includes(document.type),
      ),
    [documents],
  );

  // some legacy education documents don't have a alternate_documentable_id
  // display these documents underneath the screening instead of grouped
  // by the EducationRecord.
  const shouldShowDocumentsUnderScreening = filteredDocuments.some(
    d => !d.alternate_documentable_id,
  );

  if (!education_verification) return null;

  const status = getScreeningStatus(education_verification, reportStatusType);

  const completed = !!education_verification?.completed_at;
  const educationRecords: EducationRecord[] = education_verification?.records;

  return (
    <M.Screening
      title='Education Verification'
      screening={education_verification}
      statusType={reportStatusType}
      id={SCREENING_TITLES.education_verification.id}
      role='list'
    >
      {educationRecords.length === 0 ? (
        <>No education history provided by candidate</>
      ) : (
        educationRecords.map((record: EducationRecord, index: number) => (
          <React.Fragment key={record.id}>
            <Record
              completed={!!education_verification?.completed_at}
              documents={filteredDocuments}
              record={record}
              status={status}
              statusType={reportStatusType}
            />
            {index < educationRecords.length - 1 && <M.Divider />}
          </React.Fragment>
        ))
      )}
      {completed &&
        shouldShowDocumentsUnderScreening &&
        filteredDocuments.map((document: CandidateDocument) => (
          <Document key={document.id} document={document} />
        ))}
    </M.Screening>
  );
};

export default EducationVerification;
