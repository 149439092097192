/* eslint-disable react/no-danger */
import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { scrollToTop } from 'utils';
import { CurrentUser } from '@dashboard-experience/utils';
import { StyledModalBody } from './AdverseActionsStyledComponents';
import NoEmailMessage from './NoEmailMessage';
import { useUser } from '../../context/CurrentUser';
import {
  useFetchCandidateReport,
  useFetchPostAdverseActionsNoticeTemplate,
  useSendPostAdverseActionNotice,
} from '../../api/adverseActions';
import { AdverseAction } from '../../types/report/adverseAction';

type Props = {
  closeModal: Function;
  adverseAction: AdverseAction;
};

const PostAdverseActionNoticeModal: React.FC<Props> = ({
  adverseAction,
  closeModal,
}) => {
  const currentUser: CurrentUser = useUser();
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const currentStep: number = 1;

  const { data: report } = useFetchCandidateReport(adverseAction.report_id);
  const { data: template, isSuccess: templateLoaded } =
    useFetchPostAdverseActionsNoticeTemplate(adverseAction.report_id);

  const sendPostAdverseActionNoticeClicked = useCallback(() => {
    setShowConfirmationMessage(true);
    scrollToTop();
  }, []);

  const { call, result } = useSendPostAdverseActionNotice(adverseAction.id);

  return (
    <M.ComposedModal
      onClose={closeModal}
      open
      size='lg'
      className='actions-modal'
    >
      <M.ModalHeader closeModal={closeModal}>
        <h2>Post-adverse action notice</h2>
      </M.ModalHeader>
      <StyledModalBody>
        <div>
          {result.isError && (
            <M.InlineNotification
              kind='error'
              title='Something went wrong, please try again later.'
            />
          )}

          {result.isSuccess && (
            <M.InlineNotification kind='success' title={result.data} />
          )}
        </div>

        {currentStep === 1 && (
          <div>
            {!currentUser.account?.adverse_action_email && (
              <div>
                <NoEmailMessage />
              </div>
            )}
            {currentUser.account?.adverse_action_email &&
              !showConfirmationMessage && (
                <div id='post-adverse-action-template'>
                  <p>
                    <strong>From: </strong>
                    {currentUser.account?.adverse_action_email}
                  </p>
                  <p>
                    <strong>via</strong> checkr.com
                  </p>
                  <p>
                    <strong>To:</strong> {report?.candidate.email}
                  </p>
                  <p>
                    <strong>Subject:</strong> Post-adverse action notice -{' '}
                    {currentUser.account?.name}
                  </p>
                  <br />
                  {templateLoaded && template && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: template,
                      }}
                    />
                  )}
                  {!templateLoaded && (
                    <M.LoadingInline description='loading email template...' />
                  )}
                  <br />
                </div>
              )}
          </div>
        )}
        {showConfirmationMessage && (
          <p>
            Are you sure you want to send this post-adverse action notice to the
            candidate?
          </p>
        )}
      </StyledModalBody>
      <M.ModalFooter>
        {currentStep === 1 && (
          <div>
            <M.Button onClick={closeModal} type='button'>
              Cancel
            </M.Button>

            {currentUser.account?.adverse_action_email &&
              !showConfirmationMessage && (
                <M.Button
                  disabled={result.isLoading}
                  kind='danger'
                  onClick={sendPostAdverseActionNoticeClicked}
                  type='button'
                >
                  {result.isLoading
                    ? 'Processing ...'
                    : 'Send Post-adverse action notice'}
                </M.Button>
              )}
            {showConfirmationMessage && (
              <M.Button
                type='button'
                kind='danger'
                onClick={call}
                disabled={result.isLoading}
              >
                {result.isLoading ? 'Processing ...' : 'Confirm'}
              </M.Button>
            )}
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <M.Button onClick={closeModal} type='button'>
              Close
            </M.Button>
          </div>
        )}
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default PostAdverseActionNoticeModal;
