enum ScreeningType {
  county_criminal_search = 'county_criminal_search',
  state_criminal_search = 'state_criminal_search',
  federal_criminal_search = 'federal_criminal_search',
  international_adverse_media_search = 'international_adverse_media_search',
  international_criminal_search_v2 = 'international_criminal_search_v2',
  international_motor_vehicle_report = 'international_motor_vehicle_report',
  county_civil_search = 'county_civil_search',
  federal_civil_search = 'federal_civil_search',
  motor_vehicle_report = 'motor_vehicle_report',
  drug_alcohol_clearinghouse = 'drug_alcohol_clearinghouse',
  facis_search = 'facis_search',
  employment_verification = 'employment_verification',
  education_verification = 'education_verification',
  personal_reference_verification = 'personal_reference_verification',
  professional_reference_verification = 'professional_reference_verification',
  professional_license_verification = 'professional_license_verification',
  pro_license_verification = 'pro_license_verification',
  international_education_verification = 'international_education_verification',
  international_employment_verification = 'international_employment_verification',
  international_global_watchlist_search = 'international_global_watchlist_search',
  international_identity_document_validation = 'international_identity_document_validation',
  international_right_to_work = 'international_right_to_work',
  drug_screening = 'drug_screening',
  occupational_health_screening = 'occupational_health_screening',
  credit_report = 'credit_report',
  global_watchlist_search = 'global_watchlist_search',
  ssn_trace = 'ssn_trace',
  sex_offender_search = 'sex_offender_search',
  national_criminal_search = 'national_criminal_search',
  identity_verification = 'identity_verification',
}

export default ScreeningType;
