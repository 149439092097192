import { useNamespacedRoute } from 'modules/assess/ui/router/context';
import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_INSTANCE } from 'modules/assess/models/mvr/category-code-set';

export type Props = {};

export const DefaultLink: React.FC<Props> = ({ children }) => {
  const path = useNamespacedRoute(`categories/${DEFAULT_INSTANCE}`);

  return <Link to={path}>{children}</Link>;
};

export default DefaultLink;
