import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

export type Props = {
  onCancel?: (event: SyntheticEvent) => void;
  onDelete: () => void;
};

export const Actions: React.FC<Props> = ({ onCancel, onDelete }) => {
  return (
    <>
      <Button kind='secondary' trans='verbs.cancel' onClick={onCancel} />
      <Button kind='danger' trans='verbs.delete' onClick={onDelete} />
    </>
  );
};

export default Actions;
