import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { v4 as uuid } from 'uuid';
import { useGetAllCandidates, Candidate } from 'api/i9';
import { useDebouncedCallback } from '@dashboard-experience/utils';
import TextCellContent from './TextCellContent';
import NewEmployeeBanner from './NewEmployeeBanner';
import AddEmployeeModal from './AddEmployeeModal';
import { IEmployee } from './Models';
import { isEmptyString, isRowDisabled } from './utils/patterns';
import {
  FlexRowAlignRight,
  I9OrderContainer,
  MContainerRowRightAlign,
  MContainerColRightAlign,
  MContainerRowNoPadding,
} from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const TableContainer = styled.div`
  .mastodon-table-container {
    width: 53rem;
  },
`;

const PER_PAGE = 25;
const searchFilterDelayMs = 1000;

const headerDefinitions = [
  {
    key: 'full_name',
    header: 'Full Name',
  },
  {
    key: 'email',
    header: 'Email',
  },
  {
    key: 'updated_at',
    header: 'Report last updated',
  },
];

let wasAdded: boolean = false;

type OrderI9Props = {
  handleCandidateSelection?: (selectedCandidates: any) => void;
  handleNextStep: () => void;
  candidatesData: Map<string, Candidate & { isAdded: boolean }>;
};

interface TableProps {
  rows: any[];
  headers: any[];
  getSelectionProps: any;
  isSelectionSaved: boolean;
  onCandidatesSelected: (
    value: { selectedCandidate: Candidate; checked: boolean }[],
  ) => void;
  mappedData: any[];
  selectAll: () => void;
  selectRow: (rowId: string) => void;
}

const Table: React.FC<TableProps> = ({
  rows,
  headers,
  getSelectionProps,
  onCandidatesSelected,
  mappedData,
  selectAll,
  selectRow,
}) => {
  const [selected, setSelected] = useState(false);
  const handleOnSelect = useCallback(
    (rowId?: string) => () => {
      if (rowId) {
        selectRow(rowId);
      } else {
        selectAll();
      }
      setSelected(true);
    },
    [selectAll, selectRow],
  );

  useEffect(() => {
    if (selected || wasAdded) {
      wasAdded = false;
      setSelected(false);
      onCandidatesSelected(
        rows.map(
          ({
            id: candidateId,
            isSelected,
          }: {
            id: string;
            isSelected: boolean;
          }) => ({
            selectedCandidate: mappedData.find(({ id }) => id === candidateId),
            checked: isSelected,
          }),
        ),
      );
    }
  }, [rows, onCandidatesSelected, mappedData, selected]);
  return (
    <TableContainer>
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableSelectAll
                {...getSelectionProps()}
                onSelect={handleOnSelect()}
              />
              {headers.map((header: any) => {
                return (
                  <M.TableHeader key={header.key}>
                    {header.header}
                  </M.TableHeader>
                );
              })}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((row: any) => {
              const rowProps = getSelectionProps({ row });
              return (
                <M.TableRow key={row.id}>
                  <M.TableSelectRow
                    {...rowProps}
                    onSelect={handleOnSelect(row.id)}
                  />
                  {row.cells.map((cell: any) => {
                    return (
                      <M.TableCell key={cell.id}>
                        <TextCellContent
                          row={row}
                          cell={cell}
                          disabled={rowProps.disabled}
                        />
                      </M.TableCell>
                    );
                  })}
                </M.TableRow>
              );
            })}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </TableContainer>
  );
};

const OrderI9: React.FC<OrderI9Props> = ({
  handleNextStep,
  handleCandidateSelection,
  candidatesData,
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [addedData, setAddedData] = useState([]);
  const [modalSubmitted, setModalSubmitted] = useState(false);
  const [searchFieldKey, setSearchFieldKey] = useState('100');

  const { data, isLoading } = useGetAllCandidates(
    currentPageIndex + 1,
    PER_PAGE,
    searchFilter,
  );

  const pageCount = Math.ceil((data?.count || 0) / PER_PAGE) || 1;

  const handlePageClick = useCallback(
    pageIndex => {
      setCurrentPageIndex(pageIndex);
    },
    [setCurrentPageIndex],
  );

  const debouncedSearch = useDebouncedCallback(
    value => {
      setSearchFilter(value);
    },
    searchFilterDelayMs,
    [],
  );

  const onSearchChange = useCallback(
    e => {
      setCurrentPageIndex(0);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const handleFormSubmit = useCallback(formValues => {
    // Handle the form values here
    setAddedData(formValues.employees);
    setShowModal(false);
    setModalSubmitted(true);
    wasAdded = true;
  }, []);

  const handleFormCheckEmail = useCallback(
    emailStr => {
      const count = Array.from(candidatesData.values())
        .map(c => c.email)
        .filter(email => email === emailStr).length;
      return count > 0;
    },
    [candidatesData],
  );

  useEffect(() => {
    setAddedData([]);
  }, [candidatesData]);

  // Pinning logic -- selected data should be shown on top of table
  // and regardless of paging or search
  const mappedData = useMemo(() => {
    // Need to map the data for better handling
    const mapData = (c: Candidate, isSelected: boolean) => ({
      ...c,
      id: c.candidate_id,
      full_name: c.full_name,
      email: c.email,
      disabled: isRowDisabled(c.has_form_i9, c.full_name),
      isSelected,
    });

    const date = new Date();
    const mapAddedData = (c: IEmployee) => ({
      candidate_id: c.id,
      id: c.id,
      first_name: c.firstName,
      middle_name: c.middleName,
      last_name: c.lastName,
      full_name: `${
        isEmptyString(c.middleName)
          ? c.firstName.concat(' ', c.lastName)
          : c.firstName.concat(' ', c.middleName, ' ', c.lastName)
      }`,
      date_of_birth: '',
      email: c.email,
      created_at: date.toISOString(),
      updated_at: 'N/A',
      has_form_i9: false,
      isAdded: true,
    });

    const addedCandidates = addedData.map(c => mapAddedData(c));

    addedCandidates.forEach(candidate => {
      candidatesData.set(candidate.candidate_id, candidate);
    });

    // Sorting logic -- selected and manually added data should be ordered
    // alphabetically on the top of the table, followed by the existing rows
    // (selected) ordered in the same way.

    return Array.from(candidatesData.values())
      .filter(c => c.isAdded)
      .sort((a, b) => a.full_name.localeCompare(b.full_name))
      .concat(
        Array.from(candidatesData.values())
          .filter(c => !c.isAdded)
          .sort((a, b) => a.full_name.localeCompare(b.full_name)),
      )
      .map(c => mapData(c, true))
      .concat(
        data?.data
          .filter(c => !candidatesData.has(c.candidate_id))
          .map(c => mapData(c, false)) || [],
      );
  }, [data?.data, candidatesData, addedData]);

  const renderTable = useCallback(
    (renderProps: any) => (
      <Table
        {...renderProps}
        onCandidatesSelected={handleCandidateSelection}
        mappedData={mappedData}
      />
    ),
    [handleCandidateSelection, mappedData],
  );

  const [showModal, setShowModal] = useState(false);

  const setModalOpen = useCallback(() => {
    setModalSubmitted(false);
    setShowModal(true);
  }, [setShowModal]);

  const hideModal = useCallback((): void => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (!showModal && modalSubmitted) {
      setSearchFieldKey(uuid());
      setCurrentPageIndex(0);
      debouncedSearch('');
    }
  }, [debouncedSearch, modalSubmitted, showModal]);

  const trackEvent = useTrackEvent();

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ORDER_CANDIDATE_SELECT_STARTED, {
      'Page Type': 'Order',
      'I9 Page Type': 'I9 Order Candidate Selection Page',
    });
  }, [trackEvent]);

  const handleSelectCandidatesCompleted = useCallback(() => {
    // Track event on component mount

    trackEvent(I9_EVENT_NAMES.I9_ORDER_CANDIDATE_SELECT_COMPLETED, {
      'Page Type': 'Order',
      'I9 Page Type': 'I9 Order Candidate Selection Page',
    });
    handleNextStep();
  }, [handleNextStep, trackEvent]);

  return (
    <>
      {showModal && (
        <AddEmployeeModal
          open={showModal}
          hideModal={hideModal}
          onFormSubmit={handleFormSubmit}
          onFormCheckEmail={handleFormCheckEmail}
        />
      )}
      <M.Grid>
        <I9OrderContainer>
          <M.Container.Row>
            <M.Container.Col>
              <h3>Who do you need to verify?</h3>
            </M.Container.Col>
          </M.Container.Row>
          <>
            <MContainerRowNoPadding>
              <M.Container.Col lg={5}>
                <M.Search
                  key={searchFieldKey}
                  onChange={onSearchChange}
                  placeholder='Search for full name or email address'
                  data-testid='order-i9-candidate-search'
                />
              </M.Container.Col>
              <MContainerColRightAlign>
                <M.Button
                  kind='tertiary'
                  onClick={setModalOpen}
                  data-testid='order-i9-add-employees'
                >
                  <M.Icon icon='Add' size={16} /> Add Employees
                </M.Button>
              </MContainerColRightAlign>
            </MContainerRowNoPadding>
            <M.Container.Row style={{ display: 'block' }}>
              <NewEmployeeBanner
                count={data?.count || 0}
                isLoading={isLoading}
                openModal={setModalOpen}
              />
            </M.Container.Row>
            <M.Container.Row>
              <M.DataTable
                headers={headerDefinitions}
                rows={mappedData}
                render={renderTable}
              />
            </M.Container.Row>
            <MContainerRowRightAlign>
              <M.Pagination
                pageCount={pageCount}
                selectedIndex={currentPageIndex}
                onPageClick={handlePageClick}
              />
            </MContainerRowRightAlign>
          </>
        </I9OrderContainer>
      </M.Grid>
      <FlexRowAlignRight gap='0' alignItems='center'>
        <M.Button
          kind='primary'
          onClick={handleSelectCandidatesCompleted}
          disabled={!candidatesData.size}
          data-id='order-i9-candidate-select-next-step'
          data-testid='order-i9-candidate-select-next-step'
        >
          Continue
        </M.Button>
      </FlexRowAlignRight>
    </>
  );
};

export default OrderI9;
