import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import UnassignedAvatar from 'modules/case-management/ui/shared/UnassignedAvatar';
import StyledUser from './StyledUser';

const StyledButton = styled.button`
  border-bottom: 1px solid ${colors.uiGrey200} !important;
`;

const UnassignOption = ({ onSelect }: any) => {
  const { t } = useTranslation('case-management');
  const text = t('case-management:tasks.user_select.unassign');

  const onClick = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  return (
    <StyledButton data-testid='unassign-option' type='button' onClick={onClick}>
      <StyledUser>
        <UnassignedAvatar />
        <span className='name unassign'>{text}</span>
      </StyledUser>
    </StyledButton>
  );
};

export default UnassignOption;
