import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useGetReport } from 'api/reports';
import { namespace } from 'modules/adjudication/locales';
import { useTranslation } from 'react-i18next';
import { Candidate } from 'types';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;

  h3 {
    color: ${colors.uiNavy600};
    font-size: 1.5rem !important;
  }

  svg {
    height: 4.5rem;
    margin-bottom: 1rem;
    width: auto;
    fill: ${colors.uiNavy600};
  }

  p {
    color: ${colors.uiTextPrimaryLight};
    padding: 0;
  }

  .cds--form-item.cds--checkbox-wrapper {
    margin-top: 1rem;
  }
`;

type Props = {
  setConfirm: Function;
  confirm: boolean;
};

const Content: React.FC<Props> = ({ setConfirm, confirm }) => {
  const {
    report: { candidate },
  } = useGetReport();
  const { t } = useTranslation();
  // remove hard typing of Candidate once Utils Candidate is fleshed out
  const { first_name, last_name } = candidate as Candidate;
  const names = {
    firstName: first_name,
    lastName: last_name,
  };

  const handleCheckboxClick = useCallback(() => {
    setConfirm(!confirm);
  }, [setConfirm, confirm]);

  return (
    <Styled>
      {/* <M.Icon icon='CheckmarkFilled' /> */}
      <p>
        {t(`${namespace}:report.actions.complete.modal.content_1`, names)}
        <strong>
          {t(`${namespace}:report.actions.complete.modal.content_1_1`, names)}
        </strong>
      </p>
      <br />
      <strong>
        {t(`${namespace}:report.actions.complete.modal.content_2`, names)}
      </strong>
      <M.Checkbox
        id='some'
        labelText={t(
          `${namespace}:report.actions.complete.modal.confirm_input_label`,
          names,
        )}
        onChange={handleCheckboxClick}
      />
    </Styled>
  );
};

export default Content;
