import { AccountsClient, Client } from 'api';
import {
  PaginatedPrograms,
  Program,
} from 'containers/AdjudicationMatrix/Directory/types';
import { PATH } from './constants';

const DEFAULT_PARAMS = {
  include: 'packages',
};

export type BaseParams = {
  accountId?: string;
  params?: { [key: string]: any };
  per_page?: number;
  page?: number;
};

export const list = ({
  accountId,
  params,
}: BaseParams): Promise<{ [key: string]: any }> => {
  return AccountsClient.get(`${accountId}/${PATH}`, {
    params: { ...DEFAULT_PARAMS, ...params },
  });
};

export const getPrograms = (params?: {
  [key: string]: any;
}): Promise<PaginatedPrograms> => {
  return Client.get('v1/programs', {
    params,
    headers: { cache: true },
  });
};

// Given a specific program ID, get the base details (Created, Name, Geo IDs, Package IDs)
export const getProgram = (params?: {
  [key: string]: any;
}): Promise<Program> => {
  return Client.get(`v1/programs/${params?.id}`, {
    headers: { cache: true },
  });
};

export const getAllPrograms = (
  allPrograms: Array<any>,
  page: number = 1,
): Promise<any> => {
  let fmtAllPrograms;
  return getPrograms({ page }).then(programs => {
    fmtAllPrograms = allPrograms.concat(programs.data);
    if (programs.next_href) {
      fmtAllPrograms = getAllPrograms(fmtAllPrograms, page + 1);
    }
    return fmtAllPrograms;
  });
};
