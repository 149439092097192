import { Client } from 'api';
import { AISummary, GenericObject, Report, WorkLocation } from 'types';
import { v4 as uuid } from 'uuid';
import { reportIncludes } from 'Constants';
import { PATH } from './constants';

const DEFAULT_PARAMS = {
  include: reportIncludes,
};

export type BaseParams = {
  reportId: string;
  params?: { [key: string]: any };
};

export const getReportExceptions = (
  reportId: string,
): Promise<GenericObject> => {
  return Client.get(`${PATH}/${reportId}/exceptions`);
};

export const getWithMultiMvr = ({
  reportId,
  params,
}: BaseParams): Promise<GenericObject> => {
  return Client.get(`${PATH}/${reportId}/dashboard`, {
    headers: { Accept: 'application/vnd.checkr.multimvr+json' },
    params: { ...DEFAULT_PARAMS, ...params },
  });
};

export const getAdverseActionable = (
  reportId: String,
): Promise<GenericObject> => {
  // get report from api
  return Client.get(`${PATH}/${reportId}/adverse_actionable`);
};

export const getScreeningAdditionalInformation = (
  reportId: string,
): Promise<GenericObject> => {
  return Client.get(`/reports/${reportId}/screening_additional_information`);
};

// get associated addresses from api
export const getAssociatedAddresses = (
  reportId: string,
): Promise<GenericObject> => {
  return Client.get(`/v1/reports/${reportId}/addresses`);
};

export type OrderNewReportParams = {
  candidate_id: string;
  package: string;
  geo_ids?: string[];
  permissible_purpose?: string;
  node?: string;
  work_locations?: WorkLocation[];
};

export const orderNewReport = (
  params: OrderNewReportParams,
): Promise<Report> => {
  return Client.post('/v1/reports', params, {
    headers: { 'Idempotency-Key': uuid() },
  });
};

export const orderNewReportViaAddChecks = (
  params: OrderNewReportParams,
): Promise<Report> => {
  return Client.post('/v1/reports?origin=add_checks_web_flow', params, {
    headers: { 'Idempotency-Key': uuid() },
  });
};

export const upgradeReport = (
  reportId: string,
  packageSlug: string,
): Promise<{ [key: string]: any }> => {
  const body = {
    package: packageSlug,
  };
  return Client.post(`v1/reports/${reportId}`, body);
};

export const getAISummary = (reportId: string): Promise<AISummary> => {
  return Client.get(`v1/reports/${reportId}/ai_summary`);
};
