import React, { useContext, useEffect } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY } from 'Constants';
import {
  useTrackEvent,
  PACKAGE_BUILDER_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';
import UIContext from 'context/UI';
import UserContext from 'context/CurrentUser';
import { CreatePackage } from 'components/Packages';
import { CreatePackageContextProvider } from 'components/Packages/CreatePackage/Context';
import { hasPermission } from '@dashboard-experience/utils';
import { Redirect } from 'react-router-dom';

const CreatePackagePage = () => {
  const currentUser = useContext(UserContext);
  const { contextId } = useContext(UIContext);
  const trackEvent = useTrackEvent();

  const parentWindowNavigationToStartOfFlowFromCallToActionComplete = contextId
    ? ['/get-started', '/select-package', '/customize'].some(path =>
        contextId?.includes(path),
      )
    : true;

  const canManagePackages = hasPermission(currentUser, 'manage_packages');

  const enableCreateNewPackageFlow =
    useFlag(ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY)?.variantKey === 'true';

  const canEnterCreatePackageFlow =
    canManagePackages && enableCreateNewPackageFlow;

  useEffect(() => {
    trackEvent(
      currentUser,
      PACKAGE_BUILDER_EVENT_NAMES?.PACKAGE_BUILDER_CREATE_NEW_PACKAGE_VIEWED,
    );
  }, [trackEvent, currentUser]);

  return (
    <CreatePackageContextProvider currentUser={currentUser}>
      {parentWindowNavigationToStartOfFlowFromCallToActionComplete &&
        // do not remove this disable
        // eslint-disable-next-line no-nested-ternary
        (canEnterCreatePackageFlow ? (
          <CreatePackage />
        ) : canManagePackages ? (
          <Redirect to='/packages' />
        ) : (
          <Redirect to='/candidates' />
        ))}
    </CreatePackageContextProvider>
  );
};

export default CreatePackagePage;
