import { Report, ReportProps } from 'types';
import * as S from 'components/Report/Screenings';
import ExamReorderPanel from 'components/Report/Screenings/DrugHealthScreenings/ExamReorder/ExamReorderPanel';
import { isV2DrugScreening } from 'components/Report/Screenings/DrugHealthScreenings/utils';
import Tabs from './variations';

export type TabComponent = {
  components: readonly React.FC<ReportProps>[];
  when: (report: Report) => boolean;
};

export const TabComponents: { [key: string]: TabComponent } = {
  [Tabs.BACKGROUND_REPORT]: {
    components: Object.freeze([
      S.SelfDisclosure,
      S.IdentityVerification,
      S.SSNTrace,
      S.EvictionSearch,
      S.SexOffenderSearch,
      S.TerroristWatchlist,
      S.InternationalCriminalSearches,
      S.NationalCriminalSearch,
      S.StateCriminalSearches,
      S.PointerStateCriminalSearches,
      S.FederalDistrictCriminalSearch,
      S.FederalDistrictCivilSearch,
      S.FederalCriminalSearch,
      S.FederalCivilSearch,
      S.CountyCriminal,
      S.CountyCivil,
      S.MunicipalCriminalSearches,
      S.InternationalRightToWork,
      S.InternationalAdverseMediaSearches,
      S.InternationalGlobalWatchlistSearch,
      S.InternationalCriminalSearchesV2,
      S.InternationalEducationVerification,
      S.InternationalEmploymentVerification,
      S.InternationalIdentityDocumentValidation,
      S.InternationalCanadaBCEnhancedCriminalSearch,
      S.InternationalMotorVehicleReports,
      S.ChildAbuse,
      S.MultiMotorVehicleReport,
      S.EmploymentVerification,
      S.EducationVerification,
      S.ProfessionalLicenseVerification,
      S.PersonalReferenceVerification,
      S.ProfessionalReferenceVerification,
      S.PhotoVerification,
      S.Facis,
      S.ArrestSearch,
      S.Clearinghouse,
      S.StatementOfDispute,
      S.PublicComments,
      S.HealthcareSanctionsSearches,
    ]),
    when: () => true,
  },
  [Tabs.DRUG_SCREENING]: {
    components: Object.freeze([S.DrugScreening]), // v1 drug screening
    when: (report: Report) =>
      !!report.drug_screening && !isV2DrugScreening(report),
  },
  [Tabs.HEALTH_SCREENING]: {
    components: Object.freeze([
      ExamReorderPanel,
      S.DrugScreening, // v2 drug screening
      S.OccHealthScreening,
    ]),
    when: (report: Report) =>
      (!!report.drug_screening && isV2DrugScreening(report)) ||
      !!report.occupational_health_screening,
  },
  [Tabs.CREDIT_REPORT]: {
    components: Object.freeze([S.CreditReport, S.InternationalCreditReport]),
    when: (report: Report) =>
      !!report.credit_report || !!report.international_credit_report,
  },
  [Tabs.DRUG_ALCOHOL_CLEARINGHOUSE]: {
    components: Object.freeze([S.DrugAlcoholClearinghouse]),
    when: (report: Report) => !!report.drug_alcohol_clearinghouse,
  },
};
