import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUpdateCandidate } from 'api/candidate';
import { FieldComponentProps } from '../types';
import { CandidateInfoInPlaceContainer } from '../CandidateInformationStyledComponents';

const CustomId: React.FC<FieldComponentProps> = ({
  fieldKey,
  displayName,
  allowCandidateEdit,
  candidate,
}) => {
  const { custom_id: customId, id: candidateId } = candidate;
  const { updateCandidateCall } = useUpdateCandidate(fieldKey, displayName);

  /**
   * @name onCustomIdEditSubmit
   * @function
   * @memberOf Email
   * @description Handler for edit email submit
   * @returns {promise}
   * @param {string} newCustomIdValue - The new value entered by the user
   */
  const onCustomIdEditSubmit = useCallback(
    (newCustomIdValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          custom_id: newCustomIdValue,
        });
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  return (
    <CandidateInfoInPlaceContainer>
      <M.InPlaceTextEditor
        allowEdit={allowCandidateEdit}
        ariaLabel='candidate-custom-id'
        initialDisplayValue={customId || '-'}
        initialValue={customId || ''}
        inputId='candidate-custom-id'
        submitCallback={onCustomIdEditSubmit}
        data-testid='candidate-information-field-custom_id-in-place-text-editor'
      />
    </CandidateInfoInPlaceContainer>
  );
};

export default CustomId;
