import {
  NAV_COLLAPSE_TRANSITION,
  NAV_SIZES,
} from 'modules/layout/navigation/constants';
import React from 'react';
import styled, { css } from 'styled-components';

export const BodyContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: visible;
  width: 100%;
`;

export const MainContent = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  margin-top: 0;

  overflow-y: auto;
  overflow-x: clip;
  height: 100vh;

  @media (min-width: 768px) {
    align-items: stretch;
  }
`;

type ContentNewProps = {
  removePadding: boolean;
};

export const Content = styled.div<ContentNewProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: ${({ removePadding }) =>
    removePadding ? '0' : '4.5rem 1.5rem 1rem'};

  @media (min-width: 768px) {
    padding: ${({ removePadding }) => (removePadding ? '0' : '3rem 4rem')};
  }
`;

export const FlexGrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

type ContentWrapNewProps = {
  navCollapsed: boolean;
};
export const ContentWrap = styled.div<ContentWrapNewProps>`
  position: relative;
  left: 0;
  transition: left 0.35s ease;

  display: flex;
  flex-direction: column;

  transition: width ${NAV_COLLAPSE_TRANSITION};
  width: calc(100% - ${NAV_SIZES.collapsed});

  @media (min-width: 768px) {
    ${({ navCollapsed }) =>
      !navCollapsed &&
      css`
        width: calc(100% - ${NAV_SIZES.expanded});
      `}
  }
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Body: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <BodyContainer>{children}</BodyContainer>;
};
