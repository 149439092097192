import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useGetEnrollments } from 'api/continuousChecks';
import { Text } from 'components/ContinuousServices/Text';
import pluralize from 'pluralize';
import EnrollmentsTable, { Enrollments } from './EnrollmentsTable';

const EnrollmentsView: React.FC = () => {
  const PAGE_SIZE = 20;
  const [page, setPage] = useState(0);
  const { isLoading, data } = useGetEnrollments(page + 1, PAGE_SIZE);

  const handlePaginationClick = useCallback(
    pageIndex => {
      setPage(pageIndex);
    },
    [setPage],
  );

  const tableRows = (data?.data || []).map(
    ({ resource_id, candidate_id, full_name, ...body }: any) => ({
      ...body,
      id: resource_id,
      worker: { full_name, candidate_id },
    }),
  ) as Enrollments[];

  const pageTotal = data?.meta.page.total || 0;
  const pageCount = Math.ceil(pageTotal / PAGE_SIZE);

  if (isLoading) {
    return <M.LoadingInline description='Loading data...' />;
  }
  return (
    <>
      {tableRows.length === 0 ? (
        <Text data-testid='no-enrollments-text' style={{ marginLeft: '1rem' }}>
          <span>
            No active enrollments. You can enroll people directly through their
            report pages or by using <strong>Bulk action</strong>.
          </span>
        </Text>
      ) : (
        <>
          {!isNaN(pageTotal) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                paddingBottom: '1rem',
              }}
            >
              <div
                data-testid='enrollments-total-results'
                style={{ marginRight: '1rem' }}
              >{`${pageTotal} ${pluralize('result', pageTotal)}`}</div>
            </div>
          )}
          <EnrollmentsTable tableRows={tableRows} />
          <M.Pagination
            data-testid='enrollments-pagination-links'
            pageCount={pageCount}
            selectedIndex={page}
            onPageClick={handlePaginationClick}
          />
        </>
      )}
    </>
  );
};

export default EnrollmentsView;
