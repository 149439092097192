import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';

const TABS = {
  0: 'tasks',
  1: 'settings',
};

export const TabsNav: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = Object.values(TABS).indexOf(
    location.pathname.split('/').pop() || 0,
  );

  const user = useUser();
  const canCMManageAll = user?.permissions?.case_management_manage_all;

  const onChange = useCallback(
    ({ selectedIndex }: { selectedIndex: keyof typeof TABS }) => {
      history.push(`/cm/${TABS[selectedIndex]}`);
    },
    [history],
  );

  const { t } = useTranslation('case-management');
  const settingsTabText = t('tabs.settings');
  const tasksTabText = t('tabs.tasks');

  return (
    <div data-testid='case-management-tabs-nav'>
      <M.Tabs onChange={onChange} selectedIndex={currentTab}>
        <M.TabList>
          <M.Tab>{tasksTabText}</M.Tab>
          {canCMManageAll && <M.Tab>{settingsTabText}</M.Tab>}
        </M.TabList>
      </M.Tabs>
    </div>
  );
};

export default TabsNav;
