import React from 'react';
import InternationalCriminalSearch from './CriminalRecordsScreenings/InternationalCriminalSearch';
import GlobalWatchlistSearch from './CriminalRecordsScreenings/GlobalWatchlistSearch';
import {
  AccordionItem,
  ActionPropsProps,
  CategoryTitle,
  Icon,
} from '../../shared/ListItemElements';
import courtWeights from '../../shared/svg/court-weights.svg';

type CriminalRecordsSearchItemProps = {
  screeningTypesInSearch: string[];
  actionProps: ActionPropsProps;
};
const CriminalRecordsSearchesItem = ({
  screeningTypesInSearch,
  actionProps,
}: CriminalRecordsSearchItemProps) => {
  return (
    <AccordionItem
      open={
        (screeningTypesInSearch?.includes('international_criminal_search_v2') &&
          screeningTypesInSearch?.includes(
            'international_adverse_media_search',
          )) ||
        screeningTypesInSearch?.includes(
          'international_global_watchlist_search',
        )
      }
      title={
        <CategoryTitle data-testid='international-criminal-records-title'>
          <Icon src={courtWeights} className='screening-icon' /> Criminal
          Records Searches
        </CategoryTitle>
      }
    >
      <ul>
        <InternationalCriminalSearch
          shouldOpen={
            screeningTypesInSearch?.includes(
              'international_criminal_search_v2',
            ) &&
            screeningTypesInSearch?.includes(
              'international_adverse_media_search',
            )
          }
          actionProps={actionProps}
        />
        <GlobalWatchlistSearch
          shouldOpen={screeningTypesInSearch?.includes(
            'international_global_watchlist_search',
          )}
          actionProps={actionProps}
        />
      </ul>
    </AccordionItem>
  );
};

export default CriminalRecordsSearchesItem;
