import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';

export type Props = {
  disabled?: boolean;
  onCancel?: (event: SyntheticEvent) => void;
  onDelete: () => void;
};

export const Actions: React.FC<Props> = ({ disabled, onCancel, onDelete }) => {
  return (
    <>
      <Button
        id='assess-ruleset-version-delete-actions-cancel-button'
        kind='secondary'
        trans='verbs.cancel'
        onClick={onCancel}
      />
      <Button
        id='assess-ruleset-version-delete-actions-delete-button'
        disabled={disabled}
        kind='danger'
        trans='verbs.delete'
        onClick={onDelete}
      />
    </>
  );
};

export default Actions;
