import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment';
import _ from 'lodash';
import { useDownloadContinuousCheckFile } from 'actions';
import StatusBadge from './StatusBadge';

const DownloadLink = styled(M.Link)`
  display: block;
  flex: 1;
  min-width: 2.6rem;
  max-width: 4rem;
  color: ${colors.uiNavy600};
  &:hover,
  &:visited {
    color: ${colors.uiNavy700};
  }
`;

const StyledCell = styled(M.TableCell)`
  padding-right: 2rem !important;
  white-space: pre-line;
`;

const pastTenseAction = action => {
  let display_value;

  if (action === 'update') {
    display_value = action.concat('d');
  } else {
    display_value = action.concat('ed');
  }

  return _.capitalize(display_value);
};

const handleDownloadClick = (call, e) => {
  e.preventDefault();
  const { redirectUrl } = e.target.dataset;
  call(redirectUrl);
};

const BulkActionTableCell = ({ header, row, cell, currentUser }) => {
  const { call } = useDownloadContinuousCheckFile();
  const downloadClick = useCallback(e => handleDownloadClick(call, e), [call]);

  let cellValue;
  switch (header.name) {
    case 'status':
      cellValue = (
        <StyledCell key={cell.id}>
          <StatusBadge status={row.status} text={`${row.status}`} />
        </StyledCell>
      );
      break;
    case 'date':
      cellValue = (
        <StyledCell key={cell.id}>
          {moment(row.created_at).utc().calendar('L')}
        </StyledCell>
      );
      break;
    case 'action':
      cellValue = (
        <StyledCell key={cell.id}>{pastTenseAction(row.action)} </StyledCell>
      );
      break;
    case 'product':
      cellValue = (
        <StyledCell key={cell.id}> {row.subscription_type} </StyledCell>
      );
      break;
    case 'file':
      cellValue = (
        <StyledCell key={cell.id}>
          <DownloadLink
            href='#'
            rel='noopener noreferrer'
            data-account-id={currentUser.account.id}
            data-document-id={row.id}
            data-redirect-url={row.download_uri}
            onClick={downloadClick}
          >
            bulk_action.csv
          </DownloadLink>
        </StyledCell>
      );
      break;
    case 'uploaderEmail':
      cellValue = <StyledCell key={cell.id}>{row.uploader_email} </StyledCell>;
      break;
    case 'errors':
      cellValue = (
        <StyledCell key={cell.id}>
          <DownloadLink
            href='#'
            rel='noopener noreferrer'
            data-account-id={currentUser.account.id}
            data-document-id={row.id}
            data-redirect-url={row.error_csv_download_uri}
            onClick={downloadClick}
          >
            {row.error_csv_download_uri ? 'errors.csv' : ''}
          </DownloadLink>
        </StyledCell>
      );
      break;
    default:
  }
  return cellValue;
};

export default BulkActionTableCell;
