import { ManualBulkUploadRow } from './types';

export const CsvHeaders: Array<keyof ManualBulkUploadRow> = [
  'first_name',
  'last_name',
  'middle_name',
  'date_of_birth',
  'ssn',
  'zip',
  'phone',
  'email',
];

export const CsvReg: {
  [key: string]: RegExp;
} = {
  first_name: /^(?=.*[A-Za-zÀ-ÿ])[A-Za-zÀ-ÿ'"`0-9. -]+$/i,
  last_name: /^(?=.*[A-Za-zÀ-ÿ])[A-Za-zÀ-ÿ'"`0-9. -]+$/i,
  middle_name: /^[a-zÀ-ÿA-Z0-9 -]*$/i,
  date_of_birth: /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/i,
  ssn: /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/i,
  zip: /^\d{5}$/i,
  phone: /^(?!(\+|00)\d{1,4}\s?)?[\d\s-]*$/i,
  email: /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})*$/i,
};
