import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Flex from 'components/Flex';
import { US_STATES_NAMES } from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import { Icon, InitialValidations } from '../../../shared/ModalComponents';

const Container = styled.div`
  width: 576px;
  .cds--text-input .cds--list-box__field {
    border-bottom: 1px solid ${colors.uiGrey300} !important;
  }
`;

const TypicalTime = styled.div`
  display: flex;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandSlate7};
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.brandSlate2};
  margin-bottom: 20px;
  margin-top: 10px;
`;

const EmployerRepresentative = styled.div`
  margin-bottom: 26px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: ${colors.brandNavy4};
  margin-bottom: 8px;
`;
const SubHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.brandNavy4};
  margin-top: 25px;
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandSlate6} !important;
  margin-bottom: 17px;
`;

const MarginRight24 = styled.div`
  margin-right: 24px;
`;

const MarginBottom15 = styled.div`
  margin-bottom: 15px;
`;

const TextInput = styled(M.TextInput)`
  width: 276px;
`;

const StreetTextInput = styled(M.TextInput)`
  width: 576px;
  margin-bottom: 17px !important;
`;

const AddressTextInput = styled(M.TextInput)`
  width: 176px;
`;

const StateDropdown = styled(M.Dropdown)`
  width: 176px;
`;

const CompanyAddress = styled.div``;

export type OccupationalHealthPageOneStateType = {
  name: string;
  email: string;
  phoneNumber: string;
  street: string;
  city: string;
  zip: string;
  state: string;
};

type OccupationalHealthSetupPageOneProps = {
  state: OccupationalHealthPageOneStateType;
  setState: Function;
  validationWarnings: InitialValidations;
};

const OccupationalHealthSetupPageOne: React.FC<
  OccupationalHealthSetupPageOneProps
> = ({ state, setState, validationWarnings }) => {
  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, name: e.target.value });
    },
    [setState, state],
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, email: e.target.value });
    },
    [setState, state],
  );

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, phoneNumber: e.target.value });
    },
    [setState, state],
  );

  const handleStreetChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, street: e.target.value });
    },
    [setState, state],
  );

  const handleCityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, city: e.target.value });
    },
    [setState, state],
  );

  const handleZipChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, zip: e.target.value });
    },
    [setState, state],
  );

  const handleStateChange = useCallback(
    ({ selectedItem }) => {
      setState({ ...state, state: selectedItem });
    },
    [setState, state],
  );

  return (
    <Container>
      <TypicalTime>
        <Icon className='screening-icon' data-testid='occupational-health-icon'>
          <M.Icon icon='Calendar' size='20' />
        </Icon>
        Setup typically takes 7-10 business days
      </TypicalTime>
      <EmployerRepresentative>
        <Header>Enter contact information</Header>
        <SubHeader>Contact person</SubHeader>
        <Description>
          Enter contact info for the person responsible for setup.
        </Description>
        <Flex>
          <TextInput
            data-testid='occupational-health-contact-name'
            labelText='Contact name'
            onChange={handleNameChange}
            placeholder='Ex: Joseph Johnson'
            value={state.name}
            invalid={!validationWarnings.name?.isValid}
            invalidText={validationWarnings.name?.error}
            maxLength='150'
          />
          <MarginRight24 />
          <TextInput
            data-testid='occupational-health-email-address'
            labelText='Email address'
            onChange={handleEmailChange}
            placeholder='Ex: joe@email.com'
            type='email'
            value={state.email}
            invalid={!validationWarnings.email?.isValid}
            invalidText={validationWarnings.email?.error}
          />
        </Flex>
        <MarginBottom15 />
        <TextInput
          data-testid='occupational-health-phone-number'
          labelText='Phone number'
          onChange={handlePhoneNumberChange}
          placeholder='Ex: (123) 456-7890'
          type='tel'
          value={state.phoneNumber}
          invalid={!validationWarnings.phoneNumber?.isValid}
          invalidText={validationWarnings.phoneNumber?.error}
        />
      </EmployerRepresentative>
      <CompanyAddress>
        <Header>Company address</Header>
        <Description>PO boxes aren&apos;t accepted.</Description>
        <StreetTextInput
          data-testid='occupational-health-street'
          labelText='Street'
          onChange={handleStreetChange}
          placeholder='Street name'
          value={state.street}
          invalid={!validationWarnings.street?.isValid}
          invalidText={validationWarnings.street?.error}
        />
        <Flex>
          <AddressTextInput
            data-testid='occupational-health-city'
            labelText='City'
            onChange={handleCityChange}
            placeholder='City'
            value={state.city}
            invalid={!validationWarnings.city?.isValid}
            invalidText={validationWarnings.city?.error}
          />
          <MarginRight24 />
          <AddressTextInput
            data-testid='occupational-health-zipcode'
            labelText='ZIP code'
            onChange={handleZipChange}
            placeholder='ZIP code'
            value={state.zip}
            invalid={!validationWarnings.zipcode?.isValid}
            invalidText={validationWarnings.zipcode?.error}
          />
          <MarginRight24 />
          <StateDropdown
            data-testid='occupational-health-state'
            label='State'
            onChange={handleStateChange}
            titleText='State'
            items={US_STATES_NAMES}
            selectedItem={state.state}
            invalid={!validationWarnings.state?.isValid}
            invalidText={validationWarnings.zipcode?.error}
          />
        </Flex>
      </CompanyAddress>
    </Container>
  );
};

export default OccupationalHealthSetupPageOne;
