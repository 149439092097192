import React from 'react';
import moment from 'moment';
import { GenericObject } from 'types';
import { CellProps } from './types';
import { mostRecent } from './utils';

const CompletedAt: React.FC<CellProps> = ({ candidate, report }) => {
  const { reports } = candidate;
  const mostRecentReport: GenericObject | undefined = mostRecent(reports);
  const displayedReport = report || mostRecentReport;
  const eta: string | undefined = displayedReport?.estimated_completion_time;

  const completedAt = eta ? moment(eta).format('MMM D, YYYY') : '';

  return <span>{completedAt}</span>;
};

export default CompletedAt;
