import React from 'react';

import {
  ContextContainer,
  WebhookContainer,
  ApiKeysContainer,
} from 'containers';
import Grid from './DeveloperSettingsGrid';

const DeveloperSettingsPage = () => (
  <Grid data-floating-menu-container>
    <ApiKeysContainer />
    <WebhookContainer />
  </Grid>
);

export default ContextContainer(DeveloperSettingsPage);
