import { useList as useReportExpungements } from 'api/expungements';
import { Report } from 'types';

const useExpungements = (report: Report) => {
  const countyCrim = report?.county_criminal_searches;
  const response = useReportExpungements({
    reportId: report?.id,
    hasCountyCriminalSearches: !!(countyCrim && countyCrim.length > 0),
  });
  return response?.data?.data || [];
};

export default useExpungements;
