import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getScreeningStatus } from '@dashboard-experience/utils';
import { GenericObject } from 'types';
import {
  formatCertificationExpiration,
  getCertificationStatus,
  getCertificationStatusDetails,
  getCertificationStatusText,
  isManualEntry,
  isResultExpired,
} from './Helpers';
import { InfoStatusIcon } from './PLVStyledComponents';

type Props = {
  certification: GenericObject;
  reportStatusType: string;
};

/**
 * @name getCertificationInfoList
 * @function
 * @memberOf ProfessionalLicenseVerification/CertificationInfo
 * @description Returns an array formatted for componsumption by M.KeyValueList
 * @returns {array}
 * @param {object} certification - The certification object
 */
const getCertificationInfoList = (certification: GenericObject) => {
  const { result } = certification;
  const status = getCertificationStatus(result);
  const statusText = getCertificationStatusText(result);
  const expiration = formatCertificationExpiration(result);
  const list: GenericObject[] = [];
  list.push({
    itemKey: 'License status',
    itemValue: (
      <>
        <InfoStatusIcon icon={status} /> <strong>{statusText}</strong>{' '}
        {isManualEntry(result) && <br />}
        {getCertificationStatusDetails(result)}
      </>
    ),
  });
  if (!isManualEntry(result)) {
    list.push({
      itemKey: 'Expiration date',
      itemValue: (
        <>
          {isResultExpired(result) ? (
            <strong>{expiration}</strong>
          ) : (
            <>{expiration}</>
          )}
        </>
      ),
    });
  }
  return list;
};

const Info: React.FC<Props> = ({ certification, reportStatusType }) => {
  const { result } = certification;
  const { certification_issuer } = result;
  const certificationStatus = {
    status: getCertificationStatus(result),
  };
  return (
    <>
      <M.Screening.Heading
        headingLeft={{
          name: certification_issuer,
          strong: true,
        }}
        headingRight={
          <M.StatusBadge
            status={getScreeningStatus(certificationStatus, reportStatusType)}
            statusType={reportStatusType}
          />
        }
      />
      <M.KeyValueList
        items={getCertificationInfoList(certification)}
        data-testid='screenings-professional-license-verification-info-list'
      />
    </>
  );
};

export default Info;
