import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type Map = { [key: string]: any };

const BoldLink = styled.a`
  font-weight: bold;
`;

const BlueHighlight = styled.span`
  color: ${colors.uiAqua500};
`;

const GrayText = styled.span`
  color: ${colors.uiGrey700};
`;

const BanTheBox = (
  <BoldLink
    href='https://checkr.com/blog/evolution-of-ban-the-box'
    target='_blank'
    rel='noopener noreferrer'
  >
    ban-the-box
  </BoldLink>
);

const IndividualizedAssessment = (
  <BoldLink
    href='https://help.checkr.com/hc/en-us/articles/217481048-Individualized-assessment-how-you-consider-adverse-information'
    target='_blank'
    rel='noopener noreferrer'
  >
    individualized assessment
  </BoldLink>
);

const BENEFITS_COMPONENTS = {
  'blue-highlight': <BlueHighlight />,
  quote: <p className='p1' />,
};

const ENGAGEMENT_CANDIDATE_STORIES = {
  'learn-more': (
    <BoldLink
      href='https://checkr.com/blog/candidate-stories-in-checkr-dashboard '
      target='_blank'
      rel='noopener noreferrer'
    >
      Learn more
    </BoldLink>
  ),
  text: <GrayText />,
};

const ENGAGEMENT_EXPLORE_COMPONENTS = {
  'learn-more': (
    <BoldLink
      href='https://checkr.com/company/mission/fair-chance-hub '
      target='_blank'
      rel='noopener noreferrer'
    >
      Learn more
    </BoldLink>
  ),
  text: <GrayText />,
};

const ENGAGEMENT_COMPONENTS = {
  assess: (
    <BoldLink
      href='https://checkr.com/demo'
      target='_blank'
      rel='noopener noreferrer'
    >
      Checkr Assess
    </BoldLink>
  ),
  candidate_stories: ENGAGEMENT_CANDIDATE_STORIES,
  explore: ENGAGEMENT_EXPLORE_COMPONENTS,
};

const HOW_TO_EVALUATE_BACKGROUND_CHECKS_ATTENTION_COMPONENTS = {
  test: (
    <BoldLink
      href='https://checkr.com/blog/best-practices-for-individualized-assessments'
      target='_blank'
      rel='noopener noreferrer'
    >
      Research has also shown
    </BoldLink>
  ),
};

const HOW_TO_EVALUATE_BACKGROUND_CHECKS_PROCESS_COMPONENTS = {
  'ban-the-box': BanTheBox,
  'individualized-assessment': IndividualizedAssessment,
};

const HOW_TO_EVALUATE_BACKGROUND_CHECKS_COMPONENTS = {
  attention: HOW_TO_EVALUATE_BACKGROUND_CHECKS_ATTENTION_COMPONENTS,
  process: HOW_TO_EVALUATE_BACKGROUND_CHECKS_PROCESS_COMPONENTS,
};

const INTRO_COMPONENTS = {
  'fair-chance-link': (
    <BoldLink
      href='https://checkr.com/wp-content/uploads/2021/08/How-to-Be-a-Fair-Chance-Employer-Checkr.pdf'
      target='_blank'
      rel='noopener noreferrer'
    >
      Research has also shown
    </BoldLink>
  ),
};

const STATE_REQUIREMENTS_COMPONENTS = {
  'ban-the-box': BanTheBox,
  'individualized-assessment': IndividualizedAssessment,
};

export const TRANSLATION_COMPONENTS: Map = {
  benefits: BENEFITS_COMPONENTS,
  engagement: ENGAGEMENT_COMPONENTS,
  how_to_evaluate_background_checks:
    HOW_TO_EVALUATE_BACKGROUND_CHECKS_COMPONENTS,
  intro: INTRO_COMPONENTS,
  state_requirements: STATE_REQUIREMENTS_COMPONENTS,
};

export default TRANSLATION_COMPONENTS;
