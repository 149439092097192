export enum ParamKeys {
  PAGE = 'page',
  PER_PAGE = 'per_page',
  FULL_NAME = 'full_name',
  ORDER_BY = 'order_by',
  CREATED_AT = 'created_at',
  CREATED_BEFORE = 'created_before',
  CREATED_AFTER = 'created_after',
  SORT = 'sort',
  STATUS = 'status',
  TEST = 'test',
}

export const numericParamKeys = [ParamKeys.PAGE, ParamKeys.PER_PAGE];
export const stringParamKeys = [
  ParamKeys.FULL_NAME,
  ParamKeys.ORDER_BY,
  ParamKeys.CREATED_AT,
  ParamKeys.CREATED_BEFORE,
  ParamKeys.CREATED_AFTER,
  ParamKeys.SORT,
  ParamKeys.STATUS,
  ParamKeys.TEST,
];
