import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Icon, { ActionMap } from 'modules/assess/ui/common';
import RemoveDialog from 'modules/assess/ui/rules/common/rule/viewer/remove';
import { postMessageToDashboard } from 'utils';

type Props = Pick<ActionMap, 'copy' | 'edit' | 'remove'> & {
  disabled?: Boolean;
  editable?: Boolean;
  positionDown?: () => void;
  positionUp?: () => void;
};

const Container = styled.div`
  display: flex;
  > * {
    margin-left: 1em;
    margin-right: 1em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Component: React.FC<Props> = ({
  copy,
  disabled,
  edit,
  editable,
  positionDown,
  positionUp,
  remove,
}) => {
  const [open, show] = useState(false);

  const handleClick = useCallback(() => {
    postMessageToDashboard({
      messageId: 'scroll_to_top',
    });
    show(true);
  }, [show]);
  const handleClose = useCallback(() => show(false), [show]);

  if (disabled || !editable) {
    return null;
  }

  return (
    <>
      <Container>
        {positionDown && positionUp ? (
          <>
            <Icon name='positionDown' tooltip={false} onClick={positionDown} />
            <Icon name='positionUp' tooltip={false} onClick={positionUp} />
          </>
        ) : null}
        <Icon name='copy' onClick={copy} />
        <Icon name='edit' onClick={edit} />
        <Icon name='remove' onClick={handleClick} />
      </Container>
      <RemoveDialog open={open} onDelete={remove} onClose={handleClose} />
    </>
  );
};

export default Component;
