import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Cell from './Cell';

export const StyledEmail = styled.p`
  font-size: 11px;
  color: ${colors.uiGrey600};
`;

export const StyledName = styled.span`
  font-weight: bold;
`;

type PropsType = {
  candidate: {
    display_name: string;
    email: string;
  } | null;
  url: string;
};

const Candidate = ({ candidate, url }: PropsType) => {
  if (!candidate) return null;

  return (
    <Cell url={url} testid='cm-ui-tasks-page-table-rows-candidate'>
      <StyledName data-testid='cm-ui-tasks-page-table-rows-candidate-name'>
        {candidate?.display_name}
      </StyledName>
      <StyledEmail data-testid='cm-ui-tasks-page-table-rows-candidate-email'>
        {candidate?.email}
      </StyledEmail>
    </Cell>
  );
};

export default Candidate;
