import React from 'react';
import { useTranslation } from 'react-i18next';

import Section from '../../shared/Section';
import { namespace } from '../../../locales';
import ResourcesContent from './ResourcesContent';

const ResourcesSection: React.FC = () => {
  const { t } = useTranslation();
  const basePath = `${namespace}:resourcesSection`;
  const header = t(`${basePath}.header`);
  const subheader = t(`${basePath}.subHeader`);

  return (
    <Section header={header} subheader={subheader}>
      <ResourcesContent />
    </Section>
  );
};

export default ResourcesSection;
