/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import moment from 'moment/moment';
import Humanize from 'humanize-plus';
import { TableCell, Tooltip } from '../BulkOrderHistoryStyled';
import { useDownloadBulkOrderHistoryFile } from '../../../api/billing';
import { downloadBlobAsFile } from '../../../utils/APIClient';
import ManualBulkOrderHistory from './ManualBulkOrderHistory';

type Props = {
  header: any;
  row: ManualBulkOrderHistory;
  cell: any;
};

const ManualBulkOrderHistoryTableCell: React.FC<Props> = ({
  header,
  row,
  cell,
}) => {
  let cellValue;
  const { call, result } = useDownloadBulkOrderHistoryFile();

  const truncatesString = (value: string) => {
    const valueLengthToTruncate: number = 30;

    return (
      <>
        {value.length <= valueLengthToTruncate ? (
          value
        ) : (
          <Tooltip highlighted={false} label={value}>
            <M.TooltipLabel>
              {Humanize.truncate(value, valueLengthToTruncate)}
            </M.TooltipLabel>
          </Tooltip>
        )}
      </>
    );
  };

  const getDate = () => moment(cell.value, 'DD/MM/YYYY HH:mm').toDate();

  const downloadFile = useCallback(
    (s3ObjectKey: string) => {
      call({ s3ObjectKey });
    },
    [call],
  );

  useEffect(() => {
    const { status, data } = result;

    if (status === 'success' && data) {
      const blob = new Blob([atob(data)]);
      downloadBlobAsFile({
        data: blob,
        headers: {
          'content-type': 'application/CSV',
        },
        filename: header === 'original_uri' ? 'bulk-manual.csv' : 'errors.csv',
      });

      result.reset();
    }
  }, [result]);

  switch (header) {
    case 'order_status':
      cellValue = (
        <TableCell key={cell.id} style={{ width: 164 }}>
          Submitted
        </TableCell>
      );
      break;
    case 'ran_at':
      cellValue = (
        <TableCell key={cell.id} style={{ whiteSpace: 'nowrap', width: 140 }}>
          {moment(getDate()).format('MM/DD/YY hh:mm A')}
        </TableCell>
      );
      break;
    case 'package_name':
      cellValue = (
        <TableCell key={cell.id} style={{ minWidth: 172 }}>
          <strong>{cell.value}</strong>
        </TableCell>
      );
      break;
    case 'original_uri':
      cellValue = (
        <TableCell
          key={cell.id}
          style={{ whiteSpace: 'nowrap', minWidth: 172 }}
        >
          <M.Link
            download
            key={`${row.id}-bulk-order-history-file`}
            onClick={() => downloadFile(cell.value)}
          >
            Download CSV
          </M.Link>
        </TableCell>
      );
      break;
    case 'invoked_by':
      cellValue = (
        <TableCell
          key={cell.id}
          style={{ whiteSpace: 'nowrap', minWidth: 172 }}
        >
          {truncatesString(cell.value)}
        </TableCell>
      );
      break;
    case 'errors_uri':
      cellValue = (
        <TableCell key={cell.id} style={{ whiteSpace: 'nowrap', width: 164 }}>
          {cell.value ? (
            <M.Link
              download
              key={`${row.id}-bulk-order-history-errors`}
              onClick={() => downloadFile(cell.value)}
            >
              errors.csv
            </M.Link>
          ) : (
            <span style={{ color: colors.uiTextTertiaryLight }}>N/A</span>
          )}
        </TableCell>
      );
      break;
    default:
      cellValue = <TableCell key={cell.id}>{cell.value}</TableCell>;
  }
  return cellValue;
};

export default ManualBulkOrderHistoryTableCell;
