import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { removeParamFromUrl } from '@dashboard-experience/utils';
import { useLocation } from 'react-router-dom';
import LearnMoreModal from './LearnMoreModal';
import { FlexContainer, FlexRowAlignRight } from './style';
import { I9_ORDERING } from '../../Flags';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const FlexGrid = styled.div`
  display: flex;
  flex: 1;
  gap: 2rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const FlexCol = styled.div`
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;
  display: flex;
  padding: 1.5rem;
  max-width: 35rem;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.125rem !important;
  line-height: 1.5rem;
  margin: 1rem auto 3rem !important;
`;

const TextWrap = styled.div`
  font-size: 1rem !important;
  line-height: 1.5rem;
  margin-left: 1.5rem;
  text-align: left;

  @media screen and (min-width: 1024px) {
    margin-left: 0;
    max-width: 16rem;
  }
`;

const SubHeading = styled.h5`
  color: ${colors.uiTextPrimaryLight};
  font-size: 1.125rem;
  line-height: 1;
`;

const ParagraphUpdated = styled.p`
  font-size: 1rem !important;
  line-height: 1.5rem;
`;

const IconWrap = styled.div`
  background-color: ${colors.uiNavy100};
  border-radius: 0.5rem;
  color: ${colors.uiNavy500};
  height: 2.5rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  width: 2.5rem;
`;

const BtnWrap = styled.div`
  margin-top: 3rem;
`;

const Paragraph = styled.p`
  font-size: 1rem !important;
  line-height: 150%;
  max-width: 46.5rem;
`;

const UnorderedList = styled(M.UnorderedList)`
  .cds--list__item {
    &::before {
      content: '•';
    }

    font-size: 1rem !important;
    line-height: 150%;
    max-width: 46.5rem;
  }
`;

interface AccountGetStartedProps {
  onContinueClick: () => void;
}

const AccountGetStarted = ({ onContinueClick }: AccountGetStartedProps) => {
  const trackEvent = useTrackEvent();
  const [showModal, setShowModal] = useState(false);
  const showRedesignedI9Landing = useFlag(I9_ORDERING)?.variantKey === 'on';
  const location = useLocation();

  const setModalOpen = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const hideModal = useCallback((): void => {
    setShowModal(false);
  }, []);

  // Track event on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sourceParam = searchParams.get('source');
    let navigatedSource = 'Other';

    if (sourceParam) {
      const sourceMappings: { [key: string]: string } = {
        tab: 'Left tab',
        order: 'Order submission',
        report: 'Candidate page order new report',
      };
      navigatedSource = sourceMappings[sourceParam];
      removeParamFromUrl(window, 'source');
    }
    trackEvent(I9_EVENT_NAMES.I9_ACCOUNT_SETUP_GET_STARTED_VIEWED, {
      'Page Type': 'Account Setup',
      'I9 Page Type': 'Account Setup Get Started page',
      'I9 Page Source': navigatedSource,
    });
  }, [location.search, trackEvent]);

  return (
    <>
      {showRedesignedI9Landing ? (
        <>
          <h2>Hiring W-2 staff members?</h2>
          <SubTitle>
            Say goodbye to I-9 paperwork with Checkr’s digital I-9 solution
          </SubTitle>
          <FlexGrid>
            <FlexCol>
              <IconWrap>
                <M.Icon icon='ArrowsHorizontal' size={24} />
              </IconWrap>
              <TextWrap>
                <SubHeading>Streamlined</SubHeading>
                <ParagraphUpdated>
                  Automate I-9 tasks and notifications, including E-Verify
                  requirements, to optimize your onboarding efficiency.
                </ParagraphUpdated>
              </TextWrap>
            </FlexCol>
            <FlexCol>
              <IconWrap>
                <M.Icon icon='CheckmarkOutline' size={24} />
              </IconWrap>
              <TextWrap>
                <SubHeading>Accurate</SubHeading>
                <ParagraphUpdated>
                  Import electronic information for your entire staff to Form
                  I-9, saving you time and minimizing the potential for errors.
                </ParagraphUpdated>
              </TextWrap>
            </FlexCol>
            <FlexCol>
              <IconWrap>
                <M.Icon icon='Settings' size={24} />
              </IconWrap>
              <TextWrap>
                <SubHeading>Effective</SubHeading>
                <ParagraphUpdated>
                  Set the stage for a positive onboarding experience for your
                  entire staff with a simple and swift I-9 process.
                </ParagraphUpdated>
              </TextWrap>
            </FlexCol>
          </FlexGrid>
          <SubTitle>
            Set up is free. You only pay for completed I-9 orders.
          </SubTitle>
          <BtnWrap>
            <M.Button kind='tertiary' onClick={setModalOpen} size='md'>
              Learn more
            </M.Button>
            <M.Button kind='primary' onClick={onContinueClick} size='md'>
              Get started
            </M.Button>
          </BtnWrap>
          {showModal && (
            <LearnMoreModal
              open={showModal}
              hideModal={hideModal}
              data-testId='modal-i9-info'
            />
          )}
        </>
      ) : (
        <>
          <FlexContainer gap='0' padding='0'>
            <Paragraph>
              We&apos;re partnering with{' '}
              <a
                href='https://mitratech.com/products/tracker-i-9-compliance/'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                Tracker I-9{' '}
              </a>{' '}
              to help you stay compliant at all times. Just complete a few
              simple steps to get started and enjoy extra time-saving benefits
              such as:
            </Paragraph>
            <Paragraph>
              <UnorderedList>
                <M.ListItem>
                  Automatic status updates for your I-9 coordinator
                </M.ListItem>
                <M.ListItem>
                  Timely communications and reminders from Tracker
                </M.ListItem>
                <M.ListItem>Preferred workflow selection</M.ListItem>
              </UnorderedList>
            </Paragraph>
            <Paragraph>
              By simplifying your I-9 process and ensuring visibility every step
              of the way, we remove the guesswork from one of your most
              important hiring processes.
            </Paragraph>
          </FlexContainer>
          <FlexRowAlignRight gap='0' alignItems='center'>
            <M.Button onClick={onContinueClick}>Continue</M.Button>
          </FlexRowAlignRight>
        </>
      )}
    </>
  );
};

export default AccountGetStarted;
