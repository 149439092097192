import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'modules/assess/api';
import * as Entity from 'modules/assess/models/rules/count';
import { validateRule } from 'modules/assess/models/rules/common/validations';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/count';
import { SaveButton } from 'modules/assess/ui/rules/common/rule/editor';
import { toExternal } from '../../utils';
import Cancel from '../cancel';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${colors.uiGrey200};
  margin-top: 2em;

  .buttons {
    margin: 2em 0 1em 0;
  }
  button:last-child {
    margin-right: 0 !important;
  }
`;

type Props = {
  disabled: boolean;
  rule: Entity.Type;
  draftRule: Entity.Type;
  namespace: Namespace;
};

const Footer: React.FC<Props> = ({ disabled, rule, draftRule, namespace }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const validation = validateRule(draftRule, namespace);

  const save = useCallback(
    () =>
      dispatch(
        Actions.Update.create({
          ...toExternal(draftRule),
          editing: false,
          initial: false,
        }),
      ),
    [dispatch, draftRule],
  );

  return (
    <>
      {!validation.valid && (
        <M.InlineNotification
          style={{ maxWidth: '28rem', marginTop: '2em', marginLeft: '1rem' }}
          kind='warning'
          title={t(`assess:rules.custom.rule.validation.${validation.error}`)}
        />
      )}
      <Container data-testid='assess-rules-count-rule-editor-rule-footer'>
        <div className='buttons'>
          <Cancel rule={rule} />
          <SaveButton disabled={disabled || !validation.valid} onClick={save} />
        </div>
      </Container>
    </>
  );
};

export default Footer;
