import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getScreeningStatus,
  getResultOrStatus,
  ReportStatuses,
} from '@dashboard-experience/utils';
import moment from 'moment';
import { EMPLOYMENT_SETTINGS_EDITABLE_HOURS } from 'Constants';
import {
  EmploymentVerification as EmploymentVerificationType,
  EmploymentRecord,
  EmploymentGap,
  EmploymentHistory,
  ReportProps,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import Record from './EmploymentRecord';
import Gap from './EmploymentGap';
import { useCandidateDocuments } from '../Document/hooks';

const inEditableTimeframe = (completed_at: string) =>
  new Date().getTime() <
  moment(completed_at).unix() +
    EMPLOYMENT_SETTINGS_EDITABLE_HOURS * 60 * 60 * 1000;

const isEditable = (report: any) => {
  const status = getResultOrStatus(report);
  switch (status) {
    case ReportStatuses.PENDING:
    case ReportStatuses.SUSPENDED:
      return true;
    case ReportStatuses.CONSIDER:
      return inEditableTimeframe(report.completed_at);
    default:
      return false;
  }
};

const getDate = (history: EmploymentHistory) =>
  moment(
    history.type === 'gap' ? history.start_date : history.employer.start_date,
  );

const mergeHistory = (employment_verification: EmploymentVerificationType) => {
  const history: EmploymentHistory[] = [];
  employment_verification?.records?.forEach((record: EmploymentRecord) => {
    history.push({
      ...record,
      type: 'record',
    });
  });
  employment_verification?.gaps?.forEach((record: EmploymentGap) => {
    history.push({
      ...record,
      type: 'gap',
    });
  });
  return history.sort((a: EmploymentHistory, b: EmploymentHistory) =>
    getDate(a).diff(getDate(b)),
  );
};

const EmploymentVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { employment_verification } = report;
  const status = getScreeningStatus(
    employment_verification || {},
    reportStatusType,
  );
  const employmentHistory = useMemo(
    () => mergeHistory(employment_verification as EmploymentVerificationType),
    [employment_verification],
  );
  const { documents } = useCandidateDocuments(report?.candidate?.id as string);
  const canEdit = useMemo(() => isEditable(report), [report]);

  return employment_verification ? (
    <M.Screening
      hideCancellationReason
      title='Employment Verification'
      screening={employment_verification}
      statusType={reportStatusType}
      id={SCREENING_TITLES.employment_verification.id}
      role='list'
    >
      {employmentHistory.length === 0 && (
        <div>No employment history provided by candidate</div>
      )}
      {employmentHistory.map((record: EmploymentHistory, index: number) => (
        <React.Fragment key={record.id}>
          {record.type === 'gap' ? (
            <Gap gap={record} />
          ) : (
            <Record
              record={record}
              status={status}
              statusType={reportStatusType}
              documents={documents}
              pending={record.status === ReportStatuses.PENDING}
              isEditable={canEdit}
              completed={!!employment_verification.completed_at}
            />
          )}
          {index < employmentHistory.length - 1 && <M.Divider />}
        </React.Fragment>
      ))}
    </M.Screening>
  ) : null;
};

export default EmploymentVerification;
