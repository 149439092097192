import { Client } from 'api';
import {
  BASE_PATH,
  ADVERSE_ACTIONS_CSV_PATH,
  CANDIDATES_CSV_PATH,
  INVITATIONS_CSV_PATH,
} from './constants';

import { CSVExportParams, TCsv } from './types';

export const createAdverseActionCsv = (
  params: CSVExportParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(ADVERSE_ACTIONS_CSV_PATH, params);
};

export const createCandidateCsv = (
  params: CSVExportParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(CANDIDATES_CSV_PATH, params);
};

export const createInvitationCsv = (
  params: CSVExportParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(INVITATIONS_CSV_PATH, params);
};

export const getCsv = (id: string): Promise<TCsv> => {
  return Client.get(`${BASE_PATH}/${id}`);
};
