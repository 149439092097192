import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import Iframe from 'components/Iframe';

type Props = {
  preview: string | undefined;
  isLoading: boolean;
  type: string;
};

const PreviewIframe: React.FC<Props> = ({ preview = '', isLoading, type }) => {
  if (isLoading) {
    return <M.LoadingInline data-testid={`${type}-preview-loading`} />;
  }

  return preview ? (
    <Iframe
      content={preview}
      style={{ border: `1px solid ${colors.uiGrey100}` }}
      scrolling='no'
      data-testid={`${type}-preview-iframe`}
    />
  ) : null;
};

export default PreviewIframe;
