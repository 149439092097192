import React from 'react';
import { Content } from 'modules/assess/ui/page';
import { useSearch } from 'modules/assess/ui/mvr/category-sets/collection/api';
import * as CategoryCodeSetVersion from 'modules/assess/models/rulesets/version-diff/category-code-set-version-change';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import styled from 'styled-components';
import { Version } from 'modules/assess/models/rulesets';
import { t } from 'i18next';
import Title from '../common/Title';

const CCS_PAGE_SIZE = 100;

const Bolded = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

type Props = {
  changes?: CategoryCodeSetVersion.Type;
};

function getIDAndVersion(rulesetVersion?: Version.Type | string) {
  if (!rulesetVersion || typeof rulesetVersion !== 'object') {
    return null;
  }
  return {
    id: rulesetVersion.category_code_set_version?.category_code_set?.id,
    number: rulesetVersion.category_code_set_version?.number,
  };
}
export const CategorySetChanges: React.FC<Props> = ({ changes }) => {
  const { data } = useSearch({ per_page: CCS_PAGE_SIZE });
  const ruleset = useRuleset()?.data;

  if (!changes) {
    return null;
  }

  const categorySets = data?.data || [];
  const items = categorySets.map(categorySet => ({
    id: categorySet.id,
    label: categorySet.name,
  }));
  const activeCategoryCodeSetVersion = getIDAndVersion(ruleset?.active);
  const activeCategoryCodeSet = items.find(
    item => item.id === activeCategoryCodeSetVersion?.id,
  );
  const draftCategoryCodeSetVersion = getIDAndVersion(ruleset?.draft);
  const draftCategoryCodeSet = items.find(
    item => item.id === draftCategoryCodeSetVersion?.id,
  );
  const text = t(
    `assess:ruleset.version.publish.changes.category-set.content`,
    {
      label: activeCategoryCodeSet?.label,
      number: activeCategoryCodeSetVersion?.number,
      draftLabel: draftCategoryCodeSet?.label,
      draftNumber: draftCategoryCodeSetVersion?.number,
    },
  );

  return (
    <Content>
      <Title type='category-set' />
      <p>
        <Bolded>[Updated]:</Bolded>
        {text}
      </p>
    </Content>
  );
};

export default CategorySetChanges;
