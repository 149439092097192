import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import TabsNav from 'modules/case-management/ui/shared/tabs-nav';
import BetaBanner from './BetaBanner';
import WelcomeMessage from './WelcomeMessage';

type PropsType = {
  children: React.ReactNode;
  headerText: string;
  testId?: string;
};

export const PageContainer: React.FC<PropsType> = ({
  children,
  headerText,
  testId = 'case-management-page-container',
}: PropsType) => {
  return (
    <M.Container data-testid={testId} type='transparent'>
      <WelcomeMessage />
      <BetaBanner />
      <h1 data-testid='case-management-page-header'>{headerText}</h1>
      <TabsNav />
      {children}
    </M.Container>
  );
};

export default PageContainer;
