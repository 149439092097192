import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getScreeningStatus,
  ReportStatuses,
} from '@dashboard-experience/utils';
import {
  EducationRecord,
  ReportProps,
  Document as CandidateDocument,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { EDUCATION_PROOF_DOC_TYPES } from 'Constants';
import Document from '../Document';
import Record from '../EducationVerification/EducationRecord';
import InternationalEducationRecord from './InternationalEducationRecord';
import { useCandidateDocuments } from '../Document/hooks';

const displayParsedEducationRecord = (record: EducationRecord) => {
  return record.result.degrees.length > 0;
};

const InternationalEducationVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_education_verification: screening } = report;
  const { documents } = useCandidateDocuments(report?.candidate?.id as string);
  const filteredDocuments = useMemo(
    () =>
      documents.filter((document: CandidateDocument) =>
        EDUCATION_PROOF_DOC_TYPES.includes(document.type),
      ),
    [documents],
  );

  if (!screening) return null;

  const documentsLegacyBackfillMode = filteredDocuments.some(
    d => !d.alternate_documentable_id,
  );

  const status = getScreeningStatus(screening, reportStatusType);
  const completed = !!screening?.completed_at;
  const educationRecords = screening.records;

  return (
    <M.Screening
      title='International Education Verification'
      statusType={reportStatusType}
      screening={screening}
      id={SCREENING_TITLES.international_education_verification.id}
    >
      {educationRecords.length === 0 && status !== ReportStatuses.CANCELED ? (
        <div>No education history provided by candidate</div>
      ) : (
        educationRecords.map((record, i: number) => (
          <React.Fragment key={record.id}>
            {displayParsedEducationRecord(record) && (
              <Record
                completed={!!screening?.completed_at}
                documents={filteredDocuments}
                record={record}
                status={status}
                statusType={reportStatusType}
              />
            )}
            {!displayParsedEducationRecord(record) && (
              <InternationalEducationRecord
                record={record}
                statusType={reportStatusType}
                completed={!!screening?.completed_at}
                documents={filteredDocuments}
              />
            )}

            {i < screening.records.length - 1 && <M.Divider />}
          </React.Fragment>
        ))
      )}
      {completed &&
        documentsLegacyBackfillMode &&
        filteredDocuments.map((document: CandidateDocument) => (
          <Document
            key={document.id}
            document={document}
            dataTestId='document-of-legacy'
          />
        ))}
    </M.Screening>
  );
};

export default InternationalEducationVerification;
