import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Common from 'modules/assess/models/rules/common/entity';
import * as Lookback from 'modules/assess/models/rules/lookback';
import { Guideline as Amplitude } from 'modules/assess/v2/models/amplitude';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { PremiumUpgrade, StyledComponents } from 'modules/assess/v2/ui/common';
import { fromUrlToTranslation } from 'modules/assess/v2/ui/utils';
import Header from './Header';

type Props = {
  option: Navigation;
  rules?: Common.List | Common.Map | Lookback.List | Lookback.Map;
  showPremiumBadge?: boolean;
};

const Description = styled.div`
  color: ${colors.brandNavy4};
  line-height: 150%;
  margin-top: 16px;
  width: 75%;
`;

const StyledAquaContainer = styled(StyledComponents.AquaContainer)`
  box-shadow: 0px 4px 24px rgba(22, 22, 29, 0.08);
  border-radius: 8px;
`;

const StyledNavyContainer = styled(StyledComponents.NavyContainer)`
  box-shadow: 0px 4px 24px rgba(22, 22, 29, 0.08);
  border-radius: 8px;
`;

export const Container: React.FC<Props> = ({
  option,
  rules,
  showPremiumBadge,
}) => {
  const count = Object.values(rules || []).length;
  const { t } = useTranslation('assess:v2');
  const translationOption = fromUrlToTranslation(option);
  const description = t(
    `standard.guideline.content.options.${translationOption}.description`,
  );
  const title = t(
    `standard.guideline.content.options.${translationOption}.title`,
  );

  const StyledContainer = count > 0 ? StyledAquaContainer : StyledNavyContainer;

  const containerId = `assess-v2-ui-guideline-options-container-${option}`;
  const descriptionId = `assess-v2-ui-guideline-options-container-description-${option}`;

  return (
    <StyledContainer id={containerId}>
      <Header
        count={count}
        option={option}
        showPremiumBadge={showPremiumBadge}
        title={title}
      />
      <Description id={descriptionId}>
        <Trans>{description}</Trans>
      </Description>
      {showPremiumBadge && <PremiumUpgrade eventProperty={Amplitude.Page} />}
    </StyledContainer>
  );
};

export default Container;
