import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import { Field as Common } from 'modules/assess/models/rulesets/version-diff/common';

type Props = {
  isAssessmentDecision?: boolean;
  field: Common.FieldUnchanged;
};

const StatusBadge = styled(M.StatusBadge)`
  margin-left: 0.3rem;
`;

const Value = styled.span`
  margin-left: 0.3rem;
`;

export const FieldUnchanged: React.FC<Props> = ({
  field,
  isAssessmentDecision,
}) => {
  const { t } = useTranslation('assess');

  if (isAssessmentDecision) {
    const status = t(`assess:decisions.${field.value}`).toLowerCase();
    return <StatusBadge statusType={StatusTypes.Assess} status={status} />;
  }

  let { value } = field;
  if (field.translatePath) {
    value = t(`${field.translatePath}.${field.value || 'default'}`);
  }

  return <Value>{value}</Value>;
};

export default FieldUnchanged;
