import {
  IS_IN_IFRAME,
  CHECKR_DASHBOARD_URL,
  STAGING,
  SANDBOX,
} from 'Constants';

const postMessageToDashboard = (message: any) => {
  const postmessageUrl = STAGING || SANDBOX ? '*' : CHECKR_DASHBOARD_URL;
  if (
    IS_IN_IFRAME &&
    postmessageUrl &&
    window.parent &&
    window.parent.postMessage &&
    typeof window.parent.postMessage === 'function'
  ) {
    window.parent.postMessage(message, postmessageUrl);
    return true;
  }
  return false;
};

export default postMessageToDashboard;
