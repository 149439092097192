import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import SearchPDF from './SearchPDF';

const InternationalGlobalWatchlistSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_global_watchlist_search: search } = report;

  if (!search) return null;
  return (
    <M.Screening
      id={SCREENING_TITLES.international_global_watchlist_search.id}
      title='International Global Watchlist Search'
      statusType={reportStatusType}
      screening={search}
    >
      {search.pdf_url && (
        <SearchPDF
          url={search.pdf_url}
          text='View international global watchlist report'
        />
      )}
    </M.Screening>
  );
};

export default InternationalGlobalWatchlistSearch;
