import { Namespace } from '../api';
import * as CriminalRule from './criminal/rules/rules';
import * as MVRRule from './mvr/rules/rules';

export type Category = CriminalRule.Category | MVRRule.Category;
export type RulesType = typeof CriminalRule | typeof MVRRule;

export function getRulesByNamespace(
  namespace: Namespace,
): typeof CriminalRule | typeof MVRRule {
  switch (namespace) {
    case Namespace.criminal:
      return CriminalRule;
    case Namespace.mvr:
      return MVRRule;
    default:
      throw new Error('Invalid Rule namespace');
  }
}
