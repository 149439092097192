import { useFlag } from '@dashboard-experience/react-flagr';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hasPermission } from '@dashboard-experience/utils';
import {
  SHOW_EDIT_BUTTON_FOR_PACKAGES,
  ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY,
} from 'Constants';
import { useUser } from 'context/CurrentUser';

import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import Styles from './styles';
import CtaCard from '../../shared/CtaCard';

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  geoModalSubmitted: boolean;
};

const SetupAndExploreContent: React.FC<Props> = ({
  showModal,
  setShowModal,
  geoModalSubmitted,
}) => {
  const currentUser = useUser();
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const editExistingPackageFeatureAvailable =
    useFlag(SHOW_EDIT_BUTTON_FOR_PACKAGES)?.variantKey === 'enabled';
  const createNewPackageAvailable =
    useFlag(ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY)?.variantKey === 'true';
  const showBuildPackageCard =
    editExistingPackageFeatureAvailable && createNewPackageAvailable;
  const showUploadLogoCard = !currentUser?.account.logo_uri;
  const showGeoModalCard =
    !geoModalSubmitted &&
    currentUser?.account?.purpose === 'employment' &&
    !currentUser?.account?.default_compliance_city;

  const handlePendoGetStarted = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.SETUP_AND_EXPLORE_CLICKED, {
        'Home Setup and Explore Click': 'Get started',
      });
    }
    // @ts-ignore
    window.pendo &&
      // @ts-ignore
      window.pendo.showGuideById(
        'k74dFwHvGmlqHDsvdFBECMV3KWA@iCLfiHjI01xZfeLbmwXIeuj6QDI',
      );
  }, [isSignup, trackEvent]);

  const handlePendoBuildPackage = React.useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.SETUP_AND_EXPLORE_CLICKED, {
        'Home Setup and Explore Click': 'Build a custom package',
      });
    }

    const packagesPage = `/packages?source=home`;
    history.push(packagesPage);
  }, [isSignup, trackEvent, history]);

  const handleAccountRedirect = useCallback(() => {
    const settingsLogoSection = `/account/settings#section-logo`;
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.SETUP_AND_EXPLORE_CLICKED, {
        'Home Setup and Explore Click': 'Update your logo',
      });
    }

    history.push(settingsLogoSection);
  }, [history, isSignup, trackEvent]);

  const handleDefaultHiringClick = useCallback(() => {
    setShowModal(!showModal);
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.SETUP_AND_EXPLORE_CLICKED, {
        'Home Setup and Explore Click': 'Set your default hiring location',
      });
    }
  }, [setShowModal, showModal, isSignup, trackEvent]);

  return (
    <Styles.CtaCardContainer>
      <CtaCard
        translationKey='ctaCardGeo'
        ctaColor='aqua'
        onCardClick={handleDefaultHiringClick}
        data-testid='default-hiring-cta'
        isShown={
          hasPermission(currentUser, 'manage_account_settings') &&
          showGeoModalCard
        }
      />
      <CtaCard
        translationKey='ctaCardExplore'
        ctaColor='aqua'
        onCardClick={handlePendoGetStarted}
        data-testid='explore-cta'
      />
      <CtaCard
        translationKey='ctaCardBuild'
        ctaColor='aqua'
        onCardClick={handlePendoBuildPackage}
        data-testid='build-package-cta'
        isShown={
          hasPermission(currentUser, 'manage_packages') && showBuildPackageCard
        }
      />
      <CtaCard
        translationKey='ctaCardUpload'
        ctaColor='aqua'
        onCardClick={handleAccountRedirect}
        data-testid='upload-logo-cta'
        isShown={
          hasPermission(currentUser, 'manage_account_settings') &&
          showUploadLogoCard
        }
      />
    </Styles.CtaCardContainer>
  );
};

export default SetupAndExploreContent;
