import React from 'react';
import { ConditionsProvider } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import { Type } from 'modules/assess/models/rules/custom';
import Editor from './editor';

export default function EditorContainer({ rule }: { rule: Type }) {
  return (
    <ConditionsProvider>
      <Editor rule={rule} />
    </ConditionsProvider>
  );
}
