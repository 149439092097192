import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { mapPackage } from 'components/AddScreenings/shared/utils';
import {
  getIncludeDrivingHistorySetting,
  getScreeningSetting,
  getScreeningType,
  isInternationalMotorVehicleReport,
} from 'components/Packages/PackageScreenings';
import CIRCLE_CHECKMARK from 'components/Packages/CreatePackage/Context/CIRCLE_CHECKMARK';
import { Package, Screening } from '../../../../types';
import {
  ScreeningListItem,
  ScreeningSettings,
  ScreeningType,
  ScreeningUl,
} from './PackageCard';
import {
  actionTypes,
  MULTIPLE_PEOPLE,
  useOrderBackgroundCheckContext,
} from '../Context';
import PackagePrice from './PackagePrice';
import AliasTag from '../../Alias';
import ConditionalWrapper from './ConditionalWrapper';
import UnavailableForManualBulkTooltip from './ManualBulkTooltip';

const Container = styled.div<{ checked: boolean; onClick?: Function }>`
  flex: 1;
  min-width: 237px;
  min-height: 261px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 16px !important;
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  border: ${props =>
    props.checked
      ? `1px solid ${colors.uiNavy200} !important`
      : `1px solid ${colors.uiGrey200} !important`};
  border-radius: 2px !important;
  box-shadow: none !important;
  :hover,
  :hover .mastodon-radio-button,
  :hover .cds--radio-button__appearance {
    background: ${colors.uiNavy100} !important;
  }
  :has(:active .cds--radio-button__appearance) {
    background: ${colors.uiNavy100} !important;
  }
  :hover {
    border: 1px solid ${colors.uiNavy200} !important;
  }

  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'not-allowed')};
  }
  padding: 8px 16px 24px 16px;

  &.card-disabled {
    background: ${colors.uiGrey100} !important;
    border-color: ${colors.borderPrimaryLight} !important;
    color: ${colors.uiTextDisabledLight} !important;
    cursor: not-allowed !important;
    margin-bottom: 0 !important;

    &:active,
    .mastodon-radio-button,
    &:hover .cds--radio-button__appearance,
    .cds--radio-button__appearance:before {
      background: ${colors.uiGrey100} !important;
    }

    .cds--radio-button__label {
      cursor: not-allowed !important;
    }
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    min-width: 0;
  }

  @media screen and (max-width: 630px) {
    height: 100%;
    min-height: 0;
    margin-bottom: 4px !important;
    padding: 1rem;
  }
`;

const RadioButton = styled(M.RadioButton)<{ checked: boolean }>`
  .highlighted-label {
    border-bottom: none !important;
  }
  .cds--radio-button__appearance {
    border: 1px solid ${colors.brandSlate5} !important;
  }
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  .cds--radio-button:focus
    + .cds--radio-button__label
    .cds--radio-button__appearance::after {
    box-shadow: unset !important;
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    margin-left: -15px;
    margin-top: -15px;
    content: url(${CIRCLE_CHECKMARK}) !important;
    background-color: ${props =>
      props.checked
        ? `${colors.uiNavy100} !important`
        : `${colors.uiGrey0} !important`};
    width: 100% !important;
    height: 100% !important;
    transform: scale(0.5) !important;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  width: 208px;
  height: 22px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;

  @media all and (max-width: 940px) {
    width: 100%;
  }

  @media all and (max-width: 630px) {
    margin-bottom: 0.25rem;
  }
`;

const PackageNameLabel = styled.div`
  color: ${colors.uiTextPrimaryLight};

  max-width: 176px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 102%;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Number of lines to show before ellipsis
  max-height: 3em;

  .card-disabled & {
    color: ${colors.uiTextDisabledLight};
  }
`;

const RecommendedTag = styled.div<{ visible: boolean }>`
  color: ${colors.uiAqua600};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 5px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  .card-disabled & {
    color: ${colors.uiTextDisabledLight} !important;
  }

  // hide empty recommended tag on mobile
  @media all and (max-width: 630px) {
    display: ${props => (props.visible ? 'visible' : 'none')};
  }
`;

const BreakLine = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  min-width: 208px;
  border-bottom: 1px solid ${colors.uiGrey300};

  @media all and (max-width: 940px) {
    min-width: 0;
    width: 100%;
  }

  @media all and (max-width: 630px) {
    display: none;
  }
`;

const ScreeningListContainer = styled.div<{ showMore: boolean }>`
  ul {
    list-style-type: square;
  }
  ul > li {
    color: ${colors.brandGreen4};
  }

  ul > li > span {
    color: ${colors.uiTextPrimaryLight};
  }

  .card-disabled & {
    ul > li {
      &::before,
      span {
        color: ${colors.uiTextDisabledLight};
      }
    }
  }

  @media all and (max-width: 630px) {
    display: ${({ showMore }) => (showMore ? 'block' : 'none')};
  }
`;

const Tooltip = styled(M.Tooltip)`
  border: none !important;
`;

const ShowMoreLink = styled(M.Link)`
  margin-left: 22px;
`;

const ShowMoreLinkMobile = styled(M.Link)`
  display: none;
  margin-top: 9px;
  font-size: 12px;

  @media all and (max-width: 630px) {
    display: flex;
  }
`;

const ScreeningList = styled(ScreeningUl)`
  @media all and (max-width: 630px) {
    padding-left: 0;
    padding-top: 5px;
  }
`;

const Blurb = styled.p`
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65)) !important;
  height: 2.25rem;
  padding-bottom: 0.25rem;

  @media all and (max-width: 630px) {
    height: unset;
  }
`;

const SCREENINGS_CUTOFF = 7;

export enum RecommendedTagType {
  RECENTLY_CREATED = 'Recently created',
  RECENTLY_ORDERED = 'Recently ordered',
  MOST_POPULAR = 'Most popular',
  EMPTY = 'EMPTY',
}

export enum BLURBS {
  BASIC_PLUS = 'Simple and limited search',
  COMPLETE_CRIMINAL = 'Most thorough coverage',
  ESSENTIAL = 'In-depth criminal search, down to the county',
  PROFESSIONAL = 'Additional verifications for advanced roles',
}

type PackageChoiceCardProps = {
  basePackage: Package;
  idx: number;
  selected?: boolean;
  showPackagePrice?: boolean;
  onClick?: Function;
  hideMostPopularTag?: boolean;
  isAvailableForManualBulk: (thisPackage: Package) => boolean;
  isAllCustom?: boolean;
};

const PackageChoiceCard: React.FC<PackageChoiceCardProps> = ({
  basePackage,
  idx,
  showPackagePrice = false,
  selected = false,
  onClick = () => {},
  hideMostPopularTag,
  isAvailableForManualBulk,
  isAllCustom,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showMore, setShowMore] = useState(false);
  // In small screens and mobile widths, we hide the screenings is showMoreMobile is false
  const [showMoreMobile, setShowMoreMobile] = useState(false);
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const isManualBulkOrder = state.manualBulkUploadType === MULTIPLE_PEOPLE;

  const filteredBasePackage = useMemo(() => {
    // makes sure adverse media is not shown as a separate line item for international_only packages
    if (basePackage.international_only) {
      const filtered = basePackage.screenings?.filter(
        (screening: Screening) =>
          screening.type !== 'international_adverse_media_search',
      );
      return { ...basePackage, screenings: filtered };
    }
    return basePackage;
  }, [basePackage]);

  const updateContinueButtonState = useCallback(() => {
    const isPackageSelected = Boolean(basePackage);
    if (state.continueButtonDisabled !== !isPackageSelected) {
      dispatch({
        type: actionTypes.CONTINUE_BUTTON_DISABLED,
        payload: { continueButtonDisabled: !isPackageSelected },
      });
    }
  }, [basePackage, dispatch, state.continueButtonDisabled]);

  const availableForManualBulk = isAvailableForManualBulk
    ? isAvailableForManualBulk(basePackage)
    : false;

  useEffect(() => {
    if (
      state?.basePackage?.slug === basePackage?.slug &&
      Boolean(basePackage) &&
      Boolean(state?.basePackage) &&
      (!isManualBulkOrder || availableForManualBulk)
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [
    basePackage,
    basePackage?.slug,
    availableForManualBulk,
    isManualBulkOrder,
    state?.basePackage,
    state?.basePackage?.slug,
  ]);

  const package_blurb = useMemo(() => {
    if (basePackage?.slug === 'basic_+') {
      return BLURBS.BASIC_PLUS;
    }
    if (basePackage?.slug === 'essential') {
      return BLURBS.ESSENTIAL;
    }
    if (basePackage?.slug === 'professional') {
      return BLURBS.PROFESSIONAL;
    }
    if (basePackage?.slug === 'checkrdirect_complete') {
      return BLURBS.COMPLETE_CRIMINAL;
    }
    return '';
  }, [basePackage?.slug]);

  const recommendedTag = useMemo(() => {
    if (basePackage?.origin === 'customer_via_signup') {
      return RecommendedTagType.RECENTLY_ORDERED;
    }
    if (basePackage?.origin === 'customer_via_dashboard') {
      return RecommendedTagType.RECENTLY_CREATED;
    }
    if (basePackage?.slug === 'essential' && !hideMostPopularTag) {
      return RecommendedTagType.MOST_POPULAR;
    }
    return RecommendedTagType.EMPTY;
  }, [basePackage?.origin, basePackage?.slug, hideMostPopularTag]);

  const handleSelectPackage = useCallback(
    (basePackage: Package) => {
      const amplitudeFriendlyTag =
        recommendedTag === RecommendedTagType.EMPTY ? 'No tag' : recommendedTag;
      dispatch({
        type: actionTypes.SET_BASE_PACKAGE,
        payload: {
          basePackage: mapPackage(basePackage),
          recommendedPackageSelected: true,
          recommendedPackageType: amplitudeFriendlyTag,
        },
      });
      updateContinueButtonState();
    },
    [dispatch, recommendedTag, updateContinueButtonState],
  );

  useEffect(() => {
    if (!state.selectedPackage.name && selected) {
      handleSelectPackage(basePackage);
    }
  }, [
    basePackage,
    dispatch,
    handleSelectPackage,
    availableForManualBulk,
    recommendedTag,
    selected,
    state.selectedPackage.name,
  ]);

  const handleChoiceCardClick = useCallback(() => {
    if (availableForManualBulk) {
      setIsChecked(!isChecked);
      handleSelectPackage(basePackage);
      onClick();
    }
  }, [
    availableForManualBulk,
    isChecked,
    handleSelectPackage,
    basePackage,
    onClick,
  ]);

  const handleLinkClick = useCallback((event: Event) => {
    event.stopPropagation();
    setShowMore(true);
  }, []);

  const handleShowMoreMobileClick = useCallback((e: Event) => {
    e.preventDefault();
    setShowMoreMobile(true);
  }, []);

  if (!basePackage || !basePackage.name) {
    return (
      <Container checked={isChecked}>
        <M.LoadingInline />
      </Container>
    );
  }

  return (
    <ConditionalWrapper
      key={basePackage.id}
      condition={!availableForManualBulk}
      wrapper={UnavailableForManualBulkTooltip}
    >
      <Container
        data-testid={`package-choice-card-button-${idx}`}
        checked={isChecked}
        onClick={handleChoiceCardClick}
        className={`${!availableForManualBulk ? 'card-disabled' : ''}`}
      >
        <RecommendedTag
          visible={Boolean(recommendedTag !== RecommendedTagType.EMPTY)}
        >
          {recommendedTag}
        </RecommendedTag>
        <RadioContainer>
          {basePackage.name.length > 47 && availableForManualBulk ? (
            <Tooltip label={basePackage.name}>
              <PackageNameLabel>{basePackage.name}</PackageNameLabel>
            </Tooltip>
          ) : (
            <PackageNameLabel>{basePackage.name}</PackageNameLabel>
          )}
          <RadioButton checked={isChecked} />
        </RadioContainer>
        {!isAllCustom && (
          <Blurb className='p4' data-testid={`blurb-${basePackage.name}`}>
            {package_blurb}
          </Blurb>
        )}
        {showPackagePrice && <PackagePrice basePackage={basePackage} />}
        <BreakLine />
        <ScreeningListContainer showMore={showMoreMobile}>
          <ScreeningList data-testid={`screening-ul-${idx}`}>
            {filteredBasePackage.screenings
              ?.slice(0, showMore ? undefined : SCREENINGS_CUTOFF)
              .map((screening: Screening) => (
                <ScreeningListItem key={`${screening.type}`}>
                  <ScreeningType
                    data-testid={`screening-type-${screening.type}`}
                  >
                    {basePackage.international_only &&
                    screening.type === 'international_criminal_search_v2'
                      ? getScreeningType(
                          'international_criminal_or_adverse_media',
                        )
                      : getScreeningType(screening.type)}{' '}
                    <ScreeningSettings>
                      {`${getScreeningSetting(
                        screening,
                        basePackage?.screening_settings,
                      )}`}
                    </ScreeningSettings>
                    {isInternationalMotorVehicleReport(screening.type) && (
                      <ScreeningSettings>
                        {`${getIncludeDrivingHistorySetting(screening)}`}
                      </ScreeningSettings>
                    )}
                  </ScreeningType>
                </ScreeningListItem>
              ))}
          </ScreeningList>
          {filteredBasePackage.screenings &&
            !showMore &&
            filteredBasePackage?.screenings?.length > SCREENINGS_CUTOFF && (
              <ShowMoreLink visited={false} onClick={handleLinkClick}>
                Show more
                <M.Icon size='20' icon='ChevronDown' />
              </ShowMoreLink>
            )}
          <AliasTag basePackage={basePackage} />
        </ScreeningListContainer>

        {!showMoreMobile && (
          <ShowMoreLinkMobile onClick={handleShowMoreMobileClick}>
            Show more
            <M.Icon size='18' icon='ChevronDown' />
          </ShowMoreLinkMobile>
        )}
      </Container>
    </ConditionalWrapper>
  );
};

export default PackageChoiceCard;
