import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const MContainerRowNoPadding = styled(M.Container.Row)`
  padding: 0px !important;

  .cds--search {
    width: 330px;
  }
`;

export default MContainerRowNoPadding;
