import { useContextSelector, createContext } from 'use-context-selector';
import {
  TransitionCallback,
  TransitionHelper,
} from '@dashboard-experience/utils';

import { Report } from 'types';
import initialState from './initial-state';
import { FormData, Dispatch } from '../types';

type ContextType = {
  open: boolean;
  onClose: () => void;
  currentScreen: string;
  transitionToScreen: TransitionCallback;
  previousScreen: TransitionHelper;
  nextScreen: TransitionHelper;
  formData: FormData;
  report: Report;
  dispatch: Dispatch;
};

export const context = createContext<ContextType>({
  currentScreen: '',
  open: false,
  onClose: () => {},
  transitionToScreen: () => {},
  previousScreen: () => {},
  nextScreen: () => {},
  formData: initialState,
  report: { id: '' },
  dispatch: () => {},
});

export const useModalState = (selector: keyof ContextType) => {
  const ctx = useContextSelector(context, state => {
    // TODO: type properly
    return state[selector] as any;
  });

  if (ctx === undefined) {
    throw new Error(
      'useModalState must be used within a Exam Reorder modal provider',
    );
  }

  return ctx;
};

export const useDispatch = () =>
  useContextSelector(context, ({ dispatch }) => dispatch);
