import React, { useState, useCallback, useContext } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import ButtonSelection from 'components/AddScreenings/shared/SetupModalButtonSelection';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import { useOrderBackgroundCheckContext } from 'components/Packages/OrderBackgroundCheck/Context';
import CreatePackageContext from 'components/Packages/CreatePackage/Context/context';
import {
  ExpandableTitle,
  BoxDivider,
  BoxHeader,
  Flex,
  Box,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  BulletPoint,
  TitleWithTag,
  FlexContainerNoBorder,
} from '../../../shared/ListItemElements';
import DrugScreeningSetupModal from './DrugScreeningSetupModal';

const Dropdown = styled(M.Dropdown)`
  width: 275px !important;
  @media (max-device-width: 530px) {
    width: 200px !important;
  }
  .cds--list-box__invalid-icon {
    z-index: 1 !important;
  }
  .cds--list-box__label {
    color: ${colors.brandSlate6} !important;
  }
`;

const Container = styled.div`
  margin-left: 32px;
  margin-bottom: 24px;
  margin-top: -10px;
`;

const getDrugScreenLabel = (drugScreen: any) => {
  return drugScreen.label;
};

const DrugScreenings: React.FC<any> = ({
  shouldOpen,
  actionProps,
  drugScreenTypes,
  basePackage,
  drugSetupStatus,
}) => {
  const createPackageContext = useContext(CreatePackageContext);
  const selectedAddOnDrugScreenFromParam =
    createPackageContext?.reducerState?.additionalProperties?.drug_screening
      ?.selectedDrugScreen?.name;
  const { state } = useOrderBackgroundCheckContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDrugScreen, setSelectedDrugScreen] = useState<any>({});
  const [drugScreenAddon, setDrugScreenAddon] = useState<any>({
    type: 'drug_screening',
    subtype: ['DS_10_PANEL'],
    test_reason: [true, 'PRE_EMPLOYMENT'],
    externally_ordered: false,
    requires_observed_collection: false,
  });
  const [isInvalid, setIsInvalid] = useState(false);

  const { onAddClick, onRemoveClick, disabledScreenings, addedScreenings } =
    actionProps;

  const subtypeProps = {
    kind: addedScreenings.includes('drug_screening') ? 'tertiary' : 'secondary',

    onClick: () =>
      // do not remove this disable
      // eslint-disable-next-line no-nested-ternary
      addedScreenings.includes('drug_screening')
        ? onRemoveClick('drug_screening')
        : selectedDrugScreen.name
        ? onAddClick('drug_screening', {
            subtype: [selectedDrugScreen.name],
            selectedDrugScreen,
          })
        : setIsInvalid(true),

    disabled: disabledScreenings.includes('drug_screening'),
    hasBasePackageScreenings: !!basePackage?.screenings?.length,
    id: addedScreenings.includes('drug_screening') ? 'delete-trash-can' : '',
  };

  const existingScreening =
    basePackage?.screenings.find(
      (screening: any) => screening.type === 'drug_screening',
    ) || state?.additionalProperties?.drug_screening;
  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback((drugStatus: string) => {
    setIsOpen(false);
  }, []);

  return (
    <>
      {isOpen && (
        <DrugScreeningSetupModal open={isOpen} handleClose={handleClose} />
      )}
      <FlexContainerNoBorder>
        <ExpandableTitle
          initialExpanded={shouldOpen}
          data-testid='expandable-drug_screening_section'
          title={
            <TitleWithTag
              getPriceByType
              actionProps={actionProps}
              title='Drug Screening'
              priceByType='drug_screening'
              priceVaries
              shouldRenderDrugSetupInital
              setupStatus={drugSetupStatus}
            />
          }
        >
          <ScreeningDescriptionContainer>
            <ScreeningInfo>
              Tests candidates for a broad range of substances to reduce the
              costs and risks of drug use.{` `}
              <LearnMoreLink
                style={{ textDecoration: 'underline' }}
                linkText='Learn more'
                data-testid='drug-screening-learn-more-link'
                href='https://help.checkr.com/hc/en-us/articles/221850088'
              />
            </ScreeningInfo>
            {/* <TurnAroundTime /> */}
          </ScreeningDescriptionContainer>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <ul>
                <BulletPoint text='Helps comply with industry-specific laws and regulations; as well as corporate policies' />
                <BulletPoint text='Numerous options and combinations to select' />
                <BulletPoint text='Access to more than 6,100 clinics across the country' />
              </ul>
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <ul>
                <BulletPoint text='Some states have more specific laws or guidelines' />
              </ul>
            </Box>
          </Flex>
        </ExpandableTitle>
        <ButtonSelection
          status={drugSetupStatus}
          addedScreenings={addedScreenings}
          subtypeProps={subtypeProps}
          handleOpen={handleOpen}
          screening='drug_screening'
        />
      </FlexContainerNoBorder>
      {/*
        Here we'll change to "|| drugSetupStatus === 'pending'" to modify the behavior
      */}
      {drugSetupStatus === 'enabled' && (
        <Container>
          <Dropdown
            label='Select a drug screening'
            data-testid='drug_screening_dropdown'
            id='drug_screening_dropdown'
            items={drugScreenTypes}
            itemToString={getDrugScreenLabel}
            // do not remove this disable
            // eslint-disable-next-line react/jsx-no-bind
            onChange={({ selectedItem }: any) => {
              setSelectedDrugScreen(selectedItem);
              setIsInvalid(false);
              setDrugScreenAddon({
                ...drugScreenAddon,
                subtype: [selectedItem.name],
              });
            }}
            disabled={
              addedScreenings.includes('drug_screening') ||
              disabledScreenings.includes('drug_screening')
            }
            invalid={isInvalid}
            invalidText='Drug screen required'
            selectedItem={drugScreenTypes.find(
              (screen: any) =>
                screen?.name === existingScreening?.subtype[0] ||
                screen?.name === selectedAddOnDrugScreenFromParam,
            )}
          />
        </Container>
      )}
    </>
  );
};

export default DrugScreenings;
