/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { toastSuccess, toastError, reloadReport } from 'actions';
import {
  updateCandidateEmploymentHistorySettings,
  CandidateEmploymentHistoriesParams,
} from './actions';

// eslint-disable-next-line import/prefer-default-export
export const useUpdateCandidateEmploymentHistorySettings = (
  resultId: string,
) => {
  const dispatch = useDispatch();
  const request = (params: CandidateEmploymentHistoriesParams) =>
    updateCandidateEmploymentHistorySettings(resultId, params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Employment settings have been saved'));
      // Reload report after the toast is shown
      setTimeout(() => dispatch(reloadReport()), 2000);
    },
    onError: () => {
      dispatch(toastError('Error saving employment settings'));
    },
  });

  return {
    call,
    result,
  };
};
