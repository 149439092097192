import { createContext } from 'use-context-selector';
import {
  Report,
  Packages,
  Package,
  Exception,
  EtaPredictions,
  GenericObject,
} from 'types';

type Context = {
  candidateId: string | undefined;
  isLoading: boolean;
  isError: boolean;
  packagesLoading: boolean;
  packageObj: Package | undefined;
  packages: Packages;
  recordsFilter: string;
  report: Report;
  reportExceptions?: {
    exceptions: Exception[];
    meta: GenericObject;
  };
  reportId: string;
  screenings: string[];
  upgradePackages: Packages;
  eta: EtaPredictions;
};

const ReportContext = createContext<Context>({
  candidateId: '',
  isLoading: false,
  isError: false,
  packagesLoading: false,
  packageObj: undefined,
  packages: [],
  recordsFilter: '',
  report: { id: '' },
  reportExceptions: {
    exceptions: [],
    meta: {},
  },
  reportId: '',
  screenings: [],
  upgradePackages: [],
  eta: {
    isLoading: false,
    isError: false,
  },
});

export default ReportContext;
