import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Report } from 'types';
import ReportBadge from './ReportBadge';

type ReportSelectionButtonProps = {
  reports: Report[];
  selectedReport?: Report;
  updateUrl: Function;
};

const ReportSelectionButtons = React.forwardRef<
  HTMLDivElement,
  ReportSelectionButtonProps
>(({ reports, selectedReport, updateUrl }, ref) => {
  // For button click
  const onClick = useCallback(
    ({ currentTarget: { name }, currentTarget }) => {
      const reportOrInvite = currentTarget.getAttribute('data-kind');
      updateUrl(name, reportOrInvite);
    },
    [updateUrl],
  );

  return (
    <M.FloatingButtons ref={ref}>
      {reports.map(report => (
        <M.FloatingButton
          key={report.id}
          active={report === selectedReport}
          name={report.id}
          onClick={onClick}
          data-kind={report.object}
        >
          {/* @ts-ignore TODO: Remove comment once Report type is consumed from Utils */}
          <ReportBadge report={report} />
        </M.FloatingButton>
      ))}
    </M.FloatingButtons>
  );
});

ReportSelectionButtons.displayName = 'ReportSelectionButtons';

export default ReportSelectionButtons;
