import { isEmpty, omit as _omit, pick as _pick } from 'lodash';
import * as Actions from '../actions';

export const apply = <T>(object: T, updates: Partial<T>): T => {
  return isEmpty(updates) ? object : { ...object, ...updates };
};

export const reduce = <S, P extends Partial<S>>(
  state: S,
  action: Actions.AnyWithPayload<P>,
  include?: Array<string>,
  exclude?: Array<string>,
): S => {
  const { payload } = action;
  let updates: Partial<S> = payload;
  if (include) {
    updates = _pick(updates, include);
  }
  if (exclude) {
    updates = _omit(updates, exclude);
  }
  return apply(state, updates);
};

export default reduce;
