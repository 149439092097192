import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Header = styled.h1`
  color: ${colors.brandNavy4};
`;

const Title: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const text = t(`review_and_apply.title`);
  return (
    <Header data-testid='assess-v2-ui-guideline-review-and-apply-title-header'>
      {text}
    </Header>
  );
};

export default Title;
