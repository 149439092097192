import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { useGetTrackedModals } from 'api/accounts';
import DashboardLanding from '../components/DashboardLanding';

const DashboardLandingPage: React.FC = () => {
  const currentUser = useUser();

  const { data: trackedModals, isLoading: isTrackedModalsLoading } =
    useGetTrackedModals({
      accountId: currentUser?.account?.id,
    });

  if (isTrackedModalsLoading) return <M.LoadingSpinner />;

  return (
    <div data-testid='dashboard-landing-page'>
      <DashboardLanding trackedModals={trackedModals} />
    </div>
  );
};

export default DashboardLandingPage;
