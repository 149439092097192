import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const TableHeader = styled(M.TableHeader)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const HeaderWithTooltipStyled = styled(TableHeader)`
  .tooltip-wrapper-aligned {
    display: inline-flex;
  }
  .cds--tooltip__label {
    vertical-align: top !important;
  }
  .cds--tooltip__trigger svg {
    fill: ${colors.uiTextPrimaryLight};
  }
`;

export const NoBillsYetMessage = styled.em`
  margin: 1rem;
`;

export const CustomTableExpandRow = styled(M.TableExpandRow)`
  .cds--table-expand {
    display: none;
  }
`;

export const LabelWithTooltipStyled = styled(M.StructuredListCell)`
  font-weight: bold;
  width: 20% !important;
  border-top: none;

  .tooltip-wrapper-aligned {
    display: inline-block;
  }
`;

export const DownloadLink = styled(M.Link)`
  display: block;
  flex: 1;
  text-decoration: underline;
  color: ${colors.uiNavy600};
  &:hover,
  &:visited {
    color: ${colors.uiNavy700};
  },
&:focus {
  outline: none;
},
`;

export const PayNowLink = styled(M.Link)`
  &:hover {
    text-decoration: none;
  },
&:focus {
  outline: none;
},
`;

export const ReportDownloadLink = styled(DownloadLink)`
  text-decoration: none;
`;

export const InvoiceDownloadLink = styled(DownloadLink)`
  text-decoration: none;
  color: ${colors.uiTextPrimaryLight} !important;
  display: flex;
  align-items: center;
  .icon {
    margin-left: 8px;
    color: ${colors.uiNavy600};
    height: 26px;
    width: 26px;
  },
`;
