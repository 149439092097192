import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useUser } from 'context/CurrentUser';
import StateDropdown from './StateDropdown';
import useSubmitAccountSettings from './hooks/useCreateAccountSettings';
import { IAccountSettings } from './Models';
import AccountSetupValidations from './utils/accountSettingsSchema';
import { FlexContainer, FlexRow, FlexRowAlignRight } from './style';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';

const MajorInput = styled(M.Input)`
  width: 20rem;
`;

const MinorInput = styled(M.Input)`
  width: 10rem;
`;

const MinorStateDropdown = styled(StateDropdown)`
  width: 10rem;
`;

interface AccountSetupFormProps {
  onContinueFormSubmitted: () => void;
  tosVersion?: number;
}

const AccountSetupForm = ({
  onContinueFormSubmitted,
  tosVersion,
}: AccountSetupFormProps) => {
  const user = useUser();

  const initialValues: IAccountSettings = {
    employer_name: user.account?.company?.name || '',
    terms_of_service_agreement: {
      version: tosVersion || 0,
    },
    default_worksite: {
      street_line1: '',
      street_line2: '',
      city: '',
      state: '',
      zip_code: '',
    },
  };

  const {
    submit,
    accountSettingsCreatedResult: { isSuccess },
  } = useSubmitAccountSettings();

  useEffect(() => {
    if (isSuccess) {
      onContinueFormSubmitted();
    }
  }, [isSuccess, onContinueFormSubmitted]);

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: AccountSetupValidations,
      onSubmit: submit,
    });

  const handleStateChange = useCallback(
    ({ selectedItem }: any) => {
      setFieldValue('default_worksite.state', selectedItem);
    },
    [setFieldValue],
  );

  const trackEvent = useTrackEvent();

  // Track event on component mount
  useEffect(() => {
    trackEvent(I9_EVENT_NAMES.I9_ACCOUNT_SETUP_FORM_VIEWED, {
      'Page Type': 'Account Setup',
      'I9 Page Type': 'Account Setup Form',
    });
  }, [trackEvent]);

  const showError = useCallback(
    (field: string): boolean => {
      return (errors[field as keyof typeof errors] &&
        touched[field as keyof typeof touched]) as boolean;
    },
    [errors, touched],
  );

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );

  const showErrorDefaultWorksite = (field: string): string => {
    const t = (touched?.default_worksite as FormikTouched<IAccountSettings>)?.[
      field
    ];

    const e = (errors?.default_worksite as FormikErrors<IAccountSettings>)?.[
      field
    ] as string;

    return t && e ? e : '';
  };

  return (
    <>
      <FlexContainer gap='1.5rem' padding='0'>
        <FlexContainer gap='1rem' padding='0'>
          <h3>What is your legal company name?</h3>
          <MajorInput
            type='text'
            name='employer_name'
            placeholder='Enter legal name'
            width='20rem'
            value={values.employer_name}
            onChange={handleChange}
            invalid={showError('employer_name')}
            invalidText={showErrorMessage('employer_name')}
          />
        </FlexContainer>
        <FlexContainer gap='1rem' padding='0'>
          <h3>What is your primary worksite address?</h3>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <MajorInput
              type='text'
              name='default_worksite.street_line1'
              labelText='Street line 1'
              placeholder='Ex: 123 Main Street'
              value={values.default_worksite.street_line1}
              onChange={handleChange}
              invalid={!!showErrorDefaultWorksite('street_line1')}
              invalidText={showErrorDefaultWorksite('street_line1')}
            />
            <MajorInput
              type='text'
              name='default_worksite.street_line2'
              labelText='Street line 2'
              placeholder='Ex: Apartment 4'
              value={values.default_worksite.street_line2}
              onChange={handleChange}
              invalid={!!showErrorDefaultWorksite('street_line2')}
              invalidText={showErrorDefaultWorksite('street_line2')}
            />
          </FlexRow>
          <FlexRow gap='1.5rem' alignItems='baseline'>
            <MajorInput
              type='text'
              name='default_worksite.city'
              labelText='City'
              placeholder='Ex: Maple Heights'
              value={values.default_worksite.city}
              onChange={handleChange}
              invalid={!!showErrorDefaultWorksite('city')}
              invalidText={showErrorDefaultWorksite('city')}
            />
            <MinorStateDropdown
              name='default_worksite.state'
              titleText='State'
              label='Select'
              selectedItem={values.default_worksite.state}
              onChange={handleStateChange}
              invalid={!!showErrorDefaultWorksite('state')}
              invalidText={showErrorDefaultWorksite('state')}
            />
            <MinorInput
              type='text'
              name='default_worksite.zip_code'
              labelText='Zip code'
              placeholder='Ex: 12345'
              value={values.default_worksite.zip_code}
              onChange={handleChange}
              invalid={!!showErrorDefaultWorksite('zip_code')}
              invalidText={showErrorDefaultWorksite('zip_code')}
            />
          </FlexRow>
        </FlexContainer>
      </FlexContainer>
      <FlexRowAlignRight gap='0' alignItems='center'>
        <M.Button onClick={handleSubmit}>Continue</M.Button>
      </FlexRowAlignRight>
    </>
  );
};

export default AccountSetupForm;
