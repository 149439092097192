import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/rules/count';
import ComplexEditor from './complex-editor';
import Editor from './editor';
import ComplexRuleViewer from './complex-rule-viewer';
import Viewer from './viewer';

type Props = {
  rule: Entity.Type;
};

export const Container: React.FC<Props> = ({ rule }) => {
  const containerStyle = {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rule.editing ? colors.uiNavy100 : undefined,
  };

  let ViewComponent = Viewer;

  if (rule.editing) {
    ViewComponent = rule.complex ? ComplexEditor : Editor;
  } else if (rule.complex) {
    ViewComponent = ComplexRuleViewer;
  }

  return (
    <M.Container
      data-testid='assess-rules-count-rule'
      style={containerStyle}
      type='info'
    >
      <ViewComponent rule={rule} />
    </M.Container>
  );
};

export default Container;
