import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { List } from 'modules/assess/models/rulesets/version-diff/context-date-option-change';
import {
  ChangeType,
  Field,
} from 'modules/assess/models/rulesets/version-diff/common';

export type Row = { active: string; draft: string; id: string };
type OptionPosition = { option: string; position: string };
type OptionPositions = Array<OptionPosition>;
const emptyOptionPosition: OptionPosition = { option: '', position: '' };

const getActiveChanges = (changes: List): OptionPositions =>
  changes
    .filter(change => {
      return change.change_type !== ChangeType.created;
    })
    .map((change): OptionPosition => {
      const { position } = change;
      const positionUnchanged: number =
        (position as Field.FieldUnchanged<number>)?.value || 0;
      const positionChanged: number =
        (position as Field.FieldChanged<number>)?.active || 0;
      return {
        option: change.option.value,
        position: `${positionUnchanged || positionChanged}`,
      };
    })
    .sort((a, b) => Number(a.position) - Number(b.position));

const getDraftChanges = (changes: List): OptionPositions =>
  changes
    .filter(change => {
      return change.change_type !== ChangeType.deleted;
    })
    .map((change): OptionPosition => {
      const { position } = change;
      const positionUnchanged: number =
        (position as Field.FieldUnchanged<number>)?.value || 0;
      const positionChanged: number =
        (position as Field.FieldChanged<number>)?.draft || 0;
      return {
        option: change.option.value,
        position: `${positionUnchanged || positionChanged}`,
      };
    })
    .sort((a, b) => Number(a.position) - Number(b.position));

export const useRows = (changes: List): Array<Row> => {
  const activeChanges = getActiveChanges(changes);
  const draftChanges = getDraftChanges(changes);
  const maxLength = Math.max(activeChanges.length, draftChanges.length);
  return Array(maxLength)
    .fill(null)
    .map((x, index) => {
      const active: OptionPosition =
        activeChanges[index] || emptyOptionPosition;
      const draft: OptionPosition = draftChanges[index] || emptyOptionPosition;
      const activeOption = humanizeOnUnderscore(active.option);
      const draftOption = humanizeOnUnderscore(draft.option);
      const activePosition = active.position + (active.position ? '. ' : '');
      const draftPosition = draft.position + (draft.position ? '. ' : '');
      const id = `${active.position}-${draft.position}`;
      return {
        id,
        active: `${activePosition}${activeOption}`,
        draft: `${draftPosition}${draftOption}`,
      };
    });
};
