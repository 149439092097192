import { Entity } from 'modules/assess/models/mvr/category-code-set';
import T, { PageProps } from 'components/Table';
import React, { useCallback } from 'react';
import { useHeaders } from './headers';
import Expansion from './item-expansion';
import { useRows } from './rows';

type Props = {
  data?: Array<Entity>;
  page?: PageProps;
};

export const Table: React.FC<Props> = ({ data, page }) => {
  const headers = useHeaders();
  const rows = useRows(data);

  const expansion = useCallback(
    (id: string) => {
      const item = data?.find(r => r.id === id);
      return <Expansion item={item} />;
    },
    [data],
  );

  return (
    <T
      data-testid='assess-mvr-category-sets-search-table'
      expansion={expansion}
      headers={headers}
      page={page}
      rows={rows}
      style={{ marginTop: '2.5em' }}
    />
  );
};

export default Table;
