import React, { useEffect, useRef, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import {
  isDatePastEta,
  parseDateString,
} from 'components/Report/Screenings/CountyCriminal/Eta/utils';
import { FieldComponentProps } from '../../types';

const StyledWrapper = styled.div<{ tooltipOffset: number }>`
  display: flex;
  align-items: center;
  z-index: 100;
  .mastodon-toggletip-content {
    max-width: 18rem !important;
  }

  .cds--popover--top:not(.cds--popover--auto-align)
    > .cds--popover
    > .cds--popover-content {
    transform: translate(
      calc(-50% - ${props => props.tooltipOffset}px),
      calc(-100% - var(--cds-popover-offset, 0rem))
    );
  }

  .cds--toggletip-button {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .tooltip-header {
    font-weight: bold !important;
    margin-bottom: 0.5rem !important;
  }

  .tooltip-link {
    color: ${colors.uiNavy600} !important;
    margin-top: 0.5rem;

    &:hover {
      color: ${colors.uiNavy700};
    }
  }
`;

const EstimatedCompletion: React.FC<
  Pick<FieldComponentProps, 'reportPrediction' | 'testid'>
> = ({ reportPrediction, testid }) => {
  const wrapperRef = useRef(null);
  const iconRef = useRef(null);
  const [tooltipOffset, setTooltipOffset] = useState(0);

  useEffect(() => {
    const parent = wrapperRef.current;
    const child = iconRef.current;

    if (parent && child) {
      // @ts-ignore
      const parentRect = parent.getBoundingClientRect();
      // @ts-ignore
      const childRect = child.getBoundingClientRect();

      const parentMidX = parentRect.left + parentRect.width / 2;
      const offset = childRect.left - parentMidX;

      setTooltipOffset(offset + 45);
    }
  }, []);

  return (
    <StyledWrapper
      ref={wrapperRef}
      data-testid={testid}
      tooltipOffset={tooltipOffset}
    >
      <span>{parseDateString(reportPrediction, true)}</span>
      <M.Toggletip align='top' tabIndex={0}>
        <M.ToggletipButton>
          <M.Icon icon='HelpFilled' ref={iconRef} />
        </M.ToggletipButton>
        <M.ToggletipContent>
          <p className='tooltip-header'>Report Estimated Completion</p>
          {!isDatePastEta(reportPrediction) ? (
            <p>
              This date is based on historical turnaround times for all searches
              in the check.
            </p>
          ) : (
            <p>
              While we can accurately predict completion dates for more than 90%
              of reports, some take longer than expected. We&apos;re working to
              complete your report as quickly as possible.
            </p>
          )}
        </M.ToggletipContent>
      </M.Toggletip>
    </StyledWrapper>
  );
};

export default EstimatedCompletion;
