import React from 'react';
import { useUser } from 'context/CurrentUser';
import { useLastReportTask } from 'modules/case-management/ui/TasksPage/hooks';
import getFields from './get-fields';
import withReportContentContainer from '../report-content-container';
import { buildColumnConfig } from '../utils';
import { KeyValueList } from '../styles';
import { SectionProps } from '../types';

const ReportInformation: React.FC<SectionProps> = ({ report, statusType }) => {
  const user = useUser();
  const task = useLastReportTask({ report });
  const fields = getFields({ report, task });

  const reportInformation = buildColumnConfig({
    fields,
    report,
    statusType,
    user,
  });

  return fields.length > 0 ? (
    <KeyValueList
      withPadding
      items={reportInformation}
      data-pendo-id='report-information-panel'
      data-testid='overview-report-information'
    />
  ) : null;
};

export default withReportContentContainer(ReportInformation);
