/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React from 'react';
import styled from 'styled-components';

import * as Notifications from 'state/notifications';
import Notification from './NotificationContainer';
import { useEntries } from './hooks';

type Props = {};

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999; // This needs to be ridiculously high specifically to supersede the z-axis that Pendo badges use
`;

export const NotificationsContainer: React.FC<Props> = () => {
  const entries = useEntries();
  const elements = entries.map((data: Notifications.Entity.Type) => {
    return <Notification key={data.id} data={data} />;
  });

  return <Container>{elements}</Container>;
};

export default NotificationsContainer;
