import { useEffect, forwardRef, ForwardRefRenderFunction } from 'react';
import findKey from 'lodash/findKey';
import { useRemoveToast } from 'hooks';
import useReorderableHealthScreeningToast from './useReorderableHealthScreeningToast';
import { NotificationProps } from './types';

const HealthScreeningReorderNotification: ForwardRefRenderFunction<
  HTMLDivElement,
  NotificationProps
> = ({ report, tabIndices, tab, updateTab }, ref) => {
  const removeToast = useRemoveToast('reorderable_health_screening_toast');
  const healthTabIndex = findKey(
    tabIndices,
    (value: string) => value === 'Health Screening',
  );

  const tabsRef = ref as React.RefObject<HTMLDivElement>;

  useReorderableHealthScreeningToast({
    report,
    healthTabIndex,
    tab,
    updateTab,
    ref: tabsRef,
  });

  useEffect(() => removeToast, [removeToast]);

  return null;
};

export default forwardRef(HealthScreeningReorderNotification);
