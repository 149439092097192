import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Namespace } from 'modules/assess/api';
import { Category as CategoryType } from 'modules/assess/models/rules';
import { Type } from 'modules/assess/models/rules/premade';
import { Quickstart } from 'modules/assess/v2/models/amplitude/guidelines';
import { Count, StyledComponents } from 'modules/assess/v2/ui/common';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import List from 'modules/assess/v2/ui/common/premade/table/category/List';
import Actions from './Actions';
import { getCategoryIcon } from './utils';
import Title from './Title';

const StyledIcon = styled.img`
  display: inline-block;
`;

const StyledTitle = styled(Title)`
  justify-content: start;
  display: inline-block;
`;

const ActionsContainer = styled.div`
  justify-content: end;
  display: flex;
  justify-content: flex-start;
`;

const Category = styled(M.Container.Row)`
  cursor: pointer;
`;

export type Props = {
  category: CategoryType;
  namespace: Namespace;
  rules: Array<Row>;
};

export const Container: React.FC<Props> = ({ category, namespace, rules }) => {
  const { t } = useTranslation('assess:v2');
  const title = t(
    `standard.guidelines.quickstart.categories.${namespace}.${category}.title`,
  );
  const track = useTrackEvent();

  const handleRuleUnselected = useCallback((rule: Type) => {
    const element = document.getElementById(
      `templated-added-guideline-${rule.code}`,
    );
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, []);

  const [showRules, setShowRules] = useState(false);
  const handleShowRules = useCallback(() => {
    const updatedState = !showRules;
    setShowRules(updatedState);
    if (updatedState) {
      track(Quickstart.CategoryExpanded, { category: title });
    }
  }, [showRules, title, track]);

  const selectedRules = rules.filter(r => r.isSelected);
  if (rules.length === 0) return null;

  const basePath = 'assess-v2-ui-guideline-quickstart-table-category-container';
  const titleId = `${basePath}-title`;
  const iconId = `${basePath}-icon`;
  const countId = `${basePath}-count`;
  const listId = `${basePath}-list-container`;
  const showRulesId = `${basePath}-show-rules`;
  const icon = getCategoryIcon(category);

  return (
    <StyledComponents.NavyContainer>
      <Category onClick={handleShowRules} data-testid={basePath}>
        <StyledIcon src={icon} data-testid={iconId} />
        <StyledTitle text={title} data-testid={titleId} />
        <ActionsContainer>
          <Count
            current={selectedRules.length}
            total={rules.length}
            data-testid={countId}
          />
          <Actions isOpen={showRules} data-testid={showRulesId} />
        </ActionsContainer>
      </Category>
      {showRules && (
        <List
          handleRuleUnselected={handleRuleUnselected}
          rules={rules}
          data-testid={listId}
        />
      )}
    </StyledComponents.NavyContainer>
  );
};

export default Container;
