import React, { useContext } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import AuthRoute from 'utils/AuthRoute';
import ApiKeysPage from './ApiKeysPage';
import AccountTabsPage from './AccountTabsPage';
import BrandPage from './BrandPage';
import BrandsPage from './BrandsPage';
import DeveloperSettingsPage from './DeveloperSettings/DeveloperSettingsPage';
import SettingsPage from './SettingsPage';
import SingleSignOnPage from './SingleSignOnPage';

import UsersPage from '../UsersPage';
import UserPage from '../UserPage';
import PreferencesPage from '../PreferencesPage';
import IntegrationsPage from '../IntegrationsPage';
import PaymentPage from './PaymentPage';
import AccountHierarchyPage from './AccountHierarchyPage';

const AccountRoutes = () => {
  const user = useUser();
  const { isIframe } = useContext(UIContext);
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const { path: base } = match;

  // TODO: remove check when tabs are iframed in Angular
  if (!isIframe) {
    // Support old route for payment
    const paymentPath = `${base}/payment`;

    if (pathname === paymentPath) {
      return <Redirect to='/billing/payment' />;
    }
    return (
      <Switch>
        <Route exact path={`${base}/:tab`} component={AccountTabsPage} />
        <Route exact path={`${base}/user/:userId`} component={UserPage} />
      </Switch>
    );
  }

  return (
    <Switch>
      <AuthRoute
        exact
        path={`${base}/api_keys`}
        component={ApiKeysPage}
        authorized={hasPermission(user, 'manage_dev_settings')}
      />
      <AuthRoute
        exact
        path={`${base}/settings`}
        component={SettingsPage}
        authorized={!!user}
      />
      <AuthRoute
        exact
        path={`${base}/single-sign-on`}
        component={SingleSignOnPage}
        authorized={
          hasPermission(user, 'manage_idp_settings') ||
          hasPermission(user, 'manage_all_idps')
        }
      />
      <AuthRoute
        exact
        path={`${base}/users`}
        component={UsersPage}
        authorized={hasPermission(user, 'read_account_users')}
      />
      <AuthRoute
        exact
        path={`${base}/developer_settings`}
        component={DeveloperSettingsPage}
        authorized={hasPermission(user, 'manage_dev_settings')}
      />
      <AuthRoute
        exact
        path={`${base}/preferences`}
        component={PreferencesPage}
        authorized={!!user}
      />
      <AuthRoute
        exact
        path={`${base}/integrations`}
        component={IntegrationsPage}
        authorized={hasPermission(user, 'manage_dev_settings')}
      />
      <AuthRoute
        exact
        path={`${base}/brands`}
        component={BrandsPage}
        authorized={
          accountHasPermission(user, 'hierarchy_present') &&
          hasPermission(user, 'manage_brands')
        }
      />
      <AuthRoute
        exact
        path={`${base}/brand/:brandId`}
        component={BrandPage}
        authorized={
          accountHasPermission(user, 'hierarchy_present') &&
          hasPermission(user, 'manage_brands')
        }
      />
      <AuthRoute
        exact
        path={`${base}/hierarchy`}
        component={AccountHierarchyPage}
        authorized={
          accountHasPermission(user, 'hierarchy_present') &&
          hasPermission(user, 'manage_account_hierarchy')
        }
      />
      <Route exact path={`${base}/brands`} component={BrandsPage} />
      <Route exact path={`${base}/brand/:brandId`} component={BrandPage} />
      <AuthRoute
        exact
        path={`${base}/ats_settings`}
        component={DeveloperSettingsPage}
        authorized={accountHasPermission(user, 'hris_configurations_enabled')}
      />
      {/* Support old route for payment */}
      <AuthRoute
        exact
        path='/account/payment'
        component={PaymentPage}
        authorized={hasPermission(user, 'update_billing')}
      />
    </Switch>
  );
};

export default AccountRoutes;
