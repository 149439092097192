import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { capitalize } from 'lodash';
import styled from 'styled-components';

const BodyText = styled.div`
  color: ${colors.uiGrey900};
  padding-bottom: 24px;
`;

const Table = styled.table`
  color: ${colors.uiTextPrimaryLight};
`;

const TableRow = styled.tr`
  height: 28px;
`;

const TableHeader = styled.th`
  font-weight: bold;
  text-align: left;
`;

const Tablekey = styled.td`
  width: 144px;
`;

type Props = {
  open: boolean;
  hideModal: Function;
  report: any;
};

const NodesModal: React.FC<Props> = ({ open, hideModal, report }) => {
  return (
    <M.ComposedModal open={open} onClose={hideModal}>
      <M.ModalHeader title='Nodes details' buttonOnClick={hideModal} />
      <M.ModalBody>
        <BodyText>
          Use nodes to manage access and settings specific to users, packages,
          brands, and other aspects of your Checkr account.
        </BodyText>
        {!!report.segment_stamps && (
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Tier</TableHeader>
              </TableRow>
            </thead>
            {[...report.segment_stamps]
              .reverse()
              .map((segment: string, index: number) => {
                const [tier, name] = segment.split('|');
                return (
                  <TableRow key={segment}>
                    <Tablekey
                      style={{ fontWeight: index === 0 ? 'bold' : 'normal' }}
                    >
                      {name}
                    </Tablekey>
                    <td>
                      {tier ? (
                        <span>{capitalize(tier)}</span>
                      ) : (
                        <span style={{ color: colors.uiTextTertiaryLight }}>
                          N/A
                        </span>
                      )}
                    </td>
                  </TableRow>
                );
              })}
          </Table>
        )}
      </M.ModalBody>

      <M.ModalFooter
        actions={{
          primary: {
            name: 'Got it',
            onClick: hideModal,
          },
        }}
      />
    </M.ComposedModal>
  );
};

export default NodesModal;
