import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { map } from 'lodash/fp';
import { ICohort } from './Cohort/types';
import Cohort from './Cohort';

interface Props {
  cohorts: ICohort[];
}

const StyledLink = styled(Link)`
  color: #48525e !important;
`;

const RandomizerBody: FC<Props> = ({ cohorts }) => {
  return (
    <M.Grid>
      {cohorts.length &&
        map(
          cohort => (
            <M.GridRow key={cohort.id} data-testid={cohort.id}>
              <M.GridCol>
                <StyledLink to={`/random_testing_program/pool/${cohort.id}`}>
                  <Cohort cohort={cohort} />
                </StyledLink>
              </M.GridCol>
            </M.GridRow>
          ),
          cohorts,
        )}
    </M.Grid>
  );
};

export default RandomizerBody;
