import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

import * as Entity from 'modules/assess/models/rules/custom';

import Editor from './editor';
import Viewer from './viewer';

type Props = {
  rule: Entity.Type;
};

export const Container: React.FC<Props> = ({ rule }) => {
  const containerStyle = {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rule.editing ? colors.uiNavy100 : undefined,
  };

  return (
    <M.Container
      data-testid='assess-rules-custom-rule'
      style={containerStyle}
      type='info'
    >
      {rule.editing ? (
        <Editor key={rule.id} rule={rule} />
      ) : (
        <Viewer key={rule.id} rule={rule} />
      )}
    </M.Container>
  );
};

export default Container;
