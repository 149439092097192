import { capitalize, includes, isEmpty, filter } from 'lodash';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import checkrAnalytics from 'components/analytics';
import { Tier, V2_TIERS } from 'modules/assess/models';
import { useConfig as useApiConfig } from 'modules/assess/ui/api/config';
import { useConfig, useUser, useUserAccount } from 'modules/assess/ui/hooks';
import {
  useActive,
  useDraft,
  useDraftDiff,
} from 'modules/assess/ui/ruleset/version/api';
import { useState as useRulesetVersionState } from 'modules/assess/ui/ruleset/version/store';
import { ACCEPT_LESS_THAN_MISDEMEANOR } from 'modules/assess/v2/models/rules/base';
import { ID } from 'modules/id';
import { Ruleset } from '../../models/rulesets/default';
import { Type as Amplitude } from '../models/amplitude';
import { useDefaultRulesets } from './api';
import { formatString, isEmptyDiff, isEmptyRules } from './utils';

export const analytics = checkrAnalytics as any;

export function useDefaultCriminalRuleset(): Ruleset | undefined {
  const rulesets = useRulesets();
  return rulesets?.criminal;
}

export function useStartPage(): boolean | undefined {
  const rulesetId = useDefaultCriminalRuleset()?.id;
  const { data } = useActive(rulesetId);
  if (!data) {
    return undefined;
  }

  const { rules } = data;
  return isEmptyRules(rules);
}

export function useStateWithProp<T>(
  prop: T,
): [T, React.Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(prop);

  useEffect(() => {
    setValue(prop);
  }, [prop]);
  return [value, setValue];
}

export const useTrackEvent = () => {
  const user = useUser();
  const tier = useTier();
  const capitalizedTier = capitalize(tier);

  return useCallback(
    (event: Amplitude, eventProperties: GenericObject = {}) => {
      const formatEvent = formatString(event, [capitalizedTier]);
      analytics.track(user, formatEvent, eventProperties);
    },
    [capitalizedTier, user],
  );
};

export function useDisableReview(ruleset?: ID) {
  const { data: draft, isLoading } = useDraft(ruleset);
  const { data: diff, isLoading: isLoadingDiff } = useDraftDiff(ruleset);
  const { dirty } = useRulesetVersionState();
  const loading = isLoading || isLoadingDiff;
  const isDiffEmpty = isEmpty(diff) || isEmptyDiff(diff);
  const isDraftEmpty = isEmptyRules(draft?.rules);
  const disabled = dirty ? false : isDiffEmpty || isDraftEmpty;

  return {
    disabled,
    isLoading: loading,
  };
}

export function useV2() {
  const accountId = useUserAccount()?.id;
  const config = useApiConfig(accountId);
  const result = includes(V2_TIERS, config.data?.tier);

  return {
    loading: config.isLoading,
    result,
  };
}

// TODO: EADA-3942 - [Assess v1/v2] move two hooks out of v2
export function useBreadcrumbTitle(): string {
  const isLite = useLite();
  const isStandard = useStandard();
  if (isLite) {
    return 'Assess Lite';
  }
  if (isStandard) {
    return 'Assess Standard';
  }

  return 'Assess Premium';
}

export function useLessThanMisdemeanorPremadeRule(): boolean {
  const state = useRulesetVersionState();
  const filteredRule = filter(state.rules?.premade.table, {
    code: ACCEPT_LESS_THAN_MISDEMEANOR,
  });

  return filteredRule.length === 1;
}

export function usePremium(): boolean | null {
  const assess = useUserAccount()?.assess;
  if (!assess) {
    return null;
  }

  return assess?.tier === Tier.PREMIUM;
}

export function useStandard(): boolean | null {
  const config = useConfig();
  if (!config) {
    return null;
  }

  return config.tier === Tier.STANDARD;
}

export function useLite(): boolean | null {
  const config = useConfig();
  if (!config) {
    return null;
  }

  return config.tier === Tier.LITE;
}

export function useTier(): Tier | undefined {
  const config = useConfig();
  return config?.tier;
}

function useRulesets() {
  const accountId = useUserAccount()?.id;
  return useDefaultRulesets(accountId).data;
}

export function useIsVisible(ref: React.MutableRefObject<HTMLDivElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    if (ref.current) observer.observe(ref.current!);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
