import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { BrandFooterContainer, BrandFooterItemRight } from 'components';

type Props = {
  disableSave: boolean;
  onCloseModal: Function;
  updateAndRefresh: Function;
};

const EditBrandFooter = ({
  disableSave = false,
  onCloseModal,
  updateAndRefresh,
}: Props) => {
  return (
    <BrandFooterContainer>
      <BrandFooterItemRight>
        <M.Button
          kind='achievement'
          disabled={disableSave}
          onClick={updateAndRefresh}
          data-testid='save-btn'
        >
          Save
        </M.Button>
        <M.Button kind='primary' disabled={false} onClick={onCloseModal}>
          Close
        </M.Button>
      </BrandFooterItemRight>
    </BrandFooterContainer>
  );
};

export default EditBrandFooter;
