import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { Assess } from 'components';
import { useLite } from 'modules/assess/v2/ui/hooks';
import Missing from 'modules/assess/ui/router/missing';
import DraftCreator from 'modules/assess/ui/ruleset/version/DraftCreator';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import ReviewAndApply from 'modules/assess/v2/ui/review-and-apply';
import LiteGuideline from 'modules/assess/v2/ui/lite';
import StandardGuideline from 'modules/assess/v2/ui/standard/guideline';
import Guidelines from './guidelines';

export const Draft: React.FC = () => {
  const match = useRouteMatch();
  const { path: base, url } = match;

  const query = useRuleset();
  const loading = query?.status === 'loading';
  const ruleset = query?.data;
  const version = ruleset?.draft;
  const id = version && typeof version === 'object' ? version.id : version;
  const Guideline = useLite() ? LiteGuideline : StandardGuideline;

  if (loading) {
    return <M.LoadingSpinner />;
  }

  if (!ruleset) {
    return <Missing />;
  }

  if (!id) {
    return <DraftCreator ruleset={ruleset.id} />;
  }

  return (
    <Switch>
      <Route path={`${base}/review-and-apply`}>
        <Assess.Route.Breadcrumb
          location={`${url}/review-and-apply`}
          title='Review your guidelines'
        >
          <ReviewAndApply ruleset={ruleset.id} />
        </Assess.Route.Breadcrumb>
      </Route>
      <Route path={`${base}/guidelines`}>
        <Guidelines ruleset={ruleset.id} />
      </Route>
      <Route path={base}>
        <Guideline ruleset={ruleset.id} />
      </Route>
    </Switch>
  );
};

export default Draft;
