/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useShowReportLog } from 'providers/Candidate/hooks';
import ReturnToLegacyBannerPhaseTwo from './return-to-legacy-banner-phase-two';
import { ContentContainer } from '../components';
import * as Styles from './styles';
import Content from './content';
import EntriesPhaseTwo from './entries-phase-two';

type Props = {
  setShowOverview: Dispatch<SetStateAction<boolean>>;
  showOverview: boolean;
  setIsScrolled: Dispatch<SetStateAction<boolean>>;
  isScrolled: boolean;
};

const MainSection: React.FC<Props> = ({
  showOverview,
  setShowOverview = () => {},
  setIsScrolled,
  isScrolled,
}) => {
  const [selectedTab, setSelectedTab] = useState('Results');

  const icon = showOverview ? 'SidePanelOpen' : 'SidePanelClose';
  const label = showOverview
    ? 'Hide additional information'
    : 'Show additional information';
  const toggleShowOverview = useCallback(
    () => setShowOverview(s => !s),
    [setShowOverview],
  );

  const showReportLog = useShowReportLog();

  const tabs = [
    { label: 'Results', content: <Content /> },
    { label: 'Report log', content: <EntriesPhaseTwo /> },
  ];

  return (
    <Styles.TabsSection data-testid='candidate-tabs-section'>
      <div data-testid='candidate-tabs-header'>
        <div
          style={{
            alignItems: 'center',
            marginRight: 'auto',
            paddingTop: '.25rem',
            paddingLeft: '1.25rem',
            width: '100%',
          }}
        >
          <M.Tabs>
            <Styles.TabsList>
              {tabs.map(({ label }) => (
                <M.Tab
                  data-testid={label}
                  key={label}
                  onClick={() => setSelectedTab(label)}
                >
                  {label}
                </M.Tab>
              ))}
              <ReturnToLegacyBannerPhaseTwo
                overViewContainerOpen={showOverview}
              />
            </Styles.TabsList>
          </M.Tabs>
        </div>
        <Styles.ButtonContainer
          showOverview={showOverview}
          data-testid='candidate-tabs-toggle'
        >
          <M.TooltipButton
            data-pendo-id='candidate-tabs-toggle'
            align='bottom-right'
            label={label}
            kind='secondary'
            onClick={toggleShowOverview}
          >
            <M.Icon data-icon={icon} icon={icon} size={20} />
          </M.TooltipButton>
        </Styles.ButtonContainer>
      </div>
      <ContentContainer
        setIsScrolled={setIsScrolled}
        isScrolled={isScrolled}
        showReportLog={showReportLog}
      >
        {tabs.find(tab => tab.label === selectedTab)?.content}
      </ContentContainer>
    </Styles.TabsSection>
  );
};

export default MainSection;
