import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useUser } from 'context/CurrentUser';
import checkrAnalytics from 'components/analytics';
import { initializeAndStart } from 'components/amplitudeExperiment';

import { isEmpty } from 'lodash';
import { setUserProperties } from 'utils';
import { useModal } from '../providers/Modal';

const AnalyticsWrapper: React.FC = ({ children }: any) => {
  const userData = useUser();
  const modalContext = useModal();
  const ckrAnalytics = checkrAnalytics as any;

  const userProperties = setUserProperties(userData);

  useEffect(() => {
    const pendoUserData = {
      ...userData,
      prompt_compliance: modalContext.prompt_compliance,
    };

    if (userData && !isEmpty(ckrAnalytics)) {
      try {
        // Don't initialize pendo in e2e automation
        if (!navigator.webdriver) {
          ckrAnalytics.initializePendo(window, pendoUserData);
        }
      } catch (err) {
        Sentry.captureEvent(err as any);
      }

      try {
        initializeAndStart(userProperties);
      } catch (err) {
        console.error(err);
      }
    }
    if (
      ckrAnalytics.shouldIdentifyUser(pendoUserData) &&
      !isEmpty(ckrAnalytics)
    ) {
      ckrAnalytics.identify(pendoUserData);
    }
  }, [userData, modalContext, ckrAnalytics, userProperties]);

  return children;
};

export default AnalyticsWrapper;
