import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Level } from 'modules/assess/models/rules/lookback';
import {
  useState as useStateContext,
  useDispatch,
} from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/lookback';
import { HEADERS } from '../constants';
import { ChargeState, ChargeYears, Severity } from './types';
import { Charge as ChargeType } from '../types';
import ChargeName from './ChargeName';
import SeverityRow from './SeverityRow';

const ChargeContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

export type ChargeUnion = ChargeType | ChargeState;

type Props = {
  charge: ChargeUnion;
};

const headers = HEADERS.slice(1);

export const severity = headers.reduce((acc, el) => {
  acc[el.key] = {
    eligible: null,
    escalated: null,
  };
  return acc;
}, {} as { [key: string]: ChargeYears });

const Charge: React.FC<Props> = ({ charge }) => {
  const dispatch = useDispatch();
  const state = useStateContext();
  const { name, description } = charge;

  const baseCharge = {
    key: name,
    ...charge,
    ...severity,
  };

  const lookbackRulesList: Array<any> = Object.values(
    state.rules?.lookback.table,
  );
  const contextCharge = lookbackRulesList.find(x => x.key === name);

  const initialCharge = contextCharge || baseCharge;

  const setChargeState = useCallback(
    (data: Partial<Severity>) => {
      const type = contextCharge ? Actions.Update : Actions.Create;
      const action = type.create({
        level: Level.CATEGORY_2,
        ...initialCharge,
        ...data,
        charge_subsubcategory: name,
      });
      dispatch(action);
    },
    [contextCharge, dispatch, initialCharge, name],
  );

  return (
    <SeverityRow state={initialCharge} setState={setChargeState}>
      <M.TableCell className='charge'>
        <ChargeContainer>
          <ChargeName label={name} description={description} />
        </ChargeContainer>
      </M.TableCell>
    </SeverityRow>
  );
};

export default Charge;
