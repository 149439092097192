import React from 'react';
import Empty from './Empty';
import Added from './Added';

type Props = {
  current: Number;
  total: Number;
};

export const Container: React.FC<Props> = ({ current, total }) => {
  const noRulesSelected = current === 0;

  if (noRulesSelected) {
    return <Empty total={total} />;
  }

  return <Added current={current} total={total} />;
};

export default Container;
