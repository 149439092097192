import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';

import { useModalState, useDispatch } from '../../context';
import {
  FairChanceForm,
  FairChanceAssessment,
  FairChanceDocumentUpload,
  ChargesTable,
} from '../../../../adverse-action/fair-chance';
import Warnings from '../../../../adverse-action/initiate/flow/modal/warnings';

const FairChance: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const state = useModalState('state');
  const { adverseItems = [], individualizedAssessment } = state;
  const currentScreen = useModalState('currentScreen');
  const nextScreenAction = useModalState('nextScreen');

  const { reassessment, assessment, uploadedFile, oldReassessment } =
    individualizedAssessment;

  const enabled = uploadedFile || reassessment !== oldReassessment;

  useEffect(() => {
    const next = t(
      `${namespace}:report.actions.pre_adverse.fair_chance.buttons.next_step`,
    );
    const nextScreen = {
      onClick: nextScreenAction,
      name: next,
      disabled: !enabled,
    };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next: nextScreen,
      },
    });
    // nextScreenAction is the same function but changes on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enabled, t]);

  return (
    <div>
      <div>
        <Warnings currentScreen={currentScreen} />
        <FairChanceAssessment />
        <hr />

        {/* TODO: verify if we need to display the charges */}
        {adverseItems?.length > 0 && (
          <>
            <ChargesTable adverseItems={adverseItems} />
            <hr />
          </>
        )}
        <FairChanceForm
          dispatch={dispatch}
          type='re-assessment'
          reassessmentValue={reassessment}
          initialAssessmentValue={assessment}
        />
        <hr />
        <FairChanceDocumentUpload
          adjudicationDispatch={dispatch}
          state={state}
        />
      </div>
    </div>
  );
};

export default FairChance;
