import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const TableContainer = styled(M.TableContainer)`
  overflow: auto !important;
  padding: 0;
  margin-bottom: 1rem;

  .cds--data-table-content {
    min-width: 944px;
  }

  .mastodon-table-head {
    border-top: none !important;
  }

  .mastodon-table-select-row,
  .mastodon-table-select-all,
  .mastodon-table-header,
  .mastodon-table-cell {
    padding: 0.8rem 0.8rem 0.8rem 0 !important;
    vertical-align: top !important;

    &:first-child {
      padding-left: 1rem !important;
    }
  }
`;

export const TableCell = styled(M.TableCell)`
  .cds--link {
    cursor: pointer;

    .cds--toggletip-label {
      color: ${colors.uiNavy600};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Tooltip = styled(M.Tooltip)`
  .cds--tooltip-content {
    max-width: fit-content !important;
    width: auto;
  }

  .cds--toggletip-label {
    color: ${colors.uiGrey800};
  }
`;

export const Accordion = styled(M.Accordion)`
  margin-top: 0.6rem;

  .cds--accordion__item {
    border: none;
  }

  .cds--accordion__content {
    padding: 0;
    margin-top: 0.4rem;
  }

  .cds--accordion__title {
    margin: 0 0.4rem 0 0;
    padding: 0;
  }

  .cds--accordion__arrow {
    margin-right: 0;
  }

  .cds--accordion__heading {
    margin: 0;
    padding: 0;
    min-height: 0;
    width: auto;

    &:focus {
      &::before {
        border: none;
      }
    }

    &:hover {
      &::before {
        background-color: transparent;
      }
    }
  }

  .cds--list__item {
    color: inherit;
  }
`;

export const Pagination = styled(M.Pagination)`
  text-align: right;
  margin-bottom: 0.5rem;
`;

export const UnorderedList = styled(M.UnorderedList)`
  #mastodon & {
    padding-left: 1.6rem;
  }

  .cds--list__item {
    &::before {
      content: '•';
    }
  }
`;

export const ResultsText = styled.span`
  color: ${colors.uiTextTertiaryLight};
  font-size: 1rem !important;
  line-height: 40px;
  margin-left: 1.5rem;
  white-space: nowrap;
`;

export const TableToolbar = styled.div`
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  width: 100%;
  display: flex;

  .mastodon-search-container {
    width: 100%;
  }
`;
