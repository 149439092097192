import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import EditableSection from '../EditableSection';
import { useOrderBackgroundCheckContext } from '../../Context';

const DownloadCSVLink = styled(M.Link)`
  cursor: pointer;
  display: block;
  line-height: 22px;
`;

export type InvitationCSVProps = {
  handleGetStartedEditClick: () => void;
};

const InvitationCSV: React.FC<InvitationCSVProps> = ({
  handleGetStartedEditClick,
}) => {
  const { state } = useOrderBackgroundCheckContext();
  const { csv } = state;

  return (
    <EditableSection
      id='candidates'
      sectionTitle='Candidates'
      onEditClick={handleGetStartedEditClick}
      description={{
        title: 'Reports will be run on each person included in:',
        content: (
          <DownloadCSVLink
            href={csv.fileS3URL}
            data-testid='uploaded-csv-link'
            size='lg'
          >
            {csv.fileDisplayName}
          </DownloadCSVLink>
        ),
        isCandidatesSection: true,
        expand: true,
      }}
      inlineEdit
    />
  );
};

export default InvitationCSV;
