import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RecordRow from './example-record';

type Props = {
  actionText?: ReactNode;
  descriptionText?: ReactNode;
  example?: { disabled?: boolean };
};

const Title = styled.div`
  padding-bottom: 0.5em;
`;

const Container = styled.div`
  margin-top: 0.5rem;
`;

const Example: React.FC<Props> = ({ actionText, descriptionText, example }) => {
  const { t } = useTranslation('assess');
  const title = t(
    'assess:settings.reports.eligible-records.report-view.example',
  );

  if (!example) {
    return null;
  }

  const { disabled } = example;

  return (
    <Container>
      <Title>{title}:</Title>
      <RecordRow
        actionText={actionText}
        disabled={disabled}
        descriptionText={descriptionText}
      />
    </Container>
  );
};

export default Example;
