import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import './styles.scss';

type Props = {
  disabled?: boolean;
  isSelected?: boolean;
  onClick: (value: boolean) => void;
  text: string;
  id?: string;
};

const Button: React.FC<Props> = ({
  isSelected,
  text,
  onClick,
  id,
  disabled,
}) => {
  const { t } = useTranslation('assess:v2');
  const disabledButtonText = t(
    'common.lookback_rules.configurator.disabled_button',
  );

  if (disabled) {
    const className = isSelected ? 'selected disabled' : 'disabled';
    return (
      <M.TooltipButton
        label={disabledButtonText}
        kind='secondary'
        size='sm'
        data-testid={id}
        style={{ marginRight: '1rem' }}
        isSelected={isSelected}
        className={className}
      >
        {capitalize(text)}
      </M.TooltipButton>
    );
  }

  const className = isSelected ? 'selected' : '';
  return (
    <M.Button
      kind='secondary'
      size='sm'
      onClick={onClick}
      data-testid={id}
      disabled={disabled}
      className={className}
    >
      {capitalize(text)}
    </M.Button>
  );
};

export default Button;
