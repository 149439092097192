import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY } from 'Constants';
import { NotificationCheckboxType } from './Types';
import {
  StyledInlineFlexDiv,
  StyledTooltipIcon,
} from './PreferenceStyledComponents';

const tooltipAllowed: { [key: string]: string } = {
  notify_report_assessment_changes:
    'You’ll receive one daily email with a summary of all report assessment updates from that day.',
  candidates:
    'You’ll receive an email any time there is a candidate update, such as when an invitation is sent or it expires.',
};

const preventDefault = (e: any) => {
  e.preventDefault();
};

const NotificationCheckbox: React.FC<NotificationCheckboxType> = ({
  onSave,
  name,
  label,
  value = false,
  group = {},
  indeterminate = false,
  disabled = false,
}) => {
  const [checked, toggleCheck] = useState(value);

  // TODO Remove after delivery the user preference improvements
  // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
  const newUserPreferencesEnabled =
    useFlag(USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY)?.variantKey === 'on' ||
    false;

  useEffect(() => {
    if (value !== checked) {
      toggleCheck(value);
    }
  }, [checked, value]);

  /**
   * @name onChangeHandler
   * @function
   * @memberOf NotificationCheckbox
   * @description Handle a checkbox check/uncheck
   * @param {object} e - The click event of the checkbox
   */
  const onChangeHandler = useCallback(
    (event, { checked }) => {
      onSave(
        {
          [name]: checked,
        },
        group,
      );
      toggleCheck(checked);
    },
    [group, name, onSave],
  );

  return (
    <StyledInlineFlexDiv>
      <M.Checkbox
        onChange={onChangeHandler}
        name={name}
        labelText={label}
        indeterminate={indeterminate}
        disabled={disabled}
        hideLabel={false}
        id={`checkbox-label-${name}`}
        checked={checked}
        data-testid='checkbox'
      />
      {newUserPreferencesEnabled && tooltipAllowed[name] && (
        <M.Tooltip
          data-testid={`tooltip-${name}`}
          label={tooltipAllowed[name]}
          align='bottom'
          onClick={preventDefault}
        >
          <StyledTooltipIcon icon='InformationFilled' size='16' />
        </M.Tooltip>
      )}
    </StyledInlineFlexDiv>
  );
};

export default NotificationCheckbox;
