/* eslint-disable default-param-last */
/* eslint-disable no-nested-ternary */
import {
  UPDATE_SEGMENTS,
  POST_SEGMENTS_REQUEST,
  POST_SEGMENTS_SUCCESS,
  POST_SEGMENTS_FAILURE,
  SHOW_ASSIGN_SEGMENTS_CONFIRMATION,
  CANCEL_ASSIGN_SEGMENTS_CONFIRMATION,
  SHOW_ASSIGN_SEGMENTS_SUCCESS,
  FETCH_PARENT_OBJECT_REQUEST,
  FETCH_PARENT_OBJECT_SUCCESS,
  FETCH_PARENT_OBJECT_FAILURE,
  HIDE_ASSIGN_SEGMENTS_SUCCESS,
  GET_ASSIGNED_NODES_REQUEST,
  GET_ASSIGNED_NODES_SUCCESS,
  GET_ASSIGNED_NODES_FAILURE,
  SEARCH_HIERARCHY_REQUEST,
  SEARCH_HIERARCHY_SUCCESS,
  SEARCH_HIERARCHY_FAILURE,
} from 'actions/ActionTypes';

export const initialState = {
  segments: [],
  filteredSegments: [],
  error: null,
  postError: null,
  parentObject: {},
  initialSelectedSegments: null,
  search: {
    query: null,
    isSearching: false,
    pageCount: 0,
    pageIndex: 0,
  },
};

const segmentsReducer = (
  state = initialState,
  {
    type,
    filteredSegments,
    error,
    postError,
    parentObject,
    nodes,
    nodeIds,
    pageCount,
    pageIndex,
    query,
    isSearching,
  },
) => {
  switch (type) {
    case POST_SEGMENTS_REQUEST:
      return {
        ...state,
        isPosting: true,
        postError: null,
      };
    case POST_SEGMENTS_SUCCESS:
      return {
        ...state,
        isPosting: false,
        showAssignSegmentsConfirmationView: false,
        showAssignSegmentsSuccessView: true,
      };
    case POST_SEGMENTS_FAILURE:
      return {
        ...state,
        isPosting: false,
        postError,
      };
    case UPDATE_SEGMENTS:
      return {
        ...state,
        filteredSegments,
        search: {
          ...state.search,
          pageCount: 0,
        },
      };
    case SHOW_ASSIGN_SEGMENTS_CONFIRMATION:
      return {
        ...state,
        showAssignSegmentsConfirmationView: true,
      };
    case CANCEL_ASSIGN_SEGMENTS_CONFIRMATION:
      return {
        ...state,
        showAssignSegmentsConfirmationView: false,
        postError: false,
      };
    case SHOW_ASSIGN_SEGMENTS_SUCCESS:
      return {
        ...state,
        showAssignSegmentsConfirmationView: false,
        showAssignSegmentsSuccessView: true,
      };
    case HIDE_ASSIGN_SEGMENTS_SUCCESS:
      return {
        ...state,
        showAssignSegmentsConfirmationView: false,
        showAssignSegmentsSuccessView: false,
      };
    case FETCH_PARENT_OBJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PARENT_OBJECT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        parentObject,
      };
    case FETCH_PARENT_OBJECT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error,
      };
    case GET_ASSIGNED_NODES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ASSIGNED_NODES_SUCCESS:
      return {
        ...state,
        segments: nodes,
        initialSelectedSegments: nodeIds,
        filteredSegments: nodes,
        isFetching: false,
      };
    case GET_ASSIGNED_NODES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error,
      };
    case SEARCH_HIERARCHY_REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          isSearching,
        },
      };
    case SEARCH_HIERARCHY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        filteredSegments: nodes,
        segments: state.segments.concat(nodes),
        search: {
          pageCount,
          pageIndex,
          query,
          isSearching: false,
        },
      };
    case SEARCH_HIERARCHY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error,
      };
    default:
      return state;
  }
};

export default segmentsReducer;
