import { Reducer } from 'redux';
import { combine, handleAction, reduce } from 'state/utilities/reducers';
import * as Actions from './actions';
import * as Count from './count';
import * as Custom from './custom';
import * as Lookback from './lookback';
import * as Premade from './premade';
import * as State from './state';

export const base = combine({
  count: Count.reducers.default,
  custom: Custom.reducers.default,
  lookback: Lookback.reducers.default,
  premade: Premade.reducers.default,
});

export const insert = handleAction(
  Actions.Insert.TYPE,
  (state: State.Type, action: Actions.Insert.Action): State.Type => {
    const { payload: data } = action;
    // TODO: implement
    const actions = {
      count: Count.Actions.Load.create(data.count),
      custom: Custom.Actions.Load.create(data.custom),
      lookback: Lookback.Actions.Load.create(data.lookback),
      premade: Premade.Actions.Load.create(data.premade),
    };
    const next = {
      count: Count.reducers.default(state.count, actions.count),
      custom: Custom.reducers.default(state.custom, actions.custom),
      lookback: Lookback.reducers.default(state.lookback, actions.lookback),
      premade: Premade.reducers.default(state.premade, actions.premade),
    };
    return next;
  },
  State.initialize(),
);

export const updates = handleAction(
  Actions.Update.TYPE,
  (state: State.Type, action: Actions.Update.Action): State.Type => {
    return state;
  },
  State.initialize(),
);

export const reducer = reduce(
  null,
  base,
  insert as Reducer<State.Type>,
  updates as Reducer<State.Type>,
);

export default reducer;
