import { transformParams } from 'utils';
import querystring from 'querystring';
import { PARAMS } from 'components/Search';
import {
  FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST,
  FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS,
  FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE,
  FETCH_CANDIDATES_REQUEST,
  FETCH_CANDIDATES_SUCCESS,
  FETCH_CANDIDATES_FAILURE,
  UPDATE_SEARCH_PARAMS,
  UPDATE_PAGE_PARAM,
  CLEAR_SEARCH_PARAMS,
  DELETE_SAVED_SEARCH,
  INITIALIZE_SEARCH,
} from '../actions/ActionTypes';
import { httpHelper } from './reducerHelpers';

const getSearchParams = () => {
  return transformParams(
    querystring.parse(window.location.search.replace('?', '')),
  );
};

export const initialState = {
  candidates: null,
  fetchCandidateReportDocumentsRequest: { ...httpHelper.initialState },
  fetchCandidatesRequest: { ...httpHelper.initialState },
  reports: {},
  searchParams: getSearchParams(),
  savedSearch: {},
};

const searchReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { candidates, error, report, type, newParams, page, savedSearch },
) => {
  const previousSearchParams = { ...state.searchParams };
  switch (type) {
    case FETCH_CANDIDATE_REPORT_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchCandidateReportDocumentsRequest: { ...httpHelper.onRequest },
      };
    case FETCH_CANDIDATE_REPORT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchCandidateReportDocumentsRequest: { ...httpHelper.onSuccess },
        reports: {
          ...state.reports,
          [report.id]: report,
        },
      };
    case FETCH_CANDIDATE_REPORT_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchCandidateReportDocumentsRequest: { ...httpHelper.onFailure },
        error,
      };
    case FETCH_CANDIDATES_REQUEST:
      return {
        ...state,
        fetchCandidatesRequest: { ...httpHelper.onRequest },
      };
    case FETCH_CANDIDATES_SUCCESS:
      return {
        ...state,
        fetchCandidatesRequest: { ...httpHelper.onSuccess },
        candidates,
      };
    case FETCH_CANDIDATES_FAILURE:
      return {
        ...state,
        fetchCandidatesRequest: { ...httpHelper.onFailure },
        error,
      };
    case DELETE_SAVED_SEARCH:
      // to delete a saved search, we need to filter out the searchParams it had set
      // as well as its saved_search_id
      if (state.savedSearch) {
        const previousSearchParams = { ...state.searchParams };
        if (state.savedSearch?.params) {
          Object.keys(state.savedSearch.params).forEach(paramName => {
            delete previousSearchParams[paramName];
          });
        }
        delete previousSearchParams.saved_search_id;

        return {
          ...state,
          searchParams: {
            ...previousSearchParams,
            page: 1,
          },
          savedSearch: {},
        };
      }
      return { ...state };
    case UPDATE_SEARCH_PARAMS:
      if (!savedSearch) {
        return {
          ...state,
          searchParams: {
            ...state.searchParams,

            ...newParams,
            page: 1,
          },
        };
      }

      if (state.savedSearch?.params) {
        Object.keys(state.savedSearch.params).forEach(paramName => {
          delete previousSearchParams[paramName];
        });
      }

      return {
        ...state,
        searchParams: {
          ...previousSearchParams,
          ...newParams,
          page: 1,
          saved_search_id: savedSearch.id,
          ...savedSearch.params,
        },
        savedSearch,
      };
    case INITIALIZE_SEARCH: // Called upon initial pageload; uses some default values and updates with querystring params
      return {
        ...state,
        searchParams: {
          ...PARAMS.default,
          ...getSearchParams(),
        },
      };
    case UPDATE_PAGE_PARAM:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          page,
        },
      };
    case CLEAR_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...PARAMS.default,
        },
      };
    default:
      return state;
  }
};

export default searchReducer;
