import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { API_KEY_TYPES } from 'Constants';

import { GenericObject } from '@dashboard-experience/utils';
import { useAddApiKey } from 'api/apiKeys';
import { Type as Account } from 'modules/core-types/account';
import EmptyTableBody from '../EmptyTableBody';
import FormHeading from '../FormHeading';
import ApiKeysToolbar from './ApiKeysToolbar';
import ApiKeyEntry from './ApiKeyEntry';
import { useApiKeys } from './apiKeys-context';

const tableHeaders = [
  { key: 'object', header: 'Type' },
  { key: 'token', header: 'API Key' },
  { key: 'created_at', header: 'Created' },
  { key: 'last_used_at', header: 'Last Used' },
  { key: 'expires_at', header: '' },
];

const noop = (e: React.FormEvent) => {
  e?.preventDefault();
};

type Props = {
  account: Account;
};

const ApiKeysTable: React.FC<Props> = ({ account }) => {
  const { call: addKey } = useAddApiKey();

  const apiKeys = useApiKeys();

  const visibleItems = () => {
    if (apiKeys)
      return Object.values(apiKeys).filter(apiKey => {
        const { expires_at } = apiKey as GenericObject;

        // If expired, hide it
        if (expires_at && moment(expires_at).isSameOrBefore(moment.now())) {
          return false;
        }
        // Removing all vestiges of test mode from UI Feb 2024 per CS
        if (apiKey.object.includes('test_')) {
          return false;
        }
        return true;
      });
    return [];
  };

  const addApiKey = useCallback(
    ({ currentTarget: { innerText: token } }) => {
      const type = API_KEY_TYPES[token];

      addKey({ accountId: account.id, type });
    },
    [account.id, addKey],
  );

  const getTableContent = useCallback(
    ({
      rows,
      headers,
      getHeaderProps,
      getRowProps,
      getSelectionProps,
      getToolbarProps,
      getBatchActionProps,
      onInputChange,
      selectedRows,
      getTableProps,
      getTableContainerProps,
    }) => {
      return (
        <M.TableContainer
          {...getTableContainerProps()}
          data-floating-menu-container
        >
          <ApiKeysToolbar
            {...{
              getToolbarProps,
              getBatchActionProps,
              selectedRows,
              addApiKey,
              onInputChange,
            }}
          />
          <form autoComplete='off' onSubmit={noop}>
            <M.Table {...getTableProps()}>
              <M.TableHead className='border-top'>
                <M.TableRow>
                  <M.TableSelectAll {...getSelectionProps()} />
                  {headers.map((header: any) => (
                    <M.TableHeader
                      key={header.header}
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </M.TableHeader>
                  ))}
                </M.TableRow>
              </M.TableHead>
              {rows.length === 0 ? (
                <EmptyTableBody colSpan={tableHeaders.length + 1} />
              ) : (
                <M.TableBody>
                  {rows.map((row: any) => (
                    <ApiKeyEntry
                      key={row.id}
                      {...{
                        account,
                        row,
                        getRowProps,
                        getSelectionProps,
                      }}
                    />
                  ))}
                </M.TableBody>
              )}
            </M.Table>
          </form>
        </M.TableContainer>
      );
    },
    [account, addApiKey],
  );

  const tableRows = visibleItems();

  return (
    <>
      <M.Container.Row>
        <M.Container.Col>
          <FormHeading>API Keys</FormHeading>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          <M.DataTable
            rows={tableRows}
            headers={tableHeaders}
            render={getTableContent}
          />
        </M.Container.Col>
      </M.Container.Row>
    </>
  );
};

export default ApiKeysTable;
