import { capitalize } from 'lodash';

export const PAUSED = 'Paused';
export const PENDING = 'Pending';
export const NON_SCHEDULED = 'non_scheduled';

export const STATUSES = [
  PENDING,
  PAUSED,
  'Scheduled',
  'Complete',
  'Dispute',
  'Canceled',
  'Undeliverable',
  'All',
];

export const statusItemToParam = (status: string) =>
  status === PENDING ? NON_SCHEDULED : status?.toLowerCase();

export const statusParamToItem = (status: string) =>
  status === NON_SCHEDULED ? PENDING : capitalize(status);
