import { M } from '@dashboard-experience/mastodon';
import React from 'react';

type BannerProps = {
  isPaused: boolean;
  hasResults: boolean;
};

const AdverseActionsBanner = ({ isPaused, hasResults }: BannerProps) => {
  return isPaused && hasResults ? (
    <M.InlineNotification
      kind='warning'
      title='Review the following Adverse Action reports'
    />
  ) : null;
};

export default AdverseActionsBanner;
