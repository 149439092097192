import React from 'react';
import { GenericObject } from 'types';
import { CellProps } from './types';

const Geos: React.FC<CellProps> = ({ report, candidate }) => (
  <span>
    {(report || candidate)?.geos
      ?.map((g: GenericObject) => g.name)
      .join(',\n ')}
  </span>
);

export default Geos;
