import React from 'react';
import { Report } from 'types';
import { Provider } from '../context';
import Modal from './modal';
import { ReorderableExams } from '../types';

type Props = {
  onClose: () => void;
  open: boolean;
  reorderableExams: ReorderableExams;
  report: Report;
};

const New: React.FC<Props> = ({ onClose, open, reorderableExams, report }) => {
  return (
    <Provider
      onClose={onClose}
      open={open}
      reorderableExams={reorderableExams}
      report={report}
    >
      <Modal />
    </Provider>
  );
};

export default New;
