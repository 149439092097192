import React, { useCallback, useEffect } from 'react';
import PageLoading from 'components/PageLoading';
import {
  useQuery as useRuleset,
  useDestroy,
  usePath,
} from 'modules/assess/ui/ruleset/api';
import { useDraftDestroy } from 'modules/assess/ui/ruleset/version/api';
import { useFetch as useSettings } from 'modules/assess/ui/settings/api/hooks';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'components';
import { Namespace } from 'modules/assess/api';
import { Actions } from './Actions';
import { useState } from './store';
import { useNamespace } from '../../router/context';

export type Props = {};

export const ActionsContainer: React.FC<Props> = props => {
  const base = usePath();
  const state = useState();
  const history = useHistory();
  const ruleset = useRuleset();
  const settings = useSettings();
  const namespace = useNamespace();
  const { kind, dirty } = state;
  const { active, draft } = ruleset?.data || {};
  const latest =
    (typeof active === 'string' ? active : active?.id) === state.id;

  const assign = useCallback(() => {}, []);
  const destroy = useDestroy(ruleset?.data?.id);
  const destroyDraft = useDraftDestroy(ruleset?.data?.id);
  const edit = useCallback(() => history.push('draft'), [history]);
  const growthInsights = useCallback(() => {}, []);
  const insights = useCallback(() => {}, []);
  const publish = useCallback(() => {}, []);
  const save = useCallback(() => {}, []);
  const versions = useCallback(() => {}, []);

  useEffect(() => {
    if (destroy.result.status === 'success') {
      history.push(base);
    }
  }, [base, destroy.result.status, history]);

  useEffect(() => {
    if (destroyDraft.result.status === 'success') {
      history.push(base);
    }
  }, [base, destroyDraft.result.status, history]);

  return (
    <>
      <Prompt when={dirty} translation='dialogs.unsaved_changes.message' />
      <PageLoading enabled={destroy.result.status === 'loading'} />
      <Actions
        assign={assign}
        dirty={dirty}
        kind={kind}
        isCriminal={namespace === Namespace.criminal}
        latest={latest}
        discard={destroy.call}
        discardDraft={destroyDraft.call}
        draft={!!draft}
        edit={edit}
        growthInsights={growthInsights}
        insights={insights}
        publish={publish}
        ruleset={ruleset?.data}
        save={save}
        settings={settings.data}
        version={state.id}
        versions={versions}
      />
    </>
  );
};

export default ActionsContainer;
