import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

const Banner = styled(M.ActionableNotification)`
  background-color: ${colors.uiGrey0} !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-left-color: ${colors.uiGrey0} !important;
  margin-top: 30px;
  margin-bottom: 40px;

  .cds--actionable-notification__title {
    font-weight: normal;
  }
`;

export type Props = {
  categoryName?: string;
  components: any;
};

const CorrectVersionBanner: React.FC<Props> = ({
  categoryName,
  components,
}) => {
  const { t } = useTranslation('assess');
  const bannerContent = (
    <Trans
      t={t}
      i18nKey='rules.categories.category-set-banner.correct-version.content'
      components={components}
      values={{
        category: categoryName,
        interpolation: { escapeValue: false },
      }}
    />
  );
  return (
    <Banner
      data-testid='correct-category-version-banner'
      inline='classic'
      hideActionButton
      hideCloseButton
      kind='success'
      title={bannerContent}
    />
  );
};

export default CorrectVersionBanner;
