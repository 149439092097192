import { Geo, WorkLocation } from 'types';
import { Locations } from '../data';

export const formatCandidateLocation = (locations: Locations[] = []) => {
  return locations.map((l) => l.join(', ')).join('; ');
};

export const formatReportGeos = (geos: Geo[]) => {
  return geos.reduce((_, geo) => `${geo.name} - ${geo.state}`, '-');
};

export const formatReportTags = (tags: string[] | null | undefined) => {
  return tags && tags?.length > 0 ? tags?.join(', ') : '-';
};

export const formatReportWorkLocations = (workLocations: WorkLocation[]) => {
  return workLocations.map((location) => {
    const { country, state, city } = location;
    return [country, state, city].filter((x) => x).join(' - ');
  });
};
