import React, { useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import { ActionType } from '../../../types';
import { useModalState, useDispatch } from '../../../context';

const Section = styled.section`
  #mastodon && {
    .cds--date-picker-container {
      .cds--label {
        color: ${colors.coolBlack70};
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;

const base_path = `${exam_reorder_modal_body}.appointment.new_deadline_selection`;

const NewDeadlineSelection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { appointmentDeadline } = useModalState('formData');

  const onDateChange = useCallback(
    value =>
      dispatch({
        type: ActionType.SET_APPOINTMENT_DEADLINE,
        payload: value[0],
      }),
    [dispatch],
  );

  return (
    <Section data-testid='appointment-new-deadline-selection'>
      <M.DatePicker
        id='appointment-date-picker'
        minDate={moment().format('MM-DD-YYYY')}
        datePickerType='single'
        value={appointmentDeadline}
        onChange={onDateChange}
      >
        <M.DatePickerInput
          labelText={t(`${base_path}.label`)}
          id='appointment-date-picker-input'
          placeholder='MM/DD/YYYY'
        />
      </M.DatePicker>
    </Section>
  );
};

export default NewDeadlineSelection;
