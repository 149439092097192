import React, { useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerFooter } from 'components/Banner';
import { M } from '@dashboard-experience/mastodon';
import {
  useAdverseActions,
  useRestartAdverseAction,
} from 'modules/adjudication/api';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import { useReport } from 'containers/Report';
import { hasPermission } from '@dashboard-experience/utils';
import { useAdjudicationChangeAllowed } from 'modules/adjudication/utilities';
import ResolveUndeliverableWithEmail from './ResolveUndeliverableWithEmail';
import ResolveUndeliverableManually from './ResolveUndeliverableManually';
import ResolveUndeliverableWithPostal from './ResolveUndeliverableWithPostal';

// Determine whether to show the banner based on a few conditions
const shouldShowBanner = (
  aa_delivery_state: string | undefined,
  canceled_at: string | undefined,
  currentUser: any,
): boolean => {
  return (
    ['error', 'unknown'].includes(aa_delivery_state || '') && // The AA must have a certain state
    !canceled_at && // And not have been canceled
    !currentUser?.account?.offline_adverse_action_enabled // And the User needs to not have this flag
  );
};

const AdverseActionUndeliverableBanner: React.FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'banners.undeliverableAdverseAction',
  });

  const report = useReport();
  const { adverseAction } = useAdverseActions(report.id);

  const currentUser = useUser();
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();

  const { isIframe, isStandaloneIframe } = useContext(UIContext);

  enum ModalType {
    Null,
    Email = 'email',
    Manual = 'manual',
    Postal = 'postal',
  }

  // Control over the modal which contains various actions for Paused AA's
  const [showModal, setShowModal] = useState<ModalType>(ModalType.Null);

  const closeModal = useCallback(() => {
    setShowModal(ModalType.Null);
  }, [ModalType.Null]);

  // Open a modal to perform manual steps regarding the Candidate's AA
  const showHandleManually = useCallback(() => {
    if (!isIframe || isStandaloneIframe) {
      setShowModal(ModalType.Manual);
    }
  }, [isIframe, isStandaloneIframe, ModalType.Manual]);

  // Open a modal to edit the Candidate's email address
  const showUpdateEmail = useCallback(() => {
    if (!isIframe || isStandaloneIframe) {
      setShowModal(ModalType.Email);
    }
  }, [isIframe, isStandaloneIframe, ModalType.Email]);

  // Open a modal to edit the Candidate's postal address
  const showUpdatePostal = useCallback(() => {
    if (!isIframe || isStandaloneIframe) {
      setShowModal(ModalType.Postal);
    }
  }, [isIframe, isStandaloneIframe, ModalType.Postal]);

  // Function for re-sending the email to the Candidate
  const { restartCall } = useRestartAdverseAction(adverseAction?.id);
  const resendEmail = useCallback(() => {
    restartCall();
  }, [restartCall]);

  if (adverseAction) {
    const {
      aa_delivery_state: status,
      aa_delivery_state_reason: reason,
      canceled_at,
      events,
    } = adverseAction;

    const deliveryType = events?.filter(
      event => event.event_type === 'undeliverable',
    )[0]?.delivery_type;

    const deliveryTypeIsEmail = deliveryType === 'email';

    if (shouldShowBanner(status, canceled_at, currentUser)) {
      // The status will only ever be Error or Unknown, so use this as shorthand for handling the differences
      const statusIsErr = status === 'error';

      // If there was an error with a specific reason, display the reason; otherwise, use a generic message based on the Status
      const subtitle = (statusIsErr && reason) || t(`messages.${status}`);

      return (
        <>
          <M.ActionableNotification
            hideActionButton
            inline='classic'
            kind='warning'
            title={t(`title.${status}`)}
            subtitle={subtitle}
            hideCloseButton
            data-testid='candidate-undeliverable-aa-banner'
            style={{ maxWidth: '100%' }}
          >
            <BannerFooter>
              {statusIsErr ? (
                <M.Button kind='secondary' onClick={showHandleManually}>
                  {t('buttons.manual')}
                </M.Button>
              ) : (
                <M.Restrict authorized={adjudicationChangeAllowed}>
                  <M.Button kind='secondary' onClick={resendEmail}>
                    {t('buttons.resend')}
                  </M.Button>
                </M.Restrict>
              )}

              {/* prettier-ignore */}
              {deliveryTypeIsEmail ? (
                <M.Restrict
                  authorized={hasPermission(
                    currentUser,
                    'update_candidate_emails',
                  )}
                >
                  <M.Button kind='secondary' onClick={showUpdateEmail}>
                    {t('buttons.update_email')}
                  </M.Button>
                </M.Restrict>
              ) : (
                <M.Button kind='secondary' onClick={showUpdatePostal}>
                  {t('buttons.update_address')}
                </M.Button>
              )}
            </BannerFooter>
          </M.ActionableNotification>

          {showModal === ModalType.Email && (
            <ResolveUndeliverableWithEmail
              showModal
              onClose={closeModal}
              adverseAction={adverseAction}
            />
          )}
          {showModal === ModalType.Manual && (
            <ResolveUndeliverableManually
              showModal
              onClose={closeModal}
              adverseAction={adverseAction}
            />
          )}
          {showModal === ModalType.Postal && (
            <ResolveUndeliverableWithPostal
              showModal
              onClose={closeModal}
              adverseAction={adverseAction}
            />
          )}
        </>
      );
    }
  }

  return null;
};

export default AdverseActionUndeliverableBanner;
