import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useUserAccount } from 'modules/assess/ui/hooks';
import { useUpdateAccountSettings } from 'components';
import styled from 'styled-components';

const Checkbox = styled(M.Checkbox)`
  margin-bottom: 1rem;
`;

const GeosSettings: React.FC = () => {
  const { t } = useTranslation();

  const { call: updateAccountCall, result: updateAccountResult } =
    useUpdateAccountSettings();
  const { isLoading } = updateAccountResult;

  const account = useUserAccount();

  const [geosRequired, setGeosRequired] = useState<boolean>(
    account?.geos_required,
  );

  const handleCheck = useCallback(
    (event, { checked }) => setGeosRequired(checked),
    [],
  );

  // After clicking save, submit the new setting to the server
  const saveClicked = useCallback(() => {
    const changedSettings = {
      geos_required: geosRequired,
    };

    updateAccountCall({
      changedSettings,
      account,
    });
  }, [account, geosRequired, updateAccountCall]);

  return (
    <>
      <h3>{t('screening_settings.geos.settings.settings')}</h3>

      <Checkbox
        id='geo-required-checkbox'
        checked={geosRequired}
        onChange={handleCheck}
        labelText={t('screening_settings.geos.settings.require')}
        disabled={isLoading}
      />

      <M.Button kind='primary' onClick={saveClicked} disabled={isLoading}>
        {t('verbs.save')}
      </M.Button>
    </>
  );
};

export default GeosSettings;
