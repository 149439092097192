import { isEqual } from 'lodash';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  Action,
  ActionType,
  CreatePackageReducerState,
  DOMESTIC_GEO,
} from './utils';

export const initialState = {
  addedScreeningTypes: [],
  additionalProperties: {},
  newPackageName: '',
  newPackageNameInvalid: true,
  basePackage: {
    name: '',
    id: '',
    screenings: [],
  },
  selectedGeo: DOMESTIC_GEO,
  error: '',
  infoLinksClicked: [], // Used for amplitude tracking on Select Package step
  aliasesEnabled: AliasesEnabledType.OFF,
  aliasPrice: 0,
};

export const SKIPPED_NAME = 'SKIP_NAME_DO_NOT_SUBMIT_00q5wq58';

export const getEmptyPackageShell = () => {
  return {
    name: SKIPPED_NAME,
    screenings: [],
  };
};

const isPackageNameInvalid = (name: string) =>
  !name.trim().length || !/^[a-zA-Z0-9\s]*$/.test(name);

const didBasePackageChange = (
  basePackage: any,
  newBasePackage: any,
): boolean => {
  return !isEqual(basePackage, newBasePackage);
};

export const CreatePackageReducer = (
  reducerState: CreatePackageReducerState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.SCREENING_ADDED:
      return {
        ...reducerState,
        addedScreeningTypes: reducerState.addedScreeningTypes.includes(
          action.payload.type,
        )
          ? reducerState.addedScreeningTypes
          : reducerState.addedScreeningTypes.concat(action.payload.type),
        additionalProperties: action.payload.additionalProperties
          ? {
              ...reducerState.additionalProperties,
              [action.payload.type]: {
                ...(reducerState.additionalProperties[action.payload.type] ||
                  {}),
                ...action.payload.additionalProperties,
              },
            }
          : reducerState.additionalProperties,
      };
    case ActionType.SCREENING_REMOVED: {
      let newState = {
        ...reducerState,
        addedScreeningTypes: reducerState.addedScreeningTypes.filter(
          (type: any) => type !== action.payload.type,
        ),
      };
      if (action.payload.type === 'employment_verification') {
        delete newState.additionalProperties[action.payload.type]
          ?.international_upgrade;
        delete newState.additionalProperties[action.payload.type]
          ?.dot_compliance;
      }
      if (action.payload.type !== 'employment_verification') {
        delete newState.additionalProperties[action.payload.type];
      }
      // removes adverse media search any time intl crim search v2 is removed
      if (action.payload.type.includes('international_criminal_search_v2')) {
        newState = {
          ...newState,
          addedScreeningTypes: newState.addedScreeningTypes.filter(
            (type: any) => type !== 'international_adverse_media_search',
          ),
        };
      }
      return newState;
    }
    case ActionType.SET_PACKAGE_NAME:
      return {
        ...reducerState,
        newPackageName: action.payload.name,
        newPackageNameInvalid: isPackageNameInvalid(action.payload.name),
        error: '',
      };
    case ActionType.SET_INFO_LINKS_CLICKED:
      return {
        ...reducerState,
        infoLinksClicked:
          reducerState.infoLinksClicked &&
          reducerState.infoLinksClicked.includes(action.payload.infoLink)
            ? reducerState.infoLinksClicked
            : [
                ...(reducerState.infoLinksClicked || []),
                action.payload.infoLink,
              ],
      };
    case ActionType.SET_BASE_PACKAGE:
      return {
        ...reducerState,
        basePackage:
          action.payload.package &&
          didBasePackageChange(reducerState.basePackage, action.payload.package)
            ? action.payload.package
            : reducerState.basePackage,
      };
    case ActionType.SKIP_BASE_PACKAGE_SELECTION:
      return {
        ...reducerState,
        basePackage: getEmptyPackageShell(),
      };
    case ActionType.SET_SELECTED_GEO:
      return {
        ...reducerState,
        selectedGeo: action.payload.selectedGeo,
      };
    case ActionType.SET_ERROR:
      return {
        ...reducerState,
        error: action.payload.error,
      };
    case ActionType.SET_ALIASES_ENABLED:
      return {
        ...reducerState,
        aliasesEnabled: action.payload.aliasesEnabled,
        basePackage: {
          ...reducerState.basePackage,
          request_aliases_hard_cap_threshold: 4,
        },
      };
    case ActionType.SET_ALIASES_PRICE:
      return {
        ...reducerState,
        aliasPrice: action.payload.aliasPrice,
      };
    case ActionType.CLEAR_BASE_PACKAGE_AND_ADDONS:
      return {
        ...reducerState,
        addedScreeningTypes: [],
        additionalProperties: {},
        newPackageName: '',
        basePackage: {
          name: '',
          id: '',
          screenings: [],
        },
        error: '',
      };
    default:
      return reducerState;
  }
};
