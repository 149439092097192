import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const SettingsBanner = () => {
  return (
    <M.Container
      data-testid='case-management-settings-banner'
      type='shadow'
      rows={[
        <p key='p'>
          There are no settings currently available in Beta. Please share any
          feedback on settings you would like to{' '}
          <a href='mailto:cm-beta@checkr.com'>cm-beta@checkr.com</a>
        </p>,
      ]}
    />
  );
};

export default SettingsBanner;
