import * as React from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import { HorizontalBar, defaults } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

type Props = {
  draft?: number;
  draftColor?: any;
  live?: number;
  liveColor?: any;
  max: number;
};

export const ResultsGraph: React.FC<Props> = ({
  draft = 0,
  live = 0,
  draftColor,
  liveColor,
  max,
}) => {
  const { t } = useTranslation('assess');

  const labels = [
    t(`assess:ruleset.version.insights.results.graphs.active`),
    t(`assess:ruleset.version.insights.results.graphs.draft`),
  ];

  const values = [live, draft];
  const options = chartOptions(live, draft, liveColor, draftColor, max);

  const data: ChartData = {
    labels,
    datasets: [
      {
        data: values,
        minBarLength: 2,
      },
    ],
  };
  return (
    <HorizontalBar data={data} options={options} width={500} height={80} />
  );
};

export default ResultsGraph;

// configuration defaults

defaults.global.defaultFontSize = 14;
defaults.global.defaultFontStyle = 'Bold';

const chartOptions = (
  live: number,
  draft: number,
  liveColor: any,
  draftColor: any,
  max: number,
): ChartOptions => {
  return {
    responsive: false,
    layout: {
      padding: {
        left: 50,
      },
    },
    elements: {
      rectangle: {
        backgroundColor: [liveColor, draftColor],
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            reverse: true,
            mirror: true,
            padding: 50,
          },
          gridLines: {
            display: false,
          },
        },
        {
          type: 'category',
          position: 'right',
          offset: true,
          ticks: {
            callback: value => (value === 'Live' ? draft : live),
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
};
