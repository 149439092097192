import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Actions from './Actions';
import Title from './Title';

export type Type = React.FC<Props>;

export type Props = {
  divider?: boolean;
};

const Container = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ divider }) =>
    divider ? `border-bottom: 1px solid ${colors.uiGrey400};` : ''}
  padding: 0.5rem;
`;

const Header: Type = ({ divider }) => {
  return (
    <Container divider={divider ?? true}>
      <Title />
      <Actions.Bar />
    </Container>
  );
};

export default Header;
