import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const EventName = styled.span`
  font-weight: bold;
  display: block;
  color: ${colors.uiGrey800};
`;

export const TimelineCell = styled(M.TableCell)`
  background-color: ${colors.uiGrey50} !important;
`;

export const TimelineEventDetailsCell = styled(M.TableCell)`
  background-color: ${colors.uiGrey50} !important;
  width: 40%;
`;

export const TimelineFiltersContainer = styled.div`
  padding-bottom: 15px;
  font-size: 0.8em;
`;

export const TimelineFiltersList = styled.ul`
  display: inline-block;

  li {
    display: inline-block;
    padding: 0 10px;

    .cds--checkbox-label-text {
      font-size: 0.8em;
    }
  }
`;

export const Timestamp = styled.span`
  color: ${colors.uiGrey500};
`;
