import { M } from '@dashboard-experience/mastodon';
import { ComposedModalProps } from 'carbon-components-react';
import React, { ReactChild, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

export interface Props extends ComposedModalProps {
  content?: ReactChild;
  footer?: ReactChild;
  header?: ReactChild;
  titles?: string[];
}

export const Dialog: React.FC<Props> = ({
  children,
  content,
  footer,
  header,
  onClose,
  open,
  title,
  titles,
  ...extras
}) => {
  if (!open || typeof document === 'undefined') {
    return null;
  }

  const container = document.getElementById('mastodon') || document.body;

  return createPortal(
    <M.ComposedModal
      data-testid='dialog'
      onClose={onClose}
      open={open}
      title={title}
      {...extras}
    >
      <Header header={header} onClose={onClose} title={title} titles={titles} />
      <Content content={content} />
      {children}
      <Footer footer={footer} />
    </M.ComposedModal>,
    container,
  );
};

export const Header: React.FC<
  Pick<Props, 'header' | 'onClose' | 'title' | 'titles'>
> = ({ header, onClose, title, titles }) => {
  const { t } = useTranslation('translation');
  const defaults = titles
    ? t(`translation:dialogs.headers.${titles.length}`, {
        items: titles,
      })
    : undefined;

  if (!header && !titles && !title) {
    return null;
  }

  const content = header || defaults || title;
  const element = typeof content === 'string' ? <h2>{content}</h2> : content;

  return (
    <M.ModalHeader data-testid='dialog-header' closeModal={onClose}>
      {element}
    </M.ModalHeader>
  );
};

export const Content: React.FC<Pick<Props, 'content'>> = ({ content }) => {
  if (!content) {
    return null;
  }

  if (
    typeof content === 'object' &&
    (content as ReactElement).type === M.ModalBody
  ) {
    return content;
  }

  return <M.ModalBody data-testid='dialog-content'>{content}</M.ModalBody>;
};

export const Footer: React.FC<Pick<Props, 'footer'>> = ({ footer }) => {
  if (!footer) {
    return null;
  }

  if (
    typeof footer === 'object' &&
    (footer as ReactElement).type === M.ModalFooter
  ) {
    return footer;
  }

  return <M.ModalFooter data-testid='dialog-footer'>{footer}</M.ModalFooter>;
};

export default Dialog;
