import React from 'react';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import some from 'lodash/some';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useAssessEnabled } from 'modules/adjudication/utilities';
import { useGetReport } from 'api/reports';
import List from './adverse-items-list';
import Filtered from './filtered';

const base_path = `${namespace}:report.actions.pre_adverse.adverse_items.items`;

const StyledSection = styled.section`
  padding-bottom: 0 !important;
  border-bottom: none !important;
  margin-bottom: 1rem;
  .header {
    .title {
      display: block;
    }
  }
  .list-label {
    display: block;
    margin-top: 1.5rem;
  }
  .list-container {
    padding-top: 1.5rem;
  }
  .value-width {
    width: 75%;
  }
`;

type Props = {
  newAdverseAction: any;
  showAdversibleItemsGroupedBySeverity?: boolean;
};

const Items: React.FC<Props> = ({
  newAdverseAction,
  showAdversibleItemsGroupedBySeverity,
}) => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  const {
    adversibleItems,
    adversibleItemsPerChargeType,
    filteredAdversibleItems,
  } = newAdverseAction;
  const isAssessEnabled = useAssessEnabled();
  const isAssessedWithMvrAssessRules = Boolean(
    report?.motor_vehicle_report?.is_assessed_with_mvr_assess_rules,
  );

  const title = t(`${base_path}.title`);
  const subtitle = t(`${base_path}.subtitle`);

  const anyAdversibleItem =
    some(adversibleItems) || some(filteredAdversibleItems);
  const hiddenAdversibleItems = filteredAdversibleItems || [];

  if (!anyAdversibleItem) return null;

  return (
    <StyledSection data-testid='adverse-action-flow-items'>
      <p className='header'>
        <strong className='title'>{title}</strong>
        {subtitle}
      </p>
      {showAdversibleItemsGroupedBySeverity ? (
        adversibleItemsPerChargeType.map((itemGroup: any, i: number) => {
          return (
            <div
              key={`${itemGroup.diplay}-${i.toString()}`}
              data-testid={`adversible-item-severity-group-${itemGroup.name}`}
            >
              <strong className='list-label'>
                {humanizeOnUnderscore(itemGroup.display)}
              </strong>
              <List
                adversibleItems={itemGroup.items}
                isAssessEnabled={isAssessEnabled}
                isAssessedWithMvrAssessRules={isAssessedWithMvrAssessRules}
              />
            </div>
          );
        })
      ) : (
        <List
          adversibleItems={adversibleItems}
          isAssessEnabled={isAssessEnabled}
          isAssessedWithMvrAssessRules={isAssessedWithMvrAssessRules}
        />
      )}
      {hiddenAdversibleItems?.length > 0 && (
        <Filtered adverseItems={hiddenAdversibleItems} />
      )}
    </StyledSection>
  );
};

export default Items;
