import { ManualBulkUploadData } from '../SharedItems';
import { ManualBulkUploadRow } from './types';
import { CsvReg } from './constants';

export const removeErrorRows = (
  data: ManualBulkUploadData,
): ManualBulkUploadData => {
  const newData = data.parsedData.filter(
    (_, index) => !data.rowsWithError.includes(index + 1),
  );

  return {
    fileName: `${data.fileName.slice(0, -4)}-no-errors.csv`,
    fileSize: data.fileSize,
    parsedData: newData,
    rowsWithError: [],
    isValid: true,
  };
};

export const getCSVContent = (array: any[]): string => {
  if (!array.length) {
    return '';
  }

  const header = Object.keys(array[0]);

  return [
    header.join(','), // header row first
    ...array.map(row =>
      header.map(fieldName => JSON.stringify(row[fieldName])).join(','),
    ),
  ]
    .join('\r\n')
    .replace(/['"]+/g, '');
};

export const showLinesWithError = (arr: number[]) => {
  const uiLines = arr.map(item => item + 1);
  if (arr.length > 9) {
    return `${uiLines.slice(0, 9).join(', ')}, and ${uiLines.length - 9} more`;
  }
  return uiLines.join(', ');
};

export const removeDuplicates = (arr: any[]) => {
  return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
};

export const getRowsWithErrors = (data: ManualBulkUploadRow[]): number[] => {
  const rowsWithErrors: number[] = [];

  data.forEach((row, index) => {
    for (const [key, value] of Object.entries(row)) {
      if (key in CsvReg && !CsvReg[key].test(value as string)) {
        rowsWithErrors.push(index + 1);
      }
    }
  });

  return removeDuplicates(rowsWithErrors);
};
