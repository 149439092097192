import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import MultiSelectFilter from './MultiSelectFilter';
import Skeleton from './Skeleton';
import { getGeoIds, getProgramsByGeo } from '../helpers';
import { styledFilter } from './StyledComponents';

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  ${styledFilter}
`;

const Geo = () => {
  const {
    geos: apiGeos,
    geosLoading,
    defaultGeos,
    originalPrograms,
    currentUser,
    setPrograms,
  } = useSearchState();
  const segmentationEnabled = currentUser?.account?.segmentation_enabled;
  const text = segmentationEnabled ? 'Compliance Location' : 'Location';

  const trackEvent = useTrackEvent();

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onGeoChange = useCallback(
    (setFilters: (params: any) => void) =>
      ({ selectedItems: newGeos }: { selectedItems: Array<any> }): void => {
        const geoIds = getGeoIds(newGeos);

        if (newGeos.length > selectedItemsCount && currentUser) {
          trackEvent(
            CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
            {
              // get last item of array of selected locations which is equal to the latest added location
              Location: newGeos.slice(-1).pop().name,
              'Selected Filter': 'Location',
            },
          );
        }
        setSelectedItemsCount(newGeos.length);

        setFilters(geoIds);

        // filter programs based on geos
        if (originalPrograms.length > 0) {
          if (newGeos.length) {
            const filteredPrograms = getProgramsByGeo(originalPrograms, geoIds);
            const programs = [originalPrograms[0], ...filteredPrograms];
            setPrograms(programs);
          } else {
            setPrograms(originalPrograms);
          }
        }
      },
    [
      selectedItemsCount,
      currentUser,
      originalPrograms,
      trackEvent,
      setPrograms,
    ],
  );

  return (
    <>
      {apiGeos.length > 0 && defaultGeos && !geosLoading ? (
        <StyledMultiSelectFilter
          geos={apiGeos}
          onChange={onGeoChange}
          initialSelectedItems={defaultGeos}
          segmentationEnabled={segmentationEnabled}
          component='GeoFilter'
          param='geo_ids'
          placeholder='location'
          titleText={text}
        />
      ) : (
        <div />
      )}
      <Skeleton isLoading={geosLoading} />
    </>
  );
};

export default Geo;
