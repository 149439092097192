import React, { useCallback } from 'react';
import { useUser } from 'context/CurrentUser';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import { UserType } from 'modules/case-management/types';
import { M } from '@dashboard-experience/mastodon';
import { useTaskSearchParams, useUsers } from '../hooks';
import FilterLabel from './FilterLabel';

export const Assignment: React.FC = () => {
  const history = useHistory();
  const taskParams = useTaskSearchParams();
  const { t } = useTranslation('case-management');
  const { data } = useUsers();
  const user = useUser();

  const userId = user?.id;
  const caseManagementAssignments = Object.entries(
    t('tasks.filters.assignment.options', { returnObjects: true }),
  ).map(([key, value]) => {
    if (key === 'assigned_to_you') {
      return {
        value: userId,
        text: t(value as string),
      };
    }
    return {
      value: key,
      text: capitalize(value as string),
    };
  });
  const filteredUsers = data?.data?.filter(
    (user: UserType) => user.id !== userId,
  );
  const users =
    filteredUsers?.map((user: UserType) => ({
      value: user.id,
      text: user.display_label,
    })) || [];
  const adjudicators = caseManagementAssignments.concat(users);

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      const searchParams = new URLSearchParams({
        ...taskParams,
        page: '1',
      } as Record<string, string>);
      if (value) {
        searchParams.set('assignee_id', value);
      } else {
        searchParams.delete('assignee_id');
      }

      history.push({
        pathname: TASKS_PATH,
        search: searchParams.toString(),
      });
    },
    [history, taskParams],
  );

  return (
    <M.FilterSelect
      data-testid='case-management-tasks-page-assignment-filter'
      additionalFilters={adjudicators}
      onChange={onChange}
      labelText={<FilterLabel filterType='assignment' />}
      value={taskParams.assignee_id}
    />
  );
};

export default Assignment;
