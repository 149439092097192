import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Button } from 'components/Button';
import { Namespace } from 'modules/assess/api';
import * as api from 'modules/assess/api/ruleset-versions';
import { useNamespace } from 'modules/assess/ui/router/context';
import { useDraft } from 'modules/assess/ui/ruleset/version/api';
import * as Amplitude from 'modules/assess/v2/models/amplitude';
import { StyledComponents } from 'modules/assess/v2/ui/common';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import { ID } from 'modules/id';

type Props = {
  ruleset?: ID;
};

const StyledContainer = styled(StyledComponents.SlateContainer)`
  color: ${colors.brandNavy3};
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const Description = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Container: React.FC<Props> = ({ ruleset }) => {
  const version = useDraft(ruleset).data?.id;
  const { t } = useTranslation('assess:v2');
  const description = t('review_and_apply.review.description');
  const title = t('review_and_apply.review.title');
  const namespace = useNamespace() || Namespace.criminal;

  const track = useTrackEvent();

  const containerTestid = 'assess-v2-ruleset-version-review-container';
  const buttonTestid = 'assess-v2-ruleset-version-review-export';

  const handleExport = useCallback(() => {
    if (!version) {
      return;
    }

    api.downloadPdf(namespace, version);
    track(Amplitude.ReviewAndApply.ExportGuidelines, {
      [Amplitude.ReviewAndApply.Page]: Amplitude.ReviewAndApply.Pagename,
    });
  }, [namespace, track, version]);

  const basePath = 'assess-v2-ui-guideline-review-and-apply-review-container';
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;

  return (
    <StyledContainer data-testid={containerTestid}>
      <Title data-testid={titleId}>{title}</Title>
      <Description data-testid={descriptionId}>{description}</Description>
      <Button
        icon='Download'
        kind='secondary'
        size='sm'
        data-testid={buttonTestid}
        trans='verbs.download'
        onClick={handleExport}
      />
    </StyledContainer>
  );
};

export default Container;
