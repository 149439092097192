import {
  ADD_IDP_CONNECTION_FAILURE,
  ADD_IDP_CONNECTION_REQUEST,
  ADD_IDP_CONNECTION_SUCCESS,
  DELETE_IDP_CONNECTION_FAILURE,
  DELETE_IDP_CONNECTION_REQUEST,
  DELETE_IDP_CONNECTION_SUCCESS,
  deserializeIdpConnection,
  FETCH_IDP_CONNECTIONS_FAILURE,
  FETCH_IDP_CONNECTIONS_REQUEST,
  FETCH_IDP_CONNECTIONS_SUCCESS,
  UPDATE_IDP_CONNECTION_FAILURE,
  UPDATE_IDP_CONNECTION_REQUEST,
  UPDATE_IDP_CONNECTION_SUCCESS,
} from '../actions';

import { extractError } from '../utils/APIClient';
import { httpHelper } from './reducerHelpers';

export const initialState = {
  connection: {
    id: null,
    signInUrl: '',
    signingCert: null,
    emailDomain: '',
  },
  fetchRequest: {
    ...httpHelper.initialState,
  },
  addRequest: {
    ...httpHelper.initialState,
  },
  updateRequest: {
    ...httpHelper.initialState,
  },
  deleteRequest: {
    ...httpHelper.initialState,
  },
};

// eslint-disable-next-line default-param-last
export const IdpConnectionsReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case FETCH_IDP_CONNECTIONS_REQUEST:
      return {
        ...state,
        fetchRequest: {
          ...httpHelper.onRequest,
        },
      };
    case FETCH_IDP_CONNECTIONS_SUCCESS:
      return {
        ...state,
        connection:
          deserializeIdpConnection(action.payload[0]) ||
          initialState.connection,
        fetchRequest: {
          ...httpHelper.onSuccess,
        },
      };
    case FETCH_IDP_CONNECTIONS_FAILURE:
      return {
        ...state,
        fetchRequest: {
          ...httpHelper.onFailure,
          errorMsg: extractError(action.error),
        },
      };
    case ADD_IDP_CONNECTION_REQUEST:
      return {
        ...state,
        addRequest: {
          ...httpHelper.onRequest,
        },
        updateRequest: {
          ...httpHelper.initialState,
        },
        deleteRequest: {
          ...httpHelper.initialState,
        },
      };
    case ADD_IDP_CONNECTION_SUCCESS:
      return {
        ...state,
        connection: deserializeIdpConnection(action.payload),
        addRequest: {
          ...httpHelper.onSuccess,
          successMsg: 'Idp Connection Successfully Created',
        },
      };
    case ADD_IDP_CONNECTION_FAILURE:
      return {
        ...state,
        addRequest: {
          ...httpHelper.onFailure,
          errorMsg: extractError(action.error).filter(
            (value, i, arr) => arr.indexOf(value) === i,
          ),
        },
      };
    case UPDATE_IDP_CONNECTION_REQUEST:
      return {
        ...state,
        addRequest: {
          ...httpHelper.initialState,
        },
        updateRequest: {
          ...httpHelper.onRequest,
        },
        deleteRequest: {
          ...httpHelper.initialState,
        },
      };
    case UPDATE_IDP_CONNECTION_SUCCESS:
      return {
        ...state,
        connection: deserializeIdpConnection(action.payload),
        updateRequest: {
          ...httpHelper.onSuccess,
          successMsg: 'Idp Connection Successfully Updated',
        },
      };
    case UPDATE_IDP_CONNECTION_FAILURE:
      return {
        ...state,
        updateRequest: {
          ...httpHelper.onFailure,
          errorMsg: extractError(action.error).filter(
            (value, i, arr) => arr.indexOf(value) === i,
          ),
        },
      };
    case DELETE_IDP_CONNECTION_REQUEST:
      return {
        ...state,
        addRequest: {
          ...httpHelper.initialState,
        },
        updateRequest: {
          ...httpHelper.initialState,
        },
        deleteRequest: {
          ...httpHelper.onRequest,
        },
      };
    case DELETE_IDP_CONNECTION_SUCCESS:
      return {
        ...state,
        connection: initialState.connection,
        deleteRequest: {
          ...httpHelper.onSuccess,
          successMsg: 'Idp Connection Successfully Deleted',
        },
      };
    case DELETE_IDP_CONNECTION_FAILURE:
      return {
        ...state,
        deleteRequest: {
          ...httpHelper.onFailure,
          errorMsg: extractError(action.error),
        },
      };
    default:
      return state;
  }
};
