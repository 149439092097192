import Segment from './segment';

export type Type = {
  has_adverse_actions_data: boolean;
  has_above_than_average_adverse_actions_rate: boolean;
  has_at_least_thirty_adverse_actions: boolean;
  has_a_valid_segment: boolean;
  over_seven_year_charge_adverse_actions?: number;
  pending_charge_adverse_actions?: number;
  petty_offenses_adverse_actions?: number;
  rate_average_performer_adverse_actions?: number;
  segment?: Segment;
  top_rate_performer_adverse_actions?: number;
  total_adverse_actions?: number;
  total_candidate_adverse_actions?: number;
  total_consider_reports?: number;
  under_eighteen_charges_adverse_actions?: number;
  under_twenty_five_charges_adverse_actions?: number;
};

export const emptyAdverseActions = (type: Type): boolean => {
  return !type.has_adverse_actions_data;
};

export const hasLessThanThirtyAdverseActions = (type: Type): boolean => {
  return !type.has_at_least_thirty_adverse_actions;
};

export type Map = { [key: string]: Type };
export default Type;
