import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

const SUBSTATUSES = {
  NO_RECORD_VERIFIED: {
    icon: 'CheckmarkFilled',
    color: colors.uiGreen400,
    title: 'Declaration matches results',
    subtitle:
      'No convictions were declared. This was successfully verified by the Canadian authorities.',
    description: `Based solely on the name(s) and date of birth provided and the criminal record
    information declared by the applicant, a search of the RCMP National Repository of Criminal
    Records did not identify any records with the name(s) and date of birth of the applicant.
    Positive identification that a criminal record does or does not exist at the RCMP National
    Repository of Criminal Records can only be confirmed by fingerprint comparison. Delays do exist
    between a conviction being rendered in court, and the details being accessible on the RCMP
    National Repository of Criminal Records. Not all offences are reported to the RCMP National
    Repository of Criminal Records.`,
  },
  RECORD_VERIFIED: {
    icon: 'CheckmarkFilled',
    color: colors.uiGreen400,
    title: 'Declaration matches results',
    subtitle: `Candidate provided criminal records that were successfully verified by the Canadian
      authorities.`,
    description: `Based solely on the name(s) and date of birth provided and the criminal record
    information declared by the applicant, a search of the RCMP National Repository of Criminal
    Records has resulted in a possible match to a registered criminal record. Positive
    identification that a criminal record does or does not exist at the RCMP National Repository
    of Criminal Records can only be confirmed by fingerprint comparison. As such, the criminal
    record information declared by the applicant does not constitute a Certified Criminal Record by
    the RCMP. Delays do exist between a conviction being rendered in court, and the details being
    accessible on the RCMP National Repository of Criminal Records. Not all offences are reported
    to the RCMP National Repository of Criminal Records.`,
  },
  NOT_VERIFIED: {
    icon: 'WarningFilled',
    color: colors.uiPink600,
    title: 'Declaration does not match result',
    subtitle: `Candidate provided information that the Canadian authorities were not able to verify.
      Some information may be missing or inaccurate.`,
    description: `Based solely on the name(s) and date of birth provided and the criminal record
    information declared by the applicant, a search of the RCMP National Repository of Criminal
    Records could not be completed. Positive identification that a criminal record does or does not
    exist requires the applicant to submit fingerprints to the RCMP National Repository of
    Criminal Records by an authorized police service or accredited private fingerprinting company.
    Delays do exist between a conviction being rendered in court, and the details being accessible
    on the RCMP National Repository of Criminal Records. Not all offences are reported to the RCMP
    National Repository of Criminal Records.`,
  },
} as any;

const Substatus: React.FC<{ substatus: string }> = ({ substatus }) => {
  const statusInfo = SUBSTATUSES[substatus];
  if (!statusInfo) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h3
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <M.Icon
          icon={statusInfo.icon}
          size={16}
          style={{ fill: statusInfo.color, marginRight: '0.5rem' }}
        />
        <div className='title'>{statusInfo.title}</div>
      </h3>
      <div className='subtitle'>{statusInfo.subtitle}</div>
      <br />
      <div className='description'>{statusInfo.description}</div>
    </div>
  );
};

export default Substatus;
export { Substatus };
export { SUBSTATUSES };
