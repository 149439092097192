import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';

export type CandidateContactInfo = {
  phone_number?: string;
  first_name?: string;
};

const postCandidateInvites = async (
  packageSlug: string,
  work_locations: Array<{ country: string; city: string; state: string }>,
  candidate_emails: string[],
) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/candidate_invitations`,
    {
      emails: candidate_emails,
      package: packageSlug,
      work_locations,
      invited_from: 'self-service',
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

type Params = {
  packageSlug: string;
  work_locations: Array<{ country: string; city: string; state: string }>;
  candidate_emails: string[];
};

function usePostCandidateInvites() {
  const [mutate, result] = useMutation(
    async ({ packageSlug, work_locations, candidate_emails }: Params) => {
      const response = await postCandidateInvites(
        packageSlug,
        work_locations,
        candidate_emails,
      );
      return { res: response.data };
    },
  );

  const { isLoading, error } = result;

  return {
    mutate,
    data: result,
    isLoading,
    error,
  };
}

export default usePostCandidateInvites;
