import React, { useState, useCallback, useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import UserContext from 'context/CurrentUser';
import { useGetCohorts } from 'api/randomDrugTesting';
import { ICohort } from 'components/RandomTestingProgram/Cohort/types';
import styled from 'styled-components';
import ActionableErrorNotification from 'components/RandomTestingProgram/Cohort/ActionableErrorNotification';
import {
  RandomizerHeader,
  RandomizerBody,
  CreateCohortModal,
} from '../../components/RandomTestingProgram';
import Landing from '../../components/RandomTestingProgram/Landing/Landing';

const RandomizerPage = () => {
  const [showCreateCohortModal, setShowCreateCohortModal] = useState(false);
  const [isHeaderVisible, setHeaderVisible] = useState(false);
  const currentUser = useContext(UserContext);
  const accountId = currentUser?.account?.id;

  const {
    data: cohorts,
    isLoading,
    isError,
    refetch,
  }: {
    data: ICohort[] | undefined;
    isLoading: boolean;
    isError: boolean;
    refetch: Function;
  } = useGetCohorts();

  const openNewCohortModal = useCallback(
    () => setShowCreateCohortModal(true),
    [],
  );

  const setHeaderVisibility = useCallback(value => setHeaderVisible(value), []);
  const showHeader = cohorts && (cohorts?.length > 0 || isHeaderVisible);

  const closeNewCohortModal = useCallback(
    () => setShowCreateCohortModal(false),
    [],
  );

  const refetchData = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <StyledGrid>
      {showHeader && (
        <RandomizerHeader handleCreateNewCohort={openNewCohortModal} />
      )}
      {isError && (
        <StyledContainer type='transparent'>
          <ActionableErrorNotification
            title='There was an error retrieving your pools.'
            message='Please try again and contact support if the issue persists.'
            refetchCallback={refetchData}
          />
        </StyledContainer>
      )}
      {isLoading ? (
        <StyledLoadingContainer type='transparent'>
          <StyledSkeletonLoader type='multi' />
          <StyledSkeletonLoader type='multi' />
          <StyledSkeletonLoader type='multi' />
        </StyledLoadingContainer>
      ) : (
        <>
          {cohorts && cohorts.length > 0 && (
            <RandomizerBody cohorts={cohorts} />
          )}
          {cohorts?.length === 0 && (
            <Landing
              handleCreateNewCohort={openNewCohortModal}
              setHeaderVisibility={setHeaderVisibility}
            />
          )}
        </>
      )}
      {showCreateCohortModal && (
        <CreateCohortModal
          accountId={accountId}
          onClose={closeNewCohortModal}
          isOpen={showCreateCohortModal}
          refetchCallback={refetch}
          cohortNames={cohorts}
        />
      )}
    </StyledGrid>
  );
};

export default RandomizerPage;

const StyledGrid = styled(M.Grid)`
  min-height: 80vh;
`;

const StyledContainer = styled(M.Container)`
  margin-top: 20%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingContainer = styled(M.Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledSkeletonLoader = styled(M.CodeSnippetSkeleton)`
  height: 200px !important;
`;
