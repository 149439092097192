import React, { useMemo, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { isoCountry } from 'utils';
import { EMPLOYMENT_PROOF_DOC_TYPES } from 'Constants';
import {
  CandidateEmploymentHistoriesParams,
  useUpdateCandidateEmploymentHistorySettings,
} from 'api/candidateEmploymentHistories';
import {
  Document as CandidateDocument,
  Employer,
  EmploymentRecord,
} from 'types';
import Document from '../Document';
import DoNotContact from './DoNotContact';
import HistoryTimeline from '../HistoryTimeline';
import Position from './Position';
import VerificationTable from './VerificationTable';
import DOTv1Questions from './DOTv1Questions';
import DOTv2Questions from './DOTv2Questions';

export type RecordProps = {
  record: EmploymentRecord;
  statusType: string;
  documents?: CandidateDocument[];
  pending: boolean;
  isEditable: boolean;
  completed: boolean;
  status: string;
};

const ScreeningRow = styled.div`
  padding: 24px 0 24px 0;
`;

const Section = styled.div`
  padding: 8px 0 16px 0;
`;

const buildName = (employer: Employer) => {
  const name = [employer.name];
  if (employer.address?.city) {
    name.push(employer.address.city);
    name.push(employer.address.state);
    if (employer.address.country && employer.address.country !== 'US') {
      name.push(isoCountry(employer.address.country));
    }
  }
  return name.join(', ');
};

const Record: React.FC<RecordProps> = ({
  record,
  status,
  statusType,
  documents,
  pending,
  isEditable,
  completed,
}) => {
  const filteredDocuments: CandidateDocument[] = useMemo(
    () =>
      documents?.filter(
        document =>
          EMPLOYMENT_PROOF_DOC_TYPES.includes(document.type) &&
          (document.alternate_documentable_id === record.employer.id ||
            document.alternate_documentable_id === record.id),
      ) || [],
    [documents, record],
  );
  const { call, result } = useUpdateCandidateEmploymentHistorySettings(
    record.id,
  );
  const processing = result.isLoading;
  const saveSettings = useCallback(
    (params: CandidateEmploymentHistoriesParams) => {
      if (Object.keys(params).length > 0) call(params);
    },
    [call],
  );
  return (
    <ScreeningRow>
      <M.Screening.Heading
        headingLeft={{
          name: buildName(record.employer),
          strong: true,
        }}
        headingRight={
          <M.StatusBadge
            status={statusType === StatusTypes.Assess ? status : record.status}
            statusType={statusType}
          />
        }
      />
      {record.cancellation_reason && (
        <Section>
          <div>
            Cancellation Reason: {record.cancellation_reason_description}
          </div>
        </Section>
      )}
      <Section>
        <Position record={record} />
      </Section>
      {record.employer.do_not_contact && !record.is_argyle_employment && (
        <Section>
          <DoNotContact />
        </Section>
      )}
      <Section>
        <VerificationTable
          record={record}
          pending={pending}
          isEditable={isEditable}
          saveSettings={saveSettings}
          processing={processing}
        />
      </Section>
      {record.result.questions.length > 0 && (
        <Section>
          {record.result.dot_variant === 'DOTv2' ? (
            <DOTv2Questions
              testId='dotv2-questions'
              questions={record.result.questions}
            />
          ) : (
            // Fallback default Questions rendering when dot_variant is v1 or unknown.
            <DOTv1Questions
              testId='dotv1-questions'
              questions={record.result.questions}
            />
          )}
        </Section>
      )}
      {record.events && record.events.length > 0 && (
        <Section>
          <HistoryTimeline events={record.events} />
        </Section>
      )}
      {completed &&
        filteredDocuments.map((document: CandidateDocument) => (
          <Document key={document.id} document={document} />
        ))}
    </ScreeningRow>
  );
};
export default Record;
