import React, { useCallback, useState, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { QueryResult } from 'react-query';
import {
  SegmentsTable,
  SegmentsFooter,
  SegmentsAddConfirmationSuccess,
  BodyContainer,
  SegmentsSearch,
  SegmentsPagination,
} from 'components/Segments';
import {
  useSegmentsContext,
  useDispatch as useSegmentsDispatch,
} from 'components/Segments/SegmentsContext';
import {
  Segment,
  ParentContext,
  FetchParentObjectResponse,
  SegmentAssignedNodes,
  useFetchParentObject,
  useGetAssignedNodes,
  GET_ASSIGNED_NODES,
} from '../api/segments';

type Props = {
  accountId: string;
  parentContext: ParentContext;
  parentId: string;
  closeModal: any;
};

const SegmentsContainer: React.FC<Props> = ({
  accountId,
  parentContext,
  parentId,
  closeModal,
}) => {
  const [initialSelectedSegments, setInitialSelectedSegments] = useState<
    string[]
  >([]);
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  const [unselectedSegments, setUnselectedSegments] = useState<string[]>([]);
  const [currentAssignment, setCurrentAssignment] = useState<string[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<Segment[]>([]);
  const [canShowSegmentsBody, setCanShowSegmentsBody] =
    useState<boolean>(false);

  const segmentsDispatch = useSegmentsDispatch();

  const {
    data: parentObject,
    isFetching: apiParentObjectLoading,
    isError: hasParentObjectError,
  }: QueryResult<FetchParentObjectResponse> = useFetchParentObject(
    accountId,
    parentContext,
    parentId,
  );

  const {
    data: assignedNodes,
    isFetching: apiAssignedNodesLoading,
    isError: hasAssignedNodesError,
  }: QueryResult<SegmentAssignedNodes> = useGetAssignedNodes(
    accountId,
    parentContext,
    parentId,
  );

  const {
    showAssignSegmentsConfirmationView,
    showAssignSegmentsSuccessView,
    filteredSegments: filteredSegmentsContext,
    search,
  } = useSegmentsContext();

  useEffect(() => {
    const canShow =
      !apiParentObjectLoading &&
      !hasParentObjectError &&
      !apiAssignedNodesLoading &&
      !hasAssignedNodesError;

    setCanShowSegmentsBody(canShow);
  }, [
    apiAssignedNodesLoading,
    apiParentObjectLoading,
    hasAssignedNodesError,
    hasParentObjectError,
  ]);

  useEffect(() => {
    if (assignedNodes) {
      const { nodes } = assignedNodes;
      const nodeIds = nodes.map(node => node.custom_id);
      setSegments(nodes);
      setFilteredSegments(nodes);
      setInitialSelectedSegments(nodeIds);
      segmentsDispatch({
        type: GET_ASSIGNED_NODES,
        payload: {
          segments: nodes,
          initialSelectedSegments: nodeIds,
          filteredSegments: nodes,
        },
      });
    }
  }, [assignedNodes]);

  useEffect(() => {
    setSelectedSegments(initialSelectedSegments);
  }, [initialSelectedSegments]);

  useEffect(() => {
    if (selectedSegments && initialSelectedSegments.length > 0) {
      setCurrentAssignment(
        selectedSegments.filter(segment => {
          return !initialSelectedSegments.includes(segment);
        }),
      );
    } else if (selectedSegments) {
      setCurrentAssignment(selectedSegments);
    }
  }, [selectedSegments, initialSelectedSegments]);

  useEffect(() => {
    setFilteredSegments(filteredSegmentsContext);
    const fullList = Array.from(
      [...segments, ...filteredSegmentsContext]
        .reduce((m, o) => m.set(o.custom_id, o), new Map())
        .values(),
    );
    setSegments(fullList);
  }, [filteredSegmentsContext]);

  const mergeSelection = (
    selectedSegment: string,
    setSelectedSegments: Function,
  ) => {
    setSelectedSegments((prevSelectedSegments: string[]) =>
      prevSelectedSegments.includes(selectedSegment)
        ? prevSelectedSegments.filter(id => id !== selectedSegment)
        : [...prevSelectedSegments, selectedSegment],
    );
  };

  const updateSelectedSegments = useCallback(
    segmentId => mergeSelection(segmentId, setSelectedSegments),
    [setSelectedSegments],
  );
  const updateUnselectedSegments = useCallback(
    segmentId => {
      mergeSelection(segmentId, setUnselectedSegments);

      if (!search.isSearching) {
        setFilteredSegments((prevFilteredSegments: Segment[]) =>
          prevFilteredSegments.filter(
            segment => segment.custom_id !== segmentId,
          ),
        );
      }
    },
    [setUnselectedSegments, search],
  );

  return (
    <>
      {(apiParentObjectLoading || apiAssignedNodesLoading) && (
        <M.LoadingSpinner />
      )}
      {canShowSegmentsBody && (
        <>
          <M.ModalBody>
            {!showAssignSegmentsConfirmationView &&
              !showAssignSegmentsSuccessView && (
                <>
                  <SegmentsSearch
                    accountId={accountId}
                    parentContext={parentContext}
                    segments={segments}
                    selectedSegments={selectedSegments}
                  />
                  <BodyContainer>
                    <SegmentsTable
                      filteredSegments={filteredSegments}
                      selectedSegments={selectedSegments}
                      updateUnselectedSegments={updateUnselectedSegments}
                      updateSelectedSegments={updateSelectedSegments}
                      initialSelectedSegments={initialSelectedSegments}
                      parentContext={parentContext}
                    />
                  </BodyContainer>
                  <SegmentsPagination
                    accountId={accountId}
                    parentContext={parentContext}
                  />
                </>
              )}
            {(showAssignSegmentsConfirmationView ||
              showAssignSegmentsSuccessView) && (
              <BodyContainer>
                <SegmentsAddConfirmationSuccess
                  segments={segments}
                  parentObject={parentObject!}
                  parentContext={parentContext}
                  unselectedSegments={unselectedSegments}
                  success={showAssignSegmentsSuccessView}
                  confirmation={showAssignSegmentsConfirmationView}
                  currentAssignment={currentAssignment}
                />
              </BodyContainer>
            )}
          </M.ModalBody>
          <SegmentsFooter
            accountId={accountId}
            parentId={parentId}
            parentContext={parentContext}
            selectedSegments={selectedSegments}
            unselectedSegments={unselectedSegments}
            currentAssignment={currentAssignment}
            closeModal={closeModal || null}
          />
        </>
      )}
    </>
  );
};

export default SegmentsContainer;
