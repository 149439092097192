import { CANDIDATE_REDESIGN } from 'Flags';
import NewInvitationContainer from 'containers/Candidate/NewInvitationContainer';
import { useBetaEnabled } from 'providers/Beta';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import ExistingInvitation from './ExistingInvitation';

const CandidateInvitations: React.FC<{
  invitationId?: string;
}> = ({ invitationId }) => {
  const candidate = useCandidate();
  const { invitations = [], last_report_id } = candidate;
  const hasInvitation = invitations.length > 0;

  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  // Don't show invitation components if viewing a report
  if (last_report_id && !invitationId) return null;

  if (hasInvitation) {
    if (candidateRedesignEnabled) {
      return null;
    }
    return <ExistingInvitation />;
  }
  return <NewInvitationContainer />;
};

export default CandidateInvitations;
