import * as Entity from 'modules/assess/models/insights/entity';
import client from './client';
import { Namespace } from './constants';

const BASE_PATH = 'ruleset_versions';

export const fetch = (
  namespace: Namespace,
  version: string,
): Promise<Entity.Type> => {
  return client.get(path(namespace, version));
};

export const path = (namespace: Namespace, version: string): string => {
  return `/${namespace}/${BASE_PATH}/${version}/insights`;
};
