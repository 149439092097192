import { normalize } from 'normalizr';

import {
  FETCH_CANDIDATE_REQUEST,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_FAILURE,
} from 'actions/ActionTypes';

import { candidateSchema } from 'Schema';

import { httpHelper } from './reducerHelpers';

export const initialState = {
  fetchRequest: { ...httpHelper.initialState },

  candidates: {},
};

// eslint-disable-next-line default-param-last
const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CANDIDATE_REQUEST:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onRequest },
      };

    case FETCH_CANDIDATE_SUCCESS: {
      const { candidate } = action;

      return {
        ...state,
        fetchRequest: { ...httpHelper.onSuccess },

        ...normalize(candidate, candidateSchema).entities,
      };
    }
    case FETCH_CANDIDATE_FAILURE:
      return {
        ...state,
        fetchRequest: { ...httpHelper.onFailure },
      };

    default:
      return state;
  }
};

export default candidateReducer;
