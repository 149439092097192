/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import { StyledButton } from 'components/Account/Payment/PaymentStyledComponents';
import { scrollToTop } from 'utils';
import {
  validateNewIdPattern,
  validateNewIdUniqueness,
} from 'utils/PaymentProfileUtils';
import { PaymentProfilesTable } from 'components/PaymentProfiles';
import {
  HeadingButtonsContainer,
  HeadingContainer,
  StyledFormHeading,
} from 'components/PaymentProfiles/PaymentProfileStyledComponents';
import ProfileForm from 'components/PaymentProfiles/ProfileForm';
import { usePutBillingCustomer } from 'api/billing';
import BillingCustomerContext from './context';
import NodeBillingDisablePopup from '../../components/PaymentProfiles/NodeBillingDisablePopup';

const tableHeaders = [
  {
    key: 'id',
    header: 'id',
    name: 'profileId',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'name',
    header: 'name',
    name: 'profileName',
    className: 'left',
    tooltip: false,
  },
  {
    key: 'email',
    header: 'email',
    name: 'billingContact',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'address',
    header: 'address',
    name: 'billingAddress',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'editButton',
    header: 'editButton',
    name: '',
    className: 'left',
    tooltip: false,
  },
];

const PaymentProfilesContainer: React.FC = () => {
  const { t } = useTranslation();
  const {
    customerData,
    isLoading: fetchLoading,
    error,
    refetch,
  } = useContext(BillingCustomerContext);

  // FOR BUTTON
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const newPaymentProfile = {
    id: '',
    name: '',
    email: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      country: '',
      city: '',
      region: '',
      postal_code: '',
    },
  };

  const { call: postPaymentProfile } = usePutBillingCustomer();

  const idValidator = useCallback(
    newId => {
      if (!customerData) {
        return 'no customer data';
      }
      const existingProfiles = customerData.billing_entities;
      const idValidation = validateNewIdPattern(newId);

      if (existingProfiles.length === 0 || !idValidation.valid) {
        return idValidation;
      }

      return validateNewIdUniqueness(newId, existingProfiles);
    },
    [customerData],
  );

  const submit = useCallback(
    params => {
      if (customerData) {
        const newParams = {
          ...customerData,
          billing_entities: [
            {
              id: params.id,
              name: params.name,
              email: params.email,
              address: {
                address_line_1: params.address_line_1,
                address_line_2: params.address_line_2,
                country: params.country,
                city: params.city,
                region: params.region,
                postal_code: params.postal_code,
              },
            },
          ],
        };

        postPaymentProfile(newParams).finally(() => {
          refetch();
        });
      }
    },
    [refetch, postPaymentProfile, customerData],
  );

  if (!customerData || fetchLoading) {
    return <LoadingScreen />;
  }

  const billingEntities = customerData.billing_entities;
  const mainAccountProfile = {
    id: 'account',
    name: 'Main Account',
    email: customerData.email,
    address: customerData.address,
  };
  const records = [mainAccountProfile, ...billingEntities];

  return (
    <div>
      <HeadingContainer data-testid='payment-profile-heading-container'>
        <StyledFormHeading data-testid='pp-heading-text'>
          {t('headings.paymentProfiles')}
        </StyledFormHeading>
        <HeadingButtonsContainer>
          <StyledButton
            data-testid='add-payment-profile-button'
            kind='primary'
            onClick={showModal}
          >
            <M.Icon icon='Add' />
            {t('payment_profile.add_payment_profile_button')}
          </StyledButton>
        </HeadingButtonsContainer>
      </HeadingContainer>
      <M.TableContainer style={{ overflow: 'auto' }}>
        <PaymentProfilesTable
          isLoading={fetchLoading}
          error={error}
          submit={submit}
          tableHeaders={tableHeaders}
          records={records}
          EditForm={ProfileForm}
          NodeBillingDisablePopup={NodeBillingDisablePopup}
        />
      </M.TableContainer>
      <ProfileForm
        data-testid='add-payment-profile-form'
        newIdValidator={idValidator}
        paymentProfile={newPaymentProfile}
        submit={submit}
        modalOpen={modalOpen}
        hideModal={hideModal}
        isLoading={fetchLoading}
      />
    </div>
  );
};

export default PaymentProfilesContainer;
