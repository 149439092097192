import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { LabelWithTooltipStyled } from './InvoicesStyledComponents';

type Props = {
  translation: Function;
  fileType: string;
};
const LabelWithTooltip: React.FC<Props> = ({ fileType, translation }) => (
  <LabelWithTooltipStyled>
    <span data-testid='heading'>{translation(`labels.${fileType}`)}</span>
    <M.TooltipDefinition
      style={{ marginLeft: '0.5rem' }}
      highlighted={false}
      align='bottom'
      definition={translation(`tooltips.${fileType}`)}
    >
      <M.Icon icon='Information' />
    </M.TooltipDefinition>
  </LabelWithTooltipStyled>
);

export default LabelWithTooltip;
