import React, { useCallback, useContext, useState } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import { GenericObject } from 'types';
import { EMAIL_SUPPRESSION_FLAG_KEY } from 'Constants';
import { useGetSuppressions } from 'api/communicator/hooks';
import UIContext from 'context/UI';
import { useFlag } from '@dashboard-experience/react-flagr';
import { postMessageToDashboard } from 'utils';
import { renderModal } from 'modules/candidate';
import { SuppressionProps } from '../types';
import {
  CandidateInfoEmailSuppressedContainer,
  CandidateInfoEmailSuppressedButton,
} from '../CandidateInformationStyledComponents';
import SuppressionModal from './SuppressionModal';

const Suppression: React.FC<SuppressionProps> = ({ candidate, allowEdit }) => {
  const { contextId, isIframe } = useContext(UIContext);

  const { data } = useGetSuppressions(candidate);

  const suppressions: undefined | GenericObject[] = data?.suppressions;

  const candidateEmailSuppressed = !!suppressions?.length;

  // Angular -> React migration flag
  const migratedSuppressionModal =
    useFlag(EMAIL_SUPPRESSION_FLAG_KEY)?.variantKey === 'on' || false;
  const [displaySuppressionModal, setDisplaySuppressionModal] =
    useState<boolean>(false);

  /**
   * @name openSuppressionListModal
   * @function
   * @memberOf Suppresion
   * @description Sends message back to angular to pop the modal
   */
  const openSuppressionListModal = useCallback(() => {
    // Legacy deletion modal - only show while in iframe AND lacking the Flagr
    if (isIframe && !migratedSuppressionModal) {
      postMessageToDashboard({
        contextId,
        broadcastMessage: 'open_suppression_list_modal',
      });
    }
    // Migrated modal - show while in standalone and/or with the Flagr
    else {
      setDisplaySuppressionModal(true);
    }
  }, [contextId, isIframe, migratedSuppressionModal]);

  // Function specifically for managing the Migrated modal
  const closeSuppressionModal = useCallback(() => {
    setDisplaySuppressionModal(false);
  }, []);

  return (
    <>
      {candidateEmailSuppressed && (
        <CandidateInfoEmailSuppressedContainer>
          <CandidateInfoEmailSuppressedButton
            icon='WarningAltFilled'
            onClick={openSuppressionListModal}
            size='16'
            style={{ fill: colors.uiOrange600 }}
            data-testid='candidate-information-field-suppression-icon'
          />
        </CandidateInfoEmailSuppressedContainer>
      )}

      {displaySuppressionModal &&
        // @ts-ignore
        renderModal(SuppressionModal, {
          candidate,
          showModal: displaySuppressionModal,
          onClose: closeSuppressionModal,
          allowEdit,
        })}
    </>
  );
};

export default Suppression;
