import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import PackagesTableRow from './PackagesTableRow';
import { Package } from '../../../types';

type Props = {
  packages: Package[];
  packagesCount: number;
  currentPageIndex: number;
  pageCount: number;
  updatePageNumber: Function;
};

const PackagesTable: React.FC<Props> = ({
  currentPageIndex,
  pageCount,
  packages,
  updatePageNumber,
  packagesCount,
}) => {
  const handleUpdatePage = useCallback(
    pageIndex => updatePageNumber(pageIndex),
    [],
  );

  if (!packagesCount) {
    return <em>No packages found.</em>;
  }

  return (
    <>
      <M.Table>
        <M.TableHead className='border-top'>
          <M.TableRow>
            <M.TableHeader>Name</M.TableHeader>
            <M.TableHeader>Program</M.TableHeader>
            <M.TableHeader>Screenings</M.TableHeader>
          </M.TableRow>
        </M.TableHead>
        <M.TableBody>
          {packages.map(pkg => (
            <PackagesTableRow pkg={pkg} key={pkg.id} />
          ))}
        </M.TableBody>
      </M.Table>
      <M.Pagination
        pageCount={pageCount}
        selectedIndex={currentPageIndex}
        onPageClick={handleUpdatePage}
      />
    </>
  );
};

export default PackagesTable;
