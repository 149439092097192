/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';

const MessageList = messages => (
  <M.UnorderedList>
    {messages.map(message => (
      <M.ListItem key={message}>{message}</M.ListItem>
    ))}
  </M.UnorderedList>
);

const IdpInlineNotifications = ({
  addIdpRequest,
  updateIdpRequest,
  deleteIdpRequest,
}) => {
  return (
    <>
      {addIdpRequest.error ? (
        <M.InlineNotification
          kind='error'
          title='Error Creating Connection'
          subtitle={MessageList(addIdpRequest.errorMsg)}
        />
      ) : null}
      {updateIdpRequest.error ? (
        <M.InlineNotification
          kind='error'
          title='Error Updating Connection'
          subtitle={MessageList(updateIdpRequest.errorMsg)}
        />
      ) : null}
      {deleteIdpRequest.error ? (
        <M.InlineNotification
          kind='error'
          title='Error Deleting Connection'
          subtitle={deleteIdpRequest.errorMsg}
        />
      ) : null}
      {addIdpRequest.success ? (
        <M.InlineNotification
          kind='success'
          title='Successfully Created Connection'
        />
      ) : null}
      {updateIdpRequest.success ? (
        <M.InlineNotification
          kind='success'
          title='Successfully Updated Connection'
        />
      ) : null}
      {deleteIdpRequest.success ? (
        <M.InlineNotification
          kind='success'
          title='Successfully Deleted Connection'
        />
      ) : null}
    </>
  );
};

IdpInlineNotifications.propTypes = {
  addIdpRequest: PropTypes.object.isRequired,
  updateIdpRequest: PropTypes.object.isRequired,
  deleteIdpRequest: PropTypes.object.isRequired,
};

export default IdpInlineNotifications;
