import { ID } from 'modules/id';
import { Entity } from 'modules/entity';

export type Type<E extends Entity> = Readonly<{
  ids: Readonly<Array<ID>>;
  table: Readonly<Record<ID, Readonly<E>>>;
}>;

export const initialize = <E extends Entity>(): Type<E> => ({
  ids: Object.freeze<ID>([]),
  table: Object.freeze({}),
});

export const entries = <E extends Entity>(
  cache: Type<E>,
): Array<Readonly<E>> => {
  return cache?.ids?.map(id => cache.table[id]);
};
