import { useState } from 'react';
import { getCandidateByEmail } from 'api/i9';
import { isEmailValid } from './patterns';

export const useEmailRegistration = () => {
  const [uniqueValues, setUniqueValues] = useState(new Set<string>());
  const isEmailRegistered = (value: string): Promise<boolean> => {
    if (isEmailValid(value)) {
      if (uniqueValues.has(value)) {
        return Promise.resolve(true);
      }
      getCandidateByEmail(value).then(result => {
        if (result.count > 0) {
          setUniqueValues(new Set(uniqueValues).add(value));
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      });
    }
    return Promise.resolve(false);
  };

  return {
    isEmailRegistered,
  };
};

export default useEmailRegistration;
