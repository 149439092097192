import { M } from '@dashboard-experience/mastodon';
import StyledComponent from 'styled-components';

export const Container = StyledComponent.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const ButtonContainer = StyledComponent.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px;
`;

export const Button = StyledComponent(M.Button)`
  min-height: 36px;
  margin: 0rem !important;
`;
