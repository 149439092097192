import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  components: {};
  tkey: string;
};

const TextSectionWithComponents: React.FC<Props> = ({ components, tkey }) => {
  const { t } = useTranslation('assess');
  return (
    <div data-testid='assess-rules-text-sections-with-components'>
      <h3>
        <Trans i18nKey={`assess:${tkey}.header`} t={t} />
      </h3>
      <p>
        <Trans
          i18nKey={`assess:${tkey}.paragraph`}
          t={t}
          components={components}
        />
      </p>
    </div>
  );
};

export default TextSectionWithComponents;
