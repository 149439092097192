import React from 'react';
import styled from 'styled-components';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';

type AssignmentProps = {
  assignments: Array<Assignment.Type>;
  noun: string;
};

const TypeContainer = styled.div`
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight};
`;

const List: React.FC<AssignmentProps> = ({ assignments, noun }) => {
  const { t } = useTranslation('assess');
  const status = t('assess:ruleset_assignments.status.assigned');
  const items = assignments.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <TypeContainer>
        {status} {t(noun)}
      </TypeContainer>
      {items.map(assignment => (
        <div key={assignment?.id}>{assignment?.name}</div>
      ))}
    </div>
  );
};

export default List;
