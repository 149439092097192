import { Base } from './base';

type Example = {
  action: string;
  description: string;
};

export class Option extends Base {
  private readonly path: string = `${this.basePath}.options`;

  description: string;

  title: string;

  example: Example;

  constructor(isEligibleItemTreatmentEnabled: boolean, option: string) {
    super(isEligibleItemTreatmentEnabled);
    const baseTranslationsPath = `${this.path}.${option.toLowerCase()}`;
    const optionPath = isEligibleItemTreatmentEnabled
      ? `${baseTranslationsPath}.${this.eligibleItemTreatmentPath}`
      : baseTranslationsPath;

    const examplePath = `${baseTranslationsPath}.example`;
    this.description = `${optionPath}.description`;
    this.title = `${optionPath}.title`;
    this.example = {
      action: `${examplePath}.action`,
      description: `${examplePath}.description`,
    };
  }
}
