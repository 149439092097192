import React, { useContext, useCallback, useState, MouseEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Level } from 'modules/assess/models/rules/lookback';
import Context from 'modules/assess/ui/rules/lookback/table/context';
import {
  useState as useStateContext,
  useDispatch,
} from 'modules/assess/ui/ruleset/version/store';
import { Actions, Entity } from 'modules/assess/ui/state/rules/lookback';
import SubCategoryExpandRow from './ExpandRow';
import SeverityPeriod from './severity-period';
import BulkEdit from './BulkEdit';
import SeverityRow from './severity-period/SeverityRow';
import { severity } from './severity-period/Charge';
import { SubCategory as SubCategoryType, Charge as ChargeType } from './types';
import { ChargeState, Severity } from './severity-period/types';
import { useExpanded } from './hooks';

const { TableCell } = M.DataTable;

type Props = {
  subCategory: SubCategoryType;
};

const bodyStyle = {
  lineHeight: 0,
};

const StyledIcon = styled(M.Icon)`
  margin-left: 2em;
  top: 0.4em;
  position: relative;
  opacity: ${({ editing }) => editing && '100% !important'};
`;

const getSubClasses = (disabled: boolean | undefined, editing: boolean) => {
  let classes = !disabled ? 'subcategory' : '';
  classes += editing ? ' bulk-edit-cell' : '';
  return classes;
};

type PartialCharge = Partial<ChargeType & ChargeState>;

const SubCategory: React.FC<Props> = ({ subCategory }) => {
  const { name, charges } = subCategory;
  const { setBulkEdit, disabled } = useContext(Context);
  const { handleClick, isExpanded } = useExpanded(charges);
  const dispatch = useDispatch();
  const state = useStateContext();
  const [editing, setEditing] = useState(false);
  const [chargesState, setChargesState] = useState(severity as Severity);

  const setFields = useCallback((data: Partial<Severity>) => {
    setChargesState(prev => ({
      ...prev,
      ...data,
    }));
  }, []);

  const icon = editing ? 'Close' : 'Edit';

  const subClasses = getSubClasses(disabled, editing);

  const lookbackRulesList: Array<any> = Object.values(
    state.rules?.lookback.table,
  );

  const handleEdit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!editing && !isExpanded) {
        handleClick();
      }
      setEditing(!editing);
    },
    [editing, isExpanded, handleClick, setEditing],
  );

  const handleBulkEdit = useCallback(() => {
    charges.forEach((charge: PartialCharge) => {
      const { felony, misdemeanor, petty_offense } = chargesState;

      const stateCharge = lookbackRulesList.find(x => x.key === charge.name);

      const updatedCharge = {
        ...charge,
        id: stateCharge?.id || charge.name,
        key: stateCharge?.key || charge.name,
        charge_subcategory: stateCharge?.charge_subcategory || name,
        charge_subsubcategory:
          stateCharge?.charge_subsubcategory || charge.name,
        felony,
        misdemeanor,
        petty_offense,
        level: Level.CATEGORY_2,
      };

      if (stateCharge) {
        dispatch(Actions.Update.create(updatedCharge as Entity.Type));
      } else {
        dispatch(Actions.Create.create(updatedCharge as Entity.Type));
      }
    });

    setBulkEdit(true);
  }, [charges, chargesState, dispatch, lookbackRulesList, name, setBulkEdit]);

  return (
    <M.Table>
      <M.TableBody style={bodyStyle}>
        <SubCategoryExpandRow
          sub='true'
          onClick={handleClick}
          isExpanded={editing || isExpanded}
          className={editing ? 'bulk-edit-row' : ''}
        >
          <TableCell colSpan={8} className={subClasses}>
            {name}
            {!disabled && (
              <StyledIcon
                icon={icon}
                size='20'
                editing={editing ? 1 : 0}
                onClick={handleEdit}
              />
            )}
          </TableCell>
        </SubCategoryExpandRow>

        {!disabled && editing && (
          <SeverityRow state={chargesState} setState={setFields} bulkEdit>
            <BulkEdit handleBulkEdit={handleBulkEdit} />
          </SeverityRow>
        )}

        {isExpanded && <SeverityPeriod charges={charges} />}
      </M.TableBody>
    </M.Table>
  );
};

export default SubCategory;
