import React, { useCallback, useEffect } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import '@dashboard-experience/mastodon/lib/styles.css';
import { useFormik } from 'formik';
import { usePostPartnerApplication } from 'api/partners/hooks';
import { useFilestackUploadResponse } from 'hooks';
import { oauthAssetsFilestackKey } from 'Constants';
import { useUploadError } from '../../api/accountSettings/hooks';
import validationSchema from './ApplicationValidationSchema';
import ApplicationModal from './ApplicationModal';

//  Billing values
export const BILL_PARTNER = 'bill-partner';
export const BILL_CUSTOMER_DIRECTLY = 'bill-customer';
//  Pre credential values
export const CREDENTIAL_CHECKR = 'credential-checkr';
export const CREDENTIAL_PARTNER = 'credential-partner';
//  Signup flow values
export const SIGNUP_FLOW_YES = 'signup-flow-yes';
export const SIGNUP_FLOW_NO = 'signup-flow-no';

const defaultHexColor = `${colors.uiNavy600}`;

type AddApplicationModalProps = {
  setCloseAddModal: () => void;
  partnerId: string;
  refetchPage: () => void;
};

const AddApplicationModal: React.FC<AddApplicationModalProps> = ({
  setCloseAddModal,
  partnerId,
  refetchPage,
}) => {
  const {
    postPartnerApplicationCall,
    postPartnerApplicationResult: { isSuccess: postPartnerSuccess },
  } = usePostPartnerApplication(partnerId);
  const { callUploadError } = useUploadError();

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      homepage: '',
      description: '',
      'partner-billing': BILL_CUSTOMER_DIRECTLY,
      'pre-credentialed-accounts': CREDENTIAL_CHECKR,
      webhook: '',
      redirect: '',
      'primary-color': defaultHexColor,
      logo_url: '',
      partner_billing: false,
      pre_credentialed_accounts: false,
      sign_up_flow: true,
    },
    validationSchema,
    onSubmit: submitForm,
  });

  const closeModalHeader = useCallback(
    (keyEvent: any) => {
      if (keyEvent.screenX !== 0 && keyEvent.screenY !== 0) {
        resetForm();
        setCloseAddModal();
      }
    },
    [setCloseAddModal, resetForm],
  );

  const clearForm = useCallback(() => {
    resetForm();
    setCloseAddModal();
  }, [setCloseAddModal, resetForm]);

  useEffect(() => {
    // Upon successfully creating an application, call for a refetch of the page
    if (postPartnerSuccess) {
      clearForm();
      refetchPage();
    }
  }, [postPartnerSuccess, refetchPage, clearForm]);

  async function submitForm(formValues: any) {
    const params = {
      name: formValues.name,
      description: formValues.description,
      url: formValues.homepage,
      webhook_url: formValues.webhook,
      primary_color: formValues['primary-color'],
      redirect_uri: formValues.redirect,
      partner_billing: formValues.partner_billing,
      pre_credentialed_accounts: formValues.pre_credentialed_accounts,
      sign_up_flow: formValues.sign_up_flow,
      logo_url: formValues.logo_url,
    };
    postPartnerApplicationCall(params);
  }

  const filestackUpload = useCallback(({ onPick }) => {
    return (
      <M.Button
        kind='primary'
        name='logo_url'
        style={{ margin: '8px' }}
        onClick={onPick}
      >
        Upload Logo
      </M.Button>
    );
  }, []);

  const onFileUpload = useFilestackUploadResponse({
    filestackKey: oauthAssetsFilestackKey,
    errorCallback: callUploadError,
    successCallback: file => {
      setFieldValue('logo_url', file.url);
    },
  });

  return (
    <ApplicationModal
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      filestackUpload={filestackUpload}
      onFileUpload={onFileUpload}
      values={values}
      errors={errors}
      touched={touched}
      clearForm={clearForm}
      closeModalHeader={closeModalHeader}
      type='create'
    />
  );
};

export default AddApplicationModal;
