import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import SubCategory from './SubCategory';
import CategoryExpandRow from './ExpandRow';
import { Row, SubCategory as SubCategoryType } from './types';
import { useExpanded } from './hooks';

const { TableCell, TableExpandedRow } = M.DataTable;

const CategoryRow = styled(CategoryExpandRow)`
  td {
    border-bottom: 1px solid ${colors.uiNavy200} !important;
  }
`;

const StyledTableExpandedRow = styled(TableExpandedRow)`
  td {
    padding: 0 !important;
  }
  .cds--table-expand {
    padding-left: 0.5rem !important;
  }
  .bulk-edit-row {
    .cds--table-expand {
      border-bottom: 1px solid ${colors.uiNavy50} !important;
    }
  }
`;

type Props = {
  row: Row;
};

const Category: React.FC<Props> = ({ row }) => {
  const subcategories = row.cells[0].value.subCategories;
  const { handleClick, isExpanded } = useExpanded(subcategories, true);

  return (
    <>
      <CategoryRow
        key={row.id}
        onClick={handleClick}
        isExpanded={isExpanded}
        main='true'
      >
        <TableCell colSpan={13}>{row.cells[0].value.name}</TableCell>
      </CategoryRow>

      {isExpanded && (
        <StyledTableExpandedRow colSpan={14}>
          {subcategories.map((subCategory: SubCategoryType) => {
            return (
              <SubCategory
                key={`${row.id}-${subCategory.name}`}
                subCategory={subCategory}
              />
            );
          })}
        </StyledTableExpandedRow>
      )}
    </>
  );
};

export default Category;
