import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BinaryChoiceCard from 'components/BinaryChoiceCard';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import { useDispatch } from '../../context';

type Props = {
  header: string | React.ReactNode;
  defaultSelected?: string;
  type: any;
};

const ChoiceGroup: React.FC<Props> = ({ header, type, defaultSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const affirmative = t(`${exam_reorder_modal_body}.choices.affirmative`);
  const negative = t(`${exam_reorder_modal_body}.choices.negative`);

  const onChange = useCallback(
    (payload: string) => dispatch({ type, payload }),
    [type, dispatch],
  );

  return (
    <BinaryChoiceCard
      header={header}
      onChange={onChange}
      firstChoice={affirmative}
      secondChoice={negative}
      defaultSelected={defaultSelected}
    />
  );
};

export default ChoiceGroup;
