import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';
import './styles.scss';

type Props = {
  name: keyof Entity.Type;
  versionClass: string;
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
  showAlternateDescription?: boolean;
};

export const SettingToggle: React.FC<Props> = ({
  name,
  versionClass,
  settings,
  setSettings,
  showAlternateDescription,
}) => {
  const { t } = useTranslation('assess');
  const title = t(`settings.${versionClass}.${name}.title`);
  const label = <span className='settings-label'>{title}</span>;

  const descriptionKey = showAlternateDescription
    ? 'alternate_description'
    : 'description';
  const description = t(`settings.${versionClass}.${name}.${descriptionKey}`);
  const setting = settings?.[name];
  const value =
    typeof setting === 'object' && 'enabled' in setting
      ? setting.enabled
      : setting ?? false;

  const handleToggle = useCallback(() => {
    setSettings((state: Entity.Type) => {
      const setting = state?.[name];
      if (typeof setting === 'object' && 'enabled' in setting) {
        return {
          ...state,
          [name]: {
            ...setting,
            enabled: !setting.enabled,
          },
        };
      }

      return {
        ...state,
        [name]: !state[name],
      };
    });
  }, [name, setSettings]);

  const dataTestId = `assess-${versionClass}-settings-${name}`;
  const descriptionId = `assess-${versionClass}-ui-home-settings-boolean-settings-setting-toggle-${name}`;

  return (
    <div className={versionClass}>
      <M.Toggle
        id={`${name}-toggle`}
        onToggle={handleToggle}
        toggled={value}
        toggledText={label}
        untoggledText={label}
        data-testid={dataTestId}
        role='input'
      />
      <div className='settings-description' id={descriptionId}>
        <Trans>{description}</Trans>
      </div>
    </div>
  );
};

export default SettingToggle;
