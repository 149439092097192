import React from 'react';
import { Trans } from 'react-i18next';
import { TRANSLATION_COMPONENTS } from './sections/translation-components';

export type ParagraphsType = {
  paragraphs: Array<String>;
  section?: string;
  subsection?: string;
};

const Content: React.FC<ParagraphsType> = ({
  paragraphs,
  section,
  subsection,
}) => {
  let components = {};

  if (section) {
    components = subsection
      ? TRANSLATION_COMPONENTS[section][subsection]
      : TRANSLATION_COMPONENTS[section];
  }

  const children = paragraphs.map(p => (
    <p key={Math.random()}>
      <Trans components={components}>{p}</Trans>
    </p>
  ));
  return <div>{children}</div>;
};

export default Content;
