import { filter } from 'lodash/fp';

/* eslint-disable import/prefer-default-export */
const findOrCreateCategory = (
  categoryName: string,
  categoryHash: { [x: string]: any },
  categories: any[],
) => {
  if (!categoryHash[categoryName]) {
    categoryHash[categoryName] = { name: categoryName, tests: [] };
    categories.push(categoryHash[categoryName]);
  }
  return categoryHash[categoryName];
};

const addTestToCategory = (
  test: { [x: string]: any },
  categoryHash: { [x: string]: any },
  categories: any[],
) =>
  findOrCreateCategory(test.category, categoryHash, categories).tests.push(
    test,
  );

const documentsForSlug = (
  documents: any[],
  slug: string,
  providerStatus: string,
) => {
  return filter((document: any) => {
    // EVER-6562 exam_waiver documents should not be associated with exams that are not waived
    if (
      document?.type?.toLowerCase() === 'exam_waiver' &&
      providerStatus?.toLowerCase() !== 'waived'
    )
      return false;
    return document?.alternate_documentable_id?.toLowerCase() === slug;
  }, documents);
};

export const populateRows = (
  screening: { [x: string]: any },
  documents: any[],
) => {
  const categoryHash: { [x: string]: any } = {};
  const categories: any[] = [];
  screening?.tests.forEach((test: any) => {
    test.documents = documentsForSlug(
      documents,
      test.slug,
      test.provider_status,
    );
    addTestToCategory(test, categoryHash, categories);
  });

  if (categories.length) return categories;

  return [];
};
