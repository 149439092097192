import React, { useCallback, useState } from 'react';
import CreditSetupButtonSelection from 'components/AddScreenings/shared/CreditSetupModalButtonSelection';
import { Account } from 'types/Packages/Account';
import { useUser } from 'context/CurrentUser';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  AccordionItem,
  CategoryTitle,
  Icon,
  ExpandableTitle,
  BoxDivider,
  BoxHeader,
  Flex,
  Box,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  BulletPoint,
  FlexContainerNoBorder,
  TitleWithTag,
  AccordionItemProps,
} from '../../shared/ListItemElements';
import creditCardChecked from '../../shared/svg/credit-card-checked.svg';
import CreditReportSetupModal from './CreditReportSetupModal';

type CreditReportItemProps = AccordionItemProps & {
  account: Account;
};

const CreditReportItem = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
  account,
}: CreditReportItemProps) => {
  const currentUser = useUser();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const shouldOpen = screeningTypesInSearch?.includes('credit_report');

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <AccordionItem
      open={screeningTypesInSearch?.includes('credit_report')}
      data-testid='credit-report-dropdown'
      title={
        <CategoryTitle data-testid='credit-report-title'>
          <Icon src={creditCardChecked} className='screening-icon' /> Credit
          Report
        </CategoryTitle>
      }
    >
      <ul>
        {isOpen && (
          <CreditReportSetupModal open={isOpen} handleClose={handleClose} />
        )}
        <FlexContainerNoBorder data-testid='credit-report-section'>
          <ExpandableTitle
            initialExpanded={shouldOpen}
            data-testid='expandable-credit-report-section'
            title={
              <TitleWithTag
                getPriceByType={getPriceByType}
                title='Credit Report'
                priceByType='credit_report'
                setupStatus={currentUser?.account?.credit_report_setup}
                priceVaries={
                  currentUser?.account?.credit_report_setup === 'disabled' ||
                  currentUser?.account?.credit_report_setup === 'pending'
                }
              />
            }
          >
            <ScreeningDescriptionContainer>
              <ScreeningInfo>
                Identifies public financial information such as tax liens,
                accounts in collection, and bankruptcies.{' '}
                <LearnMoreLink
                  style={{ textDecoration: 'underline' }}
                  linkText='Learn more'
                  data-testid='credit-learn-more-link'
                  href='https://help.checkr.com/hc/en-us/articles/360001615768'
                />
              </ScreeningInfo>
              {/* <TurnAroundTime /> */}
            </ScreeningDescriptionContainer>
            <Flex>
              <Box>
                <BoxHeader>Advantages</BoxHeader>
                <ul>
                  <BulletPoint text="Provides high-level view of individual's financial history" />
                  <BulletPoint text='No impact on credit score' />
                  <BulletPoint text='Includes amounts owed, and high/low credit and payment history' />
                </ul>
              </Box>
              <BoxDivider />
              <Box>
                <BoxHeader>Considerations</BoxHeader>
                <ul>
                  <BulletPoint text='Reserved for those who will have financial or fiduciary responsibilities' />
                  <BulletPoint text="Use must be in line with company's permissible purpose" />
                  <BulletPoint text='Some states have more specific laws or guidelines' />
                </ul>
              </Box>
            </Flex>
          </ExpandableTitle>
          <CreditSetupButtonSelection
            actionProps={actionProps}
            status={account.credit_report_setup}
            handleOpen={handleOpen}
          />
        </FlexContainerNoBorder>
      </ul>
    </AccordionItem>
  );
};

export default CreditReportItem;
