import postMessageToDashboard from 'utils/PostMessageToDashboard';

const scrollToElement = elementOffsetTop => {
  postMessageToDashboard({
    messageId: `scroll_to_element`,
    elementOffsetTop,
  });
};

export default scrollToElement;
