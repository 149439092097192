import {
  GenericObject,
  localStorageFallback,
} from '@dashboard-experience/utils';
import { DEFAULT_USER_PREFERENCES } from 'Constants';

export const USER_PREFERENCE_STORAGE_KEY = 'dashboardUserPreferences';
export const LEGACY_PREFERENCE_STORAGE_KEY = 'userPreferences';

// Helpers for managing storage

export const hasSavedPreferences = () =>
  !!localStorageFallback.getItem(USER_PREFERENCE_STORAGE_KEY);

export const parsePreferences = () =>
  JSON.parse(localStorageFallback.getItem(USER_PREFERENCE_STORAGE_KEY) || '{}');

export const clearUserPreferences = () =>
  localStorageFallback.removeItem(USER_PREFERENCE_STORAGE_KEY);

export const storePreferences = (preferences: { [key: string]: any }) => {
  localStorageFallback.setItem(
    USER_PREFERENCE_STORAGE_KEY,
    JSON.stringify(preferences),
  );
};

export const storePreference = (key: string, value: any) => {
  const preferences = parsePreferences();
  preferences[key] = value;
  localStorageFallback.setItem(
    USER_PREFERENCE_STORAGE_KEY,
    JSON.stringify(preferences),
  );
};

export const storeNestedPreference = (
  parentKey: string,
  subKey: string,
  value: any,
) => {
  const preferences = parsePreferences();
  const nestedPreference = {
    ...(preferences[parentKey] || {}),
    [subKey]: value,
  };
  storePreference(parentKey, nestedPreference);
};

export const getDashboardPreference = (preference: string) => {
  const preferences = parsePreferences();
  return preferences[preference] ?? DEFAULT_USER_PREFERENCES[preference];
};
export const getNestedDashboardPreference = (key: string, subKey: string) => {
  const basePreference = getDashboardPreference(key);
  return basePreference[subKey] ?? DEFAULT_USER_PREFERENCES[key][subKey];
};

export const hasLegacyPreferences = () =>
  !!localStorageFallback.getItem(LEGACY_PREFERENCE_STORAGE_KEY);

export const getLegacyPreferences = () =>
  localStorageFallback.getItem(LEGACY_PREFERENCE_STORAGE_KEY);

export const removeLegacyPreferences = () =>
  localStorageFallback.removeItem(LEGACY_PREFERENCE_STORAGE_KEY);

// No need to persist/migrate search columns - its an unused feature, and structured better with the new design
const filteredPreferences = ['defaultSearchColumns', 'searchColumns'];
export const filterLegacyPreferences = (preferences: GenericObject) =>
  Object.entries(preferences).reduce((curr, [key, value]) => {
    if (!filteredPreferences.includes(key)) curr[key] = value;
    return curr;
  }, {} as GenericObject);
