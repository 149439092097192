import React from 'react';
import { Rules } from 'modules/assess/ui/ruleset/version';
import Kind from 'modules/assess/models/rulesets/kind';
import { useState } from 'modules/assess/ui/ruleset/version/store';
import { useReadOnly } from 'modules/assess/ui/hooks';

import Categories from './categories';
import CountRules from './count';
import CustomRules from './custom';
import LookbackRules from './lookback';
import StandardRules from './standard';

export type Props = {};

export const RulesContainer: React.FC<Props> = () => {
  const state = useState();
  const disabled = useReadOnly() || state.kind !== Kind.draft;

  const categories = <Categories disabled={disabled} />;
  const count = <CountRules disabled={disabled} />;
  const custom = <CustomRules disabled={disabled} />;
  const lookback = <LookbackRules disabled={disabled} />;
  const standard = <StandardRules disabled={disabled} />;

  return (
    <Rules
      categories={categories}
      count={count}
      custom={custom}
      lookback={lookback}
      standard={standard}
    />
  );
};

export default RulesContainer;
