import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Type } from 'modules/assess/models/rules/premade';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import Item from './rule';
import ButtonType from './ButtonType';

export type Props = {
  handleRuleUnselected: (rule: Type) => void;
  rules: Array<Row>;
  hideTopDivider?: boolean;
  buttonType?: ButtonType;
};

const Divider = styled.div`
  border-top: 1px solid ${colors.brandSlate2};
`;

export const List: React.FC<Props> = ({
  handleRuleUnselected,
  rules,
  hideTopDivider,
  buttonType,
}) => {
  const ruleItems = rules.map((rule, index) => (
    <div key={rule.id}>
      {(index !== 0 || !hideTopDivider) && <Divider />}
      <Item
        handleRuleUnselected={handleRuleUnselected}
        row={rule}
        buttonType={buttonType}
      />
    </div>
  ));
  return (
    <div data-testid='assess-v2-ui-guideline-quickstart-table-category-list'>
      {ruleItems}
    </div>
  );
};

export default List;
