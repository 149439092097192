import React, { useEffect, useReducer } from 'react';
import {
  useWorkflowScreenStates,
  getDocument,
  Report,
} from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import { useFetchAdversibleItems } from 'modules/adjudication/api/hooks';
import CustomProvider from 'state/provider';
import { useAdverseActions } from 'modules/adjudication/api';

import { context } from './context';
import transitions from '../transitions';
import Reducer, { initialState } from './reducer';

type Props = {
  onClose: () => void;
  open: boolean;
};

const Provider: React.FC<Props> = ({ onClose, open, children }) => {
  const data = useGetReport();
  const report = data?.report as unknown as Report;
  const { adverseAction } = useAdverseActions(report.id);
  const pdfIndividualizedAssessment = getDocument(
    report,
    'pdf_individualized_assessment',
  );

  const individualizedAssessment = {
    uploadedFile: null,
    reassessment: adverseAction?.individualized_reassessment,
    assessment: adverseAction?.individualized_assessment,
    oldReassessment: adverseAction?.individualized_reassessment,
    oldAssessmentUrl: pdfIndividualizedAssessment?.download_uri,
  };

  const newState = {
    ...initialState,
    individualizedAssessment,
  };

  const {
    charges: adverseItems,
    isLoading,
    isSuccess,
  } = useFetchAdversibleItems();
  const [state, dispatch] = useReducer(Reducer, newState);
  const initialScreen = 'FairChanceForm';

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch({ payload: adverseItems || [], type: 'SET_ADVERSE_ITEMS' });
    }
  }, [isLoading, isSuccess]);

  const { currentScreen, transitionToScreen, nextScreen, previousScreen } =
    useWorkflowScreenStates(initialScreen, transitions);

  return (
    <CustomProvider
      context={context}
      props={{
        open,
        onClose,
        currentScreen,
        transitionToScreen,
        nextScreen,
        previousScreen,
        state,
        dispatch,
      }}
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
