import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Options from '../shared/options';
import { useDispatch, useModalState } from '../../../context';
import { namespace } from '../../../../../../../locales';
import { useNextDisabled } from './hooks';
import Content from '../content';
import { useInitializeAdverseItems } from '../adverse-items/hooks';

const Container = styled.div`
  overflow: auto;
  background: ${colors.uiGrey0};
`;

const preview_email = `${namespace}:report.actions.pre_adverse.modal.preview_email.action`;

const ConfigOptions: React.FC = () => {
  const { t } = useTranslation();
  const transitionToScreen = useModalState('transitionToScreen');
  const { FCI_FLOW_ENABLED, OPTIONAL_FCI_FLOW_ENABLED } =
    useInitializeAdverseItems();
  const previewEmailAction = t(preview_email);
  const disabled = useNextDisabled();
  const dispatch = useDispatch();

  useEffect(() => {
    const back = { onClick: () => transitionToScreen('adverse_items') };

    if (FCI_FLOW_ENABLED) {
      back.onClick = () => transitionToScreen('fair_chance_form');
    } else if (OPTIONAL_FCI_FLOW_ENABLED) {
      back.onClick = () => transitionToScreen('fair_chance');
    }

    const next = {
      onClick: () => transitionToScreen('preview_email'),
      name: previewEmailAction,
      disabled,
    };
    const skip = null;

    dispatch({ type: 'SET_NAVIGATION', payload: { back, next, skip } });
  }, [
    t,
    dispatch,
    disabled,
    previewEmailAction,
    FCI_FLOW_ENABLED,
    OPTIONAL_FCI_FLOW_ENABLED,
  ]);

  return (
    <Container>
      <Content>
        <Options />
      </Content>
    </Container>
  );
};

export default ConfigOptions;
