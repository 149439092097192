import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

import { Invite } from 'components/Packages/OrderBackgroundCheck/SharedItems';

const TableContainerStyled = styled(M.TableContainer)`
  max-height: 288px; /* 288px is height of header and 5 rows */
  overflow-y: scroll;

  /* Always show scrollbar */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const TableRowStyled = styled(M.TableRow)`
  display: flex;
`;

interface TableHeaderProps {
  flex?: number;
  width?: string;
}

const TableHeaderStyled = styled(M.TableHeader)<TableHeaderProps>`
  align-items: center;
  display: flex;
  flex: ${props => props.flex || 'none'} !important;
  font-size: 12px !important;
  padding: 12px 24px 12px 0 !important;
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
`;

interface TableCellProps {
  flex?: number;
  width?: string;
}

const TableCellStyled = styled(M.TableCell)<TableCellProps>`
  align-items: center;
  display: flex;
  flex: ${props => props.flex || 'none'} !important;
  font-size: 12px !important;
  line-height: 100% !important;
  padding: 12px 24px 12px 0 !important;
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
  word-break: break-all;
`;

export type SmsInviteSectionContentProps = {
  invites: Invite[];
  candidatesError?: { state: boolean; msg: string };
};

const SmsInviteSectionContent: React.FC<SmsInviteSectionContentProps> = ({
  invites,
}) => {
  return (
    <TableContainerStyled>
      <M.Table>
        <M.TableHead>
          <TableRowStyled>
            <TableHeaderStyled flex={1}>Email</TableHeaderStyled>
            <TableHeaderStyled width={130}>First name</TableHeaderStyled>
            <TableHeaderStyled width={130}>Phone number</TableHeaderStyled>
          </TableRowStyled>
        </M.TableHead>
        {invites.map(invite => (
          <TableRowStyled key={invite.id}>
            <TableCellStyled flex={1} data-dd-privacy='mask'>
              {invite.email.value}
            </TableCellStyled>
            <TableCellStyled width={130}>
              {invite.firstName.value || 'n/a'}
            </TableCellStyled>
            <TableCellStyled width={130} data-dd-privacy='mask'>
              {invite.phoneNumber.value || 'n/a'}
            </TableCellStyled>
          </TableRowStyled>
        ))}
      </M.Table>
    </TableContainerStyled>
  );
};

export default SmsInviteSectionContent;
