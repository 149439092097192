import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import * as Assignable from 'modules/assess/models/assignables';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import Content from './Content';

type Props = {
  draft: boolean;
  ruleset?: Ruleset.Type;
  open: boolean;
  onClose?: (event: SyntheticEvent) => void;
  onDelete: () => void;
};

export const Container: React.FC<Props> = ({
  draft,
  ruleset,
  open,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation('assess');
  const resource = draft ? 'ruleset.version' : 'ruleset';
  const modalTitle = t(`assess:${resource}.delete.title`, {
    id: ruleset?.id,
    name: ruleset?.name,
    interpolation: { escapeValue: false },
  });
  if (!open) {
    return null;
  }

  const assignment = Object.values(ruleset?.assignments || [])?.find(
    a => a.type === Assignable.Kind.ACCOUNT,
  );
  const disabled = !!assignment && !draft;

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-delete-dialog'
      open={open}
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-delete-title'
        closeModal={onClose}
        title={modalTitle}
      />
      <M.ModalBody data-testid='assess-ruleset-delete-content'>
        <Content isDraft={draft} disabled={disabled} ruleset={ruleset} />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-delete-actions'>
        <Actions disabled={disabled} onCancel={onClose} onDelete={onDelete} />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Container;
