import { OAUTH_URL } from 'Constants';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const newOauthRedirect = (clientId: string, path: string) => {
  const search = `${
    window.location.search + (window.location.search.length > 0 ? '&' : '?')
  }ref=dashboard`;

  return `${OAUTH_URL}/authorize/${clientId}${path || ''}${search}`;
};

const RedirectToSignup = () => {
  const match = useRouteMatch<{ clientId: string }>();
  const {
    params: { clientId },
  } = match;
  const redirect = newOauthRedirect(clientId, '/signup');
  window.location.replace(redirect);
  return null;
};
const RedirectToSignin = () => {
  const match = useRouteMatch<{ clientId: string }>();
  const {
    params: { clientId },
  } = match;
  const redirect = newOauthRedirect(clientId, '/signin');
  window.location.replace(redirect);
  return null;
};

export const OauthRoutes = () => (
  <Switch>
    <Route
      path='/oauth/authorize/:clientId/signup'
      component={RedirectToSignup}
    />
    <Route
      path='/oauth/authorize/:clientId/signin'
      component={RedirectToSignin}
    />
    <Route path='/oauth/authorize/:clientId' component={RedirectToSignup} />
  </Switch>
);
