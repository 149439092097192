import React from 'react';
import styled from 'styled-components';

type Props = {
  position: number;
};

const Label = styled.label`
  display: flex;
  font-size: 24px;
  font-weight: bold;
`;

const Position: React.FC<Props> = ({ position }) => {
  return <Label>{position}</Label>;
};

export default Position;
