import React from 'react';
import styled from 'styled-components';
import { arrayUtils as arrayToChunks } from '@dashboard-experience/utils';
import { Item } from 'modules/assess/utilities/hierarchy';
import List from './List';
import Header from './Header';
import Upgrade from './Upgrade';

type Props = {
  charges?: Item[];
  subcategory?: string;
  showUpgrade?: boolean;
};

const Container = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

const Content = styled.div`
  align-items: flex-start;
  column-gap: 5.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1.5rem;
`;

const LIST_SIZE = 5;

const Subcategory: React.FC<Props> = ({
  charges,
  subcategory,
  showUpgrade,
}) => {
  const chunks = arrayToChunks(charges || [], LIST_SIZE);
  const chargesLists = chunks.map((chunk, i) => {
    const key = `charges-list-${i}`;
    return <List key={key} charges={chunk as Item[]} />;
  });

  return (
    <Container data-testid='assess-v2-ui-common-lookback-rules-subcategory-charges-container-container'>
      <Header subcategory={subcategory} />
      <Content data-testid='assess-v2-ui-common-lookback-rules-subcategory-charges-container-content'>
        {chargesLists}
        {showUpgrade && <Upgrade />}
      </Content>
    </Container>
  );
};

export default Subcategory;
