import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { Page } from 'modules/assess/ui';
import React from 'react';
import ItemAssignments from './item-assignments';
import ItemEventTimes from './item-event-times';

export type Props = {
  item?: Entity;
};

export const ItemExpansion: React.FC<Props> = ({ item }) => {
  if (!item) {
    return null;
  }

  return (
    <Page.Content
      data-testid='assess-mvr-category-sets-search-table-item-expansion'
      padding='0'
    >
      <ItemAssignments item={item} />
      <hr />
      <ItemEventTimes item={item} />
    </Page.Content>
  );
};

export default ItemExpansion;
