import { useGetReportVerificationExceptions } from 'api/verifications';
import { useGetReportExceptions } from 'api/reports';

const useReportMergedExceptions = (reportId: string) => {
  const reportMonolithExceptions = useGetReportExceptions(reportId);

  /* eslint-disable-next-line */
  const reportVerificationExceptions =
    useGetReportVerificationExceptions(reportId);

  // for now keep behavior the same, use monolith exceptions

  return reportMonolithExceptions;
};

export default useReportMergedExceptions;
