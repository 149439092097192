import React from 'react';
import { StatusTypes, getScreeningStatus } from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import DrugHealthScreening from './DrugHealthScreening';
import { Screenings } from './types';
import Table from './Table';

const headers = [
  { key: 'substance_type', header: 'Substance' },
  { key: 'result', header: 'Result' },
];

const V1DrugScreening: React.FC<ReportProps> = ({ report }) => {
  const { drug_screening: screening } = report;

  if (!screening) return null;
  const statusOrAssessment = getScreeningStatus(screening, StatusTypes.Legacy);

  return (
    <DrugHealthScreening
      screening={screening}
      title='Clinic Based Drug Screening'
      disposition='Test Results'
      reportStatusType={StatusTypes.Legacy}
      timelineStatuses={screening.drug_statuses}
      timelineKey='status'
      screeningTestId={SCREENING_TITLES.drug_screening.id}
    >
      <Table
        rows={screening.illicit_drug_records}
        headers={headers}
        type={Screenings.I3Report}
        assessEnabled={false}
        statusOrAssessment={statusOrAssessment}
        canceledStatusEnabled={false}
      />
    </DrugHealthScreening>
  );
};

export default V1DrugScreening;
