import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Prompt, PageLoading as Loading } from 'components';
import { Kind } from 'components/Button';
import { Namespace } from 'modules/assess/api';
import { useDraftUpdate } from 'modules/assess/ui/ruleset/version/api';
import { State, useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions as VersionActions } from 'modules/assess/ui/state/rulesets/versions/instance';
import { ID } from 'modules/id';

type Props = {
  kind?: Kind;
  namespace?: Namespace;
  ruleset?: ID;
  trans: string;
  trackNewRulesCallback: () => void;
};

export const SaveButton: React.FC<Props> = ({
  kind,
  namespace,
  ruleset,
  trans,
  trackNewRulesCallback,
}) => {
  const state = State.useValue();
  const disabled = !state.dirty;
  const entity = State.useEntity(state);

  const action = useDraftUpdate(ruleset);

  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const history = useHistory();

  const returnUrl = `/assess/${namespace}/rulesets/${ruleset}/versions/draft?saved=${isSaved}`;
  const loading = action.result.status === 'loading';

  useEffect(() => {
    if (action.result.status === 'success') {
      trackNewRulesCallback();
      dispatch(VersionActions.Update.create({ dirty: false }));
      action.result.reset();
      setIsSaved(true);
    }
  }, [action, trackNewRulesCallback, dispatch, history, returnUrl]);

  useEffect(() => {
    if (isSaved) {
      history.push(returnUrl);
    }
  }, [history, isSaved, returnUrl]);

  const handleClick = useCallback(() => {
    setIsSaving(true);
    action.call(entity);
  }, [action, entity]);

  return (
    <>
      <Prompt
        when={state.dirty && !isSaving}
        translation='dialogs.unsaved_changes.message'
      />
      <Loading enabled={loading} />
      <Button
        id='assess-ruleset-version-guidelines-common-save-button'
        data-testid='assess-common-save-button'
        disabled={disabled}
        kind={kind}
        onClick={handleClick}
        size='sm'
        trans={trans}
      />
    </>
  );
};

export default SaveButton;
