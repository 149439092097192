import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/package';

type Props = {
  selections: Entity.List;
};

const StyledUl = styled.ul`
  color: ${colors.uiGrey700};
  font-size: 14px;
  line-height: 16.7px;
  list-style-type: disc;
  margin-left: 5.25rem;
  margin-bottom: 0.3rem;
  li {
    margin-left: 0.9rem;
    margin-bottom: 0.3rem;
  }
  li:last-child {
    margin-bottom: 0;
  }
  span {
    font-style: italic;
  }
`;

const SelectedPackages: React.FC<Props> = ({ selections }) => {
  const { t } = useTranslation('assess');
  const noneSelectedText = t('settings.no_packages_selected');

  const selectedList = selections.map(item => {
    return <li key={item.id}> {item.name}</li>;
  });

  const selectedPackages = isEmpty(selectedList) ? (
    <span>{noneSelectedText}</span>
  ) : (
    selectedList
  );

  return (
    <StyledUl data-testid='assess-v2-ui-home-settings-boolean-settings-auto-engage'>
      {selectedPackages}
    </StyledUl>
  );
};

export default SelectedPackages;
