import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';
import useGetUser from './useGetUser';

const getPackages = async (id: string) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(
    `${CHECKR_API_BASE_URL}/accounts/${id}/packages`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

function useGetPackages() {
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useGetUser();
  const request = () => getPackages(user.account.id);

  const result = useQuery(['signup-packages'], request, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    enabled: !isUserLoading && !userError && user !== undefined,
  });

  const { isLoading, error, data } = result;

  return {
    data,
    isLoading,
    error,
  };
}

export default useGetPackages;
