import { ItemType } from 'modules/assess/models/mvr/codes';
import SectionTitle from 'modules/assess/ui/common/section-title';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  section: 'categories' | 'mappings';
  type: ItemType;
};

const Title: React.FC<Props> = ({ section, type }) => {
  const { t } = useTranslation('assess');
  const title = t(
    `assess:mvr.category-sets.instance.versions.instance.common.${section}.title`,
    { section, type },
  );

  return (
    <SectionTitle
      data-testid={`assess-mvr-category-set-version-mappings-${section}-title`}
    >
      {title}
    </SectionTitle>
  );
};

export default Title;
