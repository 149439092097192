/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Dropdown } from '../../OrderBackgroundCheck/SharedItems';
import {
  City,
  MAJOR_CITIES,
} from '../../OrderBackgroundCheck/GetStarted/MajorCities';

const CityDropdown = styled(Dropdown)``;

// convert city object to string
const cityItemToString = (city: City) =>
  city ? `${city.name}, ${city.state}` : '';

const SelectCity: React.FC<any> = ({
  cities = MAJOR_CITIES,
  state,
  setSelectedCity,
  selectedCity,
  isStateSet,
}) => {
  const selectableCities = useMemo(
    () => cities.filter((c: City) => c.state === state),
    [state],
  );
  // handle city change
  const handleCityChange = (selectedItem: any) => {
    setSelectedCity(selectedItem.selectedItem);
  };

  return (
    <>
      {isStateSet && selectableCities.length > 0 && (
        <CityDropdown
          data-testid='city-dropdown-testid'
          label='Cities (Optional)'
          items={selectableCities}
          itemToString={cityItemToString}
          onChange={handleCityChange}
          selectedItem={selectedCity.name !== '' ? selectedCity : null}
        />
      )}
    </>
  );
};

export default SelectCity;
