import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const BILL_PARTNER = 'bill-partner';
export const BILL_CUSTOMER_DIRECTLY = 'bill-customer';
//  Pre credential values
export const CREDENTIAL_CHECKR = 'credential-checkr';
export const CREDENTIAL_PARTNER = 'credential-partner';
//  Signup flow values
export const SIGNUP_FLOW_YES = 'signup-flow-yes';
export const SIGNUP_FLOW_NO = 'signup-flow-no';

const defaultHexColor = `${colors.uiNavy600}`;

const StyledLabel = styled.label`
  margin-top: 3rem !important;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const TooltipIcon = styled(M.Icon)`
  fill: ${colors.brandNavy2} !important;
  margin: 2px 0 0 7px !important;
`;

const StyledLabelHelperText = styled.div`
  color: ${colors.uiGrey700};
  font-size: 0.75rem;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  .tooltip-wrapper-aligned {
    display: inline !important;
  }
`;

const StyledLabelHelperTextCheckbox = styled.div`
  color: ${colors.uiGrey700};
  font-size: 0.55rem;
  margin-bottom: 10px !important;
  .tooltip-wrapper-aligned {
    display: inline !important;
  }
`;

const StyledHeaderLabel = styled.div`
  margin-top: 3rem !important;
  font-weight: bold;
  font-size: 16px;
`;

const StyledHeaderLabelHelperText = styled.div`
  font-size: 0.85rem;
  margin-top: 6px;
`;

const ColorDisplay = styled.div`
  display: flex;
  width: 160px;
`;

const StyledColorDiv = styled.div`
  background: ${props => props.color};
  height: calc(1.5em + 0.75rem + 2px);
  width: 75px;
  border-radius: 10%;
  border: 1px solid ${colors.uiNavy200};
`;

const StyledLogoLabel = styled.label`
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
`;

const StyledHomepageTextInput = styled(M.TextInput)`
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
`;

const StyledHr = styled.hr`
  margin-top: 3rem;
`;

const StyledDiv = styled.div`
  margin-bottom: 2rem;
`;

export {
  StyledLabel,
  TooltipIcon,
  StyledLabelHelperText,
  StyledLabelHelperTextCheckbox,
  StyledHeaderLabel,
  StyledHeaderLabelHelperText,
  ColorDisplay,
  StyledColorDiv,
  StyledLogoLabel,
  StyledHomepageTextInput,
  StyledHr,
  StyledDiv,
  defaultHexColor,
};
