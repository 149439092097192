import React from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { Container } from '../styles';
import BasicPackageCard from './BasicPackageCard';
import EssentialPackageCard from './EssentialPackageCard';
import ProfessionalPackageCard from './ProfessionalPackageCard';

const DomesticPackages = ({
  selectedPackage,
  setSelectedPackage,
}: PackageCardProps) => {
  const cardProps = {
    selectedPackage,
    setSelectedPackage,
  };
  return (
    <Container data-testid='domestic-packages-container'>
      <BasicPackageCard {...cardProps} />
      <EssentialPackageCard {...cardProps} />
      <ProfessionalPackageCard {...cardProps} />
    </Container>
  );
};

export default DomesticPackages;
