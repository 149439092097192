import React from 'react';
import {
  StatusTypes,
  getStatusTypeForUser,
  isInternal,
  hasPermission,
  accountHasPermission,
  CurrentUser,
  Geo,
} from '@dashboard-experience/utils';

import { GenericObject } from 'types';
import {
  Adjudication,
  Assessment,
  CompletedAt,
  ComplianceGeos,
  CostCenter,
  CreatedAt,
  // DefaultCell, // Default for testing a new column - stringifies the value
  EstimatedCompletion,
  FullName,
  AccountName,
  Geos,
  Package,
  PartialSSN,
  Status,
  // Tags,
} from './Cells';
import { CellProps } from './Cells/types';
import DownloadPdf from './DownloadPdf';

type PermissionProps = {
  currentUser: CurrentUser;
  geos: any[];
};

type PermissionFunction = ({ currentUser, geos }: PermissionProps) => boolean;

const always = () => true;

const accountPermission: (key: string) => PermissionFunction =
  key =>
  ({ currentUser }) =>
    accountHasPermission(currentUser, key);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userPermission: (key: string) => PermissionFunction =
  key =>
  ({ currentUser }) =>
    hasPermission(currentUser, key);

const isDSP: PermissionFunction = ({ currentUser }: GenericObject) =>
  getStatusTypeForUser(currentUser) === StatusTypes.DSP;

export type CellConfig = {
  key: string; // The key the value belongs to
  header: string; // The text displayed in the header
  component: React.FC<CellProps>; // The Component to render in the column's cells.
  hasPermission: PermissionFunction; // Callback function to determine whether or not the column should be shown
  sortable: boolean; // Whether or not the column is sortable
  headerStyle?: GenericObject; // Styles applied to the header of the column
  cellStyle?: GenericObject; // Styles applied to the td cell wrapping the Component in the column
};

// To add a new column:
// - Add it to the list of SEARCH_COLUMNS
// - Specify a CELL_CONFIG for it
// - Add it to DEFAULT_CONFIGS for any appropriate user types (DSP, Legacy, Assess)

export const SEARCH_COLUMNS = {
  ADJUDICATION: 'adjudication',
  ASSESSMENT: 'assessment.display.decision',
  COMPLETED_AT: 'completed_at',
  COST_CENTER: 'cost_center',
  COMPLIANCE_GEO: 'compliance_geo',
  CREATED_AT: 'created_at',
  FULL_NAME: 'full_name',
  ACCOUNT_NAME: 'account_name',
  GEOS: 'geos',
  PARTIAL_SSN: 'partial_ssn',
  PDF_URL: 'pdf_url',
  STATUS: 'status',
  PACKAGE: 'package',
  ESTIMATED_COMPLETION: 'reports.estimated_completion_time',
  // TAGS: 'tags',
};

export const CELL_CONFIG: {
  [key: string]: CellConfig;
} = {
  [SEARCH_COLUMNS.FULL_NAME]: {
    key: SEARCH_COLUMNS.FULL_NAME,
    header: 'Name',
    component: FullName,
    hasPermission: always,
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '24%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.ACCOUNT_NAME]: {
    key: SEARCH_COLUMNS.ACCOUNT_NAME,
    header: 'Account',
    component: AccountName,
    hasPermission: userPermission('candidate_dashboard_show_account'),
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '18%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.STATUS]: {
    key: SEARCH_COLUMNS.STATUS,
    header: 'Status',
    component: Status,
    hasPermission: always,
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '8%',
    },
    cellStyle: {
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.ADJUDICATION]: {
    key: SEARCH_COLUMNS.ADJUDICATION,
    header: 'Adjudication',
    component: Adjudication,
    hasPermission: always, // userPermission('perform_adjudication'),
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '8%',
    },
    cellStyle: {
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.ASSESSMENT]: {
    key: SEARCH_COLUMNS.ASSESSMENT,
    header: 'Assessment',
    component: Assessment,
    hasPermission: ({ currentUser }) => {
      if (!hasPermission(currentUser, 'read_assessments')) return false;
      const statusType = getStatusTypeForUser(currentUser);
      return isInternal(currentUser) || StatusTypes.Assess === statusType;
    },
    sortable: false,
  },
  [SEARCH_COLUMNS.COST_CENTER]: {
    key: SEARCH_COLUMNS.COST_CENTER,
    header: 'Cost Center',
    component: CostCenter,
    hasPermission: accountPermission('display_cost_center'),
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '10%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.GEOS]: {
    key: SEARCH_COLUMNS.GEOS,
    header: 'Geos',
    component: Geos,
    hasPermission: ({ currentUser, geos }) =>
      !accountHasPermission(currentUser, 'segmentation_enabled') &&
      geos.length > 0,
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '15%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.COMPLIANCE_GEO]: {
    key: SEARCH_COLUMNS.COMPLIANCE_GEO,
    header: 'Compliance Geo',
    component: ComplianceGeos,
    hasPermission: accountPermission('segmentation_enabled'),
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '15%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.CREATED_AT]: {
    key: SEARCH_COLUMNS.CREATED_AT,
    header: 'Created',
    component: CreatedAt,
    hasPermission: always,
    sortable: true,
    headerStyle: {
      padding: '0rem 1rem 0rem 1.5rem',
      width: '15%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '1.5rem',
      whiteSpace: 'nowrap',
    },
  },
  [SEARCH_COLUMNS.COMPLETED_AT]: {
    key: SEARCH_COLUMNS.COMPLETED_AT,
    header: 'Completed',
    component: CompletedAt,
    hasPermission: always,
    sortable: true,
    headerStyle: {
      padding: '0rem 1rem 0rem 1.5rem',
      width: '15%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '1.5rem',
      whiteSpace: 'nowrap',
    },
  },
  // [SEARCH_COLUMNS.TAGS]: {
  //   key: SEARCH_COLUMNS.TAGS,
  //   header: 'Tags',
  //   component: Tags,
  //   hasPermission: always,
  //   sortable: false,
  //   headerStyle: {
  //     padding: '0rem 1rem 0rem 0rem',
  //     width: '15%',
  //   },
  //   cellStyle: {
  //     fontSize: '12px',
  //     paddingLeft: '0rem',
  //   },
  // },
  [SEARCH_COLUMNS.PARTIAL_SSN]: {
    key: SEARCH_COLUMNS.PARTIAL_SSN,
    header: 'Social Security #',
    component: PartialSSN,
    hasPermission: isDSP,
    sortable: false,
    headerStyle: {
      whiteSpace: 'nowrap',
    },
  },
  [SEARCH_COLUMNS.PDF_URL]: {
    key: SEARCH_COLUMNS.PDF_URL,
    header: 'Download PDF',
    component: DownloadPdf,
    hasPermission: isDSP,
    sortable: false,
    headerStyle: {
      whiteSpace: 'nowrap',
    },
  },
  [SEARCH_COLUMNS.PACKAGE]: {
    key: SEARCH_COLUMNS.PACKAGE,
    header: 'Package',
    component: Package,
    hasPermission: always,
    sortable: false,
    headerStyle: {
      padding: '0rem 1rem 0rem 0rem',
      width: '15%',
    },
    cellStyle: {
      fontSize: '12px',
      paddingLeft: '0rem',
    },
  },
  [SEARCH_COLUMNS.ESTIMATED_COMPLETION]: {
    key: SEARCH_COLUMNS.ESTIMATED_COMPLETION,
    header: 'Estimated Completion',
    hasPermission: isDSP,
    sortable: true,
    component: EstimatedCompletion,
  },
};

// Configs map a status type to the columns available for that type of user
type Configs = {
  [statusType: string]: string[];
};

// The default column layout for each user type pre-customization
export const DEFAULT_CONFIGS = {
  // Fallback for new statusTypes if new ones are added, until configs are specified for it, so the app doesn't break
  DEFAULT: [
    SEARCH_COLUMNS.STATUS,
    SEARCH_COLUMNS.FULL_NAME,
    SEARCH_COLUMNS.CREATED_AT,
  ],
  // DSP (Limited Partner) users
  [StatusTypes.DSP]: [
    SEARCH_COLUMNS.CREATED_AT,
    SEARCH_COLUMNS.FULL_NAME,
    SEARCH_COLUMNS.STATUS,
    SEARCH_COLUMNS.PARTIAL_SSN,
    SEARCH_COLUMNS.ESTIMATED_COMPLETION,
    SEARCH_COLUMNS.PDF_URL,
  ],
  // Non-Assess Legacy users
  [StatusTypes.Legacy]: [
    SEARCH_COLUMNS.STATUS,
    SEARCH_COLUMNS.ADJUDICATION,
    SEARCH_COLUMNS.FULL_NAME,
    SEARCH_COLUMNS.ACCOUNT_NAME,
    SEARCH_COLUMNS.GEOS,
    SEARCH_COLUMNS.COMPLIANCE_GEO,
    SEARCH_COLUMNS.COST_CENTER,
    SEARCH_COLUMNS.CREATED_AT,
    SEARCH_COLUMNS.COMPLETED_AT,
  ],
  // Assess users
  [StatusTypes.Assess]: [
    SEARCH_COLUMNS.STATUS,
    SEARCH_COLUMNS.ASSESSMENT,
    SEARCH_COLUMNS.ADJUDICATION,
    SEARCH_COLUMNS.FULL_NAME,
    SEARCH_COLUMNS.ACCOUNT_NAME,
    SEARCH_COLUMNS.GEOS,
    SEARCH_COLUMNS.COMPLIANCE_GEO,
    SEARCH_COLUMNS.COST_CENTER,
    SEARCH_COLUMNS.CREATED_AT,
    SEARCH_COLUMNS.COMPLETED_AT,
  ],
} as Configs;

export const getSearchConfig = (
  currentUser: CurrentUser,
  customizableColumnsEnabled: boolean,
  save: string[],
) => {
  const statusType: string = getStatusTypeForUser(currentUser);

  const defaultConfigForUser =
    DEFAULT_CONFIGS[statusType] || DEFAULT_CONFIGS.DEFAULT;

  // Only use the save if customization is enabled and defaults have not changed
  const savedConfig = customizableColumnsEnabled ? save : undefined;

  const config = savedConfig || defaultConfigForUser;

  // Filter out columns the user may not have access to anymore if permissions changed
  return filterConfig(config, currentUser);
};

const filterConfig = (config: string[], currentUser: CurrentUser) =>
  config.filter(key =>
    CELL_CONFIG[key]?.hasPermission({
      currentUser,
      geos: currentUser.geos as Geo[],
    }),
  );

// Temporary overrides to the overall cell config for Hackathon
// These new values should be shifted up to the standard config eventually
export const getCellConfigType = () => {
  const config = { ...CELL_CONFIG };

  config[SEARCH_COLUMNS.FULL_NAME].headerStyle = {
    ...config[SEARCH_COLUMNS.FULL_NAME].headerStyle,
    width: '18%',
  };

  config[SEARCH_COLUMNS.ADJUDICATION].headerStyle = {
    ...config[SEARCH_COLUMNS.ADJUDICATION].headerStyle,
    padding: '0rem 2rem 0rem 1rem',
    width: '13%',
  };

  config[SEARCH_COLUMNS.ASSESSMENT].headerStyle = {
    ...config[SEARCH_COLUMNS.ASSESSMENT].headerStyle,
    padding: '0rem 2rem 0rem 1rem',
    width: '8%',
  };

  config[SEARCH_COLUMNS.GEOS].header = 'Location';
  config[SEARCH_COLUMNS.GEOS].headerStyle = {
    ...config[SEARCH_COLUMNS.GEOS].headerStyle,
    width: '10%',
  };

  config[SEARCH_COLUMNS.COMPLIANCE_GEO].header = 'Compliance Location';

  config[SEARCH_COLUMNS.CREATED_AT].headerStyle = {
    ...config[SEARCH_COLUMNS.CREATED_AT].headerStyle,

    padding: '0rem 1rem 0rem 1rem',
    width: '9%',
  };
  config[SEARCH_COLUMNS.CREATED_AT].cellStyle = {
    ...config[SEARCH_COLUMNS.CREATED_AT].cellStyle,
    paddingLeft: '1rem',
  };

  config[SEARCH_COLUMNS.COMPLETED_AT].headerStyle = {
    ...config[SEARCH_COLUMNS.COMPLETED_AT].headerStyle,
    width: '9%',
    padding: '0rem 1rem 0rem 1rem',
  };

  config[SEARCH_COLUMNS.COMPLETED_AT].cellStyle = {
    ...config[SEARCH_COLUMNS.COMPLETED_AT].cellStyle,
    paddingLeft: '1rem',
  };

  return config;
};
