import React from 'react';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import BaseFilter from './BaseFilter';
import { AnalyticsProps } from '../helpers';

const NodeName = () => {
  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.NODE_NAME,
    trackEvent,
    debouncedEvent: true,
  };

  return (
    <BaseFilter
      component='NodeNameFilter'
      param='segment_name'
      trackAnalyticsObject={trackAnalyticsObject}
    />
  );
};

export default NodeName;
