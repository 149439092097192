import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { StatusTypes, ReportStatuses } from '@dashboard-experience/utils';
import { isoCountry } from 'utils';
import styled from 'styled-components';
import { Document as CandidateDocument, EducationRecord } from 'types';
import RecordDocuments from './RecordDocuments';
import HistoryTimeline from '../HistoryTimeline';
import CandidateInput from './CandidateInput';
import VerifiedDegree from './VerifiedDegree';

const buildName = (record: EducationRecord) => {
  const { school, result } = record;
  const { address } = school;
  const name = [result.name || school.name];
  if (address?.city) {
    name.push(address.city);
    name.push(address.state);
    if (address.country && address.country !== 'US') {
      name.push(isoCountry(address.country));
    }
  }
  return name.join(', ');
};

const CurrentStudent = styled.div`
  color: ${colors.uiGrey700};
  line-height: 20px;
  margin-bottom: 8px;
`;

type RecordProps = {
  completed: boolean;
  documents?: CandidateDocument[];
  record: EducationRecord;
  statusType: string;
  status: string;
};

const Record: React.FC<RecordProps> = ({
  completed,
  documents,
  record,
  status,
  statusType,
}) => {
  return (
    <>
      <M.Screening.Heading
        headingLeft={{
          name: buildName(record),
          strong: true,
        }}
        headingRight={
          <M.StatusBadge
            status={statusType === StatusTypes.Assess ? status : record.status}
            statusType={statusType}
          />
        }
      />
      {record.school.current && (
        <CurrentStudent>Current student</CurrentStudent>
      )}
      <CandidateInput school={record.school} />
      {record.result.degrees.map((degree, i) => (
        <VerifiedDegree
          key={`degree-${i + 1}`}
          degree={degree}
          number={i + 1}
          pending={record.status === ReportStatuses.PENDING}
        />
      ))}
      <HistoryTimeline events={record.events} />
      {completed && <RecordDocuments documents={documents} record={record} />}
    </>
  );
};

export default Record;
