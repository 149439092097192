import React from 'react';
import { useTranslation } from 'react-i18next';
import { PremadeRuleChange } from 'modules/assess/models/rulesets/version-diff';
import { ChangeType } from 'modules/assess/models/rulesets/version-diff/common';
import ToogledChange from './ToogledChange';
import FieldChange from '../common/Field';

type Props = {
  change: PremadeRuleChange.Type;
};

const toogledChanges = [ChangeType.created, ChangeType.deleted];

export const Change: React.FC<Props> = ({ change }) => {
  const { t } = useTranslation();
  const itemVisibility = t(
    'assess:ruleset.version.publish.changes.premade.changes.item_visibility',
  );

  const isToogledChange = toogledChanges.includes(change.change_type);
  const toogledChange = isToogledChange ? (
    <div key='toogledChange' data-testid={`change-${change.code.value}`}>
      <ToogledChange type={change.change_type} />
    </div>
  ) : null;

  const changes = [toogledChange];

  if (change.item_visibility && change.change_type !== ChangeType.deleted) {
    const itemVisibilityChange = (
      <div
        key='itemVisibilityChange'
        data-testid={`visibility-${change.code.value}`}
      >
        <FieldChange field={change.item_visibility} name={itemVisibility} />
      </div>
    );
    changes.push(itemVisibilityChange);
  }

  return <>{changes}</>;
};

export default Change;
