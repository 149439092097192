import { AccountsClient as client } from 'api';
import { PATH } from './constants';

export type Params = {
  account: {
    id: string;
    hierarchy_present: boolean;
    segmentation_enabled?: boolean;
  };
  params?: { [key: string]: any };
};

export type FetchNodesParams = {
  nodeName: string;
} & Params;

export type FetchAllNodesParams = {
  account: {
    id: string;
    hierarchy_present: boolean;
    segmentation_enabled: boolean;
  };
  params?: { [key: string]: any };
};

export const fetchNode = ({
  account: { id: accountId },
}: Params): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/${PATH}?page=1&per_page=1`);
};

export const fetchNodes = ({
  account: { id: accountId },
  nodeName,
  params,
}: FetchNodesParams): Promise<{ [key: string]: any }> => {
  const encodedName = encodeURIComponent(nodeName);

  if (params != null) {
    for (const [key, value] of Object.entries(params)) {
      params[key] = encodeURIComponent(value);
    }
  }

  return client.get(
    `${accountId}/${PATH}?node_name=${encodedName}&page=1&per_page=10`,
    {
      params,
    },
  );
};

export const fetchAllNodes = ({
  account: { id: accountId },
  params,
}: FetchAllNodesParams): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/${PATH}?page=1&per_page=300`, {
    params,
  });
};
