import React, { useCallback, ChangeEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import * as Types from '../types';

type Props = {
  onChange?: (key: Types.FilterKey) => void;
};

export const KeySelector: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('assess');
  const translations: any = t('assess:ruleset.assign.filters.key', {
    returnObjects: true,
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value ?? undefined);
    },
    [onChange],
  );

  return (
    <M.Search
      data-testid='assess-ruleset-assign-filters-key'
      id='assess-ruleset-assign-filters-key'
      labelText={translations.label}
      placeholder={translations.placeholder}
      onChange={handleChange}
      size='sm'
      value={undefined}
    />
  );
};

export default KeySelector;
