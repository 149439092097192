import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Report } from 'types';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { useAdverseActions } from 'modules/adjudication/api';
import processEvents from './processEvents';
import { namespace } from '../../../locales';
import { useAdjudicationEvents, useReportActionEvents } from './api';
import Event from './event';

const Timeline = styled.div`
  position: relative;
  margin: 20px 0 20px -3px;
  width: 100%;
  min-width: inherit;
  max-width: inherit;

  .events-timeline-line {
    margin: 5px 0px 0px 4px;
    position: absolute;
    width: 1px;
    display: block;
    background: ${colors.uiGrey200};
    top: 0px;
    bottom: 0px;
  }

  .events-timeline-show-more {
    margin: 0px 0px 0px 22px;
  }
`;

const Events = (
  { report }: { report: Report },
  { candidateId }: { candidateId: string },
) => {
  const { id: reportId } = report;
  const { data: adjudicationEvents } = useAdjudicationEvents(reportId);
  const { adverseActions } = useAdverseActions(reportId);
  const { data: reportActionEvents } = useReportActionEvents(reportId);
  const [collapsedTimeline, setCollapsedTimeline] = useState(true);
  const { t } = useTranslation(namespace);

  const toggleCollapsedTimeline = useCallback(
    () => setCollapsedTimeline(!collapsedTimeline),
    [collapsedTimeline, setCollapsedTimeline],
  );

  const processedEvents = processEvents(
    t,
    report,
    adverseActions,
    reportActionEvents || {},
    adjudicationEvents || [],
  );

  const slicedEvents = processedEvents.slice(
    0,
    collapsedTimeline ? 3 : undefined,
  );

  return (
    <Timeline data-testid='adverse-action-timeline'>
      <div className='events-timeline-line' />
      {slicedEvents.map((event) => (
        <Event key={`${event.text}-${event.time}`} event={event} t={t} />
      ))}
      {processedEvents.length > 3 && (
        <M.Link
          className='events-timeline-show-more'
          style={{ cursor: 'pointer' }}
          role='button'
          tabIndex='0'
          onClick={toggleCollapsedTimeline}
        >
          {collapsedTimeline
            ? t('adjudication:report.events.show_more')
            : t('adjudication:report.events.show_less')}
        </M.Link>
      )}
    </Timeline>
  );
};

export default Events;
