import React from 'react';
import styled from 'styled-components';
import { Item } from 'modules/assess/utilities/hierarchy';
import { Bullet } from 'modules/assess/v2/ui/common';

const List = styled.ul`
  list-style-type: disc;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 1rem;
`;

type Props = {
  subcategories?: Array<Item>;
};

export const SubcategoriesList: React.FC<Props> = ({ subcategories }) => {
  const bulletPoints = subcategories?.map(subcategory => {
    return <Bullet key={subcategory.id} text={subcategory.name} />;
  });

  return (
    <List data-testid='assess-v2-ui-common-lookback-rules-dialog-subcategories-list'>
      {bulletPoints}
    </List>
  );
};

export default SubcategoriesList;
