import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { scrollToTopWithCallback } from 'utils';

type Props = {
  onClose?: () => void;
  [key: string]: any;
  next?: GenericObject | null;
  back?: GenericObject | null;
};

const ModalFooter: React.FC<Props> = ({ onClose, next, back, ...props }) => {
  const close = onClose ? { onClick: onClose } : null;
  // TODO: refactor once customer is standalone app, no need to scroll then
  const nextAction = !next
    ? null
    : {
        ...next,
        onClick: scrollToTopWithCallback(next.onClick),
      };

  const backAction = !back
    ? null
    : {
        ...back,
        onClick: scrollToTopWithCallback(back.onClick),
      };

  return (
    <M.ModalNavigationFooter
      close={close}
      next={nextAction}
      back={backAction}
      {...props}
    />
  );
};

export default ModalFooter;
