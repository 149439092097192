import { Package, Packages } from 'types/report/package';

/**
 * @name getPackagesWithoutProperties
 * @function
 * @memberOf Packages
 * @description Removes packages that match any of the given properties
 * @returns {array} - New list of packages
 * @param {array} packages - List of packages from the API
 * @param {object} properties - Properties to evaluate against
 */
// eslint-disable-next-line import/prefer-default-export
export const getPackagesWithoutProperties = (
  packages: Packages,
  properties: Partial<Package>,
) =>
  packages.filter(
    (p: Package) =>
      !Object.keys(properties).some(
        // @ts-ignore
        prop => Boolean(p[prop]) === properties[prop],
      ),
  );
