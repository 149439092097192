import React, { useMemo, useState, useEffect, useReducer } from 'react';
import {
  CurrentUser,
  GenericObject,
  getStatusTypeForUser,
  StatusTypes,
} from '@dashboard-experience/utils';

import CustomProvider from 'state/provider';
import { useDispatch } from 'react-redux';
import { initializeSearch } from 'actions/SearchActions';
import { useFilters, useReduxState } from './Filters';
import {
  getProgramsByGeo,
  getSavedAdvanceFilters,
  getAllFilters,
} from './Filters/helpers';
import {
  CandidateSearchContext,
  candidateSearchReducer,
} from './search-context';
import useSearchURLParams from './useSearchURLParams';

type Props = {
  currentUser: CurrentUser;
};

// TODO: Add real initialState
const initialState = {
  candidates: null,
};

const Provider: React.FC<Props> = ({ currentUser, children }) => {
  const { searchParams } = useReduxState();

  const dispatchEvent = useDispatch();

  // On initial pageload, populate the redux search state with querystring params
  useEffect(() => {
    dispatchEvent(initializeSearch());
    // Non-exhaustive deps because we ONLY want to run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { account }: { [x: string]: any } = currentUser;
  const userType = getStatusTypeForUser(currentUser);
  const assessEnabled = userType === StatusTypes.Assess;

  const {
    geos = [],
    geosLoading,
    packages = [],
    packagesLoading,
    programs: originalPrograms = [],
    programsLoading,
    defaultGeos,
    defaultPackages,
    advancedFiltersLoading,
    savedSearches,
    savedSearchesLoading,
    defaultSavedSearch,
  } = useFilters(account);

  const [filters, setFilters] = useState<GenericObject>({});
  useEffect(() => {
    // If filters ISN'T set yet, but we DO have params from the redux state
    if (!Object.keys(filters).length && Object.keys(searchParams).length) {
      // Then set the filters to those redux state params
      setFilters(searchParams);
    }
  }, [filters, searchParams]);

  // Enable URL Search Params
  useSearchURLParams(filters);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(
    getSavedAdvanceFilters(),
  );
  const [showAllFilters, setShowAllFilters] = useState(getAllFilters());
  const [programs, setPrograms] = useState(originalPrograms);
  const [geosParams] = useState(searchParams.geo_ids);

  useEffect(() => {
    if (originalPrograms.length) {
      if (geosParams) {
        const filteredPrograms = getProgramsByGeo(originalPrograms, geosParams);
        const p = [originalPrograms[0], ...filteredPrograms];
        setPrograms(p);
      } else {
        setPrograms(originalPrograms);
      }
    }
  }, [originalPrograms, geosParams]);

  const [state, dispatch] = useReducer(candidateSearchReducer, {
    ...initialState,
  });

  const props = useMemo(
    () => ({
      currentUser,
      geos,
      geosLoading,
      defaultGeos,
      packages,
      packagesLoading,
      defaultPackages,
      programs,
      programsLoading,
      originalPrograms,
      setPrograms,
      filters,
      setFilters,
      assessEnabled,
      advancedFiltersLoading,
      showAdvancedFilters,
      showAllFilters,
      setShowAdvancedFilters,
      setShowAllFilters,
      savedSearches,
      savedSearchesLoading,
      defaultSavedSearch,
      savedSearchId: searchParams?.saved_search_id,
      state,
      dispatch,
    }),
    [
      currentUser,
      geos,
      geosLoading,
      defaultGeos,
      packages,
      packagesLoading,
      defaultPackages,
      programs,
      programsLoading,
      originalPrograms,
      filters,
      assessEnabled,
      advancedFiltersLoading,
      showAdvancedFilters,
      showAllFilters,
      savedSearches,
      savedSearchesLoading,
      defaultSavedSearch,
      searchParams?.saved_search_id,
      state,
      dispatch,
    ],
  );

  return (
    <CustomProvider context={CandidateSearchContext} props={props}>
      {children}
    </CustomProvider>
  );
};

export default Provider;
