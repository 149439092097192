import React, { useContext } from 'react';
import UIContext from 'context/UI';
import { useComponents } from 'providers/Candidate';

const withIframeComponentWrapper = (Component: React.FC, key: string) => {
  const WrappedComponent: React.FC = ({ ...props }) => {
    const { isIframe, isStandaloneIframe } = useContext(UIContext);
    const components = useComponents();
    if (isIframe && !isStandaloneIframe && !components.includes(key))
      return null;

    return <Component {...props} />;
  };
  WrappedComponent.displayName = `withIframeComponentWrapper(${Component.displayName})`;
  return WrappedComponent;
};

export default withIframeComponentWrapper;
