import { M } from '@dashboard-experience/mastodon';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { useReadOnly } from 'modules/assess/ui/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { scrollToTopIfIframe } from 'utils';
import DeleteDialog from '../delete';
import DeleteDraft from '../delete-draft';
import RenameDialog from '../rename';
import VersionsDialog from '../versions';
import MenuItem from './item';
import { Action, Kind } from './types';
import CreateDialog from '../create';

export type Props = {
  kind?: Kind;
  item?: Entity;
};

export const Menu: React.FC<Props> = ({ kind, item }) => {
  const [selected, select] = useState<Action>(undefined);
  const clear = useCallback(() => select(undefined), [select]);
  const disabled = useReadOnly();
  const icon = useMemo(() => getIcon(kind), [kind]);
  const ccsvDraft = item?.versions?.find(version => version.draft);

  const handleSelect = useCallback(action => {
    select(action);
    scrollToTopIfIframe();
  }, []);

  if (!item || !item.account_id) {
    return null;
  }

  return (
    <div>
      <M.Menu
        data-floating-menu-container
        data-testid='assess-mvr-category-set-menu'
        icon={icon}
        hideTooltip
        flipped
      >
        <MenuItem action='copy' disabled={disabled} select={handleSelect} />
        <MenuItem action='rename' disabled={disabled} select={handleSelect} />
        <MenuItem action='versions' select={handleSelect} />
        {ccsvDraft && (
          <MenuItem
            action='delete-draft'
            danger
            disabled={disabled}
            divider
            select={handleSelect}
          />
        )}
        <MenuItem
          action='delete'
          danger
          disabled={disabled}
          select={handleSelect}
        />
      </M.Menu>
      <CreateDialog open={selected === 'copy'} onClose={clear} ccs={item} />
      <DeleteDialog open={selected === 'delete'} onClose={clear} ccs={item} />
      <DeleteDraft
        open={selected === 'delete-draft'}
        onClose={clear}
        ccsvDraft={ccsvDraft}
      />
      <RenameDialog open={selected === 'rename'} onClose={clear} ccs={item} />
      <VersionsDialog
        open={selected === 'versions'}
        onClose={clear}
        item={item}
      />
    </div>
  );
};

function getIcon(type: Kind = 'options') {
  switch (type) {
    case 'options': {
      return (
        <M.Icon
          data-testid='assess-ruleset-menu-icon-dots'
          icon='OverflowMenuVertical'
          size={24}
        />
      );
    }
    case 'settings': {
      return (
        <M.Icon
          data-testid='assess-ruleset-menu-icon-gear'
          icon='Settings'
          size={24}
        />
      );
    }
  }
  return undefined;
}

export default Menu;
