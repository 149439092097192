import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Header = styled.h4`
  margin: 0 !important;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${colors.uiTextTertiaryLight};
  padding: 0px 24px;
`;

const TopRightComponent = styled.span`
  text-align: right;
`;

const ChildContainer = styled.div`
  padding-top: 24px;
`;

const styles = {
  Container,
  Header,
  HeaderContainer,
  Subheader,
  TopRightComponent,
  ChildContainer,
};

export default styles;
