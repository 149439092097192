import React from 'react';

type Type = {
  disabled?: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  bulkEdit: boolean;
  setBulkEdit: React.Dispatch<React.SetStateAction<boolean>>;
  expandAll: boolean;
  setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
  collapseAll: boolean;
  setCollapseAll: React.Dispatch<React.SetStateAction<boolean>>;
};

const Context = React.createContext<Type>({
  search: '',
  bulkEdit: false,
  setSearch: () => {},
  setBulkEdit: () => {},
  expandAll: false,
  setExpandAll: () => {},
  collapseAll: false,
  setCollapseAll: () => {},
});

export default Context;
