import React, { useEffect, useState, Fragment } from 'react';
import { Exception, GenericObject, Report } from 'types';
import { M } from '@dashboard-experience/mastodon';
import {
  DOCUMENT_UPLOAD_REJECTION_REASONS,
  HIDDEN_EXCEPTION_TYPES,
  STATIC_EXCEPTIONS,
} from 'Constants';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { ExceptionsProps } from './types';
import { ExceptionsWrapper } from './ExceptionsStyledComponents';
import Footer from './ExceptionsFooter';
import * as ExceptionComponents from './templates';
import { hasManualEntries as plvHasManualEntries } from '../Screenings/ProfessionalLicenseVerification/Helpers';

const exceptionsComponentMap: {
  [componentKey: string]: {
    component: React.FC<any>;
    shouldInclude: Function | null; // A callback function that to decide whether to show a particular exception or not
  };
} = {
  default: {
    component: ExceptionComponents.Default,
    shouldInclude: null,
  },
  plvManualEntry: {
    component: ExceptionComponents.PLVManualEntry,
    shouldInclude: (report: Report) => {
      const { professional_license_verifications: plvs } = report;
      let showException: boolean = false;
      if (plvs && plvs.length) {
        showException = plvs.find((plv: GenericObject) => {
          const { certifications } = plv;
          const hasManualEntries = plvHasManualEntries(certifications);
          return hasManualEntries;
        });
      }
      return showException;
    },
  },
  ohsMissingPaperwork: {
    component: ExceptionComponents.OhsMissingPaperwork,
    shouldInclude: null,
  },
  internationalDocumentationRequested: {
    component: ExceptionComponents.InternationalDocumentationRequested,
    shouldInclude: null,
  },
};

const getExceptionComponentKey = (exception_type: string) => {
  switch (exception_type) {
    case 'occupational_health_missing_paperwork':
      return 'ohsMissingPaperwork';
    case 'international_documentation_requested':
      return 'internationalDocumentationRequested';
    default:
      return 'default';
  }
};

/**
 * @name buildExceptionsList
 * @function
 * @memberOf Exceptions
 * @description Pulls all content needed for exceptions display and formats into an array
 * @returns {array}
 * @param {object} report - The report object
 */
const buildExceptionsList = (apiExceptions: Exception[], report: Report) => {
  const list: {
    componentKey: string;
    exception: GenericObject;
  }[] = apiExceptions?.length
    ? apiExceptions
        .filter((exception: GenericObject) => {
          return !HIDDEN_EXCEPTION_TYPES.includes(exception.type);
        })
        .map((exception: GenericObject) => {
          return {
            componentKey: getExceptionComponentKey(exception.type),
            exception,
          };
        })
    : [];
  STATIC_EXCEPTIONS.forEach((exceptionKey: string) => {
    const {
      [exceptionKey]: { shouldInclude },
    } = exceptionsComponentMap;
    if (shouldInclude && shouldInclude(report)) {
      list.push({
        componentKey: exceptionKey,
        exception: {},
      });
    }
  });
  return list;
};

const rejectionReasons: GenericObject = {
  ...DOCUMENT_UPLOAD_REJECTION_REASONS,
};

/**
 * @name getReportDocumentsRejectedReasons
 * @function
 * @memberOf Exceptions
 * @description Generate a string of human-friendly rejection reasons for display
 * @returns {array}
 * @param {array} reasons - Original array of reasons
 */
const getReportDocumentsRejectedReasons = (reasons: string[]) =>
  reasons.map(reason => rejectionReasons[reason]).join(', ');

const Exceptions: React.FC<ExceptionsProps> = ({
  meta,
  report,
  reportExceptions,
  candidateExceptions,
}) => {
  const currentUser = useUser();
  const [exceptionsList, setExceptionsList] = useState<GenericObject[]>([]);
  const [candidateExceptionsList, setCandidateExceptionsList] = useState<
    GenericObject[]
  >([]);
  const [reportDocumentRejectedReasons, setReportDocumentRejectedReasons] =
    useState<String | null>(null);

  useEffect(() => {
    if (meta?.report_documents_rejected_reasons) {
      const { report_documents_rejected_reasons } = meta;
      const reasons: String = getReportDocumentsRejectedReasons(
        report_documents_rejected_reasons,
      );
      setReportDocumentRejectedReasons(reasons);
    }
  }, [meta]);

  useEffect(() => {
    if (reportExceptions && reportExceptions.length) {
      setExceptionsList(buildExceptionsList(reportExceptions, report));
    }
  }, [reportExceptions, report]);

  useEffect(() => {
    if (candidateExceptions && candidateExceptions.length) {
      setCandidateExceptionsList(
        buildExceptionsList(candidateExceptions, report),
      );
    }
  }, [reportExceptions, report, candidateExceptions]);

  if (!hasPermission(currentUser, 'read_candidate_details')) return null;

  return (
    <M.Grid>
      <M.GridRow>
        {reportDocumentRejectedReasons && (
          <ExceptionsWrapper
            kind='warning'
            title='Document Uploads'
            data-testid='document-uploads'
            hideCloseButton
          >
            <div className='exception-container'>
              <div className='exception-header' style={{ fontWeight: 'bold' }}>
                Photos Rejected
              </div>
              <div className='exception-item'>
                The photo(s) uploaded by the candidate have been rejected for
                the following reason(s): {reportDocumentRejectedReasons}{' '}
                They&apos;ve been asked to upload another copy.
              </div>
            </div>
          </ExceptionsWrapper>
        )}
        {!!exceptionsList.length && (
          <ExceptionsWrapper
            kind='warning'
            title='Exceptions'
            data-testid='exceptions'
            hideCloseButton
          >
            {exceptionsList.map(({ componentKey, exception }) => {
              const { component: Component } =
                exceptionsComponentMap[componentKey];
              return (
                <Fragment key={exception.id}>
                  <Component exception={exception} report={report} />
                  <M.Divider />
                </Fragment>
              );
            })}
            <Footer />
          </ExceptionsWrapper>
        )}
        {hasPermission(currentUser, 'read_full_reports') &&
          !!candidateExceptionsList.length && (
            <ExceptionsWrapper
              kind='warning'
              title='Candidate Exceptions (Internal)'
              data-testid='candidate-exceptions'
              hideCloseButton
            >
              {candidateExceptionsList.map(({ componentKey, exception }) => {
                const { component: Component } =
                  exceptionsComponentMap[componentKey];
                return (
                  <Fragment key={exception.id}>
                    <Component exception={exception} report={report} />
                    <M.Divider />
                  </Fragment>
                );
              })}
              <Footer />
            </ExceptionsWrapper>
          )}
      </M.GridRow>
    </M.Grid>
  );
};

export default Exceptions;
