/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { BasePackage } from 'components/Packages/OrderBackgroundCheck/Context';
import { COMMERCIAL_MVR_ADD_ON_FLAG_KEY } from 'Constants';
import { Flag } from '@dashboard-experience/react-flagr';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  AccordionItem,
  CategoryTitle,
  Icon,
  ExpandableTitle,
  AddButtonContainer,
  Box,
  BoxDivider,
  BoxHeader,
  Flex,
  JustifyContentSpaceBetween,
  ScreeningInfo,
  BulletPoint,
  Title,
  AccordionItemProps,
  CheckboxWrapper,
  Toggletip,
  BoldInfoText,
  FlexContainerNoBorder,
  ContainerWithoutBorder,
} from '../../shared/ListItemElements';
import AddScreeningsAction from '../../shared/AddScreeningsAction';
import truck from '../../shared/svg/truck.svg';
import CommercialMvrCheckbox from './MotorVehicleReport/CommercialMvrCheckbox';

export type MotorVehicleReportAdditionalPropertiesProps = {
  [motor_vehicle_report: string]: {
    mvr_type: [boolean, string];
  };
};

type MotorVehicleReportProps = AccordionItemProps & {
  additionalProperties: MotorVehicleReportAdditionalPropertiesProps;
  basePackage: BasePackage;
};

const MotorVehicleRecordsCheckItem = ({
  screeningTypesInSearch,
  getPriceByType,
  actionProps,
  additionalProperties,
  basePackage,
}: MotorVehicleReportProps) => {
  const shouldOpen = screeningTypesInSearch?.includes('motor_vehicle_report');
  const { onAddClick, disabledScreenings } = actionProps;

  const checked = () => {
    if (
      (additionalProperties?.motor_vehicle_report?.mvr_type?.length ?? 0) > 0
    ) {
      return additionalProperties?.motor_vehicle_report?.mvr_type[0];
    }
    return false;
  };

  return (
    <AccordionItem
      open={screeningTypesInSearch?.includes('motor_vehicle_report')}
      data-testid='motor-vehicle-records-check-dropdown'
      title={
        <CategoryTitle data-testid='motor-vehicle-records-check-title'>
          <Icon src={truck} className='screening-icon' /> Motor Vehicle Records
          Check
        </CategoryTitle>
      }
    >
      <FlexContainerNoBorder>
        <ExpandableTitle
          initialExpanded={shouldOpen}
          data-testid='expandable-motor-vehicle-search-section'
          title={
            <Title
              getPriceByType={getPriceByType}
              title='Motor Vehicle Report'
              priceByType='motor_vehicle_report'
            />
          }
        >
          <JustifyContentSpaceBetween>
            <ScreeningInfo>
              Searches a state’s DMV or similar entity; confirms driver license
              and identifies driving incidents.{' '}
              <LearnMoreLink
                style={{ textDecoration: 'underline' }}
                linkText='Learn more'
                data-testid='mvr-learn-more-link'
                href='https://help.checkr.com/hc/en-us/articles/360001568167'
              />
            </ScreeningInfo>
            {/* <TurnAroundTime /> */}
          </JustifyContentSpaceBetween>
          <Flex>
            <Box>
              <BoxHeader>Advantages</BoxHeader>
              <ul>
                <BulletPoint text='Most records are digitized for faster turnaround' />
                <BulletPoint
                  text='Single source of all driving-related information from that
                    state'
                />
                <BulletPoint
                  text='Reports all data as provided by the DMV for the past 3-10
                    years, depending on state (or Puerto Rico)'
                />
              </ul>
            </Box>
            <BoxDivider />
            <Box>
              <BoxHeader>Considerations</BoxHeader>
              <ul>
                <BulletPoint
                  text='Only searches state (or Puerto Rico) of the candidate’s current driver’s
                    license'
                />
                <BulletPoint text='Some states and Puerto Rico have extra registration requirements' />
                <BulletPoint text='To setup Pennsylvania MVR, please contact customer support' />
              </ul>
            </Box>
          </Flex>
        </ExpandableTitle>
        <AddButtonContainer>
          <AddScreeningsAction
            screeningType='motor_vehicle_report'
            {...actionProps}
          />
        </AddButtonContainer>
      </FlexContainerNoBorder>
      <ContainerWithoutBorder>
        <Flag flagKey={COMMERCIAL_MVR_ADD_ON_FLAG_KEY} variantKey='enabled'>
          {!disabledScreenings.includes('motor_vehicle_report') && (
            <CheckboxWrapper>
              <CommercialMvrCheckbox
                checked={checked()}
                onAddClick={onAddClick}
              />
              <div data-testid='help-filled-icon-require-commercial-mvr'>
                <Toggletip align='bottom' tabIndex={0}>
                  <M.ToggletipButton>
                    <M.Icon icon='HelpFilled' />
                  </M.ToggletipButton>
                  <M.ToggletipContent>
                    <div>
                      <p>
                        <BoldInfoText>Commercial MVR</BoldInfoText> includes the
                        same information as an MVR, plus a Commercial
                        Driver&apos;s License Information System (CDLIS) search,
                        and medical certificate data where available.
                      </p>
                      <p>
                        It should be added if a driver has a commercial
                        driver&apos;s license (CDL) and is being evaluated for a
                        commercial motor vehicle (CMV) driving position.
                      </p>
                      <p>
                        CMV drivers are subject to FMCSA regulations, which is a
                        Department of Transportation (DOT) agency.
                      </p>
                    </div>
                  </M.ToggletipContent>
                </Toggletip>
              </div>
            </CheckboxWrapper>
          )}
        </Flag>
      </ContainerWithoutBorder>
    </AccordionItem>
  );
};

export default MotorVehicleRecordsCheckItem;
