import { useState, useEffect, useContext, useCallback } from 'react';
import { has } from 'lodash';
import Context from 'modules/assess/ui/rules/lookback/table/context';
import { SubCategory, Charge } from './types';

function isSubCategory(item: SubCategory | Charge): item is SubCategory {
  return has(item, 'charges');
}

export const useExpanded = (
  list: SubCategory[] | Charge[],
  isCategory?: boolean,
) => {
  const [isExpanded, setExpanded] = useState(false);
  const { search, expandAll, collapseAll } = useContext(Context);

  const handleClick = useCallback(
    () => setExpanded(!isExpanded),
    [isExpanded, setExpanded],
  );

  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
      return;
    }

    if (collapseAll) {
      setExpanded(false);
      return;
    }

    if (search && search.length > 1) {
      let shouldExpand = false;
      list.forEach((item: SubCategory | Charge) => {
        const re = new RegExp(search, 'i');
        const match = re.test(item.name);

        let childMatch = false;

        if (isCategory && isSubCategory(item)) {
          childMatch = item.charges.some((charge: Charge) =>
            re.test(charge.name),
          );
        }

        if (match || childMatch) {
          setExpanded(true);
          shouldExpand = true;
        }
      });
      !shouldExpand && setExpanded(false);
    } else {
      setExpanded(false);
    }
  }, [collapseAll, expandAll, isCategory, list, search]);

  return {
    isExpanded,
    handleClick,
  };
};
