/* eslint-disable no-promise-executor-return */
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { hasPermission, emailIsValid } from '@dashboard-experience/utils';
import { useUpdateCandidate } from 'api/candidate';
import { Candidate } from 'types';
import { FieldComponentProps } from '../types';
import Suppression from './Suppression';
import { CandidateInfoInPlaceContainer } from '../CandidateInformationStyledComponents';

const Email: React.FC<FieldComponentProps> = ({
  fieldKey,
  displayName,
  allowCandidateEdit,
  candidate,
  testid,
  currentUser,
}) => {
  const { email, id: candidateId } = candidate;

  const { updateCandidateCall } = useUpdateCandidate(fieldKey, displayName);

  /**
   * @name onEmailEditSubmit
   * @function
   * @memberOf Email
   * @description Handler for edit email submit
   * @returns {promise}
   * @param {object} e - The click event
   */
  const onEmailEditSubmit = useCallback(
    (newEmailValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          email: newEmailValue,
        });
        if (!emailIsValid(newEmailValue)) {
          return false;
        }
        return resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  const allowManageSuppressions = hasPermission(
    currentUser,
    'manage_communicator',
  );

  return (
    <CandidateInfoInPlaceContainer data-testid={testid}>
      <M.InPlaceTextEditor
        allowEdit={allowCandidateEdit}
        ariaLabel='candidate-email'
        initialValue={email}
        inputId='candidate-email'
        submitCallback={onEmailEditSubmit}
        data-testid='candidate-information-field-email-in-place-text-editor'
      />

      {allowManageSuppressions && (
        <Suppression
          candidate={candidate as Candidate}
          allowEdit={allowCandidateEdit}
        />
      )}
    </CandidateInfoInPlaceContainer>
  );
};

export default Email;
