import React from 'react';
import styled from 'styled-components';
import * as Images from '../../../assets/how-to-evaluate-background-checks';
import Subsections from '../subsections';

const StyledImage = styled.img`
  width: 677px;
  height: 220px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

type Props = {
  subsection: Subsections;
};

const Image: React.FC<Props> = ({ subsection }) => {
  const image = getImage(subsection);
  return <StyledImage src={image} alt={image} />;
};

function getImage(subsection: string): any {
  switch (subsection) {
    case Subsections.attention:
      return Images.AttentionImage;
    case Subsections.process:
      return Images.ProcessImage;
    default:
      return null;
  }
}

export default Image;
