import { Namespace } from '../api';
import {
  Fact as CriminalFact,
  REGEX_FACTS as CRIMINAL_REGEX_FACTS,
} from './criminal/rules/fact';
import { Fact as MVRFact } from './mvr/rules/fact';

export type Fact = CriminalFact | MVRFact;

export function getFactByNamespace(namespace: Namespace) {
  switch (namespace) {
    case Namespace.criminal:
      return CriminalFact;
    case Namespace.mvr:
      return MVRFact;
    default:
      throw new Error('Invalid Fact namespace');
  }
}

export function getRegexFactsByNamespace(namespace: Namespace) {
  switch (namespace) {
    case Namespace.criminal:
      return CRIMINAL_REGEX_FACTS;
    case Namespace.mvr:
      return [];
    default:
      throw new Error('Invalid Fact namespace');
  }
}
