import React, { useMemo } from 'react';
import moment from 'moment';
import { ExceptionInstanceProps } from '../types';
import { Body, Header, ResolvedAt, VerificationLink } from './components';

const OhsMissingPaperworkException: React.FC<ExceptionInstanceProps> = ({
  exception,
}) => {
  const {
    comment,
    created_at,
    resolved_at,
    verification_uri,
    resolution_period,
    type,
  } = exception;

  const expiresAt = useMemo(
    () =>
      moment(created_at).add(resolution_period, 'd').endOf('day').toISOString(),
    [created_at, resolution_period],
  );

  const expiresAtText = 'Health screening will be canceled on';

  const bodyText = `If the candidate does not respond within ${resolution_period}
                    days after the request is sent, the health
                    screening will be placed in canceled status. If the documentation
                    is provided within ${resolution_period} days, the screening
                    will resume processing.`;

  return (
    <div className='exception-container'>
      <Header comment={comment} createdAt={created_at} type={type} />
      <VerificationLink uri={verification_uri} />
      <ResolvedAt resolvedAt={resolved_at} type={type} />
      {!resolved_at && (
        <Body
          type={type}
          bodyText={bodyText}
          expiresAt={expiresAt}
          expireAtText={expiresAtText}
        />
      )}
    </div>
  );
};

export default OhsMissingPaperworkException;
