import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject, emailIsValid } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { AdverseAction } from 'types';
import { useCandidate } from 'providers/Candidate';
import { useUpdateCandidate } from 'api/candidate';
import ResolveUndeliverableAdverseModal from './ResolveUndeliverableAdverseModal';

const EntrySection = styled.div`
  #email-section {
    display: flex;
    align-items: flex-end;

    .email-input {
      margin-top: 1rem;
      width: 50%;

      label {
        font-size: 14px;
      }
    }

    .mastodon-button.primary {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`;

const ErrorBox = styled.div`
  width: 100%;
  height: 14px;
`;

const ConfirmSection = styled.div`
  #emails {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  b {
    font-weight: bold;
  }
  ol {
    padding: revert;
    list-style: auto;
    padding-top: 0.5rem;
  }
`;

// First page of the modal - prompts user to input a new email address
const EntryPage: React.FC<GenericObject> = ({
  newEmail,
  isValid,
  changeEmail,
  goToConfirmPage,
}) => {
  const { t } = useTranslation();

  return (
    <EntrySection>
      <p>{t('resolve_undeliverable_aa.email.bad_email')}</p>
      <div id='email-section'>
        <M.TextInput
          id='new-email-input'
          className='email-input'
          type='email'
          placeholder='Email'
          defaultValue={newEmail}
          labelText={t('resolve_undeliverable_aa.email.enter_email')}
          onChange={changeEmail}
        />
        <M.Button onClick={goToConfirmPage} disabled={!isValid}>
          {t('buttons.continue')}
        </M.Button>
      </div>
    </EntrySection>
  );
};

const EntryFooter: React.FC<GenericObject> = ({ isValid, t }) => {
  // ErrorBox ensures that a consistent height is applied, and then it contains a message if applicable
  return <ErrorBox>{!isValid && t('email.invalid_entry')}</ErrorBox>;
};

// Second page of the modal - asks user to confirm the new email, and informs them of what will happen
const ConfirmPage: React.FC<GenericObject> = ({ oldEmail, newEmail, t }) => {
  return (
    <ConfirmSection>
      <p>
        {t('email.confirm_entry')}
        <span id='emails'>
          <b>{oldEmail}</b>
          <M.Icon icon='ArrowRight' size={16} />
          <b>{newEmail}</b>
        </span>
      </p>

      <Trans t={t} i18nKey='email.explanation'>
        <p>Confirming this notice will automatically:</p>
        <ol>
          <li>Resolve this notice</li>
          <li>
            {/* prettier is very aggressive about inserting linebreaks here, beware the impact on the translation string */}
            Your original Adverse Action to <b>{oldEmail}</b> will be re-sent to{' '}
            <b>{newEmail}</b>
          </li>
        </ol>
      </Trans>
    </ConfirmSection>
  );
};

const ConfirmFooter: React.FC<GenericObject> = ({ submitEmail, goToEntry }) => {
  const { t } = useTranslation();
  return (
    <M.BinaryButtons
      btnLeft={{
        kind: 'secondary',
        name: t('buttons.back'),
        onClick: goToEntry,
      }}
      btnRight={{
        kind: 'primary',
        name: t('buttons.confirm'),
        onClick: submitEmail,
      }}
    />
  );
};

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  adverseAction: AdverseAction;
};
const ResolveUndeliverableWithEmail: React.FC<ModalProps> = ({
  showModal,
  onClose,
  adverseAction,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'resolve_undeliverable_aa' });

  const candidate = useCandidate();
  const { id, email } = candidate;

  const [newEmail, setNewEmail] = useState<string>(email || '');
  const changeEmail = useCallback((event: any) => {
    setNewEmail(event.target.value);
  }, []);
  const newEmailIsValid = emailIsValid(newEmail);

  // This modal contains 2 pages, keep track of the current one here
  const [onEntryPage, setOnEntryPage] = useState(true);
  const goToConfirmPage = useCallback(() => setOnEntryPage(false), []);
  const goToEntryPage = useCallback(() => setOnEntryPage(true), []);

  const { updateCandidateCall, updateCandidateResult } = useUpdateCandidate('email', 'Email'); // prettier-ignore

  const submitEmail = useCallback(() => {
    updateCandidateCall({ id, email: newEmail });
  }, [id, newEmail, updateCandidateCall]);

  useEffect(() => {
    if (updateCandidateResult.isSuccess || updateCandidateResult.isError) {
      // Only close the modal for successes, since people with errors may want to review what they entered
      if (updateCandidateResult.isSuccess) {
        onClose();
      }

      updateCandidateResult.reset();
    }
  }, [onClose, updateCandidateResult]);

  return (
    <ResolveUndeliverableAdverseModal
      showModal={showModal}
      onClose={onClose}
      reason={adverseAction.aa_delivery_state_reason}
      footer={
        onEntryPage ? (
          <EntryFooter isValid={newEmailIsValid} t={t} />
        ) : (
          <ConfirmFooter submitEmail={submitEmail} goToEntry={goToEntryPage} />
        )
      }
    >
      {onEntryPage ? (
        <EntryPage
          newEmail={newEmail}
          isValid={newEmailIsValid}
          changeEmail={changeEmail}
          goToConfirmPage={goToConfirmPage}
        />
      ) : (
        <ConfirmPage oldEmail={email} newEmail={newEmail} t={t} />
      )}
    </ResolveUndeliverableAdverseModal>
  );
};

export default ResolveUndeliverableWithEmail;
