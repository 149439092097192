import React from 'react';
import { SCREENING_NAME_MAP } from 'Constants';

import { countCandidates } from 'components/Signup/utils/candidates';
import {
  Container,
  Content,
  SectionTitle,
  Screening,
  ScreeningPrice,
  NoneAdded,
  Recipients,
  Section,
  SectionItem,
  EmptyPrice,
} from './styles';
import { getDollarAmount } from '../../../AddScreenings/shared/utils';
import { AddOnsType, Candidate } from '../../types';

type Props = {
  packageName: string;
  packagePrice: number;
  screenings: AddOnsType[];
  candidates: Candidate[];
  screeningPrices: { [key: string]: number };
  isInternational?: boolean;
};

const OrderSummary = ({
  packageName,
  screenings,
  candidates,
  screeningPrices,
  packagePrice,
  isInternational = false,
}: Props) => {
  const numOfCandidates = countCandidates(candidates);
  return (
    <Container data-testid='order-summary'>
      <h4 className='mb-0'>Order summary</h4>
      <Content>
        <Section data-testid='order-summary-package-details'>
          <SectionTitle>Package</SectionTitle>
          <SectionItem data-testid='order-summary-package'>
            <Screening data-testid='order-summary-package-name'>
              {packageName}
            </Screening>
            <ScreeningPrice data-testid='order-summary-package-price'>
              {getDollarAmount(packagePrice.toString())}
            </ScreeningPrice>
          </SectionItem>
        </Section>
        {!isInternational && (
          <Section data-testid='order-summary-add-ons'>
            <SectionTitle>Customize</SectionTitle>
            {screenings.length > 0 ? (
              screenings.map(screening => (
                <SectionItem key={screening}>
                  <Screening data-testid='order-summary-addons-name'>
                    {SCREENING_NAME_MAP[screening]}
                  </Screening>
                  <ScreeningPrice>
                    {getDollarAmount(screeningPrices[screening].toString())}
                  </ScreeningPrice>
                </SectionItem>
              ))
            ) : (
              <SectionItem>
                <NoneAdded data-testid='screening-none-added'>
                  None added
                </NoneAdded>
                <EmptyPrice>—</EmptyPrice>
              </SectionItem>
            )}
          </Section>
        )}
        <Section data-testid='order-summary-recipients'>
          <SectionTitle>Candidates</SectionTitle>
          {numOfCandidates > 0 ? (
            <Recipients data-testid='num-of-candidates'>
              {numOfCandidates} added
            </Recipients>
          ) : (
            <NoneAdded data-testid='candidates-none-added'>
              None added
            </NoneAdded>
          )}
        </Section>
      </Content>
    </Container>
  );
};

export default OrderSummary;
