import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';

const Container = styled(M.Container)`
  #mastodon && {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0.75rem 1rem;

    .mastodon-icon {
      flex-shrink: 0;
    }
    .description {
      color: ${colors.coolBlack90};
    }
  }
`;

const base_path = `${exam_reorder_modal_body}.appointment.info_banner`;

const InfoBanner = () => {
  const { t } = useTranslation();
  return (
    <section data-testid='appointment-info-banner'>
      <Container type='info'>
        <M.Icon icon='Idea' size={32} />
        <p className='description'>{t(`${base_path}.description`)}</p>
      </Container>
    </section>
  );
};

export default InfoBanner;
