import {
  StatusTypes,
  ReportStatuses,
  computeScreeningsStatus,
} from '@dashboard-experience/utils';
import { GenericObject, Report } from 'types';

const { CONSIDER } = ReportStatuses;

const hasSingleSearch = (search: GenericObject[]) => search?.length === 1;

const getSearchText = (search: GenericObject[] | any, text: string) =>
  search && hasSingleSearch(search) ? text : text.concat('es');

const getBodyText = (report: Report) => {
  const countyCriminalSearchesStatus = computeScreeningsStatus(
    report.county_criminal_searches || [],
    StatusTypes.Legacy,
  );
  const stateCriminalSearchesStatus = computeScreeningsStatus(
    report.state_criminal_searches || [],
    StatusTypes.Legacy,
  );

  const countyText = getSearchText(
    report?.county_criminal_searches,
    'County Search',
  );
  const stateText = getSearchText(
    report?.state_criminal_searches,
    'State Search',
  );

  if (
    countyCriminalSearchesStatus === CONSIDER &&
    stateCriminalSearchesStatus !== CONSIDER
  )
    return `See ${countyText}.`;
  if (
    stateCriminalSearchesStatus === CONSIDER &&
    countyCriminalSearchesStatus !== CONSIDER
  )
    return `See ${stateText}.`;
  if (
    countyCriminalSearchesStatus === CONSIDER &&
    stateCriminalSearchesStatus === CONSIDER
  )
    return `See ${countyText} and ${stateText}.`;

  return null;
};

export default getBodyText;
