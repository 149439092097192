import React, { useEffect } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import H3 from 'modules/adjudication/ui/common/h3';
import { useModalState, useDispatch } from '../../context';
import { usePostAA } from './preview-email/hooks';
import ErrorMessage from '../errors';

const confirmation = `${namespace}:report.actions.pre_adverse.confirm`;
const send_pre_aa = `${namespace}:report.actions.pre_adverse.submit`;
const loading = `${namespace}:report.actions.pre_adverse.loading`;

const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  .confirmation {
    text-align: center;
  }
`;

const Confirm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const previousScreen = useModalState('previousScreen');
  const submitAction = t(send_pre_aa);
  const loadingAction = t(loading);
  const confirmationMsg = t(confirmation);

  const { submit, isLoading: postLoading, errors } = usePostAA();

  const nextName = postLoading ? loadingAction : submitAction;

  useEffect(() => {
    const disabled = { disabled: postLoading };

    const next = {
      name: nextName,
      disabled: postLoading,
      onClick: submit,
    };

    const back = { onClick: previousScreen, ...disabled };

    dispatch({
      type: 'SET_NAVIGATION',
      payload: {
        next,
        back,
        close: disabled,
      },
    });
    // previousScreen and submit are the same functions but change on every render, shouldn't be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, nextName, postLoading]);

  return (
    <>
      <ErrorMessage errors={errors} />
      <Container>
        {!errors.length && <H3 className='confirmation'>{confirmationMsg}</H3>}
        {postLoading && <M.LoadingSpinner data-testid='post-aa-loading' />}
      </Container>
    </>
  );
};

export default Confirm;
