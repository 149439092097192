import { useEffect, useCallback, useMemo } from 'react';
import checkrAnalytics from 'components/analytics';
import { GenericObject } from '@dashboard-experience/utils';
import { useReport } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { Events } from './events';

const analytics = checkrAnalytics as any;

export const useTrackEvent = () => {
  const user = useUser();
  const report = useReport();

  const baseProperties = useMemo(
    () => ({
      report_resource_id: report?.id,
    }),
    [report],
  );

  return useCallback(
    (event: Events, eventProperties: GenericObject = {}) =>
      analytics.track(user, event, { ...baseProperties, ...eventProperties }),
    [baseProperties, user],
  );
};

export const useTrackEventOnMount = (
  event: Events,
  eventProperties: GenericObject = {},
) => {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(event, eventProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
