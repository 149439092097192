import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { Invitation } from 'types/report/invitation';
import { KeyValueList } from '../styles';

interface InvitationStatusProps {
  invitation: Invitation;
}

const InvitationStatus: React.FC<InvitationStatusProps> = ({ invitation }) => {
  const invitationStatus = useMemo(
    () => capitalize(invitation?.status),
    [invitation?.status],
  );

  const items = useMemo(
    () => [{ itemKey: 'Status', itemValue: invitationStatus }],
    [invitationStatus],
  );

  return invitation && invitationStatus ? (
    <div>
      <KeyValueList
        withPadding
        data-testid='overview-invitation-status'
        items={items}
      />
    </div>
  ) : null;
};

export default InvitationStatus;
