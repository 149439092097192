import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  ContinuousServicesCsvExportContainer,
  ContextContainer,
} from 'containers';

type RouterProps = {
  exportId: string;
};

type Props = RouteComponentProps<RouterProps>;

const ContinuousServicesCsvExportsPage: React.FC<Props> = ({ match }) => {
  const {
    params: { exportId },
  } = match;
  return <ContinuousServicesCsvExportContainer exportId={exportId} />;
};

export default ContextContainer(ContinuousServicesCsvExportsPage);
