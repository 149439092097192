export enum AdverseActionBasis {
  EXTERNAL = 'external',
  CANDIDATE_ACTION_UNRESOLVED = 'candidate_action_unresolved',
  REPORT_INCOMPLETE = 'report_incomplete',
  REPORT_ITEM = 'report_item',
}

export type Template = {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  selected?: number | null;
  options: Template[] | null;
};

export type Templates = {
  pre: number | null;
  post: number | null;
};

export type MediaConfig = {
  email: MediumConfig;
  postal: MediumConfig;
};

export type MediumConfig = {
  priority: number;
  required: boolean;
};

export type Locations = string[];

export type AdverseActionTemplatesPayload = {
  pre?: Template[];
  post?: Template[];
  candidate_locations?: Locations[];
};

export type AdversableItem = {
  category?: null | string;
  charge: null | string;
  charge_type: null | string;
  charge_type_classification: null | string;
  criminal: boolean;
  disposition: null | string;
  judgement?: null | string;
  date: string;
  date_type: null | string;
  adversible: {
    active_warrant: boolean;
    additional_info: null | string;
    alternative_adjudication: null | string;
    applied_adjudication_filter_ids: null | string[];
    applied_context_date: null | string;
    applied_filter: null | string;
    applied_filter_state: null | string;
    arrest_date: null | string;
    cannot_complete: boolean;
    charge: null | string;
    charge_category: null | string;
    charge_date: null | string;
    charge_id: null | string;
    charge_subcategory: null | string;
    charge_subsubcategory: null | string;
    charge_type: null | string;
    charge_type_classification: null | string;
    classification: null | string;
    context_date: null | string;
    conviction_date: null | string;
    court: null | string;
    criminal: boolean;
    defendant: null | string;
    deposition: null | string;
    deposition_date: null | string;
    disposition: null | string;
    disposition_classification: null | string;
    disposition_date: null | string;
    end_date: null | string;
    excluded_by_duplicate: boolean;
    fines: null | string;
    jail_time: null | string;
    metadata: {
      charge_classification: {
        prediction: {
          confidence_prediction: null | number;
          subsubcategory_prediction: null | string;
          subcategory_prediction: null | string;
          category_prediction: null | string;
        };
        top_predictions: [
          {
            confidence_prediction: 0.9817133545875549;
            subsubcategory_prediction: null | string;
            subcategory_prediction: null | string;
            category_prediction: null | string;
          },
        ];
        version: null | string;
      };
      disposition_classification_result: {
        classification: null | string;
        confidence: null | number;
      };
      computed_disposition: null | string;
      disposition_category: null | string;
    };
    modifier_id: null | string;
    next_court_date: null | string;
    no_future_court_date: null | boolean;
    notes: null | string;
    offense_code: null | string;
    offense_date: null | string;
    plaintiff: null | string;
    plea: null | string;
    prison_time: null | string;
    probation_status: null | string;
    probation_time: null | string;
    provider_errors: string[];
    qa_state: null | string;
    release_date: null | string;
    reportable: boolean;
    restitution: null | string;
    sentence: null | string;
    sentence_date: null | string;
    severity_score: null | number;
    start_date: null | string;
    statute: null | string;
    transfer_case_jurisdiction: null | string;
    transfer_case_number: null | string;
    transfer_county: null | string;
    transfer_state: null | string;
    version: null | string;
  };
  adversible_id: null | string;
  adversible_type: string;
  text: null | string;
  assessment: {
    decision: null | string;
    rule: {
      id: null | string;
      name: null | string;
      type: null | string;
    };
    display: {
      decision: null | string;
      rules: [
        {
          id: null | string;
          type: null | string;
          name: null | string;
          item_visibility: null | string;
          applied_context_date: null | string;
        },
      ];
    };
  };
  fingerprint: null | string;
};

export type BasisAvailability = { id: AdverseActionBasis; enabled: boolean };

export type PreAdverseActionNoticeTemplatePayload = {
  adversible_items: null | AdversableItem[];
  bases: null | BasisAvailability[];
  filtered_adversible_items: null | any[];
  individualized_assessment_show_type: null | string;
  post_adverse_action_wait_time: null | number;
};
