import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/count';
import { SaveButton } from 'modules/assess/ui/rules/common/rule/editor';
import validateComplexRule from './validate-complex-rule';

type Props = {
  rule: any;
};

const Save: React.FC<Props> = ({ rule }) => {
  const dispatch = useDispatch();
  const [isRuleValid, setIsRuleValid] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        validateComplexRule(rule);
        setIsRuleValid(true);
      } catch {
        setIsRuleValid(false);
      }
    }, 700);
    return () => clearInterval(interval);
  }, [rule]);

  const save = useCallback(
    () =>
      dispatch(
        Actions.Update.create({
          ...rule,
          editing: false,
          initial: false,
        }),
      ),
    [dispatch, rule],
  );

  return <SaveButton onClick={save} disabled={!isRuleValid} />;
};

export default Save;
