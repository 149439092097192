import isEmpty from 'lodash/isEmpty';
import { ReorderableExams, Exam, ExamType } from '../../../types';

export const getSelectedExams = (exams: Exam[] = []) =>
  exams.filter(exam => exam.checked);

export const getSelectedExamsByType = (
  type: ExamType.Drug | ExamType.OccHealth,
  exams: Exam[] = [],
) => exams.filter(exam => exam.checked && exam.type === type);

export const isNextButtonDisabled = (
  reorderableExams: ReorderableExams,
  drugObservedCollection?: string,
) => {
  const selectOccExams = getSelectedExamsByType(
    ExamType.OccHealth,
    reorderableExams,
  );
  const selectedDrugExams = getSelectedExamsByType(
    ExamType.Drug,
    reorderableExams,
  );

  return (
    (isEmpty(selectOccExams) && isEmpty(selectedDrugExams)) ||
    (!isEmpty(selectedDrugExams) && Boolean(!drugObservedCollection))
  );
};
