import {
  I9VerificationsClient as i9VerificationsClient,
  SearchClient,
} from 'api';
import { GenericObject } from '../../types';

export type FormI9Params = {
  params: {
    candidate_id: string;
    full_name: string;
    email: string;
    start_date: string;
    worksite_id: string;
    workflow_type: string;
    authorized_representative_first_name: string;
    authorized_representative_last_name: string;
    authorized_representative_email: string;
  }[];
};

export type FormI9Response = {
  id: string;
  candidate_id: string;
  full_name: string;
  order_progress: string;
  order_progress_text: string;
  open_tasks: string;
  open_tasks_link: string;
  open_tasks_text: string;
  form_i9_link: string;
  email: string;
  start_date: string;
  worksite_id: string;
  worksite_name: string;
  workflow_type: string;
  authorized_representative_first_name: string;
  authorized_representative_last_name: string;
  authorized_representative_email: string;
  created_at: string;
};

export type AllFormsResponse = {
  data: FormI9Response[];
  count: number;
};

export type WorksiteEverifyStatus = 'active' | 'inactive' | 'not_determined';

export type WorksiteParams = {
  params: {
    name: string;
    street_line1: string;
    street_line2: string;
    city: string;
    state: string;
    zip_code: string;
    everify_status: WorksiteEverifyStatus;
  };
};

export type WorksiteResponse = {
  id: string;
  name: string;
  street_line1: string;
  street_line2: string;
  city: string;
  state: string;
  zip_code: string;
  everify_status: WorksiteEverifyStatus;
};

export type AllWorksitesResponse = {
  data: WorksiteResponse[];
};

export type AccountSettingsParams = {
  params: {
    employer_name: string;
    terms_of_service_agreement: {
      version: number;
    };
    default_worksite: {
      name: string;
      street_line1: string;
      street_line2: string;
      city: string;
      state: string;
      zip_code: string;
    };
  };
};

export type AccountEverifyStatus = 'deactivated' | 'activated' | 'pending';

export type AccountSettingsUpdateParams = {
  params: {
    employer_name: string;
    default_worksite_id: string;
    everify_status: AccountEverifyStatus;
  };
};

export type AccountSettingsResponse = {
  employer_name: string;
  default_worksite_id: string;
  everify_status: AccountEverifyStatus;
};

export type Candidate = {
  candidate_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  date_of_birth: string;
  email: string;
  created_at: string;
  updated_at: string;
  has_form_i9: boolean;
};

export type AllCandidatesResponse = {
  data: Candidate[];
  count: number;
};

export type FormsI9PDFLinksResponse = {
  pdf_urls: string[];
};

export type FormsI9PDFIDsParams = {
  param: {
    forms_i9_ids: string[];
  };
};

export type TOSResponse = {
  version: number;
  content: string;
};

export type UserParams = {
  params: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export type UserResponse = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type AllUsersResponse = {
  data: UserResponse[];
};

export type EmployeeParams = {
  params: EmployeeResponse;
};

export type EmployeeResponse = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
};

export const getAllI9Forms = (
  orderBy: string,
  order: string,
  page: number,
  perPage: number,
  search: string,
  worksiteId: string,
  orderProgress: string,
  openTasks: string,
): Promise<AllFormsResponse> => {
  const searchParams = new URLSearchParams();

  if (orderBy || orderBy !== '') {
    searchParams.append('order_by', orderBy);
  }

  if (order || order !== '') {
    searchParams.append('order', order);
  }

  if (page) {
    searchParams.append('page', page.toString());
  }

  if (perPage) {
    searchParams.append('per_page', perPage.toString());
  }

  if (search || search !== '') {
    searchParams.append('search', search);
  }

  if (
    (worksiteId || worksiteId !== '') &&
    worksiteId.trim().toLowerCase() !== 'all'
  ) {
    searchParams.append('worksite_id', worksiteId);
  }

  if (
    (orderProgress || orderProgress !== '') &&
    orderProgress.trim().toLowerCase() !== 'all'
  ) {
    searchParams.append('order_progress', orderProgress);
  }

  if (
    (openTasks || openTasks !== '') &&
    openTasks.trim().toLowerCase() !== 'all'
  ) {
    searchParams.append('open_tasks', openTasks);
  }

  return i9VerificationsClient.get(`/forms_i9?${searchParams.toString()}`);
};

export const getFormById = (formId: string): Promise<FormI9Response> => {
  return i9VerificationsClient.get(`/forms_i9/${formId}`);
};

export const createFormI9 = ({
  params,
}: FormI9Params): Promise<FormI9Response[]> => {
  return i9VerificationsClient.post(
    `/forms_i9`,
    params.map(p => ({ ...p })),
  );
};

export const createWorksite = ({
  params,
}: WorksiteParams): Promise<WorksiteResponse> => {
  return i9VerificationsClient.post(`/worksites`, { ...params });
};

export const updateWorksite = (
  worksiteId: string,
  { params }: WorksiteParams,
): Promise<WorksiteResponse> => {
  return i9VerificationsClient.put(`/worksites/${worksiteId}`, { ...params });
};

export const getAllWorksites = (
  orderBy: string,
  sort: string,
): Promise<AllWorksitesResponse> => {
  return i9VerificationsClient.get(
    `/worksites?order_by=${orderBy}&sort=${sort}`,
  );
};

export const deleteWorksite = (id: string): Promise<void> => {
  return i9VerificationsClient.delete(`/worksites/${id}`);
};

export const getAccountSettings = (): Promise<AccountSettingsResponse> => {
  return i9VerificationsClient.get(`/account_settings`);
};

export const createAccountSettings = ({
  params,
}: AccountSettingsParams): Promise<AccountSettingsResponse> => {
  return i9VerificationsClient.put(`/account_settings`, { ...params });
};

export const updateAccountSettings = ({
  params,
}: AccountSettingsUpdateParams): Promise<AccountSettingsResponse> => {
  return i9VerificationsClient.put(`/account_settings`, { ...params });
};

export const getAllCandidates = (
  page: number,
  perPage: number,
  searchFilter: string,
): Promise<AllCandidatesResponse> => {
  return i9VerificationsClient.get(
    `/search_candidates?page=${page}&per_page=${perPage}&search=${searchFilter}`,
  );
};

export const postFormI9PDF = ({
  param,
}: FormsI9PDFIDsParams): Promise<FormsI9PDFLinksResponse> => {
  return i9VerificationsClient.post(`/forms_i9/pdfs`, { ...param });
};

export const postFormI9CSVFile = (formI9Ids: string[]): Promise<void> => {
  return i9VerificationsClient.downloadViaPOST({
    path: '/forms_i9/csv',
    data: {
      forms_i9_ids: formI9Ids,
    },
    filename: 'forms_i9.csv',
    options: { responseType: 'blob' },
  });
};

export const getLastTOSContent = (): Promise<TOSResponse> => {
  return i9VerificationsClient.get(`/terms_of_service_contents/latest`);
};

export const createUser = ({ params }: UserParams): Promise<UserResponse> => {
  return i9VerificationsClient.post(`/users`, { ...params });
};

export const getAllUsers = (): Promise<AllUsersResponse> => {
  return i9VerificationsClient.get(`/users?order_by=full_name&sort=asc`);
};

export const createEmployee = ({
  params,
}: EmployeeParams): Promise<EmployeeResponse> => {
  return i9VerificationsClient.post(`/employees`, { ...params });
};

export const getCandidateByEmail = (email: string): Promise<GenericObject> => {
  const encodedEmail = encodeURIComponent(email);
  return SearchClient.get(
    `/search?order_by=created_at&archived=all&page=1&per_page=1&sort=desc&email=${encodedEmail}`,
  );
};
