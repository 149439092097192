import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { M } from '@dashboard-experience/mastodon';

const IdentityVerification: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { identity_verification } = report;

  if (!identity_verification) return null;

  return (
    <M.Screening
      title='Identity Verification'
      data-testid='screenings-identity-verification'
      screening={identity_verification}
      statusType={reportStatusType}
      id={SCREENING_TITLES.identity_verification.id}
    >
      <div>
        Information returned by this search is not a part of the consumer report
        and may not be used for FCRA purposes, including eligibility
        determinations under the FCRA.
      </div>
    </M.Screening>
  );
};

export default IdentityVerification;
