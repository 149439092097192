import React, { useCallback } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  useArchiveInvitations,
  useUnarchiveInvitations,
} from 'api/archiveReport';
import styled from 'styled-components';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useTrackEvent, ARCHIVE_INVITATIONS_NAMES } from 'utils/analytics';
import { useUser } from 'context/CurrentUser';
import { scrollToTopIfIframe } from 'utils';
import { InvitationStatuses } from '@dashboard-experience/utils';
import { queryCache } from 'react-query';
import { useBetaEnabled } from 'providers/Beta';
import { Invitation } from '../../types/report/invitation';

const ModalBody = styled(M.ModalBody)`
  p {
    color: ${colors.coolBlack70};
    padding-right: 0;
  }
`;

const ArchiveReportAction: React.FC<{
  invitation: Invitation;
}> = ({ invitation }) => {
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  const [unarchiveModalOpen, setUnarchiveModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const trackEvent = useTrackEvent();
  const currentUser = useUser();

  const openArchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_INVITATIONS_NAMES.ARCHIVE_INVITATION_ACTION_SELECTED, {
        'Archive action': 'Archive invitation',
      });
    }

    setArchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setArchiveModalOpen, currentUser, trackEvent]);

  const openUnarchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_INVITATIONS_NAMES.ARCHIVE_INVITATION_ACTION_SELECTED, {
        'Archive action': 'Unarchive invitation',
      });
    }

    setUnarchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [setUnarchiveModalOpen, currentUser, trackEvent]);

  const closeArchiveModal = useCallback(() => {
    setArchiveModalOpen(false);
  }, [setArchiveModalOpen]);

  const closeUnarchiveModal = useCallback(() => {
    setUnarchiveModalOpen(false);
  }, [setUnarchiveModalOpen]);

  const { call: archiveInvitationCall } = useArchiveInvitations();
  const { call: unarchiveInvitationCall } = useUnarchiveInvitations();

  const archive = useCallback(() => {
    setIsLoading(true);
    setArchiveModalOpen(false);
    archiveInvitationCall({
      candidate_invitation_ids: [
        {
          candidate_id: invitation.candidate_id,
          invitation_id: invitation.id,
        },
      ],
      user_resource_id: currentUser?.id as string,
    }).then(() => {
      queryCache.invalidateQueries('candidates/dashboard');
      setIsLoading(false);
    });
  }, [archiveInvitationCall, invitation]);

  const unarchive = useCallback(() => {
    setIsLoading(true);
    setUnarchiveModalOpen(false);
    unarchiveInvitationCall({
      candidate_invitation_ids: [
        {
          candidate_id: invitation.candidate_id,
          invitation_id: invitation.id,
        },
      ],
      user_resource_id: currentUser?.id as string,
    }).then(() => {
      queryCache.invalidateQueries('candidates/dashboard');
      setIsLoading(false);
    });
  }, [unarchiveInvitationCall, invitation]);

  const showArchiveInvitationButton =
    invitation.status === InvitationStatuses.EXPIRED;

  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);

  const buttonStyle = candidateRedesignEnabled
    ? { marginLeft: '0px' }
    : { marginTop: '1.25rem' };

  const Buttons = invitation.archived ? (
    <M.Button
      style={buttonStyle}
      onClick={openUnarchiveModal}
      kind='secondary'
      size='md'
      data-testid='unarchive-invitation'
    >
      Unarchive invitation
    </M.Button>
  ) : (
    <M.Button
      style={buttonStyle}
      onClick={openArchiveModal}
      kind='secondary'
      size='md'
      data-testid='archive-invitation'
    >
      Archive invitation
    </M.Button>
  );

  return (
    <>
      {isLoading && <M.LoadingSpinner />}
      {showArchiveInvitationButton && Buttons}

      <M.ComposedModal
        open={archiveModalOpen}
        onClose={closeArchiveModal}
        data-testid='archive-modal'
      >
        <M.ModalHeader closeModal={closeArchiveModal}>
          <h2>Archive invitation?</h2>
        </M.ModalHeader>
        <ModalBody>
          <p>
            Archiving this invitation will remove it from your{' '}
            <strong>Active</strong> tab. You can unarchive it at any time.
          </p>
        </ModalBody>
        <M.ModalFooter>
          <M.Button kind='secondary' onClick={closeArchiveModal}>
            Cancel
          </M.Button>
          <M.Button
            type='submit'
            onClick={archive}
            data-testid='confirm-archive-modal'
          >
            Confirm
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>

      <M.ComposedModal
        open={unarchiveModalOpen}
        onClose={closeUnarchiveModal}
        data-testid='unarchive-modal'
      >
        <M.ModalHeader closeModal={closeUnarchiveModal}>
          <h2>Unarchive invitation?</h2>
        </M.ModalHeader>
        <ModalBody>
          <p>
            Unarchiving this invitation will add it back to your{' '}
            <strong>Active</strong> tab. You can re-archive it at any time.
          </p>
        </ModalBody>
        <M.ModalFooter>
          <M.Button kind='secondary' onClick={closeUnarchiveModal}>
            Cancel
          </M.Button>
          <M.Button
            type='submit'
            onClick={unarchive}
            data-testid='confirm-unarchive-modal'
          >
            Confirm
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>
    </>
  );
};

export default ArchiveReportAction;
