/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable no-shadow */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { debounce } from 'lodash';

import { useEffectAfterInitialized } from '@dashboard-experience/utils';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateSearchParams } from 'actions/SearchActions';
import { useUser } from 'context/CurrentUser';
import { useSearchState } from 'components/Search/search-context';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { useLimitedTags } from 'api/accounts';
import { styledFilter } from '../StyledComponents';
import { useGetLimitedTags } from '../../hooks';

const StyledInput = styled(M.Input)`
  ${styledFilter}
`;

const noop = () => {};

const checkIfTagStartsWith = (tags: any, substr: string) => {
  return tags.some((tag: string) => {
    return tag.toLowerCase().startsWith(substr.toLowerCase());
  });
};

// TODO: check , into % url params for array params
const TagsPartial = () => {
  const ref: any = useRef();
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [initialSearch, setInitialSearch] = useState(true);
  const currentUser = useUser();
  const accountId = currentUser?.account?.id;

  const handleTouch = useCallback(() => setTouched(true), [setTouched]);
  const { call, result } = useLimitedTags();

  useEffect(() => {
    if (touched) call({ accountId, name: '' });
  }, [accountId, call, touched]);

  const { filters, setFilters } = useSearchState();
  const { tags, isTagNone } = filters;

  const trackEvent = useTrackEvent();

  const {
    tags: apiTags,
    tagsLoading,
    defaultTags,
  } = useGetLimitedTags({ result, selectedTags: tags });

  const callUseLimitedTags = useMemo(
    () =>
      debounce((e: any) => {
        if (!Array.isArray(e)) {
          // only refetch if the value isn't in the tags
          if (!checkIfTagStartsWith(apiTags, e)) call({ accountId, name: e });
          if (touched && apiTags?.length === 0) setTouched(false);
          if (initialSearch) setInitialSearch(false);
        }
      }, 1000),
    [accountId, call, initialSearch, touched, apiTags],
  );

  const none = defaultTags === 'none';

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onChange = useCallback(
    ({
      selectedItems: items,
      isNone,
    }: {
      selectedItems: Array<any>;
      isNone: boolean;
    }) => {
      const t: any = !items.length && isNone ? undefined : items;

      if (items.length > selectedItemsCount && currentUser) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_SELECTED,
          {
            // get last item of array of selected tags which is equal to the latest added tag
            Tags: items.slice(-1).pop(),
            'Selected Filter': 'Tags',
          },
        );
      }
      setSelectedItemsCount(items.length);

      setFilters((prevState: any) => ({
        ...prevState,
        tags: t,
        isTagNone: isNone,
      }));
    },
    [selectedItemsCount, currentUser, setFilters, trackEvent],
  );

  const handleKeyPressEvent = useCallback(
    e => {
      if (e.keyCode === 13) call({ accountId, name: '' });
    },
    [accountId, call],
  );

  useEffectAfterInitialized(() => {
    const input: any = document.getElementById('tag-filter-multi-select-input');
    input?.addEventListener('keypress', handleKeyPressEvent);
  });

  useEffectAfterInitialized(() => {
    if (tags !== undefined) {
      dispatch(updateSearchParams({ tags }));
      if (tags.length === 0) call({ accountId, name: '' });
    } else if (isTagNone) {
      dispatch(updateSearchParams({ tags: 'none' }));
      call({ accountId, name: '' });
    } else if (tags === undefined && isTagNone === undefined) {
      ref?.current?.clearAll();
    }
  }, [tags, dispatch, isTagNone]);

  const placeholder =
    Array.isArray(apiTags) && !apiTags?.length
      ? 'You have no reports with tags'
      : 'Search for tags';

  const newTags = Array.from(new Set(apiTags?.concat(tags || [])));

  const newDefault = Array.from(
    new Set((Array.isArray(tags) ? tags : [])?.concat(defaultTags || [])),
  );

  return (
    <div data-testid='partial-tags-filter'>
      {(newTags?.length > 0 || !initialSearch) &&
        defaultTags &&
        !tagsLoading && (
          <M.TagFilter
            onInputValueChange={callUseLimitedTags}
            tags={newTags}
            selectedItems={tags || []}
            initialSelectedItems={none ? [] : newDefault}
            none={none}
            // TODO fix this in Mastodon
            onNoneChange={noop}
            onChange={onChange}
            ref={ref}
            maxItems={25}
            wrapText
          />
        )}
      {!newTags?.length && !defaultTags?.length && initialSearch && (
        <>
          <StyledInput
            data-testid='load-tags-input'
            labelText='Tags'
            onClick={handleTouch}
            disabled={touched}
            placeholder={placeholder}
            id='tags-input-loader'
          />
          {tagsLoading && (
            <M.LoadingInline description='Loading tags for your account...' />
          )}
        </>
      )}
    </div>
  );
};

export default TagsPartial;
