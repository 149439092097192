import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({
  redirectTo = '/',
  component: Component,
  authorized,
  ...rest
}) => {
  const customRender = useCallback(
    props =>
      authorized ? <Component {...props} /> : <Redirect to={redirectTo} />,
    [authorized, Component, redirectTo],
  );
  return <Route {...rest} render={customRender} />;
};

AuthRoute.propTypes = {
  redirectTo: propTypes.string,
  component: propTypes.elementType.isRequired,
  // authorized={hasPermission(currentUser, 'read_reports')}
  authorized: propTypes.bool,
};

AuthRoute.defaultProps = {
  redirectTo: '/',
  authorized: true,
};

export default AuthRoute;
