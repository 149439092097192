/* eslint-disable react/display-name */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { UsersTableCell } from './UsersStyledComponents';

class UserStatusCell extends PureComponent {
  static defaultProps = {};

  render() {
    const { user } = this.props;
    const never = 'never';
    return (
      <UsersTableCell>
        {user && user.last_seen === never && (
          <M.StatusBadge label='invite sent' status='eligible' />
        )}
      </UsersTableCell>
    );
  }
}

UserStatusCell.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserStatusCell;
