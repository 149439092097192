import React, { useCallback } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { useHistory, useLocation } from 'react-router-dom';

export const StyledLink = styled.a`
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid ${colors.uiOrange200};
  background: ${colors.uiOrange100};
  color: ${colors.uiGrey900};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  width: 42px;
`;

export const HiddenIconWrapper = styled.div`
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  width: 42px;
`;

export const SignInLink = ({ children }: { children: string }) => {
  const trackEvent = useTrackEvent();

  const onClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_CHECKR_USER_ACCOUNT_SIGNED_IN, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    window.location.href = '/login';
  }, [trackEvent]);
  return <StyledLink onClick={onClick}>{children}</StyledLink>;
};

export const ContactUsLink = ({ children }: { children: string }) => {
  const onClick = useCallback(() => {
    window.location.href = 'https://checkr.com/contact';
  }, []);
  return <StyledLink onClick={onClick}>{children}</StyledLink>;
};

export const DashboardLink = ({ children }: { children: string }) => {
  const onClick = useCallback(() => {
    window.location.href = '/login';
  }, []);
  return <StyledLink onClick={onClick}>{children}</StyledLink>;
};

export const EditLink = ({ children }: { children: string }) => {
  const history = useHistory();
  const location = useLocation();
  const onClick = useCallback(() => {
    history.push({
      pathname: '/signup/candidate-info',
      search: location.search,
    });
  }, [history, location.search]);
  return <StyledLink onClick={onClick}>{children}</StyledLink>;
};
