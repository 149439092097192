import { AddOnsType, BasePackage } from 'components/Signup/types';
import { createContext, useContext } from 'react';
import { Candidate } from '../../components/Signup/types';

export type ContextType = {
  setAddOns: React.Dispatch<React.SetStateAction<any>>;
  addOns: Record<string, Array<AddOnsType>>;
  setSelectedPackage: React.Dispatch<React.SetStateAction<any>>;
  selectedPackage: BasePackage;
  candidates: Array<Candidate>;
  setCandidates: React.Dispatch<React.SetStateAction<any>>;
  workLocation: {
    state?: any;
    city?: any;
    country?: any;
  };
  setWorkLocation: React.Dispatch<React.SetStateAction<any>>;
};

const SignupPageContext = createContext<ContextType>({
  setAddOns: () => {},
  addOns: {
    checkrdirect_basic_plus_criminal: [],
    checkrdirect_essential_criminal: [],
    checkrdirect_professional_criminal: [],
    checkrdirect_international_basic_plus: [],
    checkrdirect_international_professional: [],
    checkrdirect_international_professional_plus: [],
  },
  selectedPackage: 'checkrdirect_essential_criminal',
  setSelectedPackage: () => {},
  candidates: [],
  setCandidates: () => {},
  workLocation: {
    country: undefined,
    city: undefined,
    state: undefined,
  },
  setWorkLocation: () => {},
});

export default SignupPageContext;

export const useSignupPageContext = () => useContext(SignupPageContext);
