import React from 'react';
import { Redirect, Switch, useLocation, matchPath } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import { GenericObject, hasPermission } from '@dashboard-experience/utils';
import AuthRoute from 'utils/AuthRoute';
import LogsPage from './LogsPage';

enum LegacyContext {
  api_logs = 'api',
  webhook_logs = 'webhook',
}

const LogsRoutes = () => {
  const { pathname } = useLocation();
  const user = useUser();

  const match = (matchPath(pathname, { path: '/:context' }) ||
    {}) as GenericObject;
  const withLogIdMatch = (matchPath(pathname, { path: '/:context/:logId' }) ||
    {}) as GenericObject;
  const logsMatch = (matchPath(pathname, '/logs/:context') ||
    {}) as GenericObject;

  // support for old routes
  const hasLegacyMatch = ['/api_logs', '/webhook_logs'].some(url =>
    match?.url?.includes(url),
  );

  if (hasLegacyMatch) {
    if (withLogIdMatch?.params?.logId) {
      return (
        <Redirect
          // @ts-ignore
          to={`/logs/${LegacyContext[withLogIdMatch.params.context]}/${
            withLogIdMatch.params.logId
          }`}
        />
      );
    }
    // @ts-ignore
    return <Redirect to={`/logs/${LegacyContext[match.params.context]}`} />;
  }

  if (['/logs', '/logs/'].some(url => pathname === url)) {
    return <Redirect to='/logs/api' />;
  }
  const { context } = logsMatch.params;

  return (
    <Switch>
      <AuthRoute
        exact
        path='/logs/:context'
        component={LogsPage}
        authorized={hasPermission(user, `read_${context}_logs`)}
      />
      <AuthRoute
        exact
        path='/logs/:context/:logId'
        component={LogsPage}
        authorized={hasPermission(user, `read_${context}_logs`)}
      />
    </Switch>
  );
};

export default LogsRoutes;
