const updateIndividualizedAssessmentTransitions = {
  FairChanceForm: {
    transitions: {
      next: 'PreviewEmail',
    },
  },
  PreviewEmail: {
    transitions: {
      previous: 'FairChanceForm',
      next: 'Confirm',
    },
  },
  Confirm: {
    transitions: {
      previous: 'PreviewEmail',
    },
  },
};

export default updateIndividualizedAssessmentTransitions;
