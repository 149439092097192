import React, { useCallback, useMemo, useState } from 'react';
import { getDecisionAliases, CurrentUser } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  useTrackEvent,
  CANDIDATE_SEARCH_DROPDOWN_NAMES,
} from 'utils/analytics';
import { onSelectChange, AnalyticsProps } from '../helpers';
import { scrollToTop } from '../../../../utils';
import { REPORT_STATUS_HELP_CENTER_URL } from '../../constants';
import { StatusModalContent, StyledBaseFilter } from '../StyledComponents';

const Assessment = () => {
  const currentUser: CurrentUser = useUser();
  const decisionAliases = useMemo(
    // @ts-ignore - ignoring, might need update in utils to make report optional
    () => getDecisionAliases({ currentUser }),
    [currentUser],
  );
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const translationKey = 'filterTooltips.assessmentFilterDefinitions';
  const labelText = t(`${translationKey}.label`);

  const trackEvent = useTrackEvent();

  const trackAnalyticsObject: AnalyticsProps = {
    propertyName: CANDIDATE_SEARCH_DROPDOWN_NAMES.ASSESSMENT,
    trackEvent,
  };

  const showModal = useCallback((): void => {
    setModalOpen(true);
    scrollToTop();
  }, []);

  const hideModal = useCallback((): void => {
    setModalOpen(false);
  }, []);

  const tooltipText = (
    <StatusModalContent>
      <strong>{t(`${translationKey}.clear.title`)}: </strong>
      {t(`${translationKey}.clear.definition`)} <br />
      <strong>{t(`${translationKey}.eligible.title`)}: </strong>
      {t(`${translationKey}.eligible.definition`)} <br />
      <strong>{t(`${translationKey}.review.title`)}: </strong>
      {t(`${translationKey}.review.definition`)} <br />
      <strong>{t(`${translationKey}.escalated.title`)}: </strong>
      {t(`${translationKey}.escalated.definition`)} <br />
      <strong>{t(`${translationKey}.processing.title`)}: </strong>
      {t(`${translationKey}.processing.definition`)} <br />
      <strong>{t(`${translationKey}.legacy_clear.title`)}: </strong>
      {t(`${translationKey}.legacy_clear.definition`)} <br />
      <strong>{t(`${translationKey}.legacy_consider.title`)}: </strong>
      {t(`${translationKey}.legacy_consider.definition`)} <br /> <br />
      <a
        href={REPORT_STATUS_HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'underline' }}
      >
        {t('filterTooltips.continueReadingLink')}...
      </a>
    </StatusModalContent>
  );

  const labelHTML = (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ marginRight: '4px' }}>{labelText}</span>
      <M.Icon icon='InformationFilled' onClick={showModal} />
    </span>
  );

  return (
    <>
      <StyledBaseFilter
        component='AssessmentFilter'
        param='assessment'
        onChange={onSelectChange}
        decisionAliases={decisionAliases}
        labelText={labelHTML}
        trackAnalyticsObject={trackAnalyticsObject}
      />
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.ModalHeader closeModal={hideModal}>
          <h2>{t(`${translationKey}.modalHeading`)}</h2>
        </M.ModalHeader>
        <M.ModalBody>{tooltipText}</M.ModalBody>
      </M.ComposedModal>
    </>
  );
};

export default Assessment;
