import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  InternationalAdverseMediaSearch as MediaSearch,
  ReportProps,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import { orderByKey } from '@dashboard-experience/utils';
import { STATUS_ORDER } from 'Constants';
import InternationalAdverseMediaSearch from './InternationalAdverseMediaSearch';

const InternationalAdverseMediaSearches: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_adverse_media_searches } = report;

  const searches: false | MediaSearch[] = useMemo(
    () =>
      !!international_adverse_media_searches &&
      (orderByKey(
        international_adverse_media_searches,
        'status',
        STATUS_ORDER,
      ) as MediaSearch[]),
    [international_adverse_media_searches],
  );

  return searches && searches.length > 0 ? (
    <M.Screening
      title='International Adverse Media Searches'
      statusType={reportStatusType}
      screening={searches}
      id={SCREENING_TITLES.international_adverse_media_searches.id}
    >
      {searches.map((search: any, i: number) => (
        <React.Fragment key={search.id}>
          <InternationalAdverseMediaSearch
            search={search}
            statusType={reportStatusType}
          />
          {i < searches.length - 1 && <M.Divider />}
        </React.Fragment>
      ))}
    </M.Screening>
  ) : null;
};

export default InternationalAdverseMediaSearches;
