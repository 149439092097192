import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import isEmpty from 'lodash/isEmpty';
import { uniq } from 'lodash';
import { Context } from 'components/Packages/context';
import SelectGeo from '../OrderBackgroundCheck/GetStarted/DropdownSelections/SelectGeo';
import SelectLocation from '../OrderBackgroundCheck/GetStarted/DropdownSelections/SelectLocation';
import { useOrderBackgroundCheckContext } from '../OrderBackgroundCheck/Context';

const Header = styled.div`
  color: ${colors.bgPrimaryDark} !important;
  width: 744px;
  height: 42px;
  flex-shrink: 0;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const LocationContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const EmailContainer = styled(M.TagsInput)`
  height: 68px !important;
  border: 1px solid ${colors.uiGrey200} !important;
  overflow-y: auto !important;
  max-width: 760px;
`;

const LinkStyled = styled(M.Link)`
  color: ${colors.bgPrimaryDark} !important;
  text-decoration: underline;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;
const EmailHeader = styled.div`
  color: ${colors.bgPrimaryDark} !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 12px;
`;

const addKeys = [9, 13, 32, 186, 188];

type Props = {
  segmentation_enabled: boolean;
  hierarchy_present: boolean;
  geos_required: boolean;
  goBackClicked: boolean;
  emails: string[];
};

const GetStarted: React.FC<Props> = ({
  segmentation_enabled,
  hierarchy_present,
  geos_required,
  goBackClicked,
  emails,
}) => {
  const { state } = useOrderBackgroundCheckContext();
  const { geos } = useContext(Context);

  const emailInputProps = {
    placeholder: '',
  };

  const countries: string[] = useMemo(
    () =>
      uniq(
        geos?.geos
          .filter((geo: any) => geo.country)
          .map((geo: any) => geo.country)
          .concat(['US']),
      ),
    [geos],
  );

  const showWorkLocationHeader = () => {
    if (!isEmpty(geos?.geos)) {
      return false;
    }
    return (
      !isEmpty(state.geo) || !isEmpty(state.location) || !isEmpty(state.node)
    );
  };

  const showGeos = !segmentation_enabled && !isEmpty(geos?.geos);

  return (
    <div>
      {!showWorkLocationHeader() && (
        <Header>
          Review your candidate’s work location. If it differs from the
          employer’s location, we recommend choosing the one with the strictest{' '}
          <LinkStyled
            data-testid='reporting-requirements-link'
            href='https://help.checkr.com/hc/en-us/articles/360000739988'
            target='_blank'
          >
            reporting requirements
          </LinkStyled>
          .
        </Header>
      )}
      <LocationContainer>
        {showGeos && (
          <SelectGeo
            segmentation_enabled={segmentation_enabled}
            geos={geos?.geos}
            geos_required={geos_required}
          />
        )}

        <SelectLocation
          countries={countries || {}}
          segmentation_enabled={segmentation_enabled}
          hierarchy_present={hierarchy_present}
          goBackClicked={goBackClicked}
        />
      </LocationContainer>
      <EmailHeader data-testid='email-box-header'>
        Checkr will send a background check invitation link to your candidate at
        this email address.
      </EmailHeader>
      <EmailContainer
        onlyUnique
        addOnBlur
        addOnPaste
        addKeys={addKeys}
        tags={emails}
        disabled
        inputProps={emailInputProps}
        className='email-box'
      />
    </div>
  );
};

export default GetStarted;
