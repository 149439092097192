import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const TitleSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  padding-left: 1em;

  color: ${colors.brandNavy4};
`;

export type Props = {
  text: string;
};

export const Title: React.FC<Props> = ({ text }) => {
  return (
    <M.Container.Col>
      <TitleSpan data-testid='assess-v2-ui-guideline-quickstart-table-category-title'>
        {text}
      </TitleSpan>
    </M.Container.Col>
  );
};

export default Title;
