import * as codes from 'modules/assess/api/category-code-set/codes';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Namespace } from 'modules/assess/api';
import { ID } from 'modules/id';
import { toastError } from 'actions';
import { useDispatch } from 'react-redux';
import { CategoryCode } from 'modules/assess/models/mvr/category-code';
import { CollectionResults } from 'modules/core-types/api-core';

export const useGetAll = (categorySetId?: ID, versionId?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const key: AnyQueryKey = ['assess', namespace, categorySetId, versionId];

  const getAll = (): Promise<CollectionResults<CategoryCode>> => {
    if (!categorySetId || !versionId) {
      return Promise.reject();
    }

    return codes.getAll(categorySetId, versionId);
  };

  return useQuery(key, getAll, { refetchOnWindowFocus: false });
};

export const useUpdate = (categorySetId?: ID, versionId?: ID) => {
  const namespace = useNamespace() || Namespace.mvr;
  const request = (updateParams: codes.UpdateParams) => {
    return codes.patch(
      categorySetId,
      versionId,
      updateParams.code,
      updateParams.categoryId,
    );
  };

  const dispatch = useDispatch();

  const [call, result] = useMutation(request, {
    onSuccess: (data: CategoryCode) => {
      queryCache.invalidateQueries(
        ({ queryKey: [ns, entity, type, instance] }) => {
          return (
            (ns === namespace && !entity) ||
            (type === 'ccs' && instance === categorySetId) ||
            (type === 'ccsv' && instance === 'draft')
          );
        },
      );
    },
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    call,
    result,
  };
};

export const useBatchUpdate = (categorySetId?: ID, versionId?: ID) => {
  const request = (params: codes.BatchUpdateParams) => {
    return codes.batchUpdate(params, categorySetId, versionId);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        ({ queryKey: [domain, ns, type, instance] }) => {
          return (
            (domain === 'assess' && ns === Namespace.mvr) ||
            (type === 'ccs' && instance === categorySetId) ||
            (type === 'ccsv' && instance === 'draft')
          );
        },
      );
    },
  });

  return {
    call,
    result,
  };
};
