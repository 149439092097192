import React from 'react';
import styled from 'styled-components';
import * as Images from '../../../assets/benefits';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  align-items: center;
  justify-content: center;
`;

const AuthorLogo = styled.img`
  width: 69px;
  height: 41px;
`;

const AuthorQuote = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const AuthorName = styled.span`
  font-weight: bold;
  margin-bottom: 0.4rem;
`;

const AuthorLogoImage = Images.AngiQuote;

type Props = {
  author: any;
};

const Author: React.FC<Props> = ({ author }) => {
  const { name, title } = author;

  return (
    <Container>
      <AuthorLogo src={AuthorLogoImage} alt={AuthorLogoImage} />
      <AuthorQuote>
        <AuthorName>{name}</AuthorName>
        {title}
      </AuthorQuote>
    </Container>
  );
};

export default Author;
