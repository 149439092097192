import React, { useState, useCallback } from 'react';
import * as Ruleset from 'modules/assess/ui/ruleset/create';
import { useReadOnly } from 'modules/assess/ui/hooks';
import Button from './Button';
import TooltipButton from './TooltipButton';

type Props = {
  disabled?: boolean;
};

export const AddButton: React.FC<Props> = ({ disabled }) => {
  const [open, show] = useState(false);

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);
  const readonly = useReadOnly() || disabled;
  const CustomButton = disabled ? TooltipButton : Button;

  return (
    <>
      <CustomButton disabled={readonly} onClick={handleClick} />
      <Ruleset.Create open={open} onClose={handleClose} />
    </>
  );
};

export default AddButton;
