/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { GenericObject } from '@dashboard-experience/utils';
import { Client } from 'api';
import { Invitation } from 'types/report/invitation';
import { v4 as uuid } from 'uuid';
import { CANDIDATE_PATH, PATH } from './constants';

export type CandidateContactInfo = {
  email: string;
  phone_number: string;
  first_name: string;
};

export type CreateCandidateParams = {
  emails: string;
  package: string;
  geo_id?: string;
  program_id?: string;
  permissible_purpose: string;
  node?: string;
  work_locations: Array<any>;
  invited_from?: string;
  billing_entity?: string;
  invitation_parameters?: Array<CandidateContactInfo>;
};

export type NewCandidateInvitationParams = {
  candidate_id: string;
  package: string;
  node?: string;
  work_locations?: Array<any>;
  permissible_purpose?: string;
};

export const createCandidate = (
  params: CreateCandidateParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(`${CANDIDATE_PATH}`, params, {
    headers: { 'Idempotency-Key': uuid() },
  });
};

export const cancelInvitation = (
  invitationId: string,
): Promise<GenericObject> => {
  return Client.delete(`${PATH}/${invitationId}`);
};

export const newCandidateInvitation = (params: any): Promise<Invitation> => {
  return Client.post(`${PATH}`, params, {
    headers: { 'Idempotency-Key': uuid() },
  });
};
