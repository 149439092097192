import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { formatDateIfDate, GenericObject } from '@dashboard-experience/utils';

type CDLISPanelProps = {
  cdlis: GenericObject;
  title: string;
};

const CDLISPanel: React.FC<CDLISPanelProps> = ({ cdlis, title }) => {
  return (
    <M.SubScreening title={title}>
      <M.Screening.Row>
        {cdlis?.driver_licenses.map(
          (driverLicense: GenericObject, idx: number) => {
            const driverLicenseItems = [
              {
                itemKey: 'Driver Name',
                itemValue: `${driverLicense.driver.name.first_name} ${driverLicense.driver.name.middle_name} ${driverLicense.driver.name.last_name} ${driverLicense.driver.name.suffix}
              `,
              },
              {
                itemKey: 'DOB',
                itemValue: formatDateIfDate(driverLicense.driver.dob) || '-',
              },
              {
                itemKey: 'License Number',
                itemValue: driverLicense.number,
              },
              {
                itemKey: 'License State',
                itemValue: driverLicense.state,
              },
              {
                itemKey: 'Current License',
                itemValue: driverLicense.current.toString(),
              },
            ];

            const items = driverLicenseItems;
            return (
              <M.KeyValueList
                data-testid='cdlis'
                key={`${driverLicense}-${idx + 1}`}
                items={items}
              />
            );
          },
        )}
      </M.Screening.Row>
    </M.SubScreening>
  );
};

export default CDLISPanel;
