export const MAX = 99;
export const MIN = 0;
export const ARROW_KEY_CODES = [38, 40];

export function isInvalid(
  min: number,
  max: number,
  value?: number | null,
): boolean {
  if (value === undefined || value === null) {
    return false;
  }

  return value > max || value < min;
}
