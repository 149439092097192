import { useTranslation } from 'react-i18next';
import React from 'react';
import { Header } from './HeaderWithTooltip';
import { TableHeader } from './InvoicesStyledComponents';

type Props = {
  header: Header;
  className?: string;
};

const DefaultHeader: React.FC<Props> = ({ header, className, ...props }) => {
  const { t } = useTranslation();
  return (
    <TableHeader className={className} {...props}>
      {t(`headings.${header.name}`)}
    </TableHeader>
  );
};

export default DefaultHeader;
