import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type Props = {
  text?: string;
};

const ListItem = styled.li`
  color: ${colors.brandAqua3};
  font-size: 20px;
  padding-bottom: 10px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandNavy4};
`;

export const Bullet: React.FC<Props> = ({ text, children }) => {
  return (
    <ListItem>
      <Text>
        <Trans>{text}</Trans>
      </Text>
      {children}
    </ListItem>
  );
};

export default Bullet;
