import Operator from 'modules/assess/models/operator';
import FactRuleOptions from '../../fact-options';
import { License } from '../licenses';
import PrivilegeToDrive from '../privilege-to-drive';
import {
  AgeAtIssuedDateRule,
  AgeIsRule,
  CategoryRule,
  ClassificationCodeRule,
  CountRule,
  CurrentRule,
  ExpirationExtendedRule,
  Fact,
  LicenseTypeRule,
  PrivilageToDriveRule,
  RecordType,
  RelativeContextDateInDaysRule,
  RelativeConvictionDateInDaysRule,
  RelativeEndDateInYearsRule,
  RelativeExpirationDateInDaysRule,
  RelativeIssuedDateInDaysRule,
  RelativeHistoryInYearsRule,
  RelativeStartDateInYearsRule,
  RestrictedLicenseStatusRule,
  RestrictionsRule,
  StateRule,
  TypeRule,
  WorkStateRule,
} from '../rules';
import StateCodes from '../state-codes';

export const AGE_AT_ISSUED_DATE: FactRuleOptions<
  AgeAtIssuedDateRule,
  'operator' | 'value'
> = {
  fact: Fact.AGE_AT_ISSUED_DATE,
  options: [
    { operator: Operator.LT, value: 18 },
    { operator: Operator.LT, value: 21 },
    { operator: Operator.LT, value: 23 },
    { operator: Operator.LT, value: 25 },
    { operator: Operator.GT, value: 25 },
  ],
};

export const AGE_IS: FactRuleOptions<AgeIsRule, 'operator' | 'value'> = {
  fact: Fact.AGE_NOW,
  options: [
    { operator: Operator.LT, value: 18 },
    { operator: Operator.LT, value: 21 },
    { operator: Operator.LT, value: 23 },
    { operator: Operator.LT, value: 25 },
    { operator: Operator.GT, value: 25 },
  ],
};

export const CATEGORY: FactRuleOptions<CategoryRule, 'value'> = {
  fact: Fact.CATEGORY,
  operator: Operator.IN,
  options: [],
};

export const CLASSIFICATION_CODE: FactRuleOptions<
  ClassificationCodeRule,
  'value'
> = {
  fact: Fact.CLASSIFICATION_CODE,
  operator: Operator.IN,
  options: [],
};

export const COUNT: FactRuleOptions<CountRule, 'operator' | 'value'> = {
  fact: Fact.COUNT,
  options: [
    { operator: Operator.GT, value: 1 },
    { operator: Operator.GT, value: 2 },
    { operator: Operator.GT, value: 3 },
    { operator: Operator.GT, value: 4 },
    { operator: Operator.GT, value: 5 },
    { operator: Operator.GT, value: 6 },
  ],
};

export const CURRENT: FactRuleOptions<CurrentRule, 'value'> = {
  fact: Fact.CURRENT,
  operator: Operator.EQ,
  options: [{ value: true }, { value: false }],
};

export const EXPIRATION_EXTENDED: FactRuleOptions<
  ExpirationExtendedRule,
  'value'
> = {
  fact: Fact.EXPIRATION_EXTENDED,
  operator: Operator.EQ,
  options: [{ value: true }, { value: false }],
};

export const LICENSE_TYPE: FactRuleOptions<LicenseTypeRule, 'value'> = {
  fact: Fact.LICENSE_TYPE,
  operator: Operator.IN,
  options: Object.values(License).map(value => ({
    value,
  })),
};

export const PRIVILEGE_TO_DRIVE: FactRuleOptions<
  PrivilageToDriveRule,
  'value'
> = {
  fact: Fact.PRIVILEGE_TO_DRIVE,
  operator: Operator.IN,
  options: Object.values(PrivilegeToDrive).map(value => ({
    value,
  })),
};

export const RELATIVE_CONTEXT_DATE_IN_DAYS: FactRuleOptions<
  RelativeContextDateInDaysRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_CONTEXT_DATE_IN_DAYS,
  options: [
    { operator: Operator.LE, value: 365 },
    { operator: Operator.LE, value: 730 },
    { operator: Operator.LE, value: 1095 },
    { operator: Operator.LE, value: 1825 },
    { operator: Operator.LE, value: 2555 },
  ],
};

export const RELATIVE_CONVICTION_DATE_IN_DAYS: FactRuleOptions<
  RelativeConvictionDateInDaysRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_CONVICTION_DATE_IN_DAYS,
  options: [
    { operator: Operator.LE, value: 365 },
    { operator: Operator.LE, value: 730 },
    { operator: Operator.LE, value: 1095 },
    { operator: Operator.LE, value: 1825 },
    { operator: Operator.LE, value: 2555 },
  ],
};

export const RELATIVE_END_DATE_IN_YEARS: FactRuleOptions<
  RelativeEndDateInYearsRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_END_DATE_IN_YEARS,
  options: [
    { operator: Operator.LE, value: 1 },
    { operator: Operator.LE, value: 2 },
    { operator: Operator.LE, value: 3 },
    { operator: Operator.LE, value: 5 },
    { operator: Operator.LE, value: 7 },
  ],
};

export const RELATIVE_EXPIRATION_DATE_IN_DAYS: FactRuleOptions<
  RelativeExpirationDateInDaysRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_EXPIRATION_DATE_IN_DAYS,
  options: [
    { operator: Operator.LE, value: 365 },
    { operator: Operator.LE, value: 730 },
    { operator: Operator.LE, value: 1095 },
    { operator: Operator.LE, value: 1825 },
    { operator: Operator.LE, value: 2555 },
  ],
};

export const RELATIVE_HISTORY_IN_YEARS: FactRuleOptions<
  RelativeHistoryInYearsRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_HISTORY_IN_YEARS,
  options: [
    { operator: Operator.LE, value: 1 },
    { operator: Operator.LE, value: 2 },
    { operator: Operator.LE, value: 3 },
    { operator: Operator.LE, value: 5 },
    { operator: Operator.LE, value: 7 },
  ],
};

export const RELATIVE_ISSUED_DATE_IN_DAYS: FactRuleOptions<
  RelativeIssuedDateInDaysRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_ISSUED_DATE_IN_DAYS,
  options: [
    { operator: Operator.LE, value: 365 },
    { operator: Operator.LE, value: 730 },
    { operator: Operator.LE, value: 1095 },
    { operator: Operator.LE, value: 1825 },
    { operator: Operator.LE, value: 2555 },
  ],
};

export const RELATIVE_START_DATE_IN_YEARS: FactRuleOptions<
  RelativeStartDateInYearsRule,
  'operator' | 'value'
> = {
  fact: Fact.RELATIVE_START_DATE_IN_YEARS,
  options: [
    { operator: Operator.LE, value: 1 },
    { operator: Operator.LE, value: 2 },
    { operator: Operator.LE, value: 3 },
    { operator: Operator.LE, value: 5 },
    { operator: Operator.LE, value: 7 },
  ],
};

export const RESTRICTED_LICENSE_STATUS: FactRuleOptions<
  RestrictedLicenseStatusRule,
  'value'
> = {
  fact: Fact.RESTRICTED_LICENSE_STATUS,
  operator: Operator.EQ,
  options: [{ value: true }, { value: false }],
};

export const RESTRICTIONS: FactRuleOptions<RestrictionsRule, 'value'> = {
  fact: Fact.RESTRICTIONS,
  operator: Operator.AMRI,
};

export const STATE: FactRuleOptions<StateRule, 'value'> = {
  fact: Fact.STATE,
  operator: Operator.IN,
  options: Object.values(StateCodes).map(value => ({
    value,
  })),
};

export const TYPE: FactRuleOptions<TypeRule, 'value'> = {
  fact: Fact.TYPE,
  operator: Operator.IN,
  options: Object.values(RecordType).map(value => ({
    value,
  })),
};

export const WORK_STATE: FactRuleOptions<WorkStateRule, 'value'> = {
  fact: Fact.WORK_STATE,
  operator: Operator.IN,
  options: Object.values(StateCodes).map(value => ({
    value,
  })),
};
