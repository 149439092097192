import { Decision } from 'modules/assess';
import { Initializer } from 'state/utilities/state';
import { Condition } from '../condition';
import { Entity } from '../common/entity';

export type Type = Entity & {
  complex: boolean;
  editing: boolean;
  filters: Array<Condition>;
  initial: boolean;
};

export const initialize: Initializer<Omit<Type, 'id'>> = () => ({
  complex: false,
  conditions: [],
  decision: Decision.REVIEW,
  editing: true,
  filters: [],
  initial: true,
  name: '',
});

export const initializeComplexRule = () => ({
  complex: true,
  conditions: [
    {
      threshold: undefined,
      operator: undefined,
      conditions: [[{ fact: 'object_type', operator: 'in' }]],
    },
  ],
  decision: Decision.REVIEW,
  editing: true,
  filters: [],
  initial: true,
  name: '',
});
