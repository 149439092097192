import React from 'react';
import styled from 'styled-components';
import { Map } from 'modules/assess/models/rules/entity';
import Navigation from 'modules/assess/v2/models/navigation/standard';
import { getDefinedLookbackRules } from 'modules/assess/v2/ui/utils';
import Description from './Description';
import Option from './options';
import Title from './Title';

type Props = {
  rules?: Map;
};

const StyledContainer = styled.div`
  margin-top: 3rem;
`;

export const Container: React.FC<Props> = ({ rules }) => {
  const lookbackRules = Object.values(rules?.lookback || []);
  const definedLookbackRules = getDefinedLookbackRules(lookbackRules);

  return (
    <StyledContainer>
      <Title />
      <Description />
      <Option option={Navigation.Quickstart} rules={rules?.premade} />
      <Option option={Navigation.Lookback} rules={definedLookbackRules} />
      <Option option={Navigation.Repeated} showPremiumBadge />
    </StyledContainer>
  );
};

export default Container;
