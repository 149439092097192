import React, { useCallback } from 'react';
import { Type } from 'modules/assess/models/rules/premade';
import { useDispatch } from 'modules/assess/ui/ruleset/version/store';
import { Actions } from 'modules/assess/ui/state/rules/premade';
import { Quickstart } from 'modules/assess/v2/models/amplitude/guidelines';
import { Row } from 'modules/assess/v2/ui/common/premade/rule';
import { useTrackEvent } from 'modules/assess/v2/ui/hooks';
import ButtonType from '../../ButtonType';
import Button from './Button';

type Props = {
  handleRuleUnselected: (rule: Type) => void;
  row: Row;
  buttonType?: ButtonType;
};

export const Container: React.FC<Props> = ({
  handleRuleUnselected,
  row,
  buttonType,
}) => {
  const dispatch = useDispatch();
  const track = useTrackEvent();

  const handleRuleSelected = useCallback(() => {
    const rule = {
      ...row.rule,
      updated: true,
    };

    dispatch(Actions.Insert.create(rule));
    track(Quickstart.RuleSelected, {
      Guideline: row.rule.code,
    });
  }, [dispatch, row.rule, track]);

  const onRuleSelected = useCallback(() => {
    handleRuleUnselected(row.rule);
  }, [handleRuleUnselected, row.rule]);

  if (row.isSelected) {
    return <Button selected onClick={onRuleSelected} buttonType={buttonType} />;
  }

  return <Button onClick={handleRuleSelected} buttonType={buttonType} />;
};

export default Container;
