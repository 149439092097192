import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import * as Entity from 'modules/assess/models/settings/account';
import Option from './option';

type Props = {
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const StyledContainer = styled.div`
  padding-bottom: 0.5rem;

  #assess-v1-settings-candidate-story-request-options {
    .cds--radio-button__label-text {
      color: ${colors.brandNavy3};
      font-weight: 500;
      font-sieze: 14px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 3.5rem;
`;

const Options: React.FC<Props> = ({ settings, setSettings }) => {
  const current =
    settings?.candidate_stories_request_on_adverse_action_value ||
    Entity.CandidateStoriesRequestOptions.All;
  const disabled = !settings?.candidate_stories_request_on_adverse_action;

  const handleChange = useCallback(
    (selected: Entity.CandidateStoriesRequestOptions) => {
      setSettings((state: Entity.Type) => ({
        ...state,
        candidate_stories_request_on_adverse_action_value: selected,
      }));
    },
    [setSettings],
  );

  return (
    <StyledContainer data-testid='assess-v1-settings-candidate-story-request-options'>
      <Flex id='assess-v1-settings-candidate-story-request-options'>
        <Option
          onChange={handleChange}
          selected={current === Entity.CandidateStoriesRequestOptions.All}
          value={Entity.CandidateStoriesRequestOptions.All}
          disabled={disabled}
        />
        <Option
          onChange={handleChange}
          selected={current === Entity.CandidateStoriesRequestOptions.AutoOnly}
          value={Entity.CandidateStoriesRequestOptions.AutoOnly}
          disabled={
            disabled || !settings?.assess_auto_adverse_action_escalated_reports
          }
        />
        <Option
          onChange={handleChange}
          selected={
            current === Entity.CandidateStoriesRequestOptions.ManualOnly
          }
          value={Entity.CandidateStoriesRequestOptions.ManualOnly}
          disabled={disabled}
        />
        <Option
          onChange={handleChange}
          selected={
            current === Entity.CandidateStoriesRequestOptions.EnabledPackages
          }
          value={Entity.CandidateStoriesRequestOptions.EnabledPackages}
          disabled={disabled}
        />
      </Flex>
    </StyledContainer>
  );
};

export default Options;
