import { Client } from 'api/clients';

// eslint-disable-next-line import/prefer-default-export
export const postCustomerAction = (
  idvId: string,
  action: string,
): Promise<{ [key: string]: any }> => {
  return Client.post(`/identity_verifications/${idvId}/action`, {
    action,
  });
};
