import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Pill = styled.div`
  align-content: flex-start;
  background: ${colors.brandYellow1};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  height: 1.2rem;
  justify-content: center;
  margin: 0 1.2rem;
  padding: 1px 0;
  width: 7rem;

  /* Text formatting */
  color: ${colors.uiGrey700};
  font-size: 0.8rem;
  font-variant: all-small-caps;
  font-weight: bold;
  letter-spacing: 1px;
`;

const Premium: React.FC = () => {
  const { t } = useTranslation('assess:v2');

  const text = t('common.premium').toUpperCase();

  return (
    <Pill
      id='assess-v2-ui-common-components-premium'
      data-testid='assess-v2-premium'
    >
      {text}
    </Pill>
  );
};

export default Premium;
