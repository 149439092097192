import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

type Props = {
  disabled?: boolean;
};

const LabelContainer = styled.div`
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight};
`;

export const Title: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const entry = disabled ? 'disabled' : 'enabled';
  const label = t(`assess:context_dates.title.${entry}`);

  return (
    <LabelContainer data-testid='assess-ruleset-version-context-dates-title'>
      {label}
    </LabelContainer>
  );
};

export default Title;
