import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ChildAbuseRecord as ChildAbuseRecordType } from 'types';

type Props = {
  record: ChildAbuseRecordType;
};

const ChildAbuseRecord: React.FC<Props> = ({ record }) => (
  <M.KeyValueList
    items={[
      record.state && {
        itemKey: 'State',
        itemValue: record.state,
      },
      record.registry && {
        itemKey: 'Registry',
        itemValue: `${record.registry} (See Attached)`,
      },
    ].filter(Boolean)}
  />
);

export default ChildAbuseRecord;
