import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { scrollToTopWithCallback } from 'utils';
import { useRefetchReportOnClose } from './hooks';

type Props = {
  [key: string]: any;
  close?: GenericObject | null;
  next?: GenericObject | null;
  back?: GenericObject | null;
};

const ModalFooter: React.FC<Props> = ({ close, next, back, ...props }) => {
  const handleClose = useRefetchReportOnClose();
  // TODO: refactor once customer is standalone app, no need to scroll then
  const nextAction = !next
    ? null
    : {
        ...next,
        onClick: scrollToTopWithCallback(next.onClick),
      };

  const backAction = !back
    ? null
    : {
        ...back,
        onClick: scrollToTopWithCallback(back.onClick),
      };

  const onClose = { onClick: handleClose };
  return (
    <M.ModalNavigationFooter
      close={{ ...onClose, ...close }}
      next={nextAction}
      back={backAction}
      {...props}
    />
  );
};

export default ModalFooter;
