import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import * as RulesetVersion from 'modules/assess/models/rulesets/version';
import styled from 'styled-components';
import Assignments from '../assignments';

export type Props = {
  isDraft: boolean;
  disabled?: boolean;
  ruleset?: Ruleset.Type;
};

const StyledContainer = styled.div`
  display: flex;
  margin-top: 2em;
`;

export const Content: React.FC<Props> = ({ isDraft, disabled, ruleset }) => {
  const { t } = useTranslation();

  const context = disabled ? 'disabled' : undefined;
  const resource = isDraft ? 'ruleset.version' : 'ruleset';

  const mainWarning = t(`assess:${resource}.delete.main`, {
    context,
    name: ruleset?.name,
    id: ruleset?.id,
    interpolation: { escapeValue: false },
  });

  const warningAssignments =
    !isDraft && ruleset?.assignments?.length
      ? t('assess:ruleset.delete.assignments', { context })
      : '';

  const draft = ruleset?.draft as RulesetVersion.Type;
  const editedBy =
    isDraft && (draft?.updated_by || draft?.updated_at)
      ? t('assess:ruleset.version.delete.last_edit', {
          context,
          edited_by: draft?.updated_by
            ? t('prepositions.by.actor', { actor: draft.updated_by })
            : '',
          edited_on: draft?.updated_at
            ? t('prepositions.on.date', {
                date: new Date(draft.updated_at).toLocaleString(),
                interpolation: { escapeValue: false },
              })
            : '',
          interpolation: { escapeValue: false },
        })
      : '';

  return (
    <div>
      <StyledContainer>
        {mainWarning} {warningAssignments}
      </StyledContainer>
      <StyledContainer>{editedBy}</StyledContainer>
      {!draft ? <Assignments assignments={ruleset?.assignments} /> : null}
    </div>
  );
};

export default Content;
