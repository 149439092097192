import React from 'react';
import * as Entity from 'modules/assess/models/rules/premade';
import { Namespace } from 'modules/assess/api';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/function-component-definition
export function Title({
  rule,
  namespace,
}: {
  rule: Entity.Type;
  namespace: Namespace;
}) {
  const { t } = useTranslation('assess');
  const text = t(`assess:rules.standard.rules.${namespace}.${rule.code}.title`);

  return <span data-testid='assess-rules-standard-table-title'>{text}</span>;
}

export default Title;
