import React from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Checkmark from './svg/Checkmark.svg';

export const Label = styled.div`
  display: flex;
  padding: 8px 16px;
  padding-left: 0px;
  align-items: center;
  height: 37px;
  gap: 8px;
  border-radius: 2px;
`;

const CheckmarkImg = styled.img`
  width: 16px;
  height: 16px;
`;

const Title = styled.span`
  color: ${colors.brandNavy4}
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const OrderedLabel: React.FC<{ dataTestId: string }> = ({ dataTestId }) => {
  return (
    <Label data-testid={`${dataTestId}-ordered-label`}>
      <CheckmarkImg src={Checkmark} alt='Check mark symbol' />
      <Title>Ordered</Title>
    </Label>
  );
};

export default OrderedLabel;
