import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { ID } from 'modules/id';

type Base = Omit<Entity.Type, 'id' | 'created_at' | 'updated_at'>;

type RequestData = Base & {
  id?: ID;
};

type ResponseData = Base & {
  id: ID;
  created_at: string;
  updated_at: string;
};

export function fromAPI(data: ResponseData): Entity.Type {
  const result: Entity.Type = {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  };

  return result;
}

export function toAPI(data: Entity.Type): RequestData {
  return data;
}
