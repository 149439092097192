import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Signature } from 'modules/assess/ui/common';
import { StyledComponents } from 'modules/assess/v2/ui/common';

type Props = {
  setConsent: (value: boolean) => void;
};

const StyledContainer = styled(StyledComponents.SlateContainer)`
  color: ${colors.uiTextPrimaryLight};
  font-size: 16px;
  line-height: 24px;
`;

const Title = styled.div`
  color: ${colors.brandNavy3};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Paragraph = styled.div`
  margin-bottom: 1.5rem;
  margin-right: 23%;
`;

const Ul = styled.ul`
  margin-right: 23%;
`;

const Li = styled.li`
  display: flex;
  &:before {
    color: ${colors.brandAqua3};
    content: '•' !important;
    font-size: 2em;
    padding-right: 6px;
  }
`;

const StyledSignature = styled.div`
  margin-top: 1.5rem;
`;

export const Container: React.FC<Props> = ({ setConsent }) => {
  const { t } = useTranslation('assess:v2');
  const translations: any = t('review_and_apply.consent', {
    returnObjects: true,
  });

  const clauses = translations.clauses.map((clause: any) => (
    <Li key={clause}>
      <Trans>{clause}</Trans>
    </Li>
  ));
  const basePath = 'assess-v2-ui-guideline-review-and-apply-consent-container';
  const titleId = `${basePath}-title`;
  const descriptionId = `${basePath}-description`;
  const acknowledgeId = `${basePath}-acknowledge`;
  const clausesId = `${basePath}-clauses-ul`;

  return (
    <StyledContainer>
      <Title data-testid={titleId}>{translations.title}</Title>
      <Paragraph data-testid={descriptionId}>
        <Trans>{translations.description}</Trans>
      </Paragraph>
      <Paragraph data-testid={acknowledgeId}>
        <Trans>{translations.acknowledge}</Trans>
      </Paragraph>
      <Ul data-testid={clausesId}>{clauses}</Ul>
      <StyledSignature>
        <Signature confirm={setConsent} />
      </StyledSignature>
    </StyledContainer>
  );
};

export default Container;
