import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

type Props = {
  onClickBulkEdit: (event?: SyntheticEvent) => void;
};

const Container = styled.div`
  display: flex;
  padding-right: 1rem;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const Text = styled.span`
  color: ${colors.brandAqua4};
  font-size: 14px;
  font-height: 5px;
  font-weight: 500;
  margin-right: 0.5rem;
  margin-top: 0.15rem;
`;

const StyledIcon = styled(M.Icon)`
  color: ${colors.brandAqua4};
  top: 0.4em;
`;

const BulkEditLink: React.FC<Props> = ({ onClickBulkEdit }) => {
  const { t } = useTranslation('assess:v2');
  const text = t(`lite.time_since_offense.table.bulk_edit_link`);
  const baseId = 'assess-v2-ui-lite-times-since-offense-table-bulk-edit-link';
  const iconId = `${baseId}-icon`;
  const textId = `${baseId}-text`;

  return (
    <Container onClick={onClickBulkEdit} data-testid={baseId}>
      <Text data-testid={textId}>{text}</Text>
      <StyledIcon icon='Edit' size='20' data-testid={iconId} />
    </Container>
  );
};

export default BulkEditLink;
