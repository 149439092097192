import React from 'react';
import { FieldComponentProps } from '../../types';

const DriversLicense: React.FC<FieldComponentProps> = ({
  candidate,
  testid,
}) => {
  const licenseNumber = candidate?.driver_license_number;
  const licenseState = candidate?.driver_license_state;
  const previousLicenseNumber = candidate?.previous_driver_license_number;
  const previousLicenseState = candidate?.previous_driver_license_state;

  if (licenseNumber)
    return <div data-testid={testid}>{`${licenseNumber} ${licenseState}`}</div>;

  const formattedPreviousDL =
    previousLicenseNumber && previousLicenseState
      ? `${previousLicenseNumber} ${previousLicenseState}`
      : previousLicenseNumber;

  return <div data-testid={testid}>{formattedPreviousDL}</div>;
};

export default DriversLicense;
