import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import SegmentsContainer from 'containers/SegmentsContainer';
import { useUser } from 'context/CurrentUser';
import { SegmentsProvider } from 'components/Segments/SegmentsContext';

type Props = {
  name: string;
  id: string;
  hideModal: () => void;
  open: boolean;
};

const AssignNodes: React.FC<Props> = ({ name, id, open, hideModal }) => {
  const currentUser = useUser();

  return (
    <M.ComposedModal open={open} hideModal={hideModal} onClose={hideModal}>
      <M.ModalHeader closeModal={hideModal}>
        <h2>Brand - {name}</h2>
      </M.ModalHeader>
      <SegmentsProvider>
        <SegmentsContainer
          parentContext='brands'
          parentId={id}
          accountId={currentUser.account.id}
          closeModal={hideModal}
        />
      </SegmentsProvider>
    </M.ComposedModal>
  );
};

export default AssignNodes;
