import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from './common';

const Title: React.FC = () => {
  const { t } = useTranslation();
  const title = t(`fairChance:fair_chance:title`);
  return (
    <Section divider data-testid='fair-chance-title'>
      <h2>{title}</h2>
    </Section>
  );
};

export default Title;
