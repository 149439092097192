import React from 'react';
import { Namespace } from 'modules/assess/api';
import { BackButton as Button } from 'modules/assess/ui/common';
import { ID } from 'modules/id';

type Props = {
  namespace?: Namespace;
  ruleset?: ID;
};

export const BackButton: React.FC<Props> = ({ namespace, ruleset }) => {
  const backUrl = `/assess/${namespace}/rulesets/${ruleset}/versions/draft`;

  return <Button cancel url={backUrl} />;
};

export default BackButton;
