import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { PathName, usePath } from 'modules/assess/ui/router/mvr/paths';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  item?: Entity;
};

export const Item: React.FC<Props> = ({ item }) => {
  const path = usePath(PathName.categories, item?.id);

  if (!item) {
    return null;
  }

  return (
    <Link to={path}>
      <strong>{item.name}</strong>
    </Link>
  );
};

export default Item;
