import React from 'react';
import CourtClosures from './CourtClosures';

const style = { display: 'block' };

const Overdue: React.FC<{}> = () => {
  return (
    <div>
      <p>
        <strong>
          Why is the county search still pending past the original estimated
          completion date?
        </strong>
        <span style={style}>
          We strive to provide you the best estimate possible given historical
          completion rates of similar searches. However, some counties are
          subject to unpredictable timelines due to manual search processes and
          court availability.
        </span>
      </p>

      <p>
        <strong>What should I do next?</strong>
        <span style={style}>
          Our researchers are working as quickly as possible. There are no
          additional actions required on your behalf at this time and we will
          update this search as soon as we have more information.
        </span>
      </p>

      <CourtClosures />
    </div>
  );
};

export default Overdue;
