import React from 'react';
import { useTranslation } from 'react-i18next';

import { Kind } from 'modules/assess/models/assignables';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import T from 'components/Table';
import { Selections } from './types';

type Props = {
  assignments?: Array<Assignment.Type>;
  selections?: Selections;
  onSelect?: (assignee: string, selected: boolean) => void;
  ruleset?: Ruleset.Type;
};

const keys = ['type', 'id', 'name', 'ruleset'];

export const Table: React.FC<Props> = ({
  assignments = [],
  selections = {},
  onSelect = () => {},
  ruleset,
}) => {
  const { t } = useTranslation('assess');

  const headers = keys.map(key => ({
    key,
    header: t(`assess:ruleset.assign.table.${key}`),
  }));

  const rows = assignments.map(assignment => {
    const assigned =
      typeof assignment.ruleset === 'string'
        ? { id: assignment.id, name: assignment.ruleset }
        : assignment.ruleset;

    const type = t(
      `assess:ruleset_assignments.${assignment.type}.noun`,
      assignment.type,
    );

    return {
      ...assignment,
      ruleset: assigned?.name,
      type,
      isSelected: !!selections[assignment.id],
      disabled:
        assignment.type === Kind.ACCOUNT && assigned?.id === ruleset?.id,
    };
  });

  return (
    <div data-testid='assess-ruleset-assign-table'>
      <T
        headers={headers}
        rows={rows}
        selections={selections}
        onSelect={onSelect}
      />
    </div>
  );
};

export default Table;
