import React from 'react';

import { EditableSection as AmplitudeEditableSection } from 'components/Packages/Amplitude/analytics';
import { useOrderBackgroundCheckContext } from '../../Context';

import CustomerInputCSV from './CustomerInputCSV';
import InvitationCSV from './InvitationCSV';
import SmsInvitationManualEntry from './SmsInvitationManualEntry';

export type CandidateInfoSectionProps = {
  handleGetStartedEditClick: () => void;
  setSubmitClicked: (clicked: boolean) => void;
  trackEditClicks: (section: AmplitudeEditableSection) => void;
  handleEdit: (path: string, title: string) => void;
};

const CandidateInfoSection: React.FC<CandidateInfoSectionProps> = ({
  handleGetStartedEditClick,
  setSubmitClicked,
  trackEditClicks,
  handleEdit,
}) => {
  const { state } = useOrderBackgroundCheckContext();
  const { providerType, manualBulkUploadType, csv } = state;

  if (providerType === 'CANDIDATE') {
    if (csv.fileDisplayName) {
      return (
        <InvitationCSV handleGetStartedEditClick={handleGetStartedEditClick} />
      );
    }

    return (
      <SmsInvitationManualEntry
        handleGetStartedEditClick={handleGetStartedEditClick}
      />
    );
  }

  if (manualBulkUploadType === 'MULTIPLE') {
    return <CustomerInputCSV handleEdit={handleEdit} />;
  }

  return null;
};

export default CandidateInfoSection;
