import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';

import ModalBody from './body';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
`;

const Body = styled(ModalBody)`
  min-height: 9.5rem;
`;

const base_path = `${exam_reorder_modal_body}.states.loading`;

const Loading = () => {
  const { t } = useTranslation();

  return (
    <Body>
      <FlexContainer>
        <div>
          <M.LoadingSpinner withOverlay={false} />
        </div>
        <div>
          <h2>{t(`${base_path}.title`)}</h2>
          <p>{t(`${base_path}.description`)}</p>
        </div>
      </FlexContainer>
    </Body>
  );
};

export default Loading;
