import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NameDialog from 'modules/assess/ui/common/name-dialog';
import { Action } from 'modules/assess/ui/common/base-dialog';
import { Entity } from 'modules/assess/models/mvr/category-code-set';
import { useNamespacedRoute } from 'modules/assess/ui/router/context';
import { useCreate } from '../api';

export type Props = {
  ccs?: Entity;
  open: boolean;
  onClose: () => void;
};

export const CreateDialog: React.FC<Props> = ({ ccs, open, onClose }) => {
  const history = useHistory();
  const { t } = useTranslation('');
  const initialName = ccs ? `${ccs.name} ${t('nouns.copy')}` : '';
  const [name, setName] = useState(initialName);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const create = useCreate();
  const path = useNamespacedRoute('categories');
  const action = ccs ? Action.duplicate : Action.create;

  const handleOnClose = useCallback(() => {
    setName(initialName);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    create.call({ name, source: ccs?.id });
  }, [ccs?.id, create, name]);

  useEffect(() => {
    setSubmitDisabled(name === '');

    switch (create.result.status) {
      case 'idle':
        return;
      case 'loading':
        setSubmitDisabled(true);
        return;
      case 'error':
        setSubmitDisabled(false);
        return;
    }

    const {
      data: { id },
    } = create.result;
    const new_ccs_path = `${path}/${id}`;
    handleOnClose();
    history.push(new_ccs_path);
  }, [create.result, create.result.status, history, path]);

  return (
    <NameDialog
      action={action}
      data-testid='assess-mvr-category-sets-create'
      open={open}
      onClose={handleOnClose}
      onChange={setName}
      onSubmit={handleSubmit}
      name={ccs?.name}
      submitDisabled={submitDisabled}
      type='category_set'
    />
  );
};

export default CreateDialog;
