import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Flex from 'components/Flex';
import { US_STATES_NAMES } from 'components/Packages/OrderBackgroundCheck/GetStarted/StatesOfUS';
import {
  Icon,
  InitialCreditReportValidations,
} from 'components/AddScreenings/shared/ModalComponents';
import Trendsource from '../../shared/svg/Trendsource.svg';

const Container = styled.div`
  width: 576px;
  .cds--text-input .cds--list-box__field {
    border-bottom: 1px solid ${colors.uiGrey300} !important;
  }
`;

const TypicalTime = styled.div`
  display: flex;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandSlate7};
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.brandSlate2};
  margin-bottom: 20px;
  margin-top: 10px;
`;

const EmployerRepresentative = styled.div`
  margin-bottom: 26px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: ${colors.brandNavy4};
  margin-bottom: 8px;
`;

const SubHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.brandNavy4};
  margin-top: 25px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandSlate6} !important;
  margin-bottom: 17px;
`;

const MarginRight24 = styled.div`
  margin-right: 24px;
`;

const MarginBottom15 = styled.div`
  margin-bottom: 15px;
`;

const TextInput = styled(M.TextInput)`
  width: 276px;
`;

const FullLengthTextInput = styled(M.TextInput)`
  width: 576px;
  margin-bottom: 17px !important;
`;

const AddressTextInput = styled(M.TextInput)`
  width: 176px;
`;

const StateDropdown = styled(M.Dropdown)`
  width: 176px;
`;

const TrendsourceContainer = styled.div`
  display: flex;
  height: 185px;
`;

const TrendsourceInfo = styled.div`
  margin-left: 10px;
`;
const TrendsourceHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.brandNavy4};
  margin-bottom: 3px;
`;

const TrendsourceDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandSlate6};
`;

const Image = styled.img`
  height: 18px;
  width: 18px;
  margin-top: 2px;
`;

const CompanyAddress = styled.div``;

export type CreditReportPageOneStateType = {
  name: string;
  title: string;
  email: string;
  phoneNumber: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  businessType: string;
};

type CreditReportSetupModalPageOneProps = {
  state: CreditReportPageOneStateType;
  setState: Function;
  validationWarnings: InitialCreditReportValidations;
};

const CreditReportSetupModalPageOne: React.FC<
  CreditReportSetupModalPageOneProps
> = ({ state, setState, validationWarnings }) => {
  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, name: e.target.value });
    },
    [setState, state],
  );
  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, title: e.target.value });
    },
    [setState, state],
  );
  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, email: e.target.value });
    },
    [setState, state],
  );

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, phoneNumber: e.target.value });
    },
    [setState, state],
  );

  const handleStreetChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, street: e.target.value });
    },
    [setState, state],
  );

  const handleCityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, city: e.target.value });
    },
    [setState, state],
  );

  const handleZipChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, zip: e.target.value });
    },
    [setState, state],
  );

  const handleStateChange = useCallback(
    ({ selectedItem }) => {
      setState({ ...state, state: selectedItem });
    },
    [setState, state],
  );

  const handleBusinessTypeChange = useCallback(
    (e: any) => {
      setState({ ...state, businessType: e.target.value });
    },
    [setState, state],
  );

  return (
    <Container>
      <TypicalTime>
        <Icon className='screening-icon' data-testid='credit-report-icon'>
          <M.Icon icon='Calendar' size='20' />
        </Icon>
        Setup typically takes 14-21 business days, including location
        inspection.
      </TypicalTime>
      <EmployerRepresentative>
        <Header>Enter contact information</Header>
        <SubHeader>Contact person</SubHeader>
        <Description>
          Authorization requires a one-time office inspection. Enter contact
          info for the person responsible for setup.
        </Description>
        <Flex>
          <TextInput
            data-testid='credit-report-contact-name'
            labelText='Contact name'
            onChange={handleNameChange}
            placeholder='Ex: Joseph Johnson'
            value={state.name}
            invalid={!validationWarnings.name?.isValid}
            invalidText={validationWarnings.name?.error}
            maxLength='150'
          />
          <MarginRight24 />
          <TextInput
            data-testid='credit-report-title'
            labelText='Title'
            onChange={handleTitleChange}
            placeholder='Ex: HR Manager'
            type='text'
            value={state.title}
            invalid={!validationWarnings.title?.isValid}
            invalidText={validationWarnings.title?.error}
          />
        </Flex>
        <MarginBottom15 />
        <Flex>
          <TextInput
            data-testid='credit-report-email-address'
            labelText='Email address'
            onChange={handleEmailChange}
            placeholder='Ex: joe@email.com'
            type='email'
            value={state.email}
            invalid={!validationWarnings.email?.isValid}
            invalidText={validationWarnings.email?.error}
          />
          <MarginRight24 />
          <TextInput
            data-testid='credit-report-phone-number'
            labelText='Phone number'
            onChange={handlePhoneNumberChange}
            placeholder='Ex: (123) 456-7890'
            type='tel'
            value={state.phoneNumber}
            invalid={!validationWarnings.phoneNumber?.isValid}
            invalidText={validationWarnings.phoneNumber?.error}
          />
        </Flex>
      </EmployerRepresentative>
      <CompanyAddress>
        <SubHeader>Inspection address</SubHeader>
        <Description>
          The location where the inspection will take place. Please review for
          accuracy.
        </Description>
        <FullLengthTextInput
          data-testid='credit-report-street'
          labelText='Street'
          onChange={handleStreetChange}
          placeholder='Street name'
          value={state.street}
          invalid={!validationWarnings.street?.isValid}
          invalidText={validationWarnings.street?.error}
        />
        <Flex>
          <AddressTextInput
            data-testid='credit-report-city'
            labelText='City'
            onChange={handleCityChange}
            placeholder='City'
            value={state.city}
            invalid={!validationWarnings.city?.isValid}
            invalidText={validationWarnings.city?.error}
          />
          <MarginRight24 />
          <AddressTextInput
            data-testid='credit-report-zipcode'
            labelText='ZIP code'
            onChange={handleZipChange}
            placeholder='ZIP code'
            value={state.zip}
            invalid={!validationWarnings.zipcode?.isValid}
            invalidText={validationWarnings.zipcode?.error}
          />
          <MarginRight24 />
          <StateDropdown
            data-testid='credit-report-state'
            label='State'
            onChange={handleStateChange}
            titleText='State'
            items={US_STATES_NAMES}
            selectedItem={state.state}
            invalid={!validationWarnings.state?.isValid}
            invalidText={validationWarnings.zipcode?.error}
          />
        </Flex>
        <MarginBottom15 />
        <FullLengthTextInput
          data-testid='credit-report-business-type'
          labelText='Business type'
          onChange={handleBusinessTypeChange}
          placeholder='Ex: Financial, Media, Consultant'
          value={state.businessType}
          invalid={!validationWarnings.businessType?.isValid}
          invalidText={validationWarnings.businessType?.error}
        />
      </CompanyAddress>
      <TrendsourceContainer>
        <Image src={Trendsource} alt='' />
        <TrendsourceInfo>
          <TrendsourceHeader>Who is Trendsource?</TrendsourceHeader>
          <TrendsourceDescription>
            Checkr utilizes a third-party vendor, Trendsource, who will be
            performing a one-time inspection of your office in order to verify
            that the proper compliance measures are in place for you to run
            credit reports. The Trendsource team will contact you directly to
            coordinate the appointment. Please note, given our current
            environment, Trendsource can conduct an onsite or virtual
            inspection. A home office is acceptable for the inspection; please
            note that the office must be independent of a living space and
            clearly an office space (i.e. a dedicated room in the house or a
            garage, as opposed to a kitchen or bedroom).
          </TrendsourceDescription>
        </TrendsourceInfo>
      </TrendsourceContainer>
    </Container>
  );
};

export default CreditReportSetupModalPageOne;
