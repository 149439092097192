import { Callback } from '@dashboard-experience/utils';
import * as Notifications from 'state/notifications/entity';

export enum DEV_DASHBOARD_STATES {
  READY_FOR_INITIAL_DATA = 'readyForInitialData',
  TOKEN_EXPIRED = 'tokenExpired',
  TOKEN_INVALID = 'tokenInvalid',
  UPDATE_LOCATION_PATHNAME = 'updateLocationPathname',
  UPDATE_URL_PARAMS = 'updateURLParams',
  WINDOW_RESIZE = 'windowResize',
  DISPLAY_NOTIFICATION = 'displayNotification',
}

export type IframeEvent = {
  eventName: DEV_DASHBOARD_STATES;
  cb: Callback;
};

export type LocationData = {
  state: DEV_DASHBOARD_STATES.UPDATE_LOCATION_PATHNAME;
  URL: string;
};

export type ParamsData = {
  report_id?: string;
  page?: string;
  per_page?: string;
  env?: 'US' | 'EU';
  created_after?: string;
  success?: 'true' | 'false';
  exclude_retries?: 'true' | 'false';
};

export type NotificationData = {
  body: string;
  id: string;
  timeout: number;
  title: string;
  type: Notifications.Kind.error | Notifications.Kind.success;
};
