import React from 'react';
import {
  useTrackEventOnMount,
  ReorderEvent,
  EventProperty,
  EventValue,
} from 'components/Report/Screenings/DrugHealthScreenings/analytics';

import Error from './error';
import ClinicError from './clinic';
import { useModalState } from '../../../context';

const ErrorView: React.FC<{}> = () => {
  const { addExamError: error } = useModalState('formData');
  const clinicError = error?.statusCode === 422;

  useTrackEventOnMount(ReorderEvent.ErrorTriggered, {
    [EventProperty.Error]: clinicError
      ? EventValue.ClinicError
      : EventValue.ServerError,
  });

  if (clinicError) return <ClinicError error={error} />;

  return <Error error={error} />;
};

export default ErrorView;
