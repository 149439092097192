import React from 'react';
import Cell from './Cell';

type PropsType = {
  reportPackage: {
    slug?: string;
  } | null;
  url: string;
};

const Package = ({ reportPackage, url }: PropsType) => {
  if (!reportPackage?.slug) return null;

  return (
    <Cell url={url} testid='cm-ui-tasks-page-table-rows-package'>
      {reportPackage.slug}
    </Cell>
  );
};

export default Package;
