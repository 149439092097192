import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useRouteMatch } from 'react-router-dom';
import { useGetApplicationInstance } from 'api/integrations';
import Deauthorize from 'components/Oauth/Deauthorize';

const DeauthorizeContainer = () => {
  const match = useRouteMatch<{ clientId: string }>();
  const {
    params: { clientId },
  } = match;
  const { data, isLoading, isError } = useGetApplicationInstance(clientId);
  if (isError)
    return (
      <M.InlineNotification
        hideCloseButton
        kind='error'
        title='Unable to get application instance info.'
      />
    );
  if (isLoading || !data) return <M.LoadingInline description='Loading...' />;
  return <Deauthorize applicationInstance={data} />;
};

export default DeauthorizeContainer;
