import { useTranslation } from 'react-i18next';

export type Key = 'name' | 'assigned' | 'time' | 'draft' | 'menu';

const keys: Key[] = ['name', 'assigned', 'time', 'draft', 'menu'];
const tkey = 'assess:mvr.category-sets.collection.search.table.headers';

export const useHeaders = () => {
  const { t } = useTranslation('assess');

  const headers = keys.map(key => ({
    key,
    header: t(`${tkey}.${key}`, ''),
  }));

  return headers;
};
