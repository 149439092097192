import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledForm = styled(M.Form)`
  color: ${colors.uiTextPrimaryLight};

  p {
    max-width: 659px;
  }
`;

export const ListItem = styled.li`
  margin-left: 1.5rem;
`;

export const StyledSecondaryMessage = styled.p`
  color: ${colors.uiTextSecondaryLight};
`;

export const StyledInlineFlexDiv = styled.div`
  display: inline-flex;
`;

export const StyledTooltipIcon = styled(M.Icon)`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  fill: ${colors.uiTextTertiaryLight};
`;

export const StyledCheckboxWrapper = styled.ul`
  margin-top: 1rem;
`;

export const StyledCheckboxBox = styled.div`
  .cds--checkbox-wrapper {
    margin-top: 0.75rem;
  }
`;

export const Divider = styled(M.Divider)`
  #mastodon & {
    border-width: 0;
    border-top-width: 1px;
  }
`;
