import React from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  open: boolean;
  onClose: () => void;
};

const LoadingModal: React.FC<Props> = ({ onClose, open }) => (
  <M.ComposedModal
    onClose={onClose}
    open={open}
    data-testid='loading-modal'
    className='actions-modal'
  >
    <M.ModalBody style={{ height: '10rem' }}>
      <M.LoadingSpinner />
    </M.ModalBody>
  </M.ComposedModal>
);

export default LoadingModal;
