import React from 'react';

import {
  useEnableCompleteNow,
  useShowCompleteNow,
} from 'modules/adjudication/utilities';

export type Props = {};

export const Guard: React.FC<Props> = ({ children }) => {
  const enableCompleteNow = useEnableCompleteNow();
  const showCompleteNow = useShowCompleteNow();

  return enableCompleteNow && showCompleteNow ? <>{children}</> : null;
};

export default Guard;
