import React, { useCallback } from 'react';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import { useSearchState } from 'components/Search/search-context';
import styled from 'styled-components';
import { toggleAllFilters } from '../helpers';

const FilterToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.uiNavy600};
  font-size: ${sizes.medium};
  line-height: 20px;
  svg {
    margin-left: 0.5rem;
  }
`;

const AllFiltersToggle = () => {
  const { showAllFilters, setShowAllFilters } = useSearchState();
  const label = showAllFilters ? 'Hide filters' : 'Show filters';
  const icon = showAllFilters ? 'ChevronUp' : 'ChevronDown';

  const toggleFilters = useCallback(
    () => toggleAllFilters(showAllFilters, setShowAllFilters),
    [showAllFilters, setShowAllFilters],
  );

  return (
    <FilterToggle onClick={toggleFilters}>
      {label} <M.Icon icon={icon} size={24} />
    </FilterToggle>
  );
};

export default AllFiltersToggle;
