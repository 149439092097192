import Kind from 'modules/assess/models/rules/common/kind';
import * as Entity from 'modules/assess/models/rules/entity';
import client from '../../client';
import { Namespace } from '../../constants';
import * as count from '../../ruleset/utilities/rules/count';

export const BASE_PATH = 'library';

export const baseURI = (namespace?: Namespace, kind?: Kind) => {
  return `${namespace}/${BASE_PATH}/${kind}_rules`;
};

const SEARCH_DEFAULTS = { page: 1, per_page: 100 };

export type SearchParams = {
  page?: number;
  per_page?: number;
};

export type SearchResults = {
  count: number;
  data: Entity.List;
};

export const search = (
  kind: Kind,
  namespace: Namespace,
  params: SearchParams = SEARCH_DEFAULTS,
): Promise<SearchResults> => {
  const path = baseURI(namespace, kind);
  return client
    .get(path, { params: { ...SEARCH_DEFAULTS, ...params } })
    .then(response => {
      if (kind === Kind.COUNT) {
        return {
          ...response,
          data: response.data.map(count.fromAPI),
        };
      }
      return response;
    });
};

export const uri = (kind: Kind, namespace?: Namespace, id?: string): string => {
  const base = baseURI(namespace, kind);
  const path = id ? `${base}/${id}` : base;
  return client.uri({ url: path });
};
