import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  StyledH2,
  StyledNote,
  GridCol,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';

const BillingInformationHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <M.Grid>
      <M.GridRow>
        <CustomCol col={5} noPadding style={{ marginTop: '3px' }}>
          <M.GridRow>
            <GridCol>
              <StyledH2 className='mb-0'>
                {t(`billing_information_header.title`)}
              </StyledH2>
              <StyledNote className='mb-0'>
                {t(`billing_information_header.description`)}
              </StyledNote>
            </GridCol>
          </M.GridRow>
          <Divider />
        </CustomCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default BillingInformationHeader;
