import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import { ContextContainer } from 'containers';
import DevexLogsIframe from 'containers/DevexLogsIframe/DevexLogsIframe';

const LogsPage = ({ location, match }) => {
  if (!['api', 'webhook'].includes(match.params.context)) {
    return <Redirect to='/404' />;
  }

  return <DevexLogsIframe location={location} />;
};

LogsPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ContextContainer(LogsPage);
