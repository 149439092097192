import { IS_IN_IFRAME } from 'Constants';
import { FormHeading } from 'components';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledFormHeading = styled(FormHeading)`
  margin-top: 2rem;
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${IS_IN_IFRAME ? '719px' : '991px'}) {
    flex-direction: column;
  }
`;

export const HeadingButtonsContainer = styled.div`
  display: inline-flex;
  margin-left: auto;
  margin-bottom: 0rem;
  align-items: flex-end;

  .mastodon-button.secondary {
    margin-right: 1rem !important;
  }

  #mastodon & {
    .mastodon-button {
      max-width: fit-content;
      height: fit-content;

      &.primary {
        background-color: ${colors.brandAqua3};
        border-color: ${colors.brandAqua3};
        margin: 0;

        &:active,
        &:focus,
        &:hover {
          &:enabled:not(.disabled) {
            background-color: ${colors.brandAqua4};
            border-color: ${colors.brandAqua4};
          }
        }
      }
    }
  }
  @media (max-width: ${IS_IN_IFRAME ? '497px' : '575px'}) {
    flex-direction: column-reverse;

    #mastodon & {
      .mastodon-button.primary {
        margin-bottom: 1rem;
      }
    }
  }
  @media (max-width: ${IS_IN_IFRAME ? '719px' : '991px'}) {
    margin-top: 1.5rem;
    width: 100%;
    margin-bottom: 2rem;

    #order-background-check {
      svg {
        display: none;
      }
    }
  }
`;

export const StyledSubHeading = styled.h2`
  margin: 1rem;
  color: ${colors.uiTextPrimaryLight};
`;

export const SelectNode = styled(M.NodeSelect)`
  margin-right: 1rem;
  margin-bottom: -0.5rem;
`;
