import { Client } from 'api';
import { CANDIDATE_EMPLOYMENT_HISTORIES_PATH } from './constants';

export type CandidateEmploymentHistoriesParams = {
  to_date_ignored?: boolean;
  from_date_ignored?: boolean;
  salary_ignored?: boolean;
  position_ignored?: boolean;
  contract_type_ignored?: boolean;
};

export const updateCandidateEmploymentHistorySettings = (
  recordId: string,
  params: CandidateEmploymentHistoriesParams,
): Promise<{ [key: string]: any }> => {
  return Client.post(
    `${CANDIDATE_EMPLOYMENT_HISTORIES_PATH}/${recordId}`,
    params,
  );
};
