const deferStates = {
  Subtypes: {
    transitions: {
      confirm: 'Confirm',
    },
  },
  Confirm: {
    transitions: {},
  },
};

export default deferStates;
