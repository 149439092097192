import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: 0 1em !important;
  margin-bottom: 1em !important;
  font-weight: bold;
`;

type Props = {
  labelTranKey: string;
  style?: CSSProperties;
};

const Separator: React.FC<Props> = ({ labelTranKey, style = {} }) => {
  const { t } = useTranslation('');
  const label = t(labelTranKey);

  return (
    <Container data-testid='assess-rule-editor-separator' style={{ ...style }}>
      {label}
    </Container>
  );
};

export default Separator;
