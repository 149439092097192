import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { address, InternationalCandidateAddress } from 'types';

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Name = styled.h5`
  flex: 1;
  font-size: 14px;
`;

const defaultFormatExtraInfo = () => null;

type AddressProps = {
  address: address | InternationalCandidateAddress;
  formatAddress: Function;
  formatDate: Function;
  formatExtraInfo?: Function;
};

const Address: React.FC<AddressProps> = ({
  address,
  formatAddress,
  formatDate,
  formatExtraInfo = defaultFormatExtraInfo,
}) => {
  const name = formatAddress(address);
  const date = formatDate(address);
  const extraInfo = formatExtraInfo(address);
  return (
    <M.Container.Row>
      <M.Container.Col>
        <HeadingWrapper>
          <Name>{name}</Name>
          {date && <span>{date}</span>}
        </HeadingWrapper>
        {extraInfo && <span>{extraInfo}</span>}
      </M.Container.Col>
    </M.Container.Row>
  );
};

export default Address;
