import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

const WarningBanner: React.FC = () => {
  const { t } = useTranslation('adjudication');
  const title = t(
    'adjudication:report.actions.pre_adverse.fair_chance.individualized_assessment',
  );
  const subtitle = t(
    'adjudication:report.actions.pre_adverse.fair_chance.states',
  );

  return (
    <M.InlineNotification
      data-testid='fair-chance-warning-banner'
      kind='warning'
      title={title}
      subtitle={subtitle}
      style={{ maxWidth: '100%' }}
      hideCloseButton
    />
  );
};

export default WarningBanner;
