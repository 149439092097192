import React, { useCallback } from 'react';
import _ from 'lodash';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  FINAL_STATUSES,
  getResultOrStatus,
  getStatusTypeForReport,
  StatusTypes,
} from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { useUser } from 'context/CurrentUser';
import {
  StatusDecorator,
  Covid19Notice,
} from './MultiMotorVehicleReportComponents';
import {
  getCDLISNotFound,
  getExperienceRuleFailed,
  getHasCustomRules,
  getDisplayCovidNotice,
  getHasSuspensionReason,
  getStatusDecoratorText,
  getSuspensionReasonMessage,
  hasNameMismatch,
  hasDobMismatch,
} from './Helpers';
import ReportHeading from './ReportHeading';
import BasicMVRKeyValueList from './BasicMVRKeyValueList';
import AdditionalInfoPanel from './AdditionalInfoPanel';
import GenericPanel from './GenericPanel';
import Violations from './Violations';
import Accidents from './Accidents';
import Suspensions from './Suspensions';
import MiscellaneousIncidents from './MiscellaneousIncidents';
import MedCerts from './MedCerts';
import CDLIS from './CDLIS';
import CustomFilters from './CustomFilters';

const MultiMotorVehicleReport: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { motor_vehicle_report: screening } = report;
  const currentUser = useUser();

  const isAssessedWithMVRAssessRules = _.get(
    report,
    'motor_vehicle_report.is_assessed_with_mvr_assess_rules',
    false,
  );

  const isAssessMvrRulesEnabled = _.get(
    report,
    'account.assess_mvr_enabled',
    false,
  );

  const isReportCompleted = FINAL_STATUSES.includes(getResultOrStatus(report));

  const assessShowCustomFilters = useCallback(() => {
    if (isReportCompleted) {
      return !isAssessedWithMVRAssessRules;
    }

    return !isAssessMvrRulesEnabled;
  }, [
    isReportCompleted,
    isAssessedWithMVRAssessRules,
    isAssessMvrRulesEnabled,
  ]);

  if (!screening) return null;
  // @ts-ignore TODO: Import Report type from utils when is up to date
  const userType = getStatusTypeForReport(report, currentUser);
  const assessEnabled = userType === StatusTypes.Assess;

  const isCanceledStatus = screening?.status === 'canceled';
  const hasStatusDecorator =
    !isCanceledStatus && !_.isEmpty(getStatusDecoratorText(screening));

  const experienceRuleFailed = getExperienceRuleFailed(screening);
  const hasSuspensionReason = getHasSuspensionReason(screening);

  const CDLISNotFound = getCDLISNotFound(screening);
  const hasCustomRules = getHasCustomRules(screening);

  const statusDecoratorText = getStatusDecoratorText(screening);

  const suspensionReasonMessage = getSuspensionReasonMessage(
    screening.suspension_reason,
  );

  return (
    <M.Screening
      title='Motor Vehicle Record'
      screening={screening}
      statusType={reportStatusType}
      id={SCREENING_TITLES.motor_vehicle_report.id}
      data-testid='screenings-multi-mvr'
      statusDecorator={
        !isCanceledStatus && hasStatusDecorator ? (
          <StatusDecorator statusDecoratorText={statusDecoratorText} />
        ) : (
          false
        )
      }
    >
      {(getResultOrStatus(screening) !== 'pending' || experienceRuleFailed) && (
        <>
          {!isCanceledStatus && (
            <>
              {hasSuspensionReason && (
                <p>
                  <M.Icon
                    icon='WarningFilled'
                    size='16'
                    fill={colors.uiYellow400}
                  />{' '}
                  {suspensionReasonMessage}
                </p>
              )}

              {CDLISNotFound && <p>CDLIS records not found</p>}
              {hasCustomRules && assessShowCustomFilters() && (
                <CustomFilters
                  mvrReport={screening}
                  reportStatusType={userType}
                  assessEnabled={assessEnabled}
                />
              )}
              {!!screening?.cdlis_record && (
                <CDLIS cdlis={screening.cdlis_record} title='CDLIS Results' />
              )}
              {screening?.license_reports?.length > 0
                ? screening?.license_reports?.map(licenseReport => {
                    const hasMismatch =
                      hasDobMismatch(licenseReport) ||
                      hasNameMismatch(licenseReport);
                    const displayCovidNotice =
                      getDisplayCovidNotice(licenseReport);

                    return !hasMismatch ? (
                      <M.Expandable
                        role='licenseReport'
                        className={`${hasMismatch ? 'hide-chevron' : ''}`}
                        key={`${licenseReport.id}`}
                        initialExpanded
                        title={<ReportHeading licenseReport={licenseReport} />}
                      >
                        {!hasMismatch && (
                          <>
                            {displayCovidNotice && <Covid19Notice />}
                            <BasicMVRKeyValueList
                              licenseReport={licenseReport}
                            />
                            <AdditionalInfoPanel
                              licenseReportOrCharge={licenseReport}
                              // @ts-ignore TODO: Update to consume Report type from Utils once is updated
                              report={report}
                              assessEnabled={assessEnabled}
                              currentUser={currentUser}
                              panelType='licenseReport'
                            />
                            {!!licenseReport?.medical_certificates?.length && (
                              <MedCerts licenseReport={licenseReport} />
                            )}
                            {!!licenseReport?.restrictions?.length && (
                              <GenericPanel
                                licenseReport={licenseReport}
                                title='Restrictions'
                                reportKey='restrictions'
                              />
                            )}
                            {!!licenseReport?.endorsements?.length && (
                              <GenericPanel
                                licenseReport={licenseReport}
                                title='Endorsements'
                                reportKey='endorsements'
                              />
                            )}
                            {!!licenseReport?.violations?.length && (
                              <Violations
                                licenseReportOrCharge={licenseReport}
                                // @ts-ignore TODO: Update to consume Report type from Utils once is updated
                                report={report}
                                assessEnabled={assessEnabled}
                                currentUser={currentUser}
                              />
                            )}
                            {!!licenseReport?.accidents?.length && (
                              <Accidents
                                licenseReportOrCharge={licenseReport}
                                report={report}
                                assessEnabled={assessEnabled}
                                currentUser={currentUser}
                              />
                            )}
                            {!!licenseReport?.suspensions?.length && (
                              <Suspensions
                                licenseReportOrCharge={licenseReport}
                                report={report}
                                assessEnabled={assessEnabled}
                                currentUser={currentUser}
                              />
                            )}
                            {!!licenseReport?.miscellaneous_incidents
                              ?.length && (
                              <MiscellaneousIncidents
                                licenseReportOrCharge={licenseReport}
                              />
                            )}
                          </>
                        )}
                      </M.Expandable>
                    ) : (
                      <ReportHeading
                        data-testid='reportHeading'
                        key={`${licenseReport.id}`}
                        licenseReport={licenseReport}
                      />
                    );
                  })
                : null}
            </>
          )}
        </>
      )}
    </M.Screening>
  );
};

export default MultiMotorVehicleReport;
