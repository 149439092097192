import React, { useCallback } from 'react';
import BinaryChoiceCard from 'components/BinaryChoiceCard';

import { Props, Field } from '../types';
import LocationPicker from '../LocationPicker/LocationPicker';
import { LocationSearchContainer } from '../styles';

const ClinicSelectionPrompt: React.FC<Props> = ({
  accountId,
  examSlugs,
  packageId,
  testPackage,
  requiresObservedCollection,
  state,
  updateState,
  translations,
}) => {
  const { selectNewClinic } = state;
  const setNewClinic = useCallback(
    (value: string) =>
      updateState(
        { ...state, [Field.SelectNewClinic]: value },
        Field.SelectNewClinic,
      ),
    [state, updateState],
  );
  const { title, choices, no_clinic } = translations;

  return (
    <LocationSearchContainer data-testid='clinic-selection-prompt'>
      <section>
        <h2>{title}</h2>
        <BinaryChoiceCard
          header='Do you want to select a clinic on behalf of your employees?'
          onChange={setNewClinic}
          firstChoice={choices.first}
          secondChoice={choices.second}
          defaultSelected={selectNewClinic}
        />
      </section>

      {selectNewClinic === choices.first && (
        <LocationPicker
          accountId={accountId}
          examSlugs={examSlugs}
          packageId={packageId}
          testPackage={testPackage}
          requiresObservedCollection={requiresObservedCollection}
          translations={translations}
          state={state}
          updateState={updateState}
        />
      )}

      {selectNewClinic === choices.second && <p>{no_clinic}</p>}
    </LocationSearchContainer>
  );
};

export default ClinicSelectionPrompt;
