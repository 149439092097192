import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const BoldText = styled.span`
  color: ${colors.brandSlate7};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

type SkipProps = {
  open: boolean;
  handleClose: Function;
  handleConfirm: Function;
  addonsNotSetup: string[];
};

const getBoldText = (addonsNotSetup: string[]) => {
  if (addonsNotSetup.length === 1) {
    return { oneItem: addonsNotSetup[0], commaSeparated: null, lastItem: null };
  }

  if (addonsNotSetup.length === 2) {
    return { commaSeparated: addonsNotSetup[0], lastItem: addonsNotSetup[1] };
  }

  const lastItem = addonsNotSetup.pop();
  const commaSeparated = addonsNotSetup.join(', ');
  return { commaSeparated, lastItem, oneItem: null };
};

const SkipForNowModal: React.FC<SkipProps> = ({
  open,
  handleClose,
  handleConfirm,
  addonsNotSetup,
}) => {
  const { commaSeparated, lastItem, oneItem } = getBoldText(addonsNotSetup);

  return (
    <M.ComposedModal
      data-testid='skip-setup-modal'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='skip-setup-modal-header'
        closeModal={handleClose}
      >
        <h2>Skip for now?</h2>
      </M.ModalHeader>
      <M.ModalBody id='skip-setup-modal-body'>
        If you’re not quite ready yet, you can always set up{' '}
        {oneItem ? (
          <BoldText>{oneItem}</BoldText>
        ) : (
          <span>
            <BoldText>{commaSeparated}</BoldText> and{' '}
            <BoldText>{lastItem}</BoldText>
          </span>
        )}{' '}
        screenings in the same location you customize add-ons (package
        builder/package editor/order add-ons).
      </M.ModalBody>

      <M.ModalFooter>
        <M.Button
          kind='secondary'
          size='sm'
          data-testid='skip-modal-close-button'
          onClick={handleClose}
        >
          Cancel
        </M.Button>
        <M.Button
          kind='primary'
          size='sm'
          data-testid='skip-modal-confirm-button'
          onClick={handleConfirm}
        >
          Confirm
        </M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default SkipForNowModal;
