import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/Button';

type Props = {
  cancel?: boolean;
  navigateUp?: boolean;
  url?: string;
};

export const BackButton: React.FC<Props> = ({ cancel, navigateUp, url }) => {
  const buttonText = cancel ? 'buttons.cancel' : 'buttons.back';
  const history = useHistory();
  const location = useLocation();
  const handleClick = useCallback(() => {
    if (url) {
      history.push(url);
      return;
    }

    if (navigateUp) {
      const back = location.pathname.split('/').slice(0, -1).join('/');
      history.push(back);
      return;
    }

    history.goBack();
  }, [history, navigateUp, url]);

  return (
    <Button
      id='assess-ui-common-back-button'
      kind='secondary'
      onClick={handleClick}
      size='sm'
      trans={buttonText}
    />
  );
};

export default BackButton;
