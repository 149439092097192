import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

const SUBSTATUSES = {
  NO_RECORD_VERIFIED: {
    icon: 'CheckmarkFilled',
    color: colors.uiGreen400,
    title: 'Declaration matches results',
    description:
      'The BC Enhanced Plus Criminal Record results were returned Clear, based solely on your name and date of birth.',
  },
  RECORD_VERIFIED: {
    icon: 'CheckmarkFilled',
    color: colors.uiGreen400,
    title: 'Declaration matches results',
    description:
      'Based solely on name(s) and date of birth provided it is recommended that the applicant obtain a Police Information Check at their local police service.',
  },
  NOT_VERIFIED: {
    icon: 'WarningFilled',
    color: colors.uiPink600,
    title: 'Declaration does not match result',
    description:
      'Based solely on name(s) and date of birth provided it is recommended that the applicant obtain a Police Information Check at their local police service.',
  },
} as any;

const Substatus: React.FC<{ substatus: string }> = ({ substatus }) => {
  const statusInfo = SUBSTATUSES[substatus];
  if (!statusInfo) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h3
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <M.Icon
          icon={statusInfo.icon}
          size={16}
          style={{ fill: statusInfo.color, marginRight: '0.5rem' }}
        />
        {statusInfo.title}
      </h3>
      <div>{statusInfo.description}</div>
    </div>
  );
};

export default Substatus;
export { Substatus };
export { SUBSTATUSES };
