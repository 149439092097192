/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AddUserForm, UsersTable } from 'components/Account/Users';
import { SortDirections } from 'components/SortedHeader';
import { getRoles, fetchUsersForAccount } from 'actions/AccountUsersActions';
import { getMultiSelectRoles } from 'reducers/Users.reducer';

const DEBOUNCE_DELAY = 500;
const PER_PAGE = 25;

export class UsersContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        sort_by: 'email',
        sort_direction: SortDirections.ASC,
      },
    };
  }

  componentDidMount() {
    this.props.getRoles(this.props.currentUser);
    this.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountId !== this.props.accountId) {
      this.getUsers();
    }
  }

  onFilterOrSort = () => {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => this.getUsers(), DEBOUNCE_DELAY);
  };

  getUsers = () => {
    const { params } = this.state;
    const { accountId } = this.props;

    params.per_page = PER_PAGE;
    if (accountId) {
      this.props.fetchUsersForAccount(accountId, params);
    }
  };

  filterBy = (key, value) => {
    const { params } = this.state;
    params[`filter[${key}]`] = value;
    this.setState({ params: { ...params, page: 0 } }, () =>
      this.onFilterOrSort(),
    );
  };

  sort = ({ sortHeaderKey, sortDirection }) => {
    const { params } = this.state;
    this.setState(
      {
        params: {
          ...params,
          sort_by: sortHeaderKey,
          sort_direction: sortDirection,
        },
      },
      () => this.onFilterOrSort(),
    );
  };

  handlePaginationClick = pageIndex => {
    const { params } = this.state;
    this.setState({ params: { ...params, page: pageIndex } }, () =>
      this.onFilterOrSort(),
    );
  };

  filterByRoles = items =>
    this.filterBy(
      'roles',
      items.map(({ id }) => id),
    );

  filterByEmail = str => this.filterBy('email', str);

  render() {
    const {
      multiSelectRoles,
      accountId,
      users,
      usersCount,
      currentUser,
      isLoading,
    } = this.props;
    const {
      params: { page, sort_by, sort_direction },
    } = this.state;

    return (
      <>
        {(currentUser.permissions.manage_account_users ||
          currentUser.permissions.ams_manage_account_non_admin_users) && (
          <AddUserForm accountId={accountId} />
        )}
        <UsersTable
          users={users}
          pageCount={Math.ceil(usersCount / PER_PAGE)}
          filterByRoles={this.filterByRoles}
          filterByEmail={this.filterByEmail}
          sort={this.sort}
          sort_by={sort_by}
          sort_direction={sort_direction}
          multiSelectRoles={multiSelectRoles}
          handlePaginationClick={this.handlePaginationClick}
          selectedIndex={page}
          isLoading={isLoading}
        />
      </>
    );
  }
}

UsersContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  getRoles: PropTypes.func.isRequired,
  fetchUsersForAccount: PropTypes.func.isRequired,
  users: PropTypes.object,
  usersCount: PropTypes.number,
  multiSelectRoles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

UsersContainer.defaultProps = {
  users: {},
  usersCount: 0,
};

// TODO: Use reselect and test that separately
const mapStateToProps = state => {
  const { users, usersCount } = state.users;
  let accountId;

  if (state.currentUser.currentUser.account) {
    accountId = state.currentUser.currentUser.account.id;
  }

  return {
    accountId,
    users,
    usersCount,
    multiSelectRoles: getMultiSelectRoles(state),
    currentUser: state.currentUser.currentUser,
    isLoading: state.users.fetchUsersAmsRequest?.processing,
  };
};

const mapDispatchToProps = dispatch => ({
  getRoles: currentUser => dispatch(getRoles(currentUser)),
  fetchUsersForAccount: (accountId, params) =>
    dispatch(fetchUsersForAccount(accountId, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
