import { Entity } from 'modules/entity';

export interface Version extends Entity {
  number: number;
}

export interface EntityVersion<V extends Version = Version> extends Entity {
  version: V;
}

export type VersionEntity<
  Field extends string,
  E extends Entity = Entity,
  V extends Version = Version,
> = V & Record<Field, E>;

export const asEntityVersion = <
  Data extends VersionEntity<Field, E, V>,
  Field extends string,
  E extends Entity = Entity,
  V extends Version = Version,
>(
  data: Data | undefined,
  field: Field,
): EntityVersion<V> | undefined => {
  if (!data) {
    return undefined;
  }
  const entity = data[field];
  const version = { ...data, [field]: undefined };
  return { ...entity, version };
};

export const asVersionEntity = <
  Data extends EntityVersion<V>,
  Field extends string,
  E extends Omit<Data, 'version'>,
  V extends Version = Version,
>(
  data: Data | undefined,
  field: Field,
): VersionEntity<Field, E, V> | undefined => {
  if (!data) {
    return undefined;
  }
  const { version } = data;
  const entity = { ...data, version: undefined };
  return { ...version, [field]: entity } as unknown as VersionEntity<
    Field,
    E,
    V
  >;
};
