import { Namespace } from 'modules/assess/api';
import { FactRule, Operator } from 'modules/assess/models';
import * as Account from 'modules/core-types/account';
import { getFactConfigsByNamespace } from './editor/conditions';

export const editable = (
  account: Account.Type | undefined,
  namespace: Namespace | undefined,
  conditions: Array<FactRule>,
): boolean => {
  if (!account || !namespace) {
    return false;
  }

  const FACT_CONFIGS = getFactConfigsByNamespace(namespace);
  const regexEnabled = account.assess_ui_regex_condition_authorized;

  return conditions.every(condition => {
    const { operator } = condition;
    const regex = operator === Operator.MRI || operator === Operator.AMRI;

    if (regex && !regexEnabled) {
      return false;
    }

    return FACT_CONFIGS.some(config => {
      if (config.fact !== condition.fact) {
        return false;
      }

      if (operator === Operator.IN && config.operator !== Operator.IN) {
        return false;
      }

      return true;
    });
  });
};
