import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes, getReportAssessment } from '@dashboard-experience/utils';
import { CellProps } from './types';

const Assessment: React.FC<CellProps> = ({ report }) => {
  if (!report) return null;

  // @ts-ignore TODO: resolve once report type is updated
  const assessment = getReportAssessment(report);

  return assessment ? (
    <M.StatusBadge statusType={StatusTypes.Assess} status={assessment} />
  ) : null;
};

export default Assessment;
