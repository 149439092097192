import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { DEF01 } from '../../types/Screenings/CreditReport';

const Container = styled.div`
  width: 576px;
  .cds--text-input .cds--list-box__field {
    border-bottom: 1px solid !important;
  }

  .cds--inline-notification {
    margin-top: 0 !important;
    padding: 10px !important;
    margin-bottom: 24px !important;
  }
  .cds--inline-notification__subtitle {
    font-size: 16px !important;
    line-height: 24px !important;
    color: ${colors.uiTextPrimaryLight} !important;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 26px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: ${colors.brandNavy4};
  margin-bottom: 8px;
`;

const SubHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.brandNavy4};
  margin-top: 25px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandNavy4} !important;
  margin-bottom: 17px;
`;

const Link = styled.a`
  color: ${colors.brandNavy4} !important;
  text-decoration: underline;
`;
const ChoiceCardGroup = styled(M.ChoiceCardGroup)`
  grid-template-columns: 0px !important;
  .mastodon-choice-card-title {
    font-weight: normal !important;
    color: ${colors.brandNavy4} !important;
  }
`;

const ChoiceCard = styled(M.ChoiceCard)`
  max-width: 576px !important;
  width: 576px;
`;

const Dropdown = styled(M.Dropdown)`
  width: 495px;
  margin-top: 8px;
  height: 32px;
`;

type PermissiblePurposeData = {
  state: string;
  purpose: string;
};

interface Card {
  id: string;
  purposes: {
    purpose: string;
    id: string;
  }[];
}

export interface CreditReportReason {
  id: string;
  city: string | null;
  state: {
    id: number;
    name: string;
    short_name: string;
    population: number;
    sq_miles: number;
    population_density: number;
    fips_code: string;
  };
  title: string;
  legal_text: string;
  object: string;
}

const transformCreditReportData = (data: CreditReportReason[]) => {
  const groupedData: Record<string, { purpose: string; id: string }[]> =
    data.reduce((result, reason) => {
      const stateName = reason.state.name;

      if (!result[stateName]) {
        result[stateName] = [];
      }

      result[stateName].push({
        purpose: reason.title,
        id: reason.id,
      });

      return result;
    }, {} as Record<string, { purpose: string; id: string }[]>);

  return Object.entries(groupedData).map(([name, purposes]) => ({
    id: name,
    purposes,
  }));
};

export type CreditReportPageTwoStateType = {
  permissiblePurpose: PermissiblePurposeData[];
};

type GroupChoiceOnChangeData = { checked: boolean; id: string; value: any };

export type ApiData = {
  location: string;
  purpose: string;
};

export const findPurposeByLocation = (apiData: ApiData[], location: string) => {
  const foundLocation = apiData.find((item: any) => item.location === location);
  return foundLocation ? foundLocation.purpose : null;
};

export const hasMissingPurpose = (
  apiData: ApiData[],
  location: string,
): boolean => {
  const foundLocation = apiData.find(
    (item: ApiData) => item.location === location,
  );
  return !foundLocation || !foundLocation.purpose;
};

type CreditReportSetupPageTwoProps = {
  selected: string[];
  setSelected: Function;
  hasSelectedError: boolean;
  apiData: ApiData[];
  setApiData: Function;
  incompletePurposeLocations: Set<string>;
  purposes: CreditReportReason[];
  canSelectLocation: boolean;
  canSelectNilLocation: boolean;
};

const ModalHeader = () => {
  return (
    <>
      <Header>Select permissible purpose</Header>
      <SubHeader>Contact person</SubHeader>
      <Description>
        Select the locations you’re hiring in. Some locations require a{' '}
        <Link
          href='https://help.checkr.com/hc/en-us/articles/360044040154#h_01FH8GG8ZRVK13NSKTQ8B78DCN'
          target='_blank'
          rel='noopener noreferrer'
        >
          permissible purpose
        </Link>{' '}
        when ordering a pre-employment credit report.{' '}
        <Link
          href='https://checkr.com/blog/credit-check-dos-and-donts-in-the-hiring-process'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </Link>
      </Description>
    </>
  );
};

const CreditReportSetupModalPageTwo: React.FC<
  CreditReportSetupPageTwoProps
> = ({
  selected,
  setSelected,
  hasSelectedError,
  apiData,
  setApiData,
  incompletePurposeLocations,
  purposes = [],
  canSelectLocation,
  canSelectNilLocation,
}) => {
  const inlineErrorRef: any = useRef();

  const permissablePurposes = useMemo(
    () => transformCreditReportData(purposes),
    [purposes],
  );

  const handleGroupChoiceChange = useCallback(
    (groupChoiceData: GroupChoiceOnChangeData[]) => {
      const filteredSelected = groupChoiceData
        .filter((selectedData: GroupChoiceOnChangeData) => selectedData.checked)
        .map((selectedData: GroupChoiceOnChangeData) => selectedData.id);
      setSelected(filteredSelected);

      const filteredApiData: ApiData[] = groupChoiceData
        .filter(filteredApiData => filteredApiData.checked)
        .map(filteredApiData => {
          const existingData = apiData.find(
            data => data.location === filteredApiData.id,
          );
          return {
            location: filteredApiData.id,
            purpose: existingData ? existingData.purpose : '',
          };
        });

      setApiData(filteredApiData);
    },
    [apiData, setSelected, setApiData],
  );

  const handleDropdownChange = useCallback(
    ({ cardId, selectedItem }: { cardId: string; selectedItem: string }) => {
      const newPurpose = apiData.map(data => {
        if (data.location === cardId) {
          return { location: cardId, purpose: selectedItem };
        }
        return data;
      });

      setApiData(newPurpose);
    },
    [apiData, setApiData],
  );

  const handleChange = useCallback(
    (value: any) => {
      return ({ selectedItem }: { selectedItem: string }) => {
        handleDropdownChange({ cardId: value, selectedItem });
      };
    },
    [handleDropdownChange],
  );

  useEffect(() => {
    const scrollToError = () => {
      if (inlineErrorRef.current && inlineErrorRef.current.scrollIntoView) {
        inlineErrorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    if (incompletePurposeLocations.size > 0) {
      scrollToError();
    }
  }, [incompletePurposeLocations.size]);

  return (
    <Container ref={inlineErrorRef}>
      <HeaderContainer>
        <M.Restrict
          authorized={!hasSelectedError}
          error="Select at least one location below. If you don't hire in these locations, select the last option in the list."
        >
          <ModalHeader />
        </M.Restrict>
        {hasSelectedError && <ModalHeader />}
      </HeaderContainer>
      <ChoiceCardGroup
        type='checkbox'
        grid={false}
        width='576px'
        onChange={handleGroupChoiceChange}
      >
        {permissablePurposes.map((card: Card) => (
          <ChoiceCard
            data-testid={`${card.id.replace(' ', '_')}-choice-card`}
            key={card.id}
            id={card.id}
            title={card.id}
            checked={selected.includes(card.id)}
            disabled={!canSelectLocation}
            description={
              selected.includes(card.id) && (
                <div
                  ref={
                    incompletePurposeLocations.has(card.id)
                      ? inlineErrorRef
                      : null
                  }
                >
                  <Dropdown
                    value={card}
                    data-testid={`${card.id.replace(
                      ' ',
                      '_',
                    )}-purpose-dropdown`}
                    label='Select a purpose'
                    items={card.purposes.map(p => p.purpose)}
                    invalid={
                      incompletePurposeLocations.size > 0 &&
                      incompletePurposeLocations.has(card.id)
                    }
                    invalidText='Select a purpose'
                    selectedItem={findPurposeByLocation(apiData, card.id)}
                    onChange={handleChange(card.id)}
                  />
                </div>
              )
            }
          />
        ))}
        <ChoiceCard
          data-testid='default-choice-card'
          key='default-choice'
          id={DEF01}
          title='We do not hire in any of these locations'
          checked={selected.includes(DEF01)}
          disabled={!canSelectNilLocation}
        />
      </ChoiceCardGroup>
    </Container>
  );
};

export default CreditReportSetupModalPageTwo;
