import { GenericObject, Report } from 'types';
import { AssessStatuses } from '@dashboard-experience/utils';

export const shouldShowCanceledStatus = (
  account: GenericObject | null | undefined,
  screening: GenericObject,
) => {
  return (
    account &&
    !account.translate_canceled_status_to_suspended &&
    screening.includes_canceled === true
  );
};

export const isV2DrugScreening = (report: Report) => {
  const { drug_screening } = report;
  // eslint-disable-next-line no-prototype-builtins
  if (!!drug_screening && drug_screening.hasOwnProperty('mro_notes'))
    return true;
  return false;
};

const isAssessmentValue = (value: string) => {
  const AssessmentValues = [
    AssessStatuses.ELIGIBLE,
    AssessStatuses.ESCALATED,
    AssessStatuses.REVIEW,
  ];

  return AssessmentValues.includes(value as AssessStatuses);
};

export const showTestAssessmentOrStatus = (
  reportStatusType: string,
  screeningStatus: string,
  testStatus: string,
) => {
  if (
    reportStatusType === 'assess' &&
    testStatus === AssessStatuses.CONSIDER &&
    isAssessmentValue(screeningStatus)
  ) {
    return screeningStatus;
  }
  return testStatus;
};
