import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = { record: { [x: string]: any } };
type List = { name: string; field: string };

const getKeyListItems = (record: { [x: string]: any }) => {
  const list: List[] = [];
  const map: { [x: string]: any } = {};

  if (record?.full_name) {
    list.push({ name: 'Full name', field: 'full_name' });
    map.full_name = record.full_name;
  }
  if (record?.consumer_file_data?.statement) {
    list.push({ name: 'Consumer File Data', field: 'statement' });
    map.statement = record.consumer_file_data.statement;
  }
  if (record?.consumer_file_data?.consumer_statement) {
    list.push({
      /* name needs to be an empty string to display this info under
         Consumer File Data when there is already something to display */
      name: list.length > 1 ? '' : 'Consumer File Data',
      field: 'consumer_statement',
    });
    map.consumer_statement = record.consumer_file_data.consumer_statement;
  }

  return list.map(({ name, field }: List) => ({
    itemKey: name,
    itemValue: map[field],
  }));
};

const Info: React.FC<Props> = ({ record }) => {
  const items = useMemo(() => getKeyListItems(record), [record]);

  return <M.KeyValueList items={items} data-testid='credit-report-info' />;
};

export default Info;
