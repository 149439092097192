import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  AssessStatuses,
  StatusTypes,
  getScreeningStatus,
  getResultOrStatus,
  kebabToSnake,
  ReportStatuses,
} from '@dashboard-experience/utils';
import UserContext from 'context/CurrentUser';
import { Report, GenericObject, ReportProps } from 'types';
import { FederalRecord } from './types';
import Record from './Record';
import { FilteredByAdjudicationRecords } from '../Components';

const FederalDistrictSearch: React.FC<
  ReportProps & { federalType: string }
> = ({ report, reportStatusType, federalType = '' }) => {
  const formattedType = kebabToSnake(federalType);

  const { [`${formattedType}`]: screeningType } = report;
  const currentUser = useContext(UserContext);
  const title = getTitleName(federalType);
  const getFedDist = screeningType as GenericObject[];

  const filteredByPAMRecords = screeningType?.filter(
    (search: { filtered_by_positive_adjudication_records: [] }) =>
      search.filtered_by_positive_adjudication_records?.length || 0,
  );
  const hasFilteredByPAMRecords = filteredByPAMRecords > 0;
  const searchStatus = getStatus(screeningType, reportStatusType);

  return (
    <M.Screening
      title={title}
      screening={getFedDist}
      statusType={reportStatusType}
      status={searchStatus}
      data-testid={`screenings-${federalType}`}
      id={federalType}
    >
      {getFedDist?.map(screeningType => {
        const status = getResultOrStatus(screeningType);
        const screeningStatus = getScreeningStatus(
          screeningType,
          reportStatusType,
        );
        const screeningTitle = `${screeningType.court}, ${screeningType.state}`;
        return (
          <M.SubScreening
            key={screeningType.id}
            title={screeningTitle}
            screening={screeningType}
            statusType={reportStatusType}
          >
            {status === ReportStatuses.CONSIDER &&
              screeningType?.records?.map(
                (record: FederalRecord, index: number) => {
                  return (
                    <Record
                      key={`${federalType}-${record.id}`}
                      report={report}
                      record={record}
                      currentUser={currentUser}
                      reportStatusType={reportStatusType}
                      status={screeningStatus}
                      index={index}
                      federalType={federalType}
                    />
                  );
                },
              )}
          </M.SubScreening>
        );
      })}
      {hasFilteredByPAMRecords && (
        <FilteredByAdjudicationRecords
          search={screeningType}
          filter='Positive Adjudication Matrix'
          filteredRecords={filteredByPAMRecords}
          report={report as Report}
          reportStatusType={reportStatusType}
        />
      )}
    </M.Screening>
  );
};

function getTitleName(type: string) {
  return type === 'federal-district-criminal-searches'
    ? 'Federal District Searches'
    : 'Federal District Civil Searches';
}

function getStatus(screeningType: any, reportStatusType: string) {
  if (reportStatusType !== StatusTypes.Assess) {
    return null;
  }
  const districtSearches = screeningType || [];
  const canceledOrClearStatuses = ['canceled', 'clear'];
  const anyNotCanceledOrClearSearches = !districtSearches.some(
    (search: { status: string; result: string }) =>
      canceledOrClearStatuses.includes(search.status) ||
      canceledOrClearStatuses.includes(search.result),
  );

  if (anyNotCanceledOrClearSearches) {
    return null;
  }

  const anyCanceledDistrictSearchesCanceled = districtSearches.some(
    (search: { status: string }) => search.status === 'canceled',
  );

  if (anyCanceledDistrictSearchesCanceled) {
    return AssessStatuses.REVIEW;
  }

  return null;
}

export default FederalDistrictSearch;
