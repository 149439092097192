import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { isInternal } from '@dashboard-experience/utils';
import { useCandidate } from 'providers/Candidate';
import { useGetContinuousChecks } from 'api/continuousChecks';
import ContinuousCheckSubscription from './ContinuousCheckSubscription';
import { useUser } from '../../context/CurrentUser';
import PostHireModuleContainer from '../PostHire/PostHireModuleContainer';

const CcGrid = styled(M.Grid)`
  .mastodon-grid-row {
    .mastodon-grid-col {
      label.cds--toggle__label {
        .cds--toggle__switch {
          margin-top: 0;
        }
      }
    }
  }

  div:not(:first-child) {
    .mastodon-grid-row {
      margin-top: 0.5rem;
    }
  }
`;

const tooltip =
  "Continuous checks monitor real-time data sources, and look for changes in a candidate's record. When a change is identified, Checkr will automatically kick off a new background check report.";

const ContinuousCheckContainer: React.FC = () => {
  const currentUser = useUser();
  const candidate = useCandidate();
  const candidateId = candidate.id;
  const mvrApproved =
    candidate?.account?.continuous_check_mvr ||
    currentUser?.account?.continuous_check_mvr;
  const criminalApproved =
    candidate?.account?.continuous_monitoring ||
    currentUser?.account?.continuous_monitoring;
  const facisApproved =
    candidate?.account?.continuous_facis ||
    currentUser?.account?.continuous_facis;
  const anyContinuousCheckEnabled =
    isInternal(currentUser) ||
    candidate?.account?.any_continuous_check_enabled ||
    currentUser?.account?.any_continuous_check_enabled;
  const { data: continuousSubscriptionsResponse } =
    useGetContinuousChecks(candidateId);
  const [continuousSubscriptions, setContinuousSubscriptions] = useState(
    Array<any>([]),
  );

  useEffect(() => {
    setContinuousSubscriptions(continuousSubscriptionsResponse as Array<any>);
  }, [continuousSubscriptionsResponse]);

  const findSubscriptionByType = useCallback(
    (type: string) => {
      if (continuousSubscriptions === undefined) return undefined;

      return continuousSubscriptions.find(
        (el: { type: string }) => el.type === type,
      );
    },
    [continuousSubscriptions],
  );

  const updateSubscription = useCallback(
    (data: object, type: string, action: string) => {
      let subs = continuousSubscriptions;
      switch (action) {
        case 'delete':
          subs = subs?.filter((el: { type: string }) => el.type !== type);
          setContinuousSubscriptions(subs);
          return;
        case 'add':
          subs = continuousSubscriptions.concat([data]);
          setContinuousSubscriptions(subs);
          return;
        case 'update':
          subs = subs?.filter((el: { type: string }) => el.type !== type);
          subs = continuousSubscriptions.concat([data]);
          setContinuousSubscriptions(subs);
      }
    },
    [continuousSubscriptions],
  );

  if (anyContinuousCheckEnabled) {
    return (
      <PostHireModuleContainer
        id='continuous-check'
        title={<>Checkr Continuous</>}
        tooltipText={tooltip}
      >
        <CcGrid condensed>
          {criminalApproved && (
            <div id='continuous-crim'>
              <ContinuousCheckSubscription
                candidate={candidate}
                subscription={findSubscriptionByType('criminal')}
                type='criminal'
                updateSubscriptions={updateSubscription}
              />
            </div>
          )}
          {mvrApproved && (
            <div id='continuous-mvr'>
              <ContinuousCheckSubscription
                candidate={candidate}
                subscription={findSubscriptionByType('mvr')}
                type='mvr'
                updateSubscriptions={updateSubscription}
              />
            </div>
          )}
          {facisApproved && (
            <div id='continuous-facis'>
              <ContinuousCheckSubscription
                candidate={candidate}
                subscription={findSubscriptionByType('facis')}
                type='facis'
                updateSubscriptions={updateSubscription}
              />
            </div>
          )}
        </CcGrid>
      </PostHireModuleContainer>
    );
  }
  return null;
};

export default ContinuousCheckContainer;
