import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Context from 'modules/assess/ui/rules/lookback/table/context';
import { ASSESS_STATUSES } from '../constants';
import Cell from './Cell';
import Input from './Input';
import { Years, SEVERITY_STATUS } from './types';

const renderInactiveStatus = (
  status: SEVERITY_STATUS,
  years: Years,
  t: TFunction,
  last?: string,
) => {
  const { REVIEW, NEVER_MEETS, ALWAYS_MEETS } = ASSESS_STATUSES;

  if (last && (status === ALWAYS_MEETS || (last && status === NEVER_MEETS))) {
    return null;
  }

  if (status === REVIEW) {
    return t(`assess:decisions.${status}`);
  }

  const decision = `assess:rules.lookback.table.cells.${status}`;

  return t(decision, { count: years ?? undefined });
};

type SeverityCellProps = {
  min: number;
  max: number;
  last?: string;
  shouldFocus: boolean;
  years: Years;
  status: SEVERITY_STATUS;
  setYears: (data: Years) => void;
};

const SeverityCell: React.FC<SeverityCellProps> = ({
  min,
  max,
  years,
  setYears,
  status,
  last,
  shouldFocus,
}) => {
  const { t } = useTranslation('assess');
  const { disabled } = useContext(Context);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    shouldFocus && setFocused(true);
  }, [shouldFocus]);

  const handleClick = useCallback(
    () => !disabled && !isFocused && setFocused(true),
    [setFocused, isFocused, disabled],
  );

  return (
    <Cell
      className={`severity-cell ${isFocused ? 'active' : ''}`}
      last={last}
      status={status}
      onClick={handleClick}
    >
      {!isFocused && renderInactiveStatus(status, years, t, last)}
      {isFocused && !disabled && (
        <Input
          min={min}
          max={max}
          years={years}
          setYears={setYears}
          setFocused={setFocused}
        />
      )}
    </Cell>
  );
};

export default SeverityCell;
