import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Container = styled.div`
  color: ${colors.brandNavy4};
  margin-bottom: 16px !important;
`;

const Title: React.FC = () => {
  const { t } = useTranslation('assess:v2');
  const description = t(`standard.guideline.content.description`);
  return (
    <Container id='assess-v2-ui-guideline-description'>
      <Trans>{description}</Trans>
    </Container>
  );
};

export default Title;
