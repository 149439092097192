import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import useGetMenuItems from './useGetMenuItems';

const ActionMenu = styled(M.ActionMenu)`
  #mastodon && {
    .mastodon-action-menu-trigger {
      svg {
        fill: ${colors.uiGrey900};
        fill-opacity: 0.78;
      }
    }
    .mastodon-action-menu-container {
      z-index: 1000;
    }
  }
`;

const SecondaryActions = () => {
  const menuItems = useGetMenuItems();
  const trigger = (
    <M.Button kind='tertiary'>
      <M.Icon icon='OverflowMenuVertical' />
    </M.Button>
  );

  return (
    <ActionMenu position='left-bottom' trigger={trigger}>
      {menuItems}
    </ActionMenu>
  );
};

export default SecondaryActions;
