import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type Props = {
  actionText?: ReactNode;
  descriptionText?: ReactNode;
  disabled?: boolean;
};

const FilteredRecordRow = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${colors.uiGrey300};
  padding: 1em;
  background-color: ${({ disabled }) => (disabled ? '' : colors.uiGrey100)};
  color: ${colors.uiGrey800};
`;

const IconContainer = styled.span`
  > * {
    width: 1em;
    height: 1em;
    fill: ${colors.uiGrey800};
  }
`;

const Row = styled.div`
  display: flex;
`;

const StatusIcon = styled(M.StatusIcon)`
  float: left;
  margin-right: 0.5em;
  margin-left: -1em;
`;

const RecordRow: React.FC<Props> = ({
  actionText,
  descriptionText,
  disabled,
}) => {
  return (
    <FilteredRecordRow disabled={disabled}>
      <div>
        {disabled && <StatusIcon icon='eligible' />}
        {descriptionText}
      </div>
      <Row>
        {actionText}
        <IconContainer>
          <M.Icon icon='ChevronRight' size={32} />
        </IconContainer>
      </Row>
    </FilteredRecordRow>
  );
};

export default RecordRow;
