import { VerificationsClient as verificationsClient } from 'api';
import { GenericObject } from 'types';

export const getReportVerificationExceptions = (
  reportId: string,
): Promise<GenericObject> => {
  return verificationsClient.get(`/reports/${reportId}/exceptions`);
};

export default getReportVerificationExceptions;
