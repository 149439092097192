/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { AnyQueryKey, queryCache, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { toastError, toastSuccess } from 'actions';
import { Invitation } from 'types/report/invitation';
import { Candidate } from 'types';
import { useContext } from 'react';
import UIContext from 'context/UI';
import { postMessageToDashboard } from 'utils';
import {
  cancelInvitation,
  createCandidate,
  CreateCandidateParams,
  newCandidateInvitation,
  NewCandidateInvitationParams,
} from './actions';

type ParamsType = {
  payload: CreateCandidateParams;
  bulkInviteEnabled: boolean;
};

export const useCreateCandidate = () => {
  const request = (params: ParamsType) => createCandidate(params.payload);
  const dispatch = useDispatch();

  const [call, result] = useMutation(request, {
    onSuccess: (_, params: ParamsType) => {
      if (!params.bulkInviteEnabled)
        dispatch(toastSuccess('Invitations sent!'));
    },
  });
  return {
    call,
    result,
  };
};

export const useCancelInvitation = (
  invitation: Invitation,
  candidateId: string,
) => {
  const dispatch = useDispatch();
  const request = () => cancelInvitation(invitation.id);
  const { contextId, isIframe, isStandaloneIframe } = useContext(UIContext);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Invitation successfully canceled'));
      // remove the invitation from the candidate
      const key: AnyQueryKey = ['candidates/dashboard', { candidateId }];
      queryCache.setQueryData(
        key,
        (oldCandidate: Candidate = { id: candidateId }) => ({
          ...oldCandidate,
          invitations: (oldCandidate.invitations || []).filter(
            i => i.id !== invitation.id,
          ),
        }),
      );

      // TODO: Remove this code when flag is on for everyone
      if (isIframe && !isStandaloneIframe) {
        // inform dashboard of the invitation being cancelled
        postMessageToDashboard({
          contextId,
          broadcastMessage: 'remove_invitation',
          params: {
            candidateId,
            invitationId: invitation.id,
          },
        });
      }
    },
  });
  return { call, result };
};

export const useNewCandidateInvitation = () => {
  const dispatch = useDispatch();
  const request = (params: NewCandidateInvitationParams) =>
    newCandidateInvitation(params);
  const { contextId, isIframe, isStandaloneIframe } = useContext(UIContext);

  const [call, result] = useMutation(request, {
    onSuccess: (invitation, params) => {
      const { candidate_id: candidateId } = params;
      dispatch(toastSuccess('Invitation successfully created'));
      // Add the invitation to the candidate
      const key: AnyQueryKey = ['candidates/dashboard', { candidateId }];
      queryCache.setQueryData(
        key,
        (oldCandidate: Candidate = { id: candidateId }) => ({
          ...oldCandidate,
          invitations: [...(oldCandidate.invitations || []), invitation],
        }),
      );

      // TODO: Remove this code when flag is on for everyone
      if (isIframe && !isStandaloneIframe) {
        // inform dashboard of the invitation being cancelled
        postMessageToDashboard({
          contextId,
          broadcastMessage: 'add_invitation',
          params: {
            candidateId,
            invitation,
          },
        });
      }
    },
  });
  return { call, result };
};

export const useCreateCandidateInvitation = () => {
  const dispatch = useDispatch();
  const request = (params: NewCandidateInvitationParams) =>
    newCandidateInvitation(params);

  const [call, result] = useMutation(request, {
    onSuccess: (invitation, params) => {
      dispatch(toastSuccess('Invitation successfully created'));
    },
    onError: (error: any) => {
      dispatch(
        toastError(
          `Failed to create invitation, ${error?.response?.data?.error}`,
        ),
      );
    },
  });
  return { call, result };
};
