import intersection from 'lodash/intersection';
import { TYPE_FACTS as CRIM_TYPE_FACTS } from './criminal/rules';
import { TYPE_FACTS as MVR_TYPE_FACTS } from './mvr/rules';
import { RecordType as CRIM_RecordType } from './criminal/rules/record';
import { RecordType as MVR_RecordType } from './mvr/rules/record';

import { Fact } from './fact';
import { Namespace } from '../api';

export function getValidFacts(
  recordTypes: Array<CRIM_RecordType | MVR_RecordType>,
  namespace: Namespace,
): Fact[] {
  let TYPE_FACTS: any;
  switch (namespace) {
    case Namespace.criminal:
      TYPE_FACTS = CRIM_TYPE_FACTS;
      break;
    case Namespace.mvr:
      TYPE_FACTS = MVR_TYPE_FACTS;
      break;
    default:
      throw new Error(`INVALID NAMESPACE: ${namespace}`);
  }
  const facts = recordTypes.map(t => TYPE_FACTS[t]);
  return intersection(...facts);
}

export default getValidFacts;
