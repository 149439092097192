import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field } from 'modules/assess/ui/rules/common/field';
import { Fact } from 'modules/assess/models/mvr/rules';
import { InnerGroupType } from 'modules/assess/ui/rules/common/rule/editor/types';
import { Condition } from 'modules/assess/models/rules/condition';
import { ConditionsList } from 'modules/assess/ui/rules/common/rule';

const InnerGroupContainer = styled(M.Container)`
  background-color: ${colors.white} !important;
  margin: 1em 0 !important;
`;

const ItemTypeContainer = styled.div`
  margin: 0.6em 0 !important;
`;

type Props = {
  conditions: InnerGroupType;
};

const InnerGroupViewer: React.FC<Props> = ({ conditions }) => {
  const { t } = useTranslation('');
  const conditionsLabel = t('nouns.condition');
  const translation: any = t('assess:rules.facts.object_type', {
    returnObjects: true,
  });
  const appliesToLabel = `${translation.name} ${translation.verb}`.trim();

  const itemTypeCondition = conditions.find(c => c.fact === Fact.TYPE);
  const ruleConditions = conditions.filter(c => c.fact !== Fact.TYPE);
  const itemTypes = Array.isArray(itemTypeCondition?.value)
    ? itemTypeCondition?.value
        .map((v: string) => translation.values[v])
        .join(', ')
    : undefined;

  return (
    <InnerGroupContainer data-testid='assess-complex-rule-inner-group'>
      <ItemTypeContainer>
        <Field
          data-testid='complex-rule-item-type-field'
          label={appliesToLabel}
        >
          <span>{itemTypes}</span>
        </Field>
      </ItemTypeContainer>
      <Field
        data-testid='complex-rule-conditions-field'
        label={conditionsLabel}
      >
        <ConditionsList conditions={ruleConditions as Condition[]} />
      </Field>
    </InnerGroupContainer>
  );
};

export default InnerGroupViewer;
