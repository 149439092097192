import { Client as client } from 'api';
import { User } from 'types';
import { DASHBOARD_PATH } from './constants';

export type GetDashboardParams = {
  lookerName: string;
  currentUser: User;
  permutationArray: string[];
};

export const getDashboard = ({
  lookerName,
  currentUser,
  permutationArray,
}: GetDashboardParams): Promise<{ [key: string]: any }> => {
  const { account, id: userId } = currentUser;
  const accountUri = account?.uri_name;

  return client.get(
    `${DASHBOARD_PATH}/${lookerName}?account_uri=${accountUri}&user_id=${userId}&permutation=${JSON.stringify(
      permutationArray,
    )}&embed_domain=${window.location.origin}`,
  );
};
