import React, { useCallback, useEffect } from 'react';

import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import { Actions } from 'modules/assess/ui/state/rulesets/versions/instance';
import { ID } from 'modules/id';
import { useDraftUpdate } from '../api/hooks';
import { State, useDispatch } from '../store';

type Props = {
  ruleset?: ID;
};

type Type = React.FC<Props>;

export const Save: Type = ({ ruleset }) => {
  const action = useDraftUpdate(ruleset);
  const loading = action.result.status === 'loading';
  const state = State.useValue();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const entity = State.useEntity(state);
    action.call(entity);
  }, [action, state]);

  useEffect(() => {
    if (action.result.status === 'success') {
      dispatch(Actions.Update.create({ dirty: false }));
      action.result.reset();
    }
  }, [action, dispatch]);

  const customRules = state.rules?.custom.table || [];
  const countRules = state.rules?.count.table || [];
  const isEditingRules =
    Object.values(customRules).some(r => r?.editing) ||
    Object.values(countRules).some(r => r?.editing);

  return (
    <>
      <PageLoading enabled={loading} />
      <Button
        data-testid='assess-ruleset-version-actions-save'
        disabled={!ruleset || loading || !state.dirty || isEditingRules}
        kind='achievement'
        size='sm'
        trans='verbs.save'
        onClick={handleClick}
      />
    </>
  );
};

export default Save;
