import React from 'react';
import ExpandableKeyValue from './ExpandableKeyValue';

type Props = {
  collections: { [x: string]: any };
  completedAt: string;
};

const list = [
  {
    name: 'Date Opened',
    field: 'date_opened',
  },
  {
    name: 'Date Effective',
    field: 'date_effective',
  },
  {
    name: 'Current Balance',
    field: 'current_balance',
  },
  {
    name: 'Original Credit Grantor',
    field: 'original_credit_grantor',
  },
  {
    name: 'Original Credit Classification',
    field: 'original_credit_classification',
  },
  {
    name: 'Original Balance',
    field: 'original_balance',
  },
  {
    name: 'Past Due',
    field: 'past_due',
  },
  {
    name: 'Account Rating',
    field: 'account_rating',
  },
];

const amountFields = ['current_balance', 'original_balance', 'past_due'];

const Collections: React.FC<Props> = ({ collections, completedAt }) => {
  const missing = collections.length === 0;
  const missingInfo = {
    missing,
    completedAt,
    type: 'collection accounts',
  };

  return (
    <ExpandableKeyValue
      items={collections}
      heading='Collections'
      list={list}
      amountFields={amountFields}
      id='screenings-credit-report-collections'
      missingInfo={missingInfo}
    />
  );
};

export default Collections;
