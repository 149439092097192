import styled from 'styled-components';
import { M, colors, TrashCan, Edit } from '@dashboard-experience/mastodon';

const TableCell = styled(M.TableCell)`
  width: 200px !important;
  margin-left: 2rem;
  float: left;
`;

const ApplicationValueCell = styled(M.TableCell)`
  margin-left: 8rem;
  float: left;
`;

const TableRow = styled(M.TableRow)`
  :hover {
    background-color: ${colors.uiGrey100} !important;
  }
`;

const ColorDisplay = styled.div`
  display: flex;
  width: 160px;
`;

const StyledDiv = styled.div`
  height: calc(1.5em + 0.75rem + 2px);
  width: 75px;
  border-radius: 10%;
  border: 1px solid ${colors.uiNavy200};
`;

const FormControl = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: ${colors.uiGrey0};
  background-clip: padding-box;
  border: 1px solid ${colors.brandSlate3};
  border-radius: 0.25rem;
`;

const TableHeader = styled(M.TableHeader)`
  vertical-align: top !important;
`;

const StyledSpan = styled.span`
  text-decoration: underline;
`;

const StyledButton = styled(M.Button)`
  vertical-align: top !important;
  background-color: ${colors.uiGrey0} !important;
  float: right;
  border: none !important;
  margin-left: 10px !important;
  :hover {
    background-color: ${colors.uiGrey100} !important;
  }
`;

const StyledDeleteIcon = styled(TrashCan)`
  fill: ${colors.uiGrey900} !important;
`;

const StyledEditIcon = styled(Edit)`
  fill: ${colors.uiGrey900} !important;
`;

const ApplicationTable = styled(M.Table)`
  border: 2px solid ${colors.uiGrey100} !important;
  margin-bottom: 25px;
`;

const ApplicationsContainer = styled(M.TableContainer)`
  margin-bottom: 20px;
  width: 82%;
  margin-left: 10%;

  a {
    color: ${colors.brandAqua3} !important;
  }
  span {
    color: ${colors.brandNavy3} !important;
  }

  .cds--data-table table {
    margin-top: -2px !important;
  }

  .mastodon-table {
    margin-top: -2px !important;
  }

  .cds--data-table-content {
    width: 100% !important;
  }

  .cds--data-table td {
    padding-top: 14px !important;
    padding-bottom: 22px !important;
    vertical-align: top !important;
  }
  .cds--data-table thead {
    border-bottom: none !important;
  }

  .cds--data-table thead tr {
    background: ${colors.brandSlate1};
    border: none !important;
    height: 48px;
  }

  tr {
    :hover {
      background-color: ${colors.uiGrey100} !important;
    }
  }
`;

const CreateApplicationButton = styled(M.Button)`
  margin-bottom: 20px !important;
`;

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100px;
  overflow: hidden;
`;

const StyledSmallImg = styled.img`
  height: 2rem;
  width: 2rem;
`;

export {
  TableCell,
  ApplicationValueCell,
  TableRow,
  ColorDisplay,
  StyledDiv,
  FormControl,
  TableHeader,
  StyledSpan,
  StyledButton,
  StyledDeleteIcon,
  StyledEditIcon,
  ApplicationTable,
  ApplicationsContainer,
  CreateApplicationButton,
  StyledImg,
  StyledSmallImg,
};
