import React from 'react';
import { useQuery as useRuleset } from 'modules/assess/ui/ruleset/api';
import { Actions as Container } from 'modules/assess/ui/page';
import { ReviewButton } from '../../common/actions';

export const Actions: React.FC = () => {
  const query = useRuleset();
  const ruleset = query?.data;

  return (
    <Container>
      <ReviewButton ruleset={ruleset?.id} />
    </Container>
  );
};

export default Actions;
