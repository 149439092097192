import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import {
  PREMIUM_DECISIONS,
  NON_PREMIUM_DECISIONS,
} from 'modules/assess/models';
import { usePremium } from 'modules/assess/v2/ui/hooks';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import { useTaskSearchParams } from 'modules/case-management/ui/TasksPage/hooks';
import FilterTitle from './FilterTitle';

export const Assessment: React.FC = () => {
  const history = useHistory();
  const taskParams = useTaskSearchParams();

  const selectedItems = taskParams?.report_assessment
    ? taskParams.report_assessment.split(',')
    : [];

  const onChange = useCallback(
    ({ selectedItems }) => {
      const selections = selectedItems.map(
        (selection: { id: any }) => selection.id,
      );
      const searchParams = new URLSearchParams({
        ...taskParams,
        page: '1',
      } as Record<string, string>);
      if (selectedItems.length) {
        searchParams.set('report_assessment', selections.join(','));
      } else {
        searchParams.delete('report_assessment');
      }
      history.push({
        pathname: TASKS_PATH,
        search: searchParams.toString(),
      });
    },
    [history, taskParams],
  );

  const options = usePremium() ? PREMIUM_DECISIONS : NON_PREMIUM_DECISIONS;

  const formatOptions = (options: any) => {
    if (!options.length) return [];

    return options.map((option: string) => ({
      id: option,
    }));
  };

  const items = formatOptions(options);

  const formattedSelections = formatOptions(selectedItems);

  const itemToString = useCallback(
    (item: { id: string }) => capitalize(item.id),
    [],
  );

  const placeholderText = !formattedSelections.length ? 'All' : '';

  return (
    <M.MultiSelect
      id='case-management-tasks-page-assessment-filter'
      label={placeholderText}
      items={items || []}
      itemToString={itemToString}
      onChange={onChange}
      titleText={<FilterTitle />}
      initialSelectedItems={formattedSelections}
    />
  );
};

export default Assessment;
