import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { SetupButton, DeleteButton, Button } from './AddScreeningsAction';
import {
  SetupButtonContainer,
  SetupPendingContainer,
  SetupPendingIcon,
  AddButtonContainer,
} from './ListItemElements';
import OrderedLabel from './OrderedLabel';
import AddedLabel from './AddedLabel';

export type ButtonSelectionProps = {
  status: string;
  addedScreenings: string[];
  subtypeProps: {
    kind: string;
    disabled: boolean;
    onClick: Function;
    id: string;
    hasBasePackageScreenings?: boolean;
  };
  handleOpen: Function;
  screening: string;
};

const ButtonSelection: React.FC<ButtonSelectionProps> = ({
  status,
  addedScreenings,
  subtypeProps,
  handleOpen,
  screening,
}) => {
  const { disabled } = subtypeProps;
  const hasBasePackageScreenings =
    subtypeProps?.hasBasePackageScreenings || false;

  const showDelete = addedScreenings.includes(screening);
  const showAddButton = !addedScreenings.includes(screening) && !disabled;
  const showAddedLabel =
    !addedScreenings.includes(screening) &&
    disabled &&
    hasBasePackageScreenings;
  const showOrderedLabel =
    !addedScreenings.includes(screening) &&
    disabled &&
    !hasBasePackageScreenings;

  return (
    <>
      {status === 'disabled' && (
        <SetupButtonContainer>
          <SetupButton data-testid='setup-button' onClick={handleOpen}>
            Set up
          </SetupButton>
        </SetupButtonContainer>
      )}
      {status === 'pending' && (
        <SetupPendingContainer>
          <SetupPendingIcon icon='Renew' /> Setup in progress
        </SetupPendingContainer>
      )}
      {status === 'enabled' && (
        <AddButtonContainer>
          {showDelete && (
            <DeleteButton {...subtypeProps} data-testid='delete-button'>
              <M.Icon icon='TrashCan' />
            </DeleteButton>
          )}
          {showAddButton && (
            <Button {...subtypeProps} data-testid='add-button'>
              <M.Icon icon='Add' /> Add
            </Button>
          )}
          {showAddedLabel && <AddedLabel />}
          {showOrderedLabel && <OrderedLabel dataTestId={screening} />}
        </AddButtonContainer>
      )}
    </>
  );
};

export default ButtonSelection;
