import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { HEADERS } from '../constants';
import SeverityCellGroup from './SeverityCellGroup';
import { Severity } from './types';

const StyledRow = styled(M.TableRow)`
  td.severity-cell {
    padding: 1.5rem 1rem !important;
  }
  td.severity-cell.active {
    padding: 0rem !important;
  }
  td.charge {
    font-size: 0.9rem;
    height: 1px;
    padding-left: 2rem !important;
    width: 12em;
  }
  td.bulk-edit {
    background-color: ${colors.uiNavy50} !important;
  }
  .cds--form-item,
  .cds--number,
  .mastodon-number-input,
  .cds--number__input-wrapper,
  input {
    height: 100% !important;
  }
`;

type Props = {
  state: any;
  setState: (data: Partial<Severity>) => void;
  bulkEdit?: boolean;
};

const headers = HEADERS.slice(1);

const SeverityRow: React.FC<Props> = ({
  children,
  state,
  bulkEdit,
  setState,
}) => {
  return (
    <StyledRow>
      <td style={{ width: '1%' }} className={bulkEdit ? 'bulk-edit' : ''} />
      {children}
      {headers.map(header => (
        <SeverityCellGroup
          key={header.key}
          header={header.key}
          years={state[header.key]}
          setChargeState={setState}
        />
      ))}
    </StyledRow>
  );
};

export default SeverityRow;
