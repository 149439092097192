import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

import * as Types from '../types';

type Props = {
  onChange?: (show: Types.FilterShow) => void;
};

const keys: Array<Types.FilterShow | 'all'> = ['all', 'selected', 'unassigned'];

export const ShowSelector: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('assess');

  const handleChange = useCallback(
    (key: string) => {
      return onChange?.(key === 'all' ? undefined : (key as Types.FilterShow));
    },
    [onChange],
  );

  return (
    <M.RadioButtonGroup
      data-testid='assess-ruleset-assign-filters-show'
      defaultSelected='all'
      name='assess-ruleset-assign-filters-show'
      onChange={handleChange}
      orientation='vertical'
      valueSelected={undefined}
    >
      {keys.map(key => (
        <M.RadioButton
          id={`assess-ruleset-assign-filters-show-${key}`}
          key={key}
          labelText={t(`assess:ruleset.assign.filters.show.${key}`)}
          value={key}
        />
      ))}
    </M.RadioButtonGroup>
  );
};

export default ShowSelector;
