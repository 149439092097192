import React from 'react';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';
import { entries } from 'state/utilities/entities/cache';
import Option from './options';

export const List: React.FC = () => {
  const state = useState();
  const options = entries(state.options) || [];
  const children = options?.map(contextDate => (
    <Option key={contextDate.id} contextDate={contextDate} />
  ));

  return (
    <div data-testid='assess-rules-version-context-dates-list'>{children}</div>
  );
};

export default List;
