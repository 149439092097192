import { ThunkAction } from 'redux-thunk';
import {
  localStorageFallback,
  sessionStorageFallback,
} from '@dashboard-experience/utils';
import { postMessageToDashboard } from 'utils';
import { clearUserPreferences } from 'api/dashboardPreferences';

// eslint-disable-next-line import/prefer-default-export
export const clearUserCache =
  (): ThunkAction<any, any, any, any> => (_dispatch, getState) => {
    // Clears user cache from dashboard and session storage, so that refreshing the page forces the object to be fetched again
    // Use when making api requests that edit the user or account in some way to invalidate the cache so refreshes trigger a refetch
    const state = getState();
    const { ui = {} } = state;
    const { contextId } = ui;
    try {
      clearUserPreferences();
      sessionStorageFallback.removeItem('currentUser');
      localStorageFallback.removeItem('currentUser');
    } catch (e) {
      // session storage not available
    }

    postMessageToDashboard({
      messageId: 'clearUserCache',
      contextId,
    });
  };
