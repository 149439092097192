import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { M, colors, sizes } from '@dashboard-experience/mastodon';
import moment from 'moment';
import styled from 'styled-components';

export const ButtonAsLink = styled.button`
  background: none;
  border: none;
  color: ${colors.uiNavy600} !important;
  cursor: pointer;
  font-size: ${sizes.medium};
  padding: 0 !important;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  div.cds--form-item {
    flex: 0 1 auto;
  }
`;

const DriverRecordReportsSearch = ({ resultsCount = 0, onChangeValue }) => {
  const DEFAULT_STATE = {
    searchText: '',
    start: '',
    end: '',
  };

  const [state, setState] = useState(DEFAULT_STATE);
  const searchUpdate = {};

  const updateSearchAction = useCallback(
    e => {
      if (e.currentTarget.value && e.currentTarget.value !== '') {
        searchUpdate.candidate_id = e.currentTarget.value;
        setState({
          ...state,
          searchText: e.currentTarget.value,
        });
      } else {
        searchUpdate.candidate_id = '';
        setState({
          ...state,
          searchText: '',
        });
      }
      onChangeValue(searchUpdate);
    },
    [searchUpdate],
  );

  const datePickerFromOnChange = useCallback(
    e => {
      if (e && e.length) {
        searchUpdate.created_at_from = moment(e[0]).format('DD/MM/YYYY');
        setState({
          ...state,
          start: moment(e[0]).format('MM/DD/YY'),
        });
      } else {
        searchUpdate.created_at_from = '';
        setState({
          ...state,
          start: '',
        });
      }
      onChangeValue(searchUpdate);
    },
    [searchUpdate],
  );

  const datePickerToOnChange = useCallback(
    e => {
      if (e && e.length) {
        searchUpdate.created_at_to = moment(e[0]).format('DD/MM/YYYY');
        setState({
          ...state,
          end: moment(e[0]).format('MM/DD/YY'),
        });
      } else {
        searchUpdate.created_at_to = '';
        setState({
          ...state,
          end: '',
        });
      }
      onChangeValue(searchUpdate);
    },
    [searchUpdate],
  );

  const resetFilters = useCallback(() => {
    searchUpdate.candidate_id = '';
    searchUpdate.created_at_from = '';
    searchUpdate.created_at_to = '';
    setState(DEFAULT_STATE);
    onChangeValue(searchUpdate);
  }, [searchUpdate]);
  return (
    <M.Container
      id='driver-record-search'
      data-testid='driver-record-search'
      style={{ marginLeft: '1rem' }}
    >
      <M.Container.Row>
        <M.Container.Col>
          <h3>Search</h3>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          <M.Input
            id='keyword-id'
            name='keyword'
            labelText='Candidate ID'
            placeholder='Enter Candidate ID'
            onChange={updateSearchAction}
            value={state.searchText || ''}
          />
        </M.Container.Col>
        <M.Container.Col>
          <DatePickerContainer>
            <M.DatePicker
              id='date-picker'
              dateFormat='m/d/y'
              onChange={datePickerFromOnChange}
              datePickerType='single'
              value={state.start || ''}
            >
              <M.DatePickerInput
                id='date-picker-input-id-start'
                labelText='Start date'
                placeholder='MM/DD/YY'
              />
            </M.DatePicker>
            <M.DatePicker
              id='date-picker'
              dateFormat='m/d/y'
              onChange={datePickerToOnChange}
              datePickerType='single'
              value={state.end || ''}
            >
              <M.DatePickerInput
                id='date-picker-input-id-end'
                labelText='End date'
                placeholder='MM/DD/YY'
              />
            </M.DatePicker>
          </DatePickerContainer>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          {!isNaN(resultsCount) && (
            <div style={{ display: 'flex' }}>
              <div
                style={{ marginRight: '1rem' }}
              >{`${resultsCount} results`}</div>
              {JSON.stringify(state) !== JSON.stringify(DEFAULT_STATE) && (
                <ButtonAsLink
                  role='button'
                  type='button'
                  onClick={resetFilters}
                >
                  Reset filters
                </ButtonAsLink>
              )}
            </div>
          )}
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

DriverRecordReportsSearch.propTypes = {
  resultsCount: PropTypes.number,
  onChangeValue: PropTypes.func.isRequired,
};

export default DriverRecordReportsSearch;
