import { useCallback, useState } from 'react';
import {
  TABLE_SORT_CYCLE,
  TABLE_SORT_CYCLES,
  TABLE_SORT_DIRECTION,
} from './enums';

export const getNextSort = ({
  sortCycle = TABLE_SORT_CYCLE.ID_STATES_FROM_ASCENDING,
  oldDirection,
}: any) => {
  if (!oldDirection) {
    throw new TypeError(
      'Table sort direction is not defined. ' +
        'Likely that `getNextSort()` is called with non-sorted table column, which should not happen in regular condition.',
    );
  }
  const directions = TABLE_SORT_CYCLES[sortCycle];

  const index = directions.indexOf(oldDirection);
  if (index < 0) {
    if (oldDirection === TABLE_SORT_DIRECTION.NONE) {
      // If the current sort direction is `none` in bi-state sort cycle,
      // returns the first one in the cycle
      return directions[0];
    }
    throw new RangeError(
      `The given sort state (${oldDirection}) is not found in the given table sort cycle: ${sortCycle}`,
    );
  }
  return directions[(index + 1) % directions.length];
};

const useSortInfo = (initialSortInfo: any) => {
  const [sortInfo, setSortInfo] = useState(initialSortInfo);

  const invokeSetSortInfo = useCallback(
    ({ columnId, sortCycle, oldDirection }) => {
      const direction = getNextSort({ sortCycle, oldDirection });
      setSortInfo({
        columnId,
        direction,
      });
    },
    [setSortInfo],
  );
  return [sortInfo, invokeSetSortInfo];
};

export default useSortInfo;
