import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { includes } from 'lodash';
import * as Countries from 'i18n-iso-countries';
import { isInternationalCountry } from 'utils/Filters';
import { Dropdown } from '../../SharedItems';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  ADD_CHECKS,
} from '../../Context';

const CountryDropdown = styled(Dropdown)`
  width: 280px;
  margin: 10px 0;
`;

const COUNTRIES_NOT_AVAILABLE = ['AF'];

const countryItemToString = (item: string) => {
  const countryName = Countries.getName(item, 'en') || item || '';

  if (includes(COUNTRIES_NOT_AVAILABLE, item)) {
    return `${countryName} (Unavailable)`;
  }

  return countryName;
};

export type SelectCountryProps = {
  countries: string[];
  segmentation_enabled: boolean;
  hierarchy_present: boolean;
  internationalWorkLocationSelection: boolean;
  goBackClicked: boolean;
};

const SelectCountry: React.FC<SelectCountryProps> = ({
  countries = ['US'],
  segmentation_enabled,
  hierarchy_present,
  internationalWorkLocationSelection,
  goBackClicked,
}) => {
  const [countryChanged, setCountryChanged] = useState(false);
  const { state, dispatch } = useOrderBackgroundCheckContext();
  // Use base package to see whether the user has come from the review & submit page
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  // Determines if 'Domestic' or 'International'
  const updateGeoType = useCallback(
    (country: string) => {
      dispatch({
        type: actionTypes.SET_SELECTED_GEO,
        payload: {
          selectedGeo: isInternationalCountry(country)
            ? 'international'
            : 'domestic',
        },
      });
    },
    [dispatch],
  );

  const isCountryDisabled = useMemo(() => {
    return (
      state.workflow === ADD_CHECKS ||
      (hierarchy_present && Object.keys(state.node).length === 0) ||
      !segmentation_enabled
    );
  }, [hierarchy_present, segmentation_enabled, state.node, state.workflow]);

  const sortedCountries = useMemo(() => {
    const result = [...countries];
    result.sort((a, b) =>
      countryItemToString(a).localeCompare(countryItemToString(b)),
    );
    return result;
  }, [countries]);

  const handleCountryChange = useCallback(
    ({ selectedItem }: any) => {
      // Reset if user comes back to page and changes location
      if (state.location.country !== selectedItem && fromReviewAndSubmitPage) {
        dispatch({
          type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
          payload: {},
        });
      }

      updateGeoType(selectedItem);

      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: { location: { country: selectedItem, state: '', city: '' } },
      });

      setCountryChanged(true);
    },
    [dispatch, state.location.country, fromReviewAndSubmitPage, updateGeoType],
  );

  useEffect(() => {
    if (
      state.workflow === ADD_CHECKS ||
      countryChanged ||
      fromReviewAndSubmitPage ||
      goBackClicked
    )
      return;
    // Default to the country in array if there's only 1 country
    if (countries.length === 1) {
      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: { location: { country: countries[0], state: '', city: '' } },
      });
    }
    // Default to the US if we have many countries
    if (countries.length > 1) {
      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: { location: { country: 'US', state: '', city: '' } },
      });
    }
  }, [
    countries,
    dispatch,
    countryChanged,
    fromReviewAndSubmitPage,
    goBackClicked,
    state.workflow,
  ]);

  return (
    <>
      {segmentation_enabled && internationalWorkLocationSelection && (
        <CountryDropdown
          data-testid='country-dropdown-testid'
          items={sortedCountries}
          label='Country'
          disabled={isCountryDisabled}
          itemToString={countryItemToString}
          onChange={handleCountryChange}
          selectedItem={
            state.location.country !== '' ? state.location.country : null
          }
        />
      )}
    </>
  );
};

export default SelectCountry;
