import React from 'react';
import { GenericObject } from 'types';
import { humanizeOnUnderscore } from '@dashboard-experience/utils';
import { CellProps } from './types';
import { mostRecent } from './utils';

const Package: React.FC<CellProps> = ({ report, candidate }) => {
  const { reports } = candidate;
  const mostRecentReport: GenericObject | undefined = mostRecent(reports);

  const displayedReport = report || mostRecentReport;
  return displayedReport?.package ? (
    <span>{humanizeOnUnderscore(displayedReport.package)}</span>
  ) : null;
};

export default Package;
