import { capitalize } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button as ComponentButton } from 'components/Button';
import { Namespace } from 'modules/assess/api';
import { ID } from 'modules/id';

type Props = {
  id: ID;
  namespace: Namespace;
};

export const Edit: React.FC<Props> = ({ namespace, id }) => {
  const { t } = useTranslation('assess:v2');
  const text = capitalize(t('home.guidelines.edit'));
  const history = useHistory();
  const url = `assess/${namespace}/rulesets/${id}/versions/draft`;
  const onClick = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return (
    <ComponentButton
      kind='primary'
      onClick={onClick}
      size='sm'
      id='assess-v2-ui-home-guidelines-records-actions-edit-button'
    >
      {text}
    </ComponentButton>
  );
};

export default Edit;
