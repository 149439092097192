import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import Button from 'components/Button';

type Props = {
  open: boolean;
  onClose: () => void;
  isAsync: boolean;
};

export const ConfirmationModal: React.FC<Props> = ({
  open,
  onClose,
  isAsync,
}) => {
  const modalTestId = 'account-hierarchy-csv-confirmation-modal';
  return (
    <M.ComposedModal data-testid={modalTestId} open={open}>
      <M.ModalHeader data-testid={`${modalTestId}-header`} closeModal={onClose}>
        <h4>Account Hierarchy Submitted</h4>
      </M.ModalHeader>
      <M.ModalBody data-testid={`${modalTestId}-body`}>
        {isAsync
          ? 'You submitted your hierarchy. Your hierarchy will be ready in a few minutes.\n\n You will receive an email once the upload is completed.'
          : 'You submitted your hierarchy. Your hierarchy will be ready in a few seconds.'}
      </M.ModalBody>
      <M.ModalFooter data-testid={`${modalTestId}-footer`}>
        <Button
          kind='primary'
          trans='verbs.close'
          onClick={onClose}
          data-testid='account-hierarchy-csv-modal-confirmation-ok'
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ConfirmationModal;
