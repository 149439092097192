import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Category } from 'modules/assess/models/mvr/category';
import { CategoryCode } from 'modules/assess/models/mvr/category-code';
import { useCCSV } from '../../context';
import { useUpdate } from './api';

type Props = {
  categories?: Array<Category>;
  categoryCode: CategoryCode;
};

export const CategoryMappingTableCell: React.FC<Props> = ({
  categories,
  categoryCode,
}) => {
  const [category, setCategory] = useState(categoryCode.category?.name);
  const ccsv = useCCSV();
  const items = categories?.map(category => ({
    id: category.id,
    label: category.name,
  }));

  useEffect(() => {
    setCategory(categoryCode.category?.name || '');
  }, [categoryCode.category]);

  const update = useUpdate(ccsv?.category_code_set?.id, ccsv?.id);

  const changeCategoryMapping = useCallback(
    selectedCategory => {
      setCategory(selectedCategory.selectedItem.label);
      update.call({
        code: categoryCode.code,
        categoryId: selectedCategory.selectedItem.id,
      });
    },
    [categoryCode.code, update],
  );

  return ccsv?.draft ? (
    <M.Dropdown
      items={items}
      onChange={changeCategoryMapping}
      selectedItem={category}
    />
  ) : (
    <span>{categoryCode.category?.name}</span>
  );
};

export default CategoryMappingTableCell;
