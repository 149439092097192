import React from 'react';
import {
  Adjudication,
  Assessment,
  CandidateStory,
  Communicator,
  Report,
  ReportChangeEvent,
  ReportTask,
  Screening,
  UserAction,
} from './EventTypes';
import { TimelineEvent } from './types';

const eventComponents = {
  communicator: Communicator,
  report: Report,
  screening: Screening,
  user_action: UserAction,
  assessment: Assessment,
  adjudication: Adjudication,
  candidate_story: CandidateStory,
  report_task: ReportTask,
  report_change_event: ReportChangeEvent,
};

const getComponentName = (type: string) =>
  eventComponents[type as keyof typeof eventComponents];

const TimelineRow: React.FC<{
  timelineEvent: TimelineEvent;
}> = ({ timelineEvent }) => {
  const EventComponent: React.FunctionComponent<{
    timelineEvent: TimelineEvent;
  }> = getComponentName(timelineEvent.type);
  return <EventComponent timelineEvent={timelineEvent} />;
};

export default TimelineRow;
