import React from 'react';
import { FieldComponentProps } from '../types';

const MvrRulesetApplied: React.FC<FieldComponentProps> = ({
  report,
  testid,
}) => {
  return (
    <div data-testid={testid}>
      {report.assessment?.mvr_ruleset.name} (Version{' '}
      {report.assessment?.mvr_ruleset.version.number})
    </div>
  );
};

export default MvrRulesetApplied;
