import React from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { Container } from '../styles';
import InternationalBasicPackageCard from './InternationalBasicPackageCard';
import InternationalEssentialPackageCard from './InternationalEssentialPackageCard';
import InternationalProfessionalPackageCard from './InternationalProfessionalPackageCard';

const InternationalPackages = ({
  selectedPackage,
  setSelectedPackage,
}: PackageCardProps) => {
  const cardProps = {
    selectedPackage,
    setSelectedPackage,
  };
  return (
    <Container data-testid='international-packages-container'>
      <InternationalBasicPackageCard {...cardProps} />
      <InternationalEssentialPackageCard {...cardProps} />
      <InternationalProfessionalPackageCard {...cardProps} />
    </Container>
  );
};

export default InternationalPackages;
