import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Signature } from 'modules/assess/ui/common';
import { Container, Spacer } from 'modules/assess/ui/common/content';

export type Props = {
  confirm: (confirmed: boolean) => void;
};

export const Content: React.FC<Props> = ({ confirm }) => {
  const namespace = useNamespace();
  const { t } = useTranslation('assess');
  const message = t('assess:ruleset.version.publish.message');
  const affirmation = t(
    `assess:ruleset.version.publish.${namespace}.affirmation`,
  );

  return (
    <Container>
      <p>{message}</p>
      <Spacer />
      <p>{affirmation}</p>
      <Spacer />
      <Signature confirm={confirm} />
    </Container>
  );
};

export default Content;
