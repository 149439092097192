import isPlainObject from 'lodash/isPlainObject';
import { Namespace } from 'modules/assess/api';
import * as ruleset from 'modules/assess/api/ruleset';
import * as versions from 'modules/assess/api/ruleset-versions';
import * as Entity from 'modules/assess/models/rulesets/version';
import { useNamespace } from 'modules/assess/ui/router/context';
import { ID } from 'modules/id';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

export const useActive = (id?: string) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'active'];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.active(context, id);
  };

  const result = useQuery(key, fetch);

  return result;
};

export const useDraft = (id?: string) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'draft'];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.fetch(context, id);
  };

  const result = useQuery(key, fetch);
  return result;
};

export const useDraftCreate = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'draft'];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.create(context, id);
  };

  const [call, result] = useMutation(fetch, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        if ((entity as { id: unknown }).id !== id) {
          return false;
        }

        return !field || (field === 'draft' && !data);
      });

      if (data) {
        // Update query if updated object was returned
        queryCache.setQueryData(key, data);
      }
    },
  });

  return {
    call,
    result,
  };
};

// After publishing on assess v2, we keep the draft cache only while the success dialog is being shown.
// We refetch the active version in order to render the right home page, and a new draft is requested.
export const useDraftPublish = (id?: ID, keepDraftCache?: boolean) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.publish(context, id);
  };

  const [call, result] = useMutation(fetch, {
    onSuccess: () => {
      queryCache.removeQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        if ((entity as { id: unknown }).id !== id) {
          return false;
        }

        if (keepDraftCache) return field === 'active';

        return (
          !field ||
          field === 'active' ||
          field === 'draft' ||
          field === 'versions'
        );
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useDraftUpdate = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'draft'];

  const fetch = (params: Partial<Entity.Type>) => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.update(context, id, params);
  };

  const [call, result] = useMutation(fetch, {
    onSuccess: (data: Entity.Type) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the field is not set (refetch the entire ruleset to get has_changes_from_active_version)
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        if ((entity as { id: unknown }).id !== id) {
          return false;
        }

        if (field === 'diff') return true;

        return !field || (field === 'draft' && !data);
      });

      if (data) {
        // Update query if updated object was returned
        queryCache.setQueryData(key, data);
      }
    },
  });

  return {
    call,
    result,
  };
};

export const useDraftDestroy = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const request = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.destroy(context, id);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        return !(entity as { id: unknown }).id;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useDraftImportCSV = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);

  const fetch = (file: File) => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.lookbackFromCSV(context, id, file);
  };

  const [call, result] = useMutation(fetch, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        if (ns !== namespace) {
          return false;
        }

        if (!isPlainObject(entity)) {
          return true;
        }

        if ((entity as { id: unknown }).id !== id) {
          return false;
        }

        return field === 'draft';
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useFetch = (id?: string) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = versions.uri(context);
  const key: AnyQueryKey = [namespace, { id }];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return versions.fetch(context, id);
  };

  const result = useQuery(key, fetch);
  return result;
};

export const usePromote = () => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);

  const fetch = (id: string) => {
    if (!id) {
      return Promise.reject();
    }
    return versions.promote(context, id);
  };

  const [call, result] = useMutation(fetch, {
    onSuccess: (data: Entity.Type, id: string) => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity, field] }) => {
        return ns === namespace;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useDraftDiff = (id?: string) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = ruleset.uri(context);
  const key: AnyQueryKey = [namespace, { id }, 'diff'];

  const fetch = () => {
    if (!id) {
      return Promise.reject();
    }
    return ruleset.draft.diff(context, id);
  };

  const result = useQuery(key, fetch);
  return result;
};
