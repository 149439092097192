import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import Kind from 'modules/assess/models/rulesets/kind';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { useState } from 'modules/assess/ui/ruleset/version/store';

const LabelContainer = styled.div`
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight};
`;

const ConfigLink = styled.span`
  padding-left: 1em;
  color: ${colors.uiNavy600};
  cursor: pointer;
`;

export const Title: React.FC = () => {
  const state = useState();

  const { t } = useTranslation('');
  const edit = t('verbs.edit');
  const view = t('verbs.view');
  const title = t('assess:ruleset.version.context_dates_panel.title');

  const readonly = useReadOnly() || state.kind !== Kind.draft;
  const linkText = readonly ? view : edit;

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const onClick = useCallback(() => {
    history.push(`${currentPath}/context-dates`);
  }, [currentPath, history]);

  return (
    <LabelContainer data-testid='assess-ruleset-version-context-dates-panel-title'>
      {title}

      <ConfigLink
        data-testid='assess-ruleset-version-context-dates-panel-title-link'
        onClick={onClick}
      >
        {linkText}
      </ConfigLink>
    </LabelContainer>
  );
};

export default Title;
