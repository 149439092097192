/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchContinuousServicesBulkActions } from 'actions';

import { hasPermission } from '@dashboard-experience/utils';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { M } from '@dashboard-experience/mastodon';
import { LoadingScreen } from 'components';
import ContinuousServicesBulkActionHeader from './BulkActionHeader';
import BulkActionTableCell from './BulkActionTableCell';
import BulkActionButton from './BulkActionButton';

const LeftButtonsContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4rem;
`;

const Header = styled.h4`
  margin: 1rem;
`;

// Remove when table styles are finalized in Mastodon
const TableHeader = styled(M.TableHeader)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

const tableHeaders = [
  { key: 'status', name: 'status' },
  { key: 'date', name: 'date' },
  { key: 'action', name: 'action' },
  { key: 'product', name: 'product' },
  { key: 'file', name: 'file' },
  { key: 'uploader_email', name: 'uploaderEmail' },
  { key: 'errors', name: 'errors' },
];
export class BulkActionTable extends PureComponent {
  static propTypes = {
    account: PropTypes.object,
    currentUser: PropTypes.object,
    t: PropTypes.func.isRequired,
    bulkActions: PropTypes.array,
    fetchContinuousServicesBulkActions: PropTypes.func,
    paramsBulkAction: PropTypes.object.isRequired,
    bulkActionsTotal: PropTypes.number.isRequired,
    isInitialBulkActionState: PropTypes.bool.isRequired,
    contextId: PropTypes.string,
  };

  static defaultProps = {
    account: {},
    currentUser: {},
    bulkActions: [],
    paramsBulkAction: {},
    fetchContinuousServicesBulkActions: () => {},
    contextId: null,
  };

  async componentDidMount() {
    const {
      accountExists,
      props: { fetchContinuousServicesBulkActions, account, paramsBulkAction },
    } = this;

    if (accountExists()) {
      const { id } = account;
      await fetchContinuousServicesBulkActions(id, paramsBulkAction);
    }
  }

  accountExists = () => {
    const { account } = this.props;
    return Object.keys(account).length > 0;
  };

  getTableContent = ({ rows, headers, getRowProps }) => {
    const { t, bulkActions, currentUser } = this.props;
    return (
      <M.TableContainer className='hackathon'>
        <M.Table>
          <M.TableHead className='border-bottom'>
            <M.TableRow>
              {headers.map(header => {
                return (
                  <TableHeader key={header.key}>
                    {t(`headings.${header.name}`)}
                  </TableHeader>
                );
              })}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((bulkAction, i) => {
              // Ensure rows and bulkActions stay in sync
              const row = bulkActions[i];
              if (!row) return null;
              return (
                <Fragment key={row.id}>
                  <M.TableRow
                    key={row.id}
                    {...getRowProps({ row: bulkAction })}
                  >
                    {bulkAction.cells.map((cell, j) => {
                      const cellProps = {
                        header: headers[j],
                        row,
                        cell,
                        currentUser,
                      };
                      return (
                        <BulkActionTableCell key={cell.id} {...cellProps} />
                      );
                    })}
                  </M.TableRow>
                </Fragment>
              );
            })}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    );
  };

  /**
   * @name handlePaginationClick
   * @function
   * @memberOf AssignedbulkActionsQueueTable
   * @description Callback method for pagination interaction
   * @param {integer} pageIndex - The zero-index of the page clicked
   */
  handlePaginationClick = pageIndex => {
    // eslint-disable-next-line no-shadow
    const { account, fetchContinuousServicesBulkActions, paramsBulkAction } =
      this.props;
    const { id } = account;
    const newParamsBulkAction = { ...paramsBulkAction };
    newParamsBulkAction.page = pageIndex + 1;
    fetchContinuousServicesBulkActions(id, newParamsBulkAction);
  };

  /**
   * @name getPageCount
   * @function
   * @memberOf AssignedbulkActionsQueueTable
   * @description Calculate the page count for the purposes of rendering the Pagination component
   * @returns {integer}  - The page count
   */
  getPageCount = (itemCount, perPage) => Math.ceil(itemCount / perPage);

  render() {
    const {
      bulkActions,
      bulkActionsTotal,
      paramsBulkAction,
      isInitialBulkActionState,
      contextId,
      currentUser,
      t,
    } = this.props;
    const pageIndex = paramsBulkAction.page - 1;
    const pageCount = this.getPageCount(
      bulkActionsTotal,
      paramsBulkAction.per_page,
    );

    const { handlePaginationClick } = this;
    if (isInitialBulkActionState) {
      return <LoadingScreen />;
    }
    return (
      <>
        <ContinuousServicesBulkActionHeader />
        {hasPermission(
          currentUser,
          'perform_continuous_services_bulk_action',
        ) && (
          <LeftButtonsContainer>
            <BulkActionButton contextId={contextId} />
          </LeftButtonsContainer>
        )}
        <Header>{t('headings.enrollmentHistory')}</Header>
        {bulkActions.length > 0 && (
          <M.DataTable
            rows={bulkActions}
            headers={tableHeaders}
            render={this.getTableContent}
          />
        )}
        {bulkActions.length > 0 && (
          <M.Pagination
            pageCount={pageCount}
            onPageClick={handlePaginationClick}
            selectedIndex={pageIndex}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.currentUser,
  account: state.currentUser.currentUser.account,
  bulkActions: state.continuousServices.bulkActionReducer.bulkActions,
  paramsBulkAction: state.continuousServices.bulkActionReducer.paramsBulkAction,
  bulkActionsTotal: state.continuousServices.bulkActionReducer.bulkActionsTotal,
  fetchContinuousServicesBulkActions:
    state.continuousServices.bulkActionReducer.fetchRequest,
  isInitialBulkActionState:
    state.continuousServices.bulkActionReducer.isInitialBulkActionState,
});

const mapDispatchToProps = dispatch => ({
  fetchContinuousServicesBulkActions: (accountId, paramsBulkAction) =>
    dispatch(fetchContinuousServicesBulkActions(accountId, paramsBulkAction)),
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkActionTable)),
);
