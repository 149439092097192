import React, { useCallback } from 'react';
import styled from 'styled-components';
import Option from 'modules/assess/models/context-date/option';
import Description from './Description';
import Select from './Select';

type Props = {
  checked?: boolean;
  onChange: (selected: boolean, option: Option) => void;
  option: Option;
};

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 0.5em;
  > * {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  > *:nth-child(1) {
    flex-grow: 0;
  }
`;

export const Container: React.FC<Props> = ({ checked, onChange, option }) => {
  const handleChange = useCallback(
    (event: boolean) => {
      onChange(event, option);
    },
    [onChange, option],
  );

  return (
    <Content data-testid='assess-ruleset-version-context-dates-create-option-container'>
      <Select checked={checked} option={option} onChange={handleChange} />
      <Description option={option} />
    </Content>
  );
};

export default Container;
