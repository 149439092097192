import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { startCase } from 'lodash';
import { toAmount, toPercent } from './utils';

const { Table, TableHead, TableHeader, TableBody, TableRow, TableCell } =
  M.DataTable;

type Props = {
  summary: { [x: string]: any };
};

export const headers = [
  '',
  'Count',
  'High Credit',
  'Credit Limit',
  'Balance',
  'Payment',
  'Past Due',
  'Available Credit',
];

export const rows = [
  { key: 'revolving_amount', count: 'revolving_trade_count' },
  { key: 'installment_amount', count: 'installment_trade_count' },
  { key: 'mortgage_amount', count: 'mortgage_trade_count' },
  { key: 'open_amount', count: 'open_trade_count' },
];

const amountCells = [
  'high_credit',
  'credit_limit',
  'current_balance',
  'monthly_payment',
  'past_due',
  'percent_available_credit',
];

const renderAmountCells = (field: { [x: string]: any }) => {
  if (!field) return null;
  return amountCells.map((cell: string) => {
    const parse = cell === 'percent_available_credit' ? toPercent : toAmount;
    return <TableCell key={cell}>{parse(field[cell])}</TableCell>;
  });
};

const SummaryTable: React.FC<Props> = ({ summary }) => {
  return (
    <Table
      data-testid='screenings-credit-report-summary-table'
      style={{ tableLayout: 'fixed' }}
    >
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableHeader data-testid='summary-table-header' key={header}>
              {header}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ key, count }) => {
          const field = summary[key];
          return (
            <TableRow data-testid='summary-table-row' key={key}>
              <TableCell>{startCase(key.split('_')[0])}</TableCell>
              <TableCell>{Number(summary.record_counts[count])}</TableCell>
              {renderAmountCells(field)}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SummaryTable;
