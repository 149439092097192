import { colors, M } from '@dashboard-experience/mastodon';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  font-size: 32px;
  line-height: 39.07px;
  font-weight: 700;
  color: ${colors.uiTextPrimaryLight};
`;

export const StyledContainer = styled(M.Container)`
  padding: 1rem !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
`;

export const StyledContainerCol = styled(M.Container.Col)`
  font-size: 14px;
  color: ${colors.uiTextPrimaryLight};
  font-weight: 400;

  b {
    font-weight: bold;
  }
`;

type Props = {
  children: ReactNode;
};

const BulkOrderHistoryHeader: React.FC<Props> = ({ children }) => {
  return (
    <HeaderWrapper>
      <Header>Bulk order history</Header>
      <StyledContainer type='info'>
        <M.Container.Row>
          <StyledContainerCol data-testid='bulk-order-history-banner'>
            {children}
          </StyledContainerCol>
        </M.Container.Row>
      </StyledContainer>
    </HeaderWrapper>
  );
};

export default BulkOrderHistoryHeader;
