import { AnyQueryKey, useQuery } from 'react-query';

import * as API from 'modules/assess/api/rulesets';
import { ID } from 'modules/id';
import { Namespace } from '../../../api';
import { useNamespace, useNamespacedRoute } from '../../router/context';

export const usePath = (id?: ID, version?: ID) => {
  let path = useNamespacedRoute('rulesets');

  if (id) {
    path += `/${id}`;

    if (version) {
      path += `/versions/${version}`;
    }
  }

  return path;
};

export const useSearch = (params?: API.SearchParams) => {
  const ns = useNamespace() || Namespace.criminal;
  const namespace = API.uri(ns);
  const key: AnyQueryKey = [namespace, { id: undefined }, params];

  const request = () => {
    return API.search(ns, params);
  };

  const result = useQuery(key, request);
  return result;
};
