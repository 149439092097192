import React, { useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import UserContext from 'context/CurrentUser';
import ContextContainer from 'containers/ContextContainer';
import { usePackage } from 'api/packages/hooks';
import { EditScreenings } from 'components/AddScreenings/';
import { mapPackage } from 'components/AddScreenings/shared/utils';

const Container = styled.div`
  margin-top: -25px;
`;

const EditPackagesPage = () => {
  const currentUser = useContext(UserContext);
  const accountId = currentUser?.account?.id;

  const params = useParams<any>();
  const { packageId } = params;

  const packageQuery = usePackage(accountId, packageId);

  const basePackage: any = packageQuery.data && mapPackage(packageQuery.data);

  return (
    <Container className='Container'>
      {basePackage && (
        <EditScreenings
          account={currentUser.account}
          basePackage={basePackage as any}
          packageId={packageId}
        />
      )}
    </Container>
  );
};

export default ContextContainer(EditPackagesPage);
