import React from 'react';
import { CATEGORIES } from 'modules/assess';
import { getFilteredCategories } from 'modules/assess/v2/ui/common/lookback-rules/utils';
import Category from './Category';

type Props = {
  search?: string;
};

const Container: React.FC<Props> = ({ search }) => {
  const filteredCategories = getFilteredCategories(CATEGORIES, search);
  const rows = filteredCategories.map(item => (
    <Category key={item.id} item={item} search={search} />
  ));
  return (
    <div data-testid='assess-v2-ui-guideline-lookback-period-table-container'>
      {rows}
    </div>
  );
};

export default Container;
