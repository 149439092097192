import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

type Props = {
  title: React.ReactElement;
  tooltipText?: string;
  iconName?: string;
  id: string;
};

const PostHireModuleContainer: React.FC<Props> = ({
  title,
  iconName = 'HelpFilled',
  tooltipText,
  id,
  ...props
}) => {
  return (
    <div
      style={{ margin: '0.25rem 0' }}
      id={id}
      data-testid='posthire-container'
    >
      <M.Grid condensed>
        <M.GridRow>
          <M.GridCol style={{ display: 'flex', marginBottom: '0.75rem' }}>
            <span style={{ color: colors.uiTextPrimaryLight }}>
              <strong>{title}</strong>
            </span>
            {tooltipText && (
              <M.TooltipDefinition
                align='bottom'
                highlighted={false}
                definition={tooltipText}
                style={{ marginLeft: '0.5rem' }}
              >
                <M.Icon icon={iconName} />
              </M.TooltipDefinition>
            )}
          </M.GridCol>
        </M.GridRow>
      </M.Grid>
      {props.children}
    </div>
  );
};

export default PostHireModuleContainer;
