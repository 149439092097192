import React from 'react';
import { GenericObject } from 'types';
import { formatLocation } from './utils';
import { FieldComponentProps } from '../types';

const WorkLocations: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const { work_locations } = report;
  return work_locations.map((location: GenericObject, idx: number) => {
    const { country, state, city } = location;
    return (
      <div key={`${location.state}-${idx + 1}`} data-testid={testid}>
        {formatLocation(country, state, city)}
        {idx !== work_locations.length - 1 ? ',' : ''}
      </div>
    );
  });
};

export default WorkLocations;
