import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const Section = styled.section.attrs({
  className: 'candidate-info-section',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${colors.uiGrey200};
  padding: 24px;
`;

export default Section;
