import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import Decision from 'modules/assess/v2/models/decision';

type Props = {
  assessEligibleTreatment?: boolean;
  checked?: boolean;
  decision?: Decision;
  handleChange?: (e: boolean) => void;
};

export const Content: React.FC<Props> = ({
  assessEligibleTreatment,
  checked,
  decision,
  handleChange,
}) => {
  const { t } = useTranslation('assess');
  const path = assessEligibleTreatment
    ? '.assess_eligible_item_treatment_enabled'
    : '';
  const translations: any = t(
    `assess:settings.reports.eligible-records.report-view.confirmation-dialog${path}`,
    { returnObjects: true, decision },
  );

  const handleCheck = useCallback(
    (event, { checked }) => {
      handleChange && handleChange(checked);
    },
    [handleChange],
  );

  return (
    <>
      <p>{translations.description}</p>
      <M.Checkbox
        checked={checked}
        id='assess-settings-confirmation-dialog-checkbox'
        labelText={translations.confirmation}
        onChange={handleCheck}
      />
    </>
  );
};

export default Content;
