import React from 'react';
import { AtsConfigFieldSchema } from 'api/atsIntegration';
import { SectionHeader } from '../../shared';
import ConfigInput from './ConfigInput';
import { ButtonContainer, Button, Container } from './styles';

type Props = {
  configs: Record<string, string | boolean>;
  configErrors: Record<string, string>;
  configSchema: AtsConfigFieldSchema[];
  onSave: () => void;
  onUpdate: (key: string, value: unknown) => void;
  onReset: () => void;
};

const ConfigurationsSection: React.FC<Props> = ({
  configs,
  configErrors,
  configSchema,
  onSave,
  onUpdate,
  onReset,
}: Props) => {
  return (
    <Container data-testid='ats-config-container'>
      <SectionHeader sectionKey='configuration' header='Configurations' />
      {configSchema.map(
        ({ encrypted, field_name, field_type, description }) => (
          <ConfigInput
            key={`configInput-${field_name}`}
            configKey={field_name}
            value={(configs[field_name] ?? '') as any}
            fieldType={field_type as any}
            description={description}
            encrypted={encrypted}
            onChange={onUpdate}
            invalidText={configErrors[field_name]}
          />
        ),
      )}
      <ButtonContainer data-testid='ats-config-save-button-container'>
        <Button
          data-testid='ats-config-cancel'
          kind='secondary'
          onClick={onReset}
        >
          Cancel
        </Button>
        <Button data-testid='ats-config-save' kind='primary' onClick={onSave}>
          Save
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ConfigurationsSection;
