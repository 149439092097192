import { useQuery, AnyQueryKey } from 'react-query';
import {
  fetchAdjudicationEvents,
  fetchReportActionEvents,
} from 'modules/adjudication/api';

export const useAdjudicationEvents = (reportId: string) => {
  const key: AnyQueryKey = ['adjudication-events', { id: reportId }];

  const request = () => fetchAdjudicationEvents(reportId);

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId,
    staleTime: Infinity,
  });

  return result;
};

export const useReportActionEvents = (reportId: string) => {
  const key: AnyQueryKey = ['report-action-events', { id: reportId }];

  const request = () => fetchReportActionEvents(reportId);

  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!reportId,
    staleTime: Infinity,
  });

  return result;
};
