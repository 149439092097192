import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportStatuses, getResultOrStatus } from '@dashboard-experience/utils';
import { CountyCivilSearch, CivilRecord, ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { useUser } from 'context/CurrentUser';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';

const CountyCivil: React.FC<ReportProps> = ({ report, reportStatusType }) => {
  const currentUser = useUser();
  const { county_civil_searches: civilSearches } = report;

  return civilSearches && civilSearches?.length > 0 ? (
    <M.Screening
      title='County Civil Searches'
      screening={civilSearches}
      statusType={reportStatusType}
      id={SCREENING_TITLES.county_civil_searches.id}
      data-testid='screenings-county-civil'
    >
      {civilSearches.map((civilSearch: CountyCivilSearch) => {
        const searchTitle = `${civilSearch.county}, ${civilSearch.state}`;
        const status = getResultOrStatus(civilSearch);
        return (
          <M.SubScreening
            key={searchTitle}
            title={searchTitle}
            screening={civilSearch}
            statusType={reportStatusType}
          >
            {status === ReportStatuses.CONSIDER &&
              civilSearch?.records?.map((record: CivilRecord) => (
                <M.Record
                  key={record.id}
                  record={record}
                  currentUser={currentUser}
                  report={report}
                  reportStatusType={reportStatusType}
                  chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
                />
              ))}
          </M.SubScreening>
        );
      })}
    </M.Screening>
  ) : null;
};

export default CountyCivil;
