import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PathName, usePath } from 'modules/assess/ui/router/mvr/paths';

import { ID } from 'modules/id';

export type Type = React.FC<Props>;

type Props = {
  item: ID;
  version: ID;
  onClose: () => void;
};

export const VersionLink: Type = ({ item, version, onClose }) => {
  const path = usePath(PathName.categories, item, version);
  const { t } = useTranslation('assess');
  const text = t('assess:mvr.category-sets.instance.versions.link.view');

  return (
    <Link to={path} onClick={onClose}>
      {text}
    </Link>
  );
};

export default VersionLink;
