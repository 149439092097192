import React from 'react';
import { Page } from 'modules/assess/ui';

export type Props = {};

export const RulesetVersionsContainer: React.FC<Props> = () => {
  return (
    <Page.Container data-testid='assess-ruleset-versions-container'>
      <h1>Assess Ruleset Versions</h1>
    </Page.Container>
  );
};

export default RulesetVersionsContainer;
