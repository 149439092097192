import { Initializer } from 'state/utilities/state';
import * as Entity from '../../../../../models/rulesets/version';
import * as Rules from '../../../rules';

export type Type = Omit<Entity.Type, 'rules'> & {
  dirty: boolean;
  rules: Rules.State.Type;
};

export const initialize: Initializer<Type> = () => ({
  description: '',
  dirty: false,
  rules: Rules.State.initialize(),
});

export const entity = (state: Type): Entity.Type => {
  return {
    ...state,
    rules: Rules.State.entities(state.rules),
  };
};
