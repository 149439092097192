import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Grid = styled(M.Grid)`
  h2 {
    color: ${colors.brandNavy4} !important;
  }

  .cds--overflow-menu-options::after {
    display: none; // Gets rid of the white tab in the corner of the menu
  }

  .mastodon-container.container-transparent {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export default Grid;
