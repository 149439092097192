import React from 'react';
import styled from 'styled-components';
import Icon, { ActionMap } from 'modules/assess/ui/common';
import { useState } from 'modules/assess/ui/ruleset/version/context-dates/store';

type Props = {
  actions: Pick<ActionMap, 'positionDown' | 'positionUp' | 'remove'>;
};

const Container = styled.div`
  display: flex;
  > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Component: React.FC<Props> = ({ actions }) => {
  const { disabled } = useState();
  const { positionDown, positionUp, remove } = actions;
  if (disabled) {
    return null;
  }

  return (
    <Container>
      <Icon name='positionDown' onClick={positionDown} tooltip={false} />
      <Icon name='positionUp' onClick={positionUp} tooltip={false} />
      <Icon name='remove' onClick={remove} />
    </Container>
  );
};

export default Component;
