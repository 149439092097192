/* eslint-disable react/destructuring-assignment */
import {
  AdversibleItemsPayload,
  IndividualizedAssessment,
  FileStackResponse,
} from 'modules/adjudication/data';

export type NavigationAction = {
  onClick?: () => void;
  name?: string;
  disabled?: boolean;
} | null;

export type Navigation = {
  next?: NavigationAction;
  back?: NavigationAction;
  close?: NavigationAction;
  skip?: NavigationAction;
};

export type Action =
  | { type: 'SET_ADVERSE_ITEMS'; payload: AdversibleItemsPayload[] }
  | { type: 'SET_INITIAL_ASSESSMENT'; payload: string }
  | { type: 'UPLOAD_INDIVIDUALIZED_ASSESSMENT'; payload: FileStackResponse }
  | { type: 'REMOVE_INDIVIDUALIZED_ASSESSMENT' }
  | { type: 'SET_NAVIGATION'; payload: Navigation };

export type Dispatch = (action: Action) => void;

export type State = {
  [key: string]: any;
  adverseItems: Array<AdversibleItemsPayload>;
  individualizedAssessment: IndividualizedAssessment | null;
  navigation?: Navigation;
};

export const initialState = {
  adverseItems: [] as Array<AdversibleItemsPayload>,
  individualizedAssessment: null,
  navigation: {
    next: null,
    back: null,
    skip: null,
    close: null,
  },
};

const Reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ADVERSE_ITEMS': {
      return { ...state, adverseItems: action.payload };
    }
    case 'SET_INITIAL_ASSESSMENT': {
      return {
        ...state,
        individualizedAssessment: {
          ...state.individualizedAssessment,
          reassessment: action.payload,
        },
      };
    }
    case 'UPLOAD_INDIVIDUALIZED_ASSESSMENT': {
      const assessment = state.individualizedAssessment || {};

      return {
        ...state,
        individualizedAssessment: {
          ...assessment,
          uploadedFile: { url: action.payload.url, file: action.payload },
        },
      };
    }
    case 'REMOVE_INDIVIDUALIZED_ASSESSMENT': {
      return {
        ...state,
        individualizedAssessment: {
          ...state.individualizedAssessment,
          uploadedFile: null,
        },
      };
    }

    case 'SET_NAVIGATION': {
      return {
        ...state,
        navigation: {
          ...initialState.navigation,
          ...action.payload,
        },
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export default Reducer;
