import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useSegmentsContext } from 'components/Segments/SegmentsContext';
import { useSearchHierarchy, ParentContext } from '../../api/segments';

type Props = {
  accountId: string;
  parentContext: ParentContext;
};

const SegmentsPagination: React.FC<Props> = ({ accountId, parentContext }) => {
  const { call: searchHierarchy } = useSearchHierarchy();

  const { search } = useSegmentsContext();

  const handlePageChange = useCallback(
    page => {
      searchHierarchy({
        accountId,
        parentContext,
        query: search.query,
        page: page + 1,
      });
    },
    [accountId, parentContext, search.query],
  );

  return (
    <>
      {search.pageCount > 1 && (
        <M.Pagination
          style={{ marginTop: '0.2rem' }}
          pageCount={search.pageCount}
          selectedIndex={search.pageIndex}
          onPageClick={handlePageChange}
        />
      )}
    </>
  );
};

export default SegmentsPagination;
