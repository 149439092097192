import React from 'react';
import styled from 'styled-components';

import * as Entity from 'modules/assess/models/settings/account';
import { AutoEngage, HideEligible } from 'modules/assess/ui/common/settings';
import * as EligibleRecords from './eligible-records';
import GeneralSettings from './general-settings';
import AutoAdverseActions from './auto-adverse-actions';

type Props = {
  locals: Entity.Type;
  remote?: Entity.Type;
  setNeedsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const StyledDiv = styled.div`
  > * {
    margin-bottom: 1.5rem;
  }
`;

export const Container: React.FC<Props> = props => {
  const { locals, setSettings } = props;

  return (
    <>
      <EligibleRecords.ReportView {...props} />
      <EligibleRecords.TreatmentTitle />
      <StyledDiv>
        <HideEligible
          locals={locals}
          setSettings={setSettings}
          versionClass='v1'
        />
        <AutoEngage
          locals={locals}
          setSettings={setSettings}
          versionClass='v1'
        />
        <AutoAdverseActions settings={locals} setSettings={setSettings} />
        <GeneralSettings settings={locals} setSettings={setSettings} />
      </StyledDiv>
    </>
  );
};

export default Container;
