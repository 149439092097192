import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Instance, useDeleteApplicationInstance } from 'api/integrations';

const Deauthorize: React.FC<{ applicationInstance: Instance }> = ({
  applicationInstance,
}) => {
  const { call, result } = useDeleteApplicationInstance();
  const deleteApplication = useCallback(
    () => call(applicationInstance),
    [applicationInstance, call],
  );
  return (
    <M.Container id='oauth-deauthorize'>
      <M.Container.Row>
        <M.Container.Col>
          <h1>Deauthorize application</h1>
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          Remove access for {applicationInstance.application.name} from your
          Checkr account.
        </M.Container.Col>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          {result.isSuccess ? (
            <M.InlineNotification
              hideCloseButton
              kind='success'
              title='Application and its tokens successfully deauthorized'
            />
          ) : (
            <M.Button
              kind='danger'
              onClick={deleteApplication}
              disabled={result.isLoading}
            >
              Deauthorize application
            </M.Button>
          )}
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  );
};

export default Deauthorize;
