import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFormik } from 'formik';
import { usePackageSubtypes } from 'api/packages';
import { useCreateCohort } from 'api/randomDrugTesting';
import {
  EditCohortFormFields,
  NewCohortFormFields,
  PackageSubtype,
} from './types';
import newCohortValidations, { ValidationErrors } from './schema';
import DetailsSection from './DetailsSection';
import ScreeningOptionsSection from './ScreeningOptionsSection';
import CandidateUploadSection from './CandidateUploadSection';
import Errors from './Errors';
import {
  DRUG_SCREENING_PACKAGE_SUBTYPE,
  INITIAL_VALUES as defaultInitialValues,
} from './Constants';
import { transformFormValuesForApi } from './utils';
import { ICohortName } from '../Cohort/types';

interface Props {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
  initialValues?: NewCohortFormFields | EditCohortFormFields;
  onSubmitCallback?: Function;
  refetchCallback: Function;
  cohortNames?: ICohortName[] | undefined;
}

const CreateCohortModal: FC<Props> = ({
  accountId,
  isOpen,
  onClose,
  initialValues = defaultInitialValues,
  onSubmitCallback,
  refetchCallback,
  cohortNames,
}) => {
  const [sectionIndex, setSectionIndex] = useState(0);
  const deleteFileRef = useRef<() => void>();

  const [showErrors, setShowErrors] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [screenings, setScreenings] = useState<PackageSubtype[]>([]);

  const isEditScenario = useMemo(
    () => initialValues !== defaultInitialValues,
    [initialValues],
  );

  useEffect(() => {
    setSectionIndex(isEditScenario ? 2 : 0);
  }, [initialValues, isEditScenario, isOpen]);

  const submissionHook = onSubmitCallback || useCreateCohort;

  const { data, isLoading } = usePackageSubtypes(accountId);
  const { call: mutateCallback, result } = submissionHook();

  const errorMessage = result?.error?.response?.data?.message;

  const { reset } = result || {};

  useEffect(() => {
    if (data) {
      setScreenings(data[DRUG_SCREENING_PACKAGE_SUBTYPE]);
    }
  }, [data]);

  useEffect(() => {
    if (result?.status !== 'error') setServerError(false);
  }, [result]);

  const onSubmit: any = async (
    values: NewCohortFormFields | EditCohortFormFields,
  ) => {
    const submissionValues = { ...values };
    if (submissionValues.drugAlcholSelection === 'DRUG_ONLY') {
      submissionValues.alcoholScreening = '';
    } else if (submissionValues.drugAlcholSelection === 'ALCOHOL_ONLY')
      submissionValues.drugScreening = '';

    const response = await mutateCallback(
      transformFormValuesForApi(submissionValues) as any,
    );
    if (response?.id) {
      refetchCallback();
      onClose();
    } else {
      setServerError(true);
    }
  };

  const initialValuesWithDefaults = {
    ...defaultInitialValues,
    ...initialValues,
    drugScreening: initialValues?.drugScreening ?? '',
    alcoholScreening: initialValues?.alcoholScreening ?? '',
  };

  const formik = useFormik<NewCohortFormFields | EditCohortFormFields>({
    initialValues: initialValuesWithDefaults,
    validationSchema: newCohortValidations,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { values, errors, touched, handleChange, handleSubmit, setErrors } =
    formik;

  const showErrorMessage = useCallback(
    (field: string): string | null => {
      return touched[field as keyof typeof touched]
        ? (errors[field as keyof typeof errors] as string)
        : null;
    },
    [errors, touched],
  );

  const validateCurrentSection = useCallback(async () => {
    let hasErrors = false;
    const validationErrors: any = {};

    if (sectionIndex === 0) {
      if (!values.cohortName) {
        validationErrors.cohortName = ValidationErrors.REQUIRED;
        hasErrors = true;
      }
      if (!values.isDOTCompliant) {
        validationErrors.isDOTCompliant = ValidationErrors.REQUIRED;
        hasErrors = true;
      }
      if (values.isDOTCompliant === 'true') {
        if (!values.dotComplianceAgency) {
          validationErrors.dotComplianceAgency = ValidationErrors.REQUIRED;
          hasErrors = true;
        }
      }
      setErrors(validationErrors);
    } else if (sectionIndex === 1) {
      if (!values.drugAlcholSelection) {
        validationErrors.drugAlcholSelection = ValidationErrors.REQUIRED;
        hasErrors = true;
      } else {
        if (
          (values.drugAlcholSelection === 'DRUG_ONLY' ||
            values.drugAlcholSelection === 'DRUG_ALCOHOL') &&
          !values.drugScreening
        ) {
          validationErrors.drugScreening = ValidationErrors.REQUIRED;
          hasErrors = true;
        }
        if (
          (values.drugAlcholSelection === 'ALCOHOL_ONLY' ||
            values.drugAlcholSelection === 'DRUG_ALCOHOL') &&
          !values.alcoholScreening
        ) {
          validationErrors.alcoholScreening = ValidationErrors.REQUIRED;
          hasErrors = true;
        }
      }
      setErrors(validationErrors);
    }
    setShowErrors(hasErrors);
    return hasErrors;
  }, [sectionIndex, values, setErrors]);

  const paginateForward = useCallback(async () => {
    const hasErrors = await validateCurrentSection();
    if (!hasErrors && sectionIndex < 2) {
      setSectionIndex(sectionIndex + 1);
      setShowErrors(false);
    }
  }, [validateCurrentSection, sectionIndex]);

  const formSubmit = useCallback(async () => {
    setShowErrors(true);
    handleSubmit();
  }, [handleSubmit]);

  const paginateBackward = useCallback(() => {
    if (sectionIndex > 0) {
      // Call the delete file handler when navigating back from the third section (upload section)
      if (sectionIndex === 2 && deleteFileRef.current) {
        deleteFileRef.current();
      }
      setSectionIndex(sectionIndex - 1);
      setShowErrors(false);
    }
  }, [sectionIndex]);

  const setDeleteFileReference = useCallback((ref: () => void) => {
    deleteFileRef.current = ref;
  }, []);

  // Form Section Render
  const showDetailsSection = sectionIndex === 0;
  const showScreeningOptionsSection = sectionIndex === 1;
  const showUploadSection = sectionIndex === 2;
  // Paginate button render
  const showSubmitBtn = sectionIndex === 2;
  const showBackBtn = sectionIndex > 0 && !isEditScenario;

  const isSubmitting = useMemo(() => result?.status === 'loading', [result]);

  const isUploadSectionUnfilled = useMemo(
    () => showUploadSection && (!values.fileStoragePath || serverError),
    [serverError, showUploadSection, values.fileStoragePath],
  );

  const poolNameList = cohortNames?.length
    ? cohortNames?.map(record => record.name.trim().toLowerCase())
    : [];
  const isPoolNameExist = poolNameList?.includes(
    values.cohortName.trim().toLowerCase(),
  );

  const disableSubmit =
    (!isEditScenario &&
      showDetailsSection &&
      (!values.cohortName ||
        !values.isDOTCompliant ||
        (values.isDOTCompliant === 'true' && !values.dotComplianceAgency))) ||
    (showScreeningOptionsSection &&
      (!values.drugAlcholSelection ||
        (values.drugAlcholSelection === 'DRUG_ONLY' && !values.drugScreening) ||
        (values.drugAlcholSelection === 'ALCOHOL_ONLY' &&
          !values.alcoholScreening) ||
        (values.drugAlcholSelection === 'DRUG_ALCOHOL' &&
          (!values.drugScreening || !values.alcoholScreening)))) ||
    isUploadSectionUnfilled ||
    isSubmitting;

  const detailsSectionProps = {
    cohortName: values.cohortName,
    isDOTCompliant: values.isDOTCompliant,
    dotComplianceAgency: values.dotComplianceAgency,
  };

  const candidateUploadProps = {
    fileDisplayName: values.fileDisplayName,
    fileStorageBucket: values.fileStorageBucket,
    fileStoragePath: values.fileStoragePath,
  };

  return (
    <M.ComposedModal onClose={onClose} open={isOpen}>
      <M.ModalHeader closeModal={onClose}>
        <h2>
          {isEditScenario ? `Edit ${initialValues.cohortName}` : 'New pool'}
        </h2>
      </M.ModalHeader>
      <M.ModalBody>
        {isSubmitting && (
          <M.LoadingSpinner
            description={`Submitting change to ${
              isEditScenario ? 'edit' : 'create'
            } ${values.cohortName}`}
          />
        )}
        {showDetailsSection && (
          <DetailsSection
            values={detailsSectionProps}
            showError={showErrorMessage}
            handleChange={handleChange}
          />
        )}
        {showScreeningOptionsSection && (
          <ScreeningOptionsSection
            screenings={screenings}
            values={values}
            showError={showErrorMessage}
            handleChange={handleChange}
            setErrors={setErrors}
          />
        )}
        {showUploadSection && (
          <CandidateUploadSection
            values={candidateUploadProps}
            accountId={accountId}
            handleChange={handleChange}
            setServerError={setServerError}
            reset={reset}
            setDeleteFileRef={setDeleteFileReference}
          />
        )}
        {showErrors && (
          <Errors
            errors={errors}
            serverError={serverError ? errorMessage : undefined}
          />
        )}
        {isPoolNameExist && (
          <Errors errors={{ cohortName: ValidationErrors.UNIQUE_NAME }} />
        )}
      </M.ModalBody>
      <M.ModalFooter>
        <M.GridRow style={{ width: '100%' }}>
          <M.GridCol style={{ padding: '0' }}>
            {showBackBtn && (
              <M.Button
                data-testid='action-btn-secondary'
                onClick={paginateBackward}
                kind='secondary'
              >
                Back
              </M.Button>
            )}
          </M.GridCol>
          <M.GridCol>
            <M.Button
              data-testid='action-btn-primary'
              style={{ float: 'right' }}
              onClick={showSubmitBtn ? formSubmit : paginateForward}
              disabled={isLoading || disableSubmit || isPoolNameExist}
            >
              {showSubmitBtn ? 'Submit' : 'Next'}
            </M.Button>
          </M.GridCol>
        </M.GridRow>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default CreateCohortModal;
