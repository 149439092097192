import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Content from 'components/FairChance/Content';
import Sections from 'components/FairChance/sections';
import Subsections from '../subsections';
import Image from './Image';

type Props = {
  content: any;
  subsection: Subsections;
};

const StyledContainer = styled(M.Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
`;

const Description = styled.span`
  margin-top: 1rem;
  text-align: center;
`;

const Container: React.FC<Props> = ({ content, subsection }) => {
  const { link, paragraphs } = content;
  const description = (
    <Content
      paragraphs={paragraphs}
      section={Sections.benefits}
      subsection={subsection}
    />
  );

  return (
    <StyledContainer
      type='shadow'
      data-testid='fair-chance-sections-benefits-example'
    >
      <Image link={link} subsection={subsection} />
      <Description>{description}</Description>
    </StyledContainer>
  );
};

export default Container;
