import React from 'react';
import { ReportProps } from 'types';
import GeneralComments from './GeneralComments';

const PublicComments: React.FC<ReportProps> = ({ report }) => {
  if (!report.public_comments?.length) return null;
  return <GeneralComments report={report} commentType='public_comments' />;
};

export default PublicComments;
