import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

const eventCategory = 'Report';

/**
 * @name getReportChangeEvent
 * @function
 * @memberOf ReportChangeEvent
 * @description Determines the human-friendly event action
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */

const getReportChangeEvent = (timelineEvent: TimelineEvent) => {
  let descript: string;

  switch (timelineEvent.event) {
    case 'package':
      descript = 'upgraded';
      break;
    default:
      descript = 'changed';
  }

  const action = `${timelineEvent.event} ${descript} from ${timelineEvent.from} to ${timelineEvent.to}`;

  return action;
};

const ReportChangeEvent: React.FC<{ timelineEvent: TimelineEvent }> = ({
  timelineEvent,
}) => {
  const eventAction = getReportChangeEvent(timelineEvent);
  return (
    <M.TableRow>
      <TimelineCell>
        <EventName>{`${eventCategory} ${eventAction}`}</EventName>
      </TimelineCell>
      <TimelineCell>
        {timelineEvent.who !== 'system' && timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default ReportChangeEvent;
