import AddScreeningsAction from 'components/AddScreenings/shared/AddScreeningsAction';
import React from 'react';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import {
  FlexContainer,
  ExpandableTitle,
  Title,
  ScreeningDescriptionContainer,
  ScreeningInfo,
  Flex,
  Box,
  BoxHeader,
  BulletPoint,
  BoxDivider,
  AddButtonContainer,
  ScreeningProps,
} from '../../../shared/ListItemElements';

const FederalCriminalSearch: React.FC<ScreeningProps> = ({
  shouldOpen,
  getPriceByType,
  actionProps,
}) => {
  return (
    <FlexContainer data-testid='federal-criminal-search-section'>
      <ExpandableTitle
        initialExpanded={shouldOpen}
        data-testid='expandable-federal-criminal-search-section'
        title={
          <Title
            getPriceByType={getPriceByType}
            actionProps={actionProps}
            title='Federal Criminal Records Search'
            priceByType='federal_criminal_search'
          />
        }
      >
        <ScreeningDescriptionContainer>
          <ScreeningInfo>
            Searches all 94 federal district courts; often used when hiring
            C-level execs, CPAs, and financial staff.{` `}
            <LearnMoreLink
              style={{ textDecoration: 'underline' }}
              linkText='Learn more'
              data-testid='federal-crim-learn-more-link'
              href='https://help.checkr.com/hc/en-us/articles/360001882627'
            />
          </ScreeningInfo>
          {/* <TurnAroundTime /> */}
        </ScreeningDescriptionContainer>
        <Flex>
          <Box>
            <BoxHeader>Advantages</BoxHeader>
            <ul>
              <BulletPoint text='Looks for federal-level crimes such as kidnapping, embezzlement, drug trafficking, etc.' />
              <BulletPoint text='Includes data that might not be found elsewhere' />
              <BulletPoint text='Federal criminal data is maintained in a single centralized source' />
            </ul>
          </Box>
          <BoxDivider />
          <Box>
            <BoxHeader>Considerations</BoxHeader>
            <ul>
              <BulletPoint text='Limited PII provided by the source means limited reportable records' />
              <BulletPoint text='Doesn’t include convictions prosecuted at the state-level (a majority of crimes)' />
            </ul>
          </Box>
        </Flex>
      </ExpandableTitle>
      <AddButtonContainer>
        <AddScreeningsAction
          screeningType='federal_criminal_search'
          {...actionProps}
        />
      </AddButtonContainer>
    </FlexContainer>
  );
};

export default FederalCriminalSearch;
