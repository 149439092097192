import {
  CHECKR_API_BASE_URL,
  SAVED_SEARCH_API_BASE_URL,
  ACCOUNT_SERVICE_BASE_URL,
} from 'Constants';

export const ACCOUNTS_URL_BASE = `${CHECKR_API_BASE_URL}/accounts`;
export const SAVED_SEARCH_URL_BASE = `${SAVED_SEARCH_API_BASE_URL}/account_searches`;
export const VERIFICATIONS_URL_BASE = `${CHECKR_API_BASE_URL}/verifications`;
export const REPORTS_URL_BASE = `${CHECKR_API_BASE_URL}/reports`;

export const ACCOUNT_SERVICE_URL_BASE = `${ACCOUNT_SERVICE_BASE_URL}`;
