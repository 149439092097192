import React from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import { Subtype } from 'modules/adjudication/data';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { ModalFooter } from 'modules/adjudication/ui/common';
import SubtypeSelection from '../../../../subtype-selection';
import { useGetIncompleteScreenings } from '../../../utils';

type Props = {
  onChange: React.Dispatch<React.SetStateAction<undefined>>;
  onClose: () => void;
  transitionToScreen: (screen: string) => void;
  selection: undefined | GenericObject;
};

const AdjudicationSubtypeSelection: React.FC<Props> = ({
  onChange,
  onClose,
  transitionToScreen,
  selection,
}) => {
  const { t } = useTranslation();
  const cancel = t(`${namespace}:report.actions.engaged.modal.actions.cancel`);
  const engage = t(`${namespace}:report.actions.engaged.modal.actions.engage`);
  const legend = t(`${namespace}:report.actions.engaged.modal.subtypes.legend`);
  const incompleteScreenings = useGetIncompleteScreenings();

  const next = {
    // TODO: verify name if incomplete screenings are available
    name: engage,
    onClick:
      incompleteScreenings.length > 0
        ? () => transitionToScreen('incomplete_screenings')
        : () => transitionToScreen('submit'),
    disabled: !selection,
  };

  const close = {
    name: cancel,
    onClick: onClose,
  };

  return (
    <>
      <SubtypeSelection
        legendText={legend}
        subtypeKind={Subtype.Engaged}
        onChange={onChange}
        value={selection?.name}
      />
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default AdjudicationSubtypeSelection;
