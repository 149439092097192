const getFormStatus = (values: any, errors: any, complete: boolean) => {
  if (complete) {
    return 'complete';
  }

  if (Object.keys(errors).length > 0) {
    return 'warning';
  }

  const allValuesEmpty = Object.values(values).every(
    value => value === '' || value === false,
  );

  if (allValuesEmpty) {
    return 'not-started';
  }

  return 'incomplete';
};

export default getFormStatus;
