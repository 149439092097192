import React, { SyntheticEvent } from 'react';
import Button from 'components/Button';
import styled from 'styled-components';

type Props = {
  onClose?: (event: SyntheticEvent) => void;
  onConfirm?: (event: SyntheticEvent) => void;
};

const Container = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const Actions: React.FC<Props> = ({ onClose, onConfirm }) => {
  return (
    <Container>
      <Button kind='secondary' trans='verbs.cancel' onClick={onClose} />
      <Button
        kind='danger'
        trans='assess:settings.unsaved_changes.buttons.leave_without_saving'
        onClick={onConfirm}
      />
    </Container>
  );
};

export default Actions;
