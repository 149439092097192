import * as CCS from 'modules/assess/models/mvr/category-code-set';
import {
  AccountParams,
  CollectionResults,
  PageParams,
} from 'modules/core-types/api-core';
import { ID } from '../../models/entity';
import client from '../client';
import { path } from './uri';

export { uri } from './uri';

const BASE_PATH = 'mvr/ccs';

export type CreateResult = CCS.Entity;
export type CreateParams = {
  name: string;
  source?: ID;
};

export const create = (data: CreateParams): Promise<CreateResult> => {
  const path = `/${BASE_PATH}`;
  return client.post(path, data);
};

export const destroy = (instance: ID): Promise<void> => {
  return client.delete(path(instance));
};

export type FetchResult = CCS.Entity;

export const fetch = (instance: ID): Promise<CCS.Entity> => {
  return client.get(`/${BASE_PATH}/${instance}`);
};

export interface SearchParams extends AccountParams, PageParams {
  name?: string;
}

export type SearchResults = CollectionResults<CCS.Entity>;

export const search = (params?: SearchParams): Promise<SearchResults> => {
  return client.get(path(), { params });
};

export type UpdateParams = Pick<CCS.Entity, 'name'>;

export const update = (
  instance: ID,
  params?: UpdateParams,
): Promise<CCS.Entity> => {
  return client.patch(path(instance), params);
};
