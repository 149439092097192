import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeaderData, SortDirections } from 'components/Table';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import { useTaskSearchParams } from 'modules/case-management/ui/TasksPage/hooks';
import { sortableTaskTableColumns, taskTableColumns } from './columns';

const { ASC, DESC, NONE } = SortDirections;

export const useGenerateHeaders = () => {
  const history = useHistory();
  const { t } = useTranslation('case-management');
  const taskSearchParams = useTaskSearchParams();
  const columns = Object.values(taskTableColumns);

  return columns.map(header => {
    const text = t(`tasks.table.headers.${header}`);
    let h: HeaderData = {
      key: header,
      header: text,
    };

    if (sortableTaskTableColumns.includes(header)) {
      const sort = taskSearchParams?.sort;
      const [sortColumn, sortDirection] = sort?.split('+') ?? [];
      const isCurrentSort = sortColumn === header;
      let direction = NONE;
      if (sortDirection === ASC.toLowerCase()) {
        direction = ASC;
      } else if (sortDirection === DESC.toLowerCase()) {
        direction = DESC;
      }

      const onSort = () => {
        const newDirection = direction === ASC ? DESC : ASC;
        const newSort = `${header}+${newDirection.toLowerCase()}`;
        if (newSort) {
          const searchParams = new URLSearchParams({
            ...taskSearchParams,
            page: '1',
            sort: newSort,
          } as Record<string, string>);
          history.push({
            pathname: TASKS_PATH,
            search: searchParams.toString(),
          });
        }
      };

      h = {
        ...h,
        sortable: true,
        onSort,
        sortDirection: direction,
        isSortHeader: isCurrentSort,
      };
    }

    return h;
  });
};
