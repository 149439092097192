import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import invert from 'lodash/invert';
import Settings from 'modules/assess/ui/settings/Container';
import { useHistory } from 'react-router-dom';
import * as Criminal from '../criminal';
import { useMVR } from '../hooks';
import * as MVR from '../mvr';

export enum TabIndex {
  criminal = 0,
  mvr = 1,
  settings = 2,
}

export type TabName = keyof typeof TabIndex;

const IndexTab = invert(TabIndex) as { [key: number]: TabName };

type Props = {
  tab?: TabName;
};

const Tabs: React.FC<Props> = ({ tab }) => {
  const mvr = useMVR();
  const history = useHistory();

  const selected = (tab && TabIndex[tab]) ?? TabIndex.criminal;
  const [nextSelected, setNextSelected] = useState(selected);

  const setSelected = useCallback(
    (index: number) => {
      const tab = IndexTab[index];
      const search = new URLSearchParams({ tab }).toString();
      history.push({ search });
    },
    [history],
  );

  const handleOverrideSettings = useCallback(() => {
    setSelected(TabIndex.settings);
  }, [setSelected]);

  const handleResumeTabSelection = useCallback(() => {
    setSelected(nextSelected);
  }, [nextSelected, setSelected]);

  const handleSelectionChange = useCallback(
    (e: { selectedIndex: number }) => {
      setSelected(e.selectedIndex);
      setNextSelected(e.selectedIndex);
    },
    [setSelected],
  );

  return (
    <div data-testid='assess-home-tabs' style={{ paddingBottom: '4rem' }}>
      <M.Tabs onChange={handleSelectionChange} selectedIndex={selected}>
        <M.TabList>
          <M.Tab data-testid='assess-criminal-tab'>Criminal Records</M.Tab>
          <M.Tab
            data-testid='assess-mvr-tab'
            style={{ display: mvr ? 'inline' : 'none' }}
          >
            Motor Vehicle Records
          </M.Tab>
          <M.Tab data-testid='assess-settings-tab'>Settings</M.Tab>
        </M.TabList>
        <M.TabPanels>
          <M.TabPanel data-testid='assess-criminal-tab-panel'>
            <Criminal.Home />
          </M.TabPanel>
          <M.TabPanel data-testid='assess-mvr-tab-panel'>
            <MVR.Home />
          </M.TabPanel>
          <M.TabPanel data-testid='assess-settings-tab-panel'>
            <Settings
              selected={selected === TabIndex.settings}
              overrideTabSelection={handleOverrideSettings}
              resumeTabSelection={handleResumeTabSelection}
            />
          </M.TabPanel>
        </M.TabPanels>
      </M.Tabs>
    </div>
  );
};

export default Tabs;
