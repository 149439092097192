import {
  AnyQueryKey,
  useMutation,
  usePaginatedQuery,
  useQuery,
} from 'react-query';
import { useCallback, useContext } from 'react';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { toastError, toastSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import {
  AccountSettingsParams,
  AccountSettingsUpdateParams,
  createAccountSettings,
  createEmployee,
  createFormI9,
  createUser,
  createWorksite,
  deleteWorksite,
  EmployeeParams,
  FormI9Params,
  FormsI9PDFIDsParams,
  getAccountSettings,
  getAllCandidates,
  getAllI9Forms,
  getAllUsers,
  getAllWorksites,
  getFormById,
  getLastTOSContent,
  postFormI9CSVFile,
  postFormI9PDF,
  updateAccountSettings,
  updateWorksite,
  UserParams,
  WorksiteParams,
} from './actions';

export const useGetFormsI9 = (
  orderBy: string,
  order: string,
  page: number,
  perPage: number,
  search: string,
  worksiteId: string,
  orderProgress: string,
  openTasks: string,
) => {
  const key: AnyQueryKey = [
    'i9verifications/allFormsI9',
    {
      orderBy,
      order,
      page,
      perPage,
      search,
      worksiteId,
      orderProgress,
      openTasks,
    },
  ];

  const dispatch = useDispatch();
  const request = () =>
    getAllI9Forms(
      orderBy,
      order,
      page,
      perPage,
      search,
      worksiteId,
      orderProgress,
      openTasks,
    );

  return usePaginatedQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    onError: error => {
      dispatch(
        toastError(
          'There was an error fetching the I-9 statuses',
          error.message,
        ),
      );
    },
  });
};

export const useGetFormsI9ById = (formId: string) => {
  const key: AnyQueryKey = ['i9verifications/formsI9', { formId }];

  const request = () => getFormById(formId);

  return useQuery(key, request, {
    enabled: Boolean(formId),
    refetchOnWindowFocus: false,
  });
};

export const useCreateFormsI9 = (successCallback: () => void) => {
  const dispatch = useDispatch();
  const request = (params: FormI9Params) => createFormI9(params);

  const [formI9CreatedCall, formI9CreatedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess(`${data.length} order(s) complete`));
      successCallback();
    },
  });
  return {
    formI9CreatedCall,
    formI9CreatedResult,
  };
};

export const usePostFormI9CSVFile = () => {
  const dispatch = useDispatch();
  const request = (formI9Ids: string[]) => postFormI9CSVFile(formI9Ids);

  const [I9ResultsDownloadedCall, I9DownloadedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
  });
  return {
    FromI9ResultsDownloadedCall: I9ResultsDownloadedCall,
    I9DownloadedResult,
  };
};

export const useCreateWorksite = (successCallback: () => void) => {
  const dispatch = useDispatch();
  const request = (params: WorksiteParams) => createWorksite(params);

  const [worksiteCreatedCall, worksiteCreatedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess(`Worksite ${data.name} successfully created`));
      successCallback();
    },
  });
  return {
    worksiteCreatedCall,
    worksiteCreatedResult,
  };
};

export const useUpdateWorksite = (
  worksiteId: string,
  successCallback: () => void,
) => {
  const dispatch = useDispatch();
  const request = (params: WorksiteParams) =>
    updateWorksite(worksiteId, params);

  const [worksiteUpdatedCall, worksiteEditedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess(`Worksite ${data.name} successfully edited`));
      successCallback();
    },
  });
  return {
    worksiteUpdatedCall,
    worksiteEditedResult,
  };
};

export const useGetWorksites = (orderBy: string, sort: string) => {
  const key: AnyQueryKey = ['i9verifications/worksites', { orderBy, sort }];

  const dispatch = useDispatch();
  const request = () => getAllWorksites(orderBy, sort);

  return useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    onError: error => {
      dispatch(
        toastError(
          'There was an error fetching the I-9 worksites',
          error.message,
        ),
      );
    },
  });
};

export const useDeleteWorksite = (worksiteName: string) => {
  const dispatch = useDispatch();
  const request = (id: string) => deleteWorksite(id);

  const [deleteWorksiteCall, deleteWorksiteResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: () => {
      dispatch(toastSuccess(`Worksite "${worksiteName}" successfully removed`));
    },
  });

  return {
    deleteWorksiteCall,
    deleteWorksiteResult,
  };
};

export const useGetAccountSettings = () => {
  const key: AnyQueryKey = ['i9verifications/account_settings', {}];
  const dispatch = useDispatch();

  const history = useHistory();

  const { contextId } = useContext(UIContext);
  const navigate = useCallback(
    path => {
      if (contextId) {
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      }
      history.push(path);
    },
    [contextId, history],
  );

  const request = () => getAccountSettings();

  return useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    onError: (error: AxiosError) => {
      if (error.response?.status !== 404) {
        dispatch(
          toastError(
            'There was an error fetching the I-9 account settings',
            error.message,
          ),
        );
        navigate('/');
      }
    },
  });
};

export const useCreateAccountSettings = () => {
  const dispatch = useDispatch();
  const request = (params: AccountSettingsParams) =>
    createAccountSettings(params);

  const [accountSettingsCreatedCall, accountSettingsCreatedResult] =
    useMutation(request, {
      onError: data => {
        dispatch(toastError('ERROR:', data.message));
      },
      onSuccess: data => {
        dispatch(
          toastSuccess(
            `Account settings for the company ${data.employer_name} successfully created`,
          ),
        );
      },
    });
  return {
    accountSettingsCreatedCall,
    accountSettingsCreatedResult,
  };
};

export const useUpdateAccountSettings = () => {
  const dispatch = useDispatch();
  const request = (params: AccountSettingsUpdateParams) =>
    updateAccountSettings(params);

  const [accountSettingsUpdatedCall, accountSettingsUpdatedResult] =
    useMutation(request, {
      onError: data => {
        dispatch(toastError('ERROR:', data.message));
      },
      onSuccess: data => {
        dispatch(
          toastSuccess(
            `Account settings for the company ${data.employer_name} successfully updated`,
          ),
        );
      },
    });
  return {
    accountSettingsUpdatedCall,
    accountSettingsUpdatedResult,
  };
};

export const useGetAllCandidates = (
  page: number,
  perPage: number,
  searchFilter: string,
) => {
  const key: AnyQueryKey = [
    'i9verifications/search_candidates',
    { page, perPage, searchFilter },
  ];

  const dispatch = useDispatch();
  const request = () => getAllCandidates(page, perPage, searchFilter.trim());

  return useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    onError: error => {
      dispatch(
        toastError('There was an error fetching the candidates', error.message),
      );
    },
  });
};

export const usePostFormI9PDF = () => {
  const dispatch = useDispatch();
  const request = (param: FormsI9PDFIDsParams) => postFormI9PDF(param);

  const [postFormsI9PDFCreatedCall, postFormsI9PDFCreatedResult] = useMutation(
    request,
    {
      onError: data => {
        dispatch(toastError('ERROR:', data.message));
      },
      onSuccess: () => {
        dispatch(toastSuccess(`PDFs requested successfully`));
      },
    },
  );
  return {
    postFormsI9PDFCreatedCall,
    postFormsI9PDFCreatedResult,
  };
};

export const useGetLastToSContent = () => {
  const key: AnyQueryKey = ['i9verifications/terms_of_service_contents', {}];

  const dispatch = useDispatch();
  const request = () => getLastTOSContent();

  return useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    onError: error => {
      dispatch(
        toastError(
          'There was an error fetching the terms of service contents',
          error.message,
        ),
      );
    },
  });
};

export const useCreateUser = (successCallback: () => void) => {
  const dispatch = useDispatch();
  const request = (params: UserParams) => createUser(params);

  const [postUserCreatedCall, postUserCreatedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(toastSuccess(`User ${data.first_name} successfully created`));
      successCallback();
    },
  });
  return {
    postUserCreatedCall,
    postUserCreatedResult,
  };
};

export const useGetAllUsers = () => {
  const key: AnyQueryKey = ['i9verifications/users', {}];

  const dispatch = useDispatch();
  const request = () => getAllUsers();

  return useQuery(key, request, {
    enabled: true,
    refetchOnWindowFocus: false,
    onError: error => {
      dispatch(
        toastError('There was an error fetching the users', error.message),
      );
    },
  });
};

export const usePostEmployee = (successCallback: () => void) => {
  const dispatch = useDispatch();

  const request = (params: EmployeeParams) => createEmployee(params);

  const [employeeCreatedCall, employeeCreatedResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
    onSuccess: data => {
      dispatch(
        toastSuccess(`Employee with ${data.email} email successfully created`),
      );
      successCallback();
    },
  });
  return {
    employeeCreatedCall,
    employeeCreatedResult,
  };
};
