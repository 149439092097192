/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { useCallback, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { clearSearchParams } from 'actions/SearchActions';
import { useSearchState } from 'components/Search/search-context';
import UserContext from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { M } from '@dashboard-experience/mastodon';
import { PARAMS } from '../../constants';
import { useReduxState } from '../hooks';
import { Reset as Text } from '../StyledComponents';

const Reset = () => {
  const dispatch = useDispatch();
  const { originalPrograms, filters, setFilters, setPrograms } =
    useSearchState();
  const { searchParams } = useReduxState();
  const { q } = searchParams;
  const label = 'Clear filters';
  const currentUser = useContext(UserContext);

  const isAnyFilterActive = useMemo(
    () =>
      PARAMS.base.some((param: string) => {
        if (searchParams[param] !== undefined && param !== 'q') return true;
        if (param === 'q' && q !== undefined && q !== '*') return true;
        return false;
      }),
    [searchParams, q],
  );

  const trackEvent = useTrackEvent();

  const resetFilters = useCallback(() => {
    if (filters.geo_ids?.length && originalPrograms.length) {
      setPrograms(originalPrograms);
    }

    if (currentUser) {
      trackEvent(CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_FILTER_RESET);
    }

    setFilters({});
    dispatch(clearSearchParams());
  }, [
    filters.geo_ids?.length,
    originalPrograms,
    currentUser,
    setFilters,
    dispatch,
    setPrograms,
    trackEvent,
  ]);

  return isAnyFilterActive ? (
    <Text onClick={resetFilters} style={{ marginLeft: '1rem' }}>
      <M.Icon icon='Close' />
      {label}
    </Text>
  ) : (
    <div />
  );
};

export default Reset;
