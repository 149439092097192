import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PackagesSearch, PackagesTable } from 'components/Screenings/Packages';
import { QueryResult } from 'react-query';
import { M } from '@dashboard-experience/mastodon';
import querystring from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import { extendParamsObject } from 'utils';
import { useUser } from '../../../context/CurrentUser';
import { usePackages } from '../../../api/packages';
import { Package } from '../../../types';
import { updateParentWindowUrl } from '../../../utils';
import UIContext from '../../../context/UI';

const parsePackagesResponse = (
  response: QueryResult<{ [key: string]: any }>,
) => {
  const count = response?.data?.count;
  const packages = response?.data?.data as Package[];
  const packagesLoading = response?.isLoading as boolean;

  return {
    count,
    packages,
    packagesLoading,
  };
};

type Params = {
  page: number;
  per_page: number;
  name: string;
  program_ids: string[] | string;
};

const defaultParams: Params = {
  page: 1,
  per_page: 10,
  name: '',
  program_ids: [],
};

const Packages: React.FC = () => {
  const { contextId } = useContext(UIContext);
  const {
    account: { id: accountId },
  } = useUser();
  const location = useLocation();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const [params, setParams] = useState<Params>(defaultParams);

  useEffect(() => {
    const searchObj = querystring.parse(location.search.replace('?', ''));
    const numericParamKeys = ['page', 'per_page'];
    const stringParamKeys = ['name', 'program_ids', 'test'];
    const newParams = extendParamsObject(
      params,
      searchObj,
      numericParamKeys,
      stringParamKeys,
    );

    setParams(newParams as Params);
  }, []);

  const [apiParams, setApiParams] = useState(
    new URLSearchParams(querystring.stringify(params)),
  );

  useEffect(() => {
    const newParams: Params = { ...params };
    if (newParams.program_ids.length && Array.isArray(newParams.program_ids)) {
      newParams.program_ids = newParams.program_ids.join(',');
    }

    const query = querystring.stringify(newParams);

    setApiParams(new URLSearchParams(query));
    history.push(`${pathname}?${query}`);

    if (contextId) {
      updateParentWindowUrl({
        contextId,
        search: newParams,
      });
    }
  }, [params, setApiParams]);

  const response = usePackages(accountId, apiParams);
  const { count, packages, packagesLoading } = parsePackagesResponse(response);

  const handlePageNumberChange = useCallback(
    (pageIndex: number) => {
      setParams({ ...params, page: pageIndex + 1 });
    },
    [params, setParams],
  );

  const handleUpdatePrograms = useCallback(
    value => {
      setParams(prev => ({ ...prev, page: 1, program_ids: value }));
    },
    [params, setParams],
  );

  const handleNameChange = useCallback(
    value => {
      setParams(prev => ({ ...prev, page: 1, name: value }));
    },
    [params, setParams],
  );

  return (
    <>
      <PackagesSearch
        packagesCount={count}
        updatePrograms={handleUpdatePrograms}
        updateName={handleNameChange}
      />
      {packagesLoading && <M.LoadingInline description='Loading packages...' />}
      {!packagesLoading && (
        <PackagesTable
          packagesCount={count}
          packages={packages}
          currentPageIndex={params.page - 1}
          pageCount={Math.ceil(count / params.per_page)}
          updatePageNumber={handlePageNumberChange}
        />
      )}
    </>
  );
};

export default Packages;
