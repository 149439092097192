import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Content: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const description = t('assess:settings.unsaved_changes.description');

  return <p>{description}</p>;
};

export default Content;
