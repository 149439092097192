import { Client } from 'api';
import { difference } from 'lodash';
import { callUserActions } from 'components/Report/userActions';

export const updateTags = async (
  reportId: string,
  tags: string[],
  tagsBackup: string[],
): Promise<any> => {
  await Client.post(`reports/${reportId}/taggings`, {
    tags,
  });
  let mode;
  let newTags;
  if (tags.length > tagsBackup.length) {
    mode = 'add';
    newTags = difference(tags, tagsBackup);
  } else {
    mode = 'remove';
    newTags = difference(tagsBackup, tags);
  }
  const eventProperties = {
    event: mode === 'add' ? 'report_tags_added' : 'report_tags_removed',
    extra_info: JSON.stringify(newTags),
  };
  callUserActions(reportId, 'report', eventProperties);
};

export const getUserTags = (accountId: string): Promise<any> => {
  return Client.get(`accounts/${accountId}/tags`);
};
