import styled from 'styled-components';

const ContentsContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const LinkCardContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

const StyledImage = styled.img`
  display: block;
  height: 100%;
`;

const styles = {
  ContentsContainer,
  LinkCardContainer,
  StyledImage,
};

export default styles;
