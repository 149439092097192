import * as Yup from 'yup';
import ValidationErrors from './types';

export const I9CoordinatorInformationValidations = Yup.object().shape({
  firstName: Yup.string().required(ValidationErrors.REQUIRED),
  lastName: Yup.string().required(ValidationErrors.REQUIRED),
  emailAddress: Yup.string()
    .email(ValidationErrors.INVALID_EMAIL)
    .matches(/^(?!.*@[^,]*,)/)
    .required(ValidationErrors.REQUIRED),
});

export default I9CoordinatorInformationValidations;
