import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ReportStatuses } from '@dashboard-experience/utils';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import { useReport } from 'containers/Report';
import * as Items from './menu-items';

const useIncludeArchive = () => {
  const report = useReport();
  const disableArchiveButton =
    report.status === ReportStatuses.PENDING ||
    report.status === ReportStatuses.DISPUTE ||
    report.status === ReportStatuses.SUSPENDED;
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  return archiveReportFlagEnabled && !disableArchiveButton;
};

const useGetMenuItems = () => {
  const menuItems = [];

  const includeArchive = useIncludeArchive();
  if (includeArchive) {
    menuItems.push(<Items.ArchiveReport />);
  }

  menuItems.push(<Items.DeleteCandidateData />);

  return menuItems;
};

export default useGetMenuItems;
