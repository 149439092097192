import * as Yup from 'yup';
import ValidationErrors from './types';
import { containsPOBox } from './worksiteSchema';

export const AccountSetupValidations = Yup.object().shape({
  employer_name: Yup.string().required(ValidationErrors.REQUIRED),
  default_worksite: Yup.object().shape({
    street_line1: Yup.string()
      .required(ValidationErrors.REQUIRED)
      .test(
        'not-po-box',
        ValidationErrors.INVALID_ADDRESS,
        value => !containsPOBox(value || ''),
      ),
    street_line2: Yup.string().test(
      'not-po-box',
      ValidationErrors.INVALID_ADDRESS,
      value => !containsPOBox(value || ''),
    ),
    city: Yup.string().required(ValidationErrors.REQUIRED),
    state: Yup.string().required(ValidationErrors.REQUIRED),
    zip_code: Yup.string()
      .matches(/(^\d{5}$)/, {
        message: ValidationErrors.INVALID_ZIP_CODE,
      })
      .required(ValidationErrors.REQUIRED),
  }),
});

export default AccountSetupValidations;
