export enum Decision {
  ELIGIBLE = 'eligible',
  REVIEW = 'review',
  ESCALATED = 'escalated',
}

export const getCountRuleDecisions = (): Array<Decision> => {
  return [Decision.REVIEW, Decision.ESCALATED];
};

export const getCurrentDecisions = (): Array<Decision> => {
  return [Decision.ELIGIBLE, Decision.REVIEW, Decision.ESCALATED];
};

export const getEligibleDecisions = (): Array<Decision> => {
  return [Decision.ELIGIBLE];
};

export const NON_PREMIUM_DECISIONS = [Decision.ELIGIBLE, Decision.REVIEW];

export const PREMIUM_DECISIONS = [...NON_PREMIUM_DECISIONS, Decision.ESCALATED];

export default Decision;
