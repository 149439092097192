import React, { useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  getCandidateInvitationStatus,
  ReportStatuses,
  StatusTypes,
} from '@dashboard-experience/utils';
import { useCandidate } from 'providers/Candidate';
import { Invitation, KeyValueProps } from 'types/report/invitation';
import { useCancelInvitation } from 'api/invitations';
import { useFlag } from '@dashboard-experience/react-flagr';
import ArchiveInvitationAction from './ArchiveInvitationAction';
import { ENABLE_ARCHIVE_REPORTS } from '../../Flags';

const multiInvitationStyle = Object.freeze({
  paddingTop: '0.5rem',
});
export const singleInvitationStyle = Object.freeze({});

export const keyStyle = Object.freeze({
  width: '10rem',
  paddingRight: '1rem',
  textAlign: 'end',
  fontWeight: 'bold',
  color: colors.uiGrey700,
});

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 18px;
    line-height: unset;
    font-weight: bold;
    color: ${colors.uiTextPrimaryLight};
    flex-grow: 1;
  }
  > div {
    flex-grow: 0;
  }
`;

export const Icon = styled(M.Icon)`
  margin-right: 0.5rem;
`;

export const CanceledInvitation = styled.div`
  display: flex;
  align-items: center;
  .mastodon-status-icon {
    margin-right: 0.5rem;
  }
`;

export const ArchivedInvitationBanner = styled.div`
  background: ${colors.uiGrey100};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 0.75rem;
  padding: 1rem;
  color: ${colors.uiGrey900};
  line-height: 1.25;

  svg {
    vertical-align: bottom;
    margin-right: 0.75rem;
    color: ${colors.uiGrey900}c7;
  }
`;

const invitationSelectString = (invitation: Invitation) => {
  const invite_status = capitalize(invitation.status);
  const d = new Date(Date.parse(invitation.created_at));
  const display_date = d.toLocaleString('en-us', {
    // @ts-ignore
    dateStyle: 'short',
    // @ts-ignore
    timeStyle: 'short',
  });

  return `${invite_status} Invitation sent at ${display_date}`;
};

const InvitationInfo: React.FC<{
  invitation: Invitation;
  candidateId: string;
}> = ({ invitation, candidateId }) => {
  const { call: cancelInvitation, result } = useCancelInvitation(
    invitation,
    candidateId,
  );
  const items = [
    {
      itemKey: 'Status',
      itemValue: (
        <M.StatusBadge
          statusType={StatusTypes.Legacy}
          status={getCandidateInvitationStatus(invitation.status)}
        />
      ),
    },
    { itemKey: 'Package', itemValue: invitation.package },
    {
      itemKey: 'Sent at',
      itemValue: moment(invitation.created_at).format('lll'),
    },
    {
      itemKey: 'Expires at',
      itemValue: moment(invitation.expires_at).format('lll'),
    },
  ] as KeyValueProps[];

  if (invitation.invitation_url == null) {
    items.unshift({
      itemKey: 'Link',
      itemValue: 'Temporarily unavailable',
    });
  } else {
    items.unshift({
      itemKey: 'Link',
      itemValue: (
        <M.Link
          className='invitation-link'
          href={invitation.invitation_url}
          target='_blank'
        >
          {invitation.invitation_url}
        </M.Link>
      ),
    });
  }

  const archiveInvitationFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  if (invitation.status === ReportStatuses.PENDING) {
    let cancelElement = (
      <M.Button
        kind='secondary'
        onClick={cancelInvitation}
        style={{ marginTop: '1.25rem' }}
      >
        <Icon icon='Close' size='16' />
        Cancel invitation
      </M.Button>
    );
    if (result.isLoading)
      cancelElement = <M.LoadingInline description='Canceling...' />;

    const cancelStatus = result.isSuccess ? (
      <CanceledInvitation style={{ marginTop: '1.25rem' }}>
        <M.StatusIcon icon='clear' />
        Invitation successfully canceled
      </CanceledInvitation>
    ) : (
      ''
    );

    items.push({
      itemKey: cancelElement,
      itemValue: cancelStatus,
    });
  }
  if (
    invitation.status === ReportStatuses.EXPIRED &&
    invitation.archived &&
    archiveInvitationFlagEnabled
  ) {
    items.push({
      itemKey: 'Archived at',
      itemValue: `${moment(invitation.archived_info?.time).format('lll')} by ${
        invitation.archived_info?.user.email
      }`,
    });
  }
  return <M.KeyValueList items={items} keyStyle={keyStyle} />;
};

const ExistingInvitation: React.FC<{}> = () => {
  const candidate = useCandidate();
  const { invitations = [] } = candidate;
  const multipleInvitations = invitations.length > 1;
  const [invitationIndex, setInvitationIndex] = useState(0);
  const updateInvitationIndex = useCallback(
    ({ target: { value } }) => setInvitationIndex(value),
    [setInvitationIndex],
  );
  const invitation = invitations[invitationIndex];
  const archiveInvitationFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';
  const showArchivedInvitationBanner =
    archiveInvitationFlagEnabled && invitation.archived;

  return (
    <M.Container
      style={multipleInvitations ? multiInvitationStyle : singleInvitationStyle}
    >
      {showArchivedInvitationBanner && (
        <ArchivedInvitationBanner>
          <M.Icon icon='Box' size={20} />
          This invitation is archived
        </ArchivedInvitationBanner>
      )}
      <Title>
        <span>Invitation information</span>
        {multipleInvitations && (
          <M.Select
            inline
            noLabel
            defaultValue={invitationIndex}
            onChange={updateInvitationIndex}
            data-testid='multiple-invitation-selector'
          >
            {invitations.map((invitation, i) => (
              <M.Select.Item
                key={invitation.id}
                value={i}
                text={invitationSelectString(invitation)}
              />
            ))}
          </M.Select>
        )}
      </Title>
      {invitation && (
        <InvitationInfo invitation={invitation} candidateId={candidate.id} />
      )}
      {archiveInvitationFlagEnabled && (
        <ArchiveInvitationAction invitation={invitation} />
      )}
    </M.Container>
  );
};

export default ExistingInvitation;
