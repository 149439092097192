export enum AdverseActionEventTypes {
  CANCELED = 'canceled',
  DEFERRED = 'created',
  DELIVERY_UNKNOWN = 'delivery_unknown',
  DISPUTED = 'disputed',
  MANUAL = 'manual',
  RESTARTED = 'restarted',
  // COMPLETED = 'completed', // TODO: include this event after confirmation from product
  // on adverse action notices
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  QUEUED = 'queued',
  DELIVERED = 'delivered',
  OPENED = 'opened',
  UNDELIVERABLE = 'undeliverable',
  PAUSED = 'paused',
  RESUMED = 'resumed',
}

export enum AdjudicationEventTypes {
  PRE_ADVERSE_ACTION = 'pre_adverse_action',
  POST_ADVERSE_ACTION = 'post_adverse_action',
  DEFERRED = 'deferred',
  ENGAGED = 'engaged',
  DISPUTED = 'disputed',
}

export enum ReportActionEventTypes {
  CANDIDATE_STORIES = 'candidate_stories',
}

export type EventView = {
  time: string;
  text: string;
  byline?: string;
  reason?: string;
  post_notice_unscheduled?: boolean;
  pdf_url?: string;
  pdf_encrypted?: boolean;
  notice?: string;
};

export type AdjudicationSubtype = {
  deletable?: boolean;
  description?: string;
  id?: string;
  kind?: string;
  name: string;
  slug?: string;
};

export type AdjudicationEvent = {
  type: string;
  subtype?: AdjudicationSubtype;
  id: string;
  time: string;
  user?: {
    email?: string;
    full_name?: string;
  };
};

export type ReportActionEvents = {
  candidate_stories?: CandidateStoryEvent[];
};

export type CandidateStoryEvent = {
  byline?: string;
  text?: string;
  time: string;
  type: string;
  user?: string;
  metadata?: {
    mode?: string;
  };
};

export type AdverseActionEvent = {
  event_type: string;
  event_subtype?: string;
  notice: string | undefined;
  delivery_type: string | undefined;
  metadata?: {
    mode?: string;
    user?: string;
    reason?: string;
    scheduled_at?: string;
    canceled_by?: string;
    sent_by?: string;
    post_notice_unscheduled?: boolean;
  };
  created_at: string;
  text?: string;
  byline?: string;
};
