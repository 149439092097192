export enum Headers {
  created_at = 'created_at',
  post_notice_scheduled_at = 'post_notice_scheduled_at',
  paused_time = 'paused_time',
  paused_user = 'paused_user',
  paused_duration = 'paused_duration',
}

export const headerText: Record<Headers, string> = {
  created_at: 'Pre-notice sent on',
  post_notice_scheduled_at: 'Post-notice sent on',
  paused_time: 'Paused on',
  paused_user: 'Paused by',
  paused_duration: 'Paused for',
};
