import React from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const SkipOrderModal: React.FC<Props> = ({ open, onCancel, onConfirm }) => (
  <M.ComposedModal type='small' kind='regular' open={open} onClose={onCancel}>
    <M.ModalHeader title='Not ready to order?' closeModal={onCancel} />
    <M.ModalBody>
      <p>
        Continue to complete your sign-up and order later in your Checkr
        dashboard.
      </p>
    </M.ModalBody>
    <M.ModalFooter
      actions={{
        primary: {
          name: 'Continue',
          'data-testid': 'skip-ordering-confirm',
          onClick: onConfirm,
        },
        secondary: {
          'data-testid': 'skip-ordering-cancel',
          onClick: onCancel,
        },
      }}
    />
  </M.ComposedModal>
);

export default SkipOrderModal;
