import React, { useCallback } from 'react';
import * as Entity from 'modules/assess/models/settings/account';
import * as Package from 'modules/assess/models/settings/package';
import { Settings } from 'modules/assess/utilities';
import styled from 'styled-components';
import SubSettingToggle from '../sub-setting-toggle';
import SelectedPackages from './selected-packages';
import SelectPackages from './select-packages';

type Props = {
  disabled?: boolean;
  settings?: Entity.Type;
  setSettings: React.Dispatch<React.SetStateAction<Entity.Type>>;
};

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.5rem;
`;

export const SelectPackagesSetting: React.FC<Props> = ({
  disabled,
  settings,
  setSettings,
}) => {
  const disableButton =
    disabled || settings?.adverse_action_automation?.value?.packages === null;
  const selections = Settings.getAutoAdverseActionPackages(settings?.packages);

  const setPackages = useCallback(
    (packages: Package.List) => {
      setSettings(state => {
        return { ...state, packages };
      });
    },
    [setSettings],
  );

  return (
    <Flex>
      <SubSettingToggle
        disabled={disabled}
        name='packages'
        settings={settings}
        setSettings={setSettings}
      />
      <SelectedPackages selections={selections} />
      <SelectPackages
        disabled={disableButton}
        packages={settings?.packages}
        setPackages={setPackages}
      />
    </Flex>
  );
};

export default SelectPackagesSetting;
