import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

const DatePicker = styled(M.DatePicker)`
  width: 100%;
  .cds--date-picker-container {
    width: 100%;
  }

  .cds--date-picker__input {
    width: 100% !important;
  }

  .cds--date-picker__icon--invalid {
    fill: ${colors.uiOrange600} !important;
    border-bottom-color: ${colors.uiOrange600} !important;
    padding: 11.5px !important;
  }
`;

export default DatePicker;
