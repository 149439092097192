import React, { useRef, useMemo, ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useBaseFilters } from '../hooks';
import { onFilterChange, AnalyticsProps } from '../helpers';

type Props = {
  component: string | ReactNode;
  filterName?: string;
  param?: string;
  params?: Array<string>;
  eventToValue?: (event: any) => any;
  onChange?: (values: any) => void;
  stateToValue?: (state: any) => any;
  valueToState?: (value: any) => any;
  [x: string]: any;
  trackAnalyticsObject?: AnalyticsProps | null;
};

/*
  Base filter is intended to work with Select and Input components
  for handling MultiSelect use the MultiSelectFilter
*/
const BaseFilter: React.FC<Props> = ({
  component,
  fallbackValue,
  filterName = '',
  onChange,
  param,
  params = [],
  eventToValue = e => e,
  stateToValue = state => state || '',
  valueToState = v => v,
  trackAnalyticsObject = null,
  ...props
}) => {
  if (param) {
    params.push(param);
  }

  const ref = useRef();
  const filter = param || filterName;
  const { states, setFilters } = useBaseFilters(params, ref, filter);

  const value = param ? stateToValue(states[param]) : stateToValue(states);

  const updater = useMemo(() => {
    return onFilterChange(setFilters, param, trackAnalyticsObject);
  }, [param, setFilters]);

  const Filter = useMemo(() => {
    if (typeof component === 'string') {
      return M[component];
    }

    return component;
  }, [component]);

  const handleChange = useMemo(() => {
    if (onChange) {
      return onChange(updater);
    }

    return (event: any) => {
      const value = eventToValue(event);
      const state = valueToState(value);
      updater(state);
    };
  }, [onChange, eventToValue, updater, valueToState]);

  return (
    <Filter
      value={value}
      defaultValue={undefined} // Required due to a bug with Mastodon
      onChange={handleChange}
      ref={ref}
      {...props}
    />
  );
};

export default BaseFilter;
