import React from 'react';
import { useSetNewAdverseAction } from 'modules/adjudication/api/hooks';
import { useModalState } from 'modules/adjudication/ui/adverse-action/initiate/flow/context';
import { AdverseActionBasis } from 'modules/adjudication/data';
import Basis from './basis';
import Items from './items';

const ItemsAndBasisSelector: React.FC = () => {
  const { newAdverseAction, showAdversibleItemsGroupedBySeverity } =
    useSetNewAdverseAction();
  const { basis } = useModalState('state');

  return (
    <>
      <Basis newAdverseAction={newAdverseAction} />
      {basis === AdverseActionBasis.REPORT_ITEM && (
        <Items
          newAdverseAction={newAdverseAction}
          showAdversibleItemsGroupedBySeverity={
            showAdversibleItemsGroupedBySeverity
          }
        />
      )}
    </>
  );
};

export default ItemsAndBasisSelector;
