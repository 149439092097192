import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Entity from 'modules/assess/models/rules/premade';
import { Namespace } from 'modules/assess/api';
import { useTranslation } from 'react-i18next';
import Title from '../../standard/table/Title';
import ToggleFairnessSettings from './ToggleFairnessSettings';

type Props = {
  namespace: Namespace;
  rules: Entity.List;
};

const List = styled.ul`
  padding-left: 15px !important;
  li {
    list-style-type: initial !important;
    list-style-position: inside !important;
    margin-top: 1em !important;
    margin-bottom: 1 em !important;
  }
`;

export const Rules: React.FC<Props> = ({ namespace, rules }) => {
  const { t } = useTranslation('assess');
  const [hidden, setHidden] = useState(false);
  const toggleRules = useCallback(() => setHidden(current => !current), []);

  const content =
    rules.length > 0 ? (
      Object.values(rules).map(rule => (
        <li key={rule.id}>
          <Title namespace={namespace} rule={rule} />
        </li>
      ))
    ) : (
      <li>{t('assess:rules.custom.notifications:no_fairness_rules')}</li>
    );

  return (
    <div data-testid='assess-rules-premade-rules-list'>
      <List>{!hidden && content}</List>
      {rules.length > 0 && (
        <ToggleFairnessSettings toggleRules={toggleRules} hidden={hidden} />
      )}
    </div>
  );
};

export default Rules;
