import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import {
  TYPE_USER,
  TYPE_PKG,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
} from 'Constants';
import { GenericObject } from '@dashboard-experience/utils';
import { useDispatch as useSegmentsDispatch } from 'components/Segments/SegmentsContext';
import { useErrorNotifier } from 'hooks';
import { SearchHierarchyParams } from './types';
import {
  patchAMSSegments,
  postSegments,
  fetchParentObject,
  getAssignedNodes,
  searchHierarchy,
} from './actions';
import {
  PACKAGES_SEGMENTS_QUERY_KEY,
  PACKAGES_SEGMENTS_PARENT_QUERY_KEY,
  AMS_API_PATCH_PATH,
  SEGMENTS_PATH,
  APPLICATION_PATH,
  NODES_PATH,
  ACCOUNTS_PATH,
  CUSTOMER_PATH,
  APP_INSTANCE_URL_QUERY,
  DEFAULT_ERROR,
  POST_SEGMENTS_SUCCESS,
  SEARCH_HIERARCHY,
} from './constants';

const postNodesPath = (
  parentContext: string,
  parentId: string,
  accountId: string,
) => {
  let path = `${parentContext}/${parentId}/${SEGMENTS_PATH}`;

  if (parentContext === TYPE_APP_INSTANCE_CUSTOMER) {
    path = `/${APPLICATION_PATH}/${parentId}/${NODES_PATH}`;
  } else if (parentContext === TYPE_MVR_RULE) {
    path = `${ACCOUNTS_PATH}/${accountId}/${parentContext}/${parentId}/${SEGMENTS_PATH}`;
  }

  return path;
};

const fetchNodesPath = (
  parentContext: string,
  parentId: string,
  accountId: string,
) => {
  let query = `?include=${parentContext}`;
  let path = `${parentContext}/${parentId}/${NODES_PATH}`;

  if (parentContext === TYPE_USER) {
    query = `?include_associations=true`;
  } else if (parentContext === TYPE_APP_INSTANCE_CUSTOMER) {
    path = `${APPLICATION_PATH}/${parentId}/${CUSTOMER_PATH}/${NODES_PATH}`;
    query = `?include=${APP_INSTANCE_URL_QUERY}`;
  } else if (parentContext === TYPE_MVR_RULE) {
    path = `${ACCOUNTS_PATH}/${accountId}/${parentContext}/${parentId}/${NODES_PATH}`;
  }

  return path + query;
};

const fetchParentObjectPath = (
  parentContext: string,
  parentId: string,
  accountId: string,
) => {
  const context =
    parentContext === TYPE_APP_INSTANCE_CUSTOMER
      ? `${APPLICATION_PATH}`
      : parentContext;
  const pkgPath = parentContext === TYPE_PKG ? `/v1` : '';
  const mvrPath =
    parentContext === TYPE_MVR_RULE ? `/${ACCOUNTS_PATH}/${accountId}` : '';

  return `${pkgPath}${mvrPath}/${context}/${parentId}`;
};

export const useFetchParentObject = (
  accountId: string,
  parentContext: string,
  parentId: string,
) => {
  const errorNotifier = useErrorNotifier();
  const key: AnyQueryKey = [PACKAGES_SEGMENTS_PARENT_QUERY_KEY];
  const path = fetchParentObjectPath(parentContext, parentId, accountId);
  const request = () => fetchParentObject(path);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: error => {
      errorNotifier(error, { title: 'Error fetching Parent Objects' });
    },
  });
};

export const useGetAssignedNodes = (
  accountId: string,
  parentContext: string,
  parentId: string,
) => {
  const errorNotifier = useErrorNotifier();
  const key: AnyQueryKey = [PACKAGES_SEGMENTS_QUERY_KEY, { id: accountId }];
  const path = fetchNodesPath(parentContext, parentId, accountId);
  const request = () => getAssignedNodes(path);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    onError: error => {
      errorNotifier(error, { title: 'Error fetching Parent Objects' });
    },
  });
};

export const useSearchHierarchy = () => {
  const dispatch = useDispatch();
  const segmentsDispatch = useSegmentsDispatch();
  const request = ({
    accountId,
    parentContext,
    query,
    page = 1,
  }: SearchHierarchyParams) =>
    searchHierarchy(
      `${ACCOUNTS_PATH}/${accountId}/${NODES_PATH}?page=${page}&per_page=5&node_name=${query}&include=${parentContext}`,
    );

  const [call] = useMutation(request, {
    onSuccess: (res, variables) => {
      const pageCount = Math.ceil(res.count / 5);
      const index = variables.page ? variables.page - 1 : 0;

      segmentsDispatch({
        type: SEARCH_HIERARCHY,
        payload: {
          filteredSegments: res.data,
          search: {
            pageCount,
            pageIndex: index,
            query: variables.query,
            isSearching: true,
          },
        },
      });
    },
    onError: error => {
      dispatch(toastError(`${DEFAULT_ERROR}`, error.message));
    },
  });

  return {
    call,
  };
};

export const usePostSegments = (
  accountId: string,
  parentContext: string,
  parentId: string,
  segments: string[],
) => {
  const dispatch = useDispatch();
  const segmentsDispatch = useSegmentsDispatch();
  let payload: GenericObject;
  let postSegmentsUrl: string;
  let request: any;

  if (parentContext === TYPE_USER) {
    payload = { nodes: segments };
    postSegmentsUrl = `${AMS_API_PATCH_PATH}/${parentId}`;
    request = () => patchAMSSegments(postSegmentsUrl, payload);
  } else {
    payload =
      parentContext === TYPE_APP_INSTANCE_CUSTOMER
        ? { nodes: segments, account_id: accountId }
        : { segments };
    postSegmentsUrl = postNodesPath(parentContext, parentId, accountId);
    request = () => postSegments(postSegmentsUrl, payload);
  }

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      segmentsDispatch({
        type: POST_SEGMENTS_SUCCESS,
      });
    },
    onError: error => {
      dispatch(toastError(`${DEFAULT_ERROR}`, error.message));
    },
  });

  return {
    call,
    result,
  };
};
