import React, { useCallback } from 'react';
import _ from 'lodash';
import { colors, M } from '@dashboard-experience/mastodon';

import { GenericObject } from '@dashboard-experience/utils';
import {
  TYPE_BRAND,
  TYPE_PAM,
  TYPE_PKG,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
} from '../../Constants';
import { Segment } from '../../api/segments';

const SelectedStyle = {
  backgroundColor: `${colors.uiNavy600}`,
  color: `${colors.uiGrey0}`,
  fontWeight: 'bold',
  cursor: 'pointer',
};

const UnselectedStyle = { cursor: 'pointer' };

type Props = {
  segment: Segment;
  onSelect: Function;
  selected: boolean;
  parentContext: string;
};

const SegmentsTableRow: React.FC<Props> = ({
  segment,
  onSelect,
  selected,
  parentContext,
}) => {
  const {
    tier,
    name,
    pam,
    custom_id,
    packages,
    brand,
    rules,
    application_instances_as_customer,
  } = segment;

  const rowTitleOptions: GenericObject = {
    [TYPE_PAM]: pam && pam.name,
    [TYPE_PKG]: packages ? packages.length : '0',
    [TYPE_BRAND]: brand && brand.name,
    [TYPE_MVR_RULE]: rules ? rules.length : '0',
    [TYPE_APP_INSTANCE_CUSTOMER]: application_instances_as_customer
      ? application_instances_as_customer.length
      : '0',
  };

  const style = selected ? SelectedStyle : UnselectedStyle;

  const onClick = useCallback(() => onSelect(custom_id), [custom_id, onSelect]);

  return (
    <M.TableRow
      onClick={onClick}
      data-testid={`segment-table-row-${custom_id}`}
    >
      <>
        <M.TableCell data-testid={`segment-tier-${custom_id}`} style={style}>
          {_.capitalize(tier)}
        </M.TableCell>
        <M.TableCell data-testid={`segment-name-${custom_id}`} style={style}>
          {name}
        </M.TableCell>
        <M.TableCell style={style}>
          {rowTitleOptions[parentContext]}
        </M.TableCell>
        <M.TableCell data-testid={`segment-id-${custom_id}`} style={style}>
          {custom_id}
        </M.TableCell>
      </>
    </M.TableRow>
  );
};

export default SegmentsTableRow;
