import React, { useMemo } from 'react';
import { useGetCustomer } from '../../api/billing';
import BillingCustomerContext from './context';

type Props = {
  accountId: string;
};
const Provider: React.FC<Props> = ({ accountId, children }) => {
  const { customerData, isLoading, isSuccess, error, refetch } =
    useGetCustomer(accountId);

  const providerValue = useMemo(
    () => ({
      customerData,
      isLoading,
      isSuccess,
      error,
      refetch,
    }),
    [customerData],
  );

  return (
    <BillingCustomerContext.Provider value={providerValue}>
      {children}
    </BillingCustomerContext.Provider>
  );
};

export default Provider;
