import React, { useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { isInternal } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Banner } from 'components/Banner';
import { useUser } from 'context/CurrentUser';
import UIContext from 'context/UI';
import { isDashboardBeta } from 'utils/URLHelper';
import { dashboardInternalUrl } from 'Constants';

const StyledBanner = styled(Banner)`
  #mastodon && {
    margin-top: 0;
    .cds--inline-notification__title {
      cursor: pointer;
      margin-bottom: 0.75rem;
    }
    .cds--inline-notification__details {
      cursor: pointer;
      margin: 0 2rem;
    }
  }
`;

const base_key = 'banners.beta';

const BetaBanner: React.FC<{}> = () => {
  const { isIframe } = useContext(UIContext);
  const user = useUser();
  const { t } = useTranslation();

  const { pathname, search, hash } = useLocation();
  const route = `${pathname}${search}`;
  const internalUrl = `${dashboardInternalUrl}${route}${hash}`;

  const redirectToInternalDashboard = useCallback(() => {
    window.location.replace(internalUrl);
  }, [internalUrl]);

  const title = t(`${base_key}.title`);
  const subtitle = t(`${base_key}.subtitle`);

  return !isIframe && isDashboardBeta() && isInternal(user) ? (
    <StyledBanner
      hideCloseButton
      kind='warning'
      title={title}
      subtitle={subtitle}
      data-testid='beta-banner'
      onClick={redirectToInternalDashboard}
    />
  ) : null;
};

export default BetaBanner;
