/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { inviteUser } from 'actions/AccountUsersActions';
import { emailIsValid } from '@dashboard-experience/utils';
import { StyledContainer } from './UsersStyledComponents';

export class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = { emailInput: '', nameInput: '' };
  }

  updateEmailInput = e => {
    this.setState({
      emailInput: e.target.value,
    });
  };

  updateNameInput = e => {
    this.setState({
      nameInput: e.target.value,
    });
  };

  addUser = () => {
    const { accountId } = this.props;

    if (accountId) {
      /* eslint-disable react/destructuring-assignment */
      this.props.inviteUser(
        accountId,
        this.state.emailInput,
        this.state.nameInput,
      );

      this.setState({ emailInput: '', nameInput: '' });
    }
  };

  render() {
    const { accountId } = this.props;
    const { emailInput, nameInput } = this.state;

    function nameError() {
      if (nameInput.length < 2 || nameInput.length > 100) {
        return 'Name must be between 2-100 characters.';
      }
      return '';
    }

    function emailError() {
      return !emailIsValid(emailInput) ? 'Email is invalid.' : '';
    }

    return (
      <StyledContainer>
        <M.GridRow>
          <M.GridCol sm={12} md={4} lg={6}>
            <M.Input
              type='email'
              id='email'
              placeholder='Enter email'
              labelText='Invite new users'
              disabled={!accountId}
              value={emailInput}
              onChange={this.updateEmailInput}
              invalid={!!(emailInput && emailError())}
              invalidText={emailError()}
              required
            />
          </M.GridCol>
          <M.GridCol sm={12} md={4} lg={6}>
            <M.Input
              type='name'
              id='name'
              placeholder='Enter full name'
              labelText='&nbsp;'
              disabled={!accountId}
              value={nameInput}
              onChange={this.updateNameInput}
              invalid={!!(nameInput && nameError())}
              invalidText={nameError()}
              required
            />
          </M.GridCol>
          <M.GridCol sm={12} md={12} lg={4}>
            <M.Button
              style={{ marginTop: '1.375rem' }}
              disabled={!!emailError() || !!nameError()}
              onClick={this.addUser}
            >
              Send invitation
            </M.Button>
          </M.GridCol>
        </M.GridRow>
      </StyledContainer>
    );
  }
}

AddUserForm.propTypes = {
  accountId: PropTypes.string,
  inviteUser: PropTypes.func,
};

AddUserForm.defaultProps = {
  accountId: '',
  inviteUser: () => {},
};

const mapDispatchToProps = dispatch => ({
  inviteUser: (accountId, email, full_name) =>
    dispatch(inviteUser(accountId, email, full_name)),
});

export default connect(null, mapDispatchToProps)(AddUserForm);
