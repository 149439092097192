const VALID_DEFAULT_DRUG_SCREENING_SUBTYPES = [
  'DS_5_PANEL',
  'DS_5_PANEL_NO_THC',
  'DS_INSTANT_5PANEL',
  'DS_7_PANEL',
  'DS_9_PANEL',
  'DS_9_PANEL_NO_THC',
  'DS_10_PANEL',
  'DS_10_PANEL_NO_THC',
];

export const buildEmploymentVerification = ({ dot_compliance = false }) => ({
  type: 'employment_verification',
  lookback_years: 7,
  requires_full_lookback: false,
  maximum_allowed_employers: null,
  requires_employment_history: false,
  international_upgrade: false,
  salary: false,
  manager_contact: true,
  contract_type: true,
  date_matching: 6,
  position_matching: 'strict',
  document_collection: true,
  dot_compliance,
});

export const buildMotorVehicleReport = ({
  requires_commerical_mvr = false,
}) => ({
  type: 'motor_vehicle_report',
  ...(requires_commerical_mvr && { mvr_type: [true, 'commercial'] }),
});

export const buildDrugScreening = ({
  drugScreeningSubtype = 'DS_10_PANEL',
}) => ({
  type: 'drug_screening',
  subtype: [
    ...(VALID_DEFAULT_DRUG_SCREENING_SUBTYPES.includes(drugScreeningSubtype)
      ? [drugScreeningSubtype]
      : ['DS_10_PANEL']),
  ],
  test_reason: [true, 'PRE_EMPLOYMENT'],
  externally_ordered: false,
  requires_observed_collection: false,
});

export const screeningPayloadsByType: { [key: string]: any } = {
  county_criminal_search: {
    type: 'county_criminal_search',
    subtype: '7years',
    requires_education_history: false,
    requires_employment_history: false,
  },
  state_criminal_search: {
    type: 'state_criminal_search',
    subtype: '7years',
    requires_education_history: false,
    requires_employment_history: false,
  },
  federal_criminal_search: {
    type: 'federal_criminal_search',
    subtype: '10years',
  },
  international_adverse_media_search: {
    type: 'international_adverse_media_search',
    out_of_country_history: true,
  },
  international_criminal_search_v2: {
    type: 'international_criminal_search_v2',
    out_of_country_history: true,
  },
  international_motor_vehicle_report: {
    type: 'international_motor_vehicle_report',
    include_driving_history: false,
  },
  county_civil_search: {
    type: 'county_civil_search',
    subtype: '7years',
  },
  federal_civil_search: {
    type: 'federal_civil_search',
  },
  motor_vehicle_report: buildMotorVehicleReport({}),
  facis_search: {
    type: 'facis_search',
    subtype: 1,
  },
  facis_search_level_3: {
    type: 'facis_search',
    subtype: 3,
  },
  employment_verification: buildEmploymentVerification({}),
  education_verification: {
    type: 'education_verification',
    requires_education_history: false,
    international_upgrade: false,
    requires_completed_degrees: false,
    document_collection: true,
    maximum_allowed_schools: 1,
  },
  personal_reference_verification: {
    type: 'personal_reference_verification',
    required_quantity: [true, 1],
  },
  professional_reference_verification: {
    type: 'professional_reference_verification',
    required_quantity: [true, 1],
  },
  professional_license_verification: {
    type: 'pro_license_verification',
    input_required: [false],
    locations: [
      true,
      '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
    ],
  },
  pro_license_verification: {
    type: 'pro_license_verification',
    input_required: [false],
    locations: [
      true,
      '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
    ],
  },
  international_education_verification: {
    type: 'international_education_verification',
  },
  international_employment_verification: {
    type: 'international_employment_verification',
  },
  international_global_watchlist_search: {
    type: 'international_global_watchlist_search',
  },
  international_identity_document_validation: {
    type: 'international_identity_document_validation',
  },
  international_right_to_work: {
    type: 'international_right_to_work',
  },
  drug_screening: buildDrugScreening({}),
  occupational_health_screening: {
    type: 'occupational_health_screening',
    subtype: [],
  },
  credit_report: {
    type: 'credit_report',
  },
  global_watchlist_search: {
    type: 'global_watchlist_search',
    subtype: null,
    preferences: null,
  },
  ssn_trace: {
    type: 'ssn_trace',
  },
  sex_offender_search: {
    type: 'sex_offender_search',
  },
  national_criminal_search: {
    type: 'national_criminal_search',
  },
};

export const screeningTypes = Object.keys(screeningPayloadsByType);
export const ssnDependentScreeningTypes = [
  'county_criminal_search',
  'state_criminal_search',
  'federal_criminal_search',
  'international_criminal_search_v2',
  'county_civil_search',
  'federal_civil_search',
];

export default Object.values(screeningPayloadsByType);

// these addon requests are needed to gather all the pricing data.
// from this doc: https://checkr.atlassian.net/wiki/spaces/RD/pages/1984790618/Package+Add-ons+Calculating+Prices
export const itemizedAddonRequest1 = {
  name: 'add ons 1',
  slug: 'add_ons_1',
  screenings: [
    {
      type: 'county_criminal_search',
      subtype: '7years',
      requires_education_history: false,
      requires_employment_history: false,
    },
    {
      type: 'state_criminal_search',
      subtype: '7years',
      requires_education_history: false,
      requires_employment_history: false,
    },
    {
      type: 'federal_criminal_search',
      subtype: '10years',
    },
    {
      type: 'international_criminal_search_v2',
      out_of_country_history: true,
    },
    {
      type: 'international_adverse_media_search',
      out_of_country_history: true,
    },
    {
      type: 'county_civil_search',
      subtype: '7years',
    },
    {
      type: 'federal_civil_search',
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 1],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 1],
    },
    {
      type: 'education_verification',
      international_upgrade: false,
      requires_education_history: false,
    },
    {
      type: 'employment_verification',
      maximum_allowed_employers: null,
      lookback_years: 3,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: false,
      salary: false,
      contract_type: false,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
      dot_compliance: false,
    },
    {
      type: 'motor_vehicle_report',
    },
    {
      type: 'facis_search',
      subtype: '1',
    },
    {
      type: 'pro_license_verification',
      input_required: [false],
      license_categories: [true, '["Accounting"]'],
      locations: [
        true,
        '["AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY","United States"]',
      ],
    },
    { type: 'global_watchlist_search', subtype: null, preferences: null },
  ],
};

export const itemizedAddonRequest2 = {
  name: 'add ons 2',
  slug: 'add_ons_2',
  screenings: [
    {
      type: 'employment_verification',
      maximum_allowed_employers: null,
      lookback_years: 5,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: false,
      salary: false,
      contract_type: false,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
      dot_compliance: false,
    },
    {
      type: 'facis_search',
      subtype: '3',
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 2],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 2],
    },
    {
      type: 'county_criminal_search',
      subtype: 'current',
      requires_education_history: false,
      requires_employment_history: false,
    },
    {
      type: 'state_criminal_search',
      subtype: 'current',
    },
  ],
};

export const itemizedAddonRequest3 = {
  name: 'add ons 3',
  slug: 'add_ons_3',
  screenings: [
    {
      type: 'employment_verification',
      maximum_allowed_employers: null,
      lookback_years: 7,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: true,
      salary: false,
      contract_type: true,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
      dot_compliance: false,
    },
    {
      type: 'personal_reference_verification',
      required_quantity: [true, 3],
    },
    {
      type: 'professional_reference_verification',
      required_quantity: [true, 3],
    },
  ],
};

export const itemizedAddonRequest4 = {
  name: 'add ons 4',
  slug: 'add_ons_4',
  screenings: [
    {
      type: 'credit_report',
    },
  ],
};

export const itemizedAddonRequest5 = {
  name: 'add ons 5',
  slug: 'add_ons_5',
  screenings: [
    {
      type: 'employment_verification',
      lookback_years: 10,
      maximum_allowed_employers: 1,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: true,
      salary: false,
      contract_type: true,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
      dot_compliance: false,
    },
  ],
};

export const itemizedAddonRequest6 = {
  name: 'add ons 6',
  slug: 'add_ons_6',
  screenings: [
    {
      type: 'employment_verification',
      lookback_years: 10,
      maximum_allowed_employers: null,
      position_matching: 'strict',
      date_matching: 186,
      manager_contact: true,
      salary: false,
      contract_type: true,
      international_upgrade: false,
      requires_employment_history: false,
      requires_full_lookback: false,
      dot_compliance: false,
    },
  ],
};

export const ADDON_SKUS: any = {
  'CIV-0002-7': {
    product_type: 'county_civil_search',
    role: 'bundle',
    name: 'County Civil Search',
    mode: 'all_of',
  },
  'CRM-0005-7-7': {
    product_type: 'county_criminal_search',
    role: 'bundle',
    name: 'County Criminal Search',
    mode: 'all_of',
  },
  'VRF-0001': {
    product_type: 'education_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0002-3': {
    product_type: 'employment_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0003-1': {
    product_type: 'facis_search',
    role: 'bundle',
    mode: 'all_of',
  },
  'CIV-0003': {
    product_type: 'federal_civil_search',
    role: 'bundle',
    name: 'Federal Civil Search',
    mode: 'all_of',
  },
  'CRM-0007-10': {
    product_type: 'federal_criminal_search',
    role: 'bundle',
    name: 'Federal Criminal Search',
    mode: 'all_of',
  },
  'CRM-0003': {
    product_type: 'global_watchlist_search',
    role: 'bundle',
    name: 'Global Watchlist Search',
    mode: 'all_of',
  },
  'INT-0005': {
    product_type: 'international_adverse_media_search',
    role: 'service_fee',
    name: 'International Adverse Media Search Charges',
    mode: 'all_of',
  },
  'INT-CRM-CONTAINER': {
    product_type: 'international_criminal_search_v2',
    role: 'container',
    name: 'International Criminal Search Charges',
    mode: 'all_of',
  },
  'DRV-0001': {
    product_type: 'motor_vehicle_report',
    role: 'bundle',
    name: 'Motor Vehicle Record',
    mode: 'all_of',
  },
  'DRV-0001-RERUN': {
    product_type: 'motor_vehicle_report',
    role: 'service_fee',
    name: 'Motor Vehicle Record Rerun Service Fee',
    mode: 'any_of',
  },
  'VRF-0004': {
    product_type: 'personal_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0006': {
    product_type: 'professional_license_verification',
    role: 'service_fee',
    mode: 'all_of',
  },
  'VRF-0005': {
    product_type: 'professional_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'CRM-0006-7-7': {
    product_type: 'state_criminal_search',
    role: 'bundle',
    name: 'State Criminal Search',
    mode: 'all_of',
  },
  'CRM-0005-C': {
    product_type: 'county_criminal_search',
    role: 'bundle',
    name: 'County Criminal Search',
    mode: 'all_of',
  },
  'VRF-0002-5': {
    product_type: 'employment_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0003-3': {
    product_type: 'facis_search',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0004-2': {
    product_type: 'personal_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0005-2': {
    product_type: 'professional_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'CRM-0006-C': {
    product_type: 'state_criminal_search',
    role: 'bundle',
    name: 'State Criminal Search',
    mode: 'all_of',
  },
  'VRF-0002-7': {
    product_type: 'employment_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0004-3': {
    product_type: 'personal_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0005-3': {
    product_type: 'professional_reference_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'CIV-0001': { product_type: 'credit_report', role: 'bundle', mode: 'all_of' },
  'VRF-0002-C': {
    product_type: 'employment_verification',
    role: 'bundle',
    mode: 'all_of',
  },
  'VRF-0002-10': {
    product_type: 'employment_verification',
    role: 'bundle',
    mode: 'all_of',
  },
};

export const ADDON_SKU_IDS = Object.entries(ADDON_SKUS).map(([key]) => {
  return key;
});
