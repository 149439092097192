import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {
  dataTestid: string;
  onClick: () => void;
  labelTranKey: string;
  iconKey: string;
};

export const CreateButton = ({
  dataTestid,
  onClick,
  labelTranKey,
  iconKey,
}: Props) => {
  const { t } = useTranslation('');
  const label = t(labelTranKey);

  return (
    <M.Button
      data-testid={dataTestid}
      onClick={onClick}
      size='sm'
      kind='secondary'
    >
      <M.Icon icon={iconKey} style={{ marginRight: '0.5rem' }} />
      {label}
    </M.Button>
  );
};

export default CreateButton;
