import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const StyledContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -4px 0px 0px ${colors.uiPink600};
  border-radius: 4px;
  color: ${colors.uiTextPrimaryLight};
  display: flex;
  padding-bottom: 2rem;
  padding-right: 1.5rem;
  padding-top: 1em;
`;

const StyledWarning = styled.span`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.25rem;
`;

type Props = {
  warning: string;
};

const Container: React.FC<Props> = ({ warning }) => {
  return (
    <StyledContainer data-testid='fair-chance-sections-adverse-action-rate-warning'>
      <StyledWarning>{warning}</StyledWarning>
    </StyledContainer>
  );
};

export default Container;
