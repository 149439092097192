import { AccountServiceClient } from 'api';
import { GenericObject } from 'types';

export type PostApplicationParams = {
  partnerId: string;
  params?: { [key: string]: any };
};

export const postPartnerApplication = (
  partnerId: any,
  params: any,
): Promise<GenericObject> => {
  return AccountServiceClient.post(
    `partner_accounts/${partnerId}/applications`,
    params,
  );
};

export const getPartnerApplications = (
  partnerId: string,
): Promise<{ [key: string]: any }> => {
  return AccountServiceClient.get(
    `partner_accounts/${partnerId}/applications?include=webhook`,
  );
};

export const putPartnerApplication = (
  applicationId: string,
  partnerId: string,
  params: any,
): Promise<{ [key: string]: any }> => {
  return AccountServiceClient.put(
    `partner_accounts/${partnerId}/applications/${applicationId}`,
    params,
  );
};

export const deletePartnerApplication = (
  applicationId: string,
  partnerId: string,
): Promise<GenericObject> => {
  return AccountServiceClient.delete(
    `partner_accounts/${partnerId}/applications/${applicationId}`,
  );
};
