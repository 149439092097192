import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import StatusCircle from 'components/StatusCircle';
import { Row, FlexContainer, CustomCell } from './TableComponents';
import { TableParams } from '../types';

type Props = Pick<TableParams, 'assessEnabled' | 'statusOrAssessment'> & {
  row: { [x: string]: any };
};

const tooltipText =
  'To ensure compliance, please issue a higher-precision reflexive test kit, and enter the Kit ID from the administered test';

const V2DrugRow: React.FC<Props> = ({
  row,
  assessEnabled,
  statusOrAssessment,
}) => {
  const { name, disposition, quantity } = row;

  let status: React.ReactElement | null = null;
  if (disposition.toLowerCase() === 'positive' || quantity > 0) {
    status = assessEnabled ? (
      <M.StatusIcon
        icon={statusOrAssessment}
        data-testid='mastodon-status-icon'
      />
    ) : (
      <StatusCircle status='positive' />
    );
  }

  const resultText = disposition || quantity;
  const result =
    disposition.toLowerCase() === 'inconclusive' ? (
      <M.TooltipDefinition definition={tooltipText} direction='top'>
        {resultText}
      </M.TooltipDefinition>
    ) : (
      resultText
    );

  return (
    <Row data-testid='drug-health-table-body-row'>
      <M.TableCell>{name}</M.TableCell>
      <CustomCell>
        <FlexContainer>
          {status}
          {result}
        </FlexContainer>
      </CustomCell>
    </Row>
  );
};

export default V2DrugRow;
