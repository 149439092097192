import React from 'react';
import styled from 'styled-components';

import ReportDateFilter from './ReportDateFilter';
import { styledFilter } from './StyledComponents';

const StyledDiv = styled.div`
  ${styledFilter}
`;

const ReportCompletedFilter = () => {
  return (
    <StyledDiv>
      <ReportDateFilter
        component='ReportCompletedFilter'
        from='reports_completed_after'
        to='reports_completed_before'
      />
    </StyledDiv>
  );
};

export default ReportCompletedFilter;
