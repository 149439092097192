import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ReactQueryProvider } from 'containers';
import { datadogRum } from '@datadog/browser-rum';

import DashboardWrapper from 'components/DashboardWrapper';
import { Overlay } from 'components/Signup/ui/Modal/styles';
import PackagesPage from 'components/Signup/PackagesPage';
import AddOnsPage from 'components/Signup/AddOnsPage';
import CandidateInfoPage from 'components/Signup/CandidateInfoPage';
import CreateAccountPage from 'components/Signup/CreateAccountPage';
import PaymentPage from 'components/Signup/PaymentPage';
import FlagrProviderWrapper from 'components/FlagrProviderWrapper';
import SignupPageProvider from './provider';
import withAuthWrapper from './SignupAuthWrapper';

const SignupPage = () => {
  useEffect(() => {
    datadogRum.startSessionReplayRecording();
  }, []);
  return (
    <FlagrProviderWrapper>
      <ReactQueryProvider>
        <SignupPageProvider>
          <Overlay />
          <DashboardWrapper>
            <Switch>
              <Route
                path='/signup/create-account'
                component={CreateAccountPage}
              />
              <Route
                path='/signup/packages'
                component={withAuthWrapper(PackagesPage)}
              />
              <Route
                path='/signup/add-ons'
                component={withAuthWrapper(AddOnsPage)}
              />
              <Route
                path='/signup/candidate-info'
                component={withAuthWrapper(CandidateInfoPage)}
              />
              <Route
                path='/signup/payment'
                component={withAuthWrapper(PaymentPage)}
              />
              <Route path='/signup*'>
                <Redirect to='/signup/create-account' />
              </Route>
            </Switch>
          </DashboardWrapper>
        </SignupPageProvider>
      </ReactQueryProvider>
    </FlagrProviderWrapper>
  );
};

export default SignupPage;
