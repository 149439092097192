import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

interface DeletionDialogProps {
  handleClose: (target: any) => void;
}
const DeleteInternalErrorDialog: React.FC<DeletionDialogProps> = ({
  handleClose,
}: DeletionDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <M.ModalBody padding='0'>
        <h1>
          {t('data_deletion.delete_candidate_data_internal_error.text.title')}
        </h1>
        <p>
          {t('data_deletion.delete_candidate_data_internal_error.text.message')}
        </p>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='primary' onClick={handleClose}>
          {t('verbs.close')}
        </M.Button>
      </M.ModalFooter>
    </>
  );
};

export default DeleteInternalErrorDialog;
