import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'modules/assess/api';
import { RulesetSettings } from 'modules/assess/models';
import { useRulesetConfig } from '../../hooks';
import { useNamespace } from '../../router/context';

const CRIMINAL_TABS = ['standard', 'lookback', 'custom', 'count'];
const MVR_TABS = ['standard', 'categories', 'custom', 'count'];

export type Type = React.FC<Props>;

export type Props = {
  categories: React.ReactNode;
  count: React.ReactNode;
  custom: React.ReactNode;
  lookback: React.ReactNode;
  standard: React.ReactNode;
};

export const Rules: Type = params => {
  const { t } = useTranslation('assess');
  const namespace = useNamespace();
  let tabs: Array<[string, React.ReactNode]> | null;
  const config = useRulesetConfig();

  if (!config) {
    return null;
  }

  const map = getTabsConfigMap(params);

  switch (namespace) {
    case Namespace.criminal:
      tabs = getTabs(config, map, CRIMINAL_TABS);
      break;
    case Namespace.mvr:
      tabs = getTabs(config, map, MVR_TABS);
      break;
    default:
      tabs = [];
  }

  function getTabLabel(label: string) {
    return t([
      `assess:rules.${label}.label.${namespace}`,
      `assess:rules.${label}.label`,
    ]);
  }

  const tabsElem: React.ReactNode | null =
    tabs.length > 0 ? (
      <M.Tabs>
        <M.TabList contained>
          {tabs.map(([label]: any) => (
            <M.Tab data-testid={label} key={label}>
              {getTabLabel(label)}
            </M.Tab>
          ))}
        </M.TabList>
        <M.TabPanels>
          {tabs.map(([label, elem]: any) => (
            <M.TabPanel data-testid={label} key={label}>
              {elem}
            </M.TabPanel>
          ))}
        </M.TabPanels>
      </M.Tabs>
    ) : null;

  return <div data-testid='assess-ruleset-version-rules'>{tabsElem}</div>;
};

function getTabs(
  settings: RulesetSettings,
  map: any,
  tabNames: string[],
): Array<[string, React.ReactNode]> {
  const tabs: Array<[string, React.ReactNode]> = [];
  tabNames.forEach(t => {
    const { path } = map[t];
    const enabled = getSettingsValue(settings, path);
    if (enabled) {
      const { component } = map[t];
      tabs.push([t, component]);
    }
  });

  return tabs;
}

function getTabsConfigMap(params: Props) {
  return {
    categories: {
      component: params.categories,
      path: 'category_code_sets.enabled',
    },
    count: {
      component: params.count,
      path: 'rulesets.rules.count.enabled',
    },
    custom: {
      component: params.custom,
      path: 'rulesets.rules.custom.enabled',
    },
    lookback: {
      component: params.lookback,
      path: 'rulesets.rules.lookback.enabled',
    },
    standard: {
      component: params.standard,
      path: 'rulesets.rules.fairness.enabled',
    },
  };
}

function getSettingsValue(settings: RulesetSettings, path: string) {
  return path
    .split('.')
    .reduce((a: { [x: string]: any }, b: string | number) => a[b], settings);
}

export default Rules;
