import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ADJUDICATION_KINDS, GenericObject } from '@dashboard-experience/utils';
import { namespace } from 'modules/adjudication/locales';
import { useSetAdjudication } from 'modules/adjudication/api';
import {
  useGetPostSubtypePayload,
  Subtype,
} from 'modules/adjudication/utilities';
import { ModalFooter } from 'modules/adjudication/ui/common';
import { useGetReport } from 'api/reports';

type Props = {
  selection: Subtype | undefined;
  onClose: () => void;
};

const Submit: React.FC<Props> = ({ selection, onClose }) => {
  const { t } = useTranslation();
  const cancel = t(`${namespace}:report.actions.engaged.modal.actions.cancel`);
  const submit = t(`${namespace}:report.actions.engaged.modal.actions.submit`);
  const message = t(`${namespace}:report.actions.engaged.modal.submit.message`);
  const canceledItems = t(
    `${namespace}:report.actions.engaged.modal.submit.canceled_items`,
  );

  const { candidateId }: GenericObject = useParams();
  const { report } = useGetReport();
  const payload = useGetPostSubtypePayload({
    subtypeKind: ADJUDICATION_KINDS.ENGAGED,
    selection,
  });
  const { call } = useSetAdjudication(report?.id, candidateId);

  const close = {
    name: cancel,
    onClick: onClose,
  };

  const next = {
    name: submit,
    onClick: () => {
      call(payload);
      onClose();
    },
  };

  return (
    <>
      <p className='p2'>
        {report.includes_canceled && (
          <>
            {canceledItems}
            <br />
            <br />
          </>
        )}
        {message}
      </p>
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default Submit;
