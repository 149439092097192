import React from 'react';
import { GenericObject } from 'types';
import { mostRecent } from './utils';
import { CellProps } from './types';

const getCostCenter = (reportOrInvite?: GenericObject) => {
  if (reportOrInvite?.cost_center?.name) {
    return reportOrInvite.cost_center.name as string;
  }
  return null;
};

const CostCenter: React.FC<CellProps> = ({ candidate, report }) => {
  const { reports, invitations } = candidate;
  const mostRecentReport: GenericObject | undefined = mostRecent(reports);
  const mostRecentInvitation: GenericObject | undefined =
    mostRecent(invitations);
  const text = getCostCenter(mostRecentReport || mostRecentInvitation);

  return text ? <span>{text}</span> : null;
};

export default CostCenter;
