import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import AddNote from './AddNote';
import ShowNotes from './ShowNotes';

const NotesTitle = styled.div`
  color: ${colors.uiGrey600};
  margin-bottom: 1rem;
`;

const Discussions = () => {
  const { t } = useTranslation('case-management');

  return (
    <div data-testid='case-management-notes'>
      <NotesTitle>{t('internal-notes.notes')}</NotesTitle>
      <ShowNotes />
      <AddNote />
    </div>
  );
};

export default Discussions;
