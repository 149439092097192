import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import AdverseActionedDetail from './AdverseActionedDetail';

const AdverseActionedDetails = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const StyledContainer = styled.div`
  color: ${colors.uiTextPrimaryLight};
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  padding-right: 1.5rem;
  padding-top: 1em;
`;

type Props = {
  content: any;
  over_seven_year_charge_adverse_actions?: number;
  pending_charge_adverse_actions?: number;
  petty_offenses_adverse_actions?: number;
  under_twenty_five_charges_adverse_actions?: number;
};

const Container: React.FC<Props> = ({
  content,
  over_seven_year_charge_adverse_actions,
  pending_charge_adverse_actions,
  petty_offenses_adverse_actions,
  under_twenty_five_charges_adverse_actions,
}) => {
  const {
    pending_charges,
    petty_charges,
    only_seven_year_old_charges,
    younger_than_twenty_five_charges,
    title,
  } = content;

  return (
    <StyledContainer data-testid='fair-chance-sections-fair-chance-opportunity-adverse-actioned-data'>
      <h5>{title}</h5>
      <AdverseActionedDetails>
        <AdverseActionedDetail
          content={pending_charges}
          data={pending_charge_adverse_actions}
        />
        <AdverseActionedDetail
          content={petty_charges}
          data={petty_offenses_adverse_actions}
        />
        <AdverseActionedDetail
          content={only_seven_year_old_charges}
          data={over_seven_year_charge_adverse_actions}
        />
        <AdverseActionedDetail
          content={younger_than_twenty_five_charges}
          data={under_twenty_five_charges_adverse_actions}
        />
      </AdverseActionedDetails>
    </StyledContainer>
  );
};

export default Container;
