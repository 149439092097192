import React, { useCallback } from 'react';
import { unparse } from 'papaparse';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useOrderBackgroundCheckContext } from '../../Context';
import { downloadBlobAsFile } from '../../../../../utils/APIClient';
import EditableSection from '../EditableSection';

const DownloadCSVLink = styled(M.Link)`
  cursor: pointer;
  display: block;
  line-height: 22px;
`;

export type CustomerInputCSVProps = {
  handleEdit: (path: string, title: string) => void;
};

const CustomerInputCSV: React.FC<CustomerInputCSVProps> = ({ handleEdit }) => {
  const { state } = useOrderBackgroundCheckContext();
  const { manualBulkUploadData } = state;

  const handleCsvUpload = useCallback(() => {
    handleEdit('/order/customize-with-addons', 'Upload candidate information');
  }, [handleEdit]);

  const downloadManualBulkCsv = useCallback(
    (e: any) => {
      e.preventDefault();

      const blob = new Blob([unparse(manualBulkUploadData.parsedData)]);
      downloadBlobAsFile({
        data: blob,
        headers: {
          'content-type': 'application/CSV',
        },
        filename: manualBulkUploadData.fileName,
      });
    },
    [manualBulkUploadData.fileName, manualBulkUploadData.parsedData],
  );

  return (
    <EditableSection
      id='candidates-manual'
      sectionTitle='Candidates'
      onEditClick={handleCsvUpload}
      description={{
        title: 'Reports will be run on each person included in:',
        content: (
          <DownloadCSVLink
            data-testid='uploaded-manual-bulk-csv-link'
            size='lg'
            onClick={downloadManualBulkCsv}
            download
          >
            {manualBulkUploadData.fileName}
          </DownloadCSVLink>
        ),
        isCandidatesSection: true,
        expand: true,
      }}
      inlineEdit
    />
  );
};

export default CustomerInputCSV;
