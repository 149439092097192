import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { GenericObject } from 'types';
import { getMisMatchOrNotFoundText } from './Helpers';
import { StatusDecorator } from './MultiMotorVehicleReportComponents';

type ReportHeadingProps = {
  licenseReport: GenericObject;
};

export const StyledSpan = styled.span`
  font-weight: bold;
`;

const HeadingLeft: React.FC<ReportHeadingProps> = ({ licenseReport }) => {
  return (
    <StyledSpan>
      {licenseReport.state} - {licenseReport.number} -{' '}
      {licenseReport.current_license && <span>Current License</span>}
      {!licenseReport.current_license && <span>Previous License</span>}
    </StyledSpan>
  );
};

const ReportHeading: React.FC<ReportHeadingProps> = ({ licenseReport }) => {
  const misMatchOrNotFoundText = getMisMatchOrNotFoundText(licenseReport);
  return (
    <M.Screening.Heading
      headingLeft={{
        name: <HeadingLeft licenseReport={licenseReport} />,
      }}
      headingRight={
        <StatusDecorator statusDecoratorText={misMatchOrNotFoundText} />
      }
    />
  );
};

export default ReportHeading;
