import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type Props = {
  current: number;
  total: number;
};

const StyledIcon = styled(M.StatusIcon)`
  background-color: ${colors.brandAqua3} !important;
  svg {
    fill: ${colors.brandAqua3} !important;
  }
`;

const StyledContainer = styled.div`
  background: rgba(112, 204, 216, 0.1);
  border-radius: 20px;
  display: flex;
  gap: 4px;
  height: 21px;
  padding: 3px 10px 2px;
  margin-left: 1rem;
`;

const StyledText = styled.span`
  color: ${colors.brandAqua3};
  font-weight: bold;
  font-size: 8px;
  height: 16px;
  letter-spacing: 1px;
  line-height: 16px;
`;

export const Count: React.FC<Props> = ({ current, total }) => {
  const { t } = useTranslation('assess:v2');
  const text = t('standard.guideline.content.options.current_state', {
    current,
    total,
  }).toUpperCase();

  const textId = `assess-v2-ui-guideline-options-count-${text}`;

  return (
    <StyledContainer id='assess-v2-ui-guideline-options-count-container'>
      <StyledIcon id='assess-v2-ui-guideline-options-count-icon' icon='clear' />
      <StyledText id={textId}>{text}</StyledText>
    </StyledContainer>
  );
};

export default Count;
