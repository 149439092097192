import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

export type Props = {
  defaultValue?: string;
  onChange: (e: string) => void;
};

export const Content: React.FC<Props> = ({ defaultValue, onChange }) => {
  const { t } = useTranslation('assess');
  const header = t('assess:ruleset.create.label');
  const placeholder = t('assess:ruleset.create.placeholder');
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value || ''),
    [onChange],
  );

  return (
    <M.Input
      data-testid='assess-ruleset-dialog-create-input'
      defaultValue={defaultValue}
      labelText={header}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default Content;
