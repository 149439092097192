import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import { ID } from 'modules/id';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { useDraftPublish } from '../api/hooks';
import Dialog from '../publish';

type Props = {
  ruleset?: ID;
  version?: ID;
};

type Type = React.FC<Props>;

export const Publish: Type = ({ ruleset, version }) => {
  const publish = useDraftPublish(ruleset);
  const history = useHistory();
  const [open, show] = useState(false);
  const isLoading = publish.result.status === 'loading';

  const handleClick = useCallback(() => show(true), [show]);
  const handleClose = useCallback(() => show(false), [show]);

  useEffect(() => {
    if (publish.result.status === 'success') {
      history.push('active');
    }
  }, [history, publish.result.status]);

  return (
    <>
      <PageLoading enabled={isLoading} />
      <Button
        disabled={useReadOnly()}
        data-testid='assess-ruleset-version-actions-publish'
        kind='primary'
        size='sm'
        trans='verbs.publish'
        onClick={handleClick}
      />
      <Dialog
        disabled={isLoading}
        open={open}
        publish={publish.call}
        version={version}
        onClose={handleClose}
      />
    </>
  );
};

export default Publish;
