const ExamReorderTransitions = {
  ScreeningSelection: {
    transitions: {
      next: 'Appointment',
    },
  },
  Appointment: {
    transitions: {
      previous: 'ScreeningSelection',
      next: 'ClinicSelection',
    },
  },
  ClinicSelection: {
    transitions: {
      previous: 'Appointment',
      next: 'Review',
    },
  },
  Review: {
    transitions: {
      previous: 'ClinicSelection',
      success: 'Success',
      error: 'Error',
    },
  },
  Success: {
    transitions: {},
  },
  Error: {
    transitions: {
      next: 'ClinicSelection',
      error: 'Error',
      review: 'Review',
    },
  },
};

export default ExamReorderTransitions;
