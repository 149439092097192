import moment from 'moment';
import pluralize from 'pluralize';

export const padNumber = number => (number < 10 ? `0${number}` : `${number}`);

export const ageOn = (birthday, date) => moment(date).diff(birthday, 'years');

export const age = birthday => {
  if (birthday) {
    return moment().diff(birthday, 'years');
  }

  return null;
};

export const diffInWords = (startDate, endDate) => {
  const diffSeconds = moment(endDate).diff(moment(startDate), 'seconds');
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffMonths = diffDays / 30;
  const diffYears = diffMonths / 12;

  if (diffSeconds < 60) {
    return pluralize('second', Math.round(diffSeconds), true);
  }
  if (diffMinutes < 60) {
    return pluralize('minute', Math.round(diffMinutes), true);
  }
  if (diffHours < 24) {
    return pluralize('hour', Math.round(diffHours), true);
  }
  if (diffDays < 30) {
    return pluralize('day', Math.round(diffDays), true);
  }
  if (diffMonths < 12) {
    return pluralize('month', Math.round(diffMonths), true);
  }

  return pluralize('year', Math.round(diffYears), true);
};

export const SECONDS = 1000;
export const MINUTES = SECONDS * 60;
export const HOURS = MINUTES * 60;
export const DAYS = HOURS * 24;

export const timeFromNow = time => diffInWords(moment.now(), time);
