/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import debounce from 'lodash/debounce';
import styled from 'styled-components';

import * as utils from 'components/AddScreenings/shared/utils';
import InternationalAddScreeningsSummary from 'components/AddScreenings/International/Summary';
import { Screening } from 'components/AddScreenings/types/Screenings';
import {
  usePackagePrice,
  useAllAddOnPricesFromSkus,
} from 'components/AddScreenings/hooks/usePackagePrice';
import AddScreeningsList, {
  getDisabledScreenings,
} from 'components/AddScreenings/AddScreeningList';
import PopularAddOns from 'components/AddScreenings/Domestic/PopularAddOns';
import OriginType from 'components/Packages/enums/OriginType';
import ScreeningType from 'components/AddScreenings/enums/ScreeningType';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
} from '../OrderBackgroundCheck/Context';
import DomesticAddScreeningsSummary from './DomesticAddScreeningSummary';

const InnerContainer = styled(M.Container)`
  display: flex;
  padding: 0px !important;
  margin-bottom: 0px;

  @media (min-width: 672px) {
    flex-direction: row;
  }
`;

const ScreeningsSection = styled.div`
  border-bottom: 1px solid ${colors.uiGrey200};
  margin-bottom: 24px !important;

  @media (min-width: 672px) {
    flex: 1 1 65%;
    border-bottom: 0;
  }

  @media (max-width: 672px) {
    min-height: 400px !important;
  }
`;

const SummaryContainer = styled.div<SummaryContainerProps>`
  margin-left: 16px;
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  min-width: 375px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: 560px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 780px) {
    top: 0;
  }

  @media (max-width: 672px) {
    padding-top: 50px !important;
  }
`;

const SummarySection = styled.div<SummarySectionProps>`
  padding: 24px;
  overflow-y: auto;

  @media (min-width: 672px) {
    min-width: 375px;
    max-width: 375px;
    flex: 1 1 35%;
  }
`;

type TopbarProps = {
  top?: number;
};

const TitleSection = styled.div<TopbarProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({ top }) => top}px;
  margin-bottom: 15px;
  color: ${colors.brandNavy4};
`;

const InlineNotification = styled(M.InlineNotification)`
  margin-left: 1px;
  width: 360px;
`;

const Margin16 = styled.div`
  margin-top: 16px;
`;

const ADDITIONAL_PACKAGE_SUMMARY_PADDING = 24;

type SummaryContainerProps = {
  maxHeight: number;
};

type SummarySectionProps = {
  maxHeight: number;
};

type Props = {
  account: any;
  hasNoSelectionError: boolean;
  windowOffsetY?: number;
  windowInnerHeight?: number;
};

const getPreviouslyOrderedScreenings = (previousScreenings?: Screening[]) => {
  if (!previousScreenings) return [];
  return previousScreenings.map(screening => screening.type as ScreeningType);
};

const ChecksAddons: React.FC<Props> = ({
  account,
  hasNoSelectionError,
  windowInnerHeight = 0,
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  const { addedScreeningTypes, additionalProperties, selectedGeo } = state;
  const basePackage = useMemo(
    () =>
      Object.values(state.basePackage).length > 0
        ? state.basePackage
        : {
            ...state.basePackage,
            name: '',
            screenings: [],
            isPrivate: true,
          },
    [state.basePackage],
  );

  const packageSummaryRef = useRef<HTMLDivElement>(null);

  const basePackageScreeningTypes = basePackage.screenings.map(
    (screening: { type: any }) => screening.type,
  );

  // query for the price of all possible add ons
  // this data populates each individual screening price in the list section
  const allAddOnPricesQuery = useAllAddOnPricesFromSkus(account.id);
  const mappedSkusQuery = utils.mapAddonSkus(allAddOnPricesQuery);

  const screeningAdded = debounce(
    useCallback((screeningType, additionalProperties) => {
      dispatch({
        type: actionTypes.SCREENING_ADDED,
        payload: { screeningType, additionalProperties },
      });
    }, []),
    200,
    { leading: false },
  );

  const screeningRemoved = useCallback(
    (screeningType, additionalProperties) => {
      dispatch({
        type: actionTypes.SCREENING_REMOVED,
        payload: { screeningType, additionalProperties },
      });
    },
    [dispatch],
  );

  // query for the price of the initial package with no-add ons
  // this data populates the base price in the summary
  const basePackagePricesQuery = usePackagePrice(
    ['base-package-prices', basePackage.name],
    account,
    basePackage,
  );

  const packageName = useMemo(
    () =>
      !state.newPackageNameInvalid && state.newPackageName !== ''
        ? state.newPackageName
        : state.basePackage.name || 'Empty Add Checks Package',
    [state.newPackageNameInvalid, state.newPackageName, state.basePackage.name],
  );

  const buildPostBodyWithAddOns = useCallback(() => {
    return utils.buildPostBodyWithAddOns({
      basePackage,
      addedScreeningTypes: state.addedScreeningTypes,
      additionalProperties: state.additionalProperties,
      packageName,
      setSlug: true,
      isPrivate: false,
    });
  }, [
    basePackage,
    packageName,
    state.addedScreeningTypes,
    state.additionalProperties,
  ]);

  // query for the base package plus all add-ons selected by the user
  // this populates all other prices shown in the summary except the initial base price
  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      basePackage.name,
      addedScreeningTypes,
      additionalProperties,
    ],
    account,
    {
      ...buildPostBodyWithAddOns(),
    },
  );

  const completedAddons = useMemo(() => {
    const status = state.addedScreeningTypes.length > 0;

    return status;
  }, [state.addedScreeningTypes.length]);

  const basicPackageScreeningTypes = useMemo(() => {
    return basePackageScreeningTypes.length > 0
      ? basePackageScreeningTypes
      : state.oldBasePackage?.screenings?.map(
          (screening: Screening) => screening.type,
        ) || [];
  }, []);

  const disabledScreenings = useMemo(() => {
    return basePackageScreeningTypes.length > 0
      ? getDisabledScreenings(basePackageScreeningTypes)
      : (state.oldBasePackage?.screenings?.map(
          (screening: Screening) => screening.type,
        ) as any) || [];
  }, []);

  useEffect(() => {
    if (completedAddons) {
      state.continueButtonDisabled &&
        dispatch({
          type: actionTypes.CONTINUE_BUTTON_DISABLED,
          payload: { continueButtonDisabled: false },
        });
    } else {
      !state.continueButtonDisabled &&
        dispatch({
          type: actionTypes.CONTINUE_BUTTON_DISABLED,
          payload: { continueButtonDisabled: true },
        });
    }
  }, [completedAddons, dispatch, state.continueButtonDisabled]);

  const handleInternationalPriceLinkClick = useCallback(() => {
    dispatch({
      type: actionTypes.SET_INTERNATIONAL_PRICE_LINK_CLICK,
      payload: { internationalPriceLinkClicked: true },
    });
  }, [dispatch]);

  return (
    <>
      <TitleSection data-testid='add-checks-title-and-notif-section'>
        Add checks to meet industry, location, or role requirements.
      </TitleSection>
      {hasNoSelectionError && (
        <InlineNotification
          hideCloseButton
          kind='error'
          title=''
          subtitle='Select at least one additional check below. '
        />
      )}
      <InnerContainer type='transparent'>
        <ScreeningsSection>
          {selectedGeo === OriginType.domestic && (
            <>
              <PopularAddOns
                onAddClick={screeningAdded}
                onRemoveClick={screeningRemoved}
                addedScreenings={addedScreeningTypes}
                disabledScreenings={disabledScreenings}
                additionalProperties={additionalProperties}
                allAddOnPricesQuery={mappedSkusQuery}
                usingAddChecks
                basePackageScreenings={basicPackageScreeningTypes}
                previouslyOrderedScreenings={getPreviouslyOrderedScreenings(
                  state.oldBasePackage?.screenings,
                )}
              />
              <Margin16 />
            </>
          )}
          <AddScreeningsList
            onAddClick={screeningAdded}
            onRemoveClick={screeningRemoved}
            addedScreenings={addedScreeningTypes}
            additionalProperties={additionalProperties}
            basePackage={basePackage}
            account={account}
            basePackageScreeningTypes={basicPackageScreeningTypes}
            previouslyOrderedScreenings={getPreviouslyOrderedScreenings(
              state.oldBasePackage?.screenings,
            )}
            selectedGeo={selectedGeo}
            allAddOnPricesQuery={mappedSkusQuery}
            screeningSettingPrice={pendingAddOnPricesQuery?.data?.items || []}
          />
        </ScreeningsSection>
        <SummaryContainer
          data-testid='package-summary-container'
          ref={packageSummaryRef}
          maxHeight={windowInnerHeight - ADDITIONAL_PACKAGE_SUMMARY_PADDING}
        >
          <SummarySection
            data-testid='package-summary'
            maxHeight={
              windowInnerHeight - 2 * ADDITIONAL_PACKAGE_SUMMARY_PADDING
            }
          >
            {selectedGeo === 'domestic' ? (
              <DomesticAddScreeningsSummary
                basePackage={basePackage}
                basePackagePrices={basePackagePricesQuery.data}
                basePackageScreeningTypes={basePackageScreeningTypes}
                pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                hasAddedScreenings={Boolean(addedScreeningTypes.length)}
                additionalProperties={additionalProperties}
              />
            ) : (
              <InternationalAddScreeningsSummary
                basePackage={basePackage}
                basePackageScreeningTypes={basePackageScreeningTypes}
                pendingAddOnPricesQuery={pendingAddOnPricesQuery}
                hasAddedScreenings={Boolean(addedScreeningTypes.length)}
                additionalProperties={additionalProperties}
                handleInternationalPriceLinkClick={
                  handleInternationalPriceLinkClick
                }
              />
            )}
          </SummarySection>
        </SummaryContainer>
      </InnerContainer>
    </>
  );
};

export default ChecksAddons;
