import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  index: number;
  deleteCondition: any;
};

export const DeleteButton: React.FC<Props> = ({ index, deleteCondition }) => {
  const handleDelete = useCallback(
    () => deleteCondition(index),
    [deleteCondition, index],
  );

  return (
    <M.GridCol
      lg={4}
      md={1}
      sm={1}
      style={{ alignSelf: 'center' }}
      data-testid='assess-rules-rule-editor-rule-condition-delete'
    >
      <M.Icon icon='TrashCan' size='16' onClick={handleDelete} />
    </M.GridCol>
  );
};

export default DeleteButton;
