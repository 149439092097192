import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';

interface DeleteValidationFailedDialogProps {
  handleClose: (target: any) => void;
}

// When we fail to validate candidate for immediate PII deletion we would create a Zendes ticket
// This component is showing this information to the user
const DeleteValidationFailedDialog: React.FC<
  DeleteValidationFailedDialogProps
> = ({ handleClose }: DeleteValidationFailedDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <M.ModalBody padding='0'>
        <M.InlineNotification
          kind='success'
          title={t(
            'data_deletion.delete_validation_failure_create_zendesk.notification',
          )}
          hideCloseButton
        />
        <p>
          {t(
            'data_deletion.delete_validation_failure_create_zendesk.text.processing_time',
          )}
        </p>
        <p>
          {t(
            'data_deletion.delete_validation_failure_create_zendesk.text.email_notification',
          )}
        </p>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button kind='primary' onClick={handleClose}>
          {t('data_deletion.delete_validation_failure_create_zendesk.affirm')}
        </M.Button>
      </M.ModalFooter>
    </>
  );
};

export default DeleteValidationFailedDialog;
