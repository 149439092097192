import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import {
  ADD_CHECKS_EVENT_NAMES,
  ADD_CHECKS_EVENT_PROPERTIES,
  useTrackEvent,
} from '../Amplitude/analytics';

const ModalStyled = styled(M.ComposedModal)`
  .mastodon-modal-body {
    padding: 32px 32px 0px 32px !important;
  }
`;

const Bold = styled.span`
  font-weight: 800;
`;

const ModalBodyContainer = styled.div`
  padding-bottom: 16px;
`;

type SubmitModalProps = {
  open: boolean;
  createReportView: boolean;
  hasReportCreationError: boolean;
  handleClose: (closeModal?: boolean) => void;
  setToRefresh: (multipleReports: boolean) => void;
};

const SubmitModal: React.FC<SubmitModalProps> = ({
  open,
  createReportView,
  hasReportCreationError,
  handleClose,
  setToRefresh,
}) => {
  const history = useHistory();
  const location = useLocation();
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const trackEvents = useCallback(
    (
      eventName: ADD_CHECKS_EVENT_NAMES,
      eventProperties?: ADD_CHECKS_EVENT_PROPERTIES,
    ) => {
      trackEvent(currentUser, eventName, eventProperties);
    },
    [trackEvent, currentUser],
  );

  const refresh = useCallback(() => {
    history.go(0);
  }, [history]);

  const handleOrderNew = useCallback(() => {
    trackEvents(ADD_CHECKS_EVENT_NAMES.ORDER_SUBMITTED_COMPLETED, {
      'Completion Type': 'Order New Check',
    });
    handleClose();
    // Need the bottom line to prevent modal from opening prematurely
    setToRefresh(false);
    // Send a param down so the page can do a refresh AND open a new modal
    history.replace(`${location.pathname}?source=addChecks`);
    // Refresh the page with the params
    refresh();
  }, [trackEvents, handleClose, setToRefresh, history, location, refresh]);

  const handleGoToCandidates = useCallback(() => {
    trackEvents(ADD_CHECKS_EVENT_NAMES.ORDER_SUBMITTED_COMPLETED, {
      'Completion Type': 'Go to Candidate Page',
    });
    handleClose();
    history.push('/candidates');
  }, [trackEvents, handleClose, history]);

  const btnOrderNewCheck = {
    name: 'Order new check',
    onClick: handleOrderNew,
    'data-testid': 'add-checks-order-new-check-button',
  };

  const btnGoToCandidates = {
    name: 'Go to Candidates page',
    onClick: handleGoToCandidates,
    'data-testid': 'add-checks-candidate-page-button',
  };

  const createReportViewText =
    'We are processing your additional checks right away!';

  const invitationViewText =
    "Your invitations are on the way! We'll email the candidate a new invitation link to gather the required information.";

  return (
    <ModalStyled
      data-testid='add-checks-submit-modal'
      open={open}
      preventCloseOnClickOutside
    >
      <M.ModalHeader data-testid='submit-modal-header' closeModal={refresh}>
        <h2>Your order was submitted</h2>
      </M.ModalHeader>
      <M.ModalBody data-testid='add-checkssubmit-modal-description'>
        <ModalBodyContainer>
          {createReportView && !hasReportCreationError
            ? createReportViewText
            : invitationViewText}
        </ModalBodyContainer>
        Once a background check is started, you can view its progress in the{' '}
        <Bold>Candidates</Bold> page. You&apos;re only charged for completed
        checks.
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={btnOrderNewCheck}
          btnRight={btnGoToCandidates}
          size='small'
        />
      </M.ModalFooter>
    </ModalStyled>
  );
};

export default SubmitModal;
