import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import { address, InternationalCandidateAddress } from 'types';

import Address from './Address';

const ItalicizedMessage = styled.p`
  font-style: italic;
`;

type AddressSectionProps = {
  title: string;
  id: string;
  addresses: address[] | InternationalCandidateAddress[];
  formatAddress: Function;
  formatDate: Function;
  formatExtraInfo?: Function;
};

const AddressSection: React.FC<AddressSectionProps> = ({
  title,
  id,
  addresses,
  formatAddress,
  formatDate,
  formatExtraInfo,
}) => {
  const firstAddress = addresses?.[0];
  const remaining: any[] = useMemo(() => addresses?.slice(1), [addresses]);

  const formatting = useMemo(
    () => ({
      formatAddress,
      formatDate,
      formatExtraInfo,
    }),
    [formatAddress, formatDate, formatExtraInfo],
  );

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(c => !c);
  }, [setCollapsed]);

  useEffect(() => setCollapsed(true), [addresses]);

  return (
    <M.Container id={id} data-testid={id}>
      <M.Container.Row>
        <M.Container.Col>
          <span className='heading-text-name heading-text h4'>{title}</span>
        </M.Container.Col>
      </M.Container.Row>
      {firstAddress && <Address address={firstAddress} {...formatting} />}
      {!collapsed &&
        remaining?.map(
          (a: address | InternationalCandidateAddress, i: number) => (
            <Address
              key={`${formatAddress(a)}${formatDate(a)}${i + 1}`}
              address={a}
              {...formatting}
            />
          ),
        )}
      {remaining.length > 0 && (
        <M.Container.Row>
          <M.Container.Col>
            <M.Link visited={false} onClick={toggleCollapsed}>
              {collapsed ? 'Show' : 'Hide'} {remaining.length} previous location
              {remaining.length > 1 && 's'}
            </M.Link>
          </M.Container.Col>
        </M.Container.Row>
      )}
      {addresses.length === 0 && (
        <M.Container.Row>
          <M.Container.Col>
            <ItalicizedMessage>No locations available</ItalicizedMessage>
          </M.Container.Col>
        </M.Container.Row>
      )}
    </M.Container>
  );
};

export default AddressSection;
