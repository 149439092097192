import React, { useContext } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useComponents } from 'providers/Candidate';
import UIContext from 'context/UI';
import PiiRemovedBanner from './PiiRemovedBanner/PiiRemovedBanner';
import CandidateOnHoldBanner from './CandidateOnHoldBanner/CandidateOnHoldBanner';

// For candidate banners, add them to this map in the order they should be shown
const banners: { [key: string]: React.FC<any> } = {
  piiRemovedBanner: PiiRemovedBanner,
  candidateOnHoldBanner: CandidateOnHoldBanner,
};

const CandidateBanners: React.FC = () => {
  const { isIframe, isStandaloneIframe } = useContext(UIContext);
  const components = useComponents();

  return (
    <M.Grid>
      <M.GridRow>
        {Object.entries(banners).map(([key, Component]) =>
          !isIframe || isStandaloneIframe || components.includes(key) ? (
            <Component key={key} />
          ) : null,
        )}
      </M.GridRow>
    </M.Grid>
  );
};

export default CandidateBanners;
