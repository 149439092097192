import React from 'react';

const PLVManualEntry = () => (
  <div className='exceptions-plv-manual-entry exception-container'>
    <strong>Candidate has a license that cannot be verified</strong>
    <p>
      Checkr does not support verifications for this candidate&#39;s license.
      Reach out to your Customer Success representative or contact
      clients@checkr.com to prioritize support for this license.
    </p>
  </div>
);

export default PLVManualEntry;
