import { colors, M } from '@dashboard-experience/mastodon';
import StyledComponent from 'styled-components';

export const Description = StyledComponent.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  width: 50%;
  color: ${colors.uiTextPrimaryLight};
`;

export const TextInput = StyledComponent(M.TextInput)``;
export const SensitiveInput = StyledComponent(M.SensitiveInput)``;

export const Checkbox = StyledComponent(M.Checkbox)`
  align-items: end;
`;

export const Container = StyledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  gap: 16px;
  padding: 10px 24px;

  #mastodon && {
    .mastodon-text-input {
      width: 50%;
    }
    .mastodon-sensitive-input-wrapper {
      width: 50%;

      .mastodon-sensitive-input {
        border-radius: 0px;
        border: 0px;
        border-bottom: 1px solid ${colors.uiGrey500};
        margin-right: 0px;
      }
    }
  }
`;
