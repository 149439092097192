import React, { useCallback, useState } from 'react';
import { Entity, Version } from 'modules/assess/models/mvr/category-code-set';
import {
  BaseDialog as Dialog,
  Action,
} from 'modules/assess/ui/common/base-dialog';
import Content from './content';
import Footer from './footer';

export type Props = {
  item: Entity;
  open: boolean;
  onClose: () => void;
};

export const VersionsDialog: React.FC<Props> = ({ item, open, onClose }) => {
  const [selectedVersion, setSelectedVersion] = useState<Version.Basic>();

  const handleClose = useCallback(() => {
    setSelectedVersion(undefined);
    onClose?.();
  }, [onClose]);

  return (
    <Dialog
      content={
        <Content
          item={item}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          onClose={onClose}
        />
      }
      footer={
        <Footer
          item={item}
          selectedVersion={selectedVersion}
          onClose={handleClose}
        />
      }
      open={open}
      onClose={handleClose}
      name={item.name}
      type='category_set'
      action={Action.versions}
    />
  );
};

export default VersionsDialog;
