import React, { useCallback, useEffect, useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';

import { FactRule, getFactByNamespace } from 'modules/assess';
import { Namespace } from 'modules/assess/api';
import { useTranslation } from 'react-i18next';
import { Field } from 'modules/assess/ui/rules/common/rule';
import { FactRuleOption } from 'modules/assess/ui/rules/common/rule/editor/conditions';
import ValueSelectorLegacy from 'modules/assess/ui/rules/common/rule/editor/conditions/value-selector-legacy';
import Kind from 'modules/assess/models/rules/common/kind';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

type Props = {
  namespace: Namespace;
};

const CountCondition: React.FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('assess');
  const translation: any = t('assess:rules.facts.count', {
    returnObjects: true,
  });
  const label = `${translation.name} ${translation.verb}`.trim();
  const { conditions, updateConditionByIndex, insertCondition } =
    useConditions();

  const Fact = getFactByNamespace(namespace);
  const index = conditions.findIndex(c => c.fact === Fact.COUNT);
  const condition = useMemo(
    () => conditions[index] || { fact: Fact.COUNT },
    [index, conditions, Fact.COUNT],
  );

  useEffect(() => {
    if (index !== -1) {
      return;
    }
    insertCondition({ fact: Fact.COUNT });
  }, [Fact.COUNT, index, insertCondition]);

  const setValue = useCallback(
    (value: FactRuleOption) =>
      updateConditionByIndex(index, { ...condition, ...value }),
    [condition, index, updateConditionByIndex],
  );

  return (
    <M.GridCol
      data-testid='assess-rules-count-rule-editor-rule-count'
      lg={6}
      md={3}
      sm={1}
    >
      <Field label={label} style={{ marginBottom: '0.75em' }} />
      <ValueSelectorLegacy
        condition={condition as Partial<FactRule>}
        kind={Kind.COUNT}
        namespace={namespace}
        setValue={setValue}
      />
    </M.GridCol>
  );
};

export default CountCondition;
