import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useCreatePackageContext,
  INTERNATIONAL_BASIC,
  INTERNATIONAL_PROFESSIONAL,
  CHECKMARK,
  ListItem,
  BoldListItem,
  RadioButton,
} from '../Context';

const SelectPackageContainer = styled.div`
  width: 928px;
  height: 453px;
  border: 1px solid ${colors.uiGrey200};
  background: ${colors.uiGrey0};
  border-radius: 4px;
  padding: 20px;
`;

const List = styled.ul`
  margin-top: 132px;
  margin-left: 20px;
  width: 75%;
`;

const BoldHeader = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ${colors.brandNavy3};
`;

const BasicPackageTypeContainer = styled.div`
  position: fixed;
  margin-left: 298px;
  margin-top: -339px;
  width: 264px;
  height: 381px;
  box-shadow: 0px 2px 15px 4px ${colors.brandSlate2};
  background: ${colors.uiGrey0};
`;

const ProfessionalPackageTypeContainer = styled.div`
  position: fixed;
  margin-left: 600px;
  margin-top: -339px;
  width: 264px;
  height: 381px;
  box-shadow: 0px 2px 15px 4px ${colors.brandSlate2};
  background: ${colors.uiGrey0};
`;

const PackageListItem = styled.div`
  font-size: 14px;
  padding-top: 15px;
  height: 47px;
  text-align: center;
  color: ${colors.brandSlate7};
`;

const CheckmarkIcon = styled.div`
  color: ${colors.brandAqua3};
  font-size: 16px;
`;

const CloseIcon = styled(M.Icon)`
  color: ${colors.brandOrangeAccent1};
`;

const PriceLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.brandNavy1};
  text-align: center;
  padding-top: 26px;
  height: 57px;
  a {
    color: ${colors.brandNavy1} !important;
  }
`;

const InfoLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${colors.brandAqua4};
  padding-top: 25px;
  text-align: center;
`;

const InternationalPackageSelector = () => {
  const {
    packageType,
    functions: { setPackageType },
  } = useCreatePackageContext();

  type handlerPropTypes = {
    target: {
      value: string;
    };
  };

  const handleInternationalBasicPackageType = useCallback(
    ({ target: { value } }: handlerPropTypes) =>
      value !== INTERNATIONAL_BASIC
        ? setPackageType(INTERNATIONAL_BASIC)
        : setPackageType(''),
    [setPackageType],
  );
  const handleInternationalProfessionalPackageType = useCallback(
    ({ target: { value } }: handlerPropTypes) =>
      value !== INTERNATIONAL_PROFESSIONAL
        ? setPackageType(INTERNATIONAL_PROFESSIONAL)
        : setPackageType(''),
    [setPackageType],
  );

  return (
    <SelectPackageContainer data-testid='international-package-selector-container'>
      <List>
        <BoldListItem>Pricing & turnaround</BoldListItem>
        <ListItem>International Criminal (or adverse media)</ListItem>
        <ListItem>Global Watchlist Records </ListItem>
        <ListItem>Education Verification </ListItem>
        <ListItem>Employment Verification </ListItem>
      </List>
      <BasicPackageTypeContainer>
        <RadioButton
          labelText={<BoldHeader>International Basic+</BoldHeader>}
          onClick={handleInternationalBasicPackageType}
          value={packageType}
          checked={packageType === INTERNATIONAL_BASIC}
        />
        <InfoLabel>A great starting point</InfoLabel>
        <PriceLabel>
          <a
            href='https://checkr.com/pricing/international'
            target='_blank'
            rel='noreferrer'
          >
            Varies by country
          </a>
        </PriceLabel>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
        <PackageListItem>
          <CloseIcon icon='Close' size='20' />
        </PackageListItem>
        <PackageListItem>
          <CloseIcon icon='Close' size='20' />
        </PackageListItem>
      </BasicPackageTypeContainer>
      <ProfessionalPackageTypeContainer>
        <RadioButton
          labelText={<BoldHeader>International Professional</BoldHeader>}
          onClick={handleInternationalProfessionalPackageType}
          value={packageType}
          checked={packageType === INTERNATIONAL_PROFESSIONAL}
        />
        <InfoLabel>More comprehensive</InfoLabel>
        <PriceLabel>
          <a
            href='https://checkr.com/pricing/international'
            target='_blank'
            rel='noreferrer'
          >
            Varies by country
          </a>
        </PriceLabel>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
        <PackageListItem>
          <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
        </PackageListItem>
      </ProfessionalPackageTypeContainer>
    </SelectPackageContainer>
  );
};

export default InternationalPackageSelector;
