import React, { useEffect, useState } from 'react';
import { useNamespace } from 'modules/assess/ui/router/context';
import { Entity } from 'modules/assess/ui/state/rules/count';
import { M } from '@dashboard-experience/mastodon';
import { Grid, Row } from 'modules/assess/ui/rules/common/rule';
import { useAssessRuleAPI } from 'modules/assess/ui/rules/common/helpers';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';
import NewConditions from 'modules/assess/ui/rules/common/rule/editor/new-conditions';
import { getCountRuleDecisions } from 'modules/assess/models';
import { useValidationRules } from 'modules/assess/ui/rules/common/rule/hooks';
import Assessment from 'modules/assess/ui/rules/common/rule/editor/assessment';
import Name from 'modules/assess/ui/rules/common/rule/editor/name';
import { toInternal } from 'modules/assess/ui/rules/count/utils';
import ScrollIntoViewWrapper from 'modules/assess/ui/common/scrollInViewWrapper';
import Conditions from './conditions';
import CountCondition from './conditions/count';
import Footer from './footer';
import Targets from './targets';

const GRID_SIZE = {
  lg: 6,
  md: 3,
  sm: 1,
};

type Props = {
  rule: Entity.Type;
};

const Editor: React.FC<Props> = ({ rule }) => {
  const namespace = useNamespace();
  const { setConditions, conditions } = useConditions();
  const internalRule = toInternal(rule);
  const [draftRule, setDraftRule] = useState(internalRule);
  const isAssessRuleApiEnabled = useAssessRuleAPI();
  const invalid = useValidationRules(draftRule);

  useEffect(() => {
    setConditions([...internalRule.conditions]);
  }, []);

  if (!namespace) {
    return null;
  }

  return (
    <ScrollIntoViewWrapper>
      <Grid
        data-testid='assess-count-rule-editor'
        style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
      >
        <Row>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-name'
          >
            <Name
              name={draftRule.name}
              setDraftRule={setDraftRule}
              invalid={invalid}
            />
          </M.GridCol>
          <M.GridCol
            {...GRID_SIZE}
            data-testid='assess-rules-common-editor-rule-assessment'
          >
            <Assessment
              decision={draftRule.decision}
              setDraftRule={setDraftRule}
              decisions={getCountRuleDecisions()}
            />
          </M.GridCol>
        </Row>
        <Row>
          <CountCondition namespace={namespace} />
        </Row>
        <Row>
          <Targets namespace={namespace} />
        </Row>
        {isAssessRuleApiEnabled ? (
          <NewConditions />
        ) : (
          <Conditions namespace={namespace} />
        )}
        <Footer
          disabled={invalid}
          rule={rule}
          draftRule={{ ...draftRule, conditions } as Entity.Type}
          namespace={namespace}
        />
      </Grid>
    </ScrollIntoViewWrapper>
  );
};

export default Editor;
