import React, { useCallback } from 'react';
import { getDashboardUrl } from 'utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { useLocation } from 'react-router-dom';
import { accountHasPermission } from '@dashboard-experience/utils';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from '../../utils';
import { SHOW_LANDING_PAGE } from '../../Flags';

const Logo = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');

  const showLandingPageFlag = useFlag(SHOW_LANDING_PAGE)?.variantKey === 'on';
  const showDashboardLandingPage =
    showLandingPageFlag &&
    accountHasPermission(user, 'is_self_service_account');
  const destination = showDashboardLandingPage ? 'Home' : 'Candidate';

  const handleTracking = useCallback(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.LOGO_CLICKED, {
        'Logo Destination': destination,
      });
    }
  }, [destination, isSignup, trackEvent]);

  return (
    <a
      data-testid='logo-link'
      href={getDashboardUrl('/')}
      onClick={handleTracking}
      className='logo'
      target='_self'
    >
      <img
        data-testid='logo-img'
        className='logo-img'
        src='https://assets.checkr.com/logo-white-2022.svg'
        alt='Checkr Logo'
      />
    </a>
  );
};

export default Logo;
