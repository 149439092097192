import React from 'react';
import { GenericObject } from 'types';
import { FieldComponentProps } from '../types';

/**
 * @name getGeosText
 * @function
 * @memberOf Geos
 * @description Construct the geos text to be displayed
 * @returns {string}
 * @param {object} geo - The geo object
 * @param {boolean} segmentationEnabled - The value of account.segmentation_enabled
 */
const getGeoText = (geo: GenericObject, segmentationEnabled: boolean) =>
  segmentationEnabled && (!geo.country || geo.country === 'US')
    ? [geo.state, geo.city].filter(x => x).join(' - ')
    : geo.name;

const Geos: React.FC<FieldComponentProps> = ({ candidate, currentUser }) => {
  const {
    account: { segmentation_enabled },
  }: { [key: string]: any } = currentUser;
  const { geos } = candidate;

  return (
    <>
      {geos.map((geo: GenericObject, idx: number) => {
        return (
          <span key={geo.id}>
            {getGeoText(geo, segmentation_enabled)}
            {idx < geos.length - 1 && <>, </>}
          </span>
        );
      })}
    </>
  );
};

export default Geos;
