import { Category } from 'modules/assess/models/mvr/category';
import { ItemType } from 'modules/assess/models/mvr/codes';
import T from 'components/Table';
import { useReadOnly } from 'modules/assess/ui/hooks';
import React from 'react';
import _ from 'lodash';
import TableHeader from '../table-header';
import NewCategory from './new-category';
import { useCCSV } from '../../context';
import CategoryRow from './category-row';

interface CategoryRowData extends Category {
  categoryRow?: React.ReactNode;
}

type Props = {
  data?: Array<CategoryRowData>;
  type: ItemType;
};

type Column = keyof Category | 'categoryRow';

const columns: Array<Column> = ['categoryRow'];

export const Table: React.FC<Props> = ({ data, type }) => {
  const headers = [
    {
      key: 'categoryRow',
      header: <TableHeader name='name' section='categories' type={type} />,
    },
  ];
  const ccsv = useCCSV();
  const readonly = useReadOnly();
  const canAddCategory = ccsv?.draft && !readonly;

  data?.forEach(row => {
    row.categoryRow = <CategoryRow row={row} />;
  });
  data = _.sortBy(data, 'id');

  return (
    <>
      <T
        data-testid='assess-mvr-category-set-version-mappings-categories-table'
        columns={columns}
        headers={headers}
        rows={data}
        style={{ maxWidth: '40em' }}
      />
      {canAddCategory && <NewCategory type={type} />}
    </>
  );
};

export default Table;
