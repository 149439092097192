import styled from 'styled-components';
import AlignItems from './AlignItems';

const FlexRow = styled.div<{ gap: string; alignItems: AlignItems }>`
  align-items: ${props => props.alignItems};
  display: flex;
  gap: ${props => props.gap};
`;

export default FlexRow;
