import { Namespace } from 'modules/assess/api';
import Kind from 'modules/assess/models/rules/common/kind';
import React from 'react';
import Content from './content';

export type Props = {
  handleAddClick: Function;
  disabled?: boolean;
  kind: Kind;
  namespace?: Namespace;
};

export const Container: React.FC<Props> = ({
  disabled,
  namespace,
  kind,
  handleAddClick,
}) => {
  if (disabled) {
    return null;
  }

  return (
    <Content
      handleAddClick={handleAddClick}
      namespace={namespace}
      kind={kind}
    />
  );
};

export default Container;
