import { assign, reduce } from 'state/utilities/reducers';
import * as Actions from './actions';
import * as State from './state';
import * as Rules from '../../../rules';

export const meta = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Insert.TYPE: {
      const {
        payload: { rules: _rules, ...data },
      } = action as Actions.Insert.Action;

      return assign.apply(state, { ...data, dirty: false });
    }
    case Actions.Update.TYPE: {
      const {
        payload: { rules: _rules, ...data },
      } = action as Actions.Update.Action;

      const next = assign.apply(state, data);
      if (next !== state && data.dirty === undefined) {
        next.dirty = true;
      }

      return next;
    }
    default: {
      break;
    }
  }
  return state;
};

const updateRules = (state: State.Type, action: Actions.Any): State.Type => {
  const rules = Rules.reducers.default(state.rules, action);

  if (rules === state.rules) {
    return state;
  }

  let dirty = true;

  switch (action.type) {
    case Rules.Actions.Insert.TYPE: {
      dirty = false;
      break;
    }
    default: {
      break;
    }
  }

  return assign.apply(state, { dirty, rules });
};

export const rules = (
  // eslint-disable-next-line default-param-last
  state: State.Type = State.initialize(),
  action: Actions.Any,
): State.Type => {
  switch (action.type) {
    case Actions.Insert.TYPE: {
      const {
        payload: { rules: data },
      } = action as Actions.Insert.Action;

      if (!data) {
        return state;
      }

      const update = Rules.Actions.Insert.create(data);
      return updateRules(state, update);
    }
    case Actions.Update.TYPE: {
      const {
        payload: { rules: data },
      } = action as Actions.Update.Action;

      if (!data) {
        return state;
      }

      const update = Rules.Actions.Update.create(data);
      return updateRules(state, update);
    }
    default: {
      break;
    }
  }
  return updateRules(state, action);
};

export const reducer = reduce(null, meta, rules);

export default reducer;
