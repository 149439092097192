/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components. 
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */

import { API, AmsAPI } from 'utils/APIClient';
import {
  TYPE_USER,
  TYPE_PKG,
  TYPE_MVR_RULE,
  TYPE_APP_INSTANCE_CUSTOMER,
} from 'Constants';
import createActionCreator from './ActionHelper';

export const UPDATE_SEGMENTS = 'UPDATE_SEGMENTS';

export const SHOW_ASSIGN_SEGMENTS_CONFIRMATION =
  'SHOW_ASSIGN_SEGMENTS_CONFIRMATION';
export const CANCEL_ASSIGN_SEGMENTS_CONFIRMATION =
  'CANCEL_ASSIGN_SEGMENTS_CONFIRMATION';
export const SHOW_ASSIGN_SEGMENTS_SUCCESS = 'SHOW_ASSIGN_SEGMENTS_SUCCESS';
export const HIDE_ASSIGN_SEGMENTS_SUCCESS = 'HIDE_ASSIGN_SEGMENTS_SUCCESS';

export const POST_SEGMENTS_REQUEST = 'POST_SEGMENTS_REQUEST';
export const POST_SEGMENTS_SUCCESS = 'POST_SEGMENTS_SUCCESS';
export const POST_SEGMENTS_FAILURE = 'POST_SEGMENTS_FAILURE';

export const FETCH_PARENT_OBJECT_REQUEST = 'FETCH_PARENT_OBJECT_REQUEST';
export const FETCH_PARENT_OBJECT_SUCCESS = 'FETCH_PARENT_OBJECT_SUCCESS';
export const FETCH_PARENT_OBJECT_FAILURE = 'FETCH_PARENT_OBJECT_FAILURE';

export const GET_ASSIGNED_NODES_REQUEST = 'GET_ASSIGNED_NODES_REQUEST';
export const GET_ASSIGNED_NODES_SUCCESS = 'GET_ASSIGNED_NODES_SUCCESS';
export const GET_ASSIGNED_NODES_FAILURE = 'GET_ASSIGNED_NODES_FAILURE';

export const SEARCH_HIERARCHY_REQUEST = 'SEARCH_HIERARCHY_REQUEST';
export const SEARCH_HIERARCHY_SUCCESS = 'SEARCH_HIERARCHY_SUCCESS';
export const SEARCH_HIERARCHY_FAILURE = 'SEARCH_HIERARCHY_FAILURE';

/**
 * @name showAssignSegmentsConfirmation
 * @function
 * @memberOf actions/index
 * @description Show the confirmation view
 * @returns {dispatch}
 */
export const showAssignSegmentsConfirmation = () => dispatch =>
  dispatch({ type: SHOW_ASSIGN_SEGMENTS_CONFIRMATION });

/**
 * @name cancelAssignSegmentsConfirmation
 * @function
 * @memberOf actions/index
 * @description Cancel the confirmation view
 * @returns {dispatch}
 */
export const cancelAssignSegmentsConfirmation = () => dispatch =>
  dispatch({ type: CANCEL_ASSIGN_SEGMENTS_CONFIRMATION });

/**
 * @name showAssignSegmentsSuccess
 * @function
 * @memberOf actions/index
 * @description Show the success view
 * @returns {dispatch}
 */
export const showAssignSegmentsSuccess = () => dispatch =>
  dispatch({ type: SHOW_ASSIGN_SEGMENTS_SUCCESS });

export const hideAssignSegmentsSuccess = () => dispatch =>
  dispatch({ type: HIDE_ASSIGN_SEGMENTS_SUCCESS });

// TODO: possible update to handle different types of segments
export const updateSegments = filteredSegments => dispatch =>
  dispatch({ type: UPDATE_SEGMENTS, filteredSegments });

/**
 * @name postSegments
 * @function
 * @memberOf actions/index
 * @description Post segments to API
 * @param {string} parentContext - What type of parent, e.g package, pam
 * @param {string} parentId - The ID of the parent resource, e.g. package, pam
 * @param {array} segments - The array of segement IDs
 */

export const postSegmentsRequest = () => ({
  type: POST_SEGMENTS_REQUEST,
});

export const postSegmentsSuccess = () => ({
  type: POST_SEGMENTS_SUCCESS,
});

export const postSegmentsFailure = () => ({
  type: POST_SEGMENTS_FAILURE,
  postError: 'Something went wrong. Please try again later.',
});

const postNodesPath = (parentContext, parentId, accountId) => {
  let path = `${parentContext}/${parentId}/segments`;

  if (parentContext === TYPE_APP_INSTANCE_CUSTOMER) {
    path = `/application_instances/${parentId}/nodes`;
  } else if (parentContext === TYPE_MVR_RULE) {
    path = `accounts/${accountId}/${parentContext}/${parentId}/segments`;
  }

  return path;
};

export const postSegments =
  (accountId, parentContext, parentId, segments) => async dispatch => {
    dispatch(postSegmentsRequest());
    if (parentContext === TYPE_USER) {
      try {
        const payload = { nodes: segments };
        await AmsAPI.patch(`/api/public/v1/users/${parentId}`, payload);
        dispatch(postSegmentsSuccess());
      } catch (error) {
        dispatch(postSegmentsFailure());
      }
    } else {
      try {
        const payload =
          parentContext === TYPE_APP_INSTANCE_CUSTOMER
            ? { nodes: segments, account_id: accountId }
            : { segments };
        const path = postNodesPath(parentContext, parentId, accountId);

        await API.post(path, payload);
        dispatch(postSegmentsSuccess());
      } catch (error) {
        dispatch(postSegmentsFailure());
      }
    }
  };

export const fetchParentObjectRequest = () => ({
  type: FETCH_PARENT_OBJECT_REQUEST,
});

export const fetchParentObjectSuccess = parentObject => ({
  type: FETCH_PARENT_OBJECT_SUCCESS,
  parentObject,
});

export const fetchParentObjectFailure = () => ({
  type: FETCH_PARENT_OBJECT_FAILURE,
  error: 'Something went wrong, please try again later.',
});

/**
 * @name fetchParentObject
 * @function
 * @memberOf actions/index
 * @description Fetches the pam, package, or brand object
 * @param {string} parentContext - What type of parent, e.g package, pam, brand
 * @param {string} parentId - The ID of the parent resource, e.g. package, pam, brand
 */

export const fetchParentObject =
  (accountId, parentContext, parentId) => async dispatch => {
    dispatch(fetchParentObjectRequest());

    if (parentContext === TYPE_USER) {
      dispatch(fetchParentObjectSuccess({ id: parentId }));
      return;
    }
    const context =
      parentContext === TYPE_APP_INSTANCE_CUSTOMER
        ? 'application_instances'
        : parentContext;
    const pkgPath = parentContext === TYPE_PKG ? '/v1' : '';
    const mvrPath =
      parentContext === TYPE_MVR_RULE ? `/accounts/${accountId}` : '';

    await API.get(`${pkgPath}${mvrPath}/${context}/${parentId}`)
      .then(response => {
        return dispatch(fetchParentObjectSuccess(response));
      })
      .catch(() => {
        return dispatch(fetchParentObjectFailure());
      });
  };

export const getAssignedNodesRequest = createActionCreator(
  GET_ASSIGNED_NODES_REQUEST,
);
export const getAssignedNodesSuccess = createActionCreator(
  GET_ASSIGNED_NODES_SUCCESS,
);
export const getAssignedNodesFailure = createActionCreator(
  GET_ASSIGNED_NODES_FAILURE,
);

const fetchNodesPath = (parentContext, parentId, accountId) => {
  let query = `?include=${parentContext}`;
  let path = `${parentContext}/${parentId}/nodes`;

  if (parentContext === TYPE_USER) {
    query = '?include_associations=true';
  } else if (parentContext === TYPE_APP_INSTANCE_CUSTOMER) {
    path = `application_instances/${parentId}/customer/nodes`;
    query = '?include=app_instance_customer';
  } else if (parentContext === TYPE_MVR_RULE) {
    path = `accounts/${accountId}/${parentContext}/${parentId}/nodes`;
  }

  return path + query;
};

export const getAssignedNodes =
  (parentContext, parentId, accountId) => dispatch => {
    dispatch(getAssignedNodesRequest());

    const path = fetchNodesPath(parentContext, parentId, accountId);

    API.get(path)
      .then(data => {
        const nodeIds = data.nodes.map(node => node.custom_id);
        dispatch(getAssignedNodesSuccess({ nodeIds, nodes: data.nodes }));
      })
      .catch(() => {
        dispatch(getAssignedNodesFailure());
      });
  };

export const searchHierarchyRequest = createActionCreator(
  SEARCH_HIERARCHY_REQUEST,
);
export const searchHierarchySuccess = createActionCreator(
  SEARCH_HIERARCHY_SUCCESS,
);
export const searchHierarchyFailure = createActionCreator(
  SEARCH_HIERARCHY_FAILURE,
);

export const searchHierarchy =
  (accountId, parentContext, query, newSearch, page = 1) =>
  async dispatch => {
    dispatch(searchHierarchyRequest({ isSearching: newSearch }));
    API.get(
      `accounts/${accountId}/nodes?page=${page}&per_page=5&node_name=${query}&include=${parentContext}`,
    )
      .then(response => {
        const pageCount = Math.ceil(response.count / 5);
        dispatch(
          searchHierarchySuccess({
            nodes: response.data,
            pageCount,
            query,
            pageIndex: page - 1,
          }),
        );
      })
      .catch(() => {
        dispatch(searchHierarchyFailure());
      });
  };
