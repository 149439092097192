import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  InnerGroupType,
  TopGroupType,
} from 'modules/assess/ui/rules/common/rule/editor/types';
import Separator from 'modules/assess/ui/rules/common/rule/editor/separator';
import InnerGroupViewer from './inner-group-viewer';

const TopGroupContainer = styled(M.Container)`
  background-color: ${colors.uiNavy50} !important;
  margin: 1em 1.5em !important;
`;

const SeparatorStyle = styled.div`
  > * {
    padding: 0 !important;
  }
`;

type Props = {
  condition: TopGroupType;
};

const TopGroupViewer: React.FC<Props> = ({ condition }) => {
  const { t } = useTranslation('assess');
  const countLabel = t('common.count_label');

  const moreThan = t('rules.facts.count.operators.gt');
  const moreThanOrEqualTo = t('rules.facts.count.operators.ge');
  const operator = condition.operator === 'gt' ? moreThan : moreThanOrEqualTo;

  return (
    <TopGroupContainer data-testid='assess-complex-rule-top-group-count'>
      <strong>{`${countLabel} ${operator} ${condition.threshold}`}</strong>
      {condition.conditions?.map(
        (innerGroup: InnerGroupType, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`topgroup-viewer-${index}`}>
            <SeparatorStyle data-testid='inner-group-separator'>
              {index > 0 && <Separator labelTranKey='assess:common.or' />}
            </SeparatorStyle>

            <InnerGroupViewer conditions={innerGroup} />
          </div>
        ),
      )}
    </TopGroupContainer>
  );
};

export default TopGroupViewer;
