import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { GenericObject } from '@dashboard-experience/utils';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

const userActionNames: GenericObject = {
  clicked_into_charges: 'User clicked into charges',
  'clicked_pre-adverse_action_button': 'User clicked pre-adverse action button',
  report_tags_added: 'Report tags added',
  report_tags_removed: 'Report tags removed',
  report_complete_now: 'Complete Now requested',
  report_archived: 'Report moved to archive',
  report_unarchived: 'Report moved from archive',
};

/**
 * @name getDescription
 * @function
 * @memberOf UserAction
 * @description Forms the event description as needed
 * @returns {string} - The description
 * @param {object} timelineEvent - The event object
 */
const getDescription = (timelineEvent: TimelineEvent) => {
  let description = '';
  if (
    ['report_tags_added', 'report_tags_removed'].indexOf(timelineEvent.event) >
    -1
  ) {
    const descriptionArray = JSON.parse(timelineEvent.extra_info);
    const mode =
      timelineEvent.event === 'report_tags_added' ? 'Added' : 'Removed';
    description = `${mode}: ${descriptionArray.join(', ')}`;
  }

  return description;
};

type ArchivedInfo = {
  system_auto_archival?: boolean;
  customer_email?: string;
  candidate_email?: string;
  candidate_resource_id?: string;
};

const getArchivedInfo = (timelineEvent: TimelineEvent): ArchivedInfo => {
  let descriptionArray: ArchivedInfo = {};

  if (
    timelineEvent.extra_info &&
    ['report_archived', 'report_unarchived'].indexOf(timelineEvent.event) > -1
  ) {
    descriptionArray = JSON.parse(timelineEvent.extra_info);
  }

  return descriptionArray;
};

const UserAction: React.FC<{ timelineEvent: TimelineEvent }> = ({
  timelineEvent,
}) => {
  const title = userActionNames[timelineEvent.event];
  const description = getDescription(timelineEvent);
  const archivedInfo: ArchivedInfo = getArchivedInfo(timelineEvent);

  return (
    <M.TableRow>
      <TimelineCell>
        {archivedInfo.system_auto_archival ? (
          <M.Tooltip
            align='top-left'
            label='Occurs when there is a status change on a report.'
          >
            <EventName>Automatically unarchived</EventName>
          </M.Tooltip>
        ) : (
          <EventName>{title}</EventName>
        )}
        {description && <div>{description}</div>}
      </TimelineCell>
      <TimelineCell className='user-action-id'>
        {timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default UserAction;
