import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import SectionTitle from '../../section-title';

export type Props = {
  onClick: () => void;
  isEditing: boolean;
  editable: boolean;
};

const EditLink = styled.span`
  padding-left: 1em;
  color: ${colors.uiNavy600};
  cursor: pointer;
`;

export const Title: React.FC<Props> = ({ onClick, isEditing, editable }) => {
  const { t } = useTranslation();
  const label = t('nouns.description');
  const edit = t('verbs.edit');

  return (
    <div data-testid='assess-entity-description-title'>
      <SectionTitle>{label}</SectionTitle>
      {editable && !isEditing && (
        <EditLink
          data-testid='assess-entity-description-edit-link'
          onClick={onClick}
        >
          {edit}
        </EditLink>
      )}
    </div>
  );
};

export default Title;
