import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  BaseRuleChange,
  CustomRuleChange,
} from 'modules/assess/models/rulesets/version-diff';
import CommonChanges from './common-changes';
import PositionChange from './PositionChange';
import Row from './Row';
import { Type } from '../../type';

type Props = {
  change: BaseRuleChange.Type;
  displayItemVisibility?: boolean;
  type: Type;
};

export const Container: React.FC<Props> = ({ ...props }) => {
  const children = [];
  if (props.type === Type.CUSTOM) {
    children.push(
      <PositionChange
        key='position'
        position={(props.change as CustomRuleChange.Type).position}
      />,
    );
  }

  children.push(<CommonChanges key='commonChanges' {...props} />);

  return (
    <M.Container
      data-testid='assess-rules-custom-count-rules-common-content'
      type='info'
    >
      <Row>{children}</Row>
    </M.Container>
  );
};

export default Container;
