/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import moment from 'moment';
import { EmploymentRecord } from 'types';
import _ from 'lodash';

type Props = {
  record: EmploymentRecord;
  pending: boolean;
  isEditable: boolean;
  processing: boolean;
  saveSettings: Function;
};

const EditLink = styled.span`
  color: ${colors.uiNavy600};
  cursor: pointer;
  margin-left: 4px;
`;

const Flex = styled.div`
  display: flex;
`;

type RowProps = {
  label: string;
  input: any;
  comments?: string | null;
  verified?: boolean | null;
  ignored?: string | null;
  pending: boolean;
  isEditable: boolean;
  stateKey: string;
  onChange: Function;
};
type StatusCircleProps = {
  verified?: boolean | null;
  ignored?: string | null;
};

// Mapping from monolith enum contract types to display texts.
const CONTRACT_TYPES: { [key: string]: string } = {
  full_time: 'Full-time',
  part_time: 'Part-time',
  contract: 'Contract',
  internship: 'Internship/Co-op',
  self_employed: 'Self-Employed',
  seasonal: 'Seasonal',
  temporary: 'Temporary',
  other: 'Other',
};

const PRESENT: string = 'Present';
const NOT_AVAILABLE: string = 'N/A';

const formatContractType = (input?: string | null) =>
  input ? CONTRACT_TYPES[input] : NOT_AVAILABLE;

const formatDate = (date?: string | null, fallback?: string | null) =>
  date ? moment(date).format('MMMM YYYY') : fallback || NOT_AVAILABLE;

const StatusCircle: React.FC<StatusCircleProps> = ({ verified, ignored }) => {
  let status = 'eligible';
  if (!verified) status = 'escalated';
  if (ignored) status = 'review';
  return <M.StatusIcon icon={status} />;
};

const Tooltip = styled(M.TooltipDefinition)`
  svg {
    fill: ${colors.uiTextPrimaryLight} !important;
    align-self: center;
  }
  margin: 0px !important;
  padding: 0px !important;
  button {
    border-bottom: none !important;
  }
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  .mastodon-status-icon {
    margin-right: 8px;
  }
`;

const Cell = styled(M.TableCell)`
  ${({ ignored }) => (ignored ? `color: ${colors.uiGrey600} !important` : '')};
  vertical-align: middle !important;
`;

const EditRow: React.FC<RowProps> = ({
  label,
  input,
  comments,
  verified,
  ignored,
  pending,
  stateKey,
  onChange,
}) => {
  const onCheck = useCallback(
    (event, { checked }) => onChange(stateKey, checked),
    [onChange, stateKey],
  );
  return (
    <M.TableRow>
      <Cell ignored={ignored}>{label}</Cell>
      <Cell ignored={ignored}>{input}</Cell>
      <Cell ignored={ignored}>
        {pending ? (
          'Pending'
        ) : (
          <Result>
            <StatusCircle verified={verified} ignored={ignored} />
            {comments || NOT_AVAILABLE}
          </Result>
        )}
      </Cell>
      <Cell>
        {ignored === 'package' && (
          <Tooltip
            highlighted={false}
            align='top-right'
            definition='Automatically ignored by package.'
          >
            <M.Checkbox checked id={stateKey} disabled />
          </Tooltip>
        )}
        {ignored && ignored !== 'package' && (
          <M.Checkbox checked id={stateKey} disabled />
        )}
        {!ignored && <M.Checkbox id={stateKey} onChange={onCheck} />}
      </Cell>
    </M.TableRow>
  );
};

const VerificationRow: React.FC<RowProps> = ({
  label,
  input,
  comments,
  verified,
  ignored,
  pending,
  isEditable,
}) => {
  const kebabCaseLabel = _.kebabCase(label);
  return (
    <M.TableRow>
      <Cell>{label}</Cell>
      <Cell data-testid={`emp-cell-${kebabCaseLabel}-candidate-input`}>
        {input}
      </Cell>
      <Cell data-testid={`emp-cell-${kebabCaseLabel}-employer-comments`}>
        {pending ? (
          'Pending'
        ) : (
          <Result>
            <StatusCircle verified={verified} ignored={ignored} />
            {comments || NOT_AVAILABLE}
          </Result>
        )}
      </Cell>
      {isEditable && <M.TableCell />}
    </M.TableRow>
  );
};

const VerificationTable: React.FC<Props> = ({
  record,
  pending,
  isEditable,
  processing,
  saveSettings,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const startEditing = useCallback(() => setIsEditing(true), [setIsEditing]);
  const Row = isEditing ? EditRow : VerificationRow;
  const [editState, setEditState] = useState({});
  const updateEditState = useCallback(
    (key, value) =>
      setEditState(state => {
        const newState: any = { ...state, [key]: value };
        if (!value) delete newState[key];
        return newState;
      }),
    [setEditState],
  );
  const save = useCallback(() => {
    setIsEditing(false);
    saveSettings(editState);
  }, [setIsEditing, editState, saveSettings]);
  const toDateVerified = record.result.end_date.verified;
  const toDateComments = record.result.end_date.comments;
  const toDateCommentsFallback = toDateVerified ? PRESENT : NOT_AVAILABLE;

  return (
    <M.Table data-floating-menu-container>
      <M.TableHead>
        <M.TableRow>
          <M.TableHeader />
          <M.TableHeader>Candidate input</M.TableHeader>
          <M.TableHeader>Employer comments</M.TableHeader>
          {isEditable && (
            <M.TableHeader>
              {isEditing ? (
                <Flex>
                  Ignore?{' '}
                  <EditLink
                    className='save-employment-matching-rules'
                    onClick={save}
                  >
                    Save
                  </EditLink>
                </Flex>
              ) : (
                <Flex>
                  {processing ? (
                    <M.LoadingInline description='Saving...' />
                  ) : (
                    <>
                      <Tooltip
                        highlighted={false}
                        align='bottom-left'
                        definition={
                          <span>
                            Select which settings can be ignored.{' '}
                            <strong>
                              Note: If you ignore an attribute, you cannot
                              unignore it.
                            </strong>
                          </span>
                        }
                      >
                        <M.Icon icon='InformationFilled' />
                      </Tooltip>{' '}
                      <EditLink
                        className='edit-employment-matching-rules'
                        onClick={startEditing}
                      >
                        Edit Matching rules
                      </EditLink>
                    </>
                  )}
                </Flex>
              )}
            </M.TableHeader>
          )}
        </M.TableRow>
      </M.TableHead>
      <M.TableBody>
        <Row
          label='Position'
          input={record.employer.position || NOT_AVAILABLE}
          comments={record.result.position.comments}
          verified={record.result.position.verified}
          ignored={record.result.position.ignored}
          pending={pending}
          isEditable={isEditable}
          stateKey='position_ignored'
          onChange={updateEditState}
        />
        <Row
          label='From date'
          input={formatDate(record.employer.start_date, 'Unknown')}
          comments={record.result.start_date.comments}
          verified={record.result.start_date.verified}
          ignored={record.result.start_date.ignored}
          pending={pending}
          isEditable={isEditable}
          stateKey='from_date_ignored'
          onChange={updateEditState}
        />
        <Row
          label='To date'
          input={formatDate(record.employer.end_date, PRESENT)}
          comments={toDateComments || toDateCommentsFallback}
          verified={toDateVerified}
          ignored={record.result.end_date.ignored}
          pending={pending}
          isEditable={isEditable}
          stateKey='to_date_ignored'
          onChange={updateEditState}
        />
        {(isEditing || !record.result.salary.ignored) && (
          <Row
            label='Salary'
            input={record.employer.salary}
            comments={record.result.salary.comments}
            verified={record.result.salary.verified}
            ignored={record.result.salary.ignored}
            pending={pending}
            isEditable={isEditable}
            stateKey='salary_ignored'
            onChange={updateEditState}
          />
        )}
        {(isEditing || !record.result.contract_type.ignored) && (
          <Row
            label='Contract type'
            input={formatContractType(record.employer.contract_type)}
            comments={record.result.contract_type.comments}
            verified={record.result.contract_type.verified}
            ignored={record.result.contract_type.ignored}
            pending={pending}
            isEditable={isEditable}
            stateKey='contract_type_ignored'
            onChange={updateEditState}
          />
        )}
      </M.TableBody>
    </M.Table>
  );
};

export default VerificationTable;
