import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Condition } from 'modules/assess/models/rules/condition';
import { Fact } from 'modules/assess/models/criminal/rules';

const Container = styled.div`
  width: 80%;
  > * {
    margin: 0.6em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

type Params = {
  conditions: Array<Condition>;
  hideCountFact?: boolean;
};

export const ConditionsList: React.FC<Params> = ({
  conditions,
  hideCountFact,
}) => {
  const { t } = useTranslation('');

  const lines = conditions.map(c => {
    if (c.fact === Fact.COUNT && !hideCountFact) {
      return null;
    }

    const translation: any = t(`assess:rules.facts.${c.fact}`, {
      returnObjects: true,
    });
    const { name } = translation;
    const operator = t(`assess:rules.facts.count.operators.${c.operator}`);
    const values = Array.isArray(c.value) ? c.value : [c.value];
    const value = values
      .map((v: string) => translation.values?.[v] ?? v)
      .join(', ');

    return (
      <div key={c.fact}>
        <span>{`${name} ${operator} ${value}`}</span>
      </div>
    );
  });

  return (
    <Container data-testid='assess-rules-common-rule-conditions-list'>
      {lines}
    </Container>
  );
};

export default ConditionsList;
