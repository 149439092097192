import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Button from 'components/Button';

type Props = {
  disabled?: boolean;
  onSave?: (event: SyntheticEvent) => void;
};

const Container = styled(M.Container.Row)`
  display: flex;
  justify-content: right;
`;

const Actions: React.FC<Props> = ({ disabled, onSave }) => {
  return (
    <Container
      id='assess-v2-ui-home-settings-actions'
      data-testid='assess-v2-settings-actions'
    >
      <Button
        disabled={disabled}
        kind='primary'
        trans='verbs.save'
        onClick={onSave}
      />
    </Container>
  );
};

export default Actions;
