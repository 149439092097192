import * as Entity from 'modules/assess/models/rulesets/version';
import client from '../client';
import { Namespace } from '../constants';
import { fromAPI } from './utilities/versions';

const BASE_PATH = 'rulesets';

export type SearchResults = {
  count: number;
  data: Array<Entity.Type>;
};

const defaults = { page: 1, per_page: 100 };

export const fetch = (
  namespace: Namespace,
  id: string,
  params = defaults,
): Promise<SearchResults> => {
  return client
    .get(`/${namespace}/${BASE_PATH}/${id}/versions`, { params })
    .then(response => {
      const data = response.data.map(fromAPI);
      return {
        ...response,
        data,
      };
    });
};
