import React from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';

const base_path = `${namespace}:report.actions.tooltip`;

export const Toggletip = styled(M.Toggletip)`
  #mastodon && {
    margin-left: 0.5rem;
    .mastodon-toggletip-content {
      max-width: 32rem;
      text-align: left;
      p {
        margin-bottom: 0.75rem;
      }

      a {
        color: ${colors.brandAqua4};
        text-decoration: underline;
        font-weight: bold;
        :visited {
          color: ${colors.brandAqua4};
        }
      }
      .cds--toggletip-content {
        max-width: 32rem;
      }
    }
  }
`;

const Tooltip = () => {
  const { t } = useTranslation();

  const engageTitle = t(`${base_path}.engage.title`);
  const engageContent = t(`${base_path}.engage.content`);
  const preAATitle = t(`${base_path}.pre_adverse.title`);
  const preAAContent = t(`${base_path}.pre_adverse.content`);
  const candidateStoryTitle = t(`${base_path}.candidate_story.title`);
  const candidateStoryContent = t(`${base_path}.candidate_story.content`);
  const link = t(`${base_path}.link`);

  const definition = (
    <div>
      <p>
        <strong>{engageTitle}</strong> {engageContent}
      </p>
      <p>
        <strong>{preAATitle}</strong> {preAAContent}
      </p>
      <p>
        <strong>{candidateStoryTitle}</strong> {candidateStoryContent}
      </p>
      <M.Link
        target='_blank'
        rel='noreferrer'
        href='https://help.checkr.com/hc/en-us/articles/216553188'
      >
        {link}
      </M.Link>
    </div>
  );

  return (
    <Toggletip align='bottom-left' tabIndex={0}>
      <M.ToggletipButton>
        <M.Icon icon='InformationFilled' />
      </M.ToggletipButton>
      <M.ToggletipContent>{definition}</M.ToggletipContent>
    </Toggletip>
  );
};

export default Tooltip;
