import React from 'react';
import ReactFilestack from 'filestack-react';
import { useFilestackPolicy } from 'api/filePolicy';
import { GenericObject } from '@dashboard-experience/utils';
import { M } from '@dashboard-experience/mastodon';

type SecureFilestackProps = {
  actionOptions: GenericObject;
  apikey: string | undefined;
  customRender: (arg0: any) => JSX.Element;
  onSuccess: (arg0: any) => void;
};

type FilestackSecurity = {
  security: { policy: string; signature: string };
};

const SecureFilestack: React.FC<SecureFilestackProps> = ({
  actionOptions,
  apikey,
  customRender,
  onSuccess,
}: GenericObject) => {
  const { data, isSuccess } = useFilestackPolicy(apikey);

  if (!isSuccess || !data) {
    return <M.LoadingInline />;
  }

  const filestackSecurity: FilestackSecurity = { security: { ...data } };

  return (
    <ReactFilestack
      actionOptions={actionOptions}
      apikey={apikey}
      customRender={customRender}
      onSuccess={onSuccess}
      clientOptions={filestackSecurity}
    />
  );
};

export default SecureFilestack;
