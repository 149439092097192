import { ReportStatuses } from '@dashboard-experience/utils';
import moment from 'moment';
import _ from 'lodash';
import { TIMELINE_DATE_FORMAT } from 'Constants';
import { GenericObject } from 'types';

const VERIFIED = 'Verified';
const UNVERIFIED = 'Unverified';
const VERIFIED_BY_NONE = 'none';

// shared
/**
 * @name isComplete
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines if verification is complete
 * @returns {boolean}
 * @param {string} status - The status
 */
export const isComplete = (status: string) =>
  status === ReportStatuses.CONSIDER ||
  status === ReportStatuses.CLEAR ||
  status === ReportStatuses.CANCELED ||
  status === ReportStatuses.SUSPENDED;

/**
 * @name isPending
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines if verification is pending
 * @returns {boolean}
 * @param {string} status - The status
 */
export const isPending = (status: string) => status === ReportStatuses.PENDING;

/**
 * @name isCanceled
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines if verification is canceled
 * @returns {boolean}
 * @param {string} status - The status
 */
export const isCanceled = (status: string) =>
  status === ReportStatuses.CANCELED;

/**
 * @name hasManualEntries
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines whether there are manual entries present
 *              in the certification results
 * @returns {boolean}
 * @param {array} certifications - The certifications array
 */
export const hasManualEntries = (certifications: GenericObject[]) =>
  certifications.some(
    certification => certification.result.verified_by === VERIFIED_BY_NONE,
  );

/**
 * @name isManualEntry
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines whether a single result is a manual entry
 * @returns {boolean}
 * @param {object} result - The result object
 */
export const isManualEntry = (result: GenericObject) =>
  result.verified_by === VERIFIED_BY_NONE;

// Info

/**
 * @name getCertificationStatus
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Returns PLV specific status
 * @returns {string}
 * @param {object} result - The result object
 */
export const getCertificationStatus = (result: GenericObject) => {
  if (isManualEntry(result)) {
    return ReportStatuses.SUSPENDED;
  }

  return result.active ? ReportStatuses.CLEAR : ReportStatuses.CONSIDER;
};

/**
 * @name getCertificationStatusText
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Returns PLV specific status text for display
 * @returns {string}
 * @param {object} result - The result object
 */
export const getCertificationStatusText = (result: GenericObject) => {
  if (isManualEntry(result)) {
    return UNVERIFIED;
  }

  return result.active ? VERIFIED : UNVERIFIED;
};

/**
 * @name getCertificationStatusDetails
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Get certification info status details text
 * @returns {string}
 * @param {object} result - The result object
 */
export const getCertificationStatusDetails = (result: GenericObject) => {
  if (isManualEntry(result)) {
    return "Checkr does not support verifications for this candidate's license";
  }

  return formatConsiderSubChecks(result);
};

/**
 * @name formatConsiderSubChecks
 * @function
 * @memberOf ParentClass
 * @description Renders sub_checks data
 * @returns {string}
 * @param {object} result - The result object
 */
const formatConsiderSubChecks = (result: GenericObject) => {
  const displaySubCheckNameMap: { [x: string]: string } = {
    not_expired: 'Expired',
    data_consistency: 'Data Inconsistency',
    in_good_standing: 'Revoked',
    found: 'Not Found',
  };

  const subChecks = result.sub_checks
    .filter(
      (subCheck: GenericObject) =>
        subCheck.status.toLowerCase() === ReportStatuses.CONSIDER,
    )
    .map(
      (subCheck: GenericObject) => displaySubCheckNameMap[subCheck.name] || '',
    )
    .join(', ');
  return subChecks ? `(${subChecks})` : '';
};

/**
 * @name formatCertificationExpiration
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Returns formatted expiration date
 * @returns {string}
 * @param {object} result - The results object
 */
export const formatCertificationExpiration = (result: GenericObject) => {
  const momentDate = moment(result.certification_expiration);
  return result.certification_expiration && momentDate.isValid()
    ? momentDate.format('MM/DD/YYYY')
    : '-';
};

/**
 * @name isResultExpired
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Determines if result is expired
 * @returns {boolean}
 * @param {object} result - The result object
 */
export const isResultExpired = (result: GenericObject) => {
  return result.certification_expiration
    ? new Date(result.certification_expiration) < new Date()
    : false;
};

// Details

/**
 * @name getCertificationDetailsRows
 * @function
 * @memberOf ParentClass
 * @description Method description
 * @returns {array}
 * @param {object} certification - The certification object
 */
export const getCertificationDetailsRows = (certification: GenericObject) => {
  const { input, result } = certification;
  const { sub_results: subResults } = result;
  const config: GenericObject = getCertificationDetailsRowConfig(result);

  // main result rows
  const resultRows = getResultRows(config, input, result);

  // sub result rows
  const subResultRows = getSubResultRows(input, subResults);

  // org dependent inputs rows
  const orgDependentRows = getOrgDependentRows(config, input, subResults);

  // put all the rows together
  const rows = [...resultRows, ...subResultRows, ...orgDependentRows];
  return rows;
};

/**
 * @name getResultRows
 * @function
 * @memberOf ParentClass
 * @description Returns an array of object to render the results in the table
 * @returns {array}
 * @param {object} config - The row config object
 * @param {object} input - The input object
 * @param {object} result - The results object
 */

const getResultRows = (
  config: GenericObject,
  input: GenericObject,
  result: GenericObject,
) =>
  Object.keys(config).map((key: string) => ({
    id: key,
    label: config[key],
    candidateInput: input[key] || '-',
    matchedResult: result[key] || '-',
  }));

/**
 * @name getSubResultRows
 * @function
 * @memberOf ParentClass
 * @description Returns an array of object to render the results in the table
 * @returns {array}
 * @param {object} input - The input object
 * @param {object} subResults - The results object
 */
const getSubResultRows = (input: GenericObject, subResults: GenericObject[]) =>
  subResults
    ? subResults.map((subResult: GenericObject) => ({
        id: subResult.name,
        label: _.startCase(subResult.name),
        candidateInput: input[subResult.name] || '-',
        matchedResult: subResult.value,
      }))
    : [];

/**
 * @name getOrgDependentRows
 * @function
 * @memberOf ParentClass
 * @description Returns an array of object to render the results in the table
 * @returns {array}
 * @param {object} config - The row config object
 * @param {object} input - The input object
 * @param {array} subResults - The sub results array
 */
const getOrgDependentRows = (
  config: GenericObject,
  input: GenericObject,
  subResults: GenericObject[],
) => {
  const subResultNames = subResults
    ? subResults.map((subResult: GenericObject) => subResult.name)
    : [];

  const staticScreeningDetailKeys = Object.keys(config);

  const orgDependentRowsConfig = _.pickBy(input, (val, key) => {
    return (
      !!val &&
      !staticScreeningDetailKeys.includes(key) &&
      !subResultNames.includes(key)
    );
  });

  const orgDependentRows = Object.keys(orgDependentRowsConfig).map(
    (key: string) => ({
      id: `org_dependent_${key}`,
      label: _.startCase(key),
      candidateInput: orgDependentRowsConfig[key],
      matchedResult: '-',
    }),
  );

  return orgDependentRows;
};

/**
 * @name getCertificationDetailsRowConfig
 * @function
 * @memberOf ProfessionalLicenseVerification/Helpers
 * @description Returns a config object describing fields that
 *              should be displayed in the table
 * @returns {object}
 * @param {object} result - The result object
 */
const getCertificationDetailsRowConfig = (result: GenericObject) => {
  if (isManualEntry(result)) {
    return {
      certification_issued_to: 'License Issued To',
      certification_issuer_region: 'State',
      certification_issuer: 'Certifying Organization',
      license_number: 'License Number',
      certification_issuer_website: 'Organization Website',
    };
  }

  return {
    certification_issued_to: 'License Issued To',
    certification_issuer_region: 'State',
    certification_issuer: 'Certifying Organization',
    certification_name: 'Certification Name',
  };
};

// Timeline

export const getTimelineEvents = (licenseVerification: GenericObject) =>
  [
    {
      itemValue: 'Verification started',
      itemKey:
        licenseVerification.created_at &&
        moment(licenseVerification.created_at).format(TIMELINE_DATE_FORMAT),
    },
    {
      itemValue: 'Verification completed',
      itemKey:
        licenseVerification.completed_at &&
        moment(licenseVerification.completed_at).format(TIMELINE_DATE_FORMAT),
    },
  ].filter(({ itemKey }) => Boolean(itemKey));
