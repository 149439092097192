import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import { useHistory } from 'react-router-dom';
import { TASKS_PATH } from 'modules/case-management/api/paths';
import { useUser } from 'context/CurrentUser';
import { useSavedFilters, useTaskSearchParams } from '../hooks';
import { FilterButton } from './FilterButton';

const FiltersContainer = styled(M.Grid)`
  padding: 0 0 2rem 0 !important;
`;

const SavedFilters = () => {
  const { t } = useTranslation('case-management');
  const { data } = useSavedFilters();

  const assignedToYou = data?.find(
    (filter: any) => filter.label === 'Assigned to you',
  )?.value;

  const unassigned = data?.find(
    (filter: any) => filter.label === 'Unassigned reports',
  )?.value;

  const allReports = data?.find(
    (filter: any) => filter.label === 'All reports',
  )?.value;

  const user = useUser();
  const taskParams = useTaskSearchParams();
  const history = useHistory();

  const updateFilter = useCallback(
    (filter?: string) => {
      const searchParams = new URLSearchParams({
        ...taskParams,
        page: '1',
      } as Record<string, string>);
      searchParams.delete('report_assessment');
      if (filter) {
        searchParams.set('assignee_id', filter);
      } else {
        searchParams.delete('assignee_id');
      }

      history.push({
        pathname: TASKS_PATH,
        search: searchParams.toString(),
      });
    },
    [history, taskParams],
  );

  const filterByAssignedToYou = useCallback(
    () => updateFilter(user?.id),
    [user, updateFilter],
  );

  const filterByUnassigned = useCallback(
    () => updateFilter('unassigned'),
    [updateFilter],
  );

  const filterByAllReports = useCallback(() => updateFilter(), [updateFilter]);

  return (
    <FiltersContainer data-testid='case-management-tasks-page-saved-filters'>
      <M.GridRow>
        <FilterButton
          count={assignedToYou}
          text={t('tasks.saved-filters.assigned_to_you')}
          color={colors.uiAqua500}
          onClick={filterByAssignedToYou}
        />
        <FilterButton
          count={unassigned}
          text={t('tasks.saved-filters.unassigned_tasks')}
          color={colors.uiGrey500}
          onClick={filterByUnassigned}
        />
        <FilterButton
          count={allReports}
          text={t('tasks.saved-filters.all_tasks')}
          color={colors.uiNavy500}
          onClick={filterByAllReports}
        />
      </M.GridRow>
    </FiltersContainer>
  );
};

export default SavedFilters;
