import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { ItemVisibility as ItemVisibilityEnum } from 'modules/assess';
import { ItemVisibilityDropdown } from 'modules/assess/ui/rules/common';
import { Field } from 'modules/assess/ui/rules/common/rule';
import { Entity } from 'modules/assess/ui/state/rules/custom';
import { SetDraftRule } from './types';

type Props = {
  option?: ItemVisibilityEnum | null;
  setDraftRule: SetDraftRule;
};

const ItemVisibility: React.FC<Props> = ({ option, setDraftRule }) => {
  const { t } = useTranslation('');
  const label = t('inputs.item_visibility');

  const handleChange = useCallback(
    e => {
      const option = e.selectedItem.id;
      const itemVisibility =
        option === ItemVisibilityEnum.DEFAULT ? null : option;
      setDraftRule((prev: Entity.Type) => ({
        ...prev,
        item_visibility: itemVisibility,
      }));
    },
    [setDraftRule],
  );

  return (
    <M.GridCol
      lg={6}
      md={3}
      sm={1}
      data-testid='assess-rules-custom-rule-editor-item-visibility'
    >
      <Field label={label} style={{ marginBottom: '0.75em' }} />
      <ItemVisibilityDropdown onChange={handleChange} selectedOption={option} />
    </M.GridCol>
  );
};

export default ItemVisibility;
