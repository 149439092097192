import React from 'react';
import { GenericObject } from 'types';
import CandidateDocuments from './CandidateDocuments';
import Details from './Details';
import { CertificationContainer } from './PLVStyledComponents';

import Info from './Info';
import { isManualEntry } from './Helpers';

type Props = {
  certification: GenericObject;
  reportStatusType: string;
};

const Certification: React.FC<Props> = ({
  certification,
  reportStatusType,
}) => {
  const { result, candidate_documents } = certification;
  return (
    <CertificationContainer>
      <Info certification={certification} reportStatusType={reportStatusType} />
      {!isManualEntry(result) && <Details certification={certification} />}
      <CandidateDocuments documents={candidate_documents} />
    </CertificationContainer>
  );
};

export default Certification;
