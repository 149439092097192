import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import Buttons from './buttons';

export type Props = {
  isEditing: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onSave: () => void;
};

const Container = styled.div`
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

export const Text: React.FC<Props> = ({
  value,
  isEditing,
  onChange,
  onCancel,
  onSave,
}) => {
  return (
    <div>
      {!isEditing ? (
        <Container data-testid='assess-entity-description-value'>
          {value}
        </Container>
      ) : (
        <Container>
          <M.TextArea
            light
            value={value || ''}
            onChange={onChange}
            data-testid='assess-entity-description-text-area'
          />
          <Buttons onCancel={onCancel} onSave={onSave} />
        </Container>
      )}
    </div>
  );
};

export default Text;
