import { Context, createContext, useContext } from 'react';
import { State } from 'modules/assess/ui/state/context-date';

export type Value = State.Type;

export const INITIAL = State.initialize();

const context: Context<Value> = createContext(INITIAL);

export const { Consumer, Provider } = context;

export const useValue = (): Value => {
  return useContext(context);
};

export const useEntity = State.entity;
