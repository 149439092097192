import { API } from 'utils/APIClient';
import {
  GetDashboardPreferenceResponse,
  PostDashboardPreferenceParams,
  PostDashboardPreferenceResponse,
  PostNestedDashboardPreferenceParams,
  PutDashboardPreferencesParams,
  PutDashboardPreferencesResponse,
} from './types';
import {
  filterLegacyPreferences,
  getLegacyPreferences,
  hasLegacyPreferences,
  hasSavedPreferences,
  parsePreferences,
  removeLegacyPreferences,
  storeNestedPreference,
  storePreference,
  storePreferences,
} from './utils';

export const putDashboardPreferences = async ({
  preferences,
}: PutDashboardPreferencesParams): Promise<PutDashboardPreferencesResponse> => {
  // Stringify preference values for API
  const bodyObj = {} as { [key: string]: string };
  Object.entries(preferences).forEach(([preference, value]) => {
    bodyObj[preference] = JSON.stringify(value);
  });

  // const body = JSON.stringify({ preferences: bodyObj });
  const response = await API.put('/user/dashboard_preferences', {
    preferences: bodyObj,
  });
  // cache value
  storePreferences(preferences);
  return response;
};

export const postDashboardPreference = async ({
  preference,
  value,
}: PostDashboardPreferenceParams): Promise<PostDashboardPreferenceResponse> => {
  const valueStr = JSON.stringify(value);
  const response = await API.post('/user/dashboard_preferences', {
    preference,
    value: valueStr,
  });
  // cache value
  storePreference(preference, value);

  return response;
};

export const postNestedDashboardPreference = async ({
  parentKey,
  subKey,
  value,
}: PostNestedDashboardPreferenceParams): Promise<PostDashboardPreferenceResponse> => {
  const preferences = parsePreferences();
  const nestedPreference = {
    ...(preferences[parentKey] || {}),
    [subKey]: value,
  };
  const valueStr = JSON.stringify(nestedPreference);
  const response = await API.post('/user/dashboard_preferences', {
    preference: parentKey,
    value: valueStr,
  });
  // cache value
  storeNestedPreference(parentKey, subKey, value);

  return response;
};

export const getDashboardPreferences =
  async (): Promise<GetDashboardPreferenceResponse> => {
    const response = (await API.get(
      '/user/dashboard_preferences',
    )) as GetDashboardPreferenceResponse;

    // parse the response, as the backend saves the preference values stringified
    const parsedResponse = {} as { [key: string]: any };
    Object.entries(response).forEach(([preference, valueStr]) => {
      const value = JSON.parse(valueStr);
      parsedResponse[preference] = value;
    });
    // cache value
    storePreferences(parsedResponse);

    return response;
  };

// Migrate pre-existing user preferences from before persistence
export const migrateUserPreferences = async () => {
  const legacyPreferences = getLegacyPreferences();
  if (!legacyPreferences) return;
  await putDashboardPreferences({
    preferences: filterLegacyPreferences(JSON.parse(legacyPreferences)),
  });
  removeLegacyPreferences();
};

export const initializeUserPreferences = async () => {
  if (hasLegacyPreferences()) {
    await migrateUserPreferences();
  }
  if (hasSavedPreferences()) {
    return parsePreferences();
  }
  return getDashboardPreferences();
};
