import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import Section from '../shared/Section';
import { LinkCard } from '../shared';

const BLOG_POSTS = [
  {
    title: 'Which Background Check is Right For You?',
    link: 'https://checkr.com/blog/which-background-check-is-right-for-you',
    description:
      'Learn how to select and tailor your background screening packages for different position types.',
  },
  {
    title: 'Ready to Order Your First Background Check With Checkr?',
    link: 'https://checkr.com/resources/articles/order-your-first-background-check-with-checkr',
    description:
      "After your Checkr account is created and credentialed, you can initiate a candidate's background check with just a few steps.",
  },
  {
    title: 'Understanding Background Check Statuses and Results',
    link: 'https://checkr.com/blog/understanding-background-check-statuses-and-results',
    description:
      'Making informed hiring decisions requires a clear understanding of the background check process and how to interpret the results.',
  },
];

const PostsContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 911px) {
    flex-direction: column;
  }
`;

const BlogSectionContainer = styled.div`
  padding-top: 20px;
`;

const LinkCardContainer = styled.div`
  width: 100%;
`;

const BlogPosts = () => {
  const { t } = useTranslation('dashboard_landing');
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const header = t('blog_posts.header');
  const subheader = t('blog_posts.subheader');

  const handleTracking = useCallback(
    (title: string) => () => {
      if (!isSignup) {
        trackEvent(DASHBOARD_LANDING_EVENT_NAMES.BLOG_CLICKED, {
          'Home Report Metrics Click': title,
        });
      }
    },
    [isSignup, trackEvent],
  );

  return (
    <BlogSectionContainer>
      <Section header={header} subheader={subheader}>
        <PostsContainer>
          {BLOG_POSTS &&
            BLOG_POSTS.map(({ title, link, description }, idx) => {
              return (
                <LinkCardContainer key={title}>
                  <LinkCard
                    colour='paper'
                    key={link}
                    title={title}
                    externalLink={link}
                    onClick={handleTracking(title)}
                    description={description}
                    tag={`blog-post-${idx + 1}`}
                  />
                </LinkCardContainer>
              );
            })}
        </PostsContainer>
      </Section>
    </BlogSectionContainer>
  );
};

export default BlogPosts;
