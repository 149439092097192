import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { M, colors } from '@dashboard-experience/mastodon';
import { isEmpty } from 'lodash';
import { ReportProps, GenericObject } from 'types';
import { useFlag } from '@dashboard-experience/react-flagr';

import { namespace } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import UIContext from 'context/UI';
import { HEALTH_SCREENING_EXAM_REORDER_UI_COMPONENTS } from 'Flags';
import { scrollToTop } from 'utils';
import Modal from './flow';
import { ExamType, Exam } from './flow/types';

type StyledNotificationProps = {
  closeButtonLabel: string;
  [key: string]: any;
};

export const StyledActionableNotification = styled(
  ({ closeButtonLabel, ...props }: StyledNotificationProps) => (
    <M.ActionableNotification {...props} />
  ),
)`
  #mastodon && {
    max-width: 100%;

    button.cds--actionable-notification__action-button {
      background-color: white;
      font-family: Helvetica Neue;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      border-color: ${colors.uiGrey900};
      box-shadow: 0px 4px 8px 0px #16161d1a;

      &:hover {
        background: ${colors.uiGrey0};
      }
      &:focus {
        background: ${colors.uiGrey0};
      }
    }

    button.cds--actionable-notification__close-button {
      width: fit-content;
      max-width: 100%;
      color: ${colors.brandNavy2};
      padding: 1em;

      &:after {
        content: '${({ closeButtonLabel }) => closeButtonLabel}';
      }
      &:hover {
        text-decoration: underline;
      }
    }
    svg.cds--actionable-notification__close-icon {
      display: none;
    }

    ul {
      list-style: disc;
      margin: 0 0 1em 1em;
    }
  }
`;

const normalizeExams = (
  exams: GenericObject[] | undefined,
  type: ExamType.Drug | ExamType.OccHealth,
): Exam[] =>
  (exams ?? []).map(exam => ({
    type,
    name: exam.name,
    status: exam.status,
    slug: exam.slug.toUpperCase(),
  }));
/*
 * This component is a panel that displays when a Health screening has been canceled and
 * the customer is able to reorder their exams.
 */
const ExamReorderPanel: React.FC<ReportProps> = ({ report }) => {
  const [open, setOpen] = useState(false);
  const { isIframe } = useContext(UIContext);
  const { t } = useTranslation(namespace, {
    keyPrefix: 'exam_reorder.banner',
  });
  const { occupational_health_screening, drug_screening } = report;

  const reorderableExams = [
    ...normalizeExams(occupational_health_screening?.tests, ExamType.OccHealth),
    ...normalizeExams(drug_screening?.exam_results, ExamType.Drug),
  ].filter(exam => exam.status === 'suspended');

  const examReorderUiEnabled =
    useFlag(HEALTH_SCREENING_EXAM_REORDER_UI_COMPONENTS)?.variantKey === 'on';

  const handleModalClose = useCallback(() => setOpen(false), [setOpen]);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
    if (isIframe) scrollToTop();
  }, [isIframe, setOpen]);

  // If the flag is not on, dont show the panel
  if (!examReorderUiEnabled) return null;
  // If no exams are reorderable, don't show the panel
  if (isEmpty(reorderableExams)) return null;

  return (
    <>
      <StyledActionableNotification
        kind='error'
        inline='true'
        title={t('title')}
        actionButtonLabel={t('actions.begin')}
        closeButtonLabel={t('actions.dismiss')}
        onActionButtonClick={handleModalOpen}
        data-testid='exam-reorder-banner'
      >
        <div className='content'>
          <p>{t('description')}</p>
          <ul>
            {reorderableExams.map((exam: any) => {
              return (
                <li key={exam.slug} data-testid='reorderable-exam-item'>
                  {exam.name}
                </li>
              );
            })}
          </ul>
          <p>{t('billing_notice')}</p>
          <p>
            <a
              href='https://help.checkr.com/hc/en-us/articles/8057765063191-Drug-test-FAQs'
              target='_blank'
              rel='noreferrer'
            >
              {t('billing_anchor_description')}
            </a>
          </p>
        </div>
      </StyledActionableNotification>
      {open && (
        <Modal
          onClose={handleModalClose}
          open={open}
          reorderableExams={reorderableExams}
          report={report}
        />
      )}
    </>
  );
};

export default ExamReorderPanel;
