import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ItemType } from 'modules/assess/models/mvr/codes';
import { t } from 'i18next';
import { useNotifier } from 'hooks';
import * as Notifications from 'state/notifications/entity';
import { useBatchUpdate } from '../instance/version/mappings/mappings/api';
import { useCCSV } from '../instance/version/context';

export type Props = {
  type: ItemType;
};

export const UploadCsvButton: React.FC<Props> = ({ type }) => {
  const ccsv = useCCSV();
  const batchUpdate = useBatchUpdate(ccsv?.category_code_set?.id, ccsv?.id);
  const importText = t(
    'assess:mvr.category-sets.instance.versions.instance.common.codes.buttons.upload',
  );
  const title = t(
    'assess:mvr.category-sets.instance.versions.instance.common.codes.upload_banner.title',
  );
  const notifier = useNotifier();

  useEffect(() => {
    if (batchUpdate.result.status === 'success') {
      const params = Notifications.success({ title });
      notifier(params);

      batchUpdate.result.reset();
    }
  }, [notifier, title, batchUpdate.result]);

  const handleImport = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const batchUpdateParams = {
        file,
        type: type.toString(),
      };

      batchUpdate.call(batchUpdateParams);
    },
    [batchUpdate, type],
  );

  return (
    <M.FileInput
      accept='text/csv'
      disableLabelChanges
      kind='secondary'
      label={importText}
      onChange={handleImport}
      data-testid='upload-ccs-csv-button'
    />
  );
};

export default UploadCsvButton;
