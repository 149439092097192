import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export const TreatmentTitle: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t('assess:settings.reports.eligible-records.treatment-title');

  return <h4>{text}</h4>;
};

export default TreatmentTitle;
