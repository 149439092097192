import { useLocation } from 'react-router-dom';
import React from 'react';

import { useUser } from '../../../context/CurrentUser';
import { Account } from '../../../types';
import { StatusBanner } from './shared';

const isAccountCredentialing = (account: Account) => {
  return (
    !account?.api_authorized &&
    !account?.authorized &&
    !account?.credentialed_at
  );
};
export const showAccountCredentialingBanner = (
  account: Account,
  currentRoute: string,
) => {
  return isAccountCredentialing(account) && currentRoute === '/home';
};

const AccountCredentialingBanner = () => {
  const { account } = useUser();
  const location = useLocation();

  if (showAccountCredentialingBanner(account, location.pathname)) {
    return <StatusBanner translationKey='verificationStatus' />;
  }
  return null;
};

export default AccountCredentialingBanner;
