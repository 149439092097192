import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { LookaheadAssessment as Type } from 'types';

type Props = {
  lookaheadAssessment?: Type;
  statusType: string;
};

const StatusBadge = styled(M.StatusBadge)`
  margin-left: 0.5rem;
`;

const LookaheadAssessment: React.FC<Props> = ({
  lookaheadAssessment,
  statusType,
}) => {
  const { t } = useTranslation();

  if (!lookaheadAssessment) {
    return null;
  }

  const decision = t(`assess:decisions.${lookaheadAssessment.decision}`);
  const time = t('prepositions.in.days', {
    days: lookaheadAssessment.num_days_offset,
  });

  const label = `${decision} ${time}`;
  const status = decision.toLowerCase();

  return <StatusBadge label={label} statusType={statusType} status={status} />;
};

export default LookaheadAssessment;
