import React from 'react';
import PageLoading from 'components/PageLoading';
import * as Actions from './Actions';
import Content from './Content';
import Header from './Header';

export type Type = React.FC<Props>;

type Props = {
  divider?: boolean;
  loading?: boolean;
};

const Container: Type = ({ children, divider, loading, ...rest }) => {
  return (
    <div {...rest}>
      <PageLoading enabled={loading} />
      <Actions.Provider>
        <Header divider={divider} />
        <Content>{children}</Content>
      </Actions.Provider>
    </div>
  );
};

export default Container;
