import { useAutoStartReportEligibility } from 'api/packages';

export type AutoStartReportProps = {
  accountId: string;
  candidateId: string;
  packageId: string;
  setCreateReportView?: Function;
};

const useAutoStartReport = ({
  accountId,
  candidateId,
  packageId,
}: AutoStartReportProps) => {
  const { data, isError, error } = useAutoStartReportEligibility(
    accountId,
    candidateId,
    packageId,
  );
  const isCreateReportView: boolean = data?.eligible ?? false;

  return { data, isCreateReportView, isError, error };
};

export default useAutoStartReport;
