import React from 'react';
import styled from 'styled-components';

import * as Types from '../types';
import KeySelector from './KeySelector';
import ShowSelector from './ShowSelector';
import TypeSelector from './TypeSelector';

type Props = {
  onKeyChange?: (key: Types.FilterKey) => void;
  onShowChange?: (show: Types.FilterShow) => void;
  onTypeChange?: (type: Types.FilterType) => void;
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  > * {
    flex: 1;
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

export const Filters: React.FC<Props> = ({
  onKeyChange,
  onShowChange,
  onTypeChange,
}) => {
  return (
    <Container data-testid='assess-ruleset-assign-filters'>
      <TypeSelector onChange={onTypeChange} />
      <KeySelector onChange={onKeyChange} />
      <ShowSelector onChange={onShowChange} />
    </Container>
  );
};

export default Filters;
