export enum Subtype {
  Engaged = 'engaged',
  Adjudication = 'adjudication',
  AdverseAction = 'adverse_action',
  Deferred = 'deferred',
}

export type SubtypeKind =
  | 'engagedSubtypes'
  | 'adjudicationSubtypes'
  | 'adverseActionSubtypes'
  | 'deferredSubtypes';
