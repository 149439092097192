import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const MComposedModalWider = styled(M.ComposedModal)`
  .cds--modal-container {
    min-width: 60rem;
  }
`;

export default MComposedModalWider;
