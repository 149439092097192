import { Report } from 'types';

type GetFieldParams = {
  report: Report;
  reportPrediction?: string;
  reportEstimationGenerated?: string;
};

const getFields = ({
  report,
  reportPrediction,
  reportEstimationGenerated,
}: GetFieldParams) => {
  const segmentationEnabled =
    report?.segment_stamps && report?.segment_stamps?.length > 0;

  const fields = [];

  if (report?.source) {
    fields.push({
      getFieldName: () => 'Source',
      fieldKey: 'source',
    });
  }

  if (segmentationEnabled) {
    fields.push({
      getFieldName: () => 'Nodes',
      fieldKey: 'nodes',
    });
  }

  fields.push({
    getFieldName: () => {
      const multipleLocations =
        report?.work_locations?.length > 1 ||
        (report?.geos && report.geos.length > 1);
      return !multipleLocations ? 'Work Location' : 'Work Locations';
    },
    fieldKey: 'work_locations',
  });

  fields.push({
    getFieldName: () => 'Package',
    fieldKey: 'package',
  });

  if (report.created_at) {
    fields.push({
      getFieldName: () => 'Created',
      fieldKey: 'created_at',
    });
  }

  if (report.completed_at) {
    fields.push({
      getFieldName: () => 'Completed',
      fieldKey: 'completed_at',
    });
  }

  if (report?.upgraded_at) {
    fields.push({
      getFieldName: () => 'Upgraded',
      fieldKey: 'upgraded_at',
    });
  }

  if (report?.revised_at) {
    fields.push({
      getFieldName: () => 'Revised',
      fieldKey: 'revised_at',
    });
  }

  if (report?.status == 'pending' && reportPrediction) {
    fields.push({
      getFieldName: () => 'Estimated Completion',
      fieldKey: 'estimated_completion',
    });
  }
  if (report?.status == 'pending' && reportEstimationGenerated) {
    fields.push({
      getFieldName: () => 'Estimation Updated',
      fieldKey: 'estimation_updated',
    });
  }

  if (report?.turnaround_time) {
    fields.push({
      getFieldName: () => 'Turnaround time',
      fieldKey: 'turnaround_time',
    });
  }

  return fields;
};

export default getFields;
