import React from 'react';
import { IdentityVerification } from 'types';
import { M } from '@dashboard-experience/mastodon';

import buildSubstatus from './utils';

const IDVerification: React.FC<{
  id_verification: IdentityVerification;
}> = ({ id_verification }) => {
  const { task_status, completed_at, task_result } = id_verification;
  const substatus =
    task_status === 'completed' && task_result
      ? task_result.status.toLowerCase()
      : task_status;
  const statusInfo = buildSubstatus(substatus, completed_at, task_result);

  if (!statusInfo) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h3
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <M.Icon
          icon={statusInfo.icon}
          size={16}
          style={{ fill: statusInfo.color, marginRight: '0.5rem' }}
        />
        <div className='title'>{statusInfo.title}</div>
      </h3>
    </div>
  );
};

export default IDVerification;
