import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from '../../common';
import Content from '../../Content';
import Sections from '../../sections';
import Example from './Example/Container';
import Subsections from './subsections';

const Examples = styled.div`
  display: flex;
  flex-direction: row;
  > *:nth-child(2) {
    margin-left: 1rem;
  }
`;

const Footer = styled.div`
  margin-top: 1.5rem;
`;

const Container: React.FC = () => {
  const { t } = useTranslation('fairChance');
  const translationPath = `fairChance:fair_chance.sections.${Sections.engagement}`;
  const translations: any = t(translationPath, { returnObjects: true });
  const { subsections, paragraphs, title } = translations;
  const { candidate_stories, explore } = subsections;
  const footer = (
    <Content paragraphs={paragraphs} section={Sections.engagement} />
  );

  return (
    <Section divider data-testid='fair-chance-sections-engage-more-candidates'>
      <h3>{title}</h3>
      <Examples>
        <Example
          content={candidate_stories}
          subsection={Subsections.candidate_stories}
        />
        <Example content={explore} subsection={Subsections.explore} />
      </Examples>
      <Footer>{footer}</Footer>
    </Section>
  );
};

export default Container;
