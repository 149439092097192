import React from 'react';
import { ExceptionInstanceProps } from '../types';
import { Body, Header, ResolvedAt, VerificationLink } from './components';

const DefaultException: React.FC<ExceptionInstanceProps> = ({
  exception,
  report,
}) => {
  const {
    client_controlled,
    comment,
    created_at,
    expires_at,
    resolved_at,
    type,
    verification_uri,
    resolution_period,
  } = exception;
  const { ssn_trace, status } = report;

  const bodyText = `If the candidate does not respond within
                    ${
                      resolution_period || 7
                    } days after the verification request
                    is sent, the report will be placed in Suspended status. If the
                    candidate does respond within these ${
                      resolution_period || 7
                    }
                    days, Checkr will review the uploaded information, and if all
                    delays are resolved, the report will resume processing.`;

  return (
    <div className='exception-container'>
      <Header comment={comment} createdAt={created_at} type={type} />
      <VerificationLink
        uri={verification_uri}
        clientControlled={client_controlled}
      />
      <ResolvedAt resolvedAt={resolved_at} type={type} ssnTrace={ssn_trace} />
      {status === 'pending' && !resolved_at && expires_at && (
        <Body
          type={type}
          bodyText={bodyText}
          expiresAt={expires_at}
          expireAtText='Report will be suspended on'
        />
      )}
    </div>
  );
};

export default DefaultException;
