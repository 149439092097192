import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

import { ID } from 'modules/id';
import { usePath } from '../api';

export type Type = React.FC<Props>;

type Props = {
  ruleset?: ID;
  version?: ID;
  onClick?: (event: SyntheticEvent) => void;
};

const NoLink = styled.span`
  color: ${colors.uiOrange600};
  pointer-events: none;
  text-decoration: underline;
`;

export const VersionLink: Type = ({ ruleset, version, onClick }) => {
  const path = usePath(ruleset, version);
  const { t } = useTranslation('assess');
  const text = t('assess:ruleset.versions.link.view');

  if (!ruleset || !version) {
    return <NoLink>{text}</NoLink>;
  }

  return (
    <Link to={path} onClick={onClick}>
      {text}
    </Link>
  );
};

export default VersionLink;
