import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { CategoryCodeSetVersionBasic } from 'modules/assess/models/mvr/category-code-set-versions/category-code-set-version';

const Banner = styled(M.InlineNotification)`
  margin-bottom: 40px;

  .cds--inline-notification__subtitle {
    margin-top: 8px !important;
  }
`;

export type Props = {
  activeCategoryVersion: CategoryCodeSetVersionBasic;
  categoryName?: string;
};

const WrongVersionBanner: React.FC<Props> = ({
  activeCategoryVersion,
  categoryName,
}) => {
  const { t } = useTranslation('assess');
  const updatedAt = moment(activeCategoryVersion.updated_at).format(
    'MM/DD/YYYY [at] h:mmA',
  );
  const bannerContent = (
    <Trans
      t={t}
      i18nKey='rules.categories.category-set-banner.wrong-version.content'
      values={{
        email: activeCategoryVersion.updated_by,
        category: categoryName,
        version_number: activeCategoryVersion.number,
        date: updatedAt,
        interpolation: { escapeValue: false },
      }}
    />
  );
  return (
    <Banner
      data-testid='updated-category-version-banner'
      hideCloseButton
      kind='warning'
      title={t('rules.categories.category-set-banner.wrong-version.title')}
      subtitle={bannerContent}
    />
  );
};

export default WrongVersionBanner;
