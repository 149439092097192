import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Toast from 'state/notifications';
import { useEntries } from 'containers/Notifications/hooks';

const useRemoveToast = (key: string) => {
  const dispatch = useDispatch();
  const entry = useEntries().find(entry => entry.key === key);

  const remove = useCallback(() => {
    if (entry) {
      dispatch(Toast.Actions.Remove.init(entry));
    }
  }, [entry, dispatch]);

  return remove;
};

export default useRemoveToast;
