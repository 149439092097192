import { GenericObject } from '@dashboard-experience/utils';

export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader?.result as string)?.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const buildDocParamsFromFile = (type: string, file: GenericObject) => ({
  type,
  path: file?.key,
  bucket: file?.container,
  content_type: file?.mimetype,
  filesize: file?.size,
});
