/* eslint-disable import/prefer-default-export */
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { addExam, AddExamParams } from 'api/healthScreening';
import { formatAxiosError } from 'utils';
import { useModalState, useDispatch } from '../context';
import { getSelectedExams } from '../modal/views/screening-selection/utils';
import { ActionType } from '../types';

const sanitizeParams = (obj: any) =>
  Object.entries(obj).reduce((a: any, [k, v]) => {
    if (v !== undefined && v !== null && v !== '') {
      a[k] = v;
    }
    return a;
  }, {});

export const useAddExam = () => {
  const formData = useModalState('formData');
  const report = useModalState('report');
  const transitionToScreen = useModalState('transitionToScreen');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    appointmentDeadline,
    reorderableExams,
    clinic,
    zipcode,
    drugObservedCollection,
  } = formData;
  const exam_slugs = getSelectedExams(reorderableExams).map(exam =>
    exam.slug.toUpperCase(),
  );

  const params: AddExamParams = {
    appointment_id: report?.appointment?.id,
    exam_slugs,
    expiration_date: appointmentDeadline,
    location_id: clinic?.id,
    location_zipcode: zipcode,
    requires_observed_collection:
      drugObservedCollection === 'Yes' ? true : drugObservedCollection,
  };

  const request = () => addExam(sanitizeParams(params));

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch({ type: ActionType.SET_ADD_EXAM_ERROR, payload: undefined });
      transitionToScreen('success');
    },
    onError: (error: AxiosError) => {
      const payload = formatAxiosError(error, t);

      dispatch({ type: ActionType.SET_ADD_EXAM_ERROR, payload });
      transitionToScreen('error');
    },
  });

  return {
    call,
    result,
  };
};
