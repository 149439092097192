import React from 'react';
import styled from 'styled-components';
import { Item } from 'modules/assess/utilities/hierarchy';
import { Bullet } from 'modules/assess/v2/ui/common';

type Props = {
  charges: Item[];
};

const Container = styled.div`
  width: 300px;
`;

const List: React.FC<Props> = ({ charges }) => {
  const list = charges.map(charge => (
    <Bullet key={charge.id} text={charge.name} />
  ));

  return (
    <Container data-testid='assess-v2-ui-common-lookback-rules-subcategory-charges-list'>
      {list}
    </Container>
  );
};

export default List;
