import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { M, DocumentPdf } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import moment from 'moment';
import sentenceCase from 'sentence-case';
import {
  ReportDownloadLink,
  PayNowLink,
  InvoiceDownloadLink,
} from './InvoicesStyledComponents';
import { BillingCustomer } from '../../../types/Billing';

type Props = {
  header: any;
  row: any;
  cell: any;
  handleDownload: Function;
  customer?: BillingCustomer;
};

const beforeDueDate = (invoice: GenericObject) => {
  const dueDate = moment(invoice.due_date);
  return moment().isSameOrBefore(dueDate, 'day');
};

// Display status string if status is paid
//  OR amount is more than $2500
//  OR there is no Pay Now link present.
//  OR billing customer is undefined
//  OR autopay is enabled and the due date is in the future.
// For other cases - display Pay Now button with a link to encourage self-service payment.
const hidePayNowButton = (
  invoice: GenericObject,
  customer?: BillingCustomer,
) => {
  if (
    invoice.status === 'paid' ||
    invoice.status === 'closed' ||
    invoice.pay_now_url === null ||
    invoice.amount > 2500 ||
    customer == undefined
  ) {
    return true;
  }
  if (customer.autopay_enabled && beforeDueDate(invoice)) {
    return true;
  }
  return false;
};

const statusCellValue = (row: any, customer?: BillingCustomer) => {
  if (hidePayNowButton(row, customer)) {
    if (customer?.autopay_enabled && beforeDueDate(row)) {
      return 'Autopay';
    }
    return sentenceCase(row.status); // Continue displaying status
  }
  return (
    <PayNowLink
      href={row.pay_now_url}
      target='_blank'
      rel='noopener noreferrer'
    >
      <strong>Pay Now</strong>
    </PayNowLink>
  );
};

const invoiceCellValue = (row: any) => {
  return row.invoice_files.some((d: any) => d.document_type === 'invoice') ? (
    <>
      {row.invoice_number || 'invoice.pdf'}
      <DocumentPdf className='icon' data-id={row.id} data-type='invoice' />
    </>
  ) : (
    ''
  );
};

const InvoicesTableCell: React.FC<Props> = ({
  header,
  row,
  cell,
  handleDownload,
  customer,
}) => {
  const { t } = useTranslation();
  const downloadClick = useCallback(e => handleDownload(e), []);
  const getMonthNameByIndex = (index: number) =>
    new Date(1970, index, 1).toLocaleString('default', { month: 'long' });
  let cellValue;
  const currencyTranslationKey = (currency: string) => {
    return t('currencies.local_currency_code') === currency ? 'local' : 'fx';
  };
  const isoCode = row.currency.iso_code;

  switch (header.header) {
    case 'month':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          {`${getMonthNameByIndex(cell.value - 1)} ${row.year}`}
        </M.TableCell>
      );
      break;
    case 'billingEntity':
    case 'customId':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          {row.billing_entity?.name || 'Main Account'}
        </M.TableCell>
      );
      break;
    case 'amount':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          {t(`currencies.${currencyTranslationKey(isoCode)}`, {
            value: cell.value,
            currencyCode: isoCode,
            formatOptions: { currency: isoCode },
          })}
        </M.TableCell>
      );
      break;
    case 'reports':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          <ReportDownloadLink
            href='#'
            download
            key={`${row.id}-csv`}
            onClick={downloadClick}
            data-id={row.id}
            data-type='report_usage'
          >
            {row.invoice_files.some(
              (d: any) => d.document_type === 'report_usage',
            )
              ? 'report_usage.csv'
              : ''}
          </ReportDownloadLink>
        </M.TableCell>
      );
      break;
    case 'invoice':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          <InvoiceDownloadLink
            href='#'
            download
            key={`${row.id}-pdf`}
            onClick={downloadClick}
            data-id={row.id}
            data-type='invoice'
          >
            {invoiceCellValue(row)}
          </InvoiceDownloadLink>
        </M.TableCell>
      );
      break;
    case 'status':
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          {statusCellValue(row, customer)}
        </M.TableCell>
      );
      break;
    default:
      cellValue = (
        <M.TableCell key={cell.id} className={header.classes}>
          {cell.value}
        </M.TableCell>
      );
  }
  return cellValue;
};

export default InvoicesTableCell;
