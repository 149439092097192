import React from 'react';
import { M } from '@dashboard-experience/mastodon';

type Props = {
  isLoading: boolean;
};

const Skeleton: React.FC<Props> = ({ isLoading }) =>
  isLoading ? <M.Select.Skeleton style={{ height: '71px' }} /> : null;

export default Skeleton;
