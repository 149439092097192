/* eslint-disable react/no-children-prop */
import { Assess } from 'components';
import { useFetch } from 'modules/assess/ui/mvr/category-sets/instance/api';
import { ID } from 'modules/id';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Missing from '../../missing';
import Versions from './versions';

type Params = {
  instance: ID;
};

const Instance: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { path: base, params, url } = match;
  const query = useFetch(params.instance);
  const title = query.data?.name || params.instance;

  return (
    <Assess.Route.Breadcrumb location={url} title={title}>
      <Switch>
        <Route path={`${base}/versions`} children={<Versions />} />
        <Route path={`${base}/:missing`} children={<Missing />} />
        <Redirect to={`${base}/versions/active`} />
      </Switch>
    </Assess.Route.Breadcrumb>
  );
};

export default Instance;
