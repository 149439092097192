import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';

type Props = {
  validationErrors: string[];
  isValidating: boolean;
};

export const ErrorNotifications: React.FC<Props> = ({
  validationErrors,
  isValidating,
}) => {
  return (
    <>
      {isValidating && <div>Validating file...</div>}
      {validationErrors.map(errorMessage => {
        return (
          <M.InlineNotification
            style={{
              width: '100%',
              marginRight: '0px',
              color: colors.red,
            }}
            key={errorMessage}
            hideCloseButton
          >
            {errorMessage}
          </M.InlineNotification>
        );
      })}
    </>
  );
};

export default ErrorNotifications;
